(function () {
    'use strict';

    angular
        .module('app.backofficeEntity')
        .controller("ShareholderPartnerController", ShareholderPartnerController);

    ShareholderPartnerController.$inject = ["$rootScope", "$scope", "$q", '$filter', "ShareholderPartnerService", "backofficeIndividualLicenseService", "typeValuesService", "ProviderMultipleFileUploadService", "utilityService", "TypeValue", "$timeout", "Messages", "countryStateService","WebApiUrl", "multipleFileUploadService"];

    function ShareholderPartnerController($rootScope, $scope, $q, $filter, ShareholderPartnerService, backofficeIndividualLicenseService, typeValuesService, ProviderMultipleFileUploadService, utilityService, TypeValue, $timeout, Messages, countryStateService, WebApiUrl, multipleFileUploadService) {
        // $scope.excelErrorArray = [];
        //Tab Initilizer
        $scope.$on("entityTabChanged", function (evt, entityId) {
            if ($rootScope.entityCurrentTab.name == "ceCourse") {
                $scope.entityId = entityId;
                $scope.onNgInit();
            }
        });

        $scope.$on("changeCourseStatus", function (evt, args) {
            var courseStatusId = args.courseStatusId;
            var applicationId = args.applicationId;
            angular.forEach($scope.processedData, function (ceCourseItem) {
                ceCourseItem.CECourseStatusId = courseStatusId;
                ceCourseItem.ApplicationId = applicationId;
                $scope.saveAdditionalInfoRecord(ceCourseItem);
            });
        });

        $scope.formats = ['MM/dd/yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];

        $scope.open1 = function () {
            $scope.popup1.opened = true;
        };
        $scope.popup1 = {
            opened: false
        };

        $scope.open2 = function () {
            $scope.popup2.opened = true;
        };
        $scope.popup2 = {
            opened: false
        };

        $scope.open3 = function () {
            $scope.popup3.opened = true;
        };
        $scope.popup3 = {
            opened: false
        };

        $scope.open4 = function () {
            $scope.popup4.opened = true;
        };
        $scope.popup4 = {
            opened: false
        };

        $scope.altInputFormats = ['M!/d!/yyyy'];

        $scope.maskConfig = {
            clearOnBlur: false,
            allowInvalidValue: false,
            clearOnBlurPlaceholder: true
        };

        $rootScope.ceCourseTabData = {
            firstYrCeHours: 0,
            secondYrCeHours: 0,
            totalCeHours: 0,
            ceCourseTypes: [],
            ceCourseTypesOrg: []
        };

        $scope.getStateByCountryID = function (CountryID) {
            try {
                //var CountryID = $scope.CountryList.whereEquals(Country, 'UserDefinedNumber').firstOrDefault().CountryId;
                countryStateService.getStateByCountryID(sessionStorage.Key, CountryID)
                    .then(function (response) {
                        if (!!response.Status) {
                            $scope.StatesData = response.State;
                        }
                    }, function (data) {
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                if (ex != null) { }
                $exceptionHandler(ex);
            }
        };

        $scope.documentByIndividualDocumentId = multipleFileUploadService.documentByIndividualDocumentId;

        $scope.viewConfig = {
            showAddBlock: false,
            isEdit: false,
            showDetails: true
        };

        $scope.paginationParams = {
            pageSize: 20,
            showpagingHeader: true
        };

        $scope.documentCECourse = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.entityId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "R",
            LoadByDocIdAndDocCode: true,
            DocId: 53,
            DocCode: "D1053",
            LoadDocumentsWithoutBroadCast: false,
            LinkType: 'CC',
            PropertyToProcess: 'IndividualCECourseId',
            IsLinkedDocControl: true
        };

        $scope.validateForm = function (currentForm) {
            if (($scope.additionalInfo.isNonLicensee == true)  && $scope.additionalInfo.DateOfBirth){
                
                angular.forEach(currentForm.errorMessages, function (errVal) {
                    if (errVal.errorMessage.includes("Date of Birth") || errVal.errorMessage.includes("Name of Shareholder") || errVal.errorMessage.includes("Name of Partnership")) {
                        currentForm.errorMessages = currentForm.errorMessages;
                    }
                    else{
                        currentForm.errorMessages = [];
                    }
                });
                
            }
            else if ($scope.additionalInfo.isNonLicensee == undefined) {
                angular.forEach(currentForm.errorMessages, function (errVal) {
                    if (errVal.errorMessage.includes("License holder")) {
                        
                        currentForm.errorMessages = [];
                    }
                    else{
                        currentForm.errorMessages = currentForm.errorMessages;
                    }
                });
            }
            if (!currentForm.errorMessages)
                currentForm.errorMessages = [];
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);


        if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
            $scope.natValidation.showError = true;
            $scope.natValidation.isValid = false;
            return false;
        } else {
            $scope.natValidation.showError = false;
            $scope.natValidation.isValid = true;
            return true;
        }

        };

        $scope.clearValidation = function () {
            $scope.hideStatusMsg();
        };

        $scope.dynamicErrorMsg = "";
        $scope.showFirstSecondYearSeletion = true;

        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/d!/yyyy',
            startDateOptions: {
                minDate: null,
                maxDate: null,
            },
            endDateOptions: {
                minDate: null
            }
        };
        // $scope.datePicker.endDateOptions.minDate = new Date();

        var getUIConfig = function () {
            var configToUse = $scope.config.UIConfigs["ProviderRenewal"];
            if (!configToUse) {
                configToUse = $scope.config.UIConfigs['Default'];
            }
            return configToUse;
        };

        var getConfig = function () {
            utilityService.getConfigData('/components/backoffice/entity/shareholder-partner-config/shareholder-partner.server-config.json').
                then(function (data) {
                    var config = data;
                    $scope.config = config;
                    $scope.uiConfig = getUIConfig();
                    $scope.tableScope = {
                        editAddtionalInfo: $scope.editAddtionalInfo,
                        deleteAddInfo: $scope.deleteAddInfo,
                        deleteBulkRow: $scope.deleteBulkRow,
                        processedData: $scope.processedData,
                        tableHeaderClicked: $scope.tableHeaderClicked
                    };
                    var headers = {};
                    var fieldList = {};

                    for (var key in $scope.uiConfig.Fields) {
                        if (key == 'CECourseActivityTypeName' && !$scope.showFirstSecondYearSeletion)
                            continue;
                        if ($scope.uiConfig.Fields[key].showInTable) {
                            headers[key] = $scope.uiConfig.Fields[key];
                        }
                        if ($scope.uiConfig.Fields[key].showInForm) {
                            fieldList[key] = $scope.uiConfig.Fields[key];
                        }
                    }

                    $scope.headers = headers;
                    $scope.fieldList = fieldList;

                    loadData(true);
                }, function (error) {
                    $scope.loadingAdditionalInfo(false);
                });
        }

        $scope.headerClicked = function (sortProp, headerProperty) {
            if (sortProp != $scope.sortColumn) {
                headerProperty.sortDirection = null;
            }
            $scope.sortColumn = sortProp;
            if (!headerProperty.sortDirection || headerProperty.sortDirection == 'desc') {
                $scope.currAddInfo = $scope.currAddInfo.orderByDescending($scope.sortColumn);
                headerProperty.sortDirection = 'asc';
            } else if (headerProperty.sortDirection == 'asc') {
                $scope.currAddInfo = $scope.currAddInfo.orderBy($scope.sortColumn);
                headerProperty.sortDirection = 'desc';
            }

            $scope.sortOrder = headerProperty.sortDirection;
        };


        var loadData = function (loadAllData) {
            var dataPromises = [ShareholderPartnerService.loadAllAdditionalInfo($scope.providerId, $scope.applicationId, $scope.userId, $scope.providerAdditionalInfo, sessionStorage.Key,)];

            $q.all(dataPromises)
                .then(function (response) {
                    var responseArr = response[0]; 

                    angular.forEach(responseArr, function (item) {
                        if (!!item.AdditionalInformation && (item.AdditionalInformation != 'Not Yet Due' || item.AdditionalInformation != 'Pass' || item.AdditionalInformation != 'Pass with Deficiencies' || item.AdditionalInformation != 'Substandard/Fail')) {
                            if (!item.DateOfBirth || item.DateOfBirth == null){
                                item.DateOfBirth = "-";
                            }
                            else 
                            {
                                let DateOfBirth = new Date(item.DateOfBirth);
                                item.DateOfBirth= DateOfBirth.toISOString().split('T')[0];
                            }
                            if (!item.LicenseExpirationDate || item.LicenseExpirationDate == null){
                                item.LicenseExpirationDate = "-";
                            }
                            else 
                            {
                                let LicenseExpirationDate = new Date(item.LicenseExpirationDate);
                                item.LicenseExpirationDate= LicenseExpirationDate.toISOString().split('T')[0];
                            }
                            if (!item.AssociationDate || item.AssociationDate == null){
                                item.AssociationDate = "-";
                            }
                            else 
                            {
                                let AssociationDate = new Date(item.AssociationDate);
                                item.AssociationDate= AssociationDate.toISOString().split('T')[0];
                            }
                            if (!item.DisassociationDate || item.DisassociationDate == null){
                                item.DisassociationDate = "-";
                            }
                            else 
                            {
                                let DisassociationDate = new Date(item.DisassociationDate);
                                item.DisassociationDate= DisassociationDate.toISOString().split('T')[0];
                            }
                            
                            let resultStr = item.AdditionalInformation.split('-');
                            resultStr.forEach(entry => {
                                if (entry.includes('isNonLicensee')) {
                                    item.isNonLicensee = entry.substring(entry.indexOf(':')+1)
                                    item.isNonLicensee = (item.isNonLicensee == 'false' ? 'No' : 'Yes');
                                }
                                else if (entry.includes('Name')) {
                                    item.Name = entry.substring(entry.indexOf(':')+1)
                                }
                                else if (entry.includes('LicenseNumber')) {
                                    item.LicenseNumber = entry.substring(entry.indexOf(':')+1)
                                    if(item.LicenseNumber == "undefined"){
                                        item.LicenseNumber = "-";
                                    }
                                }
                                else if (entry.includes('LicenseeState')) {
                                    item.State = entry.substring(entry.indexOf(':')+1)
                                    if(item.State == "undefined"){
                                        item.State = "-";
                                    }
                                }
                                else if (entry.includes('LicenseeCode')) {
                                    item.Code = entry.substring(entry.indexOf(':')+1)
                                    if(item.Code == "undefined"){
                                        item.Code = "-";
                                    }
                                }
                            });
                        }
                    });
                    //console.log('arr',responseArr);
                    
                    $scope.processedData = processResponseData(responseArr);
                    $scope.additionalInfoIndividualCount = responseArr.length;
                    $rootScope.lengthOfIndvAdded = responseArr.length > 0 ? responseArr.length : 0;
                    $scope.$emit("checkForAddInfoCount", {
                        countOfIndividual: $scope.additionalInfoIndividualCount
                    })
                    
                    
                    
                    
                    $scope.isConfigLoaded = true;
                    $scope.loadingAdditionalInfo(false);
                }, function (data) {
                    $scope.loadingAdditionalInfo(false);
                    $scope.showStatusMsg('-', data.message);
                });
        };

        $scope.editAddtionalInfo = function (isEdit) {
            $scope.loadingAdditionalInfo(true, "Please wait");
            $scope.viewConfig.isEdit = isEdit;
            $scope.viewConfig.showAddBlock = true;
            //$scope.additionalInfo.isNonLicensee = true;
            $scope.loadingAdditionalInfo(false);
        };
   
        $scope.closeEditAddtionalInfo = function () {
            $scope.viewConfig.showAddBlock = false;
            $scope.clearValidation();
        };

        var processResponseData = function (Data) {
            $scope.currAddInfo = Data;
            return Data;
        };

        $scope.onChangeMethod = function () {
            //console.log("vlaue", $scope.additionalInfo.isNonLicensee);
            
        }

        $scope.clearForm = function () {
            var check_date = Date();
            check_date = null;
            $scope.providerAdditionalInfo.AdditionalInformation = null;
            $scope.additionalInfo.isNonLicensee = null;
            $scope.additionalInfo.Name = null;
            $scope.additionalInfo.LicenseNumber = null; 
            $scope.additionalInfo.licenseeState = null;
            $scope.additionalInfo.licenseeCode = null;
            $scope.additionalInfo.licenseExpDate = check_date;
            $scope.additionalInfo.DateOfBirth = check_date;
            $scope.additionalInfo.DisassociationDate = check_date;
            $scope.additionalInfo.AssociationDate = check_date;
        }

        // Save CeCourse
        $scope.saveAdditionalInfo = function (addInfoForm) {
            $scope.hideStatusMsg();
            addInfoForm.$submitted = true;
            //console.log("date",$scope.additionalInfo.licenseExpDate);
            
            if ($scope.validateForm(addInfoForm)) {
                $scope.loadingAdditionalInfo(true, "Please wait");
                $scope.saveAdditionalInfoRecord(false);
            }
        };

        $scope.saveAdditionalInfoRecord = function () {
            //console.log('1',$scope.additionalInfo.licenseeCode.value);
            $scope.additionalInfoIndividualCount += 1;
            $scope.$emit("checkForAddInfoCount", {
                countOfIndividual: $scope.additionalInfoIndividualCount
            })
            if ($scope.additionalInfo.isNonLicensee == true){

                $scope.providerAdditionalInfo.AdditionalInformation = '#isNonLicensee:' + $scope.additionalInfo.isNonLicensee + '-' + '#Name:' + $scope.additionalInfo.Name + '-' + '#LicenseeCode:' + $scope.additionalInfo.licenseeCode.value;   
                $scope.providerAdditionalInfo.DateOfBirth = $scope.additionalInfo.DateOfBirth;
                $scope.providerAdditionalInfo.DisassociationDate = $scope.additionalInfo.DisassociationDate;
                $scope.providerAdditionalInfo.AssociationDate = $scope.additionalInfo.AssociationDate;
                $scope.providerAdditionalInfo.LicenseExpirationDate = null;
            }
            else{
                if($scope.additionalInfo.isNonLicensee == undefined ){
                    $scope.additionalInfo.isNonLicensee = false;
                }
                $scope.providerAdditionalInfo.AdditionalInformation = '#isNonLicensee:' + $scope.additionalInfo.isNonLicensee + '-' + '#Name:' + $scope.additionalInfo.Name + '-' + '#LicenseNumber:' + $scope.additionalInfo.LicenseNumber + '-' + '#LicenseeState:' + $scope.additionalInfo.licenseeState + '-' + '#LicenseeCode:' + $scope.additionalInfo.licenseeCode.value ;

                $scope.providerAdditionalInfo.LicenseExpirationDate = $scope.additionalInfo.licenseExpDate;
                $scope.providerAdditionalInfo.DateOfBirth = null;
                $scope.providerAdditionalInfo.DisassociationDate = $scope.additionalInfo.DisassociationDate;
                $scope.providerAdditionalInfo.AssociationDate = $scope.additionalInfo.AssociationDate;
            }
        

            
    
            ShareholderPartnerService.saveAdditionalInfo($scope.providerId, $scope.applicationId, $scope.userId, $scope.providerAdditionalInfo, sessionStorage.Key).then(function (response) {
                    if (response.Status) {
                        $scope.clearForm();
                        loadData();
                        
                    } else {
                        $scope.showStatusMsg('-', response.Message);
                    }
                    $scope.loadingAdditionalInfo(false);
                }, function (data) {
                    $scope.loadingAdditionalInfo(false);
                    $scope.showStatusMsg('-', data.message);
                });
        };


        $scope.deleteAddInfo = function (infoData) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                $scope.loadingAdditionalInfo(true, "Please wait");
                $scope.infoData = angular.copy(infoData);
                $scope.infoData.IsDeleted = true;
                ShareholderPartnerService.saveAdditionalInfo($scope.providerId, $scope.applicationId, $scope.userId, $scope.infoData, sessionStorage.Key).then(function (response) {
                    if (response.Status) {
                        loadData();
                    } else {
                        $scope.showStatusMsg('-', response.Message);
                    }
                    $scope.loadingAdditionalInfo(false);
                }, function (data) {
                    $scope.loadingAdditionalInfo(false);
                    $scope.showStatusMsg('-', data.message);
                });
            }
        };

        $scope.deleteBulkRow = function (addInfoData) {
            if(confirm(Messages.General.Confirm.DeleteRecord)){
                $scope.currAddInfo.where(function (item) {
                    if(item.isSelected){
                        $scope.loadingAdditionalInfo(true, "Please wait");
                        $scope.deleteItem = angular.copy(item);
                        $scope.deleteItem.IsDeleted = true;
                        $scope.deleteAddInfo($scope.deleteItem);
                        $scope.current = {};
                    }
                });
            }
        };

        $scope.getDocument = function (provDocID) {
            try {
                ShowLoader("Please wait...");
                ProviderMultipleFileUploadService.providerDocumentByProviderDocumentId(sessionStorage.Key, provDocID)
                    .then(function (response) {
                        var headers = response.headers();
                        $scope.response = response.data;;
                        utilityService.downloadExcelFile($scope.response, headers);
                        HideLoader();
                    });
            } catch (ex) {
                showStatusMessage(ex.message, "error");
                HideLoader();
                $exceptionHandler(ex);
            }
        };

    
        $scope.$on('refreshDocList', function (event, data) {
            $scope.providerDocumentGetByProviderId($scope.providerId, $scope.docId);
        });

        $scope.ProviderDocDocumentDeleteByID = function (docData) {
            try {
                if (confirm(Messages.General.Confirm.DeleteDocument)) {
                    ShowLoader("Please wait...");
                    ProviderMultipleFileUploadService.ProviderDocumentDelete(sessionStorage.Key, docData)
                        .then(function (response) {
                            var index = $scope.documentsList.indexOf(docData);
                            $scope.documentsList.splice(index, 1);
                            $scope.providerAdditionalInfo.documentUpload.documentCount = $scope.documentsList.length;
                            $rootScope.lengthOfDocUploaded = $scope.documentsList.length;
                            HideLoader();
                        }, function (error) {
                            showStatusMessage(error.message, "error");
                        });
                }
            } catch (ex) {
                showStatusMessage(ex.message, "error");
                HideLoader();
                $exceptionHandler(ex);
            }
        };

        $scope.onSelectionChanged = function (isAllSelectedClicked, currentValue) {
            if (!!isAllSelectedClicked) {
                $scope.processedData.map(function (applicationItem) {
                    applicationItem.isSelected = currentValue;
                });
            } else {
                $scope.current.isAllSelected = ($scope.processedData.where(function (item) {
                    return (!item.DisciplineFlag && (item.ApplicationSubTypeId == 2 || item.FingerprintStatus == "MatchedPass") && item.IsPaymentProcessed == true && (item.ApplicationSubTypeId == 2 || !item.ExamRequired || item.ExamStatus == "Passed"));
                }).all('!!item.isSelected'));
                $scope.current.isAllSelected = $scope.processedData.all('!!item.isSelected');
            }
            $scope.current.isAnySelected = ($scope.processedData.any('!!item.isSelected'));
        };


        $scope.onNgInit = function () {
            //console.log('1',$rootScope);
            $scope.onChangeVar = 'none';
            $scope.CountryID = $rootScope.DefaultCountryId;
            $scope.isConfigLoaded = false;
            $scope.providerId = sessionStorage.ProviderId;
            $scope.userId = sessionStorage.UserID;
            $scope.firmType = $scope.individualLicense?.ProviderLicenseTypeId;
            $scope.getStateByCountryID($scope.CountryID);
            $scope.additionalInfo = {};
            $scope.codes = [
                {value: 'A – Associated since last renewal'},
                {value: 'D – Disassociated since last renewal'}
            ];
            $scope.docId = $scope.firmType == 2 ? 118 : 117;

            $scope.providerDocumentGetByProviderId = function (providerid, docid) {
                try {
                    ShowLoader("Please wait...");
                    ProviderMultipleFileUploadService.providerDocumentGetByProviderIdDocId(sessionStorage.Key, providerid, docid)
                        .then(function (response) {
                            $scope.documentsList = [];
                            hideStatusMessage();
                            hideValidationSummary();
                            if (!!response) {
                                if (response.ProviderDocumentGET != null) {
                                    //$scope.DocLKToPageTabSelectionID = response.IndividualDocumentGetList[0].DocumentLkToPageTabSectionId;
                                    if (!!$scope.providerAdditionalInfo.documentUpload && !!$scope.providerAdditionalInfo.documentUpload.DocId) {
                                        $scope.documentsList = $filter('filter')(response.ProviderDocumentGET, function (doc) {
                                            return (doc.DocumentId === $scope.providerAdditionalInfo.documentUpload.DocId &&
                                                (!$scope.providerAdditionalInfo.documentUpload.ApplicationId || ($scope.providerAdditionalInfo.documentUpload.ApplicationId == doc.ApplicationId)) &&
                                                (!!$scope.referenceNumber ? (doc.ReferenceNumber == $scope.referenceNumber) : true));
                                        });
                                        $scope.providerAdditionalInfo.documentUpload.documentCount = $scope.documentsList.length;
                                        if($scope.providerAdditionalInfo.documentUpload.documentCount > 0 )
                                        {
                                            $rootScope.renewalInfoLicenseStatusTypeId = 8;
                                            $rootScope.lengthOfDocUploaded = $scope.documentsList.length;
                                        }
                                        else {
                                            $rootScope.lengthOfDocUploaded = 0;   
                                        }
                                        
                                    } else {
                                        $scope.documentsList = $filter('filter')(response.ProviderDocumentGET, function (doc) {
                                            return (((!$scope.providerAdditionalInfo.documentUpload.ApplicationId || ($scope.providerAdditionalInfo.documentUpload.ApplicationId == doc.ApplicationId))) &&
                                                (!$scope.providerAdditionalInfo.documentUpload.ExcludeDocId || ($scope.providerAdditionalInfo.documentUpload.ExcludeDocId != doc.DocumentId)));
                                        });
                                        $scope.providerAdditionalInfo.documentUpload.documentCount = $scope.documentsList.length;
                                    }
                                }
                                else if (response.StatusCode == "00" && response.ProviderDocumentGET == null) {
                                    $rootScope.lengthOfDocUploaded = 0;   
                                }
                            }
                            HideLoader();
                        }, function (data) {
                            HideLoader();
                            showStatusMessage(data, "error");
                        });
                } catch (ex) {
                    HideLoader();
                    showStatusMessage(ex.message, "error");
                    $exceptionHandler(ex);
                }
            };

            $scope.providerAdditionalInfo = $scope.providerAdditionalInfo.filter(function (item) {
                if($scope.firmType == 2 && item.ContentItemLkId == 813){
                     return item;
                }
                else if ($scope.firmType == 3 && item.ContentItemLkId == 814) {
                    return item;
                }
                
            });
            $scope.providerAdditionalInfo = $scope.providerAdditionalInfo[0];
            $scope.providerAdditionalInfo.documentUpload = {
                ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                ApplicationId: $scope.applicationId,
                ProviderId: $scope.providerId,
                Email: null,
                SendEmail: null,
                TransactionId: null,
                LicenseeReprint: false,
                DocumentLkToPageTabSectionId: 1,
                DocumentLkToPageTabSectionCode: "FR",
                LoadByDocIdAndDocCode: true,
                DocId: $scope.docId,
                DocCode: $scope.firmType == 2 ? "D1118" : "D1117",
                LoadDocumentsWithoutBroadCast: true
            };
            $scope.current = {};
            $scope.uiLogic = {};
            $scope.headers = {};
            
            $scope.providerDocumentGetByProviderId($scope.providerId, $scope.docId);
            
            getConfig();
            $timeout(function () {
                $scope.onNgInitOtherLogic();
            }, 100);
        };

        $scope.onNgInit();
    }
})();