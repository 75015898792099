(function () {
  'use strict'

  angular.module('app.InitialApplication')
    .factory('InitialApplicationCertificationService', InitialApplicationCertificationService)

  InitialApplicationCertificationService.$inject = ['$http', '$q', 'WebApiUrl']

  function InitialApplicationCertificationService($http, $q, WebApiUrl) {

    return {

    };
  }
})();