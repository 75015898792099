(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeFinanceController", BackofficeFinanceController);

    BackofficeFinanceController.$inject = ["$rootScope", "$scope", "$q", "RegisterFactory",
        "backofficeFinanceService", "utilityService", "lookupService", "typeValuesService", "LookUpType", "TypeValue", "feePaymentService", "initialLicenseApplicationService", "Messages"
    ];

    function BackofficeFinanceController($rootScope, $scope, $q, RegisterFactory,
        backofficeFinanceService, utilityService, lookupService, typeValuesService, LookUpType, TypeValue, feePaymentService, initialLicenseApplicationService, Messages) {
        $scope.userId = sessionStorage.UserID;
        $scope.viewConfig = {
            showAddBlock: false,
            proceedToPayment: false
        };
        if (!$scope.transactionType)
            $scope.transactionType = 'MISC';
        $scope.paymentTab = {
            isTabDeficiencyResolved: true
        };
        $scope.feeDetails = {};
        $scope.validator = {
            feePayment: {}
        };
        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/d!/yyyy',
            startDateOptions: {
                minDate: null,
            },
            endDateOptions: {}
        };



        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, individualId) {

            if ($rootScope.individualCurrentTab.name == "finance") {
                $scope.individualId = individualId;
                // if (!$scope.isNameLoadedOnce) {
                $scope.loadFinanceData();
                $scope.isFinanceLoadedOnce = true;
                //  } else {
                //$scope.individualFinanceByIndividualId($scope.individualId, true);
                //}
                $rootScope.individualCurrentTab.isFinanceLoadedOnce = true;
            }
        });

        $scope.loadFinanceData = function () {
            var dataPromises = [];

            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=feedetails")));
            dataPromises.push(backofficeFinanceService.individualPaidInvoiceByIndividualId(sessionStorage.Key, $scope.individualId));
            // dataPromises.push(backofficeFinanceService.getApplicationInfoByIndividualId(sessionStorage.Key, $scope.individualId));

            $q.all(dataPromises)
                .then(function (response) {

                    angular.forEach(response, function (responseItem, itemIndex) {
                        if (!!responseItem.Status) {
                            if (itemIndex == 0) {
                                $scope.feeDetailTypes = responseItem.ReferenceEntities.orderBy('FeeDetailDesc');
                            } else if (itemIndex == 1) {
                                $scope.invoiceList = responseItem.RevFeeDisbResponseList;
                            }
                        } else {
                            showStatusMessage(responseItem.Message, "error")
                        }
                    });
                    $scope.isFinanceLoadedOnce = true;
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    if (data != null) {
                        showStatusMessage(data.message, "error");
                    }
                });
        };

        $scope.openAddEditFinance = function (isEdit, individualFinance, editIndex) {
            ShowLoader();
            $scope.isFinanceEdit = isEdit;
            $scope.editIndex = editIndex;
            $scope.viewConfig.showAddBlock = true;
            if (!$scope.isFinanceEdit) {
                $scope.individualFinance = {};
            } else {
                $scope.individualFinance = angular.copy(individualFinance);
            }
            HideLoader();
        };

        $scope.cancelIndividualFinance = function (financeForm) {
            // $scope.viewConfig.showAddBlock = false;
            $scope.individualFinance = {};
            financeForm.$setPristine();
            financeForm.$submitted = false;
            $scope.isFinanceEdit = false;
            $scope.editIndex = false;
            // $scope.hideValidationSummary();
        };

        $scope.feeDetailTypeChanged = function (individualFinance) {
            if (!!individualFinance.feeDetailType) {
                individualFinance.Amount = individualFinance.feeDetailType.FeeAmount;
            }
        };
        $scope.validateData = function (formToValidate) {
            //            if (formToValidate.$valid) {
            if (!(!!formToValidate.errorMessages && formToValidate.errorMessages.length > 0)) {
                return true;
            } else {
                $scope.showValidation = true;
                $scope.validationErrors = formToValidate.errorMessages;
                utilityService.scrollTop();
                return false;
            }
        }

        // Save IndividualFinance
        $scope.saveIndividualFinance = function (financeForm) {
            try {
                //   $scope.hideStatusMessage();
                // $scope.hideValidationSummary();
                financeForm.$submitted = true;
                if ($scope.validateData(financeForm)) {
                    ShowLoader("Please wait...");
                    if (!$scope.feeDetailRows)
                        $scope.feeDetailRows = [];

                    if (!$scope.isFinanceEdit)
                        $scope.feeDetailRows.push(angular.copy($scope.individualFinance));
                    else
                        $scope.feeDetailRows[$scope.editIndex] = angular.copy($scope.individualFinance);

                    $scope.individualFinance = {};
                    financeForm.$setPristine();
                    financeForm.$submitted = false;
                    $scope.isFinanceEdit = false;
                    $scope.editIndex = false;
                    HideLoader();
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };

        $scope.getTotalFee = function () {
            var totalFee = 0;
            angular.forEach($scope.feeDetailRows, function (feeDetailRow) {
                totalFee = totalFee + (1 * feeDetailRow.Amount);
            });
            $scope.feeDetails.totalFee = totalFee;
            $scope.feeDetails.applicationFee = totalFee;
            return totalFee;
        };

        $scope.proceedToPayment = function (value, isRefund) {
            if (!!isRefund) {
                $scope.isRefundPayment = true;
                angular.forEach($scope.feeDetailRows, function (feeDetailRow) {
                    feeDetailRow.Amount = -1 * feeDetailRow.Amount;
                });
                $scope.getTotalFee();
            }
            //  $scope.hideStatusMessage();
            if (!$scope.feeDetailRows || $scope.feeDetailRows.length == 0) {
                utilityService.scrollTop();
                showStatusMessage(Messages.General.Errors.FeeType, "error");
                return;
            }

            // if ($scope.feeDetails.totalFee < 1) {
            //     utilityService.scrollTop();
            //     $scope.showStatusMessage(Messages.efaacda, "error");
            //     return;
            // }
            ShowLoader();

            //if (!$scope.ApplicationData) {
            var urlparm = '/' + sessionStorage.Key + '?ApplicationId=' + 0 + '&UserId=' + $scope.userId;
            var reqData = {
                ApplicationTypeId: 13,
                ApplicationStatusId: 14,
                ApplicationSubmitMode: 'B',
                GoPaperless: true,
                IsActive: true
            };

            RegisterFactory.GenerateApplicationId(urlparm, reqData).then(function (response) {
                if (response.data.Status) {
                    var Urlparm = '/' + sessionStorage.Key;
                    var reqData = {
                        ApplicationId: response.data.ApplicationId,
                        IndividualId: $scope.individualId,
                        CreatedBy: $scope.userId,
                        CreatedOn: new Date()
                    };
                    RegisterFactory.SaveIndividualApplication(Urlparm, reqData).then(function (res) {
                        if (!res.data.Status) {
                            HideLoader();
                            return;
                        }
                        getApplication(response.data.ApplicationId, value, isRefund);
                    }, function (res) {
                        HideLoader();
                        showStatusMessage(Messages.bbffceb, 'error');

                    });
                }
            }, function (error) {
                HideLoader();
                showStatusMessage(error, 'error');
            });
        };

        $scope.deleteIndividualFinance = function (individualFinance, index) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                //$rootScope.individualEventLog('deleteStatus.deleteConfirmed', $scope.IndividualId);
                ShowLoader("Please Wait...");
                $scope.feeDetailRows.splice(index, 1);
                HideLoader();
            } else {
                // $rootScope.individualEventLog('deleteStatus.deleteCanceled', $scope.IndividualId);
            }
        };

        var getApplication = function (ApplicationId, value, isRefund) {
            initialLicenseApplicationService.getApplicationInfoByApplicationId(ApplicationId, $scope.userId, sessionStorage.Key)
                .then(function (response) {
                    if (response.Status) {
                        $scope.applicationInfo = response.ApplicationResponseList[0];
                        $scope.getPaymentCommonData(value, isRefund);
                    }
                }, function (error) {
                    HideLoader();
                    showStatusMessage(error, 'error');
                });
        }

        $scope.paymentStatusUpdate = function (action) {
            if (action == 'success') {
                HideLoader();
            } else if (action == 'close') {
                $scope.feeDetailRows = [];
                $scope.individualFinance = {};
                $scope.viewConfig.proceedToPayment = false;
            }
        };

        var getAccountSelection = function () {
            var accountSelection = null;
            var feeDetail = (($scope.feeDetailRows && $scope.feeDetailRows.length > 0) ? $scope.feeDetailRows[0] : null);
            var allAccounts = {
                Apptype2: [{
                        AccountName: "InitialCLAB",
                        LicenseTypeIds: [4, 6, 7, 8, 9, 11, 16, 17, 31, 59, 62]
                    },
                    {
                        AccountName: "InitialNonCLAB",
                        LicenseTypeIds: [],
                        Default: true
                    }
                ],
                Apptype1: [{
                        AccountName: "RenewalCLAB",
                        LicenseTypeIds: [4, 6, 7, 8, 9, 11, 16, 17, 31, 59, 62]
                    },
                    {
                        AccountName: "RenewalNonCLAB",
                        LicenseTypeIds: [],
                        Default: true
                    }
                ],
                Apptype11: [{
                        AccountName: "RenewalCLAB",
                        LicenseTypeIds: [4, 6, 7, 8, 9, 11, 16, 17, 31, 59, 62]
                    },
                    {
                        AccountName: "RenewalNonCLAB",
                        LicenseTypeIds: [],
                        Default: true
                    }
                ],
                Apptype13: [{
                    AccountName: "Misc",
                    LicenseTypeIds: [],
                    Default: true
                }],
                Apptype: [{
                    AccountName: "Default",
                    LicenseTypeIds: [],
                    Default: true
                }]
            };

            var appType = feeDetail.feeDetailType.ApplicationTypeId;
            var accounts = allAccounts['Apptype' + (!!appType ? appType : '')];
            var defaultAccount = accounts.whereEquals(true, 'Default').firstOrDefault();
            var licType = feeDetail.feeDetailType.LicenseTypeId;

            try {
                accounts.forEach(function (account) {
                    if (account.LicenseTypeIds.includes(licType)) {
                        accountSelection = account;
                        throw 'done'; //Equivalent of a break
                    }
                });
            } catch (ex) {

            }
            if (!accountSelection) {
                accountSelection = defaultAccount;
            }
            return accountSelection;
        };

        $scope.getPaymentCommonData = function (value, genrateInvoice) {
            feePaymentService.getInvoiceMasterByIndividualIdAndInvoiceStatus(sessionStorage.Key, $scope.individualId, $scope.applicationInfo.ApplicationId)
                .then(function (objMasterDetails) {
                    if (objMasterDetails.StatusCode != "00") {
                        return showErrorMessage(objMasterDetails.Message);
                    }
                    if (!objMasterDetails.InvoiceMasters) {
                        var accountSelection = getAccountSelection();
                        var reqObj = {
                            InvoiceID: 0,
                            IndividualId: $scope.individualId,
                            ApplicationId: $scope.applicationInfo.ApplicationId,
                            LicenseTypeId: $scope.individualLicense ? $scope.individualLicense.LicenseTypeId : 0,
                            AmountBilled: $scope.feeDetails.totalFee,
                            AmountPaid: 0,
                            AmountAdjusted: 0,
                            Balance: $scope.feeDetails.totalFee,
                            TransType: "MP",
                            Module: $scope.moduleVal,
                            InvoiceStatus: "OPEN",
                            GPSelected: false,
                            GPAdjust: 0,
                            GPPayment: 0,
                            GPVoid: false,
                            Description: "Miscellaneous Payment",
                            ModuleGroup: (!!accountSelection && !!accountSelection.AccountName) ? accountSelection.AccountName : '',
                            LFSelected: false,
                            CreatedBy: $scope.userId,
                            ReferenceNumber: 0,
                            ShipToContact: '',
                            // PostMarkDate: $scope.payment.feeDetails.postMarkDate
                        };
                        feePaymentService.invoiceMasterSave(sessionStorage.Key, [reqObj])
                            .then(function (objMasterInvoice) {

                                if (objMasterInvoice.StatusCode != "00") {
                                    HideLoader();
                                    showStatusMessage(objMasterInvoice.Message, 'error');
                                } else {
                                    $scope.InvoiceId = objMasterInvoice.InvoiceMasters[0].InvoiceID;
                                    $scope.feeDetails.InvoiceId = $scope.InvoiceId;
                                    var invoiceDetails = [];
                                    angular.forEach($scope.feeDetailRows, function (feeDetailRow, index) {
                                        feeDetailRow.referenceNumber = ('Misc-' + feeDetailRow.feeDetailType.FeeDetailId);
                                        var miscFeeInvoiceDetail = getMiscFeeInvoiceDetail(feeDetailRow);
                                        invoiceDetails.push(miscFeeInvoiceDetail);
                                    });

                                    feePaymentService.invoiceDetailSave(sessionStorage.Key, invoiceDetails)
                                        .then(function (objInvoiceDetails) {

                                            HideLoader();
                                            if (value) {
                                                $scope.viewConfig.proceedToPayment = true;
                                            } else {
                                                $scope.feeDetailRows = [];
                                                $scope.individualFinance = {};
                                                //$scope.ApplicationData = null;
                                                $scope.viewConfig.proceedToPayment = false;
                                                utilityService.scrollTop()
                                                showStatusMessage(Messages.General.Success.InvoiceGenerated, 'success');
                                                $scope.$emit("loadInvoicePaymentsParent", {});
                                            }
                                        }, function (error) {
                                            HideLoader();
                                        });
                                }

                            }, function (response) {
                                HideLoader();
                                showStatusMessage(Messages.faaaddc, 'error');
                            });
                    } else {
                        HideLoader();
                    }
                }, function (response) {
                    showStatusMessage(Messages.faaaddc, 'error');
                });

        }

        var getMiscFeeInvoiceDetail = function (feeDetailRow, transCode) {
            var licenseEndorsementFeeInvoiceDetail = {
                InvoiceDetailID: 0,
                InvoiceID: $scope.InvoiceId,
                IndividualId: $scope.individualId,
                ApplicationId: $scope.applicationInfo.ApplicationId,
                TransType: "ORD",
                Description: feeDetailRow.feeDetailType.FeeDetailDesc,
                Quantity: 1,
                Amount: feeDetailRow.Amount,
                AmtBilled: feeDetailRow.Amount,
                AmtPaid: feeDetailRow.Amount,
                AmtAdjusted: 0,
                AmtBalance: 0,
                TransCode: feeDetailRow.feeDetailType.FeeTypeCode, //'MISCEFEE',
                Module: 'MiscPay',
                DetailCode: feeDetailRow.feeDetailType.FeeDetailCode,
                ReferenceNumber: feeDetailRow.referenceNumber,
                GPAdjust: 0,
                GPPayment: 0,
                CreatedBy: $scope.userId
            };
            return licenseEndorsementFeeInvoiceDetail;
        };

        if ($rootScope.cashBalancingEntityId) {
            $scope.individualId = $rootScope.cashBalancingEntityId;
            // if (!$scope.isNameLoadedOnce) {
            $scope.loadFinanceData();
            $scope.showonlyRefund = true;
        }
    }
})();