(function() {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationAcknowledgement', initialApplicationAcknowledgementDirective);

    initialApplicationAcknowledgementDirective.$inject = [];

    function initialApplicationAcknowledgementDirective() {
        return {
            restrict: 'E',
            scope: {
                acknowledgement: "=acknowledgement",
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                UserId: "=userId",
                headingPosition: "=headingPosition",
                isBackoffice: "=isBackoffice",
                isApprentice: "=isApprentice",
                natValidation: "=natValidation",
                individualId: '=individualId',
                applicationId: '=applicationId',
                isRenewal: '=?isRenewal',
                loadData: '=?loadData',
                individualLicense: "=individualLicense",
                showStatusMessage: "&",
                tab: "=tab",
                openNextTab: "&",
                isTabStructure: "=?isTabStructure",
                applicationInfo: "=?applicationInfo",
                isIndividualInfo: "=?isIndividualInfo",
                individualDetail: "=?individualDetail",
                showCancelButton: "=?showCancelButton"
            },
            templateUrl: "app/components/individual/application/directives/acknowledgement/acknowledgement.html",
            controller: "InitialApplicationAcknowledgementController"
        }
    }
})();