(function () {
    'use strict'

    angular.module('reporting.FieldSearchReporting')
    .controller('FieldSearchReportingLoadQueryController', ['$scope', '$uibModal', '$uibModalInstance', '$timeout', 'FieldSearchReportingUtility', 'QueryReportingService', 'QueryReportingUtility', 'parameters', FieldSearchReportingLoadQueryController]);

    function FieldSearchReportingLoadQueryController ($scope, $uibModal, $uibModalInstance, $timeout, FieldSearchReportingUtility, QueryReportingService, QueryReportingUtility, parameters) {
        var _key = sessionStorage.Key;

        $scope.$on('$destroy', function() {
            $scope.removeWatches.forEach(function (remove) {
                remove();
            });
        });

        $scope.init = function () {
            $scope.currentQuery = null;
            $scope.removeWatches = [];

            $scope.queryParams = {
                documentType: 'xlsx',
                tableList: parameters.tableList,
                savedQueries: parameters.savedQueries,
                selectedQuery: null,
                availableDocumentTypes: []
            };
           
            QueryReportingService.getAvailableExportDocumentTypes(_key)
            .then(function (data) {
                $scope.queryParams.availableDocumentTypes = data.DocumentTypes;
            }, function (err) {

            });
        };

        var setBlankCondition = function (condition) {
            var columnTable = $scope.queryParams.tableList.whereEquals(condition.Column.TableName, 'TableName').firstOrDefault();

            if (!!columnTable) {
                condition.Column.reference = columnTable.TableTypeId == 3;
            }

            condition.hasValue = true;
            condition.isOpen = false;
            condition.V1 = {
                dateOpened: false,
                Value: condition.Value
            };
            condition.V2 = {
                dateOpened: false,
                Value: condition.Value2
            };

            condition.Column.availableOperators = [];
            condition.Column.possibleValues = [];
        };

        $scope.initCondition = function (condition) {
            setBlankCondition(condition);

            //Monitor any changes to the condition
            var removeWatch = FieldSearchReportingUtility.createConditionWatch(condition);

            $scope.removeWatches.push(removeWatch);
        };

        $scope.clearCondition = function (condition) {
            FieldSearchReportingUtility.clearCondition(condition);
        };

        $scope.addTypeaheadClasses = function () {
            QueryReportingUtility.addTypeaheadClasses();
        };

        $scope.updateMultiValueSelect = function (condition) {
            FieldSearchReportingUtility.updateMultiValueSelect(condition);
        };

        $scope.setupOperatorValue = function (condition) {
            FieldSearchReportingUtility.setupOperatorValue(condition, $scope.queryParams.tableList);
        };

        $scope.loadQueryClicked = function () {
            $scope.currentQuery = angular.copy($scope.queryParams.selectedQuery);

            if (!!$scope.currentQuery.Where) {
                $scope.currentQuery.Where.Conditions.forEach(function (condition) {
                    condition.initOperator = condition.Operator;
                });
            }

            //console.log($scope.currentQuery);
        };

        $scope.clearQueryClicked = function () {
            $scope.currentQuery = null;
        };

        $scope.deleteReportingQuery = function (query) {
            try
            {
                $scope.operationPending(true, 'Deleting Query...');

                if(!query){
                    throw {
                        message: 'No query has been selected'
                    };
                } 

                if(!confirm('Are you sure you want to delete the query: ' + query.Name)) {
                    return;
                }

                var req = {
                    QueryId: query.ID
                };
                
                QueryReportingService.deleteReportingQuery(_key, req)
                .then (function (data) {
                    if(data.Status) {
                        $scope.savedQueries = data.ReportQueries.where(_queryTypeId, 'QueryTypeId');
                    } else if (!data.Status && data.Message) {
                        $scope.showStatusMsg('-', data.Message);
                    }
                    $scope.operationPending(false);
                }, function (err) {
                    if (err && err.statusText) {
                        $scope.showStatusMsg('-', err.statusText);
                    }
                    $scope.operationPending(false);
                });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMsg('-', ex.message); }
                $scope.operationPending(false);
            }
        };

        $scope.removeCondition = function (condition) {
            $scope.currentQuery.Where.Conditions.conditionalSplice('item==this', condition);
        };

        $scope.removeSelect = function (selectColumns) {
            selectColumns.forEach(function (col) {
                $scope.currentQuery.Select.conditionalSplice('item==this', col);
            });
        };

        $scope.generateSQLClicked = function () {
            if (!!$scope.currentQuery) {
                $scope.currentQuery.DocumentType = $scope.queryParams.documentType;
                $uibModalInstance.close({type: 'sql', query: $scope.currentQuery});
            } else {
                $scope.showStatusMsg('-', 'You must load a saved query first');
            }
        };

        $scope.bulkEmailClicked = function () {
            var query = angular.copy($scope.currentQuery);
            var tableList = $scope.queryParams.tableList;

            //Prepare the query for the bulk email dialog
            query.From.RelationTableName = query.From.TableName;
            query.From.RelationTableDisplayName = query.From.TableDisplayName;
            query.From.RelationTableAlias = query.From.TableAlias;

            var allRelations = [query.From].concat(query.Join);

            allRelations.forEach(function (rel) {
                var parent = allRelations.whereEquals(rel.TableAlias, 'RelationTableAlias').firstOrDefault();
                if (parent != rel) {
                    rel.parent = parent;
                    if (!parent.joins) {
                        parent.joins = [];
                    }
                    parent.joins.push(rel);
                } else {
                    rel.parent = null;
                }

                rel.table = tableList.whereEquals(rel.RelationTableName, 'TableName').firstOrDefault();
            });

            //Dismiss this dialog and open the bulk email dialog
            $uibModalInstance.dismiss();

            var modalInstance = $uibModal.open({
                templateUrl: 'app/components/report/query-reporting/query-reporting-bulk-email.html',
                controller: 'QueryReportingBulkEmailDialogController',
                size: 'lg',
                keyboard: false,
                resolve: {
                    parameters: function () {
                        return {
                            currentQuery: query,
                            processedTables: $scope.queryParams.tableList,
                            expressionList: []
                        };
                    }
                }
            });

            modalInstance.result.then(function (result) {

            }, function () {

            });
        };

        $scope.queryDataClicked = function () {
            if (!!$scope.currentQuery) {
                $scope.currentQuery.DocumentType = $scope.queryParams.documentType;
                $uibModalInstance.close({type: 'results', query: $scope.currentQuery});
            } else {
                $scope.showStatusMsg('-', 'You must load a saved query first');
            }
        };

        $scope.exportDataClicked = function () {
            if (!!$scope.currentQuery) {
                $scope.currentQuery.DocumentType = $scope.queryParams.documentType;
                $uibModalInstance.close({type: 'document', query: $scope.currentQuery});
            } else {
                $scope.showStatusMsg('-', 'You must load a saved query first');
            }
        };

        $scope.cancelClicked = function () {
            $uibModalInstance.dismiss();
        };
    }
})();