(function () {
    'use strict'

    angular.module('app.core')
    .directive('entityDocumentGeneration', [EntityDocumentGeneration]);

    function EntityDocumentGeneration() {
        return {
            restrict: 'E',
            scope: {
                entityType: '=',
                entityId: '=',
                applicationId: '=',
                applicationTypeId: '=',
                individualLicense: '=',
                applicationInfo: '=?',
                showHeaderPanel: '=?',
                showSubmitPanel: '=?',
                isBackoffice: '=?',
                natValidation: '=',
                isTabStructure: '=',
                tab: '=',
                openNextTab: '&',
                configName: '@'
            },
            link: function ($scope, element, attrs) {

            },
            templateUrl: 'app/core/directive/entity-document-generation/entity-document-generation.html',
            controller: 'EntityDocumentGenerationController'
        };
    }
})();