(function() {
    'use strict'

    angular.module('app.core').factory('configurationService', configurationService);

    configurationService.$inject = ['$http', '$q', 'AppConfig', 'WebApiUrl'];

    function configurationService($http, $q, AppConfig, WebApiUrl) {
        var settingsData = {};
        var _configurationGetbySettings = function(key, setting, getDataFromServer) {
            var deferred = $q.defer();
            if (!getDataFromServer && !!settingsData[setting]) {
                deferred.resolve(settingsData[setting]);
            } else {
                $http.get(WebApiUrl + 'Configuration/ConfigurationGetbySettings/' + key + '?setting=' + setting)
                    .then(function(data){ data = data.data;
                        if (data.Status && data.StatusCode == "00")
                            settingsData[setting] = data;
                        deferred.resolve(data);
                    }, function(error) { error = error.data;
                        deferred.reject(error);
                    });
            }
            return deferred.promise;
        }

        return {
            configurationGetbySettings: _configurationGetbySettings
        };
    }
})();