﻿(function () {
    'use strict'
    angular.module('app.core')
        .controller('AppstatusballController', ['$scope','AppstatusballFactory', function ($scope, AppstatusballFactory) {
            var _Key = '';
            var _UserId = '';
            $scope.AllStatus = [];
            // page init method
            $scope.init = function () {
                _Key = sessionStorage.Key;
                _UserId = sessionStorage.UserID;
                if ($scope.individualid) {
                    getStatusBall();
                }
            };

            var getStatusBall = function () {
                AppstatusballFactory.ExternalApplicationStatusGetAll(_Key, $scope.individualid, $scope.applicationId).then(function (res) {
                    if (res.data.Status) {
                        $scope.AllStatus = res.data.ApplicationStatusList;
                    }
                });
            };
        }]);
})();
