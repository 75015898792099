(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationExperience', initialApplicationExperienceDirective);

    initialApplicationExperienceDirective.$inject = [];

    function initialApplicationExperienceDirective() {
        return {
            restrict: 'E',
            scope: {
                licenseDescription: "=licenseDescription",
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                natValidation: "=natValidation",
                individualId: '=individualId',
                applicationId: '=applicationId',
                isBackoffice: "=isBackoffice",
                hearder: "=?hearder",
                applicationInfo: "=?applicationInfo",
                individualLicense: "=individualLicense",
                ncess: "=?ncess",
                feeDetails: "=feeDetails",
                tab: "=tab",
                openNextTab: "&",
                isRenewal: "=?isRenewal",
                dashboard: "=dashboard",
                formType: "=?formType"
            },
            templateUrl: function (elements, attrs) {
                if (attrs.dashboard == true || attrs.dashboard == "true")
                    return "app/components/individual/application/directives/experience/experience-dashboard.html";
                else
                    return "app/components/individual/application/directives/experience/experience.html";
            },
            controller: "InitialApplicationExperienceController"
        }
    }
})();
