(function() {
    'use strict'

    angular.module('app.core').factory('dataChangeLogService', dataChangeLogService)

    dataChangeLogService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function dataChangeLogService($http, $q, WebApiUrl, AppConfig) {

        var dataChangeService = {
            dataChangeFlagGet: dataChangeFlagGet,
            dataChangeFlagSave: dataChangeFlagSave,
            dataChangeFlagUpdate: dataChangeFlagUpdate
        };

        function dataChangeFlagGet(individualId, applicationId, individualLicenseId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/DataChangeFlagGet/' + key + '?IndividualId=' + individualId + '&ApplicationId=' + applicationId + '&IndividualLicenseId=' + individualLicenseId + '&Source=' + AppConfig.Source)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function dataChangeFlagSave(changeFlagData, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/DataChangeFlagSave/' + key + "?Source=" + AppConfig.Source, changeFlagData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function dataChangeFlagUpdate(applicationId, individualId, individualLicenseId, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/DataChangeFlagUpdate/' + key + '?ApplicationId=' + applicationId + '&IndividualId=' + individualId + "&IndividualLicenseId=" + individualLicenseId + '&Source=' + AppConfig.Source)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return dataChangeService;
    }
})();