(function () {
    'use strict'

    angular.module('app.core')
    .directive('dateTimePicker', [DateTimePicker]);

    function DateTimePicker() {
        return {
            restrict: 'E',
            replace: true,
            link: function ($scope, element, attrs) {

            },
            template: function (element, attrs) {
                var templ = '   <div class="date-time-picker dropdown"> \
                                    <input type="button" class="dropdown-toggle date-time-picker-toggle btn btn-secondary" \
                                        type="button" \
                                        {6} \
                                        {1} \
                                        {2} \
                                        {9} \
                                        {5} \
                                        tabindex="{8}" \
                                        value="{{(!!{0}) ? ({0} | date: \'MM/dd/yyyy HH:mm a\') : \'{7}\'}}" \
                                    /> \
                                    <ul class="dropdown-menu" role="menu"> \
                                        <li><button class="btn btn-default" href ng-click="{0} = null;">Clear Date/Time</button></li> \
                                        <li> \
                                            <datetimepicker ng-model="{0}" \
                                                {3} \
                                                {4} \
                                                date-to-json /> \
                                        </li> \
                                    </ul> \
                                </div>'.format(attrs.ngModel, 
                                    (attrs.ngDisabled) ? 'ng-disabled="{0}"'.format(attrs.ngDisabled) : '',
                                    (attrs.ngClass) ? 'ng-class="{0}"'.format(attrs.ngClass) : '',
                                    (attrs.natRequired) ? 'nat-required="{0}"'.format(attrs.natRequired) : '',
                                    (attrs.natDateFormat) ? 'nat-date-format="{0}"'.format(attrs.natDateFormat) : '',
                                    (attrs.name) ? 'name="{0}"'.format(attrs.name) : '',
                                    (attrs.id) ? 'id="{0}"'.format(attrs.id) : '',
                                    (attrs.placeholder) ? attrs.placeholder : '', attrs.tabindex, 
                                    (attrs.validateReqField) ? 'validate-req-field="{0}"'.format(attrs.validateReqField) : '');
                return templ;
            }
        };
    }
})();