(function () {
    'use strict'

    angular.module('app.core')
    .directive('addressInput', [AddressInputDirective]);

    function AddressInputDirective() {
        return {
            restrict: 'EA',
            scope: {
                address: '=?',
                includeCounty: '=?',
                includeCountry: '=?',
                contactsConfig: '=?',
                addressChanged: '&?', //Parameters: address
                includePhone: '=?',
                includeEmail: '=?',
                includeFax: '=?',
                includeCell: '=?',
                isDisabled: '@?',
                addressName: '@?',
                isRequired: '@?',
                methods: '=?'
            },
            link: function (scope, element, attrs) {

            },
            templateUrl: 'app/core/directive/address-contacts/address-input.html',
            controller: 'AddressInputController'
        };
    }
})();