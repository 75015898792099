(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .controller('EntityConfirmationController', ['$scope', '$rootScope', '$q', 'EntityConfirmationService', 'utilityService', 'PromiseHandlers', EntityConfirmationController]);

    function EntityConfirmationController($scope, $rootScope, $q, EntityConfirmationService, utilityService, PromiseHandlers) {
        var _key = sessionStorage.Key;
        var _childName = 'legalQuestions';
        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/d!/yyyy',
            startDateOptions: {
                minDate: null,
            },
            endDateOptions: {}
        };

        var getNewLegalResponse = function (content) {
            return {
                EntityConfirmationId: 0,
                EntityId: $scope.entityId,
                EntityTy: $scope.entityType,
                ApplicationId: $scope.applicationId,
                //IndividualLicenseId: $scope.individualLicense.IndividualLicenseId,
                ContentItemLkId: content.ContentItemLkId,
                // ContentItemNumber: content.ContentItemHash,
                ConfirmationResponse: null,
                Desc: '',

                IsActive: true,
                IsDeleted: false
            };
        };

        var getUIConfig = function () {
            return $scope.config.UIConfigs[$scope.configName];
        };

        var getConfig = function () {
            var deferred = $q.defer();

            try {
                utilityService.getConfigData('/components/individual/application/directives/entity-confirmation/entity-confirmation.server-config.json')
                    .then(function (data) {
                        if (!!data) {
                            $scope.config = data;

                            $scope.uiConfig = getUIConfig();

                            $scope.isConfigLoaded = true;
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.checkForChildContent = function (contentCode) {
            $scope.content[contentCode].forEach(function (contentItem) {
                if (!!contentItem.ContentItemParentId)
                    $scope.showChild(contentItem, contentCode)
            });
        };

        $scope.showChild = function (childContentItem, contentCode) {
            var show = false;
            $scope.content[contentCode].forEach(function (contentItem) {
                if (contentItem.ContentItemLkId == childContentItem.ContentItemParentId) {
                    if (!!contentItem.legalResponse.ConfirmationResponse) {
                        show = true;
                    }

                }
            });
            return show;
        };

        $scope.showChildYesNo = function (childContentItem, contentCode) {
            var show = false;
            $scope.content[contentCode].forEach(function (contentItem) {
                if (contentItem.ContentItemLkId == childContentItem.ContentItemParentId) {
                    if (contentItem.ContentResponseTypeId == 1 && !contentItem.legalResponse.ConfirmationResponse && contentItem.legalResponse.ConfirmationResponse != null) {
                        show = true;
                    }
                    if (contentItem.ContentResponseTypeId == 6 && !!contentItem.legalResponse.ConfirmationResponse && contentItem.legalResponse.ConfirmationResponse != null) {
                        show = true;
                    }
                }
            });
            return show;
        };

        var setupAllContentLegalResponses = function () {
            for (var contentName in $scope.content) {
                if (!$scope.content.hasOwnProperty(contentName)) { continue; }

                $scope.content[contentName].forEach(setupContentLegalResponse);
            }
        };

        var setupContentLegalResponse = function (content) {
            content.legalResponse = [$scope.legalResponses.whereEquals(content.ContentItemLkId, 'ContentItemLkId').firstOrDefault(), getNewLegalResponse(content)].coalesce();
            if (!content.legalResponse.ContentItemLkDesc) {
                content.legalResponse.ContentItemLkDesc = content.ContentItemLkDesc;
            }
            if (!content.legalResponse.viewConfig) {
                content.legalResponse.viewConfig = {};
            }
            if (content.ContentResponseTypeId == 7) {
                $scope.loadEntityConfirmationAddInfo(content, false);
            } else {
                content.legalResponse.entityConfirmationAddInfo = [];
            }
        };

        $scope.loadEntityConfirmationAddInfo = function (content, hideLoader) {
            EntityConfirmationService.getEntityConfirmationAddInfo(_key, $scope.entityId, $scope.entityType, $scope.applicationId, content.ContentItemLkId, null)
                .then(function (response) {
                    if (!!response && !!response.Status) {
                        content.legalResponse.entityConfirmationAddInfo = response.EntityRecords;
                    } else {
                        content.legalResponse.entityConfirmationAddInfo = [];
                    }
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    ShowErrMsg();
                });
        };

        var checkPropertyExists = function (list, prop) {
            var res = false;
            if (list) {
                for (var i = 0; i < list.length; i++) {
                    if (list[i].propName == prop)
                        return true;
                }
            }
            return res;
        };

        var ShowErrMsg = function () {
            var errorObj = {
                propName: "legalInfoErr",
                errorMessage: "Erorr in Application Questions Section"
            };
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            if (!checkPropertyExists($scope.natValidation.errorMessages, 'legalInfoErr'))
                $scope.natValidation.errorMessages.push(errorObj);
        }

        $scope.saveIndividualLegalAddInfo = function (currentAddInfoForm, content) {
            try {
                ShowLoader();
                if (!$scope.validateForm(currentAddInfoForm)) {
                    HideLoader();
                    return;
                }
                EntityConfirmationService.saveEntityConfirmationAddInfo(_key, [content.legalResponse.entityConfirmationAddInfoItem]).then(function (response) {
                    if (response.Status == false) {
                        HideLoader();
                        ShowErrMsg("unableToSaveAddLegalInfo", response.Message);
                    } else {
                        $scope.loadEntityConfirmationAddInfo(content, true);
                        content.legalResponse.viewConfig.showAddInfoForm = false;
                    }
                }, function (error) {
                    HideLoader();
                    ShowErrMsg("unableToSaveAddLegalInfo", error);
                })
            } catch (ex) {
                ShowErrMsg("unableToSaveAddLegalInfo", ex);
                HideLoader();
            }
        };

        $scope.deleteIndividualLegalAddInfo = function (entityConfirmationAddInfoItem, legalResponse, indexToDelete) {
            if (confirm('Are you sure you want to delete this record?')) {
                ShowLoader();
                entityConfirmationAddInfoItem.IsDeleted = true;
                entityConfirmationAddInfoItem.IsActive = false;
                EntityConfirmationService.saveEntityConfirmationAddInfo(_key, [entityConfirmationAddInfoItem])
                    .then(function (response) {
                        if (response.Status == false) {
                            ShowErrMsg("unableToSaveAddLegalInfo", response.Message);
                        } else {
                            legalResponse.entityConfirmationAddInfo.splice(indexToDelete, 1);
                            HideLoader();
                        }
                    }, function (data) {
                        if (data != null) {
                            ShowErrMsg("unableToSaveAddLegalInfo", data);
                        }
                    });
            }
        };

        var compileLegalResponses = function () {
            var legalResponses = [];

            for (var contentName in $scope.content) {
                if (!$scope.content.hasOwnProperty(contentName)) { continue; }

                legalResponses = legalResponses.concat($scope.content[contentName].select('legalResponse'));
            }

            return legalResponses;
        };

        $scope.contentLoaded = function (contentItems, contentCode) {
            $scope.content[contentCode] = contentItems;

            contentItems.forEach(setupContentLegalResponse);
        };

        $scope.getLegalResponses = function () {
            var deferred = $q.defer();

            try {
                EntityConfirmationService.getEntityConfirmation(_key, $scope.entityId, $scope.entityType, $scope.applicationId)
                    .then(function (data) {
                        if (data.Status) {
                            $scope.legalResponses = data.EntityConfirmations.whereEquals($scope.applicationId, 'ApplicationId');
                        } else {
                            $scope.legalResponses = [];
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.saveLegalResponses = function () {
            var deferred = $q.defer();

            try {
                var legalResponses = compileLegalResponses();

                EntityConfirmationService.saveEntityConfirmation(_key, legalResponses)
                    .then(function (data) {
                        if (data.Status) {
                            // $scope.logDataChanges();
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.$on($scope.eventname, function (event, args) {
            //$scope.sendWait(_childName);

            if (!$scope.validateForm()) {
                // $scope.sendCancel();
                return;
            }

            try {
                ShowLoader("Saving....");

                PromiseHandlers.sequence($scope.saveLegalResponses, $scope.getLegalResponses)
                    .success(function (data) {
                        if (data.Status) {
                            ShowLoader("Loading....");
                            // BusinessEntitytatusService.setAdditionalWyoming("success");
                            this.callNextFn();
                        } else {
                            HideLoader();
                            //$scope.sendCancel();
                            // BusinessEntitytatusService.setAdditionalWyoming("error");
                        }
                    }, function (data) {
                        if (data.Status) {
                            setupAllContentLegalResponses();
                            //$scope.sendComplete(_childName);
                            // BusinessEntitytatusService.setAdditionalWyoming("success");

                            // if (!!$scope.openNextTab) {
                            //     $scope.openNextTab({ currentTab: $scope.tab });
                            // }
                        } else {
                            $scope.showStatusMsg('-', data);
                            // $scope.sendCancel();
                            // BusinessEntitytatusService.setAdditionalWyoming("error");
                        }
                        HideLoader();
                    })
                    .error(function (err) {
                        $scope.showStatusMsg('-', err);
                        HideLoader();
                        //$scope.sendCancel();
                        BusinessEntitytatusService.setAdditionalWyoming("error");
                    }, function (err) {
                        $scope.showStatusMsg('-', err);
                        HideLoader();
                        //$scope.sendCancel();
                        BusinessEntitytatusService.setAdditionalWyoming("error");
                    })
                    .start();
            } catch (ex) {
                $scope.showStatusMsg('-', ex);
                HideLoader();
                //$scope.sendCancel();
                BusinessEntitytatusService.setAdditionalWyoming("error");
            }
        });

        $scope.saveAndNext = function () {
            //$scope.sendWait(_childName);

            if (!$scope.validateForm()) {
                // $scope.sendCancel();
                return;
            }

            try {
                ShowLoader("Saving....");

                PromiseHandlers.sequence($scope.saveLegalResponses, $scope.getLegalResponses)
                    .success(function (data) {
                        if (data.Status) {
                            ShowLoader("Loading....");
                            this.callNextFn();
                        } else {
                            HideLoader();
                            //   $scope.sendCancel();
                            BusinessEntitytatusService.setAdditionalWyoming("error");
                        }
                    }, function (data) {
                        if (data.Status) {
                            setupAllContentLegalResponses();
                            //$scope.sendComplete(_childName);
                            BusinessEntitytatusService.setAdditionalWyoming("success");

                            // if (!!$scope.openNextTab) {
                            //     $scope.openNextTab({ currentTab: $scope.tab });
                            // }
                        } else {
                            $scope.showStatusMsg('-', data);
                            //      $scope.sendCancel();
                            BusinessEntitytatusService.setAdditionalWyoming("error");
                        }
                        HideLoader();
                    })
                    .error(function (err) {
                        $scope.showStatusMsg('-', err);
                        HideLoader();
                        //    $scope.sendCancel();
                        BusinessEntitytatusService.setAdditionalWyoming("error");
                    }, function (err) {
                        $scope.showStatusMsg('-', err);
                        HideLoader();
                        // $scope.sendCancel();
                        BusinessEntitytatusService.setAdditionalWyoming("error");
                    })
                    .start();
            } catch (ex) {
                $scope.showStatusMsg('-', ex);
                HideLoader();
                // $scope.sendCancel();
                BusinessEntitytatusService.setAdditionalWyoming("error");
            }
        };

        $scope.validate = function () {
            // $scope.sendWait(_childName);

            if ($scope.validateForm()) {
                // $scope.sendComplete(_childName);
            } else {
                // $scope.sendCancel();
            }
        };

        $scope.init = function () {
            $scope.content = {};
            $scope.legalResponses = [];

            $q.all([
                getConfig(),
                $scope.getLegalResponses()
            ])
                .then(function (data) {
                    $scope.isFormLoaded = true;
                    HideLoader();
                }, function (err) {
                    $scope.$evalAsync(function () {
                        HideLoader();
                    });
                });
        };

        $scope.openAddLegalAddInfoForm = function (isAddInfoEdit, entityConfirmationAddInfoItem, legalResponse, content) {
            ShowLoader();
            legalResponse.viewConfig.showAddInfoForm = true;
            legalResponse.isAddInfoEdit = isAddInfoEdit;
            createNewAddInfo(isAddInfoEdit, entityConfirmationAddInfoItem, legalResponse, content);
            HideLoader();
        };

        $scope.closeAddLegalAddInfoForm = function (hideLoader, legalResponse) {
            if (!hideLoader)
                ShowLoader();
            //validateForm(null, null);
            legalResponse.viewConfig.showAddInfoForm = false;
            $scope.currentAddInfoForm = null;
            if (!hideLoader)
                HideLoader();
        };

        var createNewAddInfo = function (isAddInfoEdit, entityConfirmationAddInfoItem, legalResponse, content) {
            if (!isAddInfoEdit) {
                legalResponse.entityConfirmationAddInfoItem = {
                    IsActive: true,
                    IsDeleted: false,
                    EntityConfirmationId: 0,
                    EntityId: $scope.entityId,
                    EntityTy: $scope.entityType,
                    ApplicationId: $scope.applicationId,
                    ContentItemLkId: content.ContentItemLkId
                };

            } else {
                legalResponse.entityConfirmationAddInfoItem = angular.copy(entityConfirmationAddInfoItem);
            }


        };
    }
})();