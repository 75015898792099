(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeIndividualSearchService', backofficeIndividualSearchService)

    backofficeIndividualSearchService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeIndividualSearchService($http, $q, WebApiUrl, AppConfig) {

        var _individualSave = function (person, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualSave/' + key + '?Source=' + AppConfig.Source, person));
        };

        var _licenseTypeGetAll = function (key) {
            return returnDefferedResult($http.get(WebApiUrl + 'TypeValues/LicenseTypeGetAll/' + key));
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        return {
            individualSave: _individualSave,
            licenseTypeGetAll: _licenseTypeGetAll
        }
    }
})();