﻿(function () {
    'use strict'
    angular.module('app.login')
        .controller("LoginwemailController", ["$scope", "$controller", "$cookies", "LoginwemailService", "userPortalViewService", "$state", "Messages",
            function ($scope, $controller, $cookies, LoginwemailService, userPortalViewService, $state, Messages) {
                // Variables
                $scope.recordlist = [];
                $scope.Email = "";
                $scope.Password = "";
                // page init method
                $scope.init = function () {
                    try {
                        sessionStorage.IsLicenseActive = undefined;
                        sessionStorage.key = "";
                        if ($scope.isUserSessionActive()) { } else {
                            sessionStorage.Key = "";
                        }
                    } catch (ex) {
                        if (ex != null) {
                            $scope.showStatusMessage(ex.message, "error");
                        }
                    }
                    //
                };

                // keep me login
                $scope.keepMeLoggedin = function () {
                    try {
                        if ($scope.KeepMeLoggedIn) {
                            var userdata = {
                                USERNAME: btoa($scope.Email),
                                PASSWORD: btoa($scope.Password)
                            }
                            var jsonuserdata = JSON.stringify(userdata);
                            var date = new Date();
                            date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
                            $cookies.put('USERAUTH', jsonuserdata, {
                                expires: date.toUTCString()
                            });

                        } else {
                            $cookies.remove('USERAUTH', '');
                        }
                    } catch (ex) {
                        if (ex != null) {
                            $scope.showStatusMessage(ex.message, "error");
                        }
                    }
                }

                // Get UserDetail if Keep me Login Active
                var UserDetails = $cookies.get('USERAUTH');
                if (UserDetails) {
                    var user = angular.fromJson(UserDetails);
                    $scope.Email = atob(user.USERNAME);
                    $scope.Password = atob(user.PASSWORD);
                    $scope.KeepMeLoggedIn = true;
                }

                // Goto Register New User Page
                $scope.register = function () {
                    $state.go('app.Register');
                }
                $scope.registerNewApp = function () {
                    $state.go('app.NewApplicantRegister');
                };
                ////////////////////////
                // Login new User
                $scope.NewApplicantLogin = function () {
                    try {
                        if (validateData('loginwemailvalidation')) {
                            ShowLoader("Please wait...");
                            var Email = $scope.Email;
                            var Password = $scope.Password;
                            var LoginWithoutEmail = false;
                            LoginwemailService.Login(Email, Password, LoginWithoutEmail)
                                .then(function (res) {
                                    var response = res.data;
                                    if (response.Status) {
                                        if (response.UserInfo.UserTypeID == 2 || response.UserInfo.UserTypeID == 6) {
                                            if (response.UserInfo.TemporaryPassword) {
                                                setSessionStorageUserData(response);
                                                $state.go('app.ChangePassword');
                                            } else {
                                                response.UserInfo.UserTypeId = response.UserInfo.UserTypeID;
                                                var loginVerification = {
                                                    tokenKey: response.Key,
                                                    IndividualId: response.IndividualID,
                                                    userId: response.UserID,
                                                    currentUserData: response.UserInfo,
                                                    isUserPortalView: false
                                                };
                                                userPortalViewService.setUserPortalViewData(loginVerification);
                                                $state.go('app.UserPortalView');
                                            }
                                        } else if (response.UserInfo.UserTypeID == 1) {
                                            $scope.showStatusMessage(Messages.General.Errors.Credentials, "error");
                                            $scope.Password = "";
                                        }
                                        HideLoader();
                                    } else {
                                        HideLoader();
                                        $scope.showStatusMessage(response.Message, "error");
                                    }
                                }, function (res) {
                                    HideLoader();
                                    var data = res.data;
                                    if (data != null) {
                                        $scope.showStatusMessage(data.Message, "error");
                                    }
                                });
                        }
                    } catch (ex) {
                        HideLoader();
                        $scope.showStatusMessage(ex.message, "error");
                    }
                    //
                };
                var setSessionStorageUserData = function (data) {
                    sessionStorage.IndividualId = data.IndividualId;
                    sessionStorage.Email = data.UserInfo.Email;
                    sessionStorage.FirstName = data.UserInfo.FirstName;
                    sessionStorage.IndividualId = data.UserInfo.IndividualId;
                    sessionStorage.LastName = data.UserInfo.LastName;
                    sessionStorage.TemporaryPassword = data.UserInfo.TemporaryPassword;
                    sessionStorage.UserID = data.UserInfo.UserID;
                    sessionStorage.UserId = data.UserInfo.UserID;
                    sessionStorage.UserName = data.UserInfo.UserName;
                    sessionStorage.UserTypeID = data.UserInfo.UserTypeID;
                    sessionStorage.UserTypeName = data.UserInfo.UserTypeName;
                    sessionStorage.Key = data.Key;
                    sessionStorage.isBackOfficeUser = false;
                };

                //audit api call
                var AuditvisitInfoSave = function () {
                    var parm = 'key';
                    var data = {
                        HostIPAddress: location.host,
                        PageName: 'Individual Login',
                        RequestUrl: window.location.href,
                        RequestUrlReferrer: document.referrer,
                        RequestBrowserTypeVersion: 'Type: ' + navigator.appCodeName + ', Version: ' + navigator.appVersion,
                        SessionID: '', //document.cookie,
                        TimeStamp: new Date(),
                        IndividualId: 0,
                        EntityId: '',
                        UserId: 0,
                        Platform: navigator.platform,
                        MachineDeviceName: '',
                        DeviceId: '',
                        AppDomainName: '',
                        UserHostAddress: '',
                        UserHostName: '',
                        IsJavascriptEnabled: true,
                        IsCookieEnabled: navigator.cookieEnabled,
                        IsCrawler: '',
                        IsActiveXControlEnabled: ''
                    };
                    LoginwemailService.AuditvisitInfoSave(parm, data).then(function (res) {
                        /// auditSaved
                    }, function (res) {

                    });
                };

                $scope.GotoforgetPassword = function () {
                    // $state.go('app.ChangePassword');
                    $state.go('app.ApplicantForgotPassword');
                };

                $scope.GotoforgetUserName = function () {
                    $state.go('app.ApplicantForgotUserName');
                };

                $scope.Loginwemail = function () {
                    $state.go('app.Loginwemail');
                };

                var currentState = $state.current.name;
                $scope.backtLogin = function () {
                    switch (currentState) {
                        case 'app.ForgotPassword': {
                            $state.go('app.BackofficeLoginstaff');
                            break;
                        }
                        case 'app.ApplicantForgotPassword': {
                            $state.go('app.Loginwemail');
                            break;
                        }
                    }
                };
                AuditvisitInfoSave();
            }
        ]);
})();