﻿(function () {
    'use strict';
    /**
     *  
     */
    angular.module('profile.manager')
        .factory('profileManagemetFactory', ['$http', 'WebApiUrl', '$q', function ($http, WebApiUrl, $q) {
            var factory = {};
            factory.createProfile = function (key, reqData) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'Profile/CreateProfile/' + key, reqData)
                    .then(function(data){ data = data.data;
                        deferred.resolve(data);
                    }, function(error) { error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            };

            factory.getProfileList = function (key, reqData) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'Profile/GetProfileList/' + key, reqData)
                    .then(function(data){ data = data.data;
                        deferred.resolve(data);
                    }, function(error) { error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            };

            factory.saveUserProfiles = function (key, reqData) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'Profile/SaveUserProfiles/' + key, reqData)
                    .then(function(data){ data = data.data;
                        deferred.resolve(data);
                    }, function(error) { error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            };

            factory.getUserProfileList = function (key, reqData, getInObjectsForm) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'Profile/GetUserProfileList/' + key, reqData)
                    .then(function(data){ data = data.data;
                        if (!!getInObjectsForm) {
                            data.userProfiles = {};
                            angular.forEach(data.UserProfileList, function (userProfile) {
                                if (!!userProfile.ProfileCode)
                                    data.userProfiles['Profile_' + (userProfile.ProfileCode)] = userProfile;
                            });
                        }
                        deferred.resolve(data);
                    }, function(error) { error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            };

            factory.deleteProfileUserProfile = function (key, deleteType, profileId, userProfileId) {
                if (!profileId) {
                    profileId = '';
                }
                if (!userProfileId) {
                    userProfileId = '';
                }
                var deferred = $q.defer();
                $http.get(WebApiUrl + 'Profile/DeleteProfileUserProfile/' + key + '?DeleteType=' + deleteType + '&ProfileId=' + profileId + '&UserProfileId=' + userProfileId)
                    .then(function(data){ data = data.data;
                        deferred.resolve(data);
                    }, function(error) { error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            };

            factory.userTypeGetAll = function (key) {
                var deferred = $q.defer();
                $http.get(WebApiUrl + 'Users/UserTypeGetAll/' + key)
                    .then(function(data){ data = data.data;
                        deferred.resolve(data);
                    }, function(error) { error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            };

            factory.usersSearch = function (key, pageNumber, noOfRecords, searchData) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'Users/UsersSearch/' + key + '?PageNumber=' + pageNumber + '&NoOfRecords=' + noOfRecords, searchData)
                    .then(function(data){ data = data.data;
                        deferred.resolve(data);
                    }, function(error) { error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            };

            return factory;
        }]);
})();