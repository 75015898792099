(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('referenceFormApplicationService', referenceFormApplicationService);

    referenceFormApplicationService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig'];

    function referenceFormApplicationService($http, $q, WebApiUrl, AppConfig) {

        var _getProfessionalReferences = function (individualId) {
            var deferred = $q.defer();


            deferred.resolve([{
                FirstName: "Test First Name",
                MiddleName: "Test Middle Name",
                LastName: "Test Last Name",
                Email: "Test Email",
                PhoneNumber: "Test Phone Number"
            }]);

            return deferred.promise;
        };
        var _getIndividualReference = function (key, referenceGUID, source) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/GetIndividualReference/' + key + '?IndividualId=' + null + "&ApplicationId=" + null + '&ReferenceGUID=' + referenceGUID + '&Source=' + source)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };


        var _saveEntityVerification = function (IndividualReference, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'EntityVerification/SaveEntityVerification/' + key + '?Source=' + AppConfig.Source, IndividualReference)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _getEntityVerification = function (EntityId, EntityType, ApplicationId, VerificationTypeId, IndividualReferenceId, IndividualExperienceId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'EntityVerification/GetEntityVerification/key?EntityId=' + EntityId + '&EntityType=' + EntityType + '&ApplicationId=' + ApplicationId + '&VerificationTypeId=' + VerificationTypeId + '&IndividualReferenceId=' + IndividualReferenceId + '&IndividualExperienceId=' + IndividualExperienceId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _saveIndividualReference = function (IndividualReference, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/SaveIndividualReference/' + key + '?Source=' + AppConfig.Source, IndividualReference)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getIndividualExperience = function (experienceGUID, source) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/GetIndividualExperience/key?IndividualId=' + null + "&ApplicationId=" + null + '&ExperienceGUID=' + experienceGUID + '&Source=' + source)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            getIndividualReference: _getIndividualReference,
            getEntityVerification: _getEntityVerification,
            saveEntityVerification: _saveEntityVerification,
            saveIndividualReference: _saveIndividualReference,
            getIndividualExperience: _getIndividualExperience
        };
    }
})();