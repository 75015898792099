(function () {
    'use strict'
    angular.module('app.lga')
        .directive('lgaRequestForm', lgaRequestForm);

    lgaRequestForm.$inject = [];

    function lgaRequestForm() {
        return {
            restrict: 'E',
            scope: {
                isbackoffice: "=",
                requestitem:"=",
                onclosebtnclick: "&",
                onsavesuccess:'&'
            },
            templateUrl: "app/components/backoffice/lga/directives/lga-request-form/lga-request-form.html",
            controller: "LGARequestFormController"
        }
    }
})();