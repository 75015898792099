(function () {
  'use strict'

  angular.module('app.InitialApplication')
    .factory('initialApplicationLicenseTypeService', initialApplicationLicenseTypeService)

  initialApplicationLicenseTypeService.$inject = ['$http', '$q', 'WebApiUrl']

  function initialApplicationLicenseTypeService($http, $q, WebApiUrl) {

    return {

    };
  }
})();