(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('certificationLogic', [CertificationLogic]);

    function CertificationLogic() {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {

            }
        };
    }
})();