(function () {
    'use strict'

    angular.module('app.core').factory('countryStateService', countryStateService)

    countryStateService.$inject = ['$http', '$q', 'WebApiUrl']

    function countryStateService($http, $q, WebApiUrl) {
        var countriesData = null;

        var _countryGetAll = function (key) {
            var deferred = $q.defer();
            if (countriesData) {
                deferred.resolve(countriesData);
            } else {
                $http.get(WebApiUrl + 'Country/CountryGetAll?Key=' + (!!key ? key : ''))
                    .then(function (data) {
                        data = data.data;
                        if (data.Status && data.StatusCode == "00")
                            countriesData = data;
                        deferred.resolve(data);
                    }, function (error) {
                        error = error.data;
                        deferred.reject(error);
                    });
            }
            return deferred.promise;
        };

        var _getStateByCountryID = function (Key, ID) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'State/StateGetByCountryID/' + Key + '?CountryID=' + ID)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            countryGetAll: _countryGetAll,
            getStateByCountryID: _getStateByCountryID
        }
    }
})();