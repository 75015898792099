(function () {
  'use strict'
  angular.module('app.core')
    .directive('natAlphabet', natAlphabetDirective);

  natAlphabetDirective.$inject = [];

  function natAlphabetDirective() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        element.on('keydown', function (event) {
          if (!angular.isUndefined(attrs.allowspace)) {
            if (event.which == 32) {
              return true;
            }
          }
          if (!angular.isUndefined(attrs.allowapostophe)) {
            if (event.which == 222) {
              return true;
            }
          }
          if (!angular.isUndefined(attrs.allowhyphen)) {
            if (event.which == 109 || event.which == 189 || event.which == 173) {
              return true;
            }
          }
          if (!((event.which >= 65 && event.which <= 90) || event.which == 8 || event.which == 46 || event.which == 13 || event.which == 9 || event.which == 27 || event.which == 17 || (event.which >= 37 && event.which <= 40) || (event.which >= 112 && event.which <= 123))) {
            event.preventDefault();
          }
        })
      }
    }
  }
})();
