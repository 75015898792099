(function () {
    'use strict';

    angular
      .module('app.InitialApplication')
      .controller("InitialApplicationAssistantController", InitialApplicationAssistantController);

    InitialApplicationAssistantController.$inject = ["$rootScope", "$scope",
      "initialApplicationAssistantService", "utilityService", "WebApiUrl", "$q", "Messages"];

    function InitialApplicationAssistantController($rootScope, $scope,
      initialApplicationAssistantService, utilityService, WebApiUrl, $q, Messages) {

      
        $scope.SponsorInformation = [];
        $scope.assistantData = {};

        $scope.GetAssistantInfo = getAssistantInfo;
        $scope.GetStateByCountryID = getStateByCountryID;
         

        $scope.assistantEditIndex = 0;

        $scope.getAssistantConfig = function () {
            var relativeFilePath = "/individual/application/assistant/assistant.server-config.json";
            utilityService.getConfigData(relativeFilePath)
              .then(function (data) {
                  $scope.assistantConfig = data;
              }, function (error) {

              });
        };

        function getAssistantInfo() {
            try {

                if ($scope.assistantInfo.length > 0) {
                    for (var i in $scope.assistantInfo) {
                        $scope.SponsorInformation.push($scope.assistantInfo[i]);
                        }
                    }
            }
            catch (ex) {

            }
        }

        var checkPropertyExists = function (list, prop) {
            var res = false;
            if (list) {
                for (var i = 0; i < list.length; i++) {
                    if (list[i].propName == prop)
                        return true;
                }
            }
            return res;
        };

        var ShowErrMsg = function () {
            var errorObj = {
                propName: "supervisorErr",
                errorMessage: $scope.assistantConfig.ValidationMsg.APIErr
            };
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            if (!checkPropertyExists($scope.natValidation.errorMessages, 'supervisorErr'))
                $scope.natValidation.errorMessages.push(errorObj);
        }

        function getStateByCountryID(CountryID) {
            try {

                initialApplicationAssistantService.getStateByCountryID(sessionStorage.Key, CountryID).then(function (data) {
                    $scope.StatList = data.State;
                }, function (error) {
                   
                });

            }
            catch (ex) {

            }
        }
        angular.element(document.querySelector("#btnCancelAssistantInformation")).click(function () {
            //resetValidation();
            $scope.addAssistantInformation = false;
            angular.element(document.querySelector("#btnAssistantInformation")).show();
        });


        $scope.init = function () {
            try {
                $scope.getAssistantConfig();
                $scope.GetStateByCountryID($rootScope.DefaultCountryId);
                $scope.GetAssistantInfo();

                $scope.addAssistantInformation = false;
                angular.element(document.querySelector("#btnAssistantInformation")).click(function () {
                    $scope.clearDataAssistant();
                    $scope.addAssistantInformation = true;
                    angular.element(document.querySelector("#btnAssistantInformation")).hide();
                });

                angular.element(document.querySelector("#btnCancelAssistantInformation")).click(function () {
                    //resetValidation();
                    $scope.addAssistantInformation = false;
                    angular.element(document.querySelector("#btnAssistantInformation")).show();
                });


            }
            catch (ex) {

            }
        }

         

        $scope.editInApprenticeSponsorInformation = function (IndividualSupervisoryInfoId, $index) {
            try {
                $scope.assistantData.SponsorId = $index;
                angular.element(document.querySelector("#btnApprenticeSponsorInformation")).hide();
                $scope.addAssistantInformation = true;
                $scope.assistantData.SponsorType = $scope.SponsorInformation[$index - 1].SupervisorType;
                $scope.assistantData.SponserFirstName = $scope.SponsorInformation[$index - 1].FirstName;
                $scope.assistantData.SponserMiddleName = $scope.SponsorInformation[$index - 1].MiddleName;
                $scope.assistantData.SponserLastName = $scope.SponsorInformation[$index - 1].LastName;
                $scope.assistantData.SponsersLicenseNumber = $scope.SponsorInformation[$index - 1].SupervisorLicenseNumber;
                $scope.assistantData.BusinessLocationStreet1 = $scope.SponsorInformation[$index - 1].SponsorAddress[0].StreetLine1;
                $scope.assistantData.BusinessLocationStreet2 = $scope.SponsorInformation[$index - 1].SponsorAddress[0].StreetLine2;
                $scope.assistantData.BusinessLocationCity = $scope.SponsorInformation[$index - 1].SponsorAddress[0].City;
                $scope.assistantData.BusinessLocationState = $scope.SponsorInformation[$index - 1].SponsorAddress[0].StateCode;
                $scope.assistantData.BusinessLocationZIP = $scope.SponsorInformation[$index - 1].SponsorAddress[0].Zip;

            }
            catch (ex) {
                
            }
        }

        $scope.deleteApprenticeSponsorInformation = function (IndividualSupervisoryInfoId, $index) {
            try {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    $scope.assistantData.SponsorId = $index;
                    $scope.SponsorInformation[$index - 1].IsDeleted = true;

                    initialApplicationAssistantService.assistantSave($scope.SponsorInformation[$index - 1], sessionStorage.Key)
                           .then(function (response) {
                                
                           }, function (data) {
                               HideLoader();
                               if (data != null) { //$scope.showStatusMessage(data.message, "error");
                                   ShowErrMsg();
                               }
                           });
                }
            }
            catch (ex) {
               
            }
        }

        var individualNameSave = function (reqdata) {
            var deferred = $q.defer();
            var reqParam = '/' + sessionStorage.Key
            initialApplicationAssistantService.individualNameSave(reqdata, reqParam).then(function (res) {
                deferred.resolve(res.data);
            }, function (res) {
                deferred.reject(res.data);
                if (res.data != null) {
                    ShowErrMsg();
                }
            });
            return deferred.promise;
        };

            
        var saveIndividualAddress = function (reqdata) {
            var deferred = $q.defer();
            var reqParam = '/' + sessionStorage.Key
            initialApplicationAssistantService.saveIndividualAddress(reqdata, reqParam).then(function (res) {
                deferred.resolve(res.data);
            }, function (res) {
                deferred.reject(res.data);
                if (res.data != null) {
                    ShowErrMsg();
                }
            });
            return deferred.promise;
        };


        $scope.saveAssistantInformation = function () {
            try {
                if (true){ //(validateData("UI_IndividualRenewalLicenseRenewalHASnAApprenticeSponsorvalidation") && $scope.validateSponsorInformationMandatory()) {
                    if ($scope.assistantData.SponsorId == 0) {
                        var data = {
                            IndividualSupervisoryInfoId: 0,
                            IndividualId: $scope.individualId,
                            ApplicationId: $scope.applicationId,
                            SupervisorLicenseNumber: $scope.assistantData.SponsersLicenseNumber,
                            IsActive: true,
                            IndividualNameId: 0,//$scope.individualId,
                            FirstName: $scope.assistantData.SponserFirstName,
                            MiddleName: $scope.assistantData.SponserMiddleName,
                            LastName: $scope.assistantData.SponserLastName,
                            SupervisorWorkAddressId: 0,
                            SupervisorType: $scope.assistantData.SponsorType,
                            IsDeleted: false,
                            SponsorAddress: [{
                                AddressId: 0,
                                StreetLine1: $scope.assistantData.BusinessLocationStreet1,
                                StreetLine2: $scope.assistantData.BusinessLocationStreet2,
                                City: $scope.assistantData.BusinessLocationCity,
                                StateCode: $scope.assistantData.BusinessLocationState,
                                Zip: $scope.assistantData.BusinessLocationZIP
                            }]
                        }

                        $scope.SponsorInformation.push(data);
                        var address = {
                            AddressId: 0,
                            IndividualId: $scope.individualId,
                            StreetLine1: $scope.assistantData.BusinessLocationStreet1,
                            StreetLine2: $scope.assistantData.BusinessLocationStreet2,
                            City: $scope.assistantData.BusinessLocationCity,
                            StateCode: $scope.assistantData.BusinessLocationState,
                            Zip: $scope.assistantData.BusinessLocationZIP,
                            AddressTypeId: 5,
                            IsMailingSameasPhysical: 0,
                            IsActive: 1,
                            IsDeleted: 0,
                            ApplicationId:$scope.applicationId
                        };
                        saveIndividualAddress(address).then(function (res) {
                            var individualName = {
                                IndividualId :$scope.individualId,
                                FirstName :$scope.assistantData.SponserFirstName,
                                MiddleName :$scope.assistantData.SponserMiddleName,
                                LastName :$scope.assistantData.SponserLastName,
                                IndividualNameTypeId :8,
                                IsActive :1,
                                IsDeleted:0
                            };
                            individualNameSave(individualName).then(function (res2) {
                                if (res.IndividualAddressResponse && res.IndividualAddressResponse.length > 0) {
                                    var len = res.IndividualAddressResponse.length;
                                    data.SupervisorWorkAddressId = res.IndividualAddressResponse[len - 1].AddressId
                                }
                                data.IndividualNameId = res2.IndividualNameResponse[0].IndividualNameId
                                
                                initialApplicationAssistantService.assistantSave(data, sessionStorage.Key)
                                .then(function (response) {
                                    $scope.clearDataAssistant();
                                    $scope.addAssistantInformation = false;
                                    angular.element(document.querySelector("#btnApprenticeSponsorInformation")).show();
                                    HideLoader();
                                }, function (data) {
                                    HideLoader();
                                    if (data != null) { //$scope.showStatusMessage(data.message, "error");
                                        ShowErrMsg();
                                    }
                                });
                            });
                        }, function (res) {
                        });
                    }
                    else {
                        for (var i in $scope.SponsorInformation) {
                            if (($scope.assistantData.SponsorId - 1) == i) {
                                $scope.SponsorInformation[i].SupervisorType = $scope.assistantData.SponsorType;
                                $scope.SponsorInformation[i].FirstName = $scope.assistantData.SponserFirstName;
                                $scope.SponsorInformation[i].MiddleName = $scope.assistantData.SponserMiddleName;
                                $scope.SponsorInformation[i].LastName = $scope.assistantData.SponserLastName;
                                $scope.SponsorInformation[i].SupervisorLicenseNumber = $scope.assistantData.SponsersLicenseNumber;
                                $scope.SponsorInformation[i].SponsorAddress[0].StreetLine1 = $scope.assistantData.BusinessLocationStreet1;
                                $scope.SponsorInformation[i].SponsorAddress[0].StreetLine2 = $scope.assistantData.BusinessLocationStreet2;
                                $scope.SponsorInformation[i].SponsorAddress[0].City = $scope.assistantData.BusinessLocationCity;
                                $scope.SponsorInformation[i].SponsorAddress[0].StateCode = $scope.assistantData.BusinessLocationState;
                                $scope.SponsorInformation[i].SponsorAddress[0].Zip = $scope.assistantData.BusinessLocationZIP;
                                var address = {
                                    AddressId: $scope.SponsorInformation[i].SponsorAddress[0].AddressId,
                                    IndividualId: $scope.individualId,
                                    StreetLine1: $scope.assistantData.BusinessLocationStreet1,
                                    StreetLine2: $scope.assistantData.BusinessLocationStreet2,
                                    City: $scope.assistantData.BusinessLocationCity,
                                    StateCode: $scope.assistantData.BusinessLocationState,
                                    Zip: $scope.assistantData.BusinessLocationZIP,
                                    AddressTypeId: 5,
                                    IsMailingSameasPhysical: 0,
                                    IsActive: 1,
                                    IsDeleted: 0,
                                    ApplicationId: $scope.applicationId
                                };
                                saveIndividualAddress(address).then(function (res) {
                                   
                                }, function (res) {
                                });

                                var individualName = {
                                    IndividualNameId:$scope.SponsorInformation[i].IndividualNameId,
                                    IndividualId: $scope.individualId,
                                    FirstName: $scope.assistantData.SponserFirstName,
                                    MiddleName: $scope.assistantData.SponserMiddleName,
                                    LastName: $scope.assistantData.SponserLastName,
                                    IndividualNameTypeId: 8,
                                    IsActive: 1,
                                    IsDeleted: 0
                                };
                                individualNameSave(individualName).then(function (res2) {
                                });

                                initialApplicationAssistantService.assistantSave($scope.SponsorInformation[i], sessionStorage.Key)
                             .then(function (response) {
                                 $scope.clearDataAssistant();
                                 $scope.addAssistantInformation = false;
                                 angular.element(document.querySelector("#btnAssistantInformation")).show();
                                 $scope.assistantData.SponsorId = null;
                                 HideLoader();
                             }, function (data) {
                                 HideLoader();
                                 if (data != null) { //$scope.showStatusMessage(data.message, "error");
                                     ShowErrMsg();
                                 }
                             });

                                break;
                            }
                        }
                    }
                }
                else {
                   // $scope.validateSponsorInformationMandatory();
                   // HideLoader();
                }
            }
            catch (ex) {
 
            }
        }

        $scope.clearDataAssistant = function () {
            try {
                $scope.assistantData = {};
                $scope.assistantData.SponsorId = 0;
            }
            catch (ex) {

            }
        };

        $scope.cancelEdit = function () {
            $scope.clearDataAssistant();
            $scope.addAssistantInformation = false;
            angular.element(document.querySelector("#btnAssistantInformation")).show();
        };


        $scope.setVisibilityOfAddForm = function (assistantForm, showForm) {
            assistantForm.$setPristine();
            if ($scope.viewConfig == undefined)
                $scope.viewConfig = {};
            if (showForm)
                $scope.assistant = {};
            $scope.viewConfig.showAddForm = showForm;
        }

        $scope.init();
    }
})();
