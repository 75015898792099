﻿(function(){
    'use strict';
    angular.module('app.backofficeAdministor').factory('RolesService',RolesService);
    RolesService.$inject = ['$http','WebApiUrl','$q'];
    function RolesService($http, WebApiUrl, $q){
        var _UserRoleGetAll= function (key) {
            var data = $http.get(WebApiUrl + 'Role/RoleGetAll/' + key);
            return returnResult(data);
        };
        var _UserRoleDelete= function (key, param) {
            var data = $http.post(WebApiUrl + 'Role/RoleSave/' + key, param);
            return returnResult(data);

        };
        var returnResult= function (data) {
            var deferred = $q.defer();
            data
                .then(function (response) {
                    deferred.resolve(response.data);
                })
                .catch(function (response) {
                    deferred.reject(response);
                });
            return deferred.promise;
        };
        return {
            UserRoleGetAll:_UserRoleGetAll,
            UserRoleDelete:_UserRoleDelete
        };
    }
})();