(function () {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeIndividualEducationController", BackofficeIndividualEducationController);

    BackofficeIndividualEducationController.$inject = ["$rootScope", "$scope", "$state", "backofficeIndividualEducationService",
        "countryStateService", "typeValuesService", "lookupService", "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl",
        "loggingService", "multipleFileUploadService", "Messages"
    ];

    function BackofficeIndividualEducationController($rootScope, $scope, $state, backofficeIndividualEducationService,
        countryStateService, typeValuesService, lookupService, utilityService, $exceptionHandler, TypeValue, WebApiUrl,
        loggingService, multipleFileUploadService, Messages) {

        $scope.documentByIndividualDocumentId = multipleFileUploadService.documentByIndividualDocumentId;

        $scope.viewConfig = {
            showAddEducationBlock: false,
            hideAddEducationButton: false,
            showAddEducationExamBlock: false,
            hideAddEducationExamButton: false
        };

        $scope.maskConfig = {
            clearOnBlur: false,
            allowInvalidValue: true,
            clearOnBlurPlaceholder: false
        };

        // $scope.datePicker = {
        //     fromDateOpened: false,
        //     toDateOpened: false,
        //     format: 'M!/d!/yyyy'
        // };
        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'MM/yyyy',
            formatGraduation: "MM/dd/yyyy",
            endDateOptions: {
                datepickerMode: 'month',
                minMode: 'month',
                minDate: null
            },
            startDateOptions: {
                datepickerMode: 'month',
                minMode: 'month',
            }
        };

        $scope.isEducationEdit = false;
        $scope.education = {};
        $scope.natValidation = {};
        $scope.$on("individualTabChanged", function (evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "education" && !$rootScope.individualCurrentTab.isLoadedOnce) {
                $scope.IndividualId = individualId;
                if (!$scope.isLoadedOnce) {
                    $scope.isLoadedOnce = true;
                }
                $scope.getAllProvider();
                $scope.individualEducationBYIndividualId($scope.IndividualId);
                $rootScope.individualCurrentTab.isLoadedOnce = true;
                getDoumentType(32, 'D1032');
                initDocument();

            }
        });

        var initDocument = function () {
            $scope.documentUpload = undefined;
            setTimeout(function () {
                $scope.$apply(function () {
                    $scope.documentUpload = {
                        ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                        ApplicationId: $scope.applicationId,
                        IndividualId: $scope.IndividualId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "R",
                        LoadByDocIdAndDocCode: true,
                        DocId: 32,
                        DocCode: "D1032",
                        LoadDocumentsWithoutBroadCast: false,
                        LinkType: 'ED',
                        PropertyToProcess: 'IndividualEducationId',
                        IsLinkedDocControl: true
                    };
                });
            }, 1000);
        };

        var getDoumentType = function (id, code) {
            multipleFileUploadService.documentGetDocumentTypeName(sessionStorage.Key, id, code)
                .then(function (response) {
                    if ($scope.checkResponse(response)) {
                        if (id == 8) {
                            $scope.DocType = response.DocumentMasterGET;
                        } else {
                            $scope.DocType2 = response.DocumentMasterGET;
                        }
                    }
                }, function (data) {
                    showStatusMessage(data, "error");
                });
        }

        $scope.openAddEditEducation = function (isEdit) {
            $scope.isEducationEdit = isEdit;
            $scope.viewConfig.showAddEducationBlock = true;
            $scope.viewConfig.hideAddEducationButton = true;
            if (!$scope.isEducationEdit) {
                $scope.education = {
                    IndividualId: $scope.IndividualId,
                    //ProviderId: "-1",
                    IsActive: true,
                    UseId: sessionStorage.UserID,
                    provider: {
                        //ProviderId: vm.ProviderWithData.ProviderId,
                        ProviderStatusTypeId: 2,
                        //ApplicationId: sessionStorage.ApplicationId,
                        //ProviderNumber: "",
                        ////ProviderName: vm.ProviderWithData.ProviderName,
                        //DepartmentId: vm.ProviderWithData.DepartmentId,
                        ProviderTypeId: 4,
                        //OwnershipCompany: "",
                        UserId: sessionStorage.UserID,
                        CreatedBy: sessionStorage.UserID,
                        ModifiedBy: sessionStorage.UserID,
                        IsActive: true,
                        address: {
                            //ProviderAddressId: vm.ProviderWithData.ProviderAddressId,
                            //AddressId: vm.ProviderWithData.AddressId,
                            ////ProviderId: response.ProviderId,
                            //ApplicationId: sessionStorage.ApplicationId,
                            ////Addressee: vm.ProviderWithData.ProviderName,
                            //StreetLine1: vm.ProviderWithData.StreetLine1,
                            //StreetLine2: vm.ProviderWithData.StreetLine2,
                            //City: vm.ProviderWithData.City,
                            //StateCode: vm.ProviderWithData.StateCode,
                            CountryId: $rootScope.DefaultCountryId,
                            //CountyId: vm.ProviderWithData.CountyId,
                            //Zip: vm.ProviderWithData.Zip,
                            AddressTypeId: 6,
                            UserId: sessionStorage.UserID,
                            CreatedBy: sessionStorage.UserID,
                            ModifiedBy: sessionStorage.UserID,
                            IsActive: true
                        },
                        schoolPhone: {
                            //ContactId: vm.ProviderWithData.PhoneContactId,
                            //ProviderContactId: vm.ProviderWithData.PhoneProviderContactId,
                            ////ProviderId: vm.ProviderWithData.ProviderId,
                            //ApplicationId: sessionStorage.ApplicationId,
                            //ContactFirstName: vm.ProviderWithData.ProviderName,
                            //ContactLastName: vm.ProviderWithData.ProviderName,
                            //ContactMiddleName: vm.ProviderWithData.ProviderName,
                            ContactTypeId: 9,
                            //ContactInfo: vm.ProviderWithData.Phone,
                            IsActive: true,
                            //IsDeleted: false,
                            ModifiedBy: sessionStorage.UserID,
                            CreatedBy: sessionStorage.UserID
                        },
                        schoolWebsite: {
                            //ContactId: vm.ProviderWithData.WebsiteContactId,
                            //ProviderContactId: vm.ProviderWithData.WebsiteProviderContactId,
                            ////ProviderId: vm.ProviderWithData.ProviderId,
                            //ApplicationId: sessionStorage.ApplicationId,
                            //ContactFirstName: vm.ProviderWithData.ProviderName,
                            //ContactLastName: vm.ProviderWithData.ProviderName,
                            //ContactMiddleName: vm.ProviderWithData.ProviderName,
                            ContactTypeId: 10,
                            //ContactInfo: vm.ProviderWithData.WebSite,
                            IsActive: true,
                            //IsDeleted: false,
                            ModifiedBy: sessionStorage.UserID,
                            CreatedBy: sessionStorage.UserID
                        },
                        schoolEmail: {
                            //ContactId: vm.ProviderWithData.EmailContactId,
                            //ProviderContactId: vm.ProviderWithData.EmailProviderContactId,
                            ////ProviderId: vm.ProviderWithData.ProviderId,
                            //ApplicationId: sessionStorage.ApplicationId,
                            //ContactFirstName: vm.ProviderWithData.ProviderName,
                            //ContactLastName: vm.ProviderWithData.ProviderName,
                            //ContactMiddleName: vm.ProviderWithData.ProviderName,
                            ContactTypeId: 24,
                            //ContactInfo: vm.ProviderWithData.Email,
                            IsActive: true,
                            //IsDeleted: false,
                            ModifiedBy: sessionStorage.UserID,
                            CreatedBy: sessionStorage.UserID
                        }
                    }
                };
            }
        };


        $scope.openAddEditEducationInfo = function (isEducationEdit, educationInfoData, formNumber) {
            ShowLoader();

            $scope.viewConfig.showAddEducationDoctoralForm = true;

            $scope.isEducationDoctoralEdit = isEducationEdit;
            createNewEducationInfo(isEducationEdit, educationInfoData, formNumber);
            HideLoader();
        };
        var createNewEducationInfo = function (isEducationEdit, educationInfoData, formNumber) {
            if (!isEducationEdit) {
                $scope.educationInfo = {
                    IndividualId: $scope.IndividualId,
                    ApplicationId: $scope.applicationId,
                    IsActive: true,
                    ApplicationId: 0,
                    ReferenceNumber: new Date().valueOf()
                };
            } else {
                if (!educationInfoData.ReferenceNumber) {
                    educationInfoData.ReferenceNumber = new Date().valueOf();
                }
                $scope.educationInfo = angular.copy(educationInfoData);
                processEducationDates(formNumber);
            }
            initDocument();

        };

        $scope.programTypeChange = function (val) {

            $scope.educationInfo.ProgramTypeId = val;
        }

        var processEducationDates = function (formNumber) {
            if (!!$scope.educationInfo) {
                if (!!$scope.educationInfo.DateFrom && !angular.isDate($scope.educationInfo.DateFrom))
                    $scope.educationInfo.DateFrom = new Date($scope.educationInfo.DateFrom);
                if (!!$scope.educationInfo.DateTo && !angular.isDate($scope.educationInfo.DateTo))
                    $scope.educationInfo.DateTo = new Date($scope.educationInfo.DateTo);
            }
            $scope.setEducationToMinDate(formNumber);
            $scope.setEducationFromMaxDate(formNumber);
        };

        $scope.setEducationToMinDate = function (formNumber) {
            if (angular.isDate($scope.educationInfo.DateFrom)) {
                $scope.datePicker.endDateOptions.minDate = $scope.educationInfo.DateFrom;
            }
        };

        $scope.setEducationFromMaxDate = function (formNumber) {
            if (angular.isDate($scope.educationInfo.DateTo)) {
                $scope.datePicker.startDateOptions.maxDate = $scope.educationInfo.DateTo;
            }
        };


        $scope.openAddEditEducationExam = function (isEdit) {
            $scope.isEducationExamEdit = isEdit;
            $scope.viewConfig.showAddEducationExamBlock = true;
            $scope.viewConfig.hideAddEducationExamButton = true;
            if (!$scope.isEducationExamEdit) {
                $scope.educationExam = {
                    IndividualId: $scope.IndividualId,
                    IsActive: true
                }
            }
        };

        $scope.closeAddEditEducation = function () {
            $scope.viewConfig.showAddEducationBlock = false;
            $scope.viewConfig.hideAddEducationButton = false;
        };

        $scope.closeAddEditEducationExam = function () {
            $scope.viewConfig.showAddEducationExamBlock = false;
            $scope.viewConfig.hideAddEducationExamButton = false;
        };

        $scope.getAllProvider = function () {
            backofficeIndividualEducationService.getAllProvider(sessionStorage.Key)
                .then(function (response) {
                    if ($scope.checkServerResponse(response)) {
                        $scope.ProviderList = response.ProviderResponseList;
                    }
                },
                    function (data) {
                        HideLoader();
                        $scope.showStatusMessage(data, "error");
                    });
        };
        $scope.SelectTypeAheadItem = function (item, $model, $label, $event) {
            $scope.education.provider = item;
        }
        $scope.individualEducationBYIndividualId = function (IndividualId) {
            try {
                ShowLoader("Please Wait...");
                backofficeIndividualEducationService.individualEducationBYIndividualId(sessionStorage.Key, IndividualId, 0, sessionStorage.UserID)
                    .then(function (response) {

                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse(response)) {
                            // if (response.IndividualCECourseResponseList == null) {
                            //     $scope.EduArray = null;
                            // } else {
                            // $scope.eduID = response.IndividualCECourseResponseList[0].IndividualCECourseId;
                            // $scope.ApplicationID = response.IndividualCECourseResponseList[0].ApplicationId;
                            $scope.IndividualEducationList = response.IndividualEducation;

                            console.log($scope.IndividualEducationList);
                            // }
                            processIndividualEducationAndFields();

                        }
                        HideLoader();
                    },
                        function (response) {
                            HideLoader();
                            $scope.showStatusMessage(response.data.Message, "error");
                        });
                countryStateService.getStateByCountryID(sessionStorage.Key, $rootScope.DefaultCountryId).then(function (response) {
                    if (response.Status) {
                        $scope.StateList = response.State;
                        //    $scope.GetEducationInfo();
                    } else {
                        ShowErrMsg("countryStateLoadFaied", response.Message)
                    }
                }, function (error) {
                    ShowErrMsg("countryStateLoadFaied", error.Message)

                });
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        }

        var processIndividualEducationAndFields = function () {
            if ($scope.IndividualEducationList && $scope.IndividualEducationList.length > 0) {

                multipleFileUploadService.individualDocumentProcess(sessionStorage.Key, $scope.IndividualId,
                    $scope.IndividualEducationList, $scope.documentUpload.PropertyToProcess, $scope.documentUpload.LinkType).then(function (responseData) {
                        $scope.IndividualEducationList = responseData;
                        HideLoader();
                    });
            }
        };

        // $scope.GetEducationInfo = function () {
        //     try {
        //         $scope.IndividualEducation = [];
        //         debugger
        //         backofficeIndividualEducationService.getEducation($scope.IndividualId, $scope.ApplicationID, sessionStorage.UserID, sessionStorage.Key)
        //             .then(function (response) {
        //                 $scope.IndividualEducation = response.IndividualEducation;
        //                 processIndividualEducationByProgramType();
        //                 HideLoader();
        //             }, function (error) {
        //                 HideLoader();
        //                 $scope.showStatusMessage({ messageContent: 'Some error occured !', messageType: 'error' });
        //             });
        //     } catch (ex) {
        //         HideLoader();
        //         $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
        //     }
        // };

        var validateForm = function (currentForm) {

            $scope.natValidation.errorMessages = [];
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.closeAddEditEducationInfo = function (formNumber) {
            $scope.viewConfig.showAddEducationDoctoralForm = false;
            $scope.currentDoctoralForm = null;

        };
        $scope.setForm = function (currentForm) {
            $scope.currentDoctoralForm = currentForm;
        }

        $scope.setSaveDocumentRef = function (fn) {
            $scope.saveAllDocument = fn;
        };

        $scope.saveEducation = function (currentForm, formNumber) {
            try {
                if (!validateForm(currentForm)) {
                    return;
                };

                ShowLoader("Please Wait...");
                var individualEducationResponse = {

                };
                // if (formNumber == 1) {
                //     individualEducationResponse.IndividualEducationResponse = [$scope.educationInfo]
                // } else if (formNumber == 2) {
                individualEducationResponse.IndividualEducationResponse = [$scope.educationInfo]

                backofficeIndividualEducationService.individualEducationSave($scope.educationInfo.IndividualId,
                    ($scope.educationInfo.ApplicationId ? $scope.educationInfo.ApplicationId : 0), sessionStorage.UserID, [individualEducationResponse], sessionStorage.Key)
                    .then(function (response) {
                        if (response.Status && response.StatusCode == "00") {
                            // if (formNumber == 1) {
                            $scope.viewConfig.showAddEducationDoctoralForm = false;
                            // } else if (formNumber == 2) {
                            //     $scope.viewConfig.showAddEducationPostDoctoralForm = false;
                            // }

                            $scope.documentUpload.LinkId = response.IndividualEducation[0].IndividualEducationId;
                            $scope.viewConfig.showAddEducationForm = false;
                            if (typeof $scope.saveAllDocument == "function") {
                                $scope.saveAllDocument().then(function () {
                                    $scope.individualEducationBYIndividualId($scope.IndividualId);
                                    HideLoader();
                                }, function () { });
                            } else {
                                $scope.individualEducationBYIndividualId($scope.IndividualId);
                                HideLoader();
                            }

                        }
                        HideLoader();
                    });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        }
        $scope.editEducation = function () {
            $scope.isEducationEdit = true;
        }

        $scope.deleteEducation = function (educationOption, index) {
            try {
                if (confirm(Messages.General.Confirm.Delete)) {
                    $rootScope.individualEventLog('deleteStatus.deleteConfirmed', $scope.IndividualId);
                    ShowLoader("Please Wait...");
                    var individualEducationResponse = {

                    };

                    educationOption.IsDeleted = true;
                    individualEducationResponse.IndividualEducationResponse = [educationOption]

                    backofficeIndividualEducationService.individualEducationSave(educationOption.IndividualId,
                        (educationOption.ApplicationId ? educationOption.ApplicationId : 0), sessionStorage.UserID, [individualEducationResponse], sessionStorage.Key)
                        .then(function (response) {
                            if (response.Status && response.StatusCode == "00") {
                                // if (formNumber == 1) {
                                $scope.viewConfig.showAddEducationDoctoralForm = false;
                                // } else if (formNumber == 2) {
                                //     $scope.viewConfig.showAddEducationPostDoctoralForm = false;
                                // }
                                $scope.individualEducationBYIndividualId($scope.IndividualId);
                            }
                            HideLoader();
                        });
                }
                else {
                    $rootScope.individualEventLog('deleteStatus.deleteCanceled', $scope.IndividualId);
                }
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        } 
    }
})();
