(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationWrittenExamController", InitialApplicationWrittenExamController);

    InitialApplicationWrittenExamController.$inject = ["$rootScope", "$scope", "$q", "utilityService", "initialWrittenExamService", "countryStateService"];

    function InitialApplicationWrittenExamController($rootScope, $scope, $q, utilityService, initialWrittenExamService, countryStateService) {

        $scope.ncess = {};
        $scope.datePicker = {
            dateOpened: false,
            format: 'M!/d!/yyyy'
        };


        function AuditVisitInfoSave() {
            var data = {
                "PageName": $scope.tab.text,
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };
        $scope.getStateByCountryID = function (CountryID) {
            return countryStateService.getStateByCountryID(sessionStorage.Key, CountryID);
        };

        $scope.init = function () {
            //   console.log($rootScope.isFormValidate);
            ShowLoader("Please wait...");
            $scope.isConfigLoaded = true;
            $scope.getStateByCountryID($rootScope.DefaultCountryId).then(function (response) {
                if (response.Status) {
                    $scope.StatList = response.State;
                }
                HideLoader();
            }, function (error) {
                HideLoader();
            });

            initialWrittenExamService.getIndividualExamBYIndividualId(sessionStorage.Key, $scope.individualId, $scope.applicationId).then(function (res) {
                // console.log(res.lstIndividualExam);
                if (res.Status) {
                    if (res.lstIndividualExam) {
                        $scope.exam = res.lstIndividualExam[0];
                    }
                    else {
                        $scope.exam = {
                            ApplicationId: $scope.applicationId,
                            IndividualId: $scope.individualId,
                            IsActive: true,
                            IsDeleted: false
                        };
                    }
                }
            }, function (error) {

            });
        }

        $scope.init();

        $scope.saveExam = function (currentForm) {
            ShowLoader("Please wait...");
            if (!validateForm(currentForm)) {
                HideLoader();
                return;
            }

            // $scope.ncess.IndividualId = $scope.individualId;
            // $scope.ncess.ApplicationId = $scope.applicationId;
            // $scope.ncess.IsRecordBookOnFile = $scope.ncess.IsRecordBookOnFile;
            // $scope.ncess.RecordBookNumber = $scope.ncess.IsRecordBookOnFile ? $scope.ncess.RecordBookNumber : "";
            // $scope.ncess.IsActive = true;
            // $scope.ncess.IsDeleted = false;
            initialWrittenExamService.saveIndividualExam([$scope.exam], sessionStorage.Key).then(function (response) {
                // console.log(response);
                HideLoader();
                $scope.ncess.IsRecordBookOnFile;
                $scope.openNextTab({ currentTab: $scope.tab });
            }, function (error) {
                HideLoader();
            });
        }

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        var validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            if (!currentForm.errorMessages)
                currentForm.errorMessages = [];
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);


            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        AuditVisitInfoSave();
    }
})();
