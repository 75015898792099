(function () {
  'use strict';

  angular
    .module('app.InitialApplication')
    .controller("InitialApplicationLicenseHistoryController", InitialApplicationLicenseHistoryController);

  InitialApplicationLicenseHistoryController.$inject = ["$rootScope", "$scope", "$q",
    "initialApplicationLicenseHistoryService", "initialApplicationLegalInfoService",
    "initialApplicationstatusService", "utilityService", "countryStateService", "typeValuesService", "WebApiUrl", "AppConfig", "TypeValue", "contentService", "multipleFileUploadService", "initialApplicationEducationService", "Messages"];

  function InitialApplicationLicenseHistoryController($rootScope, $scope, $q,
    initialApplicationLicenseHistoryService, initialApplicationLegalInfoService,
    initialApplicationstatusService, utilityService, countryStateService, typeValuesService, WebApiUrl, AppConfig, TypeValue, contentService, multipleFileUploadService, initialApplicationEducationService, Messages) {

    if (AppConfig.EnableConsoleLogging)
      console.log('Verification : ', $scope.verification);

    var legalInfoOptionIndex = 0;
    $scope.loadIndividualLegalAddInfo = function (legalInfoOption) {
      initialApplicationLegalInfoService.loadIndividualLegalAddInfo($scope.individualId, $scope.applicationId, $scope.userId, legalInfoOption, sessionStorage.Key)
        .then(function (response) {
          if (!!response && !!response.Status) {
            if (!!response.ConvictionFromDate && !angular.isDate(response.ConvictionFromDate))
              response.ConvictionFromDate = new Date(response.ConvictionFromDate);
            if (!!response.ConvictionToDate && !angular.isDate(response.ConvictionToDate))
              response.ConvictionToDate = new Date(response.ConvictionToDate);
            legalInfoOption.individualLegalAddInfo = response;
          } else {
            legalInfoOption.individualLegalAddInfo = {
              IsActive: true,
              IndividualLegalId: legalInfoOption.IndividualLegalId,
              IndividualId: $scope.individualId,
              ApplicationId: $scope.applicationId
            }
          }

        }, function (data) {
          ShowErrMsg();
        });
    };

    $scope.datePicker = {
      fromDateOpened: false,
      toDateOpened: false,
      format: "M!/d!/yyyy",
      dateOptions: {
        ngModelOptions: {
          allowInvalid: true
        }
      }
    };

    $scope.getLegalInfoConfig = function () {
      var relativeFilePath = "/individual/application/legal-info/legal-info.server-config.json";
      return utilityService.getConfigData(relativeFilePath);
    };

    $scope.getValidationMessage = function (validationMessage, legalInfoNumber) {
      if (!!validationMessage)
        validationMessage = validationMessage.replace("{{legalInfoNumber}}", legalInfoNumber);
      return validationMessage;
    };

    $scope.setForm = function (currentForm) {
      $scope.currentForm = currentForm;
    };

    // var validateForm1 = function (currentForm) {
    //     currentForm.$submitted = true;
    //     $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

    //     if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
    //         $scope.natValidation.showError = true;
    //         $scope.natValidation.isValid = false;
    //         return false;
    //     } else {
    //         $scope.natValidation.showError = false;
    //         $scope.natValidation.isValid = true;
    //         return true;
    //     }

    // };

    var checkPropertyExists = function (list, prop) {
      var res = false;
      if (list) {
        for (var i = 0; i < list.length; i++) {
          if (list[i].propName == prop)
            return true;
        }
      }
      return res;
    };

    var ShowErrMsg = function () {
      var errorObj = {
        propName: "legalInfoErr",
        errorMessage: $scope.legalInfoConfig.ValidationMsg.APIErr
      };
      if ($scope.natValidation.errorMessages == undefined)
        $scope.natValidation.errorMessages = [];
      if (!checkPropertyExists($scope.natValidation.errorMessages, 'legalInfoErr'))
        $scope.natValidation.errorMessages.push(errorObj);
    };

    $scope.legalInfoSave = function (currentForm) {
      ShowLoader("Please wait...");
      if (!validateForm(null, currentForm)) {
        if ($scope.isTabStructure) {
          HideLoader();
          initialApplicationstatusService.setLegal('invalid');
        } else {
          initialApplicationstatusService.setLegal('invalid');
        }
        //  utilityService.scrollToElement("newApplicationForm");
        return false;
      }

      if (!$scope.isRenewal)
        $scope.$broadcast("SaveDeficiency", {});
      //      $scope.$broadcast("SaveDeficiency", {});
      if ($scope.legalInfo != null) {
        var dataSavePromises = [];
        angular.forEach($scope.legalInfo, function (legalInfoOption, key) {
          legalInfoOption.ApplicationId = $scope.applicationId;
          if ($scope.isRenewal)
            legalInfoOption.IndividualLicenseId = $scope.individualLicense.IndividualLicenseId;
          //dataSavePromises.push(initialApplicationLegalInfoService.legalInfoSave(legalInfoOption, sessionStorage.Key));
        });
        // $scope.setLegalStatus($scope.legalInfo);

        dataSavePromises.push(initialApplicationLegalInfoService.legalInfoSave($scope.legalInfo, sessionStorage.Key));
        angular.forEach($scope.legalInfo, function (legalInfoOption, key) {
          if (!!legalInfoOption.ContentItemResponse) {
            dataSavePromises.push(
              initialApplicationLegalInfoService.saveIndividualLegalAddInfo($scope.individualId, $scope.applicationId, $scope.userId, legalInfoOption.individualLegalAddInfo, sessionStorage.Key)
            );
          }
        });
        var isSavedSuccessFully = true;
        $q.all(dataSavePromises)
          .then(function (response) {
            angular.forEach(response, function (legalInfotData, key) {
              if (legalInfotData.Status == false) {
                isSavedSuccessFully = false;
              }
            });
            $scope.isSavedSuccessFully = isSavedSuccessFully;
            if (isSavedSuccessFully) {
              if ($scope.isTabStructure) {
                $scope.openNextTab({
                  currentTab: $scope.tab
                });
                initialApplicationstatusService.setLegal("success");
              } else {
                initialApplicationstatusService.setLegal("success");
              }
            } else
              initialApplicationstatusService.setLegal("error");

            if (!$rootScope.ProcessLoaderExternally)
              HideLoader();
          }, function (data) {
            initialApplicationstatusService.setLegal("error");
            $scope.isSavedSuccessFully = false;
            if (data != null) {
              ShowErrMsg();
            }
            if (!$rootScope.ProcessLoaderExternally)
              HideLoader();
          });
        return true;
      }
    };

    function AuditVisitInfoSave() {
      var data = {
        "PageName": $scope.verificationConfig.Content.TabHeading,
        "SessionId": sessionStorage.Key,
        "IndividualId": $scope.individualId,
        "EntityId": 0,
        "UserId": sessionStorage.UserId,
        "Username": "",
        "RequestUrlReferrer": document.referrer
      };
      utilityService.AuditVisitInfo(data);
    };

    $scope.getLegalInfoConfig = function () {
      var relativeFilePath = "/individual/application/legal-info/legal-info.server-config.json";
      return utilityService.getConfigData(relativeFilePath);
    };

    $scope.processDocAndAdditionalInfo = function () {
      var isAffirmativeAction = false;
      var docCodesByContentItemLkId = {
        ContentItem1556: {
          DocId: 17,
          DocCode: 'D1017'
        },
        ContentItem1557: {
          DocId: 18,
          DocCode: 'D1018'
        },
        ContentItem1566: {
          DocId: 19,
          DocCode: 'D1019'
        }
      };
      angular.forEach($scope.legalInfo, function (legalInfoOption, key) {
        legalInfoOption.ContentItemResponseValue = legalInfoOption.ContentItemResponse;
        if (legalInfoOption.ContentItemResponse == true) {
          isAffirmativeAction = true;
        }
        legalInfoOption.documentUpload = {
          ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
          ApplicationId: $scope.applicationId,
          IndividualId: $scope.individualId,
          // Email: null,
          // SendEmail: null,
          // TransactionId: null,
          LicenseeReprint: false,
          DocumentLkToPageTabSectionId: 1,
          DocumentLkToPageTabSectionCode: "FR",
          LoadByDocIdAndDocCode: true,
          DocId: (docCodesByContentItemLkId.hasOwnProperty('ContentItem' + legalInfoOption.ContentItemLkId)) ? docCodesByContentItemLkId['ContentItem' + legalInfoOption.ContentItemLkId].DocId : 2,
          DocCode: (docCodesByContentItemLkId.hasOwnProperty('ContentItem' + legalInfoOption.ContentItemLkId)) ? docCodesByContentItemLkId['ContentItem' + legalInfoOption.ContentItemLkId].DocCode : 'D1005',// docCodesByContentItemLkId['ContentItem' + legalInfoOption.ContentItemLkId].DocCode,
          LoadDocumentsWithoutBroadCast: true
          // DocumentUploadedForId: (($rootScope.routeData && !!$rootScope.routeData.documentUploadedForId) ? $rootScope.routeData.documentUploadedForId : 1)
        };
        if (!!legalInfoOption.ContentItemResponse) {
          $scope.loadIndividualLegalAddInfo(legalInfoOption, false);
        } else {
          legalInfoOption.individualLegalAddInfo = {
            IsActive: true,
            IndividualLegalId: legalInfoOption.IndividualLegalId,
            IndividualId: $scope.individualId,
            ApplicationId: $scope.applicationId
          }
        }
      });
      if ($scope.legalInfo != null)
        $scope.legalInfo.isAffirmativeAction = isAffirmativeAction;
    };

    $scope.init = function () {
      try {
        $scope.todaysDate = new Date();
        $scope.todaysDate.setHours(0, 0, 0, 0);
        $scope.viewConfig = {};

        $scope.documentUploadOutOfState = {
          ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
          ApplicationId: $scope.applicationId,
          IndividualId: $scope.individualId,
          Email: null,
          SendEmail: null,
          TransactionId: null,
          LicenseeReprint: false,
          DocumentLkToPageTabSectionId: 1,
          DocumentLkToPageTabSectionCode: "R",
          LoadByDocIdAndDocCode: true,
          DocId: 2,
          DocCode: "D1002",
          LoadDocumentsWithoutBroadCast: true
        };
        //getContent();
        loadInitialData();
        $scope.getLegalInfoConfig()
          .then(function (data) {
            $scope.legalInfoConfig = data;
            $scope.isConfigLoaded = true;

          });

      } catch (ex) {
        HideLoader();
        $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
        // $exceptionHandler(ex);
      }
    };

    //Out Of State Licensure Experience Starts here
    $scope.setOutOfStateLicForm = function (outOfStateLicForm) {
      $scope.outOfStateLicForm = outOfStateLicForm;
    };

    $scope.closeAddEditOutOfStateLic = function () {
      $scope.viewConfig.showAddOutOfStateLicBlock = false;
      $scope.outOfStateLicForm = null;
    };

    $scope.openAddEditOutOfStateLic = function (isEdit, individualOutOfStateLic) {
      $scope.isOutOfStateLicEdit = isEdit;
      $scope.viewConfig.showAddOutOfStateLicBlock = true;
      if (!$scope.isOutOfStateLicEdit) {
        $scope.individualOutOfStateLic = {
          IndividualId: $scope.individualId,
          IsActive: true,
          UseId: sessionStorage.UserID,
          ApplicationId: $scope.applicationId
        };
      } else {
        $scope.individualOutOfStateLic = angular.copy(individualOutOfStateLic);
      }
      if (!$scope.individualOutOfStateLic.ReferenceNumber || $scope.individualOutOfStateLic.individualOutOfStateLic == '') {
        $scope.individualOutOfStateLic.ReferenceNumber = (new Date().valueOf()).toString();
      }
    };

    $scope.getOutOfStateLicInfo = function () {
      initialApplicationLicenseHistoryService.getIndividualPreviousLicense(sessionStorage.Key, $scope.individualId, $scope.applicationId, $scope.userId)
        .then(function (response) {
          if (response.Status) {
            $scope.individualOutOfStateLicList = response.List;
            angular.forEach($scope.individualOutOfStateLicList, function (individualOutOfStateLicItem) {
              angular.forEach($scope.licenseStatusList, function (licenseStatusItem) {
                if (licenseStatusItem.LicenseStatusTypeId == individualOutOfStateLicItem.LicenseStatusTypeId) {
                  individualOutOfStateLicItem.LicenseStatusTypeName = licenseStatusItem.LicenseStatusTypeName;
                }
              });
              angular.forEach($scope.licenseTypeList, function (licenseTypeItem) {
                if (licenseTypeItem.LicenseTypeId == individualOutOfStateLicItem.LicenseTypeId) {
                  individualOutOfStateLicItem.LicenseTypeName = licenseTypeItem.LicenseTypeName;
                }
              });

              processIndividualBoardActionAndFields();

            });
          } else {
            ShowErrMsg("experienceNotLoaded", response.Message);
          }
          HideLoader();
        }, function (error) {
          HideLoader();
          ShowErrMsg("experienceNotLoaded", error.Message);
        });
    };

    $scope.saveOutOfStateLic = function (outOfStateLicForm) {
      try {
        if (!validateForm(outOfStateLicForm)) {
          return;
        };
        ShowLoader("Please Wait...");
        angular.forEach($scope.statList, function (stateItem) {
          if (stateItem.StateCode == $scope.individualOutOfStateLic.StateCode) {
            $scope.individualOutOfStateLic.StateName = stateItem.Name;
          }
        });

        initialApplicationLicenseHistoryService.saveIndividualPreviousLicense($scope.individualOutOfStateLic, $scope.individualId, $scope.applicationId, $scope.userId, sessionStorage.Key)
          .then(function (response) {
            if (response.Status && response.StatusCode == "00") {

              $scope.documentUploadNewApp.LinkId = response.RecordId;
              if (typeof $scope.saveAllDocument == "function") {
                $scope.saveAllDocument().then(function () {
                  $scope.getOutOfStateLicInfo();
                  HideLoader();
                }, function () { });
              } else {
                $scope.getOutOfStateLicInfo();
                HideLoader();
              }

              $scope.viewConfig.showAddOutOfStateLicBlock = false;
            } else {
              HideLoader();
              ShowErrMsg("SaveIndividualOutOfStateLic", response.Message);
            }
          });
      } catch (ex) {
        HideLoader();
        ShowErrMsg("SaveIndividualOutOfStateLic", ex.message);
      }
    };

    $scope.deleteOutOfStateLic = function (individualOutOfStateLic) {
      try {
        if (confirm(Messages.General.Confirm.DeleteRecord)) {
          ShowLoader();
          individualOutOfStateLic.IsDeleted = true;
          initialApplicationLicenseHistoryService.saveIndividualPreviousLicense(individualOutOfStateLic, $scope.individualId, $scope.applicationId, $scope.userId, sessionStorage.Key)
            .then(function (response) {
              if (response.Status && response.StatusCode == "00") {
                $scope.getOutOfStateLicInfo();
              }
              HideLoader();
            }, function (data) {
              HideLoader();
              ShowErrMsg();
            });
        }
      } catch (ex) {
        $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
      }
    };
    //Out Of State Licensure Experience Ends here

    //Teaching Experience Starts here
    $scope.loadCountyByState = function (stateCode, processLoaderExternally) {
      if (!processLoaderExternally)
        ShowLoader();
      var dataPromise = initialApplicationLicenseHistoryService.countyGetAll(sessionStorage.Key, stateCode);
      dataPromise.then(function (response) {
        if (response.Status) {
          $scope.countyList = response.County;
        } else {
          ShowErrMsg("countyGetAll", response.Message);
        }
        if (!processLoaderExternally)
          HideLoader();
      }, function (error) {
        HideLoader();
        ShowErrMsg("countyGetAll", error.Message);
      });
      return dataPromise;
    };

    $scope.loadDistrictByState = function (stateCode, countyCode, processLoaderExternally) {
      if (!processLoaderExternally)
        ShowLoader();
      var params = "?CountyCode=" + countyCode + "&AddressState=" + stateCode;
      var dataPromise = typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefDistrictGet, sessionStorage.Key, true, params);
      dataPromise.then(function (response) {
        if (response.Status) {
          $scope.districtList = response.RefDistrictList;
        } else {
          ShowErrMsg("refDistrictGet", response.Message);
        }
        if (!processLoaderExternally)
          HideLoader();
      }, function (error) {
        HideLoader();
        ShowErrMsg("refDistrictGet", error.Message);
      });
      return dataPromise;
    };

    $scope.loadSchoolByDistrict = function (countyCode, districtCode, processLoaderExternally) {
      if (!processLoaderExternally)
        ShowLoader();
      var params = "?CountyCode=" + countyCode + "&DistrictCode=" + districtCode;
      var dataPromise = typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefSchoolGet, sessionStorage.Key, true, params);
      dataPromise.then(function (response) {
        if (response.Status) {
          $scope.schoolList = response.RefSchoolList;
        } else {
          ShowErrMsg("refSchoolGet", response.Message);
        }
        if (!processLoaderExternally)
          HideLoader();
      }, function (error) {
        HideLoader();
        ShowErrMsg("refSchoolGet", error.Message);
      });
      return dataPromise;
    };

    var loadDropDownsData = function (individualExperience) {
      ShowLoader();
      var dataLoadPromises = [];

      if (!!individualExperience.StateCd) {
        dataLoadPromises.push($scope.loadCountyByState(individualExperience.StateCd, true));
        if (!!individualExperience.CountyCd) {
          dataLoadPromises.push($scope.loadDistrictByState(individualExperience.StateCd, individualExperience.CountyCd, true));
          if (!!individualExperience.DistrictCd)
            dataLoadPromises.push($scope.loadSchoolByDistrict(individualExperience.CountyCd, individualExperience.DistrictCd, true));
        }
      }

      if (dataLoadPromises.length > 0) {
        $q.all(dataLoadPromises).then(function (response) {
          $scope.viewConfig.showAddExperienceBlock = true;
          HideLoader();
        }, function (error) {
          HideLoader();
        });
      } else {
        $scope.viewConfig.showAddExperienceBlock = true;
        HideLoader();
      }
    };




    $scope.loadTypeahed = function (searchString) {
      return initialApplicationLicenseHistoryService.getEmployerList(
        sessionStorage.Key, searchString, 1, 'Name'
      ).then(function (response) {
        if (!!response.Employers) {
          return response.Employers.map(function (item) {
            item.formattedResult = (!!item.EmployerName ? item.EmployerName : '');             // item.formattedResult = ((!!item.EmployerName ? item.EmployerName : '')
            return item;
          });
        }
      }, function (res) {

      });
    };


    var loadInitialData = function () {
      var contentId = 183;
      if ($scope.applicationInfo.LicenseApplicationForId == 1) {
        contentId = 183;
      }
      if ($scope.applicationInfo.LicenseApplicationForId == 2) {
        contentId = 183;
      }
      if ($scope.applicationInfo.LicenseApplicationForId == 3) {
        contentId = 194;
      }
      if ($scope.applicationInfo.LicenseApplicationForId == 4) {
        contentId = 194;
      }
      var dataLoadPromises = [
        countryStateService.getStateByCountryID(sessionStorage.Key, $rootScope.DefaultCountryId),
        initialApplicationLicenseHistoryService.getRefTablesGetAll(sessionStorage.Key, 'otherlicensestatus'),
        // typeValuesService.getTypeValuesByTypeName(TypeValue.License, sessionStorage.Key),
        initialApplicationLicenseHistoryService.getIndividualPreviousLicense(sessionStorage.Key, $scope.individualId, $scope.applicationId, $scope.userId),
        initialApplicationLicenseHistoryService.getRefTablesGetAll(sessionStorage.Key, 'otherlicensetype'),
        contentService.getContentByContentItemLkIdList([contentId], sessionStorage.Key)
      ];
      $q.all(dataLoadPromises).then(function (response) {
        console.log(response);
        $scope.isConfigLoaded = true;
        $scope.statList = response[0].State;
        $scope.licenseStatusList = response[1].ReferenceEntities;//response[1].LicenseStatusTypeGetList;
        // $scope.licenseTypeList = response[2].LicenseTypeGetList;
        $scope.individualOutOfStateLicList = response[2].List;
        $scope.licenseTypeList = response[3].ReferenceEntities;
        if (response[4].ContentItemLk)
          $scope.content = response[4].ContentItemLk[0];
        $scope.getOutOfStateLicInfo();
        HideLoader();
      },
        function (error) {
          HideLoader();
          ShowErrMsg("licenseEndorsementConfigNotLoaded", "Some error occurred,Unable to load the License/Endorsement config");
        });
    };

    var ShowErrMsg = function (propName, errorMessage) {
      propName = (!!propName) ? propName : "personalErr";
      errorMessage = (!!errorMessage) ? errorMessage : $scope.personalConfig.ValidationMsg.APIError;
      if ($scope.natValidation.errorMessages == undefined)
        $scope.natValidation.errorMessages = [];
      var propExist = false;
      for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
        if ($scope.natValidation.errorMessages[i].propName == propName)
          propExist = true;
      }
      if (!propExist)
        $scope.natValidation.errorMessages.push({
          propName: propName,
          errorMessage: errorMessage
        });

      if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
        $scope.natValidation.showError = true;
        $scope.natValidation.isValid = false;
        return false;
      } else {
        $scope.natValidation.showError = false;
        $scope.natValidation.isValid = true;
        return true;
      }
    };

    // $scope.setForm = function (outOfStateLicForm) {
    //     $scope.outOfStateLicForm = outOfStateLicForm;
    // };

    var validateForm = function (outOfStateLicForm, currentForm) {
      $scope.natValidation.errorMessages = [];
      if (!!outOfStateLicForm) {
        if ($scope.individualOutOfStateLic) {
          if (!!outOfStateLicForm) {
            outOfStateLicForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(outOfStateLicForm.errorMessages);
          }
          if (($scope.individualOutOfStateLic.LicenseStatusTypeId == 2) && (new Date($scope.individualOutOfStateLic.LicenseExpirationDate) >= $scope.todaysDate)) {
            $scope.natValidation.errorMessages.push({
              propName: 'LicenseDatePrev',
              errorMessage: Messages.General.Errors.LicenseExpireDate
            });
          }
        }
      }
      if (!!currentForm) {
        currentForm.$submitted = true;
        $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
      }
      // if (!outOfStateLicForm) {
      //     if (($scope.individualOutOfStateLicList || $scope.individualOutOfStateLicList.length < 1)) {
      //         $scope.natValidation.errorMessages.push({
      //             propName: 'LicenseDssPrev',
      //             errorMessage: Messages.fdbecea
      //         });
      //     }
      // }

      // angular.forEach($scope.legalInfo, function (legalInfoOption, key) {
      //     if (legalInfoOption.ContentItemResponse) {
      //         if (legalInfoOption.documentUpload.documentCount < 1) {
      //             $scope.natValidation.errorMessages.push({
      //                 propName: 'AtLeastOneDEANUmberReq' + legalInfoOption.documentUpload.DocId,
      //                 errorMessage: Messages.dfcffdf + legalInfoOption.Index
      //             });
      //         }
      //     }
      //     legalInfoOption.showValidation = true;
      // });

      if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
        $scope.natValidation.showError = true;
        $scope.natValidation.isValid = false;
        return false;
      } else {
        $scope.natValidation.showError = false;
        $scope.natValidation.isValid = true;
        return true;
      }
    }


    $scope.saveAndNext = function (isOutOfStateLic) {
      if (!isOutOfStateLic)
        isOutOfStateLic = $scope.outOfStateLicForm;

      if (!validateForm(isOutOfStateLic)) {
        // utilityService.scrollTopwithValue('tabSectionotherStateLicenses');
        return;
      }
      //if ($scope.legalInfoSave($scope.currentForm)) {

      $scope.$broadcast("SaveDeficiency", {});
      $scope.isOutOfStateLic = isOutOfStateLic;
      // $scope.$broadcast("SaveDeficiency", {});
      // if (!!$scope.isRenewal) {
      //  utilityService.scrollToElement("newApplicationForm");


      $scope.openNextTab({ currentTab: $scope.tab });

      // }

      // }
    };

    $scope.getVerificationConfig = function () {
      var relativeFilePath = "/individual/application/verification/verification.server-config.json";
      return utilityService.getConfigData(relativeFilePath);
    };

    $scope.$on("SaveRenewal", function (event, args) {
      $scope.saveAndNext($scope.outOfStateLicForm);
    });

    $scope.getLegalInfoOptionIndex = function (option) {
      if (option.IsActive)
        legalInfoOptionIndex = legalInfoOptionIndex + 1;
      option.Index = legalInfoOptionIndex;
    };

    var getContent = function () {
      contentService.getContentByContentItemLkIdList([5], sessionStorage.Key)
        .then(function (response) {
          if (response.Status) {
            _.filter(response.ContentItemLk, function (item) {
              if (item.ContentItemLkId == 5)
                $scope.headingContent = item.ContentItemLkDesc;
            });
          }
        }, function (error) {

        });
    }

    var processIndividualBoardActionAndFields = function () {
      if ($scope.individualOutOfStateLicList && $scope.individualOutOfStateLicList.length > 0) {
        multipleFileUploadService.individualDocumentProcess(sessionStorage.Key, $scope.individualId,
          $scope.individualOutOfStateLicList, $scope.documentUploadNewApp.PropertyToProcess, $scope.documentUploadNewApp.LinkType).then(function (responseData) {
            $scope.individualOutOfStateLicList = responseData;
            //console.log(responseData);
            HideLoader();
          });
      }
    };

    $scope.documentByIndividualDocumentId = multipleFileUploadService.documentByIndividualDocumentId;

    $scope.setSaveDocumentRef = function (fn) {
      $scope.saveAllDocument = fn;
    };

    var initDocument = function () {
      $scope.documentUploadNewApp = undefined;
      setTimeout(function () {
        $scope.$apply(function () {
          $scope.documentUploadNewApp = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.individualId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "FR",
            LoadByDocIdAndDocCode: true,
            DocId: 28,
            DocCode: 'D1028',
            LoadDocumentsWithoutBroadCast: false,
            LinkType: 'AL',
            PropertyToProcess: 'IndividualPreviousLicenseId',
            IsLinkedDocControl: true
          };
        });
      }, 1000);
    };
    initDocument();


    var getEntityConfirmation = function () {
      initialApplicationEducationService.getEntityConfirmation(sessionStorage.Key, $scope.individualId, 'I', $scope.applicationId).then(function (response) {
        // debugger
        // console.log(response);
        if (response.EntityConfirmations && response.EntityConfirmations.length > 0) {
          angular.forEach(response.EntityConfirmations, function (item) {
            if (item.ConfirmationTypeId == 4) {
              $scope.confirmationExplaination = item;
            }
          });
        }
        else {
          $scope.confirmationExplaination = {
            EntityTy: 'I',
            EntityId: $scope.individualId,
            ConfirmationTypeId: 4,
            ApplicationId: $scope.applicationId,
            IsActive: true,
            IsDeleted: false
          };
        }
      }, function (error) {

      });
    }

    var saveEntityConfirmation = function () {
      var data = [$scope.confirmationExplaination];
      initialApplicationEducationService.saveEntityConfirmation(data, sessionStorage.Key).then(function (response) {
        getEntityConfirmation();
        console.log(response);
      }, function (error) {

      });
    }

    $scope.init();

  }
})();
