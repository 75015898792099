(function () {
    'use strict'

    angular.module('app.core')
    .controller('LicenseTypesSelectorController', ['$scope', '$rootScope', '$q', 'LicenseTypesSelectorService', 'utilityService', LicenseTypesSelectorController]);

    function LicenseTypesSelectorController($scope, $rootScope, $q, LicenseTypesSelectorService, utilityService) {
        var _key = sessionStorage.Key;
        var _childName = '';

        $scope.getLicensseTypeStyle = function () {
            if ($scope.allLicenseTypes.any('item.isSelected')) {
                return {
                    "background-color": "#d9edf7",
                    "color": "#31708f"
                };
            }
            return {};
        }

        $scope.$watch('dataModel.$modelValue', function (newVal) {
            if (!!newVal) {
                var licenseTypeIds = newVal.split(',').select(function (ltId) { return parseInt(ltId); });

                $scope.allLicenseTypes.whereIncludes(licenseTypeIds, 'LicenseTypeId').forEach(function (lt) {
                    lt.isSelected = true;
                });
            } else {
                $scope.allLicenseTypes.forEach(function (lt) {
                    lt.isSelected = false;
                });
            }
        });

        $scope.updateModel = function () {
            $scope.dataModel.$setViewValue($scope.allLicenseTypes.orderBy('LicenseTypeId').where('!!item.isSelected').select('LicenseTypeId').join(','));
        };

        $scope.init = function () {
            $scope.allLicenseTypes = [];
        };
    }
})();