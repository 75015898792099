﻿(function () {
    'use strict'
    angular.module('app.core').factory('contactDirectiveService', ['$http', 'WebApiUrl', function ($http, WebApiUrl) {
        var BaseUrl = WebApiUrl;
        var BaseFactory = {};
        BaseFactory.GetContactDetails = function (key, individualId) {
            return $http.get(BaseUrl + 'Individual/IndividualContactBYIndividualId/' + key + '?IndividualId=' + individualId);
        };
        BaseFactory.SaveContactDetails = function (urlParam, data) {
            var ApiPath = 'Individual/IndividualContactSave' + urlParam;
            return $http.post(BaseUrl + ApiPath, data);
        };
        BaseFactory.ContactTypeGetAll = function (key) {
            return $http.get(BaseUrl + 'TypeValues/ContactTypeGetAll/' + key);
        };
        return BaseFactory;
    }]);
})();