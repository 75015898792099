﻿(function () {
    'use strict'
    angular.module('natApp')
      .service('BEAdditionalwyomingService', ResponsibleInChargeService);

    ResponsibleInChargeService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig'];

    function ResponsibleInChargeService($http, $q, WebApiUrl, AppConfig) {
        var baseFactory = {};
        baseFactory.ProviderAddressSave = function (Key, data) {
            return returnDefferedResult($http.post(WebApiUrl + "Provider/ProviderAddressSave/" + Key, data));
        };
        baseFactory.ProviderAddressByProviderId = function (key, providerid) {
            return returnDefferedResult($http.get(WebApiUrl + 'Provider/ProviderAddressByProviderId/' + key + '?ProviderId=' + providerid));
        };
        baseFactory.GetProviderContactByProviderId = function (Key, ID) {
            return returnDefferedResult($http.get(WebApiUrl + "Provider/ProviderContactByProviderId/" + Key + "/?ProviderId=" + ID));
        };
        baseFactory.ProviderContactSave = function (Key, data) {
            return returnDefferedResult($http.post(WebApiUrl + "Provider/ProviderContactsSave/" + Key, data));
        };
        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        return baseFactory;
    };
})();