﻿(function() {
    'use strict'
    angular.module('app.core')
        .directive('providerPersonInfo', [function() {
            return {
                restrict: 'EA',
                scope: {
                    providerId: '=providerId',
                    iseditable: '=iseditable'
                },
                controller: "ProviderPersonInfoController",
                templateUrl: "app/core/directive/provider-person-info/provider-person-info.html"
            };
        }]);
})();