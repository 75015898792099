(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("PersonalController", PersonalController);

    PersonalController.$inject = ["$rootScope", "$scope", "$q",
        "personalService", "utilityService", "WebApiUrl",
        "backofficeIndividualPersonalService", "backofficeIndividualAdressService", "RegisterFactory", "LoginwemailService",
        "initialApplicationstatusService", "initialApplicationEmploymentService", "$exceptionHandler", "countryStateService",
        "lookupService", "multipleFileUploadService", "dataChangeLogService", "LookUpType", "$filter", "InlumonContentService", "individualInfoService", "Messages",
        "typeValuesService", "TypeValue", "initialApplicationNcessService", "initialApplicationVeteranInfoService", "licenseApplicationListingService"
    ];

    function PersonalController($rootScope, $scope, $q,
        personalService, utilityService, WebApiUrl, backofficeIndividualPersonalService, backofficeIndividualAdressService, RegisterFactory, LoginwemailService,
        initialApplicationstatusService, initialApplicationEmploymentService, $exceptionHandler, countryStateService, lookupService, multipleFileUploadService,
        dataChangeLogService, LookUpType, $filter, InlumonContentService, individualInfoService, Messages, typeValuesService, TypeValue, initialApplicationNcessService,
        initialApplicationVeteranInfoService, licenseApplicationListingService) {
        if (!$scope.isRenewal) {
            $scope.personal = {};
            // $scope.personalData = {};
        }
        $scope.datePicker = {
            dateOpened: false,
            format: 'M!/d!/yyyy'
        };

        $scope.blurSSN = false;
        $scope.blurDOB = false;

        $scope.maskConfig = {
            clearOnBlur: false,
            allowInvalidValue: true,
            clearOnBlurPlaceholder: false
        };
        if (!$scope.isRenewal)
            $scope.personal.selectedDiscipline = "";
        // $scope.disciplineList = [];
        $scope.documentUploadCitizen = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.individualId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "R",
            LoadByDocIdAndDocCode: true,
            DocId: 10,
            DocCode: "D1010",
            LoadDocumentsWithoutBroadCast: true
        };

        $scope.documentUploadCitizen2 = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.individualId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "R",
            LoadByDocIdAndDocCode: true,
            DocId: 25,
            DocCode: "D1025",
            LoadDocumentsWithoutBroadCast: true
        };
        // }
        $scope.addDiscipline = function () {
            // if ($scope.personal.selectedDiscipline)
            //     $scope.disciplineList.push($scope.personal.selectedDiscipline);
        }

        $scope.deleteDiscipline = function (discipline) {
            _.remove($scope.disciplineList, function (value) {
                return discipline == value;
            });
        }

        $scope.getPersonalConfig = function () {
            var relativeFilePath = "/individual/application/personal/personal.server-config.json";
            return utilityService.getConfigData(relativeFilePath);
        };

        $scope.setVisibilityOfAddForm = function (personalForm, showForm) {
            personalForm.$setPristine();
            if ($scope.viewConfig == undefined)
                $scope.viewConfig = {};
            if (showForm)
                $scope.personal = {};
            $scope.viewConfig.showAddForm = showForm;
            $scope.natValidation.showError = false;
        };

        var createNewAddresses = function () {
            if (!$scope.personalData.EmployerAddress) {
                $scope.personalData.EmployerAddress = {
                    IndividualId: $scope.IndividualId,
                    AddressTypeId: 12,
                    IsActive: true,
                    StreetLine1: '',
                    StreetLine2: '',
                    City: '',
                    StateCode: 'CA',
                    CountryId: $rootScope.DefaultCountryId,
                    Zip: '',
                    IsMailingSameasPhysical: false,
                    AdressStatusId: 1,
                    BeginDate: new Date()
                };
            }
            if (!$scope.personalData.ResidentialAddress) {
                $scope.personalData.ResidentialAddress = {
                    IndividualId: $scope.IndividualId,
                    AddressTypeId: 1,
                    IsActive: true,
                    StreetLine1: '',
                    StreetLine2: '',
                    City: '',
                    StateCode: 'CA',
                    CountryId: $rootScope.DefaultCountryId,
                    Zip: '',
                    IsMailingSameasPhysical: true,
                    AdressStatusId: 1,
                    BeginDate: new Date()
                };
                $scope.loadZipRange('CA');
            }
            if (!$scope.isRenewal && !$scope.personalData.MaillingAddress) {
                $scope.personalData.MaillingAddress = {
                    IndividualId: $scope.IndividualId,
                    AddressTypeId: 2,
                    IsActive: true,
                    CountryId: $rootScope.DefaultCountryId,
                    AdressStatusId: 1,
                    BeginDate: new Date()
                }
            }
        };

        var createNewContacts = function () {
            $scope.personalData.CellPhoneContact = getNewContactIfNotExists($scope.personalData.CellPhoneContact, "C", 1);
            $scope.personalData.HomePhoneContact = getNewContactIfNotExists($scope.personalData.HomePhoneContact, "H", 2);
            $scope.personalData.OfficePhoneContact = getNewContactIfNotExists($scope.personalData.OfficePhoneContact, "W", 3);
            $scope.personalData.FaxContact = getNewContactIfNotExists($scope.personalData.FaxContact, "F", 5);
            if (!$scope.personalData.EmailAddressContact) {
                $scope.personalData.EmailAddressContact = getNewContactIfNotExists($scope.personalData.EmailAddressContact, "E", 8);
                $scope.personalData.OriginalEmailAddressContact = angular.copy($scope.personalData.EmailAddressContact);
                if ($scope.personalData.LoginEmailAddressContact) {
                    $scope.personalData.EmailAddressContact.ContactInfo = $scope.personalData.LoginEmailAddressContact.ContactInfo;
                }
            } else {
                $scope.personalData.OriginalEmailAddressContact = angular.copy($scope.personalData.EmailAddressContact);
            }
            $scope.personalData.OfficeEmailAddressContact = getNewContactIfNotExists($scope.personalData.OfficeEmailAddressContact, "OE", 29);
            $scope.personalData.OfficeFaxContact = getNewContactIfNotExists($scope.personalData.OfficeFaxContact, "OF", 30);
        };

        var assignAddressByAddressTypeId = function (restrictCreateNewAddress) {
            var employerAddressExist = false;
            $scope.residentialAddressExist = false;
            var mallingAddress = false;

            angular.forEach($scope.personalAddress, function (addressOption, index) {
                if (!!addressOption.AdressStatusId && addressOption.AdressStatusId == 1 || (!!addressOption.AdressStatusId && !$scope.isRenewal && addressOption.AdressStatusId == 4 || addressOption.AdressStatusId == 1)) {
                    if (addressOption.AddressTypeId == 12) {
                        if (!employerAddressExist) {
                            $scope.personalData.EmployerAddress = addressOption;
                            if (!$scope.isRenewal) {
                                $scope.personalInfo.EmployerName = $scope.personalData.EmployerAddress.Addressee;
                            }
                            if (!!$scope.personalData.EmployerAddress.BeginDate) {
                                $scope.personalData.EmployerAddress.BeginDate = new Date($scope.personalData.EmployerAddress.BeginDate + (($scope.personalData.EmployerAddress.BeginDate.slice(-1) != 'Z') ? 'Z' : ''))
                            }
                            $scope.personalData.EmployerAddress.AdressStatusId = 1;
                        }
                        employerAddressExist = true;
                    } else if (addressOption.AddressTypeId == 1) {
                        if (!$scope.residentialAddressExist) {
                            $scope.personalData.ResidentialAddress = addressOption;
                            if (!!$scope.personalData.ResidentialAddress.BeginDate) {
                                $scope.personalData.ResidentialAddress.BeginDate = new Date($scope.personalData.ResidentialAddress.BeginDate + (($scope.personalData.ResidentialAddress.BeginDate.slice(-1) != 'Z') ? 'Z' : ''))
                            }
                            $scope.personalData.ResidentialAddress.AdressStatusId = 1;
                        }
                        $scope.residentialAddressExist = true;
                    } else if (!$scope.isRenewal && addressOption.AddressTypeId == 2) {
                        if (!mallingAddress) {
                            $scope.personalData.MaillingAddress = addressOption;
                            if (!!$scope.personalData.MaillingAddress.BeginDate) {
                                $scope.personalData.MaillingAddress.BeginDate = new Date($scope.personalData.MaillingAddress.BeginDate + (($scope.personalData.MaillingAddress.BeginDate.slice(-1) != 'Z') ? 'Z' : ''))
                            }
                            if ($scope.personalData.MaillingAddress)
                                $scope.personalData.MaillingAddress.AdressStatusId = 1;
                        }
                        mallingAddress = true;
                    };

                }
            });

            if (!restrictCreateNewAddress) {
                createNewAddresses(employerAddressExist, $scope.residentialAddressExist);
            }
            $scope.personalData.OriginalEmployerAddress = angular.copy($scope.personalData.EmployerAddress);
            $scope.personalData.OriginalResidentialAddress = angular.copy($scope.personalData.ResidentialAddress);

            if (!!$scope.personalData.ResidentialAddress) {
                var countryId = $rootScope.DefaultCountryId;
                if (!!$scope.personalData.ResidentialAddress.CountryId)
                    countryId = $scope.personalData.ResidentialAddress.CountryId;
                else
                    $scope.personalData.ResidentialAddress.CountryId = '';

                $scope.getStateByCountryID(countryId)
                    .then(function (data) {
                        $scope.personalData.ResidentialAddress.StatList = data.State;
                    }, function (error) {
                        HideLoader();
                        $scope.showStatusMessage({
                            messageContent: error.Message,
                            messageType: 'error'
                        });
                    });
            }
            if (!!$scope.personalData.EmployerAddress) {
                var countryId = $rootScope.DefaultCountryId;
                if (!!$scope.personalData.EmployerAddress.CountryId)
                    countryId = $scope.personalData.EmployerAddress.CountryId;
                else
                    $scope.personalData.EmployerAddress.CountryId = '';

                if (!$scope.personalData.EmployerAddress.StateCode) {
                    $scope.personalData.EmployerAddress.StateCode = 'CA';
                }

                $scope.getStateByCountryID(countryId)
                    .then(function (data) {
                        $scope.personalData.EmployerAddress.StatList = data.State;
                    }, function (error) {
                        HideLoader();
                        $scope.showStatusMessage({
                            messageContent: error.Message,
                            messageType: 'error'
                        });
                    });
            }
        };

        var assignContactsByContactTypeId = function (restrictCreateNewContact) {
            var cellPhoneExists = false;
            var homePhoneExists = false;
            var officePhoneExists = false;
            var faxExists = false;
            var emailAddressExists = false;
            var officeEmailExists = false;
            var officeFaxExists = false;
            var loginEmailAddressExists = false;

            var secondaryEmailAddressExists = false;
            angular.forEach($scope.personalContact, function (contactOption, index) {
                if (!!contactOption.ContactStatusId && contactOption.ContactStatusId == 1) {
                    if (contactOption.ContactTypeId == "1" && !cellPhoneExists) {
                        $scope.personalData.CellPhoneContact = contactOption;
                        cellPhoneExists = true;
                    }
                    if (contactOption.ContactTypeId == "2" && !homePhoneExists) {
                        $scope.personalData.HomePhoneContact = contactOption;
                        homePhoneExists = true;
                    }
                    if (contactOption.ContactTypeId == "3" && !officePhoneExists) {
                        $scope.personalData.OfficePhoneContact = contactOption;
                        officePhoneExists = true;
                    }
                    if (contactOption.ContactTypeId == "5" && !faxExists) {
                        $scope.personalData.FaxContact = contactOption;
                        faxExists = true;
                    }
                    if (contactOption.ContactTypeId == "8" && !emailAddressExists) {
                        $scope.personalData.EmailAddressContact = contactOption;
                        emailAddressExists = true;
                    }
                    if (contactOption.ContactTypeId == "29" && !officeEmailExists) {
                        $scope.personalData.OfficeEmailAddressContact = contactOption;
                        officeEmailExists = true;
                    }
                    if (contactOption.ContactTypeId == "30" && !officeFaxExists) {
                        $scope.personalData.OfficeFaxContact = contactOption;
                        officeFaxExists = true;
                    }
                }
                if (contactOption.ContactTypeId == "18" && !loginEmailAddressExists) {
                    $scope.personalData.LoginEmailAddressContact = contactOption;
                    loginEmailAddressExists = true;
                }
            });
            if (!restrictCreateNewContact)
                createNewContacts();

            $scope.personalData.OriginalCellPhoneContact = angular.copy($scope.personalData.CellPhoneContact);
            $scope.personalData.OriginalHomePhoneContact = angular.copy($scope.personalData.HomePhoneContact);
            $scope.personalData.OriginalOfficePhoneContact = angular.copy($scope.personalData.OfficePhoneContact);
            $scope.personalData.OriginalFaxContact = angular.copy($scope.personalData.FaxContact);
            $scope.personalData.OriginalOfficeEmailAddressContact = angular.copy($scope.personalData.OfficeEmailAddressContact);
            $scope.personalData.OriginalOfficeFaxContact = angular.copy($scope.personalData.OfficeFaxContact);
        };

        var getNewContactIfNotExists = function (contactItem, contactCode, contactTypeId) {
            if (!contactItem) {
                contactItem = getNewContact(contactCode, contactTypeId);
            }
            return contactItem;
        };

        var getNewContact = function (contactCode, contactTypeId) {
            var newContact = {
                BeginDate: "2013-08-20T00:00:00",
                Code: contactCode,
                ContactFirstName: "",
                ContactId: 0,
                ContactInfo: "",
                ContactLastName: "",
                ContactMiddleName: "",
                ContactTypeId: contactTypeId,
                DateContactValidated: null,
                EndDate: null,
                IndividualContactId: 0,
                IndividualId: $scope.IndividualId,
                IsActive: true,
                IsMobile: false,
                IsPreferredContact: false,
                ContactStatusId: 1
            }
            return newContact;
        };

        $scope.getIndividual = function () {
            return backofficeIndividualPersonalService.individualBYIndividualId(sessionStorage.Key, $scope.individualId);
        };

        $scope.getIndividualAddress = function () {
            return backofficeIndividualAdressService.individualAddressBYIndividualId(sessionStorage.Key, $scope.individualId);
        };

        $scope.getIndividualContact = function () {
            return backofficeIndividualAdressService.individualContactBYIndividualId(sessionStorage.Key, $scope.individualId);
        };

        $scope.GetPersonalData = function () {
            var dataPromises = [];
            dataPromises.push($scope.getIndividualAddress());
            dataPromises.push($scope.getIndividualContact());
            if (!$scope.hidePersonalInfo)
                dataPromises.push($scope.getIndividual());

            $q.all(dataPromises)
                .then(function (response) {
                    $scope.personalAddress = response[0].IndividualAddressResponse;
                    // console.log($scope.personalAddress);
                    $scope.personalContact = response[1].IndividualContactResponse;
                    if (!$scope.hidePersonalInfo) {
                        $scope.personalInfo = response[2].IndividualResponse[0];
                        //check for SSN and mask
                        if ($scope.personalInfo.SSN && $scope.personalInfo.SSN != '') {
                            $scope.blurSSN = true;
                        }
                    }



                    $scope.getPersonalInfo();
                    $scope.deficiencyDetailChanged = function () {
                        $scope.$emit('personalDataChanged', $scope.currentTab);
                    };
                }, function (error) {
                    HideLoader();
                    $scope.showStatusMessage({
                        messageContent: 'Some error occurred !',
                        messageType: 'error'
                    });
                });
        };

        $scope.getPlaceOfBirthStateByCountryId = function (countryId) {
            ShowLoader();
            $scope.getStateByCountryID(countryId)
                .then(function (data) {
                    $scope.PlaceOfBirthStates = data.State;
                    HideLoader();
                }, function (error) {
                    HideLoader();
                    $scope.showStatusMessage({
                        messageContent: 'Some error occurred !',
                        messageType: 'error'
                    });
                });
        };

        $scope.getAddressStateByCountryID = function (addressChanged, countryId) {
            if (!countryId) {
                countryId = $rootScope.DefaultCountryId;
            }
            ShowLoader();
            $scope.getStateByCountryID(countryId)
                .then(function (data) {
                    addressChanged.StatList = data.State;
                    addressChanged.StateCode = "";
                    HideLoader();
                }, function (error) {
                    HideLoader();
                    $scope.showStatusMessage({
                        messageContent: error.Message,
                        messageType: 'error'
                    });
                });
        };

        //get Personal Info
        $scope.getPersonalInfo = function () {
            try {
                //Individual
                if ($scope.personalInfo != null) {
                    $scope.IndividualId = $scope.personalInfo.IndividualId;
                    $scope.personalInfo.IndividualLicenseExpirationDate = $scope.individualLicense.LicenseExpirationDate;
                    if (!!$scope.individualLicense.PreviousCEDate && $scope.individualLicense.PreviousCEDate.length >= 10) {
                        var year = Number($scope.individualLicense.PreviousCEDate.substring(0, 4));
                        var month = Number($scope.individualLicense.PreviousCEDate.substring(5, 7));
                        var day = Number($scope.individualLicense.PreviousCEDate.substring(8, 10));
                        $scope.individualLicense.PCEDueDate = new Date(year + 6, month - 1, day);
                    }
                    if (!!$scope.personalInfo.DateOfBirth && !angular.isDate($scope.personalInfo.DateOfBirth)) {
                        $scope.personalInfo.DateOfBirth = new Date($scope.personalInfo.DateOfBirth);
                        $scope.blurDOB = true;
                    }
                    if (!$scope.personalInfo.IndividualPlaceofBirthCountry)
                        $scope.personalInfo.IndividualPlaceofBirthCountry = $rootScope.DefaultCountryId.toString();
                    else if (!!$scope.personalInfo.IndividualPlaceofBirthCountry && $scope.personalInfo.IndividualPlaceofBirthCountry != $rootScope.DefaultCountryId)
                        $scope.getPlaceOfBirthStateByCountryId($scope.personalInfo.IndividualPlaceofBirthCountry);
                    $scope.personalInfoOrg = angular.copy($scope.personalInfo);
                }
                $scope.isConfigLoaded = true;
                $scope.$emit('personalDataChanged');

                assignAddressByAddressTypeId();
                assignContactsByContactTypeId();

                HideLoader();
                countryStateService.countryGetAll(sessionStorage.Key).then(function (response) {
                    if (!response.Status) {
                        $scope.showStatusMessage(response.Message, "error");
                    } else {
                        $scope.CountryList = response.Country;
                    }
                }, function (error) {
                    $scope.showStatusMessage(error, "error");
                });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
            }
        };

        $scope.getStateByCountryID = function (CountryID) {
            return countryStateService.getStateByCountryID(sessionStorage.Key, CountryID);
        };

        //Mailing Address is same as the Residential Address
        $scope.makeSameAsPhysicalAddress = function () {
            if ($scope.personalData.ResidentialAddress.IsMailingSameasPhysical) {
                $scope.personalData.MailingAddress.StreetLine1 = $scope.personalData.ResidentialAddress.StreetLine1;
                $scope.personalData.MailingAddress.StreetLine2 = $scope.personalData.ResidentialAddress.StreetLine2;
                $scope.personalData.MailingAddress.City = $scope.personalData.ResidentialAddress.City;
                $scope.personalData.MailingAddress.StateCode = $scope.personalData.ResidentialAddress.StateCode;
                $scope.personalData.MailingAddress.Zip = $scope.personalData.ResidentialAddress.Zip;
            } else {
                $scope.personalData.MailingAddress.StreetLine1 = "";
                $scope.personalData.MailingAddress.StreetLine2 = "";
                $scope.personalData.MailingAddress.City = "";
                $scope.personalData.MailingAddress.StateCode = "";
                $scope.personalData.MailingAddress.Zip = "";
            }
            $scope.addressChanged($scope.personalData.OriginalMailingAddress, $scope.personalData.MailingAddress);
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        var validateForm = function (currentForm) {
            if (!currentForm.errorMessages)
                currentForm.errorMessages = [];
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            // if ($scope.personalData.ResidentialAddress.IsMailingSameasPhysical && $scope.personalData.EmployerAddress && $scope.personalData.EmployerAddress.IsMailingSameasPhysical) {
            //     var errorObj = {
            //         propName: "EitherHomeOrEmployerCanBeMailing",
            //         errorMessage: $scope.personalConfig.ValidationMsg.EitherHomeOrEmployerCanBeMailing
            //     };
            //     $scope.natValidation.errorMessages.push(errorObj);
            // }
            // if (!$scope.personalData.ResidentialAddress.IsMailingSameasPhysical && ($scope.personalData.EmployerAddress && !$scope.personalData.EmployerAddress.IsMailingSameasPhysical)) {
            //     var errorObj = {
            //         propName: "EitherMakeHomeOrEmployerAsMailing",
            //         errorMessage: $scope.personalConfig.ValidationMsg.EitherMakeHomeOrEmployerAsMailing
            //     };
            //     $scope.natValidation.errorMessages.push(errorObj);
            // }
            // // if (!$scope.isRenewal) {
            // if ((!$scope.applicationInfo || $scope.applicationInfo.ApplicationTypeId != 9) && $scope.personalInfo.USCitizen == true) {
            //     if ($scope.documentUploadCitizen.documentCount < 1 || !$scope.documentUploadCitizen.documentCount) {
            //         var errorObj = {
            //             propName: "CitizenshipInfomationDoc",
            //             errorMessage: Messages.fdccebe
            //         };
            //         $scope.natValidation.errorMessages.push(errorObj);
            //     }
            // }
            // if ((!$scope.applicationInfo || $scope.applicationInfo.ApplicationTypeId != 9) && $scope.personalInfo.USCitizen == false) {
            //     if ($scope.documentUploadCitizen2.documentCount < 1 || !$scope.documentUploadCitizen2.documentCount) {
            //         var errorObj = {
            //             propName: "CitizenshipInfomationDoc",
            //             errorMessage: Messages.fdccebe
            //         };
            //         $scope.natValidation.errorMessages.push(errorObj);
            //     }
            // }
            // }
            // if (!!$scope.isRenewal) {
            //     if (!$scope.individualLicenseDisciplineList) {
            //         var errorObj = {
            //             propName: "RenewalType",
            //             errorMessage: "Please select Renewal Type"
            //         };
            //         $scope.natValidation.errorMessages.push(errorObj);
            //     }
            // }
            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        var ShowErrMsg = function (propName, errorMessage) {
            propName = (!!propName) ? propName : "personalErr";
            errorMessage = (!!errorMessage) ? errorMessage : $scope.personalConfig.ValidationMsg.APIError;
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            var propExist = false;
            for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                if ($scope.natValidation.errorMessages[i].propName == propName)
                    propExist = true;
            }
            if (!propExist)
                $scope.natValidation.errorMessages.push({
                    propName: propName,
                    errorMessage: errorMessage
                });

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };
        var savePersonalInfoData = function (skipNextTab) {
            var dataSavePromises = [];
            if (!$scope.hidePersonalInfo)
                dataSavePromises.push(saveIndividual());
            else
                ShowLoader();
            // if (!$scope.hidePersonalInfo && $scope.isRenewal) {
            //     if (($scope.individualEmploymentData.EmploymentStatusId != 2 && $scope.individualLicense.applicationType != 'retired' && !!$scope.personalData.EmployerAddress.isAddressChanged) || !!$scope.personalData.ResidentialAddress.isAddressChanged) {
            //         dataSavePromises.push(saveAddress());
            //     }
            // } else {
            //     if (!!$scope.personalData.EmployerAddress.isAddressChanged || !!$scope.personalData.ResidentialAddress.isAddressChanged) {
            //         dataSavePromises.push(saveAddress());
            //     }
            // }

            // if (!$scope.hidePersonalInfo && $scope.isRenewal) {
            //     if ($scope.individualLicense.applicationType != 'retired') {
            //         dataSavePromises.push(saveEmployment());
            //     }
            // }
            dataSavePromises.push(saveContact());

            // if (!!$scope.isBackoffice && (!!$scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 2 && $scope.applicationSubTypeId != 2)) {
            //     dataSavePromises.push(saveIndividualUserForRegistration());
            // }

            // if (!!$scope.ncees && $scope.ncees.RecordBookNumber) {
            //     dataSavePromises.nceesSave = initialApplicationNcessService.saveIndividualLicenseRecordBook([$scope.ncees], sessionStorage.Key);
            // }
            $scope.individualLicense.ActionFlag = "M";
            dataSavePromises.push(licenseApplicationListingService.saveIndividualLicense(sessionStorage.Key, $scope.individualLicense));

            // if (!$scope.isRenewal)
            //     saveCitizenShip();
            $q.all(dataSavePromises)
                .then(function (response) {
                    var responseStatus = true;
                    var responseMessage = '';
                    for (var i = 0; i < response.length; i++) {
                        if (response[i].Status == false) {
                            responseStatus = false;
                            responseMessage = response[i].Message;
                            break;
                        }
                    }
                    if (responseStatus == true) {
                        $scope.tab.skipNextTab = skipNextTab;

                        if ($scope.applicationSubTypeId == 2 && !$scope.isBackoffice) {
                            initialApplicationstatusService.setPersonal('success');
                            $scope.tab.tabSaveStatus = "success";
                        }
                        else {
                            if (!!$scope.isRenewal) {
                                $scope.openNextTab({
                                    currentTab: $scope.tab
                                });
                                if ($scope.tab.skipNextTab == true) {
                                    $scope.$emit('setCurrentTabNow', $scope.tab);
                                }
                                if (skipNextTab && !!skipNextTab.fromDeficiency) {
                                    $scope.$emit('deficiencyExecuted', { success: true });
                                }
                            } else {
                                initialApplicationstatusService.setPersonal('success');
                            }
                        }


                    } else {
                        if ($scope.isRenewal)
                            initialApplicationstatusService.setPersonal('error');
                        if ($scope.applicationSubTypeId == 2) {
                            initialApplicationstatusService.setPersonal('error');
                        }
                        $scope.showStatusMessage({
                            messageContent: responseMessage,
                            messageType: 'error'
                        });
                        if ($scope.applicationSubTypeId == 2)
                            $scope.tab.tabSaveStatus = "error";
                    }
                    if (!$rootScope.ProcessLoaderExternally)
                        HideLoader();
                }, function (error) {
                    if ($scope.isRenewal)
                        initialApplicationstatusService.setPersonal('error');
                    HideLoader();
                    $scope.showStatusMessage({
                        messageContent: 'Some error occurred !',
                        messageType: 'error'
                    });
                });
        };

        $scope.savePersonalInfo = function (currentForm, skipNextTab) {
            $scope.tab.isInvalid = false;
            if (!validateForm(currentForm)) {
                if (!$scope.isRenewal) {
                    $scope.tab.isInvalid = true;
                    HideLoader();
                    if (skipNextTab == true) {
                        $scope.$emit('setCurrentTabNow', $scope.tab);
                    }
                    if (skipNextTab && !!skipNextTab.fromDeficiency) {
                        $scope.$emit('deficiencyExecuted', { success: false });
                    }
                } else {
                    initialApplicationstatusService.setPersonal('invalid');
                }
                if ($scope.applicationSubTypeId == 2) {
                    initialApplicationstatusService.setPersonal('error');
                    $scope.tab.tabSaveStatus = "invalid";
                    setTimeout(function () {
                        HideLoader();
                    }, 200);
                }
                return;
            }
            // if (!$scope.isRenewal) {
            //     $scope.saveVeteranInfo();
            // }

            if ($scope.showSubmitPanel)
                $scope.$broadcast("SaveDeficiency", {});

            if ($scope.applicationInfo.ApplicationTypeId == 2 && $scope.applicationSubTypeId != 2) {
                personalService.validateIndividualInfo({
                    SSN: $scope.personalInfo.SSN,
                    SortColumn: "LastName",
                    SortOrder: "asc",
                    IndividualId: $scope.personalInfo.IndividualId
                }, sessionStorage.Key, 1, 10).then(function (response) {
                    if (response.Status && response.ResponseReason == "NotAllowed") {
                        ShowErrMsg('individualSearchError', "It looks like the user already exist in the system.Please check the information entered for the user and try again.");
                        HideLoader();
                        return;
                    }
                    savePersonalInfoData(skipNextTab);
                }, function (error) {
                    ShowErrMsg('individualSearchError', error);
                });
            } else {
                savePersonalInfoData(skipNextTab);
            }
        };

        function saveIndividual() {
            try {
                ShowLoader("Please wait...");
                $scope.personalInfo.PlaceofBirthState = "NV";
                if ($scope.personalData)
                    $scope.personalData.USCitizen = $scope.personalInfo.USCitizen;
                return personalService.saveIndividual([$scope.personalInfo], sessionStorage.Key);
            } catch (ex) {
                HideLoader();
                $exceptionHandler(ex);
            }
        };

        function saveEmployment() {
            var datapromise = initialApplicationEmploymentService.employmentSave([$scope.individualEmploymentData], sessionStorage.Key);
            datapromise.then(function (response) {
                if (response.Status == false) {
                    $scope.showStatusMessage({
                        messageContent: response.Message,
                        messageType: 'error'
                    });
                    HideLoader();
                    return;
                }
                $scope.individualEmployment = response.IndividualEmploymentResponseList;
                processIndividualEmployment();
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage({
                    messageContent: 'Some error occurred !',
                    messageType: 'error'
                });
            });
            return datapromise;
        };

        //#region New User Registration process starts here
        function saveIndividualUserForRegistration() {
            $scope.personalInfo.LastFourOfSSN = (!!$scope.personalInfo.SSN) ? $scope.personalInfo.SSN.substring($scope.personalInfo.SSN.length - 4) : "";
            var reqData = {
                FirstName: $scope.personalInfo.FirstName,
                LastName: $scope.personalInfo.LastName,
                DateOfBirth: $scope.personalInfo.DateOfBirth,
                SSNumber: $scope.personalInfo.LastFourOfSSN,
                Email: $scope.personalData.EmailAddressContact.ContactInfo
            };

            RegisterFactory.CheckExistingUserForRegistration(reqData).then(function (res) {
                if (res.data.Status) {
                    if (res.data.StatusID == 1) {
                        if ($scope.personalInfo.IndividualId == res.data.IndividualId) {
                            $scope.isUserRegisteredSuccessfull = true;
                        } else {
                            ShowErrMsg("sameUserExists", "It looks like you are already registered in the system with the Email Address entered.");
                            $scope.isUserRegisteredSuccessfull = false;
                        }
                    } else if ((res.data.StatusID == 2 || res.data.StatusID == 4) && !$scope.isRenewal) {
                        $scope.personalInfo.IsNewApplicant = true;
                        GenerateApplicationId(0, 0, 6, 1, 7, res.data.StatusID);
                    } else if (res.data.StatusID == 4 && !!$scope.isRenewal) {
                        $scope.personalInfo.IsNewApplicant = false;
                        GenerateApplicationId(0, res.data.UserId, 7, null, 0, res.data.StatusID);
                    } else {
                        $scope.isUserRegisteredSuccessfull = true;
                    }
                } else {
                    $scope.isUserRegisteredSuccessfull = false;
                }
            });
            return processNewUserRegistrationPromise();
        };

        var processNewUserRegistrationPromise = function () {
            var userRegisterPromise = $q.defer();
            var checkIfCheckPromise = true;
            $scope.$watch('isUserRegisteredSuccessfull', function (newValue, oldValue) {
                if (newValue == true || newValue == false) {
                    if (!!checkIfCheckPromise) {
                        userRegisterPromise.resolve({
                            Status: newValue
                        });
                        checkIfCheckPromise = false;
                    }
                }
            });
            return userRegisterPromise.promise;
        };

        var ResetPassword = function (userId) {
            var Urlparm = '/key';
            var reqData = [userId];
            RegisterFactory.ResetPassword(Urlparm, reqData).then(function (res) {

            }, function (res) {
                HideLoader();
                $scope.showStatusMessage(res.data.Message, 'error');
            });
        };

        var GetUserByIndividualId = function (individualID, userid, appid) {
            var Urlparm = '/key?IndividualId=' + individualID + '&UserId=' + userid;
            RegisterFactory.UserByIndividualId(Urlparm).then(function (res) {
                if (res.data.Status) {
                    var user = res.data.Users;
                    var userStatusId = 0;
                    var userID = 0;
                    var userName = '';
                    if (user) {
                        $scope.isUserExists = true;
                        var userStatusId = user.UserStatusId;
                        var userName = user.UserName;
                        var userID = user.UserId;
                        //update user table if user name is null or empty
                        if (userName && userName != "") {
                            if ((userName.toLowerCase() == $scope.personalData.EmailAddressContact.ContactInfo.toLowerCase().trim()) && (userStatusId == 4 || userStatusId == 6)) {
                                ResetPassword(userID);
                                $scope.isUserRegisteredSuccessfull = true;
                            }
                        } else {
                            //update user here
                            user.UserName = $scope.personalData.EmailAddressContact.ContactInfo;
                            user.Email = $scope.personalData.EmailAddressContact.ContactInfo;
                            user.UserStatusId = 4;
                            user.UserTypeId = 6;
                            user.TemporaryPassword = true;
                            user.FirstName = $scope.personalInfo.FirstName;
                            user.LastName = $scope.personalInfo.LastName;
                            user.DateOfBirth = $scope.personalInfo.DateOfBirth;
                            user.ApplicationID = appid;
                            user.SourceId = 2;
                            UsersSave(user, appid);
                        }
                    } else {
                        $scope.isUserRegisteredSuccessfull = true;
                    }
                } else {
                    var indvID = $scope.CheckExistingUserForRegistrationIndvID;
                    SaveIndividualNameTypeLk(appid, indvID);

                    var reqData = {
                        UserName: $scope.personalData.EmailAddressContact.ContactInfo,
                        UserTypeId: 6,
                        UserStatusId: 4,
                        TemporaryPassword: true,
                        IndividualId: individualID,
                        FirstName: $scope.personalInfo.FirstName,
                        LastName: $scope.personalInfo.LastName ? $scope.personalInfo.LastName : "",
                        Email: $scope.personalData.EmailAddressContact.ContactInfo,
                        DateOfBirth: $scope.personalInfo.DateOfBirth,
                        SourceId: 2,
                        IsDeleted: 0,
                        IsArchive: 0,
                        CreatedOn: new Date(),
                        ApplicationID: appid
                    };
                    UsersSave(reqData, appid);
                }
            }, function (res) {
                HideLoader();
                $scope.showStatusMessage(res.data.Message, 'error');
            });
        };

        var SaveIndividualNameTypeLk = function (appID, indvID) {
            var Urlparm = '/Key';
            var reqData = {
                IndividualId: indvID,
                IndividualNameTypeId: 1,
                IndividualNameStatusId: 1,
                ApplicationId: appID,
                IsActive: 1,
                IsDeleted: 0,
            };
            RegisterFactory.SaveIndividualNameTypeLk(Urlparm, reqData).then(function (res) { }, function (res) { });
        };

        var SaveIndividualApplication = function (IndividulaID, AppID, userID) {
            if (IndividulaID && IndividulaID != 0 && AppID && AppID != 0) {
                var Urlparm = '/key';
                var reqData = {
                    ApplicationId: AppID,
                    IndividualId: IndividulaID,
                    CreatedBy: userID,
                    CreatedOn: new Date()
                };
                RegisterFactory.SaveIndividualApplication(Urlparm, reqData).then(function (res) { }, function (res) {
                    HideLoader();
                    $scope.showStatusMessage(Messages.ecbbecf, 'error');
                });
            }
        };

        var UserRoleGetBYUserID = function (uID) {
            var urlParm = "/Key?UserId=" + uID;
            RegisterFactory.UserRoleGetBYUserID(urlParm).then(function (res) {
                if (!res.data.UserRole || res.data.UserRole.length == 0) {
                    var reqData = [{
                        UserID: uID,
                        IsActive: true,
                        RoleId: 2
                    }];
                    UserRoleSave(reqData);
                }
                HideLoader();
            }, function () {

                HideLoader();
            });
        };

        var IndividualContactSave = function (indvID, userid, appID) {
            var Urlparm = '/Key';
            var reqData = {
                IndividualId: indvID,
                ContactId: 0,
                ContactTypeId: 18,
                CreatedBy: userid,
                ContactStatusId: 1,
                ApplicationId: appID,
                ContactInfo: $scope.personalData.EmailAddressContact.ContactInfo,
                IsActive: 1,
                IsDeleted: 0
            };
            RegisterFactory.IndividualContactSave(Urlparm, [reqData]).then(function (res) {
                HideLoader();
                $scope.isUserRegisteredSuccessfull = true;
            }, function (res) {
                $scope.isUserRegisteredSuccessfull = true;
            });
        };

        var UsersSave = function (reqData, AppID) {
            var Urlparm = '/Key';
            var indvID = reqData.IndividualId;
            RegisterFactory.UsersSave(Urlparm, reqData).then(function (res) {
                if (res.data.Status) {
                    var user = res.data.Users[0];
                    var uId = user.UserId;
                    if (!$scope.isUserExists) {
                        if ($scope.personalInfo.IsNewApplicant) {
                            var reqData = [{
                                UserID: uId,
                                IsActive: true,
                                RoleId: 1
                            }];
                            UserRoleSave(reqData);
                        } else {
                            var reqData = [{
                                UserID: uId,
                                IsActive: true,
                                RoleId: 2
                            }];
                            UserRoleSave(reqData);
                        }
                        if (!$scope.currentApplication)
                            SaveIndividualApplication(user.IndividualId, AppID, uId);
                    } else {
                        UserRoleGetBYUserID(uId);
                        ResetPassword(uId);
                    }
                    IndividualContactSave(indvID, uId, AppID);
                } else {
                    HideLoader();
                    $scope.showStatusMessage(res.data.Message, 'error');
                }
            }, function (res) {
                HideLoader();
                $scope.showStatusMessage('Error in the Registration Process: Users, ApplicationTypeId=' + ApplicationTypeId + '  (Reference Code: 00047). Please contact the NV Department of Education and reference this code.', 'error');
            });
        };

        var UserRoleSave = function (reqData) {
            var Urlparm = '/Key';
            RegisterFactory.UserRoleSave(Urlparm, reqData).then(function (res) {

            }, function (res) {

            });
        };

        var GenerateApplicationId = function (ApplicationID, UserID, ApplicationTypeId,
            ApplicationStatusReasonId, ApplicationInternalStatusId, UserStatusId) {
            var Urlparm = '/key?ApplicationId=' + ApplicationID + '&UserId=' + UserID;

            var urlParmReq = "?Key=" + sessionStorage.Key + "&IndividualId=" + $scope.individualId;
            $scope.currentApplication = null;
            LoginwemailService.ApplicationBYIndividualId(urlParmReq).then(function (res) {
                if (res.data.Status) {
                    angular.forEach(res.data.ApplicationResponseList, function (applicationItem) {
                        if (applicationItem.ApplicationTypeId == ApplicationTypeId) {
                            $scope.currentApplication = applicationItem;
                        }
                    });
                    if (!$scope.currentApplication) {
                        var reqData = {
                            ApplicationTypeId: ApplicationTypeId,
                            ApplicationStatusId: 6,
                            ApplicationSubmitMode: 'B',
                            ApplicationStatusReasonId: ApplicationStatusReasonId,
                            GoPaperless: true,
                            IsDeleted: 0,
                            IsActive: 1,
                            IsArchive: 0,
                            ApplicationInternalStatusId: ApplicationInternalStatusId
                        };
                        RegisterFactory.GenerateApplicationId(Urlparm, reqData).then(function (res) {
                            if (res.data.Status) {
                                var appID = res.data.ApplicationId;
                                if (UserStatusId == 4) {
                                    GetUserByIndividualId($scope.individualId, UserID, appID);
                                } else {
                                    SaveIndividualNameTypeLk(appID, $scope.individualId);
                                    var reqData = {
                                        IndividualId: $scope.individualId,
                                        ApplicationID: appID,
                                        UserName: $scope.personalData.EmailAddressContact.ContactInfo,
                                        UserTypeId: 2,
                                        FirstName: $scope.personalInfo.FirstName,
                                        LastName: $scope.personalInfo.LastName,
                                        SSN: $scope.personalInfo.SSN,
                                        Email: $scope.personalData.EmailAddressContact.ContactInfo,
                                        UserStatusId: 4,
                                        TemporaryPassword: true,
                                        DateOfBirth: $scope.personalInfo.DateOfBirth,
                                        SourceId: 2,
                                        IsDeleted: 0,
                                        IsArchive: 0,
                                        CreatedOn: new Date()
                                    };
                                    UsersSave(reqData, appID);
                                }
                            } else {
                                $scope.isUserRegisteredSuccessfull = true;
                            }
                        }, function (res) {
                            $scope.isUserRegisteredSuccessfull = true;
                        });
                    } else {
                        var appID = $scope.currentApplication.ApplicationId;
                        if (UserStatusId == 4) {
                            GetUserByIndividualId($scope.individualId, UserID, appID);
                        } else {
                            SaveIndividualNameTypeLk(appID, $scope.individualId);
                            var reqData = {
                                IndividualId: $scope.individualId,
                                ApplicationID: appID,
                                UserName: $scope.personalData.EmailAddressContact.ContactInfo,
                                UserTypeId: 2,
                                FirstName: $scope.personalInfo.FirstName,
                                LastName: $scope.personalInfo.LastName,
                                SSN: $scope.personalInfo.SSN,
                                Email: $scope.personalData.EmailAddressContact.ContactInfo,
                                UserStatusId: 4,
                                TemporaryPassword: true,
                                DateOfBirth: $scope.personalInfo.DateOfBirth,
                                SourceId: 2,
                                IsDeleted: 0,
                                IsArchive: 0,
                                CreatedOn: new Date()
                            };
                            UsersSave(reqData, appID);
                        }
                    }
                } else {
                    $scope.isUserRegisteredSuccessfull = true;
                }
            }, function (error) {

            });
        };
        //#endregion New User Registration process ends here

        $scope.addressChanged = function (originalAddress, addressOption) {

            if (originalAddress.StreetLine1 != addressOption.StreetLine1 ||
                originalAddress.StreetLine2 != addressOption.StreetLine2 ||
                originalAddress.City != addressOption.City ||
                ((!originalAddress.IndividualAddressId && !!addressOption.CountryId && addressOption.CountryId != $rootScope.DefaultCountryId) ||
                    (!!originalAddress.IndividualAddressId && originalAddress.CountryId != addressOption.CountryId)) ||
                originalAddress.StateCode != addressOption.StateCode ||
                originalAddress.Zip != addressOption.Zip ||
                (!!originalAddress.IsMailingSameasPhysical != !!addressOption.IsMailingSameasPhysical)
            ) {
                addressOption.isAddressChanged = true;
            } else {
                addressOption.isAddressChanged = false;
            }
            if (!$scope.isRenewal)
                $scope.mailingAddressChanged();

        };

        var getChangedLkId = function (changedDataItem, changeLogType) {
            var lkTypeId = '';
            if (changeLogType == 'A') {
                lkTypeId = changedDataItem.IndividualAddressId;
            } else if (changeLogType == 'C') {
                lkTypeId = changedDataItem.IndividualContactId;
            }
            return lkTypeId;
        };

        var getDataChangeLogItems = function (changedDataItems, changeLogType) {
            var dataChangeLogItems = [];
            angular.forEach(changedDataItems, function (changedDataItem) {
                var dataChangeLogItem = {
                    IndividualId: $scope.individualId,
                    ApplicationId: $scope.applicationId,
                    IndividualLicenseId: $scope.individualLicense.IndividualLicenseId,
                    LkId: getChangedLkId(changedDataItem, changeLogType),
                    LkIdType: changeLogType,
                    DataChangeFlag: 'C',
                    IsActive: true
                };
                dataChangeLogItems.push(dataChangeLogItem);
            });
            return dataChangeLogItems;
        };

        function saveAddress() {
            var addressSaveProcessPromise = $q.defer();
            try {
                $scope.personalData.ResidentialAddress.IsActive = true;
                var addressDataList = [];
                // if ((!!$scope.hidePersonalInfo) || !$scope.isRenewal || $scope.individualLicense.applicationType != 'retired') {
                //     $scope.personalData.EmployerAddress.IsActive = true;
                //     if (!!$scope.isRenewal && !!$scope.personalData.EmployerAddress.DataChangeFlag && $scope.personalData.EmployerAddress.DataChangeFlag == 'C') {
                //         $scope.personalData.EmployerAddress.ActionFlag = 'U';
                //     } else {
                //         $scope.personalData.EmployerAddress.ActionFlag = 'UI';
                //     }
                //     if ($scope.personalData.EmployerAddress.isAddressChanged)
                //         addressDataList.push($scope.personalData.EmployerAddress);
                // }
                // if (!$scope.isRenewal && $scope.personalData.MaillingAddress) {
                //     $scope.personalData.MaillingAddress.ActionFlag = 'UI';
                //     addressDataList.push($scope.personalData.MaillingAddress);
                // }

                if (!!$scope.isRenewal && !!$scope.personalData.ResidentialAddress.DataChangeFlag && $scope.personalData.ResidentialAddress.DataChangeFlag == 'C') {
                    $scope.personalData.ResidentialAddress.ActionFlag = 'U';
                } else {
                    $scope.personalData.ResidentialAddress.ActionFlag = 'UI';
                }
                if ($scope.personalData.ResidentialAddress.isAddressChanged)
                    addressDataList.push($scope.personalData.ResidentialAddress);

                personalService.saveAddress(addressDataList, sessionStorage.Key)
                    .then(function (response) {
                        if (response.Status == false) {
                            $scope.showStatusMessage({
                                messageContent: response.Message,
                                messageType: 'error'
                            });
                            addressSaveProcessPromise.reject(response);
                        } else {
                            if (!!$scope.isRenewal) {
                                var newCreatedAddress = [];
                                angular.forEach(response.IndividualAddressResponse, function (savedAddressOption) {
                                    var isNewAddress = true;
                                    angular.forEach($scope.personalAddress, function (existingAddressOption) {
                                        if (savedAddressOption.IndividualAddressId == existingAddressOption.IndividualAddressId) {
                                            isNewAddress = false;
                                        }
                                    });
                                    if (isNewAddress) {
                                        newCreatedAddress.push(savedAddressOption);
                                    }
                                });

                                $scope.personalAddress = response.IndividualAddressResponse;
                                if (!!newCreatedAddress && newCreatedAddress.length > 0) {
                                    var dataChangeLogItems = getDataChangeLogItems(newCreatedAddress, 'A');
                                    dataChangeLogService.dataChangeFlagSave(dataChangeLogItems, sessionStorage.Key).then(function (changeLogSaveResponse) {
                                        if (!changeLogSaveResponse.Status) {
                                            $scope.showStatusMessage({
                                                messageContent: changeLogSaveResponse.Message,
                                                messageType: 'error'
                                            })
                                            addressSaveProcessPromise.reject(changeLogSaveResponse);
                                            return;
                                        }
                                        $scope.changeLogData = changeLogSaveResponse.DataChangeFlagResponse;
                                        processDataChangeLog($scope.changeLogData, 'A');
                                        assignAddressByAddressTypeId(true);
                                        addressSaveProcessPromise.resolve(changeLogSaveResponse);
                                    }, function (error) {
                                        $scope.showStatusMessage({
                                            messageContent: error,
                                            messageType: 'error'
                                        })
                                        addressSaveProcessPromise.reject(error);
                                    });
                                } else {
                                    processDataChangeLog($scope.changeLogData, 'A');
                                    assignAddressByAddressTypeId(true);
                                    addressSaveProcessPromise.resolve(response);
                                }
                            } else {
                                $scope.personalAddress = response.IndividualAddressResponse;
                                assignAddressByAddressTypeId(true);
                                addressSaveProcessPromise.resolve(response);
                            }
                        }
                    }, function (data) {
                        $scope.showStatusMessage({
                            messageContent: 'Some error occurred !',
                            messageType: 'error'
                        });
                        addressSaveProcessPromise.reject(data);
                    });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
                addressSaveProcessPromise.reject(ex);
            }
            return addressSaveProcessPromise.promise;
        };

        var processLinkedContactForSave = function (contactItemOriginal, contactItem, contactList) {
            if ((!!contactItem.ContactInfo || !!contactItem.IndividualContactId) &&
                contactItemOriginal.ContactInfo != contactItem.ContactInfo) {
                if (!!$scope.isRenewal && !!contactItem.DataChangeFlag && contactItem.DataChangeFlag == 'C') {
                    contactItem.ActionFlag = 'U';
                } else {
                    contactItem.ActionFlag = 'UI';
                }
                contactItem.IsActive = true;
                contactList.push(contactItem);
            }
        };

        function saveContact() {

            var contactDataSavePromises = $q.defer();
            try {
                var contactList = [];
                processLinkedContactForSave($scope.personalData.OriginalCellPhoneContact, $scope.personalData.CellPhoneContact, contactList);
                processLinkedContactForSave($scope.personalData.OriginalHomePhoneContact, $scope.personalData.HomePhoneContact, contactList);
                processLinkedContactForSave($scope.personalData.OriginalEmailAddressContact, $scope.personalData.EmailAddressContact, contactList);
                processLinkedContactForSave($scope.personalData.OriginalFaxContact, $scope.personalData.FaxContact, contactList);
                if ($scope.isRenewal) {
                    if ((!!$scope.hidePersonalInfo) || $scope.individualLicense.applicationType != 'retired') {
                        processLinkedContactForSave($scope.personalData.OriginalOfficePhoneContact, $scope.personalData.OfficePhoneContact, contactList);
                        processLinkedContactForSave($scope.personalData.OriginalOfficeEmailAddressContact, $scope.personalData.OfficeEmailAddressContact, contactList);
                        processLinkedContactForSave($scope.personalData.OriginalOfficeFaxContact, $scope.personalData.OfficeFaxContact, contactList);
                    }
                } else {
                    processLinkedContactForSave($scope.personalData.OriginalOfficePhoneContact, $scope.personalData.OfficePhoneContact, contactList);
                    processLinkedContactForSave($scope.personalData.OriginalOfficeEmailAddressContact, $scope.personalData.OfficeEmailAddressContact, contactList);
                    processLinkedContactForSave($scope.personalData.OriginalOfficeFaxContact, $scope.personalData.OfficeFaxContact, contactList);
                }
                if (!!contactList && contactList.length > 0) {
                    personalService.saveContact(contactList, sessionStorage.Key)
                        .then(function (response) {
                            if (response.Status == false) {
                                $scope.showStatusMessage({
                                    messageContent: response.Message,
                                    messageType: 'error'
                                });
                                contactDataSavePromises.reject(response);
                            } else {
                                if (!!$scope.isRenewal) {
                                    var newCreatedContacts = [];
                                    angular.forEach(response.IndividualContactResponse, function (savedContactOption) {
                                        var isNewContact = true;
                                        angular.forEach($scope.personalContact, function (existingContactOption) {
                                            if (savedContactOption.IndividualContactId == existingContactOption.IndividualContactId) {
                                                isNewContact = false;
                                            }
                                        });
                                        if (isNewContact) {
                                            newCreatedContacts.push(savedContactOption);
                                        }
                                    });
                                    $scope.personalContact = response.IndividualContactResponse;
                                    if (!!newCreatedContacts && newCreatedContacts.length > 0) {
                                        var dataChangeLogItems = getDataChangeLogItems(newCreatedContacts, 'C');
                                        dataChangeLogService.dataChangeFlagSave(dataChangeLogItems, sessionStorage.Key).then(function (changeLogSaveResponse) {
                                            if (!changeLogSaveResponse.Status) {
                                                $scope.showStatusMessage({
                                                    messageContent: changeLogSaveResponse.Message,
                                                    messageType: 'error'
                                                })
                                                contactDataSavePromises.reject(changeLogSaveResponse);
                                                return;
                                            }
                                            $scope.changeLogData = changeLogSaveResponse.DataChangeFlagResponse;
                                            processDataChangeLog($scope.changeLogData, 'C');
                                            assignContactsByContactTypeId(true);
                                            contactDataSavePromises.resolve(changeLogSaveResponse);
                                        }, function (error) {
                                            $scope.showStatusMessage({
                                                messageContent: error,
                                                messageType: 'error'
                                            })
                                            contactDataSavePromises.reject(error);
                                        });
                                    } else {
                                        processDataChangeLog($scope.changeLogData, 'C');
                                        assignContactsByContactTypeId(true);
                                        contactDataSavePromises.resolve(response);
                                    }
                                } else {
                                    $scope.personalContact = response.IndividualContactResponse;
                                    assignContactsByContactTypeId();
                                    contactDataSavePromises.resolve(response);
                                }
                            }
                        }, function (data) {
                            $scope.showStatusMessage({
                                messageContent: data.Message,
                                messageType: 'error'
                            });
                            contactDataSavePromises.reject(data);
                        });
                } else {
                    contactDataSavePromises.resolve({
                        Status: true
                    });
                }
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
                contactDataSavePromises.reject(ex);
            }
            return contactDataSavePromises.promise;
        };

        function AuditVisitInfoSave() {
            var data = {
                "PageName": $scope.personalConfig.Content.TabHeading,
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };

        var groupChangeLogDataByType = function (changeLogData) {
            var changeLogDataGrouped = {
                addressChangeLog: [],
                contactChangeLog: [],
                nameChangeLog: []
            };
            angular.forEach(changeLogData, function (changeLogDataItem) {
                if (changeLogDataItem.LkIdType == 'A') {
                    changeLogDataGrouped.addressChangeLog.push(changeLogDataItem);
                } else if (changeLogDataItem.LkIdType == 'C') {
                    changeLogDataGrouped.contactChangeLog.push(changeLogDataItem);
                }
            });
            return changeLogDataGrouped;
        };

        var processAddressData = function (addressChangeLog) {
            angular.forEach($scope.personalAddress, function (addressItem) {
                angular.forEach(addressChangeLog, function (addressChangeLogItem) {
                    if (addressItem.IndividualAddressId == addressChangeLogItem.LkId &&
                        addressChangeLogItem.ApplicationId == $scope.applicationId &&
                        addressChangeLogItem.IndividualId == $scope.individualId &&
                        addressChangeLogItem.IndividualLicenseId == $scope.individualLicense.IndividualLicenseId) {
                        addressItem.DataChangeFlag = addressChangeLogItem.DataChangeFlag;
                    }
                });
            });
        };

        var processContactData = function (contactChangeLog) {
            angular.forEach($scope.personalContact, function (contactItem) {
                angular.forEach(contactChangeLog, function (contactChangeLogItem) {
                    if (contactItem.IndividualContactId == contactChangeLogItem.LkId &&
                        contactChangeLogItem.ApplicationId == $scope.applicationId &&
                        contactChangeLogItem.IndividualId == $scope.individualId &&
                        contactChangeLogItem.IndividualLicenseId == $scope.individualLicense.IndividualLicenseId) {
                        contactItem.DataChangeFlag = contactChangeLogItem.DataChangeFlag;
                    }
                });
            });
        };

        var processDataChangeLog = function (changeLogData, changeLogType) {
            var changeLogDataGrouped = groupChangeLogDataByType(changeLogData);
            if (!changeLogType || changeLogType == 'A')
                processAddressData(changeLogDataGrouped.addressChangeLog);
            if (!changeLogType || changeLogType == 'C')
                processContactData(changeLogDataGrouped.contactChangeLog);
        };

        $scope.isMailingSameasPhysicalChanged = function (processOnlyCurrentAddress) {
            if ($scope.personalData.ResidentialAddress.IsMailingSameasPhysical) {
                $scope.showPublicRecordMessage = true;
                if (!processOnlyCurrentAddress)
                    $scope.personalData.EmployerAddress.IsMailingSameasPhysical = false;
            } else {
                $scope.showPublicRecordMessage = false;
            }
            if (!processOnlyCurrentAddress)
                $scope.isMailingSameasEmployerChanged(true);
            $scope.addressChanged($scope.personalData.OriginalResidentialAddress, $scope.personalData.ResidentialAddress);
        };

        $scope.isMailingSameasEmployerChanged = function (processOnlyCurrentAddress) {
            if ($scope.personalData.EmployerAddress.IsMailingSameasPhysical) {
                $scope.showPublicRecordMessageForEmployer = true;
                if (!processOnlyCurrentAddress)
                    $scope.personalData.ResidentialAddress.IsMailingSameasPhysical = false;
            } else {
                $scope.showPublicRecordMessageForEmployer = false;
            }
            if (!processOnlyCurrentAddress)
                $scope.isMailingSameasPhysicalChanged(true);
            $scope.addressChanged($scope.personalData.OriginalEmployerAddress, $scope.personalData.EmployerAddress);
        };

        $scope.savePersonalInfoInternal = function (currentForm, skipNextTab) {
            $scope.savePersonalInfo(currentForm, skipNextTab);
        };

        var processIndividualEmployment = function () {
            angular.forEach($scope.individualEmployment, function (individualEmploymentItem) {
                if (individualEmploymentItem.EmploymentTypeId == 1) {
                    $scope.individualEmploymentData = individualEmploymentItem;
                }
            });
            if ($scope.isRenewal) {
                if (!$scope.individualEmploymentData) {
                    $scope.individualEmploymentData = {
                        IndividualId: $scope.individualId,
                        ApplicationId: $scope.applicationId,
                        EmploymentStatusId: 1,
                        EmploymentTypeId: 1,
                        IndividualLicenseId: $scope.individualLicense.IndividualLicenseId,
                        EmployerName: '',
                        EmploymentHistoryTypeId: 1,
                        IsActive: 1
                    };
                }
            }
        };

        $scope.mailingAddressChanged = function () {
            if ($scope.personalData.ResidentialAddress.IsMailingSameasPhysical) {
                if ($scope.personalData.ResidentialAddress) {
                    $scope.personalData.MaillingAddress.StreetLine1 = $scope.personalData.ResidentialAddress.StreetLine1;
                    $scope.personalData.MaillingAddress.StreetLine2 = $scope.personalData.ResidentialAddress.StreetLine2;
                    $scope.personalData.MaillingAddress.City = $scope.personalData.ResidentialAddress.City;
                    $scope.personalData.MaillingAddress.Zip = $scope.personalData.ResidentialAddress.Zip;
                    $scope.personalData.MaillingAddress.StateCode = $scope.personalData.ResidentialAddress.StateCode;
                }
            } else {
                if ($scope.personalData.EmployerAddress) {
                    if ($scope.personalData.EmployerAddress.IsMailingSameasPhysical) {
                        $scope.personalData.MaillingAddress.StreetLine1 = $scope.personalData.EmployerAddress.StreetLine1;
                        $scope.personalData.MaillingAddress.StreetLine2 = $scope.personalData.EmployerAddress.StreetLine2;
                        $scope.personalData.MaillingAddress.City = $scope.personalData.EmployerAddress.City;
                        $scope.personalData.MaillingAddress.Zip = $scope.personalData.EmployerAddress.Zip;
                        $scope.personalData.MaillingAddress.StateCode = $scope.personalData.EmployerAddress.StateCode;
                    }
                }
            }
        }

        // function getIndividualCitizenship() {
        //     personalService.getIndividualCitizenship($scope.individualId, sessionStorage.Key).then(function (res) {
        //         console.log(res);
        //     }, function (error) {

        //     });
        // }

        function saveCitizenShip() {
            try {
                personalService.saveIndividualCitizenship([$scope.personalData.IndividualCitizenship], sessionStorage.Key);
                citiZenShipDataSavePromises.then(function (response) {
                    if (response.Status == false) {
                        ShowErrMsg("unableToSaveCitizenshipData", response.Message);
                    } else {
                        if (!!response.IndividualCitizenshiplst && response.IndividualCitizenshiplst.length > 0) {
                            $scope.personalData.IndividualCitizenship = response.IndividualCitizenshiplst[0];
                            // processCitizenshipDates();
                        }
                    }
                }, function (error) {
                    ShowErrMsg("unableToSaveCitizenshipData", response.Message);
                });
                // return citiZenShipDataSavePromises; var citiZenShipDataSavePromises =
            } catch (ex) {
                HideLoader();
            }
        };

        var getIndividualLicenseDiscipline = function () {
            personalService.getIndividualLicenseDiscipline($scope.individualId, $scope.applicationId, sessionStorage.Key).then(function (response) {

                if (response.Status) {
                    $scope.individualLicenseDisciplineList = response.IndividualLicenseDisciplineList;
                    $scope.getDisciplineName();
                }
            }, function (error) {
                console.log(error);
            });
        }

        var getRefLicenseDisciplineTypeGetAll = function () {
            personalService.getRefLicenseDisciplineTypeGetAll($scope.individualId, sessionStorage.Key).then(function (response) {

                if (response.Status) {
                    $scope.refLicenseDisciplineTypeList = response.RefLicenseDisciplineTypeList;
                    $scope.getDisciplineName();
                }
            }, function (error) {
                console.log(error);
            });
        }


        $scope.editLicenseDiscipline = function (individualLicenseDiscipline) {
            $scope.personal.isDispEdit = true;
            $scope.personal.selectedDiscipline = individualLicenseDiscipline.LicenseDisciplineTypeId;
            $scope.editLicenseDiscip = angular.copy(individualLicenseDiscipline);
        }

        $scope.deleteLicenseDiscipline = function (individualLicenseDiscipline) {
            try {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    individualLicenseDiscipline.IsDeleted = true;

                    personalService.saveIndividualLicenseDiscipline([individualLicenseDiscipline], sessionStorage.Key)
                        .then(function (response) {
                            if (response.Status && response.StatusCode == "00") {
                                getIndividualLicenseDiscipline();
                            }
                            HideLoader();
                        }, function (data) {
                            HideLoader();
                            ShowErrMsg();
                        });
                }
            } catch (ex) {
                // $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        };

        $scope.getDisciplineName = function () {
            if ($scope.refLicenseDisciplineTypeList) {
                _.each($scope.individualLicenseDisciplineList, function (discipline) {
                    _.each($scope.refLicenseDisciplineTypeList, function (item) {
                        if (discipline.LicenseDisciplineTypeId == item.LicenseDisciplineTypeId) {
                            discipline.name = item.LicenseDisciplineTypeName;
                        }
                    });
                });
            }
            // console.log($scope.individualLicenseDisciplineList);
        }


        $scope.saveDisciplene = function () {
            ShowLoader();
            var data = {};
            //    console.log($scope.personal.selectedDiscipline);
            if ($scope.personal.isDispEdit) {
                data = $scope.editLicenseDiscip;
                data.LicenseDisciplineTypeId = $scope.personal.selectedDiscipline;
            } else {
                data = {
                    IndividualId: $scope.individualId,
                    ApplicationId: $scope.applicationId,
                    LicenseDisciplineTypeId: $scope.personal.selectedDiscipline,
                    IsActive: true,
                    IsDeleted: false
                }
            }
            if ($scope.personal.selectedDiscipline) {
                personalService.saveIndividualLicenseDiscipline([data], sessionStorage.Key).then(function (response) {
                    $scope.personal.isDispEdit = false;
                    $scope.personal.selectedDiscipline = null;
                    getIndividualLicenseDiscipline();
                    HideLoader();
                }, function (error) {
                    console.log(error);
                });
            }
        }

        $scope.changeLegacyApplication = function () {
            personalService.updateApplicationFormValidationbyApplicationId($scope.applicationInfo.ApplicationId, $scope.applicationInfo.FormValidation, sessionStorage.Key, $scope.applicationInfo.ExamTaken).then(function (response) {
                if (response.Status) {
                    $scope.applicationInfo.FormValidation = response.FormValidation;
                    $scope.applicationInfo.ExamTaken = response.ExamTaken;
                    $rootScope.isFormValidate = $scope.applicationInfo.FormValidation;
                }
            }, function (error) {

            });
        }

        function getApplication() {
            // if (!$scope.applicationInfo) {
            personalService.getApplication($scope.individualId, $scope.applicationId, sessionStorage.Key).then(function (response) {
                if (response.Status) {
                    $scope.applicationInfo = response.ApplicationResponseList[0];
                    $rootScope.isFormValidate = $scope.applicationInfo.FormValidation;

                }
            }, function (error) {

            });
            // }
            // else {
            // $rootScope.isFormValidate = $scope.applicationInfo.FormValidation;
            // }
        }

        //Intialization
        $scope.init = function () {
            try {
                if (!!$scope.loadData) {
                    ShowLoader("Please Wait...");
                    //  getApplication();
                }
                $scope.getPersonalConfig()
                    .then(function (data) {
                        $scope.personalConfig = data;
                        // if (!$scope.hidePersonalInfo)
                        //     processIndividualEmployment();
                        if (!$scope.isRenewal) {
                            AuditVisitInfoSave();
                            //  getIndividualCitizenship();
                            getRefLicenseDisciplineTypeGetAll();
                            //  getIndividualLicenseDiscipline();
                            // getIndividualCitizenship();
                            var contentId = 181;
                            if ($scope.applicationInfo.LicenseApplicationForId == 1) {
                                contentId = 203;
                            }
                            if ($scope.applicationInfo.LicenseApplicationForId == 2) {
                                contentId = 181;
                            }
                            if ($scope.applicationInfo.LicenseApplicationForId == 3) {
                                contentId = 212;
                            }
                            if ($scope.applicationInfo.LicenseApplicationForId == 4) {
                                contentId = 192;
                            }
                            if ($scope.applicationInfo.LicenseApplicationForId == 5) {
                                contentId = 167;
                            }
                            if ($scope.applicationInfo.LicenseApplicationForId == 6) {
                                contentId = 174;
                            }
                            InlumonContentService.getContentById(sessionStorage.Key, contentId).then(function (response) {
                                if (response.ContentItemLk)
                                    $scope.content = response.ContentItemLk[0];
                            }, function (error) {

                            });
                        }

                        // $scope.getIndividualLicenseRecordBook($scope.individualId);

                        // getIndividualVeteranConfByIndividualId();

                        $scope.getStateByCountryID($rootScope.DefaultCountryId)
                            .then(function (data) {
                                $scope.StatList = data.State;
                                $scope.PlaceOfBirthStates = data.State;
                                if (!!$scope.loadData) {
                                    $scope.IndividualId = $scope.individualId;
                                    $scope.GetPersonalData();
                                } else {
                                    dataChangeLogService.dataChangeFlagGet($scope.individualId, $scope.applicationId,
                                        $scope.individualLicense.IndividualLicenseId, sessionStorage.Key).then(function (changeLogResponse) {
                                            if (!changeLogResponse.Status) {
                                                $scope.showStatusMessage({
                                                    messageContent: changeLogResponse.Message,
                                                    messageType: 'error'
                                                });
                                                return;
                                            }
                                            $scope.changeLogData = changeLogResponse.DataChangeFlagResponse;
                                            processDataChangeLog($scope.changeLogData);
                                            $scope.getPersonalInfo();
                                        }, function () {
                                            $scope.showStatusMessage({
                                                messageContent: error.Message,
                                                messageType: 'error'
                                            });
                                        })
                                }
                                typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=zip"))
                                    .then(function (data) {
                                        $scope.ZipList = data.ReferenceEntities;
                                        $scope.loadZipRange('CA');
                                        typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=city"))
                                            .then(function (dataCity) {
                                                $scope.CityList = dataCity.ReferenceEntities;
                                            },
                                                function (error) {
                                                    HideLoader();
                                                    $scope.showStatusMessage({
                                                        messageContent: 'Some error occurred !',
                                                        messageType: 'error'
                                                    });
                                                });
                                    },
                                        function (error) {
                                            HideLoader();
                                            $scope.showStatusMessage({
                                                messageContent: 'Some error occurred !',
                                                messageType: 'error'
                                            });
                                        });
                            },
                                function (error) {
                                    HideLoader();
                                    $scope.showStatusMessage({
                                        messageContent: 'Some error occurred !',
                                        messageType: 'error'
                                    });
                                });
                    }, function (error) {
                        HideLoader();
                        $scope.showStatusMessage({
                            messageContent: 'Some error occurred !',
                            messageType: 'error'
                        });
                    });
                typeValuesService.getTypeValuesByTypeName('suffix', sessionStorage.Key).then(function (response) {
                    if (response) {
                        utilityService.scrollToElementOffset('applicationFormRenewal');
                        $scope.suffixTypes = response.RefSuffixResponseList;
                    }
                });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
                $exceptionHandler(ex);
            }
        };

        var getIndividualVeteranConfByIndividualId = function () {
            ShowLoader();
            initialApplicationVeteranInfoService.getIndividualVeteranConfByIndividualId(sessionStorage.Key, $scope.individualId, $scope.applicationId).then(function (response) {

                $scope.IndividualConfirmationVeteran = response.IndividualConfirmationVeteran;
                if (!$scope.IndividualConfirmationVeteran) {
                    $scope.IndividualConfirmationVeteran = {
                        IsActive: true,
                        IsDeleted: false,
                    }
                    $scope.IndividualConfirmationVeteran.IndividualLicenseId = $scope.individualLicense ? $scope.individualLicense.IndividualLicenseId : null;
                    $scope.IndividualConfirmationVeteran.IndividualId = $scope.individualId;
                    $scope.IndividualConfirmationVeteran.ApplicationId = $scope.applicationId;

                }
            }, function (error) {
                HideLoader();
            });
        }

        $scope.saveVeteranInfo = function () {
            $scope.IndividualConfirmationVeteran.ModifiedOn = new Date();
            $scope.IndividualConfirmationVeteran.IndividualLicenseId = $scope.individualLicense ? $scope.individualLicense.IndividualLicenseId : null;
            $scope.IndividualConfirmationVeteran.IndividualId = $scope.individualId;
            $scope.IndividualConfirmationVeteran.ApplicationId = $scope.applicationId;
            initialApplicationVeteranInfoService.saveVeteranInfoConfirmation([$scope.IndividualConfirmationVeteran], sessionStorage.Key)
                .then(function (response) {
                    getIndividualVeteranConfByIndividualId();
                }, function (data) {
                    ShowErrMsg("unableToSaveVeteranInfo", data);
                });
        };

        var getNewNceesRecord = function () {
            return {
                IndividualId: $scope.individualId,
                IsRecordBookOnFile: true,
                IsActive: true,
                IsDeleted: false
            };
        };

        $scope.getIndividualLicenseRecordBook = function (individualId) {
            initialApplicationNcessService.getIndividualLicenseRecordBook(individualId, null, sessionStorage.Key)
                .then(function (response) {
                    if (response.Status && response.IndividualLicenseRecordBookList && response.IndividualLicenseRecordBookList.length > 0) {
                        $scope.ncees = response.IndividualLicenseRecordBookList[0];
                    }
                    if (!!$scope.ncees && $scope.ncees.RecordBookNumber) {
                        $scope.nceesExists = true;
                    }
                    if (!$scope.ncees) {
                        $scope.ncees = getNewNceesRecord();
                    }
                }, function (data) {
                    HideLoader();
                    alert(data);
                });
        };

        $scope.loadCityTypeahed = function (searchString, stateCode) {
            var cityList = [];

            if (!!$scope.CityList && stateCode == 'CA') {
                cityList = $scope.CityList.filter(function (item) {
                    if (!!item.CityName && item.CityName.toLowerCase() == searchString.toLowerCase()) {
                        return item;
                    }
                });

                if (cityList.length == 1) {
                    return cityList;
                }

                cityList = $scope.CityList.filter(function (item) {
                    if (!!item.CityName && item.CityName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) {
                        return item;
                    }
                });
            }

            return cityList;
        };

        $scope.loadZipRange = function (stateCode) {
            if (!!$scope.ZipList) {
                $scope.zipRange = [];
                var zipItem = $scope.ZipList.whereEquals(stateCode, 'StateCd').firstOrDefault();
                if (!!zipItem) {
                    for (var i = parseInt(zipItem.LowZip); i < parseInt(zipItem.HighZip); i++) {
                        $scope.zipRange.push({
                            Zip: i.toString().padStart(5, 0)
                        });
                    }
                }
                return $scope.zipRange;
            }
        };

        $scope.loadZipTypeahed = function (searchString, stateCode) {
            var zipRange = $scope.loadZipRange(stateCode);
            if (zipRange.length > 0) {
                return zipRange.filter(function (item) {
                    if (!!zipRange && item.Zip.startsWith(searchString)) {
                        return item;
                    }
                });
            }
        };

        $scope.sendCompanyInfoToParent = function (emloyerName, companyLicenseNumber) {
            $scope.$emit("companyLicenseAndNameChanged", {
                NameonBusinessLicense: emloyerName,
                BusinessLicenseNumber: companyLicenseNumber
            });
        }

        $scope.loadTypeahed = function (searchString, searchType) {
            var emloyerName = '';
            var companyLicenseNumber = '';
            if (searchType == 'Name') {
                emloyerName = searchString;
                companyLicenseNumber = $scope.personalInfo.CompanyLicenseNumber;
            } else if (searchType == 'LicenseNumber') {
                emloyerName = $scope.personalInfo.EmployerName;
                companyLicenseNumber = searchString;
            }
            $scope.sendCompanyInfoToParent(emloyerName, companyLicenseNumber, true);
            $scope.DisableContactDetails = false;
            return personalService.getEmployerList(
                sessionStorage.Key, searchString, 5, searchType
            ).then(function (response) {
                if (!!response.Employers) {
                    return response.Employers.map(function (item) {
                        item.formattedResult = (item.EmployerName + " " + item.StreetLine1 + " " + item.StreetLine2 + " " + item.City + " " + item.Zip);
                        return item;
                    });
                }
            }, function (res) {

            });
        };

        $scope.SelectTypeAheadItem = function (selectedItem, $model, $label, $event) {
            $scope.DisableContactDetails = true;
            $scope.personalInfo.EmployerName = selectedItem.EmployerName;
            $scope.personalInfo.CompanyLicenseNumber = selectedItem.LicenseNumber;
            $scope.personalData.EmployerAddress.Addressee = selectedItem.Addressee;
            $scope.personalData.EmployerAddress.StreetLine1 = selectedItem.StreetLine1;
            $scope.personalData.EmployerAddress.StreetLine2 = selectedItem.StreetLine2;
            $scope.personalData.EmployerAddress.City = selectedItem.City;
            $scope.personalData.EmployerAddress.StateCode = selectedItem.StateCode;
            $scope.personalData.EmployerAddress.Zip = selectedItem.Zip;
            $scope.addressChanged($scope.personalData.OriginalEmployerAddress, $scope.personalData.EmployerAddress);
            $scope.sendCompanyInfoToParent($scope.personalInfo.EmployerName, $scope.personalInfo.CompanyLicenseNumber);
        };

        $scope.$on("SaveRenewal", function (event, args) {
            $scope.savePersonalInfo($scope.currentForm);
        });

        $scope.changeGPSelectedPaymentAmount = function (value) {
            if (value && ($scope.feeDetails.administrationFee == 0 || $scope.feeDetails.administrationFee > 0)) {
                if ($scope.applicationInfo.ExamTaken) {
                    $scope.feeDetails.administrationFee = $scope.feeDetails.administrationFee - 25;
                    $scope.feeDetails.totalFee = $scope.feeDetails.totalFee - 25;
                } else {
                    if ($scope.feeDetails.administrationFee == 0) {
                        $scope.feeDetails.administrationFee = $scope.feeDetails.administrationFee + 25;
                        $scope.feeDetails.totalFee = $scope.feeDetails.totalFee + 25;
                    }
                }
            }

            $scope.changeLegacyApplication();
        }

        $scope.$on('SwitchingTab', function (event, args) {
            if (args.fromTab == $scope.tab.name) {
                if (!$scope.isBackoffice) {
                    $scope.savePersonalInfoInternal($scope.personalForm, true);
                } else {
                    $scope.$broadcast("SaveDeficiency", {});
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
            }
        });

        $scope.$on('deficiencyResolve', function (event, args) {
            if (args.tabName == 'personalData') {
                $scope.savePersonalInfoInternal($scope.personalForm, { fromDeficiency: true });
            }
        });

        $scope.clearTabConfirmation = function () {
            $scope.$emit("clearTabConfirmation", { currentTab: $scope.tab });
        };


        $scope.$on("SaveApplicationTab", function (event, args) {
            if ($scope.applicationSubTypeId == 2) {

                $scope.savePersonalInfoInternal($scope.personalForm, { fromDeficiency: true });
            }
        });

        $scope.changeLicenseType = function (licenseStatusTypeId) {
            $scope.individualLicense.LicenseStatusTypeId = licenseStatusTypeId;
        }

        $scope.init();
    }
})();
