(function () {
    'use strict';
    angular
        .module('app.InitialApplication')
        .controller("FirmAffirmationProviderController", ['$scope', 'FirmAffirmationProviderService', 'firmApplicationstatusService', '$q', 'countryStateService', 'utilityService','contentService',
            function ($scope, FirmAffirmationProviderService, firmApplicationstatusService, $q, countryStateService, utilityService,contentService  ) {
                var _key = sessionStorage.Key;
                var _providerId = $scope.providerId;
                var _applicationId = $scope.applicationId;
                var _uid = $scope.userId;
                var contentItemLkId = 25;
                $scope.acknowledgement = {};
                $scope.AffirmationData = {};
                $scope.AffirmationData.ContentItemResponse = false;
                $scope.init = function () {
                    $scope.content = {};
                    getAffirmationContent();
                    GetProviderAffidavit();
                    getServerTime();
                };
                $scope.datePicker = {
                    format: 'M!/d!/yyyy',
                    dateOptions: {
                        formatYear: 'yy',
                        startingDay: 1
                    }
                };

                var getAffirmationContent = function () {
                    contentService.getContentByContentItemLkIdList([1651], sessionStorage.Key)
                        .then(function (response) {
                            var contentArray = response.ContentItemLk;
                            for (var i = 0; i < contentArray.length; i++) {
                                switch (contentArray[i].ContentItemLkId) {
                                    case 1651:
                                        $scope.content.content1651 = contentArray[i].ContentItemLkDesc;
                                        break;
                                }
                            }
                        }, function (error) {

                        });
                };

                var GetProviderAffidavit = function () {
                    FirmAffirmationProviderService.GetProviderAffidavit(_key, _providerId, _applicationId).then(function (res) {
                        if (res.data.Status) {
                            var affiList = res.data.ProviderAffidavitSignatureList;
                            if (affiList && affiList.length > 0) {
                                var sRes = $.grep(affiList, function (i) { return (i.ContentItemLkId == contentItemLkId); })
                                if (sRes && sRes.length > 0) {
                                    $scope.AffirmationData = sRes[0];
                                    if (sRes[0].Date && sRes[0].Date != null) {
                                        $scope.acknowledgement.Date = sRes[0].Date;
                                    }
                                    $scope.acknowledgement.SignatureName = sRes[0].SignatureName;
                                    $scope.acknowledgement.ProviderAffidavitSignatureId = sRes[0].ProviderAffidavitSignatureId;
                                }
                            }
                        }
                    });
                };

                var getServerTime = function () {
                    utilityService.getServerDate().then(function (res) {
                        if (res && !$scope.ApplicationDate) {
                            if (!$scope.acknowledgement.Date) {
                                $scope.acknowledgement.Date = res;
                            }
                        }
                    });
                };

                var SaveProviderAffidavit = function () {
                    var deferred = $q.defer();
                    if ($scope.AffirmationData) {

                        if (!$scope.AffirmationData.ProviderAffidavitId || $scope.AffirmationData.ProviderAffidavitId == 0) {
                            //if new
                            $scope.AffirmationData.Desc = 'AffirmationData';
                            $scope.AffirmationData.ApplicationId = _applicationId;
                            $scope.AffirmationData.ProviderId = _providerId;
                            $scope.AffirmationData.ContentItemLkId = contentItemLkId;
                            $scope.AffirmationData.IsActive = 1;
                            $scope.AffirmationData.IsDeleted = 0;
                            $scope.AffirmationData.CreatedBy = _uid;
                            //signature
                        } else {
                            //modify
                            $scope.AffirmationData.ModifiedBy = _uid;
                        }
                        if ($scope.AffirmationData && $scope.acknowledgement && $scope.acknowledgement.SignatureName) {
                            var req = { ContentItems: [$scope.AffirmationData], Provideraffidavitsignature: $scope.acknowledgement };
                            FirmAffirmationProviderService.SaveProviderAffidavit(_key, _providerId, _applicationId, _uid, [req]).then(function (res) {
                                if (res.data.Status) {
                                    GetProviderAffidavit();
                                    deferred.resolve(res);
                                } else {
                                    deferred.reject(res);
                                }
                            }, function (res) { deferred.reject(res); });
                        } else {
                            deferred.resolve({});
                        }
                    } else {
                        deferred.reject('');
                    }
                    return deferred.promise;
                };

                var ShowErrMsg = function (propName, errorMessage) {
                    propName = (!!propName) ? propName : "FIRM Information";
                    errorMessage = (!!errorMessage) ? errorMessage : $scope.EmployerInformationConfig.ValidationMsg.APIError;
                    if ($scope.natValidation.errorMessages == undefined)
                        $scope.natValidation.errorMessages = [];
                    var propExist = false;
                    for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                        if ($scope.natValidation.errorMessages[i].propName == propName)
                            propExist = true;
                    }
                    if (!propExist)
                        $scope.natValidation.errorMessages.push({
                            propName: propName,
                            errorMessage: errorMessage
                        });

                    if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                        $scope.natValidation.showError = true;
                        $scope.natValidation.isValid = false;
                        return false;
                    } else {
                        $scope.natValidation.showError = false;
                        $scope.natValidation.isValid = true;
                        return true;
                    }
                };
                var validateForm = function (form) {
                    //validate 
                    //Form custom
                    $scope.natValidation.errorMessages = [];
                    if (!!form && $scope.natValidation) {
                        if ($scope.natValidation.errorMessages == undefined) {
                            $scope.natValidation.errorMessages = [];
                        }
                        form.$submitted = true;
                        var errorMessages = angular.copy(form.errorMessages);
                        angular.forEach(errorMessages, function (errorMessage, index) {
                            $scope.natValidation.errorMessages.push(errorMessage);
                        });
                    }

                    if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                        $scope.natValidation.showError = true;
                        $scope.natValidation.isValid = false;
                        return false;
                    } else {
                        $scope.natValidation.showError = false;
                        $scope.natValidation.isValid = true;
                        return true;
                    }

                };

                $scope.$on("SaveFirmRenewal", function (event, args) {
                    if (!validateForm($scope.SignatureSecForm)) {
                        firmApplicationstatusService.setFirmAffirmation('invalid');
                    }
                    else {
                        SaveProviderAffidavit().then(function () {
                            firmApplicationstatusService.setFirmAffirmation('success');
                        }, function () {
                            firmApplicationstatusService.setFirmAffirmation('error');
                        });
                    }
                });


            }]);
})();
