(function () {
    'use strict'

    angular.module('app.enforcement')
        .controller('ComplaintQuestionnaireController', ['$scope', '$rootScope', '$q', 'ComplaintQuestionnaireService', 'utilityService', ComplaintQuestionnaireController]);

    function ComplaintQuestionnaireController($scope, $rootScope, $q, ComplaintQuestionnaireService, utilityService) {
        var _key = sessionStorage.Key;
        var _childName = '';

        var getNewComplaintQuestion = function (complaint, complaintCase, involvedParty, content, response, questionName, questionNumber, linkId, linkType) {
            return {
                ComplaintQuestionId: null,
                ComplaintId: complaint.ComplaintId,
                ComplaintCaseId: (!!complaintCase) ? complaintCase.ComplaintCaseId : null,
                ComplaintInvolvedPartyId: (!!involvedParty) ? involvedParty.ComplaintInvolvedPartyId : null,
                QuestionName: questionName,
                QuestionNumber: questionNumber,
                ContentItemLkId: (!!content) ? content.ContentItemLkId : null,
                ContentItemLkDesc: (!!content) ? content.ContentItemLkDesc : null,
                ResponseType: (typeof (response) == 'boolean') ? 'B' : 'T',
                BoolResponse: (typeof (response) == 'boolean') ? response : null,
                TextResponse: (typeof (response) != 'boolean') ? response : null,

                IsInternalOnly: null,
                IsAdministrativeAction: null,
                IsNonActionable: null,
                IsForPublic: null,
                IsForInvestigationOnly: null,
                IsForLegalOnly: null,

                LinkId: linkId,
                LinkType: linkType,

                IsActive: true,
                IsDeleted: false,
                
                ReferenceNumber: ''
            };
        };

        var getConfig = function () {
            var deferred = $q.defer();

            try {
                utilityService.getConfigData('/components/backoffice/enforcement/complaint-questionnaire/complaint-questionnaire.server-config.json')
                    .then(function (data) {
                        if (!!data) {
                            $scope.config = data;

                            $scope.uiConfig = $rootScope.getUIConfig($scope.configName, $scope.config);

                            $scope.isConfigLoaded = true;
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getComplaintQuestions = function (complaintId) {
            var deferred = $q.defer();

            try {
                ComplaintQuestionnaireService.getComplaintQuestions(sessionStorage.Key, complaintId, $scope.linkId, $scope.linkType)
                    .then(function (data) {
                        $scope.complaintQuestions = data.ComplaintQuestions.orderBy('QuestionNumber');

                        if (['outputform', 'inputWithGet'].includes($scope.mode)) {
                            $scope.complaintQuestions.forEach(function (cq) {
                                switch (cq.ResponseType) {
                                    case 'B':
                                        $scope.current.questionnaire[cq.QuestionName] = cq.BoolResponse;
                                        break;
                                    case 'T':
                                        $scope.current.questionnaire[cq.QuestionName] = cq.TextResponse;
                                        break;
                                }
                            });
                        }

                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.saveComplaintQuestions = function (questions) {
            var deferred = $q.defer();

            try {
                ComplaintQuestionnaireService.saveComplaintQuestions(questions)
                    .then(function (data) {
                        if (data.Status == false) {
                            deferred.reject(data);
                        } else {
                            deferred.resolve(data);
                        }
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.resetForm = function () {
            $scope.current = {
                questionnaire: {}
            };
        };

        $scope.contentLoaded = function (name, content) {
            $scope.content[name] = content;
        };

        $scope.init = function () {
            $scope.content = {};
            $scope.current = {
                questionnaire: {}
            };

            getConfig()
            .then(function () {
                if (!$scope.complaintId && !!$scope.complaint) {
                    $scope.complaintId = $scope.complaint.ComplaintId;
                }
                if (['outputlist', 'outputform', 'inputWithGet'].includes($scope.mode) && !!$scope.complaintId) {
                    $scope.getComplaintQuestions($scope.complaintId);
                }
            }, function () {

            });
        };

        $scope.$on('ResetComplaintQuestionnaire', function (event) {
            $scope.resetForm();
        });

        $scope.$on('SaveComplaintQuestionnaire', function (event, info) {
            if (!!info.complaint) {
                var q = $scope.current.questionnaire;
                var questions = [];
                var responseFields = $scope.uiConfig.Form.rows.recursiveSelect('columns').selectMany();

                //Iterate through all the response fields in order of their appearance
                for (var i = 0; i < responseFields.length; i++) {
                    if (!!responseFields[i].bindingField) {
                        //Get the corresponding content
                        var content = {};
                        var questionName = responseFields[i].bindingField;
                        if (!!responseFields[i].label) {
                            content = { ContentItemLkId: null, ContentItemLkDesc: responseFields[i].label };
                        } else if (!!responseFields[i].labelContent) {
                            content = $scope.content[responseFields[i].bindingField];
                        }

                        var questionNumber = $scope.questionNumberBase + i + 1;

                        var question = getNewComplaintQuestion(info.complaint, info.complaintCase, info.involvedParty, content, q[responseFields[i].bindingField], questionName, questionNumber, info.linkId, info.linkType);

                        //If an existing question of the same name exists, use that id
                        if (!!$scope.complaintQuestions) {
                            var complaintQuestionId = $scope.complaintQuestions.whereEquals(question.QuestionName, 'QuestionName').select('ComplaintQuestionId').firstOrDefault();
                            if (!!complaintQuestionId) {
                                question.ComplaintQuestionId = complaintQuestionId;
                            }
                        }
                        
                        questions.push(question);
                    }
                }

                //Now save
                //console.log(questions);
                $scope.saveComplaintQuestions(questions);
            }
        });

        $scope.$watch('current.questionnaire', function (newVal) {
            $scope.currentResponses = newVal;
        }, true);
    }
})();