(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeProviderCoursesController", BackofficeProviderLicenseController);

    BackofficeProviderLicenseController.$inject = ["$rootScope", "$scope", "$state", "bppService", "backofficeIndividualEducationService",
        "utilityService", "$exceptionHandler", "WebApiUrl", "BackofficeProviderService", "BackofficeIndividualService", "$filter", "ProviderMultipleFileUploadService", "typeValuesService", "TypeValue", "$q", "Messages"
    ];

    function BackofficeProviderLicenseController($rootScope, $scope, $state, bppService, backofficeIndividualEducationService,
        utilityService, $exceptionHandler, WebApiUrl, BackofficeProviderService, BackofficeIndividualService, $filter, ProviderMultipleFileUploadService, typeValuesService, TypeValue, $q, Messages) {

        //$scope.providerId = sessionStorage.ProviderId;
        var userId = sessionStorage.UserId;
        $scope.viewConfig = {};
        $scope.isBackoffice = true;
        $scope.natValidation = {};

        var initDocument = function (timeOut) {
            $scope.documentUpload = undefined;
            setTimeout(function () {
                $scope.$apply(function () {
                    $scope.ceCourseDocumentUploadPurpose = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                        // ApplicationId: $scope.applicationId,
                        ProviderId: $scope.ProviderId,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "CE",
                        LoadByDocIdAndDocCode: true,
                        DocId: 58,
                        DocCode: "D1058",
                        LoadDocumentsWithoutBroadCast: false,
                        LinkType: 'PR',
                        PropertyToProcess: 'ProviderCourseId',
                        IsLinkedDocControl: true
                    };

                    $scope.ceCourseDocumentUploadAttMonitor = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                        // ApplicationId: $scope.applicationId,
                        ProviderId: $scope.ProviderId,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "CE",
                        LoadByDocIdAndDocCode: true,
                        DocId: 59,
                        DocCode: "D1059",
                        LoadDocumentsWithoutBroadCast: false,
                        LinkType: 'AM',
                        PropertyToProcess: 'ProviderCourseId',
                        IsLinkedDocControl: true
                    };

                    $scope.ceCourseDocumentUploadCourseMaterial = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                        // ApplicationId: $scope.applicationId,
                        ProviderId: $scope.ProviderId,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "CE",
                        LoadByDocIdAndDocCode: true,
                        DocId: 60,
                        DocCode: "D1060",
                        LoadDocumentsWithoutBroadCast: false,
                        LinkType: 'CM',
                        PropertyToProcess: 'ProviderCourseId',
                        IsLinkedDocControl: true
                    };
                });
            }, timeOut);
        };

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, ProviderId) {
            if (ProviderId && ($rootScope.individualFirmCurrentTab.name == "courses")) {
                $scope.ProviderId = ProviderId;
                initDocument(0);
                if (!$scope.isLoadedOnce) {
                    refTableGetAll();
                    $scope.isLoadedOnce = true;
                }
                AuditVisitInfoSave();
                GetProviderStatusType();
                $scope.providerCECourseByProviderId($scope.ProviderId);
            }
        });

        var refTableGetAll = function () {
            ShowLoader();
            var dataPromises = [];
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=cecoursetype")));
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=cecoursecategory")));
            $q.all(dataPromises).then(function (response) {
                $scope.ceCourseTypes = response[0].ReferenceEntities;
                $scope.courseCategoryList = response[1].ReferenceEntities;
                //$scope.providerCECourseByProviderId($scope.ProviderId);
                HideLoader();
            }, function (error) {
                $scope.showStatusMessage(error, "error");
                HideLoader();
            });
        };

        var validateForm = function (currentForm, isFinalSave) {

            if (!currentForm.errorMessages)
                currentForm.errorMessages = [];
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);


            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.setSaveDocumentRef1 = function (fn) {
            $scope.saveAllDocumentRef1 = fn;
        };

        $scope.setSaveDocumentRef2 = function (fn) {
            $scope.saveAllDocumentRef2 = fn;
        };

        $scope.setSaveDocumentRef3 = function (fn) {
            $scope.saveAllDocumentRef3 = fn;
        };
        $scope.providerCECourseByProviderId = function (providerId, hideMessages, isInitLoad) {
            if (!isInitLoad)
                ShowLoader("Please Wait...");
            var dataSavePromise = bppService.providerCECourseByProviderId(providerId, "", sessionStorage.Key);
            dataSavePromise.then(function (response) {

                $scope.providerCECourses = response.ProviderCourseResponseList;
                angular.forEach($scope.providerCECourses, function (providerCECourseItem) {
                    angular.forEach($scope.ceCourseTypes, function (ceCourseType) {
                        if (ceCourseType.CECourseTypeId == providerCECourseItem.CourseTypeId) {
                            providerCECourseItem.CourseTypeName = ceCourseType.CECourseTypeName;
                        }
                    });
                });
                if ($scope.providerCECourses)
                    $scope.totalCourses = $scope.providerCECourses.length;
                // if (hideMessages) {
                //     $scope.hideStatusMessage();
                //     $scope.hideValidationSummary();
                // }
                // if (!isInitLoad) {
                processListData();
                HideLoader();
                //     if ($scope.checkServerResponse({ response: response })) {
                //         //$scope.providerCECourseList = processAssignmentDescValue(response.ProviderAssignmentList);
                //     }
                // }
            }, function (error) {
                $scope.showStatusMessage(error, "error");
            });
            return dataSavePromise;
        };

        $scope.openAddEditCeCourse = function (isCeCourseEdit, providerCeCourse) {
            initDocument(1000);
            $scope.addEditMode = true;
            $scope.isCeCourseEdit = isCeCourseEdit;

            $scope.providerCeCourse = angular.copy(providerCeCourse);
            // $scope.CourseForId = $scope.providerCeCourse.CourseForId;

            $scope.viewConfig.showAddBlock = true;
        };

        $scope.ceCourseItemClick = function (providerCECourse) {
            $scope.providerCECourse = providerCECourse;
            providerCECourse.displayDocuments = true;
            $scope.providerDocumentGetByProviderId();
        }

        var GetProviderStatusType = function () {
            if (!$scope.BusinessStatus) {
                // BackofficeProviderService.GetProviderStatusType(sessionStorage.Key)
                typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=refproviderlicensestatus"))
                    .then(function (res) {
                        if (res.Status) {
                            $scope.BusinessStatus = res.ReferenceEntities;
                            getLicenseStatus();
                        }
                    });
            }
        };

        $scope.closeAddEditCeCourse = function () {
            $scope.viewConfig.showAddBlock = false;
        };

        $scope.DownloadDocumentAPIURL = WebApiUrl + 'Individual/DownloadPDF/' + sessionStorage.Key + '?path=';
        $scope.downloadDocument = function (path) {
            window.open($scope.DownloadDocumentAPIURL + path, '_blank');
        };

        $scope.getDocument = function (provDocID) {
            try {
                ShowLoader("Please wait...");
                ProviderMultipleFileUploadService.providerDocumentByProviderDocumentId(sessionStorage.Key, provDocID)
                    .then(function (response) {
                        var headers = response.headers();
                        response = response.data;
                        utilityService.downloadExcelFile(response, headers);
                        HideLoader();
                    });
            } catch (ex) {
                showStatusMessage(ex.message, "error");
                HideLoader();
                $exceptionHandler(ex);
            }
        };

        $scope.providerDocumentGetByProviderId = function () {
            try {
                ShowLoader("Please wait...");
                ProviderMultipleFileUploadService.providerDocumentGetByProviderIdDocId(sessionStorage.Key, $scope.ProviderId, 0)
                    .then(function (response) {
                        $scope.providerCECourse.documentsList = [];
                        hideStatusMessage();
                        hideValidationSummary();
                        if ($scope.checkServerResponse(response)) {
                            if (response.ProviderDocumentGET != null) {
                                angular.forEach(response.ProviderDocumentGET, function (pdocItem) {
                                    if (pdocItem.DocumentLkToPageTabSectionCode == 'CE' && $scope.providerCECourse.ProviderCourseId == pdocItem.LinkId) {
                                        $scope.providerCECourse.documentsList.push(pdocItem);
                                    }
                                });
                                // if (!!$scope.providerDocumentUpload && !!$scope.providerDocumentUpload.DocId) {
                                //     $scope.documentsList = $filter('filter')(response.ProviderDocumentGET, function (doc) {
                                //         return (doc.DocumentId === $scope.providerDocumentUpload.DocId &&
                                //             (!$scope.providerDocumentUpload.ApplicationId || ($scope.providerDocumentUpload.ApplicationId == doc.ApplicationId)) &&
                                //             (!!$scope.referenceNumber ? (doc.ReferenceNumber == $scope.referenceNumber) : true));
                                //     });
                                //     $scope.providerDocumentUpload.documentCount = $scope.documentsList.length;
                                // } else {
                                //     $scope.documentsList = response.ProviderDocumentGET;
                                //     $scope.providerDocumentUpload.documentCount = $scope.documentsList.length;
                                // }
                            }
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                showStatusMessage(ex.message, "error");
                $exceptionHandler(ex);
            }
        };

        var processListData = function () {
            if ($scope.providerCECourses && $scope.providerCECourses.length > 0) {
                ProviderMultipleFileUploadService.providerDocumentProcess(sessionStorage.Key, $scope.ProviderId, $scope.ceCourseDocumentUploadPurpose.DocId,
                    $scope.providerCECourses, $scope.ceCourseDocumentUploadPurpose.PropertyToProcess, $scope.ceCourseDocumentUploadPurpose.LinkType, "ProviderDocumentListPurpose").then(function (responseData) {
                        $scope.providerCECourses = responseData;
                        console.log('data processed', responseData);
                    });
                ProviderMultipleFileUploadService.providerDocumentProcess(sessionStorage.Key, $scope.ProviderId, $scope.ceCourseDocumentUploadAttMonitor.DocId,
                    $scope.providerCECourses, $scope.ceCourseDocumentUploadAttMonitor.PropertyToProcess, $scope.ceCourseDocumentUploadAttMonitor.LinkType, "ProviderDocumentListAttendance").then(function (responseData) {
                        $scope.providerCECourses = responseData;
                        console.log('data processed', responseData);
                    });
                ProviderMultipleFileUploadService.providerDocumentProcess(sessionStorage.Key, $scope.ProviderId, $scope.ceCourseDocumentUploadCourseMaterial.DocId,
                    $scope.providerCECourses, $scope.ceCourseDocumentUploadCourseMaterial.PropertyToProcess, $scope.ceCourseDocumentUploadCourseMaterial.LinkType, "ProviderDocumentListCourseMaterial").then(function (responseData) {
                        $scope.providerCECourses = responseData;
                        console.log('data processed', responseData);
                    });
            }
        };
        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        function AuditVisitInfoSave() {

            var data = {
                "PageName": "IndividualInfo-License",
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.IndividualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        }

        $scope.saveCECourse = function (newCECourseForm, isFinalSave) {
            //$scope.providerCeCourse.CourseForId = $scope.CourseForId;
            $scope.hideStatusMessage();
            $scope.hideValidationSummary();
            newCECourseForm.$submitted = true;
            if (validateForm(newCECourseForm, isFinalSave)) {
                ShowLoader("Please wait...");
                $scope.saveCECourseRecord();
            }
        };

        $scope.deleteCeCourse = function (providerCECourse) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                ShowLoader("Please Wait...");
                $scope.providerCeCourse = angular.copy(providerCECourse);
                $scope.providerCeCourse.IsDeleted = true;
                $scope.saveCECourseRecord();
            }
        };

        $scope.saveCECourseRecord = function () {
            bppService.providerCECourseSave([$scope.providerCeCourse],
                sessionStorage.Key).then(function (response) {
                    if ($scope.checkServerResponse(response)) {
                        if (response.ProviderCourseList && response.ProviderCourseList.length > 0) {
                            $scope.ceCourseDocumentUploadPurpose.LinkId = response.ProviderCourseList[0].ProviderCourseId;
                            $scope.ceCourseDocumentUploadAttMonitor.LinkId = response.ProviderCourseList[0].ProviderCourseId;
                            $scope.ceCourseDocumentUploadCourseMaterial.LinkId = response.ProviderCourseList[0].ProviderCourseId;
                            if (typeof $scope.saveAllDocumentRef1 == "function" && typeof $scope.saveAllDocumentRef2 == "function" && typeof $scope.saveAllDocumentRef3 == "function") {
                                $q.all([$scope.saveAllDocumentRef1(), $scope.saveAllDocumentRef2(), $scope.saveAllDocumentRef3()])
                                    .then(function (responseDoc) {
                                        $scope.providerCECourseByProviderId($scope.ProviderId);
                                        $scope.closeAddEditCeCourse();
                                        $scope.showStatusMessage(responseDoc.Message, "success");
                                    });
                            } else {
                                $scope.providerCECourseByProviderId($scope.ProviderId);
                                $scope.closeAddEditCeCourse();
                                $scope.showStatusMessage(responseDoc.Message, "success");
                            }
                        } else {
                            $scope.providerCECourseByProviderId($scope.ProviderId);
                            $scope.closeAddEditCeCourse();
                            $scope.showStatusMessage(responseDoc.Message, "success");
                        }
                    }
                    $scope.addEditMode = false;
                    HideLoader();
                }, function (error) {
                    HideLoader();
                    $scope.showStatusMessage(error, "error");
                });
        };
    }
})();
