(function () {
    'use strict'
    angular.module('app.report')
        .controller('TrialBalanceReportController', TrialBalanceReportController);

    TrialBalanceReportController.$inject = ['$scope', '$q', '$controller', '$filter',
        'reportService', '$state', 'utilityService', 'ApiUrlsForReportsXls', 'typeValuesService'
    ];

    function TrialBalanceReportController($scope, $q, $controller, $filter, reportService, $state, utilityService, ApiUrlsForReportsXls, typeValuesService) {
        $controller('GlobalController', { $scope: $scope })

        // Pagination
        $scope.Pager = {
            BatchNumber: null,
            UserId: parseInt(sessionStorage.UserID) 
        };

        $scope.resetReport = function () {
            $scope.Pager.BatchNumber = null;
        }

        $scope.getExcelReport = function () {
            try {
                ShowLoader('Please Wait...')
                console.log(sessionStorage);
                $scope.Pager.UserId = parseInt(sessionStorage.UserID);
                reportService.trialBalanceReportPDF(sessionStorage.Key, $scope.Pager, ApiUrlsForReportsXls.TrialBalance)
                    .then(function (response) {
                        HideLoader()
                    }, function (data) {
                        HideLoader()
                        showStatusMessage(data, 'error')
                    })
            } catch (ex) {
                HideLoader()
                if (ex != null) { showStatusMessage(ex.message, 'error'); }
            }
        }

    };
})();