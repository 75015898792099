(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .controller('DirectiveTestingController', ['$scope', '$state', '$timeout', '$templateCache', 'utilityService', DirectiveTestingController]);

    function DirectiveTestingController($scope, $state, $timeout, $templateCache, utilityService) {

        $scope.init = function () {
            $scope.individualId = 65451;
            $scope.applicationId = 38540;
            $scope.isBackoffice = true;

            $scope.applicationInfo = {
                "IsDeleted": false,
                "IsArchive": false,
                "CreatedBy": 29,
                "CreatedOn": "2019-05-31T20:31:15",
                "ModifiedBy": null,
                "ModifiedOn": null,
                "ApplicationGuid": "ef921106-840c-11e9-a822-02d176892638",
                "ApplicationId": $scope.applicationId,
                "ApplicationTypeId": 1,
                "ApplicationStatusId": 14,
                "ApplicationStatusReasonId": null,
                "ApplicationNumber": "190531203188",
                "ApplicationSubmitMode": "B",
                "StartedDate": "2019-05-31T20:31:15",
                "SubmittedDate": null,
                "ApplicationStatusDate": "2019-05-31T20:31:15",
                "PaymentDeadlineDate": null,
                "PaymentDate": null,
                "ConfirmationNumber": null,
                "ReferenceNumber": null,
                "IsFingerprintingNotRequired": true,
                "IsPaymentRequired": true,
                "CanProvisionallyHire": false,
                "GoPaperless": true,
                "LicenseRequirementId": null,
                "WithdrawalReasonId": null,
                "LicenseTypeId": null,
                "LicenseTypeName": null,
                "IsActive": true,
                "ApplicationType": null,
                "ApplicationStatus": null,
                "IsPaid": false,
                "ApplicationInternalStatusId": null,
                "HighPriority": false,
                "ReceivedDate": "2019-05-31T00:00:00",
                "LicenseApplicationForId": null,
                "LicensePathwayLevel1Id": null,
                "LicenseExamTypeId": null,
                "LicensePathwayLevel2Id": null,
                "LicensePathwayLevel3Id": null,
                "EntityName": null,
                "CountyId": null,
                "StateId": null,
                "DeficiencyStatusId": null,
                "DeficiencyMarkedBy": null,
                "DnqStatusId": null,
                "DnqMarkedBy": null,
                "SpecialityTypeId": null,
                "FormValidation": null,
                "LicensePathwayName": null,
                "DeficientMarkedDate": null,
                "DnqMarkedDate": null,
                "PermitTypeId": null,
                "PermitApplicationTypeId": null,
                "ExamTaken": null,
                "ResubmitDate": null,
                "ResubmittedBy": null,
                "ResubmitRequired": false,
                "IsTemporary": false,
                "Message": null,
                "RecordId": 0,
                "Status": false,
                "StatusCode": null,
                "ResponseId": null,
                "ResponseReason": null
            };

            $scope.individualLicense = {
                "CreatedBy": 0,
                "CreatedOn": "0001-01-01T00:00:00",
                "ModifiedBy": 29,
                "ModifiedOn": "2019-06-04T13:55:42",
                "IndividualLicenseGuid": "123",
                "RenewalApplicationGrouping": 3,
                "RenewalApplicationHistoryQuestionContentId": "55,56,57,58,59,60,61,62,63,64,65,66,67,68,69",
                "FeeAmount": 300,
                "IndividualLicenseId": 402252,
                "IndividualId": $scope.individualId,
                "ApplicationId": $scope.applicationId,
                "LicenseTypeId": 33,
                "LicenseNumber": "MD.206836",
                "OriginalLicenseDate": "2014-04-16T00:00:00",
                "LicenseEffectiveDate": "2019-07-01T00:00:00",
                "LicenseExpirationDate": "2021-06-30T00:00:00",
                "LicenseStatusTypeId": 7,
                "IsActive": true,
                "LicenseTypeName": "PHYSICIAN",
                "LicenseInternalTypeId": 0,
                "Description": "Renewal Period from 7/1/2019 to 6/30/2021 ",
                "OriginalLicenseDateStr": "04/16/2014",
                "LicenseEffectiveDateStr": "07/01/2019",
                "LicenseExpirationDateStr": "06/30/2021",
                "applicationType": "active",
                "currentApplicationType": "active"
            };

            $scope.individualViewConfig = {
                processedTabs: [{
                    "name": "cdsCme",
                    "text": "CDS-CME",
                    "isEnabled": true,
                    "tabNumber": 15,
                    "queues": ["IntakeQ", "DeficiencyQ", "AnalystQ", "DirectorQ", "AdminQ", "DNQ"],
                    "renewalGroups": [3],
                    "deficiencyData": [],
                    "isTabDeficient": false,
                    "isTabDeficiencyResolved": true,
                    "isTabDefeciencyReviewedByIndividual": true,
                    "isActiveTab": true,
                    "showAfterRenewalTabs": true,
                    "isLoadedOnce": true
                }, {
                    "name": "testTab",
                    "text": "TestTab",
                    "isEnabled": true,
                    "tabNumber": 1000,
                    "queues": ["IntakeQ", "DeficiencyQ", "AnalystQ", "DirectorQ", "AdminQ", "DNQ"],
                    "renewalGroups": [1, 2, 3, 4, 5],
                    "deficiencyData": [],
                    "isTabDeficient": false,
                    "isTabDeficiencyResolved": true,
                    "isTabDefeciencyReviewedByIndividual": true,
                    "isActiveTab": true,
                    "showAfterRenewalTabs": true,
                    "isLoadedOnce": false
                }]
            };
            $scope.validator = {};
            angular.forEach($scope.individualViewConfig.processedTabs, function (tab) {
                $scope.validator[tab.name] = {};
            });

            $scope.tab = $scope.individualViewConfig.processedTabs[0];
            $scope.individualCurrentTab = $scope.individualViewConfig.processedTabs[0];

            $scope.displayApplication = true;

            $scope.openNextTab = function (currentTab) {
                for (var i = 0; i < $scope.individualViewConfig.processedTabs.length; i++) {
                    if ($scope.individualViewConfig.processedTabs[i].name == currentTab.name) {
                        $scope.individualViewConfig.processedTabs[i].isSaved = true;
                        for (var j = i + 1; j < $scope.individualViewConfig.processedTabs.length; j++) {
                            if ($scope.individualViewConfig.processedTabs[j].isEnabled === true) {
                                if (currentTab.name == "renewaltype") {
                                    $scope.processTabsToBeShownAsPerGroup();
                                }
                                $scope.setCurrentTab($scope.individualViewConfig.processedTabs[j]);
                                break;
                            }
                        }
                        break;
                    }
                }
            };
            $scope.setCurrentTab = function (tab) {
                tab.isSaved = false;

                if (tab != null) {
                    if (tab.name == 'feeAndPayment') {
                        var isAlltabsComplete = true;
                        for (var i = 0; i < $scope.individualViewConfig.processedTabs.length; i++) {
                            if ($scope.individualViewConfig.processedTabs[i].name == 'feeAndPayment') {
                                break;
                            }
                            if (!$scope.individualViewConfig.processedTabs[i].isSaved && $scope.individualViewConfig.processedTabs[i].isEnabled)
                                isAlltabsComplete = false;
                        }
                        if (!isAlltabsComplete) {
                            if (!!$scope.openPaymentTab) {
                                for (var i = 0; i < $scope.individualViewConfig.processedTabs.length; i++) {
                                    if (!$scope.individualViewConfig.processedTabs[i].isSaved && $scope.individualViewConfig.processedTabs[i].isEnabled) {
                                        tab = $scope.individualViewConfig.processedTabs[i];
                                        break;
                                    }
                                }
                            } else {
                                return;
                            }
                        }
                    }
                    var isAlltabsSaved = true;
                    var isAlltabsSavedExceptPayment = true;
                    for (var i = 0; i < $scope.individualViewConfig.processedTabs.length; i++) {
                        $scope.individualViewConfig.processedTabs[i].isActiveTab = false;
                        if (!$scope.individualViewConfig.processedTabs[i].isSaved && $scope.individualViewConfig.processedTabs[i].isEnabled) {
                            isAlltabsSaved = false;
                            if ($scope.individualViewConfig.processedTabs[i].name != 'feeAndPayment') {
                                isAlltabsSavedExceptPayment = false;
                            }
                        }
                    }
                    $scope.individualViewConfig.isAlltabsSaved = isAlltabsSaved;
                    $scope.individualViewConfig.isAlltabsSavedExceptPayment = isAlltabsSavedExceptPayment;
                    tab.isActiveTab = true;
                    $scope.individualCurrentTab = tab;
                    tab.isLoadedOnce = true;
                    if (!!tab && tab.name != 'licenseEndorsement')
                        utilityService.scrollToElement("newApplicationForm");
                }
            };

        };

        $scope.reloadState = function () {
            // $('script').each(function () {
            //     if ($(this).attr('src').indexOf('assets/js/jquery.min.js') != -1) {
            //         var old_src = $(this).attr('src');
            //         $(this).attr('src', '');
            //         setTimeout(function () {
            //             $(this).attr('src', old_src + '?' + new Date());
            //         }, 250);
            //     }
            // });
            // $timeout(function () {
            $templateCache.removeAll();
            $state.reload();
            // })
        };

        $scope.init();
    }
})();