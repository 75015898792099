(function () {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("IndividualCbcController", IndividualCbcController);

    IndividualCbcController.$inject = ["$rootScope", "$scope", '$q', "backofficeIndividualCbcService", "typeValuesService", "TypeValue"];

    function IndividualCbcController($rootScope, $scope, $q, backofficeIndividualCbcService, typeValuesService, TypeValue) {

        var getNewBackgroundCheck = function () {
            return {
                IndividualId: $scope.individualId,

                FBIFingerPrintDate: null,
                DOJFingerPrintDate: null,

                FBIFingerPrintStatus: null,
                DOJFingerPrintStatus: null,

                FirstRejectionDate: null,
                SecondRejectionDate: null,
                ThirdRejectionDate: null,

                MailedDate: null,
                DoneLocallyDate: null,

                Comment: '',

                IsActive: true,
                IsDeleted: false
            };
        };

        $scope.initCBC = function () {
            if (!$scope.current) {
                $scope.current = {};
            }
            if (!$scope.uiLogic) {
                $scope.uiLogic = {};
            }
            $scope.current.backgroundcheck = null;

            $scope.individualBackgroundcheckGet($scope.individualId);
        };

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "CBC") {
                $scope.individualId = individualId;
                $scope.initCBC();
            }
        });

        $scope.individualBackgroundcheckGet = function (IndividualId) {
            if (!!$scope.loadingIndividualCbc) {
                $scope.loadingIndividualCbc(true, 'Loading...');
            }
            backofficeIndividualCbcService.individualBackgroundcheckGet(sessionStorage.Key, IndividualId, null, null).then(function (response) {
                if (response.Status) {
                    if (response.IndividualBackgroundcheckList && response.IndividualBackgroundcheckList.length > 0) {
                        $scope.current.backgroundcheck = response.IndividualBackgroundcheckList[0];
                    } else {
                        $scope.current.backgroundcheck = getNewBackgroundCheck();
                    }
                } else {
                    $scope.showStatusMsg('-', response.Message);
                }
                $scope.loadingIndividualCbc(false);
            }, function (error) {
                $scope.loadingIndividualCbc(false);
                $scope.showStatusMsg('-', error);
            });

            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=backgroundcheckstatus")).then(function (response) {
                $scope.backgroundcheckstatusList = response.ReferenceEntities;
            }, function (error) {
                $scope.showStatusMsg('-', error);
            });
        };

        $scope.saveIndividualCBC = function () {
            var deferred = $q.defer();
            
            try {
                $scope.loadingIndividualCbc(true, 'Saving...');

                backofficeIndividualCbcService.individualBackgroundcheckSave(sessionStorage.Key, [$scope.current.backgroundcheck])
                .then(function(data) {
                    if (data.Status) {
                        $scope.logDataChanges();
                        $scope.current.backgroundcheck = data.IndividualBackgroundcheckList.firstOrDefault();
                        $scope.uiLogic.isEditingCBC = false;
                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                    $scope.loadingIndividualCbc(false);
                }, function(err) {
                    deferred.reject(err);
                    $scope.loadingIndividualCbc(false);
                });
            } catch (ex) {
                deferred.reject(ex);
                $scope.loadingIndividualCbc(false);
            }
            
            return deferred.promise;
        };

        //$scope.initCBC();
    }
})();