(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("ReferenceFormApplicantController", ReferenceFormApplicantController);

    ReferenceFormApplicantController.$inject = ["$state", "$scope", "$rootScope",
        "referenceFormApplicationService", "utilityService", "initialApplicationExperiencService", "$stateParams", "countryStateService", "$q", "contentService", 'Messages'];

    function ReferenceFormApplicantController($state, $scope, $rootScope,
        referenceFormApplicationService, utilityService, initialApplicationExperiencService, $stateParams, countryStateService, $q, contentService, Messages) {

        $scope.referenceShowForm = false;
        $scope.reference = {};
        $scope.professionalReferences = [];
        $scope.natValidation = {};

        $scope.VerificationTypeId = $state.current.routeData.VerificationTypeId;
        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'MM/yyyy',
            opformat: 'MM/dd/yyyy',
            endDateOptions: {
                minDate: null,
                datepickerMode: 'month',
                minMode: 'month'
            },
            startDateOptions: {
                datepickerMode: 'month',
                minMode: 'month'
            }
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        }

        $scope.setLicenseToMinDate = function () {
            if (angular.isDate($scope.reference.ContactFrom)) {
                $scope.datePicker.endDateOptions.minDate = $scope.reference.ContactFrom;
            }
        };

        $scope.setLicenseFromMaxDate = function () {
            if (angular.isDate($scope.reference.ApplicantContactTo)) {
                $scope.datePicker.startDateOptions.maxDate = $scope.reference.ApplicantContactTo;
            }
        };

        var validateForm = function (currentForm, isNext) {
            $scope.natValidation.errorMessages = [];
            if (currentForm) {
                if (!currentForm.errorMessages)
                    currentForm.errorMessages = [];
                currentForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            }


            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                utilityService.scrollTop();
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.showRefrenceAddUpdated = function () {
            $scope.showAddUpdateForm = true;
            $scope.reference = {};
            $scope.reference.CountryId = $rootScope.DefaultCountryId;
            $scope.reference.StateCd = 'AL';
        }

        $scope.hideRefrenceAddUpdated = function () {
            $scope.showAddUpdateForm = false;
            $scope.reference = {};
            $scope.currentForm = null;
            $scope.isEdit = false;
        }

        $scope.saveReferenceForm = function (currentForm) {
            ShowLoader();
            if (!validateForm(currentForm)) {
                HideLoader();
                return;
            }

            referenceFormApplicationService.saveEntityVerification([$scope.reference], sessionStorage.Key)
                .then(function (data) {
                    if (data.Status) {
                        if ($scope.VerificationTypeId == 1) {
                            $scope.professionalReferenceData.isReferenceComplete = true;
                            $scope.professionalReferenceData.ReferenceFormCompleteDate = new Date();
                            referenceFormApplicationService.saveIndividualReference([$scope.professionalReferenceData], sessionStorage.Key)
                                .then(function (data) {
                                    if (data.Status) {
                                        $scope.referenceShowForm = false;
                                        showStatusMessage(Messages.afeadce, 'success');
                                    }
                                    HideLoader();
                                }, function (error) {
                                    HideLoader();
                                });
                        }
                        else {
                            $scope.professionalReferenceData.isVerificationComplete = true;
                            $scope.professionalReferenceData.VerificationFormCompleteDate = new Date();
                            initialApplicationExperiencService.saveExperience([$scope.professionalReferenceData], sessionStorage.Key).then(function (response) {
                                $scope.referenceShowForm = false;
                                if (response.Status && response.StatusCode == "00") {
                                    showStatusMessage(Messages.eceabad, 'success');
                                }
                                HideLoader();
                            }, function (error) {
                                HideLoader();
                            });
                        }


                    }
                    else {
                        HideLoader();
                    }
                }, function (error) {
                    HideLoader();
                });
        }



        $scope.init = function () {
            ShowLoader();
            var dataSavePromises = [];
            dataSavePromises.push(countryStateService.getStateByCountryID('Key', $rootScope.DefaultCountryId));
            if ($scope.VerificationTypeId == 1) {
                dataSavePromises.push(referenceFormApplicationService.getIndividualReference('Key', $stateParams.id, 'ALBELSReferenceForm'));
            }
            else {
                dataSavePromises.push(referenceFormApplicationService.getIndividualExperience($stateParams.id, 'ALBELSVerificationForm'));
            }
            $q.all(dataSavePromises)
                .then(function (response) {
                    var responseStatus = true;
                    var responseMessage = '';
                    for (var i = 0; i < response.length; i++) {
                        if (response[i].Status == false) {
                            responseStatus = false;
                            responseMessage = response[i].Message;
                            break;
                        }
                    }
                    if (responseStatus == true) {
                        $scope.StatList = response[0].State;
                        $scope.PlaceOfBirthStates = response[0].State;
                        if ($scope.VerificationTypeId == 1) {
                            if (response[1].IndividualReferenceList) {
                                $scope.professionalReferenceData = response[1].IndividualReferenceList[0];
                            }
                        }
                        else {
                            if (response[1].IndividualExperienceList) {
                                $scope.professionalReferenceData = response[1].IndividualExperienceList[0];
                                if ($scope.professionalReferenceData.TerminationDate && $scope.professionalReferenceData.EmploymentDate) {
                                    $scope.professionalReferenceData.months = utilityService.getDatesDiff($scope.professionalReferenceData.TerminationDate, $scope.professionalReferenceData.EmploymentDate);
                                }
                            }
                        }
                        //$scope.professionalReferenceData.isVerificationComplete = false;
                    }
                    if (($scope.VerificationTypeId == 1 && $scope.professionalReferenceData && !$scope.professionalReferenceData.isReferenceComplete) || ($scope.VerificationTypeId == 2 && $scope.professionalReferenceData && !$scope.professionalReferenceData.isVerificationComplete)) {
                        referenceFormApplicationService.getEntityVerification($scope.professionalReferenceData.IndividualId, 'I', $scope.professionalReferenceData.ApplicationId, $scope.VerificationTypeId, $scope.professionalReferenceData.IndividualReferenceId, $scope.professionalReferenceData.IndividualExperienceId).then(function (response) {
                            HideLoader();
                            $scope.referenceShowForm = true;
                            if (response.EntityRecords && response.EntityRecords.length > 0) {
                                $scope.reference = response.EntityRecords[0];
                            }
                            else {
                                $scope.reference = {
                                    VerificationTypeId: $scope.VerificationTypeId,
                                    IsActive: true,
                                    IsDeleted: false,
                                    IndividualReferenceId: $scope.VerificationTypeId == 1 ? $scope.professionalReferenceData.IndividualReferenceId : null,
                                    IndividualExperienceId: $scope.VerificationTypeId == 2 ? $scope.professionalReferenceData.IndividualExperienceId : null,
                                    EntityId: $scope.professionalReferenceData.IndividualId,
                                    ApplicationId: $scope.professionalReferenceData.ApplicationId,
                                    EntityType: 'I'
                                };
                            }
                        }, function (error) {
                            HideLoader();
                        });
                    }
                    else {
                        if ($scope.VerificationTypeId == 1) {
                            showStatusMessage(Messages.cdddddd, 'success');
                        }
                        else {
                            showStatusMessage(Messages.dacabfb, 'success');
                        }
                        HideLoader();
                    }
                }, function (error) {

                });
        }


        $scope.init();

    }
})();
