(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialProviderApplicationFeePayment', initialProviderApplicationFeePayment);

    initialProviderApplicationFeePayment.$inject = [];

    function initialProviderApplicationFeePayment() {
        return {
            restrict: 'E',
            scope: {
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                providerId: "=providerId",
                ApplicationId: "=applicationId",
                UserId: "=userId",
                isRenewal: '=?isRenewal',
                isBackoffice: "=?isBackoffice",
                personalInfo: "=?personalInfo",
                personalData: "=?personalData",
                currentRenewalItem: "=currentRenewalItem",
                applicationStatusList: "=applicationStatusList",
                natValidation: "=natValidation",
                feeDetails: "=feeDetails",
                providerLicense: "=providerLicense",
                legalInfoList: "=legalInfoList",
                sourceSuffix: "=sourceSuffix",
                applicationTypeName: "=?applicationTypeName",
                applicationInfo: "=?applicationInfo",
                feeDetailRows: "=feeDetailRows",
                isMiscPayment: '=?isMiscPayment',
                paymentStatusUpdate: "&",
                dashboard: '=?dashboard',
                processedtab: '=processedtab',
                ceCourseList:'=?ceCourseList',
                providerIndList:'=?providerIndList',
                providerBranches:'=?providerBranches',
                transactionType: "=?transactionType",
                openNextTab: "&",
            },
            templateUrl: "app/components/provider/directive/provider-fee-payment/fee-payment.html",
            controller: "InitialProviderApplicationFeePaymentController"
        }
    }
})();