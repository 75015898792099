(function () {
    'use strict'
    angular.module('app.core')
        .directive('multipleFileUpload', multipleFileUploadDirective);

    multipleFileUploadDirective.$inject = [];

    function multipleFileUploadDirective() {
        return {
            restrict: 'E',
            scope: {
                individualDocumentUpload: '=individualDocumentUpload',
                showSubmitOptions: '=showSubmitOptions',
                showListing: '=showListing',
                documentsList: '=documentsList',
                preSelectFirstDocumentType: "=?preSelectFirstDocumentType",
                hideForm: "=?hideForm",
                documentNameCaption: "=?documentNameCaption",
                hideDocumentName: "=?hideDocumentName",
                hideDocumentType: "=?hideDocumentType",
                processLoader: "=?processLoader",
                showSorting: "=?showSorting",
                showFilter: "=?showFilter",
                hideastric: "=?hideastric",
                referenceNumber: "=?referenceNumber",
                disableForm: "=disableForm",
                isEmailSend: "=?isEmailSend",
                saveDocument: "&?",
                minimumfilerequired: "=?",
                allowInlineDocNameEdit: "=?",
                hideasterisk: '=asterisk',
                listingTemplateUrl: "=?"
            },
            link: function (scope, element, attrs) {
                try {
                    if (typeof scope.saveDocument == 'function') {
                        scope.saveDocument({
                            theDirFn: scope.individualDocumentSave
                        });
                    }
                    angular.extend(scope.individualDocumentUpload, {
                        individualDocumentSave: scope.individualDocumentSave
                    });
                } catch (ee) {
                    console.log(ee);
                }
            },
            templateUrl: "app/core/directive/multiple-file-upload/multiple-file-upload.html",
            controller: "MultipleFileUploadController",
            // link: function (scope) {
            //     angular.extend(scope.individualDocumentUpload, {
            //         individualDocumentSave: scope.individualDocumentSave
            //     });
            // }
        }
    }
})();