angular.module('natApp').factory('LicenseRenewalHASnAService', ['$http', '$q', 'WebApiUrl', '$rootScope', 'AppConfig', function ($http, $q, WebApiUrl, $rootScope, AppConfig) {
    return {
        sendData: function (data) {
            this.data = data;
            $rootScope.$broadcast('data_shared');
        },
        IndividualRenewalSave: function (key, IndividualRenewal) {

            return $http.post(WebApiUrl + 'Renewal/IndividualRenewalSave/' + key, {
                IndividualRenewal: IndividualRenewal
            });
        },
        Delete: function (key) {

            return $http.post(WebApiUrl + +key);
        },
        Update: function (key) {

            return $http.get(WebApiUrl + +key);
        },
        GetList: function (key) {

            return $http.get(WebApiUrl + +key);
        },
        IndividualRenewalGet: function (key, IndividualId, isBackoffice, viewClick, LicenseTypeId, IndividualLicenseId, LicenseNumber) {
            var source = (!!isBackoffice) ? "BackOffice" : "Online";
            if (viewClick) {
                source = "view";
            }
            return $http.get(WebApiUrl + 'Renewal/NVPharmacyIndividualRenewalGet/' + key + '?Source=' + source + '&IndividualId=' + IndividualId + '&IsBackoffice=' + isBackoffice + '&LicenseTypeId=' + LicenseTypeId + '&IndividualLicenseId=' + IndividualLicenseId + '&LicenseNumber=' + LicenseNumber);
        },
        ContactTypeGetAll: function (key) {

            return $http.get(WebApiUrl + 'TypeValues/ContactTypeGetAll/' + key);
        },
        GetServerDate: function () {
            return $http.get(WebApiUrl + 'Common/ServerDateTimeGet');
        },
        ApplicationTypeGetAll: function (key) {
            return $http.get(WebApiUrl + 'TypeValues/ApplicationTypeGetAll/' + key);
        },
        LicenseTypeGetAll: function (key) {
            return $http.get(WebApiUrl + 'TypeValues/LicenseTypeGetAll/' + key);
        },
        assignApplications: function (key, data) {
            return returnDefferedResult($http.post(WebApiUrl + 'Application/AssignApplications/' + key, data));
        }
    };

    function returnDefferedResult(data) {
        var deferred = $q.defer();
        data.then(function (data) {
            data = data.data;
            deferred.resolve(data);
        }, function (error) {
            error = error.data;
            deferred.reject(error);
        });
        return deferred.promise;
    };
}]);