(function () {
    'use strict'
    angular.module('app.core')
    .directive('selectedOption', [function () {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function ($scope, element, attrs, model) {
                if (!!attrs.ngModel) {
                    var val = $scope.$eval(attrs.ngModel);
                    if (val == '') {
                        model.$setViewValue(null);
                    }
                }

                element.on('change', function () {
                    var selectedIndex = element[0].selectedIndex;
                    var option = angular.element(element[0][element[0].selectedIndex]);
                    angular.element(option).attr('selected', 'selected');

                    for (var i = 0; i < element[0].childElementCount; i++) {
                        if (i != selectedIndex) {
                            option = angular.element(element[0][i]);
                            angular.element(option).removeAttr('selected');    
                        }
                    }
                });
            }
        };
    }])
    .directive('inlumonRefTableLoader', inlumonRefTableLoaderDirective);

    inlumonRefTableLoaderDirective.$inject = ['$http', '$q', '$compile', 'WebApiUrl'];

    function inlumonRefTableLoaderDirective($http, $q, $compile, WebApiUrl) {
        var getNewElementHtml = function ($scope, element, attrs) {
            var directiveAttrs = ['look-for', 'table-key', 'list-name', 'on-loaded', 'show-select', 'select', 'label', 'list', 'is-multi-select', 'is-type-ahead'];
            var DOM = '';

            if (!!$scope.isMultiSelect) {
                DOM = angular.element('<multi-select select="{0}" label="{1}" array="{2}" default="{3}"></multi-select>'
                    .format($scope.select, $scope.label, $scope.array, $scope.default));
            } else if (!!$scope.isTypeAhead) {
                var select = '';
                if (!$scope.select) {
                    select = 'item';
                } else {
                    select = 'item.' + $scope.select;
                }

                var label = '';
                if (!$scope.label) {
                    label = 'item';
                } else {
                    label = 'item.' + $scope.label;
                }

                DOM = angular.element('<input type="text" uib-typeahead="{0} as {1} for item in ({2} | filter:$viewValue | limitTo:8)" \
                    typeahead-editable="false" />'
                .format(select, label, $scope.array));
            } else {
                DOM = angular.element(' <select selected-option="{1}"><option value="">{0}</option></select>'
                                        .format($scope.default, $scope.array));

                var select = '';
                if (!$scope.select) {
                    select = 'item';
                } else {
                    select = 'item.' + $scope.select;
                }

                DOM.attr('ng-options', '{0} as item.{1} for item in {2}'.format(select, $scope.label, $scope.array));
                if ($scope.onChanged) {
                    DOM.attr('ng-change', $scope.onChanged);
                }
            }

            for (var i = 0; i < element[0].attributes.length; i++) {
                var attr = element[0].attributes[i];

                if (!directiveAttrs.includes(attr.name)) {
                    DOM.attr(attr.name, attr.nodeValue);
                }
            }

            //DOM.attr('ng-model', $scope.model);            

            return DOM;
        };

        return {
            replace: true,
            restrict: 'E',
            require: '^?waitForAll',
            scope: {
                lookFor: '@?',
                tableKey: '@',
                listName: '@?',
                onLoaded: '&?',
                orderBy: '@?',
                showSelect: '=?',
                select: '@?',
                label: '@?',
                array: '@?',
                default: '@?',
                isMultiSelect: '=?',
                isTypeAhead: '=?',
                where: '@?'
            },
            link: function ($scope, $element, $attrs, waitCtrl) {
                var lookFor = $scope.lookFor;
                var tableKey = $scope.tableKey;
                var listName = (!!$scope.listName) ? $scope.listName : (tableKey + 'List');
                var scopeElement = null;

                //Find the effective scope in which the list will be stored
                if (!!lookFor) {
                    scopeElement = $element.closest(lookFor);
                } else {
                    scopeElement = $element.parent();
                }
                var effectiveScope = scopeElement.scope();

                //Setup the options used when the directive will be replaced
                $scope.showSelect = !!$scope.showSelect;
                if (!$scope.array) {
                    $scope.array = listName;
                }
                if (!$scope.default) {
                    $scope.default = '-- Select --';
                }
                if (!!$scope.showSelect) {
                    var DOM = getNewElementHtml($scope, $element, $attrs);

                    $element.replaceWith(DOM);
                    $compile(DOM)(effectiveScope);
                }

                //Check if the data has already been loaded
                var dataExists = true;
                if (!effectiveScope[listName] || effectiveScope[listName].length == 0) {
                    dataExists = false;
                }

                //Get Data Function
                var getRefTableData = function () {
                    var deferred = $q.defer();

                    if (!!dataExists) {
                        deferred.resolve({
                            data: {
                                Status: true,
                                StatusCode: '00',
                                ReferenceEntities: effectiveScope[listName]
                            }
                        });
                        return deferred.promise;
                    }

                    $http.get(WebApiUrl + 'TypeValues/RefTablesGetAll/public/', {
                        params: {
                            tableKey: tableKey
                        }
                    }).then(function (response) {
                        if (response.data.Status) {
                            var orderBy = $scope.orderBy;
                            if (!orderBy) {
                                orderBy = $scope.label;
                            }
                            effectiveScope[listName] = response.data.ReferenceEntities.orderBy(orderBy);

                            if (!!$scope.where) {
                                effectiveScope[listName] = effectiveScope[listName].where($scope.where);
                            }

                            deferred.resolve(response);
                        } else {
                            deferred.reject(response);
                        }
                    }, function (err) {
                        deferred.reject(err);
                    });

                    return deferred.promise;
                };

                //Complete function
                var refLoadingComplete = function (response) {
                    if (!!$scope.onLoaded) {
                        $scope.onLoaded({
                            dataList: effectiveScope[listName]
                        });
                    }
                };

                var refLoadingFailed = function (response) {
                    var DOM = angular.element(
                        '<div class="alert alert-danger" style="clear: left;" role="alert" ng-show="!!' + tableKey + 'ErrorMessage"> \
                            <ul> \
                                <li style="position: relative;">{{' + tableKey + 'ErrorMessage}} \
                                    <a style="position: absolute; right: 0px; top: -8px; color: black;" ng-click="' + tableKey + 'ErrorMessage = \'\';"> \
                                        <i class="fa fa-times"></i> \
                                    </a> \
                                </li> \
                            </ul> \
                        </div>'
                    );
                    effectiveScope[tableKey + 'ErrorMessage'] = (!!response.data ? response.data.Message : response.Message);
                    var $e = $compile(DOM)(effectiveScope);
                    $element.replaceWith($e);
                };

                if (!waitCtrl) {
                    //No wait controller, just run and then complete.
                    getRefTableData()
                        .then(function (response) {
                            refLoadingComplete(response);
                        }, function (response) {
                            refLoadingFailed(response);
                        });
                } else {
                    //There's a wait controller, follow its instructions
                    waitCtrl.registerWaitChild({
                        getData: getRefTableData,
                        complete: refLoadingComplete,
                        failure: refLoadingFailed
                    });
                }
            }
        }
    }
})();
