﻿(function () {
    'use strict'
    angular.module('app.core')
        .factory('emailerFactory', ['$http', 'WebApiUrl', '$q', 'AppConfig', function ($http, WebApiUrl, $q, AppConfig) {
            var _individualCommunicationSave = function (key, data) {
                var data = $http.post(WebApiUrl + 'Individual/IndividualCommunicationSave/' + key, data);
                return returnResult(data);
            };
            var _configurationGetbySettings = function (key, setting) {
                var data = $http.get(WebApiUrl + 'Configuration/ConfigurationGetbySettings/' + key + '?setting=' + setting);
                return returnResult(data);
            };

            var _individualContactBYIndividualId = function (Key, IndividualId) {
                var data =  $http.get(WebApiUrl + 'Individual/IndividualContactBYIndividualId/' + Key + '?IndividualId=' + IndividualId);
                return returnResult(data);
            };

            var _sendEmailAndSms = function (key, reqData) {
                var data = $http.post(WebApiUrl + 'User/EmailAndText?key=' + key, reqData);
                return returnResult(data);
            };

            var returnResult = function (data) {
                var deferred = $q.defer();
                data
                    .then(function (response) {
                        deferred.resolve(response);
                    })
                    .catch(function (response) {
                        deferred.reject(response);
                    });
                return deferred.promise;
            };
            return {
                IndividualCommunicationSave: _individualCommunicationSave,
                ConfigurationGetbySettings: _configurationGetbySettings,
                IndividualContactBYIndividualId: _individualContactBYIndividualId,
                SendEmailAndSms: _sendEmailAndSms
            };
        }]);
})();