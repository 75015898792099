(function () {
    'use strict'

    angular.module('app.backofficeAdministor')
    .factory('DocumentTemplatesService', ['$http', '$q', 'WebApiUrl', DocumentTemplatesService]);

    function DocumentTemplatesService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getDocumentTemplatesInfo = function (key, search) {
            return responseHandler($http.post(WebApiUrl + 'Entity/GetDocumentGenerationInfo/' + key + '/', search));
        };

        var _getDocumentTemplates = function (key, search) {
            return responseHandler($http.post(WebApiUrl + 'Entity/GetDocumentTemplates/' + key + '/', search));
        };

        var _saveDocumentTemplate = function (key, template) {
            return responseHandler($http.post(WebApiUrl + 'Entity/SaveDocumentTemplate/' + key + '/', template));
        };

        return {
            getDocumentTemplatesInfo: _getDocumentTemplatesInfo,
            getDocumentTemplates: _getDocumentTemplates,
            saveDocumentTemplate: _saveDocumentTemplate
        };
    }
})();