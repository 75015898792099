//Code Generated by Inlumon Code generation tool
(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("ApplicationReviewController", ApplicationReviewController);

    ApplicationReviewController.$inject = ["$rootScope", "$scope", "$q", "applicationReviewService", "typeValuesService", "utilityService",
        "TypeValue"
    ];

    function ApplicationReviewController($rootScope, $scope, $q, applicationReviewService, typeValuesService, utilityService,
        TypeValue) {

        // #region Common Code
        $scope.viewConfig = {
            showAddApplicationReviewBlock: false,
            isApplicationReviewEdit: false
        };

        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/d!/yyyy',
            startDateOptions: {
                minDate: null,
            },
            endDateOptions: {}
        };

        $scope.onNgInit = function () {
            $scope.isConfigLoaded = false;
            $scope.current = {};
            $scope.uiLogic = {};
            $scope.headers = {};
            getConfig();
        };

        $scope.clearValidation = function () {
            $scope.hideStatusMsg();
        };

        var getUIConfig = function () {
            var configToUse = $scope.config.UIConfigs[$scope.configName];
            if (!configToUse) {
                configToUse = $scope.config.UIConfigs['Default'];
            }
            return configToUse;
        };

        var getConfig = function () {
            utilityService.getConfigData('/components/backoffice/entity//application-review/application-review.config.json').
                then(function (data) {
                    var config = data;
                    $scope.config = config;
                    $scope.uiConfig = getUIConfig();
                    var headersApplicationReview = {};
                    var fieldListApplicationReview = {};

                    for (var key in $scope.uiConfig.Fields.ApplicationReview) {
                        if ($scope.uiConfig.Fields.ApplicationReview[key].showInTable) {
                            headersApplicationReview[key] = $scope.uiConfig.Fields.ApplicationReview[key];
                        }
                        if ($scope.uiConfig.Fields.ApplicationReview[key].showInForm) {
                            fieldListApplicationReview[key] = $scope.uiConfig.Fields.ApplicationReview[key];
                        }
                    }

                    $scope.headersApplicationReview = headersApplicationReview;
                    $scope.fieldListApplicationReview = fieldListApplicationReview;

                    loadData(true);
                }, function (error) {
                    $scope.loadingApplicationReview(false);
                });
        };

        var resetPagination = function () {
            $scope.paginationParamsApplicationReview = {
                showPager: false
            };
        };

        var loadData = function (loadAllData) {
            var dataPromises = {
                applicationReview: applicationReviewService.applicationReviewGetByEntityId(sessionStorage.Key, $scope.entityId, $scope.applicationId)
            };
            if (!!loadAllData) {
                angular.forEach($scope.uiConfig.Areas.ApplicationReview.ReferenceData, function (refDataItem) {
                    dataPromises[refDataItem.propName] = typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=" + refDataItem.tableKey));
                })
            }

            $q.all(dataPromises)
                .then(function (response) {
                    var applicationReviews = [];
                    for (var prop in response) {
                        if (!response[prop].Status) {
                            $scope.loadingApplicationReview(false);
                            $scope.showStatusMsg('-', response[prop].Message);
                            return;
                        }
                    }
                    if (response.applicationReview) {
                        applicationReviews = response.applicationReview.lstApplicationReviewNew;
                    }
                    if (!!loadAllData) {
                        $scope.referenceData = {};
                        angular.forEach($scope.uiConfig.Areas.ApplicationReview.ReferenceData, function (refDataItem) {
                            if (!!response[refDataItem.propName]) {
                                refDataItem.upperCasePropName = refDataItem.propName.charAt(0).toUpperCase() + refDataItem.propName.slice(1);
                                $scope.referenceData['all' + refDataItem.upperCasePropName + 'List'] = response[refDataItem.propName].ReferenceEntities;
                                $scope.referenceData[refDataItem.propName + 'List'] = $scope.processRefEntityData(response[refDataItem.propName].ReferenceEntities);
                            }
                        });
                    }

                    $scope.applicationReviews = processApplicationReviewData(applicationReviews);
                    $scope.filterApplicationReviews();
                    resetPagination();
                    $scope.isConfigLoaded = true;
                    if ($scope.uiConfig.Areas.ApplicationReview.defaultOpenAddForm) {
                        $scope.addEditApplicationReview();
                    }
                    $scope.loadingApplicationReview(false);
                }, function (data) {
                    $scope.loadingApplicationReview(false);
                    $scope.showStatusMsg('-', data.message);
                });
        };

        $scope.processRefEntityData = function (entityRefData) {
            return entityRefData;
        };

        $scope.entityFilterDataByConfig = function (entityData) {
            return entityData.filter(function (entityDataItem) {
                return (!$scope.applicationReviewTypeId || (entityDataItem.ApplicationReviewTypeId == $scope.applicationReviewTypeId));
            });
        };

        $scope.saveAndNext = function () {
            $scope.loadingApplicationReview(true, "Please wait");
            $scope.openNextTab({
                currentTab: $scope.tab
            });
            $scope.loadingApplicationReview(false);
        };

        //#endregion

        // #region ApplicationReview Code

        $scope.validateApplicationReviewForm = function (currentForm) {
            var formData = {
                errorMessages: ((!!currentForm && !!currentForm.errorMessages) ? angular.copy(currentForm.errorMessages) : [])
            };

            if (!(!!formData.errorMessages && formData.errorMessages.length > 0)) {
                return true;
            } else {
                var errorMessages = [];
                angular.forEach(formData.errorMessages, function (obj) {
                    errorMessages.push(obj.errorMessage);
                })
                $scope.showStatusMsg('-', errorMessages);
                return false;
            }
        };

        var loadApplicationReviewDefaultsFromConfig = function (entityDataItem) {
            if ($scope.uiConfig.Defaults && $scope.uiConfig.Defaults.ApplicationReview) {
                for (var key in $scope.uiConfig.Defaults.ApplicationReview) {
                    if ($scope.uiConfig.Defaults.ApplicationReview[key] != undefined) {
                        entityDataItem[key] = $scope.uiConfig.Defaults.ApplicationReview[key];
                    }
                }
            }
            return entityDataItem;
        };

        var getNewApplicationReview = function () {
            var applicationReview = {
                EntityId: $scope.entityId,
                IndividualId: $scope.entityId,
                EntityType: $scope.entityType,
                ApplicationId: $scope.applicationId,
                ApplicationStatusId: $scope.applicationInfo.ApplicationStatusId,
                ApplicationReviewTypeId: $scope.applicationReviewTypeId,
                IsActive: true,
                IsDeleted: false,
                ApplicationReviewDate: new Date()
            };
            applicationReview = loadApplicationReviewDefaultsFromConfig(applicationReview);
            return applicationReview;
        };

        $scope.addEditApplicationReview = function (isApplicationReviewEdit, applicationReview) {
            $scope.loadingApplicationReview(true, "Please wait");
            $scope.viewConfig.isApplicationReviewEdit = isApplicationReviewEdit;
            $scope.viewConfig.showAddApplicationReviewBlock = true;
            if (!$scope.viewConfig.isApplicationReviewEdit) {
                $scope.applicationReview = getNewApplicationReview();
            } else {
                $scope.applicationReview = angular.copy(applicationReview);
            }
            $scope.loadingApplicationReview(false);
        };

        $scope.closeAddEditApplicationReview = function () {
            $scope.viewConfig.showAddApplicationReviewBlock = false;
            $scope.clearValidation();
        };

        var processApplicationReviewData = function (applicationReviewData) {
            if (!!applicationReviewData) {
                angular.forEach($scope.uiConfig.Areas.ApplicationReview.ReferenceData, function (refDataItem) {
                    if (!!refDataItem.on && !!refDataItem.valueMap) {
                        refDataItem.upperCasePropName = refDataItem.propName.charAt(0).toUpperCase() + refDataItem.propName.slice(1);
                        if (!!$scope.referenceData['all' + refDataItem.upperCasePropName + 'List'] && $scope.referenceData['all' + refDataItem.upperCasePropName + 'List'].length > 0) {
                            applicationReviewData = applicationReviewData.leftJoinObjects($scope.referenceData['all' + refDataItem.upperCasePropName + 'List'], refDataItem.on, refDataItem.valueMap);
                        }
                    }
                });
                applicationReviewData = $scope.entityFilterDataByConfig(applicationReviewData);
            }
            return applicationReviewData;
        };

        var updateApplicationReviewReferenceData = function () {
            angular.forEach($scope.uiConfig.Areas.ApplicationReview.ReferenceData, function (refDataItem) {
                if (!!refDataItem.refIdPropName && !!refDataItem.refCodePropName && !!refDataItem.entityIdPropName && !!refDataItem.entityCodePropName) {
                    refDataItem.upperCasePropName = refDataItem.propName.charAt(0).toUpperCase() + refDataItem.propName.slice(1);
                    var dataRow = $scope.referenceData['all' + refDataItem.upperCasePropName + 'List'].whereEquals($scope.applicationReview[refDataItem.entityCodePropName], refDataItem.refIdPropName).firstOrDefault();
                    if (!!dataRow && !!dataRow[refDataItem.refCodePropName]) {
                        $scope.applicationReview[refDataItem.entityCodePropName] = dataRow[refDataItem.refCodePropName];
                    }
                }
            });
        };

        $scope.saveApplicationReview = function (applicationReviewForm) {
            $scope.hideStatusMsg();
            applicationReviewForm.$submitted = true;
            if ($scope.validateApplicationReviewForm(applicationReviewForm)) {
                $scope.loadingApplicationReview(true, "Please wait");
                updateApplicationReviewReferenceData();
                $scope.saveApplicationReviewRecord().then(function (response) {
                    if (response.Status) {
                        $scope.$emit('applicationReviewSaved', {
                            ApplicationReview: $scope.applicationReview.ApplicationReview
                        });
                    }
                });
            }
        };

        $scope.saveApplicationReviewRecord = function () {
            var applicationReviewData = angular.copy($scope.applicationReview);
            var entityPromise = applicationReviewService.applicationReviewSave(sessionStorage.Key, [applicationReviewData]);
            entityPromise.then(function (response) {
                if (response.Status) {
                    $scope.applicationReview.ApplicationReviewId = response.lstApplicationReviewNew[0].ApplicationReviewId;
                    loadData(false);
                    $scope.closeAddEditApplicationReview();
                    $scope.showStatusMsg('+', response.Message);
                } else {
                    $scope.showStatusMsg('-', response.Message);
                }
                $scope.loadingApplicationReview(false);
                if ($scope.uiConfig.Areas.ApplicationReview.openFormAgainOnSave) {
                    $scope.addEditApplicationReview();
                }
            }, function (data) {
                $scope.loadingApplicationReview(false);
                $scope.showStatusMsg('-', data.message);
            });
            return entityPromise;
        };


        $scope.deleteApplicationReview = function (applicationReview) {
            if (confirm("Are you sure you want to delete this record?")) {
                $scope.loadingApplicationReview(true, "Please wait");
                $scope.applicationReview = angular.copy(applicationReview);
                $scope.applicationReview.IsDeleted = true;
                $scope.saveApplicationReviewRecord(false);
            }
        };

        $scope.filterApplicationReviews = function () {
            if ($scope.uiConfig.Areas.ApplicationReview.showAll) {
                $scope.applicationReviewsFiltered = $scope.applicationReviews;
            } else {
                $scope.applicationReviewsFiltered = $scope.filterApplicationReviewDataByConfig($scope.applicationReviews);
            }
        };

        $scope.loadReferenceCode = function (listData, idPropName, codePropName) {
            if (!!$scope.applicationReview[idPropName] && !!idPropName && !!codePropName) {
                var dataRow = $scope.referenceData[listData].whereEquals($scope.applicationReview[idPropName], idPropName).firstOrDefault();
                if (!!dataRow && !!dataRow[codePropName]) {
                    $scope.applicationReview[codePropName] = dataRow[codePropName];
                }
            }
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        $scope.$on("SaveDeficiency", function (event, args) {
            if (!!$scope.applicationReview && !!$scope.applicationReview.ApplicationReviewComment) {
                $scope.saveApplicationReview($scope.currentForm);
            }
        });

        //#endregion
        $scope.onNgInit();
    };
})();