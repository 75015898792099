(function() {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('providerScopeOfPracticeService', providerScopeOfPractice)
    providerScopeOfPractice.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function providerScopeOfPractice($http, $q, WebApiUrl, AppConfig) {

        var _getProviderScopeOfPractice = function(Key, IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Provider/GetProviderScopeOfPractice/' + Key + '?ProviderScopeOfPracticeId=null&ProviderId=' + IndividualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveProviderScopeOfPractice = function(Key, data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/SaveProviderScopeOfPractice/' + Key + '?Source=' + AppConfig.Source, data)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            getProviderScopeOfPractice: _getProviderScopeOfPractice,
            saveProviderScopeOfPractice: _saveProviderScopeOfPractice
        }
    }
})();