(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeIndividualNameService', backofficeIndividualNameService)

    backofficeIndividualNameService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeIndividualNameService($http, $q, WebApiUrl, AppConfig) {

        var _individualNameBYIndividualId = function (Key, individualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualNameBYIndividualId/' + Key + '?IndividualId=' + individualId));
        }

        var _individualNameSave = function (individualName, key, source) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualNameSave/' + key + "?Source=" + (!!source ? source : AppConfig.Source), individualName));
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };
        return {
            individualNameBYIndividualId: _individualNameBYIndividualId,
            individualNameSave: _individualNameSave
        }
    }
})();