(function () {
    'use strict'
    angular.module('app.core')
        .factory('ProviderMultipleFileUploadService', ProviderMultipleFileUploadService);

    ProviderMultipleFileUploadService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig'];

    function ProviderMultipleFileUploadService($http, $q, WebApiUrl, AppConfig) {

        var _documentMasterGetALL = function (Key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Document/DocumentMasterGetALL/' + Key)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _documentGetDocumentTypeName = function (Key, docId, docCode) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Document/DocumentGetDocumentTypeName/' + Key + '?docId=' + docId + '&DocCode=' + docCode)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _providerDocumentSave = function (providerDocumentUpload, ProviderId, EntityType, key) {
            var deferred = $q.defer();
            $http.post(providerDocumentUpload.ApiSaveEndpoint + key + '?ProviderId=' + ProviderId + '&EntityType=' + EntityType, providerDocumentUpload)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _providerDocumentGetByProviderIdDocId = function (Key, providerId, docId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Provider/ProviderGetProviderDocumentByProviderIdAndDocumentId/' + Key + '?ProviderId=' + providerId + '&DocumentId=' + (docId ? docId : '0'))
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _providerDocumentProcess = function (Key, providerId, documentId, dataToProcess, propertyToProcess, linkType, docListName) {
            var deferred = $q.defer();
            _providerDocumentGetByProviderIdDocId(Key, providerId, documentId).then(function (data) {
                if (data.Status) {

                    angular.forEach(dataToProcess, function (dataItemToProcess) {
                        if (!docListName)
                            dataItemToProcess.ProviderDocumentList = [];
                        else
                            dataItemToProcess[docListName] = [];

                        angular.forEach(data.ProviderDocumentGET, function (pdocItem) {
                            if (dataItemToProcess[propertyToProcess] == pdocItem.LinkId || dataItemToProcess.ReferenceNumber == pdocItem.ReferenceNumber) {
                                if (!docListName)
                                    dataItemToProcess.ProviderDocumentList.push(pdocItem);
                                else
                                    dataItemToProcess[docListName].push(pdocItem);
                            }
                        })
                    })
                    deferred.resolve(dataToProcess);
                } else {
                    deferred.resolve(dataToProcess);
                }
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _ProviderDocumentDelete = function (key, pdoc) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/ProviderDocumentDelete/' + key + '?ProviderDocId=' + pdoc.ProviderDocumentId + '&UserId=' + pdoc.ProviderId + '&ProviderId=' + pdoc.ProviderId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _providerDocumentByProviderDocumentId = function (key, pdId) {
            return $http.get(WebApiUrl + 'Provider/ProviderDocumentByProviderDocumentId/' + key + '?ProviderDocumentId=' + pdId, {
                responseType: 'arraybuffer'
            });
        };


        var _providerDocumentSaveToPDFandMailWYReprint = function (Key, data) {

            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/ProviderDocumentSaveToPDFandMailWYReprint/' + Key + '?Source=' + AppConfig.Source + 'FIRM', data)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _providerDocumentEmailSend = function (key, IndividualId, IndividualDocumentId, DocumentId, ApplicationId, Email, TemplateId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Provider/ProviderDocumentEmailSend/' + key + '?ProviderId=' + IndividualId + '&ProviderDocumentId=' + IndividualDocumentId + '&DocumentId=' + DocumentId + '&ApplicationId=' + ApplicationId + '&Email=' + Email + '&TemplateId=' + TemplateId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            documentMasterGetALL: _documentMasterGetALL,
            documentGetDocumentTypeName: _documentGetDocumentTypeName,
            providerDocumentSave: _providerDocumentSave,
            providerDocumentGetByProviderIdDocId: _providerDocumentGetByProviderIdDocId,
            ProviderDocumentDelete: _ProviderDocumentDelete,
            providerDocumentByProviderDocumentId: _providerDocumentByProviderDocumentId,
            providerDocumentProcess: _providerDocumentProcess,
            providerDocumentSaveToPDFandMailWYReprint: _providerDocumentSaveToPDFandMailWYReprint,
            providerDocumentEmailSend: _providerDocumentEmailSend,
        }
    }
})();
