(function() {
    'use strict'
    angular.module('app.core')
        .directive('natDateRange', natDateRangeDirective);

    natDateRangeDirective.$inject = ['$parse'];

    function natDateRangeDirective($parse) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attrs, ctrl) {

                var processDateChangeValues = function() {
                    if (!ctrl.$$parentForm.errorMessages) {
                        ctrl.$$parentForm.errorMessages = [];
                    }

                    var errorObj = {
                        propName: (!!attrs.validationName ? attrs.validationName : ("natdaterange-" + ctrl.$name)),
                        errorMessage: attrs.natDateRange
                    }

                    angular.forEach(ctrl.$$parentForm.errorMessages, function(error, indexOfMsg) {
                        if (errorObj.propName == error.propName) {
                            ctrl.$$parentForm.errorMessages.splice(indexOfMsg, 1);
                        }
                    })
                    var dateRangeFunc = $parse(attrs.dateRangeOptions);
                    var minDateOption = dateRangeFunc(scope).minDate;
                    var maxDateOptionItem = dateRangeFunc(scope).maxDate;
                    var maxDateOption = angular.copy(maxDateOptionItem);
                    if (!!maxDateOption)
                        maxDateOption.setHours(23, 59, 59);
                    if (angular.isDate(ctrl.$modelValue) && ((!!minDateOption && minDateOption > ctrl.$modelValue) || (!!maxDateOption && maxDateOption < ctrl.$modelValue))) {
                        ctrl.$$parentForm.errorMessages.push(errorObj);
                        ctrl.$setValidity("natdaterange", false);
                    } else {
                        ctrl.$setValidity('natdaterange', true);
                    }
                };

                element.on('blur', function(event) {
                    processDateChangeValues();
                });

                scope.$watch(function() {
                    var dateRangeFunc = $parse(attrs.dateRangeOptions);
                    var minDateOption = dateRangeFunc(scope).minDate;
                    //var maxDateOption = dateRangeFunc(scope).maxDate;
                    if (angular.isDate(minDateOption))
                        return minDateOption;
                }, function(newValue, oldValue) {
                    //console.log('minDateChanged' + newValue + ' ' + oldValue);
                    processDateChangeValues();
                });

                scope.$watch(function() {
                    var dateRangeFunc = $parse(attrs.dateRangeOptions);
                    //var minDateOption = dateRangeFunc(scope).minDate;
                    var maxDateOption = dateRangeFunc(scope).maxDate;
                    if (angular.isDate(maxDateOption))
                        return maxDateOption;
                }, function(newValue, oldValue) {
                    //console.log('maxDateChanged' + newValue + ' ' + oldValue);
                    processDateChangeValues();
                });
            }
        }
    }
})();