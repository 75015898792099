﻿(function () {
    'use strict';
    angular.module('app.backofficeAdministor')
        .controller("ReferencetableController", ReferencetableController)
    ReferencetableController.$inject = ['$scope', 'utilityService', 'ReferencetableFactory', 'RefTableFormDirectiveFactory', '$q', '$filter', 'Messages'];
    function ReferencetableController($scope, utilityService, ReferencetableFactory, RefTableFormDirectiveFactory, $q, $filter, Messages) {
        var _Key = sessionStorage.Key;
        var _UserId = sessionStorage.UserID;
        $scope.SearchString = '';
        $scope.currentPage = 1;
        $scope.totalItems = 0;
        $scope.itemsPerPage = 100;
        $scope.maxSize = 6;
        $scope.fromRow = 0;
        $scope.init = function () {
            $scope.DisplayAddSection = false;
            LoadDropDownData();
        };

        var LoadDropDownData = function () {
            ShowLoader();
            ReferencetableFactory.GetRefTableMasterList(_Key).then(function (res) {
                if (res.data.Status) {
                    $scope.RefMasterTableList = res.data.RefTableMasterListData;
                    //make the first default selected
                    if ($scope.RefMasterTableList && $scope.RefMasterTableList.length > 0) {
                        $scope.ReferenceTable = $scope.RefMasterTableList[0].RefTableId;
                        $scope.OnDropDownChange();
                    } else {
                        HideLoader();
                    }
                } else {
                    HideLoader();
                }
            }, function () { HideLoader(); });
        };

        var RefTableFieldDetailsByRefTableId = function (tid) {
            if (tid) {
                ReferencetableFactory.RefTableFieldDetailsByRefTableId(_Key, tid).then(function (res) {
                    if (res.data.Status) {
                        //for form : Add/Edit
                        $scope.FieldListDetails = angular.copy(res.data.refTableFieldDetails);
                        prepareFieldSource();

                        //for Search section
                        if (res.data.refTableFieldDetails && res.data.refTableFieldDetails.length > 0) {
                            $scope.SearchFieldListDetails = $.grep(res.data.refTableFieldDetails, function (i) {
                                return (i.IsAdditionalField != 1)
                            });
                        }
                        //for list section
                        FilterFieldsToDisplayinTable();
                        DisplayIsActive();
                    }
                }, function () { HideLoader(); });
            }
        };

        var prepareFieldSource = function () {
            $scope.isAllDropDownLoaded = false;
            if ($scope.FieldListDetails) {
                var listQ = [];
                for (var i = 0; i < $scope.FieldListDetails.length; i++) {
                    if ($scope.FieldListDetails[i].FieldType.toLowerCase() == 'dropdown') {
                        listQ.push(getFieldSource($scope.FieldListDetails[i]));
                    }
                }
                if (listQ.length > 0) {
                    $q.all(listQ).then(function (res) {
                        $scope.isAllDropDownLoaded = true;
                    });

                } else {
                    $scope.isAllDropDownLoaded = true;
                }
            } else {
                $scope.isAllDropDownLoaded = true;
            }
        };

        var getFieldSource = function (item) {
            var defer = $q.defer();
            if (item) {
                //get list of fields
                var fields = [];
                fields.splice(0, 0, item.DataSourceValueField);
                if (item.DataSourceIdField) {
                    try {
                        var obj = JSON.parse(item.DataSourceIdField);
                        if (obj) {
                            fields = fields.concat(Object.values(obj));
                        }
                    } catch (e) {
                        fields = [];
                    }
                }
                var reqObj = {
                    TableName: item.DataSourceTable,
                    FieldList: fields
                }
                ReferencetableFactory.GetRefTableFieldSourceData(_Key, reqObj).then(function (res) {
                    if (res.data.Status) {
                        item.DataSourceItems = res.data.TableData;
                    }
                    defer.resolve({});
                }, function () {
                    defer.resolve({});
                });
            } else {
                defer.resolve({});
            }
            return defer.promise;
        };

        var GetTableData = function (tid) {
            $scope.TableData = [];
            if (tid) {
                ShowLoader()
                var searchData = {
                    FromRow: $scope.fromRow,
                    RecordToFetch: $scope.itemsPerPage,
                    SearchString: $scope.SearchString + ' ' + $scope.OrderByString
                };
                ReferencetableFactory.GetTableDataByRefTableId(_Key, tid, searchData).then(function (res) {
                    if (res.data.Status) {
                        $scope.TableData = res.data.TableData;
                        $scope.totalItems = res.data.RecordCount;
                        //wait a second to load data and hide loader
                        setTimeout(function () {
                            HideLoader();
                        }, 2000)
                    } else {
                        HideLoader();
                    }
                }, function () {
                    HideLoader();
                });
            }
        };

        $scope.AddNewRecordClick = function () {
            if ($scope.FieldListDetails && $scope.FieldListDetails.length > 0) {
                $scope.DisplayAddSection = true;
                $scope.RefTableId = angular.copy($scope.ReferenceTable);
            } else {
                alert(Messages.ebcadaa);
            }
        };

        $scope.OnDropDownChange = function () {
            $scope.FieldListDetails = [];
            $scope.SearchFieldListDetails = [];
            $scope.currentPage = 1;
            $scope.totalItems = 0;
            $scope.FieldListToDispaly = [];
            $scope.DisplayAddSection = false;
            $scope.OrderByString = '';
            $scope.SearchString = '';
            //get List Data
            GetTableData($scope.ReferenceTable);
            //GetFieldData
            RefTableFieldDetailsByRefTableId($scope.ReferenceTable);

            $scope.EnableEdit = false;
            $scope.EnableAdd = false;
            if ($scope.RefMasterTableList) {
                var refMaster = $.grep($scope.RefMasterTableList, function (i) {
                    return (i.RefTableId == $scope.ReferenceTable);
                });
                if (refMaster && refMaster.length > 0) {
                    if (refMaster[0].DisplayAdd) {
                        $scope.EnableAdd = true;
                    }
                    if (refMaster[0].DisplayEdit) {
                        $scope.EnableEdit = true;
                    }
                }
            }
        };

        $scope.RefTableDataSaved = function () {
            $scope.DisplayAddSection = false;
            GetTableData($scope.ReferenceTable);
        };

        $scope.CancelClick = function () {
            $scope.DisplayAddSection = false;
        };
        $scope.EditItemCancelClick = function (item) {

        };
        $scope.SearchCancelBtnClick = function () {
            $scope.ShowSearch = $scope.ShowSearch ? false : true;
            if (!$scope.ShowSearch) {
                $scope.SearchString = '';
                $scope.currentPage = 1;
                $scope.fromRow = 0;
                GetTableData($scope.ReferenceTable);
            }
        };
        $scope.ShowHideSearch = function () {
            $scope.ShowSearch = $scope.ShowSearch ? false : true;
        };
        $scope.FieldListToDispaly = [];
        var FilterFieldsToDisplayinTable = function (i) {
            if ($scope.FieldListDetails) {
                $scope.FieldListToDispaly = $.grep($scope.FieldListDetails, function (i) {
                    return (i.DisplayInList == 1 && i.FieldName.toLowerCase() != 'isactive');
                });
            }
        };

        var DisplayIsActive = function () {
            $scope.DisplayIsActive = false;
            var isactiveList = $.grep($scope.FieldListDetails, function (i) {
                return (i.DisplayInList == 1 && i.FieldName.toLowerCase() == 'isactive');
            });
            if (isactiveList && isactiveList.length > 0) {
                $scope.DisplayIsActive = true;
            }
        };

        $scope.UpdateIsActive = function (item) {
            try {
                var primaryField = getPrimaryKeyField();
                var reqIsActive = {
                    IsActive: item.IsActive
                };
                var ReqData = {
                    FormData: reqIsActive,
                    TableId: $scope.FieldListDetails[0].RefTableId,
                    ModifiedBy: _UserId,
                    PrimaryFieldId: item[primaryField]
                }
                ShowLoader();
                RefTableFormDirectiveFactory.SaveRefTableData(_Key, ReqData).then(function (res) {
                    if (res.data.Status) {
                    }
                    HideLoader();
                }, function () {
                    HideLoader();
                });
            } catch (ex) {

            }
        };

        var getPrimaryKeyField = function () {
            if ($scope.FieldListDetails) {
                var field = $.grep($scope.FieldListDetails, function (i) {
                    return i.IsPrimaryKey;
                });
                if (field && field.length > 0) {
                    return field[0].FieldName;
                }
            }
            return '';
        };

        $scope.pageChanged = function () {
            $scope.fromRow = ($scope.currentPage * $scope.itemsPerPage) - $scope.itemsPerPage;
            GetTableData($scope.ReferenceTable);
        };

        $scope.$on('OnSearchRefData', function (event, data) {
            $scope.SearchString = generateSearchString(data);
            $scope.fromRow = 0;
            $scope.currentPage = 1;
            GetTableData($scope.ReferenceTable);
        });
        var orderBYField = '';
        var order = true;
        $scope.OrderByString = '';
        $scope.OrderBy = function (field) {
            orderBYField = field;
            if (orderBYField && orderBYField != '') {
                var orderStr = order ? 'ASC' : 'DESC';
                order = order ? false : true;
                $scope.OrderByString = ' order by ' + field + ' ' + orderStr + ' ';
            }
            GetTableData($scope.ReferenceTable);
        };

        var generateSearchString = function (data) {
            var sStr = "";
            if ($scope.SearchFieldListDetails && data) {
                for (var i = 0; i < $scope.SearchFieldListDetails.length; i++) {
                    var val = data[$scope.SearchFieldListDetails[i].FieldName];
                    if (val && val!=null) {
                        if (Object.prototype.toString.call(val) === "[object Date]") {
                            val = $filter('date')(val, 'yyyy-MM-dd');
                        }
                        else {
                            val = val + ''.replace(/'/g, "");
                        }
                        sStr = sStr + (" and " + $scope.SearchFieldListDetails[i].FieldName + " = '" + val + "' ");

                    }
                }
            }
            return sStr;
        };
    }
})();