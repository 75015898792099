(function () {
    'use strict';

    angular
        .module('app.report')
        .controller("AppProcessingTimeChartController", AppProcessingTimeChartController);

    AppProcessingTimeChartController.$inject = ["$rootScope", "$scope",
        "AppProcessingTimeChartService", "utilityService", "WebApiUrl", "initialApplicationstatusService", "countryStateService", "typeValuesService", "TypeValue", "$q", "$exceptionHandler"
    ];

    function AppProcessingTimeChartController($rootScope, $scope,
        AppProcessingTimeChartService, utilityService, WebApiUrl, initialApplicationstatusService, countryStateService, typeValuesService, TypeValue, $q, $exceptionHandler) {

        // $scope.AppProcessingTimeData = [];
        // $scope.education = {
        //     IndividualId: $scope.individualId,
        //     //ProviderId: "-1",
        //     ProgramTypeId: -1,
        //     IsActive: true,
        //     UseId: sessionStorage.UserID,
        // };
        // $scope.educationtData = {
        //     Date: new Date()
        // };
        // $scope.datePicker = {
        //     fromDateOpened: false,
        //     toDateOpened: false,
        //     format: 'M!/d!/yyyy',
        //     startDateOptions: {
        //         maxDate: null
        //     },
        //     endDateOptions: {
        //         minDate: null
        //     }
        // };

        var processDataforChart = function (AvgAppProcessingTimeList) {
            var data = [];
            if (AvgAppProcessingTimeList.length > 0) {
                var prevMonth = '';
                var nextRow = false;
                var row = ['Month', 'License Renewal Time', 'New License Application Time'];
                data.push(row);
                row = [];
                for (var i = 0; i <= AvgAppProcessingTimeList.length; i++) {

                    if (i == AvgAppProcessingTimeList.length) {
                        if (i > 0) {
                            while (row.length < data[0].length) {
                                row.push(0);
                            }
                            data.push(row);
                        }
                        continue;
                    }

                    if (AvgAppProcessingTimeList[i].Month != null) {
                        if (AvgAppProcessingTimeList[i].Month != prevMonth) {
                            if (i > 0) {
                                while (row.length < data[0].length) {
                                    row.push(0);
                                }
                                data.push(row);
                                row = [];
                            }
                            prevMonth = AvgAppProcessingTimeList[i].Month;
                            row.push(AvgAppProcessingTimeList[i].Month.toString());
                        }
                        if (AvgAppProcessingTimeList[i].Average != null) {
                            row.push(AvgAppProcessingTimeList[i].Average);
                        }
                        else {
                            row.push(0);
                        }
                    }
                }
            }
            $scope.AppProcessingTimeData = data;
            console.log(data);
        };

        var drawChart = function () {
            var chartData = google.visualization.arrayToDataTable($scope.AppProcessingTimeData);
            var options = { title: 'Average application processing time by Month', isStacked: false };

            // Instantiate and draw the chart.
            var chart = new google.charts.Bar(document.getElementById('ApplicationChartDiv'));
            chart.draw(chartData, options);
        }

        $scope.loadAppProcessingTimeChart = function (noOfMonths) {
            try {
                ShowLoader("Please Wait...");
                AppProcessingTimeChartService.getAvgApplicationProcessingTimeByMonth(sessionStorage.Key, noOfMonths)
                    .then(function (response) {
                        if (response.Status && response.StatusCode == "00") {
                            processDataforChart(response.AvgAppProcessingTimeByMonthList);
                            google.charts.setOnLoadCallback(drawChart);
                        }
                        HideLoader();
                    });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        }

        $scope.init = function () {
            google.charts.load('current', { packages: ['bar'] });
            try {
                $scope.loadAppProcessingTimeChart(6);
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
                $exceptionHandler(ex);
            }
        }

        $scope.init();
    }
})();
