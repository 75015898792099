﻿(function () {
    'use strict'
    angular.module('app.provider')
        .directive('sendApplicationReceiptEmailDirective', [function () {
            return {
                restrict: 'EA',
                scope: {
                    providerId: '=?providerId',
                    individualId: '=?individualId',
                    invoiceId: '=',
                    item: '=?',
                    onClose: '&?',
                    emailid:'=?',
                    showStatusMessage:'&'
                },
                controller: "sendApplicationReceiptEmailController",
                templateUrl: "app/components/provider/directive/send-application-receipt-email/send-application-receipt-email.html",
                link: function ($scope, element, attrs, ngCtrl) {

                }
            };
        }]);
})();