(function () {
    'use strict'

    angular.module('app.core')
        .factory('AddressInputService', ['$http', '$q', 'WebApiUrl', '$rootScope', AddressInputService]);

    function AddressInputService($http, $q, WebApiUrl, $rootScope) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
                .then(function (res) {
                    if (success) {
                        success(res);
                    }
                    deferred.resolve(res.data);
                }, function (error) {
                    if (fail) {
                        fail(error);
                    }
                    console.log("Request failed with status: " + error.status);
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getStates = function (key, countryId) {
            var urlParams = {
                CountryId: $rootScope.DefaultCountryId
            };
            return responseHandler($http.get(WebApiUrl + 'State/StateGetByCountryID/' + key + '/', { params: urlParams }));
        };

        var _getCounties = function (key, stateCode) {
            var urlParams = {
                StateCode: (!!stateCode) ? stateCode : 0
            };
            return responseHandler($http.get(WebApiUrl + 'County/CountyGetAll/' + key + '/', { params: urlParams }));
        };

        var _getCountries = function (key) {
            return responseHandler($http.get(WebApiUrl + 'Country/CountryGetAll?Key=' + (!!key ? key : '') + '/'));
        };

        return {
            getStates: _getStates,
            getCounties: _getCounties,
            getCountries: _getCountries
        };
    }
})();