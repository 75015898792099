(function () {
    'use strict'
    angular.module('app.core')
        .controller('IndividualPersonalInfoController', ['$rootScope', '$scope', '$q', 'individualPersonalInfoService',
            'countryStateService', '$filter', 'utilityService', 'backofficeIndividualNameService', 'dcaIService', 'typeValuesService',
            'initialApplicationNcessService',
            function ($rootScope, $scope, $q, individualPersonalInfoService, countryStateService, $filter,
                utilityService, backofficeIndividualNameService, dcaIService, typeValuesService, initialApplicationNcessService) {
                var _Key = sessionStorage.Key;
                var _UserId = sessionStorage.UserId;
                var individualId = $scope.individualId;

                $scope.maskConfig = {
                    clearOnBlur: false,
                    allowInvalidValue: true,
                    clearOnBlurPlaceholder: false
                };

                var init = function () {
                    $scope.countryGetAll();
                    $scope.getSuffixValues();
                    $scope.getIndividualLicenseRecordBook(individualId);
                    getIndividualCitizenship(individualId);
                    individualNameBYIndividualId();
                };

                $scope.datePicker = {
                    opened: false,
                    format: 'MM/dd/yyyy',
                    dateOptions: {
                        formatYear: 'yy',
                        startingDay: 1
                    }
                };

                $scope.getAge = function (BirthDate) {
                    if (BirthDate != null) {
                        var personBirthdate = ($filter('date')(new Date(BirthDate), 'MM/dd/yyyy'));
                        utilityService.getServerDate()
                            .then(function (response) {
                                var date = new Date(response.substring(0, 10));
                                var serverYear = date.getFullYear();
                                var birthYear = new Date(personBirthdate).getFullYear();
                                $scope.person.Age = serverYear - birthYear;
                            });
                    } else {
                        $scope.person.Age = "";
                    }
                };

                //Individual Get
                $scope.individualBYIndividualId = function (IndividualId) {
                    try {
                        ShowLoader("Please wait...");
                        individualPersonalInfoService.individualBYIndividualId(_Key, IndividualId)
                            .then(function (res) {
                                var response = res.data;
                                if (response.Status && response.IndividualResponse && response.IndividualResponse.length > 0) {
                                    $scope.person = response.IndividualResponse[0];
                                    $scope.OrignalpersonDetail = angular.copy($scope.person);
                                    if (!!$scope.person.DateOfBirth && !angular.isDate($scope.person.DateOfBirth))
                                        $scope.person.DateOfBirth = new Date($scope.person.DateOfBirth);
                                    $scope.getAge($scope.person.DateOfBirth);
                                    //$scope.person.PlaceofBirthCountry = $rootScope.DefaultCountryId;
                                    $scope.IndividualPersonalDtl = angular.copy($scope.person);
                                    //citizen country
                                    if ($scope.IndividualPersonalDtl.CitizenshipCountryId && $scope.IndividualPersonalDtl.CitizenshipCountryId > 0) {
                                        $scope.IndividualPersonalDtl.CitizenshipCountryName = getCountryByCountryID($scope.IndividualPersonalDtl.CitizenshipCountryId);
                                    }
                                    if ($scope.person.IndividualPlaceofBirthCountry != null && $scope.person.IndividualPlaceofBirthCountry > 0) {
                                        $scope.getStateByCountryID($scope.person.IndividualPlaceofBirthCountry);
                                        //get country by countryid
                                        $scope.IndividualPersonalDtl.IndividualPlaceofBirthCountryName = getCountryByCountryID($scope.person.IndividualPlaceofBirthCountry);
                                        if ($scope.IndividualPersonalDtl.IndividualPlaceofBirthState && $scope.IndividualPersonalDtl.IndividualPlaceofBirthState != '') {
                                            $scope.IndividualPersonalDtl.IndividualPlaceofBirthStateName = $scope.IndividualPersonalDtl.IndividualPlaceofBirthState;
                                        }

                                    } else {
                                        $scope.getStateByCountryID($rootScope.DefaultCountryId);
                                    }
                                    if (!!$scope.suffixTypes && $scope.suffixTypes.length > 0 && !!$scope.IndividualPersonalDtl && !!$scope.IndividualPersonalDtl.SuffixTypeId) {
                                        var suffix = $scope.suffixTypes.whereEquals($scope.IndividualPersonalDtl.SuffixTypeId, 'SuffixTypeId').firstOrDefault();
                                        $scope.IndividualPersonalDtl.SuffixTypeCode = suffix.SuffixTypeCode;
                                    }
                                }
                                HideLoader();
                            }, function (data) {
                                HideLoader();
                                alert(data);
                            });
                    } catch (ex) {
                        console.log('err', ex);
                        if (ex != null) { alert(ex.message); }
                    }
                };

                var getNewNceesRecord = function () {
                    return {
                        IndividualId: $scope.individualId,
                        IsRecordBookOnFile: true,
                        IsActive: true,
                        IsDeleted: false
                    };
                };

                $scope.getIndividualLicenseRecordBook = function (individualId) {
                    initialApplicationNcessService.getIndividualLicenseRecordBook(individualId, null, _Key)
                        .then(function (response) {
                            if (response.Status && response.IndividualLicenseRecordBookList && response.IndividualLicenseRecordBookList.length > 0) {
                                $scope.ncees = response.IndividualLicenseRecordBookList[0];
                            }
                            if (!$scope.ncees) {
                                $scope.ncees = getNewNceesRecord();
                            }
                        }, function (data) {
                            HideLoader();
                            alert(data);
                        });
                };

                var getIndividualCitizenship = function (IndividualId) {
                    // personalService.getIndividualCitizenship(IndividualId, _Key).then(function (res) {
                    //     if (res.Status && res.IndividualCitizenshiplst && res.IndividualCitizenshiplst.length > 0) {
                    //         $scope.individualCitizenship = res.IndividualCitizenshiplst[0];
                    //     }
                    // });
                };

                var getCountryByCountryID = function (id) {
                    if ($scope.CountryList && $scope.CountryList.length > 0) {
                        for (var i = 0; i < $scope.CountryList.length; i++) {
                            if ($scope.CountryList[i].CountryId == id) {
                                return $scope.CountryList[i].Name;
                            }
                        }
                    }
                    return '';
                };

                $scope.getSuffixValues = function () {
                    typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=suffix"))
                        .then(function (response) {
                            if (response) {
                                $scope.suffixTypes = response.ReferenceEntities.orderBy('SortOrder');
                            }
                            $scope.individualBYIndividualId(individualId);
                        });
                }

                // Get Country List 
                $scope.countryGetAll = function () {
                    try {
                        countryStateService.countryGetAll(_Key)
                            .then(function (response) {
                                if (response && response.Status) {
                                    $scope.CountryList = response.Country;
                                    if ($scope.IndividualPersonalDtl && $scope.IndividualPersonalDtl.CitizenshipCountryId && $scope.IndividualPersonalDtl.CitizenshipCountryId > 0) {
                                        $scope.IndividualPersonalDtl.CitizenshipCountryName = getCountryByCountryID($scope.IndividualPersonalDtl.CitizenshipCountryId);
                                    }
                                    if ($scope.IndividualPersonalDtl && $scope.IndividualPersonalDtl.IndividualPlaceofBirthCountry != null && $scope.IndividualPersonalDtl.IndividualPlaceofBirthCountry > 0) {
                                        $scope.IndividualPersonalDtl.IndividualPlaceofBirthCountryName = getCountryByCountryID($scope.IndividualPersonalDtl.IndividualPlaceofBirthCountry);
                                    }
                                }
                            }, function (data) {
                                if (data != null) { alert(data.message); }
                            });
                    } catch (ex) {
                        if (ex != null) { alert(ex.message); }
                    }
                };

                // Get State List by CountryID
                $scope.getStateByCountryID = function (CountryID) {
                    try {
                        countryStateService.getStateByCountryID(_Key, CountryID)
                            .then(function (response) {
                                $scope.StatList = null;
                                if (response && response.Status) {
                                    $scope.StatList = response.State;
                                    //get state by stateid
                                    angular.forEach($scope.StatList, function (value, index) {
                                        if ($scope.stateID == $scope.StatList[index].StateCode) {
                                            $scope.addressState = $scope.stateID;
                                        }
                                        if ($scope.IndividualPersonalDtl.IndividualPlaceofBirthStateCode && $scope.StatList[index].StateCode == $scope.IndividualPersonalDtl.IndividualPlaceofBirthStateCode) {
                                            $scope.IndividualPersonalDtl.IndividualPlaceofBirthStateName = $scope.StatList[index].Name;
                                        }
                                    });
                                    if ($scope.StatList == null || $scope.StatList.length == 0) {
                                        $scope.person.IndividualPlaceofBirthStateCode = '';
                                    } else {
                                        $scope.person.IndividualPlaceofBirthState = '';
                                    }
                                }
                            }, function (data) {
                                if (data != null) { alert(data.message); }
                            });
                    } catch (ex) {
                        console.log('err', ex);
                        if (ex != null) { alert(ex.message); }
                    }
                }

                // Clear Person Tab..
                $scope.clearPerson = function (personalForm) {
                    $scope.person.FirstName = "";
                    $scope.person.MiddleName = "";
                    $scope.person.SSN = "";
                    $scope.person.DateOfBirth = "";
                    $scope.person.LastName = "";
                    $scope.person.SuffixId = "";
                    $scope.person.Age = "";
                    $scope.person.USCitizen = null;
                    $scope.person.PlaceofBirthCountry = "";
                    $scope.person.PlaceOfBirth = "";
                    $scope.person.PlaceofBirthCity = "";
                    $scope.person.PlaceofBirthState = "";
                    $scope.person.Gender = "";
                    $scope.person.CitizenshipCountryId = '';
                    $scope.person.IndividualPlaceofBirthStateCode = '';
                    personalForm.$setPristine();
                }

                $scope.saveData = function (OrignalpersonDetail) {
                    //$scope.loadingPersonalDetails(true, "Please wait...");
                    var nameData = angular.copy(OrignalpersonDetail);
                    nameData.IndividualNameStatusId = 3;
                    backofficeIndividualNameService.individualNameSave([nameData], sessionStorage.Key).then(function (res) {
                        individualNameBYIndividualId();
                    }, function (error) {

                    });
                };

                // Save Person
                $scope.individualSave = function (personalForm) {
                    try {
                        if (validateFormData(personalForm)) {
                            ShowLoader("Please wait...");
                            if (!!$scope.suffixTypes && $scope.suffixTypes.length > 0 && !!$scope.person && !!$scope.person.SuffixTypeId) {
                                var suffix = $scope.suffixTypes.whereEquals($scope.person.SuffixTypeId, 'SuffixTypeId').firstOrDefault();
                                $scope.person.SuffixTypeCode = suffix.SuffixTypeCode;
                            }
                            var dataPromises = {
                                individualSave: individualPersonalInfoService.individualSave([$scope.person], _Key)
                            };
                            if (!!$scope.ncees && $scope.ncees.RecordBookNumber) {
                                dataPromises.nceesSave = initialApplicationNcessService.saveIndividualLicenseRecordBook([$scope.ncees], sessionStorage.Key);
                            }
                            $q.all(dataPromises)
                                .then(function (response) {
                                    if (response && response.individualSave.Status) {
                                        $scope.individualBYIndividualId(individualId);
                                        $scope.personDetail = angular.copy($scope.person);
                                        if ($scope.OrignalpersonDetail.FirstName.toLowerCase() != $scope.person.FirstName.toLowerCase() ||
                                            $scope.OrignalpersonDetail.MiddleName.toLowerCase() != $scope.person.MiddleName.toLowerCase() ||
                                            $scope.OrignalpersonDetail.LastName.toLowerCase() != $scope.person.LastName.toLowerCase()) {
                                            $scope.saveData($scope.OrignalpersonDetail);
                                        }
                                        var requestData = {
                                            EntityId: $scope.individualId,
                                            EntityType: "I"
                                        };
                                        dcaIService.updateName(sessionStorage.Key, requestData).then(function (responseUpdate) {
                                            if (!responseUpdate.Status) { }
                                        }, function (err) {
                                            $scope.showStatusMessage({
                                                messageContent: 'Some error occured !',
                                                messageType: 'error'
                                            });
                                        });
                                        $scope.showPersonalEdit = false;
                                    }
                                    if (response && response.nceesSave.Status) {
                                        $scope.getIndividualLicenseRecordBook(individualId);
                                    }
                                    HideLoader();
                                }, function (data) {
                                    HideLoader();
                                    if (data != null) { alert(data.message); }
                                });
                        }
                    } catch (ex) {
                        console.log('err', ex);
                        HideLoader();
                        if (ex != null) { alert(ex.message); }
                    }
                };

                //Individual Other Name Get
                var individualNameBYIndividualId = function () {
                    individualPersonalInfoService.individualNameBYIndividualId(_Key, individualId).then(function (res) {
                        if (res.Status) {
                            $scope.IndividualNameList = res.IndividualNameResponse;
                        }
                    });
                }

                var validateFormData = function (formToValidate) {
                    $scope.validationErrors = [];
                    if (!(!!formToValidate.errorMessages && formToValidate.errorMessages.length > 0)) {
                        return true;
                    } else {
                        $scope.showValidation = true;
                        $scope.validationErrors = angular.copy(formToValidate.errorMessages);
                        utilityService.scrollTop();
                        return false;
                    }
                };
                init();
            }
        ]);
})();