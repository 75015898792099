(function () {
    'use strict'

    angular.module('app.core')
        .factory('dashboardNotificationService', dashboardNotificationService)

    dashboardNotificationService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function dashboardNotificationService($http, $q, WebApiUrl, AppConfig) {

        var _getUser = function (Key, userId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Users/UsersGetBYID/' + Key + '?ID=' + userId));
        };

        var _individualCorrespondenceByIndividualId = function (Key, IndividualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualCommunicationGetByIndividualId/' + Key + '?IndividualId=' + IndividualId));
        }
        var _providerCorrespondenceByProviderId = function (Key, ProviderId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualCommunicationGetByIndividualId/' + Key + 
            '?IndividualId=0' + '&ProviderId=' + ProviderId));
        }
        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        return {
            getUser: _getUser,
            individualCorrespondenceByIndividualId: _individualCorrespondenceByIndividualId,
            providerCorrespondenceByProviderId: _providerCorrespondenceByProviderId
        }
    }
})();