(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('initialApplicationLegalInfoService', initialApplicationLegalInfoService)

    initialApplicationLegalInfoService.$inject = ['$http', '$q', 'initialApplicationstatusService', 'WebApiUrl', 'AppConfig']

    function initialApplicationLegalInfoService($http, $q, initialApplicationstatusService, WebApiUrl, AppConfig) {

        var _getLegalInfoContent = function (contentItemLkIdList, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Content/ContentGetBYContentItemLkIdList/' + key, contentItemLkIdList)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        var _legalInfoGet = function (individualId, applicationId,ContentLkToPageTabSectionIds, key, source) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualLegalGetByIndividualId/' + key + '?IndividualId=' + individualId + "&ApplicationId=" + applicationId + "&ContentLkToPageTabSectionIds=" + ContentLkToPageTabSectionIds + "&Source=" + source)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        var _legalInfoSave = function (legalInfoObj, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualLegalSave/' + key + '?Source=' + AppConfig.Source, legalInfoObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _loadIndividualLegalAddInfo = function (individualId, applicationId, userId, legalInfoObj, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/LoadIndividualLegalAddInfo/' + key +
                '?individualId=' + individualId + '&applicationId=' + applicationId + '&userId=' + userId, legalInfoObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _loadAllIndividualLegalAddInfo = function (individualId, applicationId, userId, legalInfoObj, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/LoadAllIndividualLegalAddInfo/' + key +
                '?individualId=' + individualId + '&applicationId=' + applicationId + '&userId=' + userId, legalInfoObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveIndividualLegalAddInfo = function (individualId, applicationId, userId, legalInfoObj, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/SaveIndividualLegalAddInfo/' + key +
                '?individualId=' + individualId + '&applicationId=' + applicationId + '&userId=' + userId, legalInfoObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            legalInfoSave: _legalInfoSave,
            legalInfoGet: _legalInfoGet,
            getLegalInfoContent: _getLegalInfoContent,
            loadIndividualLegalAddInfo: _loadIndividualLegalAddInfo,
            saveIndividualLegalAddInfo: _saveIndividualLegalAddInfo,
            loadAllIndividualLegalAddInfo: _loadAllIndividualLegalAddInfo
        };
    }
})();
