﻿(function () {
    'use strict'
    angular.module('app.core').factory('addressDirectiveService', ['$http', 'WebApiUrl', function ($http, WebApiUrl) {
        var BaseUrl = WebApiUrl;
        var BaseFactory = {};
        BaseFactory.GetAddressDetails = function (urlParam) {
            return $http.get(BaseUrl + "Individual/IndividualAddressBYIndividualId" + urlParam);
        };
        BaseFactory.SaveAddressDetails = function (key, address) {
            return $http.post(BaseUrl + 'Individual/IndividualAddressSave/' + key + '?Source=', address);
        };
        BaseFactory.GetStateList = function (key, cID) {
            return $http.get(BaseUrl + "State/StateGetByCountryID/" + key + "?CountryID=" + cID);
        };
        return BaseFactory;
    }]);
})();