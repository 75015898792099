(function () {
    'use strict';

    angular.module('app.routeAuthorization', [])
        .config(configRoutes)
        .run(routeCheck);

    configRoutes.$inject = ['$stateProvider'];
    function configRoutes($stateProvider) {
        var $delegate = $stateProvider.state;
        $stateProvider.state = function (name, definition) {
            if (!definition.resolve) {
                definition.resolve = {};
            }

            return $delegate.apply(this, arguments);
        };
    }

    routeCheck.$inject = ['$rootScope', '$state'];

    function routeCheck($rootScope, $state) {
        var stateChangeStartEvent = $rootScope.$on('$stateChangeStart', function (event, next, current) {
            if (!!next && !!next.resolve && !next.resolve.authorizeRoute) {
                next.resolve.authorizeRoute = ['$q', '$http', '$location', 'WebApiUrl', function ($q, $http, $location, WebApiUrl) {
                    var deferred = $q.defer();
                    var urlParams = {
                        Route: $location.path(),
                        LoadPermissions: true
                    };

                    var model = {
                        PageName: next.url,
                        IndividualId: sessionStorage.IndividualId,
                        EntityId: sessionStorage.ProviderId
                    };

                    $http.get(WebApiUrl + 'Authorization/GetRouteAuthorization/' + ((!!sessionStorage.Key) ? sessionStorage.Key : 'public') + '/', { params: urlParams })
                        .then(function (response) {
                            if (response.data.IsAuthorized) {

                                $http.post(WebApiUrl + 'Logging/AuditvisitInfoSave/' + (!!sessionStorage.Key ? sessionStorage.Key : 'public') + '/', model);

                                if (response.data.RoutePermission) {
                                    sessionStorage.routePermission = JSON.stringify(response.data.RoutePermission);
                                } else {
                                    sessionStorage.removeItem("routePermission");
                                }
                                deferred.resolve(response);
                            } else {
                                sessionStorage.removeItem("routePermission");
                                deferred.reject(response);
                            }
                        }, function (err) {
                            deferred.reject(err);
                        });

                    return deferred.promise;
                }];
            }
        });

        $rootScope.$on('$destroy', function () {
            stateChangeStartEvent();
        });

        // var stateChangeStartEvent = $rootScope.$on('$stateChangeStart', function (event, next, current) {
        //     verifyUserTypeAndAccess(event, next);
        // });

        // $rootScope.$on('$destroy', function () {
        //     stateChangeStartEvent();
        // });

        // var verifyUserTypeAndAccess = function (event, next) {
        //     if (!sessionStorage.UserTypeId || sessionStorage.UserTypeId != 1) {
        //         var nextUrl = (!!next.url ? next.url.toLowerCase() : "");
        //         if (!!nextUrl && !isAllowedRoute(nextUrl) && isRejectedRoute(nextUrl)) {
        //             event.preventDefault();
        //             $state.go('app.DomainLogin');
        //         }
        //     }
        // };

        // var isAllowedRoute = function (nextUrl) {
        //     var isAllowedRoute = false;
        //     RoutesData.allowedRoutes.map(function (allowedRoute) {
        //         if (allowedRoute.toLowerCase() == nextUrl) {
        //             isAllowedRoute = true;
        //         }
        //     });
        //     return isAllowedRoute;
        // };

        // var isRejectedRoute = function (nextUrl) {
        //     var isRejectedRoute = false;
        //     RoutesData.staffOnlyRoutes.map(function (rejectedRoute) {
        //         if (nextUrl.indexOf(rejectedRoute.toLowerCase()) != -1) {
        //             isRejectedRoute = true;
        //         }
        //     });
        //     return isRejectedRoute;
        // };
    };
})();
