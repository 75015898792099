﻿angular.module('natApp').factory('BackofficeIndividualService', ['$http', '$q', 'WebApiUrl', 'utilityService', function ($http, $q, WebApiUrl, utilityService) {
    var responseHandler = function (httpPromise, success, fail) {
        var deferred = $q.defer();
        httpPromise
            .then(function (res) {
                if (success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function (error) {
                if (fail) {
                    fail(error);
                }
                console.log("Request failed with status: " + error.status);
                deferred.reject(error);
            });
        return deferred.promise;
    };

    var individualSearchContent = null;
    var FirmSearchDataContent = null;
    var _individualSearchWithPage = function (individualSearchContent, key, PageNumber, NoOfRecords) {
        var deferred = $q.defer();
        $http.post(WebApiUrl + 'Individual/IndividualSearchWithPage/' + key + '?PageNumber=' + PageNumber + '&NoOfRecords=' + NoOfRecords,
            individualSearchContent)
            .then(function (data) {
                data = data.data;
                deferred.resolve(data);
            }, function (error) {
                error = error.data;
                window.location.href = "#/Backoffice/Loginstaff";
                deferred.reject(error);
            });
        return deferred.promise;
    };

    var _setIndividualSearchContent = function (value) {
        individualSearchContent = value;
    };
    var _getIndividualSearchContent = function () {
        return individualSearchContent;
    };

    // var _verifyLicenseWithPager = function (individualSearchContent) {
    //     var deferred = $q.defer();
    //     $http.post(WebApiUrl + 'Individual/IndividualVerifyLicenseLAMED',
    //         individualSearchContent)
    //         .then(function(data){ data = data.data;
    //             deferred.resolve(data);
    //         }, function(error) { error = error.data;
    //             deferred.reject(error);
    //         });
    //     return deferred.promise;
    // };

    var _verifyLicenseWithPager = function (individualSearchContent, PageNumber, NoOfRecords) {
        var deferred = $q.defer();
        $http.post(WebApiUrl + 'Provider/IndividualProviderVerifySearchWithPage?PageNumber=' + PageNumber + '&NoOfRecords=' + NoOfRecords + '&ShowAllRecords=false&Source=WYREALESTATE',
            individualSearchContent)
            .then(function (data) {
                data = data.data;
                deferred.resolve(data);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
        return deferred.promise;
    };

    var _getIndividualFPMethod = function (key, individualId, applicationId, individualBackgroundcheckId) {
        var deferred = $q.defer();
        $http.get(WebApiUrl + 'Individual/GetIndividualFPMethod/' + (!!key ? key : '') +
            '?IndividualId=' + individualId + '&ApplicationId=' + applicationId + '&IndividualBackgroundcheckId=' + individualBackgroundcheckId)
            .then(function (data) {
                data = data.data;
                deferred.resolve(data);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
        return deferred.promise;
    };

    var _downloadVerifyLicense = function (individualId) {
        var urlParams = {
            IndividualId: individualId
        };
        var httpPromise = $http.get(WebApiUrl + 'Individual/DownloadLicenseVerificationLAMED/', {
            params: urlParams,
            responseType: 'arraybuffer'
        });
        return responseHandler(httpPromise, function (res) {
            var headers = res.headers();
            utilityService.downloadExcelFile(res.data, headers);
        });
    };

    var _getMalpracticeByIndividualId = function (IndividualId) {
        var deferred = $q.defer();
        //var BoardInfoId = (Number(sessionStorage.getItem('search.BoardInfoId')));
        $http.get(WebApiUrl + 'Individual/MalpracticeByIndividualId?IndividualId=' + IndividualId)
            .then(function (data) {
                data = data.data;
                deferred.resolve(data);
            }, function (error) {
                error = error.data;
                window.location.href = "#/Backoffice/Loginstaff";
                deferred.reject(error);
            });
        return deferred.promise;
    };

    var _getLicenseCountReport = function () {
        var deferred = $q.defer();
        $http.get(WebApiUrl + 'Individual/GetVerifyLicenseInformation')
            .then(function (data) {
                data = data.data;
                deferred.resolve(data);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
        return deferred.promise;
    };

    var _verifyLicenseSearchBYIndividualId = function (individualId) {
        var deferred = $q.defer();
        $http.get(WebApiUrl + 'Individual/VerifyLicenseSearchBYIndividualId?IndividualId=' + individualId)
            .then(function (data) {
                data = data.data;
                deferred.resolve(data);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
        return deferred.promise;
    };

    var _setFirmSearchContent = function (value, type) {
        FirmSearchContent = value;
        FirmSearchContentType = type;
    };
    var _getFirmSearchContent = function () {
        return { FirmSearchContent: FirmSearchContent, FirmSearchContentType: FirmSearchContentType };
    };
    var _setFirmSearchDataContent = function (value, type) {
        FirmSearchDataContent = value;
    };
    var _getFirmSearchDataContent = function () {
        return FirmSearchDataContent;
    };

    return {
        setIndividualSearchContent: _setIndividualSearchContent,
        getIndividualSearchContent: _getIndividualSearchContent,
        setFirmSearchContent: _setFirmSearchContent,
        getFirmSearchContent: _getFirmSearchContent,
        getFirmSearchDataContent: _getFirmSearchDataContent,
        setFirmSearchDataContent: _setFirmSearchDataContent,
        individualSearchWithPage: _individualSearchWithPage,
        verifyLicenseWithPager: _verifyLicenseWithPager,
        downloadVerifyLicense: _downloadVerifyLicense,
        getMalpracticeByIndividualId: _getMalpracticeByIndividualId,
        getLicenseCountReport: _getLicenseCountReport,
        verifyLicenseSearchBYIndividualId: _verifyLicenseSearchBYIndividualId,
        getIndividualFPMethod: _getIndividualFPMethod,
        IndividualNameSave: function (IndividualNameId, IndividualId, FirstName, MiddleName, LastName, SuffixId, IndividualNameStatusId, IndividualNameTypeId, IsActive, BeginDate, EndDate, IsDeleted, key) {

            return $http.post(WebApiUrl + 'Individual/IndividualNameSave/' + key, { IndividualNameId: IndividualNameId, IndividualId: IndividualId, FirstName: FirstName, MiddleName: MiddleName, LastName: LastName, SuffixId: SuffixId, IndividualNameStatusId: IndividualNameStatusId, IndividualNameTypeId: IndividualNameTypeId, IsActive: IsActive, BeginDate: BeginDate, EndDate: EndDate, IsDeleted: IsDeleted });
        },
        LicenseConversion: function (IndividualLicenseId, IndividualId, ApplicationId, ApplicationTypeId, LicenseTypeId, IsLicenseTemporary, IsLicenseActive, LicenseNumber, OriginalLicenseDate, LicenseEffectiveDate, LicenseExpirationDate, LicenseStatusTypeId, IsActive, IsDeleted, LicenseStatusTypeCode, LicenseStatusTypeName, LicenseStatusColorCode, LicenseTypeName, Description, LicenseDetail, key) {

            return $http.post(WebApiUrl + 'Individual/IndividualLicenseConversion/' + key, { IndividualLicenseId: IndividualLicenseId, IndividualId: IndividualId, ApplicationId: ApplicationId, ApplicationTypeId: ApplicationTypeId, LicenseTypeId: LicenseTypeId, IsLicenseTemporary: IsLicenseTemporary, IsLicenseActive: IsLicenseActive, LicenseNumber: LicenseNumber, OriginalLicenseDate: OriginalLicenseDate, LicenseEffectiveDate: LicenseEffectiveDate, LicenseExpirationDate: LicenseExpirationDate, LicenseStatusTypeId: LicenseStatusTypeId, IsActive: IsActive, IsDeleted: IsDeleted, LicenseStatusTypeCode: LicenseStatusTypeCode, LicenseStatusTypeName: LicenseStatusTypeName, LicenseStatusColorCode: LicenseStatusColorCode, LicenseTypeName: LicenseTypeName, Description: Description, LicenseDetail: LicenseDetail });
        },
        CorrespondenceSave: function (IndividualCommunicationLogId, IndividualId, ApplicationId, MasterTransactionId, PageModuleId, PageModuleTabSubModuleId, PageTabSectionId, EffectiveDate, EndDate, CommunicationLogDate, Type, EmailFrom, UserIdFrom, Subject, CommunicationSource, CommunicationText, CommunicationStatus, ReferenceNumber, IsInternalOnly, IsForInvestigationOnly, IsForPublic, IsActive, IsDeleted, EmailTo, UserIdTo, DocumentUploadList, key) {

            return $http.post(WebApiUrl + 'Individual/IndividualCommunicationSave/' + key, {
                IndividualCommunicationLogId: IndividualCommunicationLogId,
                IndividualId: IndividualId,
                ApplicationId: ApplicationId,
                MasterTransactionId: MasterTransactionId,
                PageModuleId: PageModuleId,
                PageModuleTabSubModuleId: PageModuleTabSubModuleId,
                PageTabSectionId: PageTabSectionId,
                EffectiveDate: EffectiveDate,
                EndDate: EndDate,
                CommunicationLogDate: CommunicationLogDate,
                Type: Type,
                EmailFrom: EmailFrom,
                UserIdFrom: UserIdFrom,
                Subject: Subject,
                CommunicationSource: CommunicationSource,
                CommunicationText: CommunicationText,
                CommunicationStatus: CommunicationStatus,
                ReferenceNumber: ReferenceNumber,
                IsInternalOnly: IsInternalOnly,
                IsForInvestigationOnly: IsForInvestigationOnly,
                IsForPublic: IsForPublic,
                IsActive: IsActive,
                IsDeleted: IsDeleted,
                EmailTo: EmailTo,
                UserIdTo: UserIdTo,
                DocumentUploadList: DocumentUploadList
            });
        },
        LicenseTypeGetAll: function (key) {

            return $http.get(WebApiUrl + 'TypeValues/LicenseTypeGetAll/' + key);
        },

        LookupGetBYLookupTypeIDForCitizen: function (key) {

            return $http.get(WebApiUrl + 'Lookup/LookupGetBYLookupTypeID/' + key + '?LookupTypeID=45');
        },
        LookupGetBYLookupTypeIDForNameStatus: function (key) {

            return $http.get(WebApiUrl + 'Lookup/LookupGetBYLookupTypeID/' + key + '?LookupTypeID=72');
        },
        LookupGetBYLookupTypeIDForAddressStatus: function (key) {

            return $http.get(WebApiUrl + 'Lookup/LookupGetBYLookupTypeID/' + key + '?LookupTypeID=72');
        },

        SearchIndividual: function (Name, FirstName, LastName, Email, Phone, LicenseNumber, SSN, MiddleName, Address, IndividualId, IsActive, key) {

            return $http.post(WebApiUrl + 'Individual/IndividualSearch/' + key, { Name: Name, FirstName: FirstName, LastName: LastName, Email: Email, Phone: Phone, LicenseNumber: LicenseNumber, SSN: SSN, MiddleName: MiddleName, Address: Address, IndividualId: IndividualId, IsActive: IsActive });
        },

        IndividualNameBYIndividualId: function (Key, IndividualId) {

            return $http.get(WebApiUrl + 'Individual/IndividualNameBYIndividualId/' + Key + '?IndividualId=' + IndividualId);
        },
        IndividualLicenseBYIndividualId: function (Key, IndividualId) {

            return $http.get(WebApiUrl + 'Individual/IndividualLicenseBYIndividualId/' + Key + '?IndividualId=' + IndividualId);
        },
        IndividualCorrespondenceGetByIndividualId: function (Key, IndividualId) {
            return $http.get(WebApiUrl + 'Individual/IndividualCommunicationGetByIndividualId/' + Key + '?IndividualId=' + IndividualId);
        },
        IndividualDocumentSave: function (IndividualId, ApplicationId, Email, SendEmail, TransactionId, DocumentUploadList, key) {

            return $http.post(WebApiUrl + 'Individual/IndividualDocumentSave/' + key, { IndividualId: IndividualId, ApplicationId: ApplicationId, Email: Email, SendEmail: SendEmail, TransactionId: TransactionId, DocumentUploadList: DocumentUploadList });
        },
        PdfDocumentByIndividualDocumentId: function (Key, IndividualDocumentId) {

            return $http.get(WebApiUrl + 'Individual/PdfDocumentByIndividualDocumentId/' + Key + '?IndividualDocumentId=' + IndividualDocumentId);
        },
        IndividualUnPaidInvoiceByIndividualId: function (Key, IndividualId) {
            return $http.get(WebApiUrl + 'Individual/UnpaidInvoiceByIndividualId/' + Key + '?IndividualId=' + IndividualId);
        },
        IndividualPaidInvoiceByIndividualId: function (Key, IndividualId) {
            return $http.get(WebApiUrl + 'Individual/PaidInvoiceByIndividualId/' + Key + '?IndividualId=' + IndividualId);
        },
        DocumentMasterGetALL: function (Key) {
            return $http.get(WebApiUrl + 'Document/DocumentMasterGetALL/' + Key);
        },
        IndividualAffidavitByIndividualId: function (Key, IndividualId) {
            return $http.get(WebApiUrl + 'Individual/IndividualAffidavitByIndividualId/' + Key + '?IndividualId=' + IndividualId);
        },
        IndividualVeteranByIndividualId: function (Key, IndividualId) {
            return $http.get(WebApiUrl + 'Individual/IndividualVeteranByIndividualId/' + Key + '?IndividualId=' + IndividualId);
        },
        IndividualChildSupportByIndividualId: function (Key, IndividualId) {
            return $http.get(WebApiUrl + 'Individual/IndividualChildSupportByIndividualId/' + Key + '?IndividualId=' + IndividualId);
        },
        IndividualLegalGetByIndividualId: function (Key, IndividualId) {
            return $http.get(WebApiUrl + 'Individual/IndividualLegalGetByIndividualId/' + Key + '?IndividualId=' + IndividualId);
        },
        IndividualFinanceByIndividualId: function (Key, IndividualId, providerId) {
            if (IndividualId == 0) {
                return $http.get(WebApiUrl + 'Payment/GetPaymentDetailsByIndividualId/' + Key + '?IndividualId=&ProviderId=' + providerId + '&Source=WYPEPLS');
            }
            else {
                return $http.get(WebApiUrl + 'Payment/GetPaymentDetailsByIndividualId/' + Key + '?IndividualId=' + IndividualId + '&ProviderId=&Source=WYPEPLS');
            }
        },
        GenerateApplicationId: function (Key, ApplicationId, UserId, ApplicationGuid, ApplicationTypeId, ApplicationStatusId, ApplicationStatusReasonId, ApplicationNumber, ApplicationSubmitMode, StartedDate, SubmittedDate, ApplicationStatusDate, PaymentDeadlineDate, PaymentDate, ConfirmationNumber, ReferenceNumber, IsFingerprintingNotRequired, IsPaymentRequired, CanProvisionallyHire, GoPaperless, LicenseRequirementId, WithdrawalReasonId, LicenseTypeId, IsActive, ApplicationType, ApplicationStatus, IsPaid, ApplicationInternalStatusId, HighPriority) {
            return $http.post(WebApiUrl + 'Application/GenerateApplicationId/' + Key + '?ApplicationId=' + ApplicationId + "&UserId=" + UserId, {
                ApplicationGuid: ApplicationGuid,
                ApplicationId: ApplicationId,
                ApplicationTypeId: ApplicationTypeId,
                ApplicationStatusId: ApplicationStatusId,
                ApplicationStatusReasonId: ApplicationStatusReasonId,
                ApplicationNumber: ApplicationNumber,
                ApplicationSubmitMode: ApplicationSubmitMode,
                StartedDate: StartedDate,
                SubmittedDate: SubmittedDate,
                ApplicationStatusDate: ApplicationStatusDate,
                PaymentDeadlineDate: PaymentDeadlineDate,
                PaymentDate: PaymentDate,
                ConfirmationNumber: ConfirmationNumber,
                ReferenceNumber: ReferenceNumber,
                IsFingerprintingNotRequired: IsFingerprintingNotRequired,
                IsPaymentRequired: IsPaymentRequired,
                CanProvisionallyHire: CanProvisionallyHire,
                GoPaperless: GoPaperless,
                LicenseRequirementId: LicenseRequirementId,
                WithdrawalReasonId: WithdrawalReasonId,
                LicenseTypeId: LicenseTypeId,
                IsActive: IsActive,
                ApplicationType: ApplicationType,
                ApplicationStatus: ApplicationStatus,
                IsPaid: IsPaid,
                ApplicationInternalStatusId: ApplicationInternalStatusId,
                HighPriority: HighPriority
            });
        },//
        ApplicationStatusChange: function (Key, ApplicationId, ApplicationStatusId) {
            return $http.post(WebApiUrl + 'Common/ApplicationStatusChange/' + Key + '?ApplicationId=' + ApplicationId + "&ApplicationStatusId=" + ApplicationStatusId);
        },
        ApplicationStatusUpdate: function (Key, ApplicationId, ApplicationStatusId) {
            return $http.post(WebApiUrl + 'Common/ApplicationStatusUpdate/' + Key + '?ApplicationId=' + ApplicationId + "&ApplicationStatusId=" + ApplicationStatusId);
        },
        IndividualSpecialitybyIndividualId: function (Key, individualId) {
            return $http.get(WebApiUrl + 'Individual/IndividualSpecialitybyIndividualId/' + Key + '?IndividualId=' + individualId);
        },
        GetMessageByIdorCode: function (urlParam) {
            ApiPath = 'Message/GetMessageByIdorCode' + urlParam;
            return $http.get(WebApiUrl + ApiPath);
        },
        IndividualLogTableSave: function (urlParam, data) {
            ApiPath = 'Individual/IndividualLogTableSave' + urlParam;
            return $http.post(WebApiUrl + ApiPath, data);
        },
        GetApplicationBYIndividualId: function (key, individualId) {
            var apiPath = 'Individual/ApplicationBYIndividualId/' + key + '?IndividualId=' + individualId;
            return $http.get(WebApiUrl + apiPath);
        },
        ConfigurationGetbySettings: function (urlparam) {
            return $http.get(WebApiUrl + 'Configuration/ConfigurationGetbySettings/' + urlparam);
        },
        IndividualBYIndividualId: function (Key, IndividualId) {
            return $http.get(WebApiUrl + 'Individual/IndividualBYIndividualId/' + Key + '?IndividualId=' + IndividualId);
        }
    };
}]);
