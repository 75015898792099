

(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeIndividualAllegationService', backofficeIndividualAllegationService)

    backofficeIndividualAllegationService.$inject = ['$http', '$q', 'WebApiUrl']

    function backofficeIndividualAllegationService($http, $q, WebApiUrl) {

        var _getIndividualAllegation = function (individualId, key) { return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualAllegation/' + key + '?individualId=' + individualId)) };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };
        var _getIndividualAllegationAndDocs = function (individualId, key) { return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualAllegationAndDocs/' + key + '?individualId=' + individualId)) };



        var _RefAllegationDescription = function (individualId, key) {
            return returnDefferedResult($http.get(WebApiUrl + 'typevalues/refallegationdescriptiontypegetall/' + key + '?source=' + individualId));
        }
        var _RefAllegationStatus = function (individualId, key, AllegationTypeId) {
            return returnDefferedResult($http.get(WebApiUrl + 'TypeValues/RefAllegationStatusGetAll/' + key + '?AllegationTypeId=' + AllegationTypeId + '&Source=NVDENTAL'));
        }


        var _saveAllegation = function (lstIndividualAllegation, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/SaveIndividualAllegation/' + key + '?Source=NVDENTAL', lstIndividualAllegation));
        }
        var _saveAllegationDoc = function (lstIndividualAllegation, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/SaveIndividualAllegationAndDocs/' + key + '?Source=NVDENTAL', lstIndividualAllegation));
        }

        var _saveIndividualdocumentlk = function (doc, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/SaveIndividualdocumentlk/' + key + '?Source=NVDENTAL', doc));
        }
        var _getIndividualDocumentlk = function (individualId, LinkId, key, LinkType) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualDocumentlk/' + key + '?IndividualId=' + individualId + '&ApplicationId=' + null + '&LinkId=' + LinkId + '&LinkType=' + LinkType));
        }
        var _individualDocumentDeleteByID = function (id, key) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualDocumentDeleteByID/' + key + '?ID=' + id));
        }

        return {
            getIndividualAllegation: _getIndividualAllegation,
            refAllegationDescription: _RefAllegationDescription,
            refAllegationStatus: _RefAllegationStatus,
            saveAllegation: _saveAllegation,
            saveIndividualdocumentlk: _saveIndividualdocumentlk,
            getIndividualDocumentlk: _getIndividualDocumentlk,
            saveAllegationDoc: _saveAllegationDoc,
            getIndividualAllegationAndDocs: _getIndividualAllegationAndDocs,
            individualDocumentDeleteByID: _individualDocumentDeleteByID
        }
    }
})();