(function () {
    'use strict';

    angular
        .module('app.provider')
        .controller("providerAddressDirectiveController", providerAddressController);

    providerAddressController.$inject = ["$scope", "$rootScope", "$state",
        "backofficeProviderAdressService", "countryStateService", "typeValuesService", "$q", "$exceptionHandler",
        "configurationService", "utilityService", "SettingsValue", "TypeValue", "AppConfig", "WebApiUrl", "$filter", "Messages"];

    function providerAddressController($scope, $rootScope, $state,
        backofficeProviderAdressService, countryStateService, typeValuesService, $q, $exceptionHandler,
        configurationService, utilityService, SettingsValue, TypeValue, AppConfig, WebApiUrl, $filter, Messages) {

        //Tab Initilizer
        $scope.init = function () {
            if ($scope.providerId) {
                getCountryList();
                $scope.getInitData($rootScope.DefaultCountryId);
            }
        };

        var getCountryList = function () {
            ShowLoader("Please Wait...");
            var deferred = $q.defer();
            try {
                countryStateService.countryGetAll(sessionStorage.Key).then(function (res) {
                    if (res.Status) {
                        $scope.CountryList = res.Country;
                    }
                    deferred.resolve(res);
                    HideLoader();
                }, function (err) {
                    HideLoader();
                    // console.log('response', res);
                    deferred.reject(err);
                });
            }
            catch (ex) {
                HideLoader();
                // console.log('Exception GetCountryList()', ex);
                deferred.reject(ex);
            }
            return deferred.promise;
        };



        $scope.addresstypeListBind = [{ Name: 'Corporate Mailing Address', Value: 4 }, { Name: 'Corporate Physical Address', Value: 6 }, { Name: 'Additional Location', Value: 11 }];
        $scope.ContacttypeListBind = [
            { Name: 'Website', Value: 17 },
            { Name: 'Business Phone', Value: 21 },
            { Name: 'Business Email', Value: 23 },
            { Name: 'Secondary Business Email', Value: 24 },
            { Name: 'Additional Location Email', Value: 26 },
            { Name: 'Additional Location Phone', Value: 27 }
        ];

        //Common starts here
        $scope.viewConfig = {};

        $scope.getInitData = function (countryID) {
            ShowLoader("Please Wait...");
            var dataPromises = [];

            dataPromises.push(configurationService.configurationGetbySettings(sessionStorage.Key, SettingsValue.ShowAddressandContactSeparately));
            dataPromises.push(countryStateService.getStateByCountryID(sessionStorage.Key, countryID));
            dataPromises.push(typeValuesService.getTypeValuesByTypeName(TypeValue.Address, sessionStorage.Key));
            dataPromises.push(typeValuesService.getTypeValuesByTypeName(TypeValue.Contact, sessionStorage.Key));
            dataPromises.push($scope.providerAddressBYProviderId($scope.providerId, true));
            dataPromises.push($scope.providerContactBYProviderId($scope.providerId, true));

            $q.all(dataPromises)
                .then(function (response) {

                    // console.log('response', response)

                    if (response) {
                        angular.forEach(response, function (responseItem, itemIndex) {
                            if ($scope.checkServerResponse({ response: responseItem })) {
                                if (itemIndex == 0) {
                                    if (responseItem.ConfigurationList.length > 0) {
                                        var settingValue = responseItem.ConfigurationList[0].Value;
                                        if (settingValue.toLowerCase() == "true") {
                                            $scope.showAddressandContactSeparately = true;
                                        } else if (settingValue.toLowerCase() == "false") {
                                            $scope.showAddressandContactSeparately = false;
                                        }
                                    }
                                } else if (itemIndex == 1) {
                                    $scope.StatesData = responseItem.State;
                                } else if (itemIndex == 2) {
                                    $scope.AddressTypeList = responseItem.AddressTypeGetList.filter(function (u) { return u.AppliestoProvider == true });
                                } else if (itemIndex == 3) {
                                    $scope.ContactTypeList = responseItem.ContactTypeGetList.filter(function (u) { return (u.AppliestoProvider == true && (u.ContactTypeId == 24 || u.ContactTypeId == 25 || u.ContactTypeId == 26)) });
                                } else if (itemIndex == 4) {
                                    processAddressData(responseItem.ProviderAddress)
                                } else if (itemIndex == 5) {
                                    processContactsData(responseItem.ProviderContacts)
                                }
                            }
                        });
                        if (!$scope.showAddressandContactSeparately) {
                            assignAddressByAddressTypeId();
                        }
                        $scope.isDataLoaded = true;
                    }
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    if (data != null) { $scope.showStatusMessage({ message: data.message }, { type: "error" }); }
                });
        };

        var isAddressOrContactUpdated = function (currentItem, originalItem, isAddress) {
            if (!!$scope.showAddressandContactSeparately) {
                var fieldsToCompare;
                if (!!isAddress) {
                    fieldsToCompare = ['AddressTypeId', 'StreetLine1', 'StreetLine2', 'City', 'StateCode', 'CountryId', 'Zip', 'BeginDate', 'EndDate', 'IsMailingSameasPhysical'];
                } else {
                    fieldsToCompare = ['ContactTypeId', 'ContactInfo', 'IsPreferredContact'];
                }
                var isfieldsUpdated = false;
                angular.forEach(fieldsToCompare, function (fieldToCompare) {
                    if (angular.isDate(currentItem[fieldToCompare]) && angular.isDate(originalItem[fieldToCompare])) {
                        if (!utilityService.compareDateswithoutTime(currentItem[fieldToCompare], originalItem[fieldToCompare])) {
                            isfieldsUpdated = true;
                        }
                    } else {
                        if (currentItem[fieldToCompare] != originalItem[fieldToCompare]) {
                            isfieldsUpdated = true;
                        }
                    }
                });
                return isfieldsUpdated;
            } else {
                var fieldsToCompare = ['AddressTypeId', 'StreetLine1', 'StreetLine2', 'City', 'StateCode', 'Zip', 'BeginDate', 'EndDate'];
                var isfieldsUpdated = false;
                angular.forEach(fieldsToCompare, function (fieldToCompare) {
                    if (angular.isDate(currentItem[fieldToCompare]) && angular.isDate(originalItem[fieldToCompare])) {
                        if (!utilityService.compareDateswithoutTime(currentItem[fieldToCompare], originalItem[fieldToCompare])) {
                            isfieldsUpdated = true;
                        }
                    } else {
                        if (currentItem[fieldToCompare] != originalItem[fieldToCompare]) {
                            isfieldsUpdated = true;
                        }
                    }
                });
                if (currentItem.AddressTypeId == 10 || currentItem.AddressTypeId == 11) {
                    if ((currentItem.Addressee != originalItem.Addressee) ||
                        (currentItem.OfficePhoneContact.ContactInfo != originalItem.OfficePhoneContact.ContactInfo) ||
                        (currentItem.OfficeFaxContact.ContactInfo != originalItem.OfficeFaxContact.ContactInfo)
                    ) {
                        isfieldsUpdated = true;
                    }
                } else if (currentItem.AddressTypeId == 2) {
                    if ((currentItem.CellPhoneContact.ContactInfo != originalItem.CellPhoneContact.ContactInfo) ||
                        (currentItem.HomePhoneContact.ContactInfo != originalItem.HomePhoneContact.ContactInfo) ||
                        (currentItem.EmailAddressContact.ContactInfo != originalItem.EmailAddressContact.ContactInfo) ||
                        (currentItem.FaxContact.ContactInfo != originalItem.FaxContact.ContactInfo)
                    ) {
                        isfieldsUpdated = true;
                    }
                }
                if (currentItem.AddressTypeId == 10 || currentItem.AddressTypeId == 2) {
                    if (currentItem.IsMailingSameasPhysical != originalItem.IsMailingSameasPhysical) {
                        isfieldsUpdated = true;
                    }
                }

                return isfieldsUpdated;
            }
        };

        var getAddressOrContactActionFlag = function (isEdit, currentItem, originalItem, isAddress) {
            var actionFlag = '';
            if (!!$scope.showAddressandContactSeparately) {
                if (!isEdit) {
                    actionFlag = 'UI'
                } else {
                    // if ((isAddress && (currentItem.AdressStatusId == originalItem.AdressStatusId)) || (!isAddress && (currentItem.ContactStatusId == originalItem.ContactStatusId))) {
                    if ((isAddress) || (!isAddress && (currentItem.ContactStatusId == originalItem.ContactStatusId))) {
                        if (isAddressOrContactUpdated(currentItem, originalItem, isAddress)) {
                            actionFlag = "UI";
                        } else {
                            actionFlag = "U";
                        }
                    } else {
                        if (isAddressOrContactUpdated(currentItem, originalItem, isAddress)) {
                            actionFlag = "UIS";
                        } else {
                            actionFlag = "U";
                        }
                    }
                }
            } else {
                if (!isEdit) {
                    actionFlag = 'UI'
                } else {
                    if (currentItem.AdressStatusId == originalItem.AdressStatusId) {
                        if (isAddressOrContactUpdated(currentItem, originalItem)) {
                            actionFlag = "UI";
                        } else {
                            actionFlag = "U";
                        }
                    } else {
                        if (isAddressOrContactUpdated(currentItem, originalItem)) {
                            actionFlag = "UIS";
                        } else {
                            actionFlag = "U";
                        }
                    }
                }
            }
            return actionFlag;
        };
        //common ends here

        //Seperate Contact starts here
        $scope.ContactInputType = "Email";
        $scope.validateEmail = false;
        $scope.validateUrl = false;
        $scope.validatePhone = false;
        $scope.viewConfig.showContactList = true;
        $scope.ContactStatuses = [
            { ContactStatusId: 1, ContactStatusName: 'Current' },
            { ContactStatusId: 2, ContactStatusName: 'Previous' },
            { ContactStatusId: 3, ContactStatusName: 'Other' },
            { ContactStatusId: 4, ContactStatusName: 'Pending' }
        ];

        $scope.openContactAddNew = function (contactForm) {
            contactForm.$setPristine();
            $scope.clearContact();
            $scope.viewConfig.showContactList = false;
            $scope.contact.ContactStatusId = 1;
            $scope.contact.isEdit = false;
            $scope.originalContact = angular.copy($scope.contact);
        };

        $scope.setContactTypeValidation = function (contacTypeId, keepContactValue) {
            var contactTypeDesc = "";

            angular.forEach($scope.ContactTypeList, function (contactType, index) {
                if (contactType.ContactTypeId == contacTypeId) {
                    contactTypeDesc = contactType.Desc;
                }
            });
            $scope.validateEmail = false;
            $scope.validateUrl = false;
            $scope.validatePhone = false;
            if (!keepContactValue) {
                $scope.contact.ContactInfoEmail = "";
                $scope.contact.ContactInfoUrl = "";
                $scope.contact.ContactInfoPhone = "";
            }
            if (contactTypeDesc.indexOf("Email") >= 0) {
                $scope.ContactInputType = "Email";
                $scope.validateEmail = true;
                if (keepContactValue)
                    $scope.contact.ContactInfoEmail = $scope.contact.ContactInfo;
            } else if (contactTypeDesc.indexOf("Website") >= 0) {
                $scope.ContactInputType = "Url";
                $scope.validateUrl = true;
                if (keepContactValue)
                    $scope.contact.ContactInfoUrl = $scope.contact.ContactInfo;
            } else {
                $scope.ContactInputType = "Phone";
                $scope.validatePhone = true;
                if (keepContactValue)
                    $scope.contact.ContactInfoPhone = $scope.contact.ContactInfo;
            }
        };

        $scope.editContact = function (contact) {
            $scope.contact = angular.copy(contact);
            $scope.contact.ContactStatusId = 1;
            $scope.setContactTypeValidation($scope.contact.ContactTypeId, true);
            $scope.viewConfig.showContactList = false;
            $scope.originalContact = angular.copy(contact);
            $scope.contact.isEdit = true;
        };

        $scope.deleteContact = function (contact) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                ShowLoader("Please wait...");
                contact.ActionFlag = 'U';
                contact.IsDeleted = true;
                backofficeProviderAdressService.addressContactSave([contact], sessionStorage.Key)
                    .then(function (response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse({ response: response })) {
                            $scope.providerContactBYProviderId($scope.providerId);
                            $scope.clearContact();
                            $scope.viewConfig.showContactList = true;
                            // $scope.$emit("individualAddressChanged", {});
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) { $scope.showStatusMessage({ message: data.message }, { type: "error" }); }
                    });

            }
        };

        $scope.clearContact = function () {
            $scope.contact = {};
        };

        var checkIfSameContactExists = function (contact) {
            var isContactAlreadyExists = false;
            angular.forEach($scope.personalContact, function (contactItem, index) {
                if ((contact.ContactTypeId == contactItem.ContactTypeId) &&
                    (contact.ProviderContactId != contactItem.ProviderContactId) &&
                    //(contact.ContactStatusId != contactItem.ContactStatusId) &&
                    (contact.ContactInfo == contactItem.ContactInfo)) {
                    isContactAlreadyExists = true;
                }
            });
            return isContactAlreadyExists;
        };

        $scope.addressContactSave = function (contact, addressContactForm) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                addressContactForm.$submitted = true;
                if ($scope.validateData({ form: addressContactForm })) {

                    if ($scope.ContactInputType == "Email") {
                        contact.ContactInfo = $scope.contact.ContactInfoEmail;
                    }
                    if ($scope.ContactInputType == "Url") {
                        contact.ContactInfo = $scope.contact.ContactInfoUrl;
                    }
                    if ($scope.ContactInputType == "Phone") {
                        contact.ContactInfo = $scope.contact.ContactInfoPhone;
                    }
                    ShowLoader("Please wait...");
                    if (!checkIfSameContactExists(contact)) {
                        contact.providerId = $scope.providerId;
                        contact.IsActive = true;
                        if (contact.ContactStatusId == 1 && contact.isEdit == true) {
                            //Update
                            $scope.originalContact.ContactStatusId = 2;
                            backofficeProviderAdressService.addressContactSave([$scope.originalContact], sessionStorage.Key)
                                .then(function (response) {

                                    //Add New
                                    contact.ContactStatusId = 1;
                                    contact.ProviderContactId = 0;
                                    backofficeProviderAdressService.addressContactSave([contact], sessionStorage.Key)
                                        .then(function (response) {
                                            $scope.hideStatusMessage();
                                            $scope.hideValidationSummary();
                                            if ($scope.checkServerResponse({ response: response })) {
                                                $scope.providerContactBYProviderId($scope.providerId);
                                                $scope.showStatusMessage({ message: response.Message }, { type: "success" });
                                                $scope.clearContact();
                                                $scope.viewConfig.showContactList = true;
                                            }
                                            HideLoader();
                                        }, function (data) {
                                            HideLoader();
                                            if (data != null) { $scope.showStatusMessage({ message: data.Message }, { type: "error" }); }
                                        });
                                }, function (data) {
                                    HideLoader();
                                    if (data != null) { $scope.showStatusMessage({ message: data.Message }, { type: "error" }); }
                                });
                        }
                        else {
                            backofficeProviderAdressService.addressContactSave([contact], sessionStorage.Key)
                                .then(function (response) {
                                    $scope.hideStatusMessage();
                                    $scope.hideValidationSummary();
                                    if ($scope.checkServerResponse({ response: response })) {
                                        $scope.providerContactBYProviderId($scope.providerId);
                                        $scope.showStatusMessage({ message: response.Message }, { type: "success" });
                                        $scope.clearContact();
                                        $scope.viewConfig.showContactList = true;
                                        // $scope.$emit("individualAddressChanged", {});
                                    }
                                    HideLoader();
                                }, function (data) {
                                    HideLoader();
                                    if (data != null) { $scope.showStatusMessage({ message: data.Message }, { type: "error" }); }
                                });
                        }

                    } else {
                        $scope.showStatusMessage({ message: "Same contact already exists" }, { type: "error" });
                        HideLoader();
                    }

                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        var processContactsData = function (contactsData) {
            if (!!contactsData && contactsData.length != 0) {
                angular.forEach(contactsData, function (contact, index) {
                    angular.forEach($scope.ContactTypeList, function (contactType, index) {
                        if (contactType.ContactTypeId == contact.ContactTypeId) {
                            contact.Desc = contactType.Desc;
                        }
                    });
                    angular.forEach($scope.ContactStatuses, function (contactStatus, index) {
                        if (contact.ContactStatusId == contactStatus.ContactStatusId) {
                            contact.ContactStatusName = contactStatus.ContactStatusName;
                        }
                    });
                });
            }
            $scope.contactsData = contactsData;
            $scope.personalContact = contactsData;
        };

        $scope.providerContactBYProviderId = function (providerId, processDataExternally) {
            try {
                var contactDataPromises = backofficeProviderAdressService.providerContactBYProviderId(sessionStorage.Key, providerId);
                contactDataPromises.then(function (response) {
                    if (!processDataExternally) {
                        var contactsData = response.ProviderContacts;
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse({ response: response })) {
                            processContactsData(contactsData);
                        }
                    }
                }, function (data) {
                    $scope.showStatusMessage({ message: data.Message }, { type: "error" });
                });
                return contactDataPromises;
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        //Seperate Contact ends here

        //Seperate Address starts here
        $scope.viewConfig.showAddressList = true;
        $scope.AddressStatuses = [
            { AddressStatusId: 1, AddressStatusName: 'Current' },
            { AddressStatusId: 2, AddressStatusName: 'Previous' },
            { AddressStatusId: 3, AddressStatusName: 'Other' },
            { AddressStatusId: 4, AddressStatusName: 'Pending' }
        ];

        $scope.datePicker = {
            beginDateOpened: false,
            endDateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            },
            startDateOptions: {
                maxDate: null
            },
            endDateOptions: {
                minDate: null
            }
        };

        $scope.setAddressEndMinDate = function (address) {
            if (address != undefined) {
                if (!!address.BeginDate && angular.isDate(address.BeginDate))
                    $scope.datePicker.endDateOptions.minDate = address.BeginDate;
            }
        };
        $scope.setAddressStartMaxDate = function (address) {
            if (address != undefined) {
                if (!!address.EndDate && angular.isDate(address.EndDate))
                    $scope.datePicker.startDateOptions.maxDate = address.EndDate;
            }
        };

        $scope.getStateByCountryID = function (CountryID) {
            $scope.StatesData = [];
            try {
                countryStateService.getStateByCountryID(sessionStorage.Key, CountryID)
                    .then(function (response) {
                        if ($scope.checkServerResponse({ response: response })) {
                            $scope.StatesData = response.State;
                        }
                    }, function (data) {
                        if (data != null) { $scope.showStatusMessage({ message: data.Message }, { type: "error" }); }
                    });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        var processAddressData = function (addressData) {
            if (addressData != null) {
                angular.forEach(addressData, function (address, addressKey) {
                    angular.forEach($scope.AddressTypeList, function (addressType, index) {
                        if (addressType.AddressTypeId == address.AddressTypeId) {
                            address.AddressTypeDesc = addressType.AddressTypeDesc;
                        }
                    });

                    angular.forEach($scope.StatesData, function (state, index) {
                        if (state.StateCode == address.StateCode) {
                            address.State = state.Name;
                        }
                    });

                    angular.forEach($scope.AddressStatuses, function (addressStatus, index) {
                        if (address.AddressStatusId == addressStatus.AddressStatusId) {
                            address.AddressStatusName = addressStatus.AddressStatusName;
                        }
                    });
                })
            }
            $scope.AddressData = addressData;
            $scope.personalAddress = addressData;
        };

        $scope.providerAddressBYProviderId = function (providerId, processDataExternally) {
            try {
                var addressDataPromise = backofficeProviderAdressService.providerAddressBYProviderId(sessionStorage.Key, providerId);
                addressDataPromise.then(function (response) {
                    if (!processDataExternally) {
                        var addressData = response.ProviderAddress;
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse({ response: response })) {
                            processAddressData(addressData);
                        }
                    }
                }, function (data) {
                    $scope.showStatusMessage({ message: data.Message }, { type: "error" });
                });
                return addressDataPromise;
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        $scope.openAddressAddNew = function (addressForm) {
            addressForm.$setPristine();
            $scope.clearAddress();
            $scope.viewConfig.showAddressList = false;
            $scope.address.BeginDate = new Date();
            $scope.address.AddressStatusId = 1;
            $scope.setAddressStartMaxDate($scope.address);
            $scope.setAddressEndMinDate($scope.address);
            $scope.address.isEdit = false;
            $scope.originalAddress = angular.copy($scope.address);
        };

        $scope.editAddress = function (address, ProviderAddressId) {
            $scope.viewConfig.showAddressList = false;
            if (!!address.BeginDate && !angular.isDate(address.BeginDate))
                address.BeginDate = new Date(address.BeginDate);
            if (!!address.EndDate && !angular.isDate(address.EndDate))
                address.EndDate = new Date(address.EndDate);
            if (address.AdressStatusId === 0)
                address.AdressStatusId = "";
            address.AddressStatusId = 1;
            $scope.setAddressStartMaxDate($scope.address);
            $scope.setAddressEndMinDate($scope.address);
            if (!!address.CountryId)
                $scope.getStateByCountryID(address.CountryId);
            $scope.address = angular.copy(address);
            $scope.originalAddress = angular.copy(address);
            $scope.address.isEdit = true;
        };

        $scope.saveAddress = function (address, addressForm) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                addressForm.$submitted = true;
                if ($scope.validateData({ form: addressForm })) {
                    ShowLoader("Please wait...");
                    address.providerId = $scope.providerId;
                    address.IsActive = true;
                    address.ActionFlag = 'UI';
                    if (address.AddressStatusId == 1 && address.isEdit == true) {
                        $scope.originalAddress.AddressStatusId = 2;
                        //Update  Address
                        backofficeProviderAdressService.addressSave($scope.originalAddress, sessionStorage.Key)
                            .then(function (response) {
                                address.ProviderAddressId = 0;
                                address.AddressStatusId = 1;
                                //Insert new Address
                                backofficeProviderAdressService.addressSave(address, sessionStorage.Key)
                                    .then(function (response) {
                                        $scope.providerAddressBYProviderId($scope.providerId);
                                        $scope.clearAddress();
                                        $scope.viewConfig.showAddressList = true;
                                        $scope.showStatusMessage({ message: response.Message }, { type: "success" });
                                        HideLoader();
                                    }, function (data) {
                                        HideLoader();
                                        if (data != null) { $scope.showStatusMessage({ message: data.Message }, { type: "error" }); }
                                    });
                                HideLoader();
                            }, function (data) {
                                HideLoader();
                                if (data != null) { $scope.showStatusMessage({ message: data.Message }, { type: "error" }); }
                            });
                    }
                    else {

                    }
                    backofficeProviderAdressService.addressSave(address, sessionStorage.Key)
                        .then(function (response) {
                            $scope.providerAddressBYProviderId($scope.providerId);
                            $scope.clearAddress();
                            $scope.viewConfig.showAddressList = true;
                            $scope.showStatusMessage({ message: response.Message }, { type: "success" });
                            HideLoader();
                        }, function (data) {
                            HideLoader();
                            if (data != null) { $scope.showStatusMessage({ message: data.Message }, { type: "error" }); }
                        });
                    HideLoader();
                }
            } catch (ex) {
                // console.log('ex', ex);
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        $scope.deleteAddress = function (address) {
            $scope.IndividualAddressContactAddStatus = true;
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                address.IsActive = true;
                address.IsDeleted = true;
                address.ActionFlag = 'U';
                ShowLoader("Please wait...");
                backofficeProviderAdressService.addressSave(address, sessionStorage.Key)
                    .then(function (response) {
                        if ($scope.checkServerResponse({ response: response })) {
                            $scope.providerAddressBYProviderId($scope.providerId);
                            $scope.showStatusMessage({ message: response.Message }, { type: "success" });
                            $scope.clearAddress();
                            $scope.viewConfig.showAddressList = true;
                            $scope.$emit("individualAddressChanged", {});
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) { $scope.showStatusMessage({ message: data.Message }, { type: "error" }); }
                    });
            }
        };

        $scope.clearAddress = function () {
            $scope.address = {};
        };
        //Seperate Address  ends here 

    }
})();