(function () {
    'use strict'

    angular.module('app.report')
    .factory('CannedReportsService', ['$http', '$q', 'WebApiUrl', 'utilityService', CannedReportsService]);

    function CannedReportsService($http, $q, WebApiUrl, utilityService) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getCannedReports = function (key, pageName, cannedReportId) {
            var urlParams = {
                CannedReportId: cannedReportId,
                PageName: pageName
            };

            return responseHandler($http.get(WebApiUrl + 'Report/GetCannedReports/' + key + '/', { params: urlParams }));
        };

        var _executeCannedReport = function (key, request) {
            return responseHandler($http.post(WebApiUrl + 'Report/ExecuteCannedReport/' + key + '/', request));
        };

        var _executeCannedReportAndDownloadDocument = function (key, data) {
            var httpPromise = $http.post(WebApiUrl + 'Report/ExecuteCannedReportAndDownloadDocument/' + key + '/', JSON.stringify(data), {
                responseType: 'arraybuffer'
            });
            return responseHandler(httpPromise, function(res){
                var headers = res.headers();
                utilityService.downloadExcelFile(res.data, headers);
            });
        };
        
        return {
            getCannedReports: _getCannedReports,
            executeCannedReport: _executeCannedReport,
            executeCannedReportAndDownloadDocument: _executeCannedReportAndDownloadDocument
        };
    }
})();