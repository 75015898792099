(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintInvolvedPartyLogic', [ComplaintInvolvedPartyLogic]);

    function ComplaintInvolvedPartyLogic() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.licenseTypesLoaded = function (dataList) {
                    dataList.conditionalSplice('item.LicenseTypeName=="Other"');

                    dataList.push({
                        LicenseTypeId: 0,
                        LicenseTypeName: 'Unlicensed Individual'
                    });
                };

                $scope.providerLicenseTypesLoaded = function (dataList) {
                    dataList.push({
                        ProviderLicenseTypeId: 0,
                        ProviderLicenseTypeName: 'Unlicensed Firm'
                    });
                };
            },
        };
    }
})();