(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('trustAccountFundHolderService', trustAccountFundHolderService)

    trustAccountFundHolderService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function trustAccountFundHolderService($http, $q, WebApiUrl, AppConfig) {

        var _providerLicenseDetailBYProviderId = function (Key, ProviderId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Provider/GetProviderLicense/' + Key + '?ProviderId=' + ProviderId + '&Source=WYRealEstate'));
        };

        var _SaveProviderLicense = function (Key, data) {
            return returnDefferedResult($http.post(WebApiUrl + 'Provider/SaveProviderLicense/' + Key + '?Source=' + AppConfig.Source, data));
        };
        //var _licenseSave = function(individualLicense, key) {
        //    return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualLicenseSave/' + key, individualLicense));
        //};
        var _getMaxLicenseByLicenseTypeId = function (Key, licenseTypeId) {
            return returnDefferedResult($http.get(WebApiUrl + 'IndividualLicense/MaxLicenseByLicenseTypeId/' + Key + '?LicenseTypeId=' + licenseTypeId + '&Source=' + AppConfig.Source + 'FIRM'));
        };

        var _saveEntityAccount = function (Key, data) {
            return returnDefferedResult($http.post(WebApiUrl + 'Entity/SaveEntityAccount/' + Key + '?Source=' + AppConfig.Source + 'FIRM', data));
        };

        var _getEntityAccount = function (Key, EntityId, EntityType, ApplicationId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Entity/GetEntityAccount/' + Key + '?EntityId=' + EntityId + '&EntityType=' + EntityType + '&ApplicationId=' + ApplicationId + '&Source=' + AppConfig.Source + 'FIRM'));
        };

        var _licenseTypeGetAll = function (key) {
            return $http.get(WebApiUrl + 'TypeValues/RefProviderLicenseTypeGetAll/' + key);
        };
        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };
        return {
            providerLicenseDetailBYProviderId: _providerLicenseDetailBYProviderId,
            SaveProviderLicense: _SaveProviderLicense,
            getMaxLicenseByLicenseTypeId: _getMaxLicenseByLicenseTypeId,
            licenseTypeGetAll: _licenseTypeGetAll,
            getEntityAccount: _getEntityAccount,
            saveEntityAccount: _saveEntityAccount
        }
    }
})();
