(function() {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('providerHistoryDisclosureService', providerHistoryDisclosureService)
    providerHistoryDisclosureService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function providerHistoryDisclosureService($http, $q, WebApiUrl, AppConfig) {

        var _getProviderHistoryDisclosure = function(Key, IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Provider/GetProviderHistoryDisclosure/' + Key + '?ProviderHistoryDisclosureId=null&ProviderId=' + IndividualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveProviderHistoryDisclosure = function(Key, data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/SaveProviderHistoryDisclosure/' + Key + '?Source=' + AppConfig.Source, data)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            getProviderHistoryDisclosure: _getProviderHistoryDisclosure,
            saveProviderHistoryDisclosure: _saveProviderHistoryDisclosure
        }
    }
})();