(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .directive('surgeryValidation', SurgeryValidation);

    SurgeryValidation.$inject = [];

    function SurgeryValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function () {
                    $scope.natValidation.errorMessages = [];
                    $scope.invaliddate = false;
                    if (!!$scope.currentForm) {
                        $scope.currentForm.$submitted = true;
                        $scope.natValidation.errorMessages = angular.copy($scope.currentForm.errorMessages);
                        if (!$scope.natValidation.errorMessages) {
                            $scope.natValidation.errorMessages = [];
                        }
                    }

                    if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                        $scope.natValidation.showError = true;
                        $scope.natValidation.isValid = false;
                        return false;
                    } else {
                        $scope.natValidation.showError = false;
                        $scope.natValidation.isValid = true;
                        return true;
                    }
                };
            }
        };
    }
})();