(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('initialApplicationVeteranInfoService', initialApplicationVeteranInfoService)

    initialApplicationVeteranInfoService.$inject = ['$http', '$q', 'WebApiUrl']

    function initialApplicationVeteranInfoService($http, $q, WebApiUrl) {
        var _saveVeteranInfo = function (veteranObj, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualVeteranListSave/' + key, veteranObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveVeteranInfoConfirmation = function (veteranConfirmationObj, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualVeteranConfirmationSave/' + key, veteranConfirmationObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _getIndividualVeteranConfByIndividualId = function (key, individualId, applicationId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualVeteranConfByIndividualId/' + key + '?individualId=' + individualId + '&applicationId=' + applicationId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            saveVeteranInfo: _saveVeteranInfo,
            saveVeteranInfoConfirmation: _saveVeteranInfoConfirmation,
            getIndividualVeteranConfByIndividualId: _getIndividualVeteranConfByIndividualId
        };
    }
})();