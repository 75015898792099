(function () {
    angular.module('app.backofficeAdministor')
        .factory('BulkEmailFactory', TemplateMessageFactory);
    TemplateMessageFactory.$inject = ['$http', '$q', 'WebApiUrl'];
    function TemplateMessageFactory($http, $q, WebApiUrl) {
        var _individualSearchBulkEmailWithPage = function (key,pagenumber,records,data) {
            var data = $http.post(WebApiUrl + 'Individual/IndividualSearchBulkEmailWithPage/' + key + '?PageNumber=' + pagenumber + '&NoOfRecords=' + records, data);
            return returnResult(data);
        };

        var _individualCommunicationSave = function (key, data) {
            var data = $http.post(WebApiUrl + 'Individual/IndividualCommunicationSave/' + key, data);
            return returnResult(data);
        };

        var _configurationGetbySettings = function (key, setting) {
            var data = $http.get(WebApiUrl + 'Configuration/ConfigurationGetbySettings/' + key + '?setting=' + setting);
            return returnResult(data);
        };

        var _licenseTypeGetAll = function (key) {
            var data = $http.get(WebApiUrl + 'TypeValues/LicenseTypeGetAll/' + key);
            return returnResult(data);
        };

        var returnResult = function (data) {
            var deferred = $q.defer();
            data
                .then(function (response) {
                    deferred.resolve(response);
                })
                .catch(function (response) {
                    deferred.reject(response);
                });
            return deferred.promise;
        };
        return {
            IndividualSearchBulkEmailWithPage: _individualSearchBulkEmailWithPage,
            IndividualCommunicationSave: _individualCommunicationSave,
            ConfigurationGetbySettings: _configurationGetbySettings,
            LicenseTypeGetAll: _licenseTypeGetAll
        };
    }
})();
