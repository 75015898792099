(function () {
    'use strict'

    angular.module('app.core')
    .controller('AddressInputController', ['$rootScope', '$scope', '$q', 'utilityService', 'AddressInputService', AddressInputController]);

    function AddressInputController($rootScope, $scope, $q, utilityService, AddressInputService) {
        var _key = sessionStorage.Key;
        var _countryIdDefault = $rootScope.DefaultCountryId;

        var updateConfigSizes = function (config) {
            if (!config.LSize) {
                config.LSize = 1;
            };
            if (!config.ISize) {
                if (config.InputType == 'email') {
                    config.ISize = 5;
                } else {
                    config.ISize = 2;
                }
            }

            config.lClass = 'col-md-{0}'.format(config.LSize);

            config.iClass = 'col-md-{0}'.format(config.ISize);
        };

        $scope.$watch('contactsConfig', function (newVal) {
            if (!!newVal && !!$scope.address) {
                var currentSpacing = 0;

                newVal.forEach(function (config) {
                    if (!$scope.address[config.Name]) { return; }

                    currentSpacing += config.LSize + config.ISize;
                
                    if (currentSpacing > 12) {
                        $scope.address[config.Name].newLine = true;
                        currentSpacing = config.LSize + config.ISize;
                    } else {
                        $scope.address[config.Name].newLine = false;
                    }

                    updateConfigSizes(config);
                });
            }
        });

        $scope.init = function () {
            $scope.stateList = [];
            $scope.displayStateList = [];
            $scope.countyList = [];
            $scope.displayCountyList = [];
            $scope.countryList = [];
            $scope.displayCountryList = [];

            if(!$scope.addressChanged) {
                $scope.addressChanged = function () {};
            }

            if(!$scope.address) {
                $scope.address = {
                    AttentionTo: '',
                    StreetLine1: '',
                    StreetLine2: '',
                    City: '',
                    StateCode: '',
                    Zip: '',

                    CountyId: 0,
                    CountryId: _countryIdDefault,
                    
                    IsActive: true,
                    IsDeleted: false,

                    isEmpty: true
                };
            }

            checkAddressEmpty();

            if(typeof($scope.address.CountryId) === 'undefined' || $scope.address.CountryId == null || $scope.address.IsActive == null ) {
                $scope.address.CountryId = _countryIdDefault;
                $scope.useStateDropdown = true;
            }
            if(typeof($scope.address.IsActive) === 'undefined' || $scope.address.IsActive == null ) {
                $scope.address.IsActive = true;
            }
            if(typeof($scope.address.IsDeleted) === 'undefined' || $scope.address.IsActive == null) {
                $scope.address.IsDeleted = false;
            }
            if(typeof($scope.isDisabled) === 'undefined' || $scope.isDisabled == null ) {
                $scope.isDisabled = false;
            }
            if(typeof($scope.addressName) === 'undefined' || $scope.addressName == null ) {
                $scope.addressName = 'address-input';
            }
            if(typeof($scope.isRequired) === 'undefined' || $scope.isRequired == null ) {
                $scope.isRequired = 'false';
            }

            if ($scope.isRequired=='true' && $scope.addressName=='address-input') {
                throw 'You must supply an address name when the address is required, for validation purposes.';
            }

            if (!$scope.contactsConfig || $scope.contactsConfig.length == 0) {
                $scope.contactsConfig = null;
            }

            $scope.updateValidation();

            $scope.useStateDropdown = $scope.address.CountryId == _countryIdDefault || $scope.address.CountryId == null;

            $scope.$watch(function() { return $scope.isRequired; }, function (newVal, oldVal) {
                $scope.updateValidation();
            });

            if (!!$scope.methods) {
                $scope.methods.validateAddress = $scope.validateAddress;
            }
        };

        $scope.stateListLoaded = function () {
            $scope.stateList = $scope.stateList.orderBy('Name');
            $scope.displayStateList = angular.copy($scope.stateList);
            $scope.updateValidation();
        };

        $scope.countyListLoaded = function () {
            $scope.countyList = $scope.countyList.orderBy('Name');
            $scope.displayCountyList = [];
            $scope.updateValidation();
        };

        $scope.countryListLoaded = function () {
            $scope.countryList = $scope.countryList.orderBy('Name');
            $scope.displayCountryList = angular.copy($scope.countryList);
            $scope.updateValidation();
        };

        var checkAddressEmpty = function () {
            $scope.address.isAddressEmpty = !$scope.address.StreetLine1 && !$scope.address.StreetLine2 && !$scope.address.City && !$scope.address.StateCode && !$scope.address.Zip;
        };

        $scope.updateValidation = function() {
            if (!$scope.address) {
                return;
            }
            
            $scope.filterCountyList();

            $scope.validation = {
                AttentionTo: true,
                StreetLine1: ($scope.isRequired=='false' || !!$scope.address.StreetLine1),
                StreetLine2: true,
                City: ($scope.isRequired=='false' || !!$scope.address.City),
                StateCode: ($scope.isRequired=='false' || !!$scope.address.StateCode),
                Zip: ($scope.isRequired=='false' || !!$scope.address.Zip),

                CountryId: ($scope.includeCountry && $scope.displayCountryList.length > 0) ? ($scope.isRequired=='false' || !!$scope.address.CountryId) : true,

                CountyId: ($scope.includeCounty && $scope.displayCountyList.length > 0) ? ($scope.isRequired=='false' || !!$scope.address.CountyId) : true,
            };

            if (!!$scope.contactsConfig) {
                $scope.contactsConfig.forEach(function (config) {
                    var contact = $scope.address[config.Name];
                    $scope.validation[config.Name] = ($scope.isRequired=='false' || !config.IsRequired || !!contact.ContactInfo);
                });
            } else {
                $scope.validation.Phone = (!!$scope.includePhone) ? ($scope.isRequired=='false' || !!$scope.address.Phone) : true;
                $scope.validation.Email = (!!$scope.includeEmail) ? ($scope.isRequired=='false' || !!$scope.address.Email) : true;
                $scope.validation.Fax = (!!$scope.includeFax) ? ($scope.isRequired=='false' || !!$scope.address.Fax) : true;
                $scope.validation.Cell = (!!$scope.includeCell) ? ($scope.isRequired=='false' || !!$scope.address.Cell) : true;
            }

            checkAddressEmpty();
        };

        $scope.copyInfoTo = function (config) {
            if (!!config.CopyInfoTo) {
                $scope.address[config.CopyInfoTo] = $scope.address[config.Name].ContactInfo;
            }
        };

        $scope.$watch('address', function (newVal, oldVal) {
            $scope.updateValidation();
        }, true);

        $scope.validateForm = function () {
            var valid = true;

            valid = valid && !!$scope.address.StreetLine1;
            valid = valid && !!$scope.address.City;
            valid = valid && !!$scope.address.StateCode;
            valid = valid && !!$scope.address.Zip;

            if ($scope.includeCounty && $scope.displayCountyList.length > 0) {
                valid = valid && !!$scope.address.CountyId
            }
            if ($scope.includeCountry) {
                valid = valid && !!$scope.address.CountryId
            }

            if (!!$scope.contactsConfig) {
                $scope.contactsConfig.forEach(function (config) {
                    var contact = $scope.address[config.Name];
                    valid = valid && (!contact.IsRequired || !!contact.ContactInfo);
                });
            } else {
                if (!!$scope.includePhone) {
                    valid = valid && !!$scope.address.Phone;
                }
                if (!!$scope.includeEmail) {
                    valid = valid && !!$scope.address.Email;
                }
                if (!!$scope.includeFax) {
                    valid = valid && !!$scope.address.Fax;
                }
                if (!!$scope.includeCell) {
                    valid = valid && !!$scope.address.Cell;
                }
            }

            return valid;
        };

        $scope.validateAddress = function () {
            $scope.sendWait($scope.addressName);

            if ($scope.isRequired=='true') {
                if ($scope.validateForm()) {
                    $scope.sendComplete($scope.addressName);
                    return true;
                } else {
                    $scope.showStatusMsg('-', 'You must fill out all the required fields for this address.')
                    $scope.sendCancel();
                    return false;
                }
            } else {
                $scope.sendComplete($scope.addressName);
                return true;
            }
        };

        $scope.fireAddressChanged = function () {
            $scope.hideStatusMsg();
            
            $scope.addressChanged({address: $scope.address});

            $scope.updateValidation();
        };

        $scope.countryChanged = function () {
            $scope.useStateDropdown = $scope.address.CountryId == _countryIdDefault;
        };

        $scope.filterCountyList = function () {
            if (!!$scope.address && !!$scope.displayStateList && !!$scope.displayStateList.length > 0) {
                var currentState = $scope.displayStateList.whereEquals($scope.address.StateCode, 'StateCode').firstOrDefault();
                if (!!currentState) {
                    $scope.displayCountyList = $scope.countyList.whereEquals(currentState.StateId, 'StateId');
                } else {
                    $scope.address.CountyId = null;
                    $scope.displayCountyList = [];
                }
            }
        };
    }
})();