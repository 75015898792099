(function() {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('supervisingPhysicianValidation', [SupervisingPhysicianValidation]);

    function SupervisingPhysicianValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function () {
                    return true;
                };
            }
        };
    }
})();