(function () {
  'use strict'

  angular.module('app.InitialApplication')
    .factory('initialApplicationUploadDocumentService', initialApplicationUploadDocumentService)

  initialApplicationUploadDocumentService.$inject = ['$http', '$q', 'WebApiUrl']

  function initialApplicationUploadDocumentService($http, $q, WebApiUrl) {

    return {

    };
  }
})();