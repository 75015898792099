(function() {
    'use strict'

    var getTableName = function (table) {
        return (!!table.BaseTable) ? table.BaseTable : table.RelationTableName;
    };

    var searchForTableByName = function (list, name) {
        var table = list.whereEquals(name, 'BaseTable').firstOrDefault();
        if (!table) {
            table = list.whereEquals(name, 'TableName').firstOrDefault();
        }

        return table;
    };

    angular.module('reporting.FieldSearchReporting')
    .filter('fsrHasAnyListColumns', ['$filter', function($filter) {
        return function (refTableList) {
            return refTableList.where(function (t) {
                return $filter('fsrDisplayInList')(t.table.ColumnList).any();
            });
        };
    }])
    .filter('fsrDisplayInForm', [function() {
        return function (columnList) {
            if (!columnList) { return []; }

            return columnList.where('!!item.DisplayInForm');
        };
    }])
    .filter('fsrDisplayInList', [function () {
        return function (columnList) {
            if (!columnList) { return []; }

            return columnList.where('!!item.DisplayInList');
        };
    }])
    .filter('fsrConditions', [function () {
        return function (columnList) {
            if (!columnList) { return []; }
            columnList.forEach(function (col) {
                if (!col.Condition) {
                    col.Condition = { Column: col };
                }
            });
            return columnList.select('Condition');
        };
    }])
    .directive('fsrDateToString', [function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (value) {
                    if (!!value) {
                        return value.getDateObj().toJSON();
                    } else {
                        return '';
                    }
                });
                ngModel.$formatters.push(function (value) {
                    if (!!value) {
                        return value.getDateObj();
                    } else {
                        return null;
                    }
                });             
            }
        };
    }])
    .directive('fsrAddOneDay', [function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function(value) {
                    var m = ngModel;
                    if (!!value) {
                        var day = moment(value).format('YYYY-MM-DD').getDateObj();
                        return day.addDays(1).addSeconds(-1).toJSON();
                    } else {
                        return null;
                    }
                });
                ngModel.$formatters.push(function(value) {
                    var m = ngModel;
                    if (!!value) {
                        return value.getDateObj();
                    } else {
                        return null;
                    }
                });
            }
        };
    }])
    .controller('FieldSearchReportingController', ['$scope', '$q', '$timeout', '$uibModal', '$filter', 'FieldSearchReportingUtility', 'QueryReportingUtility', 'QueryReportingService', 'utilityService', FieldSearchReportingController]);

    function FieldSearchReportingController($scope, $q, $timeout, $uibModal, $filter, FieldSearchReportingUtility, QueryReportingUtility, QueryReportingService, utilityService) {
        var _key = sessionStorage.Key;
        var _queryTypeId = 2;
        var _configLocation = '/components/report/field-search-reporting/field-search-reporting.server-config.json';

        //Begin Utility Methods
        var searchJoinStructure = function (tableName, joinStructure) {
            return [joinStructure].recursiveSelect(tableName);
        };

        var setBlankCondition = function (column) {
            column.Condition = {
                Column: column,
                Operator: '',
                Value: '',
                V1: {
                    dateOpened: false,
                    Value: '',
                },
                Value2: '', 
                V2: {
                    dateOpened: false,
                    Value: ''
                },
    
                hasValue: false,
                isOpen: false,
    
                toString: function() {
                    return Column.FieldDisplayName + ' ' + Operator + ' ' + Value;
                }
            };
    
            if (column.reference) {
                column.Condition.Operator = 'in';
                column.Condition.initOperator = 'in';
                $scope.$evalAsync(function() {column.Condition.isOpen = true;});
            } else {
                var dataType = $filter('qrDataTypeMap')(column.FieldDataType);
    
                switch (dataType) {
                    case 'text': 
                        column.Condition.Operator = 'contains'; 
                        column.Condition.initOperator = 'contains';
                        break;
                    case 'datetime': 
                        column.Condition.Operator = 'between'; 
                        column.Condition.initOperator = 'between';
                        break;
                    default: 
                        column.Condition.Operator = 'equal to'; 
                        column.Condition.initOperator = 'equal to';
                        break;
                }
            }
    
            column.availableOperators = [];
            column.possibleValues = [];
    
            applyColumnSettings(column);
        };

        var getBlankWhereCondition = function(isBlock) {
            return {
                ConditionOperator: 'AND',
                IsBlock: isBlock,

                Block: (isBlock) ? {Conditions: []} : null,

                Column: null,
                Operator: '',
                Value: '',

                IsColumnValue: false,
                ColumnValue: null,

                dateOpened: false
            }
        };

        var validJoinStructureProperty = function (propName, obj) {
            return obj.hasOwnProperty(propName) && !['JoinConditionBlock', 'RelationType', 'MultiJoinSettings', 'TableSettings', 'ColumnSettings', 'relation'].includes(propName);
        };

        var generateQuery = function () {
            var cq = {
                From: {},
                Join: [],
                Where: {},
                GroupBy: [],
                OrderBy: [],
                Select: [],

                ExpressionList: [],

                IsDistinct: true
            };

            var relationList = angular.copy($scope.currentQuery.relationList);

            //Setup FROM and JOIN
            cq.From = relationList[0];
            cq.Join = relationList.slice(1);

            //Setup default relationship type
            cq.Join.forEach(function (rel) {
                if (!!$scope.currentQuery.RelationTypeDefault) {
                    rel.RelationType = $scope.currentQuery.RelationTypeDefault;
                }
            });

            //Setup WHERE
            var baseConditionBlock = getBlankWhereCondition(true);
            baseConditionBlock.Block.Conditions = relationList.recursiveSelect('Condition').where('!!item && item.hasValue');
            if (baseConditionBlock.Block.Conditions.length > 1) {
                for(var i = 1; i < baseConditionBlock.Block.Conditions.length; i++) {
                    baseConditionBlock.Block.Conditions[i].ConditionOperator = 'AND';
                }
            }
            cq.Where = baseConditionBlock.Block;
            cq.Where.Conditions.forEach(function (cond) {
                delete cond.Column.Condition;
            });

            //Setup Pagination
            cq.FromRow = ($scope.results.currentPage - 1) * $scope.results.pageSize;
            cq.RecordToFetch = $scope.results.pageSize;

            return cq;
        };
        //End Utility Methods

        // Begin Initialization Methods
        var resetProperties = function () {
            $scope.currentQuery = $scope.queryList.firstOrDefault();

            $scope.results = {
                totalRecords: 0,
                reportData: {},
                currentPage: 1,
                pageSize: 200,
                maxSize: 5,

                query: null
            };

            $scope.pageParams = {
                currentCategory: null
            };

            if($scope.tableList) {
                $scope.tableList.selectMany('ColumnList').where('!!item.Condition && item.Condition.hasValue').forEach(setBlankCondition);
            }

            $scope.queryList.forEach(function (query) {
                if (query.relationList) {
                    query.relationList.where('!!item.table').select('table').selectMany('ColumnList').where('!!item.Condition && item.Condition.hasValue').forEach(setBlankCondition);
                }
            });
        };

        var getAllSavedQueries = function () {
            var deferred = $q.defer();
            try
            {
                QueryReportingService.getSavedReportingQueries(_key)
                .then(function (data) {
                    if(data.Status) {
                        $scope.savedQueries = data.ReportQueries.whereEquals(_queryTypeId, 'QueryTypeId');
                    } else if (!data.Status && data.Message) {
                        $scope.showStatusMsg('-', data.Message);
                    }
                    deferred.resolve(data);
                }, function (err) {
                    if (err && err.statusText) {
                        $scope.showStatusMsg('-', err.statusText);
                    }
                    deferred.reject(err);
                });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMsg('-', ex.message); }
                deferred.reject(ex);
            }
            return deferred.promise;
        };

        var setupEntities = function (entityResponse) {
            //Setup table list
            $scope.entities = entityResponse.EntityList;
            $scope.entities.forEach(function (e) { 
                e.RelationTableName = e.TableName;
                e.RelationTableDisplayName = e.TableDisplayName;
                e.RelationTableAlias = '';
                e.ColumnList.forEach(function (c) {
                    c.IsExpressionColumn = false;
                    c.TableName = e.TableName;
                    if(e.TableTypeId == 3) {
                        c.reference = true;
                    } else {
                        c.reference = false;
                    }
                });
                e.table = e;
            });
        };

        var setupRelationships = function (relationResponse) {
            //Setup table relationships list
            $scope.baseRelationList = relationResponse.RelationList
            .select(function(a) {
                /** @type {QueryTableRelation} */
                var templ = angular.copy(a);
                templ.RelationType = 0;
                templ.table = angular.copy(searchForTableByName($scope.tableList, templ.RelationTableName));

                if(!!templ.table) {
                    templ.RelationTableDisplayName = templ.table.TableDisplayName;
                } else {
                    templ.RelationTableDisplayName = templ.RelationTableName;
                }
                return templ;
            });
        };

        var applyRelationshipSettings = function (rel, joinStruct) {
            if (!!joinStruct.JoinConditionBlock) {
                rel.IsCustomCondition = true;
                rel.JoinConditionBlock = joinStruct.JoinConditionBlock;
            } else {
                rel.IsCustomCondition = false;
            }

            if (!!joinStruct.RelationType) {
                rel.RelationType = joinStruct.RelationType;
            }
        };
        
        var applyTableSettings = function (rel, joinStruct) {
            var tableName = getTableName(rel.table);
            var settingsObj = (!!joinStruct.MultiJoinSettings) ? joinStruct.MultiJoinSettings[rel.ColumnName] : joinStruct;

            //Table Settings
            if (!!settingsObj.TableSettings) {
                rel.settings = settingsObj.TableSettings;

                //Category Name
                if (!rel.settings.CategoryName) {
                    rel.settings.CategoryName = rel.RelationTableName;
                }

                //Table Display Name
                if (!rel.settings.TableDisplayName) {
                    rel.settings.TableDisplayName = rel.RelationTableDisplayName;
                }
            } else {
                //Setup Defaults
                rel.settings = {
                    CategoryName: rel.RelationTableName,
                    TableDisplayName: rel.RelationTableDisplayName
                };
            }

            rel.table.TableDisplayName = rel.settings.TableDisplayName;
            rel.RelationTableDisplayName = rel.settings.TableDisplayName;

            //Column Settings
            if (!!settingsObj.ColumnSettings) {
                rel.columnSettings = settingsObj.ColumnSettings;

                rel.columnSettings.forEach(function (colSetting) {
                    var column = rel.table.ColumnList.where(function(col) {
                        return col.TableName == tableName && col.FieldName == colSetting.FieldName;
                    }).firstOrDefault();
                    if (!!column) {
                        column.settings = colSetting;

                        applyColumnSettings(column);
                    }
                });
            }
        };

        var applyColumnSettings = function (column) {
            if (!!column.settings) {
                //Field Display Name
                if (!column.settings.FieldDisplayName) {
                    column.settings.FieldDisplayName = column.FieldDisplayName;
                }

                //Possible Values
                if (!column.settings.PossibleValues) {
                    column.settings.PossibleValues = [];
                }
            } else {
                column.settings = {
                    FieldDisplayName: column.FieldDisplayName,
                    PossibleValues: []
                };
            }

            column.FieldDisplayName = column.settings.FieldDisplayName;
            column.possibleValues = column.settings.PossibleValues.select(function (v) { return {value: v}; });
        };

        var createJoinRelations = function () {
            //Now, for each table, search the join structure and apply any settings found to 
            //the corresponding relationship, the table, and its columns
            $scope.queryList.forEach(function (query) {
                //Get the name of the "from" table in this query
                var fromTableName = (function () { for (var n in query.JoinStructure) { return n; } })(); 
                query.fromTableName = fromTableName;

                var fromTable = angular.copy($scope.tableList.whereEquals(fromTableName, 'TableName').firstOrDefault());

                if (!!fromTable) {
                    fromTable.parent = null;
                    fromTable.joins = [];

                    applyTableSettings(fromTable, query.JoinStructure[fromTableName]);
                    
                    query.JoinStructure[fromTableName].relation = [fromTable];
                }

                $scope.tableList.forEach(function (table) {
                    var tableName = getTableName(table);
    
                    var joinList = searchJoinStructure(tableName, query.JoinStructure);
    
                    joinList.forEach(function (join) {
                        for (var joinProp in join) {
                            if (!validJoinStructureProperty(joinProp, join)) { continue; }
    
                            var relation = angular.copy($scope.baseRelationList.where(function (rel) { 
                                return rel.TableName == tableName && rel.RelationTableName == joinProp;
                            }));
    
                            relation.forEach(function (rel) {
                                rel.parent = null;
                                rel.joins = [];
                                applyRelationshipSettings(rel, join[joinProp]);
                                applyTableSettings(rel, join[joinProp]);    
                            });
    
                            join[joinProp].relation = relation;
                        }
                    });
                });
            });
        };

        var setupParentChild = function () {
            $scope.queryList.forEach(function (query) {
                var helper = function (subStructure) {
                    subStructure.relation.forEach(function (parent) {
                        for (var propName in subStructure) {
                            if (!validJoinStructureProperty(propName, subStructure)) { continue; }
    
                            subStructure[propName].relation.forEach(function (child) {
                                parent.joins.push(child);
    
                                child.parent = parent;
                            });
    
                            helper(subStructure[propName]);                        
                        }
                    });
                };

                helper(query.JoinStructure[query.fromTableName]);
            });
        };
        // End Initialization Methods

        var generateDisplayCategories = function (query) {
            query.displayCategories = [];

            query.CategoryList.forEach(function (categoryName) {
                var composite = query.CompositeCategoryList.whereEquals(categoryName, 'Name').firstOrDefault();
                var rels = query.relationList.where(function (rel) {
                    return rel.settings.CategoryName == categoryName;
                });
                var category = {};

                if (!!composite) {
                    category = {
                        Name: composite.Name,
                        DisplayName: composite.DisplayName,
                        ColumnList: [],
                        ConstituentRelationships: []
                    };

                    composite.Include.forEach(function (i) {
                        var relations = query.relationList.where(function (rel) {
                            return rel.settings.CategoryName == i;
                        });

                        category.ColumnList = category.ColumnList.concat(relations.select('table').selectMany('ColumnList'));
                        category.ConstituentRelationships = category.ConstituentRelationships.concat(relations);
                    });

                    category.isOpen = true;

                    query.displayCategories.push(category);
                } else {
                    rels.forEach(function (rel) {
                        category = {
                            Name: rel.RelationTableName,
                            DisplayName: rel.RelationTableDisplayName,
                            ColumnList: [],
                            ConstituentRelationships: []
                        };
                        
                        category.ColumnList = category.ColumnList.concat([rel].select('table').selectMany('ColumnList'));
                        category.ConstituentRelationships = category.ConstituentRelationships.concat([rel]);
                        
                        category.isOpen = true;
    
                        query.displayCategories.push(category);
                    });
                }
            });
        };

        $scope.init = function () {
            $scope.entities = [];
            $scope.tableList = [];
            $scope.relationList = [];
            $scope.removeWatches = [];
            $scope.displayCategories = [];
            $scope.queryList = [];

            resetProperties();

            $q.all([utilityService.getConfigData(_configLocation), 
                QueryReportingService.getQueryReportingEntityList(_key), 
                QueryReportingService.getTableRelationByTable(_key,''), 
                getAllSavedQueries()])
            .then(function (data) {
                if(!data.slice(1).every(function(d) { return d.Status; })) {
                    $scope.showStatusMsg('-', data.select('Message'));

                    $scope.operationPending(false);
                    return;
                }

                //Setup the configuration
                $scope.config = data[0];
                $scope.config.PageHeader = (!!$scope.config.PageHeader) ? $scope.config.PageHeader : 'Field Search Reporting Tool';

                //Setup the query selection
                $scope.queryList = data[0].Queries;

                setupEntities(data[1]);

                $scope.tableList = angular.copy($scope.entities);

                setupRelationships(data[2]);

                createJoinRelations();
                setupParentChild();

                //Initialize the UI
                $scope.currentQuery = $scope.queryList.firstOrDefault();
                $scope.queryChanged();

                $scope.operationPending(false);
            }, function (error) {
                console.log(error); 
                $scope.operationPending(false);
            });
        };

        $scope.$on('$destroy', function() {
            $scope.removeWatches.forEach(function (remove) {
                remove();
            });
        });
        
        $scope.initCondition = function (column) {
            setBlankCondition(column);
            var condition = column.Condition;

            //Monitor any changes to the condition
            var removeWatch = FieldSearchReportingUtility.createConditionWatch(condition);

            $scope.removeWatches.push(removeWatch);
        };

        $scope.clearCondition = function (condition) {
            FieldSearchReportingUtility.clearCondition(condition);
        };

        $scope.collapseAccordion = function (event, category) {
            if (event.target.className.includes('panel-heading') || event.target.className.includes('panel-title')) {
                category.isOpen = !category.isOpen;
            }
        };

        $scope.addTypeaheadClasses = function () {
            QueryReportingUtility.addTypeaheadClasses();
        };

        $scope.queryChanged = function () {
            var query = $scope.currentQuery;

            $scope.currentQuery = null;

            $timeout(function () {
                //Now, get the list of relationships from the join structure as the official list of relationships
                query.relationList = searchJoinStructure('relation', query.JoinStructure[query.fromTableName]).selectMany().where('!!item');

                //Assign aliases to the relationships
                //First, the FROM table (the first in the list)
                var fromTable = query.relationList[0];
                fromTable.TableAlias = 'A';
                fromTable.RelationTableAlias = 'A';
                fromTable.ColumnList.forEach(function (col) {
                    col.TableAlias = 'A';
                });

                //Now, the JOIN relationships (all but the first)
                var aliasGenerator = QueryReportingUtility.getAliasGenerator();
                QueryReportingUtility.assignAliases(query.relationList.slice(1), aliasGenerator, {});

                query.relationList.where('item.IsCustomCondition').forEach(function (rel) {
                    rel.JoinConditionBlock.Conditions.forEach(function (condition) {
                        var assignColAlias = function (rel, column) {
                            if (rel.TableName == column.TableName) {
                                column.TableAlias = rel.TableAlias;
                            }
                            if (rel.RelationTableName == column.TableName) {
                                column.TableAlias = rel.RelationTableAlias;
                            }
                        };

                        if (!!condition.Column) {
                            assignColAlias(rel, condition.Column);
                        }
                        if (!!condition.ColumnValue) {
                            assignColAlias(rel, condition.ColumnValue);
                        }
                        if (!!condition.ColumnValue2) {
                            assignColAlias(rel, condition.ColumnValue2);
                        }
                    });
                });

                //Generate the categories
                generateDisplayCategories(query);

                $scope.currentQuery = query;

                // console.log(query.relationList);
                // console.log(query.displayCategories);
            }, 1);
        };

        $scope.updateMultiValueSelect = function (condition) {
            FieldSearchReportingUtility.updateMultiValueSelect(condition);
        };

        $scope.collapseAll = function (collapse) {
            $scope.currentQuery.displayCategories.forEach(function (category) {
                category.isOpen = collapse;
            });
        };

        $scope.bulkEmailClicked = function () {
            var query = generateQuery();

            var modalInstance = $uibModal.open({
                templateUrl: 'app/components/report/query-reporting/query-reporting-bulk-email.html',
                controller: 'QueryReportingBulkEmailDialogController',
                size: 'lg',
                keyboard: false,
                resolve: {
                    parameters: function () {
                        return {
                            currentQuery: query,
                            processedTables: $scope.currentQuery.relationList.select('table'),
                            expressionList: []
                        };
                    }
                }
            });

            modalInstance.result.then(function (result) {

            }, function () {

            });
        };

        $scope.loadQueryClicked = function () {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/components/report/field-search-reporting/field-search-reporting-load-query.html',
                controller: 'FieldSearchReportingLoadQueryController',
                size: 'md',
                resolve: {
                    parameters: function () {
                        return {
                            savedQueries: $scope.savedQueries,
                            tableList: $scope.tableList
                        };
                    }
                }
            });

            modalInstance.result.then(function (results) {
                //Start the query
                //removeUnusedTables_loaded(results.query);

                if(results.type == 'results') {
                    results.query.FromRow = ($scope.results.currentPage - 1) * $scope.results.pageSize;
                    results.query.RecordToFetch = $scope.results.pageSize;
                    results.query.ExpressionList = [];
                    
                    $scope.executeQueryResults(results.query);
                } else if (results.type == 'document') {
                    $scope.executeQueryDocument(results.query);
                } else if (results.type == 'sql') {
                    $scope.generateSQL(results.query);
                }
                
            }, function () {
                //Dialog dismissed
            });
        };

        $scope.selectDataClicked = function () {
            //Open dialog to select data columns
            var query = generateQuery();

            var modalInstance = $uibModal.open({
                templateUrl: 'app/components/report/field-search-reporting/field-search-reporting-select.html',
                controller: 'FieldSearchReportingSelectController',
                size: 'md',
                resolve: {
                    parameters: function () {
                        return {
                            currentQuery: query,
                            relationList: $scope.currentQuery.relationList,
                            savedQueries: $scope.savedQueries,
                            saveQueryRef: $scope.saveReportingQuery
                        };
                    }
                }
            });

            modalInstance.result.then(function (results) {
                //Start the query
                QueryReportingUtility.removeUnusedTables(results.query);

                if(results.type == 'results') {
                    $scope.executeQueryResults(results.query);
                } else if (results.type == 'document') {
                    $scope.executeQueryDocument(results.query);
                } else if (results.type == 'sql') {
                    $scope.generateSQL(results.query);
                }
                
            }, function () {
                //Dialog dismissed
            });
        };

        $scope.clearClicked = function () {
            resetProperties();

            $scope.queryChanged();
        };

        $scope.setupOperatorValue = function (condition) {
            FieldSearchReportingUtility.setupOperatorValue(condition, $scope.tableList);
        };

        $scope.saveReportingQuery = function (query) {
            var deferred = $q.defer();
            try
            {
                //Start the query
                QueryReportingUtility.removeUnusedTables(query);

                $scope.operationPending(true, 'Saving Query...');
                QueryReportingUtility.prepareQueryForSerialization(query);

                var req = query;
                if (!req.From) {
                    throw {
                        message: 'No tables selected'
                    };
                }
                if (!req.Select || req.Select.length == 0) {
                    throw {
                        message: 'No columns selected for retrieval'
                    };
                }
                if (!req.Name || req.Name.trim().length == 0) {
                    throw {
                        message: 'No name assigned to the saved query'
                    };
                }
                
                req.QueryTypeId = _queryTypeId;
                
                //console.log(query);

                QueryReportingService.saveReportingQuery(_key, req)
                .then(function (data) {
                    //Update the saved query list
                    if (data.Status){
                        $scope.savedQueries = data.ReportQueries.where(_queryTypeId, 'QueryTypeId');
                    } else if (!data.Status && data.Message) {
                        $scope.showStatusMsg('-', data.Message);
                    }
                    $scope.operationPending(false);
                    deferred.resolve(data);
                }, function (err) {
                    if (err && err.statusText) {
                        $scope.showStatusMsg('-', err.statusText)
                    }
                    $scope.operationPending(false);
                    deferred.reject(err);
                });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMsg('-', ex.message); }
                $scope.operationPending(false);
                deferred.reject(ex);
            }
            return deferred.promise;
        };

        $scope.executeQueryResults = function (query) {
            try {
                $scope.operationPending(true, 'Querying...');
                QueryReportingUtility.prepareQueryForSerialization(query);

                //console.log(query);

                QueryReportingService.executeQueryAndGetReportTest(_key, query)
                .then(function (data) {
                    $scope.results.query = query;
                    $scope.results.reportData = data.ReportData;
                    $scope.results.totalRecords = data.TotalRecord;

                    angular.element('.result-panel-content')[0].scrollTop = 0;

                    $scope.operationPending(false);
                }, function (error) {
                    console.log(error);
                    $scope.operationPending(false);
                });
            } catch (ex) {
                console.log(ex);
                $scope.operationPending(false);
            }
        };

        $scope.executeQueryDocument = function (query) {
            try {
                $scope.operationPending(true, 'Exporting...');

                query.FromRow = 0;
                query.RecordToFetch = 0;

                QueryReportingUtility.prepareQueryForSerialization(query);

                QueryReportingService.executeQueryAndGetDocumentTest(_key, query)
                .then(function (data) {
                    //console.log(data);
                    $scope.operationPending(false);
                }, function (error) {
                    console.log(error);
                    $scope.operationPending(false);
                });
            } catch (ex) {
                console.log(ex);
                $scope.operationPending(false);
            }
        };

        $scope.generateSQL = function (query) {
            try {
                $scope.operationPending(true, 'Generating...');

                query.FromRow = 0;
                query.RecordToFetch = 0;

                QueryReportingUtility.prepareQueryForSerialization(query);

                QueryReportingService.getGeneratedSQL(_key, query)
                .then(function (data) {
                    console.log(data);
                    $scope.operationPending(false);
                }, function (error) {
                    console.log(error);
                    $scope.operationPending(false);
                });
            } catch (ex) {
                console.log(ex);
                $scope.operationPending(false);
            }
        };

        $scope.clearResultsClicked = function () {
            $scope.results = {
                totalRecords: 0,
                reportData: {},
                currentPage: 1,
                pageSize: 200,
                maxSize: 5,

                query: null
            };
        };

        $scope.pageChanged = function (query) {
            query.FromRow = ($scope.results.currentPage - 1) * $scope.results.pageSize;

            $scope.executeQueryResults(query);
        };
    }
})();