(function () {
    'use strict';

    angular
        .module('natApp')
        .controller("BackgroundQueueCheckController", BackgroundQueueCheckController);

    BackgroundQueueCheckController.$inject = ["$scope", "$state", "$rootScope", "licenseRenewalService", "typeValuesService", "BackofficeIndividualService", "$q", "WebApiUrl", "utilityService", "TypeValue"];

    function BackgroundQueueCheckController($scope, $state, $rootScope, licenseRenewalService, typeValuesService, BackofficeIndividualService, $q, WebApiUrl, utilityService, TypeValue) {

        $scope.init = function () {
            ShowLoader();
            hideStatusMessage();
            $scope.dojValidation = {};

            $rootScope.routeData = $state.current.routeData;

            $scope.datePicker = {
                fromDateOpened: false,
                toDateOpened: false,
                format: 'M!/d!/yyyy',
                endDateOptions: {
                    minDate: null
                },
                startDateOptions: {}
            };

            if (!$scope.currentRenewalItem) {
                $scope.currentRenewalItem = $scope.current.application;
            }

            var dataLoadPromises = [

                $scope.getIndividualsubsequentarrest(),
                utilityService.getServerDate(true),
                typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=backgroundcheckstatus"))
            ];
            $q.all(dataLoadPromises).then(function (response) {
                $scope.getBackgroundCheckData();
                $scope.serverDate = response[1];
                $scope.backgroundcheckstatusList = response[2].ReferenceEntities;
                HideLoader();
            }, function (error) {
                HideLoader();
                showStatusMessage(error, "error");
            });
        };

        $scope.fingerprintStatusChanged = function (statusType) {
            if (statusType == 'dps') {
                $scope.doj.DOJFingerPrintDate = $scope.serverDate;
            } else if (statusType == 'fbi') {
                $scope.doj.FBIFingerPrintDate = $scope.serverDate;
            }
        };

        $scope.billingReconciliationMethodChanged = function () {
            if (!$scope.doj.BillingReconciliationDate)
                $scope.doj.BillingReconciliationDate = $scope.serverDate;
        };

        $scope.SaveDOJ = function (dojForm, renewalItem) {
            ShowLoader();
            if (!validateForm(dojForm)) {
                HideLoader();
                return;
            }
            var isDeny = null;
            if (!!$scope.doj.SelectToDeny)
                isDeny = true;
            else
                isDeny = false;

            if ($scope.doj.SelectToDeny) {
                $scope.doj.OverallStatus = 'F';
            } else if (!!$scope.doj.DPSOKIssue && !!$scope.doj.FBIOKIssue) {
                $scope.doj.OverallStatus = 'P';
            } else {
                $scope.doj.OverallStatus = null;
            }
            licenseRenewalService.saveIndividualbackgroundcheck($scope.doj, sessionStorage.Key)
                .then(function (response) {
                    //var promises = [];
                    var processLoader = true;
                    if ($scope.doj.SelectToDeny || (!!$scope.doj.DPSOKIssue && !!$scope.doj.FBIOKIssue)) {
                        //$scope.verifyLicense(renewalItem, isDeny);
                        processLoader = false;
                    }
                    // $scope.doj.IndividualFPMethodList.IndividualBackgroundcheckId = response.IndividualBackgroundcheckId;
                    // $scope.doj.IndividualFPMethodList.IsLiveScan = !!$scope.doj.IndividualFPMethodList.liveScanOrFpCard;
                    // $scope.doj.IndividualFPMethodList.IsFingerPrintCard = !$scope.doj.IndividualFPMethodList.liveScanOrFpCard;
                    // promises.push(licenseRenewalService.saveFPMethod([$scope.doj.IndividualFPMethodList], sessionStorage.Key));
                    // $q.all(promises).then(function (responseFPMethod) {
                    //     if (!responseFPMethod[1].Status) {
                    //         showStatusMessage(responseFPMethod[1].Message, 'error');
                    //         return;
                    //     }
                    $scope.getBackgroundCheckData(processLoader);
                    $scope.closeDOJ($scope.currentRenewalItem);
                    HideLoader();
                    // }, function (error) {
                    //     HideLoader();
                    //     showStatusMessage(error, "error");
                    // });
                }, function (error) {
                    HideLoader();
                    showStatusMessage(error, "error");
                });
        };

        $scope.syncBackgroundCheck = function () {
            ShowLoader();
            var processLoader = true;
            licenseRenewalService.updateDOJFBI({
                IndividualId: $scope.currentRenewalItem.IndividualId,
                ApplicationId: $scope.currentRenewalItem.ApplicationId,
                dobunformated: null,
                GenerateBackgroundRecord: null,
                FBIRec: $scope.doj.FBIRecordLocator,
                DOJRec: $scope.doj.DOJRecordLocator,
            }, sessionStorage.Key).then(function (response) {
                $scope.getBackgroundCheckData(processLoader);
                HideLoader();
            });
        }

        $scope.saveIndividualsubsequentarrest = function (subForm, renewalItem) {
            ShowLoader();
            if (!validateSubsequentForm(subForm)) {
                HideLoader();
                return;
            }
            licenseRenewalService.saveIndividualsubsequentarrest($scope.subReq, sessionStorage.Key)
                .then(function (response) {
                    $scope.getIndividualsubsequentarrest(true);
                    $scope.closeSubsequent($scope.currentRenewalItem);
                }, function (error) {
                    showStatusMessage(error, "error");
                });
        };

        $scope.getBackgroundCheckData = function (processLoader) {
            var fingerprintStatus = {
                1: 'In Process',
                2: 'Reprint',
                3: 'Manual',
                4: 'Clear',
                5: 'Hit'
            };
            if (processLoader)
                ShowLoader();
            var dataSavePromise = licenseRenewalService.getIndividualbackgroundcheck($scope.currentRenewalItem.IndividualId, $scope.currentRenewalItem.ApplicationId, $scope.currentRenewalItem.ApplicationTypeId, sessionStorage.Key);
            dataSavePromise.then(function (response) {
                $scope.individualbackgroundcheckList = response.IndividualbackgroundcheckList;
                $scope.doj = $scope.individualbackgroundcheckList[0];
                angular.forEach($scope.individualbackgroundcheckList, function (individualbackgroundcheck) {
                    if (!!individualbackgroundcheck.FBIFingerPrintStatus) {
                        individualbackgroundcheck.FBIFingerPrintStatusName = fingerprintStatus[individualbackgroundcheck.FBIFingerPrintStatus];
                    }
                    if (!!individualbackgroundcheck.DOJFingerPrintStatus) {
                        individualbackgroundcheck.DOJFingerPrintStatusName = fingerprintStatus[individualbackgroundcheck.DOJFingerPrintStatus];
                    }
                });
                if (processLoader)
                    HideLoader();
            }, function (error) {
                if (processLoader)
                    HideLoader();
                showStatusMessage(error, "error");
            });
            return dataSavePromise;
        };

        $scope.getIndividualsubsequentarrest = function (processLoader) {
            if (processLoader)
                ShowLoader();
            var dataSavePromise = licenseRenewalService.getIndividualsubsequentarrest($scope.currentRenewalItem.IndividualId, $scope.currentRenewalItem.ApplicationId, $scope.currentRenewalItem.ApplicationTypeId, sessionStorage.Key);
            dataSavePromise.then(function (response) {
                $scope.individualsubsequentarrestList = response.IndividualsubsequentarrestList;
                if (processLoader)
                    HideLoader();
            }, function (error) {
                if (processLoader)
                    HideLoader();
                showStatusMessage(error, "error");
            });
            return dataSavePromise;
        };

        $scope.addDOJ = function (renewalItem, isEdit, dojItem) {
            renewalItem.ShowDOJ = true;
            $scope.dojValidation = {
                errorMessages: []
            };
            if (!isEdit) {
                $scope.doj = {
                    IndividualId: $scope.currentRenewalItem.IndividualId,
                    ApplicationId: $scope.currentRenewalItem.ApplicationId,
                    ApplicationTypeId: $scope.currentRenewalItem.ApplicationTypeId,
                    IndividualLicenseId: $scope.currentRenewalItem.IndividualLicenseId,
                    LicenseTypeId: $scope.currentRenewalItem.LicenseTypeId,
                    LicenseNumber: $scope.currentRenewalItem.LicenseNumber,
                    IsActive: true
                    // , IndividualFPMethodList: {
                    //     IndividualId: $scope.currentRenewalItem.IndividualId,
                    //     ApplicationId: $scope.currentRenewalItem.ApplicationId,
                    //     ApplicationTypeId: $scope.currentRenewalItem.ApplicationTypeId,
                    //     IsActive: true
                    // }
                };
            } else {
                $scope.doj = angular.copy(dojItem);
                // ShowLoader();
                // BackofficeIndividualService.getIndividualFPMethod(sessionStorage.Key, $scope.doj.IndividualId, $scope.doj.ApplicationId, $scope.doj.IndividualBackgroundcheckId)
                //     .then(function (responseFpMethod) {
                //         //if ($scope.checkServerResponse(responseFpMethod)) {
                //         if (!!responseFpMethod.IndividualFPMethodList && responseFpMethod.IndividualFPMethodList.length > 0) {
                //             $scope.doj.IndividualFPMethodList = responseFpMethod.IndividualFPMethodList[0];
                //             $scope.doj.IndividualFPMethodList.liveScanOrFpCard = !!$scope.doj.IndividualFPMethodList.IsLiveScan;
                //         } else {
                //             $scope.doj.IndividualFPMethodList = {
                //                 IndividualId: $scope.doj.IndividualId,
                //                 ApplicationId: $scope.doj.ApplicationId,
                //                 ApplicationTypeId: $scope.doj.ApplicationTypeId,
                //                 IsActive: true
                //             }
                //         }
                //         // } else {
                //         //     $scope.hideFPMethod = true;
                //         // }
                //         HideLoader();
                //     }, function (error) {
                //         $scope.hideFPMethod = true;
                //         HideLoader();
                //     });
            }
            if (!$scope.doj.CreatedOn) {
                $scope.doj.CreatedOn = $scope.serverDate;
            }
            $scope.documentUploadBGCheck = {
                ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                ApplicationId: renewalItem.ApplicationId,
                IndividualId: renewalItem.IndividualId,
                Email: null,
                SendEmail: null,
                TransactionId: null,
                LicenseeReprint: false,
                DocumentLkToPageTabSectionId: 1,
                DocumentLkToPageTabSectionCode: "FR",
                LoadByDocIdAndDocCode: true,
                DocId: 65,
                DocCode: "D1065",
                LoadDocumentsWithoutBroadCast: true,
                DocumentUploadedForId: (($rootScope.routeData && !!$rootScope.routeData.documentUploadedForId) ? $rootScope.routeData.documentUploadedForId : 1)
            };
        };

        $scope.addDOJDocument = function (renewalItem, dojItem) {
            $scope.documentUploadBGCheck = {
                ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                ApplicationId: renewalItem.ApplicationId,
                IndividualId: renewalItem.IndividualId,
                Email: null,
                SendEmail: null,
                TransactionId: null,
                LicenseeReprint: false,
                DocumentLkToPageTabSectionId: 1,
                DocumentLkToPageTabSectionCode: "FR",
                LoadByDocIdAndDocCode: true,
                DocId: 65,
                DocCode: "D1065",
                LoadDocumentsWithoutBroadCast: true,
                DocumentUploadedForId: (($rootScope.routeData && !!$rootScope.routeData.documentUploadedForId) ? $rootScope.routeData.documentUploadedForId : 1)
            };
            renewalItem.selectedIndividualBackgroundcheckId = dojItem.IndividualBackgroundcheckId;
            renewalItem.ShowUploadDocument = true;
            $scope.$broadcast("initdocumentCreation");
            $scope.$broadcast("loaddocumentListing");
        };

        $scope.addSubsequentRequest = function (renewalItem, isEdit, subReqItem) {
            renewalItem.ShowSubsequentRequest = true;
            $scope.subsequentValidation = {
                errorMessages: []
            };
            if (!isEdit) {
                $scope.subReq = {
                    IndividualId: $scope.currentRenewalItem.IndividualId,
                    ApplicationId: $scope.currentRenewalItem.ApplicationId,
                    ApplicationTypeId: $scope.currentRenewalItem.ApplicationTypeId,
                    IndividualLicenseId: $scope.currentRenewalItem.IndividualLicenseId,
                    LicenseTypeId: $scope.currentRenewalItem.LicenseTypeId,
                    LicenseNumber: $scope.currentRenewalItem.LicenseNumber,
                    IsActive: true
                };
            } else {
                $scope.subReq = angular.copy(subReqItem);
            }
        };

        $scope.closeDOJ = function (renewalItem) {
            renewalItem.ShowDOJ = false;
            $scope.dojValidation = {
                errorMessages: []
            };
        };

        $scope.closeSubsequent = function (renewalItem) {
            renewalItem.ShowSubsequentRequest = false;
            $scope.subsequentValidation = {
                errorMessages: []
            };
        };

        var validateForm = function (dojForm) {
            dojForm.$submitted = true;
            if (!dojForm.errorMessages)
                dojForm.errorMessages = [];
            $scope.dojValidation.errorMessages = angular.copy(dojForm.errorMessages);

            if (!!$scope.dojValidation.errorMessages && $scope.dojValidation.errorMessages.length > 0) {
                $scope.dojValidation.showError = true;
                $scope.dojValidation.isValid = false;
                return false;
            } else {
                $scope.dojValidation.showError = false;
                $scope.dojValidation.isValid = true;
                return true;
            }
        };

        var validateSubsequentForm = function (subForm) {
            subForm.$submitted = true;
            if (!subForm.errorMessages)
                subForm.errorMessages = [];
            $scope.subsequentValidation.errorMessages = angular.copy(subForm.errorMessages);

            if (!!$scope.subsequentValidation.errorMessages && $scope.subsequentValidation.errorMessages.length > 0) {
                $scope.subsequentValidation.showError = true;
                $scope.subsequentValidation.isValid = false;
                return false;
            } else {
                $scope.subsequentValidation.showError = false;
                $scope.subsequentValidation.isValid = true;
                return true;
            }
        };
        //$scope.init();
    }
})();