﻿(function() {
    'use strict'
    angular.module('app.core').directive('contactDirective', function() {
        return {
            restrict: 'EA',
            scope: {
                individualid: '=individualid',
                iseditable: '=iseditable'
            },
            controller: "ContactDirectiveController",
            templateUrl: "app/core/directive/ContactDirective/contact-directive-template.html",
            link: function($scope, element, attrs, ngCtrl) {

            }
        };
    });
})();