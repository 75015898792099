(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeProviderLicenseService', backofficeProviderLicenseService)

    backofficeProviderLicenseService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeProviderLicenseService($http, $q, WebApiUrl, AppConfig) {

        var _providerLicenseDetailBYProviderId = function (Key, ProviderId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Provider/GetProviderLicense/' + Key + '?ProviderId=' + ProviderId + '&Source=' + AppConfig.Source));
        };

        var _SaveProviderLicense = function (Key, data) {
            return returnDefferedResult($http.post(WebApiUrl + 'Provider/SaveProviderLicense/' + Key + '?Source=ALFSB', data));
        };
        //var _licenseSave = function(individualLicense, key) {
        //    return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualLicenseSave/' + key, individualLicense));
        //};
        var _getMaxLicenseByLicenseTypeId = function (Key, licenseTypeId) {
            return returnDefferedResult($http.get(WebApiUrl + 'IndividualLicense/MaxLicenseByLicenseTypeId/' + Key + '?LicenseTypeId=' + licenseTypeId + '&Source=' + AppConfig.Source + 'FIRM'));
        };

        var _licenseTypeGetAll = function (key) {
            return returnDefferedResult($http.get(WebApiUrl + 'TypeValues/RefProviderLicenseTypeGetAll/' + key));
        };
        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };
        var _getProviderById = function (key, ProviderId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + "Provider/GetProviderById/" + key + "?providerid=" + ProviderId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _getLicenseStatuses = function (key) {
            return returnDefferedResult($http.get(WebApiUrl + 'TypeValues/LicenseStatusTypeGetAll/' + key + '/'));
        };
        return {
            providerLicenseDetailBYProviderId: _providerLicenseDetailBYProviderId,
            SaveProviderLicense: _SaveProviderLicense,
            getMaxLicenseByLicenseTypeId: _getMaxLicenseByLicenseTypeId,
            licenseTypeGetAll: _licenseTypeGetAll,
            getProviderById: _getProviderById,
            getLicenseStatuses: _getLicenseStatuses
        }
    }
})();
