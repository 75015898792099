(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .directive('fingerprintConfirmation', [FingerprintConfirmation]);

    function FingerprintConfirmation() {
        return {
            restrict: 'E',
            scope: {
                confirmation: '=?',
                type: '=',
                entityId: '=',
                entityType: '=',
                individualLicense: '=?',
                applicationId: '=?',
                contentId: '=?',
                contentCode: '=?',
                pageTabSectionId: '=?',
                labelSize: '@?',
                controlSize: '@?',
                isRequired: '=?',
                showSubmitPanel: "=showSubmitPanel",
                tab: "=tab",
                openNextTab: "&",
                isBackoffice: "=isBackoffice",
                applicationInfo: "=?applicationInfo"
            },
            link: function ($scope, element, attrs) {

            },
            templateUrl: 'app/components/individual/application/directives/fingerprint-confirmation/fingerprint-confirmation.html',
            controller: 'FingerprintConfirmationController'
        };
    }
})();