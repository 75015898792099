(function () {
    angular.module('app.backofficeAdministor')
        .factory('templateMessageFactory', templateMessageFactory);
    templateMessageFactory.$inject = ['$http', '$q', 'WebApiUrl'];

    function templateMessageFactory($http, $q, WebApiUrl) {
        var _applicationTypeGetAll = function (key) {
            var data = $http.get(WebApiUrl + 'TypeValues/ApplicationTypeGetAll/' + key);
            return returnResult(data);
        };
        var _getAllTemplates = function (key) {
            var data = $http.get(WebApiUrl + 'Template/GetAllTemplates/' + key);
            return returnResult(data);
        };
        var _getAllTemplatesGetByAppTyId = function (key, obj) {
            var data = $http.post(WebApiUrl + 'Template/GetAllTemplatesGetByAppTyId/' + key, obj);
            return returnResult(data);
        };
        var _getAllTemplatesGetByTemplateName = function (key, obj) {
            var data = $http.post(WebApiUrl + 'Template/GetAllTemplatesGetByTemplateName/' + key, obj);
            return returnResult(data);
        };
        var _getAllTemplatesGetByAppTyIdTemplateName = function (key, obj) {
            var data = $http.post(WebApiUrl + 'Template/GetAllTemplatesGetByAppTyIdTemplateName/' + "key", obj);
            return returnResult(data);
        };
        var _createTemplate = function (key, obj) {
            var data = $http.post(WebApiUrl + 'Template/CreateTemplate/' + key, obj);
            return returnResult(data);
        };
        var _updateTemplate = function (key, obj) {
            var data = $http.post(WebApiUrl + 'Template/UpdateTemplate/' + key, obj);
            return returnResult(data);
        };
        var _deleteTemplateById = function (key, id) {
            var data = $http.post(WebApiUrl + 'Template/DeleteTemplateById/' + key, id);
            return returnResult(data);
        };
        var _auditVisitInfoSave = function (key, objAuditvisitInfo) {
            return $http.post(WebApiUrl + 'Logging/AuditvisitInfoSave/' + key, objAuditvisitInfo);
        };

        var _getAllTemplateAppliesToType = function () {
            var data = $http.get(WebApiUrl + 'Template/GetAllTemplateAppliesToType');
            return returnResult(data);
        };


        var returnResult = function (data) {
            var deferred = $q.defer();
            data
                .then(function (response) {
                    deferred.resolve(response.data);
                })
                .catch(function (response) {
                    deferred.reject(response);
                });
            return deferred.promise;
        };
        return {
            ApplicationTypeGetAll: _applicationTypeGetAll,
            GetAllTemplates: _getAllTemplates,
            GetAllTemplatesGetByAppTyId: _getAllTemplatesGetByAppTyId,
            GetAllTemplatesGetByTemplateName: _getAllTemplatesGetByTemplateName,
            GetAllTemplatesGetByAppTyIdTemplateName: _getAllTemplatesGetByAppTyIdTemplateName,
            CreateTemplate: _createTemplate,
            UpdateTemplate: _updateTemplate,
            DeleteTemplateById: _deleteTemplateById,
            AuditVisitInfoSave: _auditVisitInfoSave,
            GetAllTemplateAppliesToType: _getAllTemplateAppliesToType
        };
    }
})();