(function () {
    'use strict'

    angular.module('app.backofficeAdministor')
    .factory('contentManagementAddService', ['$http', '$q', 'WebApiUrl', contentManagementAddService]);

    function contentManagementAddService($http, $q, WebApiUrl) {
        var _getContent = function (key, ContentLkToPageTabSectionId) {
            var url = (WebApiUrl + 'Content/ContentItemLkGetAll/' + key);
            var data = $http.get(url);
            return returnResult(data);
        };

        var _createContent = function(key, Content){
            var url = (WebApiUrl + 'Content/CreateContentItemLk/' + key);
            var data = $http.post(url, Content);
            return returnResult(data);
        };

        var _getContentTypeName = function(key){
            var url = (WebApiUrl + 'Content/ContentGetContentTypeName/' + key);
            var data = $http.get(url);
            return returnResult(data);
        };

        var returnResult = function(httpPromise){
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                deferred.resolve(res.data);
            })
            .catch(function(res){
                deferred.reject(res);
            })
            return deferred.promise;
        }


        return {
            getContent: _getContent,
            createContent: _createContent,
            getContentTypeName: _getContentTypeName,
        };
    }
})();