(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('firmAffirmationProviderDirective', [function () {
            return {
                restrict: 'E',
                scope: {
                    providerId: "=providerId",
                    applicationId: "=applicationId",
                    userId: '=userId',
                    //providerLicense: "=providerLicense",
                    natValidation: "=natValidation"
                    //renewalInfo: "=?renewalInfo",
                    //showSubmitPanel: "=showSubmitPanel",
                    //showHeader: "=showHeader",
                    //headingPosition: "=headingPosition",
                    //individualId: '=individualId',
                    //applicationId: '=applicationId',
                    //userId: '=userId',
                    //isRenewal: '=?isRenewal',
                    //loadData: '=?loadData',
                    //individualLicense: "=?individualLicense",
                    //renewalInfoList: "=renewalInfoList",
                    //showStatusMessage: "&",
                    //tab: "=tab",
                    //openNextTab: "&"
                },
                templateUrl: "app/components/provider/directive/firm-affirmation/firm-affirmation.html",
                controller: "FirmAffirmationProviderController"
            }
        }]);
})();