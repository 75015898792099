(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationFeeDetailsController", InitialApplicationFeeDetailsController);

    InitialApplicationFeeDetailsController.$inject = ["$rootScope", "$scope",
        "initialApplicationFeeDetailsService", "initialApplicationstatusService", "utilityService", "WebApiUrl", "AppConfig", "$filter"
    ];

    function InitialApplicationFeeDetailsController($rootScope, $scope,
        initialApplicationFeeDetailsService, initialApplicationstatusService, utilityService, WebApiUrl, AppConfig, $filter) {

        $scope.init = function () {
            $scope.datePicker = {
                dateOpened: false,
                format: 'M!/d!/yyyy'
            };
            $scope.getFeeDetailsConfig();
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        $scope.getFeeDetailsConfig = function () {
            var relativeFilePath = "/individual/application/fee-details/fee-details.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function (data) {
                    $scope.feeDetailsConfig = data;
                    $scope.isConfigLoaded = true;
                }, function (error) {

                });
        };

        $scope.getTotalFee = function (feedetail, totalCourses) {
            var totalFee = feedetail.courseFee * totalCourses;
            feedetail.applicationFee = feedetail.courseFee;
            feedetail.totalFee = totalFee;
            return totalFee;
        };

        $scope.postMarkedDateChanged = function () {
            var dateToCompare; // = $scope.feeDetails.currentDate;
            if (!!$scope.feeDetails.postMarkDate && angular.isDate($scope.feeDetails.postMarkDate))
                dateToCompare = $scope.feeDetails.postMarkDate;
            else
                dateToCompare = null;
            if (!!dateToCompare && dateToCompare > $scope.feeDetails.lateFeeLastDate && ($scope.individualLicense.applicationType == 'active')) {
                $scope.feeDetails.isLate = true;
                $scope.feeDetails.lateFee = $scope.feeDetails.lateFeeAmount;
            } else {
                $scope.feeDetails.isLate = false;
                $scope.feeDetails.lateFee = 0;
            }
            $scope.feeDetails.totalFee = $scope.feeDetails.applicationFee + $scope.feeDetails.lateFee;
        };


        var validateForm = function (currentForm) {
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                console.log(currentForm.errorMessages);
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.feeDetailsSave = function (currentForm) {
            if (!validateForm(currentForm)) {
                initialApplicationstatusService.setFeeDetails("invalid");
                HideLoader();
                return;
            } else {
                initialApplicationstatusService.setFeeDetails("success");
            }
        };
        $scope.$on("SaveRenewal", function (event, args) {
            $scope.feeDetailsSave($scope.currentForm);
        });

        $scope.init();
    }
})();