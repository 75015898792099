(function () {
    'use strict'
    angular.module('app.core')
        .factory('userPortalViewService', UserPortalViewService);

    UserPortalViewService.$inject = ['$http', '$q', 'WebApiUrl'];

    function UserPortalViewService($http, $q, WebApiUrl) {

        var _setUserPortalViewData = function (loginVerification) {
            loginVerification.isUserPortalView = true;
            localStorage.setItem('loginVerification', JSON.stringify(loginVerification));
        };

        var _getUserPortalViewData = function (loginVerification) {
            return JSON.parse(localStorage.getItem('loginVerification'));
        };

        var _resetUserPortalViewData = function () {
            localStorage.removeItem('loginVerification');
        };
        var _userByIndividualIdExternal = function (urlParam) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Users/UserByIndividualIdExternal' + urlParam)
                .then(function (res) {
                    res = res.data;
                    deferred.resolve(res);
                }, function (res) {
                    res = res.data;
                    deferred.reject(res);
                });
            return deferred.promise;
        };

        var _userByProviderIdExternal = function (urlParam) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Users/UserByProviderIdExternal' + urlParam)
                .then(function (res) {
                    res = res.data;
                    deferred.resolve(res);
                }, function (res) {
                    res = res.data;
                    deferred.reject(res);
                });
            return deferred.promise;
        };
        var _userByIndividualId = function (urlParam) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Users/UserByIndividualId' + urlParam)
                .then(function (res) {
                    res = res.data;
                    deferred.resolve(res);
                }, function (res) {
                    res = res.data;
                    deferred.reject(res);
                });
            return deferred.promise;
        };

        var _individualBYIndividualId = function (Key, IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualBYIndividualId/' + Key + '?IndividualId=' + IndividualId)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        var _getProvider = function (key, ProviderId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + "Provider/GetProviderById/" + key + "?providerid=" + ProviderId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _applicationBYIndividualId = function (urlParm) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/ApplicationBYIndividualId' + urlParm).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        }

        var _getUserPortalState = function () {
            return 'app.Dashboard';
        };
        var _getFirmUserPortalState = function () {
            return 'app.providerdashboard';
        };
        
        return {
            setUserPortalViewData: _setUserPortalViewData,
            getUserPortalViewData: _getUserPortalViewData,
            resetUserPortalViewData: _resetUserPortalViewData,
            userByIndividualId: _userByIndividualId,
            individualBYIndividualId: _individualBYIndividualId,
            getProvider: _getProvider,
            applicationBYIndividualId: _applicationBYIndividualId,
            getUserPortalState: _getUserPortalState,
            userByIndividualIdExternal:_userByIndividualIdExternal,
            userByProviderIdExternal:_userByProviderIdExternal,
            getFirmUserPortalState: _getFirmUserPortalState

        }
    }
})();