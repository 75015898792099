(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('affidavitChecklistLogic', [AffidavitChecklistLogic]);

    function AffidavitChecklistLogic() {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                
            }
        };
    }
})();