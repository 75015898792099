(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('initialLicenseApplicationService', initialLicenseApplicationService)

    initialLicenseApplicationService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function initialLicenseApplicationService($http, $q, WebApiUrl, AppConfig) {

        var _saveTabStatus = function (tabData, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/SaveTabStatus/' + key, tabData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getAllTabStatus = function (applicationId, providerId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Provider/GetAllTabStatus/' + key + '?ApplicationId=' + applicationId + '&ProviderId=' + providerId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _getApplicationInfoByApplicationId = function (applicationId, userId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Common/ApplicationInfoByApplicationId/' + key + '?ApplicationId=' + applicationId + '&UserId=' + userId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _IndividualLicenseBYIndividualId = function (IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualLicenseDetailBYIndividualId/' + sessionStorage.Key + '?IndividualId=' + IndividualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _sendIndividualDocumentSaveToPDFandMailLA = function (tabData, key) {
            var deferred = $q.defer();
            var BoardInfoId = (Number(sessionStorage.getItem('search.BoardInfoId')));
            var urlParams = {
                Source: AppConfig.Source
            };
            $http.post(WebApiUrl + 'IndividualLicense/IndividualRenewalDocumentSaveToPDFandMailALFSB/' + key + '/', tabData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getRefTablesGetAll = function (key, tableKey) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'TypeValues/RefTablesGetAll/' + key + '?tableKey=' + tableKey)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        return {
            saveTabStatus: _saveTabStatus,
            getAllTabStatus: _getAllTabStatus,
            getApplicationInfoByApplicationId: _getApplicationInfoByApplicationId,
            individualLicenseBYIndividualId: _IndividualLicenseBYIndividualId,
            sendIndividualDocumentSaveToPDFandMailLA: _sendIndividualDocumentSaveToPDFandMailLA,
            getRefTablesGetAll: _getRefTablesGetAll
        };
    }
})();