﻿(function () {
    'use strict'
    angular.module('app.provider')
        .directive('providerApplicationFeeDirective', [function () {
            return {
                restrict: 'EA',
                scope: {
                    providerId: '=providerId',
                    displaySendEmail: '=?',
                    emailid: '=?',
                    showStatusMessage: '&',
                    isdashboard: '=isdashboard',
                    applicant: '=applicant',
                    isprovider: '=isprovider'
                },
                controller: "providerApplicationFeeCont",
                templateUrl: "app/components/provider/directive/provider-application-fee/provider-application-fee.html",
                link: function ($scope, element, attrs, ngCtrl) {

                }
            };
        }]);
})();