(function() {
    'use strict'

    angular.module('task.management')
    .factory('TaskExecutionLogService', ['$http', '$q', 'WebApiUrl', 'utilityService', TaskExecutionLogService]);

    function TaskExecutionLogService($http, $q, WebApiUrl, utilityService) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(!!success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(!!fail) {
                    fail(error);
                }
                console.log("Request failed with status: " + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getTaskList = function (key) {
            return responseHandler($http.get(WebApiUrl + 'TypeValues/RefTablesGetAll/' + key + '/', { params: { tableKey: 'reftasktype' } }));
        };

        var _getTaskStatusList = function (key) {
            return responseHandler($http.get(WebApiUrl + 'TypeValues/RefTablesGetAll/' + key + '/', { params: { tableKey: 'reftaskstatus' } }));
        };

        var _getTaskExecutionStatusList = function (key) {
            return responseHandler($http.get(WebApiUrl + 'TypeValues/RefTablesGetAll/' + key + '/', { params: { tableKey: 'reftaskexecutionstatus' } }));
        };

        var _getTaskScheduleList = function (key, taskScheduleAutoId, searchCriteria) {
            var urlParams = {
                UserId: 0,
                TaskScheduleAutoId: taskScheduleAutoId
            };

            return responseHandler($http.post(WebApiUrl + 'TaskManager/GetTaskScheduleList/' + key + '/', searchCriteria, { params: urlParams }));
        };

        var _getTaskExecutionLogList = function (key, searchCriteria) {
            return responseHandler($http.post(WebApiUrl + 'TaskManager/GetTaskscheduleexecutionlogList/' + key + '/', searchCriteria));
        };

        var _getAllApplicationStatus = function (key) {
            return responseHandler($http.get(WebApiUrl + 'TypeValues/ApplicationStatusGetAll/' + key + '/'));
        };

        var _executeTask = function (key, taskDetails) {
            return responseHandler($http.post(WebApiUrl + 'TaskManager/ExecuteTask/' + key + '/', taskDetails));
        };

        return {
            getTaskList: _getTaskList,
            getTaskStatusList: _getTaskStatusList,
            getTaskExecutionStatusList: _getTaskExecutionStatusList,
            getTaskScheduleList: _getTaskScheduleList,
            getTaskExecutionLogList: _getTaskExecutionLogList,
            getAllApplicationStatus: _getAllApplicationStatus,
            executeTask: _executeTask
        };
    }
})();