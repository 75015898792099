(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeProviderFinanceController", BackofficeProviderFinanceController);

    BackofficeProviderFinanceController.$inject = ["$rootScope", "$scope", "$q", "RegisterFactory",
        "backofficeProviderFinanceService", "utilityService", "lookupService", "typeValuesService", "LookUpType", "TypeValue", "initialProviderApplicationFeePaymentService", "initialLicenseApplicationService", "FirmLicenseRenewalHASnAService", "Messages"
    ];

    function BackofficeProviderFinanceController($rootScope, $scope, $q, RegisterFactory,
        backofficeProviderFinanceService, utilityService, lookupService, typeValuesService, LookUpType, TypeValue, initialProviderApplicationFeePaymentService, initialLicenseApplicationService, FirmLicenseRenewalHASnAService, Messages) {
        $scope.userId = sessionStorage.UserID;
        $scope.viewConfig = {
            showAddBlock: false,
            proceedToPayment: false
        };
        if (!$scope.transactionType)
            $scope.transactionType = 'MISC';
        $scope.paymentTab = {
            isTabDeficiencyResolved: true
        };
        $scope.feeDetails = {};
        $scope.validator = {
            feePayment: {}
        };
        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/d!/yyyy',
            startDateOptions: {
                minDate: null,
            },
            endDateOptions: {}
        };

        // $scope.isNameEdit = false;
        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, providerId) {
            if (($rootScope.individualFirmCurrentTab && $rootScope.individualFirmCurrentTab.name == 'finance')) {
                $scope.providerId = providerId;
                // if (!$scope.isNameLoadedOnce) {

                $scope.loadFinanceData();
                $scope.isFinanceLoadedOnce = true;
                //  } else {
                //$scope.individualFinanceByIndividualId($scope.individualId, true);
                //}
                AuditVisitInfoSave();
                $rootScope.individualFirmCurrentTab.isFinanceLoadedOnce = true;
            }
        });

        $scope.loadFinanceData = function () {
            var dataPromises = [];

            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=feedetails")));
            dataPromises.push(backofficeProviderFinanceService.individualPaidInvoiceByIndividualId(sessionStorage.Key, $scope.providerId));
            // dataPromises.push(backofficeProviderFinanceService.getApplicationInfoByIndividualId(sessionStorage.Key, $scope.individualId));

            $q.all(dataPromises)
                .then(function (response) {

                    angular.forEach(response, function (responseItem, itemIndex) {
                        if ($scope.checkServerResponse(responseItem)) {
                            if (itemIndex == 0) {
                                $scope.feeDetailTypes = responseItem.ReferenceEntities;
                            } else if (itemIndex == 1) {
                                $scope.invoiceList = responseItem.RevFeeDisbResponseList;
                            }
                            // else if (itemIndex == 2) {
                            //     //ApplicationData ApplicationId
                            //     $scope.applicationInfoList = responseItem.ApplicationInfoList;
                            // }
                        }
                    });
                    $scope.isFinanceLoadedOnce = true;
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    if (data != null) {
                        $scope.showStatusMessage(data.message, "error");
                    }
                });
        };

        $scope.openAddEditFinance = function (isEdit, individualFinance, editIndex) {
            ShowLoader();
            $scope.isFinanceEdit = isEdit;
            $scope.editIndex = editIndex;
            $scope.viewConfig.showAddBlock = true;
            if (!$scope.isFinanceEdit) {
                $scope.individualFinance = {};
            } else {
                $scope.individualFinance = angular.copy(individualFinance);
            }
            HideLoader();
        };

        $scope.cancelIndividualFinance = function (financeForm) {
            // $scope.viewConfig.showAddBlock = false;
            $scope.individualFinance = {};
            financeForm.$setPristine();
            financeForm.$submitted = false;
            $scope.isFinanceEdit = false;
            $scope.editIndex = false;
            $scope.hideValidationSummary();
        };

        $scope.feeDetailTypeChanged = function (individualFinance) {
            if (!!individualFinance.feeDetailType) {
                individualFinance.Amount = individualFinance.feeDetailType.FeeAmount;
            }
        };

        // Save IndividualFinance
        $scope.saveIndividualFinance = function (financeForm) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                financeForm.$submitted = true;
                if ($scope.validateData(financeForm)) {
                    ShowLoader("Please wait...");
                    if (!$scope.feeDetailRows)
                        $scope.feeDetailRows = [];

                    if (!$scope.isFinanceEdit)
                        $scope.feeDetailRows.push(angular.copy($scope.individualFinance));
                    else
                        $scope.feeDetailRows[$scope.editIndex] = angular.copy($scope.individualFinance);

                    $scope.individualFinance = {};
                    financeForm.$setPristine();
                    financeForm.$submitted = false;
                    $scope.isFinanceEdit = false;
                    $scope.editIndex = false;
                    HideLoader();
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) {
                    $scope.showStatusMessage(ex.message, "error");
                }
            }
        };

        $scope.getTotalFee = function () {
            var totalFee = 0;
            angular.forEach($scope.feeDetailRows, function (feeDetailRow) {
                totalFee = totalFee + (1 * feeDetailRow.Amount);
            });
            $scope.feeDetails.totalFee = totalFee;
            $scope.feeDetails.applicationFee = totalFee;
            return totalFee;
        };

        $scope.proceedToPayment = function (value) {

            $scope.hideStatusMessage();
            if (!$scope.feeDetailRows || $scope.feeDetailRows.length == 0) {
                utilityService.scrollTop();
                $scope.showStatusMessage(Messages.General.Errors.FeeType, "error");
                return;
            }

            // if ($scope.feeDetails.totalFee < 1) {
            //     utilityService.scrollTop();
            //     $scope.showStatusMessage(Messages.efaacda, "error");
            //     return;
            // }
            ShowLoader();

            //if (!$scope.ApplicationData) {
            var urlparm = '/' + sessionStorage.Key + '?ApplicationId=' + 0 + '&UserId=' + $scope.userId;
            var reqData = {
                ApplicationTypeId: 13,
                ApplicationStatusId: 14,
                ApplicationSubmitMode: 'B',
                GoPaperless: true,
                IsActive: true
            };

            RegisterFactory.GenerateApplicationId(urlparm, reqData).then(function (response) {
                if (response.data.Status) {
                    var Urlparm = sessionStorage.Key;
                    var reqData = {
                        ApplicationId: response.data.ApplicationId,
                        ProviderId: $scope.providerId,
                        CreatedBy: $scope.userId,
                        CreatedOn: new Date()
                    };
                    FirmLicenseRenewalHASnAService.ProviderApplicationSave(Urlparm, reqData).then(function (res) {
                        if (!res.data.Status) {
                            HideLoader();
                            return;
                        }
                        getApplication(response.data.ApplicationId, value);
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(Messages.bbffceb, 'error');

                    });
                }
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage(error, 'error');
            });
        };

        $scope.deleteIndividualFinance = function (individualFinance, index) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                ShowLoader("Please Wait...");
                $scope.feeDetailRows.splice(index, 1);
                HideLoader();
            }
        };

        var getApplication = function (ApplicationId, value, genrateInvoice) {
            initialLicenseApplicationService.getApplicationInfoByApplicationId(ApplicationId, $scope.userId, sessionStorage.Key)
                .then(function (response) {
                    if (response.Status) {
                        $scope.applicationInfo = response.ApplicationResponseList[0];

                        $scope.getPaymentCommonData(value, genrateInvoice);

                    }
                }, function (error) {
                    HideLoader();
                    $scope.showStatusMessage(error, 'error');
                });
        }

        $scope.paymentStatusUpdate = function (action) {
            if (action == 'success') {
                HideLoader();
            } else if (action == 'close') {
                //  if (confirm(Messages.eefcfca)) {
                $scope.feeDetailRows = [];
                $scope.individualFinance = {};
                // $scope.ApplicationData = null;
                $scope.viewConfig.proceedToPayment = false;
                //}
            }
        };

        $scope.getPaymentCommonData = function (value, genrateInvoice) {

            initialProviderApplicationFeePaymentService.getInvoiceMasterByIndividualIdAndInvoiceStatus(sessionStorage.Key, $scope.providerId, $scope.applicationInfo.ApplicationId)
                .then(function (objMasterDetails) {
                    if (objMasterDetails.StatusCode != "00") {
                        return showErrorMessage(objMasterDetails.Message);
                    }
                    if (!objMasterDetails.InvoiceMasters || genrateInvoice) {
                        var reqObj = {
                            InvoiceID: 0,
                            ProviderId: $scope.providerId,
                            ApplicationId: $scope.applicationInfo.ApplicationId,
                            LicenseTypeId: $scope.individualLicense ? $scope.individualLicense.LicenseTypeId : 0,
                            AmountBilled: $scope.feeDetails.totalFee,
                            AmountPaid: 0,
                            AmountAdjusted: 0,
                            Balance: $scope.feeDetails.totalFee,
                            TransType: $scope.transactionType,
                            Module: "ADVDUES",
                            InvoiceStatus: "OPEN",
                            GPSelected: false,
                            GPAdjust: 0,
                            GPPayment: 0,
                            GPVoid: false,
                            Description: "Miscellaneous Payment",
                            LFSelected: false,
                            CreatedBy: $scope.userId,
                            ReferenceNumber: 0,
                            ShipToContact: '',
                            // PostMarkDate: $scope.payment.feeDetails.postMarkDate
                        };
                        initialProviderApplicationFeePaymentService.invoiceMasterSave(sessionStorage.Key, [reqObj])
                            .then(function (objMasterInvoice) {

                                if (objMasterInvoice.StatusCode != "00") {
                                    HideLoader();
                                    $scope.showStatusMessage(objMasterInvoice.Message, 'error');
                                }
                                else {
                                    $scope.InvoiceId = objMasterInvoice.InvoiceMasters[0].InvoiceID;
                                    $scope.feeDetails.InvoiceId = $scope.InvoiceId;
                                    // $scope.showStatusMessage(Messages.dadeace, 'success');
                                    // $scope.feeDetailRows = [];
                                    // $scope.individualFinance = {};
                                    // $scope.viewConfig.proceedToPayment = false;
                                    var invoiceDetails = [];
                                    angular.forEach($scope.feeDetailRows, function (feeDetailRow, index) {
                                        feeDetailRow.referenceNumber = ('Misc-' + feeDetailRow.feeDetailType.FeeDetailId);
                                        var miscFeeInvoiceDetail = getMiscFeeInvoiceDetail(feeDetailRow);
                                        invoiceDetails.push(miscFeeInvoiceDetail);
                                    });

                                    initialProviderApplicationFeePaymentService.invoiceDetailSave(sessionStorage.Key, invoiceDetails)
                                        .then(function (objInvoiceDetails) {

                                            HideLoader();
                                            if (value) {
                                                $scope.viewConfig.proceedToPayment = true;
                                            } else {
                                                $scope.feeDetailRows = [];
                                                $scope.individualFinance = {};
                                                //$scope.ApplicationData = null;
                                                $scope.viewConfig.proceedToPayment = false;
                                                utilityService.scrollTop()
                                                $scope.showStatusMessage(Messages.General.Success.InvoiceGenerated, 'success');
                                            }
                                        }, function (error) {
                                            HideLoader();
                                        });
                                }

                            }, function (response) {
                                HideLoader();
                                $scope.showStatusMessage(Messages.faaaddc, 'error');
                            });
                    }
                    else {
                        HideLoader();
                    }
                }, function (response) {
                    $scope.showStatusMessage(Messages.faaaddc, 'error');
                });

        }

        var getMiscFeeInvoiceDetail = function (feeDetailRow) {
            var licenseEndorsementFeeInvoiceDetail = {
                InvoiceDetailID: 0,
                InvoiceID: $scope.InvoiceId,
                ProviderId: $scope.providerId,
                ApplicationId: $scope.applicationInfo.ApplicationId,
                TransType: 'ORD',
                Description: feeDetailRow.feeDetailType.FeeDetailDesc,
                Quantity: 1,
                Amount: feeDetailRow.Amount,
                AmtBilled: feeDetailRow.Amount,
                AmtPaid: feeDetailRow.Amount,
                AmtAdjusted: 0,
                AmtBalance: 0,
                TransCode: feeDetailRow.feeDetailType.FeeTypeCode,//'MISCEFEE',
                Module: 'MiscPay',
                DetailCode: feeDetailRow.feeDetailType.FeeDetailCode,
                ReferenceNumber: feeDetailRow.referenceNumber,
                GPAdjust: 0,
                GPPayment: 0,
                CreatedBy: $scope.userId
            };
            return licenseEndorsementFeeInvoiceDetail;
        };

        function AuditVisitInfoSave() {
            var data = {
                "PageName": "IndividualInfo-FinanceTab",
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.providerId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        }
    }
})();
