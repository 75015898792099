(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .factory('licenseApplicationListingService', licenseApplicationListingService);
    licenseApplicationListingService.$inject = ["$http", "$q", "WebApiUrl", "AppConfig"];

    function licenseApplicationListingService($http, $q, WebApiUrl, AppConfig) {

        var licenseApplicationListingService = {
            generateApplicationId: generateApplicationId,
            saveIndividual: saveIndividual,
            saveIndividualApplication: saveIndividualApplication,
            saveIndividualLicense: saveIndividualLicense,
            getIndividualLicenseBYIndividualId: getIndividualLicenseBYIndividualId,
            saveIndividualNameTypeLk: saveIndividualNameTypeLk,
            individualContactSave: individualContactSave,
            applicationStatusUpdate: applicationStatusUpdate,
            applicationStatusLkSave: applicationStatusLkSave,
            applicationStatusChangeLogSave: applicationStatusChangeLogSave,
            getCertificationApplicationList: getCertificationApplicationList,
            savePermitApplication: _savePermitApplication,
            providerContactSave: _providerContactSave,
            saveProviderApplication: _saveProviderApplication,
            saveProviderNameTypeLk: _saveProviderNameTypeLk,
            individualProviderVerifySearchWithPage: _individualProviderVerifySearchWithPage,
            saveSpecialityCertificationApplication: _saveSpecialityCertificationApplication,
            getApplicationBYIndividualId: _getApplicationBYIndividualId,
            getRefTablesGetAll: _getRefTablesGetAll,
            getIndividualSearchWithPage: _getIndividualSearchWithPage,
            getIndividualByIndividualId: _getIndividualBYIndividualId
        };

        function _getIndividualBYIndividualId(Key, IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualBYIndividualId/' + Key + '?IndividualId=' + IndividualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        function _individualProviderVerifySearchWithPage(key, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/IndividualProviderVerifySearchWithPage/' + key + '?PageNumber=1&NoOfRecords=10&ShowAllRecords=true',
                reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function _saveProviderNameTypeLk(key, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'ProviderNameTypeLk/ProviderNameTypeLkSave/' + key,
                reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function _saveProviderApplication(key, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/ProviderApplicationSave/' + key,
                reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function _providerContactSave(key, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/ProviderContactsSave/' + key + '?Source=' + AppConfig.Source,
                reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function _getIndividualSearchWithPage(key, PageNumber, NoOfRecords, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualSearchWithPage/' + key + '?PageNumber=' + PageNumber + '&NoOfRecords=' + NoOfRecords, reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function _getRefTablesGetAll (key, tableKey) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'TypeValues/RefTablesGetAll/' + key + '?tableKey=' + tableKey)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        function generateApplicationId(key, applicationID, userID, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Application/GenerateApplicationId/' + key + '?ApplicationId=' + applicationID + '&UserId=' + userID,
                reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        function saveIndividual(key, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualSave/' + key + '?Source=' + AppConfig.Source,
                reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function saveIndividualApplication(key, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/SaveIndividualApplication/' + key,
                reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function saveIndividualLicense(key, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualLicenseSave/' + key,
                reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function getIndividualLicenseBYIndividualId(key, individualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualLicenseBYIndividualId/' + key + '?IndividualId=' + individualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function saveIndividualNameTypeLk(key, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'IndividualNameTypeLk/IndividualNameTypeLkSave/' + key,
                reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function individualContactSave(key, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualContactSave/' + key + '?Source=' + AppConfig.Source,
                reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function applicationStatusUpdate(key, applicationId, applicationStatusId, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Common/ApplicationStatusUpdate/' + key + '?ApplicationId=' + applicationId + '&ApplicationStatusId=' + applicationStatusId + '&Source=' + AppConfig.Source, reqData).then(function(data){ data = data.data;
                deferred.resolve(data);
            }, function(error) { error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };

        function applicationStatusLkSave(key, userID, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Common/ApplicationStatusLkSave/' + key + '?UserId=' + userID,
                reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function applicationStatusChangeLogSave(key, userID, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Common/ApplicationStatusChangeLogSave/' + key + '?UserId=' + userID,
                reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function getCertificationApplicationList(key, userID, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/GetCertificationApplicationList/' + key + '?UserId=' + userID, reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        function _savePermitApplication(key, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/SavePermitApplication/' + key,
                reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        function _saveSpecialityCertificationApplication(key, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualLicenseSpecialitySave/' + key,
                reqData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        }        

        function _getApplicationBYIndividualId(key, individualId, IsGetAll) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/ApplicationBYIndividualId/' + key + '?IndividualId=' + individualId + '&IsGetAll=' + IsGetAll)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return licenseApplicationListingService;
    }
})();