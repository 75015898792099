(function() {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('providerInformationService', providerInformationService)

        providerInformationService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function providerInformationService($http, $q, WebApiUrl, AppConfig) {

        var responseHandler = function(httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
                .then(function(res) {
                    if (success) {
                        var res = success(res);
                    }
                    deferred.resolve(res.data);
                }, function(error) {
                    if (fail) {
                        fail(error);
                    }
                    console.log("Request failed with status: " + error.status);
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getStateByCountryID = function(countryID, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'State/StateGetByCountryID/' + key + '?CountryID=' + countryID)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getApplication = function(individualId, applicationId, key, IsGetAll) {
            if (!IsGetAll) {
                IsGetAll = false;
            }
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/ApplicationBYIndividualId/' + key + '?IndividualId=' + individualId + "&ApplicationId=" + applicationId + '&IsGetAll=' + IsGetAll)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };


        var _updateApplicationFormValidationbyApplicationId = function(ApplicationId, FormValidation, key, ExamTaken) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + '/Application/UpdateApplicationFormValidationbyApplicationId/' + key + '?ApplicationId=' + ApplicationId + '&FormValidation=' + FormValidation + '&ExamTaken=' + ExamTaken, null)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveIndividual = function(individual, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + '/Individual/IndividualSave/' + key + '?Source=' + AppConfig.Source, individual)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveAddress = function(address, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualAddressSave/' + key + '?Source=' + AppConfig.Source, address)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveContact = function(contact, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualContactSave/' + key + '?Source=' + AppConfig.Source, contact)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getEmployerList = function(key, searchString, providerTypeId, searchType) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + "Individual/GetEmployerNameAndAddress/" + key + "?SearchString=" + searchString + "&ProviderTypeId=" + providerTypeId + "&SearchType=" + searchType)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _getIndividualCitizenship = function(individualId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/GetIndividualCitizenship/' + key + '?IndividualId=' + individualId + '&Source=' + AppConfig.Source)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _saveIndividualCitizenship = function(individualCitiZenShip, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/SaveIndividualCitizenship/' + key + '?Source=' + AppConfig.Source, individualCitiZenShip)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getIndividualLicenseDiscipline = function(individualId, applicationId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/GetIndividualLicenseDiscipline/' + key + '?IndividualId=' + individualId + '&ApplicationId=' + applicationId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getRefLicenseDisciplineTypeGetAll = function(individualId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'TypeValues/RefLicenseDisciplineTypeGet/' + key + '?IndividualId=' + individualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };


        var _saveIndividualLicenseDiscipline = function(individualLicenseDiscipline, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/SaveIndividualLicenseDiscipline/' + key + '?Source=' + AppConfig.Source, individualLicenseDiscipline)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _saveEmploymentConfirmation = function(key, confirmations) {
            return responseHandler($http.post(WebApiUrl + 'Individual/SaveIndividualEmploymentConfirmation/' + key + '/', confirmations));
        };

        var _getLookupInfo = function(key, lookupTypeId) {
            var urlParams = {
                LookupTypeId: lookupTypeId
            };
            return responseHandler($http.get(WebApiUrl + 'Lookup/LookupGetBYLookupTypeID/' + key + '/', { params: urlParams }));
        };
        var _getEmploymentConfirmation = function(key, individualId) {
            var urlParams = {
                IndividualId: individualId
            };

            return responseHandler($http.get(WebApiUrl + 'Individual/GetIndividualEmploymentConfirmation/' + key + '/', { params: urlParams }));
        };
        var _getLicenses = function(individualId, key) {
            var urlParams = {
                IndividualId: individualId
            };
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualLicenseDetailBYIndividualId/' + key + '/', { params: urlParams })
                .then(function(response) {
                    deferred.resolve(response.data);
                }, function(response) {
                    deferred.reject(response);
                });
            return deferred.promise;
        };

        var _individualNameBYIndividualId = function(key, individualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualNameBYIndividualId/' + key + '?individualId=' + individualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _getIndividualCommunicationPreference = function(key, individualId, applicationId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/GetIndividualCommunicationPreference/' + key + '?individualId=' + individualId + '&applicationId=' + applicationId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _saveIndividualCommunicationPreference = function(communicationData, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/SaveIndividualCommunicationPreference/' + key + '?Source=' + AppConfig.Source, communicationData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _validateIndividualInfo = function (individualSearchContent, key, PageNumber, NoOfRecords) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/ValidateIndividualInfo/' + key + '?PageNumber=' + PageNumber + '&NoOfRecords=' + NoOfRecords,
                individualSearchContent)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    window.location.href = "#/Backoffice/Loginstaff";
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            getStateByCountryID: _getStateByCountryID,
            saveIndividual: _saveIndividual,
            saveAddress: _saveAddress,
            saveContact: _saveContact,
            getEmployerList: _getEmployerList,
            getIndividualCitizenship: _getIndividualCitizenship,
            saveIndividualCitizenship: _saveIndividualCitizenship,
            getIndividualLicenseDiscipline: _getIndividualLicenseDiscipline,
            saveIndividualLicenseDiscipline: _saveIndividualLicenseDiscipline,
            getRefLicenseDisciplineTypeGetAll: _getRefLicenseDisciplineTypeGetAll,
            getApplication: _getApplication,
            updateApplicationFormValidationbyApplicationId: _updateApplicationFormValidationbyApplicationId,
            getLicenses: _getLicenses,
            getEmploymentConfirmation: _getEmploymentConfirmation,
            saveEmploymentConfirmation: _saveEmploymentConfirmation,
            getLookupInfo: _getLookupInfo,
            getIndividualCommunicationPreference: _getIndividualCommunicationPreference,
            saveIndividualCommunicationPreference: _saveIndividualCommunicationPreference,
            individualNameBYIndividualId: _individualNameBYIndividualId,
            validateIndividualInfo: _validateIndividualInfo
        };
    }
})();