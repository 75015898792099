(function () {
    'use strict'

    angular.module('app.backofficeEntity')
        .directive('ceCourse', ceCourseDirective);

    ceCourseDirective.$inject = [];

    function ceCourseDirective() {
        return {
            restrict: 'E',
            scope: {
                entityId: '=',
                tab: "=?",
                entityType: "=",
                boardInfoId: "=?",
                configName: "=",
                licenseNumber: "=?",
                applicationId: '=',
                applicationTypeId: '=?',
                individualLicense: '=?',
                applicationInfo: '=?',
                showHeaderPanel: '=?',
                showSubmitPanel: '=?',
                isBackoffice: '=?',
                natValidation: '=',
                isTabStructure: '=?',
                openNextTab: '&'
            },
            templateUrl: 'app/components/backoffice/entity/ce-course/ce-course.html',
            controller: 'CeCourseController'
        };
    }
})();