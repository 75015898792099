﻿(function() {
    'use strict'
    angular.module('app.core')
        .directive('individualPersonalInfo', [function() {
            return {
                restrict: 'EA',
                scope: {
                    individualId: '=individualId',
                    iseditable: '=iseditable'
                },
                controller: "IndividualPersonalInfoController",
                templateUrl: "app/core/directive/individual-personal-info/individual-personal-info.html"
            };
        }]);
})();