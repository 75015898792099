﻿(function () {
    'use strict';

    angular.module('task.management')
        .controller('TaskManagementJobDetailsController', ['$scope', '$rootScope', 'TaskManagementJobDetailsService', TaskExecutionLogDetailsController]);

    function TaskExecutionLogDetailsController($scope, $rootScope, TaskManagementJobDetailsService) {

        $scope.refreshWindowsServiceStatus = function () {
            $scope.loadingServiceDetail(true, "Please wait...");
            $scope.getWindowsServiceStatus();
        };

        $scope.getWindowsServiceStatus = function () {
            TaskManagementJobDetailsService.getWindowsServiceStatus()
                .then(function (data) {
                    if (data.Status) {
                        $scope.serviceData = data.EntityItem;
                        $rootScope.serviceData = data.EntityItem;
                        if (!$scope.serviceData.ServiceExists) {
                            $scope.showServicedetailMsg("-", data.Message);
                        }
                    } else {
                        $scope.serviceData = null;
                        $rootScope.serviceData = null;
                        $scope.showServicedetailMsg("-", data.Message);
                    }
                    $scope.loadingServiceDetail(false);
                }, function (error) {
                    $scope.serviceData = null;
                    $rootScope.serviceData = null;
                    $scope.showServicedetailMsg("-", error.Message);
                    $scope.loadingServiceDetail(false);
                });
        };

        $scope.init = function () {
            $scope.getWindowsServiceStatus();
        };

        $scope.changeServiceStatus = function (action) {
            $scope.loadingServiceDetail(true, "Please wait...");
            TaskManagementJobDetailsService.updateWindowsServiceStatus(action)
                .then(function (data) {
                    if (data.Status) {
                        $scope.showServicedetailMsg("+", data.Message);
                    } else {
                        $scope.showServicedetailMsg("-", data.Message);
                    }
                    $scope.refreshWindowsServiceStatus();
                }, function (error) {
                    $scope.showServicedetailMsg("-", error.Message);
                    $scope.loadingServiceDetail(false);
                });
        };

        $scope.startService = function () {
            if (confirm("Are you sure you want to start the service?")) {
                $scope.changeServiceStatus("start");
            }
        };

        $scope.stopService = function () {
            if (confirm("Are you sure you want to stop the service?")) {
                $scope.changeServiceStatus("stop");
            }
        };

        $scope.init();
    }
})();