(function () {
    'use strict'

    angular.module('app.core').factory('formValidationService', formValidationService)

    formValidationService.$inject = ['$http', '$q', 'WebApiUrl']

    function formValidationService($http, $q, WebApiUrl) {

        var _validateTextbox = function (value) {
            return (!!value);
        }

        var _validateOnlyAlphabet = function (value) {
            if (!!value) {
                var emailReg = /^[a-zA-Z ]*$/;
                return emailReg.test(value);
            }
            else
                return false;
        }

        var _validateDropdown = function (defaultValue, value) {
            return (!!value && value !== defaultValue);
        }

        var _validateCheckbox = function (value) {
            return (!!value);
        }

        var _validateCheckboxIsChecked = function (checkedProp) {
            return (!!checkedProp);
        }

        var _validateRadio = function (value) {
            return (!!value);
        }

        var _validateZipCode = function (value, isRequired) {
            if (isRequired == null || isRequired == undefined)
                isRequired = true;
            if ((!!value) || !isRequired) {
                var emailReg = /^\d{5}(?:[-\s]\d{4})?$/;
                return emailReg.test(value);
            }
            else {
                return false;
            }
        }

        var _validateZipCodeWithoutRequiredVal = function (value) {
            if (!!value) {
                var emailReg = /^\d{5}(?:[-\s]\d{4})?$/;
                return emailReg.test(value);
            }
            else {
                return true;
            }
        }

        var _validateWebsiteWithoutRequired = function (value) {
            if (!!value) {
                var websiteReg = /^(http:\/\/www.|https:\/\/www.|ftp:\/\/www.|www.){1}([0-9A-Za-z]+\.)/;
                return websiteReg.test(value);
            }
            else {
                return true;
            }
        }

        var _validatePhone = function (value, isRequired) {
            if (isRequired == null || isRequired == undefined)
                isRequired = true;
            if (!!value) {
                var emailReg = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/;
                return emailReg.test(value);
            }
            else if (!isRequired) {
                return true;
            }
            else {
                return false;
            }
        }

        var _validatePhoneWithoutRequired = function (value) {
            if (!!value) {
                var emailReg = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/;
                return emailReg.test(value);
            }
            else {
                return true;
            }
        }

        var _validateSSN = function (message, element, value) {
            var emailReg = /^(\w{3}-?\w{2}-?\w{4}|NNN-NN-NNNN)$/;
            return (!!value && emailReg.test(value));
        }

        var _validateCompare = function (value, ValueToCompare) {
            return (!!value && value == ValueToCompare);
        }

        var _validatePositiveNumber = function (value) {
            return (!!value && (/^\-?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) && !(parseFloat(value) < 0));
        }

        var _validateNumber = function (message, element, value) {
            return (!!value && (/^\-?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)));
        }

        var _validateDecimal = function (value) {
            return (!!value && (/^\-?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)))
        }

        var _validateNumeric = function (evt) {
            var charCode = (evt.which) ? evt.which : event.keyCode
            return ((charCode > 31 && ((charCode >= 48 && charCode <= 57) || charCode == 46)) || charCode == 8 || charCode == 9);
        }

        var _validateCheckboxList = function (values) {
            var counter = 0;
            for (var i = 0; i < values.length; i++) {
                if (!!values[i]) {
                    counter++;
                }
            }
            return (counter > 0);
        }

        var _validateRadioList = function (values) {
            var counter = 0;
            for (var i = 0; i < values.length; i++) {
                if (!!values[i]) {
                    counter++;
                }
            }
            return (counter > 0);
        }

        var _checkEmail = function (message, element, value) {
            if (!!value) {
                var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
                return emailReg.test(value);
            }
            else {
                return true;
            }
        }

        var _checkDateFormat = function (value) {
            var emailReg = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
            if (!!value) {
                return emailReg.test(value);
            }
            else {
                return true;
            }
        }

        var _checkWebsite = function (value) {
            var websiteReg = /^(http:\/\/www.|https:\/\/www.|ftp:\/\/www.|www.){1}([0-9A-Za-z]+\.)/;
            if (!!value) {
                return websiteReg.test(value);
            }
            else {
                return true;
            }

        }

        var _checkDate = function (futuredate, value) {
            if (!!value) {
                var emailReg = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
                if (emailReg.test(value)) {
                    var pickDate = new Date(value);
                    var curentdate = new Date();
                    return (curentdate > pickDate);
                }
                else {
                    return false;
                }
            }
            else {
                return true;
            }
        }

        var _validateDateFutureAccept = function (futuredate, value) {
            if (!!value) {
                var emailReg = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
                return emailReg.test(value);
            }
            else {
                return false;
            }
        }

        var _validateNotRequiredDateFutureAccept = function (futuredate, value) {
            if (!!value) {
                var emailReg = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
                return emailReg.test(value);
            }
            else {
                return true;
            }
        }

        var _validateWebsite = function (value, isRequired) {
            if (isRequired == null || isRequired == undefined)
                isRequired = true;
            if (!!value) {
                var websiteReg = /^(http:\/\/www.|https:\/\/www.|ftp:\/\/www.|www.){1}([0-9A-Za-z]+\.)/;
                return websiteReg.test(value);
            }
            else if (!isRequired) {
                return true;
            }
            else {
                return false;
            }
        }

        var _validateDate = function (futuredate, value) {
            var emailReg = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
            if (!!value && emailReg.test(value)) {
                var pickDate = new Date(value);
                var curentdate = new Date();
                return (curentdate > pickDate);
            }
            else {
                return false;
            }
        }

        var _validateNotRequiredDate = function (futuredate, value) {
            var emailReg = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
            if (!!value) {
                if (emailReg.test(value)) {
                    var pickDate = new Date(value);
                    var curentdate = new Date();
                    if (curentdate > pickDate) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return false;
                }
            }
            else {
                return true;
            }
        }

        var _validateEmail = function (value, isRequired) {
            if (isRequired == null || isRequired == undefined)
                isRequired = true;
            if (value !== "" && value !== "undefined") {
                var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
                if (emailReg.test(value)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else if (!isRequired) {
                return true;
            }
            else {
                return false;
            }
        }

        var _containsstring = function (pass, str) {
            return pass.toLowerCase().indexOf(str.toString().toLowerCase()) == -1 ? false : true;
        };

        var _stringLen = function (pass, len) {
            return pass.length < 8 ? false : true;
        };

        var _hasNumber = function (s) {
            return /\d/.test(s);
        };

        var _hasUpperCase = function (s) {
            return /[A-Z]/.test(s);
        };

        var _hasLowerCase = function (s) {
            return /[a-z]/.test(s);
        };

        var _hasSpecialChar = function (s) {
            return /[`~!@@#$%^&*()_-\s+={}\s[\\|;:.,",<>?\]/]/.test(s);
        }

        var _validateEmailWithoutRequired = function (value) {
            if (value !== "" && value !== "undefined") {
                var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
                if (emailReg.test(value)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return true;
            }
        }

        return {
            validateTextbox: _validateTextbox,
            validateOnlyAlphabet: _validateOnlyAlphabet,
            validateDropdown: _validateDropdown,
            validateCheckbox: _validateCheckbox,
            validateCheckboxIsChecked: _validateCheckboxIsChecked,
            validateRadio: _validateRadio,
            validateZipCode: _validateZipCode,
            validateZipCodeWithoutRequiredVal: _validateZipCodeWithoutRequiredVal,
            validateWebsiteWithoutRequired: _validateWebsiteWithoutRequired,
            validatePhone: _validatePhone,
            validatePhoneWithoutRequired: _validatePhoneWithoutRequired,
            validateSSN: _validateSSN,
            validateCompare: _validateCompare,
            validatePositiveNumber: _validatePositiveNumber,
            validateNumber: _validateNumber,
            validateDecimal: _validateDecimal,
            validateNumeric: _validateNumeric,
            validateCheckboxList: _validateCheckboxList,
            validateRadioList: _validateRadioList,
            checkEmail: _checkEmail,
            checkDateFormat: _checkDateFormat,
            checkWebsite: _checkWebsite,
            checkDate: _checkDate,
            validateDateFutureAccept: _validateDateFutureAccept,
            validateNotRequiredDateFutureAccept: _validateNotRequiredDateFutureAccept,
            validateWebsite: _validateWebsite,
            validateDate: _validateDate,
            validateNotRequiredDate: _validateNotRequiredDate,
            validateEmail: _validateEmail,
            containsstring: _containsstring,
            stringLen: _stringLen,
            hasNumber: _hasNumber,
            hasUpperCase: _hasUpperCase,
            hasLowerCase: _hasLowerCase,
            hasSpecialChar: _hasSpecialChar,
            validateEmailWithoutRequired: _validateEmailWithoutRequired
        };
    }
})(); 