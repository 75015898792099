(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeProviderLicenseController", BackofficeProviderLicenseController);

    BackofficeProviderLicenseController.$inject = ["$rootScope", "$scope", "$state", "backofficeProviderLicenseService", "backofficeIndividualEducationService",
        "utilityService", "$exceptionHandler", "WebApiUrl", "BackofficeProviderService", "BackofficeIndividualService", "$filter", "ProviderMultipleFileUploadService", "dcaIService", "typeValuesService", "Messages"
    ];

    function BackofficeProviderLicenseController($rootScope, $scope, $state, backofficeProviderLicenseService, backofficeIndividualEducationService,
        utilityService, $exceptionHandler, WebApiUrl, BackofficeProviderService, BackofficeIndividualService, $filter, ProviderMultipleFileUploadService, dcaIService, typeValuesService, Messages) {

        var providerLicenseNo = '';
        $scope.individualLicense = {};

        $scope.documentConfig = {
            DocId: 18,
            LinkType: 'PM',
            PropertyToProcess: 'ProviderLicenseId'
        };

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, ProviderId) {
            if (ProviderId && ($rootScope.individualFirmCurrentTab.name == "license" || $rootScope.individualFirmCurrentTab.name == "firminformation")) {
                $scope.ProviderId = ProviderId;
                initDocument();
                $scope.ProviderLicenseList = [];
                if (!$scope.isLoadedOnce) {
                    $scope.isLoadedOnce = true;
                    $scope.getPaymentMethod();
                }
                AuditVisitInfoSave();
                GetProviderStatusType();
                licenseTypeGetAll();
                getRefProviderType();
                $scope.providerLicenseDetailBYProviderId($scope.ProviderId);
                
                //$rootScope.individualFirmCurrentTab.isLicenseLoaded = true;
            }
        });

        $scope.DisplayCASLicensePrimaryStatus = async function(){
            ShowLoader();
            $scope.CASFirmLicenseData = {};
            if(!!$scope.ProviderLicenseList[0].IDNumber){
                let requestData = {
                    isn: $scope.ProviderLicenseList[0].IDNumber,
                    EntityId: $scope.ProviderLicenseList[0].ProviderId,
                    EntityType: "P"
                };
                try {
                    dcaIService.getCaLicFilePrimStatCde(sessionStorage.Key, requestData)
                        .then(function (response){
                            if ($scope.checkServerResponse(response)) {
                                if (!!response.EntityRecords && response.EntityRecords.length > 0) {
                                    $scope.CASFirmLicenseData.LicenseName = $scope.ProviderLicenseList[0].LicenseTypeName;
                                    $scope.CASFirmLicenseData.ProviderLicenseNumber = $scope.ProviderLicenseList[0].ProviderLicenseNumber;
                                    $scope.CASFirmLicenseData.PrimaryProviderStatusTypeName = response.EntityRecords[0].primStatCde +" - " +$scope.ProviderLicenseList[0].ProviderStatusTypeName;
                                    let priData  = response.EntityRecords;
                                    let requestSecData = {
                                        isn: $scope.ProviderLicenseList[0].IDNumber,
                                        EntityId: $scope.ProviderLicenseList[0].ProviderId,
                                        EntityType: "P"
                                    };
                                    dcaIService.getCaLicFileSecStatCde(sessionStorage.Key, requestSecData)
                                        .then(function (response){
                                            if ($scope.checkServerResponse(response)) {
                                                if (!!response.EntityRecords && response.EntityRecords.length > 0) {
                                                    let secData = response.EntityRecords;
                                                    $scope.CASFirmLicenseData.SecondaryProviderStatusTypeName = response.EntityRecords[0].primStatCde + $scope.ProviderLicenseList[0].ProviderStatusTypeName;
                                                    /*response.EntityRecords.forEach(function(eachRecord) {

                                                    });
                                                */                                                }
                                            }
                                        });
                                    // return response.EntityRecords;
                                }
                            }
                        }, function(data) {
                            $scope.showStatusMessage(data, "error");
                            return false;
                        });
                } catch (ex) {
                    if (ex != null) {
                        $scope.showStatusMessage(ex.message, "error");
                    }
                }
            }
            HideLoader();
        };

        $scope.viewConfig = {
            showLicenseViewBlock: false,
            showCEHTable: false,
            showAddCEHButton: true,
            viewLicense: {}
        };
        $scope.datePicker = {
            beginDateOpened: false,
            endDateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };

        $scope.cehDatePicker = {
            dateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };

        var GetProviderStatusType = function () {
            if (!$scope.BusinessStatus) {
                // BackofficeProviderService.GetProviderStatusType(sessionStorage.Key)
                typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=refproviderlicensestatus"))
                    .then(function (res) {
                        if (res.Status) {
                            $scope.BusinessStatus = res.ReferenceEntities;
                            getLicenseStatus();
                        }
                    });
            }
        };

        var getRefProviderType = function () {
            typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=providertype"))
                .then(function (res) {
                    if (res.Status) {
                        $scope.ProviderTypeList = res.ReferenceEntities;
                    }
                });
        }

        var licenseTypeGetAll = function () {
            try {
                backofficeProviderLicenseService.licenseTypeGetAll(sessionStorage.Key)
                    .then(function(response){ response = response.data;
                        if ($scope.checkResponse(response)) {
                            $scope.LicenseTypeList = response.RefProviderLicenseTypeList;
                        }
                    }, function(data) { data = data.data;

                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {

                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        };


        var raiseEvaent = function (data) {
            $scope.$emit('loadFirmLicenseData', data);
        };

        $scope.providerLicenseDetailBYProviderId = function (ProviderId) {
            ShowLoader("Please Wait...");
            try {
                //$scope.ConvertableIndividualLicenseId = 0;
                backofficeProviderLicenseService.providerLicenseDetailBYProviderId(sessionStorage.Key, ProviderId)
                    .then(function (response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        HideLoader();
                        if ($scope.checkServerResponse(response)) {
                            $scope.ProviderLicenseList = response.ProviderLicenseResponseList;
                            raiseEvaent(response.ProviderLicenseResponseList);
                            if ($scope.ProviderLicenseList && $scope.ProviderLicenseList.length > 0) {
                                //$scope.ConvertableIndividualLicenseId = getMaxIndLicenseId($scope.ProviderLicenseList);
                                providerLicenseNo = $scope.ProviderLicenseList[0].ProviderLicenseNumber;
                                $scope.individualLicense.ProviderLicenseNumber = providerLicenseNo;
                                let casData = $scope.DisplayCASLicensePrimaryStatus();
                            }
                            processListData();
                            getLicenseStatus();
                        }
                    }, function (data) {
                        HideLoader();
                        $scope.showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        };

        var processListData = function () {
            if ($scope.ProviderLicenseList && $scope.ProviderLicenseList.length > 0) {
                ProviderMultipleFileUploadService.providerDocumentProcess(sessionStorage.Key, $scope.ProviderId, $scope.documentConfig.DocId,
                    $scope.ProviderLicenseList, $scope.documentConfig.PropertyToProcess, $scope.documentConfig.LinkType).then(function (responseData) {
                        $scope.ProviderLicenseList = responseData;
                        console.log('data processed', responseData);
                    });
            }
        };


        function getMaxIndLicenseId(LicArray) {
            var LicNumArr = [];
            angular.forEach(LicArray, function (value, index) {
                LicNumArr.push(LicArray[index].ProviderLicenseId);
            });
            return Math.max.apply(null, LicNumArr);
        };

        var getLicenseStatus = function () {
            if ($scope.ProviderLicenseList && $scope.ProviderLicenseList.length > 0 && $scope.BusinessStatus && $scope.BusinessStatus.length > 0) {
                var t = [];
                $scope.ProviderLicenseList.map(function (i) {
                    t = $.grep($scope.BusinessStatus, function (j) {
                        return (i.ProviderStatusTypeId == j.ProviderLicenseStatusId);
                    });
                    if (t.length > 0) {
                        i.LStatus = t[0].ProviderLicenseStatusName;
                    }
                });
            }
        };

        $scope.licenseView = function (lic, isConversion) {
            var st = setTimeout(function () {
                var individualLicense = angular.copy(lic);
                $scope.viewConfig.showLicenseViewBlock = true;
                individualLicense.ProviderLicenseEffectiveDate = $filter('date')(individualLicense.ProviderLicenseEffectiveDate, 'MM/dd/yyyy')
                individualLicense.ProviderLicenseExpirationDate = $filter('date')(individualLicense.ProviderLicenseExpirationDate, 'MM/dd/yyyy')
                $scope.individualLicense = angular.copy(individualLicense);
                if (!$scope.individualLicense.ReferenceNumber) {
                    $scope.individualLicense.ReferenceNumber = new Date().valueOf();
                }
                initDocument();
                $scope.$apply();
            }, 10);
        };

        $scope.addnewPermit = function () {
            $scope.viewConfig.showLicenseViewBlock = true;
            $scope.individualLicense = {};
            $scope.individualLicense.ReferenceNumber = new Date().valueOf();
            initDocument();
        }

        $scope.getPaymentMethod = function () {
            $scope.PaymentMethodList = [
                { Paymentcode: 'CK', Name: 'Check', Id: 1 },
                { Paymentcode: 'MO', Name: 'Money Order', Id: 2 },
                { Paymentcode: 'CP', Name: 'Click to pay', Id: 4 }
            ];
        }

        //document
        var initDocument = function () {
            $scope.documentUpload = undefined;
            setTimeout(function () {
                $scope.$apply(function () {
                    $scope.documentUpload = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                        // ApplicationId: _applicationId,
                        ProviderId: $scope.ProviderId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FRM",
                        LoadByDocIdAndDocCode: true,
                        DocId: 18,
                        DocCode: "D1018",
                        LoadDocumentsWithoutBroadCast: true,
                        LinkType: 'PM',
                        PropertyToProcess: 'ProviderLicenseId',
                    };
                });
            }, 1000);
        };


        $scope.DownloadDocumentAPIURL = WebApiUrl + 'Individual/DownloadPDF/' + sessionStorage.Key + '?path=';
        $scope.DownloadDocument = function (path) {
            window.open($scope.DownloadDocumentAPIURL + path, '_blank');
        };

        $scope.setSaveDocumentRef = function (fn) {
            $scope.saveAllDocument = fn;
        };
        //Document

        $scope.addNewBtnClick = function () {
            if (!$scope.viewConfig) {
                $scope.viewConfig = {};
            }
            $scope.viewConfig.showLicenseViewBlock = true;
            $scope.individualLicense = {};
            $scope.individualLicense.ReferenceNumber = new Date().valueOf();
            initDocument();
        };

        $scope.SaveLicense = function (isPayment, licenseForm, applicationObj) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                if ($scope.validateData(licenseForm)) {
                    ShowLoader("Please wait...");

                    if (!$scope.individualLicense.ProviderLicenseId || $scope.individualLicense.ProviderLicenseId == 0) {
                        $scope.individualLicense.ProviderId = $scope.ProviderId;
                        $scope.individualLicense.IsActive = true;
                        $scope.individualLicense.IsDeleted = false;
                        $scope.individualLicense.CreatedBy = sessionStorage.UserID;
                        $scope.individualLicense.CreatedOn = new Date();
                        $scope.individualLicense.ProviderLicenseGuid = 'pl';
                        // $scope.individualLicense.OriginalLicenseDate
                        if (!!$scope.routeData && !!$scope.routeData.ProviderTypeId) {
                            $scope.individualLicense.ProviderTypeId = $scope.routeData.ProviderTypeId;
                        }
                        if (!$scope.individualLicense.ProviderTypeId)
                            $scope.individualLicense.ProviderTypeId = 5;
                    }

                    var businessStatus = _.find($scope.BusinessStatus, function (o) { return o.ProviderLicenseStatusName == "Active"; });
                    if (businessStatus && businessStatus.ProviderLicenseStatusId == $scope.individualLicense.ProviderStatusTypeId) {
                        $scope.individualLicense.IsLicenseActive = true;
                    }

                    if (!$scope.individualLicense.ReferenceNumber) {
                        $scope.individualLicense.ReferenceNumber = new Date().valueOf();
                    }

                    var licenseData = [$scope.individualLicense];
                    backofficeProviderLicenseService.SaveProviderLicense(sessionStorage.Key, licenseData).then(function (res) {
                        if (res.Status) {
                            if (res.ProviderLicenseResponseList && res.ProviderLicenseResponseList.length > 0) {
                                $scope.documentUpload.LinkId = res.ProviderLicenseResponseList[0].ProviderLicenseId;
                                if (typeof $scope.saveAllDocument == "function") {
                                    $scope.saveAllDocument().then(function () {
                                        $scope.providerLicenseDetailBYProviderId($scope.ProviderId);
                                        $scope.cancelLicenseView();
                                        HideLoader();
                                    });
                                } else {

                                    $scope.providerLicenseDetailBYProviderId($scope.ProviderId);
                                    $scope.cancelLicenseView();
                                    HideLoader();
                                }
                            } else {
                                $scope.providerLicenseDetailBYProviderId($scope.ProviderId);
                                $scope.cancelLicenseView();
                                HideLoader();
                            }
                        }
                    });
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        };

        $scope.SavePayment = function () {
            sessionStorage.IndividualId = $scope.IndividualID;
            sessionStorage.RequestedLicenseStatusTypeId = 1;
            sessionStorage.RenewalFees = 50;
            sessionStorage.LateFees = 0;

            $scope.LicenseTypeId = sessionStorage.LicenseTypeId;
            $scope.ApplicationId = sessionStorage.ApplicationId;
            $scope.IndividualLicenseId = sessionStorage.IndividualLicenseId;
            $scope.LicenseTypeId = sessionStorage.LicenseTypeId;
            $scope.LicenseNumber = sessionStorage.LicenseNumber;


            feeDetails = [{ 'RevMstFeeId': 1, 'FeeName': sessionStorage.LicenseTypeName + ' License Conversion Fee', 'FeeAmount': 50, 'FeeTypeID': 4, 'LicenseTypeId': sessionStorage.LicenseTypeId, 'IndividualLicenseId': sessionStorage.IndividualLicenseId, 'Description': '' }];
            var date = new Date();
            sessionStorage.TransactionStartDatetime = date;
            sessionStorage.FeesDetails = JSON.stringify(feeDetails);
            $scope.FeesTotal = 50;
            $scope.$broadcast("ConversionPayment", {});
        }

        $scope.validateConversionForm = function (licenseForm) {
            $scope.hideStatusMessage();
            $scope.hideValidationSummary();

            if (licenseForm.errorMessages == undefined)
                licenseForm.errorMessages = [];

            var errorObj = {
                propName: "changeLicenseTypeforConversion",
                errorMessage: Messages.General.Errors.ChangeLicenseType
            };
            cleanErrorMessage(licenseForm.errorMessages, errorObj.propName);
            if ($scope.individualLicense.LicenseTypeId == $scope.individualLicense.ExistingLicenseTypeId) {
                licenseForm.errorMessages.push(errorObj);
            }
            return $scope.validateData(licenseForm);
        };

        var cleanErrorMessage = function (errorMessages, prop) {
            var indexOfError = null;
            for (var i = 0; i < errorMessages.length; i++) {
                if (errorMessages[i].propName == prop)
                    indexOfError = i;
            }
            if (indexOfError != null && indexOfError >= 0) {
                errorMessages.splice(indexOfError, 1);
            }
        };


        $scope.LicensePayment_init = function () {

        }


        $scope.changePaymentMethod = function (ddlpaymentMethod) {
            $scope.LicensePayment_init();
        };


        //License Conversion

        $scope.applicationId = null;
        $scope.LicenseSaveConversion = function (licenseForm) {
            try {
                if (!$scope.validateConversionForm(licenseForm)) {
                    return false;
                }
                var CurrentDate = new Date();
                var applicationObj = {
                    ApplicationTypeId: 4,
                    ApplicationStatusId: 1,
                    ApplicationStatusReasonId: 1,
                    ApplicationInternalStatusId: 1,
                    StartedDate: CurrentDate,
                    SubmittedDate: CurrentDate,
                    ApplicationStatusDate: CurrentDate,
                    PaymentDeadlineDate: CurrentDate,
                    PaymentDate: CurrentDate,
                    LicenseTypeId: $scope.individualLicense.LicenseTypeId,
                    IsActive: true,
                    HighPriority: true
                };
                if (!$scope.applicationId) {
                    backofficeProviderLicenseService.generateApplicationId(sessionStorage.Key, 0, sessionStorage.UserID, applicationObj)
                        .then(function (response) {
                            if ($scope.checkServerResponse(response)) {
                                $scope.applicationId = response.ApplicationId;
                                var applicationObj = {
                                    ApplicationId: response.ApplicationId,
                                    ApplicationNumber: response.ApplicationNumber
                                };
                                sessionStorage.applicationObj = applicationObj;
                                $scope.SaveLicense(true, licenseForm, applicationObj);
                            }
                        }, function (data) {
                            HideLoader();
                            if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                        });
                } else {
                    $scope.SaveLicense(true, licenseForm, sessionStorage.applicationObj);
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.deleteLicenseDetail = function (individualLicense) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                if (individualLicense) {
                    individualLicense.IsActive = false;
                    individualLicense.IsDeleted = true;
                    individualLicense.ModifiedBy = sessionStorage.UserID;
                    individualLicense.ModifiedOn = new Date();
                }
                var licenseData = [individualLicense];
                backofficeProviderLicenseService.SaveProviderLicense(sessionStorage.Key, licenseData).then(function (res) {
                    if (res.Status) {
                        $scope.providerLicenseDetailBYProviderId($scope.ProviderId);
                        $scope.cancelLicenseView();
                    }
                    HideLoader();
                });
            }
        }

        $scope.individualEducationBYIndividualLicenseId = function (IndividualLicenseId) {
            try {
                $scope.selectedIndividualLicenseId = IndividualLicenseId;
                $scope.IndividualCECourseList = [];
                $scope.viewConfig.showCEHTable = true;
                ShowLoader("Please Wait...");

                backofficeProviderLicenseService.individualEducationBYIndividualLicenseId(sessionStorage.Key, IndividualLicenseId)
                    .then(function (response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse(response)) {
                            $scope.IndividualCECourseList = response.IndividualCECourseResponseList;
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        $scope.showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.getCEH = function (individualCECourse) {
            $scope.isCEHEdit = true;
            $scope.viewConfig.showAddCEHBlock = true;
            $scope.viewConfig.showAddCEHButton = false;
            //$("#AddRowBlock").show();
            //$("#btnAddRow").hide();
            if (!!individualCECourse.CECourseDate && !angular.isDate(individualCECourse.CECourseDate))
                individualCECourse.CECourseDate = new Date(individualCECourse.CECourseDate);
            $scope.individualCECourse = angular.copy(individualCECourse);
            // $scope.EducationInformationCourse = $scope.CEHArray[index].CourseNameTitle;
            // $scope.EducationInformationDate = ($filter('date')(new Date($scope.CEHArray[index].CECourseDate), 'MM/dd/yyyy'));
            // $scope.EducationInformationCEHours = $scope.CEHArray[index].CECourseHours;

            // $scope.courseID = $scope.CEHArray[index].IndividualCECourseId;
            // $scope.CourseApplicationID = $scope.CEHArray[index].ApplicationId;
        }



        $scope.saveCEH = function (cehForm) {
            try {
                $scope.viewConfig.showCEHTable = true;
                cehForm.$submitted = true;
                if ($scope.validateData(cehForm)) {
                    ShowLoader("Please wait...");
                    if (!$scope.isCEHEdit) {
                        $scope.individualCECourse.IndividualId = $scope.IndividualId;
                        $scope.individualCECourse.IndividualLicenseId = $scope.selectedIndividualLicenseId;
                    }
                    // if ($scope.courseID == null) {
                    //     IndividualCECourseId = 0;
                    // } else {
                    //     IndividualCECourseId = $scope.courseID;
                    // }
                    // if ($scope.CourseApplicationID == null) {
                    //     ApplicationId = null;
                    // } else {
                    //     ApplicationId = $scope.CourseApplicationID;
                    // }

                    //CECourseTypeId = null;
                    //CECourseActivityTypeId = null;
                    //CECourseDate = $(".addRowDate").val();
                    //CECourseEndDate = null;
                    //CECourseDueDate = null;
                    //CECourseStartDate = null;
                    //CECourseHours = $scope.EducationInformationCEHours;
                    //CECourseUnits = 0.0;
                    //ProgramSponsor = null;
                    //CourseNameTitle = $scope.EducationInformationCourse;
                    // CourseSponsor = null;
                    // CECourseReportingYear = null;
                    // CECourseStatusId = null;
                    // InstructorBiography = null;
                    // ActivityDesc = null;
                    // ReferenceNumber = null;
                    $scope.individualCECourse.IsActive = true;
                    $scope.individualCECourse.IsDeleted = false;
                    //IndividualLicenseId = $scope.LicenseID;

                    backofficeIndividualEducationService.individualCECourseSave($scope.individualCECourse, sessionStorage.Key)
                        .then(function (response) {
                            if ($scope.checkServerResponse(response)) {
                                $scope.individualEducationBYIndividualLicenseId($scope.individualCECourse.IndividualLicenseId);
                                $scope.showStatusMessage(response.Message, "success");
                                //$scope.clearCEH();
                                //$("#AddRowBlock").hide();
                                //$("#btnAddRow").show();
                                $scope.viewConfig.showAddCEHBlock = false;
                                $scope.viewConfig.showAddCEHButton = true;
                            }
                            HideLoader();
                        }, function (data) {
                            HideLoader();
                            if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                        });
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        }


        $scope.deleteCEH = function (individualCECourse) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                ShowLoader("Please wait...");
                // if ($scope.IndId == null) {
                //     IndividualId = $scope.IndividualID;
                // } else {
                //     IndividualId = $scope.IndId;
                // }

                // if ($scope.CourseApplicationID == null) {
                //     ApplicationId = null;
                // } else {
                //     ApplicationId = $scope.CourseApplicationID;
                // }

                // CECourseTypeId = null;
                // CECourseActivityTypeId = null;
                // CECourseDate = $(".addRowDate").val();
                // CECourseEndDate = null;
                // CECourseDueDate = null;
                // CECourseStartDate = null;
                // CECourseHours = $scope.EducationInformationCEHours;
                // CECourseUnits = 0.0;
                // ProgramSponsor = null;
                // CourseNameTitle = $scope.EducationInformationCourse;
                // CourseSponsor = null;
                // CECourseReportingYear = null;
                // CECourseStatusId = null;
                // InstructorBiography = null;
                // ActivityDesc = null;
                // ReferenceNumber = null;
                // IsActive = true;
                individualCECourse.IsDeleted = true;
                // IndividualLicenseId = $scope.InLicenseId;

                backofficeIndividualEducationService.individualCECourseSave(individualCECourse, sessionStorage.Key)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.individualEducationBYIndividualLicenseId(individualCECourse.IndividualLicenseId);
                            $scope.showStatusMessage(response.Message, "success");
                            //$scope.clearCEH();
                            //$("#AddRowBlock").hide();
                            //$("#btnAddRow").show();
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            }
        }

        //Clear CEH Row
        $scope.cancelCEH = function () {
            $scope.viewConfig.showAddCEHBlock = false;
            $scope.viewConfig.showAddCEHButton = true;
        }

        $scope.showAddEditCEH = function () {
            $scope.individualCECourse = {};
            $scope.isCEHEdit = false;
            $scope.viewConfig.showAddCEHBlock = true;
            $scope.viewConfig.showAddCEHButton = false;
        };

        $scope.closeCEH = function () {
            $scope.viewConfig.showCEHTable = false;
        }

        $scope.cancelLicenseView = function () {
            $scope.individualLicense = {};
            $scope.viewConfig.showLicenseViewBlock = false;
            $scope.individualLicense.ProviderLicenseNumber = providerLicenseNo;
        }

        function AuditVisitInfoSave() {

            var data = {
                "PageName": "IndividualInfo-License",
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.IndividualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        }

        $scope.licenceTypeChange = function (licenceType) {
            if (licenceType) {
                ShowLoader();
                backofficeProviderLicenseService.getMaxLicenseByLicenseTypeId(sessionStorage.Key, licenceType)
                    .then(function (response) {
                        if (response.Status) {
                            $scope.individualLicense.ProviderLicenseNumber = response.MaxLicenseNumber;
                        } else {
                            $scope.individualLicense.ProviderLicenseNumber = "";
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        $scope.showStatusMessage(data, "error");
                    });
            }
        };
    }
})();
