(function () {
    //'use strict';

    angular
        .module('app.backofficeEntity')
        .controller("EntityLogController", EntityLogController);

    EntityLogController.$inject = ["$rootScope", "$scope", '$timeout', '$filter', "backofficeEntityLogService", 'DataChangeLoggingService'];

    function EntityLogController($rootScope, $scope, $timeout, $filter, backofficeEntityLogService, DataChangeLoggingService) {

        $scope.init = function () {
            $scope.entityActivityLogs = [];
            $scope.displayEntityActivityLogs = [];
            $scope.showActivityLog = true;
            $scope.activityLogGetByEntityId($scope.entityId);

            $scope.entityDataChangeLogs = [];
            $scope.displayEntityDataChangeLogs = [];
            $scope.showDataChangeLog = true;
            $scope.dataChangeLogGetByIndividualId($scope.entityId);
        }
        //Tab Initilizer
        $scope.$on("entityTabChanged", function (evt, entityId) {
            if ($rootScope.entityCurrentTab.name == "log") {
                $scope.entityId = entityId;
                $scope.init();
            }
        });

        $scope.activityLogGetByEntityId = function (entityId) {
            $scope.refreshingActivityLog = true;
            backofficeEntityLogService.entityLogGetByEntityId(sessionStorage.Key, entityId, $scope.entityType)
            .then(function (data) {
                if (data.Status) {
                    $scope.entityActivityLogs = data.IndividualCommentLogRequest;

                    $scope.displayEntityActivityLogs = $scope.entityActivityLogs;

                    $scope.refreshingActivityLog = false;
                } else {
                    $scope.showStatusMsg('-', data.Message);
                }
                $scope.loadingActivityLog(false);
            }, function (err) {
                $scope.loadingActivityLog(false);
                $scope.showStatusMsg('-', err);
            });
        };

        $scope.activitySearchChanged = function () {
            var newList = $filter('filterLog')($scope.entityActivityLogs, $scope.activitySearchText, ['CommentLogDate', 'CommentLogText', 'CreatedByName'], 'MM/dd/yyyy h:mm:ss a');

            $scope.displayEntityActivityLogs = newList;

            $scope.refreshActivityLogTable();
        };

        $scope.refreshActivityLogTable = function () {
            $scope.refreshingActivityLog = true;
            $timeout(function () { $scope.refreshingActivityLog = false; });
        };

        $scope.dataChangeLogGetByIndividualId = function (individualId) {
            $scope.refreshingDataChangeLog = true;
            DataChangeLoggingService.getDataChangeLog(sessionStorage.Key, individualId)
            .then(function (data) {
                if (data.Status) {
                    $scope.entityDataChangeLogs = data.IndividualDataChangeLogs;
                    $scope.entityDataChangeLogs.forEach(function (log) {
                        log.HumanReadable = DataChangeLoggingService.getHumanReadableSentence(log);
                    });

                    $scope.displayEntityDataChangeLogs = $scope.entityDataChangeLogs;
                    $scope.refreshingDataChangeLog = false;
                } else {
                    $scope.showStatusMsg('-', data);
                }
                $scope.loadingDataChangeLog(false);
            }, function (err) {
                $scope.loadingDataChangeLog(false);
                $scope.showStatusMsg('-', err);
            });
        };

        $scope.dataChangeSearchChanged = function () {
            var newList = $filter('filterLog')($scope.entityDataChangeLogs, $scope.dataChangeSearchText, ['DataChangedDateTime', 'HumanReadable', 'CreatedByName', 'FieldOldValue', 'FieldNewValue'], 'MM/dd/yyyy h:mm:ss a');

            $scope.displayEntityDataChangeLogs = newList;

            $scope.refreshDataChangeLogTable();
        };

        $scope.refreshDataChangeLogTable = function () {
            $scope.refreshingDataChangeLog = true;
            $timeout(function () { $scope.refreshingDataChangeLog = false; });
        };

        $scope.getValue = function (fieldText, fieldValue) {
            var valText = '';

            if (!!fieldText) {
                valText = fieldText;
            } else {
                valText = fieldValue;
            }

            if (Date.isDate(valText)) {
                valText = $filter('date')(new Date(valText), 'MM/dd/yyyy h:mm:ss a');
            }

            return valText;
        };

        $scope.init();
    }
})();