(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .directive('affidavitChecklistValidation', ['Messages', AffidavitChecklistValidation]);

    function AffidavitChecklistValidation(Messages) {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function () {
                    if ($scope.applicationInfo && !!$scope.applicationInfo.ApplicationTypeId && $scope.applicationInfo.ApplicationTypeId == 1) {
                        $scope.natValidation.errorMessages = [];
                        if (!!$scope.currentForm) {
                            $scope.currentForm.$submitted = true;
                            $scope.natValidation.errorMessages = angular.copy($scope.currentForm.errorMessages);
                            if (!$scope.natValidation.errorMessages) {
                                $scope.natValidation.errorMessages = [];
                            }
                        }
                        if (!!$scope.uiConfig && !!$scope.uiConfig.ValidationRequirement) {
                            angular.forEach($scope.current.affidavits, function (affidavit, index) {
                                var requiredResponse = $scope.uiConfig.ValidationRequirement[affidavit.ContentItemLkId];
                                if (!angular.isUndefined(requiredResponse) && requiredResponse != affidavit.ContentItemResponse) {
                                    $scope.natValidation.errorMessages.push({
                                        propName: 'AffidavitResponseRequired',
                                        errorMessage: Messages.General.Errors.NotEligibleRenewal(affidavit.ContentItemResponse, index)
                                    });
                                }
                            })
                        }

                        if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                            $scope.natValidation.showError = true;
                            $scope.natValidation.isValid = false;
                            return false;
                        } else {
                            $scope.natValidation.showError = false;
                            $scope.natValidation.isValid = true;
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
            }
        };
    }
})();