(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationNceessController", InitialApplicationNceessController);

    InitialApplicationNceessController.$inject = ["$rootScope", "$scope", "$state", "$q", "$uibModal", "typeValuesService", "initialApplicationNcessService", "WebApiUrl", "contentService", "countryStateService"];

    function InitialApplicationNceessController($rootScope, $scope, $state, $q, $uibModal, typeValuesService, initialApplicationNcessService, WebApiUrl, contentService, countryStateService) {
        $scope.ncess = {};
        $scope.datePicker = {
            dateOpened: false,
            format: 'M!/d!/yyyy'
        };
        $scope.maskConfig = {
            clearOnBlur: false,
            allowInvalidValue: true,
            clearOnBlurPlaceholder: false
        };

        $scope.documentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.individualId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "R",
            LoadByDocIdAndDocCode: true,
            DocId: 111,
            DocCode: "D1111",
            LoadDocumentsWithoutBroadCast: true,
            hideUploadedFor: true
        };

        $scope.principles = [
            { PrincipleId: 1, PrincipleName: 'Agricultural and Biological' },
            { PrincipleId: 2, PrincipleName: 'Chemical' },
            { PrincipleId: 3, PrincipleName: 'Civil' },
            { PrincipleId: 4, PrincipleName: 'Control Systems' },
            { PrincipleId: 5, PrincipleName: 'Electrical and Computer' },
            { PrincipleId: 6, PrincipleName: 'Fire Protection' },
            { PrincipleId: 7, PrincipleName: 'Industrial and Systems' },
            { PrincipleId: 8, PrincipleName: 'Mechanical' },
            { PrincipleId: 9, PrincipleName: 'Metallurgical and Materials' },
            { PrincipleId: 10, PrincipleName: 'Nuclear' },
            { PrincipleId: 11, PrincipleName: 'Petroleum' },
            { PrincipleId: 12, PrincipleName: 'Structural' },
            { PrincipleId: 13, PrincipleName: 'Environmental (Comity ONLY)' },
            { PrincipleId: 14, PrincipleName: 'Mining and Mineral Processing (Comity ONLY)' },
            { PrincipleId: 15, PrincipleName: 'Naval Architecture and Marine (Comity ONLY)' }
        ];

        $scope.principlesCivil = [         
            { PrincipleId: 16, PrincipleName: 'Architectural (Comity ONLY)' },
            { PrincipleId: 3, PrincipleName: 'Civil' },            
            { PrincipleId: 13, PrincipleName: 'Environmental (Comity ONLY)' },
            { PrincipleId: 14, PrincipleName: 'Mining and Mineral Processing (Comity ONLY)' },
            { PrincipleId: 15, PrincipleName: 'Naval Architecture and Marine (Comity ONLY)' },            
            { PrincipleId: 12, PrincipleName: 'Structural' }
        ];

        var getConfigData = function () {
            var dataPromise = [];
            dataPromise.push(typeValuesService.getTypeValuesByTypeNameAndParamsPublic("RefTablesGetAll", true, ("?tableKey=licenseapplicationfortype&pullInActive=true")));
            dataPromise.push(typeValuesService.getTypeValuesByTypeNameAndParamsPublic("RefTablesGetAll", true, ("?tableKey=licenseapplicationfor&pullInActive=true")));

            $q.all(dataPromise)
                .then(function (res) {
                    var status = true;
                    res.forEach(function (response) {
                        if (typeof (response.StatusCode) != 'undefined' && response.StatusCode != '00') {
                            status = false;
                        }
                    });
                    if (status) {
                        if (!!res[0].ReferenceEntities && res[0].ReferenceEntities.length > 0) {
                            $scope.initialLicenseApplicationForTypes = res[0].ReferenceEntities.whereNotEquals(1, 'LicenseApplicationForTypeId');
                        }
                        if (!!res[1].ReferenceEntities && res[1].ReferenceEntities.length > 0) {
                            $scope.initialLicenseApplicationSubTypes = res[1].ReferenceEntities;
                        }
                    }

                }, function (error) {
                    console.log(error);
                });
        };

        $scope.init = function () {
            ShowLoader("Please wait...");
            getConfigData();
            $scope.getEntityConfirmation();
        }

        $scope.saveBookRecord = function (currentForm, skipNextTab) {
            ShowLoader("Please wait...");
            $scope.tab.isInvalid = false;
            $scope.tab.skipNextTab = skipNextTab;
            if (!validateForm(currentForm) || !$scope.openMustPassEnggExamConfirmation(($scope.contentData.QualProfEngOthrState ? $scope.contentData.QualProfEngOthrState.ConfirmationResponse : null))) {
                $scope.tab.isInvalid = true;
                HideLoader();
                if (skipNextTab && !!skipNextTab.fromDeficiency) {
                    $scope.$emit('deficiencyExecuted', { success: false });
                }
                if ($scope.tab.skipNextTab == true) {
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
                return;
            }

            saveNcees(true, skipNextTab);
        }

        var saveNcees = function (val, skipNextTab) {
            ShowLoader();
            saveEntityConfirmation(skipNextTab);
            $scope.$broadcast("SaveDeficiency", {});
        }

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        var validateForm = function (currentForm) {
            $scope.numberrequired = false;
            if (!currentForm.errorMessages)
                currentForm.errorMessages = [];
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            if (($scope.applicationInfo.LicenseApplicationForId == 1 && !$scope.contentData.QualMinEITQual.ConfirmationResponse) ||
                ($scope.applicationInfo.LicenseApplicationForId == 2 && !$scope.contentData.QualMinLSITQual.ConfirmationResponse)) {
                var errorObj = {
                    propName: "MinQual",
                    errorMessage: "You need to have minimum Qualification"
                };
                $scope.natValidation.errorMessages.push(errorObj);
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.qualNCEESEngExamchanged = function (NCEESEngExamResponse) {
            $scope.entityConfirmationQualNCEESWaiver = $scope.contentList
                .whereEquals('QualNCEESWaiver', 'ContentItemLkCode')
                .firstOrDefault();
            if (NCEESEngExamResponse == true && !!$scope.entityConfirmationQualNCEESWaiver) {
                $scope.entityConfirmationQualNCEESWaiver.ConfirmationResponse = null;
            }
            else if (!!$scope.entityConfirmationQualNCEESWaiver) {
                $scope.entityConfirmationQualNCEESWaiver.ConfirmationResponse = true;

            }
        };

        $scope.qualNCEESSurveyorExam = function (NCEESEngExamResponse) {
            $scope.entityConfirmationQualNCEESSurveyorWaiver = $scope.contentList
                .whereEquals('QualNCEESSurveyorWaiver', 'ContentItemLkCode')
                .firstOrDefault();
            if (NCEESEngExamResponse == true && !!$scope.entityConfirmationQualNCEESSurveyorWaiver) {
                $scope.entityConfirmationQualNCEESSurveyorWaiver.ConfirmationResponse = null;
            }
            else if (!!$scope.entityConfirmationQualNCEESSurveyorWaiver) {
                $scope.entityConfirmationQualNCEESSurveyorWaiver.ConfirmationResponse = true;
            }
        };

        var saveEntityConfirmation = function (skipNextTab) {
            _.forEach($scope.contentList, function (value) {
                if (['QualPrevApplication'].indexOf(value.ContentItemLkCode) != -1) {
                    var confirmationValue = "";
                    if (!!value.LicenseApplicationForTypeId)
                        confirmationValue = value.LicenseApplicationForTypeId + '-';
                    if (!!value.LicenseApplicationForId)
                        confirmationValue = confirmationValue + value.LicenseApplicationForId;
                    value.ConfirmationExplanation = confirmationValue;
                }

                value.EntityId = $scope.individualId;
                value.EntityTy = 'I';
                value.ApplicationId = $scope.applicationId;
                value.IsActive = true;
                value.IsDeleted = false;
                value.Desc = value.ContentItemLkDesc;
            });

            initialApplicationNcessService.saveEntityConfirmation($scope.contentList, sessionStorage.Key).then(function (response) {
                $scope.tab.inComplete = (($scope.applicationInfo.LicenseApplicationForId == 1 && !$scope.contentData.QualMinEITQual.ConfirmationResponse) ||
                    ($scope.applicationInfo.LicenseApplicationForId == 2 && !$scope.contentData.QualMinLSITQual.ConfirmationResponse));
                if ([1, 2].indexOf($scope.applicationInfo.LicenseApplicationForId) == -1) {
                    // if (!!$scope.contentData.QualNCEESRecordOrder) {
                    //     $scope.QualNCEESRecordOrderOriginal = angular.copy($scope.contentData.QualNCEESRecordOrder);
                    // }
                    if (!!$scope.QualNCEESRecordOrderOriginal && $scope.QualNCEESRecordOrderOriginal.ConfirmationResponse != null
                        && $scope.QualNCEESRecordOrderOriginal.ConfirmationResponse != $scope.contentData.QualNCEESRecordOrder.ConfirmationResponse) {
                        $scope.tab.markTabsIncompleteOnChange = ['educationTraining', 'experience'];
                    }
                }
                $scope.tab.skipNextTab = skipNextTab;
                $scope.openNextTab({
                    currentTab: $scope.tab
                });
                if ($scope.tab.skipNextTab == true) {
                    $scope.$emit('setCurrentTabNow');
                }
                if (skipNextTab && !!skipNextTab.fromDeficiency) {
                    $scope.$emit('deficiencyExecuted', { success: true });
                }
                $scope.getEntityConfirmation();
            }, function (error) {
                HideLoader();
            });
        };

        $scope.openWaiverConfirmation = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'mustPassFEExam.html',
                size: 'md',
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    $scope.onCancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.onConfirm = function () {
                        $uibModalInstance.close('success');
                    };
                }]
            });

            modalInstance.result.then(function () {
                $state.go('app.Dashboard');
            });
        };

        $scope.openSurveyorWaiverConfirmation = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'mustPassFSExam.html',
                size: 'md',
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    $scope.onCancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.onConfirm = function () {
                        $uibModalInstance.close('success');
                    };
                }]
            });

            modalInstance.result.then(function () {
                $state.go('app.Dashboard');
            });
        };

        $scope.openMustPassSurveyingExamConfirmation = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'mustPassSurveyingExam.html',
                size: 'md',
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    $scope.onCancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.onConfirm = function () {
                        $uibModalInstance.close('success');
                    };
                }]
            });

            modalInstance.result.then(function () {
                $state.go('app.Dashboard');
            });
        };

        $scope.openMustPassEnggExamConfirmation = function (otherStateResponse) {
            if ([5].indexOf($scope.applicationInfo.LicenseApplicationForId) != -1
                && $scope.contentData.QualNCEESEngExam.ConfirmationResponse == false
                && $scope.contentData.QualNCEESWaiver.ConfirmationResponse == true
                && $scope.contentData.QualPassedNCEESCivilEngExam.ConfirmationResponse == false
                && !$scope.contentData.QualNCEESPrinciple.ConfirmationExplanation
                && otherStateResponse == false
            ) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'mustPassEnggExam.html',
                    size: 'md',
                    controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                        $scope.onCancel = function () {
                            $uibModalInstance.dismiss('cancel');
                        };
                        $scope.onConfirm = function () {
                            $uibModalInstance.close('success');
                        };
                    }]
                });

                modalInstance.result.then(function () {
                    $state.go('app.Dashboard');
                });
                return false;
            } else {
                return true;
            }
        };

        $scope.getEntityConfirmation = function () {
            try {
                var dataPromises = [];
                dataPromises.push(contentService.getContent(126, sessionStorage.Key));
                dataPromises.push(initialApplicationNcessService.getEntityConfirmation($scope.individualId, 'I', $scope.applicationId, sessionStorage.Key));
                dataPromises.push(countryStateService.getStateByCountryID(sessionStorage.Key, $rootScope.DefaultCountryId));
                $q.all(dataPromises)
                    .then(function (response) {
                        HideLoader();
                        $scope.contentList = response[0].ContentItemLk;
                        $scope.contentData = response[0].ContentItems;
                        $scope.EntityConfirmations = response[1].EntityConfirmations;
                        $scope.StatList = response[2].State;
                        _.forEach($scope.contentList, function (value) {
                            value.EffectiveDate = null;
                            _.forEach($scope.EntityConfirmations, function (innerValue) {
                                if (value.ContentItemLkId == innerValue.ContentItemLkId) {
                                    value.EntityId = $scope.individualId;
                                    value.EntityTy = 'I';
                                    value.ApplicationId = $scope.applicationId;
                                    value.EffectiveDate = innerValue.EffectiveDate;
                                    value.ConfirmationDate = innerValue.ConfirmationDate;
                                    value.ReferenceNumber = innerValue.ReferenceNumber;
                                    value.IsActive = true;
                                    value.IsDeleted = false;
                                    value.ConfirmationResponse = innerValue.ConfirmationResponse;
                                    value.EntityConfirmationId = innerValue.EntityConfirmationId;
                                    value.ConfirmationExplanation = innerValue.ConfirmationExplanation;
                                }
                            });
                            if (['QualPrevApplication'].indexOf(value.ContentItemLkCode) != -1 && value.ConfirmationExplanation) {
                                var typeValues = value.ConfirmationExplanation.split('-');
                                if (typeValues.length > 0)
                                    value.LicenseApplicationForTypeId = typeValues[0];
                                if (typeValues.length > 1)
                                    value.LicenseApplicationForId = typeValues[1];
                            }
                            // if (value.ConfirmationResponse == null) {
                            //     if (['QualNCEESEngExam', 'QualNCEESWaiver'].indexOf(value.ContentItemLkCode) != -1) {
                            //        // value.ConfirmationResponse = true;
                            //     }
                            // }
                        });
                        if (!!$scope.contentData.QualNCEESRecordOrder) {
                            $scope.QualNCEESRecordOrderOriginal = angular.copy($scope.contentData.QualNCEESRecordOrder);
                        }
                        if (!!$scope.contentData.QualNCEESLSITFundExam.ConfirmationDate) {
                            $scope.contentData.QualNCEESExam.ConfirmationResponse = true;
                        }

                        HideLoader();
                        $scope.isConfigLoaded = true;
                    }, function (error) {
                        HideLoader();
                        $scope.showStatusMessage({
                            messageContent: 'Some error occurred !',
                            messageType: 'error'
                        });
                    });

            } catch (ex) {

            }
        }

        $scope.$on('SwitchingTab', function (event, args) {
            if (args.fromTab == $scope.tab.name) {
                if (!$scope.isBackoffice) {
                    $scope.saveBookRecord($scope.bookRecordForm, true);
                } else {
                    $scope.$broadcast("SaveDeficiency", {});
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
            }
        });

        $scope.$on('deficiencyResolve', function (event, args) {
            if (args.tabName == 'nceesRecord') {
                $scope.saveBookRecord($scope.bookRecordForm, { fromDeficiency: true })
            }
        });

        $scope.clearTabConfirmation = function () {
            $scope.$emit("clearTabConfirmation", { currentTab: $scope.tab });
        };

        $scope.init();
    }
})();