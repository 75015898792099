(function () {
    'use strict'

    angular.module('app.pciCompliantPayment')
        .controller('PCICompliantPaymentController', ['$scope', '$rootScope', '$q', '$state', '$sce', 'PCICompliantPaymentService', 'utilityService', 'htmlToPdfGenerationService', PCICompliantPaymentController]);

    function PCICompliantPaymentController($scope, $rootScope, $q, $state, $sce, PCICompliantPaymentService, utilityService, htmlToPdfGenerationService) {
        var _key = sessionStorage.Key;
        var _childName = '';

        $scope.userId = sessionStorage.UserID;
        $scope.queueName = (!!$state.current && !!$state.current.routeData) ? $state.current.routeData.queueType : '';

        var getNewTransaction = function () {
            var transaction = {};
            transaction.ApplicationId = $scope.applicationId;
            transaction.IndividualId = ($scope.entityType == 'I') ? $scope.entityId : null;
            transaction.ProviderId = ($scope.entityType == 'P') ? $scope.entityId : null;
            transaction.AmountDue = $scope.feeDetails.totalFee;
            transaction.TotalPaidAmount = $scope.feeDetails.totalFee;
            transaction.InvoiceNo = $scope.invoiceId;
            transaction.UserDefinedPaymentNo = "";
            transaction.TransCode = "";

            transaction.EmailAddress = $scope.emailAddress;
            if (!$scope.isBackoffice) {
                transaction.PaymentSource = "OL";
                transaction.RequiresApproval = false;
                transaction.MailingPayment = false;
            } else {
                if ($scope.isConfirmPayment) {
                    transaction.PaymentSource = "BC";
                } else {
                    transaction.PaymentSource = "BO";
                }
                transaction.RequiresApproval = false;
                transaction.MailingPayment = false;
            }

            transaction.PaymentMode = "CC";
            transaction.PaymentModeNumber = "";
            //This info is typically determined by the CC form, which we will not be managing
            // transaction.CardType = detectCardType($scope.payment.CardNumber);
            // transaction.CardNumber = $scope.payment.CardNumber;
            // transaction.ExpirationMonths = $scope.payment.ExpirationMonths;
            // transaction.ExpirationYears = $scope.payment.ExpirationYears;
            // transaction.CVV = $scope.payment.CVV;
            // transaction.FirstName = $scope.payment.FirstName;
            // transaction.LastName = $scope.payment.LastName;
            // transaction.AddressStreetLine1 = $scope.payment.Address;
            // transaction.AddressCity = $scope.payment.City;
            // transaction.AddressStateCode = $scope.payment.StateCode;
            // transaction.AddressState = angular.element("#ddlState option[value='" + $scope.payment.StateCode + "']").text();
            // transaction.AddressZip = $scope.payment.Zip;
            // transaction.AddressCountry = $scope.payment.Country;
            // transaction.PhoneNumber = (($scope.personalData.CellPhoneContact) ? $scope.personalData.CellPhoneContact.ContactInfo : (($scope.personalData.HomePhoneContact) ? $scope.personalData.HomePhoneContact.ContactInfo : '')); //vm.appData.Applicant.PrimaryPhone.ContactInfo; //LeftPArt
            transaction.PaymentInitiateSubmitMode = "OL";
            transaction.PaymentCompletionSubmitMode = "OL";
            transaction.ElectronicPayment = true;
            transaction.ManualPayment = false;
            transaction.TransCode = 'PCI'; //transaction.CardType;

            return transaction;
        };

        var makeFormReadOnly = function (isReadOnly) {

        };

        $scope.getUIConfig = function () {
            var config = {};

            for (var prop in $scope.config.UIConfigs['Default']) {
                if (!$scope.config.UIConfigs['Default'].hasOwnProperty(prop)) {
                    continue;
                }

                config[prop] = $scope.config.UIConfigs['Default'][prop];
            }

            if (!!$scope.config.UIConfigs[$scope.configName]) {
                for (var prop in $scope.config.UIConfigs[$scope.configName]) {
                    if (!$scope.config.UIConfigs[$scope.configName].hasOwnProperty(prop)) {
                        continue;
                    }

                    config[prop] = $scope.config.UIConfigs[$scope.configName][prop];
                }
            }

            return config;
        };

        $scope.getServerConfig = function () {
            var deferred = $q.defer();

            try {
                var promises = [];

                $scope.uiConfig.ConfigurationSettings.forEach(function (setting) {
                    promises.push(PCICompliantPaymentService.getConfigurationBySetting(sessionStorage.Key, setting.SettingName));
                });

                $q.all(promises)
                    .then(function (data) {
                        if (!!data && data.length > 0) {
                            data.forEach(function (response) {
                                if (response.Status) {
                                    var setting = response.ConfigurationList.firstOrDefault();
                                    var propName = $scope.uiConfig.ConfigurationSettings.whereEquals(setting.Setting, 'SettingName').select('PropertyName').firstOrDefault();

                                    $scope.serverConfig[propName] = setting.Value;   
                                }
                            });

                            if (!!$scope.onServerConfigLoaded) {
                                $scope.onServerConfigLoaded();
                            }
                        }

                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getConfig = function () {
            var deferred = $q.defer();

            try {
                utilityService.getConfigData('/core/directive/pci-compliant-payment/directive/pci-compliant-payment.server-config.json')
                    .then(function (data) {
                        if (!!data) {
                            $scope.config = data;

                            $scope.uiConfig = $scope.getUIConfig();

                            $scope.isConfigLoaded = true;

                            if (!!$scope.onConfigLoaded) {
                                $scope.onConfigLoaded();
                            }
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getContacts = function () {
            var deferred = $q.defer();

            try {
                PCICompliantPaymentService.getContacts(sessionStorage.Key, $scope.entityId, $scope.entityType)
                    .then(function (data) {
                        if (data.Status) {
                            if (!!data.IndividualContactResponse) {
                                $scope.allContacts = data.IndividualContactResponse;

                                $scope.emailAddress = $scope.allContacts.whereEquals($scope.uiConfig.ContactTypeIds.Individual.Email, 'ContactTypeId').whereEquals(1, 'ContactStatusId').select('ContactInfo').firstOrDefault();
                                $scope.phoneNumber = $scope.allContacts.whereEquals($scope.uiConfig.ContactTypeIds.Individual.Phone, 'ContactTypeId').whereEquals(1, 'ContactStatusId').select('ContactInfo').firstOrDefault();
                            } else if (!!data.ProviderContacts) {
                                $scope.allContacts = data.ProviderContacts;

                                $scope.emailAddress = $scope.allContacts.whereEquals($scope.uiConfig.ContactTypeIds.Provider.Email, 'ContactTypeId').whereEquals(1, 'ContactStatusId').select('ContactInfo').firstOrDefault();
                                $scope.phoneNumber = $scope.allContacts.whereEquals($scope.uiConfig.ContactTypeIds.Provider.Phone, 'ContactTypeId').whereEquals(1, 'ContactStatusId').select('ContactInfo').firstOrDefault();
                            }
                            
                            if (!!$scope.onContactsLoaded) {
                                $scope.onContactsLoaded();
                            }
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getEntityInfo = function () {
            var deferred = $q.defer();

            try {
                PCICompliantPaymentService.getEntity(sessionStorage.Key, $scope.entityId, $scope.entityType)
                    .then(function (data) {
                        if (data.Status) {
                            if (!!data.IndividualResponse) {
                                $scope.entity = data.IndividualResponse.firstOrDefault();
                            } else if (!!data.ProviderResponse) {
                                $scope.entity = data.ProviderResponse;
                            }

                            if (!!$scope.onEntityLoaded) {
                                $scope.onEntityLoaded();
                            }
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getCurrentTime = function () {
            var deferred = $q.defer();

            try {
                PCICompliantPaymentService.getServerTime()
                    .then(function (data) {
                        $scope.currentServerTime = data;

                        if (!!$scope.onCurrentTimeLoaded) {
                            $scope.onCurrentTimeLoaded();
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getInvoiceMaster = function () {
            var deferred = $q.defer();

            try {
                PCICompliantPaymentService.getInvoiceMasters(sessionStorage.Key, $scope.entityId, $scope.entityType, $scope.applicationId)
                    .then(function (data) {
                        if (!!data.Status && !!data.InvoiceMasters) {
                            $scope.invoiceMaster = data.InvoiceMasters.firstOrDefault();
                            $scope.invoiceId = $scope.invoiceMaster.InvoiceID;

                            $scope.payment.InvoiceId = $scope.invoiceId;

                            if ($scope.invoiceMaster.InvoiceStatus == 'CLOSED') {
                                makeFormReadOnly(true);
                            }
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getInvoiceDetails = function () {
            var deferred = $q.defer();

            try {
                PCICompliantPaymentService.getInvoiceDetails(sessionStorage.Key, $scope.entityId, $scope.entityType, $scope.invoiceId)
                    .then(function (data) {
                        if (!!data.Status && !!data.InvoiceDetails) {
                            $scope.invoiceDetails = $scope.getInvoiceDetailEntries(data.InvoiceDetails);
                        } else {
                            $scope.invoiceDetails = $scope.getInvoiceDetailEntries([]);
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.saveInvoiceDetails = function () {
            var deferred = $q.defer();

            try {
                PCICompliantPaymentService.saveInvoiceDetails(sessionStorage.Key, $scope.invoiceDetails)
                    .then(function () {
                        $scope.getInvoiceDetails()
                            .then(function (data) {
                                deferred.resolve(data);
                            }, function (err) {
                                deferred.reject(err);
                            });
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.disableLeavingWarning = function () {
            window.onbeforeunload = null;
        };

        $scope.init = function () {
            $scope.current = {};
            $scope.uiLogic = {};
            $scope.pager = {};
            $scope.content = {};
            $scope.serverConfig = {};
            $scope.payment = {
                feeDetails: $scope.feeDetails
            };

            $scope.isLoading = true;
            $scope.disablePayment();

            $scope.getConfig()
            .then(function () {
                $q.all([
                        $scope.getServerConfig(),
                        $scope.getContacts(),
                        $scope.getEntityInfo(),
                        $scope.getInvoiceMaster()
                    ])
                    .then(function () {
                        //Now get and process in the invoice details
                        $scope.getInvoiceDetails()
                            .then(function () {
                                $scope.isLoading = false;
                                $scope.enablePayment();
                                if (!!$scope.operationPending) {
                                    $scope.operationPending(false);
                                }
                            }, function (err) {
                                console.log(err);
                                $scope.isLoading = false;
                                $scope.enablePayment();
                                if (!!$scope.operationPending) {
                                    $scope.showStatusMsg('-', err);
                                    $scope.operationPending(false);
                                }
                            });
                    }, function (err) {
                        $scope.isLoading = false;
                        $scope.enablePayment();
                        if (!!$scope.operationPending) {
                            $scope.showStatusMsg('-', err);
                            $scope.operationPending(false);
                        }
                    });
            }, function (err) {
                $scope.isLoading = false;
                $scope.enablePayment();
                if (!!$scope.operationPending) {
                    $scope.showStatusMsg('-', err);
                    $scope.operationPending(false);
                }
            });

            if (!!$scope.initExt) {
                $scope.initExt();
            }
        };
 
        $scope.trustPostAction = function (url) {
            return $sce.trustAsResourceUrl(url);
        };

        $scope.generateHtmlForApplication = function () {
            var deferred = $q.defer();

            htmlToPdfGenerationService.processHtmlNewApplication(null, $scope.addlCssForPdf).then(function (response) {
                var paymentHtml = angular.element('#tabSectionfeeAndPayment').parent();
                var panelPayment = angular.element(paymentHtml).find('#pnlPayment')[0];
                if (!!panelPayment && !!panelPayment.remove)
                    panelPayment.remove();

                $scope.paymentRequest.PaymentAndSubmissionEmailDetail.TabHtml = [{
                    ApplicationID: $scope.applicationId,
                    IndividualID: ($scope.entityType == 'I') ? $scope.entityId : null,
                    ProviderId: ($scope.entityType == 'P') ? $scope.entityId : null,
                    TabName: ((!!$scope.queueName) ? $scope.queueName + '-' : 'IntakeQ-') + ($scope.isMiscPayment ? 'Miscellaneous' : 'NewApplicationOuterPartHtml'),
                    CreatedBy: $scope.userId,
                    DataDesc: $scope.isMiscPayment ? 'Miscellaneous Payment' : "New Application Outer Part Html for All Tabs including the css and all sections",
                    HTMLContent: response,
                    SortingOrder: 0
                }, {
                    ApplicationID: $scope.applicationId,
                    IndividualID: ($scope.entityType == 'I') ? $scope.entityId : null,
                    ProviderId: ($scope.entityType == 'P') ? $scope.entityId : null,
                    TabName: ((!!$scope.queueName) ? $scope.queueName + '-' : 'IntakeQ-') + ('feeAndPayment'),
                    CreatedBy: $scope.userId,
                    DataDesc: $scope.isMiscPayment ? 'Miscellaneous Payment' : "New Application section feeAndPayment Html",
                    HTMLContent: angular.element(paymentHtml).html(),
                    SortingOrder: 100
                }];

                if (!!$scope.onHtmlGenerated) {
                    $scope.onHtmlGenerated();
                }

                deferred.resolve();
            });

            return deferred.promise;
        };

        $scope.postPaymentForm = function () {
            if ($scope.uiConfig.PostingMethod == 'form') {
                if (!!$scope.onRedirecting) {
                    $scope.onRedirecting();
                }

                var ccForm = angular.element($scope.uiConfig.FormElementId);
                ccForm.attr('method', $scope.uiConfig.FormMethod);
                ccForm.attr('action', $scope.uiConfig.FormAction);
                ccForm.submit();
            } else if ($scope.uiConfig.PostingMethod == 'json') {
                var deferred = $q.defer();

                if (!!$scope.jsonPaymentPostMethod) {
                    $scope.jsonPaymentPostMethod()
                        .then(function (data) {
                            deferred.resolve(data);
                        }, function (err) {
                            deferred.reject(err);
                        });
                }

                return deferred.promise;
            }
        };

        $scope.beginPaymentProcess = function () {
            $scope.uiLogic.paymentProcessing = true;

            if (!!$scope.operationPending) {
                $scope.operationPending(true, 'Processing...');
            }
            

            $scope.saveInvoiceDetails()
                .then(function () { 
                    $scope.transaction = getNewTransaction();

                    if (!!$scope.onTransactionCreated) {
                        $scope.onTransactionCreated();
                    }

                    $scope.paymentRequest = {
                        PaymentRequests: [$scope.transaction],
                        LicenseApplicationDetails: null,
                        PaymentAndSubmissionEmailDetail: {
                            ProcessEmailAndApplicationPdf: true,
                            EmailAddress: $scope.emailAddress,
                            PhoneNumber: $scope.phoneNumber,
                            IndividualId: ($scope.entityType=='I') ? $scope.entityId : null,
                            ProviderId: ($scope.entityType=='P') ? $scope.entityId : null,
                            ApplicationId: $scope.applicationId,
                            ApplicationTypeId: (!!$scope.applicationInfo) ? $scope.applicationInfo.ApplicationTypeId : 0,
                            TotalApplicationFee: $scope.feeDetails.totalFee,
                            ApplicationFees: $scope.feeDetails.applicationFee,
                            ReInstatementFee: $scope.feeDetails.reInstatementFee,
                            AdditionalApplicationFees: 0, //$scope.payment.FeeForAdditional,
                            LateFee: $scope.feeDetails.lateFee,
                            IsConfirm: (!$scope.isConfirmPayment),
                            IsSendAttachment: false,
                            ActionFlag: ($scope.feeDetails.isNewLicEndApp ? 'isNewLicEndApp' : ''),
                            DoNotSendPaymentReceipt: $scope.doNotSendReceipt,
                            QueueName: $scope.queueName,
                            FirstName: $scope.entity.FirstName,
                            LastName: $scope.entity.LastName,
                            Provider: $scope.entity.ProviderName,
                            IsMiscPayment: (!!$scope.isMiscPayment),
                            IsNewApplication: (!$scope.isRenewal || $scope.feeDetails.isNewLicEndApp),
                            PaymentHtmlProcessor: "MultiTransaction",
                            DoNotSendSubmissionSms: (!!$scope.isRenewal),
                            SubmissionEmailTemplateId: ((!!$scope.isRenewal) ? 24 : null),
                            PhoneContactTypeId: ($scope.entityType == 'I') ? $scope.uiConfig.ContactTypeIds.Individual.Phone : $scope.uiConfig.ContactTypeIds.Provider.Phone,
                            EmailContactTypeId: ($scope.entityType == 'I') ? $scope.uiConfig.ContactTypeIds.Individual.Email : $scope.uiConfig.ContactTypeIds.Provider.Email
                        }
                    };

                    if (!!$scope.onPaymentRequestCreated) {
                        $scope.onPaymentRequestCreated();
                    }

                    if (!!$scope.generateAppHtml) {
                        $scope.generateHtmlForApplication()
                            .then(function () {
                                //Preserve payment request from this directive to the result page via sessionStorage
                                sessionStorage.paymentRequest = JSON.stringify($scope.paymentRequest);
                                sessionStorage.ApplicationId = $scope.applicationId;
                                sessionStorage.ApplicationTypeId = (!!$scope.applicationInfo) ? $scope.applicationInfo.ApplicationTypeId : 0;
                                sessionStorage.paymentSourcePage = $state.current.name;

                                var promise = $scope.postPaymentForm();

                                if (!!promise) {
                                    promise
                                        .then(function () {
                                            if (!!$scope.operationPending) {
                                                $scope.operationPending(false);
                                            }
                                        }, function (err) {
                                            $scope.uiLogic.paymentProcessing = false;
                                            if (!!$scope.operationPending) {
                                                $scope.showStatusMsg('-', err);
                                                $scope.operationPending(false);
                                            }
                                        });
                                } else {
                                    if (!!$scope.operationPending) {
                                        $scope.operationPending(false);
                                    }
                                }

                                if (!!$scope.onPosting) {
                                    $scope.onPosting();
                                }
                            }, function (err) {
                                $scope.uiLogic.paymentProcessing = false;
                                if (!!$scope.operationPending) {
                                    $scope.showStatusMsg('-', err);
                                    $scope.operationPending(false);
                                }
                            });
                    } else {
                        //Preserve payment request from this directive to the result page via sessionStorage
                        sessionStorage.paymentRequest = JSON.stringify($scope.paymentRequest);
                        sessionStorage.ApplicationId = $scope.applicationId;
                        sessionStorage.ApplicationTypeId = (!!$scope.applicationInfo) ? $scope.applicationInfo.ApplicationTypeId : 0;
                        sessionStorage.paymentSourcePage = $state.current.name;

                        var promise = $scope.postPaymentForm();

                        if (!!promise) {
                            promise
                                .then(function () {
                                    if (!!$scope.operationPending) {
                                        $scope.operationPending(false);
                                    }
                                }, function (err) {
                                    $scope.uiLogic.paymentProcessing = false;
                                    if (!!$scope.operationPending) {
                                        $scope.showStatusMsg('-', err);
                                        $scope.operationPending(false);
                                    }
                                });
                        } else {
                            if (!!$scope.operationPending) {
                                $scope.operationPending(false);
                            }
                        }

                        if (!!$scope.onPosting) {
                            $scope.onPosting();
                        }
                    }
                }, function (err) {
                    $scope.uiLogic.paymentProcessing = false;
                    if (!!$scope.operationPending) {
                        $scope.showStatusMsg('-', err);
                        $scope.operationPending(false);
                    }
                });
        };

        $scope.disablePayment = function () {
            $scope.paymentEnabled = false;
            $scope.$emit('enablePayment', false);
        };

        $scope.enablePayment = function () {
            $scope.paymentEnabled = true;
            $scope.$emit('enablePayment', true);
        };

        $scope.$on('beginPCICompliantPayment', function () {
            if (!$scope.isLoading) {
                $scope.beginPaymentProcess();
            }
        });
    }
})();