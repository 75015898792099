(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintDocuments', [ComplaintDocuments]);

    function ComplaintDocuments() {
        return {
            restrict: 'E',
            scope: {
                label: '=',
                complaint: '=?',
                complaintInvolvedPartyId: '=?',
                linkType: '=?',
                linkId: '=?',
                docId: '=?',
                docCode: '=?',
                entityId: '=?',
                entityType: '=?',
                applicationId: '=?',
                readOnly: '=?',
                externalSave: '=?',
                newDocumentsList: '=?',
                useExternalForm: '=?',
                uploadFormName: '=?',
                showUploadSection: '=?',
                allowEdit: '=?',
                allowDelete: '=?',
                onlyLoadPublic: '=?',
                maxFileSize: '@?',
                configName: '@?',
                autoSelectFirst: '=?'
            },
            link: function ($scope, element, attrs) {
                $scope.forms = {};

                if (!$scope.useExternalForm || !$scope.uploadFormName) {
                    $scope.uploadFormName = 'docUploadForm';
                    //The forms.uploadForm will be set when the html fully loads
                } else if (!!$scope.useExternalForm && !!$scope.uploadFormName) {
                    $scope.forms.uploadForm = element.closest('form[name="{0}"]'.format($scope.uploadFormName)).inheritedData('$formController');
                }
            },
            templateUrl: 'app/components/backoffice/enforcement/complaint-documents/complaint-documents.html',
            controller: 'ComplaintDocumentsController'
        };
    }
})();
