(function () {
    'use strict';

    angular
        .module('natApp')
        .controller("firmucLicenseRenewalApplicationController", firmucLicenseRenewalApplicationController);

    firmucLicenseRenewalApplicationController.$inject = ["$scope", "$timeout", "$rootScope", "initialApplicationstatusService", "deficiencyService", "BackofficeProviderService", "providerAddressService", "providerContactService",
        "htmlToPdfGenerationService", "LicenseRenewalHASnAService", "utilityService", "typeValuesService", "licenseRenewalService", "licenseApplicationListingService",
        "$q", "$state", "TypeValue", "$uibModal", "initialApplicationAcknowledgementService", "licenseOutputService", "Messages", "dcaIService", "ApplicationSettings", "backofficeProviderLicenseService"
    ];

    function firmucLicenseRenewalApplicationController($scope, $timeout, $rootScope, initialApplicationstatusService, deficiencyService, BackofficeProviderService, providerAddressService, providerContactService,
        htmlToPdfGenerationService, LicenseRenewalHASnAService, utilityService, typeValuesService, licenseRenewalService, licenseApplicationListingService,
        $q, $state, TypeValue, $uibModal, initialApplicationAcknowledgementService, licenseOutputService, Messages, dcaIService, ApplicationSettings, backofficeProviderLicenseService) {

        var curDate = null;
        sessionStorage.PaymentMethodType = "";
        $scope.PersonalInfo = {};
        $scope.isRenewal = true;
        $scope.additionalCss = ['app/content/css/processHtmltoPdf.css'];
        $scope.renewalInfoHtmlContent = null;
        $scope.acknowledgmentAndDeclarationHtmlContent = null;
        $scope.paymentHtmlContent = null;
        $scope.CEHtmlContent = null;
        $scope.personalHtmlContent = null; 
        $scope.providerId = sessionStorage.ProviderId;
        var LoggedUserKey = sessionStorage.Key;
        
        $scope.init = function () {
            $scope.DisplayApplication = false;
            $scope.providerId = sessionStorage.ProviderId;

            try {
                if (sessionStorage.PaymentDone == "true") {
                    $state.go('app.FirmLogin');
                }
                if ($scope.isUserSessionActive()) {
                    curDate = sessionStorage.curDate;
                    $scope.AckAppliciantDate = curDate;
                    $scope.getProviderViewConfig();
                } else {
                    $state.go('app.FirmLogin');
                }
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };

        $scope.deleteApplicationAndMoveToDashboard = function (applicationInfo) {
            var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'deleteApplication.html',
                controller: 'ExtendLicensePreviewController',
                size: 'lg',
                resolve: {
                    contentInput: {
                        contentTypeId: 98,
                        headerForPopup: "Delete Application"
                    }
                }
            });

            modalInstance.result.then(function () {
                ShowLoader();
                licenseRenewalService.individualTablesDelete(sessionStorage.Key,
                    sessionStorage.ProviderId, applicationInfo.ApplicationId, false)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $state.go("app.Dashboard");
                        }
                    }, function (data) {
                        HideLoader();
                        if (data != null) {
                            $scope.showStatusMessage(data.message, "error");
                        }
                    });
            }, function () {
                console.log("Not Extension");
                return;
            });
        };

        var processTabsToBeShownForDeficiency = function () {
            var allTabs = $scope.providerViewConfig.processedTabs;
            var processedTabs = [];
            angular.forEach(allTabs, function (thisTab) {
                // if (!thisTab.hideInDeficiencyProcessing) {
                    processedTabs.push(thisTab);
                // }
            });

            $scope.grabHTMLElements();

            $scope.processHiddenTabs();
        };

        $scope.processHiddenTabs = function () {
            var allTabs = $scope.providerViewConfig.processedTabs;
            var processedTabs = [];
            angular.forEach(allTabs, function (thisTab) {
                if (!thisTab.hideInDeficiencyProcessing) {
                    processedTabs.push(thisTab);
                }
            });
            $scope.providerViewConfig.processedTabs = processedTabs;
        };

        $scope.$on('processTabsToBeShown', function (event, data) {
            processTabsToBeShown(data.processTabsToBeShown);
        });


        var processTabsToBeShown = function (hideShareholderTab) {
            var processedTabs = [];
            $scope.validator = {};
            $scope.providerViewConfig.paymentTabNumber = 0;

            angular.forEach($scope.providerViewConfig.renewalConfig.tabs, function (tabToShow) {
                if(tabToShow.name ==  "partnershipInfo" && hideShareholderTab == true) {
                    tabToShow.isEnabled = false;
                }
                else if (tabToShow.name ==  "shareholderInfo" && hideShareholderTab == false)
                {
                     tabToShow.isEnabled = false;
                }
            });


            angular.forEach($scope.providerViewConfig.renewalConfig.tabs, function (renewalTab) {
                if (renewalTab.isEnabled && (($scope.isBackoffice && !renewalTab.hideBackOffice) || (!$scope.isBackoffice && !renewalTab.hideOnline))) {
                    if (renewalTab.queues.indexOf($rootScope.routeData.queueType) != -1) {
                        if (renewalTab.isInitialTab == true)
                            renewalTab.showAfterRenewalTabs = true;
                        processedTabs.push(renewalTab);
                        if (!!renewalTab.isPaymentTab) {
                            $scope.providerViewConfig.paymentTabNumber = (processedTabs.length + 1);
                        }
                    }
                }
                $scope.validator[renewalTab.name] = {};
            });
            $scope.providerViewConfig.processedTabs = processedTabs;
        };

        $scope.processTabsToBeShownAsPerGroup = function () {
            angular.forEach($scope.providerViewConfig.processedTabs, function (renewalTab) {
                renewalTab.isEnabled = false;
                renewalTab.showAfterRenewalTabs = false;
                angular.forEach($scope.renewalCheckData.renewalGroups, function (renewalGroup) {
                    if (renewalTab.renewalGroups.indexOf(renewalGroup) != -1) {
                        renewalTab.isEnabled = true;
                        renewalTab.showAfterRenewalTabs = true;
                    }
                });
                if (!!$scope.currentRenewalItem && !!$scope.currentRenewalItem.ApplicationReviewReasonIds) {
                    if (renewalTab.name == 'historyDisclosure') {
                        if ($scope.currentRenewalItem.ApplicationReviewReasonIds.indexOf('1') != -1) {
                            renewalTab.showVerificationIcon = true;
                        }
                    } else if (renewalTab.name == 'personalData') {
                        if ($scope.currentRenewalItem.ApplicationReviewReasonIds.indexOf('3') != -1) {
                            renewalTab.showVerificationIcon = true;
                        }
                    } else if (renewalTab.name == 'renewaltype') {
                        if ($scope.currentRenewalItem.ApplicationReviewReasonIds.indexOf('4') != -1) {
                            renewalTab.showVerificationIcon = true;
                        }
                    }
                }
            });
        };

        $scope.getProviderViewConfig = function () {
            if ($scope.isUserSessionActive()) {
                var relativeFilePath = "/components/provider/application/firm-application.server-config.json";
                utilityService.getConfigData(relativeFilePath)
                    .then(function (data) {
                        // console.log("personal check", data);
                        $scope.providerViewConfig = data;
                        console.log('view',$scope.providerViewConfig);
                        
                        $scope.listConfig.providerViewConfig = $scope.providerViewConfig;
                        //processTabsToBeShown();
                        $scope.getInitData();
                    }, function (error) {

                    });
            } else {
                $state.go('app.BackofficeLoginstaff')
            }
        };

        $scope.openNextTab = function (currentTab, donotProcessLoader) {
            var feePaymentTabEnable = true;
            for (var i = 0; i < $scope.providerViewConfig.processedTabs.length; i++) {
                if ($scope.providerViewConfig.processedTabs[i].name == currentTab.name) {
                    var dataPromises = [];
                    dataPromises.push($scope.saveTabStatus(currentTab));
                    //dataPromises.push($scope.saveTabHtml(currentTab, currentTab.tabNumber));
                    if (!donotProcessLoader)
                        ShowLoader();
                    $q.all(dataPromises)
                        .then(function (response) {
                            $scope.providerViewConfig.processedTabs[i].isSaved = true;
                            if (!donotProcessLoader)
                                HideLoader();

                            if (!!$scope.providerViewConfig.processedTabs[i].isEnabled && $scope.providerViewConfig.processedTabs[i].name != 'feeAndPayment' && !$scope.providerViewConfig.processedTabs[i].isSaved) {
                                feePaymentTabEnable = false;
                            }

                            for (var j = i + 1; j < $scope.providerViewConfig.processedTabs.length; j++) {
                                if (!!$scope.providerViewConfig.processedTabs[j].isEnabled && $scope.providerViewConfig.processedTabs[j].name != 'feeAndPayment' && !$scope.providerViewConfig.processedTabs[j].isSaved) {
                                    feePaymentTabEnable = false;
                                }
                                if (!!feePaymentTabEnable) {
                                    $scope.ProceedtoPaymentclick();
                                }
                            }

                            for (var j = i + 1; j < $scope.providerViewConfig.processedTabs.length; j++) {
                                if ($scope.providerViewConfig.processedTabs[j].isEnabled === true) {
                                    if (currentTab.name == "renewaltype") {
                                        $scope.processTabsToBeShownAsPerGroup();
                                    }
                                    $scope.setCurrentTab($scope.providerViewConfig.processedTabs[j]);
                                    break;
                                }
                            }
                        });
                    break;
                }
                else {
                    if (!!$scope.providerViewConfig.processedTabs[i].isEnabled && $scope.providerViewConfig.processedTabs[i].name != 'feeAndPayment' && !$scope.providerViewConfig.processedTabs[i].isSaved) {
                        feePaymentTabEnable = false;
                    }
                }
            }
        };

        $scope.saveTabStatus = function (tab) {
            var applicableQueueType = $rootScope.routeData.queueType;
            if (applicableQueueType == "DeficiencyQ" || applicableQueueType == "TechDeficiencyQ") {
                applicableQueueType = "IntakeQ";
            }
            var reqData = {
                ApplicationTabStatusId: tab.ApplicationTabStatusId,
                ApplicationId: $scope.applicationId,
                PageModuleId: 0,
                PageModuleTabSubModuleId: 0,
                PageTabSectionId: 0,
                providerId: $scope.providerId,
                ProviderId: $scope.providerId,
                TabName: (applicableQueueType + "-" + tab.name),
                ApplicationTabStatus: 1,
                SortOrder: 1,
                IsActive: 1,
                IsDeleted: 0,
                CreatedBy: $scope.userId,
                CreatedOn: new Date()
            };
            return licenseRenewalService.saveTabStatus(reqData, sessionStorage.Key);
        };

        $scope.saveTabHtml = function (tab, tabNumber) {
            var htmlContent = angular.element('#tabSection' + tab.name).parent().html();
            htmlContent = htmlContent.replace(/\<textarea /g, '<div class="textAreaReplaced"><div ').replace(/\<\/textarea>/g, '</div></div>');
            htmlContent = htmlContent.replace(/\n|\t/g, ' ');
            htmlContent = htmlContent.replace(/\>\s\s+\</g, '><');
            htmlContent = htmlContent.replace(/ng[-,a-z,A-Z,0-9,.]*="[a-z,A-Z,0-9,.,\s,(,),;,=,',!,$,{,},:,?,-]*"/g, '');
            htmlContent = htmlContent.replace(/ng-binding|ng-pristine|ng-untouched|ng-isolate-scope|ng-not-empty|ng-invalid|ng-invalid-date/g, '');
            var htmlContentElement = angular.element('<div>' + htmlContent + '</div>');
            var textAreaElements = angular.element(htmlContentElement).find('.textAreaReplaced');
            angular.forEach(textAreaElements, function (textAreaElement, index) {
                if (!!textAreaElement.firstChild && !!textAreaElement.firstChild.attributes && !!textAreaElement.firstChild.attributes.value && !!textAreaElement.firstChild.attributes.value.value)
                    textAreaElement.innerHTML = "<pre>" + textAreaElement.firstChild.attributes.value.value + "</pre>";
            });
            var reqData = {
                ApplicationID: $scope.applicationId,
                providerID: $scope.providerId,
                TabName: ($rootScope.routeData.queueType + "-" + tab.name),
                CreatedBy: $scope.userId,
                DataDesc: "New Application section " + tab.name + " Html",
                HTMLContent: angular.element(htmlContentElement).html(),
                SortingOrder: tabNumber
            };

            return htmlToPdfGenerationService.saveTabHtml(reqData, sessionStorage.Key);
        };

        var processIsDeficient = function (isInitialProcessing) {
            var isDeficient = false;
            var isDeficiencyResolved = true;
            var isDefeciencyReviewedByprovider = true;
            var isStaffDeficiencyExists = false;
            var onlystaffDeficiencyExists = true;

            $scope.providerViewConfig.processedTabs.forEach(function (tabData, j) {
                var isTabDeficient = false;
                var isTabDeficiencyResolved = true;
                var isTabDefeciencyReviewedByprovider = true;

                if(tabData.tabNumber > 10 &&  tabData.tabNumber < 17)
                {
                    tabData.providerDefeciency=  tabData.individualDefeciency;
                    tabData.providerDefeciency.providerDefeciencyDtlList = tabData.individualDefeciency.IndividualDefeciencyDtlList;
                    var isDefeciencyReviewedByIndividual = false;
                    var isTabDefeciencyReviewedByIndividual = false;
                }
                 

                if (!!tabData.providerDefeciency && !!tabData.providerDefeciency.providerDefeciencyDtlList && tabData.providerDefeciency.providerDefeciencyDtlList.length > 0) {
                    tabData.providerDefeciency.providerDefeciencyDtlList.forEach(function (deficiencyDtl, i) {
                        if (deficiencyDtl.IsDefecient && (!deficiencyDtl.ResolvedByStaff || deficiencyDtl.ResolvedByStaff.toLowerCase() != 'y')) {
                            isDeficient = true;
                            isTabDeficient = true;

                            if (!tabData.providerDefeciency.providerDefeciencyDtlList[i].IsDefeciencyResolved) {
                                onlystaffDeficiencyExists = false;
                                isDeficiencyResolved = false;
                                isTabDeficiencyResolved = false;
                            }
                            if (!tabData.providerDefeciency.providerDefeciencyDtlList[i].IsDefeciencyReviewedByIndividual && (!tabData.providerDefeciency.providerDefeciencyDtlList[i].ResolvedByStaff || tabData.providerDefeciency.providerDefeciencyDtlList[i].ResolvedByStaff.toLowerCase() != 'y')) {
                                isDefeciencyReviewedByprovider = false;
                                isTabDefeciencyReviewedByprovider = false;
                            }
                            if (!tabData.individualDefeciency.IndividualDefeciencyDtlList[i].IsDefeciencyReviewedByIndividual && (!tabData.individualDefeciency.IndividualDefeciencyDtlList[i].ResolvedByStaff || tabData.individualDefeciency.IndividualDefeciencyDtlList[i].ResolvedByStaff.toLowerCase() != 'y')) {
                                isDefeciencyReviewedByIndividual = false;
                                isTabDefeciencyReviewedByIndividual = false;
                            }
                        } else if (deficiencyDtl.IsDefecient && (!!deficiencyDtl.ResolvedByStaff && deficiencyDtl.ResolvedByStaff.toLowerCase() == 'y')) {
                            if (!tabData.individualDefeciency.IndividualDefeciencyDtlList[i].IsDefeciencyResolved) {
                                isStaffDeficiencyExists = true;
                            }
                            isDeficient = true;
                            if (!!$scope.isBackoffice)
                                isTabDeficient = true;
                            if (!!$scope.isBackoffice && !tabData.individualDefeciency.IndividualDefeciencyDtlList[i].IsDefeciencyResolved) {
                                isDeficiencyResolved = false;
                                isTabDeficiencyResolved = false;
                            }
                        }
                    });
                }
                tabData.isTabDeficient = isTabDeficient;
                tabData.isTabDeficiencyResolved = isTabDeficiencyResolved;
                // tabData.isTabDefeciencyReviewedByIndividual = isTabDefeciencyReviewedByIndividual;
                if (!$scope.isBackoffice && !!isInitialProcessing && ($scope.applicationInfo.DeficiencyStatusId == 1)) {
                    if (!tabData.isTabDeficient || !!tabData.isTabDeficiencyResolved) {
                        tabData.hideInDeficiencyProcessing = true;
                        if (tabData.name == "personalData") {
                            tabData.hideInDeficiencyProcessing = false;
                        }
                    }
                    //if (!tabData.isTabDeficient) { // && tabData.name != 'renewaltype') {
                    //    tabData.hideInDeficiencyProcessing = true;
                    //} else {
                    tabData.showAfterRenewalTabs = true;
                    //}
                }
            });

            if (!$scope.isBackoffice && !!isInitialProcessing && ($scope.applicationInfo.DeficiencyStatusId == 1)) {
                processTabsToBeShownForDeficiency();
            }
            $scope.providerViewConfig.isDeficient = isDeficient;
            $scope.providerViewConfig.isDeficiencyResolved = isDeficiencyResolved;
            // $scope.providerViewConfig.isDefeciencyReviewedByIndividual = isDefeciencyReviewedByIndividual;
            $scope.providerViewConfig.onlystaffDeficiencyExists = onlystaffDeficiencyExists;
            if (!!$scope.isBackoffice || (!$scope.isBackoffice && !!isInitialProcessing)) {
                $scope.providerViewConfig.isStaffDeficiencyExists = isStaffDeficiencyExists;
            }
        };

        $scope.$on("deficiencyChanged", function (event, args) {
            processIsDeficient();
        });

        $scope.$on("personalDataChanged", function (event, args) {
            processIsDeficient(true);
        });

        var getDeficiencyData = function (tab, savedDeficiencydata) {
            var tabDeficiencyData = tab.deficiencyData;
            var deficiencyObj = {};
            if (!savedDeficiencydata) {
                deficiencyObj = {
                    ApplicationId: $scope.applicationId,
                    ProviderId: $scope.providerId,
                    ProviderLicenseId: $scope.providerLicense.ProviderLicenseId,
                    WorkflowQueueName: "IntakeQ",
                    TabName: tab.name,
                    SortOrder: tab.tabNumber,
                    IsActive: true,
                    IndividualDefeciencyDtlList: []
                };
                angular.forEach(tabDeficiencyData, function (tabDeficiencyDataItem) {
                    deficiencyObj.IndividualDefeciencyDtlList.push({
                        ProviderId: $scope.providerId,
                        IsDefecient: false,
                        DefeciencyReasonId: tabDeficiencyDataItem.DeficiencyReasonId,
                        DefeciencyReasonText: tabDeficiencyDataItem.DeficiencyReasonMessage,
                        ResolvedByStaff: tabDeficiencyDataItem.ResolvedByStaff,
                        DeficiencyReasonTypeId: tabDeficiencyDataItem.DeficiencyReasonTypeId,
                        IsRecordBasedDeficiency: tabDeficiencyDataItem.IsRecordBasedDeficiency,
                        VisibleInQueueMenuIds: tabDeficiencyDataItem.VisibleInQueueMenuIds,
                        EditableInQueueMenuIds: tabDeficiencyDataItem.EditableInQueueMenuIds,
                        AdditionalText: tabDeficiencyDataItem.AdditionalText,
                        ExplanationRequired: tabDeficiencyDataItem.ExplanationRequired,
                        IsActive: true,
                    });
                })
            } else {
                deficiencyObj = savedDeficiencydata;
                var deficiencyDetails = []
                angular.forEach(tabDeficiencyData, function (tabDeficiencyDataItem) {
                    var deficiencyExists = false;
                    angular.forEach(deficiencyObj.IndividualDefeciencyDtlList, function (individualDefeciencyDtlItem) {
                        if (individualDefeciencyDtlItem.DefeciencyReasonId == tabDeficiencyDataItem.DeficiencyReasonId) {
                            deficiencyExists = true;
                            individualDefeciencyDtlItem.ResolvedByStaff = tabDeficiencyDataItem.ResolvedByStaff;
                            individualDefeciencyDtlItem.DeficiencyReasonTypeId = tabDeficiencyDataItem.DeficiencyReasonTypeId;
                            individualDefeciencyDtlItem.IsRecordBasedDeficiency = tabDeficiencyDataItem.IsRecordBasedDeficiency;
                            individualDefeciencyDtlItem.VisibleInQueueMenuIds = tabDeficiencyDataItem.VisibleInQueueMenuIds;
                            individualDefeciencyDtlItem.EditableInQueueMenuIds = tabDeficiencyDataItem.EditableInQueueMenuIds;
                            individualDefeciencyDtlItem.AdditionalText = tabDeficiencyDataItem.AdditionalText;
                            individualDefeciencyDtlItem.ExplanationRequired = tabDeficiencyDataItem.ExplanationRequired;
                            // individualDefeciencyDtlItem.name = tabDeficiencyDataItem.name;
                            deficiencyDetails.push(individualDefeciencyDtlItem);
                        }
                    });
                    if (!deficiencyExists) {
                        deficiencyDetails.push({
                            ProviderId: $scope.providerId,
                            IsDefecient: false,
                            DefeciencyReasonId: tabDeficiencyDataItem.DeficiencyReasonId,
                            DefeciencyReasonText: tabDeficiencyDataItem.DeficiencyReasonMessage,
                            ResolvedByStaff: tabDeficiencyDataItem.ResolvedByStaff,
                            DeficiencyReasonTypeId: tabDeficiencyDataItem.DeficiencyReasonTypeId,
                            IsRecordBasedDeficiency: tabDeficiencyDataItem.IsRecordBasedDeficiency,
                            VisibleInQueueMenuIds: tabDeficiencyDataItem.VisibleInQueueMenuIds,
                            EditableInQueueMenuIds: tabDeficiencyDataItem.EditableInQueueMenuIds,
                            AdditionalText: tabDeficiencyDataItem.AdditionalText,
                            ExplanationRequired: tabDeficiencyDataItem.ExplanationRequired,
                            IsActive: true,
                            IsDefeciencyResolved: false
                        });
                    }
                });
                deficiencyObj.IndividualDefeciencyDtlList = deficiencyDetails;
            }
            return deficiencyObj;
        };

        var getDeficiencyReasonDataforTab = function (tabItem) {
            var deficiencyData = [];
            angular.forEach($scope.deficiencyReasonData, function (deficiencyReason) {
                if (tabItem.tabNumber == deficiencyReason.PageModuleTabSubModuleId) {
                    deficiencyData.push(deficiencyReason);
                }
            });
            return deficiencyData;
        };

        $scope.getAllTabStatus = function () {
            var applicableQueueType = $rootScope.routeData.queueType;
            if (applicableQueueType == "DeficiencyQ" || applicableQueueType == "TechDeficiencyQ") {
                applicableQueueType = "IntakeQ";
            }
            licenseRenewalService.getAllTabStatus($scope.applicationId, $scope.providerId, sessionStorage.Key)
                .then(function (response) {
                    if (response.Status == true) {
                        $scope.tabStatusList = response.ProviderTabStatusList;
                        var deficiencyDataPromise = [
                            deficiencyService.getIndividualDeficiency(sessionStorage.Key, $scope.providerId, $scope.applicationId, ''),
                            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=tabdeficiencyreason"))
                            // backofficeIndividualPersonalService.individualBYIndividualId(sessionStorage.Key, $scope.providerid)
                        ];
                        $q.all(deficiencyDataPromise).then(function (responseData) {
                            var feePaymentTabEnable = true;
                            $scope.deficiencyReasonData = responseData[1].ReferenceEntities.whereEquals($scope.applicationInfo.ApplicationTypeId, 'ApplicationTypeId');
                            $scope.individualDetail = $scope.providerInfo;
                            // $scope.individualAffidavit = responseData[2].IndividualAffidavitResponseList;;
                            $scope.individualDefeciencyAllList = responseData[0].IndividualDefeciencyAllList;
                            for (var j = 0; j < $scope.providerViewConfig.processedTabs.length; j++) {
                                $scope.providerViewConfig.processedTabs[j].isSaved = $scope.tabStatusList.whereContains($scope.providerViewConfig.processedTabs[j].name, 'TabName').select('ApplicationTabStatus').firstOrDefault();
                                $scope.providerViewConfig.processedTabs[j].isStatusLoaded = true;
                                $scope.providerViewConfig.processedTabs[j].deficiencyData = getDeficiencyReasonDataforTab($scope.providerViewConfig.processedTabs[j]);
                                if (!!$scope.providerViewConfig.processedTabs[j].deficiencyData && $scope.providerViewConfig.processedTabs[j].deficiencyData.length > 0) {
                                    if (!!$scope.individualDefeciencyAllList) {
                                        for (var i = 0; i < $scope.individualDefeciencyAllList.length; i++) {
                                            if ($scope.individualDefeciencyAllList[i].TabName == $scope.providerViewConfig.processedTabs[j].name) {
                                                $scope.providerViewConfig.processedTabs[j].individualDefeciency = getDeficiencyData($scope.providerViewConfig.processedTabs[j], $scope.individualDefeciencyAllList[i]);
                                            }
                                        }
                                    }
                                    if (!$scope.providerViewConfig.processedTabs[j].individualDefeciency)
                                        $scope.providerViewConfig.processedTabs[j].individualDefeciency = getDeficiencyData($scope.providerViewConfig.processedTabs[j], null);
                                }
                            }
                            for (var j = 0; j < $scope.providerViewConfig.processedTabs.length; j++) {
                                if (!!$scope.providerViewConfig.processedTabs[j].isEnabled && $scope.providerViewConfig.processedTabs[j].name != 'feeAndPayment' && !$scope.providerViewConfig.processedTabs[j].isSaved) {
                                    feePaymentTabEnable = false;
                                }
                            }
                            if (!!feePaymentTabEnable) {
                                $scope.ProceedtoPaymentclick();
                            }
                            // processAffidavits();
                            processIsDeficient(true);
                            if (!!$scope.openPaymentTab) {
                                setTabByNumber($scope.providerViewConfig.paymentTabNumber);
                            } else {
                                if (utilityService.isLocalServerAndDebuggingEnabled()) {
                                    setTabByNumber(0);
                                } else {
                                    setTabByNumber(0);
                                }
                            }
                        });
                    }
                });
        };

        $scope.setCurrentTab = function (tab) {
            if (tab != null) {
                if (!$scope.isBackoffice && !!tab.openIfAllPreviousComplete) {
                    var isAlltabsComplete = true;
                    for (var i = 0; i < $scope.providerViewConfig.processedTabs.length; i++) {
                        if ($scope.providerViewConfig.processedTabs[i].name == tab.name) {
                            break;
                        }
                        if (!$scope.providerViewConfig.processedTabs[i].isSaved && $scope.providerViewConfig.processedTabs[i].isEnabled)
                            isAlltabsComplete = false;
                    }
                    if (!isAlltabsComplete) {
                        showStatusMessage(Messages.General.Errors.CompleteTab($scope.providerViewConfig.processedTabs[i].text), 'warning');
                        utilityService.scrollTop();
                        return;
                    }
                }

                var isAlltabsSaved = true;
                var isAlltabsSavedExceptPayment = true;
                for (var i = 0; i < $scope.providerViewConfig.processedTabs.length; i++) {
                    $scope.providerViewConfig.processedTabs[i].isActiveTab = false;
                    $scope.providerViewConfig.processedTabs[i].isActiveTab = false;
                    if (!$scope.providerViewConfig.processedTabs[i].isSaved && $scope.providerViewConfig.processedTabs[i].isEnabled) {
                        isAlltabsSaved = false;
                        if (!['feeAndPayment', 'staffReview'].includes($scope.providerViewConfig.processedTabs[i].name)) {
                            isAlltabsSavedExceptPayment = false;
                        }
                    }
                }

                $scope.providerViewConfig.isAlltabsSaved = isAlltabsSaved;
                $scope.providerViewConfig.isAlltabsSavedExceptPayment = isAlltabsSavedExceptPayment;
                $scope.individualCurrentTab = tab;
                if (tab) {
                    tab.isActiveTab = true;
                    tab.isLoadedOnce = true;
                }
                auditVisitInfoSave($scope.individualCurrentTab);
                $scope.canChangeTabs = true;
                $timeout(function () {
                    utilityService.scrollToElementOffset("applicationFormRenewal");
                }, 1000)
            }
        };

        var setTabByNumber = function (tabNumber) {
            if (!!$scope.providerViewConfig.processedTabs && $scope.providerViewConfig.processedTabs.length > tabNumber)
                $scope.setCurrentTab($scope.providerViewConfig.processedTabs[tabNumber]);
        };

        // var processAffidavits = function () {
        //     angular.forEach($scope.individualAffidavit, function (affidavitItem) {
        //         if (affidavitItem.ContentItemLkId == 125) {
        //             $scope.Acknowledgement = affidavitItem;
        //         }
        //     })
        // };

        
        $scope.DisplayCASLicensePrimaryStatus = async function(ApplicationInformation){
            ShowLoader();
            let requestData = {
                isn: ApplicationInformation,
            }
                try {
                    return await dcaIService.getCaLicFilePrimStatCde(sessionStorage.Key, requestData)
                        .then(function (response){
                        
                            if (!!response.EntityRecords && response.EntityRecords.length > 0) {
                                // return response.EntityRecords;
                                angular.forEach(response.EntityRecords, function (eachRecord) {
                                    if  (eachRecord.primStatCde.includes(13))
                                    {
                                        $scope.isRetiredOrDelinquent = true;
                                        $rootScope.isRetiredOrDelinquent = true;
                                    }
                                });
                            }
                            
                        }, function(data) {
                            $scope.showStatusMessage(data, "error");
                           
                        });
                } catch (ex) {
                    if (ex != null) {
                        $scope.showStatusMessage(ex.message, "error");
                    }
                }
            }

        $scope.individualRenewalGet = async function () {
            $scope.userId = (!$scope.isBackoffice ? sessionStorage.UserId : sessionStorage.UserID);
            $scope.showRenewalTypeEnabled = true;
            $scope.DisplayApplication = true;
            $scope.ShowRenewalApplicationHASnA = true;
            $scope.providerInfo = [];
            $scope.individualRenewal = [];
            $scope.providerData = {};
            $scope.applicationId = $scope.renewalCheckData.Application.ApplicationId;
            $scope.providerLicense = $scope.renewalCheckData.ProviderLicenseList[0];
            $scope.providerIdNumber = $scope?.providerLicense?.IDNumber;
            // await $scope.DisplayCASLicensePrimaryStatus($scope.providerIdNumber);
            $scope.applicationInfo = $scope.renewalCheckData.Application;
            $scope.isRetiredOrDelinquent = $rootScope.isRetiredOrDelinquent;
            $scope.getActiveLicense();
            var hideShareholderTab = false;
            if ($scope.providerLicense.LicenseTypeName == 'Corporation') {
                $rootScope.LicenseTypeName = 2;
                hideShareholderTab = true;
            }
            processTabsToBeShown(hideShareholderTab);
            $scope.licenseTypeGetAll();
            $scope.getAllTabStatus();
            //required

            // $scope.applicationInfo = $scope.renewalCheckData.Application;

            if (!!$scope.currentDeficiencyItemData) {
                $scope.currentDeficiencyItemData.ApplicationId = $scope.applicationInfo.ApplicationId;
                $scope.currentDeficiencyItemData.ApplicationStatusId = $scope.applicationInfo.ApplicationStatusId;
            }
            $scope.feeDetails = {
                licenseRenewalFee: 100,
                firstLicenseFee: 125,
                additionalLicenseFee: 75,
                certificateFee: 100,
                retiredWithWallFee: 20,
                retiredWithoutWallFee: 0,
                lateFee: 0
            };
            $scope.initializeAcknowledgement = true;
            $scope.initializeFeeDetails = true;
            processApplicationFeeAndRenewalData();
            if (($scope.isBackoffice && !!$scope.IsPendingApplication && !!$scope.DisplayAddApplication) || (!$scope.isBackoffice && $scope.renewalCheckData.Application.DeficiencyStatusId != 1)) {
                var renewalItem = {
                    ApplicationStatusId: $scope.renewalCheckData.Application.ApplicationStatusId,
                    ApplicationId: $scope.renewalCheckData.Application.ApplicationId,
                    ProviderId: $scope.renewalCheckData.ProviderId,
                    LicenseNumber: ((!!$scope.renewalCheckData && !!$scope.renewalCheckData.ProviderLicenseList && $scope.renewalCheckData.ProviderLicenseList.length > 0) ? $scope.renewalCheckData.ProviderLicenseList[0].ProviderLicenseNumber : '')
                };
                if (!$scope.currentRenewalItem)
                    $scope.currentRenewalItem = {};
                if ($scope.isBackoffice) {
                    if (renewalItem.ApplicationStatusId == 14 || renewalItem.ApplicationStatusId == 1 || renewalItem.ApplicationStatusId == 10) {
                        //Edit
                        $scope.IsPendingApplication = true;
                        renewalItem.disableSubmitControls = false;
                        renewalItem.disableRenewalForm = false;
                        renewalItem.DisableSaveButton = false;
                        renewalItem.DisableSubmitButton = false;
                        renewalItem.ShowPayAndApproveButton = true;
                        renewalItem.ShowConfirmAndApproveButton = true;
                        $scope.ProceedtoPaymentSectionEnable = false;
                        //"Save" ,"Pay Only","Pay and Approve", "Close"
                        //Form Not disabled

                    } else if (renewalItem.ApplicationStatusId == 2 || renewalItem.ApplicationStatusId == 13 || renewalItem.ApplicationStatusId == 15) {
                        //Verify
                        renewalItem.disableSubmitControls = false;
                        renewalItem.disableRenewalForm = true;
                        renewalItem.DisableSaveButton = true;
                        renewalItem.DisableSubmitButton = true;
                        renewalItem.ShowPayAndApproveButton = true;
                        renewalItem.ShowConfirmAndApproveButton = true;
                        $scope.ProceedtoPaymentSectionEnable = true;
                        //"Save" ,"Confirm And Approve", "Close"
                        //Form Not disabled
                    } else if (renewalItem.ApplicationStatusId == 3) {
                        //View
                        renewalItem.disableSubmitControls = true;
                        renewalItem.disableRenewalForm = true;
                        renewalItem.DisableSaveButton = true;
                        renewalItem.DisableSubmitButton = true;
                        renewalItem.ShowPayAndApproveButton = false;
                        renewalItem.ShowConfirmAndApproveButton = true;
                        $scope.ProceedtoPaymentSectionEnable = true;

                        //"Save"----Disabled ,"Confirm And Approve"----Disabled, "Close"
                        //Form disabled
                    }
                }
                $scope.currentRenewalItem = renewalItem;
                if ($scope.callCreatePendingRenewal)
                    $scope.createPendingRenewal();
                else
                    $scope.proceedToRenewalApp(false);
            } else {
                if (!$scope.isBackoffice && $scope.renewalCheckData.Application.DeficiencyStatusId == 1) {
                    $scope.ProceedtoPaymentSectionEnable = true;
                } 
                // else if(!$scope.isBackoffice && $rootScope.isRetiredOrDelinquent) {
                //     renewalItem.disableSubmitControls = false;
                //     renewalItem.disableRenewalForm = false;
                //     renewalItem.DisableSaveButton = false;
                //     renewalItem.DisableSubmitButton = false;
                //     renewalItem.ShowPayAndApproveButton = true;
                //     renewalItem.ShowConfirmAndApproveButton = true;
                //     $scope.ProceedtoPaymentSectionEnable = false;
                // }
                $scope.proceedToRenewalApp(false);
            }
        };

        $scope.setShowRenewalType = function (showRenewalTypeEnabled) {
            $scope.showRenewalTypeEnabled = showRenewalTypeEnabled;
        };

        $scope.proceedToRenewalApp = function (showRenewalTypeEnabled) {
            if (!showRenewalTypeEnabled) {
                $scope.providerId = $scope.renewalCheckData.ProviderId;
                $scope.applicationInfo = $scope.renewalCheckData.Application;
                $scope.applicationId = $scope.applicationInfo.ApplicationId;
                $scope.processTabsToBeShownAsPerGroup();
            }
            $scope.showRenewalTypeEnabled = showRenewalTypeEnabled;
        };

        $scope.licenseTypeGetAll = function () {
            try {
                LicenseRenewalHASnAService.LicenseTypeGetAll(sessionStorage.Key)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response.data)) {
                            $scope.LicenseTypeGetAll = response.data.LicenseTypeGetList;
                            for (var i in $scope.LicenseTypeGetAll) {
                                if ($scope.LicenseTypeGetAll[i].LicenseTypeId == $scope.providerLicense.ProviderLicenseTypeId) {
                                    $scope.providerLicense.LicenseTypeName = $scope.LicenseTypeGetAll[i].LicenseTypeName;
                                    $scope.LicenseTypeName = $scope.LicenseTypeGetAll[i].LicenseTypeName;
                                    sessionStorage.LicenseTypeName = $scope.LicenseTypeName;
                                }
                            }
                        }
                    }, function (data) {
                        if (data != null) {
                            showStatusMessage(data.message, "error");
                        }
                    });
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };

        $scope.IsAllTheSectionSaved = false;
        $scope.saveRecord = function (isSaveOnly) {
            $rootScope.ProcessLoaderExternally = true;
            initialApplicationstatusService.resetAll($scope.providerLicense, $scope.PayandSubmitbtnClicked);
            $rootScope.ProcessLoaderExternally = true;
            ShowLoader();
            $scope.$broadcast("SaveRenewal", {
                showConfirmationPopup: (!$scope.PayandSubmitbtnClicked && !isSaveOnly)
            });
            var requestData = {
                EntityId: $scope.providerId,
                EntityType: "I"
            };
            dcaIService.updateName(sessionStorage.Key, requestData).then(function (responseUpdate) {
                if (!responseUpdate.Status) {
                    $scope.showStatusMessage({
                        messageContent: 'Some error occured !',
                        messageType: 'error'
                    });
                }
            }, function (err) {
                $scope.showStatusMessage({
                    messageContent: 'Some error occured !',
                    messageType: 'error'
                });
            });
            var intervalDataSave = setInterval(function () {
                var allTabsProcessedStatus = initialApplicationstatusService.getAllTabsProcessedStatus();
                if (!!allTabsProcessedStatus) {
                    clearInterval(intervalDataSave);
                    var allTabsStatus = initialApplicationstatusService.getAllTabStatus();
                    $scope.IsAllTheSectionSaved = allTabsStatus;
                    if (allTabsStatus) {
                        if (!$scope.PayandSubmitbtnClicked) {
                            if (!isSaveOnly) {
                                $scope.ProceedtoPaymentclick();
                                HideLoader();
                            } else {
                                HideLoader();
                            }
                        } else {
                            if ($scope.isApplicationSubmitWithoutPayment) {
                                applicationStatusUpdate(13);
                            } else {
                                $scope.PayandSubmitTabRenewal();
                            }
                        }
                    } else {
                        HideLoader();
                        $scope.ProceedtoPaymentSectionEnable = false;
                        $('#applicationIncompleteModal').modal('show');
                    }
                }
            }, 500);
        };

        $scope.ProceedtoPaymentclick = function () {
            $scope.ProceedtoPaymentSectionEnable = true;
            //$scope.$apply();
        };

        $scope.PayandSubmitTabRenewal = function () {
            $scope.PayandSubmitbtnClicked = false;
            $scope.$broadcast("PayandSubmit", {});
            //Wait for payment submit and check whether payment is succeed 
            //There may be chance to payment failure.Only the form should submit only after payment success
            var paymentStatus = '';
            var interval = setInterval(function () {
                paymentStatus = initialApplicationstatusService.getPaymetStatus();
                if (!!paymentStatus && paymentStatus != "waiting") {
                    clearInterval(interval);
                    $rootScope.ProcessLoaderExternally = false;
                    if (paymentStatus == "success") {
                        if (!$scope.isBackoffice) {
                            $state.go('app.PaymentProviderConfirmation', {
                                entityId: $scope.providerId,
                                entityType: 'P',
                                applicationId: $scope.applicationInfo.ApplicationId,
                                applicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                                paymentMethodTypeId: $rootScope.SelectedPaymentMethod,
                                isReSubmit: false
                            });
                        } else {
                            $scope.getLicenseeRenewalList($scope.pagination.currentPage);
                        }
                    }
                    if (paymentStatus == "error") {
                        HideLoader();
                    }
                }
            }, 500);
        };

        $scope.payandSubmitbtnClick = function () {
            $rootScope.ProcessLoaderExternally = true;
            $scope.PayandSubmitbtnClicked = true;
            $scope.saveRecord();
        };

        $scope.$on("PayandSubmitTabRenewal", function (event, args) {
            $scope.PayandSubmitTabRenewal(false);
        });

        var applicationStatusUpdate = function (status) {
            ShowLoader();
            var applicationSubmitPromise = licenseApplicationListingService.applicationStatusUpdate(sessionStorage.Key, $scope.applicationInfo.ApplicationId, status, {});
            var applicationSubmitLkPromise = applicationStatusLkSave(sessionStorage.UserID, status);
            var applicationSubmitChangeLogPromise = applicationStatusChangeLogSaveData(sessionStorage.UserID, status);
            $q.all([applicationSubmitPromise, applicationSubmitLkPromise, applicationSubmitChangeLogPromise]).
                then(function (responseAppSubmitted) {
                    if (!$scope.isBackoffice) {
                        HideLoader();
                        $state.go('app.PaymentProviderConfirmation', {
                            entityId: $scope.providerId,
                            entityType: 'I',
                            applicationId: $scope.applicationInfo.ApplicationId,
                            applicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                            paymentMethodTypeId: '',
                            isReSubmit: false
                        });
                    }
                    $scope.applicationInfo.ApplicationStatusId = status;
                }, function (error) {
                    HideLoader();
                    $scope.isdisable = false;
                    showStatusMessage(
                        error,
                        'error'
                    );
                });
        };

        var applicationStatusLkSave = function (userId, applicationStatusId) {
            var reqData = {
                ApplicationId: $scope.applicationInfo.ApplicationId,
                ApplicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                ApplicationStatusId: applicationStatusId,
                IsActive: 1,
                CreatedBy: userId,
                HighPriority: false
            };

            return licenseApplicationListingService.applicationStatusLkSave(sessionStorage.Key, userId, reqData);
        };

        var applicationStatusChangeLogSaveData = function (userId, applicationStatusId) {
            var reqData = {
                ApplicationId: $scope.applicationInfo.ApplicationId,
                ApplicationNumber: $scope.applicationInfo.ApplicationNumber,
                ApplicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                ApplicationStatusId: applicationStatusId,
                ApplicationSubmitMode: 'B',
                LicenseTypeId: $scope.applicationInfo.LicenseTypeId
            };

            return licenseApplicationListingService.applicationStatusChangeLogSave(sessionStorage.Key, userId, reqData);
        };

        $scope.$on("ApplicationSubmitWithoutPayment", function (event, args) {
            $scope.isApplicationSubmitWithoutPayment = true;
            $scope.PayandSubmitbtnClicked = true;
            $scope.saveRecord();
        });

        $scope.grabHTMLElements = function () {
            
            $scope.personalHtml = angular.element('#tabSectionfirmData')[0]?.outerHTML;
            
            
            if (!$scope.renewalInfoHtmlContent) {
                $scope.renewalInfoHtml = angular.element('#tabSectionpeerReviewInfo').parent();
                $scope.renewalInfoHtmlContent = angular.element($scope.renewalInfoHtml).html();
                if (!!$scope.renewalInfoHtmlContent)
                $scope.renewalInfoHtmlContent = $scope.renewalInfoHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');
            }

            if (!$scope.acknowledgmentAndDeclarationHtmlContent) {
                $scope.acknowledgmentAndDeclarationHtml = angular.element('#tabSectionacknowledgmentAndDeclaration').parent();            
                $scope.acknowledgmentAndDeclarationHtmlContent = angular.element($scope.acknowledgmentAndDeclarationHtml).html();
                if (!!$scope.acknowledgmentAndDeclarationHtmlContent)
                $scope.acknowledgmentAndDeclarationHtmlContent = $scope.acknowledgmentAndDeclarationHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');
            }
            
            // if (!$scope.CEHtmlContent) {
                // $scope.CEHtml = angular.element('#tabSectioncontinuingeducation').parent();
                // $scope.CEHtmlContent = angular.element($scope.CEHtml).html();
                // if (!!$scope.CEHtmlContent)
                $scope.CEHtmlContent = angular.element('#tabSectionshareholderInfo')[0]?.outerHTML;
                $scope.CEHtmlContent = $scope.CEHtmlContent?.replace(new RegExp('app/content/images/', 'g'), '');
            // }
            
            if (!$scope.paymentHtmlContent) {
                $scope.paymentHtml = angular.element('#tabSectionfeeAndPayment').parent();
                $scope.paymentHtmlContent = angular.element($scope.paymentHtml).html();
                if (!!$scope.paymentHtmlContent)
                $scope.paymentHtmlContent = $scope.paymentHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');
            }
            
        }

        $scope.deficiencyStatusUpdate = function () {
            var defnotresolved = true;
            var defmarkedbystaff = 0;
            var defresolvedbyindividual = 0;
            if(!$scope.isBackoffice && !!$scope.providerViewConfig)
            {
                $scope.providerViewConfig.processedTabs.forEach(function (tabData, j) {
                    if(tabData.tabNumber > 10 &&  tabData.tabNumber < 17)
                    {
                        if (!!tabData.providerDefeciency && !!tabData.providerDefeciency.IndividualDefeciencyDtlList && tabData.providerDefeciency.IndividualDefeciencyDtlList.length > 0) {
                        
                            tabData.providerDefeciency.IndividualDefeciencyDtlList.forEach(function (deficiencyDtl, i) {
                                if(!!deficiencyDtl.IsDefecient && !!deficiencyDtl.IsDefeciencyReviewedByIndividual)
                                {
                                    defnotresolved = false;
                                    defmarkedbystaff = defmarkedbystaff+1;
                                    defresolvedbyindividual = defresolvedbyindividual+1;
                                }
                                else if(!!deficiencyDtl.IsDefecient && !deficiencyDtl.IsDefeciencyReviewedByIndividual)
                                {
                                    defnotresolved = true;
                                    defmarkedbystaff = defmarkedbystaff+1;
                                }
                            });
                        }
                    }

                });
            }
            $scope.isPaymentSubmitted = false;
            $scope.isConfirmPayment = false;
            $scope.transactionsData = false;
            if ($scope.providerViewConfig.isDeficient && !!defnotresolved && (defmarkedbystaff != defresolvedbyindividual)) {
                showStatusMessage(Messages.General.Errors.ResolveDef, "error");
                return;
            } else {
                $scope.$broadcast("SaveDeficiency", {
                    isSaveAllTabs: true
                });
                hideStatusMessage();
            }

            var data = {
                dataObj: {
                    EmailAddress: '',
                    ProviderId: $scope.providerId,
                    ApplicationId: $scope.applicationId,
                    ProcessEmailAndApplicationPdf: true,
                    PhoneNumber: '',
                    TotalApplicationFee: '',
                    ApplicationFees: '',
                    ReInstatementFee: '',
                    AdditionalApplicationFees: 0,
                    ApplicationFeeType: '',
                    LateFee: '',
                    IsConfirm: !!$scope.isConfirmPayment,
                    IsSendAttachment: false,
                    ActionFlag: '',
                    DoNotSendPaymentReceipt: '',
                    FirstName: '',
                    LastName: '',
                    IsMiscPayment: (!!$scope.isMiscPayment),
                    IsNewApplication: (!$scope.isRenewal),
                    PaymentHtmlProcessor: "",
                    LicenseNumber: $scope.providerLicense ? $scope.providerLicense.LicenseNumber : null,
                    LicenseTypeId: !!$scope.providerLicense ? $scope.providerLicense.LicenseTypeId : null,
                    QueueName: $scope.queueName
                },
 
            };

            var htmlToPdfDataPromise = htmlToPdfGenerationService.processHtmlNewApplication($scope.additionalCss);
            if ($scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 16) {
                htmlToPdfDataPromise = htmlToPdfGenerationService.processHtml($scope.additionalCss);
            }

            htmlToPdfDataPromise.then(function (response) {
                if ($scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 16) {
                    data.dataObj.TabHtml = [{
                        ApplicationID: $scope.applicationId,
                        ProviderID: $scope.providerId,
                        TabName: 'IntakeQ-RenewalHtml',
                        CreatedBy: $scope.userId,
                        DataDesc: "RenewalHtml",
                        HTMLContent: response,
                        SortingOrder: 1
                    }];
                } else {
                    $scope.IsPaymentSubmitted = false;
                    $scope.grabHTMLElements();
                    
                    if (!$scope.isConfirmPayment) {
                        data.dataObj.TabHtml = [{
                            ApplicationID: $scope.applicationId,
                            ProviderID: $scope.providerId,
                            TabName: 'IntakeQ-NewApplicationOuterPartHtml',
                            CreatedBy: $scope.userId,
                            DataDesc: "New Application Outer Part Html for All Tabs including the css and all sections",
                            HTMLContent: response,
                            SortingOrder: 0
                        }, {
                            ApplicationID: $scope.applicationId,
                            ProviderID: $scope.providerId,
                            TabName: 'IntakeQ-personal',
                            CreatedBy: $scope.userId,
                            DataDesc: "New Application section personal Html",
                            SortingOrder: 100,
                            HTMLContent: $scope.personalHtml
                        }, {
                            ApplicationID: $scope.applicationId,
                            ProviderID: $scope.providerId,
                            TabName: 'IntakeQ-renewalInfo',
                            CreatedBy: $scope.userId,
                            DataDesc: "New Application section renewalInfo Html",
                            HTMLContent: $scope.renewalInfoHtmlContent,
                            SortingOrder: 200
                        }, {
                            ApplicationID: $scope.applicationId,
                            ProviderID: $scope.providerId,
                            TabName: 'IntakeQ-CE',
                            CreatedBy: $scope.userId,
                            DataDesc: "New Application section CE Html",
                            HTMLContent: $scope.CEHtmlContent,
                            SortingOrder: 300
                        }, {
                            ApplicationID: $scope.applicationId,
                            ProviderID: $scope.providerId,
                            TabName: 'IntakeQ-acknowledgmentAndDeclaration',
                            CreatedBy: $scope.userId,
                            DataDesc: "New Application section acknowledgmentAndDeclaration Html",
                            HTMLContent: $scope.acknowledgmentAndDeclarationHtmlContent,
                            SortingOrder: 400
                        }, {
                            ApplicationID: $scope.applicationId,
                            ProviderID: $scope.providerId,
                            TabName: 'IntakeQ-feeAndPayment',
                            CreatedBy: $scope.userId,
                            DataDesc: "New Application section feeAndPayment Html",
                            HTMLContent: $scope.paymentHtmlContent,
                            SortingOrder: 500
                        }];
                    }

                }

                htmlToPdfGenerationService.saveUpdatedHtml(sessionStorage.Key, data.dataObj)
                    .then(function (response) {
                        console.log("Pdf generated ",response);
                    }, function (error) {
                        console.log("error",error);        
                    })

            });

            ShowLoader();
            var queueApprovalData = {
                ProcessDeficiency: true
            };
            if (!!$scope.providerViewConfig.isStaffDeficiencyExists) {
                queueApprovalData.DeficiencyStatusId = 2;
            } else {
                queueApprovalData.DeficiencyStatusId = 3;
            }
            saveApplicationStatusChangeLog($scope.applicationId, 37);
            queueApprovalData.ApplicationStatusId = $scope.applicationInfo.ApplicationStatusId;
            if (!!$scope.applicationInfo.DeficiencyStatusId && $scope.applicationInfo.DeficiencyStatusId == 1) {
                if (!!$scope.applicationInfo.ApplicationStatusId && $scope.applicationInfo.ApplicationStatusId == 43) {
                    queueApprovalData.ApplicationStatusId = 44;
                }
                if (!!$scope.applicationInfo.ApplicationStatusId && $scope.applicationInfo.ApplicationStatusId == 45) {
                    queueApprovalData.ApplicationStatusId = 44;
                }
            }

            initialApplicationstatusService.saveApplicationStatus($scope.applicationId, sessionStorage.Key, queueApprovalData.ApplicationStatusId, queueApprovalData)
                .then(function (response) {
                    if (response.Status == false) {
                        showStatusMessage(response.Message, "error");
                        HideLoader();
                        return;
                    }
                    HideLoader();

                    $state.go('app.PaymentProviderConfirmation', {
                        entityId: $scope.providerId,
                        entityType: 'I',
                        applicationId: $scope.applicationInfo.ApplicationId,
                        applicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                        paymentMethodTypeId: '',
                        isReSubmit: true
                    });
                }, function (data) {
                    showStatusMessage(error, "error");
                    HideLoader();
                });

        };

        var saveApplicationStatusChangeLog = function (applicationId, applicationStatus) {
            var reqData = {
                ApplicationId: applicationId,
                ApplicationStatusReasonId: 1,
                ApplicationNumber: '',
                ApplicationTypeId: ((!!$scope.applicationInfo) ? $scope.applicationInfo.ApplicationTypeId : 1),
                ApplicationStatusId: applicationStatus,
                ApplicationSubmitMode: ($scope.isBackoffice ? 'B' : 'O'),
                ApplicationStatusChangedBy: $scope.userId,
                ReferenceNumber: '',
                LicenseTypeId: 0,
                HighPriority: false,
                ApplicationInternalStatusId: 1,
                ApplicationInternalStatusReasonText: ''
            };
            initialApplicationstatusService.saveApplicationStatusChangeLog(sessionStorage.Key, $scope.userId, reqData)
                .then(function (response) {
                    if (applicationStatus == 2 && !$scope.isBackoffice && !($scope.IndividualRenewal.IndividualChildSupport.isAffirmativeAction || $scope.IndividualRenewal.IndividualLegal.isAffirmativeAction)) {
                        // saveApplicationStatusChangeLog(applicationId, 3)
                    }
                }, function (data) {
                    showStatusMessage(data, "error");
                });
        };

        function auditVisitInfoSave(tab) {
            var data = {
                PageName: (($scope.isBackoffice ? "Backoffice" : "Online") + " - " + ("Renewal Application - " + tab.text)),
                SessionId: sessionStorage.Key,
                ProviderId: $scope.providerId,
                EntityId: tab.tabNumber,
                UserId: $scope.userId,
                Username: "",
                RequestUrlReferrer: document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };

        $scope.paymentProviderSuccess = function (event, args) {
            $scope.applicationInfo.ApplicationStatusId = args.statusId;
            $scope.$emit('applicationStatusChanged', {
                ApplicationId: args.applicationId,
                ApplicationStatusId: args.statusId
            });
            if (!$scope.isBackoffice) {
                $state.go('app.PaymentProviderConfirmation', {
                    entityId: $scope.providerId,
                    entityType: 'P',
                    applicationId: $scope.applicationInfo.ApplicationId,
                    applicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                    paymentMethodTypeId: $rootScope.SelectedPaymentMethod,
                    isReSubmit: false
                });
            }
        };

        function initialProcessing() {
            angular.forEach($scope.renewalCheckData.ProviderLicenseList, function (licenseType) {
                if (licenseType.ProviderStatusTypeId == 1 || licenseType.ProviderStatusTypeId == 7 || licenseType.ProviderStatusTypeId == 8) {
                    licenseType.applicationType = 'active';
                    licenseType.currentApplicationType = 'active';
                }
            });
            // processApplicationFeeAndRenewalData();
        };

        var mapRefLicenseTypeAndFeeDetails = function () {
            $scope.renewalCheckData.ProviderLicenseList.map(function (individualLicenseType) {
                if (!!$scope.refLicenseTypes) {
                    individualLicenseType.refLicenseType = $scope.refLicenseTypes.whereEquals(individualLicenseType.ProviderLicenseTypeId, 'LicenseTypeId').firstOrDefault();
                }
                var feeDetailRowsByLicenseType = getFeeDetailsByAppType(individualLicenseType);
                 individualLicenseType.applicationFee = feeDetailRowsByLicenseType.whereEquals('RENEWAL', 'FeeTypeCode').sum('FeeAmount');
                 individualLicenseType.lateFee = feeDetailRowsByLicenseType.whereEquals('LATE', 'FeeTypeCode').sum('FeeAmount');
            });
        };

        var getFeeDetailsByAppType = function (licenseType) {
            var feesForLicenseType = [];
            var feechangeImplemantationDate = new Date('10/1/2022');
            if (!!$scope.feeDetailRows) {
                feesForLicenseType = $scope.feeDetailRows
                    .whereEquals($scope.applicationInfo.ApplicationTypeId, 'ApplicationTypeId')
                    .where(function (feeDetailItem) {
                        return (feeDetailItem.LicenseTypeIds.split(',').indexOf(licenseType.ProviderLicenseTypeId.toString()) != -1);
                    })
                    //.whereContains(licenseType.PreviousProviderStatusTypeId.toString(), 'LicenseStatusTypeIds')
                    .whereEquals('RENEWAL', 'FeeTypeCode');

                if (licenseType.ApplyLateFee) {
                    var lateFeesForLicenseType = $scope.feeDetailRows
                        .whereEquals($scope.applicationInfo.ApplicationTypeId, 'ApplicationTypeId')
                        .where(function (feeDetailItem) {
                            return (feeDetailItem.LicenseTypeIds.split(',').indexOf(licenseType.ProviderLicenseTypeId.toString()) != -1);
                        })
                        //.whereContains(licenseType.PreviousLicenseStatusTypeId.toString(), 'LicenseStatusTypeIds')
                        .whereEquals('LATE', 'FeeTypeCode');
                    feesForLicenseType = feesForLicenseType.concat(lateFeesForLicenseType);
                }
            }

            feesForLicenseType = feesForLicenseType.map(function (i) {
                i.ProviderLicenseNumber = licenseType.ProviderLicenseNumber;
                return i;
            });

            
            if (!!feesForLicenseType && feesForLicenseType.length > 0 && (!$scope.applicationInfo || !$scope.applicationInfo.SubmittedDate || utilityService.checkIfDateGreaterOrEqual($scope.applicationInfo.SubmittedDate, feechangeImplemantationDate))) {
                feesForLicenseType = feesForLicenseType.filter(function (feeItemForLicenseType) {
                    return ((!feeItemForLicenseType.EndDate || utilityService.checkIfDateGreaterOrEqual(feeItemForLicenseType.EndDate, licenseType.PreviousExpirationDate))
                        && (!feeItemForLicenseType.EffectiveDate || utilityService.checkIfDateGreaterOrEqual(licenseType.PreviousExpirationDate, feeItemForLicenseType.EffectiveDate)));
                });
            }

            if (!!feesForLicenseType && feesForLicenseType.length > 0 && !!$scope.applicationInfo && !!$scope.applicationInfo.SubmittedDate && utilityService.checkIfDateGreaterOrEqual(feechangeImplemantationDate, $scope.applicationInfo.SubmittedDate)) {
                feesForLicenseType = feesForLicenseType.filter(function (feeItemForLicenseType) {
                    return ((!feeItemForLicenseType.EndDate || utilityService.checkIfDateGreaterOrEqual(feeItemForLicenseType.EndDate, $scope.applicationInfo.SubmittedDate))
                        && (!feeItemForLicenseType.EffectiveDate || utilityService.checkIfDateGreaterOrEqual($scope.applicationInfo.SubmittedDate, feeItemForLicenseType.EffectiveDate)));
                });
            }

            if (!!feesForLicenseType && feesForLicenseType.length > 0) {
                return feesForLicenseType;
            } else {
                // $scope.showStatusMsg('-', "No fees associated with this license type: " + licenseType.LicenseTypeName);
                // throw ('No fees associated with this license type: ' + licenseType.LicenseTypeName);
            }
        };

        var processApplicationFeeAndRenewalData = function () {
            $scope.feeDetails.applicationFee = 0;
            var renewalGroups = [];
            $scope.renewalCheckData.selectedGroups = {};
            var historyQuestionContentId = '';
            var feeDetailRows = [];
            var licenseType = $scope.renewalCheckData.ProviderLicenseList
                // .whereIncludes(ApplicationSettings.ActiveStatuses, 'ApplicationStatusId')
                // .whereIncludes(ApplicationSettings.AvailableTypes, 'ApplicationTypeId')
                // .whereEquals(currentApplicationId, 'ApplicationId')
                .orderByDescending('LinkProviderLicenseId')
                .firstOrDefault();
            if (!!licenseType.IsSelectedToRenew) {
                $scope.callCreatePendingRenewal = false;
            }
            else {
                $scope.callCreatePendingRenewal = true;
                licenseType.IsSelectedToRenew = true;
            }
            angular.forEach($scope.renewalCheckData.ProviderLicenseList, function (licenseType) {
                if (licenseType.IsSelectedToRenew) {
                    if (renewalGroups.indexOf(licenseType.RenewalApplicationGrouping) == -1)
                        renewalGroups.push(licenseType.RenewalApplicationGrouping);
                    $scope.renewalCheckData.selectedGroups["group" + licenseType.RenewalApplicationGrouping] = true;
                    if (!!licenseType.RenewalApplicationHistoryQuestionContentId) {
                        if (!historyQuestionContentId)
                            historyQuestionContentId = licenseType.RenewalApplicationHistoryQuestionContentId;
                        else
                            historyQuestionContentId = historyQuestionContentId.concat(',', licenseType.RenewalApplicationHistoryQuestionContentId);
                    }
                    //feeDetailRows = feeDetailRows.concat(angular.copy(getFeeDetailsByAppType(licenseType)));
                    feeDetailRows = angular.copy(getFeeDetailsByAppType(licenseType));
                }
            });
            mapRefLicenseTypeAndFeeDetails();
            $scope.renewalCheckData.RenewalApplicationHistoryQuestionContentId = historyQuestionContentId.split(',').distinct().join(',');
            $scope.renewalCheckData.renewalGroups = renewalGroups;
            $scope.feeDetails.fees = feeDetailRows;
            $scope.feeDetails.applicationFee = $scope.feeDetails.fees.whereEquals('RENEWAL', 'FeeTypeCode').sum('FeeAmount');
            $scope.feeDetails.lateFee = $scope.feeDetails.fees.whereEquals('LATE', 'FeeTypeCode').sum('FeeAmount');
            $scope.feeDetails.totalFee = $scope.feeDetails.applicationFee + $scope.feeDetails.lateFee;
        };

        $scope.createPendingRenewal = function () {
            ShowLoader();
            //$scope.hideStatusMsg();
            // if (!validateForm(currentForm)) {
            //     initialApplicationstatusService.setRenewalType("invalid");
            //     $scope.showStatusMsg('-', $scope.natValidation.errorMessages);
            //     HideLoader();
            //     return;
            // }
            $scope.renewalCheckData.ProcessSentData = true;
            $scope.renewalCheckData.ProviderLicenseList.ProviderTypeId = 5;
            angular.forEach($scope.renewalCheckData.ProviderLicenseList, function (providerLicense) {
                if (!!providerLicense.IsSelectedToRenew && !providerLicense.ProviderLicenseExpirationDate) {
                    var expirationOptions = {
                        baseDate: providerLicense.PreviousExpirationDate,
                        dateOfBirth: (!!$scope.providerInfo) ? $scope.providerInfo.DateOfBirth : (new Date()),
                        dayOfMonth: 'end'
                    };
                    providerLicense.ProviderLicenseExpirationDate = providerLicense.PreviousExpirationDate;
                }
            })
            licenseRenewalService.checkAndCreatePendingRenewal(sessionStorage.Key, $scope.renewalCheckData)
                .then(function (response) {
                    if (!response.Status) {
                        initialApplicationstatusService.setRenewalType('error');
                        // $scope.showStatusMsg('-', response.Message);
                    } else {
                        initialApplicationstatusService.setRenewalType('success');
                        $scope.renewalCheckData = response.RenewalCheckData;
                        $scope.providerLicense = $scope.renewalCheckData.ProviderLicenseList[0];
                        $scope.getActiveLicense();
                        initialProcessing();
                        mapRefLicenseTypeAndFeeDetails();
                        $scope.setRenewalTypeEdit(false);
                        processApplicationFeeAndRenewalData();
                        $scope.proceedToRenewalApp(false);
                    }
                    HideLoader();
                }, function (data) {
                    initialApplicationstatusService.setRenewalType('error');
                    HideLoader();
                    $scope.showStatusMsg('-', data);
                });
        };

        $scope.setRenewalTypeEdit = function (isRenewalTypeEdit) {
            $scope.isRenewalTypeEdit = isRenewalTypeEdit;
        };

        function monthDiff(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }

        var callProcessTabsTobeShown = function () {
            $rootScope.submissionLicenseStatusTypeId = 3;
            var processTabsToBeShown = true;
            $scope.$emit('processTabsToBeShown', { processTabsToBeShown: processTabsToBeShown });
        }

        $scope.getActiveLicense = function () {
            ShowLoader();
            backofficeProviderLicenseService.providerLicenseDetailBYProviderId(sessionStorage.Key, $scope.providerId)
                .then(function (responseItem) {
                    var LinkProviderLicenseId = 1;
                    $scope.activeProviderLicense = responseItem.ProviderLicenseResponseList.where('!!item.IsLicenseActive').orderByDescending('ProviderLicenseId').firstOrDefault();

                    if($scope.applicationInfo.ApplicationStatusId == 3){
                        LinkProviderLicenseId = $scope.activeProviderLicense?.LinkProviderLicenseId ?? LinkProviderLicenseId;
                        $scope.activeProviderLicense = responseItem.ProviderLicenseResponseList.whereEquals(LinkProviderLicenseId,'ProviderLicenseId').firstOrDefault();
                    }

                    if (!!$scope.applicationInfo.SubmittedDate){
                        submittedDate = new Date($scope.applicationInfo.SubmittedDate);
                        $scope.providerLicense.isSubmitted = true;
                        $scope.providerLicense.SubmittedDate = $scope.applicationInfo.SubmittedDate;
                    }

                    // if (submittedDate > expirationDate) {
                    //     $scope.providerLicense.isLicenseExpired = true;
                    //     year = submittedDate.getFullYear();
                    //     month = submittedDate.getMonth();
                    //     day = submittedDate.getDate();
                    // }
                    HideLoader();
                }, function (error) {
                    HideLoader();
                    $scope.showStatusMsg('-', 'Some error occurred!');
                });
        }

        var getAddressbyType = function (addList, type) {
            var res;
            if (addList && addList.length > 0) {
                for (var i = 0; i < addList.length; i++) {
                    if (addList[i].AddressTypeId == type && addList[i].AddressStatusId == 1) {
                        if (!res) {
                            res = addList[i];
                        } else if (res.AddressId < addList[i].AddressId) {
                            res = addList[i];
                        }
                    }
                }
            }
            return res;
        };

        $scope.getAddressDetails = function () {
            var urlParam = "/" + LoggedUserKey + "?ProviderId=" +  $scope.providerId;
            providerAddressService.GetAddressDetails(urlParam).then(function (res) {
                var providerRes = res.data.ProviderAddress;

                $scope.MailingAddress = getAddressbyType(providerRes, 1);

            });
        };

        $scope.getContactDetails = function() {
            providerContactService.GetContactDetails(LoggedUserKey, $scope.providerId).then(function(res) {
                if (res.data) {
                    $scope.ContactDetailsList = res.data.ProviderContacts;
                    $scope.contactsLoaded = true;
                }
            });
        };

        $scope.getInitData = function () {
            $q.all([BackofficeProviderService.GetProviderById(sessionStorage.Key, $scope.providerId),
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=feedetails&pullInActive=true")),
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=licensetype"))
            ]).then(function (response) {

                if (!response[0].data.ProviderResponse) {
                    // $scope.showStatusMsg('-', 'some error occurred !');
                } else {
                    $scope.providerInfo = response[0].data.ProviderResponse;
                }

                if (!response[1].Status) {
                    // $scope.showStatusMsg('-', 'some error occurred !');
                } else {
                    $scope.feeDetailRows = response[1].ReferenceEntities;
                }
                if (!response[2].Status) {
                    // $scope.showStatusMsg('-', 'some error occurred !');
                } else {
                    $scope.refLicenseTypes = response[2].ReferenceEntities;
                }
                $scope.individualRenewalGet();
                $scope.getAddressDetails();
                $scope.getContactDetails();
            }, function (err) {
                HideLoader();
                // $scope.showStatusMsg('-', 'some error occurred !');
            });
        };

        $scope.$on('paymentProviderSuccess', $scope.paymentProviderSuccess);

        $scope.init();
    }
})();