﻿(function () {
    'use strict'
    angular.module('app.backofficeAdministor').directive('configConfigtypeDirective', function () {
        return {
            restrict: 'EA',
            scope: {
                individualid: '=individualid',
                iseditable: '=iseditable'
            },
            controller: "ConfigConfigtypeDirectiveController",
            templateUrl: "app/components/backoffice/administrator/configuration/config-configtype-directive/config-configtype-directive-teplate.html",
            link: function ($scope, element, attrs, ngCtrl) {

            }
        };
    });
})();