﻿(function () {
    'use strict'
    angular.module('app.core')
        .directive('dashboardPaymentDirective', [function () {
            return {
                restrict: 'EA',
                scope: {
                    individualId: '=?individualId',
                    applicationId: '=?applicationId',
                    iseditable: '=iseditable',
                    displaySendEmail: '=?',
                    emailid: '=?',
                    showStatusMessage: '&',
                    headerText: "=",
                    isdashboard: '=isdashboard',
                    applicant: '=applicant',
                    isprovider: '=?isprovider',
                    providerId: '=?providerId',
                    useType: '=useType',
                    unPaidMiscAppInvoiceExists: "=?unPaidMiscAppInvoiceExists"
                },
                controller: "DashboardPaymentController",
                templateUrl: "app/core/directive/dashboard-payment-directive/dashboard-payment-template.html",
                link: function ($scope, element, attrs, ngCtrl) {

                }
            };
        }]);
})();
