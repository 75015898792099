(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('initialApplicationEducationService', initialApplicationEducationService)

    initialApplicationEducationService.$inject = ['$http', '$q', 'WebApiUrl']

    function initialApplicationEducationService($http, $q, WebApiUrl) {
        var _individualEducationSave = function (individualId, applicationId, userId, individualEducation, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualEducationSave/' + key +
                "?individualId=" + individualId + "&applicationId=" + applicationId + "&UserId=" + userId, individualEducation)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getEducation = function (individualId, applicationId, userId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualEducationBYIndividualId/' + key + '?IndividualId=' + individualId +
                '&applicationId=' + applicationId +
                '&userId=' + userId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;

        };

        var _educationSave = function (objCECourse, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualContinuingEducationSave/' + key, objCECourse)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _individualCEHSSave = function (objCECourse, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualCEHSave/' + key, objCECourse)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var LookupGetBYLookupTypeID = function (urlParam) {
            return $http.get(WebApiUrl + "Lookup/LookupGetBYLookupTypeID" + urlParam);
        };

        var _getCEEducation = function (individualId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualCECourseByIndividualId/' + key +
                '?IndividualId=' + individualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getIndividualCEHBYIndividualId = function (individualId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualCEHBYIndividualId/' + key +
                '?IndividualId=' + individualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getAllProviderByProviderTypeIdAndProviderStatusTypeId = function (key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/GetEmployerNameAndAddress/' + key +
                '?SearchString=&ProviderTypeId=1&ProviderStatusTypeId=1')
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            educationSave: _educationSave,
            individualEducationSave: _individualEducationSave,
            getEducation: _getEducation,
            LookupGetBYLookupTypeID: LookupGetBYLookupTypeID,
            getCEEducation: _getCEEducation,
            individualCEHSSave: _individualCEHSSave,
            getIndividualCEHBYIndividualId: _getIndividualCEHBYIndividualId,
            getAllProviderByProviderTypeIdAndProviderStatusTypeId: _getAllProviderByProviderTypeIdAndProviderStatusTypeId
        };
    }
})();
