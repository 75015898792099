angular.module('natApp').controller("PaymentProviderConfirmationController", ["$rootScope", "$scope", "$state", "$q", "$stateParams", "paymentProviderConfirmationService",
    function ($rootScope, $scope, $state, $q, $stateParams, paymentProviderConfirmationService) {

        //State Data
        $scope.paymentData = {
            entityId: $stateParams.entityId,
            entityType: $stateParams.entityType,
            applicationId: $stateParams.applicationId,
            applicationTypeId: $stateParams.applicationTypeId,
            paymentMethodTypeId: $stateParams.paymentMethodTypeId,
            isReSubmit: (($stateParams.isReSubmit == 'true' || $stateParams.isReSubmit == true) ? true : false)
        };
        $scope.userId = (!!sessionStorage.UserId ? sessionStorage.UserId : sessionStorage.UserID);
        $scope.uiConfig = {
            isConfigLoaded: false
        };
        $scope.init = function () {
            if ($rootScope.isUserSessionActive()) {
                ShowLoader();
                var dataPromises = {
                    provider: paymentProviderConfirmationService.providerByProviderId(sessionStorage.Key, $scope.paymentData.entityId),
                    application: paymentProviderConfirmationService.getApplicationInfoByApplicationId($scope.paymentData.applicationId, $scope.userId, sessionStorage.Key),
                    providerLicense: paymentProviderConfirmationService.getProviderLicense(sessionStorage.Key, $scope.paymentData.entityId)
                };

                $q.all(dataPromises)
                    .then(function (response) {
                        for (var prop in response) {
                            if (!response[prop].Status) {
                                //$scope.showStatusMessage('-', response[prop].Message);
                                return;
                            }
                        }
                        if (response.provider) {
                            if (!!response.provider.ProviderResponse) {
                                $scope.paymentData.provider = response.provider.ProviderResponse;
                            }
                        }

                        if (response.application) {
                            if (!!response.application.ApplicationResponseList && response.application.ApplicationResponseList.length > 0) {
                                $scope.paymentData.applicationInfo = response.application.ApplicationResponseList[0];
                                $scope.paymentData.applicationInfo.AppType = ($scope.paymentData.applicationInfo.ApplicationTypeId == 1 ? 'renewal' : 'application');
                            }
                        }

                        if (response.providerLicense) {
                            if (!!response.providerLicense.ProviderLicenseResponseList && response.providerLicense.ProviderLicenseResponseList.length > 0) {
                                var providerLicenses = response.providerLicense.ProviderLicenseResponseList;
                                var ProviderLicenseTypeId = '';
                                providerLicenses = providerLicenses
                                    .whereEquals($scope.paymentData.applicationId, 'ApplicationId')
                                    .select('ProviderLicenseTypeId').distinct();
                                providerLicenses.map(function (providerLicense, index) {
                                    if (index == 0) {
                                        ProviderLicenseTypeId = providerLicense;
                                    } else if (index == (providerLicenses.length - 1)) {
                                        ProviderLicenseTypeId = ProviderLicenseTypeId + ' and ' + providerLicense;
                                    } else {
                                        ProviderLicenseTypeId = ProviderLicenseTypeId + ', ' + providerLicense;
                                    }
                                });
                                $scope.paymentData.ProviderLicenseTypeId = ProviderLicenseTypeId;
                                $scope.paymentData.ProviderLicenseTypeName = $scope.paymentData.ProviderLicenseTypeId == 2 ? 'COR' : $scope.paymentData.ProviderLicenseTypeId == 3 ? 'PAR' : ' ' ;
                            }
                        }
                        $scope.uiConfig.isConfigLoaded = true;
                        HideLoader();
                    }, function (error) {
                        //$scope.showStatusMessage('-', error.message);
                    });

            } else {
                HideLoader();
                $state.go('app.Loginwoemail');
            }
        };

        $scope.printConfirmation = function () {
            window.print();
        };

        $scope.init();
    }
]);