(function () {
    'use strict'
    angular.module('app.core')
        .directive('natRegex', natRegexDirective);

    natRegexDirective.$inject = [];

    function natRegexDirective() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                //element.on('blur', function (event) {
                scope.$watch(attrs.ngModel, function (newValue, oldValue) {
                    var regexTypes = {
                        ssn: /^(\w{3}-?\w{2}-?\w{4}|NNN-NN-NNNN)$/,
                        nceesid: /^(\d{2}-?\d{3}-?\d{2}|NN-NNN-NN)$/,
                        height: /^((\d{1,2})\')?((0?[0-9])"|(1[0-1])")?$/,
                        weight: /^(\d{1,3})([.](\d{0,2}))?$/,
                        zip: /^(\b\d{5}\b)$/,
                    }
                    var RegPattern = new RegExp(regexTypes[attrs.regextype]);
                    // var RegPattern = new RegExp(attrs.regex);

                    if (!ctrl.$$parentForm.errorMessages) {
                        ctrl.$$parentForm.errorMessages = [];
                    }

                    var errorObj = {
                        propName: (!!attrs.validationName ? attrs.validationName : ("natregex-" + ctrl.$name)),
                        errorMessage: attrs.natRegex
                    }

                    angular.forEach(ctrl.$$parentForm.errorMessages, function (error, indexOfMsg) {
                        if (errorObj.propName == error.propName) {
                            ctrl.$$parentForm.errorMessages.splice(indexOfMsg, 1);
                        }
                    })

                    if (!!newValue && !RegPattern.test(newValue.trim())) {
                        ctrl.$$parentForm.errorMessages.push(errorObj);
                        ctrl.$setValidity("natregex", false);
                    } else {
                        ctrl.$setValidity('natregex', true);
                    }
                })
            }
        }
    }
})();