﻿(function () {
    'use strict'
    angular.module('natApp')
        .directive('taskDirective', [function () {
            return {
                restrict: 'EA',
                scope: {
                    individualid: '=individualid',
                    onarchiveclick: "&?onarchiveclick",
                    ondeleteclick: "&?onardeleteclick"
                },
                controller: "TaskDirectiveController",
                templateUrl: "app/components/backoffice/ui/directives/task-directive/task-template.html",
                link: function ($scope, element, attrs, ngCtrl) {

                }
            };
        }]);
})();