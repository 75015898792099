(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('licenseOutputService', licenseOutputService)

    licenseOutputService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function licenseOutputService($http, $q, WebApiUrl, AppConfig) {

        var licenseOutputService = {
            generateAndSendLicenseEmail: generateAndSendLicenseEmail,
            individualLicenseDetailBYIndividualId: individualLicenseDetailBYIndividualId,
            individualContactBYIndividualId: individualContactBYIndividualId
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function generateAndSendLicenseEmail(key, licenseEmailObj) {
            return returnDefferedResult($http.post(WebApiUrl + 'IndividualLicense/GenerateLicenseOutput/' + key + '?Source=' + AppConfig.Source, licenseEmailObj));
        };

        function individualLicenseDetailBYIndividualId(Key, individualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualLicenseDetailBYIndividualId/' + Key + '?IndividualId=' + individualId));
        };

        function individualContactBYIndividualId(Key, individualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualContactBYIndividualId/' + Key + '?IndividualId=' + individualId));
        };


        return licenseOutputService;
    }
})();
