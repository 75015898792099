(function () {
    'use strict'

    angular.module('app.core').factory('typeValuesService', typeValuesService)

    typeValuesService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']
    var typesData = {};

    function typeValuesService($http, $q, WebApiUrl, AppConfig) {
        var _getTypeValuesByTypeName = function (typeValue, key, getDataFromServer, useTypeValueOnly) {
            var deferred = $q.defer();
            if (!getDataFromServer && !!typesData[typeValue]) {
                deferred.resolve(typesData[typeValue]);
            } else {
                $http.get(WebApiUrl + 'TypeValues/' + typeValue + (useTypeValueOnly != undefined ? '/' : 'TypeGetAll/') + key)
                    .then(function (data) {
                        data = data.data;
                        if (data.Status && data.StatusCode == "00")
                            typesData[typeValue] = data;
                        deferred.resolve(data);
                    }, function (error) {
                        error = error.data;
                        deferred.reject(error);
                    });
            }
            return deferred.promise;
        };

        var _getTypeValuesByTypeNameAndParams = function (typeValue, key, useTypeValueOnly, params) {
            var deferred = $q.defer();

            $http.get(WebApiUrl + 'TypeValues/' + typeValue + (useTypeValueOnly ? '/' : 'TypeGetAll/') + key + params)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        var _getTypeValuesByTypeNameAndParamsPublic = function (typeValue, useTypeValueOnly, params) {
            var deferred = $q.defer();

            $http.get(WebApiUrl + 'TypeValues/' + typeValue + (useTypeValueOnly ? '/' : 'TypeGetAll/') + 'public/' + params)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        var _getTypeValuesApplicationTypeGetAll = function (key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'TypeValues/ApplicationTypeGetAll/' + key)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });

            return deferred.promise;
        };
        return {
            getTypeValuesByTypeName: _getTypeValuesByTypeName,
            getTypeValuesByTypeNameAndParams: _getTypeValuesByTypeNameAndParams,
            getTypeValuesByTypeNameAndParamsPublic: _getTypeValuesByTypeNameAndParamsPublic,
            GetAllPrefix: function (key) {
                return $http.get(WebApiUrl + 'TypeValues/GetAllPrefix/' + key);
            },
            GetAllSuffix: function (key) {
                return $http.get(WebApiUrl + 'TypeValues/GetAllSuffix/' + key);
            },
            ProfTitleSuffix: function (key) {
                return $http.get(WebApiUrl + 'TypeValues/GetAllProfTitleSuffix/' + key);
            },
            RefTablesGetAll: function (tableKey, key) {
                return $http.get(WebApiUrl + 'TypeValues/RefTablesGetAll?Key=' + (!!key ? key : "") + '&tableKey=' + tableKey);
            },
            RefProviderLicenseTypeGetAll: function (tableKey) {
                var urlParams = {
                    tableKey: 'licensetype'
                };
                return $http.get(WebApiUrl + 'TypeValues/RefTablesGetAll/' + tableKey + '/', { params: urlParams });
            },

            // var _getLicenseTypes = function (key) {
            //     var urlParams = {
            //         tableKey: 'licensetype'
            //     };
            //     return returnDefferedResult($http.get(WebApiUrl + 'TypeValues/RefTablesGetAll/' + key + '/', { params: urlParams }));
            // };
            getTypeValuesApplicationTypeGetAll: _getTypeValuesApplicationTypeGetAll
        }
    }
})();
