(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('personalAppearanceLogic', [PersonalAppearanceLogicDirective]);

    function PersonalAppearanceLogicDirective() {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                
            }
        };
    }
})();