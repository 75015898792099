(function () {
    'use strict'

    angular.module('app.core')
    .directive('activityTimerButton', [ActivityTimerButton]);

    function ActivityTimerButton() {
        return {
            restrict: 'E',
            require: '^activityTimer',
            scope: {
                name: '@',
                linkType: '@',
                linkId: '=',
                text: '@?'
            },
            link: function ($scope, element, attrs, activityTimer) {
                $scope.activityTimer = activityTimer;
            },
            controller: ['$scope', function ($scope) {

                $scope.startActivityTimer = function () {
                    $scope.activityTimer.startActivity($scope.name, $scope.text, $scope.linkType, $scope.linkId);
                };

                $scope.stopActivityTimer = function () {
                    $scope.activityTimer.stopActivity($scope.name);
                };
            }],
            templateUrl: 'app/core/directive/activity-timer/activity-timer-button.html'
        };
    }
})();