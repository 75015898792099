(function () {
    'use strict'

    angular.module('app.enforcement')
    .factory('ComplaintChangeLogService', ['$http', '$q', 'WebApiUrl', ComplaintChangeLogService]);

    function ComplaintChangeLogService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getComplaintChangeLog = function (key, search) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/GetComplaintChangeLog/' + key + '/', search));
        };

        return {
            getComplaintChangeLog: _getComplaintChangeLog,
        };
    }
})();