(function () {
    'use strict'

    angular.module('app.enforcement')
    .factory('ComplaintActionsService', ['$http', '$q', 'WebApiUrl', ComplaintActionsService]);

    function ComplaintActionsService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getStaffUsers = function (key) {
            var urlParams = {
                UserTypeId: 1
            };
            return responseHandler($http.get(WebApiUrl + 'Users/GetUsersByUserTypeId/' + key + '/', { params: urlParams }));
        };

        var _getActions = function (key, complaintId, complaintCaseId, actionGroupName, actionGroupNames) {
            var urlParams = {
                ComplaintId: complaintId,
                ComplaintCaseId: complaintCaseId,
                ComplaintActionGroupName: actionGroupName,
                ComplaintActionGroupNames: actionGroupNames
            };
            return responseHandler($http.get(WebApiUrl + 'Complaint/GetComplaintAction/' + key + '/', { params: urlParams }));
        };

        var _saveActions = function (key, request) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/SaveComplaintAction/' + key + '/', request));
        };

        return {
            getStaffUsers: _getStaffUsers,
            
            getActions: _getActions,
            saveActions: _saveActions
        };
    }
})();