(function () {
    'use strict';

    angular
        .module('app.provider')
        .controller("ApprovedCourseListingController", ApprovedCourseListingController);

    ApprovedCourseListingController.$inject = ["$rootScope", "$scope", "$state", "bppService", "RegisterFactory",
        "countryStateService", "initialApplicationstatusService", "typeValuesService", "lookupService", "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl", "$q"
    ];

    function ApprovedCourseListingController($rootScope, $scope, $state, bppService, RegisterFactory,
        countryStateService, initialApplicationstatusService, typeValuesService, lookupService, utilityService, $exceptionHandler, TypeValue, WebApiUrl, $q) {

        if (!$scope.isBackoffice)
            $scope.providerId = sessionStorage.ProviderId;
        var userId = ($scope.isBackoffice ? sessionStorage.UserID : sessionStorage.UserId);
        $scope.viewConfig = {};
        $scope.viewConfig.approvedCourses = true;
        //$scope.isBackoffice = false;

        var init = function () {
            ShowLoader();
            $scope.renewalStartDate = new Date();
            $scope.renewalStartDate.setDate($scope.renewalStartDate.getDate() + 90);
            var dataSavePromise = typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=cecoursetype"));
            dataSavePromise.then(function (response) {
                $scope.ceCourseTypes = response.ReferenceEntities;
                $scope.providerCECourseByProviderId($scope.providerId);
                HideLoader();
            }, function (error) {
                $scope.showStatusMessage(error, "error");
                HideLoader();
            });
        };

        $scope.providerCECourseByProviderId = function (providerId, hideMessages, isInitLoad) {
            if (!isInitLoad)
                ShowLoader("Please Wait...");
            var dataSavePromise = bppService.providerCECourseByProviderId(providerId, "", sessionStorage.Key, null, true);
            dataSavePromise.then(function (response) {

                $scope.providerCECourses = response.ProviderCourseResponseList;
                angular.forEach($scope.providerCECourses, function (providerCECourseItem) {
                    if ((new Date(providerCECourseItem.CourseExpirationDate) > $scope.renewalStartDate) && providerCECourseItem.CourseStatusId != 2)
                        providerCECourseItem.isRenewalDisabled = true;
                    angular.forEach($scope.ceCourseTypes, function (ceCourseType) {
                        if (ceCourseType.CECourseTypeId == providerCECourseItem.CourseTypeId) {
                            providerCECourseItem.CourseTypeName = ceCourseType.CECourseTypeName;
                        }
                    });
                });
                if ($scope.providerCECourses)
                    $scope.totalCourses = $scope.providerCECourses.length;
                // if (hideMessages) {
                //     $scope.hideStatusMessage();
                //     $scope.hideValidationSummary();
                // }
                // if (!isInitLoad) {
                HideLoader();
                //     if ($scope.checkServerResponse({ response: response })) {
                //         //$scope.providerCECourseList = processAssignmentDescValue(response.ProviderAssignmentList);
                //     }
                // }
            }, function (error) {
                $scope.showStatusMessage(error, "error");
            });
            return dataSavePromise;
        };

        $scope.openAddEditCeCourse = function (providerCECourse) {
            providerCECourse.isdisplay = true;
        };

        $scope.ceCourseItemClick = function (providerCECourse) {
            $scope.providerCECourse = providerCECourse;
            $scope.providerCECourseList = [$scope.providerCECourse];
            providerCECourse.displayRenewalApp = true;
        };

        $scope.closeRenewalApp = function () {
            $scope.providerCECourse.displayRenewalApp = false;
        };

        init();
    }

})();