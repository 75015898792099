﻿(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('individualInfoService', ['$http', '$q', 'WebApiUrl', 'AppConfig', individualInfoService])

    function individualInfoService($http, $q, WebApiUrl, AppConfig) {
        var individualSearchContent = null;

        var _setIndividualSearchContent = function (configType, value) {
            if (!individualSearchContent)
                individualSearchContent = {};
            individualSearchContent[configType] = value;
        };
        var _getIndividualSearchContent = function (configType) {
            return (!!individualSearchContent ? individualSearchContent[configType] : null);
        };

        var _individualSearchWithPage = function (individualSearchContent, key, PageNumber, NoOfRecords) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualSearchWithPage/' + key + '?PageNumber=' + PageNumber + '&NoOfRecords=' + NoOfRecords,
                individualSearchContent)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    window.location.href = "#/Backoffice/Loginstaff";
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            setIndividualSearchContent: _setIndividualSearchContent,
            getIndividualSearchContent: _getIndividualSearchContent,
            individualSearchWithPage: _individualSearchWithPage
        };
    }
}());
