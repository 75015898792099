/// <reference path="../../../scripts/built-in-type-extensions.ts" />
/// <reference path="query-reporting-doc.ts" />

(function () {
    'use strict'

    angular.module('app.report')
        .controller('QueryReportingController', ['$scope', '$q', '$timeout', '$uibModal', 'QueryReportingService', QueryReportingController]);

    /**
     * @param  {QueryReportingScope} $scope
     * @param  {*} $q
     * @param  {*} $timeout
     * @param  {*} $uibModal
     * @param  {QueryReportingService} QueryReportingService
     */
    function QueryReportingController($scope, $q, $timeout, $uibModal, QueryReportingService) {
        var _key = sessionStorage.Key;
        var _queryTypeId = 1;

        $scope.init = function () {
            $scope.currentQuery = {
                QueryTypeId: 1
            };
            $scope.utility = {};
            $scope.queryParams = {
                isDistinct: false
            };

            $q.all([getAllSavedQueries(), getAvailableExportDocumentTypes()]).then(function (data) {
                $scope.loadingQueries(false);
                resetProperties();
            });
        };

        /**
         * Asynchronously retrieves the export document types that are supported
         */
        var getAvailableExportDocumentTypes = function (){
            var deferred = $q.defer();
            try {
                QueryReportingService.getAvailableExportDocumentTypes(_key)
                .then(function (data) {
                    if (data.Status) {
                        $scope.exportDocumentTypes = data.DocumentTypes;
                    } else if (!data.Status && data.Message) {
                        $scope.showStatusMsg('-', data.Message);
                    }
                    deferred.resolve(data);
                }, function (err) {
                    if (err && err.statusText) {
                        $scope.showStatusMsg('-', err.statusText);
                    }
                    deferred.reject(err);
                });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMsg('-', ex.message); }
                deferred.reject(ex);
            }
            return deferred.promise;
        };

        /**
         * Asynchronously retrieves all of the saved queries created using the tool
         */
        var getAllSavedQueries = function () {
            var deferred = $q.defer();
            try
            {
                QueryReportingService.getSavedReportingQueries(_key)
                .then(function (data) {
                    if(data.Status) {
                        $scope.savedQueries = data.ReportQueries;//.whereEquals(_queryTypeId, 'QueryTypeId');
                    } else if (!data.Status && data.Message) {
                        $scope.showStatusMsg('-', data.Message);
                    }
                    deferred.resolve(data);
                }, function (err) {
                    if (err && err.statusText) {
                        $scope.showStatusMsg('-', err.statusText);
                    }
                    deferred.reject(err);
                });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMsg('-', ex.message); }
                deferred.reject(ex);
            }
            return deferred.promise;
        };


        /**
         * Checks if the total records returned from the query would be overwhelmingly large
         */
        var checkTotalRecord = function () {
            if ($scope.totalRecords && $scope.totalRecords > 20000) {
                $scope.exportType = 'cp';
                $scope.exportTypeDisableText = 'Query returns huge number of records. Maximum 20000 records allowed to export.'
            } else {
                $scope.exportTypeDisableText = undefined;
            }
        };


        /**
         * 
         */
        var resetProperties = function () {
            $scope.loadedQuery = {
                queryId: 0,
                saveQueryName: '',
                query: null
            };
            $scope.clearResults();
            $scope.exportTypeDisableText = undefined;
            $scope.exportType = (!!$scope.exportDocumentTypes && $scope.exportDocumentTypes.length > 0) ? $scope.exportDocumentTypes[0].Name : '';
        };


        $scope.clearQuery = function () {
            //Sometimes this method takes a while to execute, so async execution is necessary to show the loader
            $scope.clearingQuery(true);
            $timeout(function () {
                resetProperties();
                $scope.utility.resetProperties();
            }, 1)
            .then(function () {
                $scope.clearingQuery(false);
            });
        };
                
        $scope.executeQuery = function (resetPagination) {
            var deferred = $q.defer();

            try
            {
                var req = $scope.utility.getCopyOfCurrentQuery(true);

                if (!req.From) {
                    throw {
                        message: 'No tables selected'
                    };
                }
                if (!req.Select || req.Select.length == 0) {
                    throw {
                        message: 'No columns selected for retrieval'
                    };
                }

                if (resetPagination) {
                    $scope.results.currentPage = 1;
                    req.FromRow = 0;

                } else {
                    req.FromRow = ($scope.results.currentPage - 1) * $scope.results.pageSize;
                }
                req.RecordToFetch = $scope.results.pageSize;

                $scope.gettingQueryResults(true, 'Querying...');
                
                $scope.reportData = [];
                $scope.utility.prepareQueryForSerialization(req);

                req.IsDistinct = $scope.queryParams.isDistinct;

                QueryReportingService.executeQueryAndGetReportTest(_key, req)
                .then(function (data) {
                    if (data.Status) {
                        $scope.results.reportData = data.ReportData;
                        $scope.results.totalRecords = data.TotalRecord;
                        checkTotalRecord();
                    } else if (!data.Status && data.Message) {
                        $scope.showStatusMsg('-', data.Message)
                    }
                    $scope.gettingQueryResults(false);
                    deferred.resolve(data);
                }, function (err) {
                    if (err && err.statusText) {
                        $scope.showStatusMsg('-', err.statusText)
                    }
                    $scope.gettingQueryResults(false);
                    deferred.reject(err);
                });
            } catch (ex) {
                $scope.gettingQueryResults(false);
                if (ex != null) { $scope.showStatusMsg('-', ex.message); }
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.loadReportingQuery = function () {
            try
            {
                if(!$scope.loadedQuery) {
                    throw {
                        Message: 'No query has been selected'
                    };
                }

                var loadedQuery = angular.copy($scope.loadedQuery.query);
                
                resetProperties();
                $scope.utility.resetProperties();

                $scope.loadedQuery.query = loadedQuery;
                $scope.loadedQuery.queryId = loadedQuery.ID;
                $scope.loadedQuery.saveQueryName = loadedQuery.Name;
                
                $scope.utility.setQuery($scope.loadedQuery.query);
            } catch (ex) {
                if (ex != null) { $scope.showStatusMsg('-', ex.Message); }
            }
        };

        $scope.deleteReportingQuery = function () {
            try
            {
                if(!$scope.loadedQuery.query){
                    throw {
                        message: 'No query has been selected'
                    };
                } 

                if(!confirm('Are you sure you want to delete the query: ' + $scope.loadedQuery.query.Name)) {
                    return;
                }

                var req = {
                    QueryId: $scope.loadedQuery.query.ID
                };

                $scope.loadingQueries(true, 'Deleting Query...');
                QueryReportingService.deleteReportingQuery(_key, req)
                .then (function (data) {
                    if(data.Status) {
                        $scope.loadedQuery.query = null;
                        $scope.savedQueries = data.ReportQueries;//.whereEquals(_queryTypeId, 'QueryTypeId');
                    } else if (!data.Status && data.Message) {
                        $scope.showStatusMsg('-', data.Message);
                    }
                    $scope.loadingQueries(false);
                }, function (err) {
                    if (err && err.statusText) {
                        $scope.showStatusMsg('-', err.statusText);
                    }
                    $scope.loadingQueries(false);
                });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMsg('-', ex.message); }
                $scope.loadingQueries(false);
            }
        };

        $scope.saveReportingQuery = function () {
            try
            {
                var req = $scope.utility.getCopyOfCurrentQuery(true);
                if (!req.From) {
                    throw {
                        message: 'No tables selected'
                    };
                }
                if (!req.Select || req.Select.length == 0) {
                    throw {
                        message: 'No columns selected for retrieval'
                    };
                }
                if (!$scope.loadedQuery.saveQueryName || $scope.loadedQuery.saveQueryName.trim().length == 0) {
                    throw {
                        message: 'No name assigned to the saved query'
                    };
                }
                req.Name = $scope.loadedQuery.saveQueryName;
                req.QueryTypeId = _queryTypeId;
                $scope.utility.prepareQueryForSerialization(req);
                
                $scope.loadingQueries(true, 'Saving Query...');
                QueryReportingService.saveReportingQuery(_key, req)
                .then(function (data) {
                    //Update the saved query list
                    if (data.Status){
                        $scope.savedQueries = data.ReportQueries;//.whereEquals(_queryTypeId, 'QueryTypeId');
                        $scope.showSaveQuery = false;
                        $scope.loadedQuery.saveQueryName = '';
                    } else if (!data.Status && data.Message) {
                        $scope.showStatusMsg('-', data.Message);
                    }
                    $scope.loadingQueries(false);
                }, function (err) {
                    if (err && err.statusText) {
                        $scope.showStatusMsg('-', err.statusText)
                    }
                    $scope.loadingQueries(false);
                });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMsg('-', ex.message); }
                $scope.loadingQueries(false);
            }
        };

        $scope.updateReportingQuery = function () {
            try
            {
                if(!$scope.loadedQuery) {
                    throw {
                        message: 'No query has been selected'
                    };
                }

                var req = $scope.utility.getCopyOfCurrentQuery(true);
                if (!req.From) {
                    throw {
                        message: 'No tables selected'
                    };
                }
                if (!req.Select || req.Select.length == 0) {
                    throw {
                        message: 'No columns selected for retrieval'
                    };
                }
                if (!$scope.loadedQuery.saveQueryName || $scope.loadedQuery.saveQueryName.trim().length == 0) {
                    throw {
                        message: 'No name assigned to the saved query'
                    };
                }
                
                req.ID = $scope.loadedQuery.query.ID;
                req.Name = $scope.loadedQuery.saveQueryName;
                $scope.utility.prepareQueryForSerialization(req);
                
                $scope.loadingQueries(true, 'Updating Query...');
                $scope.clearResults();
                QueryReportingService.updateReportingQuery(_key, req)
                .then(function (data) {
                    //Update the saved query list
                    if (data.Status){
                        //Force a $digest cycle for the saved queries list
                        //For some reason the $digest cycle wasn't detecting that the reporting queries had changed, 
                        //so it wasn't updating any of the model in the select control of the Load/Delete Query list.
                        $scope.savedQueries = null;
                        $timeout(function () {
                            $scope.savedQueries = data.ReportQueries;//.whereEquals(_queryTypeId, 'QueryTypeId');
                            //$scope.showSaveQuery = false;
                            $scope.loadedQuery.query = data.ReportQueries.where('item.ID==this', req.ID)[0];  //Wipe away the old loaded query with whatever they had loaded 
                            $scope.saveQueryName = $scope.loadedQuery.query.Name;
                            $scope.loadReportingQuery();
                        }, 1);
                    } else if (!data.Status && data.Message) {
                        $scope.showStatusMsg('-', data.Message);
                    }
                    $scope.loadingQueries(false);
                }, function (err) {
                    if (err && err.statusText) {
                        $scope.showStatusMsg('-', err.statusText)
                    }
                    $scope.loadingQueries(false);
                });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMsg('-', ex.message); }
                $scope.loadingQueries(false);
            }
        };

        $scope.exportToDocument = function (type) {
            var deferred = $q.defer();

            try
            {
                var req = $scope.utility.getCopyOfCurrentQuery(true);

                if (!req.From) {
                    throw {
                        message: 'No tables selected'
                    };
                }
                if (!req.Select || req.Select.length == 0) {
                    throw {
                        message: 'No columns selected for retrieval'
                    };
                }

                $scope.gettingQueryResults(true);
                    
                req.DocumentType = type;
                req.FromRow = 0;
                req.RecordToFetch = 0;
                $scope.utility.prepareQueryForSerialization(req);

                req.IsDistinct = $scope.queryParams.isDistinct;

                QueryReportingService.executeQueryAndGetDocumentTest(_key, req)
                .then(function (data) {
                    if (data.Status) {
                        // $scope.reportData = data.ReportData;
                        // $scope.totalRecords = data.TotalRecord;
                        //checkTotalRecord();
                    } else if (data.Message) {
                        $scope.showStatusMsg('-', data.Message)
                    }
                    $scope.gettingQueryResults(false);
                    deferred.resolve(data);
                }, function (err) {
                    if (err && err.Message) {
                        $scope.showStatusMsg('-', err.Message)
                    }
                    $scope.gettingQueryResults(false);
                    deferred.reject(err);
                });
            } catch (ex) {
                $scope.gettingQueryResults(false);
                if (ex != null) { $scope.showStatusMsg('-', ex.message); }
                deferred.reject(ex);
            }

            return deferred.promise;
        };
        
        $scope.clearResults = function () {
            $scope.clearingQueryResults(true);
            $timeout(function () {
                $scope.results = {
                    totalRecords: 0,
                    reportData: [],
                    currentPage: 1,
                    pageSize: 500,
                    maxSize: 5,
                    fromRow: 0
                };
            }, 1)
            .then(function () {
                $scope.clearingQueryResults(false);
            });            
        };

        $scope.pageChanged = function () {
            if ($scope.results.currentPage) {
                var cPage = angular.copy($scope.currentPage);
                var pSize = angular.copy($scope.pageSize);
                $scope.results.fromRow = (cPage - 1) * pSize;

                try {
                    $scope.clearingQueryResults(true, 'Changing Pages...');
                    $scope.executeQuery(false)
                    .then(function(data) {
                        $scope.clearingQueryResults(false);
                    }, function (err) {
                        $scope.clearingQueryResults(false);
                    });
                } catch (ex) {
                    $scope.clearingQueryResults(false);
                }
            }
        };
        
        $scope.openBulkEmailDialog = function () {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/components/report/query-reporting/query-reporting-bulk-email.html',
                controller: 'QueryReportingBulkEmailDialogController',
                size: 'lg',
                keyboard: false,
                resolve: {
                    parameters: function () {
                        return {
                            currentQuery: $scope.utility.getCopyOfCurrentQuery(true),
                            processedTables: angular.copy($scope.utility.getTables()),
                            expressionList: angular.copy($scope.utility.getExpressionList())
                        };
                    }
                }
            });

            modalInstance.result.then(function (result) {

            }, function () {

            });
        };
    };
})();