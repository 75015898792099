(function () {
  'use strict';

  angular
    .module('natApp')
    .controller("LicenseRenewalApplicationBackOfficeController", LicenseRenewalApplicationBackOfficeController);

  LicenseRenewalApplicationBackOfficeController.$inject = ["$scope", "AppConfig"];

  function LicenseRenewalApplicationBackOfficeController($scope, AppConfig) {
    console.log('Backoffice');
    $scope.IsBackoffice = true;
    $scope.FirmApplication = false;
    $scope.CPAApplication = false;
    if($scope.current.application.LicenseTypeName === 'Certified Public Accountant')
    {
      sessionStorage.FirmApplication = false;
      $scope.CPAApplication = true;
    }
    else
    {
      sessionStorage.FirmApplication = true;
      $scope.FirmApplication = true;
    }
  }
})();