(function () {
    'use strict'

    angular.module('app.pciCompliantPayment')
    .directive('pciCompliantPayment', [PCICompliantPayment]);

    function PCICompliantPayment() {
        return {
            restrict: 'E',
            scope: {
                entityId: '=',
                entityType: '=',
                applicationId: '=',
                applicationTypeId: '=',
                individualLicense: '=',
                applicationInfo: '=?',
                showHeaderPanel: '=?',
                showSubmitPanel: '=?',
                isBackoffice: '=?',
                onPosting: '&',
                configName: '@',
                feeDetails: '=',
                feeDetailRows: "=",
                isMiscPayment: '=',
                generateAppHtml: '=',
                isConfirmPayment: '=',
                doNotSendReceipt: '=',
                isRenewal: '=',
                isLoading: '=?',
                paymentEnabled: '=?',
                onRedirecting: '&?',
                addlCssForPdf: '=?'
            },
            link: function ($scope, element, attrs) {

            },
            templateUrl: 'app/core/directive/pci-compliant-payment/directive/pci-compliant-payment.html',
            controller: 'PCICompliantPaymentController'
        };
    }
})();