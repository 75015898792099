(function () {
  'use strict'

  angular.module('app.InitialApplication')
    .factory('initialApplicationAssistantService', initialApplicationAssistantService)

  initialApplicationAssistantService.$inject = ['$http', '$q', 'WebApiUrl']

  function initialApplicationAssistantService($http, $q, WebApiUrl) {

    
      var _getStateByCountryID = function (Key, ID) {

          var deferred = $q.defer();
          $http.get(WebApiUrl + 'State/StateGetByCountryID/' + Key + '?CountryID=' + ID)
            .then(function(data){ data = data.data;
                deferred.resolve(data);
            }, function(error) { error = error.data;
                deferred.reject(error);
            });
          return deferred.promise;
      };
      var _assistantSave = function (assistantObj, key) {
          var deferred = $q.defer();
          $http.post(WebApiUrl + 'Individual/IndividualSupervisoryInfoSave/' + key, assistantObj)
           .then(function(data){ data = data.data;
               deferred.resolve(data);
           }, function(error) { error = error.data;
               deferred.reject(error);
           });

          return deferred.promise;
      };

      var _IndividualNameSave = function (reqData, urlParam) {
          return $http.post(WebApiUrl + 'Individual/IndividualNameSave' + urlParam, reqData);
      };

      var _SaveIndividualAddress = function (reqData, urlParam) {
          return $http.post(WebApiUrl + 'Individual/IndividualAddressSave' + urlParam, reqData);
      };


    return {
        getStateByCountryID: _getStateByCountryID,
        assistantSave: _assistantSave,
        individualNameSave: _IndividualNameSave,
        saveIndividualAddress: _SaveIndividualAddress
    };
  }
})();