(function () {
    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeProviderDocumentController", BackofficeProviderDocumentController);

    BackofficeProviderDocumentController.$inject = ["$rootScope", "$scope", "$state", "WebApiUrl", "ProviderMultipleFileUploadService", "backofficeProviderAdressService", "Messages"];

    function BackofficeProviderDocumentController($rootScope, $scope, $state, WebApiUrl, ProviderMultipleFileUploadService, backofficeProviderAdressService, Messages) {
        $scope.documentsList = null;
        $scope.documentsList2 = null;
        $scope.documentsListLegacy = null;
        // $scope.LoadDocument
        $scope.documentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
            ApplicationId: $scope.ApplicationID,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "FRM",
            LoadByDocIdAndDocCode: false,
            ExcludeDocId: 48
        };

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, providerId) {
            if ($rootScope.individualFirmCurrentTab.name == "document") {
                $scope.documentUploadinchard = undefined;
                $scope.documentUpload.ProviderId = providerId;
                $scope.providerId = providerId;
                $scope.providerContactBYProviderId(providerId);
                $scope.$broadcast("loaddocumentListing", providerId);
                $scope.$broadcast("initdocumentCreation");
                $rootScope.individualFirmCurrentTab.isLoadedOnce = true;
            }
        });

        $scope.providerContactBYProviderId = function (providerId) {
            try {
                var contactDataPromises = backofficeProviderAdressService.providerContactBYProviderId(sessionStorage.Key, providerId);
                contactDataPromises.then(function (response) {
                    $scope.contactsData = response.ProviderContacts;
                    if ($scope.contactsData) {
                        $scope.email = null;
                        angular.forEach($scope.contactsData, function (contact) {
                            if (contact.ContactStatusId == 1 && contact.ContactTypeId == 23) {
                                $scope.email = contact.ContactInfo;
                                sessionStorage.currentProviderUserEmail = contact.ContactInfo;
                            }
                        });

                        if (!$scope.email) {
                            angular.forEach($scope.contactsData, function (contact) {
                                if (contact.ContactStatusId == 1 && contact.ContactTypeId == 26) {
                                    $scope.email = contact.ContactInfo;
                                    sessionStorage.currentProviderUserEmail = contact.ContactInfo;
                                }
                            });
                        }
                    }
                }, function (data) {
                    $scope.showStatusMessage(data, "error");
                });
                return contactDataPromises;
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        $scope.CreateDocument = function () {
            ShowLoader("Please wait...");
            // if (!$scope.email || $scope.email == "") {
            //     HideLoader();
            //     $scope.showStatusMessage(Messages.fefeefc, "success");
            //     return;
            // }

            var data = {
                "ProviderId": $scope.providerId,
                "ProviderLicenseId": null,
                "Email": $scope.email ? $scope.email : "",
                "LicenseNumber": null,
                "Source": null,
                "DocumentTypeName": null,
                "UserId": sessionStorage.UserID,
                "IsEmailSend": false
            };
            ProviderMultipleFileUploadService.providerDocumentSaveToPDFandMailWYReprint(sessionStorage.Key, data).then(function (res) {
                // $scope.documentsList = null;
                // $scope.documentsList2 = null;
                // $scope.documentUpload = undefined;
                if (res.Status) {
                    $scope.showStatusMessage(Messages.General.Errors.DocsSaved, "success");
                    HideLoader();
                    // var st = setTimeout(function () {
                    //     InitializeDocUpload();
                    //     $rootScope.individualFirmCurrentTab.isLoadedOnce = true;
                    //     $scope.$apply();
                    // }, 500);
                }
                else {
                    HideLoader();
                    // var st = setTimeout(function () {
                    //     InitializeDocUpload();
                    //     $rootScope.individualFirmCurrentTab.isLoadedOnce = true;
                    //     $scope.$apply();
                    // }, 500);
                }
            }, function (error) {
                HideLoader();
            });
        }
    }
})();