(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationLegalInfoController", InitialApplicationLegalInfoController);

    InitialApplicationLegalInfoController.$inject = ["$rootScope", "$scope", "countryStateService", "$q",
        "initialApplicationLegalInfoService", "utilityService", "initialApplicationstatusService", "dcaIService", "WebApiUrl", "$exceptionHandler", "initialApplicationAcknowledgementService", "contentService", "initialApplicationVeteranInfoService", "Messages", "backofficeIndividualLicenseService", "initialApplicationStaffReviewService"
    ];

    function InitialApplicationLegalInfoController($rootScope, $scope, countryStateService, $q,
        initialApplicationLegalInfoService, utilityService, initialApplicationstatusService, dcaIService, WebApiUrl, $exceptionHandler, initialApplicationAcknowledgementService, contentService, initialApplicationVeteranInfoService, Messages, backofficeIndividualLicenseService, initialApplicationStaffReviewService) {
        $scope.documentsList = null;

        $scope.datePicker = {
            dateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };
        $scope.documentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.individualId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "FR",
            LoadByDocIdAndDocCode: true,
            DocId: 1,
            DocCode: "D1001",
            LoadDocumentsWithoutBroadCast: true
        };

        $scope.documentUploadMilitaryInformation = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.individualId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "R",
            LoadByDocIdAndDocCode: true,
            DocId: 27,
            DocCode: "D1027",
            LoadDocumentsWithoutBroadCast: true
        };
        var legalInfoOptionIndex = 0;

        var counter = 0;
        //$scope.legalInfo = {};

        $scope.getLegalInfoConfig = function () {
            var relativeFilePath = "/individual/application/legal-info/legal-info.server-config.json";
            return utilityService.getConfigData(relativeFilePath);
        };

        $scope.getLegalInfoOptionIndex = function (option) {
            if (option.IsEnabled)
                legalInfoOptionIndex = legalInfoOptionIndex + 1;
            option.Index = legalInfoOptionIndex;
        }

        $scope.getValidationMessage = function (validationMessage, legalInfoNumber) {
            if (!!validationMessage)
                validationMessage = validationMessage.replace("{{legalInfoNumber}}", legalInfoNumber);
            return validationMessage;
        };

        $scope.setLegalStatus = function () {
            if ($scope.isRenewal) {
                var isAffirmativeAction = false;
                if ($scope.legalInfo.legalInfoDisciplinary == true) {
                    isAffirmativeAction = true;
                }
                $scope.legalInfo.isAffirmativeAction = isAffirmativeAction;
            }
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        var calculateCECourseHoursbyType = function () {
            $scope.firstYrCETechnicalHours = 0;
            $scope.secondYrCETechnicalHours = 0;
            $scope.totalTechnicalCEHours = 0;
            if (!!$rootScope.ceCourseTabData) {
                angular.forEach($rootScope.ceCourseTabData.ceCourseTypes, function (ceCourseType) {
                    if (ceCourseType.CECourseTypeId == 1 || ceCourseType.CECourseTypeId == 2 || ceCourseType.CECourseTypeId == 3 || ceCourseType.CECourseTypeId == 4 || ceCourseType.CECourseTypeId == 5 || ceCourseType.CECourseTypeId == 7 || ceCourseType.CECourseTypeId == 8) {
                        $scope.firstYrCETechnicalHours = $scope.firstYrCETechnicalHours + ceCourseType.firstYrHours;
                        $scope.secondYrCETechnicalHours = $scope.secondYrCETechnicalHours + ceCourseType.secondYrHours;
                        $scope.totalTechnicalCEHours = $scope.firstYrCETechnicalHours + $scope.secondYrCETechnicalHours;
                    }
                    if (ceCourseType.CECourseTypeId == 2) {
                        $scope.ethicsTotalHours = ceCourseType.firstYrHours + ceCourseType.secondYrHours;
                    }
                    if (ceCourseType.CECourseTypeId == 3) {
                        $scope.fraudTotalHours = ceCourseType.firstYrHours + ceCourseType.secondYrHours;
                    }
                    if (ceCourseType.CECourseTypeId == 8) {
                        $scope.govtTotalHours = ceCourseType.firstYrHours + ceCourseType.secondYrHours;
                    }
                    if (ceCourseType.CECourseTypeId == 1) {
                        $scope.AATotalHours = ceCourseType.firstYrHours + ceCourseType.secondYrHours;
                    }
                    if (ceCourseType.CECourseTypeId == 7) {
                        $scope.prepEngagementTotalHours = ceCourseType.firstYrHours + ceCourseType.secondYrHours;
                    }
                    if (ceCourseType.CECourseTypeId == 4) {
                        $scope.boardApprovedTotalHours = ceCourseType.firstYrHours + ceCourseType.secondYrHours;
                    }
                });
            }
        };

        var validateForm = function (currentForm) {
            if ($scope.useType == 'continuingEducation') {
                $rootScope.submissionLicenseStatusTypeId = 3;
                calculateCECourseHoursbyType();
            }
            if ($scope.useType == 'Affidavit') {
                $rootScope.renewalInfoLicenseStatusTypeId = 3;
            }
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            // $scope.natValidation.warningMessages = [];

            angular.forEach($scope.legalInfo, function (legalInfoOption, key) {
                legalInfoOption.showValidation = true;
            });
            if (!$scope.isRenewal) {
                var LostCivilRightsError = false;
                var documentUpload = null;
                var i = 0;

                if ($scope.applicationInfo.LicenseApplicationForId == 5 || $scope.applicationInfo.ApplicationTypeId == 2 || $scope.applicationInfo.ApplicationTypeId == 8) {
                    if (LostCivilRightsError && (documentUpload == null || documentUpload.documentCount == 0)) {
                        var errorObj = {
                            propName: "CitizenshipInfomationDoc",
                            errorMessage: Messages.General.Errors.UploadDoc
                        };
                        $scope.natValidation.errorMessages.push(errorObj);
                    }
                }
            }

            $scope.question3 = false;
            $scope.question4 = false;
            $scope.question5 = false;
            $scope.question345DocNotUploaded = false;

            $scope.question6 = false;
            $scope.question7 = false;
            $scope.question8a = false;
            $scope.question8b = false;
            $scope.question8c = false;
            $scope.question9 = false;
            $scope.question10 = false;
            $scope.question10a = false;

            if (!!$scope.isRenewal) {
                var isConviction = false;
                angular.forEach($scope.legalInfo, function (legalInfoOption, key) {
                    if ($scope.useType == 'Affidavit') {
                        if (legalInfoOption.ContentItemLkId == 11 && !!legalInfoOption.ContentItemResponse) {
                            isConviction = true;
                            $rootScope.renewalInfoLicenseStatusTypeId = 8;
                            if (!legalInfoOption.Desc && (!legalInfoOption.documentUpload || !legalInfoOption.documentUpload.documentCount || legalInfoOption.documentUpload.documentCount == 0)) {
                                var errorObj = {
                                    propName: "ConvictionInfomationDoc",
                                    errorMessage: "Please provide an explanation or upload a document for your Convictions"
                                };
                                $scope.natValidation.errorMessages.push(errorObj);
                            }
                        }
                        if (legalInfoOption.ContentItemLkId == 12 && !!legalInfoOption.ContentItemResponse && isConviction) {
                            if (!legalInfoOption.individualLegalAddInfo.IncidentComplaintNature) {
                                var errorObj = {
                                    propName: "TitleofTheMatter",
                                    errorMessage: "Please enter Title of The Matter"
                                };
                                $scope.natValidation.errorMessages.push(errorObj);
                            }
                            if (!legalInfoOption.individualLegalAddInfo.AgencythattookAction) {
                                var errorObj = {
                                    propName: "CourtorAgencyName",
                                    errorMessage: "Please enter Court or the AgencyName"
                                };
                                $scope.natValidation.errorMessages.push(errorObj);
                            }
                            if (!legalInfoOption.individualLegalAddInfo.IncidentComplaintIdentifyingInfo) {
                                var errorObj = {
                                    propName: "DocketNumber",
                                    errorMessage: "Please enter the Docket Number"
                                };
                                $scope.natValidation.errorMessages.push(errorObj);
                            }
                            if (!legalInfoOption.individualLegalAddInfo.IncidentFromDate) {
                                var errorObj = {
                                    propName: "DateofOccurence",
                                    errorMessage: "Please enter the Date of Occurence"
                                };
                                $scope.natValidation.errorMessages.push(errorObj);
                            }
                        }
                        if (legalInfoOption.ContentItemLkId == 23 && !!$scope.legalInfoList.item22.ContentItemResponse) {
                            // if (!legalInfoOption.Desc) {
                            //     var errorObj = {
                            //         propName: "PeerReviewFormAvailability",
                            //         errorMessage: "Please provide availability to upload completed form PR-1"
                            //     };
                            //     $scope.natValidation.errorMessages.push(errorObj);
                            // }
                            if (!legalInfoOption.individualLegalAddInfo.AdditionalInformation) {
                                var errorObj = {
                                    propName: "PeerRating",
                                    errorMessage: "Please enter Peer Review Rating"
                                };
                                $scope.natValidation.errorMessages.push(errorObj);
                            }
                            if (legalInfoOption.individualLegalAddInfo.AdditionalInformation == 'Substandard/Fail') {
                                $rootScope.renewalInfoLicenseStatusTypeId = 8;
                            }
                            if ((!legalInfoOption.documentUpload || !legalInfoOption.documentUpload.documentCount || legalInfoOption.documentUpload.documentCount == 0)&&(legalInfoOption.individualLegalAddInfo.AdditionalInformation != 'Not Yet Due')) {
                                var errorObj = {
                                    propName: "PeerReviewFormDoc",
                                    errorMessage: "Please upload a Peer Review Form PR-1"
                                };
                                $scope.natValidation.errorMessages.push(errorObj);
                            }
                        }
                        if (legalInfoOption.ContentItemLkId == 15 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question3 = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 16 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question4 = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 21 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question5 = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 21) {
                            if ((!legalInfoOption.documentUpload || !legalInfoOption.documentUpload.documentCount || legalInfoOption.documentUpload.documentCount == 0)) {
                                $scope.question345DocNotUploaded = true;
                            }
                        }
                    }
                    if ($scope.useType == 'continuingEducation') {
                        if (legalInfoOption.ContentItemLkId == 547 && !!legalInfoOption.ContentItemResponse && !!$scope.legalInfoList && !!$scope.legalInfoList.item546 && $scope.legalInfoList.item546.ContentItemResponse == true) {
                            if (!legalInfoOption.individualLegalAddInfo.IncidentFromDate) {
                                var errorObj = {
                                    propName: "DateofEngagement",
                                    errorMessage: "Please enter the Engagement Start Date"
                                };
                                $scope.natValidation.errorMessages.push(errorObj);
                            }
                        }
                        if (legalInfoOption.ContentItemLkId == 539 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question6 = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 540 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question7 = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 542 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question8a = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 543 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question8b = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 544 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question8c = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 545 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question9 = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 546 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question10 = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 547 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question10a = true;
                        }
                    }
                });
                if ($scope.useType == 'continuingEducation') {
                    if ($scope.individualLicense.LicenseStatusTypeId != 9 && !($scope.individualLicense.isLicenseExpired && ($scope.individualLicense.reEntryLicensee || $scope.individualLicense.newLicensee))) {
                        if (!!$scope.question6) {
                            if (!!$rootScope.ceCourseTabData && !!$rootScope.ceCourseTabData.totalCeHours) {
                                if ($rootScope.ceCourseTabData.totalCeHours < $scope.totalCeHoursMin) {
                                    var errorObj = {
                                        propName: "CEHoursTotal",
                                        errorMessage: "Total CE Hours should be equal to or more than " + $scope.totalCeHoursMin
                                    };
                                    $scope.natValidation.warningMessages.push(errorObj);
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }

                                if ($scope.totalTechnicalCEHours < $scope.totalTechnicalCEHoursMin) {
                                    var errorObj = {
                                        propName: "TotalTechnicalCEHours",
                                        errorMessage: "Total Technical CE Hours should be equal to or more than " + $scope.totalTechnicalCEHoursMin
                                    };
                                    $scope.natValidation.warningMessages.push(errorObj);
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }

                                if ($rootScope.ceCourseTabData.firstYrCeHours < $scope.firstYearHoursMin) {
                                    var errorObj = {
                                        propName: "FyTotalCEHours",
                                        errorMessage: "Total First Year CE Hours should be equal to or more than " + $scope.firstYearHoursMin
                                    };
                                    $scope.natValidation.warningMessages.push(errorObj);
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }
                                if ($rootScope.ceCourseTabData.secondYrCeHours < $scope.secondYearHoursMin) {
                                    var errorObj = {
                                        propName: "SyTotalCEHours",
                                        errorMessage: "Total Second Year CE Hours should be equal to or more than " + $scope.secondYearHoursMin
                                    };
                                    $scope.natValidation.warningMessages.push(errorObj);
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }

                                if ($scope.firstYrCETechnicalHours < $scope.firstYearTechnicalHoursMin) {
                                    var errorObj = {
                                        propName: "FyTechnicalCEHours",
                                        errorMessage: "First Year Technical CE Hours should be equal to or more than " + $scope.firstYearTechnicalHoursMin
                                    };
                                    $scope.natValidation.warningMessages.push(errorObj);
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }
                                if ($scope.secondYrCETechnicalHours < $scope.secondYearTechnicalHoursMin) {
                                    var errorObj = {
                                        propName: "SyTechnicalCEHours",
                                        errorMessage: "Second Year Technical CE Hours should be equal to or more than " + $scope.secondYearTechnicalHoursMin
                                    };
                                    $scope.natValidation.warningMessages.push(errorObj);
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }

                                if ($scope.ethicsTotalHours < $scope.ethicsHoursMin) {
                                    var errorObj = {
                                        propName: "EthicsCEHours",
                                        errorMessage: "Ethics CE Hours should be equal to or more than " + $scope.ethicsHoursMin
                                    };
                                    $scope.natValidation.warningMessages.push(errorObj);
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }
                            }
                            else {
                                var errorObj = {
                                    propName: "CEHoursTotal",
                                    errorMessage: "Please enter CE Hours in the table below"
                                };
                                $scope.natValidation.warningMessages.push(errorObj);
                                $rootScope.submissionLicenseStatusTypeId = 8;
                            }
                        }
                        else {
                            var errorObj = {
                                propName: "CEHoursRequiredForActive",
                                errorMessage: "CE is required for Active Renewal"
                            };
                            $scope.natValidation.warningMessages.push(errorObj);
                            $rootScope.submissionLicenseStatusTypeId = 8;
                        }

                        if (!!$scope.question7) {
                            if ($scope.boardApprovedTotalHours < 2) {
                                var errorObj = {
                                    propName: "BoardApprovedCEHours",
                                    errorMessage: "Board Approved Reg Review CE Hours should be equal to or more than 2"
                                };
                                $scope.natValidation.warningMessages.push(errorObj);
                            }
                            $rootScope.submissionLicenseStatusTypeId = 8;
                        }
                        else {
                            if (!!$scope.regReviewDate && ($scope.regReviewDate <= new Date($scope.activeIndividualLicense.LicenseExpirationDate))) {
                            // if (!!$scope.activeIndividualLicense.LicenseExpirationDate){    
                                var errorObj = {
                                    propName: "BoardApprovedCEHoursRegReviewDue",
                                    errorMessage: "Board Approved Reg Review CE Hours should be equal to or more than 2 because Reg Review Date is past due date"
                                };
                                $scope.natValidation.warningMessages.push(errorObj);
                                $rootScope.submissionLicenseStatusTypeId = 8;
                            }
                        }

                        if (!!$scope.question8a) {
                            if ($scope.govtTotalHours < $scope.govtMin) {
                                var errorObj = {
                                    propName: "GovtCEHours",
                                    errorMessage: "Government Auditing CE Hours should be equal to or more than " + $scope.govtMin
                                };
                                $scope.natValidation.warningMessages.push(errorObj);
                                $rootScope.submissionLicenseStatusTypeId = 8;
                            }
                        }
                        else {
                            if (!!$scope.question8b) {
                                var comboTotalHours = $scope.AATotalHours + $scope.govtTotalHours;
                                if ((comboTotalHours < $scope.AAMin)) {
                                    var errorObj = {
                                        propName: "AACEHours",
                                        errorMessage: "A&A CE Hours should be equal to or more than " + $scope.AAMin
                                    };
                                    $scope.natValidation.warningMessages.push(errorObj);
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }
                            }
                            else {
                                if (!!$scope.question8c) {
                                    var prepComboTotalHours = $scope.AATotalHours + $scope.prepEngagementTotalHours;
                                    if ((prepComboTotalHours < $scope.prepEngagementMin)) {
                                        var errorObj = {
                                            propName: "PrepCEHours",
                                            errorMessage: "Preparation Engagement CE Hours should be equal to or more than " + $scope.prepEngagementMin
                                        };
                                        $scope.natValidation.warningMessages.push(errorObj);
                                        $rootScope.submissionLicenseStatusTypeId = 8;
                                    }
                                }
                            }
                        }

                        if (!!$scope.question8a || !!$scope.question8b || !!$scope.question8c) {
                            if ((!!$scope.question9 || !$scope.question9) && !($scope.individualLicense.reEntryLicensee || $scope.individualLicense.newLicensee)) {
                                if ($scope.fraudTotalHours < $scope.fraudMin) {
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                    var errorObj = {
                                        propName: "FraudCEHours",
                                        errorMessage: $scope.fraudMin + " Hours of Fraud CE Hours required"
                                    };
                                    $scope.natValidation.warningMessages.push(errorObj);
                                }
                            }
                            else {
                                //$rootScope.submissionLicenseStatusTypeId = 8;
                            }
                        }

                        utilityService.scrollToElementOffset("applicationFormRenewal");
                    }
                    if ($scope.individualLicense.isLicenseExpired && ($scope.individualLicense.reEntryLicensee || $scope.individualLicense.newLicensee)) {
                        $rootScope.submissionLicenseStatusTypeId = 8;
                    }
                    $scope.uniqueObjArray = [
                        ...new Map($scope.natValidation.warningMessages.map((item) => [item["errorMessage"], item])).values(),
                    ];
                    $scope.natValidation.warningMessages = $scope.uniqueObjArray;
                }
                if ($scope.useType == 'Affidavit') {
                    // if ($scope.individualLicense.LicenseStatusTypeId != 9) {
                    //     if (!!$scope.question3 || !!$scope.question4 || !!$scope.question5) {
                    //         if (!!$scope.question345DocNotUploaded) {
                    //             $rootScope.renewalInfoLicenseStatusTypeId = 8;
                    //             var errorObj = {
                    //                 propName: "MilitaryDoc",
                    //                 errorMessage: "Please upload military orders or DD form 214 or copy of spouse's/partner's military duty"
                    //             };
                    //             $scope.natValidation.warningMessages.push(errorObj);
                    //         }
                    //     }
                    // }
                }
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }

        };

        var checkPropertyExists = function (list, prop) {
            var res = false;
            if (list) {
                for (var i = 0; i < list.length; i++) {
                    if (list[i].propName == prop)
                        return true;
                }
            }
            return res;
        };

        var ShowErrMsg = function () {
            var errorObj = {
                propName: "legalInfoErr",
                errorMessage: $scope.legalInfoConfig.ValidationMsg.APIErr
            };
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            if (!checkPropertyExists($scope.natValidation.errorMessages, 'legalInfoErr'))
                $scope.natValidation.errorMessages.push(errorObj);
        }

        $scope.legalInfoSave = function (currentForm, skipNextTab) {
            $scope.processCEHoursValidationRules();
            try {
                ShowLoader("Please wait...");
                var confirmation = false;
                if (!$rootScope.isFormValidate) {
                    $scope.tab.isInvalid = false;
                    if (!validateForm(currentForm)) {
                        if (!$scope.isRenewal) {
                            $scope.tab.isInvalid = true;
                            HideLoader();
                            if (skipNextTab == true) {
                                $scope.$emit('setCurrentTabNow', $scope.tab);
                            }
                            if (skipNextTab && !!skipNextTab.fromDeficiency) {
                                $scope.$emit('deficiencyExecuted', { success: false });
                            }
                        } else {
                            HideLoader();
                            initialApplicationstatusService.setLegal('invalid');
                        }
                        return;
                    }
                    if ($scope.useType == 'continuingEducation') {
                        if ($rootScope.submissionLicenseStatusTypeId == 8 && !!$scope.natValidation.warningMessages && $scope.natValidation.warningMessages.length > 0) {
                            if (counter > 0 && (!!$scope.isBackoffice || confirm("You are submitting an incomplete renewal application which will cause delays in processing your renewal. Click cancel to return to your application and make corrections.  Otherwise, you may click “OK” to submit your application."))) {
                                confirmation = true;
                            }
                            else {
                                confirmation = false;
                            }
                        }
                        else {
                            confirmation = true;
                        }
                        counter = counter + 1;
                    }
                    if ($scope.useType == 'Affidavit') {
                        if ($rootScope.renewalInfoLicenseStatusTypeId == 8 && !!$scope.natValidation.warningMessages && $scope.natValidation.warningMessages.length > 0) {
                            if (counter > 0 && (!!$scope.isBackoffice || confirm("You are submitting an incomplete renewal application which will cause delays in processing your renewal. Click cancel to return to your application and make corrections.  Otherwise, you may click “OK” to submit your application."))) {
                                confirmation = true;
                            }
                            else {
                                confirmation = false;
                            }
                        }
                        else {
                            confirmation = true;
                        }
                        counter = counter + 1;
                    }
                    if (!!$scope.natValidation.warningMessages && $scope.natValidation.warningMessages.length > 0 && !confirmation) {
                        $scope.natValidation.showWarning = true;
                        HideLoader();
                        // $scope.natValidation.isValid = false;
                        return;
                    } else {
                        $scope.natValidation.showWarning = false;
                        // $scope.natValidation.isValid = true;
                        //  return true;
                    }
                }
                if (!$scope.isRenewal || !!$scope.isRenewal) {
                    $scope.$broadcast("SaveDeficiency", {});
                }

                // if ($scope.useType == 'Affidavit' || !!confirmation) {
                if (!!confirmation) {
                    if ($scope.legalInfo || $scope.legalInfos) {
                        sessionStorage.AutoApprove = true;

                        angular.forEach($scope.legalInfo, function (legalInfoOption, key) {
                            legalInfoOption.ApplicationId = $scope.applicationId;
                            legalInfoOption.IndividualId = $scope.individualId;
                            if ($scope.isRenewal) {
                                if (legalInfoOption.ContentItemResponse) {
                                    sessionStorage.AutoApprove = false;
                                }
                                legalInfoOption.IndividualLicenseId = $scope.individualLicense.IndividualLicenseId;
                            }
                            // if ($scope.legalInfo.legalInfoDisciplinary == true) {
                            //     if (legalInfoOption.ContentItemNumber == 1) {
                            //         legalInfoOption.ContentItemResponse = false;
                            //     } else if (legalInfoOption.ContentItemNumber == 2) {
                            //         legalInfoOption.ContentItemResponse = true;
                            //     }
                            // } else if ($scope.legalInfo.legalInfoDisciplinary == false) {
                            //     if (legalInfoOption.ContentItemNumber == 1) {
                            //         legalInfoOption.ContentItemResponse = true;
                            //     } else if (legalInfoOption.ContentItemNumber == 2) {
                            //         legalInfoOption.ContentItemResponse = false;
                            //     }
                            // }
                        });
                        if (!!$scope.isRenewal) {
                            var dataSavePromises = [];
                            angular.forEach($scope.legalInfo, function (legalInfoOption, key) {
                                legalInfoOption.ApplicationId = $scope.applicationId;
                            });

                            dataSavePromises.push(initialApplicationLegalInfoService.legalInfoSave($scope.legalInfo, sessionStorage.Key));
                            angular.forEach($scope.legalInfo, function (legalInfoOption, key) {
                                if ((!!legalInfoOption.ContentItemResponse && (legalInfoOption.ContentItemLkId == 12 || legalInfoOption.ContentItemLkId == 547)) || (legalInfoOption.ContentItemLkId == 23 && !!$scope.legalInfoList.item22.ContentItemResponse)) {
                                    dataSavePromises.push(
                                        initialApplicationLegalInfoService.saveIndividualLegalAddInfo($scope.individualId, $scope.applicationId, $scope.userId, legalInfoOption.individualLegalAddInfo, sessionStorage.Key)
                                    );
                                }
                            });

                            $q.all(dataSavePromises).then(function (res) {
                                var isSavedSuccessfully = true;
                                angular.forEach(res, function (response) {
                                    if (!response.Status) {
                                        isSavedSuccessfully = false;
                                    }
                                })
                                if (isSavedSuccessfully) {
                                    $scope.tab.skipNextTab = skipNextTab;
                                    $scope.openNextTab({
                                        currentTab: $scope.tab
                                    });
                                    if ($scope.tab.skipNextTab == true) {
                                        $scope.$emit('setCurrentTabNow');
                                    }
                                    if (skipNextTab && !!skipNextTab.fromDeficiency) {
                                        $scope.$emit('deficiencyExecuted', { success: true });
                                    }
                                }
                                HideLoader();
                            }, function (res) {
                                HideLoader();
                                $scope.showStatusMessage({
                                    messageContent: 'Some error occurred !',
                                    messageType: 'error'
                                });
                            })
                        } else {
                            initialApplicationLegalInfoService.legalInfoSave($scope.legalInfo, sessionStorage.Key)
                                .then(function (response) {
                                    if (response.Status == false) {
                                        initialApplicationstatusService.setLegal('error');
                                        $scope.showStatusMessage({
                                            messageContent: response.Message,
                                            messageType: 'error'
                                        });
                                    } else {
                                        if (!$scope.isRenewal) {
                                            $scope.tab.skipNextTab = skipNextTab;
                                            $scope.openNextTab({
                                                currentTab: $scope.tab
                                            });
                                            if ($scope.tab.skipNextTab == true) {
                                                $scope.$emit('setCurrentTabNow');
                                            }
                                            if (skipNextTab && !!skipNextTab.fromDeficiency) {
                                                $scope.$emit('deficiencyExecuted', { success: true });
                                            }
                                        } else {
                                            initialApplicationstatusService.setLegal('success');
                                        }
                                    }
                                    if (!$rootScope.ProcessLoaderExternally)
                                        HideLoader();
                                }, function (error) {
                                    initialApplicationstatusService.setLegal('error');
                                    HideLoader();
                                    $scope.showStatusMessage({
                                        messageContent: 'Some error occurred !',
                                        messageType: 'error'
                                    });
                                });

                        }
                    }
                }
                else {
                    HideLoader();
                }
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
                $exceptionHandler(ex);
            }
        };

        function AuditVisitInfoSave() {
            var data = {
                "PageName": $scope.legalInfoConfig.Content.TabHeading,
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };

        $scope.flagForReview = function () {
            if ($scope.useType == 'continuingEducation') {
                $rootScope.submissionLicenseStatusTypeId = 3;
                calculateCECourseHoursbyType();
            }
            if ($scope.useType == 'Affidavit') {
                $rootScope.renewalInfoLicenseStatusTypeId = 3;
            }
            $scope.question3 = false;
            $scope.question4 = false;
            $scope.question5 = false;
            $scope.question345DocNotUploaded = false;

            $scope.question6 = false;
            $scope.question7 = false;
            $scope.question8a = false;
            $scope.question8b = false;
            $scope.question8c = false;
            $scope.question9 = false;
            $scope.question10 = false;
            $scope.question10a = false;

            if (!!$scope.isRenewal) {
                var isConviction = false;
                angular.forEach($scope.legalInfo, function (legalInfoOption, key) {
                    if ($scope.useType == 'Affidavit') {
                        if (legalInfoOption.ContentItemLkId == 11 && !!legalInfoOption.ContentItemResponse) {
                            isConviction = true;
                            $rootScope.renewalInfoLicenseStatusTypeId = 8;
                        }
                        if (legalInfoOption.ContentItemLkId == 12 && !!legalInfoOption.ContentItemResponse && isConviction) {

                        }
                        if (legalInfoOption.ContentItemLkId == 23 && !!$scope.legalInfoList.item22.ContentItemResponse) {
                            if (legalInfoOption.individualLegalAddInfo.AdditionalInformation == 'Substandard/Fail') {
                                $rootScope.renewalInfoLicenseStatusTypeId = 8;
                            }
                        }
                        if (legalInfoOption.ContentItemLkId == 15 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question3 = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 16 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question4 = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 21 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question5 = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 21) {
                            if ((!legalInfoOption.documentUpload || !legalInfoOption.documentUpload.documentCount || legalInfoOption.documentUpload.documentCount == 0)) {
                                $scope.question345DocNotUploaded = true;
                            }
                        }
                    }
                    if ($scope.useType == 'continuingEducation') {
                        if (legalInfoOption.ContentItemLkId == 539 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question6 = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 540 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question7 = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 542 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question8a = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 543 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question8b = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 544 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question8c = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 545 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question9 = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 546 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question10 = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 547 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question10a = true;
                        }
                    }
                });
                if ($scope.useType == 'continuingEducation') {
                    if ($scope.individualLicense.LicenseStatusTypeId != 9) {
                        if (!!$scope.question6) {
                            if (!!$rootScope.ceCourseTabData && !!$rootScope.ceCourseTabData.totalCeHours) {
                                if ($rootScope.ceCourseTabData.totalCeHours < $scope.totalCeHoursMin) {
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }

                                if ($scope.totalTechnicalCEHours < $scope.totalTechnicalCEHoursMin) {
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }

                                if ($rootScope.ceCourseTabData.firstYrCeHours < $scope.firstYearHoursMin) {
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }
                                if ($rootScope.ceCourseTabData.secondYrCeHours < $scope.secondYearHoursMin) {
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }

                                if ($scope.firstYrCETechnicalHours < $scope.firstYearTechnicalHoursMin) {
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }
                                if ($scope.secondYrCETechnicalHours < $scope.secondYearTechnicalHoursMin) {
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }

                                if ($scope.ethicsTotalHours < $scope.ethicsHoursMin) {
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }
                            }
                            else {
                                $rootScope.submissionLicenseStatusTypeId = 8;
                            }
                        }
                        else {
                            $rootScope.submissionLicenseStatusTypeId = 8;
                        }

                        if (!!$scope.question7) {
                            $rootScope.submissionLicenseStatusTypeId = 8;
                        }

                        if (!$scope.question7 && (!!$scope.regReviewDate && (utilityService.compareDateswithoutTime($scope.regReviewDate, $scope.activeIndividualLicense.LicenseExpirationDate)))) {
                            $rootScope.submissionLicenseStatusTypeId = 8;
                        }

                        if (!!$scope.question8a) {
                            if ($scope.govtTotalHours < $scope.govtMin) {
                                $rootScope.submissionLicenseStatusTypeId = 8;
                            }
                        }
                        else {
                            if (!!$scope.question8b) {
                                var comboTotalHours = $scope.AATotalHours + $scope.govtTotalHours;
                                if ((comboTotalHours < $scope.AAMin)) {
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }
                            }
                            else {
                                if (!!$scope.question8c) {
                                    var prepComboTotalHours = $scope.AATotalHours + $scope.prepEngagementTotalHours;
                                    if ((prepComboTotalHours < $scope.prepEngagementMin)) {
                                        $rootScope.submissionLicenseStatusTypeId = 8;
                                    }
                                }
                            }
                        }

                        if (!!$scope.question8a || !!$scope.question8b || !!$scope.question8c) {
                            if ((!!$scope.question9 || !$scope.question9) && !($scope.individualLicense.reEntryLicensee || $scope.individualLicense.newLicensee)) {
                                if ($scope.fraudTotalHours < $scope.fraudMin) {
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }
                            }
                            else {
                                //$rootScope.submissionLicenseStatusTypeId = 8;
                            }
                        }

                    }

                    if ($scope.individualLicense.isLicenseExpired && ($scope.individualLicense.reEntryLicensee || $scope.individualLicense.newLicensee)) {
                        $rootScope.submissionLicenseStatusTypeId = 8;
                    }
                }
                if ($scope.useType == 'Affidavit') {
                    // if ($scope.individualLicense.LicenseStatusTypeId != 9) {
                    //     if (!!$scope.question3 || !!$scope.question4 || !!$scope.question5) {
                    //         if (!!$scope.question345DocNotUploaded) {
                    //             $rootScope.renewalInfoLicenseStatusTypeId = 8;
                    //         }
                    //     }
                    // }
                }
            }
        }

        function init() {
            $scope.individualIdNumber = $scope.individualLicense.IDNumber;
            $scope.DisplayCASLicensePrimaryStatus($scope.individualIdNumber);
            $scope.isRetiredOrDelinquent = $rootScope.isRetiredOrDelinquent;

            $scope.$emit('processTabsToBeShownIfNotRetired', $scope.individualLicense.LicenseStatusTypeId);

            try {
                if (!!$scope.loadData) {
                    ShowLoader("Please wait...");
                }
                if (!$scope.isRenewal) {
                    // if ($scope.applicationInfo.LicenseApplicationForId == 6) {
                    //     $scope.contentItemsByUseType = {
                    //         Affidavit: {
                    //             ContentIds: [{
                    //                 ContentItemLkId: "222",
                    //                 DocumentId: 1,
                    //                 DocumentCode: "D1001",
                    //                 showPreviousName: true,
                    //                 showDocumentControl: false,
                    //                 hideDocumentType: true,
                    //                 preSelectFirstDocumentType: true,
                    //                 showWhen: true,
                    //                 showLicenceNumber: true,
                    //                 showDescription: false,
                    //                 loadAddInfo: true
                    //             },
                    //             {
                    //                 ContentItemLkId: "223",
                    //                 DocumentId: 2,
                    //                 DocumentCode: "D1002",
                    //                 showDocumentControl: false,
                    //                 hideDocumentType: true,
                    //                 preSelectFirstDocumentType: true,
                    //                 showWhen: true,
                    //                 showDescription: false,
                    //                 loadAddInfo: true
                    //             },
                    //             {
                    //                 ContentItemLkId: "224",
                    //                 DocumentId: 3,
                    //                 DocumentCode: "D1003",
                    //                 showDocumentControl: false,
                    //                 hideDocumentType: true,
                    //                 preSelectFirstDocumentType: true,
                    //                 showWhen: true,
                    //                 showDeniedCertification: true,
                    //                 showLicenceNumber: false,
                    //                 showDescription: true,
                    //                 loadAddInfo: true
                    //             },
                    //             {
                    //                 ContentItemLkId: "225",
                    //                 DocumentId: 4,
                    //                 DocumentCode: "D1004",
                    //                 showDocumentControl: false,
                    //                 hideDocumentType: true,
                    //                 preSelectFirstDocumentType: true,
                    //                 showWhen: true,
                    //                 showDisciplinedPendingComplaints: true,
                    //                 showLicenceNumber: false,
                    //                 showDescription: true,
                    //                 loadAddInfo: true
                    //             },

                    //             {
                    //                 ContentItemLkId: "238",
                    //                 DocumentId: 5,
                    //                 DocumentCode: "D1005",
                    //                 showDocumentControl: false,
                    //                 hideDocumentType: true,
                    //                 preSelectFirstDocumentType: true,
                    //                 showWhen: false,
                    //                 showDateOfOffense: true,
                    //                 showOffense: true,
                    //                 showLicenceNumber: false,
                    //                 showFelonyorMisdemeanor: true,
                    //                 showDescription: true,
                    //                 loadAddInfo: true
                    //             },
                    //             {
                    //                 ContentItemLkId: "239",
                    //                 DocumentId: 6,
                    //                 DocumentCode: "D1006",
                    //                 showDocumentControl: false,
                    //                 hideDocumentType: true,
                    //                 preSelectFirstDocumentType: true,
                    //                 showWhen: false,
                    //                 showDateOfOffense: true,
                    //                 showOffense: true,
                    //                 showLicenceNumber: false,
                    //                 showFelonyorMisdemeanor: true,
                    //                 showActiontaken: true,
                    //                 showDescription: true,
                    //                 showQuestion: true,
                    //                 loadAddInfo: true
                    //             }
                    //             ]
                    //         }
                    //     };
                    // }
                    // else {
                    $scope.contentItemsByUseType = {
                        Affidavit: {
                            ContentIds: [{
                                ContentItemLkId: "397",
                                DocumentId: 1,
                                DocumentCode: "D1001",
                                showDocumentControl: true,
                                hideDocumentType: true,
                                preSelectFirstDocumentType: true,
                                showWhen: false,
                                showPreviousName: true,
                                showLicenceNumber: true,
                                showDescription: false,
                                loadAddInfo: true
                            }, {
                                ContentItemLkId: "398",
                                DocumentId: 2,
                                DocumentCode: "D1002",
                                showDocumentControl: true,
                                hideDocumentType: true,
                                preSelectFirstDocumentType: true,
                                showWhen: false,
                                showDeniedOn: true,
                                showDeferredOn: true,
                                showClosedOn: true,
                                showLicensedas: true,
                                showLicenseNumber: true,
                                showLicenseExpirationDate: true,
                                showLicenceNumber: false,
                                showDescription: false,
                                loadAddInfo: true
                            },
                            {
                                ContentItemLkId: "399",
                                DocumentId: 3,
                                DocumentCode: "D1003",
                                showDocumentControl: true,
                                hideDocumentType: true,
                                preSelectFirstDocumentType: true,
                                showWhen: true,
                                showDeniedLicensure: true,
                                showWhatHappened: false,
                                showLicenceNumber: false,
                                additionalInformation: true,
                                loadAddInfo: true
                            },
                            {
                                ContentItemLkId: "400",
                                DocumentId: 4,
                                DocumentCode: "D1004",
                                showDocumentControl: true,
                                hideDocumentType: true,
                                preSelectFirstDocumentType: true,
                                loadAddInfo: true,
                                showDescription: true
                            }
                            ]
                        }
                    };
                    // }

                    $scope.tabContentData = $scope.contentItemsByUseType[$scope.useType];
                    // getIndividualVeteranConfByIndividualId();
                }

                if ($scope.isRenewal) {
                    $scope.contentItemsByUseType = {
                        Affidavit: {
                            ContentIds: [{
                                ContentItemLkId: "11",
                                DocumentId: 1,
                                DocumentCode: "D1001",
                                showDocumentControl: true,
                                hideDocumentType: true,
                                preSelectFirstDocumentType: true,
                                showWhen: true,
                                showLicenceNumber: true,
                                showDescription: true,
                                loadAddInfo: true
                            }, {
                                ContentItemLkId: "12",
                                DocumentId: 2,
                                DocumentCode: "D1002",
                                showDocumentControl: false,
                                hideDocumentType: true,
                                preSelectFirstDocumentType: true,
                                showWhen: true,
                                showLicenceNumber: false,
                                showDescription: false,
                                loadAddInfo: true
                            }]
                        }
                    };

                    $scope.tabContentData = $scope.contentItemsByUseType['Affidavit'];
                }

                function monthDiff(d1, d2) {
                    var months;
                    months = (d2.getFullYear() - d1.getFullYear()) * 12;
                    months -= d1.getMonth();
                    months += d2.getMonth();
                    return months <= 0 ? 0 : months;
                }

                var callProcessTabsTobeShown = function () {
                    $rootScope.submissionLicenseStatusTypeId = 3;
                    var hideCETab = true;
                    $scope.$emit('processTabsToBeShown', { hideCETab: hideCETab });
                }

                $scope.processCEHoursValidationRules = function () {
                    // initialize values
                    var noOfMonths = 24;
                    $scope.totalCeHoursMin = 0;
                    $scope.totalTechnicalCEHoursMin = 0;
                    $scope.firstYearHoursMin = 0;
                    $scope.firstYearTechnicalHoursMin = 0;
                    $scope.secondYearHoursMin = 0;
                    $scope.secondYearTechnicalHoursMin = 0;
                    $scope.ethicsHoursMin = 0;
                    $scope.fraudMin = 0;
                    $scope.govtMin = 0;
                    $scope.AAMin = 0;
                    $scope.govtplusAASumMin = 0;
                    $scope.prepEngagementMin = 0;

                    var todaysDate = new Date();
                    var submittedDate = todaysDate;
                    var expirationDate = new Date($scope.activeIndividualLicense.LicenseExpirationDate);
                    var effectiveCEDate = new Date($scope.activeIndividualLicense.OriginalLicenseDate);

                    var year = Number($scope.activeIndividualLicense.LicenseExpirationDate.substring(0, 4));
                    var month = Number($scope.activeIndividualLicense.LicenseExpirationDate.substring(5, 7));
                    var day = Number($scope.activeIndividualLicense.LicenseExpirationDate.substring(8, 10));

                    var firstYearCEEffectiveDate = new Date(year - 2, month - 1, day + 1);
                    var firstYearCEExpirationDate = new Date(year - 1, month - 1, day);
                    var secondYearCEEffectiveDate = new Date(year - 1, month - 1, day + 1);
                    var secondYearCEExpirationDate = new Date($scope.activeIndividualLicense.LicenseExpirationDate);

                    if (!!$scope.applicationInfo.SubmittedDate){
                        submittedDate = new Date($scope.applicationInfo.SubmittedDate);
                        $scope.individualLicense.isSubmitted = true;
                        $scope.individualLicense.SubmittedDate = $scope.applicationInfo.SubmittedDate;
                    }
                        
                    if (submittedDate > expirationDate) {
                        $scope.individualLicense.isLicenseExpired = true;
                        year = submittedDate.getFullYear();
                        month = submittedDate.getMonth();
                        day = submittedDate.getDate();

                        firstYearCEEffectiveDate = new Date(year - 2, month, day + 1);
                        firstYearCEExpirationDate = new Date(year - 1, month, day);
                        secondYearCEEffectiveDate = new Date(year - 1, month, day + 1);
                        secondYearCEExpirationDate = new Date();
                    }

                    if (!!$scope.activeIndividualLicense) {
                        if (!!$scope.activeIndividualLicense.CEDate && $scope.activeIndividualLicense.CEDate.length >= 10) {
                            var year = Number($scope.activeIndividualLicense.CEDate.substring(0, 4));
                            var month = Number($scope.activeIndividualLicense.CEDate.substring(5, 7));
                            var day = Number($scope.activeIndividualLicense.CEDate.substring(8, 10));
                            var conversionDate = new Date(year, month - 1, day);
                            noOfMonths = monthDiff(conversionDate, expirationDate);
                            $scope.individualLicense.reEntryLicensee = true;
                            if (noOfMonths < 24) {
                                $scope.individualLicense.effectiveCEDate = new Date($scope.activeIndividualLicense.CEDate);
                                $scope.individualLicense.isProrated = true;
                                if (noOfMonths < 6) {
                                    callProcessTabsTobeShown();
                                }
                            }
                            else {
                                $scope.individualLicense.effectiveCEDate = firstYearCEEffectiveDate;
                            }

                        }
                        else {
                            var effectiveDate = new Date($scope.activeIndividualLicense.OriginalLicenseDate);
                            //var originalLicenseDate = new Date($scope.activeIndividualLicense.originalLicenseDate);
                            noOfMonths = monthDiff(effectiveDate, expirationDate);
                            if (!$scope.activeIndividualLicense.LicenseEffectiveDate || $scope.activeIndividualLicense.LicenseEffectiveDate == "") {
                                $scope.individualLicense.newLicensee = true;
                                if (noOfMonths < 24) {
                                    $scope.individualLicense.effectiveCEDate = effectiveCEDate;
                                    $scope.individualLicense.isProrated = true;
                                    if (noOfMonths < 6) {
                                        callProcessTabsTobeShown();
                                    }
                                }
                                else {
                                    $scope.individualLicense.effectiveCEDate = firstYearCEEffectiveDate;
                                }
                            }
                            else {
                                noOfMonths = 24;
                                $scope.individualLicense.firstYearCEEffectiveDate = firstYearCEEffectiveDate;
                                $scope.individualLicense.firstYearCEExpirationDate = firstYearCEExpirationDate;
                                $scope.individualLicense.secondYearCEEffectiveDate = secondYearCEEffectiveDate;
                                $scope.individualLicense.secondYearCEExpirationDate = secondYearCEExpirationDate;
                            }
                        }

                        if (!!$scope.activeIndividualLicense.PreviousCEDate && $scope.activeIndividualLicense.PreviousCEDate.length >= 10) {
                            var year = Number($scope.activeIndividualLicense.PreviousCEDate.substring(0, 4));
                            var month = Number($scope.activeIndividualLicense.PreviousCEDate.substring(5, 7));
                            var day = Number($scope.activeIndividualLicense.PreviousCEDate.substring(8, 10));
                            $scope.regReviewDate = new Date(year + 6, month - 1, day);
                        }
                    }
                    if (noOfMonths >= 24) {
                        $scope.totalCeHoursMin = 80;
                        $scope.totalTechnicalCEHoursMin = 40;
                        $scope.firstYearHoursMin = 20;
                        $scope.firstYearTechnicalHoursMin = 12;
                        $scope.secondYearHoursMin = 20;
                        $scope.secondYearTechnicalHoursMin = 12;
                        $scope.ethicsHoursMin = 4;
                        $scope.fraudMin = 4;
                        $scope.govtMin = 24;
                        $scope.AAMin = 24;
                        $scope.govtplusAASumMin = 24;
                        $scope.prepEngagementMin = 8;
                    }
                    else if (noOfMonths >= 18 && noOfMonths < 24) {
                        $scope.totalCeHoursMin = 60;
                        $scope.totalTechnicalCEHoursMin = 30;
                        $scope.firstYearHoursMin = 0;
                        $scope.firstYearTechnicalHoursMin = 0;
                        $scope.secondYearHoursMin = 0;
                        $scope.secondYearTechnicalHoursMin = 0;
                        $scope.ethicsHoursMin = 0;
                        $scope.fraudMin = 0;
                        $scope.govtMin = 18;
                        $scope.AAMin = 18;
                        $scope.govtplusAASumMin = 18;
                        $scope.prepEngagementMin = 6;
                    }
                    else if (noOfMonths >= 12 && noOfMonths < 18) {
                        $scope.totalCeHoursMin = 40;
                        $scope.totalTechnicalCEHoursMin = 20;
                        $scope.firstYearHoursMin = 0;
                        $scope.firstYearTechnicalHoursMin = 0;
                        $scope.secondYearHoursMin = 0;
                        $scope.secondYearTechnicalHoursMin = 0;
                        $scope.ethicsHoursMin = 0;
                        $scope.fraudMin = 0;
                        $scope.govtMin = 12;
                        $scope.AAMin = 12;
                        $scope.govtplusAASumMin = 12;
                        $scope.prepEngagementMin = 4;
                    }
                    else if (noOfMonths >= 6 && noOfMonths < 12) {
                        $scope.totalCeHoursMin = 20;
                        $scope.totalTechnicalCEHoursMin = 10;
                        $scope.firstYearHoursMin = 0;
                        $scope.firstYearTechnicalHoursMin = 0;
                        $scope.secondYearHoursMin = 0;
                        $scope.secondYearTechnicalHoursMin = 0;
                        $scope.ethicsHoursMin = 0;
                        $scope.fraudMin = 0;
                        $scope.govtMin = 6;
                        $scope.AAMin = 6;
                        $scope.govtplusAASumMin = 6;
                        $scope.prepEngagementMin = 2;
                    }
                }
                $scope.getLegalInfoConfig()
                    .then(function (data) {
                        var source = "";
                        var ContentLkToPageTabSectionIds = '3';
                        $scope.legalInfoConfig = data;
                        if ($scope.isRenewal) {
                            $scope.isConfigLoaded = true;
                            source = "cbaRenewal";
                        } else {
                            source = "cadcabpelsgNewApp";
                        }
                        if (!$scope.isRenewal) {
                            AuditVisitInfoSave();
                        }
                        if ($scope.useType == 'Affidavit') {
                            ContentLkToPageTabSectionIds = '3';
                        }
                        if ($scope.useType == 'continuingEducation') {
                            ContentLkToPageTabSectionIds = '4';
                        }
                        if (!!$scope.loadData) {

                            initialApplicationLegalInfoService.legalInfoGet($scope.individualId, $scope.applicationId, ContentLkToPageTabSectionIds, sessionStorage.Key, source)
                                .then(function (response) {
                                    if ($scope.isRenewal) {
                                        $scope.legalInfo = response.IndividualLegalResponse;
                                        $scope.legalInfo = processIndividualLegalResponse($scope.legalInfo);
                                    } else {
                                        var dd = processIndividualLegalResponse(response.IndividualLegalResponse);
                                        $scope.legalInfos = dd;
                                        $scope.isConfigLoaded = true;
                                    }
                                    backofficeIndividualLicenseService.individualLicenseDetailBYIndividualId(sessionStorage.Key, $scope.individualId)
                                        .then(function (responseItem) {
                                            $scope.activeIndividualLicense = responseItem.IndividualLicenseList.where('!!item.IsLicenseActive').orderByDescending('IndividualLicenseId').firstOrDefault();

                                            var LinkIndividualLicenseId = 1;
                                            $scope.activeIndividualLicense = responseItem.IndividualLicenseList.where('!!item.IsLicenseActive').orderByDescending('IndividualLicenseId').firstOrDefault();

                                            if($scope.applicationInfo.ApplicationStatusId == 3){
                                                LinkIndividualLicenseId = $scope.activeIndividualLicense.LinkIndividualLicenseId;
                                                $scope.activeIndividualLicense = responseItem.IndividualLicenseList.whereEquals(LinkIndividualLicenseId,'IndividualLicenseId').firstOrDefault();
                                            }
                                            if ($scope.useType == 'continuingEducation') {
                                                $scope.processCEHoursValidationRules();
                                                $scope.flagForReview();
                                            }
                                        }, function (error) {
                                            HideLoader();
                                            $scope.showStatusMessage({
                                                messageContent: 'Some error occurred!',
                                                messageType: 'error'
                                            });
                                        });
                                    HideLoader();
                                }, function (error) {
                                    HideLoader();
                                    $scope.showStatusMessage({
                                        messageContent: 'Some error occurred !',
                                        messageType: 'error'
                                    });
                                });
                            // if (!$scope.isRenewal) {
                            //     getaffidavit();
                            // }
                        }
                        if ($scope.isRenewal) {

                        } else {
                            countryStateService.getStateByCountryID(sessionStorage.Key, $rootScope.DefaultCountryId).then(function (stateResponse) {
                                $scope.StatList = stateResponse.State;
                            }, function (error) {

                            });
                        }
                    }, function (error) {
                        HideLoader();
                        $scope.showStatusMessage({
                            messageContent: 'Some error occurred !',
                            messageType: 'error'
                        });
                    });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
                $exceptionHandler(ex);
            }
        };

        $scope.DisplayCASLicensePrimaryStatus = async function(ApplicationInformation){
            ShowLoader();
            let requestData = {
                isn: ApplicationInformation,
            }
                try {
                    return await dcaIService.getCaLicFilePrimStatCde(sessionStorage.Key, requestData)
                        .then(function (response){
                        
                            if (!!response.EntityRecords && response.EntityRecords.length > 0) {
                                // return response.EntityRecords;
     
                                if (response.EntityRecords[0].primStatCde.includes(13))

                                {    
                                    $scope.isRetiredOrDelinquent = true;                                    $rootScope.isRetiredOrDelinquent = true;                                    
                                    $scope.legalInfo = processIndividualLegalResponse($scope.legalInfo);
                                    // var hideCETab = true;
                                    // $scope.$emit('processTabsToBeShown', { hideCETab: hideCETab });
                                }
                            }
                            
                        }, function(data) {
                            $scope.showStatusMessage(data, "error");
                           
                        });
                } catch (ex) {
                    if (ex != null) {
                        $scope.showStatusMessage(ex.message, "error");
                    }
                }
            }
            

        var getaffidavit = function () {
            var src = 'cadcabpelsgNewApp';
            if ($scope.applicationInfo.ApplicationTypeId == 9) {
                src = 'cadcabpelsgNewApp';
            }
            initialApplicationAcknowledgementService.acknowledgementGet(sessionStorage.Key, $scope.individualId, $scope.applicationId)
                .then(function (res) {
                    if (res.Status && res.IndividualAffidavitResponseList && res.IndividualAffidavitResponseList.length > 0) {
                        $scope.acknowledgement = res.IndividualAffidavitResponseList[0];
                        $scope.acknowledgement.IndividualId = $scope.individualId;
                        $scope.acknowledgement.ApplicationId = $scope.applicationId;
                        if (!$scope.acknowledgement.Date) {
                            $scope.acknowledgement.Date = new Date();
                        }
                    } else {
                        $scope.acknowledgement.Date = new Date();
                    }
                    console.log(res.IndividualAffidavitResponseList);
                }, function (error) {

                });
        }

        $scope.loadIndividualLegalAddInfo = function (legalInfoOption, hideLoader) {
            initialApplicationLegalInfoService.loadAllIndividualLegalAddInfo($scope.individualId, $scope.applicationId, $scope.userId, legalInfoOption, sessionStorage.Key)
                .then(function (response) {
                    if (!!response && response.length > 0) {
                        legalInfoOption.individualLegalAddInfo = response[0];
                    } else {
                        legalInfoOption.individualLegalAddInfo = {
                            IsActive: true,
                            IndividualLegalId: legalInfoOption.IndividualLegalId,
                            IndividualId: $scope.individualId,
                            ApplicationId: $scope.applicationId
                        }
                    }
                    if (hideLoader)
                        HideLoader();
                }, function (data) {
                    ShowErrMsg();
                });
        };

        var processIndividualLegalResponse = function (legalInfoData) {
            var ques3 = '';
            var ques4 = '';
            var ques5 = '';
            var ques6 = '';
            angular.forEach(legalInfoData, function (legalInfo) {
                if (legalInfo.ContentItemLkId == 814 || legalInfo.ContentItemLkId == 813 || legalInfo.ContentItemLkId == 819 || legalInfo.ContentItemLkId == 820) {
                    legalInfo.IsActive = false;
                }
                
                if (legalInfo.ContentItemLkId == 549) {
                    ques3 = legalInfo.ContentDescription;
                    legalInfo.IsActive = false;
                }
                if (legalInfo.ContentItemLkId == 550) {
                    ques4 = legalInfo.ContentDescription;
                    legalInfo.IsActive = false;
                }
                if (legalInfo.ContentItemLkId == 551) {
                    ques5 = legalInfo.ContentDescription;
                    legalInfo.IsActive = false;
                }
                if (legalInfo.ContentItemLkId == 552) {
                    ques6 = legalInfo.ContentDescription;
                    legalInfo.IsActive = false;
                }
                if ($scope.isRetiredOrDelinquent && ( legalInfo.ContentItemLkId == 13 || legalInfo.ContentItemLkId == 14 || legalInfo.ContentItemLkId == 22 || legalInfo.ContentItemLkId == 23)) {
                    legalInfo.IsActive = false;
                }
            });
            var legalInfoRecords = [];
            $scope.legalInfoList = {};
            // var tabContentData = $scope.contentItemsByUseType[$scope.useType];
            if ($scope.isRenewal) {
                // tabContentData = $scope.contentItemsByUseType['Affidavit'];
            }
            angular.forEach(legalInfoData, function (legalInfo) {
                if ($scope.useType == 'continuingEducation') {
                    if ($scope.individualLicense.reEntryLicensee || $scope.individualLicense.newLicensee) {
                        if (legalInfo.ContentItemLkId == 542)
                            legalInfo.ContentDescription = ques3;
                        if (legalInfo.ContentItemLkId == 543)
                            legalInfo.ContentDescription = ques4;
                        if (legalInfo.ContentItemLkId == 544)
                            legalInfo.ContentDescription = ques5;
                        if (legalInfo.ContentItemLkId == 539)
                            legalInfo.ContentDescription = ques6;
                    }
                }

                legalInfo.viewConfig = {};
                // legalInfo.tabContentItem = tabContentItem;
                legalInfo.IndividualId = $scope.individualId;
                legalInfo.ApplicationId = $scope.applicationId;


                if (!!legalInfo.DocId) {

                    legalInfo.documentUpload = {
                        ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                        ApplicationId: $scope.applicationId,
                        IndividualId: $scope.individualId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FR",
                        LoadByDocIdAndDocCode: true,
                        DocId: legalInfo.DocId,
                        DocCode: legalInfo.DocCode,
                        LoadDocumentsWithoutBroadCast: true,
                        hideValidationMarks: true
                    };
                }


                if (legalInfo.ContentItemLkId == 12 || legalInfo.ContentItemLkId == 23 || legalInfo.ContentItemLkId == 547) {
                    $scope.loadIndividualLegalAddInfo(legalInfo);
                }
                legalInfoRecords.push(legalInfo);
                //  }
                // }
                //  });
                $scope.legalInfoList['item' + legalInfo.ContentItemLkId] = legalInfo;
            });
            return legalInfoRecords;
        };


        $scope.showChildOrvalidationRequired = function (legalInfoOption) {
            return ((
                (
                    (
                        (
                            (legalInfoOption.ContentItemLkId > 538 && legalInfoOption.ContentItemLkId != 547 && legalInfoOption.ContentItemLkId != 548 && legalInfoOption.ContentItemLkId != 545) 
                            || (legalInfoOption.ContentItemLkId == 547 && $scope.legalInfoList.item546.ContentItemResponse == true) 
                            || (legalInfoOption.ContentItemLkId == 548 && $scope.legalInfoList.item547.ContentItemResponse == true) 
                            || (legalInfoOption.ContentItemLkId == 545 
                                && !($scope.individualLicense.reEntryLicensee || $scope.individualLicense.newLicensee) 
                                && ($scope.legalInfoList.item542.ContentItemResponse == true || $scope.legalInfoList.item543.ContentItemResponse == true 
                                    || $scope.legalInfoList.item544.ContentItemResponse == true)
                                )
                        ) 
                        && ($scope.individualLicense.LicenseStatusTypeId != 9 && $scope.individualLicense.LicenseStatusTypeId != 4)
                    ) 
                    || (legalInfoOption.ContentItemLkId == 540)
                ) 
                && $scope.useType == 'continuingEducation') 
                || 
                (!$scope.isRetiredOrDelinquent && legalInfoOption.ContentItemLkId == 15 || legalInfoOption.ContentItemLkId == 16 || legalInfoOption.ContentItemLkId == 21)
                ||
                (
                    (legalInfoOption.ContentItemLkId == 11 
                    ||
                    (legalInfoOption.ContentItemLkId == 12 && $scope.legalInfoList.item11.ContentItemResponse == true)
                    ||
                    (
                                ($scope.individualLicense.LicenseStatusTypeId != 9  && $scope.individualLicense.LicenseStatusTypeId != 4) 
                            && (legalInfoOption.ContentItemLkId != 12 || (legalInfoOption.ContentItemLkId == 12 && $scope.legalInfoList.item11.ContentItemResponse == true)) 
                            && (legalInfoOption.ContentItemLkId != 22 || (legalInfoOption.ContentItemLkId == 22 && $scope.legalInfoList.item13.ContentItemResponse == true)) 
                            && (legalInfoOption.ContentItemLkId != 23 || (legalInfoOption.ContentItemLkId == 23 && $scope.legalInfoList.item22.ContentItemResponse == true))
                        )
                    ) 
                    && $scope.useType == 'Affidavit'
                )
                );
        };

        $scope.saveVeteranInfo = function (currentVeteranForm, next) {
            $scope.IndividualConfirmationVeteran.ModifiedOn = new Date();
            $scope.IndividualConfirmationVeteran.IndividualLicenseId = $scope.individualLicense ? $scope.individualLicense.IndividualLicenseId : null;
            $scope.IndividualConfirmationVeteran.IndividualId = $scope.individualId;
            $scope.IndividualConfirmationVeteran.ApplicationId = $scope.applicationId;
            initialApplicationVeteranInfoService.saveVeteranInfoConfirmation([$scope.IndividualConfirmationVeteran], sessionStorage.Key)
                .then(function (response) {
                    if (response.Status) { }

                }, function (data) {
                    ShowErrMsg("unableToSaveVeteranInfo", data);
                });
        };

        $scope.$on("SaveRenewal", function (event, args) {
            $scope.legalInfoSave($scope.currentForm);
        });

        $scope.$on('SwitchingTab', function (event, args) {
            if (args.fromTab == $scope.tab.name) {
                if (!$scope.isBackoffice) {
                    $scope.legalInfoSave($scope.currentForm, true);
                } else {
                    $scope.$broadcast("SaveDeficiency", {});
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
            }
        });

        $scope.$on('deficiencyResolve', function (event, args) {
            if (args.tabName == 'background') {
                $scope.legalInfoSave($scope.currentForm, { fromDeficiency: true });
            }
        });

        $scope.clearTabConfirmation = function () {
            $scope.$emit("clearTabConfirmation", { currentTab: $scope.tab });
        };

        $scope.changeLicenseType = function (licenseStatusTypeId) {
            if ($scope.isRetiredOrDelinquent) {
                
                $scope.showLegalInfoForRetiredDelinquent = licenseStatusTypeId == 9 ? false : true;                
                // $scope.$emit('processTabsToBeShownIfNotRetired', $scope.individualLicense.LicenseStatusTypeId );
            }
            var hideCETab = false;
            $scope.individualLicense.LicenseStatusTypeId = licenseStatusTypeId;
            $scope.individualLicense.ActionFlag = "M";
            ShowLoader("Please wait...");
            //  licenseApplicationListingService.saveIndividualLicense(sessionStorage.Key, $scope.individualLicense)

            initialApplicationStaffReviewService.saveIndividualLicense(sessionStorage.Key, $scope.individualLicense).then(function (response) {
                if (response.Status) {
                    if (licenseStatusTypeId == 9) {
                        $rootScope.submissionLicenseStatusTypeId = 3;
                        hideCETab = true;
                    }

                    // $scope.individualLicense = response.IndividualLicenseList.whereEquals($scope.applicationId, 'ApplicationId').firstOrDefault();
                    $scope.$emit('processTabsToBeShown', { hideCETab: hideCETab });
                    HideLoader();
                } else {
                    HideLoader();
                }
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage({
                    messageContent: 'Some error occurred !',
                    messageType: 'error'
                });
            });
        }

        init();
    }
})();