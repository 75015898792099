/**
 * Created by handsome on 2/4/2017.
 */

(function () {
    'use strict';
    angular.module('app.backofficeAdministor').factory('contentFactory', contentFactory);
    contentFactory.$inject = ['$http', '$q', 'WebApiUrl'];
    function contentFactory($http, $q, WebApiUrl) {
        var _fillGrid = function (key, ContentLkToPageTabSectionId) {
            var url;
            if (ContentLkToPageTabSectionId == '')
                url = (WebApiUrl + '/Content/ContentItemLkGetAll/' + key);
            else
                url = (WebApiUrl + '/Content/ContentGetBYContentLkToPageTabSectionId/' + key + '?ContentLkToPageTabSectionId=' + ContentLkToPageTabSectionId);

            var data = $http.get(url);
            return returnResult(data);
        };
        var _getAllPages = function (key) {
            var data = $http.get(WebApiUrl + '/Page/PageGetAllPageNames/' + key);

            return returnResult(data);
        };

        var _getAllTabsSubModule = function (key, PageModuleId) {
            var data = $http.get(WebApiUrl + 'Page/PageGetAllTabsByPageModuleId/' + key + '?PageModuleId=' + PageModuleId);
            return returnResult(data);
        };

        var _getAllTabSection = function (key, PageModuleTabSubModuleId) {
            var data = $http.get(WebApiUrl + 'Page/PageGetAllSectionsByTabId/' + key + '?PageModuleTabSubModuleId=' + PageModuleTabSubModuleId);

            return returnResult(data);
        };

        var _getAllContentType = function (key, PageModId, PageTabSecId, PageModTabSubModId) {
            var data = $http.get(WebApiUrl + '/Content/ContentGetContentTypeNameByPageTabSectionId/' + key + '?PageModId=' + PageModId + '&PageTabSecId=' + PageTabSecId + '&PageModTabSubModId=' + PageModTabSubModId);

            return returnResult(data);
        };

        var _updateContentById = function (key, obj) {
            var data = $http.post(WebApiUrl + '/Content/ContentUpdateContentInfo/' + key, obj);

            return returnResult(data);
        };
        var returnResult = function (data) {
            var deferred = $q.defer();
            data
                .then(function (response) {
                    deferred.resolve(response.data);
                })
                .catch(function (response) {
                    deferred.reject(response);
                });
            return deferred.promise;
        };
        return {
            fillGrid: _fillGrid,
            getAllPages: _getAllPages,
            getAllTabsSubModule: _getAllTabsSubModule,
            getAllTabSection: _getAllTabSection,
            getAllContentType: _getAllContentType,
            updateContentById: _updateContentById
        };
    }
})();
