(function () {
    'use strict'
    angular.module('app.provider')
        .directive('ceProviderSignInController', ceProviderSignInControllerDirective);

        ceProviderSignInControllerDirective.$inject = [];

    function ceProviderSignInControllerDirective() {
        return {
            restrict: 'E',
            scope: {
                hideStatusMessage : "&",
                hideValidationSummary: "&",
                checkServerResponse :"&",
                validateData :"&",
                providerId :"=",
                applicationId: '=',
                showStatusMessage:"&", 
                cssClass:'=?'
            },
            templateUrl: "app/components/provider/directive/provider-sign-in/provider-sign-in.html",
            controller: "CeProviderSignInController"
        }
    }
})();