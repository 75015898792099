(function () {
    'use strict'

    angular.module('task.management')
        .factory('TaskManagementJobDetailsService', ['$http', '$q', 'WebApiUrl', TaskManagementJobDetailsService]);

    function TaskManagementJobDetailsService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
                .then(function (res) {
                    if (!!success) {
                        success(res);
                    }
                    deferred.resolve(res.data);
                }, function (error) {
                    if (!!fail) {
                        fail(error);
                    }
                    console.log("Request failed with status: " + error.status);
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getWindowsServiceStatus = function () {
            return responseHandler($http.get(WebApiUrl + 'TaskManager/GetWindowsServiceStatus/' + sessionStorage.Key));
        };

        var _updateWindowsServiceStatus = function (action) {
            return responseHandler($http.post(WebApiUrl + 'TaskManager/UpdateWindowsServiceStatus/' + sessionStorage.Key, null, { params: { action: action } }));
        };

        return {
            getWindowsServiceStatus: _getWindowsServiceStatus,
            updateWindowsServiceStatus: _updateWindowsServiceStatus
        };
    }
})();