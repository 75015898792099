/// <reference path="built-in-type-extensions.ts" />

//Built-in Type Methods
(function () {
    'use strict'

    // var getParamNames = (function(reComments, reParams, reNames) {
    //     var gpn = function(fn) {
    //       return ((fn + '').replace(reComments, '').match(reParams) || [0, ''])[1].match(reNames) || [];
    //     };

    //     return gpn;
    //   })(/\/\*[\s\S]*?\*\/|\/\/.*?[\r\n]/g, /\(([\s\S]*?)\)/, /[$\w]+/g);

    var sanitizeForEval = function (expression, allowedIdentifiers, type, multipleItems) {
        var identifiers = allowedIdentifiers.map(function (i) { return i + '[0-9]*'; }).join('|');
        var testRegEx = null;
        
        if (type == 'predicate') {
            testRegEx = new RegExp('(?:!*(?:{0})(?:\\.(?:\\w|\\d)+)*(?: ?(?:==|!=|<=|>=|<|>)* ?((?:\\w|\\d)+(?:\\.(?:\\w|\\d)+)*|(?:"(?:.*)")))? ?(&&|\\|\\|)?)+'.format(identifiers), 'gi');
        } else if (['value', 'order', 'key']) {
            if (multipleItems) {
                testRegEx = new RegExp('(?:((?:\\w|\\d)* ?: ?)?(item[0-9]*(?:\\.(?:\\w|\\d)+)|this1|this2))', 'gi');
            } else {
                testRegEx = new RegExp('((?:\\w|\\d|\\.)*)', 'gi');
            }
        }
        
        var santizedExpression = '';
        var matches = testRegEx.exec(expression);

        if (!matches) {
            throw 'Invalid predicate - the "item" in the array must be in the left operand.';
        }

        while (!!matches && !!matches[0]) {
            if (!!matches[1] && (matches[1].includes('function') || matches[1].includes('=') || matches[1].includes('(') || matches[1].includes(')'))) {
                throw 'Invalid predicate - Cannot define functions, use parentheses, or use the assignment operator in the right operand';
            }

            santizedExpression = santizedExpression.concat(' ' + matches[0]);

            matches = testRegEx.exec(expression);
        }

        return santizedExpression.trim();
    };

    var setCallback = function (mapSpecifier, mapType, multipleItems) {
        var callback;

        multipleItems = !!multipleItems;

        if (typeof (mapSpecifier) === 'string') {
            switch (mapType) {
                case 'value':
                    var properties = mapSpecifier.split(',');
                    if (multipleItems) {
                        var propValues = [];
                        properties.forEach(function (prop) {
                            var testRegEx = new RegExp('(?:((?:\\w|\\d)* ?: ?)?(item[0-9]*(?:\\.(?:\\w|\\d)+)|this1|this2))', 'gi');
                            var propValue = '';
                            var matches = testRegEx.exec(prop);

                            if (!matches) {
                                throw 'Invalid value map - the "item1" or "item2" must be used.';
                            }

                            while (!!matches) {
                                if (!!matches[1] && (matches[1].includes('function') || matches[1].includes('=') || matches[1].includes('(') || matches[1].includes(')'))) {
                                    throw 'Invalid value map - Cannot define functions, use parentheses, or use the assignment operator in the right operand';
                                }

                                propValue = propValue.concat(' ' + matches[0]);

                                matches = testRegEx.exec(prop);
                            }

                            propValues.push(propValue);
                        });

                        callback = function (item1, item2, index1, index2, array1, array2) {
                            var objTemplate = '({{0}})';
                            var newObj = '';
                            var propTemplate = '{0}:{1}, ';
                            var properties = '';

                            var createThis = function (item, itemName) {
                                if (!item) {
                                    return '';
                                }

                                var thisProps = '';

                                for (var itemProp in item) {
                                    thisProps = thisProps.concat(propTemplate.format(itemProp, itemName + '.' + itemProp));
                                }

                                return thisProps;
                            };
                            var itemIsNull = function (propValue) {
                                //If the propValue is empty, 
                                //or if it indicates an empty object,
                                //or if the value uses item1 and item1 is null, 
                                //or if the value uses item2 and item2 is null
                                return (!propValue) || (propValue == '({})') || (propValue.includes('item1') && !item1) || (propValue.includes('item2') && !item2);
                            };

                            propValues.forEach(function (propNameValue) {
                                var nameParsing = propNameValue.split(':');
                                var propName = '';
                                var propValue = '';

                                if (nameParsing.length > 1) {
                                    // { stuff1: item1.stuff }
                                    propName = nameParsing[0];

                                    //A name was specified, so it's just the prop value
                                    if (nameParsing[1].trim() == 'this1') {
                                        propValue = objTemplate.format(createThis(item1, 'item1'));
                                    } else if (nameParsing[1].trim() == 'this2') {
                                        propValue = objTemplate.format(createThis(item2, 'item2'));
                                    } else {
                                        propValue = nameParsing[1];
                                    }

                                    if (itemIsNull(propValue)) {
                                        properties = properties.concat(propTemplate.format(propName, 'null'));
                                    } else {
                                        properties = properties.concat(propTemplate.format(propName, propValue));
                                    }
                                } else {
                                    var valueParsing = nameParsing[0].split('.');

                                    if (valueParsing.length > 1) {
                                        // { stuff: item1.stuff }
                                        propName = valueParsing[1];
                                        propValue = nameParsing[0];

                                        if (itemIsNull(propValue)) {
                                            properties = properties.concat(propTemplate.format(propName, 'null'));
                                        } else {
                                            properties = properties.concat(propTemplate.format(propName, propValue));
                                        }
                                    } else {
                                        // { item1: item1 }
                                        //No name was specified, so all the properties are set
                                        if (nameParsing[0].trim() == 'this1') {
                                            properties = properties.concat(createThis(item1, 'item1'));
                                        } else if (nameParsing[0].trim() == 'this2') {
                                            properties = properties.concat(createThis(item2, 'item2'));
                                        } else {
                                            propName = nameParsing[0];
                                            propValue = nameParsing[0];

                                            properties = properties.concat(propTemplate.format(propName, propValue));
                                        }
                                    }
                                }
                            });

                            newObj = objTemplate.format(properties);

                            return eval(newObj);
                        };
                    } else {
                        callback = function (a, i, arr) {
                            var newVal = {};

                            if (properties.length == 1) {
                                var expression = sanitizeForEval('a.' + properties[0], ['a', 'i', 'arr'], mapType, multipleItems);
                                newVal = eval(expression);
                            } else {
                                properties.forEach(function (prop) {
                                    var expression = sanitizeForEval('a.' + prop, ['a', 'i', 'arr'], mapType, multipleItems);
                                    newVal[prop] = eval(expression);
                                });
                            }

                            return newVal;
                        };
                    }
                    break;
                case 'predicate':
                    var expression = sanitizeForEval(mapSpecifier, ['item', 'index', 'array'], mapType, multipleItems);

                    if (multipleItems) {
                        callback = function (item1, item2, index1, index2, array1, array2) {
                            return eval(expression);
                        };
                        //To hopefully get ahead of any potential issues with minification
                        var paramNames = callback.getParamNames();
                        expression = expression.replace('item1', paramNames[0]);
                        expression = expression.replace('item2', paramNames[1]);
                        expression = expression.replace('index1', paramNames[2]);
                        expression = expression.replace('index2', paramNames[3]);
                        expression = expression.replace('array1', paramNames[4]);
                        expression = expression.replace('array2', paramNames[5]);
                    } else {
                        callback = function (item, index, array) {
                            return eval(expression);
                        };
                        //To hopefully get ahead of any potential issues with minification
                        var paramNames = callback.getParamNames();
                        expression = expression.replace('item', paramNames[0]);
                        expression = expression.replace('index', paramNames[1]);
                        expression = expression.replace('array', paramNames[2]);
                    }

                    break;
                case 'order':
                    var properties = mapSpecifier.split(',');
                    callback = function (a, i, arr) {
                        var propArr = properties.map(function (prop) {
                            var expression = sanitizeForEval('a.' + prop, ['a', 'i', 'arr'], mapType, multipleItems);
                            return eval(expression);
                        });

                        return propArr;
                    };
                    break;
                case 'key':
                    var properties = mapSpecifier.split(',');
                    callback = function (a, i, arr) {
                        return properties.reduce(
                            function (val, prop) {
                                var expression = sanitizeForEval('a.' + prop, ['a', 'i', 'arr'], mapType, multipleItems);

                                return ((val.length > 0) ? val + ',' : '') + eval(expression);
                            }, '');
                    };
                    break;
            }
        } else if (typeof (mapSpecifier) === 'function') {
            callback = mapSpecifier;
        } else {
            if (multipleItems) {
                callback = function (a, b, i1, i2, arr1, arr2) {
                    return {
                        item1: a,
                        item2: b
                    };
                };
            } else {
                callback = function (a, i, arr) {
                    return a;
                };
            }
        }

        return callback;
    };

    //Array method definitions for compatibility with earlier ECMA versions
    if (!Array.prototype.findIndex) {
        Object.defineProperty(Array.prototype, 'findIndex', {
            writable: false,
            enumerable: false,
            value: function (predicate, thisArg) {
                predicate = (predicate && typeof (predicate) === 'function') ? predicate : function (a) {
                    return a;
                };

                var fIndex = function () {
                    var i = 0;
                    while (i < this.length) {
                        if (predicate.call(thisArg, this[i], i, this)) {
                            return i;
                        }
                        i++;
                    }

                    return -1;
                };

                return fIndex.call(this);
            }
        });
    }

    if (!Array.prototype.find) {
        Object.defineProperty(Array.prototype, 'find', {
            writable: false,
            enumerable: false,
            value: function (predicate, thisArg) {
                var i = this.findIndex(predicate, thisArg);

                return (i == -1) ? undefined : this[i];
            }
        });
    }

    if (!Array.prototype.includes) {
        Object.defineProperty(Array.prototype, 'includes', {
            writable: false,
            enumerable: false,
            value: function (element, start) {
                start = (typeof (start) !== 'undefined' && start != null) ? start : 0;

                return (this.findIndex(function (e, i) {
                    return e == element && i >= start;
                }) != -1);
            }
        });
    }

    //Array method definitions for supporting functionality
    if (!Array.prototype.equals) {
        Object.defineProperty(Array.prototype, 'equals', {
            writable: false,
            enumerable: false,
            value: function (cArr, keyMap) {
                keyMap = setCallback(keyMap, 'key', false);

                if (this.length != cArr.length) {
                    return false;
                }
                for (var i = 0; i < this.length; i++) {
                    var aKey = keyMap(this[i], i, this);
                    var bKey = keyMap(cArr[i], i, cArr);

                    if (Array.isArray(aKey)) {
                        aKey = aKey.join();
                    }
                    if (Array.isArray(bKey)) {
                        bKey = bKey.join();
                    }

                    if (aKey !== bKey) {
                        return false;
                    }
                }
                return true;
            }
        });
    }

    if (!Array.prototype.conditionCount) {
        Object.defineProperty(Array.prototype, 'conditionCount', {
            writable: false,
            enumerable: false,
            value: function (predicate, thisArg) {
                predicate = setCallback(predicate, 'predicate', false);

                var count = 0;

                this.forEach(function (e, i) {
                    if (predicate.call(thisArg, e, i, this)) {
                        count++;
                    }
                });

                return count;
            }
        });
    }

    if (!Array.prototype.toDict) {
        Object.defineProperty(Array.prototype, 'toDict', {
            writable: false,
            enumerable: false,
            value: function (keyMap, valueMap) {
                keyMap = setCallback(keyMap, 'key', false);
                valueMap = setCallback(valueMap, 'value', false);

                var d = {
                    keys: [],
                    values: [],
                    pairs: []
                };

                this.forEach(function (e, index) {
                    var k = keyMap(e, index, this);
                    var v = valueMap(e, index, this);
                    var cmp = function (ec) {
                        return ec == k;
                    };
                    var arrCmp = function (ec) {
                        return ec.equals(k);
                    }
                    var keyIndex = d.keys.findIndex(Array.isArray(k) ? arrCmp : cmp);

                    if (keyIndex == -1) {
                        d.keys.push(k);
                        d.values.push([v]);
                    } else {
                        d.values[keyIndex].push(v);
                    }
                });

                for (var i = 0; i < d.keys.length; i++) {
                    d.pairs.push({
                        key: d.keys[i],
                        values: d.values[i]
                    });
                }

                return d;
            }
        });
    }

    if (!Array.prototype.conditionalSplice) {
        Object.defineProperty(Array.prototype, 'conditionalSplice', {
            writable: false,
            enumerable: false,
            value: function (predicate, thisArg) {
                predicate = setCallback(predicate, 'predicate', false);
                var indexesToSplice = [];

                this.forEach(function (e, index) {
                    if (predicate.call(thisArg, e, index, this)) {
                        indexesToSplice.push(index);
                    }
                });

                var arr = this;
                //Splice from the highest index down to the lowest.
                indexesToSplice = indexesToSplice.orderByDescending();
                indexesToSplice.forEach(function (i) {
                    arr.splice(i, 1);
                });
            }
        });
    }

    if (!Array.prototype.merge) {
        Object.defineProperty(Array.prototype, 'merge', {
            writable: false,
            enumerable: false,
            value: function (mArr) {
                var length = (mArr.length > this.length) ? mArr.length : this.length;
                var newArr = [];

                for (var i = 0; i < length; i++) {
                    var e1 = this[i];
                    var e2 = mArr[i];
                    var newE = [];

                    if (e1) {
                        if (Array.isArray(e1)) {
                            newE = newE.concat(e1);
                        } else {
                            newE.push(e1);
                        }
                    }

                    if (e2) {
                        if (Array.isArray(e2)) {
                            newE = newE.concat(e2);
                        } else {
                            newE.push(e2);
                        }
                    }

                    newArr.push(newE);
                }

                return newArr;
            }
        });
    }

    if (!Array.prototype.contains) {
        Object.defineProperty(Array.prototype, 'contains', {
            writable: false,
            enumerable: false,
            value: function (value, valueMap) {
                valueMap = setCallback(valueMap, 'value', false);
                var result = false;

                try {
                    this.forEach(function (a, i, arr) {
                        if (value == valueMap(a, i, arr)) {
                            result = true;
                            throw 'done';
                        }
                    });
                } catch (ex) {

                }

                return result;
            }
        });
    }

    if (!Array.prototype.firstIndex) {
        Object.defineProperty(Array.prototype, 'firstIndex', {
            writable: false,
            enumerable: false,
            value: function (value, valueMap) {
                valueMap = setCallback(valueMap, 'value', false);
                var index = -1;

                try {
                    this.forEach(function (a, i, arr) {
                        if (value == valueMap(a, i, arr)) {
                            index = i;
                            throw 'done';
                        }
                    });
                } catch (ex) {

                }

                return index;
            }
        });
    }

    if (!Array.prototype.allIndex) {
        Object.defineProperty(Array.prototype, 'allIndex', {
            writable: false,
            enumerable: false,
            value: function (value, valueMap) {
                valueMap = setCallback(valueMap, 'value', false);
                var indices = [];

                try {
                    this.forEach(function (a, i, arr) {
                        if (value == valueMap(a, i, arr)) {
                            indices.push(i);
                        }
                    });
                } catch (ex) {

                }

                return indices;
            }
        });
    }

    //Array methods that implement a C# LINQ-like functionality
    if (!Array.prototype.groupBy) {
        Object.defineProperty(Array.prototype, 'groupBy', {
            writable: false,
            enumerable: false,
            value: function (keyMap) {
                return this.toDict(keyMap).pairs;
            }
        });
    }

    if (!Array.prototype.orderBy) {
        Object.defineProperty(Array.prototype, 'orderBy', {
            writable: false,
            enumerable: false,
            value: function (orderVal) {
                orderVal = setCallback(orderVal, 'order', false);

                var sortedArr = [].concat(this);

                return sortedArr.sort(function (a, b) {
                    var aKey = orderVal(a);
                    var bKey = orderVal(b);

                    if (Array.isArray(aKey)) {
                        for (var i = 0; i < aKey.length; i++) {
                            if (!aKey[i] && !!bKey[i]) { 
                                return -1;
                            } else if (!!aKey[i] && !bKey[i]) {
                                return 1;
                            }

                            if (aKey[i] < bKey[i]) {
                                return -1;
                            } else if (aKey[i] > bKey[i]) {
                                return 1;
                            }
                        }
                    } else {
                        if (!aKey && !!bKey) { 
                            return -1;
                        } else if (!!aKey && !bKey) {
                            return 1;
                        }

                        if (aKey < bKey) {
                            return -1;
                        } else if (aKey > bKey) {
                            return 1;
                        }
                    }

                    return 0;
                });
            }
        });
    }

    if (!Array.prototype.orderByDescending) {
        Object.defineProperty(Array.prototype, 'orderByDescending', {
            writable: false,
            enumerable: false,
            value: function (orderVal) {
                orderVal = setCallback(orderVal, 'order', false);

                var sortedArr = [].concat(this);

                return sortedArr.sort(function (a, b) {
                    var aKey = orderVal(a);
                    var bKey = orderVal(b);

                    if (Array.isArray(aKey)) {
                        for (var i = 0; i < aKey.length; i++) {
                            if (!aKey[i] && !!bKey[i]) { 
                                return 1;
                            } else if (!!aKey[i] && !bKey[i]) {
                                return -1;
                            }

                            if (aKey[i] < bKey[i]) {
                                return 1;
                            } else if (aKey[i] > bKey[i]) {
                                return -1;
                            }
                        }
                    } else {
                        if (!aKey && !!bKey) { 
                            return 1;
                        } else if (!!aKey && !bKey) {
                            return -1;
                        }

                        if (aKey < bKey) {
                            return 1;
                        } else if (aKey > bKey) {
                            return -1;
                        }
                    }

                    return 0;
                });
            }
        });
    }

    if (!Array.prototype.where) {
        Object.defineProperty(Array.prototype, 'where', {
            writable: false,
            enumerable: false,
            value: function (predicate, thisArg) {
                predicate = setCallback(predicate, 'predicate', false);
                var result = [];

                this.forEach(function (e, i) {
                    if (predicate.call(thisArg, e, i, this)) {
                        result.push(e);
                    }
                });

                return result;
            }
        });
    }

    if (!Array.prototype.whereEquals) {
        Object.defineProperty(Array.prototype, 'whereEquals', {
            writable: false,
            enumerable: false,
            value: function (value, valueMap) {
                valueMap = setCallback(valueMap, 'value', false);

                return this.where(function (e, i, arr) {
                    return value == valueMap(e, i, arr);
                });;
            }
        });
    }

    if (!Array.prototype.whereNotEquals) {
        Object.defineProperty(Array.prototype, 'whereNotEquals', {
            writable: false,
            enumerable: false,
            value: function (value, valueMap) {
                valueMap = setCallback(valueMap, 'value', false);

                return this.where(function (e, i, arr) {
                    return value != valueMap(e, i, arr);
                });;
            }
        });
    }

    if (!Array.prototype.whereIncludes) {
        Object.defineProperty(Array.prototype, 'whereIncludes', {
            writable: false,
            enumerable: false,
            value: function (values, valueMap) {
                valueMap = setCallback(valueMap, 'value', false);

                if (!Array.isArray(values)) {
                    values = [values];
                }

                return this.where(function (e, i, arr) {
                    if (!!values.includes) {
                        return values.includes(valueMap(e, i, arr));
                    } else {
                        return false;
                    }
                });
            }
        });
    }

    if (!Array.prototype.whereNotIncludes) {
        Object.defineProperty(Array.prototype, 'whereNotIncludes', {
            writable: false,
            enumerable: false,
            value: function (values, valueMap) {
                valueMap = setCallback(valueMap, 'value', false);

                if (!Array.isArray(values)) {
                    values = [values];
                }

                return this.where(function (e, i, arr) {
                    if (!!values.includes) {
                        return !values.includes(valueMap(e, i, arr));
                    } else {
                        return false;
                    }
                });
            }
        });
    }

    if (!Array.prototype.whereContains) {
        Object.defineProperty(Array.prototype, 'whereContains', {
            writable: false,
            enumerable: false,
            value: function (value, valueMap) {
                valueMap = setCallback(valueMap, 'value', false);

                if (!value) {
                    return this;
                }

                if (typeof (value) !== 'string') {
                    throw 'whereContains is only usable for string comparison - comparison value is not a string';
                }

                return this.where(function (e, i, arr) {
                    var arrVal = valueMap(e, i, arr);
                    if (typeof (arrVal) !== 'string') {
                        throw 'whereContains is only usable for string comparison - value map returned a non-string';
                    } else {
                        return arrVal.includes(value);
                    }
                });
            }
        });
    }

    if (!Array.prototype.whereStartsWith) {
        Object.defineProperty(Array.prototype, 'whereStartsWith', {
            writable: false,
            enumerable: false,
            value: function (value, valueMap) {
                valueMap = setCallback(valueMap, 'value', false);

                if (!value) {
                    return this;
                }

                if (typeof (value) !== 'string') {
                    throw 'whereStartsWith is only usable for string comparison - comparison value is not a string';
                }

                return this.where(function (e, i, arr) {
                    var arrVal = valueMap(e, i, arr);
                    if (typeof (arrVal) !== 'string') {
                        throw 'whereStartsWith is only usable for string comparison - value map returned a non-string';
                    } else {
                        return arrVal.startsWith(value);
                    }
                });
            }
        });
    }

    if (!Array.prototype.whereIn) {
        Object.defineProperty(Array.prototype, 'whereIn', {
            writable: false,
            enumerable: false,
            value: function (value, valuesMap) {
                valuesMap = setCallback(valuesMap, 'value', false);

                if (!value) {
                    return this;
                }

                return this.where(function (e, i, arr) {
                    var arrIn = valuesMap(e, i, arr);
                    var valCopy = value;

                    if (typeof(arrIn) == 'string') {
                        arrIn = arrIn.split(',');
                        valCopy = value.toString();
                    }

                    if (!Array.isArray(arrIn)) {
                        throw 'whereIn is only used to search a single value to a mapped Array value in the array';
                    } else {
                        return arrIn.includes(valCopy);
                    }
                });
            }
        });
    }

    if (!Array.prototype.whereNotIn) {
        Object.defineProperty(Array.prototype, 'whereNotIn', {
            writable: false,
            enumerable: false,
            value: function (value, valuesMap) {
                valuesMap = setCallback(valuesMap, 'value', false);

                if (!value) {
                    return this;
                }

                return this.where(function (e, i, arr) {
                    var arrIn = valuesMap(e, i, arr);
                    var valCopy = value;

                    if (typeof(arrIn) == 'string') {
                        arrIn = arrIn.split(',');
                        valCopy = value.toString();
                    }

                    if (!Array.isArray(arrIn)) {
                        throw 'whereIn is only used to search a single value to a mapped Array value in the array';
                    } else {
                        return !arrIn.includes(valCopy);
                    }
                });
            }
        });
    }

    if (!Array.prototype.select) {
        Object.defineProperty(Array.prototype, 'select', {
            writable: false,
            enumerable: false,
            value: function (valueMap) {
                valueMap = setCallback(valueMap, 'value', false);

                return this.map(valueMap);
            }
        });
    }

    if (!Array.prototype.recursiveSelect) {
        Object.defineProperty(Array.prototype, 'recursiveSelect', {
            writable: false,
            enumerable: false,
            value: function (property, valueMap, ignore) {
                valueMap = setCallback(valueMap, 'value', false);

                ignore = (!!ignore) ? ignore : [];

                var stack = [this];
                var history = [];
                var selectArr = [];

                while (stack.length > 0) {
                    var e = stack.shift();
                    var prop = '';

                    history.push(e);

                    if (typeof (e) === 'object') {
                        for (prop in e) {
                            if (e.hasOwnProperty(prop)) {
                                var v = e[prop];
                                if (prop === property) {
                                    selectArr.push(v);
                                }

                                if (typeof (v) === 'object' && !history.includes(v) && !ignore.includes(prop)) {
                                    stack.push(v);
                                }
                            }
                        }
                    }
                }

                return selectArr.select(valueMap);
            }
        });
    }

    if (!Array.prototype.selectMany) {
        Object.defineProperty(Array.prototype, 'selectMany', {
            writable: false,
            enumerable: false,
            value: function (valueMap) {
                valueMap = setCallback(valueMap, 'value', false);

                var results = [];

                this.forEach(function (e, index, arr) {
                    var v = valueMap(e, index, arr);

                    if (Array.isArray(v)) {
                        results = results.concat(v);
                    } else {
                        results.push(v);
                    }
                });

                return results;
            }
        });
    }

    if (!Array.prototype.distinct) {
        Object.defineProperty(Array.prototype, 'distinct', {
            writable: false,
            enumerable: false,
            value: function (keyMap) {
                keyMap = setCallback(keyMap, 'key', false);

                var keys = [];
                var distinctArr = [];

                this.forEach(function (e, index, arr) {
                    var key = keyMap(e, index, arr);

                    if (!keys.includes(key)) {
                        keys.push(key);
                        distinctArr.push(e);
                    }
                });

                return distinctArr;
            }
        });
    }

    if (!Array.prototype.joinObjects) {
        Object.defineProperty(Array.prototype, 'joinObjects', {
            writable: false,
            enumerable: false,
            value: function (array2, on, valueMap) {
                var arr1 = [].concat(this);
                var arr2 = [].concat(array2);

                on = setCallback(on, 'predicate', true);
                valueMap = setCallback(valueMap, 'value', true);

                var result = [];

                arr1.forEach(function (item1, index1, array1) {
                    arr2.forEach(function (item2, index2, array2) {
                        if (on(item1, item2, index1, index2, array1, array2)) {
                            result.push(valueMap(item1, item2, index1, index2, array1, array2));
                        }
                    });
                });

                return result;
            }
        });
    }

    if (!Array.prototype.leftJoinObjects) {
        Object.defineProperty(Array.prototype, 'leftJoinObjects', {
            writable: false,
            enumerable: false,
            value: function (array2, on, valueMap) {
                var arr1 = [].concat(this);
                var arr2 = [].concat(array2);

                on = setCallback(on, 'predicate', true);
                valueMap = setCallback(valueMap, 'value', true);

                var result = [];

                arr1.forEach(function (item1, index1, array1) {
                    var isMatchFound = false;
                    arr2.forEach(function (item2, index2, array2) {
                        if (on(item1, item2, index1, index2, array1, array2)) {
                            result.push(valueMap(item1, item2, index1, index2, array1, array2));
                            isMatchFound = true;
                        }
                    });
                    if (!isMatchFound) {
                        result.push(valueMap(item1, null, index1, null, array1, array2));
                    }
                });

                return result;
            }
        });
    }

    if (!Array.prototype.intersect) {
        Object.defineProperty(Array.prototype, 'intersect', {
            writable: false,
            enumerable: false,
            value: function (cArr, keyMap) {
                keyMap = setCallback(keyMap, 'key', false);

                var results = [];

                this.forEach(function (e, index, arr) {
                    var a = keyMap(e, index, arr);

                    if (cArr.find(function (c) {
                            return a == keyMap(c, index, arr);
                        })) {
                        results.push(e);
                    }
                });

                return results;
            }
        });
    }

    if (!Array.prototype.except) {
        Object.defineProperty(Array.prototype, 'except', {
            writable: false,
            enumerable: false,
            value: function (cArr, keyMap) {
                keyMap = setCallback(keyMap, 'key', false);

                var results = [];

                this.forEach(function (e, index, arr) {
                    var a = keyMap(e, index, arr);

                    if (!cArr.find(function (c) {
                            return a == keyMap(c, index, arr);
                        })) {
                        results.push(e);
                    }
                });

                return results;
            }
        });
    }

    if (!Array.prototype.sum) {
        Object.defineProperty(Array.prototype, 'sum', {
            writable: false,
            enumerable: false,
            value: function (valueMap) {
                valueMap = setCallback(valueMap, 'value', false);

                var sum = 0;

                this.forEach(function (v, i, arr) {
                    sum += valueMap(v, i, arr);
                });

                if (!sum) {
                    sum = 0;
                }

                return sum;
            }
        });
    }

    if (!Array.prototype.any) {
        Object.defineProperty(Array.prototype, 'any', {
            writable: false,
            enumerable: false,
            value: function (predicate, thisArg) {
                predicate = setCallback(predicate, 'predicate', false);

                var result = false;
                try {
                    this.forEach(function (e, i) {
                        if (predicate.call(thisArg, e, i, this)) {
                            result = true;
                            throw 'true';
                        }
                    });
                } catch (ex) {

                }
                return result;
            }
        });
    }

    if (!Array.prototype.all) {
        Object.defineProperty(Array.prototype, 'all', {
            writable: false,
            enumerable: false,
            value: function (predicate, thisArg) {
                predicate = setCallback(predicate, 'predicate', false);

                var result = true;
                try {
                    this.forEach(function (e, i) {
                        if (!predicate.call(thisArg, e, i, this)) {
                            result = false;
                            throw 'false';
                        }
                    });
                } catch (ex) {

                }
                return result;
            }
        });
    }

    if (!Array.prototype.skip) {
        Object.defineProperty(Array.prototype, 'skip', {
            writable: false,
            enumerable: false,
            value: function (skip) {
                if (skip > this.length) {
                    return [];
                }

                return this.slice(skip);
            }
        });
    }

    if (!Array.prototype.take) {
        Object.defineProperty(Array.prototype, 'take', {
            writable: false,
            enumerable: false,
            value: function (take) {
                if (take > this.length) {
                    take = this.length;
                }

                return this.slice(0, take);
            }
        });
    }

    if (!Array.prototype.firstOrDefault) {
        Object.defineProperty(Array.prototype, 'firstOrDefault', {
            writable: false,
            enumerable: false,
            value: function () {
                if (this.length == 0) {
                    return null;
                } else {
                    return this[0];
                }
            }
        });
    }

    if (!Array.prototype.coalesce) {
        Object.defineProperty(Array.prototype, 'coalesce', {
            writable: false,
            enumerable: false,
            value: function () {
                for (var i = 0; i < this.length; i++) {
                    if (typeof (this[i]) !== 'undefined' && this[i] != null) {
                        return this[i];
                    }
                }
            }
        });
    }

    //Date methods
    if (!Date.prototype.getDiff) {
        Object.defineProperty(Date.prototype, 'getDiff', {
            enumerable: false,
            writeable: false,
            value: function (date2) {
                return TimeSpan.getDiff(this, date2);
            }
        });
    }

    if (!Date.prototype.addTimeSpan) {
        Object.defineProperty(Date.prototype, 'addTimeSpan', {
            writable: false,
            enumerable: false,
            value: function (timeSpan) {
                return this.addMilliseconds(timeSpan.totalMilliseconds);
            }
        });
    }

    if (!Date.prototype.subTimeSpan) {
        Object.defineProperty(Date.prototype, 'subTimeSpan', {
            writable: false,
            enumerable: false,
            value: function (timeSpan) {
                return this.addMilliseconds(-timeSpan.totalMilliseconds);
            }
        });
    }

    if (!Date.prototype.addYears) {
        Object.defineProperty(Date.prototype, 'addYears', {
            writable: false,
            enumerable: false,
            value: function (numYears) {
                var date = new Date(this.valueOf());
                date.setFullYear(date.getFullYear() + numYears);
                return date;
            }
        });
    }

    if (!Date.prototype.addMonths) {
        Object.defineProperty(Date.prototype, 'addMonths', {
            writable: false,
            enumerable: false,
            value: function (numMonths) {
                var date = new Date(this.valueOf());
                date.setMonth(date.getMonth() + numMonths);
                return date;
            }
        });
    }

    if (!Date.prototype.addDays) {
        Object.defineProperty(Date.prototype, 'addDays', {
            writable: false,
            enumerable: false,
            value: function (numDays) {
                var date = new Date(this.valueOf());
                date.setDate(date.getDate() + numDays);
                return date;
            }
        });
    }

    if (!Date.prototype.addHours) {
        Object.defineProperty(Date.prototype, 'addHours', {
            writable: false,
            enumerable: false,
            value: function (numHours) {
                var date = new Date(this.valueOf());
                date.setHours(date.getHours() + numHours);
                return date;
            }
        });
    }

    if (!Date.prototype.addMinutes) {
        Object.defineProperty(Date.prototype, 'addMinutes', {
            writable: false,
            enumerable: false,
            value: function (numMinutes) {
                var date = new Date(this.valueOf());
                date.setMinutes(date.getMinutes() + numMinutes);
                return date;
            }
        });
    }

    if (!Date.prototype.addSeconds) {
        Object.defineProperty(Date.prototype, 'addSeconds', {
            writable: false,
            enumerable: false,
            value: function (numSeconds) {
                var date = new Date(this.valueOf());
                date.setSeconds(date.getSeconds() + numSeconds);
                return date;
            }
        });
    }

    if (!Date.prototype.addMilliseconds) {
        Object.defineProperty(Date.prototype, 'addMilliseconds', {
            writable: false,
            enumerable: false,
            value: function (numMSeconds) {
                var date = new Date(this.valueOf());
                date.setMilliseconds(date.getMilliseconds() + numMSeconds);
                return date;
            }
        });
    }

    if (!Date.isDate) {
        Object.defineProperty(Date, 'isDate', {
            writable: false,
            enumerable: false,
            value: function (val) {
                var valType = typeof (val);

                //This could trigger if val is also a false Boolean value, but then it still wouldn't be a Date
                if (!val) {
                    return false;
                }

                //If it's a Date object, it's obviously a date, but if it's a string, then it might be able to be parsed
                //as a Date, so we check that too.
                if (valType == 'object' && val.getJSType() == 'Date') {
                    return true;
                } else if (valType == 'string') {
                    return !!val.getDateObj();
                }

                return false;
            }
        });
    }

    if (!Date.prototype.getDateObj) {
        //This method exists for compatibility when comparing Date objects and Strings that can be 
        //parsed as Date objects.  This way, if two variables are being compared, both can call a "getDateObj" method
        //regardless of its actual type (Date or String)
        Object.defineProperty(Date.prototype, 'getDateObj', {
            writable: false,
            enumerable: false,
            value: function () {
                return this;
            }
        });
    }

    //String methods to help with formatting
    if (!String.prototype.format) {
        Object.defineProperty(String.prototype, 'format', {
            writable: false,
            enumerable: false,
            value: function () {
                var regex = /\{(\d+)\}/gi;
                var result = this;
                var replacements = arguments;

                //Check that a sufficient number of arguments has been supplied
                while (true) {
                    var match = regex.exec(result);
                    if (!match) {
                        break;
                    }
                    var i = match[1]; //the format param index (the first/only capture group in the regex)
                    if (i >= arguments.length) {
                        throw 'Not enough arguments to format the string';
                    }
                };

                for (var i = 0; i < arguments.length; i++) {
                    var paramReplaceRegex = new RegExp('\{(' + i + ')\}', 'gi');

                    result = result.replace(paramReplaceRegex, replacements[i]);
                }

                return result;
            }
        });
    }

    if (!String.format) {
        Object.defineProperty(String, 'format', {
            writable: false,
            enumerable: false,
            value: function () {
                if (arguments.length == 0) {
                    throw 'A string to format must be supplied with enough additional arguments';
                }

                var initial = arguments[0];

                if (typeof (initial) !== 'string') {
                    throw 'First argument must be a string to format';
                }

                //Make a copy of the arguments array and splice out the "initial" value;
                var args = [].slice.call(arguments);
                args.splice(0, 1);

                return initial.format.apply(initial, args);
            }
        });
    }

    if (!String.prototype.toTitleCase) {
        Object.defineProperty(String.prototype, 'toTitleCase', {
            writable: false,
            enumerable: false,
            value: function () {
                //A very simple, naive title case method - could be better and handle more situations, but this will work for now.
                return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
            }
        });
    }

    if (!String.prototype.capitalize) {
        Object.defineProperty(String.prototype, 'capitalize', {
            writable: false,
            enumerable: false,
            value: function () {
                //A very simple, naive title case method - could be better and handle more situations, but this will work for now.
                return this.charAt(0).toUpperCase() + this.slice(1);
            }
        });
    }

    //String methods
    if (!String.prototype.getDateObj) {
        var momentFormatRegex = [{
                Regex: /(\d{4})-(\d{1,2})-(\d{1,2})T(\d{1,2}):(\d{1,2}):(\d{1,2})/g,
                Format: 'YYYY-MM-DDTHH:mm:ss'
            },
            {
                Regex: /(\d{1,2})\/(\d{1,2})\/(\d{4}) (\d{1,2}):(\d{1,2}):(\d{1,2})/g,
                Format: 'MM/DD/YYYY HH:mm:ss'
            },
            {
                Regex: /(\d{1,2})\/(\d{1,2})\/(\d{4}) (\d{1,2}):(\d{1,2})/g,
                Format: 'MM/DD/YYYY HH:mm'
            },
            {
                Regex: /(\d{4})-(\d{1,2})-(\d{1,2})/g,
                Format: 'YYYY-MM-DD'
            },
            {
                Regex: /(\d{1,2})\/(\d{1,2})\/(\d{4})/g,
                Format: 'MM/DD/YYYY'
            },
            {
                Regex: /(\d{4})-(\d{1,2})/g,
                Format: 'YYYY-MM'
            },
            {
                Regex: /(\d{1,2})\/(\d{4})/g,
                Format: 'MM/YYYY'
            }
        ];
        Object.defineProperty(String.prototype, 'getDateObj', {
            writable: false,
            enumerable: false,
            value: function () {
                if (!!this) {
                    //Regular expressions for the date formats supported (days are optional)
                    for (var i = 0; i < momentFormatRegex.length; i++) {
                        var regex = momentFormatRegex[i].Regex;
                        var format = momentFormatRegex[i].Format;

                        regex.lastIndex = 0;

                        var matches = regex.exec(this);
                        if (!!matches) {
                            return moment(this, format).toDate();
                        }
                    }

                    return null;
                } else {
                    return null;
                }
            }
        });
    }

    if (!String.prototype.includes) {
        Object.defineProperty(String.prototype, 'includes', {
            writable: false,
            enumerable: false,
            value: function (search, start) {
                if (typeof start !== 'number') {
                    start = 0;
                }

                if (start + search.length > this.length) {
                    return false;
                } else {
                    return this.indexOf(search, start) !== -1;
                }
            }
        });
    }

    if (!String.prototype.splice) {
        Object.defineProperty(String.prototype, 'splice', {
            enumerable: false,
            writeable: false,
            value: function (start, num, replace) {
                var startStr = this.slice(0, start);
                var endStr = this.slice(start + num);

                return startStr + replace + endStr;
            }
        });
    }

    if (!String.prototype.createHtmlSearch) {
        Object.defineProperty(String.prototype, 'toHtmlSearch', {
            enumerable: false,
            writeable: false,
            value: function () {
                return this.trim().toLowerCase()
                    .replace(/\./gmi, '\\.')
                    .replace(/%/gmi, '.*?')
                    .replace(/</gmi, '&lt;')
                    .replace(/>/gmi, '&gt;');
            }
        });
    }

    //Function methods
    if (!Function.prototype.getParamNames) {
        Object.defineProperty(Function.prototype, 'getParamNames', {
            writable: false,
            enumerable: false,
            value: (function (reComments, reParams, reNames) {
                var thisObj = this;

                var gpn = function (fn) {
                    if (!fn) {
                        fn = this;
                    }

                    var matchParams = ((fn + '').replace(reComments, '').match(reParams) || [0, ''])
                    return matchParams[1].match(reNames) || [];
                };

                return gpn;
            })(/\/\*[\s\S]*?\*\/|\/\/.*?[\r\n]/g, /\(([\s\S]*?)\)/, /[$\w]+/g)
        });
    }

    //Object methods
    if (!Object.prototype.getJSType) {
        Object.defineProperty(Object.prototype, 'getJSType', {
            writable: false,
            enumerable: false,
            value: function () {
                var funcNameRegex = /function (.{1,})\(/;
                var results = (funcNameRegex).exec((this).constructor.toString());
                return (results && results.length > 1) ? results[1] : "";
            }
        });
    }

    if (!Object.prototype.isEmptyObj) {
        Object.defineProperty(Object.prototype, 'isEmptyObj', {
            writable: false,
            enumerable: false,
            value: function () {
                return Object.keys(this).length == 0;
            }
        });
    }

    //HTMLElement Methods
    if (!jQuery.fn.findNodeNames) {
        jQuery.fn.extend({
            findNodeNames: function (nodeName) {
                var results = [];

                var queue = [this[0]];
                do {
                    var current = queue.shift();
                    if (!!current) {
                        if (current.nodeName == nodeName) {
                            results.push(current);
                        }

                        var children = current.childNodes;
                        for (var i = 0; i < children.length; i++) {
                            queue.push(children[i]);
                        }
                    }
                } while (queue.length != 0)

                return results;
            }
        });
    }

    if (!jQuery.fn.scrollTo) {
        jQuery.fn.extend({
            scrollTo: function () {
                var positionOfElement = this.offset();
                $('html, body').animate({
                    scrollTop: (!!positionOfElement ? positionOfElement.top - 50 : 0)
                }, '50');
            }
        });
    }

    //RegExp
    if (!RegExp.prototype.execAll) {
        Object.defineProperty(RegExp.prototype, 'execAll', {
            enumerable: false,
            writeable: false,
            value: function (strToSearch) {
                var results = [];

                var match = this.exec(strToSearch);
                while (!!match) {
                    results.push(match);
                    match = this.exec(strToSearch);
                }

                return results;
            }
        });
    }
})();

//TimeSpan class (similar to C# .NET TimeSpan)
function TimeSpan(days, hours, minutes, seconds, milliseconds) {
    this.days = (!!days) ? days : 0;
    this.hours = (!!hours) ? hours : 0;
    this.minutes = (!!minutes) ? minutes : 0;
    this.seconds = (!!seconds) ? seconds : 0;
    this.milliseconds = (!!milliseconds) ? milliseconds : 0;
}

//TimeSpan Methods
(function () {
    //Private Methods
    var convertMSToTimeSpan = function (ms) {
        var diff = ms;
        var mathFn = (ms > 0) ? Math.floor : Math.ceil;

        var days = mathFn(diff / (1000 * 60 * 60 * 24));
        diff -= days * (1000 * 60 * 60 * 24);

        var hours = mathFn(diff / (1000 * 60 * 60));
        diff -= hours * (1000 * 60 * 60);

        var minutes = mathFn(diff / (1000 * 60));
        diff -= minutes * (1000 * 60);

        var seconds = mathFn(diff / (1000));
        diff -= seconds * (1000);

        //The remainder (anything smaller than milliseconds is negligible, and therefore cut off)
        var milliseconds = mathFn(diff);

        return new TimeSpan(days, hours, minutes, seconds, milliseconds);
    };

    //Static Methods
    Object.defineProperty(TimeSpan, 'getDiff', {
        writable: false,
        enumerable: false,
        value: function (date1, date2) {
            return convertMSToTimeSpan(date1.getTime() - date2.getTime());
        }
    });
    Object.defineProperty(TimeSpan, 'from', {
        writable: false,
        enumerable: false,
        value: function (type, value) {
            var ms = 0;
            switch (type) {
                case 'milliseconds':
                case 'ms':
                default:
                    ms = value;
                    break;
                case 'seconds':
                case 's':
                    ms = value * 1000;
                    break;
                case 'minutes':
                case 'm':
                    ms = value * 60 * 1000;
                    break;
                case 'hours':
                case 'h':
                    ms = value * 60 * 60 * 1000;
                    break;
                case 'days':
                case 'd':
                    ms = value * 24 * 60 * 60 * 1000;
                    break;
            }

            ts = convertMSToTimeSpan(ms);

            return ts;
        }
    });
    Object.defineProperty(TimeSpan, 'parse', {
        writable: false,
        enumerable: false,
        value: function (timeStr) {
            var isoTime = /(-)?(?:(\d+)\.)?(\d{1,2}):(\d{1,2}):(\d{1,2})(?:\.(\d{1,3}))?/g;
            var matches = isoTime.exec(timeStr);
            var ts = null;

            if (!!matches && matches.length > 0) {
                var s = 1;
                if (matches[1] == '-') {
                    s = -1;
                }
                var days = s * parseFloat(matches[2]);
                var hours = s * parseFloat(matches[3]);
                var minutes = s * parseFloat(matches[4]);
                var seconds = s * parseFloat(matches[5]);
                var milliseconds = s * parseFloat(matches[6]);

                ts = new TimeSpan(days, hours, minutes, seconds, milliseconds);
            }

            return ts;
        }
    });

    //Arithmetic Operators
    Object.defineProperty(TimeSpan.prototype, 'add', {
        writable: false,
        enumerable: false,
        value: function (ts2) {
            return convertMSToTimeSpan(this.totalMilliseconds + ts2.totalMilliseconds);
        }
    });
    Object.defineProperty(TimeSpan.prototype, 'subtract', {
        writable: false,
        enumerable: false,
        value: function (ts2) {
            return convertMSToTimeSpan(this.totalMilliseconds - ts2.totalMilliseconds);
        }
    });

    //Boolean Operators
    Object.defineProperty(TimeSpan.prototype, 'equals', {
        writeable: false,
        enumerable: false,
        value: function (ts2) {
            return this.totalMilliseconds == ts2.totalMilliseconds;
        }
    });
    Object.defineProperty(TimeSpan.prototype, 'notEquals', {
        writeable: false,
        enumerable: false,
        value: function (ts2) {
            return this.totalMilliseconds != ts2.totalMilliseconds;
        }
    });
    Object.defineProperty(TimeSpan.prototype, 'lessThanEquals', {
        writeable: false,
        enumerable: false,
        value: function (ts2) {
            return this.totalMilliseconds <= ts2.totalMilliseconds;
        }
    });
    Object.defineProperty(TimeSpan.prototype, 'lessThan', {
        writeable: false,
        enumerable: false,
        value: function (ts2) {
            return this.totalMilliseconds < ts2.totalMilliseconds;
        }
    });
    Object.defineProperty(TimeSpan.prototype, 'greaterThanEquals', {
        writeable: false,
        enumerable: false,
        value: function (ts2) {
            return this.totalMilliseconds >= ts2.totalMilliseconds;
        }
    });
    Object.defineProperty(TimeSpan.prototype, 'greaterThan', {
        writeable: false,
        enumerable: false,
        value: function (ts2) {
            return this.totalMilliseconds > ts2.totalMilliseconds;
        }
    });

    //Computed Values
    Object.defineProperty(TimeSpan.prototype, 'totalMilliseconds', {
        enumerable: false,
        get: function () {
            var days = this.days * (1000 * 60 * 60 * 24);
            var hours = this.hours * (1000 * 60 * 60);
            var minutes = this.minutes * (1000 * 60);
            var seconds = this.seconds * (1000);
            var milliseconds = this.milliseconds;

            return days + hours + minutes + seconds + milliseconds;
        }
    });
    Object.defineProperty(TimeSpan.prototype, 'totalSeconds', {
        enumerable: false,
        get: function () {
            var days = this.days * (60 * 60 * 24);
            var hours = this.hours * (60 * 60);
            var minutes = this.minutes * (60);
            var seconds = this.seconds;
            var milliseconds = this.milliseconds / (1000);

            return days + hours + minutes + seconds + milliseconds;
        }
    });
    Object.defineProperty(TimeSpan.prototype, 'totalMinutes', {
        enumerable: false,
        get: function () {
            var days = this.days * (60 * 24);
            var hours = this.hours * (60);
            var minutes = this.minutes;
            var seconds = this.seconds / (60);
            var milliseconds = this.milliseconds / (1000 * 60);

            return days + hours + minutes + seconds + milliseconds;
        }
    });
    Object.defineProperty(TimeSpan.prototype, 'totalHours', {
        enumerable: false,
        get: function () {
            var days = this.days * (24);
            var hours = this.hours;
            var minutes = this.minutes / (60);
            var seconds = this.seconds / (60 * 60);
            var milliseconds = this.milliseconds / (1000 * 60 * 60);

            return days + hours + minutes + seconds + milliseconds;
        }
    });
    Object.defineProperty(TimeSpan.prototype, 'totalDays', {
        enumerable: false,
        get: function () {
            var days = this.days;
            var hours = this.hours / (24);
            var minutes = this.minutes / (60 * 24);
            var seconds = this.seconds / (60 * 60 * 24);
            var milliseconds = this.milliseconds / (1000 * 60 * 60 * 24);

            return days + hours + minutes + seconds + milliseconds;
        }
    });

    //Common Methods
    Object.defineProperty(TimeSpan.prototype, 'toString', {
        writable: false,
        enumerable: false,
        value: function () {
            var s = '';
            if (this.totalMilliseconds < 0) {
                s = '-';
            }
            return '{0}{1}.{2}:{3}:{4}.{5}'.format(s, Math.abs(this.days), Math.abs(this.hours), Math.abs(this.minutes), Math.abs(this.seconds), Math.abs(this.milliseconds));
        }
    });
})();