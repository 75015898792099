var Key = '';
angular.module('natApp').factory('TokenKeyService',["$http", function ($http) {
    return {
        SetTokanKey: function (key) {
            Key = key;
            return null;
        },
        CheckTokanKey: function () {
            return null;
        },

    };
}]);