(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeIndividualPermitController", BackofficeIndividualPermitController);

    BackofficeIndividualPermitController.$inject = ["$rootScope", "$scope", "$state", "$q", "backofficeIndividualPermitService",
        "backofficeIndividualLicenseService", "initialApplicationstatusService", "countryStateService", "typeValuesService",
        "lookupService", "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl", "loggingService", "Messages"
    ];

    function BackofficeIndividualPermitController($rootScope, $scope, $state, $q, backofficeIndividualPermitService,
        backofficeIndividualLicenseService, initialApplicationstatusService, countryStateService, typeValuesService,
        lookupService, utilityService, $exceptionHandler, TypeValue, WebApiUrl, loggingService, Messages) {

        $scope.viewConfig = {
            showAddAdministratorPermit: false,
            showAddSitePermit: false,
        };
        $scope.isAdministratorPermitEdit = false;
        $scope.isSitePermitEdit = false;
        $scope.permit = {};
        $scope.datePicker = {
            effectiveDateOpened: false,
            expirationDateOpened: false,
            BoardApprovalDateTempOpened: false,
            ACLSToDateOpened: false,
            ACLSFromDateOpened: false,
            BoardApprovalDatePermOpened: false,
            reEvaluationDateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            },
            effectiveDateOptions: {
                maxDate: null
            },
            expirationDateOptions: {
                minDate: null
            }
        };
        $scope.feeDetailsTemplate = {
            applicationFee: 200,
            totalFee: 200,
            lateFeeAmount: 0,
            lateFee: 0,
            isReInstatement: false,
            reInstatementFee: 0
        };
        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "permit" && !$rootScope.individualCurrentTab.isLoadedOnce) {
                if (!$scope.isLoadedOnce) {
                    $scope.isLoadedOnce = true;
                }
                $scope.IndividualId = individualId;
                $scope.UserId = sessionStorage.UserID;
                $scope.isBackoffice = true;
                $scope.validator = { feePayment: {} };
                $scope.loadPermitTabData($scope.IndividualId);
                $rootScope.individualCurrentTab.isLoadedOnce = true;
            }
        });

        $scope.setPermitEndMinDate = function (permit) {
            if (!!permit.PermitEffectiveDate && angular.isDate(permit.PermitEffectiveDate))
                $scope.datePicker.expirationDateOptions.minDate = permit.PermitEffectiveDate;
        };

        $scope.setPermitStartMaxDate = function (permit) {
            if (!!permit.PermitExpirationDate && angular.isDate(permit.PermitExpirationDate))
                $scope.datePicker.effectiveDateOptions.maxDate = permit.PermitExpirationDate;
        };

        $scope.loadPermitTabData = function (individualId) {
            var dataPromises = [$scope.individualLicenseDetailBYIndividualId($scope.IndividualId),
            $scope.individualPermitByIndividualId($scope.IndividualId, true, 1),
            $scope.individualPermitByIndividualId($scope.IndividualId, true, 2),
            $scope.permitStatusTypeGetAll(),
            $scope.permitApplicationTypeGetAll(),
            $scope.permitTypeGetAll()

            ];
            ShowLoader("Please Wait...");
            $q.all(dataPromises).then(function (response) {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                HideLoader();
            }, function (error) {
                HideLoader();
            });
        };

        var getPermitSiteAddress = function (individualId, individualLicenceId) {
            var promise = backofficeIndividualPermitService.getPermitSiteAddress(individualId, individualLicenceId, sessionStorage.Key);
            return promise;
        }

        $scope.permitApplicationTypeGetAll = function () {
            var promise = typeValuesService.getTypeValuesByTypeName(TypeValue.PermitApplicationType, sessionStorage.Key);
            promise.then(function (response) {
                $scope.permitApplicationTypeList = response.RefPermitApplicationTypeResponseList;
            }, function (err) {
                $scope.showStatusMessage(err.message, "error");
            });
            return promise;
        }

        $scope.permitTypeGetAll = function () {
            var promise = typeValuesService.getTypeValuesByTypeName(TypeValue.PermitType, sessionStorage.Key);
            promise.then(function (response) {
                $scope.permitTypeList = response.ApplicationTypeGetList;
            }, function (err) { $scope.showStatusMessage(err.message, "error"); });
            return promise;
        }

        $scope.permitStatusTypeGetAll = function () {
            try {
                var permitStatusPromise = typeValuesService.getTypeValuesByTypeName(TypeValue.PermitStatus, sessionStorage.Key);
                permitStatusPromise.then(function (response) {
                    if ($scope.checkServerResponse(response)) {
                        $scope.PermitStatusList = response.RefPermitStatusResponseList;
                    }
                }, function (data) {
                    if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                });
                return permitStatusPromise;
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        $scope.individualLicenseDetailBYIndividualId = function (individualId) {
            try {
                var individualLicenseDetailPromise = backofficeIndividualLicenseService.individualLicenseDetailBYIndividualId(sessionStorage.Key, individualId);
                individualLicenseDetailPromise.then(function (response) {
                    if ($scope.checkServerResponse(response)) {
                        $scope.IndividualLicenseList = response.IndividualLicenseList;
                    }
                }, function (data) {
                    HideLoader();
                    $scope.showStatusMessage(data, "error");
                });
                return individualLicenseDetailPromise;
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        };

        var showAdminstratorPermit = function () {
            $scope.viewConfig.showAddAdministratorPermit = true;
        };

        $scope.openAddEditAdministratorPermit = function (isEdit) {
            $scope.isAdministratorPermitEdit = isEdit;
            showAdminstratorPermit();
            $scope.closeAddEditSitePermit();
            if (!$scope.isAdministratorPermitEdit) {
                $scope.permit = {
                    IndividualId: $scope.IndividualId,
                    IsActive: true,
                    PermitTypeId: 2
                };
            }
            $scope.feeDetails = angular.copy($scope.feeDetailsTemplate);
            HideLoader();
        };

        var showSitePermit = function () {
            $scope.viewConfig.showAddSitePermit = true;
        };

        $scope.openAddEditSitePermit = function (isEdit) {
            $scope.isSitePermitEdit = isEdit;
            showSitePermit();
            $scope.closeAddEditAdministratorPermit();
            if (!$scope.isSitePermitEdit) {
                $scope.permit = {
                    IndividualId: $scope.IndividualId,
                    IsActive: true,
                    PermitTypeId: 1
                };
            }
            $scope.feeDetails = angular.copy($scope.feeDetailsTemplate);
            HideLoader();
        };

        var getIndividualLicenseByIndividualLicenseId = function (individualLicenseId) {
            var individualLicense;
            angular.forEach($scope.IndividualLicenseList, function (individualLicenseItem) {
                if (individualLicenseItem.IndividualLicenseId == individualLicenseId) {
                    individualLicense = individualLicenseItem;
                }
            });
            return individualLicense;
        };

        $scope.editAdministratorPermit = function (permit) {
            ShowLoader();
            $scope.permit = angular.copy(permit);
            $scope.originalPermit = angular.copy(permit);
            processDatesAndIndividualLicense();
            $scope.openAddEditAdministratorPermit(true);
        };

        var processDatesAndIndividualLicense = function () {
            if ($scope.permit.PermitEffectiveDate != null && !angular.isDate($scope.permit.PermitEffectiveDate))
                $scope.permit.PermitEffectiveDate = new Date($scope.permit.PermitEffectiveDate);
            if ($scope.permit.PermitExpirationDate != null && !angular.isDate($scope.permit.PermitExpirationDate))
                $scope.permit.PermitExpirationDate = new Date($scope.permit.PermitExpirationDate);

            $scope.setPermitStartMaxDate($scope.permit);
            $scope.setPermitEndMinDate($scope.permit);
            $scope.permit.individualLicense = getIndividualLicenseByIndividualLicenseId($scope.permit.IndividualLicenseId);
        };

        $scope.editSitePermit = function (permit) {
            ShowLoader();
            $scope.permit = angular.copy(permit);
            $scope.originalPermit = angular.copy(permit);
            processDatesAndIndividualLicense();
            $scope.openAddEditSitePermit(true);
            //var promise = getPermitSiteAddress(permit.IndividualId,permit.IndividualLicenseId,sessionStorage.Key);
            //promise.then(res=> permit = res.GetPermitSiteAddressList ,err=>$scope.showStatusMessage(err.message,"Error"));
        };

        $scope.closeAddEditPermit = function () {
            $scope.closeAddEditAdministratorPermit();
            $scope.closeAddEditSitePermit();
        };

        $scope.closeAddEditAdministratorPermit = function () {
            $scope.viewConfig.showAddAdministratorPermit = false;
            $scope.ProceedtoPaymentSectionEnable = false;
        };

        $scope.closeAddEditSitePermit = function () {
            $scope.viewConfig.showAddSitePermit = false;
            $scope.ProceedtoPaymentSectionEnable = false;
        };

        $scope.individualPermitByIndividualId = function (IndividualId, isInitialRequest, permitType) {
            if (!isInitialRequest)
                ShowLoader("Please Wait...");
            try {
                var individualPermitPromise = backofficeIndividualPermitService.individualPermitByIndividualId(sessionStorage.Key, IndividualId, permitType);
                individualPermitPromise.then(function (response) {
                    if (!isInitialRequest) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        HideLoader();
                    }
                    if ($scope.checkServerResponse(response)) {
                        if (permitType == 1) {
                            $scope.sitePermits = response.PermitResponseList;
                        } else if (permitType == 2) {
                            $scope.administartorPermits = response.PermitResponseList;
                        }
                    }
                },
                    function (data) {
                        $scope.showStatusMessage(data, "error");
                    });
                return individualPermitPromise;
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        };

        $scope.savePermitAndComplete = function (permitForm) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                permitForm.$submitted = true;
                ShowLoader("Please wait...");
                // console.log($scope.permit);
                if ($scope.validateData(permitForm)) {
                    // $scope.permit.IndividualLicenseId = $scope.permit.individualLicense.IndividualLicenseId;
                    $scope.savePermitInfo(false);
                } else {
                    HideLoader();
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        };

        $scope.savePermitAndProceedToPayment = function (permitForm) {
            $scope.hideStatusMessage();
            $scope.hideValidationSummary();
            permitForm.$submitted = true;
            ShowLoader("Please wait...");

            if ($scope.validateData(permitForm)) {
                //if (permitForm.PermitStatusId == 2) {
                var reqData = {
                    ApplicationTypeId: (($scope.permit.PermitTypeId == 1) ? 8 : 9),
                    ApplicationStatusId: 1,
                    ApplicationSubmitMode: 'B',
                    GoPaperless: true,
                    IsActive: 1
                };

                backofficeIndividualPermitService.generateApplicationId($scope.UserId, reqData, sessionStorage.Key)
                    .then(function (responseApplication) {
                        if ($scope.checkServerResponse(responseApplication)) {
                            $scope.ApplicationId = responseApplication.ApplicationId;
                            var reqIndAppData = {
                                ApplicationId: responseApplication.ApplicationId,
                                IndividualId: $scope.IndividualId,
                                CreatedBy: $scope.UserId,
                                CreatedOn: new Date()
                            };
                            backofficeIndividualPermitService.saveIndividualApplication(reqIndAppData, sessionStorage.Key)
                                .then(function (responseIndApplication) {
                                    if ($scope.checkServerResponse(responseIndApplication)) {
                                        $scope.permit.ApplicationId = responseApplication.ApplicationId;
                                        $scope.permit.IndividualLicenseId = $scope.permit.individualLicense.IndividualLicenseId;
                                        var permitData = angular.copy($scope.permit);
                                        permitData.PermitStatusId = 11;
                                        permitData.PermitId = 0;
                                        backofficeIndividualPermitService.savePermitInfo([permitData], sessionStorage.Key)
                                            .then(function (responsePermit) {
                                                if ($scope.checkServerResponse(responsePermit)) {
                                                    var currentSavedPermit = null;
                                                    var permitsToCompare = [];
                                                    var returnedPermits = [];
                                                    if ($scope.permit.PermitTypeId == 1) {
                                                        permitsToCompare = $scope.sitePermits;
                                                        returnedPermits = responsePermit.SitePermitResponseList;
                                                    } else if ($scope.permit.PermitTypeId == 2) {
                                                        permitsToCompare = $scope.administartorPermits;
                                                        returnedPermits = responsePermit.AdminPermitResponseList;
                                                    }
                                                    angular.forEach(returnedPermits, function (savedPermitItem) {
                                                        var isNewPermit = true;
                                                        angular.forEach(permitsToCompare, function (permitToCompare) {
                                                            if (savedPermitItem.PermitId == permitToCompare.PermitId) {
                                                                isNewPermit = false;
                                                            }
                                                        });
                                                        if (isNewPermit) {
                                                            currentSavedPermit = savedPermitItem;
                                                        }
                                                    });
                                                    $scope.permit.PermitId = currentSavedPermit.PermitId;
                                                    //$scope.permit = currentSavedPermit;
                                                    //processDatesAndIndividualLicense();
                                                    $scope.ProceedtoPaymentSectionEnable = true;
                                                    HideLoader();
                                                } else {
                                                    HideLoader();
                                                }
                                            }, function (data) {
                                                $scope.showStatusMessage(data, "error");
                                                HideLoader();
                                            });
                                    } else {
                                        HideLoader();
                                    }
                                }, function (data) {
                                    $scope.showStatusMessage(data, "error");
                                    HideLoader();
                                });
                        } else {
                            HideLoader();
                        }
                    },
                        function (data) {
                            $scope.showStatusMessage(data, "error");
                            HideLoader();
                        });

                //}
            } else {
                HideLoader();
            }
        };

        $scope.payAndSubmit = function () {
            initialApplicationstatusService.resetAll();
            $scope.$broadcast("PayandSubmit", {});
            var paymentStatus = '';
            var interval = setInterval(function () {
                paymentStatus = initialApplicationstatusService.getPaymetStatus();
                if (!!paymentStatus && paymentStatus != "waiting") {
                    clearInterval(interval);
                    if (paymentStatus == "success") {
                        var permitDataItems = [];
                        var permitData = angular.copy($scope.originalPermit);
                        permitData.PermitStatusId = 4;
                        permitDataItems.push(permitData);
                        var permitDataNew = angular.copy($scope.permit);
                        permitDataNew.PermitStatusId = $scope.permit.PermitStatusId;
                        permitDataItems.push(permitDataNew);
                        backofficeIndividualPermitService.savePermitInfo(permitDataItems, sessionStorage.Key)
                            .then(function (responsePermit) {
                                if ($scope.checkServerResponse(responsePermit)) {
                                    var reqData = {
                                        ApplicationId: $scope.ApplicationId,
                                        ApplicationTypeId: (($scope.permit.PermitTypeId == 1) ? 8 : 9),
                                        ApplicationStatusId: 3,
                                        ApplicationStatusReasonId: 0,
                                        IsActive: true,
                                        CreatedBy: $scope.UserId,
                                        ApplicationInternalStatusReasonText: '',
                                        ApplicationInternalStatusId: 0
                                    };
                                    var reqDataChangeLog = {
                                        ApplicationId: $scope.ApplicationId,
                                        ApplicationNumber: '',
                                        ApplicationStatusReasonId: 0,
                                        ApplicationTypeId: (($scope.permit.PermitTypeId == 1) ? 8 : 9),
                                        ApplicationStatusId: 3,
                                        ApplicationSubmitMode: 'B',
                                        ApplicationStatusChangedBy: $scope.UserId,
                                        ReferenceNumber: '',
                                        LicenseTypeId: 0,
                                        ApplicationInternalStatusId: 0,
                                        ApplicationInternalStatusReasonText: ''
                                    };

                                    var dataSavePromises = [
                                        initialApplicationstatusService.saveApplicationStatus($scope.ApplicationId, sessionStorage.Key, 3),
                                        initialApplicationstatusService.saveApplicationStatusLk(sessionStorage.Key, $scope.UserId, reqData),
                                        initialApplicationstatusService.saveApplicationStatusChangeLog(sessionStorage.Key, $scope.UserId, reqDataChangeLog)
                                    ];
                                    $q.all(dataSavePromises).then(function (responseItems) {
                                        var allSavedSuccessFully = true;
                                        angular.forEach(responseItems, function (responseItem) {
                                            if (!$scope.checkServerResponse(responseItem)) {
                                                allSavedSuccessFully = false;
                                            }
                                        });
                                        if (!!allSavedSuccessFully) {
                                            $scope.ProceedtoPaymentSectionEnable = false;
                                            $scope.closeAddEditPermit();
                                            $scope.individualPermitByIndividualId($scope.IndividualId, false, permitDataNew.PermitTypeId);
                                        } else
                                            HideLoader();
                                    }, function (data) {
                                        HideLoader();
                                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                                    });
                                }
                                HideLoader();
                            }, function (data) {
                                HideLoader();
                                if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                            });
                    }
                    if (paymentStatus == "error") {
                        HideLoader();
                    }
                }
            }, 500);
        };

        $scope.savePermitInfo = function (isDeleted) {
            var permitData = angular.copy($scope.permit);
            backofficeIndividualPermitService.savePermitInfo([permitData],
                sessionStorage.Key).then(function (response) {
                    if ($scope.checkServerResponse(response)) {
                        $scope.individualPermitByIndividualId($scope.IndividualId, false, permitData.PermitTypeId);
                        $scope.closeAddEditPermit();
                        $scope.showStatusMessage(response.Message, "success");
                    }
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                });
        };

        $scope.deletePermit = function (permit) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                ShowLoader("Please Wait...");
                $scope.permit = angular.copy(permit);
                $scope.permit.IsDeleted = true;
                $scope.savePermitInfo(true);
            }
        };
    }
})();