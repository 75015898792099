(function () {
    'use strict';

    angular
        .module('app.core')
        .controller("WarningController", WarningController);

    WarningController.$inject = ["$rootScope", "$scope", "$q", "warningService", "typeValuesService", "TypeValue", "Messages"];

    function WarningController($rootScope, $scope, $q, warningService, typeValuesService, TypeValue, Messages) {

        $scope.viewConfig = {
            showAddBlock: false,
            isWarningEdit: false
        };
        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/d!/yyyy',
            startDateOptions: {
                minDate: null,
            },
            endDateOptions: {}
        };

        $scope.init = function () {
            var dataPromises = [];
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=licensedisciplinetype")));
            // dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=validlocation")));
            dataPromises.push(warningService.getwarning(sessionStorage.Key, $scope.entityId, $scope.entityType, null));
            
            $scope.warning = null;

            $q.all(dataPromises)
                .then(function (response) {
                    angular.forEach(response, function (responseItem, itemIndex) {
                        if (responseItem.Status) {
                            if (itemIndex == 0) {
                                $scope.warningTypes = responseItem.ReferenceEntities;
                                // } else if (itemIndex == 1) {
                                //     $scope.validlocations = responseItem.ReferenceEntities;
                            } else if (itemIndex == 1) {
                                $scope.warnings = processWarningData(responseItem.DisciplineList);
                            }
                        } else {
                            $scope.showStatusMsg('-', responseItem.Message);
                        }
                    });
                    $scope.loadingWarning(false);
                }, function (data) {
                    $scope.loadingWarning(false);
                    $scope.showStatusMsg('-', data.message);
                });
        }

        //Tab Initilizer
        $scope.$on("entityTabChanged", function (evt, entityId) {
            if ($rootScope.entityCurrentTab.name == "warning") {
                $scope.entityId = entityId;
                $scope.init();
            }
        });


        $scope.openAddEditWarning = function (isEdit, warning) {
            $scope.loadingWarning(true, "Please wait");
            $scope.viewConfig.isWarningEdit = isEdit;
            $scope.viewConfig.showAddBlock = true;
            if (!$scope.viewConfig.isWarningEdit) {
                $scope.warning = {
                    EntityId: $scope.entityId,
                    EntityTy: $scope.entityType,
                    IsActive: true,
                    BeginDate: new Date(),
                    LicenseNumber: null
                };
            } else {
                $scope.warning = angular.copy(warning);
            }
            $scope.loadingWarning(false);
        };

        $scope.closeAddEditWarning = function () {
            $scope.viewConfig.showAddBlock = false;
        };

        var processWarningData = function (warningData) {
            angular.forEach(warningData, function (warning) {
                angular.forEach($scope.warningTypes, function (warningType) {
                    if (warningType.LicenseDisciplineTypeId == warning.DisciplineTypeId) {
                        warning.LicenseDisciplineTypeName = warningType.LicenseDisciplineTypeName;
                    }
                });
                angular.forEach($scope.validlocations, function (validlocation) {
                    if (validlocation.ValidlocationId == warning.ValidlocationId) {
                        warning.ValidLocationName = validlocation.ValidLocationName;
                    }
                });
            });
            return warningData;
        };

        var customValidation = function (currentForm) {
            var formData = {
                errorMessages: angular.copy(currentForm.errorMessages)
            };

            if (!(!!formData.errorMessages && formData.errorMessages.length > 0)) {
                return true;
            } else {
                var errorMessages = [];
                angular.forEach(formData.errorMessages, function (obj) {
                    errorMessages.push(obj.errorMessage);
                })
                $scope.showStatusMsg('-', errorMessages);
                return false;
            }
        };


        // Save Warning
        $scope.saveWarning = function (warningForm) {
            $scope.hideStatusMsg();
            warningForm.$submitted = true;
            if (customValidation(warningForm)) {
                $scope.loadingWarning(true, "Please wait");
                $scope.saveWarningRecord();
            }
        };

        $scope.saveWarningRecord = function () {
            var warningData = angular.copy($scope.warning);
            warningService.warningSave([warningData], sessionStorage.Key).then(function (response) {
                if (response.Status) {
                    $scope.logDataChanges();
                    $scope.$emit("reloadWarningFlag");
                    $scope.init();
                    $scope.closeAddEditWarning();
                    $scope.showStatusMsg('+', response.Message);
                } else {
                    $scope.showStatusMsg('-', response.Message);
                }
                $scope.loadingWarning(false);
            }, function (data) {
                $scope.loadingWarning(false);
                $scope.showStatusMsg('-', data.message);
            });
        };


        $scope.deleteWarning = function (warning) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                $rootScope.individualEventLog('deleteStatus.deleteConfirmed', $scope.entityId);
                $scope.loadingWarning(true, "Please wait");
                $scope.warning = warning;
                $scope.warning.IsActive = false;
                $scope.warning.IsDeleted = true;
                $scope.saveWarningRecord();
            } else {
                $rootScope.individualEventLog('deleteStatus.deleteCanceled', $scope.entityId);
            }
        };

        $scope.init();
    }
})();