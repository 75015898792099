//Code Generated by Inlumon Code generation tool
(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('applicationReviewService', applicationReviewService)

    applicationReviewService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function applicationReviewService($http, $q, WebApiUrl, AppConfig) {

        return {
            applicationReviewGetByEntityId: _applicationReviewGetByEntityId,
            applicationReviewSave: _applicationReviewSave
        };

        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function _applicationReviewGetByEntityId(Key, entityId, applicationId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Application/ApplicationReviewGet/' + Key + '?IndividualId=' + entityId + '&ApplicationId=' + applicationId + "&Source=" + AppConfig.Source));
        };


        function _applicationReviewSave(key, applicationReview) {
            return returnDefferedResult($http.post(WebApiUrl + 'Application/SaveApplicationReview/' + key + "?Source=" + AppConfig.Source, applicationReview));
        };

        // $http.get(WebApiUrl + 'Application/ApplicationReviewGet/' + key + '?ApplicationId=' + applicationId + '&IndividualId=' + individualId + '&ProviderId=&Source=' + AppConfig.Source)
    }
})();