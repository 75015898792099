(function () {
  'use strict'
  angular.module('app.report')
    .controller('LicensureReportController', LicensureReportController);

  LicensureReportController.$inject = ['$scope', '$q', '$controller', '$filter',
    'reportService', '$state', 'utilityService', 'ApiUrlsForReports', 'ApiUrlsForReportsXls'];

  function LicensureReportController($scope, $q, $controller, $filter, reportService, $state, utilityService, ApiUrlsForReports, ApiUrlsForReportsXls) {
    $controller('GlobalController', { $scope: $scope })

    // Pagination
    $scope.Pager = {
      sortType: 'LicenseNumber',
      sortOrder: 'asc',
      currentPage: 1,
      totalRecords: 10,
      pageSize: 20,
      maxSize: 5
    }
    $scope.Headers = [
      { name: 'Status', sortType: 'LicenseStatusTypeName', sortOrder: 'asc' },
      { name: 'License#', sortType: 'LicenseNumber', sortOrder: 'desc' },
      { name: 'Original Date of licensure', sortType: 'OriginalLicenseDate', sortOrder: 'asc' },
      { name: 'Date of current License', sortType: 'LicenseEffectiveDate', sortOrder: 'asc' },
      { name: 'Date of Expiration', sortType: 'LicenseExpirationDate', sortOrder: 'asc' },
      { name: 'First Name', sortType: 'FirstName', sortOrder: 'asc' },
      { name: 'Middle Name', sortType: 'MiddleName', sortOrder: 'asc' },
      { name: 'Last Name', sortType: 'LastName', sortOrder: 'asc' }
    ]
    $scope.getLicensureReportList = function () {
      try {
        if ($scope.isSessionActive()) {
          hideStatusMessage();
          ShowLoader('Please Wait...');
          reportService.licensureReportWithPager(sessionStorage.Key, $scope.Pager, ApiUrlsForReports.licensure)
            .then(function (response) {
              if ($scope.checkResponse(response)) {
                $scope.Pager.totalRecords = response.PagerVM.TotalRecords
                $scope.LicensureList = response.PagerVM.Records
              }
              HideLoader()
              utilityService.scrollTop()
            }, function (data) {
              HideLoader()
              showStatusMessage(data, 'error')
            })
        } else {
          $state.go('app.Loginwoemail')
        }
      } catch (ex) {
        HideLoader()
        if (ex != null) { showStatusMessage(ex.message, 'error'); }
      }
    }

    $scope.getLicensureReportList();

    $scope.SortRecords = function (header) {
      $scope.Pager.currentPage = 1;
      $scope.Pager.sortType = header.sortType;
      $scope.Pager.sortOrder = header.sortOrder;
      header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
      $scope.getLicensureReportList();
    }
    $scope.getExcelForLicensureReport = function () {
      try {
        ShowLoader('Please Wait...')
        reportService.getXLSForLicensureReport(sessionStorage.Key, $scope.Pager, ApiUrlsForReportsXls.licensure)
          .then(function (response) {
            HideLoader()
          }, function (data) {
            HideLoader()
            showStatusMessage(data, 'error')
          })
      } catch (ex) {
        HideLoader()
        if (ex != null) { showStatusMessage(ex.message, 'error'); }
      }
    }
  };
})();

