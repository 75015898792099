(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("PeerReviewController", PeerReviewController);

    PeerReviewController.$inject = ["$rootScope", "$scope", "countryStateService", "$q", "backofficeProviderLicenseService",
        "peerReviewService", "utilityService", "initialApplicationstatusService", "dcaIService", "WebApiUrl", "$exceptionHandler", "initialApplicationAcknowledgementService", "contentService", "initialApplicationVeteranInfoService", "Messages", "backofficeIndividualLicenseService", "initialApplicationStaffReviewService"
    ];

    function PeerReviewController($rootScope, $scope, countryStateService, $q, backofficeProviderLicenseService,
        peerReviewService, utilityService, initialApplicationstatusService, dcaIService, WebApiUrl, $exceptionHandler, initialApplicationAcknowledgementService, contentService, initialApplicationVeteranInfoService, Messages, backofficeIndividualLicenseService, initialApplicationStaffReviewService) {
        $scope.documentsList = null;

        $scope.providerId = sessionStorage.ProviderId;

        $scope.datePicker = {
            dateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };
        $scope.documentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
            ApplicationId: $scope.applicationId,
            ProviderId: $scope.providerId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "FR",
            LoadByDocIdAndDocCode: true,
            DocId: 2,
            DocCode: "D1002",
            LoadDocumentsWithoutBroadCast: true
        };

        var legalInfoOptionIndex = 0;

        var counter = 0;
        //$scope.legalInfo = {};

        $scope.getLegalInfoConfig = function () {
            var relativeFilePath = "/individual/application/legal-info/legal-info.server-config.json";
            return utilityService.getConfigData(relativeFilePath);
        };

        $scope.getLegalInfoOptionIndex = function (option) {
            if (option.IsEnabled)
                legalInfoOptionIndex = legalInfoOptionIndex + 1;
            option.Index = legalInfoOptionIndex;
        }

        $scope.getValidationMessage = function (validationMessage, legalInfoNumber) {
            if (!!validationMessage)
                validationMessage = validationMessage.replace("{{legalInfoNumber}}", legalInfoNumber);
            return validationMessage;
        };

        $scope.setLegalStatus = function () {
            if ($scope.isRenewal) {
                var isAffirmativeAction = false;
                if ($scope.legalInfo.legalInfoDisciplinary == true) {
                    isAffirmativeAction = true;
                }
                $scope.legalInfo.isAffirmativeAction = isAffirmativeAction;
            }
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        $scope.$on('checkForAddInfoCount', function (event, data) { 
            $scope.checkForAddInfoCount = data.countOfIndividual
        });

        var validateForm = function (currentForm) {
            if ($scope.useType == 'ShareholderOrPartner') {
                $rootScope.submissionLicenseStatusTypeId = 3;
               
            }
            if ($scope.useType == 'Affidavit') {
                $rootScope.renewalInfoLicenseStatusTypeId = 3;
                $scope.peerReviewQuestion1.showValidation = true;
                $scope.peerReviewQuestion2.showValidation = true;
            }
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            // $scope.natValidation.warningMessages = [];

            // angular.forEach($scope.legalInfo, function (legalInfoOption, key) {
            //     legalInfoOption.showValidation = true;
            // });
            
            $scope.question1 = false;
            $scope.question2 = false;

            if (!!$scope.isRenewal) {
                    if ($scope.useType == 'Affidavit') {

                    if (!!$scope.peerReviewQuestion1.ContentItemResponse) {
                        if (!$scope.peerReviewQuestion2.providerLegalAddInfo.AdditionalInformation) {
                            var errorObj = {
                                propName: "PeerRating",
                                errorMessage: "Please enter Peer Review Rating"
                            };
                            $scope.natValidation.errorMessages.push(errorObj);
                        }
                        if ($scope.peerReviewQuestion2.providerLegalAddInfo.AdditionalInformation == 'Substandard/Fail') {
                            $rootScope.renewalInfoLicenseStatusTypeId = 8;
                        }
                        else if ($scope.peerReviewQuestion2.providerLegalAddInfo.AdditionalInformation != 'Substandard/Fail')
                        {
                            $rootScope.renewalInfoLicenseStatusTypeId = 3;   
                        }
                        if ((!$scope.peerReviewQuestion2.documentUpload || !$scope.peerReviewQuestion2.documentUpload.documentCount || $scope.peerReviewQuestion2.documentUpload.documentCount == 0)&&($scope.peerReviewQuestion2.providerLegalAddInfo.AdditionalInformation != 'Not Yet Due')) {
                            var errorObj = {
                                propName: "PeerReviewFormDoc",
                                errorMessage: "Please upload a Peer Review Form PR-1"
                            };
                            $scope.natValidation.errorMessages.push(errorObj);
                        }
                    }
                }
                if ($scope.useType == 'ShareholderOrPartner') {


                    if ($scope.shareholderOrPartner1.ContentItemResponse == true) {
                        angular.forEach($scope.providerAdditionalInfo, function (validationCheck) {
                            if((!!validationCheck.documentUpload && (validationCheck.documentUpload.documentCount == 0 || validationCheck.documentUpload.documentCount == undefined)) && ($scope.checkForAddInfoCount == 0)) {
                                var errorObj = {
                                    propName: "Shareholder/PartnershipDoc",
                                    errorMessage: "Please upload a Shareholder/Partnership List Document or add an Indvidual."
                                };
                                $scope.natValidation.errorMessages.push(errorObj);
                            }  
                        }); 

                        $rootScope.submissionLicenseStatusTypeId = 8;
                    }
                        
                    

                    if ($scope.shareholderOrPartner2.ContentItemResponse == true) {
                        angular.forEach($scope.providerAdditionalInfo, function (validationCheck) {
                            if((!!validationCheck.documentUpload && (validationCheck.documentUpload.documentCount == 0 || validationCheck.documentUpload.documentCount == undefined)) && ($scope.checkForAddInfoCount == 0)) {
                                var errorObj = {
                                    propName: "Shareholder/PartnershipDoc",
                                    errorMessage: "Please upload a Shareholder/Partnership List Document or add an Indvidual."
                                };
                                $scope.natValidation.errorMessages.push(errorObj);
                            }  
                        }); 
                        $rootScope.submissionLicenseStatusTypeId = 8;
                    }
                        
                    
                    if (($scope.shareholderOrPartner1.ContentItemResponse == false ) && ($scope.FirmType == 2)) {
                        
                        angular.forEach($scope.providerAdditionalInfo, function (validationCheck) {
                            if(!!validationCheck.documentUpload && validationCheck.documentUpload.documentCount > 0) {
                                var errorObj = {
                                    propName: "Shareholder/PartnershipDoc",
                                    errorMessage: "This question should be answered 'Yes' as you have uploaded a Shareholder/Partnership Document."
                                };
                                $scope.natValidation.errorMessages.push(errorObj);
                            }  
                        }); 
                        if ($scope.checkForAddInfoCount > 0){
                            var errorObj = {
                                propName: "Shareholder/PartnershipCountOfIndividual",
                                errorMessage: "This question should be answered 'Yes' as you have added Individuals."
                            };
                            $scope.natValidation.errorMessages.push(errorObj);
                        }

                        $rootScope.submissionLicenseStatusTypeId = 3;
                    }
                    if (($scope.shareholderOrPartner2.ContentItemResponse == false ) && ($scope.FirmType == 3)) {
                        
                        angular.forEach($scope.providerAdditionalInfo, function (validationCheck) {
                            if(!!validationCheck.documentUpload && validationCheck.documentUpload.documentCount > 0) {
                                var errorObj = {
                                    propName: "Shareholder/PartnershipDoc",
                                    errorMessage: "This question should be answered No as you have uploaded a Shareholder/Partnership Document."
                                };
                                $scope.natValidation.errorMessages.push(errorObj);
                            }  
                        }); 
                        if ($scope.checkForAddInfoCount > 0){
                            var errorObj = {
                                propName: "Shareholder/PartnershipCountOfIndividual",
                                errorMessage: "This question should be answered No as you have added Individuals."
                            };
                            $scope.natValidation.errorMessages.push(errorObj);
                        }

                        $rootScope.submissionLicenseStatusTypeId = 3;
                    }
                    

                         
                    
                }
            

            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }

        };

        var checkPropertyExists = function (list, prop) {
            var res = false;
            if (list) {
                for (var i = 0; i < list.length; i++) {
                    if (list[i].propName == prop)
                        return true;
                }
            }
            return res;
        };

        var ShowErrMsg = function () {
            var errorObj = {
                propName: "legalInfoErr",
                errorMessage: $scope.legalInfoConfig.ValidationMsg.APIErr
            };
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            if (!checkPropertyExists($scope.natValidation.errorMessages, 'legalInfoErr'))
                $scope.natValidation.errorMessages.push(errorObj);
        }

        $scope.legalInfoSave = function (currentForm, skipNextTab) {
            try {
                ShowLoader("Please wait...");
                var confirmation = false;
                if (!$rootScope.isFormValidate) {
                    $scope.tab.isInvalid = false;
                    if (!validateForm(currentForm)) {
                        if (!$scope.isRenewal) {
                            $scope.tab.isInvalid = true;
                            HideLoader();
                            if (skipNextTab == true) {
                                $scope.$emit('setCurrentTabNow', $scope.tab);
                            }
                            if (skipNextTab && !!skipNextTab.fromDeficiency) {
                                $scope.$emit('deficiencyExecuted', { success: false });
                            }
                        } else {
                            HideLoader();
                            initialApplicationstatusService.setLegal('invalid');
                        }
                        return;
                    }
                    $scope.providerLegalInfo = [];
                    if ($scope.useType == 'ShareholderOrPartner') {
                        $scope.providerLegalInfo.push($scope.shareholderOrPartner1);
                        $scope.providerLegalInfo.push($scope.shareholderOrPartner2);
                        if ($rootScope.submissionLicenseStatusTypeId == 8 && !!$scope.natValidation.warningMessages && $scope.natValidation.warningMessages.length > 0) {
                            if (counter > 0 && (!!$scope.isBackoffice || confirm("You are submitting an incomplete renewal application which will cause delays in processing your renewal. Click cancel to return to your application and make corrections.  Otherwise, you may click “OK” to submit your application."))) {
                                confirmation = true;
                            }
                            else {
                                confirmation = false;
                            }
                        }
                        else {
                            confirmation = true;
                        }
                        counter = counter + 1;
                    }
                    if ($scope.useType == 'Affidavit') {
                        $scope.providerLegalInfo.push($scope.peerReviewQuestion1);
                        $scope.providerLegalInfo.push($scope.peerReviewQuestion2);
                        if ($rootScope.renewalInfoLicenseStatusTypeId == 8 && !!$scope.natValidation.warningMessages && $scope.natValidation.warningMessages.length > 0) {
                            if (counter > 0 && (!!$scope.isBackoffice || confirm("You are submitting an incomplete renewal application which will cause delays in processing your renewal. Click cancel to return to your application and make corrections.  Otherwise, you may click “OK” to submit your application."))) {
                                confirmation = true;
                            }
                            else {
                                confirmation = false;
                            }
                        }
                        else {
                            confirmation = true;
                        }
                        counter = counter + 1;
                    }
                    if (!!$scope.natValidation.warningMessages && $scope.natValidation.warningMessages.length > 0 && !confirmation) {
                        $scope.natValidation.showWarning = true;
                        HideLoader();
                        
                        return;
                    } else {
                        $scope.natValidation.showWarning = false;
                        
                    }
                }
                // if (!$scope.isRenewal || !!$scope.isRenewal) {
                //     $scope.$broadcast("SaveDeficiency", {});
                // }

                
                if (!!confirmation) {
                    if ($scope.providerLegalInfo) {
                        //sessionStorage.AutoApprove = true;

                        angular.forEach($scope.providerLegalInfo, function (legalInfoOption, key) {
                            // legalInfoOption.ApplicationId = $scope.applicationId;
                            // legalInfoOption.IndividualId = $scope.individualId;
                            if ($scope.isRenewal && $scope.useType == 'Affidavit') {
                                if (legalInfoOption.ContentItemResponse) {
                                    //sessionStorage.AutoApprove = false;
                                }
                                // legalInfoOption.IndividualLicenseId = $scope.providerLicense.IndividualLicenseId;
                            }
                            // if ($scope.legalInfo.legalInfoDisciplinary == true) {
                            //     if (legalInfoOption.ContentItemNumber == 1) {
                            //         legalInfoOption.ContentItemResponse = false;
                            //     } else if (legalInfoOption.ContentItemNumber == 2) {
                            //         legalInfoOption.ContentItemResponse = true;
                            //     }
                            // } else if ($scope.legalInfo.legalInfoDisciplinary == false) {
                            //     if (legalInfoOption.ContentItemNumber == 1) {
                            //         legalInfoOption.ContentItemResponse = true;
                            //     } else if (legalInfoOption.ContentItemNumber == 2) {
                            //         legalInfoOption.ContentItemResponse = false;
                            //     }
                            // }
                        });
                        if (!!$scope.isRenewal) {
                            var dataSavePromises = [];
                            // angular.forEach($scope.legalInfo, function (legalInfoOption, key) {
                            //     legalInfoOption.ApplicationId = $scope.applicationId;
                            // });

                            dataSavePromises.push(peerReviewService.legalInfoSaveProvider($scope.providerLegalInfo, sessionStorage.Key));
                            angular.forEach($scope.providerLegalInfo, function (legalInfoOption, key) {
                                if ((legalInfoOption.ContentItemLkId == 820 && !!$scope.legalInfoList.item819.ContentItemResponse)) {
                                    legalInfoOption.providerLegalAddInfo.ApplicationId = $scope.applicationId;
                                    legalInfoOption.providerLegalAddInfo.ProviderId = $scope.providerId;
                                    legalInfoOption.providerLegalAddInfo.IsActive = true;
                                    legalInfoOption.providerLegalAddInfo.IsDeleted = false;
                                    legalInfoOption.providerLegalAddInfo.EntityLegalId = legalInfoOption.EntityLegalId;
                                    dataSavePromises.push(
                                        peerReviewService.saveIndividualLegalAddInfo($scope.providerId, $scope.applicationId, $scope.userId, legalInfoOption.providerLegalAddInfo, sessionStorage.Key)
                                    );
                                }
                            });

                            $q.all(dataSavePromises).then(function (res) {
                                var isSavedSuccessfully = true;
                                angular.forEach(res, function (response) {
                                    if (!response.Status) {
                                        isSavedSuccessfully = false;
                                    }
                                })
                                if (isSavedSuccessfully) {
                                    $scope.tab.skipNextTab = skipNextTab;
                                    $scope.openNextTab({
                                        currentTab: $scope.tab
                                    });
                                    if ($scope.tab.skipNextTab == true) {
                                        $scope.$emit('setCurrentTabNow');
                                    }
                                    if (skipNextTab && !!skipNextTab.fromDeficiency) {
                                        $scope.$emit('deficiencyExecuted', { success: true });
                                    }
                                }
                                HideLoader();
                            }, function (res) {
                                HideLoader();
                                $scope.showStatusMessage({
                                    messageContent: 'Some error occurred !',
                                    messageType: 'error'
                                });
                            })
                        } else {
                            initialApplicationLegalInfoService.legalInfoSave($scope.legalInfo, sessionStorage.Key)
                                .then(function (response) {
                                    if (response.Status == false) {
                                        initialApplicationstatusService.setLegal('error');
                                        $scope.showStatusMessage({
                                            messageContent: response.Message,
                                            messageType: 'error'
                                        });
                                    } else {
                                        if (!$scope.isRenewal) {
                                            $scope.tab.skipNextTab = skipNextTab;
                                            $scope.openNextTab({
                                                currentTab: $scope.tab
                                            });
                                            if ($scope.tab.skipNextTab == true) {
                                                $scope.$emit('setCurrentTabNow');
                                            }
                                            if (skipNextTab && !!skipNextTab.fromDeficiency) {
                                                $scope.$emit('deficiencyExecuted', { success: true });
                                            }
                                        } else {
                                            initialApplicationstatusService.setLegal('success');
                                        }
                                    }
                                    if (!$rootScope.ProcessLoaderExternally)
                                        HideLoader();
                                }, function (error) {
                                    initialApplicationstatusService.setLegal('error');
                                    HideLoader();
                                    $scope.showStatusMessage({
                                        messageContent: 'Some error occurred !',
                                        messageType: 'error'
                                    });
                                });

                        }
                    }
                }
                else {
                    HideLoader();
                }
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
                $exceptionHandler(ex);
            }
        };

        function AuditVisitInfoSave() {
            var data = {
                "PageName": $scope.legalInfoConfig.Content.TabHeading,
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };

        $scope.flagForReview = function () {
            // if ($scope.useType == 'ShareholderOrPartner') {
            //     $rootScope.submissionLicenseStatusTypeId = 3;
            //     // calculateCECourseHoursbyType();
            // }
            // if ($scope.useType == 'Affidavit') {
            //     $rootScope.renewalInfoLicenseStatusTypeId = 3;
            // }
            $scope.question3 = false;
            $scope.question4 = false;
            $scope.question5 = false;
            $scope.question345DocNotUploaded = false;

            $scope.question6 = false;
            $scope.question7 = false;
            $scope.question8a = false;
            $scope.question8b = false;
            $scope.question8c = false;
            $scope.question9 = false;
            $scope.question10 = false;
            $scope.question10a = false;

            if (!!$scope.isRenewal) {
                var isPeerReviewed = false;
                angular.forEach($scope.legalInfo, function (legalInfoOption, key) {
                    if ($scope.useType == 'Affidavit') {
                        if (legalInfoOption.ContentItemLkId == 819 && !!legalInfoOption.ContentItemResponse) {
                            isPeerReviewed = true;
                            //$rootScope.renewalInfoLicenseStatusTypeId = 8;
                        }

                        if (legalInfoOption.ContentItemLkId == 820 && !!$scope.legalInfoList.item820.ContentItemResponse) {
                            if (legalInfoOption.individualLegalAddInfo.AdditionalInformation == 'Substandard/Fail') {
                                $rootScope.renewalInfoLicenseStatusTypeId = 8;
                            }
                        }

                    }
                    if ($scope.useType == 'ShareholderOrPartner') {
                        if (legalInfoOption.ContentItemLkId == 813 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question6 = true;
                        }
                        if (legalInfoOption.ContentItemLkId == 814 && !!legalInfoOption.ContentItemResponse) {
                            $scope.question7 = true;
                        }
                        
                    }
                });
            }
        }

        function init() {
            $scope.individualIdNumber = $scope.providerLicense.IDNumber;
            // $scope.DisplayCASLicensePrimaryStatus($scope.individualIdNumber);
            $scope.isRetiredOrDelinquent = $rootScope.isRetiredOrDelinquent;

            try {
                if (!!$scope.loadData) {
                    ShowLoader("Please wait...");
                }
                if (!$scope.isRenewal) {
                    $scope.contentItemsByUseType = {
                        Affidavit: {
                            ContentIds: [{
                                ContentItemLkId: "397",
                                DocumentId: 1,
                                DocumentCode: "D1001",
                                showDocumentControl: true,
                                hideDocumentType: true,
                                preSelectFirstDocumentType: true,
                                showWhen: false,
                                showPreviousName: true,
                                showLicenceNumber: true,
                                showDescription: false,
                                loadAddInfo: true
                            }, {
                                ContentItemLkId: "398",
                                DocumentId: 2,
                                DocumentCode: "D1002",
                                showDocumentControl: true,
                                hideDocumentType: true,
                                preSelectFirstDocumentType: true,
                                showWhen: false,
                                showDeniedOn: true,
                                showDeferredOn: true,
                                showClosedOn: true,
                                showLicensedas: true,
                                showLicenseNumber: true,
                                showLicenseExpirationDate: true,
                                showLicenceNumber: false,
                                showDescription: false,
                                loadAddInfo: true
                            },
                            {
                                ContentItemLkId: "399",
                                DocumentId: 3,
                                DocumentCode: "D1003",
                                showDocumentControl: true,
                                hideDocumentType: true,
                                preSelectFirstDocumentType: true,
                                showWhen: true,
                                showDeniedLicensure: true,
                                showWhatHappened: false,
                                showLicenceNumber: false,
                                additionalInformation: true,
                                loadAddInfo: true
                            },
                            {
                                ContentItemLkId: "400",
                                DocumentId: 4,
                                DocumentCode: "D1004",
                                showDocumentControl: true,
                                hideDocumentType: true,
                                preSelectFirstDocumentType: true,
                                loadAddInfo: true,
                                showDescription: true
                            }
                            ]
                        }
                    };
                    // }

                    $scope.tabContentData = $scope.contentItemsByUseType[$scope.useType];
                }

                if ($scope.isRenewal) {
                    $scope.contentItemsByUseType = {
                        Affidavit: {
                            ContentIds: [{
                                ContentItemLkId: "11",
                                DocumentId: 1,
                                DocumentCode: "D1001",
                                showDocumentControl: true,
                                hideDocumentType: true,
                                preSelectFirstDocumentType: true,
                                showWhen: true,
                                showLicenceNumber: true,
                                showDescription: true,
                                loadAddInfo: true
                            }, {
                                ContentItemLkId: "12",
                                DocumentId: 2,
                                DocumentCode: "D1002",
                                showDocumentControl: false,
                                hideDocumentType: true,
                                preSelectFirstDocumentType: true,
                                showWhen: true,
                                showLicenceNumber: false,
                                showDescription: false,
                                loadAddInfo: true
                            }]
                        }
                    };

                    $scope.tabContentData = $scope.contentItemsByUseType['Affidavit'];
                }

                var callProcessTabsTobeShown = function () {
                    $rootScope.submissionLicenseStatusTypeId = 3;
                    var hideCETab = true;
                    $scope.$emit('processTabsToBeShown', { hideCETab: hideCETab });
                }

                $scope.getLegalInfoConfig()
                    .then(function (data) {
                        var source = "";
                        var ContentLkToPageTabSectionId = '3';
                        $scope.legalInfoConfig = data;
                        if ($scope.isRenewal) {
                            $scope.isConfigLoaded = true;
                            source = "cbaRenewal";
                        }
                        if (!$scope.isRenewal) {
                            AuditVisitInfoSave();
                        }
                        if ($scope.useType == 'Affidavit') {
                            ContentLkToPageTabSectionId = '3';
                        }
                        if ($scope.useType == 'ShareholderOrPartner') {
                            ContentLkToPageTabSectionId = '3';
                            // ContentItemLkId1 = '813';
                            // ContentItemLkId12 = '814';
                        }
                        if (!!$scope.loadData) {

                            peerReviewService.legalInfoGetProvider($scope.providerId, $scope.applicationId, ContentLkToPageTabSectionId, sessionStorage.Key, source)
                                .then(function (response) {
                                    if ($scope.isRenewal) {
                                        $scope.legalInfo = response.EntityLegalList;
                                        $scope.legalInfo = processProviderLegalResponse($scope.legalInfo);
                                    } else {
                                        var dd = processProviderLegalResponse(response.EntityLegalList);
                                        $scope.legalInfos = dd;
                                        $scope.isConfigLoaded = true;
                                    }
                                    backofficeProviderLicenseService.providerLicenseDetailBYProviderId(sessionStorage.Key, $scope.providerId)
                                        .then(function (responseItem) {
                                            $scope.activeproviderLicense = responseItem.ProviderLicenseResponseList.where('!!item.IsLicenseActive').orderByDescending('ProviderLicenseId').firstOrDefault();
                                            $scope.FirmType = $scope.activeproviderLicense.ProviderLicenseTypeId;
                                            

                                            var LinkIndividualLicenseId = 1;

                                            // if($scope.applicationInfo.ApplicationStatusId == 3){
                                            //     LinkIndividualLicenseId = $scope.activeproviderLicense.LinkIndividualLicenseId;
                                            //     $scope.activeproviderLicense = responseItem.IndividualLicenseList.whereEquals(LinkIndividualLicenseId,'ProviderLicenseId').firstOrDefault();
                                            // }
                                            if ($scope.useType == 'ShareholderOrPartner') {
                                                $scope.flagForReview();
                                            }
                                        }, function (error) {
                                            HideLoader();
                                            $scope.showStatusMessage({
                                                messageContent: 'Some error occurred!',
                                                messageType: 'error'
                                            });
                                        });
                                    HideLoader();
                                }, function (error) {
                                    HideLoader();
                                    $scope.showStatusMessage({
                                        messageContent: 'Some error occurred !',
                                        messageType: 'error'
                                    });
                                });
                            // if (!$scope.isRenewal) {
                            //     getaffidavit();
                            // }
                        }
                        if ($scope.isRenewal) {

                        } else {
                            countryStateService.getStateByCountryID(sessionStorage.Key, $rootScope.DefaultCountryId).then(function (stateResponse) {
                                $scope.StatList = stateResponse.State;
                            }, function (error) {

                            });
                        }
                    }, function (error) {
                        HideLoader();
                        $scope.showStatusMessage({
                            messageContent: 'Some error occurred !',
                            messageType: 'error'
                        });
                    });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
                $exceptionHandler(ex);
            }
        };

        $scope.DisplayCASLicensePrimaryStatus = async function(ApplicationInformation){
            ShowLoader();
            let requestData = {
                isn: ApplicationInformation,
            }
                try {
                    return await dcaIService.getCaLicFilePrimStatCde(sessionStorage.Key, requestData)
                        .then(function (response){
                        
                            if (!!response.EntityRecords && response.EntityRecords.length > 0) {
                                // return response.EntityRecords;
                                if (response.EntityRecords[0].primStatCde.includes(13))

                                {    
                                    $scope.isRetiredOrDelinquent = true;
                                    $rootScope.isRetiredOrDelinquent = true;                                    
                                    $scope.legalInfo = processProviderLegalResponse($scope.legalInfo);
                                    // var hideCETab = true;
                                    // $scope.$emit('processTabsToBeShown', { hideCETab: hideCETab });
                                }
                            }
                            
                        }, function(data) {
                            $scope.showStatusMessage(data, "error");
                           
                        });
                } catch (ex) {
                    if (ex != null) {
                        $scope.showStatusMessage(ex.message, "error");
                    }
                }
            }
            

        var getaffidavit = function () {
            var src = 'cadcabpelsgNewApp';
            if ($scope.applicationInfo.ApplicationTypeId == 9) {
                src = 'cadcabpelsgNewApp';
            }
            initialApplicationAcknowledgementService.acknowledgementGet(sessionStorage.Key, $scope.individualId, $scope.applicationId)
                .then(function (res) {
                    if (res.Status && res.IndividualAffidavitResponseList && res.IndividualAffidavitResponseList.length > 0) {
                        $scope.acknowledgement = res.IndividualAffidavitResponseList[0];
                        $scope.acknowledgement.IndividualId = $scope.individualId;
                        $scope.acknowledgement.ApplicationId = $scope.applicationId;
                        if (!$scope.acknowledgement.Date) {
                            $scope.acknowledgement.Date = new Date();
                        }
                    } else {
                        $scope.acknowledgement.Date = new Date();
                    }
                    console.log(res.IndividualAffidavitResponseList);
                }, function (error) {

                });
        }

                
            

        $scope.loadAllProviderLegalAddInfo = function (legalInfoOption, hideLoader) {
            peerReviewService.loadAllProviderLegalAddInfo($scope.providerId, $scope.applicationId, $scope.userId, legalInfoOption, sessionStorage.Key)
                .then(function (response) {
                    if (!!response && response.length > 0) {
                        legalInfoOption.providerLegalAddInfo = response[0];
                    } else {
                        legalInfoOption.providerLegalAddInfo = {
                            IsActive: true,
                            EntityLegalId: legalInfoOption.EntityLegalId,
                            ProviderId: $scope.providerId,
                            ApplicationId: $scope.applicationId
                        }
                    }
                    if (hideLoader)
                        HideLoader();
                }, function (data) {
                    ShowErrMsg();
                });
        };

        var processProviderLegalResponse = function (legalInfoData) {
            // var ques3 = '';
            // var ques4 = '';
            // var ques5 = '';
            // var ques6 = '';
            var legalInfoRecords = [];
            $scope.legalInfoList = {};
            $scope.providerAdditionalInfo = [];

            if($scope.useType == 'Affidavit') {
                angular.forEach(legalInfoData, function (legalInfo) {
                    legalInfo.ProviderId = $scope.providerId;
                    legalInfo.ApplicationId = $scope.applicationId;

                    if (legalInfo.ContentItemLkId == 819) {
                        legalInfo.IsActive = true;
                        $scope.peerReviewQuestion1 = legalInfo;

                        // $scope.peerReviewQuestion1ContentDescription = $scope.peerReviewQuestion1.ContentDescription;
                        // console.log('1', $scope.peerReviewQuestion1);
                        
                        
                    }
                    else if (legalInfo.ContentItemLkId == 820) {
                        legalInfo.IsActive = true;
                        $scope.peerReviewQuestion2 = legalInfo;
                        $scope.peerReviewQuestion2.AdditionalInformation = 'Not Yet Due';
                        $scope.peerReviewQuestion2.documentUpload = null;
                        

                        $scope.peerReviewQuestion2.documentUpload = {
                            ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                            ApplicationId: $scope.applicationId,
                            ProviderId: $scope.providerId,
                            Email: null,
                            SendEmail: null,
                            TransactionId: null,
                            LicenseeReprint: false,
                            DocumentLkToPageTabSectionId: 1,
                            DocumentLkToPageTabSectionCode: "FR",
                            LoadByDocIdAndDocCode: true,
                            DocId: 2,
                            DocCode: "D1002",
                            LoadDocumentsWithoutBroadCast: true
                        };
                        
                        // $scope.peerReviewQuestion2.ContentDescription = peerReviewQuestion2[0].ContentDescription;
                        // console.log('2', $scope.peerReviewQuestion2);
                    }
                    if (legalInfo.ContentItemLkId == 820) {
                        $scope.loadAllProviderLegalAddInfo(legalInfo);
                    }
                    legalInfoRecords.push(legalInfo);
                    $scope.legalInfoList['item' + legalInfo.ContentItemLkId] = legalInfo;
    
                });
            
            }
            else if ($scope.useType == 'ShareholderOrPartner')
            {
                angular.forEach(legalInfoData, function (legalInfo) {
                    if (legalInfo.ContentItemLkId == 813) {
                        legalInfo.IsActive = true;
                        $scope.shareholderOrPartner1 = legalInfo;
                        $scope.providerAdditionalInfo.push($scope.shareholderOrPartner1);
                        if( $scope.isBackoffice == false )
                        {
                            if ($rootScope.lengthOfIndvAdded > 0 || $rootScope.lengthOfDocUploaded > 0) {
                                $scope.shareholderOrPartner1.ContentItemResponse = true;
                            }
                            else {
                                $scope.shareholderOrPartner1.ContentItemResponse = false;
                            }
                        }
                    
                    }
                    else if (legalInfo.ContentItemLkId == 814) {
                        legalInfo.IsActive = true;
                        $scope.shareholderOrPartner2 = legalInfo;
                        $scope.providerAdditionalInfo.push($scope.shareholderOrPartner2);
                        if ($scope.isBackoffice == false)
                        {
                            if ($rootScope.lengthOfIndvAdded > 0 || $rootScope.lengthOfDocUploaded > 0) {
                                $scope.shareholderOrPartner2.ContentItemResponse = true;
                            }
                            else {
                                $scope.shareholderOrPartner2.ContentItemResponse = false;
                            }
                        }
                    }

                    
                    legalInfoRecords.push(legalInfo);
                    $scope.legalInfoList['item' + legalInfo.ContentItemLkId] = legalInfo;
    
                });
                

            }
            return legalInfoRecords;
        };


        // $scope.showChildOrvalidationRequired = function (legalInfoOption) {
        //     return ((
        //         (
        //             (
        //                 (
        //                     (legalInfoOption.ContentItemLkId > 538 && legalInfoOption.ContentItemLkId != 547 && legalInfoOption.ContentItemLkId != 548 && legalInfoOption.ContentItemLkId != 545) 
        //                     || (legalInfoOption.ContentItemLkId == 547 && $scope.legalInfoList.item546.ContentItemResponse == true) 
        //                     || (legalInfoOption.ContentItemLkId == 548 && $scope.legalInfoList.item547.ContentItemResponse == true) 
        //                     || (legalInfoOption.ContentItemLkId == 545 
        //                         && !($scope.providerLicense.reEntryLicensee || $scope.providerLicense.newLicensee) 
        //                         && ($scope.legalInfoList.item542.ContentItemResponse == true || $scope.legalInfoList.item543.ContentItemResponse == true 
        //                             || $scope.legalInfoList.item544.ContentItemResponse == true)
        //                         )
        //                 ) 
        //                 && ($scope.providerLicense.LicenseStatusTypeId != 9 && $scope.providerLicense.LicenseStatusTypeId != 4)
        //             ) 
        //             || (legalInfoOption.ContentItemLkId == 540)
        //         ) 
        //         && $scope.useType == 'ShareholderOrPartner') 
        //         || 
        //         (!$scope.isRetiredOrDelinquent && legalInfoOption.ContentItemLkId == 15 || legalInfoOption.ContentItemLkId == 16 || legalInfoOption.ContentItemLkId == 21)
        //         ||
        //         (
        //             (legalInfoOption.ContentItemLkId == 11 
        //             ||
        //             (legalInfoOption.ContentItemLkId == 12 && $scope.legalInfoList.item11.ContentItemResponse == true)
        //             ||
        //             (
        //                         ($scope.providerLicense.LicenseStatusTypeId != 9  && $scope.providerLicense.LicenseStatusTypeId != 4) 
        //                     && (legalInfoOption.ContentItemLkId != 12 || (legalInfoOption.ContentItemLkId == 12 && $scope.legalInfoList.item11.ContentItemResponse == true)) 
        //                     && (legalInfoOption.ContentItemLkId != 22 || (legalInfoOption.ContentItemLkId == 22 && $scope.legalInfoList.item13.ContentItemResponse == true)) 
        //                     && (legalInfoOption.ContentItemLkId != 23 || (legalInfoOption.ContentItemLkId == 23 && $scope.legalInfoList.item22.ContentItemResponse == true))
        //                 )
        //             ) 
        //             && $scope.useType == 'Affidavit'
        //         )
        //         );
        // };

        $scope.$on("SaveRenewal", function (event, args) {
            // $scope.legalInfoSave($scope.currentForm);
        });

        $scope.$on('SwitchingTab', function (event, args) {
            if (args.fromTab == $scope.tab.name) {
                if (!$scope.isBackoffice) {
                    // $scope.legalInfoSave($scope.currentForm, true);
                } else {
                    $scope.$broadcast("SaveDeficiency", {});
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
            }
        });

        $scope.$on('deficiencyResolve', function (event, args) {
            if (args.tabName == 'background') {
                // $scope.legalInfoSave($scope.currentForm, { fromDeficiency: true });
            }
        });

        $scope.clearTabConfirmation = function () {
            $scope.$emit("clearTabConfirmation", { currentTab: $scope.tab });
        };

        $scope.changeLicenseType = function (licenseStatusTypeId) {

            if ($scope.isRetiredOrDelinquent) {
                $scope.showLegalInfoForRetiredDelinquent = licenseStatusTypeId == 9 ? false : true;                
                // $scope.$emit('processTabsToBeShownIfNotRetired', $scope.providerLicense.LicenseStatusTypeId );
            }
            var hideCETab = false;
            $scope.providerLicense.LicenseStatusTypeId = licenseStatusTypeId;
            $scope.providerLicense.ActionFlag = "M";
            ShowLoader("Please wait...");
            //  licenseApplicationListingService.saveIndividualLicense(sessionStorage.Key, $scope.providerLicense)

            initialApplicationStaffReviewService.saveIndividualLicense(sessionStorage.Key, $scope.providerLicense).then(function (response) {
                if (response.Status) {
                    if (licenseStatusTypeId == 9) {
                        $rootScope.submissionLicenseStatusTypeId = 3;
                        hideCETab = true;
                    }

                    // $scope.providerLicense = response.IndividualLicenseList.whereEquals($scope.applicationId, 'ApplicationId').firstOrDefault();
                    $scope.$emit('processTabsToBeShown', { hideCETab: hideCETab });
                    HideLoader();
                } else {
                    HideLoader();
                }
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage({
                    messageContent: 'Some error occurred !',
                    messageType: 'error'
                });
            });
        }

        init();
    }
})();