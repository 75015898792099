(function () {
    'use strict'

    angular.module('app.enforcement')
    .controller('ComplaintActionsController', ['$scope', '$rootScope', '$q', '$timeout', '$filter', 'ComplaintActionsService', 'utilityService', 'Messages', ComplaintActionsController]);

    function ComplaintActionsController($scope, $rootScope, $q, $timeout, $filter, ComplaintActionsService, utilityService, Messages) {
        var _key = sessionStorage.Key;
        var _childName = '';
        
        $scope.getNewAction = function () {
            return {
                ComplaintActionId: 0,
                ComplaintId: $scope.complaint.ComplaintId,
                ComplaintCaseId: $scope.complaintCaseId,
                ComplaintNumber: $scope.complaint.ComplaintNumber,
                ComplaintActionTypeId: $scope.uiConfig.DefaultItemTypeId,
                ComplaintActionStatusId: null,
                AssignedTo: null,
                Activity: '',

                EffectiveDate: null,
                ActionDatetobeCompleted: null,
                DueDate: null,
                CompletedDate: null,

                OrderSignedDate: null,
                OrderSignedBy: null,

                AllowHearingOfficerView: null,

                UserId: sessionStorage.UserID,

                ComplaintActionNotes: '',

                ReferenceNumber: '',

                CreatedOn: null,

                TabType: '',

                IsActive: true,
                IsDeleted: false,

                ComplaintLawViolationIds: null,
                ComplaintCodeViolationIds: null,
                ComplaintFinancialIds: null,
                ComplaintFinancialAmount: null,
                ComplaintFinancialPaidDate: null,
                ComplaintTermId: null,
                ComplaintAdditionalTermIds: null
            };
        };

        var getConfig = function () {
            var deferred = $q.defer();
        
            try {
                utilityService.getConfigData('/components/backoffice/enforcement/complaint-actions/complaint-actions.server-config.json')
                .then(function(data) {
                    if (!!data) {
                        $scope.config = data;
        
                        $scope.uiConfig = $rootScope.getUIConfig($scope.configName, $scope.config);
                        $scope.tableHeaders = $scope.uiConfig.TableHeaders;

                        $scope.isConfigLoaded = true;

                        if (!!$scope.getConfigExt) {
                            $scope.getConfigExt();
                        }
                    }
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
        
            return deferred.promise;
        };

        $scope.getStaffUsers = function () {
            var deferred = $q.defer();
            
            try {
                ComplaintActionsService.getStaffUsers(sessionStorage.Key)
                .then(function(data) {
                    if (data.Status && data.UsersList) {
                        $scope.usersList = data.UsersList.orderBy('FirstName,LastName');
                    } else {
                        $scope.usersList = [];
                    }
                    $scope.usersList.forEach(function (user) {
                        user.FullName = user.FirstName + ' ' + user.LastName;
                    });
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.getActions = function () {
            var deferred = $q.defer();
            
            try {
                var actionGroupName = (!!$scope.uiConfig.ActionGroupName) ? $scope.uiConfig.ActionGroupName : '';
                var actionGroupNames = (!!$scope.uiConfig.ActionGroupNames) ? $scope.uiConfig.ActionGroupNames : '';

                ComplaintActionsService.getActions(sessionStorage.Key, $scope.complaint.ComplaintId, $scope.complaintCaseId, actionGroupName, actionGroupNames)
                .then(function(data) {
                    if (data.Status && !!data.ComplaintActionList) {
                        $scope.actions = data.ComplaintActionList;

                        if (!!$scope.getActionsExt) {
                            $scope.getActionsExt($scope.actions);
                        }
                        // if (!!$scope.uiConfig.ItemTypeFilter && $scope.uiConfig.ItemTypeFilter.length > 0) {
                        //     $scope.actions = data.ComplaintActionList.whereIncludes($scope.uiConfig.ItemTypeFilter, 'ComplaintActionTypeId');    
                        // } else {
                        //     $scope.actions = data.ComplaintActionList; 
                        // }
                        $scope.actions = $scope.actions
                            .orderByDescending($scope.uiConfig.SortLogBy)
                            .leftJoinObjects($scope.usersList, 'item1.CreatedBy==item2.UserId', 'this1, CreatedByName: item2.FullName');

                        $scope.displayActions = angular.copy($scope.actions);

                        deferred.resolve(data);
                    } else {
                        if (!!$scope.uiConfig.UseDBForm) {
                            if (!!$scope.getActionsExt) {
                                $scope.getActionsExt($scope.actions);
                            }
                            deferred.resolve(data);
                        } else {
                            $scope.actions = [];
                            $scope.displayActions = [];
                            if (!!$scope.showStatusMsg && !data.Status) {
                                $scope.showStatusMsg('-', data);        
                            }
                            deferred.reject(data);
                        }
                    }
                }, function(err) {
                    $scope.showStatusMsg('-', err);
                    deferred.reject(err);
                });
            } catch (ex) {
                $scope.showStatusMsg('-', ex);
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.saveAction = function (action) {
            var deferred = $q.defer();
            
            try {
                ComplaintActionsService.saveActions(sessionStorage.Key, [action])
                .then(function(data) {
                    if (data.Status) {
                        if (!!$scope.actionSaved) {
                            $scope.actionSaved({$complaintAction: data.ComplaintActionList.firstOrDefault()});
                        }

                        if (!!$scope.saveActionExt) {
                            $scope.saveActionExt(data.ComplaintActionList.firstOrDefault());
                        }
                        deferred.resolve(data);
                    } else {
                        $scope.showStatusMsg('-', data);
                        deferred.reject(data);
                    }
                }, function(err) {
                    $scope.showStatusMsg('-', err);
                    deferred.reject(err);
                });
            } catch (ex) {
                $scope.showStatusMsg('-', ex);
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.saveActions = function (actions) {
            var deferred = $q.defer();
            
            try {
                ComplaintActionsService.saveActions(sessionStorage.Key, actions)
                .then(function(data) {
                    if (data.Status) {
                        if (!!$scope.actionsSaved) {
                            $scope.actionsSaved({$complaintActions: data.ComplaintActionList});
                        }
                        deferred.resolve(data);
                    } else {
                        $scope.showStatusMsg('-', data);
                        deferred.reject(data);
                    }
                }, function(err) {
                    $scope.showStatusMsg('-', err);
                    deferred.reject(err);
                });
            } catch (ex) {
                $scope.showStatusMsg('-', ex);
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.addEditActionClicked = function (action) {
            if (!action) {
                action = $scope.getNewAction();
            }
            $scope.current.action = null;
            $timeout(function () {
                $scope.current.action = angular.copy(action);
            });
        };

        $scope.deleteActionClicked = function (action) {
            if (confirm(Messages.eccceeb)) {
                action.IsActive = 0;
                action.IsDeleted = 1;

                $scope.operationPending(true, 'Deleting...');

                $scope.saveAction(action)
                .then(function () {
                    $scope.cancelActionClicked();

                    $scope.getActions()
                    .then(function () {
                        $scope.operationPending(false);
                    }, function () {
                        $scope.operationPending(false);
                    });
                }, function () {
                    $scope.operationPending(false);
                });
            }
        };

        $scope.cancelActionClicked = function () {
            $scope.current.action = null;
        };

        $scope.saveActionClicked = function (form) {
            $scope.hideStatusMsg();
            if (!$scope.validateForm(form)) { return; }

            $scope.operationPending(true, 'Saving...');

            $scope.saveAction($scope.current.action)
            .then(function () {
                $scope.cancelActionClicked();

                $scope.getActions()
                .then(function () {
                    $scope.operationPending(false);
                }, function () {
                    $scope.operationPending(false);
                });
            }, function () {
                $scope.operationPending(false);
            });
        };

        $scope.filterActions = function () {
            if (!!$scope.uiConfig) {
                $scope.originalActionList = angular.copy($scope.actionList);
                $scope.actionList = $scope.originalActionList.where(function (action) {
                    var groups = (!!action.GroupNames) ? action.GroupNames.split(',') : [];
                    var actionGroupName = (!!$scope.uiConfig.ActionGroupName) ? $scope.uiConfig.ActionGroupName : '';
                    var itemTypeFilter = (!!$scope.uiConfig.ItemTypeFilter) ? $scope.uiConfig.ItemTypeFilter : [];

                    return (itemTypeFilter.length == 0 || itemTypeFilter.includes(action.ComplaintActionTypeId)) 
                        && (groups.length == 0 || groups.includes(actionGroupName));
                });
            }
        };

        var checkComplaintActionNotes = function (action, searchString, searchRegex) {
            var rawText = $filter('htmlToPlaintext')(action.ComplaintActionNotes).toLowerCase();

            return !!rawText.match(searchRegex);
        };

        $scope.searchTextChanged = function () {
            $scope.displayActions = $filter('filterLog')($scope.actions, $scope.searchParams.searchText, [
                    'ActionType', 'AssignedToName', 'EffectiveDate', 'CompletedDate', 'OrderSignedDate',
                    'CreatedOn', 'CreatedByName', checkComplaintActionNotes, 'ActionStatus',
                    'ComplaintLawViolationNames', 'ComplaintCodeViolationNames', 'ComplaintFinancialNames',
                    'ComplaintTermName', 'ComplaintAdditionalTermNames', 'ComplaintFinancialAmount'
                ], 'MM/dd/yyyy hh:mm:ss a');
        };

        $scope.toggleRowDetailsClicked = function (item, name) {
            if (item.rowDetails == name) {
                item.rowDetails = '';
            } else {
                item.rowDetails = name;
            }

            item.rowClass = {
                'row-expanded': !!item.rowDetails
            };
        };

        $scope.closeRowDetailsClicked = function (item) {
            item.rowDetails = '';
            item.rowClass = {
                'row-expanded': !!item.rowDetails
            };
        };
        
        $scope.init = function () {
            $scope.uiLogic = {};
            $scope.current = {
                action: null
            };
            $scope.refTables = {};

            $scope.searchParams = {
                searchText: ''
            };

            $scope.pager = {};
            $scope.actions = [];
            $scope.tableHeaders = {};
            $scope.currentUserId = sessionStorage.UserID;

            if (typeof($scope.canAddNew) == 'undefined') {
                $scope.canAddNew = true;
            }
            if (typeof($scope.canEdit) == 'undefined') {
                $scope.canEdit = true;
            }
            if (typeof($scope.canDelete) == 'undefined') {
                $scope.canDelete = true;
            }

            getConfig();
            $timeout(function () {
                $scope.operationPending(true, 'Loading...');

                $scope.getStaffUsers()
                .then(function() {
                    $scope.getActions()
                    .then(function () {
                        $scope.operationPending(false);
                    }, function () {
                        $scope.operationPending(false);
                    });
                }, function () {
                    $scope.operationPending(false);
                });
            });

            if (!!$scope.initExt) {
                $scope.initExt();
            }
        };

        $scope.$watch('actions', function (newVal) {
            $scope.currentActions = newVal;
        });

        $scope.$watch('displayActions', function (newVal) {
            $scope.currentDisplayActions = newVal;
        });
    }
})();
