(function () {
  'use strict'
  angular.module('app.InitialApplication')
    .directive('initialApplicationChildSupport', initialApplicationChildSupportDirective);

  initialApplicationChildSupportDirective.$inject = [];

  function initialApplicationChildSupportDirective() {
    return {
      restrict: 'E',
      scope: {
        childSupport: "=childSupport",
        showSubmitPanel: "=showSubmitPanel",
        showHeader: "=showHeader",
        headingPosition: "=headingPosition",
        natValidation: "=natValidation"
      },
      templateUrl: "app/components/individual/application/directives/child-support/child-support.html",
      controller: "InitialApplicationChildSupportController"
    }
  }
})();