(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeAllegationController", BackofficeAllegationController);

    BackofficeAllegationController.$inject = ["$rootScope", "$scope", "$state", "$q", "backofficeIndividualAllegationService", "backofficeIndividualLicenseService", "initialApplicationstatusService",
        "countryStateService", "typeValuesService", "lookupService", "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl", "multipleFileUploadService", "IndividualDocumentSaveService", "Messages"
    ];

    function BackofficeAllegationController($rootScope, $scope, $state, $q, backofficeIndividualAllegationService, backofficeIndividualLicenseService, initialApplicationstatusService, countryStateService, typeValuesService, lookupService, utilityService, $exceptionHandler, TypeValue, WebApiUrl, multipleFileUploadService, IndividualDocumentSaveService, Messages) {

        $scope.viewConfig = {
            showEditView: false,

        };

        $scope.documentByIndividualDocumentId = multipleFileUploadService.documentByIndividualDocumentId;

        $scope.uploadDocument = [];
        $scope.uploadDocument2 = [];
        $scope.AllegationTypes = [{ id: 1, value: 'Board Action' }, { id: 2, value: 'Malpractice' }];
        $scope.AllegationStatus = [{ id: 1, value: 'Pending' }, { id: 2, value: 'Closed' }, { id: 3, value: 'Other' }]

        $scope.natValidation = {};
        $scope.natValidation2 = {};
        $scope.allegation = {};
        $scope.datePicker = {
            effectiveDateOpened: false,
            expirationDateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            },
            effectiveDateOptions: {
                maxDate: null
            },
            expirationDateOptions: {
                minDate: null
            }
        };
        $scope.feeDetailsTemplate = {
            applicationFee: 200,
            totalFee: 200,
            lateFeeAmount: 0,
            lateFee: 0,
            isReInstatement: false,
            reInstatementFee: 0
        };
        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "allegation" && !$rootScope.individualCurrentTab.isLoadedOnce) {
                if (!$scope.isLoadedOnce) {
                    $scope.isLoadedOnce = true;
                }
                $scope.refallegationstatus2(2);
                $scope.refallegationstatus1(1);
                $scope.IndividualId = individualId;
                $scope.UserId = sessionStorage.UserID;
                $scope.isBackoffice = true;
                $scope.validator = { feePayment: {} };
                $scope.loadAllegationTabData($scope.IndividualId);
                $rootScope.individualCurrentTab.isLoadedOnce = true;
                getDoumentType(8, 'D1008');
                getDoumentType(7, 'D1007');

                $scope.documentBoardAction = {
                    ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                    ApplicationId: $scope.applicationId,
                    IndividualId: $scope.IndividualId,
                    Email: null,
                    SendEmail: null,
                    TransactionId: null,
                    LicenseeReprint: false,
                    DocumentLkToPageTabSectionId: 1,
                    DocumentLkToPageTabSectionCode: "R",
                    LoadByDocIdAndDocCode: true,
                    DocId: 8,
                    DocCode: "D1008",
                    LoadDocumentsWithoutBroadCast: false,
                    LinkType: 'AL',
                    PropertyToProcess: 'IndividualAllegationId',
                    IsLinkedDocControl: true
                };
                $scope.documentMalpractice = {
                    ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                    ApplicationId: $scope.applicationId,
                    IndividualId: $scope.IndividualId,
                    Email: null,
                    SendEmail: null,
                    TransactionId: null,
                    LicenseeReprint: false,
                    DocumentLkToPageTabSectionId: 1,
                    DocumentLkToPageTabSectionCode: "R",
                    LoadByDocIdAndDocCode: true,
                    DocId: 7,
                    DocCode: "D1007",
                    LoadDocumentsWithoutBroadCast: false,
                    LinkType: 'AL',
                    PropertyToProcess: 'IndividualAllegationId',
                    IsLinkedDocControl: true
                };

            }
        });

        var getDoumentType = function (id, code) {
            multipleFileUploadService.documentGetDocumentTypeName(sessionStorage.Key, id, code)
                .then(function (response) {
                    if ($scope.checkResponse(response)) {
                        if (id == 8) {
                            $scope.DocType = response.DocumentMasterGET;
                        } else {
                            $scope.DocType2 = response.DocumentMasterGET;
                        }
                        // if (!!$scope.preSelectFirstDocumentType && (!!$scope.DocType && $scope.DocType.length > 0))
                        //     $scope.document.DocumentType = $scope.DocType[0];
                    }
                }, function (data) {
                    showStatusMessage(data, "error");
                });
        }

        $scope.setPermitEndMinDate = function (permit) {
            if (!!permit.PermitEffectiveDate && angular.isDate(permit.PermitEffectiveDate))
                $scope.datePicker.expirationDateOptions.minDate = permit.PermitEffectiveDate;
        };

        $scope.setPermitStartMaxDate = function (permit) {
            if (!!permit.PermitExpirationDate && angular.isDate(permit.PermitExpirationDate))
                $scope.datePicker.effectiveDateOptions.maxDate = permit.PermitExpirationDate;
        };

        $scope.loadAllegationTabData = function (individualId) {
            var dataPromises = [
                getIndividualAllegation(individualId),
                getDescriptionType(individualId)

            ];
            ShowLoader("Please Wait...");
            $q.all(dataPromises).then(function (response) {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                HideLoader();
            }, function (error) {
                HideLoader();
            });

        };

        var getIndividualAllegation = function (individualId) {

            var promise = backofficeIndividualAllegationService.getIndividualAllegationAndDocs(individualId, sessionStorage.Key);
            // var promise = backofficeIndividualAllegationService.getIndividualAllegation(individualId, sessionStorage.Key);
            promise.then(function (res) {
                //console.log(res);
                $scope.GetIndividualAllegationListBoardAction = res.IndividualAllegationDocsSaveList.filter(function (x) {
                    if (x.AllegationTypeId == 1) {
                        return true;
                    }
                    else return false;
                });
                processIndividualBoardActionAndFields();
                //console.log($scope.GetIndividualAllegationListBoardAction);
                //debugger
                $scope.GetIndividualAllegationListMalpractice = res.IndividualAllegationDocsSaveList.filter(function (x) {
                    if (x.AllegationTypeId == 2)
                        return true;
                    else return false;
                });
                processIndividualMalpracticeAndFields();
            }, function (err) { $scope.showStatusMessage(err.message, "error"); });

        }

        var getDescriptionType = function (individualId) {
            var promise = backofficeIndividualAllegationService.refAllegationDescription(individualId, sessionStorage.Key);
            promise.then(function (response) {
                $scope.refAllegationDescriptionTypeList = response.RefAllegationDescriptionTypeList;
            }, function (err) { $scope.showStatusMessage(err.message, "error") });
            return promise;
        }

        $scope.addAction = function (val) {

            $scope.viewConfig.showEditView = true;
            $scope.action = val;
            $scope.allegation = {
                IndividualId: $scope.IndividualId,
                AllegationTypeId: val,
                IsActive: true,
                IsDeleted: false
            };
            if (val == 2) {
                $scope.currentSelect = $scope.MalpracticeData;
            }
            else {
                $scope.currentSelect = $scope.BoradActionData;
            }
            // $scope.documentsList = null;
            // $scope.documentUpload = {
            //     ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            //     ApplicationId: null,
            //     IndividualId: $scope.IndividualId,
            //     Email: null,
            //     SendEmail: null,
            //     TransactionId: null,
            //     LicenseeReprint: false,
            //     DocumentLkToPageTabSectionId: 1,
            //     DocumentLkToPageTabSectionCode: "FR",
            //     LinkType: 1
            // };
            $scope.showDocument = false;
        }

        $scope.addAction2 = function (val) {
            $scope.viewConfig.showEditViewMalpractice = true;
            $scope.action = val;
            $scope.malpracticeAllegation = {
                IndividualId: $scope.IndividualId,
                AllegationTypeId: val,
                IsActive: true,
                IsDeleted: false
            };
            $scope.showMalpracticeDocument = false;
        }

        $scope.refallegationstatus2 = function (id) {
            backofficeIndividualAllegationService.refAllegationStatus($scope.IndividualId, sessionStorage.Key, id)
                .then(function (response) {
                    if ($scope.checkServerResponse(response)) {
                        $scope.MalpracticeData = response.RefAllegationStatusList;
                    }
                }, function (data) {
                    HideLoader();
                    $scope.showStatusMessage(data, "error");
                });
        };



        $scope.refallegationstatus1 = function (id) {
            var promise = backofficeIndividualAllegationService.refAllegationStatus($scope.IndividualId, sessionStorage.Key, id)
                .then(function (response) {
                    if ($scope.checkServerResponse(response)) {
                        // debugger
                        $scope.BoradActionData = response.RefAllegationStatusList;
                        $scope.currentSelect = $scope.BoradActionData;
                    }
                }, function (data) {
                    HideLoader();
                    $scope.showStatusMessage(data, "error");
                });
        };

        var showEditView = function () {
            $scope.viewConfig.showEditView = true;
        };

        $scope.openAddEditAllegation = function (isEdit) {
            showEditView();
            HideLoader();
        };

        var showSitePermit = function () {
            $scope.viewConfig.showAddSitePermit = true;
        };

        // $scope.$on('documentsSavedFromControl', function (data) {
        //     if ($scope.documentUpload) {
        //         if ($scope.documentUpload.documentsList) {
        //             if ($scope.documentUpload.documentsList.length > 0) {
        //                 angular.forEach($scope.documentUpload.documentsList[0].DocumentUploadList, function (item) {
        //                     $scope.uploadDocument.push(item);
        //                 });
        //             }
        //         }
        //     }
        //     if ($scope.malpracticeDocumentUpload) {
        //         if ($scope.malpracticeDocumentUpload.documentsList) {
        //             if ($scope.malpracticeDocumentUpload.documentsList.length > 0) {
        //                 angular.forEach($scope.malpracticeDocumentUpload.documentsList[0].DocumentUploadList, function (item) {
        //                     $scope.uploadDocument2.push(item);
        //                 });
        //             }
        //         }
        //     }
        //     if ($scope.uploadDocument) {
        //         if ($scope.uploadDocument.length > 0)
        //             saveDoc();
        //     }
        //     if ($scope.uploadDocument2) {
        //         if ($scope.uploadDocument2.length > 0)
        //             saveDoc2();
        //     }
        // });

        $scope.editMalpracticeAllegation = function (allegation, val) {

            if (val) {
                ShowLoader();
                $scope.malpracticeDocumentsList = null;
                $scope.malpracticeDocumentUpload = {
                    ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                    ApplicationId: null,
                    IndividualId: $scope.IndividualId,
                    Email: null,
                    SendEmail: null,
                    TransactionId: null,
                    LicenseeReprint: false,
                    DocumentLkToPageTabSectionId: 1,
                    DocumentLkToPageTabSectionCode: "R",
                    LoadByDocIdAndDocCode: true,
                    LinkType: 'AM',
                    IndividualAllegationId: allegation.IndividualAllegationId,
                    LoadDocumentsWithoutBroadCast: true,
                    DocId: 7,
                    DocCode: 'D1007',
                    isPiblic: true,
                    hideDocumentType: true
                };
                $scope.showMalpracticeDocument = true;
            }
            else {
                $scope.showMalpracticeDocument = false;
            }
            $scope.malpracticeAllegation = angular.copy(allegation);
            $scope.action = 2;
            $scope.MalpracticeData;
            $scope.viewConfig.showEditViewMalpractice = true;


        }

        $scope.editAllegation = function (allegation, AllegationTypeId, val) {
            if (val) {
                ShowLoader();
                // $scope.documentsList = null;
                // $scope.documentUpload = {
                //     ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                //     ApplicationId: null,
                //     IndividualId: $scope.IndividualId,
                //     Email: null,
                //     SendEmail: null,
                //     TransactionId: null,
                //     LicenseeReprint: false,
                //     DocumentLkToPageTabSectionId: 1,
                //     DocumentLkToPageTabSectionCode: "R",
                //     LoadByDocIdAndDocCode: true,
                //     LinkType: allegation.AllegationTypeId == 1 ? 'AB' : 'AM',
                //     IndividualAllegationId: allegation.IndividualAllegationId,
                //     LoadDocumentsWithoutBroadCast: true,
                //     DocId: 8,
                //     DocCode: 'D1008',
                //     isPiblic: true,
                //     hideDocumentType: true
                // };
                $scope.showDocument = true;
            }
            else {
                $scope.showDocument = false;
            }

            $scope.allegation = angular.copy(allegation);
            $scope.originalPermit = angular.copy(allegation);
            // $scope.refallegationstatus(AllegationTypeId);
            $scope.action = AllegationTypeId;
            $scope.openAddEditAllegation(true);


            // if (AllegationTypeId == 2) {
            //     $scope.currentSelect = $scope.MalpracticeData;
            // }
            // else {
            $scope.currentSelect = $scope.BoradActionData;
            // }
        };

        $scope.refallegationstatus = function (AllegationTypeId) {
            var promise = backofficeIndividualAllegationService.refAllegationStatus($scope.IndividualId, sessionStorage.Key, AllegationTypeId);
            promise.then(function (response) { $scope.refAllegationstatuslist = response.RefAllegationStatusList; },
                function (err) { $scope.showStatusMessage(err.message, "error") });
            return promise;
        };

        $scope.closeAddEditAllegation = function () {
            $scope.viewConfig.showEditView = false;
            $scope.natValidation.errorMessages = [];
            $scope.showDocument = false;
            $scope.resetDocumentList2();
        };
        $scope.closeAddEditAllegation2 = function () {
            $scope.viewConfig.showEditViewMalpractice = false;
            $scope.natValidation2.errorMessages = [];
            $scope.showMalpracticeDocument = false;
            $scope.resetDocumentList2();
        };

        var validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            }
            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            }
            else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        var validateForm2 = function (currentForm) {
            $scope.natValidation2.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                $scope.natValidation2.errorMessages = angular.copy(currentForm.errorMessages);
            }
            if (!!$scope.natValidation2.errorMessages && $scope.natValidation2.errorMessages.length > 0) {
                $scope.natValidation2.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            }
            else {
                $scope.natValidation2.showError = false;
                $scope.natValidation2.isValid = true;
                return true;
            }
        };

        $scope.individualPermitByIndividualId = function (IndividualId, isInitialRequest, permitType) {
            if (!isInitialRequest)
                ShowLoader("Please Wait...");
            try {
                var individualPermitPromise = backofficeIndividualPermitService.individualPermitByIndividualId(sessionStorage.Key, IndividualId, permitType);
                individualPermitPromise.then(function (response) {
                    if (!isInitialRequest) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        HideLoader();
                    }
                    if ($scope.checkServerResponse(response)) {
                        if (permitType == 1) {
                            $scope.sitePermits = response.PermitResponseList;
                        } else if (permitType == 2) {
                            $scope.administartorPermits = response.PermitResponseList;
                        }
                    }
                },
                    function (data) {
                        $scope.showStatusMessage(data, "error");
                    });
                return individualPermitPromise;
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        };

        var processIndividualBoardActionAndFields = function () {
            if ($scope.GetIndividualAllegationListBoardAction && $scope.GetIndividualAllegationListBoardAction.length > 0) {
                multipleFileUploadService.individualDocumentProcess(sessionStorage.Key, $scope.IndividualId,
                    $scope.GetIndividualAllegationListBoardAction, $scope.documentBoardAction.PropertyToProcess, $scope.documentBoardAction.LinkType).then(function (responseData) {
                        $scope.GetIndividualAllegationListBoardAction = responseData;
                        //console.log(responseData);
                        HideLoader();
                    });
            }
        };
        var processIndividualMalpracticeAndFields = function () {
            if ($scope.GetIndividualAllegationListMalpractice && $scope.GetIndividualAllegationListMalpractice.length > 0) {
                multipleFileUploadService.individualDocumentProcess(sessionStorage.Key, $scope.IndividualId,
                    $scope.GetIndividualAllegationListMalpractice, $scope.documentMalpractice.PropertyToProcess, $scope.documentMalpractice.LinkType).then(function (responseData) {
                        $scope.GetIndividualAllegationListMalpractice = responseData;
                        //console.log(responseData);
                        HideLoader();
                    });
            }
        };

        $scope.saveAllegation = function (allegationForm) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                allegationForm.$submitted = true;
                ShowLoader("Please wait...");

                //if ($scope.validateData(allegationForm)) {
                if (validateForm(allegationForm)) {
                    // console.log($scope.documentUpload);
                    $scope.saveAllegationInfo(false);
                } else {
                    HideLoader();
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        };

        $scope.saveAllegation2 = function (allegationForm) {
            try {
                var allegationData = angular.copy($scope.malpracticeAllegation);
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                allegationForm.$submitted = true;
                ShowLoader("Please wait...");

                //if ($scope.validateData(allegationForm)) {
                if (validateForm2(allegationForm)) {
                    // console.log($scope.documentUpload);
                    $scope.malpracticeAllegation.DocumentUploadList = $scope.individualDocumentUpload.DocumentUploadList;
                    backofficeIndividualAllegationService.saveAllegationDoc([$scope.malpracticeAllegation],
                        sessionStorage.Key).then(function (response) {
                            if ($scope.checkServerResponse(response)) {
                                allegationData.IndividualAllegationId = response.IndividualAllegationDocsSaveList[0].IndividualAllegationId;
                                $scope.documentMalpractice.individualDocumentSave(allegationData.IndividualAllegationId).then(function (documentResponse) {
                                    if (documentResponse.Status) {
                                        $scope.closeAddEditAllegation2();
                                        $scope.individualDocumentUpload.DocumentUploadList = [];
                                        $scope.loadAllegationTabData($scope.IndividualId);
                                        $scope.showStatusMessage(response.Message, "success");
                                        HideLoader();
                                    } else {
                                        HideLoader();
                                        ShowErrMsg('educationDocUploadFailed', "Unable to upload Malpractice documents");
                                    }
                                }, function (error) {
                                    HideLoader();
                                    ShowErrMsg('educationDocUploadFailed', "Unable to upload Malpractice documents");
                                })
                            }
                            // if (IsDeleted)
                            //     HideLoader();
                        }, function (data) {
                            HideLoader();
                            if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                        });
                } else {
                    HideLoader();
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        };

        $scope.savePermitAndProceedToPayment = function (permitForm) {
            $scope.hideStatusMessage();
            $scope.hideValidationSummary();
            permitForm.$submitted = true;
            ShowLoader("Please wait...");

            if ($scope.validateData(permitForm)) {
                //if (permitForm.PermitStatusId == 2) {
                var reqData = {
                    ApplicationTypeId: (($scope.permit.PermitTypeId == 1) ? 8 : 9),
                    ApplicationStatusId: 1,
                    ApplicationSubmitMode: 'B',
                    GoPaperless: true,
                    IsActive: 1
                };

                backofficeIndividualPermitService.generateApplicationId($scope.UserId, reqData, sessionStorage.Key)
                    .then(function (responseApplication) {
                        if ($scope.checkServerResponse(responseApplication)) {
                            $scope.ApplicationId = responseApplication.ApplicationId;
                            var reqIndAppData = {
                                ApplicationId: responseApplication.ApplicationId,
                                IndividualId: $scope.IndividualId,
                                CreatedBy: $scope.UserId,
                                CreatedOn: new Date()
                            };
                            backofficeIndividualPermitService.saveIndividualApplication(reqIndAppData, sessionStorage.Key)
                                .then(function (responseIndApplication) {
                                    if ($scope.checkServerResponse(responseIndApplication)) {
                                        $scope.permit.ApplicationId = responseApplication.ApplicationId;
                                        $scope.permit.IndividualLicenseId = $scope.permit.individualLicense.IndividualLicenseId;
                                        var permitData = angular.copy($scope.permit);
                                        permitData.PermitStatusId = 11;
                                        permitData.PermitId = 0;
                                        backofficeIndividualPermitService.savePermitInfo([permitData], sessionStorage.Key)
                                            .then(function (responsePermit) {
                                                if ($scope.checkServerResponse(responsePermit)) {
                                                    var currentSavedPermit = null;
                                                    var permitsToCompare = [];
                                                    var returnedPermits = [];
                                                    if ($scope.permit.PermitTypeId == 1) {
                                                        permitsToCompare = $scope.sitePermits;
                                                        returnedPermits = responsePermit.SitePermitResponseList;
                                                    } else if ($scope.permit.PermitTypeId == 2) {
                                                        permitsToCompare = $scope.administartorPermits;
                                                        returnedPermits = responsePermit.AdminPermitResponseList;
                                                    }
                                                    angular.forEach(returnedPermits, function (savedPermitItem) {
                                                        var isNewPermit = true;
                                                        angular.forEach(permitsToCompare, function (permitToCompare) {
                                                            if (savedPermitItem.PermitId == permitToCompare.PermitId) {
                                                                isNewPermit = false;
                                                            }
                                                        });
                                                        if (isNewPermit) {
                                                            currentSavedPermit = savedPermitItem;
                                                        }
                                                    });
                                                    $scope.permit.PermitId = currentSavedPermit.PermitId;
                                                    //$scope.permit = currentSavedPermit;
                                                    //processDatesAndIndividualLicense();
                                                    $scope.ProceedtoPaymentSectionEnable = true;
                                                    HideLoader();
                                                } else {
                                                    HideLoader();
                                                }
                                            }, function (data) {
                                                $scope.showStatusMessage(data, "error");
                                                HideLoader();
                                            });
                                    } else {
                                        HideLoader();
                                    }
                                }, function (data) {
                                    $scope.showStatusMessage(data, "error");
                                    HideLoader();
                                });
                        } else {
                            HideLoader();
                        }
                    },
                        function (data) {
                            $scope.showStatusMessage(data, "error");
                            HideLoader();
                        });

                //}
            } else {
                HideLoader();
            }
        };

        $scope.payAndSubmit = function () {
            initialApplicationstatusService.resetAll();
            $scope.$broadcast("PayandSubmit", {});
            var paymentStatus = '';
            var interval = setInterval(function () {
                paymentStatus = initialApplicationstatusService.getPaymetStatus();
                if (!!paymentStatus && paymentStatus != "waiting") {
                    clearInterval(interval);
                    if (paymentStatus == "success") {
                        var permitDataItems = [];
                        var permitData = angular.copy($scope.originalPermit);
                        permitData.PermitStatusId = 4;
                        permitDataItems.push(permitData);
                        var permitDataNew = angular.copy($scope.permit);
                        permitDataNew.PermitStatusId = $scope.permit.PermitStatusId;
                        permitDataItems.push(permitDataNew);
                        backofficeIndividualPermitService.savePermitInfo(permitDataItems, sessionStorage.Key)
                            .then(function (responsePermit) {
                                if ($scope.checkServerResponse(responsePermit)) {
                                    var reqData = {
                                        ApplicationId: $scope.ApplicationId,
                                        ApplicationTypeId: (($scope.permit.PermitTypeId == 1) ? 8 : 9),
                                        ApplicationStatusId: 3,
                                        ApplicationStatusReasonId: 0,
                                        IsActive: true,
                                        CreatedBy: $scope.UserId,
                                        ApplicationInternalStatusReasonText: '',
                                        ApplicationInternalStatusId: 0
                                    };
                                    var reqDataChangeLog = {
                                        ApplicationId: $scope.ApplicationId,
                                        ApplicationNumber: '',
                                        ApplicationStatusReasonId: 0,
                                        ApplicationTypeId: (($scope.permit.PermitTypeId == 1) ? 8 : 9),
                                        ApplicationStatusId: 3,
                                        ApplicationSubmitMode: 'B',
                                        ApplicationStatusChangedBy: $scope.UserId,
                                        ReferenceNumber: '',
                                        LicenseTypeId: 0,
                                        ApplicationInternalStatusId: 0,
                                        ApplicationInternalStatusReasonText: ''
                                    };

                                    var dataSavePromises = [
                                        initialApplicationstatusService.saveApplicationStatus($scope.ApplicationId, sessionStorage.Key, 3),
                                        initialApplicationstatusService.saveApplicationStatusLk(sessionStorage.Key, $scope.UserId, reqData),
                                        initialApplicationstatusService.saveApplicationStatusChangeLog(sessionStorage.Key, $scope.UserId, reqDataChangeLog)
                                    ];
                                    $q.all(dataSavePromises).then(function (responseItems) {
                                        var allSavedSuccessFully = true;
                                        angular.forEach(responseItems, function (responseItem) {
                                            if (!$scope.checkServerResponse(responseItem)) {
                                                allSavedSuccessFully = false;
                                            }
                                        });
                                        if (!!allSavedSuccessFully) {
                                            $scope.ProceedtoPaymentSectionEnable = false;
                                            $scope.closeAddEditPermit();
                                            $scope.individualPermitByIndividualId($scope.IndividualId, false, permitDataNew.PermitTypeId);
                                        } else
                                            HideLoader();
                                    }, function (data) {
                                        HideLoader();
                                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                                    });
                                }
                                HideLoader();
                            }, function (data) {
                                HideLoader();
                                if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                            });
                    }
                    if (paymentStatus == "error") {
                        HideLoader();
                    }
                }
            }, 500);
        };

        $scope.saveAllegationInfo = function (IsDeleted) {
            var allegationData = angular.copy($scope.allegation);
            allegationData.IsDeleted = IsDeleted;
            if (!IsDeleted) {
                allegationData.DocumentUploadList = $scope.individualDocumentUpload2.DocumentUploadList;
            }
            backofficeIndividualAllegationService.saveAllegationDoc([allegationData],
                sessionStorage.Key).then(function (response) {
                    if ($scope.checkServerResponse(response)) {
                        allegationData.IndividualAllegationId = response.IndividualAllegationDocsSaveList[0].IndividualAllegationId;
                        $scope.documentBoardAction.individualDocumentSave(allegationData.IndividualAllegationId).then(function (documentResponse) {
                            if (documentResponse.Status) {
                                $scope.closeAddEditAllegation();
                                $scope.loadAllegationTabData($scope.IndividualId);
                                $scope.showStatusMessage(response.Message, "success");
                                // $scope.viewConfig.showAddEducationBlock = false;
                                HideLoader();
                            } else {
                                HideLoader();
                                ShowErrMsg('educationDocUploadFailed', "Unable to upload Board Action documents");
                            }
                        }, function (error) {
                            HideLoader();
                            ShowErrMsg('educationDocUploadFailed', "Unable to upload Board Action documents");
                        })



                    }
                    if (IsDeleted)
                        HideLoader();
                }, function (data) {
                    HideLoader();
                    if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                });
        };


        $scope.deletePermit = function (permit) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                ShowLoader("Please Wait...");
                $scope.allegation = angular.copy(permit);
                $scope.allegation.IsDeleted = true;
                $scope.permit = angular.copy(permit);
                $scope.permit.IsDeleted = true;
                //$scope.savePermitInfo(true);
                $scope.saveAllegationInfo(true);
            }
        };

        $scope.masterDocument = {
            DocumentName: "",
            DocumentType: "",
            DocumentFile: null
        };

        $scope.resetDocument = function () {
            $scope.document = angular.copy($scope.masterDocument);
            if (!!$scope.preSelectFirstDocumentType && (!!$scope.DocType2 && $scope.DocType.length > 0))
                $scope.document.DocumentType = $scope.DocType2[0];
        }

        $scope.resetDocumentList = function () {
            $scope.resetDocument();
            $scope.individualDocumentUpload = {};
            $scope.individualDocumentUpload.DocumentUploadList = [];
        }

        $scope.resetDocumentList();
        $scope.AddNewDocument = function () {
            var document = $scope.document;
            var individualDocumentUpload = $scope.individualDocumentUpload;
            var date = new Date();
            $scope.individualDocumentUpload.DocumentUploadList.push({
                IndividualDocumentId: 0,
                DocumentLkToPageTabSectionCode: 'R',
                DocumentLkToPageTabSectionId: 1,
                DocumentName: document.DocumentName,
                DocumentTypeId: $scope.DocType2[0].DocumentMasterId,
                DocumentTypeName: $scope.DocType2[0].DocumentTypeIdName,
                DocumentPath: document.DocumentFile.filename,
                DocNameWithExtention: document.DocumentName,
                DocStrBase64: document.DocumentFile.base64,
                IsDocumentUploadedbyIndividual: (!sessionStorage.isBackOfficeUser),
                IsDocumentUploadedbyStaff: (!!sessionStorage.isBackOfficeUser),
                ReferenceNumber: "",
                DocumentId: 19,
                DocumentCd: "D1019",
                OtherDocumentTypeName: document.DocumentType.DocumentTypeIdName,
                EffectiveDate: date
            });
            $scope.resetDocument();
        };

        $scope.masterDocument2 = {
            DocumentName: "",
            DocumentType: "",
            DocumentFile: null
        };

        $scope.resetDocument2 = function () {
            $scope.document2 = angular.copy($scope.masterDocument2);
            // if (!!$scope.preSelectFirstDocumentType && (!!$scope.DocType2 && $scope.DocType.length > 0))
            //     $scope.document.DocumentType = $scope.DocType2[0];
        }

        $scope.resetDocumentList2 = function () {
            $scope.resetDocument2();
            $scope.individualDocumentUpload2 = {};
            $scope.individualDocumentUpload2.DocumentUploadList = [];
        }

        $scope.resetDocumentList2();
        $scope.AddNewDocument2 = function () {
            var document = $scope.document2;
            var individualDocumentUpload = $scope.individualDocumentUpload2;
            var date = new Date();
            $scope.individualDocumentUpload2.DocumentUploadList.push({
                IndividualDocumentId: 0,
                DocumentLkToPageTabSectionCode: 'R',
                DocumentLkToPageTabSectionId: 1,
                DocumentName: document.DocumentName,
                DocumentTypeId: $scope.DocType[0].DocumentMasterId,
                DocumentTypeName: $scope.DocType[0].DocumentTypeIdName,
                DocumentPath: document.DocumentFile.filename,
                DocNameWithExtention: document.DocumentName,
                DocStrBase64: document.DocumentFile.base64,
                IsDocumentUploadedbyIndividual: (!sessionStorage.isBackOfficeUser),
                IsDocumentUploadedbyStaff: (!!sessionStorage.isBackOfficeUser),
                ReferenceNumber: "",
                DocumentId: 8,
                DocumentCd: "D1008",
                OtherDocumentTypeName: document.DocumentType.DocumentTypeIdName,
                EffectiveDate: date
            });
            $scope.resetDocument2();
        };

        $scope.deleteDoc = function (doc) {
            var index = $scope.individualDocumentUpload.DocumentUploadList.indexOf(doc);
            $scope.individualDocumentUpload.DocumentUploadList.splice(index, 1);
        };

        $scope.deleteDoc2 = function (doc) {
            var index = $scope.individualDocumentUpload.DocumentUploadList.indexOf(doc);
            $scope.individualDocumentUpload.DocumentUploadList.splice(index, 1);
        };

        $scope.individualDocumentDeleteByID = function (doc) {
            if (confirm(Messages.cdeefee)) {
                ShowLoader();
                backofficeIndividualAllegationService.individualDocumentDeleteByID(doc.IndividualDocumentId, sessionStorage.Key).then(function (response) {
                    HideLoader();
                    doc.IsDeleted = true;
                    getIndividualAllegation($scope.IndividualId);
                }, function (error) {
                    HideLoader();
                });
            }
        }

        $scope.getDocument = function (IndividualDocumentId) {
            try {
                ShowLoader("Please wait...");
                IndividualDocumentSaveService.PdfDocumentByIndividualDocumentId(sessionStorage.Key, IndividualDocumentId)
                    .then(function (response) {
                        var headers = response.headers();
                        response = response.data;
                        utilityService.downloadExcelFile(response, headers);
                        HideLoader();
                    });
            } catch (ex) {
                showStatusMessage(ex.message, "error");
                HideLoader();
                $exceptionHandler(ex);
            }
        };

        // $scope.resetDocumentListAction = function () {
        //     //$scope.resetDocument();
        //     $scope.allegation.DocumentUploadList = [];
        // };
        // $scope.AddNewDocument = function (files) {
        //     if (!$scope.allegation.DocumentUploadList)
        //         $scope.allegation.DocumentUploadList = [];
        //     var individualDocumentUpload = $scope.individualDocumentUpload;
        //     if (files && files.length) {
        //         UploadDataUrl.base64DataUrl(files).then(function (response) {
        //             angular.forEach(response.file, function (currentDocFile) {
        //                 var documentToUpload = {
        //                     IndividualDocumentId: 0,
        //                     DocumentLkToPageTabSectionCode: individualDocumentUpload.DocumentLkToPageTabSectionCode,
        //                     DocumentLkToPageTabSectionId: individualDocumentUpload.DocumentLkToPageTabSectionId,
        //                     DocumentPath: currentDocFile.name,
        //                     DocName: currentDocFile.name,
        //                     DocStrBase64: currentDocFile.base64,
        //                     IsDocumentUploadedbyIndividual: (!$scope.isBackOfficeUser),
        //                     IsDocumentUploadedbyStaff: $scope.isBackOfficeUser,
        //                     ReferenceNumber: (!!$scope.referenceNumber ? $scope.referenceNumber : ""),
        //                     DocumentId: (!!individualDocumentUpload.DocId) ? individualDocumentUpload.DocId : 19,
        //                     DocumentCd: (!!individualDocumentUpload.DocCode) ? individualDocumentUpload.DocCode : "D1019",
        //                     DocumentUploadedForId: individualDocumentUpload.DocumentUploadedForId
        //                 };
        //                 $scope.processDocumentFields(documentToUpload);
        //                 $scope.individualDocumentUpload.DocumentUploadList.push(documentToUpload);
        //             });
        //             $scope.processValidation(true);
        //         })
        //     } else {
        //         $scope.processValidation(true);
        //     }
        //     //$scope.resetDocument();
        // };
    }
})();