(function() {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('employmentHistoryLogic', [EmploymentHistoryLogic]);

    function EmploymentHistoryLogic() {
        return {
            restrict: 'EA',
            link: function($scope, element, attrs) {

            }
        };
    }
})();