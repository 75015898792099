(function () {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeProviderWarningController", BackofficeProviderWarningController);

        BackofficeProviderWarningController.$inject = ["$rootScope", "$scope", "$state", "providerWarningService", "utilityService", "typeValuesService", "$exceptionHandler", "WebApiUrl", "countryStateService", "ProviderMultipleFileUploadService", "$q", "ResponsibleInChargeService", 'Messages'];

    function BackofficeProviderWarningController($rootScope, $scope, $state, providerWarningService, utilityService, typeValuesService, $exceptionHandler, WebApiUrl, countryStateService, ProviderMultipleFileUploadService, $q, ResponsibleInChargeService, Messages) {


        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, ProviderId) {
            if (ProviderId && $rootScope.individualFirmCurrentTab.name == "firminformation") {
                $scope.ProviderId = ProviderId;
                $scope.CountryId = $rootScope.DefaultCountryId;
                GetStateList($scope.CountryId, true, true);
                $scope.ProviderOwnershipNew = {
                    IsActive: true,
                    IsDeleted: false,
                    ProviderId: $scope.ProviderId
                };
                $scope.ownershipData = {};
                $scope.UILogic = {};
                $scope.ProviderOwnership = {};
                getMemberDetails(true);
                getProviderOwnershipNew(true);
                getRefTablesGetAll();

                getProviderOwnership();
                getStateByCountryID($rootScope.DefaultCountryId);
                initDocument();
            }
        });
        $scope.init();

        $scope.init = function () {
            var dataPromises = [];
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=licensedisciplinetype")));
            // dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=validlocation")));
            dataPromises.push(providerWarningService.getwarning(sessionStorage.Key, $scope.entityId, $scope.entityType, null));
            
            $scope.warning = null;

            $q.all(dataPromises)
                .then(function (response) {
                    angular.forEach(response, function (responseItem, itemIndex) {
                        if (responseItem.Status) {
                            if (itemIndex == 0) {
                                $scope.warningTypes = responseItem.ReferenceEntities;
                                // } else if (itemIndex == 1) {
                                //     $scope.validlocations = responseItem.ReferenceEntities;
                            } else if (itemIndex == 1) {
                                $scope.warnings = processWarningData(responseItem.DisciplineList);
                            }
                        } else {
                            $scope.showStatusMsg('-', responseItem.Message);
                        }
                    });
                    $scope.loadingWarning(false);
                }, function (data) {
                    $scope.loadingWarning(false);
                    $scope.showStatusMsg('-', data.message);
                });
        }

        $scope.closeAddEditWarning = function () {
            $scope.viewConfig.showAddBlock = false;
        };

        $scope.openAddEditWarning = function (isEdit, warning) {
            $scope.loadingWarning(true, "Please wait");
            $scope.viewConfig.isWarningEdit = isEdit;
            $scope.viewConfig.showAddBlock = true;
            if (!$scope.viewConfig.isWarningEdit) {
                $scope.warning = {
                    EntityId: $scope.entityId,
                    EntityTy: $scope.entityType,
                    IsActive: true,
                    BeginDate: new Date(),
                    LicenseNumber: null
                };
            } else {
                $scope.warning = angular.copy(warning);
            }
            $scope.loadingWarning(false);
        };

        var processWarningData = function (warningData) {
            angular.forEach(warningData, function (warning) {
                angular.forEach($scope.warningTypes, function (warningType) {
                    if (warningType.LicenseDisciplineTypeId == warning.DisciplineTypeId) {
                        warning.LicenseDisciplineTypeName = warningType.LicenseDisciplineTypeName;
                    }
                });
                angular.forEach($scope.validlocations, function (validlocation) {
                    if (validlocation.ValidlocationId == warning.ValidlocationId) {
                        warning.ValidLocationName = validlocation.ValidLocationName;
                    }
                });
            });
            return warningData;
        };

        $scope.documentConfig = {
            DocId: 8,
            LinkType: 'OI',
            PropertyToProcess: 'EntityOwnershipId'
        };

        var initDocument = function () {
            $scope.documentUpload = undefined;
            setTimeout(function () {
                $scope.$apply(function () {
                    $scope.documentUpload = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                        // ApplicationId: _applicationId,
                        ProviderId: $scope.ProviderId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FRM",
                        LoadByDocIdAndDocCode: true,
                        DocId: 8,
                        DocCode: "D1008",
                        LoadDocumentsWithoutBroadCast: true,
                        //PDocumentLkType: 'OI'
                        LinkType: 'OI',
                        PropertyToProcess: 'EntityOwnershipId'
                    };
                    $scope.IfIsUSCitizenDocumentUpload = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                        ApplicationId: $scope.applicationId,
                        ProviderId: $scope.ProviderId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FRM",
                        LoadByDocIdAndDocCode: true,
                        DocId: 82,
                        DocCode: "D1082",
                        LoadDocumentsWithoutBroadCast: true
                    };
                    $scope.IfIsNotAUSCitizenDocumentUpload = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                        ApplicationId: $scope.applicationId,
                        ProviderId: $scope.ProviderId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FRM",
                        LoadByDocIdAndDocCode: true,
                        DocId: 83,
                        DocCode: "D1083",
                        LoadDocumentsWithoutBroadCast: true
                    };
                });
            }, 1000);
        };

        var getMemberDetails = function (isInitLoad) {
            providerOwnershipService.getProviderOwnership(sessionStorage.Key, $scope.ProviderId).then(function (res) {
                if (res.Status) {
                    $scope.ProviderOwnershipList = res.ProviderOwnershipList;
                    if (!isInitLoad)
                        HideLoader();
                }
            })
        };

        var GetStateList = function (cid, l1, l2) {
            var deferred = $q.defer();
            try {
                countryStateService.getStateByCountryID(sessionStorage.Key, cid).then(function (res) {
                    if (res.Status) {
                        if (l1) {
                            $scope.StateList = res.State;
                        }
                        if (l2) {
                            $scope.StateList2 = res.State;
                        }
                    } else {
                        if (l1) {
                            $scope.StateList = [];
                        }
                        if (l2) {
                            $scope.StateList2 = [];
                        }
                    }
                    deferred.resolve(res);
                }, function (err) {
                    console.log('response', err);
                    deferred.reject(err);
                });
            }
            catch (ex) {
                console.log('Exception GetStateList()', ex);
                deferred.reject(ex);
            }
            return deferred.promise;
        };

        $scope.CountryChanged = function () {
            $scope.ProviderAddress.StateCode = '';
            $scope.StateList2 = [];
            if ($scope.ProviderAddress.CountryId) {
                GetStateList($scope.ProviderAddress.CountryId, false, true);
            }
        };

        var getRefTablesGetAll = function () {
            var dataPromise = [];
            dataPromise.push(ResponsibleInChargeService.getRefTablesGetAll(sessionStorage.Key, 'ownershiptype'));
            // dataPromise.push(BusinessEntityService.getRefTablesGetAll(sessionStorage.Key, 'licenseapplicationfor'));

            $q.all(dataPromise)
                .then(function (res) {
                    var status = true;
                    _.each(res, function (response) {
                        if (!response.Status) {
                            status = false;
                        }
                    })
                    if (status) {
                        $scope.ownershipTypeList = res[0].ReferenceEntities;
                        //$scope.initialLicenseApplicationSubTypes = res[1].ReferenceEntities;
                    }
                }, function (error) {
                    console.log('Error');
                });
        };

        var validateMemeberForm = function (form) {
            $scope.natValidation.beOwnership.errorMessages = [];
            if (!!form && $scope.natValidation.beOwnership) {
                if ($scope.natValidation.beOwnership.errorMessages == undefined) {
                    $scope.natValidation.beOwnership.errorMessages = [];
                }
                form.$submitted = true;
                var errorMessages = angular.copy(form.errorMessages);
                angular.forEach(errorMessages, function (errorMessage, index) {
                    $scope.natValidation.beOwnership.errorMessages.push(errorMessage);
                });
            }

            if (!!$scope.natValidation.beOwnership.errorMessages && $scope.natValidation.beOwnership.errorMessages.length > 0) {
                $scope.natValidation.beOwnership.showError = true;
                $scope.natValidation.beOwnership.isValid = false;
                return false;
            } else {
                $scope.natValidation.beOwnership.showError = false;
                $scope.natValidation.beOwnership.isValid = true;
                return true;
            }
        };

        $scope.resetMemberDetails = function () {
            $scope.UILogic.ResponsibleChargeDV = false;
            $scope.UILogic.AddResponsibleChargebutton = false;
            $scope.ProviderOwnership = {};
        };

        $scope.editMemberDetails = function (item) {
            $scope.ProviderOwnership = angular.copy(item);
            $scope.UILogic.ResponsibleChargeDV = true;
            $scope.UILogic.AddResponsibleChargebutton = true;
            $scope.isEdit = true;
        }

        var getProviderOwnershipNew = function (isInitLoad, ProviderOwnershipId) {
            providerOwnershipService.getProviderOwnershipNew(sessionStorage.Key, ProviderOwnershipId, $scope.ProviderId).then(function (res) {
                if (res.Status) {
                    $scope.ProviderOwnershipNew = res.EntityRecords[0];
                    if (!$scope.ProviderOwnershipNew || $scope.ProviderOwnershipNew.length == 0)
                        $scope.ProviderOwnershipNew = {
                            IsActive: true,
                            IsDeleted: false,
                            ProviderId: $scope.ProviderId
                        };
                    if (!isInitLoad)
                        HideLoader();
                }
            })
        };

        var validateManagerForm = function (form) {
            //validate 
            //Form custom
            $scope.natValidation.beResponsible.errorMessages = [];
            if (!!form && $scope.natValidation) {
                if ($scope.natValidation.beResponsible.errorMessages == undefined) {
                    $scope.natValidation.beResponsible.errorMessages = [];
                }
                form.$submitted = true;
                var errorMessages = angular.copy(form.errorMessages);
                angular.forEach(errorMessages, function (errorMessage, index) {
                    $scope.natValidation.beResponsible.errorMessages.push(errorMessage);
                });
            }

            if (!!$scope.natValidation.beResponsible.errorMessages && $scope.natValidation.beResponsible.errorMessages.length > 0) {
                $scope.natValidation.beResponsible.showError = true;
                $scope.natValidation.beResponsible.isValid = false;
                return false;
            } else {
                $scope.natValidation.beResponsible.showError = false;
                $scope.natValidation.beResponsible.isValid = true;
                return true;
            }
        };

        $scope.saveProviderOwnershipNew = function (form) {
            if (!validateManagerForm()) {
                HideLoader();
                utilityService.scrollTop();
                return false;
            }
            else {
                var deferred = $q.defer();
                providerOwnershipService.saveProviderOwnershipNew(sessionStorage.Key, [$scope.ProviderOwnershipNew]).then(function (res) {
                    if (res.data.Status) {
                        $scope.ProviderOwnershipNew = res.data.EntityRecords;
                        if (!!$scope.ProviderOwnershipNew && $scope.ProviderOwnershipNew.length > 0)
                            getProviderOwnershipNew(false, $scope.ProviderOwnershipNew.ProviderOwnershipId);
                        deferred.resolve(res);
                    }
                    else {
                        deferred.resolve({});
                        HideLoader();
                    }
                }, function () {
                    deferred.reject('');
                    HideLoader();
                });
                return deferred.promise;
            }
        };

        $scope.DownloadDocumentAPIURL = WebApiUrl + 'Individual/DownloadPDF/' + sessionStorage.Key + '?path=';
        $scope.DownloadDocument = function (path) {
            window.open($scope.DownloadDocumentAPIURL + path, '_blank');
        };

        $scope.setSaveDocumentRef = function (fn) {
            $scope.saveAllDocument = fn;
        };

        var saveProviderOwnership = function () {
            providerOwnershipService.saveProviderOwnership(sessionStorage.Key, [$scope.ProviderOwnership]).then(function (res) {
                if (res.Status) {
                    $scope.ProviderOwnershipList = res.ProviderOwnershipList;
                    getMemberDetails(false);
                    $scope.resetMemberDetails();
                }
                else {
                    HideLoader();
                }
            }, function () {
                HideLoader();
            })
        };

        $scope.saveMemberDetails = function (form) {
            if (validateMemeberForm(form)) {
                ShowLoader();

                if (!$scope.isEdit || !$scope.ProviderOwnership) {
                    // $scope.ProviderOwnership = {};
                    $scope.ProviderOwnership.ProviderId = $scope.ProviderId;
                    // $scope.ProviderOwnership.ApplicationId = $scope.applicationId;
                    // $scope.ProviderOwnership.OwnershipTypeId = 2;
                    $scope.ProviderOwnership.IsActive = true;
                }
                saveProviderOwnership();
                $scope.isEdit = false;
            }
        };

        $scope.netValidator = {};
        $scope.saveProviderOwnership = function (form) {
            if (validateForm(form) && $scope.ownershipData) {
                ShowLoader();
                if (!$scope.ownershipData.EntityOwnershipId) {
                    $scope.ownershipData.EntityId = $scope.ProviderId;
                    $scope.ownershipData.IsActive = true;
                }
                if (!$scope.ownershipData.ReferenceNumber) {
                    $scope.ownershipData.ReferenceNumber = new Date().valueOf();
                }

                $scope.ownershipData.LicensedIssuedStateId = getStateIdByCd($scope.ownershipData.getStateIdByCd);
                providerOwnershipService.saveEntityOwnership(sessionStorage.Key, [$scope.ownershipData]).then(function (res) {
                    if (res.Status && res.EntityOwnershipList && res.EntityOwnershipList.length > 0) {
                        $scope.documentUpload.LinkId = res.EntityOwnershipList[0].EntityOwnershipId;
                        if (typeof $scope.saveAllDocument == "function") {
                            $scope.saveAllDocument().then(function () {
                                getProviderOwnership();
                                $scope.cancelBtnClick();
                            }, function () { });
                        } else {
                            getProviderOwnership();
                            $scope.cancelBtnClick();
                        }
                    } else {
                        HideLoader();
                    }
                }, function (err) {
                    console.log(err);
                    HideLoader();
                });
            }
        };

        var getStateIdByCd = function (cd) {
            try {
                if ($scope.statesData) {
                    var state = $.grep($scope.statesData, function (i) {
                        return (i.StateCode == cd);
                    })
                    if (state && state.length > 0) {
                        return state[0].StateId;
                    }
                }
            } catch (ee) { console.log(ee); }
            return '';
        };


        var getProviderOwnership = function () {
            try {
                ShowLoader();
                providerOwnershipService.getEntityOwnership(sessionStorage.Key, $scope.ProviderId).then(function (res) {
                    if (res.Status) {
                        $scope.providerOwnershipList = res.EntityOwnershipList;
                        processDocument();
                    }
                    HideLoader();
                }, function (err) {
                    console.log(err);
                    HideLoader();
                });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        var processDocument = function () {
            if ($scope.providerOwnershipList && $scope.providerOwnershipList.length > 0) {
                ProviderMultipleFileUploadService.providerDocumentProcess(sessionStorage.Key, $scope.ProviderId, $scope.documentConfig.DocId,
                    $scope.providerOwnershipList, $scope.documentConfig.PropertyToProcess, $scope.documentConfig.LinkType).then(function (responseData) {
                        $scope.providerOwnershipList = responseData;
                    });
            }
        };

        var getStateByCountryID = function (countryId) {
            $scope.statesData = [];
            try {
                countryStateService.getStateByCountryID(sessionStorage.Key, countryId)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.statesData = response.State;
                        }
                    }, function (data) {
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };



        $scope.addNewBtnClick = function () {
            $scope.showOwnershipForm = true;
            $scope.ownershipData = {};
            $scope.ownershipData.ReferenceNumber = new Date().valueOf();
            initDocument();
        };

        $scope.cancelBtnClick = function () {
            $scope.ownershipData = {};
            $scope.showOwnershipForm = false;
        };

        $scope.editButtonClick = function (i) {
            $scope.showOwnershipForm = true;
            $scope.ownershipData = angular.copy(i);
            if (!$scope.ownershipData.ReferenceNumber) {
                $scope.ownershipData.ReferenceNumber = new Date().valueOf();
            }
            initDocument();
        };

        $scope.countyLoaded = function (countyList) {
            $scope.countyList = countyList;
        };

        $scope.deleteButtonClick = function (i) {
            try {

                if (i && confirm(Messages.bbabace)) {
                    ShowLoader();
                    i.IsActive = false;
                    i.IsDeleted = true;
                    providerOwnershipService.saveEntityOwnership(sessionStorage.Key, [i]).then(function (res) {
                        if (res.Status) {
                            getProviderOwnership();
                        } else {
                            HideLoader();
                        }
                    }, function (err) {
                        console.log(err);
                        HideLoader();
                    });
                }
            } catch (ex) { }
        };


        //This function validates the for on click of submit button
        var validateForm = function (form, error) {
            $scope.netValidator.errorMessages = [];
            if (!!form) {
                form.$submitted = true;
                var errorMessages = angular.copy(form.errorMessages);
                angular.forEach(errorMessages, function (errorMessage, index) {
                    $scope.netValidator.errorMessages.push(errorMessage);
                });
            }
            if (!!error) {
                $scope.netValidator.errorMessages.push(error);
            }
            if (!!$scope.netValidator.errorMessages && $scope.netValidator.errorMessages.length > 0) {
                $scope.netValidator.showError = true;
                $scope.netValidator.isValid = false;
                return false;
            } else {
                $scope.netValidator.showError = false;
                $scope.netValidator.isValid = true;
                return true;
            }
        };
    }
})();