(function () {
    'use strict'

    angular.module('app.backofficeEntity')
        .directive('shareholderPartner', shareholderPartner);

    shareholderPartner.$inject = [];

    function shareholderPartner() {
        return {
            restrict: 'E',
            scope: {
                entityId: '=',
                tab: "=?",
                entityType: "=",
                boardInfoId: "=?",
                configName: "=",
                licenseNumber: "=?",
                applicationId: '=',
                applicationTypeId: '=?',
                individualLicense: '=?',
                applicationInfo: '=?',
                showHeaderPanel: '=?',
                showSubmitPanel: '=?',
                isBackoffice: '=?',
                natValidation: '=',
                isTabStructure: '=?',
                openNextTab: '&',
                providerAdditionalInfo: '='
            },
            templateUrl: 'app/components/backoffice/entity/shareholder-partner/shareholder-partner.html',
            controller: 'ShareholderPartnerController'
        };
    }
})();