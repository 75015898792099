(function() {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('initialApplicationEmploymentService', initialApplicationEmploymentService)

    initialApplicationEmploymentService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function initialApplicationEmploymentService($http, $q, WebApiUrl, AppConfig) {

        var _individualRenewalGetByApplicationId = function(key, IndividualId, ApplicationId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Renewal/IndividualRenewalGetByApplicationId/' + key + '?IndividualId=' + IndividualId + '&ApplicationId=' + ApplicationId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _getStateByCountryID = function(Key, ID) {

            var deferred = $q.defer();
            $http.get(WebApiUrl + 'State/StateGetByCountryID/' + Key + '?CountryID=' + ID)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getEmploymentInfo = function(individualId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualEmploymentByIndividualId/' + key + '?IndividualId=' + individualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        var _employmentSave = function(employObj, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualEmploymentSave/' + key + '?Source=' + AppConfig.Source, employObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        var _getEmployerList = function(urlparam) {
            return $http.get(WebApiUrl + 'Individual/GetEmployerNameAndAddress' + urlparam);
        };

        var _assistantSave = function(assistantObj, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualSupervisoryInfoSave/' + key, assistantObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        var _IndividualNameSave = function(reqData, urlParam) {
            return $http.post(WebApiUrl + 'Individual/IndividualNameSave' + urlParam, reqData);
        };

        var _SaveIndividualAddress = function(reqData, urlParam) {
            return $http.post(WebApiUrl + 'Individual/IndividualAddressSave' + urlParam, reqData);
        };

        return {
            individualRenewalGetByApplicationId: _individualRenewalGetByApplicationId,
            getStateByCountryID: _getStateByCountryID,
            getEmploymentInfo: _getEmploymentInfo,
            employmentSave: _employmentSave,
            getEmployerList: _getEmployerList,
            assistantSave: _assistantSave,
            individualNameSave: _IndividualNameSave,
            saveIndividualAddress: _SaveIndividualAddress
        };
    }
})();
