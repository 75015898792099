(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('miscDataLogic', ['MiscDataService', '$q', MiscDataLogic]);

    function MiscDataLogic(MiscDataService, $q) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                var getIndividualOther = function () {
                    var deferred = $q.defer();
                    
                    try {
                        MiscDataService.getIndividualOther(sessionStorage.Key, $scope.individualId)
                        .then(function(data) {
                            if (data.Status) {
                                $scope.current.individualOther = data.IndividualResponse.firstOrDefault();
                            } else {
                                $scope.showStatusMsg('-', 'No individual data available.');
                            }
                            deferred.resolve(data);
                        }, function(err) {
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                var saveIndividualOther = function () {
                    var deferred = $q.defer();
                    
                    try {
                        MiscDataService.saveIndividualOther(sessionStorage.Key, [$scope.current.individualOther])
                        .then(function(data) {
                            deferred.resolve(data);
                        }, function(err) {
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                if ($scope.configName == 'licenseData') {
                    $scope.$onConfigLoaded = function () {
                        $scope.datePicker = {
                            fromDateOpened: false,
                            toDateOpened: false,
                            format: 'M!/d!/yyyy',
                            options: {
                                
                            }
                        };

                        getIndividualOther()
                        .then(function () {
                            $scope.operationPending(false);
                        });  
                    };

                    $scope.saveAndNext = function () {
                        $scope.operationPending(true, 'Saving...');
                        saveIndividualOther()
                        .then(function () {
                            $scope.operationPending(true, 'Loading...');
                            getIndividualOther()
                            .then(function () {
                                $scope.operationPending(false);
                            }, function () {
                                $scope.operationPending(false);
                            });
                        }, function () {
                            $scope.operationPending(false);
                        });
                    };
                }
            },
        };
    }
})();