﻿(function () {
    'use strict'
    angular.module('app.core').factory('providerContactService', ['$http', 'WebApiUrl', function ($http, WebApiUrl) {
        var BaseUrl = WebApiUrl;
        var BaseFactory = {};
        BaseFactory.GetContactDetails = function (key, providerId) {
            return $http.get(BaseUrl + 'Provider/ProviderContactByProviderId/' + key + '?providerId=' + providerId);
        };
        BaseFactory.SaveContactDetails = function (urlParam, data) {
            var ApiPath = 'Provider/ProviderContactsSave' + urlParam;
            return $http.post(BaseUrl + ApiPath, data);
        };
        BaseFactory.ContactTypeGetAll = function (key) {
            return $http.get(BaseUrl + 'TypeValues/ContactTypeGetAll/' + key);
        };
        return BaseFactory;
    }]);
})();