

(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeIndividualCertificationService', backofficeIndividualCertificationService)

    backofficeIndividualCertificationService.$inject = ['$http', '$q', 'WebApiUrl']

    function backofficeIndividualCertificationService($http, $q, WebApiUrl) {

        var _getIndividualBoardCertification = function (key, individualId) { return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualBoardCertification/' + key + '?individualId=' + individualId + '&ApplicationId=' + null + '&IndividualLicenseId=' + null)) };
        var _getIndividualCEAudit = function (key, individualId) { return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualCEAudit/' + key + '?individualId=' + individualId + '&ApplicationId=' + null + '&IndividualLicenseId=' + null)) };
        var _getIndividualCECourseByIndividualIdn = function (key, individualId) { return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualCECourseByIndividualId/' + key + '?individualId=' + individualId + '&ApplicationId=' + null + '&IndividualLicenseId=' + null)) };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        var _RefAllegationDescription = function (individualId, key) {
            return returnDefferedResult($http.get(WebApiUrl + 'typevalues/refallegationdescriptiontypegetall/' + key + '?source=' + individualId));
        }
        var _RefAllegationStatus = function (individualId, key, AllegationTypeId) {
            return returnDefferedResult($http.get(WebApiUrl + 'TypeValues/RefAllegationStatusGetAll/' + key + '?AllegationTypeId=' + AllegationTypeId + '&Source=NVDENTAL'));
        }


        var _saveAllegation = function (lstIndividualAllegation, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/SaveIndividualAllegation/' + key + '?Source=NVDENTAL', lstIndividualAllegation));
        }
        var _saveAllegationDoc = function (lstIndividualAllegation, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/SaveIndividualAllegationAndDocs/' + key + '?Source=NVDENTAL', lstIndividualAllegation));
        }

        var _saveIndividualdocumentlk = function (doc, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/SaveIndividualdocumentlk/' + key + '?Source=NVDENTAL', doc));
        }
        var _getIndividualDocumentlk = function (individualId, LinkId, key, LinkType) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualDocumentlk/' + key + '?IndividualId=' + individualId + '&ApplicationId=' + null + '&LinkId=' + LinkId + '&LinkType=' + LinkType));
        }

        var _saveIndividualBoardCertification = function (doc, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/SaveIndividualBoardCertification/' + key + '?Source=NVDENTAL', doc));
        }
        var _saveIndividualCEAudit = function (doc, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/SaveIndividualCEAudit/' + key + '?Source=NVDENTAL', doc));
        }
        var _saveIndividualCECourseSave = function (doc, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualCECourseSave/' + key + '?Source=NVDENTAL', doc));
        }
        var _typeValues = function (key, tableKey) {
            return returnDefferedResult($http.get(WebApiUrl + 'TypeValues/RefTablesGetAll/' + key + '?tableKey=' + tableKey + '&pullInActive=' + true));
        }

        return {
            getIndividualBoardCertification: _getIndividualBoardCertification,
            getIndividualCEAudit: _getIndividualCEAudit,
            getIndividualCECourseByIndividualIdn: _getIndividualCECourseByIndividualIdn,
            refAllegationDescription: _RefAllegationDescription,
            saveIndividualBoardCertification: _saveIndividualBoardCertification,
            saveIndividualCEAudit: _saveIndividualCEAudit,
            saveIndividualCECourseSave: _saveIndividualCECourseSave,
            typeValues: _typeValues,
            refAllegationStatus: _RefAllegationStatus,
            saveAllegation: _saveAllegation,
            saveIndividualdocumentlk: _saveIndividualdocumentlk,
            getIndividualDocumentlk: _getIndividualDocumentlk,
            saveAllegationDoc: _saveAllegationDoc
        }
    }
})();