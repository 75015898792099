(function () {
    'use strict';
    angular.module('natApp').config(exceptionConfig);

    exceptionConfig.$inject = ['$provide', 'WebApiUrl', '$httpProvider', '$locationProvider'];

    function exceptionConfig($provide, WebApiUrl, $httpProvider, $locationProvider) {

        $locationProvider.hashPrefix('');

        $provide.decorator('$exceptionHandler', ['$delegate', function ($delegate) {
            return function (exception, cause) {
                var clientException = {
                    Message: exception.message,
                    Stack: exception.stack,
                    Title: exception.name,
                    UrlReferrer: window.location.href,
                    IndividualId: sessionStorage.logCurrentIndividualId
                };
                $.ajax({
                    url: WebApiUrl + 'Logging/SaveClientExceptionInfo?key=' + sessionStorage.Key,
                    type: "POST",
                    contentType: "application/json",
                    data: JSON.stringify(clientException),
                    dataType: 'json',
                    success: function (result) {
                        console.log('Exception Logged Successfulluy on server: ', result);
                    }
                });
                $delegate(exception, cause);
            };
        }]);
        $httpProvider.interceptors.push('natHttpInterceptor');
        $httpProvider.interceptors.push('httpResponseInterceptor');
    };


    angular.module('natApp').service('natHttpInterceptor', [function () {
        var service = this;

        service.request = function (config) {
            config.headers.dataType = 'json';
            return config;
        };
    }]);

    angular.module('natApp').factory('httpResponseInterceptor', ['$window', '$injector', function ($window, $injector) {
        var popupOpened = false;
        var openConfirmationPopup = function () {
            if (!popupOpened) {
                popupOpened = true;
                var modalInstance = $injector.get('$uibModal').open({
                    animation: true,
                    templateUrl: 'app/app.session-expired-confirmation.html',
                    size: 'md',
                    controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                        $scope.onConfirm = function () {
                            $uibModalInstance.close('success');
                        };
                    }]
                });

                modalInstance.result.then(function () {
                    var urlPath = $window.location.hash;
                    if (urlPath.includes("Backoffice") || urlPath.includes("Admin") || urlPath.includes("Report") || urlPath.includes("Enforcement")) {
                        $injector.get('$state').transitionTo('app.BackofficeLoginstaff');
                        popupOpened = false;
                        HideLoader();
                    } else {
                        $injector.get('$state').transitionTo('app.Loginwemail');
                        popupOpened = false;
                        HideLoader();
                    }
                });
            }

        };
        return {
            response: function (response) {
                if (response.headers()['content-type'] === "application/json; charset=utf-8") {
                    if (response.data.StatusCode == "03") {
                        ShowLoader();
                        console.log('User Session Expired: ', response);
                        openConfirmationPopup();
                    }
                }
                return response;
            }
        };
    }]);
})();
