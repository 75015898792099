﻿(function () {
    'use strict'
    angular.module('natApp')
        .controller('BEDocumentsController', BEDocumentsController);

    BEDocumentsController.$inject = ['$scope', '$q', 'WebApiUrl', 'BusinessEntitytatusService'];
    function BEDocumentsController($scope, $q, WebApiUrl, BusinessEntitytatusService) {
        $scope.documentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
            ApplicationId: $scope.applicationId,
            ProviderId: $scope.providerid,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "BE",
            LoadByDocIdAndDocCode: true,
            DocId: 5,
            DocCode: "D1005",
            LoadDocumentsWithoutBroadCast: true
        };
        $scope.documentUploadBusinessEntities = {
            ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
            ApplicationId: $scope.applicationId,
            ProviderId: $scope.providerid,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "BE",
            LoadByDocIdAndDocCode: true,
            DocId: 21,
            DocCode: "D1021",
            LoadDocumentsWithoutBroadCast: true
        };

        $scope.$on($scope.eventname, function (event, args) {
            if ((!$scope.documentUploadBusinessEntities.documentCount || $scope.documentUploadBusinessEntities.documentCount < 1 || !$scope.documentUpload.documentCount || $scope.documentUpload.documentCount < 1)) {
                $scope.showError = true;
                BusinessEntitytatusService.setBEDocument("error");
            }
            else {
                $scope.showError = false;
                BusinessEntitytatusService.setBEDocument("success");
            }

        });
    };
})();