﻿(function () {
    'use strict'
    angular.module('app.login')
        .controller("LoginAgencyController", ["$scope", "$state", "LoginwemailService", "Messages",
    function ($scope, $state, LoginwemailService, Messages) {
        $scope.netValidator = {};
        sessionStorage.LoginPage = $state.$current.self.name;//'app.LoginBusinessPortal';
        $scope.login = function (form) {
            if (!validateForm(form)) {
                return;
            }
            ShowLoader("Please wait...");
            var Email = $scope.Email;
            var Password = $scope.Password;
            var LoginWithoutEmail = false;
            LoginwemailService.Login(Email, Password, LoginWithoutEmail,'provider')
                .then(function (res) {
                    var response = res.data;
                    if ($scope.checkServerResponse(response)) {
                        UserRoleGetBYUserID(response);
                    } else {
                        var error = { propName: "loginerror", errorMessage: response.Message };
                        validateForm(null, error);
                        $scope.Password = "";
                        HideLoader();
                    }
                }, function (res) {
                    HideLoader();
                    var data = res.data;
                    if (data != null) {
                        var error = { propName: "loginerror", errorMessage: response.Message };
                        validateForm(null, error);
                        $scope.Password = "";
                    }
                });
        };

        var UserRoleGetBYUserID = function (data) {
            var userData = data;
            var FormatedUserData = {
                Key: userData.Key,
                IndividualID: userData.IndividualID,
                Email: userData.UserInfo.Email,
                FirstName: userData.UserInfo.FirstName,
                IndividualId: userData.UserInfo.IndividualId,
                LastName: userData.UserInfo.LastName,
                TemporaryPassword: userData.UserInfo.TemporaryPassword,
                UserID: userData.UserInfo.UserID,
                UserName: userData.UserInfo.UserName,
                UserTypeID: userData.UserInfo.UserTypeID,
                UserTypeName: userData.UserInfo.UserTypeName,
                ProviderId: userData.UserInfo.ProviderId
            };
            var urlParm = "?Key=" + userData.Key + "&UserId=" + userData.UserID;
            LoginwemailService.UserRoleGetBYUserID(urlParm).then(function (res) {
                HideLoader();
                if (res.data.Status) {
                    if (res.data.UserRole && res.data.UserRole.length > 0) {
                        var UserRole = res.data.UserRole[0];
                        SetSessionStorageUserRoleData(UserRole);
                    }
                    var userTypeID = userData.UserInfo.UserTypeID;
                    var userStatusId = userData.UserInfo.UserStatusId;
                    if (userTypeID == 7) {
                        if (userStatusId == 6 || userStatusId == 1) {
                            SetSessionStorageUserData(FormatedUserData);
                            if (userData.UserInfo.TemporaryPassword) {
                                sessionStorage.LoginPage = $state.$current.self.name;
                                $state.go('app.ChangePassword');
                            } else {
                                //$state.go('app.providerSignIn');
                                $state.go('app.cedashboard');
                            }
                        } else {
                            var error = { propName: "loginerror", errorMessage: Messages.General.Errors.NotAuthorizedPortal };
                            $scope.Password = "";
                            validateForm(null, error);
                        }
                    } else {
                        var error = { propName: "loginerror", errorMessage: Messages.General.Errors.Credentials}
                        validateForm(null, error);
                    }
                } else {
                    HideLoader();
                    var error = { propName: "loginerror", errorMessage: res.data.Message };
                    validateForm(null, error);
                    $scope.Password = "";
                    //$scope.showStatusMessage(res.data.Message, 'error');
                }
            }, function (res) {
                HideLoader();
                var error = { propName: "loginerror", errorMessage: Messages.General.Errors.GetUserRoles };
                validateForm(null, error);
                $scope.Password = "";
                //$scope.showStatusMessage(Messages.ffaaddd, 'error');
            })
        };
        var AuditvisitInfoSave = function () {
            var parm = 'key';
            var data = {
                HostIPAddress: location.host,
                PageName: 'LoginBusinessPortal',
                RequestUrl: window.location.href,
                RequestUrlReferrer: document.referrer,
                RequestBrowserTypeVersion: 'Type: ' + navigator.appCodeName + ', Version: ' + navigator.appVersion,
                SessionID: '', //document.cookie,
                TimeStamp: new Date(),
                IndividualId: 0,
                EntityId: '',
                UserId: 0,
                Platform: navigator.platform,
                MachineDeviceName: '',
                DeviceId: '',
                AppDomainName: '',
                UserHostAddress: '',
                UserHostName: '',
                IsJavascriptEnabled: true,
                IsCookieEnabled: navigator.cookieEnabled,
                IsCrawler: '',
                IsActiveXControlEnabled: ''
            };
            LoginwemailService.AuditvisitInfoSave(parm, data).then(function (res) {
                /// auditSaved
            }, function (res) {

            });
        };
        var SetSessionStorageUserData = function (data) {
            sessionStorage.IndividualId = data.IndividualId;
            sessionStorage.Email = data.Email;
            sessionStorage.FirstName = data.FirstName;
            sessionStorage.IndividualId = data.IndividualId;
            sessionStorage.LastName = data.LastName;
            sessionStorage.TemporaryPassword = data.TemporaryPassword;
            sessionStorage.UserId = data.UserID;
            sessionStorage.UserID = data.UserID;
            sessionStorage.UserName = data.UserName;
            sessionStorage.UserTypeID = data.UserTypeID;
            sessionStorage.UserTypeName = data.UserTypeName;
            sessionStorage.Key = data.Key;
            sessionStorage.ProviderId = data.ProviderId;
        };
        var SetSessionStorageUserRoleData = function (data) {
            sessionStorage.Grantable = data.Grantable;
            sessionStorage.Role = data.Role;
            sessionStorage.RoleID = data.RoleID;
            sessionStorage.Selected = data.Selected;
            sessionStorage.UserRoleID = data.UserRoleID;
        };

        $scope.GotoforgetPassword = function () {
            //$state.go('app.AgencyForgotPassword');
        };

        $scope.GotoforgetPassword = function () {
            $state.go('app.ForgotPassword');
        };

        //This function validates the for on click of submit button
        var validateForm = function (form, error) {
            $scope.netValidator.errorMessages = [];
            if (!!form) {
                form.$submitted = true;
                var errorMessages = angular.copy(form.errorMessages);
                angular.forEach(errorMessages, function (errorMessage, index) {
                    $scope.netValidator.errorMessages.push(errorMessage);
                });
            }
            if (!!error) {
                $scope.netValidator.errorMessages.push(error);
            }
            if (!!$scope.netValidator.errorMessages && $scope.netValidator.errorMessages.length > 0) {
                $scope.netValidator.showError = true;
                $scope.netValidator.isValid = false;
                return false;
            } else {
                $scope.netValidator.showError = false;
                $scope.netValidator.isValid = true;
                return true;
            }
        };

    }]);
})();
