(function() {
    'use strict'
    angular.module('app.core')
        .directive('natNumber', natNumberDirective);

    natNumberDirective.$inject = [];

    function natNumberDirective() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {
                element.on('keydown', function(event) {
                    if (!angular.isUndefined(attrs.allowdecimal)) {
                        if (((!!ngModel && !!ngModel.$viewValue && ngModel.$viewValue.indexOf('.') == -1) || (!ngModel || !ngModel.$viewValue)) && event.which == 190 || event.which == 110) {
                            return true;
                        }
                        if (!angular.isUndefined(attrs.beforedecimalpoints)) {
                            if (!!ngModel.$viewValue) {
                                var pointsBeforeDecimal = ngModel.$viewValue.split('.')[0];
                                if (element[0].selectionStart <= 5 && (pointsBeforeDecimal.length >= attrs.beforedecimalpoints && !(event.which == 190 || event.which == 110 || event.which == 8))) {
                                    event.preventDefault();
                                }
                            }
                        }
                        if (!angular.isUndefined(attrs.afterdecimalpoints)) {
                            if (!!ngModel.$viewValue) {
                                var viewValuesAfterAndBefore = ngModel.$viewValue.split('.');
                                if (viewValuesAfterAndBefore.length >= 2) {
                                    if (element[0].selectionStart > 5 && viewValuesAfterAndBefore[1].length >= attrs.afterdecimalpoints && !(event.which == 8)) {
                                        event.preventDefault();
                                    }
                                }
                            }
                        }
                    } else {
                        if (!angular.isUndefined(attrs.beforedecimalpoints)) {
                            if (!!ngModel.$viewValue) {
                                var pointsBeforeDecimal = ngModel.$viewValue.split('.')[0];
                                if (element[0].selectionStart <= 5 && (pointsBeforeDecimal.length >= attrs.beforedecimalpoints && !(event.which == 190 || event.which == 110 || event.which == 8))) {
                                    event.preventDefault();
                                }
                            }
                        }
                    }
                    if (!angular.isUndefined(attrs.allowspace)) {
                        if (event.which == 32) {
                            return true;
                        }
                    }
                    if (!event.shiftKey || (event.shiftKey && event.which == 9)) {
                        if (!((event.which >= 48 && event.which <= 57) || (event.which >= 96 && event.which <= 105) || event.which == 8 || event.which == 46 || event.which == 13 || event.which == 9 || event.which == 27 || event.which == 17 || (event.which >= 37 && event.which <= 40) || (event.which >= 112 && event.which <= 123))) {
                            event.preventDefault();
                        }
                    } else {
                        event.preventDefault();
                    }
                })
            }
        }
    }
})();