(function() {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('initialApplicationLicenseDetailsService', initialApplicationLicenseDetailsService);

    initialApplicationLicenseDetailsService.$inject = ['$http', '$q', 'WebApiUrl'];

    function initialApplicationLicenseDetailsService($http, $q, WebApiUrl) {
        return {};
    }
})();