(function () {
    'use strict'

    angular.module('app.enforcement')
    .controller('ComplaintUpdateController', ['$scope', '$rootScope', '$q', 'ComplaintUpdateService', 'utilityService', ComplaintUpdateController]);

    function ComplaintUpdateController($scope, $rootScope, $q, ComplaintUpdateService, utilityService) {
        var _key = sessionStorage.Key;
        var _childName = '';
        
        var getConfig = function () {
            var deferred = $q.defer();
        
            try {
                utilityService.getConfigData('/components/backoffice/enforcement/complaint-update/complaint-update.server-config.json')
                .then(function(data) {
                    if (!!data) {
                        $scope.config = data;
        
                        $scope.uiConfig = $rootScope.getUIConfig($scope.configName, $scope.config);
        
                        $scope.isConfigLoaded = true;

                        if (!!$scope.getConfigExt) {
                            $scope.getConfigExt();
                        }
                    }
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
        
            return deferred.promise;
        };

        $scope.getStaffUsers = function () {
            var deferred = $q.defer();
            
            try {
                ComplaintUpdateService.getStaffUsers(sessionStorage.Key)
                .then(function(data) {
                    if (data.Status && data.UsersList) {
                        $scope.usersList = data.UsersList.orderBy('FirstName,LastName');
                    } else {
                        $scope.usersList = [];
                    }
                    $scope.usersList.forEach(function (user) {
                        user.FullName = user.FirstName + ' ' + user.LastName;
                    });
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.saveComplaint = function (complaint) {
            var deferred = $q.defer();
            
            try {
                ComplaintUpdateService.saveComplaint(sessionStorage.Key, [complaint])
                .then(function(data) {
                    if (data.Status) {
                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.saveComplaintClicked = function () {
            if ($scope.validateForm()) {
                $scope.operationPending(true, 'Saving...');
  
                $scope.saveComplaint($scope.current.complaint)
                .then(function () {
                    $scope.operationPending(false);
                    if (!!$scope.saveCompleted) {
                        $scope.saveCompleted({ $complaint: $scope.current.complaint });
                    }
                    if (!!$scope.saveCompletedExt) {
                        $scope.saveCompletedExt($scope.current.complaint);
                    }
                }, function () {
                    $scope.operationPending(false);
                });
            }
        };

        $scope.resetComplaintClicked = function () {
            $scope.current.complaint = angular.copy($scope.complaint);

            if (!!$scope.form) {
                $scope.hideStatusMsg();
                $scope.form.$setPristine();
            }
        };

        $scope.init = function () {
            $scope.uiLogic = {};
            $scope.current = {
                complaint: angular.copy($scope.complaint)
            };

            $scope.canSave = true;

            getConfig();
            $scope.getStaffUsers();

            if (!!$scope.initExt) {
                $scope.initExt();
            }
        };
    }
})();
