(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('examVerificationService', examVerificationService)

        examVerificationService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function examVerificationService($http, $q, WebApiUrl, AppConfig) {
        var _getStateByCountryID = function (countryID, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'State/StateGetByCountryID/' + key + '?CountryID=' + countryID)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveExamVerification = function (experience, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualExamSave/' + key + '?Source=' + AppConfig.Source, experience)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _getExamVerification = function (individualId, applicationId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualExamBYIndividualId/' + key + '?IndividualId=' + individualId + '&ApplicationId=' + applicationId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            getStateByCountryID: _getStateByCountryID,
            saveExamVerification: _saveExamVerification,
            getExamVerification: _getExamVerification
        };
    }
})();
