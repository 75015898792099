﻿(function () {
    'use strict'
    angular.module('app.backofficeProvider')
      .directive('firmAddDirective', firmAddDirective);

    firmAddDirective.$inject = [];

    function firmAddDirective() {
        return {
            restrict: 'A',
            templateUrl: "app/components/backoffice/firms/firm-add-directive/firms-add.html",
            controller: "BackofficeFirmsAddController",
            link: function (scope, element, attrs, ngModel) {
            }
        }
    }
})();
