(function () {
    'use strict'

    angular.module('app.core')
    .directive('logParams', [activityLoggerParamsDirective]);
    function activityLoggerParamsDirective() {
        return {
            restrict: 'AE',
            scope: {
                individualId: '=?logIndividual',
                providerId: '=?logProvider',
                applicationId: '=?logApplication',
                pageModuleIds: '@?logPageModule',   // <PageModuleId>, <PageModuleTabSubModuleId>, <PageTabSectionId>
                logType: '@?',
                logChangeName: '@?'
            },
            link: function (scope, element, attrs, ctrl) {
                ctrl.$scope = scope;
                ctrl.params = {};

                var updateControllerParams = function () {
                    ctrl.params.individualId = scope.individualId;
                    ctrl.params.providerId = scope.providerId;
                    ctrl.params.applicationId = scope.applicationId;
                    ctrl.params.pageModuleIds = scope.pageModuleIds;
                    ctrl.params.logType = scope.logType;
                    ctrl.params.logChangeName = scope.logChangeName;
                };
                
                scope.$watch(function () { return '{0},{1},{2},{3},{4},{5}'.format(scope.individualId, scope.providerId, scope.applicationId, scope.pageModuleIds, scope.logType, scope.logChangeName); }, function () {
                    updateControllerParams();
                });
            },
            controller: ['$scope', function ($scope) {}]
        };
    }
})();