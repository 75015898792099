(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationStaffReviewController", InitialApplicationStaffReviewController);

    InitialApplicationStaffReviewController.$inject = ["$rootScope", "$scope", "$state", "$q", "backofficeIndividualPersonalService", "backofficeProviderLicenseService",
        "initialApplicationStaffReviewService", "licenseApplicationListingService", "utilityService", "typeValuesService", "dcaIService", 'Messages',
        'LicenseSettings', 'backofficeIndividualLicenseService', 'initialApplicationEducationService', 'initialApplicationExperiencService', 'expirationService', 'BackofficeIndividualService', 'BackofficeProviderService', '$uibModal', 'initialApplicationstatusService'];

    function InitialApplicationStaffReviewController($rootScope, $scope, $state, $q, backofficeIndividualPersonalService, backofficeProviderLicenseService,
        initialApplicationStaffReviewService, licenseApplicationListingService, utilityService, typeValuesService, dcaIService, Messages,
        LicenseSettings, backofficeIndividualLicenseService, initialApplicationEducationService, initialApplicationExperiencService, expirationService, BackofficeIndividualService, BackofficeProviderService, $uibModal, initialApplicationstatusService) {

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        $rootScope.routeData = $state.current.routeData;

        var createNewLicenseRecord = function () {
            $scope.individualLicense = {
                IndividualId: $scope.individualId,
                ApplicationId: $scope.applicationId,
                ActionFlag: $scope.staffReviewConfig.license.ActionFlag,
                ApplicationTypeId: $scope.staffReviewConfig.license.ApplicationTypeId,
                LicenseTypeId: $scope.applicationInfo.LicenseTypeId,
                IsLicenseTemporary: false,
                IsLicenseActive: true,
                LicenseStatusTypeId: $scope.staffReviewConfig.license.LicenseStatusTypeId,
                IsActive: true,
                IsDeleted: false,
                LicenseStatusTypeCode: $scope.staffReviewConfig.license.LicenseStatusTypeCode,
                LicenseStatusTypeName: $scope.staffReviewConfig.license.LicenseStatusTypeName,
                ApplicationTypeName: $scope.staffReviewConfig.license.ApplicationTypeName
            };
        };

        var createNewPermitRecord = function () {
            $scope.individualPermit = {
                PermitApplicationId: $rootScope.permitApplicationId,
                IndividualId: $scope.individualId,
                ApplicationId: $scope.applicationId,
                IndividualLicenseId: $scope.individualLicense.IndividualLicenseId,
                PermitTypeId: $rootScope.PermitTypeId,
                PermitApplciationTypeId: $rootScope.PermitApplicationTypeId,
                PermitStatusId: 1,
                IsEnabled: true,
                IsActive: true,
                IsDeleted: false,
            };
        };

        var getIndividualLicense = function () {
            var deferred = $q.defer();
            try {
                //$scope.individualLicense = {};
                licenseApplicationListingService.getIndividualLicenseBYIndividualId(sessionStorage.Key, $scope.individualId)
                    .then(function (response) {
                        if (response.Status) {
                            if (!!response.IndividualLicenseList && response.IndividualLicenseList.length > 0) {
                                //$scope.individualLicense = response.IndividualLicenseList[0];
                                if (!$scope.individualLicense) {
                                    var isindividualLicenseList = _.filter(response.IndividualLicenseList, function (o) {
                                        return o.ApplicationId == $scope.applicationId;
                                    });
                                    $scope.individualLicenseList = _.uniq(isindividualLicenseList);
                                    $scope.individualLicense = $scope.individualLicenseList[0];
                                }
                                if ($scope.individualLicense.LicenseStatusTypeId == 17) {
                                    $scope.individualLicense.OriginalLicenseDate = new Date();
                                    $scope.individualLicense.LicenseEffectiveDate = new Date();
                                    $scope.individualLicense.LicenseStatusTypeId = 1;
                                    $scope.individualLicense.ActionFlag = 'MA';
                                }

                                if (!$scope.individualLicense || $scope.individualLicense.length == 0) {
                                    createNewLicenseRecord();
                                }
                                processLicenseDates();
                                if ([28].includes($scope.applicationInfo.ApplicationStatusId)) {
                                    getUserData();
                                }
                            } else {
                                createNewLicenseRecord();
                                if ([28].includes($scope.applicationInfo.ApplicationStatusId)) {
                                    getUserData();
                                }
                            }
                        } else {
                            if (response.StatusCode == "00") {
                                createNewLicenseRecord();
                                if ([28].includes($scope.applicationInfo.ApplicationStatusId)) {
                                    getUserData();
                                }
                            } else {
                                $scope.showStatusMessage({
                                    messageContent: response.Message,
                                    messageType: 'error'
                                });
                            }
                        }
                        deferred.resolve(response);
                    }, function (error) {
                        deferred.reject(error);
                        $scope.showStatusMessage({
                            messageContent: 'Some error occured !',
                            messageType: 'error'
                        });
                    });
            } catch (ex) {
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
                deferred.reject(ex);
            }
            return deferred.promise;
        };

        var getProviderLicense = function () {
            var deferred = $q.defer();
            try {
                //$scope.individualLicense = {};
                backofficeProviderLicenseService.providerLicenseDetailBYProviderId(sessionStorage.Key, $scope.providerId)
                    .then(function (response) {
                        if (response.Status) {
                            if (!!response.ProviderLicenseResponseList && response.ProviderLicenseResponseList.length > 0) {
                                //$scope.individualLicense = response.IndividualLicenseList[0];
                                if (!$scope.providerLicense) {
                                    var isproviderLicenseList = _.filter(response.ProviderLicenseResponseList, function (o) {
                                        return o.ApplicationId == $scope.applicationId;
                                    });
                                    $scope.ProviderLicenseResponseList = _.uniq(isproviderLicenseList);
                                    $scope.providerlLicense = $scope.ProviderLicenseResponseList[0];
                                }
                                if ($scope.providerLicense.LicenseStatusTypeId == 17) {
                                    $scope.providerLicense.OriginalLicenseDate = new Date();
                                    $scope.providerLicense.LicenseEffectiveDate = new Date();
                                    $scope.providerLicense.LicenseStatusTypeId = 1;
                                    $scope.providerLicense.ActionFlag = 'MA';
                                }

                                if (!$scope.providerLicense || $scope.providerLicense.length == 0) {
                                    createNewLicenseRecord();
                                }
                                processLicenseDates();
                                if ([28].includes($scope.applicationInfo.ApplicationStatusId)) {
                                    getUserData();
                                }
                            } else {
                                createNewLicenseRecord();
                                if ([28].includes($scope.applicationInfo.ApplicationStatusId)) {
                                    getUserData();
                                }
                            }
                        } else {
                            if (response.StatusCode == "00") {
                                createNewLicenseRecord();
                                if ([28].includes($scope.applicationInfo.ApplicationStatusId)) {
                                    getUserData();
                                }
                            } else {
                                $scope.showStatusMessage({
                                    messageContent: response.Message,
                                    messageType: 'error'
                                });
                            }
                        }
                        deferred.resolve(response);
                    }, function (error) {
                        deferred.reject(error);
                        $scope.showStatusMessage({
                            messageContent: 'Some error occured !',
                            messageType: 'error'
                        });
                    });
            } catch (ex) {
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
                deferred.reject(ex);
            }
            return deferred.promise;
        };

        var getUserData = function () {
            backofficeIndividualPersonalService.individualBYIndividualId(sessionStorage.Key, $scope.individualId).then(function (response) {
                if (response.IndividualResponse && response.IndividualResponse.length > 0) {
                    $scope.personalInfo = response.IndividualResponse[0];

                    if ($scope.personalInfo.DateOfBirth) {
                        var dateOfBirth = new Date($scope.personalInfo.DateOfBirth);
                        var currentDate = new Date();
                        var finalDate = new Date(currentDate.getFullYear(),
                            dateOfBirth.getMonth(), daysInMonth(dateOfBirth.getMonth() + 1,
                                currentDate.getFullYear()));

                        if ((new Date($scope.personalInfo.DateOfBirth).getMonth() - currentDate.getMonth() >= 6)) {
                            $scope.individualLicense.LicenseExpirationDate = new Date(finalDate);
                        } else {
                            $scope.individualLicense.LicenseExpirationDate = new Date(finalDate).addYears(1);
                        }
                    }
                }
            });
        };

        function daysInMonth(month, year) {
            return new Date(year, month, 0).getDate();
        };

        var getIndividualPermit = function () {
            var deferred = $q.defer();
            try {
                $scope.individualLicense = {};
                licenseApplicationListingService.getIndividualLicenseBYIndividualId(sessionStorage.Key, $scope.individualId)
                    .then(function (response) {
                        if (response.Status) {
                            if (!!response.IndividualLicenseList && response.IndividualLicenseList.length > 0) {
                                //$scope.individualLicense = response.IndividualLicenseList[0];
                                var isindividualLicenseList = _.filter(response.IndividualLicenseList, function (o) {
                                    return o.LicenseStatusTypeCode == "A";
                                });
                                $scope.individualLicenseList = _.uniq(isindividualLicenseList);
                                $scope.individualLicense = $scope.individualLicenseList[0];

                                $scope.individualPermit = {};
                                initialApplicationStaffReviewService.individualPermitByIndividualId(sessionStorage.Key, $scope.individualId, $rootScope.PermitTypeId, $scope.applicationId)
                                    .then(function (response) {
                                        if (response.Status) {
                                            if (!!response.PermitResponseList && response.PermitResponseList.length > 0) {
                                                $scope.individualPermit = response.PermitResponseList[0];
                                                processPermitDates();
                                            } else {
                                                createNewPermitRecord();
                                            }
                                        } else {
                                            if (response.StatusCode == "00")
                                                createNewPermitRecord();
                                            else
                                                $scope.showStatusMessage({
                                                    messageContent: response.Message,
                                                    messageType: 'error'
                                                });
                                        }
                                        deferred.resolve(response);
                                    }, function (error) {
                                        deferred.reject(error);
                                        $scope.showStatusMessage({
                                            messageContent: 'Some error occured !',
                                            messageType: 'error'
                                        });
                                    });
                            }
                        } else {
                            if (response.StatusCode != "00")
                                $scope.showStatusMessage({
                                    messageContent: response.Message,
                                    messageType: 'error'
                                });
                        }
                    }, function (error) {
                        deferred.reject(error);
                        $scope.showStatusMessage({
                            messageContent: 'Some error occured !',
                            messageType: 'error'
                        });
                    });
            } catch (ex) {
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
                deferred.reject(ex);
            }
            return deferred.promise;
        };

        var validateForm = function (currentReasonForm) {
            $scope.natValidation.errorMessages = [];
            if (!!$scope.currentForm) {
                $scope.currentForm.$submitted = true;
                if (!!$scope.currentForm.errorMessages)
                    $scope.natValidation.errorMessages = angular.copy($scope.currentForm.errorMessages);
            }

            if (!!currentReasonForm) {
                currentReasonForm.$submitted = true;
                var addInfoErrorMessages = angular.copy(currentReasonForm.errorMessages);
                angular.forEach(addInfoErrorMessages, function (errorMessage, index) {
                    $scope.natValidation.errorMessages.push(errorMessage);
                });
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.setLicenseToMinDate = function () {
            if (angular.isDate($scope.individualLicense.LicenseEffectiveDate)) {
                $scope.datePicker.endDateOptions.minDate = $scope.individualLicense.LicenseEffectiveDate;
            }
        };

        $scope.setLicenseFromMaxDate = function () {
            if (angular.isDate($scope.individualLicense.LicenseExpirationDate)) {
                $scope.datePicker.startDateOptions.maxDate = $scope.individualLicense.LicenseExpirationDate;
            }
        };

        $scope.setProviderLicenseToMinDate = function () {
            if (angular.isDate($scope.providerLicense.ProviderLicenseEffectiveDate)) {
                $scope.datePicker.endDateOptions.minDate = $scope.providerLicense.ProviderLicenseEffectiveDate;
            }
        };

        $scope.setProviderLicenseFromMaxDate = function () {
            if (angular.isDate($scope.providerLicense.ProviderLicenseExpirationDate)) {
                $scope.datePicker.startDateOptions.maxDate = $scope.providerLicense.ProviderLicenseExpirationDate;
            }
        };

        var processLicenseDates = function () {
            if (!!$scope.individualLicense) {
                if (!!$scope.individualLicense.OriginalLicenseDate && !angular.isDate($scope.individualLicense.OriginalLicenseDate))
                    $scope.individualLicense.OriginalLicenseDate = new Date($scope.individualLicense.OriginalLicenseDate);
                if (!!$scope.individualLicense.LicenseEffectiveDate && !angular.isDate($scope.individualLicense.LicenseEffectiveDate))
                    $scope.individualLicense.LicenseEffectiveDate = new Date($scope.individualLicense.LicenseEffectiveDate);
                if (!!$scope.individualLicense.LicenseExpirationDate && !angular.isDate($scope.individualLicense.LicenseExpirationDate))
                    $scope.individualLicense.LicenseExpirationDate = new Date($scope.individualLicense.LicenseExpirationDate);
                $scope.setLicenseToMinDate();
                $scope.setLicenseFromMaxDate();
            }
            else if(!!$scope.providerLicense){
                if (!!$scope.providerLicense.OriginalLicenseDate && !angular.isDate($scope.providerLicense.OriginalLicenseDate))
                    $scope.providerLicense.OriginalLicenseDate = new Date($scope.providerLicense.OriginalLicenseDate);
                if (!!$scope.providerLicense.ProviderLicenseEffectiveDate && !angular.isDate($scope.providerLicense.ProviderLicenseEffectiveDate))
                    $scope.providerLicense.ProviderLicenseEffectiveDate = new Date($scope.providerLicense.ProviderLicenseEffectiveDate);
                if (!!$scope.providerLicense.ProviderLicenseExpirationDate && !angular.isDate($scope.providerLicense.ProviderLicenseExpirationDate))
                    $scope.providerLicense.ProviderLicenseExpirationDate = new Date($scope.providerLicense.ProviderLicenseExpirationDate);
                $scope.setProviderLicenseToMinDate();
                $scope.setProviderLicenseFromMaxDate();
            }
            
        };

        $scope.setPermitToMinDate = function () {
            if (angular.isDate($scope.individualPermit.PermitEffectiveDate)) {
                $scope.datePicker.endDateOptions.minDate = $scope.individualPermit.PermitEffectiveDate;
            }
        };

        $scope.setPermitFromMaxDate = function () {
            if (angular.isDate($scope.individualPermit.PermitExpirationDate)) {
                $scope.datePicker.startDateOptions.maxDate = $scope.individualPermit.PermitExpirationDate;
            }
        };

        var processPermitDates = function () {
            if (!!$scope.individualPermit) {
                if (!!$scope.individualPermit.PermitIssueDate && !angular.isDate($scope.individualPermit.PermitIssueDate))
                    $scope.individualPermit.PermitIssueDate = new Date($scope.individualPermit.PermitIssueDate);
                if (!!$scope.individualPermit.PermitEffectiveDate && !angular.isDate($scope.individualPermit.PermitEffectiveDate))
                    $scope.individualPermit.PermitEffectiveDate = new Date($scope.individualPermit.PermitEffectiveDate);
                if (!!$scope.individualPermit.PermitExpirationDate && !angular.isDate($scope.individualPermit.PermitExpirationDate))
                    $scope.individualPermit.PermitExpirationDate = new Date($scope.individualPermit.PermitExpirationDate);
            }
            $scope.setPermitToMinDate();
            $scope.setPermitFromMaxDate();
        };

        $scope.$on('$destroy', function () {
            HideLoader();
        });

        var disableButtons = function (disabled) {
            $scope.denyDisabled = disabled;
            $scope.preApproveDisabled = disabled;
            $scope.approveDisabled = disabled;
        };

        $scope.denyApplication = function (currentForm) {
            if (!confirm(Messages.dacbaff)) {
                return;
            }

            ShowLoader('Please wait...');

            disableButtons(true);

            $scope.applicationReview.ApplicationStatusId = 5; //LAMED: Denied
            $scope.applicationReview.ApplicationDenialDate = new Date();

            saveApplicationStatusReview()
                .then(function (data) {
                    HideLoader();
                    disableButtons(false);
                    if (data.Status) {
                        $scope.$emit('denyApplication', {
                            applicationId: $scope.applicationId
                        });
                        $scope.openNextTab({
                            currentTab: $scope.tab
                        });
                    } else {
                        console.log(data.Message);
                    }
                }, function (err) {
                    HideLoader();
                    console.log(err);
                });
        };

        $scope.openConfirmationPopup = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/individual/application/directives/staff-review/staff-review-license-exists-confirmation.html',
                size: 'md',
                resolve: {
                },
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    $scope.onCancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.onConfirm = function () {
                        $uibModalInstance.close('success');
                    };
                }]
            });

            modalInstance.result.then(function () {

            });
        };

        var processLicenseAndApplication = function (individualId, applicationId, applicationProcessingData) {
            var reqData = {
                UpdateApplicationStatus: true,
                IndividualId: individualId,
                ApplicationId: applicationId,
                ApprovalType: applicationProcessingData.ApprovalType,
                ApplicationStatusId: applicationProcessingData.ApplicationStatusId,
                IsSubmission: false,
                LogApplicationStatusIds: applicationProcessingData.LogApplicationStatusIds
            }
            initialApplicationstatusService.processLicenseAndApplication(sessionStorage.Key, reqData)
                .then(function (response) {

                }, function (data) {
                    $scope.showStatusMessage({
                        messageContent: 'Some error occured !',
                        messageType: 'error'
                    });
                });
        };

        var processFirmLicenseAndApplication = function (providerId, applicationId, applicationProcessingData) {
            var reqData = {
                UpdateApplicationStatus: true,
                ProviderId: providerId,
                ApplicationId: applicationId,
                ApprovalType: applicationProcessingData.ApprovalType,
                ApplicationStatusId: applicationProcessingData.ApplicationStatusId,
                IsSubmission: false,
                LogApplicationStatusIds: applicationProcessingData.LogApplicationStatusIds
            }
            initialApplicationstatusService.processFirmLicenseAndApplication(sessionStorage.Key, reqData)
                .then(function (response) {

                }, function (data) {
                    $scope.showStatusMessage({
                        messageContent: 'Some error occured !',
                        messageType: 'error'
                    });
                });
        };

        $scope.preApproveApplication = async function (currentForm, approvalStatusId, approvalType, applicationDenialReasonId, backgroundCheckStatusId) {
            ShowLoader('Please wait...');
            
            //check if license exists
            if (!!$scope.licenseAlreadyExists()) {
                $scope.openConfirmationPopup();
                HideLoader();
                return;
            }

            disableButtons(true);
            let isOk = false;
            if(!!$scope.individualLicense)
            {
                if (!!approvalStatusId && approvalStatusId == 3) {
                    let requestData = {
                        isn: $scope.individualLicense.IDNumber,
                        IndividualLicenseId:  $scope.individualLicense.IndividualLicenseId,
                        isAbandoned: 0
                    };

                    let renewalProcessStatusResponse = await dcaIService.updateRenewalProcessStatus(sessionStorage.Key, requestData);

                    isOk = (!!renewalProcessStatusResponse && !!renewalProcessStatusResponse.Status);
                }
                else{
                    isOk = true;
                }
            }
            else if(!!$scope.providerLicense)
            {
                if (!!approvalStatusId && approvalStatusId == 3) {
                    let requestData = {
                        isn: $scope.providerLicense.IDNumber,
                        IndividualLicenseId:  $scope.providerLicense.ProviderLicenseId,
                        isAbandoned: 0,
                        ProviderId: $scope.providerId,
                        isConviction: 0
                    };
                    let renewalProcessStatusResponse = await dcaIService.updateRenewalProcessStatus(sessionStorage.Key, requestData);

                    isOk = (!!renewalProcessStatusResponse && !!renewalProcessStatusResponse.Status);
                }
                else{
                    if(!!approvalStatusId && approvalStatusId == 46)
                    {
                        
                    }
                    else
                    {
                        isOk = true;
                    }
                }

                
                
            }

            if(isOk){
                $scope.hasStatusUpdate = $scope.applicationReview.ApplicationStatusId != approvalStatusId;
                if(!!$scope.providerLicense)
                {
                    $scope.applicationReview.IndividualId = $scope.providerId;
                    $scope.applicationReview.ProviderId = $scope.providerId;
                }
                $scope.applicationReview.approvalType = approvalType;
                $scope.applicationReview.ApplicationStatusId = (!!approvalStatusId ? approvalStatusId : $scope.applicationInfo.ApplicationStatusId);
                $scope.applicationReview.ApplicationDenialReasonId = applicationDenialReasonId;
                $scope.applicationReview.ApplicationDenialReasonId = applicationDenialReasonId;
                $scope.applicationReview.BackgroundCheckStatusId = (!!backgroundCheckStatusId ? backgroundCheckStatusId : $scope.applicationInfo.BackgroundCheckStatusId);

                saveApplicationStatusReview()
                    .then(function (data) {
                        if (data.Status) {
                            $scope.$emit('preApproveApplication', {
                                applicationId: $scope.applicationId,
                                approvalType: approvalType
                            });
                            $scope.openNextTab({
                                currentTab: $scope.tab
                            });

                            if (!!$scope.hasStatusUpdate) {
                                if(!!$scope.providerLicense)
                                {
                                    BackofficeProviderService.ApplicationStatusChange(sessionStorage.Key
                                        , $scope.applicationReview.ApplicationId
                                        , $scope.applicationReview.ApplicationStatusId
                                    )
                                        .then(function (data) {
                                            if (!!approvalStatusId && approvalStatusId == 3) {
                                                var applicationProcessingData = {
                                                    ApprovalType: "approvalRenewal",
                                                    ApplicationStatusId: approvalStatusId,
                                                    LogApplicationStatusIds: approvalStatusId,
                                                    ApplicationReviewReasonIds: null
                                                };
                                                processFirmLicenseAndApplication($scope.providerId, $scope.applicationId, applicationProcessingData);
                                                $rootScope.$broadcast('changeCourseStatus', { courseStatusId: 2, applicationId: $scope.applicationId });
                                            }
                                            $scope.$parent.closeApplication($scope.$parent.current.application)
    
                                            console.log(data);
                                        }, function (err) {
                                            $scope.showStatusMessage({
                                                messageContent: 'Some error occured !',
                                                messageType: 'error'
                                            });
                                        });
                                }
                                else{
                                    BackofficeIndividualService.ApplicationStatusChange(sessionStorage.Key
                                        , $scope.applicationReview.ApplicationId
                                        , $scope.applicationReview.ApplicationStatusId
                                    )
                                        .then(function (data) {
                                            if (!!approvalStatusId && approvalStatusId == 3) {
                                                var applicationProcessingData = {
                                                    ApprovalType: "approvalRenewal",
                                                    ApplicationStatusId: approvalStatusId,
                                                    LogApplicationStatusIds: approvalStatusId,
                                                    ApplicationReviewReasonIds: null
                                                };
                                                processLicenseAndApplication($scope.individualId, $scope.applicationId, applicationProcessingData);
                                                $rootScope.$broadcast('changeCourseStatus', { courseStatusId: 2, applicationId: $scope.applicationId });
                                            }
                                            $scope.$parent.closeApplication($scope.$parent.current.application)
    
                                            console.log(data);
                                        }, function (err) {
                                            $scope.showStatusMessage({
                                                messageContent: 'Some error occured !',
                                                messageType: 'error'
                                            });
                                        });
                                }
                                
                                
                            }

                        } else {
                            console.log(data.Message);
                        }

                        HideLoader();
                        disableButtons(false);
                    }, function (err) {
                        HideLoader();
                        disableButtons(false);

                        console.log(err);
                    });
            }
            else{
                if(!!approvalStatusId && approvalStatusId == 46 && !!$scope.providerLicense)
                {
                        let errMsg = "Enforcement Refferal Option Not Available";
                    $scope.showStatusMessage({
                        messageContent: errMsg,
                        messageType: 'error'
                    });
                    HideLoader();
                    disableButtons(false);
                }
                else
                {
                    let errMsg = "CAS profile cannot be updated at this time. Please review CAS profile and make necessary changes before approving application in Connect.";
                    $scope.showStatusMessage({
                        messageContent: errMsg,
                        messageType: 'error'
                    });
                    HideLoader();
                    disableButtons(false);
                }
            }

        };

        $scope.approveApplication = function (currentForm) {
            ShowLoader('Please wait...');
            if (!validateForm()) {
                HideLoader();
                return;
            }
            //check if license exists
            if (!!$scope.licenseAlreadyExists()) {
                $scope.openConfirmationPopup();
                HideLoader();
                return;
            }
            disableButtons(true);

            $scope.applicationReview.ApplicationStatusId = 3; //LAMED: Approved
            $scope.applicationReview.ApplicationApprovalDate = new Date();

            saveApplicationStatusReview()
                .then(function (data) {
                    if (data.Status) {
                        saveIndividualLicense().then(function (response) {
                            HideLoader();
                            disableButtons(false);
                            if (response.Status) {
                                var requestData = {
                                    EntityId: $scope.individualId,
                                    EntityType: "I",
                                    ApplicationId: $scope.applicationId,
                                    IndividualLicenseId: $scope.individualLicense.IndividualLicenseId,
                                    ResidentialAddressConfig: {
                                        AddressTypeId: 2,
                                        CheckIsMailingFlag: false
                                    },
                                    MailingAddressConfig: {
                                        AddressTypeId: 1,
                                        CheckIsMailingFlag: false
                                    },
                                    MailingPhoneContactTypeId: 1,
                                    ResidentialPhoneContactTypeId: 2,
                                    EmailContactTypeId: 8
                                };

                                dcaIService.generateLicenseTransferFile(sessionStorage.Key, requestData).then(function (response) {
                                    if (!response.Status) {
                                        $scope.showStatusMessage({
                                            messageContent: 'Some error occured !',
                                            messageType: 'error'
                                        });
                                        HideLoader();
                                    }
                                    $scope.$emit('approveApplication', {
                                        applicationId: $scope.applicationId
                                    });
                                    $scope.openNextTab({
                                        currentTab: $scope.tab
                                    });
                                }, function (err) {
                                    $scope.showStatusMessage({
                                        messageContent: 'Some error occured !',
                                        messageType: 'error'
                                    });
                                    HideLoader();
                                });
                            } else {
                                $scope.showStatusMessage({
                                    messageContent: 'Some error occured !',
                                    messageType: 'error'
                                });
                            }
                        }, function (err) {
                            $scope.showStatusMessage({
                                messageContent: 'Some error occured !',
                                messageType: 'error'
                            });
                            HideLoader();
                        });
                    } else {
                        $scope.showStatusMessage({
                            messageContent: 'Some error occured !',
                            messageType: 'error'
                        });
                    }
                }, function (err) {
                    $scope.showStatusMessage({
                        messageContent: 'Some error occured !',
                        messageType: 'error'
                    });
                    HideLoader();
                });
        };

        var queueStatusReviewTypeMapping = [
            { queueType: "AdminQ", applicationStatusId: 0, ApplicationReviewTypeId: 0 },
            { queueType: "IntakeQ", applicationStatusId: 8, ApplicationReviewTypeId: 0 },

            { queueType: "ReviewQ", applicationStatusId: 42, ApplicationReviewTypeId: 1 },
            { queueType: "ComplianceReviewQ", applicationStatusId: 44, ApplicationReviewTypeId: 3 },
            { queueType: "ManagerQ", applicationStatusId: 28, ApplicationReviewTypeId: 5 },
            { queueType: "EnforcementReferralQ", applicationStatusId: 46, ApplicationReviewTypeId: 6 },
            { queueType: "DeficiencyQ", applicationStatusId: 43, ApplicationReviewTypeId: 2 },
            { queueType: "ComplianceReviewDeficiency", applicationStatusId: 45, ApplicationReviewTypeId: 4 }
        ];

        var processApplicationReview = function (response) {
            if (response.lstApplicationReviewNew && response.lstApplicationReviewNew.length > 0) {
                //$scope.applicationReviews = response.lstApplicationReviewNew.whereIncludes([3], 'ApplicationReviewTypeId');
                $scope.applicationReviews = response.lstApplicationReviewNew; //.whereIncludes([$rootScope.routeData.queueType], 'queueType');
            } else {
                $scope.applicationReviews = [];
            }
            if (!$scope.applicationReview) {
                createApplicationReview();
            }
            if (!!$scope.applicationReview && !$scope.applicationReview.ApplicationReviewDate) {
                $scope.applicationReview.ApplicationReviewDate = new Date();
            }
        };

        var getApplicationReviewType = function () {
            var queueStatusReviewType = queueStatusReviewTypeMapping
                .whereEquals($rootScope.routeData.queueType, 'queueType')
                .whereEquals($scope.applicationInfo.ApplicationStatusId, 'applicationStatusId').firstOrDefault();
            var applicationReviewType = null;
            if (!!queueStatusReviewType) {
                applicationReviewType = $scope.applicationReviewTypes.whereEquals(queueStatusReviewType.ApplicationReviewTypeId, 'ApplicationReviewTypeId').firstOrDefault();
            }
            return applicationReviewType;
        };

        var createApplicationReview = function () {
            var applicationReviewType = getApplicationReviewType();
            $scope.applicationReview = {
                ApplicationId: $scope.applicationId,
                IndividualId: $scope.individualId,
                ApplicationStatusId: $scope.applicationInfo.ApplicationStatusId,
                ApplicationReviewTypeId: (applicationReviewType ? applicationReviewType.ApplicationReviewTypeId : null),
                ReviewTypeCode: (applicationReviewType ? applicationReviewType.ReviewTypeCode : null),
                ReviewTypeName: (applicationReviewType ? applicationReviewType.ReviewTypeName : null)
            }
        };

        var saveApplicationStatusReview = function () {
            var deferred = $q.defer();

            if (!validateForm()) {
                deferred.reject();
                return deferred.promise;
            }
            if (!!$scope.applicationReview) {
                initialApplicationStaffReviewService.saveApplicationReview(sessionStorage.Key, [$scope.applicationReview]).then(function (response) {
                    processApplicationReview(response);
                    deferred.resolve(response);
                }, function (error) {
                    deferred.reject(error);
                    $scope.showStatusMessage({
                        messageContent: 'Some error occurred !',
                        messageType: 'error'
                    });
                });
            }
            return deferred.promise;
        };

        var saveIndividualLicense = function () {
            var deferred = $q.defer();
            if (!!$scope.individualLicense && ($scope.applicationInfo.LicenseTypeId == 1 || $scope.applicationInfo.LicenseTypeId == 2) && $scope.individualLicense.LicenseExpirationDate == null) {
                $scope.individualLicense.LicenseExpirationDate = new Date($scope.individualLicense.LicenseEffectiveDate).addYears(1);
            } else if (!!$scope.individualLicense && !($scope.applicationInfo.LicenseTypeId == 1 || $scope.applicationInfo.LicenseTypeId == 2) && $scope.individualLicense.LicenseExpirationDate == null) {
                $scope.individualLicense.LicenseExpirationDate = expirationService.getRenewalCycleBasedExpirationDate();
            }

            initialApplicationStaffReviewService.saveIndividualLicense(sessionStorage.Key, $scope.individualLicense).then(function (response) {
                if (response.Status) {
                    deferred.resolve(response);
                } else {
                    deferred.reject(response);
                }
            }, function (error) {
                deferred.reject(error);
                $scope.showStatusMessage({
                    messageContent: 'Some error occurred !',
                    messageType: 'error'
                });
            });
            return deferred.promise;
        };

        $scope.applicationIsComplete = function () {
            return false; //!!$scope.individualLicense.LicenseNumber;
        };

        $scope.getIsDeficiencyStatusDirty = function () {
            var isDeficienciesDirty = false;

            $scope.individualViewConfig.processedTabs.forEach(function (t) {
                if (t.isTabDeficiencyDirty) {
                    isDeficienciesDirty = true;
                }
            });

            return isDeficienciesDirty;
        };

        $scope.applicationIsDeficient = function () {
            var isDeficient = false;

            $scope.individualViewConfig?.processedTabs?.forEach(function (t) {
                if (t.isTabDeficient && !t.isTabDeficiencyResolved) {
                    isDeficient = true;
                }
            });

            $scope.providerViewConfig?.processedTabs?.forEach(function (t) {
                if (t.isTabDeficient && !t.isTabDeficiencyResolved) {
                    isDeficient = true;
                }
            });


            return isDeficient;
        };

        $scope.licenseAlreadyExists = function () {
            return !!$scope.individualId &&
                !!$scope.existingLicenseTypes &&
                $scope.existingLicenseTypes.includes($scope.applicationInfo.LicenseTypeId) &&
                !!$scope.configForAppType &&
                !!$scope.configForAppType.LicenseTypeIdsAllowedMultiple &&
                !$scope.configForAppType.LicenseTypeIdsAllowedMultiple.includes($scope.applicationInfo.LicenseTypeId);
        };

        $scope.init = function () {
            $scope.datePicker = {
                fromDateOpened: false,
                toDateOpened: false,
                format: 'M!/d!/yyyy',
                endDateOptions: {
                    minDate: null
                },
                startDateOptions: {}
            };
            if (!$scope.personalData) {
                $scope.personalData = {};
            }

            $scope.uiLogic = {};

            //$rootScope.displayMainLoader(true, "Please wait...");
            ShowLoader("Please wait...");
            if(!$scope.providerId && !!$scope.individualId)
            {
            var promises = {
                staffReviewConfig: utilityService.getConfigData("/components/individual/application/directives/staff-review/staff-review.server-config.json"),
                applicationReviewType: typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=applicationreviewtype")),
                appTypeConfig: utilityService.getConfigData('/components/backoffice/application/application-type-selection/application-type-selection.server-config.json'),
                applicationReview: initialApplicationStaffReviewService.getApplicationReview(sessionStorage.Key, $scope.individualId, $scope.applicationId),
                individualExperience: initialApplicationExperiencService.getIndividualExperience($scope.individualId, $scope.applicationId, sessionStorage.Key),
                individualEducation: initialApplicationEducationService.getEducation($scope.individualId, $scope.applicationId, sessionStorage.UserID, sessionStorage.Key)
            }
            if (!!$scope.individualId) {
                promises.individualLicense = backofficeIndividualLicenseService.individualLicenseDetailBYIndividualId(sessionStorage.Key, $scope.individualId);
            }

            $q.all(promises).then(function (response) {
                $scope.staffReviewConfig = response.staffReviewConfig;
                $scope.applicationReviewTypes = response.applicationReviewType.ReferenceEntities;
                $scope.configForAppType = response.appTypeConfig;
                if (!!$scope.isLicenseApp) {
                    getIndividualLicense().then(function (responseLicense) {
                        //$rootScope.displayMainLoader(false);
                        HideLoader();
                    });
                } else if (!!$scope.isPermitApp) {
                    getIndividualPermit().then(function (responsePermit) {
                        //$rootScope.displayMainLoader(false);
                        HideLoader();
                    });
                }
                processApplicationReview(response.applicationReview)
                if (!!$scope.individualId && response.individualLicense.IndividualLicenseList) {
                    $scope.existingLicenses = response.individualLicense.IndividualLicenseList;
                    $scope.existingLicenseTypes = response.individualLicense.IndividualLicenseList.whereIncludes(LicenseSettings.ActiveStatuses, 'LicenseStatusTypeId').select('LicenseTypeId');
                } else if (!!$scope.individualId) {
                    $scope.existingLicenses = [];
                    $scope.existingLicenseTypes = [];
                }

                //#region Qualifying Months calculation
                $scope.experienceList = response.individualExperience.IndividualExperienceList;
                var individualEducation = response.individualEducation.IndividualEducation;
                if (!!individualEducation && individualEducation.length > 0) {
                    individualEducation = individualEducation.whereEquals(1, 'ProgramTypeId');
                }
                $scope.individualEducation = individualEducation;
                $scope.getQualifyingExperienceMonths();
                $scope.getQualifyingEducationMonths();
                ////#endregion Qualifying Months calculation

            });
        }
        else if (!$scope.individualId && !!$scope.providerId){
            var promises = {
                staffReviewConfig: utilityService.getConfigData("/components/individual/application/directives/staff-review/staff-review.server-config.json"),
                applicationReviewType: typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=applicationreviewtype")),
                appTypeConfig: utilityService.getConfigData('/components/backoffice/application/application-type-selection/application-type-selection.server-config.json'),
                applicationReview: initialApplicationStaffReviewService.getApplicationReview(sessionStorage.Key, $scope.providerId, $scope.applicationId)
            }
            if (!!$scope.providerId) {
                promises.providerLicense = backofficeProviderLicenseService.providerLicenseDetailBYProviderId(sessionStorage.Key, $scope.providerId);
            }

            $q.all(promises).then(function (response) {
                $scope.staffReviewConfig = response.staffReviewConfig;
                $scope.applicationReviewTypes = response.applicationReviewType.ReferenceEntities;
                $scope.configForAppType = response.appTypeConfig;
                if (!!$scope.isLicenseApp) {
                    getProviderLicense().then(function (responseLicense) {
                        //$rootScope.displayMainLoader(false);
                        HideLoader();
                    });
                } else if (!!$scope.isPermitApp) {
                    getIndividualPermit().then(function (responsePermit) {
                        //$rootScope.displayMainLoader(false);
                        HideLoader();
                    });
                }
                processApplicationReview(response.applicationReview)
                if (!!$scope.providerId && response.providerLicense.ProviderLicenseResponseList) {
                    $scope.existingLicenses = response.providerLicense.ProviderLicenseResponseList;
                    $scope.existingLicenseTypes = response.providerLicense.ProviderLicenseResponseList.whereIncludes(LicenseSettings.ActiveStatuses, 'LicenseStatusTypeId').select('LicenseTypeId');
                } else if (!!$scope.providerId) {
                    $scope.existingLicenses = [];
                    $scope.existingLicenseTypes = [];
                }

                //#region Qualifying Months calculation
                /*$scope.experienceList = response.individualExperience.IndividualExperienceList;
                var individualEducation = response.individualEducation.IndividualEducation;
                if (!!individualEducation && individualEducation.length > 0) {
                    individualEducation = individualEducation.whereEquals(1, 'ProgramTypeId');
                }
                $scope.individualEducation = individualEducation;
                $scope.getQualifyingExperienceMonths();
                $scope.getQualifyingEducationMonths();*/ // Commented by Chandra
                ////#endregion Qualifying Months calculation

            });
        }
        };

        $scope.getQualifyingExperienceMonths = function () {
            var qualifyingExperienceMonths = 0;
            var qualifyingExperienceFieldMonths = 0;
            var qualifyingExperienceOfficeMonths = 0;
            if ($scope.experienceList && $scope.experienceList.length > 0) {
                angular.forEach($scope.experienceList, function (experienceItem) {
                    qualifyingExperienceMonths = qualifyingExperienceMonths + (!!experienceItem.VerifiedExpMonths ? experienceItem.VerifiedExpMonths : 0);
                    qualifyingExperienceFieldMonths = qualifyingExperienceFieldMonths + (!!experienceItem.VerifiedFieldTime ? experienceItem.VerifiedFieldTime : 0);
                    qualifyingExperienceOfficeMonths = qualifyingExperienceOfficeMonths + (!!experienceItem.VerifiedOfficeTime ? experienceItem.VerifiedOfficeTime : 0);
                });
            };
            $scope.uiLogic.qualifyingExperienceMonths = qualifyingExperienceMonths;
            $scope.uiLogic.qualifyingExperienceFieldMonths = qualifyingExperienceFieldMonths;
            $scope.uiLogic.qualifyingExperienceOfficeMonths = qualifyingExperienceOfficeMonths;
        };

        $scope.getQualifyingEducationMonths = function () {
            var qualifyingEducationMonths = 0;
            if ($scope.individualEducation && $scope.individualEducation.length > 0) {
                angular.forEach($scope.individualEducation, function (individualEducationItem) {
                    qualifyingEducationMonths = qualifyingEducationMonths + (!!individualEducationItem.ProgramHours ? individualEducationItem.ProgramHours : 0);
                });
            };
            $scope.uiLogic.qualifyingEducationMonths = qualifyingEducationMonths;
        };

        $scope.$watch('uiLogic.NCEESRecordTechnicalReviewExperience.NCEESRecordTechnicalReviewExperience[0].legalResponse.Desc', function (newval) {
            if (!!newval && newval != "") {
                $scope.uiLogic.qualifyingExperienceMonths += parseInt(newval);
            }
        })

        $scope.$watch('uiLogic.NCEESRecordTechnicalReview.NCEESRecordTechnicalReview[0].legalResponse.Desc', function (newval) {
            if (!!newval && newval != "") {
                $scope.uiLogic.qualifyingEducationMonths += parseInt(newval);
            }
        })

        $scope.$on('SwitchingTab', function (event, args) {
            if (args.fromTab == $scope.tab.name) {
                if (!$scope.isBackoffice) {
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                } else {
                    $scope.$broadcast("SaveDeficiency", {});
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
            }
        });

        $scope.clearTabConfirmation = function () {
            $scope.$emit("clearTabConfirmation", { currentTab: $scope.tab });
        };
    }
})();