(function () {
  'use strict'

  angular.module('app.backofficeIndividual')
    .factory('backofficeProviderCommentService', backofficeProviderCommentService)

  backofficeProviderCommentService.$inject = ['$http', '$q', 'WebApiUrl']

  function backofficeProviderCommentService($http, $q, WebApiUrl) {
      var _saveIndividualComment = function (ProviderComment, individualDocumentUpload, Key) {
      var deferred = $q.defer();
      $http.post(WebApiUrl+'Provider/ProviderCommentLogSave/' + Key, {
          ModelData: ProviderComment,
          ProviderDocumentUpload: individualDocumentUpload
      }).then(function(data){ data = data.data;
        deferred.resolve(data);
      }, function(error) { error = error.data;
        deferred.reject(error);
      });
      return deferred.promise;
    };

    var _providerCommentLogGetByProviderId = function (Key, ProviderId) {
      var deferred = $q.defer();
        $http.get(WebApiUrl + 'Provider/ProviderCommentLogGetByProviderId/' + Key + '?ProviderId=' + ProviderId)
        .then(function(data){ data = data.data;
          deferred.resolve(data);
        }, function(error) { error = error.data;
          deferred.reject(error);
        });
      return deferred.promise;
    };


    return {
        saveProviderComment: _saveIndividualComment,
      providerCommentLogGetByProviderId: _providerCommentLogGetByProviderId
    }
  }
})();