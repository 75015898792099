(function () {
    'use strict'

    angular.module('app.core')
    .directive('applicationApprovalProcessing', [ApplicationApprovalProcessing]);

    function ApplicationApprovalProcessing() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                var appScope = $scope.$parent;

                var processState = function (tabData) {
                    var feeAndPayment = tabData.tabList.whereEquals('feeAndPayment', 'name').firstOrDefault();
                    if (!feeAndPayment) {
                        feeAndPayment = tabData.tabList.whereEquals('noFeeSubmission', 'name').firstOrDefault();
                    }

                    if (appScope.applicationInfo.ApplicationStatusId == 14) {
                        appScope.checkPayment(true)
                        .then(function (response) {
                            if (response.IsPaid) {
                                //Submit the application again to make sure it takes this time
                                appScope.submitApplication();

                                //Also update the Fee & Payment tab to ensure it is saved
                                feeAndPayment.isSaved = true;
                                $scope.saveTabStatus(feeAndPayment);
                            }
                        }, function (response) {

                        });
                    }

                    if (appScope.applicationInfo.ApplicationStatusId == 2) {
                        //Update the Fee & Payment tab to ensure it is saved
                        feeAndPayment.isSaved = true;
                        $scope.saveTabStatus(feeAndPayment);
                    }
                };

                appScope.$applicationLoadingComplete = function (tabData) {
                    processState(tabData);
                };
            },
        };
    }
})();