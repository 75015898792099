﻿(function () {
    'use strict'
    angular.module('app.core')
        .directive('emailerDirective', [function () {
        return {
            restrict: 'EA',
            scope: {
                IndividualId: "=individualid",
                ApplicationId: "=applicationid",
                d_FromEmail: "=fromemail",
                d_EmailTo: "=emailto",
                useridto: "=useridto",
                d_EmailSubject: "=emailsubject",
                d_EmailText: "=emailtext",
                d_CommunicationType:"=communicationtype",
                d_CommunicationSrc:"=communicationsrc",
                loadfromemail: "=loadfromemail",
                referencenumber:"=referencenumber",
                callbackitem: "=callbackitem",                
                sendsms: "=sendsms",
                smstemplateid:"=?smstemplateid",
                sendemailnotification: "=sendemailnotification",
                emailnotificationtemplateId: "=?emailnotificationtemplateId",
                displayfromemail: "=displayfromemail",
                displaytoemail: "=displaytoemail",
                displaysubject: "=displaysubject",
                displaymessagetext: '=displaymessagetext',
                displayfullwidth: '=displayfullwidth',
                istextrequired: '=istextrequired',
                sendemail:"=?sendemail",
                onclose:"&?",
                onsend:"&?"
            },
            controller: "EmailerController",
            templateUrl: "app/core/directive/emailer-directive/emailer-template.html",
        };
    }]);
})();
