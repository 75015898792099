﻿(function () {
    'use strict'
    angular.module('natApp')
      .directive('beResponsibleinchargeDirective', beInstructionsDirective);
    beInstructionsDirective.$inject = [];

    function beInstructionsDirective() {
        return {
            restrict: 'E',
            scope: {
                providerid: "=providerid",
                showheader: '=showheader',
                eventname: "=eventname",
                applicationId: "=applicationId",
                userId: '=userId',
                providerLicense: "=providerLicense",
                natValidation: "=natValidation",
                saveonsubmit: "=saveonsubmit",
                name:"=name"
            },
            templateUrl: "app/components/individual/businessentity/directives/be-responsibleincharge-directive/be-responsibleincharge-directive.html",
            controller: "BEResponsibleInchargeController"
        }
    }
})();