(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationPlss', initialApplicationPlssDirective);

    initialApplicationPlssDirective.$inject = [];

    function initialApplicationPlssDirective() {
        return {
            restrict: 'E',
            scope: {
                licenseDescription: "=licenseDescription",
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                natValidation: "=natValidation",
                individualId: '=individualId',
                applicationId: '=applicationId',
                isBackoffice: "=isBackoffice",
                hearder: "=?hearder",
                applicationInfo: "=?applicationInfo",
                ncess: "=?ncess",
                tab: "=tab",
                openNextTab: "&",
                isRenewal: "=?isRenewal",
                dashboard: "=dashboard"
            },
            templateUrl: "app/components/individual/application/directives/plss/plss.html",
            controller: "InitialApplicationPLSSController"
        }
    }
})();
