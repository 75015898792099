(function() {
    'use strict'

    angular.module('app.merging')
    .factory('IndividualMergingService', ['$http', '$q', 'WebApiUrl', IndividualMergingService]);

    function IndividualMergingService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(!!success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(!!fail) {
                    fail(error);
                }
                console.log("Request failed with status: " + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _searchForIndividuals = function (key, request) {
            return responseHandler($http.post(WebApiUrl + 'Individual/Merging_SearchForIndividuals/' + key + '/', request));
        };

        var _mergeIndividuals = function (key, request) {
            return responseHandler($http.post(WebApiUrl + 'Individual/Merging_MergeIndividuals/' + key + '/', request));
        };

        var _changeApplicationType = function (key, request) {
            return responseHandler($http.post(WebApiUrl + 'Individual/ChangeApplicationType/' + key + '/', request));
        };

        return {
            searchForIndividuals: _searchForIndividuals,
            mergeIndividuals: _mergeIndividuals,
            changeApplicationType: _changeApplicationType
        };
    }
})();