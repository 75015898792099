(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('feePaymentService', feePaymentService)

    feePaymentService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function feePaymentService($http, $q, WebApiUrl, AppConfig) {

        var _individualProcessPayment = function (key, data) {
            var deferred = $q.defer();
            var urlParams = {
                Source: "BPELSGFlatTemplate"
            };
            /*
            ,
                headers: { "Content-Type": "application/json"  }
            */
            //data = {applicationAndExamProcessingData: data};
            
            $http.post(WebApiUrl + 'ApplicationProcessing/CommonProcessPaymentApplicationAndExam/' + key, data, { params: urlParams }).then(function (response) {
                response = response.data;
                deferred.resolve(response);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getInvoiceMasterByIndividualIdAndInvoiceStatus = function (key, individualId, applicationId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/GetInvoiceMasterByIndividualIdAndApplicationIdAndInvoiceStatus/' + key + "?IndividualId=" + individualId + '&ApplicationId=' + applicationId + '&Source=' + AppConfig.Source).then(function (data) {
                data = data.data;
                deferred.resolve(data);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _invoiceMasterSave = function (key, reqData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/InvoiceMasterSave/' + key + '?Source=' + AppConfig.Source, reqData).then(function (data) {
                data = data.data;
                deferred.resolve(data);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getPaymentDetailsByApplicationIdAndIndividualId = function (key, applicationId, individualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Payment/GetPaymentDetailsByApplicationIdAndIndividualId/' + key + "?ApplicationId=" + applicationId + "&IndividualId=" + individualId + '&Source=' + AppConfig.Source).then(function (data) {
                data = data.data;
                deferred.resolve(data);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getInvoiceDetailByIndividualIdAndInvoiceId = function (key, individualId, invoiceId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/GetInvoiceDetailByIndividualIdAndInvoiceId/' + key + "?IndividualId=" + individualId + "&InvoiceId=" + invoiceId + '&Source=' + AppConfig.Source).then(function (data) {
                data = data.data;
                deferred.resolve(data);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };


        var _invoiceDetailSave = function (key, data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/InvoiceDetailSave/' + key + '?Source=' + AppConfig.Source, data).then(function (response) {
                response = response.data;
                deferred.resolve(response);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };

        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function _getInvoiceMaster(key, urlParams) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetInvoiceMaster/' + key, {
                params: urlParams
            }));
        };

        function _getInvoiceDetail(key, urlParams) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetInvoiceDetail/' + key, {
                params: urlParams
            }));
        };

        function _getRevFeeCollect(key, urlParams) {
            return returnDefferedResult($http.get(WebApiUrl + 'EntityCashiering/GetRevFeeCollect/' + key, {
                params: urlParams
            }));
        };

        return {
            getInvoiceMasterByIndividualIdAndInvoiceStatus: _getInvoiceMasterByIndividualIdAndInvoiceStatus,
            invoiceMasterSave: _invoiceMasterSave,
            getPaymentDetailsByApplicationIdAndIndividualId: _getPaymentDetailsByApplicationIdAndIndividualId,
            getInvoiceDetailByIndividualIdAndInvoiceId: _getInvoiceDetailByIndividualIdAndInvoiceId,
            invoiceDetailSave: _invoiceDetailSave,
            individualProcessPayment: _individualProcessPayment,
            getInvoiceMaster: _getInvoiceMaster,
            getInvoiceDetail: _getInvoiceDetail,
            getRevFeeCollect: _getRevFeeCollect,
        };
    }
})();