﻿(function() {
    'use strict';
    angular.module('app.backofficeAdministor').factory('UserAccountService', UserAccountService);
    UserAccountService.$inject = ['$http', 'WebApiUrl', '$q', 'AppConfig'];

    function UserAccountService($http, WebApiUrl, $q, AppConfig) {
        var _UsersSearch = function(key,
            UserName,
            UserTypeId,
            FirstName,
            LastName,
            BusinessName,
            EntityName,
            LicenseNumber,
            Phone,
            PositionTitle,
            Email,
            UserStatusId,
            SourceId,
            Gender,
            IsPending,
            DateOfBirth,
            DOB,
            SourceName,
            UserTypeName,
            UserStatusName) {
            var data = $http.post(WebApiUrl + 'Users/UsersSearchAdmin/' + key, {
                UserName: UserName,
                UserTypeId: UserTypeId,
                FirstName: FirstName,
                LastName: LastName,
                BusinessName: BusinessName,
                EntityName: EntityName,
                LicenseNumber: LicenseNumber,
                Phone: Phone,
                PositionTitle: PositionTitle,
                Email: Email,
                UserStatusId: UserStatusId,
                SourceId: SourceId,
                Gender: Gender,
                IsPending: IsPending,
                DateOfBirth: DateOfBirth,
                DOB: DOB,
                SourceName: SourceName,
                UserTypeName: UserTypeName,
                UserStatusName: UserStatusName
            });
            return returnResult(data);
        };
        var _UsersSave = function(UserId,
            UserName,
            UserTypeId,
            FirstName,
            LastName,
            EntityName,
            Phone,
            PositionTitle,
            Email,
            UserStatusId,
            SourceId,
            Gender,
            IsPending,
            DateOfBirth,
            IsDeleted,
            UserRoles,
            key,
            EntityId,
            EntityType,
            ApplicationAssignedTo,
            LicenseTypeAssignedTo,
            TemporaryPassword) {

            var url = 'Users/UsersSave/';
            if (UserTypeId == 7) {
                url = 'Users/UsersEntitySave/'
            }
            var data = $http.post(WebApiUrl + url + key + '?Source=' + AppConfig.Source, {
                UserId: UserId,
                UserName: UserName,
                UserTypeId: UserTypeId,
                FirstName: FirstName,
                LastName: LastName,
                EntityName: EntityName,
                Phone: Phone,
                PositionTitle: PositionTitle,
                Email: Email,
                UserStatusId: UserStatusId,
                SourceId: SourceId,
                Gender: Gender,
                IsPending: IsPending,
                DateOfBirth: DateOfBirth,
                IsDeleted: IsDeleted,
                UserRoles: UserRoles,
                EntityId: EntityId,
                EntityType: EntityType,
                TemporaryPassword: TemporaryPassword,
                ApplicationAssignedTo: ApplicationAssignedTo,
                LicenseTypeAssignedTo: LicenseTypeAssignedTo
            });
            return returnResult(data);
        };
        var _UsersDeletebyID = function(ID, key) {
            var data = $http.get(WebApiUrl + 'Users/UsersDeletebyID/' + key + '?ID=' + ID);
            return returnResult(data);
        };
        var _UpdateUser = function(key) {
            var data = $http.get(WebApiUrl + +key);
            return returnResult(data);
        };
        var _UsersGetBYID = function(key, ID) {
            var data = $http.get(WebApiUrl + 'Users/UsersGetBYID/' + key + '?ID=' + ID);
            return returnResult(data);
        };
        var _UserStatusGetAll = function(key) {
            var data = $http.get(WebApiUrl + 'Users/UserStatusGetAll/' + key);
            return returnResult(data);
        };
        var _UserTypeGetAll = function(key) {
            var data = $http.get(WebApiUrl + 'Users/UserTypeGetAll/' + key);
            return returnResult(data);
        };
        var _LookupGetBYLookupTypeID = function(key) {
            var data = $http.get(WebApiUrl + 'Lookup/LookupGetBYLookupTypeID/' + key + '?LookupTypeID=37');
            return returnResult(data);
        };
        var _RoleGetbyUserTypeId = function(UserTypeId, key) {
            var data = $http.get(WebApiUrl + 'Role/RoleGetbyUserTypeId/' + key + '?UserTypeId=' + UserTypeId);
            return returnResult(data);
        };
        var _SourceGetAll = function(key) {
            var data = $http.get(WebApiUrl + 'Source/SourceGetAll/' + key);
            return returnResult(data);
        };
        var _UserRoleGetBYUserID = function(UserId, key) {
            var data = $http.get(WebApiUrl + 'Users/UserRoleGetBYUserID/' + key + '?UserId=' + UserId);
            return returnResult(data);
        };
        var _ValidateEmailExistUser = function(Email, key, IndividualId) {
            var data = $http.get(WebApiUrl + 'User/ValidateEmailExistUser/' + key + '?Email=' + Email + '&IndividualId=' + IndividualId + '&ProviderId=0');
            return returnResult(data);
        };
        var _ValidateEmailExistForFirmUser = function(Email, key, ProviderId) {
            var data = $http.get(WebApiUrl + 'User/ValidateEmailExistUser/' + key + '?Email=' + Email + '&ProviderId=' + ProviderId + '&IndividualId=0');
            return returnResult(data);
        };
        var _ValidateUserNameExist = function(UserName) {

            var data = $http.get(WebApiUrl + 'User/ValidateUserNameExist?UserName=' + UserName);
            return returnResult(data);
        };
        var _CallActions = function(userid, userstatusid, key) {
            var data = $http.get(WebApiUrl + '/Users/UsersStatusSavebyID/' + key + '?ID=' + userid + '&userstatusid=' + userstatusid);
            return returnResult(data);
        };

        var _CheckExistingUserForRegistration = function(data, type) {
            var deferred = $q.defer();
            var url = '/User/CheckExistingUserForRegistration';
            if (type == 7)
                url = '/User/CheckExistingEntityUserForRegistration';

            $http.post(WebApiUrl + url, data).then(function(res) {
                deferred.resolve(res);
            }, function(res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };

        var _saveAndSendOTP = function(key, verificationRequest) {
            var data = $http.post(WebApiUrl + 'User/SaveAndSendOTP/' + key, verificationRequest);
            return returnResult(data);
        };

        var _saveAndSendOTPForFirms = function(key, verificationRequest) {
            var data = $http.post(WebApiUrl + 'User/SaveAndSendOTPForFirms/' + key, verificationRequest);
            return returnResult(data);
        };

        var _verifyOTPAndProcessContact = function(key, verificationRequest) {
            var data = $http.post(WebApiUrl + 'User/VerifyOTPAndProcessContact/' + key, verificationRequest);
            return returnResult(data);
        };

        var _verifyOTPAndProcessContactForFirms = function(key, verificationRequest) {
            var data = $http.post(WebApiUrl + 'User/VerifyOTPAndProcessContactForFirms/' + key, verificationRequest);
            return returnResult(data);
        };

        var returnResult = function(data) {
            var deferred = $q.defer();
            data
                .then(function(response) {
                    deferred.resolve(response.data);
                })
                .catch(function(response) {
                    deferred.reject(response);
                });
            return deferred.promise;
        };

        return {
            UsersSearch: _UsersSearch,
            UsersSave: _UsersSave,
            UsersDeletebyID: _UsersDeletebyID,
            UpdateUser: _UpdateUser,
            UsersGetBYID: _UsersGetBYID,
            UserStatusGetAll: _UserStatusGetAll,
            UserTypeGetAll: _UserTypeGetAll,
            RoleGetbyUserTypeId: _RoleGetbyUserTypeId,
            SourceGetAll: _SourceGetAll,
            UserRoleGetBYUserID: _UserRoleGetBYUserID,
            ValidateEmailExistUser: _ValidateEmailExistUser,
            ValidateEmailExistForFirmUser: _ValidateEmailExistForFirmUser,
            ValidateUserNameExist: _ValidateUserNameExist,
            CallActions: _CallActions,
            LookupGetBYLookupTypeID: _LookupGetBYLookupTypeID,
            CheckExistingUserForRegistration: _CheckExistingUserForRegistration,
            saveAndSendOTP: _saveAndSendOTP,
            saveAndSendOTPForFirms: _saveAndSendOTPForFirms,
            verifyOTPAndProcessContact: _verifyOTPAndProcessContact,
            verifyOTPAndProcessContactForFirms: _verifyOTPAndProcessContactForFirms 
        };
    }
})();