(function () {
    'use strict'
    angular.module('app.login').controller("LoginwoemailController", ["$scope", "$controller", "$cookies", "LoginwoemailService", "LicenseRenewalHASnAService", "$state","Messages",
        function ($scope, $controller, $cookies, LoginwoemailService, LicenseRenewalHASnAService, $state, Messages) {
            // Variables
            $scope.recordlist = [];
            $scope.Email = "";
            $scope.Password = "";
            // page init method
            $scope.init = function () {
                try {
                    sessionStorage.IsLicenseActive = undefined;
                    sessionStorage.PaymentDone = "false";
                    sessionStorage.LegalStatus = false;
                    if ($scope.isUserSessionActive()) {
                        sessionStorage.removeItem("Key");
                        sessionStorage.removeItem("Email");
                        sessionStorage.removeItem("UserId");
                    }
                }
                catch (ex) {
                    if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                }
            };

            // keep me login
            $scope.keepMeLoggedinWOE = function () {
                try {
                    if ($scope.KeepMeLoggedInWOE) {
                        var userdatawoe = {
                            LASTNAME: btoa($scope.LastName),
                            ACCESSCODE: btoa($scope.AccessCode),
                            LICENSENUMBER: btoa($scope.LicenseNumber)
                        }
                        var jsonuserdata = JSON.stringify(userdatawoe);
                        var date = new Date();
                        date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
                        $cookies.put('USERAUTHWOE', jsonuserdata, {
                            expires: date.toUTCString()
                        });

                    } else {
                        $cookies.remove('USERAUTHWOE', '');
                    }
                }
                catch (ex) {
                    if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                }
            }

            // Login User
            $scope.login = function () {
                try {
                    if (validateData('loginwoemailvalidation', 1)) {
                        ShowLoader("Validating...");
                        var LastName = $scope.LastName;
                        var AccessCode = $scope.AccessCode;
                        var LicenseNumber = $scope.LicenseNumber;
                        var LoginWithoutEmail = true;
                        LoginwoemailService.Login(LastName, AccessCode, LicenseNumber, LoginWithoutEmail)
                            .then(function (res) {
                                var response = res.data;
                                // console.log(response);
                                $scope.recordlist = response;
                                if ($scope.checkServerResponse(response)) {
                                    $scope.Key = response.Key;
                                    $scope.keepMeLoggedinWOE();
                                    sessionStorage.Key = $scope.Key;
                                    sessionStorage.UserId = response.UserID;
                                    sessionStorage.TempPsw = response.UserInfo.TemporaryPassword;
                                    $scope.serverDate = LicenseRenewalHASnAService.GetServerDate().then(function (res) {
                                        sessionStorage.curDate = res.data;
                                        if ($scope.recordlist.UserInfo.UserTypeName == "Licensee") {
                                            sessionStorage.Key = $scope.Key;
                                            sessionStorage.Email = $scope.Email;
                                            sessionStorage.UserId = $scope.recordlist.IndividualID;
                                            sessionStorage.IndividualId = $scope.recordlist.IndividualID;
                                            $state.go('app.LicenseRenewalApplication');
                                        } else {
                                            console.log('else');
                                            $scope.showStatusMessage(Messages.General.Errors.CheckInfo, "error");
                                            $scope.AccessCode = "";
                                            $scope.LicenseNumber = "";
                                        }
                                    });
                                }
                                else {
                                    $scope.showStatusMessage(Messages.General.Errors.CheckInfo, "error");
                                    $scope.AccessCode = "";
                                    $scope.LicenseNumber = "";
                                }
                                HideLoader();
                            }, function (res) {
                                var data = res.data;
                                HideLoader();
                                if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                            });
                    } else {
                        $scope.statusMessage = {};
                    }
                }
                catch (ex) {
                    HideLoader();
                    $scope.showStatusMessage(ex.message, "error");
                }
            };

            // reset access code
            $scope.resetAccessCode = function () {
                try {
                    if (validateData('loginwoemailvalidation')) {
                        ShowLoader("Please wait...");
                        var LastName = $scope.LastName;
                        var SSN = $scope.AccessCode;
                        var LicenseNumber = $scope.LicenseNumber;
                        LoginwoemailService.ResetAccessCode(LastName, SSN, LicenseNumber, sessionStorage.key)
                            .then(function (res) {
                                var response = res.data;
                                if ($scope.checkServerResponse(response)) {
                                    $scope.showStatusMessage(response.Message, "success");
                                }
                                else {
                                    $scope.showStatusMessage(Messages.General.Errors.InvalidUserPass, "error");
                                    $scope.Password = "";
                                }
                            },function (res) {
                                var data = res.data;
                                if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                            });
                    }
                }
                catch (ex) {
                    $scope.showStatusMessage(ex.message, "error");
                }
                HideLoader();
            };

            // Get UserDetail if Keep me Login Active
            var UserDetails = $cookies.get('USERAUTHWOE');
            if (UserDetails) {
                var user = angular.fromJson(UserDetails);
                $scope.LastName = atob(user.LASTNAME);
                $scope.AccessCode = atob(user.ACCESSCODE);
                $scope.LicenseNumber = atob(user.LICENSENUMBER);
                $scope.KeepMeLoggedInWOE = true;
            }
        }]);
})();