(function () {
    'use strict';

    angular
        .module('app.provider')
        .controller("CeCourseRenewalController", CeCourseRenewalController);

    CeCourseRenewalController.$inject = ["$rootScope", "$scope", "$state", "bppService", "RegisterFactory",
        "countryStateService", "initialApplicationstatusService", "typeValuesService", "lookupService", "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl", "$q", "htmlToPdfGenerationService", "initialProviderApplicationFeePaymentService", "Messages",
    ];

    function CeCourseRenewalController($rootScope, $scope, $state, bppService, RegisterFactory,
        countryStateService, initialApplicationstatusService, typeValuesService, lookupService, utilityService, $exceptionHandler, TypeValue, WebApiUrl, $q, htmlToPdfGenerationService, initialProviderApplicationFeePaymentService, Messages) {
        if (!$scope.isBackoffice)
            $scope.providerId = sessionStorage.ProviderId;
        var userId = ($scope.isBackoffice ? sessionStorage.UserID : sessionStorage.UserId);
        $scope.userId = userId;
        $scope.backOfficeOrOnline = ($scope.isBackoffice ? 'B' : 'O');
        var applicationTypeId = 11; // applicationTypeId = 11 for renewal of ce course
        //$scope.addEditMode1 = false;
        $scope.viewConfig = {};
        $scope.natValidation = {};

        var init = function () {
            $scope.datePicker = {
                fromDateOpened: false,
                toDateOpened: false,
                format: 'M!/d!/yyyy',
                endDateOptions: {
                    minDate: null
                },
                startDateOptions: {
                }
            };
            $scope.providerData = {
                ceCourseList: {},
                addEditMode1: false
            };
            $scope.enableCourseDirective = false;
            $scope.natValidation = {};
            if ((!$scope.currentRenewalItem && (!!$scope.providerCECourse && !$scope.providerCECourse.ApplicationId)) && !!$scope.providerId) {
                loadProviderApplication(sessionStorage.Key, $scope.providerId, applicationTypeId);
            } else {
                var applicationId = null;
                if (!!$scope.currentRenewalItem && !!$scope.currentRenewalItem.ApplicationId)
                    applicationId = $scope.currentRenewalItem.ApplicationId;
                else if (!!$scope.providerCECourse && !!$scope.providerCECourse.ApplicationId)
                    applicationId = $scope.providerCECourse.ApplicationId
                else
                    applicationId = 0;
                loadApplicationByApplicationId(sessionStorage.Key, applicationId);
            }

            $scope.feeDetails = {
                renewalPeriod: 'Renewal Period from 01/01/2018 to 12/31/2018',
                feeType: 'Course Renewal Fee',
                courseFee: 50,
                lateFee: '0',
                isOverridePaymentAmount: false
            };
            $scope.totalCourses = 1;
        };
        $scope.setLicenseToMinDate = function () {
            if (angular.isDate($scope.viewConfig.CourseEffectiveDate)) {
                $scope.datePicker.endDateOptions.minDate = $scope.viewConfig.CourseEffectiveDate;
            }
        };

        $scope.setLicenseFromMaxDate = function () {
            if (angular.isDate($scope.viewConfig.CourseExpirationDate)) {
                $scope.datePicker.startDateOptions.maxDate = $scope.viewConfig.CourseExpirationDate;
            }
        };

        var processFeeDetails = function () {
            var feeDetailData = {};
            angular.forEach($scope.feeDetailRows, function (feeDetailItem) {
                feeDetailData["feeType" + feeDetailItem.FeeDetailId] = feeDetailItem;
            });

            var ceCourseAppFeeDetail = feeDetailData.feeType28;
            var ceCourseREFeeDetail = feeDetailData.feeType13;

            $scope.feeDetails.ceCourseAppFee = ceCourseAppFeeDetail.FeeAmount;
            $scope.feeDetails.totalFee = ceCourseAppFeeDetail.FeeAmount;
            $scope.feeDetails.ceCourseAppFeeDetailCode = ceCourseAppFeeDetail.FeeDetailCode;
            $scope.feeDetails.ceCourseAppFeeDetailDesc = ceCourseAppFeeDetail.FeeDetailDesc;
            $scope.feeDetails.ceCourseAppFeeFundingString = ceCourseAppFeeDetail.FundingString;

            $scope.feeDetails.ceCourseREFee = ceCourseREFeeDetail.FeeAmount;
            $scope.feeDetails.totalFee = ceCourseREFeeDetail.FeeAmount;
            $scope.feeDetails.ceCourseREFeeDetailCode = ceCourseREFeeDetail.FeeDetailCode;
            $scope.feeDetails.ceCourseREFeeDetailDesc = ceCourseREFeeDetail.FeeDetailDesc;
            $scope.feeDetails.ceCourseREFeeFundingString = ceCourseREFeeDetail.FundingString;

            $scope.feeDetails.associationType = 'CR';
            $scope.feeDetails.Module = 'CeCourseRenewal';
        };

        var getFeeDetails = function () {
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=feedetails")).then(function (responseFee) {
                if (!!responseFee.Status) {
                    $scope.feeDetailRows = responseFee.ReferenceEntities;
                    processFeeDetails();
                    // processFeeStructure();
                    initialProviderApplicationFeePaymentService.getPaymentDetailsByApplicationIdAndIndividualId(sessionStorage.Key, $scope.applicationInfo.ApplicationId, $scope.providerId).then(function (objPaymentDetail) {
                        if (objPaymentDetail.StatusCode != "00") {
                            $scope.showStatusMessage(objPaymentDetail.Message, 'error');
                        }
                        if (!!objPaymentDetail.PaymentDetails && objPaymentDetail.PaymentDetails.length > 0) {
                            $scope.feeDetails.applicationFee = objPaymentDetail.PaymentDetails[0].AmountBilled;
                            $scope.feeDetails.totalFee = objPaymentDetail.PaymentDetails[0].AmountDue;
                        }
                    }, function (error) {
                        HideLoader();
                        $scope.showStatusMessage(error.Message, 'error');
                        return;
                    });
                }
            }, function (error) {
                showStatusMessage(error, "error");
            });
        };

        var verifyCECourseForProvider = function (providerId, applicationId) {
            ShowLoader("Please Wait...");
            var dataSavePromise = bppService.providerCECourseByProviderId(providerId, "", sessionStorage.Key, applicationId);
            dataSavePromise.then(function (response) {
                var foundRenewalCourse = false;
                var providerCECourses = response.ProviderCourseResponseList;
                angular.forEach(providerCECourses, function (providerCECourseItem) {
                    if (providerCECourseItem.ProviderCourseId == $scope.providerCECourse.ProviderCourseId) {
                        foundRenewalCourse = true;
                    }
                });
                if (!foundRenewalCourse) {
                    generateApplicationId(0, userId, providerId, applicationTypeId);
                }
                else {
                    getFeeDetails();
                    $scope.viewConfig.loadSignInInfo = true;
                }
                HideLoader();
            }, function (error) {
                $scope.showStatusMessage({
                    res: error,
                    msg: "error"
                });
            });
            return dataSavePromise;
        };

        var providerCECourseByProviderId = function (providerId, applicationId) {
            ShowLoader("Please Wait...");
            var todaysDate = new Date();
            var dataSavePromise = bppService.providerCECourseByProviderId(providerId, "", sessionStorage.Key, applicationId);
            dataSavePromise.then(function (response) {
                var providerCECourses = response.ProviderCourseResponseList;
                $scope.providerData.ceCourseList = providerCECourses;
                if (providerCECourses != null && (providerCECourses.length > 0)) {
                    $scope.enableCourseDirective = true;
                    $scope.viewConfig.CourseExpirationDate = providerCECourses[0].CourseExpirationDate;
                    $scope.viewConfig.CourseEffectiveDate = providerCECourses[0].CourseEffectiveDate;
                }
                HideLoader();

            }, function (error) {
                $scope.showStatusMessage({
                    res: error,
                    msg: "error"
                });
            });
            return dataSavePromise;
        };

        $scope.loadPayment = function () {
            $scope.viewConfig.loadPayment = true;
        };

        var loadApplicationByApplicationId = function (key, applicationId) {
            bppService.applicationByApplicationId(key, applicationId, userId).then(function (response) {
                if (response.Status) {
                    $scope.applicationInfo = response.ApplicationResponseList[0];
                    getFeeDetails();
                    providerCECourseByProviderId($scope.providerId, applicationId);
                    if ($scope.applicationInfo.ApplicationStatusId == 2 || $scope.applicationInfo.ApplicationStatusId == 3 || $scope.applicationInfo.ApplicationStatusId == 15) {
                        $scope.viewConfig.loadPayment = true;
                    }
                    $scope.viewConfig.loadSignInInfo = true;
                }
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage(error, 'error');
            });
        };

        var loadProviderApplication = function (key, providerId, applicationTypeId) {
            // ShowLoader();
            // bppService.applicationByProviderId(key, providerId).then(function (response) {
            //     if (response.Status) {
            //         var pendingApplication;
            //         angular.forEach(response.ApplicationResponseList, function (application) {
            //             if (application.ApplicationTypeId == applicationTypeId && (application.ApplicationStatusId == 1 || application.ApplicationStatusId == 14)) {
            //                 pendingApplication = application;
            //                 $scope.IsPendingApplication = true;
            //             }
            //         });
            //         $scope.applicationInfo = pendingApplication;
            //         if (!pendingApplication) {
            generateApplicationId(0, userId, providerId, applicationTypeId);
            // } else {
            //     if (!!$scope.providerCECourse && !!$scope.providerCECourse.CourseId) {
            //         verifyCECourseForProvider($scope.providerId, $scope.applicationInfo.ApplicationId);
            //     }
            //     else {
            // getFeeDetails();
            // providerCECourseByProviderId($scope.providerId, $scope.applicationInfo.ApplicationId);
            //                 // $scope.viewConfig.loadSignInInfo = true;
            //             }
            //         }
            //     }
            // }, function (error) {
            //     HideLoader();
            //     $scope.showStatusMessage(error, 'error');
            // });
        };

        $scope.submitApplicationForm = function () {
            $scope.saveRecord();
        };

        var changeCourseStatus = function (ceCourseList) {
            angular.forEach(ceCourseList, function (ceCourse) {
                if (ceCourse.CourseStatus == "Pending Renewal" || ceCourse.CourseStatusId == 2) {
                    ceCourse.CourseStatus = "Pending Approval";
                    ceCourse.CourseStatusId = 1;
                    ceCourse.IsRenewalCourseUpdate = false;
                }
                else if (ceCourse.CourseStatus == "Pending Approval" || ceCourse.CourseStatusId == 1) {
                    ceCourse.CourseStatus = "Current";
                    ceCourse.CourseStatusId = 3;
                    ceCourse.IsRenewalCourseUpdate = true;
                    ceCourse.CourseExpirationDate = $scope.viewConfig.CourseExpirationDate;
                    ceCourse.CourseEffectiveDate = $scope.viewConfig.CourseEffectiveDate;
                    ceCourse.CourseApprovalDate = $scope.viewConfig.CourseEffectiveDate;
                }
            });
            return ceCourseList;
        }

        var updateCECourses = function (ceCourseList, applicationId) {
            bppService.providerCECourseSave(ceCourseList,
                sessionStorage.Key).then(function (response) {
                    if ($scope.checkServerResponse(response)) {
                        loadApplicationByApplicationId(sessionStorage.Key, applicationId);
                        // $scope.getAllLicenseeRenewalList();
                        // $scope.showStatusMessage(Messages.aefcaee, "success");
                    }
                    HideLoader();
                }, function (error) {
                    HideLoader();
                    $scope.showStatusMessage(error, "error");
                });
        };

        $scope.approveApplicationForm = function () {
            var updatedCourseList = changeCourseStatus($scope.providerData.ceCourseList);
            var providerCourseStatusUpdatePromise = bppService.providerCECourseSave(updatedCourseList, sessionStorage.Key);

            //updateCECourses($scope.providerData.ceCourseList);
            var applicationSubmitPromise = bppService.applicationStatusUpdate(sessionStorage.Key, $scope.applicationInfo.ApplicationId, 3, 'B');
            var applicationSubmitLkPromise = saveApplicationStatusLk($scope.applicationInfo.ApplicationId, 3);
            var applicationSubmitChangeLogPromise = saveApplicationStatusChangeLog($scope.applicationInfo.ApplicationId, 3);
            $q.all(applicationSubmitPromise, applicationSubmitLkPromise, applicationSubmitChangeLogPromise, providerCourseStatusUpdatePromise).then(function (response) {
                //bppService.applicationStatusUpdate(sessionStorage.Key, $scope.applicationInfo.ApplicationId, 3, 'B').then(function (response) {
                $scope.getAllLicenseeRenewalList();
                console.log('status updated to approved');
            });
        };

        var saveApplicationStatusLk = function (applicationId, applicationStatus) {
            var reqData = {
                ApplicationId: applicationId,
                ApplicationTypeId: 11,
                ApplicationStatusId: applicationStatus,
                ApplicationStatusReasonId: 0,
                IsActive: true,
                IsDeleted: false,
                IsArchive: false,
                CreatedBy: $scope.userId,
                ApplicationInternalStatusReasonText: '',
                ApplicationInternalStatusId: 0,
                HighPriority: false
            };
            return initialApplicationstatusService.saveApplicationStatusLk(sessionStorage.Key, $scope.userId, reqData);
        };

        var saveApplicationStatusChangeLog = function (applicationId, applicationStatus) {
            var reqData = {
                ApplicationId: applicationId,
                ApplicationStatusReasonId: 0,
                ApplicationNumber: '',
                ApplicationTypeId: 11,
                ApplicationStatusId: applicationStatus,
                ApplicationSubmitMode: ($scope.isBackoffice ? 'B' : 'O'),
                ApplicationStatusChangedBy: $scope.userId,
                ReferenceNumber: '',
                LicenseTypeId: 0,
                HighPriority: false,
                ApplicationInternalStatusId: 0,
                ApplicationInternalStatusReasonText: ''
            };
            return initialApplicationstatusService.saveApplicationStatusChangeLog(sessionStorage.Key, $scope.userId, reqData);
        };

        $scope.saveRecord = function () {
            $scope.PayandSubmitbtnClicked = false;
            $scope.$broadcast("PayandSubmit", {});
            var paymentStatus = '';
            initialApplicationstatusService.resetAll();
            var interval = setInterval(function () {
                paymentStatus = initialApplicationstatusService.getPaymetStatus();
                if (!!paymentStatus && paymentStatus != "waiting") {
                    clearInterval(interval);
                    $rootScope.ProcessLoaderExternally = false;
                    if (paymentStatus == "success") {
                        var reqObj = initialApplicationstatusService.getEmailAndLogCommunication();
                        reqObj.ProviderId = $scope.providerId;
                        reqObj.ApplicationId = $scope.applicationInfo.ApplicationId;
                        reqObj.PullTabHtmlForPdf = false;
                        reqObj.CreateApplicationPdf = true;
                        reqObj.SendSubmissionEmail = true;
                        reqObj.SendSubmissionSms = true;

                        var updatedCourseList = changeCourseStatus($scope.providerData.ceCourseList);
                        var providerCourseStatusUpdatePromise = bppService.providerCECourseSave(updatedCourseList, sessionStorage.Key);

                        var applicationStatus = ($scope.isBackoffice ? 15 : 2);
                        var applicationSubmitPromise = bppService.applicationStatusUpdate(sessionStorage.Key, $scope.applicationInfo.ApplicationId, applicationStatus, $scope.backOfficeOrOnline);
                        var applicationSubmitLkPromise = saveApplicationStatusLk($scope.applicationInfo.ApplicationId, applicationStatus);
                        var applicationSubmitChangeLogPromise = saveApplicationStatusChangeLog($scope.applicationInfo.ApplicationId, applicationStatus);
                        $q.all(applicationSubmitPromise, applicationSubmitLkPromise, applicationSubmitChangeLogPromise, providerCourseStatusUpdatePromise).then(function (responseAppSubmitted) {

                            //bppService.applicationStatusUpdate(sessionStorage.Key, $scope.applicationInfo.ApplicationId, 2, 'O').then(function (response) {
                            htmlToPdfGenerationService.processFirmRenewalHtml().then(function (response) {
                                reqObj.TabHtml = {
                                    ApplicationID: $scope.applicationInfo.ApplicationId,
                                    IndividualID: $scope.IndividualId,
                                    TabName: 'RenewalCompleteForm',
                                    CreatedBy: $scope.UserId,
                                    DataDesc: "Renewal Complete Form Html including the css and all sections",
                                    HTMLContent: response,
                                    SortingOrder: 1
                                };
                                // initialApplicationstatusService.submitApplication(sessionStorage.Key, reqObj)
                                //     .then(function (objEmailAndLogCommunication) {
                                //         if (objEmailAndLogCommunication.StatusCode != "00") {
                                //             return showErrorMessage(objEmailAndLogCommunication.Message);
                                //         }
                                if (!$scope.isBackoffice) {
                                    $state.go('app.PaymentConfirmation', {
                                        paymentMethod: 3,
                                        applicationType: $scope.applicationInfo.ApplicationTypeId
                                    });
                                }
                                else {
                                    //loadApplicationByApplicationId(sessionStorage.Key, $scope.applicationInfo.ApplicationId);
                                    $scope.applicationInfo.ApplicationStatusId = 15;
                                    providerCECourseByProviderId($scope.providerId, $scope.applicationInfo.ApplicationId);
                                }
                                // }, function (response) {
                                //     return showErrorMessage(response);
                                // });
                            });
                        });
                    }
                    if (paymentStatus == "error") {
                        HideLoader();
                    }
                }
            }, 500);
        };

        $scope.ProceedtoPaymentclick = function () {
            $scope.ProceedtoPaymentSectionEnable = true;
        };

        $scope.PayandSubmitbtnClick = function () {
            $rootScope.ProcessLoaderExternally = true;
            $scope.PayandSubmitbtnClicked = true;
            $scope.saveRecord();
        };

        var generateApplicationId = function (applicationId, userId, providerId, applicationTypeId) {
            var urlparm = '/' + sessionStorage.Key + '?ApplicationId=' + applicationId + '&UserId=' + userId;
            var reqData = {
                ApplicationTypeId: applicationTypeId,
                ApplicationStatusId: ($scope.isBackoffice ? 14 : 1),
                ApplicationSubmitMode: ($scope.isBackoffice ? 'B' : 'O'),
                GoPaperless: true,
                IsActive: true
            };

            RegisterFactory.GenerateApplicationId(urlparm, reqData).then(function (response) {
                if (response.data.Status) {
                    saveProviderApplication(providerId, response.data.ApplicationId, userId);
                }
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage(error, 'error');
            });
        };

        var createPendingRecord = function (providerCECourse, applicationId) {
            var todaysDate = new Date();
            providerCECourse.ProviderCourseId = 0;
            providerCECourse.ApplicationId = applicationId;
            providerCECourse.CourseEffectiveDate = providerCECourse.CourseExpirationDate;
            providerCECourse.CourseApprovalDate = providerCECourse.CourseEffectiveDate;
            if (providerCECourse.CourseTypeId == 1) {
                providerCECourse.CourseExpirationDate = todaysDate.addYears(2);
            }
            if (providerCECourse.CourseTypeId == 2) {
                providerCECourse.CourseExpirationDate = todaysDate.addYears(3);
            }
            providerCECourse.CourseStatus = "Pending Renewal";
            providerCECourse.CourseStatusId = 2;
            updateCECourses([providerCECourse], applicationId);
        };

        var saveProviderApplication = function (providerId, applicationId, userId) {
            var reqData = {
                ApplicationId: applicationId,
                providerId: providerId,
                CreatedBy: userId
                // CreatedOn: new Date()
            };
            RegisterFactory.saveProviderApplication(sessionStorage.Key, reqData).then(function (response) {
                if (response.data.Status) {
                    createPendingRecord($scope.providerCECourse, applicationId);
                } else {
                    HideLoader();
                }
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage(error, 'error');
            });
        };

        $scope.closeApplicationForm = function () {
            $scope.currentRenewalItem = null;
            if (angular.isFunction($scope.CancelAddClick)) {
                $scope.CancelAddClick();
            }
            else {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    $scope.closeRenewalApp();
                }
            }
        };

        init();
    }

})();