/// <reference path="../../../scripts/built-in-type-extensions.ts" />
/// <reference path="query-reporting-doc.ts" />

(function() {
    'use strict'
    
    angular.module('app.report')
    .factory('templateMessageService', [function () {
        //This factory exists because some versions of the software have a capitalized name, and some have a lower-case name.
        //To resolve this dependency issue, I have created this factory to act as an alias for either, allowing me to keep
        //the same code despite which version is being used.
        if (angular.element(document.body).injector().has('TemplateMessageFactory')){
            return angular.element(document.body).injector().get('TemplateMessageFactory');
        } else if (angular.element(document.body).injector().has('templateMessageFactory')) {
            return angular.element(document.body).injector().get('templateMessageFactory');
        } else {
            return null;
        }
    }])
    .controller('QueryReportingBulkEmailDialogController', ['$scope', '$uibModalInstance', '$q', '$timeout', 'templateMessageService', 'QueryReportingService', 'QueryReportingUtility', 'parameters', QueryReportingBulkEmailDialogController]);

    function QueryReportingBulkEmailDialogController($scope, $uibModalInstance, $q, $timeout, templateMessageService, QueryReportingService, QueryReportingUtility, parameters) {
        var _key = '';

        /**
         * 
         */
        var getAllEmailTemplates = function () {
            var deferred = $q.defer();
            try {
                templateMessageService.GetAllTemplates()
                .then(function (data) {
                    //We only want Email templates
                    $scope.templateList = data.filter(function (t) { return t.TemplateTypeId == 1; });
                    deferred.resolve(data);
                }, function (error) {
                    if (error) { $scope.errorMessage = error; }
                    deferred.reject(error);
                });
            } catch(ex) {
                if (ex) { $scope.errorMessage = ex.message; }
            }
            return deferred.promise;
        };

        /**
         * 
         * @param {ExecuteQuery} query 
         * @returns {QueryColumn[]}
         */
        var getColumnList = function (query) {
            /** @type {QueryColumn[]} */
            var columnList = [];

            if(query.From) {
                query.From.table.ColumnList.forEach(function (c) {
                    var rel = query.From;
                    var column = angular.copy(c);
    
                    column.TableAlias = rel.TableAlias;
                    column.TableName = rel.TableName;
                    column.TableDisplayName = rel.TableDisplayName;
    
                    columnList.push(column);
                });
                    
                query.Join.forEach(function (rel) {
                    if(rel.table) {
                        rel.table.ColumnList.forEach(function (c) {
                            var column = angular.copy(c);
    
                            column.TableAlias = rel.RelationTableAlias;
                            column.TableName = rel.RelationTableName;
                            column.TableDisplayName = rel.RelationTableDisplayName;

                            columnList.push(column);
                        });
                    }
                });
            }

            return columnList;
        };

        /**
         * 
         * @param {ExecuteQuery} query 
         * @returns {QueryTableRelation[]}
         */
        var getTableList = function (query) {
            var tables = [];

            if (query.From && query.From) {
                tables.push(query.From);
            }

            query.Join.forEach(function (j) {
                tables.push(j);
            });

            return tables;
        };

        $scope.init = function () {
            _key = sessionStorage.Key;

            //Dialog data
            $scope.expressionList = parameters.expressionList;
            $scope.processedTables = parameters.processedTables;
            $scope.currentQuery = parameters.currentQuery;
            $scope.columnExpressionList = getColumnList($scope.currentQuery).concat(parameters.expressionList);
            $scope.individualTableList = getTableList($scope.currentQuery).filter(function (t) { return t.RelationTableName === 'individual'; });
            $scope.templateList = [];
            
            $scope.columnExpressionList.forEach(function (col) { 
                col.TypeaheadDisplayName = col.TableAlias + ':' + col.TableDisplayName + ' ' + col.FieldDisplayName;
            });

            if($scope.individualTableList.length == 0) {
                $scope.errorMessage = 'You must select the "Individual" table in this query to use the Bulk Email operation.'
                return;
            }

            //Dialog fields to fill in
            $scope.individualTable = ($scope.individualTableList.length == 1) ? $scope.individualTableList[0] : null;
            $scope.emailColumn = null;
            $scope.templateMessageParams = [];
            $scope.template = null;

            //Dialog messages
            $scope.infoMessage = 'All queries made with the bulk email operation will automatically have "Distinct Rows?" set to true.';
            $scope.errorMessage = '';

            $q.all([getAllEmailTemplates()]).then(function () {
                $scope.operationInProgress(false);
            }, function() {
                $scope.operationInProgress(false);
            });
        };

        $scope.deleteParam = function (array, i) {
            array.splice(i, 1);
        };

        $scope.addParam = function (newName) {
            if(!newName) {
                newName = 'NewParam';
            }

            $scope.templateMessageParams.push({
                Name: newName,
                Column: null,

                showNameEditor: false
            });
        };  

        $scope.templateSelected = function ($item, $model, $label, $event) {
            $scope.templateMessageParams = [];
            
            var regex = /#{1}([a-zA-Z]+)#{1}/g;

            var params = $scope.template.TemplateMessage.match(regex);

            params.forEach(function(p) {
                $scope.addParam(p.replace(/#/g, ''));
            });
        };

        $scope.closeEditor = function (event, messageParam) {
            if(event) {
                if(event.which == 13) {
                    messageParam.showNameEditor = false;
                }
            }
        };

        $scope.clearTemplate = function () {
            $scope.template = null;
            $scope.templateMessageParams = [];
        };

        $scope.validateDialog = function () {
            return $scope.individualTable && typeof($scope.individualTable) != 'string' && 
                   $scope.emailColumn && $scope.template && 
                   $scope.templateMessageParams.every(function (p) { return p.Column }) ;
        };

        $scope.executeBulkEmailOnQuery = function () {
            if (!$scope.individualTable || typeof($scope.individualTable) == 'string') {
                $scope.errorMessage = 'You must define a reference to the "Individual" table in this query.'
                return;
            }

            if (!$scope.emailColumn) {
                $scope.errorMessage = 'You must define a column that will contain the email addresses for this operation.';
                return;
            }

            $scope.currentQuery.IndividualTable = $scope.individualTable;
            $scope.currentQuery.EmailColumn = $scope.emailColumn;
            $scope.currentQuery.BulkEmailSelect = $scope.templateMessageParams;
            $scope.currentQuery.TemplateId = $scope.template.TemplateId;

            $scope.currentQuery.Select = [$scope.emailColumn].concat($scope.templateMessageParams.select('Column'));

            QueryReportingUtility.removeUnusedTables($scope.currentQuery);

            if ($scope.currentQuery.From) {
                $scope.reportData = [];
                var reqData = angular.copy($scope.currentQuery);
                QueryReportingUtility.prepareQueryForSerialization(reqData);

                reqData.IsDistinct = 1;

                $scope.operationInProgress(true);
                QueryReportingService.executeBulkEmailOnQuery(_key, reqData)
                .then(function (data) {
                    if (data.Status) {
                        $scope.errorMessage = data.ErrorMessages;
                        if(data.ErrorMessages.length == 0) {
                            $scope.successMessage = 'All emails sent successfully!';
                        } else {
                            $scope.infoMessage = 'Some emails did not get sent.  Check the error below for a list of the issues.';
                        }
                    } else if (!data.Status && data.Message) {
                        $scope.errorMessage = data.Message;
                    }
                    $scope.operationInProgress(false);
                }, function (err) {
                    if (err && err.statusText) {
                        $scope.errorMessage = err.statusText;
                    }
                    $scope.operationInProgress(false);
                });
            }
        };

        $scope.cancelDialog = function () {
            $uibModalInstance.dismiss();
        };
    };
})();