(function () {
    'use strict'

    angular.module('app.compliance')
    .directive('complianceSearchLogic', [ComplianceSearchLogic]);

    function ComplianceSearchLogic() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {

            },
        };
    }
})();