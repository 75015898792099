(function () {
    'use strict';

    angular
        .module('app.provider')
        .controller("CeCourseProviderController", CeCourseProviderController);

    CeCourseProviderController.$inject = ["$scope", "$rootScope", "$state", "bppService", "ProviderMultipleFileUploadService", "initialApplicationstatusService",
        "countryStateService", "typeValuesService", "lookupService", "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl", "$q", "Messages"
    ];

    function CeCourseProviderController($scope, $rootScope, $state, bppService, ProviderMultipleFileUploadService, initialApplicationstatusService,
        countryStateService, typeValuesService, lookupService, utilityService, $exceptionHandler, TypeValue, WebApiUrl, $q, Messages) {

        $scope.providerLicense = {};
        $scope.ceCourseDocumentUploadPurpose = {
            ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
            // ApplicationId: $scope.applicationId,
            ProviderId: $scope.providerId,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "CE",
            LoadByDocIdAndDocCode: true,
            DocId: 58,
            DocCode: "D1058",
            LoadDocumentsWithoutBroadCast: false,
            LinkType: 'PR',
            PropertyToProcess: 'ProviderCourseId',
            IsLinkedDocControl: true
        };

        $scope.ceCourseDocumentUploadAttMonitor = {
            ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
            // ApplicationId: $scope.applicationId,
            ProviderId: $scope.providerId,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "CE",
            LoadByDocIdAndDocCode: true,
            DocId: 59,
            DocCode: "D1059",
            LoadDocumentsWithoutBroadCast: false,
            LinkType: 'AM',
            PropertyToProcess: 'ProviderCourseId',
            IsLinkedDocControl: true
        };

        $scope.ceCourseDocumentUploadCourseMaterial = {
            ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
            // ApplicationId: $scope.applicationId,
            ProviderId: $scope.providerId,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "CE",
            LoadByDocIdAndDocCode: true,
            DocId: 60,
            DocCode: "D1060",
            LoadDocumentsWithoutBroadCast: false,
            LinkType: 'CM',
            PropertyToProcess: 'ProviderCourseId',
            IsLinkedDocControl: true
        };

        var init = function () {
            $scope.viewConfig = {};
            ShowLoader();

            var dataSavePromise = [];
            dataSavePromise.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=cecoursetype")));
            dataSavePromise.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=coursefor")));
            dataSavePromise.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=cecoursecategory")));
            $q.all(dataSavePromise)
                .then(function (response) {
                    $scope.ceCourseTypes = response[0].ReferenceEntities;
                    $scope.coursefor = response[1].ReferenceEntities;
                    $scope.courseCategoryList = response[2].ReferenceEntities;
                    $scope.providerCECourseByProviderId($scope.providerId, true, true);
                }, function (error) {
                    $scope.showStatusMessage(error, "error");
                });
            HideLoader();
            return dataSavePromise;
        };

        var ShowErrMsg = function (propName, errorMessage) {
            propName = (!!propName) ? propName : "personalErr";
            errorMessage = (!!errorMessage) ? errorMessage : $scope.personalConfig.ValidationMsg.APIError;
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            var propExist = false;
            for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                if ($scope.natValidation.errorMessages[i].propName == propName)
                    propExist = true;
            }
            if (!propExist)
                $scope.natValidation.errorMessages.push({
                    propName: propName,
                    errorMessage: errorMessage
                });

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        var validateForm = function (currentForm, isFinalSave) {

            if (!currentForm.errorMessages)
                currentForm.errorMessages = [];
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            // if ($scope.personalData.ResidentialAddress.IsMailingSameasPhysical && $scope.personalData.EmployerAddress.IsMailingSameasPhysical) {
            //     var errorObj = {
            //         propName: "EitherHomeOrEmployerCanBeMailing",
            //         errorMessage: $scope.personalConfig.ValidationMsg.EitherHomeOrEmployerCanBeMailing
            //     };
            //     $scope.natValidation.errorMessages.push(errorObj);
            // }
            if (($scope.ceCourseDocumentUploadAttMonitor.DocumentUploadList.length < 1) && (!$scope.providerCeCourse.AttendanceMonitoring)) {
                $scope.natValidation.errorMessages.push({
                    propName: 'AtLeastOneDEANUmberReq',
                    errorMessage: Messages.General.Errors.UploadEither('Attendance/Monitoring'),
                });
            }

            if (($scope.ceCourseDocumentUploadPurpose.DocumentUploadList.length < 1) && (!$scope.providerCeCourse.Purpose)) {
                $scope.natValidation.errorMessages.push({
                    propName: 'AtLeastOneDEANUmberReq',
                    errorMessage: Messages.General.Errors.UploadEither('Purpose'),
                });
            }

            if ($scope.ceCourseDocumentUploadCourseMaterial.DocumentUploadList.length < 1) {
                $scope.natValidation.errorMessages.push({
                    propName: 'AtLeastOneDEANUmberReq',
                    errorMessage: Messages.General.Errors.UploadDocFor('Course Material')
                });
            }

            if (isFinalSave) {
                var errorObj = {
                    propName: "saveAndProceed",
                    errorMessage: Messages.General.Errors.SaveOpenForm
                };
                $scope.natValidation.errorMessages.push(errorObj);
            }
            // if ((!$scope.personalData.CellPhoneContact || !$scope.personalData.CellPhoneContact.ContactInfo) &&
            //     (!$scope.personalData.HomePhoneContact || !$scope.personalData.HomePhoneContact.ContactInfo)) {
            //     var errorObj = {
            //         propName: "EitherCellPhoneOrHomePhoneRequired",
            //         errorMessage: $scope.personalConfig.ValidationMsg.EitherCellPhoneOrHomePhoneRequired
            //     };
            //     $scope.natValidation.errorMessages.push(errorObj);
            // }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                initialApplicationstatusService.setCeCourseFormStatus(false);
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.setSaveDocumentRef1 = function (fn) {
            $scope.saveAllDocumentRef1 = fn;
        };

        $scope.setSaveDocumentRef2 = function (fn) {
            $scope.saveAllDocumentRef2 = fn;
        };

        $scope.setSaveDocumentRef3 = function (fn) {
            $scope.saveAllDocumentRef3 = fn;
        };

        $scope.downloadDocumentAPIURL = WebApiUrl + 'Individual/DownloadPDF/' + sessionStorage.Key + '?path=';
        $scope.downloadDocument = function (path) {
            window.open($scope.downloadDocumentAPIURL + path, '_blank');
        };

        $scope.openAddEditCeCourse = function (isCeCourseEdit, providerCeCourse) {
            initialApplicationstatusService.setCeCourseFormStatus(false);
            $scope.addEditMode = true;
            $scope.isCeCourseEdit = isCeCourseEdit;
            if (!$scope.isCeCourseEdit) {
                $scope.providerCeCourse = {
                    ApplicationId: $scope.applicationId,
                    ProviderId: $scope.providerId,
                    CourseStatus: "Pending Approval",
                    CourseStatusId: 1,
                    IsActive: true
                }
                if (!!$rootScope.routeData && $rootScope.routeData.courseType == 'RE') {
                    $scope.providerCeCourse.CourseTypeId = 1;
                }
                else if (!!$rootScope.routeData && $rootScope.routeData.courseType == 'AP') {
                    $scope.providerCeCourse.CourseTypeId = 2;
                }
            } else {
                $scope.providerCeCourse = angular.copy(providerCeCourse);
                // $scope.CourseForId = $scope.providerCeCourse.CourseForId;
            }
            $scope.viewConfig.showAddBlock = true;
        };

        $scope.closeAddEditCeCourse = function () {
            $scope.viewConfig.showAddBlock = false;
            initialApplicationstatusService.setCeCourseFormStatus(true);
        };

        var assignCeCourseForParam = function () {
            // angular.forEach($scope.providerCECourses, function (providerCECourseItem) {
            //     angular.forEach($scope.coursefor, function (courseforItem) {
            //         if (courseforItem.CourseForId == providerCECourseItem.CourseForId)
            //             providerCECourseItem.CourseForName = courseforItem.CourseForName;
            //     });
            // });
            angular.forEach($scope.providerCECourses, function (providerCECourseItem) {
                angular.forEach($scope.ceCourseTypes, function (ceCourseTypeItem) {
                    if (ceCourseTypeItem.CECourseTypeId == providerCECourseItem.CourseTypeId)
                        providerCECourseItem.CECourseTypeName = ceCourseTypeItem.CECourseTypeName;
                });
                if (providerCECourseItem.CourseTypeId == 1) {
                    $scope.feeDetails.courseFee = $scope.feeDetails.ceCourseREFee;
                }
                else if (providerCECourseItem.CourseTypeId == 2) {
                    $scope.feeDetails.courseFee = $scope.feeDetails.ceCourseAppFee;
                }
            });
        }

        $scope.providerCECourseByProviderId = function (providerId, hideMessages, isInitLoad) {
            if (!isInitLoad)
                ShowLoader("Please Wait...");
            var dataSavePromise = bppService.providerCECourseByProviderId(providerId, "", sessionStorage.Key, $scope.applicationId);
            dataSavePromise.then(function (response) {
                $scope.providerCECourses = response.ProviderCourseResponseList;
                assignCeCourseForParam();
                $scope.ceCourseList = response.ProviderCourseResponseList;
                if ($scope.providerCECourses != null)
                    $scope.totalCourses = $scope.providerCECourses.length;
                else
                    $scope.totalCourses = 0;

                if (hideMessages) {
                    $scope.hideStatusMessage();
                    $scope.hideValidationSummary();
                }
                processListData();
                if (!isInitLoad) {
                    HideLoader();
                    if ($scope.checkServerResponse({
                        response: response
                    })) {
                        //$scope.providerCECourseList = processAssignmentDescValue(response.ProviderAssignmentList);
                    }
                }
            }, function (error) {
                $scope.showStatusMessage({
                    res: error,
                    msg: "error"
                });
            });
            return dataSavePromise;
        };

        var processListData = function () {
            if ($scope.providerCECourses && $scope.providerCECourses.length > 0) {
                ProviderMultipleFileUploadService.providerDocumentProcess(sessionStorage.Key, $scope.providerId, $scope.ceCourseDocumentUploadPurpose.DocId,
                    $scope.providerCECourses, $scope.ceCourseDocumentUploadPurpose.PropertyToProcess, $scope.ceCourseDocumentUploadPurpose.LinkType, "ProviderDocumentListPurpose").then(function (responseData) {
                        $scope.providerCECourses = responseData;
                        console.log('data processed', responseData);
                    });
                ProviderMultipleFileUploadService.providerDocumentProcess(sessionStorage.Key, $scope.providerId, $scope.ceCourseDocumentUploadAttMonitor.DocId,
                    $scope.providerCECourses, $scope.ceCourseDocumentUploadAttMonitor.PropertyToProcess, $scope.ceCourseDocumentUploadAttMonitor.LinkType, "ProviderDocumentListAttendance").then(function (responseData) {
                        $scope.providerCECourses = responseData;
                        console.log('data processed', responseData);
                    });
                ProviderMultipleFileUploadService.providerDocumentProcess(sessionStorage.Key, $scope.providerId, $scope.ceCourseDocumentUploadCourseMaterial.DocId,
                    $scope.providerCECourses, $scope.ceCourseDocumentUploadCourseMaterial.PropertyToProcess, $scope.ceCourseDocumentUploadCourseMaterial.LinkType, "ProviderDocumentListCourseMaterial").then(function (responseData) {
                        $scope.providerCECourses = responseData;
                        console.log('data processed', responseData);
                    });
            }
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        $scope.$on("Save", function (event, args) {
            if ($scope.addEditMode) {
                $scope.saveCECourse($scope.currentForm, true);
            }
            else {
                initialApplicationstatusService.setCeCourseFormStatus(true);
            }
        });

        $scope.saveCECourse = function (newCECourseForm, isFinalSave) {
            //$scope.providerCeCourse.CourseForId = $scope.CourseForId;
            $scope.hideStatusMessage();
            $scope.hideValidationSummary();
            newCECourseForm.$submitted = true;
            if (validateForm(newCECourseForm, isFinalSave)) {
                ShowLoader("Please wait...");
                $scope.saveCECourseRecord();
            }
        };

        $scope.deleteCeCourse = function (providerCECourse) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                ShowLoader("Please Wait...");
                $scope.providerCeCourse = angular.copy(providerCECourse);
                $scope.providerCeCourse.IsDeleted = true;
                $scope.saveCECourseRecord();
            }
        };

        $scope.saveCECourseRecord = function () {
            bppService.providerCECourseSave([$scope.providerCeCourse],
                sessionStorage.Key).then(function (response) {
                    if ($scope.checkServerResponse({
                        response: response
                    })) {
                        if (response.ProviderCourseList && response.ProviderCourseList.length > 0) {
                            $scope.ceCourseDocumentUploadPurpose.LinkId = response.ProviderCourseList[0].ProviderCourseId;
                            $scope.ceCourseDocumentUploadAttMonitor.LinkId = response.ProviderCourseList[0].ProviderCourseId;
                            $scope.ceCourseDocumentUploadCourseMaterial.LinkId = response.ProviderCourseList[0].ProviderCourseId;
                            if (typeof $scope.saveAllDocumentRef1 == "function" && typeof $scope.saveAllDocumentRef2 == "function" && typeof $scope.saveAllDocumentRef3 == "function") {
                                $q.all([$scope.saveAllDocumentRef1(), $scope.saveAllDocumentRef2(), $scope.saveAllDocumentRef3()])
                                    .then(function (responseDoc) {
                                        $scope.providerCECourseByProviderId($scope.providerId);
                                        $scope.closeAddEditCeCourse();
                                        $scope.showStatusMessage({
                                            res: response.Message,
                                            msg: "success"
                                        });
                                    });
                            } else {
                                $scope.providerCECourseByProviderId($scope.providerId);
                                $scope.closeAddEditCeCourse();
                                $scope.showStatusMessage({
                                    res: response.Message,
                                    msg: "success"
                                });
                            }
                        } else {
                            $scope.providerCECourseByProviderId($scope.providerId);
                            $scope.closeAddEditCeCourse();
                            $scope.showStatusMessage({
                                res: response.Message,
                                msg: "success"
                            });
                        }
                    }
                    $scope.addEditMode = false;
                    initialApplicationstatusService.setCeCourseFormStatus(true);
                    HideLoader();
                }, function (error) {
                    HideLoader();
                    $scope.showStatusMessage(error, "error");
                });
        };
        init();
    }

})();