﻿(function () {
    'use strict';
    angular.module('natApp').config(externalAppConfig);

    externalAppConfig.$inject = ['$stateProvider'];

    function externalAppConfig($stateProvider) {
        $stateProvider.state('app.pciCompliantPaymentResult', {
            url: '/Payment/Result',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/core/directive/pci-compliant-payment/pages/pci-compliant-payment-result.html',
                    controller: 'PCICompliantPaymentResultController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            },
            routeData: {
            }
        });

        $stateProvider.state('app.pciCompliantPaymentSuccess', {
            url: '/Payment/Success?success&txnId&txnTime&message',
            views: {
                'content@app': {
                    templateUrl: 'app/core/directive/pci-compliant-payment/pages/pci-compliant-payment-result.html',
                    controller: 'PCICompliantPaymentResultController'
                }
            },
            params: {
                success: 'true',
                txnId: null,
                txnTime: null,
                message: ''
            },
            routeData: {
                success: true,
                cancelled: false
            }
        });

        $stateProvider.state('app.pciCompliantPaymentFail', {
            url: '/Payment/Fail',
            views: {
                'content@app': {
                    templateUrl: 'app/core/directive/pci-compliant-payment/pages/pci-compliant-payment-result.html',
                    controller: 'PCICompliantPaymentResultController'
                }
            },
            routeData: {
                success: false,
                cancelled: false
            }
        });

        $stateProvider.state('app.pciCompliantPaymentCancel', {
            url: '/Payment/Cancel',
            views: {
                'content@app': {
                    templateUrl: 'app/core/directive/pci-compliant-payment/pages/pci-compliant-payment-result.html',
                    controller: 'PCICompliantPaymentResultController'
                }
            },
            routeData: {
                success: false,
                cancelled: true
            }
        });

        $stateProvider.state('app.Dashboard', {
            url: '/Individual/Dashboard',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/individual-dashboard/individual-dashboard.html',
                    controller: 'IndividualDashboardController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            },
            routeData: {
                applicationArea: "Individual Dashboard"
            }
        });


        $stateProvider.state('app.OnlineNewApplication', {
            url: '/User/onlineNewApplication',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/dashboard/online-InitialLicenseApplication.html',
                    controller: 'OnLineInitialLicensureApplicationController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            },
            routeData: {
                queueType: 'IntakeQ',
                documentUploadedForId: 2,
                applicationArea: "Online-IntakeQ"
            }
        });

        $stateProvider.state('app.OnlineNewExamApplication', {
            url: '/User/OnlineNewExamApplication',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/dashboard/online-InitialLicenseApplication.html',
                    controller: 'OnLineInitialLicensureApplicationController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            },
            routeData: {
                queueType: 'IntakeExamQ',
                documentUploadedForId: 2,
                applicationArea: "Online-IntakeExamQ"
            }
        });

        $stateProvider.state('app.InitialLicenseApplications', {
            url: '/Application/InitialLicenseApplications',
            views: {
                'content@app': {
                    templateUrl: 'app/components/individual/application/InitialLicenseApplications.html',
                    controller: 'InitialLicenseApplicationsController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });
        $stateProvider.state('app.applicationform', {
            url: '/applicationform/{externalFormTypeId}/{externalFormUrlUniqueId}',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },

                'content@app': {
                    templateUrl: 'app/components/individual/external-form/external-form.html',
                    controller: 'ExternalFormController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.complaintOnlineSubmission', {
            url: '/submitComplaint',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/backoffice/enforcement/complaint-online-submission/complaint-online-submission.html',
                    controller: 'ComplaintOnlineSubmissionController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            },
            routeData: {
                configName: 'OnlineSubmission'
            }
        });

        $stateProvider.state('app.NewApplicantLogin', {
            url: '/User/NewApplicantLogin',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.NewApplicantLogin': {
                    templateUrl: 'app/components/login/login-with-email-newapplicant.html',
                    controller: 'LoginwemailController'
                }
            }
        });

        $stateProvider.state('app.LicenseRenewalApplication', {
            url: '/Individual/LicenseRenewalApplication',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/individual/license/renewal/individual/license-renewal-application-online.html',
                    controller: 'LicenseRenewalApplicationOnlineController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            },
            routeData: {
                queueType: 'IntakeQ',
                documentUploadedForId: 2,
                applicationArea: "Online-IntakeQ"
            }
        });

        $stateProvider.state('app.FirmLicenseRenewalApplication', {
            url: '/Provider/FirmRenewalApplication',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/provider/license/renewal/provider/firm-license-renewal-application-online.html',
                    controller: 'FirmLicenseRenewalApplicationOnlineController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            },
            routeData: {
                queueType: 'IntakeQ',
                documentUploadedForId: 2,
                applicationArea: "Online-IntakeQ"
            }
        });

        $stateProvider.state('app.ApplicantApplicationType', {
            url: '/Application/ApplicantApplicationType',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/individual/application/applicant-application-type.html',
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });
        $stateProvider.state('app.ExamInstructions', {
            url: '/ExamInstructions',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/online-exam/exam-instructions.html',
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.OnlineExam', {
            url: '/OnlineExam',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/online-exam/online-exam.html',
                    controller: 'OnlineExamController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });
        $stateProvider.state('app.PaymentConfirmation', {
            url: '/User/PaymentConfirmation/{entityId}/{entityType}/{applicationId}/{applicationTypeId}/{paymentMethodTypeId}/{isReSubmit}',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/individual/payment/payment-confirmation.html',
                    controller: 'PaymentConfirmationController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.PaymentProviderConfirmation', {
            url: '/User/ProviderPaymentConfirmation/{entityId}/{entityType}/{applicationId}/{applicationTypeId}/{paymentMethodTypeId}/{isReSubmit}',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/provider/payment/payment-confirmation.html',
                    controller: 'PaymentProviderConfirmationController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.PaymentConfirmation2', {
            url: '/User/PaymentConfirmationtest',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/individual/payment/payment-confirmation.html',
                    controller: 'PaymentConfirmationController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.UserLinking', {
            url: '/User/UserLinking',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/dashboard/user-linking/user-linking.html',
                    controller: 'UserLinkingController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.ProviderUserLinking', {
            url: '/User/ProviderUserLinking',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/provider-dashboard/provider-user-linking/provider-user-linking.html',
                    controller: 'ProviderUserLinkingController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.Register', {
            url: '/User/Register',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.Register': {

                    templateUrl: 'app/components/account/register-new-applicant.html',
                    controller: 'RegisterController'
                }
            }
        });


        $stateProvider.state('app.NewApplicantRegister', {
            url: '/User/NewApplicantRegister',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.NewApplicantRegister': {
                    templateUrl: 'app/components/login/register-new-applicant.html',
                    controller: 'RegisterController'
                }
            }
        });

        $stateProvider.state('app.Loginwemail', {
            url: '/',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.Loginwemail': {
                    templateUrl: 'app/components/login/login-with-email.html',
                    controller: 'LoginwemailController'
                }
            }
        });


        $stateProvider.state('app.ChangePassword', {
            url: '/User/ChangePassword',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.ChangePassword': {
                    templateUrl: 'app/components/login/change-password.html',
                    controller: 'ChangePasswordController'
                }
            }
        });

        $stateProvider.state('app.ApplicantForgotPassword', {
            url: '/User/ApplicantForgotPassword',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.ApplicantForgotPassword': {
                    templateUrl: 'app/components/login/forgotpassword.html',
                    controller: 'ForgotPasswordController'
                }
            }
        });

        $stateProvider.state('app.ApplicantForgotUserName', {
            url: '/User/ApplicantForgotUserName',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.ApplicantForgotUserName': {
                    templateUrl: 'app/components/login/forgot-username-applicant.html',
                    controller: 'ForgotUserNameController'
                }
            }
        });

        $stateProvider.state('app.FirmForgotUserName', {
            url: '/User/FirmForgotUserName',
            views: {
                'main@': {
                    templateUrl: 'app/core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.FirmForgotUserName': {
                    templateUrl: 'app/components/login/firm-forgot-username.html',
                    controller: 'FirmForgotUserNameController'
                }
            }
        });

        $stateProvider.state('app.ForgotPassword', {
            url: '/User/ForgotPassword',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.ForgotPassword': {
                    templateUrl: 'app/components/login/forgotpassword.html',
                    controller: 'ForgotPasswordController'
                }
            }
        });

        $stateProvider.state('app.UserPortalView', {
            url: '/User/UserPortalView',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    controller: 'UserPortalViewProcessingController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        // Routes Not being used


        $stateProvider.state('app.ProviderLicenseRenewalApplication', {
            url: '/Provider/LicenseRenewalApplication',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/individual/license/renewal/license-renewal-application-online.html',
                    controller: 'LicenseRenewalApplicationOnlineController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            },
            routeData: {
                applicationArea: "Online-Renewal"
            }
        });

        $stateProvider.state('app.ProviderAMCRenewalApplication', {
            url: '/Provider/AMCRenewalApplication',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/individual/license/renewal/amc-renewal-application-online.html',
                    controller: 'AMCRenewalApplicationOnlineController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            },
            routeData: {
                applicationArea: "Online-Renewal"
            }
        });

        $stateProvider.state('app.FirmForgotPassword', {
            url: '/User/FirmForgotPassword',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.FirmForgotPassword': {
                    templateUrl: 'app/components/login/forgotpassword.html',
                    controller: 'ForgotPasswordController'
                }
            }
        });

        $stateProvider.state('app.VerifyLicense', {
            url: '/VerifyLicense',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/individual/license/verify/verify-license.html',
                    controller: 'VerifyLicenseController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });
        $stateProvider.state('app.FirmChangePassword', {
            url: '/User/FirmChangePassword',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.FirmChangePassword': {
                    templateUrl: 'app/components/login/change-password.html',
                    controller: 'ChangePasswordController'
                }
            }
        });
        $stateProvider.state('app.FirmRenewal', {
            url: '/User/FirmRenewal',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/individual/license/renewal/firm/firm-renewal.html',
                    controller: 'FirmRenewalApplicationController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.LicenseRenewalHASnAPDF', {
            url: '/Output/LicenseRenewalHASnA',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.LicenseRenewalHASnAPDF': {
                    templateUrl: 'app/components/individual/license/output/license-renewal-hasna-pdf-file.html',
                    controller: 'PaymentController'
                }
            }
        });

        $stateProvider.state('app.LicenseOutput', {
            url: '/Output/LicenseOutput',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.LicenseOutput': {
                    templateUrl: 'app/components/individual/license/output/license-output.html',
                    controller: 'PaymentController'
                }
            }
        });


        $stateProvider.state('app.PaymentReceipt', {
            url: '/Output/PaymentReceipt',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.PaymentReceipt': {
                    templateUrl: 'app/components/individual/license/output/payment-receipt.html',
                    controller: 'PaymentController'
                }
            }
        });


        $stateProvider.state('app.LoginBusinessPortal', {
            url: '/User/LoginBusinessPortal',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.LoginBusinessPortal': {
                    templateUrl: 'app/components/login/login-with-agency.html',
                    controller: 'LoginAgencyController'
                }
            }
        });

        $stateProvider.state('app.BusinessPortalRegister', {
            url: '/User/BusinessPortalRegister',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.BusinessPortalRegister': {
                    templateUrl: 'app/components/account/lga-registration/lga-registration.html',
                    controller: 'LGARegistrationController'
                }
            }
        });

        $stateProvider.state('app.FirmRegister', {
            url: '/User/FirmRegister',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.FirmRegister': {
                    templateUrl: 'app/components/account/firm-register-new-applicant.html',
                    controller: 'FirmRegisterController'
                }
            }
        });

        $stateProvider.state('app.StaffChangePassword', {
            url: '/User/StaffChangePassword',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.StaffChangePassword': {
                    templateUrl: 'app/components/login/change-password.html',
                    controller: 'ChangePasswordController'
                }
            }
        });

        $stateProvider.state('app.DashboardApplicant', {
            url: '/User/DashboardApplicant',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/dashboard/dashboard-applicant.html',
                    controller: 'DashboardApplicantController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });


        $stateProvider.state('app.Payment', {
            url: '/User/Payment',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/individual/payment/payment.html',
                    controller: 'PaymentController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });


        $stateProvider.state('app.FirmRenewalForm', {
            url: '/User/FirmRenewalForm',
            views: {
                'content@app': {
                    templateUrl: 'app/components/individual/license/renewal/firm-renewal-form.html',
                    // controller: 'LicenseRenewalApplicationOnlineController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.VerifyCode', {
            url: '/VerifyCode',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                //'main@': {
                //    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                //    controller: 'MainController'
                //},
                'content@app': {
                    templateUrl: 'app/components/online-exam/verify-code.html',
                    controller: 'OnlineExamController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.LicenseRenewalApplicationNew', {
            url: '/User/LicenseRenewalApplicationNew',
            views: {
                'content@app': {
                    templateUrl: 'app/components/individual/license/renewal/ui-design/license-renewal-application-new.html',
                    // controller: 'LicenseRenewalApplicationOnlineController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });
        $stateProvider.state('app.SelectUserType', {
            url: '/SelectUserType',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/individual/application/selectusertype.html',
                    // controller: 'LicenseRenewalApplicationOnlineController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });


        $stateProvider.state('app.FirmPaymentConfirmation', {
            url: '/User/FirmPaymentConfirmation/{paymentMethod}',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/individual/payment/payment-conformation.html',
                    controller: 'PaymentConfirmationController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        /*BPP Login Start*/
        $stateProvider.state('app.BPPLogin', {
            url: '/User/BPPLogin',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.BPPLogin': {
                    templateUrl: 'app/components/login/login-bpp.html',
                    controller: 'LoginwemailController'
                }
            }
        });

        $stateProvider.state('app.BPPPortal', {
            url: '/User/BPPPortal',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/dashboard/dashboard-bpp.html',
                    //controller: 'DashboardApplicantController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        /*BPP Login End*/


        $stateProvider.state('app.FirmLogin', {
            url: '/User/FirmLogin',
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/authentication-layout.html',
                    controller: 'MainController'
                },
                'content@app.FirmLogin': {
                    templateUrl: 'app/components/login/firm-login/firm-login.html',
                    controller: 'FirmLoginController'
                }
            }
        });


        $stateProvider.state('app.FirmPermitRenewalApplication', {
            url: '/User/FirmPermitRenewalApplication',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/individual/license/renewal/firm/firm-renewal.html',
                    controller: 'FirmRenewalApplicationController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });



        $stateProvider.state('app.OnlineBusinessEntities', {
            url: '/Online/BusinessEntities',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/backoffice/businessentity/businessentitylist.html',
                    controller: 'BusinessEntityOnlineController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                },
                routeData: {
                    applicationFormType: 'C',
                    LicenseApplicationForId: 20
                }
            }
        });

        $stateProvider.state('app.OnlineBusinessBranchEntity', {
            url: '/Online/BusinessBranchEntity',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/backoffice/businessentity/businessentityonline.html',
                    controller: 'BusinessEntityOnlineController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                },
                routeData: {
                    applicationFormType: 'B',
                    LicenseApplicationForId: 21
                }
            }
        });
        $stateProvider.state('app.DashboardOld', {
            url: '/Individual/DashboardOld',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/individual-dashboard/individual-dashboard-old.html',
                    controller: 'IndividualDashboardOldController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            },
            routeData: {
                applicationArea: "Individual Dashboard"
            }
        });

        $stateProvider.state('app.cedashboard', {
            url: '/provider/cedashboard',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/provider-dashboard/provider-dashboard.html',
                    controller: 'providerDashboardController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.providerdashboard', {
            url: '/provider/providerdashboard',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/provider-dashboard/firm-dashboard.html',
                    controller: 'firmDashboardController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.VerifyCeCourseIndividual', {
            url: '/provider/VerifyCeCourseIndividual',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/provider/application/verify-ce-course-individual/verify-ce-course-individual.html',
                    controller: 'VerifyCeCourseIndividualController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.CeCourseProviderIndividual', {
            url: '/provider/CeCourseProviderIndividual',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/provider/application/ce-course-provider-individual/ce-course-provider-individual.html',
                    controller: 'CeCourseProviderIndividualController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.RECeCourseProvider', {
            url: '/provider/CeCourseProvider',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/provider/application/ce-course-provider/ce-course-provider-app.html',
                    controller: 'CeCourseProviderAppController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                },
                routeData: {
                    courseType: 'RE'
                }
            }
        });

        $stateProvider.state('app.APCeCourseProvider', {
            url: '/provider/CeCourseProvider',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/provider/application/ce-course-provider/ce-course-provider-app.html',
                    controller: 'CeCourseProviderAppController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                },
                routeData: {
                    courseType: 'AP'
                }
            }
        });

        $stateProvider.state('app.CECreditbyCourse', {
            url: '/provider/CECreditbyCourse',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    //controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/provider/application/ce-credit-by-course/ce-credit-by-course.html',
                    //controller: 'CeCourseProviderAppController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.PreLicensingRosterandCredit', {
            url: '/provider/PreLicensingRosterandCredit',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    //controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/provider/application/pre-licensing-roster-credit/pre-licensing-roster-credit.html',
                    //controller: 'CeCourseProviderAppController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.providerSignIn', {
            url: '/provider/providerSignIn',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/provider/application/provider-sign-in/provider-sign-in-app.html',
                    controller: 'CeProviderSignInAppController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });

        $stateProvider.state('app.DatesToApprovedCourses', {
            url: '/provider/datestoapprovedcourses',
            views: {
                'header@app': {
                    templateUrl: 'app/core/layout/header/header-licensee/header-licensee.html',
                    controller: 'HeaderLicenseeController'
                },
                'content@app': {
                    templateUrl: 'app/components/provider/application/add-dates-to-approved-courses/add-dates-to-approved-courses.html',
                    controller: 'AddDateToApprovedCoursesController'
                },
                'footer@app': {
                    templateUrl: 'app/core/layout/footer/footer.html'
                }
            }
        });
    };
})();
