(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('feePayment', feePaymentDirective);

    feePaymentDirective.$inject = [];

    function feePaymentDirective() {
        return {
            restrict: 'E',
            scope: {
                showSubmitPanel: "=showSubmitPanel",
                headingPosition: "=headingPosition",
                natValidation: "=natValidation",
                individualId: '=individualId',
                applicationId: '=applicationId',
                userId: '=userId',
                isRenewal: '=?isRenewal',
                isBackoffice: "=?isBackoffice",
                currentRenewalItem: "=currentRenewalItem",
                showStatusMessage: "&",
                tab: "=tab",
                openNextTab: "&",
                feeDetails: "=?feeDetails",
                individualLicense: "=individualLicense",
                applicationInfo: "=?applicationInfo",
                feeDetailRows: "=feeDetailRows",
                isMiscPayment: '=?isMiscPayment',
                isRefundPayment: '=?isRefundPayment',
                isCashiering: '=?isCashiering',
                paymentStatusUpdate: "&",
                dashboard: '=?dashboard',
                additionalCss: '=?',
                renewalCheckData: "=?",
                useAltPaymentMethod: '=?',
                module: "=?module",
                acceptNewPayment: "=?"
            },
            link: function (scope, element, attrs) {

            },
            templateUrl: "app/components/individual/application/directives/fee-payment/fee-payment.html",
            controller: "FeePaymentController"
        }
    }
})();
