﻿(function () {
    'use strict';
    angular.module('app.backofficeAdministor').factory('ResetPasswordService', ResetPasswordService);
    ResetPasswordService.$inject = ['$http', 'WebApiUrl', '$q'];
    function ResetPasswordService($http, WebApiUrl, $q) {

        var _ResetPassword =  function (param) {
            var data = $http.post(WebApiUrl + 'Users/ResetPassword/' + sessionStorage.Key, param);
            return returnResult(data);
        };
        var _searchUser = function (key, param) {
            var data = $http.post(WebApiUrl + 'Users/UsersSearchAdmin/' + key, param);
            return returnResult(data);
        };
        var _UsersGetAll = function (key) {
            var data = $http.get(WebApiUrl + 'Users/UsersGetAll/' + key);
            return returnResult(data);
        };
        var _getAllUserTypeName = function (key) {
            var data = $http.get(WebApiUrl + 'Users/UserTypeGetAll/' + key);
            return returnResult(data);
        };
        var _getAllRoleList = function (key, UserTypeID) {
            var data = $http.get(WebApiUrl + 'Role/RoleGetbyUserTypeId/' + key + "?UserTypeId=" + UserTypeID);
            return returnResult(data);
        };
        var _getAllUserStatus = function (key) {
            var data = $http.get(WebApiUrl + 'Users/UserStatusGetAll/' + key);
            return returnResult(data);
        };
        var returnResult = function (data) {
            var deferred = $q.defer();
            data
                .then(function (response) {
                    deferred.resolve(response.data);
                })
                .catch(function (response) {
                    deferred.reject(response);
                });
            return deferred.promise;
        };
        return {
            ResetPassword:_ResetPassword,
            searchUser:_searchUser,
            UsersGetAll:_UsersGetAll,
            getAllUserTypeName:_getAllUserTypeName,
            getAllRoleList:_getAllRoleList,
            getAllUserStatus:_getAllUserStatus
        };
    }
})();