(function () {
    'use strict'

    angular.module('app.core')
    .factory('DocumentUploadService', ['$http', '$q', 'WebApiUrl', DocumentUploadService]);

    function DocumentUploadService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log("Request failed with status: " + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _uploadDocument = function (key, apiUrl, documentUploadRequest, urlParams) {
            return responseHandler($http.post(WebApiUrl + apiUrl + '/' + key + '/', documentUploadRequest, { params: urlParams }));
        };

        var _getExampleDocument = function (key, apiUrl) {
            return responseHandler($http.get(WebApiUrl + apiUrl + '/' + key + '/'));
        };

        return {
            uploadDocument: _uploadDocument,
            getExampleDocument: _getExampleDocument
        };
    }
})();