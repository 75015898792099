angular.module('natApp').controller("GlobalController", ["$scope", "$controller", "ConfigurationService", "$filter", "$state", "$timeout",
function ($scope, $controller, ConfigurationService, $filter, $state, $timeout) {
    $scope.$on('$viewContentLoaded', function () {
        $.getScript("app/scripts/validation.js", function (data, textStatus, jqxhr) {
        });
    });

    $scope.isSessionActive = function () {
        if (sessionStorage.Key == "" || sessionStorage.Key == undefined || sessionStorage.Key == "undefined") {
            return false;
        }
        else {
            return true;
        }
    };

    $scope.checkResponse = function (response) {

        if(!!response)
        {
            if (response.StatusCode === "04" && response.ResponseReason === null) {
                showStatusMessage(response.Message, "error");
            }
            else if (response.StatusCode === "03" && response.ResponseReason === null) {
                sessionStorage.Key = "";
                sessionStorage.URL = window.location.href;
                ShowLoader(response.Message + "\n" + " Redirecting to Login Page...");
                var UrlPath = window.location.hash;
                $timeout(function () {
                    if (UrlPath.includes("Backoffice")) {
                        $state.go('app.BackofficeLoginstaff');
                    } else {
                        $state.go('app.Loginwoemail');
                    }
                }, 3000);

            }
            else if (response.StatusCode === "02" && response.ResponseReason === null) {
                showStatusMessage(response.Message, "error");
            }
            else if (response.StatusCode === "01" && response.ResponseReason != null) {
                showStatusMessage(JSON.parse(response.ResponseReason)[0].Message, "error");
            }
            else if (response.StatusCode === "00" && response.ResponseReason === null) {
                return true;
            }
            else if (response.StatusCode === "00" && response.ResponseReason != null) {
                return true;
            }
            else {
                return false;
            }
        }
        else{
            return false;
        }
    };

    $scope.Key;

    $scope.logout = function (flag) {
        $timeout(function () {
            if (flag == "Applicant") {
                $state.go('app.Loginwoemail');
            }
            else if (flag == "Licensee") {
                $state.go('app.Loginwoemail');
            }
            else if (flag == "Staff") {
                $state.go('app.BackofficeLoginstaff');
            } else {
                sessionStorage.Key = "";
            }
        }, 100);
    };

    $scope.getAllConfiguration = function () {
        try {
            ShowLoader("Loading...");
            ConfigurationService.ConfigurationGetAll(sessionStorage.Key)
			.then(function(response){ response = response.data;
			    if ($scope.checkResponse(response)) {

			    } else {
			        //else of CheckResponse
			    }
			    HideLoader();
			}, function(data) { data = data.data;
			    HideLoader();
			    showStatusMessage(data.message, "error");
			});
        }
        catch (ex) {
            HideLoader();
            showStatusMessage(ex.message, "error");
        }
    };
    $scope.getConfigurationBYID = function (ID) {
        try {
            ShowLoader("Loading...");
            ConfigurationService.ConfigurationGetBYID(sessionStorage.Key, ID)
			.then(function(response){ response = response.data;

			    if ($scope.checkResponse(response)) {

			    } else {

			        //else of CheckResponse
			    }
			    HideLoader();
			}, function(data) { data = data.data;

			    HideLoader();
			    showStatusMessage(data.message, "error");
			});
        }
        catch (ex) {
            HideLoader();
            showStatusMessage(ex.message, "error");
        }
    }

    $scope.parseDate = function (input) {
        if (input != undefined && input != null && input != "") {
            var parts = input.match(/(\d+)/g);
            return new Date(parts[0], parts[1] - 1, parts[2]);
        }
    }
}]);