/**
 * Created by handsome on 2/4/2017.
 */
/**
 * Created by handsome on 2/4/2017.
 */
// Creating a factory from the module
(function () {
    'use strict';
    angular.module('app.backofficeAdministor').factory('documentFactory', documentFactory);
    documentFactory.$inject = ['$http', 'WebApiUrl', '$q'];
    function documentFactory($http, WebApiUrl, $q) {
        var _loadApplicationTypes = function (key) {
            var data = $http.get(WebApiUrl + 'TypeValues/ApplicationTypeGetAll/' + key);
            return returnResult(data);
        };

        var _loadDocumentTypes = function (key) {
            var data = $http.get(WebApiUrl + 'Document/DocumentMasterGetALL/' + key);
            return returnResult(data);
        };
        var _loadTransactionTypes = function (key) {
            var data = $http.get(WebApiUrl + 'Common/GetMasterTransactions/' + key);
            return returnResult(data);
        };
        var _addDocument = function (key, param) {
            var data = $http.post(WebApiUrl + 'Document/DocumentMasterSave/' + key, param);
            return returnResult(data);
        };
        var _SearchGetDocumentResultSet = function (key, param) {
            var data = $http.post(WebApiUrl + 'Document/Search_GetDocumentResultSet/' + key, param);
            return returnResult(data);
        };
        var _pageGetAllPageNamesByMasterTransactionId = function (key,mt) {
            var data = $http.get(WebApiUrl + 'Page/PageGetAllPageNamesByMasterTransactionId/' + key + '?MasterTransactionId='+mt);
            return returnResult(data);
        };
        var _pageGetAllTabsByPageModuleId = function (key, moduleId) {
            var data = $http.get(WebApiUrl + 'Page/PageGetAllTabsByPageModuleId/' + key + '?PageModuleId=' + moduleId);
            return returnResult(data);
        };
        var _getDocumentMasterByDocumentMasterId = function (key, docMasterId) {
            var data = $http.get(WebApiUrl + 'Document/GetDocumentMasterByDocumentMasterId/' + key + '?documentMasterId=' + docMasterId);
            return returnResult(data);
        };

        var returnResult = function (data) {
            var deferred = $q.defer();
            data
                .then(function (response) {
                    deferred.resolve(response.data);
                })
                .catch(function (response) {
                    deferred.reject(response);
                });
            return deferred.promise;
        };
        return {
            loadApplicationTypes: _loadApplicationTypes,
            loadDocumentTypes: _loadDocumentTypes,
            loadTransactionTypes: _loadTransactionTypes,
            PageGetAllPageNamesByMasterTransactionId: _pageGetAllPageNamesByMasterTransactionId,
            addDocument: _addDocument,
            SearchGetDocumentResultSet: _SearchGetDocumentResultSet,
            PageGetAllTabsByPageModuleId: _pageGetAllTabsByPageModuleId,
            GetDocumentMasterByDocumentMasterId: _getDocumentMasterByDocumentMasterId
        }
    }
})();
