(function () {
    'use strict'

    angular.module('app.core')
    .directive('activityDescriptionsLogic', [ActivityDescriptionsLogic]);

    function ActivityDescriptionsLogic() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                
            }
        };
    }
})();