(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeIndividualFinancialController", BackofficeIndividualFinancialController);

    BackofficeIndividualFinancialController.$inject = ["$rootScope", "$scope", "utilityService",
        "BackofficeIndividualService"
    ];

    function BackofficeIndividualFinancialController($rootScope, $scope, utilityService,
        BackofficeIndividualService) {

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "finance" && !$rootScope.individualCurrentTab.isLoadedOnce) {
                $scope.individualId = individualId;
                if (!$scope.isLoadedOnce) {
                    $scope.isLoadedOnce = true;
                }
                $scope.IndividualFinanceByIndividualId($scope.individualId);
                $rootScope.individualCurrentTab.isLoadedOnce = true;
            }
        });

        $scope.IndividualFinanceByIndividualId = function (individualId) {
            if (individualId) {
                $scope.FinanceArray = [];
                try {
                    BackofficeIndividualService.IndividualFinanceByIndividualId(sessionStorage.Key, individualId, 0)
                        .then(function(response){ response = response.data;
                            hideStatusMessage();
                            hideValidationSummary();
                            if ($scope.checkServerResponse(response)) {
                                $scope.FinanceArray = response.PaymentAllDetails;
                            }
                        }, function(data) { data = data.data;
                            showStatusMessage(data, "error");
                        });
                } catch (ex) {
                    if (ex != null) {
                        showStatusMessage(ex.message, "error");
                    }
                }
            }
        }
    }
})();