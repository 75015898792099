(function () {
    'use strict'

    angular.module('app.core')
    .controller('individualLicenseController', ['$scope', '$rootScope', '$q', 'individualLicenseService', 'utilityService', 'typeValuesService', 'TypeValue', 'Messages', individualLicenseController]);

    function individualLicenseController($scope, $rootScope, $q, individualLicenseService, utilityService, typeValuesService, TypeValue, Messages) {
        var _key = sessionStorage.Key;
        var _childName = '';

        var getUIConfig = function () {
            return $scope.config.UIConfigs[$scope.configName];
        };

        var getConfig = function () {
            var deferred = $q.defer();
        
            try {
                utilityService.getConfigData('core/data/config-files/core/directive/individual-license/individual-license.server-config.json')
                .then(function(data) {
                    if (!!data) {
                        $scope.config = data;
        
                        $scope.uiConfig = getUIConfig();
        
                        $scope.isConfigLoaded = true;
                    }
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
        
            return deferred.promise;
        };

        $scope.viewConfig = {
            showLicenseViewBlock: false,
            showCEHTable: false,
            showAddCEHButton: true,
            viewLicense: {}
        };
        $scope.datePicker = {
            beginDateOpened: false,
            endDateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };

        $scope.cehDatePicker = {
            dateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };

        $scope.individualLicenseDetailBYIndividualId = function (individualId) {
            ShowLoader("Please Wait...");
            try {
                $scope.ConvertableIndividualLicenseId = 0;
                individualLicenseService.individualLicenseDetailBYIndividualId(sessionStorage.Key, individualId)
                    .then(function (response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        HideLoader();
                        if ($rootScope.checkServerResponse(response)) {
                            $scope.IndividualLicenseList = response.IndividualLicenseList;
                            if (response.IndividualLicenseList != null) {
                                $scope.ConvertableIndividualLicenseId = getMaxIndLicenseId($scope.IndividualLicenseList);
                            }
                        }
                    }, function (data) {
                        HideLoader();
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        };

        function getMaxIndLicenseId(LicArray) {
            var LicNumArr = [];
            angular.forEach(LicArray, function (value, index) {
                LicNumArr.push(LicArray[index].IndividualLicenseId);
            });
            return Math.max.apply(null, LicNumArr);
        };

        $scope.licenseView = function (individualLicense, isConversion) {
            $scope.licenseConversion = isConversion;
            sessionStorage.SaveDone = false;
            sessionStorage.ApplicationId = null;
            // angular.forEach($scope.LicenseArray, function(value, index) {
            //if ($scope.LicenseArray[index].IndividualLicenseId == IndividualLicenseId) {

            //$scope.licenseNO = individualLicense.LicenseNumber;
            //$("#txtlicenseNumber").prop("disabled", true);
            //$("#ddllicenseLicenseType").prop("disabled", true);

            $scope.viewConfig.viewLicense.disableLicenseNumber = !isConversion;
            $scope.viewConfig.viewLicense.disableLicenseType = !isConversion;
            //$scope.licenseType = individualLicense.LicenseTypeId;
            //$scope.licenseStatus = individualLicense.LicenseStatusTypeId;
            individualLicense.LicenseEffectiveDate = (!isConversion) ? ((!!individualLicense.LicenseEffectiveDate) ? (new Date(individualLicense.LicenseEffectiveDate)) : "") : (new Date());
            individualLicense.LicenseExpirationDate = (!!individualLicense.LicenseExpirationDate) ? (new Date(individualLicense.LicenseExpirationDate)) : "";
            individualLicense.OriginalLicenseDate = (!!individualLicense.OriginalLicenseDate) ? (new Date(individualLicense.OriginalLicenseDate)) : "";
            //$scope.licenseBeginningDate = ($filter('date')(new Date(individualLicense.LicenseEffectiveDate), 'MM/dd/yyyy'));
            // $scope.licenseEndingDate = ($filter('date')(new Date(individualLicense.LicenseExpirationDate), 'MM/dd/yyyy'));
            // $scope.licenseOriginalLicenseDate = ($filter('date')(new Date(individualLicense.OriginalLicenseDate), 'MM/dd/yyyy'));

            // $scope.InLicenseId = individualLicense.IndividualLicenseId;
            // $scope.AID = individualLicense.ApplicationId;
            // $scope.ATID = individualLicense.ApplicationTypeId;

            // $scope.IndId = individualLicense.IndividualId;

            // sessionStorage.LicenseNumber = individualLicense.LicenseNumber;
            // sessionStorage.LicenseTypeId = individualLicense.LicenseTypeId;
            // sessionStorage.LicenseTypeName = individualLicense.LicenseTypeName;
            // sessionStorage.LicenseExpirationDate = ($filter('date')(new Date(individualLicense.LicenseExpirationDate), 'MM/dd/yyyy'));
            // sessionStorage.OriginalLicenseDate = ($filter('date')(new Date(individualLicense.OriginalLicenseDate), 'MM/dd/yyyy'));
            // sessionStorage.IndividualLicenseId = individualLicense.IndividualLicenseId;
            // sessionStorage.IndividualId = individualLicense.IndividualId;

            //}
            //})

            // ShowLoader("Please wait...");
            // $("#btnlicenseView").hide();
            //$("#licenseTable").hide();
            // HideLoader();
            //$("#licenseViewBlock").show();
            $scope.viewConfig.showLicenseViewBlock = true;
            $scope.viewConfig.viewLicense.showPaymentBlock = isConversion;
            //$scope.DisplayPaymentFlag = !isConversion;
            // $("#licensePayment").hide();
            // $("#licensePaymentButton").hide();
            // $("#licenseSaveButton").show();

            //if (Conversion) {
            // $("#txtlicenseNumber").prop("disabled", false);
            // $("#ddllicenseLicenseType").prop("disabled", false);
            //$scope.licenseConversion = true;
            //$scope.licenseBeginningDate = ($filter('date')(new Date(), 'MM/dd/yyyy'));
            // $("#licensePayment").show();
            // $("#licensePaymentButton").show();
            // $("#licenseSaveButton").hide();
            //$scope.ddlpaymentMethod.Paymentcode=null;
            //     $scope.DisplayPaymentFlag = false;
            // }
            individualLicense.ExistingLicenseTypeId = individualLicense.LicenseTypeId;
            $scope.individualLicense = individualLicense;
        }

        $scope.getPaymentMethod = function () {
            //$scope.DisplayPaymentFlag = false;
            $scope.PaymentMethodList = [
                { Paymentcode: 'CK', Name: 'Check', Id: 1 },
                { Paymentcode: 'MO', Name: 'Money Order', Id: 2 },
                { Paymentcode: 'CP', Name: 'Click to pay', Id: 4 }
            ];
        }

        $scope.SaveLicense = function (isPayment, licenseForm, applicationObj) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                if (validateForm(licenseForm)) {
                    ShowLoader("Please wait...");
                    if ($scope.licenseConversion) {
                        $scope.individualLicense.ApplicationId = applicationObj.ApplicationId;
                        $scope.individualLicense.ApplicationTypeId = 4;
                    }
                    $scope.individualLicense.IsActive = true;
                    $scope.individualLicense.IsDeleted = false;
                    if (isPayment) {
                        individualLicenseService.licenseConversion($scope.individualLicense, sessionStorage.Key)
                            .then(function (response) {
                                if ($rootScope.checkServerResponse(response)) {
                                    $scope.SavePayment();
                                }
                            }, function (data) {
                                HideLoader();
                                if (data != null) { showStatusMessage(data.message, "error"); }
                            });
                    } else {
                        $scope.individualLicense.ActionFlag = 'M';
                        individualLicenseService.licenseSave($scope.individualLicense, sessionStorage.Key)
                            .then(function (response) {
                                if ($rootScope.checkServerResponse(response)) {
                                    showStatusMessage(response.Message, "success");
                                    $scope.individualLicenseDetailBYIndividualId($scope.individualId);
                                    $scope.viewConfig.showLicenseViewBlock = false;
                                }
                                else {
                                    HideLoader();
                                }
                            }, function (data) {
                                HideLoader();
                                if (data != null) { showStatusMessage(data.message, "error"); }
                            });

                    }
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.SaveLicenseAdd = function (licenseForm, applicationObj) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                if (validateForm(licenseForm)) {
                    ShowLoader("Please wait...");
                    if ($scope.licenseConversion) {
                        $scope.individualLicense.ApplicationId = applicationObj.ApplicationId;
                        $scope.individualLicense.ApplicationTypeId = 4;
                    }
                    $scope.individualLicense.IsActive = true;
                    $scope.individualLicense.IsDeleted = false;
                    $scope.individualLicense.ActionFlag = 'A';
                    $scope.individualLicense.IndividualId = $scope.individualId;
                    individualLicenseService.licenseSave($scope.individualLicense, sessionStorage.Key)
                        .then(function (response) {

                            if ($rootScope.checkServerResponse(response)) {
                                showStatusMessage(response.Message, "success");
                                $scope.individualLicenseDetailBYIndividualId($scope.individualId);
                                $scope.viewConfig.showLicenseViewBlock = false;
                                $scope.LicenseDV = false;
                                $scope.AddLicenseButton = false;
                            }
                            else {
                                HideLoader();
                            }
                        }, function (data) {
                            HideLoader();
                            if (data != null) { showStatusMessage(data.message, "error"); }
                        });
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.SavePayment = function () {
            sessionStorage.IndividualId = $scope.IndividualID;
            sessionStorage.RequestedLicenseStatusTypeId = 1;
            sessionStorage.RenewalFees = 50;
            sessionStorage.LateFees = 0;

            $scope.LicenseTypeId = sessionStorage.LicenseTypeId;
            $scope.ApplicationId = sessionStorage.ApplicationId;
            $scope.IndividualLicenseId = sessionStorage.IndividualLicenseId;
            $scope.LicenseTypeId = sessionStorage.LicenseTypeId;
            $scope.LicenseNumber = sessionStorage.LicenseNumber;


            feeDetails = [{ 'RevMstFeeId': 1, 'FeeName': sessionStorage.LicenseTypeName + ' License Conversion Fee', 'FeeAmount': 50, 'FeeTypeID': 4, 'LicenseTypeId': sessionStorage.LicenseTypeId, 'IndividualLicenseId': sessionStorage.IndividualLicenseId, 'Description': '' }];
            var date = new Date();
            sessionStorage.TransactionStartDatetime = date;
            sessionStorage.FeesDetails = JSON.stringify(feeDetails);
            $scope.FeesTotal = 50;
            $scope.$broadcast("ConversionPayment", {});
        }

        $scope.validateConversionForm = function (licenseForm) {
            $scope.hideStatusMessage();
            $scope.hideValidationSummary();

            if (licenseForm.errorMessages == undefined)
                licenseForm.errorMessages = [];

            var errorObj = {
                propName: "changeLicenseTypeforConversion",
                errorMessage: Messages.General.Errors.ChangeLicenseType
            };
            cleanErrorMessage(licenseForm.errorMessages, errorObj.propName);
            if ($scope.individualLicense.LicenseTypeId == $scope.individualLicense.ExistingLicenseTypeId) {
                licenseForm.errorMessages.push(errorObj);
            }
            return validateForm(licenseForm);
        };

        var cleanErrorMessage = function (errorMessages, prop) {
            var indexOfError = null;
            for (var i = 0; i < errorMessages.length; i++) {
                if (errorMessages[i].propName == prop)
                    indexOfError = i;
            }
            if (indexOfError != null && indexOfError >= 0) {
                errorMessages.splice(indexOfError, 1);
            }
        };


        $scope.LicensePayment_init = function () {
            //angular.element(document.querySelector("#moneyOrderID")).show();
            // if (sessionStorage.PaymentMethodType == "CP") {
            //     $("#lblmoneyOrderNo").html("Invoice#:");
            // } else if (sessionStorage.PaymentMethodType == "CK") {
            //     $("#lblmoneyOrderNo").html("Check#:");
            // } else { $("#lblmoneyOrderNo").html("Money Order#:"); }


            // LicenseRenewalHASnAService.GetServerDate().then(function(response){ response = response.data;
            //     var date = new Date(response.substring(0, 10));
            //     $scope.ReciptDate = ($filter('date')(new Date(date), 'MMM dd, yyyy'));
            // });


            // // $scope.TotalFees = parseInt(sessionStorage.RenewalFees) + $scope.ConvenienceFee + parseInt(sessionStorage.LateFees);

            // // $scope.OriginalLicenseDate = ($filter('date')(new Date(sessionStorage.OriginalLicenseDate), 'MM/dd/yyyy'));

            // // var date = new Date(sessionStorage.LicenseExpirationDate);
            // // date.setFullYear(date.getFullYear() + 1);
            // // $scope.LicenseExpirationDate = ($filter('date')(new Date(date), 'MM/dd/yyyy'));
            // // $scope.LicenseNumber = sessionStorage.LicenseNumber;

            // // $scope.ApplicationName = sessionStorage.ApplicationTypeName;
            // // $scope.LicenseTypeName = sessionStorage.LicenseTypeName;
            // // $scope.IndividualName = sessionStorage.IndividualName;
        }


        $scope.changePaymentMethod = function (ddlpaymentMethod) {
            //if (ddlpaymentMethod != null) {
            // var paymentType = ddlpaymentMethod;
            // sessionStorage.PaymentMethodType = paymentType.Paymentcode;
            // if (paymentType.Paymentcode == 'MO') {
            //     $scope.DisplayPaymentFlag = true;
            // } else if (paymentType.Paymentcode == 'CP') {
            //     $scope.DisplayPaymentFlag = true;
            // } else {
            //     $scope.DisplayPaymentFlag = true;
            // }
            $scope.LicensePayment_init();
            // } else {
            //     angular.element(document.querySelector("#PaymentDiv")).hide();
            //     angular.element(document.querySelector("#moneyOrderID")).hide();
            // }
        };

        //License Conversion

        $scope.applicationId = null;
        $scope.LicenseSaveConversion = function (licenseForm) {
            try {
                if (!$scope.validateConversionForm(licenseForm)) {
                    return false;
                }
                var CurrentDate = new Date();
                // LicenseTypeId = sessionStorage.LicenseTypeId;
                var applicationObj = {
                    ApplicationTypeId: 4,
                    ApplicationStatusId: 1,
                    ApplicationStatusReasonId: 1,
                    ApplicationInternalStatusId: 1,
                    StartedDate: CurrentDate,
                    SubmittedDate: CurrentDate,
                    ApplicationStatusDate: CurrentDate,
                    PaymentDeadlineDate: CurrentDate,
                    PaymentDate: CurrentDate,
                    LicenseTypeId: $scope.individualLicense.LicenseTypeId,
                    IsActive: true,
                    HighPriority: true
                };
                if (!$scope.applicationId) {
                    individualLicenseService.generateApplicationId(sessionStorage.Key, 0, sessionStorage.UserID, applicationObj)
                        .then(function (response) {
                            if ($rootScope.checkServerResponse(response)) {
                                $scope.applicationId = response.ApplicationId;
                                // sessionStorage.ApplicationId = response.ApplicationId;
                                // sessionStorage.ApplicationNumber = response.ApplicationNumber;
                                var applicationObj = {
                                    ApplicationId: response.ApplicationId,
                                    ApplicationNumber: response.ApplicationNumber
                                };
                                sessionStorage.applicationObj = applicationObj;
                                $scope.SaveLicense(true, licenseForm, applicationObj);
                            }
                        }, function (data) {
                            HideLoader();
                            if (data != null) { showStatusMessage(data.message, "error"); }
                        });
                } else {
                    $scope.SaveLicense(true, licenseForm, sessionStorage.applicationObj);
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.deleteLicenseDetail = function (individualLicense) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                ShowLoader("Please Wait...");
                individualLicense.IsActive = true;
                individualLicense.IsDeleted = true;
                individualLicense.ActionFlag = "D";
                individualLicenseService.licenseSave(individualLicense, sessionStorage.Key)
                    .then(function (response) {
                        if ($rootScope.checkServerResponse(response)) {
                            $scope.individualLicenseDetailBYIndividualId($scope.individualId);
                            showStatusMessage(response.Message, "success");
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });
            }
        }

        $scope.individualEducationBYIndividualLicenseId = function (IndividualLicenseId) {
            try {
                $scope.selectedIndividualLicenseId = IndividualLicenseId;
                $scope.IndividualCECourseList = [];
                $scope.viewConfig.showCEHTable = true;
                ShowLoader("Please Wait...");
                //$scope.LicenseID = IndividualLicenseId;
                individualLicenseService.individualEducationBYIndividualLicenseId(sessionStorage.Key, IndividualLicenseId)
                    .then(function (response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($rootScope.checkServerResponse(response)) {
                            //$scope.LicenseID = IndividualLicenseId;
                            //$scope.courseID = response.IndividualCECourseResponseList[0].IndividualCECourseId;
                            //$scope.CourseApplicationID = response.IndividualCECourseResponseList[0].ApplicationId;
                            $scope.IndividualCECourseList = response.IndividualCECourseResponseList;
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.getCEH = function (individualCECourse) {
            $scope.isCEHEdit = true;
            $scope.viewConfig.showAddCEHBlock = true;
            $scope.viewConfig.showAddCEHButton = false;
            //$("#AddRowBlock").show();
            //$("#btnAddRow").hide();
            if (!!individualCECourse.CECourseDate && !angular.isDate(individualCECourse.CECourseDate))
                individualCECourse.CECourseDate = new Date(individualCECourse.CECourseDate);
            $scope.individualCECourse = angular.copy(individualCECourse);
            // $scope.EducationInformationCourse = $scope.CEHArray[index].CourseNameTitle;
            // $scope.EducationInformationDate = ($filter('date')(new Date($scope.CEHArray[index].CECourseDate), 'MM/dd/yyyy'));
            // $scope.EducationInformationCEHours = $scope.CEHArray[index].CECourseHours;

            // $scope.courseID = $scope.CEHArray[index].IndividualCECourseId;
            // $scope.CourseApplicationID = $scope.CEHArray[index].ApplicationId;
        }

        $scope.saveCEH = function (cehForm) {
            try {
                $scope.viewConfig.showCEHTable = true;
                cehForm.$submitted = true;
                if (validateForm(cehForm)) {
                    ShowLoader("Please wait...");
                    if (!$scope.isCEHEdit) {
                        $scope.individualCECourse.IndividualId = $scope.individualId;
                        $scope.individualCECourse.IndividualLicenseId = $scope.selectedIndividualLicenseId;
                    }
                    $scope.individualCECourse.IsActive = true;
                    $scope.individualCECourse.IsDeleted = false;

                    backofficeIndividualEducationService.individualCECourseSave($scope.individualCECourse, sessionStorage.Key)
                        .then(function (response) {
                            if ($rootScope.checkServerResponse(response)) {
                                $scope.individualEducationBYIndividualLicenseId($scope.individualCECourse.IndividualLicenseId);
                                showStatusMessage(response.Message, "success");
                                $scope.viewConfig.showAddCEHBlock = false;
                                $scope.viewConfig.showAddCEHButton = true;
                            }
                            HideLoader();
                        }, function (data) {
                            HideLoader();
                            if (data != null) { showStatusMessage(data.message, "error"); }
                        });
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }


        $scope.deleteCEH = function (individualCECourse) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                ShowLoader("Please wait...");
                individualCECourse.IsDeleted = true;

                backofficeIndividualEducationService.individualCECourseSave(individualCECourse, sessionStorage.Key)
                    .then(function (response) {
                        if ($rootScope.checkServerResponse(response)) {
                            $scope.individualEducationBYIndividualLicenseId(individualCECourse.IndividualLicenseId);
                            showStatusMessage(response.Message, "success");
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });
            }
        }

        //Clear CEH Row
        $scope.cancelCEH = function () {
            $scope.viewConfig.showAddCEHBlock = false;
            $scope.viewConfig.showAddCEHButton = true;
        }

        $scope.showAddEditCEH = function () {
            $scope.individualCECourse = {};
            $scope.isCEHEdit = false;
            $scope.viewConfig.showAddCEHBlock = true;
            $scope.viewConfig.showAddCEHButton = false;
        };

        $scope.closeCEH = function () {
            $scope.viewConfig.showCEHTable = false;
        }

        $scope.cancelLicenseView = function () {
            $scope.individualLicenseDetailBYIndividualId($scope.individualId);
            $scope.viewConfig.showLicenseViewBlock = false;
            $scope.natValidation.errorMessages = [];
            $scope.natValidation.showError = false;
            $scope.natValidation.isValid = true;
        }

        function AuditVisitInfoSave() {

            var data = {
                "PageName": "IndividualInfo-License",
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        }

        $scope.addLicence = function () {
            $scope.individualLicense = {};
            if ($scope.IndividualLicenseList && $scope.IndividualLicenseList.length > 0) {
                $scope.individualLicense.OriginalLicenseDate = new Date($scope.IndividualLicenseList[0].OriginalLicenseDate);
            }
        }

        $scope.saveDiscipline = function () {
            ShowLoader();
            var data = {};
            if ($scope.personal.isDispEdit) {
                data = $scope.editLicenseDiscip;
                data.LicenseDisciplineTypeId = $scope.personal.selectedDiscipline;
            }
            else {
                data = {
                    IndividualId: $scope.individualId,
                    ApplicationId: $scope.applicationId,
                    LicenseDisciplineTypeId: $scope.personal.selectedDiscipline,
                    IsActive: true,
                    IsDeleted: false
                }
            }
            if ($scope.personal.selectedDiscipline) {
                individualLicenseService.saveIndividualLicenseDiscipline([data], sessionStorage.Key).then(function (response) {
                    $scope.personal.isDispEdit = false;
                    $scope.personal.selectedDiscipline = null;
                    getIndividualLicenseDiscipline();
                    HideLoader();
                }, function (error) {
                    console.log(error);
                });
            }
            else {
                showStatusMessage(Messages.cabeaee, "error")
                HideLoader();
            }
        }

        $scope.editLicenseDiscipline = function (individualLicenseDiscipline) {
            $scope.personal.isDispEdit = true;
            $scope.personal.selectedDiscipline = individualLicenseDiscipline.LicenseDisciplineTypeId;
            $scope.editLicenseDiscip = angular.copy(individualLicenseDiscipline);
        }

        $scope.deleteLicenseDiscipline = function (individualLicenseDiscipline) {
            try {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    individualLicenseDiscipline.IsDeleted = true;

                    individualLicenseService.saveIndividualLicenseDiscipline([individualLicenseDiscipline], sessionStorage.Key)
                        .then(function (response) {
                            if (response.Status && response.StatusCode == "00") {
                                getIndividualLicenseDiscipline();
                            }
                            HideLoader();
                        }, function (data) {
                            HideLoader();
                            ShowErrMsg();
                        });
                }
            } catch (ex) {
                // showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        };

        var getIndividualLicenseDiscipline = function () {
            individualLicenseService.getIndividualLicenseDiscipline($scope.individualId, $scope.applicationId, sessionStorage.Key).then(function (response) {
                //console.log(response);
                if (response.Status) {
                    $scope.individualLicenseDisciplineList = response.IndividualLicenseDisciplineList;
                    $scope.getDisciplineName();
                }
            }, function (error) {
                //console.log(error);
            });
        }

        $scope.getDisciplineName = function () {
            if ($scope.refLicenseDisciplineTypeList) {
                _.each($scope.individualLicenseDisciplineList, function (discipline) {
                    _.each($scope.refLicenseDisciplineTypeList, function (item) {
                        if (discipline.LicenseDisciplineTypeId == item.LicenseDisciplineTypeId) {
                            discipline.name = item.LicenseDisciplineTypeName;
                        }
                    });
                });
            }
        }

        var getRefLicenseDisciplineTypeGetAll = function () {
            individualLicenseService.getRefLicenseDisciplineTypeGetAll($scope.individualId, sessionStorage.Key).then(function (response) {
                //console.log(response);
                if (response.Status) {
                    $scope.refLicenseDisciplineTypeList = response.RefLicenseDisciplineTypeList;
                    getIndividualLicenseDiscipline();
                }
                else {
                    getIndividualLicenseDiscipline();
                }
            }, function (error) {
                getIndividualLicenseDiscipline();
                //console.log(error);
            });
        }

        var validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            if (!!$scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 8) {
                if (!$scope.natValidation.errorMessages) {
                    $scope.natValidation.errorMessages = [];
                }
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        var licenseStatusTypeGetAll = function () {
            try {
                typeValuesService.getTypeValuesByTypeName(TypeValue.LicenseStatus, sessionStorage.Key)
                    .then(function (response) {
                        if ($rootScope.checkServerResponse(response)) {
                            $scope.LicenseStatusList = response.LicenseStatusTypeGetList;
                            $scope.lStatus = $scope.LicenseStatusList[0].LicenseStatusTypeId;
                        }
                    }, function (data) {

                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {

                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        var licenseTypeGetAll = function () {
            try {
                typeValuesService.getTypeValuesByTypeName(TypeValue.License, sessionStorage.Key)
                    .then(function (response) {
                        if ($rootScope.checkServerResponse(response)) {
                            $scope.LicenseTypeList = response.LicenseTypeGetList;
                            $scope.ltype = $scope.LicenseTypeList[0].LicenseTypeId;
                        }
                    }, function (data) {

                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {

                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        var ShowErrMsg = function (propName, errorMessage) {
            propName = (!!propName) ? propName : "certificationErr";
            errorMessage = (!!errorMessage) ? errorMessage : $scope.uiConfig.ValidationMsg.APIError;
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            var propExist = false;
            for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                if ($scope.natValidation.errorMessages[i].propName == propName)
                    propExist = true;
            }
            if (!propExist)
                $scope.natValidation.errorMessages.push({
                    propName: propName,
                    errorMessage: errorMessage
                });

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.init = function () {
                if (!$scope.isLoadedOnce) {
                    $scope.isLoadedOnce = true;
                    $scope.getPaymentMethod();

                }
                AuditVisitInfoSave();
                getRefLicenseDisciplineTypeGetAll();
                licenseStatusTypeGetAll();
                licenseTypeGetAll();
                $scope.IndividualLicenseList = [];
                $scope.individualLicenseDetailBYIndividualId($scope.individualId);
                $rootScope.individualCurrentTab.isLoadedOnce = true;
                $scope.viewConfig = {
                    showLicenseViewBlock: false,
                    showCEHTable: false,
                    showAddCEHButton: true,
                    viewLicense: {}
                };
                $scope.LicenseDV = false;
                $scope.AddLicenseButton = false;
                $scope.personal = $scope.personData;
                $scope.natValidation.errorMessages = [];
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
        };

    }
})();