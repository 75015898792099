(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .directive('individualSearch', individualSearchDirective);

    individualSearchDirective.$inject = [];

    function individualSearchDirective() {
        return {
            restrict: 'E',
            scope: {
                searchContent: "=?",
                redirectRoute: "=?",
                onSearchComplete: "&",
                onSearchCancel: "&"
            },
            templateUrl: 'app/components/backoffice/individual/search/search.html',
            controller: 'IndividualSearchController'
        };
    }
})();