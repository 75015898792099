﻿(function () {
    'use strict'
    angular.module('app.core')
        .factory('SecureCommunicationDirectiveFactory', ['$http', 'WebApiUrl', '$q', 'AppConfig', function ($http, WebApiUrl, $q, AppConfig) {

            var _getSecureCommunicationAll = function (key, reqdata) {
                return returnDefferedResult($http.post(WebApiUrl + 'Individual/GetSecureCommunicationAll/' + key, reqdata));
            };

            var _makeSecureCommunicationUnread = function (_key, _userId, _applicationId, _userType) {
                return returnDefferedResult($http.post(WebApiUrl + 'Individual/MakeSecureCommunicationUnread/' + _key + '?UserId=' + _userId + '&ApplicationId=' + _applicationId + '&UserType=' + _userType + '&CommunicationType=M'));
            };

            var returnDefferedResult = function (request) {
                var deferred = $q.defer();
                request.then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.reject(response);
                });
                return deferred.promise;
            };
            return {
                GetSecureCommunicationAll: _getSecureCommunicationAll,
                MakeSecureCommunicationUnread:_makeSecureCommunicationUnread
            };
    }]);
})();