﻿angular.module('natApp').controller("DashboardLicenseeController",["$scope", "$window", "$controller","$state",
function ($scope, $window, $controller,$state) {$controller('GlobalController', { $scope: $scope });
    // Variables
    $scope.HomeAddressStreet = "";
    $scope.HomeAddressCity = "";
    $scope.HomeAddressState = "";
    $scope.HomeAddressZip = "";

    $scope.MailingAddressStreet = "";
    $scope.MailingAddressCity = "";
    $scope.MailingAddressState = "";
    $scope.MailingAddressZip = "";

    $scope.RenewApplication = function () {
        try {
            $window.open('#/User/LicenseRenewalHASnA', '_blank');
        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }

    // page init method
    $scope.init = function () {
        try {
            if ($scope.isSessionActive()) {
            }
            else {
                $state.go('app.Loginwoemail');
            }
        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }

    $scope.updateAddressDetails = function () {
        try {
        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }

    angular.element(document.querySelector("#DashboardliAdd")).hide();
    angular.element(document.querySelector("#DashboardliFill")).show();
    angular.element(document.querySelector("#Edit")).show();
    angular.element(document.querySelector("#Edit")).click(function () {
        angular.element(document.querySelector("#DashboardliAdd")).show();
        angular.element(document.querySelector("#DashboardliFill")).hide();
        angular.element(document.querySelector("#Edit")).hide();
    });
    angular.element(document.querySelector("#Cancel")).click(function () {
        angular.element(document.querySelector("#DashboardliAdd")).hide();
        angular.element(document.querySelector("#DashboardliFill")).show();
        angular.element(document.querySelector("#Edit")).show();
    });
}]);