(function () {
    'use strict';

    angular
        .module('app.constant', []);

    angular
        .module('app.constant')
        .constant('AppConfig', {
            Name: 'Dispensing-Opticians',
            DataFolderName: 'config-files',
            ConfigServerUrl: 'app/core/data/',
            BoardName: 'California Board of Accountancy',
            BoardFotterName: 'California Board of Accountancy',
            BoardUrl: 'https://www.dca.ca.gov/cba/',
            BoardEmail: 'Accountancy.Connect@cba.ca.gov',
            BoardPhone: '(916) 561-1702',
            EnableConsoleLogging: true,
            Source: 'LAMED',
            CopyrightYear: '2023',
            BoardBackofficeLogoSealPath: 'app/content/images/logo/CBA_color_logo_small.jpg',
            BoardBackofficeLogoPath: 'app/content/images/logo/backoffice-logo.png',
            BoardOnlineLogoSealPath: 'app/content/images/logo/CBA_color_logo_small.jpg',
            BoardOnlineLogoPath: 'app/content/images/logo/online-logo.png',
            BoardRightLogoSealPath: 'app/content/images/logo/dca-logo-right.png',
            BoardRightLogoWhitePath: 'app/content/images/logo/dca_logo_white_large_noback.png',
            BoardRightLogoAlt: 'DCA Logo',
            DefaultCountryId: 319
        });

    angular
        .module('app.constant')
        .constant('LookUpType', {
            HairColorTypeId: 35,
            EyeColorTypeId: 36,
            GenderTypeId: 37,
            SuffixTypeId: 46,
            NameStatusTypeId: 72
        });

    angular
        .module('app.constant')
        .constant('TypeValue', {
            Address: "Address",
            Contact: "Contact",
            LicenseStatus: "LicenseStatus",
            License: "License",
            ApplicationStatus: "ApplicationStatusGetAll",
            AuxiliaryType: "GetAllAuxiliaryType",
            Prefix: "Prefix",
            Suffix: "Suffix",
            ProfTitleSuffix: "ProfTitleSuffix",
            PermitStatus: "PermitStatus",
            MaritalStatus: "MaritalTypeGetAll",
            ExamType: "ExamTypeGetAllByExamNameId",
            PermitApplicationType: "PermitApplication",
            PermitType: "Application",
            RefAllegationDescription: "RefAllegationDescription",
            RefProgram: "RefProgram",
            LicenseKind: "LicenseKind",
            LicenseKindTypeGetAllWithEndorsements: "LicenseKindTypeGetAllWithEndorsements",
            LicenseKindTypeGetAll: "LicenseKindTypeGetAll",
            RefGradeNumberGetAll: "RefGradeNumberGetAll",
            RefEndorsementGetAll: "RefEndorsementGetAll",
            RefEndorsementProvisionGetAll: "RefEndorsementProvisionGetAll",
            LicenseProvisionGetAll: "LicenseProvisionGetAll",
            EndorsementGetAllByLicenseKindCode: "EndorsementGetAllByLicenseKindCode",
            RefLicenseDurationReqGetAll: "RefLicenseDurationReqGetAll",
            RefProvisionGetAll: "RefProvisionGetAll",
            RefExamNameGetAll: "RefExamNameGetAll",
            RefDistrictGet: "RefDistrictGet",
            RefSchoolGet: "RefSchoolGet",
            EmploymentStatusGetAll: "EmploymentStatusGetAll",
            RefDegreeGetAll: "RefDegreeGetAll",
            RefTablesGetAll: "RefTablesGetAll",
            RefAssignmentGetAll: "RefAssignmentGetAll",
            RefLicenseRequirementGetAll: "RefLicenseRequirementGetAll"
        });

    angular
        .module('app.constant')
        .constant('SettingsValue', {
            ShowAddressandContactSeparately: "ShowAddressandContactSeparately",
            IsDocumentNameHidden: "IsDocumentNameHidden",
            AllowedDocTypesForOnline: "AllowedDocTypesForOnline",
            AllowedDocTypesForBackOffice: "AllowedDocTypesForBackOffice",
            AllowedDocTypesForComplaint: "AllowedDocTypesForComplaint"
        });

    angular
        .module('app.constant')
        .constant('ApplicationSettings', {
            CompletedStatus: 3,
            ActiveStatuses: [1, 2, 13, 14, 16, 27, 28, 29, 30],
            InactiveStatuses: [3, 4, 5, 6],

            AvailableTypes: [1, 2, 8, 9],

            AppLicenseStatus: {
                'inProcess1': {
                    ApplicationStatusId: 1,
                    LicenseStatusTypeId: 17,
                    IsActive: false
                },
                'submitted': {
                    ApplicationStatusId: 2,
                    LicenseStatusTypeId: 17,
                    IsActive: false
                },
                'approved': {
                    ApplicationStatusId: 3,
                    LicenseStatusTypeId: 1,
                    IsActive: true
                },
                'withdrawn': {
                    ApplicationStatusId: 4,
                    LicenseStatusTypeId: 18,
                    IsActive: false
                },
                'denied': {
                    ApplicationStatusId: 5,
                    LicenseStatusTypeId: 13,
                    IsActive: false
                },
                'archived': {
                    ApplicationStatusId: 6,
                    LicenseStatusTypeId: 14,
                    IsActive: false
                },
                'managerApprove': {
                    ApplicationStatusId: 28,
                    LicenseStatusTypeId: 17,
                    IsActive: false
                },
                'evaluatorInitialApprove': {
                    ApplicationStatusId: 29,
                    LicenseStatusTypeId: 17,
                    IsActive: false
                },
                'evaluatorTechReturnApprove': {
                    ApplicationStatusId: 29,
                    LicenseStatusTypeId: 17,
                    IsActive: false
                },
                'techReviewApprove': {
                    ApplicationStatusId: 30,
                    LicenseStatusTypeId: 17,
                    IsActive: false
                },
                'techReviewExamPendingApprove': {
                    ApplicationStatusId: 16,
                    LicenseStatusTypeId: 17,
                    IsActive: false
                },
                'techReviewNotReady': {
                    ApplicationStatusId: 26,
                    LicenseStatusTypeId: 17,
                    IsActive: false
                },
                'techReviewIneligible': {
                    ApplicationStatusId: 28,
                    LicenseStatusTypeId: 17,
                    IsActive: false,
                    ApplicationInternalStatusId: 1
                },
                'preApproved': {
                    ApplicationStatusId: 12,
                    LicenseStatusTypeId: 17,
                    IsActive: false
                },
                'inProcess14': {
                    ApplicationStatusId: 14,
                    LicenseStatusTypeId: 17,
                    IsActive: false
                },
                'backToPending': {
                    ApplicationStatusId: 13,
                    LicenseStatusTypeId: 17,
                    IsActive: false
                },
                'backToTechReview': {
                    ApplicationStatusId: 25,
                    LicenseStatusTypeId: 17,
                    IsActive: false
                },
                'backToEvaluatorFinal': {
                    ApplicationStatusId: 30,
                    LicenseStatusTypeId: 17,
                    IsActive: false
                },
                'backToEvaluatorInitial': {
                    ApplicationStatusId: 27,
                    LicenseStatusTypeId: 17,
                    IsActive: false
                },
                'backToManager': {
                    ApplicationStatusId: 28,
                    LicenseStatusTypeId: 17,
                    IsActive: false
                },
                'fingerprintQApprove': {
                    LicenseStatusTypeId: 17,
                    IsActive: false,
                    UpdateBackgroundCheckStatus: true,
                    BackgroundCheckStatusId: 2
                }
            }
        });

    angular
        .module('app.constant')
        .constant('LicenseSettings', {
            ActiveStatuses: [1],
            ActivePendingStatuses: [1, 17]
        });

    angular
        .module('app.constant')
        .constant('Messages', {
            "eddbbfd": "You need to be 18 years to complete the Registration. Please contact Board Office (Reference Code: 00017).",
            "aeeedce": "Please enter Date of Birth in valid MM/DD/YYYY format (Reference Code: 00016).",
            "abdfade": "",
            "eefedbe": "Error in the Registration Process: (Reference Code: 00035). When contacting Board Office, please refer the Reference Code in the communication.",
            "efbefaa": "Error in the Registration Process: Users, (Reference Code: 00047). When contacting Board Office, please refer the Reference Code in the communication.",
            "ebedccb": "Error in the Registration Process: ProviderSave",
            "ddcefbd": "Error in the Registration Process: UserRoleSave, (Reference Code: 00050). When contacting Board Office, please refer the Reference Code in the communication.",
            "ffbdddd": "Error in the Registration Process: IndividuallogSave, ApplicationTypeId=7  (Reference Code: 00051). When contacting Board Office, please refer the Reference Code in the communication.",
            "bcedeff": "Occurred Error!",
            "efefcae": "Profile saved successfully.",
            "bbcddaa": "The User Name entered is already registered with us.",
            "cbbebad": "The Email entered is already registered with us.",
            "eddffaf": "Oops! an error occurred while saving user.",
            "fcfdcaa": "Please select Assign To Queue or User name",
            "dcdddfa": "Either application can not be moved or is already in selected queue.",
            "ffbdffc": "Review and Save all the tabs before sending deficiency",
            "ceaeada": "Payment successful",
            "efbdcdf": "Error in the .",
            "faaaddc": "Some error occurred !",
            "fefeefc": "Please add con",
            "efaacda": "Invoice amount cannot be zero.",
            "bbffceb": "Error in the Registration Process: IndividualApplicationSave, ApplicationTypeId=7  (Reference Code: 00043). When contacting Board Office, please refer the Reference Code in the communication.",
            "dadeace": "Invoice generated successfully",
            "dcdcabb": "No Record Found",
            "ebcecca": "Saved Successfully",
            "bdcfebb": "Deleted Successfully",
            "cfedefc": "Updated successfully.",
            "aedbddf": "Deleted successfully.",
            "affbeca": "Saved successfully.",
            "bbeadad": "Please select Application type",
            "cfafefc": "You already have Early Exam Type Application , please select other one.",
            "aaabcfc": "Some Error occurred",
            "aeabcfa": "Some error occured.",
            "ecbbecf": "Error in the Registration Process: IndividualApplicationSave, ApplicationTypeId=7  (Reference Code: 00043). Please contact the NV Department of Education and reference this code.",
            "afeadce": "Reference Form date submitted.",
            "eceabad": "Experience Verification Form date submitted.",
            "cdddddd": "You have already submitted Reference Form",
            "dacabfb": "You have already submitted Experience Verification Form",
            "afdbcfd": "Online New Application is coming soon. Login page (Reference Code: 00061).",
            "aadaebb": "Login credentials entered is not valid. Please try again or click on Forgot Password link to reset your password. Login failed (Reference Code: 00058).",
            "ffaaddd": "Error in the Login Process for getting Roles: GetUserRoles (Reference Code: 00060). When contacting Board Office, please refer the Reference Code in the communication.",
            "ccecefa": "Email Address and Confirm Email Address does not match.",
            "eaacdec": "Error in the Registration Process: IndividualSelect (Reference Code: 00035). When contacting Board Office, please refer the Reference Code in the communication.",
            "cceedce": "Please save the course once and then proceed to payment",
            "aefcaee": "Application approved successfully",
            "dbccced": "The License No. is already associated with another user.",
            "fedcbeb": "Please enter License Number.",
            "bcededb": "The License No. does not exist in system.",
            "bdecebf": "Some error occurred while loading deficiencies",
            "cabeaee": "Please select Discipline",
            "ebaedfa": "Are you sure you want to delete this template?",
            "bbabace": "Are you sure you want to delete this record?",
            "ddceade": "Are you sure you wish to delete this application?  All your current progress will be lost.",
            "eafebdd": "Are you sure you wish to withdrawn this application?",
            "bfabdaf": "Are you sure you wish to close this application?",
            "dafcece": "Are you sure you want to delete this action?",
            "ccdabaf": "Are you sure you want to delete this case?",
            "febfdaf": "Are you sure you want to delete this document?",
            "eeaccfd": "Are you sure you want to delete this party?",
            "bddecba": "Are you sure you want to delete this complaint?",
            "aabebce": "Are you sure want to delete this record?",
            "eefcfca": "Are you sure you want to cancel the payment?",
            "dfdbeab": "Are you sure you want to delete?",
            "ccafaae": "Are you sure you want to delete this Address?",
            "cdeefee": "Are you sure you want to delete this Record?",
            "faebeeb": "Do you want to Approve the request for secure access to the Alabama board of funeral service CEPP portal?",
            "eadcfff": "Do you want to email deficiencies and deny this record?",
            "dacbaff": "Are you sure you want to deny this application?",
            "cceccbd": "Are you sure you want to Deassociate this record?",
            "ecedede": "Are you sure you wish to delete this address?",
            "aebdbdf": "Are you sure you want to change the status of this address to Previous?",
            "ccccdeb": "Are you sure you wish to withdraw this application?",
            "bcfefad": "Oops! Some Error Occurred.",
            "efafcdf": "else part of success",
            "ebcadaa": "No fields found to add record",
            "dcbaaaf": "Saved Successfully.",
            "aaaccef": "License number required!",
            "acefcde": "Record Deleted.",
            "baaddab": "Application Submitted Successfully.",
            "bafbefd": "firm info loaded",
            "caacefc": "Email sent successfully",
            "ddccdba": "The Secure Message functionality has encountered an error. Please try after some time or contact technical support.",
            "efbceae": "Select an Item Code",
            "ffafcaa": "Select Content Item #",
            "dceaada": "Either Cell Phone or Home Phone is Required",
            "fbeecfc": "Residential/Home Address Already Exists with current status.",
            "aeceadc": "CPR End Date should be greater than June 30th of current year",
            "cefaeee": "Minimum ",
            "cebbabc": "At least one document with document type ",
            "cebcbca": "Atleast 1 record is required",
            "bbfeaff": "Please Select Mailing Payment Option",
            "bcbfabd": "Please Upload document ",
            "ffecfcd": "Please Upload document.",
            "fdbecea": "Atleast one record is required.",
            "dfcffdf": "Please upload docuement for Question ",
            "fdccebe": "Please Upload Citizenship Infomation document.",
            "ffdefec": "Discipline is required.",
            "cdcebcb": "Please contact Board for renewal",
            "bffedcf": "please select one option.",
            "ecabcdb": "Please enter some search criteria.",
            "cfaaccb": "Please upload at leaast document for Asylum Special Circumstance",
            "cfaaccc": "Please upload at leaast document for Honorably Discharged Veteran Special Consideration",
            NatRequired: {
                requirederrormessage: {
                    CouncilName: "Name of Council is required",
                    CodeRequired: "Code is required",
                    UrlRequired: "Url is required",
                    PysicalAddress: "Physical address AddressLine is required",
                    PysicalCity: "Physical address City is required",
                    PysicalState: "Physical address State is required",
                    PysicalZip: "Physical address zip is required",
                    MailingAddress: "Mailing address AddressLine is required",
                    MailingCity: "Mailing address City is required",
                    MailingState: "Mailng address State is required",
                    MailingZip: "Mailing address zip is required",

                },
                valueerrormessage: {
                    url: "Invalid url. eg: http(s)://www.example.com",
                    zip: "Invalid physical address zip., Eg: XXXXX-XXXX or XXXXX",
                    phone: "Invalid contact phone. Format eg:(XXX) XXX-XXXX Or (XXX)XXX-XXXX",
                    email: "Invalid Email. Format eg: xxx@xxx.xxx",
                    fax: "Invalid contact fax. Format eg:XXX-XXX-XXXX Or XXXXXXXXXX",
                }
            },
            General: {
                Errors: {
                    CompleteTab: function (tab) { return "Please complete all sections before moving to the " + tab + " section" },
                    SaveOpenForm: "Please save currently open form before proceeding to payment",
                    GetUserRoles: 'Error in the Login Process for getting Roles: GetUserRoles (Reference Code: 00060). When contacting Board Office, please refer the Reference Code in the communication.',
                    Delete: "Failed Deleting.",
                    SomeError: "Some Error Occured!",
                    NoRecord: "No Record Found.",
                    DocName: "Please enter document name",
                    DocType: "Please select document type",
                    UploadDoc: "Please Upload document.",
                    NotAuthorizedPortal: "You are not authorized to access this portal or your account is disabled, please contact to board office. ",
                    ManagingAgent: "One or more Responsible Incharge and/or Managing Agent have not renewed their licenses.",
                    SelectBranch: 'Please select branch responsible for this location',
                    SuperviserComment: "Supervisor's Comment are required.",
                    VerifyExp: "Please verify or Unverify all the experience which have status as Pending Verification.",
                    References: "at least 5 References Required (3 should be PE’s with Personal Knowledge)",
                    LicenseExpireDate: 'License is Expired so To Date should be in the past',
                    RequiresLicense: "License number required!",
                    NoFieldsFound: 'No fields found to add record',
                    SelectStaff: "Please select staff user to assign application.",
                    AddOneAssignment: "Please add at least one assignment data",
                    AddOneCECourse: "Please add at least on CE Course.",
                    TotalFTE: "Total FTE cannot be more than 100%",
                    ACLS: function (index) { return "Either New ACLS From and To or New PALS From and To Date Required for row " + (index + 1) },
                    EmailNoMatch: "Email Address and Confirm Email Address does not match.",
                    TaskError: "Oops! Something went wrong while executing job.",
                    TaskNameExists: "Task Name already exists.",
                    SelectLocation: 'You must select a location',
                    OneLicensePermit: 'You must add at least one license/permit type to continue.',
                    FetchData: "Oops! Something went wrong while fetching list data.",
                    UserDelete: "Unable to delete user",
                    RoleRequired: "Role is Required",
                    EmptyRow: "Cannot save empty row!",
                    OneExam: "You must input at least one exam",
                    AnswerQuestion: "You must select an answer to the question.",
                    OneLicense: "You must input at least one license.",
                    ConfirmQuestion: "Please answer confirmation question",
                    TranscriptInNCEESConfirmQuestion: "Will education transcripts be included in your NCEES Record? is required.",
                    ProfessionRequired: "Type of Profession is required",
                    OneRecord: "At least one record is required",
                    OneDoc: "At least on document is required",
                    PleaseAdvise: 'Please be advised that, per our rules, an applicant who has failed to attain a passing score by the fourth examination attempt for either USMLE Step 2 or USMLE Step 3 shall thereafter be deemed ineligible for licensing.  You may contact the LSBME directly for further information.',
                    DAE: "Cannot mutliple DAE for single License!",
                    ChangeLicenseType: "Please change License Type for Conversion",
                    ResolveDef: "Please resolve all deficiencies before submitting application.",
                    ResolveDef2: "Please resolve all deficiencies before submitting application.",
                    ResolveDef3: "Resolve all deficiency before completing the review",
                    ResolveDef4: "Review and Save all the tabs before completing the review",
                    ReviewSaveDef: "Review and Save all the tabs before sending deficiency",
                    NoValidLicense: "No valid license is there for adding additional License/Endorsements.Please contact board office.",
                    SameContact: "Same contact already exists",
                    FingerPrint: "Please select Live Scan or Fingerprint Card",
                    FeeType: "Please add at least one fee type",
                    FeeRefund: "Please select atleast one fee type to refund",
                    FeeTransfer: "Please select atleast one fee type to transfer.",
                    ContactAlreadyExist: "Contact already exists",
                    ProcessData: "Error Processing",
                    ExpIsRequired: "Experience is required",
                    ExpSupplementRecordIsRequired: "Do you want to report experience to supplement your NCEES Record? is required.",
                    ExpAtLeastXRecordsRequired: "At least {{numberOfRecord}} engagements are required",
                    EitherExpOrEdu: "Either experience or education is required",
                    WorkInfoRequired: "Work information is required.",
                    SelectCredential: "Please select Credential",
                    AppNoUncarchive: "Application can not be unarchived",
                    AppArchive: "Some error occurred while archiving/unarchiving the application",
                    SearchField: "Please input at least one field to search.",
                    SessionExpired: "User Session Expired.",
                    InvalidUser: "Invalid UserName",
                    CEMinimum: "Continuing Education Information CE Hours minimum is 15",
                    CreditMinimum: "Please (minimum 30) credits have you earned toward a degree",
                    CheckDeclaration: 'Check declaration',
                    InvalidUserPass: "Invalid UserName Or Password",
                    InvalidOTP: "Invalid OTP",
                    SelectDocument: "Please select document",
                    FutureDate: "Please select future date",
                    SelectApp: "Please select an Application Type to create an application",
                    SelectSubApp: "Please select an Application Sub Type to create an application",

                    UploadDocFor: function (uploadfor) {
                        return 'Please upload doucment for ' + uploadfor;
                    },
                    EndDateGrater: 'End date cannot be greater than Start date',
                    EitherCellOrHome: "Either Cell Phone or Home Phone is Required",
                    IsRequired: function (label) { return '{0} is required'.format(label) },
                    EitherExcemption: 'Either select first four options or apply for exemption',
                    PleaseEither: "Please either fill all fields of document to upload or keep all empty",
                    NotEligibleRenewal: function (yesno, index) { return 'You are not eligible for renewal as you have selected ' + (yesno ? 'Yes' : 'No') + ' for question ' + (index + 1) },
                    CheckInfo: "Please check the information entered and try again.",
                    DOBFormat: "Please enter Date of Birth in valid MM/DD/YYYY format (Reference Code: 00016).",
                    Years18: "You need to be 18 years to complete the Registration. Please contact Board Office (Reference Code: 00017).",
                    OTP: "OTP send failed. Please click on Send new OTP icon to generate a new OTP.",
                    AddCE: "Please add atleast one ce course",
                    PrimaryAddressExists: "Primary Office Address Already Exists",
                    OnlyOneAddress: "Only one address can be your mailing address.",
                    AddressExistsCurrentStats: "Address Already Exists with current status.",
                    UploadEither: function (uploadFor) {
                        if (uploadFor)
                            return 'Please upload either doucment for ' + uploadFor + ' or enter description in the text box';
                        return 'Please upload either doucment or enter description in the text box';
                    },
                    LoginWUsername: function (message) { return "Error in the Login Process: Loginwithusername (Reference Code: 00059). When contacting Board Office, please refer the Reference Code in the communication." + message },
                    Credentials: "Login credentials entered is not valid. Please try again or click on Forgot Password link to reset your password. Login failed (Reference Code: 00058).",
                    AddressExist: function (addressTypeName) { return "You already have a Current " + addressTypeName + ", if you want to modify please click on edit button." },
                    ContactExist: function (contactTypeName) { return "You already have a " + contactTypeName + " with Current status, if you want to modify please click on edit button." },
                    Renew: "You might have either submitted your online renewal application or may not be able to renew online. Please contact the Alabama Board of Professional Engineers and Professional Land Surveyors.",
                    PayorBeforeBeneficiary: "Please add Payor information before adding beneficiary.",
                    AtleastOneBeneficiary: "Please add atleast one beneficiary.",
                    PaymentAmountNotMatching: "Payment Amount is not matching to total of all beneficiary fee types.",
                    OrganizationCanNotBeBeneficiary: "Organization can not be a beneficiary.",
                    IndividualCanNotBeBeneficiary: "Individual can not be a beneficiary.",
                    deleteConfirmation: "Are you sure you want to Delete receipt?"
                },
                Success: {
                    CESave: "CE Course Saved successfully",
                    Wallet: "Wallet card generated successfully",
                    UserDelete: "User Profile deleted successfully.",
                    ProfileDelete: "Profile deleted successfully.",
                    TaskModified: "Task Modified Successfully!",
                    Saved: "Saved Successfully!",
                    TaskExecuted: "Task Executed Successfully!",
                    Delete: "Deleted Successfully.",
                    DocsSaved: "All Documents have been generated",
                    InvoiceGenerated: "Invoice generated successfully",
                    InvoiceSaved: "Invoice saved successfully",
                    AppDelete: "Application deleted successfully",
                    FingerPrint: "Fingerprint status updated successfully",
                    EmailSent: "Email sent Successfully.",
                    ResolveDef: "All Deficiencies Resolved Successfully"

                },
                Confirm: {
                    Delete: "Are you sure you want to Delete?",
                    DeleteNote: "Are you sure you want to Delete note?",
                    DeleteDocument: "Really want to delete this document?",

                    DenySecureAccess: "Do you want to Deny the request for secure access to the California Acupunture Board CEPP portal?",
                    DeleteRecord: "Are you sure you want to Delete Record?",
                    UnlinkRecord: "Are you sure want to deassociate this record?",
                    RemoveFromLicense: "By clicking 'OK' ALL of the items selected will be removed from your license and it will cost you to reinstate those licenses and/or endorsements in the future",
                    DeleteUser: function (user) { return 'Are you sure you want to Delete user ' + user + "?"; },
                    UnlockApp: "Are you sure you want to unlock this application?",
                    ArchiveApp: "Are you sure you want to archive this application?",
                    UnarchiveApp: "Are you sure you want to un-archive this application?",
                    CloseApp: "Are you sure you want to close this application?",
                    FingerPrint: "Please confirm if you want to make this record as Fingerprint processing?",
                }
            },
            PasswordRequirement: "All Passwords must meet the following criteria:\n" +
                "1. They cannot be the user’s First or Last Name.\n" +
                "2. The password must contain at least 8 characters\n" +
                "3. They must contain at least ONE Upper Case Letter A, B, C\n" +
                "4. They must contain at least ONE Lower Case Letter a, b, c\n" +
                "5. They must contain at least ONE Number 0, 1, 2, 3, 4, 5, 6, 7, 8, 9\n" +
                "6. They must contain at least ONE of the following Special Characters:\n" +
                "` ~ ! @ # $ % ^ & * ( ) _ - + = { } [ ] | : ; \" ' < > , . ? \\ /",
            Registration: {
                Errors: {
                    Age: "You need to be 18 years to complete the Registration. Please contact Board Office.",
                    BirthDayFormat: "Please enter Date of Birth in valid MM/DD/YYYY format.",
                    EmailConfirmationMatch: "Email Address and Confirm Email Address does not match.",
                    IndividualSelect: "Error in the Registration Process: IndividualSelect. When contacting Board Office, please refer the Reference Code in the communication.",
                    IndividualUpdate: "Some Error occurred while updating individual",
                    ApplicationSaveProcess: function (ApplicationTypeId) {
                        return 'Error in the Registration Process: ApplicationSave, ApplicationTypeId=' + ApplicationTypeId + '  (Reference Code: 00039). When contacting Board Office, please refer the Reference Code in the communication'
                    },
                    IndividualNameTypeLkSave: function (ApplicationTypeId) {
                        return 'Error in the Registration Process: IndividualNameTypeLkSave, ApplicationTypeId=' + ApplicationTypeId + '  (Reference Code: 00044). When contacting Board Office, please refer the Reference Code in the communication.'
                    },
                    UsersProcess: function (ApplicationTypeId) {
                        return 'Error in the Registration Process: Users, ApplicationTypeId=' + ApplicationTypeId + '  (Reference Code: 00047). When contacting Board Office, please refer the Reference Code in the communication.'
                    },
                    UserRoleSaveProcess: function (ApplicationTypeId) {
                        return 'Error in the Registration Process: UserRoleSave, ApplicationTypeId=' + ApplicationTypeId + '  (Reference Code: 00050). When contacting Board Office, please refer the Reference Code in the communication.'
                    },
                    UserChangeLogSaveProcess: function (ApplicationTypeId) {
                        return 'Error in the Registration Process: UserChangeLogSave, ApplicationTypeId=' + ApplicationTypeId + '  (Reference Code: 00049). When contacting Board Office, please refer the Reference Code in the communication.'
                    },
                    ApplicationStatusLkSaveProcess: function (ApplicationTypeId) {
                        return 'Error in the Registration Process: ApplicationStatusLkSave, ApplicationTypeId=' + ApplicationTypeId + '  (Reference Code: 00041). When contacting Board Office, please refer the Reference Code in the communication.'
                    },
                    RegistrationError: 'Error in the Registration Process. When contacting Board Office, please refer the Reference Code in the communication.',
                    IndividualApplicationSave: 'Error in the Registration Process: IndividualApplicationSave, ApplicationTypeId=7  (Reference Code: 00043). When contacting Board Office, please refer the Reference Code in the communication.',
                    IndividualLogSaveProcess: 'Error in the Registration Process: IndividuallogSave, ApplicationTypeId=7  (Reference Code: 00051). When contacting Board Office, please refer the Reference Code in the communication.',
                },
                Success: {
                    Registration: "A temporary password has been sent to the email address used to create your account.  Please use the temporary password when signing into your account for the first time.  You will be asked to create a new password.",
                }
            },
            Payment: {
                Error: "Error Processing Payment. Please verify payment information."
            }
        })
})();
