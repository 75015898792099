(function () {
    'use strict'

    angular.module('app.core').factory('contentService', contentService)

    contentService.$inject = ['$http', '$q', 'WebApiUrl']

    function contentService($http, $q, WebApiUrl) {

        var _getContentByContentItemLkIdList = function (contentItemLkIdList, key, getInObjectsForm) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Content/ContentGetBYContentItemLkIdList?Key=' + key, contentItemLkIdList)
                .then(function (data) {
                    data = data.data;
                    if (!!getInObjectsForm) {
                        data.ContentItems = {};
                        angular.forEach(data.ContentItemLk, function (contentItem) {
                            data.ContentItems['contentItem' + contentItem.ContentItemLkId] = contentItem;
                        });
                    }
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        var _getContentByContentItemLkId = function (contentItemLkId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Content/LookupContentGetBYContentItemLkID/' + key + '?ID=' + contentItemLkId)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        var _getContent = function (contentLkToPageTabSectionId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Content/ContentGetBYContentLkToPageTabSectionId?Key=' + key + '&ContentLkToPageTabSectionId=' + contentLkToPageTabSectionId)
                .then(function (data) {
                    data = data.data;
                    data.ContentItems = {};
                    angular.forEach(data.ContentItemLk, function (contentItem) {
                        data.ContentItems[contentItem.ContentItemLkCode] = contentItem;
                    });
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        return {
            getContentByContentItemLkIdList: _getContentByContentItemLkIdList,
            getContentByContentItemLkId: _getContentByContentItemLkId,
            getContent: _getContent
        }
    }
})();
