﻿(function () {
    'use strict'
    angular.module('app.provider')
        .controller('providerApplicationDocumentCont',['$scope', 'providerApplicationDocumentFactory', 'WebApiUrl', '$state', 'FirmLicenseRenewalHASnAService',  'Messages', 
			function($scope, providerApplicationDocumentFactory, WebApiUrl, $state, FirmLicenseRenewalHASnAService, Messages) {
            var _key = sessionStorage.Key;
            $scope.init = function () {
                if ($scope.providerId) {
                    getApplicationDocumentByProviderId();
                    $scope.DownloadDocumentAPIURL = WebApiUrl + 'Individual/DownloadPDF/' + _key + '?path=';
                }
            };

            var getApplicationDocumentByProviderId = function () {
                providerApplicationDocumentFactory.applicationDocumentByProviderId(_key, $scope.providerId, sessionStorage.UserID).then(function (res) {
                    if (res.data.Status) {
                        $scope.ApplicationDocument = res.data.ApplicationDocumentList;
                    }
                });
            };

            $scope.renewBtnClick = function (value) {
                sessionStorage.LicenseNumber = value.LicenseNumber;
                // sessionStorage.ProviderName = value.LicenseNumber;
                sessionStorage.CurrentProviderId = value.EntityId;
                // $state.go('app.FirmRenewal');
            };

            var WyomingProviderRenewalGet = function () {
                ShowLoader();

                $scope.showRenewButton = false;
                FirmLicenseRenewalHASnAService.WyomingProviderRenewalGet(sessionStorage.Key, sessionStorage.ProviderId, false).then(function (res) {
                    if (res.data.Status && (res.data.Message == 'Success' || $scope.isBackoffice == true)) {

                        sessionStorage.LicenseNumber = res.data.ProviderRenewal.ProviderLicense[0].ProviderLicenseNumber
                        $scope.showRenewButton = true;
                        HideLoader();
                    }
                    else {
                        $scope.showRenewButton = false;
                        HideLoader();
                    }
                }, function () { HideLoader() });
            };
            WyomingProviderRenewalGet();


            $scope.addLicenseNumber = function (LicenseNumber, userId) {

                if (LicenseNumber || userId) {
                    ShowLoader();
                    providerApplicationDocumentFactory.GetEntityLicenseAssociation(sessionStorage.Key, LicenseNumber, userId).then(function (response) {
                        if (response.data.Status && !response.data.lstEntityLicenseAssociation) {
                            if (LicenseNumber) {
                                GetProviderLicenseByCertificateNumber(LicenseNumber);
                            }
                            else {
                                HideLoader();
                            }
                        }
                        else {
                            HideLoader();
                            if (!userId)
                                showStatusMessage(Messages.dbccced, "error");
                            else
                                $scope.EntityLicenseAssociations = response.data.lstEntityLicenseAssociation

                        }
                    }, function (error) {
                        HideLoader();
                    });
                }
                else {
                    HideLoader();
                    showStatusMessage(Messages.fedcbeb, "error");
                }

            }

            var GetProviderLicenseByCertificateNumber = function (LicenseNumber) {
                providerApplicationDocumentFactory.GetProviderLicenseByCertificateNumber(sessionStorage.Key, LicenseNumber).then(function (response) {
                    if (response.data.Status) {
                        $scope.response = response.data;
                        $scope.response.LicenseNumber = LicenseNumber;
                        HideLoader();
                        $('#addLicenseNumbermodel').modal('show');
                    }
                    else {
                        HideLoader();
                        showStatusMessage(Messages.bcededb, "error");
                    }
                }, function (error) {
                    HideLoader();
                });
            }


            $scope.associatedRecord = function () {
                $('#addLicenseNumbermodel').modal('hide');
                ShowLoader();
                var saveData = {
                    UserId: sessionStorage.UserID,
                    LicenseNumber: $scope.response.LicenseNumber,
                    EntityId: $scope.response.ProviderId,
                    EntityType: 'P',
                    LicenseTypeId: $scope.response.ProviderTypeId,
                    EntityLicenseId: $scope.response.ProviderLicenseId,
                    IsActive: true,
                    IsDeleted: false
                }
                providerApplicationDocumentFactory.SaveEntityLicenseAssociation(sessionStorage.Key, [saveData]).then(function (saveResponse) {
                    if (saveResponse.data.Status) {
                        $scope.addLicenseNumber('', sessionStorage.UserID);
                    }
                    else {
                        HideLoader();
                    }
                }, function (error) {
                    HideLoader();
                });
            }

            $scope.addLicenseNumber('', sessionStorage.UserID);

        }]);
})();