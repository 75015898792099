(function () {
  'use strict'

  angular.module('app.InitialApplication')
    .factory('initialApplicationChildSupportService', initialApplicationChildSupportService)

  initialApplicationChildSupportService.$inject = ['$http', '$q', 'WebApiUrl']

  function initialApplicationChildSupportService($http, $q, WebApiUrl) {

      var _childSupportSave = function (childSupportObj, key) {
          var deferred = $q.defer();
          $http.post(WebApiUrl + 'Individual/IndividualChildSupportSave/' + key, childSupportObj)
           .then(function(data){ data = data.data;
               deferred.resolve(data);
           }, function(error) { error = error.data;
               deferred.reject(error);
           });

          return deferred.promise;
      }
    return {
        childSupportSave: _childSupportSave
    };
  }
})();