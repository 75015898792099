(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InstructionsController", InstructionsController);

    InstructionsController.$inject = ["$scope", "utilityService", "contentService"];

    function InstructionsController($scope, utilityService, contentService) {
        $scope.init = function () {
            $scope.providerId = sessionStorage.ProviderId;
            $scope.getInstructionsConfig();
        };

        $scope.getInstructionsConfig = function () {
            var relativeFilePath = "/individual/application/instructions/instructions.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function (data) {
                    $scope.instructionsConfig = data;
                    $scope.getContentByLkItemId();
                    $scope.isConfigLoaded = true;
                }, function (error) {
                    ShowErrMsg("instructionConfigNotLoaded", "Some error occurred,Unable to load the instruction config");
                });
        };

        $scope.$on('SwitchingTab', function (event, args) {
            if (args.fromTab == $scope.tab.name) {
                if (!$scope.isBackoffice) {
                    $scope.saveNext(true);
                } else {
                    $scope.$broadcast("SaveDeficiency", {});
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
            }
        });

        var ShowErrMsg = function (propName, errorMessage) {
            propName = (!!propName) ? propName : "instructionsErr";
            errorMessage = (!!errorMessage) ? errorMessage : $scope.instructionsConfig.ValidationMsg.APIError;
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            var propExist = false;
            for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                if ($scope.natValidation.errorMessages[i].propName == propName)
                    propExist = true;
            }
            if (!propExist)
                $scope.natValidation.errorMessages.push({
                    propName: propName,
                    errorMessage: errorMessage
                });
            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };


        $scope.getContentByLkItemId = function () {
            if (!!$scope.providerId && $scope.providerId != "null") {
                var contentItemLks = 'Firm Instructions';
            }

            else {
            var contentItemLks = 'Instructions';
            }
            if ($scope.isRenewal) {
                //contentItemLks = 1;
                conetneItemLks = 'RenInstructions-G1';
                if ($scope.individualLicense.LicenseTypeId == 2)
                    //contentItemLks = 2;
                    contentItemLks = 'RenInstruction-G2';
            } else {
                if ($scope.individualLicense && $scope.individualLicense.licenseTypeInfo && $scope.individualLicense.licenseTypeInfo.InitialApplicationInstructionContentId) {
                    contentItemLks = $scope.individualLicense.licenseTypeInfo.InitialApplicationInstructionContentId;
                }
            }
            $scope.contentItemLks = contentItemLks;
        };

        $scope.saveNext = function (skipNextTab) {
            ShowLoader();
            $scope.tab.skipNextTab = skipNextTab;
            $scope.openNextTab({
                currentTab: $scope.tab
            });
            if ($scope.tab.skipNextTab) {
                $scope.$emit('setCurrentTabNow');
            }
        };

        $scope.clearTabConfirmation = function () {
            $scope.$emit("clearTabConfirmation", { currentTab: $scope.tab });
        };

        $scope.init();
    }
})();