﻿(function () {
    'use strict'
    angular.module('app.provider')
        .directive('providerApplicationDocumentDirective', [function () {
            return {
                restrict: 'EA',
                scope: {
                    providerId: '=providerId',
                    displayRenewBtn: '='
                },
                controller: "providerApplicationDocumentCont",
                templateUrl: "app/components/provider/directive/provider-application-document/provider-application-document.html",
                link: function ($scope, element, attrs, ngCtrl) {

                }
            };
        }]);
})();