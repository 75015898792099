(function () {
    'use strict'
    angular.module('app.core')
        .controller('MultipleFileUploadListController', MultipleFileUploadListController);

    MultipleFileUploadListController.$inject = ['$controller', '$rootScope', '$scope', '$http', '$q', '$filter', 'multipleFileUploadService',
        'IndividualDocumentSaveService', 'utilityService', '$exceptionHandler', 'Messages'
    ];

    function MultipleFileUploadListController($controller, $rootScope, $scope, $http, $q, $filter,
        multipleFileUploadService, IndividualDocumentSaveService, utilityService, $exceptionHandler, Messages) {
        $controller('GlobalController', {
            $scope: $scope
        });
        $scope.isBackOffice = sessionStorage.isBackOfficeUser;
        $scope.documentByIndividualDocumentId = multipleFileUploadService.documentByIndividualDocumentId;
        $scope.sortType = 'EffectiveDate';
        $scope.sortOrder = '-';
        $scope.documentSearch = {
            SearchText: ''
        };
        $scope.current ={};

        $scope.userCanDelete = true;
        var parent = $scope.$parent;
        for(var i = 0; i<10;i++){
            parent = parent.$parent;
            if(!!parent.applicationInfo){
                if(![1].includes(parent.applicationInfo.ApplicationStatusId)){
                    $scope.userCanDelete = false;
                    break;
                }
            }
        }

        $scope.natValidation = {};
        $scope.sendEmail = {};
        $scope.changeSort = function (sortType) {
            if ($scope.sortType == sortType) {
                if ($scope.sortOrder == '+')
                    $scope.sortOrder = '-';
                else
                    $scope.sortOrder = '+';
            } else {
                $scope.sortOrder = '+';
            }
            $scope.sortType = sortType;
        };

        $scope.$on("loaddocumentListing", function (evt, individualId) {
            $scope.individualDocumentGetByIndividualId(individualId);
        });

        $scope.onSelectionChanged = function (isAllSelectedClicked, currentValue) {
            if (!!isAllSelectedClicked) {
                $scope.documentsList.map(function (applicationItem) {
                    applicationItem.isSelected = currentValue;
                });
            } else {
                $scope.current.isAllSelected = ($scope.documentsList.where(function (item) {
                    return (!item.DisciplineFlag && (item.ApplicationSubTypeId == 2 || item.FingerprintStatus == "MatchedPass") && item.IsPaymentProcessed == true && (item.ApplicationSubTypeId == 2 || !item.ExamRequired || item.ExamStatus == "Passed"));
                }).all('!!item.isSelected'));
                $scope.current.isAllSelected = $scope.documentsList.all('!!item.isSelected');
            }
            $scope.current.isAnySelected = ($scope.documentsList.any('!!item.isSelected'));
        };

        $scope.downloadBulkDocumentList = function () {
            $scope.documentsList.where(function (item) {
                if(item.isSelected){
                    ShowLoader("Please wait...");
                    $scope.documentByIndividualDocumentId(item.IndividualDocumentId)
                }
            });
        };

        $scope.individualDocumentGetByIndividualId = function (IndividualId) {
            try {
                if ($scope.processLoader)
                    ShowLoader("Please wait...");
                multipleFileUploadService.individualDocumentGetByIndividualId(sessionStorage.Key, IndividualId)
                    .then(function (response) {
                        $scope.documentsList = [];
                        hideStatusMessage();
                        hideValidationSummary();
                        if ($scope.checkResponse(response)) {
                            if (response.IndividualDocumentGetList != null) {
                                $scope.DocLKToPageTabSelectionID = response.IndividualDocumentGetList[0].DocumentLkToPageTabSectionId;
                                if (!!$scope.individualDocumentUpload && !!$scope.individualDocumentUpload.DocId && !!$scope.individualDocumentUpload.LoadByDocIdAndDocCode) {
                                    var documentsListFiltered = $filter('filter')(response.IndividualDocumentGetList, function (doc) {
                                        return (doc.DocumentId === $scope.individualDocumentUpload.DocId &&
                                            (!$scope.individualDocumentUpload.ApplicationId || ($scope.individualDocumentUpload.ApplicationId == doc.ApplicationId)) &&
                                            (!!$scope.referenceNumber ? (doc.ReferenceNumber == $scope.referenceNumber) : true));
                                    });
                                    if (!!$scope.individualDocumentUpload && !!$scope.individualDocumentUpload.LinkId && !!$scope.individualDocumentUpload.LinkType) {
                                        if (!!documentsListFiltered) {
                                            documentsListFiltered = documentsListFiltered.whereEquals($scope.individualDocumentUpload.LinkId, 'LinkId').whereEquals($scope.individualDocumentUpload.LinkType, 'LinkType');
                                        }
                                    }
                                    $scope.documentsList = documentsListFiltered.orderByDescending('EffectiveDate');
                                    $scope.individualDocumentUpload.documentCount = $scope.documentsList.length;
                                } else {
                                    var documentsListFiltered = [];
                                    if (!!$scope.individualDocumentUpload.MultipleDocId) {
                                        documentsListFiltered = $filter('filter')(response.IndividualDocumentGetList, function (doc) {
                                            return (($scope.individualDocumentUpload.MultipleDocId.indexOf(doc.DocumentId) != -1) &&
                                                (!$scope.individualDocumentUpload.ApplicationId || ($scope.individualDocumentUpload.ApplicationId == doc.ApplicationId)));
                                        });
                                    } else {
                                        documentsListFiltered = $filter('filter')(response.IndividualDocumentGetList, function (doc) {
                                            return (((!$scope.individualDocumentUpload.ApplicationId || ($scope.individualDocumentUpload.ApplicationId == doc.ApplicationId))) &&
                                                (!$scope.individualDocumentUpload.ExcludeDocId || ($scope.individualDocumentUpload.ExcludeDocId != doc.DocumentId)));
                                        });
                                    }
                                    if (!!$scope.individualDocumentUpload && !!$scope.individualDocumentUpload.LinkId && !!$scope.individualDocumentUpload.LinkType) {
                                        if (!!documentsListFiltered) {
                                            documentsListFiltered = documentsListFiltered.whereEquals($scope.individualDocumentUpload.LinkId, 'LinkId').whereEquals($scope.individualDocumentUpload.LinkType, 'LinkType');
                                        }
                                    }
                                    $scope.documentsList = documentsListFiltered;
                                    if (!!$scope.individualDocumentUpload)
                                        $scope.individualDocumentUpload.documentCount = $scope.documentsList.length;
                                }
                            }
                        }
                        if ($scope.processLoader)
                            HideLoader();
                    }, function (data) {
                        HideLoader();
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                showStatusMessage(ex.message, "error");
                $exceptionHandler(ex);
            }
        };

        $scope.individualDocumentDeleteByID = function (docData) {
            try {
                if (confirm(Messages.General.Confirm.DeleteDocument)) {
                    $rootScope.individualEventLog('deleteStatus.deleteConfirmed', $scope.individualDocumentUpload.IndividualId);
                    ShowLoader("Please wait...");
                    multipleFileUploadService.individualDocumentDeleteByID(sessionStorage.Key, docData.IndividualDocumentId)
                        .then(function (response) {
                            var index = $scope.documentsList.indexOf(docData);
                            $scope.documentsList.splice(index, 1);
                            if (!!$scope.individualDocumentUpload)
                                $scope.individualDocumentUpload.documentCount = $scope.documentsList.length;
                            HideLoader();
                        }, function (error) {
                            showStatusMessage(ex.message, "error");
                        });
                } else {
                    $rootScope.individualEventLog('deleteStatus.deleteCanceled', $scope.individualDocumentUpload.IndividualId);
                }
            } catch (ex) {
                showStatusMessage(ex.message, "error");
                HideLoader();
                $exceptionHandler(ex);
            }
        };

        if (!!$scope.individualDocumentUpload && (!!$scope.individualDocumentUpload.LoadDocumentsWithoutBroadCast)) {
            $scope.individualDocumentGetByIndividualId($scope.individualDocumentUpload.IndividualId);
        }

        var gettemplate = function () {
            multipleFileUploadService.getAllTemplates().then(function (response) {
                $scope.templateList = response;
            }, function (error) {

            });
        }

        $scope.openEmailModel = function (docData) {
            $scope.sendEmail.email = sessionStorage.currentUserEmail;
            $scope.template = {};
            $scope.template.TemplateId = "";
            $scope.docData = docData;
            $scope.correspondenceEmailBlock = true;
        };

        var validateForm = function (curentForm) {
            $scope.natValidation.errorMessages = [];
            if (!!curentForm) {
                curentForm.$submitted = true;
                if (!curentForm.errorMessages)
                    curentForm.errorMessages = [];
                $scope.natValidation.errorMessages = angular.copy(curentForm.errorMessages);
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };


        $scope.sendEmail = function (emailDocumentForm) {
            if (validateForm(emailDocumentForm)) {
                ShowLoader();
                multipleFileUploadService.individualDocumentEmailSend(sessionStorage.Key, $scope.docData.IndividualId, $scope.docData.IndividualDocumentId, $scope.docData.DocumentId, $scope.docData.ApplicationId, $scope.sendEmail.email, $scope.template.TemplateId).then(function (response) {
                    HideLoader();
                    showStatusMessage(Messages.General.Success.EmailSent, "success");
                    $scope.cancelEmail();
                }, function (error) {
                    HideLoader();
                });
            }
        };

        $scope.cancelEmail = function () {
            $scope.correspondenceEmailBlock = false;
        };

        if ($scope.isEmailSend) {
            gettemplate();
        }
    }
})();
