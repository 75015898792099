﻿(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('backofficeProviderResposibleChargeService', backofficeProviderResposibleChargeService)

    backofficeProviderResposibleChargeService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeProviderResposibleChargeService($http, $q, WebApiUrl, AppConfig) {

        var _getEmployerList = function (key, searchString, providerTypeId, searchType) {
            var deferred = $q.defer();
            searchString = encodeURIComponent(searchString);
            if (searchString == '') {
                $http.get(WebApiUrl + "Individual/GetEmployerNameAndAddress/" + key + "?SearchString=&ProviderTypeId=" + providerTypeId + "&SearchType=" + searchType)
                    .then(function(data){ data = data.data;
                        deferred.resolve(data);
                    }, function(error) { error = error.data;
                        deferred.reject(error);
                    });
            }
            else {
                $http.get(WebApiUrl + "Individual/GetEmployerNameAndAddress/" + key + "?SearchString=" + searchString + "&ProviderTypeId=" + providerTypeId + "&SearchType=" + searchType)
                    .then(function(data){ data = data.data;
                        deferred.resolve(data);
                    }, function(error) { error = error.data;
                        deferred.reject(error);
                    });
            }


            return deferred.promise;
        };
        var _getProviderId = function (key, ProviderId, individualId) {

            if (!individualId) {
                individualId = 0;
            }
            //if (ProviderIndividual == null) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + "Provider/GetProviderIndividual/" + key + "?IndividualId=" + individualId + "&ProviderId=" + ProviderId + "&Source=" + AppConfig.Source)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
            //}
            //else            {
            //    return returnDefferedResult($http.post(WebApiUrl + "Provider/SaveProviderIndividual/" + key + "?Source=OL", ProviderIndividual));
            //}
        };

        var _getIndividual = function (key, IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + "Individual/IndividualBYIndividualId/" + key + "?IndividualId=" + IndividualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getProvider = function (key, ProviderId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + "Provider/GetProviderById/" + key + "?providerid=" + ProviderId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _saveProvider = function (key, ProviderIndividual) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/SaveProviderIndividual/' + key + '?Source=OL', ProviderIndividual)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };
        return {
            getEmployerList: _getEmployerList,
            providerIdBy_IndividualId: _getProviderId,
            providerDetails: _getProvider,
            providerSave: _saveProvider,
            individualDetail: _getIndividual
        };
    }
})();
