/// <reference path="../../../scripts/built-in-type-extensions.ts" />
/// <reference path="query-reporting-doc.ts" />

(function() {
    'use strict'

    angular.module('reporting.QueryReporting')
    .filter('qrDataTypeMap', [function() {
        return function(dataType) {
            if(!dataType) {
                return;
            }
            switch(dataType.toLowerCase()) {
                case 'char':
                case 'varchar':
                case 'binary':
                case 'varbinary':
                    return 'text';
                case 'integer':
                case 'int':
                case 'tinyint':
                case 'smallint':
                case 'mediumint':
                case 'bigint':
                case 'decimal':
                case 'numeric':
                case 'float':
                case 'double':
                    return 'number';
                case 'date':
                case 'timestamp':
                case 'year':
                    return 'datetime';
                case 'bit':
                    return 'boolean';
                default:
                    return dataType;
            }
        };
    }])
    .filter('qrDisplayInList', [function() {
        return function(columnList) {
            if (!columnList) { return []; }
            // return columnList.where(function(c) { return c.DisplayInList; });
            return columnList.where('item.DisplayInList');
        };
    }])
    .filter('qrTypeaheadFilter', [function() {
        return function(colExprList, str, getColLabel) {
            if (!colExprList) { return []; }
            
            if (!getColLabel) {
                getColLabel = function (c) { return c; };
            }

            var filteredList = colExprList.where(function (c) {
                return getColLabel(c).search(str) > -1;
            });

            return filteredList;
        };
    }])
    .controller('QueryMakerController', ['$scope', '$q', '$timeout', '$uibModal', 'QueryReportingService', 'QueryReportingUtility', QueryMakerController]);

    /**
     * 
     * @param {QueryMakerScope} $scope 
     * @param {*} $q 
     * @param {*} $timeout 
     * @param {*} $uibModal 
     * @param {QueryReportingService} QueryReportingService 
     */
    function QueryMakerController($scope, $q, $timeout, $uibModal, QueryReportingService, QueryReportingUtility) {
        var _key = '';

        /**
         * 
         */
        var setupUtilityMethods = function () {
            $scope.utilityMethods.resetProperties = resetProperties;            
            $scope.utilityMethods.getCopyOfCurrentQuery = getCopyOfCurrentQuery;
            $scope.utilityMethods.prepareQueryForSerialization = prepareQueryForSerialization;

            $scope.utilityMethods.clearQuery = $scope.clearQuery;

            $scope.utilityMethods.getTables = function() { return $scope.processedTables; };
            $scope.utilityMethods.getExpressionList = function () { return $scope.currentQuery.ExpressionList; };
            $scope.utilityMethods.setQuery = $scope.setQuery;
        };

        $scope.init = function () {
            _key = sessionStorage.Key;

            $scope.cachedTablePaths = {};
            $scope.entities = [];
            $scope.relationList = [];

            $scope.relationTypes=[
                'Inner Join',
                'Left Outer Join',
                'Left Outer Join (if null)',
                'Right Outer Join',
                'Right Outer Join (if null)'
            ];

            $scope.dateOptions = {
                maxDate: new Date(),
                minDate: new Date(1800,1,1),
                startingDay: 1
            };

            if (!$scope.currentQuery) {
                $scope.currentQuery = {};
                $scope.currentQuery.ExpressionList = [];
            }

            if(!$scope.utilityMethods) {
                $scope.utilityMethods = {};
            }
            setupUtilityMethods();

            $timeout(function() {
                if($scope.rightPanelLoaderMethod) {
                    $scope.$parent[$scope.rightPanelLoaderMethod] = $scope.loadingRightPanel;
                }
            },1);

            $q.all([getEntityList(), getAllTableRelation(), getTableRelationPaths()]).then(function () {
                postProcessTables();
                $scope.clearQuery();
                
                $scope.initialLoading(false);
            }, function (error) {
                $scope.initialLoading(false);
            });
        };


        /**
         * Asynchronously retrieves the entity list of the tables and columns for the tool
         */
        var getEntityList = function () {
            var deferred = $q.defer();
            try {
                QueryReportingService.getQueryReportingEntityList(_key)
                .then(function (data) {
                    if (data.Status) {
                        $scope.entities = data.EntityList;
                        $scope.entities.forEach(function (e) { 
                            e.RelationTableName = e.TableName;
                            e.RelationTableDisplayName = e.TableDisplayName;
                            e.RelationTableAlias = '';
                            e.ColumnList.forEach(function (c) {
                                c.IsExpressionColumn = false;
                                if(e.TableTypeId == 3) {
                                    c.reference = true;
                                } else {
                                    c.reference = false;
                                }
                            });
                        });
                        $scope.processedTables = angular.copy(data.EntityList);
                    } else if (!data.Status && data.Message) {
                        $scope.showStatusMsg({type: '-', message: data.Message});
                    }
                    deferred.resolve(data);
                }, function (err) {
                    if (err && err.statusText) {
                        $scope.showStatusMsg({type: '-', message: err.statusText});
                    }
                    deferred.reject(err);
                });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMsg({type:'-', message: ex.message}); }
                deferred.reject(ex);
            }
            return deferred.promise;
        };

        /**
         * Asynchronously retrieves the table relation list for the tool
         */
        var getAllTableRelation = function () {
            var deferred = $q.defer();
            try {
                QueryReportingService.getTableRelationByTable(_key, '')
                .then(function (data) {
                    if (data.Status) {
                        //Group the relation list together by their tables, then get an array of column names
                        //The purpose for this is to determine if there are multiple columns that reference the same table
                        //In those instances this will enable the user to select which columns they want to relate
                        $scope.relationList = data.RelationList
                            .groupBy('TableName,RelationTableName')
                            .select(function(a){
                                /** @type {QueryTableRelation} */
                                var templ = angular.copy(a.values[0]);
                                templ.ColumnName = a.values.select(function(v){return v.ColumnName;});
                                templ.RelationColumnName = a.values.select(function(v){return v.RelationColumnName;});
                                templ.RelationType = 0;
                                return templ;
                            });
                    } else if (!data.Status && data.Message) {
                        $scope.showStatusMsg({type: '-', message: data.Message});
                    }
                    deferred.resolve(data);
                }, function (err) {
                    if (err && err.statusText) {
                        $scope.showStatusMsg({type: '-', message: err.statusText});
                    }
                    deferred.reject(err);
                });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMsg({type: '-', message: ex.message}); }
                deferred.reject(ex);
            }
            return deferred.promise;
        };

        /**
         * 
         */
        var getTableRelationPaths = function () {
            var deferred = $q.defer();
            
            try {
                QueryReportingService.getTableRelationPaths(_key)
                .then(function(data) {
                    if(data.Status) {
                        $scope.cachedTablePaths = data.TableRelationPaths;
                    } else {
                        $scope.showStatusMsg({type: '-', message: data.Message});
                    }
                    deferred.resolve(data);
                }, function (error) {
                    deferred.reject(error);
                });
            } catch(ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        /**
         * Enables/Disables tables in the table list based on the current table selection and the available relationships
         */
        var processTableEnableDisable = function () {
            var selectedRelationship = $scope.selectedRelationship;
            var source = '';

            if(!$scope.processedTables) {
                return;
            }

            if(selectedRelationship) {
                $scope.processedTables.forEach(function (t) {
                    t.isEnabled = false;
                });

                source = selectedRelationship.RelationTableName.toLowerCase();

                if($scope.cachedTablePaths[source]) {
                    var sourcePaths = angular.copy($scope.cachedTablePaths[source]);
                    for (var destination in sourcePaths) {
                        if (!sourcePaths.hasOwnProperty(destination.toLowerCase())) {
                            continue;
                        }

                        //Make sure the destination table is a valid path given the current selection as well
                        /** @type {QueryTableRelation[]} */
                        var path = sourcePaths[destination.toLowerCase()];// removeInvalidPathNodes(sourcePaths[destination.toLowerCase()], selectedRelationship);
                        if(validateRelationPath(selectedRelationship, path)) {
                            //Enable this table in the list
                            $scope.processedTables.forEach(function (t) {
                                if (t.TableName.toLowerCase() == destination.toLowerCase()) {
                                    t.isEnabled = true;
                                }
                            });
                        }
                    }
                }
            } else {
                $scope.processedTables.forEach(function (t) {
                    t.isEnabled = true;
                });
            }
        };

        /**
         * Stores the relation paths from one table to another in a "dictionary" object
         */
        var postProcessTables = function () {
            if(!$scope.relationList) {
                $scope.cachedTablePaths = {};
                return;
            }

            var processRelationList = function (rel) {
                var fromTable = QueryReportingUtility.getTableFromList(rel.TableName.toLowerCase(), $scope.processedTables);
                rel.table = QueryReportingUtility.getTableFromList(rel.RelationTableName.toLowerCase(), $scope.processedTables);
                //rel.ColumnList = angular.copy(rel.ColumnList);

                if(fromTable) {
                    rel.TableDisplayName = fromTable.TableDisplayName;
                } else {
                    rel.TableDisplayName = rel.TableName;
                }

                if(rel.table) {
                    rel.RelationTableDisplayName = rel.table.TableDisplayName;
                } else {
                    rel.RelationTableDisplayName = rel.RelationTableName;
                }
            };

            var getRelationship = function (rel) {
                return $scope.relationList.where(function (r) {
                    return r.TableName == rel.TableName &&
                           r.RelationTableName == rel.RelationTableName
                })[0];
            }

            $scope.relationList.forEach(processRelationList);

            for(var sourceTable in $scope.cachedTablePaths) {
                if(!$scope.cachedTablePaths.hasOwnProperty(sourceTable)) {
                    continue;
                }

                for (var destinationTable in $scope.cachedTablePaths[sourceTable]) {
                    if(!$scope.cachedTablePaths[sourceTable].hasOwnProperty(destinationTable)) {
                        continue;
                    }

                    for (var i = 0; i < $scope.cachedTablePaths[sourceTable][destinationTable].length; i++) {
                        $scope.cachedTablePaths[sourceTable][destinationTable][i] = getRelationship($scope.cachedTablePaths[sourceTable][destinationTable][i]);
                    }
                }
            }
        };

        /**
         * Resets the properties of the UI back to their defaults
         */
        var resetProperties = function () {
            $scope.currentQuery.From = undefined;
            $scope.currentQuery.Join = [];
            [$scope.currentQuery.Where].recursiveSelect('Conditions').selectMany().where('!item.IsBlock').forEach(function (w){ w.removeWatch(); });
            $scope.currentQuery.Where = { Conditions: [] };
            $scope.currentQuery.GroupBy = [];
            $scope.currentQuery.OrderBy = [];
            $scope.currentQuery.Select = [];
            $scope.currentQuery.ExpressionList = [];
            $scope.currentQuery.QueryTypeId = 1;

            $scope.selectedRelationship = undefined;

            $scope.clearEntitySelection();
        };

        /**
         * Clears the column checkboxes
         */
        var clearColumnSelection = function () {
            if($scope.selectedRelationship && $scope.selectedRelationship.table) {
                $scope.selectedRelationship.table.ColumnList.forEach(function (c) { c.isSelected = false; });
            }
        };

        /**
         * 
         * @param {ReportQuery} query 
         * @param {QueryTable[]} tableList 
         */
        var setTableReferencesInQuery = function (query, tableList) {
            query.From.RelationTableName = query.From.TableName;
            query.From.RelationTableDisplayName = query.From.TableDisplayName;
            query.From.RelationTableAlias = query.From.TableAlias;
            query.From.table = QueryReportingUtility.getTableFromList(query.From.TableName, tableList);

            query.Join.forEach(function (rel) {
                rel.table = QueryReportingUtility.getTableFromList(rel.RelationTableName, tableList);
            });
        };


        /**
         * Validates that the query is consistent 
         * 
         * @param {ExecuteQuery} query 
         * @param {string} columnType 
         * @param {string[]} messages 
         * @returns {boolean}
         */
        var validateQuery = function (query, columnType, messages) {
            if(!messages) {
                messages = [];
            }
            
            //First, make sure no outside tables made it into the column selections
            /** @type {QueryColumn[]} */
            var whereColumns = [query.Where].recursiveSelect('Conditions').selectMany().where('!item.IsBlock && !!item.Column').select('Column');
            whereColumns = whereColumns.concat([query.Where].recursiveSelect('Conditions').selectMany().where('!item.IsBlock && !!item.ColumnValue').select('ColumnValue'));
            var whereValid = whereColumns.length == 0 || whereColumns.every(function(c) {
                if (c.IsExpressionColumn || !c.Column) {
                    return true;
                } else {
                    if (query.From.TableName.toLowerCase() != c.TableName.toLowerCase() && 
                       !query.Join.some(function(t) { return t.RelationTableName.toLowerCase() == c.TableName.toLowerCase() && t.RelationTableAlias == c.TableAlias })) {
                        //table does not exist
                        messages.push(c.FieldDisplayName + ': ' + c.TableDisplayName + ' is not currently selected.');
                        return false;
                    }
                }
               
                return true;
            });
            var groupValid = query.GroupBy.length == 0 || query.GroupBy.every(function(c) {
                if (c.IsExpressionColumn) {
                    return true;
                } else {
                    if (query.From.TableName.toLowerCase() != c.TableName.toLowerCase() && 
                       !query.Join.some(function(t) { return t.RelationTableName.toLowerCase() == c.TableName.toLowerCase() && t.RelationTableAlias == c.TableAlias })) {
                        //table does not exist
                        messages.push(c.FieldDisplayName + ': ' + c.TableDisplayName + ' is not currently selected.');
                        return false;
                    }
                }

                return true;
            });
            var orderValid = query.OrderBy.length == 0 || query.OrderBy.every(function(c) {
                if (c.IsExpressionColumn) {
                    return true;
                } else {
                    if (query.From.TableName.toLowerCase() != c.TableName.toLowerCase() && 
                       !query.Join.some(function(t) { return t.RelationTableName.toLowerCase() == c.TableName.toLowerCase() && t.RelationTableAlias == c.TableAlias })) {
                        //table does not exist
                        messages.push(c.FieldDisplayName + ': ' + c.TableDisplayName + ' is not currently selected.');
                        return false;
                    }
                }

                //Also check that order by columns are in the group by column list
                if (query.GroupBy.length > 0 && !query.GroupBy.some(function(g) { 
                        if(!g.IsExpressionColumn) {
                            return g.TableName.toLowerCase() == c.TableName.toLowerCase() && g.TableAlias == c.TableAlias && g.FieldName.toLowerCase() == c.FieldName.toLowerCase(); 
                        } else {
                            return false;
                        }
                    })) {
                    
                    if(columnType && columnType == 'group') {
                        messages.push('The selected Grouping columns must be added before adding Sorting columns');
                    } else if (columnType && columnType != 'group') {
                        messages.push('The selected columns are not present in Grouping list');
                    }
                    
                    return false;
                }
                return true;
            });
            var selectValid = query.Select.length == 0 || query.Select.every(function(c) {
                if (c.IsExpressionColumn) {
                    return true;
                } else {
                    if (query.From.TableName.toLowerCase() != c.TableName.toLowerCase() && 
                       !query.Join.some(function(t) { return t.RelationTableName.toLowerCase() == c.TableName.toLowerCase() && t.RelationTableAlias == c.TableAlias })) {
                        //table does not exist
                        messages.push(c.FieldDisplayName + ': ' + c.TableDisplayName + ' is not currently selected.');
                        return false;
                    }
                }

                //Also check that order by columns are in the group by column list
                if (query.GroupBy.length > 0 && !query.GroupBy.some(function(g) { 
                        if(!g.IsExpressionColumn) {
                            return g.TableName.toLowerCase() == c.TableName.toLowerCase() && g.TableAlias == c.TableAlias && g.FieldName.toLowerCase() == c.FieldName.toLowerCase(); 
                        } else {
                            return false;
                        }
                    })) {

                    if(columnType && columnType == 'group') {
                        messages.push('The selected Grouping columns must be added before adding Select columns');
                    } else if (columnType && columnType != 'group') {
                        messages.push('The selected columns are not present in Grouping list');
                    }

                    return false;
                }
                return true;
            });

            return whereValid && groupValid && orderValid && selectValid;
        };

        /**
         * Validates the relation path of a newly selected table within the context of the rest of the query
         * 
         * @param {QueryTableRelation} startTable 
         * @param {QueryTableRelation[]} path 
         */
        var validateRelationPath = function (startTable, path) {
            var currentTable = startTable;
            var viablePath = true;
            if(path.length == 0) {
                return false;
            }

            var last = path[path.length - 1];
            path.forEach(function(p) {
                if (currentTable.RelationTableName.toLowerCase() == p.TableName.toLowerCase()) {
                    currentTable = p;
                } else {
                    viablePath = false;
                }

                if(p.ColumnName.length > 1 && p !== last) {
                    viablePath = false;
                }
            });

            return viablePath;
        };

        /**
         * Updates any column references in the given expression, ensuring that the table alias is up-to-date
         * 
         * @param {QueryColumnExpression} expression 
         * @param {*} aliasChanges 
         */
        var updateExpressionAliases = function (expression, aliasChanges) {
            if(expression.LeftOperand) {
                expression.LeftOperand.Parameters.forEach(function (p) {
                    if(p.Value.IsColumn && !p.Value.Column.IsExpressionColumn) {
                        p.Value.Column.TableAlias = aliasChanges[p.Value.Column.TableAlias];
                    }
                });    
            }

            if(expression.Operator && expression.RightOperand) {
                expression.RightOperand.Parameters.forEach(function (p) {
                    if(p.Value.IsColumn && !p.Value.Column.IsExpressionColumn) {
                        p.Value.Column.TableAlias = aliasChanges[p.Value.Column.TableAlias];
                    }
                });
            }
        };

        /**
         * Updates all of the columns selected in the query (including those used in expressions) with the new table aliases
         * 
         * @param {ExecuteQuery} query 
         * @param {*} aliasChanges 
         */
        var updateColumnAliases = function (query, aliasChanges) {
            query.ExpressionList.forEach(function (e) {
                updateExpressionAliases(e.Expression, aliasChanges);
            });

            [query.Where].recursiveSelect('Conditions').selectMany().where('!item.IsBlock && !!item.Column').select('Column').forEach(function(w) {
                if(!w) {
                    return;
                }

                if(!w.IsExpressionColumn) {
                    w.TableAlias = aliasChanges[w.TableAlias];
                } else {
                    updateExpressionAliases(w.Expression, aliasChanges);
                }
            });
            [query.Where].recursiveSelect('Conditions').selectMany().where('!item.IsBlock && !!item.ColumnValue').select('ColumnValue').forEach(function(w) {
                if(!w) {
                    return;
                }

                if(!w.IsExpressionColumn) {
                    w.TableAlias = aliasChanges[w.TableAlias];
                } else {
                    updateExpressionAliases(w.Expression, aliasChanges);
                }
            });
            query.GroupBy.forEach(function (c) {
                if (!c.IsExpressionColumn) {
                    c.TableAlias = aliasChanges[c.TableAlias];
                } else {
                    updateExpressionAliases(c.Expression, aliasChanges);
                }
            });
            query.OrderBy.forEach(function (c) {
                if (!c.IsExpressionColumn) {
                    c.TableAlias = aliasChanges[c.TableAlias];
                } else {
                    updateExpressionAliases(c.Expression, aliasChanges);
                }
            });
            query.Select.forEach(function (c) {
                if (!c.IsExpressionColumn) {
                    c.TableAlias = aliasChanges[c.TableAlias];
                } else {
                    updateExpressionAliases(c.Expression, aliasChanges);
                }
            });
        };

        /**
         * Retrieves the currently selected relationship
         * 
         * @param {QueryTable} from 
         * @returns {QueryTableRelation}
         */
        var getSelectedRelation = function (from) {
            var tables = [from].concat([from].recursiveSelect('joins').selectMany());

            return tables.find(function (rel) { return rel.isSelected; });
        };
        
        /**
         * 
         * @param {QueryColumn[]} expressionList 
         * @param {string} name 
         * @returns {QueryColumn}
         */
        var getExpressionFromList = function (expressionList, name) {
            return expressionList.find(function (e) { return name == e.FieldName });
        };

        /**
         * Retrieves a copy of the current query
         * 
         * @param {boolean} deepCopy 
         * @returns {ExecuteQuery} 
         */
        var getCopyOfCurrentQuery = function (deepCopy) {
            return QueryReportingUtility.getCopyOfCurrentQuery($scope, deepCopy);
        };

        /**
         * 
         * @param {Object} source 
         * @param {Object} destination 
         */
        var copyPropertyValues = function (source, destination) {
            for (var prop in source) {
                if (!destination.hasOwnProperty(prop)) {
                    continue;
                }

                destination[prop] = source[prop];
            }
        };

        /**
         * Rebuilds the From/Join hierarchy for display in the UI
         * 
         * @param {QueryTable} from 
         * @param {QueryTableRelation[]} join 
         */
        var expandJoins = function (from, join) {
            var relationMapping = {};

            //First setup the relation mapping based on the recovered aliases
            relationMapping[from.TableAlias] = from;
            join.forEach(function (rel) {
                relationMapping[rel.RelationTableAlias] = rel;

                rel.table = QueryReportingUtility.getTableFromList(rel.RelationTableName.toLowerCase(), $scope.processedTables);
                rel.hasColumns = (!!rel.table);
                rel.ColumnName = (Array.isArray(rel.ColumnName) ? rel.ColumnName[0] : rel.ColumnName);
                rel.RelationTableDisplayName = (!!rel.table) ? rel.table.RelationTableDisplayName : rel.RelationTableName;
                rel.RelationColumnName = (Array.isArray(rel.RelationColumnName) ? rel.RelationColumnName[0] : rel.RelationColumnName);
                rel.IsActiveColName = (!!rel.table) ? rel.table.IsActiveColName : '';
                rel.IsDeletedColName = (!!rel.table) ? rel.table.IsDeletedColName : '';
            });

            //Setup the from table
            from.ShowTable = true;
            from.table = QueryReportingUtility.getTableFromList(from.RelationTableName.toLowerCase(), $scope.processedTables);
            from.hasColumns = (!!from.table);
            from.TableDisplayName = (!!from.table) ? from.table.TableDisplayName : from.TableDisplayName;
            from.RelationTableDisplayName = (!!from.table) ? from.table.TableDisplayName : from.TableDisplayName;
            from.parent = null;
            from.joins = [];
            from.isSelected = (!from.isSelected) ? false : true;;

            //Now expand the hierarchy
            join.forEach(function (rel) {
                var currentRelation = relationMapping[rel.TableAlias];
                
                rel.ShowTable = true;
                
                rel.parent = currentRelation;
                rel.joins = [];
                rel.isSelected = (!rel.isSelected) ? false : true;

                rel.TableDisplayName = (!!rel.parent.table) ? rel.parent.table.TableDisplayName : rel.parent.TableName;

                currentRelation.joins.push(rel);
            });
        };

        /**
         * Prepares the query for serialization by removing potential circular references
         * 
         * @param {ExecuteQuery} query 
         */
        var prepareQueryForSerialization = function (query) {
            //Remove all potential circular references since the JSON serializer can't handle them
            if(query.From) {
                query.From.table = undefined;
                query.From.joins = [];
            }
            if(query.Join) {
                query.Join.forEach(function (j) {
                    j.table = undefined;
                    j.parent = undefined;
                    j.joins = [];
                });
            }
        };


        /**
         * 
         * @param {QueryColumn[]} expressionList 
         * @param {QueryColumn[]} columnList 
         * @param {*} expressionNameChange 
         */
        var rebuildExpressionReferencesForColumns = function (expressionList, columnList, expressionNameChange, updateDisplayName) {
            columnList.forEach(function (c, index) {
                if (!c) { return; }

                if (c.IsExpressionColumn) {
                    var fieldName = c.FieldName;

                    if(expressionNameChange && expressionNameChange.old == fieldName) {
                        fieldName = expressionNameChange.new;
                    }

                    var expr = angular.copy(getExpressionFromList(expressionList, fieldName));
                    expr.FieldName = fieldName;
                    if(updateDisplayName) {
                        expr.FieldDisplayName = fieldName;
                    } else {
                        expr.FieldDisplayName = c.FieldDisplayName;
                    }

                    //Delete the non-reference and then insert the proper expressionList reference
                    copyPropertyValues(expr, c);
                }
            });
        };

        /**
         * 
         * @param {ExecuteQuery} query 
         * @param {{old: string, new: string}} [expressionNameChange]
         */
        var rebuildExpressionReferences = function (query, expressionNameChange) {
            //Start with any expressions that reference other expressions
            query.ExpressionList.forEach(function (e) {
                if(e.Expression.LeftOperand) {
                    e.Expression.LeftOperand.Parameters.forEach(function (p) {
                        if(p.Value.IsColumn && p.Value.Column.IsExpressionColumn) {
                            p.Value.Column = getExpressionFromList(query.ExpressionList, p.Value.Column.FieldName);
                        }
                    });
                }

                if(e.Expression.Operator && e.Expression.RightOperand) {
                    e.Expression.RightOperand.Parameters.forEach(function (p) {
                        if(p.Value.IsColumn && p.Value.Column.IsExpressionColumn) {
                            p.Value.Column = getExpressionFromList(query.ExpressionList, p.Value.Column.FieldName);
                        } 
                    });
                }
            });

            buildDependencies(query.ExpressionList);

            //Rebuild the references for the join condition columns
            var joinColumns = query.Join.select('JoinConditionBlock').recursiveSelect('Conditions').selectMany().where('!item.IsBlock && !!item.Column').select('Column');
            joinColumns = joinColumns.concat(query.Join.select('JoinConditionBlock').recursiveSelect('Conditions').selectMany().where('!item.IsBlock && !!item.ColumnValue').select('ColumnValue'));
            rebuildExpressionReferencesForColumns(query.ExpressionList, joinColumns, expressionNameChange, true);

            //Now for each column, get the expression references
            var whereColumns = [query.Where].recursiveSelect('Conditions').selectMany().where('!item.IsBlock && !!item.Column').select('Column');
            whereColumns = whereColumns.concat([query.Where].recursiveSelect('Conditions').selectMany().where('!item.IsBlock && !!item.ColumnValue').select('ColumnValue'));
            rebuildExpressionReferencesForColumns(query.ExpressionList, whereColumns, expressionNameChange, true);
            rebuildExpressionReferencesForColumns(query.ExpressionList, query.GroupBy, expressionNameChange, true);
            rebuildExpressionReferencesForColumns(query.ExpressionList, query.OrderBy, expressionNameChange, true);
            rebuildExpressionReferencesForColumns(query.ExpressionList, query.Select, expressionNameChange, false);
        };

        /**
         * 
         * @param {ExecuteQuery} query 
         */
        var rebuildColumnReferences = function (query) {
            for(var i = 0; i < query.Select.length; i++) {
                var colToReplace = query.Select[i];
                if (!colToReplace.IsExpressionColumn) {
                    var rel = query.Join.concat([query.From]).where('item.RelationTableAlias==this', colToReplace.TableAlias)[0];
                    var col = rel.table.ColumnList.where('item.FieldName==this', colToReplace.FieldName)[0];
    
                    col.TableAlias = rel.RelationTableAlias;
                    col.TableName = rel.RelationTableName;
                    col.FieldDisplayName = colToReplace.FieldDisplayName;
                    col.showTitleEditor = false; 
                    col.selectedForResults = true; 
                    col.checkedInDropdown = true;

                    query.Select[i] = col;
                }
            }

            for(var i = 0; i < query.OrderBy.length; i++) {
                var colToReplace = query.OrderBy[i];
                if (!colToReplace.IsExpressionColumn) {
                    var rel = query.Join.concat([query.From]).where('item.RelationTableAlias==this', colToReplace.TableAlias)[0];
                    var col = rel.table.ColumnList.where('item.FieldName==this', colToReplace.FieldName)[0];
    
                    col.TableAlias = rel.RelationTableAlias;
                    col.TableName = rel.RelationTableName;
                    col.FieldDisplayName = colToReplace.FieldDisplayName;
                    col.showOrderEditor = false; 
                    col.Direction = colToReplace.Direction;

                    query.OrderBy[i] = col;
                }
            }

            for(var i = 0; i < query.GroupBy.length; i++) {
                var colToReplace = query.GroupBy[i];
                if (!colToReplace.IsExpressionColumn) {
                    var rel = query.Join.concat([query.From]).where('item.RelationTableAlias==this', colToReplace.TableAlias)[0];
                    var col = rel.table.ColumnList.where('item.FieldName==this', colToReplace.FieldName)[0];
    
                    col.TableAlias = rel.RelationTableAlias;
                    col.TableName = rel.RelationTableName;
                    col.FieldDisplayName = colToReplace.FieldDisplayName;

                    query.GroupBy[i] = col;
                }
            }
        };     

        /**
         * 
         * @param {ExecuteQuery} query 
         */
        var setExpressionTableNameAlias = function (query) {
            var setName = function (c) { if(!!c && c.IsExpressionColumn) {c.TableName = 'Expression'; c.TableAlias = 'Expr';} };
            [query.Where].recursiveSelect('Conditions').selectMany().where('!item.IsBlock').forEach(function (c) {
                setName(c.Column);
                setName(c.ColumnValue);
            });
            query.GroupBy.forEach(setName);
            query.OrderBy.forEach(setName);
            query.Select.forEach(setName);
            if (!!query.ExpressionList) {
                query.ExpressionList.forEach(setName);
            } else {
                query.ExpressionList = [];
            }
        };

        /**
         * 
         * @param {QueryColumn[]} expressionList 
         */
        var buildDependencies = function (expressionList) {
            //Build up dependencies
            expressionList.forEach(function (e) {
                var expr = e.Expression;
                expr.dependencies = getExpressionDependencies(e);
            });
            //Build up dependents
            expressionList.forEach(function (e) {
                var expr = e.Expression;
                expr.dependents = getExpressionDependents(e);
            });
        };

        /**
         * 
         * @param {QueryColumn} col 
         * @returns {string[]}
         */
        var getExpressionDependencies = function (col) {
            var expr = col.Expression;
            var dependencies = [];

            if(expr.LeftOperand) {
                expr.LeftOperand.Parameters.forEach(function(p) {
                    if(p.Value.DataType == 'expression' && !dependencies.includes(p.Value.Column.FieldName)) {
                        dependencies.push(p.Value.Column.FieldName);
                        dependencies = dependencies.concat(getExpressionDependencies(p.Value.Column));
                    }
                });
            }

            if(expr.Operator && expr.RightOperand) {
                expr.RightOperand.Parameters.forEach(function(p) {
                    if(p.Value.DataType == 'expression' && !dependencies.includes(p.Value.Column.FieldName)) {
                        dependencies.push(p.Value.Column.FieldName);
                        dependencies = dependencies.concat(getExpressionDependencies(p.Value.Column));
                    }
                });
            }

            return dependencies.distinct();
        };

        /**
         * 
         * @param {QueryColumn} col 
         * @returns {string[]}
         */
        var getExpressionDependents = function (col) {
            var dependents = [];

            $scope.currentQuery.ExpressionList.forEach(function (e) {
                if(e.Expression.dependencies.includes(col.FieldName) && e.FieldName != col.FieldName) {
                    dependents.push(e.FieldName);
                }
            });

            return dependents.distinct();
        };


        /**
         * 
         * @param {QueryWhereBlock} whereBlock 
         * @param {QueryTableRelation} rel 
         */
        var deleteConditionsUsingRelationship = function (whereBlock, rel) {
            /** @type {QueryWhereCondition[][]} */
            var conditions = [whereBlock].recursiveSelect('Conditions');

            //Remove all conditions that are using this table
            conditions.forEach(function(ca) {
                ca.conditionalSplice(function (e, index) {
                    if (e.IsBlock || !e.Column) { 
                        return false; 
                    }
                    return (e.Column.TableName == rel.RelationTableName && e.Column.TableAlias == rel.RelationTableAlias) || 
                           (e.IsColumnValue && (e.ColumnValue.TableName == rel.RelationTableName && e.ColumnValue.TableAlias == rel.RelationTableAlias));
                });
            });
            //Now remove all empty condition blocks and make sure the first condition in each block has no condition operator
            conditions.forEach(function(ca) {
                ca.conditionalSplice(function (e, index) {
                    return e.IsBlock && (!e.Block || !e.Block.Conditions || e.Block.Conditions.length == 0);
                });
                if(ca.length  > 0) {
                    ca[0].ConditionOperator = '';
                }
            });
        };

        /**
         * 
         * @param {QueryColumn} lColumn 
         * @param {string} operator 
         * @param {QueryColumn} rColumn 
         * @returns {QueryWhereBlock}
         */
        var generateConditionBlock = function(lColumn, operator, rColumn) {
            /**
             * @type {QueryWhereBlock}
             */
            var block = {
                isDefault: true,
                Conditions: [
                    {
                        ConditionOperator: '',
                        IsBlock: false,
                        Block: null,
                        Column: lColumn,
                        Operator: operator,
                        Value: '',
                        IsColumnValue: true,
                        ColumnValue: rColumn
                    }
                ]
            };

            return block;
        };

        /**
         * 
         * @param {QueryTableRelation} rel 
         */
        var setDefaultConditionBlock = function(rel) {
            var lTable = rel.parent.table;
            var rTable = rel.table;
            var lColumn = null;
            var rColumn = null;

            if (!!lTable) {
                lColumn = lTable.ColumnList.where('item.FieldName==this', rel.ColumnName).firstOrDefault();
            }
            if (!!rTable) {
                rColumn = rTable.ColumnList.where('item.FieldName==this', rel.RelationColumnName).firstOrDefault();
            }

            if(!lColumn) {
                lColumn = {
                    TableName: rel.TableName,
                    TableDisplayName: rel.TableDisplayName,
                    TableAlias: rel.TableAlias,
                    FieldName: rel.ColumnName,
                    FieldDisplayName: rel.ColumnName,
                    FieldDataType: 'int'
                };
            } else {
                lColumn.TableName = rel.TableName;
                lColumn.TableDisplayName = rel.TableDisplayName;
                lColumn.TableAlias = rel.TableAlias;
            }
            
            if(!rColumn) {
                rColumn = {
                    TableName: rel.RelationTableName,
                    TableDisplayName: rel.RelationTableDisplayName,
                    TableAlias: rel.RelationTableAlias,
                    FieldName: rel.RelationColumnName,
                    FieldDisplayName: rel.RelationColumnName,
                    FieldDataType: 'int'
                };
            } else {
                rColumn.TableName = rel.RelationTableName;
                rColumn.TableDisplayName = rel.RelationTableDisplayName;
                rColumn.TableAlias = rel.RelationTableAlias;
            }

            var op = 'equal to';

            if(!!rel.IsCustomCondition) {
            } else {
                rel.IsCustomCondition = false;
                rel.JoinConditionBlock = generateConditionBlock(lColumn, op, rColumn);
            }
        }

        $scope.getColLabel = function (col) {
            return QueryReportingUtility.getColumnLabel(col);
        };

        $scope.getColumnSelection = function () {
            if($scope.selectedRelationship.table) {
                return $scope.selectedRelationship.table.ColumnList.where('item.isSelected');
            } else {
                return [];
            }
        };


        $scope.clearEntitySelection = function () {
            $scope.processedTables = angular.copy($scope.entities);
            processTableEnableDisable();
        };

        $scope.clearQuery = function () {
            //Sometimes this method takes a while to execute, so async execution is necessary to show the loader
            $scope.clearingQuery(true, 'Clearing...');
            $timeout(function () {
                resetProperties();
            }, 1)
            .then(function () {
                $scope.clearingQuery(false);
            });
        };

        $scope.isValueDefined = function(value) {
            if(typeof value == 'boolean') {
                return true;
            }
            
            return !!value;
        };
        
        $scope.deleteSelection = function (array, i) {
            array.splice(i, 1);
        };

        $scope.moveSelectionUp = function (array, i) {
            if(i <= 0) {
                return;
            }

            var elm = array.splice(i, 1);
            array.splice(i-1, 0, elm[0]);
        };

        $scope.moveSelectionDown = function (array, i) {
            if(i >= array.length - 1) {
                return;
            }

            var elm = array.splice(i, 1);
            array.splice(i+1, 0, elm[0]);
        };


        $scope.deleteRelation = function (parentTable, index) {
            var deleted = parentTable.joins.splice(index, 1)[0];

            var rList = [deleted].concat([deleted].recursiveSelect('joins', null, ['parent']).selectMany());

            //Delete any columns selected that don't have joined tables any longer
            rList.forEach(function (rel) {
                //$scope.whereColumns = $scope.whereColumns.filter(function (w) { return w.TableAlias != rel.RelationTableAlias });
                deleteConditionsUsingRelationship($scope.currentQuery.Where, rel);
                $scope.currentQuery.GroupBy = $scope.currentQuery.GroupBy.filter(function (g) { return g.TableAlias != rel.RelationTableAlias });
                $scope.currentQuery.OrderBy = $scope.currentQuery.OrderBy.filter(function (o) { return o.TableAlias != rel.RelationTableAlias });
                $scope.currentQuery.Select = $scope.currentQuery.Select.filter(function (s) { return s.TableAlias != rel.RelationTableAlias });
            });

            if(rList.some(function(r) { return r.isSelected; })) {
                $scope.selectRelationship($scope.currentQuery.From);
            }

            var query = getCopyOfCurrentQuery(false);
            updateColumnAliases(query, query.aliasChanges);

            processTableEnableDisable();
        };

        $scope.selectRelationship = function (rel) {
            if($scope.selectedRelationship) {
                $scope.selectedRelationship.isSelected = false;
            }
            $scope.selectedRelationship = rel;
            if($scope.selectedRelationship) {
                $scope.selectedRelationship.isSelected = true;
            }

            processTableEnableDisable();
        };


        $scope.addSingleTable = function (table) {
            var query = getCopyOfCurrentQuery(true);
            var selectedRelationship = null;

            //Need to setup From/Join for the query to validate
            if(!query.From) {
                query.From = angular.copy(table);
                query.From.ShowTable = true;
                query.From.TableAlias = 'A';
                query.From.RelationTableAlias = 'A';
                query.From.hasColumns = true;
                query.From.table = angular.copy(table);
                query.From.parent = null;                    
                query.From.joins = [];
            } else {
                var selectedRelationship = getSelectedRelation(query.From);
                var from = (selectedRelationship.RelationTableName) ? selectedRelationship.RelationTableName.toLowerCase() : selectedRelationship.TableName.toLowerCase();
                var to = table.TableName.toLowerCase();
                               
                //Copy the cached path from the current selection to the new table
                /** @type {QueryTableRelation[]} */
                var path = null;
                
                try {
                    path = angular.copy($scope.cachedTablePaths[from][to]);
                } catch(ex) {
                }

                if(path) {
                    //Filter the relations out based on the other selections thus far
                    path = path;

                    var multiColRel = null;
                    path.forEach(function(p) {
                        if(p.ColumnName.length > 1 && !multiColRel) {
                            multiColRel = p;
                        }
                    });

                    if(!multiColRel) {
                        $scope.addRelationshipPath(query, path);
                    } else {
                        $scope.openMultipathSelectorDialog(query, path, multiColRel);
                    }
                }
            }

            $scope.setQuery(query);
        };

        $scope.addRelationshipPath = function (query, path) {
            var selectedRelationship = getSelectedRelation(query.From);

            //Make sure the path is still viable
            if(validateRelationPath(selectedRelationship, path)) {
                //Expand the path into a hierarchy
                var currentRelation = selectedRelationship;

                path.forEach(function (rel) {
                    rel.ShowTable = true;
                    rel.table = QueryReportingUtility.getTableFromList(rel.RelationTableName.toLowerCase(), $scope.processedTables);
                    rel.hasColumns = (!!rel.table);
                    rel.parent = currentRelation;
                    rel.joins = [];
                    rel.isSelected = false;

                    currentRelation.joins.push(rel);
                    currentRelation = rel;
                });

                var aliasChanges = {};
                query.Join = QueryReportingUtility.assignAliases([query.From].recursiveSelect('joins').selectMany(), QueryReportingUtility.getAliasGenerator(), aliasChanges);
                updateColumnAliases(query, aliasChanges);
                rebuildExpressionReferences(query, null);
            }
        };

        $scope.setQuery = function(query) {
            var selectedRelationship = getSelectedRelation(query.From);

            if(validateQuery(query)) {
                query.Join.forEach(function(rel) {
                    rel.ColumnName = (Array.isArray(rel.ColumnName) ? rel.ColumnName[0] : rel.ColumnName);
                    rel.RelationColumnName = (Array.isArray(rel.RelationColumnName) ? rel.RelationColumnName[0] : rel.RelationColumnName);
                });

                $scope.currentQuery.QueryTypeId = undefined;
                $scope.currentQuery.From = undefined;
                $scope.currentQuery.Join = undefined;
                $scope.currentQuery.Where = undefined;
                $scope.currentQuery.GroupBy = undefined;
                $scope.currentQuery.OrderBy = undefined;
                $scope.currentQuery.Select = undefined;
                $scope.currentQuery.ExpressionList = undefined;

                //Setting this on a timeout because I need to force the ui to $digest the undefined
                //first before setting it to the validated query.  This will ensure that the template for showing the tables
                //and joins will reload and show the current, validated query.
                $timeout(function() {
                    setExpressionTableNameAlias(query);
                    setTableReferencesInQuery(query, $scope.processedTables);
                        
                    $scope.currentQuery.QueryTypeId = (!!query.QueryTypeId) ? query.QueryTypeId : 1;

                    //Setup FROM
                    $scope.currentQuery.From = query.From;

                    //Setup JOIN
                    $scope.currentQuery.Join = query.Join;
                    expandJoins($scope.currentQuery.From, $scope.currentQuery.Join);
                    //Generate default condition blocks
                    query.Join.forEach(setDefaultConditionBlock);

                    //Setup WHERE
                    $scope.currentQuery.Where = query.Where;
                    [$scope.currentQuery.Where].recursiveSelect('Conditions').selectMany().where('!item.IsBlock').forEach(
                    /**
                     * @param {QueryWhereCondition} c
                     */    
                    function (c) {
                            if(!c.Column) { return; }

                        if(!c.Column.IsExpressionColumn) {
                                QueryReportingUtility.getPossibleValuesForColumn(c.Column, c.Value, $scope.processedTables); 
                        }
                        QueryReportingUtility.getAvailableConditionOperators(c.Column, false); 
                        c.removeWatch = $scope.$watch(function () { return c.dateOpened; }, function (newVal, oldVal, scope) {
                            if (!newVal && oldVal) {
                                c.showValueEditor = false;
                            }
                        });
                    });

                    //Setup GROUP BY
                    $scope.currentQuery.GroupBy = query.GroupBy;

                    //Setup ORDER BY 
                    $scope.currentQuery.OrderBy = query.OrderBy;

                    //Setup SELECT
                    $scope.currentQuery.Select = query.Select;

                    //Setup Expression Lists
                    $scope.currentQuery.ExpressionList = query.ExpressionList;

                    QueryReportingUtility.addTypeaheadClasses();

                    //Now rebuild the references, if any
                    //I.e. make sure the selected columns point to the appropriate reference
                    var queryCopy = getCopyOfCurrentQuery(false);
                    updateColumnAliases(queryCopy, queryCopy.aliasChanges);
                    rebuildExpressionReferences(queryCopy);
                    rebuildColumnReferences(queryCopy);

                    if(!!selectedRelationship) {
                        $scope.selectRelationship(selectedRelationship);
                    } else {
                        $scope.selectRelationship($scope.currentQuery.From);
                    }

                    processTableEnableDisable();
                }, 1);
                
            } else {

            }
        };

        $scope.addGroupColumns = function (newCols) {
            var query = getCopyOfCurrentQuery(true);
            var selectedRelationship = angular.copy($scope.selectedRelationship);
            var messages = [];

            //newCols = angular.copy(newCols);

            newCols.forEach(function (c) {
                if (c.IsExpressionColumn) {
                    return;
                }
                c.TableAlias = selectedRelationship.RelationTableAlias;
                c.TableName = selectedRelationship.RelationTableName;
                c.TableDisplayName = selectedRelationship.RelationTableDisplayName;
            });
            
            query.GroupBy = query.GroupBy.concat(newCols);

            if(validateQuery(query, 'group', messages)){
                $scope.currentQuery.GroupBy = $scope.currentQuery.GroupBy.concat(newCols);

                clearColumnSelection();
            } else {
                //Show messages
                $scope.showStatusMsg({type: '-', message: messages});
            }
        };

        $scope.addOrderColumns = function (newCols) {
            var query = getCopyOfCurrentQuery(true);
            var selectedRelationship = angular.copy($scope.selectedRelationship);
            var messages = [];
            
            //newCols = angular.copy(newCols);

            newCols.forEach(function (c) {
                if (c.IsExpressionColumn) {
                    return;
                }
                c.TableAlias = selectedRelationship.RelationTableAlias;
                c.TableName = selectedRelationship.RelationTableName;
                c.TableDisplayName = selectedRelationship.RelationTableDisplayName;
            });
            
            query.OrderBy = query.OrderBy.concat(newCols);

            if(validateQuery(query, 'order', messages)) {
                newCols.forEach(function(c) { c.Direction = 'ASC'; c.showOrderEditor = false; });
                $scope.currentQuery.OrderBy = $scope.currentQuery.OrderBy.concat(newCols);

                clearColumnSelection();
            } else {
                //Show messages
                $scope.showStatusMsg({type: '-', message: messages});
            }
        };

        /**
         * 
         * @param {QueryTableRelation} rel 
         * @param {QueryColumn[]} newCols 
         */
        var validateAndAddSelectColumns = function (rel, newCols) {
            var query = getCopyOfCurrentQuery(true);
            var messages = [];

            newCols = newCols.where('!item.selectedForResults');

            newCols.forEach(function (c) {
                if (c.IsExpressionColumn) {
                    return;
                }
                c.TableAlias = rel.RelationTableAlias;
                c.TableName = rel.RelationTableName;
                c.TableDisplayName = rel.RelationTableDisplayName;
            });
            
            query.Select = query.Select.concat(newCols);

            if(validateQuery(query, 'select', messages)) {
                newCols.forEach(function(c) { c.showTitleEditor = false; c.selectedForResults = true; c.checkedInDropdown = true; });
                    
                $scope.currentQuery.Select = $scope.currentQuery.Select.concat(newCols);

                $scope.fixSelectDisplayUniqueness($scope.currentQuery.Select);

                clearColumnSelection();
            } else {
                //Show messages
                $scope.showStatusMsg({type: '-', message: messages});
            }
        };

        /**
         * 
         * @param {QueryTableRelation} rel 
         * @param {QueryColumn} col 
         */
        var validateAndRemoveSelectColumn = function (col) {
            var messages = [];

            //Find and remove the column
            var query = getCopyOfCurrentQuery(true);

            var i = query.Select.findIndex(function (s) { return s === col; });
            if(i > -1) {
                $scope.deleteSelection(query.Select, i);
            }

            if(validateQuery(query, 'select', messages)) {
                col.showTitleEditor = false; col.selectedForResults = false; col.checkedInDropdown = false;

                i = $scope.currentQuery.Select.findIndex(function (s) { return s === col; });
                if(i > -1) {
                    $scope.deleteSelection($scope.currentQuery.Select, i);
                }

                $scope.fixSelectDisplayUniqueness($scope.currentQuery.Select);
            } else {
                //Show messages
                $scope.showStatusMsg({type: '-', message: messages});
            }
        };

        $scope.addSelectColumns = function (newCols) {
            var selectedRelationship = $scope.selectedRelationship;//angular.copy($scope.selectedRelationship);
            
            validateAndAddSelectColumns(selectedRelationship, newCols);
        };

        $scope.selectColumnForResults = function (rel, col) {
            if (!col.selectedForResults) {
                validateAndAddSelectColumns(rel, [col]);
            } else {
                validateAndRemoveSelectColumn(col);
            }
        };

        $scope.removeSelectedForResults = function (col) {
            col.checkedInDropdown = false;
            col.selectedForResults = false;
        };


        $scope.fixSelectDisplayUniqueness = function (selectColumns) {
            var nameRegex = /^(.*?)\s?\(?(\d+)?\)?$/;
            var names = {};

            selectColumns.forEach(function (c) {
                var matches = nameRegex.exec(c.FieldDisplayName);
                var fieldName = matches[1];
                var count = (matches[2]) ? parseFloat(matches[2]) : 0;
                
                if (!names.hasOwnProperty(fieldName)) {
                    names[fieldName] = count;
                } else {
                    names[fieldName]++;
                    c.FieldDisplayName = fieldName + ' (' + names[fieldName] + ')';
                }
            });
        };

        $scope.openTypeaheadDropdown = function (event) {
            angular.element(event.target).controller('ngModel').$setViewValue();
            angular.element(event.target).trigger('input');
            angular.element(event.target).trigger('change');
        };

        $scope.closeEditor = function (event, column) {
            if (event) {
                if (event.which == 13) {
                    if (column.showColumnEditor) {
                        column.showColumnEditor = false;
                    }
                    if (column.showTitleEditor) {
                        column.showTitleEditor = false;
                    }
                    if (column.showOrderEditor) {
                        column.showOrderEditor = false;
                    }
                    if (column.showOperatorEditor) {
                        column.showOperatorEditor = false;
                    }
                    if (column.showValueEditor) {
                        column.showValueEditor = false;
                    }
                }
            } else {
                if (column.showTitleEditor) {
                    column.showTitleEditor = false;

                    if (!column.FieldDisplayName || column.FieldDisplayName.trim(' ') == '') {
                        column.FieldDisplayName = angular.copy(column.FieldName);
                    }
                }
                if (column.showOrderEditor) {
                    column.showOrderEditor = false;
                }
                if (column.showOperatorEditor) {
                    column.showOperatorEditor = false;
                }
                if (column.showValueEditor) {
                    column.showValueEditor = false;
                }
            }
        };


        $scope.openExpressionColumnEditor = function (selectedExpression, mode) {
            if(selectedExpression) {
                selectedExpression.isSelected = true;
            }
            
            var modalInstance = $uibModal.open({
                templateUrl: 'app/components/report/query-reporting/query-reporting-expression-editor.html',
                controller: 'QueryReportingExpressionEditorController',
                size: 'lg',
                resolve: {
                    parameters: function () {
                        return {
                            currentQuery: getCopyOfCurrentQuery(true),
                            processedTables: angular.copy($scope.processedTables),
                            expressionList: $scope.currentQuery.ExpressionList,
                            selectedExpression: selectedExpression,
                            mode: mode
                        };
                    }
                }
            });
            var expressionNameChange = { 
                old: (selectedExpression) ? selectedExpression.FieldName : '',
                new: (selectedExpression) ? selectedExpression.FieldName : ''
            };

            modalInstance.result.then(function (result) {
                if(selectedExpression) {
                    delete selectedExpression.isSelected;    
                }
                delete result.column.isSelected;
                delete result.column.Expression.leftAvailableFunctions;
                delete result.column.Expression.rightAvailableFunctions;

                if (mode == 'add')  {
                    //Add the column to the given category
                    switch(result.columnType) {
                        // case 'where':
                        //     $scope.addWhereColumns([result.column]);
                        //     break;
                        case 'group':
                            $scope.addGroupColumns([result.column]);
                            break;
                        case 'order':
                            $scope.addOrderColumns([result.column]);
                            break;
                        case 'select':
                            $scope.addSelectColumns([result.column]);
                            break;
                    }

                    result.column.TableAlias = '';
                    result.column.TableName = 'Expression';
                    result.column.TableDisplayName = 'Expression';
                    
                } else if(mode == 'edit') {
                    //Edit the column while keeping the original reference
                    copyPropertyValues(result.column, selectedExpression);
                    expressionNameChange.new = result.column.FieldName;
                } else {
                    //Create the column
                    $scope.currentQuery.ExpressionList.push(result.column);
                }
                
                rebuildExpressionReferences(getCopyOfCurrentQuery(false), expressionNameChange);
            }, function () {
                if(selectedExpression) {
                    delete selectedExpression.isSelected;    
                }
                //Do nothing if they dismiss the dialog
            });            
        };

        $scope.deleteExpression = function (array, i) {
            var expression = array[i];
            
            if(expression.Expression.dependents.length > 0) {
                $scope.showStatusMsg({type: '-', message: 'Cannot delete this expression.  The following other expressions depend on ' + expression.FieldName + ': ' + expression.Expression.dependents.toString()});
                return;
            }

            $scope.deleteSelection(array, i);

            buildDependencies($scope.currentQuery.ExpressionList);

            //$scope.whereColumns = $scope.whereColumns.filter(function (c) { if(c.IsExpressionColumn) { return c.FieldName != expression.FieldName; } return true; });
            $scope.currentQuery.GroupBy = $scope.currentQuery.GroupBy.filter(function (c) { if(c.IsExpressionColumn) { return c.FieldName != expression.FieldName; } return true; });
            $scope.currentQuery.OrderBy = $scope.currentQuery.OrderBy.filter(function (c) { if(c.IsExpressionColumn) { return c.FieldName != expression.FieldName; } return true; });
            $scope.currentQuery.Select = $scope.currentQuery.Select.filter(function (c) { if(c.IsExpressionColumn) { return c.FieldName != expression.FieldName; } return true; });
        };


        $scope.openMultipathSelectorDialog = function (query, path, multiColRel) {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/components/report/query-reporting/query-reporting-multipath-selector.html',
                controller: 'QueryReportingMultipathSelectorController',
                keyboard: false,
                resolve: {
                    parameters: function () {
                        return {
                            relationPath: path,
                            relation: multiColRel
                        };
                    }
                }
            });

            modalInstance.result.then(function () {
                $scope.addRelationshipPath(query, path);

                $scope.setQuery(query);
            }, function () {

            });
        };

        $scope.openJoinSelectorDialog = function (rel) { 
            var modalInstance = $uibModal.open({
                templateUrl: 'app/components/report/query-reporting/query-reporting-join-selector.html',
                controller: 'QueryReportingJoinSelectorController'
            });

            modalInstance.result.then(function (result) {
                rel.RelationType = result.relationshipType;
            });
        };

        $scope.openCustomJoinConditionsDialog = function (rel) {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/components/report/query-reporting/query-reporting-join-conditions.html',
                controller: 'QueryReportingJoinConditionsController',
                size: 'lg',
                resolve: {
                    parameters: function () {
                        return {
                            relation: rel,
                            currentQuery: getCopyOfCurrentQuery(true),
                            setDefaultConditionBlock: setDefaultConditionBlock,
                            generateConditionBlock: generateConditionBlock
                        };
                    }
                }
            });

            modalInstance.result.then(function (result) {
                rel.JoinConditionBlock = result.joinConditionBlock;
                rel.IsCustomCondition = result.isCustom;
            }, function () {
                //Dialog dismissed
            });
        };
    }
})();