(function () {
    'use strict'

    angular.module('app.core')
    .factory('ActivityTimerService', ['$http', '$q', 'WebApiUrl', ActivityTimerService]);

    function ActivityTimerService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _saveActivityTime = function (key, activityTime) {
            return responseHandler($http.post(WebApiUrl + 'Common/SaveActivityTime/' + key + '/', activityTime));
        };

        var _getActivityTimes = function (key, activityTime) {
            return responseHandler($http.post(WebApiUrl + 'Common/GetActivityTimes/' + key + '/', activityTime));
        };

        return {
            saveActivityTime: _saveActivityTime,
            getActivityTimes: _getActivityTimes
        };
    }
})();