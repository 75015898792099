(function () {
    'use strict'

    angular.module('app.core')
    .factory('InlumonContentEditorService', ['$http', '$q', 'WebApiUrl', InlumonContentEditorService]);

    function InlumonContentEditorService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getContentByCode = function (key, contentCode) {
            var urlParams = {
                ContentItemLkCode: contentCode
            };
            return responseHandler($http.get(WebApiUrl + 'Content/ContentGetBYContentItemLkCode/' + key + '/', { params: urlParams }));
        };

        var _getContentById = function (key, contentId) {
            var urlParams = {
                ID: contentId
            };
            return responseHandler($http.get(WebApiUrl + 'Content/LookupContentGetBYContentItemLkID/' + key + '/', { params: urlParams }));
        };

        var _getContentByPageTabSectionId = function (key, pageTabSectionId) {
            var urlParams = {
                ContentLkToPageTabSectionId: pageTabSectionId
            };
            return responseHandler($http.get(WebApiUrl + 'Content/ContentGetBYContentLkToPageTabSectionId' + key + '/', { params: urlParams }));
        };

        var _updateContentInfo = function (key, content) {
            return responseHandler($http.post(WebApiUrl + 'Content/ContentUpdateContentInfo/' + key + '/', content));
        };

        var _getUserProfileList = function (key, userId) {
            var postObj = {
                UserId: userId,
                ProfileId: 0,
                FromRow: 0,
                RecordPerPage: 0,
                Order: false,
                OrderByField: ''
            };
            return responseHandler($http.post(WebApiUrl + 'Profile/GetUserProfileList/' + key + '/', postObj));
        };

        return {
            getContentByCode: _getContentByCode,
            getContentById: _getContentById,
            getContentByPageTabSectionId: _getContentByPageTabSectionId,
            updateContentInfo: _updateContentInfo,
            getUserProfileList: _getUserProfileList
        }
    }
})();