(function () {
    'use strict'

    angular.module('app.core')
    .directive('controllerNoScope', [ControllerNoScope])
    .directive('dynamicController', ['$parse', '$compile', DynamicController]);

    function ControllerNoScope() {
        return {
            restrict: 'A',
            scope: false,
            controller: '@',
            priority: 500 // same as ng-controller
        };
    }

    function DynamicController($parse, $compile) {
        return {
            restrict: 'A',
            terminal: true,
            priority: 100000,
            link: function(scope, elem, attrs) {
                var stopWatch = scope.$watch(attrs.dynamicController, function (newVal) {
                    if (!!newVal) {
                        var name = $parse(elem.attr('dynamic-controller'))(scope);
                        var newScope = $parse(elem.attr('new-scope'))(scope);
                        elem.removeAttr('dynamic-controller');
                        if (!!newScope) {
                            elem.attr('ng-controller', name);
                        } else {
                            elem.attr('controller-no-scope', name);
                        }                        
                        $compile(elem)(scope);

                        stopWatch();
                    }
                });
            }
        };
    };
})();