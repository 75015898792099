(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationStaffReview', initialApplicationStaffReviewDirective);

    initialApplicationStaffReviewDirective.$inject = [];

    function initialApplicationStaffReviewDirective() {
        return {
            restrict: 'E',
            scope: {
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                natValidation: "=natValidation",
                individualId: "=individualId",
                applicationId: "=applicationId",
                showStatusMessage: "&",
                tab: "=tab",
                userId: '=userId',
                openNextTab: "&",
                isPermitApp: "=?isPermitApp",
                isLicenseApp: "=?isLicenseApp",
                applicationInfo: "=?applicationInfo",
                individualViewConfig: "=?individualViewConfig",
                providerViewConfig: "=?providerViewConfig",
                permitApplicationInfo: "=?permitApplicationInfo",
                providerId: "=?providerId",
                isBackoffice: '=',
                individualLicense: '=',
                providerLicense: '='
            },
            templateUrl: "app/components/individual/application/directives/staff-review/staff-review.html",
            controller: "InitialApplicationStaffReviewController"
        }
    }
})();