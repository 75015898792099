(function() {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationEmploymentController", InitialApplicationEmploymentController);

    InitialApplicationEmploymentController.$inject = ["$rootScope", "$scope",
        "initialApplicationEmploymentService", "initialApplicationstatusService", "utilityService", "WebApiUrl", "$filter", "$q", "Messages"
    ];

    function InitialApplicationEmploymentController($rootScope, $scope,
        initialApplicationEmploymentService, initialApplicationstatusService, utilityService, WebApiUrl, $filter, $q, Messages) {


        $scope.EmploymentInformation = [];
        $scope.employmentData = {};

        $scope.GetEmploymentInfo = getEmploymentInfo;
        $scope.GetStateByCountryID = getStateByCountryID;
        $scope.SupervisorInfo = $scope.assistantInfo && $scope.assistantInfo != null ? $scope.assistantInfo : [];
        $scope.employmentEditIndex = 0;
        $scope.maskConfig = {
            clearOnBlur: false,
            allowInvalidValue: true,
            clearOnBlurPlaceholder: false
        };
        $scope.getEmploymentConfig = function() {
            var relativeFilePath = "/individual/application/employment/employment.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function(data) {
                    $scope.employmentConfig = data;
                    $scope.isConfigLoaded = true;
                }, function(error) {

                });
        };
        $scope.setForm = function(currentForm) {
            $scope.currentForm = currentForm;
        }

        var validateForm = function(currentForm) {
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        }

        var checkPropertyExists = function(list, prop) {
            var res = false;
            if (list) {
                for (var i = 0; i < list.length; i++) {
                    if (list[i].propName == prop)
                        return true;
                }
            }
            return res;
        };

        var ShowErrMsg = function() {
            var errorObj = {
                propName: "employmentErr",
                errorMessage: $scope.employmentConfig.ValidationMsg.APIErr
            };
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            if (!checkPropertyExists($scope.natValidation.errorMessages, 'employmentErr'))
                $scope.natValidation.errorMessages.push(errorObj);
        }

        function getEmploymentInfo() {
            try {

                if ($scope.employmentInfo.length > 0) {
                    $scope.EmploymentInformation = $scope.employmentInfo;
                }
            } catch (ex) {

            }
        }

        function getStateByCountryID(CountryID) {
            try {

                initialApplicationEmploymentService.getStateByCountryID(sessionStorage.Key, CountryID).then(function(data) {
                    $scope.StatList = data.State;
                }, function(error) {
                    ShowErrMsg();
                });

            } catch (ex) {

            }
        }
        angular.element(document.querySelector("#btnCancelEmployementInformation")).click(function() {
            //resetValidation();
            $scope.addEmploymentInformation = false;
            angular.element(document.querySelector("#btnEmploymentInformation")).show();
        });


        $scope.init = function() {
            try {
                $scope.getEmploymentConfig();
                $scope.GetStateByCountryID($rootScope.DefaultCountryId);
                $scope.GetEmploymentInfo();

                $scope.addEmploymentInformation = false;
                angular.element(document.querySelector("#btnEmploymentInformation")).click(function() {
                    $scope.currentForm.$setPristine();
                    $scope.clearDataEmployment();
                    $scope.addEmploymentInformation = true;
                    angular.element(document.querySelector("#btnEmploymentInformation")).hide();
                });

                angular.element(document.querySelector("#btnCancelEmployementInformation")).click(function() {
                    //resetValidation();
                    $scope.natValidation.showError = false;
                    $scope.addEmploymentInformation = false;
                    angular.element(document.querySelector("#btnEmploymentInformation")).show();
                });


            } catch (ex) {

            }
        }

        $scope.editEmploymentInformation = function(IndividualEmploymentId, $index) {
            try {
                $scope.employmentData = {};
                $scope.employmentEditIndex = $index;
                angular.element(document.querySelector("#btnEmploymentInformation")).hide();
                $scope.addEmploymentInformation = true;
                $scope.employmentData.IndividualEmploymentId = $scope.EmploymentInformation[$index - 1].IndividualEmploymentId;
                $scope.employmentData.EmployerName = $scope.EmploymentInformation[$index - 1].EmployerName;
                $scope.employmentData.PhysicalAddressStreet = $scope.EmploymentInformation[$index - 1].EmploymentAddress[0].StreetLine1;
                $scope.employmentData.PhysicalAddressStreet2 = $scope.EmploymentInformation[$index - 1].EmploymentAddress[0].StreetLine2;
                $scope.employmentData.EmployerCity = $scope.EmploymentInformation[$index - 1].EmploymentAddress[0].City;
                $scope.employmentData.PhysicalAddressState = $scope.EmploymentInformation[$index - 1].EmploymentAddress[0].StateCode;
                $scope.employmentData.PhysicalAddressZIP = $scope.EmploymentInformation[$index - 1].EmploymentAddress[0].Zip;
                $scope.employmentData.EmploymentStartDate = new Date($scope.EmploymentInformation[$index - 1].EmploymentStartDate);
                if ($scope.EmploymentInformation[$index - 1].EmploymentContact != undefined || $scope.EmploymentInformation[$index - 1].EmploymentContact != null) {
                    if ($scope.EmploymentInformation[$index - 1].EmploymentContact.length > 0) {
                        for (var i = 0; i < $scope.EmploymentInformation[$index - 1].EmploymentContact.length; i++) {


                            if ($scope.EmploymentInformation[$index - 1].EmploymentContact[i].ContactTypeId == "3") {
                                $scope.employmentData.WorkPhone = $scope.EmploymentInformation[$index - 1].EmploymentContact[i].ContactInfo;
                            }
                            if ($scope.EmploymentInformation[$index - 1].EmploymentContact[i].ContactTypeId == "4") {
                                $scope.employmentData.AlternatePhone = $scope.EmploymentInformation[$index - 1].EmploymentContact[i].ContactInfo;
                            }
                            if ($scope.EmploymentInformation[$index - 1].EmploymentContact[i].ContactTypeId == "5") {
                                $scope.employmentData.EmploymentFax = $scope.EmploymentInformation[$index - 1].EmploymentContact[i].ContactInfo;
                            }
                        }
                    }
                }
                if (!$scope.employmentData.WorkPhone || $scope.employmentData.WorkPhone == '') {
                    $scope.DisableContactDetails = true;
                }
                $scope.chkEmployment = false;
                //$scope.IndividualRenewal.IndividualEmployment = $scope.EmploymentInformation;

                //supervisor
                var svrInd = getSupervisorIndexbyIndividualemploymentid($scope.employmentData.IndividualEmploymentId);
                if (svrInd != -1) {
                    var svr = $scope.SupervisorInfo[svrInd];
                    $scope.employmentData.IndividualSupervisoryInfoId = svr.IndividualSupervisoryInfoId;
                    $scope.employmentData.SupervisorName = svr.FirstName;
                    $scope.employmentData.SupervisorMiddleName = svr.MiddleName;
                    $scope.employmentData.SupervisorLastName = svr.LastName;
                    $scope.employmentData.SupervisorLicense = svr.SupervisorLicenseNumber;
                    $scope.employmentData.IndividualNameId = svr.IndividualNameId;
                    $scope.employmentData.SupervisorProfession = svr.SupervisorType;
                }
            } catch (ex) {
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        };

        $scope.deleteEmploymentInformation = function(IndividualEmploymentId, $index) {
            try {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    $scope.EmploymentInformation[$index - 1].IsDeleted = true;

                    initialApplicationEmploymentService.employmentSave($scope.EmploymentInformation[$index - 1], sessionStorage.Key)
                        .then(function(response) {

                            HideLoader();
                        }, function(data) {
                            HideLoader();
                            if (data != null) { //$scope.showStatusMessage(data.message, "error");
                                ShowErrMsg();
                            }
                        });

                }
            } catch (ex) {
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        var customValidation = function() {
            if (!$scope.natValidation.errorMessages)
                $scope.natValidation.errorMessages = [];
            var res = true;
            if ($scope.isApprentice && (!$scope.employmentData.SupervisorProfession || $scope.employmentData.SupervisorProfession == '')) {
                res = false;
                var errorObj = {
                    propName: "natrequired-Profession",
                    errorMessage: Messages.General.Errors.ProfessionRequired
                };
                if (!checkPropertyExists($scope.natValidation.errorMessages, 'natrequired-Profession'))
                    $scope.natValidation.errorMessages.push(errorObj);
            }
            if ($scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
            }
            return res;
        };
        $scope.saveEmploymentInformation = function() {
            if (!validateForm($scope.currentForm)) {
                customValidation();
                return;
            }
            if (!customValidation()) {
                return;
            }
            try {
                if (true) { // if (validateData("UI_IndividualRenewalLicenseRenewalHASnAEmployementvalidation")) {

                    if (true) { // if ($scope.validateEmploymentInformationPhone()) {
                        var IndividualEmploymentId = $scope.employmentData.IndividualEmploymentId;
                        if ($scope.employmentEditIndex == 0) {
                            var EmploymentContact = [];
                            if ($scope.employmentData.WorkPhone != null && $scope.employmentData.WorkPhone != "") {

                                EmploymentContact.push({
                                    IndividualEmploymentContactId: 0,
                                    IndividualId: $scope.individualId,
                                    ContactId: 0,
                                    IndividualEmploymentId: 0,
                                    ContactTypeId: 3,
                                    BeginDate: $scope.employmentData.EmploymentStartDate,
                                    EndDate: null,
                                    IsPreferredContact: true,
                                    IsMobile: true,
                                    IsActive: true,
                                    ContactFirstName: "",
                                    ContactLastName: "",
                                    ContactMiddleName: "",
                                    Code: "W",
                                    ContactInfo: $scope.employmentData.WorkPhone,
                                    DateContactValidated: null
                                });
                            }


                            if ($scope.employmentData.AlternatePhone != null && $scope.employmentData.AlternatePhone != "") {

                                EmploymentContact.push({
                                    IndividualEmploymentContactId: 0,
                                    IndividualId: $scope.individualId,
                                    ContactId: 0,
                                    IndividualEmploymentId: 0,
                                    ContactTypeId: 4,
                                    BeginDate: $scope.employmentData.EmploymentStartDate,
                                    EndDate: null,
                                    IsPreferredContact: true,
                                    IsMobile: true,
                                    IsActive: true,
                                    ContactFirstName: "",
                                    ContactLastName: "",
                                    ContactMiddleName: "",
                                    Code: "A",
                                    ContactInfo: $scope.employmentData.AlternatePhone,
                                    DateContactValidated: null
                                });
                            }


                            if ($scope.employmentData.EmploymentFax != null && $scope.employmentData.EmploymentFax != "") {

                                EmploymentContact.push({
                                    IndividualEmploymentContactId: 0,
                                    IndividualId: $scope.individualId,
                                    ContactId: 0,
                                    IndividualEmploymentId: 0,
                                    ContactTypeId: 5,
                                    BeginDate: $scope.employmentData.EmploymentStartDate,
                                    EndDate: null,
                                    IsPreferredContact: true,
                                    IsMobile: true,
                                    IsActive: true,
                                    ContactFirstName: "",
                                    ContactLastName: "",
                                    ContactMiddleName: "",
                                    Code: "F",
                                    ContactInfo: $scope.employmentData.EmploymentFax,
                                    DateContactValidated: null
                                });
                            }
                            var newEmploy = {
                                IndividualEmploymentId: 0,
                                IndividualId: $scope.individualId,
                                ApplicationId: $scope.applicationId,
                                EmployerName: $scope.employmentData.EmployerName,
                                ProviderId: 0,
                                EmploymentHistoryTypeId: 1,
                                EmploymentStartDate: $scope.employmentData.EmploymentStartDate,
                                EmploymentEndDate: null,
                                EmploymentStatusId: null,
                                EmploymentTypeId: null,
                                PositionId: null,
                                IsWorkinginFieldofApplication: false,
                                EverWorkedinFieldofApplication: false,
                                ReferenceNumber: "",
                                Role: null,
                                IsActive: true,
                                IsDeleted: false,
                                EmploymentAddress: [{ IndividualEmploymentAddressId: 0, IndividualId: $scope.individualId, AddressId: 0, IndividualEmploymentId: 0, AddressTypeId: 1, BeginDate: $scope.employmentData.EmploymentStartDate, EndDate: null, IsMailingSameasPhysical: false, IsActive: true, Addressee: "", StreetLine1: $scope.employmentData.PhysicalAddressStreet, StreetLine2: $scope.employmentData.PhysicalAddressStreet2, City: $scope.employmentData.EmployerCity, StateCode: $scope.employmentData.PhysicalAddressState, Zip: $scope.employmentData.PhysicalAddressZIP, CountyId: null, CountryId: $rootScope.DefaultCountryId }],
                                EmploymentContact: EmploymentContact
                            };
                            initialApplicationEmploymentService.employmentSave(newEmploy, sessionStorage.Key)
                                .then(function(response) {
                                    if (response.IndividualEmploymentResponseList && response.IndividualEmploymentResponseList.length > 0) {
                                        newEmploy.IndividualEmploymentId = response.IndividualEmploymentResponseList[0].IndividualEmploymentId;
                                        $scope.EmploymentInformation.push(newEmploy);
                                    }
                                    if (!$scope.isApprentice)
                                        $scope.clearDataEmployment();
                                    else
                                        saveSupervisorrmation(response.IndividualEmploymentResponseList[0].IndividualEmploymentId);

                                    $scope.addEmploymentInformation = false;
                                    angular.element(document.querySelector("#btnEmploymentInformation")).show();
                                    // $scope.IndividualRenewal.IndividualEmployment = $scope.EmploymentInformation;
                                    if (!$rootScope.ProcessLoaderExternally)
                                        HideLoader();
                                }, function(data) {
                                    ShowErrMsg();
                                    if (!$rootScope.ProcessLoaderExternally)
                                        HideLoader();
                                    if (data != null) { //$scope.showStatusMessage(data.message, "error");
                                    }
                                });

                        } else {
                            var i;
                            for (i = 0; i < $scope.EmploymentInformation.length; i++) {

                                if (($scope.employmentEditIndex - 1) == i) {

                                    $scope.EmploymentInformation[i].IndividualEmploymentId = $scope.employmentData.IndividualEmploymentId;
                                    $scope.EmploymentInformation[i].EmployerName = $scope.employmentData.EmployerName;
                                    $scope.EmploymentInformation[i].EmploymentAddress[0].StreetLine1 = $scope.employmentData.PhysicalAddressStreet;
                                    $scope.EmploymentInformation[i].EmploymentAddress[0].StreetLine2 = $scope.employmentData.PhysicalAddressStreet2;
                                    $scope.EmploymentInformation[i].EmploymentAddress[0].City = $scope.employmentData.EmployerCity;
                                    $scope.EmploymentInformation[i].EmploymentAddress[0].StateCode = $scope.employmentData.PhysicalAddressState;
                                    $scope.EmploymentInformation[i].EmploymentAddress[0].Zip = $scope.employmentData.PhysicalAddressZIP;

                                    // Employment Contact
                                    if ($scope.EmploymentInformation[i].EmploymentContact != undefined || $scope.EmploymentInformation[i].EmploymentContact != null) {
                                        var EmploymentContact = $scope.EmploymentInformation[i].EmploymentContact;

                                        // Work Phone
                                        var index = -1;
                                        //var result = $filter('filter')(EmploymentContact, function (d) {
                                        //    if (d.Code === "W") {
                                        //        index = EmploymentContact.indexOf(d);
                                        //        return d;
                                        //    }
                                        //});

                                        var result = {};
                                        angular.forEach(EmploymentContact, function(val, index) {
                                            if (val.Code === "W") {
                                                result = val
                                            }
                                        });





                                        if ($scope.employmentData.WorkPhone != null && $scope.employmentData.WorkPhone != "") {
                                            if (result.length > 0) {
                                                EmploymentContact[index].ContactInfo = $scope.employmentData.WorkPhone;
                                            } else {
                                                EmploymentContact.push({
                                                    IndividualEmploymentContactId: 0,
                                                    IndividualId: $scope.individualId,
                                                    ContactId: 0,
                                                    IndividualEmploymentId: 0,
                                                    ContactTypeId: 3,
                                                    BeginDate: $scope.employmentData.EmploymentStartDate,
                                                    EndDate: null,
                                                    IsPreferredContact: true,
                                                    IsMobile: true,
                                                    IsActive: true,
                                                    ContactFirstName: "",
                                                    ContactLastName: "",
                                                    ContactMiddleName: "",
                                                    Code: "W",
                                                    ContactInfo: $scope.employmentData.WorkPhone,
                                                    DateContactValidated: null
                                                });
                                            }
                                        } else {
                                            if (result.length > 0) {
                                                EmploymentContact[index].ContactInfo = WorkContactInfo;
                                            }
                                        }

                                        index = -1;
                                        result = {};
                                        angular.forEach(EmploymentContact, function(val, index) {
                                            if (val.Code === "A") {
                                                result = val
                                            }
                                        });

                                        //result = $filter('filter')(EmploymentContact, function (d) { 
                                        //    if (d.Code === "A") {
                                        //        index = EmploymentContact.indexOf(d);
                                        //        return d;
                                        //    }
                                        //});


                                        if ($scope.employmentData.AlternatePhone != null && $scope.employmentData.AlternatePhone != "") {

                                            if (result.length > 0) {
                                                EmploymentContact[index].ContactInfo = $scope.employmentData.AlternatePhone;
                                            } else {
                                                EmploymentContact.push({
                                                    IndividualEmploymentContactId: 0,
                                                    IndividualId: $scope.individualId,
                                                    ContactId: 0,
                                                    IndividualEmploymentId: 0,
                                                    ContactTypeId: 4,
                                                    BeginDate: $scope.employmentData.EmploymentStartDate,
                                                    EndDate: null,
                                                    IsPreferredContact: true,
                                                    IsMobile: true,
                                                    IsActive: true,
                                                    ContactFirstName: "",
                                                    ContactLastName: "",
                                                    ContactMiddleName: "",
                                                    Code: "A",
                                                    ContactInfo: $scope.employmentData.AlternatePhone,
                                                    DateContactValidated: null
                                                });
                                            }
                                        } else {
                                            if (result.length > 0) {
                                                EmploymentContact[index].ContactInfo = $scope.employmentData.AlternatePhone;
                                            }
                                        }

                                        // FAX
                                        index = -1;
                                        result = {};
                                        angular.forEach(EmploymentContact, function(val, index) {
                                            if (val.Code === "F") {
                                                result = val
                                            }
                                        });

                                        //result = $filter('filter')(EmploymentContact, function (d) {
                                        //    if (d.Code === "F") {
                                        //        index = EmploymentContact.indexOf(d);
                                        //        return d;
                                        //    }
                                        //});


                                        if ($scope.employmentData.EmploymentFax != null && $scope.employmentData.EmploymentFax != "") {
                                            if (result.length > 0) {
                                                EmploymentContact[index].ContactInfo = $scope.employmentData.EmploymentFax;
                                            } else {
                                                EmploymentContact.push({
                                                    IndividualEmploymentContactId: 0,
                                                    IndividualId: $scope.individualId,
                                                    ContactId: 0,
                                                    IndividualEmploymentId: 0,
                                                    ContactTypeId: 5,
                                                    BeginDate: $scope.employmentData.EmploymentStartDate,
                                                    EndDate: null,
                                                    IsPreferredContact: true,
                                                    IsMobile: true,
                                                    IsActive: true,
                                                    ContactFirstName: "",
                                                    ContactLastName: "",
                                                    ContactMiddleName: "",
                                                    Code: "F",
                                                    ContactInfo: $scope.employmentData.EmploymentFax,
                                                    DateContactValidated: null
                                                });
                                            }
                                        }
                                    } else {
                                        if (result.length > 0) {
                                            EmploymentContact[index].ContactInfo = $scope.employmentData.EmploymentFax;
                                        }
                                    }

                                    $scope.EmploymentInformation[i].EmploymentContact = EmploymentContact;

                                    initialApplicationEmploymentService.employmentSave($scope.EmploymentInformation[i], sessionStorage.Key)
                                        .then(function(response) {
                                            if ($scope.isApprentice && response.IndividualEmploymentResponseList && response.IndividualEmploymentResponseList.length > 0) {
                                                saveSupervisorrmation(response.IndividualEmploymentResponseList[0].IndividualEmploymentId);
                                            } else {
                                                $scope.clearDataEmployment();
                                            }
                                            $scope.addEmploymentInformation = false;
                                            angular.element(document.querySelector("#btnEmploymentInformation")).show();
                                            $scope.employmentData.IndividualEmploymentId = null;
                                            // $scope.IndividualRenewal.IndividualEmployment = $scope.EmploymentInformation;
                                            if (!$rootScope.ProcessLoaderExternally)
                                                HideLoader();
                                        }, function(data) {
                                            ShowErrMsg();
                                            if (!$rootScope.ProcessLoaderExternally)
                                                HideLoader();
                                            if (data != null) { //$scope.showStatusMessage(data.message, "error");
                                            }
                                        });
                                    break;
                                }
                            }
                        }
                    }
                } else {
                    // $scope.validateEmploymentInformationPhone();
                    // HideLoader();
                }
            } catch (ex) {

            }
        };

        $scope.clearDataEmployment = function() {
            try {
                $scope.chkEmployment = true;
                $scope.DisableContactDetails = false;
                $scope.employmentData = {};
                $scope.employmentEditIndex = 0;
            } catch (ex) {
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.setVisibilityOfAddForm = function(employmentForm, showForm) {
            employmentForm.$setPristine();
            if ($scope.viewConfig == undefined)
                $scope.viewConfig = {};
            if (showForm)
                $scope.employment = {};
            $scope.viewConfig.showAddForm = showForm;
        }

        $scope.$on("SaveRenewal", function(event, args) {
            //for Apprentice only mandatory validation required
            //initialApplicationstatusService.setEmployment(true);
            if (!$scope.isApprentice) {
                initialApplicationstatusService.setEmployment('success');
                return;
            }

            //get EmploymentInformation list where isdeleted is false
            var FilteredList = [];
            if ($scope.EmploymentInformation) {
                for (var i = 0; i < $scope.EmploymentInformation.length; i++) {
                    if (!$scope.EmploymentInformation.IsDeleted) {
                        FilteredList.push($scope.EmploymentInformation[i]);
                    }
                }
            }
            var isWorkInfoValid = true;
            if (FilteredList.length == 0) {
                isWorkInfoValid = false;
                initialApplicationstatusService.setEmployment("invalid");
                if ($scope.natValidation.errorMessages == undefined)
                    $scope.natValidation.errorMessages = [];

                if (!validateForm($scope.currentForm)) {
                    customValidation();
                    return;
                }
                if (!customValidation()) {
                    return;
                }
                var errorObj = {
                    propName: "natrequired-workinfo",
                    errorMessage: Messages.General.Errors.WorkInfoRequired
                };

                if (!checkPropertyExists($scope.natValidation.errorMessages, 'natrequired-ceinfo'))
                    $scope.natValidation.errorMessages.push(errorObj);
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
            } else {
                initialApplicationstatusService.setEmployment("success");
            }
        });



        //Typeahead Employer details
        $scope.loadTypeahed = function(searchString) {
            $scope.DisableContactDetails = false;
            var urlParam = "/" + (sessionStorage.Key ? sessionStorage.Key : "key") + "?SearchString=" + searchString + "&ProviderTypeId=2";
            return initialApplicationEmploymentService.getEmployerList(urlParam).then(function(response) {
                if (!!response.data.Employers) {
                    return response.data.Employers.map(function(item) {
                        item.formattedResult = (item.EmployerName + " " + item.StreetLine1 + " " + item.StreetLine2 + " " + item.City + " " + item.Zip);
                        return item;
                    });
                }
            }, function(res) {

            });
        };
        $scope.SelectTypeAheadItem = function(item, $model, $label, $event) {
            $scope.DisableContactDetails = true;
            $scope.employmentData.EmploymentFax = '';
            $scope.employmentData.WorkPhone = '';
            $scope.employmentData.EmployerName = item.EmployerName;
            //set address details
            //if (item.EmployerAddress && item.EmployerAddress.length > 0) {
            //    var sddress = item.EmployerAddress[0];
            var sddress = item;
            $scope.employmentData.PhysicalAddressStreet = sddress.StreetLine1;
            $scope.employmentData.PhysicalAddressStreet2 = sddress.StreetLine2;
            $scope.employmentData.EmployerCity = sddress.City;
            $scope.employmentData.PhysicalAddressState = sddress.StateCode;
            $scope.employmentData.PhysicalAddressZIP = sddress.Zip;
            //var contac
            //}
        };

        var individualNameSave = function(reqdata) {
            var deferred = $q.defer();
            var reqParam = '/' + sessionStorage.Key
            initialApplicationEmploymentService.individualNameSave(reqdata, reqParam).then(function(res) {
                deferred.resolve(res.data);
            }, function(res) {
                deferred.reject(res.data);
                ShowErrMsg();
            });
            return deferred.promise;
        };

        var saveSupervisorrmation = function(indvEmpId) {
            try {
                var data = {
                    IndividualSupervisoryInfoId: $scope.employmentData.IndividualSupervisoryInfoId,
                    IndividualId: $scope.individualId,
                    ApplicationId: $scope.applicationId,
                    SupervisorLicenseNumber: $scope.employmentData.SupervisorLicense,
                    IsActive: true,
                    IndividualNameId: 0, //$scope.individualId,
                    FirstName: $scope.employmentData.SupervisorName,
                    MiddleName: $scope.employmentData.SupervisorMiddleName,
                    LastName: $scope.employmentData.SupervisorLastName,
                    SupervisorWorkAddressId: 0,
                    SupervisorType: $scope.employmentData.SupervisorProfession,
                    IsDeleted: false,
                    Individualemploymentid: indvEmpId
                }
                var indvNameData = {
                    IndividualNameId: $scope.employmentData.IndividualNameId,
                    IndividualId: $scope.individualId,
                    FirstName: $scope.employmentData.SupervisorName,
                    MiddleName: ($scope.employmentData.SupervisorMiddleName && $scope.employmentData.SupervisorMiddleName != '' ? $scope.employmentData.SupervisorMiddleName : ''),
                    LastName: $scope.employmentData.SupervisorLastName,
                    IndividualNameTypeId: 8,
                    IsActive: 1,
                    IsDeleted: 0
                };
                individualNameSave(indvNameData).then(function(res) {
                    if (res.IndividualNameResponse && res.IndividualNameResponse.length > 0) {
                        data.IndividualNameId = res.IndividualNameResponse[0].IndividualNameId
                        initialApplicationEmploymentService.assistantSave(data, sessionStorage.Key)
                            .then(function(response) {
                                //if new added then assign id
                                if (!$scope.employmentData.IndividualSupervisoryInfoId && response.IndividualSupervisoryInfos && response.IndividualSupervisoryInfos.length > 0)
                                    $scope.employmentData.IndividualSupervisoryInfoId = response.IndividualSupervisoryInfos[0].IndividualSupervisoryInfoId;
                                if (!$scope.employmentData.IndividualNameId) {
                                    $scope.employmentData.IndividualNameId = data.IndividualNameId;
                                }
                                //
                                setSupervisorIfEditSuccess(indvEmpId);

                                $scope.clearDataEmployment();
                                if (!$rootScope.ProcessLoaderExternally)
                                    HideLoader();
                            }, function(data) {
                                if (!$rootScope.ProcessLoaderExternally)
                                    HideLoader();
                                if (data != null) { //$scope.showStatusMessage(data.message, "error");
                                    ShowErrMsg();
                                }
                            });
                    } else {
                        var msg = 'Supervisor name already exist. Please edit if you want to change.';
                        if (-1 != res.Message.indexOf("already exist")) {
                            msg = 'Supervisor name already exist. Please edit if you want to change.';
                        }
                        var errorObj = {
                            propName: "natrequired-workinfo-indvName",
                            errorMessage: msg
                        };
                        if (!$scope.natValidation.errorMessages)
                            $scope.natValidation.errorMessages = [];
                        if (!checkPropertyExists($scope.natValidation.errorMessages, 'natrequired-workinfo-indvName'))
                            $scope.natValidation.errorMessages.push(errorObj);
                        $scope.natValidation.showError = true;
                        $scope.natValidation.isValid = false;
                    }
                });
            } catch (ex) {

            }
        };

        var getSupervisorIndexbyIndividualemploymentid = function(Individualemploymentid) {
            if ($scope.SupervisorInfo && $scope.SupervisorInfo != null && $scope.SupervisorInfo.length > 0)
                return $scope.SupervisorInfo.map(function(i) { return i.Individualemploymentid }).indexOf(Individualemploymentid);
            return -1;
        };

        var setSupervisorIfEditSuccess = function(Individualemploymentid) {
            var svrInd = getSupervisorIndexbyIndividualemploymentid(Individualemploymentid);
            if (svrInd != -1) {
                $scope.SupervisorInfo[svrInd].IndividualSupervisoryInfoId = $scope.employmentData.IndividualSupervisoryInfoId;
                $scope.SupervisorInfo[svrInd].FirstName = $scope.employmentData.SupervisorName;
                $scope.SupervisorInfo[svrInd].MiddleName = $scope.employmentData.SupervisorMiddleName;
                $scope.SupervisorInfo[svrInd].LastName = $scope.employmentData.SupervisorLastName;
                $scope.SupervisorInfo[svrInd].SupervisorLicenseNumber = $scope.employmentData.SupervisorLicense;
                $scope.SupervisorInfo[svrInd].IndividualNameId = $scope.employmentData.IndividualNameId;
                $scope.SupervisorInfo[svrInd].SupervisorType = $scope.employmentData.SupervisorProfession;
            } else {
                var newSvr = {
                    Individualemploymentid: Individualemploymentid,
                    IndividualSupervisoryInfoId: $scope.employmentData.IndividualSupervisoryInfoId,
                    FirstName: $scope.employmentData.SupervisorName,
                    MiddleName: $scope.employmentData.SupervisorMiddleName,
                    LastName: $scope.employmentData.SupervisorLastName,
                    SupervisorLicenseNumber: $scope.employmentData.SupervisorLicense,
                    IndividualNameId: $scope.employmentData.IndividualNameId,
                    SupervisorType: $scope.employmentData.SupervisorProfession
                }
                $scope.SupervisorInfo.push(newSvr);
            }
        };
        $scope.init();
    }
})();