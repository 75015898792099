(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .controller('ApplicationIndividualSearchController', ['$scope', '$q', 'licenseApplicationListingService', ApplicationIndividualSearchController]);

    function ApplicationIndividualSearchController($scope, $q, licenseApplicationListingService) {
        var getNewSearch = function () {
            return {
                SearchByName: '',
                Application: '',
                SSN: '',
                Name: '',
                LastName: '',
                FirstName: '',
                Phone: '',
                Status: '',
                DateOfBirth: '',
                LicenseNumber: '',
                Email: '',
                Address: '',
                LicenseTypeId: 0,
                LicenseStatusTypeId: 0,
                notIndividualInfo: true,

                IsActive: true,
                SortColumn: 'LastName asc, FirstName',
                SortOrder: 'asc'
            };
        };

        $scope.maskConfig = {
            clearOnBlur: false,
            allowInvalidValue: true,
            clearOnBlurPlaceholder: false
        };

        $scope.init = function () {
            $scope.showSearch = true;
            $scope.indPager = {
                totalItems: 0,
                itemsPerPage: 10,
                fromRow: 0,
                currentPage: 1,
                maxSize: 6
            };

            $scope.search = getNewSearch();

            // $scope.$evalAsync(function () {
            //     $scope.getIndividualSearchWithPage();
            // });
        };

        $scope.indPageChanged = function () {
            $scope.indPager.fromRow = ($scope.indPager.currentPage * $scope.indPager.itemsPerPage) - $scope.indPager.itemsPerPage;
            $scope.getIndividualSearchWithPage();
        };

        $scope.getIndividualSearchWithPage = function () {
            var reqdata = $scope.search;

            $scope.hideStatusMsg();
            $scope.operationPending(true, 'Searching...');

            reqdata.FromRow = $scope.indPager.fromRow;
            reqdata.ItemsPerPage = $scope.indPager.itemsPerPage;
            reqdata.UserId = $scope.userId;

            licenseApplicationListingService.getIndividualSearchWithPage(sessionStorage.Key, $scope.indPager.currentPage, $scope.indPager.itemsPerPage, reqdata)
                .then(function (response) {
                    if (response.Status) {
                        $scope.IndividualList = response.IndividualList;

                        $scope.indPager.totalItems = response.Total_Recard;

                        console.log($scope.IndividualList);
                    }
                    else {
                        $scope.IndividualList = null;
                        $scope.individualSelected({ $individual: null });
                    }
                    $scope.operationPending(false);
                }, function (error) {
                    $scope.operationPending(false);
                });
        };

        $scope.addNewApplication = function () {
            $scope.individualSelected({ $individual: null });
        };

        $scope.addLicense = function (item) {
            $scope.individualSelected({ $individual: item });
        };

        $scope.cancelSearch = function () {
            $scope.search = getNewSearch();
        };
    }
})();