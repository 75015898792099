// General Controller for the Online-Exam
angular.module('natApp').controller("OnlineExamController", ["$scope", '$timeout', "examFactory", "$q", "$window", "utilityService",
    function ($scope, $timeout, examFactory, $q, $window, utilityService) {
        var stopped;
        $scope.init = function () {
            $scope.accesscode = null;
            $scope.codeInvalid = false;
            $scope.examFinished = false;
            $scope.earlyTest = false;
            $scope.lateTest = false;
            $scope.exam = null;
            $scope.links = null;
            $scope.sync = false;
            $scope.questions = [];
            $scope.index = 0;
            $scope.finishFlag = false;
            $scope.timelimit = {
                hours: 0,
                minutes: 0,
                seconds: 0
            };
            $scope.individualId = sessionStorage.IndividualId;
            $scope.applicationInfo = JSON.parse(sessionStorage.applicationItemForExam);
            $scope.uiLogic = {
                sendExamFailureEmail: false,
                sendExamPassedEmail: false,
                tagsToReplace: {
                    tagName: "#AbsoluteUri#",
                    tagValue: $window.location.origin
                }
            };

            $scope.timerlayout = null;

            // Hide/Show Sections
            $scope.hideVerify = true;
            $scope.hideInstruction = true;
            $scope.hideTest = true;
            $scope.hideCompletion = true;

            // Link Pagination
            $scope.LinkPager = {
                sortType: 'ExamSectionId',
                sortOrder: 'asc',
                currentPage: 1,
                totalRecords: 10,
                pageSize: 20,
                maxSize: 5,
                ExamId: $scope.ExamTarget
            }

            $scope.AnswerBank = [];
            $scope.Answer = {
                EntityExamQuestionAnswerId: -1,
                EntityExamId: 4, // Change Later
                QuestionId: null,
                AnswerId: null,
                IsCorrect: null,
                IsActive: true,
                IsDeleted: false
            };

            $scope.Pager = {
                sortType: 'ExamId',
                sortOrder: 'asc',
                currentPage: 1,
                totalRecords: 10,
                pageSize: 20,
                maxSize: 5,
                ExamId: null,
                ExamCompletion: false
            };

            $scope.getAllExams();
        }

        $scope.$watch('finishFlag', function (newValue) {
            if (newValue == true) {
                window.onbeforeunload = null;
            }
            else {
                window.onbeforeunload = function (evt) {
                    return "Do you want to close the opened application";
                };
            }
        });

        $scope.$watch('sync', function (newValue) {
            if ($scope.sync == true) {
                $scope.sync = false;
                $scope.saveExamAttempt(false);
            }
        });

        $scope.saveExamAttempt = function (status) {

            // Save Total Score
            var total = 0;
            for (var i = 0; i < $scope.questions.length; i++) {
                if ($scope.entityexamquestions[i].IsCorrect == true) {
                    total = total + $scope.questions[i].PointValue
                };
            }
            if (!!status) {
                var questionAnswered = $scope.entityexamquestions.where("!!item.AnswerId");
                if (!questionAnswered || questionAnswered.length < $scope.entityexamquestions.length) {
                    $scope.showExamMsg("-", "Not all questions have a response. Please answer all questions.");
                    return;
                } else {
                    $scope.hideExamMsg();
                }
                if (!confirm("Are you ready to submit your Exam?")) {
                    return;
                }
            }

            $scope.entityexam.TotalScore = total;

            // Save Results
            $scope.entityexam.ResultStatus = 2;
            if ($scope.entityexam.TotalScore >= ($scope.exam.OverallScore * $scope.exam.PassingScore)) {
                $scope.entityexam.ResultStatus = 1;
            }

            // Save Time Left
            $scope.entityexam.TimeLeft = $scope.timelimit.hours + ":" + $scope.timelimit.minutes;

            // If Exam is ending, then save Completion status
            if (status == true) {
                $scope.entityexam.ExamCompletion = true;
                $scope.entityexam.ExamEndDate = new Date();
                $scope.entityexam.ExamDate = new Date();
                $scope.loadingExamUser(true);
                if (!!stopped) {
                    $timeout.cancel(stopped);
                }
            }
            examFactory.saveEntityExam(sessionStorage.Key, $scope.entityexam).then(function (res) {
                $scope.saveAnswers(status);
                console.log(res);
            });
        }

        $scope.createEntityExam = function (applicableExam) {
            // Set up Multi-Calls
            var promises = [];

            //To Do Load Exam by license Type Etc;
            $scope.exam = applicableExam;
            $scope.exam.ApplicationId = $scope.applicationInfo.ApplicationId;
            $scope.exam.EntityId = $scope.individualId;
            $scope.exam.EntityType = "I";
            $scope.exam.ExamStartDate = new Date();
            $scope.exam.GenerateExamQuestionAnswer = true;
            $scope.exam.EntityExamId = -1;
            $scope.exam.TimeLimit = $scope.exam.ExamTimeLimit;
            $scope.exam.TimeLeft = $scope.exam.ExamTimeLimit;
            $scope.exam.ExamTypeId = 1;
            $scope.exam.ExamNameId = 1;

            $scope.loadingExamUser(true);

            promises.push(examFactory.saveEntityExam(sessionStorage.Key, $scope.exam));

            $q.all(promises).then(function (res) {
                var examData = res[0].data.ExamList[0];
                $scope.exam.EntityExamId = examData.EntityExamId;
                $scope.hideVerify = true;
                $scope.hideInstruction = false;
            }).finally(function () {
                $scope.loadingExamUser(false);
            });
        }

        $scope.getAllExams = function () {
            $scope.Pager.LicenseTypeId = $scope.applicationInfo.LicenseTypeId;
            examFactory.getAllExams(sessionStorage.Key, $scope.Pager).then(function (res) {
                $scope.allExams = angular.copy(res.data.ExamList);
                if (!!$scope.allExams && $scope.allExams.length > 0) {
                    var applicableExam = $scope.allExams.whereEquals(true, 'IsActive')
                        .filter(function (examItem) {
                            return (!examItem.EndDate || utilityService.checkIfDateGreaterOrEqual(examItem.EndDate, new Date()))
                        })
                        .orderByDescending('ExamId').firstOrDefault();
                    if (!!applicableExam) {
                        //exam.Completed = false;
                        var entityExamPager = {
                            sortType: 'ExamId',
                            sortOrder: 'asc',
                            currentPage: 1,
                            totalRecords: 10,
                            pageSize: 100,
                            maxSize: 5,
                            ExamId: null,
                            FromDate: null,
                            ToDate: null,
                            EntityType: 'I',
                            EntityId: $scope.individualId,
                            ExamId: applicableExam.ExamId
                        };
                        examFactory.getEntityExams(sessionStorage.Key, entityExamPager).then(function (res) {
                            var entityExams = res.data.ExamList;
                            if (!!entityExams && entityExams.length > 0) {
                                var entityExam = entityExams.whereEquals($scope.applicationInfo.ApplicationId, 'ApplicationId')
                                    .whereEquals(false, 'ExamCompletion').whereEquals(applicableExam.ExamId, 'ExamId')
                                    .whereEquals(1, 'ExamTypeId').whereEquals(1, 'ExamNameId')
                                    .firstOrDefault();
                                if (!!entityExam) {
                                    entityExam.ExamDesc = applicableExam.ExamDesc;
                                    entityExam.ExamQuestionText = applicableExam.ExamQuestionText;
                                    $scope.exam = entityExam;
                                    $scope.hideVerify = true;
                                    $scope.hideInstruction = false;
                                } else {
                                    $scope.createEntityExam(applicableExam);
                                }
                            } else {
                                $scope.createEntityExam(applicableExam);
                            }
                        });
                    }
                }
            }).finally(function () {
                $scope.loadingExamUser(false);
            });
        };

        $scope.retrieveData = function () {
            var promises = [];
            $scope.loadingExamUser(true);
            promises.push(examFactory.getSingleEntityExam(sessionStorage.Key, $scope.exam.EntityExamId));
            promises.push(examFactory.getEntityExamQA(sessionStorage.Key, $scope.exam.EntityExamId));

            $q.all(promises).then(function (res) {
                $scope.entityexam = angular.copy(res[0].data.ExamList[0]);
                $scope.entityexamquestions = angular.copy(res[1].data.ExamList);
                $scope.retrieveQuestions($scope.entityexamquestions);
                var str = $scope.entityexam.TimeLeft.split(':');
                $scope.timelimit.hours = Number(str[0]);
                $scope.timelimit.minutes = Number(str[1]);
            });
        }

        $scope.countdown = function () {
            stopped = $timeout(function () {
                var stop = false;
                if ($scope.timelimit.seconds == 0) {

                    // Set Flag to true if we are under 5 minutes
                    if ($scope.timelimit.hours == 0 && $scope.timelimit.minutes <= 5) {
                        $scope.timerlayout = "danger";
                    }
                    else if ($scope.timelimit.hours == 0 && $scope.timelimit.minutes <= 10) {
                        $scope.timerlayout = "warning";
                    }
                    else {
                        $scope.timerlayout = "base";
                    }

                    if ($scope.timelimit.hours > 0 && $scope.timelimit.minutes == 0) {
                        $scope.timelimit.minutes = 59;
                        $scope.timelimit.hours--;
                    }
                    else if ($scope.timelimit.hours == 0 && $scope.timelimit.minutes == 0) {
                        $scope.timelimit.minutes = 0;
                        stop = true;
                        $scope.saveExamAttempt(true);
                    }
                    else {
                        $scope.timelimit.minutes--;
                    }

                    if (stop == true) {
                        $timeout.cancel(stopped);
                    }
                    else {
                        $scope.timelimit.seconds = 59;
                    }
                }
                else if ($scope.timelimit.seconds % 50 == 0) {
                    $scope.sync = true;
                    $scope.timelimit.seconds--;
                }
                else {
                    $scope.timelimit.seconds--;
                }
                if (stop == false) $scope.countdown();
            }, 1000);
        };

        $scope.retrieveQuestions = function (entityQAs) {
            var promises = []
            angular.forEach(entityQAs, function (entityQA, index) {
                promises.push(examFactory.getSingleQuestion(sessionStorage.Key, entityQA.QuestionId));
            });

            $q.all(promises).then(function (res) {
                for (var i = 0; i < res.length; i++) $scope.questions = $scope.questions.concat(res[i].data.ExamList[0]);
                $scope.retrieveAnswers($scope.questions);
            });
        }

        $scope.retrieveAnswers = function (questions) {
            var promises = [];
            var imagepromises = [];
            angular.forEach(questions, function (question, index) {
                promises.push(examFactory.getQuestionAnswers(sessionStorage.Key, question.ExamQuestionId));
            });

            $q.all(promises).then(function (res) {
                for (var i = 0; i < res.length; i++) {
                    if (res[i].data.ExamList.length > 0) {
                        var location = $scope.findIndex(res[i].data.ExamList[0].ExamQuestionId);
                        $scope.questions[location].answers = res[i].data.ExamList;
                    }
                }
            }).finally(function () {
                $scope.hideTest = false;
                $scope.hideInstruction = true;
                $scope.loadingExamUser(false);
                $scope.getImage(false);
                $scope.countdown();
            });
        }

        $scope.saveAnswers = function (loader) {
            var promises = [];
            angular.forEach($scope.entityexamquestions, function (qa, index) {
                if (qa.Altered != null) promises.push(examFactory.saveEntityExamQA(sessionStorage.Key, qa));
                qa.Altered = null;
            });

            $q.all(promises).then(function (res) {
                console.log(res);
                if (loader == true) {
                    $scope.loadingExamUser(false);
                    $scope.hideTest = true;
                    $scope.hideCompletion = false;
                    if ($scope.entityexam.ResultStatus == 2) {
                        $scope.uiLogic.sendExamFailureEmail = true;
                    } else if ($scope.entityexam.ResultStatus == 1) {
                        $scope.uiLogic.sendExamPassedEmail = true;
                    }
                }
            });
        }

        $scope.checkCorrect = function (answer) {
            var QA = $scope.findQA(answer.ExamQuestionId);
            $scope.entityexamquestions[QA].Altered = true;
            if (answer.CorrectAnswer == false) {
                $scope.entityexamquestions[QA].IsCorrect = false;
            }
            else {
                $scope.entityexamquestions[QA].IsCorrect = true;
            }
        }

        $scope.getImage = function (status) {
            if (status == true) $scope.loadingExamUser(true);
            examFactory.getSingleQuestionDocument(sessionStorage.Key, $scope.questions[$scope.index].ExamQuestionId).then(function (res) {
                console.log(res);
                if (res.data.ExamList != null) $scope.questions[$scope.index].image = res.data.ExamList[0];
                else $scope.questions[$scope.index].image = null;
                if (status == true) $scope.loadingExamUser(false);
            });
        }

        $scope.navigationManagement = function (direction) {
            if (direction == "next") {
                if ($scope.index != $scope.questions.length - 1) {
                    $scope.index = $scope.index + 1;
                    $scope.getImage(true);

                    if ($scope.index == $scope.questions.length - 1) {
                        $scope.finishFlag = true;
                    }
                    else $scope.finishFlag = false;
                }
            }
            else if (direction > -1) {
                $scope.index = direction;
                $scope.getImage(true);

                if ($scope.index == $scope.questions.length - 1) {
                    $scope.finishFlag = true;
                }
                else $scope.finishFlag = false;
            }
            else {
                if ($scope.index != 0) {
                    if ($scope.index == $scope.questions.length - 1) {
                        $scope.finishFlag = false;
                    }
                    $scope.index = $scope.index - 1;
                    $scope.getImage(true);
                }
            }
        }

        $scope.findIndex = function (id) {
            return $scope.questions.map(function (e) { return e.ExamQuestionId; }).indexOf(id);
        }

        $scope.findQA = function (id) {
            return $scope.entityexamquestions.map(function (e) { return e.QuestionId; }).indexOf(id);
        }

        window.onbeforeunload = function (evt) {
            return "Do you want to close this exam?";
        };

        $scope.openNew64Image = function () {
            var newTab = $window.open('Exam Image ' + $scope.index, '_blank');
            newTab.document.write('<div style="width:100%;"><img src="data:image/png;base64,' + $scope.questions[$scope.index].image.Photo + '" style="max-width:100%; height: auto;"></div>');

            // var newTab = window.open();
            // newTab.document.body.innerHTML = '<img src="data:image/png;base64,' + $scope.questions[$scope.index].image.Photo + '">';
        }

    }]);

angular.module('natApp').directive('ngConfirmClick', [
    function () {
        return {
            link: function (scope, element, attr) {
                var msg = attr.ngConfirmClick || "Are you sure?";
                var clickAction = attr.confirmedClick;
                element.bind('click', function (event) {
                    if (window.confirm(msg)) {
                        scope.$eval(clickAction)
                    }
                });
            }
        };
    }])
