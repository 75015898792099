(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('legalQuestionsLogic', [LegalQuestionsLogic]);

    function LegalQuestionsLogic() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                
            }
        };
    }
})();