﻿(function () {
    'use strict'
    angular.module('app.provider')
        .controller('providerApplicationFeeCont', ['$scope', 'providerApplicationFeeFactory', '$q', 'initialProviderApplicationFeePaymentService', '$rootScope', 'Messages', function ($scope, providerApplicationFeeFactory, $q, initialProviderApplicationFeePaymentService, $rootScope, Messages) {
            var _key = sessionStorage.Key;
            $scope.init = function () {
                if ($scope.providerId) {
                    getPaymentDetails();
                }
            };

            $scope.$watch('providerId', function (newval, oldval) {
                if (newval && oldval && newval != oldval) {
                    getPaymentDetails();
                }
            })

            var getPaymentDetails = function () {
                providerApplicationFeeFactory.getPaymentDetails(_key, $scope.providerId, $scope.isdashboard, sessionStorage.UserID).then(function (res) {
                    HideLoader();
                    if (res.data.Status) {
                        $scope.PaymentDetails = res.data.PaymentAllDetails;
                    }
                });
            };

            $scope.paymentEnterKey = function($event, clickAction, individualId, paymentd, type){
                if($event.keyCode == 13){
                    $rootScope.individualEventLog(clickAction, individualId);
                    if(type == 'show'){
                        $scope.ShowPayment(paymentd) 
                    }else if(type == 'payInvoice'){
                        $scope.payInvoice(paymentd)
                    }else if(type == 'deleteInvoice'){
                        $scope.deleteInvoice(paymentd)
                    }
                }
            }

            var win = {};
            $scope.showPayment = function (item) {
                if (item) {
                    if (item.HTMLContent) {
                        win = window.open("", "Payment Details", "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600, top=" + (100) + ", left=" + 240);
                        win.document.body.innerHTML = item.HTMLContent ? item.HTMLContent : '';
                    } else {
                        ShowLoader();
                        downloadPaymentReceipt(item).then(function (res) {
                            if (res.data.Status) {
                                item.HTMLContent = res.data.PaymentEmailHtml;
                                win = window.open("", "Payment Details", "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600, top=" + (100) + ", left=" + 240);
                                if (win) {
                                    win.document.body.innerHTML = item.HTMLContent ? item.HTMLContent : '';
                                } else {
                                    win = window.open("", "Payment Details", "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600, top=" + (100) + ", left=" + 240);
                                    win.document.body.innerHTML = item.HTMLContent ? item.HTMLContent : '';
                                }
                            }
                            HideLoader();
                        }, function (res) {
                            HideLoader();
                        });
                    }
                }
            };


            $scope.sendEmail = function (item) {
                if (item.HTMLContent) {
                    item.showEmailSec = !item.showEmailSec;
                } else {
                    ShowLoader();
                    downloadPaymentReceipt(item).then(function (res) {
                        if (res.data.Status) {
                            item.HTMLContent = res.data.PaymentEmailHtml;
                            item.showEmailSec = !item.showEmailSec;
                        }
                        HideLoader();
                    }, function (res) {
                        HideLoader();
                    });
                }
            };

            var downloadPaymentReceipt = function (item) {
                var defer = $q.defer();
                var data = {
                    IndividualId: 0,
                    ApplicationId: item.ApplicationID,
                    InvoiceNumber: item.InvoiceID,
                    ProviderId: $scope.providerId,
                    IsSendEmail: false,
                    GenerateHtml: true
                };
                providerApplicationFeeFactory.sendPaymentConfirmationEmail(sessionStorage.Key, data).then(function (res) {
                    defer.resolve(res);
                }, function (err) {
                    defer.reject(err);
                });
                return defer.promise;
            };

            $scope.showMessage = function (message, type) {
                $scope.showStatusMessage({ message: message, type: type });
            };

            $scope.payInvoice = function (invoice) {
                ShowLoader();

                initialProviderApplicationFeePaymentService.getInvoiceDetailByIndividualIdAndInvoiceId(sessionStorage.Key, $scope.providerId, invoice.InvoiceID)
                    .then(function (objInvoiceDetail) {
                        HideLoader();
                        if (objInvoiceDetail.Status) {
                            $scope.feeDetails = {};
                            $scope.feeDetails.totalFee = 0;
                            $scope.feeDetailRows = [];
                            angular.forEach(objInvoiceDetail.InvoiceDetails, function (item) {
                                $scope.feeDetailRows.push({ Amount: item.Amount, feeDetailType: { FeeDetailDesc: item.Description, BoardInfoId: $scope.boardInfoId, FeeAmount: item.Amount, FeeDetailCode: item.TransCode } });
                                $scope.feeDetails.totalFee = $scope.feeDetails.totalFee + item.Amount;
                            });
                            $scope.feeDetails.InvoiceId = invoice.InvoiceID;
                            $scope.individualid = $scope.individualid;
                            $scope.ApplicationId = invoice.ApplicationID;
                            $scope.proceedToPayment = true;
                            $('#paymentModal').modal('show');
                        }
                        else {
                            showStatusMessage(objInvoiceDetail.Message, 'error');
                            getPaymentDetails();
                        }
                    }, function (error) {
                        HideLoader();
                    });
            }
            $scope.paymentStatusUpdate = function (action) {
                if (action == 'success') {
                    HideLoader();
                } else if (action == 'close') {
                    //  if (confirm(Messages.eefcfca)) {
                    $scope.proceedToPayment = false;
                    $('#paymentModal').modal('hide');
                    getPaymentDetails();
                    //}
                }
            };

            $scope.closePayment = function () {
                $scope.proceedToPayment = false;
                $('#paymentModal').modal('hide');
                getPaymentDetails();
            }

            $scope.deleteInvoice = function (invoice) {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    ShowLoader();
                    invoice.InvoiceStatus = 'DELETED';
                    initialProviderApplicationFeePaymentService.invoiceMasterSave(sessionStorage.Key, [invoice]).then(function (response) {

                        getPaymentDetails();
                    }, function (error) {
                        HideLoader();
                    });
                }
            }


        }]);
})();