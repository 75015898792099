﻿(function () {
    'use strict'
    angular.module('app.providerDashboard')
        .controller("providerDashboardController", ['$scope', '$rootScope', '$state', 'bppService', "LoginwemailService", function ($scope, $rootScope, $state, bppService, LoginwemailService) {
            // page init method
            var init = function () {
                try {
                    if ($scope.isUserSessionActive()) {
                        $scope.providerId = sessionStorage.ProviderId;
                        $scope.isBackoffice = false;
                        providerGetByProviderId();
                    } else {
                        $state.go('app.LoginBusinessPortal');
                    }
                } catch (ex) {
                    if (ex != null) {
                        showStatusMessage(ex.message, "error");
                    }
                }
            };
            $scope.resetPasswordClick = function () {
                $state.go('app.ChangePassword');
            };
            $scope.logoutClick = function () {
                LoginwemailService.logout();
                sessionStorage.clear();
                $state.go('app.LoginBusinessPortal');
            };

            $scope.additionalServiceLinkClicked = function (id) {
                if (id == 1) {
                    $state.go('app.RECeCourseProvider');
                } else if (id == 2) {
                    $state.go('app.APCeCourseProvider');
                } else if (id == 3) {
                    $state.go('app.CeCourseProviderIndividual');
                } else if (id == 4) {
                    $state.go('app.CECreditbyCourse');
                } else if (id == 5) {
                    $state.go('app.providerSignIn');
                } else if (id == 6) {
                    $state.go('app.DatesToApprovedCourses');
                } else if (id == 7) {
                    $state.go('app.VerifyCeCourseIndividual');
                } else if (id == 8) {
                    $state.go('app.PreLicensingRosterandCredit');
                } else if (id == 9) {
                    $state.go('app.CECourseHoursUpload');
                }
            };

            var providerGetByProviderId = function () {
                ShowLoader("Please Wait...");
                bppService.getProviderById(sessionStorage.Key, $scope.providerId).then(function (res) {
                    if (res.Status) {
                        $scope.providerDetails = res.ProviderResponse;
                    } else {
                        $scope.showStatusMessage(res.Message, "error");
                    }
                    HideLoader();
                }, function (err) {
                    HideLoader();
                    $scope.showStatusMessage(err.Message, "error");
                });
            };

            init();
        }]);
})();
