(function() {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationLicenseDetailsController", InitialApplicationLicenseDetailsController);

    InitialApplicationLicenseDetailsController.$inject = ["$rootScope", "$scope", "initialApplicationLicenseDetailsService", "initialApplicationstatusService", "utilityService", "WebApiUrl"];

    function InitialApplicationLicenseDetailsController($rootScope, $scope, initialApplicationLicenseDetailsService, initialApplicationstatusService, utilityService, WebApiUrl) {

        $scope.init = function() {
            $scope.getLicenseDetailsConfig();

        };

        $scope.getLicenseDetailsConfig = function() {
            var relativeFilePath = "/individual/application/license-details/license-details.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function(data) {
                    $scope.licenseDetailsConfig = data;
                    $scope.isConfigLoaded = true;
                }, function(error) {
                    ShowErrMsg("licenseDetailsConfigNotLoaded", "Some error occurred,Unable to load the renewal type config");
                });
        };

        $scope.setForm = function(currentForm) {
            $scope.currentForm = currentForm;
        };

        var validateForm = function(currentForm) {
            $scope.natValidation.errorMessages = [];
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.init();
    }
})();