(function () {
    'use strict'

    angular.module('app.provider')
        .factory('bppService', bppService)

    bppService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function bppService($http, $q, WebApiUrl, AppConfig) {

        var _getIndividualDetailforCEProvider = function (searchContent, key, pageNumber, noOfRecords) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/GetIndividualDetailforCEProvider/' + key + '?PageNumber=' + pageNumber + '&NoOfRecords=' + noOfRecords,
                searchContent));
        };

        var _getProviderById = function (key, providerid) {
            return returnDefferedResult($http.get(WebApiUrl + 'Provider/GetProviderById/' + key + '?providerid=' + providerid));
        };

        var _getProviderApplicationList = function (key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'ProviderApplication/GetProviderApplicationList/' + key, reqData));
        };

        var _applicationByProviderId = function (key, providerid) {
            return returnDefferedResult($http.get(WebApiUrl + 'Provider/ApplicationBYProviderId/' + key + '?providerid=' + providerid));
        };

        var _applicationByApplicationId = function (key, applicationId, userId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Common/ApplicationInfoByApplicationId/' + key + '?ApplicationId=' + applicationId + '&UserId=' + userId));
        };

        var _providerCECourseSave = function (CECourse, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Provider/ProviderCourseSave/' + key + '?Source=' + AppConfig.Source, CECourse));
        };

        var _providerCECourseByProviderId = function (providerId, providerName, key, applicationId, onlyLatestRecord) {
            return returnDefferedResult($http.get(WebApiUrl + 'Provider/GetProviderCourseByProviderId/' + key + '?ProviderId=' + providerId + '&ProviderName=' + providerName + ((!!applicationId ? ('&ApplicationId=' + applicationId) : '')) + ((!!onlyLatestRecord ? ('&OnlyLatestRecord=' + onlyLatestRecord) : ''))));
        };

        var _applicationByProviderId = function (key, providerId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Provider/ApplicationBYProviderId/' + key + '?providerid=' + providerId));
        };

        var _applicationStatusUpdate = function (key, applicationId, applicationStatusId, applicationSubmitMode) {
            return returnDefferedResult($http.post(WebApiUrl + 'Common/ApplicationStatusUpdate/' + key + '?ApplicationId=' + applicationId + '&ApplicationStatusId=' + applicationStatusId + '&Source=' + AppConfig.Source + '&ApplicationSubmitMode=' + applicationSubmitMode));
        };

        var _saveProviderCourseUpload = function (key, reqObj) {
            return returnDefferedResult($http.post(WebApiUrl + 'Provider/SaveProviderCourseUpload/' + key + '?Source=' + AppConfig.Source, reqObj));
        };

        var _getProviderCourseUpload = function (key, providerId, applicationId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Provider/GetProviderCourseUpload/' + key + '?providerid=' + providerId + '&ApplicationId=' + applicationId));
        };

        var _getIndividualDetailByProviderIdAndCourseName = function (key, providerId, courseName) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualDetailByProviderIdAndCourseName/' + key + '?providerId=' + providerId + '&courseName=' + courseName));
        };

        var _indAssExcelParse = function (ceCourseExcel, key) {
            return returnDefferedResult($http.post(ceCourseExcel.ApiSaveEndpoint + key, ceCourseExcel));
        };

        var _getEmployerList = function (key, searchString, providerTypeId, searchType) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetEmployerNameAndAddress/' + key + "?SearchString=" + escape(searchString) + "&ProviderTypeId=" + providerTypeId + "&SearchType=" + searchType));
          };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        return {
            getIndividualDetailforCEProvider: _getIndividualDetailforCEProvider,
            getProviderById: _getProviderById,
            getProviderApplicationList: _getProviderApplicationList,
            applicationByProviderId: _applicationByProviderId,
            providerCECourseSave: _providerCECourseSave,
            providerCECourseByProviderId: _providerCECourseByProviderId,
            applicationByApplicationId: _applicationByApplicationId,
            applicationStatusUpdate: _applicationStatusUpdate,
            saveProviderCourseUpload: _saveProviderCourseUpload,
            getProviderCourseUpload: _getProviderCourseUpload,
            getIndividualDetailByProviderIdAndCourseName: _getIndividualDetailByProviderIdAndCourseName,
            getEmployerList: _getEmployerList
        };
    }
})();
