﻿(function () {
    'use strict'
    angular.module('app.provider')
        .factory('providerApplicationDocumentFactory', ['$http', 'WebApiUrl', '$q', 'AppConfig', function ($http, WebApiUrl, $q, AppConfig) {
            var baseFactory = {};
            baseFactory.applicationDocumentByProviderId = function (key, providerId, UserId) {
                return $http.get(WebApiUrl + 'Provider/ApplicationDocumentByProviderId/' + key + '?ProviderId=' + providerId + '&UserId=' + UserId);
            }
            baseFactory.GetEntityLicenseAssociation = function (key, LicenseNumber, UserId) {
                return $http.get(WebApiUrl + 'Entity/GetEntityLicenseAssociation/' + key + '?LicenseNumber=' + LicenseNumber + '&UserId=' + UserId);
            }
            baseFactory.GetProviderLicenseByCertificateNumber = function (key, CertificateNumber) {
                return $http.get(WebApiUrl + 'Provider/GetProviderLicenseByCertificateNumber/' + key + '?CertificateNumber=' + CertificateNumber);
            }
            baseFactory.SaveEntityLicenseAssociation = function (key, data) {
                return $http.post(WebApiUrl + 'Entity/SaveEntityLicenseAssociation/' + key, data);
            }

            return baseFactory;
        }]);
})();