(function () {
  'use strict';

  angular
    .module('app.InitialApplication')
    .controller("InitialApplicationProfessionalReferencesController", InitialApplicationProfessionalReferencesController);

  InitialApplicationProfessionalReferencesController.$inject = ["$rootScope", "$scope",
    "initialApplicationProfessionalReferencesService", "utilityService", "WebApiUrl", "personalService", "countryStateService", "$q", "contentService", "ConfigurationService", "Messages"];

  function InitialApplicationProfessionalReferencesController($rootScope, $scope,
    initialApplicationProfessionalReferencesService, utilityService, WebApiUrl, personalService, countryStateService, $q, contentService, ConfigurationService, Messages) {

    $scope.reference = {};
    $scope.professionalReferences = [];

    $scope.datePicker = {
      fromDateOpened: false,
      toDateOpened: false,
      format: 'MM/yyyy',
      endDateOptions: {
        minDate: null,
        datepickerMode: 'month',
        minMode: 'month'
      },
      startDateOptions: {
        datepickerMode: 'month',
        minMode: 'month'
      }
    };

    $scope.setForm = function (currentForm) {
      $scope.currentForm = currentForm;
    }

    $scope.setLicenseToMinDate = function () {
      if (angular.isDate($scope.reference.StartDate)) {
        $scope.datePicker.endDateOptions.minDate = $scope.reference.StartDate;
      }
    };

    $scope.setLicenseFromMaxDate = function () {
      if (angular.isDate($scope.reference.EndDate)) {
        $scope.datePicker.startDateOptions.maxDate = $scope.reference.EndDate;
      }
    };

    var validateForm = function (currentForm, isNext) {
      $scope.natValidation.errorMessages = [];
      if (currentForm) {
        if (!currentForm.errorMessages)
          currentForm.errorMessages = [];
        currentForm.$submitted = true;
        $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
      }


      if (isNext) {
        if (!$scope.professionalReferences) {
          var errorObj = {
            propName: "atleastthreesupervisors",
            errorMessage: Messages.General.Errors.References
          };
          $scope.natValidation.errorMessages.push(errorObj);
        }
        if ($scope.professionalReferences) {
          if ($scope.professionalReferences.length < 5) {
            var errorObj = {
              propName: "atleastthreesupervisors",
              errorMessage: Messages.General.Errors.References
            };
            $scope.natValidation.errorMessages.push(errorObj);
          }
        }

      }
      if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
        $scope.natValidation.showError = true;
        $scope.natValidation.isValid = false;
        return false;
      } else {
        $scope.natValidation.showError = false;
        $scope.natValidation.isValid = true;
        return true;
      }
    };

    $scope.getProfessionalReferences = function () {
      ShowLoader();
      initialApplicationProfessionalReferencesService.getIndividualReference($scope.individualId, $scope.applicationId, sessionStorage.Key)
        .then(function (data) {
          if (data.Status)
            $scope.professionalReferences = data.IndividualReferenceList;
          HideLoader();
        }, function (error) {
          HideLoader();
        });
    }

    $scope.SaveIndividualReference = function (data) {
      initialApplicationProfessionalReferencesService.saveIndividualReference([data], sessionStorage.Key)
        .then(function (data) {
          if (data.Status) {
            $scope.getProfessionalReferences();
          }
          $scope.hideRefrenceAddUpdated();
          HideLoader();
        }, function (error) {
          HideLoader();

        });
    }

    $scope.saveNext = function () {
      ShowLoader();
      if (!$rootScope.isFormValidate) {
        if (!validateForm($scope.currentForm, true)) {
          HideLoader();
          return;
        }
      }
      if (!$scope.dashboard && !$scope.isRenewal)
        $scope.$broadcast("SaveDeficiency", {});
      $scope.openNextTab({ currentTab: $scope.tab });
    }

    $scope.editReference = function (reference) {
      $scope.reference = angular.copy(reference);
      $scope.showAddUpdateForm = true;
      $scope.isEdit = true;
    }

    $scope.showRefrenceAddUpdated = function () {
      $scope.showAddUpdateForm = true;
      $scope.reference = {};
      $scope.reference.CountryId = $rootScope.DefaultCountryId;
      $scope.reference.StateCd = 'AL';
    }

    $scope.hideRefrenceAddUpdated = function () {
      $scope.showAddUpdateForm = false;
      $scope.reference = {};
      $scope.currentForm = null;
      $scope.isEdit = false;
    }

    $scope.saveRefrenceForm = function (currentForm) {
      ShowLoader();
      // if (!$rootScope.isFormValidate) {
      if (!validateForm(currentForm)) {
        HideLoader();
        return;
      }
      // }

      if ($scope.isEdit) {
        $scope.reference
      }
      else {
        $scope.reference.ApplicationId = $scope.applicationId;
        $scope.reference.IndividualId = $scope.individualId;
        $scope.reference.IsActive = true;
        $scope.reference.IsDeleted = false;
      }
      $scope.SaveIndividualReference($scope.reference);

    }

    // $scope.getStateByCountryID = function () {
    //   return countryStateService.getStateByCountryID(sessionStorage.Key, $rootScope.DefaultCountryId);
    // };

    $scope.deleteRef = function (individualRefer, indexToDelete) {
      try {
        if (confirm(Messages.General.Confirm.DeleteRecord)) {
          individualRefer.IsDeleted = true;

          initialApplicationProfessionalReferencesService.saveIndividualReference([individualRefer], sessionStorage.Key)
            .then(function (response) {
              if (response.Status && response.StatusCode == "00") {
                $scope.professionalReferences.splice(indexToDelete, 1);
              }
              HideLoader();
            }, function (data) {
              HideLoader();
              ShowErrMsg();
            });
        }
      } catch (ex) {
        // $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
      }
    };


    var getRefLicenseDisciplineTypeGetAll = function () {
      return initialApplicationProfessionalReferencesService.getReferencedisciplinetypeid(sessionStorage.Key);
    }

    function AuditVisitInfoSave() {
      var data = {
        "PageName": $scope.tab.text,
        "SessionId": sessionStorage.Key,
        "IndividualId": $scope.individualId,
        "EntityId": 0,
        "UserId": sessionStorage.UserId,
        "Username": "",
        "RequestUrlReferrer": document.referrer
      };
      utilityService.AuditVisitInfo(data);
    };

    var getCountry = function () {
      return countryStateService.countryGetAll(sessionStorage.Key);
    }

    $scope.init = function () {
      if (!$scope.dashboard)
        AuditVisitInfoSave();
      $scope.getProfessionalReferences();
      ShowLoader();
      var dataSavePromises = [];
      dataSavePromises.push(getRefLicenseDisciplineTypeGetAll());
      dataSavePromises.push(countryStateService.getStateByCountryID(sessionStorage.Key, $rootScope.DefaultCountryId));
      dataSavePromises.push(getCountry());
      $q.all(dataSavePromises)
        .then(function (response) {
          var responseStatus = true;
          var responseMessage = '';
          for (var i = 0; i < response.length; i++) {
            if (response[i].Status == false) {
              responseStatus = false;
              responseMessage = response[i].Message;
              break;
            }
          }
          if (responseStatus == true) {
            $scope.refLicenseDisciplineTypeList = response[0].ReferenceEntities;
            // console.log($scope.refLicenseDisciplineTypeList);
            $scope.StatList = response[1].State
            $scope.PlaceOfBirthStates = response[1].State
            $scope.CountryList = response[2].Country;
          }
          HideLoader();
        }, function (error) {

        });
      var contentId = 129;
      if ($scope.applicationInfo) {
        if ($scope.applicationInfo.LicenseApplicationForId == 1) {
          contentId = 207;
        }
        if ($scope.applicationInfo.LicenseApplicationForId == 2) {
          contentId = 187;
        }
        if ($scope.applicationInfo.LicenseApplicationForId == 3) {
          contentId = 216;
        }
        if ($scope.applicationInfo.LicenseApplicationForId == 4) {
          contentId = 198;
        }
      }
      contentService.getContentByContentItemLkIdList([contentId], sessionStorage.Key)
        .then(function (response) {
          if (response.ContentItemLk)
            $scope.content129 = response.ContentItemLk[0];
        }, function (error) {

        });

      $scope.referenceFormLink = null;
      ConfigurationService.ConfigurationGetbySettings(sessionStorage.Key, 'ReferenceFormLink').then(function (res) {
        if (res && res.data.Status && res.data.ConfigurationList && res.data.ConfigurationList.length > 0) {
          var setVal = res.data.ConfigurationList[0].Value;
          if (setVal && setVal != null) {
            $scope.referenceFormLink = setVal;
          }
        }
      }, function (error) {

      })
    }

    $scope.getStateByCountryID = function (CountryID) {
      ShowLoader();
      try {
        countryStateService.getStateByCountryID(sessionStorage.Key, CountryID)
          .then(function (response) {
            if (response.Status) {
              $scope.PlaceOfBirthStates = response.State;
            }
            else {
              $scope.PlaceOfBirthStates = null;
            }
            HideLoader();
          }, function (data) {
            HideLoader();
            if (data != null) { $scope.showStatusMessage(data.message, "error"); }
          });
      } catch (ex) {
        if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
        $exceptionHandler(ex);
      }
    };

    $scope.init();

  }
})();
