﻿(function () {
    'use strict'
    angular.module('app.core')
        .controller('DashboardDeficiencyDetailsController', ['$scope', '$state', '$controller', 'dashboardDeficiencyDetailsFactory', '$q', 'typeValuesService', 'TypeValue', 'WebApiUrl', 'Messages',
            function ($scope, $state, $controller, dashboardDeficiencyDetailsFactory, $q, typeValuesService, TypeValue, WebApiUrl, Messages) {
                $controller('GlobalController', {
                    $scope: $scope
                });
                var _Key = '';
                var _UserId = '';
                $scope.UserTypeID = sessionStorage.UserTypeID;
                $scope.IndividualId = 0;
                $scope.FirstName = '';
                $scope.LastName = '';
                $scope.DashboardPage = {};
                $scope.DownloadDocumentAPIURL = '';
                // page init method
                $scope.init = function () {
                    try {
                        if ($scope.isSessionActive()) {
                            _Key = sessionStorage.Key;
                            _UserId = sessionStorage.UserID;
                            $scope.IndividualId = sessionStorage.IndividualId;
                            $scope.ApplicationTypeId = sessionStorage.ApplicationTypeId;
                            //Check Deficiency
                            // if (sessionStorage.DeficiencyStatusId && sessionStorage.DeficiencyStatusId == 1) {
                            $scope.displayDeficiencyApplicationButton = false;
                            getIndividualDeficiency();
                            // } else {
                            $scope.DeficiencyList = [];
                            // }
                        }
                    } catch (ex) {
                        showStatusMessage(ex.message, "error");
                    }
                };

                var getIndividualDeficiency = function () {
                    var onlystaffDeficiencyExists = true;
                    if (sessionStorage.ApplicationId && $scope.IndividualId) {
                        $q.all([dashboardDeficiencyDetailsFactory.getIndividualDeficiency(_Key, $scope.IndividualId, sessionStorage.ApplicationId),
                        typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=tabdeficiencyreason"))
                        ]).then(function (response) {
                            if (!response[0].data.Status || !response[1].Status) {
                                showStatusMessage(Messages.bdecebf, "error");
                                return;
                            }
                            $scope.deficiencyReasonData = response[1].ReferenceEntities;
                            angular.forEach(response[0].data.IndividualDefeciencyAllList, function (individualDefeciency) {
                                angular.forEach(individualDefeciency.IndividualDefeciencyDtlList, function (individualDefeciencyDtl) {
                                    if (!!individualDefeciencyDtl.IsDefecient) {
                                        angular.forEach($scope.deficiencyReasonData, function (tabDeficiencyDataItem) {
                                            if (individualDefeciencyDtl.DefeciencyReasonId == tabDeficiencyDataItem.DeficiencyReasonId) {
                                                if (!tabDeficiencyDataItem.ResolvedByStaff || tabDeficiencyDataItem.ResolvedByStaff.toLowerCase() != 'y') {
                                                    onlystaffDeficiencyExists = false;
                                                    individualDefeciencyDtl.IsStaffDeficiency = false;
                                                } else {
                                                    individualDefeciencyDtl.IsStaffDeficiency = true;
                                                }
                                            }
                                        });
                                    }
                                });
                            });

                            prepareDeficiencyList(response[0].data.IndividualDefeciencyAllList);
                            $scope.displayDeficiencyApplicationButton = (!onlystaffDeficiencyExists);;
                        });
                    }
                };

                var prepareDeficiencyList = function (listData) {
                    var res = [];
                    if (listData && listData.length > 0) {
                        for (var i = 0; i < listData.length; i++) {
                            var dDetailList = listData[i].IndividualDefeciencyDtlList;
                            if (dDetailList && dDetailList.length > 0) {
                                for (var j = 0; j < dDetailList.length; j++) {
                                    //filter 
                                    var item = dDetailList[j];
                                    if (item && item.IsDefecient && !item.DateDefeciencyResolved) {
                                        res.splice(0, 0, item);
                                    }
                                }
                            }
                        }
                    }
                    $scope.DeficiencyList = angular.copy(res);
                };

                $scope.processDeficiencyApplication = function () {
                    sessionStorage.currentApplicationId = sessionStorage.ApplicationId;
                    sessionStorage.deficientApplicationId = $scope.applicationId;
                    sessionStorage.isDeficientApplication = true;
                    if ($scope.UserTypeID == 2) {
                        sessionStorage.isNewLicEndApp = false;
                        $state.go('app.OnlineNewApplication');
                    } else if ($scope.UserTypeID == 6) {
                        if ($scope.ApplicationTypeId == 1) {
                            sessionStorage.isNewLicEndApp = false;
                            $state.go('app.LicenseRenewalApplication');
                        } else if ($scope.ApplicationTypeId == 2) {
                            sessionStorage.isNewLicEndApp = true;
                            $state.go('app.OnlineNewApplication');
                        }
                    }
                };

            }
        ]);
})();