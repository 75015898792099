(function () {
    'use strict'

    angular.module('app.enforcement')
    .factory('ComplaintQuestionnaireService', ['$http', '$q', 'WebApiUrl', ComplaintQuestionnaireService]);

    function ComplaintQuestionnaireService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _saveComplaintQuestions = function (complaintQuestions) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/SaveComplaintQuestionsPublic/public/', complaintQuestions));
        };

        var _getComplaintQuestions = function (key, complaintId, linkId, linkType) {
            var urlParams = {
                ComplaintId: complaintId,
                LinkId: linkId,
                LinkType: linkType
            };
            return responseHandler($http.get(WebApiUrl + 'Complaint/GetComplaintQuestions/' + key + '/', { params: urlParams }));
        };

        return {
            saveComplaintQuestions: _saveComplaintQuestions,
            getComplaintQuestions: _getComplaintQuestions
        };
    }
})();