(function() {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeProviderNameController", BackofficeProviderNameController);

    BackofficeProviderNameController.$inject = ["$rootScope", "$scope", "$state", "backofficeProviderNameService", "utilityService", "$exceptionHandler", "WebApiUrl", "typeValuesService", "TypeValue", "BackofficeProviderService", "$q", "ProviderMultipleFileUploadService", 'Messages'];

    function BackofficeProviderNameController($rootScope, $scope, $state, backofficeProviderNameService, utilityService, $exceptionHandler, WebApiUrl, typeValuesService, TypeValue, BackofficeProviderService, $q, ProviderMultipleFileUploadService, Messages) {
       
        $scope.documentConfig = {
            DocId: 16,
            LinkType: 'NM',
            PropertyToProcess: 'ProviderNameId'
        };

        //Tab Initilizer
        $scope.$on("individualTabChanged", function(evt, ProviderId) {
            if ($rootScope.individualFirmCurrentTab.name == "name") {
                $scope.ProviderId = ProviderId;
                $scope.providerNameadd = {};
                $scope.providerNameByProviderId($scope.ProviderId);
                getRefNameStatus();
                initDocument();
                GetProviderByProviderId($scope.ProviderId);
                //$rootScope.individualFirmCurrentTab.isLoadedOnce = true;
            }
        });
        var GetProviderByProviderId = function () {
            if ($scope.ProviderId) {
                //get provider from list
                BackofficeProviderService.GetProviderById(sessionStorage.Key, $scope.ProviderId).then(function (res) {
                    if (res.data.Status && res.data.ProviderResponse) {
                        $scope.ProviderData = res.data.ProviderResponse;
                        // $scope.identificationChanged(true);
                        // loadFirmStatusSection();
                        // setContentItemLkIds();
                        // $scope.getEntityConfirmation();
                    }
                });
            }
        };
        
        var initDocument = function () {
            $scope.documentUpload = undefined;
            setTimeout(function () {
                $scope.$apply(function () {
                    $scope.documentUpload = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                        // ApplicationId: _applicationId,
                        ProviderId: $scope.ProviderId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FRM",
                        LoadByDocIdAndDocCode: true,
                        DocId: 16,
                        DocCode: "D1016",
                        LoadDocumentsWithoutBroadCast: true,
                        LinkType: 'NM',                       
                        PropertyToProcess: 'ProviderNameId'
                    };
                });
            },1000);
        };


        $scope.DownloadDocumentAPIURL = WebApiUrl + 'Individual/DownloadPDF/' + sessionStorage.Key + '?path=';
        $scope.DownloadDocument = function (path) {
            window.open($scope.DownloadDocumentAPIURL + path, '_blank');
        };

        $scope.setSaveDocumentRef = function (fn) {
            $scope.saveAllDocument = fn;
        };

        $scope.datePicker = {
            beginDateOpened: false,
            endDateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };

        // Save Name
        $scope.saveName = function (nameForm) {
            try {
                $scope.nameValidator = {};
                if (validateName(nameForm, $scope.nameValidator)) {
                    ShowLoader("Please wait...");
                    $scope.providerNameadd.ProviderId = $scope.ProviderId;
                    $scope.providerNameadd.IsActive = true;
                    $scope.providerNameadd.IsDeleted = false;
                    if (!$scope.providerNameadd.ProviderNameId) {
                        $scope.providerNameadd.DateofNameChange = new Date();
                        $scope.providerNameadd.ProviderNameTypeId = 1;
                    }
                    if (!$scope.providerNameadd.ReferenceNumber) {
                        $scope.providerNameadd.ReferenceNumber = new Date().valueOf();
                    }
                    var namesToSave = [];
                    //validate current name
                    if ($scope.NameArray && $scope.NameArray.length>0 && $scope.providerNameadd.ProviderNameStatusId == 3) {
                        //check if any name with current status
                        namesToSave = $.grep($scope.NameArray, function (i) {
                            return (i.ProviderNameStatusId == 3 && i.ProviderNameId != $scope.providerNameadd.ProviderNameId)
                        });
                        namesToSave = namesToSave.map(function (i) {
                            i.ProviderNameStatusId = 1;//previous
                            return i;
                        });

                    }
                    namesToSave.push($scope.providerNameadd);
                    UpdateProviderandPreviousName($scope.providerNameadd).then(function () {
                        backofficeProviderNameService.nameProviderSave(namesToSave, sessionStorage.Key)
                            .then(function (response) {
                                $scope.providerNameadd = {};
                                $scope.clearNote();
                                if (response.Status && response.ProviderNameList && response.ProviderNameList.length > 0) {
                                    $scope.documentUpload.LinkId = response.ProviderNameList[0].ProviderNameId;
                                    if (typeof $scope.saveAllDocument == "function") {
                                        $scope.saveAllDocument().then(function () {
                                            $scope.providerNameByProviderId($scope.ProviderId);
                                            $scope.$emit('businessNameChanged');
                                            HideLoader();
                                        }, function () { });
                                    } else {
                                        $scope.providerNameByProviderId($scope.ProviderId);
                                        HideLoader();
                                    }
                                } else {
                                    $scope.providerNameByProviderId($scope.ProviderId);
                                    HideLoader();
                                }
                            }, function (data) {
                                HideLoader();
                                if (data != null) { $scope.showStatusMessage(data.Message, "error"); }
                            });
                    }, function (err) {
                        if (err && err.Message) {
                            $scope.showStatusMessage(err.Message, "error");
                        }
                    });
                }

            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage(ex.message, "error");
                $exceptionHandler(ex);
            }
        };

        var validateName = function (nameForm , validator) {
            validator.errorMessages = [];
            if (!!nameForm) {
                nameForm.$submitted = true;
                var veteranErrorMessages = angular.copy(nameForm.errorMessages);
                angular.forEach(veteranErrorMessages, function (errorMessage, index) {
                    validator.errorMessages.push(errorMessage);
                });
            }

            if (!!validator.errorMessages && validator.errorMessages.length > 0) {
                validator.showError = true;
                validator.isValid = false;
                return false;
            } else {
                validator.showError = false;
                validator.isValid = true;
                return true;
            }
        };

        $scope.clearNote = function () {
            $scope.providerNameadd = {};
            $scope.showName = false;
        };

        $scope.addNewBtnClick = function () {
            $scope.showName = true
            $scope.providerNameadd = {};
            $scope.providerNameadd.ReferenceNumber = new Date().valueOf();
        }

        $scope.editProviderName = function (item) {
            $scope.showName = true;
            if (!item.ReferenceNumber) {
                item.ReferenceNumber = new Date().valueOf();
            }
            $scope.providerNameadd = angular.copy(item);
            initDocument();
        };

        $scope.deleteProviderName = function (item) {
            if (item && confirm(Messages.bbabace)) {
                ShowLoader("Please wait...");
                $scope.providerNameadd.ProviderId = $scope.ProviderId;
                item.IsActive = false;
                item.IsDeleted = true;
                backofficeProviderNameService.nameProviderSave([item], sessionStorage.Key)
                    .then(function (response) {
                        $scope.providerNameByProviderId($scope.ProviderId);
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            }
        };

        $scope.providerNameByProviderId = function (ProviderId) {
            try {
                ShowLoader("Please wait...");
                backofficeProviderNameService.nameByProviderId(sessionStorage.Key, ProviderId)
                    .then(function (response) {
                        $scope.NameArray = [];
                        if ($scope.checkServerResponse(response)) {
                            if (response.ProviderNameList != null) {
                                $scope.NameArray = response.ProviderNameList;
                                assignStatus();
                                processDocument();
                            }
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        $scope.showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        var assignStatus = function () {
            if ($scope.NameArray && $scope.providernameStatusList) {
                $scope.NameArray = $scope.NameArray.map(function (i) {
                    if(i.ProviderNameStatusId){
                         var tSt= $.grep($scope.providernameStatusList, function (itm) {
                             return (itm.ProviderNameStatusId == i.ProviderNameStatusId);
                         });
                         if (tSt.length > 0) {
                             i.StatusName = tSt[0].ProviderNameStatusName;
                         }
                    }
                    return i;
                });
            }
        };

        var processDocument = function () {
            if ($scope.NameArray && $scope.NameArray.length > 0) {
                ProviderMultipleFileUploadService.providerDocumentProcess(sessionStorage.Key, $scope.ProviderId, $scope.documentConfig.DocId,
                $scope.NameArray, $scope.documentConfig.PropertyToProcess, $scope.documentConfig.LinkType).then(function (responseData) {
                    $scope.NameArray = responseData;
                    console.log('data processed', responseData);
                });
            }
        };

        var getRefNameStatus = function () {
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=providernamestatus")).then(function (res) {
                if (res.Status) {
                    $scope.providernameStatusList = res.ReferenceEntities;
                    assignStatus();
                }
            });
        };

        var UpdateProviderandPreviousName = function (newName) {
            var defer = $q.defer();
            if (newName) {
                ShowLoader();
                BackofficeProviderService.GetProviderById(sessionStorage.Key, $scope.ProviderId).then(function (res) {
                    var providerData = res.data.ProviderResponse
                    //if name changed and name status is current then update provider
                    if (newName.ProviderNameStatusId == 3 && providerData.ProviderName != newName.ProviderName) {
                        var prevName = providerData.ProviderName;
                        providerData.ProviderName = newName.ProviderName;
                        BackofficeProviderService.ProviderSave(sessionStorage.Key, 0, sessionStorage.UserID, providerData).then(function (res) {
                            HideLoader();
                        }, function () {
                            HideLoader();
                        });
                        //save previous name
                        var prevnameData = {
                            ProviderId: $scope.ProviderId,
                            ProviderName: prevName,
                            ProviderNameStatusId: 1, //previous
                            //BeginDate : 
                            EndDate:new Date(),
                            IsActive: true,
                            IsDeleted: false,
                            DateofNameChange: new Date(),
                            ProviderNameTypeId: 1,
                            ReferenceNumber: new Date().valueOf()
                        }
                        backofficeProviderNameService.nameProviderSave([prevnameData], sessionStorage.Key).then(function (res) {
                            defer.resolve(res);
                        }, function (err) {
                            defer.reject(err);
                        });
                    } else {
                        defer.resolve({});
                    }
                }, function (err) {
                    defer.reject(err);
                });
            } else {
                defer.resolve({});
            }
            return defer.promise;
        };


    }
})();