(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintOnlineSubmissionLogic', ['$rootScope', '$timeout', 'ComplaintOnlineSubmissionService', 'ComplaintCorrespondenceService', '$q', ComplaintOnlineSubmissionLogic]);

    function ComplaintOnlineSubmissionLogic($rootScope, $timeout, ComplaintOnlineSubmissionService, ComplaintCorrespondenceService, $q) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                var isBackoffice = sessionStorage.isBackOfficeUser == true || sessionStorage.isBackOfficeUser == 'true';

                var getNewCorrespondence = function (complaintId, involvedParty, template, attachments = [], complaintNumber) {
                    return {
                        ComplaintCorrespondenceId: 0,
                        ComplaintId: complaintId,
                        ComplaintInvolvedPartyId: involvedParty.ComplaintInvolvedPartyId,
                        ConversationGuid: null,
                        Type: 'E',
                        EmailTo: involvedParty.Email,
                        EmailFrom: (!!$scope.serverConfig.EmailFrom) ? $scope.serverConfig.EmailFrom : $scope.uiConfig.AfterSubmitEmail.EmailFrom,
                        UserIdFrom: (!!sessionStorage.UserID) ? sessionStorage.UserID : 1,
                        Subject: (!!template) ? template.TemplateSubject : $scope.uiConfig.AfterSubmitEmail.Subject,
                        CommunicationSource: (!!isBackoffice) ? 'Backoffice' : 'Online',
                        CommunicationText: (!!template) ? template.TemplateMessage : 'Your complaint has been submitted.',
                        CommunicationStatus: 'P',
                        ReferenceNumber: complaintNumber,
                        TemplateId: (!!template) ? template.TemplateId : null,
                        NotifyTemplateId: null,
                        InternalSubject: 'Complaint Submission',
                        HasAttachments: true,
                        Attachments: attachments,
        
                        CorrespondenceMode: 'Email',
        
                        NotificationTemplateCode: null,
                    };
                };

                var getNewDocument = function (fileName, base64Contents) {
                    return {
                        ComplaintDocumentId: null,
                        ComplaintId: null,
                        ComplaintInvolvedPartyId: null,
                        EntityId: null,
                        EntityType: null,
                        ApplicationId: null,
                        DocumentLkToPageTabSectionId: 0,
                        DocumentLkToPageTabSectionCode: '',
                        DocumentTypeName: '',
                        EffectiveDate: new Date().toJSON(),
                        EndDate: null,
                        IsDocumentUploadedbyEntity: !$scope.isBackoffice,
                        IsDocumentUploadedbyStaff: $scope.isBackoffice,
                        ReferenceNumber: '',
                        IsActive: true,
                        IsDeleted: true,
                        ComplaintDocumentGuid: '',
                        DocumentPath: '',
                        DocumentId: null,
                        DocumentCd: null,
                        DocumentTypeId: null,
                        DocumentName: fileName,
                        OtherDocumentTypeName: '',
                        IsDocumentGeneratedBySystem: false,
                        DocumentUploadedForId: null,
                        LinkId: $scope.linkId,
                        LinkType: $scope.linkType,
                        IsPublic: false,
        
                        DocStrBase64: base64Contents
                    };
                };

                var getNewAffidavit = function (contentItem) {
                    return {
                        ComplaintAffidavitId: null,
                        ComplaintId: null,
                        ComplaintInvolvedPartyId: null,
                        ContentItemLkId: contentItem.ContentItemLkId,
                        ContentItemNum: contentItem.ContentItemHash,
                        ContentItemResponse: null,
                        Desc: contentItem.ContentItemLkDesc,
                        IsActive: true,
                        IsDeleted: false,

                        Name: '',
                        SignatureName: '',
                        Date: new Date()
                    };
                };

                $scope.getHtmlOfForm = function () {
                    var deferred = $q.defer();
                    
                    try {
                        ComplaintOnlineSubmissionService.getHtml('#complaintForm', [
                            'app/content/css/Header.css',
                            'app/core/directive/inlumon-table/inlumon-table-template.css',
                            'app/core/directive/utility-directives/utility.css',
                            'app/core/directive/multi-select/multi-select.css',
                            'app/core/directive/date-time-picker/date-time-picker.css',
                            'app/components/backoffice/enforcement/complaint-online-submission/complaint-online-submission.css'
                        ])
                        .then(function(data) {
                            console.log(data);
                            deferred.resolve(data);
                        }, function(err) {
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                $scope.convertHtmlToPdf = function (html) {
                    var deferred = $q.defer();
                    
                    try {
                        ComplaintOnlineSubmissionService.convertHtmlToPdf(html, 0.75, 0.75)
                        .then(function(data) {
                            deferred.resolve(data);
                        }, function(err) {
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                $scope.convertHtmlAndDownloadPdf = function (html, marginX, marginY) {
                    var deferred = $q.defer();
                    
                    try {
                        ComplaintOnlineSubmissionService.convertHtmlAndDownloadPdf(html, marginX, marginY)
                        .then(function(data) {
                            deferred.resolve(data);
                        }, function(err) {
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                $scope.downloadDocumentFromPublic = function (document) {
                    var deferred = $q.defer();
                    
                    try {
                        ComplaintOnlineSubmissionService.downloadDocumentFromPublic('public', document.ComplaintDocumentId, document.ComplaintDocumentGuid)
                        .then(function(data) {
                            if (!data.Status && !!data.StatusCode) {
                                deferred.reject(data);
                            } else {
                                deferred.resolve(data);
                            }
                        }, function(err) {
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                $scope.downloadDocumentClicked = function (document) {
                    $scope.hideStatusMsg();
                    
                    $rootScope.displayMainLoader(true, 'Starting Download...');

                    $scope.downloadDocumentFromPublic(document)
                    .then(function (data) {
                        $rootScope.displayMainLoader(false);
                    }, function (err) {
                        $scope.showStatusMsg('-', err);
                        $rootScope.displayMainLoader(false);
                    });
                };

                $scope.generateHtmlAndDownloadPdf = function () {
                    var deferred = $q.defer();
                    
                    try {
                        $scope.getHtmlOfForm()
                        .then(function (data) {
                            $scope.convertHtmlAndDownloadPdf(data, 0.75, 0.75)
                            .then(function (data) {
                                
                            }, function (err) {
                                deferred.reject(err);
                            });
                        }, function (err) { 
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                $scope.generateComplaintFormPdf = function (newComplaint) {
                    var deferred = $q.defer();
                    
                    try {
                        $timeout(function () {
                            $scope.getHtmlOfForm()
                            .then(function (data) {
                                $scope.convertHtmlToPdf(data)
                                .then(function (data) {
                                    var doc = getNewDocument($scope.uiConfig.ComplaintFormDocTypeInfo.FileName, data.PDFStrBase64);
                                    doc.ComplaintId = newComplaint.ComplaintId;
                                    doc.DocumentId = $scope.uiConfig.ComplaintFormDocTypeInfo.DocId;
                                    doc.DocumentCd = $scope.uiConfig.ComplaintFormDocTypeInfo.DocCode;
                                    doc.DocumentTypeName = $scope.uiConfig.ComplaintFormDocTypeInfo.DocumentTypeIdName;
                                    doc.DocumentTypeId = $scope.uiConfig.ComplaintFormDocTypeInfo.DocumentTypeId;
                                    doc.SendDocumentBase64Back = true;
    
                                    $scope.uploadDocuments([doc], false)
                                    .then(function (data) {
                                        if (!!data.Status && !!data.ComplaintDocumentList && !!data.ComplaintDocumentList[0]) {
                                            $scope.current.complaintFormPdf = data.ComplaintDocumentList[0];
                                        }
                                        deferred.resolve(data);
                                    }, function (err) {
                                        deferred.reject(err);
                                    });
                                }, function (err) {
                                    deferred.reject(err);
                                });
                            }, function (err) { 
                                deferred.reject(err);
                            });
                        }, 1);
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                $scope.uploadDocuments = function (documents, showUploadTracker) {
                    var deferred = $q.defer();
                    
                    try {
                        var uploadPromises = [];
                        var newUploadDocChunk = [];
                        var docChunks = [];

                        var sizeUploaded = 0;
                        var sizeUploadedMB = 0;
                        var totalSize = documents.sum(function (doc) { return doc.DocStrBase64.length; });
                        var totalSizeMB = totalSize / 1024 / 1024;

                        if (!!showUploadTracker) {
                            $scope.operationPending(true, 'Uploading documents, this may take some time...{0} MB of {1} MB Uploaded'.format(sizeUploadedMB, totalSizeMB.toFixed(2)));
                        }

                        //Iterate through the document list and make sure to break up the document uploads
                        //so that each individual request does not exceed a configured maximum size
                        documents.forEach(function (doc) {  
                            var fileSize = doc.DocStrBase64.length;
                            var testChunkSize = fileSize + newUploadDocChunk.sum(function (doc) { return doc.DocStrBase64.length; });

                            //Check to see if this file would put the size of the chunk beyond the configured maximum size
                            if (!!$scope.uiConfig.MaxDocChunkUploadSize && 
                                (testChunkSize >= $scope.uiConfig.MaxDocChunkUploadSize)) {
                                //Upload the existing chunk and create a new chunk for the current file to go into
                                docChunks.push(newUploadDocChunk);

                                newUploadDocChunk = [];
                            } 

                            newUploadDocChunk.push(doc);
                        });

                        //Upload the remaining chunk of files
                        if (newUploadDocChunk.length > 0) {
                            docChunks.push(newUploadDocChunk);

                            newUploadDocChunk = [];
                        }

                        //upload the chunks one at a time;
                        if (docChunks.length > 0) {
                            var i = 0;

                            var uploadDocChunk = function (docChunk) {
                                ComplaintOnlineSubmissionService.uploadDocuments(docChunk)
                                .then(function (data) {
                                    if (!data.Status){
                                        deferred.reject(data);
                                    } else {
                                        sizeUploaded += docChunk.sum(function (doc) { return doc.DocStrBase64.length; });

                                        sizeUploadedMB = sizeUploaded / 1024 / 1024;

                                        if (!!showUploadTracker) {
                                            $scope.operationPending(true, 'Uploading documents, this may take some time: {0} MB of {1} MB uploaded...'.format(sizeUploadedMB.toFixed(2), totalSizeMB.toFixed(2)));
                                        }
                                        
                                        i++;
                                        if (i < docChunks.length) {
                                            $timeout(function () {
                                                uploadDocChunk(docChunks[i]);
                                            }, 1);
                                        } else {
                                            deferred.resolve(data);
                                        }
                                    }
                                }, function (err) {
                                    deferred.reject(err);
                                });
                            };

                            uploadDocChunk(docChunks[i]);
                        }

                        // $q.all(uploadPromises)
                        // .then(function(data) {
                        //     if(data.any('!item.Status')){
                        //         deferred.reject(data);
                        //     }else{
                        //         deferred.resolve(data);
                        //     }
                        // }, function(err) {
                        //     deferred.reject(err);
                        // });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                $scope.saveAffidavit = function (affidavit) {
                    var deferred = $q.defer();
                    
                    try {
                        ComplaintOnlineSubmissionService.saveComplaintAffidavits([affidavit])
                        .then(function(data) {
                            deferred.resolve(data);
                        }, function(err) {
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                $scope.getConfirmationTemplate = function (id) {
                    var deferred = $q.defer();
                    
                    try {
                        ComplaintCorrespondenceService.getTemplate(sessionStorage.Key, id)
                        .then(function(data) {
                            deferred.resolve(data);
                        }, function(err) {
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                $scope.sendCorrespondence = function (correspondence) {
                    var deferred = $q.defer();
                    
                    try {
                        ComplaintCorrespondenceService.sendCorrespondencesFromPublic('public', [correspondence])
                        .then(function(data) {
                            deferred.resolve(data);
                        }, function(err) {
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                $scope.saveDataExt = function (newComplaint, involvedParties) {
                    var deferred = $q.defer();
                    
                    try {
                        var promises = [];

                        //Get the confirmation email template
                        promises.push($scope.getConfirmationTemplate(137));

                        //Track the previous complaint data temporarily
                        $scope.previous.complaint = newComplaint;

                        //Save Witnesses
                        $scope.current.witnesses.forEach(function (witness) {
                            witness.ComplaintId = newComplaint.ComplaintId;

                            promises.push(ComplaintOnlineSubmissionService.saveComplaintInvolvedParty(witness));
                        });

                        //Save Affidavit
                        var complainant = involvedParties.where('item.ComplaintInvolvedPartyTypeId==1').firstOrDefault();

                        if (!!$scope.current.affidavit) {
                            $scope.current.affidavit.ComplaintId = newComplaint.ComplaintId;
                            $scope.current.affidavit.ComplaintInvolvedPartyId = complainant.ComplaintInvolvedPartyId;

                            promises.push($scope.saveAffidavit($scope.current.affidavit));                
                        }
                        

                        //Save Documents
                        if ($scope.current.documentsList.length > 0) {
                            $scope.current.documentsList.forEach(function (doc) {
                                doc.ComplaintId = newComplaint.ComplaintId;
                            });
                            promises.push($scope.uploadDocuments($scope.current.documentsList, true));
                        }

                        $scope.$broadcast('SaveComplaintQuestionnaire', {complaint: newComplaint, involvedParty: complainant});
                        
                        //Generate the PDF of the complaint form and push it.
                        promises.push($scope.generateComplaintFormPdf(newComplaint));

                        //Wait for all to finish
                        $q.all(promises)
                        .then(function(data) {
                            $scope.operationPending(true, 'Loading...');

                            var template = data[0];
                            var complaintNumRX = new RegExp('{#ComplaintNumber#}', 'gmi');
 
                            if (!!template) {
                                template.TemplateMessage = template.TemplateMessage.replace(complaintNumRX, newComplaint.ComplaintNumber);
                            }
                            
                            if (!!complainant.Email) {
                                var newCorrespondence = getNewCorrespondence(newComplaint.ComplaintId, complainant, template, [$scope.current.complaintFormPdf], newComplaint.ComplaintNumber);
                                $scope.sendCorrespondence(newCorrespondence);
                            }

                            $scope.$broadcast('ResetComplaintQuestionnaire');
                            
                            deferred.resolve(data);
                        }, function(err) {
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                $scope.resetExt = function () {
                    $scope.current.documentsList = [];
                    $scope.current.witnesses = [];

                    if (!!$scope.affidavitContent && !!$scope.affidavitContent[0]) {
                        $scope.current.affidavit = getNewAffidavit($scope.affidavitContent[0]);
                    }
                };

                $scope.getServerConfig = function () {
                    var deferred = $q.defer();
                        
                    try {
                        var promises = [];
        
                        if (!$scope.uiConfig.ConfigurationSettings || $scope.uiConfig.ConfigurationSettings.length == 0) {
                            deferred.resolve({});
                            return deferred.promise;
                        }
        
                        $scope.uiConfig.ConfigurationSettings.forEach(function (setting) {
                            promises.push(ComplaintCorrespondenceService.getConfigurationBySetting((!sessionStorage.Key) ? 'public':sessionStorage.Key, setting.SettingName));
                        });
        
                        $q.all(promises)
                        .then(function(data) {
                            if (!!data && data.length > 0) {
                                data.forEach(function (response) {
                                    var setting = response.ConfigurationList.firstOrDefault();
                                    var propName = $scope.uiConfig.ConfigurationSettings.whereEquals(setting.Setting, 'SettingName').select('PropertyName').firstOrDefault();
        
                                    $scope.serverConfig[propName] = setting.Value;
                                });
                            }
        
                            deferred.resolve(data);
                        }, function(err) {
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                $scope.getConfigExt = function () {
                    var deferred = $q.defer();
                    
                    try {
                        $scope.getServerConfig()
                        .then(function(data) {
                            deferred.resolve(data);
                        }, function(err) {
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                $scope.initExt = function () {
                    $scope.previous = {};
                    $scope.serverConfig = {};

                    $scope.current.documentsList = [];
                    $scope.current.witnesses = [];
                };

                $scope.affidavitContentLoaded = function () {
                    try {
                        $scope.current.affidavit = getNewAffidavit($scope.affidavitContent[0]);  
                    } catch (ex) {
                        $scope.showStatusMsg('-', 'Unable to load some content, please refresh the page.');
                    }
                };

                $scope.licenseTypesLoaded = function (dataList, party) {
                    // dataList.conditionalSplice('item.LicenseTypeName=="Other"');

                    if (!!party) {
                        party.LicenseTypeName = null;
                    };

                    dataList.push({
                        LicenseTypeId: 0,
                        LicenseTypeName: 'Unlicensed Individual'
                    });
                };

                $scope.providerLicenseTypesLoaded = function (dataList) {
                    // dataList.conditionalSplice('item.LicenseTypeName=="Other"');
                    
                    dataList.push(
                    {
                        LicenseTypeId: 1,
                        LicenseTypeName: 'Licensed Firm'
                    },
                    {
                        LicenseTypeId: 0,
                        LicenseTypeName: 'Unlicensed Firm'
                    });
                };

                $scope.isUnlicensedSubject = function (subject) {
                    if (['Unlicensed Individual', 'Unlicensed Firm'].includes(subject.LicenseTypeName)) {
                        return true;
                    } else {
                        return false;
                    }
                };

                $scope.providerLicenseTypesLoaded = function (dataList) {
                    dataList.push({
                        ProviderLicenseTypeId: 0,
                        ProviderLicenseTypeName: 'Unlicensed Firm'
                    });
                };

                $scope.subjectEntityTypeChanged = function () {
                    if ($scope.current.subject.EntityType == 'P') {
                        $scope.current.subject.FirstName = '';
                        $scope.current.subject.MiddleName = '';
                        $scope.current.subject.LastName = '';
                    } else {
                        $scope.current.subject.ProviderName = '';
                    }
                };

                $scope.clearComplainant = function () {
                    var isAnon = $scope.current.complainant.IsAnonymous;
                    $scope.current.complainant = $scope.getNewInvolvedParty(1, 'I');

                    $scope.current.complainant.IsAnonymous = isAnon;
                    $scope.current.complainant.CountryId = 319;
                    $scope.current.complainant.holdsLicense = null;
                };

                $scope.onBeforeSaveInvolvedParty = function (ip) {
                    //If Complainant
                    if (ip.ComplaintInvolvedPartyTypeId == 1) {
                        //Set the source ID automatically to Anonymous if anonymous
                        if (ip.IsAnonymous) {
                            ip.ComplaintInvolvedPartySourceId = 1; //Anonymous
                        } else if (ip.IsInternalOnly) {
                            ip.ComplaintInvolvedPartySourceId = 7; //Internal
                        }
                    }
                };
                
                $scope.scrollTo = function (id) {
                    $timeout(function () {
                        $(id).scrollTo();
                    }, 1);
                };
            }
        };
    }
})();