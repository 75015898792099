(function () {
  'use strict'
  angular.module('app.core')
    .directive('bootstrapDatepicker', bootstrapDatepickerDirective);

  bootstrapDatepickerDirective.$inject = [];

  function bootstrapDatepickerDirective() {
    return {
      require: '?ngModel',
      restrict: 'A',
      scope: {
        model: '=ngModel'
      },
      link: function (scope, element, attrs, controller) {
        var updateModel;

        updateModel = function (ev) {
          element.datetimepicker('hide');
          element.blur();
          return scope.$apply(function () {
            var dateTimeValue = ev.date._d;
            var formattedDateTime = (dateTimeValue.getMonth() + 1) + "/" + dateTimeValue.getDate() + "/" + dateTimeValue.getFullYear();
            return controller.$setViewValue(formattedDateTime);
          });
        };
        if (controller != null) {
          controller.$render = function () {
            var options;
            options = {};
            var value = attrs.bootstrapDatepicker;
            if (angular.isObject(value)) {
              options = value;
            }
            if (typeof (value) === "string" && value.length > 0) {
              options = angular.fromJson(value);
            }
            element.datetimepicker(options).data().DateTimePicker.date = controller.$viewValue;
            element.datetimepicker('setValue');
            element.datetimepicker('update');
            return controller.$viewValue;
          };
        }
        scope.$watch('model', function (newValue, oldValue) {
          console.log('newValue', newValue);
          console.log('oldValue', oldValue);
          if (newValue == null && oldValue != null) {
            var options;
            options = {};
            var value = attrs.bootstrapDatepicker;
            if (angular.isObject(value)) {
              options = value;
            }
            if (typeof (value) === "string" && value.length > 0) {
              options = angular.fromJson(value);
            }
            element.datetimepicker(options).data().DateTimePicker.clear();
          }
        }, true);
        return attrs.$observe('bootstrapDatepicker', function (value) {
          var options;
          options = {};
          if (angular.isObject(value)) {
            options = value;
          }
          if (typeof (value) === "string" && value.length > 0) {
            options = angular.fromJson(value);
          }
          return element.datetimepicker(options).on('dp.change', updateModel);
        });
      }
    };
  }

})();