(function () {
    'use strict';

    angular
        .module('app.provider')
        .controller("CeProviderSignInController", CeProviderSignInController);

    CeProviderSignInController.$inject = ["$rootScope", "$scope", "$state", "bppService","ProviderMultipleFileUploadService",
        "countryStateService", "typeValuesService", "lookupService", "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl", "$q", "Messages"
    ];

    function CeProviderSignInController($rootScope, $scope, $state, bppService, ProviderMultipleFileUploadService,
        countryStateService, typeValuesService, lookupService, utilityService, $exceptionHandler, TypeValue, WebApiUrl, $q, Messages) {
        $scope.documentsList = [];
        $scope.signInDocumentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
            // ApplicationId: $scope.applicationId,
            ProviderId: $scope.providerId,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "CE",
            LoadByDocIdAndDocCode: true,
            DocId: 2,
            DocCode: "D1002",
            LoadDocumentsWithoutBroadCast: false,
            LinkType: 'SU',
            PropertyToProcess: 'ProviderCourseId',
            IsLinkedDocControl: true
        };

        $scope.datePicker = {
            beginDateOpened: false,
            endDateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            },
            startDateOptions: {
                maxDate: null
            },
            endDateOptions: {
                minDate: null
            }
        };

        var init = function () {
            $scope.viewConfig = {};
            ShowLoader();
            var dataSavePromises = [bppService.providerCECourseByProviderId($scope.providerId, "", sessionStorage.Key),
            $scope.providerCourseUploadByProviderId($scope.providerId, true, true)
            ];
            $q.all(dataSavePromises).then(function (response) {
                if (response[0].Status) {
                    $scope.providerCECourses = response[0].ProviderCourseResponseList;
                } else {
                    $scope.showStatusMessage(response[0].Message, "error");
                }
                if (response[1].Status) {
                    $scope.providerSignInList = processCourses(response[1].ProviderCourseUploadList);
                    processListData();
                } else {
                    $scope.showStatusMessage(response[1].Message, "error");
                }
            }, function (error) {
                $scope.showStatusMessage(error, "error");
            });
            HideLoader();
        };

        $scope.setSaveDocumentRef1 = function (fn) {
            $scope.saveAllDocumentRef1 = fn;
        };
        $scope.downloadDocumentAPIURL = WebApiUrl + 'Individual/DownloadPDF/' + sessionStorage.Key + '?path=';
        $scope.downloadDocument = function (path) {
            window.open($scope.downloadDocumentAPIURL + path, '_blank');
        };
        var processCourses = function (providerCourseUploadList) {
            angular.forEach(providerCourseUploadList, function (providerCourseUpload) {
                angular.forEach($scope.providerCECourses, function (providerCECourse) {
                    if (providerCourseUpload.ProviderCourseId == providerCECourse.ProviderCourseId) {
                        providerCourseUpload.CourseName = providerCECourse.CourseName;
                    }
                });
            });
            return providerCourseUploadList;
        };

        $scope.openAddEditAssignment = function (isAssignmentEdit, providerSignIn) {
            $scope.isAssignmentEdit = isAssignmentEdit;
            if (!$scope.isAssignmentEdit) {
                $scope.providerSignIn = {
                    ApplicationId: $scope.applicationId,
                    ProviderId: $scope.providerId,
                    IsActive: true
                }
            } else {
                $scope.providerSignIn = angular.copy(providerSignIn);
            }
            $scope.viewConfig.showAddBlock = true;
        };

        $scope.closeAddEditAssignment = function () {
            $scope.viewConfig.showAddBlock = false;
        };
        var processListData = function () {
            if ($scope.providerSignInList && $scope.providerSignInList.length > 0) {
                ProviderMultipleFileUploadService.providerDocumentProcess(sessionStorage.Key, $scope.providerId, $scope.signInDocumentUpload.DocId,
                    $scope.providerSignInList, $scope.signInDocumentUpload.PropertyToProcess, $scope.signInDocumentUpload.LinkType).then(function (responseData) {
                        $scope.providerSignInList = responseData;
                        console.log('data processed', responseData);
                    });
            }
        };
        $scope.providerCourseUploadByProviderId = function (providerId, hideMessages, isInitLoad) {
            if (!isInitLoad)
                ShowLoader("Please Wait...");
            var dataSavePromise = bppService.getProviderCourseUpload(sessionStorage.Key, providerId, $scope.applicationId);
            dataSavePromise.then(function (response) {
                if (hideMessages) {
                    $scope.hideStatusMessage();
                    $scope.hideValidationSummary();
                }
                HideLoader();
                
                if (!isInitLoad) {
                    if ($scope.checkServerResponse({
                        response: response
                    })) {
                        $scope.providerSignInList = processCourses(response.ProviderCourseUploadList);
                        processListData();
                    }
                }
                
            }, function (error) {
                $scope.showStatusMessage(error, "error");
            });
            return dataSavePromise;
        };

        // Save Assignment
        $scope.saveAssignment = function (providerSignInForm) {
            $scope.hideStatusMessage();
            $scope.hideValidationSummary();
            providerSignInForm.$submitted = true;
            if ($scope.validateData({
                providerSignInForm: providerSignInForm
            })) {
                ShowLoader("Please wait...");
                $scope.saveAssignmentRecord(false);
            }

        };

        $scope.saveAssignmentRecord = function (isDeleted) {
            bppService.saveProviderCourseUpload(sessionStorage.Key, [$scope.providerSignIn])
                .then(function (response) {
                    if ($scope.checkServerResponse(({
                        response: response
                    }))) {
                        if (response.ProviderCourseUploadList && response.ProviderCourseUploadList.length > 0) {
                            $scope.signInDocumentUpload.LinkId = response.ProviderCourseUploadList[0].ProviderCourseId;
                            if (typeof $scope.saveAllDocumentRef1 == "function") {
                                $q.all([$scope.saveAllDocumentRef1()])
                                    .then(function (responseDoc) {
                                        $scope.providerCourseUploadByProviderId($scope.providerId);
                                        $scope.closeAddEditAssignment();
                                        $scope.showStatusMessage({
                                            res: response.Message,
                                            msg: "success"
                                        });
                                    });
                            } else {
                                $scope.providerCourseUploadByProviderId($scope.providerId);
                                $scope.closeAddEditAssignment();
                                $scope.showStatusMessage({
                                    res: response.Message,
                                    msg: "success"
                                });
                            }
                        } else {
                            $scope.providerCourseUploadByProviderId($scope.providerId);
                            $scope.closeAddEditAssignment();
                            $scope.showStatusMessage({
                                res: response.Message,
                                msg: "success"
                            });
                        }

                    }
                    HideLoader();
                }, function (error) {
                    HideLoader();
                    $scope.showStatusMessage(error, "error");
                });
        };

        $scope.deleteAssignment = function (individualAssignment) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                ShowLoader("Please Wait...");
                $scope.individualAssignment = angular.copy(individualAssignment);
                $scope.individualAssignment.IsDeleted = true;
                $scope.saveAssignmentRecord(true);
            }
        };

        function AuditVisitInfoSave() {
            var reqData = {
                "PageName": "IndividualInfo-AssignmentTab",
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(reqData);
        }
        init();
    }
})();