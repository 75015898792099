(function () {
    'use strict'

    angular.module('app.core')
        .filter('htmlToPlaintext', function () {
            return function (text) {
                return text ? String(text).replace(/<[^>]+>/gm, '') : '';
            };
        })
        .filter('trustedHtml', ['$sce', function ($sce) {
            return function (text) {
                return $sce.trustAsHtml(text);
            };
        }])
        .filter('filterLog', ['$filter', function ($filter) {
            return function (list, searchText, props, dateFormat) {
                var newList = [];
                if (!!searchText && !!searchText.trim()) {
                    searchText = searchText.toHtmlSearch();

                    searchText = searchText.replace(/\(/g, '\\(').replace(/\)/g, '\\)');

                    var searchRegex = new RegExp(searchText, 'gmi');

                    list.forEach(function (item) {
                        try {
                            props.forEach(function (prop) {

                                if (typeof (prop) == 'string') {
                                    if (!item[prop]) { return; }

                                    var val = item[prop];
                                    var valType = typeof (val);

                                    if (Date.isDate(val)) {
                                        var strDate = $filter('date')(val, dateFormat);
                                        if (!!strDate.toLowerCase().match(searchRegex)) {
                                            newList.push(item);
                                            throw 'Done';
                                        }
                                    } else if (!!val && valType == 'string') {
                                        if (!!val.toLowerCase().match(searchRegex)) {
                                            newList.push(item);
                                            throw 'Done';
                                        }
                                    }
                                } else if (typeof (prop) == 'function') {
                                    if (prop(item, searchText, searchRegex)) {
                                        newList.push(item);
                                        throw 'Done';
                                    }
                                }
                            });
                        } catch (ex) {
                            //Done
                        }
                    });
                } else {
                    newList = list;
                }

                return newList;
            };
        }])
        .directive('autoFocus', ['$timeout', function ($timeout) {
            return {
                link: function (scope, element, attrs) {
                    attrs.$observe("autoFocus", function (newValue) {
                        if (newValue === "true")
                            $timeout(function () {
                                element.focus()
                            });
                    });
                }
            };
        }])
        .directive('stringToNumber', [function () {
            return {
                require: 'ngModel',
                link: function (scope, element, attrs, ngModel) {
                    ngModel.$parsers.push(function (value) {
                        if (value == 'null') {
                            return null;
                        }
                        return '' + value;
                    });
                    ngModel.$formatters.push(function (value) {
                        return parseFloat(value);
                    });
                }
            };
        }])
        .directive('dateToString', ['$filter', function ($filter) {
            return {
                retrict: 'A',
                require: 'ngModel',
                link: function (scope, element, attrs, ngModel) {
                    ngModel.$parsers.push(function (value) {
                        if (!value) {
                            //return moment(new Date()).format('MM/DD/YYYY');
                            return '';
                        }
                        return moment(value).format('MM/DD/YYYY');
                    });
                    ngModel.$formatters.push(function (value) {
                        if (!value) {
                            //return new Date();
                            return null;
                        }
                        return value.getDateObj(); //moment(value, 'MM/DD/YYYY').toDate();
                    });
                }
            };
        }])
        .directive('dateToJson', ['$filter', function ($filter) {
            return {
                retrict: 'A',
                require: 'ngModel',
                link: function (scope, element, attrs, ngModel) {
                    ngModel.$parsers.push(function (value) {
                        if (!value) {
                            //return moment(new Date()).format('MM/DD/YYYY');
                            return '';
                        }
                        return value.getDateObj().toJSON();
                    });
                    ngModel.$formatters.push(function (value) {
                        if (!value) {
                            //return new Date();
                            return null;
                        }
                        return value.getDateObj(); //moment(value, 'MM/DD/YYYY').toDate();
                    });
                }
            };
        }])
        .directive('stringToBoolean', [function () {
            return {
                retrict: 'A',
                require: 'ngModel',
                link: function (scope, element, attrs, ngModel) {
                    ngModel.$parsers.push(function (value) {
                        //to boolean
                        if (typeof (value) == 'string') {
                            if (value.toLowerCase() == 'true') {
                                return true;
                            } else { //} if (value.toLowerCase() == 'false') {
                                return false;
                            }
                        }
                        return value;
                    });
                    ngModel.$formatters.push(function (value) {
                        //to boolean
                        if (typeof (value) == 'string') {
                            if (value.toLowerCase() == 'true') {
                                return true;
                            } else { //} if (value.toLowerCase() == 'false') {
                                return false;
                            }
                        }
                        return value;
                    });
                }
            };
        }])
        .directive('clickAndDragDroppable', ['$document', '$window', function ($document, $window) {
            return {
                restrict: 'A',
                link: function ($scope, element, attrs, ctrl) {
                    ctrl.element = element;
                    ctrl.element.attrs = attrs;
                },
                controller: ['$scope', function ($scope) {
                    var self = this;

                    this.enableDroppable = function () {
                        self.element.addClass('droppable');
                    };

                    this.dropped = function () {
                        self.element.removeClass('droppable');
                    };
                }]
            }
        }])
        .directive('clickAndDrag', ['$document', '$window', function ($document, $window) {
            return {
                restrict: 'A',
                require: ['clickAndDrag', '^?clickAndDragDroppable'],
                link: function ($scope, element, attrs, controllers) {
                    var ctrl = controllers[0],
                        clickAndDragDroppable = controllers[1];
                    var bounds = [];
                    if (!!attrs.clickAndDrag) {
                        bounds = attrs.clickAndDrag.split(',').select(function (b) {
                            return parseFloat(b);
                        });
                    }

                    ctrl.bounds = bounds;
                    ctrl.element = element;
                    ctrl.element.attrs = attrs;

                    ctrl.minX = (bounds[0]) ? bounds[0] : 0;
                    ctrl.minY = (bounds[1]) ? bounds[1] : 0;
                    ctrl.maxX = (bounds[2]) ? bounds[2] : 0;
                    ctrl.maxY = (bounds[3]) ? bounds[3] : 0;

                    if (!!angular.isUndefined(attrs.ignoreInitialStyle)) {
                        element.css({
                            position: 'fixed',
                            border: '1px solid red',
                            backgroundColor: 'lightgrey'
                        });
                    }
                    if (!!attrs.initialClass) {
                        element.addClass(attrs.initialClass);
                    }

                    ctrl.enableDroppable = function () {
                        if (!!clickAndDragDroppable) {
                            clickAndDragDroppable.enableDroppable();
                        }
                    };


                    function isOverlapping(e1, e2) {
                        if (e1.length && e1.length > 0) {
                            e1 = e1[0];
                        }
                        if (e2.length && e2.length > 0) {
                            e2 = e2[0];
                        }
                        var rect1 = e1 ? e1.getBoundingClientRect() : false;
                        var rect2 = e2 ? e2.getBoundingClientRect() : false;

                        var overlap = null;
                        if (rect1 && rect2) {
                            overlap = !(
                                rect1.right < rect2.left ||
                                rect1.left > rect2.right ||
                                rect1.bottom < rect2.top ||
                                rect1.top > rect2.bottom
                            )
                            return overlap;
                        } else {
                            return overlap;
                        }
                    }

                    ctrl.highlightDragger = function () {
                        if (!!clickAndDragDroppable) {
                            if (!!isOverlapping(element, clickAndDragDroppable.element)) {
                                element.addClass('highlight-dragger');
                            } else {
                                element.removeClass('highlight-dragger');
                            }
                        }
                    };

                    ctrl.dropped = function () {
                        if (!!clickAndDragDroppable) {
                            if (!!isOverlapping(element, clickAndDragDroppable.element)) {
                                element.removeClass(element.attrs.onDragClass);
                                ctrl.isInitialStyleApplied = false;
                                ctrl.initializedPosition = false;
                                element.css({
                                    width: 'auto',
                                    height: 'auto'
                                })
                                element.removeClass('draggable-unplugged');
                                element.addClass('draggable-plugged');
                                element.removeClass(element.attrs.onUnplugClass);
                                element.addClass(element.attrs.onPlugClass);
                            } else {
                                element.addClass('draggable-unplugged');
                                element.removeClass('draggable-plugged');
                                element.addClass(element.attrs.onUnplugClass);
                                element.removeClass(element.attrs.onPlugClass);
                            }
                            clickAndDragDroppable.dropped();
                        }
                        element.removeClass('highlight-dragger');
                    };
                },
                controller: ['$scope', function ($scope) {
                    var self = this;

                    this.moveElementTo = function (x, y) {
                        var minX = self.minX;
                        var minY = self.minY;
                        var maxX = (self.maxX > 0) ? self.maxX : $window.innerWidth - self.element[0].offsetWidth;
                        var maxY = (self.maxY > 0) ? self.maxY : $window.innerHeight - self.element[0].offsetHeight;

                        if (x >= maxX) {
                            self.element.css({
                                left: maxX + 'px'
                            });
                        } else if (x <= minX) {
                            self.element.css({
                                left: minX + 'px'
                            });
                        } else {
                            self.element.css({
                                left: x + 'px'
                            });
                        }

                        if (y >= maxY) {
                            self.element.css({
                                top: maxY + 'px'
                            });
                        } else if (y < minY) {
                            self.element.css({
                                top: minY + 'px'
                            });
                        } else {
                            self.element.css({
                                top: y + 'px'
                            });
                        }

                        self.element.css({
                            right: '',
                            bottom: ''
                        });
                    };
                    this.setElementStyle = function () {
                        if (!this.isInitialStyleApplied) {
                            if (!angular.isUndefined(self.element.attrs.preserveWidth)) {
                                self.element.css({
                                    width: (self.element.width() + 'px')
                                })
                            }
                            if (self.element.attrs.onDragStyle) {
                                self.element.css(JSON.parse(self.element.attrs.onDragStyle));
                            }
                            if (self.element.attrs.onDragClass) {
                                self.element.addClass(self.element.attrs.onDragClass);
                            }

                            this.isInitialStyleApplied = true;
                        }
                    };
                }]
            }
        }])
        .directive('clickAndDragFocus', ['$document', function ($document) {
            return {
                restrict: 'A',
                require: '^clickAndDrag',
                link: function (scope, element, attrs, clickAndDrag) {
                    var startX = null;
                    var startY = null;
                    var x = null;
                    var y = null;

                    element.on('mousedown', function (event) {
                        if (!clickAndDrag.initializedPosition) {
                            var elementPosition = clickAndDrag.element[0].style.position;
                            var elementViewportOffset = clickAndDrag.element[0].getBoundingClientRect();
                            x = ((!!elementPosition && elementPosition == 'fixed') ? clickAndDrag.element[0].offsetLeft : elementViewportOffset.left);
                            y = ((!!elementPosition && elementPosition == 'fixed') ? clickAndDrag.element[0].offsetTop : elementViewportOffset.top);
                            clickAndDrag.initializedPosition = true;
                        }

                        // Prevent default dragging of selected content
                        event.preventDefault();
                        event.stopPropagation();

                        startX = event.pageX - x;
                        startY = event.pageY - y;
                        $document.on('mousemove', mousemove);
                        $document.on('mouseup', mouseup);
                        clickAndDrag.enableDroppable();
                    });

                    function mousemove(event) {
                        y = event.pageY - startY;
                        x = event.pageX - startX;
                        clickAndDrag.highlightDragger();
                        clickAndDrag.moveElementTo(x, y);
                        clickAndDrag.setElementStyle();
                    }

                    function mouseup() {
                        clickAndDrag.dropped();
                        $document.off('mousemove', mousemove);
                        $document.off('mouseup', mouseup);
                    }
                }
            }
        }])
        .directive('resize', ['$document', function ($document) {
            return {
                restrict: 'A',
                link: function (scope, element, attr) {
                    var dimension = {};
                    var iconPosition = [0, 0];
                    var modes = ['all', 'horizontal', 'vertical'];
                    var mode = attr.resize && modes.indexOf(attr.resize) > -1 ? attr.resize : 'all';
                    var position = {};
                    element.css({
                        position: 'reltive'
                    });
                    //create button for resizing
                    var btn = document.createElement("span");
                    btn.style.width = '15px';
                    btn.style.height = '15px';
                    btn.innerHTML = "<svg>\
                    <circle cx='12.5' cy='2.5' r='2' fill='#777777'></circle>\
                    <circle cx='7.5' cy='7.5' r='2' fill='#777777'></circle>\
                    <circle cx='12.5' cy='7.5' r='2' fill='#424242'></circle>\
                    <circle cx='2.5' cy='12.5' r='2' fill='#777777'></circle>\
                    <circle cx='7.5' cy='12.5' r='2' fill='#424242'></circle>\
                    <circle cx='12.5' cy='12.5' r='2' fill='#212121'></circle></svg>";
                    btn.style.bottom = iconPosition[0] + 'px';
                    btn.style.right = iconPosition[1] + 'px';
                    btn.style.position = 'absolute';
                    btn.style.visibility = 'hidden';
                    if (mode == 'horizontal') {
                        btn.style.cursor = 'ew-resize';
                    } else if (mode == 'vertical') {
                        btn.style.cursor = 'ns-resize';
                    } else {
                        btn.style.cursor = 'nwse-resize';
                    }
                    //bind resize function to button;
                    btn.onmousedown = function ($event) {
                        $event.stopImmediatePropagation();
                        position.x = $event.clientX;
                        position.y = $event.clientY;
                        dimension.width = element.prop('offsetWidth');
                        dimension.height = element.prop('offsetHeight');
                        $document.bind('mousemove', mousemove);
                        $document.bind('mouseup', mouseup);
                        return false;
                    };

                    function mousemove($event) {
                        var deltaWidth = dimension.width - (position.x - $event.clientX);
                        var deltaHeight = dimension.height - (position.y - $event.clientY);
                        var newDimensions = {};
                        if (mode == 'horizontal') {
                            newDimensions = {
                                width: deltaWidth + 'px'
                            };
                        } else if (mode == 'vertical') {
                            newDimensions = {
                                height: deltaHeight + 'px'
                            };
                        } else {
                            newDimensions = {
                                width: deltaWidth + 'px',
                                height: deltaHeight + 'px'
                            };
                        }
                        element.css(newDimensions);
                        return false;
                    }

                    function mouseup() {
                        $document.unbind('mousemove', mousemove);
                        $document.unbind('mouseup', mouseup);
                    }

                    element.append(btn);
                    //show button on hover
                    element.bind('mouseover', function () {
                        btn.style.visibility = 'visible';
                    });
                    element.bind('mouseout', function () {
                        btn.style.visibility = 'hidden';
                    });
                }
            };
        }])
        .directive('collapsePanel', [function () {
            return {
                restrict: 'E',
                transclude: true,
                scope: {
                    headerText: "=?",
                    defaultIsCollapsed: '=?',
                    isCollapsed: "=?",
                    headerClasses: "=?",
                    bodyClasses: "=?",
                    bg: "=?", //possible-options - primary,success,info,warning,highlight,lightblue,blue 
                    bgExpanded: "=?", //possible-options - primary,success,info,warning,highlight,lightblue,blue 
                    headerTemplate: "=?",
                    hidePanelHeader: "=",
                    enableDragDrop: "=?"
                },
                link: function ($scope, element, attrs) {
                    $scope.isCollapsed = (angular.isUndefined($scope.defaultIsCollapsed) ? true : $scope.defaultIsCollapsed);
                    $scope.bodyClasses = ((!$scope.hidePanelHeader ? 'rounded-panel-bottom ' : '') + (!$scope.bodyClasses ? '' : $scope.bodyClasses));
                    $scope.bgClass = (angular.isUndefined($scope.bg) ? 'bg-highlight' : 'bg-' + $scope.bg);
                    $scope.bgExpandedClass = (angular.isUndefined($scope.bgExpanded) ? $scope.bgClass : 'bg-' + $scope.bgExpanded);
                    $scope.bgCurrentClass = (!!$scope.isCollapsed ? $scope.bgClass : $scope.bgExpandedClass)
                    $scope.panelLoaded = true;

                    $scope.toggleCollapse = function (isCollapsed, $event) {
                        $scope.isCollapsed = !isCollapsed;
                        $scope.bgCurrentClass = (!!$scope.isCollapsed ? $scope.bgClass : $scope.bgExpandedClass)
                        $event.stopPropagation();
                        $event.preventDefault();
                    };

                    $scope.draggerClicked = function ($event) {
                        $event.stopPropagation();
                        $event.preventDefault();
                    };
                },
                template: '<div class="inl-collapse-panel" click-and-drag ignore-initial-style initial-class="bg-white"\
                                    on-drag-class=\'draggable\' on-unplug-class="tabs-unplugged"\
                                    on-plug-class="tabs-plugged" preserve-width ng-if="(!!panelLoaded)">\
                                <div class="collapse-panel-header" ng-class="bgCurrentClass" ng-if="(!hidePanelHeader)">\
                                    <div class="collapse-panel-header-block text-center" ng-click="toggleCollapse(isCollapsed,$event);">\
                                        <button class="btn btn-sm btn-default dragger-handler pull-left"\
                                            ng-if="(enableDragDrop)" click-and-drag-focus ng-click="draggerClicked($event)">\
                                            <span class="glyphicon glyphicon-align-justify"></span>\
                                        </button>    \
                                        <ng-bind-html ng-bind-html="headerText"></ng-bind-html>\
                                        <ng-include src="headerTemplate" ng-if="(headerTemplate)"></ng-include>\
                                        <a class="pull-right expand-icon" ng-show="!!isCollapsed" ng-click="toggleCollapse(isCollapsed,$event);">\
                                            <i class="fa fa-plus"></i>\
                                        </a>\
                                        <a class="pull-right collapse-icon" ng-show="!isCollapsed" ng-click="toggleCollapse(isCollapsed,$event);">\
                                            <i class="fa fa-minus"></i>\
                                        </a>\
                                    </div>\
                                </div>\
                                <div ng-class="bodyClasses" uib-collapse="(!hidePanelHeader && !!isCollapsed)">\
                                    <ng-transclude></ng-transclude>\
                                </div>\
                            </div>'
            };
        }]);
})();
