﻿(function () {
    'use strict'
    angular.module('natApp')
      .service('ResponsibleInChargeService', ResponsibleInChargeService);

    ResponsibleInChargeService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig'];

    function ResponsibleInChargeService($http, $q, WebApiUrl, AppConfig) {
        var baseFactory = {};
        baseFactory.individualSearchWithPage = function (individualSearchContent, key, PageNumber, NoOfRecords) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualSearchWithPage/' + key + '?PageNumber=' + PageNumber + '&NoOfRecords=' + NoOfRecords, individualSearchContent));
        };

        baseFactory.SaveProviderIndividual = function (key, data) {
            return returnDefferedResult($http.post(WebApiUrl + 'Provider/SaveProviderIndividual/' + key + '?Source=' + AppConfig.Source, data));
        };
        baseFactory.GetProviderIndividual = function (key, indvId, provId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Provider/GetProviderIndividual/' + key + '?IndividualId=' + indvId + '&ProviderId=' + provId + '&Source=' + AppConfig.Source));
        };

        baseFactory.IndividualByIndividualID = function (key, indv) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualBYIndividualId/' + key + '?IndividualId=' + indv + '&Source=' + AppConfig.Source));
        };
        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        return baseFactory;
    };
})();