(function () {
    'use strict';

    angular
        .module('app.provider')
        .controller("AddDateToApprovedCoursesController", AddDateToApprovedCoursesController);

    AddDateToApprovedCoursesController.$inject = ["$rootScope", "$scope", "$state", "bppService", "RegisterFactory",
        "countryStateService", "initialApplicationstatusService", "typeValuesService", "lookupService", "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl", "$q"
    ];

    function AddDateToApprovedCoursesController($rootScope, $scope, $state, bppService, RegisterFactory,
        countryStateService, initialApplicationstatusService, typeValuesService, lookupService, utilityService, $exceptionHandler, TypeValue, WebApiUrl, $q) {
        $scope.providerId = sessionStorage.ProviderId;
        var userId = sessionStorage.UserId;
        $scope.viewConfig = {};
        $scope.viewConfig.approvedCourses = true;

        var init = function () {
            $scope.providerCECourseByProviderId($scope.providerId);
        }
        $scope.providerCECourseByProviderId = function (providerId, hideMessages, isInitLoad) {
            if (!isInitLoad)
                ShowLoader("Please Wait...");
            var dataSavePromise = bppService.providerCECourseByProviderId(providerId, "", sessionStorage.Key);
            dataSavePromise.then(function (response) {

                $scope.providerCECourses = response.ProviderCourseResponseList;
                if ($scope.providerCECourses)
                    $scope.totalCourses = $scope.providerCECourses.length;
                // if (hideMessages) {
                //     $scope.hideStatusMessage();
                //     $scope.hideValidationSummary();
                // }
                // if (!isInitLoad) {
                HideLoader();
                //     if ($scope.checkServerResponse({ response: response })) {
                //         //$scope.providerCECourseList = processAssignmentDescValue(response.ProviderAssignmentList);
                //     }
                // }
            }, function (error) {
                $scope.showStatusMessage(error, "error");
            });
            return dataSavePromise;
        };

        $scope.openAddEditCeCourse = function (providerCECourse) {
            providerCECourse.isdisplay = true;
        }

        init();
    }

})();