(function () {
  'use strict'

  angular.module('app.InitialApplication')
    .factory('instructionsService', instructionsService);

    instructionsService.$inject = ['$http', '$q', 'WebApiUrl'];

  function instructionsService($http, $q, WebApiUrl) {
    return {

    };
  }
})();