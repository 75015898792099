(function () {
    'use strict';

    angular
        .module('app.backofficeEntity')
        .controller("CeCourseController", CeCourseController);

    CeCourseController.$inject = ["$rootScope", "$scope", "$q", "backofficeCeCourseService", "backofficeIndividualLicenseService", "typeValuesService", "utilityService", "TypeValue", "$timeout", "Messages", "WebApiUrl", "multipleFileUploadService"];

    function CeCourseController($rootScope, $scope, $q, backofficeCeCourseService, backofficeIndividualLicenseService, typeValuesService, utilityService, TypeValue, $timeout, Messages, WebApiUrl, multipleFileUploadService) {
        // $scope.excelErrorArray = [];
        //Tab Initilizer
        $scope.$on("entityTabChanged", function (evt, entityId) {
            if ($rootScope.entityCurrentTab.name == "ceCourse") {
                $scope.entityId = entityId;
                $scope.onNgInit();
            }
        });

        $scope.$on("changeCourseStatus", function (evt, args) {
            var courseStatusId = args.courseStatusId;
            var applicationId = args.applicationId;
            angular.forEach($scope.ceCourses, function (ceCourseItem) {
                ceCourseItem.CECourseStatusId = courseStatusId;
                ceCourseItem.ApplicationId = applicationId;
                $scope.saveCeCourseRecord(ceCourseItem);
            });
        });

        $rootScope.ceCourseTabData = {
            firstYrCeHours: 0,
            secondYrCeHours: 0,
            totalCeHours: 0,
            ceCourseTypes: [],
            ceCourseTypesOrg: []
        };

        $scope.documentByIndividualDocumentId = multipleFileUploadService.documentByIndividualDocumentId;

        $scope.viewConfig = {
            showAddBlock: false,
            isCeCourseEdit: false,
            showDetails: true
        };

        $scope.paginationParams = {
            pageSize: 20,
            showpagingHeader: true
        };

        $scope.documentCECourse = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.entityId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "R",
            LoadByDocIdAndDocCode: true,
            DocId: 53,
            DocCode: "D1053",
            LoadDocumentsWithoutBroadCast: false,
            LinkType: 'CC',
            PropertyToProcess: 'IndividualCECourseId',
            IsLinkedDocControl: true
        };

        $scope.validateForm = function (currentForm) {
            $scope.updateMinMaxStep($scope.ceCourse.CourseCategoryId);
            var formData = {
                errorMessages: angular.copy(currentForm.errorMessages)
            };
            var regex = $scope.regexExp;
            var regexMsg = $scope.regexExpMsg;
            var matches = regex.exec($scope.ceCourse.CECourseHours);
            if (matches == null) {
                var errorObj = {
                    propName: "CEHoursInvalid",
                    errorMessage: regexMsg
                };
                formData.errorMessages.push(errorObj);
            }
            if (!(!!formData.errorMessages && formData.errorMessages.length > 0)) {
                return true;
            } else {
                var errorMessages = [];
                angular.forEach(formData.errorMessages, function (obj) {
                    errorMessages.push(obj.errorMessage);
                })
                $scope.showStatusMsg('-', errorMessages);
                return false;
            }
        };

        $scope.clearValidation = function () {
            $scope.hideStatusMsg();
        };

        $scope.dynamicErrorMsg = "";
        $scope.showFirstSecondYearSeletion = true;

        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/d!/yyyy',
            startDateOptions: {
                minDate: null,
                maxDate: null,
            },
            endDateOptions: {
                minDate: null
            }
        };
        // $scope.datePicker.endDateOptions.minDate = new Date();

        var getUIConfig = function () {
            var configToUse = $scope.config.UIConfigs[$scope.configName];
            if (!configToUse) {
                configToUse = $scope.config.UIConfigs['Default'];
            }
            return configToUse;
        };

        var getConfig = function () {
            utilityService.getConfigData('/components/backoffice/entity/ce-course/ce-course.server-config.json').
                then(function (data) {
                    var config = data;
                    $scope.config = config;
                    $scope.uiConfig = getUIConfig();
                    $scope.tableScope = {
                        addEditCeCourse: $scope.addEditCeCourse,
                        deleteCeCourse: $scope.deleteCeCourse,
                        deleteBulkCeCourse: $scope.deleteBulkCeCourse,
                        ceCourses: $scope.ceCourses,
                        tableHeaderClicked: $scope.tableHeaderClicked
                    };
                    var headers = {};
                    var fieldList = {};

                    for (var key in $scope.uiConfig.Fields) {
                        if (key == 'CECourseActivityTypeName' && !$scope.showFirstSecondYearSeletion)
                            continue;
                        if ($scope.uiConfig.Fields[key].showInTable) {
                            headers[key] = $scope.uiConfig.Fields[key];
                        }
                        if ($scope.uiConfig.Fields[key].showInForm) {
                            fieldList[key] = $scope.uiConfig.Fields[key];
                        }
                    }

                    $scope.headers = headers;
                    $scope.fieldList = fieldList;

                    loadData(true);
                }, function (error) {
                    $scope.loadingCeCourse(false);
                });
        }

        $scope.headerClicked = function (sortProp, headerProperty) {
            if (sortProp != $scope.sortColumn) {
                headerProperty.sortDirection = null;
            }
            $scope.sortColumn = sortProp;
            if (!headerProperty.sortDirection || headerProperty.sortDirection == 'desc') {
                $scope.currCycleCeCourses = $scope.currCycleCeCourses.orderByDescending($scope.sortColumn);
                headerProperty.sortDirection = 'asc';
            } else if (headerProperty.sortDirection == 'asc') {
                $scope.currCycleCeCourses = $scope.currCycleCeCourses.orderBy($scope.sortColumn);
                headerProperty.sortDirection = 'desc';
            }

            $scope.sortOrder = headerProperty.sortDirection;
        };

        var lodaDefaultsFromConfig = function (entityDataItem) {
            if ($scope.uiConfig.Defaults) {
                for (var key in $scope.uiConfig.Defaults) {
                    if ($scope.uiConfig.Defaults[key]) {
                        entityDataItem[key] = $scope.uiConfig.Defaults[key];
                    }
                }
            }
            return entityDataItem;
        };

        var getNewCeCourse = function () {
            var ceCourse = {
                EntityId: $scope.entityId,
                EntityType: $scope.entityType,
                IndividualId: $scope.entityId,
                ApplicationId: $scope.applicationId,
                LicenseNumber: $scope.licenseNumber,
                CECourseStatusId: 1,
                IsActive: true,
                IsDeleted: false
            };
            ceCourse = lodaDefaultsFromConfig(ceCourse);
            return ceCourse;
        };

        $scope.calculateTotals = function (ceCourses) { };

        // $scope.$on("SendUp", function (evt, data) {
        //     var errorObj = {
        //         propName: "MinMaxIncrementInvalid",
        //         errorMessage: data
        //     };
        //     $scope.excelErrorArray.push(errorObj);
        // });

        $scope.checkMinMaxIncrementErrorForCECourse = function () {
            angular.forEach($scope.currCycleCeCourses, function (errorItem) {
                if (errorItem.CECourseCategoryName == "Live Presentation" || errorItem.CECourseCategoryName == "Group Internet-based programs" || errorItem.CECourseCategoryName == "Blended Learning Program" ) {
                    if (errorItem.CECourseHours < 1 ) {
                        var errorObj = {
                            propName: "MinMaxIncrementInvalid",
                            errorMessage: "Minimum hours claimed for " + errorItem.CECourseCategoryName + " must be 1 (May be in increments of .20 or .50 thereafter)."
                        };
                        $scope.natValidation.warningMessages.push(errorObj);
                        $scope.natValidation.showWarning = true;
                    }
                }

                else if (errorItem.CECourseCategoryName == "Nano Learning Program") {
                    if (errorItem.CECourseHours % 0.5 == 0) {
                        if ((errorItem.CECourseHours % 1 != 0) && (errorItem.CECourseHours % 0.2 != 0)) {
                            var errorObj = {
                                propName: "MinMaxIncrementInvalid",
                                errorMessage: "Hours claimed for " + errorItem.CECourseCategoryName + " must be increments of 0.2." 
                            };
                            $scope.natValidation.warningMessages.push(errorObj);
                            $scope.natValidation.showWarning = true; 
                        }
                    }
                }

                else if (errorItem.CECourseCategoryName == "Interactive Self-Study") {
                    if (errorItem.CECourseHours < 0.5 ) {
                        var errorObj = {
                            propName: "MinMaxIncrementInvalid",
                            errorMessage: "Minimum hours claimed for " + errorItem.CECourseCategoryName + " must be 0.5 (May be in increments of .20 or .50 thereafter)."
                        };
                        $scope.natValidation.warningMessages.push(errorObj);
                        $scope.natValidation.showWarning = true;                                
                    }
                }  
                else if (errorItem.CECourseCategoryName == "Author of published books, articles, CE program instruction materials, or questions for the CPA Exam" || errorItem.CECourseCategoryName == "Teaching:  as an instructor, discussion leader or speaker") {
                    if (errorItem.CECourseHours > 40 ) {
                        var errorObj = {
                            propName: "MinMaxIncrementInvalid",
                            errorMessage: "Maximum hours claimed for " + errorItem.CECourseCategoryName + " is 40 hours."
                        };
                        $scope.natValidation.warningMessages.push(errorObj);
                        $scope.natValidation.showWarning = true; 
                    }
                }
                else {
                    var errorObj = {
                        propName: "MinMaxIncrementInvalid",
                        errorMessage: "Please enter CE Hours in increments of 0.50 " + errorItem.CECourseCategoryName +" only."
                    };
                    $scope.natValidation.warningMessages.push(errorObj);
                    $scope.natValidation.showWarning = true; 
                }
            
            $scope.uniqueObjArray = [
                ...new Map($scope.natValidation.warningMessages.map((item) => [item["errorMessage"], item])).values(),
            ];
            $scope.natValidation.warningMessages = $scope.uniqueObjArray;

                });
        };

        $scope.checkSubjectCodeForNanoLearning = function () {
            angular.forEach($scope.ceCourses, function (ceCourse) {
                if (ceCourse.CECourseCategoryName == "Nano Learning Program") {
                    if (ceCourse.CECourseTypeName == "Ethics" || ceCourse.CECourseTypeName =="Fraud" || ceCourse.CECourseTypeName =="Board-Approved Regulatory Review Course"){
                        var errorArray = {
                            propName: "SubjectCodeForNanoLearning",
                            errorMessage: ceCourse.CECourseTypeName + " course cannot be taken from " + ceCourse.CECourseCategoryName
                        };
                        $scope.natValidation.warningMessages.push(errorArray);
                        $scope.natValidation.showWarning = true;
                    }
                }
            });
        };

        $scope.checkCourseCompletionDateIsValid = function () {
            $scope.applicationObj = utilityService.applicationObject;
            var isTabCheck = true;
            $scope.natValidation.warningMessages = [];
            var expirationDate = new Date($scope.individualLicense.LicenseExpirationDate);
            if (!!$scope.individualLicense.isLicenseExpired) {
                if(!$scope.individualLicense.isSubmitted)
                    expirationDate = new Date();
                else 
                    expirationDate = new Date($scope.individualLicense.SubmittedDate);
            }
            if (!!$scope.individualLicense && !!$scope.individualLicense.effectiveCEDate) {
                angular.forEach($scope.ceCourses, function (ceCourseItem) {
                    if (!$scope.applicationObj){
                        if (!((($scope.individualLicense.effectiveCEDate <= new Date(ceCourseItem.CECourseDate) || !!$scope.individualLicense.isLicenseExpired)) && (expirationDate >= new Date(ceCourseItem.CECourseDate)))) {
                            var errorObj = {
                                propName: "CourseCompletedDateInvalid",
                                errorMessage: "Date Completed of Course " + ceCourseItem.CourseNameTitle + " should be between " + $scope.individualLicense.effectiveCEDate.toDateString() + " and " + expirationDate.toDateString()
                            };
                            $scope.natValidation.warningMessages.push(errorObj);
                            isTabCheck = false;
                            $scope.natValidation.showWarning = true;
                        }
                    }
                });
            }

            if (!!$scope.individualLicense && !!$scope.individualLicense.firstYearCEEffectiveDate && !!$scope.individualLicense.firstYearCEExpirationDate) {
                angular.forEach($scope.firstYrCeCourses, function (firstYrCeCourseItem) {
                    if (!$scope.applicationObj){
                        if (!(($scope.individualLicense.firstYearCEEffectiveDate <= new Date(firstYrCeCourseItem.CECourseDate)) && ($scope.individualLicense.firstYearCEExpirationDate >= new Date(firstYrCeCourseItem.CECourseDate)))) {
                            var errorObj = {
                                propName: "FirstYrCourseCompletedDateInvalid",
                                errorMessage: "Date Completed of Course " + firstYrCeCourseItem.CourseNameTitle + " should be between " + $scope.individualLicense.firstYearCEEffectiveDate.toDateString() + " and " + $scope.individualLicense.firstYearCEExpirationDate.toDateString()
                            };
                            $scope.natValidation.warningMessages.push(errorObj);
                            isTabCheck = false;
                            $scope.natValidation.showWarning = true;
                        }
                    }    
                });
            }
            if (!!$scope.individualLicense && !!$scope.individualLicense.secondYearCEEffectiveDate && !!$scope.individualLicense.secondYearCEExpirationDate) {
                angular.forEach($scope.secondYrCeCourses, function (secondYrCeCourseItem) {
                    if (!$scope.applicationObj){
                        if (!(($scope.individualLicense.secondYearCEEffectiveDate <= new Date(secondYrCeCourseItem.CECourseDate)) && ($scope.individualLicense.secondYearCEExpirationDate >= new Date(secondYrCeCourseItem.CECourseDate)))) {
                            var errorObj = {
                                propName: "SecondYrCourseCompletedDateInvalid",
                                errorMessage: "Date Completed of Course " + secondYrCeCourseItem.CourseNameTitle + " should be between " + $scope.individualLicense.secondYearCEEffectiveDate.toDateString() + " and " + $scope.individualLicense.secondYearCEExpirationDate.toDateString()
                            };
                            $scope.natValidation.warningMessages.push(errorObj);
                            isTabCheck = false;
                            $scope.natValidation.showWarning = true;
                        }
                    }
                })
            }
            if (!isTabCheck) {
                if (!!$scope.tab)
                    $scope.tab.isSaved = isTabCheck;
            }
        };

        var loadData = function (loadAllData) {
            var dataPromises = [backofficeCeCourseService.ceCourseGetByEntityId(sessionStorage.Key, $scope.entityId, $scope.entityType)];
            if (!!loadAllData) {
                dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=cecoursecategory")));
                dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=cecoursetype")));
                dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=cecourseactivitytype")));
                dataPromises.push(backofficeIndividualLicenseService.individualLicenseDetailBYIndividualId(sessionStorage.Key, $scope.entityId));
            }

            $q.all(dataPromises)
                .then(function (response) {
                    var ceCourses = [];
                    angular.forEach(response, function (responseItem, itemIndex) {
                        if (responseItem.Status) {
                            if (itemIndex == 0) {
                                if (!!$scope.applicationInfo && $scope.applicationInfo.ApplicationStatusId == 3)
                                    ceCourses = responseItem.IndividualCECourseResponseList.whereEquals($scope.applicationId, 'ApplicationId');
                                else
                                    ceCourses = responseItem.IndividualCECourseResponseList.whereNotIncludes([2], 'CECourseStatusId');
                            } else if (itemIndex == 1) {
                                $scope.CeCourseCategories = responseItem.ReferenceEntities;
                                $scope.ceCourseStatuses = $scope.processRefEntityData(responseItem.ReferenceEntities);
                            } else if (itemIndex == 2) {
                                $scope.ceCourseTypes = responseItem.ReferenceEntities;
                                $scope.ceCourseTypesOrg = responseItem.ReferenceEntities;
                            } else if (itemIndex == 3) {
                                $scope.ceCourseActivityTypes = responseItem.ReferenceEntities;
                            } else if (itemIndex == 4) {
                                var isLicenseActiveList = _.filter(responseItem.IndividualLicenseList, function (o) {
                                    return true;
                                });
                                $scope.individualLicenseList = _.uniq(isLicenseActiveList);
                            }
                        } else {
                            if (responseItem.Message != "No record found.")
                                $scope.showStatusMsg('-', responseItem.Message);
                        }
                    });
                    
                    $scope.ceCourses = processCeCourseData(ceCourses);
                    $scope.paginationParams.pageSize = $scope.ceCourses.length;

                    if (!!$scope.paginationParams.setTotalRecords)
                        $scope.paginationParams.setTotalRecords($scope.currCycleCeCourses.length);
                    $scope.calculateTotals($scope.ceCourses);
                    $scope.checkCourseCompletionDateIsValid();
                    $scope.checkMinMaxIncrementErrorForCECourse();
                    $scope.checkSubjectCodeForNanoLearning();
                    $scope.isConfigLoaded = true;
                    $scope.loadingCeCourse(false);
                    // angular.forEach($scope.excelErrorArray, function (errorItem) {
                    //     $scope.natValidation.warningMessages.push (errorItem)
                    // });
                    // $scope.natValidation.showWarning = true;
                }, function (data) {
                    $scope.loadingCeCourse(false);
                    $scope.showStatusMsg('-', data.message);
                });
        };

        $scope.processRefEntityData = function (entityRefData) {
            return entityRefData;
        };

        $scope.entityFilterDataByConfig = function (entityData) {
            return entityData;
        };

        $scope.onMethodOfPresentationChange = function (value) {
            if(value == 6) {
                return $scope.ceCourseTypes.filter(listValue => listValue.CECourseTypeId != 2 && listValue.CECourseTypeId != 3 && listValue.CECourseTypeId != 4 );
            } else {
                return $scope.ceCourseTypes;
            }
        };
        
        $scope.updateMinMaxStep = function(value) {
            var methodOfPresentation = $scope.CeCourseCategories.filter(function(item) { return item.CECourseCategoryId === value; }).shift();
            if([1,5,7].indexOf(value) !== -1){
                $scope.minInputValue = 1.00;
                $scope.maxInputValue = null;
                $scope.stepInputValue = null;
                $scope.regexExp = /^((?:[1-9]+[0-9]*)(?:\.[024568]0)*|(?:[1-9]+[0-9]*\.[024568]0*))$/;
                $scope.regexExpMsg = "Minimum hours claimed for " + methodOfPresentation.CECourseCategoryName + " must be " + $scope.minInputValue + ". (May be in increments of .20 or .50 thereafter)";
            } else if([2].indexOf(value) !== -1){
                $scope.minInputValue = 0;
                $scope.maxInputValue = null;
                $scope.stepInputValue = null;
                $scope.regexExp = /^(0*\.[568]0*|(?:[1-9]+[0-9]*)(?:\.[024568]0)*|(?:[1-9]+[0-9]*\.[024568]0*))$/;
                $scope.regexExpMsg = "Minimum hours claimed for " + methodOfPresentation.CECourseCategoryName + " must be 0.5. (May be in increments of .20 or .50 thereafter)";
            } else if([6].indexOf(value) !== -1){
                $scope.minInputValue = 0.20;
                $scope.maxInputValue = null;
                $scope.stepInputValue = 0.20;
                $scope.regexExp = /^(0\.\d{1,2}|[1-9]\d*(\.\d{1,2})?|0?[1-9]\d*(\.\d{1,2})?)$/;
                $scope.regexExpMsg = "Hours claimed for " + methodOfPresentation.CECourseCategoryName + " must be increments of " + $scope.minInputValue + ".";
            } else if([3,4].indexOf(value) !== -1){
                $scope.minInputValue = 0;
                $scope.maxInputValue = 40;
                $scope.regexExp = /^([1-3]?\d(\.\d+)?|40(\.0+)?)$/;
                $scope.regexExpMsg = "Maximum hours claimed for " + methodOfPresentation.CECourseCategoryName + " is "+ $scope.maxInputValue+" hours.";
            } else{
                $scope.stepInputValue = 0.50;
                $scope.minInputValue = 0;
                $scope.maxInputValue = null;
                $scope.regexExp = /^(0|[1-9]\d*)(\.\d{1,2})?$|^0?\.\d{1,2}?$/;
                $scope.regexExpMsg =  "Please enter CE Hours in increments of " + $scope.stepInputValue +" only.";
            }
        };

        $scope.addEditCeCourse = function (isCeCourseEdit, ceCourse) {
            $scope.loadingCeCourse(true, "Please wait");
            $scope.viewConfig.isCeCourseEdit = isCeCourseEdit;
            $scope.viewConfig.showAddBlock = true;
            if (!$scope.viewConfig.isCeCourseEdit) {
                $scope.ceCourse = getNewCeCourse();
            } else {
                $scope.ceCourse = angular.copy(ceCourse);
            }
            $scope.loadingCeCourse(false);
        };

        $scope.closeAddEditCeCourse = function () {
            $scope.viewConfig.showAddBlock = false;
            $scope.clearValidation();
        };

        var processCeCourseData = function (ceCourseData) {
            ceCourseData = ceCourseData.leftJoinObjects($scope.CeCourseCategories, 'item1.CourseCategoryId==item2.CECourseCategoryId', 'this1, CourseCategoryName: item2.CECourseCategoryName');
            ceCourseData = ceCourseData.leftJoinObjects($scope.ceCourseTypes, 'item1.CECourseTypeId==item2.CECourseTypeId', 'this1, CECourseTypeName: item2.CECourseTypeName');
            ceCourseData = ceCourseData.leftJoinObjects($scope.ceCourseActivityTypes, 'item1.CECourseActivityTypeId==item2.CECourseActivityTypeId', 'this1, CECourseActivityTypeName: item2.CECourseActivityTypeName');
            // ceCourseData = ceCourseData.leftJoinObjects($scope.prefixTypes, 'item1.PrefixTypeId==item2.PrefixTypeId', 'this1, PrefixTypeName: item2.PrefixTypeName');
            // ceCourseData = ceCourseData.leftJoinObjects($scope.suffixTypes, 'item1.SuffixTypeId==item2.SuffixTypeId', 'this1, SuffixTypeName: item2.SuffixTypeName');
            // ceCourseData = ceCourseData.leftJoinObjects($scope.profTitleSuffixTypes, 'item1.ProfTitleSuffixTypeId==item2.ProfTitleSuffixTypeId', 'this1, ProfTitleSuffixTypeName: item2.ProfTitleSuffixTypeName');
            // if ($scope.licenseNumber) {
            //     ceCourseData = ceCourseData.whereEquals($scope.licenseNumber, 'LicenseNumber');
            // }
            ceCourseData = $scope.entityFilterDataByConfig(ceCourseData);
            $scope.filterCeCourseDataByCycle(ceCourseData);
            $scope.processDocuments(ceCourseData);
            return ceCourseData;
        };

        // Save CeCourse
        $scope.saveCeCourse = function (ceCourseForm) {
            $scope.hideStatusMsg();
            ceCourseForm.$submitted = true;
            if ($scope.validateForm(ceCourseForm)) {
                $scope.loadingCeCourse(true, "Please wait");
                $scope.saveCeCourseRecord();
            }
        };

        $scope.saveCeCourseRecord = function (ceCourse) {
            var ceCourseData = angular.copy($scope.ceCourse);
            if (!!ceCourse)
                ceCourseData = ceCourse;

            if (!!ceCourseData.CourseNumber)
                ceCourseData.CourseNumber = 'R' + ceCourseData.CourseNumber;
            backofficeCeCourseService.ceCourseSave(ceCourseData,
                sessionStorage.Key).then(function (response) {
                    if (response.Status) {
                        ceCourseData.IndividualCECourseId = response.IndividualCECourseResponseList[0].IndividualCECourseId;
                        if (!ceCourseData.IsDeleted) {
                            $scope.documentCECourse.individualDocumentSave(ceCourseData.IndividualCECourseId).then(function (documentResponse) {
                                if (documentResponse.Status) {
                                    loadData(false);
                                    $scope.closeAddEditCeCourse();
                                    $scope.showStatusMsg('+', documentResponse.Message);
                                } else {
                                    $scope.showStatusMsg('-', documentResponse.Message);
                                }
                            }, function (error) {
                                $scope.showStatusMsg('-', error.Message);
                            })
                        }else{
                            loadData(true);
                        }
                    } else {
                        $scope.showStatusMsg('-', response.Message);
                    }
                    $scope.loadingCeCourse(false);
                }, function (data) {
                    $scope.loadingCeCourse(false);
                    $scope.showStatusMsg('-', data.message);
                });
        };


        $scope.deleteCeCourse = function (ceCourse) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                $scope.loadingCeCourse(true, "Please wait");
                $scope.ceCourse = angular.copy(ceCourse);
                $scope.ceCourse.IsDeleted = true;
                $scope.saveCeCourseRecord(false);
            }
        };

        $scope.deleteBulkCeCourse = function (ceCourse) {
            if(confirm(Messages.General.Confirm.DeleteRecord)){
                $scope.ceCourses.where(function (item) {
                    if(item.isSelected){
                        $scope.loadingCeCourse(true, "Please wait");
                        $scope.ceCourse = angular.copy(item);
                        $scope.ceCourse.IsDeleted = true;
                        $scope.saveCeCourseRecord(false);
                        $scope.current = {};
                    }
                });
            }
        };

        $scope.onSelectionChanged = function (isAllSelectedClicked, currentValue) {
            if (!!isAllSelectedClicked) {
                $scope.ceCourses.map(function (applicationItem) {
                    applicationItem.isSelected = currentValue;
                });
            } else {
                $scope.current.isAllSelected = ($scope.ceCourses.where(function (item) {
                    return (!item.DisciplineFlag && (item.ApplicationSubTypeId == 2 || item.FingerprintStatus == "MatchedPass") && item.IsPaymentProcessed == true && (item.ApplicationSubTypeId == 2 || !item.ExamRequired || item.ExamStatus == "Passed"));
                }).all('!!item.isSelected'));
                $scope.current.isAllSelected = $scope.ceCourses.all('!!item.isSelected');
            }
            $scope.current.isAnySelected = ($scope.ceCourses.any('!!item.isSelected'));
        };

        $scope.isConfiguredField = function (fieldName) {
            return ($scope.fieldList[fieldName]);
        };

        $scope.changeDynamicErrorMsg = function () {
            if (!!$scope.datePicker.startDateOptions.minDate && !!$scope.datePicker.startDateOptions.maxDate)
                $scope.dynamicErrorMsg = "Date Completed should be between " + $scope.datePicker.startDateOptions.minDate.toDateString() + " and " + $scope.datePicker.startDateOptions.maxDate.toDateString();
            else if (!$scope.datePicker.startDateOptions.minDate && !!$scope.datePicker.startDateOptions.maxDate)
                $scope.dynamicErrorMsg = "Date Completed should less than " + $scope.datePicker.startDateOptions.maxDate.toDateString();
            else if (!!$scope.datePicker.startDateOptions.minDate && !$scope.datePicker.startDateOptions.maxDate)
                $scope.dynamicErrorMsg = "Date Completed should greater than " + $scope.datePicker.startDateOptions.minDate.toDateString();
        }

        $scope.setDateCompletedRange = function (val) {
            $scope.applicationObj = utilityService.applicationObject;
            if (val == 1) {
                if (!$scope.applicationObj){
                    $scope.datePicker.startDateOptions.minDate = new Date($scope.individualLicense.firstYearCEEffectiveDate);
                    $scope.datePicker.startDateOptions.maxDate = new Date($scope.individualLicense.firstYearCEExpirationDate);
                    $scope.changeDynamicErrorMsg();
                }
                else{
                    if ($scope.applicationObj.isRenewal == true &&
                        $scope.individualLicense.LicenseNumber == $scope.applicationObj.LicenseNumber) {
                        $scope.datePicker.startDateOptions.minDate = null;
                        $scope.datePicker.startDateOptions.maxDate = null;
                        $scope.changeDynamicErrorMsg();
                    }
                    else {
                        $scope.datePicker.startDateOptions.minDate = new Date($scope.individualLicense.firstYearCEEffectiveDate);
                        $scope.datePicker.startDateOptions.maxDate = new Date($scope.individualLicense.firstYearCEExpirationDate);
                        $scope.changeDynamicErrorMsg();
                    }
                }
            }
            else {
                if (!$scope.applicationObj){
                    $scope.datePicker.startDateOptions.minDate = new Date($scope.individualLicense.secondYearCEEffectiveDate);
                    $scope.datePicker.startDateOptions.maxDate = new Date($scope.individualLicense.secondYearCEExpirationDate);
                    $scope.changeDynamicErrorMsg();
                }
                else {
                    if ($scope.applicationObj.isRenewal == true &&
                        $scope.individualLicense.LicenseNumber == $scope.applicationObj.LicenseNumber) {
                        $scope.datePicker.startDateOptions.minDate = null;
                        $scope.datePicker.startDateOptions.maxDate = null;
                        $scope.changeDynamicErrorMsg();
                    }
                    else {
                        $scope.datePicker.startDateOptions.minDate = new Date($scope.individualLicense.secondYearCEEffectiveDate);
                        $scope.datePicker.startDateOptions.maxDate = new Date($scope.individualLicense.secondYearCEExpirationDate);
                        $scope.changeDynamicErrorMsg();
                    }
                }
            }
        }

        $scope.onNgInit = function () {
            $scope.isConfigLoaded = false;
            $scope.current = {};
            $scope.uiLogic = {};
            $scope.headers = {};

            if (!!$scope.individualLicense && !!$scope.individualLicense.isProrated) {
                $scope.datePicker.startDateOptions.minDate = $scope.individualLicense.effectiveCEDate;
                $scope.datePicker.startDateOptions.maxDate = new Date($scope.individualLicense.LicenseExpirationDate);
                if (!!$scope.individualLicense.isLicenseExpired) {
                    $scope.datePicker.startDateOptions.minDate = null;
                    $scope.datePicker.startDateOptions.maxDate = new Date();
                }
                $scope.changeDynamicErrorMsg();
                $scope.showFirstSecondYearSeletion = false;
            }

            if (!!$scope.individualLicense && !!$scope.individualLicense.firstYearCEEffectiveDate && !!$scope.individualLicense.firstYearCEExpirationDate) {
                $scope.datePicker.startDateOptions.minDate = $scope.individualLicense.firstYearCEEffectiveDate;
                $scope.datePicker.startDateOptions.maxDate = new Date($scope.individualLicense.firstYearCEExpirationDate);
                $scope.changeDynamicErrorMsg();
                $scope.showFirstSecondYearSeletion = true;
            }

            getConfig();
            $timeout(function () {
                $scope.onNgInitOtherLogic();
            }, 100);
        };

        $scope.bulkUploadComplete = function () {
            loadData();
        };

        $scope.onNgInit();
    }
})();