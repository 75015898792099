(function () {
    'use strict'

    angular.module('app.core')
    .controller('DocumentUploadController', ['$scope', '$timeout', 'DocumentUploadService', 'Messages', DocumentUploadController]);

    function DocumentUploadController($scope, $timeout, DocumentUploadService, Messages) {
        var _key = sessionStorage.Key;
        var _downloadDocumentPrefix = 'data:application/octet-stream;';

        var optionsRequired = {
            getExampleDocumentApiUrl: false,
            uploadDocumentApiUrl: true,
            fileExtensions: false,
            getExampleDocumentMessage: false,
            uploadDocumentMessage: false
        };

        $scope.invalidFiles = [];
        $scope.closeFileError = function(){
            $scope.invalidFiles = [];
        }

        var b64toBlob = function(b64Data, type) {
            var byteCharacters = atob(b64Data);
            var byteArrays = [];
            var sliceSize = 512;

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }
            return new Blob(byteArrays, {type: type});
        }

        var downloadBase64File = function (b64Data, mimeType, downloadPrefix, fileName) {
            var blob = b64toBlob(b64Data, mimeType);
            var anchor = angular.element('<a/>');

            if(window.navigator.msSaveBlob) { // IE
                window.navigator.msSaveBlob(blob, fileName);
            } else if (navigator.userAgent.search("Firefox") !== -1) { // Firefox
                anchor.css({display: 'none'});
                angular.element(document.body).append(anchor);
            
                anchor.attr({
                  href: downloadPrefix + 'base64,' + b64Data,
                  target: '_blank',
                  download: fileName
                })[0].click();
            
                anchor.remove();
              } else { // Chrome
                anchor.attr({
                  href: downloadPrefix + 'base64,'  + b64Data,
                  target: '_blank',
                  download: fileName
                })[0].click();
              }
        };

        var setupOptions = function () {
            if(!$scope.options) {
                $scope.options = {};
            }

            if(!$scope.options.fileExtensions) {
                $scope.options.fileExtensions = [];
            }

            if(typeof($scope.options.fileExtensions) === 'string') {
                $scope.options.fileExtensions = $scope.options.fileExtensions.split(',').select(function (x) { return x.trim(); });
            } else if (!Array.isArray($scope.options.fileExtensions)) {
                console.log('Option: fileExtensions must be either a comma-delimited string or an array of strings');
            }

            if(!$scope.options.getExampleDocumentMessage) {
                $scope.options.getExampleDocumentMessage = 'Getting File...';
            }

            if(!$scope.options.uploadDocumentMessage) {
                $scope.options.uploadDocumentMessage = 'Uploading File...';
            }
        };
        
        var validateOptions = function () {
            for(var optionProp in optionsRequired) {
                if(optionsRequired.hasOwnProperty(optionProp)) {
                    var required = optionsRequired[optionProp];

                    if (required && !$scope.options[optionProp]) {
                        console.log('Option: {0} is required'.format(optionProp));
                    }
                }
            }
        };

        var getNewResponse = function () {
            return {
                fileName: Messages.abdfade,
                base64DocString: '',
                docMimeType: '',
                successMessage: '',
                errorMessage: '',
                suppressMessage: false,
                hideLoader: true
            };
        };

        var resetProperties = function () {
            $scope.base64DocString = '';
            $scope.document = null;
            $scope.errorMessage = '';
            $scope.successMessage = '';
            $scope.documentBase64 = '';
            $scope.currentFileName = 'You may click here to open the file explorer, or drag and drop the file into this space.';
        };

        $scope.init = function () {
            setupOptions();

            resetProperties();            

            validateOptions();

            $scope.sharedMethods = {
                downloadFile: function (documentBase64, documentType, fileName) {
                    downloadBase64File(documentBase64, documentType, _downloadDocumentPrefix, fileName);
                },
                showLoader: function (show, message) {
                    $scope.operationPending(show, message);
                },
                showSuccessMessage: function (message) {
                    $scope.successMessage = message;
                },
                showErrorMessage: function (message) {
                    $scope.errorMessage = message;
                }
            }

            if(!!$scope.provideMethods) {
                $scope.provideMethods({methods: $scope.sharedMethods});
            }
        };

        $scope.addDocument = function (files) {
            var extRegex = /^.*\.(.*)$/gi;
            var extMatch = extRegex.exec(files[0].name);

            //If file extensions have been specified, check to make sure the file extension is acceptable
            if (!!$scope.options.fileExtensions && $scope.options.fileExtensions.length > 0) {
                //Check if the file has an extension, and make sure it's xlsx
                if (!extMatch || !extMatch[1] || !$scope.options.fileExtensions.includes(extMatch[1])) {
                    $scope.errorMessage = 'The file extension is invalid';
                    return;
                }
            }
            
            var reader = new FileReader();
            var urlRegex = /.*base64,(.*)/gi;

            reader.onload = function () {
                $scope.documentBase64 = '';
                $scope.document = undefined;
                $scope.currentFileName = '';
                
                $timeout(function () {
                    var match = urlRegex.exec(reader.result);
                    $scope.documentBase64 = match[1];
                    $scope.document = files[0];
                    $scope.currentFileName = files[0].name;
                }, 1);
            };

            reader.readAsDataURL(files[0]);
        };

        $scope.getExampleDocument = function () {
            try {
                if(!$scope.options.getExampleDocumentApiUrl) {
                    $scope.errorMessage = 'Get example document failed - no API url provided';
                }

                $scope.operationPending(true, $scope.options.getExampleDocumentMessage);
                
                DocumentUploadService.getExampleDocument(_key, $scope.options.getExampleDocumentApiUrl)
                .then(function (data) {
                    if(data.Status) {
                        if (!!$scope.getExampleDocumentResponse) {
                            var response = getNewResponse();
                            var result = true; 
                            
                            if($scope.getExampleDocumentResponse) {
                                result = $scope.getExampleDocumentResponse(data, response, $scope.sharedMethods);
                            }
        
                            if(result) {
                                $scope.successMessage = response.message;
                            } else {
                                $scope.errorMessage = response.message;
                            }

                            if (response.base64DocString && response.base64DocString.length > 0) {
                                downloadBase64File(response.base64DocString, response.docMimeType, _downloadDocumentPrefix, response.fileName);
                            }
                        }
                    } else {
                        $scope.errorMessage = data.Message;
                    }
                    $scope.operationPending(false);
                }, function (error) {
                    $scope.errorMessage = 'An error occurred when trying to send the request';
                    $scope.operationPending(false);
                });
            } catch (ex) {
                $scope.errorMessage = ex.message;
                $scope.operationPending(false);
            }
        };

        $scope.uploadDocument = function () {
            try {
                if(!$scope.options.uploadDocumentApiUrl) {
                    $scope.errorMessage = 'Upload document failed - no API url provided';
                }

                $scope.operationPending(true, $scope.options.uploadDocumentMessage);

                var urlParams = {};
                var request = $scope.getUploadRequest($scope.currentFileName, $scope.document, $scope.documentBase64, urlParams);

                DocumentUploadService.uploadDocument(_key, $scope.options.uploadDocumentApiUrl, request, urlParams)
                .then(function (data) {
                    $scope.cancelUpload();
                    if (data.Status) {
                        if (!!$scope.getUploadDocumentResponse) {
                            var response = getNewResponse();
                            var result = $scope.getUploadDocumentResponse(data, response, $scope.sharedMethods);
    
                            if (!response.suppressMessage) {
                                if (result) {
                                    $scope.successMessage = response.message;
                                } else {
                                    $scope.errorMessage = response.message;
                                }
                            }
                            
                            if (response.base64DocString && response.base64DocString.length > 0) {
                                downloadBase64File(response.base64DocString, response.docMimeType, _downloadDocumentPrefix, response.fileName);
                            }

                            if (!!response.hideLoader) {
                                $scope.operationPending(false);
                            }
                        }
                    } else {
                        $scope.errorMessage = data.Message;
                        $scope.operationPending(false);
                    }
                }, function (error) {
                    $scope.errorMessage = 'An error occurred when trying to send the request';
                    $scope.cancelUpload();
                    $scope.operationPending(false);
                });
            } catch (ex) {
                $scope.cancelUpload();
                $scope.operationPending(false);
            }
        };

        $scope.cancelUpload = function () {
            resetProperties();
        }
    }
})();