(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("DeficiencyController", DeficiencyController);

    DeficiencyController.$inject = ["$rootScope", "$scope", "$state", "$q",
        "deficiencyService", "backofficeIndividualAdressService",
        "initialApplicationstatusService", "backofficeIndividualPersonalService", "providerContactService", "BackofficeProviderService","utilityService", "WebApiUrl", "Messages"
    ];

    function DeficiencyController($rootScope, $scope, $state, $q,
        deficiencyService, backofficeIndividualAdressService,
        initialApplicationstatusService, backofficeIndividualPersonalService, providerContactService, BackofficeProviderService, utilityService, WebApiUrl, Messages) {

        $rootScope.routeData = $state.current.routeData;
        $scope.ckEditorOptions = {
            language: 'en',
            allowedContent: true,
            entities: false,
            disableNativeSpellChecker: false,
            scayt_autoStartup: true
        };

        $scope.init = function () {
            if ($scope.deftype == 'emailer') {
                $scope.getIndividualContact();
            }

            $scope.currentUserInfo = {};
        };

        $scope.saveDeficiency = function (isSaveAllTabs) {
            // $scope.sendWait('deficiency');
            // $scope.savingDeficiency(true, 'Saving...');

            if ((($scope.currentTab && $scope.currentTab.isActiveTab) || !!isSaveAllTabs) && !!$scope.deficiencyData) {
                $scope.currentDate = new Date();
                utilityService.getServerDate().then(function (response) {
                    $scope.currentDate = new Date(response);
                    if (!!$scope.deficiencyData.IndividualDefeciencyDtlList && $scope.deficiencyData.IndividualDefeciencyDtlList.length > 0) {
                        for (var i = 0; i < $scope.deficiencyData.IndividualDefeciencyDtlList.length; i++) {
                            if ($scope.deficiencyData.IndividualDefeciencyDtlList[i].IsDefecient && $scope.deficiencyData.IndividualDefeciencyDtlList[i].IsDefeciencyResolved) {
                                $scope.deficiencyData.IndividualDefeciencyDtlList[i].DateDefeciencyResolved = $scope.currentDate;
                            } else {
                                $scope.deficiencyData.IndividualDefeciencyDtlList[i].DateDefeciencyResolved = null;
                            }
                        }
                    }
                    deficiencyService.saveIndividualDeficiency($scope.deficiencyData, sessionStorage.Key)
                        .then(function (responseDef) {
                            // $scope.sendComplete('deficiency');
                            $scope.$emit('deficiencySaved', $scope.currentTab);
                            // $scope.savingDeficiency(false);

                            $scope.currentTab.individualDefeciency = getDeficiencyData($scope.currentTab, responseDef.IndividualDefeciencyAllList[0]);
                        }, function () {
                            // $scope.sendCancel();
                            // $scope.savingDeficiency(false);
                        });
                }, function () {
                    // $scope.sendCancel();
                    // $scope.savingDeficiency(false);
                });
            } else {
                // $scope.sendComplete('deficiency');
                // $scope.savingDeficiency(false);
            }
        };

        $scope.sendDeficiencyEmail = function () {
            ShowLoader();
            $scope.errorMessage = '';
            if (!$scope.deficiencyEmailObj.Email) {
                $scope.errorMessage = "Please enter email address <br/>";
            }
            if (!$scope.deficiencyEmailObj.BodyContent) {
                $scope.errorMessage = $scope.errorMessage + "Please enter email body";
            }
            if ($scope.errorMessage != '') {
                HideLoader();
                return;
            }
            if ($scope.isDenyCall && !$scope.applicationInfo.dnqueuedItemsDataExists) {
                if (!confirm(Messages.eadcfff)) {
                    HideLoader();
                    return;
                }
            }
            $scope.deficiencyEmailObj.ProviderId = $scope.applicationInfo?.ProviderId;
            var dataService;
            //if ($scope.isAssignTo) {
            dataService = deficiencyService.emailAndText($scope.deficiencyEmailObj, sessionStorage.Key);
            // } else {
            //     if ($scope.deficiencyEmailObj != null) {
            //         $scope.individualcommunicationlog.EmailTo = $scope.deficiencyEmailObj.Email;
            //         $scope.individualcommunicationlog.CommunicationText = $scope.deficiencyEmailObj.BodyContent;
            //         $scope.individualcommunicationlog.SecureCommunicationText = $scope.deficiencyEmailObj.BodyContent;
            //     }
            //     dataService = deficiencyService.individualCommunicationSave($scope.individualcommunicationlog, sessionStorage.Key);
            // }
            dataService.then(function (responseDef) {
                showStatusMessage(Messages.General.Success.EmailSent, "success");
                HideLoader();
                if (!!$scope.isDenyCall) {
                    if (!$scope.applicationInfo.dnqueuedItemsDataExists) {
                        $scope.denyLicense();
                    } else {
                        $scope.closeDeficiencyEmailForm();
                        HideLoader();
                    }
                } else if (!!$scope.isAssignTo)
                    $scope.moveToQueue();
                else if (!!$scope.isDeficiencyCall) {
                    var appStatusId = $scope.applicationStatusId;
                    if ($scope.applicationStatusId == 8 || $scope.applicationStatusId == 10 || $scope.applicationStatusId == 42 || $scope.applicationStatusId == 41) {
                        appStatusId = 43;
                    }
                    else {
                        appStatusId = 45;
                    }
                    $scope.updateApplicationDefeciency({
                        applicationId: $scope.ApplicationId,
                        applicationStatusId: appStatusId
                    });
                    $scope.closeDeficiencyEmailForm();
                } else {
                    $scope.closeDeficiencyEmailForm();
                    HideLoader();
                }
            });
        };

        var assignContactsByContactTypeId = function (restrictCreateNewContact) {
            $scope.personalData = {};
            var emailAddressExists = false;
            var secondaryEmailAddressExists = false;
            angular.forEach($scope.personalContact, function (contactOption, index) {
                if (contactOption.ContactStatusId == 1) {
                    if (contactOption.ContactTypeId == "8" && !emailAddressExists) {
                        $scope.personalData.EmailAddressContact = contactOption;
                        emailAddressExists = true;
                    }
                    if (contactOption.ContactTypeId == "23" && !secondaryEmailAddressExists) {
                        $scope.personalData.SecondaryEmailAddressContact = contactOption;
                        secondaryEmailAddressExists = true;
                    }
                }
            });
            $scope.deficiencyEmailObj = {
                EmailACopyToSender: false,
                sendEmail: true,
                IndividualId: $scope.IndividualId,
                CommunicationType: ((!$scope.isAssignTo) ? 2 : 0),
            };
            $scope.individualcommunicationlog = {
                IndividualId: $scope.IndividualId,
                ApplicationId: $scope.ApplicationId,
                Type: "M",
                UserIdFrom: ((!!sessionStorage.UserID) ? sessionStorage.UserID : sessionStorage.UserId),
                CommunicationSource: 'Email Communication',
                CommunicationStatus: "E",
                IsActive: true,
                EmailaCopyToSender: true,
                SendEmail: true,
                IndividualLicenseId: ((!!$scope.individualLicense) ? $scope.individualLicense.IndividualLicenseId : ''),
                SaveCommunicationOnly: false
            };
            // if (!!$scope.isDeficiencyCall) {
            //     $scope.deficiencyEmailObj.Subject = 'Deficiencies in Renewal Form';
            //     $scope.individualcommunicationlog.Subject = 'Deficiencies in Renewal Form';
            // }
            // else if (!!$scope.isAssignTo) {
            //     $scope.deficiencyEmailObj.Subject = 'Renewal assigned to queue';
            //     $scope.individualcommunicationlog.Subject = 'Renewal assigned to queue';
            // } else {
            //     $scope.deficiencyEmailObj.Subject = 'Application Does Not Qualify';
            //     $scope.individualcommunicationlog.Subject = 'Application Does Not Qualify';
            // }

            if (!$scope.isAssignTo) {
                if (!!$scope.personalData.EmailAddressContact) {
                    $scope.deficiencyEmailObj.Email = $scope.personalData.EmailAddressContact.ContactInfo;
                } else if (!!$scope.personalData.SecondaryEmailAddressContact) {
                    $scope.deficiencyEmailObj.Email = $scope.personalData.SecondaryEmailAddressContact.ContactInfo;
                }
                $scope.individualcommunicationlog.EmailTo = $scope.deficiencyEmailObj.Email;
            }
            $scope.getDeficiencyEmailBody();
        };

        var assignProviderContactsByContactTypeId = function (restrictCreateNewContact) {
            $scope.personalData = {};
            var emailAddressExists = false;
            var secondaryEmailAddressExists = false;
            angular.forEach($scope.personalContact, function (contactOption, index) {
                if (contactOption.ContactStatusId == 1) {
                    if (contactOption.ContactTypeId == "23" && !emailAddressExists) {
                        $scope.personalData.EmailAddressContact = contactOption;
                        emailAddressExists = true;
                    }
                    // if (contactOption.ContactTypeId == "23" && !secondaryEmailAddressExists) {
                    //     $scope.personalData.SecondaryEmailAddressContact = contactOption;
                    //     secondaryEmailAddressExists = true;
                    // }
                }
            });
            $scope.deficiencyEmailObj = {
                EmailACopyToSender: false,
                sendEmail: true,
                IndividualId: $scope.IndividualId,
                ProviderId: $scope.ProviderId,
                CommunicationType: ((!$scope.isAssignTo) ? 2 : 0),
            };
            $scope.individualcommunicationlog = {
                IndividualId: $scope.ProviderId,
                ApplicationId: $scope.ApplicationId,
                Type: "M",
                UserIdFrom: ((!!sessionStorage.UserID) ? sessionStorage.UserID : sessionStorage.UserId),
                CommunicationSource: 'Email Communication',
                CommunicationStatus: "E",
                IsActive: true,
                EmailaCopyToSender: true,
                SendEmail: true,
                ProviderLicenseId: ((!!$scope.providerLicense) ? $scope.providerLicense.ProviderLicenseId : ''),
                SaveCommunicationOnly: false
            };
            // if (!!$scope.isDeficiencyCall) {
            //     $scope.deficiencyEmailObj.Subject = 'Deficiencies in Renewal Form';
            //     $scope.individualcommunicationlog.Subject = 'Deficiencies in Renewal Form';
            // }
            // else if (!!$scope.isAssignTo) {
            //     $scope.deficiencyEmailObj.Subject = 'Renewal assigned to queue';
            //     $scope.individualcommunicationlog.Subject = 'Renewal assigned to queue';
            // } else {
            //     $scope.deficiencyEmailObj.Subject = 'Application Does Not Qualify';
            //     $scope.individualcommunicationlog.Subject = 'Application Does Not Qualify';
            // }

            if (!$scope.isAssignTo) {
                if (!!$scope.personalData.EmailAddressContact) {
                    $scope.deficiencyEmailObj.Email = $scope.personalData.EmailAddressContact.ContactInfo;
                } else if (!!$scope.personalData.SecondaryEmailAddressContact) {
                    $scope.deficiencyEmailObj.Email = $scope.personalData.SecondaryEmailAddressContact.ContactInfo;
                }
                $scope.individualcommunicationlog.EmailTo = $scope.deficiencyEmailObj.Email;
            }
            $scope.getDeficiencyEmailBody();
        };

        $scope.getDeficiencyEmailBody = function () {
            var bodyContent = "";
            var itemNumber = 0;
            var templateId;

            if (!!$scope.isDeficiencyCall) {
                templateId = 29;
                angular.forEach($scope.allTabsDeficiencyData, function (tabDeficiency) {
                    if (!!tabDeficiency.individualDefeciency && !!tabDeficiency.individualDefeciency.IndividualDefeciencyDtlList && tabDeficiency.individualDefeciency.IndividualDefeciencyDtlList.length > 0) {
                        angular.forEach(tabDeficiency.individualDefeciency.IndividualDefeciencyDtlList, function (individualDefeciencyDtlItem) {
                            if (individualDefeciencyDtlItem.IsDefecient && !individualDefeciencyDtlItem.IsDefeciencyResolved && (!individualDefeciencyDtlItem.ResolvedByStaff || individualDefeciencyDtlItem.ResolvedByStaff.toLowerCase() != 'y')) {
                                itemNumber++;
                                bodyContent = bodyContent + itemNumber + " : " + individualDefeciencyDtlItem.DefeciencyReasonText +
                                    ((!!individualDefeciencyDtlItem.StaffComment) ? ("<br/> Notes : " + individualDefeciencyDtlItem.StaffComment + '<br/>') : '<br/>');
                            }
                        });
                    }
                });
            } else if (!!$scope.isDenyCall) {
                var templateId = 30;
                $scope.individualLicenseKindList = $scope.applicationInfo.individualLicenseKindList;
                angular.forEach($scope.individualLicenseKindList, function (individualLicenseKind) {
                    angular.forEach(individualLicenseKind.individualLicenseKindEndorsementList, function (individualLicenseKindEndorsement) {
                        if (individualLicenseKindEndorsement.EndorsementPathId == 4) {
                            angular.forEach($scope.applicationInfo.dnqReasonTypes, function (dnqReason) {
                                if (dnqReason.DNQId == individualLicenseKindEndorsement.DNQId) {
                                    individualLicenseKindEndorsement.DNQName = dnqReason.DNQName;
                                }
                            })
                            itemNumber++;
                            bodyContent = bodyContent + itemNumber + " : " + individualLicenseKind.LicenseKindName + "-" +
                                individualLicenseKindEndorsement.EndorsementName +
                                (!individualLicenseKindEndorsement.DNQName ? "" : (" : " + individualLicenseKindEndorsement.DNQName)) +
                                (!individualLicenseKindEndorsement.DNQReason ? "" : (" - " + individualLicenseKindEndorsement.DNQReason)) + "<br/>";
                        }
                    });
                });
            }
            if (!$scope.isAssignTo) {

                if(!!$scope.applicationInfo?.IndividualId)
                {
                    var dataPromises = [utilityService.getTemplateById(templateId),
                        backofficeIndividualPersonalService.individualBYIndividualId(sessionStorage.Key, $scope.IndividualId)
                        ];
        
                        $q.all(dataPromises).then(function (response) {
        
                            if (!!response[0].TemplateMessage) {
                                if (!!$scope.isDeficiencyCall)
                                    bodyContent = response[0].TemplateMessage.replace('#Deficiencies#', bodyContent);
                                else if (!!$scope.isDenyCall)
                                    bodyContent = response[0].TemplateMessage.replace('#DnqueuedEndorsements#', bodyContent);
                            }
        
                            if (!!response[1] && !!response[1].IndividualResponse && response[1].IndividualResponse.length > 0) {
                                $scope.personalInfo = response[1].IndividualResponse[0];
                                bodyContent = bodyContent.replace('#Fullname#', $scope.personalInfo.FullName);
                                bodyContent = bodyContent.replace('#Firstname#', $scope.personalInfo.FirstName);
                                bodyContent = bodyContent.replace('#Lastname#', $scope.personalInfo.LastName);
                                bodyContent = bodyContent.replace('#FullName#', $scope.personalInfo.FullName);
                                bodyContent = bodyContent.replace('#FirstName#', $scope.personalInfo.FirstName);
                                bodyContent = bodyContent.replace('#LastName#', $scope.personalInfo.LastName);
                                bodyContent = bodyContent.replace('#ApplicationNumber#', $scope.applicationInfo.ApplicationNumber);
                            }
                            $scope.deficiencyEmailObj.BodyContent = bodyContent;
                            $scope.individualcommunicationlog.CommunicationText = bodyContent;
                            $scope.individualcommunicationlog.SecureCommunicationText = bodyContent;
                            $scope.deficiencyEmailObj.Subject = response[0].TemplateSubject;
                            $scope.individualcommunicationlog.Subject = response[0].TemplateSubject;
                            $scope.loadCkEditor = true;
        
        
                            // if(!!sessionStorage.isBackOfficeUser && (sessionStorage.isBackOfficeUser == true || sessionStorage.isBackOfficeUser == 'true')) {
                            //     utilityService.getBoardSignatureTemplate()
                            //     .then(function (result) {
                            //         $scope.deficiencyEmailObj.BodyContent += result.TemplateMessage.replace('#UserFirst#', sessionStorage.FirstName).replace('#UserLast#', sessionStorage.LastName);
                            //         HideLoader();
                            //     });
                            // } else {
                            HideLoader();
                            // }
                        }, function (error) {
                            $scope.deficiencyEmailObj.BodyContent = bodyContent;
                            // if(!!sessionStorage.isBackOfficeUser && (sessionStorage.isBackOfficeUser == true || sessionStorage.isBackOfficeUser == 'true')) {
                            //     utilityService.getBoardSignatureTemplate()
                            //     .then(function (result) {
                            //         $scope.deficiencyEmailObj.BodyContent += result.TemplateMessage.replace('#UserFirst#', sessionStorage.FirstName).replace('#UserLast#', sessionStorage.LastName);
                            //         HideLoader();
                            //     });
                            // } else {
                            HideLoader();
                            // }
                        });
                }
                else if(!!$scope.applicationInfo?.ProviderId)
                {
                    templateId = 305;
                    var dataPromises = [utilityService.getTemplateById(templateId),
                        BackofficeProviderService.GetProviderById(sessionStorage.Key, $scope.applicationInfo?.ProviderId)
                        ];
        
                        $q.all(dataPromises).then(function (response) {
        
                            if (!!response[0].TemplateMessage) {
                                if (!!$scope.isDeficiencyCall)
                                    bodyContent = response[0].TemplateMessage.replace('#Deficiencies#', bodyContent);
                                else if (!!$scope.isDenyCall)
                                    bodyContent = response[0].TemplateMessage.replace('#DnqueuedEndorsements#', bodyContent);
                            }
        
                            if (!!response[1] && !!response[1].data.ProviderResponse ) {
                                $scope.personalInfo = response[1].data.ProviderResponse;
                                bodyContent = bodyContent.replace('#FirmName#', $scope.personalInfo.ProviderName);
                                bodyContent = bodyContent.replace('#Firstname#', '');
                                bodyContent = bodyContent.replace('#Lastname#', '');
                                bodyContent = bodyContent.replace('#FirmName#', $scope.personalInfo.ProviderName);
                                bodyContent = bodyContent.replace('#FirstName#', '');
                                bodyContent = bodyContent.replace('#LastName#', '');
                                bodyContent = bodyContent.replace('#ApplicationNumber#', $scope.applicationInfo.ApplicationNumber);
                            }
                            $scope.deficiencyEmailObj.BodyContent = bodyContent;
                            $scope.individualcommunicationlog.CommunicationText = bodyContent;
                            $scope.individualcommunicationlog.SecureCommunicationText = bodyContent;
                            $scope.deficiencyEmailObj.Subject = response[0].TemplateSubject;
                            $scope.individualcommunicationlog.Subject = response[0].TemplateSubject;
                            $scope.loadCkEditor = true;
        
        
                            // if(!!sessionStorage.isBackOfficeUser && (sessionStorage.isBackOfficeUser == true || sessionStorage.isBackOfficeUser == 'true')) {
                            //     utilityService.getBoardSignatureTemplate()
                            //     .then(function (result) {
                            //         $scope.deficiencyEmailObj.BodyContent += result.TemplateMessage.replace('#UserFirst#', sessionStorage.FirstName).replace('#UserLast#', sessionStorage.LastName);
                            //         HideLoader();
                            //     });
                            // } else {
                            HideLoader();
                            // }
                        }, function (error) {
                            $scope.deficiencyEmailObj.BodyContent = bodyContent;
                            // if(!!sessionStorage.isBackOfficeUser && (sessionStorage.isBackOfficeUser == true || sessionStorage.isBackOfficeUser == 'true')) {
                            //     utilityService.getBoardSignatureTemplate()
                            //     .then(function (result) {
                            //         $scope.deficiencyEmailObj.BodyContent += result.TemplateMessage.replace('#UserFirst#', sessionStorage.FirstName).replace('#UserLast#', sessionStorage.LastName);
                            //         HideLoader();
                            //     });
                            // } else {
                            HideLoader();
                            // }
                        });
                }
                
            } else {
                $scope.deficiencyEmailObj.Subject = 'Application assigned';
                $scope.individualcommunicationlog.Subject = 'Application assigned';
                $scope.deficiencyEmailObj.BodyContent = '<div></div>\n\n<div></div>';
                $scope.loadCkEditor = true;

                // if (!!sessionStorage.isBackOfficeUser && (sessionStorage.isBackOfficeUser == true || sessionStorage.isBackOfficeUser == 'true')) {
                //     utilityService.getBoardSignatureTemplate()
                //         .then(function (result) {
                //             $scope.deficiencyEmailObj.BodyContent += result.TemplateMessage.replace('#UserFirst#', sessionStorage.FirstName).replace('#UserLast#', sessionStorage.LastName);
                //             HideLoader();
                //         });
                // } else {
                HideLoader();
                // }
            }


        };

        $scope.getIndividualContact = function () {
            ShowLoader();
            if(!!$scope.applicationInfo?.IndividualId)
            {
                backofficeIndividualAdressService.individualContactBYIndividualId(sessionStorage.Key, $scope.IndividualId).then(function (response) {
                    $scope.personalContact = response.IndividualContactResponse;
                    assignContactsByContactTypeId();
                })
            }
            else if(!!$scope.applicationInfo?.ProviderId)
            {
                //var contactDataPromises = providerContactService.GetProviderById(sessionStorage.Key, $scope.applicationInfo.ProviderId);

                providerContactService.GetContactDetails(sessionStorage.Key, $scope.applicationInfo?.ProviderId).then(function(res) {
                    if (res.data) {
                        $scope.ContactDetailsList = res.data.ProviderContacts;
                        $scope.contactsLoaded = true;
                        processContactsData($scope.ContactDetailsList);
                        assignProviderContactsByContactTypeId();
                    }
                });
                // contactDataPromises.then(function (response) {
                //     if (!processDataExternally) {
                //         var contactsData = response.ProviderContacts;
                //         processContactsData(contactsData);
                //         assignProviderContactsByContactTypeId();
                //     }
                // });
            }
            
        };

        var processContactsData = function (contactsData) {
            if (!!contactsData && contactsData.length != 0) {
                angular.forEach(contactsData, function (contact, index) {
                    angular.forEach($scope.ContactTypeList, function (contactType, index) {
                        if (contactType.ContactTypeId == contact.ContactTypeId) {
                            contact.Desc = contactType.Desc;
                        }
                    });
                    angular.forEach($scope.ContactStatuses, function (contactStatus, index) {
                        if (contact.ContactStatusId == contactStatus.ContactStatusId) {
                            contact.ContactStatusName = contactStatus.ContactStatusName;
                        }
                    });
                });
            }
            $scope.contactsData = contactsData;
            $scope.personalContact = contactsData;
        };

        var getIndividualDeficiencyDetailItem = function (tabDeficiencyDataItem) {
            return {
                IndividualId: $scope?.IndividualId || sessionStorage?.ProviderId,
                IsDefecient: false,
                DefeciencyReasonId: tabDeficiencyDataItem.DeficiencyReasonId,
                DefeciencyReasonText: tabDeficiencyDataItem.DeficiencyReasonMessage,
                ResolvedByStaff: tabDeficiencyDataItem.ResolvedByStaff,
                DeficiencyReasonTypeId: tabDeficiencyDataItem.DeficiencyReasonTypeId,
                IsRecordBasedDeficiency: tabDeficiencyDataItem.IsRecordBasedDeficiency,
                VisibleInQueueMenuIds: tabDeficiencyDataItem.VisibleInQueueMenuIds,
                EditableInQueueMenuIds: tabDeficiencyDataItem.EditableInQueueMenuIds,
                AdditionalText: tabDeficiencyDataItem.AdditionalText,
                ExplanationRequired: tabDeficiencyDataItem.ExplanationRequired,
                IsActive: true,
            };
        };

        var getProviderDeficiencyDetailItem = function (tabDeficiencyDataItem) {
            return {
                IndividualId: $scope.IndividualId,
                IsDefecient: false,
                DefeciencyReasonId: tabDeficiencyDataItem.DeficiencyReasonId,
                DefeciencyReasonText: tabDeficiencyDataItem.DeficiencyReasonMessage,
                ResolvedByStaff: tabDeficiencyDataItem.ResolvedByStaff,
                DeficiencyReasonTypeId: tabDeficiencyDataItem.DeficiencyReasonTypeId,
                IsRecordBasedDeficiency: tabDeficiencyDataItem.IsRecordBasedDeficiency,
                VisibleInQueueMenuIds: tabDeficiencyDataItem.VisibleInQueueMenuIds,
                EditableInQueueMenuIds: tabDeficiencyDataItem.EditableInQueueMenuIds,
                AdditionalText: tabDeficiencyDataItem.AdditionalText,
                ExplanationRequired: tabDeficiencyDataItem.ExplanationRequired,
                IsActive: true,
                ProviderId: $scope.ProviderId,
            };
        };

        var getDeficiencyData = function (tab, deficiencyObj) {
            if (!deficiencyObj) {
                deficiencyObj = {
                    ApplicationId: $scope.ApplicationId,
                    IndividualId: $scope.IndividualId,
                    IndividualLicenseId: $scope.individualLicense.IndividualLicenseId,
                    WorkflowQueueName: "IntakeQ", //$rootScope.routeData.queueType,
                    TabName: tab.name,
                    SortOrder: tab.tabNumber,
                    IsActive: true,
                    IndividualDefeciencyDtlList: []
                };
                angular.forEach(tab.deficiencyData, function (tabDeficiencyDataItem) {
                    if (!tabDeficiencyDataItem.IsRecordBasedDeficiency) {
                        deficiencyObj.IndividualDefeciencyDtlList.push(getIndividualDeficiencyDetailItem(tabDeficiencyDataItem));
                    }
                })
            } else {
                var deficiencyDetails = []
                angular.forEach(tab.deficiencyData, function (tabDeficiencyDataItem) {
                    var deficiencyExists = false;
                    angular.forEach(deficiencyObj.IndividualDefeciencyDtlList, function (individualDefeciencyDtlItem) {
                        if (individualDefeciencyDtlItem.DefeciencyReasonId == tabDeficiencyDataItem.DeficiencyReasonId) {
                            deficiencyExists = true;

                            individualDefeciencyDtlItem.ResolvedByStaff = tabDeficiencyDataItem.ResolvedByStaff;
                            individualDefeciencyDtlItem.DeficiencyReasonTypeId = tabDeficiencyDataItem.DeficiencyReasonTypeId;
                            individualDefeciencyDtlItem.IsRecordBasedDeficiency = tabDeficiencyDataItem.IsRecordBasedDeficiency;
                            individualDefeciencyDtlItem.VisibleInQueueMenuIds = tabDeficiencyDataItem.VisibleInQueueMenuIds;
                            individualDefeciencyDtlItem.EditableInQueueMenuIds = tabDeficiencyDataItem.EditableInQueueMenuIds;
                            individualDefeciencyDtlItem.AdditionalText = tabDeficiencyDataItem.AdditionalText;
                            individualDefeciencyDtlItem.ExplanationRequired = tabDeficiencyDataItem.ExplanationRequired;
                            deficiencyDetails.push(individualDefeciencyDtlItem);
                        }
                    });
                    if (!deficiencyExists) {
                        if (!tabDeficiencyDataItem.IsRecordBasedDeficiency) {
                            deficiencyDetails.push(getIndividualDeficiencyDetailItem(tabDeficiencyDataItem));
                        }
                    }
                });
                deficiencyObj.IndividualDefeciencyDtlList = deficiencyDetails;
            }
            return deficiencyObj;
        };

        $scope.deficiencyDetailChanged = function () {
            $scope.$emit('deficiencyChanged', $scope.currentTab);
        };

        $scope.init();

        $scope.$on("SaveDeficiency", function (event, args) {
            if (!!args.updateDeficiencyStatus) {
                $scope.applicationInfo.DeficiencyStatusId = args.deficiencyStatusId;
            }
            $scope.saveDeficiency(args.isSaveAllTabs);
        });

        $scope.$on("invokeDeficiency", function (event, args) {
            if (!!args && !!args.tab && args.tab.tabNumber == $scope.currentTab.tabNumber) {
                if (!!$scope.currentTab.recordBasedDeficiencyData && $scope.currentTab.recordBasedDeficiencyData.length > 0) {
                    angular.forEach($scope.currentTab.recordBasedDeficiencyData, function (recordBasedDeficiencyItem) {
                        var individualDefeciencyDtlItem = $scope.deficiencyData.IndividualDefeciencyDtlList
                            .whereEquals(recordBasedDeficiencyItem.DeficiencyReasonId, 'DefeciencyReasonId')
                            .whereEquals(args.tableName, 'TableName')
                            .whereEquals(args.tableLinkId, 'TableLinkId')
                            .whereEquals(args.tableLinkIdColumnName, 'TableLinkIdColumnName').firstOrDefault();
                        if (!individualDefeciencyDtlItem) {
                            individualDefeciencyDtlItem = getIndividualDeficiencyDetailItem(recordBasedDeficiencyItem)
                            individualDefeciencyDtlItem.TableName = args.tableName;
                            individualDefeciencyDtlItem.TableLinkId = args.tableLinkId;
                            individualDefeciencyDtlItem.TableLinkIdColumnName = args.tableLinkIdColumnName;
                            if (!!args.tags && !!individualDefeciencyDtlItem.DefeciencyReasonText) {
                                for (var tagKey in args.tags) {
                                    individualDefeciencyDtlItem.DefeciencyReasonText = individualDefeciencyDtlItem.DefeciencyReasonText.replace(('{{' + tagKey + '}}'), args.tags[tagKey]);
                                }
                            }
                            $scope.deficiencyData.IndividualDefeciencyDtlList.push(individualDefeciencyDtlItem)
                        }
                    })
                    $scope.$apply();
                    utilityService.scrollToElementOffset("deficiencyContainer" + $scope.currentTab.name);
                }
            }
        });
    }
})();