﻿(function () {
    'use strict';
    
    angular.module('task.management')
        .directive('taskExecutionLogDetailsDirective', function () {
            return {
                restrict: 'EA',
                scope: {
                    taskScheduleExecutionId: '=taskScheduleExecutionId',
                    displaySearch: '=?',
                    loadExecutionLogFunction: '&?'
                },
                controller: "TaskExecutionLogDetailsController",
                templateUrl: "app/components/backoffice/administrator/task-management-dev/task-execution-log-details.html",
                link: function (scope) {
                    if (scope.loadExecutionLogFunction) {
                        scope.loadExecutionLogFunction({ loadMethod: scope.loadExecutionLog });
                    }
                }
            };
        });
})();
