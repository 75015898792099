(function () {
    'use strict'

    angular.module('app.core').factory('utilityService', utilityService);

    utilityService.$inject = ['$http', '$q', '$timeout', '$location', '$window', '$document', 'AppConfig', 'WebApiUrl'];

    function utilityService($http, $q, $timeout, $location, $window, $document, AppConfig, WebApiUrl) {
        this.applicationObject = {}

        var _scrollTop = function () {
            $timeout(function () {
                $('html, body').animate({
                    scrollTop: 0
                }, '100');
            })
        };

        var _scrollToElement = function (scrollElement) {
            var positionOfElement = $("#" + scrollElement).position();
            $timeout(function () {
                $('html, body').animate({
                    scrollTop: (!!positionOfElement ? positionOfElement.top : 0)
                }, '50');
            })
        };

        var _scrollToElementOffset = function (scrollElement) {
            var positionOfElement = $("#" + scrollElement).offset();
            $timeout(function () {
                $('html, body').animate({
                    scrollTop: positionOfElement.top
                }, '2000');
            })
        };

        var _parseDate = function (input) {
            if (input != undefined && input != null && input != "") {
                var parts = input.match(/(\d+)/g);
                return new Date(parts[0], parts[1] - 1, parts[2]);
            }
        };

        var _getDatesDiff = function (firstDate, secondDate, datepart) {
            firstDate = _getDateWithoutTime(firstDate);
            secondDate = _getDateWithoutTime(secondDate);
            // datepart = datepart.toLowerCase();
            var firstDateFullYear = firstDate.getFullYear();
            var secondDateFullYear = secondDate.getFullYear();
            var months = (firstDate.getFullYear() - secondDate.getFullYear()) * 12;
            var diffInMonths = firstDate.getMonth() - secondDate.getMonth();
            months += diffInMonths;
            if (firstDate.getDate() >= secondDate.getDate()) {
                months++;
            }
            return months;
        };

        var _compareDateswithoutTime = function (firstDate, secondDate) {
            firstDate = _getDateWithoutTime(firstDate);
            secondDate = _getDateWithoutTime(secondDate);
            return (firstDate.getTime() == secondDate.getTime());
        };

        var _checkIfDateGreater = function (firstDate, secondDate) {
            firstDate = _getDateWithoutTime(firstDate);
            secondDate = _getDateWithoutTime(secondDate);
            return (firstDate.getTime() > secondDate.getTime());
        };

        var _checkIfDateGreaterOrEqual = function (firstDate, secondDate) {
            firstDate = _getDateWithoutTime(firstDate);
            secondDate = _getDateWithoutTime(secondDate);
            return (firstDate.getTime() >= secondDate.getTime());
        };

        var _getDateWithoutTime = function (inputDate) {
            if (!angular.isDate(inputDate)) {
                try {
                    inputDate = new Date(inputDate);
                } catch (ex) {
                    console.log('invalid Date conversion' + ex);
                }
            }
            var inputDateWithoutTime = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate(), 0, 0, 0, 0);
            return inputDateWithoutTime;
        };

        var _getDateObject = function (inputDate) {
            if (!angular.isDate(inputDate)) {
                inputDate = new Date(inputDate);
            }
            return inputDate;
        };

        var _downloadExcelFile = function (data, headers, contentTypeValue, name) {
            var octetStreamMime = 'application/octet-stream';
            var success = false;

            // Get the filename from the x-filename header or default to "download.bin"
            var filename = headers['x-filename'] || name || 'download.bin';

            // Determine the content type from the header or default to "application/octet-stream"
            var contentType = headers['content-type'] || contentTypeValue || octetStreamMime;

            try {
                // Try using msSaveBlob if supported
                var blob = new Blob([data], {
                    type: contentType
                });
                if (navigator.msSaveOrOpenBlob)
                    navigator.msSaveOrOpenBlob(blob, filename);
                else if (navigator.msSaveBlob)
                    navigator.msSaveBlob(blob, filename);
                else {
                    // Try using other saveBlob implementations, if available
                    var saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob;
                    if (saveBlob === undefined) throw "Not supported";
                    saveBlob(blob, filename);
                }
                success = true;
            } catch (ex) {
                console.log(ex);
            }

            if (!success) {
                // Get the blob url creator
                var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
                if (urlCreator) {
                    // Try to use a download link
                    var link = document.createElement('a');
                    if ('download' in link) {
                        // Try to simulate a click
                        try {
                            // Prepare a blob URL
                            var blob = new Blob([data], {
                                type: contentType
                            });
                            var url = urlCreator.createObjectURL(blob);
                            link.setAttribute('href', url);

                            // Set the download attribute (Supported in Chrome 14+ / Firefox 20+)
                            link.setAttribute("download", filename);
                            link.setAttribute("target", '_blank');

                            // Simulate clicking the download link
                            var event = document.createEvent('MouseEvents');
                            event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                            link.dispatchEvent(event);
                            success = true;

                        } catch (ex) {
                            console.log(ex);
                        }
                    }

                    if (!success) {
                        // Fallback to window.location method
                        try {
                            // Prepare a blob URL
                            var blob = new Blob([data], {
                                type: octetStreamMime
                            });
                            var url = urlCreator.createObjectURL(blob);
                            window.location = url;
                            success = true;
                        } catch (ex) {
                            console.log(ex);
                        }
                    }
                }
            }
        };

        var _getServerDate = function (needDateObject) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Common/ServerDateTimeGet')
                .then(function (data) {
                    data = data.data;
                    if (!!needDateObject) {
                        data = _getDateWithoutTime(data.substring(0, 19));
                    }
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getConfigData = function (relativeFilePath) {
            var deferred = $q.defer();
            $http.get(AppConfig.ConfigServerUrl + AppConfig.DataFolderName + relativeFilePath)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _verifyAddress = function (address) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/VerifyAddress', address)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getDate = function (dateString) {
            var dt;
            if (!angular.isDate(dateString))
                dt = new Date(dateString);
            else
                dt = dateString;
            return ((dt.getMonth() + 1) + "/" + dt.getDate() + "/" + dt.getFullYear());
        };

        /*Start AuditVisitInfo function*/
        var _audit_visit_info = function (Params) {
            var data = {
                HostIPAddress: "127.0.0.1",
                PageName: Params.PageName,
                RequestUrl: window.location.href,
                RequestUrlReferrer: document.referrer,
                RequestBrowserTypeVersion: 'Type: ' + navigator.appCodeName + ', Version: ' + navigator.appVersion,
                SessionId: document.cookie,
                IndividualId: Params.IndividualId,
                EntityId: Params.EntityId,
                UserId: Params.UserId,
                Username: Params.Username,
                Platform: navigator.platform,
                MachineDeviceName: 'MachineDeviceName',
                DeviceId: 'DeviceId',
                AppDomainName: window.location.hostname,
                UserHostAddress: '127.0.0.l',
                UserHostName: 'localhost',
                IsJavascriptEnabled: true,
                IsCookieEnabled: navigator.cookieEnabled,
                IsCrawler: false,
                IsActiveXControlEnabled: false
            };
            this.SaveAuditVisitInfo(data);
        };

        var _get_audit_visit_info = function (data) {

        };

        var _save_audit_visit_info = function (data) {
            $http.post(WebApiUrl + 'Logging/AuditvisitInfoSave/' + sessionStorage.Key, data);
        };

        var _get_logdata = function (Params) {
            var data = {
                EventID: Params.EventID == undefined ? 0 : Params.EventID,
                Source: Params.Source,
                IndividualId: Params.EventID,
                EntityId: Params.EventID,
                UserId: Params.UserId,
                Username: Params.Username,
                Priority: Params.Priority == undefined ? 0 : Params.Priority,
                Severity: Params.Severity == undefined ? "Error" : Params.Severity,
                Title: Params.Title == undefined ? "PageName" : Params.Title,
                MachineName: "MachineName",
                AppDomainName: "AppDomainName",
                Application: Params.Application == undefined ? location.href : Params.Application,
                ProcessID: Params.ProcessID,
                ProcessName: Params.ProcessName,
                ThreadName: Params.ThreadName,
                Win32ThreadId: Params.Win32ThreadId,
                RequestURL: Params.RequestURL,
                RequestURLReferrer: Params.referrer,
                RequestBroswerTypeVersion: 'Type: ' + navigator.appCodeName + ', Version: ' + navigator.appVersion,
                UserAgent: navigator.userAgent,
                UserHostAddress: '127.0.0.1',
                UserHostName: 'localhost',
                Message: Params.Message == undefined ? "Code" : Params.Message,
                FormattedMessage: Params.Message == undefined ? "Code" : Params.Message,
                StackTrace: "",
                Exception: "",
                ElapsedMs: 0,
                SessionId: Params.SessionId,
                IsDebug: false,
                InputString: Params.InputString
            }
            _exception_log_save(data);
        };

        var _exception_log_save = function (data) {
            $http.post(WebApiUrl + 'Logging/LogSave/' + sessionStorage.Key, data);
        };
        /*End AuditVisitInfo function*/

        var _individualNameSave = function (reqData, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualNameSave/' + key + '?Source=' + AppConfig.Source, reqData).then(function (data) {
                data = data.data;
                deferred.resolve(data);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getTemplateById = function (templateId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Template/GetTemplateById?Source=' + AppConfig.Source + '&id=' + templateId).then(function (data) {
                data = data.data;
                deferred.resolve(data);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _b64toBlob = function b64toBlob(b64Data, contentType, sliceSize, name) {
            contentType = contentType || '';
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(b64Data);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }
            _downloadExcelFile(byteArrays, '[]', contentType, name);
            // var blob = new Blob(byteArrays, {type: contentType});
            // return blob;
        };

        var _downloadBase64File = function (hrefUrl, fileName) {
            var link = document.createElement('a');
            link.setAttribute('href', hrefUrl);

            // Set the download attribute (Supported in Chrome 14+ / Firefox 20+)
            link.setAttribute("download", fileName);

            // Simulate clicking the download link
            var event = document.createEvent('MouseEvents');
            event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
            link.dispatchEvent(event);
        };

        var _getCurrentUserInformation = function (key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'User/GetCurrentUserInformation/' + key)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getBoardSignatureTemplate = function () {
            //999 is the template for the board's signature
            var deferred = $q.defer();
            _getTemplateById(999).then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getDatePickerObject = function (format) {
            var datePicker = {
                fromDateOpened: false,
                toDateOpened: false,
                endDateOptions: {
                    minDate: null
                },
                startDateOptions: {}
            };
            if (!!format) {
                datePicker.format = format;
            }
            return datePicker;
        };

        var _isLocalServerAndDebuggingEnabled = function () {
            var isDebugMode = false;
            if ($location.search().hasOwnProperty('debug')) {
                isDebugMode = $location.search()['debug'];
            }
            return (($location.host().indexOf('localhost') != -1) && !!isDebugMode);
        };

        var _getApplicationVersion = function (applicationStartedDate) {
            var applicationVersion = 1;
            if (_checkIfDateGreater(applicationStartedDate, (new Date(2019, 8, 21)))) {
                applicationVersion = 2;
            }
            return applicationVersion;
        };

        return {
            scrollTop: _scrollTop,
            scrollToElement: _scrollToElement,
            scrollToElementOffset: _scrollToElementOffset,
            parseDate: _parseDate,
            getDatesDiff: _getDatesDiff,
            getDateWithoutTime: _getDateWithoutTime,
            compareDateswithoutTime: _compareDateswithoutTime,
            checkIfDateGreater: _checkIfDateGreater,
            checkIfDateGreaterOrEqual: _checkIfDateGreaterOrEqual,
            getServerDate: _getServerDate,
            getDateObject: _getDateObject,
            downloadExcelFile: _downloadExcelFile,
            getConfigData: _getConfigData,
            verifyAddress: _verifyAddress,
            getDate: _getDate,
            AuditVisitInfo: _audit_visit_info,
            SaveAuditVisitInfo: _save_audit_visit_info,
            GetAuditVisitInfo: _get_audit_visit_info,
            ExceptionLogSave: _exception_log_save,
            GetLogData: _get_logdata,
            individualNameSave: _individualNameSave,
            getTemplateById: _getTemplateById,
            b64toBlob: _b64toBlob,
            downloadBase64File: _downloadBase64File,
            getCurrentUserInformation: _getCurrentUserInformation,
            getBoardSignatureTemplate: _getBoardSignatureTemplate,
            getApplicationVersion: _getApplicationVersion,
            getDatePickerObject: _getDatePickerObject,
            isLocalServerAndDebuggingEnabled: _isLocalServerAndDebuggingEnabled
        };
    }
})();
