(function () {
    'use strict'

    angular.module('app.core')
        .directive('dashboardNotification', dashboardNotificationDirective);

    dashboardNotificationDirective.$inject = [];

    function dashboardNotificationDirective() {
        return {
            restrict: 'E',
            scope: {
                individualId: '=',
                providerId: '='
            },
            templateUrl: 'app/core/directive/dashboard-notification/dashboard-notification.html',
            controller: 'DashboardNotificationController'
        };
    }
})();