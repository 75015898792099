﻿angular.module('natApp').factory('LoginwemailService', ['$http', '$q', 'WebApiUrl', 'AppConfig', function ($http, $q, WebApiUrl, AppConfig) {
    return {
        Login: function (Email, Password, LoginWithoutEmail, AppDomain) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'User/Login?Source=' + AppConfig.Source, {
                Email: Email,
                Password: Password,
                LastName: '',
                AccessCode: '',
                LicenseNumber: '',
                LoginWithoutEmail: LoginWithoutEmail,
                AppDomain: (AppDomain ? AppDomain : '')
            }).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        },


        ForgetPassword: function (Email) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'User/ForgetPassword?Email=' + Email).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        },
        ValidateUserEmailandSendOTP: function (Email, OTPby) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'User/ValidateUserEmailandSendOTP?Email=' + Email + '&OTPby=' + OTPby + '&Source=' + AppConfig.Source).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        },
        ValidateStaffEmailandSendOTP: function (Email, OTPby) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'User/ValidateStaffEmailandSendOTP?Email=' + Email + '&OTPby=' + OTPby + '&Source=' + AppConfig.Source).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        },
        VerifyOTP: function (data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'User/VerifyOTP', data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        },
        ConfigurationGetbySettings: function (key, setting) {
            return $http.get(WebApiUrl + 'Configuration/ConfigurationGetbySettings/' + key + '?setting=' + setting);
        },

        UserRoleGetBYUserID: function (urlParm) {
            ApiPath = 'Users/UserRoleGetBYUserID' + urlParm;
            var deferred = $q.defer();
            $http.get(WebApiUrl + ApiPath).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        },
        AuditvisitInfoSave: function (params, data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Logging/AuditvisitInfoSave/' + params, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        },
        LogSave: function (params, data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Logging/LogSave/' + params, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        },
        ApplicationBYIndividualId: function (urlParm) {
            ApiPath = 'Individual/ApplicationBYIndividualId' + urlParm;
            var deferred = $q.defer();
            $http.get(WebApiUrl + ApiPath).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        },
        logout: function () {
            return $http.get(WebApiUrl + 'User/LogOut/' + sessionStorage.Key);
        }
    };
}]);
