(function() {
    'use strict'

    angular.module('app.core').factory('lookupService', lookupService)

    lookupService.$inject = ['$http', '$q', 'WebApiUrl']

    function lookupService($http, $q, WebApiUrl) {
        var lookupData = {};
        var _getLookUpDataByTypeId = function(lookupTypeID, key, getDataFromServer) {
            var deferred = $q.defer();
            if (!getDataFromServer && !!lookupData[lookupTypeID]) {
                deferred.resolve(lookupData[lookupTypeID]);
            } else {
                $http.get(WebApiUrl + 'Lookup/LookupGetBYLookupTypeID/' + key + '?LookupTypeID=' + lookupTypeID)
                    .then(function(data){ data = data.data;
                        if (data.Status && data.StatusCode == "00")
                            lookupData[lookupTypeID] = data;
                        deferred.resolve(data);
                    }, function(error) { error = error.data;
                        deferred.reject(error);
                    });
            }
            return deferred.promise;
        };
        return {
            getLookUpDataByTypeId: _getLookUpDataByTypeId

        }
    }
})();