
(function () {
    'use strict';
    angular
        .module('natApp')
        .controller("BusinessEntityController", ['$scope', '$state', 'FirmRenewalService', "$q", "WebApiUrl", "utilityService", "$rootScope", "initialApplicationstatusService", "licenseOutputService", "AppConfig", "BusinessEntityService", "BusinessEntitytatusService",
            function ($scope, $state, FirmRenewalService, $q, WebApiUrl, utilityService, $rootScope, initialApplicationstatusService, licenseOutputService, AppConfig, BusinessEntityService, BusinessEntitytatusService) {

                $scope.ProviderId = $scope.providerId;//19992;
                $scope.ApplicationId = $scope.applicationId;//4354;
                $scope.UserId = sessionStorage.UserID;

                var _key = sessionStorage.Key;
                var _uid = sessionStorage.UserID;
                $scope.paymentPersonalData = {};
                $scope.ProceedtoPaymentSectionEnable = $scope.paymentProcessed;
                $scope.staffReviewEnable = ($scope.IsBackoffice) ? $scope.paymentProcessed : false;
                $scope.ProviderAddress = {};
                $scope.acknowledgement = {};
                $scope.DisciplinaryData = {};
                //Payment section
                $scope.FeeDetails = {
                    renewalPeriod: 'Company Registration Fee',
                    feeTyep: 'Company Registration Fee',
                    applicationFee: 100,
                    lateFee: 0,
                    isOverridePaymentAmount: false,
                    totalFee: 75
                    //applicationFeeOverride:131,
                };

                $scope.init = function () {
                    InitializeValidator();
                    assignWatchonFee();
                    //if edit not required to generate it
                    // GenerateApplicationId();
                    GetProviderAffidavit();
                    getServerTime();
                    $scope.documentUpload = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSave/',
                        ApplicationId: $scope.ApplicationID,
                        ProviderId: $scope.ProviderId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FRM",
                        LoadByDocIdAndDocCode: true,
                        DocId: 4,
                        DocCode: "D1004",
                        LoadDocumentsWithoutBroadCast: true
                    };
                };
                var GetProviderAffidavit = function () {
                    FirmRenewalService.GetProviderAffidavit(sessionStorage.Key, $scope.ProviderId, $scope.ApplicationId).then(function (res) {
                        if (res.data.Status) {
                            var affiList = res.data.ProviderAffidavitSignatureList;
                            if (affiList && affiList.length > 0) {
                                var sRes = $.grep(affiList, function (i) {
                                    return (i.ContentItemLkId == 1);
                                })
                                if (sRes && sRes.length > 0) {
                                    $scope.DisciplinaryData = sRes[sRes.length - 1];
                                    $scope.acknowledgement.SignatureName = sRes[sRes.length - 1].SignatureName;
                                    $scope.acknowledgement.ProviderAffidavitSignatureId = sRes[sRes.length - 1].ProviderAffidavitSignatureId;
                                }
                            }
                        }
                    });
                };

                var SaveProviderAffidavit = function () {
                    var deferred = $q.defer();
                    if ($scope.DisciplinaryData) {
                        if (!$scope.DisciplinaryData.ProviderAffidavitId || $scope.DisciplinaryData.ProviderAffidavitId == 0) {
                            //if new
                            $scope.DisciplinaryData.Desc = 'DISCIPLINARY DECLARATION';
                            $scope.DisciplinaryData.ApplicationId = $scope.ApplicationId;
                            $scope.DisciplinaryData.ProviderId = $scope.ProviderId;
                            $scope.DisciplinaryData.ContentItemLkId = 1;
                            $scope.DisciplinaryData.IsActive = 1;
                            $scope.DisciplinaryData.IsDeleted = 0;
                            $scope.DisciplinaryData.CreatedBy = $scope.ProviderId;
                            //signature
                        } else {
                            //modify
                            $scope.DisciplinaryData.ModifiedBy = $scope.ProviderId;
                        }
                        if ($scope.DisciplinaryData && $scope.acknowledgement && $scope.acknowledgement.SignatureName) {
                            var req = {
                                ContentItems: [$scope.DisciplinaryData],
                                Provideraffidavitsignature: $scope.acknowledgement
                            };
                            FirmRenewalService.SaveProviderAffidavit(sessionStorage.Key, $scope.ProviderId, $scope.ApplicationId, _uid, [req]).then(function (res) {
                                if (res.data.Status) {
                                    GetProviderAffidavit();
                                    deferred.resolve(res);
                                } else {
                                    deferred.reject(res);
                                }
                            }, function (res) {
                                deferred.reject(res);
                            });
                        } else {
                            deferred.resolve({});
                        }
                    } else {
                        deferred.reject('');
                    }
                    return deferred.promise;
                };


                var getServerTime = function () {
                    //  $scope.ApplicationDate = new Date();
                    utilityService.getServerDate().then(function (res) {
                        if (res && !$scope.ApplicationDate) {
                            debugger
                            $scope.ApplicationDate = res;
                        }
                    });
                };
                var assignWatchonFee = function () {
                    $scope.$watch('FeeDetails', function (n, o) {
                        calculateTotalFee()
                    }, true)
                };
                var calculateTotalFee = function () {
                    $scope.FeeDetails.totalFee = (($scope.FeeDetails.lateFee ? $scope.FeeDetails.lateFee : 0) * 1) + (($scope.FeeDetails.applicationFee ? $scope.FeeDetails.applicationFee : 0) * 1)
                };
                $scope.natValidation = {};

                var validateForm = function (form) {
                    //validate 
                    //Form custom
                    $scope.natValidation.errorMessages = [];
                    if (!!form && $scope.natValidation) {
                        if ($scope.natValidation.errorMessages == undefined) {
                            $scope.natValidation.errorMessages = [];
                        }
                        form.$submitted = true;
                        var errorMessages = angular.copy(form.errorMessages);
                        angular.forEach(errorMessages, function (errorMessage, index) {
                            $scope.natValidation.errorMessages.push(errorMessage);
                        });
                    }

                    if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                        $scope.natValidation.showError = true;
                        $scope.natValidation.isValid = false;
                        return false;
                    } else {
                        $scope.natValidation.showError = false;
                        $scope.natValidation.isValid = true;
                        return true;
                    }

                };

                $scope.onformsubmitsuccess = function (provider) {
                    //$scope.ProviderId = provider.ProviderId;
                    $scope.ProviderId = provider.ProviderId;
                    setTimeout(function () {
                        $scope.$broadcast("SaveBEOtherInfo", {});
                    }, 200);
                };

                var InitializeValidator = function () {
                    $scope.validator = {
                        beInformation: {},
                        beAdditionalWyo: {},
                        beDocument: {},
                        beResponsible: {}
                    };
                };

                $scope.ProceedtoPaymentclick = function (form) {

                    $scope.PayandSubmitbtnClicked = false;
                    SubmmitFirmApplication(form);


                };

                $scope.PayandSubmitbtnClick = function (isAutoApproval) {
                    $scope.PayandSubmitbtnClicked = true;
                    SubmmitFirmApplication(isAutoApproval);
                };

                $scope.IsAllTheSectionSaved = false;
                var SubmmitFirmApplication = function (isAutoApproval, form) {

                    BusinessEntitytatusService.resetAll($scope.PayandSubmitbtnClicked);
                    $scope.$broadcast("SaveBusinessEntity", {});
                    ShowLoader();
                    SaveProviderAffidavit();
                    var intervalDataSave = setInterval(function () {
                        var allTabsProcessedStatus = BusinessEntitytatusService.getAllTabsProcessedStatus();
                        // console.log('allTabsStatus' + allTabsProcessedStatus);
                        if (!!allTabsProcessedStatus) {
                            clearInterval(intervalDataSave);
                            var allTabsStatus = BusinessEntitytatusService.getAllTabStatus();
                            //  console.log('allTabsStatus', allTabsStatus, $scope.PayandSubmitbtnClicked);
                            $scope.IsAllTheSectionSaved = allTabsStatus;
                            if (allTabsStatus && (!form || validateForm(form))) {
                                if (!$scope.PayandSubmitbtnClicked) {
                                    //     console.log(0);
                                    $scope.ProceedtoPaymentSectionEnable = true;
                                    HideLoader();
                                } else {
                                    //  console.log(1);
                                    $scope.PayandSubmitbtnClicked = false;
                                    $scope.$broadcast("PayandSubmit", {});
                                    //Wait for payment submit and check whether payment is succeed 
                                    //There may be chance to payment failure.Only the form should submit only after payment success
                                    var paymentStatus = '';
                                    var interval = setInterval(function () {
                                        paymentStatus = BusinessEntitytatusService.getPaymetStatus();
                                        if (!!paymentStatus && paymentStatus != "waiting") {
                                            clearInterval(interval);
                                            $rootScope.ProcessLoaderExternally = false;
                                            if (paymentStatus == "success") {
                                                var stts = 2;
                                                $q.all([saveApplicationStatusLk($scope.ApplicationId, stts), saveApplicationStatusChangeLog($scope.ApplicationId, stts), saveApplicationStatus($scope.ApplicationId, stts)]).then(function () {

                                                    if ($scope.IsBackoffice)
                                                        $scope.staffReviewEnable = true;
                                                    else
                                                        $state.go('app.EntityPaymentConfirmation', { paymentMethod: $rootScope.SelectedPaymentMethod });
                                                });
                                            }
                                            if (paymentStatus == "error") {
                                                HideLoader();
                                            }
                                        }
                                    }, 500);
                                }
                            } else {
                                HideLoader();
                                $scope.ProceedtoPaymentSectionEnable = false;
                                $scope.$apply();
                                $('#applicationIncompleteModal').modal('show');
                            }
                        }
                    }, 500);
                };

                //get ApplicationID
                var GenerateApplicationId = function () {
                    var reqData = {
                        ApplicationTypeId: 10,
                        ApplicationStatusId: 8,
                        ApplicationSubmitMode: 'O',
                        //ApplicationStatusReasonId: 0,
                        GoPaperless: true,
                        IsDeleted: 0,
                        IsActive: 1,
                        IsArchive: 0,
                        //ApplicationInternalStatusId: 0
                    };
                    ShowLoader();
                    BusinessEntityService.GenerateApplicationId(_key, 0, $scope.UserId, reqData).then(function (res) {
                        if (res.data.Status && res.data.ApplicationId) {
                            $scope.ApplicationId = res.data.ApplicationId;
                            $scope.ApplicationNumber = res.data.ApplicationNumber;
                        }
                        HideLoader();
                    }, function () {
                        HideLoader();
                    });
                };

                var saveApplicationStatusLk = function (applicationId, applicationStatus) {
                    var deferred = $q.defer();
                    var reqData = {
                        ApplicationId: applicationId,
                        ApplicationTypeId: 10,
                        ApplicationStatusId: applicationStatus,
                        ApplicationStatusReasonId: 0,
                        IsActive: true,
                        IsDeleted: false,
                        IsArchive: false,
                        CreatedBy: _uid,
                        ApplicationInternalStatusReasonText: '',
                        ApplicationInternalStatusId: 0,
                        HighPriority: false
                    };
                    BusinessEntitytatusService.saveApplicationStatusLk(_key, _uid, reqData)
                        .then(function (response) {
                            deferred.resolve(response);
                        }, function (data) {
                            deferred.reject(data);
                            showStatusMessage(data, "error");
                        });
                    return deferred.promise;
                };

                var saveApplicationStatusChangeLog = function (applicationId, applicationStatus) {
                    var deferred = $q.defer();
                    var reqData = {
                        ApplicationId: applicationId,
                        ApplicationStatusReasonId: 0,
                        ApplicationNumber: '',
                        ApplicationTypeId: 10,
                        ApplicationStatusId: applicationStatus,
                        ApplicationSubmitMode: ($scope.IsBackoffice ? 'B' : 'O'),
                        ApplicationStatusChangedBy: _uid,
                        ReferenceNumber: '',
                        LicenseTypeId: 0,
                        HighPriority: false,
                        ApplicationInternalStatusId: 0,
                        ApplicationInternalStatusReasonText: ''
                    };
                    BusinessEntitytatusService.saveApplicationStatusChangeLog(_key, _uid, reqData)
                        .then(function (response) {
                            deferred.resolve(response);
                        }, function (data) {
                            deferred.reject(data);
                            showStatusMessage(data, "error");
                        });
                    return deferred.promise;
                };

                var saveApplicationStatus = function (applicationId, applicationStatus) {
                    var deferred = $q.defer();
                    BusinessEntitytatusService.saveApplicationStatus(applicationId, _key, applicationStatus)
                        .then(function (response) {
                            deferred.resolve(response);
                        }, function (data) {
                            deferred.reject(data);
                            HideLoader();
                            $scope.isSavedSuccessFully = false;
                        });
                    return deferred.promise;
                };

                $scope.CancelApplicationClick = function () {
                    if ($scope.IsBackoffice) {
                        $scope.closeform({ item: $scope.callbackitem });
                    }
                };

            }]);
})();