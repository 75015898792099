(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .directive('reciprocityLogic', ['$q', 'ReciprocityService', 'PromiseHandlers', 'Messages', ReciprocityLogic]);

    function ReciprocityLogic($q, ReciprocityService, PromiseHandlers, Messages) {
        var _key = sessionStorage.Key;

        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                var getAffidavitSignature = function (affidavit) {
                    return {
                        Name: '',
                        SignatureName: affidavit.SignatureName
                    };
                };

                $scope.viewConfig = {
                    showAddBlock: false,
                    isReciprocityEdit: false
                };

                $scope.loadContent = function () {
                    var deferred = $q.defer();
                    try {
                        $q.all([
                            ReciprocityService.getContent(_key, $scope.config.Content.QuestionContentCode),
                            ReciprocityService.getContent(_key, $scope.config.Content.BasisContentCode),
                            ReciprocityService.getStates(_key),
                            ReciprocityService.getExams(_key)
                        ])
                            .then(function (data) {
                                if (data[0].Status) {
                                    $scope.content.affidavitQuestion = data[0].ContentItemLk.firstOrDefault();
                                } else {
                                    //   deferred.reject(data[0]);
                                }

                                if (data[1].Status) {
                                    $scope.content.basis = data[1].ContentItemLk.firstOrDefault();
                                } else {
                                    //  deferred.reject(data[1]);
                                }

                                if (data[2].Status) {
                                    $scope.content.louisianaState = data[2].State.whereEquals('AL', 'StateCode').firstOrDefault();
                                    $scope.content.stateList = data[2].State;
                                } else {
                                    deferred.reject(data[2]);
                                }

                                if (data[3].Status) {
                                    $scope.content.examNames = data[3].RefExamNameGetList;
                                } else {
                                    deferred.reject(data[3]);
                                }

                                deferred.resolve(data);
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    return deferred.promise;
                };

                $scope.loadIndividual = function () {
                    var deferred = $q.defer();

                    try {
                        ReciprocityService.getIndividual(_key, $scope.individualId)
                            .then(function (data) {
                                if (data.Status) {
                                    $scope.current.individual = data.IndividualResponse.firstOrDefault();

                                    deferred.resolve(data);
                                } else {
                                    deferred.reject(data);
                                }
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.loadAffidavits = function () {
                    var deferred = $q.defer();

                    try {
                        ReciprocityService.getAffidavits(_key, $scope.individualId, $scope.applicationId, $scope.individualLicense.IndividualLicenseId, $scope.config.Content.AffidavitTabSectionId)
                            .then(function (data) {
                                if (data.Status && !!$scope.content.affidavitQuestion) {
                                    $scope.current.affidavit = data.IndividualAffidavitResponseList.whereEquals($scope.content.affidavitQuestion.ContentItemLkId, 'ContentItemLkId').firstOrDefault();

                                    deferred.resolve(data);
                                } else {
                                    deferred.reject(data);
                                }
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.saveAffidavits = function () {
                    var deferred = $q.defer();

                    try {
                        ReciprocityService.saveAffidavits(_key, $scope.individualId, $scope.applicationId, [$scope.current.affidavit], $scope.current.signature, sessionStorage.UserID)
                            .then(function (data) {
                                if (data.Status) {
                                    deferred.resolve(data);
                                } else {
                                    deferred.reject(data);
                                }
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.stateChanged = function () {
                    var state = $scope.content.stateList.whereEquals($scope.current.reciprocity.ReciprocityStateId, 'StateId').firstOrDefault();
                    if (!!state) {
                        $scope.current.reciprocity.ReciprocityStateCd = state.StateCode;
                    }
                };

                $scope.reciprocityChanged = function () {
                    if (!$scope.current.reciprocity.Reciprocity) {
                        $scope.current.reciprocity.ReciprocityExamId = 1;
                        $scope.current.reciprocity.ReciprocityStateId = $scope.content.louisianaState.StateId;
                        $scope.current.reciprocity.ReciprocityStateCd = $scope.content.louisianaState.StateCode;
                    } else {
                        $scope.current.reciprocity.ReciprocityExamId = null;
                        $scope.current.reciprocity.ReciprocityStateId = null;
                        $scope.current.reciprocity.ReciprocityStateCd = null;
                    }
                };

                $scope.$loadContent = function () {
                    return [
                        $scope.loadContent()
                    ];
                };

                // $scope.$loadData = function () {
                //     return [
                //         $scope.loadAffidavits(),
                //         $scope.loadReciprocities(),
                //         $scope.loadIndividual()
                //     ];
                // };

                $scope.LicenseTypeChanged = function (LicenseApplicationForId) {
                    var licenseTypeId = 0;
                    angular.forEach($scope.LicenseList, function (licenseItem) {
                        if (licenseItem.LicenseApplicationForId == LicenseApplicationForId) {
                            $scope.isReciprocity = licenseItem.Reciprocity;
                            licenseTypeId = licenseItem.LicenseTypeId;
                        }
                    });
                    $scope.current.reciprocity.ReciprocityStateCd = null;
                    $scope.current.reciprocity.LicenseTypeId = licenseTypeId;
                }

                $scope.$dataLoaded = function (data) {
                    //Finish setting up affidavit
                    // $scope.current.signature = getAffidavitSignature($scope.current.affidavit);

                    //Finish setting up reciprocity
                    $scope.current.reciprocity = $scope.reciprocities.whereEquals($scope.applicationId, 'ApplicationId').firstOrDefault();
                    if (!$scope.current.reciprocity) {
                        $scope.current.reciprocity = $scope.getNewReciprocity();
                    }

                    // if ($scope.current.reciprocity.StateCode=='LA') {
                    //     $scope.current.reciprocityChoice == 1;
                    // } else if (!!$scope.current.reciprocity.StateCode) {
                    //     $scope.current.reciprocityChoice == 2;
                    // }
                };

                $scope.addEditReciprocity = function (isReciprocityEdit, reciprocity) {
                    $scope.isReciprocity = (!!reciprocity) ? reciprocity.Reciprocity : false;
                    ShowLoader("Please wait");
                    $scope.viewConfig.isReciprocityEdit = isReciprocityEdit;
                    $scope.viewConfig.showAddBlock = true;
                    if (!$scope.viewConfig.isReciprocityEdit) {
                        $scope.current.reciprocity = $scope.getNewReciprocity();
                    } else {
                        $scope.current.reciprocity = angular.copy(reciprocity);
                    }
                    HideLoader();
                };

                $scope.clearValidation = function () {
                    $scope.hideStatusMsg();
                };

                $scope.closeAddEditReciprocity = function () {
                    $scope.viewConfig.showAddBlock = false;
                    $scope.clearValidation();
                };

                $scope.validateForm = function (currentForm) {
                    $scope.natValidation.errorMessages = [];
                    if (!!currentForm) {
                        currentForm.$submitted = true;
                        $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
                    }

                    if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                        $scope.natValidation.showError = true;
                        $scope.natValidation.isValid = false;
                        return false;
                    } else {
                        $scope.natValidation.showError = false;
                        $scope.natValidation.isValid = true;
                        return true;
                    }
                };

                $scope.validateTab = function () {
                    $scope.natValidation.errorMessages = [];
                    if (!$scope.reciprocities || $scope.reciprocities.length <= 0) {
                        $scope.natValidation.errorMessages.push({ errorMessage: Messages.General.Errors.OneLicensePermit });
                    }
                    if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                        $scope.natValidation.showError = true;
                        $scope.natValidation.isValid = false;
                        return false;
                    } else {
                        $scope.natValidation.showError = false;
                        $scope.natValidation.isValid = true;
                        return true;
                    }

                }

                $scope.saveReciprocity = function (reciprocityForm) {
                    $scope.hideStatusMsg();
                    reciprocityForm.$submitted = true;
                    if ($scope.validateForm(reciprocityForm)) {
                        ShowLoader("Please wait");
                        $scope.saveReciprocityRecord();
                    }
                };

                $scope.saveReciprocityRecord = function () {
                    var reciprocity = angular.copy($scope.current.reciprocity);
                    $scope.saveReciprocities([reciprocity]).then(function (response) {
                        if (response.Status) {
                            $scope.loadReciprocities();
                            $scope.closeAddEditReciprocity();
                            $scope.showStatusMsg('+', response.Message);
                        } else {
                            $scope.showStatusMsg('-', response.Message);
                        }
                    }, function (data) {
                        HideLoader();
                        $scope.showStatusMsg('-', data.message);
                    });
                };


                $scope.deleteReciprocity = function (reciprocity) {
                    if (confirm(Messages.General.Confirm.DeleteRecord)) {
                        ShowLoader("Please wait");
                        $scope.current.reciprocity = angular.copy(reciprocity);
                        $scope.current.reciprocity.IsActive = false;
                        $scope.current.reciprocity.IsDeleted = true;
                        $scope.saveReciprocityRecord();
                    }
                };


                $scope.$saveData = function () {
                    return [
                        // $scope.saveAffidavits(),
                        $scope.saveReciprocities([])
                    ];
                };
            }
        };
    }
})();