(function () {
  'use strict'

  angular.module('app.InitialApplication')
    .factory('initialApplicationProfessionalReferencesService', initialApplicationProfessionalReferencesService);

  initialApplicationProfessionalReferencesService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig'];

  function initialApplicationProfessionalReferencesService($http, $q, WebApiUrl, AppConfig) {

    var _getProfessionalReferences = function (individualId) {
      var deferred = $q.defer();


      deferred.resolve([{
        FirstName: "Test First Name",
        MiddleName: "Test Middle Name",
        LastName: "Test Last Name",
        Email: "Test Email",
        PhoneNumber: "Test Phone Number"
      }]);

      return deferred.promise;
    };
    var _getIndividualReference = function (individualId, ApplicationId, key) {
      var deferred = $q.defer();
      $http.get(WebApiUrl + 'Individual/GetIndividualReference/' + key + '?IndividualId=' + individualId + "&ApplicationId=" + ApplicationId)
        .then(function(data){ data = data.data;
          deferred.resolve(data);
        }, function(error) { error = error.data;
          deferred.reject(error);
        });
      return deferred.promise;
    };


    var _saveIndividualReference = function (IndividualReference, key) {
      var deferred = $q.defer();
      $http.post(WebApiUrl + 'Individual/SaveIndividualReference/' + key + '?Source=' + AppConfig.Source, IndividualReference)
        .then(function(data){ data = data.data;
          deferred.resolve(data);
        }, function(error) { error = error.data;
          deferred.reject(error);
        });
      return deferred.promise;
    };
    var _getReferencedisciplinetypeid = function (key) {
      var deferred = $q.defer();
      $http.get(WebApiUrl + 'TypeValues/RefTablesGetAll/' + key + '?tableKey=referencedisciplinetypeid')
        .then(function(data){ data = data.data;
          deferred.resolve(data);
        }, function(error) { error = error.data;
          deferred.reject(error);
        });
      return deferred.promise;
    };

    return {
      getProfessionalReferences: _getProfessionalReferences,
      getIndividualReference: _getIndividualReference,
      saveIndividualReference: _saveIndividualReference,
      getReferencedisciplinetypeid: _getReferencedisciplinetypeid
    };
  }
})();