(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeIndividualEducationService', backofficeIndividualEducationService)

    backofficeIndividualEducationService.$inject = ['$http', '$q', 'WebApiUrl']

    function backofficeIndividualEducationService($http, $q, WebApiUrl) {

        var _individualEducationBYIndividualId = function (Key, IndividualId, applicationId, userId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualEducationBYIndividualId/' + Key + '?IndividualId=' + IndividualId +
                '&applicationId=' + applicationId +
                '&userId=' + userId
            ));
        };

        var _individualCECourseSave = function (individualCECourse, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualCECourseSave/' + key, individualCECourse));
        };

        // var _individualEducationSave = function (individualId, applicationId, userId, individualEducation, key) {
        //     return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualEducationSave/' + key +
        //         "?individualId=" + individualId + "&applicationId=" + applicationId + "&UserId=" + userId, individualEducation));
        // };

        var _getAllProvider = function (Key) {
            return returnDefferedResult($http.get(WebApiUrl + "Provider/GetAllProvider/" + Key));
        };

        var _saveProvider = function (Key, ApplicationId, UserId, objData) {
            return returnDefferedResult($http.post(WebApiUrl + "Provider/ProviderSave/" + Key + "?ApplicationId=" + ApplicationId + "&UserId=" + UserId, objData));
        };

        var _providerAddressSave = function (Key, objData) {
            return returnDefferedResult($http.post(WebApiUrl + "Provider/ProviderAddressSave/" + Key, objData));
        };
        var _providerContactsSave = function (Key, objData) {
            return $http.post(vm.baseUrl + "/Provider/ProviderContactsSave/" + Key, objData);
        };
        var _getProviderAddressByProviderId = function (Key, ID) {
            return returnDefferedResult($http.get(WebApiUrl + "Provider/ProviderAddressByProviderId/" + Key + "/?ProviderAddressId=" + ID));
        };

        var _getProviderContactByProviderId = function (Key, ID) {
            return returnDefferedResult($http.get(WebApiUrl + "Provider/ProviderContactByProviderId/" + Key + "/?ProviderContactId=" + ID));
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        var _individualEducationSave = function (individualId, applicationId, userId, individualEducation, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualEducationSave/' + key +
                "?individualId=" + individualId + "&applicationId=" + applicationId + "&UserId=" + userId, individualEducation)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getEducation = function (individualId, applicationId, userId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualEducationBYIndividualId/' + key + '?IndividualId=' + individualId +
                '&applicationId=' + applicationId +
                '&userId=' + userId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;

        };

        return {
            individualEducationBYIndividualId: _individualEducationBYIndividualId,
            individualEducationSave: _individualEducationSave,
            individualCECourseSave: _individualCECourseSave,
            getAllProvider: _getAllProvider,
            saveProvider: _saveProvider,
            providerAddressSave: _providerAddressSave,
            providerContactsSave: _providerContactsSave,
            getProviderAddressByProviderId: _getProviderAddressByProviderId,
            getProviderContactByProviderId: _getProviderContactByProviderId
        }
    }
})();