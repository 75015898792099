(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .directive('individualNotes', individualNotesDirective);

    individualNotesDirective.$inject = [];

    function individualNotesDirective() {
        return {
            restrict: 'E',
            scope: {
                individualId: '='
            },
            templateUrl: 'app/components/backoffice/individual/notes/notes.html',
            controller: 'IndividualNotesController'
        };
    }
})();