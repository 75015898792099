(function () {
    'use strict'

    angular.module('app.core')
        .factory('dcaIService', dcaIService)

    dcaIService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function dcaIService($http, $q, WebApiUrl, AppConfig) {
        return {
            generateLicenseTransferFile: _generateLicenseTransferFile,
            updateAddress: _updateAddress,
            updateName: _updateName,
            updateEmail: _updateEmail,
            updatePhone: _updatePhone,
            getLicenseTypeView: _getLicenseTypeView,
            linkExistingUser: _linkExistingUser,
            dojMatch: _dojMatch,
            getRenewalProcessStatus: _getRenewalProcessStatus,
            updateRenewalProcessStatus: _updateRenewalProcessStatus,
            getCaLicFilePrimStatCde:_getCaLicFilePrimStatCde,
            getCaLicFileSecStatCde:_getCaLicFileSecStatCde,
            getPceDate:_getPceDate,
            updateRenewalProcessStatusForRetired: _updateRenewalProcessStatusForRetired,
            updateEmailForFirms: _updateEmailForFirms,
            updateAddressForFirms: _updateAddressForFirms,
            updatePhoneForFirms: _updatePhoneForFirms
        };

        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function _generateLicenseTransferFile(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/GenerateLicenseTransferFile/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _updateAddress(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/UpdateAddress/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _updateAddressForFirms(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/UpdateAddressForFirms/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _updateName(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/UpdateName/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _updateEmail(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/UpdateEmail/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _updateEmailForFirms(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/UpdateEmailForFirms/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _updatePhoneForFirms(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/UpdatePhoneForFirms/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _updatePhone(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/UpdatePhone/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _getLicenseTypeView(key) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/GetLicenseTypeView/' + key + '?Source=' + AppConfig.Source));
        };

        function _linkExistingUser(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/LinkExistingUser/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _dojMatch(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/DOJMatch/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _getRenewalProcessStatus(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/GetRenewalProcessStatus/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _updateRenewalProcessStatus(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/UpdateRenewalProcessStatus/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _getCaLicFilePrimStatCde(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/LicenseFileStatCode/' + key, reqData));
        };

        function _getCaLicFileSecStatCde(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/GetCaLicFileSecStatCde/' + key, reqData));
        };

        function _getPceDate(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/GetCaLicFileByIsn/' + key, reqData));
        };

        function _updateRenewalProcessStatusForRetired(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'DCAIService/UpdateRenewalProcessStatusForRetired/' + key, reqData));
        };
    }
})();
