(function() {
    'use strict'

    angular.module('reporting.QueryReporting')
    .directive('queryMaker', [QueryMakerDirective]);

    function QueryMakerDirective() {
        return {
            restrict: 'E',
            scope: {
                currentQuery: '=',
                showMenu: '=',
                rightPanelTitle: '=',
                rightPanelLoaderMethod: '@',
                utilityMethods: '=?',
                showStatusMsg: '&',
            },
            transclude: true,
            templateUrl: 'app/components/report/query-reporting/query-maker.html',
            controller: 'QueryMakerController',
            link: function(scope, element, attr) {

            }
        };
    }
})();