angular.module('natApp').factory('FrimForgotUsernameService', ['$http', '$q', 'WebApiUrl', 'AppConfig', function ($http, $q, WebApiUrl, AppConfig) {
    return {
        CheckLoginUserName: function (data, Source) {
            ApiPath = 'User/CheckLoginUserName'+ '?Source=' + Source;
            var deferred = $q.defer();
            $http.post(WebApiUrl + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        }
    };
}]);
