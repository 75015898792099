(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintCorrespondence', [ComplaintCorrespondence]);

    function ComplaintCorrespondence() {
        return {
            restrict: 'E',
            scope: {
                complaintId: '=',
                complaintInvolvedPartyId: '=?',

                //Supported: 'email', 'conversation'
                //- Email (Default): Message contents are sent over email
                //- Conversation (Not yet supported): The conversation does not take place over email 
                //  * Notifications are sent to recipient, but contents are only readable in system
                mode: '=?', 
                complaint: '=?',

                readOnly: '=?',
                configName: '@?'
            },
            link: function ($scope, element, attrs) {

            },
            templateUrl: 'app/components/backoffice/enforcement/complaint-correspondence/complaint-correspondence.html',
            controller: 'ComplaintCorrespondenceController'
        };
    }
})();