(function () {
    'use strict'

    angular.module('app.report')
        .directive('cannedReportsLogic', [CannedReportsLogic]);

    function CannedReportsLogic() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.onBeforeTableTemplateLoaded = function () {
                    if (!!$scope.configName && $scope.configName == 'CasesByAllegationType') {
                        $scope.allegationTypeMetrics = [];
                    }
                    if (!!$scope.configName && $scope.configName == 'CasesByStandardOfConduct') {
                        $scope.standardOfConductMetrics = [];
                    }
                };

                $scope.onAfterTableTemplateLoaded = function () {
                    if (!!$scope.configName && $scope.configName == 'CasesClosed') {
                        $scope.average = 0;
                    }
                };

                $scope.onCannedReportExecuting = function (request) {
                    if (!!$scope.configName && ['DistrictInfo'].includes($scope.configName)) {
                        if (!!request && !!request.FormValues && !!request.FormValues.district && typeof(request.FormValues.district) == 'object') {
                            request.FormValues._DistrictId = request.FormValues.district.DistrictId;
                        } else {
                            request.FormValues._DistrictId = 0;
                        }
                    }
                    if (!!$scope.configName && ['InstitutionInfo'].includes($scope.configName)) {
                        if (!!request && !!request.FormValues && !!request.FormValues.institution && typeof(request.FormValues.institution) == 'object') {
                            request.FormValues._InstitutionId = request.FormValues.institution.InstitutionId;
                        } else {
                            request.FormValues._InstitutionId = 0;
                        }
                    }

                    delete request.FormValues.district;
                    delete request.FormValues.institution;
                };

                $scope.onCannedReportExecuted = function (request) {
                    if (!!$scope.configName && $scope.configName == 'CasesClosed' && !!$scope.results.reportData && $scope.results.reportData.length > 0) {
                        $scope.average = $scope.results.reportData.sum('Days to Close the Case') / $scope.results.reportData.length;
                    }
                    if (!!$scope.configName && $scope.configName == 'CasesByAllegationType' && !!$scope.results.reportData && $scope.results.reportData.length > 0) {
                        $scope.allegationTypeMetrics = [];
                        angular.forEach($scope.results.reportData, function (data) {
                            if (!!data.Allegation) {
                                var allegationExists = $scope.allegationTypeMetrics.whereEquals(data.Allegation, 'AllegationType');
                                if (allegationExists.length == 0) {
                                    $scope.allegationTypeMetrics.push({
                                        "AllegationType": data.Allegation,
                                        "Count": 1
                                    })
                                } else {
                                    var index = $scope.allegationTypeMetrics.indexOf(allegationExists.firstOrDefault());
                                    $scope.allegationTypeMetrics[index].Count += 1;
                                }
                            }

                        });
                    }
                    if (!!$scope.configName && $scope.configName == 'CasesByStandardOfConduct' && !!$scope.results.reportData && $scope.results.reportData.length > 0) {
                        $scope.standardOfConductMetrics = [];
                        angular.forEach($scope.results.reportData, function (data) {
                            if (data['Standard Of Conduct'] != "N/A") {
                                var standardOfConductExists = $scope.standardOfConductMetrics.whereEquals(data['Standard Of Conduct'], 'StandardOfConduct');
                                if (standardOfConductExists.length == 0) {
                                    $scope.standardOfConductMetrics.push({
                                        "StandardOfConduct": data['Standard Of Conduct'],
                                        "Count": 1
                                    })
                                } else {
                                    var index = $scope.standardOfConductMetrics.indexOf(standardOfConductExists.firstOrDefault());
                                    $scope.standardOfConductMetrics[index].Count += 1;
                                }
                            }

                        });
                    }
                };

                $scope.onCannedReportDownloading = function (request) {
                    if (!!$scope.configName && ['DistrictInfo'].includes($scope.configName)) {
                        if (!!request && !!request.FormValues && !!request.FormValues.district && typeof(request.FormValues.district) == 'object') {
                            request.FormValues._DistrictId = request.FormValues.district.DistrictId;
                        } else {
                            request.FormValues._DistrictId = 0;
                        }
                    }
                    if (!!$scope.configName && ['InstitutionInfo'].includes($scope.configName)) {
                        if (!!request && !!request.FormValues && !!request.FormValues.institution && typeof(request.FormValues.institution) == 'object') {
                            request.FormValues._InstitutionId = request.FormValues.institution.InstitutionId;
                        } else {
                            request.FormValues._InstitutionId = 0;
                        }
                    }

                    delete request.FormValues.district;
                    delete request.FormValues.institution;
                };

                $scope.onCannedReportDownloaded = function (request) {

                };

                $scope.initExt = function () {
                    $scope.refTables.filteredSchoolList = [];

                    if (!!sessionStorage.DistrictId) {
                        $scope.uiLogic.isDistrictDashboard = true;
                        $scope.districtId = Number(sessionStorage.DistrictId);
                    }
                    if (!!sessionStorage.InstitutionId) {
                        $scope.uiLogic.isInstitutionDashboard = true;
                        $scope.institutionId = Number(sessionStorage.InstitutionId);
                    }
                };

                $scope.resetReportExt = function () {
                    if (!!$scope.refTables.districts) {
                        $scope.current.form.district = $scope.refTables.districts.whereEquals($scope.districtId, 'DistrictId').firstOrDefault();
                    }
                    if (!!$scope.refTables.institutions) {
                        $scope.current.form.institution = $scope.refTables.institutions.whereEquals($scope.institutionId, 'InstitutionId').firstOrDefault();
                    }
                };

                $scope.getTotalOfColumn = function (colName) {
                    var total = 0;
                    if (!!$scope.results && !!$scope.results.reportData) {
                        $scope.results.reportData.forEach(function (data) {
                            total += Number(data[colName]);
                        });
                    }
                    return total;
                };

                $scope.$watch('refTables.districts', function (newVal) {
                    if (!!newVal) {
                        $scope.current.form.district = newVal.whereEquals($scope.districtId, 'DistrictId').firstOrDefault();
                    }
                });

                $scope.$watch('refTables.institutions', function (newVal) {
                    if (!!newVal) {
                        $scope.current.form.institution = newVal.whereEquals($scope.institutionId, 'InstitutionId').firstOrDefault();
                    }
                });
            }
        };
    }
})();