//Code Generated by Inlumon Code generation tool
(function () {
    'use strict'

    angular.module('app.backofficeAdministor')
        .factory('revenueDepositService', revenueDepositService)

    revenueDepositService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function revenueDepositService($http, $q, WebApiUrl, AppConfig) {

        return {
            revenueDepositGetByEntityId: _revenueDepositGetByEntityId,
            revenueDepositSave: _revenueDepositSave
        };

        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function _revenueDepositGetByEntityId(Key, batchNumber) {
            return returnDefferedResult($http.get(WebApiUrl + 'RevDeposit/GetRevDeposit/' + Key + "?BatchNumber=" + (!!batchNumber ? batchNumber : "")));
        };

        function _revenueDepositSave(revenueDeposit, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'RevDeposit/SaveRevDeposit/' + key + "?Source=" + AppConfig.Source, revenueDeposit));
        };

    }
})();