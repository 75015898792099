(function () {
    'use strict'
    angular.module('app.core')
        .factory('multipleFileUploadService', MultipleFileUploadService);

    MultipleFileUploadService.$inject = ['$http', '$q', 'utilityService', 'WebApiUrl'];

    function MultipleFileUploadService($http, $q, utilityService, WebApiUrl) {

        var _documentMasterGetALL = function (Key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Document/DocumentMasterGetALL/' + Key)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _documentGetDocumentTypeName = function (Key, docId, docCode) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Document/DocumentGetDocumentTypeName/' + Key + '?docId=' + docId + '&DocCode=' + docCode)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _getAllTemplates = function () {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Template/GetAllTemplates')
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _individualDocumentSave = function (individualDocumentUpload, key) {
            var deferred = $q.defer();
            var apiEndPoint = ((individualDocumentUpload.ApiSaveEndpoint) ? individualDocumentUpload.ApiSaveEndpoint : (WebApiUrl + 'Individual/IndividualDocumentSave/'));
            $http.post(apiEndPoint + key, individualDocumentUpload)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _individualDocumentEmailSend = function (key, IndividualId, IndividualDocumentId, DocumentId, ApplicationId, Email, TemplateId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualDocumentEmailSend/' + key + '?IndividualId=' + IndividualId + '&IndividualDocumentId=' + IndividualDocumentId + '&DocumentId=' + DocumentId + '&ApplicationId=' + ApplicationId + '&Email=' + Email + '&TemplateId=' + TemplateId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _individualDocumentGetByIndividualId = function (Key, individualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualDocumentGetByIndividualId/' + Key + '?IndividualId=' + individualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _individualDocumentProcess = function (Key, individualId, dataToProcess, propertyToProcess, linkType) {
            var deferred = $q.defer();
            _individualDocumentGetByIndividualId(Key, individualId).then(function (data) {
                if (data.Status) {
                    angular.forEach(dataToProcess, function (dataItemToProcess) {
                        dataItemToProcess.IndividualDocumentList = [];
                        angular.forEach(data.IndividualDocumentGetList, function (individualDocumentItem) {
                            if (dataItemToProcess[propertyToProcess] == individualDocumentItem.LinkId) {
                                dataItemToProcess.IndividualDocumentList.push(individualDocumentItem);
                            }
                        })
                    })
                    deferred.resolve(dataToProcess);
                } else {
                    deferred.resolve(dataToProcess);
                }
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _publicIndividualDocumentGetByIndividualId = function (individualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/PublicIndividualDocumentGetByIndividualId/?IndividualId=' + individualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };


        var _publicIndividualDocumentProcess = function (individualId, dataToProcess, propertyToProcess, linkType) {
            var deferred = $q.defer();
            _publicIndividualDocumentGetByIndividualId(individualId).then(function (data) {
                if (data.Status) {
                    angular.forEach(dataToProcess, function (dataItemToProcess) {
                        dataItemToProcess.IndividualDocumentList = [];
                        angular.forEach(data.IndividualDocumentGetList, function (individualDocumentItem) {
                            if (dataItemToProcess[propertyToProcess] == individualDocumentItem.LinkId) {
                                dataItemToProcess.IndividualDocumentList.push(individualDocumentItem);
                            }
                        })
                    })
                    deferred.resolve(dataToProcess);
                } else {
                    deferred.resolve(dataToProcess);
                }
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _individualDocumentDeleteByID = function (key, IndividualDocumentId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualDocumentDeleteByID/' + key + '?ID=' + IndividualDocumentId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _documentByIndividualDocumentId = function (individualDocumentId) {
            ShowLoader("Please wait...");
            $http.get(WebApiUrl + 'Individual/DocumentByIndividualDocumentId/' + (sessionStorage.Key) + '?IndividualDocumentId=' + individualDocumentId, {
                responseType: 'arraybuffer'
            }).then(function (response) {
                console.log(response);
                var headers = response.headers();
                response = response.data;
                utilityService.downloadExcelFile(response, headers);
                HideLoader();
            });
        };

        return {
            documentMasterGetALL: _documentMasterGetALL,
            documentGetDocumentTypeName: _documentGetDocumentTypeName,
            individualDocumentSave: _individualDocumentSave,
            individualDocumentGetByIndividualId: _individualDocumentGetByIndividualId,
            individualDocumentProcess: _individualDocumentProcess,
            individualDocumentDeleteByID: _individualDocumentDeleteByID,
            documentByIndividualDocumentId: _documentByIndividualDocumentId,
            individualDocumentEmailSend: _individualDocumentEmailSend,
            getAllTemplates: _getAllTemplates,
            publicIndividualDocumentProcess: _publicIndividualDocumentProcess,
        }
    }
})();