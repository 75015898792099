﻿(function () {
    'use strict'
    angular.module('profile.manager').directive('addUserProfileDirective', function () {
        return {
            restrict: 'EA',
            scope: {
                profileId: '=',
                profile : '=?',
                userTypeList:'=?',
                onUserProfileSave: "&?",
                onCloseUserProfile: "&?"
            },
            controller: "addUserProfileDirectiveController",
            templateUrl: "app/components/backoffice/administrator/profile-management/add-user-profile-directive/add-user-profile-directive-template.html",
            link: function ($scope, element, attrs, ngCtrl) {

            }
        };
    });
})();