(function () {
  angular
    .module('app.backofficeIndividual')
    .controller("BackofficeIndividualDocumentController", BackofficeIndividualDocumentController);

  BackofficeIndividualDocumentController.$inject = ["$rootScope", "$scope", "$state", "WebApiUrl", "loggingService", "backofficeIndividualDocumentService", "backofficeIndividualLicenseService"];

  function BackofficeIndividualDocumentController($rootScope, $scope, $state, WebApiUrl, loggingService, backofficeIndividualDocumentService, backofficeIndividualLicenseService) {

    $scope.documentsList = null;
    $scope.documentsListLegacy = null;
    $scope.documentUpload = {
      ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
      ApplicationId: null,
      IndividualId: "",
      Email: null,
      SendEmail: null,
      TransactionId: null,
      LicenseeReprint: false,
      DocumentLkToPageTabSectionId: 1,
      DocumentLkToPageTabSectionCode: "FR",
      ExcludeDocId: 85,
      LoadDocumentsWithoutBroadCast: true
    };

    $scope.documentUploadLegacy = {
      ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
      ApplicationId: null,
      IndividualId: "",
      Email: null,
      SendEmail: null,
      TransactionId: null,
      LicenseeReprint: false,
      DocumentLkToPageTabSectionId: 1,
      DocumentLkToPageTabSectionCode: "FR",
      LoadByDocIdAndDocCode: true,
      DocId: 85,
      DocCode: "D1085",
      DocumentUploadedForId: (($rootScope.routeData && !!$rootScope.routeData.documentUploadedForId) ? $rootScope.routeData.documentUploadedForId : 1),
      loadDeleted: false,
      showLoadDeleted: true,
      LoadDocumentsWithoutBroadCast: true
    };

    $scope.init = function (individualId) {
      $scope.individualId = individualId;
      $scope.documentUpload.IndividualId = individualId;
      $scope.documentUploadLegacy.IndividualId = individualId;
    };

    //Tab Initilizer
    $scope.$on("individualTabChanged", function (evt, individualId) {
      if ($rootScope.individualCurrentTab.name == "document") {
        $scope.init(individualId);
        $scope.$broadcast("loaddocumentListing", individualId);
        $scope.$broadcast("initdocumentCreation");
      }
    });

    $scope.$on("closeLicenseOutputForm", function (event, args) {
      $scope.$broadcast("loaddocumentListing", $scope.individualId);
    });

    $scope.init($scope.individualId);
  }

})();