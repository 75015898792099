(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("CprController", CprController);

    CprController.$inject = ["$rootScope", "$scope",
        "cprService", "initialApplicationstatusService", "contentService", "utilityService", "WebApiUrl",
        "$filter", "typeValuesService"
    , 'Messages'];

    function CprController($rootScope, $scope,
        cprService, initialApplicationstatusService, contentService, utilityService, WebApiUrl, $filter,
        typeValuesService, Messages) {

        $scope.natValidation = {};
        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                minDate: (new Date(2015, 0, 1)),
                maxDate: (new Date(2017, 0, 31)),
                ngModelOptions: {
                    allowInvalid: true
                },
            },
            startDateOptions: {
                maxDate: null
            },
            endDateOptions: {
                minDate: null
            }
        };

        // $scope.getCprCertificationConfig = function () {
        //     var relativeFilePath = "/components/individual/application/directives/cpr-certification/cpr-certification.server-config.json";
        //     utilityService.getConfigData(relativeFilePath)
        //         .then(function (data) {
        //             $scope.cprCertificationConfig = data;
        //             $scope.isConfigLoaded = true;
        //         }, function (error) {
        //             ShowErrMsg("cprCertificationConfig", "Some error occurred,Unable to load the CPR Certification config");
        //         });
        // };

        $scope.GetStateByCountryID = function (CountryID) {
            try {
                initialApplicationLicenseInfoService.getStateByCountryID(sessionStorage.Key, CountryID).then(function (data) {
                    $scope.StatList = data.State;
                }, function (error) {
                    ShowErrMsg("unableToLoadStateList", error);
                });
            } catch (ex) {
                ShowErrMsg("unableToLoadStateList", error);
            }
        };

        $scope.cprInfo = {};
        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        var validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            // if (angular.isDate($scope.cprInfo.CPRCertificationEndDate) && $scope.cprInfo.CPRCertificationEndDate < (new Date(new Date().getFullYear(), 6, 1))) {
            //     var errorObj = {
            //         propName: "EndDateGreaterThanJuly",
            //         errorMessage: Messages.aeceadc
            //     };
            //     $scope.natValidation.errorMessages.push(errorObj);
            // }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        var ShowErrMsg = function (propName, errorMessage) {
            propName = (!!propName) ? propName : "cprCertificationErr";
            errorMessage = (!!errorMessage) ? errorMessage : $scope.cprCertificationConfig.ValidationMsg.APIError;
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            var propExist = false;
            for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                if ($scope.natValidation.errorMessages[i].propName == propName)
                    propExist = true;
            }
            if (!propExist)
                $scope.natValidation.errorMessages.push({
                    propName: propName,
                    errorMessage: errorMessage
                });

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        var getdate = function () {
            ShowLoader();
            cprService.getAllIndividualCPR(sessionStorage.Key, $scope.IndividualID)
                .then(function (response) {
                    if (response.Status) {
                        if (response.IndividualCPRResponseList) {
                            $scope.IndividualCPRResponseList = response.IndividualCPRResponseList;
                        }
                    }

                    HideLoader();
                }, function (error) {
                    HideLoader();
                    console.log('error');
                });
        }

        $scope.init = function () {
            try {
                $scope.refcprcoursetype();
                $scope.refcprstatus();
                getdate();
            } catch (ex) {

            }
        }

        $scope.deleteIndividualIdCrp = function (combinedAddress) {
            if (confirm(Messages.cdeefee)) {
                ShowLoader();
                combinedAddress.IsDeleted = true;
                cprService.cprCertificationSave(combinedAddress, sessionStorage.Key)
                    .then(function (response) {
                        getdate();
                    }, function (error) {
                        HideLoader();
                    });
            }
        };

        $scope.addCrp = function (isEdit, data) {
            // $scope.isEdit = false;
            if (!isEdit) {
                $scope.cprInfo = {
                    IndividualId: $scope.IndividualID,
                    IsActive: true,
                    IsDelete: false,
                    CreatedBy: $scope.UserId
                };
                $scope.isEdit = false;
            } else {
                $scope.isEdit = true;
                $scope.cprInfo = angular.copy(data);
                processEducationDates();
            }
            $scope.AddContinuingdv = true;
        }



        $scope.refcprcoursetype = function () {
            typeValuesService.RefTablesGetAll('cprcoursetype', sessionStorage.Key, false, true)
                .then(function (response) {

                    if (response.data.Status) {
                        $scope.refcprcoursetypes = response.data.ReferenceEntities;
                        //  prepareAuxiliaryData();
                        //console.log($scope.refcprcoursetypes);
                    } else {
                        // ShowErrMsg("unableToLoadAuxiliaryTypes", response.Message);
                    }
                }, function (data) {
                    if (data != null) {
                        //   ShowErrMsg("unableToLoadAuxiliaryTypes", data);
                    }
                });
        };
        $scope.refcprstatus = function () {
            typeValuesService.RefTablesGetAll('cprstatus', sessionStorage.Key, false, true)
                .then(function (response) {
                    //console.log(response);
                    if (response.data.Status) {
                        $scope.refcprstatus = response.data.ReferenceEntities;
                        //  prepareAuxiliaryData();
                        //console.log($scope.refcprcoursetypes);
                    } else {
                        // ShowErrMsg("unableToLoadAuxiliaryTypes", response.Message);
                    }
                }, function (data) {
                    if (data != null) {
                        //   ShowErrMsg("unableToLoadAuxiliaryTypes", data);
                    }
                });
        };

        $scope.saveCprForm = function (form) {
            ShowLoader();
            if (!validateForm(form)) {
                HideLoader();
                return;
            }
            if ($scope.cprInfo.CourseNameTitle == "CPR") {
                $scope.cprInfo.CPRCourseTypeId = 1;
            } else if ($scope.cprInfo.CourseNameTitle == "PALS") {
                $scope.cprInfo.CPRCourseTypeId = 2;
            } else if ($scope.cprInfo.CourseNameTitle == "ACLS") {
                $scope.cprInfo.CPRCourseTypeId = 3;
            }
            if ($scope.cprInfo.CPRCertificationBeginDate == undefined) {
                $scope.cprInfo.CPRCertificationBeginDate = null;
            }
            if ($scope.cprInfo.CPRCertificationEndDate == undefined) {
                $scope.cprInfo.CPRCertificationEndDate = null;
            }

            cprService.cprCertificationSave($scope.cprInfo, sessionStorage.Key)
                .then(function (response) {
                    $scope.closeCprForm();
                    getdate();
                    if ($scope.isEdit) {
                        $scope.showStatusMessage(Messages.cfedefc, "success");
                    } else {
                        $scope.showStatusMessage(Messages.cfedefc, "success");
                    }
                }, function (error) {
                    $scope.showStatusMessage(data.message, "error");
                    HideLoader();
                });
        }

        $scope.closeCprForm = function () {
            $scope.AddContinuingdv = false;
            $scope.currentForm = null;
        }

        $scope.setEmploymentToMinDate2 = function () {
            if (angular.isDate($scope.cprInfo.CPRCertificationBeginDate))
                $scope.datePicker.endDateOptions.minDate = null;
            $scope.cprInfo.CPRCertificationEndDate = null;
        };

        $scope.setEmploymentFromMaxDate = function () {
            if (angular.isDate($scope.cprInfo.CPRCertificationEndDate))
                $scope.datePicker.startDateOptions.maxDate = $scope.cprInfo.CPRCertificationEndDate;
        };

        var processEducationDates = function () {
            if (!!$scope.cprInfo) {
                if (!!$scope.cprInfo.CPRCertificationBeginDate && !angular.isDate($scope.cprInfo.CPRCertificationBeginDate))
                    $scope.cprInfo.CPRCertificationBeginDate = new Date($scope.cprInfo.CPRCertificationBeginDate);
                if (!!$scope.cprInfo.CPRCertificationEndDate && !angular.isDate($scope.cprInfo.CPRCertificationEndDate))
                    $scope.cprInfo.CPRCertificationEndDate = new Date($scope.cprInfo.CPRCertificationEndDate);
            }
        };

        // $scope.saveCprCertification = function (currentForm) {
        //     ShowLoader("Please wait...");
        //     if (!validateForm(currentForm)) {
        //         HideLoader();
        //         return;
        //     }

        //     cprService.cprCertificationSave($scope.cprInfo, sessionStorage.Key)
        //         .then(function (response) {
        //             if (response.Status == false) {
        //                 ShowErrMsg("unableToSaveCPRCertification", response.Message);

        //             }
        //             HideLoader();
        //         }, function (data) {

        //             ShowErrMsg("unableToSaveCPRCertification", data);
        //             if (!$rootScope.ProcessLoaderExternally)
        //                 HideLoader();
        //         });
        // };
        $scope.init();

    }
})();