(function() {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationChildSupportController", InitialApplicationChildSupportController);

    InitialApplicationChildSupportController.$inject = ["$rootScope", "$scope", "$q",
        "initialApplicationChildSupportService", "initialApplicationstatusService", "utilityService", "WebApiUrl", "AppConfig"
    ];

    function InitialApplicationChildSupportController($rootScope, $scope, $q,
        initialApplicationChildSupportService, initialApplicationstatusService, utilityService, WebApiUrl, AppConfig) {
        if (AppConfig.EnableConsoleLogging)
            console.log('Child Support : ', $scope.childSupport);

        $scope.init = function() {
            $scope.childSupportData = {};
            $scope.getChildSupportConfig();
            $scope.initChildSupportInfo();
        };

        $scope.getChildSupportConfig = function() {
            var relativeFilePath = "/individual/application/child-support/child-support.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function(data) {
                    $scope.childSupportConfig = data;
                    $scope.isConfigLoaded = true;
                    //$scope.childSupport.Value = data.OptionsDefaultValue;
                }, function(error) {

                });
        };

        $scope.initChildSupportInfo = function() {
            angular.forEach($scope.childSupport, function(childSupportOption, key) {
                if (childSupportOption.ContentItemNumber == 3 && childSupportOption.ContentItemLkId == 6 && childSupportOption.ContentItemResponse == true) {
                    sessionStorage.ChildSupportInfo = true;
                } else {
                    sessionStorage.ChildSupportInfo = false;
                }
                if (childSupportOption.ContentItemResponse == true) {
                    $scope.childSupportData.childSupportValue = childSupportOption.IndividualChildSupportId;
                }
            });
        };

        $scope.setForm = function(currentForm) {
            $scope.currentForm = currentForm;
        }
        var validateForm = function(currentForm) {
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                console.log(currentForm.errorMessages);
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        }

        var checkPropertyExists = function(list, prop) {
            var res = false;
            if (list) {
                for (var i = 0; i < list.length; i++) {
                    if (list[i].propName == prop)
                        return true;
                }
            }
            return res;
        };

        var ShowErrMsg = function() {
            var errorObj = {
                propName: "childSupportErr",
                errorMessage: $scope.childSupportConfig.ValidationMsg.APIErr
            };
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            if (!checkPropertyExists($scope.natValidation.errorMessages, 'childSupportErr'))
                $scope.natValidation.errorMessages.push(errorObj);
        }

        $scope.childSupportSave = function(currentForm) {
            //initialApplicationstatusService.setChildSupport(true);

            if (!validateForm(currentForm)) {
                initialApplicationstatusService.setChildSupport("invalid");
                HideLoader();
                return;
            }
            if ($scope.childSupport != null) {
                var dataSavePromises = [];
                angular.forEach($scope.childSupport, function(childSupportOption, key) {
                    dataSavePromises.push(initialApplicationChildSupportService.childSupportSave(childSupportOption, sessionStorage.Key));
                });
                var isSavedSuccessFully = true;
                $q.all(dataSavePromises)
                    .then(function(response) {
                        angular.forEach(response, function(childSupportData, key) {
                            if (childSupportData.Status == false) {
                                isSavedSuccessFully = false;
                            }
                        });
                        $scope.isSavedSuccessFully = isSavedSuccessFully;
                        if (isSavedSuccessFully)
                            initialApplicationstatusService.setChildSupport("success");
                        else
                            initialApplicationstatusService.setChildSupport("error");

                        if (!$rootScope.ProcessLoaderExternally)
                            HideLoader();
                    }, function(data) {
                        initialApplicationstatusService.setChildSupport("error");
                        $scope.isSavedSuccessFully = false;
                        if (data != null) {
                            ShowErrMsg();
                        }
                        if (!$rootScope.ProcessLoaderExternally)
                            HideLoader();
                    });
            } 
        };

        $scope.$on("SaveRenewal", function(event, args) {
            $scope.childSupportSave($scope.currentForm);
        });

        $scope.setChildSupportInfo = function() {
            sessionStorage.ChildSupportInfo = false;
            angular.forEach($scope.childSupport, function(childSupportOption, key) {
                if (childSupportOption.ContentItemNumber == 3 && childSupportOption.ContentItemLkId == 6 && childSupportOption.ContentItemResponse == true) {
                    sessionStorage.ChildSupportInfo = true;
                } else {
                    sessionStorage.ChildSupportInfo = false;
                }

                if ($scope.childSupportData.childSupportValue == childSupportOption.IndividualChildSupportId) {
                    childSupportOption.ContentItemResponse = true;
                } else {
                    childSupportOption.ContentItemResponse = false;
                }
            });
        };

        $scope.init();
    }
})();
