(function () {
    'use strict'
    angular.module('app.core')
        .controller('IndividualinfoContinuingEducationController', IndividualinfoContinuingEducationController);

    IndividualinfoContinuingEducationController.$inject = ['$controller', '$rootScope', '$scope', 'individualinfoContinuingEducationService'];

    function IndividualinfoContinuingEducationController($controller, $rootScope, $scope, individualinfoContinuingEducationService) {

        var init = function () {
            individualinfoContinuingEducationService.getCDSCMEInfoandAudit(sessionStorage.Key, $scope.license.IndividualId, $scope.license.ApplicationId).then(function (response) {
                $scope.cDSCMEInfoandAuditList = response.GetCDSCMEInfoandAuditList;
            }, function (error) {

            });
        }

        init();

    }
})();


