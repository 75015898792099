(function () {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeProviderCommentController", BackofficeProviderCommentController);

    BackofficeProviderCommentController.$inject = ["$rootScope", "$scope", "$state", "backofficeProviderCommentService", "utilityService", "$exceptionHandler", "WebApiUrl"];

    function BackofficeProviderCommentController($rootScope, $scope, $state, backofficeProviderCommentService, utilityService, $exceptionHandler, WebApiUrl) {
        $scope.providerDocumentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderCommentLogSave/',
            ApplicationId: null,
            ProviderId: "",
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "ASD",
        };

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, ProviderId) {
            if ($rootScope.individualFirmCurrentTab.name == "notes" && !$rootScope.individualFirmCurrentTab.isLoadedOnce) {
                $scope.providerDocumentUpload.ProviderId = ProviderId;
                $scope.ProviderId = ProviderId;
                $scope.providerCommentLogGetByProviderId(ProviderId);
                $scope.$broadcast("initdocumentCreation");
                AuditVisitInfoSave();
                $rootScope.individualFirmCurrentTab.isLoadedOnce = true;
            }
        });

        // Save Notes
        $scope.saveNote = function () {
            try {
                if (validateData("UI_BackofficeIndividualNotesValidation")) {
                    $scope.hideStatusMessage();
                    $scope.hideValidationSummary();
                    ShowLoader("Please wait...");
                    var date = new Date();
                    $scope.providerDocumentUpload.DocumentUploadList = [];
                    var ProviderComment = {
                        ProviderId: $scope.ProviderId,
                        EffectiveDate: date,
                        CommentLogDate: date,
                        Type: 'C',
                        CommentLogText: $scope.notesComment,
                        ReferenceNumber: "",
                        IsInternalOnly: true,
                        IsForInvestigationOnly: true,
                        IsForPublic: true,
                        IsActive: true,
                        IsDeleted: false,
                        ProviderCommentLogId: 0
                    }

                    backofficeProviderCommentService.saveProviderComment(ProviderComment, $scope.providerDocumentUpload, sessionStorage.Key)
                        .then(function (response) {
                            if (response.StatusCode == "00") {
                                $scope.providerCommentLogGetByProviderId(ProviderComment.ProviderId);
                                $scope.providerDocumentUpload.DocumentUploadList = [];
                                $scope.notesComment = "";
                                $scope.showStatusMessage(response.Message, "success");
                            } else {
                                $scope.showStatusMessage(response.Message, "error");
                            }
                            HideLoader();
                        }, function (data) {
                            HideLoader();
                            $scope.showStatusMessage(data.Message, "error");
                        });
                }

            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage(ex.message, "error");
                $exceptionHandler(ex);
            }
        };

        $scope.providerCommentLogGetByProviderId = function (ProviderId) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                ShowLoader("Please wait...");
                backofficeProviderCommentService.providerCommentLogGetByProviderId(sessionStorage.Key, ProviderId)
                    .then(function (response) {
                        $scope.NoteArray = [];

                        if ($scope.checkServerResponse(response)) {
                            if (response.ProviderCommentLogRequest != null) {
                                // $scope.NoteAID = response.IndividualCommentLogRequest;
                                $scope.NoteArray = response.ProviderCommentLogRequest;
                            }
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        $scope.showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        }

        function AuditVisitInfoSave() {
            var data = {
                "PageName": "ProviderInfo-CommentTab",
                "SessionId": sessionStorage.Key,
                "ProviderId": $scope.ProviderId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        }

        $scope.clearNote = function () {
            $scope.notesComment = "";
        }
    }
})();