(function() {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('initialApplicationLicenseInfoService', initialApplicationLicenseInfoService)

    initialApplicationLicenseInfoService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function initialApplicationLicenseInfoService($http, $q, WebApiUrl, AppConfig) {

        var _saveLicenseInfo = function(licenseInfoObj, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualNVBusinessLicenseSave/' + key + '?Source=' + AppConfig.Source, licenseInfoObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getLicenseInfoByIndividualId = function(individualId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualNVBusinessLicenseGetByIndividualId/' + key + '?IndividualId=' + individualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getLicenseInfoContent = function(contentItemLkIds, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Content/ContentGetBYContentItemLkIdList/' + key, contentItemLkIds).then(function(data){ data = data.data;
                deferred.resolve(data);
            }, function(error) { error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getStateByCountryID = function(Key, ID) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'State/StateGetByCountryID/' + Key + '?CountryID=' + ID)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveBusinessAddress = function(businessAddressObj, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualNVBusinessLicenseAddressSave/' + key, businessAddressObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            saveLicenseInfo: _saveLicenseInfo,
            getLicenseInfoByIndividualId: _getLicenseInfoByIndividualId,
            getLicenseInfoContent: _getLicenseInfoContent,
            getStateByCountryID: _getStateByCountryID,
            saveBusinessAddress: _saveBusinessAddress
        };
    }
})();
