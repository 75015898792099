﻿(function() {
    'use strict'
    angular.module('app.core')
        .factory('individualPersonalInfoService', ['$http', 'WebApiUrl', '$q', 'AppConfig', function($http, WebApiUrl, $q, AppConfig) {
            var baseFactory = {};
            baseFactory.individualBYIndividualId = function(Key, IndividualId) {
                return $http.get(WebApiUrl + 'Individual/IndividualBYIndividualId/' + Key + '?IndividualId=' + IndividualId);
            }
            baseFactory.individualSave = function(person, key) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'Individual/IndividualSave/' + key + '?Source=' + AppConfig.Source, person)
                    .then(function(data) {
                        data = data.data;
                        deferred.resolve(data);
                    }, function(error) {
                        error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            }
            baseFactory.individualNameBYIndividualId = function(key, individualId) {
                var deferred = $q.defer();
                $http.get(WebApiUrl + 'Individual/IndividualNameBYIndividualId/' + key + '?individualId=' + individualId)
                    .then(function(data) {
                        data = data.data;
                        deferred.resolve(data);
                    }, function(error) {
                        error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            };
            return baseFactory;
        }]);
})();