(function () {
    'use strict'

    angular.module('app.core').factory('loggingService', loggingService);

    loggingService.$inject = ['$http', '$q', '$state', 'AppConfig', 'WebApiUrl'];

    function loggingService($http, $q, $state, AppConfig, WebApiUrl) {

        var loggingService = {
            logSave: logSave,
            logInfo: logText
        };

        var logInfo = {

            tabLog: {
                tabOpen: "Individual Info - ##tabName## opened"
            },

            //delete status
            deleteStatus: {
                deleteConfirmed: "Prompt confirmed",
                deleteCanceled: "Prompt canceled",
            },

            // document status
            documentStatus: {
                docLinkClicked: "##from##, document link clicked for document type ##documentType##",
                docDeleted: "##from##,  document delete clicked for document type ##documentType##",
                docUnDeleted: "##from##,  document undelete clicked for document type ##documentType##",
                docUploaded: "##from##: document upload clicked for document type ##documentType##",
                docUploadCancel: "##from##, document upload cancel clicked",
            },

            // log In logging
            logIn: {
                yesSelected: "Answer one, yes, was selected",
                noSelected: "Answer two, no, was selected",
                yesSubmitted: "Answer one, yes, was submitted",
                noSubmitted: "Answer two, no, was submitted",
                nextClicked: "The next button was clicked",
                backClicked: "The back button was clicked"
            },

            // dashboard logging
            dashboard: {

                yesEverythingLooksFine: "Dashboard - yes everything looks fine clicked",
                noIWantToMakeChanges: "Dashboard - no I want to make changes clicked",
                popUpEditCancel: "Dashboard - popup edit cancel clicked",
                popUpEditSave: "Dashboard - popup edit save clicked",

                //Address Detail
                editResidentialAddressClicked: "Dashboard - Address Detail - Home Address edit clicked ",
                editResidentialAddressCanceled: "Dashboard - Address Detail - Home Address edit canceled ",
                editResidentialAddressSaved: "Dashboard - Address Detail - Home Address edit saved ",
                editAMailingAddressClicked: "Dashboard - Address Detail - Mailing Address edit clicked ",
                editMailingAddressCanceled: "Dashboard - Address Detail - Mailing Address edit canceled ",
                editMailingAddressSaved: "Dashboard - Address Detail - Mailing Address edit saved ",

                //Business Address
                addBusinessAddressClicked: "Dashboard - Address Detail - Add business address clicked",
                editBusinessAddressClicked: "Dashboard - Address Detail - Edit business address clicked",
                deleteBusinessAddressClicked: "Dashboard - Address Detail - Delete business address clicked",
                cancelBusinessAdd: "Dashboard - Address Detail - Add business address - cancel clicked",
                saveBusinessAdd: "Dashboard - Address Detail - Add business address - save clicked",
                cancelBusinessEdit: "Dashboard - Address Detail - Edit business address - cancel clicked",
                saveBusinessEdit: "Dashboard - Address Detail - Edit business address - save clicked",

                //Contact Detail
                addContactClicked: "Dashboard - Contact Detail - Add clicked on contact detail ",
                addContactCanceled: "Dashboard - Contact Detail - Cancel add contact clicked ",
                addContactSaved: "Dashboard - Contact Detail - Save contact clicked ",
                editContactClicked: "Dashboard - Contact Detail - edit clicked ",
                editContactCanceled: "Dashboard - Contact Detail - edit cancel clicked ",
                editContactSaved: "Dashboard - Contact Detail - edit save clicked",
                deleteContact: "Dashboard - Contact Detail - delete clicked ",

                //Professional Development
                addDevClicked: "Dashboard - Professional Development - add clicked ",
                addDevSaved: "Dashboard - Professional Develoment - add - Save clicked ",
                addDevCanceled: "Dashboard - Professional Develoment - add - Cancel clicked ",

                devCourseEdit: "Dashboard - Professional Development - edit clicked ",
                devCourseEditSave: "Dashboard - Professional Development - edit -  save clicked",
                devCourseEditCanceled: "Dashboard - Professional Development - edit - cancel clicked",

                devDeleteCourse: "Dashboard - Professional Development - delete click ",

                //Continuing Education Credits
                addEducation: "Education - Add education clicked",
                cancelEducationAdd: "Education - Add education - cancel clicked",
                saveEducationAdd: "Education - Add education - save clicked",
                editEducation: "Education - edit clicked",
                cancelEducationEdit: "Education - edit cancel clicked",
                saveEducationEdit: "Education - edit save clicked",
                educationDeleted: "Education - delete education was clicked",

                //Payment Detail
                clickedViewPrint: "View/Print link in payment clicked",
                payInvoice: "Pay Invoice in payment clicked",
                deleteInvoice: "Delete Invoice in payment clicked",
                closePayment: "Close in payment clicked",

                //links
                clickedResetPassword: "reset password clicked",
                clickedSignOut: "sign out clicked",
                clickedNotYouSignOut: "clicked click here to log out",
                clickedCorrelationDirectory: "correlation directory link clicked",
                clickedStillHaveQuestions: "email link for questions clicked",
            },

            //INDIVIDUAL INFO

            // Individual Search
            individualInfo: {
                search: "Individual Info - Search clicked",
                cancelSearch: "Individual Info - Cancel Search clicked",
                addIndividual: "Individual Info - Add Individual clicked",
                saveIndividual: "Individual Info - Save Individual clicked",
                cancelAddIndividual: "Individual Info - Cancel Individual clicked",
            },

            personal: {
                closeProfileImg: "Individual Info - close profile image clicked",
                showMore: "Individual Info - Individual Details show clicked",
                showLess: "Individual Info - Individual Details hide clicked",
                profileIcon: "Individual Info - profile icon click",
                personalSave: "Individual Info - Personal tab - save clicked",
                personalCancel: "Individual Info - Personal tab - cancel clicked",
            },
            address: {
                addNewAddress: "Individual Info - Address - add address clicked",
                addressAddNewCanceled: "Individual Info - Address - Add new - cancel clicked",
                addressAddNewSaved: "Individual Info - Address - Add new - save clicked",
                addressEdit: "Individual Info - Address - edit clicked",
                addressEditCanceled: "Individual Info - Address - edit cancel clicked",
                addressEditSaved: "Individual Info - Address - edit save clicked",
                addressDeleted: "Individual Info - Address - delete was clicked",
                addressPreviousView: "Individual Info - Address - previous address view clicked",
                addressPreviousDeleted: "Individual Info - Address - previous address delete clicked",
                addressViewCanceled: "Individual Info - Address - previous address view cancel clicked"
            },
            contact: {
                addNewContact: "Individual Info - Address - Contact - add contact clicked",
                contactAddNewCanceled: "Individual Info - Address - Contact - Add new - cancel clicked",
                contactAddNewSaved: "Individual Info - Address - Contact - Add new - save clicked",
                contactEdit: "Individual Info - Address - Contact - edit clicked",
                contactEditCanceled: "Individual Info - Address - Contact - edit cancel clicked",
                contactEditSaved: "Individual Info - Address - Contact - save edit clicked",
                contactDeleted: "Individual Info - Address - Contact - delete click"
            },
            financial: {
                financialAdd: "Individual Info - Financial - Add clicked",
                financialCanceled: "Individual Info - Financial - Add new - cancel clicked",
                financialEditSaved: "Individual Info - Financial - edit save clicked",
                financialEdit: "Individual Info - Financial - edit clicked",
                financialDeleted: "Individual Info - Financial - delete clicked",
                financialProceedPayment: "Individual Info - Financial - Proceed to Payment  clicked",
                financialGenerateInvoice: "Individual Info - Financial - Generate Invoice  clicked",
                financialEditCanceled: "Individual Info - Financial - edit cancel clicked",
            },

            name: {
                addName: "Individual Info - Name - Add name clicked",
                cancelAdd: "Individual Info - Name - Add name - cancel clicked",
                saveAdd: "Individual Info - Name - Add name - save clicked",
                editName: "Individual Info - Name - edit clicked",
                cancelEdit: "Individual Info - Name - edit cancel clicked",
                saveEdit: "Individual Info - Name - edit save clicked",
                nameDeleted: "Individual Info - Name - delete clicked"
            },

            education: {
                addEducation: "Individual Info - education - Add education clicked",
                cancelAdd: "Individual Info - education - Add education - cancel clicked",
                saveAdd: "Individual Info - education - Add education - save clicked",
                editEducation: "Individual Info - education - edit clicked",
                cancelEdit: "Individual Info - education - edit cancel clicked",
                saveEdit: "Individual Info - education - edit save clicked",
                educationDeleted: "Individual Info - education - delete education was clicked",
                docLink: "Individual Info - education - document link clicked"
            },
            exam: {
                addExam: "Individual Info - exam - Add exam clicked",
                cancelAdd: "Individual Info - exam - Add exam - cancel clicked",
                saveAdd: "Individual Info - exam - Add exam - save clicked",
                editExam: "Individual Info - exam - edit clicked",
                cancelEdit: "Individual Info - exam - edit cancel clicked",
                saveEdit: "Individual Info - exam - edit save clicked",
                examDeleted: "Individual Info - exam - delete exam was clicked",
                docLink: "Individual Info - exam - document link clicked"
            },
            dea: {
                addDEA: "Individual Info - DEA - Add DEA clicked",
                cancelAdd: "Individual Info - DEA - Add DEA - cancel clicked",
                saveAdd: "Individual Info - DEA - Add DEA - save clicked",
                editDEA: "Individual Info - DEA - edit clicked",
                cancelEdit: "Individual Info - DEA - edit cancel clicked",
                saveEdit: "Individual Info - DEA - edit save clicked",
                deaDeleted: "Individual Info - DEA - delete DEA was clicked",
                docLink: "Individual Info - DEA - document link clicked"
            },
            primaryBoard: {
                addPrimaryBoard: "Individual Info - Primary Board - Add Primary Board clicked",
                cancelAdd: "Individual Info - Primary Board - Add Primary Board - cancel clicked",
                saveAdd: "Individual Info - Primary Board - Add Primary Board - save clicked",
                editPrimaryBoard: "Individual Info - Primary Board - edit clicked",
                cancelEdit: "Individual Info - Primary Board - edit cancel clicked",
                saveEdit: "Individual Info - Primary Board - edit save clicked",
                primaryBoardDeleted: "Individual Info - Primary Board - delete Primary Board was clicked"
            },
            appData: {
                emailClicked: "Individual Info - Application Data - email clicked",
            },
            license: {
                addLicense: "Individual Info - License - Add License clicked",
                cancelAdd: "Individual Info - License - Add License - cancel clicked",
                saveAdd: "Individual Info - License - Add License - save clicked",
                editLicense: "Individual Info - License - edit clicked",
                cancelEdit: "Individual Info - License - edit cancel clicked",
                saveEdit: "Individual Info - License - edit save clicked",
                licenseDeleted: "Individual Info - License - delete license clicked",
                viewLicense: "Individual Info - License - view license details clicked"
            },
            comment: {
                commentCancel: "Individual Info - Comment - cancel comment clicked",
                commentSave: "Individual Info - Comment - save comment clicked",
            },
            correspondence: {
                mailClicked: "Individual Info - Correspondence - mail clicked",
                editEmail: "Individual Info - Correspondence - edit email clicked",
                editSaveEmail: "Individual Info - Correspondence - edit save clicked",
                editCancelEmail: "Individual Info - Correspondence - edit cancel clicked",
            },
            feeAndPayment: {
                payNow: "pay now clicked",
                cancel: "fee and payment cancel clicked",
                miscCancel: "misc fee and payment cancel clicked",
                delete: "fee and payment delete clicked",
                confirmPayment: "confirm payment clicked",
            },

            employment: {
                employmentAdd: "Employment - add clicked",
                employmentAddCancel: "Employment - add cancel clicked",
                employmentAddSave: "Employment - add save clicked",
                employmentEdit: "Employment - view clicked",
                employmentEditCancel: "Employment - view cancel clicked",
                employmentEditSave: "Employment - view save clicked",
                employmentDelete: "Employment - deassociate clicked",
            },

            backofficeLogging: {

                menuTabClicked: "Backoffice - ##menuName## clicked",
                backofficeSearchClicked: "Backoffice - ##menuName## - search clicked",
                backofficeCancelClicked: "Backoffice - ##menuName## - cancel clicked",
                backofficeSearchClosed: "Backoffice - ##menuName## - search closed",

                newTaskClicked: "Backoffice - new task clicked",

                //New task form doesn't exist. Can be logged later.
                //newTaskCanceled: "Backoffice - new task cancel clicked",
                // newTaskSaved: "Backoffice - new task save clicked",
                // editTaskClicked: "Backoffice - edit task clicked",
                // editTaskCanceled: "Backoffice - edit task cancel clicked",
                // editTaskSaved: "Backoffice - edit task save clicked",
                // deleteTask: "Backoffice - delete task clicked",

                showAll: "Backoffice - show all clicked",
                downArrowClicked: "Backoffice - down arrow clicked",
                archiveClicked: "Backoffice - archive clicked",
                deleteClicked: "Backoffice - delete clicked",


                lastNameClicked: "Backoffice - last name clicked",
                firstNameClicked: "Backoffice - first name clicked",
                licNumClicked: "Backoffice - license number clicked",
                appType: "Backoffice - application type clicked",
                subDate: "Backoffice - submitted date clicked",
                assignTo: "Backoffice - assign to clicked",
                assignAndEmail: "Backoffice - assign and email clicked",
                startedBy: "Backoffice - started by clicked",
                status: "Backoffice - status clicked",
                lockedBy: "Backoffice - locked by/on clicked",
                sendOutput: "Backoffice - send output clicked",

                renewalSearchByLicNo: "Backoffice - search by License No clicked",
                renewalSearchByLicNoCancel: "Backoffice - search by License No - cancel clicked",
                renewalSearch: "Backoffice - search clicked",
                renewalSearchCancel: "Backoffice - search cancel clicked",
                openRenewalApplication: "Backoffice - open renewal application clicked",
                openRenewalApplicationInProgress: "Backoffice - open renewal application - In progress clicked",
                openRenewalApplicationVerify: "Backoffice - open renewal application - verify clicked",
                openRenewalApplicationView: "Backoffice - open renewal application - view clicked",
                emailSend: "Backoffice - email send clicked",

                appVerify: "Backoffice - verify clicked",
                appView: "Backoffice - view clicked",
                appVerifyBC: "Backoffice - verify BC clicked",

                nonConfidentialClicked: "Backoffice - non-confidential clicked",
                confidentialClicked: "Backoffice - confidential clicked",
                promptStatusChangeConfirmed: "Backoffice - prompt status change confirmed",
                promptStatusChangeCanceled: "Backoffice - prompt status change canceled",
                searchClicked: "Backoffice - search icon clicked",
                printClicked: "Backoffice - print icon clicked",
                printBoardPacket: "Backoffice - print board packet clicked",
                printPromptClosed: "Backoffice - print prompt closed",
                commentClicked: "Backoffice - comment icon clicked",
                commentCanceled: "Backoffice - comment canceled",
                commentSaved: "Backoffice - comment saved",
                commentTextBoxClosed: "Backoffice - comment text prompt closed",

                nameClicked: "Backoffice - a name was clicked on",
                pageChange: "Backoffice - page change",

                addAppClicked: "Backoffice - add application clicked",

                editAppClicked: "Backoffice - edit application clicked",

                appIntakeQ: "Backoffice - application action IntakeQ clicked",
                appAnalystPending: "Backoffice - application action analyst pending clicked",
                appAnalystApproved: "Backoffice - application action analyst approved clicked",
                appAnalystDenied: "Backoffice - application action analyst denied clicked",
                appBGPending: "Backoffice - application action BG pending clicked",
                appBGApproved: "Backoffice - application action BG approved clicked",
                appBGDenied: "Backoffice - application action BG denied clicked",
                appDeficiency: "Backoffice - application action deficiency clicked",
                appPaid: "Backoffice - application action paid clicked",
                appNotPaid: "Backoffice - application action not paid clicked",
                appUnlock: "Backoffice - application action unlock clicked",
                appLock: "Backoffice - application action lock clicked",
                appArchive: "Backoffice - application action archive clicked",
                appUnarchive: "Backoffice - application action un-archive clicked",
                appDelete: "Backoffice - application action delete clicked",

                unlockPromptConfirmed: "Backoffice - unlock prompt confirmed",
                unlockPromptCanceled: "Backoffice - unlock prompt canceled",

                exportToExcel: "Backoffice - export to excel clicked",
                exportToPDF: "Backoffice - export to PDF clicked",
                tableHeader: "Backoffice - ##tableHeaderName## clicked",

                clearQuery: "Backoffice - clear query clicked",
                execute: "Backoffice - execute clicked",
                clearSection: "Backoffice - clear section clicked",
                addCondition: "Backoffice - add condition clicked",
                addColumn: "Backoffice - add colum clicked",

                addNewBPP: "Backoffice - BPP - add new clicked",
                bppSubmitRegistration: "Backoffice - BPP - submit registration clicked",
                approvedBPP: "Backoffice - BPP - approved clicked",
                deniedBPP: "Backoffice - BPP - denied clicked",
                verifyBPP: "Backoffice - BPP - verify clicked",
                approveBPP: "Backoffice - BPP - approve clicked",
                denyBPP: "Backoffice - BPP - deny clicked",
                closeBPP: "Backoffice - BPP - close clicked",
                searchBPP: "Backoffice - BPP - search icon clicked",
                login: "Backoffice - login clicked",

                saveCouncil: "Backoffice - council information save clicked",
                cancelCouncil: "Backoffice - council information cancel clicked",

                addNewRole: "Backoffice - add new role clicked",
                addTypeRole: "Backoffice - ##type## - add new role clicked",
                saveNewRole: "Backoffice - ##type## - add new role save clicked",
                cancelNewRole: "Backoffice - ##type## - add new role cancel clicked",
                editRole: "Backoffice - ##type## - edit role clicked",
                saveEditRole: "Backoffice - ##type## - edit role save clicked",
                cancelEditRole: "Backoffice - ##type## - edit role cancel clicked",
                deleteRole: "Backoffice - ##type## - delete role clicked",

                addNewUser: "Backoffice - add new user clicked",
                editUser: "Backoffice - edit user clicked",
                updateAccount: "Backoffice - update account clicked",
                cancelAccount: "Backoffice - cancel clicked",
                createAccount: "Backoffice - create account clicked",
                enable: "Backoffice - enable clicked",
                disable: "Backoffice - disable clicked",
                deleteUser: "Backoffice - delete user clicked",
                notes: "Backoffice - notes clicked",
                documents: "Backoffice - documents clicked",

                addNewTemplate: "Backoffice - add new template clicked",
                saveNewTemplate: "Backoffice - save new template clicked",
                cancelNewTemplate: "Backoffice - cancel new template clicked",
                editNewTemplate: "Backoffice - edit template clicked",
                saveEditTemplate: "Backoffice - save edit template clicked",
                cancelEditTemplate: "Backoffice - cancel edit template clicked",
                deleteTemplate: "Backoffice - delete template",

                editContent: "Backoffice - content management - edit clicked",
                editSave: "Backoffice - content management - edit save clicked",
                editCancel: "Backoffice - edit cancel clicked",

                addNewDocument: "Backoffice - add new document clicked",
                saveNewdocument: "Backoffice - save new document clicked",
                cancelNewDocument: "Backoffice - cancel new document clicked",
                editNewDocument: "Backoffice - edit document clicked",
                saveEditDocument: "Backoffice - save edit document clicked",
                cancelEditDocument: "Backoffice - cancel edit document clicked",
                deleteDocument: "Backoffice - delete document",

                configTabClicked: "Backoffice - ##tabName## tab clicked",

                saveNewDeficiency: "Backoffice - save new deficiency clicked",
                cancelNewDeficiency: "Backoffice - cancel new deficiency clicked",
                editDeficiency: "Backoffice - edit deficiency clicked",
                saveEditDeficiency: "Backoffice - save edit deficiency clicked",
                cancelEditDeficiency: "Backoffice - cancel edit deficiency clicked",
                deleteDeficiency: "Backoffice - delete deficiency",

                editSetting: "Backoffice - edit setting clicked",
                updateEditSetting: "Backoffice - update edit setting clicked",
                cancelEditSetting: "Backoffice - cancel edit setting clicked",

                find: "Backoffice - find clicked",
                closeOut: "Backoffice - close out clicked",
                comments: "Backoffice - comments clicked",

                plus: "Backoffice - plus button clicked",
                minus: "Backoffice - minus button clicked",
                licensesSave: "Backoffice - save clicked",
                licensesCancel: "Backoffice - cancel clicked",

                addEndorsement: "Backoffice - add endorsement clicked",
                addProvision: "Backoffice - add provision clicked",
                addProvisionSave: "Backoffice - save provision clicked",
                addProvisionCancel: "Backoffice - cancel provision clicked",
                addAssignment: "Backoffice - add assignment clicked",
                addAssignmentSave: "Backoffice - save assignment clicked",
                addAssignmentCancel: "Backoffice - cancel assignment clicked",
                showProvision: "Backoffice - show provision clicked",
                showAssignment: "Backoffice - show assignment clicked",

                saveLicenses: "Backoffice - licenses save clicked",
                cancelLicenses: "Backoffice - cancel licenses clicked",

                addNewRecord: "Backoffice - add new record clicked",
                recordSave: "Backoffice - record save clicked",
                recordCancel: "Backoffice - record cancel clicked",
                editRecord: "Backoffice - edit record clicked",

                isActive: "Backoffice - is active clicked",

                messageDetails: "Backoffice - message details clicked",
                reply: "Backoffice - reply clicked",
                sendReply: "send email clicked",
                closeReply: "close email clicked",

                createTask: "Backoffice - create task clicked",
                saveNewTask: "Backoffice - save new task clicked",
                cancelNewTask: "Backoffice - cancel new task clicked",
                editTask: "Backoffice - edit task clicked",
                cloneTask: "Backoffice - clone task clicked",
                saveEditTask: "Backoffice - save edit task clicked",
                cancelEditTask: "Backoffice - cancel edit task clicked",

                executeTask: "backoffice - execute clicked",
                viewTask: "backoffice - view clicked",
                scrollUp: "backoffice - scroll up clicked",

                closeBulkEmail: "Backoffice - close bulk email",

                // addNewProfile: "Backoffice - add new profile clicked",
                // saveNewProfile: "Backoffice - save new profile clicked",
                // cancelNewProfile: "Backoffice - cancel new profile clicked",
                // editNewProfile: "Backoffice - edit profile clicked",
                // saveEditProfile: "Backoffice - save edit profile clicked",
                // cancelEditProfile: "Backoffice - cancel edit profile clicked",
                // assignProfile: "Backoffice - assign profile clicked",
                // closeAssign: "Backoffice - close assign profile clicked",
                // deleteProfile: "Backoffice - delete profile",

                home: "Backoffice - home clicked",
                changePassWord: "Backoffice - change password clicked",
                settings: "Backoffice - settings clicked",
                help: "Backoffice - help clicked",
                logout: "Backoffice - logout clicked",
            },

        };

        function logText() {
            return logInfo;
        }

        function logSave(reqData, key) {
            if (!!reqData.messageType) {
                var commentLogText = '';
                var applicationArea = '';
                try {
                    applicationArea = ((!!$state.current.routeData && !!$state.current.routeData.applicationArea) ? $state.current.routeData.applicationArea : "");
                    commentLogText = eval("logInfo." + reqData.messageType);
                    if (!!reqData.hashTag && !!reqData.replaceTag)
                        commentLogText = commentLogText.replace(reqData.hashTag, reqData.replaceTag);

                    commentLogText = commentLogText.replace('##from##', applicationArea);
                } catch (ex) {
                    commentLogText = reqData.messageType;
                }
                reqData.CommentLogText = commentLogText;
            };

            var logSaveReq = {
                ModelData: reqData,
                IndividualDocumentUpload: {}
            };

            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualCommentLogSave/' + key + '?Source=' + AppConfig.Source, logSaveReq).then(function (data) {
                data = data.data;
                deferred.resolve(data);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };

        return loggingService;
    }
})();
