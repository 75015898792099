(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintSearchValidation', [ComplaintSearchValidation]);

    function ComplaintSearchValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function (form) {
                    var valid = true;
                    
                    $scope.hideStatusMsg();
                    if (!!form && form.errorMessages.length > 0) {
                        $scope.showStatusMsg('-', form.errorMessages);
                        valid = false;
                    }
                    
                    return valid;
                };
            },
        };
    }
})();