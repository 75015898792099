(function () {
    'use strict'

    angular.module('app.enforcement')
    .factory('ComplaintSearchService', ['$http', '$q', 'WebApiUrl', 'utilityService', ComplaintSearchService]);

    function ComplaintSearchService($http, $q, WebApiUrl, utilityService) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getStaffUsers = function (key) {
            var urlParams = {
                UserTypeId: 1
            };
            return responseHandler($http.get(WebApiUrl + 'Users/GetUsersByUserTypeId/' + key + '/', { params: urlParams }));
        };

        var _getInvolvedParties = function (key, complaintId, complaintInvolvedPartyTypeId, complaintInvolvedPartyId) {
            var request = {
                ComplaintId: complaintId,
                ComplaintInvolvedPartyTypeId: complaintInvolvedPartyTypeId,
                ComplaintInvolvedPartyId: complaintInvolvedPartyId
            };
            return responseHandler($http.post(WebApiUrl + 'Complaint/GetComplaintInvolvedParty/' + key + '/', request));
        };

        var _getComplaints = function (key, request, pageNum, pageSize) {
            var urlParams = {
                PageNumber: pageNum,
                NoOfRecords: pageSize
            };
            return responseHandler($http.post(WebApiUrl + 'Complaint/ComplaintSearchWithPage/' + key + '/', request, { params: urlParams }));
        };

        var _saveComplaint = function (key, request) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/SaveComplaint/' + key + '/', request))
        };

        return {
            getStaffUsers: _getStaffUsers,
            getInvolvedParties: _getInvolvedParties, 
            
            getComplaints: _getComplaints,
            saveComplaint: _saveComplaint
        };
    }
})();