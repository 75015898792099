(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeProviderAddressController", BackofficeProviderAddressController);

    BackofficeProviderAddressController.$inject = ["$rootScope", "$scope", "$state",
        "backofficeProviderAdressService", "countryStateService", "dcaIService", "typeValuesService", "$q", "$exceptionHandler", "RegisterFactory",
        "configurationService", "utilityService", "SettingsValue", "TypeValue", "AppConfig", "WebApiUrl", "$filter", "Messages"];

    function BackofficeProviderAddressController($rootScope, $scope, $state,
        backofficeProviderAdressService, countryStateService, dcaIService, typeValuesService, $q, $exceptionHandler, RegisterFactory,
        configurationService, utilityService, SettingsValue, TypeValue, AppConfig, WebApiUrl, $filter, Messages) {

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, providerId) {
            if (providerId && $rootScope.individualFirmCurrentTab.name == "firminformation") {
                $scope.ProviderId = providerId;
                $scope.providerRoute = $state.current.name;
                ShowLoader();
                if (!$scope.isLoadedOnce) {
                    $scope.getInitData($rootScope.DefaultCountryId);
                    GetCountryList();
                    $scope.isLoadedOnce = true;
                } else {
                    $scope.isDataLoaded = false;
                    $scope.loadAddressAndContacts($scope.ProviderId);
                }
                $rootScope.individualFirmCurrentTab.isAddressLoaded = true;
            }
        });

        var GetCountryList = function () {
            var deferred = $q.defer();
            try {
                countryStateService.countryGetAll(sessionStorage.Key).then(function (res) {
                    if (res.Status) {
                        $scope.CountryList = res.Country;
                    }
                    deferred.resolve(res);
                }, function (err) {
                    //    console.log('response', res);
                    deferred.reject(err);
                });
            }
            catch (ex) {
                //  console.log('Exception GetCountryList()', ex);
                deferred.reject(ex);
            }
            return deferred.promise;
        };



        $scope.addresstypeListBind = [{ Name: 'Corporate Mailing Address', Value: 4 }, { Name: 'Corporate Physical Address', Value: 6 }, { Name: 'Additional Location', Value: 11 }];
        $scope.ContacttypeListBind = [
            { Name: 'Website', Value: 17 },
            { Name: 'Business Phone', Value: 21 },
            { Name: 'Business Email', Value: 23 },
            { Name: 'Secondary Business Email', Value: 24 },
            { Name: 'Additional Location Email', Value: 26 },
            { Name: 'Additional Location Phone', Value: 27 },
            { Name: 'Fax', Value: 5 },
        ];

        //Common starts here
        $scope.viewConfig = {};

        $scope.getInitData = function (countryID) {
            var dataPromises = [];

            dataPromises.push(configurationService.configurationGetbySettings(sessionStorage.Key, SettingsValue.ShowAddressandContactSeparately));
            dataPromises.push(countryStateService.getStateByCountryID(sessionStorage.Key, countryID));
            dataPromises.push(typeValuesService.getTypeValuesByTypeName(TypeValue.Address, sessionStorage.Key));
            dataPromises.push(typeValuesService.getTypeValuesByTypeName(TypeValue.Contact, sessionStorage.Key));
            dataPromises.push($scope.providerAddressBYProviderId($scope.ProviderId, true));
            dataPromises.push($scope.providerContactBYProviderId($scope.ProviderId, true));

            $q.all(dataPromises)
                .then(function (response) {
                    angular.forEach(response, function (responseItem, itemIndex) {
                        if ($scope.checkServerResponse(responseItem)) {
                            if (itemIndex == 0) {
                                if (responseItem.ConfigurationList.length > 0) {
                                    var settingValue = responseItem.ConfigurationList[0].Value;
                                    if (settingValue.toLowerCase() == "true") {
                                        $scope.showAddressandContactSeparately = true;
                                    } else if (settingValue.toLowerCase() == "false") {
                                        $scope.showAddressandContactSeparately = false;
                                    }
                                }
                            } else if (itemIndex == 1) {
                                $scope.StatesData = responseItem.State;
                            } else if (itemIndex == 2) {
                                // $scope.AddressTypeList = responseItem.AddressTypeGetList.filter(function (u) { return u.AppliestoProvider == true });
                                $scope.AddressTypeList = [];
                                angular.forEach(responseItem.AddressTypeGetList, function (addressType) {
                                    if ((($state.current.name == 'app.Firms' || $state.current.name == 'app.AMC') && (addressType.AddressTypeId == 4 || addressType.AddressTypeId == 6)) || ($state.current.name == 'app.CEPP' && (addressType.AddressTypeId == 10 || addressType.AddressTypeId == 11)))
                                        $scope.AddressTypeList.push(addressType);
                                });
                            } else if (itemIndex == 3) {
                                // $scope.ContactTypeList = responseItem.ContactTypeGetList.filter(function (u) { return u.AppliestoProvider == true });
                                $scope.ContactTypeList = [];
                                angular.forEach(responseItem.ContactTypeGetList, function (item) {
                                    if ($state.current.name == 'app.Firms' && (item.ContactTypeId == 21 || item.ContactTypeId == 22 || item.ContactTypeId == 23 || item.ContactTypeId == 24 || item.ContactTypeId == 25 || item.ContactTypeId == 26 || item.ContactTypeId == 27)) {
                                        $scope.ContactTypeList.push(item);
                                    }
                                    if ($state.current.name == 'app.AMC' && (item.ContactTypeId == 21 || item.ContactTypeId == 22 || item.ContactTypeId == 23 || item.ContactTypeId == 24 || item.ContactTypeId == 25 || item.ContactTypeId == 26 || item.ContactTypeId == 27)) {
                                        $scope.ContactTypeList.push(item);
                                    }
                                    if ($state.current.name == 'app.CEPP' && (item.ContactTypeId == 24 || item.ContactTypeId == 25 || item.ContactTypeId == 26 || item.ContactTypeId == 27)) {
                                        $scope.ContactTypeList.push(item);
                                    }
                                });
                            } else if (itemIndex == 4) {
                                processAddressData(responseItem.ProviderAddress)
                            } else if (itemIndex == 5) {
                                processContactsData(responseItem.ProviderContacts)
                            }
                        }
                    });
                    if (!$scope.showAddressandContactSeparately) {
                        // assignAddressByAddressTypeId();
                    }
                    $scope.isDataLoaded = true;
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                });
        };

        var isAddressOrContactUpdated = function (currentItem, originalItem, isAddress) {
            if (!!$scope.showAddressandContactSeparately) {
                var fieldsToCompare;
                if (!!isAddress) {
                    fieldsToCompare = ['AddressTypeId', 'StreetLine1', 'StreetLine2', 'City', 'StateCode', 'CountryId', 'Zip', 'BeginDate', 'EndDate', 'IsMailingSameasPhysical'];
                } else {
                    fieldsToCompare = ['ContactTypeId', 'ContactInfo', 'IsPreferredContact'];
                }
                var isfieldsUpdated = false;
                angular.forEach(fieldsToCompare, function (fieldToCompare) {
                    if (angular.isDate(currentItem[fieldToCompare]) && angular.isDate(originalItem[fieldToCompare])) {
                        if (!utilityService.compareDateswithoutTime(currentItem[fieldToCompare], originalItem[fieldToCompare])) {
                            isfieldsUpdated = true;
                        }
                    } else {
                        if (currentItem[fieldToCompare] != originalItem[fieldToCompare]) {
                            isfieldsUpdated = true;
                        }
                    }
                });
                return isfieldsUpdated;
            } else {
                var fieldsToCompare = ['AddressTypeId', 'StreetLine1', 'StreetLine2', 'City', 'StateCode', 'Zip', 'BeginDate', 'EndDate'];
                var isfieldsUpdated = false;
                angular.forEach(fieldsToCompare, function (fieldToCompare) {
                    if (angular.isDate(currentItem[fieldToCompare]) && angular.isDate(originalItem[fieldToCompare])) {
                        if (!utilityService.compareDateswithoutTime(currentItem[fieldToCompare], originalItem[fieldToCompare])) {
                            isfieldsUpdated = true;
                        }
                    } else {
                        if (currentItem[fieldToCompare] != originalItem[fieldToCompare]) {
                            isfieldsUpdated = true;
                        }
                    }
                });
                if (currentItem.AddressTypeId == 10 | currentItem.AddressTypeId == 11) {
                    if ((currentItem.Addressee != originalItem.Addressee) ||
                        (currentItem.OfficePhoneContact.ContactInfo != originalItem.OfficePhoneContact.ContactInfo) ||
                        (currentItem.OfficeFaxContact.ContactInfo != originalItem.OfficeFaxContact.ContactInfo)
                    ) {
                        isfieldsUpdated = true;
                    }
                } else if (currentItem.AddressTypeId == 2) {
                    if ((currentItem.CellPhoneContact.ContactInfo != originalItem.CellPhoneContact.ContactInfo) ||
                        (currentItem.HomePhoneContact.ContactInfo != originalItem.HomePhoneContact.ContactInfo) ||
                        (currentItem.EmailAddressContact.ContactInfo != originalItem.EmailAddressContact.ContactInfo) ||
                        (currentItem.FaxContact.ContactInfo != originalItem.FaxContact.ContactInfo)
                    ) {
                        isfieldsUpdated = true;
                    }
                }
                if (currentItem.AddressTypeId == 10 || currentItem.AddressTypeId == 2) {
                    if (currentItem.IsMailingSameasPhysical != originalItem.IsMailingSameasPhysical) {
                        isfieldsUpdated = true;
                    }
                }

                return isfieldsUpdated;
            }
        };

        var getAddressOrContactActionFlag = function (isEdit, currentItem, originalItem, isAddress) {
            var actionFlag = '';
            if (!!$scope.showAddressandContactSeparately) {
                if (!isEdit) {
                    actionFlag = 'UI'
                } else {
                    // if ((isAddress && (currentItem.AdressStatusId == originalItem.AdressStatusId)) || (!isAddress && (currentItem.ContactStatusId == originalItem.ContactStatusId))) {
                    if ((isAddress) || (!isAddress && (currentItem.ContactStatusId == originalItem.ContactStatusId))) {
                        if (isAddressOrContactUpdated(currentItem, originalItem, isAddress)) {
                            actionFlag = "UI";
                        } else {
                            actionFlag = "U";
                        }
                    } else {
                        if (isAddressOrContactUpdated(currentItem, originalItem, isAddress)) {
                            actionFlag = "UIS";
                        } else {
                            actionFlag = "U";
                        }
                    }
                }
            } else {
                if (!isEdit) {
                    actionFlag = 'UI'
                } else {
                    if (currentItem.AdressStatusId == originalItem.AdressStatusId) {
                        if (isAddressOrContactUpdated(currentItem, originalItem)) {
                            actionFlag = "UI";
                        } else {
                            actionFlag = "U";
                        }
                    } else {
                        if (isAddressOrContactUpdated(currentItem, originalItem)) {
                            actionFlag = "UIS";
                        } else {
                            actionFlag = "U";
                        }
                    }
                }
            }
            return actionFlag;
        };

        $scope.loadAddressAndContacts = function (providerId) {
            var dataPromises = [];
            dataPromises.push($scope.providerAddressBYProviderId(providerId, true));
            dataPromises.push($scope.providerContactBYProviderId(providerId, true));
            $q.all(dataPromises)
                .then(function (response) {
                    angular.forEach(response, function (responseItem, itemIndex) {
                        if ($scope.checkServerResponse(responseItem)) {
                            if (itemIndex == 0) {
                                processAddressData(responseItem.ProviderAddress)
                            } else if (itemIndex == 1) {
                                processContactsData(responseItem.ProviderContacts)
                            }
                        }
                    });
                    if (!$scope.showAddressandContactSeparately) {
                        // assignAddressByAddressTypeId();
                    }
                    $scope.isDataLoaded = true;
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                });
        };
        //common ends here

        //Seperate Contact starts here
        $scope.ContactInputType = "Email";
        $scope.validateEmail = false;
        $scope.validateUrl = false;
        $scope.validatePhone = false;
        $scope.viewConfig.showContactList = true;
        $scope.ContactStatuses = [
            { ContactStatusId: 1, ContactStatusName: 'Current' },
            { ContactStatusId: 2, ContactStatusName: 'Previous' },
            { ContactStatusId: 3, ContactStatusName: 'Other' },
            { ContactStatusId: 4, ContactStatusName: 'Pending' }
        ];

        $scope.openContactAddNew = function (contactForm) {
            contactForm.$setPristine();
            $scope.clearContact();
            $scope.viewConfig.showContactList = false;
            $scope.contact.isEdit = false;
            $scope.originalContact = angular.copy($scope.contact);
        };

        $scope.setContactTypeValidation = function (contacTypeId, keepContactValue) {
            var contactTypeDesc = "";

            angular.forEach($scope.ContactTypeList, function (contactType, index) {
                if (contactType.ContactTypeId == contacTypeId) {
                    contactTypeDesc = contactType.Desc;
                }
            });
            $scope.validateEmail = false;
            $scope.validateUrl = false;
            $scope.validatePhone = false;
            if (!keepContactValue) {
                $scope.contact.ContactInfoEmail = "";
                $scope.contact.ContactInfoUrl = "";
                $scope.contact.ContactInfoPhone = "";
            }
            if (contactTypeDesc.indexOf("Email") >= 0) {
                $scope.ContactInputType = "Email";
                $scope.validateEmail = true;
                if (keepContactValue)
                    $scope.contact.ContactInfoEmail = $scope.contact.ContactInfo;
            } else if (contactTypeDesc.indexOf("Website") >= 0) {
                $scope.ContactInputType = "Url";
                $scope.validateUrl = true;
                if (keepContactValue)
                    $scope.contact.ContactInfoUrl = $scope.contact.ContactInfo;
            } else {
                $scope.ContactInputType = "Phone";
                $scope.validatePhone = true;
                if (keepContactValue)
                    $scope.contact.ContactInfoPhone = $scope.contact.ContactInfo;
            }
        };

        $scope.editContact = function (contact) {
            $scope.contact = angular.copy(contact);
            $scope.setContactTypeValidation($scope.contact.ContactTypeId, true);
            $scope.viewConfig.showContactList = false;
            $scope.originalContact = angular.copy(contact);
            $scope.contact.isEdit = true;
        };

        $scope.deleteContact = function (contact) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                ShowLoader("Please wait...");
                contact.ActionFlag = 'U';
                contact.IsDeleted = true;
                backofficeProviderAdressService.addressContactSave([contact], sessionStorage.Key)
                    .then(function (response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse(response)) {
                            $scope.providerContactBYProviderId($scope.ProviderId);
                            $scope.clearContact();
                            $scope.viewConfig.showContactList = true;
                            // $scope.$emit("individualAddressChanged", {});
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });

            }
        };
    

        $scope.clearContact = function () {
            $scope.contact = {};
        };

        var checkIfSameContactExists = function (contact) {
            var isContactAlreadyExists = false;
            angular.forEach($scope.personalContact, function (contactItem, index) {
                if ((contact.ContactTypeId == contactItem.ContactTypeId) &&
                    (contact.ProviderContactId != contactItem.ProviderContactId) &&
                    //(contact.ContactStatusId != contactItem.ContactStatusId) &&
                    (contact.ContactInfo == contactItem.ContactInfo)) {
                    isContactAlreadyExists = true;
                }
            });
            return isContactAlreadyExists;
        };

        $scope.addressContactSave = function (contact, addressContactForm) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                addressContactForm.$submitted = true;
                if ($scope.validateData(addressContactForm)) {

                    //validate multiple current contact                   
                    if (contact.ContactStatusId == 1 && $scope.contactsData && $scope.contactsData.length > 0) {
                        var item = $.grep($scope.contactsData, function (i) {
                            return (i.ContactStatusId == 1 && contact.ContactTypeId == i.ContactTypeId && contact.ContactId != i.ContactId)
                        });
                        if (item && item.length > 0) {
                            var contactTypeName = '';
                            angular.forEach($scope.ContactTypeList, function (c) {
                                if (c.ContactTypeId == contact.ContactTypeId) {
                                    contactTypeName = c.Desc;
                                }
                            });
                            $scope.showStatusMessage(Messages.General.Errors.ContactExist(contactTypeName), "error");
                            return;
                        };
                    }

                    if ($scope.ContactInputType == "Email") {
                        contact.ContactInfo = $scope.contact.ContactInfoEmail;
                    }
                    if ($scope.ContactInputType == "Url") {
                        contact.ContactInfo = $scope.contact.ContactInfoUrl;
                    }
                    if ($scope.ContactInputType == "Phone") {
                        contact.ContactInfo = $scope.contact.ContactInfoPhone;
                    }
                    ShowLoader("Please wait...");
                    if (!checkIfSameContactExists(contact)) {
                        contact.providerId = $scope.ProviderId;
                        contact.IsActive = true;
                        if (contact.ContactStatusId == 1 && contact.isEdit == true) {
                            //Update
                            $scope.originalContact.ContactStatusId = 2;
                            backofficeProviderAdressService.addressContactSave([$scope.originalContact], sessionStorage.Key)
                                .then(function (response) {
                                    //Add New
                                    contact.ContactStatusId = 1;
                                    contact.ProviderContactId = 0;
                                    backofficeProviderAdressService.addressContactSave([contact], sessionStorage.Key)
                                        .then(function (response) {
                                            $scope.hideStatusMessage();
                                            $scope.hideValidationSummary();
                                            if ($scope.checkServerResponse(response)) {
                                                // $scope.changeUserName([contact]);
                                                var requestData = {
                                                    EntityId: $scope.ProviderId,
                                                    ProviderContactId: response.RecordId,
                                                    
                                                };
                                                if (contact.ContactTypeId == 23) {
                                                    dcaIService.updateEmailForFirms(sessionStorage.Key, requestData);
                                                }
                                                else if (contact.ContactTypeId == 21 ) {
                                                    dcaIService.updatePhoneForFirms(sessionStorage.Key, requestData);
                                                }
                                                
                                                $scope.providerContactBYProviderId($scope.ProviderId);
                                                $scope.showStatusMessage(response.Message, "success");
                                                $scope.clearContact();
                                                $scope.viewConfig.showContactList = true;
                                            }
                                            HideLoader();
                                        }, function (data) {
                                            HideLoader();
                                            if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                                        });
                                }, function (data) {
                                    HideLoader();
                                    if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                                });
    
                        }
                        else {
                            backofficeProviderAdressService.addressContactSave([contact], sessionStorage.Key)
                                .then(function (response) {
                                    $scope.hideStatusMessage();
                                    $scope.hideValidationSummary();
                                    if ($scope.checkServerResponse(response)) {
                                        $scope.providerContactBYProviderId($scope.ProviderId);
                                        $scope.showStatusMessage(response.Message, "success");
                                        $scope.clearContact();
                                        $scope.viewConfig.showContactList = true;
                                        // $scope.$emit("individualAddressChanged", {});
                                    }
                                    HideLoader();
                                }, function (data) {
                                    HideLoader();
                                    if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                                });
                        }

                    } else {
                        $scope.showStatusMessage(Messages.General.Errors.SameContact, "error");
                        $(document).scrollTop(0);
                        HideLoader();
                    }

                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        var processContactsData = function (contactsData) {
            if (!!contactsData && contactsData.length != 0) {
                angular.forEach(contactsData, function (contact, index) {
                    angular.forEach($scope.ContactTypeList, function (contactType, index) {
                        if (contactType.ContactTypeId == contact.ContactTypeId) {
                            contact.Desc = contactType.Desc;
                        }
                    });
                    angular.forEach($scope.ContactStatuses, function (contactStatus, index) {
                        if (contact.ContactStatusId == contactStatus.ContactStatusId) {
                            contact.ContactStatusName = contactStatus.ContactStatusName;
                        }
                    });
                });
            }
            $scope.contactsData = contactsData;
            $scope.personalContact = contactsData;
            raiseContactLoadEvaent(contactsData);
        };

        var raiseContactLoadEvaent = function (data) {
            $scope.$emit('loadFirmContactData', data);
        };

        $scope.providerContactBYProviderId = function (providerId, processDataExternally) {
            try {
                var contactDataPromises = backofficeProviderAdressService.providerContactBYProviderId(sessionStorage.Key, providerId);
                contactDataPromises.then(function (response) {
                    if (!processDataExternally) {
                        var contactsData = response.ProviderContacts;
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse(response)) {
                            processContactsData(contactsData);
                        }
                    }
                }, function (data) {
                    $scope.showStatusMessage(data, "error");
                });
                return contactDataPromises;
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        //Seperate Contact ends here

        //Seperate Address starts here
        $scope.viewConfig.showAddressList = true;
        $scope.AddressStatuses = [
            { AddressStatusId: 1, AddressStatusName: 'Current' },
            { AddressStatusId: 2, AddressStatusName: 'Previous' },
            { AddressStatusId: 3, AddressStatusName: 'Other' },
            { AddressStatusId: 4, AddressStatusName: 'Pending' }
        ];

        $scope.datePicker = {
            beginDateOpened: false,
            endDateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            },
            startDateOptions: {
                maxDate: null
            },
            endDateOptions: {
                minDate: null
            }
        };

        $scope.setAddressEndMinDate = function (address) {
            if (address != undefined) {
                if (!!address.BeginDate && angular.isDate(address.BeginDate))
                    $scope.datePicker.endDateOptions.minDate = address.BeginDate;
            }
        };
        $scope.setAddressStartMaxDate = function (address) {
            if (address != undefined) {
                if (!!address.EndDate && angular.isDate(address.EndDate))
                    $scope.datePicker.startDateOptions.maxDate = address.EndDate;
            }
        };

        $scope.getStateByCountryID = function (CountryID) {
            $scope.StatesData = [];
            try {
                countryStateService.getStateByCountryID(sessionStorage.Key, CountryID)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.StatesData = response.State;
                        }
                    }, function (data) {
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        var processAddressData = function (addressData) {
            if (addressData != null) {
                angular.forEach(addressData, function (address, addressKey) {
                    angular.forEach($scope.AddressTypeList, function (addressType, index) {
                        if (addressType.AddressTypeId == address.AddressTypeId) {
                            address.AddressTypeDesc = addressType.AddressTypeDesc;
                        }
                    });

                    angular.forEach($scope.StatesData, function (state, index) {
                        if (state.StateCode == address.StateCode) {
                            address.State = state.Name;
                        }
                    });

                    angular.forEach($scope.AddressStatuses, function (addressStatus, index) {
                        if (address.AddressStatusId == addressStatus.AddressStatusId) {
                            address.AddressStatusName = addressStatus.AddressStatusName;
                        }
                    });
                })
            }
            $scope.AddressData = addressData;
            $scope.personalAddress = addressData;
            raiseAddressLoadEvaent(addressData);
        };

        var raiseAddressLoadEvaent = function (data) {
            $scope.$emit('loadFirmAddressData', data);
        };

        $scope.providerAddressBYProviderId = function (providerId, processDataExternally) {
            try {
                var addressDataPromise = backofficeProviderAdressService.providerAddressBYProviderId(sessionStorage.Key, providerId);
                addressDataPromise.then(function (response) {
                    if (!processDataExternally) {
                        var addressData = response.ProviderAddress;
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse(response)) {
                            processAddressData(addressData);
                        }
                    }
                }, function (data) {
                    $scope.showStatusMessage(data, "error");
                });
                return addressDataPromise;
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        $scope.openAddressAddNew = function (addressForm) {
            addressForm.$setPristine();
            $scope.clearAddress();
            $scope.viewConfig.showAddressList = false;
            $scope.address.BeginDate = new Date();
            $scope.setAddressStartMaxDate($scope.address);
            $scope.setAddressEndMinDate($scope.address);
            $scope.address.isEdit = false;
            $scope.originalAddress = angular.copy($scope.address);
        };

        $scope.editAddress = function (address, ProviderAddressId) {
            $scope.viewConfig.showAddressList = false;
            if (!!address.BeginDate && !angular.isDate(address.BeginDate))
                address.BeginDate = new Date(address.BeginDate);
            if (!!address.EndDate && !angular.isDate(address.EndDate))
                address.EndDate = new Date(address.EndDate);
            if (address.AdressStatusId === 0)
                address.AdressStatusId = "";
            $scope.setAddressStartMaxDate($scope.address);
            $scope.setAddressEndMinDate($scope.address);
            if (!!address.CountryId)
                $scope.getStateByCountryID(address.CountryId);

            $scope.address = angular.copy(address);
            $scope.address.isEdit = true;
            $scope.originalAddress = angular.copy($scope.address);

        };

        $scope.saveAddress = function (address, addressForm) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                addressForm.$submitted = true;
                if ($scope.validateData(addressForm)) {
                    //check if multiple current address                    
                    if (address.AddressStatusId == 1 && $scope.AddressData && $scope.AddressData.length > 0) {
                        var item = $.grep($scope.AddressData, function (i) {
                            return (i.AddressStatusId == 1 && address.AddressTypeId == i.AddressTypeId && address.AddressId != i.AddressId)
                        });
                        if (item && item.length > 0) {
                            var addressTypeName = '';
                            angular.forEach($scope.AddressTypeList, function (addressType, index) {
                                if (addressType.AddressTypeId == address.AddressTypeId) {
                                    addressTypeName = addressType.AddressTypeDesc;
                                }
                            });
                            $scope.showStatusMessage(Messages.General.Errors.AddressExits(addressTypeName), "error");
                            return;
                        };
                    }
                    ShowLoader("Please wait...");

                    if (address.AddressStatusId == 1 && address.isEdit == true) {

                        //Update  Address
                        // backofficeProviderAdressService.addressSave($scope.originalAddress, sessionStorage.Key)
                        //     .then(function (response) {
                        //         address.ProviderAddressId = 0;
                        //         address.AddressStatusId = 1;
                        //Insert new Address

                        if (angular.equals($scope.originalAddress, address)) {
                            address.ActionFlag = 'U';
                        }
                        else {
                            address.providerId = $scope.ProviderId;
                            address.IsActive = true;
                            address.ActionFlag = 'UI';
                        }
                        backofficeProviderAdressService.addressSave(address, sessionStorage.Key)
                            .then(function (response) {
                                $scope.providerAddressBYProviderId($scope.ProviderId);
                                $scope.clearAddress();
                                $scope.viewConfig.showAddressList = true;
                                $scope.showStatusMessage(response.Message, "success");
                                HideLoader();
                            }, function (data) {
                                HideLoader();
                                if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                            });
                        HideLoader();
                        // }, function (data) {
                        //     HideLoader();
                        //     if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                        // });
                    }
                    else {
                        if (address.isEdit) {
                            address.ActionFlag = 'U';
                        }
                        else {
                            address.providerId = $scope.ProviderId;
                            address.IsActive = true;
                            address.ActionFlag = 'UI';

                        }
                        backofficeProviderAdressService.addressSave(address, sessionStorage.Key)
                            .then(function (response) {
                                $scope.providerAddressBYProviderId($scope.ProviderId);
                                $scope.clearAddress();
                                $scope.viewConfig.showAddressList = true;
                                $scope.showStatusMessage(response.Message, "success");
                                HideLoader();
                            }, function (data) {
                                HideLoader();
                                if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                            });
                    }

                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        $scope.deleteAddress = function (address) {
            $scope.IndividualAddressContactAddStatus = true;
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                address.IsActive = true;
                address.IsDeleted = true;
                address.ActionFlag = 'U';
                ShowLoader("Please wait...");
                backofficeProviderAdressService.addressSave(address, sessionStorage.Key)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.providerAddressBYProviderId($scope.ProviderId);
                            $scope.showStatusMessage(response.Message, "success");
                            $scope.clearAddress();
                            $scope.viewConfig.showAddressList = true;
                            $scope.$emit("individualAddressChanged", {});
                        }
                        HideLoader();
                    }, function (data) {

                        HideLoader();
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            }
        };

        $scope.clearAddress = function () {
            $scope.address = {};
        };
        //Seperate Address  ends here 

    }
})();