﻿(function () {
    'use strict'
    angular.module('natApp')
        .factory('taskDirectiveFactory', ['$http', 'WebApiUrl', '$q', 'AppConfig', function ($http, WebApiUrl, $q, AppConfig) {

            var _saveIndividualTask = function (key, data) {
                return _getDeferResult($http.post(WebApiUrl + 'TaskManager/SaveIndividualTask/' + key, data));
            };

            var _getIndividualTaskList = function (key, data) {
                return _getDeferResult($http.post(WebApiUrl + 'TaskManager/GetIndividualTaskList/' + key, data));
            };

            var _getDeferResult = function (method) {
                var defer = $q.defer();
                method.then(function (res) {
                    defer.resolve(res);
                }, function (res) {
                    defer.reject(res);
                });
                return defer.promise;
            };

            return {
                GetIndividualTaskList: _getIndividualTaskList,
                SaveIndividualTask: _saveIndividualTask,
            };
        }]);
})();