(function () {
  'use strict'
  angular.module('app.InitialApplication')
    .directive('initialApplicationUploadDocument', initialApplicationUploadDocumentDirective);

  initialApplicationUploadDocumentDirective.$inject = [];

  function initialApplicationUploadDocumentDirective() {
    return {
      restrict: 'E',
      scope: {

      },
      templateUrl: "app/components/individual/application/directives/upload-document/upload-document.html",
      controller: "InitialApplicationUploadDocumentController"
    }
  }
})();