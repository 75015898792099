﻿(function () {
    'use strict';
    angular
        .module('app.lga')
        .factory("LGARegistrationFactory", ['$http', '$q', 'WebApiUrl', 'AppConfig', function ($http, $q, WebApiUrl, AppConfig) {
            var _licenseConversion = function (individualLicense, key) {
                return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualLicenseConversion/' + key, individualLicense));
            };

            var returnDefferedResult = function (request) {
                var deferred = $q.defer();
                request.then(function (response) {
                    deferred.resolve(response.data);
                }, function (response) {
                    deferred.reject(response);
                });
                return deferred.promise;
            };

            return {
                LicenseConversion: _licenseConversion,

            };
        }]);
})();