﻿(function () {
    'use strict';
    
    angular.module('task.management')
        .directive('taskManagementJobDetails', function () {
            return {
                restrict: 'EA',
                controller: "TaskManagementJobDetailsController",
                templateUrl: "app/components/backoffice/administrator/task-management-dev/task-management-job-details/task-management-job-details.html",
            };
        });
})();