(function() {
    'use strict'

    angular.module('natApp').directive('logDataChanges', [DataChangeLoggingDirective]);
    function DataChangeLoggingDirective() {
        return {
            restrict: 'AE',
            require: ['logDataChanges', '?^logParams'],
            link: function ($scope, element, attrs, ctrls) {
                //Optional name for the activity logger instance
                //Primarily used for debugging purposes
                var logCtrl = ctrls[0];
                var paramsCtrl = ctrls[1];

                logCtrl.name = attrs.logDataChanges;

                if (!!paramsCtrl) { 
                    $scope.params = paramsCtrl.params;
                    var removeWatch = $scope.$watch('params', function (newVal) {
                        logCtrl.setupParams(paramsCtrl.$scope, element, attrs);
                    }, true);
    
                    $scope.$on('$destroy', function () {
                        removeWatch(); 
                    });
                } else {
                    logCtrl.setupParams({}, element, attrs);
                }
            },
            controller: ['$scope', '$state', 'utilityService', 'DataChangeLoggingService', function ($scope, $state, utilityService, DataChangeLoggingService) {
                var self = this;

                self.config = {};
                self.watches = [];
                self.logAllChanges = function () {
                    self.watches.forEach(function (watchScope) {
                        watchScope.logChange();
                    });
                };

                utilityService.getConfigData('/core/directive/inlumon-data-change-logging/inlumon-data-change-logging.server-config.json')
                .then(function (configResults) {
                    self.config = configResults;
                    
                    var pageIds = [];
                    if (!!self.pageModuleIds) {
                        pageIds = self.pageModuleIds.split(',').select(function (item) { return item.trim(); });
                    }
                    
                    self.pageModuleId = (!!pageIds[0]) ? pageIds[0] : 1;
                    self.pageModuleTabSubModuleId = (!!pageIds[1]) ? pageIds[1] : 1;
                    self.pageTabSectionId = (!!pageIds[2]) ? pageIds[2] : 1;
                });

                self.setupParams = function (params, element, attrs) {
                    self.$scope = params;

                    var pageIds = [];
                    if (!!params.pageModuleIds) {
                        pageIds = params.pageModuleIds.split(',').select(function (item) { return item.trim(); });
                    }

                    self.individualId = (!!params.individualId) ? params.individualId : 0;
                    self.applicationId = (!!params.applicationId) ? params.applicationId : 0;

                    self.pageModuleId = (!!pageIds[0]) ? pageIds[0] : 1;
                    self.pageModuleTabSubModuleId = (!!pageIds[1]) ? pageIds[1] : 1;
                    self.pageTabSectionId = (!!pageIds[2]) ? pageIds[2] : 1;

                    self.logType = (!!params.logType) ? params.logType : 'L';
                    self.logChangeName = params.logChangeName;

                    if (!!attrs.lookFor) {
                        self.effectiveScope = element.closest(attrs.lookFor).scope();
                    } else {
                        self.effectiveScope = self.$scope.$parent;
                    }
                    
                    if (!!self.logChangeName) {
                        self.effectiveScope[self.logChangeName] = self.logAllChanges;
                    }
                };

                // Anything that needs to be shared with the directives that require logActivity needs to be added to "this", not "$scope"
                self.getIndividualId = function () {
                    return (!!self.individualId) ? self.individualId : 0;
                };

                self.getApplicationId = function () {
                    return (!!self.applicationId) ? self.applicationId: 0;
                };

                self.getEffectiveScope = function () {
                    return self.effectiveScope;
                };

                self.getNewModel = function () {
                    return {
                        IndividualId: self.getIndividualId(),
                        ApplicationId: self.getApplicationId(),
                        MasterTransactionId: 0,
                        PageModuleId: self.pageModuleId,
                        PageModuleTabSubModuleId: self.pageModuleTabSubModuleId,
                        PageTabSectionId: self.pageTabSectionId,
                        DataChangedBy: sessionStorage.UserID,
                        DataLogSource: $state.current.name.slice(0, 100),
                        FieldNameChanged: '',
                        FieldOldValue: '',
                        FieldNewValue: '',
                        ReferenceNumber: '',
                        BeforeDataText: '',
                        AfterDataText: '',

                        IsInternalOnly: true,
                        IsForInvestigationOnly: true,
                        IsForLegalOnly: true,
                        IsForPublic: true,

                        IsActive: true,
                        IsDeleted: false
                    };
                };

                self.registerWatch = function (watchScope) {
                    self.watches.push(watchScope);
                };

                self.removeWatch = function (watchScope) {
                    self.watches.conditionalSplice('item==this', watchScope);
                };

                self.getDiffObject = function (oldValue, newValue) {
                    var diff = {};

                    var getEquivalent = function (v) {
                        var vt = typeof(v);
                        var equivalent = null;

                        switch(vt) {
                            case 'undefined':
                                equivalent = null;
                                break;
                            case 'string':
                                if (!v.trim()) {
                                    equivalent = null;
                                } else {
                                    equivalent = v;
                                }
                                break;
                            default:
                                equivalent = v;
                                break;
                        }

                        return equivalent;
                    };

                    var isEquivalent = function (o, n) {
                        var equivalentO = getEquivalent(o);
                        var equivalentN = getEquivalent(n);
                        
                        return equivalentO == equivalentN;
                    };
                    
                    for (var prop in newValue) {
                        var newValType = typeof(newValue[prop]);
                        var oldValType = typeof(oldValue[prop]);
                        
                        if (newValType == 'undefined') {
                            newValue[prop] = null;
                        }
                        if (oldValType == 'undefined') {
                            oldValue[prop] = null;
                        }

                        var newVal = newValue[prop];
                        var oldVal = oldValue[prop];

                        if (newVal != null && oldVal != null) {
                            if (Date.isDate(newVal) && Date.isDate(oldVal)) {
                                var oldDate = oldVal.getDateObj();
                                var newDate = newVal.getDateObj();
                                
                                if (oldDate.toString() != newDate.toString()) {
                                    diff[prop] = [oldVal, newVal];
                                }
                            } else if (Array.isArray(newVal) && Array.isArray(oldVal)) {
                                if (!oldVal.equals(newVal)) {
                                    diff[prop] = [oldVal, newVal];
                                }
                            } else {
                                if (!isEquivalent(oldVal, newVal)) {
                                    diff[prop] = [oldVal, newVal];
                                }
                            }
                        } else {
                            if (!isEquivalent(oldVal, newVal)) {
                                diff[prop] = [oldVal, newVal];
                            }
                        }
                    }

                    return diff;
                };

                self.getDifferencesList = function (watchScope, oldValue, newValue) {
                    var differences = [];
                    var logConfig = self.config[watchScope.config];
                    var diff = self.getDiffObject(oldValue, newValue);

                    if (watchScope.configType == 'include') {
                        for (var prop in diff) {
                            var difference = { PropertyName: prop, FieldName: '', OldValue: '', NewValue: '' };

                            if (logConfig.include.includes(prop)) {
                                difference.FieldName = (!!logConfig.fields[prop]) ? logConfig.fields[prop] : prop;
                                difference.OldValue = diff[prop][0];
                                difference.NewValue = diff[prop][1];

                                differences.push(difference);
                            }
                        }
                    } else if (watchScope.configType == 'exclude') {
                        for (var prop in diff) {
                            var difference = { PropertyName: prop, FieldName: '', OldValue: '', NewValue: '' };

                            if (!logConfig.exclude.includes(prop)) {
                                difference.FieldName = (!!logConfig.fields[prop]) ? logConfig.fields[prop] : prop;
                                difference.OldValue = diff[prop][0];
                                difference.NewValue = diff[prop][1];

                                differences.push(difference);
                            }
                        }
                    }

                    return differences;
                };

                self.createLogChangeFn = function (name, fn) {
                    self.effectiveScope[name] = fn;
                };

                self.getPropertyVal = function (property) {
                    return self.effectiveScope[property];
                };

                self.getFieldName = function (diff, v) {
                    var fieldName = diff.FieldName;

                    for (var prop in v) {
                        var propReplace = new RegExp('\{(' + prop + ')\}', 'gi');

                        fieldName = fieldName.replace(propReplace, v[prop]);
                    }

                    return fieldName;
                };

                self.saveDataChangeLog = function (watchScope, oldValue, newValue) {
                    var changes = [];
                    var logConfig = self.config[watchScope.config];

                    //First get the list of differences
                    var differences = self.getDifferencesList(watchScope, oldValue, newValue);

                    //Iterate through the differences and create a new model for each.
                    var timeStamp = new Date();
                    var encryptedFields = (!!logConfig && !!logConfig.encryptedFields) ? logConfig.encryptedFields : [];

                    differences.forEach(function(diff) {
                        var model = self.getNewModel();
                        
                        model.ReferenceNumber = newValue[logConfig.id];
                        model.FieldNameChanged = self.getFieldName(diff, newValue).slice(0,50);

                        //Recent change will encrypt data in database columns
                        model.FieldOldValue = diff.OldValue;
                        model.FieldNewValue = diff.NewValue;
                        model.BeforeDataText = diff.OldValue;
                        model.AfterDataText = diff.NewValue;

                        //Only note the specific changes if the field isn't encrypted
                        // if (!encryptedFields.includes(diff.PropertyName)) {
                        //     model.FieldOldValue = diff.OldValue;
                        //     model.FieldNewValue = diff.NewValue;
                        //     model.BeforeDataText = diff.OldValue;
                        //     model.AfterDataText = diff.NewValue;
                        // } else {
                        //     model.FieldOldValue = '';
                        //     model.FieldNewValue = 'Encrypted';
                        //     model.BeforeDataText = '';
                        //     model.AfterDataText = 'Encrypted';
                        // }
                        model.DataChangedDateTime = timeStamp;

                        //Now add the model to the changes list
                        changes.push(model);
                    });
                    
                    //Log the changes, if there are any
                    if (!!changes && changes.length > 0) {
                        console.log(changes);
                        DataChangeLoggingService.saveDataChangeLog(sessionStorage.Key, changes)
                        .then(function (data) { if (!data.Status) { console.error(data); }}, function (err) { console.error(err); });
                    }
                };
            }]
        };
    }

    angular.module('natApp').directive('watchChanges', [WatchChangesDirective]);
    function WatchChangesDirective() {
        return {
            restrict: 'E',
            require: '^^logDataChanges',
            scope: {
                logChangeName: '@?',
                property: '@',
                config: '@',
                configType: '@?'
            },
            link: function ($scope, element, attrs, logDataChanges) {
                $scope.orgValue = null;
                $scope.newValue = null;
                $scope.refValue = null;
                $scope.configType = (!!$scope.configType) ? $scope.configType : 'include';
                $scope.lookFor = attrs.lookFor;

                $scope.logChange = function (newValue) {
                    if (!newValue) {
                        newValue = angular.copy($scope.newValue);
                    }
                    if (typeof($scope.orgValue) !== 'undefined' && $scope.orgValue != null) {
                        var orgValue = angular.copy($scope.orgValue);
                        logDataChanges.saveDataChangeLog($scope, orgValue, newValue);

                        $scope.newValue = null;
                        $scope.orgValue = null;
                        $scope.refValue = null;
                    }
                };

                var effectiveScope = null;
                if (!!$scope.lookFor) {
                    effectiveScope = element.closest($scope.lookFor);
                } else {
                    effectiveScope = $scope.$parent;
                }

                //Make sure the new property is watchable
                if (!$scope.property) {
                    console.log('You must define a scope variable in the controller to watch.');
                } else {
                    if (!!$scope.logChangeName) {
                        logDataChanges.createLogChangeFn($scope.logChangeName, $scope.logChange);
                    }

                    logDataChanges.registerWatch($scope);

                    var clearWatch = effectiveScope.$watch($scope.property, function (newValue, oldValue) {
                        var isNewObj = (newValue != $scope.refValue);   //Check if we are comparing an entirely new object or not

                        if (!!newValue && typeof(newValue) !== 'object') {
                            throw 'Data Change Log - Invalid Property - ' + $scope.property + ' must be an object';
                        }
                        if (!!oldValue && typeof(oldValue) !== 'object') {
                            throw 'Data Change Log - Invalid Property - ' + $scope.property + ' must be an object';
                        }

                        $scope.newValue = angular.copy(newValue);
                        //If there is no original value, or if the value is empty, 
                        //or if the value is a different object to compare
                        if (!$scope.orgValue || $scope.orgValue.isEmptyObj() || isNewObj) {
                            if (!!oldValue && !oldValue.isEmptyObj() && !isNewObj) {
                                $scope.orgValue = angular.copy(oldValue);
                            } else {
                                $scope.orgValue = angular.copy(newValue);
                            }
                        }
                        if (!newValue) {
                            $scope.orgValue = null;
                        }
                        if (isNewObj) {
                            $scope.refValue = newValue;
                        }
                    }, true);

                    $scope.$on('$destroy', function () {
                        clearWatch();
                        logDataChanges.removeWatch($scope);
                    });

                    //Setup the method call that will log the data changes for this watch
                    // if (!!$scope.logChangeName) {
                    //     $scope.$parent[$scope.logChangeName] = $scope.logChange;
                    // }
                }
            }
        };
    }
})();