(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('initialProviderApplicationFeePaymentService', initialProviderApplicationFeePaymentService)

        initialProviderApplicationFeePaymentService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function initialProviderApplicationFeePaymentService($http, $q, WebApiUrl, AppConfig) {

        var _providerProcessPayment = function (key, data) {
            var deferred = $q.defer();
            var urlParams = {
                Source: "cbafirm"
            };
            
            $http.post(WebApiUrl + 'ApplicationProcessing/CommonProcessPaymentAndApplicationForCBAFirms/' + key, data, { params: urlParams }).then(function (response) {
                response = response.data;
                deferred.resolve(response);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };
        
        var _getInvoiceMasterByIndividualIdAndInvoiceStatus = function (key, ProviderId, ApplicationId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + '/Individual/GetInvoiceMasterByIndividualIdAndApplicationIdAndInvoiceStatus/' + key + "?IndividualId=" + ProviderId + '&ApplicationId=' + ApplicationId + '&Source=Provider')
                .then(function(response){ response = response.data;
                    deferred.resolve(response);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _invoiceMasterSave = function (key, data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + '/Individual/InvoiceMasterSave/' + key + '?Source=' + AppConfig.Source, data).then(function(response){ response = response.data;
                deferred.resolve(response);
            }, function(error) { error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };
        var _getInvoiceDetailByIndividualIdAndInvoiceId = function (key, ProviderId, InvoiceId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + '/Individual/GetInvoiceDetail/' + key + "?ProviderId=" + ProviderId + "&InvoiceId=" + InvoiceId + '&Source=' + AppConfig.Source)
                .then(function(response){ response = response.data;
                    deferred.resolve(response);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _invoiceDetailSave = function (key, data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + '/Individual/InvoiceDetailSave/' + key + '?Source=' + AppConfig.Source, data).then(function(response){ response = response.data;
                deferred.resolve(response);
            }, function(error) { error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };
        
        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        var _sendEmailAndLogCommunication = function (key, data, sourceSuffix) {
            var deferred = $q.defer();
            var src = '';
            if (!sourceSuffix) {
                src = "WYRealEstateAMCFirm";
            }
            else {
                src = 'WYRealEstate' + sourceSuffix;
                // src = sourceSuffix;
            }
            $http.post(WebApiUrl + '/Payment/SendEmailAndLogCommunicationWY/' + key + '?Source=' + src, data).then(function(response){ response = response.data;
                deferred.resolve(response);
            }, function(error) { error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };
        var _getPaymentDetailsByApplicationIdAndIndividualId = function (key, ApplicationId, IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + '/Payment/GetPaymentDetailsByApplicationIdAndIndividualId/' + key + "?ApplicationId=" + ApplicationId + "&IndividualId=" + IndividualId + '&Source=' + AppConfig.Source + 'FIRM').then(function(response){ response = response.data;
                deferred.resolve(response);
            }, function(error) { error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _GetPaymentDetailsByIndividualId = function (key, providerid) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + '/Payment/GetPaymentDetailsByIndividualId/' + key + "?IndividualId=&ProviderId=" + providerid + '&Source=' + AppConfig.Source).then(function(response){ response = response.data;
                deferred.resolve(response);
            }, function(error) { error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getInvoiceMaster = function (key, urlParams) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetInvoiceMaster/' + key, {
                params: urlParams
            }));
        };

        var _getRevFeeCollect = function (key, urlParams) {
            return returnDefferedResult($http.get(WebApiUrl + 'EntityCashiering/GetRevFeeCollect/' + key, {
                params: urlParams
            }));
        };

        var _getInvoiceDetail = function (key, urlParams) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetInvoiceDetail/' + key, {
                params: urlParams
            }));
        };
        //

        return {
            providerProcessPayment: _providerProcessPayment,
            getInvoiceMasterByIndividualIdAndInvoiceStatus: _getInvoiceMasterByIndividualIdAndInvoiceStatus,
            invoiceMasterSave: _invoiceMasterSave,
            getInvoiceDetailByIndividualIdAndInvoiceId: _getInvoiceDetailByIndividualIdAndInvoiceId,
            invoiceDetailSave: _invoiceDetailSave,
            sendEmailAndLogCommunication: _sendEmailAndLogCommunication,
            getPaymentDetailsByApplicationIdAndIndividualId: _getPaymentDetailsByApplicationIdAndIndividualId,
            GetPaymentDetailsByIndividualId: _GetPaymentDetailsByIndividualId,
            getInvoiceMaster: _getInvoiceMaster,
            getRevFeeCollect: _getRevFeeCollect,
            getInvoiceDetail: _getInvoiceDetail
        };
    }
})();