(function () {
    'use strict';

    angular
        .module('app.provider')
        .controller("CeCreditByCourseController", CeCreditByCourseController);

    CeCreditByCourseController.$inject = ["$rootScope", "$scope", "$state", '$timeout', "initialApplicationEducationService", "bppService", "RegisterFactory", "htmlToPdfGenerationService",
        "countryStateService", "initialApplicationstatusService", "typeValuesService", "lookupService", "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl", "$q", "UploadDataUrl",
        'PromiseHandlers', 'CECreditByCourseService', 'Messages'
    ];

    function CeCreditByCourseController($rootScope, $scope, $state, $timeout, initialApplicationEducationService, bppService, RegisterFactory, htmlToPdfGenerationService,
        countryStateService, initialApplicationstatusService, typeValuesService, lookupService, utilityService, $exceptionHandler, TypeValue, WebApiUrl, $q, UploadDataUrl,
        PromiseHandlers, CECreditByCourseService, Messages) {
        var _key = sessionStorage.Key;
        var _docMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        var _pollingTimeout = 1000;

        $scope.invalidFiles = [];
        $scope.closeFileError = function(){
            $scope.invalidFiles = [];
        }
        $scope.invalidFiles2 = [];
        $scope.closeFileError2 = function(){
            $scope.invalidFiles2 = [];
        }

        $scope.uploadOptions = {
            getExampleDocumentApiUrl: 'Provider/GetCECourseHoursUploadExampleDocument',
            uploadDocumentApiUrl: 'Provider/UploadCECourseHours',
            fileExtensions: 'xlsx',
            getExampleDocumentMessage: 'Getting File...',
            uploadDocumentMessage: 'Validating File...'
        };

        $scope.generateUploadRequest = function (fileName, document, documentBase64, urlParams) {
            return {
                DocStrBase64: documentBase64
            };
        };

        $scope.getExampleDocumentResponse = function (data, response) {
            response.fileName = 'ExampleCECourseHours.xlsx';
            response.base64DocString = data.Base64ValidatedDocument;
            response.docMimeType = _docMimeType;
            response.message = 'Example file downloaded successfully';

            return true;
        };

        var startPollingValidationProgress = function (sharedMethods) {
            var userId = sessionStorage.UserID;
            var statusMessage = 'Stage: {0} out of {1}, {2}, {3} entries out of {4} processed...';
            var eventFlags = {
                destroyed: false
            };

            $scope.$on('$destroy', function () {
                eventFlags.destroyed = true;
            });

            //Begin polling of the task
            PromiseHandlers.poll(CECreditByCourseService.getValidationProgress)
            .success(function(data){
                if (!data.Initialized || eventFlags.destroyed) {sharedMethods.showLoader(false); return;}

                if (data.Status) {
                    if (data.IsComplete) {
                        if (data.PassedValidation) {
                            //Show success message
                            sharedMethods.showSuccessMessage(data.Message);
                            sharedMethods.showLoader(false);
                        } else {
                            //Show error message, download document
                            sharedMethods.showErrorMessage(data.Message);
                            sharedMethods.showLoader(false);
                            if (data.ResponseHasDocument) {
                                sharedMethods.downloadFile(data.DocumentBase64, data.DocumentType, 'FailedCECourseHours.xlsx');
                            }
                        }
                    } else {
                        var msg = statusMessage.format(data.CurrentStage, data.NumStages, data.StageMessage, data.NumEntriesProcessed, data.NumEntriesTotal);
                        var thisObj = this;
                        sharedMethods.showLoader(true, msg);

                        //Call again, but on a timer since we don't want to hammer the server with progress requests
                        $timeout(function () {thisObj.callAgain(_key, userId);}, _pollingTimeout);
                    }
                } else {
                    console.log(data);
                    sharedMethods.showErrorMessage(data.Message);
                    sharedMethods.showLoader(false);
                }
            })
            .error (function (error) {
                console.log(error);
                sharedMethods.showLoader(false);
            })
            .start(_key, userId);
        };

        $scope.getUploadDocumentResponse = function (data, response, sharedMethods) {
            if (data.Status) {
                startPollingValidationProgress(sharedMethods);
            }

            response.suppressMessage = true;
            response.hideLoader = false;
            
            return true;
        };

        $scope.setupSharedMethods = function (sharedMethods) {
            $scope.documentUploadMethods = sharedMethods;
        };

        $scope.initDocumentUpload = function () {
            $timeout(function () {
                $scope.documentUploadMethods.showLoader(true, 'Checking server for existing validation process...');
                startPollingValidationProgress($scope.documentUploadMethods);
            },1);
        };

        $scope.providerId = sessionStorage.ProviderId;
        var userId = sessionStorage.UserId;
        $scope.ceCourse = {};
        //$scope.addEditMode1 = false;
        $scope.datePicker = {
            beginDateOpened: false,
            endDateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            },
            startDateOptions: {
                maxDate: null
            },
            endDateOptions: {
                minDate: null
            }
        };
        $scope.viewConfig = {
            ReportHoursCourse: false,
            UploadEvaluation: false
        };
        $scope.natValidation = {};
        var init = function () {
            $scope.providerData = {
                ceCourseList: {},
                addEditMode1: false
            };
            //ShowLoader();
            $scope.providerCECourseByProviderId($scope.providerId, true, true);
            $scope.natValidation = {};
            //HideLoader();
        };

        $scope.AddNewDocument = function (files) {

            if ($scope.ceCourse.DocumentUploadList == undefined)
                $scope.ceCourse.DocumentUploadList = [];
            if (files && files.length) {
                var fileName = files[0].name.split('.');
                var extension = fileName[fileName.length - 1];
                if(extension =='xlsx'){
                    $scope.dataError = false;
                    UploadDataUrl.base64DataUrl(files).then(function (response) {
                        angular.forEach(response.file, function (currentDocFile) {
                            $scope.document = currentDocFile;
                            $scope.currentFileName = currentDocFile.name;
                            $scope.saveFileName = currentDocFile.name;
                            //var indAssExcel = $scope.indAssExcel;
                            //var date = new Date();

                        });
                    })
                }
                else{
                    $scope.dataUploadSuccess = false;
                    $scope.dataError = true;
                    $scope.messageToUser ="Use sample excel(.xlsx) file to upload the data. Sample excel file can be downloaded by clicking the link above";
                }
            }
            // var document = $scope.imageDocument;
        };

        $scope.parseDocument = function () {
            ShowLoader();
            if ($scope.document) {
                $scope.indAssExcel.DocStrBase64 = $scope.document.base64;
                $scope.indAssExcel.DocumentUploadList.push({
                    DocStrBase64: document.base64
                });
                fileParseService.indAssExcelParse($scope.indAssExcel, sessionStorage.Key)
                    .then(function (response) {
                        $scope.individualAssignmentList = response.IndAssParsedDataList;
                        if (!!response.Base64ValidatedDocument) {
                            $scope.messageToUser ="Please fix the data errors in the Excel file that is returned to you (as download.xlsx) and upload it again";
                            $scope.base64Data = response.Base64ValidatedDocument;
                            $scope.documentType = response.DocumentType;
                            $scope.hrefUrl = "data:" + $scope.documentType + ";base64," + $scope.base64Data;
                            $scope.documentName = "download.xlsx";
                            utilityService.downloadBase64File($scope.hrefUrl, $scope.documentName);
                            $scope.saveFileName = "download.xlsx";
                            $scope.dataError = true;
                            $scope.dataUploadSuccess = false;
                        }
                        else{
                            $scope.messageToUser = "Document uploaded successfully and bulk insert complete";
                            $scope.dataError = false;
                            $scope.dataUploadSuccess = true;
                        }

                        //$scope.dataInBase64 = $scope.base64File;

                        //utilityService.b64toBlob($scope.base64File,'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',512,'download.xlsx');
                        // $scope.personalInfo.ProfilePictureUrl = getProfileImageUrl(response.IndividualDocumentUploadList[0].DocumentUploadList[0].IndividualDocumentId);
                        // if (!$scope.imageDocumentsList)
                        //     $scope.imageDocumentsList = [];
                        // $scope.imageDocumentsList.push(response.IndividualDocumentUploadList[0].DocumentUploadList[0]);
                        //$scope.imageDocument.DocumentFile = null;
                        HideLoader();
                    });
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                $scope.resetDocumentList();
            }
            else {
                // if (!$scope.natValidation.errorMessages)
                $scope.natValidation.errorMessages = [];
                $scope.natValidation.errorMessages.push({
                    propName: "profilePicRequired",
                    errorMessage: Messages.General.Errors.SelectDocument
                });
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                HideLoader();
            }
        };

        $scope.showFileUploadDiv = function (providerCECourse) {
            $scope.ceCourse = angular.copy(providerCECourse);
        }

        $scope.providerCECourseByProviderId = function (providerId, hideMessages, isInitLoad) {

            ShowLoader("Please Wait...");
            var dataSavePromise = bppService.providerCECourseByProviderId(providerId, "", sessionStorage.Key);
            dataSavePromise.then(function (response) {
                $scope.providerCECourses = response.ProviderCourseResponseList;
                $scope.ceCourseList = response.ProviderCourseResponseList;
                if ($scope.providerCECourses != null)
                    $scope.totalCourses = $scope.providerCECourses.length;
                else
                    $scope.totalCourses = 0;

                if (hideMessages) {
                    $scope.hideStatusMessage();
                    $scope.hideValidationSummary();
                }
                //processListData();
                HideLoader();
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage({
                    res: error,
                    msg: "error"
                });
            });
            return dataSavePromise;
        };

        init();
    }

})();