(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('renewalTypeService', renewalTypeService);

    renewalTypeService.$inject = ['$http', '$q', 'WebApiUrl'];

    function renewalTypeService($http, $q, WebApiUrl) {

        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
                .then(function (res) {
                    if (success) {
                        var res = success(res);
                    }
                    deferred.resolve(res.data);
                }, function (error) {
                    if (fail) {
                        fail(error);
                    }
                    console.log("Request failed with status: " + error.status);
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _processDataInObjectsForm = function (res) {
            res.data.ContentItems = {};
            angular.forEach(res.data.ContentItemLk, function (contentItem) {
                res.data.ContentItems['contentItem' + contentItem.ContentItemLkCode] = contentItem;
            });
            return res;
        };

        var _getContent = function (key, contentCode, getInObjectsForm) {
            var urlParams = {
                ContentItemLkCode: contentCode
            }
            return responseHandler($http.get(WebApiUrl + 'Content/ContentGetBYContentItemLkCode/' + key + '/', {
                params: urlParams
            }), (getInObjectsForm ? _processDataInObjectsForm : null));
        };

        var _individualLicenseSave = function (individualLicenseObj, key) {
            return responseHandler($http.post(WebApiUrl + 'Individual/IndividualLicenseSave/' + key, individualLicenseObj));
        };

        var _legalInfoGet = function (individualId, applicationId, key, contentPageTabSectionId, contentItemLkIds, individualLicenseId) {
            return responseHandler($http.get(WebApiUrl + 'Individual/IndividualLegalGetByIndividualId/' + key + '?IndividualId=' + individualId +
                '&ApplicationId=' + applicationId +
                ((contentPageTabSectionId) ? ('&ContentLkToPageTabSectionIds=' + contentPageTabSectionId) : "") +
                ((contentItemLkIds) ? ('&ContentItemLkIds=' + contentItemLkIds) : "") +
                ((individualLicenseId) ? ('&IndividualLicenseId=' + individualLicenseId) : "")));
        };

        var _individualBYIndividualId = function (Key, IndividualId) {
            return responseHandler($http.get(WebApiUrl + 'Individual/IndividualBYIndividualId/' + Key + '?IndividualId=' + IndividualId));
        }

        return {
            individualLicenseSave: _individualLicenseSave,
            getContent: _getContent,
            legalInfoGet: _legalInfoGet,
            individualBYIndividualId: _individualBYIndividualId
        };
    }
})();