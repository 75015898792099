﻿(function () {
    'use strict'
    angular.module('app.account')
        .controller('FirmRegisterController',["$scope", "$controller", "$state", 'AppConfig', 'RegisterFactory', 'MessageFactory', '$filter', '$q', 'countryStateService', 'Messages', 
			function($scope, $controller, $state, AppConfig, RegisterFactory, MessageFactory, $filter, $q, countryStateService,Messages) {

                $scope.login = function () {
                    $state.go('app.FirmLogin');
                };

                $scope.dateOptions = { format: 'MM/DD/YYYY' };

                $scope.formats = ['MM/dd/yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
                $scope.format = $scope.formats[0];

                $scope.open1 = function () {
                    $scope.popup1.opened = true;
                };
                $scope.popup1 = {
                    opened: false
                };
                $scope.altInputFormats = ['M!/d!/yyyy'];

                $scope.maskConfig = {
                    clearOnBlur: false,
                    allowInvalidValue: false,
                    clearOnBlurPlaceholder: true
                };

                $scope.getStateByCountryID = function (CountryID) {
                    $scope.StatesData = [];
                    try {
                        countryStateService.getStateByCountryID("Key", CountryID)
                            .then(function (response) {
                                $scope.StatesData = response.State;
                                
                            }, function (data) {
                                if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                            });
                    } catch (ex) {
                        if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                        $exceptionHandler(ex);
                    }
                };
                
                $scope.getStateByCountryID(319);

                $scope.Individual = {};
                var isUserExists = false;
                $scope.Register = function () {

                    //$scope.hideStatusMessage();
                    //$scope.Individual.IsNewApplicant = false;
                    //if (validateData('regvalidation')) {
                    //    ShowLoader("Please wait...");
                    //    CheckExistingMemberforRegistration();
                    //} else {
                    //    $scope.statusMessage = {};
                    //}

                    $scope.hideStatusMessage();
                    if (!validateData('regvalidation'))
                        return;

                    // if (!angular.isDate($scope.provider.DateOfBirth)) {
                    //     $scope.showStatusMessage(Messages.eddbbfd, 'error');
                    //     return;
                    // }
                    // if (!validateDateOFBirth(new Date(angular.copy($scope.Individual.DateOfBirth)))) {
                    //     $scope.showStatusMessage(Messages.eddbbfd, 'error');
                    //     return;
                    // }
                    ShowLoader("Please wait...");
                    CheckExistingUserForRegistration();
                };

                $scope.RegisterNewApplicant = function (form) {
                    $scope.hideStatusMessage();
                    if (!validateData('naregistrationval'))
                        return;
                    // if (!angular.isDate($scope.Individual.DateOfBirth)) {
                    //     $('#dpregisterdob').addClass("required-text");
                    //     $scope.showStatusMessage(Messages.aeeedce, 'error');
                    //     return;
                    // }
                    // if (!validateDateOFBirth(new Date(angular.copy($scope.Individual.DateOfBirth)))) {
                    //     $('#dpregisterdob').addClass("required-text");
                    //     $scope.showStatusMessage(Messages.eddbbfd, 'error');
                    //     return;
                    // }
                    //email                    
                    if ($scope.provider.ConfirmEmail.trim().toLowerCase() != $scope.provider.Email.trim().toLowerCase()) {
                        $('#confirmemail').addClass("required-text");
                        $scope.showStatusMessage(Messages.Registration.Errors.EmailConfirmationMatch, 'error');
                        return;
                    }
                    ShowLoader("Please wait...");
                    CheckExistingUserForRegistration();
                };

                $scope.CheckExistingUserForRegistrationStatusCode = 0;
                $scope.CheckExistingUserForRegistrationIndvID = 0;
                var CheckExistingUserForRegistration = function () {
                    var reqData = angular.copy($scope.provider);
                    reqData.BusinessName = $scope.provider.FirmName.trim().toUpperCase();
                    RegisterFactory.CheckExistingEntityUserForRegistration(reqData).then(function (res) {
                        var errMessage = res.data.StatusMessage;
                        var userID = res.data.UserId;
                        if (res.data.Status) {
                            //you are already registered in the system with the Email Address entered
                            if (res.data.StatusID == 1) {
                                HideLoader();
                                $scope.showStatusMessage(errMessage, 'error');
                            }
                            //Existing user registration
                            else if (res.data.StatusID == 4) {
                                ApplicationTypeId = 7;
                                ApplicationStatusReasonId = null;
                                ApplicationInternalStatusId = 0;
                                $scope.CheckExistingUserForRegistrationStatusCode = res.data.StatusID;
                                $scope.CheckExistingUserForRegistrationIndvID = res.data.EntityId;
                                GenerateApplicationId(0, userID, 7, 0, 0);
                            } else {
                                HideLoader();
                            }
                        } else {
                            HideLoader();
                        }
                    }, function (res) {
                        $scope.showStatusMessage(Messages.Registration.RegistrationError, 'error');
                        HideLoader();
                    });
                };


                var SaveProviderOther = function (applicationId) {
                    var deffer = $q.defer();
                    var reqData = {
                        ProviderId: $scope.provider.ProviderId,
                        ApplicationId: applicationId,
                        SMSOptOut: $scope.provider.SMSOptOut,
                        IsActive: 1,
                        IsDeleted: 0
                    };
                    RegisterFactory.SaveProviderOther(sessionStorage.Key, [reqData]).then(function (res) {
                        deffer.resolve(res);
                    }, function (res) {
                        deffer.reject();
                    });
                    return deffer.promise;
                };

                var SaveProvider = function (applicationId) {
                    $scope.provider.IsActive = true;
                    $scope.provider.IsEnabled = true;
                    $scope.provider.IsDeleted = false;
                    $scope.provider.ProviderTypeId = 5;
                    $scope.provider.ApplicationId = applicationId;
                    $scope.provider.ProviderStatusTypeId = 1;
                    $scope.provider.DepartmentId = 2;
                    $scope.provider.ProviderName = $scope.provider.FirmName.trim().toUpperCase();
                    $scope.provider.TaxId = $scope.provider.ZipCode;
                    $scope.provider.ClosedDate = $scope.provider.IssuanceDate;
                    $scope.provider.SMSOptOut = false;
                    RegisterFactory.SaveProvider(sessionStorage.Key, applicationId, 0, $scope.provider).then(function (res) {
                        if (res.data.Status) {
                            $scope.provider.ProviderId = res.data.ProviderId;                            
                            if (res.data.Status) {
                                var reqData = {
                                    skipSMS: true,
                                    EntityId: $scope.provider.ProviderId,
                                    EntityType: "P",
                                    ApplicationID: applicationId,
                                    UserName: $scope.provider.Email,
                                    UserTypeId: 7,
                                    Email: $scope.provider.Email,
                                    FirstName: $scope.provider.FirstName,
                                    LastName: $scope.provider.LastName,
                                    EntityName: $scope.provider.FirmName.trim().toUpperCase(),
                                    DateOfBirth: $scope.provider.DateOfBirth,
                                    Phone: $scope.provider.Phone,
                                    UserStatusId: 1,
                                    TemporaryPassword: true,
                                    SourceId: 1,
                                    IsDeleted: 0,
                                    IsArchive: 0,
                                    CreatedOn: new Date(),

                                };
                                UsersSave(reqData, applicationId);
                            }
                            
                        } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(res.data.Message, 'error');
                    });
                };

                var ApplicationTypeId = 0;
                var ApplicationStatusReasonId = 0;
                var ApplicationInternalStatusId = 0;

                var GenerateApplicationId = function (ApplicationID, UserID) {

                    var Urlparm = '/key?ApplicationId=' + ApplicationID + '&UserId=' + UserID;
                    var reqData = {
                        ApplicationTypeId: ApplicationTypeId,
                        ApplicationStatusId: 6,
                        ApplicationSubmitMode: 'O',
                        ApplicationStatusReasonId: ApplicationStatusReasonId,
                        GoPaperless: true,
                        IsDeleted: 0,
                        IsActive: 1,
                        IsArchive: 0,
                        ApplicationInternalStatusId: ApplicationInternalStatusId
                    };

                    RegisterFactory.GenerateApplicationId(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) {
                            var appID = res.data.ApplicationId;
                            if ($scope.CheckExistingUserForRegistrationStatusCode == 4) {
                                SaveProvider(appID);
                            }
                            else if ($scope.CheckExistingUserForRegistrationStatusCode == 2) {
                                var reqData = {
                                    EntityId: $scope.CheckExistingUserForRegistrationIndvID,
                                    EntityType: "P",
                                    ApplicationID: appID,
                                    UserName: $scope.provider.Email,
                                    UserTypeId: 7,
                                    Email: $scope.provider.Email,
                                    FirstName: $scope.provider.FirstName,
                                    LastName: $scope.provider.LastName,
                                    EntityName: $scope.provider.FirmName.trim().toUpperCase(),
                                    Phone: $scope.provider.Phone,
                                    UserStatusId: 1,
                                    TemporaryPassword: true,
                                    SourceId: 1,
                                    IsDeleted: 0,
                                    IsArchive: 0,
                                    CreatedOn: new Date(),
                                };
                                UsersSave(reqData, appID);
                            }
                            else {
                                HideLoader();
                            }
                        } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }

                    }, function (res) {
                        HideLoader();
                        var err = 'Error in the Registration Process: ApplicationSave, ApplicationTypeId=' + ApplicationTypeId + '  (Reference Code: 00039). When contacting Board Office, please refer the Reference Code in the communication'
                        $scope.showStatusMessage(Messages.abdfade, 'error');
                    });
                };

                $scope.preventPaste = function (e) {
                    e.preventDefault();
                    return false;
                };

                var SaveIndividualNameTypeLk = function (appID, indvID) {
                    var Urlparm = '/Key';
                    var reqData = {
                        IndividualId: indvID,
                        IndividualNameTypeId: 1,
                        IndividualNameStatusId: 1,
                        ApplicationId: appID,
                        IsActive: 1,
                        IsDeleted: 0,
                    };
                    RegisterFactory.SaveIndividualNameTypeLk(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) { } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(Messages.Registration.IndividualNameTypeLkSave(ApplicationTypeId), 'error');
                    });
                };
                var IndividualContactSave = function (indvID, userid, appID) {
                    var Urlparm = '/Key';
                    var reqData = {
                        ProviderId: indvID,
                        ContactId: 0,
                        ContactTypeId: 23,
                        ContactStatusId: 1,
                        ApplicationId: appID,
                        ContactInfo: $scope.provider.Email,
                        IsActive: 1,
                        IsDeleted: 0
                    };
                    RegisterFactory.ProviderContactSave(Urlparm, [reqData]).then(function (res) {

                        HideLoader();
                        if (res.data.Status) {
                            $scope.registrationSuccess = Messages.Registration.Success.Registration
                            $scope.showStatusMessage(res.data.Message, 'success');
                        } else {
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(res.data.Message, 'error');
                    });
                };

                var IndividualContactPhoneSave = function (indvID, userid, appID) {
                    var deffer = $q.defer();
                    var Urlparm = '/Key';
                    var reqData = {
                        ProviderId: indvID,
                        ContactId: 0,
                        ContactTypeId: 21,
                        CreatedBy: userid,
                        ContactStatusId: 1,
                        ApplicationId: appID,
                        ContactInfo: $scope.provider.Phone,
                        IsActive: 1,
                        IsDeleted: 0
                    };
                    RegisterFactory.ProviderContactSave(Urlparm, [reqData]).then(function (res) {
                        deffer.resolve();
                    }, function (res) {
                        deffer.reject();
                    });
                    return deffer.promise;
                };
                var UsersSave = function (reqData, AppID) {
                    var Urlparm = '/Key?Source=' + 'cbafirm';
                    var indvID = $scope.CheckExistingUserForRegistrationIndvID;
                    reqData.UserRoles=[{
                        UserID: null,
                        Selected: true,
                        RoleId: 2
                    }];
                    
                    RegisterFactory.UsersEntitySave(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) {

                            var user = res.data.Users[0];
                            var uId = user.UserId;
                            if (!isUserExists) {
                                // var reqData = [{
                                //     UserID: uId,
                                //     Selected: true,
                                //     RoleId: 2
                                // }];
                                // UserRoleSave(reqData);
                                // SaveIndividualApplication
                                SaveProviderApplication($scope.provider.ProviderId, AppID, uId);
                            } else {
                                //user already exist condition
                                // check role else insert
                                ShowMessageExistingUserLogin(user.UserName, user.UserStatusId, res);
                                // UserRoleGetBYUserID(uId);
                                ResetPassword(uId);
                            }
                            reqData = {
                                ApplicationId: AppID,
                                ApplicationTypeId: 6,
                                ApplicationStatusId: 6,
                                ApplicationStatusReasonId: 1,
                                IsActive: 1,
                                IsDeleted: 0,
                                IsArchive: 0,
                                CreatedBy: uId,
                                ApplicationInternalStatusReasonText: '',
                                ApplicationInternalStatusId: 7,
                                HighPriority: false
                            };
                            IndividualContactSave($scope.provider.ProviderId, uId, AppID);
                            IndividualContactPhoneSave($scope.provider.ProviderId, uId, AppID);
                            // ApplicationStatusLkSave(uId, reqData);

                        } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(Messages.Registration.Errors.UsersProcess(ApplicationTypeId), 'error');
                    });
                };
                var UserRoleSave = function (reqData) {
                    var Urlparm = '/Key';
                    RegisterFactory.UserRoleSave(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) {
                            $scope.registrationSuccess = "Please check your email a temporary password has been sent to you. You need to login using that password."
                        } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(Messages.Registration.Errors.UsersProcess(ApplicationTypeId), 'error');
                    });
                };
                var _IndividualName = '';
                var CheckExistinCheckExistingEntityUserForRegistrationgMemberforRegistration = function () {
                    var Urlparm = '/Key';
                    RegisterFactory.CheckExistingMemberforRegistration(Urlparm, $scope.Individual).then(function (res) {
                        if (res.data.Status) {
                            var individualID = res.data.IndividualID;
                            _IndividualName = res.data.FirstName;
                            GetUserByIndividualId(individualID);
                        } else {
                            HideLoader();
                            //   console.log(res.data.Message);
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(res.data.Message, 'error');
                    });
                };

                var GetUserByIndividualId = function (individualID, userid, appid) {
                    var Urlparm = '/key?IndividualId=' + individualID + '&UserId=' + userid;
                    RegisterFactory.UserByIndividualId(Urlparm).then(function (res) {
                        if (res.data.Status) {
                            var user = res.data.Users;
                            var userStatusId = 0;
                            var userID = 0;
                            var userName = '';
                            if (user) {
                                isUserExists = true;
                                var userStatusId = user.UserStatusId;
                                var userName = user.UserName;
                                var userID = user.UserId;
                                //update user table if user name is null or empty
                                if (userName && userName != "") {
                                    if ((userName.toLowerCase() == $scope.Individual.Email.toLowerCase().trim()) && (userStatusId == 4 || userStatusId == 6)) {
                                        ResetPassword(userID);
                                    }
                                    ShowMessageExistingUserLogin(userName, userStatusId, res);
                                    HideLoader();
                                } else {
                                    //update user here
                                    user.UserName = $scope.Individual.Email;
                                    user.Email = $scope.Individual.Email;
                                    user.UserStatusId = 4;
                                    user.UserTypeId = 6;
                                    user.TemporaryPassword = true;
                                    user.FirstName = $scope.Individual.FirstName;
                                    user.LastName = $scope.Individual.LastName;
                                    user.DateOfBirth = angular.copy($scope.Individual.DateOfBirth);
                                    user.Phone = $scope.Individual.Phone;
                                    user.ApplicationID = appid,
                                        UsersSave(user, appid);
                                    UserChangeLogSave(userID);
                                }
                            } else {
                                HideLoader();
                            }

                        } else {
                            var indvID = $scope.CheckExistingUserForRegistrationIndvID;
                            SaveIndividualNameTypeLk(appid, indvID);

                            //UserTypeId = 6, UserStatusId = 4, TemporaryPassword = True, IndividualId = IndividualId
                            var reqData = {
                                UserName: $scope.Individual.Email,
                                UserTypeId: 6,
                                UserStatusId: 4,
                                TemporaryPassword: true,
                                IndividualId: individualID,
                                FirstName: $scope.Individual.FirstName,
                                LastName: $scope.Individual.LastName,
                                Email: $scope.Individual.Email,
                                DateOfBirth: angular.copy($scope.Individual.DateOfBirth),
                                Phone: $scope.Individual.Phone,
                                SourceId: 0,
                                IsDeleted: 0,
                                IsArchive: 0,
                                CreatedOn: new Date(),
                                ApplicationID: appid
                            };
                            UsersSave(reqData, appid);
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(res.data.Message, 'error');
                    });
                };

                //
                var UserChangeLogSave = function (userID) {
                    var Urlparm = '/key?userId=' + userID;
                    var reqData = {
                        UserChangeLogId: 0,
                        UserId: userID,
                        UserName: '',
                        UserTypeId: 6,
                        UserStatusId: 4,
                        UserChangeDateTime: new Date(),
                        UserChangedBy: userID,
                        ReferenceNumber: '',
                        UserChangeReasonText: '',
                        UserChangeLogGuid: 'Guid'
                    };
                    RegisterFactory.UserChangeLogSave(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) {

                        } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(Messages.Registration.Errors.UserChangeLogSaveProcess(ApplicationTypeId), 'error');
                    });
                };

                var ApplicationStatusChangeLogSave = function (appID, userID) {
                    var Urlparm = '/key?UserId=' + userID;
                    reqData = {
                        ApplicationId: appID,
                        ApplicationStatusReasonId: 1,
                        ApplicationNumber: '',
                        ApplicationTypeId: 6,
                        ApplicationStatusId: 6,
                        ApplicationSubmitMode: '',
                        ApplicationStatusChangedBy: userID,
                        ReferenceNumber: '',
                        HighPriority: '',
                        ApplicationInternalStatusId: 7,
                        ApplicationInternalStatusReasonText: '',
                        ApplicationStatusChangeLogGuid: 'guid'
                    };
                    RegisterFactory.ApplicationStatusChangeLogSave(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) { } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'success');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(res.data.Message, 'error');
                    });
                };


                var ApplicationStatusLkSave = function (userID, reqData) {
                    var Urlparm = '/key?UserId=' + userID;
                    RegisterFactory.ApplicationStatusLkSave(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) { } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'success');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(Messages.Registration.Errors.ApplicationStatusLkSaveProcess(ApplicationTypeId), 'error');
                    });
                }

                var ShowMessageExistingUserLogin = function (userName, userStatusId, res) {
                    //var msg = '';
                    if (!userName || userName == '' || (userName.toLowerCase() == $scope.Individual.Email.toLowerCase().trim())) {
                        if (userStatusId == 1) {
                            $scope.showStatusMessage(MessageFactory.Alreadyreg, "error");
                        } else if (userStatusId == 2 || userStatusId == 3) {
                            $scope.showStatusMessage(MessageFactory.PlsUseForgotPass, "error");
                        } else if (userStatusId == 4 || userStatusId == 6) {
                            $scope.registrationSuccess = "Please check your email a temporary password has been sent to you. You need to login using that password."
                        } else if (userStatusId = 5) {
                            $scope.showStatusMessage(MessageFactory.ContactCAMTC, "error");
                        }
                    } else {
                        $scope.showStatusMessage(MessageFactory.UserEmailShouldSame, "error");
                    }
                };

                var ResetPassword = function (userId) {
                    var Urlparm = '/key';
                    var reqData = [{ UserId: userId }];
                    RegisterFactory.ResetPassword(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) { } else {
                            HideLoader();
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(res.data.Message, 'error');
                    });
                };

                var SaveProviderApplication = function (providerId, applicationId, userID) {
                    if (providerId && providerId != 0 && applicationId && applicationId != 0) {
                        var Urlparm = '/key';
                        var reqData = {
                            ApplicationId: applicationId,
                            ProviderId: providerId,
                            CreatedBy: userID,
                            CreatedOn: new Date()
                        };
                        RegisterFactory.SaveProviderApplication(Urlparm, reqData).then(function (res) {
                            if (res.data.Status) { } else {
                                HideLoader();
                            }
                        }, function (res) {
                            HideLoader();
                            $scope.showStatusMessage(Messages.fecbada, 'error');

                        });
                    }
                };

                var UserRoleGetBYUserID = function (uID) {
                    var urlParm = "/Key?UserId=" + uID;
                    RegisterFactory.UserRoleGetBYUserID(urlParm).then(function (res) {
                        if (!res.data.UserRole || res.data.UserRole.length == 0) {
                            var reqData = [{
                                UserID: uID,
                                Selected: true,
                                RoleId: 2
                            }];
                            UserRoleSave(reqData);
                        }
                        HideLoader();
                    }, function () {
                        HideLoader();
                    });
                };
                //audit api call
                var AuditvisitInfoSave = function () {
                    var parm = 'key';
                    var data = {
                        HostIPAddress: location.host,
                        PageName: 'Ingividual Registration',
                        RequestUrl: window.location.href,
                        RequestUrlReferrer: document.referrer,
                        RequestBrowserTypeVersion: 'Type: ' + navigator.appCodeName + ', Version: ' + navigator.appVersion,
                        SessionID: '', //document.cookie,
                        TimeStamp: new Date(),
                        IndividualId: 0,
                        EntityId: '',
                        UserId: 0,
                        Platform: navigator.platform,
                        MachineDeviceName: '',
                        DeviceId: '',
                        AppDomainName: '',
                        UserHostAddress: '',
                        UserHostName: '',
                        IsJavascriptEnabled: true,
                        IsCookieEnabled: navigator.cookieEnabled,
                        IsCrawler: '',
                        IsActiveXControlEnabled: ''
                    };
                    RegisterFactory.AuditvisitInfoSave(parm, data).then(function (res) {
                        /// auditSaved
                    }, function (res) {

                    });
                };
                AuditvisitInfoSave();
                //indv log save
                var IndividualLogSave = function (uid, indvID, appID) {
                    var parem = 'key'
                    var reqData = {
                        IndividualId: indvID,
                        ApplicationId: appID,
                        LogSource: "ApplicantRegistration",
                        LogText: "Applicant Registration Submitted",
                        ReferenceNumber: '',
                        IsActive: 1,
                        IsDeleted: 0,
                        CreatedBy: uid,
                        LogMessageId: 1
                    };
                    RegisterFactory.IndividualLogTableSave(parem, reqData).then(function (res) {
                        // console.log(res);
                    }, function (res) {
                        $scope.showStatusMessage(Messages.Registration.Errors.IndividualLogSaveProcess, 'error');
                    });
                };

                var _MS_PER_DAY = 1000 * 60 * 60 * 24;
                // a and b are javascript Date objects
                var dateDiffInDays = function (a, b) {
                    // Discard the time and time-zone information.
                    var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
                    var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
                    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
                };

                var validateDateOFBirth = function (dateofBirth) {
                    var s = dateDiffInDays(dateofBirth, new Date());
                    var validationYear = 18;
                    if (s < (365 * validationYear)) {
                        return false;
                    }
                    return true;
                };

                var getMaskedEmail = function (email) {
                    var res = email;
                    //if email length >6 make first 3 char * and last 3 char *
                    var ind = email.indexOf('@');
                    if (ind > 6) {
                        // console.log(ind - 3)
                        res = '***' + email.substr(3, (ind - 6)) + '***' + email.substr(ind)
                    }
                    //if email length <6 make first 3 char * and last 3 char *
                    else if (ind >= 3) {
                        var ind = email.indexOf('@');
                        res = '***' + email.substr(3, ind - 3) + email.substr(ind)
                    }
                    return res;
                };
            }
        ])
})();