(function () {
    'use strict'

    angular.module('app.compliance')
    .directive('complianceSearchValidation', [ComplianceSearchValidation]);

    function ComplianceSearchValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function () {
                    return true;
                }
            },
        };
    }
})();