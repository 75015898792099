(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('applicationTypeSelectionLogic', ['LicenseSettings', ApplicationTypeSelectionLogic]);

    function ApplicationTypeSelectionLogic(LicenseSettings) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.isBadLicenseCombo = false;

                $scope.$dataLoaded = function () {
                    var types = $scope.initialLicenseApplicationTypes;
                    var physicianCategory = 'MD/DO';
                    
                    var physicianCatsList = types.whereEquals(physicianCategory, 'ApplicationSubTypeCode').orderBy('ApplicationSubTypeName');
                    var otherCatsList = types.whereNotEquals(physicianCategory, 'ApplicationSubTypeCode').orderBy('ApplicationSubTypeName');

                    $scope.initialLicenseApplicationTypes = physicianCatsList.concat(otherCatsList);


                    var subTypes = $scope.initialLicenseApplicationSubTypes;
                    var physicianLicenseTypes = [29,33];
                    
                    var physicianList = subTypes.whereIncludes(physicianLicenseTypes, 'LicenseTypeId').orderBy('LicenseApplicationForName');
                    var othersList = subTypes.whereNotIncludes(physicianLicenseTypes, 'LicenseTypeId').orderBy('LicenseApplicationForName');

                    $scope.initialLicenseApplicationSubTypes = physicianList.concat(othersList);

                    if (!!$scope.individual) {
                        $scope.individual.activePendingLicenseTypes = $scope.individual.existingLicenses.whereIncludes(LicenseSettings.ActivePendingStatuses, 'LicenseStatusTypeId').select('LicenseTypeId');
                        $scope.individual.activeLicenseTypes = $scope.individual.existingLicenses.whereIncludes(LicenseSettings.ActiveStatuses, 'LicenseStatusTypeId').select('LicenseTypeId');
                    }
                };

                $scope.$validateAppTypeSelection = function () {
                    $scope.isBadLicenseCombo = false;
                    
                    var physLics = [29,33];
                    var physRequiredLics = [10,12,42,69,70,94];
                    var podLics = [36];
                    var podRequiredLics = [37,38];
                    var medPsyLics = [24];
                    var medPsyRequiredLics = [25];
                    var internLics = [15];
                    var internRequiresLics = [103];

                    //Check that someone attempting to apply for a Physician-required license has a Physician license (either active or in process)
                    if ((!!$scope.applicationType.LicenseTypeId && physRequiredLics.includes($scope.applicationType.LicenseTypeId))) {
                        if (!!$scope.individual && !!$scope.individual.activePendingLicenseTypes && $scope.individual.activePendingLicenseTypes.whereIncludes(physLics).length > 0) {
                        } else {
                            $scope.showStatusMsg('-', 'You must be an active, licensed physician, or have a physician application in process, to apply for this license.');
                            $scope.isBadLicenseCombo = true;
                        }
                    }

                    //Check that someone attempting to apply for Podiatrist-required license has a Podiatrist license (either active or in process)
                    if ((!!$scope.applicationType.LicenseTypeId && podRequiredLics.includes($scope.applicationType.LicenseTypeId))) {
                        if (!!$scope.individual && !!$scope.individual.activePendingLicenseTypes && $scope.individual.activePendingLicenseTypes.whereIncludes(podLics).length > 0) {
                        } else {
                            $scope.showStatusMsg('-', 'You must be an active, licensed podiatrist, or have a podiatrist application in process, to apply for this license.');
                            $scope.isBadLicenseCombo = true;
                        }
                    }

                    //Check that someone attempting to apply for Medical Psychologist-required license has a Medical Psychologist license (either active or in process)
                    if ((!!$scope.applicationType.LicenseTypeId && medPsyRequiredLics.includes($scope.applicationType.LicenseTypeId))) {
                        if (!!$scope.individual && !!$scope.individual.activePendingLicenseTypes && $scope.individual.activePendingLicenseTypes.whereIncludes(medPsyLics).length > 0) {
                        } else {
                            $scope.showStatusMsg('-', 'You must be an active, licensed medical psychologist, or have a medical psychologist application in process, to apply for this license.');
                            $scope.isBadLicenseCombo = true;
                        }
                    }

                    //Check that someone attempting to apply for a Internship Beyond Year 1 license has an Internship license (must be active)
                    if ((!!$scope.applicationType.LicenseTypeId && internRequiresLics.includes($scope.applicationType.LicenseTypeId))) {
                        if (!!$scope.individual && !!$scope.individual.activeLicenseTypes && $scope.individual.activeLicenseTypes.whereIncludes(internLics).length > 0) {
                        } else {
                            $scope.showStatusMsg('-', 'You must be an active, licensed PGY to apply for this license.');
                            $scope.isBadLicenseCombo = true;
                        }
                    }
                };

                $scope.$canCreateApplication = function () {
                    return !$scope.isBadLicenseCombo;
                };
            }
        };
    }
})();