(function() {
    'use strict'

    angular.module('natApp').directive('inlumonCss', [inlumonCssDirective]);

    function inlumonCssDirective() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                var stylesheets = eval(attrs.inlumonCss);
                var headElements = [];

                if (!Array.isArray(stylesheets)) {
                    stylesheets = [stylesheets];
                }

                for (var i = 0; i < stylesheets.length; i++) {
                    var newStyle = angular.element('<link href="{0}" rel="stylesheet" />'.format(stylesheets[i]));

                    angular.element('head').append(newStyle);

                    headElements.push(newStyle);
                }

                element.on('$destroy', function (transition) {
                    for(var i = 0; i < headElements.length; i++) {
                        headElements[i].remove();
                    }
                });
            }
        }
    }
})();