﻿(function () {
    'use strict'
    angular.module('app.backofficeAdministor').factory('deficiencyReasonFactory', ['$http', 'WebApiUrl', function ($http, WebApiUrl) {
        var BaseUrl = WebApiUrl;
        var BaseFactory = {};
        BaseFactory.GetAddressDetails = function (urlParam) {
            return $http.get(BaseUrl + "Individual/GetDeficiencyReason" + urlParam);
        };
        BaseFactory.GetAllTemplates = function (key) {
            return $http.get(WebApiUrl + 'Template/GetAllTemplates/' + key);           
        };
        return BaseFactory;
    }]);
})();