(function () {
    'use strict'

    angular.module('app.provider')
    .factory('CECreditByCourseService', ['$http', '$q', 'WebApiUrl', CECreditByCourseService]);

    function CECreditByCourseService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };


        var _getValidationProgress = function (key, userId) {
            var urlParams = {
                userId: userId
            };
            return responseHandler($http.get(WebApiUrl + 'Provider/GetCECourseUploadValidationProgress/' + key + '/', { params: urlParams }));
        };

        return {
            getValidationProgress: _getValidationProgress
        };
    }
})();