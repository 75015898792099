(function () {
    'use strict'

    angular.module('app.core')
    .directive('applicationStatusPickerLogic', [ApplicationStatusPickerLogic]);

    function ApplicationStatusPickerLogic() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {

            },
        };
    }
})();