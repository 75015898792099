(function () {
    'use strict'
    angular.module('app.core')
        .controller('ProviderMultipleFileUploadController', ProviderMultipleFileUploadController);

    ProviderMultipleFileUploadController.$inject = ['$controller', '$rootScope', '$scope', '$http', '$q', 'ProviderMultipleFileUploadService', '$exceptionHandler', 'UploadDataUrl'];

    function ProviderMultipleFileUploadController($controller, $rootScope, $scope, $http, $q, ProviderMultipleFileUploadService, $exceptionHandler, UploadDataUrl) {
        $controller('GlobalController', {
            $scope: $scope
        });
        // console.log($scope.$parent.test);
        $scope.masterDocument = {
            DocumentName: "",
            DocumentType: "",
            DocumentFile: null
        };
        $scope.resetDocument = function () {
            $scope.document = angular.copy($scope.masterDocument);
            if (!!$scope.preSelectFirstDocumentType && (!!$scope.DocType && $scope.DocType.length > 0))
                $scope.document.DocumentType = $scope.DocType[0];
        }

        $scope.invalidFiles = [];
        $scope.closeFileError = function(){
            $scope.invalidFiles = [];
        }

        $scope.resetDocumentList = function () {
            $scope.resetDocument();
            // $scope.providerDocumentUpload.DocumentUploadList = [];
        }

        $scope.resetDocumentList();

        function init() {
            if (!$scope.providerDocumentUpload.LoadByDocIdAndDocCode) {
                ProviderMultipleFileUploadService.documentMasterGetALL(sessionStorage.Key)
                    .then(function (response) {
                        if ($scope.checkResponse(response)) {
                            $scope.DocType = response.DocumentMasterGET;
                            if (!!$scope.preSelectFirstDocumentType && (!!$scope.DocType && $scope.DocType.length > 0))
                                $scope.document.DocumentType = $scope.DocType[0];
                        }
                    }, function (data) {
                        showStatusMessage(data, "error");
                    });
            } else {
                ProviderMultipleFileUploadService.documentGetDocumentTypeName(sessionStorage.Key, $scope.providerDocumentUpload.DocId, $scope.providerDocumentUpload.DocCode)
                    .then(function (response) {
                        if ($scope.checkResponse(response)) {
                            $scope.DocType = response.DocumentMasterGET;
                            if (!!$scope.preSelectFirstDocumentType && (!!$scope.DocType && $scope.DocType.length > 0))
                                $scope.document.DocumentType = $scope.DocType[0];
                        }
                    }, function (data) {
                        showStatusMessage(data, "error");
                    });
            }
        }

        $scope.$on("initdocumentCreation", function (evt) {
            init();
        });

        $scope.AddNewDocument = function (files) {
            if (!$scope.providerDocumentUpload.DocumentUploadList)
                $scope.providerDocumentUpload.DocumentUploadList = [];
            var providerDocumentUpload = $scope.providerDocumentUpload;
            if (files && files.length) {
                UploadDataUrl.base64DataUrl(files).then(function (response) {
                    angular.forEach(response.file, function (currentDocFile) {
                        var documentToUpload = {
                            IndividualDocumentId: 0,
                            DocumentLkToPageTabSectionCode: providerDocumentUpload.DocumentLkToPageTabSectionCode,
                            DocumentLkToPageTabSectionId: providerDocumentUpload.DocumentLkToPageTabSectionId,
                            DocumentPath: currentDocFile.name,
                            DocName: currentDocFile.name,
                            Extension: currentDocFile.name.split('.').pop(),
                            DocStrBase64: currentDocFile.base64,
                            IsDocumentUploadedbyIndividual: (!sessionStorage.isBackOfficeUser),
                            IsDocumentUploadedbyStaff: (!!sessionStorage.isBackOfficeUser),
                            ReferenceNumber: (!!$scope.referenceNumber ? $scope.referenceNumber : ""),
                            DocumentId: (!!providerDocumentUpload.DocId) ? providerDocumentUpload.DocId : 19,
                            DocumentCd: (!!providerDocumentUpload.DocCode) ? providerDocumentUpload.DocCode : "D1019",
                            ProviderId: providerDocumentUpload.ProviderId,
                            ApplicationId: providerDocumentUpload.ApplicationId,
                            EntityId: providerDocumentUpload.ProviderId,
                            EntityType: "P"
                        };
                        $scope.processDocumentFields(documentToUpload);
                        $scope.providerDocumentUpload.DocumentUploadList.push(documentToUpload);
                    });
                    $scope.processValidation(true);
                })
            } else {
                $scope.processValidation(true);
            }
            //$scope.resetDocument();
        };

        $scope.processValidation = function (internalCall) {
            if (!!$scope.document.DocumentName || !!$scope.document.DocumentType ||
                (!!$scope.providerDocumentUpload.DocumentUploadList &&
                    $scope.providerDocumentUpload.DocumentUploadList.length > 0)
            ) {
                $scope.providerDocumentUpload.isUploadRequired = true;
                if ((!!$scope.hideDocumentName || !!$scope.document.DocumentName) && !!$scope.document.DocumentType &&
                    (!!$scope.providerDocumentUpload.DocumentUploadList &&
                        $scope.providerDocumentUpload.DocumentUploadList.length > 0)) {
                    $scope.providerDocumentUpload.isUploadValid = true;
                } else {
                    $scope.providerDocumentUpload.isUploadValid = false;
                }
            } else {
                $scope.providerDocumentUpload.isUploadRequired = false;
                $scope.providerDocumentUpload.isUploadValid = true;
            }
            if (!internalCall) {
                angular.forEach($scope.providerDocumentUpload.DocumentUploadList, function (currentDocFile) {
                    $scope.processDocumentFields(currentDocFile);
                });
            }
        };

        $scope.processDocumentFields = function (documentToUpload) {
            if (!$scope.hideDocumentName) {
                if (!!$scope.document.DocumentName) {
                    documentToUpload.DocumentName = $scope.document.DocumentName;
                    if (!documentToUpload.DocName || documentToUpload.DocName == '') {
                        documentToUpload.DocName = $scope.document.DocumentName;
                    } else {
                        if (documentToUpload.DocumentPath == documentToUpload.DocName) {
                            //take document name from scope
                            documentToUpload.DocNameWithExtention = $scope.document.DocumentName;
                        } else {
                            //take Edited document name
                            documentToUpload.DocNameWithExtention = documentToUpload.DocName;
                        }
                    }
                }
                else {
                    documentToUpload.DocumentName = documentToUpload.DocumentPath;
                    documentToUpload.DocNameWithExtention = documentToUpload.DocName;
                }
            } else {
                documentToUpload.DocumentName = documentToUpload.DocumentPath;
                documentToUpload.DocNameWithExtention = documentToUpload.DocName;
            }
            if (!$scope.hideDocumentType) {
                if (!!$scope.document.DocumentType) {
                    documentToUpload.DocumentTypeId = $scope.document.DocumentType.DocumentMasterId;
                    documentToUpload.DocumentTypeName = $scope.document.DocumentType.DocumentTypeIdName;
                    documentToUpload.OtherDocumentTypeName = $scope.document.DocumentType.DocumentTypeIdName;
                    if (!$scope.providerDocumentUpload.DocId && $scope.document.DocumentType.DocumentId) {
                        documentToUpload.DocumentId = $scope.document.DocumentType.DocumentId;
                        documentToUpload.DocumentCd = $scope.document.DocumentType.DocumentCd;
                    }
                }
            } else if ($scope.DocType && $scope.DocType.length > 0) {
                documentToUpload.DocumentTypeId = $scope.DocType[0].DocumentMasterId;
                documentToUpload.DocumentTypeName = $scope.DocType[0].DocumentTypeIdName;
            }
            documentToUpload.EffectiveDate = new Date();
            return documentToUpload;
        };

        $scope.deleteDoc = function (doc) {
            var index = $scope.providerDocumentUpload.DocumentUploadList.indexOf(doc);
            $scope.providerDocumentUpload.DocumentUploadList.splice(index, 1);
        };

        $scope.providerDocumentSave = function () {
            var defer = $q.defer();
            try {
                hideStatusMessage();
                hideValidationSummary();
                ShowLoader("Please wait...");
                if ($scope.providerDocumentUpload.DocumentUploadList && $scope.providerDocumentUpload.DocumentUploadList.length > 0) {
                    $scope.EntityType = "P";
                    ProviderMultipleFileUploadService.providerDocumentSave($scope.providerDocumentUpload, $scope.providerDocumentUpload.ProviderId, $scope.EntityType, sessionStorage.Key)
                        .then(function (response) {
                            if (response.Status) {
                                $scope.providerDocumentUpload.DocumentUploadList.length=0;
                                $scope.$emit("refreshDocList");
                                if (!$scope.providerDocumentUpload.IsLinkedDocControl) {
                                    if ($scope.showListing) {
                                        $scope.$emit("documentsSavedFromControl", {
                                            savedDocuments: response.providerDocumentUploadList
                                        })
                                        $scope.$broadcast("loaddocumentListing", $scope.providerDocumentUpload.ProviderId, $scope.providerDocumentUpload.DocId);
                                    }
                                }
                                //showStatusMessage(response.Message, "success");
                                $scope.resetDocumentList();
                                defer.resolve(response);
                            } else {
                                defer.reject(response);
                            }
                            HideLoader();
                        }, function (data) {
                            HideLoader();
                            if (data != null) {
                                showStatusMessage(data.message, "error");
                            }

                            defer.reject(data);
                        });
                } else {
                    defer.resolve({});
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
                $exceptionHandler(ex);
                defer.reject(ex);
            }

            return defer.promise;
        }

        $rootScope.$on("cleardocumentField", function () {
            $scope.resetDocumentList();
        });

        init();
    }
})();
