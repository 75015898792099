(function() {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("providerApplicationController", providerApplicationController);

    providerApplicationController.$inject = ["$rootScope", "$scope", "$state", "providerApplicationService", "utilityService", "$exceptionHandler", "WebApiUrl", "countryStateService"];

    function providerApplicationController($rootScope, $scope, $state, providerApplicationService, utilityService, $exceptionHandler, WebApiUrl, countryStateService) {


        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, ProviderId) {
            if (ProviderId && $rootScope.individualFirmCurrentTab.name == "appdata") {
                $scope.ProviderId = ProviderId;
                getProviderApplication();
            }
        });

        var getProviderApplication = function () {
            try {
                ShowLoader();
                providerApplicationService.getProviderApplication(sessionStorage.Key, $scope.ProviderId).then(function (res) {
                    if (res.Status) {
                        $scope.providerApplicationList = res.ApplicationDocumentList;
                    }
                    HideLoader();
                }, function (err) {
                    console.log(err);
                    HideLoader();
                });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        $scope.DownloadDocumentAPIURL = WebApiUrl + 'Individual/DownloadPDF/' + sessionStorage.Key + '?path=';
        $scope.DownloadDocument = function (path) {
            window.open($scope.DownloadDocumentAPIURL + path, '_blank');
        };


    }
})();