(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintCasesValidation', [ComplaintCasesValidation]);

    function ComplaintCasesValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.setForm = function (form, formType) {
                    if (!$scope.forms) {
                        $scope.forms = {};
                    }

                    $scope.forms[formType] = form;
                };

                $scope.validateForm = function (formType) {
                    var valid = true;
                    
                    //If it's being changed to Discipline Queue
                    if ($scope.current.complaintCase.CaseStageId != $scope.current.originalComplaintCase.CaseStageId 
                        && $scope.current.complaintCase.CaseStageId == 8) {
                        //Make sure it's using the "Referred for Discipline"
                        
                        if ($scope.current.complaintCase.ClosureReasonId != 16) {
                            $scope.forms[formType].errorMessages.push({
                                propName: 'invalidClosureReason',
                                errorMessage: 'Cannot proceed to Discipline Queue with current closure reason.'
                            });
                            valid = false;
                        }
                    }

                    $scope.hideStatusMsg();
                    if (!!$scope.forms && !!$scope.forms[formType] && $scope.forms[formType].errorMessages.length > 0) {
                        $scope.showStatusMsg('-', $scope.forms[formType].errorMessages);
                        valid = false;
                    }
                    
                    return valid;
                }
            },
        };
    }
})();