(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationLicenseDetails', initialApplicationLicenseDetailsDirective);

    initialApplicationLicenseDetailsDirective.$inject = [];

    function initialApplicationLicenseDetailsDirective() {
        return {
            restrict: 'E',
            scope: {
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                applicationId: "=applicationId",
                individualId: "=individualId",
                natValidation: "=natValidation",
                individualLicense: "=individualLicense",
                individualRenewalDetails: "=?"

            },
            templateUrl: "app/components/individual/application/directives/license-details/license-details.html",
            controller: "InitialApplicationLicenseDetailsController"
        }
    }
})();