(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("TrustAccountFundHolderController", TrustAccountFundHolderController);

    TrustAccountFundHolderController.$inject = ["$rootScope", "$scope", "$state", "trustAccountFundHolderService", "backofficeIndividualEducationService",
        "utilityService", "$exceptionHandler", "WebApiUrl", "countryStateService", "BackofficeIndividualService", "$filter", "ProviderMultipleFileUploadService", "typeValuesService", "TypeValue", "Messages"
    ];

    function TrustAccountFundHolderController($rootScope, $scope, $state, trustAccountFundHolderService, backofficeIndividualEducationService,
        utilityService, $exceptionHandler, WebApiUrl, countryStateService, BackofficeIndividualService, $filter, ProviderMultipleFileUploadService, typeValuesService, TypeValue, Messages) {

        var providerLicenseNo = '';
        $scope.individualLicense = {};

        $scope.documentConfig = {
            DocId: 12,
            LinkType: 'PM',
            PropertyToProcess: 'EntityAccountId'
        };

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, ProviderId) {
            if (ProviderId && ($rootScope.individualFirmCurrentTab.name == "trustAccountFundHolder")) {
                $scope.ProviderId = ProviderId;
                initDocument();
                $scope.ProviderLicenseList = [];
                if (!$scope.isLoadedOnce) {
                    $scope.isLoadedOnce = true;
                }
                AuditVisitInfoSave();
                $scope.providerLicenseDetailBYProviderId($scope.ProviderId);
                //$rootScope.individualFirmCurrentTab.isLicenseLoaded = true;
            }
        });

        $scope.viewConfig = {
            showLicenseViewBlock: false,
            showCEHTable: false,
            showAddCEHButton: true,
            viewLicense: {}
        };
        $scope.datePicker = {
            beginDateOpened: false,
            endDateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };

        $scope.cehDatePicker = {
            dateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };

        var raiseEvaent = function (data) {
            $scope.$emit('loadFirmLicenseData', data);
        };

        $scope.providerLicenseDetailBYProviderId = function (ProviderId) {
            ShowLoader("Please Wait...");
            try {
                //$scope.ConvertableIndividualLicenseId = 0;
                trustAccountFundHolderService.getEntityAccount(sessionStorage.Key, ProviderId, 'P')
                    .then(function (response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        HideLoader();
                        if ($scope.checkServerResponse(response)) {
                            $scope.ProviderLicenseList = response.EntityAccountList;
                            raiseEvaent(response.EntityAccountList);
                            // if ($scope.ProviderLicenseList && $scope.ProviderLicenseList.length > 0) {
                            //     //$scope.ConvertableIndividualLicenseId = getMaxIndLicenseId($scope.ProviderLicenseList);
                            //     providerLicenseNo = $scope.ProviderLicenseList[0].ProviderLicenseNumber;
                            //     $scope.individualLicense.ProviderLicenseNumber = providerLicenseNo;
                            // }
                            //processListData();
                        }
                    }, function (data) {
                        HideLoader();
                        $scope.showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        };

        var processListData = function () {
            if ($scope.ProviderLicenseList && $scope.ProviderLicenseList.length > 0) {
                ProviderMultipleFileUploadService.providerDocumentProcess(sessionStorage.Key, $scope.ProviderId, $scope.documentConfig.DocId,
                    $scope.ProviderLicenseList, $scope.documentConfig.PropertyToProcess, $scope.documentConfig.LinkType).then(function (responseData) {
                        $scope.ProviderLicenseList = responseData;
                    });
            }
        };




        $scope.licenseView = function (lic, isConversion) {
            var st = setTimeout(function () {
                var individualLicense = angular.copy(lic);
                $scope.viewConfig.showLicenseViewBlock = true;
                individualLicense.ProviderLicenseEffectiveDate = $filter('date')(individualLicense.ProviderLicenseEffectiveDate, 'MM/dd/yyyy')
                individualLicense.ProviderLicenseExpirationDate = $filter('date')(individualLicense.ProviderLicenseExpirationDate, 'MM/dd/yyyy')
                $scope.individualLicense = angular.copy(individualLicense);
                if (!$scope.individualLicense.ReferenceNumber) {
                    $scope.individualLicense.ReferenceNumber = new Date().valueOf();
                }
                initDocument();
                $scope.$apply();
            }, 10);
        };

        $scope.addnewPermit = function () {
            $scope.viewConfig.showLicenseViewBlock = true;
            $scope.individualLicense = {};
            $scope.individualLicense.ReferenceNumber = new Date().valueOf();
            initDocument();
        }



        //document
        var initDocument = function () {
            $scope.documentUpload = undefined;
            setTimeout(function () {
                $scope.$apply(function () {
                    $scope.documentUpload = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                        // ApplicationId: _applicationId,
                        ProviderId: $scope.ProviderId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FRM",
                        LoadByDocIdAndDocCode: true,
                        DocId: 12,
                        DocCode: "D1012",
                        LoadDocumentsWithoutBroadCast: true
                      //  LinkType: 'PM',
                       // PropertyToProcess: 'EntityAccountId',
                    };
                });
            }, 1000);
        };


        $scope.DownloadDocumentAPIURL = WebApiUrl + 'Individual/DownloadPDF/' + sessionStorage.Key + '?path=';
        $scope.DownloadDocument = function (path) {
            window.open($scope.DownloadDocumentAPIURL + path, '_blank');
        };

        $scope.setSaveDocumentRef = function (fn) {
            $scope.saveAllDocument = fn;
        };
        //Document

        $scope.addNewBtnClick = function () {
            if (!$scope.viewConfig) {
                $scope.viewConfig = {};
            }
            $scope.viewConfig.showLicenseViewBlock = true;
            $scope.individualLicense = { EntityType: 'P' };
            $scope.individualLicense.ReferenceNumber = new Date().valueOf();
            initDocument();
        };

        $scope.SaveLicense = function (isPayment, licenseForm, applicationObj) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                if ($scope.validateData(licenseForm)) {
                    ShowLoader("Please wait...");

                    if (!$scope.individualLicense.EntityAccountId || $scope.individualLicense.EntityAccountId == 0) {
                        $scope.individualLicense.ProviderId = $scope.ProviderId;
                        $scope.individualLicense.IsActive = true;
                        $scope.individualLicense.IsDeleted = false;
                        $scope.individualLicense.CreatedBy = sessionStorage.UserID;
                        $scope.individualLicense.CreatedOn = new Date();
                        $scope.individualLicense.ProviderLicenseGuid = 'pl';
                        // $scope.individualLicense.OriginalLicenseDate
                        if (!$scope.individualLicense.ProviderTypeId)
                            $scope.individualLicense.ProviderTypeId = 1;
                    }

                    var businessStatus = _.find($scope.BusinessStatus, function (o) { return o.ProviderLicenseStatusName == "Active"; });
                    if (businessStatus && businessStatus.ProviderLicenseStatusId == $scope.individualLicense.ProviderStatusTypeId) {
                        $scope.individualLicense.IsLicenseActive = true;
                    }

                    if (!$scope.individualLicense.ReferenceNumber) {
                        $scope.individualLicense.ReferenceNumber = new Date().valueOf();
                    }

                    $scope.individualLicense.EntityType = 'P';
                    $scope.individualLicense.EntityId = $scope.ProviderId;

                    var licenseData = [$scope.individualLicense];

                    trustAccountFundHolderService.saveEntityAccount(sessionStorage.Key, licenseData).then(function (res) {
                        if (res.Status) {
                            if (res.EntityAccountList && res.EntityAccountList.length > 0) {
                                $scope.documentUpload.LinkId = res.EntityAccountList[0].EntityAccountId;
                                if (typeof $scope.saveAllDocument == "function") {
                                    $scope.saveAllDocument().then(function () {
                                        showStatusMessage(Messages.ebcecca, "success");

                                        $scope.providerLicenseDetailBYProviderId($scope.ProviderId);
                                        $scope.cancelLicenseView();
                                        HideLoader();
                                    });
                                } else {
                                    showStatusMessage(Messages.ebcecca, "success");

                                    $scope.providerLicenseDetailBYProviderId($scope.ProviderId);
                                    $scope.cancelLicenseView();
                                    HideLoader();
                                }
                            } else {
                                $scope.providerLicenseDetailBYProviderId($scope.ProviderId);
                                $scope.cancelLicenseView();
                                HideLoader();
                            }
                        }
                    });
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        };



        $scope.validateConversionForm = function (licenseForm) {
            $scope.hideStatusMessage();
            $scope.hideValidationSummary();

            if (licenseForm.errorMessages == undefined)
                licenseForm.errorMessages = [];

            var errorObj = {
                propName: "changeLicenseTypeforConversion",
                errorMessage: Messages.General.Errors.ChangeLicenseType
            };
            cleanErrorMessage(licenseForm.errorMessages, errorObj.propName);
            if ($scope.individualLicense.LicenseTypeId == $scope.individualLicense.ExistingLicenseTypeId) {
                licenseForm.errorMessages.push(errorObj);
            }
            return $scope.validateData(licenseForm);
        };

        var cleanErrorMessage = function (errorMessages, prop) {
            var indexOfError = null;
            for (var i = 0; i < errorMessages.length; i++) {
                if (errorMessages[i].propName == prop)
                    indexOfError = i;
            }
            if (indexOfError != null && indexOfError >= 0) {
                errorMessages.splice(indexOfError, 1);
            }
        };



        //License Conversion

        $scope.applicationId = null;
        $scope.LicenseSaveConversion = function (licenseForm) {
            try {
                if (!$scope.validateConversionForm(licenseForm)) {
                    return false;
                }
                var CurrentDate = new Date();
                var applicationObj = {
                    ApplicationTypeId: 4,
                    ApplicationStatusId: 1,
                    ApplicationStatusReasonId: 1,
                    ApplicationInternalStatusId: 1,
                    StartedDate: CurrentDate,
                    SubmittedDate: CurrentDate,
                    ApplicationStatusDate: CurrentDate,
                    PaymentDeadlineDate: CurrentDate,
                    PaymentDate: CurrentDate,
                    LicenseTypeId: $scope.individualLicense.LicenseTypeId,
                    IsActive: true,
                    HighPriority: true
                };
                if (!$scope.applicationId) {
                    trustAccountFundHolderService.generateApplicationId(sessionStorage.Key, 0, sessionStorage.UserID, applicationObj)
                        .then(function (response) {
                            if ($scope.checkServerResponse(response)) {
                                $scope.applicationId = response.ApplicationId;
                                var applicationObj = {
                                    ApplicationId: response.ApplicationId,
                                    ApplicationNumber: response.ApplicationNumber
                                };
                                sessionStorage.applicationObj = applicationObj;
                                $scope.SaveLicense(true, licenseForm, applicationObj);
                            }
                        }, function (data) {
                            HideLoader();
                            if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                        });
                } else {
                    $scope.SaveLicense(true, licenseForm, sessionStorage.applicationObj);
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.deleteLicenseDetail = function (individualLicense) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                if (individualLicense) {
                    individualLicense.IsActive = false;
                    individualLicense.IsDeleted = true;
                    individualLicense.ModifiedBy = sessionStorage.UserID;
                    individualLicense.ModifiedOn = new Date();
                }
                var licenseData = [individualLicense];
                trustAccountFundHolderService.saveEntityAccount(sessionStorage.Key, licenseData).then(function (res) {
                    if (res.Status) {
                        showStatusMessage(Messages.bdcfebb, "success");

                        $scope.providerLicenseDetailBYProviderId($scope.ProviderId);
                        $scope.cancelLicenseView();
                    }
                    HideLoader();
                });
            }
        }




        $scope.cancelLicenseView = function () {
            $scope.individualLicense = {};
            $scope.viewConfig.showLicenseViewBlock = false;
            $scope.individualLicense.ProviderLicenseNumber = providerLicenseNo;
        }

        function AuditVisitInfoSave() {

            var data = {
                "PageName": "IndividualInfo-License",
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.IndividualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        }

        var getreftasktype = function () {
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=accounttype")).then(function (res) {
                if (res.Status) {
                    $scope.accounttypes = [];
                    angular.forEach(res.ReferenceEntities, function (item) {
                        if (item.AccountTypeId != 3) {
                            $scope.accounttypes.push(item);
                        }
                    });
                    // $scope.accounttypes = res.ReferenceEntities;
                }
            }, function () {

            });
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=accountstatus")).then(function (res) {
                if (res.Status) {
                    $scope.accountstatus = res.ReferenceEntities;
                }
            }, function () {

            });

            countryStateService.getStateByCountryID(sessionStorage.Key, $rootScope.DefaultCountryId).then(function (response) {

            }, function (error) {

            });
        };

        getreftasktype();
    }
})();
