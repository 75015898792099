﻿(function () {
    'use strict'
    angular.module('app.backofficeAdministor').factory('ConfigConfigtypeDirectiveFactory', ['$http', 'WebApiUrl', '$q', function ($http, WebApiUrl, $q) {

        var _configurationSearch = function (key, param) {
            return returnDefferedResult($http.post(WebApiUrl + 'Configuration/ConfigurationSearch/' + key, param));
        };

        var _configurationSave = function (key, param) {
            return returnDefferedResult($http.post(WebApiUrl + 'Configuration/ConfigurationSave/' + key, param));
        };

        var _reloadConfiguration = function () {
            return returnDefferedResult($http.get(WebApiUrl + 'Common/RefreshConfiguration/'+sessionStorage.Key));
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };
        return {
            ConfigurationSearch: _configurationSearch,
            ConfigurationSave: _configurationSave,
            reloadConfiguration: _reloadConfiguration
        };
    }]);
})();