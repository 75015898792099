(function () {
    'use strict'

    angular.module('app.enforcement')
        .controller('ComplaintSearchController', ['$scope', '$rootScope', '$state', '$stateParams', '$q', '$timeout', 'WebApiUrl', 'ComplaintSearchService', 'utilityService', 'Messages', ComplaintSearchController]);

    function ComplaintSearchController($scope, $rootScope, $state, $stateParams, $q, $timeout, WebApiUrl, ComplaintSearchService, utilityService, Messages) {
        var _key = sessionStorage.Key;
        var _childName = '';

        $scope.routeData = $state.current.routeData;
        $scope.configName = $scope.routeData.queueName;

        $scope.title = (!!$scope.routeData) ? $scope.routeData.title : 'Complaint Search';

        var _searchScreen = 'search';
        var _addEditScreen = 'addEdit';

        $scope.documentTypes = [];

        var getNewComplaint = function () {
            var newComplaint = {
                ComplaintNumber: '',
                ComplaintPriority: null,
                ComplaintRefNum: '',

                SecureLevel: null,

                ComplaintStatusId: null,
                CurrentStatusDate: null,

                ComplaintDispTypeId: null,
                ComplaintSourceTypeId: null,
                ComplaintPublicCaseReasonId: null,
                ComplaintFormTypeId: null,
                ComplaintClassId: null,

                ReceiveDate: null,
                IncidentDate: null,

                DispositionDate: null,
                DispositionUpdatedBy: null,
                DocketNumber: '',

                EnforcementRegionId: null,
                EnforcementCaseTypeId: null,
                EnforcementComplexityTypeId: null,
                EnforcementSecureClassId: null,

                CloseDate: null,
                ComplaintNotes: '',
                OutsideAgencyNotification: null,

                IsNonactionable: null,
                IsAnonymous: null,
                IsAdministrativeAction: null,
                IsInternalOnly: null,
                IsForInvestigationOnly: null,
                IsForLegalOnly: null,
                IsForPublic: null,

                IsActive: true,
                IsDeleted: false,

                DispositionDeciderId: null,
                DispositionNotes: '',

                InvestigatorId: null,

                ComplaintCategoryId: null,
                CaseTypeId: null,
                CaseStageId: null,
                Reason: '',
                IntakeStatusDate: null,
                BoardAttorneyId: null,
                CaseStatusId: null,
                ComplaintDisciplineTypeId: null,
                ComplaintDisciplineTypeIds: null
            };

            if (!!$scope.config.NewComplaintDefaultValues) {
                var defaults = $scope.config.NewComplaintDefaultValues;
                for (var prop in defaults) {
                    if (typeof (defaults[prop]) != 'undefined') {
                        if (defaults[prop] == 'NOW') {
                            newComplaint[prop] = new Date().toJSON();
                        } else {
                            newComplaint[prop] = defaults[prop];
                        }
                    }
                }
            }

            return newComplaint;
        };

        var getUIConfig = function () {
            return $scope.config;
        };

        var getQueueConfig = function () {
            var config = {};

            for (var prop in $scope.uiConfig.QueueConfigs['Default']) {
                if (!$scope.uiConfig.QueueConfigs['Default'].hasOwnProperty(prop)) { continue; }

                config[prop] = $scope.uiConfig.QueueConfigs['Default'][prop];
            }

            if (!!$scope.uiConfig.QueueConfigs[$scope.routeData.queueName]) {
                for (var prop in $scope.uiConfig.QueueConfigs[$scope.routeData.queueName]) {
                    if (!$scope.uiConfig.QueueConfigs[$scope.routeData.queueName].hasOwnProperty(prop)) { continue; }

                    config[prop] = $scope.uiConfig.QueueConfigs[$scope.routeData.queueName][prop];
                }
            }

            return config;
        };

        var getConfig = function () {
            var deferred = $q.defer();

            try {
                utilityService.getConfigData('/components/backoffice/enforcement/complaint-search/complaint-search.server-config.json')
                    .then(function (data) {
                        if (!!data) {
                            $scope.config = data;

                            $scope.uiConfig = $rootScope.getUIConfig($scope.configName, $scope.config);
                            $scope.queueConfig = $scope.uiConfig;

                            if (!!$scope.queueConfig.DocumentTypes) {
                                $scope.documentTypes = angular.copy($scope.queueConfig.DocumentTypes);
                            }

                            $scope.isConfigLoaded = true;
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getStaffUsers = function () {
            var deferred = $q.defer();

            try {
                ComplaintSearchService.getStaffUsers(sessionStorage.Key)
                    .then(function (data) {
                        if (data.Status && data.UsersList) {
                            $scope.usersList = data.UsersList.orderBy('FirstName,LastName');
                        } else {
                            $scope.usersList = [];
                        }
                        $scope.usersList.forEach(function (user) {
                            user.FullName = user.FirstName + ' ' + user.LastName;
                        });
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.saveComplaint = function (complaint) {
            var deferred = $q.defer();

            try {
                ComplaintSearchService.saveComplaint(sessionStorage.Key, [complaint])
                    .then(function (data) {
                        if (data.Status) {
                            deferred.resolve(data);
                        } else {
                            deferred.reject(data);
                        }
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };
        $scope.saveComplaintClicked = function () {
            $scope.operationPending(true, 'Saving...');

            $scope.saveComplaint($scope.current.complaint)
                .then(function (data) {
                    $scope.searchComplaintsClicked(1);
                }, function (err) {
                    $scope.operationPending(false);
                    $scope.showStatusMsg('-', err);
                });
        };
        $scope.addEditComplaintClicked = function (complaint) {
            //$scope.current.screen = _addEditScreen;

            if (!complaint) {
                //complaint = getNewComplaint();
                $state.go($scope.queueConfig.AddNewState, { origin: $state.current.name });
            }

            //$scope.current.complaint = angular.copy(complaint);
        };
        $scope.cancelAddClicked = function () {
            $scope.current.screen = _searchScreen;
            $scope.current.complaint = null;
        };
        $scope.deleteComplaintClicked = function (complaint) {
            if (confirm(Messages.aebaece)) {
                complaint.IsActive = false;
                complaint.IsDeleted = true;

                $scope.operationPending(true, 'Deleting...');

                $scope.saveComplaint(complaint)
                    .then(function () {
                        $scope.getComplaints()
                            .then(function () {
                                $scope.operationPending(false);
                            }, function () {
                                $scope.operationPending(false);
                            });
                    }, function () {
                        $scope.operationPending(false);
                    });
            }
        };

        $scope.getComplaints = function () {
            var deferred = $q.defer();

            try {
                $scope.searchData.UserId = sessionStorage.UserID;
                $scope.searchData.QueueName = $scope.routeData.queueName;
                ComplaintSearchService.getComplaints(sessionStorage.Key, $scope.searchData, $scope.paginationParams.currentPage, $scope.paginationParams.pageSize)
                    .then(function (data) {
                        if (data.Status) {
                            $scope.complaintsList = data.SearchList;
                            $scope.paginationParams.setTotalRecords(data.SearchList.firstOrDefault().Total_Record);

                            $scope.complaintsList.forEach(function (complaint) {
                                complaint.documentConfigs = [];

                                $scope.documentTypes.forEach(function (docType) {
                                    complaint.documentConfigs.push(angular.copy(docType));
                                });
                            });
                            deferred.resolve(data);
                        } else {
                            $scope.complaintsList = [];
                            deferred.reject(data);
                        }
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };
        $scope.searchComplaintsClicked = function (page) {
            $scope.current.screen = _searchScreen;

            $scope.operationPending(true, 'Searching...');
            if (!!$scope.hideStatusMsg) {
                $scope.hideStatusMsg();
            }

            if (!!page) {
                $scope.paginationParams.currentPage = page;
            }

            $scope.getComplaints()
                .then(function (data) {
                    $scope.operationPending(false);
                }, function (err) {
                    $scope.operationPending(false);
                    $scope.showStatusMsg('-', err);
                });
        };
        $scope.resetSearchClicked = function () {
            $scope.searchData = {
                SortColumn: '',
                SortOrder: ''
            };

            $scope.paginationParams.currentPage = 1;

            return $scope.searchComplaintsClicked(1);
        };
        $scope.tableHeaderClicked = function (column, order) {
            $scope.searchData.SortColumn = column;
            $scope.searchData.SortOrder = order;

            return $scope.searchComplaintsClicked();
        };

        $scope.toggleRowDetailsClicked = function (item, name) {
            if (item.rowDetails == name) {
                item.rowDetails = '';
            } else {
                item.rowDetails = name;
            }

            item.rowClass = {
                'row-expanded': !!item.rowDetails
            };
        };

        $scope.closeRowDetailsClicked = function (item) {
            item.rowDetails = '';
            item.rowClass = {
                'row-expanded': !!item.rowDetails
            };
        };

        $scope.getInvolvedParty = function (complaint, typeId, nameProp, licenseProp, complaintInvolvedPartyId) {
            var deferred = $q.defer();

            try {
                ComplaintSearchService.getInvolvedParties(sessionStorage.Key, complaint.ComplaintId, typeId, complaintInvolvedPartyId)
                    .then(function (data) {
                        if (data.Status && !!data.ComplaintInvolvedPartyList) {
                            var party = data.ComplaintInvolvedPartyList.firstOrDefault();
                            if (!!party) {
                                complaint[nameProp] = (party.EntityType == 'I') ? party.FirstName + ' ' + party.LastName : party.ProviderName;
                                complaint[licenseProp] = party.LicenseNumber;
                            }

                            if (!!$scope.getInvolvedPartyExt) {
                                $scope.getInvolvedPartyExt(data.ComplaintInvolvedPartyList, complaint, typeId, nameProp, licenseProp);
                            }
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.respondentSaved = function (complaint, respondent) {
            if (!!respondent && !!complaint) {
                complaint.RespondentId = respondent.EntityId;
                complaint.Respondent = respondent.FirstName + ' ' + respondent.MiddleName + ' ' + respondent.LastName;
                complaint.RespondentType = respondent.EntityType;
            }
        };
        $scope.complainantSaved = function (complaint, complainant) {
            if (!!complainant && !!complaint) {
                complaint.ComplainantId = complainant.EntityId;
                complaint.Complainant = complainant.FirstName + ' ' + complainant.MiddleName + ' ' + complainant.LastName;
                complaint.ComplainantType = complainant.EntityType;
            }
        };

        $scope.saveComplaintDetails = function (complaint) {
            if (!!$scope.saveComplaintDetailsExt) {
                $scope.saveComplaintDetailsExt(complaint);
            }
        }

        $scope.init = function () {
            $scope.uiLogic = {};
            $scope.current = {
                complaint: null,
                screen: _searchScreen,
                stuff: '1,2',
                party: null
            };
            $scope.sort = {
                column: '',
                order: ''
            };

            $scope.tableHeaders = {};

            $scope.paginationParams = {
                pageSize: 10,
                pageChanged: $scope.searchComplaintsClicked
            };

            $timeout(function () {
                $scope.operationPending(true, 'Loading...');
                getConfig()
                    .then(function () {
                        $scope.tableHeaders = $scope.queueConfig.TableHeaders;

                        $scope.resetSearchClicked();
                        $scope.getStaffUsers();
                    }, function () {
                        $scope.operationPending(false);
                    });
            }, 1);

            if (!!$scope.initExt) {
                $scope.initExt();
            }

            if (!!$scope.getStaffUsers) {
                $scope.getStaffUsers();
            }
        };
    }
})();
