(function () {
    'use strict'

    angular.module('app.core')
        .factory('emailService', emailService);

    emailService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig'];

    function emailService($http, $q, WebApiUrl, AppConfig) {
        var _emailAndText = function (emailData, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'User/EmailAndText/' + key + "?Source=" + AppConfig.Source, emailData)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _individualBYIndividualId = function (Key, IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualBYIndividualId/' + Key + '?IndividualId=' + IndividualId)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _individualContactBYIndividualId = function (Key, IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualContactBYIndividualId/' + Key + '?IndividualId=' + IndividualId)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        };


        return {
            emailAndText: _emailAndText,
            individualBYIndividualId: _individualBYIndividualId,
            individualContactBYIndividualId: _individualContactBYIndividualId
        };
    }
})();