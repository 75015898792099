(function () {
  'use strict'
  angular.module('app.core')
    .directive('natEmail', natEmailDirective);

  natEmailDirective.$inject = [];

  function natEmailDirective() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ctrl) {
        //element.on('blur', function (event) {
        scope.$watch(attrs.ngModel, function (newValue, oldValue) {
          var RegPattern = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

          if (!ctrl.$$parentForm.errorMessages) {
            ctrl.$$parentForm.errorMessages = [];
          }

          var errorObj = {
            propName: (!!attrs.validationName ? attrs.validationName : ("natemail-" + ctrl.$name)),
            errorMessage: attrs.natEmail
          }

          angular.forEach(ctrl.$$parentForm.errorMessages, function (error, indexOfMsg) {
            if (errorObj.propName == error.propName) {
              ctrl.$$parentForm.errorMessages.splice(indexOfMsg, 1);
            }
          })

          if (!!newValue && !RegPattern.test(newValue.trim())) {
            ctrl.$$parentForm.errorMessages.push(errorObj);
            ctrl.$setValidity("natemail", false);
          }
          else {
            ctrl.$setValidity('natemail', true);
          }
        })
      }
    }
  }
})();
