(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintActions', [ComplaintActions]);

    function ComplaintActions() {
        return {
            restrict: 'E',
            scope: {
                complaint: '=',
                complaintCaseId: '=?',
                readOnly: '=?',
                queueName: '=?',
                canAddNew: '=?',
                canEdit: '=?',
                canDelete: '=?',
                configName: '@?',
                actionSaved: '&?',
                actionsSaved: '&?',
                currentActions: '=?',
                currentDisplayActions: '=?'
            },
            link: function ($scope, element, attrs) {

            },
            templateUrl: 'app/components/backoffice/enforcement/complaint-actions/complaint-actions.html',
            controller: 'ComplaintActionsController'
        };
    }
})();
