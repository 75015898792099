(function() {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('examinationLogic', [ExaminationLogicDirective]);

    function ExaminationLogicDirective() {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {

            }
        };
    }
})();