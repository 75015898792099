//Code Generated by Inlumon Code generation tool
(function () {
    'use strict'

    angular.module('app.backofficeAdministor')
        .directive('revenueDeposit', [revenueDepositDirective]);

    revenueDepositDirective.$inject = [];

    function revenueDepositDirective() {
        return {
            restrict: 'E',
            scope: {
                configName: "=",
                boardInfoId: "=?",
                showHeaderPanel: '=?',
                headingPosition: "=",
                showSubmitPanel: '=?'
            },
            link: function ($scope, element, attrs) {

            },
            templateUrl: 'app/components/backoffice/administrator/revenue-deposit/revenue-deposit.html',
            controller: 'RevenueDepositController'
        };
    }
})();