(function () {
    'use strict'

    angular.module('app.enforcement')
    .controller('ComplaintOnlineSubmissionController', ['$scope', '$rootScope', '$timeout', '$q', '$state', '$stateParams', 'ComplaintOnlineSubmissionService', 'utilityService', 'PromiseHandlers', ComplaintOnlineSubmissionController]);

    function ComplaintOnlineSubmissionController($scope, $rootScope, $timeout, $q, $state, $stateParams, ComplaintOnlineSubmissionService, utilityService, PromiseHandlers) {
        var _key = sessionStorage.Key;
        var _childName = '';

        $scope.configName = (!!$state.current.routeData && !!$state.current.routeData.configName) ? $state.current.routeData.configName : 'OnlineSubmission';

        //Dummy methods
        $scope.saveDataExt = function (complaint, involvedParties) { 
            var deferred = $q.defer();
            
            try {
                deferred.resolve({Status: true, StatusCode: '00'});
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.getNewInvolvedParty = function (typeId, entityType) {
            return {
                ComplaintInvolvedPartyTypeId: typeId,
                ComplaintId: null,
                EntityId: null,
                EntityType: (!!entityType) ? entityType : 'I',
                LicenseNumber: '',
                LicenseStatusTypeName: '',
                LicenseTypeName: '',
                LicenseExpirationDate: null,
                LicenseId: null,
                SecureLevel: null,
                FirstName: '',
                MiddleName: '',
                LastName: '',
                SSN: null,
                Gender: '',
                DateOfBirth: null,
                ProviderName: '',
                CellPhoneNumber: '',
                HomePhoneNumber: '',
                WorkPhoneNumber: '',
                Email: '',
                WorkEmail: '',

                StreetLine1: '',
                StreetLine2: '',
                City: '',
                StateCd: '',
                Zip: '',
                CountyId: null,
                CountryId: null,

                IsAnonymous: false,
                IsInternalOnly: false,
                IsForInvestigationOnly: false,
                IsForLegalOnly: false,
                IsForPublic: false,

                Notes: '',
                ReferenceNumber: '',

                IsActive: true,
                IsDeleted: false
            };
        };

        var getNewComplaint = function () {
            var newComplaint = {
                ComplaintNumber: '',
                ComplaintPriority: null,
                ComplaintRefNum: '',

                SecureLevel: null,

                ComplaintStatusId: null,
                CurrentStatusDate: null,

                ComplaintDispTypeId: null,
                ComplaintSourceTypeId: null,
                ComplaintPublicCaseReasonId: null,
                ComplaintFormTypeId: null,
                ComplaintClassId: null,

                ReceiveDate: null,
                IncidentDate: null,

                DispositionDate: null,
                DispositionUpdatedBy: null,
                DocketNumber: '',

                EnforcementRegionId: null,
                EnforcementCaseTypeId: null,
                EnforcementComplexityTypeId: null,
                EnforcementSecureClassId: null,

                CloseDate: null,
                ComplaintNotes: '',
                OutsideAgencyNotification: null,                

                IsNonactionable: null,
                IsAnonymous: null,
                IsAdministrativeAction: null,
                IsInternalOnly: null,
                IsForInvestigationOnly: null,
                IsForLegalOnly: null,
                IsForPublic: null,

                IsActive: true,
                IsDeleted: false,

                DispositionDeciderId: null,
                DispositionNotes: '',

                InvestigatorId: null,

                ComplaintCategoryId: null,
                CaseTypeId: null,
                CaseStageId: null,
                Reason: '',
                IntakeStatusDate: null,
                BoardAttorneyId: null,
                CaseStatusId: null,
                ComplaintDisciplineTypeId: null,
                ComplaintDisciplineTypeIds: null,

                ComplaintCategoryNotes: null,
                AttemptedContact: null,
                AttemptedContactNotes: null,
                PersonsComplainedTo: null,
                EnteredLitigation: null,
                LitigationNotes: null
            };

            if (!!$scope.config.NewComplaintDefaultValues) {
                var defaults = $scope.config.NewComplaintDefaultValues;
                for (var prop in defaults) {
                    if (typeof(defaults[prop]) != 'undefined') {
                        if (defaults[prop] == 'NOW') {
                            newComplaint[prop] = new Date().toJSON();
                        } else {
                            newComplaint[prop] = defaults[prop];
                        }
                    }
                }
            }

            if (!!$scope.uiConfig.NewComplaintDefaultValues) {
                var defaults = $scope.uiConfig.NewComplaintDefaultValues;
                for (var prop in defaults) {
                    if (typeof(defaults[prop]) != 'undefined') {
                        if (defaults[prop] == 'NOW') {
                            newComplaint[prop] = new Date().toJSON();
                        } else {
                            newComplaint[prop] = defaults[prop];
                        }
                    }
                }
            }

            return newComplaint;
        };

        var getConfig = function () {
            var deferred = $q.defer();
        
            try {
                utilityService.getConfigData('/components/backoffice/enforcement/complaint-online-submission/complaint-online-submission.server-config.json')
                .then(function(data) {
                    if (!!data) {
                        $scope.config = data;
        
                        $scope.uiConfig = $rootScope.getUIConfig($scope.configName, $scope.config);
        
                        $scope.isConfigLoaded = true;

                        resetForm();

                        if (!!$scope.getConfigExt) {
                            var configExt = $scope.getConfigExt();

                            if (!!configExt && !!configExt.then) {
                                configExt.then(function () {
                                    deferred.resolve(data);
                                }, function () {
                                    deferred.reject(data);
                                });
                            } else {
                                deferred.resolve(data);
                            }
                        } else {
                            deferred.resolve(data);
                        }
                    }
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
        
            return deferred.promise;
        };

        $scope.saveComplaintInvolvedParty = function (party) {
            var deferred = $q.defer();
            
            try {
                ComplaintOnlineSubmissionService.saveComplaintInvolvedParty(party)
                .then(function(data) {
                    if (data.Status) {
                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.saveComplaint = function (complaint) {
            var deferred = $q.defer();
            
            try {
                ComplaintOnlineSubmissionService.saveComplaint(complaint)
                .then(function(data) {
                    if (data.Status && !!data.Complaintlst && data.Complaintlst.length > 0) {
                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.setForm = function (form) {
            $scope.form = form;
            $scope.$broadcast('complaintSetForm', form);
        };

        var resetForm = function () {
            $scope.$broadcast('complaintClearForms');
            
            $scope.current.complaint = getNewComplaint();
            $scope.uiConfig.InvolvedParties.forEach(function (ip) {
                $scope.current[ip.PropertyName] = $scope.getNewInvolvedParty(ip.TypeId, ip.EntityType);
            });

            if (!!$scope.resetExt) {
                $scope.resetExt();
            }
        };

        var saveFailed = function (err) {
            $scope.showStatusMsg('-', err);
            $scope.operationPending(false);
        };

        $scope.saveAllInvolvedParties = function (newComplaint) {
            var promises = [];
            var deferred = $q.defer();

            $scope.uiConfig.InvolvedParties.forEach(function (ip) {
                if ($scope.validateInvolvedParty($scope.current[ip.PropertyName])){
                    $scope.current[ip.PropertyName].ComplaintId = newComplaint.ComplaintId;

                    if (!!$scope.onBeforeSaveInvolvedParty) {
                        $scope.onBeforeSaveInvolvedParty($scope.current[ip.PropertyName]);
                    }

                    promises.push($scope.saveComplaintInvolvedParty($scope.current[ip.PropertyName]));
                }
            });

            $q.all(promises)
            .then(function (data) {
                deferred.resolve(data);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        };

        $scope.submitComplaintForm = function () {
            $scope.hideStatusMsg();

            if ($scope.validateForm()) {
                $scope.operationPending(true, 'Submitting...');

                var newComplaint = null;
                var involvedParties = null;

                if ($scope.beforeSubmitComplaintFormExt) {
                    $scope.beforeSubmitComplaintFormExt($scope.current.complaint);
                }

                PromiseHandlers.sequence($scope.saveComplaint, $scope.saveAllInvolvedParties, $scope.saveDataExt)
                .success(function (data) {
                    newComplaint = data.Complaintlst.firstOrDefault();

                    this.callNextFn(newComplaint);
                }, function (data) {
                    involvedParties = data.selectMany('ComplaintInvolvedPartyList');

                    this.callNextFn(newComplaint, involvedParties);
                }, function (data) {
                    $scope.resetComplaintForm();
                    $scope.showStatusMsg('+', $scope.uiConfig.SubmissionMessage);
                    $scope.operationPending(false);
                })
                .error(saveFailed,saveFailed,saveFailed)
                .start($scope.current.complaint);
            }
        };

        $scope.resetComplaintForm = function () {
            $scope.hideStatusMsg();
            
            resetForm();

            $scope.form.$setPristine();
        };

        $scope.goBackToOrigin = function () {
            if (!!$stateParams.origin) {
                $state.go($stateParams.origin);
            }
        };

        $scope.init = function () {
            $scope.current = {};
            $scope.uiLogic = {};
            $scope.refTables = {};
            $scope.pager = {};

            $scope.showGoBackButton = !!$stateParams.origin;

            getConfig();

            if (!!$scope.initExt) {
                $scope.initExt();
            }
        };
    }
})();
