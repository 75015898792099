angular.module('natApp').factory('LoginwoemailService', ['$http', 'WebApiUrl', function ($http, WebApiUrl) {
    return {
        Login: function (LastName, AccessCode, LicenseNumber, LoginWithoutEmail) {
            return $http.post(WebApiUrl + 'User/Login?Source=NVPHARMACY', { Email: '', Password: '', LastName: LastName, AccessCode: AccessCode, LicenseNumber: LicenseNumber, LoginWithoutEmail: LoginWithoutEmail });
        },
        ResetAccessCode: function (LastName, SSN, LicenseNumber, key) {
            return $http.post(WebApiUrl + 'User/ResetAccessCode/', { LastName: LastName, SSN: SSN, LicenseNumber: LicenseNumber });
        },
    };

}]);