(function () {
    'use strict';
    angular.module('natApp').run(natAppRun);

    natAppRun.$inject = ['$rootScope', 'AppConfig'];

    function natAppRun($rootScope, AppConfig) {
        $rootScope.BoardName = AppConfig.BoardName;
        $rootScope.CopyrightYear = AppConfig.CopyrightYear;
        $rootScope.BoardUrl = AppConfig.BoardUrl;
        $rootScope.BoardEmail = AppConfig.BoardEmail;
        $rootScope.BoardFotterName = AppConfig.BoardFotterName;
        $rootScope.BoardBackofficeLogoSealPath = AppConfig.BoardBackofficeLogoSealPath;
        $rootScope.BoardBackofficeLogoPath = AppConfig.BoardBackofficeLogoPath;
        $rootScope.BoardOnlineLogoSealPath = AppConfig.BoardOnlineLogoSealPath;
        $rootScope.BoardRightLogoSealPath = AppConfig.BoardRightLogoSealPath;
        $rootScope.BoardRightLogoWhitePath = AppConfig.BoardRightLogoWhitePath;
        $rootScope.BoardRightLogoAlt = AppConfig.BoardRightLogoAlt;
        $rootScope.BoardOnlineLogoPath = AppConfig.BoardOnlineLogoPath;
        $rootScope.DefaultCountryId = AppConfig.DefaultCountryId;
        dateProtoType();

        var stateChangeStartEvent = $rootScope.$on('$stateChangeStart', function (event, next, current) {
            $(document).off('keydown');
            if (!!sessionStorage.isBackOfficeUser && (sessionStorage.isBackOfficeUser == true || sessionStorage.isBackOfficeUser == 'true')) {
                sessionStorage.logCurrentIndividualId = null;
            }
        });

        $rootScope.$on('$destroy', function () {
            stateChangeStartEvent();
        });

    };


    function dateProtoType() {
        Date.prototype.toJSON = function () {
            var timezoneOffsetInMinutes = -this.getTimezoneOffset(); //UTC minus local time
            //var sign = timezoneOffsetInMinutes >= 0 ? '+' : '-';
            //var leadingZero = (timezoneOffsetInHours < 10) ? '0' : '';

            //It's a bit unfortunate that we need to construct a new Date instance 
            //(we don't want _this_ Date instance to be modified)
            var correctedDate = new Date(this.getFullYear(), this.getMonth(),
                this.getDate(), this.getHours(), this.getMinutes(), this.getSeconds(),
                this.getMilliseconds());
            correctedDate.setMinutes(this.getMinutes() + timezoneOffsetInMinutes);
            var isoDateString = correctedDate.toISOString().replace('Z', '');

            //return iso + sign + leadingZero + Math.abs(timezoneOffsetInHours).toString() + ':00';
            return isoDateString;
        };

        // String.prototype.getDateObj = function () {
        //     if (this != null && this != '' && this.length >= 10) {
        //         var year = this.substr(0, 4);
        //         var month = this.substr(5, 2);
        //         month = month - 1;
        //         var date = this.substr(8, 2);
        //         var dobObj = new Date(year, month, date);
        //         return dobObj;
        //     } else {
        //         return null;
        //     }
        // };
    };

})();

