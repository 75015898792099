(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeIndividualLicenseService', backofficeIndividualLicenseService)

    backofficeIndividualLicenseService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeIndividualLicenseService($http, $q, WebApiUrl, AppConfig) {

        var _individualLicenseDetailBYIndividualId = function (Key, IndividualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualLicenseDetailBYIndividualId/' + Key + '?IndividualId=' + IndividualId));
        };

        var _applicationBYIndividualId = function (urlParm) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/ApplicationBYIndividualId/' + urlParm));
        };

        var _licenseSave = function (individualLicense, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualLicenseSave/' + key, individualLicense));
        };

        var _individualEducationBYIndividualLicenseId = function (Key, IndividualLicenseId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualEducationBYIndividualLicenseId/' + Key + '?IndividualLicenseId=' + IndividualLicenseId));
        };
        var _generateApplicationId = function (Key, ApplicationId, UserId, applicationObj) {
            return returnDefferedResult($http.post(WebApiUrl + 'Application/GenerateApplicationId/' + Key + '?ApplicationId=' + ApplicationId + "&UserId=" + UserId, applicationObj));
        };
        var _licenseConversion = function (individualLicense, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualLicenseConversion/' + key, individualLicense));
        };

        var _getApplicationDocumentByIndividualIdForOthers = function (Key, IndividualId, DocumentId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/ApplicationDocumentByIndividualId/' + Key + '?IndividualId=' + IndividualId + '&Source=' + AppConfig.Source + '&DocumentId=' + DocumentId));
        };

        var _getMaxLicenseByLicenseTypeId = function (Key, licenseTypeId) {
            return returnDefferedResult($http.get(WebApiUrl + 'IndividualLicense/MaxLicenseByLicenseTypeId/' + Key + '?LicenseTypeId=' + licenseTypeId));
        };
        var _sendGenericEmailandLogCommunication = function (Key, Obj) {
            return returnDefferedResult($http.post(WebApiUrl + 'Template/SendGenericEmailandLogCommunication/' + Key + '?Source=' + AppConfig.Source, Obj));
        };

        var _getComplaintAssociatedLicense = function (Key, licenseTypeId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Complaint/GetComplaintAssociatedLicense/' + Key + '?LicenseTypeId=' + licenseTypeId));
        };

        var _getIndividualSupervisoryInfo = function (Key, IndividualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualSupervisoryInfo/' + Key + '?IndividualId=' + IndividualId + '&EntityId=' + IndividualId + '&EntityType=I'));
        };
        var _getIndividualPreviousLicenseBYIndividualId = function (Key, IndividualId, ApplicationId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualPreviousLicenseBYIndividualId/' + Key + '?IndividualId=' + IndividualId + '&ApplicationId=' + ApplicationId + '&UserId=null'));
        };

        var _getLicenseTypes = function (key) {
            var urlParams = {
                tableKey: 'licensetype'
            };
            return returnDefferedResult($http.get(WebApiUrl + 'TypeValues/RefTablesGetAll/' + key + '/', { params: urlParams }));
        };

        var _getLicenseStatuses = function (key) {
            return returnDefferedResult($http.get(WebApiUrl + 'TypeValues/LicenseStatusTypeGetAll/' + key + '/'));
        };

        var _getUserProfiles = function (key, userId) {
            var postObj = {
                UserId: userId,
                ProfileId: 0,
                FromRow: 0,
                RecordPerPage: 0,
                Order: false,
                OrderByField: ''
            };
            return returnDefferedResult($http.post(WebApiUrl + 'Profile/GetUserProfileList/' + key + '/', postObj));
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };
        return {
            individualLicenseDetailBYIndividualId: _individualLicenseDetailBYIndividualId,
            applicationBYIndividualId: _applicationBYIndividualId,
            licenseSave: _licenseSave,
            individualEducationBYIndividualLicenseId: _individualEducationBYIndividualLicenseId,
            generateApplicationId: _generateApplicationId,
            licenseConversion: _licenseConversion,
            getApplicationDocumentByIndividualIdForOthers: _getApplicationDocumentByIndividualIdForOthers,
            getMaxLicenseByLicenseTypeId: _getMaxLicenseByLicenseTypeId,
            sendGenericEmailandLogCommunication: _sendGenericEmailandLogCommunication,
            getComplaintAssociatedLicense: _getComplaintAssociatedLicense,
            getIndividualSupervisoryInfo: _getIndividualSupervisoryInfo,
            getIndividualPreviousLicenseBYIndividualId: _getIndividualPreviousLicenseBYIndividualId,
            getLicenseTypes: _getLicenseTypes,
            getLicenseStatuses: _getLicenseStatuses,
            getUserProfiles: _getUserProfiles
        }
    }
})();
