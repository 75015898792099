(function () {
    'use strict'
    angular.module('app.core').controller('providerAddressController', ['$rootScope', '$scope', 'providerAddressService', 'dcaIService', 'typeValuesService', 'TypeValue',
        function ($rootScope, $scope, providerAddressService, dcaIService, typeValuesService, TypeValue) {
            var LoggedUserKey = sessionStorage.Key;
            var LoggedUserID = sessionStorage.UserId;
            var providerId = sessionStorage.ProviderId;
            $scope.AddressDetails = {};
            $scope.PAddressDetails = {}; 

            var init = function () {
                $scope.netValidator = {};
                getStateList($rootScope.DefaultCountryId);
                getAddressDetails();
            };

            var getAddressDetails = function () {
                var urlParam = "/" + LoggedUserKey + "?ProviderId=" + providerId;
                providerAddressService.GetAddressDetails(urlParam).then(function (res) {
                    var providerRes = res.data.ProviderAddress;

                    $scope.MailingAddress = getAddressbyType(providerRes, 1);
                    $scope.HomeAddress = getAddressbyType(providerRes, 2);
                    // $scope.employeraddress = getAddressbyType(indvRes, 12);

                    // if ($scope.HomeAddress && $scope.HomeAddress.IsMailingSameasPhysical) {
                    //     $scope.MailingAddress = angular.copy($scope.HomeAddress);
                    // } else if (employeraddress && employeraddress.IsMailingSameasPhysical) {
                    //     $scope.MailingAddress = angular.copy(employeraddress);
                    // }
                    //if mailing address is empty
                    // if (!$scope.employeraddress) {
                    //     $scope.MailingAddress = getAddressbyType(indvRes, 1);
                    // }
                    if (!$scope.MailingAddress) {
                        $scope.MailingAddress = {
                            providerId: $scope.providerId,
                            AddressTypeId: 1,
                            IsActive: true,
                            StreetLine1: '',
                            StreetLine2: '',
                            City: '',
                            StateCode: '',
                            CountryId: 0,
                            Zip: '',
                            IsMailingSameasPhysical: false,
                            AddressStatusId: 1,
                            BeginDate: new Date()
                        };
                        $scope.loadZipRange('CA');
                    }
                    else{
                        //$scope.AddressDetails.IsMailingSameasPhysical = !$scope.MAddressIsPO;
                        // $scope.MAddressIsPO = !$scope.MailingAddress.IsMailingSameasPhysical;
                    }

                    if (!$scope.HomeAddress) {
                        $scope.HomeAddress = {
                            IndividualId: $scope.individualid,
                            AddressTypeId: 2,
                            IsActive: true,
                            StreetLine1: '',
                            StreetLine2: '',
                            City: '',
                            StateCode: '',
                            CountryId: 0,
                            Zip: '',
                            IsMailingSameasPhysical: false,
                            AdressStatusId: 1,
                            BeginDate: new Date()
                        };
                        $scope.loadZipRange('CA');
                    }
                    if(true) {
                        setStateName();
                        setCountryName();
                    }
                });
            };

            var getAddressbyType = function (addList, type) {
                var res;
                if (addList && addList.length > 0) {
                    for (var i = 0; i < addList.length; i++) {
                        if (addList[i].AddressTypeId == type && addList[i].AddressStatusId == 1) {
                            if (!res) {
                                res = addList[i];
                            } else if (res.AddressId < addList[i].AddressId) {
                                res = addList[i];
                            }
                        }
                    }
                }
                return res;
            };

            $scope.updateIServiceData = function (requestData, serviceType) {
                var dataPromise = null;
                if (serviceType == "Phone") {
                    dataPromise = dcaIService.updatePhone(sessionStorage.Key, requestData);
                } else if (serviceType == "Email") {
                    dataPromise = dcaIService.updateEmail(sessionStorage.Key, requestData);
                } else if (serviceType == "Address") {
                    dataPromise = dcaIService.updateAddressForFirms(sessionStorage.Key, requestData);
                }

                dataPromise
                    .then(function (responseUpdate) {
                        if (!responseUpdate.Status) {

                        }
                    }, function (err) {
                    });
            };

            var saveAddressDetails = function (form) {
                // $scope.AddressDetails.IsMailingSameasPhysical = !$scope.MAddressIsPO;
                // MAddressIsPO
                if (!validateForm(form)) {
                    return;
                }
                ShowLoader();
                $scope.AddressDetails.ActionFlag = 'UI';
                $scope.AddressDetails.IsMailingSameasPhysical = !$scope.MAddressIsPO;
                let addressList = [$scope.AddressDetails];
                $scope.PAddressDetails.ActionFlag = 'UI';
                if($scope.MAddressIsPO){
                    addressList.push($scope.PAddressDetails);
                }
                else if(!!$scope.PAddressDetails.ProviderAddressId){
                    $scope.PAddressDetails.AdressStatusId = 2;
                    $scope.PAddressDetails.IsDeleted = true;
                    addressList.push($scope.PAddressDetails);
                }
                addressList[0].AdressStatusId = addressList[0].AddressStatusId;
                providerAddressService.SaveAddressDetails(providerId, LoggedUserKey, addressList[0]).then(function (response) {
                    var requestData = {
                        EntityId: providerId,
                        ProviderAddressId: response.data.ProviderAddressId,              
                        MailingAddressId: -1,
                        ResidentialAddressId: -1,
                        SendMultiple: true
                    };
                    let addressList = response.data.ProviderAddressList.where(a => a.IsActive && !a.IsDeleted && a.AddressStatusId == 1).orderByDescending(a=> a.ProviderAddressId);
                    if (!!addressList.where(a=> a.AddressTypeId == 1)) {
                        requestData.MailingAddressId = addressList.where(a=> a.AddressTypeId == 1)[0].ProviderAddressId;
                    }

                    if ($scope.AddressDetails.IsMailingSameasPhysical == false && !!addressList.where(a=> a.AddressTypeId == 2)) {
                        requestData.ResidentialAddressId = addressList.where(a=> a.AddressTypeId == 2)[0].ProviderAddressId;        
                    }

                    $scope.updateIServiceData(requestData, "Address");

                    $scope.HomeAddress = undefined;
                    $scope.MailingAddress = undefined;
                    getAddressDetails();
                    HideLoader();
                }, function () { HideLoader() });
                $scope.HideEditBtn = false;
            };

            var getStateList = function (countryID) {
                providerAddressService.GetStateList(LoggedUserKey, countryID).then(function (res) {
                    $scope.StateList = res.data.State;
                    typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=zip")).then(function (data) {
                        $scope.ZipList = data.ReferenceEntities;
                        $scope.loadZipRange('CA');
                    });
                    typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=city")).then(function (dataCity) {
                        $scope.CityList = dataCity.ReferenceEntities;
                    });
                    typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=country")).then(function (dataCountry) {
                        $scope.CountryList = dataCountry.ReferenceEntities;
                        setCountryName();
                    });
                    setStateName();
                });
            };

            $scope.loadCityTypeahed = function (searchString, stateCode) {
                var cityList = [];

                if (!!$scope.CityList && stateCode == 'CA') {
                    cityList = $scope.CityList.filter(function (item) {
                        if (!!item.CityName && item.CityName.toLowerCase() == searchString.toLowerCase()) {
                            return item;
                        }
                    });

                    if (cityList.length == 1) {
                        return cityList;
                    }

                    cityList = $scope.CityList.filter(function (item) {
                        if (!!item.CityName && item.CityName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) {
                            return item;
                        }
                    });
                }

                return cityList;
            };

            $scope.loadZipRange = function (stateCode) {
                if (!!$scope.ZipList) {
                    $scope.zipRange = [];
                    var zipItem = $scope.ZipList.whereEquals(stateCode, 'StateCd').firstOrDefault();
                    if (!!zipItem) {
                        for (var i = parseInt(zipItem.LowZip); i < parseInt(zipItem.HighZip); i++) {
                            $scope.zipRange.push({
                                Zip: i.toString().padStart(5, 0)
                            });
                        }
                    }
                    return $scope.zipRange;
                }
            };

            $scope.loadZipTypeahed = function (searchString, stateCode) {
                var zipRange = $scope.loadZipRange(stateCode);
                if (zipRange.length > 0) {
                    return zipRange.filter(function (item) {
                        if (!!zipRange && item.Zip.startsWith(searchString)) {
                            return item;
                        }
                    });
                }
            };

            var setStateName = function () {
                if ($scope.StateList && $scope.StateList.length > 0) {
                    //set home state
                    if ($scope.HomeAddress) {
                        var len = $scope.StateList.length;
                        for (var i = 0; i < len; i++) {
                            if ($scope.StateList[i].StateCode == $scope.HomeAddress.StateCode) {
                                $scope.HomeAddress.StateName = $scope.StateList[i].Name;
                                break;
                            }
                        }
                    }
                    //set mailing State
                    if ($scope.MailingAddress) {
                        var len = $scope.StateList.length;
                        for (var i = 0; i < len; i++) {
                            if ($scope.StateList[i].StateCode == $scope.MailingAddress.StateCode) {
                                $scope.MailingAddress.StateName = $scope.StateList[i].Name;
                                break;
                            }
                        }
                    }
                };
            };

            var setCountryName = function () {
                if ($scope.CountryList && $scope.CountryList.length > 0) {
                    //set home country
                    if ($scope.HomeAddress) {
                        var len = $scope.CountryList.length;
                        for (var i = 0; i < len; i++) {
                            if ($scope.CountryList[i].CountryId == $scope.HomeAddress.CountryId) {
                                $scope.HomeAddress.CountryName = $scope.CountryList[i].Name;
                                break;
                            }
                        }
                    }
                    //set mailing Country
                    if ($scope.MailingAddress) {
                        var len = $scope.CountryList.length;
                        for (var i = 0; i < len; i++) {
                            if ($scope.CountryList[i].CountryId == $scope.MailingAddress.CountryId) {
                                $scope.MailingAddress.CountryName = $scope.CountryList[i].Name;
                                break;
                            }
                        }
                    }
                };
            };

            //This function validates the for on click of submit button
            var validateForm = function (form, error) {
                $scope.netValidator.errorMessages = [];
                if (!!form) {
                    form.$submitted = true;
                    var errorMessages = angular.copy(form.errorMessages);
                    angular.forEach(errorMessages, function (errorMessage, index) {
                        $scope.netValidator.errorMessages.push(errorMessage);
                    });
                }
                if (!!error) {
                    $scope.netValidator.errorMessages.push(error);
                }
                if (!!$scope.netValidator.errorMessages && $scope.netValidator.errorMessages.length > 0) {
                    $scope.netValidator.showError = true;
                    $scope.netValidator.isValid = false;
                    return false;
                } else {
                    $scope.netValidator.showError = false;
                    $scope.netValidator.isValid = true;
                    return true;
                }
            };

            $scope.addressText = "";
            $scope.BtnClickEvent = function (cmd, form) {
                switch (cmd) {
                    case "editHome":
                        {
                            $scope.AddressDetails = angular.copy($scope.HomeAddress);
                            $scope.addressText = "Address"
                            $scope.HideEditBtn = true;
                            break;
                        }
                    case "editMailing":
                        {
                            $scope.AddressDetails = angular.copy($scope.MailingAddress);
                            $scope.PAddressDetails = angular.copy($scope.HomeAddress);
                            $scope.addressText = "Mailing Address"
                            $scope.PaddressText = "Home Address"
                            $scope.HideEditBtn = true;
                            break;
                        }
                    case "editCompany":
                        {
                            $scope.AddressDetails = angular.copy($scope.employeraddress);
                            $scope.addressText = "Company Address"
                            $scope.HideEditBtn = true;
                            break;
                        }
                    case "save":
                        {
                            saveAddressDetails(form);
                            break;
                        }
                    case "cancel":
                        {
                            $scope.HideEditBtn = false;
                            break;
                        }
                }
                $scope.loadZipRange();
            };

            var validateAddress = function () {
                var error = '';
                if (!$scope.AddressDetails) {
                    $scope.AddressDetails = {};
                }
                if (!$scope.AddressDetails.StreetLine1 || $scope.AddressDetails.StreetLine1 == '') {
                    error += '<span class="notok"></span>Please enter street. <br/>';
                }
                if (!$scope.AddressDetails.City || $scope.AddressDetails.City == '') {
                    error += '<span class="notok"></span>Please enter city <br/>';
                }
                if (!$scope.AddressDetails.StateCode || $scope.AddressDetails.StateCode == '') {
                    error += '<span class=notok></span>Please select state<br/>';
                }
                if (!$scope.AddressDetails.Zip || $scope.AddressDetails.Zip == '') {
                    error += '<span class="notok"></span>Please enter zip <br/>';
                }
                if (error != '') {
                    $('#addressDirErr').show();
                    $('#addressDirErr').html(error);
                    return false;
                } else {
                    $('#addressDirErr').hide();
                }
                return true;
            };

            init();
        }
    ]);
})();