(function() {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('peerReview', peerReview);

    peerReview.$inject = [];

    function peerReview() {
        return {
            restrict: 'E',
            scope: {
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                natValidation: "=natValidation",
                legalInfo: "=?legalInfo",
                providerId: '=providerId',
                applicationId: '=applicationId',
                userId: '=userId',
                isRenewal: '=?isRenewal',
                loadData: '=?loadData',
                applicationInfo: "=?applicationInfo",
                providerLicense: "=?providerLicense",
                showStatusMessage: "&",
                useType: "=?useType",
                tab: "=tab",
                openNextTab: "&",
                isBackoffice: "=isBackoffice",
                feeDetails: "=feeDetails"
            },
            templateUrl: "app/components/provider/application/directives/peer-review/peer-review.html",
            controller: "PeerReviewController"
        }
    }
})();