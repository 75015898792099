(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeIndividualNameController", BackofficeIndividualNameController);

    BackofficeIndividualNameController.$inject = ["$rootScope", "$scope", "$q",
        "backofficeIndividualNameService", "utilityService", "lookupService", "typeValuesService", "LookUpType", "TypeValue", "Messages"
    ];

    function BackofficeIndividualNameController($rootScope, $scope, $q,
        backofficeIndividualNameService, utilityService, lookupService, typeValuesService, LookUpType, TypeValue, Messages) {

        $scope.viewConfig = {
            showAddBlock: false
        };
        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/d!/yyyy',
            startDateOptions: {
                minDate: null,
            },
            endDateOptions: {}
        };

        $scope.isNameEdit = false;
        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "name" && !$rootScope.individualCurrentTab.isLoadedOnce) {
                $scope.IndividualId = individualId;
                if (!$scope.isLoadedOnce) {
                    $scope.loadNameData();
                    $scope.isLoadedOnce = true;
                } else {
                    $scope.individualNameByIndividualId($scope.IndividualId, true);
                }
                $rootScope.individualCurrentTab.isLoadedOnce = true;
            }
        });

        $scope.loadNameData = function () {
            var dataPromises = [];

            dataPromises.push(typeValuesService.getTypeValuesByTypeName(TypeValue.Suffix, sessionStorage.Key));
            dataPromises.push(lookupService.getLookUpDataByTypeId(LookUpType.NameStatusTypeId, sessionStorage.Key));
            dataPromises.push(backofficeIndividualNameService.individualNameBYIndividualId(sessionStorage.Key, $scope.IndividualId));

            $q.all(dataPromises)
                .then(function (response) {
                    angular.forEach(response, function (responseItem, itemIndex) {
                        if ($scope.checkServerResponse(responseItem)) {
                            if (itemIndex == 0) {
                                $scope.suffixTypes = responseItem.RefSuffixResponseList;
                            } else if (itemIndex == 1) {
                                $scope.nameStatuses = responseItem.Lookup;
                            } else if (itemIndex == 2) {
                                $scope.individualNames = processNamesData(responseItem.IndividualNameResponse);
                            }
                        }
                    });
                    $scope.isNameLoadedOnce = true;
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    if (data != null) {
                        $scope.showStatusMessage(data.message, "error");
                    }
                });
        };

        $scope.setNameEndMinDate = function () {
            if (angular.isDate($scope.individualName.BeginDate))
                $scope.datePicker.endDateOptions.minDate = $scope.individualName.BeginDate;
            else
                $scope.datePicker.endDateOptions.minDate = null;
        };

        $scope.setNameBeginMaxDate = function () {
            if (angular.isDate($scope.individualName.EndDate))
                $scope.datePicker.startDateOptions.maxDate = $scope.individualName.EndDate;
            else
                $scope.datePicker.startDateOptions.maxDate = null;
        };


        $scope.openAddEditName = function (isEdit, individualName) {
            ShowLoader();
            $scope.isNameEdit = isEdit;
            $scope.viewConfig.showAddBlock = true;
            if (!$scope.isNameEdit) {
                $scope.individualName = {
                    IndividualId: $scope.IndividualId,
                    IsActive: true,
                    BeginDate: new Date()
                }
            } else {
                $scope.individualName = angular.copy(individualName);
            }
            HideLoader();
        };

        $scope.closeAddEditName = function () {
            $scope.viewConfig.showAddBlock = false;
        };

        var processNamesData = function (individualNamesData) {
            angular.forEach(individualNamesData, function (individualName, index) {
                angular.forEach($scope.nameStatuses, function (nameStatus, index) {
                    if (nameStatus.LookupId == individualName.IndividualNameStatusId) {
                        individualName.StatusName = nameStatus.LookupDesc;
                    }
                });
            });
            return individualNamesData;
        };

        $scope.individualNameByIndividualId = function (IndividualId, hideMessages) {
            ShowLoader("Please Wait...");
            backofficeIndividualNameService.individualNameBYIndividualId(sessionStorage.Key, $scope.IndividualId)
                .then(function (response) {
                        if (hideMessages) {
                            $scope.hideStatusMessage();
                            $scope.hideValidationSummary();
                        }
                        HideLoader();
                        if ($scope.checkServerResponse(response)) {
                            $scope.individualNames = processNamesData(response.IndividualNameResponse);
                        }
                    },
                    function (data) {
                        $scope.showStatusMessage(data, "error");
                    });
        };

        // Save IndividualName
        $scope.saveIndividualName = function (nameForm) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                nameForm.$submitted = true;
                if ($scope.validateData(nameForm)) {
                    ShowLoader("Please wait...");
                    $scope.saveIndividualNameRecord();
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) {
                    $scope.showStatusMessage(ex.message, "error");
                }
            }
        };

        $scope.saveIndividualNameRecord = function () {
            var individualNameData = angular.copy($scope.individualName);
            backofficeIndividualNameService.individualNameSave([individualNameData],
                sessionStorage.Key).then(function (response) {
                if ($scope.checkServerResponse(response)) {
                    $scope.individualNameByIndividualId($scope.IndividualId);
                    $scope.closeAddEditName();
                    $scope.showStatusMessage(response.Message, "success");
                }
                HideLoader();
            }, function (data) {
                HideLoader();
                if (data != null) {
                    $scope.showStatusMessage(data.message, "error");
                }
            });
        };


        $scope.deleteIndividualName = function (individualName) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                $rootScope.individualEventLog('deleteStatus.deleteConfirmed', $scope.IndividualId);
                ShowLoader("Please Wait...");
                $scope.individualName = angular.copy(individualName);
                $scope.individualName.IsDeleted = true;
                $scope.saveIndividualNameRecord();
            } else {
                $rootScope.individualEventLog('deleteStatus.deleteCanceled', $scope.IndividualId);
            }
        };
    }
})();