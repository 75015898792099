(function () {
    'use strict'

    angular.module('app.backofficeEntity')
        .factory('backofficeEntityLogService', backofficeEntityLogService)

    backofficeEntityLogService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeEntityLogService($http, $q, WebApiUrl, AppConfig) {

        return {
            entityLogGetByEntityId: _entityLogGetByEntityId
        };

        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function _entityLogGetByEntityId(Key, entityId, entityType) {
            return returnDefferedResult($http.get(WebApiUrl + 'EntityLog/EntityLogGetByEntityId/' + Key + '?EntityId=' + entityId + '&EntityType=' + entityType));
        }
    }
})();