(function () {
    'use strict';

    angular
        .module('natApp')
        .controller("FirmLicenseRenewalApplicationOnlineController", FirmLicenseRenewalApplicationOnlineController);

    FirmLicenseRenewalApplicationOnlineController.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "licenseRenewalService", "AppConfig"];

    function FirmLicenseRenewalApplicationOnlineController($scope, $rootScope, $state, $stateParams, licenseRenewalService, AppConfig) {
        console.log('Online');
        $scope.isBackoffice = false;
        $rootScope.renewalType = sessionStorage.renewalType;
        $scope.listConfig = {};
        $rootScope.routeData = $state.current.routeData;


        function initialProcessing() {
            angular.forEach($scope.renewalCheckData.ProviderLicenseList, function (licenseType) {
                if (licenseType.ProviderStatusTypeId == 1 || licenseType.ProviderStatusTypeId == 7 || licenseType.ProviderStatusTypeId == 8) {
                    licenseType.applicationType = 'active';
                    licenseType.currentApplicationType = 'active';
                }
            });
            processApplicationData();
        };

        var processApplicationData = function () {
            var renewalGroups = [];
            $scope.renewalCheckData.selectedGroups = {};
            var historyQuestionContentId = '';
            angular.forEach($scope.renewalCheckData.ProviderLicenseList, function (licenseType) {
                if (licenseType.IsSelectedToRenew) {
                    if (renewalGroups.indexOf(licenseType.RenewalApplicationGrouping) == -1)
                        renewalGroups.push(licenseType.RenewalApplicationGrouping);
                    $scope.renewalCheckData.selectedGroups["group" + licenseType.RenewalApplicationGrouping] = true;
                    if (!!licenseType.RenewalApplicationHistoryQuestionContentId) {
                        if (!historyQuestionContentId)
                            historyQuestionContentId = licenseType.RenewalApplicationHistoryQuestionContentId;
                        else
                            historyQuestionContentId.concat(',', licenseType.RenewalApplicationHistoryQuestionContentId);
                    }
                }
            });
            $scope.renewalCheckData.RenewalApplicationHistoryQuestionContentId = historyQuestionContentId.split(',').distinct();
            $scope.renewalCheckData.renewalGroups = renewalGroups;
        };

        $scope.checkAndCreatePendingRenewal = function () {
            ShowLoader();
            $scope.renewalCheckData = {
                IsBackOffice: $scope.isBackoffice,
                CheckRenewalEligibility: false,
                CreatePendingInternally: false,
                ReceivedDate: null,
                ProviderId: sessionStorage.ProviderId,
                ApplicationId: sessionStorage.deficientApplicationId ? sessionStorage.deficientApplicationId : null,
                //ApplicationId: null,
                BoardInfoId: 1,
                EntityType: 'P',
                ProviderTypeId: 5,
                OpenExistingApplication: (!!sessionStorage.isDeficientApplication)
            };
            licenseRenewalService.checkAndCreatePendingRenewal(sessionStorage.Key, $scope.renewalCheckData).then(function (responseLicenseCheck) {
                if (!!responseLicenseCheck.Status) {
                    HideLoader();
                    $scope.renewalCheckData = responseLicenseCheck.RenewalCheckData;
                    $scope.DisplayAddApplication = true;
                    $scope.IsPendingApplication = true;
                    $scope.loadApplication = true;
                    $scope.showRenewalTypeEnabled = true;
                    initialProcessing();
                } else {
                    $scope.DisplayAddApplication = false;
                    $scope.IsPendingApplication = false;
                    $scope.loadApplication = false;
                    showStatusMessage(responseLicenseCheck.Message, "error");
                    HideLoader();
                }
            }, function (data) {
                HideLoader();
                if (data != null) {
                    showStatusMessage(data.message, "error");
                }
            });
        };

        $scope.checkAndCreatePendingRenewal();
    }
})();