(function () {
    'use strict'
    angular.module('app.core')
        .controller('UserPortalViewProcessingController', UserPortalViewProcessingController);

    UserPortalViewProcessingController.$inject = ['$rootScope', '$scope', '$q', '$state', 'userPortalViewService'];

    function UserPortalViewProcessingController($rootScope, $scope, $q, $state, userPortalViewService) {

        function init() {
            ShowLoader();
            var userPortalViewData = userPortalViewService.getUserPortalViewData();
            if (!!userPortalViewData && (userPortalViewData.currentUserData.ProviderId == 0 || userPortalViewData.currentUserData.ProviderId == undefined)) {
                // console.log("res", userPortalViewData.currentUserData.ProviderId);
                userPortalViewService.individualBYIndividualId(userPortalViewData.tokenKey, userPortalViewData.IndividualId).then(function (response) {
                    userPortalViewData.personalInfo = response.IndividualResponse[0];
                    setSessionStorageUserData(userPortalViewData);
                    userPortalViewService.resetUserPortalViewData();
                    sendUserToRespectivePortal(userPortalViewData);
                }, function (data) {
                    HideLoader();
                    $scope.showStatusMessage(data, "error");
                });
            }
            else if (!!userPortalViewData && userPortalViewData.currentUserData.ProviderId != 0) {
                userPortalViewService.getProvider(userPortalViewData.tokenKey, userPortalViewData.currentUserData.ProviderId).then(function (response) {
                    // console.log("response from providerId", response);
                    var isFirmFirstLogin = userPortalViewData.currentUserData.IsFirstLogin;
                    userPortalViewData.personalInfo = response.ProviderResponse;
                    setSessionStorageUserDataFirm(userPortalViewData);
                    userPortalViewService.resetUserPortalViewData();
                    if (isFirmFirstLogin) {
                        $state.go('app.ProviderUserLinking');
                    } else {
                        $state.go('app.providerdashboard');
                    }
                }, function (data) {

                    HideLoader();
                    $scope.showStatusMessage(data, "error");
                });
            }
            else {
                HideLoader();
            }
        }

        var setSessionStorageUserDataFirm = function (data) {
            sessionStorage.ProviderId = data.currentUserData.ProviderId;
            sessionStorage.logCurrentProviderId = data.currentUserData.ProviderId;
            sessionStorage.Email = data.currentUserData.Email;
            // sessionStorage.FirstName = data.personalInfo.FirstName;
            // // sessionStorage.LastName = data.personalInfo.LastName;
            sessionStorage.UserID = data.currentUserData.UserID;
            sessionStorage.UserId = data.currentUserData.UserID;
            sessionStorage.applicantUserId = data.currentUserData.UserID;
            sessionStorage.UserName = data.currentUserData.UserName;
            sessionStorage.UserTypeID = (!!data.currentUserData.UserTypeId ? data.currentUserData.UserTypeId : data.currentUserData.UserTypeID);
            sessionStorage.IsFirstLogin = data.currentUserData.IsFirstLogin;
            sessionStorage.Key = data.tokenKey;
            sessionStorage.isUserPortalView = data.isUserPortalView;
            sessionStorage.isBackOfficeUser = false;
        };
        var setSessionStorageUserData = function (data) {
            sessionStorage.IndividualId = data.IndividualId;
            sessionStorage.logCurrentIndividualId = data.IndividualId;
            sessionStorage.Email = data.currentUserData.Email;
            sessionStorage.FirstName = data.personalInfo.FirstName;
            sessionStorage.LastName = data.personalInfo.LastName;
            sessionStorage.UserID = data.userId;
            sessionStorage.UserId = data.userId;
            sessionStorage.applicantUserId = data.currentUserData.UserId;
            sessionStorage.UserName = data.currentUserData.UserName;
            sessionStorage.UserTypeID = (!!data.currentUserData.UserTypeId ? data.currentUserData.UserTypeId : data.currentUserData.UserTypeID);
            sessionStorage.IsFirstLogin = data.currentUserData.IsFirstLogin;
            sessionStorage.Key = data.tokenKey;
            sessionStorage.isUserPortalView = data.isUserPortalView;
            sessionStorage.isBackOfficeUser = false;
        };

        var sendUserToRespectivePortal = function (userData) {
            HideLoader();
            if (userData.currentUserData.IsFirstLogin) {
                $state.go('app.UserLinking');
            } else {
                $state.go('app.Dashboard');
            }

        };


        init();
    }
})();