(function () {
    'use strict'

    angular.module('app.core')
    .directive('inlumonHighlightSearch', ['$filter', '$sanitize', HighlightSearch]);

    function HighlightSearch($filter, $sanitize) {
        return {
            restrict: 'A',
            priority: -1,
            link: function ($scope, element, attrs) {
                var toWatch = '';
                if (!!attrs.ngBind) {
                    toWatch = attrs.ngBind;
                } else if (!!attrs.ngBindHtml) {
                    toWatch = attrs.ngBindHtml;
                }

                var updateHighlight = function (value, searchText) {
                    var html = $sanitize(value);

                    if (!value || !searchText) { element[0].innerHTML = html; return; }
                    
                    try {
                        searchText = searchText
                            .replace(/\(/g, '\\(')
                            .replace(/\)/g, '\\)')
                            .replace(/\$/g, '\\$')
                            .replace(/\,/g, '\\,')
                            .replace(/\./g, '\\.');
                        
                        var r = '(<[^>]*>)|(#[^;]*;)|({0})([^<]*?)'.format(searchText);
                        var regex = new RegExp(r, 'gmi');
    
                        var replacements = [];
                        var matches = regex.exec(html);
                        while (!!matches) {
                            if (!matches[1] && !matches[2] && !!matches[3]) {
                                var matchIndex = matches.index;
                                var matchLen = matches[3].length;
    
                                replacements.push({
                                    index: matchIndex,
                                    length: matchLen,
                                    replace: '<span class="inlumon-search-highlight">{0}</span>'.format(matches[3])
                                });
                            } else if (!matches[1] && !matches[2] && !matches[3]) {
                                break;
                            }
    
                            matches = regex.exec(html);
                        }
    
                        // Order by descending index so that the text is replaced from the end of the string to the beginning
                        // This will preserve the found indices in the replacement strings.
                        replacements = replacements.orderByDescending('index');
                        replacements.forEach(function (replacement) {
                            html = html.splice(replacement.index, replacement.length, replacement.replace);
                        });
                        element[0].innerHTML = html;
                    } catch (ex) {
                        console.error(ex);
                    }
                };

                var currentSearchText = '';
                var currentValue = '';

                if (!!toWatch) {
                    $scope.$watch(toWatch, function (newVal) {
                        if (typeof(newVal) != 'string') {
                            newVal = '' + newVal;
                        }
                        $scope.$applyAsync(function () {
                            currentValue = newVal;
                            updateHighlight(currentValue, currentSearchText);
                        });
                    });
                    $scope.$watch(attrs.inlumonHighlightSearch, function (newVal) {
                        if (typeof(newVal) != 'undefined') {
                            $scope.$applyAsync(function () {
                                currentSearchText = newVal.toHtmlSearch();
                                updateHighlight(currentValue, currentSearchText);
                            });
                        }
                    }, true);
                }
            },
        };
    }
})();
