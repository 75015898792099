angular.module('natApp').factory('LoginwoemailService', ['$http', 'WebApiUrl', '$q', function ($http, WebApiUrl, $q) {
    return {
        Login: function (LastName, AccessCode, LicenseNumber, LoginWithoutEmail) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'User/Login?Source=NVPHARMACY', { Email: '', Password: '', LastName: LastName, AccessCode: AccessCode, LicenseNumber: LicenseNumber, LoginWithoutEmail: LoginWithoutEmail })
                .then(function (res) {
                    deferred.resolve(res);
                }, function (res) {
                    deferred.reject(res);
                });
            return deferred.promise;
        },
        ResetAccessCode: function (LastName, SSN, LicenseNumber, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'User/ResetAccessCode/', { LastName: LastName, SSN: SSN, LicenseNumber: LicenseNumber })
                .then(function (res) {
                    deferred.resolve(res);
                }, function (res) {
                    deferred.reject(res);
                });
            return deferred.promise;
        },
    };
}]);