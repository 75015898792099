(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('telemedicineLogic', [TelemedicineLogicDirective]);

    function TelemedicineLogicDirective() {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                $scope.$onBeforeLoad = function () {
                    console.log('Loading data...');
                };

                // Available hooks and default implementations
                // $scope.$onBeforeConfig = function () {};
                // $scope.$onAfterConfig = function (config) {};
                // $scope.$onConfigError = function (error) {};
                // $scope.$onConfigEx = function (ex) {};
        
                // $scope.$onBeforeContent = function () {};
                // $scope.$onFilterContent = function (contentList) { return contentList; };
                // $scope.$onAfterContent = function (content) {};
                // $scope.$onContentFailed = function (data) {};
                // $scope.$onContentError = function (error) {};
        
                // $scope.$onBeforeSave = function () {};
                // $scope.$onAfterSave = function (data) {};
                // $scope.$onSaveFailed = function (data) {};
                // $scope.$onSaveError = function (error) {};
                // $scope.$onSaveEx = function (ex) {};
        
                // $scope.$onBeforeLoad = function () {};
                // $scope.$onFilterTelemedicine = function (telemedicineList) { return telemedicineList; };
                // $scope.$onAfterLoad = function (data) {};
                // $scope.$onLoadFailed = function (data) {};
                // $scope.$onLoadError = function (error) {};
        
                // $scope.$onAddressChanged = function (address) {};
                // $scope.$onSaveAndNext = function() {};
            }
        };
    }
})();