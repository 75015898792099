(function () {
    'use strict';

    angular
        .module('natApp')
        .controller('MainController', MainController);

    MainController.$inject = ['$scope', '$rootScope', '$state', '$window', '$timeout', 'utilityService', 'loggingService'];
    /** @ngInject **/
    function MainController($scope, $rootScope, $state, $window, $timeout, utilityService, loggingService) {
        $rootScope.getUIConfig = function (configName, config) {
            var uiConfig = {};
            
            // Get the base configuration name
            var baseConfigName = 'Default';
            if (!!config.UIConfigs[configName] && !!config.UIConfigs[configName].BaseConfig) {
                baseConfigName = config.UIConfigs[configName].BaseConfig;
            }

            // Set the base configuration properties
            if (!!config.UIConfigs[baseConfigName]) {
                for (var prop in config.UIConfigs[baseConfigName]) {
                    if (!config.UIConfigs[baseConfigName].hasOwnProperty(prop)) { continue; }
    
                    uiConfig[prop] = config.UIConfigs[baseConfigName][prop];
                }
            }

            // Set the named configuration properties
            if (!!config.UIConfigs[configName]) {
                for (var prop in config.UIConfigs[configName]) {
                    if (!config.UIConfigs[configName].hasOwnProperty(prop)) { continue; }
    
                    uiConfig[prop] = config.UIConfigs[configName][prop];
                }
            }

            return uiConfig;
        };
        
        $scope.validateData = function (formToValidate) {
            //            if (formToValidate.$valid) {
            if (!(!!formToValidate.errorMessages && formToValidate.errorMessages.length > 0)) {
                return true;
            } else {
                $scope.showValidation = true;
                $scope.validationErrors = formToValidate.errorMessages;
                utilityService.scrollTop();
                return false;
            }
        }

        $scope.hideValidationSummary = function () {
            $scope.showValidation = false;
            $scope.validationErrors = [];
        }

        $scope.showStatusMessage = function (messageContent, messageType) {
            $scope.statusMessage = {
                messageContent: messageContent,
                showMessage: true,
                messageType: messageType
            };
            console.log(messageContent);
        }

        $scope.hideStatusMessage = function () {
            $scope.statusMessage = {
                showMessage: false
            };
        }

        $rootScope.checkServerResponse = function (response) {
            if (response.StatusCode == "04" && response.ResponseReason == null) {
                $scope.showStatusMessage(response.Message, "error");
            } else if (response.StatusCode == "03" && response.ResponseReason == null) {
                sessionStorage.Key = "";
                sessionStorage.URL = $window.location.href;
                ShowLoader(response.Message + "\n" + " Redirecting to Login Page...");
                var urlPath = $window.location.hash;
                $timeout(function () {
                    if (urlPath.includes("Backoffice")) {
                        $state.go('app.BackofficeLoginstaff');
                    } else {
                        $state.go('app.Loginwoemail');
                    }
                }, 3000);
            } else if (response.StatusCode == "02" && response.ResponseReason == null) {
                $scope.showStatusMessage(response.Message, "error");
            } else if (response.StatusCode == "01" && response.ResponseReason != null) {
                $scope.showStatusMessage(JSON.parse(response.ResponseReason)[0].Message, "error");
            } else if (response.StatusCode == "00" && response.ResponseReason == null) {
                return true;
            } else if (response.StatusCode == "00" && response.ResponseReason != null) {
                return true;
            } else {
                return false;
            }
        };

        $rootScope.isUserSessionActive = function () {
            if (sessionStorage.Key == "" || sessionStorage.Key == undefined || sessionStorage.Key == "undefined") {
                return false;
            } else {
                return true;
            }
        };
        
        $scope.parseStringToDate = function (input) {
            if (input != undefined && input != null && input != "") {
                var parts = input.match(/(\d+)/g);
                return new Date(parts[0], parts[1] - 1, parts[2]);
            }
        }
        $rootScope.individualEventLog = function (firsTextType, individualId, secondTextType, isEdit, toReplace, replaceWith, toReplaceTwo, replaceWithTwo, thirdTextType, isView) {
            var logRequest = {
                IndividualId: individualId,
                MasterTransactionId: 1,
                PageModuleId: 1,
                PageModuleTabSubModuleId: 1,
                PageTabSectionId: 1,
                Type: "L",
                CommentLogSource: "WSAPI",
                IsInternalOnly: true,
                IsForInvestigationOnly: true,
                IsForPublic: true,
                IsActive: true,
                NotesTypeId: 1,
                DoNotProcessNotesLog: true
            };
            logRequest.messageType = ((!isEdit) ? firsTextType : secondTextType);
            if (isView == true) {
                logRequest.messageType = thirdTextType;
            }
            logRequest.hashTag = toReplace;
            logRequest.replaceTag = replaceWith;
            logRequest.hashTagTwo = toReplaceTwo;
            logRequest.replaceTagTwo = replaceWithTwo;
            loggingService.logSave(logRequest, ((!!sessionStorage.Key) ? sessionStorage.Key : ''));
        };

        $scope.$on('individualEventLog', function (event, data) {
            $rootScope.individualEventLog(data.txtType, data.individualId);
        });
    }
})();