(function() {
    'use strict'

    angular.module('app.backofficeIndividual')
        .controller('AddressContactsController', ['$rootScope', '$scope', '$q', 'backofficeIndividualAdressService', 'backofficeIndividualPersonalService', 'typeValuesService', 'Messages', AddressContactsController]);

    function AddressContactsController($rootScope, $scope, $q, backofficeIndividualAdressService, backofficeIndividualPersonalService, typeValuesService, Messages) {

        $scope.$on('closeAddressContactsForm', function() {
            $scope.cancelAddress();
        });
        $scope.$on('openAddressContactsForm', function() {
            if (!isLatestOpen()) {
                openLatestAddress();
            }
        });
        $scope.$on('addressStatusChanged', function(event, info) {
            if (!!info.IndividualAddressId) {
                if (!isLatestOpen()) {
                    openLatestAddress();
                }

                if ($scope.current.address.IndividualAddressId == info.IndividualAddressId) {
                    $scope.current.address.AdressStatusId = info.AddressStatusId;
                    $scope.current.address.AddressStatusName = $scope.addressStatuses.whereEquals(info.AddressStatusId, 'AddressStatusId').select('AddressStatusName').firstOrDefault();
                }
            }
        });

        var getNewAddress = function() {
            var typeId = $scope.addressTypeId;

            var indAddressIdToReplace = 0;
            var addressIdToReplace = 0;
            var isMailing = false;
            var isPublic = false;

            var addressToReplace = $scope.addresses.whereEquals(1, 'AdressStatusId').firstOrDefault();
            if (!!addressToReplace && !$scope.alwaysCreateNew) {
                indAddressIdToReplace = addressToReplace.IndividualAddressId;
                addressIdToReplace = addressToReplace.AddressId;
                isMailing = addressToReplace.IsMailingSameasPhysical;
                isPublic = addressToReplace.IsPublicAddress;
            }

            return {
                IndividualAddressId: indAddressIdToReplace,
                AddressId: addressIdToReplace,
                IndividualId: $scope.individualId,
                ApplicationId: (!!$scope.applicationId) ? $scope.applicationId : null,
                AddressTypeId: typeId,
                AdressStatusId: 1,
                StreetLine1: '',
                StreetLine2: '',
                City: '',
                StateCode: 'CA',
                CountryId: $rootScope.DefaultCountryId,
                Zip: '',
                IsMailingSameasPhysical: isMailing,
                IsPublicAddress: isPublic,
                CreatedBy: sessionStorage.UserID,
                CreatedOn: new Date(),

                IsActive: true,
                IsDeleted: false,

                justCreated: true,
                contactsConfig: $scope.contactsConfig
            };
        };

        var getNewContact = function(contactTypeId) {
            var contactToReplace = $scope.allContacts.whereEquals(1, 'ContactStatusId').whereEquals(contactTypeId, 'ContactTypeId').firstOrDefault();

            var indContactIdToReplace = 0;
            var contactIdToReplace = 0;
            if (!!contactToReplace) {
                indContactIdToReplace = contactToReplace.IndividualContactId;
                contactIdToReplace = contactToReplace.ContactId;
            }

            return {
                IndividualContactId: indContactIdToReplace,
                ContactId: contactIdToReplace,
                IndividualId: $scope.individualId,
                ApplicationId: (!!$scope.applicationId) ? $scope.applicationId : null,

                BeginDate: new Date().toJSON(),
                Code: '',
                ContactFirstName: "",
                ContactInfo: "",
                ContactLastName: "",
                ContactMiddleName: "",
                ContactTypeId: contactTypeId,
                DateContactValidated: null,
                EndDate: null,

                IsActive: true,
                IsMobile: false,
                IsPreferredContact: false,
                ContactStatusId: 1,
                CreatedBy: sessionStorage.UserID,
                CreatedOn: new Date(),

                justCreated: true
            };
        };

        $scope.validateAddress = function(address) {
            return $scope.input.validateAddress();
        };

        $scope.cancelAddress = function() {
            $scope.current.address = null;
            $scope.current.originalAddress = null;
        };

        $scope.addEditAddress = function(address) {
            if (!address) {
                address = getNewAddress();
            }
            $scope.contactsConfig.forEach(function(config) {
                if (!address[config.Name]) {
                    address[config.Name] = getNewContact(config.ContactTypeId);
                    address['Original' + config.Name] = angular.copy(address[config.Name]);
                }
            });

            address.ApplicationId = (!!$scope.applicationId) ? $scope.applicationId : null;

            $scope.current.originalAddress = address;
            $scope.current.address = angular.copy(address);
        };

        $scope.saveAddress = function(address) {
            $scope.common.saveChangedAddresses();
        };

        $scope.deleteAddress = function(address) {
            if (confirm(Messages.ecedede)) {
                $scope.common.deleteAddress(address);
            }
        };

        $scope.markAddressPrevious = function(address) {
            if (confirm(Messages.aebdbdf)) {
                $scope.common.markAddressPrevious(address);
            }
        };

        $scope.addressChanged = function(originalAddress, address) {
            updateDisplayContacts(address);

            $scope.common.addressChanged(address);
        };

        $scope.isPublicChanged = function(newPublic) {
            if (!!$scope.publicChanged) {
                $scope.publicChanged({ $address: $scope.current.address });
            }
            $scope.common.publicChanged(newPublic);
        };

        $scope.showPreviousChanged = function(newState) {
            $scope.showPreviousAddresses = newState;

            if (!!$scope.showPreviousAddresses) {
                $scope.displayAddresses = $scope.addresses;
            } else {
                $scope.displayAddresses = $scope.addresses.whereEquals(1, 'AdressStatusId');
            }
        };

        var isLatestOpen = function() {
            if (!!$scope.alwaysCreateNew || (!!$scope.current.address && !!$scope.current.address.justCreated)) { return true; }

            var mostRecent = $scope.addresses.whereEquals(1, 'AdressStatusId').orderByDescending('IndividualAddressId').firstOrDefault();

            return !!$scope.current.address && !!mostRecent && $scope.current.address.IndividualAddressId == mostRecent.IndividualAddressId;
        };

        var openLatestAddress = function() {
            if ($scope.addresses.length > 0 && !$scope.alwaysCreateNew) {
                $scope.addEditAddress($scope.addresses.whereEquals(1, 'AdressStatusId').orderByDescending('IndividualAddressId').firstOrDefault());
            } else if (!$scope.current.address) {
                $scope.addEditAddress(null);
            }
        };

        $scope.setPublic = function(newVal) {
            if (!isLatestOpen()) {
                openLatestAddress();
            }

            $scope.current.address.IsPublicAddress = newVal;
        };

        $scope.isMailingChanged = function(newMailing) {
            if (!!$scope.mailingChanged) {
                $scope.mailingChanged({ $address: $scope.current.address });
            }
            $scope.common.mailingChanged(newMailing);
        };

        $scope.setMailing = function(newVal) {
            if (!isLatestOpen()) {
                openLatestAddress();
            }

            $scope.current.address.IsMailingSameasPhysical = newVal;
        };

        $scope.getCurrentAddress = function() {
            if (!isLatestOpen()) {
                openLatestAddress();
            }

            return $scope.current.address;
        };

        var filterAddresses = function() {
            $scope.addresses = $scope.allAddresses.whereEquals($scope.addressTypeId, 'AddressTypeId').orderByDescending('CreatedOn');

            if (!!$scope.showPreviousAddresses) {
                $scope.displayAddresses = $scope.addresses;
            } else {
                $scope.displayAddresses = $scope.addresses.whereEquals(1, 'AdressStatusId');
            }

            if (!!$scope.addresses) {
                $scope.addresses.forEach(function(addr) {
                    addr.isUpdateOnly = !!$scope.alwaysCreateNew;
                    addr.contactsConfig = $scope.contactsConfig;
                });
            }
        };

        var filterContacts = function() {
            $scope.addresses.forEach(function(address) {
                $scope.contactsConfig.forEach(function(config) {
                    address[config.Name] = $scope.allContacts
                        .whereEquals('' + address.IndividualAddressId, 'ReferenceNumber')
                        .whereEquals(config.ContactTypeId, 'ContactTypeId')
                        .firstOrDefault();

                    if (!address[config.Name]) {
                        address[config.Name] = getNewContact(config.ContactTypeId);
                    }

                    address['Original' + config.Name] = angular.copy(address[config.Name]);
                });
            });
        };

        var updateDisplayContacts = function(address) {
            address.contactsConfig.forEach(function(config) {
                if (!!config.TableDisplay && !!address[config.Name]) {
                    address[config.TableDisplay] = address[config.Name].ContactInfo;
                }
            });
        };

        var generateEntireAddress = function(address) {
            address.entireAddress = '{0} {1} {2}, {3}, {4} {5}'.format((!!address.AttentionTo) ? 'Attn: ' + address.AttentionTo : '', address.StreetLine1, address.StreetLine2, address.City, address.StateCode, address.Zip);
            if (address.entireAddress) {
                address.entireAddress = address.entireAddress.trim();
                address.entireAddress = address.entireAddress.replace(/(\s)+/g, ' ');
                address.entireAddress = address.entireAddress.replace(/\s,/g, ',');
            }
        };

        $scope.hasConfig = function() {
            return !!$scope.config;
        };

        $scope.setConfig = function(config) {
            $scope.config = config;

            $scope.addressTypeId = config.addressTypeMap[$scope.addressConfig];
            $scope.contactsConfig = config.addressTypeContactLinks[$scope.addressConfig];

            if (!$scope.contactsConfig) {
                $scope.contactsConfig = [];
            }
            if (!!$scope.tableHeaderConfig) {
                $scope.tableHeaders = angular.copy(config.addressTableHeaders[$scope.tableHeaderConfig])
            }
        };

        $scope.addressesSaved = function() {
            $scope.logDataChanges();
        };

        $scope.updateAddressContacts = function(allAddresses, allContacts, addressTypes) {
            $scope.current.address = null;

            $scope.isConfigLoaded = true;

            $scope.allAddresses = allAddresses;
            $scope.allAddresses.forEach(function(addr) {
                addr.AddressStatusName = $scope.addressStatuses.whereEquals(addr.AdressStatusId, 'AddressStatusId').select('AddressStatusName').firstOrDefault();
            });
            $scope.allAddresses.forEach(generateEntireAddress);
            filterAddresses();

            $scope.allContacts = allContacts;
            filterContacts();

            $scope.addressTypes = addressTypes;
            $scope.addressType = addressTypes.whereEquals($scope.addressTypeId, 'AddressTypeId').firstOrDefault();

            $scope.addresses.forEach(function(address) {
                updateDisplayContacts(address);
            });

            openLatestAddress();
        };

        $scope.init = function() {
            $scope.current = {
                address: null,
                originalAddress: null
            };
            $scope.input = {};
            $scope.tableHeaders = {};

            $scope.addressStatuses = [{
                    AddressStatusId: 1,
                    AddressStatusName: 'Current'
                },
                {
                    AddressStatusId: 2,
                    AddressStatusName: 'Previous'
                },
                {
                    AddressStatusId: 3,
                    AddressStatusName: 'Other'
                }
            ];

            if (!$scope.tableHeaderConfig) {
                $scope.tableHeaders = {
                    AddressStatusName: {
                        label: 'Status'
                    },
                    entireAddress: {
                        label: "Address",
                        sizeRatio: 3
                    },
                    DisplayPhone: {
                        label: 'Phone'
                    },
                    DisplayEmail: {
                        label: 'Email'
                    },
                    Actions: {
                        label: 'Actions',
                        templateUrl: 'addressActionsTemplate.html',
                        sizeRatio: 1
                    }
                };
            }

            if (typeof($scope.isReadOnly) == 'undefined') {

            }
            if (typeof($scope.isRequired) == 'undefined') {
                $scope.isRequired = false;
            }
            if (typeof($scope.showPublicMailing) == 'undefined') {
                $scope.showPublicMailing = true;
            }
            if (typeof($scope.showTable) == 'undefined') {
                $scope.showTable = true;
            }
            if (typeof($scope.showPreviousDefault) == 'undefined') {
                $scope.showPreviousAddresses = true;
            }

            if (!$scope.addressOptions) {
                $scope.addressOptions = {
                    includeCounty: true,
                    includeCountry: true,
                    showPublicMailing: $scope.showPublicMailing
                };
            }

            if (!$scope.additionalFields) {
                $scope.additionalFields = [];
            }

            $scope.additionalFieldParams = {};
            $scope.additionalFields.forEach(function(param) {
                $scope.additionalFieldParams[param.property] = {};
            });

            $scope.addressTableEvents = {
                addEditAddress: $scope.addEditAddress,
                deleteAddress: $scope.deleteAddress,
                markAddressPrevious: $scope.markAddressPrevious,
                canDelete: $scope.canDelete,
                isReadOnly: $scope.isReadOnly,
                isRequired: $scope.isRequired,
                showPreviousChanged: $scope.showPreviousChanged,
                showPreviousAddresses: $scope.showPreviousAddresses
            };
            $scope.addresses = [];
            $scope.displayAddresses = [];

            $scope.childName = 'address' + $scope.addressTypeId;
        };

        $scope.$watch('isRequired', function(newVal) {
            $scope.addressTableEvents.isRequired = $scope.isRequired;
        });
        $scope.$watch('isReadOnly', function(newVal, oldVal) {
            $scope.addressTableEvents.isReadOnly = $scope.isReadOnly;

            if (!isLatestOpen() && $scope.isConfigLoaded) {
                openLatestAddress();
            }
        });
        $scope.$watch('canDelete', function() {
            $scope.addressTableEvents.canDelete = $scope.canDelete;
        });
    }
})();