(function () {
    'use strict';
    angular.module('app.backofficeAdministor')
        .controller("BulkEmailController", BulkEmailController)
    BulkEmailController.$inject = ['$scope', 'BulkEmailFactory', 'utilityService', 'typeValuesService', 'TypeValue'];
    function BulkEmailController($scope, BulkEmailFactory, utilityService, typeValuesService, TypeValue) {
        var _key = sessionStorage.Key;
        var _userId = sessionStorage.UserID;
        $scope.init = function () {
            $scope.netValidator = {};
            $scope.currentPage = 1;
            $scope.totalItems = 0;
            $scope.itemsPerPage = 50;
            $scope.maxSize = 6;
            $scope.SearchData = {};
            $scope.FromEmail = 'info@inlumon.com';
            ConfigurationGetbySettings('EmailFrom');
            //$scope.AuditVisitInfoSave();
            IndividualSearchBulkEmailWithPage();
            licenseStatusTypeGetAll();
            LicenseTypeGetAll();
        };

        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/d!/yyyy',
            startDateOptions: {
                minDate: null,
            },
            endDateOptions: {}
        };

        var IndividualSearchBulkEmailWithPage = function () {
            $scope.SearchData.ContactTypeId = 8;
            $scope.SearchData.SortColumn = 'IndividualId';
            $scope.SearchData.SortOrder = 'DESC';
            $scope.IndividualList = [];
            $scope.AllItemSelected = false;
            ShowLoader()
            BulkEmailFactory.IndividualSearchBulkEmailWithPage(_key, $scope.currentPage, $scope.itemsPerPage, $scope.SearchData).then(function (res) {
                if (res.data.Status) {
                    $scope.totalItems = res.data.Total_Recard;
                    $scope.IndividualList = res.data.IndividualList;
                }
                HideLoader()
            }, function () {
                HideLoader()
            });
        };

        $scope.AllItemSelectedChane = function () {
            if ($scope.IndividualList) {
                $scope.IndividualList.map(function (i) {
                    i.isSelected = $scope.AllItemSelected;
                    return i;
                });
            }
        };

        $scope.ItemSelected = function () {
            //check is all selected
            if ($scope.IndividualList) {
                for (var i = 0; i < $scope.IndividualList.length; i++) {
                    if (!$scope.IndividualList[i].isSelected) {
                        $scope.AllItemSelected = false;
                        return;
                    }
                }
                $scope.AllItemSelected = true;
            }
        };

        $scope.SendEmail = function (form) {

            if (!validateForm(form)) {
                window.scrollTo(0, 0);
                return;
            }
            var selectedEmail = getSelectedEmails();
            if (selectedEmail && selectedEmail.length > 0) {
                ShowLoader()
                var EmailCommunication = {
                    EmailFrom: $scope.FromEmail,
                    Subject: $scope.EmailSubject,
                    CommunicationText: $scope.EmailText,
                    CommunicationSource: 'Backoffice Bulk Email',
                    Type: 'Bulk Email',
                    BulkEmail: selectedEmail,
                    CreatedBy: _userId,
                    IsActive: true,
                    IsInternalOnly: false,
                    CommunicationLogDate: new Date(),
                    EffectiveDate: new Date(),
                };
                BulkEmailFactory.IndividualCommunicationSave(_key, EmailCommunication).then(function (res) {
                    $scope.EmailSentSuccess = "Email sent successfully."
                    window.scrollTo(0, 0);
                    HideLoader()
                    clearMessage();
                }, function () {
                    HideLoader()
                });
            } else {
                window.scrollTo(0, 0);
                validateForm(null, "No associated email found or you have not selected any individual, please select the Individual(s) to send Email.");
            }
        };

        var clearMessage = function () {
            setTimeout(function () {
                $scope.$apply(function () {
                    $scope.EmailSentSuccess = undefined;
                });
            }, 5000);
        };

        $scope.ClearSaveSection = function (form) {
            $scope.EmailSubject = '';
            $scope.EmailText = '';
            form.$submitted = false;
            form.$setPristine();
            $scope.netValidator = {};
        };

        var ConfigurationGetbySettings = function (sett) {
            ShowLoader()
            BulkEmailFactory.ConfigurationGetbySettings(_key, sett).then(function (res) {
                if (res && res.data.Status && res.data.ConfigurationList && res.data.ConfigurationList.length > 0) {
                    var setVal = res.data.ConfigurationList[0].Value;
                    if (setVal && setVal != null) {
                        $scope.FromEmail = setVal;
                    }
                }
                HideLoader()
            }, function () {
                HideLoader()
            });
        };

        var getSelectedEmails = function () {
            var items = [];
            if ($scope.IndividualList) {
                $scope.IndividualList.map(function (i) {
                    if (i.isSelected && i.Email && i.Email.length > 4) {
                        items.splice(0, 0, { EmailId: i.Email, IndividualId: i.IndividualId });
                    }
                    return i;
                });
            }
            return items;
        };

        $scope.AuditVisitInfoSave = function () {
            var data = {
                "PageName": "BulkEmailPage",
                "SessionId": sessionStorage.Key,
                "IndividualId": sessionStorage.IndividualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };

        // Get LicenseStatus
        var licenseStatusTypeGetAll = function () {
            typeValuesService.getTypeValuesByTypeName(TypeValue.LicenseStatus, _key)
                .then(function (response) {
                    if (response.Status) {
                        $scope.LicenseStatusList = response.LicenseStatusTypeGetList;
                    }
                }, function (data) {
                });
        };

        //get LicenseType List
        var LicenseTypeGetAll = function () {
            BulkEmailFactory.LicenseTypeGetAll(_key).then(function (res) {
                if (res && res.data.Status) {
                    $scope.LicenseTypeList = res.data.LicenseTypeGetList;
                }
            }, function () {
            });
        };

        $scope.btnSearchClicked = function () {
            //make pageIndex 0
            $scope.currentPage = 1;
            IndividualSearchBulkEmailWithPage();
        };

        $scope.ClearSearch = function () {
            $scope.SearchData = {};
            $scope.currentPage = 1;
            IndividualSearchBulkEmailWithPage();
        };

        $scope.pageChanged = function () {
            //console.log('test',$scope.currentPage);
            IndividualSearchBulkEmailWithPage()
        };

        var validateForm = function (form, error) {
            $scope.netValidator.errorMessages = [];
            if (!!form) {
                form.$submitted = true;
                var errorMessages = angular.copy(form.errorMessages);
                angular.forEach(errorMessages, function (errorMessage, index) {
                    $scope.netValidator.errorMessages.push(errorMessage);
                });
            }
            if (error && error != '') {
                $scope.netValidator.errorMessages.push({ errorMessage: error });
            }
            if (!!$scope.netValidator.errorMessages && $scope.netValidator.errorMessages.length > 0) {
                $scope.netValidator.showError = true;
                $scope.netValidator.isValid = false;
                return false;
            } else {
                $scope.netValidator.showError = false;
                $scope.netValidator.isValid = true;
                return true;
            }
        };

    };

})();