(function() {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('supervisorInformationValidation', [SupervisorInformationValidation]);

    function SupervisorInformationValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.validateSupervisorInfo = function (supervisorInfo) {
                    var valid = true;
                    var errMsgs = [];
                    var supervisorType = $scope.getSupervisorTypeName().toLowerCase();

                    if (!supervisorInfo.SupervisorIndividualId) {
                        valid = false;
                        errMsgs.push('You must find a supervisor.');
                    }

                    if (!supervisorInfo.SupervisedIndividualId) {
                        valid = false;
                        errMsgs.push('You must find a supervisee.');
                    }

                    if (!!$scope.current.supervisor && !!$scope.current.supervisee && $scope.current.supervisor.IndividualId == $scope.current.supervisee.IndividualId) {
                        valid = false;
                        errMsgs.push('You cannot supervise yourself.');
                    }

                    if (!supervisorInfo.Description) {
                        valid = false;
                        errMsgs.push('You must describe your duties.');
                    }

                    // if (!supervisorInfo.SupervisedWorkAddressId) {
                    //     valid = false;
                    //     errMsgs.push('You must select an address from which the supervisee will perform their duties.');
                    // }

                    // if (!supervisorInfo.SupervisorWorkAddressId && $scope.current.manualSupervisorAddress.isAddressEmpty) {
                    //     valid = false;
                    //     errMsgs.push('You must select an address for the supervisor.');
                    // }

                    if (![1].includes($scope.individualLicense.LicenseTypeId)) {
                        if (!supervisorInfo.DateBegin) {
                            valid = false;
                            errMsgs.push('You must choose a date from which the supervision will take effect.');
                        }
                        if (!supervisorInfo.SupervisedSubTypeId) {
                            valid = false;
                            errMsgs.push('You must select a supervision type.');
                        }
                        if (!supervisorInfo.SupervisedStatusId) {
                            valid = false;
                            errMsgs.push('You must select a supervision status.');
                        }
                    }                    

                    $scope.showStatusMsg('-', errMsgs);

                    return valid;
                };

                $scope.validateForm = function () {
                    return true;
                };
            }
        };
    }
})();