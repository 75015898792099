(function () {
    'use strict'

    angular.module('app.core')
    .directive('waitForAll', [WaitForAllDirective]);

    function WaitForAllDirective() {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs, ctrl) {
                ctrl.linked = true;
                ctrl.beginCalls(element, attrs);
            },
            controller: ['$q', '$parse', function ($q, $parse) {
                var waitChildren = [];
                var self = this;

                self.linked = false;

                self.registerWaitChild = function (waitChild) {
                    if (!self.linked) {
                        //If it hasn't yet been linked, add waitChild to the children to all be processed at once
                        waitChildren.push(waitChild);
                    } else {
                        //If it has already been linked, run it immediately
                        if (!waitChild.getData) {
                            return;
                        }

                        waitChild.getData()
                        .then(function (response) {
                            if (!!waitChild.complete) {
                                waitChild.complete(response);
                            }
                        }, function (response) {
                            if (!!waitChild.failure) {
                                waitChild.failure(response);
                            }
                        });
                    }
                };

                self.beginCalls = function (element, attrs) {
                    var promises = [];
                    var promisesRetrieved = function () {};
                    var onLoaded = function () {};
                    var onFailed = function () {};
                    var effectiveScope = null;

                    if (!!attrs.promisesRetrieved) {
                        promisesRetrieved = $parse(attrs.promisesRetrieved);
                    }
                    if (!!attrs.onLoaded) {
                        onLoaded = $parse(attrs.onLoaded);
                    }
                    if (!!attrs.onFailed) {
                        onFailed = $parse(attrs.onFailed);
                    }
                    

                    if (!!attrs.lookFor) {
                        effectiveScope = element.closest(attrs.lookFor).scope();
                    } else {
                        effectiveScope = element.scope();
                    }

                    waitChildren.forEach(function (waitChild) {
                        if (!!waitChild.getData) {
                            promises.push(waitChild.getData());
                        }
                    });

                    promisesRetrieved(effectiveScope, { promises: promises });

                    $q.all(promises)
                    .then (function (response) {
                        waitChildren.forEach(function (waitChild, i) {
                            if (!!waitChild.complete) {
                                waitChild.complete((response[i]) ? response[i] : response);
                            }
                        });
                        onLoaded(effectiveScope);
                        waitChildren = [];
                    }, function (response) {
                        waitChildren.forEach(function (waitChild, i) {
                            if (!!waitChild.failure) {
                                waitChild.failure((response[i]) ? response[i] : response);
                            }
                        });
                        onFailed(effectiveScope);
                        waitChildren = [];
                    });
                };
            }]
        };
    }
})();