(function () {
    'use strict'

    angular.module('natApp')
        .factory('BusinessEntitytatusService', BusinessEntitytatusService)

    BusinessEntitytatusService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig', 'initialApplicationstatusService']

    function BusinessEntitytatusService($http, $q, WebApiUrl, AppConfig, initialApplicationstatusService) {

        var PaymetStatus = '';
        var IsPayment = false;
        var AllTabsStatus = false;
        var AllTabsProcessed = false;
        var BEInformation = '';
        var AdditionalWyoming = '';
        var BEDocument = '';
        var BEResponsibleIncharge = '';

        var _saveApplicationStatus = function (ApplicationId, key, applicationStatus) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Common/ApplicationStatusUpdate/' + key + '?ApplicationId=' + ApplicationId + '&ApplicationStatusId=' + applicationStatus + '&Source=' + AppConfig.Source)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveApplicationStatusLk = function (key, userId, reqObj) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Common/ApplicationStatusLkSave/' + key + '?UserId=' + userId, reqObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveApplicationStatusChangeLog = function (key, userId, reqObj) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Common/ApplicationStatusChangeLogSave/' + key + '?UserId=' + userId, reqObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _licenseStatusUpdate = function (key, reqObj) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/LicenseStatusUpdate/' + key, reqObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _setPaymetStatus = function (val) {
            // initialApplicationstatusService.setPaymetStatus = val;
            PaymetStatus = val;
        };
        var _getPaymetStatus = function () {

            return PaymetStatus;//initialApplicationstatusService.setPaymetStatus;;
        };
        var _setBEInformation = function (val) {
            BEInformation = val;
        };
        var _setAdditionalWyoming = function (val) {
            AdditionalWyoming = val;
        };
        var _setBEDocument = function (val) {
            BEDocument = val;
        };
        var _setBEResponsibleIncharge = function (val) {
            BEResponsibleIncharge = val
        };
        var _resetAll = function (isPayment) {
            PaymetStatus = 'waiting';
            IsPayment = false;
            AllTabsStatus = false;
            AllTabsProcessed = false;
            BEInformation = '';
            AdditionalWyoming = '';
            BEDocument = '';
            BEResponsibleIncharge = '';
        };
        var _getAllTabsProcessedStatus = function () {
            AllTabsProcessed = setAllTabProcessStatus();
            return AllTabsProcessed;
        };
        var _getAllTabStatus = function () {
            AllTabsStatus = setAllTabStatus();
            return AllTabsStatus;
        };
        var setAllTabStatus = function () {

            // if (BEInformation == "success" || AdditionalWyoming == "success" && BEDocument == "success" && BEResponsibleIncharge == "success") {
            //     return true;
            // }
            // if (BEInformation == "success" && BEDocument == "success") {
            //     return true;
            // }
            // if (BEInformation == "success" && (!AdditionalWyoming && !BEDocument && !BEResponsibleIncharge)) {
            //     return true;
            // }
            // else {
            return false;
            // };
        };

        var setAllTabProcessStatus = function () {

            if (!(BEInformation == "invalid" || BEInformation == "success" || BEInformation == "error")) {
                return false;
            }
            // if (BEInformation && (!AdditionalWyoming && !BEDocument && !BEResponsibleIncharge)) {
            //     return true;
            // }
            // if (!(AdditionalWyoming == "invalid" || AdditionalWyoming == "success" || AdditionalWyoming == "error")) {
            //     return false;
            // }
            // if (!(BEDocument == "invalid" || BEDocument == "success" || BEDocument == "error")) {
            //     return false;
            // }
            // if (!(BEResponsibleIncharge == "invalid" || BEResponsibleIncharge == "success" || BEResponsibleIncharge == "error")) {
            //     return false;
            // }
            return true;
        };

        return {
            saveApplicationStatus: _saveApplicationStatus,
            saveApplicationStatusLk: _saveApplicationStatusLk,
            saveApplicationStatusChangeLog: _saveApplicationStatusChangeLog,
            licenseStatusUpdate: _licenseStatusUpdate,
            getAllTabStatus: _getAllTabStatus,
            getAllTabsProcessedStatus: _getAllTabsProcessedStatus,
            resetAll: _resetAll,
            setPaymetStatus: _setPaymetStatus,
            getPaymetStatus: _getPaymetStatus,
            setBEInformation: _setBEInformation,
            setAdditionalWyoming: _setAdditionalWyoming,
            setBEDocument: _setBEDocument,
            setBEResponsibleIncharge: _setBEResponsibleIncharge,
        };
    }
})();