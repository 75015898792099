(function () {
    'use strict'
    angular.module('app.report')
        .controller('ApprovedCoursesReportController', ApprovedCoursesReportController);

    ApprovedCoursesReportController.$inject = ['$scope', '$q', '$controller', '$filter',
        'reportService', '$state', 'utilityService', 'ApiUrlsForReports', 'ApiUrlsForReportsXls'
    ];

    function ApprovedCoursesReportController($scope, $q, $controller, $filter, reportService, $state, utilityService, ApiUrlsForReports, ApiUrlsForReportsXls) {
        $controller('GlobalController', { $scope: $scope })

        $scope.dateOptions = { format: 'MM/DD/YYYY' };

        $scope.getExpireDate = function(){
            $scope.CurrentDate = new Date();
            var year = $scope.CurrentDate.getFullYear();
            var month = $scope.CurrentDate.getMonth();
            var day = $scope.CurrentDate.getDate();
            $scope.FromDate = new Date(year-3, month, day);
        }

        $scope.getExpireDate();

        // Pagination
        $scope.Pager = {
            sortType: 'ExpirationDate',
            sortOrder: 'desc',
            currentPage: 1,
            totalRecords: 10,
            pageSize: 20,
            maxSize: 5,
            FromDate: null,
            ToDate: null,
            CourseTypeId: 1
        };
    

        $scope.Headers = [
            { name: 'Course Name', sortType: 'CourseName', sortOrder: 'asc' },
            { name: 'Credit Hours', sortType: 'CreditHours', sortOrder: 'asc' },
            { name: 'Expiration Date', sortType: 'ExpirationDate', sortOrder: 'asc' },
            { name: 'Provider/Sponsor', sortType: 'ProviderSponsor', sortOrder: 'asc' },
            { name: 'Phone', sortType: 'Phone', sortOrder: 'asc' },
            { name: 'Instructor Name', sortType: 'InstructorName', sortOrder: 'asc' },
        ];
        $scope.getData = function () {
            try {
                $scope.Pager.FromDate = $scope.FromDate;

                if ($scope.isSessionActive()) {
                    hideStatusMessage();
                    ShowLoader('Please Wait...');

                    $scope.showListing = true; 

                    reportService.approvedCoursesReportWithPager(sessionStorage.Key, $scope.Pager, ApiUrlsForReports.approvedCourses)
                        .then(function (response) {
                            if ($scope.checkResponse(response)) {
                                $scope.Pager.totalRecords = response.PagerVM.TotalRecords
                                $scope.DataList = response.PagerVM.Records
                                angular.forEach($scope.DataList, function(data) {
                                    if(data.ExpirationDate != null)data.ExpirationDate = new Date( data.ExpirationDate);
                                });
                            }
                            HideLoader()
                            utilityService.scrollTop()
                        }, function (data) {
                            HideLoader()
                            showStatusMessage(data, 'error')
                        })  
                        
                } else {
                    $state.go('app.Loginwoemail')
                }
            } catch (ex) {
                HideLoader()
                if (ex != null) { showStatusMessage(ex.message, 'error'); }
            }
        };

        $scope.SortRecords = function (header, pager) {
            pager.currentPage = 1;
            pager.sortType = header.sortType;
            pager.sortOrder = header.sortOrder;
            header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
            $scope.getData();
        };
        $scope.resetReport = function () {
            $scope.CECourseTypeId = null;
        }

        $scope.getExcelForReport = function (reportType) {
            try {
                ShowLoader('Please Wait...')
                $scope.Pager.ReportType = reportType;
                reportService.getXLSForApprovedCoursesReport(sessionStorage.Key, $scope.Pager, ApiUrlsForReportsXls.approvedCourses)
                    .then(function (response) {
                        HideLoader()
                    }, function (data) {
                        HideLoader()
                        showStatusMessage(data, 'error')
                    })
            } catch (ex) {
                HideLoader()
                if (ex != null) { showStatusMessage(ex.message, 'error'); }
            }
        }
    };
})();