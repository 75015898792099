﻿//call this EmailDirective.js after angular.js 
//include 'emailer' module into your existing module whenever required
//write following line into html
// <emailer value="'Compose'"></emailer>
(function () {
    angular.module('app.core')
    .config(config)
    .directive('emailer', ['$http', 'Messages' , function ($http, Messages) {
        return {
            require: '^ngController',
            restrict: 'E',
            //replace: true,
            scope: {
                'closedive': '&',
                'onmailsendsuccess': '&',
                'IndividualId': '=individualid',
                'ApplicationId': '=applicationid',
                'ProviderId': '=providerid',
                'Type': '=type',
                'UserIdFrom': '=useridfrom',
                'IndividualIdFrom': '=individualidfrom',
                'ProviderIdFrom': '=provideridfrom',
                'IsInternalOnly': '=isinternalonly',
                'IsForInvestigationOnly': '=isforinvestigationonly',
                'IsForPublic': '=isforpublic',
                'ToCCBCC': '=toccbcc',
                'UserIdTo': '=useridto',
                'IndividualIdTo': '=individualidto',
                'ProviderIdTo': '=provideridto',
                'EmailFrom': '=emailfrom',
                'EmailTo': '=emailto',
                'Subject': '=subject',
                'InternalSubject': '=internalsubject',
                'SecureCommunicationText': '=securecommunicationtext',
                'key': '=key',
                'BaseURL': '=baseurl',
                'GovernmentAgencyId': '=governmentagencyid',
                'GovernmentAgencyAddressContactId': '=governmentagencyaddresscontactid',
                'GovernmentAgencyAddressId': '=governmentagencyaddressid',
                'EmailFor': '=emailfor',
                'SendEmail': '=sendemail',
                'CallbackItem': '=callbackitem',
                'emailerconfig': '=emailerconfig'
            },
            template: '<form  name="emailer">' +                         
                         '<table style="margin: 20px auto;" class="tblAssign form-table">' +
                         '<tbody><tr><td align="right" style="width: 85px;">' +
                         '<label class="input-label required">From : </label></td>' +
                         '<td colspan="3"><input type="text" ng-disabled="{{emailerconfig.DisableFromEmail}}" style="width: 406px !important;" class="inputTextbox  calendarMask EmailFromTextBox"  maxlength="150" name="From" ng-model="EmailFrom" required ><br /><span style="color:red" ng-show="(emailer.From.$dirty && emailer.From.$invalid)||(submitted && emailer.From.$invalid)"> From is required field.</span> </td>' +
                         //'<td align="left" colspan="2"><input type="checkbox" ng-model="ToCCBCC" ng-true-value="\'CC\'" ng-false-value="">Email a Copy to sender?</td>' +
                         '</tr>' +
                         '<tr><td align="right"><label class="input-label required">To :</label></td>' +
                         '<td colspan="3"><input type="text" style="width: 406px !important;" class="inputTextbox  calendarMask EmailToTextBox"  maxlength="150"  name="To" ng-model="EmailTo" required ><br /><span style="color:red" ng-show="(emailer.To.$dirty && emailer.To.$invalid)||(submitted && emailer.To.$invalid)"> To is required field.</span></td>' +
                         //'<td align="left" colspan="2"></td>'+
                         '</tr><tr><td align="right">' +
                         '<label class="input-label required">Subject :</label></td>' +
                         '<td colspan="3"><input type="text" style="width: 406px !important;" class="inputTextbox  calendarMask"  maxlength="150" name="Subject"  ng-model="Subject"  required><br /><span style="color:red" ng-show="(emailer.Subject.$dirty && emailer.Subject.$invalid)||(submitted && emailer.Subject.$invalid)"> Subject is required field.</span></td>' +
                         '</tr><tr><td align="right" style="width:106px;"><label class="input-label required">Internal Subject :</label></td>' +
                         '<td colspan="3"><input type="text" style="width: 406px !important;" class="inputTextbox  calendarMask"  maxlength="150" name="InternalSubject" ng-model="InternalSubject"  required><br /><span style="color:red" ng-show="(emailer.InternalSubject.$dirty && emailer.InternalSubject.$invalid)||(submitted && emailer.InternalSubject.$invalid)"> Internal Subject is required field.</span></td>' +
                         '</tr><tr><td colspan="4"><div style="width: 100% !important" class="posRelative"><h4 class="input-label required">Message :<span style="color:red">*</span></h4>' +
                         '<div class="emaileditorstyle" cols="20" rows="6" name="Email" text-angular="text-angular" placeholder="Please enter your message here..."  required ng-model="SecureCommunicationText" ></div>' +
                         //'<textarea cols="20" rows="6" style="width:91%"   name="Email" ng-model="SecureCommunicationText" placeholder="Please enter your message here..."  required></textarea>' +
                         '</div>' +
                         '<span style="color:red" ng-show="(emailer.Email.$dirty && emailer.Email.$invalid)||(submitted && emailer.Email.$invalid)"> Message is required field.</span></td></tr></tbody></table>' +
                         '<table style="width: 100%;"><tbody><tr><td colspan="4"><div class="">' +
                         '<span class="fltrt"> <input type="button" class="secondary medium buttonalignmentDefee pull-right" href ng-click="close($event)" value="Close">' +
                         '<input type="submit" class="buttonGreen medium pull-right" style="margin-right:10px" value="Send" href ng-click="submitted=true ; !emailer.$invalid && send()" >' +
                         '</span></div></td></tr></tbody></table></form>'
                        ,
            link: function (scope, element, attrs, parentCtrl) {
                scope.send = function () {


                    var data = {};
                    var apiUrl = '';
                    if (scope.EmailFor != null && scope.EmailFor == "Individual") {
                        data = {
                            IndividualId: scope.IndividualId,
                            ApplicationId: scope.ApplicationId,
                            Type: scope.Type,
                            EmailFrom: scope.EmailFrom,
                            Subject: scope.Subject,
                            CommunicationSource: ((scope.emailerconfig && scope.emailerconfig.CommunicationSource) ? scope.emailerconfig.CommunicationSource : ''),
                            CommunicationText: scope.SecureCommunicationText,
                            CommunicationStatus: 'S',
                            ReferenceNumber: '',
                            IsInternalOnly: scope.IsInternalOnly,
                            IsForInvestigationOnly: scope.IsForInvestigationOnly,
                            IsForPublic: scope.IsForPublic,
                            IsActive: true,
                            IsDeleted: false,
                            EmailsTo: scope.EmailTo,
                            EmailaCopyToSender: false,
                            SendEmail: true
                        };
                        apiUrl = 'Log/CommunicationLogSave/';
                    }
                    else {
                        apiUrl = 'Individual/SaveCommunicationLog/';
                        data = {
                            IndividualId: scope.IndividualId, ApplicationId: scope.ApplicationId, ProviderId: scope.ProviderId, Type: scope.Type,
                            UserIdFrom: scope.UserIdFrom, IndividualIdFrom: scope.IndividualIdFrom, ProviderIdFrom: scope.ProviderIdFrom,
                            IsInternalOnly: scope.IsInternalOnly, IsForInvestigationOnly: scope.IsForInvestigationOnly, IsForPublic: scope.IsForPublic,
                            ToCCBCC: scope.ToCCBCC, UserIdTo: scope.UserIdTo, IndividualIdTo: scope.IndividualIdTo, ProviderIdTo: scope.ProviderIdTo,
                            EmailFrom: scope.EmailFrom, EmailTo: scope.EmailTo, Subject: scope.Subject, InternalSubject: scope.InternalSubject,
                            SecureCommunicationText: scope.SecureCommunicationText,
                            CommunicationToLogList: '',
                            ReferenceNumber: '',
                            CommunicationSource: '',
                            SendEmail: true
                        };
                    }
                    scope.reqData = data;
                    scope.loader = true;
                    ShowLoader();

                    $http({
                        method: "Post",
                        url: scope.BaseURL + apiUrl + sessionStorage.Key,
                        data: data
                    }).then(function(res){ res = res.data;
                        HideLoader();
                        console.log(scope.reqData, 'req');
                        scope.onmailsendsuccess(scope.reqData, scope.CallbackItem);
                        scope.closedive({}, scope.CallbackItem);
                        scope.SecureCommunicationText = "";
                        if (scope.EmailFor != null && scope.EmailFor == "Individual") {
                            alert(Messages.caacefc);
                        } else {
                            alert(res.Message);
                        }
                    }, function(data) { data = data.data;
                        HideLoader();
                        alert(Messages.ddccdba);
                    }).finally(function (data) {
                        scope.submitted = false;
                        scope.emailer.$setPristine();
                        scope.loader = false;
                    });
                }
                scope.close = function ($event) {
                    scope.closedive({ event: $event }, scope.CallbackItem);
                }

            }
        };
    }]);
    config.$inject = [ '$provide'];
    function config($provide) {
        $provide.decorator('taOptions', ['$delegate', function (taOptions) {
            taOptions.toolbar = [
            ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote'],
            ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
            ['html', 'insertLink']
            ];
            return taOptions;
        }]);
    };
}).call(this);