(function () {
    'use strict'

    angular.module('app.backofficeEntity')
        .directive('entityLog', entityLogDirective);

    entityLogDirective.$inject = [];

    function entityLogDirective() {
        return {
            restrict: 'E',
            scope: {
                entityId: '=',
                tab: "=",
                entityType: "=",
                boardInfoId: "=?"
            },
            templateUrl: 'app/components/backoffice/entity/log/log.html',
            controller: 'EntityLogController'
        };
    }
})();