(function() {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationNcessRecord', initialApplicationNcessRecordDirective);

    initialApplicationNcessRecordDirective.$inject = [];

    function initialApplicationNcessRecordDirective() {
        return {
            restrict: 'E',
            scope: {
                personalInfo: "=personalInfo",
                personalAddress: "=personalAddress",
                personalContact: "=personalContact",
                personalData: "=?personalData",
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                individualId: '=individualId',
                applicationId: '=applicationId',
                isRenewal: '=?isRenewal',
                loadData: '=?loadData',
                isNameChange: "=?isNameChange",
                natValidation: "=natValidation",
                ncess: "=?ncess",
                showStatusMessage: "&",
                tab: "=tab",
                openNextTab: "&",
                chnageNcees: "&",
                individualLicense: "=?individualLicense",
                hidePersonalInfo: "=hidePersonalInfo",
                individualEmployment: "=?individualEmployment",
                applicationInfo: "=?applicationInfo",
                isBackoffice: "=isBackoffice",
                dashboard: "=dashboard",
            },
            templateUrl: function(elements, attrs) {
                return "app/components/individual/application/directives/ncees-record/nceess-record.html";
            },
            controller: "InitialApplicationNceessController"
        }
    }
})();