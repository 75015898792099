(function() {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('providerApplicationAcknowledgement', providerApplicationAcknowledgement);

    providerApplicationAcknowledgement.$inject = [];

    function providerApplicationAcknowledgement() {
        return {
            restrict: 'E',
            scope: {
                acknowledgement: "=acknowledgement",
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                UserId: "=userId",
                headingPosition: "=headingPosition",
                isBackoffice: "=isBackoffice",
                isApprentice: "=isApprentice",
                natValidation: "=natValidation",
                individualId: '=individualId',
                applicationId: '=applicationId',
                isRenewal: '=?isRenewal',
                loadData: '=?loadData',
                providerLicense: "=providerLicense",
                showStatusMessage: "&",
                tab: "=tab",
                openNextTab: "&",
                isTabStructure: "=?isTabStructure",
                applicationInfo: "=?applicationInfo",
                isIndividualInfo: "=?isIndividualInfo",
                individualDetail: "=?individualDetail",
                showCancelButton: "=?showCancelButton"
            },
            templateUrl: "app/components/provider/application/directives/provider-acknowledgement/provider-acknowledgement.html",
            controller: "ProviderApplicationAcknowledgementController"
        }
    }
})();