(function () {
  'use strict'

  angular.module('app.report')
    .factory('reportService', ReportService)
    .service('ReportDecorators', [function () {
        this.Obsolete = function (fn) {
            if(typeof(fn) !== 'function') {
                throw { message: 'Invalid parameter: not a function. ' };
            }
            var obsolete = function() {
                console.log("Function is obsolete: " + fn.name);
                return fn.apply(null, arguments);
            };

            return obsolete;
        };

        this.Deprecated = function (fn) {
            if(typeof(fn) !== 'function') {
                throw { message: 'Invalid parameter: not a function. ' };
            }
            var deprecated = function() {
                console.log("Function is deprecated: " + fn.name);
                return fn.apply(null, arguments);
            };

            return deprecated;
        };
    }]);

  ReportService.$inject = ['$http', '$q', 'utilityService', 'WebApiUrl', 'AppConfig', 'ReportDecorators']

  function ReportService($http, $q, utilityService, WebApiUrl, AppConfig, ReportDecorators) {

    var responseHandler = function (httpPromise, success, fail) {
        var deferred = $q.defer();
        httpPromise
        .then(function(res) {
            if(success) {
                success(res);
            }
            deferred.resolve(res.data);
        }, function(error) {
            if(fail) {
                fail(error);
            }
            console.log("Request failed with status: " + error.status);
            deferred.reject(error);
        });
        return deferred.promise;
    };

    var _getQueryReportingEntityList = function (key) {        
        return responseHandler($http.get(WebApiUrl + 'Report/GetQueryReportingEntityList/'+key));
    };

    var _getTableRelationByTable = function (key,tableName) {
        var parameters = { TableName: tableName };
        return responseHandler($http.get(WebApiUrl + 'Report/GetTableRelationByTable/' + key, { params: parameters }));
    };

    var _getAvailableExportDocumentTypes = function (key) {
        return responseHandler($http.get(WebApiUrl + 'Report/GetAvailableExportDocumentTypes/' + key));
    };

    var _getSavedReportingQueries = function (key, query) {
        if(query == undefined) {
            query = null;
        }
        return responseHandler($http.get(WebApiUrl + 'Report/GetSavedReportingQueries/' + key, { params: query }));
    };

    var _deleteReportingQuery = function (key, query) {
        return responseHandler($http.get(WebApiUrl + 'Report/DeleteSavedReportingQuery/' + key, { params: query }));
    };

    var _saveReportingQuery = function (key, request) {
        return responseHandler($http.post(WebApiUrl + 'Report/SaveReportingQuery/' + key, JSON.stringify(request)));
    };

    var _updateReportingQuery = function (key, request) {
        return responseHandler($http.post(WebApiUrl + 'Report/UpdateReportingQuery/' + key, JSON.stringify(request)));
    };

    var _executeQueryAndGetReportTest = function (key, data) {
        return responseHandler($http.post(WebApiUrl + 'Report/ExecuteQueryAndGetReportTest/' + key, JSON.stringify(data)));
    };

    var _executeQueryAndGetDocumentTest = function (key, data) {
        var httpPromise = $http.post(WebApiUrl + 'Report/ExecuteQueryAndGetDocumentTest/' + key, JSON.stringify(data), {
            responseType: 'arraybuffer'
        });
        return responseHandler(httpPromise, function(res){
            var headers = res.headers();
            utilityService.downloadExcelFile(res.data, headers);
        });
    };

    var _executeBulkEmailOnQuery = function (key, data) {
        return responseHandler($http.post(WebApiUrl + 'Report/ExecuteBulkEmailOnQuery/' + key, JSON.stringify(data)));
    };

    var _getPossibleValuesForColumn = function (key, tableName, columnName) {
        var parameters = { tableName: tableName, columnName: columnName };
        return responseHandler($http.get(WebApiUrl + 'Report/GetPossibleValuesForColumn/' + key, { params: parameters }));
    };

    var _getAvailableConditionOperators = function (key, columnDataType) {
        var parameters = { columnDataType: columnDataType };
        return responseHandler($http.get(WebApiUrl + 'Report/GetAvailableConditionOperators/' + key, { params: parameters }));
    };

    var _getAvailableFunctions = function (key) {
        return responseHandler($http.get(WebApiUrl + 'Report/GetAvailableFunctions/' + key));
    };

    
    //Report Data
    var _getReportData = function (key, Pager, apiUrl) {
        var deferred = $q.defer();
        $http.post(WebApiUrl + apiUrl + '?Key=' + key, Pager)
        .then(function (res) {
            deferred.resolve(res.data);
        }, function (error) {
            console.log("Request failed with status: " + error.status);
            deferred.reject(error);
        });
        return deferred.promise;
    };

    //Excel reports
    var _getXLSForReport = function (key, Pager, apiUrl) {
        var deferred = $q.defer();
        $http.post(WebApiUrl + apiUrl + '?Key=' + key, Pager, {
            responseType: 'arraybuffer'
        })
        .then(function (res) {
            var headers = res.headers();
            utilityService.downloadExcelFile(res.data, headers);
            deferred.resolve(res.data);
        }, function (error) {
            console.log("Request failed with status: " + error.status);
            deferred.reject(error);
        });
        return deferred.promise;
    };

    var _getXLSForReportPublic = function (Pager, apiUrl) {
      var deferred = $q.defer();
      Pager.Source = "NVPHARMACY";
      $http.post(WebApiUrl + apiUrl, Pager, {
        responseType: 'arraybuffer'
      })
        .then(function (res) {
          var headers = res.headers();
          utilityService.downloadExcelFile(res.data, headers);
          deferred.resolve(res.data);
        }, function (error) {
          console.log("Request failed with status: " + error.status);
          deferred.reject(error);
        });
      return deferred.promise;
    };
    //End Methods with Unknown Status

    //Begin Deprecated methods
    var _executeQueryAndGetReport = function (key, data) {
        console.log("executeQueryAndGetReport is deprecated.");
        return responseHandler($http.post(WebApiUrl + 'Report/ExecuteQueryAndGetReport/' + key, data));
    };

    var _executeQueryAndGetExcel = function (key, data) {
        console.log("executeQueryAndGetReport is deprecated.");
        var httpPromise = $http.post(WebApiUrl + 'Report/ExecuteQueryAndGetExcel/' + key, data, {
            responseType: 'arraybuffer'
        });
        return responseHandler(httpPromise, function(res){
            var headers = res.headers();
            utilityService.downloadExcelFile(res.data, headers);
        });
    };
    //End Deprecated Methods

    var _getXLSForReportWithSource = function (key, Pager, apiUrl) {
      var deferred = $q.defer();
      $http.post(WebApiUrl + apiUrl + '?Key=' + key + '&Source=' + AppConfig.Source, Pager, {
        responseType: 'arraybuffer'
      }).then(function (data) {
        var headers = data.headers();
        data = data.data;
        utilityService.downloadExcelFile(data, headers);
        deferred.resolve(data);
      }, function (error) {
        var status = error.status
        console.log("Request failed with status: " + status);
        deferred.reject(error);
      });
      return deferred.promise;
    }

    //Begin Deprecated methods
    var _executeQueryAndGetReport = function (key, data) {
        return responseHandler($http.post(WebApiUrl + 'Report/ExecuteQueryAndGetReport/' + key, data));
    };
    _executeQueryAndGetReport = ReportDecorators.Deprecated(_executeQueryAndGetReport);

    var _executeQueryAndGetExcel = function (key, data) {
        var httpPromise = $http.post(WebApiUrl + 'Report/ExecuteQueryAndGetExcel/' + key, data, {
            responseType: 'arraybuffer'
        });
        return responseHandler(httpPromise, function(res){
            var headers = res.headers();
            utilityService.downloadExcelFile(res.data, headers);
        });
    };
    _executeQueryAndGetExcel = ReportDecorators.Deprecated(_executeQueryAndGetExcel);
    //End Deprecated Methods

    return {
      licensureReportWithPager: _getReportData,
      dailyDepositReportWithPager: _getReportData,
      mailingListReportWithPager: _getReportData,
      probationListReportWithPager: _getReportData,
      occupationalLicensingReport: _getReportData,
      veteranDataReportWithPager: _getReportData,
      pendingRenewalReportWithPager: _getReportData,
      pendingApprovalReportWithPager: _getReportData,
      allDataDentalReportWithPager: _getReportData,
      fBIDOJReportWithPager: _getReportData,

      getXLSForDailyDepositReport: _getXLSForReportWithSource,
      getXLSForLicensureReport: _getXLSForReport,
      getXLSForMailingListReport: _getXLSForReport,
      getXLSForProbationListReport: _getXLSForReport,
      getXLSForOccupationalLicensingReport: _getXLSForReport,
      getXLSForVeteranDataReport: _getXLSForReport,
      getXLSForPendingRenewalReport: _getXLSForReport,
      getXLSForPendingApprovalReport: _getXLSForReport,
      getXLSForReportPublic: _getXLSForReportPublic,
      getXLSForAllDataDentalListReport: _getXLSForReport,
      getXLSForFBIDOJReport: _getXLSForReport,
      trialBalanceReportPDF: _getXLSForReport,
      getXLSForTrialBalanceReport: _getXLSForReport,
      
      getQueryReportingEntityList: _getQueryReportingEntityList,
      getTableRelationByTable: _getTableRelationByTable,
      getAvailableExportDocumentTypes: _getAvailableExportDocumentTypes,

      getSavedReportingQueries: _getSavedReportingQueries,
      deleteReportingQuery: _deleteReportingQuery,
      saveReportingQuery: _saveReportingQuery,
      updateReportingQuery: _updateReportingQuery,
      
      executeQueryAndGetReportTest: _executeQueryAndGetReportTest,
      executeQueryAndGetDocumentTest: _executeQueryAndGetDocumentTest,
      executeBulkEmailOnQuery: _executeBulkEmailOnQuery,

      getPossibleValuesForColumn: _getPossibleValuesForColumn,
      getAvailableConditionOperators: _getAvailableConditionOperators,
      getAvailableFunctions: _getAvailableFunctions,
      
      //Deprecated
      executeQueryAndGetReport: _executeQueryAndGetReport,
      executeQueryAndGetExcel: _executeQueryAndGetExcel,
    }
  }
})();
