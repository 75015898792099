(function () {
    'use strict'

    angular.module('app.provider')
        .factory('provideCEEducationService', provideCEEducationService)

    provideCEEducationService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function provideCEEducationService($http, $q, WebApiUrl, AppConfig) {
        var _getStateByCountryID = function (countryID, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'State/StateGetByCountryID/' + key + '?CountryID=' + countryID)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _educationSave = function (individualId, applicationId, userId, individualEducation, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualEducationSave/' + key +
                "?individualId=" + individualId + "&applicationId=" + applicationId + "&UserId=" + userId + "&Source=" + AppConfig.Source, individualEducation)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;

        };

        var _individualCECourseSave = function (objCECourse, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/SaveProviderCourseAddInfo/' + key, objCECourse)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _getEducation = function (individualId, applicationId, userId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualEducationBYIndividualId/' + key + '?IndividualId=' + individualId +
                '&applicationId=' + applicationId +
                '&userId=' + userId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;

        };

        var _saveAddress = function (address, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualAddressSave/' + key, address)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var LookupGetBYLookupTypeID = function (urlParam) {
            return $http.get(WebApiUrl + "Lookup/LookupGetBYLookupTypeID" + urlParam);
        }

        var _getCEEducation = function (ProviderId, ApplicationId, ProviderCourseId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Provider/GetProviderCourseAddInfo/' + key +
                '?ProviderId=' + ProviderId + '&ApplicationId=' + ApplicationId + '&ProviderCourseId=' + ProviderCourseId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getIndividualDocumentlk = function (key, indvId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/GetIndividualDocumentlk/' + key + '?IndividualId=' + indvId + '&ApplicationId=&LinkId=&LinkType=')
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveIndividualdocumentlk = function (key, data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/SaveIndividualdocumentlk/' + key + '?Source=', data)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _getIndividualCEInfo = function (key, indvId, IndividualLicenseId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/GetIndividualCEInfo/' + key + '?IndividualId=' + indvId + '&IndividualLicenseId=' + IndividualLicenseId + '&Source=' + AppConfig.Source)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        return {
            getStateByCountryID: _getStateByCountryID,
            educationSave: _educationSave,
            individualCECourseSave: _individualCECourseSave,
            getEducation: _getEducation,
            LookupGetBYLookupTypeID: LookupGetBYLookupTypeID,
            getCEEducation: _getCEEducation,
            saveAddress: _saveAddress,
            GetIndividualDocumentlk: _getIndividualDocumentlk,
            SaveIndividualdocumentlk: _saveIndividualdocumentlk,
            getIndividualCEInfo: _getIndividualCEInfo
        };
    }
})();
