(function () {
    'use strict';
    angular
        .module('app.backofficeProvider')
        .controller('firmOtherInformationController',['$scope', 'FirmRenewalInformationService', '$q', 'countryStateService', 'WebApiUrl', '$rootScope', 'typeValuesService', 'TypeValue', 'Messages', 
			function($scope, FirmRenewalInformationService, $q, countryStateService, WebApiUrl, $rootScope, typeValuesService, TypeValue, Messages) {
                $scope.configuration = {};
                var _applicationId = null;
                var _uid = sessionStorage.UserID;
                $scope.natValidation = {};
                //Tab Initilizer
                $scope.$on("individualTabChanged", function (evt, ProviderId) {
                    if ($rootScope.individualFirmCurrentTab.name == "firminformation") {
                        $scope.ProviderId = ProviderId;
                        //getRefTableData();
                        initDocument();
                        getProviderBusinessType();
                        GetRefpracticeinfo();
                    }
                });


                $scope.datePicker = {
                    crpRejectionDate: false,
                    crpReceivedDate: false,
                    format: 'M!/d!/yyyy',
                    dateOptions: {
                        formatYear: 'yy',
                        startingDay: 1
                    }
                };

                var initDocument = function () {
                    setTimeout(function () {
                        $scope.$apply(function () {
                            $scope.documentUpload = {
                                ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                                // ApplicationId: _applicationId,
                                ProviderId: $scope.ProviderId,
                                Email: null,
                                SendEmail: null,
                                TransactionId: null,
                                LicenseeReprint: false,
                                DocumentLkToPageTabSectionId: 1,
                                DocumentLkToPageTabSectionCode: "FRM",
                                LoadByDocIdAndDocCode: true,
                                DocId: 17,
                                DocCode: "D1017",
                                LoadDocumentsWithoutBroadCast: true,
                                PDocumentLkType: 'SP'
                            };
                        });
                    }, 1000);
                };

                $scope.DownloadDocumentAPIURL = WebApiUrl + 'Individual/DownloadPDF/' + sessionStorage.Key + '?path=';
                $scope.DownloadDocument = function (path) {
                    window.open($scope.DownloadDocumentAPIURL + path, '_blank');
                };

                $scope.setSaveDocumentRef = function (fn) {
                    $scope.saveAllDocument = fn;
                };

                var getRefTableData = function () {
                    var req = [];
                    req.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=refbusinesstype")));
                    req.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=refpracticeinfo")));
                    $q.all(req).then(function (res) {
                        $scope.businessType = res[0].ReferenceEntities;
                        $scope.practiceinfo = res[1].ReferenceEntities;
                        GetRefpracticeinfo();
                    });
                };

                var GetRefpracticeinfo = function () {
                    if ($scope.practiceinfo) {
                        $scope.RefpracticeinfoList = angular.copy($scope.practiceinfo);
                    } else {
                        $scope.RefpracticeinfoList = [
                            { PraticeTypeId: 1, PracticeTypeName: 'A: Audits' },
                            { PraticeTypeId: 2, PracticeTypeName: 'A: SSAE Engagements' },
                            { PraticeTypeId: 3, PracticeTypeName: 'A: PCAOB Engagement' },
                            { PraticeTypeId: 4, PracticeTypeName: 'R: Reviews' },
                            { PraticeTypeId: 5, PracticeTypeName: 'C: Compilations' },
                            { PraticeTypeId: 6, PracticeTypeName: 'P: Preparations Only (Not Subject to Peer Review)' },
                            { PraticeTypeId: 7, PracticeTypeName: 'T: Other (Tax, Consulting, Etc.)' },
                            { PraticeTypeId: 8, PracticeTypeName: 'E: Exclusively provides accounting services through a firm properly registered in Wyoming.' }
                        ];
                    }
                };

                var GetProviderPracticeDataForSave = function (id) {
                    var filteredData = $.grep($scope.RefpracticeinfoList, function (i) {
                        return (i.IsSelected || i.ProviderPracticeId);
                    })
                    var data = filteredData.map(function (i) {
                        i.PraticeTypeId = i.PraticeTypeId;
                        i.ProviderBusinessTypeId = id;
                        i.BeginDate = angular.copy($scope.providerOther.BeginDate);
                        i.Enddate = angular.copy($scope.providerOther.EndDate);
                        if (i.ProviderPracticeId && i.ProviderPracticeId != 0) {
                            i.ModifiedBy = _uid;
                            if (i.IsSelected) {
                                i.IsActive = true;
                                i.IsDeleted = false;
                            } else {
                                i.IsActive = false;
                                i.IsDeleted = true;
                            }
                        } else {//new item
                            i.IsActive = true;
                            i.ProviderId = $scope.ProviderId;
                            i.ApplicationId = _applicationId;
                            i.IsDeleted = false;
                            i.CreatedBy = _uid;
                        }
                        return i;
                    });
                    return data;
                };

                var SaveProviderPractice = function (data) {
                    var differ = $q.defer();
                    FirmRenewalInformationService.SaveProviderPractice(sessionStorage.Key, data).then(function (res) {
                        if (res.data.Status) {
                            differ.resolve(res);
                        } else {
                            differ.reject(res);
                        }
                    });
                    return differ.promise;
                };

                //var GetProviderPractice = function () {
                //    var differ = $q.defer();
                //    FirmRenewalInformationService.GetProviderPractice(sessionStorage.Key, $scope.ProviderId, 0).then(function (res) {
                //        if (res.data.Status) {
                //            differ.resolve(res);
                //        } else {
                //            differ.reject(res);
                //        }
                //    });
                //    return differ.promise;
                //};

                var ShowErrMsg = function (propName, errorMessage) {
                    propName = (!!propName) ? propName : "FIRM Information";
                    errorMessage = (!!errorMessage) ? errorMessage : $scope.EmployerInformationConfig.ValidationMsg.APIError;
                    if ($scope.natValidation.errorMessages == undefined)
                        $scope.natValidation.errorMessages = [];
                    var propExist = false;
                    for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                        if ($scope.natValidation.errorMessages[i].propName == propName)
                            propExist = true;
                    }
                    if (!propExist)
                        $scope.natValidation.errorMessages.push({
                            propName: propName,
                            errorMessage: errorMessage
                        });

                    if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                        $scope.natValidation.showError = true;
                        $scope.natValidation.isValid = false;
                        return false;
                    } else {
                        $scope.natValidation.showError = false;
                        $scope.natValidation.isValid = true;
                        return true;
                    }
                };

                var validateForm = function (form) {
                    //validate 
                    //Form custom
                    $scope.natValidation.errorMessages = [];
                    if (!!form && $scope.natValidation) {
                        if ($scope.natValidation.errorMessages == undefined) {
                            $scope.natValidation.errorMessages = [];
                        }
                        form.$submitted = true;
                        var errorMessages = angular.copy(form.errorMessages);
                        angular.forEach(errorMessages, function (errorMessage, index) {
                            $scope.natValidation.errorMessages.push(errorMessage);
                        });
                    }

                    if (!$scope.providerOther || !$scope.providerOther.BusinessOrgTypeId || $scope.providerOther.BusinessOrgTypeId == '') {
                        ShowErrMsg('EntityType', "Please select Firm Registration Type (Entity Type)");
                    }

                    if ($scope.RefpracticeinfoList && $scope.RefpracticeinfoList.length > 0) {
                        var selectedPract = $.grep($scope.RefpracticeinfoList, function (i) {
                            return (i.IsSelected);
                        });
                        if (!selectedPract || selectedPract.length == 0) {
                            ShowErrMsg('scopeofPracticeInfo', "Scope Of Practice Information is required.");
                        }
                    }

                    if ($scope.DocumentUploadRequired && !$scope.documentUpload1.documentCount > 0) {
                        ShowErrMsg('EntityTypeChangeDoc', "Please upload the document for Entity Type change.");
                    }

                    if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                        $scope.natValidation.showError = true;
                        $scope.natValidation.isValid = false;
                        return false;
                    } else {
                        $scope.natValidation.showError = false;
                        $scope.natValidation.isValid = true;
                        return true;
                    }

                };
                $scope.DocumentUploadRequired = false;
                $scope.EntityTypeChanged = function () {
                    //if ($scope.ProviderOtheEntityType && $scope.ProviderOtheEntityType != '' && $scope.providerOther.EntityType != 'SP' && $scope.providerOther.EntityType != $scope.ProviderOtheEntityType) {
                    //    $scope.DocumentUploadRequired = true;
                    //} else {
                    //    $scope.DocumentUploadRequired = false;
                    //}
                };

                var SaveFormData = function () {
                    //prepare data providerother
                    ShowLoader("Please wait...");
                    if ($scope.providerOther) {
                        var poList = [];
                        if (!$scope.providerOther.ProviderBusinessTypeId || $scope.providerOther.ProviderBusinessTypeId != 0) {
                            //new provider other
                            $scope.providerOther.ProviderId = $scope.ProviderId;
                            $scope.providerOther.ApplicationId = _applicationId;
                            $scope.providerOther.CreatedBy = _uid;
                            $scope.providerOther.IsActive = true;
                            $scope.providerOther.IsDeleted = false;
                        } else {
                            //update
                            $scope.providerOther.ModifiedBy = _uid;
                        }
                        poList.splice(0, 0, $scope.providerOther);
                        if (typeof $scope.saveAllDocument == 'function') {
                            $scope.saveAllDocument();
                        }
                        saveProviderbusinesstype([$scope.providerOther]).then(function (res) {
                            if (res.data.Status && res.data.ProviderBusinessTypeList.length > 0) {
                                var id = res.data.ProviderBusinessTypeList[0].ProviderBusinessTypeId;
                                var providerpdata = GetProviderPracticeDataForSave(id);
                                if (providerpdata) {
                                    SaveProviderPractice(providerpdata).then(function (resp) {
                                        GetRefpracticeinfo();
                                        getProviderBusinessType();
                                        HideLoader();
                                    });
                                }
                            } else {
                                HideLoader();
                            }
                        }, function () {
                            HideLoader();
                        });
                    } else {
                        HideLoader();
                    }
                };

                var saveProviderbusinesstype = function (data) {
                    var defer = $q.defer();
                    FirmRenewalInformationService.saveProviderBusinessType(sessionStorage.Key, data).then(function (res) {
                        defer.resolve(res);
                    }, function (err) {
                        defer.reject(err);
                    });
                    return defer.promise;
                };

                var getProviderBusinessType = function () {
                    var req = [];
                    req.push(FirmRenewalInformationService.getProviderBusinessTypeByProviderId(sessionStorage.Key, 0, $scope.ProviderId));
                    req.push(FirmRenewalInformationService.GetProviderPractice(sessionStorage.Key, $scope.ProviderId, 0));
                    $q.all(req).then(function (res) {
                        processList(res[0].data.ProviderBusinessTypeList, res[1].data.ProviderPracticeList);
                    });
                };

                var processList = function (businessTypeList, practice) {
                    if (businessTypeList) {
                        //asign practice name
                        if (practice) {
                            practice = practice.map(function (i) {
                                i.practiceName = getPracticeName(i.PraticeTypeId);
                                return i;
                            });

                            businessTypeList = businessTypeList.map(function (j) {
                                var fpractice = $.grep(practice, function (k) {
                                    return (k.ProviderBusinessTypeId == j.ProviderBusinessTypeId);
                                });
                                j.BusinessName = getBusinessName(j.BusinessOrgTypeId);
                                j.pPractice = fpractice;
                                return j;
                            });
                        }
                        $scope.tableListData = businessTypeList;
                    }
                };

                var getBusinessName = function (id) {
                    if (id == 1)
                        return 'Partnership';
                    if (id == 2)
                        return 'Corporation';
                    if (id == 3)
                        return 'Limited Liability Company';
                    if (id == 4)
                        return 'Sole Proprietorship';
                };

                var getPracticeName = function (pid) {
                    if ($scope.RefpracticeinfoList && $scope.RefpracticeinfoList.length > 0) {
                        var p = $.grep($scope.RefpracticeinfoList, function (i) {
                            return (i.PraticeTypeId == pid);
                        });
                        if (p && p.length > 0) {
                            return p[0].PracticeTypeName;
                        }
                    };
                    return '';
                };

                $scope.SubmitForm = function (form) {
                    if (validateForm(form)) {
                        SaveFormData();
                    }
                };

                $scope.addnewClick = function () {
                    $scope.configuration.showForm = true;
                    $scope.providerOther = {};
                    GetRefpracticeinfo();
                };

                $scope.editOtherInfo = function (i) {
                    $scope.providerOther = angular.copy(i);
                    $scope.configuration.showForm = true;
                    if (i.pPractice) {
                        $scope.RefpracticeinfoList = $scope.RefpracticeinfoList.map(function (j) {
                            var titem = $.grep(i.pPractice, function (k) {
                                return (j.PraticeTypeId == k.PraticeTypeId);
                            });
                            if (titem && titem.length > 0) {
                                j.ProviderPracticeId = titem[0].ProviderPracticeId;
                                j.ApplicationId = titem[0].ApplicationId;
                                j.ProviderId = titem[0].ProviderId;
                                j.IsSelected = true;
                            }
                            return j;
                        });
                    }
                };

                $scope.deleteItem = function (i) {
                    if (confirm(Messages.bbabace)) {
                        if (i.pPractice && i.pPractice.length > 0) {
                            i.pPractice = i.pPractice.map(function (itm) {
                                itm.IsActive = 0;
                                itm.IsDeleted = 1;
                                return itm;
                            });
                            SaveProviderPractice(angular.copy(i.pPractice));
                        }

                        i.IsActive = 0;
                        i.IsDeleted = 1;
                        ShowLoader();
                        saveProviderbusinesstype([i]).then(function () {
                            getProviderBusinessType();
                            HideLoader();
                        }, function () { HideLoader() });
                    }
                };

            }]);
})();
