(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('initialApplicationPlssService', initialApplicationPlssService)

    initialApplicationPlssService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function initialApplicationPlssService($http, $q, WebApiUrl, AppConfig) {
        var _getStateByCountryID = function (countryID, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'State/StateGetByCountryID/' + key + '?CountryID=' + countryID)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveIndividualEssay = function (experience, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/SaveIndividualEssay/' + key + '?Source=' + AppConfig.Source, experience)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _getIndividualEssay = function (individualId, applicationId, key, IndividualLicenseId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/GetIndividualEssay/' + key + '?IndividualId=' + individualId + "&ApplicationId=" + applicationId + '&Source=' + AppConfig.Source + '&}&IndividualLicenseId=' + IndividualLicenseId)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            getStateByCountryID: _getStateByCountryID,
            saveIndividualEssay: _saveIndividualEssay,
            getIndividualEssay: _getIndividualEssay
        };
    }
})();
