(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .directive('otherLicensesLogic', [OtherLicensesLogic]);

    function OtherLicensesLogic() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                //Add any other methods needed to facilitate the board-specific logic
                $scope.filterData = function (data) {
                    var filteredData = data.where('item.OtherLicenseTypeId == ' + $scope.otherLicenseTypeId);
                    if ($scope.otherLicenseTypeId == 2) {
                        if ($scope.configName.indexOf('InStateLicenses') != -1) {
                            filteredData = filteredData.where('item.StateCode == "AL"');
                        } else {
                           // filteredData = filteredData.where('item.StateCode != "AL"');
                        }
                    }
                    return filteredData;
                };
            }
        };
    }
})();