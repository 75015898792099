(function () {
    'use strict'

    angular.module('app.pciCompliantPayment')
    .factory('PCICompliantPaymentService', ['$http', '$q', 'WebApiUrl', 'AppConfig', PCICompliantPaymentService]);

    function PCICompliantPaymentService($http, $q, WebApiUrl, AppConfig) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        //Get invoice master
        //Get invoice details
        //Save invoice details
        var _getContacts = function (key, entityId, entityType) {
            if (entityType == 'I') {
                var urlParams = {
                    IndividualId: entityId
                };
                //IndividualContactResponse - list
                return responseHandler($http.get(WebApiUrl + 'Individual/IndividualContactBYIndividualId/' + key + '/', { params: urlParams }));
            } else if (entityType == 'P') {
                var urlParams = {
                    ProviderId: entityId
                };
                //ProviderContacts - list
                return responseHandler($http.get(WebApiUrl + 'Provider/ProviderContactByProviderId/' + key + '/', { params: urlParams }));
            }
        };

        var _getEntity = function (key, entityId, entityType) {
            var urlParams = {
                IndividualId: (entityType == 'I') ? entityId : '',
                providerId: (entityType == 'P') ? entityId : '',
                Source: AppConfig.Source
            };
            if (entityType == 'I') {
                return responseHandler($http.get(WebApiUrl + 'Individual/IndividualBYIndividualId/' + key + '/', { params: urlParams }));
            } else if (entityType == 'P') {
                return responseHandler($http.get(WebApiUrl + 'Provider/GetProviderById/' + key + '/', { params: urlParams }));
            }
        };

        var _getInvoiceMasters = function (key, entityId, entityType, applicationId) {
            var urlParams = {
                IndividualId: entityId,
                ApplicationId: applicationId,
                Source: (entityType == 'P') ? 'provider' : AppConfig.Source
            };
            return responseHandler($http.get(WebApiUrl + 'Individual/GetInvoiceMasterByIndividualIdAndApplicationIdAndInvoiceStatus/' + key + '/', { params: urlParams }));
        };

        var _getInvoiceDetails = function (key, entityId, entityType, invoiceId) {
            var urlParams = {
                IndividualId: (entityType == 'I') ? entityId : '',
                InvoiceId: invoiceId,
                ProviderId: (entityType == 'P') ? entityId : ''
            };
            return responseHandler($http.get(WebApiUrl + 'Individual/GetInvoiceDetailByIndividualIdAndInvoiceId/' + key + '/', { params: urlParams }));
        };

        var _saveInvoiceDetails = function (key, invoiceDetails) {
            var urlParams = {
                Source: AppConfig.Source
            };

            return responseHandler($http.post(WebApiUrl + 'Individual/InvoiceDetailSave/' + key + '/', invoiceDetails, { params: urlParams }));
        };

        var _processExternalElectronicPayment = function(key, data) {
            var urlParams = {
                Source: (!!AppConfig.Source) ? AppConfig.Source : ''
            };

            return responseHandler($http.post(WebApiUrl + 'Payment/ProcessExternalElectronicPayment/' + key + '/', data, { params: urlParams }));
        };

        var _getConfigurationBySetting = function (key, setting) {
            var urlParams = {
                Setting: setting
            };

            return responseHandler($http.get(WebApiUrl + 'Configuration/ConfigurationGetbySettings/' + key + '/', { params: urlParams }));
        };

        var _signCyberSourceParameters = function (key, value) {
            return responseHandler($http.post(WebApiUrl + 'Payment/SignCyberSourceParameters/' + key + '/', value));
        };

        var _getServerTime = function () {
            return responseHandler($http.get(WebApiUrl + 'Common/ServerDateTimeUTCGet/'));
        };

        var _getNewUUID = function () {
            return responseHandler($http.get(WebApiUrl + 'Common/GetNewUUID/'));
        };

        var _saveApplicationStatus = function (ApplicationId, key, applicationStatusId, queueApprovalData) {
            var urlParams = {
                ApplicationId: ApplicationId,
                ApplicationStatusId: applicationStatusId,
                Source: AppConfig.Source
            };

            return responseHandler($http.post(WebApiUrl + 'Common/ApplicationStatusUpdate/' + key + '/', queueApprovalData, { params: urlParams }));
        };

        var _saveApplicationStatusLk = function (key, userId, reqObj) {
            var urlParams = {
                UserId: userId
            };

            return responseHandler($http.post(WebApiUrl + 'Common/ApplicationStatusLkSave/' + key + '/', reqObj, { params: urlParams }));
        };

        var _saveApplicationStatusChangeLog = function (key, userId, reqObj) {
            var urlParams = {
                UserId: userId
            };

            return responseHandler($http.post(WebApiUrl + 'Common/ApplicationStatusChangeLogSave/' + key + '/', reqObj, { params: urlParams }));
        };

        return {
            getContacts: _getContacts,
            getEntity: _getEntity,
            getInvoiceMasters: _getInvoiceMasters,
            getInvoiceDetails: _getInvoiceDetails,
            saveInvoiceDetails: _saveInvoiceDetails,
            processExternalElectronicPayment: _processExternalElectronicPayment,
            getConfigurationBySetting: _getConfigurationBySetting,
            signCyberSourceParameters: _signCyberSourceParameters,
            getServerTime: _getServerTime,
            getNewUUID: _getNewUUID,

            saveApplicationStatus: _saveApplicationStatus,
            saveApplicationStatusLk: _saveApplicationStatusLk,
            saveApplicationStatusChangeLog: _saveApplicationStatusChangeLog
        };
    }
})();