(function () {
    'use strict';

    angular
        .module('app.provider')
        .controller("CeCourseProviderAppController", CeCourseProviderAppController);

    CeCourseProviderAppController.$inject = ["$rootScope", "$scope", "$state", "bppService", "RegisterFactory", "htmlToPdfGenerationService",
        "countryStateService", "initialApplicationstatusService", "typeValuesService", "lookupService", "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl", "$q", "initialProviderApplicationFeePaymentService", "contentService", "Messages"
    ];

    function CeCourseProviderAppController($rootScope, $scope, $state, bppService, RegisterFactory, htmlToPdfGenerationService,
        countryStateService, initialApplicationstatusService, typeValuesService, lookupService, utilityService, $exceptionHandler, TypeValue, WebApiUrl, $q, initialProviderApplicationFeePaymentService, contentService, Messages) {
        if (!$scope.isBackoffice) {
            $scope.providerId = sessionStorage.ProviderId;
        }
        else {
            //   $scope.providerId = $scope.ProviderId;
        }

        var userId = ($scope.isBackoffice ? sessionStorage.UserID : sessionStorage.UserId);
        $scope.backOfficeOrOnline = ($scope.isBackoffice ? 'B' : 'O');
        $scope.userId = userId;
        var applicationTypeId = 8; // applicationTypeId = 8 for new ce course
        //$scope.addEditMode1 = false;
        $scope.viewConfig = {};
        $scope.natValidation = {};
        $rootScope.routeData = $state.current.views.routeData;
        var init = function () {
            $scope.datePicker = {
                fromDateOpened: false,
                toDateOpened: false,
                format: 'M!/d!/yyyy',
                endDateOptions: {
                    minDate: null
                },
                startDateOptions: {
                }
            };
            $scope.getContentByLkItemId();
            $scope.providerData = {
                ceCourseList: {},
                addEditMode1: false
            };
            $scope.natValidation = {};
            if (!$scope.isBackoffice || !!$scope.viewConfigListing.DisplayAddApplication) {
                loadProviderApplication(sessionStorage.Key, $scope.providerId, applicationTypeId);
            } else {
                loadApplicationByApplicationId(sessionStorage.Key, $scope.currentRenewalItem.ApplicationId);
            }

            $scope.feeDetails = {
                // renewalPeriod: 'Renewal Period from 01/01/2018 to 12/31/2018',
                feeType: 'Course Fee',
                courseFee: 50,
                lateFee: '0',
                isOverridePaymentAmount: false
            };
            $scope.totalCourses = 0;
        };

        $scope.setLicenseToMinDate = function () {
            if (angular.isDate($scope.viewConfig.CourseEffectiveDate)) {
                $scope.datePicker.endDateOptions.minDate = $scope.viewConfig.CourseEffectiveDate;
            }
        };

        $scope.setLicenseFromMaxDate = function () {
            if (angular.isDate($scope.viewConfig.CourseExpirationDate)) {
                $scope.datePicker.startDateOptions.maxDate = $scope.viewConfig.CourseExpirationDate;
            }
        };

        $scope.getContentByLkItemId = function () {
            contentService.getContentByContentItemLkIdList([1646], sessionStorage.Key)
                .then(function (response) {
                    var contentArray = response.ContentItemLk;
                    for (var i = 0; i < contentArray.length; i++) {
                        switch (contentArray[i].ContentItemLkId) {
                            case 1646:
                                $scope.instructionsContent = contentArray[i].ContentItemLkDesc;
                                break;
                        }
                    }
                }, function (error) {

                });
        };

        var processFeeDetails = function () {
            var feeDetailData = {};
            angular.forEach($scope.feeDetailRows, function (feeDetailItem) {
                feeDetailData["feeType" + feeDetailItem.FeeDetailId] = feeDetailItem;
            });

            var ceCourseAppFeeDetail = feeDetailData.feeType28;
            var ceCourseREFeeDetail = feeDetailData.feeType13;

            $scope.feeDetails.ceCourseAppFee = ceCourseAppFeeDetail.FeeAmount;
            $scope.feeDetails.totalFee = ceCourseAppFeeDetail.FeeAmount;
            $scope.feeDetails.ceCourseAppFeeDetailCode = ceCourseAppFeeDetail.FeeDetailCode;
            $scope.feeDetails.ceCourseAppFeeDetailDesc = ceCourseAppFeeDetail.FeeDetailDesc;
            $scope.feeDetails.ceCourseAppFeeFundingString = ceCourseAppFeeDetail.FundingString;

            $scope.feeDetails.ceCourseREFee = ceCourseREFeeDetail.FeeAmount;
            $scope.feeDetails.totalFee = ceCourseREFeeDetail.FeeAmount;
            $scope.feeDetails.ceCourseREFeeDetailCode = ceCourseREFeeDetail.FeeDetailCode;
            $scope.feeDetails.ceCourseREFeeDetailDesc = ceCourseREFeeDetail.FeeDetailDesc;
            $scope.feeDetails.ceCourseREFeeFundingString = ceCourseREFeeDetail.FundingString;

            $scope.feeDetails.associationType = 'CA';
            $scope.feeDetails.Module = 'CeCourseApp';
        };

        var getFeeDetails = function () {
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=feedetails")).then(function (responseFee) {
                if (!!responseFee.Status) {
                    $scope.feeDetailRows = responseFee.ReferenceEntities;
                    processFeeDetails();
                    // processFeeStructure();
                    initialProviderApplicationFeePaymentService.getPaymentDetailsByApplicationIdAndIndividualId(sessionStorage.Key, $scope.applicationInfo.ApplicationId, $scope.providerId).then(function (objPaymentDetail) {
                        if (objPaymentDetail.StatusCode != "00") {
                            $scope.showStatusMessage(objPaymentDetail.Message, 'error');
                        }
                        if (!!objPaymentDetail.PaymentDetails && objPaymentDetail.PaymentDetails.length > 0) {
                            $scope.feeDetails.applicationFee = objPaymentDetail.PaymentDetails[0].AmountBilled;
                            $scope.feeDetails.totalFee = objPaymentDetail.PaymentDetails[0].AmountDue;
                        }
                    }, function (error) {
                        HideLoader();
                        $scope.showStatusMessage(error.Message, 'error');
                        return;
                    });
                }
            }, function (error) {
                showStatusMessage(error, "error");
            });
        };

        $scope.loadPayment = function () {
            if (!$scope.providerData.ceCourseList || $scope.providerData.ceCourseList.length == 0) {
                $scope.showStatusMessage(Messages.General.Errors.AddOneCECourse, 'error');
            }
            //  else if ($scope.providerData.addEditMode1) {
            //     $scope.showStatusMessage(Messages.cceedce, 'error');
            // }
            else {
                $scope.$broadcast("Save");
                if (initialApplicationstatusService.getCeCourseFormStatus())
                    $scope.viewConfig.loadPayment = true;
            }
        };

        var providerCECourseByProviderId = function (providerId, applicationId) {
            ShowLoader("Please Wait...");
            var todaysDate = new Date();
            var dataSavePromise = bppService.providerCECourseByProviderId(providerId, "", sessionStorage.Key, applicationId);
            dataSavePromise.then(function (response) {
                var providerCECourses = response.ProviderCourseResponseList;
                if (providerCECourses != null && (providerCECourses.length > 0)) {
                    if (providerCECourses[0].CourseTypeId == 1) {
                        $scope.viewConfig.CourseExpirationDate = todaysDate.addYears(2);
                    }
                    if (providerCECourses[0].CourseTypeId == 2) {
                        $scope.viewConfig.CourseExpirationDate = todaysDate.addYears(3);
                    }
                    $scope.viewConfig.CourseEffectiveDate = todaysDate;
                }

                HideLoader();

            }, function (error) {
                $scope.showStatusMessage({
                    res: error,
                    msg: "error"
                });
            });
            return dataSavePromise;
        };

        var loadApplicationByApplicationId = function (key, applicationId) {
            bppService.applicationByApplicationId(key, applicationId, userId).then(function (response) {
                if (response.Status) {
                    $scope.applicationInfo = response.ApplicationResponseList[0];
                    getFeeDetails();
                    providerCECourseByProviderId($scope.providerId, applicationId);
                    if ($scope.applicationInfo.ApplicationStatusId == 2 || $scope.applicationInfo.ApplicationStatusId == 3 || $scope.applicationInfo.ApplicationStatusId == 15) {
                        $scope.viewConfig.loadPayment = true;
                    }
                    $scope.viewConfig.loadSignInInfo = true;
                }
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage(error, 'error');
            });
        };

        var loadProviderApplication = function (key, providerId, applicationTypeId) {
            ShowLoader();
            bppService.applicationByProviderId(key, providerId).then(function (response) {
                if (response.Status) {
                    var pendingApplication;
                    angular.forEach(response.ApplicationResponseList, function (application) {
                        if (application.ApplicationTypeId == applicationTypeId && (application.ApplicationStatusId == 1 || application.ApplicationStatusId == 14)) {
                            pendingApplication = application;
                            $scope.IsPendingApplication = true;
                        }
                    });
                    $scope.applicationInfo = pendingApplication;
                    if (!pendingApplication) {
                        generateApplicationId(0, userId, providerId, applicationTypeId);
                    } else {
                        getFeeDetails();
                        providerCECourseByProviderId($scope.providerId, $scope.applicationInfo.ApplicationId);
                        $scope.viewConfig.loadSignInInfo = true;
                    }
                }
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage(error, 'error');
            });
        };

        $scope.submitApplicationForm = function () {
            $scope.saveRecord();
        };

        var changeCourseStatus = function (ceCourseList) {
            var todaysDate = new Date();
            angular.forEach(ceCourseList, function (ceCourse) {
                if (ceCourse.CourseStatus == "Pending Approval" || ceCourse.CourseStatusId == 1) {
                    ceCourse.CourseStatus = "Current";
                    ceCourse.CourseStatusId = 3;
                }
                ceCourse.CourseExpirationDate = $scope.viewConfig.CourseExpirationDate;
                ceCourse.CourseEffectiveDate = $scope.viewConfig.CourseEffectiveDate;
                ceCourse.CourseApprovalDate = $scope.viewConfig.CourseEffectiveDate;
            });
            updateCECourses(ceCourseList);
        }

        var updateCECourses = function (ceCourseList) {
            bppService.providerCECourseSave(ceCourseList,
                sessionStorage.Key).then(function (response) {
                    if ($scope.checkServerResponse(response)) {
                        $scope.getAllLicenseeRenewalList();
                        $scope.showStatusMessage(Messages.aefcaee, "success");
                    }
                    HideLoader();
                }, function (error) {
                    HideLoader();
                    $scope.showStatusMessage(error, "error");
                });
        };

        $scope.approveApplicationForm = function () {
            changeCourseStatus($scope.providerData.ceCourseList);
            //updateCECourses($scope.providerData.ceCourseList);
            var applicationSubmitPromise = bppService.applicationStatusUpdate(sessionStorage.Key, $scope.applicationInfo.ApplicationId, 3, 'B');
            var applicationSubmitLkPromise = saveApplicationStatusLk($scope.applicationInfo.ApplicationId, 3);
            var applicationSubmitChangeLogPromise = saveApplicationStatusChangeLog($scope.applicationInfo.ApplicationId, 3);
            $q.all(applicationSubmitPromise, applicationSubmitLkPromise, applicationSubmitChangeLogPromise).then(function (response) {
                //bppService.applicationStatusUpdate(sessionStorage.Key, $scope.applicationInfo.ApplicationId, 3, 'B').then(function (response) {
                console.log('status updated to approved');
            });
        };

        $scope.getPaymentMethod = function () {
            $scope.PaymentMethodList = [{
                Paymentcode: 'CK',
                PaymentMethod: 'Check'
            },
            {
                Paymentcode: 'MO',
                PaymentMethod: 'Money Order'
            },
            {
                Paymentcode: 'CP',
                PaymentMethod: 'Click to pay'
            },
            {
                Paymentcode: 'CC',
                PaymentMethod: 'Credit/Debit Card'
            }
            ];
        }

        $scope.IsAllTheSectionSaved = false;

        var saveApplicationStatusLk = function (applicationId, applicationStatus) {
            var reqData = {
                ApplicationId: applicationId,
                ApplicationTypeId: 8,
                ApplicationStatusId: applicationStatus,
                ApplicationStatusReasonId: 0,
                IsActive: true,
                IsDeleted: false,
                IsArchive: false,
                CreatedBy: $scope.userId,
                ApplicationInternalStatusReasonText: '',
                ApplicationInternalStatusId: 0,
                HighPriority: false
            };
            return initialApplicationstatusService.saveApplicationStatusLk(sessionStorage.Key, $scope.userId, reqData);
        };

        var saveApplicationStatusChangeLog = function (applicationId, applicationStatus) {
            var reqData = {
                ApplicationId: applicationId,
                ApplicationStatusReasonId: 0,
                ApplicationNumber: '',
                ApplicationTypeId: 8,
                ApplicationStatusId: applicationStatus,
                ApplicationSubmitMode: ($scope.isBackoffice ? 'B' : 'O'),
                ApplicationStatusChangedBy: $scope.userId,
                ReferenceNumber: '',
                LicenseTypeId: 0,
                HighPriority: false,
                ApplicationInternalStatusId: 0,
                ApplicationInternalStatusReasonText: ''
            };
            return initialApplicationstatusService.saveApplicationStatusChangeLog(sessionStorage.Key, $scope.userId, reqData);
        };

        $scope.saveRecord = function () {
            $scope.PayandSubmitbtnClicked = false;
            $scope.$broadcast("PayandSubmit", {});
            var paymentStatus = '';
            initialApplicationstatusService.resetAll();
            var interval = setInterval(function () {
                paymentStatus = initialApplicationstatusService.getPaymetStatus();
                if (!!paymentStatus && paymentStatus != "waiting") {
                    clearInterval(interval);
                    $rootScope.ProcessLoaderExternally = false;
                    if (paymentStatus == "success") {
                        var reqObj = initialApplicationstatusService.getEmailAndLogCommunication();
                        reqObj.ProviderId = $scope.providerId;
                        reqObj.ApplicationId = $scope.applicationInfo.ApplicationId;
                        reqObj.PullTabHtmlForPdf = false;
                        reqObj.CreateApplicationPdf = true;
                        reqObj.SendSubmissionEmail = true;
                        reqObj.SendSubmissionSms = true;

                        var applicationStatus = ($scope.isBackoffice ? 15 : 2);
                        var applicationSubmitPromise = bppService.applicationStatusUpdate(sessionStorage.Key, $scope.applicationInfo.ApplicationId, applicationStatus, $scope.backOfficeOrOnline);
                        var applicationSubmitLkPromise = saveApplicationStatusLk($scope.applicationInfo.ApplicationId, applicationStatus);
                        var applicationSubmitChangeLogPromise = saveApplicationStatusChangeLog($scope.applicationInfo.ApplicationId, applicationStatus);
                        $q.all(applicationSubmitPromise, applicationSubmitLkPromise, applicationSubmitChangeLogPromise).then(function (responseAppSubmitted) {

                            // bppService.applicationStatusUpdate(sessionStorage.Key, $scope.applicationInfo.ApplicationId, 2, 'O').then(function (response) {
                            htmlToPdfGenerationService.processFirmRenewalHtml().then(function (response) {
                                reqObj.TabHtml = {
                                    ApplicationID: $scope.applicationInfo.ApplicationId,
                                    IndividualID: $scope.IndividualId,
                                    TabName: 'CECourseCompleteForm',
                                    CreatedBy: $scope.UserId,
                                    DataDesc: "Ce Course Complete Form Html including the css and all sections",
                                    HTMLContent: response,
                                    SortingOrder: 1
                                };
                                // initialApplicationstatusService.submitApplication(sessionStorage.Key, reqObj)
                                //     .then(function (objEmailAndLogCommunication) {
                                //         if (objEmailAndLogCommunication.StatusCode != "00") {
                                //             return showErrorMessage(objEmailAndLogCommunication.Message);
                                //         }
                                if (!$scope.isBackoffice) {
                                    $state.go('app.PaymentConfirmation', {
                                        paymentMethod: 3,
                                        applicationType: $scope.applicationInfo.ApplicationTypeId
                                    });
                                }
                                else {
                                    //loadApplicationByApplicationId(sessionStorage.Key, $scope.applicationInfo.ApplicationId);
                                    $scope.applicationInfo.ApplicationStatusId = 15;
                                    providerCECourseByProviderId($scope.providerId, $scope.applicationInfo.ApplicationId);
                                }
                                // }, function (response) {
                                //     return showErrorMessage(response);
                                // });
                            });
                        });
                    }
                    if (paymentStatus == "error") {
                        HideLoader();
                    }
                }
            }, 500);
        };

        $scope.ProceedtoPaymentclick = function () {
            $scope.ProceedtoPaymentSectionEnable = true;
        };

        $scope.PayandSubmitbtnClick = function () {
            $rootScope.ProcessLoaderExternally = true;
            $scope.PayandSubmitbtnClicked = true;
            $scope.saveRecord();
        };

        var generateApplicationId = function (applicationId, userId, providerId, applicationTypeId) {
            var urlparm = '/' + sessionStorage.Key + '?ApplicationId=' + applicationId + '&UserId=' + userId;
            var reqData = {
                ApplicationTypeId: applicationTypeId,
                ApplicationStatusId: ($scope.isBackoffice ? 14 : 1),
                ApplicationSubmitMode: ($scope.isBackoffice ? 'B' : 'O'),
                GoPaperless: true,
                IsActive: true
            };

            RegisterFactory.GenerateApplicationId(urlparm, reqData).then(function (response) {
                if (response.data.Status) {
                    saveProviderApplication(providerId, response.data.ApplicationId, userId);
                }
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage(error, 'error');
            });
        };

        var saveProviderApplication = function (providerId, applicationId, userId) {
            var reqData = {
                ApplicationId: applicationId,
                providerId: providerId,
                CreatedBy: userId
                // CreatedOn: new Date()
            };
            RegisterFactory.saveProviderApplication(sessionStorage.Key, reqData).then(function (response) {
                if (response.data.Status) {
                    loadApplicationByApplicationId(sessionStorage.Key, applicationId);
                } else {
                    HideLoader();
                }
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage(error, 'error');
            });
        };

        $scope.closeApplicationForm = function () {
            if (!!$scope.isBackoffice) {
                if (!!$scope.currentRenewalItem)
                    $scope.currentRenewalItem.ShowInternalForm = false;
                $scope.viewConfigListing.DisplayAddApplication = false;
            }
            else {
                if (confirm(Messages.General.Confirm.CloseApp)) {
                    $state.go('app.cedashboard');
                }
            }
        };

        init();
    }

})();