(function () {
    'use strict'

    angular.module('app.backofficeAdministor')
    .directive('documentTemplatesValidation', [DocumentTemplatesValidation]);

    function DocumentTemplatesValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.validateTemplate = function () {
                    return true;    
                };


                $scope.validateForm = function () {
                    return true;
                }
            }
        };
    }
})();