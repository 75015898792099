(function() {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeIndividualEmploymentController", BackofficeIndividualEmploymentController);

    BackofficeIndividualEmploymentController.$inject = ["$rootScope", "$scope", "$state", "backofficeIndividualEmploymentService",
        "countryStateService", "typeValuesService", "lookupService", "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl", "Messages"
    ];

    function BackofficeIndividualEmploymentController($rootScope, $scope, $state, backofficeIndividualEmploymentService,
        countryStateService, typeValuesService, lookupService, utilityService, $exceptionHandler, TypeValue, WebApiUrl, Messages) {

        $scope.viewConfig = {
            showAddBlock: false,
            hideAddButton: false
        };
        $scope.isEdit = false;
        $scope.employment = {};
        //Tab Initilizer
        $scope.$on("individualTabChanged", function(evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "employment" && !$rootScope.individualCurrentTab.isLoadedOnce) {
                $scope.IndividualId = individualId;
                if (!$scope.isLoadedOnce) {
                    $scope.lookupGetBYLookupTypeIDForEmpHistory();
                    $scope.isLoadedOnce = true;
                }
                $scope.individualEmploymentByIndividualId($scope.IndividualId, true);
                $rootScope.individualCurrentTab.isLoadedOnce = true;
            }
        });

        $scope.lookupGetBYLookupTypeIDForEmpHistory = function() {
            try {
                lookupService.getLookUpDataByTypeId(67, sessionStorage.Key)
                    .then(function(response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkResponse(response)) {
                            $scope.EmploymentHistoryTypes = response.Lookup;
                        }
                    }, function(data) {
                        $scope.showStatusMessage(data, "error");
                    });
            } catch (ex) {
                $scope.showStatusMessage(ex.message, "error");
            }
        }

        $scope.openAddEditEmployment = function(isEdit) {
            $scope.isEdit = isEdit;
            $scope.viewConfig.showAddBlock = true;
            $scope.viewConfig.hideAddButton = true;
            if (!$scope.isEdit) {
                $scope.employment = {
                    IndividualId: $scope.IndividualId,
                    IsActive: true,
                    EmploymentHistoryTypeId: "-1"
                }
            }
            if (!$scope.employment.physicalAddress) {
                $scope.employment.physicalAddress = {
                    AddressTypeId: 1,
                    IndividualId: $scope.IndividualId,
                    IsActive: true,
                }
            }
            initializeAddressAndContact($scope.employment);
        }
        var initializeAddressAndContact = function(employment) {
            if (!employment.workPhoneContact) {
                employment.workPhoneContact = getNewContact("W", 3);
            }
            if (!employment.alternatePhoneContact) {
                employment.alternatePhoneContact = getNewContact("A", 4);
            }
            if (!employment.faxContact) {
                employment.faxContact = getNewContact("F", 5);
            }
        }
        var getNewContact = function(contactCode, contactTypeId) {
            var newContact = {
                BeginDate: new Date(),
                Code: contactCode,
                ContactFirstName: "",
                ContactId: 0,
                ContactInfo: "",
                ContactLastName: "",
                ContactMiddleName: "",
                ContactTypeId: contactTypeId,
                DateContactValidated: null,
                EndDate: null,
                IndividualContactId: 0,
                IndividualId: $scope.IndividualId,
                IsActive: true,
                IsMobile: false,
                IsPreferredContact: false
            };
            return newContact;
        }

        $scope.closeAddEditEmployment = function() {
            $scope.viewConfig.showAddBlock = false;
            $scope.viewConfig.hideAddButton = false;
        };

        $scope.individualEmploymentByIndividualId = function(IndividualId, hideMessages) {
            ShowLoader("Please Wait...");
            try {
                backofficeIndividualEmploymentService.individualEmploymentByIndividualId(sessionStorage.Key, IndividualId)
                    .then(function(response) {
                            if (hideMessages) {
                                $scope.hideStatusMessage();
                                $scope.hideValidationSummary();
                            }
                            HideLoader();
                            if ($scope.checkServerResponse(response)) {
                                if (response.IndividualEmploymentResponseList != null) {
                                    angular.forEach(response.IndividualEmploymentResponseList, function(employment, empKey) {
                                        angular.forEach(employment.EmploymentAddress, function(empAddress, empAddressKey) {
                                            if (empAddress.AddressTypeId == 1) {
                                                employment.physicalAddress = empAddress;
                                            }
                                        });
                                        angular.forEach(employment.EmploymentContact, function(empContact, empContactKey) {
                                            if (empContact.ContactTypeId == 3) {
                                                employment.workPhoneContact = empContact;
                                            } else if (empContact.ContactTypeId == 4) {
                                                employment.alternatePhoneContact = empContact;
                                            } else if (empContact.ContactTypeId == 5) {
                                                employment.faxContact = empContact;
                                            }
                                        });
                                        initializeAddressAndContact(employment);
                                    });
                                    $scope.IndividualEmployments = response.IndividualEmploymentResponseList;
                                }
                            }
                        },
                        function(data) {
                            $scope.showStatusMessage(data, "error");
                        });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        }


        // Save Employment
        $scope.saveEmployment = function(employmentForm) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                employmentForm.$submitted = true;
                if ($scope.validateData(employmentForm)) {
                    ShowLoader("Please wait...");
                    $scope.SaveEmployemtRecord(false);
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        }


        $scope.SaveEmployemtRecord = function(isDeleted) {
            var employementData = angular.copy($scope.employment);
            if (!isDeleted) {
                employementData.EmploymentAddress = [employementData.physicalAddress];
                employementData.EmploymentContact = [
                    employementData.workPhoneContact,
                    employementData.alternatePhoneContact,
                    employementData.faxContact
                ];
                delete employementData.physicalAddress;
                delete employementData.workPhoneContact;
                delete employementData.alternatePhoneContact;
                delete employementData.faxContact;
            }
            backofficeIndividualEmploymentService.individualEmploymentSave(employementData,
                sessionStorage.Key).then(function(response) {
                if ($scope.checkServerResponse(response)) {
                    $scope.individualEmploymentByIndividualId($scope.IndividualId);
                    $scope.closeAddEditEmployment();
                    $scope.showStatusMessage(response.Message, "success");
                }
                HideLoader();
            }, function(data) {
                HideLoader();
                if (data != null) { $scope.showStatusMessage(data.message, "error"); }
            });
        }

        $scope.getEmploymentByIndividualEmploymentId = function(individualEmployment) {
            $scope.employment = angular.copy(individualEmployment);
            $scope.openAddEditEmployment(true);
        }

        $scope.deleteEmploymentByIndividualEmploymentId = function(individualEmployment) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                ShowLoader("Please Wait...");
                $scope.employment = angular.copy(individualEmployment);
                $scope.employment.IsDeleted = true;
                $scope.SaveEmployemtRecord(true);
            }
        }
    }
})();