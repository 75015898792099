(function () {
    'use strict'

    angular.module('app.core')
    .factory('PCICompliantPaymentServiceExt', ['$http', '$q', 'WebApiUrl', PCICompliantPaymentServiceExt]);

    function PCICompliantPaymentServiceExt($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getTransactionToken = function (key, urlParams) {
            return responseHandler($http.get(WebApiUrl + 'Payment/GetElavonTransactionToken/' + key + '/', { params: urlParams }));
        };

        return {
            getTransactionToken: _getTransactionToken
        };
    }
})();