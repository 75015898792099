(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('noFeeAppSubmissionLogic', [NoFeeAppSubmissionLogic]);

    function NoFeeAppSubmissionLogic() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {

            },
        };
    }
})();