(function () {
    'use strict'
    angular.module('app.report')
      .controller('AllDataReportController', AllDataReportController);
  
      AllDataReportController.$inject = ['$scope', '$q', '$controller', '$filter',
      'reportService', '$state', 'utilityService', 'ApiUrlsForReports', 'ApiUrlsForReportsXls'];
  
    function AllDataReportController($scope, $q, $controller, $filter, reportService, $state, utilityService, ApiUrlsForReports, ApiUrlsForReportsXls) {
      $controller('GlobalController', { $scope: $scope })
  
      // Pagination
      $scope.Pager = {
        sortType: 'CredentialDefinition',
        sortOrder: 'ASC',
        currentPage: 1,
        totalRecords: 10,
        pageSize: 20,
        maxSize: 5,
        mode: 1
      }
      $scope.Headers = [
        { name: 'First Name', sortType: 'FirstName', sortOrder: 'desc'},
        { name: 'Middle Name', sortType: 'MiddleName', sortOrder: 'desc'},
        { name: 'Last Name', sortType: 'LastName', sortOrder: 'desc'},
        { name: 'Suffix', sortType: 'Suffix', sortOrder: 'desc'},
        { name: 'Title', sortType: 'Title', sortOrder: 'desc'},
        { name: 'Birth Date', sortType: 'BirthDate', sortOrder: 'desc' },
        { name: 'Birth City', sortType: 'BirthCity', sortOrder: 'desc'},
        { name: 'Birth State', sortType: 'BirthState', sortOrder: 'desc'},
        { name: 'Birth Country', sortType: 'BirthCountry', sortOrder: 'desc'},
        { name: 'SSN', sortType: 'SSN', sortOrder: 'desc'},
        { name: 'Credential Number', sortType: 'CredentialNumber', sortOrder: 'desc'},
        { name: 'Credential Type', sortType: 'CredentialType', sortOrder: 'desc'},
        { name: 'Credential Definition', sortType: 'CredentialDefinition', sortOrder: 'desc'},
        { name: 'First Issuance Date', sortType: 'FirstIssuanceDate', sortOrder: 'desc'},
        { name: 'Expiration Date', sortType: 'ExpirationDate', sortOrder: 'desc'},
        { name: 'Credential Status', sortType: 'CredentialStatus', sortOrder: 'desc'},
        { name: 'Discipline Status', sortType: 'DisciplineStatus', sortOrder: 'desc'},
        { name: 'Mailing Address', sortType: 'MailingAdd', sortOrder: 'desc' },
        { name: 'Mailing Address 2', sortType: 'MailingAdd2', sortOrder: 'desc'},
        { name: 'Mailing City', sortType: 'MailingCity', sortOrder: 'desc'},
        { name: 'Mailing State', sortType: 'MailingState', sortOrder: 'desc'},
        { name: 'Mailing Zip', sortType: 'MailingZip', sortOrder: 'desc'},
        { name: 'Primary Business Name', sortType: 'PrimaryBusinessName', sortOrder: 'desc'},
        { name: 'Primary Physical Address', sortType: 'PrimaryPhysicalAdd', sortOrder: 'desc'},
        { name: 'Primary Physical Address 2', sortType: 'PrimaryPhysicalAdd2', sortOrder: 'desc'},
        { name: 'Primary City', sortType: 'PrimaryCity', sortOrder: 'desc'},
        { name: 'Primary State', sortType: 'PrimaryState', sortOrder: 'desc'},
        { name: 'Primary Zip#', sortType: 'PrimaryZip', sortOrder: 'desc'},
        { name: 'Primary Parish', sortType: 'PrimaryParish', sortOrder: 'desc'},
        { name: 'Primary Phone', sortType: 'PrimaryPhone', sortOrder: 'desc' },
        { name: 'Exams Passed', sortType: 'ExamsPassed', sortOrder: 'desc'},
        { name: 'Specialty 1', sortType: 'Specialty1', sortOrder: 'desc'},
        { name: 'Specialty 2', sortType: 'Specialty2', sortOrder: 'desc'},
        { name: 'Specialty 3', sortType: 'Specialty3', sortOrder: 'desc'},
        { name: 'Specialty 4', sortType: 'Specialty4', sortOrder: 'desc'},
        { name: 'Medical School', sortType: 'MedicalSchool', sortOrder: 'desc'},
        { name: 'Medical School City', sortType: 'MedicalSchoolCity', sortOrder: 'desc'},
        { name: 'Medical School State', sortType: 'MedicalSchoolState', sortOrder: 'desc'},
        { name: 'Medical School Date Graduated', sortType: 'MedicalSchoolDateGraduated', sortOrder: 'desc'},
        { name: 'Race', sortType: 'Race', sortOrder: 'desc'},
        { name: 'Gender', sortType: 'Gender', sortOrder: 'desc'}
      ]
      $scope.getAllDataReportList = function () {
        try {
          if ($scope.isSessionActive()) {
            ShowLoader('Please Wait...')
            reportService.allDataDentalReportWithPager(sessionStorage.Key, $scope.Pager, ApiUrlsForReports.allData)
              .then(function (response) {
                console.log(response);  
                if ($scope.checkResponse(response)) {
                  $scope.Pager.totalRecords = response.PagerVM.TotalRecords;
                  $scope.LicensureList = response.PagerVM.Records;
                }
                HideLoader();
                utilityService.scrollTop();
              }, function (data) {
                HideLoader()
                showStatusMessage(data, 'error')
              })
          } else {
            $state.go('app.Loginwoemail')
          }
        } catch (ex) {
          HideLoader()
          if (ex != null) { showStatusMessage(ex.message, 'error'); }
        }
      }
  
      $scope.getAllDataReportList();
  
      $scope.SortRecords = function (header) {
        $scope.Pager.currentPage = 1;
        $scope.Pager.sortType = header.sortType;
        $scope.Pager.sortOrder = header.sortOrder;
        header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
        $scope.getAllDataReportList();
      }
      $scope.getExcelForAllDataReport = function () {
        try {
          ShowLoader('Please Wait...')
          reportService.getXLSForAllDataDentalListReport(sessionStorage.Key, $scope.Pager, ApiUrlsForReportsXls.allData)
            .then(function (response) {
              console.log(response);
              HideLoader()
            }, function (data) {
              HideLoader()
              showStatusMessage(data, 'error')
            })
        } catch (ex) {
          HideLoader()
          if (ex != null) { showStatusMessage(ex.message, 'error'); }
        }
      }
    };
})();
  
  