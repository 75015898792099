﻿(function () {
    'use strict'
    angular.module('natApp')
        .controller('BEAdditionalwyomingController', BEAdditionalwyomingController);

    BEAdditionalwyomingController.$inject = ['$scope', '$rootScope', 'BEAdditionalwyomingService', '$q', 'countryStateService', 'BusinessEntitytatusService', 'Messages'];

    function BEAdditionalwyomingController($scope, $rootScope, BEAdditionalwyomingService, $q, countryStateService, BusinessEntitytatusService, Messages) {
        var _key = sessionStorage.Key;
        var _providerId = $scope.providerid;
        var _uid = sessionStorage.UserID;
        $scope.init = function () {
            $q.all([GetCountryList(), GetStateList($rootScope.DefaultCountryId, true, true), ProviderAddressByProviderId(), GetProviderContactByProviderId()]).then(function () {
                setProviderAddressListData();
            });
        };

        var GetCountryList = function () {
            var deferred = $q.defer();
            try {
                countryStateService.countryGetAll(_key).then(function (res) {
                    if (res.Status) {
                        $scope.CountryList = res.Country;
                    }
                    deferred.resolve(res);
                }, function (err) {
                    console.log('response', res);
                    deferred.reject(err);
                });
            }
            catch (ex) {
                console.log('Exception GetCountryList()', ex);
                deferred.reject(ex);
            }
            return deferred.promise;
        };

        var GetStateList = function (cid, l1, l2) {
            var deferred = $q.defer();
            try {
                countryStateService.getStateByCountryID(_key, cid).then(function (res) {
                    if (res.Status) {
                        if (l1) {
                            $scope.StateList = res.State;
                        }
                        if (l2) {
                            $scope.StateList2 = res.State;
                        }
                    } else {
                        if (l1) {
                            $scope.StateList = [];
                        }
                        if (l2) {
                            $scope.StateList2 = [];
                        }
                    }
                    deferred.resolve(res);
                }, function (err) {
                    deferred.reject(err);
                });
            }
            catch (ex) {
                console.log('Exception GetStateList()', ex);
                deferred.reject(ex);
            }
            return deferred.promise;
        };

        var ProviderAddressByProviderId = function () {
            var deferred = $q.defer();
            try {
                BEAdditionalwyomingService.ProviderAddressByProviderId(_key, _providerId).then(function (res) {
                    if (res.data.Status) {
                        $scope.ProviderAdditionalAddressList = res.data.ProviderAddress;
                    }
                    deferred.resolve(res);
                }, function (err) {
                    console.log('response', err);
                    deferred.reject(err);

                });
            }
            catch (ex) {
                deferred.reject(ex);
                console.log('Exception ProviderAddressByProviderId()', ex);
            }
            return deferred.promise;
        };

        var GetProviderContactByProviderId = function () {
            var deferred = $q.defer();
            try {
                BEAdditionalwyomingService.GetProviderContactByProviderId(_key, _providerId).then(function (res) {
                    if (res.data.Status) {
                        $scope.ProviderContact = res.data.ProviderContacts;
                    }
                    deferred.resolve(res);
                }, function (err) {
                    deferred.reject(err);
                    console.log('response', err);

                });
            }
            catch (ex) {
                deferred.reject(ex);
                console.log('Exception GetProviderContactByProviderId()', ex);
            }
            return deferred.promise;
        };

        var SaveProviderContact = function (contact) {
            var deferred = $q.defer();
            if (contact && contact.length > 0) {
                BEAdditionalwyomingService.ProviderContactSave(_key, contact).then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.reject(response);
                });
            } else {
                deferred.resolve({});
            }
            return deferred.promise;
        };

        var SaveProviderAddress = function (address) {
            var deferred = $q.defer();
            BEAdditionalwyomingService.ProviderAddressSave(_key, address).then(function (response) {
                deferred.resolve(response);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        $scope.FilterAdditionalAddress = function (item) {
            return (item.AddressTypeId == 11 && !item.IsDeleted);
        };

        var isValidAdditionalAddress = function (form) {
            if (!$scope.natValidationAdd) {
                $scope.natValidationAdd = {};
            }
            $scope.natValidationAdd.errorMessages = [];
            if (!!form && $scope.natValidationAdd) {
                if ($scope.natValidationAdd.errorMessages == undefined) {
                    $scope.natValidationAdd.errorMessages = [];
                }
                form.$submitted = true;
                var errorMessages = angular.copy(form.errorMessages);
                angular.forEach(errorMessages, function (errorMessage, index) {
                    $scope.natValidationAdd.errorMessages.push(errorMessage);

                });
            }

            if (!!$scope.natValidationAdd.errorMessages && $scope.natValidationAdd.errorMessages.length > 0) {
                $scope.natValidationAdd.showError = true;
                $scope.natValidationAdd.isValid = false;
                return false;
            } else {
                $scope.natValidationAdd.showError = false;
                $scope.natValidationAdd.isValid = true;
                return true;
            }

        };

        $scope.SaveAdditionalWyomingLocation = function (form) {
            if (!isValidAdditionalAddress(form)) {
                return;
            }

            if ($scope.AdditionalWyomingLocation) {
                //Save Provider Address
                if (!$scope.AdditionalWyomingLocation.ProviderAddressId || $scope.AdditionalWyomingLocation.ProviderAddressId == 0) {
                    $scope.AdditionalWyomingLocation.AddressTypeId = 11;
                    $scope.AdditionalWyomingLocation.BadAddress = false;
                    $scope.AdditionalWyomingLocation.IsActive = 1;
                    $scope.AdditionalWyomingLocation.IsDeleted = 0;
                    $scope.AdditionalWyomingLocation.CreatedBy = _uid;
                    $scope.AdditionalWyomingLocation.ProviderId = _providerId;
                } else {
                    $scope.AdditionalWyomingLocation.ModifiedBy = _uid;
                }
                ShowLoader();
                //Save provider address
                SaveProviderAddress($scope.AdditionalWyomingLocation).then(function (res) {
                    if (res.data.Status) {
                        var pAddID = res.data.ProviderAddressId;
                        //Save ProviderContact
                        var contactList = getContactList(pAddID, $scope.AdditionalWyomingLocation);
                        ShowLoader();
                        if (contactList && contactList.length > 0) {
                            SaveProviderContact(contactList).then(function (res) {
                                if (res && res.data && res.data.Status) {
                                    $scope.AdditionalWyomingLocation = {};
                                    $scope.addprivsAddress = false;
                                    $q.all([ProviderAddressByProviderId(), GetProviderContactByProviderId()]).then(function () {
                                        setProviderAddressListData();
                                        HideLoader();
                                    }, function (res) {
                                        HideLoader();
                                    });
                                } else {
                                    HideLoader();
                                }
                            }, function () { HideLoader(); });
                        } else {
                            $q.all([ProviderAddressByProviderId(), GetProviderContactByProviderId()]).then(function () {
                                setProviderAddressListData();
                                $scope.AdditionalWyomingLocation = {};
                                $scope.addprivsAddress = false;
                                HideLoader();
                            }, function (res) {
                                HideLoader();
                            });
                        }
                    } else {
                        HideLoader();
                    }

                }, function () {
                    HideLoader();
                });

            }

            // else {
            //     SaveAdditionalAddressinTemp($scope.AdditionalWyomingLocation)
            //     $scope.AdditionalWyomingLocation = {};
            //     $scope.addprivsAddress = false;
            // }
        };

        var SaveAdditionalAddressinTemp = function (address) {
            try {
                if (!$scope.ProviderAdditionalAddressList) {
                    $scope.ProviderAdditionalAddressList = [];
                }
                var checkIndex = -1;
                //if database record update
                if (address.ProviderAddressId) {
                    checkIndex = $scope.ProviderAdditionalAddressList.map(function (i) {
                        return i.ProviderAddressId;
                    }).indexOf(address.ProviderAddressId);
                }
                //if temp record update
                else if (address.TempId) {
                    checkIndex = $scope.ProviderAdditionalAddressList.map(function (i) {
                        return i.TempId;
                    }).indexOf(address.TempId);
                    address.TempId = $scope.ProviderAdditionalAddressList[checkIndex].TempId;
                }
                var tempState = {};
                //set provider State
                tempState = getStateByCode(address.StateCode);
                if (tempState && tempState.StateCode) {
                    address.State = tempState.Name;
                }
                //address type
                address.AddressTypeId = 11;
                if (checkIndex == -1) {
                    var unkid = guidGenerator();
                    address.TempId = unkid;
                    $scope.ProviderAdditionalAddressList.splice(0, 0, address);
                } else {
                    $scope.ProviderAdditionalAddressList.splice(checkIndex, 1, address);
                }
            } catch (e) {
                console.log('exc::', e);
            }
        };

        var getContactList = function (refNumber, item) {
            //Save ProviderContact
            var contactList = [];
            //AdditionalEmail
            if (item.AdditionalEmail) {
                if (item.AdditionalEmail.ProviderContactId) {
                    if (!item.AdditionalEmail.ReferenceNumber) {
                        item.AdditionalEmail.ReferenceNumber = refNumber
                    }
                    item.AdditionalEmail.ModifiedBy = $scope.userId;
                    contactList.splice(0, 0, item.AdditionalEmail);
                } else {
                    item.AdditionalEmail.IsActive = 1;
                    item.AdditionalEmail.IsDeleted = item.IsDeleted ? item.IsDeleted : 0;
                    item.AdditionalEmail.CreatedBy = $scope.userId;
                    item.AdditionalEmail.ProviderId = $scope.providerid;
                    item.AdditionalEmail.ContactTypeId = 26;
                    item.AdditionalEmail.ReferenceNumber = refNumber;
                    contactList.splice(0, 0, item.AdditionalEmail);
                }

            }
            //AdditionalPhone
            if (item.AdditionalPhone) {
                if (item.AdditionalPhone.ProviderContactId) {
                    if (!item.AdditionalPhone.ReferenceNumber) {
                        item.AdditionalPhone.ReferenceNumber = refNumber
                    }
                    item.AdditionalPhone.ModifiedBy = $scope.userId;
                    contactList.splice(0, 0, item.AdditionalPhone);
                } else {
                    item.AdditionalPhone.IsActive = 1;
                    item.AdditionalPhone.IsDeleted = item.IsDeleted ? item.IsDeleted : 0;
                    item.AdditionalPhone.CreatedBy = $scope.userId;
                    item.AdditionalPhone.ProviderId = $scope.providerid;
                    item.AdditionalPhone.ContactTypeId = 27;
                    item.AdditionalPhone.ReferenceNumber = refNumber;
                    contactList.splice(0, 0, item.AdditionalPhone);
                }
            }
            return contactList;
        };

        function guidGenerator() {
            var S4 = function () {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            };
            return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
        }

        $scope.CountryChanged = function () {
            $scope.AdditionalWyomingLocation.StateCode = '';
            if (!$scope.AdditionalWyomingLocation.CountryId) {
                $scope.StateList = angular.copy($scope.CountryList);
            } else {
                $scope.StateList = [];
                GetStateList($scope.AdditionalWyomingLocation.CountryId, true, true);
            }
        };

        var setProviderAddressListData = function () {
            try {
                if ($scope.ProviderAdditionalAddressList) {
                    var tempState = {};
                    for (var i = 0; i < $scope.ProviderAdditionalAddressList.length; i++) {
                        //set provider State
                        tempState = getStateByCode($scope.ProviderAdditionalAddressList[i].StateCode);
                        if (tempState && tempState.StateCode) {
                            $scope.ProviderAdditionalAddressList[i].State = tempState.Name;
                        }
                        //Set provider Contact
                        if ($scope.ProviderContact && $scope.ProviderContact.length > 0) {
                            //get AdditionalEmail
                            var paddID = '' + $scope.ProviderAdditionalAddressList[i].ProviderAddressId;
                            var addEm = $.grep($scope.ProviderContact, function (n, i) { return (n.ContactTypeId == 26 && n.ReferenceNumber == paddID) });
                            $scope.ProviderAdditionalAddressList[i].AdditionalEmail = (addEm && addEm.length > 0) ? addEm[0] : {};

                            //get AdditionalPhone
                            var addPh = $.grep($scope.ProviderContact, function (n, i) { return (n.ContactTypeId == 27 && n.ReferenceNumber == paddID) });
                            $scope.ProviderAdditionalAddressList[i].AdditionalPhone = (addPh && addPh.length > 0) ? addPh[0] : {};
                        }
                    }
                }
            } catch (ex) {
                console.log('Exception setProviderAddressListData()', ex);
            }
        };

        var SaveProviderTempDataToDatabase = function () {
            if ($scope.ProviderAdditionalAddressList && $scope.ProviderAdditionalAddressList.length > 0) {
                var getFilterList = $.grep($scope.ProviderAdditionalAddressList, function (i) {
                    return (i.ProviderAddressId || !i.IsDeleted)
                });
                if (getFilterList && getFilterList.length > 0) {
                    var methodList = [];
                    getFilterList.map(function (i) {
                        var item = i;
                        //address
                        if (!item.ProviderAddressId || item.ProviderAddressId == 0) {
                            item.AddressTypeId = 11;
                            item.BadAddress = false;
                            item.IsActive = 1;
                            item.IsDeleted = item.IsDeleted ? item.IsDeleted : 0;
                            item.CreatedBy = $scope.userId;
                            item.ProviderId = $scope.providerid;
                        } else {
                            item.ModifiedBy = $scope.userId;
                        }
                        methodList.push(SaveProviderAddress(item));
                        return i;
                    });

                    $q.all(methodList).then(function (res) {
                        var contactList = [];
                        if (res.length == getFilterList.length) {
                            for (var i = 0; i < getFilterList.length; i++) {
                                var aId = res[i].data.ProviderAddressId;
                                var newItems = getContactList(aId, getFilterList[i]);
                                if (newItems && newItems.length > 0) {
                                    contactList = contactList.concat(newItems);
                                }
                            }
                        }
                        if (contactList.length > 0) {
                            SaveProviderContact(contactList).then(function (res) {
                                if (res && res.data && res.data.Status) {
                                    $q.all([ProviderAddressByProviderId(), GetProviderContactByProviderId()]).then(function () {
                                        setProviderAddressListData();
                                        HideLoader();
                                    }, function (res) {
                                        HideLoader();
                                    });
                                } else {
                                    HideLoader();
                                }
                                BusinessEntitytatusService.setAdditionalWyoming("success");
                            }, function () {
                                BusinessEntitytatusService.setAdditionalWyoming("error");
                                HideLoader();
                            });
                        } else {
                            $q.all([ProviderAddressByProviderId(), GetProviderContactByProviderId()]).then(function () {
                                setProviderAddressListData();
                            }, function (res) {
                                HideLoader();
                            });
                            BusinessEntitytatusService.setAdditionalWyoming("success");
                        }
                    }, function () {
                        BusinessEntitytatusService.setAdditionalWyoming("error");
                    });
                }
            }
            else {
                BusinessEntitytatusService.setAdditionalWyoming("success");
            }
        };

        $scope.EditAdditionalAddress = function (item) {
            $scope.AdditionalWyomingLocation = angular.copy(item);
            if ($scope.AdditionalWyomingLocation.CountryId) {
                GetStateList($scope.AdditionalWyomingLocation.CountryId, false, true);
            }
            $scope.addprivsAddress = true;
        };

        $scope.DeleteAdditionalAddress = function (item) {
            if (item) {
                if (confirm(Messages.dfdbeab)) {

                    item.IsDeleted = 1;
                    if ($scope.saveonsubmit) {
                        return;
                    }
                    var contactList = [];
                    //AdditionalEmail
                    if (item.AdditionalEmail && item.AdditionalEmail.ProviderContactId) {
                        item.AdditionalEmail.IsDeleted = 1;
                        contactList.splice(0, 0, item.AdditionalEmail);
                    }
                    //AdditionalPhone
                    if (item.AdditionalPhone && item.AdditionalPhone.ProviderContactId) {
                        item.AdditionalPhone.IsDeleted = 1;
                        contactList.splice(0, 0, item.AdditionalPhone);
                    }
                    $q.all([SaveProviderAddress(item), SaveProviderContact(contactList)]).then(function () {
                        $q.all([ProviderAddressByProviderId(false, true)]).then(function () {
                            setProviderAddressListData();
                            HideLoader()
                        }, function () { HideLoader() });
                        //alert(Messages.acefcde);
                    }, function () {

                    });
                }
            }
        };
        var getStateByCode = function (StateCode) {
            if ($scope.StateList && StateCode) {
                var index = $scope.StateList.map(function (i) { return i.StateCode }).indexOf(StateCode);
                if (index != -1) {
                    return $scope.StateList[index];
                } else {
                    return StateCode;
                }
            }
        };
        $scope.$on($scope.eventname, function (event, args) {

            if ($scope.saveonsubmit && $scope.providerid && $scope.providerid != 0) {
                SaveProviderTempDataToDatabase();
            } else {
                BusinessEntitytatusService.setAdditionalWyoming("success");
            }
        });
    }
})();