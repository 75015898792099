(function () {
    'use strict'

    angular.module('task.management')
        .controller('CreateTaskController', ['$scope', '$q', '$state', 'TaskManagementService', CreateTaskController]);

    function CreateTaskController($scope, $q, $state, TaskManagementService) {
        var _key = sessionStorage.Key;

        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'MM/dd/yyyy',
            startDateOptions: {
                maxDate: null,
                minDate: null
            },
            endDateOptions: {
                minDate: null,
                maxDate: null
            }
        };

        if ($state.params && $state.params.actionType) {
            $scope.actionType = $state.params.actionType;
        }

        var generateDays = function () {
            $scope.days = [];
            for (var i = 31; i > 0; i--) {
                $scope.days.splice(0, 0, i);
            }
        };
        var generateMinutes = function () {
            $scope.minutes = [];
            for (var i = 59; i >= 0; i--) {
                $scope.minutes.splice(0, 0, i);
            }
        };
        var generateHours = function () {
            $scope.hours = [];
            var min = '';
            for (var i = 23; i >= 0; i--) {
                $scope.hours.splice(0, 0, i);
            }
        };

        var generateoccursEveryHr = function () {
            $scope.occursEveryHr = [];
            for (var i = 24; i > 0; i--) {
                $scope.occursEveryHr.splice(0, 0, i);
            }
        };
        var generateoccursEveryDay = function () {
            $scope.occursEveryDay = [];
            for (var i = 31; i > 0; i--) {
                $scope.occursEveryDay.splice(0, 0, i);
            }
        }
        var generateoccursEveryWeek = function () {
            $scope.occursEveryWeek = [];
            for (var i = 100; i > 0; i--) {
                $scope.occursEveryWeek.splice(0, 0, i);
            }
        };
        var generateoccursEveryMonth = function () {
            $scope.occursEveryMonth = [];
            for (var i = 12; i > 0; i--) {
                $scope.occursEveryMonth.splice(0, 0, i);
            }
        };

        var resetProperties = function () {
            generateDays();
            generateHours();
            generateMinutes();
            generateoccursEveryDay();
            generateoccursEveryHr();
            generateoccursEveryMonth();
            generateoccursEveryWeek();

            $scope.taskDetails = {
                TaskScheduleType: '',
                TaskScheduleModeId: 1,
                TaskSchduleHour: 0,
                TaskScheduleMin: 0,
                TemplateId: 0,
                RecurEvery: 1,
                EntityType: 'I',
                IsEnabled: true,
                GMTMin: new Date().getTimezoneOffset(),
                TaskScheduleDate: null,
                TaskEffectiveDate: null,
                TaskEndDate: null,
                TaskScheduleModeName: 'One Time'
            };
        };

        $scope.getEvent = function () {
            return $scope.taskScheduleAutoId == 0;
        };

        $scope.startDateChanged = function (isEdit) {
            if (!isEdit) {
                $scope.taskDetails.TaskEndDate = null;
            }
            if ($scope.taskDetails.TaskEffectiveDate && angular.isDate($scope.taskDetails.TaskEffectiveDate)) {
                $scope.datePicker.endDateOptions.minDate = $scope.taskDetails.TaskEffectiveDate;
                var effectiveDate = new Date($scope.taskDetails.TaskEffectiveDate);
                effectiveDate.setFullYear(effectiveDate.getFullYear() + 10);
                $scope.datePicker.endDateOptions.maxDate = effectiveDate;
            } else {
                $scope.datePicker.endDateOptions.minDate = new Date();
            }
        };

        $scope.endDateChanged = function () {
            if ($scope.taskDetails.TaskEndDate && angular.isDate($scope.taskDetails.TaskEndDate)) {
                $scope.datePicker.startDateOptions.maxDate = $scope.taskDetails.TaskEndDate;
            } else {
                $scope.datePicker.startDateOptions.maxDate = null;
            }
        };

        $scope.init = function () {
            resetProperties();

            $scope.taskScheduleDatePicker = {
                dateOpened: false,
                format: 'MM/dd/yyyy'
            };

            var serviceCalls = [TaskManagementService.getTaskList(_key), TaskManagementService.getAllTemplates(_key)];

            if ($state.params && $state.params.TaskScheduleAutoId) {
                $scope.taskScheduleAutoId = $state.params.TaskScheduleAutoId;

                serviceCalls.push(TaskManagementService.getTaskDetailsById(_key, $scope.taskScheduleAutoId));
            } else {
                $scope.taskScheduleAutoId = 0;
            }

            $scope.taskList = [];
            $scope.taskRecipients = [];

            $q.all(serviceCalls).then(function (data) {
                $scope.taskList = data[0].ReferenceEntities;
                $scope.templateList = data[1].where('item.AppliestoTaskStatusNotification && item.IsActive');
                if (data[2]) {
                    $scope.taskDetails = data[2].TaskDetails;
                    if ($scope.taskDetails) {
                        if ($scope.taskDetails.TaskEffectiveDate) {
                            $scope.taskDetails.TaskEffectiveDate = new Date($scope.taskDetails.TaskEffectiveDate);
                        }
                        if ($scope.taskDetails.TaskEndDate) {
                            $scope.taskDetails.TaskEndDate = new Date($scope.taskDetails.TaskEndDate);
                        }
                        $scope.startDateChanged(true);
                        $scope.endDateChanged();
                    }
                    if ($scope.actionType && $scope.actionType == "clone") {
                        $scope.taskDetails.TaskScheduleAutoId = 0;
                        $scope.taskDetails.TaskScheduleId = null;
                        $scope.taskDetails.IsEnabled = true;
                    }
                    $scope.taskRecipients = data[2].TaskDetails.Recipients.select(function (r) { return { email: r }; });
                }

                $scope.loadingTasks(false);
            }, function (error) {
                $scope.loadingTasks(false);
            });
        };

        var processValidationMessage = function () {
            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        var validateForm = function (currentForm) {
            if (!$scope.natValidation) {
                $scope.natValidation = {};
            }
            $scope.natValidation.errorMessages = [];
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            return processValidationMessage();
        };

        $scope.saveTaskSchedule = function (currentForm) {
            try {
                if (!validateForm(currentForm)) {
                    return;
                }
                $scope.loadingTasks(true, "Saving...");

                $scope.taskDetails.TaskScheduleType = $scope.taskList.where('item.TaskTypeId==this', $scope.taskDetails.TaskTypeId)[0].TaskTypeCode;
                $scope.taskDetails.Recipients = $scope.taskRecipients.select('email');
                TaskManagementService.createNewTask(_key, $scope.taskDetails)
                    .then(function (data) {
                        console.log(data);
                        if (data.Status && data.StatusCode != '-1') {
                            $state.go('app.TaskList');
                        } else if (data.Status && data.StatusCode == '-1') {
                            $scope.natValidation.errorMessages = [{
                                errorMessage: "Task with same name already exists"
                            }];
                            processValidationMessage();
                        }
                        $scope.loadingTasks(false);
                    }, function (error) {
                        $scope.loadingTasks(false);
                    });
            } catch (ex) {
                $scope.loadingTasks(false);
            }
        };

        $scope.scheduleTypeChanged = function (modeId) {
            $scope.taskDetails.RecurEvery = null;
            $scope.taskDetails.RecurEveryFrequencyType = null;
            $scope.taskDetails.TaskScheduleDate = null;
            $scope.taskDetails.TaskEffectiveDate = null;
            $scope.taskDetails.TaskEndDate = null;
            switch (modeId) {
                case '1':
                    $scope.taskDetails.TaskScheduleModeName = 'One Time';
                    break;
                case '2':
                    $scope.taskDetails.TaskScheduleModeName = 'Daily';
                    $scope.taskDetails.RecurEvery = 1;
                    $scope.taskDetails.RecurEveryFrequencyType = 'Day';
                    break;
                case '3':
                    $scope.taskDetails.TaskScheduleModeName = 'Weekly';
                    $scope.taskDetails.RecurEvery = 1;
                    $scope.taskDetails.RecurEveryFrequencyType = 'Week';
                    break;
                case '4':
                    $scope.taskDetails.TaskScheduleModeName = 'Monthly';
                    $scope.taskDetails.RecurEvery = 1;
                    $scope.taskDetails.RecurEveryFrequencyType = 'Month';
                    break;
            }
        };
    }
})();