﻿(function () {
    'use strict'
    angular.module('natApp')
      .directive('beAdditionalwyomingDirective', beAdditionalwyomingDirective);

    beAdditionalwyomingDirective.$inject = [];

    function beAdditionalwyomingDirective() {
        return {
            restrict: 'E',
            scope: {
                providerid: "=providerid",
                showheader: '=showheader',
                eventname: "=eventname",
                applicationId: "=applicationId",
                userId: '=userId',
                providerLicense: "=providerLicense",
                natValidation: "=natValidation",
                saveonsubmit: "=saveonsubmit",
                name: "=name"
            },
            templateUrl: "app/components/individual/businessentity/directives/be-additionalwyoming-directive/be-additionalwyoming-directive.html",
            controller: "BEAdditionalwyomingController"
        }
    }
})();