(function() {
    'use strict'
    angular.module('app.core')
        .directive('convertToNumber', convertToNumberDirective);

    convertToNumberDirective.$inject = [];

    function convertToNumberDirective() {
        return {
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function(val) {
                    if (val == "")
                        return val;
                    return parseInt(val, 10);
                });
                ngModel.$formatters.push(function(val) {
                    return '' + val;
                });
            }
        }
    }
})();