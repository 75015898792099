﻿(function () {
    'use strict'
    angular.module('app.backofficeAdministor').directive('referencetableFormDirective', function () {
        return {
            restrict: 'EA',
            scope: {
                reftableid: '=reftableid',
                FieldListDetails: '=fieldListDetails',
                edititem: '=edititem',
                type: '=type',
                oncancelclick: '&oncancelclick',
                onsavesuccess: '&onsavesuccess'
            },
            controller: "RefTableFormDirectiveController",
            templateUrl: "app/components/backoffice/administrator/referencetable/referencetable-form-directive/referencetable-form-template.html",
        };
    });
})();