(function() {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeIndividualResponsibleChargeController", BackofficeIndividualResponsibleChargeController);

    BackofficeIndividualResponsibleChargeController.$inject = ["$rootScope", "$scope", "$state", "backofficeIndividualResposibleChargeService",
        "countryStateService", "typeValuesService", "lookupService", "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl", "Messages"
    ];

    function BackofficeIndividualResponsibleChargeController($rootScope, $scope, $state, backofficeIndividualResposibleChargeService,
        countryStateService, typeValuesService, lookupService, utilityService, $exceptionHandler, TypeValue, WebApiUrl, Messages) {      
      
        //Tab Initilizer
        $scope.$on("individualTabChanged", function(evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "ResponsibleCharge" && !$rootScope.individualCurrentTab.isLoadedOnce) {
                $scope.IndividualId = individualId;
               
               // ShowLoader();               
                $scope.providerIdByIndividualId($scope.IndividualId);
                $rootScope.individualCurrentTab.isLoadedOnce = true; 
            }
        });

        $scope.saveResponsibleCharges = function (ProviderIndividual, responsibleCharge) {
            ShowLoader("Please Wait...");
            if ($scope.validateData(responsibleCharge)) {
                ProviderIndividual.IndividualId = $scope.IndividualId;
                ProviderIndividual.Responsible = true;
                backofficeIndividualResposibleChargeService.providerSave(sessionStorage.Key, [ProviderIndividual])
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.providerIdByIndividualId($scope.IndividualId);
                            $scope.clear();
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            }
            else {
                HideLoader();
            }
          
        }
        $scope.deleteProvider = function (ProviderIndividual) {
            if (confirm(Messages.General.Confirm.Delete)) {
                ShowLoader("Please wait...");
               
                ProviderIndividual.IsDeleted = true;
                backofficeIndividualResposibleChargeService.providerSave(sessionStorage.Key, [ProviderIndividual])
                    .then(function (response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse(response)) {
                            $scope.providerIdByIndividualId($scope.IndividualId);
                           // $scope.clear();
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });

            }
        };
        $scope.clear = function () {
            $scope.personalInfo.EmployerName = '';
            $scope.CompanyLicenseNumber = '';
        }

        $scope.providerIdByIndividualId = function(IndividualId) {
            ShowLoader("Please Wait...");
            try {
                backofficeIndividualResposibleChargeService.providerIdBy_IndividualId(sessionStorage.Key, IndividualId)
                    .then(function (response) {
                      
                        if ($scope.checkServerResponse(response)) {
                            if (response.ProviderIndividualList != null) {
                                $scope.ProviderIdList = [];
                                $scope.ProviderIdList = response.ProviderIndividualList;
                                if ($scope.ProviderIdList.length > 0) {
                                    $scope.ProviderDetails = [];
                                    angular.forEach($scope.ProviderIdList, function (value, index) {

                                        backofficeIndividualResposibleChargeService.providerDetails(sessionStorage.Key, value.ProviderId)
                                    .then(function (response) {
                                        if (response.ProviderResponse!=null) {
                                          var ProviderDetail = {
                                              ProviderName: response.ProviderResponse.ProviderName,
                                              LicenseNumber: response.ProviderResponse.LicenseNumber,
                                              IndividualId: value.IndividualId,
                                              ProviderId: value.ProviderId,
                                              Responsible: value.Responsible
                                            };
                                            $scope.ProviderDetails.push(ProviderDetail);
                                        }
                                    });
                                    })
                                                                   
                                }
                                HideLoader();
                            }
                            else {
                                $scope.ProviderIdList = [];
                                $scope.ProviderDetails = [];

                                HideLoader();
                            }                           
                        } else {
                            HideLoader();
                        }
                    }, function(data) {
                        HideLoader();
                        $scope.showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }
        $scope.loadTypeahed = function (searchString, searchType) {
           // var emloyerName = '';
           // var companyLicenseNumber = '';
           // if (searchType == 'Name') {
            //    emloyerName = searchString;
               // companyLicenseNumber = $scope.personalInfo.CompanyLicenseNumber;
          //  }
            //else if (searchType == 'LicenseNumber') {
            //    emloyerName = $scope.personalInfo.EmployerName;
            //    companyLicenseNumber = searchString;
            //}
           // $scope.sendCompanyInfoToParent(emloyerName, companyLicenseNumber, true);
           // $scope.DisableContactDetails = false;
            return backofficeIndividualResposibleChargeService.getEmployerList(
                sessionStorage.Key, searchString, 5, searchType
            ).then(function (response) {
                if (!!response.Employers) {
                    return response.Employers.map(function (item) {
                        item.formattedResult = (item.EmployerName + " " + item.StreetLine1 + " " + item.StreetLine2 + " " + item.City + " " + item.Zip);
                        return item;
                    });
                }
            }, function (res) {

            });
        };


        $scope.SelectTypeAheadItem = function (selectedItem, $model, $label, $event) { 
            $scope.CompanyLicenseNumber = selectedItem.LicenseNumber;
            $scope.selectedItems = selectedItem;
        };
      
       
    }
})();