(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .factory('EntityConfirmationService', ['$http', '$q', 'WebApiUrl', EntityConfirmationService]);

    function EntityConfirmationService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getEntityConfirmation = function (key, entityId,entityType, applicationId) {
            var urlParams = {
                EntityId: entityId,
                EntityType: entityType,
                ApplicationId: applicationId
            }

            return responseHandler($http.get(WebApiUrl + 'Entity/EntityConfirmation_Get/' + key + '/', { params: urlParams }));
        };

        var _saveEntityConfirmation = function (key, legalResponses) {
            return responseHandler($http.post(WebApiUrl + 'Entity/EntityConfirmation_Save/' + key + '/', legalResponses));
        };

        return {
            getEntityConfirmation: _getEntityConfirmation,
            saveEntityConfirmation: _saveEntityConfirmation
        };
    }
})();