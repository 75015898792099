﻿angular.module('natApp').factory('ChangePasswordService', ['$http', '$q', 'WebApiUrl', function ($http, $q, WebApiUrl) {
    return {
        ChangePassword: function (urlParm, reqdata) {
            ApiPath = 'User/ChangePassword' + urlParm;
            var deferred = $q.defer();
            $http.post(WebApiUrl + ApiPath, reqdata).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        },
        ChangePasswordAndLogin: function (urlParm, reqdata) {
            ApiPath = 'User/ChangePasswordAndLogin' + urlParm;
            var deferred = $q.defer();
            $http.post(WebApiUrl + ApiPath, reqdata).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        },
    };
}]);