(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .directive('applicationSpecialtiesValidation', ['Messages', ApplicationSpecialtiesValidation]);

    function ApplicationSpecialtiesValidation(Messages) {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                $scope.$on('$destroy', function () {
                    $scope.natValidation.showError = false;
                    $scope.natValidation.errorMessages = [];
                });

                $scope.validateForm = function () {
                    return true;
                };

                var checkForValues = function (except) {
                    var valid = true;
                    if (!except) {
                        except = [];
                    }
                    for (var certProp in $scope.current.specialty) {
                        var val = $scope.current.specialty[certProp];
                        //If it is a configured value
                        if(!!$scope.headers[certProp] && !except.includes(certProp)) {
                            var label = '';
                            if (!!$scope.uiConfig.FieldTableHeaders && !!$scope.uiConfig.FieldTableHeaders[certProp]) {
                                label = $scope.uiConfig.FieldTableHeaders[certProp].label;
                            } else  {
                                label = certProp;
                            }

                            if (typeof(val) === 'undefined' || val == null ||
                                (typeof(val) != 'boolean' && typeof(val) != 'number' && !val)) {
                                $scope.natValidation.showError = true;
                                $scope.natValidation.errorMessages.push({
                                    propName: certProp,
                                    errorMessage: Messages.General.Errors.IsRequired(label)
                                });
                                valid = false;
                            }
                        }
                    }

                    return valid;
                };

                $scope.validateSpecialty = function () {
                    var valid = true;
                    if (!$scope.natValidation) { $scope.natValidation = {}; }
                    
                    $scope.natValidation.showError = false;
                    $scope.natValidation.errorMessages = [];

                    valid = checkForValues(['SpecialityDateText']);

                    return valid;
                };
            }
        };
    }
})();