(function () {
  'use strict'

  angular.module('app.InitialApplication')
    .factory('initialApplicationChecklistService', initialApplicationChecklistService)

  initialApplicationChecklistService.$inject = ['$http', '$q', 'WebApiUrl']

  function initialApplicationChecklistService($http, $q, WebApiUrl) {

    return {

    };
  }
})();