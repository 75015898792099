(function () {
    'use strict'
    angular.module('app.report')
        .controller('DailyDepositReportController', DailyDepositReportController);

    DailyDepositReportController.$inject = ['$scope', '$q', '$controller', '$filter',
        'reportService', '$state', 'utilityService', 'ApiUrlsForReports', 'ApiUrlsForReportsXls'
    ];

    function DailyDepositReportController($scope, $q, $controller, $filter, reportService, $state, utilityService, ApiUrlsForReports, ApiUrlsForReportsXls) {
        $controller('GlobalController', { $scope: $scope })

        $scope.dateOptions = { format: 'MM/DD/YYYY' };

        // Pagination
        $scope.Pager = {
            sortType: 'InvoiceMaster_CreatedOn',
            sortOrder: 'desc',
            currentPage: 1,
            totalRecords: 10,
            pageSize: 20,
            maxSize: 5,
            FromDate: null,
            ToDate: null,
            PaymentMethod: 3,
            ModuleGroup: null,
            Mode: 5
        };

        $scope.Headers = [
            { name: 'Revenue Code', sortType: 'DetailCode', sortOrder: 'asc' },
            { name: 'Revenue Code Description', sortType: 'FeeDetailName', sortOrder: 'asc' },
            { name: 'Received Date', sortType: 'PaymentDate', sortOrder: 'asc' },
            { name: 'Transaction Date', sortType: 'InvoiceMaster_CreatedOn', sortOrder: 'asc' },    
            { name: 'Postmark Date', sortType: 'PostMarkDate', sortOrder: 'asc' },            
            { name: 'Receipt#', sortType: 'ReceiptNo', sortOrder: 'asc' },         
            { name: 'Invoice No.', sortType: 'InvoiceNumber', sortOrder: 'asc' }, 
            { name: 'Payment Amount', sortType: 'AmtPaid', sortOrder: 'asc' },
            { name: 'Payment Method', sortType: 'PaymentMethod', sortOrder: 'asc' },
            { name: 'Check No.', sortType: 'PaymentModeNumber', sortOrder: 'asc' },
            { name: 'Batch No.', sortType: 'BatchNum', sortOrder: 'asc' },
            { name: 'Deposit No.', sortType: 'BankDepositNo', sortOrder: 'asc' },
            { name: 'Deposit Date', sortType: 'BankDepositDate', sortOrder: 'asc' },
            { name: 'License Number', sortType: 'LicenseNo', sortOrder: 'asc' },
            { name: 'License Type', sortType: 'LicenseTypeName', sortOrder: 'asc' },
            { name: 'Licensee/Applicant', sortType: 'Name', sortOrder: 'asc' },
            { name: 'Staff Initials', sortType: 'InvoiceMaster_CreatedBy', sortOrder: 'asc' },
            { name: 'Comments', sortType: 'referencenumber', sortOrder: 'asc' }
        ];

        $scope.getDailyDepositReportList = function () {
            try {
                if ($scope.isSessionActive()) {
                    hideStatusMessage();
                    ShowLoader('Please Wait...');
                    var errorMessage = "";

                    if (!$scope.Pager.FromDate) {
                        errorMessage = "Please enter From date";
                    }

                    if (!$scope.Pager.ToDate) {
                        errorMessage = (!!errorMessage ? (errorMessage + "<br>") : "") + "Please enter To date";
                    }
                    $scope.errorMessage = errorMessage;
                    if (!!errorMessage) {
                        HideLoader();
                        return;
                    }
                    $scope.showListing = true;
                    reportService.dailyDepositReportWithPager(sessionStorage.Key, $scope.Pager, ApiUrlsForReports.dailyDeposit)
                        .then(function (response) {
                            if ($scope.checkResponse(response)) {
                                $scope.Pager.totalRecords = response.PagerVM.TotalRecords
                                $scope.DailyDepositList = response.PagerVM.Records
                            }
                            HideLoader()
                            utilityService.scrollTop()
                        }, function (data) {
                            HideLoader()
                            showStatusMessage(data, 'error')
                        })
                } else {
                    $state.go('app.Loginwoemail')
                }
            } catch (ex) {
                HideLoader()
                if (ex != null) { showStatusMessage(ex.message, 'error'); }
            }
        };
        //$scope.getDailyDepositReportList();

        $scope.SortRecords = function (header) {
            $scope.Pager.currentPage = 1;
            $scope.Pager.sortType = header.sortType;
            $scope.Pager.sortOrder = header.sortOrder;
            header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
            $scope.getDailyDepositReportList();
        };

        $scope.resetReport = function () {
            $scope.Pager.FromDate = null;
            $scope.Pager.ToDate = null;
            $scope.Pager.PaymentMethod = 3;
            $scope.Pager.ModuleGroup = null;
            $scope.getDailyDepositReportList();
        };

        $scope.getPdfFordepositReport = function () {
            // html2canvas(document.getElementById('Pdftable'), {
            //     onrendered: function (canvas) {
            //         var data = canvas.toDataURL();
            //         var width = canvas.width;
            //         var height = canvas.height;
            //         var docDefinition = {
            //             content: [{
            //                 image: data,
            //                 width: width,
            //                 height: height
            //             }]
            //         };
            //         pdfMake.createPdf(docDefinition).download("dailyDepositReport.pdf");
            //     }
            // });
            var hearders = [];
            _.each($scope.Headers, function (item) {
                hearders.push({ text: item.name, bold: false, fontSize: 10 });
            });
            var data = [];
            data.push(hearders);
            _.each($scope.DailyDepositList, function (dailyDeposit) {
                var s = [];

                if (dailyDeposit.InvoiceDetail_TransType)
                    s.push({ text: dailyDeposit.InvoiceDetail_TransType, bold: false, fontSize: 8 });
                else
                    s.push("")
                if (dailyDeposit.InvoiceDetail_Description)
                    s.push({ text: dailyDeposit.InvoiceDetail_Description, bold: false, fontSize: 8 });
                else
                    s.push("")
                if (dailyDeposit.InvoiceMaster_CreatedOn)
                    s.push({ text: dailyDeposit.InvoiceMaster_CreatedOn.split('T')[0], bold: false, fontSize: 8 });
                else
                    s.push("")
                if (dailyDeposit.InvoiceMaster_CreatedOn)
                    s.push({ text: dailyDeposit.InvoiceMaster_CreatedOn.split('T')[1], bold: false, fontSize: 8 });
                else
                    s.push("")
                if (dailyDeposit.UserDefinedRefNo)
                    s.push({ text: dailyDeposit.UserDefinedRefNo, bold: false, fontSize: 8 });
                else
                    s.push("")
                if (dailyDeposit.Quantity)
                    s.push({ text: dailyDeposit.Quantity, bold: false, fontSize: 8 });
                else
                    s.push("")
                if (dailyDeposit.Amount)
                    s.push({ text: dailyDeposit.Amount, bold: false, fontSize: 8 });
                else
                    s.push("")
                if (dailyDeposit.AmtPaid)
                    s.push({ text: dailyDeposit.AmtPaid, bold: false, fontSize: 8 });
                else
                    s.push("")
                // s.push(dailyDeposit.InvoiceDetail_TransType);
                // s.push(dailyDeposit.InvoiceDetail_Description);
                // s.push(dailyDeposit.InvoiceMaster_CreatedOn);
                //  s.push(dailyDeposit.InvoiceMaster_CreatedOn);
                // s.push(dailyDeposit.UserDefinedRefNo);
                // s.push(dailyDeposit.Quantity);
                // s.push(dailyDeposit.Amount);
                // s.push(dailyDeposit.AmtPaid);
                if (dailyDeposit.PaymentMethod)
                    s.push({ text: dailyDeposit.PaymentMethod, bold: false, fontSize: 8 });
                else
                    s.push("");
                if (dailyDeposit.paymentmodenumber)
                    s.push({ text: dailyDeposit.paymentmodenumber, bold: false, fontSize: 8 });
                else
                    s.push("");
                if (dailyDeposit.LicenseNo)
                    s.push({ text: dailyDeposit.LicenseNo, bold: false, fontSize: 8 });
                else
                    s.push("");
                if (dailyDeposit.Name)
                    s.push({ text: dailyDeposit.Name, bold: false, fontSize: 8 });
                else
                    s.push("");
                if (dailyDeposit.InvoiceMaster_CreatedBy)
                    s.push({ text: dailyDeposit.InvoiceMaster_CreatedBy, bold: false, fontSize: 8 });
                else
                    s.push("");
                if (dailyDeposit.referencenumber)
                    s.push({ text: dailyDeposit.referencenumber, bold: false, fontSize: 8 });
                else
                    s.push("");
                // s.push(dailyDeposit.paymentmodenumber);
                // s.push(dailyDeposit.LicenseNo);
                // s.push(dailyDeposit.Name);
                // s.push(dailyDeposit.InvoiceMaster_CreatedBy);
                // s.push(dailyDeposit.referencenumber);
                data.push(s);
            });
            var docDefinition = {
                pageSize: 'A5',

                // by default we use portrait, you can change it to landscape if you wish
                pageOrientation: 'landscape',

                // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                pageMargins: [40, 60, 40, 60],
                content: [
                    {
                        layout: 'lightHorizontalLines',
                        table: {
                            //    headerRows: 1,
                            widths: [20, 40, 50, 30, 30, 10, 10, 10, 20, 20, 20, 30, 30, 20],
                            body: data
                        }
                    }]
            };
            var win = window.open('', '_blank');
            //pdfMake.createPdf(docDefinition).open({}, win);
        };

        $scope.getExcelFordepositReport = function (reportType) {
            try {
                ShowLoader('Please Wait...')
                $scope.Pager.ReportType = reportType;
                reportService.getXLSForDailyDepositReport(sessionStorage.Key, $scope.Pager, ApiUrlsForReportsXls.dailyDeposit)
                    .then(function (response) {
                        HideLoader()
                    }, function (data) {
                        HideLoader()
                        showStatusMessage(data, 'error')
                    })
            } catch (ex) {
                HideLoader()
                if (ex != null) { showStatusMessage(ex.message, 'error'); }
            }
        }

        $scope.handleModule = function(mode,moduleEntry){
            var position = null;
            if(mode == true){
                if($scope.Pager.ModuleGroup == null) $scope.Pager.ModuleGroup = [];
                position = $scope.Pager.ModuleGroup.indexOf(moduleEntry);
                if(position == -1) $scope.Pager.ModuleGroup.push(moduleEntry);
            }
            else{
                position = $scope.Pager.ModuleGroup.indexOf(moduleEntry);
                if(position != -1) {
                    $scope.Pager.ModuleGroup.splice(position,1);
                    if($scope.Pager.ModuleGroup.length == 0) $scope.Pager.ModuleGroup = null;
                }
            } 
        }
    };
})();
