(function () {
    'use strict'

    angular.module('app.enforcement')
    .controller('ComplaintChangeLogController', ['$scope', '$rootScope', '$q', 'ComplaintChangeLogService', 'utilityService', 'Messages', ComplaintChangeLogController]);

    function ComplaintChangeLogController($scope, $rootScope, $q, ComplaintChangeLogService, utilityService, Messages) {
        var _key = sessionStorage.Key;
        var _childName = '';

        var getConfig = function () {
            var deferred = $q.defer();
        
            try {
                utilityService.getConfigData('/components/backoffice/enforcement/complaint-change-log/complaint-change-log.server-config.json')
                .then(function(data) {
                    if (!!data) {
                        $scope.config = data;
        
                        $scope.uiConfig = $rootScope.getUIConfig($scope.configName, $scope.config);
        
                        $scope.isConfigLoaded = true;

                        $scope.tableHeaders = $scope.uiConfig.TableHeaders;

                        if (!!$scope.getConfigExt) {
                            $scope.getConfigExt(data);
                        }
                    }
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
        
            return deferred.promise;
        };

        $scope.getComplaintChangeLog = function () {
            var deferred = $q.defer();
            
            try {
                var request = {
                    ComplaintId: $scope.complaint.ComplaintId,
                    ComplaintCaseId: $scope.complaintCaseId,
                    QueueName: $scope.queueName
                };

                ComplaintChangeLogService.getComplaintChangeLog(sessionStorage.Key, request)
                .then(function(data) {
                    if (data.Status) {
                        $scope.complaintChangeLogList = data.ComplaintChangeLogList;

                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.toggleRowDetailsClicked = function (item, name) {
            if (item.rowDetails == name) {
                item.rowDetails = '';
            } else {
                item.rowDetails = name;
            }

            item.rowClass = {
                'row-expanded': !!item.rowDetails
            };
        };

        $scope.closeRowDetailsClicked = function (item) {
            item.rowDetails = '';
            item.rowClass = {
                'row-expanded': !!item.rowDetails
            };
        };

        $scope.init = function () {
            $scope.current = {
                complaintChangeLog: null
            };
            
            $scope.pager = {};
            $scope.tableHeaders = {};
            $scope.complaintCaseList = [];
            $scope.involvedPartyList = [];

            getConfig()
            .then(function () {
                $q.all([
                    $scope.getComplaintChangeLog()
                ])
                .then(function () {

                    $scope.operationPending(false);
                }, function () {
                    $scope.operationPending(false);
                });
            }, function () {
                $scope.showStatusMsg('-', 'Unable to retrieve configuration.');
                $scope.operationPending(false);
            });

            if (!!$scope.initExt) {
                $scope.initExt();
            }
        };

        $scope.$watch('complaintChangeLogList', function (newVal) {
            $scope.currentChangeLog = newVal;
        });
    }
})();
