(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .directive('cdsCmeValidation', CdsCmeValidation);

    CdsCmeValidation.$inject = ['Messages'];

    function CdsCmeValidation(Messages) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function () {
                    $scope.natValidation.errorMessages = [];
                    if (!!$scope.currentForm) {
                        $scope.currentForm.$submitted = true;
                        $scope.natValidation.errorMessages = angular.copy($scope.currentForm.errorMessages);
                        if (!$scope.natValidation.errorMessages) {
                            $scope.natValidation.errorMessages = [];
                        }
                    }

                    if ($scope.uiConfig.validateApplyForExemption) {
                        if (!((!!$scope.current.individualCeInfo.CMEBest && !!$scope.current.individualCeInfo.CMEChronicPain &&
                                    !!$scope.current.individualCeInfo.CMEDiversion && !!$scope.current.individualCeInfo.CMEAddiction &&
                                    !$scope.current.individualCeInfo.IsExtensionRequested) ||
                                (!$scope.current.individualCeInfo.CMEBest && !$scope.current.individualCeInfo.CMEChronicPain &&
                                    !$scope.current.individualCeInfo.CMEDiversion && !$scope.current.individualCeInfo.CMEAddiction &&
                                    !!$scope.current.individualCeInfo.IsExtensionRequested))) {
                            $scope.natValidation.errorMessages.push({
                                propName: 'cdsCmeEitherFirstFourOrExemption',
                                errorMessage: Messages.General.Errors.EitherExcemption
                            });
                        }
                    }

                    if (!$scope.isBackoffice && (!$scope.documentUpload || !$scope.documentUpload.documentCount || $scope.documentUpload.documentCount == 0)) {
                        $scope.natValidation.errorMessages.push({
                            propName: 'atleastOneDocumentRequired',
                            errorMessage: Messages.General.Errors.OneDoc
                        });
                    }

                    if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                        $scope.natValidation.showError = true;
                        $scope.natValidation.isValid = false;
                        return false;
                    } else {
                        $scope.natValidation.showError = false;
                        $scope.natValidation.isValid = true;
                        return true;
                    }
                };
            }
        };
    }
})();