(function () {
    'use strict'

    angular.module('app.lga')
        .factory('LGARequestFormFactory', ['$http', '$q', 'WebApiUrl', 'AppConfig', function ($http, $q, WebApiUrl, AppConfig) {

            var _usersSearch = function (key, PageNumber, NoOfRecords, data) {
                return returnDefferedResult($http.post(WebApiUrl + 'Users/UsersSearch/' + key + '?PageNumber=' + PageNumber + '&NoOfRecords=' + NoOfRecords, data));
            };
            var _governmentAgencySave = function (key, Action, UserId, data) {
                return returnDefferedResult($http.post(WebApiUrl + 'Agency/GovernmentAgencySave/' + key + '?Action=' + Action + '&UserId=' + UserId, data));
            };
            var _governmentAgencyDeptSave = function (key, Action, UserId, data) {
                return returnDefferedResult($http.post(WebApiUrl + 'Agency/GovernmentAgencyDeptSave/' + key + '?Action=' + Action + '&UserId=' + UserId, data));
            };
            var _governmentAgencyAddressContactSave = function (key, Action, UserId, data) {
                return returnDefferedResult($http.post(WebApiUrl + 'Agency/GovernmentAgencyAddressContactSave/' + key + '?Action=' + Action + '&UserId=' + UserId, data));
            };
            var _governmentAgencyUserSave = function (key, data) {
                return returnDefferedResult($http.post(WebApiUrl + 'Users/GovernmentAgencyUserSave/' + key, data));
            };
            var _getAgencyUserInfo = function (key, email, userId) {
                return returnDefferedResult($http.get(WebApiUrl + 'Agency/GetAgencyUserInfo/' + key + '?Email=' + (!email ? '' : email) + '&UserId=' + (!userId ? '' : userId)));
            };
            var _setAgencyUserStatus = function (param) {
                return returnDefferedResult($http.post(WebApiUrl + 'Agency/SetAgencyUserStatus?Source=' + 'ALFSB', param));
            };

            var _sendBBPRegistrationEmail = function (key, data) {
                var data = $http.post(WebApiUrl + 'Agency/SendBBPRegistrationEmail/' + key, data);
                return returnDefferedResult(data);
            };

            var returnDefferedResult = function (request) {
                var deferred = $q.defer();
                request.then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.reject(response);
                });
                return deferred.promise;
            };
            return {
                UsersSearch: _usersSearch,
                GovernmentAgencySave: _governmentAgencySave,
                GovernmentAgencyDeptSave: _governmentAgencyDeptSave,
                GovernmentAgencyAddressContactSave: _governmentAgencyAddressContactSave,
                GovernmentAgencyUserSave: _governmentAgencyUserSave,
                GetAgencyUserInfo: _getAgencyUserInfo,
                SetAgencyUserStatus: _setAgencyUserStatus,
                SendBBPRegistrationEmail: _sendBBPRegistrationEmail
            };
        }]);
})();