(function () {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeProviderDCHInformationController", BackofficeProviderDCHInformationController);

    BackofficeProviderDCHInformationController.$inject = ["$rootScope", "$scope", "$state", "backofficeProvideDCHInformationService", "utilityService", "$exceptionHandler", "WebApiUrl", "$q", "countryStateService"];

    function BackofficeProviderDCHInformationController($rootScope, $scope, $state, backofficeProvideDCHInformationService, utilityService, $exceptionHandler, WebApiUrl, $q, countryStateService) {

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, ProviderId) {
            if ($rootScope.individualFirmCurrentTab.name == "firminformation") {
                $scope.ProviderId = ProviderId;
                if ($scope.ProviderId) {
                    $scope.DCHData = {};
                    GetDCHInformation();
                }
            }
        });

        $scope.SaveDCHInformation = function (form) {
            $scope.DchLicenseError = undefined;
            if ($scope.validateData(form)) {
                ShowLoader();
                if (tempOldlId != $scope.DCHData.LicenseNumber && $scope.DCHData.LicensedIssuedStateCd.toUpperCase() == 'WY') {
                    //get indv by license if indv found then save providerindiindiv
                    $scope.SearchLicense().then(function (res) {
                        var sres = res.data.DCHInformationList[0];
                        var pindvList = [];
                        var pindv = {
                            ProviderId: $scope.ProviderId,
                            IndividualId: sres.IndividualId,
                            Responsible: true,
                            CreatedBy: sessionStorage.UserID,
                            CreatedOn: new Date(),
                            ModifiedBy: sessionStorage.UserID,
                            ModifiedOn: new Date(),
                            IsDeleted: false
                        };
                        pindvList.push(pindv);

                        SaveProviderIndividual(pindvList).then(function () {
                            HideLoader();
                        }, function (res) {
                            HideLoader();
                        });
                    }, function () {
                        HideLoader();
                        $scope.DchLicenseError = 'DCH License# entered is not found. Please check DCH License# entered.';
                    });
                } else {
                    //if existing individualid then deactivate here
                    ///////////issue ownership table inserts duplicate record/////////////////////////////Read me///
                    if (!IsDCHOwnerShipRecord && $scope.DCHData.IndividualId) {
                        var editindv = {
                            ProviderId: $scope.ProviderId,
                            IndividualId: $scope.DCHData.IndividualId,
                            Responsible: false,
                            CreatedBy: sessionStorage.UserID,
                            CreatedOn: new Date(),
                            ModifiedBy: sessionStorage.UserID,
                            ModifiedOn: new Date(),
                            IsDeleted: true
                        };
                        SaveProviderIndividual([editindv])
                        backofficeProvideDCHInformationService.GetProviderOwnership(sessionStorage.Key, $scope.ProviderId).then(function (res) {
                            if (res.data.Status && res.data.ProviderOwnershipList) {
                                var dcList = res.data.ProviderOwnershipList;
                                if (dcList && dcList.length > 0) {
                                    $scope.DCHData.ProvIndvNameOwnershipId = dcList[0].ProvIndvNameOwnershipId;
                                    $scope.DCHData.CreatedBy = dcList[0].CreatedBy;
                                    $scope.DCHData.IsActive = true;
                                    $scope.DCHData.IsDeleted = false;
                                    $scope.DCHData.ProviderId = $scope.ProviderId;
                                    if (dcList[0].ApplicationId) {
                                        $scope.DCHData.ApplicationId = dcList[0].ApplicationId;
                                    }
                                    FinalSaveOwnershipInfo();
                                }
                            }
                        });
                    } else {
                        FinalSaveOwnershipInfo();
                    }
                }
            }
        };

        var FinalSaveOwnershipInfo = function () {
            //prepare DCH data
            var dchlistData = [];
            if (tempOldlId != $scope.DCHData.LicenseNumber || IsDCHOwnerShipRecord) {
                $scope.DCHData.IndividualId = undefined;
                if ($scope.DCHData.ProvIndvNameOwnershipId) {
                    $scope.DCHData.ModifiedBy = sessionStorage.UserID;
                } else {
                    $scope.DCHData.CreatedBy = sessionStorage.UserID;
                    $scope.DCHData.IsActive = true;
                    $scope.DCHData.IsDeleted = false;
                    $scope.DCHData.ProviderId = $scope.ProviderId;
                }
                dchlistData.splice(0, 0, $scope.DCHData);
            }
            SaveProviderOwnership(dchlistData).then(function () {
                HideLoader();
            }, function (res) {
                HideLoader();
            });
        };

        var tempOldlId = '';

        var GetDCHInformation = function () {
            backofficeProvideDCHInformationService.GetDCHInformation(sessionStorage.Key, $scope.ProviderId).then(function (res) {
                if (res.data.Status && res.data.DCHInformationList && res.data.DCHInformationList.length > 0) {
                    $scope.DCHData = res.data.DCHInformationList[0];
                    $scope.DCHData.LicensedIssuedStateCd = $scope.DCHData.ActivelyLicensedIn;
                    tempOldlId = $scope.DCHData.LicenseNumber;
                } else {
                    console.log('called3');
                    GetProviderOwnership();
                }
            });
        };

        $scope.SearchLicense = function () {
            var deferred = $q.defer();
            if ($scope.DCHData.LicenseNumber && $scope.DCHData.LicensedIssuedStateCd.toUpperCase() == 'WY') {
                backofficeProvideDCHInformationService.GetDCHInformation(sessionStorage.Key, $scope.ProviderId, $scope.DCHData.LicenseNumber).then(function (res) {
                    if (res.data.Status && res.data.DCHInformationList && res.data.DCHInformationList.length > 0) {
                        deferred.resolve(res);
                    } else {
                        deferred.reject({});
                    }
                });
            } else {
                deferred.reject({});
            }
            return deferred.promise;
        };

        var SaveProviderIndividual = function (arrayItems) {
            var deferred = $q.defer();
            backofficeProvideDCHInformationService.SaveProviderIndividual(sessionStorage.Key, arrayItems).then(function (res) {
                if (res.data.Status) {
                    GetDCHInformation();
                    deferred.resolve(res);
                } else {
                    deferred.reject(res);
                }
            });
            return deferred.promise;
        };

        var IsDCHOwnerShipRecord = false;
        var GetProviderOwnership = function () {
            IsDCHOwnerShipRecord = true;
            backofficeProvideDCHInformationService.GetProviderOwnership(sessionStorage.Key, $scope.ProviderId).then(function (res) {
                if (res.data.Status && res.data.ProviderOwnershipList) {
                    var dcList = $.grep(res.data.ProviderOwnershipList, function (i) {
                        return (i.ProviderId == $scope.ProviderId);
                    });
                    if (dcList && dcList.length > 0) {
                        $scope.DCHData = dcList[0];
                        tempOldlId = $scope.DCHData.LicenseNumber;
                    }
                }
            });
        };

        var SaveProviderOwnership = function (data) {
            var deferred = $q.defer();
            if (data && data.length > 0) {
                backofficeProvideDCHInformationService.SaveProviderOwnership(sessionStorage.Key, data).then(function (res) {
                    if (res.data.Status) {
                        deferred.resolve(res);
                    } else {
                        deferred.reject(res);
                    }
                });
            } else {
                deferred.resolve({});
            }
            return deferred.promise;
        };

        var GetStateList = function () {
            var deferred = $q.defer();
            try {
                countryStateService.getStateByCountryID(sessionStorage.Key, $rootScope.DefaultCountryId).then(function (res) {
                    if (res.Status) {
                        $scope.StateList = res.State;
                    }
                    deferred.resolve(res);
                }, function (err) {
                    //console.log('response', res);
                    deferred.reject(err);
                });
            }
            catch (ex) {
                //console.log('Exception GetStateList()', ex);
                deferred.reject(ex);
            }
            return deferred.promise;
        };
        GetStateList();
    }
})();