﻿(function () {
    'use strict'
    angular.module('app.core')
        .directive('secureCommunicationDirective', [function () {
        return {
            restrict: 'EA',
            scope: {
                individualid: '=?individualid',
                isstaff: '=?isstaff',
                isbackoffice: '=?isbackoffice',
                displayarea:'=displayarea',
                displaysendemail: '=?displaysendemail',
                displayheader: '=?displayheader',
                sendsms:"=?sendsms",
                sendemailnotification: "=?sendemailnotification",
                mailboxtype: "=?mailboxtype",
                additionalhclass: "=?additionalhclass",
                CommunicationType: '=?communicationtype'
            },
            controller: "SecureCommunicationDirectiveController",
            templateUrl: "app/core/directive/secure-communication-directive/secure-communication-directive-template.html",
        };
    }]);
})();
