﻿(function() {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeProviderDisiplineService', backofficeProviderDisiplineService)

    backofficeProviderDisiplineService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeProviderDisiplineService($http, $q, WebApiUrl, AppConfig) {


        // var _saveDisipline = function(key, DisiplineData) {
        //     var deferred = $q.defer();
        //     $http.post(WebApiUrl + 'Provider/SaveProviderOther/' + key + '?Source=', DisiplineData)
        //         .then(function(data){ data = data.data;
        //             deferred.resolve(data);
        //         }, function(error) { error = error.data;
        //             deferred.reject(error);
        //         });
        //     return deferred.promise;
        // };
        // var _GetDisipline = function(key, ProviderId) {
        //     var deferred = $q.defer();
        //     $http.get(WebApiUrl + "Provider/GetProviderOther/" + key + '?ProviderId=' + ProviderId + '&ApplicationId=')
        //         .then(function(data){ data = data.data;
        //             deferred.resolve(data);
        //         }, function(error) { error = error.data;
        //             deferred.reject(error);
        //         });
        //     return deferred.promise;
        // };
        var _GetDisipline = function(Key, IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Provider/GetProviderDiscipline/' + Key + '?ProviderDisciplineId=null&ProviderId=' + IndividualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveDisipline = function(Key, data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/SaveProviderDiscipline/' + Key + '?Source=' + AppConfig.Source, data)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            saveDisipline: _saveDisipline,
            GetDisipline: _GetDisipline,

        }
    }
})();