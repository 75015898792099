(function() {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("StandardsPracticeSurveyingController", StandardsPracticeSurveyingController);

    StandardsPracticeSurveyingController.$inject = ["$rootScope", "$scope",
        "initialApplicationCPCEducationService", "initialApplicationstatusService", "utilityService", "typeValuesService", "WebApiUrl", "TypeValue", "contentService", "countryStateService", "multipleFileUploadService", "Messages"
    ];

    function StandardsPracticeSurveyingController($rootScope, $scope,
        initialApplicationCPCEducationService, initialApplicationstatusService, utilityService, typeValuesService, WebApiUrl, TypeValue, contentService, countryStateService, multipleFileUploadService, Messages) {
        $scope.documentByIndividualDocumentId = multipleFileUploadService.documentByIndividualDocumentId;

        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: "M!/d!/yyyy",
            dateOptions: {
                ngModelOptions: {
                    allowInvalid: true
                }
            }
        };
        $scope.documentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.individualId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "FR",
            LoadByDocIdAndDocCode: true,
            DocId: 2,
            DocCode: "D1002",
            LoadDocumentsWithoutBroadCast: true
        };
        $scope.documentsList = [];

        $scope.init = function() {
            $scope.minimumTotalHoursReq = 30;
            $scope.minimumTotalHoursReqForBothIndividually = 10;
            $scope.maximumCarryOver = 15;
            $scope.viewConfig = {};
            processCEHHours();
            getReciProcityStates();
        };

        function getReciProcityStates() {
            typeValuesService.getTypeValuesByTypeName(TypeValue.RefReciprocityState, sessionStorage.Key, false, true)
                .then(function(response) {
                    if (!response.Status) {
                        $scope.showStatusMessage(response.message, "error");
                        return
                    }
                    $scope.reciprocityStateList = response.RefReciprocityStateGetList;
                }, function(data) {
                    if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                });
            countryStateService.getStateByCountryID(sessionStorage.Key, $rootScope.DefaultCountryId).then(function(response) {
                $scope.statList = response.State;
            }, function(error) {

            });

            typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=cecourseactivitytype")).then(function(reponse) {
                $scope.cecourseactivitytype = reponse.ReferenceEntities;
            }, function(error) {

            });
        };

        function processCEHHours() {
            angular.forEach($scope.individualCEH, function(cehHour) {
                if (cehHour.LicenseType.toLowerCase() == 'pe') {
                    $scope.peCEHHours = cehHour;
                } else if (cehHour.LicenseType.toLowerCase() == 'ls') {
                    $scope.lsCEHHours = cehHour;
                }
            });
            if (!$scope.peCEHHours) {
                $scope.peCEHHours = {
                    CECarryInHours: 0
                };
            }

            if (!$scope.lsCEHHours) {
                $scope.lsCEHHours = {
                    CECarryInHours: 0
                };
            }
        };

        var processIndividualLicenseReciprocity = function() {
            if (!!$scope.individualLicenseReciprocity && $scope.individualLicenseReciprocity.length > 0) {
                $scope.individualLicenseReciprocityData = $scope.individualLicenseReciprocity[0];
            } else {
                $scope.individualLicenseReciprocityData = {
                    IndividualId: $scope.individualId,
                    IndividualLicenseId: $scope.individualLicense.IndividualLicenseId,
                    ApplicationId: $scope.applicationId,
                    LicenseTypeId: $scope.individualLicense.LicenseTypeId,
                    Reciprocity: true,
                    IsActive: true
                };
            }
        };
        var processIndividualLicenseStatusSubTypeReason = function() {
            angular.forEach($scope.individualLicenseStatusSubTypeReason, function(licenseReason) {
                if (licenseReason.ContentItemLkId == 145) {
                    $scope.individualLicenseStatusSubTypeReasonMilitary = licenseReason;
                } else if (licenseReason.ContentItemLkId == 144) {
                    $scope.individualLicenseStatusSubTypeReasonFirstTime = licenseReason;
                } else if (licenseReason.ContentItemLkId == 147) {
                    $scope.individualLicenseStatusSubTypeReasonComity = licenseReason;
                } else if (licenseReason.ContentItemLkId == 146) {
                    $scope.individualLicenseStatusSubTypeReasonPhysical = licenseReason;
                }
            });
            if (!$scope.individualLicenseStatusSubTypeReasonMilitary) {
                $scope.individualLicenseStatusSubTypeReasonMilitary = getIndividualLicenseStatusSubTypeReason(1, 145, 2);
            }
            if (!$scope.individualLicenseStatusSubTypeReasonFirstTime) {
                $scope.individualLicenseStatusSubTypeReasonFirstTime = getIndividualLicenseStatusSubTypeReason(2, 144, 1);
            }
            if (!$scope.individualLicenseStatusSubTypeReasonComity) {
                $scope.individualLicenseStatusSubTypeReasonComity = getIndividualLicenseStatusSubTypeReason(3, 147, 4);
            }
            if (!$scope.individualLicenseStatusSubTypeReasonPhysical) {
                $scope.individualLicenseStatusSubTypeReasonPhysical = getIndividualLicenseStatusSubTypeReason(4, 146, 3);
            }
        };

        var getIndividualLicenseStatusSubTypeReason = function(statusReasonTypeId, contentItemLkId, contentItemHash) {
            var statusReasonType = {
                IndividualLicenseId: $scope.individualLicense.IndividualLicenseId,
                LicenseStatusSubTypeReasonId: statusReasonTypeId,
                ContentItemLkId: contentItemLkId,
                ContentItemHash: contentItemHash,
                IsActive: true
            }
            return statusReasonType;
        };

        $scope.setForm = function(currentForm) {
            $scope.currentForm = currentForm;
        };

        $scope.reciprocityStateChanged = function() {
            $scope.viewConfig.showCommityStateNotSameASMailingState = false;
            if (!!$scope.personalData) {
                $scope.individualLicenseReciprocityData.ReciprocityStateCd = getStateCodeById($scope.individualLicenseReciprocityData.ReciprocityStateId);
                if (!!$scope.personalData.EmployerAddress && !!$scope.personalData.EmployerAddress.IsMailingSameasPhysical) {
                    if ($scope.personalData.EmployerAddress.StateCode != $scope.individualLicenseReciprocityData.ReciprocityStateCd) {
                        $scope.viewConfig.showCommityStateNotSameASMailingState = true;
                    }
                } else if (!!$scope.personalData.ResidentialAddress && !!$scope.personalData.ResidentialAddress.IsMailingSameasPhysical) {
                    if ($scope.personalData.ResidentialAddress.StateCode != $scope.individualLicenseReciprocityData.ReciprocityStateCd) {
                        $scope.viewConfig.showCommityStateNotSameASMailingState = true;
                    }
                }
            }
        };

        var validateForm = function(currentForm, val) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                if (!!currentForm.errorMessages)
                    $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            }
            if (val) {
                var d = new Date().getFullYear();
                if ($scope.courseTypeId == 2) {
                    var total = 0;
                    angular.forEach($scope.individualOutOfStateLicList, function(item) {
                        if (item.CECourseTypeId == $scope.courseTypeId) {
                            if (item.CECourseDate && (new Date(item.CECourseDate).getFullYear() == d || new Date(item.CECourseDate).getFullYear() == 2019))
                                total += Number(item.CECourseHours);
                        }
                    });
                    if (!$scope.natValidation.errorMessages) {
                        $scope.natValidation.errorMessages = [];
                    }

                    if (total < 4) {
                        var errorObj = {
                            propName: "totalhoursIsuue",
                            errorMessage: Messages.General.Errors.References
                        };
                        $scope.natValidation.errorMessages.push(errorObj);
                    }

                }
                if ($scope.courseTypeId == 3) {
                    var currentYearExist = false;
                    angular.forEach($scope.individualOutOfStateLicList, function(item) {
                        if (item.CECourseTypeId == $scope.courseTypeId) {
                            if (item.CECourseDate && (new Date(item.CECourseDate).getFullYear() == d)) {
                                currentYearExist = true;
                            }
                        }
                    });
                    if (!$scope.natValidation.errorMessages) {
                        $scope.natValidation.errorMessages = [];
                    }

                    if (!currentYearExist) {
                        var errorObj = {
                            propName: "recordIssue",
                            errorMessage: Messages.General.Errors.References
                        };
                        $scope.natValidation.errorMessages.push(errorObj);
                    }

                }
            }
            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.getTotalCpc = function(cpcEducationItem) {
            cpcEducationItem.TotalCPC = ((!!cpcEducationItem.CECarryInHours) ? cpcEducationItem.CECarryInHours : 0) + (1 * ((!!cpcEducationItem.CECurrentReportedHours) ? cpcEducationItem.CECurrentReportedHours : 0));
            return cpcEducationItem.TotalCPC;
        };

        $scope.getCarryOverForNextRenewalPeriod = function(cpcEducationItem) {
            var minimumTotalHoursReq = 0;
            if ($scope.individualLicense.LicenseTypeId == 2 || $scope.individualLicense.LicenseTypeId == 4) {
                var cpcHoursToCompare = 0;
                if ($scope.individualLicense.applicationType == 'exempt' && (!!$scope.individualLicense.LicenseStatusSubType && !!$scope.individualLicense.LicenseStatusSubType.LicenseStatusSubTypeId && $scope.individualLicense.LicenseStatusSubType.LicenseStatusSubTypeId == 2)) {
                    cpcHoursToCompare = cpcEducationItem.CECurrentReportedHours;
                } else {
                    cpcHoursToCompare = cpcEducationItem.TotalCPC;
                    minimumTotalHoursReq = $scope.minimumTotalHoursReq
                }
                if (!!cpcHoursToCompare && cpcHoursToCompare > minimumTotalHoursReq) {
                    var carryOver = (cpcHoursToCompare - minimumTotalHoursReq).toFixed(2);
                    //var carryOver = cpcHoursToCompare - minimumTotalHoursReq;
                    if (carryOver <= $scope.maximumCarryOver) {
                        cpcEducationItem.CERolloverHours = carryOver;
                    } else {
                        cpcEducationItem.CERolloverHours = $scope.maximumCarryOver;
                    }
                } else {
                    cpcEducationItem.CERolloverHours = 0;
                }
                return cpcEducationItem.CERolloverHours;
            } else {
                var lsCEHHoursToCompare = 0;
                var peCEHHoursToCompare = 0;
                if ($scope.individualLicense.applicationType == 'exempt' && (!!$scope.individualLicense.LicenseStatusSubType && !!$scope.individualLicense.LicenseStatusSubType.LicenseStatusSubTypeId && $scope.individualLicense.LicenseStatusSubType.LicenseStatusSubTypeId == 2)) {
                    lsCEHHoursToCompare = $scope.lsCEHHours.CECurrentReportedHours;
                    peCEHHoursToCompare = $scope.peCEHHours.CECurrentReportedHours;
                } else {
                    lsCEHHoursToCompare = $scope.lsCEHHours.TotalCPC;
                    peCEHHoursToCompare = $scope.peCEHHours.TotalCPC;
                    minimumTotalHoursReq = $scope.minimumTotalHoursReq
                }
                var totalCPCPEndLS = (!!lsCEHHoursToCompare ? (1 * lsCEHHoursToCompare) : 0) +
                    (!!peCEHHoursToCompare ? (1 * peCEHHoursToCompare) : 0);
                var carryOverIndividual = 0;
                if (totalCPCPEndLS > minimumTotalHoursReq) {
                    var carryOver = (totalCPCPEndLS - minimumTotalHoursReq) / 2;
                    if (carryOver <= ($scope.maximumCarryOver / 2)) {
                        $scope.lsCEHHours.CERolloverHours = carryOver;
                        $scope.peCEHHours.CERolloverHours = carryOver;
                    } else {
                        carryOver = ($scope.maximumCarryOver / 2);
                        $scope.lsCEHHours.CERolloverHours = ($scope.maximumCarryOver / 2);
                        $scope.peCEHHours.CERolloverHours = ($scope.maximumCarryOver / 2);
                    }
                    carryOverIndividual = carryOver;
                } else {
                    $scope.lsCEHHours.CERolloverHours = 0;
                    $scope.peCEHHours.CERolloverHours = 0;
                }
                return carryOverIndividual;
            }
        };

        var ShowErrMsg = function(propName, errorMessage) {
            propName = (!!propName) ? propName : "cpcEducationErr";
            errorMessage = (!!errorMessage) ? errorMessage : $scope.cpcEducationConfig.ValidationMsg.APIError;
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            var propExist = false;
            for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                if ($scope.natValidation.errorMessages[i].propName == propName)
                    propExist = true;
            }
            if (!propExist)
                $scope.natValidation.errorMessages.push({
                    propName: propName,
                    errorMessage: errorMessage
                });

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.getCPCEducationConfig = function() {
            var relativeFilePath = "/individual/application/cpc-education/cpc-education.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function(data) {
                    $scope.cpcEducationConfig = data;
                    $scope.isConfigLoaded = true;
                }, function(error) {
                    ShowErrMsg("cpcEducationConfig", "Some error occurred,Unable to load the Continuing Professional Competency config")
                });
        };

        $scope.saveContinuingEducation = function(currentForm) {
            ShowLoader("Please wait...");
            if (!validateForm(currentForm)) {
                HideLoader();
                return;
            }

        };

        var assignIdsToTypeReasons = function(individualLicenseStatusSubTypeReason) {
            individualLicenseStatusSubTypeReason.IndividualLicenseStatusSubTypeId = ((!!$scope.individualLicense.LicenseStatusSubType) ? $scope.individualLicense.LicenseStatusSubType.IndividualLicenseStatusSubTypeId : '');
            individualLicenseStatusSubTypeReason.LicenseStatusTypeId = $scope.individualLicense.LicenseStatusTypeId;
            individualLicenseStatusSubTypeReason.LicenseStatusSubTypeId = ((!!$scope.individualLicense.LicenseStatusSubType) ? $scope.individualLicense.LicenseStatusSubType.LicenseStatusSubTypeId : '');
        };

        var getLicenseStatusSubTypeReasonsByProcesing = function() {
            if ($scope.individualLicense.LicenseStatusSubType.LicenseStatusSubTypeId == 1) {
                $scope.individualLicenseStatusSubTypeReasonMilitary.ContentItemResponse = true;
                $scope.individualLicenseStatusSubTypeReasonFirstTime.ContentItemResponse = false;
                $scope.individualLicenseStatusSubTypeReasonComity.ContentItemResponse = false;
                $scope.individualLicenseStatusSubTypeReasonPhysical.ContentItemResponse = false;
            } else if ($scope.individualLicense.LicenseStatusSubType.LicenseStatusSubTypeId == 2) {
                $scope.individualLicenseStatusSubTypeReasonMilitary.ContentItemResponse = false;
                $scope.individualLicenseStatusSubTypeReasonFirstTime.ContentItemResponse = true;
                $scope.individualLicenseStatusSubTypeReasonComity.ContentItemResponse = false;
                $scope.individualLicenseStatusSubTypeReasonPhysical.ContentItemResponse = false;
            } else if ($scope.individualLicense.LicenseStatusSubType.LicenseStatusSubTypeId == 3) {
                $scope.individualLicenseStatusSubTypeReasonMilitary.ContentItemResponse = false;
                $scope.individualLicenseStatusSubTypeReasonFirstTime.ContentItemResponse = false;
                $scope.individualLicenseStatusSubTypeReasonComity.ContentItemResponse = true;
                $scope.individualLicenseStatusSubTypeReasonPhysical.ContentItemResponse = false;
            } else if ($scope.individualLicense.LicenseStatusSubType.LicenseStatusSubTypeId == 4) {
                $scope.individualLicenseStatusSubTypeReasonMilitary.ContentItemResponse = false;
                $scope.individualLicenseStatusSubTypeReasonFirstTime.ContentItemResponse = false;
                $scope.individualLicenseStatusSubTypeReasonComity.ContentItemResponse = false;
                $scope.individualLicenseStatusSubTypeReasonPhysical.ContentItemResponse = true;
            }
            assignIdsToTypeReasons($scope.individualLicenseStatusSubTypeReasonMilitary);
            assignIdsToTypeReasons($scope.individualLicenseStatusSubTypeReasonFirstTime);
            assignIdsToTypeReasons($scope.individualLicenseStatusSubTypeReasonComity);
            assignIdsToTypeReasons($scope.individualLicenseStatusSubTypeReasonPhysical);

            var licenseStatusSubTypeReasons = [$scope.individualLicenseStatusSubTypeReasonMilitary,
                $scope.individualLicenseStatusSubTypeReasonComity,
                $scope.individualLicenseStatusSubTypeReasonFirstTime,
                $scope.individualLicenseStatusSubTypeReasonPhysical
            ];
            return licenseStatusSubTypeReasons;
        };

        var getStateCodeById = function(reciprocityStateId) {
            var stateCode = '';
            angular.forEach($scope.reciprocityStateList, function(reciprocityState) {
                if (reciprocityState.ReciprocityStateId == reciprocityStateId) {
                    stateCode = reciprocityState.ReciprocityStateCode;
                }
            });
            return stateCode;
        };

        $scope.saveCPCEducation = function(currentForm, val) {
            if (!validateForm(currentForm, val)) {
                HideLoader();
                if ($scope.courseTypeId == 3) {
                    initialApplicationstatusService.setStandardsPractice3("invalid");
                } else {
                    initialApplicationstatusService.setStandardsPractice("invalid");
                }
                return;
            }


            if ($scope.individualLicense.applicationType == 'active') {
                if ($scope.courseTypeId == 3) {
                    initialApplicationstatusService.setStandardsPractice3("success");
                } else {
                    initialApplicationstatusService.setStandardsPractice("success");
                }

            } else if ($scope.individualLicense.applicationType == 'exempt') {
                var licenseStatusSubTypeReasons = getLicenseStatusSubTypeReasonsByProcesing();
                initialApplicationCPCEducationService.saveIndividualLicenseStatusSubTypeReason(licenseStatusSubTypeReasons, sessionStorage.Key).then(function(response) {
                    if (!response.Status) {
                        if ($scope.courseTypeId == 3) {
                            initialApplicationstatusService.setStandardsPractice3('error');
                        } else {
                            initialApplicationstatusService.setStandardsPractice('error');
                        }

                        ShowErrMsg("cpcEducationSaveError", response.Message);
                    } else {
                        $scope.individualLicenseStatusSubTypeReason = response.IndividualLicenseStatusSubTypeReasonlst;
                        processIndividualLicenseStatusSubTypeReason();
                        if ($scope.individualLicenseStatusSubTypeReasonComity.ContentItemResponse) {
                            $scope.individualLicenseReciprocityData.ReciprocityStateCd = getStateCodeById($scope.individualLicenseReciprocityData.ReciprocityStateId);
                            initialApplicationCPCEducationService.saveIndividualLicenseReciprocity([$scope.individualLicenseReciprocityData], sessionStorage.Key).then(function(responseRec) {
                                if (!responseRec.Status) {
                                    if ($scope.courseTypeId == 3) {
                                        initialApplicationstatusService.setStandardsPractice3('error');
                                    } else {
                                        initialApplicationstatusService.setStandardsPractice('error');
                                    }

                                    ShowErrMsg("cpcEducationSaveError", responseRec.Message);
                                } else {
                                    $scope.individualLicenseReciprocity = responseRec.IndividualLicenseReciprocitylst;
                                    processIndividualLicenseReciprocity();

                                    if ($scope.courseTypeId == 3) {
                                        initialApplicationstatusService.setStandardsPractice3("success");
                                    } else {
                                        initialApplicationstatusService.setStandardsPractice("success");
                                    }
                                }
                                if (!$rootScope.ProcessLoaderExternally)
                                    HideLoader();
                            }, function(error) {
                                if ($scope.courseTypeId == 3) {
                                    initialApplicationstatusService.setStandardsPractice3('error');
                                } else {
                                    initialApplicationstatusService.setStandardsPractice('error');
                                }

                                if (!$rootScope.ProcessLoaderExternally)
                                    HideLoader();
                                ShowErrMsg("cpcEducationSaveError", data);
                            });
                        } else if ($scope.individualLicenseStatusSubTypeReasonFirstTime.ContentItemResponse) {
                            var cehItemsToSave = [];
                            if ($scope.individualLicense.LicenseTypeId == 4) {
                                $scope.peCEHHours.IndividualLicenseId = $scope.individualLicense.IndividualLicenseId;
                                cehItemsToSave.push($scope.peCEHHours);
                            } else if ($scope.individualLicense.LicenseTypeId == 2) {
                                $scope.lsCEHHours.IndividualLicenseId = $scope.individualLicense.IndividualLicenseId;
                                cehItemsToSave.push($scope.lsCEHHours);
                            } else if ($scope.individualLicense.LicenseTypeId == 5) {
                                $scope.peCEHHours.IndividualLicenseId = $scope.individualLicense.IndividualLicenseId;
                                $scope.lsCEHHours.IndividualLicenseId = $scope.individualLicense.IndividualLicenseId;
                                cehItemsToSave.push($scope.peCEHHours);
                                cehItemsToSave.push($scope.lsCEHHours);
                            }
                            initialApplicationCPCEducationService.individualCEHSave(cehItemsToSave, sessionStorage.Key).then(function(response) {
                                if (!response.Status) {
                                    if ($scope.courseTypeId == 3) {
                                        initialApplicationstatusService.setStandardsPractice3('error');
                                    } else {
                                        initialApplicationstatusService.setStandardsPractice('error');
                                    }

                                    ShowErrMsg("cpcEducationSaveError", response.Message);
                                } else {
                                    $scope.individualCEH = response.IndividualCEHResponseList;
                                    processCEHHours();
                                    if ($scope.courseTypeId == 3) {
                                        initialApplicationstatusService.setStandardsPractice3("success");
                                    } else {
                                        initialApplicationstatusService.setStandardsPractice("success");
                                    }

                                }
                                if (!$rootScope.ProcessLoaderExternally)
                                    HideLoader();
                            }, function(error) {
                                if ($scope.courseTypeId == 3) {
                                    initialApplicationstatusService.setStandardsPractice3('error');
                                } else {
                                    initialApplicationstatusService.setStandardsPractice('error');
                                }

                                if (!$rootScope.ProcessLoaderExternally)
                                    HideLoader();
                                ShowErrMsg("cpcEducationSaveError", data);
                            });
                        } else {
                            if (!$rootScope.ProcessLoaderExternally)
                                HideLoader();
                            if ($scope.courseTypeId == 3) {
                                initialApplicationstatusService.setStandardsPractice3("success");
                            } else {
                                initialApplicationstatusService.setStandardsPractice("success");
                            }

                        }
                    }
                }, function(error) {
                    if ($scope.courseTypeId == 3) {
                        initialApplicationstatusService.setStandardsPractice3('error');
                    } else {
                        initialApplicationstatusService.setStandardsPractice('error');
                    }

                    if (!$rootScope.ProcessLoaderExternally)
                        HideLoader();
                    ShowErrMsg("cpcEducationSaveError", data);
                });
            } else {
                if ($scope.courseTypeId == 3) {
                    initialApplicationstatusService.setStandardsPractice3("success");
                } else {
                    initialApplicationstatusService.setStandardsPractice("success");
                }
            }
        };

        $scope.$on("SaveRenewal", function(event, args) {

            if ($scope.individualLicense.applicationType != 'retired') {
                $scope.saveCPCEducation($scope.currentForm, true);
            }

        });

        $scope.getContentByLkItemId = function() {
            contentService.getContentByContentItemLkIdList([29, 30], sessionStorage.Key)
                .then(function(response) {
                    var contentArray = response.ContentItemLk;
                    if (contentArray) {
                        for (var i = 0; i < contentArray.length; i++) {
                            switch (contentArray[i].ContentItemLkId) {
                                case 29:
                                    $scope.content29 = contentArray[i].ContentItemLkDesc;
                                    break;
                                case 30:
                                    $scope.content30 = contentArray[i].ContentItemLkDesc;
                                    break;
                            }
                        }
                    }
                }, function(error) {

                });
        };


        $scope.setOutOfStateLicForm = function(outOfStateLicForm) {
            $scope.outOfStateLicForm = outOfStateLicForm;
        };

        $scope.openAddEditOutOfStateLic = function(isEdit, individualOutOfStateLic) {
            $scope.isOutOfStateLicEdit = isEdit;
            $scope.viewConfig.showAddOutOfStateLicBlock = true;
            if (!$scope.isOutOfStateLicEdit) {
                $scope.individualOutOfStateLic = {
                    IndividualId: $scope.individualId,
                    IsActive: true,
                    UseId: sessionStorage.UserID,
                    ApplicationId: $scope.applicationId
                };
            } else {
                $scope.individualOutOfStateLic = angular.copy(individualOutOfStateLic);
                if ($scope.individualOutOfStateLic.CECourseDate) {
                    $scope.individualOutOfStateLic.CECourseDate = new Date($scope.individualOutOfStateLic.CECourseDate);
                }
            }
            if (!$scope.individualOutOfStateLic.ReferenceNumber || $scope.individualOutOfStateLic.individualOutOfStateLic == '') {
                $scope.individualOutOfStateLic.ReferenceNumber = (new Date().valueOf()).toString();
            }
        };

        $scope.closeAddEditOutOfStateLic = function() {
            $scope.viewConfig.showAddOutOfStateLicBlock = false;
            $scope.outOfStateLicForm = null;
        };

        $scope.getOutOfStateLicInfo = function() {
            initialApplicationCPCEducationService.getCEEducation($scope.individualId, sessionStorage.Key, $scope.applicationId, $scope.userId)
                .then(function(response) {
                    if (response.Status) {
                        $scope.individualOutOfStateLicList = _.filter(response.IndividualCECourseResponseList, function(o) { return o.CECourseTypeId == $scope.courseTypeId });
                        processIndividualBoardActionAndFields();


                        angular.forEach($scope.individualOutOfStateLicList, function(item) {
                            if (item.CECourseTypeId == $scope.courseTypeId) {
                                $scope.totalValue.total += Number(item.CECourseHours);
                            }
                        });

                    }
                    HideLoader();
                }, function(error) {
                    HideLoader();
                    ShowErrMsg("experienceNotLoaded", error.Message);
                });
        };
        var processIndividualBoardActionAndFields = function() {
            if ($scope.individualOutOfStateLicList && $scope.individualOutOfStateLicList.length > 0) {
                multipleFileUploadService.individualDocumentProcess(sessionStorage.Key, $scope.individualId,
                    $scope.individualOutOfStateLicList, $scope.documentUploadNewApp.PropertyToProcess, $scope.documentUploadNewApp.LinkType).then(function(responseData) {
                    $scope.individualOutOfStateLicList = responseData;
                    //console.log(responseData);
                    HideLoader();
                });
            }
        };

        $scope.saveOutOfStateLic = function(outOfStateLicForm) {
            try {

                if (!validateForm($scope.outOfStateLicForm)) {
                    return;
                };
                ShowLoader("Please Wait...");
                angular.forEach($scope.statList, function(stateItem) {
                    if (stateItem.StateCode == $scope.individualOutOfStateLic.StateCode) {
                        $scope.individualOutOfStateLic.StateName = stateItem.Name;
                    }
                });
                $scope.individualOutOfStateLic.CECourseTypeId = $scope.courseTypeId;
                initialApplicationCPCEducationService.educationSave($scope.individualOutOfStateLic, sessionStorage.Key)
                    .then(function(response) {

                        if (response.Status && response.StatusCode == "00") {

                            $scope.documentUploadNewApp.LinkId = response.IndividualCECourseResponseList[0].IndividualCECourseId;
                            if (typeof $scope.saveAllDocument == "function") {
                                $scope.saveAllDocument().then(function() {
                                    $scope.getOutOfStateLicInfo();
                                    HideLoader();
                                }, function() {});
                            } else {
                                $scope.getOutOfStateLicInfo();
                                HideLoader();
                            }

                            $scope.viewConfig.showAddOutOfStateLicBlock = false;
                        } else {
                            HideLoader();
                            ShowErrMsg("SaveIndividualOutOfStateLic", response.Message);
                        }
                    });
            } catch (ex) {
                HideLoader();
                ShowErrMsg("SaveIndividualOutOfStateLic", ex.message);
            }
        };

        $scope.setSaveDocumentRef = function(fn) {
            $scope.saveAllDocument = fn;
        };

        $scope.deleteOutOfStateLic = function(individualOutOfStateLic) {
            try {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    ShowLoader();
                    individualOutOfStateLic.IsDeleted = true;
                    initialApplicationCPCEducationService.educationSave(individualOutOfStateLic, sessionStorage.Key)
                        .then(function(response) {
                            if (response.Status && response.StatusCode == "00") {
                                $scope.getOutOfStateLicInfo();
                            }
                            HideLoader();
                        }, function(data) {
                            HideLoader();
                            ShowErrMsg();
                        });
                }
            } catch (ex) {
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        };
        var initDocument = function() {
            $scope.documentUploadNewApp = undefined;
            setTimeout(function() {
                $scope.$apply(function() {
                    $scope.documentUploadNewApp = {
                        ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                        ApplicationId: $scope.applicationId,
                        IndividualId: $scope.individualId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FR",
                        LoadByDocIdAndDocCode: true,
                        DocId: 1,
                        DocCode: 'D1001',
                        LoadDocumentsWithoutBroadCast: false,
                        LinkType: 'AL',
                        PropertyToProcess: 'IndividualCECourseId',
                        IsLinkedDocControl: true
                    };
                });
            }, 1000);
        };
        initDocument();
        $scope.getOutOfStateLicInfo();
        $scope.getContentByLkItemId();

        $scope.init();

    }
})();