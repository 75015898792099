(function () {
    'use strict'

    angular.module('app.core')
    .controller('BulkUploadController', ['$scope', '$rootScope', '$state', '$q', '$timeout', 'PromiseHandlers', 'BulkUploadService', 'InlumonFormBuilderService', 'utilityService', BulkUploadController]);

    function BulkUploadController($scope, $rootScope, $state, $q, $timeout, PromiseHandlers, BulkUploadService, InlumonFormBuilderService, utilityService) {
        var _downloadDocumentPrefix = 'data:application/octet-stream;';
        var _docMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        if (!!$state && !!$state.current && !!$state.current.routeData) {
            $scope.bulkUploadOperationId = (!!$scope.bulkUploadOperationId) ? $scope.bulkUploadOperationId : $state.current.routeData.bulkUploadOperationId;
            $scope.bulkUploadOperationCode = (!!$scope.bulkUploadOperationCode) ? $scope.bulkUploadOperationCode : $state.current.routeData.bulkUploadOperationCode;
            $scope.inputValues = (!!$scope.inputValues) ? $scope.inputValues : $state.current.routeData.inputValues;
            $scope.configName = (!!$scope.configName) ? $scope.configName : $state.current.routeData.configName;
        }

        if (!!$state && !!$state.current){
            $scope.configName = ($state.current.name.includes("Firm")) ? "ShareholderOrPartner" : $scope.configName;
            // console.log($scope.configName);
            
        }

        var errorHandler = function (err) {
            $scope.showStatusMsg('-', err);
            $scope.operationPending(false);  
        };

        var b64toBlob = function(b64Data, type) {
            var byteCharacters = atob(b64Data);
            var byteArrays = [];
            var sliceSize = 512;

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }
            return new Blob(byteArrays, {type: type});
        };

        var downloadBase64File = function (b64Data, mimeType, downloadPrefix, fileName) {
            var blob = b64toBlob(b64Data, mimeType);
            var anchor = angular.element('<a/>');

            if(window.navigator.msSaveBlob) { // IE
                window.navigator.msSaveBlob(blob, fileName);
            } else if (navigator.userAgent.search("Firefox") !== -1) { // Firefox
                anchor.css({display: 'none'});
                angular.element(document.body).append(anchor);

                anchor.attr({
                    href: downloadPrefix + 'base64,' + b64Data,
                    download: fileName
                })[0].click();

                anchor.remove();
            } else { // Chrome
                anchor.attr({
                    href: downloadPrefix + 'base64,'  + b64Data,
                    download: fileName
                })[0].click();
            }
        };

        $scope.getUIConfig = function () {
            var config = {};
        
            for (var prop in $scope.config.UIConfigs['Default']) {
                if (!$scope.config.UIConfigs['Default'].hasOwnProperty(prop)) { continue; }
        
                config[prop] = $scope.config.UIConfigs['Default'][prop];
            }
        
            if (!!$scope.config.UIConfigs[$scope.configName]) {
                for (var prop in $scope.config.UIConfigs[$scope.configName]) {
                    if (!$scope.config.UIConfigs[$scope.configName].hasOwnProperty(prop)) { continue; }
        
                    config[prop] = $scope.config.UIConfigs[$scope.configName][prop];
                }
            }
        
            return config;
        };

        $scope.getConfig = function () {
            var deferred = $q.defer();
        
            try {
                utilityService.getConfigData('/core/directive/bulk-upload/bulk-upload.server-config.json')
                .then(function(data) {
                    if (!!data) {
                        $scope.config = data;
        
                        $scope.uiConfig = $scope.getUIConfig();
        
                        $scope.isConfigLoaded = true;
                    }
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
        
            return deferred.promise;
        };

        $scope.addDocument = function (files) {
            if (!files || !files[0]) { return; }
            
            var extRegex = /^.*\.(.*)$/gi;
            var extMatch = extRegex.exec(files[0].name);

            //If file extensions have been specified, check to make sure the file extension is acceptable
            if (!!$scope.options.fileExtensions && $scope.options.fileExtensions.length > 0) {
                //Check if the file has an extension, and make sure it's xlsx
                if (!extMatch || !extMatch[1] || !$scope.options.fileExtensions.includes(extMatch[1])) {
                    $scope.errorMessage = 'The file extension is invalid';
                    return;
                }
            }
            
            var reader = new FileReader();
            var urlRegex = /.*base64,(.*)/gi;

            reader.onload = function () {
                $scope.resetBulkUploadOperationClicked();
                $scope.current.documentBase64 = '';
                $scope.current.document = undefined;
                $scope.current.fileName = '';
                
                $timeout(function () {
                    var match = urlRegex.exec(reader.result);
                    $scope.current.documentBase64 = match[1];
                    $scope.current.document = files[0];
                    $scope.current.fileName = files[0].name;
                }, 1);
            };

            reader.readAsDataURL(files[0]);
        };

        // $scope.processExcel = function (data) {
        //     var emitMinMaxError = [];
        //     var minMaxerrorMessage = '';
        //     var workbook = XLSX.read(data, {
        //         type: 'base64'
        //     });

        //     var firstSheet = workbook.SheetNames[0];
        //     var excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);

        //     angular.forEach(excelRows, function (row) {
        //         if(["Live Presentation","Group Internet-based programs","Blended Learning Program"].indexOf(row["Presentation Method"]) !== -1){
        //             if (row["Hours Claimed"] < 1) {
        //                 minMaxerrorMessage =  "Minimum hours claimed for " + row["Presentation Method"] + " must be 1 (May be in increments of .20 or .50 thereafter).";
        //                 emitMinMaxError.push(minMaxerrorMessage);
                        
        //             }                    

        //         } else if(["Interactive Self-Study"].indexOf(row["Presentation Method"]) !== -1){
        //             if (row["Hours Claimed"] < 0.5) {
        //                 minMaxerrorMessage = "Minimum hours claimed for " + row["Presentation Method"] + " must be 0.5. (May be in increments of .20 or .50 thereafter).";
        //                 emitMinMaxError.push(minMaxerrorMessage);
        //             }                    
                    
        //         }                   
        //          else if(["Author of published books, articles, CE program instruction materials, or questions for the CPA Exam","Teaching:  as an instructor, discussion leader or speaker"].indexOf(row["Presentation Method"]) !== -1){
        //             if (row["Hours Claimed"] > 40) {
        //                 minMaxerrorMessage = "Maximum hours claimed for " + row["Presentation Method"] + " is 40 hours.";
        //                 emitMinMaxError.push(minMaxerrorMessage);
        //             }
                    
        //         } else{
        //                 minMaxerrorMessage = "Please enter CE Hours in increments of 0.50" + row["Presentation Method"] +" only.";
        //                 emitMinMaxError.push(minMaxerrorMessage);
        
        //         }
        //         if (emitMinMaxError.length > 0){
        //             $scope.$emit("SendUp", emitMinMaxError);
        //         }
        //         emitMinMaxError = [];
        //     });                 
        // }

        $scope.downloadTemplateFile = function (bulkUploadOperation) {
            var deferred = $q.defer();
            
            try {
                BulkUploadService.downloadTemplateFile(sessionStorage.Key, bulkUploadOperation)
                .then(function(data) {
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.runBulkUploadOperation = function () {
            var deferred = $q.defer();
            
            try {
                var request = angular.copy($scope.bulkUploadOperation);
                request.Base64String = $scope.current.documentBase64;
                request.InputFileName = $scope.current.fileName;
                request.InputParameters = $scope.current.formParams;
                if (!request.InputParameters) {
                    request.InputParameters = {};
                }

                // Now copy everything from the input values passed into the page/directive
                if (!!$scope.inputValues) {
                    for (var prop in $scope.inputValues) {
                        request.InputParameters[prop] = $scope.inputValues[prop];
                    }
                }

                BulkUploadService.runBulkUploadOperation(sessionStorage.Key, request)
                .then(function(data) {
                    if (!!data.Status) {
                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.getOperationProgress = function (operation) {
            var deferred = $q.defer();
            
            try {
                BulkUploadService.getOperationProgress(sessionStorage.Key, operation)
                .then(function(data) {
                    if (!!data.Status) {
                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        var getProgressMessage = function (progress) {
            return '{0}...{1}'
            .format(
                progress.StageMessage, 
                ((!!progress.NumEntriesProcessed) ? 'Processed {0} of {1} entries'.format(progress.NumEntriesProcessed, progress.NumEntriesTotal) : '')
            );
        };

        $scope.monitorProgressOfOperation = function (operation) {
            var deferred = $q.defer();
            
            try {
                var response = {
                    Operation: operation,
                    Progress: null,
                    Error: null
                };
                PromiseHandlers.poll($scope.getOperationProgress)
                .success(function(data) {
                    if (data.Status) {
                        var progress = data.ProgressList.firstOrDefault();
                        response.Progress = progress;
                        if (!!progress && !progress.IsComplete) {
                            $scope.operationPending(true, getProgressMessage(progress));
                            
                            var self = this;
                            $timeout(function () {
                                self.callAgain(response.Operation);
                            }, 2000);
                        } else if (!!progress && !!progress.IsComplete) {
                            $scope.uploadComplete();
                            deferred.resolve(response);
                        } else {
                            response.Error = {
                                message: 'No progress found'
                            };
                            $scope.uploadComplete();
                            deferred.reject(response);
                        }
                    } else {
                        response.Error = data;
                        $scope.uploadComplete();
                        deferred.reject(response);
                    }
                })
                .error(function(err) {
                    response.Error = err;
                    $scope.uploadComplete();
                    deferred.reject(response);
                })
                .start(response.Operation);
            } catch (ex) {
                $scope.uploadComplete();
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.downloadResultFile = function (operation) {
            var deferred = $q.defer();
            
            try {
                operation.FlagForCleanup = true;
                BulkUploadService.downloadResultFile(sessionStorage.Key, operation)
                .then(function(data) {
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.downloadTemplateFileClicked = function () {
            $scope.operationPending(true, 'Generating File...');
            
            $scope.downloadTemplateFile($scope.bulkUploadOperation)
            .then(function() {
                $scope.operationPending(false);
            }, errorHandler);
        };

        $scope.runBulkUploadOperationClicked = function () {
            if (!$scope.current.document) { 
                $scope.showStatusMsg('-', 'Please select a file.');
            }
            $scope.hideStatusMsg();
            $scope.operationPending(true, 'Running...');
            
            $scope.runBulkUploadOperation()
            .then(function (data) {
                $scope.monitorProgressOfOperation(data)
                .then(function (data) {
                    if (!!data.Progress && data.Progress.ResultHasDocument) {
                        // $scope.processExcel($scope.current.documentBase64);
                        $scope.downloadResultFile(data.Operation);
                        $scope.showStatusMsg('-', 'There were errors in the file.  A file with the validation errors has been downloaded to your computer.');
                        $scope.operationPending(false);
                        $scope.resetBulkUploadOperationClicked();
                    } else if (!!data.Progress && !data.Progress.OperationResult) {
                        $scope.showStatusMsg('-', data.Progress.ResultMessage);
                        $scope.operationPending(false);
                        $scope.resetBulkUploadOperationClicked();
                    } else {
                        $scope.showStatusMsg('+', 'Operation Successful!');
                        $scope.operationPending(false);
                        $scope.resetBulkUploadOperationClicked();
                    }
                    
                }, errorHandler);
            }, errorHandler);
        };

        $scope.resetBulkUploadOperationClicked = function () {
            $scope.current = {};
            $scope.uiLogic = {};
        };

        var setupOptions = function () {
            if(!$scope.options) {
                $scope.options = {};
            }

            if(!$scope.options.fileExtensions) {
                $scope.options.fileExtensions = [];
            }

            if(typeof($scope.options.fileExtensions) === 'string') {
                $scope.options.fileExtensions = $scope.options.fileExtensions.split(',').select(function (x) { return x.trim(); });
            } else if (!Array.isArray($scope.options.fileExtensions)) {
                console.log('Option: fileExtensions must be either a comma-delimited string or an array of strings');
            }

            if(!$scope.options.getExampleDocumentMessage) {
                $scope.options.getExampleDocumentMessage = 'Getting File...';
            }

            if(!$scope.options.uploadDocumentMessage) {
                $scope.options.uploadDocumentMessage = 'Uploading File...';
            }
        };

        $scope.init = function () {
            $scope.current = {};
            $scope.uiLogic = {};
            $scope.refTables = {};
            $scope.pager = {};

            if (!$scope.bulkUploadOperationId && !$scope.bulkUploadOperationCode) {
                //console.err('You must define either a bulkUploadOperationId or a bulkUploadOperationCode');
                throw 'You must define either a bulkUploadOperationId or a bulkUploadOperationCode';
            }

            setupOptions();

            $scope.bulkUploadOperation = {
                BulkUploadOperationId: $scope.bulkUploadOperationId,
                BulkUploadOperationCode: $scope.bulkUploadOperationCode
            };

            $scope.getConfig()
            .then(function () {
                $scope.operationPending(false);
            }, errorHandler);

            if (!!$scope.initExt) {
                $scope.initExt();
            }
        };

        //Eliminate race condition between config loading and form loading
        $scope.$watch(function () { return !!InlumonFormBuilderService.refTables.forms && !!$scope.uiConfig }, function (newVal) {
            if (!!newVal && !!$scope.uiConfig.FormId) {
                $scope.uiConfig.FormConfig = InlumonFormBuilderService.generateFormConfig($scope.uiConfig.FormId, 'uploadDocumentForm', 'current.formParams');
            }
        });
    }
})();