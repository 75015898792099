(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('deficiency', deficiency);

    deficiency.$inject = [];

    function deficiency() {
        return {
            restrict: 'E',
            scope: {
                IndividualId: '=individualId',
                ApplicationId: '=applicationId',
                individualLicense: "=individualLicense",
                isBackoffice: "=?isBackoffice",
                applicationInfo: "=?applicationInfo",
                deftype: "=?deftype",
                deficiencyData: "=?deficiencyData",
                currentTab: "=?currentTab",
                allTabsDeficiencyData: "=?allTabsDeficiencyData",
                closeDeficiencyEmailForm: "&closeDeficiencyEmailForm",
                denyLicense: "&denyLicense",
                moveToQueue: "&moveToQueue",
                updateApplicationDefeciency: "&updateApplicationDefeciency",
                isDenyCall: "=?isDenyCall",
                isDeficiencyCall: "=?isDeficiencyCall",
                isAssignTo: "=?isAssignTo",
                applicationStatusId: "=?applicationStatusId"
            },
            templateUrl: "app/components/individual/application/directives/deficiency/deficiency.html",
            controller: "DeficiencyController"
        }
    }

    angular.module('app.InitialApplication')
        .directive('invokeDeficiency', invokeDeficiency);

    invokeDeficiency.$inject = [];

    function invokeDeficiency() {
        return {
            restrict: 'A',
            scope: {
                tab: "=",
                tableName: "=",
                tableLinkId: "=",
                tableLinkIdColumnName: "=",
                tags: "="
            },
            link: function ($scope, $element, $attrs) {
                $element.on('click', function (event) {
                    $scope.$emit("invokeDeficiencyParent", {
                        tab: $scope.tab,
                        tableName: $scope.tableName,
                        tableLinkId: $scope.tableLinkId,
                        tableLinkIdColumnName: $scope.tableLinkIdColumnName,
                        tags: $scope.tags
                    });
                });
            }
        }
    };

    angular.module('app.InitialApplication')
        .directive('allowEditForDeficiency', allowEditForDeficiency);

    allowEditForDeficiency.$inject = [];

    function allowEditForDeficiency() {
        return {
            restrict: 'A',
            scope: {
                tab: "=",
                deficiencyStatusId: "=",
                tableName: "=",
                tableLinkId: "=",
                tableLinkIdColumnName: "=",
                statusElement: "=?",
                enableAllowEditForDeficiency: "=?"
            },
            link: function ($scope, $element, $attrs) {
                function processDeficient() {
                    if (!!$scope.enableAllowEditForDeficiency) {
                        if (!sessionStorage.isBackoffice && $scope.deficiencyStatusId == 1) {
                            if (!!$scope.tab && !!$scope.tab.individualDefeciency && !!$scope.tab.individualDefeciency.IndividualDefeciencyDtlList) {
                                var individualDefeciencyDtls = $scope.tab.individualDefeciency.IndividualDefeciencyDtlList
                                    .whereEquals(true, 'IsDefecient')
                                    .whereNotEquals(true, 'IsDefeciencyResolved')
                                    .whereEquals($scope.tableName, 'TableName')
                                    .whereEquals($scope.tableLinkId, 'TableLinkId')
                                    .whereEquals($scope.tableLinkIdColumnName, 'TableLinkIdColumnName');
                                if (!!individualDefeciencyDtls && individualDefeciencyDtls.length > 0) {
                                    if (!$scope.statusElement) {
                                        $scope.statusElement = "li";
                                    }
                                    var deficientElement = document.createElement($scope.statusElement);
                                    var innerHTML = "Deficient";
                                    deficientElement.innerHTML = innerHTML;
                                    deficientElement.style.color = 'Red';
                                    deficientElement.style.fontWeight = 'bold';
                                    deficientElement.style.fontSize = '16px';
                                    deficientElement.style.marginRight = '10px';
                                    deficientElement.style.lineHeight = '30px';
                                    $element.append(deficientElement);
                                } else {
                                    $element.find('input,a,button').each(function (index, innerElement) {
                                        angular.element(innerElement).addClass('section-disabled');
                                        angular.element(innerElement).attr('disabled', 'disabled');
                                    });
                                }
                            }
                        }
                    }
                }
                processDeficient();
            }
        }
    }
})();