(function () {
    'use strict'

    angular.module('app.enforcement')
        .directive('complaintQuestionnaireLogic', ['ComplaintQuestionnaireService', '$q', complaintQuestionnaireLogic]);

    function complaintQuestionnaireLogic(ComplaintQuestionnaireService, $q) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.resetPatientForm = function (isNotApplicable) {
                    if (!!isNotApplicable) {
                        $scope.current.questionnaire.FirstName = null;
                        $scope.current.questionnaire.MiddleName = null;
                        $scope.current.questionnaire.LastName = null;
                        $scope.current.questionnaire.Suffix = null;
                        $scope.current.questionnaire.DateOfDBirth = null;
                        $scope.current.questionnaire.YourRelationship = null;
                        $scope.current.questionnaire.YourRelationshipOther = null;
                    }
                };

                $scope.resetOtherCheckboxes = function (mailauthorization, noAuthorization, sectionNotApplicable) {
                    if(!!mailauthorization){
                        $scope.current.questionnaire.NoAuthorization = false;
                        $scope.current.questionnaire.SectionNotApplicable = false;
                    }

                    if(!!noAuthorization){
                        $scope.current.questionnaire.Mailauthorization = false;
                        $scope.current.questionnaire.SectionNotApplicable = false;
                    }

                    if(!!sectionNotApplicable){
                        $scope.current.questionnaire.Mailauthorization = false;
                        $scope.current.questionnaire.NoAuthorization = false;
                    }
                };

                $scope.saveComplaintQuestionsExt = function(){
                    $scope.$broadcast('SaveComplaintQuestionnaire', { complaint: $scope.complaint});
                    alert($scope.uiConfig.SubmissionMessage);
                };

                $scope.autoCalcDate = function (date, prop, numDays) {
                    if (!!date) {
                        $scope.current.questionnaire[prop] = date.getDateObj().addDays(numDays);
                    } else {
                        $scope.current.questionnaire[prop] = null;
                    }
                };

                $scope.$watch('complaint.CitationCompliance', function (newVal) {
                    if(!!newVal){
                        var complaintActionsList = newVal;
                        if(!!complaintActionsList && complaintActionsList.length>0){
                            var assessedAmountList = complaintActionsList.whereEquals(400,'ComplaintActionTypeId');
                            var dismissedAmountList = complaintActionsList.whereEquals(402,'ComplaintActionTypeId');
                            var reducedAmountList = complaintActionsList.whereEquals(401,'ComplaintActionTypeId');
                            var paymentReceivedList = complaintActionsList.whereEquals(403,'ComplaintActionTypeId');
                            var refundIssuedList = complaintActionsList.whereEquals(404,'ComplaintActionTypeId');

                            var assessedAmount = 0;
                            var dismissedAmount = 0
                            var reducedAmount = 0;
                            var paymentReceived = 0;
                            var refundIssued = 0;

                            if(!!assessedAmountList){
                                assessedAmount = assessedAmountList.sum('ComplaintFinancialAmount');
                            }
                            if(!!dismissedAmountList){
                                dismissedAmount = dismissedAmountList.sum('ComplaintFinancialAmount');
                            }
                            if(!!reducedAmountList){
                                reducedAmount = reducedAmountList.sum('ComplaintFinancialAmount');
                            }
                            if(!!paymentReceivedList){
                                paymentReceived = paymentReceivedList.sum('ComplaintFinancialAmount');
                            }
                            if(!!refundIssuedList){
                                refundIssued = refundIssuedList.sum('ComplaintFinancialAmount');
                            }

                            $scope.current.questionnaire.FineAmountCompliance = assessedAmount - dismissedAmount - reducedAmount;
                            $scope.current.questionnaire.BalanceDue = $scope.current.questionnaire.FineAmountCompliance - paymentReceived + refundIssued;
                        }
                    }                    
                }, true)
            },
        };
    }
})();