﻿angular.module('natApp').controller("CashBalancingController", ["$scope", "$controller", "CouncilInfoService", "cashieringService", "profileManagemetFactory", "reportService", "ApiUrlsForReportsXls",
    function ($scope, $controller, CouncilInfoService, cashieringService, profileManagemetFactory, reportService, ApiUrlsForReportsXls) {
        $controller('GlobalController', {
            $scope: $scope
        });

        $scope.individualViewConfig = {
            licenseStatusDisabled: false,
            tabs: [{
                name: 'batches',
                text: 'Batches'
            }, {
                name: 'receiptDetails',
                text: 'Receipt Details'
            },
            {
                name: 'paymentType',
                text: 'Grouping By Payment Type'
            },
            {
                name: 'feeType',
                text: 'Grouping By Fee Type'
            }, {
                name: 'cashrefund',
                text: 'Cash Refund'
            }, {
                name: 'trialBalance',
                text: 'Trial Balance'
            },
            ]
        };

        $scope.headersDrawers = {
            DrawerNumber: {
                label: "Batch Number"
            },
            DrawerOpenDate: {
                label: "Batch Opened Date",
                templateUrl: "cashBalancingDates.html",
                dateFormat: "M!/d!/yyyy",
            },
            DrawerCloseDate: {
                label: "Batch Closed Date",
                templateUrl: "cashBalancingDates.html",
                dateFormat: "M!/d!/yyyy",
            },
            DrawerInd: {
                label: "Batch Status",
                templateUrl: "cashBalancingStatus.html"
            },
            DrawerOpenedByName: {
                label: "Opened By"
            },
            DepositNumber: {
                label: "Deposit Number"
            },
            UserDefinedDepositNo: {
                label: "Report of Collection Number"
            },
            DepositDate: {
                label: "Deposit Date",
                templateUrl: "cashBalancingDates.html",
                dateFormat: "M!/d!/yyyy",
            },
            DepositAmount: {
                label: "Deposit Amount"
            },
            Actions: {
                label: "Actions",
                templateUrl: "cashBalancingActions.html",
            }
        };

        $scope.setCurrentTab = function (tab) {
            $scope.individualCurrentTab = tab;
            $scope.secondDailyDepositList = null;
            $scope.DailyDepositList = null;
            $scope.searchData.totalRecords = 0;
            $scope.trialBalanceReportWithPager();
        };

        $scope.individualCurrentTab = $scope.individualViewConfig.tabs[0];

        $scope.Headers = [{
            name: 'Fee Type',
            sortType: 'DetailCode',
            sortOrder: 'asc'
        },
        {
            name: 'Fee Type Description',
            sortType: 'InvoiceDetail_Description',
            sortOrder: 'asc'
        },
        {
            name: 'Payment Date',
            sortType: 'PaymentDate',
            sortOrder: 'asc'
        },
        {
            name: 'Receipt#',
            sortType: 'UserDefinedRefNo',
            sortOrder: 'asc'
        },
        {
            name: 'Payment Amount',
            sortType: 'AmtPaid',
            sortOrder: 'asc'
        },
        {
            name: 'Received Date',
            sortType: 'DateAdded',
            sortOrder: 'asc'
        },
        {
            name: 'Postmark Date',
            sortType: 'PostMarkDate',
            sortOrder: 'asc'
        },
        {
            name: 'Payment Method',
            sortType: 'PaymentMethod',
            sortOrder: 'asc'
        },
        {
            name: 'Total Amount',
            sortType: 'CheckAmount',
            sortOrder: 'asc'
        },
        {
            name: 'DLN',
            sortType: 'PaymentModeNumber',
            sortOrder: 'asc'
        },
        {
            name: 'State Receipt Number',
            sortType: 'Module',
            sortOrder: 'asc'
        },
        {
            name: 'Batch Number',
            sortType: 'BatchNum',
            sortOrder: 'asc'
        },
        {
            name: 'Licensee/Applicant',
            sortType: 'Name',
            sortOrder: 'asc'
        },
        {
            name: 'License Type',
            sortType: 'LicType',
            sortOrder: 'asc'
        },
        {
            name: 'License Number',
            sortType: 'LicenseNo',
            sortOrder: 'asc'
        },
        {
            name: 'Payor',
            sortType: 'PayorName',
            sortOrder: 'asc'
        },
        {
            name: 'Notes',
            sortType: 'Notes',
            sortOrder: 'asc'
        }
        ];

        $scope.secondHeaders = [{
            name: 'Account',
            sortType: 'Type',
            sortOrder: 'asc'
        }, {
            name: 'Class of Revenue',
            sortType: 'ClassOfRevenue',
            sortOrder: 'asc'
        },
        {
            name: 'Current Fy Amount',
            sortType: 'CurFyAmtDep',
            sortOrder: 'asc'
        },
        {
            name: 'Revenue In Advance',
            sortType: 'RenenueInAdvance',
            sortOrder: 'asc'
        },
        {
            name: 'Total Amount',
            sortType: 'TotalAmtDep',
            sortOrder: 'asc'
        }
        ];

        $scope.FirstName = (sessionStorage.FirstName + ' ' + sessionStorage.LastName);
        $scope.searchData = {
            "sortType": "ReceiptNo",
            "sortOrder": "asc",
            "currentPage": 1,
            "totalRecords": 0,
            "pageSize": 10,
            "maxSize": 5,
            "PaymentMethod": 3,
            "BatchNumber": "",
            "UserId": sessionStorage.UserID,
            "FirstName": (sessionStorage.FirstName + ' ' + sessionStorage.LastName),
            "maxSize": 10,
            "SearchMode": "receiptDetail",
            "DrawerInd": ""
        };

        $scope.SortRecords = function (header) {
            $scope.searchData.currentPage = 1;
            $scope.searchData.sortType = header.sortType;
            $scope.searchData.sortOrder = header.sortOrder;
            header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
            if (!($scope.individualCurrentTab.name == 'paymentType' || $scope.individualCurrentTab.name == 'feeType'))
                $scope.trialBalanceReportWithPager();
        };

        $scope.resetReport = function () {
            $scope.trialBalanceReportWithPager();
        };

        // "DrawerNumber"==3
        $scope.trialBalanceReportWithPager = function () {
            $scope.viewConfig.isConfigLoaded = false;
            if ($scope.individualCurrentTab && ($scope.individualCurrentTab.name == 'paymentType' || $scope.individualCurrentTab.name == 'feeType')) {
                ShowLoader();
                var type = 'F';
                if ($scope.individualCurrentTab.name == 'feeType')
                    type = 'P';
                CouncilInfoService.TrialBalanceSummeryGet(sessionStorage.Key, type, $scope.searchData.BatchNumber).then(function (response) {
                    HideLoader();
                    //Records
                    if (response.data.PagerVM) {
                        $scope.secondDailyDepositList = response.data.PagerVM.Records;
                        $scope.searchData.totalRecords = response.data.PagerVM.TotalRecords;
                    }
                }, function (error) {
                    HideLoader();
                });
            } else if ($scope.individualCurrentTab && ($scope.individualCurrentTab.name == 'batches')) {
                $scope.getRevDrawer();
            } else {
                ShowLoader();
                if ($scope.individualCurrentTab.name == 'receiptDetails') {
                    $scope.searchData.SearchMode = "receiptDetail";
                } else if ($scope.individualCurrentTab.name == 'cashrefund') {
                    $scope.searchData.SearchMode = "cashrefund";
                }
                CouncilInfoService.TrialBalanceReportWithPager(sessionStorage.Key, $scope.searchData).then(function (response) {
                    HideLoader();
                    if (response.data.PagerVM) {
                        $scope.DailyDepositList = response.data.PagerVM.Records;
                        $scope.searchData.totalRecords = response.data.PagerVM.TotalRecords;
                    }
                }, function (error) {
                    HideLoader();
                });
            }

        };

        $scope.getExcelForDataReport = function () {
            try {
                ShowLoader('Please Wait...')
                reportService.getXLSForTrialBalanceReport(sessionStorage.Key, $scope.searchData, ApiUrlsForReportsXls.TrialBalanceXLS)
                    .then(function (response) {
                        HideLoader();
                    }, function (data) {
                        HideLoader()
                        showStatusMessage(data, 'error')
                    })
            } catch (ex) {
                HideLoader()
                if (ex != null) { showStatusMessage(ex.message, 'error'); }
            }
        };

        var resetPagination = function () {
            $scope.paginationParamsCashieringBalance = {
                showPager: false
            };
        };

        $scope.getRevDrawer = function () {
            $scope.loadingCashBalancing(true);
            $scope.searchData.DrawerNumber = $scope.searchData.BatchNumber;
            cashieringService.getRevDrawer(sessionStorage.Key, $scope.searchData).then(function (response) {
                if (response.Status) {
                    if (!!response.EntityRecords && response.EntityRecords.length > 0) {
                        $scope.revDrawerList = response.EntityRecords;
                    }
                    $scope.viewConfig.isConfigLoaded = true;
                    $scope.showStatusMsg('+', response.Message);
                } else {
                    $scope.showStatusMsg('-', response.Message);
                }
                resetPagination();
                $scope.loadingCashBalancing(false);
            }, function (data) {
                $scope.loadingCashBalancing(false);
                $scope.showStatusMsg('-', data.message);
            });
        };

        $scope.reopenBatch = function (revDrawer) {
            $scope.loadingCashBalancing(true);
            revDrawer.DrawerInd = "O";
            revDrawer.IsDrawerReopened = true;
            cashieringService.reOpenRevDrawer(sessionStorage.Key, [revDrawer]).then(function (response) {
                if (response.Status) {
                    $scope.showStatusMsg('+', "Batch opened succesfully.");
                } else {
                    $scope.showStatusMsg('-', response.Message);
                }
                $scope.loadingCashBalancing(false);
            }, function (data) {
                $scope.loadingCashBalancing(false);
                $scope.showStatusMsg('-', data.message);
            });
        };

        $scope.tableEvents = {
            reopenBatch: $scope.reopenBatch
        };

        $scope.loadTrialBalancaData = function () {
            if (!!$scope.viewConfig && !!$scope.viewConfig.profileLoaded && !!$scope.viewConfig.userLoaded) {
                $scope.tableEvents.canReOpenBatch = $scope.canReOpenBatch;
                $scope.setCurrentTab($scope.individualViewConfig.tabs[0]);
            }
        };

        $scope.onProfileLoaded = function () {
            $scope.viewConfig.profileLoaded = true;
            $scope.loadTrialBalancaData();
        };

        $scope.init = function () {
            $scope.viewConfig = {
                isConfigLoaded: false
            };
            profileManagemetFactory.usersSearch(sessionStorage.Key, 1, 10000, {
                UserTypeId: 1
            }).then(function (res) {
                if (res.Status) {
                    $scope.userSearchList = res.Users;
                    $scope.viewConfig.userLoaded = true;
                    $scope.loadTrialBalancaData();
                }
                HideLoader();
            }, function (err) {
                HideLoader();
                showStatusMessage(err.Message, "error");
            });
        };

        $scope.init();
    }
]);