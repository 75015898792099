(function() {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeIndividualApplicationDataController", BackofficeIndividualApplicationDataController);

    BackofficeIndividualApplicationDataController.$inject = ["$rootScope", "$scope", "$state", "backofficeIndividualApplicationDataService",
        "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl", "loggingService"
    ];

    function BackofficeIndividualApplicationDataController($rootScope, $scope, $state, backofficeIndividualApplicationDataService,
        utilityService, $exceptionHandler, TypeValue, WebApiUrl, loggingService) {

        //Tab Initilizer
        $scope.$on("individualTabChanged", function(evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "applicationdata" && !$rootScope.individualCurrentTab.isLoadedOnce) {
                $scope.individualId = individualId;
                if (!$scope.isLoadedOnce) {
                    $scope.isLoadedOnce = true;
                }
                $scope.individualApplicationDataByIndividualId($scope.individualId);
                $rootScope.individualCurrentTab.isLoadedOnce = true;
                $scope.DownloadDocumentAPIURL = WebApiUrl + 'Individual/DownloadPDF/' + sessionStorage.Key + '?path=';
            }
        });

        //Application Tab..
        $scope.individualApplicationDataByIndividualId = function(individualId) {
            ShowLoader("Please Wait...");
            try {
                backofficeIndividualApplicationDataService.individualApplicationDataByIndividualId(sessionStorage.Key, individualId)
                    .then(function(response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse(response)) {
                            $scope.ApplicationList = response.ApplicationDocumentList;
                        }
                        HideLoader();
                    }, function(data) {
                        $scope.showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage(ex.message, "error");
            }
        };
    }
})();