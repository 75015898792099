(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintChangeLog', [ComplaintChangeLogDirective]);

    function ComplaintChangeLogDirective() {
        return {
            restrict: 'E',
            scope: {
                complaint: '=',
                complaintCaseId: '=?',
                configName: '@?'
            },
            link: function ($scope, element, attrs) {

            },
            templateUrl: 'app/components/backoffice/enforcement/complaint-change-log/complaint-change-log.html',
            controller: 'ComplaintChangeLogController'
        };
    }
})();