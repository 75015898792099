(function () {
    'use strict'
    angular.module('app.core')
        .directive('natDateFormat', natDateFormatDirective);

    natDateFormatDirective.$inject = ['$parse'];

    function natDateFormatDirective($parse) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                element.on('blur', function (event) {
                    if (!ctrl.$$parentForm.errorMessages) {
                        ctrl.$$parentForm.errorMessages = [];
                    }

                    var errorObj = {
                        propName: (!!attrs.validationName ? attrs.validationName : ("natdateformat-" + ctrl.$name)),
                        errorMessage: attrs.natDateFormat
                    }

                    angular.forEach(ctrl.$$parentForm.errorMessages, function (error, indexOfMsg) {
                        if (errorObj.propName == error.propName) {
                            ctrl.$$parentForm.errorMessages.splice(indexOfMsg, 1);
                        }
                    })

                    if (!!ctrl.$modelValue && !angular.isDate(ctrl.$modelValue)) {
                        ctrl.$$parentForm.errorMessages.push(errorObj);
                        ctrl.$setValidity("natdateformat", false);
                    } else {
                        ctrl.$setValidity('natdateformat', true);
                    }
                })
            }
        }
    }
})();