(function () {
    'use strict'

    angular.module('app.compliance')
    .controller('ComplianceSearchController', ['$scope', '$rootScope', '$q', 'ComplianceSearchService', 'utilityService', ComplianceSearchController]);

    function ComplianceSearchController($scope, $rootScope, $q, ComplianceSearchService, utilityService) {
        var _key = sessionStorage.Key;
        var _childName = '';

        var getNewComplaint = function () {
            return {
                ComplaintNumber: '',
                ComplaintPriority: null,
                ComplaintRefNum: '',

                SecureLevel: null,

                ComplaintStatusId: null,
                CurrentStatusDate: null,

                ComplaintDispTypeId: null,
                ComplaintSourceTypeId: null,
                ComplaintPublicCaseReasonId: null,
                ComplaintFormTypeId: null,
                ComplaintClassId: null,

                ReceiveDate: null,
                IncidentDate: null,

                DispositionDate: null,
                DispositionUpdatedBy: null,
                DocketNumber: '',

                EnforcementRegionId: null,
                EnforcementCaseTypeId: null,
                EnforcementComplexityTypeId: null,
                EnforcementSecureClassId: null,

                CloseDate: null,
                ComplaintNotes: '',
                OutsideAgencyNotification: false,                

                IsNonactionable: false,
                IsAnonymous: false,
                IsAdministrativeAction: false,
                IsInternalOnly: false,
                IsForInvestigationOnly: false,
                IsForLegalOnly: false,
                IsForPublic: false,

                IsActive: true,
                IsDeleted: false,

                DispositionDeciderId: null,
                DispositionNotes: '',

                InvestigatorId: null
            };
        };

        var getNewInvolvedParty = function () {
            return {
                EntityId: null,
                EntityType: 'I'
            };
        };

        $scope.saveComplaint = function (complaint) {
            var deferred = $q.defer();
            
            try {
                ComplianceSearchService.saveComplaint(sessionStorage.Key, [complaint])
                .then(function(data) {
                    if (data.Status) {
                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.saveComplaintClicked = function () {
            $scope.operationPending(true, 'Saving...');

            $scope.saveComplaint($scope.current.complaint)
            .then(function (data) {
                $scope.searchComplaintClicked();
            }, function (err) {
                $scope.operationPending(false);
                $scope.showStatusMsg('-', err);
            });
        };
        $scope.addEditComplaintClicked = function (complaint) {
            $scope.current.screen = 'addEditNew';
            
            if (!complaint) {
                complaint = getNewComplaint();    
            }

            $scope.current.complaint = angular.copy(complaint);
        };
        $scope.cancelAddClicked = function () {
            $scope.current.screen = 'search';
            $scope.current.complaint = null;
        };
        $scope.deleteComplaint = function () {
            
        };

        $scope.getComplaints = function () {
            var deferred = $q.defer();
            
            try {
                ComplianceSearchService.getComplaints(sessionStorage.Key, $scope.searchData, $scope.paginationParams.currentPage, $scope.paginationParams.pageSize)
                .then(function(data) {
                    if (data.Status) {
                        $scope.complaintsList = data.SearchList;
                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.searchComplaintsClicked = function () {
            $scope.operationPending(true, 'Searching...');

            $scope.getComplaints()
            .then(function (data) {
                $scope.operationPending(false);
            }, function (err) {
                $scope.operationPending(false);
                $scope.showStatusMsg('-', err);
            });
        };
        $scope.resetSearchClicked = function () {
            $scope.searchData = {};

            $scope.getComplaints();
        };

        $scope.init = function () {
            $scope.searchData = {};
            $scope.uiLogic = {};
            $scope.current = {
                complaint: null,
                screen: 'search'  
            };

            $scope.tableEvents = {
                addEditComplaintClicked: $scope.addEditComplaintClicked
            };

            $scope.tableHeaders = {
                ComplaintRefNum: {
                    label: 'Reference #'
                },
                ComplaintStatus: {
                    label: 'Status'
                },
                Respondent: {
                    label: 'Respondent'
                },
                Complainant: {
                    label: 'Complainant'
                },
                IncidentDate: {
                    label: 'Complaint Date'
                },
                ReceiveDate: {
                    label: 'Received Date'
                },
                Actions: {
                    label: 'Actions',
                    templateUrl: 'complaintSearchActions.html'
                }
            };

            $scope.paginationParams = {};
        };
    }
})();