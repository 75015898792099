(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeDAuxilaryTabService', backofficeNameTabService)

    backofficeNameTabService.$inject = ['$http', '$q', 'WebApiUrl']

    function backofficeNameTabService($http, $q, WebApiUrl) {

        var _individualLicenseDetailBYIndividualId = function (Key, IndividualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualLicenseDetailBYIndividualId/' + Key + '?IndividualId=' + IndividualId));
        };
        var _licenseSave = function (individualLicense, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualLicenseSave/' + key, individualLicense));
        };

        var _getIndividualSupervisoryInfo = function (Key, IndividualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualSupervisoryInfo/' + Key + '?IndividualId=' + IndividualId));
        };
        var _generateApplicationId = function (Key, ApplicationId, UserId, applicationObj) {
            return returnDefferedResult($http.post(WebApiUrl + 'Application/GenerateApplicationId/' + Key + '?ApplicationId=' + ApplicationId + "&UserId=" + UserId, applicationObj));
        };
        var _saveIndividualNameInfo = function (individualLicense, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/SaveIndividualNameInfo/' + key + "?Source=NVDENTAL", individualLicense));
        };
        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        var _saveDentalAuxiliary = function (objDentalAuxiliaries, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualSupervisoryInfoSave/' + key + '?Source=' + AppConfig.Source, objDentalAuxiliaries)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        return {
            // individualLicenseDetailBYIndividualId: _individualLicenseDetailBYIndividualId,
            //  licenseSave: _licenseSave,
            // individualEducationBYIndividualLicenseId: _individualEducationBYIndividualLicenseId,
            // generateApplicationId: _generateApplicationId,
            //  licenseConversion: _licenseConversion,
            // getIndividualNameInfo: _getIndividualNameInfo,
            // saveIndividualNameInfo: _saveIndividualNameInfo,
            getIndividualSupervisoryInfo: _getIndividualSupervisoryInfo
        }
    }
})();
