(function () {
    'use strict'
    angular.module('app.core')
        .factory('individualinfoContinuingEducationService', individualinfoContinuingEducationService);

    individualinfoContinuingEducationService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig'];

    function individualinfoContinuingEducationService($http, $q, WebApiUrl, AppConfig) {

        var _getCDSCMEInfoandAudit = function (Key, IndividualId, ApplicationId, IndividualLicenseId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/GetCDSCMEInfoandAudit/' + Key + '?IndividualId=' + IndividualId + '&ApplicationId=' + ApplicationId + '&IndividualLicenseId=' + IndividualLicenseId).then(function(data){ data = data.data;
                deferred.resolve(data);
            }, function(error) { error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };


        return {
            getCDSCMEInfoandAudit: _getCDSCMEInfoandAudit
        }
    }
})();
