/**
 * Created by handsome on 2/4/2017.
 */
(function () {

    'use strict';
    angular.module('app.backofficeAdministor')
        .controller('DocumentController', DocumentController);

    DocumentController.$inject = ['$scope', 'documentFactory', 'utilityService', '$timeout','$q','Messages'];
    function DocumentController($scope, documentFactory, utilityService, $timeout,$q,Messages) {
        var key = sessionStorage.Key;
        $scope.userPerPages = 500;
        $scope.netValidator = {};
        $scope.order = "";
        $scope.init = function () {
            $scope.loadApplicationTypes();
            $scope.loadDocumentTypes();
            $scope.loadTransactionTypes();
            $scope.Search();
            ShowLoader('Please Wait...');
            $scope.data = {};
        };
        $scope.loadApplicationTypes = function () {
            documentFactory.loadApplicationTypes(key)
                .then(function (response) {
                    $scope.ApplicationTypes = response.ApplicationTypeGetList;
                })
                .catch(function (response) {

                });
        };
        $scope.loadDocumentTypes = function () {
            documentFactory.loadDocumentTypes(key)
                .then(function (response) {
                    $scope.DocumentMasterAll = response.DocumentMasterGET;
                    $scope.DocumentTypes = [];
                    processDocumentType($scope.DocumentMasterAll);
                })
                .catch(function (response) {

                });
        };


        //process unique data
        var processDocumentType = function (allItems) {
            var resuletItems = [];
            if (allItems) {
                var idList = [];
                var singleItem = {};
                for (var i = 0; i < allItems.length; i++) {
                    var index = idList.indexOf(allItems[i].DocumentId);
                    if (index === -1) {
                        singleItem = {
                            DocumentCd: allItems[i].DocumentCd,
                            DocumentId: allItems[i].DocumentId
                        };
                        resuletItems.splice(resuletItems.length, 0, singleItem);
                        idList.splice(0, 0, allItems[i].DocumentId);
                    }
                }
            }
            $scope.DocumentTypes = resuletItems;
        };

        //GetMaxDocumentItemId from DocumentMaster
        var GetMaxDocumentItemIdByDocId = function (docId) {
            var resultId = 0;
            if ($scope.DocumentMasterAll) {
                for (var i = 0; i < $scope.DocumentMasterAll.length; i++) {
                    if ($scope.DocumentMasterAll[i].DocumentId == docId && resultId < $scope.DocumentMasterAll[i].DocumentTypeId) {
                        resultId = $scope.DocumentMasterAll[i].DocumentTypeId;
                    }
                }
            }
            return (resultId+1);
        };

        $scope.orderData = function (param) {
            $scope.orderflag = !$scope.orderflag;
            if (!$scope.orderflag) {
                $scope.order = '-' + param;
            }
            if ($scope.orderflag) {
                $scope.order = param.split('-')[0];
            }
        };

        //load transaction type
        $scope.loadTransactionTypes = function () {
            documentFactory.loadTransactionTypes(key)
                .then(function (response) {
                    $scope.TransactionTypes = response.MasterTransactionList;
                });
        };

        //Load PageNames
        $scope.loadPageNames = function (forSearch) {
            var deferred = $q.defer();
            if (forSearch) {
                $scope.PageNamesSH = [];
                if ($scope.searchdata.MasterTransactionId) {
                    documentFactory.PageGetAllPageNamesByMasterTransactionId(key, $scope.searchdata.MasterTransactionId)
                        .then(function (response) {
                            $scope.PageNamesSH = response.PageModule;
                            deferred.resolve(response);
                        })
                        .catch(function () {
                            deferred.reject({});
                        });
                } else {
                    deferred.reject({});
                }
            } else {
                $scope.PageNames = [];
                if ($scope.data.MasterTransactionId) {
                    documentFactory.PageGetAllPageNamesByMasterTransactionId(key, $scope.data.MasterTransactionId)
                        .then(function (response) {
                            $scope.PageNames = response.PageModule;
                            deferred.resolve(response);
                        })
                        .catch(function () {
                            deferred.reject({});
                        });
                } else {
                    deferred.reject({});
                }
            }
            return deferred.promise;
        };

        //Load Sub Page Names
        $scope.loadPageSubName = function (forSearch) {
            var deferred = $q.defer();
            if (forSearch) {
                $scope.SubPageNamesSH = [];
                if ($scope.searchdata.PageModuleId) {
                    documentFactory.PageGetAllTabsByPageModuleId(key, $scope.searchdata.PageModuleId)
                       .then(function (response) {
                           $scope.SubPageNamesSH = response.PageModuleTabSubModule;
                           deferred.resolve(response);
                       })
                       .catch(function () {
                           deferred.reject({});
                       });
                } else {
                    deferred.reject({});
                }
            } else {
                $scope.SubPageNames = [];
                if ($scope.data.PageModuleId) {
                    documentFactory.PageGetAllTabsByPageModuleId(key, $scope.data.PageModuleId)
                       .then(function (response) {
                           $scope.SubPageNames = response.PageModuleTabSubModule;
                           deferred.resolve(response);
                       })
                       .catch(function () {
                           deferred.reject({});
                       });
                } else {
                    deferred.reject({});
                }
            }
            return deferred.promise;
        };

        //Page TabSectionGet
        $scope.GetPageTabSectionByPageSubmoduleId = function (forSearch) {
            var deferred = $q.defer();
            if (forSearch) {
                if ($scope.searchdata.PageModuleTabSubModuleId) {
                    $scope.PageTabSectionListSH = [
                        { PageTabSectionId: 1, PageTabSectionName: 'Section for Question to proceed and mandatory' },
                        { PageTabSectionId: 2, PageTabSectionName: 'Section to dsiplay the Instructions' },
                        { PageTabSectionId: 3, PageTabSectionName: 'Section to display the first checkbox' },
                        { PageTabSectionId: 4, PageTabSectionName: 'SecondCheckbox' },
                        { PageTabSectionId: 5, PageTabSectionName: 'ThirdCheckbox' },
                        { PageTabSectionId: 6, PageTabSectionName: 'FourthCheckbox' },
                        { PageTabSectionId: 7, PageTabSectionName: 'MassageProgramInstruction' },
                    ];
                    deferred.resolve({});
                } else {
                    deferred.reject({});
                }
            } else {
                if ($scope.data.PageModuleTabSubModuleId) {
                    $scope.PageTabSectionList = [
                        { PageTabSectionId: 1, PageTabSectionName: 'Section for Question to proceed and mandatory' },
                        { PageTabSectionId: 2, PageTabSectionName: 'Section to dsiplay the Instructions' },
                        { PageTabSectionId: 3, PageTabSectionName: 'Section to display the first checkbox' },
                        { PageTabSectionId: 4, PageTabSectionName: 'SecondCheckbox' },
                        { PageTabSectionId: 5, PageTabSectionName: 'ThirdCheckbox' },
                        { PageTabSectionId: 6, PageTabSectionName: 'FourthCheckbox' },
                        { PageTabSectionId: 7, PageTabSectionName: 'MassageProgramInstruction' },
                    ];
                    deferred.resolve({});
                } else {
                    deferred.reject({});
                }
            }
            return deferred.promise;
        };

        $scope.showAll = function () {
            $scope.show = !$scope.show;
            ShowLoader('Please wait...');
            if ($scope.show) {
                $timeout(function () {
                    HideLoader();
                });
               // $scope.userPerPages = $scope.lists.length;
            }
            if (!$scope.show) {
                HideLoader();
                //$scope.userPerPages = 10;
            }
        };
        $scope.clearCtrls = function () {
            $scope.data = {};
            $scope.netValidator = {};
            if ($scope.documentform) {
                $scope.documentform.$submitted = false;
                $scope.documentform.$setPristine();
            }
            $scope.ShowSaveSection = false;
        };
        $scope.add = function (form) {
            if (!validateForm(form)) {
                return;
            }
            var documentTypeId = GetMaxDocumentItemIdByDocId($scope.data.DocumentCode.DocumentId);
            var data = {};
            if (!$scope.data.DocumentMasterId || $scope.data.DocumentMasterId == 0) {
                data = Object.assign($scope.data, {
                    "DocumentMasterId": 0,
                    "DocumentId": $scope.data.DocumentCode.DocumentId,
                    "DocumentCd": $scope.data.DocumentCode.DocumentCd,
                    "DocumentTypeId": documentTypeId,
                    "CreatedBy": sessionStorage.UserID,
                    "CreatedOn": new Date()
                });
            } else {
                data = Object.assign($scope.data, {
                    "DocumentId": $scope.data.DocumentCode.DocumentId,
                    "DocumentCd": $scope.data.DocumentCode.DocumentCd,
                    //"DocumentTypeId": documentTypeId,
                    "ModifiedBy": sessionStorage.UserID,
                    "ModifiedOn": new Date()
                });
            }
            
            ShowLoader("Saving Data...");
            documentFactory.addDocument(key, data)
                .then(function (response) {
                    HideLoader();
                    if (response.Status) {
                        if (!$scope.data.DocumentMasterId || $scope.data.DocumentMasterId == 0) {
                            $scope.loadDocumentTypes();
                        }
                        $scope.Search();
                        showStatusMessage(response.Message, 'success')
                        $scope.clearCtrls();
                    }
                })
                .catch(function () {
                    showStatusMessage(Messages.bcedeff, 'error')
                });
        };

        //This function validates the for on click of submit button
        var validateForm = function (form, error) {
            $scope.netValidator.errorMessages = [];
            if (!!form) {
                form.$submitted = true;
                var errorMessages = angular.copy(form.errorMessages);
                angular.forEach(errorMessages, function (errorMessage, index) {
                    $scope.netValidator.errorMessages.push(errorMessage);
                });
            }
            if (!!error) {
                $scope.netValidator.errorMessages.push(error);
            }
            
            if (!!$scope.netValidator.errorMessages && $scope.netValidator.errorMessages.length > 0) {
                $scope.netValidator.showError = true;
                $scope.netValidator.isValid = false;
                return false;
            } else {
                $scope.netValidator.showError = false;
                $scope.netValidator.isValid = true;
                return true;
            }
        };

        $scope.Search = function () {
            ShowLoader();
            if (!$scope.searchdata) {
                $scope.searchdata = {};
            }
            documentFactory.SearchGetDocumentResultSet(key, $scope.searchdata)
                .then(function (response) {
                    HideLoader();
                    $scope.lists = response.DocumentViewModel;
                    if (!$scope.lists || $scope.lists.length == 0) {
                        return;
                    }
                })
                .catch(function (response) {
                    HideLoader();
                });
        };

        //EditItem Functionility
        $scope.EditItemClick = function (item) {
            if (item) {
                ShowLoader();
                documentFactory.GetDocumentMasterByDocumentMasterId(key, item.DocumentMasterId).then(function (res) {
                    if (res.Status) {
                        var EditItem = angular.copy(res.DocumentMasterData);
                        $scope.ShowSaveSection = true;
                        $scope.data.MasterTransactionId = EditItem.MasterTransactionId;
                        $scope.loadPageNames().then(function () {
                            $scope.data.PageModuleId = EditItem.PageModuleId;
                            $scope.loadPageSubName().then(function () {
                                $scope.data.PageModuleTabSubModuleId = EditItem.PageModuleTabSubModuleId;
                                $scope.GetPageTabSectionByPageSubmoduleId().then(function () {
                                    $scope.data.PageTabSectionId = EditItem.PageTabSectionId;
                                    $scope.data = Object.assign($scope.data, EditItem);
                                    $scope.data.DocumentCode = getSelectedDocumentIdCD($scope.data.DocumentId);
                                    HideLoader();
                                }, function () {
                                    $scope.data = Object.assign($scope.data, EditItem);
                                    $scope.data.DocumentCode = getSelectedDocumentIdCD($scope.data.DocumentId);
                                    HideLoader();
                                });
                            }, function () {
                                $scope.data = Object.assign($scope.data, EditItem);
                                $scope.data.DocumentCode = getSelectedDocumentIdCD($scope.data.DocumentId);
                                HideLoader();
                            });
                        }, function () {
                            $scope.data = Object.assign($scope.data, EditItem);
                            $scope.data.DocumentCode = getSelectedDocumentIdCD($scope.data.DocumentId);
                            HideLoader();
                        });
                    } else {
                        HideLoader();
                    }
                }, function () { HideLoader(); });
            }
        };

        //Add new button click display section
        $scope.AddNewButtonClick = function () {
            $scope.ShowSaveSection = true;
            if (!$scope.data) {
                $scope.data = {};
            }
            $scope.data.IsActive = true;
        };
        var getSelectedDocumentIdCD = function (id) {
            var res = {};
            if ($scope.DocumentTypes) {
                var getItem = $.grep($scope.DocumentTypes,function (i) {
                    return (i.DocumentId == id);
                });
                if (getItem && getItem.length>0) {
                    res = getItem[0];
                }
            }
            return res;
        };

        $scope.DeleteItem = function (item) {
            if (confirm(Messages.General.Confirm.Delete)) {
                ShowLoader();
                documentFactory.GetDocumentMasterByDocumentMasterId(key, item.DocumentMasterId).then(function (res) {
                    if (res.Status) {
                        var selectedItem = angular.copy(res.DocumentMasterData);
                        selectedItem.IsActive = false;
                        selectedItem.IsDeleted = true;
                        documentFactory.addDocument(key, selectedItem).then(function (response) {
                            $scope.Search();
                        }, function () {
                            HideLoader();
                        });
                    }
                }, function () { HideLoader(); });
            }
        };

        $scope.ActiveDeActiveItem = function (item) {
            ShowLoader();
            documentFactory.GetDocumentMasterByDocumentMasterId(key, item.DocumentMasterId).then(function (res) {
                if (res.Status) {
                    var selectedItem = angular.copy(res.DocumentMasterData);
                    selectedItem.IsActive = item.IsActive;
                    documentFactory.addDocument(key, selectedItem).then(function (response) {
                        $scope.Search();
                    }, function () {
                        HideLoader();
                    });
                }
            }, function () { HideLoader(); });
        };

        function AuditVisitInfoSave() {
            var data = {
                "PageName": "DocumentManagement",
                "SessionId": sessionStorage.Key,
                "IndividualId": sessionStorage.IndividualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };
        AuditVisitInfoSave();

        function trim(str) {
            return str.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
        };
    }
})();