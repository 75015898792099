(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeIndividualPrimaryBoardController", BackofficeIndividualPrimaryBoardController);

    BackofficeIndividualPrimaryBoardController.$inject = ["$rootScope", "$scope", "backofficeIndividualPrimaryBoardService", "multipleFileUploadService", "WebApiUrl", "utilityService", "Messages"
    ];

    function BackofficeIndividualPrimaryBoardController($rootScope, $scope, backofficeIndividualPrimaryBoardService, multipleFileUploadService, WebApiUrl, utilityService, Messages) {

        $scope.documentByIndividualDocumentId = multipleFileUploadService.documentByIndividualDocumentId;

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "primaryBoard") {
                $scope.IndividualId = individualId;
                // ShowLoader();
                $rootScope.individualCurrentTab.isLoadedOnce = true;
                $scope.individualCertificationInfoGETBYIndividualId($scope.IndividualId);
                HideLoader();
            }
        });

        $scope.addPrimaryBoardbutton = true;
        $scope.primaryBoardFormShow = false;
        $scope.isPrimaryBoardEdit = false;

        $scope.natValidation = {};

        var validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.editPrimaryBoard = function (item) {
            $scope.addPrimaryBoardbutton = false;
            $scope.primaryBoard = angular.copy(item);
            $scope.primaryBoardFormShow = true;
            $scope.isPrimaryBoardEdit = true;
        }
        $scope.cancelprimaryBoardForm = function () {
            $scope.addPrimaryBoardbutton = true;
            $scope.primaryBoard = {};
            $scope.primaryBoardFormShow = false;
            $scope.isPrimaryBoardEdit = false;
        }

        $scope.addNewprimaryBoard = function () {
            $scope.addPrimaryBoardbutton = false;
            $scope.primaryBoardFormShow = true;
            $scope.primaryBoard = {
                IndividualId: $scope.IndividualId,
                IsActive: true,
                IsDeleted: false
            };
            $scope.isPrimaryBoardEdit = false;
        }

        $scope.individualCertificationInfoGETBYIndividualId = function (IndividualId) {
            try {
                ShowLoader("Please Wait...");
                backofficeIndividualPrimaryBoardService.individualCertificationInfoGETBYIndividualId(sessionStorage.Key, IndividualId)
                    .then(function (response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse(response)) {
                            $scope.PrimaryBoardList = response.IndividualCertificationResponse;
                        }
                        HideLoader();
                    },
                        function (response) {
                            HideLoader();
                            $scope.showStatusMessage(response.data.Message, "error");
                        });
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.savePrimaryBoard = function (currentForm) {
            try {
                if (!validateForm(currentForm)) {
                    return;
                };

                ShowLoader("Please Wait...");
                $scope.primaryBoard.CertificationTypeId = 1;
                backofficeIndividualPrimaryBoardService.saveIndividualCertificationInfo(sessionStorage.Key, $scope.primaryBoard)
                    .then(function (response) {
                        $scope.individualCertificationInfoGETBYIndividualId($scope.IndividualId);
                        $scope.primaryBoardFormShow = false;
                        $scope.addPrimaryBoardbutton = true;
                        HideLoader();
                    });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        }
        $scope.deletePrimaryBoard = function (primaryBoardObject) {
            try {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    $rootScope.individualEventLog('deleteStatus.deleteConfirmed', $scope.IndividualId);
                    primaryBoardObject.IsDeleted = true;
                    ShowLoader("Please Wait...");

                    backofficeIndividualPrimaryBoardService.saveIndividualCertificationInfo(sessionStorage.Key, primaryBoardObject)
                        .then(function (response) {
                            $scope.individualCertificationInfoGETBYIndividualId($scope.IndividualId);
                            HideLoader();
                        });
                }
                else {
                    $rootScope.individualEventLog('deleteStatus.deleteCanceled', $scope.IndividualId);
                }
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        }
    }
})();