(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeProviderDEAService', backofficeProviderDEAService)

    backofficeProviderDEAService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeProviderDEAService($http, $q, WebApiUrl, AppConfig) {

        var _getIndividualLicenseAddInfo = function (Key, IndividualId, ApplicationId, LicenseNumber) {
            if (LicenseNumber)
                return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualLicenseAddInfo/' + Key + '?EntityId=' + IndividualId + '&ApplicationId=' + '&LicenseNumber=' + LicenseNumber));
            else
                return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualLicenseAddInfo/' + Key + '?EntityId=' + IndividualId + '&ApplicationId='));
        };

        var _saveIndividualLicenseAddInfo = function (Key, objData) {
            return returnDefferedResult($http.post(WebApiUrl + "Individual/SaveIndividualLicenseAddInfo/" + Key + '?Source=' + AppConfig.Source, objData));
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        var _getProviderLicenseDetailBYProviderId = function (Key, ProviderId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Provider/ProviderLicenseDetailBYProviderId/' + Key + '?ProviderId=' + ProviderId + '&Source=' + AppConfig.Source));
        };

        return {
            getIndividualLicenseAddInfo: _getIndividualLicenseAddInfo,
            saveIndividualLicenseAddInfo: _saveIndividualLicenseAddInfo,
            getProviderLicenseDetailBYProviderId: _getProviderLicenseDetailBYProviderId
        }
    }
})();