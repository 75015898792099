(function () {
  'use strict'
  angular.module('app.InitialApplication')
    .directive('initialApplicationCertification', initialApplicationCertificationDirective);

  initialApplicationCertificationDirective.$inject = [];

  function initialApplicationCertificationDirective() {
    return {
      restrict: 'E',
      scope: {
        headingPosition: "=headingPosition"
      },
      templateUrl: "app/components/individual/application/directives/certification/certification.html",
      controller: "InitialApplicationCertificationController"
    }
  }
})();