(function () {
    'use strict'

    angular.module('app.enforcement')
    .factory('ComplaintCorrespondenceService', ['$http', '$q', 'WebApiUrl', 'AppConfig', ComplaintCorrespondenceService]);

    function ComplaintCorrespondenceService($http, $q, WebApiUrl, AppConfig) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getInvolvedParty = function (key, complaintId, complaintInvolvedPartyId) {
            var request = {
                ComplaintId: complaintId,
                ComplaintInvolvedPartyId: complaintInvolvedPartyId
            };
            return responseHandler($http.post(WebApiUrl + 'Complaint/GetComplaintInvolvedParty/' + key + '/', request));
        };

        var _sendCorrespondences = function (key, correspondences) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/SendComplaintCorrespondences/' + key + '/', correspondences));
        };

        var _sendCorrespondencesFromPublic = function (key, correspondences) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/SendComplaintCorrespondencesFromPublic/' + key + '/', correspondences));
        };

        var _getCorrespondences = function (key, correspondenceSearch) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/GetComplaintCorrespondences/' + key + '/', correspondenceSearch));
        };
        
        var _getTemplate = function (key, templateId) {
            var urlParams = {
                id: templateId
            };
            return responseHandler($http.get(WebApiUrl + 'Template/GetTemplateById/' + key + '/', { params: urlParams }));
        };

        var _getConfigurationBySetting = function (key, setting) {
            var urlParams = {
                Setting: setting
            };

            return responseHandler($http.get(WebApiUrl + 'Configuration/ConfigurationGetbySettings/' + key +'/', { params: urlParams }));
        };

        return {
            getInvolvedParty: _getInvolvedParty,
            sendCorrespondences: _sendCorrespondences,
            sendCorrespondencesFromPublic: _sendCorrespondencesFromPublic,
            getCorrespondences: _getCorrespondences,
            getTemplate: _getTemplate,
            getConfigurationBySetting: _getConfigurationBySetting
        };
    }
})();
