/// <reference path="status-messages.ts" />

(function() {
    'use strict'

    angular.module('app.core')
    .controller('StatusMessagesController', ['$scope', StatusMessagesController]);

    /**
     * 
     * @param {StatusMessagesControllerScope} $scope 
     */
    function StatusMessagesController($scope) {
        $scope.init = function () {
            $scope.successMessage = [];
            $scope.informationMessage = [];
            $scope.errorMessage = [];
            $scope.genericMessage = [];
        };
    }
})();