﻿(function () {
    'use strict'
    angular.module('app.core').directive('providerAddressDirective', function () {
        return {
            restrict: 'EA',
            scope: {
                providerId: '=providerId',
                iseditable: '=iseditable'
            },
            controller: "providerAddressController",
            templateUrl: "app/core/directive/ProviderAddress/provider-address-template.html"
        };
    });
})();