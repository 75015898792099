(function () {
  'use strict'

  angular.module('app.InitialApplication')
    .factory('initialApplicationLicenseHistoryService', initialApplicationLicenseHistoryService)

  initialApplicationLicenseHistoryService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

  function initialApplicationLicenseHistoryService($http, $q, WebApiUrl, AppConfig) {


    var _getIndividualPreviousLicense = function (Key, individualId, applicationId, userId) {
      return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualPreviousLicenseBYIndividualId/' + Key + '?IndividualId=' + individualId +
        '&ApplicationId=' + 0 + '&UserId=' + userId +
        '&Source=' + AppConfig.Source));
    };

    var _getIndividualExperience = function (Key, individualId) {
      return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualExperience/' + Key + '?IndividualId=' + individualId +
        '&Source=' + AppConfig.Source));
    };

    var _individualEducationBYIndividualId = function (Key, individualId, applicationId, userId) {
      return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualEducationBYIndividualId/' + Key + '?IndividualId=' + individualId +
        '&ApplicationId=' + 0 + '&userId=' + userId +
        '&Source=' + AppConfig.Source));
    };

    var _individualExamBYIndividualId = function (Key, individualId, applicationId) {
      return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualExamBYIndividualId/' + Key + '?IndividualId=' + individualId +
        '&ApplicationId=' + 0 +
        '&Source=' + AppConfig.Source));
    };

    var _getIndividualEducationField = function (Key, individualId, individualEducationId) {
      return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualEducationField/'
        + Key + '?IndividualId=' + individualId +
        '&IndividualEducationId=' + individualEducationId +
        '&Source=' + AppConfig.Source));
    };

    var _getIndividualDeclaration = function (Key, individualId, applicationId) {
      return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualDeclaration/'
        + Key + '?IndividualId=' + individualId +
        '&ApplicationId=' + applicationId +
        '&Source=' + AppConfig.Source));
    };

    var _individualDeclarationSave = function (individualDeclaration, key) {
      return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualDeclarationSave/' + key +
        "?Source=" + AppConfig.Source, individualDeclaration));
    };

    var _countyGetAll = function (Key, stateCode) {
      return returnDefferedResult($http.get(WebApiUrl + 'County/CountyGetAll/'
        + Key + '?StateCode=' + stateCode));
    };

    var _educationSave = function (individualId, applicationId, userId, individualEducation, key) {
      return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualEducationSave/' + key +
        "?individualId=" + individualId + "&applicationId=" + (!!applicationId ? applicationId : 0) + "&UserId=" + userId + "&Source=" + AppConfig.Source, individualEducation));
    };

    var _examSave = function (individualExam, key) {
      return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualExamSave/' + key +
        "?Source=" + AppConfig.Source, individualExam));
    };

    var _saveIndividualExperience = function (individualExperience, key) {
      return returnDefferedResult($http.post(WebApiUrl + 'Individual/SaveIndividualExperience/' + key +
        "?Source=" + AppConfig.Source, individualExperience));
    };

    var _saveIndividualPreviousLicense = function (individualPreviousLicense, individualId, applicationId, userId, key) {
      return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualPreviousLicenseSave/' + key + '?IndividualId=' + individualId +
        '&ApplicationId=' + (!!applicationId ? applicationId : 0) + '&UserId=' + userId +
        "&Source=" + AppConfig.Source, individualPreviousLicense));
    };

    var _educationFieldSave = function (individualEducationField, key) {
      return returnDefferedResult($http.post(WebApiUrl + 'Individual/SaveIndividualEducationField/' + key +
        "?Source=" + AppConfig.Source, individualEducationField));
    };

    var _getEmployerList = function (key, searchString, providerTypeId, searchType) {
      return returnDefferedResult($http.get(WebApiUrl + "Individual/GetEmployerNameAndAddress/" + key + "?SearchString=" + searchString + "&ProviderTypeId=" + providerTypeId + "&SearchType=" + searchType));
    };

    var _getRefTablesGetAll = function (key, tableKey) {
      var deferred = $q.defer();

      $http.get(WebApiUrl + 'TypeValues/RefTablesGetAll/' + key + '?tableKey=' + tableKey)
        .then(function(data){ data = data.data;
          deferred.resolve(data);
        }, function(error) { error = error.data;
          deferred.reject(error);
        });
      return deferred.promise;
    }

    var returnDefferedResult = function (request) {
      var deferred = $q.defer();
      request.then(function (response) {
        deferred.resolve(response.data);
      }, function (response) {
        deferred.reject(response);
      });
      return deferred.promise;
    };


    return {
      countyGetAll: _countyGetAll,
      educationSave: _educationSave,
      examSave: _examSave,
      saveIndividualExperience: _saveIndividualExperience,
      saveIndividualPreviousLicense: _saveIndividualPreviousLicense,
      educationFieldSave: _educationFieldSave,
      getEmployerList: _getEmployerList,
      getIndividualExperience: _getIndividualExperience,
      getIndividualPreviousLicense: _getIndividualPreviousLicense,
      getIndividualDeclaration: _getIndividualDeclaration,
      individualDeclarationSave: _individualDeclarationSave,
      individualEducationBYIndividualId: _individualEducationBYIndividualId,
      individualExamBYIndividualId: _individualExamBYIndividualId,
      getIndividualEducationField: _getIndividualEducationField,
      getRefTablesGetAll: _getRefTablesGetAll
    };
  }
})();