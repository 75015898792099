(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .directive('individualPersonal', individualPersonalDirective);

    individualPersonalDirective.$inject = [];

    function individualPersonalDirective() {
        return {
            restrict: 'E',
            scope: {
                individualId: '=',
                checkServerResponse: "&",
                hideStatusMessage: "&",
                hideValidationSummary: "&",
                showStatusMessage: "&",
                validateData: "&"
            },
            templateUrl: 'app/components/backoffice/individual/personal/personal.html',
            controller: 'IndividualPersonalController'
        };
    }
})();