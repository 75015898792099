(function () {
    var imageUrl = '';

    angular.module('natApp').directive('inlumonLoader', ['$document', '$timeout', function ($document, $timeout) {
        return {
            restrict: 'A',
            priority: 500,
            link: {
                pre: function (scope, element, attrs) {
                        var loaderGroupNames = attrs.inlumonLoader.split(' ');
                        var loaderLookFor = attrs.inlumonLoaderLookFor;
                        var loaderInitValues = (typeof (attrs.inlumonLoaderInit) !== 'undefined') ? attrs.inlumonLoaderInit.split(' ') : [];
                        var loaderIsFixed = (typeof (attrs.inlumonLoaderFixed) !== 'undefined') ? true : false;

                        var scopeElement = null;
                        if (!!loaderLookFor) {
                            scopeElement = element.closest(loaderLookFor);
                        } else {
                            scopeElement = element;
                        }

                        var effectiveScope = scopeElement.scope();

                        for (var i = 0; i < loaderGroupNames.length; i++) {
                            //Need to execute this next part in an IIFE (Immediately invoked function expression).
                            //The reason is that the functions defined in loaderMemberFunction and the "loader group invoking function" need to use
                            //the "closure scopes".  Closure scopes store the variables declared outside a function by reference, so when the variables 
                            //outside of the function change as a result of the for-loop iteration, then those changes are reflected in the closure scopes 
                            //of, say, "loaderMemberFunction".  Which means that even though you only wanted to use the variables defined by the values you 
                            //get when i==0, it will instead give you the values of when i==n-1 (the end of the loop) when they are invoked later in the application.
                            (function () {
                                var loaderGroupName = loaderGroupNames[i];
                                var loaderInitValue = (i >= loaderInitValues.length) ? false : (loaderInitValues[i].toLowerCase() === 'true');

                                var loaderGroupArr = '_inlumonLoader__' + loaderGroupName;
                                var loaderGroupState = '_inlumonLoader__' + loaderGroupName + '_state';
                                var loaderGroupId = '_inlumonLoader__' + loaderGroupName + '_id';
                                var loaderGroupSelector = '#' + loaderGroupId;
                                var loaderBlockStr = '';

                                if (!!imageUrl) {
                                    loaderBlockStr = '<div id="{0}" class="hideInPdf {1}"><div><p><img width="80" height="80" src="{3}" /></p><p><span>{2}</span></p></div></div>';
                                } else {
                                    loaderBlockStr = '<div id="{0}" class="hideInPdf {1}"><div><p><i class="fa fa-spinner fa-pulse fa-4x"></i></p><p><span>{2}</span></p></div></div>';
                                }

                                var loaderStyle = (loaderIsFixed) ? 'inlumon-loader inlumon-loader-fixed' : 'inlumon-loader';

                                //Setup the element
                                if (!loaderIsFixed) {
                                    element.css('position', 'relative');
                                }

                                //Setup the scope to allow for multiple elements (a loader group) to have, essentially, the same loader callback and state
                                if (!Array.isArray(effectiveScope[loaderGroupArr])) {
                                    effectiveScope[loaderGroupArr] = [];
                                    effectiveScope[loaderGroupState] = false;
                                }

                                //Add a function to this loader group
                                var loaderMemberFunction = function (isLoading, message) {
                                    effectiveScope[loaderGroupState] = isLoading;

                                    if (!message) {
                                        message = 'Loading...';
                                    }

                                    $timeout(function () {
                                        var elementExists = element.find(loaderGroupSelector).length > 0;
                                        var loaderBlockElement = angular.element(loaderBlockStr.format(loaderGroupId, loaderStyle, message, imageUrl));
                                        
                                        if (elementExists) {
                                            element.find(loaderGroupSelector).remove();
                                            //element.find(loaderGroupSelector).remove();
                                        }
    
                                        if (!!effectiveScope[loaderGroupState]) {
                                            element.prepend(loaderBlockElement);
                                        }
                                    },1);
                                };

                                loaderMemberFunction(loaderInitValue);

                                effectiveScope[loaderGroupArr].push(loaderMemberFunction);

                                //Now define the loader group function caller.
                                if (typeof (effectiveScope[loaderGroupName]) !== 'function') {
                                    //Setup the function that will call the entire loader group
                                    effectiveScope[loaderGroupName] = function (isLoading, message) {
                                        for (var loaderFn in effectiveScope[loaderGroupArr]) {
                                            effectiveScope[loaderGroupArr][loaderFn](isLoading, message);
                                        }
                                    }
                                }
                            })();
                        }
                    }
            }
        };
    }]);
})();