(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationVeteranInfoController", InitialApplicationVeteranInfoController);

    InitialApplicationVeteranInfoController.$inject = ["$rootScope", "$scope",
        "initialApplicationVeteranInfoService", "initialApplicationstatusService", "utilityService", "WebApiUrl", "AppConfig", "Messages"
    ];

    function InitialApplicationVeteranInfoController($rootScope, $scope,
        initialApplicationVeteranInfoService, initialApplicationstatusService, utilityService, WebApiUrl, AppConfig, Messages) {

        if (AppConfig.EnableConsoleLogging)
            console.log('Veteran Info : ', $scope.veteranInfoConfirmation);
        $scope.IndividualConfirmationVeteran = {};
        $scope.question3 = {};
        $scope.question4 = {};
        $scope.current = {};
        $scope.init = function () {
            $scope.veteranInfoTemplate = angular.copy($scope.veteranInfo);
            $scope.datePicker = {
                fromDateOpened: false,
                toDateOpened: false,
                format: 'M!/d!/yyyy',
                toDateOptions: {
                    minDate: null
                },
                fromDateOptions: {}
            };

            $scope.getVeteranInfoConfig();
            $scope.viewConfig = {
                showAddVeteranInfo: false
            };
        };

        $scope.documentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.individualId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "R",
            LoadByDocIdAndDocCode: true,
            DocId: 57,
            DocCode: "D1057",
            LoadDocumentsWithoutBroadCast: true,
            hideUploadedFor: true
        };

        $scope.question3.documentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.individualId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "R",
            LoadByDocIdAndDocCode: true,
            DocId: 106,
            DocCode: 'D1106',
            LoadDocumentsWithoutBroadCast: true,
            hideUploadedFor: true
        };

        $scope.question4.documentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.individualId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "R",
            LoadByDocIdAndDocCode: true,
            DocId: 116,
            DocCode: 'D1116',
            LoadDocumentsWithoutBroadCast: true,
            hideUploadedFor: true
        };


        $scope.getVeteranInfoConfig = function () {
            ShowLoader();
            var relativeFilePath = "/individual/application/veteran-info/veteran-info.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function (data) {
                    $scope.veteranInfoConfig = data;
                    $scope.isConfigLoaded = true;
                }, function (error) {
                    HideLoader();
                    ShowErrMsg("veteranInfoConfig", "Some error occurred,Unable to load the Military Service Information config")
                });

            getIndividualVeteranConfByIndividualId();
        };

        var getIndividualVeteranConfByIndividualId = function (next, skipNextTab) {
            ShowLoader();
            initialApplicationVeteranInfoService.getIndividualVeteranConfByIndividualId(sessionStorage.Key, $scope.individualId, $scope.applicationId).then(function (response) {
                HideLoader();
                $scope.tab.skipNextTab = skipNextTab;
                $scope.IndividualConfirmationVeteran = response.IndividualConfirmationVeteran;
                if (next) {
                    $scope.tab.individualConfirmationVeteran = $scope.IndividualConfirmationVeteran;
                    $scope.openNextTab({ currentTab: $scope.tab });
                }
                if ($scope.tab.skipNextTab == true) {
                    $scope.$emit('setCurrentTabNow');
                }
                if (skipNextTab && !!skipNextTab.fromDeficiency) {
                    $scope.$emit('deficiencyExecuted', { success: true });
                }
                if (!$scope.IndividualConfirmationVeteran) {
                    $scope.IndividualConfirmationVeteran = {
                        IsActive: true,
                        IsDeleted: false,
                    }
                    $scope.IndividualConfirmationVeteran.IndividualLicenseId = $scope.individualLicense ? $scope.individualLicense.IndividualLicenseId : null;
                    $scope.IndividualConfirmationVeteran.IndividualId = $scope.individualId;
                    $scope.IndividualConfirmationVeteran.ApplicationId = $scope.applicationId;

                }
            }, function (error) {
                HideLoader();
            });
        }

        $scope.setToDateOptions = function () {
            if ($scope.veteranInfo.ServiceDateFrom != undefined && angular.isDate($scope.veteranInfo.ServiceDateFrom)) {
                var minDate = angular.copy($scope.veteranInfo.ServiceDateFrom);
                $scope.datePicker.toDateOptions.minDate = minDate;
                $scope.datePicker.toDateOptions.minDate.setDate($scope.datePicker.toDateOptions.minDate.getDate() + 1);
            }
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        $scope.setVeteranForm = function (currentVeteranForm) {
            $scope.currentVeteranForm = currentVeteranForm;
        };

        var createNewVeteranInfo = function (isVeteranEdit, veteranInfo) {
            if (!isVeteranEdit) {
                $scope.veteranInfo = angular.copy($scope.veteranInfoTemplate);
                $scope.veteranInfo.IndividualId = $scope.individualId;
                $scope.veteranInfo.IndividualVeteranConfirmationId = $scope.veteranInfoConfirmation.IndividualVeteranConfirmationId;
                angular.forEach($scope.veteranInfo.VeteranBranches, function (veteranBranch, index) {
                    veteranBranch.IndividualId = $scope.individualId;
                })
            } else {
                $scope.veteranInfo = angular.copy(veteranInfo);
                if (!!$scope.veteranInfo) {
                    if (!!$scope.veteranInfo.ServiceDateFrom && !angular.isDate($scope.veteranInfo.ServiceDateFrom))
                        $scope.veteranInfo.ServiceDateFrom = new Date($scope.veteranInfo.ServiceDateFrom);
                    if (!!$scope.veteranInfo.ServiceDateTo && !angular.isDate($scope.veteranInfo.ServiceDateTo))
                        $scope.veteranInfo.ServiceDateTo = new Date($scope.veteranInfo.ServiceDateTo);
                    $scope.setToDateOptions();
                }
            }

        };

        var ShowErrMsg = function (propName, errorMessage) {
            propName = (!!propName) ? propName : "veteranInfoErr";
            errorMessage = (!!errorMessage) ? errorMessage : $scope.veteranInfoConfig.ValidationMsg.APIError;
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            var propExist = false;
            for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                if ($scope.natValidation.errorMessages[i].propName == propName)
                    propExist = true;
            }
            if (!propExist)
                $scope.natValidation.errorMessages.push({
                    propName: propName,
                    errorMessage: errorMessage
                });

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        var validateForm = function (currentForm, currentVeteranForm) {
            $scope.natValidation.errorMessages = [];

            if (!!currentForm) {
                currentForm.$submitted = true;
                if (!!currentForm.errorMessages) {
                    $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
                }
            }
            if ($scope.IndividualConfirmationVeteran.SpouseofActiveMilitaryMember == true) {
                if (!$scope.documentUpload.documentsList || $scope.documentUpload.documentsList.length == 0) {
                    $scope.natValidation.errorMessages.push({ errorMessage: 'Please upload at least one document' });
                }
            }
            if (!!$scope.current.AsylumSpecialCircumstance && !!$scope.current.AsylumSpecialCircumstance.AsylumSpecialCircumstance[0].legalResponse.ConfirmationResponse) {
                if (!$scope.question3.documentUpload.documentsList || $scope.question3.documentUpload.documentsList.length == 0) {
                    var errorObj = {
                        propName: "AsylumSpecialCircumstance",
                        errorMessage: Messages.cfaaccb
                    };
                    $scope.natValidation.errorMessages.push(errorObj);
                }
            }
            if (!!$scope.current.HonorablyDischargeVeteranCircumstance && !!$scope.current.HonorablyDischargeVeteranCircumstance.HonorablyDischargeVeteranCircumstance[0].legalResponse.ConfirmationResponse) {
                if (!$scope.question4.documentUpload.documentsList || $scope.question4.documentUpload.documentsList.length == 0) {
                    var errorObj = {
                        propName: "HonorablyDischargeVeteranCircumstance",
                        errorMessage: Messages.cfaaccc
                    };
                    $scope.natValidation.errorMessages.push(errorObj);
                }
            }
            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.veteranBranchChanged = function (currentBranch, veteranInfo) {
            angular.forEach(veteranInfo.VeteranBranches, function (veteranBranch, index) {
                if (veteranBranch.BranchofServicesId == currentBranch.BranchofServicesId) {
                    veteranBranch.BranchofServicesIdResponse = true;
                } else {
                    veteranBranch.BranchofServicesIdResponse = false;
                }
            });
        };

        $scope.openAddEditVeteranInfo = function (isVeteranEdit, veteranInfo) {
            ShowLoader();
            $scope.viewConfig.showAddVeteranInfo = true;
            $scope.isVeteranEdit = isVeteranEdit;
            createNewVeteranInfo(isVeteranEdit, veteranInfo);
            HideLoader();
        };

        $scope.closeAddEditVeteranInfo = function (hideLoader) {
            if (!hideLoader)
                ShowLoader();
            validateForm(null, null);
            $scope.viewConfig.showAddVeteranInfo = false;
            $scope.currentVeteranForm = null;
            if (!hideLoader)
                HideLoader();
        };

        $scope.saveVeteranInfo = function (currentVeteranForm, next, skipNextTab) {
            $scope.tab.isInvalid = false;
            if (!validateForm($scope.currentForm)) {
                $scope.tab.isInvalid = true;
                initialApplicationstatusService.setVeteran("invalid");
                if (skipNextTab == true) {
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
                if (skipNextTab && !!skipNextTab.fromDeficiency) {
                    $scope.$emit('deficiencyExecuted', { success: false });
                }
                HideLoader();
                return;
            }
            $scope.IndividualConfirmationVeteran.ModifiedOn = new Date();
            ShowLoader("Please wait...");
            if (!$scope.isRenewal)
                $scope.$broadcast("SaveDeficiency", {});
            $scope.IndividualConfirmationVeteran.IndividualLicenseId = $scope.individualLicense ? $scope.individualLicense.IndividualLicenseId : null;
            $scope.IndividualConfirmationVeteran.IndividualId = $scope.individualId;
            $scope.IndividualConfirmationVeteran.ApplicationId = $scope.applicationId;
            initialApplicationVeteranInfoService.saveVeteranInfoConfirmation([$scope.IndividualConfirmationVeteran], sessionStorage.Key)
                .then(function (response) {
                    getIndividualVeteranConfByIndividualId(next, skipNextTab);
                    $scope.$broadcast("AsylumSpecialCircumstance", {});
                    $scope.$broadcast("HonorablyDischargeVeteranCircumstance", {});
                    if (response.Status == false) {
                        initialApplicationstatusService.setVeteran("error");
                        ShowErrMsg("unableToSaveVeteranInfo", response.Message);
                    } else {
                        initialApplicationstatusService.setVeteran("success");
                    }
                    if (!$rootScope.ProcessLoaderExternally)
                        HideLoader();
                }, function (data) {
                    ShowErrMsg("unableToSaveVeteranInfo", data);
                });
        };

        $scope.deleteVeteranInfo = function (veteran, indexToDelete) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                ShowLoader("Please wait...");
                veteran.IsDeleted = true;
                initialApplicationVeteranInfoService.saveVeteranInfo([veteran], sessionStorage.Key)
                    .then(function (response) {
                        if (response.Status == false) {
                            ShowErrMsg("unableDeleteVeteranInfo", response.Message);
                        } else {
                            $scope.veteranInfoConfirmation.IndividualVeteranlst.splice(indexToDelete, 1)
                        }
                        HideLoader();
                    }, function (data) {
                        ShowErrMsg("unableDeleteVeteranInfo", data);
                    });
            }
        };

        $scope.saveVeteranConfirmation = function (currentForm, currentVeteranForm) {
            ShowLoader("Please wait...");
            if (!validateForm(currentForm, currentVeteranForm)) {
                initialApplicationstatusService.setVeteran("invalid");
                HideLoader();
                return;
            }
            //	//IndividualVeteran
            $scope.veteranInfoConfirmation.ModifiedOn = new Date();
            $scope.veteranInfoConfirmation.IsActive = true;
            $scope.veteranInfoConfirmation.ApplicationId = $scope.applicationId;
            $scope.veteranInfoConfirmation.IndividualLicenseId = $scope.individualLicense.IndividualLicenseId;

            initialApplicationVeteranInfoService.saveVeteranInfoConfirmation($scope.veteranInfoConfirmation, sessionStorage.Key)
                .then(function (response) {
                    if (response.Status == false) {
                        initialApplicationstatusService.setVeteran("error");
                        ShowErrMsg("unableToSaveVeteranInfo", response.Message);
                        $scope.isSavedSuccessFully = false;
                    } else {
                        initialApplicationstatusService.setVeteran("success");
                    }
                    if (!$rootScope.ProcessLoaderExternally)
                        HideLoader();
                }, function (data) {
                    initialApplicationstatusService.setVeteran("error");
                    if (!$rootScope.ProcessLoaderExternally)
                        HideLoader();
                    ShowErrMsg("unableToSaveVeteranInfo", data);
                });
        };

        $scope.$on("SaveRenewal", function (event, args) {
            $scope.saveVeteranInfo($scope.currentForm, $scope.currentVeteranForm);
        });

        $scope.$on('SwitchingTab', function (event, args) {
            if (args.fromTab == $scope.tab.name) {
                if (!$scope.isBackoffice) {
                    $scope.saveVeteranInfo($scope.currentForm, false, true);
                } else {
                    $scope.$broadcast("SaveDeficiency", {});
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
            }
        });

        $scope.$on('deficiencyResolve', function (event, args) {
            if (args.tabName == 'veteranInfo') {
                $scope.saveVeteranInfo(scope.currentForm, false, { fromDeficiency: true });
            }
        });

        $scope.clearTabConfirmation = function () {
            $scope.$emit("clearTabConfirmation", { currentTab: $scope.tab });
        };

        $scope.init();

    }
})();