(function () {
    'use strict'

    angular.module('natApp').directive('logActivity', [activityLoggerDirective]);
    function activityLoggerDirective() {
        return {
            restrict: 'AE',
            require: ['logActivity', '?^logParams'],
            link: function ($scope, element, attrs, ctrls) {
                //Optional name for the activity logger instance
                //Primarily used for debugging purposes
                var logCtrl = ctrls[0];
                var paramsCtrl = ctrls[1];

                logCtrl.name = attrs.logActivity;

                if (!!paramsCtrl) { 
                    $scope.params = paramsCtrl.params;
                    var removeWatch = $scope.$watch('params', function (newVal) {
                        logCtrl.setupParams(paramsCtrl.$scope);
                    }, true);
    
                    $scope.$on('$destroy', function () {
                        removeWatch(); 
                    });
                } else {
                    logCtrl.setupParams({});
                }
            },
            controller: ['$state', 'utilityService', 'activityLoggingService', function ($state, utilityService, activityLoggingService) {
                var self = this;

                self.config = {};
                self.logType = null;
                self.pageModuleIds = null;

                utilityService.getConfigData('/core/directive/inlumon-activity-logging/inlumon-activity-logging.server-config.json')
                .then(function (configResults) {
                    self.config = configResults;

                    self.pageModuleId = (!!self.pageModuleId) ? self.pageModuleId : 1;
                    self.pageModuleTabSubModuleId = (!!self.pageModuleTabSubModuleId) ? self.pageModuleTabSubModuleId : 1;
                    self.pageTabSectionId = (!!self.pageTabSectionId) ? self.pageTabSectionId : 1;

                    self.logType = (!!self.logType) ? self.logType : 'L';
                });

                self.setupParams = function (params) {
                    var pageIds = [];
                    if (!!params.pageModuleIds) {
                        pageIds = params.pageModuleIds.split(',').select(function (item) { return item.trim(); });
                    }

                    self.individualId = (!!params.individualId) ? params.individualId : 0;
                    self.providerId = (!!params.providerId) ? params.providerId : 0;
                    self.applicationId = (!!params.applicationId) ? params.applicationId : 0;

                    self.pageModuleId = (!!pageIds[0]) ? pageIds[0] : 1;
                    self.pageModuleTabSubModuleId = (!!pageIds[1]) ? pageIds[1] : 1;
                    self.pageTabSectionId = (!!pageIds[2]) ? pageIds[2] : 1;

                    self.logType = (!!params.logType) ? params.logType : 'L';
                };

                // Anything that needs to be shared with the directives that require logActivity needs to be added to "this", not "$scope"
                self.getIndividualId = function () {
                    return (!!self.individualId) ? self.individualId : 0;
                };

                self.getProviderId = function () {
                    return (!!self.providerId) ? self.providerId : 0;
                };

                self.getApplicationId = function () {
                    return (!!self.applicationId) ? self.applicationId : 0;
                };

                self.getConfigData = function () {
                    return self.config;
                };

                self.getNewModel = function () {
                    return {
                        IndividualId: self.getIndividualId(),
                        ProviderId: self.getProviderId(),
                        ApplicationId: self.getApplicationId(),
                        MasterTransactionId: 1,
                        PageModuleId: self.pageModuleId,
                        PageModuleTabSubModuleId: self.pageModuleTabSubModuleId,
                        PageTabSectionId: self.pageTabSectionId,
                        Type: self.logType,
                        CommentLogSource: '',
                        CommentLogText: '',
                        IsInternalOnly: true,
                        IsForInvestigationOnly: true,
                        IsForPublic: false,
                        NotesTypeId: 1,
                        DoNotProcessNotesLog: true,

                        IsActive: true,
                        IsDeleted: false
                    };
                };

                self.logActivity = function (commentLogText, individualId, providerId, applicationId) {
                    var model = self.getNewModel();

                    if (!!individualId) {
                        model.IndividualId = individualId;
                    }
                    if (!!providerId) {
                        model.ProviderId = providerId;
                    }
                    if (!!applicationId) {
                        model.ApplicationId = applicationId;
                    }

                    if (!!commentLogText) {
                        model.CommentLogSource = $state.current.name.slice(0, 25);
                        model.CommentLogText = commentLogText;

                        //Replace known variables with global information
                        if (!!$state.current) {
                            if (!!$state.current.routeData && !!$state.current.routeData.applicationArea) {
                                model.CommentLogText = model.CommentLogText.replace('##from##', $state.current.routeData.applicationArea);
                                model.CommentLogText = model.CommentLogText.replace('##appArea##', $state.current.routeData.applicationArea);
                            }
                            if (!!$state.current.routeData && !!$state.current.routeData.queueType) {
                                model.CommentLogText = model.CommentLogText.replace('##queue##', $state.current.routeData.queueType);
                            }
                            if (!!$state.current && !!$state.current.url) {
                                model.CommentLogText = model.CommentLogText.replace('##url##', $state.current.url);
                            }
                        }

                        if (!!sessionStorage.menuName) {
                            model.CommentLogText = model.CommentLogText.replace('##menuName##', sessionStorage.menuName);
                        }

                        activityLoggingService.logActivity(sessionStorage.Key, model);
                    }
                };

                self.getNewAuditModel = function () {
                    return {
                        IndividualId: self.getIndividualId(),
                        EntityId: self.getProviderId()
                    };
                };

                self.logAuditInfo = function (commentLogText, individualId, providerId) {
                    var model = self.getNewAuditModel();

                    if (!!individualId) {
                        model.IndividualId = individualId;
                    }
                    if (!!providerId) {
                        model.EntityId = providerId;
                    }
                    model.PageName = $state.current.url + ' - ' + commentLogText;

                    activityLoggingService.logAuditInfo(sessionStorage.Key, model);
                };

                self.getTemplateIndex = function (useEventIndex) {
                    var templateIndex = useEventIndex;

                    //Continue to transform it until it is a number
                    //This may be necessary as it is uncertain what a function might return
                    for (var i = 0; i < 3; i++) {
                        switch (typeof (templateIndex)) {
                            case 'boolean':
                                templateIndex = (templateIndex) ? 1 : 0;
                                break;
                            case 'function':
                                templateIndex = templateIndex();
                                break;
                            case 'string':
                                templateIndex = parseInt(templateIndex);
                                break;
                            default:
                                templateIndex = 0;
                                break;
                        }
                        if (typeof (templateIndex) == 'number') {
                            break;
                        }
                    }

                    return templateIndex;
                };

                self.getCommentLogText = function (messageTemplates, templateIndex) {
                    var config = self.getConfigData();
                    var commentLogText = '';

                    if (templateIndex >= messageTemplates.length) {
                        console.log('Log Event Directive - templateIndex is out of bounds, defaulting to 0.');
                        templateIndex = 0;

                        if (messageTemplates.length == 0) {
                            return 'Unspecified Event';
                        }
                    }

                    try {
                        commentLogText = eval('config.' + messageTemplates[templateIndex]);
                        if (!commentLogText) {
                            commentLogText = messageTemplates[templateIndex];
                        }
                    } catch (ex) {
                        commentLogText = messageTemplates[templateIndex];
                    }

                    return commentLogText;
                };

                self.getMessageTemplates = function (attr) {
                    var messageTemplates = attr.split(' ');
                    if (messageTemplates.length == 0) {
                        console.log('Log Event Directive - Setup invalid - must have at least 1 event that corresponds to config file.');
                    }
                    return messageTemplates;
                };
            }]
        };
    }

    angular.module('natApp').directive('logClick', [clickLoggerDirective]);
    function clickLoggerDirective() {
        return {
            restrict: 'A',
            require: '^^logActivity',   // Must search parents, cannot include self, because two directives cannot have isolated scope on the same element
            scope: {
                individualId: '=?logIndividual',
                providerId: '=?logProvider',
                applicationId: '=?logApplication',
                useEventIndex: '=?logClickUse',
                replaceTags: "=?logReplaceTags"
            },
            link: function (scope, element, attrs, logActivityCtrl) {
                var messageTemplates = logActivityCtrl.getMessageTemplates(attrs.logClick);

                var eventHandler = function () {
                    var templateIndex = logActivityCtrl.getTemplateIndex(scope.useEventIndex);

                    var commentLogText = logActivityCtrl.getCommentLogText(messageTemplates, templateIndex);

                    if (!!scope.replaceTags && !!commentLogText) {
                        if (typeof (scope.replaceTags) !== 'object') {
                            throw 'Invalid type: provide proper object.';
                        }
                        for (var prop in scope.replaceTags) {
                            var replaceRegExp = new RegExp(prop, 'gi');
                            commentLogText = commentLogText.replace(replaceRegExp, scope.replaceTags[prop]);
                        }
                    }

                    logActivityCtrl.logActivity(commentLogText, scope.individualId, scope.providerId, scope.applicationId);
                    logActivityCtrl.logAuditInfo(commentLogText, scope.individualId, scope.providerId);
                };

                element.on('click', eventHandler);

                scope.$on('$destroy', function () {
                    element.off('click', eventHandler);
                });
            }
        };
    }

    angular.module('natApp').directive('logLoad', [loadLoggerDirective]);
    function loadLoggerDirective() {
        return {
            restrict: 'A',
            require: '^logActivity', // Search self and parent elements for logActivity directive controller
            // scope: {},   Cannot have isolated scope - two directives on the same element cannot have isolated scopes, and since logActivity has it, this one cannot
            link: function (scope, element, attrs, logActivityCtrl) {
                var messageTemplates = logActivityCtrl.getMessageTemplates(attrs.logLoad);

                var commentLogText = logActivityCtrl.getCommentLogText(messageTemplates, 0);

                console.log(commentLogText);
                logActivityCtrl.logActivity(commentLogText);
                logActivityCtrl.logAuditInfo(commentLogText);
            }
        };
    }
})();