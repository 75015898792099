(function() {
    'use strict'

    angular.module('task.management')
    .factory('TaskManagementService', ['$http', '$q', 'WebApiUrl', TaskManagementService]);

    function TaskManagementService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(!!success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(!!fail) {
                    fail(error);
                }
                console.log("Request failed with status: " + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getTaskList = function (key) {
            return responseHandler($http.get(WebApiUrl + 'TypeValues/RefTablesGetAll/' + key + '/', { params: { tableKey: 'reftasktype' } }));
        };

        var _getScheduledTaskList = function (key, ids, searchCriteria) {
            return responseHandler($http.post(WebApiUrl + 'TaskManager/GetAutomatedManualTasksList/' + key, JSON.stringify(searchCriteria), { params: ids }));
        };

        var _deleteTaskById = function (key, id) {
            return responseHandler($http.post(WebApiUrl + 'TaskManager/DeleteTaskById/' + key, null, { params: { TaskScheduleAutoId: id } }));
        };

        var _createNewTask = function (key, taskDetails) {
            return responseHandler($http.post(WebApiUrl + 'TaskManager/CreateNewTask/' + key, JSON.stringify(taskDetails)));
        };

        var _getTaskDetailsById = function (key, taskScheduleAutoId) {
            return responseHandler($http.get(WebApiUrl + 'TaskManager/GetTaskDetailsById/' + key, { params: { TaskScheduleAutoId: taskScheduleAutoId } }));
        };

        var _getAllTemplates = function (key) {
            return responseHandler($http.get(WebApiUrl + 'Template/GetAllTemplates/' + key));
        };

        return {
            getTaskList: _getTaskList,
            getScheduledTaskList: _getScheduledTaskList,
            deleteTaskById: _deleteTaskById,
            createNewTask: _createNewTask,
            getTaskDetailsById: _getTaskDetailsById,
            getAllTemplates: _getAllTemplates
        };
    }
})();