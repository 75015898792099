(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeIndividualCbcService', backofficeIndividualCbcService)

    function backofficeIndividualCbcService($http, $q, WebApiUrl) {
        var _individualBackgroundcheckGet = function (Key, IndividualId, ApplicationId, ApplicationTypeId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualBackgroundcheckGet/' + Key + '?IndividualId=' + IndividualId + '&ApplicationId=' + ApplicationId + '&ApplicationTypeId=' + ApplicationTypeId));
        };


        var _individualBackgroundcheckSave = function (key, data) {
            
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualBackgroundcheckSave/' + key + '/', data));
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        return {
            individualBackgroundcheckGet: _individualBackgroundcheckGet,
            individualBackgroundcheckSave: _individualBackgroundcheckSave
        };
    }
}());