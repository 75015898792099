﻿(function () {
    'use strict';

    angular
        .module('app.lga')
        .controller("LGARequestAccessController", ['$scope', '$q', 'LGARequestAccessFactory', function ($scope, $q, LGARequestAccessFactory) {

            $scope.init = function () {
                $scope.ShowSearch = true;
                $scope.currentPage = 1;
                $scope.totalItems = 0;
                $scope.itemsPerPage = 10;
                $scope.maxSize = 6;
                $scope.fromRow = 0;
                $scope.SearchParm = {};
                GovernmentAgencyUserGetAll();
            };
            //need to remove
            $scope.ddlStatus = [{ Id: "4", Name: "Verify" }, { Id: "6", Name: "Approved" }, { Id: "5", Name: "Denied" }];

            var GovernmentAgencyUserGetAll = function () {
                $scope.lstGovernmentAgencyUserGet = [];
                ShowLoader();
                var reqdata = $scope.SearchParm;
                reqdata.Key = sessionStorage.Key;
                reqdata.FromRow = $scope.fromRow;
                reqdata.ItemsPerPage = $scope.itemsPerPage;
                LGARequestAccessFactory.GovernmentAgencyUserGetAll(reqdata).then(function (res) {
                    HideLoader();
                    $scope.lstGovernmentAgencyUserGet = res.data.lstGovernmentAgencyUserGet;
                    if (!res.data.Status)
                        $scope.ResponceMessage = res.data.Message;
                    if (res.data.lstGovernmentAgencyUserGet != null) {
                        $scope.totalItems = res.data.TotalRecords;
                        console.log('total ite,', $scope.totalItems);
                    } else {
                        $scope.totalItems = 0;
                    }

                }, function (res) {
                    HideLoader();
                });
            };
            //call api to get recertification list using filter
            $scope.btnSearchClisked = function () {
                //make pageIndex 0
                $scope.currentPage = 1;
                $scope.fromRow = 0;
                GovernmentAgencyUserGetAll();
            };

            $scope.pageChanged = function () {
                $scope.fromRow = ($scope.currentPage * $scope.itemsPerPage) - $scope.itemsPerPage;
                GovernmentAgencyUserGetAll();
            };
            
            $scope.Addbtn = function () {
                $scope.AddnewAgencyRequest = true;
            };
            $scope.CloseregistrationForm = function () {
                $scope.AddnewAgencyRequest = false;
            };
            $scope.OnNewRegistrationSave = function () {
                $scope.CloseregistrationForm();
                GovernmentAgencyUserGetAll();
            };
            $scope.CloseListregistrationForm = function (item) {
                item.displayForm = false;
            };
            $scope.ApproveButtonClick = function (item) {
                //make all hide
                if ($scope.lstGovernmentAgencyUserGet) {
                    $scope.lstGovernmentAgencyUserGet.map(function (i) {
                        i.displayForm = false;
                        i.ShowEmailForm = false;
                        return i;
                    });
                }
                item.displayForm = true;
            };
            $scope.EmailIconClicked = function (item) {
                //make all hide
                if ($scope.lstGovernmentAgencyUserGet) {
                    $scope.lstGovernmentAgencyUserGet.map(function (i) {
                        i.displayForm = false;
                        i.ShowEmailForm = false;
                        return i;
                    });
                }
                item.ShowEmailForm = true;
                $scope.SendEmailConfig = {
                    ToEmail: item.EmailAddress
                };
            };
            $scope.CloseEmailSection = function (item) {
                console.log('close item');
                item.ShowEmailForm = false;
            };
        }]);
})();