(function () {
    'use strict';
    angular.module('app.core')
        .controller('HeaderLicenseeController', HeaderLicenseeController)
    HeaderLicenseeController.$inject = ['$scope', '$controller'];

    function HeaderLicenseeController($scope, $controller) {
        $controller('GlobalController', { $scope: $scope });
    }

})();