(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('initialWrittenExamService', initialWrittenExamService)

    initialWrittenExamService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function initialWrittenExamService($http, $q, WebApiUrl, AppConfig) {
        var _saveIndividualExam = function (data, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualExamSave/' + key + '?Source=' + AppConfig.Source, data)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getIndividualExamBYIndividualId = function (key, IndividualId, ApplicationId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualExamBYIndividualId/' + key + "?IndividualId=" + IndividualId + "&ApplicationId=" + ApplicationId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            saveIndividualExam: _saveIndividualExam,
            getIndividualExamBYIndividualId: _getIndividualExamBYIndividualId
        };
    }
})();