﻿(function () {
    'use strict'
    angular.module('app.core').directive('addressDirective', function () {
        return {
            restrict: 'EA',
            scope: {
                individualid: '=individualid',
                iseditable: '=iseditable'
            },
            controller: "addressDirectiveController",
            
            templateUrl: "app/core/directive/AddressDirective/address-directive-template.html",
            link: function ($scope, element, attrs, ngCtrl) {

            }
        };
    });
})();