angular.module('natApp').factory('ProcessPaymentService', ['$http', 'WebApiUrl', function ($http, WebApiUrl) {
    return {
        ProcessPayment: function (key, AffirmativeAction, IsBackofficePayment, ApplicationNumber, ApplicationId, IndividualId, CardNumber, CVV, ExpirationMonths, ExpirationYears, Amount, Description, InvoiceNumber, FirstName, LastName, Address, City, StateCode, Zip, Country, EmailAddress, TransactionObject, RequestedLicenseStatusTypeId) {

            return $http.post(WebApiUrl + 'Payment/ProcessPayment/' + key + '?AffirmativeAction=' + AffirmativeAction + '&IsBackofficePayment=' + IsBackofficePayment,
                {
                    ApplicationNumber: ApplicationNumber,
                    ApplicationId: ApplicationId,
                    IndividualId: IndividualId,
                    CardNumber: CardNumber,
                    CVV: CVV,
                    ExpirationMonths: ExpirationMonths,
                    ExpirationYears: ExpirationYears,
                    Amount: Amount,
                    Description: Description,
                    InvoiceNumber: InvoiceNumber,
                    FirstName: FirstName,
                    LastName: LastName,
                    Address: Address,
                    City: City,
                    StateCode: StateCode,
                    Zip: Zip,
                    Country: Country,
                    EmailAddress: EmailAddress,
                    TransactionObject: TransactionObject,
                    RequestedLicenseStatusTypeId: RequestedLicenseStatusTypeId
                });

        },
        MonthListGet: function (key) {
            return $http.get(WebApiUrl + 'Payment/MonthListGet/' + key);
        },
        YearListGet: function (key) {

            return $http.get(WebApiUrl + 'Payment/YearListGet/' + key);
        },
        GetStateByCountryID: function (Key, ID) {

            return $http.get(WebApiUrl + 'State/StateGetByCountryID/' + Key + '?CountryID=' + ID);
        },
        CountryGetAll: function (key) {

            return $http.get(WebApiUrl + 'Country/CountryGetAll?Key=' + (!!key ? key : ''));
        },
        InitiatePayment: function (key, ApplicationId, IndividualId, IndividualLicenseId, LicenseTypeId, LicenseNumber, TransactionDeviceTy, FeeDetailsList, TransactionObject) {

            return $http.post(WebApiUrl + 'Payment/InitiatePayment/' + key, { ApplicationId: ApplicationId, IndividualId: IndividualId, IndividualLicenseId: IndividualLicenseId, LicenseTypeId: LicenseTypeId, LicenseNumber: LicenseNumber, TransactionDeviceTy: TransactionDeviceTy, FeeDetailsList: FeeDetailsList, TransactionObject: TransactionObject });
        },
        ProcessManualPayment: function (key, AffirmativeAction, IsBackofficePayment, ApplicationNumber, ApplicationId, IndividualId, PaymentMode, PaymentModeNumber, PaymentBankName, Amount, Description, InvoiceNumber, TransactionObject, RequestedLicenseStatusTypeId) {
            return $http.post(WebApiUrl + 'Payment/ProcessManualPayment/' + key + '?AffirmativeAction=' + AffirmativeAction + '&IsBackofficePayment=' + IsBackofficePayment,
                {
                    ApplicationNumber: ApplicationNumber,
                    ApplicationId: ApplicationId,
                    IndividualId: IndividualId,
                    PaymentMode: PaymentMode,
                    PaymentModeNumber: PaymentModeNumber,
                    PaymentBankName: PaymentBankName,
                    Amount: Amount,
                    Description: Description,
                    InvoiceNumber: InvoiceNumber,
                    TransactionObject: TransactionObject,
                    RequestedLicenseStatusTypeId: RequestedLicenseStatusTypeId
                });

        },
    };
}]);