(function () {
    'use strict'

    angular.module('app.enforcement')
    .factory('ComplaintCasesService', ['$http', '$q', 'WebApiUrl', ComplaintCasesService]);

    function ComplaintCasesService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getInvolvedParties = function (key, request) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/GetComplaintInvolvedParty/' + key + '/', request));
        };

        var _getComplaintCases = function (key, search) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/GetComplaintCases/' + key + '/', search));
        };

        var _saveComplaintCases = function (key, complaintCases) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/SaveComplaintCases/' + key + '/', complaintCases));
        };

        return {
            getInvolvedParties: _getInvolvedParties,
            getComplaintCases: _getComplaintCases,
            saveComplaintCases: _saveComplaintCases
        };
    }
})();