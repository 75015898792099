(function () {
  'use strict';

  angular.module('app.core')
    .directive('onFinishRender', onFinishRenderDirective);

  onFinishRenderDirective.$inject = ['$timeout'];

  function onFinishRenderDirective($timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attr) {
        if (scope.$last === true) {
          $timeout(function () {
            scope.$emit('ngRepeatFinished');
          });
        }
      }
    };
  }
})();

