(function () {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("providerJursidictionsController", providerJursidictionsController);

    providerJursidictionsController.$inject = ["$rootScope", "$scope", "$state", "providerJursidictionsService", "utilityService", "$exceptionHandler", "WebApiUrl", "countryStateService", "typeValuesService", "TypeValue", 'Messages'];

    function providerJursidictionsController($rootScope, $scope, $state, providerJursidictionsService, utilityService, $exceptionHandler, WebApiUrl, countryStateService, typeValuesService, TypeValue, Messages) {


        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, ProviderId) {
            if (ProviderId && $rootScope.individualFirmCurrentTab.name == "license") {
                $scope.ProviderId = ProviderId;
                $scope.jursidiction = {};
                getProviderPreviousLicense();
                getStateByCountryID($rootScope.DefaultCountryId);
                getRefproviderlicensestatus();
            }
        });

        $scope.netValidator = {};
        $scope.saveProviderPreviousLicense = function (form) {
            if (validateForm(form) && $scope.jursidiction) {
                ShowLoader();
                if (!$scope.jursidiction.ProviderPreviousLicenseId) {
                    $scope.jursidiction.ProviderId = $scope.ProviderId;
                    $scope.jursidiction.IsActive = true;
                }
                // $scope.jursidiction.ProviderLicenseStatus = getStatusNameById($scope.jursidiction.ProviderLicenseStatusTypeId);

                providerJursidictionsService.saveProviderPreviousLicense(sessionStorage.Key, [$scope.jursidiction]).then(function (res) {
                    if (res.Status) {
                        getProviderPreviousLicense();
                        $scope.cancelBtnClick();
                    } else {
                        HideLoader();
                    }
                }, function (err) {
                    console.log(err);
                    HideLoader();
                });
            }
        };

        var getStatusNameById = function (id) {
            try {
                if ($scope.refProviderLicenseStatusList) {
                    var item = $.grep($scope.refProviderLicenseStatusList, function (i) {
                        return id == i.ProviderLicenseStatusId;
                    })
                    if (item) {
                        return item[0].ProviderLicenseStatusName;
                    }
                }
            } catch (ee) {
                console.log('exc', ee.toString());
            }
            return '';
        }

        var getProviderPreviousLicense = function () {
            try {
                ShowLoader();
                providerJursidictionsService.getProviderPreviousLicense(sessionStorage.Key, $scope.ProviderId, null).then(function (res) {
                    if (res.Status) {
                        $scope.providerPreviousLicenseList = res.ProviderPreviousLicenseList;
                    }
                    HideLoader();

                }, function (err) {
                    console.log(err);
                    HideLoader();
                });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        var getStateByCountryID = function (countryId) {
            $scope.statesData = [];
            try {
                countryStateService.getStateByCountryID(sessionStorage.Key, countryId)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.statesData = response.State;
                        }
                    }, function (data) {
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        var getRefproviderlicensestatus = function () {
            try {
                typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=refproviderlicensestatus"))
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.refProviderLicenseStatusList = response.ReferenceEntities;
                        }
                    }, function (data) {
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        $scope.cancelBtnClick = function () {
            $scope.jursidiction = {};
            $scope.showJursidiction = false;
        };

        $scope.editButtonClick = function (i) {
            $scope.showJursidiction = true;
            $scope.jursidiction = angular.copy(i);
        };

        $scope.deleteButtonClick = function (i) {
            try {
                if (i && confirm(Messages.bbabace)) {
                    ShowLoader();
                    i.IsActive = false;
                    i.IsDeleted = true;
                    providerJursidictionsService.saveProviderPreviousLicense(sessionStorage.Key, [i]).then(function (res) {
                        if (res.Status) {
                            getProviderPreviousLicense();
                        } else {
                            HideLoader();
                        }
                    }, function (err) {
                        console.log(err);
                        HideLoader();
                    });
                }
            } catch (ex) { }
        };


        //This function validates the for on click of submit button
        var validateForm = function (form, error) {
            $scope.netValidator.errorMessages = [];
            if (!!form) {
                form.$submitted = true;
                var errorMessages = angular.copy(form.errorMessages);
                angular.forEach(errorMessages, function (errorMessage, index) {
                    $scope.netValidator.errorMessages.push(errorMessage);
                });
            }
            if (!!error) {
                $scope.netValidator.errorMessages.push(error);
            }
            if (!!$scope.netValidator.errorMessages && $scope.netValidator.errorMessages.length > 0) {
                $scope.netValidator.showError = true;
                $scope.netValidator.isValid = false;
                return false;
            } else {
                $scope.netValidator.showError = false;
                $scope.netValidator.isValid = true;
                return true;
            }
        };



    }
})();