(function () {
    'use strict'

    angular.module('app.enforcement')
    .factory('ComplaintDocumentsService', ['$http', '$q', 'WebApiUrl', 'utilityService', ComplaintDocumentsService]);

    function ComplaintDocumentsService($http, $q, WebApiUrl, utilityService) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                var headers = res.headers();
                if (!!headers['generatedresponse'] && headers['apistatus']=='false') {
                    res.data = {
                        Status: false,
                        StatusCode: headers.statuscode,
                        Message: headers.message
                    };
                }
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getDocuments = function (key, request) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/GetComplaintDocuments/' + key + '/', request));
        };

        var _getDocumentsFromPublic = function (key, request) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/GetComplaintDocumentsFromPublic/public/', request));
        };

        var _uploadDocuments = function (key, documents) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/UploadComplaintDocuments/' + key + '/', documents));
        };

        var _downloadDocument = function (key, complaintDocumentId) {
            var urlParams = {
                ComplaintDocumentId: complaintDocumentId
            };

            var httpPromise = $http.get(WebApiUrl + 'Complaint/DownloadComplaintDocument/' + key + '/', { params: urlParams, responseType: 'arraybuffer' });
            return responseHandler(httpPromise, function(res) {
                var headers = res.headers();
                if (res.status == 200) {
                    utilityService.downloadExcelFile(res.data, headers);
                }
            }, function (err) {
                
            });
        };

        var _downloadDocumentFromPublic = function (key, complaintDocumentId) {
            var urlParams = {
                ComplaintDocumentId: complaintDocumentId
            };

            var httpPromise = $http.get(WebApiUrl + 'Complaint/DownloadComplaintDocumentFromPublic/public/', { params: urlParams, responseType: 'arraybuffer' });
            return responseHandler(httpPromise, function(res) {
                var headers = res.headers();
                if (res.status == 200) {
                    utilityService.downloadExcelFile(res.data, headers);
                }
            }, function (err) {
                
            });
        };

        var _deleteDocument = function (key, complaintDocumentId) {
            var urlParams = {
                ComplaintDocumentId: complaintDocumentId
            };
            return responseHandler($http.get(WebApiUrl + 'Complaint/DeleteComplaintDocument/' + key + '/', { params: urlParams }));
        };

        // var _getDocumentTypes = function (key, docId, docCode) {
        //     if (!!docId) {
        //         var urlParams = {
        //             DocId: docId,
        //             DocCode: docCode
        //         };
        //         return responseHandler($http.get(WebApiUrl + 'Document/DocumentGetDocumentTypeName/' + key + '/', { params: urlParams }));
        //     } else {
        //         return responseHandler($http.get(WebApiUrl + 'Document/DocumentMasterGetALL/' + key + '/'));
        //     }
        // };

        var _getDocumentTypes = function (key, docId, docCode) {
            var urlParams = {
                DocId: docId,
                DocCode: docCode
            };
            return responseHandler($http.get(WebApiUrl + 'Complaint/GetDocumentTypesByIdAndCode/', {params: urlParams}));
        };

        return {
            getDocuments: _getDocuments,
            getDocumentsFromPublic: _getDocumentsFromPublic,
            uploadDocuments: _uploadDocuments,
            downloadDocument: _downloadDocument,
            downloadDocumentFromPublic: _downloadDocumentFromPublic,
            deleteDocument: _deleteDocument,

            getDocumentTypes: _getDocumentTypes
        };
    }
})();