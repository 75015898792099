(function () {
    'use strict'

    angular.module('app.pciCompliantPayment')
        .controller('PCICompliantPaymentResultController', ['$scope', '$rootScope', '$q', '$timeout', '$window', '$location', '$state', 'AppConfig', 'PCICompliantPaymentService', 'utilityService', PCICompliantPaymentResultController]);

    function PCICompliantPaymentResultController($scope, $rootScope, $q, $timeout, $window, $location, $state, AppConfig, PCICompliantPaymentService, utilityService) {
        var _key = sessionStorage.Key;
        var _childName = '';

        var getUIConfig = function () {
            var config = {};

            for (var prop in $scope.config.UIConfigs['Default']) {
                if (!$scope.config.UIConfigs['Default'].hasOwnProperty(prop)) { continue; }

                config[prop] = $scope.config.UIConfigs['Default'][prop];
            }

            if (!!$scope.config.UIConfigs[$scope.configName]) {
                for (var prop in $scope.config.UIConfigs[$scope.configName]) {
                    if (!$scope.config.UIConfigs[$scope.configName].hasOwnProperty(prop)) { continue; }

                    config[prop] = $scope.config.UIConfigs[$scope.configName][prop];
                }
            }

            return config;
        };

        var getConfig = function () {
            var deferred = $q.defer();

            try {
                utilityService.getConfigData('/core/directive/pci-compliant-payment/pages/pci-compliant-payment-result.server-config.json')
                    .then(function (data) {
                        if (!!data) {
                            $scope.config = data;

                            $scope.uiConfig = getUIConfig();

                            $scope.isConfigLoaded = true;
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.clearSessionState = function () {
            delete sessionStorage.paymentRequest;
            delete sessionStorage.ApplicationId;
            delete sessionStorage.paymentSourcePage;
        };

        $scope.mapQueryStringPayment = function (payment) {
            for (var to in $scope.uiConfig.QueryStringToPaymentMapping) {
                var from = $scope.uiConfig.QueryStringToPaymentMapping[to];

                payment[to] = $scope.queryString[from];
            }
            // payment.PaymentDate = $scope.queryString.txn_time;
            // payment.PaymentModeNumber = $scope.queryString.txn_id;
            // payment.TransactionDate = $scope.queryString.txn_time;
        };

        $scope.mapQueryStringStatus = function () {
            for (var to in $scope.uiConfig.QueryStringToStatusMapping) {
                var from = $scope.uiConfig.QueryStringToStatusMapping[to];

                $scope.paymentStatus[to] = $scope.$eval('queryString.' + from);
            }

            //Convert the expression to a boolean
            //$scope.paymentStatus.IsPaymentSuccessful = $scope.$eval($scope.uiConfig.QueryStringToStatusMapping.IsPaymentSuccessful);
        };

        $scope.finishProcessingPayment = function () {
            $scope.validator = { survey: {} };
            var paymentResult = $state.href('app.pciCompliantPaymentResult', {}, { absolute: true });

            //Check if the URL is somehow malformed
            if (!$location.$$absUrl.includes(paymentResult)) {
                //The likeliest explanation is that the query string from the payment portal has been appended to the hostname, not the url.
                //Strip out the query string and append it to the url.
                try {
                    var regEx = /(?:(\?.*?)#)/gi;
                    var queryString = regEx.exec($location.$$absUrl)[1];
                    var newUrl = $location.$$absUrl.replace(queryString, '') + queryString;

                    //Redirect to the new url
                    $window.location.href = newUrl;
                    return;
                } catch (ex) {
                    //Maybe that wasn't the issue after all.
                    console.log(ex);
                }
            }

            //Get the query string from the URL
            $scope.queryString = $location.search();
            //$scope.queryString now contains all of the transaction metadata sent back

            $scope.confirmationPage = false;
            if (sessionStorage.isBackOfficeUser == 'false') {
                $scope.dashboardState = 'app.Dashboard';
            } else {
                $scope.dashboardState = sessionStorage.paymentSourcePage;
            }

            $scope.mapQueryStringStatus();

            if (!!$scope.paymentStatus && !!sessionStorage.paymentRequest) {
                $scope.confirmationPage = true;
                if (!!$scope.paymentStatus.IsPaymentSuccessful) {
                    //Serialized from the fee-payment.controller.js file prior to going to the external payment page
                    $scope.paymentRequest = JSON.parse(sessionStorage.paymentRequest);
                    var applicationId = sessionStorage.ApplicationId;
                    var applicationTypeId = sessionStorage.ApplicationTypeId;
                    $scope.applicationId = Number(sessionStorage.ApplicationId);
                    $scope.applicationTypeId = Number(sessionStorage.ApplicationTypeId);


                    //Check if it was an application submission
                    if (!$scope.paymentRequest.PaymentAndSubmissionEmailDetail.IsMiscPayment && !!applicationId) {
                        $rootScope.displayMainLoader(true, 'Submitting Application...');
                    } else {
                        $rootScope.displayMainLoader(true, 'Completing Transaction...');
                    }

                    //Copy the transaction data into the payment object
                    $scope.paymentRequest.PaymentRequests.forEach(function (payment) {
                        $scope.mapQueryStringPayment(payment);
                    });

                    //$rootScope.displayMainLoader(false);

                    //Complete processing the payment
                    var sourceFlag = AppConfig.Source;
                    $scope.processingExternalElectronicPayment = true;
                    PCICompliantPaymentService.processExternalElectronicPayment(sessionStorage.Key, $scope.paymentRequest, sourceFlag)
                        .then(function (objEmailAndLogCommunication) {
                            $scope.processingExternalElectronicPayment = false;
                            $rootScope.displayMainLoader(false);
                            if (objEmailAndLogCommunication.StatusCode != "00") {
                                $scope.showStatusMsg('-', objEmailAndLogCommunication.Message);
                            } else {
                                //Update the application status to submitted
                                if (!!applicationId) {
                                    var applicationStatusId = $scope.uiConfig.AppTypeSubmissionStatuses
                                        .whereEquals(applicationTypeId, "ApplicationTypeId")
                                        .select('ApplicationStatusId')
                                        .firstOrDefault();

                                    saveApplicationStatus(applicationId, applicationStatusId);
                                    saveApplicationStatusLk(applicationId, applicationStatusId);
                                    saveApplicationStatusChangeLog(applicationId, applicationStatusId);
                                }

                                //$scope.clearSessionState();
                            }
                        }, function (response) {
                            $rootScope.displayMainLoader(false);
                            $scope.showStatusMsg('-', response);
                        });

                    $scope.showSuccessMessage = true;
                } else {
                    if (!!$state.current.routeData.cancelled) {
                        $rootScope.displayMainLoader(false);
                        $scope.showErrorMessage = true;
                        $scope.errorMessage = (!!$scope.paymentStatus.ResultMessage) ? $scope.paymentStatus.ResultMessage : 'Your payment has been cancelled.';
                    } else {
                        $rootScope.displayMainLoader(false);
                        $scope.showErrorMessage = true;
                        $scope.errorMessage = (!!$scope.paymentStatus.ResultMessage) ? $scope.paymentStatus.ResultMessage : 'There was an error processing your payment.';
                    }
                }
            } else {
                $scope.showErrorMessage = true;
                $scope.errorMessage = (!!$scope.paymentStatus.ResultMessage) ? $scope.paymentStatus.ResultMessage : 'There was an error processing your payment.';
                // HideLoader();
                // if ($scope.isSessionActive() && $stateParams.paymentMethod != undefined) {
                //     $scope.paymentMethod = $stateParams.paymentMethod;
                //     $scope.applicationType = $stateParams.applicationType;
                //     $scope.isReSubmit = (($stateParams.isReSubmit == 'true' || $stateParams.isReSubmit == true) ? true : false);
                // } else {
                //     if (!!sessionStorage.UserID) {
                //         $scope.clearSessionState();
                //         $state.go($scope.dashboardState);
                //     } else {
                //         $scope.clearSessionState();
                //         $state.go('app.Loginwemail');
                //     }
                // }
            }
        };

        $scope.init = function () {
            $scope.current = {};
            $scope.uiLogic = {};
            $scope.pager = {};
            $scope.content = {};
            $scope.refTables = {};
            $scope.paymentStatus = {};

            getConfig()
                .then(function () {
                    $scope.finishProcessingPayment();
                    $scope.operationPending(false);
                }, function (err) {
                    $scope.showStatusMsg('-', err);
                    $scope.operationPending(false);
                });
        };

        var saveApplicationStatus = function (applicationId, applicationStatus, hideLoader) {
            PCICompliantPaymentService.saveApplicationStatus(applicationId, sessionStorage.Key, applicationStatus)
                .then(function (response) {
                }, function (data) {
                    $scope.showStatusMsg('-', data);
                });
        };

        var saveApplicationStatusLk = function (applicationId, applicationStatus) {
            var reqData = {
                ApplicationId: applicationId,
                ApplicationTypeId: 2,
                ApplicationStatusId: applicationStatus,
                ApplicationStatusReasonId: 0,
                IsActive: true,
                IsDeleted: false,
                IsArchive: false,
                CreatedBy: sessionStorage.UserID,
                ApplicationInternalStatusReasonText: '',
                ApplicationInternalStatusId: 0,
                HighPriority: false
            };
            PCICompliantPaymentService.saveApplicationStatusLk(sessionStorage.Key, sessionStorage.UserID, reqData)
                .then(function (response) {

                }, function (data) {
                    $scope.showStatusMsg('-', data);
                });
        };

        var saveApplicationStatusChangeLog = function (applicationId, applicationStatus) {
            var reqData = {
                ApplicationId: applicationId,
                ApplicationStatusReasonId: 0,
                ApplicationNumber: '',
                ApplicationTypeId: 2,
                ApplicationStatusId: applicationStatus,
                ApplicationSubmitMode: 'O',
                ApplicationStatusChangedBy: sessionStorage.UserID,
                ReferenceNumber: '',
                LicenseTypeId: 0,
                HighPriority: false,
                ApplicationInternalStatusId: 0,
                ApplicationInternalStatusReasonText: ''
            };
            PCICompliantPaymentService.saveApplicationStatusChangeLog(sessionStorage.Key, sessionStorage.UserID, reqData)
                .then(function (response) {

                }, function (data) {
                    $scope.showStatusMsg('-', data);
                });
        };
    }
})();