(function () {
    'use strict'

    angular.module('app.core')
        .factory('paymentProviderConfirmationService', paymentProviderConfirmationService)

        paymentProviderConfirmationService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function paymentProviderConfirmationService($http, $q, WebApiUrl, AppConfig) {

        return {
            providerByProviderId: _providerByProviderId,
            getApplicationInfoByApplicationId: _getApplicationInfoByApplicationId,
            getProviderLicense: _getProviderLicense
        };

        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function _getApplicationInfoByApplicationId(applicationId, userId, key) {
            return returnDefferedResult($http.get(WebApiUrl + 'Common/ApplicationInfoByApplicationId/' + key + '?ApplicationId=' + applicationId + '&UserId=' + userId));
        };

        function _providerByProviderId(Key, providerId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Provider/GetProviderById/' + Key + '?providerid=' + providerId));
        };

        function _getProviderLicense(key, providerId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Provider/GetProviderLicense/' + key + '?ProviderId=' + providerId));
        };
    }
})();