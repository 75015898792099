(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
    .directive('addressContacts', [AddressContactsDirective]);

    function AddressContactsDirective() {
        return {
            restrict: 'E',
            require: '^addressContactsCommon',
            transclude: {
                'before': '?acBefore',
                'after': '?acAfter',
                'addl': '?acAddl'
            },
            scope: {
                individualId: '=',
                addressConfig: '@',
                applicationId: '=?',
                linkContacts: '=?',
                additionalFields: '=?',
                addressOptions: '=?',
                isReadOnly: '=?',
                isDisabled: '=?',
                canDelete: '=?',
                showPublicMailing: '=?',
                alwaysCreateNew: '=?',
                isRequired: '=?',
                mailingChanged: '&?',
                publicChanged: '&?',
                showTable: '=?',
                tableHeaderConfig: '=?',
                showPreviousDefault: '=?'
            },
            link: function ($scope, element, attrs, addrContComm) {
                addrContComm.registerAddressContact($scope);
            },
            templateUrl: 'app/core/directive/address-contacts/address-contacts.html',
            controller: 'AddressContactsController'
        };
    }
})();