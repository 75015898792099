(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationExperienceController", InitialApplicationExperienceController);

    InitialApplicationExperienceController.$inject = ["$rootScope", "$scope", "$state", "$uibModal", "initialApplicationExperiencService", "utilityService", "contentService", "countryStateService",
        "$q", "EntityConfirmationService", "typeValuesService", "ConfigurationService", "externalFormService", "htmlToPdfGenerationService", "WebApiUrl", "Messages"
    ];

    function InitialApplicationExperienceController($rootScope, $scope, $state, $uibModal, initialApplicationExperiencService, utilityService, contentService, countryStateService,
        $q, EntityConfirmationService, typeValuesService, ConfigurationService, externalFormService, htmlToPdfGenerationService, WebApiUrl, Messages) {

        $rootScope.routeData = $state.current.routeData;

        $scope.datePicker = {
            dateOpened: false,
            dateTopened: false,
            format: 'M!/d!/yyyy',
            formatGraduation: 'M!/d!/yyyy'
        };

        $scope.uiLogic = {};
        $scope.tempMonths;
        $scope.tempVerMonths;

        $scope.documentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.individualId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "R",
            LoadByDocIdAndDocCode: true,
            DocId: 104,
            DocCode: "D1104",
            LoadDocumentsWithoutBroadCast: true,
            hideUploadedFor: true,
            hideValidationMarks: true
        };

        $scope.maskConfig = {
            clearOnBlur: false,
            allowInvalidValue: true,
            clearOnBlurPlaceholder: false
        };

        $scope.experience = {};
        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        var validateForm = function (currentForm, externalForm) {
            if (!$scope.natValidation) {
                $scope.natValidation = {};
            }
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                if (!currentForm.errorMessages)
                    currentForm.errorMessages = [];
                currentForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            }
            if (!!externalForm) {
                if ($scope.applicationInfo && [1, 2].indexOf($scope.applicationInfo.LicenseApplicationForId) != -1) {
                    if (!!$scope.entityConfirmationExperience && $scope.entityConfirmationExperience.ConfirmationResponse == null) {
                        $scope.natValidation.errorMessages.push({
                            errorMessage: Messages.General.Errors.ConfirmQuestion
                        });
                    }
                    if (!!$scope.entityConfirmationExperience && !!$scope.entityConfirmationExperience.ConfirmationResponse
                        && (!$scope.experienceList || $scope.experienceList.length == 0)) {
                        $scope.natValidation.errorMessages.push({
                            errorMessage: Messages.General.Errors.OneRecord
                        });
                    }
                } else {
                    if ($scope.entityConfirmationNCEESRecord && !!$scope.entityConfirmationNCEESRecord.ConfirmationResponse &&
                        !!$scope.entityConfirmationExperienceNCEES && $scope.entityConfirmationExperienceNCEES.ConfirmationResponse == null) {
                        $scope.natValidation.errorMessages.push({
                            errorMessage: Messages.General.Errors.ExpSupplementRecordIsRequired
                        });
                    }
                    if ($scope.entityConfirmationNCEESRecord && !!$scope.entityConfirmationNCEESRecord.ConfirmationResponse &&
                        !!$scope.entityConfirmationExperienceNCEES && !!$scope.entityConfirmationExperienceNCEES.ConfirmationResponse
                        && (!$scope.experienceList || $scope.experienceList.length == 0)) {
                        $scope.natValidation.errorMessages.push({
                            errorMessage: Messages.General.Errors.OneRecord
                        });
                    }
                    if (($scope.entityConfirmationNCEESRecord && !$scope.entityConfirmationNCEESRecord.ConfirmationResponse)
                        && (!!$scope.individualLicense && $scope.individualLicense.licenseTypeInfo && $scope.individualLicense.licenseTypeInfo.NumberOfReferenceRequired
                            && (!$scope.experienceList || $scope.experienceList.length < $scope.individualLicense.licenseTypeInfo.NumberOfReferenceRequired))) {
                        $scope.natValidation.errorMessages.push({
                            errorMessage: Messages.General.Errors.ExpAtLeastXRecordsRequired.replace("{{numberOfRecord}}", $scope.individualLicense.licenseTypeInfo.NumberOfReferenceRequired)
                        });
                    }
                }


                if ($scope.applicationInfo && [1, 2].indexOf($scope.applicationInfo.LicenseApplicationForId) != -1) {
                    if ((!!$scope.entityConfirmationExperience && $scope.entityConfirmationExperience.ConfirmationResponse == false) &&
                        (!!$scope.entityConfirmationEducation && $scope.entityConfirmationEducation.ConfirmationResponse == false)) {
                        $scope.natValidation.errorMessages.push({
                            errorMessage: Messages.General.Errors.EitherExpOrEdu
                        });
                    }
                }
            }

            if (!!$scope.experience.EmploymentDate && !!$scope.experience.TerminationDate && !!$scope.experience.TotalWorked) {
                if (!monthDiff($scope.experience.EmploymentDate, $scope.experience.TerminationDate, $scope.experience.TotalWorked)) {
                    $scope.natValidation.errorMessages.push({
                        errorMessage: "Number of months entered is incorrect"
                    });
                }
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        function monthDiff(d1, d2, monthsEntered) {
            var months;
            d1 = new Date(d1);
            d2 = new Date(d2);
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return parseInt(monthsEntered) <= months ? true : false;
        }

        function AuditVisitInfoSave() {
            // $scope.ncess
            var data = {
                "PageName": $scope.tab.text,
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };

        var getConfigData = function () {
            var dataPromise = [];
            dataPromise.push(typeValuesService.getTypeValuesByTypeNameAndParamsPublic("RefTablesGetAll", true, ("?tableKey=licenseapplicationfor&pullInActive=true")));

            $q.all(dataPromise)
                .then(function (res) {
                    var status = true;
                    res.forEach(function (response) {
                        if (typeof (response.StatusCode) != 'undefined' && response.StatusCode != '00') {
                            status = false;
                        }
                    });
                    if (status) {
                        $scope.initialLicenseApplicationSubTypes = res[0].ReferenceEntities;
                        if ($scope.initialLicenseApplicationSubTypes) {
                            if ($scope.applicationInfo && $scope.applicationInfo.LicenseTypeId == 16) {
                                $scope.initialLicenseApplicationSubTypes = $scope.initialLicenseApplicationSubTypes.filter(function (applicationForSubType) {
                                    return ((!!applicationForSubType.IsActive && [5, 16].indexOf(applicationForSubType.LicenseApplicationForId) != -1));
                                });
                            }
                            else {
                                $scope.initialLicenseApplicationSubTypes = $scope.initialLicenseApplicationSubTypes.filter(function (applicationForSubType) {
                                    return ((!!applicationForSubType.IsActive && applicationForSubType.LicenseApplicationForTypeId == 2) || (applicationForSubType.LicenseApplicationForTypeId == 2 && [5, 27, 28].indexOf(applicationForSubType.LicenseApplicationForId) != -1));
                                });
                            }
                        }
                    }

                }, function (error) {
                    console.log(error);
                });
        };

        var init = function () {
            if (!$scope.formType) {
                if (!!$scope.individualLicense
                    && !!$scope.individualLicense.licenseTypeInfo && !!$scope.individualLicense.licenseTypeInfo.ReferenceFormType) {
                    $scope.formType = $scope.individualLicense.licenseTypeInfo.ReferenceFormType;
                }
            }
            ShowLoader();
            if (!$scope.dashboard)
                AuditVisitInfoSave();
            $scope.getExperience(true);
            getConfigData();
            var dataPromise = [];

            dataPromise.push(countryStateService.countryGetAll(sessionStorage.Key));
            dataPromise.push(countryStateService.getStateByCountryID(sessionStorage.Key, $rootScope.DefaultCountryId));
            dataPromise.push($scope.getEntityConfirmation());

            $q.all(dataPromise).then(function (res) {
                if (res[0].Status) {
                    $scope.countryList = res[0].Country;
                }
                if (res[1].Status) {
                    $scope.stateList = res[1].State;
                    $scope.referenceStateList = res[1].State;
                    $scope.referenceLicenseStateList = res[1].State;
                    $scope.referenceResponseStateList = res[1].State;
                }
                HideLoader();
            }, function (error) {
                HideLoader();
            });

            $scope.experienceVerificationForm = null;
            ConfigurationService.ConfigurationGetbySettings(sessionStorage.Key, 'ExperienceVerificationFormLink').then(function (res) {
                if (res && res.data.Status && res.data.ConfigurationList && res.data.ConfigurationList.length > 0) {
                    var setVal = res.data.ConfigurationList[0].Value;
                    if (setVal && setVal != null) {
                        $scope.experienceVerificationForm = setVal;
                    }
                }
            }, function (error) {

            });
        }

        $scope.getStateByCountryID = function (countryId) {
            ShowLoader();
            try {
                countryStateService.getStateByCountryID(sessionStorage.Key, countryId)
                    .then(function (response) {
                        if (response.Status) {
                            $scope.stateList = response.State;
                        } else {
                            $scope.stateList = null;
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) {
                            $scope.showStatusMessage(data.message, "error");
                        }
                    });
            } catch (ex) {
                if (ex != null) {
                    $scope.showStatusMessage(ex.message, "error");
                }
                $exceptionHandler(ex);
            }
        };

        $scope.getReferenceStateByCountryID = function (referenceCountryId) {
            ShowLoader();
            try {
                countryStateService.getStateByCountryID(sessionStorage.Key, referenceCountryId)
                    .then(function (response) {
                        if (response.Status) {
                            $scope.referenceStateList = response.State;
                        } else {
                            $scope.referenceStateList = null;
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) {
                            $scope.showStatusMessage(data.message, "error");
                        }
                    });
            } catch (ex) {
                if (ex != null) {
                    $scope.showStatusMessage(ex.message, "error");
                }
                $exceptionHandler(ex);
            }
        };

        $scope.getTotalExperience = function () {
            if ($scope.experience.TerminationDate && $scope.experience.EmploymentDate) {
                var months = utilityService.getDatesDiff($scope.experience.TerminationDate, $scope.experience.EmploymentDate);
                if (months > 0) {
                    $scope.experience.ExpYears = (months).toFixed(1);
                }
            }
        }

        $scope.saveTabHtml = function (experience, htmlContent) {
            var reqData = {
                ApplicationID: $scope.applicationId,
                IndividualID: $scope.individualId,
                TabName: "ExperienceFormA",
                CreatedBy: sessionStorage.UserID,
                DataDesc: "Experience Form Part A",
                HTMLContent: htmlContent,
                SortingOrder: 1,
                TableName: "individualexperience",
                TableLinkId: experience.IndividualExperienceId,
                TableLinkIdColumnName: "IndividualExperienceId",
                TableLinkTypeId: 1
            };
            return htmlToPdfGenerationService.saveTabHtml(reqData, sessionStorage.Key);
        };

        var processExternalForm = function (experienceItem) {
            var deferred = $q.defer();
            var entityExternalFormType = $scope.entityExternalFormTypes.whereEquals($scope.formType, 'ExternalFormTypeCode').firstOrDefault();
            var externalFormData = [];
            var externalForm = {
                EntityId: $scope.individualId,
                EntityType: 'I',
                EntityName: experienceItem.SupervisorName,
                ApplicationId: $scope.applicationId,
                ExternalFormTypeId: entityExternalFormType.ExternalFormTypeId,
                ExternalFormStatusId: 1,
                AssignedToEmailIds: experienceItem.Email,
                IsActive: true,
                IsDeleted: false,
                TemplateId: 136,
                SendEmail: true,
                GenerateFormPdf: true,
                EntityExternalFormAssignedItems: []
            };
            externalForm.EntityExternalFormAssignedItems.push({
                EntityId: $scope.individualId,
                EntityType: 'I',
                EntityExternalFormAssignedItemTypeId: 1,
                EntityExternalFormAssignedItemStatusId: 1,
                EntityExternalFormAssignedItemLinkId: experienceItem.IndividualExperienceId,
                IsActive: true,
                IsDeleted: false,
                EntityExternalFormPdfDetail: {
                    GenerateFormPdf: true,
                    LoadHtmlFromDb: true,
                    EntityId: $scope.individualId,
                    EntityType: 'I',
                    ApplicationId: $scope.applicationId,
                    DataDesc: "Experience Form Part A",
                    TabName: "ExperienceFormA",
                    TableName: "individualexperience",
                    TableLinkId: experienceItem.IndividualExperienceId,
                    TableLinkIdColumnName: "IndividualExperienceId",
                    TableLinkTypeId: 1,
                    SortingOrder: 1,
                    DocumentId: 107,
                    DocumentCd: "D1107"
                }
            });
            externalFormData.push(externalForm);
            if (!!externalFormData && externalFormData.length > 0) {
                externalFormService.saveEntityExternalForm(externalFormData, sessionStorage.Key)
                    .then(function (response) {
                        deferred.resolve(response);
                    }, function (data) {
                        showStatusMessage(data, "error");
                    });
            } else {
                deferred.resolve({
                    Status: true
                });
            }
            return deferred.promise;
        };

        $scope.sendExternalFormDeficiency = function () {
            $scope.externalFormData = $scope.experience.entityExternalForm;
            if ($scope.externalFormData) {
                $scope.externalFormData.DeficiencyStatusId = 1;
                $scope.externalFormData.SaveFormOnResend = true;
                $scope.externalFormData.SendEmail = true;
                $scope.externalFormData.TemplateId = 156;
                $scope.externalFormData.ReGenerateFormUrl = false;

                var dataPromises = {
                    reSendEntityExternalForm: externalFormService.reSendEntityExternalForm([$scope.externalFormData], sessionStorage.Key)
                }
                ShowLoader();
                $q.all(dataPromises)
                    .then(function (response) {
                        var errorMessage = '';
                        angular.forEach(response, function (responseItem) {
                            if (!responseItem.Status) {
                                errorMessage = errorMessage + responseItem.Message;
                            }
                        });
                        if (!!errorMessage) {
                            $scope.showStatusMessage(errorMessage, "error");
                            HideLoader();
                            return;
                        }
                        $scope.hideAddUpdateForm();
                        $scope.getExperience();
                        HideLoader();
                    }, function (error) {
                        $scope.showStatusMessage(error.message, "error");
                    });
            }
        };

        $scope.saveExperience = function (form, saveExternalFormDeficiency) {
            if (validateForm(form)) {
                $scope.experience.IndividualId = $scope.individualId;
                $scope.experience.ApplicationId = $scope.applicationId;
                $scope.experience.IsActive = true;
                $scope.experience.IsDeleted = false;
                ShowLoader("Please Wait...");

                var dataSavePromises = {
                    experience: initialApplicationExperiencService.saveExperience([$scope.experience], sessionStorage.Key)
                };
                if (!!$scope.experience.entityExternalForm) {
                    $scope.experience.entityExternalForm.DeficiencyStatusId = (!!$scope.experience.entityExternalForm.DeficiencyResolved ? 3 : $scope.experience.entityExternalForm.DeficiencyStatusId);
                    dataSavePromises.entityExternalForm = externalFormService.saveEntityExternalForm([$scope.experience.entityExternalForm], sessionStorage.Key)
                }
                $q.all(dataSavePromises)
                    .then(function (response) {
                        if (!response.experience.Status) {
                            showStatusMessage(response.Message, "error");
                            HideLoader();
                            return;
                        }
                        if (!!response.experience && response.experience.IndividualExperienceList && response.experience.IndividualExperienceList.length > 0) {
                            $scope.experience.IndividualExperienceId = response.experience.IndividualExperienceList[0].IndividualExperienceId;
                        }
                        if (!saveExternalFormDeficiency) {
                            htmlToPdfGenerationService.processExternalFormHtml('#experiencFormPartA').then(function (response) {
                                $scope.saveTabHtml($scope.experience, response).then(function (responseTabSave) {
                                    if (!responseTabSave.Status) {
                                        HideLoader();
                                        $scope.showStatusMessage(responseTabSave.Message, "error");
                                        return;
                                    }
                                    if (!$scope.sendExternalForm) {
                                        $scope.hideAddUpdateForm();
                                        $scope.getExperience();
                                        HideLoader();
                                    } else {
                                        processExternalForm($scope.experience).then(function (responseForm) {
                                            if (!responseForm.Status) {
                                                $scope.showStatusMessage(responseForm.Message, "error");
                                            }
                                            $scope.hideAddUpdateForm();
                                            $scope.getExperience();
                                            HideLoader();
                                        }, function (error) {
                                            $scope.showStatusMessage(error.message, "error");
                                            HideLoader();
                                        })
                                    }
                                }, function (error) {
                                    $scope.showStatusMessage(error.message, "error");
                                    HideLoader();
                                })
                            }, function (error) {
                                $scope.showStatusMessage(error.message, "error");
                                HideLoader();
                            })
                        } else {
                            $scope.sendExternalFormDeficiency();
                        }
                    }, function (error) {
                        HideLoader();
                    });
            }
        };

        $scope.getQualifyingExperienceMonths = function () {
            var qualifyingExperienceMonths = 0;
            if ($scope.experienceList && $scope.experienceList.length > 0) {
                angular.forEach($scope.experienceList, function (experienceItem) {
                    qualifyingExperienceMonths = qualifyingExperienceMonths + (!!experienceItem.VerifiedExpMonths ? experienceItem.VerifiedExpMonths : 0);
                });
            };
            $scope.experienceLoaded = true;
            if (angular.isNumber($scope.uiLogic.qualifyingExperienceMonths) && $scope.uiLogic.qualifyingExperienceMonths != 0) {
                if (angular.isNumber($scope.tempVerMonths)) {
                    $scope.uiLogic.qualifyingExperienceMonths -= parseInt($scope.tempVerMonths);
                    $scope.uiLogic.qualifyingExperienceMonths += qualifyingExperienceMonths;
                    $scope.tempVerMonths = qualifyingExperienceMonths;
                }
                else {
                    $scope.uiLogic.qualifyingExperienceMonths = $scope.tempMonths + qualifyingExperienceMonths;
                    $scope.tempVerMonths = qualifyingExperienceMonths;
                }
            }
            else {
                $scope.uiLogic.qualifyingExperienceMonths = qualifyingExperienceMonths;
            }
        };

        $scope.$watch('uiLogic.NCEESRecordTechnicalReviewExperience.NCEESRecordTechnicalReviewExperience[0].legalResponse.Desc', function (newval) {
            if (!!newval && newval != "") {
                if (angular.isNumber($scope.tempMonths)) {
                    $scope.uiLogic.qualifyingExperienceMonths -= parseInt($scope.tempMonths);
                    $scope.uiLogic.qualifyingExperienceMonths += parseInt(newval);
                    $scope.tempMonths = parseInt(newval);
                }
                else {
                    $scope.uiLogic.qualifyingExperienceMonths += parseInt(newval);
                    $scope.tempMonths = parseInt(newval);
                }
            }
            else {
                if (angular.isNumber($scope.tempMonths)) {
                    $scope.uiLogic.qualifyingExperienceMonths -= parseInt($scope.tempMonths);
                    $scope.uiLogic.qualifyingExperienceMonths += 0;
                    $scope.tempMonths = 0;
                }
            }
        })

        $scope.getAcknowledgement = function (experienceItem) {
            $scope.individualReference = experienceItem.individualReference;
            externalFormService.acknowledgementGet($scope.individualId, sessionStorage.Key, 110, experienceItem.entityExternalForm.EntityExternalFormId).then(function (response) {
                if (response.IndividualAffidavitResponseList != null && response.IndividualAffidavitResponseList.length > 0) {
                    $scope.acknowledgement = response.IndividualAffidavitResponseList.whereEquals(430, 'ContentItemLkId').firstOrDefault();
                    if (!!$scope.acknowledgement && !!$scope.acknowledgement.Date && !angular.isDate($scope.acknowledgement.Date))
                        $scope.acknowledgement.Date = new Date($scope.acknowledgement.Date);
                }
            })
        };

        $scope.getExperience = function (initialCall) {
            ShowLoader("Please Wait...");
            var promises = {
                individualExperience: initialApplicationExperiencService.getIndividualExperience($scope.individualId, $scope.applicationId, sessionStorage.Key),
                entityExternalForm: externalFormService.getEntityExternalForm(sessionStorage.Key, $scope.individualId, 'I', null, $scope.applicationId),
                reference: externalFormService.getIndividualReference(sessionStorage.Key, { IndividualId: $scope.individualId })
            }
            if (!!initialCall) {
                promises.licenseapplicationfor = typeValuesService.getTypeValuesByTypeNameAndParamsPublic("RefTablesGetAll", true, ("?tableKey=licenseapplicationfor&pullInActive=true"));
                promises.referencerelationtype = typeValuesService.getTypeValuesByTypeNameAndParamsPublic("RefTablesGetAll", true, ("?tableKey=referencerelationtype"));
                promises.experiencearea = typeValuesService.getTypeValuesByTypeNameAndParamsPublic("RefTablesGetAll", true, ("?tableKey=experiencearea"));
                promises.licensetype = typeValuesService.getTypeValuesByTypeNameAndParamsPublic("RefTablesGetAll", true, ("?tableKey=licensetype"));
                promises.country = typeValuesService.getTypeValuesByTypeNameAndParamsPublic("RefTablesGetAll", true, ("?tableKey=country"));
                promises.entityexternalformtype = typeValuesService.getTypeValuesByTypeNameAndParamsPublic("RefTablesGetAll", true, ("?tableKey=entityexternalformtype"));
            }
            $q.all(promises).then(function (response) {
                var errorMessage = '';
                angular.forEach(response, function (responseItem) {
                    if (!responseItem.Status) {
                        errorMessage = errorMessage + responseItem.Message;
                    }
                });
                if (!!errorMessage) {
                    $scope.showStatusMessage(errorMessage, "error");
                    HideLoader();
                    return;
                }

                if (!!initialCall) {
                    $scope.initialLicenseApplicationSubTypes = response.licenseapplicationfor.ReferenceEntities;
                    if ($scope.initialLicenseApplicationSubTypes) {
                        if ($scope.applicationInfo && $scope.applicationInfo.LicenseTypeId == 16) {
                            $scope.initialLicenseApplicationSubTypes = $scope.initialLicenseApplicationSubTypes.filter(function (applicationForSubType) {
                                return ((!!applicationForSubType.IsActive && [5, 16].indexOf(applicationForSubType.LicenseApplicationForId) != -1));
                            });
                        }
                        else {
                            $scope.initialLicenseApplicationSubTypes = $scope.initialLicenseApplicationSubTypes.filter(function (applicationForSubType) {
                                return ((!!applicationForSubType.IsActive && applicationForSubType.LicenseApplicationForTypeId == 2) || (applicationForSubType.LicenseApplicationForTypeId == 2 && [5, 27, 28].indexOf(applicationForSubType.LicenseApplicationForId) != -1));
                            });
                        }
                    }
                    $scope.refreferenceRelationTypes = response.referencerelationtype.ReferenceEntities;
                    $scope.refreferenceExperienceAreas = response.experiencearea.ReferenceEntities;
                    $scope.licenseTypes = response.licensetype.ReferenceEntities;
                    $scope.countries = response.country.ReferenceEntities;
                    $scope.entityExternalFormTypes = response.entityexternalformtype.ReferenceEntities;
                }

                $scope.experienceList = response.individualExperience.IndividualExperienceList;
                $scope.getQualifyingExperienceMonths();
                $scope.entityExternalForms = response.entityExternalForm.EntityRecords;
                if (!!$scope.experienceList && $scope.experienceList.length > 0 &&
                    !!$scope.entityExternalForms && $scope.entityExternalForms.length > 0) {
                    angular.forEach($scope.entityExternalForms, function (entityExternalFormItem) {
                        if (!!entityExternalFormItem.EntityExternalFormAssignedItems && entityExternalFormItem.EntityExternalFormAssignedItems.length > 0) {
                            angular.forEach(entityExternalFormItem.EntityExternalFormAssignedItems, function (entityExternalFormAssignedItem) {
                                var experienceItem = $scope.experienceList.whereEquals(entityExternalFormAssignedItem.EntityExternalFormAssignedItemLinkId, 'IndividualExperienceId').firstOrDefault();
                                if (!!experienceItem) {
                                    experienceItem.entityExternalForm = angular.copy(entityExternalFormItem);
                                    experienceItem.entityExternalForm.DeficiencyResolved = (experienceItem.entityExternalForm.DeficiencyStatusId == 3);
                                    entityExternalFormAssignedItem.individualExperience = angular.copy(experienceItem);
                                }
                            })
                        }
                    })
                }
                if (!!response.reference.EntityRecords) {
                    var individualReferenceList = response.reference.EntityRecords;
                    $scope.experienceList = $scope.experienceList
                        .leftJoinObjects(individualReferenceList, 'item1.IndividualExperienceId==item2.IndividualExperienceId', 'this1, individualReference: this2');
                }
                HideLoader();
            }, function (error) {
                HideLoader();
            });
        };

        $scope.deleteExprieance = function (individualExperience, indexToDelete, formNumber) {
            try {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    individualExperience.IsDeleted = true;
                    ShowLoader("Please Wait...");
                    initialApplicationExperiencService.saveExperience([individualExperience], sessionStorage.Key).then(function (response) {
                        if (response.Status && response.StatusCode == "00") {
                            $scope.experienceList.splice(indexToDelete, 1)
                        }
                        HideLoader();
                    }, function (error) {
                        HideLoader();
                    });
                }
            } catch (ex) {
                // $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        };

        $scope.editExperience = function (individualExperience, isClone, showDeficiencyForm) {
            $scope.showAddUpdatexperience = true;
            $scope.experience = angular.copy(individualExperience);
            $scope.experience.showDeficiencyForm = showDeficiencyForm;

            if (!!showDeficiencyForm) {
                if (!!$scope.experience && !!$scope.experience.entityExternalForm && !!$scope.experience.entityExternalForm.DeficiencyResolved) {
                    $scope.experience.entityExternalForm.DeficiencyResolved = false;
                }
            }

            if (!!isClone) {
                $scope.experience.ExperienceStatusId = 3;
                $scope.experience.LinkIndividualExperienceId = $scope.experience.IndividualExperienceId;
                $scope.experience.IndividualExperienceId = null;
            }
            if (individualExperience.CountryId)
                $scope.getStateByCountryID(individualExperience.CountryId);
            if (individualExperience.ReferenceCountryId)
                $scope.getStateByCountryID(individualExperience.CountryId);
        };

        $scope.resendReferenceForm = function (externalForm) {
            $scope.externalForm = angular.copy(externalForm);
            $scope.openResendReferenceEmail($scope.externalForm);
        }

        $scope.openResendReferenceEmail = function (externalFormData) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'resendReferenceEmail.html',
                size: 'md',
                resolve: {
                    externalFormData: externalFormData
                },
                controller: ['$scope', '$uibModalInstance', 'externalFormData', function ($scope, $uibModalInstance, externalFormData) {
                    $scope.externalFormData = externalFormData;

                    var validateForm = function (formsToValidate) {
                        $scope.natValidation = {
                            errorMessages: []
                        };

                        if (!!formsToValidate) {
                            angular.forEach(formsToValidate, function (currentForm, index) {
                                currentForm.$submitted = true;
                                if (!!currentForm.errorMessages) {
                                    var errorMessages = angular.copy(currentForm.errorMessages);
                                    angular.forEach(errorMessages, function (errorMessage, index) {
                                        $scope.natValidation.errorMessages.push(errorMessage);
                                    });
                                }
                            });
                        }

                        if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                            $scope.showStatusMsg('-', $scope.natValidation.errorMessages);
                            return false;
                        } else {
                            $scope.hideStatusMsg();
                            return true;
                        }
                    };

                    $scope.onCancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };

                    $scope.onConfirm = function (currentForm) {
                        if (validateForm([currentForm])) {
                            $uibModalInstance.close($scope.externalFormData);
                        }
                    };

                }]
            });
            modalInstance.result.then(function (response) {
                $scope.externalFormData = response;
                if ($scope.externalFormData && !!$scope.externalFormData.EntityExternalFormAssignedItems
                    && $scope.externalFormData.EntityExternalFormAssignedItems.length > 0 && !!$scope.externalFormData.EntityExternalFormAssignedItems[0].individualExperience) {
                    var experience = $scope.externalFormData.EntityExternalFormAssignedItems[0].individualExperience;
                    experience.Email = $scope.externalFormData.AssignedToEmailIds;
                    $scope.externalFormData.SaveFormOnResend = true;
                    $scope.externalFormData.SendEmail = true;
                    $scope.externalFormData.TemplateId = 136;
                    $scope.externalFormData.ReGenerateFormUrl = true;

                    var dataPromises = {
                        saveExperience: initialApplicationExperiencService.saveExperience([experience], sessionStorage.Key),
                        reSendEntityExternalForm: externalFormService.reSendEntityExternalForm([$scope.externalFormData], sessionStorage.Key)
                    }
                    ShowLoader();
                    $q.all(dataPromises)
                        .then(function (response) {
                            var errorMessage = '';
                            angular.forEach(response, function (responseItem) {
                                if (!responseItem.Status) {
                                    errorMessage = errorMessage + responseItem.Message;
                                }
                            });
                            if (!!errorMessage) {
                                $scope.showStatusMessage(errorMessage, "error");
                                HideLoader();
                                return;
                            }
                            HideLoader();
                            $scope.getExperience();
                        }, function (error) {
                            $scope.showStatusMessage(error.message, "error");
                        });
                }

            }, function () {

            });
        };

        $scope.saveAndNext = function (skipNextTab) {
            ShowLoader();
            $scope.$broadcast("SaveDeficiency", {});
            $scope.$broadcast("NCEESRecordTechnicalReviewExperienceSave", {});
            $scope.tab.isInvalid = false;
            if (!validateForm(null, true)) {
                $scope.tab.isInvalid = true;
                HideLoader();
                if (skipNextTab == true) {
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
                if (skipNextTab && !!skipNextTab.fromDeficiency) {
                    $scope.$emit('deficiencyExecuted', { success: false });
                }
                return;
            }
            $scope.saveEntityConfirmation().then(function (response) {
                if (!response.Status) {
                    $scope.showStatusMessage(response.Message, "error");
                    HideLoader();
                    return;
                }
                HideLoader();
                $scope.tab.skipNextTab = skipNextTab;
                $scope.openNextTab({
                    currentTab: $scope.tab
                });
                if ($scope.tab.skipNextTab == true) {
                    $scope.$emit('setCurrentTabNow');
                }
                if (skipNextTab && !!skipNextTab.fromDeficiency) {
                    $scope.$emit('deficiencyExecuted', { success: true });
                }
            }, function (error) {
                $scope.showStatusMessage(error.message, "error");
            })
        };

        $scope.showAddUpdateForm = function (sendExternalForm, experienceStatusId) {
            $scope.showAddUpdatexperience = true;
            $scope.experience = {
                ExperienceStatusId: experienceStatusId
            };
            $scope.sendExternalForm = sendExternalForm;
        };

        $scope.hideAddUpdateForm = function (clearErrorMessages) {
            $scope.showAddUpdatexperience = false;
            $scope.experience = {};
            if (!!clearErrorMessages && !!$scope.natValidation) {
                $scope.natValidation.errorMessages = [];
            }
        }

        var getNewEntityConfirmation = function (content) {
            return {
                EntityConfirmationId: 0,
                EntityId: $scope.individualId,
                EntityTy: 'I',
                ApplicationId: $scope.applicationId,
                ContentItemLkId: content.ContentItemLkId,
                ConfirmationResponse: null,
                Desc: '',
                IsActive: true,
                IsDeleted: false
            };
        };

        $scope.getEntityConfirmation = function () {
            var deferred = $q.defer();
            try {
                $q.all([
                    contentService.getContentByContentItemLkIdList([472, 509], sessionStorage.Key),
                    EntityConfirmationService.getEntityConfirmation(sessionStorage.Key, $scope.individualId, 'I', $scope.applicationId)
                ])
                    .then(function (response) {
                        if (response.any('!item.Status')) {
                            HideLoader();
                            $scope.showStatusMessage(Messages.aeabcfa, "error");
                            return;
                        }
                        $scope.contentDataExperience = response[0].ContentItemLk.whereEquals(472, 'ContentItemLkId').firstOrDefault();
                        $scope.contentDataExperienceNCEES = response[0].ContentItemLk.whereEquals(509, 'ContentItemLkId').firstOrDefault();

                        $scope.entityConfirmationExperience = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals(472, 'ContentItemLkId')
                            .firstOrDefault();

                        $scope.entityConfirmationExperienceNCEES = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals(509, 'ContentItemLkId')
                            .firstOrDefault();

                        $scope.entityConfirmationEducation = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals(471, 'ContentItemLkId')
                            .firstOrDefault();

                        $scope.entityConfirmationNCEESRecord = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals(499, 'ContentItemLkId')
                            .firstOrDefault();

                        $scope.entityConfirmationQualNCEESEngExam = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals('QualNCEESEngExam', 'ContentItemLkCode')
                            .firstOrDefault();

                        $scope.entityConfirmationQualNCEESWaiver = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals('QualNCEESWaiver', 'ContentItemLkCode')
                            .firstOrDefault();
                        $scope.entityConfirmationQualNCEESSurveyorWaiver = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals('QualNCEESSurveyorWaiver', 'ContentItemLkCode')
                            .firstOrDefault();
                        $scope.entityConfirmationQualNCEESSurveyorExam = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals('QualNCEESSurveyorExam', 'ContentItemLkCode')
                            .firstOrDefault();

                        if (!$scope.entityConfirmationExperience) {
                            $scope.entityConfirmationExperience = getNewEntityConfirmation($scope.contentDataExperience);
                        }
                        if (!$scope.entityConfirmationExperienceNCEES) {
                            $scope.entityConfirmationExperienceNCEES = getNewEntityConfirmation($scope.contentDataExperienceNCEES);
                        }
                        deferred.resolve({
                            Status: true
                        });
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }
            return deferred.promise;
        };

        $scope.saveEntityConfirmation = function () {
            var deferred = $q.defer();
            try {
                EntityConfirmationService.saveEntityConfirmation(sessionStorage.Key, [$scope.entityConfirmationExperience, $scope.entityConfirmationExperienceNCEES])
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.$on('SwitchingTab', function (event, args) {
            if (args.fromTab == $scope.tab.name) {
                if (!$scope.isBackoffice) {
                    $scope.saveAndNext(true);
                } else {
                    $scope.$broadcast("SaveDeficiency", {});
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
            }
        });

        $scope.$on('deficiencyResolve', function (event, args) {
            if (args.tabName == 'experience') {
                $scope.saveAndNext({ fromDeficiency: true });
            }
        });

        $scope.clearTabConfirmation = function () {
            $scope.$emit("clearTabConfirmation", { currentTab: $scope.tab });
        };

        init();
    }
})();