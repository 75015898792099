(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('peerReviewService', peerReviewService)

    peerReviewService.$inject = ['$http', '$q', 'initialApplicationstatusService', 'WebApiUrl', 'AppConfig']

    function peerReviewService($http, $q, initialApplicationstatusService, WebApiUrl, AppConfig) {

        var _getLegalInfoContent = function (contentItemLkIdList, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Content/ContentGetBYContentItemLkIdList/' + key, contentItemLkIdList)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        var _legalInfoGetProvider = function (ProviderId, applicationId,ContentLkToPageTabSectionId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Entity/GetEntityLegal/' + key + '?IndividualId=null&ProviderId=' + ProviderId + "&ApplicationId=" + applicationId + "&ContentLkToPageTabSectionId=" + ContentLkToPageTabSectionId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        var _legalInfoSaveProvider = function (legalInfoObj, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Entity/SaveEntityLegal/' + key + '?Source=' + AppConfig.Source, legalInfoObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _loadIndividualLegalAddInfo = function (individualId, applicationId, userId, legalInfoObj, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Entity/LoadAllEntityLegalAddInfo/' + key +
                '?individualId=' + individualId + '&applicationId=' + applicationId + '&userId=' + userId, legalInfoObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _loadAllProviderLegalAddInfo = function (individualId, applicationId, userId, legalInfoObj, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Entity/LoadAllEntityLegalAddInfo/' + key + '?Source=' + AppConfig.Source + 
                '&entityId=' + individualId + '&applicationId=' + applicationId + '&userId=' + userId, legalInfoObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveIndividualLegalAddInfo = function (entityId, applicationId, userId, legalInfoObj, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Entity/SaveEntityLegalAddInfo/' + key +
                '?entityId=' + entityId + '&applicationId=' + applicationId + '&userId=' + userId, legalInfoObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            legalInfoSaveProvider: _legalInfoSaveProvider,
            legalInfoGetProvider: _legalInfoGetProvider,
            getLegalInfoContent: _getLegalInfoContent,
            loadIndividualLegalAddInfo: _loadIndividualLegalAddInfo,
            saveIndividualLegalAddInfo: _saveIndividualLegalAddInfo,
            loadAllProviderLegalAddInfo: _loadAllProviderLegalAddInfo
        };
    }
})();
