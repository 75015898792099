(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('collaborativeDrugTherapyValidation', [CollaborativeDrugTherapyValidation]);

    function CollaborativeDrugTherapyValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function () {
                    var errMsgs = [];
                    var valid = true;
                    if (!!$scope.showConfirmation && !!$scope.current.confirmationResponse.ConfirmationResponse) {
                        if (!$scope.current.affidavitResponse.ContentItemResponse) {
                            valid = false;
                            errMsgs.push('You must check the verification box.');
                        }
                    }

                    $scope.showStatusMsg('-', errMsgs);

                    return valid;
                }
                
            }
        };
    }
})();