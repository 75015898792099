(function () {
  'use strict'
  angular.module('app.report')
    .controller('OccupationalLicensingReportController', OccupationalLicensingReportController);

  OccupationalLicensingReportController.$inject = ['$scope', '$q', '$controller', '$filter',
    'reportService', '$state', 'utilityService', 'ApiUrlsForReports', 'ApiUrlsForReportsXls'];

  function OccupationalLicensingReportController($scope, $q, $controller, $filter, reportService, $state, utilityService, ApiUrlsForReports, ApiUrlsForReportsXls) {
    // // Pagination
    // $scope.Pager = {
    //   sortType: 'LicenseNumber',
    //   sortOrder: 'asc',
    //   currentPage: 1,
    //   totalRecords: 10,
    //   pageSize: 20,
    //   maxSize: 5
    // }
    $scope.Headers = [
      { name: 'Quarter', sortType: 'Quarter', sortOrder: 'asc', class: 'w200' },
      { name: 'Count Of New License', sortType: 'CountOfNewLicense', sortOrder: 'asc', class: 'w150' },
      { name: 'Count Of License Expired for 2 Years', sortType: 'CountOfLicenseExpiredFor2Years', sortOrder: 'desc', class: 'w150' }
    ]
    $scope.getOccupationalLicensingReportList = function () {
      try {
        if ($scope.isUserSessionActive()) {
          ShowLoader('Please Wait...')
          reportService.occupationalLicensingReport(sessionStorage.Key, null, ApiUrlsForReports.occupationalLicensing)
            .then(function (response) {
              if ($scope.checkServerResponse(response)) {
                $scope.OccupationalLicensingData = response.PagerVM.Records;
              }
              HideLoader();
              utilityService.scrollTop();
            }, function (data) {
              HideLoader()
              showStatusMessage(data, 'error')
            })
        } else {
          $state.go('app.Loginwoemail')
        }
      } catch (ex) {
        HideLoader()
        if (ex != null) { showStatusMessage(ex.message, 'error'); }
      }
    }

    $scope.getOccupationalLicensingReportList();

    $scope.SortRecords = function (header) {
      $scope.Pager.currentPage = 1;
      $scope.Pager.sortType = header.sortType;
      $scope.Pager.sortOrder = header.sortOrder;
      header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
      $scope.getOccupationalLicensingReportList();
    }
    $scope.getExcelForOccupationalLicensingReport = function () {
      try {
        ShowLoader('Please Wait...')
        reportService.getXLSForOccupationalLicensingReport(sessionStorage.Key, null, ApiUrlsForReportsXls.occupationalLicensing)
          .then(function (response) {
            HideLoader()
          }, function (data) {
            HideLoader()
            showStatusMessage(data, 'error')
          })
      } catch (ex) {
        HideLoader()
        if (ex != null) { showStatusMessage(ex.message, 'error'); }
      }
    }
  };
})();

