﻿(function () {
    'use strict'
    angular.module('natApp')
      .directive('beDocumentsDirective', beDocumentsDirective);

    beDocumentsDirective.$inject = [];

    function beDocumentsDirective() {
        return {
            restrict: 'E',
            scope: {
                providerid: "=providerid",
                showheader: '=showheader',
                eventname: "=eventname",
                applicationId: "=applicationId",
                userId: '=userId',
                providerLicense: "=providerLicense",
                natValidation: "=natValidation"
            },
            templateUrl: "app/components/individual/businessentity/directives/be-document-directive/be-document-directive.html",
            controller: "BEDocumentsController"
        }
    }
})();