(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .directive('applicationListingLogic', ['$rootScope', '$q', 'licenseApplicationListingService', 'TypeValue', 'typeValuesService', ApplicationListingLogic]);

    function ApplicationListingLogic($rootScope, $q, licenseApplicationListingService, TypeValue, typeValuesService) {
        return {
            restrict: 'AE',
            link: function ($scope, element, attrs) {
                //BEGIN GENERAL
                var showIndividualSearchSection = function () {
                    $scope.showIndividualSearch = true;
                    $scope.showAppTypeSelection = false;
                    $scope.showNewApplication = false;

                    $scope.hideApplicationListingSection();
                };
                var showApplicationTypeSelectionSection = function () {
                    $scope.showIndividualSearch = false;
                    $scope.showAppTypeSelection = true;
                    $scope.showNewApplication = false;

                    $scope.hideApplicationListingSection();
                };
                var showNewApplicationSection = function () {
                    $scope.showIndividualSearch = false;
                    $scope.showAppTypeSelection = false;
                    $scope.showNewApplication = true;

                    $scope.hideApplicationListingSection();
                };

                //Overload - will hide all the other sections of the newapp
                $scope.hideTemplateSections = function () {
                    $scope.showIndividualSearch = false;
                    $scope.showAppTypeSelection = false;
                    $scope.showNewApplication = false;
                };
                //END GENERAL

                //BEGIN ADD APPLICATION
                $scope.startAppCreationProcess = function () {
                    showIndividualSearchSection();
                };
                //END ADD APPLICATION


                //BEGIN INDIVIDUAL/LICENSE SEARCH
                $scope.individualSelected = function (individual) {
                    if (!!individual) {
                        $scope.current.person = individual;
                    } else {
                        $scope.current.person = null;
                    }

                    if ($scope.ApplicationTypeId == 1) {
                        //Renewal Logic
                    } else if ($scope.ApplicationTypeId == 2) {
                        //Initial Application Logic
                        showApplicationTypeSelectionSection();
                    }
                };
                //END INDIVIDUAL/LICENSE SEARCH


                //BEGIN INITIAL APPLICATION TYPE SELECTION 
                $scope.applicationTypeSelected = function (appType) {
                    $scope.applicationType = appType;

                    createNewApplication();
                };
                //END INITIAL APPLICATION TYPE SELECTION


                //BEGIN APPLICATION CREATION
                var getNewApplicationEntry = function () {
                    if (!$scope.queueConfig.AddAppConfig) {
                        throw 'Cannot add new application in this queue.';
                    }

                    return {
                        ApplicationTypeId: $scope.ApplicationTypeId,
                        ApplicationSubTypeId: 1,
                        ApplicationStatusId: $scope.config.StartingApplicationStatus,
                        ApplicationSubmitMode: 'B',
                        UserId: $scope.userId,
                        GoPaperless: true,
                        IsDeleted: 0,
                        IsActive: 1,
                        IsArchive: 0,
                        LicenseApplicationForId: $scope.applicationType.LicenseApplicationForId,
                        LicensePathwayLevel1Id: $scope.applicationType.LicensePathwayLevel1Id,
                        LicenseExamTypeId: $scope.applicationType.LicenseExamTypeId,
                        LicensePathwayLevel2Id: $scope.applicationType.LicensePathwayLevel2Id,
                        LicensePathwayLevel3Id: $scope.applicationType.LicensePathwayLevel3Id,
                        EntityName: $scope.applicationType.EntityName,
                        LicenseTypeId: $scope.applicationType.LicenseTypeId,
                        IsTemporary: $scope.applicationType.IsTemporary
                    };
                };

                var getNewLicenseEntry = function () {
                    var licenseType = $scope.licenseTypes.whereEquals($scope.applicationType.LicenseTypeId, 'LicenseTypeId').firstOrDefault();
                    // var credentialType = (!!$scope.applicationType.IsTemporary) ? 'P' : licenseType.CredentialType;
                    //  var credentialTypeName = (!!$scope.applicationType.IsTemporary) ? 'Permit' : licenseType.CredentalTypeName;

                    return {
                        IndividualId: $scope.IndividualId,
                        ApplicationId: $scope.ApplicationId,
                        ApplicationTypeId: $scope.ApplicationTypeId,
                        IsLicenseTemporary: $scope.applicationType.IsTemporary,
                        IsLicenseActive: false,
                        LicenseNumber: '',
                        LicenseTypeId: $scope.applicationType.LicenseTypeId,
                        LicenseStatusTypeId: 17, //APPLICATION IN PROCESS
                        // CredentialType: credentialType,
                        //  CredentialTypeName: credentialTypeName,
                        IsActive: true,
                        IsDeleted: false,

                        ActionFlag: 'N' //Unfortunately, this is the only way to create a new license, and it ignores the IsLicenseActive
                    };
                };

                var deactivateLicense = function (data) {
                    var newIndLicense = data.IndividualLicenseList.whereEquals($scope.ApplicationId, 'ApplicationId').firstOrDefault();
                    newIndLicense.IsLicenseActive = false;
                    newIndLicense.ActionFlag = 'M';
                    return individualLicenseSave(newIndLicense);
                };

                var saveIndividualApplication = function (IndividulaID, AppID, userID) {
                    var reqData = {
                        ApplicationId: AppID,
                        IndividualId: IndividulaID,
                        CreatedBy: userID,
                        CreatedOn: new Date()
                    };

                    return licenseApplicationListingService.saveIndividualApplication(sessionStorage.Key, reqData);
                };

                var saveIndividualNameTypeLk = function (appID, indvID) {
                    var reqData = {
                        IndividualId: indvID,
                        IndividualNameTypeId: 1,
                        IndividualNameStatusId: 14,
                        ApplicationId: appID,
                        IsActive: 1,
                        IsDeleted: 0,
                    };

                    return licenseApplicationListingService.saveIndividualNameTypeLk(sessionStorage.Key, reqData);
                };

                var individualLicenseSave = function (newLicense) {
                    return licenseApplicationListingService.saveIndividualLicense(sessionStorage.Key, newLicense);
                };

                var createApplicationProcess = function (userID) {
                    var promiseSaveIndividualApplication = saveIndividualApplication($scope.IndividualId, $scope.ApplicationId, userID);
                    var promiseSaveIndividualNameTypeLk = saveIndividualNameTypeLk($scope.ApplicationId, $scope.IndividualId);
                    var promiseIndividualLicenseSave = individualLicenseSave(getNewLicenseEntry());

                    $q.all([promiseSaveIndividualApplication, promiseSaveIndividualNameTypeLk, promiseIndividualLicenseSave])
                        .then(function (res) {
                            var isSavedSuccessFully = true;
                            var errMessage = '';
                            angular.forEach(res, function (resData, key) {
                                if (resData.Status == false) {
                                    isSavedSuccessFully = false;
                                    errMessage += resData.Message;
                                }
                            });
                            if (isSavedSuccessFully) {
                                // deactivateLicense(res[2])
                                //.then(function (data) {
                                $rootScope.displayMainLoader(false);

                                $scope.setupTemplateController();

                                showNewApplicationSection();
                                // }, function (error) {
                                // });
                            } else {
                                $scope.showStatusMsg('-', res.Message);
                                $rootScope.displayMainLoader(false);
                            }
                        }, function (res) {
                            $rootScope.displayMainLoader(false);
                            $scope.showStatusMsg('-', res.Message);
                        });
                };

                var generateApplicationId = function (applicationID, userID) {
                    try {
                        var reqData = {};
                        reqData = getNewApplicationEntry();

                        licenseApplicationListingService.generateApplicationId(sessionStorage.Key, applicationID, userID, reqData)
                            .then(function (res) {
                                if ($scope.checkServerResponse(res)) {
                                    $scope.ApplicationId = res.ApplicationId;
                                    $scope.ApplicationTypeId = reqData.ApplicationTypeId;
                                    $scope.applicationNumber = res.ApplicationNumber;

                                    //Setup current application
                                    $scope.current.application = reqData;
                                    $scope.current.application.ApplicationId = $scope.ApplicationId;

                                    var reqDataSaveIndividual = {
                                        // IndividualPlaceofBirthState: 'O',
                                        Gender: '',
                                        IsActive: true,
                                        ApplicationId: res.ApplicationId
                                    };

                                    //Save a new individual if it doesn't already exist
                                    if (!$scope.current.person) {
                                        //Save a new individual since one was not already selected
                                        licenseApplicationListingService.saveIndividual(sessionStorage.Key, [reqDataSaveIndividual]).then(function (res) {
                                            if ($scope.checkServerResponse(res) && res.IndividualResponse && res.IndividualResponse.length > 0) {
                                                $scope.IndividualId = res.IndividualResponse[0].IndividualId;
                                                $scope.current.person = res.IndividualResponse[0];
                                                createApplicationProcess(userID);
                                            } else {
                                                $rootScope.displayMainLoader(false);
                                                console.log(res.Message);
                                                $scope.showStatusMsg("some error occured!", "error");
                                                return;
                                            }
                                        }, function (res) {
                                            $rootScope.displayMainLoader(false);
                                            $scope.showStatusMsg('-', res.Message);
                                            return;
                                        });
                                    } else {
                                        //Use the individual already selected
                                        $scope.IndividualId = $scope.current.person.IndividualId;
                                        createApplicationProcess(userID);
                                    }
                                } else {
                                    $rootScope.displayMainLoader(false);
                                    $scope.showStatusMsg('-', res.Message);
                                }
                            }, function (res) {
                                $rootScope.displayMainLoader(false);
                                $scope.showStatusMsg('-', res.Message);
                            });
                    } catch (ex) {
                        console.log(ex);
                        $scope.showStatusMsg('-', 'An error occurred generating the application.');
                    }
                };

                var createNewApplication = function () {
                    $rootScope.displayMainLoader(true, 'Creating application...');
                    if (!$scope.applicationType) {
                        $scope.showStatusMsg('-', 'You must select an application type to create an application');
                        $rootScope.displayMainLoader(false);
                        return;
                    }

                    //Only 
                    // $scope.getFeeDetails()
                    // .then (function () {
                    generateApplicationId(0, $scope.userId);
                    // }, function (err) {
                    //     $scope.showStatusMsg('-', 'Could not retrieve or could not find fees for selected credential definition.');
                    //     $rootScope.displayMainLoader(false);
                    // });
                };
                //END APPLICATION CREATION

                $scope.$on('tabComplete', function (event, tab) {

                });

                var getFeeDetailsByAppType = function () {
                    var feeDetailData = {};
                    var feesForLicenseType = $scope.feeDetailRows
                        .whereEquals($scope.ApplicationTypeId, 'ApplicationTypeId')
                        .whereEquals($scope.applicationType.LicenseTypeId, 'LicenseTypeId');

                    if (!$scope.applicationType.IsTemporary) {
                        feesForLicenseType = feesForLicenseType.whereNotEquals('TEMP', 'FeeTypeCode');
                    }

                    if (feesForLicenseType.length > 0) {
                        return {
                            fees: feesForLicenseType,
                            applicationFee: feesForLicenseType.sum('FeeAmount'),
                            totalFee: feesForLicenseType.sum('FeeAmount'),
                            isNewLicEndApp: (!!$scope.isNewLicEndApp)
                        };
                    } else {
                        throw 'No fees associated with this license type';
                    }

                    //var feeDetailData = {};
                    // angular.forEach($scope.feeDetailRows, function (feeDetailItem) {
                    //     feeDetailData["feeType" + feeDetailItem.FeeDetailId] = feeDetailItem;
                    // });
                    // var applicationFee = feeDetailData.feeType1.FeeAmount;
                    // var licenseRenewalFee = feeDetailData.feeType2.FeeAmount;
                    // var backgroundFee = feeDetailData.feeType3.FeeAmount;
                    // var veteranExemptionFee = feeDetailData.feeType4.FeeAmount;
                    // if ($scope.isNewLicEndApp) {
                    //     applicationFee = 0;
                    // }
                    // var licenseEndorsementFee = feeDetailData.feeType5.FeeAmount;;
                    // return {
                    //     applicationFee: applicationFee,
                    //     newLicenseFee: applicationFee,
                    //     licenseRenewalFee: licenseRenewalFee,
                    //     backgroundFee: backgroundFee,
                    //     applicationFeeWtBgFee: (applicationFee - backgroundFee),
                    //     licenseRenewalFeeWtBgFee: (licenseRenewalFee - backgroundFee),
                    //     veteranExemptionFee: veteranExemptionFee,
                    //     licenseEndorsementFee: licenseEndorsementFee,
                    //     totalFee: applicationFee,
                    //     isNewLicEndApp: (!!$scope.isNewLicEndApp)
                    // };
                };

                $scope.getFeeDetails = function () {
                    var deferred = $q.defer();
                    typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=feedetails")).then(function (responseFee) {
                        if (responseFee.Status) {
                            try {
                                $scope.feeDetailRows = responseFee.ReferenceEntities;
                                $scope.feeDetails = getFeeDetailsByAppType();
                                deferred.resolve();
                            } catch (ex) {
                                deferred.reject(ex);
                            }
                        }
                    }, function (error) {
                        deferred.reject(error);
                        showStatusMessage(error, "error");
                    });

                    return deferred.promise;
                };
            }
        };
    }
})();