(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('applicationSpecialtiesLogic', [ApplicationSpecialtiesLogic]);

    function ApplicationSpecialtiesLogic() {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                var getNewSpecialty = function () {
                    return {
                        IndividualId: $scope.individualId,
                        ApplicationId: $scope.applicationId,
                        ApplicationTypeId: $scope.applicationTypeId,
                        LicenseSpecialityTypeId: null,
                        LicenseSpecialityName: null,
                        CertificationDate: null,
        
                        LicenseEndorsementResponseList: [{
                            LicensePathWay:{
                                IndividualId: $scope.individualId,
                                ApplicationId: $scope.applicationId,
                                ApplicationTypeId: $scope.applicationTypeId,
                                IndividualLicenseId: (!!$scope.individualLicense) ? $scope.individualLicense.IndividualLicenseId : 0
                            }
                        }],
        
                        IsActive: true,
                        IsDeleted: false
                    };
                };

                $scope.addEditSpecialty = function (index, specialty) {
                    if(!!specialty || (!specialty && $scope.individualSpecialties.length < 4)) {
                        if(!specialty) {
                            specialty = getNewSpecialty();
                        }
            
                        $scope.current.specialty = angular.copy(specialty);
                    } else {
                        $scope.showStatusMsg('-', 'You may only list up to four specialities in your application.')
                    }
                };
            }
        };
    }
})();