(function () {
    'use strict'

    angular.module('app.enforcement')
    .controller('ComplaintInvolvedPartyController', ['$scope', '$rootScope', '$q', '$timeout', 'ComplaintInvolvedPartyService', 'WebApiUrl', 'utilityService', ComplaintInvolvedPartyController]);

    function ComplaintInvolvedPartyController($scope, $rootScope, $q, $timeout, ComplaintInvolvedPartyService, WebApiUrl, utilityService) {
        var _key = sessionStorage.Key;
        var _childName = '';

        $scope.isBackoffice = sessionStorage.isBackOfficeUser == 'true' || sessionStorage.isBackOfficeUser == true;

        var getNewInvolvedParty = function () {
            return {
                ComplaintInvolvedPartyTypeId: $scope.complaintInvolvedPartyTypeId,
                ComplaintId: $scope.complaint.ComplaintId,
                EntityId: null,
                EntityType: (!!$scope.uiConfig.DefaultValues && $scope.uiConfig.DefaultValues.EntityType) ? $scope.uiConfig.DefaultValues.EntityType : 'I',
                LicenseNumber: '',
                LicenseStatusTypeName: '',
                LicenseTypeName: '',
                LicenseExpirationDate: null,
                LicenseId: null,
                SecureLevel: null,
                FirstName: '',
                MiddleName: '',
                LastName: '',
                SSN: null,
                Gender: '',
                DateOfBirth: null,
                ProviderName: '',
                CellPhoneNumber: '',
                HomePhoneNumber: '',
                WorkPhoneNumber: '',
                Email: '',
                WorkEmail: '',

                StreetLine1: '',
                StreetLine2: '',
                City: '',
                StateCd: '',
                Zip: '',
                CountyId: null,
                CountryId: null,

                IsAnonymous: false,
                IsInternalOnly: false,
                IsForInvestigationOnly: false,
                IsForLegalOnly: false,
                IsForPublic: false,

                Notes: '',
                ReferenceNumber: '',

                IsActive: true,
                IsDeleted: false
            };
        };

        var refreshDocumentControl = function () {
            $scope.refreshingDocumentControl = true;
            $timeout(function () {
                $scope.refreshingDocumentControl = false;
            }, 1);
        };

        var getUIConfig = function () {
            var config = {};

            for (var prop in $scope.config.UIConfigs['Default']) {
                if (!$scope.config.UIConfigs['Default'].hasOwnProperty(prop)) { continue; }

                config[prop] = $scope.config.UIConfigs['Default'][prop];
            }

            if (!!$scope.config.UIConfigs[$scope.configName]) {
                for (var prop in $scope.config.UIConfigs[$scope.configName]) {
                    if (!$scope.config.UIConfigs[$scope.configName].hasOwnProperty(prop)) { continue; }
    
                    config[prop] = $scope.config.UIConfigs[$scope.configName][prop];
                }
            }

            return config;
        };

        var getConfig = function () {
            var deferred = $q.defer();
        
            try {
                utilityService.getConfigData('/components/backoffice/enforcement/complaint-involved-party/complaint-involved-party.server-config.json')
                .then(function(data) {
                    if (!!data) {
                        $scope.config = data;
        
                        $scope.uiConfig = $rootScope.getUIConfig($scope.configName, $scope.config);
        
                        $scope.tableHeaders = $scope.uiConfig.TableHeaders;

                        $scope.isConfigLoaded = true;
                    }
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
        
            return deferred.promise;
        };

        $scope.searchClicked = function () {
            $scope.hideStatusMsg();
            
            if (!$scope.current.party.searchName && !$scope.current.party.LicenseNumber) {
                $scope.showStatusMsg('-', 'You must provide a name or license number to perform a search.');
                return;
            }

            if ($scope.current.party.EntityType == 'I') {
                $scope.searchIndividuals();
            } else if ($scope.current.party.EntityType == 'P') {
                $scope.searchProviders();
            }
        };

        $scope.searchIndividuals = function () {
            var deferred = $q.defer();
            
            try {
                $scope.operationPending(true, 'Searching...');

                var searchParams = { 
                    Name: $scope.current.party.searchName,
                    LicenseNumber: $scope.current.party.LicenseNumber,
                    SortColumn: 'LastName',
                    SortOrder: 'asc',
                    IsFuzzySearch: true,
                    IsPublicSearch: true
                };
                ComplaintInvolvedPartyService.getIndividuals(sessionStorage.Key, searchParams, $scope.searchPager.currentPage, $scope.searchPager.pageSize)
                .then(function(data) {
                    if (data.Status) {
                        $scope.searchResults = data.IndividualList;
                        $scope.searchPager.setTotalRecords(data.Total_Recard);
                    } else {
                        $scope.searchResults = [];
                    }
                    $scope.operationPending(false);
                    deferred.resolve(data);
                }, function(err) {
                    $scope.operationPending(false);
                    deferred.reject(err);
                });
            } catch (ex) {
                $scope.operationPending(false);
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.searchProviders = function () {
            var deferred = $q.defer();
            
            try {
                $scope.operationPending(true, 'Searching...');

                var searchParams = { 
                    Name: $scope.current.party.searchName,
                    LicenseNumber: $scope.current.party.LicenseNumber,
                    SortColumn: 'ProviderName',
                    SortOrder: 'asc',
                    IsFuzzySearch: true,
                    IsPublicSearch: true
                };
                ComplaintInvolvedPartyService.getProviders(sessionStorage.Key, searchParams, $scope.searchPager.currentPage, $scope.searchPager.pageSize)
                .then(function(data) {
                    if (data.Status) {
                        $scope.searchResults = data.ProviderList;
                        $scope.searchPager.setTotalRecords(data.Total_Record);
                    } else {
                        $scope.searchResults = [];
                    }
                    $scope.operationPending(false);
                    deferred.resolve(data);
                }, function(err) {
                    $scope.operationPending(false);
                    deferred.reject(err);
                });
            } catch (ex) {
                $scope.operationPending(false);
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.getPublicAddress = function (ent) {
            var deferred = $q.defer();
            
            try {
                ComplaintInvolvedPartyService.getPublicAddress(sessionStorage.Key, ent.IndividualId)
                .then(function(data) {
                    if (data.Status) {
                        deferred.resolve(data);
                    } else {
                        $scope.showStatusMsg('-', data);
                        deferred.reject(data);
                    }
                }, function(err) {
                    $scope.showStatusMsg('-', err);
                    deferred.reject(err);
                });
            } catch (ex) {
                $scope.showStatusMsg('-', ex);
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.getContacts = function (ent) {
            var deferred = $q.defer();
            
            try {
                ComplaintInvolvedPartyService.getContacts(sessionStorage.Key, ent.IndividualId)
                .then(function(data) {
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.selectEntity = function (ent) {
            $scope.hideStatusMsg();

            var cp = $scope.current.party;

            cp.EntityId = (cp.EntityType=='I') ? ent.IndividualId : ent.ProviderId;
            cp.FirstName = ent.FirstName;
            cp.LastName = ent.LastName;
            cp.MiddleName = ent.MiddleName;
            cp.DateOfBirth = ent.DateOfBirth;
            cp.ProviderName = ent.ProviderName;
            cp.LicenseTypeName = ent.LicenseTypeName;
            cp.LicenseNumber = ent.LicenseNumber;
            cp.LicenseStatusTypeName = ent.LicenseStatusTypeName;
            cp.LicenseExpirationDate = ent.LicenseExpirationDate;

            // if (!cp.CellPhoneNumber) {
            //     cp.CellPhoneNumber = ent.Phone;
            // }
            // if (!cp.Email) {
            //     cp.Email = ent.Email;
            // }

            // $scope.documentConfiguration.IndividualId = (!!cp.EntityId) ? cp.EntityId : 0;
            // refreshDocumentControl();

            if (!!$scope.uiConfig.GetAddressAfterSelected)
            {
                $scope.getPublicAddress(ent)
                .then(function (data) {
                    var pubAddr = data.IndividualAddressResponse.whereEquals(1, 'AdressStatusId').firstOrDefault();
                    if (!!pubAddr) {
                        cp.StreetLine1 = pubAddr.StreetLine1;
                        cp.StreetLine2 = pubAddr.StreetLine2;
                        cp.City = pubAddr.City;
                        cp.StateCd = pubAddr.StateCode;
                        cp.Zip = pubAddr.Zip;
                    }
                });
            }
            if (!!$scope.uiConfig.GetContactsAfterSelected && !!$scope.isBackoffice) {
                $scope.getContacts(ent)
                .then(function (data) {
                    var currentContacts = data.IndividualContactResponse.whereEquals(1, 'ContactStatusId');
                    var currentPhones = currentContacts.whereIncludes($scope.uiConfig.ContactsConfig.PhoneTypeIds, 'ContactTypeId');
                    var currentEmails = currentContacts.whereIncludes($scope.uiConfig.ContactsConfig.EmailTypeIds, 'ContactTypeId');
                    var phoneUsed = null;
                    var emailUsed = null;
                    if (!!$scope.uiConfig.ContactsConfig.UsePreferredFirst) {
                        phoneUsed = currentPhones.whereEquals(true, 'IsPreferredContact').firstOrDefault();
                        emailUsed = currentEmails.whereEquals(true, 'IsPreferredContact').firstOrDefault();
                    }

                    if (phoneUsed == null) {
                        phoneUsed = currentPhones.whereEquals($scope.uiConfig.ContactsConfig.DefaultPhone, 'ContactTypeId').firstOrDefault();
                    }
                    if (emailUsed == null) {
                        emailUsed = currentEmails.whereEquals($scope.uiConfig.ContactsConfig.DefaultEmail, 'ContactTypeId').firstOrDefault();
                    }

                    cp.CellPhoneNumber = (!!phoneUsed) ? phoneUsed.ContactInfo : cp.CellPhoneNumber;
                    cp.Email = (!!emailUsed) ? emailUsed.ContactInfo : cp.Email;
                });
            }
        };

        $scope.getInvolvedParties = function () {
            var deferred = $q.defer();
            
            try {
                ComplaintInvolvedPartyService.getInvolvedParties(sessionStorage.Key, $scope.complaint.ComplaintId, $scope.complaintInvolvedPartyTypeId)
                .then(function(data) {
                    if (data.Status && !!data.ComplaintInvolvedPartyList) {
                        $scope.involvedParties = data.ComplaintInvolvedPartyList;
                        $scope.pager.setTotalRecords($scope.involvedParties.length);
                        deferred.resolve(data);
                    } else {
                        $scope.involvedParties = [];
                        $scope.showStatusMsg('-', data);
                        deferred.reject(data);
                    }
                }, function(err) {
                    $scope.showStatusMsg('-', err);
                    deferred.reject(err);
                });
            } catch (ex) {
                $scope.showStatusMsg('-', ex);
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.saveInvolvedParty = function (party) {
            var deferred = $q.defer();
            
            try {
                ComplaintInvolvedPartyService.saveInvolvedParties(sessionStorage.Key, [party])
                .then(function(data) {
                    if (data.Status) {
                        deferred.resolve(data);
                    } else {
                        $scope.showStatusMsg('-', data);
                        deferred.reject(data);
                    }
                }, function(err) {
                    $scope.showStatusMsg('-', err);
                    deferred.reject(err);
                });
            } catch (ex) {
                $scope.showStatusMsg('-', ex);
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };


        $scope.addEditInvolvedPartyClicked = function (party) {
            $scope.hideStatusMsg();

            if (!party) {
                party = getNewInvolvedParty();
            }

            refreshDocumentControl();

            $scope.current.party = angular.copy(party);
        };

        $scope.deleteInvolvedPartyClicked = function (party) {
            $scope.hideStatusMsg();

            if (confirm('Are you sure you want to delete this party?')) {
                party.IsActive = false;
                party.IsDeleted = true;

                $scope.operationPending(true, 'Deleting...');

                $scope.saveInvolvedParty(party)
                .then(function () {
                    $scope.getInvolvedParties()
                    .then(function () {
                        if (!!$scope.partySaved && !party.ComplaintInvolvedPartyId) {
                            var latestParty = $scope.involvedParties.firstOrDefault();
                            $scope.partySaved({$party: latestParty});
                        }

                        $scope.operationPending(false);
                    }, function () {
                        $scope.operationPending(false);
                    });
                }, function () {
                    $scope.operationPending(false);
                });
            }
        };

        $scope.saveInvolvedPartyClicked = function () {
            $scope.hideStatusMsg();

            if (!$scope.validateForm()) { return; }

            $scope.operationPending(true, 'Saving...');

            $scope.saveInvolvedParty($scope.current.party)
            .then(function () {
                if (!!$scope.partySaved && !!$scope.current.party.ComplaintInvolvedPartyId) {
                    $scope.partySaved({$party: $scope.current.party});
                }

                $scope.cancelInvolvedPartyClicked();

                $scope.getInvolvedParties()
                .then(function () {
                    if (!!$scope.partySaved) {
                        var latestParty = $scope.involvedParties.firstOrDefault();
                        $scope.partySaved({$party: latestParty});
                    }
                    $scope.operationPending(false);
                }, function () {
                    $scope.operationPending(false);
                });
            }, function () {
                $scope.operationPending(false);
            });
        };

        $scope.removeInvolvedPartyClicked = function (party, index) {
            $scope.hideStatusMsg();

            $scope.involvedParties.splice(index, 1);
        };

        $scope.addInvolvedPartyClicked = function () {
            $scope.hideStatusMsg();

            var party = $scope.current.party;

            if (!$scope.validateForm()) { return; }

            if (!!party.tempId) {
                $scope.involvedParties.splice(party.tempId-1, 1, party);
            } else {
                party.tempId = $scope.involvedParties.length + 1;
                $scope.involvedParties.push(party);
            }

            $scope.cancelInvolvedPartyClicked();
        };

        $scope.cancelInvolvedPartyClicked = function () {
            $scope.resetSearch();
            
            $scope.current.party = null;
            //$scope.documentConfiguration.IndividualId = 0;
            refreshDocumentControl();
        };

        $scope.resetSearch = function () {
            $scope.hideStatusMsg();

            $scope.searchResults = [];

            if (!!$scope.searchPager && !!$scope.searchPager.setTotalRecords) {
                $scope.searchPager.currentPage = 1;
                $scope.searchPager.setTotalRecords(0);
            }
        };

        $scope.toggleRowDetailsClicked = function (item, name) {
            if (item.rowDetails == name) {
                item.rowDetails = '';
            } else {
                item.rowDetails = name;
            }

            item.rowClass = {
                'row-expanded': !!item.rowDetails
            };
        };

        $scope.closeRowDetailsClicked = function (item) {
            item.rowDetails = '';
            item.rowClass = {
                'row-expanded': !!item.rowDetails
            };
        };

        $scope.searchPageChanged = function () {
            $scope.searchClicked();
        };

        $scope.init = function () {
            $scope.isPublicAccessible = true;
            $scope.uiLogic = {};
            $scope.current = {
                party: null
            };
            $scope.refTables = {};

            if (!$scope.maxOfType) {
                $scope.maxOfType = 0;
            }

            $scope.complaintPartyTypeName = '';
            $scope.refreshingDocumentControl = false;

            $scope.pager = {};
            $scope.involvedParties = [];
            $scope.tableHeaders = {};

            $scope.searchPager = {
                pageChanged: $scope.searchPageChanged
            };
            $scope.searchEvents = {
                selectEntity: $scope.selectEntity
            };
            $scope.searchResults = [];
            $scope.searchHeaders = {
                Name: {
                    label: 'Name'
                },
                Address: {
                    label: 'Address'
                },
                LicenseNumber: {
                    label: 'License Number'
                },
                Actions: {
                    label: 'Actions',
                    templateUrl: 'searchActions.html'
                }
            };

            if (!!$scope.initExt) {
                $scope.initExt();
            }

            getConfig();
            $timeout(function () {
                $scope.operationPending(true, 'Loading...');

                if (!$scope.externalSave) {
                    $scope.getInvolvedParties()
                    .then(function () {
                        $scope.operationPending(false);
                    }, function () {
                        $scope.operationPending(false);
                    });
                } else {
                    $scope.operationPending(false);
                }
            }, 1);
        };

        $scope.complaintTypesLoaded = function () {
            $scope.complaintPartyType = $scope.complaintPartyTypes.whereEquals($scope.complaintInvolvedPartyTypeId, 'ComplaintInvolvedPartyTypeId').firstOrDefault();
            if (!!$scope.complaintPartyType) {
                $scope.complaintPartyTypeName = $scope.complaintPartyType.ComplaintInvolvedPartyTypeName;
            }
        };

        $scope.$on('complaintClearForms', function () {
            $scope.cancelInvolvedPartyClicked();
            $scope.involvedParties = [];
        });

        $scope.$watch('involvedParties', function (newVal) {
            $scope.currentInvolvedParties = newVal;
        });
    }
})();
