(function () {
    'use strict'
    angular.module('app.core')
        .directive('inlumonEmail', inlumonEmail);

    inlumonEmail.$inject = [];

    function inlumonEmail() {
        return {
            restrict: 'E',
            scope: {
                individualId: '=individualId',
                applicationId: '=?applicationId',
                emailTemplateId: '=emailTemplateId',
                sendButtonText: "=?sendButtonText",
                cancelButtonText: "=?cancelButtonText",
                sendAutomattedEmail: "=?",
                tagsToReplace: "=?",
                onCancel: "&onCancel",
                onComplete: "&onComplete"
            },
            templateUrl: "app/core/directive/inlumon-email/inlumon-email.html",
            controller: "EmailController"
        }
    }
})();