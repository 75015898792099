(function () {
    angular.module('natApp').filter('inlumonTableColSpan', [function () {
        return function (tableHeaders) {
            var thCount = 0;
            for (var prop in tableHeaders) {
                if (!tableHeaders.hasOwnProperty(prop)) { continue; }

                thCount++;
            }
            return thCount;
        }
    }])
        .directive('inlumonTableCellTemplate', ['$parse', '$compile', function ($parse, $compile) {
            return {
                link: function ($scope, element, attrs) {
                    var template = $scope.$eval(attrs.inlumonTableCellTemplate);
                    var templateHtml = angular.element(template);

                    element.replaceWith(templateHtml);

                    $compile(templateHtml)($scope);
                }
            };
        }])
        .directive('watch', ['$parse', function ($parse) {
            return {
                link: function ($scope, element, attrs) {
                    $scope.$watch(attrs.watch, function (newVal, oldVal) {
                        $parse(attrs.onWatchTrigger)($scope, { $value: newVal });
                    });
                }
            };
        }])
        .directive('inlumonTable', ['$parse', '$timeout', function ($parse, $timeout) {
            return {
                restrict: 'E',
                replace: true,
                //controller: 'InlumonTableController',
                // scope: {
                //     tableHeaders: '=iltHeaders',
                //     tableData: '=iltData',

                //     tableStyle: '=?iltTableStyle',

                //     paginationParams: '=?iltPaginationParams',
                //     injectedScope: '=?iltScope',

                //     showColumnBorders: '=?iltColumnBorders',
                //     showRowBorders: '=?iltRowBorders',
                //     centerData: '=?iltCenterData',

                //     menuTemplate: '=?iltMenuTemplate',
                //     headerCellTemplate: '=?iltHeaderCellTemplate',
                //     dataCellTemplate: '=?iltDataCellTemplate',
                //     beforeRowTemplate: '=?iltBeforeRowTemplate',
                //     afterRowTemplate: '=?iltAfterRowTemplate',
                //     beforeTableTemplate: '=?iltBeforeTableTemplate',
                //     afterTableTemplate: '=?iltAfterTableTemplate',

                //     allowSorting: '=?iltAllowSorting',
                //     sortHeader:'=?iltSortHeader',
                //     sortDirection:'=?iltSortDirection',
                //     sortHeaderClicked:'=?iltHeaderClicked',

                //     isStatic: '=?iltIsStatic',

                //     $parentItem: '=?iltParentItem'
                // },
                //templateUrl: 'app/core/directive/inlumon-table/inlumon-table-template.html',
                link: function ($scope, element, attrs) {
                    var dataCellTemplate = (!!attrs.iltDataCellTemplate) ? $scope.$eval(attrs.iltDataCellTemplate) : 'app/core/directive/inlumon-table/default-data-cell.html';
                    var cellStyle = '';
                    var headerMap = [];
                    var tablePropertiesObj = '__inlumonTable_{0}'.format(element.tableId);

                    var inlumonTableProps = $scope.$eval('{0} = {}'.format(tablePropertiesObj));

                    var saveSortingState = false;

                    var generateOptionsForHeader = function (header, headerProperty) {
                        /**
                         * @type {TableHeaderObj}
                         */
                        var options = {
                            label: null,
                            templateUrl: null,
                            sizeRatio: 1,
                            cellStyle: {}
                        };

                        if (typeof (header) === 'string') {
                            options.label = header;
                        } else {
                            options.label = (!!header.label) ? header.label : headerProperty;
                        }
                        options.templateUrl = (!!header.templateUrl) ? header.templateUrl : dataCellTemplate;
                        options.sizeRatio = (!!header.sizeRatio) ? header.sizeRatio : 1;

                        return options;
                    };


                    var updateTableHeaders = function (tableHeaders) {
                        var index = 0;
                        var sizeRatioSum = 0;

                        for (headerProperty in tableHeaders) {
                            if (tableHeaders.hasOwnProperty(headerProperty)) {
                                var header = tableHeaders[headerProperty];
                                var headerOptions = generateOptionsForHeader(header, headerProperty);

                                if (typeof (tableHeaders[headerProperty]) === 'string') {
                                    tableHeaders[headerProperty] = headerOptions;
                                } else {
                                    tableHeaders[headerProperty].templateUrl = headerOptions.templateUrl;
                                    tableHeaders[headerProperty].sizeRatio = headerOptions.sizeRatio;
                                }

                                sizeRatioSum += headerOptions.sizeRatio;

                                //headerMap[index] = headerProperty;
                                //index++;
                            }
                        }

                        for (var prop in tableHeaders) {
                            if (tableHeaders.hasOwnProperty(prop)) {
                                var h = tableHeaders[prop];
                                h.cellStyle = {}; //angular.copy($scope.cellStyle);

                                h.cellStyle['width'] = ((100 / sizeRatioSum) * h.sizeRatio) + '%';
                            }
                        }
                    };

                    var updateSortBindings = function () {
                        if (!!attrs.iltSortHeader) {
                            var updateHeader = '{0} = "{1}"'.format(attrs.iltSortHeader, inlumonTableProps.sortHeader);
                            $parse(updateHeader)($scope);
                        }


                        if (!!attrs.iltSortDirection) {
                            var updateDirection = '{0} = "{1}"'.format(attrs.iltSortDirection, inlumonTableProps.sortDirection);
                            $parse(updateDirection)($scope);
                        }
                    };

                    if (!!attrs.iltHeaders) {
                        $scope.$watch(attrs.iltHeaders, function (newVal) {
                            updateTableHeaders(newVal);
                        }, true);
                    }

                    if (!!attrs.iltPaginationParams) {
                        var paginationParams = {};
                        //Keep pagination params updated
                        $scope.$watch(attrs.iltPaginationParams, function (newVal) {
                            paginationParams = newVal;
                            if (!paginationParams) { return; }

                            var tableData = $scope.$eval(attrs.iltData);

                            if (typeof (paginationParams.showPager) === 'undefined') {
                                paginationParams.showPager = false;
                            }
                            if (!paginationParams.pageSize) {
                                paginationParams.pageSize = 20;
                            }
                            if (!paginationParams.pageChanged) {
                                paginationParams.pageChanged = function () { };
                            }
                            if (!paginationParams.totalRecords) {
                                paginationParams.totalRecords = (!!tableData) ? tableData.length : 0;
                            }
                            if (!paginationParams.setTotalRecords) {
                                paginationParams.setTotalRecords = function (totalRecords) {
                                    $timeout(function () {
                                        paginationParams.totalRecords = totalRecords;
                                        paginationParams.ignoreDataLength = true;
                                    });
                                };
                            }

                            if (!paginationParams.currentPage) {
                                paginationParams.currentPage = 1;
                            }
                        });
                        //Watch the pertinent pagination param properties
                        $scope.$watch(function () { return (!!paginationParams) ? '' + paginationParams.currentPage + '' + paginationParams.totalRecords + '' + paginationParams.pageSize : '' }, function () {
                            if (!paginationParams) { return; }

                            var pp = paginationParams;
                            if (!pp.shownRecords) {
                                pp.shownRecords = {
                                    begin: 0,
                                    end: 0
                                };
                            }
                            var sr = pp.shownRecords;
                            sr.begin = (pp.currentPage - 1) * pp.pageSize + 1;
                            sr.end = pp.currentPage * pp.pageSize;
                            if (sr.end > pp.totalRecords) {
                                sr.end = pp.totalRecords;
                            }

                            if (pp.totalRecords > pp.pageSize) {
                                pp.showPager = true;
                            } else {
                                pp.showPager = false;
                            }
                        });
                    }

                    $scope.$watch(attrs.iltData, function () {
                        if (!saveSortingState) {
                            inlumonTableProps.sortHeader = null;
                            inlumonTableProps.sortDirection = null;
                        } else {
                            saveSortingState = false;
                        }
                    });

                    inlumonTableProps.headerClicked = function (params) {
                        if (inlumonTableProps.sortHeader != params.$headerProperty) {
                            inlumonTableProps.sortDirection = null;
                        }

                        if (!inlumonTableProps.sortDirection || inlumonTableProps.sortDirection == 'desc') {
                            inlumonTableProps.sortDirection = params.$sortDirection = 'asc';
                        } else if (inlumonTableProps.sortDirection == 'asc') {
                            inlumonTableProps.sortDirection = params.$sortDirection = 'desc';
                        }

                        inlumonTableProps.sortHeader = params.$headerProperty;
                        params.$sortDirection = inlumonTableProps.sortDirection;
                        $timeout(function () {
                            if (!!attrs.iltHeaderClicked) {
                                saveSortingState = true;
                                $parse(attrs.iltHeaderClicked)($scope, params);
                            } else {
                                inlumonTableProps.defaultSortMethod(params);
                            }
                        }, 1);
                    };

                    inlumonTableProps.defaultSortMethod = function (params) {
                        var dataLength = params.$tableData.length;

                        /** @type {any[]} */
                        var sortedArr = angular.copy(params.$tableData);
                        if (params.$sortDirection == 'asc') {
                            sortedArr = params.$tableData.orderBy(params.$headerProperty);
                        } else if (params.$sortDirection == 'desc') {
                            sortedArr = params.$tableData.orderByDescending(params.$headerProperty);
                        }

                        Array.prototype.splice.apply(params.$tableData, [0, dataLength].concat(sortedArr));
                    };
                },
                template: function (element, attrs) {
                    var tableId = Math.floor((Math.random() * 100) + 1);
                    var defaultHeaderCellTemplate = 'defaultHeaderCell{0}.html'.format(tableId);
                    var headerCellTemplate = (!!attrs.iltHeaderCellTemplate) ? attrs.iltHeaderCellTemplate : '\'' + defaultHeaderCellTemplate + '\'';
                    var sortHeaderProperty = (!!attrs.iltSortHeader) ? attrs.iltSortHeader : '__inlumonTable_{0}.sortHeader'.format(tableId);
                    var sortDirectionProperty = (!!attrs.iltSortDirection) ? attrs.iltSortDirection : '__inlumonTable_{0}.sortDirection'.format(tableId);
                    // var headerClicked = (!!attrs.iltHeaderClicked) ? 
                    //     attrs.iltHeaderClicked + '({$index: $index, $header: $header, $headerProperty: {2}, $tableData: {0}, $sortDirection: {1}})'.format(attrs.iltData, sortDirectionProperty, sortHeaderProperty) 
                    //     : 
                    //     '__inlumonTable_{3}.headerClicked({$index: $index, $header: $header, $headerProperty: {2}, $tableData: {0}, $sortDirection: {1}})'.format(attrs.iltData, sortDirectionProperty, sortHeaderProperty, tableId);
                    var headerClicked = '__inlumonTable_{2}.headerClicked({$index: $index, $header: $header, $headerProperty: $headerProperty, $tableData: {0}})'.format(attrs.iltData, sortDirectionProperty, tableId);

                    element.tableId = tableId;

                    var templ = ' \
                    <div class="inlumon-table"> \
                        <script id="{12}" type="text/ng-template"> \n \
                            <span ng-show="!{10}">{{$header.label}}</span> \n \
                            <a ng-show="{10}" ng-click="{13}">{{$header.label}} \n \
                                <div style="display: inline-block;" ng-show="$headerProperty == {15}"> \n \
                                    <span ng-show="{14} == \'asc\'" class="glyphicon glyphicon-chevron-up"></span> \n \
                                    <span ng-show="{14} == \'desc\'" class="glyphicon glyphicon-chevron-down"></span> \n \
                                </div> \n \
                            </a> \n \
                        </script> \
                        <div class="row"> \
                            <div class="col-md-12 hiddenpdf hideInPdf"> \
                                <div class="inlumon-table-menu"> \
                                    {1} \
                                    \
                                    <div class="clearfix"></div> \
                                </div> \
                            </div> \
                        </div> \
                        <div class="clearfix"></div> \
                        <div class="table-responsive"> \
                            <table class="table table-bordered"> \
                                <thead> \
                                    <tr class="inlumon-table-header-row"> \
                                        <td class="inlumon-table-header-cell" ng-repeat="($headerProperty, $header) in {2}" ng-style="$header.cellStyle"> \
                                            <div class="inlumon-table-header-cell-content" ng-include="{3}"></div> \
                                        </td> \
                                    </tr> \
                                </thead> \
                                <tbody> \
                                    <tr ng-repeat-start="$item in {4}" ng-show="{17}">  \
                                        <td colspan="{{{2} | inlumonTableColSpan}}" class="inlumon-table-before-row"><div ng-include="{5}"></div></td> \
                                    </tr> \
                                    <tr ng-init="$item.index = $index" ng-style="$item.rowStyle" ng-class="$item.rowClass"> \
                                        <td class="inlumon-table-data-cell" title="{{$item[$headerProperty]}}" ng-repeat="($headerProperty, $header) in {2}" ng-style="$header.cellStyle"> \
                                            <div class="inlumon-table-data-cell-content" ng-if="!!$header.template"><span inlumon-table-cell-template="$header.template"></span></div> \
                                            <div class="inlumon-table-data-cell-content" ng-if="!$header.template" ng-include="$header.templateUrl" watch="$item[$headerProperty]" on-watch-trigger="$data=$value"></div> \
                                        </td> \
                                    </tr> \
                                    <tr ng-repeat-end ng-show="{18}"> \
                                        <td colspan="{{{2} | inlumonTableColSpan}}" class="inlumon-table-after-row"><div ng-include="{6}"></div></td> \
                                    </tr> \
                                </tbody> \
                            </table> \
                            <div ng-if="!!{7}" ng-show="{7}.showPager" class="inlumon-table-pager"> \
                                <ul uib-pagination total-items="{7}.totalRecords" ng-model="{7}.currentPage" ng-change="{7}.pageChanged({7}.currentPage)"  \
                                    items-per-page="{7}.pageSize" force-ellipses="true" rotate="true" max-size="5" boundary-links="true"></ul> \
                            </div> \
                        </div> \
                    </div> \
                '.format(
                        (!!attrs.iltBeforeTableTemplate) ? '<div ng-show="!!{0}" ng-include="{0}"></div>'.format(attrs.iltBeforeTableTemplate) : '', //0
                        (!!attrs.iltMenuTemplate) ? '<div ng-include="{0}"></div>'.format(attrs.iltMenuTemplate) : '', //1
                        attrs.iltHeaders, //2
                        headerCellTemplate, //3
                        (!!attrs.iltPaginationParams) ? '{0} | iltPage : {1}'.format(attrs.iltData, attrs.iltPaginationParams) : attrs.iltData, //4
                        (!!attrs.iltBeforeRowTemplate) ? attrs.iltBeforeRowTemplate : '', //5
                        (!!attrs.iltAfterRowTemplate) ? attrs.iltAfterRowTemplate : '', //6
                        attrs.iltPaginationParams, //7
                        (!!attrs.iltAfterTableTemplate) ? '<div ng-show="!!{0}" ng-include="{0}"></div>'.format(attrs.iltAfterTableTemplate) : '', //8
                        (!!attrs.iltTableStyle) ? attrs.iltTableStyle : '', //9
                        (!!attrs.iltAllowSorting) ? attrs.iltAllowSorting : 'false', //10
                        (!!attrs.iltIsStatic) ? attrs.iltIsStatic : 'true', //11
                        defaultHeaderCellTemplate, //12
                        headerClicked, //13
                        sortDirectionProperty, //14
                        sortHeaderProperty, //15
                        tableId, //16
                        (!!attrs.iltBeforeRowTemplate) ? 'true' : 'false', //17
                        (!!attrs.iltAfterRowTemplate) ? 'true' : 'false', //18
                    );
                    // var templ = ' \
                    //     <div> \n \
                    //         <script id="{12}" type="text/ng-template"> \n \
                    //             <span ng-show="!{10}">{{$header.label}}</span> \n \
                    //             <a ng-show="{10}" ng-click="{13}">{{$header.label}} \n \
                    //                 <div style="display: inline-block;" ng-show="$headerProperty == {15}"> \n \
                    //                     <span ng-show="{14} == \'asc\'" class="glyphicon glyphicon-chevron-up"></span> \n \
                    //                     <span ng-show="{14} == \'desc\'" class="glyphicon glyphicon-chevron-down"></span> \n \
                    //                 </div> \n \
                    //             </a> \n \
                    //         </script> \n \
                    //         \n \
                    //         <!-- Before Table Template --> \n \
                    //         {0} \n \
                    //         \n \
                    //         <!-- Table --> \n \
                    //         <div class="inlumon-table"> \n \
                    //             <!-- Table Menu --> \n \
                    //             <div class="inlumon-table-menu"> \n \
                    //                 {1} \n \
                    //             </div> \n \
                    //             <div class="clearfix"></div> \n \
                    //             \n \
                    //             <div class="inlumon-table-scroller"> \n \
                    //                 <div ng-style="{9}"> \n \
                    //                     <!-- Table Header Row --> \n \
                    //                     <div class="inlumon-table-header-row"> \n \
                    //                         <div class="inlumon-table-header-cell" ng-repeat="($headerProperty, $header) in {2}" ng-style="$header.cellStyle"> \n \
                    //                             <div class="inlumon-table-header-cell-content" ng-include="{3}"></div> \n \
                    //                         </div> \n \
                    //                     </div> \n \
                    //                     \n \
                    //                     <!-- Table Data Rows --> \n \
                    //                     <div ng-repeat="$item in {4}" class="inlumon-table-data-row" ng-style="rowStyle" ng-class="$item.rowClass"> \n \
                    //                         {5} \n \
                    //                         <div ng-init="$item.index = $index;"></div> \n \
                    //                         <div class="inlumon-table-data-cell" ng-repeat="($headerProperty, $header) in {2}" ng-style="$header.cellStyle"> \n \
                    //                             <div ng-if="!!{11}" class="inlumon-table-data-cell-content" ng-include="$header.templateUrl" onload="$data=$item[$headerProperty]"></div> \n \
                    //                             <div ng-if="!{11}" class="inlumon-table-data-cell-content" ng-include="$header.templateUrl" inlumon-table-item-data></div> \n \
                    //                         </div> \n \
                    //                         {6} \n \
                    //                     </div> \n \
                    //                 </div> \n \
                    //             </div> \n \
                    //             \n \
                    //             <!-- Table Pagination --> \n \
                    //             <div ng-if="!!{7}" ng-show="{7}.showPager" class="inlumon-table-pager"> \n \
                    //                 <ul uib-pagination total-items="{7}.totalRecords" ng-model="{7}.currentPage" ng-change="{7}.pageChanged({7}.currentPage)"  \n \
                    //                     items-per-page="{7}.pageSize" force-ellipses="true" rotate="true" max-size="5" boundary-links="true" ></ul> \n \
                    //             </div> \n \
                    //         </div> \n \
                    //         \n \
                    //         <!-- After Table Template --> \n \
                    //         {8} \n \
                    //     </div> \
                    // '.format(
                    //         (!!attrs.iltBeforeTableTemplate) ? '<div ng-show="!!{0}" ng-include="{0}"></div>'.format(attrs.iltBeforeTableTemplate) : '',
                    //         (!!attrs.iltMenuTemplate) ? '<div ng-include="{0}"></div>'.format(attrs.iltMenuTemplate) : '',
                    //         attrs.iltHeaders, 
                    //         headerCellTemplate,
                    //         (!!attrs.iltPaginationParams) ? '{0} | iltPage : {1}'.format(attrs.iltData, attrs.iltPaginationParams) : attrs.iltData,
                    //         (!!attrs.iltBeforeRowTemplate) ? '<li ng-show="!!{0}" class="inlumon-table-before-row" ng-include="{0}"></li>'.format(attrs.iltBeforeRowTemplate) : '',
                    //         (!!attrs.iltAfterRowTemplate) ? '<li ng-show="!!{0}" class="inlumon-table-after-row" ng-include="{0}"></li>'.format(attrs.iltAfterRowTemplate) : '',
                    //         attrs.iltPaginationParams,
                    //         (!!attrs.iltAfterTableTemplate) ? '<div ng-show="!!{0}" ng-include="{0}"></div>'.format(attrs.iltAfterTableTemplate) : '',
                    //         (!!attrs.iltTableStyle) ? attrs.iltTableStyle : '', 
                    //         (!!attrs.iltAllowSorting) ? attrs.iltAllowSorting : 'false',
                    //         (!!attrs.iltIsStatic) ? attrs.iltIsStatic : 'true',
                    //         defaultHeaderCellTemplate,
                    //         headerClicked,
                    //         sortDirectionProperty,
                    //         sortHeaderProperty,
                    //         tableId
                    //         );

                    return templ;
                }
            };
        }]);

    angular.module('natApp').directive('inlumonTableItemData', [function () {
        return {
            restrict: 'A',
            link: function ($scope, element, attrs) {
                var item = $scope.$item;
                var headerProperty = $scope.$headerProperty;

                var clearWatch = $scope.$watch(function () { return item[headerProperty]; }, function (newVal, oldVal) {
                    $scope.$data = newVal;
                });

                $scope.$on('$destroy', function () {
                    clearWatch();
                });
            }
        };
    }]);
})();