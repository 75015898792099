(function () {
    'use strict'

    angular.module('app.backofficeAdministor')
    .directive('documentTemplatesLogic', [DocumentTemplatesLogic]);

    function DocumentTemplatesLogic() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {

            }
        };
    }
})();