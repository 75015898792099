(function() {
    'use strict'

    angular.module('app.core')
    .factory('PromiseHandlers', ['$q', '$timeout', function ($q, $timeout) {
        /**
         * Internal type that handles the creation of the sequence
         * @param {Function[]} fns 
         */
        function PromiseHandlerType(fns, type) {
            this.started = false;
            this.functionList = fns;
            this.successList = [];
            this.errorList = [];
    
            /**
             * The meta-function that calls the function at the given index in the sequence, with the given args, 
             * and handles the "then" of the promise returned.
             * 
             * This is the function that processes the sequence.
             * @param {number} index 
             * @param {any[]} args 
             */
            var _callFn = function (index, args) {
                if(index >= this.functionList.length) { return; }

                //Get the current function and then
                var fn = this.functionList[index];
                var success = this.successList[index];
                var error = this.errorList[index];
                var thisObj = this;

                //Call the function
                var val = fn.apply(null, args);

                //Determine which functions are provided to the success/error functions
                var providedFns = null;
                switch (type) {
                    case 'sequence':
                        providedFns = {
                            callNextFn: function() {
                                nextArgs = Array.prototype.slice.call(arguments);
        
                                //Call the next in the sequence 
                                _callFn.call(thisObj, index+1, nextArgs);    
                            },
                            callIndexFn: function (nextIndex, args) {
                                nextArgs = args;
        
                                //Call the next in the sequence
                                _callFn.call(thisObj, nextIndex, nextArgs);
                            }
                        }
                        break;
                    case 'poll':
                        providedFns = {
                            callAgain: function () {
                                nextArgs = Array.prototype.slice.call(arguments);

                                _callFn.call(thisObj, 0, nextArgs);
                            }
                        }
                        break;
                }

                //Check that the function returned a promise
                var nextArgs = null;
                if(!!val && val.then) {
                    //Call the then for this promise
                    val.then(function () {
                        var currentResolveArgs = Array.prototype.slice.call(arguments);
                        try {
                            if (!!success) {
                                //Call the then associated with this function call
                                success.apply(providedFns, currentResolveArgs);
                            } else {
                                throw 'Invalid Argument: sequence broken, call to null function.'
                            }
                        } catch (ex) {
                            if(!!error) {
                                //If the success throw an exception, call the error in the sequence in addition
                                error.apply(providedFns, [ex]);
                            } else {
                                //End the sequence
                                throw ex;
                            }                            
                        }
                    }, function () {
                        var currentResolveArgs = Array.prototype.slice.call(arguments);

                        try {
                            if(!!error) {
                                error.apply(providedFns, currentResolveArgs);
                            } else {
                                //End the sequence
                                throw currentResolveArgs;
                            }
                        } catch (ex) {
                            //End the sequence
                            throw ex;
                        }
                    });
                } else {
                    throw 'Invalid Argument: function {0} does not return a promise.'.format(fn.name);
                }
            };

            /**
             * Starts the sequence from the function at index 0.
             * @param {...*} arguments
             */
            this.start = function () {
                var firstArgs = Array.prototype.slice.call(arguments);

                if(!this.started) {
                    this.started = true;
                    var thisObj = this;

                    //Use the $timeout service to allow any success and error functions to execute before calling the first
                    //function in the sequence
                    $timeout(function() {
                        _callFn.call(thisObj, 0, firstArgs);
                    }, 1);
                } else {
                    throw 'Invalid Call: cannot call start function more than once.';
                }
                
                return this;
            };
    
            /**
             * Generates the list of .success functions for the sequence.
             * @param {...*} arguments The functions to call when the corresponding sequence function succeeds (promise.resolve).
             */
            this.success = function () {
                if(this.successList.length > 0) {
                    throw 'Invalid Call: cannot set success functions more than once.';
                }

                this.successList = Array.prototype.slice.call(arguments);
                if (!this.successList.every(function(f) { return typeof(f) === 'function' || f == null; })) {
                    throw 'Invalid Agument: success list must all be defined functions or null.';
                }
    
                return this;
            };
    
            /**
             * Generates the list of .error functions for the sequence.
             * @param {...*} arguments The functions to call when the corresponding sequence function (or .success function) fails (promise.reject, or exception).
             */
            this.error = function () {
                if(this.errorList.length > 0) {
                    throw 'Invalid Call: cannot set error functions more than once.';
                }

                this.errorList = Array.prototype.slice.call(arguments);
                if (!this.errorList.every(function(f) { return typeof(f) === 'function' || f == null })) {
                    throw 'Invalid Agument: error list must all be defined functions or null.';
                }
    
                return this;
            };
        }

        /**
         * The method that creates a new sequence based on the functions passed in.
         * @param {Function[]} fns The list of sequence functions.  This can be an array of functions, or functions passed in as arguments
         */
        var _sequence = function () {
            var fns = Array.prototype.slice.call(arguments);

            if (!Array.isArray(fns) || fns.length == 0) {
                throw 'Invalid Argument: function list must be non-empty.';
            }
            //If the first parameter is an array, then use that parameter as the function list
            if (Array.isArray(fns[0])) {
                fns = fns[0];
            }
            if (!fns.every(function(f) { return typeof(f) === 'function'; })) {
                throw 'Invalid Argument: function list must be an array of functions.';
            }

            return new PromiseHandlerType(fns, 'sequence');
        };

        var _poll = function () {
            var fns = Array.prototype.slice.call(arguments);

            if (!Array.isArray(fns) || fns.length == 0) {
                throw 'Invalid Argument: function list must be non-empty.';
            }
            //If the first parameter is an array, then use that parameter as the function list
            if (Array.isArray(fns[0])) {
                fns = fns[0];
            }
            if (!fns.every(function(f) { return typeof(f) === 'function'; })) {
                throw 'Invalid Argument: function list must be an array of functions.';
            }

            if (fns.length > 1) {
                throw 'Invalid Argument: there can only be 1 function polled.';
            }

            return new PromiseHandlerType(fns, 'poll');
        };

        return {
            sequence: _sequence,
            poll: _poll
        };
    }]);

    //Example code
    // angular.module('app.core').run(['$q', 'PromiseHandlers', 
    //     function runSequence($q, PromiseHandlers) {
    //         //BEGIN PROXY SERVICE CALL FUNCTIONS
    //         //Each of these return a promise, like all the api service calls should
    //         var test1 = function (key, test) { 
    //             var deferred = $q.defer();

    //             console.group('test1');

    //             console.log('test1');
    //             deferred.resolve({
    //                 myKey: 'My' + key,
    //                 myTest: 'My' + test
    //             });

    //             return deferred.promise;
    //         };

    //         var test2 = function (a1, a2, a3) {
    //             var deferred = $q.defer();

    //             console.group('test2');

    //             console.log('test2');
    //             deferred.resolve({
    //                 arg1: a1, 
    //                 arg2: a2, 
    //                 arg3: a3
    //             });

    //             return deferred.promise;
    //         };

    //         var test3 = function (key, testArg) {
    //             var deferred = $q.defer();

    //             console.group('test3');

    //             console.log('test3');
    //             deferred.resolve({
    //                 Key: key,
    //                 Test: testArg,
    //                 NumberList: [1,2,3,4]
    //             });

    //             return deferred.promise;
    //         };
    //         //END PROXY SERVICE CALL FUNCTIONS
            
    //         //PromiseHandlers.sequence(Array of promise-returning functions, Array of arguments to pass into the first function call)

    //         //Calling "start" on the "sequence" function will begin the sequence calls by calling the first function (test1) with the
    //         //given arguments.

    //         //If a promise returned from one of the functions is rejected, or if there is an exception in the corresponding 
    //         //.success function, then the corresponding .error function will be called.

    //         //If a .success function is called that is null, the corresponding .error function will be called

    //         //If there is no corresponding .error function, an exception will be thrown to end the sequence
            
    //         //The sequence ends when a .success or .error function no longer call a next function.
    //         PromiseHandlers.sequence(test1, test2, test3)
    //         .success(
    //             function(data) {
    //                 //Test1 "success" handler
    //                 console.log(data);
    //                 console.error('Stack Trace');
    //                 console.groupEnd();
                    
    //                 //Call the next function in the sequence (test2), with the given arguments
    //                 this.callNextFn('a1Param', 'a2Param', 'a3Param');
    //             }, 
    //             function (data) {
    //                 //Test2 "success" handler
    //                 console.log(data);
    //                 console.error('Stack Trace');
    //                 console.groupEnd();

    //                 //Or you can call the function at the specified index of the sequence (test3, in this case) with the given arguments
    //                 this.callIndexFn(2, ['the key', 'testing argument']);
    //             },
    //             function (data) {
    //                 //Test3 "success" handler
    //                 console.log(data);
    //                 console.error('Stack Trace');
    //                 console.groupEnd();

    //                 //No more functions in the sequence are called, sequence ends.
    //             })
    //         .error(null, null, null)
    //         .start('first', 'arguments');
    //     }]);
})();