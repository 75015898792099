(function () {
    'use strict'

    angular.module('app.core')
        .factory('warningService', warningService)

    warningService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function warningService($http, $q, WebApiUrl, AppConfig) {

        return {
            getwarning: _getwarning,
            warningSave: _warningSave,
            warningExists: _warningExists,
            getInvoiceMaster: _getInvoiceMaster,
            getApplicationPaymentInvoiceStatus: _getApplicationPaymentInvoiceStatus,
            getApplicationPaymentInvoiceStatusProvider: _getApplicationPaymentInvoiceStatusProvider,
            getApplicationExamStatus: _getApplicationExamStatus,
            getBackgroundStatus: _getBackgroundStatus
        };

        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function _getwarning(Key, entityId, entityType) {
            return returnDefferedResult($http.get(WebApiUrl + 'Complaint/GetDiscipline/' + Key + '?EntityId=' + entityId + '&EntityTy=' + entityType));
        };

        function _warningSave(warning, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Complaint/SaveDiscipline/' + key + "?Source=" + AppConfig.Source, warning));
        };

        function _warningExists(Key, entityId, entityType, applicationTypeId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Complaint/GetDiscipline/' + Key + '?EntityId=' + entityId + '&EntityTy=' + entityType)
                .then(function (response) {
                    var warningExists = false;
                    if (response.data.Status && !!response.data.DisciplineList && response.data.DisciplineList.length > 0) {
                        angular.forEach(response.data.DisciplineList, function (warning) {
                            if (((!!warning.AppliesToInitialApp && applicationTypeId == 2) || (!!warning.AppliesToRenewal && applicationTypeId == 1)) && (warning.EndDate == null || (new Date(warning.EndDate)) > (new Date()))) {
                                warningExists = true;
                            }
                        });
                    }
                    response.data.warningExists = warningExists;
                    deferred.resolve(response.data);
                }, function (response) {
                    deferred.reject(response);
                });
            return deferred.promise;
        }

        function _getInvoiceMaster(key, entityId, applicationId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetInvoiceMaster/' + key + "?IndividualId=" + entityId + "&ApplicationId=" + applicationId));
        };

        function _getApplicationPaymentInvoiceStatus(key, reqParams) {
            return returnDefferedResult($http.get(WebApiUrl + 'EntityCashiering/GetApplicationPaymentInvoiceStatus/' + key, {
                params: reqParams
            }));
        };

        function _getApplicationPaymentInvoiceStatusProvider(key, reqParams) {
            return returnDefferedResult($http.get(WebApiUrl + 'EntityCashiering/GetApplicationPaymentInvoiceStatusProvider/' + key, {
                params: reqParams
            }));
        };

        function _getApplicationExamStatus(key, reqParams) {
            return returnDefferedResult($http.get(WebApiUrl + 'Exam/GetApplicationExamStatus/' + key, {
                params: reqParams
            }));
        };

        function _getBackgroundStatus(dOJFingerPrintStatus, dOJApprovalStatus, fbiFingerPrintStatus, fbiApprovalStatus) {
            var fingerprintStatus = "";
            if (((!!dOJFingerPrintStatus && (['001', '011', '021', '061'].indexOf(dOJFingerPrintStatus) != -1)) || dOJApprovalStatus == 1)
                && ((!!fbiFingerPrintStatus && (['035', '045', '063', '064', '084', '174'].indexOf(fbiFingerPrintStatus) != -1)) || fbiApprovalStatus == 1)) {
                fingerprintStatus = "MatchedPass";
            } else if ((dOJApprovalStatus != 1 && !!dOJFingerPrintStatus && ['002', '012', '022', '038', '039', '062', '062', '067', '068'].indexOf(dOJFingerPrintStatus) != -1)
                || (fbiApprovalStatus != 1 && !!fbiFingerPrintStatus && ['032', '042', '065', '066', '096', '097'].indexOf(fbiFingerPrintStatus) != -1)) {
                fingerprintStatus = "CriminalHistory";
            } else if ((dOJApprovalStatus != 1 && !!dOJFingerPrintStatus && ['004', '024', '224', '225', '005', '025'].indexOf(dOJFingerPrintStatus) != -1)
                || (fbiApprovalStatus != 1 && !!fbiFingerPrintStatus && ['016', '017', '036', '046', '310', '311', '037', '047'].indexOf(fbiFingerPrintStatus) != -1)) {
                fingerprintStatus = "Rejects";
            }
            else if (
                ((!dOJFingerPrintStatus || ['102', '103', '104', '105', '106', '107', '108', '109'].indexOf(dOJFingerPrintStatus) != -1
                    || ['001', '011', '021', '061', '002', '012', '022', '038', '039', '062', '062', '067', '068', '004', '024', '224', '225', '005', '025'].indexOf(dOJFingerPrintStatus) == -1
                ) && dOJApprovalStatus != 1)
                || ((!fbiFingerPrintStatus || ['098', '099', '100', '101'].indexOf(fbiFingerPrintStatus) != -1
                    || ['035', '045', '063', '064', '084', '174', '032', '042', '065', '066', '096', '097', '016', '017', '036', '046', '310', '311', '037', '047'].indexOf(fbiFingerPrintStatus) == -1) && fbiApprovalStatus != 1)

            ) {
                fingerprintStatus = "Unmatched";
            }
            return fingerprintStatus;
        }
    }
})();