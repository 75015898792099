(function() {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('employmentHistoryValidation', ['Messages', EmploymentHistoryValidation]);

    function EmploymentHistoryValidation(Messages) {
        return {
            restrict: 'EA',
            link: function($scope, element, attrs) {
                $scope.$on('$destroy', function () {
                    $scope.natValidation.showError = false;
                    $scope.natValidation.errorMessages = [];
                });
                
                $scope.validateForm = function () {
                    $scope.natValidation.showError = false;
                    $scope.natValidation.errorMessages = [];
                    
                    return true;  
                };

                $scope.validateEmployment = function (except) {
                    var valid = true;

                    if (!except) {
                        except = [];
                    }

                    $scope.natValidation.showError = false;
                    $scope.natValidation.errorMessages = [];

                    for (var empProp in $scope.current.employment) {
                        var val = $scope.current.employment[empProp];
                        //If it is a configured value
                        if(!!$scope.config.FieldTableHeaders[empProp] && !except.includes(empProp)) {
                            var label = $scope.config.FieldTableHeaders[empProp].label;

                            if (false) {
                            // if (typeof(val) === 'undefined' || val == null ||
                            //     (typeof(val) != 'boolean' && typeof(val) != 'number' && !val)) {
                                $scope.natValidation.showError = true;
                                $scope.natValidation.errorMessages.push({
                                    propName: empProp,
                                    errorMessage: Messages.General.Errors.IsRequired(label)
                                });
                                valid = false;
                            }
                        }
                    }

                    return valid;
                };
            }
        };
    }
})();