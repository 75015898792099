﻿(function () {
    'use strict';
    /**
     *  
     */
    angular.module('profile.manager')
    .controller('addUserProfileDirectiveController', ['$scope', '$state', 'typeValuesService', 'TypeValue', 'profileManagemetFactory','Messages',
        function ($scope, $state, typeValuesService, TypeValue, profileManagemetFactory, Messages) {
            $scope.natValidation = {};
            $scope.search = {};
            var _key = sessionStorage.Key;
            var _userId = sessionStorage.UserID;
            $scope.init = function () {
                assignPager();
                if ($scope.profileId) {
                    getUserProfileList();
                };
            };
            
            var assignPager = function () {
                $scope.userSearchPager = {
                    fromRow: 0,
                    currentPage: 1,
                    itemsPerPage: 10,
                    totalItems: 0,
                    maxSize:6
                }
                $scope.userProfPager = {
                    fromRow: 0,
                    currentPage: 1,
                    itemsPerPage: 10,
                    totalItems: 0,
                    maxSize: 6
                }
            }

            $scope.searchUserClick = function (form) {
                if (validateForm(form)) {
                    $scope.displaySearchUser = true;
                    ShowLoader("Please Wait...");
                    //search User
                    try {
                        profileManagemetFactory.usersSearch(_key, $scope.userSearchPager.currentPage, $scope.userSearchPager.itemsPerPage, $scope.search).then(function (res) {
                            if (res.Status) {
                                $scope.userSearchList = res.Users;
                                $scope.userSearchPager.totalItems = res.Total_Recard;
                                assignUserProfileToSearchList();
                            } else {
                                showStatusMessage(res.Message, "error");
                            }
                            HideLoader();
                        }, function (err) {
                            HideLoader();
                            showStatusMessage(err.Message, "error");
                        });
                    }
                    catch (ex) {
                        HideLoader();
                        showStatusMessage(ex.message, "error");
                    }
                }
            };

            $scope.userSearchPageChanged = function () {
                $scope.searchUserClick();
            };

            $scope.clearSearchClick = function () {
                $scope.search = {};
                $scope.displaySearchUser = false;
                $scope.natValidation = {};
                if ($scope.frmUProfile) {
                    $scope.frmUProfile.$submitted = false;
                    $scope.frmUProfile.$setPristine();
                }
            };

            $scope.assignUserToProfile = function (user) {
                //insert userProfile    
                if (user.isSelected) {
                    var request = {
                        UserId: user.UserId,
                        UserTypeId: user.UserTypeId,
                        ProfileId: $scope.profileId,
                        IsGrantable: true,
                        IsActive: true,
                        IsDeleted: false,
                        CreatedBy: _userId,
                        ModifiedBy: _userId
                    };
                    try {
                        ShowLoader('Please Wait...');
                        profileManagemetFactory.saveUserProfiles(_key, { UserProfileList: [request] }).then(function (res) {
                            HideLoader();
                            if (res.Status) {
                                //load UserList
                                getUserProfileList();
                            } else {
                                showStatusMessage(res.Message, "error");
                            }
                        }, function (err) {
                            showStatusMessage(err.Message, "error");
                        });
                    }
                    catch (ex) {
                        HideLoader();
                        showStatusMessage(ex.message, "error");
                    }
                } else if (user.UserProfileId) {
                    //deleteUserProfile
                    try {
                        var deleteType = 'userprofile';
                        var profileId = '';
                        var userProfileId = user.UserProfileId;
                        ShowLoader("Please Wait...");
                        profileManagemetFactory.deleteProfileUserProfile(_key, deleteType, profileId, userProfileId).then(function (res) {
                            if (res.Status) {
                                getUserProfileList();
                            } else {
                                showStatusMessage(res.Message, "error");
                                HideLoader();
                            }
                        }, function (err) {
                            HideLoader();
                            showStatusMessage(err.Message, "error");
                        });

                    }
                    catch (ex) {
                        HideLoader();
                        showStatusMessage(ex.message, "error");
                    }
                }
            };

            var getUserProfileList = function () {
                ShowLoader("Please Wait...");
                //search User
                try {
                    var reqData = {
                        ProfileId: $scope.profileId,
                        FromRow: $scope.userProfPager.fromRow,
                        RecordPerPage: $scope.userProfPager.itemsPerPage,
                        Order: true,
                        OrderByField: 'FirstName'
                    };
                    profileManagemetFactory.getUserProfileList(_key, reqData).then(function (res) {
                        if (res.Status) {
                            $scope.userProfileList = res.UserProfileList;
                            $scope.userProfPager.totalItems = res.RecordCount;
                            assignUserProfileToSearchList();
                        } else {
                            showStatusMessage(res.Message, "error");
                        }
                        HideLoader();
                    }, function (err) {
                        HideLoader();
                        showStatusMessage(err.Message, "error");
                    });
                }
                catch (ex) {
                    HideLoader();
                    showStatusMessage(ex.message, "error");
                }
            };

            var assignUserProfileToSearchList = function () {
                try{
                    if ($scope.userProfileList && $scope.userProfileList.length > 0 && $scope.userSearchList && $scope.userSearchList.length > 0) {
                        $scope.userSearchList.map(function (i) {
                            var isUserPExists = getUserPIfAlreadyAssigned(i.UserId);
                            if (isUserPExists) {
                                i.isSelected = true;
                                i.UserProfileId = isUserPExists.UserProfileId;
                            } else {
                                i.isSelected = false;
                                i.UserProfileId = undefined;
                            }
                            return i;
                        });
                    }
                }catch(e){console.log(e)}
            };

            var getUserPIfAlreadyAssigned = function (uid) {
                if ($scope.userProfileList) {
                    var userP = $.grep($scope.userProfileList, function (i) {
                        return (i.UserId == uid);
                    });
                    if (userP && userP.length > 0) {
                        return userP[0];
                    }
                }
                return false;
            };

            $scope.userProfPageChanged = function () {
                $scope.userProfPager.fromRow = ($scope.userProfPager.currentPage * $scope.userProfPager.itemsPerPage) - $scope.userProfPager.itemsPerPage;
                getUserProfileList();
            };

            $scope.deleteUserProfileById = function (item) {
                if (item && confirm(Messages.General.Confirm.DeleteRecord)) {
                    try {
                        var deleteType = 'userprofile';
                        var profileId = '';
                        var userProfileId = item.UserProfileId;
                        ShowLoader("Please Wait...");
                        profileManagemetFactory.deleteProfileUserProfile(_key, deleteType, profileId, userProfileId).then(function (res) {
                            if (res.Status) {
                                showStatusMessage(Messages.General.Success.Saved, "success");
                                getUserProfileList();
                                //$scope.clearSearchClick();
                            } else {
                                showStatusMessage(res.Message, "error");
                                HideLoader();
                            }
                        }, function (err) {
                            HideLoader();
                            showStatusMessage(err.Message, "error");
                        });

                    }
                    catch (ex) {
                        HideLoader();
                        showStatusMessage(ex.message, "error");
                    }
                }
            };


            $scope.closeSection = function () {
                if (typeof $scope.onCloseUserProfile == 'function') {
                    $scope.onCloseUserProfile({ profile: $scope.profile });
                }
            };

            var validateForm = function (form) {
                $scope.natValidation.errorMessages = [];
                $scope.invaliddate = false;
                if (!!form) {
                    form.$submitted = true;
                    var veteranErrorMessages = angular.copy(form.errorMessages);
                    angular.forEach(veteranErrorMessages, function (errorMessage, index) {
                        $scope.natValidation.errorMessages.push(errorMessage);
                    });
                }

                if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                    $scope.natValidation.showError = true;
                    $scope.natValidation.isValid = false;
                    return false;
                } else {
                    $scope.natValidation.showError = false;
                    $scope.natValidation.isValid = true;
                    return true;
                }
            };

        }]);
})();