(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintOnlineSubmissionValidation', [ComplaintOnlineSubmissionValidation]);

    function ComplaintOnlineSubmissionValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.validateInvolvedParty = function (party) {
                    //Determine if we need to add the subject's employer
 
                    if ($scope.current.subject.EntityType == 'I') {
                        if (party == $scope.current.subjectWork && 
                            !!$scope.current.subjectWork.ProviderName && 
                            !!$scope.current.subjectWork.ProviderName.trim()) {
                            return true;
                        } else {
                            return true;
                        }
                    } else {
                        if (party != $scope.current.subjectWork) {
                            return true;
                        }
                    }

                    return false;
                };

                $scope.validateForm = function () {
                    var valid = true;
                    var errMsgs = [];

                    $scope.hideStatusMsg();

                    for (var field in $scope.form) {
                        if (!!$scope.form[field] && !!$scope.form[field].$validate) {
                            $scope.form[field].$validate();
                        }
                    }

                    if (!!$scope.form && $scope.form.errorMessages.length > 0) {
                        errMsgs = errMsgs.concat($scope.form.errorMessages);
                    }

                    if (errMsgs.length > 0) {
                        $scope.showStatusMsg('-', errMsgs);
                        valid = false;
                    }
                   
                    return valid;
                }
            },
        };
    }
})();