(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('documentationUploadValidation', [DocumentationUploadValidation]);

    function DocumentationUploadValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function () {
                    return true;
                }
            },
        };
    }
})();