(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('externalFormService', externalFormService)

    externalFormService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function externalFormService($http, $q, WebApiUrl, AppConfig) {

        return {
            getEntityExternalForm: _getEntityExternalForm,
            getEntityExternalFormAssignedItem: _getEntityExternalFormAssignedItem,
            saveEntityExternalForm: _saveEntityExternalForm,
            reSendEntityExternalForm: _reSendEntityExternalForm,
            submitEntityExternalForm: _submitEntityExternalForm,
            getIndividualExperience: _getIndividualExperience,
            getIndividualReference: _getIndividualReference,
            individualExternalBYIndividualId: _individualExternalBYIndividualId,
            saveEntityExternalFormAssignedItem: _saveEntityExternalFormAssignedItem,
            countyGetAll: _countyGetAll,
            saveIndividualExperience: _saveIndividualExperience,
            saveEntityReference: _saveEntityReference,
            acknowledgementSave: _acknowledgementSave,
            acknowledgementGet: _acknowledgementGet
        };

        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function _getEntityExternalForm(Key, entityId, entityType, externalFormUrlUniqueId, applicationId) {
            return returnDefferedResult($http.get(WebApiUrl + 'EntityExternalForm/GetEntityExternalForm'
                + '?Key=' + (!!Key ? Key : '')
                + ((entityId) ? ('&EntityId=' + entityId) : '')
                + ((entityType) ? ('&EntityType=' + entityType) : '')
                + ((externalFormUrlUniqueId) ? ('&ExternalFormUrlUniqueId=' + externalFormUrlUniqueId) : '')
                + ((applicationId) ? ('&ApplicationId=' + applicationId) : '')
            ));
        };

        function _getEntityExternalFormAssignedItem(Key, entityId, entityType, entityExternalFormId) {
            return returnDefferedResult($http.get(WebApiUrl + 'EntityExternalForm/GetEntityExternalFormAssignedItem'
                + '?Key=' + (!!Key ? Key : '')
                + ((entityId) ? ('?EntityId=' + entityId) : '')
                + ((entityType) ? ('&EntityType=' + entityType) : '')
                + ((entityExternalFormId) ? ('&EntityExternalFormId=' + entityExternalFormId) : '')
            ));
        };

        function _saveEntityExternalForm(entityExternalForms, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'EntityExternalForm/SaveEntityExternalForm/' + key + "?Source=" + AppConfig.Source, entityExternalForms));
        };

        function _reSendEntityExternalForm(entityExternalForms, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'EntityExternalForm/ReSendEntityExternalForm/' + key + "?Source=" + AppConfig.Source, entityExternalForms));
        };

        function _submitEntityExternalForm(entityExternalForms, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'EntityExternalForm/SubmitEntityExternalForm/' + key + "?Source=" + AppConfig.Source, entityExternalForms));
        };

        function _getIndividualExperience(Key, individualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualExperience/' + Key + '?IndividualId=' + individualId +
                '&Source=verificationform'));
        };

        function _getIndividualReference(Key, request) {
            return returnDefferedResult($http.get(WebApiUrl + 'EntityReference/GetEntityReference?Key=' + (!!Key ? Key : ''), { params: request }));
        };

        function _individualExternalBYIndividualId(Key, individualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualExternalBYIndividualId/' + Key + '?IndividualId=' + individualId +
                '&Source=verificationform'));
        };


        function _saveEntityExternalFormAssignedItem(entityExternalFormAssignedItems, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'EntityExternalForm/SaveEntityExternalFormAssignedItem/' + key + "?Source=" + AppConfig.Source, entityExternalFormAssignedItems));
        };

        function _countyGetAll(Key, stateCode) {
            return returnDefferedResult($http.get(WebApiUrl + 'County/CountyGetAll/'
                + Key + '?StateCode=' + stateCode));
        };

        function _saveIndividualExperience(individualExperience, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/SaveIndividualExperience?Key=Key' + ((!!key) ? key : "") +
                "&Source=verificationform", individualExperience));
        };

        function _saveEntityReference(entityReferences, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'EntityReference/SaveEntityReference?Key=' + ((!!key) ? key : ""), entityReferences));
        };

        function _acknowledgementSave(acknowledgementObj) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/SaveIndividualAffidavit/'+key+'?individualId=' + acknowledgementObj.IndividualId + "&applicationId=" + acknowledgementObj.ApplicationId + "&userId=" + 0 + "&Source=" + AppConfig.Source, [{
                ContentItems: [acknowledgementObj],
                Individualaffidavitsignature: {
                    SignatureName: acknowledgementObj.SignatureName,
                    Date: acknowledgementObj.Date
                }
            }]));
        };

        function _acknowledgementGet(individualId, key, contentLkToPageTabSectionIds, entityExternalFormId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualAffidavitByIndividualId/' + key + '?IndividualId=' + individualId
                + '&ContentLkToPageTabSectionIds=' + contentLkToPageTabSectionIds
                + '&EntityExternalFormId=' + entityExternalFormId +
                '&Source=verificationform'));
        };

    }
})();