(function () {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("IndividualNotesController", IndividualNotesController);

    IndividualNotesController.$inject = ["$rootScope", "$scope", "$state", "backofficeIndividualNotesService", "utilityService", "$exceptionHandler", "WebApiUrl", "typeValuesService", "profileManagemetFactory", "TypeValue", "$q", "Messages"];

    function IndividualNotesController($rootScope, $scope, $state, backofficeIndividualNotesService, utilityService, $exceptionHandler, WebApiUrl, typeValuesService, profileManagemetFactory, TypeValue, $q, Messages) {
        $scope.init = function () {
            $scope.individualDocumentUpload = {
                ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualCommentLogSave/',
                ApplicationId: null,
                IndividualId: $scope.individualId,
                Email: null,
                SendEmail: null,
                TransactionId: null,
                LicenseeReprint: false,
                DocumentLkToPageTabSectionId: 1,
                DocumentLkToPageTabSectionCode: "ASD",
                hideValidationMarks: true,
                isUploadRequired: false,
                isUploadValid: true,
                DocumentUploadedForId: (($rootScope.routeData && !!$rootScope.routeData.documentUploadedForId) ? $rootScope.routeData.documentUploadedForId : 1)
            };
            $scope.individualNote = {};
            loadInitialData($scope.individualId);
            $scope.$broadcast("initdocumentCreation");
            $scope.viewConfig = {};
            $rootScope.individualCurrentTab.isLoadedOnce = true;

            $scope.isBackoffice = (sessionStorage.isBackOfficeUser == 'true') || (sessionStorage.isBackOfficeUser == true);
        };

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "notes" && !$rootScope.individualCurrentTab.isLoadedOnce) {
                $scope.individualId = individualId;
                $scope.init();
            }
        });

        var processNotesData = function (individualCommentsWithdocs) {
            angular.forEach(individualCommentsWithdocs, function (individualComment) {
                angular.forEach($scope.notesTypeList, function (notesType) {
                    if (notesType.NotesTypeId == individualComment.NotesTypeId) {
                        individualComment.NotesTypeName = notesType.NotesTypeName;
                    }
                });
            });
            return individualCommentsWithdocs;
        };

        var loadInitialData = function (individualId) {
            var reqData = {
                UserId: sessionStorage.UserID,
                ProfileId: 0,
                FromRow: 0,
                RecordPerPage: 0,
                Order: true,
                OrderByField: 'FirstName'
            };
            var dataPromises = [backofficeIndividualNotesService.individualCommentLogGetByIndividualId(sessionStorage.Key, individualId),
                typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=notestype")),
                profileManagemetFactory.getUserProfileList(sessionStorage.Key, reqData, true)
            ];

            $q.all(dataPromises).then(function (response) {
                var errorMessage = '';
                angular.forEach(response, function (responseItem) {
                    if (!responseItem.Status) {
                        errorMessage = errorMessage + responseItem.Message;
                    }
                });
                if (!!errorMessage) {
                    $scope.showStatusMsg("-", errorMessage);
                    return;
                }
                $scope.notesTypeList = response[1].ReferenceEntities;
                $scope.notesList = processNotesData(response[0].IndividualCommentsWithdocs);
                $scope.userProfiles = response[2].userProfiles;
                $scope.openAddEditComment(false);
                $scope.loadingNotes(false);
            }, function (error) {
                $scope.showStatusMsg("-", error);
            });
        };

        $scope.openAddEditComment = function (isCommetEdit, individualNote) {
            $scope.isCommetEdit = isCommetEdit
            if (!!isCommetEdit) {
                $scope.individualNote = angular.copy(individualNote);
            } else {
                $scope.individualNote = {
                    IndividualId: $scope.individualId,
                    Type: 'C',
                    ReferenceNumber: "",
                    IsInternalOnly: true,
                    IsForInvestigationOnly: true,
                    IsActive: true,
                    NotesTypeId: $scope.individualNote.notesTypeId,
                    IsForPublic: false
                };
            }
            $scope.individualDocumentUpload.isUploadRequired = false;
            $scope.individualDocumentUpload.isUploadValid = true;
            $scope.viewConfig.showAddEditNotesBlock = true;
        };

        $scope.closeAddEditNotesBlock = function () {
            $scope.viewConfig.showAddEditNotesBlock = false;
        };

        var customValidation = function (notesForm) {
            var formData = {
                errorMessages: angular.copy(notesForm.errorMessages)
            };
            if (!$scope.individualDocumentUpload.isUploadValid) {
                formData.errorMessages.push({
                    propName: "fieldsReqForUpload",
                    errorMessage: Messages.General.Errors.PleaseEither
                });
            }
            if (!(!!formData.errorMessages && formData.errorMessages.length > 0)) {
                return true;
            } else {
                var errorMessages = [];
                angular.forEach(formData.errorMessages, function (obj) {
                    errorMessages.push(obj.errorMessage);
                })
                $scope.showStatusMsg('-', errorMessages);
                return false;
            }
        };

        // Save Notes
        $scope.saveNote = function (notesForm, individualNote, isDelete) {
            try {
                $scope.hideStatusMsg();
                if (!!isDelete) {
                    if (!confirm(Messages.General.Confirm.DeleteNote)) {
                        $scope.loadingNotes(false);
                        return;
                    }
                    individualNote.IsDeleted = true;
                }

                if (!!isDelete || customValidation(notesForm)) {
                    $scope.loadingNotes(true, "Please wait...");
                    backofficeIndividualNotesService.saveIndividualComment(individualNote, $scope.individualDocumentUpload, sessionStorage.Key, isDelete)
                        .then(function (response) {
                            if (response.StatusCode == "00") {
                                $scope.individualCommentLogGetByIndividualId($scope.individualId);
                                $scope.individualDocumentUpload.DocumentUploadList = [];
                                $scope.closeAddEditNotesBlock();
                                $scope.showStatusMsg("+", response.Message);
                            } else {
                                $scope.showStatusMsg("-", response.Message);
                            }
                            $scope.loadingNotes(false);
                        }, function (data) {
                            $scope.loadingNotes(false);
                            $scope.showStatusMsg("-", data.message);
                        });
                }
            } catch (ex) {
                $scope.loadingNotes(false);
                $scope.showStatusMsg("-", ex.message);
                $exceptionHandler(ex);
            }
        };

        $scope.individualCommentLogGetByIndividualId = function (individualId) {
            try {
                $scope.loadingNotes(true, "Please wait...");
                var dataPromise = backofficeIndividualNotesService.individualCommentLogGetByIndividualId(sessionStorage.Key, individualId);
                dataPromise.then(function (response) {
                    $scope.notesList = [];
                    if (response.Status) {
                        if (response.IndividualCommentsWithdocs != null) {
                            $scope.notesList = processNotesData(response.IndividualCommentsWithdocs);
                        }
                    }
                    $scope.loadingNotes(false);
                }, function (data) {
                    $scope.loadingNotes(false);
                    $scope.showStatusMsg("-", data);
                });
                return dataPromise;
            } catch (ex) {
                $scope.loadingNotes(false);
                if (ex != null) {
                    $scope.showStatusMsg("-", ex.message);
                }
                $exceptionHandler(ex);
            }
        };

        $scope.init();
    }
})();