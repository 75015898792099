(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("ExternalFormController", ExternalFormController);

    ExternalFormController.$inject = ["$rootScope", "$scope", "$uibModal", "$stateParams", "$q", "typeValuesService", "externalFormService", "htmlToPdfGenerationService", "TypeValue", "countryStateService", "Messages"];

    function ExternalFormController($rootScope, $scope, $uibModal, $stateParams, $q, typeValuesService, externalFormService, htmlToPdfGenerationService, TypeValue, countryStateService, Messages) {
        $scope.init = function () {
            $scope.natValidation = {
                errorMessages: []
            };
            $scope.datePicker = {
                fromDateOpened: false,
                toDateOpened: false,
                format: 'M!/d!/yyyy',
                formatGraduation: 'M!/d!/yyyy'
            };

            $scope.maskConfig = {
                clearOnBlur: false,
                allowInvalidValue: true,
                clearOnBlurPlaceholder: false
            };

            $scope.formDetails = {
                externalFormTypeId: $stateParams.externalFormTypeId,
                externalFormUrlUniqueId: $stateParams.externalFormUrlUniqueId
            };
            $scope.viewConfig = {};
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=entityexternalformtype")).then(function (response) {
                if (response.Status) {
                    var externalFormTypes = response.ReferenceEntities;
                    $scope.formDetails.externalFormType = externalFormTypes.whereEquals($scope.formDetails.externalFormTypeId, 'ExternalFormTypeId').firstOrDefault();
                    $scope.formType = $scope.formDetails.externalFormType.ExternalFormTypeCode;
                    if (!!$scope.formDetails.externalFormType) {
                        if (!!$scope.formDetails.externalFormType) {
                            loadExperienceData();
                        }
                    }
                }
            }, function (error) {

            });

        };

        var getNewIndividualReference = function () {
            var individualReference = {
                EntityId: $scope.externalForm.EntityId,
                IndividualId: $scope.externalForm.EntityId,
                IndividualLicenseId: $scope.experience.IndividualLicenseId,
                ApplicationId: $scope.externalForm.ApplicationId,
                EntityType: $scope.externalForm.EntityType,
                IsActive: true,
                IsDeleted: false,
                IndividualExperienceId: $scope.experience.IndividualExperienceId
            };
            return individualReference;
        }

        var loadExperienceData = function () {
            externalFormService.getEntityExternalForm(sessionStorage.Key, null, null, $scope.formDetails.externalFormUrlUniqueId).then(function (resposeExternalForm) {
                if (resposeExternalForm.Status) {
                    if (!!resposeExternalForm.EntityRecords && resposeExternalForm.EntityRecords.length > 0) {
                        $scope.externalForm = resposeExternalForm.EntityRecords.firstOrDefault();
                        var promises = {
                            individual: externalFormService.individualExternalBYIndividualId(sessionStorage.Key, $scope.externalForm.EntityId),
                            state: countryStateService.getStateByCountryID(sessionStorage.Key, $rootScope.DefaultCountryId),
                            experience: externalFormService.getIndividualExperience(sessionStorage.Key, $scope.externalForm.EntityId),
                            reference: externalFormService.getIndividualReference(sessionStorage.Key, { IndividualId: $scope.externalForm.EntityId }),
                            acknowledgement: externalFormService.acknowledgementGet($scope.externalForm.EntityId, sessionStorage.Key, $scope.formDetails.externalFormType.AffidavitContentItemLkPageTabSectionId, $scope.externalForm.EntityExternalFormId),
                            licenseapplicationfor: typeValuesService.getTypeValuesByTypeNameAndParamsPublic("RefTablesGetAll", true, ("?tableKey=licenseapplicationfor")),
                            referencerelationtype: typeValuesService.getTypeValuesByTypeNameAndParamsPublic("RefTablesGetAll", true, ("?tableKey=referencerelationtype")),
                            experiencearea: typeValuesService.getTypeValuesByTypeNameAndParamsPublic("RefTablesGetAll", true, ("?tableKey=experiencearea")),
                            licensetype: typeValuesService.getTypeValuesByTypeNameAndParamsPublic("RefTablesGetAll", true, ("?tableKey=licensetype")),
                            country: typeValuesService.getTypeValuesByTypeNameAndParamsPublic("RefTablesGetAll", true, ("?tableKey=country"))
                        };

                        $q.all(promises)
                            .then(function (response) {
                                var errorMessage = '';
                                angular.forEach(response, function (responseItem) {
                                    if (!responseItem.Status) {
                                        errorMessage = errorMessage + responseItem.Message;
                                    }
                                });
                                if (!!errorMessage) {
                                    $scope.showStatusMessage(errorMessage, "error");
                                    HideLoader();
                                    return;
                                }

                                if (!!response.individual.IndividualResponse && response.individual.IndividualResponse.length > 0) {
                                    $scope.individualData = response.individual.IndividualResponse[0];
                                }
                                $scope.stateList = response.state.State;
                                $scope.referenceResponseStateList = response.state.State;
                                $scope.referenceLicenseStateList = response.state.State;
                                $scope.referenceStateList = response.state.State;
                                if (!!response.experience.IndividualExperienceList) {
                                    var individualExperienceList = response.experience.IndividualExperienceList;
                                    $scope.externalForm.EntityExternalFormAssignedItems = $scope.externalForm.EntityExternalFormAssignedItems
                                        .leftJoinObjects(individualExperienceList, 'item1.EntityExternalFormAssignedItemLinkId==item2.IndividualExperienceId', 'this1, IndividualExperience: this2');
                                }
                                if (!!response.reference.EntityRecords) {
                                    var individualReferenceList = response.reference.EntityRecords;
                                    $scope.externalForm.EntityExternalFormAssignedItems = $scope.externalForm.EntityExternalFormAssignedItems
                                        .leftJoinObjects(individualReferenceList, 'item1.EntityExternalFormAssignedItemLinkId==item2.IndividualExperienceId', 'this1, IndividualReference: this2');
                                }
                                if (response.acknowledgement.IndividualAffidavitResponseList != null && response.acknowledgement.IndividualAffidavitResponseList.length > 0) {
                                    $scope.acknowledgement = response.acknowledgement.IndividualAffidavitResponseList.whereEquals(430, 'ContentItemLkId').firstOrDefault();
                                    $scope.processAffidavitValues();
                                    HideLoader();
                                }
                                $scope.initialLicenseApplicationSubTypes = response.licenseapplicationfor.ReferenceEntities;
                                if ($scope.initialLicenseApplicationSubTypes) {
                                    $scope.initialLicenseApplicationSubTypes = $scope.initialLicenseApplicationSubTypes.whereEquals(2, 'LicenseApplicationForTypeId');
                                }

                                $scope.refreferenceRelationTypes = response.referencerelationtype.ReferenceEntities;
                                $scope.refreferenceExperienceAreas = response.experiencearea.ReferenceEntities;
                                if (!!response.licensetype.ReferenceEntities && response.licensetype.ReferenceEntities.length > 0) {
                                    $scope.licenseTypes = response.licensetype.ReferenceEntities.whereNotEquals(1, 'InitialApplicationGrouping');
                                }
                                $scope.countries = response.country.ReferenceEntities;
                                $scope.countryList = response.country.ReferenceEntities;

                                $scope.experience = $scope.externalForm.EntityExternalFormAssignedItems[0].IndividualExperience;
                                $scope.individualReference = $scope.externalForm.EntityExternalFormAssignedItems[0].IndividualReference;
                                if (!$scope.individualReference) {
                                    $scope.individualReference = getNewIndividualReference();
                                }

                                $scope.openAddEditExperience(true, $scope.externalForm.EntityExternalFormAssignedItems[0])
                            }, function (error) {

                            })
                    }
                }
            }, function (error) {

            });
        };

        $scope.processAffidavitValues = function () {
            if (!!$scope.acknowledgement && !!$scope.acknowledgement.Date && !angular.isDate($scope.acknowledgement.Date))
                $scope.acknowledgement.Date = new Date($scope.acknowledgement.Date);
            else if (!$scope.acknowledgement) {
                $scope.acknowledgement = {
                    Date: new Date()
                }
            } else if (!$scope.acknowledgement.Date) {
                $scope.acknowledgement.Date = new Date();
            }
        };

        var loadSubmissionHtml = function () {
            var deferred = $q.defer();
            htmlToPdfGenerationService.processExternalFormHtml('#experiencFormPartB').then(function (response) {
                if ($scope.externalForm.EntityExternalFormAssignedItems && $scope.externalForm.EntityExternalFormAssignedItems.length > 0) {
                    $scope.externalForm.GenerateFormPdf = true;
                    $scope.externalForm.TemplateId = 152;
                    $scope.externalForm.SendEmail = true;
                    $scope.externalForm.AttachFormPdf = true;

                    angular.forEach($scope.externalForm.EntityExternalFormAssignedItems, function (entityExternalFormAssignedItem) {
                        entityExternalFormAssignedItem.EntityExternalFormPdfDetail = {
                            GenerateFormPdf: true,
                            LoadHtmlFromDb: false,
                            SaveHtmlToDb: true,
                            EntityId: $scope.externalForm.EntityId,
                            EntityType: 'I',
                            ApplicationId: $scope.externalForm.ApplicationId,
                            DataDesc: "Experience Form Part B",
                            TabName: "ExperienceFormB",
                            TableName: "individualexperience",
                            TableLinkId: $scope.experience.IndividualExperienceId,
                            TableLinkIdColumnName: "IndividualExperienceId",
                            TableLinkTypeId: 2,
                            HTMLContent: response,
                            SortingOrder: 1,
                            DocumentId: 108,
                            DocumentCd: "D1108"
                        };
                    });
                }
                deferred.resolve({ Status: true });
            }, function () {
                deferred.resolve({ Status: false });
            });

            return deferred.promise;
        };

        $scope.submitEntityExternalForm = function () {
            try {
                if (!validateForm(null, true)) {
                    HideLoader();
                    return;
                } else {
                    ShowLoader();
                    loadSubmissionHtml($scope.externalForm).then(function (response) {
                        if (!response.Status) {
                            HideLoader();
                            $scope.showStatusMessage("Some error occureed.", "error");
                            return;
                        }
                        if ($scope.externalForm.MarkDeficiencyResolved) {
                            $scope.externalForm.DeficiencyStatusId = 2;
                        }
                        $scope.externalForm.ExternalFormStatusId = 2;
                        externalFormService.submitEntityExternalForm([$scope.externalForm])
                            .then(function (response) {
                                HideLoader();
                            }, function (data) {
                                HideLoader();
                            });
                    }, function (data) {
                        HideLoader();
                    });
                }
            } catch (ex) {
                HideLoader();
            }
        };

        $scope.resolveDeficiency = function (currentForm) {
            $scope.externalForm.MarkDeficiencyResolved = true;
            $scope.acknowledgementSave(currentForm, false);
        };

        $scope.acknowledgementSave = function (currentForm, saveForLater) {
            try {
                if (!validateForm(currentForm)) {
                    return;
                } else {
                    ShowLoader();
                    if ($scope.acknowledgement != null) {
                        $scope.acknowledgement.IndividualId = $scope.externalForm.EntityId;
                        $scope.acknowledgement.ApplicationId = $scope.externalForm.ApplicationId;
                        var promises = {
                            acknowledgementSave: externalFormService.acknowledgementSave($scope.acknowledgement),
                            saveEntityReference: externalFormService.saveEntityReference([$scope.individualReference], sessionStorage.Key),
                        }
                        $q.all(promises)
                            .then(function (response) {
                                var errorMessage = '';
                                angular.forEach(response, function (responseItem) {
                                    if (!responseItem.Status) {
                                        errorMessage = errorMessage + responseItem.Message;
                                    }
                                });
                                if (!!errorMessage) {
                                    $scope.showStatusMessage(errorMessage, "error");
                                    HideLoader();
                                    return;
                                } else {
                                    if (!!saveForLater) {
                                        loadExperienceData();
                                    } else {
                                        $scope.submitEntityExternalForm();
                                    }
                                }
                            }, function (data) {
                                HideLoader();
                            });
                    }
                }
            } catch (ex) {
                HideLoader();
            }
        };

        $scope.updateEntityExternalFormAssignedItemStatus = function (experienceForm, entityExternalFormAssignedItem, entityExternalFormAssignedItemStatusId) {
            // $scope.loadingFormAssignedItem(true);
            try {
                entityExternalFormAssignedItem.isNotesRequired = (entityExternalFormAssignedItemStatusId == 3 && !entityExternalFormAssignedItem.EntityExternalFormAssignedItemNotes);
                if (!validateForm(experienceForm)) {
                    return;
                };
                ShowLoader("Please Wait...");
                externalFormService.saveIndividualExperience([entityExternalFormAssignedItem.IndividualExperience], sessionStorage.Key)
                    .then(function (response) {
                        if (response.Status && response.StatusCode == "00") {
                            entityExternalFormAssignedItem.EntityExternalFormAssignedItemStatusId = ((!!entityExternalFormAssignedItemStatusId) ? entityExternalFormAssignedItemStatusId : entityExternalFormAssignedItem.EntityExternalFormAssignedItemStatusId);
                            externalFormService.saveEntityExternalFormAssignedItem([entityExternalFormAssignedItem], sessionStorage.Key)
                                .then(function (response) {
                                    $scope.viewConfig.showAddExperienceBlockType = false;
                                    loadExperienceData();
                                    // $scope.loadingFormAssignedItem(false);
                                }, function (data) {
                                    // $scope.loadingFormAssignedItem(false);
                                    showStatusMessage(data, "error");
                                });
                        } else {
                            HideLoader();
                            ShowErrMsg("SaveIndividualExperience", response.Message);
                        }
                    });
            } catch (ex) {
                HideLoader();
                ShowErrMsg("SaveIndividualExperience", ex.message);
            }
        };

        //Teaching Experience Starts here
        var validateForm = function (currentForm, isExternalForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                if (!currentForm.errorMessages)
                    currentForm.errorMessages = [];
                $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            }
            // if (!!isExternalForm) {
            //     if (!!$scope.externalForm.EntityExternalFormAssignedItems.any('item.EntityExternalFormAssignedItemStatusId==1')) {
            //         $scope.natValidation.errorMessages.push({
            //             propName: "TakeActionOnAllItems",
            //             errorMessage: Messages.General.Errors.VerifyExp
            //         });
            //     }
            // } else {
            //     if (!!$scope.entityExternalFormAssignedItem && !!$scope.entityExternalFormAssignedItem.isNotesRequired) {
            //         $scope.natValidation.errorMessages.push({
            //             propName: "NotesRequired",
            //             errorMessage: Messages.General.Errors.SuperviserComment
            //         });
            //     }
            // }
            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.loadCountyByState = function (stateCode, processLoaderExternally) {
            if (!processLoaderExternally)
                ShowLoader();
            var dataPromise = externalFormService.countyGetAll(sessionStorage.Key, stateCode);
            dataPromise.then(function (response) {
                if (response.Status) {
                    $scope.countyList = response.County;
                } else {
                    ShowErrMsg("countyGetAll", response.Message);
                }
                if (!processLoaderExternally)
                    HideLoader();
            }, function (error) {
                HideLoader();
                ShowErrMsg("countyGetAll", error.Message);
            });
            return dataPromise;
        };

        $scope.loadDistrictByState = function (stateCode, countyCode, processLoaderExternally) {
            if (!processLoaderExternally)
                ShowLoader();
            var params = "?CountyCode=" + countyCode + "&AddressState=" + stateCode;
            var dataPromise = typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefDistrictGet, sessionStorage.Key, true, params);
            dataPromise.then(function (response) {
                if (response.Status) {
                    $scope.districtList = response.RefDistrictList;
                } else {
                    ShowErrMsg("refDistrictGet", response.Message);
                }
                if (!processLoaderExternally)
                    HideLoader();
            }, function (error) {
                HideLoader();
                ShowErrMsg("refDistrictGet", error.Message);
            });
            return dataPromise;
        };

        $scope.loadSchoolByDistrict = function (countyCode, districtCode, processLoaderExternally) {
            if (!processLoaderExternally)
                ShowLoader();
            var params = "?CountyCode=" + countyCode + "&DistrictCode=" + districtCode;
            var dataPromise = typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefSchoolGet, sessionStorage.Key, true, params);
            dataPromise.then(function (response) {
                if (response.Status) {
                    $scope.schoolList = response.RefSchoolList;
                } else {
                    ShowErrMsg("refSchoolGet", response.Message);
                }
                if (!processLoaderExternally)
                    HideLoader();
            }, function (error) {
                HideLoader();
                ShowErrMsg("refSchoolGet", error.Message);
            });
            return dataPromise;
        };

        $scope.setExperienceForm = function (experienceForm) {
            $scope.experienceForm = experienceForm;
        };

        $scope.closeAddEditExperience = function () {
            $scope.viewConfig.showAddExperienceBlockType = false;
            $scope.experienceForm = null;
        };

        $scope.setExperienceEndMinDate = function (individualExperience) {
            if (angular.isDate(individualExperience.EmploymentDate))
                $scope.datePicker.endDateOptions.minDate = individualExperience.EmploymentDate;
        };

        $scope.setExperienceFroomMaxDate = function (individualExperience) {
            if (angular.isDate(individualExperience.TerminationDate))
                $scope.datePicker.startDateOptions.maxDate = individualExperience.TerminationDate;
        };

        var loadDropDownsData = function (individualExperience) {
            ShowLoader();
            var dataLoadPromises = [];

            if (!!individualExperience.StateCd) {
                dataLoadPromises.push($scope.loadCountyByState(individualExperience.StateCd, true));
                if (!!individualExperience.CountyCd) {
                    dataLoadPromises.push($scope.loadDistrictByState(individualExperience.StateCd, individualExperience.CountyCd, true));
                    if (!!individualExperience.DistrictCd)
                        dataLoadPromises.push($scope.loadSchoolByDistrict(individualExperience.CountyCd, individualExperience.DistrictCd, true));
                }
            }

            if (dataLoadPromises.length > 0) {
                $q.all(dataLoadPromises).then(function (response) {
                    $scope.viewConfig.showAddExperienceBlockType = true;
                    HideLoader();
                }, function (error) {
                    HideLoader();
                });
            } else {
                $scope.viewConfig.showAddExperienceBlockType = true;
                HideLoader();
            }
        };

        $scope.openAddEditExperience = function (isEdit, entityExternalFormAssignedItem) {
            $scope.isExperienceEdit = isEdit;
            $scope.entityExternalFormAssignedItem = entityExternalFormAssignedItem;
            $scope.experience = angular.copy(entityExternalFormAssignedItem.IndividualExperience);
            loadDropDownsData(entityExternalFormAssignedItem.IndividualExperience);
        };

        $scope.getExperienceInfo = function () {
            externalFormService.getIndividualExperience(sessionStorage.Key, $scope.externalForm.EntityId)
                .then(function (response) {
                    if (response.Status) {
                        if (!!response.IndividualExperienceList) {
                            $scope.individualExperienceListOutState = response.IndividualExperienceList.whereNotEquals('NE', 'StateCd');
                        }
                    } else {
                        ShowErrMsg("experienceNotLoaded", response.Message);
                    }
                    HideLoader();
                }, function (error) {
                    HideLoader();
                    ShowErrMsg("experienceNotLoaded", error.Message);
                });
        };

        $scope.deleteExperience = function (individualExperience) {
            try {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    ShowLoader();
                    individualExperience.IsDeleted = true;
                    externalFormService.saveIndividualExperience([individualExperience], sessionStorage.Key)
                        .then(function (response) {
                            if (response.Status && response.StatusCode == "00") {
                                $scope.getExperienceInfo();
                            }
                            HideLoader();
                        }, function (data) {
                            HideLoader();
                            ShowErrMsg();
                        });
                }
            } catch (ex) {
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
            }
        };

        $scope.exemptionFromLicenseChanged = function () {
            if (!!$scope.individualReference && !!$scope.individualReference.ExemptFromLicense) {
                $scope.individualReference.LicenseDisciplineTypeId = null;
                $scope.individualReference.LicenseNumber = null;
                $scope.individualReference.LicenseExpirationDate = null;
                $scope.individualReference.ReferenceStateCd = null;
            } else if (!!$scope.individualReference && !$scope.individualReference.ExemptFromLicense) {
                $scope.individualReference.ExemptReason = null;
            }
        };

        $scope.openDeclineReferenceRequest = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'declineReferenceRequest.html',
                size: 'md',
                resolve: {
                    externalFormData: $scope.externalForm
                },
                controller: ['$scope', '$uibModalInstance', 'externalFormData', function ($scope, $uibModalInstance, externalFormData) {
                    $scope.externalFormData = externalFormData;

                    var validateForm = function (formsToValidate) {
                        $scope.natValidation = {
                            errorMessages: []
                        };

                        if (!!formsToValidate) {
                            angular.forEach(formsToValidate, function (currentForm, index) {
                                currentForm.$submitted = true;
                                if (!!currentForm.errorMessages) {
                                    var errorMessages = angular.copy(currentForm.errorMessages);
                                    angular.forEach(errorMessages, function (errorMessage, index) {
                                        $scope.natValidation.errorMessages.push(errorMessage);
                                    });
                                }
                            });
                        }

                        if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                            $scope.showStatusMsg('-', $scope.natValidation.errorMessages);
                            return false;
                        } else {
                            $scope.hideStatusMsg();
                            return true;
                        }
                    };

                    $scope.onCancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };

                    $scope.onConfirm = function (currentForm) {
                        if (validateForm([currentForm])) {
                            $uibModalInstance.close($scope.externalFormData);
                        }
                    };

                }]
            });
            modalInstance.result.then(function (response) {
                $scope.externalFormData = response;
                if ($scope.externalFormData && !!$scope.externalFormData.EntityExternalFormAssignedItems
                    && $scope.externalFormData.EntityExternalFormAssignedItems.length > 0 && !!$scope.externalFormData.EntityExternalFormAssignedItems[0]) {
                    $scope.externalFormData.ExternalFormStatusId = 3;
                    $scope.externalFormData.TemplateId = 152;
                    $scope.externalFormData.SendEmail = true;
                    var dataPromises = {
                        submitEntityExternalForm: externalFormService.submitEntityExternalForm([$scope.externalFormData], sessionStorage.Key)
                    }
                    ShowLoader();
                    $q.all(dataPromises)
                        .then(function (response) {
                            var errorMessage = '';
                            angular.forEach(response, function (responseItem) {
                                if (!responseItem.Status) {
                                    errorMessage = errorMessage + responseItem.Message;
                                }
                            });
                            if (!!errorMessage) {
                                $scope.showStatusMessage(errorMessage, "error");
                                HideLoader();
                                return;
                            }
                            $scope.externalForm.ExternalFormStatusId = 3;
                            HideLoader();
                        }, function (error) {
                            $scope.showStatusMessage(error.message, "error");
                        });
                }

            }, function () {

            });
        };
        //Teaching Experience

        $scope.init();
    }
})();