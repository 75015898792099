﻿(function () {
    'use strict'
    angular.module('app.provider')
        .controller('sendApplicationReceiptEmailController', ['$scope', 'sendApplicationReceiptEmailFactory', function ($scope, sendApplicationReceiptEmailFactory) {
            var _key = sessionStorage.Key;
            $scope.natValidation = {};
            $scope.init = function () {
                $scope.emailAddress = angular.copy($scope.emailid);
            };

            $scope.sendEmail = function (form) {
                if (validateForm(form)) {
                    if ($scope.providerId || $scope.individualId) {
                        var req = {
                            IndividualId:$scope.individualId,
                            ProviderId: $scope.providerId,
                            InvoiceId : $scope.invoiceId,
                            EmailId: $scope.emailAddress,
                            EmailContent: ($scope.item? $scope.item.HTMLContent : "")
                        }
                        ShowLoader();
                        try {
                            var src = 'providerim';
                            if (req.IndividualId && req.IndividualId > 0) {
                                src = 'individualim';
                            }
                            sendApplicationReceiptEmailFactory.sendApplicationPaymentReceiptEmail(sessionStorage.Key, req, src).then(function (res) {
                                if (res && res.Status) {
                                    $scope.showStatusMessage({ message: "Application Receipt sent to specified email address.", type: "success" });
                                    $scope.closeBtnClicked();
                                }
                                HideLoader();
                            }, function (err) {
                                HideLoader();
                                if (err) {
                                    $scope.showStatusMessage({ message: err.Message , type: "error" });
                                }
                            });
                        }catch(e){
                            HideLoader();
                            $scope.showStatusMessage({ message: "some error occured!", type: "error" });
                        }

                    }
                }
            };

            $scope.closeBtnClicked = function () {
                if ($scope.item) {
                    $scope.item.showEmailSec = false;
                }
            };


            var validateForm = function (currentForm) {
                $scope.natValidation.errorMessages = [];
                if (!!currentForm) {
                    currentForm.$submitted = true;
                    $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
                }
                if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                    $scope.natValidation.showError = true;
                    $scope.natValidation.isValid = false;
                    return false;
                } else {
                    $scope.natValidation.showError = false;
                    $scope.natValidation.isValid = true;
                    return true;
                }
            };
           
        }]);
})();