(function() {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeIndividualCorrespondenceService', backofficeIndividualCorrespondenceService)

    backofficeIndividualCorrespondenceService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeIndividualCorrespondenceService($http, $q, WebApiUrl, AppConfig) {

        var _individualCorrespondenceByIndividualId = function(Key, IndividualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualCommunicationGetByIndividualId/' + Key + '?IndividualId=' + IndividualId));
        }

        var _individualCorrespondenceSave = function(correspondence, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualCommunicationSave/' + key + '?Source=' + AppConfig.Source, correspondence));
        };

        var _getUserInformation = function (key, userId) {
            var urlParams = {
                ID: userId
            };

            return returnDefferedResult($http.get(WebApiUrl + 'Users/UsersGetBYID/' + key + '/', { params: urlParams }))
        }

        var returnDefferedResult = function(request) {
            var deferred = $q.defer();
            request.then(function(response) {
                deferred.resolve(response.data);
            }, function(response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        return {
            individualCorrespondenceByIndividualId: _individualCorrespondenceByIndividualId,
            individualCorrespondenceSave: _individualCorrespondenceSave,

            getUserInformation: _getUserInformation
        }
    }
})();