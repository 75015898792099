(function () {
    'use strict';

    angular
        .module('app.provider')
        .controller("CeProviderSignInAppController", CeProviderSignInAppController);

    CeProviderSignInAppController.$inject = ["$rootScope", "$scope", "$state", "bppService", "RegisterFactory", "htmlToPdfGenerationService", "initialApplicationstatusService",
        "countryStateService", "typeValuesService", "lookupService", "utilityService", "loggingService", "$exceptionHandler", "TypeValue", "WebApiUrl", "$q", "Messages"
    ];

    function CeProviderSignInAppController($rootScope, $scope, $state, bppService, RegisterFactory, htmlToPdfGenerationService, initialApplicationstatusService,
        countryStateService, typeValuesService, lookupService, utilityService, loggingService, $exceptionHandler, TypeValue, WebApiUrl, $q, Messages) {
        if (!$scope.isBackoffice)
            $scope.providerId = sessionStorage.ProviderId;
        var userId = ($scope.isBackoffice ? sessionStorage.UserID : sessionStorage.UserId);
        var applicationTypeId = 9;
        $scope.viewConfig = {};

        //var providerInfo = loggingService.providerInfo();

        var init = function () {
            if (!$scope.isBackoffice) {
                loadProviderApplication(sessionStorage.Key, $scope.providerId, applicationTypeId);
            } else {
                loadApplicationByApplicationId(sessionStorage.Key, $scope.currentRenewalItem.ApplicationId);
            }
        };

        var loadApplicationByApplicationId = function (key, applicationId) {
            bppService.applicationByApplicationId(key, applicationId, userId).then(function (response) {
                if (response.Status) {
                    $scope.applicationInfo = response.ApplicationResponseList[0];
                    $scope.viewConfig.loadSignInInfo = true;
                }
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage(error, 'error');
            });
        };

        var loadProviderApplication = function (key, providerId, applicationTypeId) {
            ShowLoader();
            bppService.applicationByProviderId(key, providerId).then(function (response) {
                if (response.Status) {
                    var pendingApplication;
                    angular.forEach(response.ApplicationResponseList, function (application) {
                        if (application.ApplicationTypeId == applicationTypeId && application.ApplicationStatusId == 1) {
                            pendingApplication = application;
                        }
                    });
                    $scope.applicationInfo = pendingApplication;
                    if (!pendingApplication) {
                        generateApplicationId(0, userId, providerId, applicationTypeId);
                    } else {
                        $scope.viewConfig.loadSignInInfo = true;
                    }
                }
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage(error, 'error');
            });
        };

        $scope.submitApplicationForm = function () {
            ShowLoader();
            var reqObj = {};
            reqObj.ProviderId = $scope.providerId;
            reqObj.ApplicationId = $scope.applicationInfo.ApplicationId;
            reqObj.PullTabHtmlForPdf = false;
            reqObj.CreateApplicationPdf = true;
            reqObj.SendSubmissionEmail = true;
            reqObj.SendSubmissionSms = true;
            reqObj.DoNotSendPaymentReceipt = true;
            bppService.applicationStatusUpdate(sessionStorage.Key, $scope.applicationInfo.ApplicationId, 3, 'O').then(function (response) {
                htmlToPdfGenerationService.processFirmRenewalHtml().then(function (response) {
                    reqObj.TabHtml = {
                        ApplicationID: $scope.applicationInfo.ApplicationId,
                        IndividualID: $scope.providerId,
                        TabName: 'RenewalCompleteForm',
                        CreatedBy: $scope.UserId,
                        DataDesc: "Renewal Complete Form Html including the css and all sections",
                        HTMLContent: response,
                        SortingOrder: 1
                    };
                    initialApplicationstatusService.submitApplication(sessionStorage.Key, reqObj)
                        .then(function (objEmailAndLogCommunication) {
                            if (objEmailAndLogCommunication.StatusCode != "00") {
                                return showErrorMessage(objEmailAndLogCommunication.Message);
                            }
                            if (!$scope.isBackoffice) {
                                $state.go('app.PaymentConfirmation', {
                                    paymentMethod: 3,
                                    applicationType: $scope.applicationInfo.ApplicationTypeId
                                });
                            }
                        }, function (response) {
                            return showErrorMessage(response);
                        });
                });
            });
        };

        var generateApplicationId = function (applicationId, userId, providerId, applicationTypeId) {
            var urlparm = '/' + sessionStorage.Key + '?ApplicationId=' + applicationId + '&UserId=' + userId;
            var reqData = {
                ApplicationTypeId: applicationTypeId,
                ApplicationStatusId: 1,
                ApplicationSubmitMode: 'O',
                GoPaperless: true,
                IsActive: true
            };

            RegisterFactory.GenerateApplicationId(urlparm, reqData).then(function (response) {
                if (response.data.Status) {
                    saveProviderApplication(providerId, response.data.ApplicationId, userId);
                }
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage(error, 'error');
            });
        };

        var saveProviderApplication = function (providerId, applicationId, userId) {
            var reqData = {
                ApplicationId: applicationId,
                providerId: providerId,
                CreatedBy: userId,
                // CreatedOn: new Date()
            };
            RegisterFactory.saveProviderApplication(sessionStorage.Key, reqData).then(function (response) {
                if (response.data.Status) {
                    loadApplicationByApplicationId(sessionStorage.Key, applicationId);
                } else {
                    HideLoader();
                }
            }, function (error) {
                HideLoader();
                $scope.showStatusMessage(error, 'error');
            });
        };
        var eventLog = function (txtType) {
            var logRequest = {
                ProviderId: $scope.providerId,
                MasterTransactionId: 1,
                PageModuleId: 1,
                PageModuleTabSubModuleId: 1,
                PageTabSectionId: 1,
                Type: "L",
                CommentLogSource: "WSAPI",
                CommentLogText: txtType,
                IsInternalOnly: true,
                IsForInvestigationOnly: true,
                IsForPublic: true,
                IsActive: true,
                NotesTypeId: 1,
                DoNotProcessNotesLog: true
            };
            loggingService.providerLogSave(logRequest, sessionStorage.Key);
        };

        $scope.closeApplicationForm = function () {
            if (!!$scope.isBackoffice)
                $scope.currentRenewalItem.ShowInternalForm = false;
            else {
                if (confirm(Messages.General.Confirm.CloseApp)) {
                    $state.go('app.cedashboard');
                }
            }
        };

        init();
    }
})();