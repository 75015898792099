(function () {
  'use strict'
  angular.module('app.report')
    .controller('ProbationListReportController', ProbationListReportController);

  ProbationListReportController.$inject = ['$scope', '$q', '$controller', '$filter',
    'reportService', '$state', 'utilityService', 'ApiUrlsForReports', 'ApiUrlsForReportsXls'];

  function ProbationListReportController($scope, $q, $controller, $filter, reportService, $state, utilityService, ApiUrlsForReports, ApiUrlsForReportsXls) {
    $controller('GlobalController', { $scope: $scope })

    // Pagination
    $scope.Pager = {
      sortType: 'LicenseNumber',
      sortOrder: 'asc',
      currentPage: 1,
      totalRecords: 10,
      pageSize: 20,
      maxSize: 5
    }
    $scope.Headers = [
      { name: 'First Name', sortType: 'FirstName', sortOrder: 'asc', class: 'w90' },
      { name: 'Middle Name', sortType: 'MiddleName', sortOrder: 'asc', class: 'w90' },
      { name: 'Last Name', sortType: 'LastName', sortOrder: 'asc', class: 'w90' },
      { name: 'License#', sortType: 'LicenseNumber', sortOrder: 'desc', class: 'w70' },
      { name: 'Date of Expiration', sortType: 'LicenseExpirationDate', sortOrder: 'asc', class: 'w120' },
      { name: 'Type', sortType: 'LicenseTypeName', sortOrder: 'asc' },
      { name: 'Status', sortType: 'LicenseStatusTypeName', sortOrder: 'asc', class: 'w60' },
      { name: 'StreetLine1', sortType: 'StreetLine1', sortOrder: 'asc', class: 'w90' },
      { name: 'StreetLine2', sortType: 'StreetLine2', sortOrder: 'asc', class: 'w90' },
      { name: 'City', sortType: 'City', sortOrder: 'asc', class: 'w80' },
      { name: 'Zip', sortType: 'Zip', sortOrder: 'asc', class: 'w50' },
      { name: 'StateCode', sortType: 'StateCode', sortOrder: 'asc', class: 'w60' }
    ]
    $scope.getProbationListReportList = function () {
      try {
        if ($scope.isSessionActive()) {
          ShowLoader('Please Wait...')
          reportService.probationListReportWithPager(sessionStorage.Key, $scope.Pager, ApiUrlsForReports.probationList)
            .then(function (response) {
              if ($scope.checkResponse(response)) {
                $scope.Pager.totalRecords = response.PagerVM.TotalRecords;
                $scope.LicensureList = response.PagerVM.Records;
              }
              HideLoader();
              utilityService.scrollTop();
            }, function (data) {
              HideLoader()
              showStatusMessage(data, 'error')
            })
        } else {
          $state.go('app.Loginwoemail')
        }
      } catch (ex) {
        HideLoader()
        if (ex != null) { showStatusMessage(ex.message, 'error'); }
      }
    }

    $scope.getProbationListReportList();

    $scope.SortRecords = function (header) {
      $scope.Pager.currentPage = 1;
      $scope.Pager.sortType = header.sortType;
      $scope.Pager.sortOrder = header.sortOrder;
      header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
      $scope.getProbationListReportList();
    }
    $scope.getExcelForProbationListReport = function () {
      try {
        ShowLoader('Please Wait...')
        reportService.getXLSForProbationListReport(sessionStorage.Key, $scope.Pager, ApiUrlsForReportsXls.probationList)
          .then(function (response) {
            HideLoader()
          }, function (data) {
            HideLoader()
            showStatusMessage(data, 'error')
          })
      } catch (ex) {
        HideLoader()
        if (ex != null) { showStatusMessage(ex.message, 'error'); }
      }
    }
  };
})();

