﻿


(function () {
    'use strict'
    angular.module('natApp')
        .factory('BusinessEntityService', ['$http', '$q', 'WebApiUrl', 'AppConfig', function ($http, $q, WebApiUrl, AppConfig) {
            var baseFactory = {};
            baseFactory.RenewalSearchWithPager = function (key, PageNumber, NoOfRecords, SortColumn, SortOrder, ShowAllRecords, renewalSearchObj) {
                return returnDefferedResult($http.post(WebApiUrl + 'Renewal/ProviderRenewalSearchWithPager/' + key + '?PageNumber=' + PageNumber + '&NoOfRecords=' + NoOfRecords + '&SortColumn=' + SortColumn + '&SortOrder=' + SortOrder + '&ShowAllRecords=' + ShowAllRecords, renewalSearchObj));
            };
            baseFactory.ProviderSearchWithPager = function (key, PageNumber, NoOfRecords, SortColumn, SortOrder, ShowAllRecords, renewalSearchObj) {
                return returnDefferedResult($http.post(WebApiUrl + 'Renewal/ProviderSearchWithPager/' + key + '?PageNumber=' + PageNumber + '&NoOfRecords=' + NoOfRecords + '&SortColumn=' + SortColumn + '&SortOrder=' + SortOrder + '&ShowAllRecords=' + ShowAllRecords, renewalSearchObj));
            };
            baseFactory.GetProviderApplicationList = function (key, renewalSearchObj) {
                return returnDefferedResult($http.post(WebApiUrl + 'ProviderApplication/GetProviderApplicationList/' + key, renewalSearchObj));
            };
            baseFactory.GenerateApplicationId = function (key, applicationid, uid, data) {
                return returnDefferedResult($http.post(WebApiUrl + "Application/GenerateApplicationId/" + key + '?ApplicationId=' + applicationid + '&UserId=' + uid, data));
            };
            var returnDefferedResult = function (request) {
                var deferred = $q.defer();
                request.then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.reject(response);
                });
                return deferred.promise;
            };

            return baseFactory;
        }]);
})();