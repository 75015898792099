(function () {
    'use strict';

    angular
        .module('app.provider')
        .controller('VerifyCeCourseIndividualController', VerifyCeCourseIndividualController);

        VerifyCeCourseIndividualController.$inject = ['$scope', 'bppService', 'utilityService', 'initialApplicationCEEducationService', '$state', 'typeValuesService', 'TypeValue', 'Messages'];
    /** @ngInject */
    function VerifyCeCourseIndividualController($scope, bppService, utilityService, initialApplicationCEEducationService, $state, typeValuesService, TypeValue, Messages) {

        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/d!/yyyy'
        };

        $scope.Init = function () {
            if (!$scope.isUserSessionActive()) {
                $state.go('app.LoginBusinessPortal');
                return;
            }
            $scope.showCeCourseIndividuals = false;
            $scope.pager = {
                pageSize: 20,
                currentPage: 1,
                totalRecords: "",
                maxSize: 3,
            };
            ShowLoader();
            bppService.providerCECourseByProviderId(sessionStorage.ProviderId, "", sessionStorage.Key)
                // typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=refprovidercourse"))
                .then(function (response) {
                    if ($scope.checkServerResponse(response)) {
                        $scope.ceCourseProviders = response.ProviderCourseResponseList;
                        HideLoader();
                    }
                }, function (data) {
                    HideLoader()
                    showStatusMessage(data, 'error')
                });
        };

        $scope.viewConfig = {};
        $scope.natValidation = {};
        $scope.searchData = {
            isSearched: false
        };

        $scope.onSelect = function($item, $model, $label){
            ShowLoader();
            bppService.getIndividualDetailByProviderIdAndCourseName(sessionStorage.Key,sessionStorage.ProviderId,$model)
            .then(function(response){
                if ($scope.checkServerResponse(response)) {
                    $scope.showCeCourseIndividuals = true
                    $scope.ceCourseIndividuals = response.IndividualCESearchList;
                    HideLoader();
                }
            });

        }

        var validateForm = function (currentForm) {
            if (!!currentForm) {
                if (!currentForm.errorMessages)
                    currentForm.errorMessages = [];
                currentForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            } else {
                $scope.natValidation.errorMessages = [];
            }

            if (!$scope.searchData.LastName && !$scope.searchData.FirstName && !$scope.searchData.LicenseNumber) {
                var errorObj = {
                    propName: "EitherLastOrFirstNameOrLicenseNoReq",
                    errorMessage: Messages.ecabcdb
                };
                $scope.natValidation.errorMessages.push(errorObj);
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.searchCeCourseByIndividual = function () {
            hideStatusMessage();
            ShowLoader('Please Wait...');
            $scope.closeAddCeEducationForm();
            if (!validateForm()) {
                HideLoader();
                $scope.searchData.isSearched = false;
                return;
            }
            bppService.getIndividualDetailforCEProvider($scope.searchData, sessionStorage.Key, $scope.pager.currentPage, $scope.pager.pageSize)
                .then(function (response) {
                    if ($scope.checkServerResponse(response)) {
                        $scope.individualLicenses = response.IndividualCESearchList;
                        $scope.pager.totalRecords = response.Total_Record;
                        $scope.searchData.isSearched = true;
                        HideLoader();
                    }
                }, function (data) {
                    HideLoader()
                    showStatusMessage(data, 'error')
                });
        };

        var createNewEducation = function (isEducationEdit, educationInfo, individualLicense) {
            if (!isEducationEdit) {
                $scope.individualLicense.educationInfo = {
                    IndividualId: individualLicense.IndividualId,
                    IndividualLicenseId: individualLicense.IndividualLicenseId,
                    LicenseTypeId: individualLicense.LicenseTypeId,
                    IsActive: true
                };
            } else {
                $scope.individualLicense.educationInfo = angular.copy(educationInfo);
            }
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        $scope.openCeCourseDetails = function (individualLicense) {
            ShowLoader();
            $scope.individualLicense = individualLicense;
            initialApplicationCEEducationService.getCEEducation(individualLicense.IndividualId, sessionStorage.Key).then(function (response) {
                if ($scope.checkServerResponse(response)) {
                    individualLicense.individualCeCourseList = response.IndividualCECourseResponseList;
                    individualLicense.showCourseProvider = true;
                    $scope.openAddCeEducationForm(false, null, individualLicense);
                }
                HideLoader();
            }, function (error) {
                HideLoader();
                showStatusMessage(error, 'error');
            });
        };

        $scope.openAddCeEducationForm = function (isEducationEdit, educationInfo, individualLicense) {
            ShowLoader();
            $scope.viewConfig.showAddEducationForm = true;
            $scope.isEducationEdit = isEducationEdit;
            createNewEducation(isEducationEdit, educationInfo, individualLicense);
            HideLoader();
        };

        $scope.closeAddCeEducationForm = function () {
            $scope.viewConfig.showAddEducationForm = false;
        };

        $scope.reset = function () {
            $scope.searchData = {};
            // $scope.currentCeForm.$setPristine();
            $scope.natValidation.errorMessages = [];
            hideStatusMessage();
        };

        $scope.setCeForm = function (currentForm) {
            $scope.currentCeForm = currentForm;
        };

        $scope.saveContinuingEducation = function (currentForm, individualLicense) {
            ShowLoader("Please wait...");
            if (!validateForm(currentForm)) {
                HideLoader();
                return;
            }
            hideStatusMessage();
            individualLicense.educationInfo.ProviderId = individualLicense.educationInfo.ceProvider.ProviderId;
            individualLicense.educationInfo.CECourseHours = individualLicense.educationInfo.ceProvider.CourseHours;
            individualLicense.educationInfo.CourseNameTitle = individualLicense.educationInfo.ceProvider.CourseName;
            individualLicense.educationInfo.CourseNumber = individualLicense.educationInfo.ceProvider.CourseId;
            individualLicense.educationInfo.CourseCategoryId = individualLicense.educationInfo.ceProvider.CourseCategoryId;

            bppService.getProviderById(sessionStorage.Key, individualLicense.educationInfo.ProviderId).then(function (response) {
                if (response.Status == false) {
                    ShowErrMsg("unableToSaveCEEducation", response.Message);
                } else {
                    individualLicense.educationInfo.CourseSponsor = response.ProviderResponse.ProviderName;
                    initialApplicationCEEducationService.educationSave(individualLicense.educationInfo, sessionStorage.Key)
                        .then(function (response) {
                            if (response.Status == false) {
                                ShowErrMsg("unableToSaveCEEducation", response.Message);
                            } else {
                                showStatusMessage(Messages.General.Success.CESave, 'success');
                                $scope.currentCeForm.$setPristine();
                                $scope.openCeCourseDetails(individualLicense);
                            }
                        }, function (data) {
                            initialApplicationstatusService.setCEEducation("error");
                            ShowErrMsg("unableToSaveCEEducation", data);
                            HideLoader();
                        });
                }
            });

        };
    }
})();