﻿(function () {
    'use strict'
    angular.module('app.provider')
        .factory('providerApplicationFeeFactory', ['$http', 'WebApiUrl', '$q', 'AppConfig', function ($http, WebApiUrl, $q, AppConfig) {
            var baseFactory = {};
            baseFactory.getPaymentDetails = function (key, providerId, isdashboard, UserID) {
                var urlParam = key + '?IndividualId=&ProviderId=' + providerId + '&Source=NVPHARMACYDASHBOARD';// + AppConfig.Source;
                if (isdashboard)
                    var urlParam = key + '?IndividualId=&ProviderId=' + providerId + '&UserId=' + UserID + '&Source=NVPHARMACYDASHBOARD';// + AppConfig.Source;

                return $http.get(WebApiUrl + 'Payment/GetPaymentDetailsByIndividualId/' + urlParam);
            }
            baseFactory.sendPaymentConfirmationEmail = function (key, data) {
                return $http.post(WebApiUrl + 'Payment/ProviderIndividualPaymentConfirmation/' + key + "?Source=wypeplsiprovider", data);
            }

            baseFactory.getPaymentDetail = function (key, indvid, isProvider) {

                var urlParam = key + '?IndividualId=' + indvid + '&ProviderId=';
                if (isProvider) {
                    urlParam = key + '?IndividualId=&ProviderId=' + indvid;
                }
                return $http.get(WebApiUrl + 'Payment/GetPaymentDetail/' + urlParam);
            }
            return baseFactory;
        }]);
})();
