(function() {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeFirmsResponsibleChargeController", BackofficeFirmsResponsibleChargeController);

    BackofficeFirmsResponsibleChargeController.$inject = ["$rootScope", "$scope", "$state", "backofficeProviderResposibleChargeService",
        "countryStateService", "typeValuesService", "lookupService", "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl", "BackofficeIndividualService"
    , 'Messages'];

    function BackofficeFirmsResponsibleChargeController($rootScope, $scope, $state, backofficeProviderResposibleChargeService,
        countryStateService, typeValuesService, lookupService, utilityService, $exceptionHandler, TypeValue, WebApiUrl, BackofficeIndividualService, Messages) {
      
        $scope.userId = sessionStorage.UserID;
        $scope.IsTab = true;
        //Tab Initilizer
        $scope.$on("individualTabChanged", function(evt, ProviderId) {
            $scope.ShowAssociation = false;
            if ($rootScope.individualFirmCurrentTab.name == "DCH") {
                $scope.ProviderId = ProviderId;               
                ShowLoader();
                $scope.ShowAssociation = false;
                var to = setTimeout(function () {
                    if ($scope.ProviderId) {
                        $scope.ShowAssociation = true;
                        console.log('app', $scope.ShowAssociation);
                        $scope.$apply();
                        HideLoader();
                    }
                    clearInterval(to);
                },500);
                //$scope.providerIdByIndividualId($scope.ProviderId);
                $rootScope.individualFirmCurrentTab.isLoadedOnce = true;
            }
        });




        //$scope.saveResponsibleCharges = function (ProviderIndividual) {           
        //    backofficeProviderResposibleChargeService.providerSave(sessionStorage.Key, [ProviderIndividual])
        //        .then(function (response) {
        //            if ($scope.checkServerResponse(response)) {
        //                $scope.providerIdByIndividualId($scope.ProviderId);
        //                $scope.IndividualSearchArray = [];
        //        }
        //        HideLoader();
        //    }, function (data) {
        //        HideLoader();
        //        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
        //    });
        //}
        //$scope.deleteProvider = function (ProviderIndividual) {
        //    if (confirm(Messages.dfdbeab)) {
        //        ShowLoader("Please wait...");

        //        ProviderIndividual.IsDeleted = true;
        //        backofficeProviderResposibleChargeService.providerSave(sessionStorage.Key, [ProviderIndividual])
        //            .then(function (response) {
        //                $scope.hideStatusMessage();
        //                $scope.hideValidationSummary();
        //                if ($scope.checkServerResponse(response)) {
        //                    $scope.providerIdByIndividualId($scope.ProviderId);
        //                    $scope.IndividualSearchArray = [];
        //                }
        //                HideLoader();
        //            }, function (data) {
        //                HideLoader();
        //                if (data != null) { $scope.showStatusMessage(data.message, "error"); }
        //            });

        //    }
        //};
        //$scope.providerIdByIndividualId = function (ProviderId) {
        //    ShowLoader("Please Wait...");
        //    try {
        //        backofficeProviderResposibleChargeService.providerIdBy_IndividualId(sessionStorage.Key, ProviderId)
        //            .then(function (response) {

        //                if ($scope.checkServerResponse(response)) {
        //                    if (response.ProviderIndividualList != null) {
        //                        $scope.ProviderIdList = [];
        //                        $scope.ProviderIdList = response.ProviderIndividualList;

        //                        if ($scope.ProviderIdList.length > 0) {
        //                            $scope.ProviderDetails = [];
        //                            angular.forEach($scope.ProviderIdList, function (value, index) {
        //                                if (value.IndividualId != 0) {
        //                                    backofficeProviderResposibleChargeService.individualDetail(sessionStorage.Key, value.IndividualId)
        //                          .then(function (response) {
        //                              if (response.IndividualResponse != null) {
        //                                  //$scope.ProviderDetails.push(response.IndividualResponse, Responsible = value.Responsible);
        //                                  var ProviderDetail = {
        //                                      LastName: response.IndividualResponse[0].LastName,
        //                                      FirstName: response.IndividualResponse[0].FirstName,
        //                                      LicenseNumber: response.IndividualResponse[0].LicenseNumber,
        //                                      IndividualId: value.IndividualId,
        //                                      ProviderId: value.ProviderId,
        //                                      Responsible: value.Responsible
        //                                  };
        //                                  $scope.ProviderDetails.push(ProviderDetail);
        //                              }
        //                          });
        //                                }

        //                            })

        //                        }

        //                        HideLoader();
        //                    }
        //                    else {
        //                        $scope.ProviderIdList = [];
        //                        $scope.ProviderDetails = [];
        //                        HideLoader();
        //                    }
        //                } else {
        //                    HideLoader();
        //                }
        //            }, function (data) {
        //                HideLoader();
        //                $scope.showStatusMessage(data, "error");
        //            });
        //    } catch (ex) {
        //        HideLoader();
        //        if (ex != null) { showStatusMessage(ex.message, "error"); }
        //    }
        //}
       
        //$scope.SearchByName = function (search) {
        //    $scope.individualSearchWithPage();
        //    BackofficeIndividualService.setIndividualSearchContent($scope.search);
        //}

        //$scope.individualSearchWithPage = function () {          
       
        //        try {
        //            ShowLoader("Please wait...");                    
        //            BackofficeIndividualService.individualSearchWithPage($scope.search, sessionStorage.Key, $scope.Pager.currentPage, $scope.Pager.pageSize)
        //                .then(function (response) {                          
        //                    if ($scope.checkResponse(response)) {
        //                        $scope.Pager.totalRecords = response.Total_Recard;
        //                        if (response.Total_Recard > 0) {
        //                            hideStatusMessage();
        //                            $scope.IndividualSearchArray = response.IndividualList;                                   
        //                            HideLoader();
        //                        } else {
        //                            showStatusMessage(Messages.dcdcabb, "error");
        //                        }
        //                    }
        //                    HideLoader();
        //                }, function (data) {
        //                    HideLoader();
        //                    if (data != null) { showStatusMessage(data.message, "error"); }
        //                });
        //        } catch (ex) {
        //            HideLoader();
        //            if (ex != null) { showStatusMessage(ex.message, "error"); }
        //        }
          
        //}
        //$scope.AddinProviderIndividual = function (searchData) {            
        //    var ProviderIndividual = { LicenseNumber: searchData.LicenseNumber, IndividualId: searchData.IndividualId, ProviderId: $scope.ProviderId, IsDeleted: 0, Responsible:1 }
        //    $scope.saveResponsibleCharges(ProviderIndividual);
          
        //    $scope.IndividualSearchArray = [];
        //    $scope.ResponsibleChargeDV = false;
        //    $scope.AddResponsibleChargebutton = false;
        //}
        //$scope.Pager = {
        //    pageSize: 10,
        //    currentPage: 1,
        //    totalRecords: "",
        //    maxSize: 3,
        //};

        var searchMaster = {
            Name: "",
            Email: "",
            FirstName: "",
            LastName: "",
            Phone: "",
            LicenseNumber: "",
            DateOfBirth: "",
            Address: ""
        };
       
        $scope.search = angular.copy(searchMaster); 

        $scope.cancel = function () {
            $scope.search = angular.copy(searchMaster);
        }
    }
})();