/// <reference path="../../../scripts/built-in-type-extensions.ts" />
/// <reference path="query-reporting-doc.ts" />

(function() {
    'use strict'
    
    angular.module('app.report')
    .controller('QueryReportingMultipathSelectorController', ['$scope', '$uibModalInstance', 'parameters', QueryReportingMultipathSelectorController]);

    function QueryReportingMultipathSelectorController($scope, $uibModalInstance, parameters) {
        $scope.init = function () { 
            $scope.relationPath = parameters.relationPath;
            $scope.relation = parameters.relation;
            
            $scope.selectedColumns = null;

            $scope.columnRelations = $scope.relation.ColumnName.merge($scope.relation.RelationColumnName).select(function(p) { return { from: p[0], to: p[1] }});
        };

        $scope.validateDialog = function (){
            return $scope.selectedColumns;
        };

        $scope.columnsSelected = function () {
            $scope.relation.ColumnName = $scope.selectedColumns.from;
            $scope.relation.RelationColumnName = $scope.selectedColumns.to;

            $uibModalInstance.close({ relationPath: $scope.relationPath, relation: $scope.relation });
        };

        $scope.cancelDialog = function () {
            $uibModalInstance.dismiss();
        };
    }
})();