(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationCpcEducation', initialApplicationCpcEducationDirective);

    initialApplicationCpcEducationDirective.$inject = [];

    function initialApplicationCpcEducationDirective() {
        return {
            restrict: 'E',
            scope: {
                cpcEducationInfo: "=cpcEducationInfo",
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                applicationId: "=applicationId",
                individualId: "=individualId",
                individualLicense: "=individualLicense",
                individualCEH: "=individualCeh",
                natValidation: "=natValidation",
                showStatusMessage: "&",
                tab: "=tab",
                openNextTab: "&",
                isRenewal: '=?isRenewal',
                loadData: '=?loadData',
                individualLicenseReciprocity: "=?individualLicenseReciprocity",
                individualLicenseStatusSubTypeReason: "=?individualLicenseStatusSubTypeReason",
                personalData: "=?personalData",
                totalValue: "=?totalValue"
            },
            templateUrl: "app/components/individual/application/directives/cpc-education/cpc-education.html",
            controller: "InitialApplicationCPCEducationController"
        }
    }
})();