(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationFeeDetailsController", InitialApplicationFeeDetailsController);

    InitialApplicationFeeDetailsController.$inject = ["$rootScope", "$scope",
        "contentService", "initialApplicationstatusService", "utilityService", "WebApiUrl", "AppConfig", "$filter"
    ];

    function InitialApplicationFeeDetailsController($rootScope, $scope,
        contentService, initialApplicationstatusService, utilityService, WebApiUrl, AppConfig, $filter) {

        $scope.init = function () {
            $scope.datePicker = {
                dateOpened: false,
                format: 'M!/d!/yyyy'
            };
            $scope.getFeeDetailsConfig();
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        }

        $scope.getFeeDetailsConfig = function () {
            var relativeFilePath = "/individual/application/fee-details/fee-details.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function (data) {
                    $scope.feeDetailsConfig = data;
                    $scope.isConfigLoaded = true;
                }, function (error) {

                });
        };

        $scope.getTotalFee = function (feedetail, individualLicense) {
            var totalFee = (1 * $scope.feeDetails.applicationFee) + (1 * $scope.feeDetails.lateFee);
            totalFee = $filter('number')(totalFee, 2);
            $scope.feeDetails.totalFee = totalFee;
            return totalFee;
        };

        $scope.postMarkedDateChanged = function () {
            var dateToCompare = $scope.feeDetails.currentDate;
            if (!!$scope.feeDetails.postMarkDate && angular.isDate($scope.feeDetails.postMarkDate))
                dateToCompare = $scope.feeDetails.postMarkDate;
            // if (!!$scope.individualLicense.currentLicenseStatusTypeId && ($scope.individualLicense.currentLicenseStatusTypeId == 14 || $scope.individualLicense.currentLicenseStatusTypeId == 16 || $scope.individualLicense.currentLicenseStatusTypeId == 12)
            //     && ($scope.individualLicense.LicenseStatusTypeId == 1 || $scope.individualLicense.LicenseStatusTypeId == 7 || $scope.individualLicense.LicenseStatusTypeId == 8)) {
            //     $scope.feeDetails.isLate = false;
            //     $scope.feeDetails.lateFee = 0;
            // } else {
            if (((!$scope.feeDetails.isMilitaryExempt) && dateToCompare >= $scope.feeDetails.lateFeeLastDate)) {
                var differenceInMonths = utilityService.getDatesDiff(dateToCompare, $scope.feeDetails.lateFeeLastDate, 'm');
                //console.log("Number of months", differenceInMonths);
                $scope.feeDetails.differenceInMonths = differenceInMonths;
                $scope.feeDetails.isLate = true;
                $scope.feeDetails.lateFee = 250;// ($scope.feeDetails.lateFeeAmountPerMonth * $scope.feeDetails.differenceInMonths);
                // $scope.feeDetails.lateFee = ($scope.feeDetails.lateFee > $scope.feeDetails.maxLateFeeAmount) ? $scope.feeDetails.maxLateFeeAmount : $scope.feeDetails.lateFee;
            } else {
                $scope.feeDetails.isLate = false;
                $scope.feeDetails.lateFee = 0;
            }
            // }
            $scope.feeDetails.totalFee = $scope.feeDetails.applicationFee + $scope.feeDetails.lateFee;
        };


        var validateForm = function (currentForm) {
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                // console.log(currentForm.errorMessages);
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.feeDetailsSave = function (currentForm) {
            if (!validateForm(currentForm)) {
                initialApplicationstatusService.setFeeDetails("invalid");
                HideLoader();
                return;
            } else {
                initialApplicationstatusService.setFeeDetails("success");
            }
        };
        $scope.$on("SaveRenewal", function (event, args) {
            $scope.feeDetailsSave($scope.currentForm);
        });

        var getContent = function () {
            contentService.getContentByContentItemLkIdList([27], sessionStorage.Key)
                .then(function (response) {
                    if (response.Status) {
                        _.filter(response.ContentItemLk, function (item) {
                            if (item.ContentItemLkId == 27)
                                $scope.content27 = item.ContentItemLkDesc;
                        });
                    }
                }, function (error) {

                });
        }
        getContent();

        $scope.init();
    }
})();
