(function () {
    'use strict'

    angular.module('app.core')
    .directive('activityDescriptionsValidation', [ActivityDescriptionsValidation]);

    function ActivityDescriptionsValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function () {
                    var activitiesAnswered = $scope.activitiesPrompts.select('response').every(function (res) { return !!res.Desc; });
                    var questionAnswered = typeof($scope.researchQuestion.response.ContentItemResponse) !== 'undefined';
                    var researchAnswered = true;
                    if ($scope.researchQuestion.response.ContentItemResponse) {
                        var researchAnswered = $scope.researchPrompts.select('response').every(function (res) { return !!res.Desc; });
                    }

                    return activitiesAnswered && questionAnswered && researchAnswered;
                };
            }
        };
    }
})();