(function () {
    'use strict'

    angular.module('app.backofficeEntity')
        .directive('cashiering', cashieringDirective);

    cashieringDirective.$inject = [];

    function cashieringDirective() {
        return {
            restrict: 'E',
            scope: {
                entityType: "=",
                boardInfoId: "=?",
                configName: "=",
                showHeaderPanel: '=?',
                showSubmitPanel: '=?',
                isBackoffice: '=?'
            },
            templateUrl: 'app/components/backoffice/entity/cashiering/cashiering.html',
            controller: 'CashieringController'
        };
    }
})();