(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintChangeLogValidation', [complaintChangeLogValidation]);

    function complaintChangeLogValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.setForm = function (form, formType) {
                    if (!$scope.forms) {
                        $scope.forms = {};
                    }

                    $scope.forms[formType] = form;
                };

                $scope.validateForm = function (formType) {
                    var valid = true;
                    
                    $scope.hideStatusMsg();
                    if (!!$scope.forms && !!$scope.forms[formType] && $scope.forms[formType].errorMessages.length > 0) {
                        $scope.showStatusMsg('-', $scope.forms[formType].errorMessages);
                        valid = false;
                    }
                    
                    return valid;
                }
            },
        };
    }
})();