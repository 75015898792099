(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeIndividualAddressController", BackofficeIndividualAddressController);

    BackofficeIndividualAddressController.$inject = ["$rootScope", "$scope", "$state",
        "backofficeIndividualAdressService", "countryStateService", "typeValuesService", "$q", "$exceptionHandler",
        "configurationService", "utilityService", "SettingsValue", "TypeValue", "AppConfig", "WebApiUrl",
        "backofficeIndividualLicenseService", "backofficeIndividualDEAService", "Messages", "dcaIService",
        "$uibModal"
    ];

    function BackofficeIndividualAddressController($rootScope, $scope, $state,
        backofficeIndividualAdressService, countryStateService, typeValuesService, $q, $exceptionHandler,
        configurationService, utilityService, SettingsValue, TypeValue, AppConfig, WebApiUrl,
        backofficeIndividualLicenseService, backofficeIndividualDEAService, Messages, dcaIService,
        $uibModal) {

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "address" || $rootScope.individualCurrentTab.name == "personal") {
                $scope.IndividualId = individualId;
                ShowLoader();
                $rootScope.headerAddrees = {}
                if (!$scope.isLoadedOnce) {
                    $scope.getInitData($rootScope.DefaultCountryId);
                    $scope.isLoadedOnce = true;
                } else {
                    $scope.isDataLoaded = false;
                    $scope.loadAddressAndContacts($scope.IndividualId);
                }
                $rootScope.individualCurrentTab.isLoadedOnce = true;
                $scope.individualLicenseDetailBYIndividualId($scope.IndividualId);
                $scope.getDEA($scope.IndividualId);
            }
        });

        //Common starts here
        $scope.viewConfig = {};

        $scope.isAddressOrContactEdit = false;

        $scope.getInitData = function (countryID) {
            var dataPromises = [];

            dataPromises.push(configurationService.configurationGetbySettings(sessionStorage.Key, SettingsValue.ShowAddressandContactSeparately));
            dataPromises.push(countryStateService.getStateByCountryID(sessionStorage.Key, countryID));
            dataPromises.push(typeValuesService.getTypeValuesByTypeName(TypeValue.Address, sessionStorage.Key));
            dataPromises.push(typeValuesService.getTypeValuesByTypeName(TypeValue.Contact, sessionStorage.Key));
            dataPromises.push($scope.individualAddressBYIndividualId($scope.IndividualId, true));
            dataPromises.push($scope.individualContactBYIndividualId($scope.IndividualId, true));
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=city")));
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=zip")));

            $q.all(dataPromises)
                .then(function (response) {
                    angular.forEach(response, function (responseItem, itemIndex) {
                        if ($scope.checkServerResponse(responseItem)) {
                            if (itemIndex == 0) {
                                if (responseItem.ConfigurationList.length > 0) {
                                    var settingValue = responseItem.ConfigurationList[0].Value;
                                    if (settingValue.toLowerCase() == "true") {
                                        $scope.showAddressandContactSeparately = true;
                                    } else if (settingValue.toLowerCase() == "false") {
                                        $scope.showAddressandContactSeparately = false;
                                    }
                                }
                            } else if (itemIndex == 1) {
                                $scope.StatesData = responseItem.State;
                            } else if (itemIndex == 2) {
                                $scope.AddressTypeList = [];
                                if ((!!AppConfig.Source) && AppConfig.Source == 'NVDENTAL') {
                                    angular.forEach(responseItem.AddressTypeGetList, function (addressType) {
                                        if (addressType.AddressTypeId == 1 || addressType.AddressTypeId == 2 || addressType.AddressTypeId == 4 || addressType.AddressTypeId == 10 || addressType.AddressTypeId == 11)
                                            $scope.AddressTypeList.push(addressType);
                                    });
                                } else {
                                    $scope.AddressTypeList = responseItem.AddressTypeGetList;
                                }
                            } else if (itemIndex == 3) {
                                $scope.ContactTypeList = responseItem.ContactTypeGetList;
                            } else if (itemIndex == 4) {
                                processAddressData(responseItem.IndividualAddressResponse)
                            } else if (itemIndex == 5) {
                                processContactsData(responseItem.IndividualContactResponse)
                            } else if (itemIndex == 6) {
                                $scope.CityList = responseItem.ReferenceEntities;
                            } else if (itemIndex == 7) {
                                $scope.ZipList = responseItem.ReferenceEntities;
                            }
                        }
                    });
                    countryStateService.countryGetAll(sessionStorage.Key).then(function (response) {
                        if (!response.Status) {
                            $scope.showStatusMessage(response.Message, "error");
                        } else {
                            $scope.CountryList = response.Country;
                        }
                    }, function (error) {
                        $scope.showStatusMessage(error, "error");
                    });
                    if (!$scope.showAddressandContactSeparately) {
                        assignAddressByAddressTypeId();
                    }
                    $scope.isDataLoaded = true;
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                });
        };

        $scope.loadCityTypeahed = function (searchString, stateCode) {
            var cityList = [];

            if (!!$scope.CityList && stateCode == 'CA') {
                cityList = $scope.CityList.filter(function (item) {
                    if (!!item.CityName && item.CityName.toLowerCase() == searchString.toLowerCase()) {
                        return item;
                    }
                });

                if (cityList.length == 1) {
                    return cityList;
                }

                cityList = $scope.CityList.filter(function (item) {
                    if (!!item.CityName && item.CityName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) {
                        return item;
                    }
                });
            }

            return cityList;
        };

        $scope.loadZipRange = function (stateCode) {
            if (!!$scope.ZipList) {
                $scope.zipRange = [];
                var zipItem = $scope.ZipList.whereEquals(stateCode, 'StateCd').firstOrDefault();
                if (!!zipItem) {
                    for (var i = parseInt(zipItem.LowZip); i < parseInt(zipItem.HighZip); i++) {
                        $scope.zipRange.push({
                            Zip: i.toString().padStart(5, 0)
                        });
                    }
                }
                return $scope.zipRange;
            }
        };

        $scope.loadZipTypeahed = function (searchString, stateCode) {
            var zipRange = $scope.loadZipRange(stateCode);
            if (zipRange.length > 0) {
                return zipRange.filter(function (item) {
                    if (!!zipRange && item.Zip.startsWith(searchString)) {
                        return item;
                    }
                });
            }
        };

        var isAddressOrContactUpdated = function (currentItem, originalItem, isAddress) {
            if (!!$scope.showAddressandContactSeparately) {
                var fieldsToCompare;
                if (!!isAddress) {
                    fieldsToCompare = ['AddressTypeId', 'StreetLine1', 'StreetLine2', 'City', 'StateCode', 'CountryId', 'Zip', 'BeginDate', 'EndDate', 'IsMailingSameasPhysical'];
                } else {
                    fieldsToCompare = ['ContactTypeId', 'ContactInfo', 'IsPreferredContact'];
                }
                var isfieldsUpdated = false;
                angular.forEach(fieldsToCompare, function (fieldToCompare) {
                    if (angular.isDate(currentItem[fieldToCompare]) && angular.isDate(originalItem[fieldToCompare])) {
                        if (!utilityService.compareDateswithoutTime(currentItem[fieldToCompare], originalItem[fieldToCompare])) {
                            isfieldsUpdated = true;
                        }
                    } else {
                        if (currentItem[fieldToCompare] != originalItem[fieldToCompare]) {
                            isfieldsUpdated = true;
                        }
                    }
                });
                return isfieldsUpdated;
            } else {
                var fieldsToCompare = ['AddressTypeId', 'StreetLine1', 'StreetLine2', 'City', 'StateCode', 'Zip', 'BeginDate', 'EndDate'];
                var isfieldsUpdated = false;
                angular.forEach(fieldsToCompare, function (fieldToCompare) {
                    if (angular.isDate(currentItem[fieldToCompare]) && angular.isDate(originalItem[fieldToCompare])) {
                        if (!utilityService.compareDateswithoutTime(currentItem[fieldToCompare], originalItem[fieldToCompare])) {
                            isfieldsUpdated = true;
                        }
                    } else {
                        if (currentItem[fieldToCompare] != originalItem[fieldToCompare]) {
                            isfieldsUpdated = true;
                        }
                    }
                });
                if (currentItem.AddressTypeId == 10 | currentItem.AddressTypeId == 11) {
                    if ((currentItem.Addressee != originalItem.Addressee) ||
                        (currentItem.OfficePhoneContact.ContactInfo != originalItem.OfficePhoneContact.ContactInfo) ||
                        (currentItem.OfficeFaxContact.ContactInfo != originalItem.OfficeFaxContact.ContactInfo)
                    ) {
                        isfieldsUpdated = true;
                    }
                } else if (currentItem.AddressTypeId == 2) {
                    if ((currentItem.CellPhoneContact.ContactInfo != originalItem.CellPhoneContact.ContactInfo) ||
                        (currentItem.HomePhoneContact.ContactInfo != originalItem.HomePhoneContact.ContactInfo) ||
                        (currentItem.EmailAddressContact.ContactInfo != originalItem.EmailAddressContact.ContactInfo) ||
                        (currentItem.FaxContact.ContactInfo != originalItem.FaxContact.ContactInfo)
                    ) {
                        isfieldsUpdated = true;
                    }
                }
                if (currentItem.AddressTypeId == 10 || currentItem.AddressTypeId == 2) {
                    if (currentItem.IsMailingSameasPhysical != originalItem.IsMailingSameasPhysical) {
                        isfieldsUpdated = true;
                    }
                }

                return isfieldsUpdated;
            }
        };

        var getAddressOrContactActionFlag = function (isEdit, currentItem, originalItem, isAddress) {
            var actionFlag = '';
            if (!!$scope.showAddressandContactSeparately) {
                if (!isEdit) {
                    actionFlag = 'UI'
                } else {
                    if ((isAddress && (currentItem.AdressStatusId == originalItem.AdressStatusId)) || (!isAddress && (currentItem.ContactStatusId == originalItem.ContactStatusId))) {
                        if (isAddressOrContactUpdated(currentItem, originalItem, isAddress)) {
                            actionFlag = "UI";
                        } else {
                            actionFlag = "U";
                        }
                    } else {
                        if (isAddressOrContactUpdated(currentItem, originalItem, isAddress)) {
                            if (currentItem.AdressStatusId == !1) {
                                actionFlag = "UIS";
                            }
                            else {
                                actionFlag = "U";
                            }
                        } else {
                            actionFlag = "U";
                        }
                    }
                }
            } else {
                if (!isEdit) {
                    actionFlag = 'UI'
                } else {
                    if (currentItem.AdressStatusId == originalItem.AdressStatusId) {
                        // if (isAddressOrContactUpdated(currentItem, originalItem)) {
                        //     actionFlag = "UI";
                        // } else {
                        //     actionFlag = "U";
                        // }
                        actionFlag = "U";
                    } else {
                        // if (isAddressOrContactUpdated(currentItem, originalItem)) {
                        //     actionFlag = "UIS";
                        // } else {
                        //     actionFlag = "U";
                        // }
                        actionFlag = "U";
                    }
                }
            }
            return actionFlag;
        };

        $scope.loadAddressAndContacts = function (individualId) {
            var dataPromises = [];
            dataPromises.push($scope.individualAddressBYIndividualId(individualId, true));
            dataPromises.push($scope.individualContactBYIndividualId(individualId, true));
            $q.all(dataPromises)
                .then(function (response) {
                    angular.forEach(response, function (responseItem, itemIndex) {
                        if ($scope.checkServerResponse(responseItem)) {
                            if (itemIndex == 0) {
                                processAddressData(responseItem.IndividualAddressResponse)
                            } else if (itemIndex == 1) {
                                processContactsData(responseItem.IndividualContactResponse)
                            }
                        }
                    });
                    if (!$scope.showAddressandContactSeparately) {
                        assignAddressByAddressTypeId();
                    }
                    $scope.isDataLoaded = true;
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                });
        };
        //common ends here

        //Seperate Contact starts here
        $scope.ContactInputType = "Email";
        $scope.validateEmail = false;
        $scope.validateUrl = false;
        $scope.validatePhone = false;
        $scope.viewConfig.showContactList = true;
        $scope.ContactStatuses = [
            { ContactStatusId: 1, ContactStatusName: 'Current' },
            { ContactStatusId: 2, ContactStatusName: 'Previous' },
            { ContactStatusId: 3, ContactStatusName: 'Other' },
            { ContactStatusId: 4, ContactStatusName: 'Pending' }
        ];

        $scope.openContactAddNew = function (contactForm) {
            contactForm.$setPristine();
            $scope.clearContact();
            $scope.viewConfig.showContactList = false;
            $scope.contact.isEdit = false;
            $scope.originalContact = angular.copy($scope.contact);
        };

        $scope.setContactTypeValidation = function (contacTypeId, keepContactValue) {
            var contactTypeDesc = "";

            angular.forEach($scope.ContactTypeList, function (contactType, index) {
                if (contactType.ContactTypeId == contacTypeId) {
                    contactTypeDesc = contactType.Desc;
                }
            });
            $scope.validateEmail = false;
            $scope.validateUrl = false;
            $scope.validatePhone = false;
            if (!keepContactValue) {
                $scope.contact.ContactInfoEmail = "";
                $scope.contact.ContactInfoUrl = "";
                $scope.contact.ContactInfoPhone = "";
            }
            if (contactTypeDesc.indexOf("Email") >= 0) {
                $scope.ContactInputType = "Email";
                $scope.validateEmail = true;
                if (keepContactValue)
                    $scope.contact.ContactInfoEmail = $scope.contact.ContactInfo;
            } else if (contactTypeDesc.indexOf("Website") >= 0) {
                $scope.ContactInputType = "Url";
                $scope.validateUrl = true;
                if (keepContactValue)
                    $scope.contact.ContactInfoUrl = $scope.contact.ContactInfo;
            } else {
                $scope.ContactInputType = "Phone";
                $scope.validatePhone = true;
                if (keepContactValue)
                    $scope.contact.ContactInfoPhone = $scope.contact.ContactInfo;
            }
        };

        $scope.editContact = function (contact) {
            $scope.contact = angular.copy(contact);
            $scope.setContactTypeValidation($scope.contact.ContactTypeId, true);
            $scope.viewConfig.showContactList = false;
            $scope.originalContact = angular.copy(contact);
            $scope.contact.isEdit = true;
        };

        $scope.deleteContact = function (contact) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                $rootScope.individualEventLog('deleteStatus.deleteConfirmed', $scope.IndividualId);
                ShowLoader("Please wait...");
                contact.ActionFlag = 'U';
                contact.ContactStatusId = 2;
                // contact.IsDeleted = true;
                backofficeIndividualAdressService.addressContactSave([contact], sessionStorage.Key)
                    .then(function (response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse(response)) {
                            $scope.individualContactBYIndividualId($scope.IndividualId);
                            $scope.clearContact();
                            $scope.viewConfig.showContactList = true;
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            }
            else {
                $rootScope.individualEventLog('deleteStatus.deleteCanceled', $scope.IndividualId);
            }
        };

        $scope.clearContact = function () {
            $scope.contact = {};
        };

        var checkIfSameContactExists = function (contact) {
            var isContactAlreadyExists = false;
            angular.forEach($scope.personalContact, function (contactItem, index) {
                if ((contact.ContactTypeId == contactItem.ContactTypeId) &&
                    (contact.IndividualContactId != contactItem.IndividualContactId) &&
                    //(contact.ContactStatusId != contactItem.ContactStatusId) &&
                    (contact.ContactInfo == contactItem.ContactInfo)) {
                    isContactAlreadyExists = true;
                }
            });
            return isContactAlreadyExists;
        };
        var checkIfCurrentContactExists = function (contact) {
            var isContactAlreadyExists = false;
            angular.forEach($scope.personalContact, function (contactItem, index) {
                if ((contact.ContactTypeId == contactItem.ContactTypeId) &&
                    (contact.ContactId != contactItem.ContactId) &&
                    (contact.ContactStatusId == 1) && contactItem.ContactStatusId == 1) {
                    isContactAlreadyExists = true;
                }
            });
            return isContactAlreadyExists;
        };

        $scope.addressContactSave = function (contact, addressContactForm) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                addressContactForm.$submitted = true;
                if ($scope.validateData(addressContactForm)) {

                    if ($scope.ContactInputType == "Email") {
                        contact.ContactInfo = $scope.contact.ContactInfoEmail;
                    }
                    if ($scope.ContactInputType == "Url") {
                        contact.ContactInfo = $scope.contact.ContactInfoUrl;
                    }
                    if ($scope.ContactInputType == "Phone") {
                        contact.ContactInfo = $scope.contact.ContactInfoPhone;
                    }
                    ShowLoader("Please wait...");
                    // if (!checkIfSameContactExists(contact)) {
                    if (!checkIfCurrentContactExists(contact)) {

                        contact.IndividualId = $scope.IndividualId;
                        contact.IsActive = true;
                        contact.ActionFlag = getAddressOrContactActionFlag(contact.isEdit, contact, $scope.originalContact, false);
                        backofficeIndividualAdressService.addressContactSave([contact], sessionStorage.Key)
                            .then(function (response) {
                                $scope.hideStatusMessage();
                                $scope.hideValidationSummary();
                                if ($scope.checkServerResponse(response)) {
                                    if (contact.ContactTypeId == "8") {
                                        var requestData = {
                                            EntityId: $scope.IndividualId,
                                            IndividualContactId: response.RecordId
                                        };
                                        $scope.updateIServiceData(requestData, "Email");
                                    } else if (contact.ContactTypeId == "1" || contact.ContactTypeId == "2") {
                                        var requestData = {
                                            EntityId: $scope.IndividualId,
                                            IndividualContactId: response.RecordId
                                        };
                                        $scope.updateIServiceData(requestData, "Phone");
                                    }
                                    $scope.individualContactBYIndividualId($scope.IndividualId);
                                    $scope.showStatusMessage(response.Message, "success");
                                    $scope.clearContact();
                                    $scope.viewConfig.showContactList = true;
                                    $scope.isAddressOrContactEdit = false;
                                }
                                HideLoader();
                            }, function (data) {
                                HideLoader();
                                if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                            });
                    }
                    else {
                        $scope.showStatusMessage(Messages.General.Errors.SameContact, "error");
                        HideLoader();
                        $(document).scrollTop(0);
                    }
                    // }
                    // else {
                    //     $(document).scrollTop(0);
                    //     $scope.showStatusMessage(Messages.General.Errors.SameContact, "error");
                    //     HideLoader();
                    // }
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        var processContactsData = function (contactsData) {
            if (!!contactsData && contactsData.length != 0) {
                angular.forEach(contactsData, function (contact, index) {
                    angular.forEach($scope.ContactTypeList, function (contactType, index) {
                        if (contactType.ContactTypeId == contact.ContactTypeId) {
                            contact.Desc = contactType.Desc;
                        }
                    });
                    angular.forEach($scope.ContactStatuses, function (contactStatus, index) {
                        if (contact.ContactStatusId == contactStatus.ContactStatusId) {
                            contact.ContactStatusName = contactStatus.ContactStatusName;
                        }
                    });
                    if (!$rootScope.headerAddrees) {
                        $rootScope.headerAddrees = {}
                    }
                    if (contact.ContactTypeId == 8 && contact.ContactStatusId == 1) {
                        $rootScope.headerAddrees.showEmail = angular.copy(contact.ContactInfo);
                        sessionStorage.currentUserEmail = $rootScope.headerAddrees.showEmail;
                    }
                    if (contact.ContactTypeId == 3 && contact.ContactStatusId == 1) {
                        $rootScope.headerAddrees.showPhone = angular.copy(contact.ContactInfo);
                    }
                    if (contact.ContactTypeId == 2 && contact.ContactStatusId == 1) {
                        $rootScope.headerAddrees.showHomePhone = angular.copy(contact.ContactInfo);
                    }
                    if (contact.ContactTypeId == 1 && contact.ContactStatusId == 1) {
                        $rootScope.headerAddrees.showHomePhone = angular.copy(contact.ContactInfo);
                    }
                });
            }
            $scope.contactsData = contactsData;
            $scope.personalContact = contactsData;
        };

        $scope.individualContactBYIndividualId = function (IndividualId, processDataExternally) {
            try {
                var contactDataPromises = backofficeIndividualAdressService.individualContactBYIndividualId(sessionStorage.Key, IndividualId);
                contactDataPromises.then(function (response) {
                    if (!processDataExternally) {
                        var contactsData = response.IndividualContactResponse;
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse(response)) {
                            processContactsData(contactsData);
                        }
                    }
                }, function (data) {
                    $scope.showStatusMessage(data, "error");
                });
                return contactDataPromises;
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        //Seperate Contact ends here

        //Seperate Address starts here
        $scope.viewConfig.showAddressList = true;
        $scope.AddressStatuses = [
            { AddressStatusId: 1, AddressStatusName: 'Current' },
            { AddressStatusId: 2, AddressStatusName: 'Previous' },
            // { AddressStatusId: 3, AddressStatusName: 'Other' },
            // { AddressStatusId: 4, AddressStatusName: 'Pending' }
        ];
        $scope.StatesDataMilitary = [
            { StateCode: 'AA', Name: 'AA' },
            { StateCode: 'AE', Name: 'AE' },
            { StateCode: 'AP', Name: 'AP' }
        ];

        $scope.datePicker = {
            beginDateOpened: false,
            endDateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            },
            startDateOptions: {
                maxDate: null
            },
            endDateOptions: {
                minDate: null
            },
            beginDateOpened2: false,
            endDateOpened2: false,
            startDateOptions2: {
                maxDate: null
            },
            endDateOptions2: {
                minDate: null
            }
        };

        $scope.setAddressEndMinDate = function (address, val) {
            if (!!address.BeginDate && angular.isDate(address.BeginDate)) {
                if (val == 1) {
                    $scope.datePicker.endDateOptions.minDate = address.BeginDate;
                }
                else {
                    $scope.datePicker.endDateOptions2.minDate = address.BeginDate;

                }
            }
        };
        $scope.setAddressStartMaxDate = function (address, val) {
            if (!!address.EndDate && angular.isDate(address.EndDate)) {
                if (val == 1)
                    $scope.datePicker.startDateOptions.maxDate = address.EndDate;
            }
            else {
                $scope.datePicker.startDateOptions2.maxDate = address.EndDate;
            }
        };

        $scope.getStateByCountryID = function (CountryID) {
            try {
                countryStateService.getStateByCountryID(sessionStorage.Key, CountryID)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.StatesData = response.State;
                        }
                    }, function (data) {
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        var processAddressData = function (addressData) {

            if (addressData != null) {
                angular.forEach(addressData, function (address, addressKey) {
                    angular.forEach($scope.AddressTypeList, function (addressType, index) {
                        if (addressType.AddressTypeId == address.AddressTypeId) {
                            address.AddressTypeDesc = addressType.AddressTypeDesc;
                        }
                    });

                    if (!address.MilitaryAddress) {
                        angular.forEach($scope.StatesData, function (state, index) {
                            if (state.StateCode == address.StateCode) {
                                address.State = state.Name;
                            }
                        });
                    } else {
                        angular.forEach($scope.StatesDataMilitary, function (state, index) {
                            if (state.StateCode == address.StateCode) {
                                address.State = state.Name;
                            }
                        });
                    }

                    angular.forEach($scope.AddressStatuses, function (addressStatus, index) {
                        if (address.AdressStatusId == addressStatus.AddressStatusId) {
                            address.AddressStatusName = addressStatus.AddressStatusName;
                        }
                    });
                    if (!$rootScope.headerAddrees) {
                        $rootScope.headerAddrees = {};
                    }
                    if (address.AddressTypeId == 2 && address.AdressStatusId == 1 && address.IsMailingSameasPhysical) {
                        $rootScope.headerAddrees.showAddress = address.StreetLine1 + ' ' + address.StreetLine2;
                        $rootScope.headerAddrees.showAddress2 = address.City + ',' + address.StateCode + ', ' + address.Zip;
                    }



                    if (address.AddressTypeId == 5 && address.IsMailingSameasPhysical && address.AdressStatusId == 1) {
                        $rootScope.headerAddrees.showAddress = address.StreetLine1 + ' ' + address.StreetLine2;
                        $rootScope.headerAddrees.showAddress2 = address.City + ',' + address.StateCode + ', ' + address.Zip;

                    }
                })
            }
            // debugger
            $scope.AddressData = addressData;
            $scope.personalAddress = addressData;

            // $scope.personalData.CombinedAddresses2 = [];
            // $scope.personalData.CombinedAddresses1 = [];

            // angular.forEach($scope.personalData.CombinedAddresses, function (item) {
            //     if (item.AdressStatusId == 1) {
            //         $scope.personalData.CombinedAddresses1.push(item);
            //     } else {
            //         $scope.personalData.CombinedAddresses2.push(item);
            //     }
            // });
        };

        $scope.individualAddressBYIndividualId = function (IndividualId, processDataExternally) {
            try {
                var addressDataPromise = backofficeIndividualAdressService.individualAddressBYIndividualId(sessionStorage.Key, IndividualId);
                addressDataPromise.then(function (response) {
                    if (!processDataExternally) {
                        var addressData = response.IndividualAddressResponse;
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse(response)) {
                            processAddressData(addressData);
                        }
                    }
                }, function (data) {
                    $scope.showStatusMessage(data, "error");
                });
                return addressDataPromise;
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        $scope.openConfirmationPopup = function (addressForm) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/backoffice/individual/address/address-exists-confirmation.html',
                size: 'md',
                resolve: {
                },
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    $scope.onCancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.onConfirm = function () {
                        $uibModalInstance.close('success');
                    };
                }]
            });

            modalInstance.result.then(function () {
                $scope.openAddressAddNew(addressForm);
            });
        };

        $scope.openAddressAddNew = function (addressForm) {
            addressForm.$setPristine();
            $scope.clearAddress();
            $scope.viewConfig.showAddressList = false;
            $scope.address.BeginDate = new Date();
            $scope.setAddressStartMaxDate($scope.address);
            $scope.setAddressEndMinDate($scope.address);
            $scope.address.isEdit = false;
            $scope.address.CountryId = $rootScope.DefaultCountryId;
            $scope.address.StateCode = 'CA';
            $scope.address.AddressTypeId = 1;
            $scope.loadZipRange('CA');
            $scope.originalAddress = angular.copy($scope.address);
        };

        $scope.openAddressAddNewConfirmation = function (addressForm) {
            //check if one address with current status exists
            $scope.currentAddress = $scope.AddressData.whereEquals(1, 'AdressStatusId');
            if (!!$scope.currentAddress && $scope.currentAddress.length > 0) {
                $scope.openConfirmationPopup(addressForm);
            } else {
                $scope.openAddressAddNew(addressForm)
            }
        }

        $scope.editAddress = function (address, IndividualAddressId) {
            $scope.currentAddress = [address];

            $scope.viewConfig.showAddressList = false;
            if (!!address.BeginDate && !angular.isDate(address.BeginDate))
                address.BeginDate = new Date(address.BeginDate);
            if (!!address.EndDate && !angular.isDate(address.EndDate))
                address.EndDate = new Date(address.EndDate);
            if (address.AdressStatusId === 0)
                address.AdressStatusId = "";
            $scope.setAddressStartMaxDate(address);
            $scope.setAddressEndMinDate(address);
            if (!!address.CountryId)
                $scope.getStateByCountryID(address.CountryId);
            $scope.address = angular.copy(address);
            $scope.originalAddress = angular.copy(address);
            $scope.address.isEdit = true;

            if ($scope.originalAddress.LicenseNumber)
                $scope.licenseNumberChanged($scope.originalAddress.LicenseNumber);
        };

        $scope.updateIServiceData = function (requestData, serviceType) {
            var dataPromise = null;
            if (serviceType == "Phone") {
                dataPromise = dcaIService.updatePhone(sessionStorage.Key, requestData);
            } else if (serviceType == "Email") {
                dataPromise = dcaIService.updateEmail(sessionStorage.Key, requestData);
            } else if (serviceType == "Address") {
                dataPromise = dcaIService.updateAddress(sessionStorage.Key, requestData);
            }

            dataPromise
                .then(function (responseUpdate) {
                    if (!responseUpdate.Status) {

                    }
                }, function (err) {
                });
        };

        $scope.updateAddressStatus = function () {
            var defer = $q.defer();

            if (!!$scope.currentAddress && $scope.currentAddress.length > 0) {
                angular.forEach($scope.currentAddress, function (currentAddressItem) {
                    currentAddressItem.AdressStatusId = 2;
                });
                backofficeIndividualAdressService.addressSave($scope.currentAddress, sessionStorage.Key)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            // angular.forEach($scope.currentAddress, function (address) {
                            //     if (address.AddressTypeId == "1" || address.AddressTypeId == "2") {
                            //         var requestData = {
                            //             EntityId: $scope.IndividualId,
                            //             IndividualAddressId: response.RecordId
                            //         };
                            //         $scope.updateIServiceData(requestData, "Address");
                            //     }
                            // });
                            $scope.individualAddressBYIndividualId($scope.IndividualId);
                            defer.resolve({
                                Status: true
                            });
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        defer.reject({
                            Status: false
                        });
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            }
            return defer.promise;
        }

        $scope.saveAddress = function (address, addressForm) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                addressForm.$submitted = true;
                if (validateForm(addressForm) && $scope.validateData(addressForm)) {
                    ShowLoader("Please wait...");

                    if (address.AdressStatusId == 1) {
                        $scope.updateAddressStatus();
                    }

                    address.IndividualId = $scope.IndividualId;
                    address.IsActive = true;

                    var filterLicense = _.find($scope.IndividualLicenseList, function (o) { return o.LicenseNumber == address.LicenseNumber; });
                    if (filterLicense) {
                        address.IndividualLicenseId = filterLicense.IndividualLicenseId;
                    }

                    var filterdea = _.find($scope.deaList, function (o) { return o.LicenseNumber == address.LicenseNumber; });
                    if (!filterdea && address.DEA && address.LicenseNumber) {
                        var asoosicateLicense = _.find($scope.IndividualLicenseList, function (o) { return o.LicenseNumber == address.LicenseNumber; });
                        if (asoosicateLicense) {
                            var DAE = _.find($scope.deaList, function (o) { return o.IndividualLicenseAddInfoId == address.DEA; });
                            if (DAE) {
                                DAE.IndividualLicenseId = asoosicateLicense.IndividualLicenseId;
                                DAE.LicenseNumber = asoosicateLicense.LicenseNumber;
                                saveIndividualLicenseAddInfo(DAE);
                            }
                        }
                        //  address.IndividualLicenseId = filterdea.IndividualLicenseId;
                    }

                    address.ActionFlag = getAddressOrContactActionFlag(address.isEdit, address, $scope.originalAddress, true);
                    backofficeIndividualAdressService.addressSave([address], sessionStorage.Key)
                        .then(function (response) {
                            if ($scope.checkServerResponse(response)) {
                                if (address.AddressTypeId == "1" || address.AddressTypeId == "2") {
                                    var requestData = {
                                        EntityId: $scope.IndividualId,
                                        IndividualAddressId: response.RecordId
                                    };
                                    $scope.updateIServiceData(requestData, "Address");
                                }
                                $scope.individualAddressBYIndividualId($scope.IndividualId);
                                $scope.clearAddress();
                                $scope.viewConfig.showAddressList = true;
                                $scope.showStatusMessage(response.Message, "success");
                                $scope.isAddressOrContactEdit = false;
                            }
                            HideLoader();
                        }, function (data) {
                            HideLoader();
                            if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                        });
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        var saveIndividualLicenseAddInfo = function (dea) {
            backofficeIndividualDEAService.saveIndividualLicenseAddInfo(sessionStorage.Key, [dea])
                .then(function (response) {
                    $scope.getDEA($scope.IndividualId);
                    $scope.DEAFormShow = false;
                    $scope.adddeabutton = true;
                    HideLoader();
                });
        }

        $scope.deleteAddress = function (address) {
            $scope.IndividualAddressContactAddStatus = true;
            if (confirm(Messages.General.Confirm.Delete)) {
                $rootScope.individualEventLog('deleteStatus.deleteConfirmed', $scope.IndividualId);
                address.IsActive = true;
                // address.IsDeleted = true;
                address.AdressStatusId = 2;
                address.ActionFlag = 'U';
                ShowLoader("Please wait...");
                backofficeIndividualAdressService.addressSave([address], sessionStorage.Key)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.individualAddressBYIndividualId($scope.IndividualId);
                            $scope.showStatusMessage(response.Message, "success");
                            $scope.clearAddress();
                            $scope.viewConfig.showAddressList = true;
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            }
            else {
                $rootScope.individualEventLog('deleteStatus.deleteCanceled', $scope.IndividualId);
            }
        };

        $scope.clearAddress = function () {
            $scope.address = {};
        };

        //Seperate Address  ends here

        //Combined Address and Contact starts here
        $scope.viewConfig.showAddCombinedAddressForm = false;
        $scope.personalData = {};
        $scope.natValidation = {};
        $scope.natValidation2 = {};
        $scope.maskConfig = {
            clearOnBlur: false,
            allowInvalidValue: true,
            clearOnBlurPlaceholder: false
        };

        $scope.openAddEditCombinedAddressForm = function (isCombinedAddressEdit, combinedAddress, adressStatusId) {
            ShowLoader();
            if (adressStatusId == 1) {
                $scope.isCurrent = true;
            }
            else {
                $scope.isCurrent = false;
            }
            $scope.viewConfig.showAddCombinedAddressForm = true;
            $scope.isCombinedAddressEdit = isCombinedAddressEdit;
            createNewCombinedAddressAndContacts(isCombinedAddressEdit, combinedAddress, adressStatusId);
            $scope.setAddressStartMaxDate($scope.personalData.CombinedAddress);
            $scope.setAddressEndMinDate($scope.personalData.CombinedAddress);
            $scope.personalData.OriginalCombinedAddress = angular.copy($scope.personalData.CombinedAddress);

            HideLoader();
        };

        $scope.openAddEditCombinedAddressForm2 = function (isCombinedAddressEdit, combinedAddress, adressStatusId) {
            ShowLoader();
            if (adressStatusId == 1) {
                $scope.isCurrent = true;
            }
            else {
                $scope.isCurrent = false;
            }
            $scope.viewConfig.showAddCombinedAddressForm2 = true;
            $scope.isCombinedAddressEdit2 = isCombinedAddressEdit;
            createNewCombinedAddressAndContacts2(isCombinedAddressEdit, combinedAddress, adressStatusId);
            $scope.setAddressStartMaxDate($scope.personalData.CombinedAddress2);
            $scope.setAddressEndMinDate($scope.personalData.CombinedAddress2);
            $scope.personalData.OriginalCombinedAddress2 = angular.copy($scope.personalData.CombinedAddress2);
            HideLoader();
        };


        $scope.closeAddCombinedAddressForm = function () {
            ShowLoader();
            $scope.viewConfig.showAddCombinedAddressForm = false;
            $scope.currentAddressForm = null;
            HideLoader();
        };

        $scope.closeAddCombinedAddressForm2 = function () {
            ShowLoader();
            $scope.viewConfig.showAddCombinedAddressForm2 = false;
            $scope.currentAddressForm2 = null;
            HideLoader();
        };

        var createNewCombinedAddressAndContacts = function (isCombinedAddressEdit, combinedAddress, adressStatusId) {
            if (!isCombinedAddressEdit) {
                $scope.personalData.CombinedAddress = {
                    IndividualId: $scope.IndividualId,
                    AddressTypeId: "",
                    IsActive: true,
                    CountryId: $rootScope.DefaultCountryId,
                    BeginDate: new Date(),
                    AdressStatusId: adressStatusId
                };
            } else {
                if (!!combinedAddress.BeginDate && !angular.isDate(combinedAddress.BeginDate))
                    combinedAddress.BeginDate = new Date(combinedAddress.BeginDate);
                if (!!combinedAddress.EndDate && !angular.isDate(combinedAddress.EndDate))
                    combinedAddress.EndDate = new Date(combinedAddress.EndDate);
                $scope.personalData.CombinedAddress = angular.copy(combinedAddress);

            }
            if ($scope.personalData.CombinedAddress.CountryId) {
                $scope.getAddressStateByCountryID(null, $scope.personalData.CombinedAddress.CountryId, true);
            }

            $scope.personalData.CombinedAddress.CellPhoneContact = getNewContactIfNotExists($scope.personalData.CombinedAddress.CellPhoneContact, "C", 1);
            $scope.personalData.CombinedAddress.HomePhoneContact = getNewContactIfNotExists($scope.personalData.CombinedAddress.HomePhoneContact, "H", 2);
            $scope.personalData.CombinedAddress.OfficePhoneContact = getNewContactIfNotExists($scope.personalData.CombinedAddress.OfficePhoneContact, "W", 3);
            $scope.personalData.CombinedAddress.FaxContact = getNewContactIfNotExists($scope.personalData.CombinedAddress.FaxContact, "F", 5);
            $scope.personalData.CombinedAddress.EmailAddressContact = getNewContactIfNotExists($scope.personalData.CombinedAddress.EmailAddressContact, "E", 8);
            $scope.personalData.CombinedAddress.OfficeFaxContact = getNewContactIfNotExists($scope.personalData.CombinedAddress.OfficeFaxContact, "OF", 30);
        };

        var createNewCombinedAddressAndContacts2 = function (isCombinedAddressEdit, combinedAddress, adressStatusId) {
            if (!isCombinedAddressEdit) {
                $scope.personalData.CombinedAddress2 = {
                    IndividualId: $scope.IndividualId,
                    AddressTypeId: "",
                    IsActive: true,
                    CountryId: $rootScope.DefaultCountryId,
                    BeginDate: new Date(),
                    AdressStatusId: adressStatusId
                };
            } else {
                if (!!combinedAddress.BeginDate && !angular.isDate(combinedAddress.BeginDate))
                    combinedAddress.BeginDate = new Date(combinedAddress.BeginDate);
                if (!!combinedAddress.EndDate && !angular.isDate(combinedAddress.EndDate))
                    combinedAddress.EndDate = new Date(combinedAddress.EndDate);
                $scope.personalData.CombinedAddress2 = angular.copy(combinedAddress);

            }
            if ($scope.personalData.CombinedAddress2.CountryId) {
                $scope.getAddressStateByCountryID(null, $scope.personalData.CombinedAddress2.CountryId, false);
            }
            $scope.personalData.CombinedAddress2.CellPhoneContact = getNewContactIfNotExists($scope.personalData.CombinedAddress2.CellPhoneContact, "C", 1);
            $scope.personalData.CombinedAddress2.HomePhoneContact = getNewContactIfNotExists($scope.personalData.CombinedAddress2.HomePhoneContact, "H", 2);
            $scope.personalData.CombinedAddress2.OfficePhoneContact = getNewContactIfNotExists($scope.personalData.CombinedAddress2.OfficePhoneContact, "W", 3);
            $scope.personalData.CombinedAddress2.FaxContact = getNewContactIfNotExists($scope.personalData.CombinedAddress2.FaxContact, "F", 5);
            $scope.personalData.CombinedAddress2.EmailAddressContact = getNewContactIfNotExists($scope.personalData.CombinedAddress2.EmailAddressContact, "E", 8);
            $scope.personalData.CombinedAddress2.OfficeFaxContact = getNewContactIfNotExists($scope.personalData.CombinedAddress2.OfficeFaxContact, "OF", 30);
        };

        var getNewContactIfNotExists = function (contactItem, contactCode, contactTypeId) {
            if (!contactItem) {
                contactItem = getNewContact(contactCode, contactTypeId);
            }
            return contactItem;
        };

        var getNewContact = function (contactCode, contactTypeId) {
            var newContact = {
                BeginDate: "2013-08-20T00:00:00",
                Code: contactCode,
                ContactFirstName: "",
                ContactId: 0,
                ContactInfo: "",
                ContactLastName: "",
                ContactMiddleName: "",
                ContactTypeId: contactTypeId,
                DateContactValidated: null,
                EndDate: null,
                IndividualContactId: 0,
                IndividualId: $scope.IndividualId,
                IsActive: true,
                IsMobile: false,
                IsPreferredContact: false
            }
            return newContact;
        };




        var assignAddressByAddressTypeId = function () {
            $scope.personalData.CombinedAddresses = [];
            $scope.personalData.CombinedAddresses2 = [];
            $scope.personalData.CombinedAddresses1 = [];

            //var listMailingAddress = $.grep($scope.personalAddress, function (i) {
            //    return (i.AdressStatusId == 1 && i.IsMailingSameasPhysical && (i.AddressTypeId == 10 || i.AddressTypeId == 10));
            //});
            //var hasMailingAddress = (listMailingAddress && listMailingAddress.length > 0);

            angular.forEach($scope.personalAddress, function (addressOption, index) {
                angular.forEach($scope.personalContact, function (contactOption, index) {
                    if (addressOption.IndividualAddressId == contactOption.ReferenceNumber) {
                        if (addressOption.AddressTypeId == 10 || addressOption.AddressTypeId == 11) {
                            if (contactOption.ContactTypeId == "3") {
                                addressOption.OfficePhoneContact = contactOption;
                            }
                            if (contactOption.ContactTypeId == "30") {
                                addressOption.OfficeFaxContact = contactOption;
                            }
                        }
                        if (addressOption.AddressTypeId == 2) {
                            if (contactOption.ContactTypeId == "1") {
                                addressOption.CellPhoneContact = contactOption;
                            }
                            if (contactOption.ContactTypeId == "2") {
                                addressOption.HomePhoneContact = contactOption;
                            }
                            if (contactOption.ContactTypeId == "5") {
                                addressOption.FaxContact = contactOption;
                            }
                            if (contactOption.ContactTypeId == "8") {
                                addressOption.EmailAddressContact = contactOption;
                            }
                        }
                    }
                });
                if (addressOption.AdressStatusId == 1) {
                    $scope.personalData.CombinedAddresses1.push(addressOption);
                } else {
                    $scope.personalData.CombinedAddresses2.push(addressOption);
                }

                $rootScope.headerAddrees = {};

                _.each($scope.personalData.CombinedAddresses1, function (item) {
                    // /&& item.IsMailingSameasPhysical
                    if (item.AddressTypeId == 2 && item.AdressStatusId == 1 && item.IsMailingSameasPhysical) {
                        $rootScope.headerAddrees.showAddress = item.StreetLine1 + ' ' + item.StreetLine2;
                        $rootScope.headerAddrees.showAddress2 = item.City + ',' + item.StateCode + ', ' + item.Zip;

                        if (item.CellPhoneContact && item.CellPhoneContact.ContactInfo) {
                            $rootScope.headerAddrees.showPhone = angular.copy(item.CellPhoneContact.ContactInfo);
                        }
                        else if (item.HomePhoneContact && item.HomePhoneContact.ContactInfo) {
                            $rootScope.headerAddrees.showPhone = angular.copy(item.HomePhoneContact.ContactInfo);
                        }

                        if (item.EmailAddressContact && item.EmailAddressContact.ContactInfo)
                            $rootScope.headerAddrees.showEmail = angular.copy(item.EmailAddressContact.ContactInfo);
                    }
                    if (item.AddressTypeId == 5 && item.IsMailingSameasPhysical && item.AdressStatusId == 1) {
                        $rootScope.headerAddrees.showAddress = item.StreetLine1 + ' ' + item.StreetLine2;
                        $rootScope.headerAddrees.showAddress2 = item.City + ',' + item.StateCode + ', ' + item.Zip;
                        if (item.OfficePhoneContact && item.OfficePhoneContact.ContactInfo)
                            $rootScope.headerAddrees.showPhone = angular.copy(item.OfficePhoneContact.ContactInfo);
                        if (item.EmailAddressContact && item.EmailAddressContact.ContactInfo) {
                            $rootScope.headerAddrees.showEmail = angular.copy(item.EmailAddressContact.ContactInfo);
                        }
                    }
                });

            });

            //set email address from home address if Email is null
            if ($rootScope.headerAddrees && $rootScope.headerAddrees.showAddress && !$rootScope.headerAddrees.showEmail) {
                var homeAddress = $.grep($scope.personalData.CombinedAddresses1, function (i) {
                    return (i.AddressTypeId == 2);
                });
                if (homeAddress && homeAddress.length > 0) {
                    var add = homeAddress[0];
                    if (add && add.EmailAddressContact && add.EmailAddressContact.ContactInfo) {
                        $rootScope.headerAddrees.showEmail = add.EmailAddressContact.ContactInfo;
                    }
                }
            }

        };

        var processLinkedContactForSave = function (contactItem, individualCombinedAddress, currentSavedAddress, contactDataItems) {
            if (!!contactItem.ContactInfo || $scope.isCombinedAddressEdit) {
                contactItem.IsActive = true;
                // if ($scope.isCombinedAddressEdit) {
                //     contactItem.ReferenceNumber = individualCombinedAddress.IndividualAddressId;
                // } else {
                if (contactItem.IndividualContactId == 0) {
                    contactItem.ActionFlag = '';

                } else {
                    contactItem.ActionFlag = 'U';
                }
                contactItem.ReferenceNumber = currentSavedAddress.IndividualAddressId;
                //}
                contactDataItems.push(contactItem);
            }
        };
        var processLinkedContactForSave2 = function (contactItem, individualCombinedAddress, currentSavedAddress, contactDataItems) {
            if (!!contactItem.ContactInfo || $scope.isCombinedAddressEdit2) {
                contactItem.IsActive = true;
                // if ($scope.isCombinedAddressEdit) {
                //     contactItem.ReferenceNumber = individualCombinedAddress.IndividualAddressId;
                // } else {
                if (contactItem.IndividualContactId == 0) {
                    contactItem.ActionFlag = '';

                } else {
                    contactItem.ActionFlag = 'U';
                }
                contactItem.ReferenceNumber = currentSavedAddress.IndividualAddressId;
                //}
                contactDataItems.push(contactItem);
            }
        };

        var ShowErrMsg = function (propName, errorMessage) {
            HideLoader();
            $scope.showStatusMessage(errorMessage, "error");
        };

        $scope.militaryAddressChanged = function (address) {
            address.City = '';
            address.StateCode = '';
            if (!!address.MilitaryAddress) {
                address.CountryId = $rootScope.DefaultCountryId.toString();
            } else {
                address.CountryId = '';
            }
        };

        var validateForm = function (currentAddressForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentAddressForm) {
                currentAddressForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(currentAddressForm.errorMessages);
                var isPrimaryExists = false;
                var isCombinedAddressSameAsMailingExists = false;
                var isHomeExist = false;
                var address = {};
                angular.forEach($scope.personalAddress, function (combinedAddress, index) {
                    if ((combinedAddress.AddressTypeId == 2 || combinedAddress.AddressTypeId == 5) &&
                        combinedAddress.IndividualAddressId != $scope.address.IndividualAddressId) {
                        if (combinedAddress.IsMailingSameasPhysical && $scope.address.IsMailingSameasPhysical && combinedAddress.AdressStatusId == 1)
                            isCombinedAddressSameAsMailingExists = true;
                        // if (combinedAddress.AddressTypeId == 5 && combinedAddress.AdressStatusId == 1 && $scope.address.LicenseNumber === address.LicenseNumber)
                        //     isPrimaryExists = true;
                        // if (combinedAddress.AddressTypeId == 2 && combinedAddress.AdressStatusId == 1 && $scope.address.LicenseNumber === address.LicenseNumber) {
                        //     isHomeExist = true;
                        //     address = combinedAddress;
                        // }

                    }
                    if (combinedAddress.AddressTypeId == $scope.address.AddressTypeId && $scope.address.AddressId != combinedAddress.AddressId && $scope.address.AdressStatusId == 1 && combinedAddress.AdressStatusId == 1 && $scope.address.LicenseNumber === combinedAddress.LicenseNumber) {
                        isHomeExist = true;
                        address = combinedAddress;
                    }
                    // if (!$scope.personalData.CombinedAddress.AddressId && $scope.personalData.CombinedAddress.AddressTypeId == combinedAddress.AddressTypeId && isExist && combinedAddress.AdressStatusId == 1) {
                    //     isExist = false;
                    //     var addressTypeDes = "";
                    //     angular.forEach($scope.AddressTypeList, function (addressType, index) {
                    //         if (addressType.AddressTypeId == $scope.personalData.CombinedAddress.AddressTypeId) {
                    //             addressTypeDes = addressType.AddressTypeDesc;
                    //         }
                    //     });
                    //     var errorObj = {
                    //         propName: "EitherHomeOrOfficeCanBeMailingss",
                    //         errorMessage: addressTypeDes + " Already Exists with current status."
                    //     };
                    //     $scope.natValidation.errorMessages.push(errorObj);
                    // }
                });

                if (isCombinedAddressSameAsMailingExists && $scope.address.AddressId !== address.AddressId && $scope.address.LicenseNumber === address.LicenseNumber) {
                    var errorObj = {
                        propName: "EitherHomeOrOfficeCanBeMailing",
                        errorMessage: Messages.General.Errors.OnlyOneAddress
                    };
                    $scope.natValidation.errorMessages.push(errorObj);
                }

                // if ($scope.personalData.CombinedAddress.AddressTypeId == 2 && $scope.personalData.CombinedAddress.AdressStatusId == 1 && ((!$scope.personalData.CombinedAddress.CellPhoneContact || !$scope.personalData.CombinedAddress.CellPhoneContact.ContactInfo) &&
                //     (!$scope.personalData.CombinedAddress.HomePhoneContact || !$scope.personalData.CombinedAddress.HomePhoneContact.ContactInfo))) {
                //     var errorObj = {
                //         propName: "EitherCellPhoneOrHomePhoneRequired",
                //         errorMessage: Messages.dceaada
                //     };
                //     $scope.natValidation.errorMessages.push(errorObj);
                // }

                // if ($scope.address.AddressTypeId == 5 && $scope.address.AdressStatusId == 1 && $scope.address.LicenseNumber === address.LicenseNumber) {
                if (isHomeExist) {
                    var errorObj = {
                        propName: "PrimaryAddressAlreadyExists",
                        errorMessage: Messages.General.Errors.AddressExistsCurrentStats
                    };
                    $scope.natValidation.errorMessages.push(errorObj);
                }
                //  }

                //address.AddressId !== $scope.personalData.CombinedAddress.AddressId && 
                // if ($scope.address.AddressTypeId == 2 && $scope.address.AdressStatusId == 1 && $scope.address.LicenseNumber !== address.LicenseNumber) {
                //     if (isHomeExist) {
                //         var errorObj = {
                //             propName: "HomeAddressAlreadyExists",
                //             errorMessage: Messages.fbeecfc
                //         };
                //         $scope.natValidation.errorMessages.push(errorObj);
                //     }
                // }
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        var validateForm2 = function (currentAddressForm) {
            $scope.natValidation2.errorMessages = [];
            if (!!currentAddressForm) {
                currentAddressForm.$submitted = true;
                $scope.natValidation2.errorMessages = angular.copy(currentAddressForm.errorMessages);
                var isPrimaryExists = false;
                var isCombinedAddressSameAsMailingExists = false;
                angular.forEach($scope.personalAddress, function (combinedAddress, index) {
                    if ((combinedAddress.AddressTypeId == 2 || combinedAddress.AddressTypeId == 10) &&
                        combinedAddress.IndividualAddressId != $scope.personalData.CombinedAddress2.IndividualAddressId) {
                        if (combinedAddress.IsMailingSameasPhysical && $scope.personalData.CombinedAddress2.IsMailingSameasPhysical)
                            isCombinedAddressSameAsMailingExists = true;
                        if (combinedAddress.AddressTypeId == 10 && combinedAddress.AdressStatusId == 1)
                            isPrimaryExists = true;
                    }
                });

                if (isCombinedAddressSameAsMailingExists) {
                    var errorObj = {
                        propName: "EitherHomeOrOfficeCanBeMailing",
                        errorMessage: Messages.General.Errors.OnlyOneAddress
                    };
                    $scope.natValidation2.errorMessages.push(errorObj);
                }

                if ($scope.personalData.CombinedAddress2.AddressTypeId == 2 && ((!$scope.personalData.CombinedAddress2.CellPhoneContact || !$scope.personalData.CombinedAddress2.CellPhoneContact.ContactInfo) &&
                    (!$scope.personalData.CombinedAddress2.HomePhoneContact || !$scope.personalData.CombinedAddress2.HomePhoneContact.ContactInfo))) {
                    var errorObj = {
                        propName: "EitherCellPhoneOrHomePhoneRequired",
                        errorMessage: Messages.General.Errors.EitherCellOrHome
                    };
                    $scope.natValidation2.errorMessages.push(errorObj);
                }

                if ($scope.personalData.CombinedAddress2.AddressTypeId == 10 && $scope.personalData.CombinedAddress2.AdressStatusId == 1) {
                    if (isPrimaryExists) {
                        var errorObj = {
                            propName: "PrimaryAddressAlreadyExists",
                            errorMessage: Messages.General.Errors.PrimaryAddressExits
                        };
                        $scope.natValidation2.errorMessages.push(errorObj);
                    }
                }
            }

            if (!!$scope.natValidation2.errorMessages && $scope.natValidation2.errorMessages.length > 0) {
                $scope.natValidation2.showError = true;
                $scope.natValidation2.isValid = false;
                return false;
            } else {
                $scope.natValidation2.showError = false;
                $scope.natValidation2.isValid = true;
                return true;
            }
        };

        $scope.saveCombinedAddress = function (combinedAddressForm, id) {
            try {
                ShowLoader();
                if (!validateForm(combinedAddressForm)) {
                    $scope.validateData($scope.natValidation)
                    HideLoader();
                    return;
                } else {
                    $scope.hideStatusMessage();
                    $scope.hideValidationSummary();
                }
                $scope.personalData.CombinedAddress.IsActive = true;
                $scope.personalData.AdressStatusId = id;

                $scope.personalData.CombinedAddress.ActionFlag = getAddressOrContactActionFlag($scope.isCombinedAddressEdit, $scope.personalData.CombinedAddress, $scope.personalData.OriginalCombinedAddress, true);
                if ($scope.personalData.CombinedAddress.AddressTypeId == 10 || $scope.personalData.CombinedAddress.AddressTypeId == 11) {
                    $scope.personalData.CombinedAddress.OfficePhoneContact.ActionFlag = $scope.personalData.CombinedAddress.ActionFlag;
                    $scope.personalData.CombinedAddress.OfficeFaxContact.ActionFlag = $scope.personalData.CombinedAddress.ActionFlag;
                    $scope.personalData.CombinedAddress.OfficePhoneContact.ContactStatusId = $scope.personalData.CombinedAddress.AdressStatusId;
                    $scope.personalData.CombinedAddress.OfficeFaxContact.ContactStatusId = $scope.personalData.CombinedAddress.AdressStatusId;
                } else if ($scope.personalData.CombinedAddress.AddressTypeId == 2) {
                    $scope.personalData.CombinedAddress.CellPhoneContact.ActionFlag = $scope.personalData.CombinedAddress.ActionFlag;
                    $scope.personalData.CombinedAddress.HomePhoneContact.ActionFlag = $scope.personalData.CombinedAddress.ActionFlag;
                    $scope.personalData.CombinedAddress.EmailAddressContact.ActionFlag = $scope.personalData.CombinedAddress.ActionFlag;
                    $scope.personalData.CombinedAddress.FaxContact.ActionFlag = $scope.personalData.CombinedAddress.ActionFlag;
                    $scope.personalData.CombinedAddress.CellPhoneContact.ContactStatusId = $scope.personalData.CombinedAddress.AdressStatusId;
                    $scope.personalData.CombinedAddress.HomePhoneContact.ContactStatusId = $scope.personalData.CombinedAddress.AdressStatusId;
                    $scope.personalData.CombinedAddress.EmailAddressContact.ContactStatusId = $scope.personalData.CombinedAddress.AdressStatusId;
                    $scope.personalData.CombinedAddress.FaxContact.ContactStatusId = $scope.personalData.CombinedAddress.AdressStatusId;
                }
                var isCombinedAddressSavedSuccessFully = true;
                backofficeIndividualAdressService.addressSave([$scope.personalData.CombinedAddress], sessionStorage.Key)
                    .then(function (response) {
                        if (response.Status == false) {
                            isCombinedAddressSavedSuccessFully = false;
                            ShowErrMsg("unableToSaveCombinedAddress", response.Message);
                        } else {
                            var currentSavedAddress = $scope.personalData.CombinedAddress;
                            angular.forEach(response.IndividualAddressResponse, function (savedAddresOption, savedIndex) {
                                var isNewAddress = true;
                                angular.forEach($scope.personalAddress, function (existingAddresOption, existingIndex) {
                                    if (savedAddresOption.IndividualAddressId == existingAddresOption.IndividualAddressId) {
                                        isNewAddress = false;
                                    }
                                });
                                if (isNewAddress) {
                                    currentSavedAddress = savedAddresOption;
                                }
                            });
                            $scope.personalAddress = response.IndividualAddressResponse;

                            var contactDataItems = [];

                            if ($scope.personalData.CombinedAddress.AddressTypeId == 10 || $scope.personalData.CombinedAddress.AddressTypeId == 11) {
                                processLinkedContactForSave($scope.personalData.CombinedAddress.OfficePhoneContact, $scope.personalData.CombinedAddress, currentSavedAddress, contactDataItems);
                                processLinkedContactForSave($scope.personalData.CombinedAddress.OfficeFaxContact, $scope.personalData.CombinedAddress, currentSavedAddress, contactDataItems);
                            }

                            if ($scope.personalData.CombinedAddress.AddressTypeId == 2) {
                                processLinkedContactForSave($scope.personalData.CombinedAddress.CellPhoneContact, $scope.personalData.CombinedAddress, currentSavedAddress, contactDataItems);
                                processLinkedContactForSave($scope.personalData.CombinedAddress.HomePhoneContact, $scope.personalData.CombinedAddress, currentSavedAddress, contactDataItems);
                                processLinkedContactForSave($scope.personalData.CombinedAddress.EmailAddressContact, $scope.personalData.CombinedAddress, currentSavedAddress, contactDataItems);
                                processLinkedContactForSave($scope.personalData.CombinedAddress.FaxContact, $scope.personalData.CombinedAddress, currentSavedAddress, contactDataItems);
                            }

                            var isContactSavedSuccessFully = true;
                            if (contactDataItems.length > 0) {
                                backofficeIndividualAdressService.addressContactSave(contactDataItems, sessionStorage.Key)
                                    .then(function (responseContacts) {
                                        if (responseContacts.Status == false) {
                                            isContactSavedSuccessFully = false;
                                            ShowErrMsg("unableToSaveCombinedContact", responseContacts.Message);
                                        } else {
                                            if (!$scope.personalContact) {
                                                $scope.personalContact = responseContacts.IndividualContactResponse;
                                            } else {
                                                var allContacts = angular.copy($scope.personalContact);
                                                angular.forEach(responseContacts.IndividualContactResponse, function (personalContactData, key) {
                                                    var contactExists = false;
                                                    angular.forEach(allContacts, function (contactOption, indexAllContacts) {
                                                        if (personalContactData.IndividualContactId == contactOption.IndividualContactId) {
                                                            contactExists = true;
                                                            allContacts[indexAllContacts] = personalContactData;
                                                        }
                                                    });
                                                    if (!contactExists) {
                                                        allContacts.push(personalContactData);
                                                    }
                                                });
                                                $scope.personalContact = allContacts;
                                            }
                                        }
                                        processAddressData($scope.personalAddress);
                                        processContactsData($scope.personalContact);
                                        assignAddressByAddressTypeId();
                                        $scope.isContactSavedSuccessFully = isContactSavedSuccessFully;
                                        var isAddressandContactSavedSuccessFully = isCombinedAddressSavedSuccessFully && isContactSavedSuccessFully;
                                        if (isAddressandContactSavedSuccessFully) {
                                            $scope.viewConfig.showAddCombinedAddressForm = false;
                                            $scope.currentAddressForm = null;
                                        }
                                        HideLoader();
                                    }, function (data) {
                                        ShowErrMsg("unableToSaveCombinedContact", data);
                                        HideLoader();
                                    });
                            } else {
                                processAddressData($scope.personalAddress);
                                assignAddressByAddressTypeId();
                                var isAddressandContactSavedSuccessFully = isCombinedAddressSavedSuccessFully;
                                if (isAddressandContactSavedSuccessFully) {
                                    $scope.viewConfig.showAddCombinedAddressForm = false;
                                    $scope.currentAddressForm = null;
                                }
                                HideLoader();
                            }
                        }
                    }, function (data) {
                        $scope.isAddressandContactSavedSuccessFully = false;
                        if (data != null) {
                            ShowErrMsg("unableToSaveCombinedAddress", data);
                        }
                        HideLoader();
                    });
            } catch (ex) {
                HideLoader();
                ShowErrMsg("unableToSaveCombinedAddress", ex);
            }
        };

        $scope.saveCombinedAddress2 = function (combinedAddressForm, id) {
            try {

                ShowLoader();
                if (!validateForm2(combinedAddressForm)) {
                    $scope.validateData($scope.natValidation2)
                    HideLoader();
                    return;
                } else {
                    $scope.hideStatusMessage();
                    $scope.hideValidationSummary();
                }
                $scope.personalData.CombinedAddress2.IsActive = true;
                $scope.personalData.AdressStatusId = id;

                $scope.personalData.CombinedAddress2.ActionFlag = getAddressOrContactActionFlag($scope.isCombinedAddressEdit2, $scope.personalData.CombinedAddress2, $scope.personalData.OriginalCombinedAddress2, true);
                if ($scope.personalData.CombinedAddress2.AddressTypeId == 10 || $scope.personalData.CombinedAddress2.AddressTypeId == 11) {
                    $scope.personalData.CombinedAddress2.OfficePhoneContact.ActionFlag = $scope.personalData.CombinedAddress2.ActionFlag;
                    $scope.personalData.CombinedAddress2.OfficeFaxContact.ActionFlag = $scope.personalData.CombinedAddress2.ActionFlag;
                    $scope.personalData.CombinedAddress2.OfficePhoneContact.ContactStatusId = $scope.personalData.CombinedAddress2.AdressStatusId;
                    $scope.personalData.CombinedAddress2.OfficeFaxContact.ContactStatusId = $scope.personalData.CombinedAddress2.AdressStatusId;
                } else if ($scope.personalData.CombinedAddress2.AddressTypeId == 2) {
                    $scope.personalData.CombinedAddress2.CellPhoneContact.ActionFlag = $scope.personalData.CombinedAddress2.ActionFlag;
                    $scope.personalData.CombinedAddress2.HomePhoneContact.ActionFlag = $scope.personalData.CombinedAddress2.ActionFlag;
                    $scope.personalData.CombinedAddress2.EmailAddressContact.ActionFlag = $scope.personalData.CombinedAddress2.ActionFlag;
                    $scope.personalData.CombinedAddress2.FaxContact.ActionFlag = $scope.personalData.CombinedAddress2.ActionFlag;
                    $scope.personalData.CombinedAddress2.CellPhoneContact.ContactStatusId = $scope.personalData.CombinedAddress2.AdressStatusId;
                    $scope.personalData.CombinedAddress2.HomePhoneContact.ContactStatusId = $scope.personalData.CombinedAddress2.AdressStatusId;
                    $scope.personalData.CombinedAddress2.EmailAddressContact.ContactStatusId = $scope.personalData.CombinedAddress2.AdressStatusId;
                    $scope.personalData.CombinedAddress2.FaxContact.ContactStatusId = $scope.personalData.CombinedAddress2.AdressStatusId;
                }
                var isCombinedAddressSavedSuccessFully = true;
                backofficeIndividualAdressService.addressSave([$scope.personalData.CombinedAddress2], sessionStorage.Key)
                    .then(function (response) {
                        if (response.Status == false) {
                            isCombinedAddressSavedSuccessFully = false;
                            ShowErrMsg("unableToSaveCombinedAddress", response.Message);
                        } else {
                            var currentSavedAddress = $scope.personalData.CombinedAddress2;
                            angular.forEach(response.IndividualAddressResponse, function (savedAddresOption, savedIndex) {
                                var isNewAddress = true;
                                angular.forEach($scope.personalAddress, function (existingAddresOption, existingIndex) {
                                    if (savedAddresOption.IndividualAddressId == existingAddresOption.IndividualAddressId) {
                                        isNewAddress = false;
                                    }
                                });
                                if (isNewAddress) {
                                    currentSavedAddress = savedAddresOption;
                                }
                            });
                            $scope.personalAddress = response.IndividualAddressResponse;
                            var contactDataItems = [];

                            if ($scope.personalData.CombinedAddress2.AddressTypeId == 10 || $scope.personalData.CombinedAddress2.AddressTypeId == 11) {
                                processLinkedContactForSave2($scope.personalData.CombinedAddress2.OfficePhoneContact, $scope.personalData.CombinedAddressw, currentSavedAddress, contactDataItems);
                                processLinkedContactForSave2($scope.personalData.CombinedAddress2.OfficeFaxContact, $scope.personalData.CombinedAddress2, currentSavedAddress, contactDataItems);
                            }

                            if ($scope.personalData.CombinedAddress2.AddressTypeId == 2) {
                                processLinkedContactForSave2($scope.personalData.CombinedAddress2.CellPhoneContact, $scope.personalData.CombinedAddress2, currentSavedAddress, contactDataItems);
                                processLinkedContactForSave2($scope.personalData.CombinedAddress2.HomePhoneContact, $scope.personalData.CombinedAddress2, currentSavedAddress, contactDataItems);
                                processLinkedContactForSave2($scope.personalData.CombinedAddress2.EmailAddressContact, $scope.personalData.CombinedAddress2, currentSavedAddress, contactDataItems);
                                processLinkedContactForSave2($scope.personalData.CombinedAddress2.FaxContact, $scope.personalData.CombinedAddress2, currentSavedAddress, contactDataItems);
                            }

                            var isContactSavedSuccessFully = true;
                            if (contactDataItems.length > 0) {
                                backofficeIndividualAdressService.addressContactSave(contactDataItems, sessionStorage.Key)
                                    .then(function (responseContacts) {
                                        if (responseContacts.Status == false) {
                                            isContactSavedSuccessFully = false;
                                            ShowErrMsg("unableToSaveCombinedContact", responseContacts.Message);
                                        } else {
                                            if (!$scope.personalContact) {
                                                $scope.personalContact = responseContacts.IndividualContactResponse;
                                            } else {
                                                var allContacts = angular.copy($scope.personalContact);
                                                angular.forEach(responseContacts.IndividualContactResponse, function (personalContactData, key) {
                                                    var contactExists = false;
                                                    angular.forEach(allContacts, function (contactOption, indexAllContacts) {
                                                        if (personalContactData.IndividualContactId == contactOption.IndividualContactId) {
                                                            contactExists = true;
                                                            allContacts[indexAllContacts] = personalContactData;
                                                        }
                                                    });
                                                    if (!contactExists) {
                                                        allContacts.push(personalContactData);
                                                    }
                                                });
                                                $scope.personalContact = allContacts;
                                            }
                                        }
                                        processAddressData($scope.personalAddress);
                                        processContactsData($scope.personalContact);
                                        assignAddressByAddressTypeId();
                                        $scope.isContactSavedSuccessFully = isContactSavedSuccessFully;
                                        var isAddressandContactSavedSuccessFully = isCombinedAddressSavedSuccessFully && isContactSavedSuccessFully;
                                        if (isAddressandContactSavedSuccessFully) {
                                            $scope.viewConfig.showAddCombinedAddressForm2 = false;
                                            $scope.currentAddressForm = null;
                                        }
                                        HideLoader();
                                    }, function (data) {
                                        ShowErrMsg("unableToSaveCombinedContact", data);
                                        HideLoader();
                                    });
                            } else {
                                processAddressData($scope.personalAddress);
                                assignAddressByAddressTypeId();
                                var isAddressandContactSavedSuccessFully = isCombinedAddressSavedSuccessFully;
                                if (isAddressandContactSavedSuccessFully) {
                                    $scope.viewConfig.showAddCombinedAddressForm2 = false;
                                    $scope.currentAddressForm = null;
                                }
                                HideLoader();
                            }
                        }
                    }, function (data) {
                        $scope.isAddressandContactSavedSuccessFully = false;
                        if (data != null) {
                            ShowErrMsg("unableToSaveCombinedAddress", data);
                        }
                        HideLoader();
                    });
            } catch (ex) {
                HideLoader();
                ShowErrMsg("unableToSaveCombinedAddress", ex);
            }
        };

        $scope.combinedAddressTypeChanged = function (officeAddress) {

            if (officeAddress.AddressTypeId != 2 && officeAddress.AddressTypeId != 10) {
                officeAddress.IsMailingSameasPhysical = false;
            }
            if (officeAddress.AddressTypeId != 2 && officeAddress.AddressTypeId != 10 && officeAddress.AddressTypeId != 11) {
                officeAddress.MilitaryAddress = false;
            }
        };


        $scope.isMailingSameasPhysicalChanged = function () {
            if ($scope.personalData.combinedAddress.AddressTypeId == 2 && $scope.personalData.combinedAddress.ResidentialAddress.IsMailingSameasPhysical)
                $scope.showPublicRecordMessage = true;
            else
                $scope.showPublicRecordMessage = false;
        };

        $scope.isMailingSameasPhysicalChanged2 = function () {
            if ($scope.personalData.combinedAddress2.AddressTypeId == 2 && $scope.personalData.combinedAddress2.ResidentialAddress.IsMailingSameasPhysical)
                $scope.showPublicRecordMessage2 = true;
            else
                $scope.showPublicRecordMessage2 = false;
        };

        $scope.deleteCombinedAddress = function (combinedAddress) {
            if (confirm(Messages.ccafaae)) {
                ShowLoader();
                combinedAddress.IsDeleted = true;
                combinedAddress.ActionFlag = 'U';
                backofficeIndividualAdressService.addressSave([combinedAddress], sessionStorage.Key)
                    .then(function (response) {
                        if (response.Status == false) {
                            ShowErrMsg("unableDeleteCombinedAddress", response.Message);
                        } else {
                            $scope.personalAddress = response.IndividualAddressResponse;
                            processAddressData($scope.personalAddress);
                            assignAddressByAddressTypeId();
                            HideLoader();
                        }
                    }, function (data) {
                        if (data != null) {
                            ShowErrMsg("unableDeleteOfficeAddress", data);
                        }
                    });
            }
        };

        $scope.resetAddressData = function () {
            $scope.address.StateCode = "";
            $scope.address.City = "";
        }

        $scope.getAddressStateByCountryID = function (addressChanged, countryId, isOffice) {

            if (!countryId) {
                countryId = $rootScope.DefaultCountryId;
            }
            ShowLoader();
            countryStateService.getStateByCountryID(sessionStorage.Key, countryId)
                .then(function (data) {

                    if (isOffice)
                        $scope.StatList1 = data.State;
                    else
                        $scope.StatList2 = data.State;

                    if (addressChanged)
                        addressChanged.StateCode = "";
                    HideLoader();
                }, function (error) {
                    HideLoader();
                    $scope.showStatusMessage({ messageContent: error.Message, messageType: 'error' });
                });
        };

        $scope.getStateByCountryID = function (CountryID) {
            try {
                countryStateService.getStateByCountryID(sessionStorage.Key, CountryID)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.StatList1 = response.State;
                            $scope.StatList2 = response.State;
                        }
                    }, function (data) {
                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.editAddressorContact = function () {
            $scope.isAddressOrContactEdit = true;
        }

        $scope.getStateByCountryID($rootScope.DefaultCountryId);

        $scope.statusChanege = function (address) {

            if (address.AdressStatusId != 1) {
                address.IsMailingSameasPhysical = false;
            }
        }

        $scope.individualLicenseDetailBYIndividualId = function (individualId) {
            ShowLoader("Please Wait...");
            try {
                backofficeIndividualLicenseService.individualLicenseDetailBYIndividualId(sessionStorage.Key, individualId)
                    .then(function (response) {
                        HideLoader();
                        if ($scope.checkServerResponse(response)) {
                            var isLicenseActiveList = _.filter(response.IndividualLicenseList, function (o) { return o.IsLicenseActive == true; });
                            $scope.IndividualLicenseList = _.uniq(isLicenseActiveList);

                        }
                    }, function (data) {
                        HideLoader();
                    });
            } catch (ex) {
                HideLoader();
            }
        };

        $scope.getDEA = function (IndividualId) {
            try {
                backofficeIndividualDEAService.getIndividualLicenseAddInfo(sessionStorage.Key, IndividualId, 0)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.deaList = response.IndividualLicenseAddInfoList;
                            // $scope.processDEAFields();
                        }
                    }, function (response) {
                    });
            } catch (ex) {
            }
        }

        $scope.processDEAFields = function () {
            var indexOfSlash = -1;
            var totalChar = -1;
            if (!!$scope.deaList) {
                angular.forEach($scope.deaList, function (val, index) {
                    indexOfSlash = $scope.deaList[index].AddInfoTypeValue1.indexOf("/");

                    totalChar = $scope.deaList[index].AddInfoTypeValue1.length;
                    $scope.deaList[index].Schedule = $scope.deaList[index].AddInfoTypeValue1.substring(indexOfSlash + 1, totalChar);
                    if ($scope.deaList[index].Schedule == "undefined") {
                        $scope.deaList[index].Schedule = "";
                    }
                    $scope.deaList[index].AddInfoTypeValue1 = $scope.deaList[index].AddInfoTypeValue1.substring(0, indexOfSlash);
                    if ($scope.deaList[index].AddInfoTypeValue1 == "undefined") {
                        $scope.deaList[index].AddInfoTypeValue1 = "";
                    }
                });
            }
        }

        $scope.licenseNumberChanged = function (LicenseNumber) {
            ShowLoader();
            backofficeIndividualDEAService.getIndividualLicenseAddInfo(sessionStorage.Key, $scope.IndividualId, 0, LicenseNumber)
                .then(function (response) {
                    HideLoader();
                    if ($scope.checkServerResponse(response)) {
                        $scope.address.DEA = null;
                        if (response.IndividualLicenseAddInfoList && response.IndividualLicenseAddInfoList.length)
                            $scope.address.DEA = response.IndividualLicenseAddInfoList[0].IndividualLicenseAddInfoId;
                    }
                }, function (response) {
                    HideLoader();
                });
        }
    }
})();