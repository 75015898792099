(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('FirmAffirmationProviderService', ['$http', '$q', 'WebApiUrl', 'AppConfig', function ($http, $q, WebApiUrl, AppConfig) {
            var baseFactory = {};
            baseFactory.SaveProviderAffidavit = function (key, providerid, appid, uid, data) {
                return returnDefferedResult($http.post(WebApiUrl + 'Provider/SaveProviderAffidavit/' + key + '?ProviderId=' + providerid + '&applicationId=' + appid + '&userId=' + uid + '&Source=' + AppConfig.Source, data));
            };
            baseFactory.GetProviderAffidavit = function (key, provId, appid) {
                return returnDefferedResult($http.get(WebApiUrl + 'Provider/GetProviderAffidavit/' + key + '?providerId=' + provId + '&applicationId=' + appid + '&Source=' + AppConfig.Source));
            };
            var returnDefferedResult = function (request) {
                var deferred = $q.defer();
                request.then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.reject(response);
                });
                return deferred.promise;
            };
            return baseFactory;
        }]);
})();