(function () {
    'use strict'
    angular.module('app.core')
        .directive('inlumonResourceLoader', inlumonResourceLoader);

    inlumonResourceLoader.$inject = ['$http', '$q', '$compile', 'WebApiUrl'];

    function inlumonResourceLoader($http, $q, $compile, WebApiUrl) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                resourceType: '=?'
            },
            link: function ($scope, element, attrs) {

            },
            template: '<div>\
                            <style>ul.default-style {padding-left: 40px;margin-bottom: 10px;}ul.default-style li {list-style: inside;margin-bottom: 5px;}</style>\
                            <div class="resource-type-loader">\
                                <inlumon-ref-table-loader table-key="resource" list-name="resourceTypeList" \
                                look-for=".resource-type-loader"></inlumon-ref-table-loader>\
                                <ul class="default-style">\
                                    <li ng-repeat="resourceType in resourceTypeList"><a target="{{(resourceType.OpenInNewWindow)?\'_blank\':\'_self\'}}" title="{{resourceType.ResourceHoverText}}" href="{{resourceType.ResourceLink}}">{{resourceType.ResourceLinkText}}</a><a ng-if="resourceType.EnableHelpIcon" uib-tooltip-html="resourceType.HelpIconText" class="ml10"><i class="fa fa-question-circle"></i></a></li>\
                                </ul>\
                            </div>\
                        </div>'
        }
    }
})();