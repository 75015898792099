(function () {
    'use strict'
    angular.module('app.provider')
        .directive('providerAddressContactDirective', providerAddressContactDirective);

    providerAddressContactDirective.$inject = [];

    function providerAddressContactDirective() {
        return {
            restrict: 'E',
            scope: {
                enableAddress: "=?",
                enableContact: "=?",
                providerId: "=",
                checkServerResponse: "&",
                hideStatusMessage: "&",
                hideValidationSummary: "&",
                showStatusMessage: "&",
                validateData:"&"
            },
            templateUrl: "app/components/provider/directive/provider-address-directive/provider-address.html",
            controller: "providerAddressDirectiveController"
        }
    }
})();