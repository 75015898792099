(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeIndividualDEAController", BackofficeIndividualDEAController);

    BackofficeIndividualDEAController.$inject = ["$rootScope", "$scope", "backofficeIndividualDEAService", "multipleFileUploadService", "WebApiUrl", "utilityService", "backofficeIndividualLicenseService", "Messages"
    ];

    function BackofficeIndividualDEAController($rootScope, $scope, backofficeIndividualDEAService, multipleFileUploadService, WebApiUrl, utilityService, backofficeIndividualLicenseService, Messages) {

        $scope.documentByIndividualDocumentId = multipleFileUploadService.documentByIndividualDocumentId;

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "dea") {
                $scope.IndividualId = individualId;
                // ShowLoader();
                $rootScope.individualCurrentTab.isLoadedOnce = true;
                $scope.getDEA($scope.IndividualId);
                $scope.individualLicenseDetailBYIndividualId($scope.IndividualId);
                getDoumentType(32, 'D1032');
                HideLoader();
                initDocument();
            }
        });

        $scope.adddeabutton = true;
        $scope.DEAFormShow = false;
        $scope.isEditDEA = false;




        var initDocument = function () {
            $scope.documentDEATab = undefined;
            setTimeout(function () {
                $scope.$apply(function () {
                    $scope.documentDEATab = {
                        ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                        ApplicationId: $scope.applicationId,
                        IndividualId: $scope.IndividualId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "R",
                        LoadByDocIdAndDocCode: true,
                        DocId: 32,
                        DocCode: "D1032",
                        LoadDocumentsWithoutBroadCast: false,
                        LinkType: 'BC',
                        PropertyToProcess: 'IndividualLicenseAddInfoId',
                        IsLinkedDocControl: true
                    };
                });
            }, 1000);
        };

        $scope.setSaveDocumentRef = function (fn) {
            $scope.saveAllDocument = fn;
        };

        var getDoumentType = function (id, code) {
            multipleFileUploadService.documentGetDocumentTypeName(sessionStorage.Key, id, code)
                .then(function (response) {
                    if ($scope.checkResponse(response)) {
                        if (id == 8) {
                            $scope.DocType = response.DocumentMasterGET;
                        } else {
                            $scope.DocType2 = response.DocumentMasterGET;
                        }
                    }
                }, function (data) {
                    showStatusMessage(data, "error");
                });
        }
        $scope.natValidation = {};

        var validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            }

            if ($scope.dea.AddInfoTypeValue1 == undefined && $scope.dea.Schedule == undefined) {
                //$scope.natValidation.errorMessages.push("Cannot save empty row!");
                var errorObj = {
                    propName: "emptyDEARow",
                    errorMessage: Messages.General.Errors.EmptyRow
                };
                if (!$scope.natValidation.errorMessages) {
                    $scope.natValidation.errorMessages = [];
                }
                $scope.natValidation.errorMessages.push(errorObj);
                // $scope.natValidation.showError = true;
                // $scope.natValidation.isValid = false;
                // return false;
            }
            if ($scope.dea && $scope.dea.LicenseNumber) {
                var filterDeaList = _.find($scope.deaList, function (o) { return o.LicenseNumber == $scope.dea.LicenseNumber; });
                if (filterDeaList && filterDeaList.IndividualLicenseAddInfoId != $scope.dea.IndividualLicenseAddInfoId) {
                    var errorObj = {
                        propName: "emptyDEALicenseRow",
                        errorMessage: Messages.General.Errors.DAE
                    };
                    if (!$scope.natValidation.errorMessages) {
                        $scope.natValidation.errorMessages = [];
                    }
                    $scope.natValidation.errorMessages.push(errorObj);
                }
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }

        };

        $scope.editDEA = function (item) {
            $scope.adddeabutton = false;
            if (!item.ReferenceNumber) {
                item.ReferenceNumber = new Date().valueOf();
            }
            $scope.dea = angular.copy(item);
            $scope.DEAFormShow = true;
            $scope.isEditDEA = true;

            initDocument();
        }
        $scope.cancelDEAForm = function () {
            $scope.adddeabutton = true;
            $scope.dea = {};
            $scope.DEAFormShow = false;
            $scope.natValidation = {};
            $scope.natValidation.errorMessages = [];
            $scope.isEditDEA = false;
        }

        $scope.addNewDEA = function () {
            $scope.adddeabutton = false;
            $scope.DEAFormShow = true;
            $scope.dea = {
                IndividualId: $scope.IndividualId,
                IsActive: true,
                IsDeleted: false,
                AddInfoTypeId1: 21,
                ReferenceNumber: new Date().valueOf()
            };
            $scope.isEditDEA = false;
            $scope.natValidation = {};
            $scope.natValidation.errorMessages = [];
            initDocument();
        }

        $scope.getDEA = function (IndividualId) {
            try {
                ShowLoader("Please Wait...");
                backofficeIndividualDEAService.getIndividualLicenseAddInfo(sessionStorage.Key, IndividualId, 0)
                    .then(function (response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse(response)) {
                            $scope.deaList = response.IndividualLicenseAddInfoList;
                            $scope.processDEAFields();
                        }
                        HideLoader();
                    },
                        function (response) {
                            HideLoader();
                            $scope.showStatusMessage(response.data.Message, "error");
                        });
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.processDEAFields = function () {
            var indexOfSlash = -1;
            var totalChar = -1;
            if (!!$scope.deaList) {
                angular.forEach($scope.deaList, function (val, index) {
                    indexOfSlash = $scope.deaList[index].AddInfoTypeValue1.indexOf("/");

                    totalChar = $scope.deaList[index].AddInfoTypeValue1.length;
                    $scope.deaList[index].Schedule = $scope.deaList[index].AddInfoTypeValue1.substring(indexOfSlash + 1, totalChar);
                    if ($scope.deaList[index].Schedule == "undefined") {
                        $scope.deaList[index].Schedule = "";
                    }
                    $scope.deaList[index].AddInfoTypeValue1 = $scope.deaList[index].AddInfoTypeValue1.substring(0, indexOfSlash);
                    if ($scope.deaList[index].AddInfoTypeValue1 == "undefined") {
                        $scope.deaList[index].AddInfoTypeValue1 = "";
                    }
                });
            }
            processIndividualEducationAndFields();
        }

        var processIndividualEducationAndFields = function () {
            if ($scope.deaList && $scope.deaList.length > 0) {

                multipleFileUploadService.individualDocumentProcess(sessionStorage.Key, $scope.IndividualId,
                    $scope.deaList, $scope.documentDEATab.PropertyToProcess, $scope.documentDEATab.LinkType).then(function (responseData) {
                        $scope.deaList = responseData;
                        HideLoader();
                    });
            }
        };

        $scope.savedea = function (currentForm) {
            try {
                if (!validateForm(currentForm)) {
                    return;
                };
                $scope.dea.AddInfoTypeValue1 = $scope.dea.AddInfoTypeValue1 + "/" + $scope.dea.Schedule;

                ShowLoader("Please Wait...");
                var filterLicense = _.find($scope.IndividualLicenseList, function (o) { return o.LicenseNumber == $scope.dea.LicenseNumber; });
                if (filterLicense) {
                    $scope.dea.IndividualLicenseId = filterLicense.IndividualLicenseId;
                }
                backofficeIndividualDEAService.saveIndividualLicenseAddInfo(sessionStorage.Key, [$scope.dea])
                    .then(function (response) {
                        if (response.Status && response.StatusCode == "00") {
                            // if (formNumber == 1) {


                            $scope.documentDEATab.LinkId = response.IndividualLicenseAddInfoList[0].IndividualLicenseAddInfoId;
                            //  $scope.viewConfig.showAddEducationForm = false;
                            if (typeof $scope.saveAllDocument == "function") {
                                $scope.saveAllDocument().then(function () {
                                    $scope.getDEA($scope.IndividualId);
                                    HideLoader();
                                }, function () { });
                            } else {
                                $scope.getDEA($scope.IndividualId);
                                HideLoader();
                            }

                        }
                        else {
                            HideLoader();
                        }
                        $scope.DEAFormShow = false;
                        $scope.adddeabutton = true;

                    });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        }

        $scope.deleteDEA = function (deaObject) {
            try {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    $rootScope.individualEventLog('deleteStatus.deleteConfirmed', $scope.IndividualId);
                    deaObject.IsDeleted = true;
                    ShowLoader("Please Wait...");

                    backofficeIndividualDEAService.saveIndividualLicenseAddInfo(sessionStorage.Key, [deaObject])
                        .then(function (response) {
                            $scope.getDEA($scope.IndividualId);
                            HideLoader();
                        });
                }
                else {
                    $rootScope.individualEventLog('deleteStatus.deleteCanceled', $scope.IndividualId);
                }
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        }

        $scope.individualLicenseDetailBYIndividualId = function (individualId) {
            ShowLoader("Please Wait...");
            try {
                $scope.ConvertableIndividualLicenseId = 0;
                backofficeIndividualLicenseService.individualLicenseDetailBYIndividualId(sessionStorage.Key, individualId)
                    .then(function (response) {
                        HideLoader();
                        if ($scope.checkServerResponse(response)) {
                            var isLicenseActiveList = _.filter(response.IndividualLicenseList, function (o) { return o.IsLicenseActive == true; });
                            $scope.IndividualLicenseList = _.uniq(isLicenseActiveList);

                        }
                    }, function (data) {
                        HideLoader();
                    });
            } catch (ex) {
                HideLoader();
            }
        };
    }
})();