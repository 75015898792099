(function () {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("IndividualPersonalController", IndividualPersonalController);

    IndividualPersonalController.$inject = ["$rootScope", "$scope", "$filter", "$q",
        "backofficeIndividualPersonalService", "backofficeIndividualLicenseService", "lookupService", "countryStateService",
        "typeValuesService", "utilityService", "$exceptionHandler", "LookUpType", "TypeValue",
        "configurationService", "backofficeIndividualNameService", "personalService", "dcaIService"
    ];

    function IndividualPersonalController($rootScope, $scope, $filter, $q,
        backofficeIndividualPersonalService, backofficeIndividualLicenseService, lookupService,
        countryStateService, typeValuesService, utilityService, $exceptionHandler, LookUpType, TypeValue,
        configurationService, backofficeIndividualNameService, personalService, dcaIService) {
        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "personal") {
                $scope.init(individualId);
            }
        });

        // Mask SSN and DOB fields when new individual selected
        $scope.$watch('person.IndividualId', function (newVal, oldVal) {
            $scope.blurSSN = true;
            $scope.blurDOB = true;
            $scope.blurLast4SSN = true;
        });

        $scope.init = function (individualId) {
            $scope.blurSSN = true;
            $scope.blurDOB = true;
            $scope.blurLast4SSN = true;
            $scope.HairColor = [];
            $scope.EyeColor = [];
            $scope.Gender = [];
            $scope.Race = [];
            $scope.citizenshipValidation = {};
            $scope.viewConfig = {
                isPersonalEdit: false
            };
            $scope.individualId = individualId;
            if (!$scope.isLoadedOnce) {
                //$scope.lookupGetBYLookupTypeIDForEyeColor();
                $scope.countryGetAll();
                //$scope.lookupGetBYLookupTypeIDForGender();
                $scope.isLoadedOnce = true;
            } else {
                $scope.individualBYIndividualId(individualId);
                $scope.ConfigurationGetbySettings('FullNameorConcatenateFNandLNinHeader');
                $scope.ConfigurationGetbySettings('DisplayProfessionalTitleSuffix');
                $scope.ConfigurationGetbySettings('DisplayOtherAttributesSection');
                $scope.ConfigurationGetbySettings('DisplayCitizenshipSection');
                $scope.ConfigurationGetbySettings('DisplayBirthPlaceSection');
                $scope.individualLicenseDetailBYIndividualId(individualId);
                $scope.individualUnPaidInvoiceByIndividualId($scope.individualId);
            }
            $q.all([
                personalService.getLookupInfo(sessionStorage.Key, 35), //Hair Color
                personalService.getLookupInfo(sessionStorage.Key, 36), //Eye Color
                personalService.getLookupInfo(sessionStorage.Key, 37), //Gender
                personalService.getLookupInfo(sessionStorage.Key, 38) //Race
            ])
                .then(function (response) {
                    $scope.HairColor = response[0].Lookup;
                    $scope.EyeColor = response[1].Lookup;
                    $scope.Gender = response[2].Lookup;
                    $scope.Race = response[3].Lookup;
                }, function (err) {

                });
            $rootScope.individualCurrentTab.isLoadedOnce = true;
            // $scope.addIndividualShow = false;
            $scope.editIndividualShow = true;
        };
        $scope.datePicker = {
            opened: false,
            format: 'MM/dd/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };

        $scope.getAge = function (BirthDate) {
            if (BirthDate != null) {
                var personBirthdate = ($filter('date')(new Date(BirthDate), 'MM/dd/yyyy'));
                utilityService.getServerDate()
                    .then(function (response) {
                        var date = new Date(response);
                        var serverYear = date.getFullYear();
                        var birthYear = new Date(personBirthdate).getFullYear();
                        $scope.person.Age = serverYear - birthYear;
                    });
            } else {
                $scope.person.Age = "";
            }
        };

        $scope.getAgePersonAdd = function (BirthDate) {
            if (BirthDate != null) {
                var personBirthdate = ($filter('date')(new Date(BirthDate), 'MM/dd/yyyy'));
                utilityService.getServerDate()
                    .then(function (response) {
                        var date = new Date(response.substring(0, 10));
                        var serverYear = date.getFullYear();
                        var birthYear = new Date(personBirthdate).getFullYear();
                        $scope.personAdd.Age = serverYear - birthYear;
                    });
            } else {
                $scope.personAdd.Age = "";
            }
        };

        // Get Lookup List for SuffixId
        $scope.lookupGetBYLookupTypeIDForSuffix = function () {
            try {
                lookupService.getLookUpDataByTypeId(LookUpType.SuffixTypeId, sessionStorage.Key)
                    .then(function (response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse({
                            response: response
                        })) {
                            $scope.Suffix = response.Lookup;
                            $scope.licenseStatusTypeGetAll();
                        }
                    }, function (data) {
                        $scope.showStatusMessage({
                            message: data,
                            type: "error"
                        });
                    });
            } catch (ex) {
                if (ex != null) {
                    $scope.showStatusMessage({
                        message: ex.message,
                        type: "error"
                    });
                }
                $exceptionHandler(ex);
            }
        };

        // Get Country List 
        $scope.countryGetAll = function () {
            try {
                countryStateService.countryGetAll(sessionStorage.Key)
                    .then(function (response) {
                        if ($scope.checkServerResponse({
                            response: response
                        })) {
                            $scope.CountryList = response.Country;
                            $scope.lookupGetBYLookupTypeIDForSuffix();
                        }
                    }, function (data) {
                        if (data != null) {
                            $scope.showStatusMessage({
                                message: data.message,
                                type: "error"
                            });
                        }
                    });
            } catch (ex) {
                if (ex != null) {
                    $scope.showStatusMessage({
                        message: ex.message,
                        type: "error"
                    });
                }
                $exceptionHandler(ex);
            }
        }
        // Get State List by CountryID
        $scope.getStateByCountryID = function (CountryID) {
            try {
                countryStateService.getStateByCountryID(sessionStorage.Key, CountryID)
                    .then(function (response) {
                        $scope.StatList = null;
                        if ($scope.checkServerResponse({
                            response: response
                        })) {
                            $scope.StatList = response.State;
                            angular.forEach($scope.StatList, function (value, index) {
                                if ($scope.stateID == $scope.StatList[index].StateCode) {
                                    $scope.addressState = $scope.stateID;
                                }
                            });
                            if ($scope.StatList == null || $scope.StatList.length == 0) {
                                $scope.person.IndividualPlaceofBirthStateCode = '';
                            } else {
                                $scope.person.IndividualPlaceofBirthState = '';
                            }
                        }
                    }, function (data) {
                        if (data != null) {
                            $scope.showStatusMessage({
                                message: data.message,
                                type: "error"
                            });
                        }
                    });
            } catch (ex) {
                if (ex != null) {
                    $scope.showStatusMessage({
                        message: ex.message,
                        type: "error"
                    });
                }
                $exceptionHandler(ex);
            }
        }

        // // // Get Lookup List for Gender
        // // $scope.lookupGetBYLookupTypeIDForGender = function () {
        // //   try {
        // //     lookupService.getLookUpDataByTypeId(LookUpType.GenderTypeId,sessionStorage.Key)
        // //       .then(function(response){ response = response.data;
        // //         $scope.hideStatusMessage();
        // //         $scope.hideValidationSummary();
        // //         if ($scope.checkServerResponse({response:response})) {
        // //           $scope.Gender = response.Lookup;
        // //         }
        // //       })
        // //       .error(function (data) {
        // //         $scope.showStatusMessage(data, "error");
        // //       });
        // //   }
        // //   catch (ex) {
        // //     if (ex != null) {  $scope.showStatusMessage({
        //         message :ex.message,
        //         type: "error"
        //     });
        // }
        // //       $exceptionHandler(ex);
        // //   }
        // // }

        // // // Get Lookup List for Hair color
        // // $scope.lookupGetBYLookupTypeIDForHairColor = function () {
        // //   try {
        // //     lookupService.getLookUpDataByTypeId(LookUpType.HairColorTypeId, sessionStorage.Key)
        // //       .then(function(response){ response = response.data;
        // //         $scope.hideStatusMessage();
        // //         $scope.hideValidationSummary();
        // //         if ($scope.checkServerResponse({response:response})) {
        // //           $scope.HairColor = response.Lookup;
        // //         }
        // //       })
        // //       .error(function (data) {
        // //         $scope.showStatusMessage(data, "error");
        // //       });
        // //   }
        // //   catch (ex) {
        // //     if (ex != null) { 
        //     $scope.showStatusMessage({
        //         message :ex.message,
        //         type: "error"
        //     });
        //  }
        // //     $exceptionHandler(ex);
        // //   }
        // // }



        // Get LicenseStatus
        $scope.licenseStatusTypeGetAll = function () {
            try {
                typeValuesService.getTypeValuesByTypeName(TypeValue.LicenseStatus, sessionStorage.Key)
                    .then(function (response) {
                        if ($scope.checkServerResponse({
                            response: response
                        })) {
                            $scope.LicenseStatusList = response.LicenseStatusTypeGetList;
                            $scope.individualBYIndividualId($scope.individualId);
                            $scope.individualLicenseDetailBYIndividualId($scope.individualId);

                            $scope.ConfigurationGetbySettings('FullNameorConcatenateFNandLNinHeader');
                            $scope.ConfigurationGetbySettings('DisplayProfessionalTitleSuffix');
                            $scope.ConfigurationGetbySettings('DisplayOtherAttributesSection');
                            $scope.ConfigurationGetbySettings('DisplayCitizenshipSection');
                            $scope.ConfigurationGetbySettings('DisplayBirthPlaceSection');

                        }
                    }, function (data) {
                        if (data != null) {
                            $scope.showStatusMessage({
                                message: data.message,
                                type: "error"
                            });
                        }
                    });
            } catch (ex) {
                if (ex != null) {
                    $scope.showStatusMessage({
                        message: ex.message,
                        type: "error"
                    });
                }
                $exceptionHandler(ex);
            }
        }

        $scope.individualLicenseDetailBYIndividualId = function (individualId) {
            $scope.loadingPersonalDetails(true, "Please wait...");
            try {
                backofficeIndividualLicenseService.individualLicenseDetailBYIndividualId(sessionStorage.Key, individualId)
                    .then(function (response) {
                        if ($scope.checkServerResponse({
                            response: response
                        })) {
                            $scope.IndividualLicenseList = response.IndividualLicenseList;
                        }
                    }, function (data) {
                        $scope.loadingPersonalDetails(false);
                        $scope.showStatusMessage({
                            message: data.message,
                            type: "error"
                        });
                    });
            } catch (ex) {
                $scope.loadingPersonalDetails(false);
                if (ex != null) {
                    $scope.showStatusMessage({
                        message: ex.message,
                        type: "error"
                    });
                }
            }
        };

        $scope.individualBYIndividualId = function (individualId) {
            try {
                $scope.loadingPersonalDetails(true, "Please wait...");
                backofficeIndividualPersonalService.individualBYIndividualId(sessionStorage.Key, individualId)
                    .then(function (response) {
                        if ($scope.checkServerResponse({
                            response: response
                        })) {
                            if (response.IndividualResponse != null) {
                                $scope.person = response.IndividualResponse[0];
                                if (!!$scope.person.DateOfBirth && !angular.isDate($scope.person.DateOfBirth))
                                    $scope.person.DateOfBirth = $scope.person.DateOfBirth.getDateObj();
                                $scope.getAge($scope.person.DateOfBirth);
                                $scope.personDetail = angular.copy($scope.person);
                                $scope.OrignalpersonDetail = angular.copy($scope.person);
                                sessionStorage.currentUserEmail = $scope.personDetail.Email;
                                var Licensedata = $filter('filter')($scope.LicenseStatusList, function (d) {
                                    if (d.LicenseStatusTypeId == $scope.personDetail.LicenseStatusTypeId)
                                        return d;
                                });
                                $scope.personDetail.LicenseStatusTypeName = ((!!Licensedata && Licensedata.length > 0) ? Licensedata[0].LicenseStatusTypeName : '');

                                if ($scope.person.IndividualPlaceofBirthCountry != null && $scope.person.IndividualPlaceofBirthCountry > 0) {
                                    $scope.getStateByCountryID($scope.person.IndividualPlaceofBirthCountry);
                                } else {
                                    $scope.getStateByCountryID($rootScope.DefaultCountryId);
                                }
                            }
                        }
                        $scope.loadingPersonalDetails(false);
                    }, function (data) {
                        $scope.loadingPersonalDetails(false);
                        $scope.showStatusMessage({
                            message: data.message,
                            type: "error"
                        });
                    });
            } catch (ex) {
                $scope.loadingPersonalDetails(false);
                if (ex != null) {
                    $scope.showStatusMessage({
                        message: ex.message,
                        type: "error"
                    });
                }
                $exceptionHandler(ex);
            }
        }

        //Clear Add person form 
        $scope.clearAddPerson = function (personalForm) {
            if ($scope.personAdd) {
                $scope.personAdd.FirstName = "";
                $scope.personAdd.MiddleName = "";
                $scope.personAdd.SSN = "";
                $scope.personAdd.DateOfBirth = "";
                $scope.personAdd.LastName = "";
                $scope.personAdd.SuffixId = "";
                $scope.personAdd.Age = "";
                $scope.personAdd.USCitizen = null;
                $scope.personAdd.PlaceofBirthCountry = "";
                $scope.personAdd.PlaceOfBirth = "";
                $scope.personAdd.PlaceofBirthCity = "";
                $scope.personAdd.PlaceofBirthState = "";
                $scope.personAdd.Gender = "";
                $scope.personAdd.SuffixTypeId = "";
                $scope.personAdd.PrefixTypeId = "";
                $scope.personAdd.ProfTitleSuffixTypeId = "";
                $scope.personAdd.IndividualOtherHairColor = "";
                $scope.personAdd.IndividualOtherEyeColor = "";
                $scope.personAdd.IndividualOtherWeight = "";
                $scope.personAdd.IndividualOtherHeight = '';
                $scope.personAdd.CitizenshipCountryId = '';
                $scope.personAdd.IndividualPlaceofBirthStateCode = '';
                personalAddForm.$setPristine();
            }
        }

        // Clear Person Tab..
        $scope.clearPerson = function (personalForm) {
            $scope.person = angular.copy($scope.OrignalpersonDetail);
            personalForm.$setPristine();
            $scope.viewConfig.isPersonalEdit = false;
        }

        //Add New Person
        $scope.individualAdd = function (personalForm) {
            try {

                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                //if ($scope.validateData(personalAddForm)) {
                if ($scope.personAdd.IndividualOtherHairColor != null && $scope.personAdd.IndividualOtherHairColor != '' && $scope.personAdd.IndividualOtherHairColor != undefined) {
                    var hairColor = $filter('filter')($scope.HairColor, function (d) {
                        if (d.LookupDesc.toLowerCase() === $scope.personAdd.IndividualOtherHairColor.toLowerCase()) {
                            return d;
                        }
                    }, true);
                    $scope.personAdd.IndividualOtherHairColor = (hairColor != null && hairColor.length > 0) ? hairColor[0].LookupDesc : null;
                } else {
                    $scope.personAdd.IndividualOtherHairColor = null;
                }

                if ($scope.personAdd.IndividualOtherEyeColor != null && $scope.personAdd.IndividualOtherEyeColor != '' && $scope.personAdd.IndividualOtherHairColor != undefined) {
                    var eyeColor = $filter('filter')($scope.EyeColor, function (d) {
                        if (d.LookupDesc.toLowerCase() === $scope.personAdd.IndividualOtherEyeColor.toLowerCase()) {
                            return d;
                        }
                    }, true);
                    $scope.personAdd.IndividualOtherEyeColor = (eyeColor != null && eyeColor.length > 0) ? eyeColor[0].LookupDesc : null;
                } else {
                    $scope.personAdd.IndividualOtherEyeColor = null;
                }

                if ($scope.personAdd.SSN && $scope.personAdd.SSN.length > 4) {
                    var ssn = angular.copy($scope.personAdd.SSN);
                    $scope.personAdd.Last4SSN = ssn.substring((ssn.length - 4));
                }

                $scope.loadingPersonalDetails(true, "Please wait...");
                //  console.log($scope.personAdd);
                $scope.personAdd.IsActive = true;
                backofficeIndividualPersonalService.individualSave([$scope.personAdd], sessionStorage.Key)
                    .then(function (response) {
                        //  console.log(response);
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse({
                            response: response
                        })) {
                            $scope.showStatusMessage(response.Message, "success");
                            $scope.personDetail = angular.copy($scope.personAdd);
                            $scope.viewConfig.isPersonalEdit = false;
                        }
                        $scope.loadingPersonalDetails(false);
                    }, function (data) {
                        $scope.loadingPersonalDetails(false);
                        if (data != null) {
                            $scope.showStatusMessage({
                                message: data.message,
                                type: "error"
                            });
                        }
                    });
                //}
            } catch (ex) {
                $scope.loadingPersonalDetails(false);
                if (ex != null) {
                    $scope.showStatusMessage({
                        message: ex.message,
                        type: "error"
                    });
                }
                $exceptionHandler(ex);
            }
        }

        // Save Person
        $scope.individualSave = function (personalForm) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                if ($scope.validateData({
                    form: personalForm
                })) {
                    $scope.beginSaveInformation();
                    if ($scope.person.IndividualOtherHairColor != null && $scope.person.IndividualOtherHairColor != '' && $scope.person.IndividualOtherHairColor != undefined) {
                        var hairColor = $filter('filter')($scope.HairColor, function (d) {
                            if (d.LookupDesc.toLowerCase() === $scope.person.IndividualOtherHairColor.toLowerCase()) {
                                return d;
                            }
                        }, true);
                        $scope.person.IndividualOtherHairColor = (hairColor != null && hairColor.length > 0) ? hairColor[0].LookupDesc : null;
                    } else {
                        $scope.person.IndividualOtherHairColor = null;
                    }

                    if ($scope.person.IndividualOtherEyeColor != null && $scope.person.IndividualOtherEyeColor != '' && $scope.person.IndividualOtherHairColor != undefined) {
                        var eyeColor = $filter('filter')($scope.EyeColor, function (d) {
                            if (d.LookupDesc.toLowerCase() === $scope.person.IndividualOtherEyeColor.toLowerCase()) {
                                return d;
                            }
                        }, true);
                        $scope.person.IndividualOtherEyeColor = (eyeColor != null && eyeColor.length > 0) ? eyeColor[0].LookupDesc : null;
                    } else {
                        $scope.person.IndividualOtherEyeColor = null;
                    }

                    if ($scope.person.SSN && $scope.person.SSN.length > 4) {
                        var ssn = angular.copy($scope.person.SSN);
                        $scope.person.Last4SSN = ssn.substring((ssn.length - 4));
                    }

                    $scope.loadingPersonalDetails(true, "Please wait...");
                    //console.log($scope.person);
                    backofficeIndividualPersonalService.individualSave([$scope.person], sessionStorage.Key)
                        .then(function (response) {
                            $scope.hideStatusMessage();
                            $scope.hideValidationSummary();
                            if ($scope.checkServerResponse({
                                response: response
                            })) {
                                $scope.logDataChanges();
                                $scope.showStatusMessage({
                                    message: response.Message,
                                    type: "success"
                                });
                                $scope.personDetail = angular.copy($scope.person);
                                if ($scope.OrignalpersonDetail.FirstName.toLowerCase() != $scope.person.FirstName.toLowerCase() || $scope.OrignalpersonDetail.LastName.toLowerCase() != $scope.person.LastName.toLowerCase())
                                    $scope.saveData($scope.OrignalpersonDetail);
                                $scope.OrignalpersonDetail = angular.copy($scope.person);
                                $scope.viewConfig.isPersonalEdit = false;
                                var requestData = {
                                    EntityId: $scope.individualId,
                                    EntityType: "I"
                                };
                                dcaIService.updateName(sessionStorage.Key, requestData).then(function (responseUpdate) {
                                    if (!responseUpdate.Status) {
                                        $scope.showStatusMessage({
                                            messageContent: 'Some error occured !',
                                            messageType: 'error'
                                        });
                                    }
                                    $scope.loadingPersonalDetails(false);
                                }, function (err) {
                                    $scope.showStatusMessage({
                                        messageContent: 'Some error occured !',
                                        messageType: 'error'
                                    });
                                    $scope.loadingPersonalDetails(false);
                                });
                            }
                        }, function (data) {
                            $scope.loadingPersonalDetails(false);
                            if (data != null) {
                                $scope.showStatusMessage({
                                    message: data.message,
                                    type: "error"
                                });
                            }
                        });
                }
            } catch (ex) {
                $scope.loadingPersonalDetails(false);
                if (ex != null) {
                    $scope.showStatusMessage({
                        message: ex.message,
                        type: "error"
                    });
                }
                $exceptionHandler(ex);
            }
        }

        $scope.individualUnPaidInvoiceByIndividualId = function (individualId) {
            try {
                $scope.UnPaidInvoice = [];
                $scope.totalUnPaidAmount = 0;
                backofficeIndividualPersonalService.IndividualUnPaidInvoiceByIndividualId(sessionStorage.Key, individualId)
                    .then(function (response) {
                        response = response.data;
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse({
                            response: response
                        })) {
                            if (response.RevFeeDueResponseList != null) {
                                $scope.UnPaidInvoice = response.RevFeeDueResponseList;
                                angular.forEach($scope.UnPaidInvoice, function (val, ind) {
                                    $scope.totalUnPaidAmount = $scope.totalUnPaidAmount + $scope.UnPaidInvoice[ind].FeeAmount;
                                });
                            }
                        }
                    }, function (data) {
                        data = data.data;
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        }

        ///Getting Configuration Setting by configuration string
        $scope.ConfigurationGetbySettings = function (configuration) {
            try {
                configurationService.configurationGetbySettings(sessionStorage.Key, configuration)
                    .then(function (response) {
                        if ($scope.checkServerResponse({
                            response: response
                        })) {
                            if (response.ConfigurationList) {
                                if (configuration == 'FullNameorConcatenateFNandLNinHeader') {
                                    $scope.fullnameOrConcatenate = response.ConfigurationList[0] ? response.ConfigurationList[0].Value : 'F';
                                } else if (configuration == 'DisplayProfessionalTitleSuffix') {
                                    $scope.displayproffestionalTitlesuffix = response.ConfigurationList[0] ? (response.ConfigurationList[0].Value == "True" ? true : false) : true;
                                } else if (configuration == 'DisplayOtherAttributesSection') {
                                    $scope.displayOtherAttributesSection = response.ConfigurationList[0] ? (response.ConfigurationList[0].Value == "True" ? true : false) : true;
                                } else if (configuration == 'DisplayCitizenshipSection') {
                                    $scope.displayCitizenshipSection = response.ConfigurationList[0] ? (response.ConfigurationList[0].Value == "True" ? true : false) : true;
                                } else if (configuration == 'DisplayBirthPlaceSection') {
                                    $scope.displayBirthPlaceSection = response.ConfigurationList[0] ? (response.ConfigurationList[0].Value == "True" ? true : false) : true;
                                }
                            }
                        }
                        return;
                    }, function (data) {
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
                return;
            }
        }

        // Get Lookup List for Hair color
        $scope.lookupGetBYLookupTypeIDForHairColor = function () {
            try {
                lookupService.getLookUpDataByTypeId(LookUpType.HairColorTypeId, sessionStorage.Key)
                    .then(function (response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse({
                            response: response
                        })) {
                            $scope.HairColor = response.Lookup;
                        }
                    }, function (data) {
                        $scope.showStatusMessage({
                            message: data.message,
                            type: "error"
                        });
                    });
            } catch (ex) {
                if (ex != null) {
                    $scope.showStatusMessage({
                        message: ex.message,
                        type: "error"
                    });
                }
                $exceptionHandler(ex);
            }
        }

        // Get Lookup List for Eye color
        $scope.lookupGetBYLookupTypeIDForEyeColor = function () {
            try {
                lookupService.getLookUpDataByTypeId(LookUpType.EyeColorTypeId, sessionStorage.Key)
                    .then(function (response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse({
                            response: response
                        })) {
                            $scope.EyeColor = response.Lookup;
                            $scope.filterEyeColor = response.Lookup;
                        }
                    }, function (data) {
                        $scope.showStatusMessage({
                            message: data.message,
                            type: "error"
                        });
                    });
            } catch (ex) {
                if (ex != null) {
                    $scope.showStatusMessage({
                        message: ex.message,
                        type: "error"
                    });
                }
                $exceptionHandler(ex);
            }
        }

        $scope.keydown = function (event) {
            var code = event.which || event.keyCode;
            if (((code >= 48 && code <= 57) || code == 9 || code == 190 || code == 116 || code == 8 || code == 123)) {
                return true;
            }
            event.preventDefault();
        }

        $scope.GetAllPrefix = function () {
            try {
                typeValuesService.getTypeValuesByTypeName(TypeValue.Prefix, sessionStorage.Key)
                    .then(function (response) {
                        if ($scope.checkServerResponse({
                            response: response
                        })) {
                            $scope.Prefixes = response.RefPrefixResponseList;
                        }
                    }, function (data) {
                        if (data != null) {
                            $scope.showStatusMessage({
                                message: data.message,
                                type: "error"
                            });
                        }
                    });
            } catch (ex) {
                if (ex != null) {
                    $scope.showStatusMessage({
                        message: ex.message,
                        type: "error"
                    });
                }
                $exceptionHandler(ex);
            }
        }

        $scope.GetAllSuffix = function () {
            try {
                typeValuesService.getTypeValuesByTypeName(TypeValue.Suffix, sessionStorage.Key)
                    .then(function (response) {
                        if ($scope.checkServerResponse({
                            response: response
                        })) {
                            $scope.Suffixes = response.RefSuffixResponseList.orderBy('SortOrder');
                        }
                    }, function (data) {
                        if (data != null) {
                            $scope.showStatusMessage({
                                message: data.message,
                                type: "error"
                            });
                        }
                    });
            } catch (ex) {
                if (ex != null) {
                    $scope.showStatusMessage({
                        message: ex.message,
                        type: "error"
                    });
                }
                $exceptionHandler(ex);
            }
        };

        $scope.GetAllProfTitleSuffix = function () {
            try {
                typeValuesService.getTypeValuesByTypeName(TypeValue.ProfTitleSuffix, sessionStorage.Key)
                    .then(function (response) {
                        if ($scope.checkServerResponse({
                            response: response
                        })) {
                            $scope.ProfTitleSuffixes = response.RefProfTitleSuffixResponseList;
                        }
                    }, function (data) {
                        if (data != null) {
                            $scope.showStatusMessage({
                                message: data.message,
                                type: "error"
                            });
                        }
                    });
            } catch (ex) {
                if (ex != null) {
                    $scope.showStatusMessage({
                        message: ex.message,
                        type: "error"
                    });
                }
                $exceptionHandler(ex);
            }
        };

        $scope.lookupGetBYLookupTypeIDForHairColor();
        $scope.lookupGetBYLookupTypeIDForEyeColor();
        $scope.GetAllPrefix();
        $scope.GetAllSuffix();
        $scope.GetAllProfTitleSuffix();

        $scope.onsuffixChange = function () {
            var suffix = $filter('filter')($scope.Suffixes, function (d) {
                if (d.SuffixTypeId == $scope.person.SuffixTypeId)
                    return d;
            }, true);

            $scope.person.SuffixTypeCode = (suffix && suffix.length > 0) ? suffix[0].SuffixTypeCode : null;
        }

        $scope.onPrefixChange = function () {
            var prefix = $filter('filter')($scope.Prefixes, function (d) {
                if (d.PrefixTypeId == $scope.person.PrefixTypeId)
                    return d;
            }, true);

            $scope.person.PrefixTypeCode = (prefix && prefix.length > 0) ? prefix[0].PrefixTypeCode : null;
        }

        $scope.onProfTitlesuffixChange = function () {
            var proTitleSuffix = $filter('filter')($scope.ProfTitleSuffixes, function (d) {
                if (d.ProfTitleSuffixTypeId == $scope.person.ProfTitleSuffixTypeId)
                    return d;
            }, true);

            $scope.person.ProfTitleSuffixTypeCode = (proTitleSuffix && proTitleSuffix.length > 0) ? proTitleSuffix[0].ProfTitleSuffixTypeCode : null;
        }

        $scope.onkeyPressHairColor = function (event) {
            var keycode = event.which || event.keyCode;
            if (keycode == 9 || keycode == 13) {
                if ($scope.person.IndividualOtherHairColor != null && $scope.person.IndividualOtherHairColor != '' && $scope.person.IndividualOtherHairColor != undefined) {
                    var hairColor = $filter('filter')($scope.HairColor, function (d) {
                        if (d.LookupDesc.toLowerCase() === $scope.person.IndividualOtherHairColor.toLowerCase()) {
                            return d;
                        }
                    }, true);
                    $scope.person.IndividualOtherHairColor = (hairColor != null && hairColor.length > 0) ? hairColor[0].LookupDesc : null;
                } else {
                    $scope.person.IndividualOtherHairColor = null;
                }
            }
        }

        $scope.onkeyPressEyeColor = function (event) {
            var keycode = event.which || event.keyCode;
            if (keycode == 9 || keycode == 13) {
                if ($scope.person.IndividualOtherEyeColor != null && $scope.person.IndividualOtherEyeColor != '' && $scope.person.IndividualOtherHairColor != undefined) {
                    var eyeColor = $filter('filter')($scope.EyeColor, function (d) {
                        if (d.LookupDesc.toLowerCase() === $scope.person.IndividualOtherEyeColor.toLowerCase()) {
                            return d;
                        }
                    }, true);
                    $scope.person.IndividualOtherEyeColor = (eyeColor != null && eyeColor.length > 0) ? eyeColor[0].LookupDesc : null;
                } else {
                    $scope.person.IndividualOtherEyeColor = null;
                }
            }
        }

        $scope.saveData = function (OrignalpersonDetail) {
            $scope.loadingPersonalDetails(true, "Please wait...");
            var nameData = angular.copy(OrignalpersonDetail);
            nameData.IndividualNameStatusId = 3;
            backofficeIndividualNameService.individualNameSave([nameData], sessionStorage.Key).then(function (res) {
                $scope.loadingPersonalDetails(false);
            }, function (error) {

            });
        }

        if (!!$scope.individualId)
            $scope.init($scope.individualId);
    }
})();