﻿
angular.module('app.backofficeAdministor').controller("ConfigurationController", ["$scope", "$controller", "ConfigurationService", "$state", "utilityService",
function ($scope, $controller, ConfigurationService, $state, utilityService) {
    $controller('GlobalController', { $scope: $scope });
    $scope.recordlist = [];

    //Codes
    $scope.Password = "";
    $scope.Description = "";
    //DocumentList
    $scope.ApplicationType = "";
    $scope.DocumentType = "";
    $scope.Applicant = false;
    $scope.Office = false;
    $scope.MaxSize = "";
    $scope.Description = "";
    //ApplicationStatusColor
    $scope.Active = "";
    $scope.ActiveOnProbation = "";
    //DeficiencyReason
    $scope.DRApplicationType = "";
    $scope.DeficiencyName = "";
    $scope.Description = "";
    $scope.LetterSubject = "";
    $scope.LetterContent = "";
    $scope.Other = false;
    $scope.IsActive = false;

    $scope.init = function () {
        try {
            createTab();
        }
        catch (ex) {
        }
    };

    var createTab = function () {
        $scope.TabList = [
            //{ Id: 1, Text: 'Codes', IsActive: false },
            //{ Id: 2, Text: 'Upload Document List', IsActive: false },
            //{ Id: 3, Text: 'Application Status Color', IsActive: false },
            { Id: 4, Text: 'Deficiency Reason', IsActive: false },
            { Id: 5, Text: 'Application Configuration', IsActive: true }
            // { Id: 6, Text: 'Criteria', IsActive: false },
            // { Id: 7, Text: 'Rooms', IsActive: false },
        ];
        $scope.selectedTab = 5;

        var data = {
            "PageName": "Configuration - Application Configuration",
            "SessionId": sessionStorage.Key,
            "IndividualId": (sessionStorage.IndividualId) ? sessionStorage.IndividualId : 0,
            "EntityId": (sessionStorage.ProviderId) ? sessionStorage.ProviderId : 0,
            "UserId": sessionStorage.UserId,
            "Username": "",
            "RequestUrlReferrer": ""
        };
        utilityService.AuditVisitInfo(data);
    };

    $scope.tabItemClick = function (item) {
        item.IsActive = true;
        $scope.selectedTab = item.Id;
        //deactivate all tab and activate selected tab
        for (var i = 0; i < $scope.TabList.length; i++) {
            if ($scope.TabList[i].Id != item.Id) {
                $scope.TabList[i].IsActive = false;
            }
        }

        var data = {
            "PageName": "Configuration - " + item.Text,
            "SessionId": sessionStorage.Key,
            "IndividualId": (sessionStorage.IndividualId) ? sessionStorage.IndividualId : 0,
            "EntityId": (sessionStorage.ProviderId) ? sessionStorage.ProviderId : 0,
            "UserId": sessionStorage.UserId,
            "Username": "",
            "RequestUrlReferrer": ""
        };
        utilityService.AuditVisitInfo(data);
    };

    //Application Configuration
    $scope.getApplicationConfigurationList = function () {

        if ($scope.isSessionActive()) {
            try {
                ConfigurationService.GetAllConfigurationList(sessionStorage.Key)
                .then(function(response){ response = response.data;
                    if (response.StatusCode == null && response.Message != "") {
                        sessionStorage.Key = "";
                        sessionStorage.URL = window.location.href;
                        if (sessionStorage.LoginPage && sessionStorage.LoginPage != '') {
                            try {
                                $state.go(sessionStorage.LoginPage);
                            } catch (ex) {
                                $state.go('app.BackofficeLoginstaff');
                            }
                        } else {
                            $state.go('app.BackofficeLoginstaff');
                        }

                    } else {
                        $scope.recordlist = response;
                    }

                }, function(data) { data = data.data;
                });
            }
            catch (ex) {
                showStatusMessage(ex.message, "error");
            }
        }
        else {
            sessionStorage.URL = window.location.href;
            if (sessionStorage.LoginPage && sessionStorage.LoginPage != '') {
                try {
                    $state.go(sessionStorage.LoginPage);
                } catch (ex) {
                    $state.go('app.BackofficeLoginstaff');
                }
            } else {
                $state.go('app.BackofficeLoginstaff');
            }
        }
    };
    $scope.editApplicationConfiguration = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.updateApplicationConfiguration = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.getApplicationConfiguration = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    //Codes
    $scope.insertCode = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.getCodesList = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.getCode = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.editCode = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.deleteCode = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.updateCode = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    //DocumentList
    $scope.insertDocument = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.getDocumentList = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.getDocument = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.editDocument = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.deleteDocument = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.updateDocument = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    //ApplicationStatusColor
    $scope.getStatusColorList = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.getStatusColor = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.editStatusColor = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.updateStatusColor = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    //DeficiencyReason
    $scope.insertDeficiencyReason = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.getDeficiencyReasonList = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.getDeficiencyReason = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.editDeficiencyReason = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.updateDeficiencyReason = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
    $scope.deleteDeficiencyReason = function () {
        try {

        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }
}]);