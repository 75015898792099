(function() {
    'use strict'
    angular.module('app.core')
        .directive('datepickerLocaldate', datepickerLocaldateDirective);

    datepickerLocaldateDirective.$inject = ['$parse'];

    function datepickerLocaldateDirective($parse) {
        return {
            restrict: 'A',
            require: ['ngModel'],
            link: function(scope, element, attr, ctrls) {
                var ngModelController = ctrls[0];

                // // // called with a JavaScript Date object when picked from the datepicker
                // // ngModelController.$parsers.push(function(viewValue) {
                // //     // undo the timezone adjustment we did during the formatting
                // //     //viewValue.setMinutes(viewValue.getMinutes() + viewValue.getTimezoneOffset());
                // //     // we just want a local date in ISO format
                // //     return viewValue;
                // // });

                // called with a 'yyyy-mm-dd' string to format
                ngModelController.$formatters.push(function(modelValue) {
                    if (!modelValue) {
                        return undefined;
                    }
                    // date constructor will apply timezone deviations from UTC (i.e. if locale is behind UTC 'dt' will be one day behind)
                    var dt = modelValue;
                    if (!!modelValue && !angular.isDate(modelValue))
                        dt = new Date(modelValue);
                    // else if (!!modelValue && angular.isDate(modelValue))
                    //     dt = modelValue;
                    // // 'undo' the timezone offset again (so we end up on the original date again)
                    //dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
                    return dt;
                });
            }
        };
    }
})();