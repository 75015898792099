(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeIndividualPrimaryBoardService', backofficeIndividualPrimaryBoardService)

    backofficeIndividualPrimaryBoardService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeIndividualPrimaryBoardService($http, $q, WebApiUrl, AppConfig) {
        var _individualCertificationInfoGETBYIndividualId = function (Key, IndividualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualCertificationInfoGETBYIndividualId/' + Key + '?IndividualId=' + IndividualId));
        };

        var _saveIndividualCertificationInfo = function (Key, objData) {
            return returnDefferedResult($http.post(WebApiUrl + "Individual/SaveIndividualCertificationInfo/" + Key, objData));
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        return {
            individualCertificationInfoGETBYIndividualId: _individualCertificationInfoGETBYIndividualId,
            saveIndividualCertificationInfo : _saveIndividualCertificationInfo,
        }
    }
})();