(function () {
    'use strict'

    var editContentProfileName = 'EDITCONTENT';

    var userCanEditContent = false;

    angular.module('app.core')
    .directive('inlumonContentEditor', ['$rootScope', '$q', '$compile', 'InlumonContentEditorService', InlumonContentEditor])
    .run(['$rootScope', 'InlumonContentEditorService', function ($rootScope, InlumonContentEditorService) {
        //Check permissions for user here before creating elements
        //If user has permission, setupEditElement($scope, element, attrs);
        $rootScope.$watch(function () { return (sessionStorage.isBackOfficeUser == 'true' || sessionStorage.isBackOfficeUser == true) && sessionStorage.UserID; }, function () {
            if (!!sessionStorage.UserID && (sessionStorage.isBackOfficeUser == 'true' || sessionStorage.isBackOfficeUser == true)) {
                InlumonContentEditorService.getUserProfileList(sessionStorage.Key, sessionStorage.UserID)
                .then(function (data) {
                    if (data.Status && !!data.UserProfileList) {
                        userCanEditContent = data.UserProfileList.whereEquals(editContentProfileName, 'ProfileCode').firstOrDefault();
                    }
                }, function (err) {
                    console.log(err);
                });
            }
        });
    }]);

    function InlumonContentEditor($rootScope, $q, $compile, InlumonContentEditorService) {
        var pathToPopoverTemplate = 'app/core/directive/inlumon-content-editor/inlumon-content-editor.html';

        var createEditButton = function (element, contentId) {
            var popover =   'uib-popover uib-popover-template="\'app/core/directive/inlumon-content-editor/inlumon-content-editor.popup.html\'" ' + 
                            'popover-title="Content Editor" popover-placement="auto bottom" popover-is-open="editorIsOpen" popover-append-to-body="true"' + 
                            'popover-trigger="\'click\'"';
            var style = 'position: absolute;' +
                        'top: 0px;' +
                        'left: 0px;' +
                        'margin-left: -8px;' +
                        'margin-top: -8px;' +
                        'cursor: pointer;';
            var btn = angular.element('<a {0} style="{1}"><span class="glyphicon glyphicon-pencil icon-flipped"></span></a>'.format(popover, style));
            
            return btn;
        };

        var saveContent = function (content) {
            var deferred = $q.defer();

            try {
                $rootScope.displayMainLoader(true, 'Saving...');
                
                InlumonContentEditorService.updateContentInfo(sessionStorage.Key, content)
                .then(function (data) {
                    console.log('Content saved successfully!');
                    deferred.resolve(data);
                    $rootScope.displayMainLoader(false);
                }, function (err) {
                    deferred.reject(err);
                    $rootScope.displayMainLoader(false);
                });
            } catch (ex) {
                deferred.reject(ex);
                $rootScope.displayMainLoader(false);
            }

            return deferred.promise;
        };

        var setupNewEditorScope = function ($scope, content) {
            var originalContent = angular.copy(content);
            var editorScope = $scope.$new(true);

            //Setup scope variables
            editorScope.content = content;
            editorScope.editorIsOpen = false;

            editorScope.ckOptions = {
                language: 'en',
                allowedContent: true,
                entities: false,
                disableNativeSpellChecker: false,
                scayt_autoStartup: true,
                toolbarGroups: [
                    { name: 'document',    groups: [ 'mode', 'document', 'doctools' ] },
                    { name: 'clipboard',   groups: [ 'clipboard', 'undo' ] },
                    { name: 'editing',     groups: [ 'find', 'selection', 'spellchecker' ] },
                    //{ name: 'forms' },
                    '/',
                    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                    { name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
                    { name: 'links' },
                    { name: 'insert' },
                    '/',
                    { name: 'styles' },
                    { name: 'colors' },
                    { name: 'tools' },
                    //{ name: 'others' },
                    { name: 'about' }
                ],
                // toolbar: [
                //     ['Source'],
                //     ['RemoveFormat', 'CopyFormatting', '-', 'TextColor', 'BGColor', '-', 'NumberedList', 'BulletedList', '-', 'Outdent', 'Index', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '/'],
                //     ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript', '-', 'Link', 'Unlink', '/'],
                //     ['Styles', 'Format', 'Font', 'FontSize']
                // ]
            };

            var resetContent = function () {
                content.ContentItemLkDesc = originalContent.ContentItemLkDesc;
            };

            //Setup scope methods
            editorScope.saveContent = function () {
                saveContent(content)
                .then(function (data) {
                    originalContent = angular.copy(content);
                    editorScope.editorIsOpen = false;
                }, function (err) {
                    console.log(err);
                });
            };
            editorScope.cancelContent = function () {
                resetContent();
                editorScope.editorIsOpen = false;
            };

            //Setup a watch on the editorIsOpen to ensure that if the editor closes that the 
            //content reverts back to the original content
            var removeEditorOpenWatch = editorScope.$watch('editorIsOpen', function (newVal) {
                var ed = editorScope;
                if (!newVal) {
                    //Destroy the CKEDITOR instance in the popup before it gets destroyed
                    //(This removes an annoying error when the editor is not destroyed properly)
                    resetContent();
                }
            });

            //Cleanup when the scope is destroyed
            editorScope.$on('$destroy', function () {
                removeEditorOpenWatch();
            });

            return editorScope;
        };

        var setupEditElement = function ($scope, element, attrs) {
            //Get ContentID
            var propName = attrs.inlumonContentEditor;
            if (!propName) {
                throw 'Content editor requires a property name';
            }
            var val = $scope.$eval(propName);
            if (!val) {
                throw 'Content editor requires a content object at the property name';
            }
            var content = val;
            var editorScope = setupNewEditorScope($scope, content);

            //Create the edit button
            var btn = createEditButton(element, content.ContentItemLkId);

            var compBtn = $compile(btn)(editorScope);

            $scope.$evalAsync(function () {
                element.append(compBtn);
            });
        };

        return {
            restrict: 'A',
            link: function ($scope, element, attrs) {
                var hasBeenSetup = false;
                //It won't work unless the key is there
                if (!sessionStorage.Key) {
                    return;
                }

                $scope.$watch(function () { return userCanEditContent; }, function () {
                    if (!!userCanEditContent && !hasBeenSetup) {
                        setupEditElement($scope, element, attrs);
                        hasBeenSetup = true;
                    }
                });
            }
        };
    }
})();