(function () {
    'use strict'
    angular.module('app.core')
        .directive('providerMultipleFileUpload', providerMultipleFileUpload);

    providerMultipleFileUpload.$inject = [];

    function providerMultipleFileUpload() {
        return {
            restrict: 'E',
            scope: {
                providerDocumentUpload: '=providerDocumentUpload',
                showSubmitOptions: '=showSubmitOptions',
                showListing: '=showListing',
                documentsList: '=documentsList',
                hideForm: "=?hideForm",
                documentNameCaption: "=?documentNameCaption",
                preSelectFirstDocumentType: "=?preSelectFirstDocumentType",
                referenceNumber: "=?referenceNumber",
                saveDocument: "&?",
                hideDocumentName: "=?hideDocumentName",
                hideDocumentType: "=?hideDocumentType"
            },
            link: function (scope, element, attrs) {
                try {
                    if (typeof scope.saveDocument == 'function') {
                        scope.saveDocument({
                            theDirFn: scope.providerDocumentSave
                        });
                    }
                } catch (ee) {
                    console.log(ee);
                }
            },
            // link: function (scope) {
            //     angular.extend(scope.providerDocumentUpload, {
            //         providerDocumentSave: scope.providerDocumentSave
            //     });
            // },
            templateUrl: "app/core/directive/provider-multiple-file-upload/provider-multiple-file-upload.html",
            controller: "ProviderMultipleFileUploadController"
        }
    }
})();
