(function () {
    'use strict'

    angular.module('app.report')
    .controller('CannedReportsController', ['$scope', '$rootScope', '$state', '$q', '$timeout', 'CannedReportsService', 'utilityService', 'InlumonFormBuilderService', CannedReportsController]);

    function CannedReportsController($scope, $rootScope, $state, $q, $timeout, CannedReportsService, utilityService, InlumonFormBuilderService) {
        var _key = sessionStorage.Key;
        var _childName = '';

        if (!!$state && !!$state.current && !!$state.current.routeData) {
            $scope.title = (!!$state.current.routeData.title) ? $state.current.routeData.title : $scope.title;
            $scope.pageName = (!!$state.current.routeData.pageName) ? $state.current.routeData.pageName : $scope.pageName;
            $scope.cannedReportId = (!!$state.current.routeData.cannedReportId) ? $state.current.routeData.cannedReportId : $scope.cannedReportId;
            $scope.searchParams = (!!$state.current.routeData.searchParams) ? $state.current.routeData.searchParams : $scope.searchParams;
            $scope.pagerParams = (!!$state.current.routeData.pagerParams) ? $state.current.routeData.pagerParams : $scope.pagerParams;
            $scope.autoExecReport = (!!$state.current.routeData.autoExecReport) ? $state.current.routeData.autoExecReport : $scope.autoExecReport;
            $scope.configName = (!!$state.current.routeData.configName) ? $state.current.routeData.configName : $scope.configName;
        }

        $scope.getUIConfig = function () {
            var config = {};
        
            for (var prop in $scope.config.UIConfigs['Default']) {
                if (!$scope.config.UIConfigs['Default'].hasOwnProperty(prop)) { continue; }
        
                config[prop] = $scope.config.UIConfigs['Default'][prop];
            }
        
            if (!!$scope.config.UIConfigs[$scope.configName]) {
                for (var prop in $scope.config.UIConfigs[$scope.configName]) {
                    if (!$scope.config.UIConfigs[$scope.configName].hasOwnProperty(prop)) { continue; }
        
                    config[prop] = $scope.config.UIConfigs[$scope.configName][prop];
                }
            }
        
            return config;
        };

        $scope.getConfig = function () {
            var deferred = $q.defer();
        
            try {
                utilityService.getConfigData('/components/report/canned-reports/canned-reports.server-config.json')
                .then(function(data) {
                    if (!!data) {
                        $scope.config = data;
        
                        $scope.uiConfig = $scope.getUIConfig();
        
                        $scope.isConfigLoaded = true;
                    }
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
        
            return deferred.promise;
        };

        $scope.getCannedReports = function () {
            var deferred = $q.defer();
            
            try {
                CannedReportsService.getCannedReports(sessionStorage.Key, $scope.pageName, $scope.cannedReportId)
                .then(function(data) {
                    if (data.Status) {
                        $scope.cannedReportsList = data.CannedReports;

                        if ($scope.cannedReportsList.length == 1) {
                            $scope.current.cannedReport = $scope.cannedReportsList.firstOrDefault();
                            generateForm($scope.current.cannedReport.FormId);

                            if (!!$scope.autoExecReport) {
                                //Auto-execute the report
                                $scope.operationPending(true, 'Running Report...');
            
                                $scope.executeCannedReport(true)
                                .then(function () {
                                    $scope.operationPending(false);
                                }, errorHandler);
                            }
                        }

                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.executeCannedReport = function (resetResults) {
            var deferred = $q.defer();
            
            try {
                var request = {
                    CannedReportId: $scope.current.cannedReport.CannedReportId,
                    PageName: $scope.pageName,
                    FormValues: angular.copy($scope.current.form),

                    PageNumber: $scope.pager.currentPage,
                    PageSize: $scope.pager.pageSize,
                    SortColumn: $scope.pager.sortColumn,
                    SortDirection: $scope.pager.sortDirection,
                    IsExcelReport: false
                };
                if (!!$scope.onCannedReportExecuting) {
                    $scope.onCannedReportExecuting(request);
                }
                CannedReportsService.executeCannedReport(sessionStorage.Key, request)
                .then(function(data) {
                    if (!!data.Status) {
                        if (!!resetResults) {
                            //We don't want to override the changes that inlumon-table made to the object, so
                            //just reset the params specified in the blankPager variable
                            for (var pagerProp in blankPager) {
                                $scope.pager[pagerProp] = blankPager[pagerProp];
                            }
                            $scope.results = angular.copy(blankResults);
                        }
                        
                        $scope.results.headers = {};
                        $scope.results.reportData = data.ReportData;
                        $scope.pager.setTotalRecords(data.TotalRecords);

                        if (!!$scope.results.reportData && $scope.results.reportData.length > 0) {
                            var sampleData = $scope.results.reportData[0];
                            for (var headerName in sampleData) {
                                if (!['TotalRecord', 'TotalRecords'].includes(headerName)) {
                                    var headerConfig = {
                                        label: headerName
                                    };
                                    $scope.results.headers[headerName] = headerConfig;
                                }
                            }
                        }

                        if (!!$scope.onCannedReportExecuted) {
                            $scope.onCannedReportExecuted(request);
                        }

                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.executeCannedReportAndDownloadDocument = function () {
            var deferred = $q.defer();
            
            try {
                var request = {
                    CannedReportId: $scope.current.cannedReport.CannedReportId,
                    PageName: $scope.pageName,
                    FormValues: angular.copy($scope.current.form),
                    DocumentType: 'xlsx',
                    IsExcelReport: true
                };
                if (!!$scope.onCannedReportDownloading) {
                    $scope.onCannedReportDownloading(request);
                }
                CannedReportsService.executeCannedReportAndDownloadDocument(sessionStorage.Key, request)
                .then(function(data) {
                    if (!!$scope.onCannedReportDownloaded) {
                        $scope.onCannedReportDownloaded(request);
                    }

                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.getCannedReportsClicked = function () {
            $scope.operationPending(true, 'Loading...');
            
            $scope.getCannedReports()
            .then(function () {
                $scope.operationPending(false);
            }, errorHandler);
        };

        $scope.cannedReportChanged = function () {
            $scope.resetReportClicked();
        };

        $scope.resetReportClicked = function () {
            if (!!$scope.searchParams) {
                $scope.current.form = angular.copy($scope.searchParams);
            } else {
                $scope.current.form = {};
            }
            $scope.pager = angular.copy(blankPager);
            $scope.results = angular.copy(blankResults);

            //Change the form
            if (!!$scope.current.cannedReport) {
                generateForm($scope.current.cannedReport.FormId);
            } else {
                generateForm(null);
            }

            if (!!$scope.resetReportExt) {
                $scope.resetReportExt();
            }
        };

        $scope.executeCannedReportClicked = function (form) {
            if (!$scope.validateForm(form)) { return; }

            $scope.operationPending(true, 'Running Report...');

            $scope.executeCannedReport(true)
            .then(function () {
                $scope.operationPending(false);
            }, errorHandler);
        };

        $scope.downloadCannedReportClicked = function (form) {
            if (!$scope.validateForm(form)) { return; }

            $scope.operationPending(true, 'Generating Report...');

            $scope.executeCannedReportAndDownloadDocument()
            .then(function () {
                $scope.operationPending(false);
            }, errorHandler);
        };

        $scope.tableHeaderClicked = function (headerProperty, sortDirection) {
            if (!sortDirection) {
                headerProperty = blankPager.sortColumn;
                sortDirection = blankPager.sortDirection;
            }
            $scope.pager.sortColumn = headerProperty;
            $scope.pager.sortDirection = sortDirection;

            $scope.operationPending(true, 'Loading...');
            
            $scope.executeCannedReport(false)
            .then(function () {
                $scope.operationPending(false);
            }, errorHandler);
        };

        $scope.pageChanged = function () {
            $scope.operationPending(true, 'Loading...');
            
            $scope.executeCannedReport(false)
            .then(function () {
                $scope.operationPending(false);
            }, errorHandler);
        };

        var blankPager = {
            pageChanged: $scope.pageChanged,

            currentPage: 1,
            maxSize: 5,
            pageSize: 50,

            sortColumn: null,
            sortDirection: null
        };
        var blankResults = {};

        $scope.init = function () {
            $scope.current = {};
            $scope.uiLogic = {};
            $scope.refTables = {};

            if (!$scope.title) {
                $scope.title = 'Canned Reports';
            }

            if (typeof($scope.showSearch) == 'undefined') {
                $scope.showSearch = true;
            }

            if (!!$scope.searchParams) {
                $scope.current.form = angular.copy($scope.searchParams);
            } else {
                $scope.current.form = {};
            }

            if (!!$scope.pagerParams) {
                //Copy the specified pager params into the blankPager
                for (var pagerProp in $scope.pagerParams) {
                    blankPager[pagerProp] = $scope.pagerParams[pagerProp];
                }
            }

            $scope.pager = angular.copy(blankPager);
            $scope.results = angular.copy(blankResults);

            if (!!$scope.initExt) {
                $scope.initExt();
            }
        };

        $scope.refTablesLoaded = function () {
            $scope.getConfig()
            .then(function() {
                $scope.getCannedReports()
                .then(function () {
                    $scope.operationPending(false);
                }, errorHandler);
            }, errorHandler);
        };

        var errorHandler = function (err) {
            $scope.showStatusMsg('-', err);
            $scope.operationPending(false);
        };

        var generateForm = function (formId) {
            if (!!formId) {
                //Generate the form from the formId
                $scope.current.reportFormConfig = InlumonFormBuilderService.generateFormConfig(formId, 'reportForm', 'current.form');
            }
            
            $scope.uiLogic.refreshingForm = true;
            $timeout(function () {
                $scope.uiLogic.refreshingForm = false;
            }, 1);
        };

        //Copy report data to outgoing parameter
        $scope.$watch('results.reportData', function (newVal) {
            $scope.currentReportData = newVal;
        });
    }
})();