﻿(function () {
    'use strict'
    angular.module('app.core')
        .controller('EmailerController', ['$scope', 'emailerFactory', '$q', 'WebApiUrl', 'utilityService', function ($scope, emailerFactory, $q, WebApiUrl, utilityService) {
            var _key = sessionStorage.Key;
            var _userId = sessionStorage.UserID;
            $scope.netValidator = {};
            $scope.init = function () {
                //$scope.FromEmail = 'info@inlumon.com';
                if ($scope.loadfromemail) {
                    ConfigurationGetbySettings('EmailFrom');
                    //IndividualContactBYIndividualId($scope.IndividualId)
                }
                initializedata();
            };

            $scope.options = {
                language: 'en',
                allowedContent: true,
                entities: false,
                disableNativeSpellChecker: false,
                scayt_autoStartup: true
            };

            var initializedata = function () {
                $scope.FromEmail = angular.copy($scope.d_FromEmail);
                $scope.EmailTo = angular.copy($scope.d_EmailTo);
                $scope.EmailSubject = angular.copy($scope.d_EmailSubject);
                $scope.EmailText = angular.copy($scope.d_EmailText);
            };

            var ConfigurationGetbySettings = function (sett) {
                ShowLoader()
                emailerFactory.ConfigurationGetbySettings(_key, sett).then(function (res) {
                    if (res && res.data.Status && res.data.ConfigurationList && res.data.ConfigurationList.length > 0) {
                        var setVal = res.data.ConfigurationList[0].Value;
                        if (setVal && setVal != null) {
                            $scope.FromEmail = setVal;
                        }
                    }
                    HideLoader()
                }, function () {
                    HideLoader()
                });
            };

            var validateForm = function (form, error) {
                $scope.netValidator.errorMessages = [];
                if (!!form) {
                    form.$submitted = true;
                    var errorMessages = angular.copy(form.errorMessages);
                    angular.forEach(errorMessages, function (errorMessage, index) {
                        $scope.netValidator.errorMessages.push(errorMessage);
                    });
                }
                if (error && error != '') {
                    $scope.netValidator.errorMessages.push({
                        errorMessage: error
                    });
                }
                if (!!$scope.netValidator.errorMessages && $scope.netValidator.errorMessages.length > 0) {
                    $scope.netValidator.showError = true;
                    $scope.netValidator.isValid = false;
                    return false;
                } else {
                    $scope.netValidator.showError = false;
                    $scope.netValidator.isValid = true;
                    return true;
                }
            };

            $scope.SendEmail = function (form) {

                if (!validateForm(form)) {
                    return;
                }
                if ($scope.istextrequired && (!$scope.EmailText || $scope.EmailText == '')) {
                    return;
                }
                ShowLoader()
                var EmailCommunication = {
                    EmailFrom: $scope.FromEmail,
                    Subject: $scope.EmailSubject,
                    CommunicationText: $scope.EmailText,
                    CommunicationSource: $scope.d_CommunicationSrc,
                    IndividualId: $scope.IndividualId,
                    ApplicationId: $scope.ApplicationId,
                    Type: $scope.d_CommunicationType,
                    CreatedBy: _userId,
                    EmailTo: $scope.EmailTo,
                    IsActive: true,
                    IsInternalOnly: false,
                    CommunicationLogDate: new Date(),
                    EffectiveDate: new Date(),
                    UserIdFrom: _userId,
                    ReferenceNumber: $scope.referencenumber ? ($scope.referencenumber + '') : null,
                    UserIdTo: $scope.useridto,
                    SaveCommunicationOnly: $scope.sendemail
                };
                emailerFactory.IndividualCommunicationSave(_key, EmailCommunication).then(function (res) {
                    $scope.EmailSentSuccess = "Email sent successfully."
                    if ($scope.sendsms) {
                        if ($scope.smstemplateid && $scope.smstemplateid != 0) {
                            utilityService.getTemplateById($scope.smstemplateid).then(function (tres) {
                                if (tres) {
                                    var msgSubj = tres.TemplateSubject;
                                    var msg = tres.TemplateDesc;
                                    SendNotification('SMS', $scope.IndividualId, msgSubj, msg);
                                }
                            });
                        } else {
                            SendNotification('SMS', $scope.IndividualId, '', '');
                        }
                    }
                    if ($scope.sendemailnotification) {
                        if ($scope.emailnotificationtemplateId && $scope.emailnotificationtemplateId != 0) {
                            utilityService.getTemplateById($scope.emailnotificationtemplateId).then(function (tres) {
                                if (tres) {
                                    var msgSubj = tres.TemplateSubject;
                                    var msg = tres.TemplateDesc;
                                    SendNotification('EMAIL', $scope.IndividualId, msgSubj, msg);
                                }
                            });
                        } else {
                            SendNotification('EMAIL', $scope.IndividualId, '', '');
                        }
                    }
                    HideLoader();
                    clearMessage();
                    try {
                        $scope.onsend({
                            parentItem: $scope.callbackitem,
                            newItem: EmailCommunication
                        });
                        //$scope.EmailSubject = '';
                        $scope.EmailText = '';
                        // $scope.EmailTo = '';
                        form.$submitted = false;
                        form.$setPristine();
                        $scope.netValidator = {};

                    } catch (ex) { }
                }, function () {
                    HideLoader()
                });
            };

            //var IndividualContactBYIndividualId = function (indvId) {
            //    if (indvId) {
            //        emailerFactory.IndividualContactBYIndividualId(_key, indvId).then(function (res) {
            //            if (res.data.Status) {
            //                var list = res.data.IndividualContactResponse
            //                if (list && list.length > 0) {
            //                    var item = $.grep(list, function (i) {
            //                        return (i.ContactTypeId==1 && i.ContactStatusId==1);
            //                    })
            //                    if (item && item.length > 0) {
            //                        IndividualContactPhone = item[0].ContactInfo;
            //                    }
            //                }


            //            }
            //        });
            //    }
            //};

            var SendNotification = function (type, indvID, msgSubj, msg) {
                if (indvID && indvID != 0 && ((type == 'SMS') || (type == 'EMAIL'))) {
                    var data = {};
                    if (type == 'SMS') {
                        data = {
                            sendSMS: true,
                            ToNumber: '',
                            FullTextMessage: (msg && msg != '' ? msg : 'NVDOE: You have a secure message. Please login to the portal to view the secure message.'),
                            IndividualId: indvID,
                            CommunicationType: 1
                        };
                    }
                    if (type == 'EMAIL') {
                        data = {
                            sendEmail: true,
                            Subject: (msgSubj && msgSubj != '' ? msgSubj : 'Secure Message Notification'),
                            Email: IndividualContactEmail,
                            BodyContent: (msg && msg != '' ? msg : 'You have a secure message. Please login to the portal to view the secure message.<br /> <br /> <br />Alabama Board of Funeral Service'),
                            IndividualId: indvID,
                            CommunicationType: 0
                        };
                    }
                    emailerFactory.SendEmailAndSms(_key, data).then(function (res) {
                        HideLoader();
                    }, function (res) {
                        HideLoader();
                    });
                }
            };

            var clearMessage = function () {
                setTimeout(function () {
                    $scope.$apply(function () {
                        $scope.EmailSentSuccess = undefined;
                    });
                }, 5000);
            };
            $scope.ClearSaveSection = function (form) {
                $scope.EmailSubject = '';
                $scope.EmailText = '';
                $scope.EmailTo = '';
                form.$submitted = false;
                form.$setPristine();
                $scope.netValidator = {};
                try {
                    $scope.onclose({
                        item: $scope.callbackitem
                    });
                } catch (ex) { }
            };
        }]);
})();