(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .directive('otherLicensesValidation', ['Messages', OtherLicensesValidation]);

    function OtherLicensesValidation(Messages) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.$on('$destroy', function () {
                    if (!$scope.natValidation) {
                        $scope.natValidation = {};
                    }
                    
                    $scope.natValidation.showError = false;
                    $scope.natValidation.errorMessages = [];
                });

                $scope.validateForm = function () {
                    var valid = true;

                    $scope.natValidation.showError = false;
                    $scope.natValidation.errorMessages = [];

                    if ($scope.showYesNoQuestion && $scope.content.yesNoQuestion.response.ContentItemResponse == null) {
                        $scope.natValidation.errorMessages.push({
                            propName: "yesNoQuestion",
                            errorMessage: Messages.General.Errors.AnswerQuestion
                        });
                        valid = false;
                    }

                    if (!!$scope.content.yesNoQuestion.response.ContentItemResponse) {
                        if ($scope.otherLicenseList.length == 0) {
                            $scope.natValidation.errorMessages.push({
                                propName: "licenseList",
                                errorMessage: Messages.General.Errors.OneLicense
                            });
                            $scope.natValidation.showError = true;
                            valid = false;
                        }
                    }

                    return valid;
                };

                $scope.validateLicense = function () {
                    var valid = true;

                    $scope.natValidation.showError = false;
                    $scope.natValidation.errorMessages = [];

                    for (var licProp in $scope.current.license) {
                        var val = $scope.current.license[licProp];
                        //If it is a configured value
                        if (!!$scope.uiConfig.FieldTableHeaders[licProp]) {
                            var label = $scope.uiConfig.FieldTableHeaders[licProp].label;

                            if (typeof (val) === 'undefined' || val == null ||
                                (typeof (val) != 'boolean' && typeof (val) != 'number' && !val)) {
                                $scope.natValidation.showError = true;
                                $scope.natValidation.errorMessages.push({
                                    propName: licProp,
                                    errorMessage: Messages.General.Errors.IsRequired(label)
                                });
                                valid = false;
                            }
                        }
                    }

                    return valid;
                };
            }
        };
    }
})();