angular.module('natApp').factory('examFactory', ['$http', '$q', 'WebApiUrl', function ($http, $q, WebApiUrl) {

    var returnDeferredResult = function (request) {
        var deferred = $q.defer();
        request.then(function (response) {
            deferred.resolve(response);
        }, function (response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    return {

        // Category API Calls
        getAllCategories: function (key, pager) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/GetAllCategories/' + key, pager));
        },

        saveCategory: function (key, data) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/SaveCategory/' + key, data));
        },

        // Section API Calls
        getAllSections: function (key, pager) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/GetAllSections/' + key, pager));
        },

        saveSection: function (key, data) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/SaveSection/' + key, data));
        },

        // Question API Calls
        getAllQuestionTypes: function (key) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetAllQuestionTypes/' + key));
        },

        getAllQuestions: function (key, pager) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/GetAllQuestions/' + key, pager));
        },

        getSingleQuestion: function (key, ID) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetSingleQuestion/' + key + '?ID=' + ID));
        },

        getQuestionsByExam: function (key, ID) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetQuestionsFromExam/' + key + '?ID=' + ID));
        },

        getQuestionsBySection: function (key, ExamSectionId, Amount) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetQuestionsBySection/' + key + '?ExamSectionId=' + ExamSectionId + '&Amount=' + Amount));
        },

        getQuestionAnswers: function (key, ID) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetQuestionAnswers/' + key + '?ID=' + ID));
        },

        getQuestionConflicts: function (key, ID) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetQuestionConflicts/' + key + '?ID=' + ID));
        },

        getSingleQuestionDocument: function (key, ID) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetSingleQuestionDocument/' + key + '?ID=' + ID));
        },

        saveQuestion: function (key, data) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/SaveQuestion/' + key, data));
        },

        saveAnswer: function (key, data) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/SaveQuestionAnswer/' + key, data));
        },

        saveConflict: function (key, data) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/SaveQuestionConflict/' + key, data));
        },

        saveExamQuestionDocument: function (key, data) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/SaveExamQuestionDocument/' + key, data));
        },

        // Exam API Calls
        getAllExams: function (key, pager) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/GetAllExams/' + key, pager));
        },

        getExam: function (key, ID) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetExam/' + key + '?ID=' + ID));
        },

        saveExam: function (key, data) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/SaveExam/' + key, data));
        },

        getQuestionLink: function (key, ID) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetQuestionLinks/' + key + '?ID=' + ID));
        },

        saveQuestionLink: function (key, data) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/SaveQuestionLink/' + key, data));
        },

        getSectionLink: function (key, pager) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/GetExamSectionLinks/' + key, pager));
        },

        saveSectionLink: function (key, data) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/SaveExamSectionLink/' + key, data));
        },

        // Verify Exam Call
        verifyExam: function (obj) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/VerifyExam/' + obj));
        },

        // Entity Exam Calls
        getEntityExams: function (key, pager) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/GetEntityExams/' + key, pager));
        },

        // Entity Exam Calls
        getEntityExamResult: function (key, request) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetEntityExamResult/' + key, { params: request }));
        },

        getSingleEntityExam: function (key, ID) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetSingleEntityExam/' + key + '?ID=' + ID));
        },

        getCompletedEntityExams: function (key, ID) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetCompletedEntityExams/' + key + '?ID=' + ID));
        },

        getIndividualEntityExam: function (key, ID) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetIndividualEntityExam/' + key + '?ID=' + ID));
        },

        deleteQuestionsEntityExam: function (key, ID) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/DeleteQuestionsEntityExam/' + key + '?ID=' + ID));
        },

        saveEntityExam: function (key, data) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/SaveEntityExam/' + key, data));
        },

        getEntityExaminees: function (key, pager) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/GetEntityExaminees/' + key, pager));
        },

        getEntityExamQA: function (key, EntityExamId) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetEntityExamQA/' + key + '?EntityExamId=' + EntityExamId));
        },

        saveEntityExamQA: function (key, data) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/SaveEntityExamQA/' + key, data));
        },

        getExamLocationLk: function (key) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetExamLocationLk/' + key));
        },

        saveExamLocationLk: function (key, data) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/SaveExamLocationLk/' + key, data));
        },

        getRefExamLocation: function (key) {
            return returnDeferredResult($http.get(WebApiUrl + 'Exam/GetRefExamLocation/' + key));
        },

        // Report Calls
        getExamSectionReport: function (key, pager) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/GetExamSectionReport/' + key, pager));
        },

        getExamQuestionReport: function (key, pager) {
            return returnDeferredResult($http.post(WebApiUrl + 'Exam/GetExamQuestionReport/' + key, pager));
        },

        getExamSectionXLSReport: function (key, pager) {
            return returnDeferredResult($http.post(WebApiUrl + 'Report/GetXLSForExamSectionReport/' + key, pager));
        },

        getExamQuestionXLSReport: function (key, pager) {
            return returnDeferredResult($http.post(WebApiUrl + 'Report/GetXLSForExamQuestionReport/' + key, pager));
        },

        getPDFForExam: function (key, pager) {
            return returnDeferredResult($http.post(WebApiUrl + 'Report/GetPDFForExam/' + key, pager));
        },

        sendEligibilityAndEmail: function (key, entityExamReq) {
            return returnDeferredResult($http.post(WebApiUrl + 'ApplicationProcessing/SendEligibilityAndEmail/' + key, entityExamReq));
        }

    };

}]);