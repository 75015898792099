(function () {
    'use strict'

    angular.module('app.enforcement')
        .directive('complaintChangeLogLogic', ['ComplaintChangeLogExtService', '$q', complaintChangeLogLogic]);

    function complaintChangeLogLogic(ComplaintChangeLogExtService, $q) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.initExt = function () {

                };
            }
        };
    }
})();
