(function () {
    'use strict'

    angular.module('app.enforcement')
    .factory('ComplaintUpdateExtService', ['$http', '$q', 'WebApiUrl', ComplaintUpdateExtService]);

    function ComplaintUpdateExtService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getUsersByRole = function (key, id, name) {
            var request = {
                RoleId: id,
                RoleName: name
            };
            return responseHandler($http.post(WebApiUrl + 'Users/UsersGetAllByRole/' + key + '/', request));
        };

        return {
            getUsersByRole: _getUsersByRole
        };
    }
})();