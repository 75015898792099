(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintUpdate', [ComplaintUpdate]);

    function ComplaintUpdate() {
        return {
            restrict: 'E',
            scope: {
                complaint: '=',
                configName: '@',
                saveCompleted: '&?',
                readOnly: '=?',
                queueName: '=?'
            },
            link: function ($scope, element, attrs) {

            },
            templateUrl: 'app/components/backoffice/enforcement/complaint-update/complaint-update.html',
            controller: 'ComplaintUpdateController'
        };
    }
})();