(function () {
    'use strict'

    angular.module('app.pciCompliantPayment')
    .directive('pciCompliantPaymentLogic', ['$window', '$q', '$http', '$state', '$timeout', 'WebApiUrl', 'PCICompliantPaymentServiceExt', PCICompliantPaymentLogic]);

    function PCICompliantPaymentLogic($window, $q, $http, $state, $timeout, WebApiUrl, PCICompliantPaymentServiceExt) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                if ($scope.entityType == 'I') {
                    var getNewApplicationFeeInvoiceDetail = function (feeDetail, moduleName, descPrefix) {
                        var applicationFeeInvoiceDetail = {
                            InvoiceDetailID: 0,
                            InvoiceID: $scope.payment.InvoiceId,
                            IndividualId: $scope.entityId,
                            ApplicationId: $scope.applicationId,
                            TransType: "ORD",
                            Description: (descPrefix + "-" + feeDetail.FeeDetailDesc + ((!!feeDetail.LicenseNumber) ? ("-" + feeDetail.LicenseNumber) : "")),
                            Quantity: 1,
                            Amount: feeDetail.FeeAmount,
                            AmtBilled: feeDetail.FeeAmount,
                            AmtPaid: 0,
                            AmtAdjusted: 0,
                            AmtBalance: feeDetail.FeeAmount,
                            TransCode: feeDetail.FeeTypeCode,
                            Module: moduleName,
                            DetailCode: feeDetail.FeeDetailCode,
                            ReferenceNumber: ((!!feeDetail.FeeTypeCode ? feeDetail.FeeTypeCode : '') + '-' + (!!feeDetail.FeeDetailCode ? feeDetail.FeeDetailCode : '')),
                            GPAdjust: 0,
                            GPPayment: 0,
                            CreatedBy: $scope.userId
                        };
                        if ($scope.payment.PaymentMethod == 3) {
                            applicationFeeInvoiceDetail.AmtPaid = applicationFeeInvoiceDetail.Amount;
                            applicationFeeInvoiceDetail.AmtBalance = applicationFeeInvoiceDetail.Amount - applicationFeeInvoiceDetail.AmtPaid;
                        }
                        return applicationFeeInvoiceDetail;
                    };

                    var getOverrideApplicationFeeInvoiceDetail = function (feeDetail, moduleName, descPrefix) {
                        var applicationFeeInvoiceDetail = {
                            InvoiceDetailID: 0,
                            InvoiceID: $scope.payment.InvoiceId,
                            IndividualId: $scope.entityId,
                            ApplicationId: $scope.applicationId,
                            TransType: "ORD",
                            Description: (descPrefix + "-" + feeDetail.FeeDetailDesc + ((!!feeDetail.LicenseNumber) ? ("-" + feeDetail.LicenseNumber) : "")),
                            Quantity: 1,
                            Amount: feeDetail.FeeAmount,
                            AmtBilled: feeDetail.FeeAmount,
                            AmtPaid: 0,
                            AmtAdjusted: 0,
                            AmtBalance: feeDetail.FeeAmount,
                            TransCode: feeDetail.FeeTypeCode,
                            Module: moduleName,
                            DetailCode: feeDetail.FeeDetailId,
                            ReferenceNumber: ((!!feeDetail.FeeTypeCode ? feeDetail.FeeTypeCode : '') + '-' + (!!feeDetail.FeeDetailCode ? feeDetail.FeeDetailCode : '')),
                            GPAdjust: 0,
                            GPPayment: 0,
                            CreatedBy: $scope.userId
                        };
                        if ($scope.payment.PaymentMethod == 3) {
                            applicationFeeInvoiceDetail.AmtPaid = applicationFeeInvoiceDetail.Amount;
                            applicationFeeInvoiceDetail.AmtBalance = applicationFeeInvoiceDetail.Amount - applicationFeeInvoiceDetail.AmtPaid;
                        }
                        return applicationFeeInvoiceDetail;
                    };
            
                    var getIndividualTransactionObject = function (revFeeMasterId, amount, transCode) {
                        return {
                            RevFeeMasterId: revFeeMasterId,
                            DebitAmt: 0,
                            CreditAmt: amount,
                            FeePaidAmount: amount,
                            OrigFeeAmount: amount,
                            TransCode: transCode,
                            ModuleCode: "ADVDUES",
                            TransType: "PAY"
                        }
                    };
            
                    var getMiscFeeInvoiceDetail = function (feeDetailRow) {
                        var licenseEndorsementFeeInvoiceDetail = {
                            InvoiceDetailID: 0,
                            InvoiceID: $scope.payment.InvoiceId,
                            IndividualId: $scope.entityId,
                            ApplicationId: $scope.applicationId,
                            TransType: "ORD",
                            Description: feeDetailRow.feeDetailType.FeeDetailDesc,
                            Quantity: 1,
                            Amount: feeDetailRow.Amount,
                            AmtBilled: feeDetailRow.Amount,
                            AmtPaid: feeDetailRow.Amount,
                            AmtAdjusted: 0,
                            AmtBalance: 0,
                            TransCode: feeDetailRow.feeDetailType.FeeTypeCode,
                            Module: 'MiscPay',
                            DetailCode: feeDetailRow.feeDetailType.FeeDetailCode,
                            ReferenceNumber: feeDetailRow.referenceNumber,
                            GPAdjust: 0,
                            GPPayment: 0,
                            CreatedBy: $scope.userId
                        };
                        return licenseEndorsementFeeInvoiceDetail;
                    };
            
                    var getInvoiceDetailRowFormInvoiceDetails = function (transCode, referenceNumber, invoiceDetailsData, removeItem) {
                        var invoiceDetail = null;
                        if (!invoiceDetailsData) {
                            return invoiceDetail;
                        }
                        var i;
                        for (i = 0; i < invoiceDetailsData.length; i++) {
                            if (invoiceDetailsData[i].TransCode == transCode &&
                                (invoiceDetailsData[i].ReferenceNumber == referenceNumber)) {
                                invoiceDetail = invoiceDetailsData[i];
                                break;
                            }
                        }
                        if (!!removeItem && !!invoiceDetail) {
                            invoiceDetailsData.splice(i, 1);
                        }
                        return invoiceDetail;
                    };
            
                    $scope.getInvoiceDetailEntries = function (invoiceDetailsData) {
                        var invoiceDetails = [];
    
                        if ($scope.isMiscPayment) {
                            angular.forEach($scope.feeDetailRows, function (feeDetailRow, index) {
                                feeDetailRow.referenceNumber = ('Misc-' + feeDetailRow.feeDetailType.FeeDetailId);
                                var miscFeeInvoiceDetail = getInvoiceDetailRowFormInvoiceDetails(
                                    feeDetailRow.feeDetailType.FeeTypeCode, feeDetailRow.referenceNumber, invoiceDetailsData, true);
                                if (!miscFeeInvoiceDetail) {
                                    miscFeeInvoiceDetail = getMiscFeeInvoiceDetail(feeDetailRow);
                                    invoiceDetails.push(miscFeeInvoiceDetail);
                                } else if (!!miscFeeInvoiceDetail) {
                                    miscFeeInvoiceDetail.ModifiedBy = $scope.userId;
                                    miscFeeInvoiceDetail.Amount = feeDetailRow.Amount;
                                    miscFeeInvoiceDetail.AmtBilled = feeDetailRow.Amount;
                                    miscFeeInvoiceDetail.AmtPaid = feeDetailRow.Amount;
                                    miscFeeInvoiceDetail.AmtBalance = 0;
                                    invoiceDetails.push(miscFeeInvoiceDetail);
                                }
                            });
                        } else {
                            if (!!$scope.feeDetailRows && !!$scope.applicationInfo) {
                                $scope.feeDetailRows.forEach(function (fdr) {
                                    fdr.ReferenceNumber = ((!!fdr.FeeTypeCode ? fdr.FeeTypeCode : '') + '-' + (!!fdr.FeeDetailCode ? fdr.FeeDetailCode : ''));
                                    var feeInvoiceDetail = getInvoiceDetailRowFormInvoiceDetails(fdr.FeeTypeCode, fdr.ReferenceNumber, invoiceDetailsData, true);
                                    if (!feeInvoiceDetail) {
                                        feeInvoiceDetail = getNewApplicationFeeInvoiceDetail(fdr,
                                            ($scope.applicationInfo.ApplicationTypeId == 1 ? "RenewalApp" : ($scope.applicationInfo.ApplicationTypeId == 2 ? "NewApplication" : "")),
                                            ($scope.applicationInfo.ApplicationTypeId == 1 ? "RenApp" : ($scope.applicationInfo.ApplicationTypeId == 2 ? "NewApp" : ""))
                                        );
                                        invoiceDetails.push(feeInvoiceDetail);
                                    } else if (!!feeInvoiceDetail) {
                                        feeInvoiceDetail.ModifiedBy = $scope.userId;
                                        feeInvoiceDetail.Amount = fdr.FeeAmount;
                                        feeInvoiceDetail.AmtBilled = fdr.FeeAmount;
                                        feeInvoiceDetail.AmtPaid = 0;
                                        feeInvoiceDetail.AmtBalance = 0;
                                        if ($scope.payment.PaymentMethod == 3) {
                                            feeInvoiceDetail.AmtPaid = feeInvoiceDetail.Amount;
                                            feeInvoiceDetail.AmtBalance = feeInvoiceDetail.Amount - feeInvoiceDetail.AmtPaid;
                                        }
                                        invoiceDetails.push(feeInvoiceDetail);
                                    }
                                });
                            }
                            angular.forEach(invoiceDetailsData, function (invoiceDetailsItem) {
                                invoiceDetailsItem.IsDeleted = true;
                                invoiceDetails.push(invoiceDetailsItem);
                            });
                        }
        
                        return invoiceDetails;
                    };
                } else if ($scope.entityType == 'P') {
                    

                    $scope.getInvoiceDetailEntries = function (invoiceDetailsData) {
                        var invoiceDetails = [];
    
                        
    
                        return invoiceDetails;
                    };
                }

                $scope.onServerConfigLoaded = function () { };

                $scope.jsonPaymentPostMethod = function () {
                    var deferred = $q.defer();
                    
                    try {
                        var request = {
                            FirstName: $scope.entity.FirstName,
                            LastName: $scope.entity.LastName,
                            Amount: $scope.invoiceMaster.Balance
                        };
                        PCICompliantPaymentServiceExt.getTransactionToken(sessionStorage.Key, request)
                        .then(function (response) {
                            if (response.Status) {
                                //Now setup the iFrame
                                var lightboxParams = {
                                    ssl_txn_auth_token: response.TransactionToken
                                };

                                var callback = {
                                    onError: function (error) {
                                        console.group('Error');
                                        console.log(error);
                                        console.groupEnd();

                                        deferred.reject(response);
                                    },
                                    onCancelled: function () {
                                        console.group('Cancelled');

                                        console.groupEnd();

                                        deferred.reject(response);
                                    },
                                    onDeclined: function (response) {
                                        console.group('Declined');
                                        console.log(response);
                                        console.groupEnd();

                                        deferred.reject(response);
                                    },
                                    onApproval: function (response) {
                                        console.group('Success');
                                        console.log(response);
                                        console.groupEnd();

                                        if (!!$scope.onRedirecting) {
                                            $scope.onRedirecting();
                                        }

                                        deferred.resolve(response);

                                        $timeout(function () {
                                            //ssl_txn_id
                                            //ssl_txn_time
                                            HideLoader();
                                            $state.go('app.pciCompliantPaymentSuccess', {
                                                success: 'true',
                                                txnId: response.ssl_txn_id,
                                                txnTime: response.ssl_txn_time.getDateObj().toJSON(),
                                                message: response.ssl_result_message
                                            });
                                        }, 1000);
                                    }
                                };

                                PayWithConverge.open(lightboxParams, callback);
                            } else {
                                $scope.showStatusMsg('-', response);
                                deferred.reject(response);
                            }
                        }, function (err) {
                            $scope.showStatusMsg('-', 'Unable to initialize paymnent processing.');
                        });
                    } catch (ex) {
                        $scope.showStatusMsg('-', ex);
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;  
                };
            }
        };
    }
})();