(function () {
    'use strict'

    angular.module('app.core')
    .controller('EntityDocumentGenerationController', ['$scope', '$rootScope', '$q', '$timeout', 'EntityDocumentGenerationService', 'utilityService', EntityDocumentGenerationController]);

    function EntityDocumentGenerationController($scope, $rootScope, $q, $timeout, EntityDocumentGenerationService, utilityService) {
        var _key = sessionStorage.Key;
        var _childName = '';

        $scope.init = function () {
            $scope.uiConfig = {};
            $scope.isConfigLoaded = true;

            $scope.current = {
                license: null,
                documentTemplate: null,
                userDefinedAliases: {}
            };

            $scope.licenseList = [];
            $scope.userInfo = {};
            $scope.documentTemplates = [];
            $scope.aliasDefaults = {};
      
            $scope.displayDocumentTemplates = [];

            $timeout(function () {
                $scope.getLicenses();
                $scope.getUserInfo();
                $scope.getDocumentTemplates(null, null);
            }, 1);
        };

        $scope.licenseSelectionChanged = function () {
            $scope.current.documentTemplate = null;
            $scope.current.userDefinedAliases = {};

            $scope.displayDocumentTemplates = [];

            $scope.displayDocumentTemplates = $scope.documentTemplates.where(function (template) {
                if (!template.DisplayInList) {
                    return false;
                }
                
                if (!template.ForLicenseTypeIds.includes($scope.current.license.LicenseTypeId) && template.ForLicenseTypeIds.length > 0) {
                    return false;
                } else if (template.NotLicenseTypeIds.includes($scope.current.license.LicenseTypeId) && template.NotLicenseTypeIds.length > 0) {
                    return false;
                }
                return true;
            }).orderBy('DocumentTemplateName');
        };

        $scope.getLicenses = function () {
            var deferred = $q.defer();
            
            try {
                $scope.operationPending(true, 'Retrieving Licenses...');
                EntityDocumentGenerationService.getLicenses(sessionStorage.Key, $scope.entityId)
                .then(function(data) {
                    if (data.Status) {
                        $scope.licenseList = data.IndividualLicenseList.whereNotIncludes([5,7,8], 'LicenseStatusTypeId').orderBy('LicenseTypeName');
                    } else {
                        $scope.licenseList = [];
                    }
                    $scope.operationPending(false);
                    deferred.resolve(data);
                }, function(err) {
                    $scope.operationPending(false);
                    deferred.reject(err);
                });
            } catch (ex) {
                $scope.operationPending(false);
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.getDocumentTemplates = function (documentTemplateCode, boardInfoId) {
            var deferred = $q.defer();
            
            try {
                var search = {
                    DocumentTemplateCode: documentTemplateCode,
                    BoardInfoId: boardInfoId
                };

                EntityDocumentGenerationService.getDocumentTemplates(sessionStorage.Key, search)
                .then(function(data) {
                    if (data.Status) {
                        $scope.documentTemplates = data.DocumentTemplates;
                    } else {
                        $scope.documentTemplates = [];
                        $scope.showStatusMsg('-', data.Message);
                    }

                    $scope.documentTemplates.forEach(function (template) {
                        if (!!template.ForLicenseTypeIds) {
                            template.ForLicenseTypeIds = template.ForLicenseTypeIds.split(',').select(function (lt) { return parseInt(lt); });
                        }
                        if (!!template.NotLicenseTypeIds) {
                            template.NotLicenseTypeIds = template.NotLicenseTypeIds.split(',').select(function (lt) { return parseInt(lt); });
                        }
                        if (!!template.JSONInputParameters) {
                            template.UserDefinedAliases = JSON.parse(template.JSONInputParameters);
                        }
                    });

                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;  
        };

        $scope.getUserInfo = function () {
            var deferred = $q.defer();
            
            try {
                EntityDocumentGenerationService.getUserInfo(_key, sessionStorage.UserID)
                .then(function(data) {
                    if (data.Status) {
                        $scope.userInfo = data.Users;

                        $scope.aliasDefaults['{#AnalystName#}'] = $scope.userInfo.FirstName + ' ' + $scope.userInfo.LastName;
                        $scope.aliasDefaults['{#AnalystEmail#}'] = $scope.userInfo.Email;
                    } else {
                        $scope.userInfo = [];

                        $scope.aliasDefaults['{#AnalystName#}'] = '';
                        $scope.aliasDefaults['{#AnalystEmail#}'] = '';
                    }
                    
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.validateForm = function () {
            var errMsgs = [];

            if (!$scope.current.license) {
                errMsgs.push('You must select a license to generate a document.');
            }

            if (!$scope.current.documentTemplate) {
                errMsgs.push('You must select a document type to generate a document.');
            } 

            if (!!$scope.current.documentTemplate && !!$scope.current.documentTemplate.UserDefinedAliases) {
                $scope.current.documentTemplate.UserDefinedAliases.forEach(function (userAlias) {
                    var userAliasValue = (!!$scope.current.userDefinedAliases[userAlias.Alias]) ? $scope.current.userDefinedAliases[userAlias.Alias] : '';

                    if (!!userAlias.Required && (typeof(userAliasValue) == 'undefined' || userAliasValue == null || userAliasValue == '')) {
                        errMsgs.push('You must supply a {0} to generate this document.'.format(userAlias.Label));
                    }
                });
            }

            if (errMsgs.length > 0) {
                $scope.showStatusMsg('-', errMsgs);
                return false;
            }
            return true;
        };

        $scope.generateAndDownloadDocument = function () {
            var deferred = $q.defer();
            $scope.hideStatusMsg();

            if (!$scope.validateForm()) { return; }

            try {
                $rootScope.displayMainLoader(true, 'Generating Document...');

                var request = {
                    EntityId: $scope.entityId,
                    EntityType: $scope.entityType,
                    LicenseNumber: ($scope.current.documentTemplate.SearchLicenseNumber) ? $scope.current.license.LicenseNumber : null,
                    IndividualLicenseId: ($scope.current.documentTemplate.SearchIndividualLicenseId) ? $scope.current.license.IndividualLicenseId : null,
                    ApplicationId: ($scope.current.documentTemplate.SearchApplicationId) ? $scope.current.license.ApplicationId : null,
                    TemplateName: $scope.current.documentTemplate.DocumentTemplateCode,
                    UserDefinedAliases: $scope.current.userDefinedAliases
                };
                EntityDocumentGenerationService.generateAndDownloadDocument(sessionStorage.Key, request)
                .then(function(data) {
                    if (!data.Status && !!data.StatusCode) {
                        $scope.showStatusMsg('-', data);
                    }
                    $rootScope.displayMainLoader(false);
                    deferred.resolve(data);
                }, function(err) {
                    $rootScope.displayMainLoader(false);
                    deferred.reject(err);
                });
            } catch (ex) {
                $rootScope.displayMainLoader(false);
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.clearSelections = function () {
            $scope.hideStatusMsg();

            $scope.init();
        };
    }
})();