(function () {
    'use strict'

    angular.module('app.backofficeEntity')
        .directive('ceCourseLogic', CeCourseLogic);

    CeCourseLogic.$inject = ['backofficeCeCourseService', 'utilityService', 'multipleFileUploadService', '$rootScope'];

    function CeCourseLogic(backofficeCeCourseService, utilityService, multipleFileUploadService, $rootScope) {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                $scope.processRefEntityData = function (entityRefData) {
                    var filteredEntityRefData = [];
                    angular.forEach(entityRefData, function (entityRefDataItem) {
                        var isFiltered = true;
                        angular.forEach($scope.uiConfig.Filter, function (filterItem) {
                            if (entityRefDataItem[filterItem.field] != undefined &&
                                !!filterItem.VisibleInForm && filterItem.VisibleInForm.length > 0 &&
                                (filterItem.VisibleInForm.indexOf(entityRefDataItem[filterItem.field]) == -1)
                            ) {
                                isFiltered = false;
                            }
                        });
                        if (!!isFiltered) {
                            filteredEntityRefData.push(entityRefDataItem);
                        }
                    })
                    return filteredEntityRefData;
                };

                $scope.entityFilterDataByConfig = function (entityData) {
                    var filteredEntityData = [];

                    angular.forEach(entityData, function (entityDataItem) {
                        var isFiltered = true;
                        angular.forEach($scope.uiConfig.Filter, function (filterItem) {
                            if (entityDataItem[filterItem.field] != undefined &&
                                !!filterItem.VisibleInTable && filterItem.VisibleInTable.length > 0 &&
                                (filterItem.VisibleInTable.indexOf(entityDataItem[filterItem.field]) == -1)
                            ) {
                                isFiltered = false;
                            }
                        });
                        if (!!isFiltered) {
                            filteredEntityData.push(entityDataItem);
                        }
                    });
                    return filteredEntityData;
                };

                $scope.calculateTotals = function (ceCourses) {
                    $scope.verifiedTotalCeHours = ceCourses.whereEquals(true, 'CECourseStatusId').sum('CECourseHours');
                    $scope.unVerifiedTotalCeHours = ceCourses.whereNotEquals(true, 'CECourseStatusId').sum('CECourseHours');
                    $scope.firstYrCeHours = ceCourses.whereEquals(1, 'CECourseActivityTypeId').sum('CECourseHours');
                    $scope.secondYrCeHours = ceCourses.whereEquals(2, 'CECourseActivityTypeId').sum('CECourseHours');
                    if (!$scope.showFirstSecondYearSeletion)
                        $scope.firstYrCeHours = ceCourses.sum('CECourseHours');
                    $rootScope.ceCourseTabData.firstYrCeHours = $scope.firstYrCeHours;
                    $rootScope.ceCourseTabData.secondYrCeHours = $scope.secondYrCeHours;
                    $scope.totalCeHours = ceCourses.sum('CECourseHours');
                    $rootScope.ceCourseTabData.totalCeHours = $scope.totalCeHours;
                };

                $scope.onNgInitOtherLogic = function () {
                    $scope.getProviderIdList();
                };

                var assignSubjectAreaToCourses = function () {
                    angular.forEach($scope.ceCourseTypes, function (ceCourseType) {
                        ceCourseType.firstYrHours = 0;
                        ceCourseType.secondYrHours = 0;
                        angular.forEach($scope.firstYrCeCourses, function (firstYrCeCourseItem) {
                            if (ceCourseType.CECourseTypeId == firstYrCeCourseItem.CECourseTypeId) {
                                ceCourseType.firstYrHours = ceCourseType.firstYrHours + firstYrCeCourseItem.CECourseHours;
                            }
                        })
                        angular.forEach($scope.secondYrCeCourses, function (secondYrCeCourseItem) {
                            if (ceCourseType.CECourseTypeId == secondYrCeCourseItem.CECourseTypeId) {
                                ceCourseType.secondYrHours = ceCourseType.secondYrHours + secondYrCeCourseItem.CECourseHours;
                            }
                        })
                    });
                }

                $scope.getCurrentCycle = function () {
                    var cycle = "";
                    var currYear = new Date().getFullYear();
                    // If year is even then currYear-2 to currYear else currYear-1 to currYear+1
                    if (currYear % 2 == 0)
                        cycle = (currYear - 2) + '-' + (currYear);
                    else
                        cycle = (currYear - 1) + '-' + (currYear + 1);
                    return cycle;
                };

                $scope.getCycleStartEndDates = function () {
                    var licenseExpirationDate = '10-1';
                    $scope.currCycle = $scope.getCurrentCycle();
                    $scope.currCycleYears = $scope.currCycle.split("-");
                    $scope.prevCycle = ($scope.currCycleYears[0] - 2) + '-' + ($scope.currCycleYears[0]);
                    $scope.prevCycleYears = $scope.prevCycle.split("-");
                    $scope.currCycleStartDate = licenseExpirationDate + '-' + $scope.currCycleYears[0];
                    $scope.currCycleEndDate = licenseExpirationDate + '-' + $scope.currCycleYears[1];
                    $scope.prevCycleStartDate = licenseExpirationDate + '-' + $scope.prevCycleYears[0];
                    $scope.prevCycleEndDate = licenseExpirationDate + '-' + $scope.prevCycleYears[1];
                };

                $scope.filterCeCourseDataByCycle = function (ceCourseData) {
                    $scope.getCycleStartEndDates();
                    $scope.currCycleCeCourses = ceCourseData.where(function (ceCourseDataItem) {
                        // if (utilityService.checkIfDateGreaterOrEqual(ceCourseDataItem.CECourseDate, $scope.currCycleStartDate) && utilityService.checkIfDateGreater($scope.currCycleEndDate, ceCourseDataItem.CECourseDate)) {
                        return true;
                        // }
                        // return false;
                    });
                    $scope.prevCycleCeCourses = ceCourseData.where(function (ceCourseDataItem) {
                        if (utilityService.checkIfDateGreaterOrEqual(ceCourseDataItem.CECourseDate, $scope.prevCycleStartDate) && utilityService.checkIfDateGreater($scope.prevCycleEndDate, ceCourseDataItem.CECourseDate)) {
                            return true;
                        }
                        return false;
                    });
                    $scope.firstYrCeCourses = ceCourseData.whereEquals(1, 'CECourseActivityTypeId');
                    $scope.secondYrCeCourses = ceCourseData.whereEquals(2, 'CECourseActivityTypeId');
                    if (!$scope.showFirstSecondYearSeletion)
                        $scope.firstYrCeCourses = ceCourseData;
                    assignSubjectAreaToCourses();
                    $rootScope.ceCourseTabData.ceCourseTypes = $scope.ceCourseTypes;
                };

                $scope.processDocuments = function (ceCourseData) {
                    if (ceCourseData && ceCourseData.length > 0) {
                        multipleFileUploadService.individualDocumentProcess(sessionStorage.Key, $scope.entityId,
                            ceCourseData, $scope.documentCECourse.PropertyToProcess, $scope.documentCECourse.LinkType).then(function (responseData) {
                                ceCourseData = responseData;
                                //console.log(responseData);
                            });
                    }
                }

                $scope.getProviderIdList = function () {
                    backofficeCeCourseService.providerCECourseByProviderId("", "", sessionStorage.Key)
                        .then(function (response) {
                            if (response.StatusCode = "00") {
                                $scope.ceCourseProviders = response.ProviderCourseResponseList;
                                $scope.ProviderIdList = _.uniq(response.ProviderCourseResponseList, false, function (p) { return p.ProviderId; });
                                HideLoader();
                            }
                        }, function (data) {
                            HideLoader();
                            $scope.showStatusMsg('-', data.message);
                        });
                };

                $scope.loadProviderTypeahed = function (searchString) {
                    if (!!$scope.ProviderIdList) {
                        return $scope.ProviderIdList.filter(function (item) {
                            if ((!!item.ProviderName && item.ProviderName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)) {
                                return item;
                            }
                        });
                    }
                };

                $scope.loadCourseTypeahed = function (searchString) {
                    if (!!$scope.ceCourseProviders) {
                        return $scope.ceCourseProviders.filter(function (item) {
                            if ((!!item.CourseName && item.CourseName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) && (item.ProviderId == $scope.ceCourse.ProviderId) && (item.CourseStatus == 'Current')) {
                                return item;
                            }
                        });
                    }
                };

                $scope.SelectProviderTypeAheadItem = function (item, $model, $label, $event) {
                    $scope.ceCourse.ProviderId = item.ProviderId;
                };

                $scope.SelectCourseTypeAheadItem = function (item, $model, $label, $event) {
                    $scope.ceCourse.CECourseHours = item.CourseHours;
                    $scope.ceCourse.CourseNumber = item.CourseId;
                    $scope.ceCourse.CECourseTypeId = item.CourseTypeId;
                };

            }
        };
    }
})();