(function () {
  'use strict';

  angular.module('app.report')
    .constant("ApiUrlsForReports", {
      licensure: "Report/LicensureReportWithPager",
      dailyDeposit: "Report/DailyDepositReportWithPager",
      mailingList: "Report/MailingListReportWithPager",
      probationList: "Report/ProbationListReportWithPager",
      occupationalLicensing: "Report/OccupationalLicensingReport",
      veteranData: "Report/VeteranDataReportWithPager",
      pendingRenewal: "Report/PendingRenewalReportWithPager",
      pendingApproval: "Report/PendingApprovalReportWithPager",
      allData: "Report/AllDataDentalReportWithPager",
      FBIDOJ: "Report/FBIDOJReportWithPager"
    });

  angular.module('app.report')
    .constant("ApiUrlsForReportsXls", {
      licensure: "Report/GetXLSForLicensureReportReport",
      dailyDeposit: "Report/GetXLSForDailyDepositReport",
      mailingList: "Report/GetXLSForMailingListReport",
      probationList: "Report/GetXLSForProbationListReport",
      occupationalLicensing: "Report/GetXLSForOccupationalLicensingReport",
      veteranData: "Report/GetXLSForVeteranDataReport",
      pendingRenewal: "Report/GetXLSForPendingRenewalReport",
      pendingApproval: "Report/GetXLSForPendingApprovalReport",
      VerifyLicense: "Report/GetXLSForIndividualProviderVerifySearch",
      allData: "Report/GetXLSForAllDataDentalListReport",
      FBIDOJ: "Report/GetXLSForFBIDOJReport",
      TrialBalance: "Report/TrialBalanceReportPDF",
      TrialBalanceXLS: "Report/GetXLSForTrialBalanceReport"
    });
})();