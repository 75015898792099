﻿


(function () {
    'use strict'
    angular.module('natApp')
        .factory('BackofficeFirmRenewalListService', ['$http', '$q', 'WebApiUrl', 'AppConfig', function ($http, $q, WebApiUrl, AppConfig) {
            var baseFactory = {};
            baseFactory.RenewalSearchWithPager = function (key, PageNumber, NoOfRecords, SortColumn, SortOrder, ShowAllRecords, renewalSearchObj) {
                return returnDefferedResult($http.post(WebApiUrl + 'Renewal/ProviderRenewalSearchWithPager/' + key + '?PageNumber=' + PageNumber + '&NoOfRecords=' + NoOfRecords + '&SortColumn=' + SortColumn + '&SortOrder=' + SortOrder + '&ShowAllRecords=' + ShowAllRecords, renewalSearchObj));
            };

            baseFactory.GetProviderLicenseByCertificateNumber = function (key, certNumber) {
                return returnDefferedResult($http.get(WebApiUrl + 'Provider/GetProviderLicenseByCertificateNumber/' + key + '?CertificateNumber=' + certNumber));
            };
            baseFactory.providerSearchWithPage = function (key, PageNumber, NoOfRecords, data) {
                return returnDefferedResult($http.post(WebApiUrl + 'Provider/ProviderSearchWithPage/' + key + '?PageNumber=' + PageNumber + '&NoOfRecords=' + NoOfRecords, data));
            };
            baseFactory.ProviderRenewalSearchWithPager = function (key, PageNumber, NoOfRecords, SortColumn, SortOrder, ShowAllRecords, renewalSearchObj, QueuePage) {
                return returnDefferedResult($http.post(WebApiUrl + 'Renewal/ProviderRenewalSearchWithPager/' + key + '?PageNumber=' + PageNumber + '&NoOfRecords=' + NoOfRecords + '&SortColumn=' + SortColumn + '&SortOrder=' + SortOrder + '&ShowAllRecords=' + ShowAllRecords + '&QueuePage=' + QueuePage, renewalSearchObj));
            };
            // renewalSearchObj
            var returnDefferedResult = function (request) {
                var deferred = $q.defer();
                request.then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.reject(response);
                });
                return deferred.promise;
            };

            return baseFactory;
        }]);
})();
