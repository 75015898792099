﻿angular.module('natApp').controller("BackofficeDashboardStaffController", ["$scope", "$controller", "$state",
    function ($scope, $controller, $state) {
        $controller('GlobalController', { $scope: $scope });
        $scope.init = function () {
            google.charts.load('current', { packages: ['corechart'] });
            try {
                if ($scope.isSessionActive()) {
                    $scope.isDashboard = true;
                    google.charts.setOnLoadCallback(drawChart);
                }
                else {
                    $state.go('app.BackofficeLoginstaff');
                }
            }
            catch (ex) {
                showStatusMessage(ex.message, "error");
            }
        }

        var drawChart = function () {
            var licenseJsonData = {
                "cols": [
                    { "id": "", "label": "Month", "type": "string" },
                    { "id": "", "label": "Count", "type": "number" }
                ],
                "rows": [
                    { "c": [{ "v": "May 2018", "f": null }, { "v": 498, "f": null }] },
                    { "c": [{ "v": "June 2018", "f": null }, { "v": 593, "f": null }] },
                    { "c": [{ "v": "July 2018", "f": null }, { "v": 820, "f": null }] },
                    { "c": [{ "v": "August 2018", "f": null }, { "v": 825, "f": null }] },
                    { "c": [{ "v": "September 2018", "f": null }, { "v": 1005, "f": null }] },
                    { "c": [{ "v": "October 2018", "f": null }, { "v": 1153, "f": null }] },
                ]
            };

            var applicationJsonData = {
                "cols": [
                    { "id": "", "label": "Month", "type": "string" },
                    { "id": "", "label": "License Renewal", "type": "number" },
                    { "id": "", "label": "New License Application", "type": "number" }
                ],
                "rows": [
                    { "c": [{ "v": "May 2018", "f": null }, { "v": 498, "f": null }, { "v": 333, "f": null }] },
                    { "c": [{ "v": "June 2018", "f": null }, { "v": 593, "f": null }, { "v": 666, "f": null }] },
                    { "c": [{ "v": "July 2018", "f": null }, { "v": 820, "f": null }, { "v": 888, "f": null }] },
                    { "c": [{ "v": "August 2018", "f": null }, { "v": 825, "f": null }, { "v": 777, "f": null }] },
                    { "c": [{ "v": "September 2018", "f": null }, { "v": 1005, "f": null }, { "v": 1221, "f": null }] },
                    { "c": [{ "v": "October 2018", "f": null }, { "v": 1153, "f": null }, { "v": 1300, "f": null }] },
                ]
            };

            var licenseData = new google.visualization.DataTable(licenseJsonData);
            //var licenseChart = new google.visualization.ColumnChart(document.getElementById('LicenseChartDiv'));
            //licenseChart.draw(licenseData, { width: 400, height: 240, isStacked: true });

            var applicationData = new google.visualization.DataTable(applicationJsonData);
            // var applicationChart = new google.visualization.ColumnChart(document.getElementById('ApplicationChartDiv'));
            // applicationChart.draw(applicationData, { width: 400, height: 240, isStacked: false });
        }

        $scope.ArchiveButtonClicked = function () {

        };

        $scope.DeleteButtonClicked = function () {

        };


    }]);
