(function () {
    'use strict';

    angular
        .module('app.provider')
        .controller("ProviderCEEducationController", ProviderCEEducationController);

        ProviderCEEducationController.$inject = ["$rootScope", "$scope",
        "provideCEEducationService", "utilityService", "WebApiUrl", "$q", "contentService", "Messages"
    ];

    function ProviderCEEducationController($rootScope, $scope,
        provideCEEducationService, utilityService, WebApiUrl, $q, contentService, Messages) {
        var _key = sessionStorage.Key;
        var _UserId = '';
        $scope.natValidation={};
        $scope.courseEditIndex = 0;
        $scope.educationtData = {
            Date: new Date()
        };

        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'MM/dd/yyyy',
            dateOptions: {
                minDate: (new Date(1947, 0, 1)),
                maxDate: (new Date(2050, 0, 31)),
                ngModelOptions: {
                    allowInvalid: true
                }
            }
        };
        

        $scope.getEducationConfig = function () {
            var relativeFilePath = "/individual/application/education/education.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function (data) {
                    $scope.educationConfig = data;
                    $scope.isConfigLoaded = true;
                }, function (error) {
                    ShowErrMsg("educationConfig", "Some error occurred,Unable to load the Continuing Education config")
                });
        };

        function getEducationInfo() {

            try {
                $scope.IndividualCECourse = [];
                if ($scope.IndividualCECourseResponseList) {
                    $scope.IndividualCECourse = $scope.IndividualCECourseResponseList;
                }
                else {
                    $scope.IndividualCECourseResponseList = [];
                }
                
            } catch (ex) {

            }
        }     

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        var validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        // var ShowErrMsg = function (propName, errorMessage) {
        //     propName = (!!propName) ? propName : "educationErr";
        //     errorMessage = (!!errorMessage) ? errorMessage : $scope.educationConfig.ValidationMsg.APIError;
        //     if ($scope.natValidation.errorMessages == undefined)
        //         $scope.natValidation.errorMessages = [];
        //     var propExist = false;
        //     for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
        //         if ($scope.natValidation.errorMessages[i].propName == propName)
        //             propExist = true;
        //     }
        //     if (!propExist)
        //         $scope.natValidation.errorMessages.push({
        //             propName: propName,
        //             errorMessage: errorMessage
        //         });

        //     if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
        //         $scope.natValidation.showError = true;
        //         $scope.natValidation.isValid = false;
        //         return false;
        //     } else {
        //         $scope.natValidation.showError = false;
        //         $scope.natValidation.isValid = true;
        //         return true;
        //     }
        // };

        // $scope.addEducationInformation = false;

        // $scope.showAddForm = function () {
        //     $scope.currentForm.$setPristine();
        //     $scope.addEducationInformation = true;
        //     $scope.clearDataEducation();

        // };

        // $scope.closeAddForm = function () {
        //     $scope.addEducationInformation = false;
        //     $scope.natValidation.showError = false;
        // };

        $scope.ShowHideSection = function (res, sec) {
            if (!$scope.courseAData) {
                $scope.courseAData = {};
                $scope.NatValidatorCourseA = {};
                // $scope.showAddCpeCourseAFormDetails = false;
            }
            $scope.showAddCpeCourseAFormDetails = res;
          
        };

        $scope.init = function () {

            try {
                _key = sessionStorage.Key;
                _UserId = sessionStorage.UserID;
              
                $scope.getEducationConfig();
              

                GetIndividualCECourseResponseList();
                       
                $scope.addEducationInformation = false;
             
            } catch (ex) {

            }
        };

     
        $scope.clearDataEducation = function () {
            try {
                // $scope.Education.EducationInformationDate = "";
                $scope.Education.InstructorName = "";
                $scope.Education.CourseLocationState = "";
                $scope.Education.CourseBeginDate = "";
                $scope.Education.CourseEndDate = "";
                // $scope.Education.EducationInclasscredit = false;
                //   $scope.Education.EducationCLCredit = false;
                $scope.courseEditIndex = 0;
            } catch (ex) {

            }
        }
     

      
        // $scope.CEAffirmationChanege = function () {
        //     $scope.natValidation.errorMessages = [];
        // }
        // $scope.dataSave = function () {
        //     var CECourse = {
        //         IndividualCECourseId: $scope.EducationCheck.IndividualCECourseId ? $scope.EducationCheck.IndividualCECourseId : 0,
        //         IndividualId: $scope.individualId,
        //         ApplicationId: $scope.applicationId,
        //         CECourseTypeId: null,
        //         CECourseActivityTypeId: null,
        //         CourseBeginDate: null,
        //         CourseEndDate: null,
        //         CECourseDueDate: null,
        //         CECourseDate: $scope.Education.EducationInformationDate,
        //         CECourseHours: 0,
        //         CECourseUnits: $scope.EducationCheck.EducationInformationCEUnits,
        //         ProgramSponsor: $scope.EducationCheck.EducationInformationInstitution,
        //         CourseNameTitle: $scope.EducationCheck.EducationInformationCourse,
        //         CourseSponsor: $scope.EducationCheck.EducationInformationInstitution,
        //         //InclassCredit: $scope.Education.EducationInclasscredit ? $scope.Education.EducationInclasscredit : false,
        //         CLCredit: $scope.EducationCheck.EducationCLCredit ? $scope.EducationCheck.EducationCLCredit : false,
        //         CECourseReportingYear: 2018,
        //         CECourseStatusId: null,
        //         InstructorBiography: null,
        //         ActivityDesc: null,
        //         ReferenceNumber: null,
        //         IsActive: true,
        //         IsDeleted: false,
        //         IndividualLicenseId: $scope.individualLicense.IndividualLicenseId,
        //         CEAffirmation: $scope.EducationCheck.CEAffirmation
        //     }

        //     provideCEEducationService.individualCECourseSave([CECourse], sessionStorage.Key)
        //         .then(function (response) {                   
        //            GetIndividualCECourseResponseList();                  
        //         }, function (error) {
                  
        //         });
        // }
       

       
      


        var GetIndividualCECourseResponseList = function () {
            ShowLoader();
            provideCEEducationService.getCEEducation($scope.ProviderId, $scope.applicationId,$scope.ProviderCourseId, _key).then(function (res) {
                if (res.Status) {
                    $scope.IndividualCECourseResponseList = res.ProviderCourseAddInfoList;
                  }
                else {
                    $scope.IndividualCECourseResponseList = res.ProviderCourseAddInfoList;
                   
                }              
                HideLoader();
            }, function () {
                HideLoader();
            });
        };

        $scope.EditIndividualCECourseResponse = function (item, sec) {
            
            $scope.courseAData = angular.copy(item);
            if (item.CourseBeginDate) {
                $scope.courseAData.CourseBeginDate = new Date(item.CourseBeginDate);
            }
            if (item.CourseEndDate) {
                $scope.courseAData.CourseEndDate = new Date(item.CourseEndDate);
            }           
            $scope.showDocument = true;
            $scope.showAddCpeCourseAFormDetails = true;
        };

        $scope.DeleteIndividualCECourseResponse = function (item, sec) {
            if (item && confirm(Messages.bbabace)) {
                item.IsDeleted = true;
                provideCEEducationService.individualCECourseSave([item], sessionStorage.Key)
                .then(function (response) {                   
                   GetIndividualCECourseResponseList();                  
                }, function (error) {
                  
                });
            }
        };

        $scope.DVSaveCourseAData = function (form, sec) {

            //validate form
            if (!validateForm(form, $scope.courseAData)) {
                return;
            }
          
            if (new Date($scope.courseAData.CourseEndDate) < new Date($scope.courseAData.CourseBeginDate)) {
                if ($scope.natValidation.errorMessages == undefined)
                    $scope.natValidation.errorMessages = [];

                var errorObj = {
                    propName: "natrequiredaa-ceinfosss",
                    errorMessage: Messages.General.Errors.EndDateGrater
                };
                $scope.natValidation.errorMessages.push(errorObj);
               
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                $scope.isEndDateRed = true;
                return;
            }

            $scope.isEndDateRed = false;
            $scope.isStartDateRed = false;

            if ($scope.courseAData) {

                $scope.courseAData.ProviderId = $scope.ProviderId;
                $scope.courseAData.ProviderCourseId=$scope.ProviderCourseId;
                $scope.courseAData.ApplicationId =null;
                $scope.courseAData.IsActive = 1;
                $scope.courseAData.IsDeleted = 0;               
            }
            provideCEEducationService.individualCECourseSave([$scope.courseAData],_key).then(function () {
                $scope.showAddCpeCourseAFormDetails = false;
                GetIndividualCECourseResponseList();   
                }, function () {
                 
                });           
        };
    
        $scope.init();

    }
})();