﻿(function () {
    'use strict'
    angular.module('app.backofficeBusinessEntity')
        .directive('amcformDirective', amcformDirective);

    amcformDirective.$inject = [];

    function amcformDirective() {
        return {
            restrict: 'E',
            scope: {
                IsBackoffice: '=isbackoffice',
                providerId: '=providerId',
                applicationId: '=?applicationId',
                callbackitem: '=callbackitem',
                paymentProcessed: '=paymentProcessed',
                applicationStatus: '=applicationStatus',
                closeform: '&',
                ApplicationNumber: '=?applicationNumber',
                applicationForId: '=?applicationForId'
            },
            templateUrl: "app/components/individual/amc-application/amc-application.html",
            controller: "amcApplicationController"
        }
    }
})();