(function () {
    'use strict'
    angular.module('app.core')
        .directive('providerMultipleFileUploadList', providerMultipleFileUploadList);
    providerMultipleFileUploadList.$inject = [];
    function providerMultipleFileUploadList() {
        return {
            restrict: 'E',
            scope: {
                documentsList: '=documentsList',
                providerDocumentUpload: '=?providerDocumentUpload',
                documentNameCaption: '=?documentNameCaption',
                referenceNumber: "=?referenceNumber",
                disableForm: "=disableForm",
                isEmailSend: "=?isEmailSend"
            },
            templateUrl: "app/core/directive/provider-multiple-file-upload-list/provider-multiple-file-upload-list.html",
            controller: "ProviderMultipleFileUploadListController"
        }
    }
})();
