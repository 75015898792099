(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('providerInformation', providerInformation);

        providerInformation.$inject = [];

    function providerInformation() {
        return {
            restrict: 'E',
            scope: {
                personalInfo: "=personalInfo",
                personalAddress: "=personalAddress",
                personalContact: "=personalContact",
                personalData: "=?personalData",
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                providerId: '=providerId',
                applicationId: '=applicationId',
                isRenewal: '=?isRenewal',
                loadData: '=?loadData',
                isNameChange: "=?isNameChange",
                natValidation: "=natValidation",
                showStatusMessage: "&",
                tab: "=tab",
                openNextTab: "&",
                isBackoffice: "=isBackoffice",
                individualLicense: "=?individualLicense",
                hidePersonalInfo: "=hidePersonalInfo",
                individualEmployment: "=?individualEmployment",
                isInitialApplication: "=?isInitialApplication",
                applicationInfo: "=?applicationInfo",
                feeDetails: "=feeDetails",
                applicationSubTypeId:"=?applicationSubTypeId"
            },
            templateUrl: function (elements, attrs) {
                if (attrs.isInitialApplication == true || attrs.isInitialApplication == "true")
                    return "app/components/provider/application/directives/provider-information/initial-provider-information.html";
                else
                    return "app/components/provider/application/directives/provider-information/provider-information.html";
            },
            controller: "ProviderInformationController"
        }
    }
})();