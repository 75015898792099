(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('instructions', instructionsDirective);

        instructionsDirective.$inject = [];

    function instructionsDirective() {
        return {
            restrict: 'E',
            scope: {
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                applicationId: "=applicationId",
                individualId: "=individualId",
                renewalApplicationInstruction: "=renewalApplicationInstruction",
                natValidation: "=natValidation",
                isRenewal: '=?isRenewal',
                loadData: '=?loadData',
                tab: "=tab",
                openNextTab: "&",
                isBackoffice: "=isBackoffice",
                individualLicense: '=individualLicense',
                applicationInfo: '=applicationInfo'
            },
            templateUrl: "app/components/individual/application/directives/instructions/instructions.html",
            controller: "InstructionsController"
        }
    }
})();