(function () {
  'use strict'
  angular.module('app.InitialApplication')
    .directive('initialApplicationChecklist', initialApplicationChecklistDirective);

  initialApplicationChecklistDirective.$inject = [];

  function initialApplicationChecklistDirective() {
    return {
      restrict: 'E',
      scope: {

      },
      templateUrl: "app/components/individual/application/directives/checklist/checklist.html",
      controller: "InitialApplicationChecklistController"
    }
  }
})();