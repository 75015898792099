(function() {
    'use strict'
    angular.module('app.login')
        .controller("ChangePasswordController", ["$scope", "$controller", "$cookies", "LoginwemailService", "ChangePasswordService", "userPortalViewService", "$state", "Messages",
            function($scope, $controller, $cookies, LoginwemailService, ChangePasswordService, userPortalViewService, $state, Messages) {
                // Variables
                $scope.showLoginButton = false;
                // page init method
                var currentState = $state.current.name;
                if (currentState == 'app.FirmChangePassword') {
                    $scope.isFirmChangePassword = true;
                }
                $scope.loginRedirect = function() {
                    switch (currentState) {
                        case 'app.StaffChangePassword':
                            {
                                $state.go('app.BackofficeLoginstaff');
                                break;
                            }
                        case 'app.ChangePassword':
                            {
                                if (sessionStorage.LoginPage && sessionStorage.LoginPage != '') {
                                    try {
                                        $state.go(sessionStorage.LoginPage);
                                    } catch (ex) {
                                        $state.go('app.Loginwemail');
                                    }
                                } else {
                                    $state.go('app.Loginwemail');
                                }
                                break;
                                // $state.go('app.Loginwemail');
                                // break;
                            }
                        case 'app.FirmChangePassword':
                            {
                                $state.go('app.FirmLogin');
                                break;
                            }
                        default:
                            {
                                $state.go('app.Loginwemail');
                                break;
                            }
                    }
                };


                $scope.init = function() {
                    try {
                        $scope.showLoginButton = false;
                        if ($scope.isUserSessionActive()) {} else {
                            $scope.loginRedirect();
                        }
                    } catch (ex) {
                        if (ex != null) {
                            $scope.showStatusMessage(ex.message, "error");
                        }
                    }
                };

                var customValidation = function(){
                    var passes = true;
                    var passwd = $scope.newPassword;
                    var oldpasswd = $scope.oldPassword;
                    var msg = ""
                    if(passwd.length < 8){
                        msg += "Password to short. \n"
                        passes = false;
                    }
                    if(passwd.toUpperCase() == passwd || passwd.toLowerCase() == passwd){
                        msg += "Password must have one uppercase and one lowercase letter. \n"
                        passes = false;
                    }
                    if(!/[0-9]/.test(passwd)){
                        msg += "Password must have at least one Number.\n"
                        passes = false;
                    }
                    var specialCharacters = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
                    if(!specialCharacters.test(passwd)){
                        msg += "Password must have at least one Special Character.\n"
                        passes = false;
                    }

                    if(!passes){
                        $scope.showStatusMessage(msg, "error");
                    }
                    return passes;
                }

                // Login User
                $scope.resetpassword = function() {
                    hideStatusMessage();
                    try {
                        if (validateData('changepasswordvalidation') && customValidation()) {
                            ShowLoader();
                            var UserId = sessionStorage.UserID;
                            var ConfirmPassword = $scope.confirmPassword;
                            if (UserId != undefined && UserId != "" && UserId != null && sessionStorage.Key != undefined && sessionStorage.Key != "") {
                                var reqdata = {
                                    ChangePasswordRequest: {
                                        UserId: UserId,
                                        NewPassword: $scope.newPassword,
                                        OldPassword: $scope.oldPassword,
                                        ConfirmPassword: ConfirmPassword
                                    },
                                    LoginInfo: {
                                        AccessCode: "",
                                        Email: sessionStorage.Email,
                                        LastName: "",
                                        LicenseNumber: "",
                                        LoginWithoutEmail: false,
                                        Password: $scope.newPassword,
                                        AppDomain: $scope.isFirmChangePassword ? 'cbafirm' : ''
                                    }
                                };

                                ChangePasswordService.ChangePasswordAndLogin('/' + sessionStorage.Key + ($scope.isFirmChangePassword ? '?Source=cbafirm' : ''), reqdata)
                                    .then(function(res) {

                                        var response = res.data
                                        if (!!response.Status) {
                                            $scope.clearControls();

                                            $scope.showStatusMessage(response.Message, "success");
                                            if (currentState == 'app.StaffChangePassword') {
                                                sessionStorage.MenuList = JSON.stringify(response.MenuList);
                                                sessionStorage.Key = response.Key;
                                                sessionStorage.FirstName = response.UserInfo.FirstName;
                                                sessionStorage.LastName = response.UserInfo.LastName;
                                                $state.go('app.BackofficeDashboardStaff');
                                            } else if (currentState == 'app.ChangePassword') {
                                                sessionStorage.MenuList = JSON.stringify(response.MenuList);
                                                sessionStorage.Key = response.Key;
                                                sessionStorage.FirstName = response.UserInfo.FirstName;
                                                sessionStorage.LastName = response.UserInfo.LastName;
                                                // if (sessionStorage.LoginPage && sessionStorage.LoginPage != '') {
                                                //     try {
                                                //         $state.go(sessionStorage.LoginPage);
                                                //     } catch (ex) {
                                                //         $state.go('app.Loginwemail');
                                                //     }
                                                // }
                                                // else {
                                                //     $scope.showLoginButton = true;
                                                // }
                                                var loginVerification = {
                                                    tokenKey: response.Key,
                                                    IndividualId: response.IndividualID,
                                                    userId: response.UserID,
                                                    currentUserData: response.UserInfo,
                                                    isUserPortalView: false
                                                };
                                                userPortalViewService.setUserPortalViewData(loginVerification);
                                                $state.go('app.UserPortalView');
                                                //$state.go('app.LoginBusinessPortal');
                                            } else {
                                                response.UserInfo.UserTypeId = response.UserInfo.UserTypeID;
                                                var loginVerification = {
                                                    tokenKey: response.Key,
                                                    IndividualId: response.IndividualID,
                                                    userId: response.UserID,
                                                    currentUserData: response.UserInfo,
                                                    isUserPortalView: false
                                                };
                                                userPortalViewService.setUserPortalViewData(loginVerification);
                                                $state.go('app.UserPortalView');
                                            }
                                        } else {
                                            $scope.showStatusMessage(response.Message, "error");
                                        }
                                        HideLoader();
                                    }, function(res) {
                                        HideLoader();
                                        var data = res.data;
                                        $scope.clearControls();
                                        if (data != null) {
                                            $scope.showStatusMessage(Messages.General.Errors.SomeError, "error");
                                        }
                                    });
                            } else {
                                $scope.clearControls();
                                $scope.showLoginButton = true;
                                $scope.showStatusMessage(Messages.General.Errors.SessionExpired, "error");
                                HideLoader();
                            }

                        }
                    } catch (ex) {
                        // console.log(ex);
                        $scope.showStatusMessage(ex, "error");
                    }
                };

                $scope.clearControls = function() {
                    $scope.oldPassword = "";
                    $scope.newPassword = "";
                    $scope.confirmPassword = "";
                    sessionStorage.Key = "";
                };

                var currentState = $state.current.name;


                $scope.showPasswordRule = function(event) {
                    event.preventDefault();
                    alert(Messages.PasswordRequirement);
                };
            }
        ]);
})();