(function () {
    'use strict'
    angular.module('app.core')
        .directive('userPortalView', userPortalViewDirective);

    userPortalViewDirective.$inject = [];

    function userPortalViewDirective() {
        return {
            restrict: 'E',
            scope: {
                individualId: "=individualId"
            },
            templateUrl: "app/core/directive/user-portal-view/user-portal-view.html",
            controller: "UserPortalViewController"
        }
    }
})();