(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('initialApplicationBEStaffReviewService', initialApplicationBEStaffReviewService)

    initialApplicationBEStaffReviewService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function initialApplicationBEStaffReviewService($http, $q, WebApiUrl, AppConfig) {

        var staffReviewService = {};
        staffReviewService.sendProviderDocumentSaveToPDFandMailWY = function (tabData, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/ProviderDocumentSaveToPDFandMailWY/' + key + '?Source=ALPEPLS', tabData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        staffReviewService.applicationReviewGet = function (key, ApplicationId, ProviderId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Application/ApplicationReviewGet/' + key + '?ApplicationId=' + ApplicationId + '&IndividualId=&ProviderId=' + ProviderId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        staffReviewService.applicationReviewSave = function (key, tabData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Application/SaveApplicationReview/' + key + '?Source=' + AppConfig.Source, tabData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        //Payment/SendSubmissionEmailAndLogCommunicationWY
        return staffReviewService;
    }
})();