(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .directive('locationsValidation', [locationsValidationDirective]);

    function locationsValidationDirective() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function () {
                    var valid = true;
                    var errMsgs = [];
                    if (!$scope.addressAddedForThisApp && !!$scope.uiConfig.Validations && $scope.uiConfig.Validations.AtleastOneRequired) {
                        valid = false;
                        errMsgs.push('You must add at least 1 location for this application.');
                    }

                    $scope.showStatusMsg('-', errMsgs);

                    return valid;
                };
            },
        };
    }
})();