(function () {
    'use strict'

    angular.module('reporting.QueryReporting')
    .controller('QueryReportingConditionBlockController', ['$scope', 'QueryReportingUtility', QueryReportingConditionBlockController]);

    function QueryReportingConditionBlockController($scope, QueryReportingUtility) {

        /**
         * @returns {QueryWhereCondition}
         */
        var getBlankWhereCondition = function(isBlock) {
            return {
                ConditionOperator: 'AND',
                IsBlock: isBlock,

                Block: (isBlock) ? {Conditions: []} : null,

                Column: null,
                Operator: '',
                Value: '',

                IsColumnValue: false,
                ColumnValue: null,

                dateOpened: false
            }
        };

        var getTableList = function () {
            var tempQuery = QueryReportingUtility.getCopyOfCurrentQuery($scope, true);

            return [tempQuery.From].concat(tempQuery.Join).where('!!item && !!item.table').select('table');
        };

        $scope.init = function () {
            
        };

        $scope.addTypeaheadClasses = function () {
            QueryReportingUtility.addTypeaheadClasses();
        };
        
        $scope.getPossibleValuesForColumn = function (col, selected) {
            QueryReportingUtility.getPossibleValuesForColumn(col, selected, getTableList())
            .then(function (data) {
                if (!data.Status) {
                    $scope.showStatusMsg({type: '-', message: data.Message});
                }
            }, function (error) {
                $scope.showStatusMsg({type: '-', message: error.message});
            });
        };

        $scope.getColumnExpressionList = function () {
            return QueryReportingUtility.getColumnExpressionList($scope);
        };

        $scope.updateMultiValueSelect = function (condition) {
            condition.Value = condition.Column.possibleValues.where('item.isSelected').select('value').join(',');
        };

        $scope.getColLabel = function (col) {
            return QueryReportingUtility.getColumnLabel(col);
        };

        $scope.isValueDefined = function(value) {
            if(typeof value == 'boolean') {
                return true;
            }
            
            return !!value;
        };

        $scope.initConditionOperator = function(first, condition) {
            if(first) {
                condition.ConditionOperator = '';
            }
        };

        $scope.whereColumnSelected = function(condition) {
            if(!condition.Column.IsExpressionColumn) {
                if(condition.Column.reference) {
                    QueryReportingUtility.getPossibleValuesForColumn(condition.Column, null, getTableList())
                    .then(function (data) {
                        if (!data.Status) {
                            $scope.showStatusMsg({type: '-', message: data.Message});
                        }
                    }, function (error) {
                        $scope.showStatusMsg({type: '-', message: error.message});
                    });
                    QueryReportingUtility.addTypeaheadClasses();
                }
            }
            condition.dateOpened = false;
            QueryReportingUtility.getAvailableConditionOperators(condition.Column, true)
            .then(function (data) {
                if (!data.Status) {
                    $scope.showStatusMsg({type: '-', message: data.Message});
                }
                //Temporary compatibility fix
                condition.Column.availableOperators.conditionalSplice('item=="between"');
            }, function (err) {
            });
        };
        
        $scope.whereColumnValueTypeChanged = function (condition) {
            if (condition.IsColumnValue) {
                condition.Value = '';
                QueryReportingUtility.addTypeaheadClasses();
            } else {
                condition.ColumnValue = null;
            }
        };

        $scope.removeCondition = function (blockConditions, index) {
            var condition = blockConditions.splice(index, 1)[0];

            if(!condition.IsBlock && !!condition.removeWatch) {
                condition.removeWatch();
            }

            if(index == 0 && blockConditions.length > 0) {
                blockConditions[0].ConditionOperator = '';
            } else if (blockConditions.length == 0) {

            }
        };

        $scope.addWhereCondition = function(whereBlock, isBlock) {
            var condition = getBlankWhereCondition(isBlock);

            condition.removeWatch = $scope.$watch(function () { return condition.dateOpened; }, function (newVal, oldVal, scope) {
                if (!newVal && oldVal) {
                    condition.showValueEditor = false;
                }
            });

            QueryReportingUtility.addTypeaheadClasses();

            whereBlock.Conditions.push(condition);
        };
    }
})();