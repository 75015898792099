(function () {
    'use strict'
    angular.module('app.provider')
        .directive('ceCourseProvider', ceCourseProviderDirective);

    ceCourseProviderDirective.$inject = [];

    function ceCourseProviderDirective() {
        return {
            restrict: 'E',
            scope: {
                hideStatusMessage: "&",
                hideValidationSummary: "&",
                validateData: "&",
                checkServerResponse: "&",
                showStatusMessage: "&",
                providerId: "=",
                applicationId: '=',
                totalCourses: '=?',
                ceCourseList: '=?',
                addEditMode: '=?',
                natValidation: "=natValidation",
                feeDetails: "=?feeDetails",
                isRenewal: "=?isRenewal"
            },
            templateUrl: "app/components/provider/directive/ce-course-provider/ce-course-provider.html",
            controller: "CeCourseProviderController"
        }
    }
})();