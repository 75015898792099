angular.module('natApp').factory('CouncilInfoService', ['$http', 'WebApiUrl', function ($http, WebApiUrl) {
    return {
        BoardAuthorityGet: function (Key) {

            return $http.get(WebApiUrl + 'Board/BoardAuthorityGet/' + Key);
        },

        SaveBoardAuthority: function (
            BoardAuthorityId,
            StateCode,
            Name,
            Code,
            Acronym,
            Url,
            PhysicalAddressLine1,
            PhysicalAddressLine2,
            PhysicalAddressCity,
            PhysicalAddressState,
            PhysicalAddressZip,
            IsMailingSameasPhysical,
            MailingAddressLine1,
            MailingAddressLine2,
            MailingAddressCity,
            MailingAddressState,
            MailingAddressZip,
            ContactPhone,
            ContactEmail,
            ContactFax,
            AlternatePhone,
            IsActive,
            CreatedBy,
            CreatedOn,
            ModifiedBy,
            ModifiedOn,
            BoardAuthorityGuid,
            Key) {

            return $http.post(WebApiUrl + 'Board/BoardAuthoritySave/' + Key + '/', {
                BoardAuthorityId: BoardAuthorityId,
                StateCode: StateCode,
                Name: Name,
                Code: Code,
                Acronym: Acronym,
                Url: Url,
                PhysicalAddressLine1: PhysicalAddressLine1,
                PhysicalAddressLine2: PhysicalAddressLine2,
                PhysicalAddressCity: PhysicalAddressCity,
                PhysicalAddressState: PhysicalAddressState,
                PhysicalAddressZip: PhysicalAddressZip,
                IsMailingSameasPhysical: IsMailingSameasPhysical,
                MailingAddressLine1: MailingAddressLine1,
                MailingAddressLine2: MailingAddressLine2,
                MailingAddressCity: MailingAddressCity,
                MailingAddressState: MailingAddressState,
                MailingAddressZip: MailingAddressZip,
                ContactPhone: ContactPhone,
                ContactEmail: ContactEmail,
                ContactFax: ContactFax,
                AlternatePhone: AlternatePhone,
                SystemName: null,
                SystemAbbreviation: null,
                SystemUrl: null,
                ApplicationSystemUrl: null,
                SystemContact: null,
                IsActive: IsActive,
                CreatedBy: CreatedBy,
                CreatedOn: CreatedOn,
                ModifiedBy: ModifiedBy,
                ModifiedOn: ModifiedOn,
                BoardAuthorityGuid: BoardAuthorityGuid
            });
        },
        TrialBalanceReportWithPager: function (key, data) {
            return $http.post(WebApiUrl + 'Report/TrialBalanceReportWithPager/' + key, data);
        },
        TrialBalanceSummeryGet: function (key, type, batchNumber) {
            return $http.get(WebApiUrl + 'Report/TrialBalanceSummeryGet?Key=' + key + '&type=' + type + '&BatchNumber=' + batchNumber);
        },
        GetStateByCountryID: function (Key, ID) {

            return $http.get(WebApiUrl + 'State/StateGetByCountryID/' + Key + '?CountryID=' + ID);
        },
    };
}]);