(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .controller('FingerprintConfirmationController', ['$scope', '$rootScope', '$q', 'FingerprintConfirmationService', 'utilityService', 'WebApiUrl', 'backofficeIndividualAdressService', 'backofficeIndividualPersonalService', FingerprintConfirmationController]);

    function FingerprintConfirmationController($scope, $rootScope, $q, FingerprintConfirmationService, utilityService, WebApiUrl, backofficeIndividualAdressService, backofficeIndividualPersonalService) {
        var _key = sessionStorage.Key;
        var _childName = '';

        $scope.documentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.entityId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "R",
            LoadByDocIdAndDocCode: true,
            DocId: 102,
            DocCode: "D1102",
            LoadDocumentsWithoutBroadCast: true,
            hideUploadedFor: true
        };

        $scope.documentUploadDeficiency = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.entityId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "R",
            LoadByDocIdAndDocCode: true,
            DocId: 105,
            DocCode: "D1105",
            LoadDocumentsWithoutBroadCast: true,
            hideUploadedFor: true,
            hideValidationMarks: true
        };

        $scope.documentUploadFPCard = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.entityId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "R",
            LoadByDocIdAndDocCode: true,
            DocId: 103,
            DocCode: "D1103",
            LoadDocumentsWithoutBroadCast: true,
            hideUploadedFor: true
        };

        var getNewFingerprintConfirmation = function (content) {
            return {
                EntityId: $scope.entityId,
                EntityTy: $scope.entityType,
                LicenseId: (!!$scope.individualLicense) ? $scope.individualLicense.IndividualLicenseId : null,
                ApplicationId: $scope.applicationId,
                ConfirmationTypeId: 0,
                ConfirmationResponse: $scope.addressData && $scope.addressData.StateCode == 'CA' ? false : null,
                ContentItemLkId: content.ContentItemLkId,
                Desc: content.ContentItemLkDesc,
                ConfirmationDate: null,
                EffectiveDate: new Date(),
                IsActive: true,
                IsDeleted: false
            };
        };

        $scope.contentLoaded = function (content) {
            $scope.content.question = content.whereEquals('FingerprintConfirmation', 'ContentItemLkCode').firstOrDefault();
            $scope.content.questionFingerprintOnFile = content.whereEquals('FingerprintOnFileConfirmation', 'ContentItemLkCode').firstOrDefault();
            $scope.getFingerprintConfirmations($scope.content);
        };

        $scope.saveFingerprintConfirmation = function () {
            var deferred = $q.defer();

            try {
                var entityConfirmations = [$scope.confirmationFingerprintOnFile];
                if ($scope.confirmationFingerprintOnFile.ConfirmationResponse == false || !!$scope.confirmation.EntityConfirmationId) {
                    entityConfirmations.push($scope.confirmation);
                }
                FingerprintConfirmationService.saveConfirmations(sessionStorage.Key, entityConfirmations)
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getFingerprintConfirmations = function (content) {
            var deferred = $q.defer();

            try {
                FingerprintConfirmationService.getConfirmations(sessionStorage.Key, $scope.entityId, $scope.entityType, $scope.applicationId, null)
                    .then(function (data) {
                        if (data.Status && data.EntityConfirmations.length > 0) {
                            $scope.confirmation = data.EntityConfirmations.whereEquals(content.question.ContentItemLkId, 'ContentItemLkId').firstOrDefault();
                            $scope.confirmationFingerprintOnFile = data.EntityConfirmations.whereEquals(content.questionFingerprintOnFile.ContentItemLkId, 'ContentItemLkId').firstOrDefault();
                        }
                        if (!$scope.confirmation) {
                            $scope.confirmation = getNewFingerprintConfirmation(content.question);
                        }
                        if (!$scope.confirmationFingerprintOnFile) {
                            $scope.confirmationFingerprintOnFile = getNewFingerprintConfirmation(content.questionFingerprintOnFile);
                        }
                        $scope.operationPending(false);
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.CopyToClipboard = function () {
            try {
                $scope.operationPending(true, 'Loading Address...');
                var dataPromises = [];
                dataPromises.push($scope.getIndividual());
                dataPromises.push($scope.getIndividualAddress());

                $q.all(dataPromises)
                    .then(function (response) {
                        $scope.personalInfo = response[0].IndividualResponse[0];
                        $scope.addressData = response[1].IndividualAddressResponse.whereEquals(1, 'AddressTypeId').whereEquals(1, 'AdressStatusId').firstOrDefault();
                        if (!$scope.addressData.StreetLine2) {
                            var address = $scope.personalInfo.FirstName + ' ' + $scope.personalInfo.LastName + '\r\n' + $scope.addressData.StreetLine1 + '\r\n' + $scope.addressData.City + "," + " " + $scope.addressData.StateCode + " " + $scope.addressData.Zip;
                        }
                        if ($scope.addressData.StreetLine2) {
                            var address = $scope.personalInfo.FirstName + ' ' + $scope.personalInfo.LastName + '\r\n' + $scope.addressData.StreetLine1 + '\r\n' + $scope.addressData.StreetLine2 + '\r\n' + $scope.addressData.City + "," + " " + $scope.addressData.StateCode + " " + $scope.addressData.Zip;
                        }
                        /* Select the text field */
                        var textArea = document.createElement("textarea");
                        textArea.value = address;
                        var copyAddressContainer = angular.element("#copyAddressContainer");
                        angular.element(copyAddressContainer).append(textArea);
                        textArea.focus();
                        textArea.select();
                        document.execCommand('copy');
                        angular.element(textArea).remove();
                        $scope.operationPending(false);
                    }, function (error) {
                        $scope.operationPending(false);
                        $scope.showStatusMessage({
                            messageContent: 'Some error occurred !',
                            messageType: 'error'
                        });
                    });
            } catch (ex) {
                $scope.operationPending(false);
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
                $exceptionHandler(ex);
            }
        };

        $scope.getIndividual = function () {
            return backofficeIndividualPersonalService.individualBYIndividualId(sessionStorage.Key, $scope.entityId);
        };
        $scope.getIndividualAddress = function () {
            return backofficeIndividualAdressService.individualAddressBYIndividualId(sessionStorage.Key, $scope.entityId);
        };

        $scope.validateForm = function () {
            $scope.sendWait(_childName);
            var isValid = true;
            $scope.hideStatusMsg();
            if (!!$scope.isRequired) {
                if (['radio', 'checkbox'].includes($scope.type)) {
                    if (typeof ($scope.confirmationFingerprintOnFile.ConfirmationResponse) == 'undefined' || $scope.confirmationFingerprintOnFile.ConfirmationResponse == null) {
                        $scope.sendCancel(_childName, 'You must answer ' + $scope.content.questionFingerprintOnFile.ContentItemLkDesc);
                        $scope.showStatusMsg('-', 'You must answer ' + $scope.content.questionFingerprintOnFile.ContentItemLkDesc);
                        isValid = false;
                    } else if ($scope.confirmationFingerprintOnFile.ConfirmationResponse == false) {
                        if (typeof ($scope.confirmation.ConfirmationResponse) == 'undefined' || $scope.confirmation.ConfirmationResponse == null) {
                            $scope.sendCancel(_childName, 'You must answer ' + $scope.content.question.ContentItemLkDesc);
                            $scope.showStatusMsg('-', 'You must answer ' + $scope.content.question.ContentItemLkDesc);
                            isValid = false;
                        }
                    }
                }
                if (['date'].includes($scope.type) && $scope.confirmationFingerprintOnFile.ConfirmationResponse == false && !$scope.confirmation.ConfirmationDate) {
                    $scope.sendCancel(_childName, 'You must answer ' + $scope.content.question.ContentItemLkDesc);
                    $scope.showStatusMsg('-', 'You must answer ' + $scope.content.question.ContentItemLkDesc);
                    isValid = false;
                }
                if ($scope.confirmationFingerprintOnFile.ConfirmationResponse == false && $scope.confirmation.ConfirmationResponse == false && (!$scope.documentUpload.documentCount || $scope.documentUpload.documentCount < 1)) {
                    $scope.sendCancel(_childName, 'Please Upload Live Scan document');
                    $scope.showStatusMsg('-', 'Please Upload Live Scan document');
                    isValid = false;
                }
            }
            if (!!isValid) {
                $scope.sendComplete(_childName);
            }
            return isValid;
        };

        $scope.saveConfirmation = function (skipNextTab) {
            $scope.operationPending(true, 'Saving...');
            $scope.$broadcast("SaveDeficiency", {});
            $scope.sendWait(_childName);
            $scope.tab.isInvalid = false;
            if (!$scope.validateForm()) {
                $scope.tab.isInvalid = true;
                $scope.operationPending(false);
                if (skipNextTab == true) {
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
                if (skipNextTab && !!skipNextTab.fromDeficiency) {
                    $scope.$emit('deficiencyExecuted', { success: false });
                }
                return;
            }
            $scope.saveFingerprintConfirmation()
                .then(function () {
                    $scope.getFingerprintConfirmations($scope.content)
                        .then(function () {
                            $scope.operationPending(false);
                            $scope.sendComplete(_childName);
                            $scope.tab.skipNextTab = skipNextTab;
                            $scope.openNextTab({
                                currentTab: $scope.tab
                            });
                            if ($scope.tab.skipNextTab == true) {
                                $scope.$emit('setCurrentTabNow');
                            }
                            if (skipNextTab && !!skipNextTab.fromDeficiency) {
                                $scope.$emit('deficiencyExecuted', { success: true });
                            }
                        }, function (err) {
                            $scope.operationPending(false);
                            $scope.sendCancel(_childName, err);
                        })
                }, function (err) {
                    $scope.operationPending(false);
                    $scope.sendCancel(_childName, err);
                });
        };

        $scope.init = function () {
            $scope.content = {};

            if (!!$scope.contentId) {
                _childName = 'confirmationId' + $scope.contentId;
            } else if (!!$scope.contentCode) {
                _childName = 'confirmationCode' + $scope.contentCode;
            } else if (!!$scope.pageTabSectionId) {
                _childName = 'confirmationPageTabSection' + $scope.pageTabSectionId;
            }

            if (!$scope.labelSize) {
                $scope.labelSize = 'col-md-10';
            }
            if (!$scope.controlSize) {
                $scope.controlSize = 'col-md-2';
            }

            $scope.getIndividualAddress().then(function (response) {
                if (response.IndividualAddressResponse && response.IndividualAddressResponse.length > 0) {
                    $scope.addressData = response.IndividualAddressResponse[response.IndividualAddressResponse.length - 1];
                    if ($scope.addressData && $scope.addressData.StateCode && $scope.addressData.StateCode == 'CA') {
                        $scope.confirmation = {
                            ConfirmationResponse: false
                        };
                    }
                }
            })

            $scope.queueName = $rootScope.routeData.queueType;
        };

        $scope.$on('saveConfirmations', function () {
            $scope.saveFingerprintConfirmation();
        });

        $scope.$on('SwitchingTab', function (event, args) {
            if (args.fromTab == $scope.tab.name) {
                if (!$scope.isBackoffice) {
                    $scope.saveConfirmation(true);
                } else {
                    $scope.$broadcast("SaveDeficiency", {});
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
            }
        });

        $scope.$on('deficiencyResolve', function (event, args) {
            if (args.tabName == 'fingerprint') {
                $scope.saveConfirmation({ fromDeficiency: true });
            }
        });

        $scope.clearTabConfirmation = function () {
            $scope.$emit("clearTabConfirmation", { currentTab: $scope.tab });
        };

        $scope.saveAndNext = function () {
            $scope.saveConfirmation();
        };
    }
})();