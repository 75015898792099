(function() {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('examinationValidation', ['Messages', ExaminationValidationDirective]);

    function ExaminationValidationDirective(Messages) {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                $scope.$on('$destroy', function () {
                    $scope.natValidation.showError = false;
                    $scope.natValidation.errorMessages = [];
                });

                $scope.validateForm = function () {
                    var valid = true;

                    $scope.natValidation.showError = false;
                    $scope.natValidation.errorMessages = [];
                    
                    if (!!$scope.current.confirmation.IsExamTaken) {
                        if ($scope.current.examinations.length == 0) {
                            $scope.natValidation.errorMessages.push({
                                propName: "licenseList",
                                errorMessage: Messages.General.Errors.OneExam
                            });
                            $scope.natValidation.showError = true;
                            valid = false;
                        }
                    }

                    //LAMED PHYSICIAN ONLY - Check for 4 or more failures
                    if (!$scope.isBackoffice && !!$scope.individualLicense && $scope.individualLicense.LicenseTypeId == 33) {
                        $scope.current.examinations.forEach(function (exam) {
                            if ((exam.ExamResults == 'Pass' && exam.ExamNumber > 4) || (exam.ExamResults == 'Fail' && exam.ExamNumber >= 4)) {
                                valid = false;
    
                                $scope.$emit('criticalError', {ErrorMessage: Messages.General.Errors.PleaseAdvise});
                            }
                        });
                    }
                    
                    return valid;  
                };

                $scope.validateExam = function () {
                    var valid = true;

                    $scope.natValidation.showError = false;
                    $scope.natValidation.errorMessages = [];

                    for (var empProp in $scope.current.employment) {
                        var val = $scope.current.employment[empProp];
                        //If it is a configured value
                        if(!!$scope.config.UIConfigs.FieldTableHeaders[empProp]) {
                            var label = $scope.config.UIConfigs.FieldTableHeaders[empProp].label;

                            if (typeof(val) === 'undefined' || val == null ||
                                (typeof(val) != 'boolean' && typeof(val) != 'number' && !val)) {
                                $scope.natValidation.showError = true;
                                $scope.natValidation.errorMessages.push({
                                    propName: empProp,
                                    errorMessage: Messages.General.Errors.IsRequired(label)
                                });
                                valid = false;
                            }
                        }
                    }

                    return valid;
                };
            }
        };
    }
})();