(function () {
    'use strict'

    angular.module('app.core')
    .directive('individualLicense', [individualLicense]);

    function individualLicense() {
        return {
            restrict: 'E',
            scope: {
                individualId: '=',
                applicationId: '=',
                applicationTypeId: '=',
                individualLicense: '=',
                applicationInfo: '=?',
                showHeaderPanel: '=?',
                showSubmitPanel: '=?',
                isBackoffice: '=?',
                natValidation: '=',
                isTabStructure: '=',
                tab: '=',
                openNextTab: '&',
                configName: '@',
                checkServerResponse: "&",
                hideStatusMessage: "&",
                showStatusMessage: "&",
                hideValidationSummary: "&",
                personData: '&'
            },
            link: function ($scope, element, attrs) {

            },
            templateUrl: 'app/core/directive/individual-license-directive/individual-license.html',
            controller: 'individualLicenseController'
        };
    }
})();