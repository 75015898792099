(function() {
    'use strict'

    angular.module('reporting.QueryReporting')
    .factory('QueryReportingUtility', ['$q', '$timeout', 'QueryReportingService', QueryReportingUtility]);

    function QueryReportingUtility($q, $timeout, QueryReportingService) {
        var _key = sessionStorage.Key;

        /**
         * 
         * @param {*} col 
         */
        var _getColumnLabel = function(col) {
            if(!col) { return ''; }
    
            return col.TableAlias + ':' + col.TableDisplayName + ' ' + col.FieldDisplayName;
        }

        /**
         * Retrieves the QueryTable reference using the supplied tableName
         * 
         * @param {string} tableName 
         * @param {QueryTable[]} tables 
         * @returns {QueryTable|undefined}
         */
        var _getTableFromList = function (tableName, tables) {
            return angular.copy(tables.find(function(e) { return e.TableName.toLowerCase() == tableName.toLowerCase(); }));
        };

        /**
         * Creates an alias generator object which ensures the table aliases don't conflict.
         * 
         * @returns {AliasGenerator}
         */
        var _getAliasGenerator = function() {
            return {
                currentAlias: 'A',
                nextAlias: function() {
                    //Treat the alias like a base-26 numbering system
                    var lastCurrentAliasLetterPos = this.currentAlias.length - 1;
                    var nextAlias = '';
                    var aliasIncremented = false;

                    //Now find the next letter to increment, starting at the end
                    for (var i = lastCurrentAliasLetterPos; i >= 0; i--) {
                        var currentLetter = this.currentAlias.charAt(i);
                        if(!aliasIncremented && currentLetter != 'Z') {
                            //This is the "digit" to increment - the first non-Z "digit" found
                            aliasIncremented = true;
                            nextAlias = String.fromCharCode(currentLetter.charCodeAt(0) + 1) + nextAlias;
                        } else if (!aliasIncremented && currentLetter == 'Z') {
                            //The alias hasn't been incremented yet, but this "digit" is Z
                            //So, reset it to A
                            nextAlias = 'A' + nextAlias;
                        } else {
                            //Nothing special about this "digit", add it and move on
                            nextAlias = currentLetter + nextAlias;
                        }
                    }

                    if(!aliasIncremented) {
                        //No "digits" were incremented, create a new "digit" at the front and start over
                        nextAlias = ''.padStart(this.currentAlias.length + 1, 'A');
                    }

                    this.currentAlias = nextAlias;

                    return this.currentAlias;
                }
            }; 
        };

        /**
         * Re-assigns all tables with potentially new aliases
         * 
         * @param {QueryTableRelation[]} joinList 
         * @param {AliasGenerator} aliasGenerator 
         * @param {*} aliasChanges 
         */
        var _assignAliases = function (joinList, aliasGenerator, aliasChanges) {
            if(aliasChanges && !aliasChanges['A']) {
                aliasChanges['A'] = 'A';
            } 

            joinList.forEach(function (rel) {
                if(rel.parent.RelationTableAlias) {
                    rel.TableAlias = rel.parent.RelationTableAlias;
                }
                var previousAlias = rel.RelationTableAlias;
                var newAlias = aliasGenerator.nextAlias();

                if(aliasChanges && previousAlias) {
                    aliasChanges[previousAlias] = newAlias;
                }
                rel.RelationTableAlias = newAlias;

                if(rel.table && rel.table.ColumnList) {
                    rel.table.ColumnList.forEach(function (c) {
                        c.TableAlias = newAlias;
                    });
                }
            });

            return joinList;
        };

        /**
         * Prepares the query for serialization by removing potential circular references and unnecessary properties
         * 
         * @param {ExecuteQuery} query 
         */
        var _prepareQueryForSerialization = function (query) {
            //Remove all potential circular references since the JSON serializer can't handle them
            var cleanRelationship = function (rel) {
                delete rel.ColumnList;
                delete rel.table;
                delete rel.joins;
                delete rel.parent;
            };
            var cleanColumn = function (col) {
                delete col.availableOperators;
                delete col.retrievingOperators;
                delete col.Condition;
            };

            if(query.From) {
                cleanRelationship(query.From);
            }
            if(query.Join) {
                query.Join.forEach(cleanRelationship);
            }
            if(query.Select) {
                query.Select.forEach(cleanColumn);
            }
            if(query.GroupBy) {
                query.GroupBy.forEach(cleanColumn);
            }
            if(query.OrderBy) {
                query.OrderBy.forEach(cleanColumn);
            }
        };

        /**
         * Optimizes a query by removing unused joins
         * @param {ExecuteQuery} query 
         */
        var _removeUnusedTables = function (query) {
            query.Join = [];

            //Remove any tables that don't have a condition or aren't selected for export
            var tableList = [query.From].concat([query.From].recursiveSelect('joins').selectMany());
            var whereColumns = query.Where.Conditions.select('Column');
            var selectColumns = query.Select;

            //Since recursive select is breadth-first processing, so the tables lower in the hierarchy are at the end of the list
            //Reversing it will give us the deepest table joins first
            tableList.reverse();

            //Remove all columns that aren't used from the tables
            tableList.forEach(function (t) {
                if(!t.table) { return; }

                if(!t.joins) { t.joins = []; }

                var cList = t.table.ColumnList;
                cList.conditionalSplice(function (c) {
                    if (!selectColumns.whereEquals(c.RefTableFieldId, 'RefTableFieldId').firstOrDefault() && 
                        !whereColumns.whereEquals(c.RefTableFieldId, 'RefTableFieldId').firstOrDefault()) {
                        return true;
                    } else {
                        return false;
                    }
                });
            });

            //Now remove any joins where there are no columns and no joins
            tableList.forEach(function (t) {
                t.joins.conditionalSplice(function (j) {
                    //Remove the table if it doesn't have any columns and has no joins;
                    if(!j.table && j.joins.length == 0) { 
                        return true; 
                    }
                    
                    if (j.table.ColumnList.length == 0 && j.joins.length == 0) {
                        return true;
                    }

                    return false;
                });
            });

            query.Join = [query.From].recursiveSelect('joins').selectMany();
        };

        /**
         * Alters a new typeahead when a condition is added by adding a class that changes its style
         */
        var _addTypeaheadClasses = function (className) {
            if(!className) {
                className = 'query-reporting-typeahead';
            }
            $timeout(function () {
                var typeaheads = angular.element(document.body).find('.dropdown-menu[uib-typeahead-popup]');

                for (var i = 0; i < typeaheads.length; i++) {
                    angular.element(typeaheads[i]).addClass(className);
                }
            }, 1);
        };

        /**
         * Asynchronously retrieves the condition operators for a given column/expression
         * 
         * @param {QueryWhereColumn} column 
         * @param {boolean} setOperator 
         */
        var _getAvailableConditionOperators = function (column, setOperator) {
            var deferred = $q.defer();


            if(setOperator) {
                column.Operator = '[Loading operators...]';
            }
            if (column.Operator && column.Operator != '[Loading operators...]') {
                column.availableOperators = [column.Operator, '[Loading operators...]'];
            } else {
                column.availableOperators = ['[Loading operators...]'];
            }
            column.retrievingOperators = true;

            try
            {
                QueryReportingService.getAvailableConditionOperators(_key, column.FieldDataType)
                .then(function (data) {
                    if(data.Status) {
                        column.availableOperators = data.Operators;
                        column.availableOperatorsFailed = false;
                        if(data.Operators.length > 0 && setOperator) {
                            column.Operator = column.availableOperators[0];
                        } else if (data.Operators.length == 0) {
                            //There were no values in the database
                            //1. The query completed successfully, no exceptions thrown, and the list is empty
                            //2. This means there are no possible values to be retrieved
                            column.Operator = '[No Operators Found]';
                            column.availableOperators = [column.Operator];
                        }
                    } else if (!data.Status && data.Message) {
                        column.availableOperators = [data.Message, '[Failed to retrieve operators]'];
                        column.availableOperatorsFailed = true;
                        if(setOperator) {
                            column.Operator = data.Message;
                        }
                    }
                    column.retrievingOperators = false;

                    deferred.resolve(data);
                }, function (err) {
                    if (err && err.statusText) {
                        showStatusMessage(err.statusText, 'error')
                    }
                    column.availableOperators = [err.statusText, '[Failed to retrieve operators]'];
                    column.availableOperatorsFailed = true;
                    if(setOperator) {
                        column.Operator = err.statusText;
                    }
                    column.retrievingOperators = false;

                    deferred.reject(err);
                });
            } catch (ex) {
                if (ex != null) { showStatusMessage(ex.message, 'error'); }
                deferred.reject(ex);
            }

            return deferred.promise;
        };


        /**
         * Retrieves a copy of the current query
         * 
         * @param {boolean} deepCopy 
         * @returns {ExecuteQuery} 
         */
        var _getCopyOfCurrentQuery = function (scope, deepCopy) {
            var aliasChanges = {};

            if(deepCopy) {
                return {
                    FromRow: angular.copy(scope.fromRow),
                    RecordToFetch: angular.copy(scope.pageSize),
                    From: angular.copy(scope.currentQuery.From),
                    Join: (scope.currentQuery.From) ? angular.copy(_assignAliases([scope.currentQuery.From].recursiveSelect('joins').selectMany(), _getAliasGenerator(), aliasChanges)) : [],        
                    Where: angular.copy(scope.currentQuery.Where),
                    GroupBy: angular.copy(scope.currentQuery.GroupBy),
                    OrderBy: angular.copy(scope.currentQuery.OrderBy),
                    Select: angular.copy(scope.currentQuery.Select),
                    ExpressionList: angular.copy(scope.currentQuery.ExpressionList),
                    IsDistinct: angular.copy(scope.isDistinct),

                    aliasChanges: aliasChanges
                };
            } else {
                return {
                    FromRow: scope.fromRow,
                    RecordToFetch: scope.pageSize,
                    From: scope.currentQuery.From,
                    Join: (scope.currentQuery.From) ? _assignAliases([scope.currentQuery.From].recursiveSelect('joins').selectMany(), _getAliasGenerator(), aliasChanges) : [],        
                    Where: scope.currentQuery.Where,
                    GroupBy: scope.currentQuery.GroupBy,
                    OrderBy: scope.currentQuery.OrderBy,
                    Select: scope.currentQuery.Select,
                    ExpressionList: scope.currentQuery.ExpressionList,
                    IsDistinct: scope.isDistinct,

                    aliasChanges: aliasChanges
                };
            }
        };

        /**
         * 
         * @param {QueryColumn} column 
         * @param {string} selected 
         * @param {QueryTableRelation[]} tableList 
         */
        var _getPossibleValuesForColumn = function (column, selected, tableList) {
            var deferred = $q.defer();

            var table = _getTableFromList(column.TableName, tableList);
            if (table && table.TableTypeId == 3) {
                column.reference = true;
                column.possibleValues = ['[Loading Values...]'];
                column.retrievingValues = true;
                var selectedValues = (!!selected) ? selected.split(',') : [];
                
                try
                {
                    //This column is in a reference table - get the possible values for the WHERE drop-down
                    QueryReportingService.getPossibleValuesForColumn(_key, column.TableName.toLowerCase(), column.FieldName.toLowerCase())
                    .then(function (data) {
                        if(data.Status) {
                            column.possibleValues = data.Values.select(function(v) { return { value: v, isSelected: selectedValues.includes(v) } });
                            column.possibleValuesFailed = false;
                        } else if (!data.Status && data.Message) {
                            column.possibleValues = [data.Message, '[Failed to retrieve values]'];
                            column.possibleValuesFailed = true;
                            }
                        column.retrievingValues = false;

                        deferred.resolve(data);
                    }, function (err) {
                        if (err && err.statusText) {
                            showStatusMessage(err.statusText, 'error')
                        }
                        column.possibleValues = [err.statusText, '[Failed to retrieve values]'];
                        column.possibleValuesFailed = true;
                        column.retrievingValues = false;

                        deferred.reject(err);
                    });
                } catch (ex) {
                    if (ex != null) { showStatusMessage(ex.message, 'error'); }

                    deferred.reject(ex);
                }
            } else {
                column.reference = false;
                column.possibleValues = null;
                column.retrievingValues = false;

                deferred.reject(null);
            }

            return deferred.promise;
        };

        /**
         * 
         * @param {*} scope 
         */
        var _getColumnExpressionList = function (scope) {
            var query = _getCopyOfCurrentQuery(scope, true);
            var fromTables = [];
            var joinTables = [];

            if(!!query.From) {
                // fromTables = angular.copy([query.From].where(function(w) { return !!w.table; }).select(function(j) { return { TableAlias: j.RelationTableAlias, TableName: j.RelationTableName, ColumnList: j.table.ColumnList }; }));
                fromTables = angular.copy([query.From].where('!!item.table').select(function(j) { return { TableAlias: j.RelationTableAlias, TableName: j.RelationTableName, TableDisplayName: j.RelationTableDisplayName, ColumnList: j.table.ColumnList }; }));
            }
            if(!!query.Join) {
                // joinTables = angular.copy(query.Join.where(function(w) { return !!w.table; }).select(function(j) { return { TableAlias: j.RelationTableAlias, TableName: j.RelationTableName, ColumnList: j.table.ColumnList }; }));
                joinTables = angular.copy(query.Join.where('!!item.table').select(function(j) { return { TableAlias: j.RelationTableAlias, TableName: j.RelationTableName, TableDisplayName: j.RelationTableDisplayName, ColumnList: j.table.ColumnList }; }));
            }
            var tableColumnLists = fromTables.concat(joinTables);
            tableColumnLists.forEach(function (t) {
                t.ColumnList.forEach(function(c) {
                    c.TableAlias = t.TableAlias;
                    c.TableName = t.TableName;
                    c.TableDisplayName = t.TableDisplayName;
                });
            });

            var allArr = query.ExpressionList.concat(tableColumnLists.selectMany('ColumnList')); //function(tcl) { return tcl.ColumnList; }));

            return allArr;
        };

        return {
            getColumnLabel: _getColumnLabel,
            getTableFromList: _getTableFromList,
            getAliasGenerator: _getAliasGenerator,
            getAvailableConditionOperators: _getAvailableConditionOperators,
            getColumnExpressionList: _getColumnExpressionList,
            getCopyOfCurrentQuery: _getCopyOfCurrentQuery,
            getPossibleValuesForColumn: _getPossibleValuesForColumn,

            assignAliases: _assignAliases,
            addTypeaheadClasses: _addTypeaheadClasses,
            prepareQueryForSerialization: _prepareQueryForSerialization,
            removeUnusedTables: _removeUnusedTables
        };
    }
})();