﻿(function () {
    'use strict'
    angular.module('app.providerDashboard')
        .controller("firmDashboardController", ["$scope", '$state', 'providerUserLinkingService', 'BackofficeProviderService', '$q','$rootScope','LoginwemailService','multipleFileUploadService', 'licenseRenewalService', 'ConfigurationService', 'backofficeProviderLicenseService', 'peerReviewService',
        'dcaIService', 'WebApiUrl', 'ApplicationSettings', 'utilityService', 
        function ($scope, $state, providerUserLinkingService, BackofficeProviderService, $q, $rootScope, LoginwemailService, multipleFileUploadService, licenseRenewalService, ConfigurationService, backofficeProviderLicenseService, peerReviewService, dcaIService, WebApiUrl, ApplicationSettings, utilityService) {

            function auditVisitInfoSave() {
                var data = {
                    PageName: "Online - Firm Dashboard",
                    SessionId:sessionStorage.Key,
                    ProviderId: sessionStorage.ProviderId,
                    EntityId: 0,
                    UserId: $scope.userId,
                    Username: sessionStorage.ProviderName,
                    RequestUrlReferrer: document.referrer
                };
                utilityService.AuditVisitInfo(data);
            };

            var getUserDetails = function () {
                ShowLoader();
                if(!!sessionStorage.UserId)
                {
                    providerUserLinkingService.getUser(sessionStorage.Key, sessionStorage.UserId).then(function (response) {
                        var currentUser = response.Users;
                        // console.log('user', currentUser);
                        sessionStorage.FirstName = response.Users.FirstName;
                        sessionStorage.LastName = response.Users.LastName;
                        $scope.FirstName = sessionStorage.FirstName;
                        $scope.LastName = sessionStorage.LastName;
                        $rootScope.ContactName = response.Users.FirstName;
                        })
                }
            };
            auditVisitInfoSave();
            getUserDetails();
            $scope.natValidation = [];
            var init = function () {
                $scope.personalData = {};
                $scope.validator = {
                    personalInfo: {}
                };
                $scope.checkTempPswd();
                sessionStorage.removeItem("deficientApplicationId");
                sessionStorage.removeItem("isDeficientApplication");
                $scope.DisplayApplication = false;
                try {
                    if ($scope.isUserSessionActive()) {
                        var curDate = sessionStorage.curDate;
                        $scope.AckAppliciantDate = curDate;
                        $scope.providerId = sessionStorage.ProviderId;
                        $scope.firmName = sessionStorage.ProviderName;
                        $scope.UserFullName = sessionStorage.FirstName + ' ' + sessionStorage.LastName;
                        $scope.loadPersonalData = true;
                        providerGetByProviderId();
                        getLicenseStatuses();
                        ShowLoader();
                        LicenseRenewalCheck();
                        //deficiencyAppCheck();
                        GetRenewalPeriodText();
                        // getLicenseOutputDocuments();
                    } else {
                        $state.go('app.Loginwemail');
                    }
                } catch (ex) {
                    if (ex != null) {
                        showStatusMessage(ex.message, "error");
                    }
                }
            };
            // $scope.documentByIndividualDocumentId = multipleFileUploadService.documentByIndividualDocumentId;
            $scope.ResetPasswordClick = function () {
                $state.go('app.ChangePassword');
            };
            $scope.logoutClick = function () {
                LoginwemailService.logout();
                sessionStorage.clear();
                $state.go('app.FirmLogin');
            };


            // var deficiencyAppCheck = function () {
            //     ShowLoader();
            //     var urlParm = "?Key=" + sessionStorage.Key + "&IndividualId=" + $scope.individualId;
            //     var dataPromises = [backofficeIndividualLicenseService.applicationBYIndividualId(urlParm)];
            //     $q.all(dataPromises).then(function (response) {
            //         var errorMessage = '';
            //         angular.forEach(response, function (responseItem, key) {
            //             if (!responseItem.Status) {
            //                 errorMessage += responseItem.Message;
            //             }
            //         });
            //         if (!!errorMessage) {
            //             $scope.showStatusMessage(errorMessage, "error");
            //             HideLoader();
            //             return;
            //         }
            //         applicationBYIndividualId(response[0]);

            //         var pendingApplicationExists = false;
            //         angular.forEach(response[0].ApplicationResponseList, function (applicationItem) {
            //             if ((applicationItem.ApplicationTypeId == 1 || applicationItem.ApplicationTypeId == 2 ||
            //                 applicationItem.ApplicationTypeId == 9 || applicationItem.ApplicationTypeId == 10 ||
            //                 applicationItem.ApplicationTypeId == 11 || applicationItem.ApplicationTypeId == 12) &&
            //                 (applicationItem.ApplicationStatusId != 3)) {
            //                 pendingApplicationExists = true;
            //             }
            //         });
            //         $scope.pendingApplicationExists = pendingApplicationExists;
            //         HideLoader();
            //     });
            // };

            var applicationBYIndividualId = function (res) {
                if (res.Status && res.ApplicationResponseList) {
                    var applications = res.ApplicationResponseList;
                    var appDetails = $.grep(applications, function (i) {
                        return ((i.ApplicationTypeId == 1 || i.ApplicationTypeId == 2 || i.ApplicationTypeId == 9 || i.ApplicationTypeId == 10 || i.ApplicationTypeId == 12) &&
                            (!ApplicationSettings.InactiveStatuses.includes(i.ApplicationStatusId) && (i.ApplicationStatusId != 30 || (i.ApplicationStatusId == 30 && i.DnqStatusId == 1))) &&
                            (!i.IsArchive)
                        );
                    });
                    if (appDetails && appDetails.length > 0) {
                        SaveApplicationDetailsToSession(appDetails[0]);
                    }
                }
            };

            var SaveApplicationDetailsToSession = function (data) {
                if (data) {
                    sessionStorage.ApplicationId = data.ApplicationId;
                    sessionStorage.ApplicationStatus = data.ApplicationStatus;
                    sessionStorage.IndividualNameId = data.IndividualNameId;
                    sessionStorage.ProviderId = data.ProviderId;
                    sessionStorage.ApplicationTypeId = data.ApplicationTypeId;
                    sessionStorage.ApplicationStatusId = data.ApplicationStatusId;
                    sessionStorage.DeficiencyStatusId = data.DeficiencyStatusId;
                    sessionStorage.DnqStatusId = data.DnqStatusId;
                    sessionStorage.DnqMarkedBy = data.DnqMarkedBy;
                    sessionStorage.licenseApplicationForId = data.LicenseApplicationForId;
                    $scope.applicationDataLoaded = true;
                }
            };



            $scope.recertifyBtnClick = function (renewalItem) {
                ShowLoader();
                var currDate = new Date();
                currDate.setHours(0,0,0,0);
                var expDate = new Date($scope.currLic.ProviderLicenseExpirationDateStr);
                expDate.setHours(0,0,0,0);
                if(currDate <= expDate.addDays(610)){
                    $state.go('app.FirmLicenseRenewalApplication');
                }
                else{
                    $('#renewalNotAvailableModal').modal('show');

                    $("#renewalNotAvailableModal").on('hide.bs.modal', function(){
                        closeModal();
                    });
                }
                
            };

            function closeModal(){
                HideLoader();
                $("#renewalNotAvailableModal").off('hide.bs.modal');
            }

            function monthDiff(d1, d2) {
                var months;
                months = (d2.getFullYear() - d1.getFullYear()) * 12;
                months -= d1.getMonth();
                months += d2.getMonth();
                return months <= 0 ? 0 : months;
            }

            $scope.getActiveLicense = function () {
                ShowLoader();
                backofficeProviderLicenseService.individualLicenseDetailBYIndividualId(sessionStorage.Key, $scope.providerId)
                    .then(function (responseItem) {
                        $scope.activeIndividualLicense = responseItem.IndividualLicenseList.where('!!item.IsLicenseActive').orderByDescending('IndividualLicenseId').firstOrDefault();
                        var noOfMonths = 24;
                        var todaysDate = new Date();
                        var expirationDate = new Date($scope.activeIndividualLicense.LicenseExpirationDate);
                        var effectiveCEDate = new Date($scope.activeIndividualLicense.OriginalLicenseDate);
    
                        var year = Number($scope.activeIndividualLicense.LicenseExpirationDate.substring(0, 4));
                        var month = Number($scope.activeIndividualLicense.LicenseExpirationDate.substring(5, 7));
                        var day = Number($scope.activeIndividualLicense.LicenseExpirationDate.substring(8, 10));
    
                        var firstYearCEEffectiveDate = new Date(year - 2, month - 1, day + 1);
                        var firstYearCEExpirationDate = new Date(year - 1, month - 1, day);
                        var secondYearCEEffectiveDate = new Date(year - 1, month - 1, day + 1);
                        var secondYearCEExpirationDate = new Date($scope.activeIndividualLicense.LicenseExpirationDate);
    
                        if (todaysDate > expirationDate) {
                            $scope.individualLicense.isLicenseExpired = true;
                            year = todaysDate.getFullYear();
                            month = todaysDate.getMonth();
                            day = todaysDate.getDate();
    
                            firstYearCEEffectiveDate = new Date(year - 2, month, day + 1);
                            firstYearCEExpirationDate = new Date(year - 1, month, day);
                            secondYearCEEffectiveDate = new Date(year - 1, month, day + 1);
                            secondYearCEExpirationDate = new Date();
                        }
                        if (!!$scope.activeIndividualLicense) {
                            if (!!$scope.activeIndividualLicense.CEDate && $scope.activeIndividualLicense.CEDate.length >= 10) {
                                var year = Number($scope.activeIndividualLicense.CEDate.substring(0, 4));
                                var month = Number($scope.activeIndividualLicense.CEDate.substring(5, 7));
                                var day = Number($scope.activeIndividualLicense.CEDate.substring(8, 10));
                                var conversionDate = new Date(year, month - 1, day);
                                noOfMonths = monthDiff(conversionDate, expirationDate);
                                $scope.individualLicense.reEntryLicensee = true;
                                if (noOfMonths < 24) {
                                    $scope.individualLicense.effectiveCEDate = new Date($scope.activeIndividualLicense.CEDate);
                                    $scope.individualLicense.isProrated = true;
                                }
                                else {
                                    $scope.individualLicense.effectiveCEDate = firstYearCEEffectiveDate;
                                }
                            }
                            else {
                                var effectiveDate = new Date($scope.activeIndividualLicense.OriginalLicenseDate);
                                // var originalLicenseDate = new Date($scope.activeIndividualLicense.originalLicenseDate);
                                noOfMonths = monthDiff(effectiveDate, expirationDate);
                                if (!$scope.activeIndividualLicense.LicenseEffectiveDate || $scope.activeIndividualLicense.LicenseEffectiveDate == "") {
                                    $scope.individualLicense.newLicensee = true;
                                    if (noOfMonths < 24) {
                                        $scope.individualLicense.effectiveCEDate = effectiveCEDate;
                                        $scope.individualLicense.isProrated = true;
                                    }
                                    else {
                                        $scope.individualLicense.effectiveCEDate = firstYearCEEffectiveDate;
                                    }
                                }
                                else {
                                    $scope.individualLicense.firstYearCEEffectiveDate = firstYearCEEffectiveDate;
                                    $scope.individualLicense.firstYearCEExpirationDate = firstYearCEExpirationDate;
                                    $scope.individualLicense.secondYearCEEffectiveDate = secondYearCEEffectiveDate;
                                    $scope.individualLicense.secondYearCEExpirationDate = secondYearCEExpirationDate;
                                }
                            }
                        }
                        // start AD-364
                        if (!!$scope.activeIndividualLicense.PreviousCEDate && $scope.activeIndividualLicense.PreviousCEDate.length >= 10) {
                            var year = Number($scope.activeIndividualLicense.PreviousCEDate.substring(0, 4));
                            var month = Number($scope.activeIndividualLicense.PreviousCEDate.substring(5, 7));
                            var day = Number($scope.activeIndividualLicense.PreviousCEDate.substring(8, 10));
                            $scope.individualLicense.PceDueDate = new Date(year + 6, month - 1, day);
                        }
                        // End AD-364
                        HideLoader();
                    }, function (error) {
                        HideLoader();
                        $scope.showStatusMsg('-', 'Some error occurred!');
                    });
            }

            var GetRenewalPeriodText = function () {
                $rootScope.isRetiredOrDelinquent = false;
                backofficeProviderLicenseService.providerLicenseDetailBYProviderId(sessionStorage.Key, $scope.providerId).then(function (res) {
                    if (res.ProviderLicenseResponseList) {
                        $scope.providerLicenseList = res.ProviderLicenseResponseList;
                        $scope.providerLicense = res.ProviderLicenseResponseList.where('!!item.IsLicenseActive').orderByDescending('ProviderLicenseId').firstOrDefault();;
                        // $scope.getActiveLicense();
                    }
                    if (!!$scope.providerLicenseList && $scope.providerLicenseList.length > 0) {
                        $scope.showLicenseList = $scope.providerLicenseList.filter(function (obj) {
                            return (obj.ProviderStatusTypeId == 1 || obj.ProviderStatusTypeId == 4);
                        });
                        // console.log('licenseList', $scope.showLicenseList);
                        
                        $scope.showLicenseList = $scope.showLicenseList.map(function (obj) {
                            if (obj.ProviderLicenseTypeId == 1 || obj.ProviderLicenseTypeId == 2) {
                                obj.ProviderLicenseExpirationDate = 'None';
                                $scope.FirmType = obj.ProviderLicenseTypeId;
                                sessionStorage.LicenseTypeName =  $scope.FirmType == 2 ? 'Corporation' : 'Partnership';
                            }
                            obj.ProviderLicenseStatusTypeName = "";
                            let casData = $scope.DisplayCASLicensePrimaryStatus(obj);
                            casData.then(function(CASresults) {
                                obj.ProviderLicenseStatusTypeName = CASresults.map(function(eachRecord) {
                                    return $scope.CASlicenseStatusTypeName(eachRecord.primStatCde);

                                });
                                $scope.providerLicenseStatus = obj.ProviderLicenseStatusTypeName;
                                console.log($scope.providerLicenseStatus);
                                // console.log($scope.ProviderLicenseStatusTypeName);
                                if ((obj.ProviderLicenseStatusTypeName.includes('RETIRED')
                                || (obj.ProviderLicenseStatusTypeName.includes('RETIRED') && 
                                obj.ProviderLicenseStatusTypeName.includes('DELINQUENT')))
                                )
                                {   
                                    $rootScope.isRetiredOrDelinquent = true
                                }
                             })
 
                            return obj;
                        });
                        if (!!$scope.showLicenseList && $scope.showLicenseList.length > 0){
                            $scope.currLic = $scope.showLicenseList[0];
                            $scope.LicNum = $scope.showLicenseList[0].ProviderLicenseNumber;
                        }   
                    }
                    else{
                        $scope.showLinkLicenseBtn = true;
                    }
                });
            };

            $scope.DisplayCASLicensePrimaryStatus = async function(ApplicationInformation){
                ShowLoader();
                if(!!ApplicationInformation.IDNumber){
                    let requestData = {
                        isn: ApplicationInformation.IDNumber,
                        EntityId: ApplicationInformation.ProviderId,
                        EntityType: "P"
                    };
                    try {
                        return await dcaIService.getCaLicFilePrimStatCde(sessionStorage.Key, requestData)
                            .then(function (response){
                                if ($scope.checkServerResponse(response)) {
                                    if (!!response.EntityRecords && response.EntityRecords.length > 0) {
                                        return response.EntityRecords;
                                    }
                                }
                            }, function(data) {
                                $scope.showStatusMessage(data, "error");
                                return false;
                            });
                    } catch (ex) {
                        if (ex != null) {
                            $scope.showStatusMessage(ex.message, "error");
                        }
                    }
                }
                HideLoader();
            };

            // $scope.DisplayPceDueDate = async function (ApplicationInformation) {
            //     ShowLoader();
            //     if(!!ApplicationInformation.IDNumber){
            //         let requestData = {
            //             isn: ApplicationInformation.IDNumber,
            //             IndividualId: $scope.individualId
            //         };
            //         try {
            //             return await dcaIService.getPceDate(sessionStorage.Key, requestData)
            //                 .then(function (response){
            //                     // console.log("pceDate", response);
            //                     $scope.lastPceDt = response.EntityRecords[0].lastPceDt
            //                     if (!!$scope.lastPceDt && $scope.lastPceDt >= 8) {
            //                         var year = Number($scope.lastPceDt.substring(0, 4));
            //                         var month = Number($scope.lastPceDt.substring(4, 6));
            //                         var day = Number($scope.lastPceDt.substring(6, 8));
            //                         $scope.lastPceDt= new Date(year + 6, month-1, day);
            //                     }


            //                 }, function(data) {
            //                     $scope.showStatusMessage(data, "error");
            //                     return false;
            //                 });
            //         } catch (ex) {
            //             if (ex != null) {
            //                 $scope.showStatusMessage(ex.message, "error");
            //             }
            //         }
            //     }
            // };

            $scope.CASlicenseStatusTypeName = function(licenseStatusTypeId) {
                if (licenseStatusTypeId) {
                    return $scope.licenseStatusList.whereEquals(licenseStatusTypeId, 'LicenseStatusTypeCode').firstOrDefault().LicenseStatusTypeName;
                }
            };

            var getLicenseStatuses = function() {
                ShowLoader();
                backofficeProviderLicenseService.getLicenseStatuses(sessionStorage.Key)
                    .then(function(response) {
                        if (response.Status) {
                            $scope.licenseStatusList = response.LicenseStatusTypeGetList;
                        } else {
                            $scope.licenseStatusList = {};
                        }
                        HideLoader();
                    }, function(err) {
                        HideLoader();
                    });
            };

            // var getLicenseOutputDocuments = function () {
            //     $scope.documentsList = [];
            //     multipleFileUploadService.individualDocumentGetByIndividualId(sessionStorage.Key, $scope.individualId).then(function (response) {
            //         if (response.IndividualDocumentGetList != null && response.IndividualDocumentGetList.length > 0) {
            //             response.IndividualDocumentGetList.map(function (i) {
            //                 if (i.DocumentName != null) {
            //                     i.LicenseNumber = i.DocumentName.substring(0, i.DocumentName.indexOf('+'));;
            //                 }
            //                 return i;
            //             });
            //             $scope.documentsList = response.IndividualDocumentGetList.whereEquals(3, 'DocumentId').orderByDescending('EffectiveDate');
            //         }
            //     }, function (error) {

            //     });
            // };

            // var getApplicationDocumentByIndividualIdForOthers = function () {
            //     backofficeIndividualLicenseService.getApplicationDocumentByIndividualIdForOthers(sessionStorage.Key, $scope.individualId, 3).then(function (res) {
            //         $scope.ApplicationDocumentList = res.ApplicationDocumentList;
            //     });
            // };

            // $scope.DownloadDocument = function (path) {
            //     if (path) {
            //         window.open(WebApiUrl + 'Individual/DownloadPDF/' + sessionStorage.Key + '?path=' + path, '_blank');
            //     }
            // };

            $scope.loadAllProviderLegalAddInfo = function (legalInfoOption, hideLoader) {
                peerReviewService.loadAllProviderLegalAddInfo($scope.providerId, $scope.applicationId, $scope.userId, legalInfoOption, sessionStorage.Key)
                    .then(function (response) {
                        if (!!response && response.length > 0) {
                            legalInfoOption.providerLegalAddInfo = response[0];
                        } else {
                            legalInfoOption.providerLegalAddInfo = {
                                IsActive: true,
                                EntityLegalId: legalInfoOption.EntityLegalId,
                                ProviderId: $scope.providerId,
                                ApplicationId: $scope.applicationId
                            }
                        }
                        if (hideLoader)
                            HideLoader();
                    }, function (data) {
                        ShowErrMsg();
                    });
            };

            var processProviderLegalResponse = function (legalInfoData) {
                // var ques3 = '';
                // var ques4 = '';
                // var ques5 = '';
                // var ques6 = '';
                var legalInfoRecords = [];
                $scope.legalInfoList = {};
                $scope.providerAdditionalInfo = [];
    
                
                angular.forEach(legalInfoData, function (legalInfo) {
                    if (legalInfo.ContentItemLkId == 813) {
                        legalInfo.IsActive = true;
                        $scope.shareholderOrPartner1 = legalInfo;
                        $scope.providerAdditionalInfo.push($scope.shareholderOrPartner1);
                    }
                    else if (legalInfo.ContentItemLkId == 814) {
                        legalInfo.IsActive = true;
                        $scope.shareholderOrPartner2 = legalInfo;
                        $scope.providerAdditionalInfo.push($scope.shareholderOrPartner2);
                    }

                    $scope.providerAdditionalInfo.documentUpload = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                        ApplicationId: $scope.applicationId,
                        ProviderId: $scope.providerId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FR",
                        LoadByDocIdAndDocCode: true,
                        DocId: $scope.firmType == 2 ? 118 : 117,
                        DocCode: $scope.firmType == 2 ? "D1118" : "D1117",
                        LoadDocumentsWithoutBroadCast: true
                    };
                    
                    legalInfoRecords.push(legalInfo);
                    $scope.legalInfoList['item' + legalInfo.ContentItemLkId] = legalInfo;
    
                });
                
                return legalInfoRecords;
            };

            var LicenseRenewalCheck = function () {
                //New code starts here 
                $scope.renewalCheckData = {
                    ProviderId: sessionStorage.ProviderId,
                   // ApplicationId: null,
                    IsBackOffice: false,
                    CheckRenewalEligibility: true,
                    CreatePendingInternally: false,
                    BoardInfoId: 1,
                    EntityType: "P"
                
                };
                licenseRenewalService.checkAndCreatePendingRenewal(sessionStorage.Key, $scope.renewalCheckData).then(function (responseLicenseCheck) {
                    if (!!responseLicenseCheck.Status) {
                        $scope.ShowRenewal = true;
                        $scope.renewalCheckData = responseLicenseCheck.RenewalCheckData;
                        $scope.renewalDataForProcessing = {
                            ProviderId: sessionStorage.ProviderId,
                           // ApplicationId: null,
                            IsBackOffice: false,
                            CheckRenewalEligibility: false,
                            CreatePendingInternally: false,
                            BoardInfoId: 1,
                            EntityType: "P",
                            OpenExistingApplication: false,
                            ProviderTypeId: 5
                        
                        };

                        licenseRenewalService.checkAndCreatePendingRenewal(sessionStorage.Key, $scope.renewalDataForProcessing).then(function (responseLicenseCheck) {
                            if (!!responseLicenseCheck.Status){
                                console.log('1', responseLicenseCheck);
                                $scope.providerLicense = responseLicenseCheck.RenewalCheckData.ProviderLicenseList[0];
                                $scope.applicationInfo = responseLicenseCheck.RenewalCheckData.Application;
                                
                                if ($scope.applicationInfo.DeficiencyStatusId == 1 || $scope.applicationInfo.DeficiencyStatusId == 3)
                                {
                                    $scope.ShowRenewal = false;
                                }
                                else if ($scope.applicationInfo.ApplicationStatusId == 42){
                                    $scope.ShowRenewal = false;
                                }
                                
                                else if ($scope.applicationInfo.DeficiencyStatusId == null &&  $scope.ShowRenewal == true)
                                {
                                    $scope.ShowRenewal = true;
                                }
                                else if($scope.applicationInfo.DeficiencyStatusId == null &&  $scope.ShowRenewal == false)
                                {
                                    $scope.ShowRenewal = false;
                                }


                                // console.log('defval',$scope.hideRenwBtnWhnDef )
                                $scope.applicationId = responseLicenseCheck.RenewalCheckData.Application.ApplicationId;
                                $scope.ContentLkToPageTabSectionId = '3';

                                peerReviewService.legalInfoGetProvider(sessionStorage.ProviderId, $scope.applicationId, $scope.ContentLkToPageTabSectionId, sessionStorage.Key, "cbaRenewal")
                                    .then(function (response) {
                                        
                                        $scope.legalInfo = response.EntityLegalList;
                                        $scope.legalInfo = processProviderLegalResponse($scope.legalInfo);
                                       
                                        backofficeProviderLicenseService.providerLicenseDetailBYProviderId(sessionStorage.Key, sessionStorage.ProviderId)
                                            .then(function (responseItem) {
                                                $scope.activeproviderLicense = responseItem.ProviderLicenseResponseList.where('!!item.IsLicenseActive').orderByDescending('ProviderLicenseId').firstOrDefault();
                                                $scope.FirmType = $scope.activeproviderLicense.ProviderLicenseTypeId;
                                                
    
                                                var LinkIndividualLicenseId = 1;
    
                                                // if($scope.applicationInfo.ApplicationStatusId == 3){
                                                //     LinkIndividualLicenseId = $scope.activeproviderLicense.LinkIndividualLicenseId;
                                                //     $scope.activeproviderLicense = responseItem.IndividualLicenseList.whereEquals(LinkIndividualLicenseId,'ProviderLicenseId').firstOrDefault();
                                                // }
                                                
                                            }, function (error) {
                                                HideLoader();
                                                $scope.showStatusMessage({
                                                    messageContent: 'Some error occurred!',
                                                    messageType: 'error'
                                                });
                                            });
                                        HideLoader();
                                    }, function (error) {
                                        HideLoader();
                                        $scope.showStatusMessage({
                                            messageContent: 'Some error occurred !',
                                            messageType: 'error'
                                        });
                                    });
                                // if (!$scope.isRenewal) {
                                //     getaffidavit();
                                // }
                            
                                
                            }

                        });


                    } else {
                        if (responseLicenseCheck.ResponseReason != 2 && responseLicenseCheck.ResponseReason != 5) {
                            showStatusMessage(responseLicenseCheck.Message, "error");

                        } else {
                            if (responseLicenseCheck.ResponseReason == 2) {
                                $scope.renewalResponseReason = "You are not currently eligible to renew. This is because either you have recently renewed or because you are not within 56 days (8 weeks) of your next expiration date!";
                            } else if (responseLicenseCheck.ResponseReason == 5) {
                                $scope.renewalResponseReason = "You are not eligible to renew your license at this time. Please contact the Board for further information!";
                            }
                        }
                        HideLoader();
                    }
                    $scope.renewalDataLoaded = true;
                }, function (data) {
                    $scope.renewalDataLoaded = true;
                    HideLoader();
                    if (data != null) {
                        showStatusMessage(data.message, "error");
                    }
                });
            };

            $scope.individualSave = function () {
                var deferred = $q.defer();
                try {
                    backofficeIndividualPersonalService.individualSave([$scope.person], sessionStorage.Key)
                        .then(function (data) {
                            deferred.resolve(data);
                        }, function (err) {
                            deferred.reject(err);
                        });
                } catch (ex) {
                    deferred.reject(ex);
                }
                return deferred.promise;
            };

            // $scope.individualByIndividualId = function () {
            //     var deferred = $q.defer();
            //     try {
            //         backofficeIndividualPersonalService.individualBYIndividualId(sessionStorage.Key, $scope.individualId)
            //             .then(function (data) {
            //                 if (data.Status) {
            //                     $scope.person = data.IndividualResponse[0];
            //                     deferred.resolve(data);
            //                 } else {
            //                     deferred.reject(data);
            //                 }
            //             }, function (err) {
            //        backofficeIndividualPersonalService         deferred.reject(err);
            //             });
            //     } catch (ex) {
            //         deferred.reject(ex);
            //     }
            //     return deferred.promise;
            // };

            $scope.checkTempPswd = function () {
                if(!!sessionStorage.UserId)
                {
                providerUserLinkingService.getUser(sessionStorage.Key, sessionStorage.UserId).then(function (response) {

                    if(!!response) {
                        sessionStorage.TemporaryPassword = response.Users.TemporaryPassword;
                        sessionStorage.IsFirstLogin = response.Users.TemporaryPassword;
                    }
                    else {
                        $scope.showStatusMessage(res.Message, "error");
                    }
                    HideLoader();
                }, function (err) {
                    HideLoader();
                    $scope.showStatusMessage(err.Message, "error");
                 });
                }

            };

            var providerGetByProviderId = function () {
                ShowLoader("Please Wait...");
                backofficeProviderLicenseService.getProviderById(sessionStorage.Key, $scope.providerId).then(function (res) {
                    if (res.Status) {
                        
                        $scope.providerDetails = res.ProviderResponse;
                        sessionStorage.LicenseNumber = res.ProviderResponse.LicenseNumber;
                        
                    } else {
                        $scope.showStatusMessage(res.Message, "error");
                    }
                    HideLoader();
                }, function (err) {
                    HideLoader();
                    $scope.showStatusMessage(err.Message, "error");
                });
            };

            ///Getting Configuration Setting by configuration string
            $scope.ConfigurationGetbySettings = function (configuration) {
                try {
                    ConfigurationService.ConfigurationGetbySettings(sessionStorage.Key, configuration)
                        .then(function (response) {
                            response = response.data;
                            console.log('response', response)
                            if ($scope.checkServerResponse(response)) {
                                if (response.ConfigurationList) {
                                    if (configuration == 'DisplayAddressandContactinDashboardLinkedByReference') {
                                        console.log('response.ConfigurationList', response.ConfigurationList);
                                        $scope.DisplayAddressandContactinDashboardLinkedByReference = response.ConfigurationList[0] ? (response.ConfigurationList[0].Value == "True" ? true : false) : true;
                                    }
                                }
                            }
                            return;
                        }, function (data) {
                            data = data.data;
                            showStatusMessage(data, "error");
                        });
                } catch (ex) {
                    if (ex != null) {
                        showStatusMessage(ex.message, "error");
                    }
                    return;
                }
            };

            init();

            $scope.$on("closeLicenseOutputForm", function (event, args) {
                getLicenseOutputDocuments();
            });


        }]);
})();