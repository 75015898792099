(function () {
    'use strict'

    angular.module('app.core')
        .factory('paymentConfirmationService', paymentConfirmationService)

    paymentConfirmationService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function paymentConfirmationService($http, $q, WebApiUrl, AppConfig) {

        return {
            individualByIndividualId: _individualByIndividualId,
            getApplicationInfoByApplicationId: _getApplicationInfoByApplicationId,
            getIndividualLicense: _getIndividualLicense
        };

        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function _getApplicationInfoByApplicationId(applicationId, userId, key) {
            return returnDefferedResult($http.get(WebApiUrl + 'Common/ApplicationInfoByApplicationId/' + key + '?ApplicationId=' + applicationId + '&UserId=' + userId));
        };

        function _individualByIndividualId(Key, individualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualBYIndividualId/' + Key + '?IndividualId=' + individualId));
        };

        function _getIndividualLicense(key, individualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualLicenseDetailBYIndividualId/' + key + '?IndividualId=' + individualId));
        };
    }
})();