(function () {
    'use strict';
    angular.module('app.backofficeAdministor').factory('SetPermissionsService', SetPermissionsService);
    SetPermissionsService.$inject = ['$http', 'WebApiUrl', '$q'];
    function SetPermissionsService($http, WebApiUrl, $q) {
        var _UserRoleGetAll = function (key) {
            var data = $http.get(WebApiUrl + 'Users/UserTypeGetAll/' + key);
            return returnResult(data);
        };
        var _Permissions = function (key) {
            var data = $http.get(WebApiUrl + 'Menu/MenuGetAll/' + key);
            return returnResult(data);
        };
        var _savePermissions = function (key, param) {
            var data = $http.post(WebApiUrl + 'Role/RoleSave/' + key, param);
            return returnResult(data);
        };
        var _roleMenuGetbyRoleId = function(key,id){
            var data = $http.get(WebApiUrl + 'Role/RoleMenuGetbyRoleId/1?RoleId='+id);
            return returnResult(data);
        };
        var _saveMenuPermissions = function(key,param){
            var data = $http.post(WebApiUrl + 'Role/RoleMenuSave/' + key, param);
            return returnResult(data);
        };
        var _RoleMenusSave = function (key, param) {
            var data = $http.post(WebApiUrl + 'Role/RoleMenusSave/' + (key ? key : 'key'), param);
            return returnResult(data);
        };
        var _GetJSTreeMenuList = function (urlParam) {
            var data = $http.get(WebApiUrl + 'Menu/GetJSTreeMenuList' + urlParam);
            return returnResult(data);
        };
        var returnResult = function (data) {
            var deferred = $q.defer();
            data
                .then(function (response) {
                    deferred.resolve(response.data);
                })
                .catch(function (response) {
                    deferred.reject(response);
                });
            return deferred.promise;
        };
         return {
             UserRoleGetAll: _UserRoleGetAll,
             Permissions: _Permissions,
             savePermissions: _savePermissions,
             RoleMenuGetbyRoleId: _roleMenuGetbyRoleId,
             SaveMenuPermissions: _saveMenuPermissions,
             GetJSTreeMenuList: _GetJSTreeMenuList,
             RoleMenusSave: _RoleMenusSave
         };
    }
})();