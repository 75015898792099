(function () {
    'use strict'

    angular.module('app.core')
        .factory('applicationListingService', applicationListingService)

    applicationListingService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function applicationListingService($http, $q, WebApiUrl, AppConfig) {
        return {
            processApplicationApproval: _processApplicationApproval,
            processApplicationAudit:_processApplicationAudit,
            processApplicationAbandoned: _processApplicationAbandoned
        };

        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function _processApplicationApproval(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'ApplicationProcessing/ProcessApplicationApproval/' + key, reqData));
        };

        function _processApplicationAudit(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'ApplicationProcessing/ProcessApplicationAudit/' + key, reqData));
        };

        function _processApplicationAbandoned(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'ApplicationProcessing/ProcessApplicationAbandoned/' + key, reqData));
        };
    }
})();