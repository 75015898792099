(function() {
    'use strict'

    angular.module('reporting.QueryReporting')
    .directive('queryReportingConditionBlock', [QueryReportingConditionBlock]);

    function QueryReportingConditionBlock() {
        return {
            restrict: 'E',
            scope: {
                rootConditionBlock: '=conditionBlock',
                currentQuery: '=query',
                title: '@',
                showStatusMsg: '&'
            },
            link: function (scope, element, attrs) {

            },
            controller: 'QueryReportingConditionBlockController',
            templateUrl: 'app/components/report/query-reporting/query-reporting-condition-block.html'
        };
    }
})();