(function () {
    'use strict'

    angular.module('app.backofficeAdministor')
    .directive('contentManagementAdd', [contentManagementAdd]);

    function contentManagementAdd() {
        return {
            restrict: 'E',
            templateUrl: 'app/components/backoffice/administrator/content-management/content-management-add/content-management-add.html',
            controller: 'contentManagementAddController'
        };
    }
})();