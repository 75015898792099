(function () {
    'use strict'

    angular.module('app.enforcement')
    .controller('ComplaintCorrespondenceController', ['$scope', '$rootScope', '$q', '$filter', 'ComplaintCorrespondenceService', 'utilityService', ComplaintCorrespondenceController]);

    function ComplaintCorrespondenceController($scope, $rootScope, $q, $filter, ComplaintCorrespondenceService, utilityService) {
        var _key = sessionStorage.Key;
        var _childName = '';
        var isBackoffice = (sessionStorage.isBackofficeUser == true) || (sessionStorage.isBackofficeUser == 'true');

        var getNewCorrespondence = function () {
            return {
                ComplaintCorrespondenceId: 0,
                ComplaintId: $scope.complaintId,
                ComplaintInvolvedPartyId: $scope.complaintInvolvedPartyId,
                ConversationGuid: null,
                Type: 'E',
                EmailTo: $scope.involvedParty.Email,
                EmailFrom: (!!$scope.serverConfig.EmailFrom) ? $scope.serverConfig.EmailFrom : $scope.uiConfig.Defaults.EmailFrom,
                UserIdFrom: sessionStorage.UserID,
                Subject: '',
                CommunicationSource: (!!isBackoffice) ? 'Backoffice' : 'Online',
                CommunicationText: '',
                CommunicationStatus: 'P',
                ReferenceNumber: null,
                TemplateId: null,
                NotifyTemplateId: null,
                InternalSubject: null,

                Attachments: [],

                CorrespondenceMode: $scope.mode,

                NotificationTemplateCode: $scope.uiConfig.NotificationTemplateCode,
            };
        };

        var errorHandler = function (err) {
            $scope.showStatusMsg('-', err);
            $scope.operationPending(false);
        };

        var getConfig = function () {
            var deferred = $q.defer();
        
            try {
                utilityService.getConfigData('/components/backoffice/enforcement/complaint-correspondence/complaint-correspondence.server-config.json')
                .then(function(data) {
                    if (!!data) {
                        $scope.config = data;
        
                        $scope.uiConfig = $rootScope.getUIConfig($scope.configName, $scope.config);
        
                        $scope.isConfigLoaded = true;

                        if (!!$scope.getConfigExt) {
                            $scope.getConfigExt();
                        }
                    }
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
        
            return deferred.promise;
        };

        var getServerConfig = function () {
            var deferred = $q.defer();
            
            try {
                var promises = [];

                if (!$scope.uiConfig.ConfigurationSettings || $scope.uiConfig.ConfigurationSettings.length == 0) {
                    deferred.resolve({});
                    return deferred.promise;
                }

                $scope.uiConfig.ConfigurationSettings.forEach(function (setting) {
                    promises.push(ComplaintCorrespondenceService.getConfigurationBySetting(sessionStorage.Key, setting.SettingName));
                });

                $q.all(promises)
                .then(function(data) {
                    if (!!data && data.length > 0) {
                        data.forEach(function (response) {
                            var setting = response.ConfigurationList.firstOrDefault();
                            var propName = $scope.uiConfig.ConfigurationSettings.whereEquals(setting.Setting, 'SettingName').select('PropertyName').firstOrDefault();

                            $scope.serverConfig[propName] = setting.Value;
                        });
                    }

                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.getInvolvedParty = function (complaintInvolvedPartyId) {
            var deferred = $q.defer();
            
            try {
                ComplaintCorrespondenceService.getInvolvedParty(sessionStorage.Key, $scope.complaintId, complaintInvolvedPartyId)
                .then(function(data) {
                    if (!!data.Status && !!data.ComplaintInvolvedPartyList) {
                        $scope.involvedParty = data.ComplaintInvolvedPartyList.firstOrDefault();
                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.sendCorrespondence = function (correspondence) {
            var deferred = $q.defer();
            
            try {
                correspondence.Attachments.forEach(function (doc) {
                    doc.LinkType = 'AT';
                });
                ComplaintCorrespondenceService.sendCorrespondences(sessionStorage.Key, [correspondence])
                .then(function(data) {
                    if (!!$scope.sendCorrespondenceExt) {
                        $scope.sendCorrespondenceExt();
                    }
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.getCorrespondences = function () {
            var deferred = $q.defer();
            
            try {
                var correspondenceSearch = {
                    ComplaintId: $scope.complaintId,
                    ComplaintInvolvedPartyId: $scope.complaintInvolvedPartyId,
                };

                ComplaintCorrespondenceService.getCorrespondences(sessionStorage.Key, correspondenceSearch)
                .then(function(data) {
                    if (data.Status) {
                        $scope.correspondenceList = data.CorrespondenceList;
                        $scope.displayCorrespondenceList = data.CorrespondenceList;

                        if (!!$scope.getCorrespondencesExt) {
                            $scope.getCorrespondencesExt();
                        }
                        deferred.resolve(data);
                    } else {
                        $scope.correspondenceList = [];
                        $scope.displayCorrespondenceList = [];
                        deferred.reject(data);
                    }
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.createCorrespondenceClicked = function () {
            $scope.current.correspondence = getNewCorrespondence();
        };

        $scope.sendCorrespondenceClicked = function () {
            if (!$scope.validateForm()) {
                return;
            }

            $scope.operationPending(true, 'Sending...');

            $scope.sendCorrespondence($scope.current.correspondence)
            .then(function (data) {
                if (data.Status) {
                    $scope.cancelCorrespondenceClicked();

                    $scope.getCorrespondences()
                    .then(function (data) {
                        if (data.Status) {
                            $scope.operationPending(false);
                        } else {
                            errorHandler(data);
                        }
                    }, errorHandler)
                } else {
                    errorHandler(data);
                }
            }, errorHandler);
        };

        $scope.cancelCorrespondenceClicked = function () {
            $scope.current.correspondence = null;
        };

        var checkBodyContent = function (correspondence, searchString, searchRegex) {
            var rawText = $filter('htmlToPlaintext')(correspondence.CommunicationText).toLowerCase();

            return !!rawText.match(searchRegex);
        };

        $scope.searchTextChanged = function () {
            $scope.displayCorrespondenceList = $filter('filterLog')($scope.correspondenceList, $scope.searchParams.searchText, [
                    checkBodyContent, 'EmailTo', 'EmailFrom', 'UserNameFrom'
                ], 'MM/dd/yyyy hh:mm:ss a');
        };

        $scope.toggleRowDetailsClicked = function (item, name) {
            if (item.rowDetails == name) {
                item.rowDetails = '';
            } else {
                item.rowDetails = name;
            }

            item.rowClass = {
                'row-expanded': !!item.rowDetails
            };
        };

        $scope.closeRowDetailsClicked = function (item) {
            item.rowDetails = '';
            item.rowClass = {
                'row-expanded': !!item.rowDetails
            };
        };

        $scope.init = function () {
            $scope.current = {};
            $scope.uiLogic = {};
            $scope.pager = {};
            $scope.searchParams = {};
            $scope.serverConfig = {};

            if (!$scope.mode || typeof($scope.mode) != 'string') {
                $scope.mode = 'email';
            }
            $scope.mode = $scope.mode.toLowerCase();

            if (!$scope.configName) {
                $scope.configName = 'Default';
            }
            
            $q.all([
                getConfig(),
                $scope.getInvolvedParty($scope.complaintInvolvedPartyId)
            ])
            .then(function() {
                getServerConfig()
                .then(function () {
                    $scope.getCorrespondences()
                    .then(function () {
                        $scope.operationPending(false);
                    }, errorHandler);
                }, errorHandler);
            }, errorHandler);

            if (!!$scope.initExt) {
                $scope.initExt();
            }
        };
    }
})();
