(function() {
    'use strict'

    angular.module('app.provider')
        .factory('backofficeProviderAdressService', backofficeProviderAdressService)

    backofficeProviderAdressService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeProviderAdressService($http, $q, WebApiUrl, AppConfig) {

        var _providerAddressBYProviderId = function (Key, ProviderId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Provider/ProviderAddressByProviderId/' + Key + '?ProviderId=' + ProviderId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _providerContactBYProviderId = function (Key, ProviderId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Provider/ProviderContactByProviderId/' + Key + '?ProviderId=' + ProviderId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _addressSave = function (ProviderAddress, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/ProviderAddressSave/' + key, ProviderAddress)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _addressContactSave = function(contact, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/ProviderContactsSave/' + key + '?Source=' + AppConfig.Source, contact)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            providerAddressBYProviderId: _providerAddressBYProviderId,
            providerContactBYProviderId: _providerContactBYProviderId,
            addressSave: _addressSave,
            addressContactSave: _addressContactSave
        }
    }
})();