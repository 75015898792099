(function () {
    'use strict'

    angular.module('app.core').factory('htmlToPdfGenerationService', htmlToPdfGenerationService)

    htmlToPdfGenerationService.$inject = ['$http', '$q', 'WebApiUrl']

    function htmlToPdfGenerationService($http, $q, WebApiUrl) {

        var htmlToPdfGenerationService = {
            processHtml: processHtml,
            processHtmlNewApplication: processHtmlNewApplication,
            processExternalFormHtml: processExternalFormHtml,
            saveTabHtml: saveTabHtml,
            saveUpdatedHtml: saveUpdatedHtml
        };

        function saveUpdatedHtml(key, htmlPDF) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Payment/GenerateApplicationPdfGeneral/'+ key, htmlPDF)
                .then(function (data)  {
                    deferred.resolve(data);
                }, function (error) {
                    deferred.reject(error);
                });     
                return deferred.promise;
        };

        function saveTabHtml(tabHtml, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Common/SaveTabHtml/' + key, tabHtml)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function processHtml(addlCss) {
            var deferred = $q.defer();
            var pdfHtml = angular.element("#applicationFormRenewal").html();
            var exportHtml = angular.element('<div></div>');
            var stylesToImport = ['app/content/css/bootstrap.min.css',
                'app/content/css/font-awesome.min.css',
                'app/content/css/backoffice/Admin.css',
                'app/content/css/backoffice/button.css',
                'app/content/css/editor.css',
                'app/content/css/SearchPanelnew.css'
            ];
            if (!!addlCss) {
                stylesToImport = stylesToImport.concat(addlCss);
            }
            console.log(stylesToImport);
            var dataSavePromises = [];
            angular.forEach(stylesToImport, function (styleToImport, key) {
                dataSavePromises.push($http.get(styleToImport));
            });
            $q.all(dataSavePromises)
                .then(function (response) {
                    console.log(response, 'response')
                    angular.forEach(response, function (styleToImportData, key) {
                        angular.element(exportHtml).append('<style>' + styleToImportData.data + '</style>');
                    });
                    angular.element(document).find('style').each(function (index) {
                        angular.element(exportHtml).append('<style>' + angular.element(this).html() + '</style>');
                    });
                    if (pdfHtml) {
                        pdfHtml = pdfHtml.replace(/\<textarea /g, '<div class="textAreaReplaced"><div ').replace(/\<\/textarea>/g, '</div></div>');
                        angular.element(exportHtml).append(pdfHtml);
                    }
                    var panelPayment = angular.element(exportHtml).find('#pnlPayment')[0];
                    if (!!panelPayment)
                        angular.element(panelPayment).remove();
                    var textAreaElements = angular.element(exportHtml).find('.textAreaReplaced');
                    angular.forEach(textAreaElements, function (textAreaElement, index) {
                        if (!!textAreaElement.firstChild && !!textAreaElement.firstChild.attributes && !!textAreaElement.firstChild.attributes.value && !!textAreaElement.firstChild.attributes.value.value)
                            textAreaElement.innerHTML = "<pre>" + textAreaElement.firstChild.attributes.value.value + "</pre>";
                    });
                    var pdfString = "<html><body class='pdf'>" + angular.element(exportHtml).html() + "</body></html>";
                    console.log(pdfString, 'string');
                    deferred.resolve(pdfString);
                }, function (data) {
                    console.log('some error occurred' + data);
                    deferred.reject(data);
                });
            return deferred.promise;
        };

        function processHtmlNewApplication(addlCss) {
            var deferred = $q.defer();
            var pdfHtml = angular.element("#newApplicationForm").html();
            var exportHtml = angular.element('<div></div>');
            var stylesToImport = ['app/content/css/bootstrap.min.css',
                'app/content/css/font-awesome.min.css',
                'app/content/css/backoffice/Admin.css',
                'app/content/css/backoffice/button.css',
                'app/content/css/editor.css',
                'app/content/css/SearchPanelnew.css'
            ];
            if (!!addlCss) {
                stylesToImport = stylesToImport.concat(addlCss);
            }
            var dataSavePromises = [];
            angular.forEach(stylesToImport, function (styleToImport, key) {
                dataSavePromises.push($http.get(styleToImport));
            });
            $q.all(dataSavePromises)
                .then(function (response) {
                    angular.forEach(response, function (styleToImportData, key) {
                        angular.element(exportHtml).append('<style>' + styleToImportData.data + '</style>');
                    });
                    angular.element(document).find('style').each(function (index) {
                        angular.element(exportHtml).append('<style>' + angular.element(this).html() + '</style>');
                    });
                    angular.element(exportHtml).append(pdfHtml);

                    var innerContentForm = angular.element(exportHtml).find('#innerContentForm')[0];
                    if (!!innerContentForm)
                        angular.element(innerContentForm).remove();
                    var outerContentForm = angular.element(exportHtml).find('#outerContentForm')[0];
                    if (!!outerContentForm)
                        outerContentForm.innerHTML = "{{innerContent}}";
                    var pdfString = "<html><body class='pdf'>" + angular.element(exportHtml).html() + "</body></html>";
                    deferred.resolve(pdfString);
                }, function (data) {
                    console.log('some error occurred' + data);
                    deferred.reject(data);
                });
            return deferred.promise;
        };

        function processExternalFormHtml(formSelector, addlCss) {
            var deferred = $q.defer();
            var pdfHtml = angular.element(formSelector).html();
            var exportHtml = angular.element('<div></div>');
            var stylesToImport = ['app/content/css/bootstrap.min.css',
                'app/content/css/font-awesome.min.css',
                'app/content/css/backoffice/Admin.css',
                'app/content/css/backoffice/button.css',
                'app/content/css/editor.css',
                'app/content/css/SearchPanelnew.css'
            ];
            if (!!addlCss) {
                stylesToImport = stylesToImport.concat(addlCss);
            }
            console.log(stylesToImport);
            var dataSavePromises = [];
            angular.forEach(stylesToImport, function (styleToImport, key) {
                dataSavePromises.push($http.get(styleToImport));
            });
            $q.all(dataSavePromises)
                .then(function (response) {
                    console.log(response, 'response')
                    angular.forEach(response, function (styleToImportData, key) {
                        angular.element(exportHtml).append('<style>' + styleToImportData.data + '</style>');
                    });
                    angular.element(document).find('style').each(function (index) {
                        angular.element(exportHtml).append('<style>' + angular.element(this).html() + '</style>');
                    });
                    if (pdfHtml) {
                        pdfHtml = pdfHtml.replace(/\<textarea /g, '<div class="textAreaReplaced"><div ').replace(/\<\/textarea>/g, '</div></div>');
                        angular.element(exportHtml).append(pdfHtml);
                    }

                    var textAreaElements = angular.element(exportHtml).find('.textAreaReplaced');
                    angular.forEach(textAreaElements, function (textAreaElement, index) {
                        if (!!textAreaElement.firstChild && !!textAreaElement.firstChild.attributes && !!textAreaElement.firstChild.attributes.value && !!textAreaElement.firstChild.attributes.value.value)
                            textAreaElement.innerHTML = "<pre>" + textAreaElement.firstChild.attributes.value.value + "</pre>";
                    });
                    var pdfString = "<html><body class='pdf'>" + angular.element(exportHtml).html() + "</body></html>";
                    console.log(pdfString, 'string');
                    deferred.resolve(pdfString);
                }, function (data) {
                    console.log('some error occurred' + data);
                    deferred.reject(data);
                });
            return deferred.promise;
        };

        return htmlToPdfGenerationService;
    }
})();