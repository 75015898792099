(function () {
  'use strict'
  angular.module('app.InitialApplication')
    .directive('initialApplicationLicenseHistory', initialApplicationLicenseHistoryDirective);

  initialApplicationLicenseHistoryDirective.$inject = [];

  function initialApplicationLicenseHistoryDirective() {
    return {
      restrict: 'E',
      scope: {
        showSubmitPanel: "=showSubmitPanel",
        showHeader: "=showHeader",
        headingPosition: "=headingPosition",
        isBackoffice: "=isBackoffice",
        applicationId: "=applicationId",
        individualId: "=individualId",
        natValidation: "=natValidation",
        individualLicense: "=individualLicense",
        showStatusMessage: "&",
        tab: "=tab",
        openNextTab: "&",
        isRenewal: '=?isRenewal',
        loadData: "=?loadData",
        applicationInfo: "=?applicationInfo",
        userId: "=userId",
        isIndividualInfo: "=?isIndividualInfo"
      },
      templateUrl: "app/components/individual/application/directives/license-history/license-history.html",
      controller: "InitialApplicationLicenseHistoryController"
    }
  }
})();