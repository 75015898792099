(function () {
    angular
        .module('app.backofficeIndividual')
        .controller("IndividualCorrespondenceController", IndividualCorrespondenceController);

    IndividualCorrespondenceController.$inject = ["$rootScope", "$scope", '$q', "backofficeIndividualCorrespondenceService", "utilityService", "WebApiUrl", "backofficeIndividualAdressService", "UploadDataUrl"];

    function IndividualCorrespondenceController($rootScope, $scope, $q, backofficeIndividualCorrespondenceService, utilityService, WebApiUrl, backofficeIndividualAdressService, UploadDataUrl) {

        $scope.viewConfig = {
            showAddBlock: false
        };

        $scope.invalidFiles = []
        $scope.closeFileError = function(){
            $scope.invalidFiles = []
        }

        $scope.options = {
            language: 'en',
            allowedContent: true,
            entities: false,
            disableNativeSpellChecker: false,
            scayt_autoStartup: true,
            toolbar: [{
                    name: 'basic',
                    items: ['Bold', 'Italic', 'Underline']
                },
                {
                    name: 'links',
                    items: ['Link', 'Unlink']
                },
                {
                    name: 'tools',
                    items: ['Maximize']
                },
                {
                    name: 'document',
                    items: ['Source']
                },
                {
                    name: 'paragraph',
                    items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                }
            ],
        };

        // Called when the editor is completely ready.
        $scope.onReady = function () {
            // ...
        };

        $scope.isCorespondenceEdit = false;

        $scope.sortType = 'CommunicationLogDate';
        $scope.sortOrder = '-';

        $scope.changeSort = function (sortType) {
            if ($scope.sortType == sortType) {
                if ($scope.sortOrder == '+')
                    $scope.sortOrder = '-';
                else
                    $scope.sortOrder = '+';
            } else {
                $scope.sortOrder = '+';
            }
            $scope.sortType = sortType;
        };

        $scope.init = function () {
            $scope.current = {
                userFullName: '',
                userEmail: ''
            };
            $scope.getUserInformation();
            $scope.individualCorrespondenceByIndividualId(true);
        };

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "correspondence" && !$rootScope.individualCurrentTab.isLoadedOnce) {
                $scope.individualId = individualId;
                $scope.init();
                $rootScope.individualCurrentTab.isLoadedOnce = true;
            }
        });

        var clearFileValues = function () {
            $scope.document = {};
        };

        $scope.openAddEditCorrespondence = function (isEdit, individualCorrespondence) {
            $scope.loadingCorrespondence(true);
            clearFileValues();
            $scope.isCorrespondenceEdit = isEdit;
            if (!$scope.isCorrespondenceEdit) {
                $scope.documentUpload = {
                    ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                    ApplicationId: null,
                    IndividualId: $scope.individualId,
                    Email: null,
                    SendEmail: null,
                    TransactionId: null,
                    LicenseeReprint: false,
                    DocumentLkToPageTabSectionId: 1,
                    DocumentLkToPageTabSectionCode: "FR",
                };
                $scope.individualCorrespondence = {
                    IndividualId: $scope.individualId,
                    IsActive: true,
                    CommunicationLogDate: new Date()
                }
                var emailAddressContacts = [];
                backofficeIndividualAdressService.individualContactBYIndividualId(sessionStorage.Key, $scope.individualId)
                    .then(function (response) {
                        $scope.hideStatusMsg();
                        if (response.Status) {
                            angular.forEach(response.IndividualContactResponse, function (individualContact) {
                                if (individualContact.ContactStatusId == 1) {
                                    if (individualContact.ContactTypeId == 8 || individualContact.ContactTypeId == 18) {
                                        if (!!individualContact.ContactInfo) {
                                            emailAddressContacts.push(individualContact.ContactInfo);
                                        }
                                    }
                                }
                            });
                            $scope.individualCorrespondence.EmailTo = emailAddressContacts.distinct().join(',');
                            $scope.viewConfig.showAddBlock = true;

                            $scope.individualCorrespondence.CommunicationText = '<p>&nbsp</p>\n\n<p>&nbsp</p>\n\n<p>{0}</p>\n\n<p>{1}</p>'.format($scope.current.userFullName, $scope.current.userEmail);
                        } else {
                            $scope.showStatusMsg("-", response.Message);
                            return;
                        }
                        $scope.loadingCorrespondence(false);
                    }, function (error) {
                        $scope.viewConfig.showAddBlock = true;
                        $scope.loadingCorrespondence(false);
                        $scope.showStatusMsg("-", error);
                    })
            } else {
                $scope.individualCorrespondence = angular.copy(individualCorrespondence);
                $scope.loadingCorrespondence(false);
                $scope.viewConfig.showAddBlock = true;
            }
        };

        $scope.closeAddEditCorrespondence = function () {
            $scope.viewConfig.showAddBlock = false;
            $scope.viewConfig.showCorrespondenceFullScreen = false;
        };

        $scope.individualCorrespondenceByIndividualId = function (hideMessages) {
            backofficeIndividualCorrespondenceService.individualCorrespondenceByIndividualId(sessionStorage.Key, $scope.individualId)
                .then(function (response) {
                        if (hideMessages) {
                            $scope.hideStatusMsg();
                        }
                        if (response.StatusCode == "00") {
                            if (!!response.IndividualCommunicationLogRequest) {
                                $scope.individualCorrespondences = response.IndividualCommunicationLogRequest.orderByDescending('IndividualCommunicationLogId');
                            } else {
                                $scope.individualCorrespondences = [];
                            }
                        } else {
                            $scope.individualCorrespondences = null;
                            $scope.showStatusMsg("-", response.Message);
                        }
                        $scope.loadingCorrespondence(false);
                    },
                    function (data) {
                        $scope.showStatusMsg("-", data);
                    });
        };

        $scope.saveIndividualCorrespondence = function (correspondenceForm) {
            try {
                $scope.hideStatusMsg();
                correspondenceForm.$submitted = true;
                if (!(!!correspondenceForm.errorMessages && correspondenceForm.errorMessages.length > 0)) {
                    $scope.loadingCorrespondence(true, "Sending Email Correspondence...");
                    $scope.saveIndividualCorrespondenceRecord();
                } else {
                    var errorMessages = [];
                    angular.forEach(correspondenceForm.errorMessages, function (obj) {
                        errorMessages.push(obj.errorMessage);
                    })
                    $scope.showStatusMsg('-', errorMessages);
                }
            } catch (ex) {
                $scope.loadingCorrespondence(false);
                if (ex != null) {
                    $scope.showStatusMsg("-", ex.message);
                }
            }
        };

        $scope.deleteDoc = function (document, index) {
            if (!!$scope.document.documentUploadList && $scope.document.documentUploadList.length > 0) {
                $scope.document.documentUploadList.splice(index, 1);
            }
        };

        $scope.AddNewDocument = function (files) {
            if (!$scope.document.documentUploadList)
                $scope.document.documentUploadList = [];
            if (!!$scope.document.DocumentFile && $scope.document.DocumentFile.length > 0) {
                UploadDataUrl.base64DataUrl(files).then(function (response) {
                    angular.forEach(response.file, function (currentDocFile) {
                        $scope.document.documentUploadList.push({
                            IndividualDocumentId: 1,
                            DocumentLkToPageTabSectionId: 1,
                            DocumentLkToPageTabSectionCode: "FR",
                            DocumentTypeName: "Correspondence",
                            DocumentPath: null,
                            DocName: currentDocFile.name,
                            DocNameWithExtention: currentDocFile.name,
                            DocStrBase64: currentDocFile.base64,
                            EffectiveDate: new Date(),
                            EndDate: null,
                            IsDocumentUploadedbyIndividual: (!sessionStorage.isBackOfficeUser || sessionStorage.isBackOfficeUser == false || sessionStorage.isBackOfficeUser == 'false'),
                            IsDocumentUploadedbyStaff: (!!sessionStorage.isBackOfficeUser && (sessionStorage.isBackOfficeUser == true || sessionStorage.isBackOfficeUser == 'true')),
                            ReferenceNumber: null,
                            DocumentId: 1,
                            DocumentCd: null,
                            DocumentTypeId: 1,
                            OtherDocumentTypeName: null,
                            DocumentUploadedForId: 1
                        });
                    });
                });
            }
        };

        $scope.saveIndividualCorrespondenceRecord = function () {
            var individualCorrespondenceData = angular.copy($scope.individualCorrespondence);
            individualCorrespondenceData.DocumentUploadList = $scope.document.documentUploadList;
            backofficeIndividualCorrespondenceService.individualCorrespondenceSave(individualCorrespondenceData,
                sessionStorage.Key).then(function (response) {
                if (response.Status) {
                    $scope.loadingCorrespondence(true, 'Loading Correspondence..');
                    $scope.individualCorrespondenceByIndividualId();
                    $scope.closeAddEditCorrespondence();
                    $scope.showStatusMsg("+", response.Message);
                } else {
                    $scope.showStatusMsg("-", response.Message);
                }
                $scope.loadingCorrespondence(false);
            }, function (data) {
                $scope.loadingCorrespondence(false);
                if (data != null) {
                    $scope.showStatusMsg("-", data.message);
                }
            });
        };

        $scope.getUserInformation = function () {
            var deferred = $q.defer();
            
            try {
                backofficeIndividualCorrespondenceService.getUserInformation(sessionStorage.Key, sessionStorage.UserID)
                .then(function(data) {
                    if (data.Status) {
                        $scope.userInfo = data.Users;

                        $scope.current.userFullName = $scope.userInfo.FirstName + ' ' + $scope.userInfo.LastName;
                        $scope.current.userEmail = $scope.userInfo.Email;
                    }
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.init();
    }
})();