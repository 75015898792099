(function () {
  'use strict'

  angular.module('app.InitialApplication')
    .factory('initialApplicationFeeDetailsService', initialApplicationFeeDetailsService)

  initialApplicationFeeDetailsService.$inject = ['$http', '$q', 'WebApiUrl']

  function initialApplicationFeeDetailsService($http, $q, WebApiUrl) {
    return {

    };
  }
})();
