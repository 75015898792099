(function () {
    'use strict'
    angular.module('app.core')
        .controller('UserPortalViewController', UserPortalViewController);

    UserPortalViewController.$inject = ['$rootScope', '$scope', '$q', '$window', '$uibModal', 'userPortalViewService'];

    function UserPortalViewController($rootScope, $scope, $q, $window, $uibModal, userPortalViewService) {

        function init() {
            $scope.viewConfig = {
                isUserPortalViewDisabled: true,
                isUserPortalViewLoading: true
            };
            $scope.currentUserData = {};
            HideLoader();
        }

        $scope.loginStaffAsUser = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: '<login-staff>',
                size: 'md',
                backdropClass: 'modalbackdropstatic'
            });

            if(!!$scope.individualId)
            {
                modalInstance.result.then(function (response) {
                    response.loginVerification.IndividualId = $scope.individualId;
                    response.loginVerification.currentUserData = $scope.currentUserData;
                    userPortalViewService.setUserPortalViewData(response.loginVerification);
                    $rootScope.individualEventLog('personal.staffLoginSuccessfull', $scope.individualId);
                    var baseRoute = $window.location.origin + $window.location.pathname;
                    $window.open(baseRoute + '/#/User/UserPortalView', '_blank');
                }, function (response) {
                    // console.log(response);
                });
            }
            else if(!!sessionStorage.ProviderId && !$scope.individualId)
            {
                modalInstance.result.then(function (response) {
                    response.loginVerification.IndividualId = sessionStorage.ProviderId;
                    response.loginVerification.currentUserData = $scope.currentUserData;
                    userPortalViewService.setUserPortalViewData(response.loginVerification);
                    $rootScope.individualEventLog('personal.staffLoginSuccessfull', sessionStorage.ProviderId);
                    var baseRoute = $window.location.origin + $window.location.pathname;
                    $window.open(baseRoute + '#/provider/providerdashboard', '_blank');
                }, function (response) {
                    // console.log(response);
                });
            }

        };

        $scope.$watch('individualId', function () {
            if ($scope.individualId) {
                $scope.viewConfig.isUserPortalViewDisabled = true;
                $scope.viewConfig.isUserPortalViewLoading = true;
                // console.log('initializing');
                // console.log('IndividualId: ' + $scope.individualId);
                var Urlparm = '/' + sessionStorage.Key + '?IndividualId=' + $scope.individualId + '&UserId=' + sessionStorage.UserID;
                userPortalViewService.userByIndividualIdExternal(Urlparm).then(function (response) {
                    if (response.Status) {
                        if (!!response.Users) {
                            $scope.viewConfig.isUserPortalViewDisabled = false;
                            $scope.currentUserData = response.Users;
                        } else {
                            $scope.currentUserData = {};
                            $scope.viewConfig.isUserPortalViewDisabled = true;
                        }
                    } else {
                        $scope.currentUserData = {};
                        $scope.viewConfig.isUserPortalViewDisabled = true;
                    }
                    $scope.viewConfig.isUserPortalViewLoading = false;
                }, function (error) {
                    $scope.currentUserData = {};
                    $scope.viewConfig.isUserPortalViewDisabled = true;
                    $scope.viewConfig.isUserPortalViewLoading = false;
                    showStatusMessage(error, "error");
                });
            } else if (sessionStorage.ProviderId) {
                $scope.viewConfig.isUserPortalViewDisabled = true;
                $scope.viewConfig.isUserPortalViewLoading = true;
                // console.log('initializing');
                // console.log('IndividualId: ' + $scope.individualId);
                var Urlparm = '/' + sessionStorage.Key + '?ProviderId=' + sessionStorage.ProviderId + '&UserId=' + sessionStorage.UserID;
                userPortalViewService.userByProviderIdExternal(Urlparm).then(function (response) {
                    if (response.Status) {
                        if (!!response.Users) {
                            sessionStorage.ProviderName = response.Users.ProviderName;
                            $scope.viewConfig.isUserPortalViewDisabled = false;
                            $scope.currentUserData = response.Users;
                        } else {
                            $scope.currentUserData = {};
                            $scope.viewConfig.isUserPortalViewDisabled = true;
                        }
                    } else {
                        $scope.currentUserData = {};
                        $scope.viewConfig.isUserPortalViewDisabled = true;
                    }
                    $scope.viewConfig.isUserPortalViewLoading = false;
                }, function (error) {
                    $scope.currentUserData = {};
                    $scope.viewConfig.isUserPortalViewDisabled = true;
                    $scope.viewConfig.isUserPortalViewLoading = false;
                    showStatusMessage(error, "error");
                });
            }
        });

        init();
    }
})();