(function () {
    'use strict'

    angular.module('app.enforcement')
    .factory('ComplaintOnlineSubmissionService', ['$http', '$q', 'WebApiUrl', 'utilityService', ComplaintOnlineSubmissionService]);

    function ComplaintOnlineSubmissionService($http, $q, WebApiUrl, utilityService) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _saveComplaint = function (complaint) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/SaveComplaintFromPublic/public/', complaint));
        };

        var _saveComplaintInvolvedParty = function (party) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/SaveComplaintInvolvedPartyFromPublic/public/', party));
        };

        var _uploadDocuments = function (documents) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/UploadComplaintDocumentsFromPublic/public/', documents));
        };

        var _downloadDocumentFromPublic = function (key, complaintDocumentId, complaintDocumentGuid) {
            var urlParams = {
                ComplaintDocumentId: complaintDocumentId,
                ComplaintDocumentGuid: complaintDocumentGuid
            };

            var httpPromise = $http.get(WebApiUrl + 'Complaint/DownloadComplaintDocumentFromPublic/public/', { params: urlParams, responseType: 'arraybuffer' });
            return responseHandler(httpPromise, function(res) {
                var headers = res.headers();
                if (res.status == 200) {
                    utilityService.downloadExcelFile(res.data, headers);
                }
            }, function (err) {
                
            });
        };

        var _saveComplaintAffidavits = function (affidavits) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/SaveComplaintAffidavitsFromPublic/public/', affidavits));
        };

        var _getHtml = function (topLevelElement, addlCss) {
            var deferred = $q.defer();

            try {
                var pdfHtml = angular.element(topLevelElement).html();
                var exportHtml = angular.element('<div></div>');
                var stylesToImport = ['app/content/css/bootstrap.min.css',
                    'app/content/css/font-awesome.min.css',
                    'app/content/css/backoffice/Admin.css',
                    'app/content/css/backoffice/button.css',
                    'app/content/css/editor.css',
                    'app/content/css/SearchPanelnew.css'
                ];
                if (!!addlCss) {
                    stylesToImport = stylesToImport.concat(addlCss);
                }
                var getCssPromises = [];
                angular.forEach(stylesToImport, function (styleToImport, key) {
                    getCssPromises.push($http.get(styleToImport));
                });
                $q.all(getCssPromises)
                .then(function (response) {
                    angular.forEach(response, function (styleToImportData, key) {
                        angular.element(exportHtml).append('<style>' + styleToImportData.data + '</style>');
                    });
                    angular.element(document).find('style').each(function (index) {
                        angular.element(exportHtml).append('<style>' + angular.element(this).html() + '</style>');
                    });
                    if (pdfHtml) {
                        pdfHtml = pdfHtml.replace(/\<textarea /g, '<div class="textAreaReplaced"><div ').replace(/\<\/textarea>/g, '</div></div>');
                        angular.element(exportHtml).append(pdfHtml);
                    }
                    var panelPayment = angular.element(exportHtml).find('#pnlPayment')[0];
                    if (!!panelPayment)
                        angular.element(panelPayment).remove();
                    var textAreaElements = angular.element(exportHtml).find('.textAreaReplaced');
                    angular.forEach(textAreaElements, function (textAreaElement, index) {
                        if (!!textAreaElement.firstChild && !!textAreaElement.firstChild.attributes && !!textAreaElement.firstChild.attributes.value && !!textAreaElement.firstChild.attributes.value.value)
                            textAreaElement.innerHTML = "<pre>" + textAreaElement.firstChild.attributes.value.value + "</pre>";
                    });
                    var pdfString = "<html><body class='pdf'>" + angular.element(exportHtml).html() + "</body></html>";
                    deferred.resolve(pdfString);
                }, function (data) {
                    console.log('some error occurred' + data);
                    deferred.reject(data);
                });
            } catch(ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        var _convertHtmlToPdf = function (html, marginX, marginY) {
            var request = {
                Html: html,
                MarginX: marginX,
                MarginY: marginY
            };
            return responseHandler($http.post(WebApiUrl + 'Entity/ConvertHtmlToPDF/public/', request));
        };

        var _convertHtmlAndDownloadPdf = function (html, marginX, marginY, fileName) {
            var request = {
                FileName: fileName,
                Html: html,
                MarginX: marginX,
                MarginY: marginY
            };

            var httpPromise = $http.post(WebApiUrl + 'Entity/ConvertHtmlAndDownloadPDF/public/', request, { responseType: 'arraybuffer' });
            return responseHandler(httpPromise, function(res) {
                var headers = res.headers();
                if (res.status == 200) {
                    utilityService.downloadExcelFile(res.data, headers);
                }
            }, function (err) {
                
            });
        };

        return {
            saveComplaint: _saveComplaint,
            saveComplaintInvolvedParty: _saveComplaintInvolvedParty,
            uploadDocuments: _uploadDocuments,
            downloadDocumentFromPublic: _downloadDocumentFromPublic,
            saveComplaintAffidavits: _saveComplaintAffidavits,
            getHtml: _getHtml,
            convertHtmlToPdf: _convertHtmlToPdf,
            convertHtmlAndDownloadPdf: _convertHtmlAndDownloadPdf
        };
    }
})();