(function () {
    'use strict'

    angular.module('app.core')
    .factory('individualLicenseService', ['$http', '$q', 'WebApiUrl', 'AppConfig', individualLicenseService]);

    function individualLicenseService($http, $q, WebApiUrl, AppConfig) {
        var _individualLicenseDetailBYIndividualId = function(Key, IndividualId) {
            return responseHandler($http.get(WebApiUrl + 'Individual/IndividualLicenseDetailBYIndividualId/' + Key + '?IndividualId=' + IndividualId));
        };
        var _licenseSave = function(individualLicense, key) {
            return responseHandler($http.post(WebApiUrl + 'Individual/IndividualLicenseSave/' + key +'?Source=' + AppConfig.Source, individualLicense));
        };

        var _individualEducationBYIndividualLicenseId = function(Key, IndividualLicenseId) {
            return responseHandler($http.get(WebApiUrl + 'Individual/IndividualEducationBYIndividualLicenseId/' + Key + '?IndividualLicenseId=' + IndividualLicenseId));
        };
        var _generateApplicationId = function(Key, ApplicationId, UserId, applicationObj) {
            return responseHandler($http.post(WebApiUrl + 'Application/GenerateApplicationId/' + Key + '?ApplicationId=' + ApplicationId + "&UserId=" + UserId, applicationObj));
        };
        var _licenseConversion = function(individualLicense, key) {
            return responseHandler($http.post(WebApiUrl + 'Individual/IndividualLicenseConversion/' + key, individualLicense));
        };
        var _saveIndividualLicenseDiscipline = function (individualLicenseDiscipline, key) {
            return responseHandler($http.post(WebApiUrl + 'Individual/SaveIndividualLicenseDiscipline/' + key + '?Source=' + AppConfig.Source, individualLicenseDiscipline))
                
        };
        var _getIndividualLicenseDiscipline = function (individualId, applicationId, key) {
            return responseHandler($http.get(WebApiUrl + 'Individual/GetIndividualLicenseDiscipline/' + key + '?IndividualId=' + individualId + '&ApplicationId=' + applicationId))   
        };
        var _getRefLicenseDisciplineTypeGetAll = function (individualId, key) {
            return responseHandler($http.get(WebApiUrl + 'TypeValues/RefLicenseDisciplineTypeGet/' + key + '?IndividualId=' + individualId))
        };
        var _licenseTypeGetAll = function (key) {
            return responseHandler($http.get(WebApiUrl + 'TypeValues/LicenseTypeGetAll/' + key))
        };
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };


        return {
            individualLicenseDetailBYIndividualId: _individualLicenseDetailBYIndividualId,
            licenseSave: _licenseSave,
            individualEducationBYIndividualLicenseId: _individualEducationBYIndividualLicenseId,
            generateApplicationId: _generateApplicationId,
            licenseConversion: _licenseConversion,
            saveIndividualLicenseDiscipline: _saveIndividualLicenseDiscipline,
            getIndividualLicenseDiscipline: _getIndividualLicenseDiscipline,
            getRefLicenseDisciplineTypeGetAll: _getRefLicenseDisciplineTypeGetAll,
            licenseTypeGetAll: _licenseTypeGetAll
        };
    }
})();