﻿/// <reference path="deficiency-reason-directive-controller.js" />
(function () {
    'use strict'
    angular.module('app.backofficeAdministor')
        .controller('ConfigConfigtypeDirectiveController', ['$scope', 'ConfigConfigtypeDirectiveFactory', function ($scope, ConfigConfigtypeDirectiveFactory) {
            var _Key = sessionStorage.Key;
            $scope.init = function () {
                $scope.currentPage = 1;
                $scope.totalItems = 0;
                $scope.itemsPerPage = 25;
                $scope.maxSize = 6;
                $scope.fromRow = 0;
                $scope.SearchObject = {};
                getConfigurationList();
            };

            $scope.UpdateConfig = function () {
                if ($scope.ItemtoEdit && $scope.ItemtoEdit.Value) {
                    $scope.SettingValueRequired = undefined;
                    saveConfiguration();
                } else {
                    $scope.SettingValueRequired = "Setting value is required."
                }
            };


            $scope.reloadConfiguration = function () {
                ShowLoader();
                ConfigConfigtypeDirectiveFactory.reloadConfiguration().then(function (res) {
                    HideLoader();
                }, function () {
                    HideLoader();
                });
            };

            var getConfigurationList = function () {
                $scope.ConfigurationLiat = [];
                ShowLoader();
                var reqdata = $scope.SearchObject;
                reqdata.Key = sessionStorage.Key;
                reqdata.FromRow = $scope.fromRow;
                reqdata.ItemsPerPage = $scope.itemsPerPage;
                ConfigConfigtypeDirectiveFactory.ConfigurationSearch(_Key, reqdata).then(function (res) {
                    if (res.data.Status) {
                        $scope.totalItems = res.data.TotalCount;
                        $scope.ConfigurationLiat = res.data.Configuration;
                    }
                    HideLoader();
                }, function (res) {
                    HideLoader();
                });
            };

            var saveConfiguration = function () {
                ShowLoader();
                if ($scope.ItemtoEdit) {
                    ConfigConfigtypeDirectiveFactory.ConfigurationSave(_Key, $scope.ItemtoEdit).then(function (res) {
                        if (res.data.Status) {
                            getConfigurationList();
                        } else {
                            HideLoader();
                        }
                    }, function () {
                        HideLoader();
                    });
                }
            };
            $scope.ItemtoEdit = {};
            $scope.EditConfig = function (item) {
                //make all item display edit false
                $scope.ConfigurationLiat = $scope.ConfigurationLiat.map(function (i) {
                    i.DisplayEdit = false;
                    return i;
                });
                $scope.ItemtoEdit = angular.copy(item);
                item.DisplayEdit = true;
            };

            //call api to get recertification list using filter
            $scope.btnSearchClisked = function () {
                //make pageIndex 0
                $scope.currentPage = 1;
                $scope.fromRow = 0;
                getConfigurationList();
            };

            $scope.ClearSearch = function () {
                $scope.SearchObject = {};
                $scope.currentPage = 1;
                $scope.fromRow = 0;
                getConfigurationList();
            };

            $scope.pageChanged = function () {
                $scope.fromRow = ($scope.currentPage * $scope.itemsPerPage) - $scope.itemsPerPage;
                getConfigurationList()
            };
        }]);
})();