(function () {
    'use strict'

    angular.module('app.core')
    .factory('ChildEventHandlerFactory', ['$parse', '$q', '$interval', ChildEventHandlerFactory])
    .directive('childEventHandler', ['ChildEventHandlerFactory', ChildEventHandlerDirective]);

    function ChildEventHandlerFactory($parse, $q, $interval) {
        var _setupChildEvents = function ($scope, attrs) {
            //Check for the attributes that would define event handlers.

            //If any, parse them using the $parse service.
            //When the "event" functions are called, a context ($scope) is passed in.
            
            //This is very similar to how angular handles '&' scope variables in directives.

            //The reason we're doing it this way (instead of defining scope: {...} or just adding them to the parent $scope directly) 
            //is because we don't want to modify the $scope of the parent controller too much, but we still want to access it easily 
            //since this directive needs to broadcast events down to its children and intercept events sent up from its children.
            
            //The $scope from which $broadcast and $emit are called drive the controllers that receive the event. If we defined our own 
            //scope, we would only emit to the controller in which this directive was defined, and we would broadcast to nobody (because
            //there would be no children).

            //So, for these reasons, we $parse the attributes and pass in the $scope manually when the events are run.
            var saveInformationDefaultEvent = function () {};
            var saveInformationEvent = saveInformationDefaultEvent;
            var defaultSaveInformation = true;
            if (!!attrs.saveInformation) {
                defaultSaveInformation = false;
                saveInformationEvent = $parse(attrs.saveInformation);
            }
            var loadInformationDefaultEvent = function () {};
            var loadInformationEvent = loadInformationDefaultEvent;
            var defaultLoadInformation = true;
            if (!!attrs.loadInformation) {
                defaultLoadInformation = false;
                loadInformationEvent = $parse(attrs.loadInformation);
            }
            var validateChildrenDefaultEvent = function () {};
            var validateChildrenEvent = validateChildrenDefaultEvent;
            var defaultChildValidated = true;
            if (!!attrs.validateChildren) {
                defaultChildValidated = false;
                validateChildrenEvent = $parse(attrs.validateChildren);
            }

            var waitCompleteDefaultEvent = function () {};
            var waitCompleteEvent = waitCompleteDefaultEvent;
            var defaultWaitComplete = true;
            if (!!attrs.waitComplete) {
                defaultWaitComplete = false;
                waitCompleteEvent = $parse(attrs.waitComplete);
            }
            var waitCanceledDefaultEvent = function () {};
            var waitCanceledEvent = waitCanceledDefaultEvent;
            var defaultWaitCanceled = true;
            if (!!attrs.waitCanceled) {
                defaultWaitCanceled = false;
                waitCanceledEvent = $parse(attrs.waitCanceled);
            }
            
            var wait = {};
            var cancelDestroy = false;
            var waitingOnResponse = false;
            var errorMessages = [];

            //Method that polls for the latest status from the children. 
            //If they are done, then the waitComplete event is fired
            //Otherwise, the waitCanceled event is fired
            $scope.waitForChildren = function (usingPromise) {
                var deferred = $q.defer();

                if (usingPromise) {
                    defaultWaitComplete = false;
                    defaultWaitCanceled = false;
                } else {
                    defaultWaitComplete = waitCompleteEvent == waitCompleteDefaultEvent;
                    defaultWaitCanceled = waitCanceledEvent == waitCanceledDefaultEvent;
                }

                var intervalPromise = $interval(function () {
                    var okToDestroy = true;

                    if(!cancelDestroy) {
                        //Go through all the children and check for all the children
                        //that are still being waiting on
                        for(var child in wait) {
                            if(wait.hasOwnProperty(child) && wait[child]) {
                                okToDestroy = false;
                            }
                        }
                        
                        //Check if all the children are done
                        if (okToDestroy) {
                            //Cancel the interval
                            $interval.cancel(intervalPromise);

                            //Send the complete event
                            deferred.resolve({ Status: true, StatusCode: '00', Message: '' });
                            waitCompleteEvent($scope);
                            waitingOnResponse = false;
                        }
                    } else {
                        //Cancel the destroy and the interval

                        //Go through all the children and check for all the children
                        //that are still being waiting on
                        for(var child in wait) {
                            if(wait.hasOwnProperty(child) && wait[child]) {
                                okToDestroy = false;
                            }
                        }

                        if (okToDestroy) {
                            //If a child of this controller is canceled, 
                            //then the parent of this controller must be canceled as well.
                            $interval.cancel(intervalPromise);

                            //Call the cancellation event
                            deferred.reject(angular.copy(errorMessages));
                            waitCanceledEvent($scope);
                            waitingOnResponse = false;
                        }                        
                    }
                }, 100);   

                return deferred.promise;
            };

            //Events available to parents to broadcast down to their children
            var initBegin = function () {
                waitingOnResponse = true;
                cancelDestroy = false;
                errorMessages = [];
                wait = {};
            };

            $scope.beginSaveInformation = function () {
                initBegin();
                $scope.$broadcast('SaveInformation');
            };
            $scope.beginLoadInformation = function () {
                initBegin();
                $scope.$broadcast('LoadInformation');
            };
            $scope.beginValidateChildren = function () {
                initBegin();
                $scope.$broadcast('ValidateChildren');
            };

            //Broadcasted down event handlers
            //(cannot stopPropagation on a broadcasted event)
            $scope.$on('SaveInformation', function(event) {
                //Prevent the scope from handling its own broadcasted event
                if ($scope != event.targetScope) {
                    saveInformationEvent($scope);
                }
            });
            $scope.$on('LoadInformation', function(event) {
                //Prevent the scope from handling its own broadcasted event
                if ($scope != event.targetScope) {
                    loadInformationEvent($scope);
                }
            });
            $scope.$on('ValidateChildren', function(event) {
                //Prevent the scope from handling its own broadcasted event
                if ($scope != event.targetScope) {
                    validateChildrenEvent($scope);
                }
            });


            //Events available to children to be emitted to the parent controller
            $scope.sendWait = function (id) {
                $scope.$emit('Wait', id);
            };
            $scope.sendComplete = function (id) {
                $scope.$emit('OperationComplete', id);
            };
            $scope.sendCancel = function (id, err) {
                $scope.$emit('CancelOperation', {id: id, err: err});
            };

            //Emitted up event handlers
            //(stopPropagation is called at the first handler)
            $scope.$on('Wait', function (event, id) {
                //Prevent the scope from handling its own emitted event
                if (waitingOnResponse && $scope != event.targetScope) {
                    wait[id] = true;

                    //Stop at the first handling of the event
                    event.stopPropagation();
                }
            });
            $scope.$on('OperationComplete', function (event, id) {
                //Prevent the scope from handling its own emitted event
                if (waitingOnResponse && $scope != event.targetScope) {
                    wait[id] = false;

                    //Stop at the first handling of the event
                    event.stopPropagation();
                }
            });
            $scope.$on('CancelOperation', function(event, params) {
                //Prevent the scope from handling its own emitted event
                if (waitingOnResponse && $scope != event.targetScope) {
                    wait[params.id] = false;
                    cancelDestroy = true;
                    if (!!params.err) {
                        if (Array.isArray(params.err)) {
                            errorMessages = errorMessages.concat(params.err);
                        } else {
                            errorMessages.push(params.err);
                        }
                    }

                    //Stop at the first handling of the event
                    event.stopPropagation();
                }
            });
        };

        return {
            setupChildEvents: _setupChildEvents
        };
    }

    function ChildEventHandlerDirective(ChildEventHandlerFactory) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                ChildEventHandlerFactory.setupChildEvents($scope, attrs);
            }
        };
    }
})();