﻿(function () {
    'use strict';
    /**
     *  
     */
    angular.module('profile.manager')
    .controller('profileMgmtController', ['$scope', '$state', 'typeValuesService', 'TypeValue', 'profileManagemetFactory','Messages',
        function ($scope, $state, typeValuesService, TypeValue, profileManagemetFactory, Messages) {
            $scope.showSearchSection = true;
            $scope.profile = {};
            $scope.natValidation = {};
            $scope.profileList = [];
            var _key = sessionStorage.Key;
            var _userId = sessionStorage.UserID;
            $scope.datePicker = {
                fromDateOpened: false,
                toDateOpened: false,
                format: 'M!/d!/yyyy'
            };

            $scope.datePicker2 = {
                fromDateOpened: false,
                toDateOpened: false,
                format: 'M!/d!/yyyy'
            };

            $scope.init = function () {
                $scope.search = {};
                $scope.fromRow = 0;
                $scope.currentPage = 1;
                $scope.itemsPerPage = 10;
                $scope.totalItems = 0;
                getProfileList();
                userTypeGetAll();
            };

            $scope.showHideSearch = function () {
                $scope.showSearchSection = !$scope.showSearchSection;
            };

            $scope.cancelButtonClick = function () {
                $scope.profile = {};
                $scope.showAddNewSection = false;
            }

            $scope.addNewProfileClicked = function () {
                $scope.showAddNewSection = !$scope.showAddNewSection;
                if ($scope.showAddNewSection) {
                    $scope.showSearchSection = false;
                }
            };

            $scope.saveProfile = function (form) {
                try {
                    if (validateForm(form) && $scope.profile) {
                        ShowLoader("Saving...");
                        //if new profile
                        if (!$scope.profile.ProfileId)
                        {
                            $scope.profile.IsActive = true;
                            $scope.profile.IsDeleted = false;
                            $scope.profile.ProfileTypeId = 1;
                            $scope.profile.IsEnabled = true;
                            $scope.profile.IsEditable = true;
                            $scope.profile.CreatedBy = _userId;
                        }
                        ////else edit profile
                        else{
                            //$scope.profile
                            $scope.profile.ModifiedBy = _userId;
                        }
                        profileManagemetFactory.createProfile(_key, $scope.profile).then(function (res) {
                            if (res.Status) {
                                showStatusMessage(Messages.efefcae, "success");
                                $scope.cancelButtonClick();
                                $scope.searchbtnClicked();
                            } else {
                                showStatusMessage(res.Message, "error");
                                HideLoader();
                            }
                        }, function (err) {
                            HideLoader();
                            showStatusMessage(err.Message, "error");
                        });
                    }
                }
                catch (ex) {
                    HideLoader();
                    showStatusMessage(ex.message, "error");
                }
            };

            $scope.editProfileClicked = function (item) {
                $scope.isProfileEdit= true;
                ShowLoader("Please Wait...");
                $scope.profile = angular.copy(item);
                $scope.showAddNewSection = true;
                if ($scope.showAddNewSection) {
                    $scope.showSearchSection = false;
                }
                HideLoader();
            };

            $scope.deleteProfileById = function (item) {
                if (item && confirm(Messages.General.Confirm.Delete)) {
                    try {
                        var deleteType = 'profile';
                        var profileId = item.ProfileId;
                        var userProfileId = '';
                        ShowLoader("Please Wait...");
                        profileManagemetFactory.deleteProfileUserProfile(_key, deleteType, profileId, userProfileId).then(function (res) {
                            if (res.Status) {
                                showStatusMessage(Messages.General.Success.Delete, "success");
                                $scope.searchbtnClicked();
                            } else {
                                showStatusMessage(res.Message, "error");
                                HideLoader();
                            }
                        }, function (err) {
                            HideLoader();
                            showStatusMessage(err.Message, "error");
                        });

                    }
                    catch (ex) {
                        HideLoader();
                        showStatusMessage(ex.message, "error");
                    }
                }
            };

            $scope.assignProfileToUser = function (item) {
                if (item) {
                    item.showAssignProfile = !item.showAssignProfile;
                }
            };

            $scope.searchbtnClicked = function () {
                $scope.fromRow = 0;
                $scope.currentPage = 1;
                getProfileList();
            };

            $scope.clearSearch = function () {
                $scope.search = {};
                $scope.fromRow = 0;
                $scope.currentPage = 1;
                getProfileList();
            };

            $scope.sortpropertyName = 'CreatedOn';
            $scope.reverse = true;
            $scope.sortBy = function (propertyName) {
                $scope.reverse = ($scope.sortpropertyName === propertyName) ? !$scope.reverse : false;
                $scope.sortpropertyName = propertyName;
                getProfileList();
            };

            $scope.pageChanged = function () {
                $scope.fromRow = ($scope.currentPage * $scope.itemsPerPage) - $scope.itemsPerPage;
                getProfileList();
            };

            var getProfileList = function () {
                try {

                    ShowLoader("Please Wait...");
                    var reqdata = $scope.search;
                    if (!reqdata)
                        reqdata = {};
                    reqdata.FromRow = $scope.fromRow;
                    reqdata.RecordPerPage = $scope.itemsPerPage;
                    reqdata.OrderByField = $scope.sortpropertyName;
                    reqdata.Order = $scope.reverse;

                    profileManagemetFactory.getProfileList(_key, reqdata).then(function (res) {
                        if (res.Status) {
                            $scope.profileList = res.ProfileList;
                            $scope.totalItems = res.RecordCount;
                        } else {
                            showStatusMessage(res.Message, "error");
                        }
                        HideLoader();
                    }, function (err) {
                        HideLoader();
                        showStatusMessage(err.Message, "error");
                    });

                }
                catch (ex) {
                    HideLoader();
                    showStatusMessage(ex.message, "error");
                }
            };

            //User Profile Related codes
            var userTypeGetAll = function () {
                try {
                    profileManagemetFactory.userTypeGetAll(_key).then(function (res) {
                        if (res.Status) {
                            $scope.userTypeList = res.UserType;
                        } else {
                            showStatusMessage(res.Message, "error");
                        }
                    }, function (err) {
                        showStatusMessage(err.Message, "error");
                    });
                }
                catch (ex) {
                    HideLoader();
                    showStatusMessage(ex.message, "error");
                }
            };

            $scope.onCloseUserProfile = function (profile) {
                console.log('pft');
                if (profile) {
                    profile.showAssignProfile = false;
                }
            };

            //
            var validateForm = function (form) {
                $scope.natValidation.errorMessages = [];
                $scope.invaliddate = false;
                if (!!form) {
                    form.$submitted = true;
                    var veteranErrorMessages = angular.copy(form.errorMessages);
                    angular.forEach(veteranErrorMessages, function (errorMessage, index) {
                        $scope.natValidation.errorMessages.push(errorMessage);
                    });
                }

                if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                    $scope.natValidation.showError = true;
                    $scope.natValidation.isValid = false;
                    return false;
                } else {
                    $scope.natValidation.showError = false;
                    $scope.natValidation.isValid = true;
                    return true;
                }
            };
        }]);
})();