(function() {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('initialApplicationNcessService', initialApplicationNcessService);

    initialApplicationNcessService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function initialApplicationNcessService($http, $q, WebApiUrl, AppConfig) {

        var _getIndividualLicenseRecordBook = function(individualId, aplicationId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/GetIndividualLicenseRecordBook/' + key + '?IndividualId=' + individualId + '&ApplicationId=' + aplicationId)
                .then(function(data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function(error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveIndividualLicenseRecordBook = function(individualLicenseRecordBook, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/SaveIndividualLicenseRecordBook/' + key + '?Source=' + AppConfig.Source, individualLicenseRecordBook)
                .then(function(data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function(error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _getEntityConfirmation = function(EntityId, EntityType, applicationId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Entity/EntityConfirmation_Get/' + key + '?EntityId=' + EntityId + '&EntityType=' + EntityType + '&Source=' + AppConfig.Source + '&applicationId=' + applicationId)
                .then(function(data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function(error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };


        var _saveEntityConfirmation = function(data, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Entity/EntityConfirmation_Save/' + key + '?Source=' + AppConfig.Source, data)
                .then(function(data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function(error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            getIndividualLicenseRecordBook: _getIndividualLicenseRecordBook,
            saveIndividualLicenseRecordBook: _saveIndividualLicenseRecordBook,
            getEntityConfirmation: _getEntityConfirmation,
            saveEntityConfirmation: _saveEntityConfirmation
        };
    }
})();