(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('examVerification', initialExamVerificationDirective);

    initialExamVerificationDirective.$inject = [];

    function initialExamVerificationDirective() {
        return {
            restrict: 'E',
            scope: {
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                natValidation: "=natValidation",
                individualId: '=individualId',
                applicationId: '=applicationId',
                isBackoffice: "=isBackoffice",
                applicationInfo: "=applicationInfo",
                userId: "=userId",
                tab: "=tab",
                openNextTab: "&",
                isRenewal: "=?isRenewal",
                applicationSubTypeId:"=?applicationSubTypeId",
                feeDetails: "=feeDetails",
                isIndividualInfo: "=?isIndividualInfo"
            },
            templateUrl: "app/components/individual/application/directives/exam-verification/exam-verification.html",
            controller: "ExamVerificationController"
        }
    }
})();
