(function() {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationLegalInfo', initialApplicationLegalInfoDirective);

    initialApplicationLegalInfoDirective.$inject = [];

    function initialApplicationLegalInfoDirective() {
        return {
            restrict: 'E',
            scope: {
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                natValidation: "=natValidation",
                legalInfo: "=?legalInfo",
                individualId: '=individualId',
                applicationId: '=applicationId',
                userId: '=userId',
                isRenewal: '=?isRenewal',
                loadData: '=?loadData',
                applicationInfo: "=?applicationInfo",
                individualLicense: "=?individualLicense",
                showStatusMessage: "&",
                useType: "=?useType",
                tab: "=tab",
                openNextTab: "&",
                isBackoffice: "=isBackoffice",
                feeDetails: "=feeDetails"
            },
            templateUrl: "app/components/individual/application/directives/legal-info/legal-info.html",
            controller: "InitialApplicationLegalInfoController"
        }
    }
})();