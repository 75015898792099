(function() {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('applicationTypeSelection', [ApplicationTypeSelectionDirective]);

    function ApplicationTypeSelectionDirective() {
        return {
            restrict: 'E',
            scope: {
                individual: '=',
                applicationTypeSelected: '&'
            },
            link: function ($scope, element, attrs) {
                
            },
            templateUrl: 'app/components/backoffice/application/application-type-selection/application-type-selection.html',
            controller: 'ApplicationTypeSelectionController'
        };
    }
})();