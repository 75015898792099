(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintCorrespondenceLogic', ['$q', 'ComplaintOnlineSubmissionService', ComplaintCorrespondenceLogic]);

    function ComplaintCorrespondenceLogic($q, ComplaintOnlineSubmissionService) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.getHtml = function (selector) {
                    var deferred = $q.defer();

                    try {
                        ComplaintOnlineSubmissionService.getHtml(selector, [
                            'app/content/css/Header.css',
                            'app/core/directive/inlumon-table/inlumon-table-template.css',
                            'app/core/directive/utility-directives/utility.css',
                            'app/core/directive/multi-select/multi-select.css',
                            'app/core/directive/date-time-picker/date-time-picker.css',
                            'app/components/backoffice/enforcement/complaint-online-submission/complaint-online-submission.css'
                        ])
                            .then(function (data) {
                                console.log(data);
                                deferred.resolve(data);
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.convertHtmlAndDownloadPdf = function (html, fileName) {
                    var deferred = $q.defer();

                    try {
                        ComplaintOnlineSubmissionService.convertHtmlAndDownloadPdf(html, 0.75, 0.75, fileName)
                            .then(function (data) {
                                deferred.resolve(data);
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.downloadAsPdf = function (correspondence) {
                    var selector = '#email{0}'.format(correspondence.ComplaintCorrespondenceId);
                    var spaceRegEx = /\s/gi;
                    var fileName = '{0}_{1}'.format(correspondence.Subject.replace(spaceRegEx, '_'), correspondence.ComplaintCorrespondenceId);

                    $scope.getHtml(selector)
                    .then(function (html) {
                        $scope.convertHtmlAndDownloadPdf(html, fileName);
                    });
                };
            },
        };
    }
})();