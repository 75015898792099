(function () {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("IndividualInfoController", IndividualInfoController);

    IndividualInfoController.$inject = ["$rootScope", "$scope", "$state", "individualInfoService",
        "utilityService", "$uibModal", "$timeout", "Messages"
    ];

    function IndividualInfoController($rootScope, $scope, $state, individualInfoService,
        utilityService, $uibModal, $timeout, Messages) {
        $scope.routeData = $state.current.routeData;
        $scope.configName = $scope.routeData.configName;
        $scope.isBackoffice = sessionStorage.isBackOfficeUser == 'true' || sessionStorage.isBackOfficeUser == true;

        function auditVisitInfoSave(tab) {
            var data = {
                PageName: ("IndividualInfo" + tab.text),
                SessionId: sessionStorage.Key,
                IndividualId: $scope.individualId,
                EntityId: tab.tabNumber,
                UserId: $scope.userId,
                Username: "",
                RequestUrlReferrer: document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };

        $scope.resultAreaIndividualInfo = JSON.parse(localStorage.getItem('resultAreaIndividualInfo'));

        var setTabFinalize = function (tab) {
            $rootScope.individualCurrentTab = tab;
            $rootScope.entityCurrentTab = tab;
            $scope.$broadcast('individualTabChanged', $scope.individualId);
            $scope.$broadcast('entityTabChanged', $scope.individualId);
            auditVisitInfoSave(tab);
        };

        $scope.setCurrentTab = function (tab) {
            if (tab.loadAfterTimeOut) {
                $rootScope.individualCurrentTab = null;
                $rootScope.entityCurrentTab = null;
                $timeout(function () {
                    setTabFinalize(tab);
                });
            } else {
                setTabFinalize(tab);
            }
        };

        $scope.printPage = function () {
            window.print();
        };

        var processTabs = function () {
            $scope.validator = {};
            $scope.individualViewConfig.tabsConfig.tabs = $scope.individualViewConfig.tabsConfig.tabs.orderBy('tabOrder');
            angular.forEach($scope.individualViewConfig.tabsConfig.tabs, function (tab) {
                tab.isLoadedOnce = false;
                tab.icon = ((!!tab.icon) ? tab.icon : $scope.individualViewConfig.tabsConfig.defaultIcon);
                tab.loadAfterTimeOut = (angular.isUndefined(tab.loadAfterTimeOut) ? $scope.individualViewConfig.tabsConfig.loadAfterTimeOut : tab.loadAfterTimeOut);
                tab.collapsedInitially = (angular.isUndefined(tab.collapsedInitially) ? $scope.individualViewConfig.tabsConfig.collapsedInitially : tab.collapsedInitially);
                tab.hideReloadIcon = (angular.isUndefined(tab.hideReloadIcon) ? $scope.individualViewConfig.tabsConfig.hideReloadIcon : tab.hideReloadIcon);
                tab.hideTabHeaderIcon = (angular.isUndefined(tab.hideTabHeaderIcon) ? $scope.individualViewConfig.tabsConfig.hideTabHeaderIcon : tab.hideTabHeaderIcon);
                tab.hideTabHeaderText = (angular.isUndefined(tab.hideTabHeaderText) ? $scope.individualViewConfig.tabsConfig.hideTabHeaderText : tab.hideTabHeaderText);
                tab.visibilityCondition = (!!tab.visibilityCondition) ? tab.visibilityCondition : 'if';
                $scope.validator[tab.name] = {};
            });
        };

        $scope.showSearchPanel = function () {
            $scope.individualViewConfig.showSearchPanel = true;
        };

        $scope.hideSearchPanel = function () {
            $scope.individualViewConfig.showSearchPanel = false;
        };

        $scope.onSearchCancel = function () {
            if (!$scope.individualViewConfig.IsInitialSearch)
                $scope.hideSearchPanel();
        };

        $scope.onSearchComplete = function () {
            $scope.hideSearchPanel();
            $scope.initializeSearch();
        };

        $scope.hideSearchResultsPanel = function () {
            $scope.individualViewConfig.showSearchResultsPanel = false;
        };

        $scope.showSearchResultsPanel = function () {
            $scope.individualViewConfig.showSearchResultsPanel = true;
        };

        $scope.initializeSearch = function () {
            $scope.individualViewConfig.IsInitialSearch = true;
            $scope.individualSearchContent = individualInfoService.getIndividualSearchContent($scope.routeData.configName);
            if (!$scope.individualSearchContent || !$scope.individualSearchContent.Total_Recard) {
                $scope.showSearchPanel();
                $scope.hideSearchResultsPanel();
            } else {
                $scope.showSearchResultsPanel();
                $scope.individualSearchWithPage();
            }
        };

        var getUIConfig = function () {
            var config = {};

            for (var prop in $scope.config.UIConfigs['Default']) {
                if (!$scope.config.UIConfigs['Default'].hasOwnProperty(prop)) { continue; }

                config[prop] = $scope.config.UIConfigs['Default'][prop];
            }

            if (!!$scope.config.UIConfigs[$scope.configName]) {
                for (var prop in $scope.config.UIConfigs[$scope.configName]) {
                    if (!$scope.config.UIConfigs[$scope.configName].hasOwnProperty(prop)) { continue; }

                    config[prop] = $scope.config.UIConfigs[$scope.configName][prop];
                }
            }

            return config;
        };

        var getTabsParentMenu = function (menuList, menuName) {
            var parentMenu = null;
            for (var i = 0; i < menuList.length; i++) {
                var menuItem = menuList[i];
                if (menuItem.Name == menuName) {
                    parentMenu = menuItem.MenuList;
                    break;
                };
                if (!!menuItem.MenuList && menuItem.MenuList.length > 0) {
                    getTabsParentMenu(menuItem.MenuList, menuName);
                }
            }
            return parentMenu;
        };

        var processRoleInfo = function () {
            var tabsMenuList = null;
            if (sessionStorage.MenuList != undefined) {
                var menuList = JSON.parse(sessionStorage.MenuList);
                tabsMenuList = getTabsParentMenu(menuList, $scope.individualViewConfig.tabsConfig.roleBasedPermission.parentMenu);
            }
            if (!!tabsMenuList) {
                angular.forEach($scope.individualViewConfig.tabsConfig.tabs, function (tabItem) {
                    var tabMenuItem = tabsMenuList.whereEquals('Tab', 'MenuType').whereEquals(tabItem.name, 'MenuCode').firstOrDefault();
                    if (!tabMenuItem) {
                        tabItem.isEnabled = false;
                    } else {
                        tabItem.permissionInfo = tabMenuItem;
                    }
                });
            }
        };

        $scope.init = function () {
            $scope.userId = sessionStorage.UserID;
            $scope.applicationId = "";
            try {
                if ($scope.isUserSessionActive()) {
                    var relativeFilePath = "/components/backoffice/individual/individual.config.json";
                    utilityService.getConfigData(relativeFilePath).then(function (data) {
                        $scope.config = data;
                        $scope.individualViewConfig = getUIConfig();
                        if ($scope.individualViewConfig.tabsConfig.roleBasedPermission && !!$scope.individualViewConfig.tabsConfig.roleBasedPermission.isEnabled) {
                            processRoleInfo();
                        }
                        $scope.resultAreaIndividualInfo = (!!$scope.resultAreaIndividualInfo ? $scope.resultAreaIndividualInfo : $scope.individualViewConfig.resultAreas);
                        $scope.initializeSearch();
                    }, function (error) {
                        showStatusMessage(error.message, "error");
                    })
                } else {
                    $state.go('app.BackofficeLoginstaff');
                }
            } catch (ex) {
                showStatusMessage(ex.message, "error");
            }
        };

        $scope.enableInterfaceEdit = function () {
            $scope.sortableOptions.disabled = false;
            $scope.individualViewConfig.enableInterfaceEdit = true;
        };

        $scope.saveInterface = function () {
            localStorage.setItem('resultAreaIndividualInfo', JSON.stringify($scope.resultAreaIndividualInfo));
            $scope.sortableOptions.disabled = true;
            $scope.individualViewConfig.enableInterfaceEdit = false;
        };

        $scope.sortableOptions = {
            // handle: '> .sorting-handlers',
            start: function (e, ui) {
                $scope.$apply(function () {
                    $scope.individualViewConfig.isSorting = true;
                });
            },
            stop: function (e, ui) {
                $scope.individualViewConfig.isSorting = false;
            },
            axis: 'y',
            disabled: true
        };

        $scope.sortRecords = function (header) {
            $scope.loadingIndividualSearchResults(true, "Loading Individuals..");
            $scope.individualSearchContent.SortColumn = header.sortColumn;
            $scope.individualSearchContent.SortOrder = header.sortOrder;
            header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
            $scope.individualSearchWithPage();
        };

        //For All Tab..
        $scope.displaySearchResult = function (searchData) {
            if (searchData.IndividualId != $scope.individualId) {
                processTabs();
                $scope.individualViewConfig.tabsConfig.showTabSection = true;
                $scope.individualId = searchData.IndividualId;
                $rootScope.individualData = searchData;
                $scope.$broadcast('individualInfoSelectionChanged', $scope.individualId);
                $scope.setCurrentTab($scope.individualViewConfig.tabsConfig.tabs[0]);
                //temp changes
                // $scope.setCurrentTab($scope.individualViewConfig.tabsConfig.tabs[8]);
                if (!!$scope.individualViewConfig.enableAtGlance) {
                    $scope.individualViewConfig.showAtGlance = true;
                } else {
                    $scope.individualViewConfig.showAtGlance = false;
                }
            } else if ($scope.individualViewConfig.enableAtGlance) {
                $scope.individualViewConfig.showAtGlance = !$scope.individualViewConfig.showAtGlance;
            }
        };

        $scope.collapseExpandeTab = function (tab, isCollapsed, $event) {
            tab.isCollapsed = isCollapsed;
            $event.stopPropagation();
            $event.preventDefault();
        };

        $scope.reloadTab = function (tab, $event) {
            // console.log('tab reloaded');
            $event.stopPropagation();
            $event.preventDefault();
        };

        $scope.loadPagedIndividuals = function () {
            $scope.loadingIndividualSearchResults(true, "Loading Individuals..");
            $scope.individualSearchWithPage();
        };

        $scope.templateLoaded = function () {
            $scope.individualViewConfig.templatesLoaded = true;
        };

        $scope.individualSearchWithPage = function () {
            if (!!$scope.individualViewConfig.IsInitialSearch && !!$scope.individualSearchContent.individualSearchResult && $scope.individualSearchContent.individualSearchResult.length > 0) {
                $scope.individualSearchResult = $scope.individualSearchContent.individualSearchResult;
                $scope.individualViewConfig.pager.totalRecords = $scope.individualSearchContent.Total_Recard;
                $scope.individualViewConfig.IsInitialSearch = false;
                $scope.displaySearchResult($scope.individualSearchContent.individualSearchResult[0]);
                return;
            }

            individualInfoService.individualSearchWithPage($scope.individualSearchContent, sessionStorage.Key, $scope.individualViewConfig.pager.currentPage, $scope.individualViewConfig.pager.pageSize)
                .then(function (response) {
                    if ($scope.checkServerResponse(response)) {
                        $scope.individualViewConfig.pager.totalRecords = response.Total_Recard;
                        $scope.individualSearchResult = response.IndividualList;
                        if (response.Total_Recard > 0) {
                            hideStatusMessage();
                            if (response.IndividualList != null) {
                                $scope.displaySearchResult(response.IndividualList[0]);
                            }
                            HideLoader();
                        } else {
                            $scope.showSearchPanel();
                            $scope.hideSearchResultsPanel();
                            showStatusMessage(Messages.General.Errors.NoRecord, "error");
                        }
                        $scope.loadingIndividualSearchResults(false);
                    }
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    if (data != null) {
                        showStatusMessage(data.message, "error");
                    }
                });
        };

        $scope.openCorrespondence = function () {
            $uibModal.open({
                animation: true,
                templateUrl: 'app/components/backoffice/individual/individual-info/partial/correspondence.html',
                size: 'lg',
                resolve: {
                    individualId: $scope.individualId
                },
                controller: ['$scope', '$uibModalInstance', 'individualId', function ($scope, $uibModalInstance, individualId) {
                    $scope.individualId = individualId;
                    $scope.onCloseCorrespondence = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                }]
            });
        };

        $scope.openNotes = function () {
            $uibModal.open({
                animation: true,
                templateUrl: 'app/components/backoffice/individual/individual-info/partial/notes.html',
                size: 'lg',
                resolve: {
                    individualId: $scope.individualId
                },
                controller: ['$scope', '$uibModalInstance', 'individualId', function ($scope, $uibModalInstance, individualId) {
                    $scope.individualId = individualId;
                    $scope.onCloseNotes = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                }]
            });
        };

        $scope.init();
    }
})();
