(function () {
    'use strict'

    angular.module('app.core')
        .factory('ApplicationStatusPickerService', ['$http', '$q', 'WebApiUrl', 'AppConfig', ApplicationStatusPickerService]);

    function ApplicationStatusPickerService($http, $q, WebApiUrl, AppConfig) {
        var applicationStatusPickerService = {
            processLicenseAndApplication: _processLicenseAndApplication,
            getApplicationReview: _getApplicationReview,
            saveApplicationReview: _saveApplicationReview,
            getIndividualLicenseBYIndividualId: _getIndividualLicenseBYIndividualId
        };

        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                console.log('Request failed with status: ' + error.status);
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function _processLicenseAndApplication(key, reqObj) {
            return returnDefferedResult($http.post(WebApiUrl + 'IndividualLicense/ProcessLicenseAndApplication/' + key, reqObj));
        };

        function _getApplicationReview(key, individualId, applicationId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Application/ApplicationReviewGet/' + key + '?ApplicationId=' + applicationId + '&IndividualId=' + individualId + '&ProviderId=&Source=' + AppConfig.Source)) ;
        };

        function _saveApplicationReview(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'Application/SaveApplicationReview/' + key,
                reqData));
        };

        function _getIndividualLicenseBYIndividualId(key, individualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualLicenseBYIndividualId/' + key + '?IndividualId=' + individualId));
        };

        return applicationStatusPickerService;
    }
})();