(function() {
    'use strict'
    angular.module('app.report')
        .directive('licenseChart', licenseChartDirective);

    licenseChartDirective.$inject = [];

    function licenseChartDirective() {
        return {
            restrict: 'E',
            scope: {
                // educationInfo: "=educationInfo",
                // showSubmitPanel: "=showSubmitPanel",
                // showHeader: "=showHeader",
                // headingPosition: "=headingPosition",
                // isApprentice: "=isApprentice",
                // natValidation: "=natValidation",
                // individualId: '=individualId',
                // applicationId: '=applicationId',
                // showStatusMessage: "&",
                // tab: "=tab",
                // openNextTab: "&",
                // isRenewal: "=?isRenewal",
                // isTabStructure: "=?isTabStructure"
            },
            //templateUrl: "app/components/individual/application/directives/education/education.html",
            controller: "licenseChartController"
        }
    }
})();
