//Code Generated by Inlumon Code generation tool
(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .directive('applicationReview', [applicationReviewDirective]);

    applicationReviewDirective.$inject = [];

    function applicationReviewDirective() {
        return {
            restrict: 'E',
            scope: {
                entityId: '=',
                entityType: "=",
                configName: "=",
                applicationId: '=',
                applicationInfo: "=?applicationInfo",
                applicationReviewTypeId: '=',
                isBackoffice: '=?',
                boardInfoId: "=?"
            },
            link: function ($scope, element, attrs) {

            },
            templateUrl: 'app/components/backoffice/entity//application-review/application-review.html',
            controller: 'ApplicationReviewController'
        };
    }
})();