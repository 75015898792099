(function () {
    'use strict';

    angular
      .module('app.InitialApplication')
      .controller("InitialApplicationCertificationController", InitialApplicationCertificationController);

    InitialApplicationCertificationController.$inject = ["$rootScope", "$scope",
      "InitialApplicationCertificationService", "utilityService", "WebApiUrl"];

    function InitialApplicationCertificationController($rootScope, $scope,
      InitialApplicationCertificationService, utilityService, WebApiUrl) {
        $scope.certification = {

        };

        $scope.getCertificationConfig = function () {
            var relativeFilePath = "/individual/application/certification/certification.server-config.json";
            utilityService.getConfigData(relativeFilePath)
              .then(function (data) {
                  $scope.certificationConfig = data;
              }, function (error) {

              });
        };

        $scope.getCertificationConfig();

        $scope.setVisibilityOfAddForm = function (certificationForm, showForm) {
            certificationForm.$setPristine();
            if ($scope.viewConfig == undefined)
                $scope.viewConfig = {};
            if (showForm)
                $scope.certification = {};
            $scope.viewConfig.showAddForm = showForm;
        }


    }
})();
