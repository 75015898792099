﻿(function () {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeProviderPeerController", BackofficeProviderPeerController);

    BackofficeProviderPeerController.$inject = ["$rootScope", "$scope", "$state", "backofficeProviderPeerService", "utilityService", "$exceptionHandler", "WebApiUrl", "ProviderMultipleFileUploadService", 'Messages'];

    function BackofficeProviderPeerController($rootScope, $scope, $state, backofficeProviderPeerService, utilityService, $exceptionHandler, WebApiUrl, ProviderMultipleFileUploadService, Messages) {

        $scope.documentConfig = {
            DocId: 16,
            LinkType: 'PO',
            PropertyToProcess: 'ProviderOtherId'
        };

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, ProviderId) {

            if ($rootScope.individualFirmCurrentTab.name == "compliance" && !$rootScope.individualFirmCurrentTab.isLoadedOnce) {
                $scope.ProviderId = ProviderId;
                $scope.GetProviderPeerByIndividualId($scope.ProviderId);
                $rootScope.individualFirmCurrentTab.isLoadedOnce = true;
                initDocument();
            }
        });

        var initDocument = function () {
            $scope.documentUpload = undefined;
            setTimeout(function () {
                $scope.$apply(function () {
                    $scope.documentUpload = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                        // ApplicationId: _applicationId,
                        ProviderId: $scope.ProviderId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FRM",
                        LoadByDocIdAndDocCode: true,
                        DocId: 16,
                        DocCode: "D1016",
                        LoadDocumentsWithoutBroadCast: true,
                        LinkType: 'PO',
                        PropertyToProcess: 'ProviderOtherId'
                    };
                });
            }, 1000);
        };

        $scope.savePeer = function (peer, PeerForm) {
            ShowLoader("Please Wait...");

            if ($scope.validateData(PeerForm)) {
                peer.ProviderId = $scope.ProviderId;
                peer.isActive = true;
                peer.IsDelete = false;
                backofficeProviderPeerService.savePeer(sessionStorage.Key, [peer])
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.documentUpload.LinkId = response.ProviderOtherList[0].ProviderOtherId;
                            $scope.cancelPeer();
                            if (typeof $scope.saveAllDocument == "function") {
                                $scope.saveAllDocument().then(function () {
                                    $scope.GetProviderPeerByIndividualId($scope.ProviderId);
                                    $scope.$emit('businessNameChanged');
                                    HideLoader();
                                }, function () { });
                            } else {
                                $scope.GetProviderPeerByIndividualId($scope.ProviderId);
                                HideLoader();
                            }

                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            }
            else {
                HideLoader();
            }

        }

        $scope.DownloadDocumentAPIURL = WebApiUrl + 'Individual/DownloadPDF/' + sessionStorage.Key + '?path=';
        $scope.DownloadDocument = function (path) {
            window.open($scope.DownloadDocumentAPIURL + path, '_blank');
        };

        $scope.setSaveDocumentRef = function (fn) {
            $scope.saveAllDocument = fn;
        };

        $scope.editPeer = function (item) {

            if (!item.ReferenceNumber) {
                item.ReferenceNumber = new Date().valueOf();
            }
            $scope.peer = item;
            if (item.PeerReviewDate) {
                $scope.peer.PeerReviewDate = new Date(item.PeerReviewDate);
            }
            $scope.addPeer = true;
            initDocument();
        }

        $scope.GetProviderPeerByIndividualId = function (ProviderId) {
            ShowLoader("Please Wait...");
            try {
                backofficeProviderPeerService.GetPeer(sessionStorage.Key, ProviderId)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            if (response.ProviderOtherList != null) {
                                $scope.peerList = response.ProviderOtherList;
                                HideLoader();
                                processDocument();
                            }
                            else {
                                HideLoader();
                            }
                        } else {
                            HideLoader();
                        }
                    }, function (data) {
                        HideLoader();
                        $scope.showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.addNewBtnClick = function () {

            $scope.peer = {};
            $scope.peer.ReferenceNumber = new Date().valueOf();
            $scope.addPeer = true;
        }

        $scope.cancelPeer = function () {
            $scope.addPeer = false;
        }

        var processDocument = function () {
            if ($scope.peerList && $scope.peerList.length > 0) {
                ProviderMultipleFileUploadService.providerDocumentProcess(sessionStorage.Key, $scope.ProviderId, $scope.documentConfig.DocId,
                    $scope.peerList, $scope.documentConfig.PropertyToProcess, $scope.documentConfig.LinkType).then(function (responseData) {
                        $scope.peerList = responseData;
                        //  console.log('data processed', responseData);
                    });
            }
        };

        $scope.deletePeer = function (item) {
            if (item && confirm(Messages.bbabace)) {
                ShowLoader("Please wait...");
                item.IsActive = false;
                item.IsDeleted = true;
                backofficeProviderPeerService.savePeer(sessionStorage.Key, [item])
                    .then(function (response) {
                        $scope.GetProviderPeerByIndividualId($scope.ProviderId);
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            }
        };




    }
})();