(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('deficiencyService', deficiencyService);

        deficiencyService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig'];

    function deficiencyService($http, $q, WebApiUrl, AppConfig) {

        var _getIndividualDeficiency = function (key, IndividualId, ApplicationId, IndividualLicenseId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/GetIndividualDeficiency/' + key + "?IndividualId=" + IndividualId + "&ApplicationId=" + ApplicationId + "&IndividualLicenseId=" + IndividualLicenseId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getDeficiencyReason = function (key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Configuration/GetDeficiencyReason/' + key, null)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveIndividualDeficiency = function (individualDeficiency, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/SaveIndividualDeficiency/' + key + "?Source=" + AppConfig.Source, [individualDeficiency])
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };



        var _emailAndText = function (emailData, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'User/EmailAndText/' + key + "?Source=" + AppConfig.Source, emailData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _individualCommunicationSave = function (communicationData, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualCommunicationSave/' + key + "?Source=" + AppConfig.Source, communicationData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            getIndividualDeficiency: _getIndividualDeficiency,
            saveIndividualDeficiency: _saveIndividualDeficiency,
            emailAndText: _emailAndText,
            individualCommunicationSave: _individualCommunicationSave,
            getDeficiencyReason: _getDeficiencyReason
        };
    }
})();