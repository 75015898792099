﻿
angular.lowercase = angular.$$lowercase;
angular.module('natApp',
    [
        'ui.router',
        'ui.bootstrap',
        'ngSanitize',
        'ngAnimate',
        'ngCookies',
        'naif.base64',
        'ui.mask',
        'ngFileUpload',
        'app.constant',
        'app.core',
        'app.backofficeIndividual',
        'app.report',
        'app.InitialApplication',
        'app.ApplicationWorkflow',
        'angularUtils.directives.dirPagination',
        'app.backofficeAdministor',
        'app.account',
        'app.login',
        'app.individualDashboard',
        'app.backofficeProvider',
        'app.providerDashboard',
        'profile.manager',
        'reporting.QueryReporting',
        'reporting.FieldSearchReporting',
        'task.management',
        'app.provider',
        'app.backofficeBusinessEntity',
        'app.backofficeAmcEntity',
        'app.lga',
        //'app.backofficecompany',
        'app.merging',
        'app.compliance',
        'ui.bootstrap.datetimepicker',
        'app.enforcement',
        'app.routeAuthorization',
        'app.pciCompliantPayment'
    ]);