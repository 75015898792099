(function () {
    'use strict';

    angular
        .module('app.report')
        .controller("LicenseChartController", LicenseChartController);

        LicenseChartController.$inject = ["$rootScope", "$scope",
        "licenseChartService", "utilityService", "WebApiUrl", "initialApplicationstatusService", "countryStateService", "typeValuesService", "TypeValue", "$q", "$exceptionHandler"
    ];

    function LicenseChartController($rootScope, $scope,
        licenseChartService, utilityService, WebApiUrl, initialApplicationstatusService, countryStateService, typeValuesService, TypeValue, $q, $exceptionHandler) {

        var processDataforChart = function (licenseList) {
            var data = [];
            if (licenseList.length >0) {
                var row = ['Month','Count of Licenses Expiring'];
                data.push(row);
                for (var i = 0; i < licenseList.length; i++) {
                    row = [];
                    if (licenseList[i].Month != null){
                        row.push(licenseList[i].Month.toString());
                    }
                    else{
                        row.push("None");
                    }

                    if (licenseList[i].CountOfLicense != null){
                        row.push(licenseList[i].CountOfLicense);                        
                    }
                    else{
                        row.push(0);
                    }
                    data.push(row);
                }
            }
            $scope.licenseData = data;
            console.log(data);
        };

        var drawChart = function(){
            var chartData = google.visualization.arrayToDataTable($scope.licenseData);
            var options = {title: 'License Expiration by Month', isStacked:false};  

            // Instantiate and draw the chart.
            var chart = new google.charts.Bar(document.getElementById('LicenseChartDiv'));
            chart.draw(chartData, options);
        }

        $scope.loadLicenseChart = function (noOfMonths) {
            try {
                ShowLoader("Please Wait...");
                licenseChartService.getLicenseExpiringByMonth(sessionStorage.Key, noOfMonths)
                    .then(function (response) {
                        if (response.Status && response.StatusCode == "00") {
                            processDataforChart(response.LicenseExpiringByMonthList);
                            google.charts.setOnLoadCallback(drawChart);
                        }
                        HideLoader();
                    });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        }

        $scope.init = function () {
            google.charts.load('current', { packages: ['bar'] });
            try {
                $scope.loadLicenseChart(6);
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
                $exceptionHandler(ex);
            }
        }

        $scope.init();
    }
})();
