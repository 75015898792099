(function () {
    'use strict'

    angular.module('app.backofficeEntity')
        .directive('ceCourseValidation', [CeCourseValidation]);

    function CeCourseValidation() {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                
            }
        };
    }
})();