(function() {
    'use strict'

    angular.module('app.report')
        .factory('licenseChartService', licenseChartService)

        licenseChartService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function licenseChartService($http, $q, WebApiUrl, AppConfig) {
        var _getLicenseExpiringByMonth = function(key,noOfMonths) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Report/LicenseExpiringByMonth/' + key + '?noOfMonths=' + noOfMonths)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            getLicenseExpiringByMonth: _getLicenseExpiringByMonth
        };
    }
})();
