﻿/// <reference path="deficiency-reason-directive-controller.js" />
(function () {
    'use strict'
    angular.module('app.backofficeAdministor').controller('deficiencyReasonController', ['$scope', 'ConfigurationService', '$q', 'deficiencyReasonFactory', 'Messages', function ($scope, ConfigurationService, $q, deficiencyReasonFactory, Messages) {
        $scope.ddlMasterTransaction = [];
        $scope.DRSearch = {};
        var _Key = sessionStorage.Key;
        var init = function () {
            $scope.netValidator = {};
            $scope.GetDeficiencyReason();
            // $scope.DeficiencyReasonInfo = {};
            GetAllMasterTransaction();
            GetAllTemplates();
            //Get_All_LAPP_DeficiencyTemplate();
        };

        $scope.GetDeficiencyReason = function (isSearch) {
            var deficiencyReasonSearch = {};
            if (isSearch)
                deficiencyReasonSearch.IsSearch = true;
            else
                deficiencyReasonSearch.IsSearch = false;
            deficiencyReasonSearch.ApplicationTypeId = $scope.DRSearch.sMasterTransactionId;
            deficiencyReasonSearch.DeficiencyReasonName = $scope.DRSearch.sDeficiencyTemplateName;
            deficiencyReasonSearch.IsActive = $scope.DRSearch.sIsActive;
            ShowLoader();
            ConfigurationService.GetDeficiencyReasonList(_Key, deficiencyReasonSearch).then(function(data){ data = data.data;
                //$scope.DeficiencyTemplateGrid.api.setRowData(data.DeficiencyTemplateResponseList);
                $scope.DeficiencyReasonList = data.DeficiencyReasonResponseList;

                HideLoader();
            }, function(error) { error = error.data;
                $scope.Error = error;
                HideLoader();
            });
        };

        var GetAllMasterTransaction = function () {
            ShowLoader();
            ConfigurationService.GetAllMasterTransaction(_Key).then(function(data){ data = data.data;
                $scope.TransactionTypes = data.MasterTransactionList;
                HideLoader();
            }, function(error) { error = error.data;
                $scope.Error = error;
                HideLoader();
            });
        };

        // //Load PageNames
        // $scope.loadPageNames = function (forSearch) {
        //     var deferred = $q.defer();
        //     //$scope.DeficiencyReasonInfo.PageTabSectionId = '';
        //     //$scope.PageTabSectionList = [];
        //     //$scope.DeficiencyReasonInfo.PageModuleTabSubModuleId = '';
        //     //$scope.SubPageNames = [];
        //     if (forSearch) {
        //         $scope.PageNamesSH = [];
        //         if ($scope.searchdata.MasterTransactionId) {
        //             ConfigurationService.PageGetAllPageNamesByMasterTransactionId(_Key, $scope.searchdata.MasterTransactionId)
        //                 .then(function (response) {
        //                     $scope.PageNamesSH = response.data.PageModule;
        //                     deferred.resolve(response);
        //                 })
        //                 .catch(function () {
        //                     deferred.reject({});
        //                 });
        //         } else {
        //             deferred.reject({});
        //         }
        //     } else {
        //         $scope.PageNames = [];
        //         $scope.DeficiencyReasonInfo.ApplicationTypeId = $scope.DeficiencyReasonInfo.MasterTransactionId;
        //         if ($scope.DeficiencyReasonInfo.MasterTransactionId) {
        //             ConfigurationService.PageGetAllPageNamesByMasterTransactionId(_Key, $scope.DeficiencyReasonInfo.MasterTransactionId)
        //                 .then(function (response) {
        //                     $scope.PageNames = response.data.PageModule;
        //                     deferred.resolve(response);
        //                 })
        //                 .catch(function () {
        //                     deferred.reject({});
        //                 });
        //         } else {
        //             deferred.reject({});
        //         }
        //     }
        //     return deferred.promise;
        // };

        //Load Sub Page Names
        $scope.loadPageSubName = function (forSearch) {
            var deferred = $q.defer();
            //clear child
            //$scope.DeficiencyReasonInfo.PageTabSectionId = '';
            //$scope.PageTabSectionList = [];

            if (forSearch) {
                $scope.SubPageNamesSH = [];
                if ($scope.searchdata.PageModuleId) {
                    ConfigurationService.PageGetAllTabsByPageModuleId(_Key, $scope.searchdata.PageModuleId)
                        .then(function (response) {
                            $scope.SubPageNamesSH = response.data.PageModuleTabSubModule;
                            deferred.resolve(response);
                        })
                        .catch(function () {
                            deferred.reject({});
                        });
                } else {
                    deferred.reject({});
                }
            } else {
                $scope.SubPageNames = [];
                $scope.DeficiencyReasonInfo.ApplicationTypeId = $scope.DeficiencyReasonInfo.MasterTransactionId;
                $scope.DeficiencyReasonInfo.PageModuleId = $scope.DeficiencyReasonInfo.MasterTransactionId;
                if ($scope.DeficiencyReasonInfo.PageModuleId) {
                    ConfigurationService.PageGetAllTabsByPageModuleId(_Key, $scope.DeficiencyReasonInfo.PageModuleId)
                        .then(function (response) {
                            $scope.SubPageNames = response.data.PageModuleTabSubModule;
                            deferred.resolve(response);
                        })
                        .catch(function () {
                            deferred.reject({});
                        });
                } else {
                    deferred.reject({});
                }
            }
            return deferred.promise;
        };

        //Page TabSectionGet
        $scope.GetPageTabSectionByPageSubmoduleId = function (forSearch) {
            var deferred = $q.defer();
            if (forSearch) {
                //modification required
                //if ($scope.DeficiencyReasonInfo.PageModuleTabSubModuleId) {
                //    $scope.PageTabSectionList = [];
                //    if ($scope.DeficiencyReasonInfo.PageModuleTabSubModuleId) {
                //        ConfigurationService.GetAllTabsSubModule(_Key, $scope.DeficiencyReasonInfo.PageModuleTabSubModuleId)
                //           .then(function (response) {
                //               $scope.PageTabSectionList = response.data.PageTabSection;
                //               deferred.resolve(response);
                //           })
                //           .catch(function () {
                //               deferred.reject({});
                //           });
                //    } else {
                //        deferred.reject({});
                //    }
                //    deferred.resolve({});
                //} else {
                //    deferred.reject({});
                //}
            } else {
                if ($scope.DeficiencyReasonInfo.PageModuleTabSubModuleId) {
                    $scope.PageTabSectionList = [];
                    if ($scope.DeficiencyReasonInfo.PageModuleTabSubModuleId) {
                        ConfigurationService.GetAllTabsSubModule(_Key, $scope.DeficiencyReasonInfo.PageModuleTabSubModuleId)
                            .then(function (response) {
                                $scope.PageTabSectionList = response.data.PageTabSection;
                                deferred.resolve(response);
                            })
                            .catch(function () {
                                deferred.reject({});
                            });
                    } else {
                        deferred.reject({});
                    }
                    deferred.resolve({});
                } else {
                    deferred.reject({});
                }
            }
            return deferred.promise;
        };


        var GetAllTemplates = function () {
            deficiencyReasonFactory.GetAllTemplates(_Key).then(function (response) {
                $scope.ddlddldeftemp = response.data;
            });
        };

        var Get_All_LAPP_DeficiencyTemplate = function () {
            ShowLoader();
            ConfigurationService.Get_All_LAPP_DeficiencyTemplate(_Key).then(function(data){ data = data.data;
                $scope.ddlddldeftemp = data.DeficiencyTemplateResponseList;
            }, function(error) { error = error.data;
                $scope.Error = error;
            });
        };

        $scope.EditItem = function (item) {
            var EditItem = angular.copy(item);
            ShowLoader();
            $scope.DeficiencyReasonInfo = {};
            $scope.DeficiencyReasonInfo.MasterTransactionId = EditItem.MasterTransactionId;
            // $scope.loadPageNames().then(function () {
            $scope.DeficiencyReasonInfo.PageModuleId = EditItem.PageModuleId;
            $scope.loadPageSubName().then(function () {
                $scope.DeficiencyReasonInfo.PageModuleTabSubModuleId = EditItem.PageModuleTabSubModuleId;
                $scope.GetPageTabSectionByPageSubmoduleId().then(function () {
                    $scope.DeficiencyReasonInfo.PageTabSectionId = EditItem.PageTabSectionId;
                    $scope.DeficiencyReasonInfo = Object.assign($scope.DeficiencyReasonInfo, EditItem);
                    HideLoader();
                }, function () {
                    $scope.DeficiencyReasonInfo = Object.assign($scope.DeficiencyReasonInfo, EditItem);
                    HideLoader();
                });
            }, function () {
                $scope.DeficiencyReasonInfo = Object.assign($scope.DeficiencyReasonInfo, EditItem);
                HideLoader();
            });
            // }, function () {
            //     $scope.DeficiencyReasonInfo = Object.assign($scope.DeficiencyReasonInfo, EditItem);
            //     HideLoader();
            // });
        };
        $scope.DeleteItem = function (item) {
            if (confirm(Messages.General.Confirm.Delete)) {
                item.Is_Deleted = true;
                $scope.SaveDeficiencyReason(null, item);
            }
        };

        $scope.SaveDeficiencyReason = function (form, item) {
            var objContent = {};
            //Delete;
            if (item) {
                objContent = item;
            } else {
                if (!validateForm(form)) {
                    return;
                }
                objContent = angular.copy($scope.DeficiencyReasonInfo);
                if (!objContent.Deficiency_ID || objContent.Deficiency_ID == 0) {
                    objContent.Deficiency_ID = 0;
                    objContent.Is_Deleted = false;
                    objContent.IsEnabled = true;
                    objContent.DeficiencyReasonCode = 'RES';
                    objContent.DeficiencyReasonTypeId = 1;
                    objContent.Application_Type_ID = 1
                    objContent.DeficiencyReasonAppliesToTypeId = 1

                }
                objContent.ExplanationRequired = $scope.DeficiencyReasonInfo.ExplanationRequired;
            }
            ShowLoader();
            ConfigurationService.SaveDeficiencyReason(_Key, objContent)
                .then(function(response){ response = response.data;
                    HideLoader();
                    $scope.GetDeficiencyReason();
                    $scope.cancelBtnClick();
                }, function(data) { data = data.data;
                    alert(Messages.bcfefad);
                    HideLoader();
                });
        };

        //This function validates the for on click of submit button
        var validateForm = function (form, error) {
            $scope.netValidator.errorMessages = [];
            if (!!form) {
                form.$submitted = true;
                var errorMessages = angular.copy(form.errorMessages);
                angular.forEach(errorMessages, function (errorMessage, index) {
                    $scope.netValidator.errorMessages.push(errorMessage);
                });
            }
            if (!!error) {
                $scope.netValidator.errorMessages.push(error);
            }

            if (!!$scope.netValidator.errorMessages && $scope.netValidator.errorMessages.length > 0) {
                $scope.netValidator.showError = true;
                $scope.netValidator.isValid = false;
                return false;
            } else {
                $scope.netValidator.showError = false;
                $scope.netValidator.isValid = true;
                return true;
            }
        };

        $scope.cancelBtnClick = function () {
            $scope.netValidator = {};
            $scope.DeficiencyReasonInfo = null;
            if ($scope.frmDeficiencyReason) {
                $scope.frmDeficiencyReason.$setPristine();
            }
        };
        $scope.addnewClick = function () {
            $scope.DeficiencyReasonInfo = {};
        };

        init();
    }]);
})();
