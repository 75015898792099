(function() {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationFeeDetails', initialApplicationFeeDetailsDirective);

    initialApplicationFeeDetailsDirective.$inject = [];

    function initialApplicationFeeDetailsDirective() {
        return {
            restrict: 'E',
            scope: {
                licenseDescription: "=licenseDescription",
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                feeDetails: "=feeDetails",
                isBackoffice: "=isBackoffice",
                isPendingApplication: "=isPendingApplication",
                natValidation: "=natValidation",
                administratorPermits: "=administratorPermits",
                sitePermits: "=sitePermits",
                individualLicense: "=individualLicense"
            },
            templateUrl: "app/components/individual/application/directives/fee-details/fee-details.html",
            controller: "InitialApplicationFeeDetailsController"
        }
    }
})();
