(function () {
    'use strict'

    angular.module('app.core')
        .directive('statusMessages', ['$document', StatusMessagesDirective]);

    function StatusMessagesDirective($document) {
        var validateMessage = function (message) {
            if (typeof (message) !== 'string' && !Array.isArray(message)) {
                throw 'Status message type is unsupported: message must be either a string or an array of strings';
            }
        };

        var showSuccessMessage = function (scope, message) {
            scope.successMessage = message;
        };

        var showInformationMessage = function (scope, message) {
            scope.informationMessage = message;
        };

        var showErrorMessage = function (scope, message) {
            scope.errorMessage = message;
        };

        var showGenericMessage = function (scope, message) {
            scope.genericMessage = message;
        };

        var hideAllMessages = function (scope) {
            scope.successMessage = [];
            scope.informationMessage = [];
            scope.errorMessage = [];
            scope.genericMessage = [];
        };

        var getMessages = function (messageObjs) {
            var messages = [];

            //If it's not an array, turn it into an array
            if (!Array.isArray(messageObjs)) {
                messageObjs = [messageObjs];
            }

            //Now iterate through the message objects and attempt to find the message in them
            messageObjs.forEach(function (messageObj) {
                if (typeof (messageObj) == 'string' && !!messageObj) {
                    messages.push(messageObj);
                } else if (typeof (messageObj) == 'object' && !!messageObj) {
                    if (!!messageObj.message) {
                        messages.push(messageObj.message);
                    } else if (!!messageObj.Message) {
                        messages.push(messageObj.Message);
                    } else if (!!messageObj.errorMessage) {
                        messages.push(messageObj.errorMessage);
                    } else if (!!messageObj.statusText) {
                        messages.push(messageObj.statusText);
                    }
                }
            });

            return messages;
        };

        return {
            restrict: 'E',
            scope: {
                name: '@',
                lookFor: '@?',
                scrollTo: '=?'
            },
            link: function ($scope, element, attrs) {
                var elementName = $scope.name;
                if (!elementName) {
                    throw 'Unable to initialize status message: no name provided';
                }
                var scopeElement = null;
                if (!!$scope.lookFor) {
                    scopeElement = element.closest($scope.lookFor);
                } else {
                    scopeElement = element;
                }

                if (!$scope.scrollTo) {
                    $scope.scrollTo = true;
                }

                var effectiveScope = scopeElement.scope();
                var showMessageName = 'show{0}Msg'.format(elementName.toTitleCase());
                var hideMessageName = 'hide{0}Msg'.format(elementName.toTitleCase());

                if (effectiveScope[showMessageName]) {
                    throw 'Two status message directives exist with the same name: {0}'.format(elementName);
                }

                effectiveScope[showMessageName] = function (type, messageObjs) {
                    var messages = getMessages(messageObjs);

                    switch (type.toLowerCase()) {
                        case 'success':
                        case 'succ':
                        case '+':
                            showSuccessMessage($scope, messages);
                            break;
                        case 'information':
                        case 'info':
                        case '@':
                            showInformationMessage($scope, messages);
                            break;
                        case 'error':
                        case 'err':
                        case '-':
                            showErrorMessage($scope, messages);
                            break;
                        default:
                            showGenericMessage($scope, messages);
                            break;
                    }

                    if (!!messages && messages.length > 0) {
                        element.parent().scrollTo();
                    }
                };

                effectiveScope[hideMessageName] = function () {
                    hideAllMessages($scope);
                };

                $scope.$on('$destroy', function () {
                    delete effectiveScope[showMessageName];
                    delete effectiveScope[hideMessageName];
                });
            },
            controller: 'StatusMessagesController',
            templateUrl: 'app/core/directive/status-messages/status-messages.html'
        };
    }
})();