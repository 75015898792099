(function () {
  'use strict'
  angular.module('app.report')
    .controller('PendingRenewalReportController', PendingRenewalReportController);

  PendingRenewalReportController.$inject = ['$scope', '$q', '$controller', '$filter',
    'reportService', '$state', 'utilityService', 'ApiUrlsForReports', 'ApiUrlsForReportsXls'];

  function PendingRenewalReportController($scope, $q, $controller, $filter, reportService, $state, utilityService, ApiUrlsForReports, ApiUrlsForReportsXls) {

    // Pagination
    $scope.Pager = {
      sortType: 'LicenseNumber',
      sortOrder: 'asc',
      currentPage: 1,
      totalRecords: 10,
      pageSize: 20,
      maxSize: 5
    }

    $scope.Headers = [
      { name: 'Status', sortType: 'Status', sortOrder: 'asc', class: 'w60' },
      { name: 'License#', sortType: 'LicenseNumber', sortOrder: 'desc', class: 'w70' },
      { name: 'Original License Date', sortType: 'OriginalDateOfLicensure', sortOrder: 'asc' },
      { name: 'License Issue Date', sortType: 'LicenseIssueDate', sortOrder: 'asc' },
      { name: 'License Exp. Date', sortType: 'LicenseExpirationDate', sortOrder: 'asc' },
      { name: 'First Name', sortType: 'FirstName', sortOrder: 'asc', class: 'w90' },
      { name: 'Last Name', sortType: 'LastName', sortOrder: 'asc', class: 'w90' },
      { name: 'DoB', sortType: 'DateOfBirth', sortOrder: 'asc', class: 'w80' },
      { name: 'Phone', sortType: 'PhoneNumber', sortOrder: 'asc', class: 'w90' },
      { name: 'Street', sortType: 'StreetLine1', sortOrder: 'asc', class: 'w90' },
      { name: 'City/State/ZIP', sortType: 'City,StateCode,Zip', sortOrder: 'asc', class: 'w80' }
    ]

    $scope.getPendingRenewalReportList = function () {
      try {
        if ($scope.isUserSessionActive()) {
          ShowLoader('Please Wait...')
          reportService.pendingRenewalReportWithPager(sessionStorage.Key, $scope.Pager, ApiUrlsForReports.pendingRenewal)
            .then(function (response) {
              if ($scope.checkServerResponse(response)) {
                $scope.Pager.totalRecords = response.PagerVM.TotalRecords;
                $scope.PendingRenewalData = response.PagerVM.Records;
              }
              HideLoader();
              utilityService.scrollTop();
            }, function (data) {
              HideLoader()
              showStatusMessage(data, 'error')
            })
        } else {
          $state.go('app.Loginwoemail')
        }
      } catch (ex) {
        HideLoader()
        if (ex != null) { showStatusMessage(ex.message, 'error'); }
      }
    }

    $scope.getPendingRenewalReportList();

    $scope.SortRecords = function (header) {
      $scope.Pager.currentPage = 1;
      $scope.Pager.sortType = header.sortType;
      $scope.Pager.sortOrder = header.sortOrder;
      header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
      $scope.getPendingRenewalReportList();
    }
    $scope.getExcelForPendingRenewalReport = function () {
      try {
        ShowLoader('Please Wait...')
        reportService.getXLSForPendingRenewalReport(sessionStorage.Key, $scope.Pager, ApiUrlsForReportsXls.pendingRenewal)
          .then(function (response) {
            HideLoader()
          }, function (data) {
            HideLoader()
            showStatusMessage(data, 'error')
          })
      } catch (ex) {
        HideLoader()
        if (ex != null) { showStatusMessage(ex.message, 'error'); }
      }
    }
  };
})();

