(function () {
    'use strict'

    angular.module('app.core')
        .directive('applicationStatusPicker', [ApplicationStatusPicker]);

    function ApplicationStatusPicker() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                individualId: "=",
                applicationId: '=',
                applicationTypeId: '=',
                applicationSubTypeId: '=',
                applicationNumber: '=',
                applicationStatusId: '=',
                applicationSubmitMode: '=',
                applicationInternalStatusId: '=?',
                licenseTypeId: '=?',
                popoverPlacement: '@?',
                applicationStatusUpdated: '&?'
            },
            link: function ($scope, element, attrs) {

            },
            templateUrl: 'app/core/directive/application-status-picker/application-status-picker.html',
            controller: 'ApplicationStatusPickerController'
        };
    }
})();