(function() {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('supervisorInformationLogic', ['$q', SupervisorInformationLogic]);

    function SupervisorInformationLogic($q) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                var _childName = 'supervisorInformation';

                // $scope.$watch('current.supervisorInfo.SupervisedWorkAddressId', function (newVal, oldVal) {
                //     if (!!$scope.current.supervisorInfo && !!$scope.supervisedBusinessAddresses) {
                //         if (!!newVal) {
                //             $scope.current.superviseeAddress = $scope.supervisedBusinessAddresses.whereEquals(newVal, 'AddressId').firstOrDefault();
                //         } else {
                //             $scope.current.superviseeAddress = null;
                //         }
                //     }
                // });

                $scope.$watch('current.manualSupervisorAddress', function (newVal, oldVal) {
                    if (!!$scope.current.supervisorInfo) {
                        if (!!newVal) {
                            $scope.current.supervisorInfo.SupervisorStreetLine1 = newVal.StreetLine1;
                            $scope.current.supervisorInfo.SupervisorStreetLine2 = newVal.StreetLine2;
                            $scope.current.supervisorInfo.SupervisorCity = newVal.City;
                            $scope.current.supervisorInfo.SupervisorState = newVal.StateCode;
                            $scope.current.supervisorInfo.SupervisorZip = newVal.Zip;
                            $scope.current.supervisorInfo.SupervisorCountyId = newVal.CountyId;
                            $scope.current.supervisorInfo.SupervisorCountryId = newVal.CountryId;
                            $scope.current.supervisorInfo.SupervisorPhone = newVal.Phone;
                            $scope.current.supervisorInfo.SupervisorEmail = newVal.Email;
                            $scope.current.supervisorInfo.SupervisorFax = newVal.Fax;
                        }
                    }
                }, true);

                $scope.$init = function () {
                    $scope.manualSupervisorAddress = null;
                };

                $scope.$clear = function () {
                    $scope.manualSupervisorAddress = {};
                };

                $scope.$dataLoaded = function () {
                    var deferred = $q.defer();
                    if ($scope.licenseTypeConfig.SingleForm) {
                        var supervisorInfo = $scope.data.individualSupervisors.whereEquals($scope.applicationId, 'ApplicationId').firstOrDefault();

                        if (!!supervisorInfo) {
                            $scope.getSupervisorFromLicenseNumber(supervisorInfo.SupervisorLicenseNumber)
                            .then(function (data) {
                                $scope.addEditSupervisorInfo(supervisorInfo);
                                $scope.current.manualSupervisorAddress = {
                                    StreetLine1: supervisorInfo.SupervisorStreetLine1,
                                    StreetLine2: supervisorInfo.SupervisorStreetLine2,
                                    City: supervisorInfo.SupervisorCity,
                                    StateCode: supervisorInfo.SupervisorState,
                                    Zip: supervisorInfo.SupervisorZip,
                                    CountyId: supervisorInfo.SupervisorCountyId,
                                    CountryId: supervisorInfo.SupervisorCountryId,
                                    Phone: supervisorInfo.SupervisorPhone,
                                    Email: supervisorInfo.SupervisorEmail,
                                    Fax: supervisorInfo.SupervisorFax
                                };
                                deferred.resolve(data);
                            }, function (err) {
                                deferred.reject(err);
                            });
                        } else {
                            $scope.addEditSupervisorInfo();
                            supervisorInfo = $scope.current.supervisorInfo;

                            $scope.current.manualSupervisorAddress = {
                                StreetLine1: supervisorInfo.SupervisorStreetLine1,
                                StreetLine2: supervisorInfo.SupervisorStreetLine2,
                                City: supervisorInfo.SupervisorCity,
                                StateCode: supervisorInfo.SupervisorState,
                                Zip: supervisorInfo.SupervisorZip,
                                CountyId: supervisorInfo.SupervisorCountyId,
                                CountryId: supervisorInfo.SupervisorCountryId,
                                Phone: supervisorInfo.SupervisorPhone,
                                Email: supervisorInfo.SupervisorEmail,
                                Fax: supervisorInfo.SupervisorFax
                            };
                            deferred.reject({});
                        }
                    } else {
                        deferred.resolve({});
                    }

                    return deferred.promise;
                };

                $scope.getSupervisorFromLicenseNumber = function (licenseNumber) {
                    var deferred = $q.defer();

                    $scope.operationPending(true, 'Searching...');

                    $scope.searchForIndividual(licenseNumber)
                    .then(function(data) {
                        $scope.current.supervisor = data.IndividualResponseName.firstOrDefault();

                        if (hasAcceptableLicense($scope.current.supervisor)) {
                            $scope.operationPending(false);
                            deferred.resolve(data);
                        } else {
                            $scope.current.supervisor = null;
                            $scope.operationPending(false);
                            $scope.showStatusMsg('-', 'This license number is not an acceptable license type or status.');
                            deferred.reject(data);
                        }
                    }, function (err) {
                        $scope.operationPending(false);
                        deferred.reject(err);
                    });

                    return deferred.promise;
                };

                var hasAcceptableLicense = function (licensee) {
                    if (// If the license is not of an acceptable type OR if it is not an acceptable status
                        (!!$scope.licenseTypeConfig.AcceptableLicenseTypes && 
                        $scope.licenseTypeConfig.AcceptableLicenseTypes.length > 0 && 
                        !$scope.licenseTypeConfig.AcceptableLicenseTypes.includes(licensee.LicenseTypeId)) 
                        || 
                        (!!$scope.licenseTypeConfig.AcceptableLicenseStatuses &&
                        $scope.licenseTypeConfig.AcceptableLicenseStatuses.length > 0 &&
                        !$scope.licenseTypeConfig.AcceptableLicenseStatuses.includes(licensee.LicenseStatusTypeId))
                        ) {
                        return false;
                    }
                    return true;
                };

                $scope.saveForm = function () {
                    if ($scope.licenseTypeConfig.SingleForm) {
                        $scope.saveSupervisorInfo($scope.current.supervisorInfo)
                        .then(function (data) {
                            if (!!$scope.showSubmitPanel && !!$scope.openNextTab) {
                                $scope.openNextTab({currentTab: $scope.tab});
                            }
                        }, function () {

                        });
                    } else {
                        $scope.sendComplete(_childName);
        
                        if (!!$scope.showSubmitPanel && !!$scope.openNextTab) {
                            $scope.openNextTab({currentTab: $scope.tab});
                        }
                    }
                };
            }
        };
    }
})();