(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('certificationValidation', ['Messages',CertificationValidation]);

    function CertificationValidation(Messages) {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                $scope.$on('$destroy', function () {
                    $scope.natValidation.showError = false;
                    $scope.natValidation.errorMessages = [];
                });

                //Step 2 and Step 3 USMLE are the only ones to check for disqualifying
                var checkIfDisqualifying = function (exam) {
                    return [91,92].includes(exam.ExamNameId) && ((exam.ExamResultId == 1 && exam.NumberofAttempts > 4) || (exam.ExamResultId == 2 && exam.NumberofAttempts >= 4));
                };

                $scope.validateForm = function () {
                    var valid = true;
                    
                    //LAMED PHYSICIAN/OSTEOPATH ONLY - Check for 4 or more failures
                    if (!!$scope.individualLicense && [29,33].includes($scope.individualLicense.LicenseTypeId)) {
                        $scope.certificationList.forEach(function (exam) {
                            var resultIsDisqualifying = checkIfDisqualifying(exam);
                            if (resultIsDisqualifying) {
                                valid = false;
    
                                $scope.$emit('criticalError', { propName: 'examResults', errorMessage: Messages.General.Errors.PleaseAdvise});
                            }
                        });
                    }

                    return valid;
                };

                var checkForValues = function (except) {
                    var valid = true;
                    if (!except) {
                        except = [];
                    }
                    for (var certProp in $scope.current.certification) {
                        var val = $scope.current.certification[certProp];
                        //If it is a configured value
                        if(!!$scope.modeConfig.Data[certProp] && !except.includes(certProp)) {
                            var label = '';
                            if (!!$scope.modeConfig.FieldTableHeaders && !!$scope.modeConfig.FieldTableHeaders[certProp]) {
                                label = $scope.modeConfig.FieldTableHeaders[certProp].label;
                            } else  {
                                label = certProp;
                            }

                            if (typeof(val) === 'undefined' || val == null ||
                                (typeof(val) != 'boolean' && typeof(val) != 'number' && !val)) {
                                $scope.natValidation.showError = true;
                                $scope.natValidation.errorMessages.push({
                                    propName: certProp,
                                    errorMessage: Messages.General.Errors.IsRequired(label)
                                });
                                valid = false;
                            }
                        }
                    }

                    return valid;
                };

                $scope.validateCertification = function () {
                    var valid = true;
                    if (!$scope.natValidation) { $scope.natValidation = {}; }
                    
                    $scope.natValidation.showError = false;
                    $scope.natValidation.errorMessages = [];

                    if (!$scope.current.certification.NBCOTDateScheduled) {
                        valid = checkForValues(['NBCOTDateScheduled']);
                    } else {
                        valid = checkForValues(['NBCOTDateTaken', 'ExamResultName', 'CertificationBoardNumber']);
                    }

                    return valid;
                };
            }
        };
    }
})();