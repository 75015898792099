(function () {
    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeProviderLegacyDocumentController", BackofficeProviderDocumentController);

    BackofficeProviderDocumentController.$inject = ["$rootScope", "$scope", "$state", "WebApiUrl", "ProviderMultipleFileUploadService"];

    function BackofficeProviderDocumentController($rootScope, $scope, $state, WebApiUrl, ProviderMultipleFileUploadService) {
        $scope.documentsList = null;
        $scope.documentsList2 = null;
        $scope.documentsListLegacy = null;
       // $scope.LoadDocument
        $scope.documentUploadLegacy = {
            ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
            ApplicationId: $scope.ApplicationID,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "L",
            LoadByDocIdAndDocCode: true,
            DocId: 48,
            DocCode: "D1048"
           // DocumentUploadedForId: (($rootScope.routeData && !!$rootScope.routeData.documentUploadedForId) ? $rootScope.routeData.documentUploadedForId : 1)
        };

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, providerId) {
            if ($rootScope.individualFirmCurrentTab.name == "document") {
                $scope.documentUploadinchard = undefined;
                $scope.documentUploadLegacy.ProviderId = providerId;
                $scope.providerId = providerId;
                $scope.$broadcast("loaddocumentListing", providerId,$scope.documentUploadLegacy.DocId);
                $scope.$broadcast("initdocumentCreation");
                $rootScope.individualFirmCurrentTab.isLoadedOnce = true;
            }
        });
    }
})();