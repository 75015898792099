(function () {
    'use strict'

    angular.module('app.enforcement')
    .factory('ComplaintSearchExtService', ['$http', '$q', 'WebApiUrl', 'utilityService', ComplaintSearchExtService]);

    function ComplaintSearchExtService($http, $q, WebApiUrl, utilityService) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _downloadActionReport = function (key, request) {
            var httpPromise = $http.post(WebApiUrl + 'Entity/GenerateAndDownloadDocument/' + key + '/', request, {
                responseType: 'arraybuffer'
            });
            return responseHandler(httpPromise, function(res) {
                var headers = res.headers();
                if (headers['apistatus'] == 'true') {
                    utilityService.downloadExcelFile(res.data, headers);
                }
            });
        };

        var _getStaffUsers = function (key) {
            var urlParams = {
                UserTypeId: 1
            };
            return responseHandler($http.get(WebApiUrl + 'Users/GetUsersByUserTypeId/' + key + '/', { params: urlParams }));
        };

        var _getInvolvedParties = function (key, complaintId, complaintInvolvedPartyTypeId) {
            var request = {
                ComplaintId: complaintId,
                ComplaintInvolvedPartyTypeId: complaintInvolvedPartyTypeId
            };
            return responseHandler($http.post(WebApiUrl + 'Complaint/GetComplaintInvolvedParty/' + key + '/', request));
        };

        var _saveComplaintInvolvedParty = function (party) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/SaveComplaintInvolvedPartyFromPublic/public/', party));
        };

        return {
            downloadActionReport: _downloadActionReport,
            getStaffUsers: _getStaffUsers,
            getInvolvedParties: _getInvolvedParties,
            saveComplaintInvolvedParty: _saveComplaintInvolvedParty
        };
    }
})();