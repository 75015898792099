(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintCases', [ComplaintCasesDirective]);

    function ComplaintCasesDirective() {
        return {
            restrict: 'E',
            scope: {
                complaint: '=',
                complaintCaseId: '=?',
                caseNumber: '=?',
                readOnly: '=?',
                queueName: '=?',
                configName: '@?',
                onEmptyCaseList: '&?',
                singleCase: '=?',
                
                //Outgoing parameters
                currentCases: '=?'
            },
            link: function ($scope, element, attrs) {

            },
            templateUrl: 'app/components/backoffice/enforcement/complaint-cases/complaint-cases.html',
            controller: 'ComplaintCasesController'
        };
    }
})();