(function () {
    'use strict';

    angular
      .module('app.InitialApplication')
      .controller("InitialApplicationLicenseTypeController", InitialApplicationLicenseTypeController);

    InitialApplicationLicenseTypeController.$inject = ["$rootScope", "$scope",
      "initialApplicationLicenseTypeService", "utilityService", "WebApiUrl"];

    function InitialApplicationLicenseTypeController($rootScope, $scope,
      initialApplicationLicenseTypeService, utilityService, WebApiUrl) {
        $scope.licenseType = {

        };

        $scope.getLicenseTypeConfig = function () {
            var relativeFilePath = "/individual/application/license-type/license-type.server-config.json";
            utilityService.getConfigData(relativeFilePath)
              .then(function (data) {
                  $scope.licenseTypeConfig = data;
              }, function (error) {

              });
        };

        $scope.getLicenseTypeConfig();

        $scope.setVisibilityOfAddForm = function (licenseTypeForm, showForm) {
            licenseTypeForm.$setPristine();
            if ($scope.viewConfig == undefined)
                $scope.viewConfig = {};
            if (showForm)
                $scope.licenseType = {};
            $scope.viewConfig.showAddForm = showForm;
        }

    }
})();
