(function () {
    'use strict'

    angular.module('app.enforcement')
        .directive('watchComplaintProperty', ['$parse', WatchComplaintProperty])
        .directive('complaintSearchLogic', ['ComplaintSearchExtService', '$q', '$timeout', ComplaintSearchLogic]);

    function WatchComplaintProperty($parse) {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                var removeWatch = $scope.$watch(attrs.property, function (newVal) {
                    $parse(attrs.onChange)($scope, {$value: newVal});
                });

                $scope.$on('$destroy', function () {
                    removeWatch();
                });
            }
        }
    }

    function ComplaintSearchLogic(ComplaintSearchExtService, $q, $timeout) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.downloadActionReportClicked = function (complaint, groupName) {
                    var deferred = $q.defer();

                    try {
                        $scope.operationPending(true, 'Generating...');
                        var request = {
                            ComplaintId: complaint.ComplaintId,
                            ComplaintActionGroup: groupName,
                            TemplateName: $scope.uiConfig.ActionReportTemplateName
                        };
                        ComplaintSearchExtService.downloadActionReport(sessionStorage.Key, request)
                            .then(function (data) {
                                if (!data.Status && !!data.StatusCode) {
                                    $scope.showStatusMsg('-', data);
                                }
                                $scope.operationPending(false);
                                deferred.resolve(data);
                            }, function (err) {
                                $scope.showStatusMsg('-', err);
                                $scope.operationPending(false);
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        $scope.showStatusMsg('-', ex);
                        $scope.operationPending(false);
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.getStaffUsers = function () {
                    var deferred = $q.defer();

                    try {
                        ComplaintSearchExtService.getStaffUsers(sessionStorage.Key)
                            .then(function (data) {
                                if (data.Status && data.UsersList) {
                                    $scope.usersList = data.UsersList.orderBy('FirstName,LastName');
                                } else {
                                    $scope.usersList = [];
                                }
                                $scope.usersList.forEach(function (user) {
                                    user.FullName = user.FirstName + ' ' + user.LastName;
                                });
                                deferred.resolve(data);
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.assignmentUpdated = function (complaint, assignment) {
                    complaint.InvestigatorId = assignment.AssignedTo;

                    $scope.operationPending(true, 'Updating Investigator...');

                    $scope.saveComplaint(complaint)
                        .then(function (data) {
                            $scope.operationPending(false);
                        }, function (err) {
                            $scope.operationPending(false);
                            $scope.showStatusMsg('-', err);
                        });
                };

                $scope.initExt = function () {
                    
                };

                $scope.saveComplaintDetailsExt = function (complaint) {
                    $scope.hideStatusMsg();
                    ComplaintSearchExtService.getInvolvedParties(sessionStorage.Key, complaint.ComplaintId)
                        .then(function (data) {
                            if (data.Status && !!data.ComplaintInvolvedPartyList) {
                                var involvedParties = data.ComplaintInvolvedPartyList;
                                var complainant = involvedParties.where('item.ComplaintInvolvedPartyTypeId==1').firstOrDefault();
                                $scope.$broadcast('SaveComplaintQuestionnaire', { complaint: complaint, involvedParty: complainant });
                                $scope.showStatusMsg('+', $scope.config.SubmissionMessageComplaintDetail);
                                $scope.operationPending(false);
                            } else {
                                $scope.involvedParties = [];
                                $scope.showStatusMsg('-', data);
                            }
                        }, function (err) {
                            $scope.showStatusMsg('-', err);
                        });
                };

                $scope.saveQuestionnaireFormClicked = function (form, complaint) {
                    if (!$scope.validateForm(form)) { return; }

                    $scope.$broadcast('SaveComplaintQuestionnaire', {
                        complaint: complaint
                    });
                };

                $scope.resetQuestionnaireFormClicked = function (complaint) {
                    complaint.resetDisciplineInformationForm = true;
                    complaint.resetProbationInformationForm = true;
                    $timeout(function () {
                        complaint.resetDisciplineInformationForm = false;
                        complaint.resetProbationInformationForm = false;
                    });
                };

                $scope.calculateCostRecovery = function (complaint, entries) {
                    if (!!entries) {
                        var costRecoveryAmount = 
                            entries.whereIncludes([425], 'ComplaintActionTypeId').select('ComplaintFinancialAmount').sum()
                            - entries.whereIncludes([426,427], 'ComplaintActionTypeId').select('ComplaintFinancialAmount').sum();
                        var balance = costRecoveryAmount 
                            - entries.whereIncludes([428], 'ComplaintActionTypeId').select('ComplaintFinancialAmount').sum()
                            + entries.whereIncludes([429], 'ComplaintActionTypeId').select('ComplaintFinancialAmount').sum();

                        if (!complaint.costRecoveryForm) {
                            complaint.costRecoveryForm = {};
                        }
                        complaint.costRecoveryForm.CostRecoveryAmount = costRecoveryAmount;
                        complaint.costRecoveryForm.CostRecoveryBalance = balance;
                    }
                };

                $scope.filterActions = function (list, groupName) {
                    list.conditionalSplice('$item.GroupNames != this', groupName);
                };

                $scope.scrollTo = function (id) {
                    $timeout(function () {
                        $(id).scrollTo();
                    }, 1);
                };

                $scope.licenseTypesLoaded = function (dataList) {
                    dataList.push(
                        {
                            LicenseTypeId: 1,
                            LicenseTypeName: 'Licensed Firm'
                        },
                        {
                            LicenseTypeId: 0,
                            LicenseTypeName: 'Unlicensed Individual'
                        },
                        {
                            LicenseTypeId: 0,
                            LicenseTypeName: 'Unlicensed Firm'
                        }
                    );
                };
            }
        };
    }
})();