(function () {
  'use strict';

  angular
    .module('app.InitialApplication')
    .controller("InitialApplicationUploadDocumentController", InitialApplicationUploadDocumentController);

  InitialApplicationUploadDocumentController.$inject = ["$rootScope", "$scope", "$state",
    "initialApplicationUploadDocumentService", "countryStateService", "typeValuesService", "TypeValue", "WebApiUrl"];

  function InitialApplicationUploadDocumentController($rootScope, $scope, $state,
    initialApplicationUploadDocumentService, countryStateService, typeValuesService, TypeValue, WebApiUrl) {

  }
})();
