(function () {
    'use strict'

    angular.module('app.core')
    .directive('activityTimer', ['$compile', '$rootScope', '$q', 'ActivityTimerService', ActivityTimer]);

    function ActivityTimer($compile, $rootScope, $q, ActivityTimerService) {
        var saveActivityTime = function (activityTime) {
            var deferred = $q.defer();
            
            try {
                ActivityTimerService.saveActivityTime(sessionStorage.Key, activityTime)
                .then(function(data) {
                    if (data.Status) {
                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        return {
            restrict: 'A',
            require: 'activityTimer',
            link: function ($scope, element, attrs, activityTimer) {
                var timerBox = '<div click-and-drag ng-show="!!activityTimer.isRunning" class="activity-timer">\
                                    <div class="activity-timer-grabber">\
                                        <span class="glyphicon glyphicon-align-justify" click-and-drag-focus></span>\
                                    </div>\
                                    <collapse-panel header-text="\'Activity Timers\'" default-is-collapsed="false" bg="\'highlight\'">\
                                        <div>\
                                            <div class="info pull-left" ng-repeat-start="timer in activityTimer.timers">\
                                                <p>{{timer.ActivityName}}</p>\
                                                <p>{{timer.ActivityText}}</p>\
                                                <p>{{timer.currentDuration.toString(\'hh:mm:ss\')}}</p>\
                                            </div>\
                                            <div class="stop pull-right">\
                                                <a title="Stop" href ng-click="activityTimer.stopActivity(timer.ActivityName)" log-click="activityTimer.stop">\
                                                    <i class="fa fa-stop"></i>\
                                                </a>\
                                            </div>\
                                            <div class="clearfix"></div>\
                                            <div class="divider" ng-if="!$last"></div>\
                                            <div ng-repeat-end></div>\
                                        </div>\
                                    </collapse-panel>\
                                </div>';
                var newScope = $scope.$new(true);

                newScope.activityTimer = activityTimer;

                var $e = angular.element(timerBox);
                element.append($e);
                $compile($e)(newScope);
                
                newScope.$on('$destroy', function () {
                    
                });
            },
            controller: ['$interval', function ($interval) {
                var self = this;

                self.timers = {};

                var stop = null;
                var startInterval = function () {
                    stop = $interval(function () {
                        var currentTime = new Date();
                        for (var timerName in self.timers) {
                            if (!self.timers.hasOwnProperty(timerName)) { continue; }
    
                            self.timers[timerName].currentDuration = currentTime.getDiff(self.timers[timerName].StartDateTime);
                        }
                    }, 1000);
                };
                var stopInterval = function () {
                    $interval.cancel(stop);

                    stop = null;
                };
                var checkIfRunning = function () {
                    if (!self.timers.isEmptyObj()) {
                        if (!stop) { startInterval(); }

                        self.isRunning = true;
                    } else {
                        if (!!stop) { stopInterval(); }

                        self.isRunning = false;
                    }
                };
                var getExistingTimes = function () {
                    var deferred = $q.defer();
                    
                    try {
                        var search = {
                            Data: {
                                IsCompleted: false,
                                PerformedBy: sessionStorage.UserID
                            }
                        };
                        ActivityTimerService.getActivityTimes(sessionStorage.Key, search)
                        .then(function(data) {
                            if (data.Status) {
                                data.ActivityTimes.Records.forEach(function (record) {
                                    record.StartDateTime = record.StartDateTime.getDateObj();
                                    record.EndDateTime = record.EndDateTime.getDateObj();
                                    self.timers[record.ActivityName] = record;
                                });
                            }
                            checkIfRunning();
                            deferred.resolve(data);
                        }, function(err) {
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };

                var watchLogin = function () {
                    var currentId = '';

                    var stopWatchLogin = $interval(function () {
                        //console.log('Check ' + new Date().ticks);
                        
                        if (!!sessionStorage && !!sessionStorage.UserID && sessionStorage.UserID != currentId) {
                            currentId = sessionStorage.UserID;

                            getExistingTimes();
                        } else if (!sessionStorage || !sessionStorage.UserID) {
                            currentId = '';
                            
                            self.timers = {};
                        }
                        checkIfRunning();
                    }, 1000);
                };

                self.startActivity = function (name, activityText, linkType, linkId, individualId, applicationId) {
                    var exists = !!self.timers[name];
                    if (!exists) {
                        self.timers[name] = {
                            ActivityName: name,
                            IndividualId: individualId,
                            ApplicationId: applicationId,
                            LinkType: linkType,
                            LinkId: linkId,
                            ActivityText: activityText,
                            StartDateTime: new Date(),
                            EndDateTime: null,
                            PerformedBy: sessionStorage.UserID,
                            IsCompleted: false,
                            IsActive: true,
                            IsDeleted: false,

                            currentDuration: new TimeSpan(),
                            isPaused: false
                        };

                        saveActivityTime(self.timers[name])
                        .then(function (data) {
                            self.timers[name].ActivityTimeId = data.SavedActivityTime.ActivityTimeId;
                        });
                    }

                    checkIfRunning();
                };

                self.stopActivity = function (name) {
                    if (!!self.timers[name]) {
                        self.timers[name].EndDateTime = new Date();
                        self.timers[name].IsCompleted = true;

                        if (!!self.timers[name].ActivityTimeId) {
                            saveActivityTime(self.timers[name]);
                        }
                        delete self.timers[name];
                    }

                    checkIfRunning();
                };

                watchLogin();
            }]
        };
    }
})();