(function() {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('licenseAddlInfoLogic', [LicenseAdditionalInfoLogic]);

    function LicenseAdditionalInfoLogic() {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                $scope.setupQuestions = function () {
                    $scope.current.additionalInfo.deaNumberQuestion = !!$scope.current.additionalInfo.AddInfoTypeValue1;
                    $scope.current.additionalInfo.snNumberQuestion = !!$scope.current.additionalInfo.AddInfoTypeValue2;
                };

                $scope.clearFields = function (fields) {
                    fields.forEach(function (field) {
                        $scope.current.additionalInfo[field] = '';
                    });
                };

                $scope.updateArray = function () {
                    var addlInfo = $scope.current.additionalInfo;

                    //Convert from column structure to array
                    addlInfo.addlInfo.forEach(function (info, index) {
                        var value = 'AddInfoTypeValue{0}'.format(''+(index+1));

                        info.AddlInfoTypeValue = addlInfo[value];
                    });
                };
            }
        };
    }
})();