﻿(function () {
    'use strict'
    angular.module('natApp')
        .factory('EInformationDirectiveService', ['$http', '$q', 'WebApiUrl', 'AppConfig', function ($http, $q, WebApiUrl, AppConfig) {
            var baseFactory = {};
            baseFactory.GetProviderById = function (key, providerid) {
                return returnDefferedResult($http.get(WebApiUrl + 'Provider/GetProviderById/' + key + '?providerid=' + providerid));
            };
            baseFactory.ProviderAddressByProviderId = function (key, providerid) {
                return returnDefferedResult($http.get(WebApiUrl + 'Provider/ProviderAddressByProviderId/' + key + '?ProviderId=' + providerid));
            };
            baseFactory.GetProviderContactByProviderId = function (Key, ID) {
                return returnDefferedResult($http.get(WebApiUrl + "Provider/ProviderContactByProviderId/" + Key + "/?ProviderId=" + ID));
            };
            baseFactory.ProviderAddressSave = function (Key, data) {
                return returnDefferedResult($http.post(WebApiUrl + "Provider/ProviderAddressSave/" + Key, data));
            };
            baseFactory.ProviderContactSave = function (Key, data) {
                return returnDefferedResult($http.post(WebApiUrl + "Provider/ProviderContactsSave/" + Key, data));
            };
            baseFactory.GetProviderLicense = function (key, providerid) {
                return returnDefferedResult($http.get(WebApiUrl + "Provider/GetProviderLicense/" + key + '?ProviderId=' + providerid + '&Source=' + AppConfig.Source));
            };
            baseFactory.GetProviderOther = function (key, providerid, ApplicationId) {
                return returnDefferedResult($http.get(WebApiUrl + "Provider/GetProviderOther/" + key + '?ProviderId=' + providerid + '&ApplicationId=' + ApplicationId));
            };
            baseFactory.GenerateApplicationId = function (key, applicationid, providerid, data) {
                return returnDefferedResult($http.post(WebApiUrl + "Application/GenerateApplicationId/" + key + '?ApplicationId=' + applicationid + '&UserId=' + providerid, data));
            };
            baseFactory.ProviderApplicationSave = function (key, data) {
                return returnDefferedResult($http.post(WebApiUrl + "Provider/ProviderApplicationSave/" + key, data));
            };
            baseFactory.SaveProviderOtherSave = function (key, data) {
                return returnDefferedResult($http.post(WebApiUrl + "Provider/SaveProviderOther/" + key, data));
            };
            baseFactory.ProviderSave = function (key, ApplicationId, UserId, data) {
                return returnDefferedResult($http.post(WebApiUrl + "Provider/ProviderSave/" + key + '?ApplicationId=' + ApplicationId + '&UserId=' + UserId, data));
            };

            baseFactory.UpdateLicenseTypebyApplicationId = function (key, ApplicationId, LicenseTypeId, data) {
                return returnDefferedResult($http.post(WebApiUrl + "Application/UpdateLicenseTypebyApplicationId/" + key + '?ApplicationId=' + ApplicationId + '&LicenseTypeId=' + LicenseTypeId, data));
            };
            var returnDefferedResult = function (request) {
                var deferred = $q.defer();
                request.then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.reject(response);
                });
                return deferred.promise;
            };

            return baseFactory;
        }]);
})();