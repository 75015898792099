(function () {
    'use strict'

    angular.module('app.enforcement')
        .controller('ComplaintCasesController', ['$scope', '$rootScope', '$q', 'ComplaintCasesService', 'utilityService', 'Messages', ComplaintCasesController]);

    function ComplaintCasesController($scope, $rootScope, $q, ComplaintCasesService, utilityService, Messages) {
        var _key = sessionStorage.Key;
        var _childName = '';

        var getNewComplaintCase = function () {
            return {
                ComplaintCaseId: null,
                ComplaintId: $scope.complaint.ComplaintId,
                ComplaintInvolvedPartyId: null,
                CaseNumber: $scope.complaint.ComplaintRefNum,
                CaseTypeId: null,
                CaseStatusId: $scope.uiConfig.DefaultComplaintCase.CaseStatusId,
                CaseStageId: $scope.uiConfig.DefaultComplaintCase.CaseStageId,
                CaseNotes: null,
                InvestigatorId: null,
                CloseDate: null,
                ComplaintDisciplineTypeIds: null,
                ComplaintDispTypeIds: null,
                DispositionDeciderId: null,
                DispositionDate: null,
                DispositionNotes: null,
                ComplaintPublicCaseReasonIds: null,
                DocketNumber: null,
                BoardAttorneyId: null,
                ComplaintLawViolationIds: null,
                ComplaintCodeViolationIds: null,
                ComplaintFinancialIds: null,
                ComplaintFinancialAmount: null,
                ComplaintFinancialPaidDate: null,
                ComplaintTermId: null,
                ComplaintAdditionalTermIds: null,
                IsInternalOnly: null,
                IsAdministrativeAction: null,
                IsNonActionable: null,
                IsForPublic: null,
                IsForInvestigationOnly: null,
                IsForLegalOnly: null,
                ReferenceNumber: null,
                IsActive: null,
                IsDeleted: null
            };
        };

        var getConfig = function () {
            var deferred = $q.defer();

            try {
                utilityService.getConfigData('/components/backoffice/enforcement/complaint-cases/complaint-cases.server-config.json')
                    .then(function (data) {
                        if (!!data) {
                            $scope.config = data;

                            $scope.uiConfig = $rootScope.getUIConfig($scope.configName, $scope.config);

                            $scope.isConfigLoaded = true;

                            $scope.tableHeaders = $scope.uiConfig.TableHeaders;

                            if (!!$scope.getConfigExt) {
                                $scope.getConfigExt(data);
                            }
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getInvolvedParties = function (complaintInvolvedPartyTypeIds) {
            var deferred = $q.defer();

            try {
                var request = {
                    ComplaintId: $scope.complaint.ComplaintId,
                    ComplaintCaseId: $scope.complaintCaseId,
                    ComplaintInvolvedPartyTypeIds: complaintInvolvedPartyTypeIds
                };

                ComplaintCasesService.getInvolvedParties(sessionStorage.Key, request)
                    .then(function (data) {
                        if (data.Status) {
                            $scope.involvedPartyList = data.ComplaintInvolvedPartyList;
                            deferred.resolve(data);
                        } else {
                            $scope.involvedPartyList = [];
                            deferred.reject(data);
                        }

                        if (!!$scope.getInvolvedPartiesExt) {
                            $scope.getInvolvedPartiesExt(data, complaintInvolvedPartyTypeIds);
                        }
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getComplaintCases = function () {
            var deferred = $q.defer();

            try {
                var request = {
                    ComplaintId: $scope.complaint.ComplaintId,
                    ComplaintCaseId: $scope.complaintCaseId,
                    QueueName: $scope.queueName
                };

                ComplaintCasesService.getComplaintCases(sessionStorage.Key, request)
                    .then(function (data) {
                        if (data.Status) {
                            $scope.complaintCaseList = data.ComplaintCaseList;
                            if (!!$scope.singleCase) {
                                $scope.current.complaintCase = angular.copy($scope.complaintCaseList.firstOrDefault());
                            }

                            deferred.resolve(data);
                        } else {
                            deferred.reject(data);
                        }

                        if (!!$scope.getComplaintCasesExt) {
                            $scope.getComplaintCasesExt(data);
                        }
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.saveComplaintCases = function (complaintCases) {
            var deferred = $q.defer();

            try {
                ComplaintCasesService.saveComplaintCases(sessionStorage.Key, complaintCases)
                    .then(function (data) {
                        if (data.Status) {
                            deferred.resolve(data);
                        } else {
                            deferred.reject(data);
                        }

                        if (!!$scope.saveComplaintCasesExt) {
                            $scope.saveComplaintCasesExt(data, complaintCases);
                        }
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.addEditComplaintCaseClicked = function (complaintCase) {
            if (!complaintCase) {
                complaintCase = getNewComplaintCase();
            }

            $scope.current.complaintCase = angular.copy(complaintCase);
        };

        $scope.saveComplaintCaseClicked = function (complaintCase, formType) {
            if ($scope.validateForm(formType)) {
                $scope.operationPending(true, 'Saving...');

                $scope.saveComplaintCases([complaintCase])
                    .then(function () {
                        if (!!$scope.singleCase) {
                            $scope.resetComplaintCaseClicked();
                        } else {
                            $scope.cancelComplaintCaseClicked();
                        }

                        $scope.operationPending(true, 'Loading...');

                        $scope.getComplaintCases()
                            .then(function () {
                                //if ($scope.complaintCaseList.length == 0 && !!$scope.onEmptyCaseList) {
                                $scope.onEmptyCaseList();
                                //}
                                $scope.operationPending(false);
                            }, function (err) {
                                $scope.operationPending(false);
                                $scope.showStatusMsg('-', err);
                            });
                    }, function (err) {
                        $scope.operationPending(false);
                        $scope.showStatusMsg('-', err);
                    });
            }
        };

        $scope.deleteComplaintCaseClicked = function (complaintCase) {
            if (confirm(Messages.dbbefde)) {
                $scope.operationPending(true, 'Deleting...');

                complaintCase.IsActive = false;
                complaintCase.IsDeleted = true;

                $scope.saveComplaintCases([complaintCase])
                    .then(function () {
                        $scope.getComplaintCases()
                            .then(function () {
                                $scope.operationPending(false);
                            }, function (err) {
                                $scope.operationPending(false);
                                $scope.showStatusMsg('-', err);
                            });
                    }, function (err) {
                        $scope.operationPending(false);
                        $scope.showStatusMsg('-', err);
                    });
            }
        };

        $scope.cancelComplaintCaseClicked = function () {
            $scope.current.complaintCase = null;
        };

        $scope.resetComplaintCaseClicked = function () {
            $scope.current.complaintCase = angular.copy($scope.complaintCaseList.firstOrDefault());
        };

        $scope.toggleRowDetailsClicked = function (item, name) {
            if (item.rowDetails == name) {
                item.rowDetails = '';
            } else {
                item.rowDetails = name;
            }

            item.rowClass = {
                'row-expanded': !!item.rowDetails
            };
        };

        $scope.closeRowDetailsClicked = function (item) {
            item.rowDetails = '';
            item.rowClass = {
                'row-expanded': !!item.rowDetails
            };
        };

        $scope.init = function () {
            $scope.current = {
                complaintCase: null
            };

            //console.log($scope.singleCase);

            $scope.pager = {};
            $scope.tableHeaders = {};
            $scope.complaintCaseList = [];
            $scope.involvedPartyList = [];

            getConfig()
                .then(function () {
                    $q.all([
                        $scope.getComplaintCases(),
                        $scope.getInvolvedParties($scope.uiConfig.ComplaintInvolvedPartyTypeIds)
                    ])
                        .then(function () {
                            $scope.complaintCaseList.forEach(function (complaintCase) {
                                complaintCase.involvedParty = $scope.involvedPartyList.whereEquals(complaintCase.ComplaintInvolvedPartyId, 'ComplaintInvolvedPartyId').firstOrDefault();
                            });

                            $scope.operationPending(false);
                        }, function () {
                            $scope.operationPending(false);
                        });
                }, function () {
                    $scope.showStatusMsg('-', 'Unable to retrieve configuration.');
                    $scope.operationPending(false);
                });

            if (!!$scope.initExt) {
                $scope.initExt();
            }
        };

        $scope.$watch('complaintCaseList', function (newVal) {
            $scope.currentCases = newVal;
        });
    }
})();
