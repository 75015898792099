(function () {
    'use strict';
    angular
        .module('app.backofficeIndividual')
        .controller("IndividualSearchController", IndividualSearchController);

    IndividualSearchController.$inject = ['$scope', '$q', '$state', 'individualInfoService', 'backofficeIndividualSearchService',
        'typeValuesService', 'TypeValue', 'utilityService'
    ];

    function IndividualSearchController($scope, $q, $state, individualInfoService, backofficeIndividualSearchService,
        typeValuesService, TypeValue, utilityService) {

        var searchMaster = {};
        $scope.routeData = $state.current.routeData;
        $scope.configName = $scope.routeData.configName;
        $scope.init = function () {
            $scope.natValidation = {};

            $scope.viewConfig = {
                showAddIndividualButton: false,
                isAddIndividual: false
            };

            $scope.pager = {
                pageSize: 20,
                currentPage: 1,
                totalRecords: "",
                maxSize: 3,
            };

            $q.all([
                    utilityService.getConfigData('/components/backoffice/individual/search/search.config.json')
                ])
                .then(function (response) {
                    $scope.config = response[0];
                    $scope.searchConfig = getUIConfig();
                    searchMaster = $scope.searchConfig.searchDefaults;
                    $scope.search = ((!!$scope.searchContent) ? (angular.copy($scope.searchContent)) : angular.copy(searchMaster));

                    $scope.loadingIndividualSearch(false);
                    //Temp changes
                    if (utilityService.isLocalServerAndDebuggingEnabled()) {
                        $scope.search.FirstName = "psfee";
                        $scope.search.LastName = "testfee";
                        $scope.searchIndividual($scope.search);
                    }
                }, function (data) {
                    $scope.loadingIndividualSearch(false);
                    $scope.showStatusMsg('-', data.message);
                });
        };

        var getUIConfig = function () {
            var config = {};

            for (var prop in $scope.config.UIConfigs['Default']) {
                if (!$scope.config.UIConfigs['Default'].hasOwnProperty(prop)) {
                    continue;
                }

                config[prop] = $scope.config.UIConfigs['Default'][prop];
            }

            if (!!$scope.config.UIConfigs[$scope.configName]) {
                for (var prop in $scope.config.UIConfigs[$scope.configName]) {
                    if (!$scope.config.UIConfigs[$scope.configName].hasOwnProperty(prop)) {
                        continue;
                    }

                    config[prop] = $scope.config.UIConfigs[$scope.configName][prop];
                }
            }

            return config;
        };

        $scope.searchIndividual = function () {
            $scope.loadingIndividualSearch(true, "Loading Search Results..");
            $scope.search.IsActive = true;
            individualInfoService.individualSearchWithPage($scope.search, sessionStorage.Key, $scope.pager.currentPage, $scope.pager.pageSize)
                .then(function (response) {
                        $scope.pager.totalRecords = response.Total_Recard;
                        $scope.hideStatusMsg();
                        $scope.individualSearchResult = response.IndividualList;
                        $scope.search.pager = $scope.pager.totalRecords;
                        $scope.search.individualSearchResult = $scope.individualSearchResult;
                        $scope.search.Total_Recard = response.Total_Recard;
                        individualInfoService.setIndividualSearchContent($scope.configName, $scope.search);
                        if (!!response.Status) {
                            if (response.Total_Recard > 0) {
                                if (!!$scope.redirectRoute)
                                    $state.go($scope.redirectRoute);
                                if ($scope.onSearchComplete)
                                    $scope.onSearchComplete();
                            } else {
                                $scope.showStatusMsg('-', "No Record Found");
                                $scope.viewConfig.showAddIndividualButton = true;
                            }
                        } else {
                            $scope.showStatusMsg('-', response.Message);
                            $scope.viewConfig.showAddIndividualButton = true;
                        }
                        $scope.loadingIndividualSearch(false);
                    },
                    function (data) {
                        $scope.loadingIndividualSearch(false);
                        $scope.showStatusMsg('-', data.message);
                    });
        };

        $scope.openAddIndividualForm = function () {
            ShowLoader();
            $scope.viewConfig.isAddIndividual = true;
            $scope.individual = {
                FirstName: $scope.search.FirstName,
                LastName: $scope.search.LastName,
                SSN: $scope.search.SSN,
                DateOfBirth: $scope.search.DateOfBirth,
                IsActive: true,
                IsDelete: false,
                IndividualOtherId: 0
            };
            $q.all([
                    typeValuesService.getTypeValuesByTypeName('ProfTitleSuffix', sessionStorage.Key),
                    typeValuesService.getTypeValuesByTypeName('Suffix', sessionStorage.Key)
                ])
                .then(function (response) {
                    $scope.profSuffixes = response[0].RefProfTitleSuffixResponseList;
                    $scope.suffixes = response[1].RefSuffixResponseList;
                    HideLoader();
                }, function (error) {
                    $scope.showStatusMsg('-', error.message);
                });
        };

        var validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            }
            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.individualSave = function (personalForm) {
            ShowLoader();
            if (!validateForm(personalForm)) {
                HideLoader();
                return;
            }

            var searchReqData = angular.copy(searchMaster);
            searchReqData.SSN = $scope.individual.SSN;
            searchReqData.IsActive = true;

            individualInfoService.individualSearchWithPage(searchReqData, sessionStorage.Key, $scope.pager.currentPage, $scope.pager.pageSize)
                .then(function (response) {
                    $scope.pager.totalRecords = response.Total_Recard;
                    if (response.Total_Recard > 0) {
                        $scope.hideStatusMsg();
                        $scope.showStatusMsg('-', "Individual with same SSN exist in the system.");
                        HideLoader();
                    } else if (!response.Status && response.StatusCode != '00') {
                        $scope.hideStatusMsg();
                        $scope.showStatusMsg('-', response);
                        HideLoader();
                    } else {
                        backofficeIndividualSearchService.individualSave([$scope.individual], sessionStorage.Key).then(function (res) {
                            $scope.search = angular.copy(searchMaster);
                            $scope.search.IndividualId = res.IndividualResponse[0].IndividualId;
                            $scope.search.FirstName = res.IndividualResponse[0].FirstName;
                            $scope.search.LastName = res.IndividualResponse[0].LastName;
                            $scope.search.DateOfBirth = res.IndividualResponse[0].DateOfBirth;
                            $scope.search.SSN = res.IndividualResponse[0].SSN;
                            $scope.hideStatusMsg();
                            $scope.showStatusMsg('+', "Individual added successfully.");
                            $scope.searchIndividual($scope.search);
                            $scope.viewConfig.isAddIndividual = false;
                        }, function (error) {
                            HideLoader();
                        });
                    }
                }, function (data) {
                    HideLoader();
                    if (data != null) {
                        $scope.showStatusMsg('-', data.message);
                    }
                });
        };

        $scope.cancelSearch = function () {
            $scope.search = angular.copy(searchMaster);
            $scope.viewConfig.showAddIndividualButton = false;
            if ($scope.onSearchCancel)
                $scope.onSearchCancel();
        };

        $scope.cancelIndividualSave = function () {
            $scope.viewConfig.isAddIndividual = false;
            $scope.natValidation.errorMessages = [];
            $scope.natValidation.showError = false;
        };

        $scope.init();
    }

})();