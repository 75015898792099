(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationLicenseInfoController", InitialApplicationLicenseInfoController);

    InitialApplicationLicenseInfoController.$inject = ["$rootScope", "$scope", "$state", "$q",
        "initialApplicationLicenseInfoService", "personalService", "initialApplicationAcknowledgementService", "utilityService", "WebApiUrl", "initialApplicationstatusService", "AppConfig", "$exceptionHandler"
    ];

    function InitialApplicationLicenseInfoController($rootScope, $scope, $state, $q,
        initialApplicationLicenseInfoService, personalService, initialApplicationAcknowledgementService, utilityService, WebApiUrl, initialApplicationstatusService, AppConfig, $exceptionHandler) {

        $scope.licenseData = {};
        $scope.licenseInfoContent = [];

        $scope.init = function () {
            try {
                $scope.datePicker = {
                    dateOpened: false,
                    format: 'M!/d!/yyyy',
                    dateOptions: {
                        formatYear: 'yy',
                        startingDay: 1
                    }
                };
                if (!!$scope.loadData) {
                    ShowLoader("Please Wait...");
                }
                if (!!$scope.licenseInfo) {
                    if (!!$scope.acknowledgement && !!$scope.acknowledgement.Date && !angular.isDate($scope.acknowledgement.Date))
                        $scope.acknowledgement.Date = new Date($scope.acknowledgement.Date);
                    else if (!$scope.acknowledgement) {
                        $scope.acknowledgement = {
                            Date: new Date()
                        }
                    } else if (!$scope.acknowledgement.Date) {
                        $scope.acknowledgement.Date = new Date();
                    }
                }
                $scope.getLicenseInfoConfig()
                    .then(function (data) {
                        $scope.licenseInfoConfig = data;
                        if (!$scope.isRenewal) {
                            AuditVisitInfoSave();
                        }
                        if (!!$scope.loadData) {
                            $scope.getLicenseInfoContent()
                                .then(function (response) {
                                    $scope.licenseInfoContent = response.ContentItemLk;

                                    $scope.getLicenseInfo()
                                        .then(function (response) {
                                            if (response.IndividualNVBusinessLicenseResponse != null && response.IndividualNVBusinessLicenseResponse.length > 0) {
                                                $scope.licenseInfo = response.IndividualNVBusinessLicenseResponse;
                                                $scope.initLicenseInfo();
                                            }
                                            HideLoader();
                                        }, function (error) {
                                            HideLoader();
                                            $scope.showStatusMessage({ messageContent: 'Some error occurred !', messageType: 'error' });
                                        });
                                },
                                    function (error) {
                                        HideLoader();
                                        $scope.showStatusMessage({ messageContent: 'Some error occurred !', messageType: 'error' });
                                    });
                        } else {
                            $scope.isConfigLoaded = true;
                            // $scope.initLicenseInfo();
                        }
                    },
                        function (error) {
                            HideLoader();
                            $scope.showStatusMessage({ messageContent: 'Some error occurred !', messageType: 'error' });
                        });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
                $exceptionHandler(ex);
            }
        };

        $scope.licenseOption = function () {
            return {
                BusinessLicenseNumber: '',
                IndividualNVBusinessLicenseId: 0,
                IndividualId: $scope.individualId,
                ContentItemResponse: false,
                ContentItemLkId: 0,
                ContentItemHash: 0,
                ContentDescription: '',
                IsActive: true,
                IsDeleted: false,
                NameonBusinessLicense: '',
                Status: ''
            }
        };

        $scope.getLicenseInfo = function () {
            return initialApplicationLicenseInfoService.getLicenseInfoByIndividualId($scope.individualId, sessionStorage.Key);
        };

        $scope.getLicenseInfoContent = function () {
            var contentIds = [1, 2, 3];
            return initialApplicationLicenseInfoService.getLicenseInfoContent(contentIds, sessionStorage.Key);
        };

        $scope.getLicenseInfoConfig = function () {
            $scope.individualLicenseInfo = -1;
            var relativeFilePath = "/individual/application/license-info/license-info.server-config.json";
            return utilityService.getConfigData(relativeFilePath)

        };

        $scope.initLicenseInfo = function () {
            angular.forEach($scope.licenseInfo, function (licenseInfoOption, key) {
                if (licenseInfoOption.ContentItemResponse == true) {
                    $scope.licenseData.licenseInfoValue = licenseInfoOption.IndividualNVBusinessLicenseId;
                }
                if (licenseInfoOption.ContentItemLkId == 1) {
                    if (!!licenseInfoOption.IndividualNVBusinessLicenseAddressResponse &&
                        licenseInfoOption.IndividualNVBusinessLicenseAddressResponse.length > 0) {
                        $scope.businessAddress = licenseInfoOption.IndividualNVBusinessLicenseAddressResponse[0];
                    } else {
                        if (!$scope.businessAddress) {
                            $scope.businessAddress = {
                                IndividualId: $scope.individualId,
                                ApplicationId: $scope.applicationId,
                                IndividualLicenseId: $scope.individualLicense.IndividualLicenseId,
                                IsActive: true,
                                CountryId: $rootScope.DefaultCountryId,
                                BeginDate: new Date(),
                                IndividualNVBusinessLicenseId: licenseInfoOption.IndividualNVBusinessLicenseId
                            };
                        }
                    }
                }
            });
            $scope.isConfigLoaded = true;
        };

        $scope.setLicenseInfo = function () {
            angular.forEach($scope.licenseInfo, function (licenseInfoOption, key) {
                if ($scope.licenseData.licenseInfoValue == licenseInfoOption.IndividualNVBusinessLicenseId) {
                    licenseInfoOption.ContentItemResponse = true;
                } else {
                    licenseInfoOption.ContentItemResponse = false;
                }
            });
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        var validateForm = function (currentForm) {
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        var ShowErrMsg = function (propName, errorMessage) {
            propName = (!!propName) ? propName : "licenseInfoErr";
            errorMessage = (!!errorMessage) ? errorMessage : $scope.licenseInfoConfig.ValidationMsg.APIError;
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            var propExist = false;
            for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                if ($scope.natValidation.errorMessages[i].propName == propName)
                    propExist = true;
            }
            if (!propExist)
                $scope.natValidation.errorMessages.push({
                    propName: propName,
                    errorMessage: errorMessage
                });

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.loadTypeahed = function (searchString, searchType) {
            return personalService.getEmployerList(
                sessionStorage.Key, searchString, 5, searchType
            ).then(function (response) {
                if (!!response.Employers) {
                    return response.Employers.map(function (item) {
                        item.formattedResult = (item.EmployerName + " " + item.StreetLine1 + " " + item.StreetLine2 + " " + item.City + " " + item.Zip);
                        return item;
                    });
                }
            }, function (error) {
                $scope.showStatusMessage({ messageContent: response.Message, messageType: 'error' });
                HideLoader();
            });
        };

        $scope.SelectTypeAheadItem = function (selectedItem, $model, $label, $event) {
            $scope.DisableContactDetails = true;
            $scope.businessAddress.NameonBusinessLicense = selectedItem.EmployerName;
            $scope.businessAddress.EmployerName = selectedItem.EmployerName;
            $scope.businessAddress.BusinessLicenseNumber = selectedItem.LicenseNumber;
        };

        $scope.saveLicenseInfo = function (currentForm) {
            try {
                ShowLoader("Please wait...");
                if (!validateForm(currentForm)) {
                    if (!$scope.isRenewal) {
                        HideLoader();
                    } else {
                        initialApplicationstatusService.setLicense('invalid');
                    }
                    return;
                }
                // if ($scope.licenseInfo != null) {
                // if ($scope.isRenewal) {
                //     angular.forEach($scope.licenseInfo, function(licenseInfoOption, key) {
                //         licenseInfoOption.IndividualLicenseId = $scope.individualLicense.IndividualLicenseId;
                //     });
                // }
                // initialApplicationLicenseInfoService.saveLicenseInfo($scope.licenseInfo, sessionStorage.Key)
                //     .then(function(response) {
                //         if (response.Status == false) {
                //             initialApplicationstatusService.setLicense('error');
                //             HideLoader();
                //             $scope.showStatusMessage({ messageContent: response.Message, messageType: 'error' });
                //         } else {
                //             initialApplicationLicenseInfoService.saveBusinessAddress([$scope.businessAddress], sessionStorage.Key)
                //                 .then(function(responseAdd) {
                //                     if (responseAdd.Status == false) {
                //                         ShowErrMsg("unableToSaveBusinessAddress", responseAdd.Message);
                //                     } else {
                $scope.acknowledgement.IndividualId = $scope.individualId;
                $scope.acknowledgement.ApplicationId = $scope.applicationId;
                initialApplicationAcknowledgementService.acknowledgementSave($scope.acknowledgement, $scope.UserId, sessionStorage.Key)
                    .then(function (responseAck) {
                        if (responseAck.Status) {
                            if (!$scope.isRenewal) {
                                $scope.openNextTab({ currentTab: $scope.tab });
                            } else {
                                initialApplicationstatusService.setLicense('success');
                            }
                        } else {
                            initialApplicationstatusService.setLicense('error');
                            $scope.showStatusMessage({ messageContent: error, messageType: 'error' });
                        }
                        if (!$rootScope.ProcessLoaderExternally)
                            HideLoader();
                    }, function (error) {
                        initialApplicationstatusService.setLicense('error');
                        HideLoader();
                        $scope.showStatusMessage({ messageContent: error, messageType: 'error' });
                    });
                //                 }
                //             }, function(data) {
                //                 initialApplicationstatusService.setLicense('error');
                //                 HideLoader();
                //                 $scope.showStatusMessage({ messageContent: error, messageType: 'error' });
                //             });
                //     }
                // }, function(error) {
                //     initialApplicationstatusService.setLicense('error');
                //     HideLoader();
                //     $scope.showStatusMessage({ messageContent: error, messageType: 'error' });
                // });
                // };
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
                $exceptionHandler(ex);
            }
        };

        $scope.$on("companyLicenseAndNameChangedParent", function (event, args) {
            console.log(args);
            $scope.businessAddress.NameonBusinessLicense = args.NameonBusinessLicense;
            $scope.businessAddress.BusinessLicenseNumber = args.BusinessLicenseNumber;
        });

        $scope.$on("SaveRenewal", function (event, args) {
            if ($scope.individualLicense.applicationType != 'retired') {
                $scope.saveLicenseInfo($scope.currentForm);
            }
        });

        function AuditVisitInfoSave() {
            var data = {
                "PageName": $scope.licenseInfoConfig.Content.TabHeading,
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        }

        $scope.init();
    }
})();