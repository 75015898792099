(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('FingerprintConfirmationService', ['$http', '$q', 'WebApiUrl', FingerprintConfirmationService]);

    function FingerprintConfirmationService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
                .then(function (res) {
                    if (success) {
                        success(res);
                    }
                    deferred.resolve(res.data);
                }, function (error) {
                    if (fail) {
                        fail(error);
                    }
                    console.log('Request failed with status: ' + error.status);
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getConfirmations = function (key, entityId, entityType, applicationId, individualLicenseId, contentItemLkId) {
            var urlParams = {
                EntityType: (!!entityType) ? entityType : null,
                EntityId: (!!entityId) ? entityId : null,
                ApplicationId: (!!applicationId) ? applicationId : null,
                LicenseId: (!!individualLicenseId) ? individualLicenseId : null,
                ContentItemLkId: (!!contentItemLkId) ? contentItemLkId : null
            };
            return responseHandler($http.get(WebApiUrl + 'Entity/EntityConfirmation_Get/' + key + '/', {
                params: urlParams
            }));
        };

        var _saveConfirmations = function (key, confirmations) {
            return responseHandler($http.post(WebApiUrl + 'Entity/EntityConfirmation_Save/' + key + '/', confirmations));
        };

        return {
            getConfirmations: _getConfirmations,
            saveConfirmations: _saveConfirmations
        };
    }
})();