(function() {
    'use strict'

    angular.module('natApp').factory('activityLoggingService', ['$http', '$q', 'WebApiUrl', 'AppConfig', activityLoggingService]);

    function activityLoggingService($http, $q, WebApiUrl, AppConfig) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log("Request failed with status: " + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _logActivity = function (key, model) {
            if (!!model.IndividualId) {
                var request = {
                    ModelData: model,
                    IndividualDocumentUpload: null
                };
                return responseHandler($http.post(WebApiUrl + 'Individual/IndividualCommentLogSave/' + key + '/?Source=' + AppConfig.Source, request));
            } else if (!!model.ProviderId) {
                var request = {
                    ModelData: model,
                    ProviderDocumentUpload: null
                };
                return responseHandler($http.post(WebApiUrl + 'Provider/ProviderCommentLogSave/' + key + '/', request));
            }
        };

        var _logAuditInfo = function (key, model) {
            return responseHandler($http.post(WebApiUrl + 'Logging/AuditvisitInfoSave/' + ((!!key) ? key : 'public') + '/', model))
        };

        return {
            logActivity: _logActivity,
            logAuditInfo: _logAuditInfo
        };
    };
})();