(function () {
    'use strict'

    angular.module('app.enforcement')
        .controller('ComplaintDocumentsController', ['$scope', '$rootScope', '$q', '$timeout', 'ComplaintDocumentsService', 'utilityService', 'configurationService', 'SettingsValue',
            ComplaintDocumentsController]);

    function ComplaintDocumentsController($scope, $rootScope, $q, $timeout, ComplaintDocumentsService, utilityService, configurationService, SettingsValue) {
        var _key = sessionStorage.Key;

        $scope.isBackoffice = sessionStorage.isBackOfficeUser == 'true' || sessionStorage.isBackOfficeUser == true;

        $scope.acceptPattern = "*";

        configurationService.configurationGetbySettings("0", SettingsValue.AllowedDocTypesForOnline).then(function (data) {
            if (!!data && !!data.ConfigurationList && data.ConfigurationList.length > 0) {
                $scope.acceptPattern = data.ConfigurationList[0].Value;
            }
        });

        $scope.invalidFiles = [];
        $scope.closeFileError = function () {
            $scope.invalidFiles = [];
        };

        var getNewDocument = function (fileName, base64Contents) {
            var newDoc = {
                ComplaintDocumentId: null,
                ComplaintId: $scope.complaint.ComplaintId,
                ComplaintInvolvedPartyId: $scope.complaintInvolvedPartyId,
                EntityId: $scope.entityId,
                EntityType: $scope.entityType,
                ApplicationId: $scope.applicationId,
                DocumentLkToPageTabSectionId: 0,
                DocumentLkToPageTabSectionCode: '',
                DocumentTypeName: '',
                ComplaintDocumentStatusId: null,
                EffectiveDate: null,
                EndDate: null,
                IsDocumentUploadedbyEntity: !$scope.isBackoffice,
                IsDocumentUploadedbyStaff: $scope.isBackoffice,
                ReferenceNumber: '',
                IsActive: true,
                IsDeleted: true,
                ComplaintDocumentGuid: '',
                DocumentPath: '',
                DocumentId: null,
                DocumentCd: null,
                DocumentTypeId: null,
                DocumentName: fileName,
                OtherDocumentTypeName: '',
                IsDocumentGeneratedBySystem: false,
                DocumentUploadedForId: null,
                LinkId: $scope.linkId,
                LinkType: $scope.linkType,
                IsPublic: false,

                DocStrBase64: base64Contents
            };

            if (!!$scope.autoSelectFirst) {
                newDoc.documentType = $scope.documentTypes.firstOrDefault();
                $scope.documentTypeChanged(newDoc);
            }

            return newDoc;
        };

        $scope.getUIConfig = function () {
            var config = {};

            console.log($scope.configName);
            for (var prop in $scope.config.UIConfigs['Default']) {
                if (!$scope.config.UIConfigs['Default'].hasOwnProperty(prop)) { continue; }

                config[prop] = $scope.config.UIConfigs['Default'][prop];
            }

            if (!!$scope.config.UIConfigs[$scope.configName]) {
                for (var prop in $scope.config.UIConfigs[$scope.configName]) {
                    if (!$scope.config.UIConfigs[$scope.configName].hasOwnProperty(prop)) { continue; }

                    config[prop] = $scope.config.UIConfigs[$scope.configName][prop];
                }
            }

            return config;
        };

        $scope.getConfig = function () {
            var deferred = $q.defer();

            try {
                utilityService.getConfigData('/components/backoffice/enforcement/complaint-documents/complaint-documents.server-config.json')
                .then(function (data) {
                    if (!!data) {
                        $scope.config = data;

                        $scope.uiConfig = $scope.getUIConfig();

                        $scope.isConfigLoaded = true;
                    }
                    deferred.resolve(data);
                }, function (err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getDocuments = function () {
            var deferred = $q.defer();

            try {
                //It won't work unless the key is there
                if (!sessionStorage.Key) {
                    deferred.resolve({});
                    return deferred.promise;
                }

                var request = {
                    ComplaintId: $scope.complaint.ComplaintId,
                    ComplaintInvolvedPartyId: $scope.complaintInvolvedPartyId,
                    EntityId: $scope.entityId,
                    EntityType: $scope.entityType,
                    ApplicationId: $scope.applicationId,
                    DocId: $scope.docId,
                    DocCode: $scope.docCode,
                    LinkType: $scope.linkType,
                    LinkId: $scope.linkId,
                    IsPublicOnly: $scope.onlyLoadPublic
                };
                ComplaintDocumentsService.getDocuments(sessionStorage.Key, request)
                .then(function (data) {
                    if (data.Status) {
                        $scope.documentsList = data.ComplaintDocumentList;
                        $scope.pagination.setTotalRecords(data.ComplaintDocumentList.length);
                    } else {
                        $scope.documentsList = [];
                        $scope.pagination.setTotalRecords(0);
                    }
                    if (!!$scope.getDocumentsExt) {
                        $scope.getDocumentsExt();
                    }
                    deferred.resolve(data);
                }, function (err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getDocumentsFromPublic = function () {
            var deferred = $q.defer();

            try {
                //It won't work unless the key is there
                if (!sessionStorage.Key) {
                    deferred.resolve({});
                    return deferred.promise;
                }

                var request = {
                    ComplaintId: $scope.complaint.ComplaintId,
                    ComplaintInvolvedPartyId: $scope.complaintInvolvedPartyId,
                    EntityId: $scope.entityId,
                    EntityType: $scope.entityType,
                    ApplicationId: $scope.applicationId,
                    DocId: $scope.docId,
                    DocCode: $scope.docCode,
                    LinkType: $scope.linkType,
                    LinkId: $scope.linkId,
                    IsPublicOnly: true
                };
                ComplaintDocumentsService.getDocumentsFromPublic(sessionStorage.Key, request)
                .then(function (data) {
                    if (data.Status) {
                        $scope.documentsList = data.ComplaintDocumentList;
                        $scope.pagination.setTotalRecords(data.ComplaintDocumentList.length);
                    } else {
                        $scope.documentsList = [];
                        $scope.pagination.setTotalRecords(0);
                    }
                    if (!!$scope.getDocumentsFromPublicExt) {
                        $scope.getDocumentsFromPublicExt();
                    }
                    deferred.resolve(data);
                }, function (err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.uploadDocuments = function (documents) {
            var deferred = $q.defer();

            documents.forEach(function (doc) {
                if (!doc.DocumentId && !!$scope.documentTypes && $scope.documentTypes.length == 1) {
                    doc.documentType = $scope.documentTypes.firstOrDefault();
                    $scope.documentTypeChanged(doc);
                }
            });

            try {
                ComplaintDocumentsService.uploadDocuments(sessionStorage.Key, documents)
                .then(function (data) {
                    if (data.Status) {
                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                }, function (err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.$watch('invalidFiles', function (newVal) {
            if (!!newVal && newVal.length > 0) {
                $scope.showStatusMsg('-', newVal.select(function (i) {
                    if (i.$error == 'pattern') {
                        return i.name + ' is an invalid file type.';
                    } else {
                        return i.name + ' is too large (max ' + $scope.maxFileSize + ').';
                    }
                }));
            }
        });

        $scope.downloadDocument = function (document) {
            var deferred = $q.defer();

            try {
                ComplaintDocumentsService.downloadDocument(sessionStorage.Key, document.ComplaintDocumentId)
                .then(function (data) {
                    if (!data.Status && !!data.StatusCode) {
                        deferred.reject(data);
                    } else {
                        deferred.resolve(data);
                    }
                }, function (err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.downloadDocumentFromPublic = function (document) {
            var deferred = $q.defer();

            try {
                ComplaintDocumentsService.downloadDocumentFromPublic(sessionStorage.Key, document.ComplaintDocumentId)
                .then(function (data) {
                    if (!data.Status && !!data.StatusCode) {
                        deferred.reject(data);
                    } else {
                        deferred.resolve(data);
                    }
                }, function (err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.deleteDocument = function (document) {
            var deferred = $q.defer();

            try {
                ComplaintDocumentsService.deleteDocument(sessionStorage.Key, document.ComplaintDocumentId)
                .then(function (data) {
                    if (data.Status) {
                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                }, function (err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getDocumentTypes = function () {
            var deferred = $q.defer();

            try {
                ComplaintDocumentsService.getDocumentTypes(sessionStorage.Key, $scope.docId, $scope.docCode)
                .then(function (data) {
                    if (data.Status) {
                        $scope.documentTypes = data.DocumentMasterGET;

                        deferred.resolve(data);
                    } else {
                        deferred.reject(data);
                    }
                }, function (err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getDocumentsClicked = function () {
            $scope.operationPending(true, 'Loading...');

            var getDocPromise = null;
            if (!!$scope.onlyLoadPublic) {
                getDocPromise = $scope.getDocumentsFromPublic();
            } else {
                getDocPromise = $scope.getDocuments();
            }

            getDocPromise
            .then(function (data) {
                $scope.operationPending(false);
            }, function (err) {
                $scope.showStatusMsg('-', err);
                $scope.operationPending(false);
            });
        };

        var resetForm = function (form) {
            $scope.current = {
                documentsList: []
            };

            if (!!$scope.documentsList) {
                $scope.documentsList.forEach(function (doc) {
                    doc.isEditing = false;
                });
            }

            if (!!form) {
                form.$setPristine();
                form.errorMessages = [];
            }

            $scope.isEditing = false;
        };

        $scope.uploadDocumentsClicked = function (form) {
            $scope.hideStatusMsg();

            if (!$scope.validateForm(form)) {
                return;
            }

            $rootScope.displayMainLoader(true, 'Uploading Documents...');

            $scope.uploadDocuments($scope.current.documentsList)
            .then(function (data) {
                resetForm(form);

                $rootScope.displayMainLoader(false);
                $scope.getDocumentsClicked();
            }, function (err) {
                $scope.showStatusMsg('-', err);
                $rootScope.displayMainLoader(false);
            });
        };

        $scope.resetClicked = function (form) {
            if (!!$scope.hideStatusMsg) {
                $scope.hideStatusMsg();
            }

            if (!!$scope.resetExt) {
                $scope.resetExt(form);
            }

            resetForm(form);
        };

        $scope.downloadDocumentClicked = function (document) {
            $scope.hideStatusMsg();

            $rootScope.displayMainLoader(true, 'Starting Download...');

            var downloadDocPromise = null;
            if (!!$scope.onlyLoadPublic) {
                downloadDocPromise = $scope.downloadDocumentFromPublic(document);
            } else {
                downloadDocPromise = $scope.downloadDocument(document);
            }

            downloadDocPromise
            .then(function (data) {
                $rootScope.displayMainLoader(false);
            }, function (err) {
                $scope.showStatusMsg('-', err);
                $rootScope.displayMainLoader(false);
            });
        };

        $scope.deleteDocumentClicked = function (document) {
            $scope.hideStatusMsg();

            if (!confirm('Are you sure you want to delete this document?')) { return; }

            $scope.operationPending(true, 'Deleting Document...');

            $scope.deleteDocument(document)
            .then(function (data) {
                resetForm();

                $scope.getDocumentsClicked();
            }, function (err) {
                $scope.showStatusMsg('-', err);
                $scope.operationPending(false);
            });
        };

        $scope.editDocumentClicked = function (document) {
            $scope.isEditing = true;
            if (!document.isEditing) {
                document.isEditing = true;

                var docCopy = angular.copy(document);

                if (!!docCopy.DocumentId && !!docCopy.DocumentTypeId) {
                    docCopy.documentType = $scope.documentTypes
                        .whereEquals(document.DocumentId, 'DocumentId')
                        .whereEquals(document.DocumentTypeId, 'DocumentTypeId')
                        .firstOrDefault();
                    $scope.documentTypeChanged(docCopy);
                } else if (!docCopy.DocumentId && !!$scope.documentTypes && $scope.documentTypes.length == 1) {
                    docCopy.documentType = $scope.documentTypes.firstOrDefault();
                    $scope.documentTypeChanged(docCopy);
                }

                $scope.current.documentsList.push(docCopy);
            }
        };

        $scope.deleteTemporaryDocumentClicked = function (document, form) {
            $scope.hideStatusMsg();

            if (!!form) {
                form.$setPristine();
                form.errorMessages = [];
            }

            if (!!$scope.deleteTemporaryDocumentExt) {
                $scope.deleteTemporaryDocumentExt(document, form);
            }

            $scope.current.documentsList.conditionalSplice('item==this', document);

            if ($scope.current.documentsList.length == 0) {
                resetForm(form);
            }
        };

        $scope.addDocuments = function (docs, invalidFiles) {
            $scope.hideStatusMsg();
            var docPromises = [];

            if (!!docs && !!docs.length) {
                docs.forEach(function (doc) {
                    var reader = new FileReader();
                    var deferred = $q.defer();

                    reader.onload = function () {
                        var currentFileName = doc.name;
                        var documentBase64 = '';
                        var urlRegex = /.*base64,(.*)/gi;
                        var match = urlRegex.exec(reader.result);
                        var errMsg = '';

                        if (!!match) {
                            documentBase64 = match[1];

                            $scope.current.documentsList.push(getNewDocument(currentFileName, documentBase64));
                        } else {
                            errMsg = 'The selected file {0} is either empty or unable to be read.'.format(currentFileName);
                        }
                        deferred.resolve({ isSuccess: (!errMsg), error: errMsg });
                    };

                    docPromises.push(deferred.promise);
                    reader.readAsDataURL(doc);
                });
            }

            if (!!invalidFiles && invalidFiles.length > 0) {
                for (var i = 0; i < invalidFiles.length; i++) {
                    var deferred = $q.defer();
                    var errMsg = '';
                    if (invalidFiles[i].$error == 'maxSize') {
                        errMsg = 'File {0} exceeds maximum allowed size of {1}'.format(invalidFiles[i].name, invalidFiles[i].$errorParam);
                    } else if (invalidFiles[i].$error == 'pattern') {
                        errMsg = 'File {0} is not an allowed file type'.format(invalidFiles[i].name);
                    } else {
                        errMsg = 'File {0} encountered the following error: {1}'.format(invalidFiles[i].name, invalidFiles[i].$error);
                    }
                    deferred.resolve({ isSuccess: false, error: errMsg });
                    docPromises.push(deferred.promise);
                }
            }

            $q.all(docPromises)
            .then(function (results) {
                var errMsgs = results.where('!item.isSuccess').select('error');

                if (!!$scope.addDocumentsExt) {
                    $scope.addDocumentsExt();
                }

                $scope.showStatusMsg('-', errMsgs);
            });
        };

        $scope.documentTypeChanged = function (document) {
            //$scope.hideStatusMsg();

            if (!!document.documentType) {
                document.DocumentId = document.documentType.DocumentId;
                document.DocumentCd = document.documentType.DocumentCd;
                document.DocumentTypeId = document.documentType.DocumentTypeId;
                document.DocumentTypeName = document.documentType.DocumentTypeIdName;
            }
        };

        $scope.init = function () {
            resetForm();

            $scope.pagination = {};

            if (typeof ($scope.showUploadSection) == 'undefined') {
                $scope.showUploadSection = true;
            }
            if (typeof ($scope.allowEdit) == 'undefined') {
                $scope.allowEdit = true;
            }
            if (typeof ($scope.allowDelete) == 'undefined') {
                $scope.allowDelete = true;
            }

            if (!!$scope.initExt) {
                $scope.initExt();
            }

            $scope.getConfig()
            .then(function () {
                $q.all([
                    $scope.getDocumentsClicked(),
                    $scope.getDocumentTypes()
                ])
                    .then(function () {
                        $scope.operationPending(false);
                    }, function (err) {
                        $scope.showStatusMsg('-', err);
                        $scope.operationPending(false);
                    });
            }, function (err) {
                $scope.showStatusMsg('-', err);
                $scope.operationPending(false);
            });
        };

        var removeDocListWatch = $scope.$watch('current.documentsList', function (newVal) {
            $scope.newDocumentsList = newVal;
        }, true);

        $scope.$on('$destroy', function () {
            removeDocListWatch();
        });

        $scope.$on('complaintClearForms', function () {
            resetForm();
            $scope.documentsList = [];
        });
        $scope.$on('complaintUploadDocuments', function (event, callback) {
            var invokeCallback = function (status) {
                if (!!callback && typeof (callback) == 'function') {
                    callback(
                        {
                            Status: status
                        }
                    );
                }
            };

            $scope.hideStatusMsg();

            if (!$scope.validateUpload()) {
                invokeCallback(false);
            }

            $scope.uploadDocuments($scope.current.documentsList)
                .then(function (data) {
                    resetForm();

                    invokeCallback(true);
                }, function (err) {
                    $scope.showStatusMsg('-', err);
                });
        });
        $scope.$on('complaintSetForm', function (event, form) {
            $scope.setForm(form);
        });
    }
})();
