(function() {
    'use strict'
    angular.module('app.core')
        .controller('DashboardPaymentController', ['$scope', '$controller', 'DashboardPaymentFactory', '$uibModal', '$q', 'feePaymentService', 'initialLicenseApplicationService', '$rootScope', '$window', 'Messages',
            function($scope, $controller, DashboardPaymentFactory, $uibModal, $q, feePaymentService, initialLicenseApplicationService, $rootScope, $window, Messages) {
                $controller('GlobalController', {
                    $scope: $scope
                });
                var _Key = '';
                var _UserId = '';
                // page init method
                $scope.validator = {
                    feePayment: {}
                };
                $scope.paymentTab = {
                    name: "feeAndPayment",
                    text: "Fee and Payment",
                    tabNumber: 100,
                };

                $scope.isBackOfficeUser = (!!sessionStorage.isBackOfficeUser && (sessionStorage.isBackOfficeUser == true || sessionStorage.isBackOfficeUser == 'true'));
                $scope.init = function() {
                    try {

                        if ($scope.isSessionActive()) {
                            _Key = sessionStorage.Key;
                            _UserId = ((!!sessionStorage.UserID) ? sessionStorage.UserID : sessionStorage.UserId);
                            if (!$scope.individualId)
                                $scope.individualId = sessionStorage.IndividualId;
                            if (!!sessionStorage.ProviderId){
                                $scope.providerId = sessionStorage.ProviderId;
                                $scope.isprovider = true;
                            }
                            getPaymentDetails();
                        } else {
                            $state.go('app.Loginwoemail');

                        }
                    } catch (ex) {
                        showStatusMessage(ex.message, "error");
                    }
                };

                $scope.$on("loadInvoicePayments", function(event, args) {
                    getPaymentDetails();
                });

                var isUnPaidMiscAppInvoiceExists = function(paymentDetails) {
                    var unPaidMiscAppInvoiceExists = false;
                    angular.forEach(paymentDetails, function(paymentDetailItem) {
                        if (paymentDetailItem.TransType == 'MISCAPP' && paymentDetailItem.InvoiceStatus == 'OPEN') {
                            unPaidMiscAppInvoiceExists = true;
                        }
                    });
                    return unPaidMiscAppInvoiceExists;
                };

                var combinePaymentDtlsForSameApp = function() {
                    angular.forEach($scope.PaymentDetails, function(paymentDetailParent) {
                        angular.forEach($scope.PaymentDetails, function(paymentDetailChild, index) {
                            if (paymentDetailChild.ApplicationID == paymentDetailParent.ApplicationID && paymentDetailChild.LicenseNumber != paymentDetailParent.LicenseNumber) {
                                paymentDetailParent.LicenseNumber = paymentDetailParent.LicenseNumber + ', ' + paymentDetailChild.LicenseNumber;
                                paymentDetailParent.LicenseTypeName = paymentDetailParent.LicenseTypeName + ', ' + paymentDetailChild.LicenseTypeName;
                                $scope.PaymentDetails.splice(index, 1);
                            }
                        })
                    });
                };

                var getPaymentDetails = function() {
                    ShowLoader();
                    $scope.licenseTypes = [];

                    $q.all([
                            DashboardPaymentFactory.GetPaymentDetails(_Key, $scope.individualId, $scope.providerId, $scope.isprovider),
                            DashboardPaymentFactory.GetLicenseTypes(_Key)
                        ])
                        .then(function(res) {
                            HideLoader();
                            if (res[1].data.Status) {
                                $scope.licenseTypes = null;
                                $scope.licenseTypes = res[1].data.ReferenceEntities;
                            }

                            if (res[0].data.Status && !!res[0].data.PaymentAllDetails) {
                                $scope.PaymentDetails = res[0].data.PaymentAllDetails;
                                angular.forEach($scope.PaymentDetails, function(paymentDetail) {
                                    angular.forEach($scope.licenseTypes, function(licenseType) {
                                        if (paymentDetail.LicenseTypeId == licenseType.LicenseTypeId) {
                                            paymentDetail.LicenseTypeName = licenseType.LicenseTypeName;
                                        }
                                    })
                                });
                                combinePaymentDtlsForSameApp();
                                $scope.unPaidMiscAppInvoiceExists = isUnPaidMiscAppInvoiceExists($scope.PaymentDetails);
                            } else {
                                $scope.PaymentDetails = null;
                            }

                        }, function(error) {
                            HideLoader();
                        });
                };

                $scope.ShowPayment = function(item) {
                    if (item) {
                        var win = $window.open("", "Payment Details", "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600, top=" + (100) + ", left=" + 240);
                        var printButtonStyle = '\n<link href="{0}/app/core/directive/dashboard-payment-directive/dashboard-payment.css" rel="stylesheet" />\n'.format(window.origin);
                        var printButton = '\n<button class="print-button no-print" onclick="window.print()">Print</button>\n';
                        win.document.head.innerHTML = printButtonStyle
                        win.document.body.innerHTML = item.HTMLContent ? printButton + item.HTMLContent : '';
                    }
                };

                $scope.payInvoice = function(invoice) {
                    ShowLoader();

                    feePaymentService.getInvoiceDetailByIndividualIdAndInvoiceId(sessionStorage.Key, $scope.individualId, invoice.InvoiceID)
                        .then(function(objInvoiceDetail) {
                            HideLoader();
                            if (objInvoiceDetail.Status) {
                                $scope.feeDetails = {};
                                $scope.feeDetails.totalFee = 0;
                                $scope.feeDetailRows = [];
                                angular.forEach(objInvoiceDetail.InvoiceDetails, function(item) {
                                    var regexp = /Misc-(\d+)/gi;
                                    var matches = regexp.execAll(item.ReferenceNumber);
                                    var feeDetailId = '0';
                                    if (!!matches[0] && !!matches[0][1]) {
                                        feeDetailId = matches[0][1];
                                    }
                                    $scope.feeDetailRows.push({
                                        Amount: item.Amount,
                                        feeDetailType: {
                                            FeeDetailId: feeDetailId,
                                            FeeDetailDesc: item.Description,
                                            FeeTypeCode: item.TransCode,
                                            BoardInfoId: $scope.boardInfoId,
                                            FeeAmount: item.Amount,
                                            FeeDetailCode: item.TransCode,
                                            ReferenceNumber: item.ReferenceNumber
                                        }
                                    });
                                    $scope.feeDetails.totalFee = $scope.feeDetails.totalFee + item.Amount;
                                });
                                $scope.feeDetails.InvoiceId = invoice.InvoiceID;
                                $scope.invoiceMaster = invoice;
                                $scope.ApplicationId = invoice.ApplicationID;
                                initialLicenseApplicationService.getApplicationInfoByApplicationId($scope.ApplicationId, _UserId, sessionStorage.Key)
                                    .then(function(response) {
                                        if (response.Status) {
                                            $scope.applicationInfo = response.ApplicationResponseList[0];
                                            $scope.proceedToPayment = true;
                                            $scope.modalInstance = $uibModal.open({
                                                animation: true,
                                                ariaLabelledBy: 'modal-title',
                                                ariaDescribedBy: 'modal-body',
                                                templateUrl: 'invoicePaymentModal.html',
                                                size: 'lg',
                                                scope: $scope,
                                                backdropClass: 'modalbackdropstatic'
                                            });
                                        }
                                    }, function(error) {
                                        HideLoader();
                                        showStatusMessage(error, 'error');
                                    });
                            } else {
                                showStatusMessage(objInvoiceDetail.Message, 'error');
                                getPaymentDetails();
                            }
                        }, function(error) {
                            HideLoader();
                        });
                };
                $scope.paymentStatusUpdate = function(action) {
                    if (action == 'success') {
                        HideLoader();
                    } else if (action == 'close') {
                        $scope.proceedToPayment = false;
                        $scope.modalInstance.close();
                        getPaymentDetails();
                    }
                };

                $scope.closePayment = function() {
                    $scope.proceedToPayment = false;
                    $scope.modalInstance.close();
                    getPaymentDetails();
                };

                $scope.deleteInvoice = function(invoice) {
                    if (confirm(Messages.General.Confirm.DeleteRecord)) {
                        ShowLoader();
                        invoice.InvoiceStatus = 'DELETED';
                        feePaymentService.invoiceMasterSave(sessionStorage.Key, [invoice]).then(function(response) {

                            getPaymentDetails();
                        }, function(error) {
                            HideLoader();
                        });
                    }
                }


            }
        ]);
})();