(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .filter('applicationPathwayFilter', [function () {
            return function (pathwayList, applicationSubType) {
                return pathwayList.whereEquals(applicationSubType, 'ApplicationSubTypeId');
            };
        }])
        .controller('ApplicationTypeSelectionController', ['$scope', '$q', 'utilityService', 'licenseApplicationListingService', 'backofficeIndividualLicenseService', 'LicenseSettings', ApplicationTypeSelectionController]);

    function ApplicationTypeSelectionController($scope, $q, utilityService, licenseApplicationListingService, backofficeIndividualLicenseService, LicenseSettings) {

        $scope.$dataLoaded = function () { };
        $scope.$canCreateApplication = function () {
            return true;
        };
        $scope.$validateAppTypeSelection = function () { };


        $scope.init = function () {
            $scope.applicationType = {
                ApplicationSubTypeId: null,
                LicenseApplicationForId: null,
                LicenseTypeId: null,
                AllowsTemporary: null,
                LicenseByReciprocity: null,
                IsTemporary: null
            };

            getConfigData();
        };

        $scope.licenseAlreadyExists = function () {
            return !!$scope.individual &&
                !!$scope.individual.existingLicenseTypes &&
                $scope.individual.existingLicenseTypes.includes($scope.applicationType.LicenseTypeId) &&
                !!$scope.config &&
                !!$scope.config.LicenseTypeIdsAllowedMultiple &&
                !$scope.config.LicenseTypeIdsAllowedMultiple.includes($scope.applicationType.LicenseTypeId);
        };

        $scope.clearApplicationTypeSelection = function () {
            $scope.applicationType.ApplicationSubTypeId = null;
            $scope.applicationType.LicenseApplicationForId = null;
            $scope.applicationType.LicenseTypeId = null;
            $scope.applicationType.AllowsTemporary = null;
            $scope.applicationType.LicenseByReciprocity = null;
            $scope.applicationType.IsTemporary = null;

            $scope.hideStatusMsg();
        };

        $scope.clearLicenseApplicationForSelection = function () {
            $scope.applicationType.LicenseApplicationForId = null;
            $scope.applicationType.LicenseTypeId = null;
            $scope.applicationType.AllowsTemporary = null;
            $scope.applicationType.LicenseByReciprocity = null;
            $scope.applicationType.IsTemporary = null;

            $scope.hideStatusMsg();
        };

        $scope.applicationTypeChanged = function (levelData, levelType) {
            if (levelType == 'subType') {
            } else if (levelType == 'appFor') {
                $scope.applicationType.ApplicationSubTypeId = levelData.ApplicationSubTypeId;
                $scope.applicationType.LicenseApplicationForId = levelData.LicenseApplicationForId;
                //levelData = $scope.licenseApplicationFor;
                $scope.applicationType.LicenseTypeId = levelData.LicenseTypeId;
                $scope.applicationType.AllowsTemporary = $scope.config.LicenseTypeIdsAllowedTemporary.includes(levelData.LicenseTypeId);
                $scope.applicationType.LicenseByReciprocity = $scope.config.LicenseTypeIdsAllowedReciprocity.includes(levelData.LicenseTypeId);
                if ($scope.applicationType.AllowsTemporary) {
                    $scope.applicationType.IsTemporary = null;
                } else {
                    $scope.applicationType.IsTemporary = false;
                }

                if ($scope.applicationType.LicenseByReciprocity) {
                    $scope.applicationType.StateId = null;
                } else {
                    $scope.applicationType.StateId = null;
                }
            }

            if ($scope.licenseAlreadyExists()) {
                $scope.showStatusMsg('-', 'User already has a credential of that type');
            }

            $scope.$validateAppTypeSelection();
        };

        $scope.canCreateApplication = function () {
            return !!$scope.applicationType &&
                !!$scope.applicationType.ApplicationSubTypeId &&
                !!$scope.applicationType.LicenseApplicationForId &&
                !!$scope.applicationType.LicenseTypeId &&
                (!$scope.applicationType.AllowsTemporary || (!!$scope.applicationType.AllowsTemporary && $scope.applicationType.IsTemporary != null)) &&
                (!$scope.applicationType.LicenseByReciprocity || (!!$scope.applicationType.LicenseByReciprocity && $scope.applicationType.StateId != null)) &&
                $scope.$canCreateApplication() &&
                !$scope.licenseAlreadyExists();
        };

        var validateApplicationTypeForm = function () {
            if (!$scope.applicationType.ApplicationSubTypeId) {
                $scope.showStatusMsg("Please select an application type", "error");
                return false;
            } else if (!$scope.applicationType.LicenseApplicationForId) {
                $scope.showStatusMsg("Please select an application sub-type", "error");
                return false;
            }
            return true;
        };

        var getConfigData = function () {
            var dataPromise = [];
            dataPromise.push(utilityService.getConfigData('/components/backoffice/application/application-type-selection/application-type-selection.server-config.json'));
            dataPromise.push(licenseApplicationListingService.getRefTablesGetAll(sessionStorage.Key, 'applicationsubtype'));
            dataPromise.push(licenseApplicationListingService.getRefTablesGetAll(sessionStorage.Key, 'licenseapplicationfortype'));
            dataPromise.push(licenseApplicationListingService.getRefTablesGetAll(sessionStorage.Key, 'licensetype'));
            dataPromise.push(licenseApplicationListingService.getRefTablesGetAll(sessionStorage.Key, 'licenseapplicationfor'));
            if (!!$scope.individual) {
                dataPromise.push(backofficeIndividualLicenseService.individualLicenseDetailBYIndividualId(sessionStorage.Key, $scope.individual.IndividualId));
            }

            $q.all(dataPromise)
                .then(function (res) {
                    var status = true;
                    res.forEach(function (response) {
                        if (typeof (response.StatusCode) != 'undefined' && response.StatusCode != '00') {
                            status = false;
                        }
                    });
                    if (status) {
                        $scope.config = res[0];
                        $scope.initialLicenseApplicationTypes = res[1].ReferenceEntities;
                        $scope.initialLicenseApplicationForTypes = res[2].ReferenceEntities;
                        $scope.refLicenseTypes = res[3].ReferenceEntities;

                        if ($scope.initialLicenseApplicationForTypes && $scope.initialLicenseApplicationForTypes.length > 0 &&
                            $scope.refLicenseTypes && $scope.refLicenseTypes.length > 0) {
                            $scope.initialLicenseApplicationForTypes.map(function (initialLicenseApplicationForType) {
                                initialLicenseApplicationForType.refLicenseType = $scope.refLicenseTypes.whereEquals(initialLicenseApplicationForType.LicenseTypeId, 'LicenseTypeId').firstOrDefault();
                            });
                        }

                        $scope.initialLicenseApplicationSubTypes = res[4].ReferenceEntities;
                        if (!!$scope.individual && res[5].IndividualLicenseList) {
                            $scope.individual.existingLicenses = res[5].IndividualLicenseList;
                            $scope.individual.existingLicenseTypes = res[5].IndividualLicenseList.whereIncludes(LicenseSettings.ActivePendingStatuses, 'LicenseStatusTypeId').select('LicenseTypeId');
                        } else if (!!$scope.individual) {
                            $scope.individual.existingLicenses = [];
                            $scope.individual.existingLicenseTypes = [];
                        }
                    }

                    $scope.$dataLoaded();
                    $scope.operationPending(false);
                }, function (error) {
                    console.log(error);
                });
        };

        $scope.createApplication = function () {
            if (validateApplicationTypeForm()) {
                $scope.applicationTypeSelected({
                    $appType: $scope.applicationType
                });
            }
        };
    }
})();
