﻿(function () {
    'use strict'
    angular.module('app.login')
        .factory('FirmLoginService', ['$http', '$q', 'WebApiUrl', function ($http, $q, WebApiUrl) {
            var baseFactory = {};
            baseFactory.firmLogin = function () {

            };
            baseFactory.AuditvisitInfoSave = function (params, data) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'Logging/AuditvisitInfoSave/' + params, data).then(function (res) {
                    deferred.resolve(res);
                }, function (res) {
                    deferred.reject(res);
                });
                return deferred.promise;
            };
            baseFactory.FirmLoginProvider = function (params) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'Provider/FirmLoginProvider/' + params).then(function (res) {
                    deferred.resolve(res);
                }, function (res) {
                    deferred.reject(res);
                });
                return deferred.promise;
            };
            return baseFactory;
        }]);
})();