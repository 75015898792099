(function () {
    'use strict'
    angular.module('app.core')
        .controller('ProviderMultipleFileUploadListController', ProviderMultipleFileUploadListController);

    ProviderMultipleFileUploadListController.$inject = ['$controller', '$rootScope', '$scope', '$http', '$q', '$filter', 'ProviderMultipleFileUploadService',
        'IndividualDocumentSaveService', 'utilityService', '$exceptionHandler', 'Messages'
    ];

    function ProviderMultipleFileUploadListController($controller, $rootScope, $scope, $http, $q, $filter,
        ProviderMultipleFileUploadService, IndividualDocumentSaveService, utilityService, $exceptionHandler, Messages) {
        $controller('GlobalController', { $scope: $scope });
        $scope.$on("loaddocumentListing", function (evt, individualId, docid) {
            $scope.providerDocumentGetByProviderId(individualId, docid);
        });
        $scope.providerDocumentGetByProviderId = function (providerid, docid) {
            try {
                ShowLoader("Please wait...");
                ProviderMultipleFileUploadService.providerDocumentGetByProviderIdDocId(sessionStorage.Key, providerid, docid)
                    .then(function (response) {
                        $scope.documentsList = [];
                        hideStatusMessage();
                        hideValidationSummary();
                        if ($scope.checkResponse(response)) {
                            if (response.ProviderDocumentGET != null) {
                                //$scope.DocLKToPageTabSelectionID = response.IndividualDocumentGetList[0].DocumentLkToPageTabSectionId;
                                if (!!$scope.providerDocumentUpload && !!$scope.providerDocumentUpload.DocId) {
                                    $scope.documentsList = $filter('filter')(response.ProviderDocumentGET, function (doc) {
                                        return (doc.DocumentId === $scope.providerDocumentUpload.DocId &&
                                            (!$scope.providerDocumentUpload.ApplicationId || ($scope.providerDocumentUpload.ApplicationId == doc.ApplicationId)) &&
                                            (!!$scope.referenceNumber ? (doc.ReferenceNumber == $scope.referenceNumber) : true));
                                    });
                                    $scope.providerDocumentUpload.documentCount = $scope.documentsList.length;
                                } else {
                                    $scope.documentsList = $filter('filter')(response.ProviderDocumentGET, function (doc) {
                                        return (((!$scope.providerDocumentUpload.ApplicationId || ($scope.providerDocumentUpload.ApplicationId == doc.ApplicationId))) &&
                                            (!$scope.providerDocumentUpload.ExcludeDocId || ($scope.providerDocumentUpload.ExcludeDocId != doc.DocumentId)));
                                    });
                                    $scope.providerDocumentUpload.documentCount = $scope.documentsList.length;
                                }
                            }
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                showStatusMessage(ex.message, "error");
                $exceptionHandler(ex);
            }
        };

        $scope.getDocument = function (provDocID) {
            try {
                ShowLoader("Please wait...");
                ProviderMultipleFileUploadService.providerDocumentByProviderDocumentId(sessionStorage.Key, provDocID)
                    .then(function (response) {
                        var headers = response.headers();
                        $scope.response = response.data;;
                        utilityService.downloadExcelFile($scope.response, headers);
                        HideLoader();
                    });
            } catch (ex) {
                showStatusMessage(ex.message, "error");
                HideLoader();
                $exceptionHandler(ex);
            }
        };

        $scope.ProviderDocDocumentDeleteByID = function (docData) {
            try {
                if (confirm(Messages.General.Confirm.DeleteDocument)) {
                    ShowLoader("Please wait...");
                    ProviderMultipleFileUploadService.ProviderDocumentDelete(sessionStorage.Key, docData)
                        .then(function (response) {
                            var index = $scope.documentsList.indexOf(docData);
                            $scope.documentsList.splice(index, 1);
                            $scope.providerDocumentUpload.documentCount = $scope.documentsList.length;
                            HideLoader();
                        }, function (error) {
                            showStatusMessage(error.message, "error");
                        });
                }
            } catch (ex) {
                showStatusMessage(ex.message, "error");
                HideLoader();
                $exceptionHandler(ex);
            }
        };
        if (!!$scope.providerDocumentUpload && (!!$scope.providerDocumentUpload.LoadDocumentsWithoutBroadCast)) {
            $scope.providerDocumentGetByProviderId($scope.providerDocumentUpload.ProviderId, $scope.providerDocumentUpload.DocId);
        }

        var getTemplate = function () {
            multipleFileUploadService.getAllTemplates().then(function (response) {
                $scope.templateList = response;
            }, function (error) {

            });
        }

        $scope.openEmailModel = function (docData) {
            $scope.email = sessionStorage.currentProviderUserEmail;
            $scope.template = {};
            $scope.template.TemplateId = "";
            $scope.docData = docData;
            $scope.correspondenceEmailBlock = true;
        }
        $scope.sendEmail = function () {
            if ($scope.template.TemplateId) {
                ShowLoader();
                ProviderMultipleFileUploadService.providerDocumentEmailSend(sessionStorage.Key, $scope.docData.ProviderId, $scope.docData.ProviderDocumentId, $scope.docData.DocumentId, $scope.docData.ApplicationId, sessionStorage.currentProviderUserEmail, $scope.template.TemplateId).then(function (response) {
                    HideLoader();
                    showStatusMessage(Messages.General.Success.EmailSent, "success");
                }, function (error) {
                    HideLoader();
                });
            }
        }

        $scope.cancelEmail = function () {
            $scope.correspondenceEmailBlock = false;
        }
        if ($scope.isEmailSend) {
            getTemplate();
        }
    }
})();
