(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("firmNotesController", BackofficeProviderLicenseController);

    BackofficeProviderLicenseController.$inject = ["$rootScope", "$scope", "$state", "utilityService", "$exceptionHandler", "WebApiUrl", "$filter", "firmNotesService",
    ];

    function BackofficeProviderLicenseController($rootScope, $scope, $state, utilityService, $exceptionHandler, WebApiUrl, $filter, firmNotesService) {

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, ProviderId) {
            if (ProviderId && ($rootScope.individualFirmCurrentTab.name == "notes")) {

            }
        });

    };
})();