(function () {
    'use strict'

    angular.module('app.core')
    .directive('applicationStatusPickerValidation', [ApplicationStatusPickerValidation]);

    function ApplicationStatusPickerValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function (form) {
                    var valid = true;
                    
                    if (!!form && form.errorMessages.length > 0) {
                        valid = false;
                    }
                    
                    return valid;
                };
            },
        };
    }
})();