﻿(function () {
    'use strict'
    angular.module('natApp')
      .controller('BEInstructionsController', BEInstructionsController);

    BEInstructionsController.$inject = ['$scope'];

    function BEInstructionsController($scope) {
        
    }
})();