(function () {
    'use strict'
    angular.module('app.report')
        .controller('DOJFBIReportController', DOJFBIReportController);

    DOJFBIReportController.$inject = ['$scope', '$q', '$controller', '$filter',
        'reportService', '$state', 'utilityService', 'ApiUrlsForReports', 'ApiUrlsForReportsXls', 'typeValuesService'
    ];

    function DOJFBIReportController($scope, $q, $controller, $filter, reportService, $state, utilityService, ApiUrlsForReports, ApiUrlsForReportsXls, typeValuesService) {
        $controller('GlobalController', { $scope: $scope })

        $scope.mode = 1;
        $scope.dateOptions = { format: 'MM/DD/YYYY' };
        $scope.datePicker = {
            annualdateOpened: false,
            secondannualDate: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };

        // Pagination
        $scope.Pager = {
            sortType: 'IndividualId',
            sortOrder: 'asc',
            currentPage: 1,
            totalRecords: 10,
            pageSize: 20,
            maxSize: 5,
            FromDate: null,
            Mode: 1
        };

        $scope.IndividualHeaders = [
            { name: 'FBI Record Locator', sortType: 'FBIRecordLocator', sortOrder: 'asc' },
            { name: 'DOJ Record Locator', sortType: 'DOJRecordLocator', sortOrder: 'asc' },
            { name: 'First Name', sortType: 'FirstName', sortOrder: 'asc' },
            { name: 'Last Name', sortType: 'LastName', sortOrder: 'asc' },
            { name: 'License Type', sortType: 'LicenseType', sortOrder: 'asc' },
            { name: 'FBI Status', sortType: 'FBIFingerPrintStatus', sortOrder: 'asc' },
            { name: 'DOJ Status', sortType: 'DOJFingerPrintStatus', sortOrder: 'asc' }
        ];

        $scope.FirmHeaders = [
            { name: 'FBI Record Locator', sortType: 'FBIRecordLocator', sortOrder: 'asc' },
            { name: 'DOJ Record Locator', sortType: 'DOJRecordLocator', sortOrder: 'asc' },
            { name: 'First Name', sortType: 'FirstName', sortOrder: 'asc' },
            { name: 'Last Name', sortType: 'LastName', sortOrder: 'asc' },
            { name: 'License Type', sortType: 'LicenseType', sortOrder: 'asc' },
            { name: 'FBI Initial Attempted Match Date', sortType: 'FBIFingerPrintDate', sortOrder: 'asc' },
            { name: 'DOJ Initial Attempted Match Date', sortType: 'DOJFingerPrintDate', sortOrder: 'asc' }
        ];

        $scope.getReportList = function () {
            try {
                if ($scope.isSessionActive()) {
                    ShowLoader('Please Wait...')
                    $scope.Pager.Mode = $scope.mode;
                    if ($scope.mode == 2) {
                        $scope.Pager.FromDate = null;
                        $scope.Pager.sortType = "FBIFingerPrintDate DESC, DOJFingerPrintDate";
                        $scope.Pager.sortOrder = "desc"
                    }
                    else {
                        $scope.Pager.sortType = "FBILastMatchDate DESC, DOJLastMatchDate";
                        $scope.Pager.sortOrder = "desc"
                    }
                    reportService.fBIDOJReportWithPager(sessionStorage.Key, $scope.Pager, ApiUrlsForReports.FBIDOJ)
                        .then(function (response) {

                            if ($scope.checkResponse(response)) {
                                $scope.Pager.totalRecords = response.List != null ? response.List[0].TotalRecord : 0;
                                $scope.LicenseList = response.List;
                            }
                            HideLoader();
                            utilityService.scrollTop();
                        }, function (data) {
                            HideLoader()
                            showStatusMessage(data, 'error')
                        })
                    $scope.showListing = true;
                } else {
                    $state.go('app.Loginwoemail')
                }
            } catch (ex) {
                HideLoader()
                if (ex != null) { showStatusMessage(ex.message, 'error'); }
            }
        };


        //$scope.getDailyDepositReportList();

        $scope.SortRecords = function (header) {
            $scope.Pager.currentPage = 1;
            $scope.Pager.sortType = header.sortType;
            $scope.Pager.sortOrder = header.sortOrder;
            header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
            $scope.getReportList();
        };
        $scope.resetReport = function () {
            $scope.Pager.FromDate = null;
            $scope.mode = 1;
            $scope.Pager.Mode = 1;
            $scope.showListing = false;
        }

        $scope.CopyToClipboard = function () {
            // Get The Text
            var FBI = Array.prototype.map.call($scope.LicenseList, function (item) { return item.FBIRecordLocator; });
            var DOJ = Array.prototype.map.call($scope.LicenseList, function (item) { return item.DOJRecordLocator; });
            var Combine = FBI.concat(DOJ);
            var Filtered = Combine.filter(function (el) {
                return el != "";
            }).join('\r\n');

            // create temp element
            var copyElement = document.createElement("textarea");
            copyElement.appendChild(document.createTextNode(Filtered));
            copyElement.id = 'tempCopyToClipboard';
            angular.element(document.body.append(copyElement));

            // select the text
            var range = document.createRange();
            range.selectNode(copyElement);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);

            // copy & cleanup
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
            copyElement.remove();
        }


        $scope.getExcelReport = function () {
            try {
                ShowLoader('Please Wait...')
                reportService.getXLSForFBIDOJReport(sessionStorage.Key, $scope.Pager, ApiUrlsForReportsXls.FBIDOJ)
                    .then(function (response) {
                        HideLoader()
                    }, function (data) {
                        HideLoader()
                        showStatusMessage(data, 'error')
                    })
            } catch (ex) {
                HideLoader()
                if (ex != null) { showStatusMessage(ex.message, 'error'); }
            }
        }

    };
})();