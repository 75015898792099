(function() {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("providerHistoryDisclosureController", providerHistoryDisclosureController);

    providerHistoryDisclosureController.$inject = ["$rootScope", "$scope", "$state", "providerHistoryDisclosureService", "utilityService", "$exceptionHandler", "WebApiUrl", "countryStateService", "ProviderMultipleFileUploadService", 'Messages'];

    function providerHistoryDisclosureController($rootScope, $scope, $state, providerHistoryDisclosureService, utilityService, $exceptionHandler, WebApiUrl, countryStateService, ProviderMultipleFileUploadService, Messages) {


        //Tab Initilizer
        $scope.$on("individualTabChanged", function(evt, ProviderId) {
            if (ProviderId && $rootScope.individualFirmCurrentTab.name == "compliance") {
                $scope.ProviderId = ProviderId;
                $scope.saveDate = {};
                getProviderScopeOfPractice();
                getStateByCountryID($rootScope.DefaultCountryId);
                initDocument();
            }
        });
        $scope.documentConfig = {
            DocId: 8,
            LinkType: 'OI',
            PropertyToProcess: 'ProviderHistoryDisclosureId'
        };

        var initDocument = function() {
            $scope.documentUpload = undefined;
            setTimeout(function() {
                $scope.$apply(function() {
                    $scope.documentUpload = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                        // ApplicationId: _applicationId,
                        ProviderId: $scope.ProviderId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FRM",
                        LoadByDocIdAndDocCode: true,
                        DocId: 8,
                        DocCode: "D1008",
                        LoadDocumentsWithoutBroadCast: true,
                        //PDocumentLkType: 'OI'
                        LinkType: 'OI',
                        PropertyToProcess: 'ProviderHistoryDisclosureId'
                    };
                });
            }, 1000);
        };

        $scope.DownloadDocumentAPIURL = WebApiUrl + 'Individual/DownloadPDF/' + sessionStorage.Key + '?path=';
        $scope.DownloadDocument = function(path) {
            window.open($scope.DownloadDocumentAPIURL + path, '_blank');
        };

        $scope.setSaveDocumentRef = function(fn) {
            $scope.saveAllDocument = fn;
        };

        $scope.netValidator = {};
        $scope.saveProviderScopeOfPractice = function(form) {

            if (validateForm(form) && $scope.saveDate) {
                ShowLoader();
                if (!$scope.saveDate.ProviderHistoryDisclosureId) {
                    $scope.saveDate.ProviderId = $scope.ProviderId;
                    $scope.saveDate.IsActive = true;
                }
                if (!$scope.saveDate.ReferenceNumber) {
                    $scope.saveDate.ReferenceNumber = new Date().valueOf();
                }


                providerHistoryDisclosureService.saveProviderHistoryDisclosure(sessionStorage.Key, [$scope.saveDate]).then(function(res) {
                    if (res.Status && res.ProviderHistoryDisclosureList && res.ProviderHistoryDisclosureList.length > 0) {
                        $scope.documentUpload.LinkId = res.ProviderHistoryDisclosureList[0].ProviderHistoryDisclosureId;
                        if (typeof $scope.saveAllDocument == "function") {
                            $scope.saveAllDocument().then(function() {
                                getProviderScopeOfPractice();
                                $scope.cancelBtnClick();
                            }, function() {});
                        } else {
                            getProviderScopeOfPractice();
                            $scope.cancelBtnClick();
                        }
                    } else {
                        HideLoader();
                    }
                }, function(err) {
                    console.log(err);
                    HideLoader();
                });
            }
        };
        var getProviderScopeOfPractice = function() {
            try {
                ShowLoader();
                providerHistoryDisclosureService.getProviderHistoryDisclosure(sessionStorage.Key, $scope.ProviderId).then(function(res) {
                    if (res.Status) {
                        $scope.ProviderHistoryDisclosureList = res.ProviderHistoryDisclosureList;
                        processDocument();
                    }
                    HideLoader();
                }, function(err) {
                    console.log(err);
                    HideLoader();
                });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        var processDocument = function() {

            if ($scope.ProviderHistoryDisclosureList && $scope.ProviderHistoryDisclosureList.length > 0) {
                ProviderMultipleFileUploadService.providerDocumentProcess(sessionStorage.Key, $scope.ProviderId, $scope.documentConfig.DocId,
                    $scope.ProviderHistoryDisclosureList, $scope.documentConfig.PropertyToProcess, $scope.documentConfig.LinkType).then(function(responseData) {
                    $scope.ProviderHistoryDisclosureList = responseData;
                });
            }
        };

        var getStateByCountryID = function(countryId) {
            $scope.statesData = [];
            try {
                countryStateService.getStateByCountryID(sessionStorage.Key, countryId)
                    .then(function(response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.statesData = response.State;
                        }
                    }, function(data) {
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };



        $scope.addNewBtnClick = function() {
            $scope.showScopeOfPracticeForm = true;
            $scope.saveDate = {};
            $scope.saveDate.ReferenceNumber = new Date().valueOf();
            initDocument();
        };

        $scope.cancelBtnClick = function() {
            $scope.saveDate = {};
            $scope.showScopeOfPracticeForm = false;
        };

        $scope.editButtonClick = function(i) {
            $scope.showScopeOfPracticeForm = true;
            $scope.saveDate = angular.copy(i);
            if (!$scope.saveDate.ReferenceNumber) {
                $scope.saveDate.ReferenceNumber = new Date().valueOf();
            }
            initDocument();
        };

        $scope.deleteButtonClick = function(i) {
            try {

                if (i && confirm(Messages.bbabace)) {
                    ShowLoader();
                    i.IsActive = false;
                    i.IsDeleted = true;
                    providerHistoryDisclosureService.saveProviderHistoryDisclosure(sessionStorage.Key, [i]).then(function(res) {
                        if (res.Status) {
                            getProviderScopeOfPractice();
                        } else {
                            HideLoader();
                        }
                    }, function(err) {
                        console.log(err);
                        HideLoader();
                    });
                }
            } catch (ex) {}
        };


        //This function validates the for on click of submit button
        var validateForm = function(form, error) {
            $scope.netValidator.errorMessages = [];
            if (!!form) {
                form.$submitted = true;
                var errorMessages = angular.copy(form.errorMessages);
                angular.forEach(errorMessages, function(errorMessage, index) {
                    $scope.netValidator.errorMessages.push(errorMessage);
                });
            }
            if (!!error) {
                $scope.netValidator.errorMessages.push(error);
            }
            if (!!$scope.netValidator.errorMessages && $scope.netValidator.errorMessages.length > 0) {
                $scope.netValidator.showError = true;
                $scope.netValidator.isValid = false;
                return false;
            } else {
                $scope.netValidator.showError = false;
                $scope.netValidator.isValid = true;
                return true;
            }
        };
    }
})();