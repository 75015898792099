(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeIndividualDEAService', backofficeIndividualDEAService)

    backofficeIndividualDEAService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeIndividualDEAService($http, $q, WebApiUrl, AppConfig) {

        var _getIndividualLicenseAddInfo = function (Key, IndividualId, ApplicationId, LicenseNumber) {
            if (LicenseNumber)
                return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualLicenseAddInfo/' + Key + '?IndividualId=' + IndividualId + '&ApplicationId=' + '&LicenseNumber=' + LicenseNumber));
            else
                return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetIndividualLicenseAddInfo/' + Key + '?IndividualId=' + IndividualId + '&ApplicationId='));
        };

        var _saveIndividualLicenseAddInfo = function (Key, objData) {
            return returnDefferedResult($http.post(WebApiUrl + "Individual/SaveIndividualLicenseAddInfo/" + Key + '?Source=' + AppConfig.Source, objData));
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        return {
            getIndividualLicenseAddInfo: _getIndividualLicenseAddInfo,
            saveIndividualLicenseAddInfo: _saveIndividualLicenseAddInfo,
        }
    }
})();