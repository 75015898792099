(function () {
    'use strict';

    angular
        .module('app.provider')
        .controller("ProviderAppListingController", ProviderAppListingController);

    ProviderAppListingController.$inject = ["$rootScope", "$scope", "$state", "bppService",
        "countryStateService", "typeValuesService", "lookupService", "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl", "$q"
    ];

    function ProviderAppListingController($rootScope, $scope, $state, bppService,
        countryStateService, typeValuesService, lookupService, utilityService, $exceptionHandler, TypeValue, WebApiUrl, $q) {
        $scope.isBackoffice = true;
        $scope.routeData = $state.current.routeData;
        $scope.viewConfigListing = {};
        $scope.viewConfigListing.DisplayAddApplication = false;
        $scope.datePicker = {
            beginDateOpened: false,
            endDateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            },
            startDateOptions: {
                maxDate: null
            },
            endDateOptions: {
                minDate: null
            }
        };

        $scope.searchDataObj = {
            ProviderName: "",
            ApplicationStatusId: "",
            ApplicationTypeIds: $scope.routeData.applicationTypeIds
        };

        $scope.init = function () {
            $scope.searchData = angular.copy($scope.searchDataObj);
            $scope.searchData.ApplicationStatusId = 1;
            $scope.addNewLicense = true;
            if ($scope.isUserSessionActive()) {
                $scope.getLicenseeRenewalList(1);
                $scope.getLicenseStatus();
            } else {
                $state.go('app.Loginwoemail');
            }
        };

        $scope.getLicenseStatus = function () {
            typeValuesService.getTypeValuesByTypeName('ApplicationStatusGetAll', sessionStorage.Key, false, true)
                .then(function (response) {
                    $scope.ApplicationStatusList = response.ApplicationStatusList;
                }, function (error) {

                });
        };

        $scope.renewalSearch = function () {
            hideStatusMessage();
            $scope.currentPage = 1;
            $scope.getLicenseeRenewalList(1);
        };

        $scope.CancelSearchClick = function () {
            $scope.clearApplication();
            $scope.getLicenseeRenewalList(1);
        };

        $scope.CancelAddClick = function () {
            $scope.currentRenewalItem = null;
            $scope.licenseBoSearch = false;
            $scope.viewConfigListing.DisplayAddApplication = false;
            $scope.getLicenseeRenewalList(1)
            // $scope.getLicenseeRenewalList(1);
        };

        $scope.getAllLicenseeRenewalList = function () {
            $scope.getLicenseeRenewalList(1, true);
        };

        $scope.licenseBoAdd = function () {
            $scope.licenseBoSearch = true;
            $scope.gridRenewalList = false;
            $scope.providerId = null;
        };

        $scope.loadTypeahed = function (searchString, searchType) {
            return bppService.getEmployerList(
                sessionStorage.Key, searchString, 3, searchType
            ).then(function (response) {
                if (!!response.Employers) {
                    return response.Employers.filter(function(Employer){return Employer.ProviderStatusTypeId == 1}).map(function (item) {
                        item.formattedResult = (item.EmployerName + " " + item.StreetLine1 + " " + item.StreetLine2 + " " + item.City + " " + item.Zip);
                        return item;
                    });
                }
            }, function (res) {

            });
        };

        $scope.createCourseApp = function () {
            $scope.viewConfigListing.DisplayAddApplication = true;
            $scope.licenseBoSearch = false;
            $scope.gridRenewalList = true;
        }

        $scope.SelectTypeAheadItem = function (selectedItem, $model, $label, $event) {
            $scope.providerId = null;
            setTimeout(function () {
                $scope.CompanyLicenseNumber = selectedItem.LicenseNumber;
                $scope.providerId = selectedItem.ProviderId;
                $scope.selectedItems = selectedItem;
            }, 10);
        };

        $scope.getLicenseeRenewalList = function (PageNumber, showAllRecords) {
            $scope.hidePaging = !!showAllRecords;
            resetValidation();
            $scope.viewConfigListing.DisplayAddApplication = false;
            ShowLoader("Please Wait...");
            $scope.gridRenewalList = true;
            bppService.getProviderApplicationList(sessionStorage.Key, $scope.searchData).then(function (response) {
                HideLoader();
                if (!response.Status) {
                    $scope.showStatusMessage(response.Message, "error");
                    return;
                }
                $scope.providerCourseApps = response.ProviderApplicationList;
            }, function (error) {
                $scope.showStatusMessage(error, "error");
            })
        };

        $scope.clearApplication = function () {
            $scope.searchData = angular.copy($scope.searchDataObj);
            $scope.viewConfigListing.DisplayAddApplication = false;
            $scope.IsPendingApplication = false;
            $scope.SearchLicenseNumber = "";
        };

        var resetRenewalListing = function () {
            angular.forEach($scope.providerCourseApps, function (renewalItem, key) {
                renewalItem.ShowInternalForm = false;
            });
        };

        $scope.openApplication = function (renewalItem) {
            // $scope.DisplayAddApplication = false;
            // $scope.addNewLicense = true;
            $scope.currentRenewalItem = renewalItem;
            // if (renewalItem.ApplicationStatusId == 14) {
            //     //Edit
            //     $scope.IsPendingApplication = true;
            //     renewalItem.disableSubmitControls = false;
            //     renewalItem.disableRenewalForm = false;
            //     renewalItem.DisableSaveButton = false;
            //     renewalItem.DisableSubmitButton = false;
            //     renewalItem.ShowPayAndApproveButton = true;
            //     renewalItem.ShowConfirmAndApproveButton = true;
            //     $scope.ProceedtoPaymentSectionEnable = false;
            //     //"Save" ,"Pay Only","Pay and Approve", "Close"
            //     //Form Not disabled

            // } else if (renewalItem.ApplicationStatusId == 2 || renewalItem.ApplicationStatusId == 13 || renewalItem.ApplicationStatusId == 15) {
            //     //Verify
            //     renewalItem.disableSubmitControls = false;
            //     renewalItem.disableRenewalForm = true;
            //     renewalItem.DisableSaveButton = true;
            //     renewalItem.DisableSubmitButton = true;
            //     renewalItem.ShowPayAndApproveButton = true;
            //     renewalItem.ShowConfirmAndApproveButton = true;
            //     $scope.ProceedtoPaymentSectionEnable = true;
            //     //"Save" ,"Confirm And Approve", "Close"
            //     //Form Not disabled
            // } else if (renewalItem.ApplicationStatusId == 3) {
            //     //View
            //     renewalItem.disableSubmitControls = true;
            //     renewalItem.disableRenewalForm = true;
            //     renewalItem.DisableSaveButton = true;
            //     renewalItem.DisableSubmitButton = true;
            //     renewalItem.ShowPayAndApproveButton = false;
            //     renewalItem.ShowConfirmAndApproveButton = true;
            //     $scope.ProceedtoPaymentSectionEnable = true;

            //     //"Save"----Disabled ,"Confirm And Approve"----Disabled, "Close"
            //     //Form disabled
            // }
            resetRenewalListing();
            // //load required data for directive            
            $scope.providerId = renewalItem.ProviderId;
            // $scope.EIN = renewalItem.TaxId;
            // $scope.licenseNumber = renewalItem.LicenseNumber;
            // $scope.providerName = renewalItem.ProviderName;
            renewalItem.ShowInternalForm = true;
            hideStatusMessage();
        }

        $scope.init();
    }

})();