(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationBEStaffReviewController", InitialApplicationBEStaffReviewController);

    InitialApplicationBEStaffReviewController.$inject = ["$rootScope", "$scope", "$q",
        "initialApplicationStaffReviewService", "licensureApplicationListingService", "utilityService", "WebApiUrl", "initialProviderApplicationFeePaymentService", "personalService", "BackofficeProviderService", "initialApplicationBEStaffReviewService", "EInformationDirectiveService"
    , 'Messages'];

    function InitialApplicationBEStaffReviewController($rootScope, $scope, $q,
        initialApplicationStaffReviewService, licensureApplicationListingService, utilityService, WebApiUrl, initialProviderApplicationFeePaymentService, personalService, BackofficeProviderService, initialApplicationBEStaffReviewService, EInformationDirectiveService, Messages) {

        $scope.natValidation = {};
        $scope.applicationReview = {};

        $rootScope.emailofProvider;
        $scope.getStaffReviewConfig = function () {
            ShowLoader();
            var relativeFilePath = "/individual/application/staff-review/staff-review.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function (data) {
                    $scope.staffReviewConfig = data;
                    $scope.isConfigLoaded = true;
                    HideLoader();
                    $scope.getIndividualLicense();
                }, function (error) {
                    $scope.showStatusMessage({ messageContent: error.Message, messageType: 'error' });
                });
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        var createNewLicenseRecord = function () {
            $scope.individualLicense = {
                ProviderId: $scope.providerId,
                ApplicationId: $scope.applicationId,
                ActionFlag: 'N',
                ApplicationTypeId: 10,
                ProviderTypeId: 5,// $scope.applicationInfo.LicenseTypeId,
                IsLicenseTemporary: false,
                IsLicenseActive: true,
                ProviderStatusTypeId: 1,
                IsActive: true,
                IsDeleted: false,
                // LicenseStatusTypeCode: 'ACT',
                ProviderStatusTypeName: 'Active',
                ApplicationTypeName: "New Provider Application",
                ProviderLicenseGuid: 'pl',
                New: true
            };
        };

        $scope.getIndividualLicense = function () {
            try {

                $scope.individualLicense = {};
                BackofficeProviderService.ProviderEINProviderId(sessionStorage.Key, $scope.providerId)
                    .then(function (response) {

                        if (response.data.Status) {

                            if (!!response.data.ProviderLicenseResponseList && response.data.ProviderLicenseResponseList.length > 0) {
                                $scope.individualLicense = response.data.ProviderLicenseResponseList[0];
                                processLicenseDates();
                            } else {
                                createNewLicenseRecord();
                            }
                            // console.log($scope.individualLicense);
                        } else {
                            if (response.data.StatusCode == "00")
                                createNewLicenseRecord();
                            else
                                $scope.showStatusMessage({ messageContent: response.Message, messageType: 'error' });
                        }
                        HideLoader();
                    }, function (error) {
                        HideLoader();
                        $scope.showStatusMessage({ messageContent: 'Some error occured !', messageType: 'error' });
                    });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        };

        var validateForm = function (currentForm, currentReasonForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                if (!!currentForm.errorMessages)
                    $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            }

            if (!!currentReasonForm) {
                currentReasonForm.$submitted = true;
                var addInfoErrorMessages = angular.copy(currentReasonForm.errorMessages);
                angular.forEach(addInfoErrorMessages, function (errorMessage, index) {
                    $scope.natValidation.errorMessages.push(errorMessage);
                });
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        var ShowErrMsg = function (propName, errorMessage) {
            propName = (!!propName) ? propName : "examinationErr";
            errorMessage = (!!errorMessage) ? errorMessage : $scope.examinationConfig.ValidationMsg.APIError;
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            var propExist = false;
            for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                if ($scope.natValidation.errorMessages[i].propName == propName)
                    propExist = true;
            }
            if (!propExist)
                $scope.natValidation.errorMessages.push({
                    propName: propName,
                    errorMessage: errorMessage
                });

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.setLicenseToMinDate = function () {
            if (angular.isDate($scope.individualLicense.ProviderLicenseEffectiveDate)) {
                $scope.datePicker.endDateOptions.minDate = $scope.individualLicense.ProviderLicenseEffectiveDate;
            }
        };

        $scope.setLicenseFromMaxDate = function () {
            if (angular.isDate($scope.individualLicense.ProviderLicenseExpirationDate)) {
                $scope.datePicker.startDateOptions.maxDate = $scope.individualLicense.ProviderLicenseExpirationDate;
            }
        };

        var processLicenseDates = function () {
            if (!!$scope.individualLicense) {
                if (!!$scope.individualLicense.OriginalLicenseDate && !angular.isDate($scope.individualLicense.OriginalLicenseDate))
                    $scope.individualLicense.OriginalLicenseDate = new Date($scope.individualLicense.OriginalLicenseDate);
                if (!!$scope.individualLicense.ProviderLicenseEffectiveDate && !angular.isDate($scope.individualLicense.ProviderLicenseEffectiveDate))
                    $scope.individualLicense.ProviderLicenseEffectiveDate = new Date($scope.individualLicense.ProviderLicenseEffectiveDate);
                if (!!$scope.individualLicense.ProviderLicenseExpirationDate && !angular.isDate($scope.individualLicense.ProviderLicenseExpirationDate))
                    $scope.individualLicense.ProviderLicenseExpirationDate = new Date($scope.individualLicense.ProviderLicenseExpirationDate);
            }
            $scope.setLicenseToMinDate();
            $scope.setLicenseFromMaxDate();
        };

        var applicationStatusLkSave = function (userId, applicationStatusId) {
            var reqData = {
                HighPriority: false,
                ApplicationId: $scope.applicationId,
                ApplicationTypeId: 10,
                ApplicationStatusId: 3,
                ApplicationStatusReasonId: 0,
                IsActive: true,
                IsDeleted: false,
                IsArchive: false,
                CreatedBy: sessionStorage.UserID,
                ApplicationInternalStatusReasonText: '',
                ApplicationInternalStatusId: 0,
            };

            return licensureApplicationListingService.applicationStatusLkSave(sessionStorage.Key, userId, reqData);
        }

        var applicationStatusChangeLogSaveData = function (userId, applicationStatusId) {
            var reqData = {
                ApplicationId: $scope.applicationId,
                ApplicationStatusReasonId: 0,
                ApplicationNumber: '',
                ApplicationTypeId: 10,
                ApplicationStatusId: 3,
                ApplicationSubmitMode: 'B',
                ApplicationStatusChangedBy: sessionStorage.UserID,
                ReferenceNumber: '',
                LicenseTypeId: $scope.application.LicenseTypeId,
                HighPriority: false,
                ApplicationInternalStatusId: 0,
                ApplicationInternalStatusReasonText: ''
            };

            return licensureApplicationListingService.applicationStatusChangeLogSave(sessionStorage.Key, userId, reqData);
        };

        $scope.saveStaffReviewData = function (currentForm) {
            $scope.isdisable = true;
            ShowLoader("Please wait...");
            if (!validateForm(currentForm, $scope.currentReasonForm)) {
                HideLoader();
                return;
            }
            var allTabsComplete = true;
            var ispaymentComplete = true;

            initialProviderApplicationFeePaymentService.getInvoiceMasterByIndividualIdAndInvoiceStatus(sessionStorage.Key, $scope.providerId, $scope.applicationId)
                .then(function (objMasterDetails) {

                    if (objMasterDetails.StatusCode != "00") {
                        showStatusMessage(objMasterDetails.Message, 'error');
                        HideLoader();
                        $scope.isdisable = false;
                    }

                    if (!!objMasterDetails.InvoiceMasters && objMasterDetails.InvoiceMasters[0].InvoiceStatus == "CLOSED") {
                        saveApplicationReview();
                        BackofficeProviderService.SaveProviderLicense(sessionStorage.Key, [$scope.individualLicense])
                            .then(function (response) {

                                if (response.data.Status == false) {
                                    showStatusMessage(response.data.Message, 'error');
                                } else {
                                    $scope.providerLicense = response.data.ProviderLicenseResponseList[0];
                                    sendApprovalEmail($scope.providerLicense);
                                    // if (!$scope.isRenewal) {
                                    var applicationSubmitPromise = licensureApplicationListingService.applicationStatusUpdate(sessionStorage.Key, $scope.applicationId, 3, {});
                                    var applicationSubmitLkPromise = applicationStatusLkSave($scope.userId, 3);
                                    var applicationSubmitChangeLogPromise = applicationStatusChangeLogSaveData($scope.userId, 3);
                                    $q.all(applicationSubmitPromise, applicationSubmitLkPromise, applicationSubmitChangeLogPromise).
                                        then(function (responseAppSubmitted) {
                                            // debugger
                                            var submitErrMessages = '';
                                            var isSubmittedSuccessFully = true;

                                            angular.forEach(responseAppSubmitted, function (resData, key) {
                                                if (resData.Status == false) {
                                                    isSubmittedSuccessFully = false;
                                                    submitErrMessages += resData.Message;
                                                }
                                            });
                                            if (isSubmittedSuccessFully) {
                                                $scope.$emit('updatePaymentAndStatus', { applicationId: $scope.applicationId, applicationStatusId: 3, updateStatusOnly: false });
                                                $scope.isdisable = true;
                                                // $scope.tab.IndividualLicenseId = $scope.individualLicense.IndividualLicenseId;
                                                $scope.openNextTab();

                                            } else {
                                                $scope.isdisable = false;
                                                HideLoader();
                                                showStatusMessage(submitErrMessages, 'error');
                                            }
                                        }, function (error) {
                                            HideLoader();
                                            $scope.isdisable = false;
                                            showStatusMessage(error, 'error');
                                        });

                                }
                                if (!$rootScope.ProcessLoaderExternally)
                                    HideLoader();
                            },
                                function (error) {
                                    HideLoader();
                                    showStatusMessage(Messages.faaaddc, 'error');
                                });
                    } else {
                        $('#paymentNotConfirmedModal').modal('show');
                        HideLoader();
                    }
                }, function (response) {
                    showStatusMessage(response, 'error');
                    HideLoader();
                });
            // }
            //     }
            // }, function (error) {

            // });
        };

        $scope.init = function () {

            $scope.datePicker = {
                fromDateOpened: false,
                toDateOpened: false,
                format: 'M!/d!/yyyy',
                endDateOptions: {
                    minDate: null
                },
                startDateOptions: {}
            };
            applicationReviewGet();
            $scope.getStaffReviewConfig();
            BackofficeProviderService.GetApplicationBYProviderId(sessionStorage.Key, $scope.providerId).then(function (response) {
                if (response.data.Status) {
                    //  console.log(response.data.ApplicationResponseList[0]);
                    $scope.application = response.data.ApplicationResponseList[0];
                }
            }, function (error) {

            });

            EInformationDirectiveService.GetProviderById(sessionStorage.Key, $scope.providerId).then(function (response) {

                $scope.provider = response.data.ProviderResponse;
            }, function (error) {

            });

        };
        var sendApprovalEmail = function (value) {
            updateProvider();
            var data = {
                ProviderId: value.ProviderId,
                ApplicationId: value.ApplicationId,
                UserId: sessionStorage.UserID,
                ProviderLicenseId: value.ProviderLicenseId,
                Email: $rootScope.emailofProvider.ContactInfo,
                LicenseNumber: $scope.individualLicense.ProviderLicenseNumber
            };
            initialApplicationBEStaffReviewService.sendProviderDocumentSaveToPDFandMailWY(data, sessionStorage.Key).then(function (response) {

                // $scope.$emit('updatePaymentAndStatus', { applicationId: $scope.ApplicationId, applicationStatusId: 3, updateStatusOnly: true });
            }, function (error) {
                HideLoader();
            });
            // $scope.individualLicense.ProviderLicenseNumber


        }
        var updateProvider = function () {

            $scope.provider.ProviderNumber = $scope.individualLicense.ProviderLicenseNumber;
            EInformationDirectiveService.ProviderSave(sessionStorage.Key, $scope.applicationId, sessionStorage.UserID, $scope.provider).then(function (response) {

            }, function (error) {

            });
        }

        var applicationReviewGet = function () {
            initialApplicationBEStaffReviewService.applicationReviewGet(sessionStorage.Key, $scope.applicationId, $scope.providerId).then(function (response) {
                if (response.Status && response.lstApplicationReviewNew && response.lstApplicationReviewNew.length > 0)
                    $scope.applicationReview = response.lstApplicationReviewNew[0];
            }, function (error) {

            });
        }

        var saveApplicationReview = function () {
            $scope.applicationReview.IsActive = true;
            $scope.applicationReview.IsDeleted = false;
            $scope.applicationReview.ApplicationId = $scope.applicationId;
            $scope.applicationReview.ProviderId = $scope.providerId;
            $scope.applicationReview.ApplicationStatusId = 3;
            initialApplicationBEStaffReviewService.applicationReviewSave(sessionStorage.Key, [$scope.applicationReview]).then(function (response) {
                console.log(response);
            }, function (error) {

            });
        }
        $scope.init();
    }
})();