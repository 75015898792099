(function () {
    'use strict'

    angular.module('app.core')
    .directive('licenseTypesSelector', [LicenseTypesSelector]);

    function LicenseTypesSelector() {
        return {
            restrict: 'E',
            require: 'ngModel',
            scope: {
                buttonText: '@'
            },
            link: function ($scope, element, attrs, ngModel) {
                $scope.dataModel = ngModel;
            },
            templateUrl: 'app/core/directive/license-types-selector/license-types-selector.html',
            controller: 'LicenseTypesSelectorController'
        };
    }
})();