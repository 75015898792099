(function () {
    'use strict';
    angular.module('natApp').factory('FirmLicenseRenewalHASnAService', ['$http', 'WebApiUrl', '$rootScope', 'AppConfig', '$q', function ($http, WebApiUrl, $rootScope, AppConfig, $q) {
        var baseFactory = {};
        baseFactory.GenerateApplicationId = function (key, applicationid, providerid, data) {
            return returnDefferedResult($http.post(WebApiUrl + "Application/GenerateApplicationId/" + key + '?ApplicationId=' + applicationid + '&UserId=' + providerid, data));
        };
        baseFactory.ProviderApplicationSave = function (key, data) {
            return returnDefferedResult($http.post(WebApiUrl + "Provider/ProviderApplicationSave/" + key, data));
        };
        baseFactory.BusinessRenewalCheck = function (key, providername, ProviderLicenseNumber, ein, src) {
            // + "&ein=" + ein
            return returnDefferedResult($http.get(WebApiUrl + "Renewal/BusinessRenewalCheck/" + key + "?ProviderName=" + providername + "&ProviderLicenseNumber=" + ProviderLicenseNumber + "&Source=" + AppConfig.Source));
        };
        baseFactory.WyomingProviderRenewalGet = function (key, provid, isbackoffice) {
            return returnDefferedResult($http.get(WebApiUrl + 'Renewal/WyomingProviderRenewalGet/' + key + '?IndividualId=' + provid + '&IsBackoffice=' + isbackoffice + '&Source=' + AppConfig.Source));
        };
        baseFactory.saveApplicationStatusLk = function (key, userId, reqObj) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Common/ApplicationStatusLkSave/' + key + '?UserId=' + userId, reqObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        baseFactory.saveApplicationStatusChangeLog = function (key, userId, reqObj) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Common/ApplicationStatusChangeLogSave/' + key + '?UserId=' + userId, reqObj)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        baseFactory.saveApplicationStatus = function (ApplicationId, key, applicationStatus) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Common/ApplicationStatusUpdate/' + key + '?ApplicationId=' + ApplicationId + '&ApplicationStatusId=' + applicationStatus + '&Source=' + AppConfig.Source)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        baseFactory.ProviderLicenseStatusUpdate = function (key, data) {
            return returnDefferedResult($http.post(WebApiUrl + 'Provider/ProviderLicenseStatusUpdate/' + key, data));
        };
        baseFactory.SendLicenseEmail = function (Key, licenseEmailObj, source) {
            return returnDefferedResult($http.post(WebApiUrl + 'Provider/ProviderDocumentSaveToPDFandMailWY/' + Key + '?Source=' + source, licenseEmailObj));
        }

        baseFactory.Generatepdf = function (reqdata) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/Generatepdf', reqdata));
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        return baseFactory;
    }]);
})();
