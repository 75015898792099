﻿(function () {
    'use strict'
    angular.module('app.core')
        .factory('dashboardDeficiencyDetailsFactory', ['$http', 'WebApiUrl', '$q', 'AppConfig', function ($http, WebApiUrl, $q, AppConfig) {
            var baseFactory = {};
            baseFactory.getIndividualDeficiency = function (key, individualId, applicationId) {
                return $http.get(WebApiUrl + 'Individual/GetIndividualDeficiency/' + key + '?IndividualId=' + individualId + '&ApplicationId=' + applicationId + '&IndividualLicenseId=');
            }
            return baseFactory;
        }]);
})();