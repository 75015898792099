﻿angular.module('natApp').factory('ApplicantDashboardFactory', ['$http', 'WebApiUrl', function ($http, WebApiUrl) {
    var baseFactory = {};
    baseFactory.ConfigurationGetbySettings = function (key, setting) {
        return $http.get(WebApiUrl + 'Configuration/ConfigurationGetbySettings/' + key + '?setting=' + setting);
    }
    baseFactory.GetContactDetails = function (key, individualId) {
        return $http.get(WebApiUrl + 'Individual/IndividualContactBYIndividualId/' + key + '?IndividualId=' + individualId);
    };
    baseFactory.SaveContactDetails = function (urlParam, data) {
        var ApiPath = 'Individual/IndividualContactSave' + urlParam;
        return $http.post(WebApiUrl + ApiPath, data);
    };
    baseFactory.ContactTypeGetAll = function (key) {
        return $http.get(WebApiUrl + 'TypeValues/ContactTypeGetAll/' + key);
    };
    baseFactory.GetAddressDetails = function (urlParam) {
        return $http.get(WebApiUrl + "Individual/IndividualAddressBYIndividualId" + urlParam);
    };
    baseFactory.SaveAddressDetails = function (key, address) {
        return $http.post(WebApiUrl + 'Individual/IndividualAddressSave/' + key + '?Source=', address);
    };
    baseFactory.GetStateList = function (key, cID) {
        return $http.get(WebApiUrl + "State/StateGetByCountryID/" + key + "?CountryID=" + cID);
    };
    baseFactory.ApplicationBYIndividualId = function (urlParm) {
        var ApiPath = 'Individual/ApplicationBYIndividualId' + urlParm;
        return $http.get(WebApiUrl + ApiPath);
    };

    return baseFactory;
}]);