﻿angular.module('natApp').controller("CouncilInfoController", ["$scope", "$controller", "CouncilInfoService", "$state", "$rootScope",
function ($scope, $controller, CouncilInfoService, $state, $rootScope) {
    $controller('GlobalController', { $scope: $scope });
    // Variables
    $scope.BoardAuthorityId = 0;
    $scope.StateCode = "";
    $scope.Name = "";
    $scope.Code = "";
    $scope.Acronym = "";
    $scope.Url = "";
    $scope.PhysicalAddressLine1 = "";
    $scope.PhysicalAddressLine2 = "";
    $scope.PhysicalAddressCity = "";
    $scope.PhysicalAddressState = "";
    $scope.PhysicalAddressZip = "";
    $scope.IsMailingSameasPhysical = false;

    $scope.MailingAddressLine1 = "";
    $scope.MailingAddressLine2 = "";
    $scope.MailingAddressCity = "";
    $scope.MailingAddressState = "";
    $scope.MailingAddressZip = "";

    $scope.ContactPhone = "";
    $scope.ContactEmail = "";
    $scope.ContactFax = "";
    $scope.AlternatePhone = "";

    $scope.SystemName = "";
    $scope.SystemAbbreviation = "";
    $scope.SystemUrl = "";
    $scope.ApplicationSystemUrl = "";
    $scope.SystemContact = "";

    $scope.IsActive = true;
    $scope.CreatedBy = "";
    $scope.CreatedOn = "";
    $scope.ModifiedBy = "";
    $scope.ModifiedOn = "";
    $scope.BoardAuthorityGuid = "";

    // page init method
    $scope.init = function () {
        HideLoader();
        if ($scope.isSessionActive()) {
            $scope.getStateByCountryID($rootScope.DefaultCountryId);
            $scope.getCouncilInfo();
        }
        else {
            $state.go('app.BackofficeLoginstaff');
        }
    }

    // get CouncilInfo Data
    $scope.getCouncilInfo = function () {
        try {
            ShowLoader("Loading...");
            CouncilInfoService.BoardAuthorityGet(sessionStorage.Key)
			.then(function(response){ response = response.data;
			    if ($scope.checkResponse(response)) {
			         
			        BoardAuthority = response.BoardAuthority;
			        $scope.BoardAuthorityId = BoardAuthority[0].BoardAuthorityId;
			        $scope.StateCode = BoardAuthority[0].StateCode;
			        $scope.Name = BoardAuthority[0].Name;
			        $scope.Code = BoardAuthority[0].Code;
			        $scope.Acronym = BoardAuthority[0].Acronym;
			        $scope.Url = BoardAuthority[0].Url;
			        $scope.PhysicalAddressLine1 = BoardAuthority[0].PhysicalAddressLine1;
			        $scope.PhysicalAddressLine2 = BoardAuthority[0].PhysicalAddressLine2;
			        $scope.PhysicalAddressCity = BoardAuthority[0].PhysicalAddressCity;
			        $scope.PhysicalAddressState = parseInt(BoardAuthority[0].PhysicalAddressState);
			        $scope.PhysicalAddressZip = BoardAuthority[0].PhysicalAddressZip;
			        $scope.IsMailingSameasPhysical = BoardAuthority[0].IsMailingSameasPhysical;
			        $scope.MailingAddressLine1 = BoardAuthority[0].MailingAddressLine1;
			        $scope.MailingAddressLine2 = BoardAuthority[0].MailingAddressLine2;
			        $scope.MailingAddressCity = BoardAuthority[0].MailingAddressCity;
			        $scope.MailingAddressState = parseInt(BoardAuthority[0].MailingAddressState);
			        $scope.MailingAddressZip = BoardAuthority[0].MailingAddressZip;
			        $scope.ContactPhone = BoardAuthority[0].ContactPhone;
			        $scope.ContactEmail = BoardAuthority[0].ContactEmail;
			        $scope.ContactFax = BoardAuthority[0].ContactFax;
			        $scope.AlternatePhone = BoardAuthority[0].AlternatePhone;
			        $scope.IsActive = BoardAuthority[0].IsActive;
			        //$scope.CreatedBy = BoardAuthority[0].CreatedBy;
			        //$scope.CreatedOn = BoardAuthority[0].CreatedOn;
			        //$scope.ModifiedBy = BoardAuthority[0].ModifiedBy;
			        //$scope.ModifiedOn = BoardAuthority[0].ModifiedOn;
			        //$scope.BoardAuthorityGuid = BoardAuthority[0].BoardAuthorityGuid;
			    } else {
			        //else of CheckResponse
			    }
			    HideLoader();
			}, function(data) { data = data.data;
			    HideLoader();
			    showStatusMessage(data.message, "error");
			});
        }
        catch (ex) {
            HideLoader();
            showStatusMessage(ex.message, "error");
        }
    }

    // Update CouncilInfo
    $scope.updateCouncilInfo = function () {
        hideStatusMessage();
        try {
            if ($scope.isSessionActive()) {
                if (validateData('councilinfovalidation')) {
                     
                    CouncilInfoService.SaveBoardAuthority(
                        $scope.BoardAuthorityId,
                        $scope.StateCode,
                        $scope.Name,
                        $scope.Code,
                        $scope.Acronym,
                        $scope.Url,
                        $scope.PhysicalAddressLine1,
                        $scope.PhysicalAddressLine2,
                        $scope.PhysicalAddressCity,
                        $scope.PhysicalAddressState,
                        $scope.PhysicalAddressZip,
                        $scope.IsMailingSameasPhysical,
                        $scope.MailingAddressLine1,
                        $scope.MailingAddressLine2,
                        $scope.MailingAddressCity,
                        $scope.MailingAddressState,
                        $scope.MailingAddressZip,
                        $scope.ContactPhone,
                        $scope.ContactEmail,
                        $scope.ContactFax,
                        $scope.AlternatePhone,
                        $scope.IsActive,
                        $scope.CreatedBy,
                        $scope.CreatedOn,
                        $scope.ModifiedBy,
                        $scope.ModifiedOn,
                        $scope.BoardAuthorityGuid,
                        sessionStorage.Key)
					.then(function(response){ response = response.data;
					     
					    if ($scope.checkResponse(response)) {
					        showStatusMessage(response.Message, "success");
					        $scope.getCouncilInfo("", 2);
					    } else {
					        //else of CheckResponse
					    }
					}, function(data) { data = data.data;
					    showStatusMessage(data.message, "error");
					    $scope.getCouncilInfo("", 2);
					});
                }
            }
            else {
                sessionStorage.URL = window.location.href;
                if (sessionStorage.LoginPage && sessionStorage.LoginPage != '') {
                    try {
                        $state.go(sessionStorage.LoginPage);
                    } catch (ex) {
                        $state.go('app.BackofficeLoginstaff');
                    }
                } else {
                    $state.go('app.BackofficeLoginstaff');
                }
            }
        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }

    // Get State List by CountryID
    $scope.getStateByCountryID = function (CountryID) {
        try {
            CouncilInfoService.GetStateByCountryID(sessionStorage.Key, CountryID)
					.then(function(response){ response = response.data;
					    if ($scope.checkResponse(response)) {
					        $scope.StatList = response.State;
					    } else {
					        //else of CheckResponse
					    }
					}, function(data) { data = data.data;
					    showStatusMessage(data.message, "error");
					});
        }
        catch (ex) {
            showStatusMessage(ex.message, "error");
        }
    }

    // Reset Page or Cancle Button
    $scope.reset = function () {
        resetValidation();
        hideStatusMessage();
        hideValidationSummary();
    }

    $scope.makeSameAsPhysicalAddressOnChange = function () {
        if ($scope.IsMailingSameasPhysical == true) {
            $scope.MailingAddressLine1 = $scope.PhysicalAddressLine1;
            $scope.MailingAddressLine2 = $scope.PhysicalAddressLine2;
            $scope.MailingAddressCity = $scope.PhysicalAddressCity;
            $scope.MailingAddressState = $scope.PhysicalAddressState;
            $scope.MailingAddressZip = $scope.PhysicalAddressZip;
            $scope.IsMailingSameasPhysical = true;
            resetValidation();
        }
    }

    $scope.makeSameAsPhysicalAddress = function ($event) {
        var checkbox = $event.target;
        if (checkbox.checked) {
            $scope.MailingAddressLine1 = $scope.PhysicalAddressLine1;
            $scope.MailingAddressLine2 = $scope.PhysicalAddressLine2;
            $scope.MailingAddressCity = $scope.PhysicalAddressCity;
            $scope.MailingAddressState = $scope.PhysicalAddressState;
            $scope.MailingAddressZip = $scope.PhysicalAddressZip;
            $scope.IsMailingSameasPhysical = true;

        } else {
            $scope.MailingAddressLine1 = "";
            $scope.MailingAddressLine2 = "";
            $scope.MailingAddressCity = "";
            $scope.MailingAddressState = "";
            $scope.MailingAddressZip = "";
            $scope.IsMailingSameasPhysical = false;
        }
        resetValidation();
    }
}]);
