(function () {
    'use strict';

    angular
        .module('app.backofficeEntity')
        .controller("CashieringController", CashieringController);

    CashieringController.$inject = ["$scope", "$q", "$rootScope", "$state", "$uibModal",
        "cashieringService", "utilityService", "typeValuesService", "initialApplicationstatusService", "htmlToPdfGenerationService", "Messages"
    ];

    function CashieringController($scope, $q, $rootScope, $state, $uibModal,
        cashieringService, utilityService, typeValuesService, initialApplicationstatusService, htmlToPdfGenerationService, Messages) {
        $scope.userId = sessionStorage.UserID;

        $scope.validationObject = angular.copy($scope.validationObjectTemplate);
        $scope.feePayment = {};
        $scope.payment = {
            PaymentMethodSelected: "-1"
        };
        $scope.content = {};
        $scope.invoiceMaster = {};
        $scope.queueName = ((!!$state.current && !!$state.current.routeData && !!$state.current.routeData.queueType) ? $state.current.routeData.queueType : "");

        $scope.additionalCss = ['app/content/css/processHtmltoPdf.css'];
        $scope.headers = [{
            name: 'First Name',
            SortType: 'FirstName',
            SortOrder: 'asc'
        }, {
            name: 'Middle Name',
            SortType: 'MiddleName',
            SortOrder: 'asc'
        }, {
            name: 'Last Name',
            SortType: 'LastName',
            SortOrder: 'asc'
        }, {
            name: 'Organization Name',
            SortType: 'EntityName',
            SortOrder: 'asc'
        }, {
            name: 'SSN',
            SortType: 'SSN',
            SortOrder: 'asc'
        }, {
            name: 'Date Of Birth',
            SortType: 'DateOfBirth',
            SortOrder: 'asc'
        }, {
            name: 'Address',
            SortType: 'StreetLine1',
            SortOrder: 'asc'
        }, {
            name: 'City',
            SortType: 'City',
            SortOrder: 'asc'
        }, {
            name: 'State',
            SortType: 'State',
            SortOrder: 'asc'
        }, {
            name: 'Zip',
            SortType: 'Zip',
            SortOrder: 'asc'
        }];

        $scope.headersRevenue = [{
            name: 'select'
        }, {
            name: 'First Name',
            SortType: 'FirstName',
            SortOrder: 'asc'
        }, {
            name: 'Middle Name',
            SortType: 'MiddleName',
            SortOrder: 'asc'
        }, {
            name: 'Last Name',
            SortType: 'LastName',
            SortOrder: 'asc'
        }, {
            name: 'Organization Name',
            SortType: 'EntityName',
            SortOrder: 'asc'
        }, {
            name: 'SSN',
            SortType: 'SSN',
            SortOrder: 'asc'
        }, {
            name: 'Date Of Birth',
            SortType: 'DateOfBirth',
            SortOrder: 'asc'
        }, {
            name: 'Receipt Number',
            SortType: 'ReceiptNumber',
            SortOrder: 'asc'
        }, {
            name: 'Address',
            SortType: 'StreetLine1',
            SortOrder: 'asc'
        }, {
            name: 'City',
            SortType: 'City',
            SortOrder: 'asc'
        }, {
            name: 'State',
            SortType: 'State',
            SortOrder: 'asc'
        }, {
            name: 'Zip',
            SortType: 'Zip',
            SortOrder: 'asc'
        }, {
            name: 'Batch#',
            SortType: 'BatchNum',
            SortOrder: 'asc'
        }, {
            name: 'Deposit#',
            SortType: 'DepositNumber',
            SortOrder: 'asc'
        }, {
            name: 'Deposit Date',
            SortType: 'DepositDate',
            SortOrder: 'asc'
        }, {
            name: ' Collection #',
            SortType: 'UserDefinedDepositNo',
            SortOrder: 'asc'
        }, {
            name: ' Status',
            SortType: 'TransactionStatus',
            SortOrder: 'asc'
        }];

        $scope.maskConfig = {
            clearOnBlur: false,
            allowInvalidValue: true,
            clearOnBlurPlaceholder: false
        };

        $scope.paginationPayor = {
            CurrentPage: 1,
            maxSize: 5,
            TotalRecords: 10,
            PageSize: (!!localStorage.queuePageSize ? localStorage.queuePageSize : 10),
            SortType: "Default",
            SortOrder: "asc"
        };

        $scope.paginationBeneficiary = {
            CurrentPage: 1,
            maxSize: 5,
            TotalRecords: 10,
            PageSize: (!!localStorage.queuePageSize ? localStorage.queuePageSize : 10),
            SortType: "Default",
            SortOrder: "asc"
        };

        $scope.paginationReceipt = {
            CurrentPage: 1,
            maxSize: 5,
            TotalRecords: 10,
            PageSize: (!!localStorage.queuePageSize ? localStorage.queuePageSize : 10),
            SortType: "Default",
            SortOrder: "asc"
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        $scope.pageChanged = function (type) {
            if (type == 1) {
                $scope.searchPayorInformation($scope.paginationPayor.CurrentPage, null, type);
            } else if (type == 2) {
                $scope.searchPayorInformation($scope.paginationBeneficiary.CurrentPage, null, type);
            }
        };

        $scope.sortRecords = function (header, type) {
            if (type == 1) {
                $scope.paginationPayor.SortType = header.SortType;
                $scope.paginationPayor.SortOrder = header.SortOrder;
                header.SortOrder = (header.SortOrder == "asc" ? "desc" : "asc");
                $scope.searchPayorInformation($scope.paginationPayor.CurrentPage, null, type);
            } else if (type == 2) {
                $scope.paginationBeneficiary.SortType = header.SortType;
                $scope.paginationBeneficiary.SortOrder = header.SortOrder;
                header.SortOrder = (header.SortOrder == "asc" ? "desc" : "asc");
                $scope.searchPayorInformation($scope.paginationBeneficiary.CurrentPage, null, type);
            }
        };

        $scope.getPaymentMethod = function (paymentMode) {
            var allPaymentMethods = [{
                paymentMethodId: '-1',
                Name: "Select",
                isElectronic: false,
                showOnline: true,
                showBackoffice: true
            }, {
                paymentMethodId: '1',
                Name: "Check",
                isElectronic: false,
                showOnline: true,
                showBackoffice: true,
                PaymentMode: "CK"
            }, {
                paymentMethodId: '7',
                Name: "Cashiers Check",
                isElectronic: false,
                showOnline: false,
                showBackoffice: false,
                PaymentMode: "CCK"
            },
            {
                paymentMethodId: '2',
                Name: "Money Order",
                isElectronic: false,
                showOnline: true,
                showBackoffice: true,
                PaymentMode: "MO"
            },
            {
                paymentMethodId: '4',
                Name: "Click To Pay",
                isElectronic: false,
                showOnline: false,
                showBackoffice: false,
                PaymentMode: "PC"
            },
            {
                paymentMethodId: '5',
                Name: "Cash",
                isElectronic: false,
                showOnline: true,
                showBackoffice: true,
                PaymentMode: "CS"
            }, {
                paymentMethodId: '8',
                Name: "IBT",
                isElectronic: false,
                showOnline: false,
                showBackoffice: false,
                PaymentMode: "IBT"
            }, {
                paymentMethodId: '9',
                Name: "Refund",
                isElectronic: false,
                showOnline: false,
                showBackoffice: false,
                PaymentMode: "RD"
            }
            ];

            if (!!paymentMode) {
                var paymentMethod;
                angular.forEach(allPaymentMethods, function (paymentMethodItem) {
                    if (paymentMode == paymentMethodItem.PaymentMode) {
                        paymentMethod = paymentMethodItem;
                    }
                });
                return paymentMethod;
            } else {
                var paymentMethodList = [];
                angular.forEach(allPaymentMethods, function (paymentMethodItem) {
                    if (($scope.isBackoffice && paymentMethodItem.showBackoffice) || (!$scope.isBackoffice && paymentMethodItem.showOnline)) {
                        paymentMethodList.push(paymentMethodItem);
                    }
                });
                return paymentMethodList;
            }
        };

        var getIndividualTransactionObject = function (revFeeMasterId, amount, transCode) {
            return {
                RevFeeMasterId: revFeeMasterId,
                DebitAmt: 0,
                CreditAmt: amount,
                FeePaidAmount: amount,
                OrigFeeAmount: amount,
                TransCode: transCode,
                ModuleCode: "ADVDUES",
                TransType: "PAY"
            }
        };

        $scope.openConfirmationPopup = function (displayData) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'cashieringConfirmation.html',
                size: 'md',
                resolve: {
                    displayData: displayData
                },
                controller: ['$scope', '$uibModalInstance', 'displayData', function ($scope, $uibModalInstance, displayData) {
                    $scope.displayData = displayData;
                    if (!!$scope.displayData && !!$scope.displayData.ReceiptNumber) {
                        var invoiceNumberLength = $scope.displayData.ReceiptNumber.length;
                        for (var i = 0; i < (8 - invoiceNumberLength); i++) {
                            $scope.displayData.ReceiptNumber = "0" + $scope.displayData.ReceiptNumber;
                        }
                    }
                    $scope.onCancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.onConfirm = function () {
                        $uibModalInstance.close('success');
                    };
                }]
            });

            modalInstance.result.then(function () {
                $scope.paymentStatusUpdate({
                    action: 'close'
                });
            });
        };

        $scope.validationObjectTemplate = {
            Check: {
                CheckNo: false,
                CheckMailingPayment: false
            },
            Card: {
                CardNumber: false,
                Cvv: false,
                ExpDateMonth: false,
                ExpDateYear: false,
                AddressFirstName: false,
                AddressLastName: false,
                AddressStreet: false,
                AddressCity: false,
                AddressState: false,
                AddressZIP: false,
                AddressCountry: false,
            }
        };

        $scope.changePaymentMethod = function (isInternalChange) {
            $scope.payment.PaymentMethodSelected = $scope.entityPaymentInfo.PaymentMethodSelected;
            $scope.validationObject = angular.copy($scope.validationObjectTemplate);
            if (!!$scope.currentForm)
                $scope.currentForm.$submitted = false;
            if (!isInternalChange)
                $scope.payment.PaymentMethod = $scope.payment.PaymentMethodSelected;
            if ((['1', '2', '4', '5', '6', '7', '8', '9'].indexOf($scope.payment.PaymentMethod) != -1) && !!$scope.isBackoffice) {
                $scope.validationObject.Check = {
                    CheckNo: true,
                    CheckMailingPayment: true
                };
            } else if ($scope.payment.PaymentMethod == '3') {
                $scope.validationObject.Card = {
                    CardNumber: true,
                    Cvv: true,
                    ExpDateMonth: true,
                    ExpDateYear: true,
                    AddressFirstName: true,
                    AddressLastName: true,
                    AddressStreet: true,
                    AddressCity: true,
                    AddressState: true,
                    AddressZIP: true,
                    AddressCountry: true,
                };
            }
        };

        $scope.selectPayorBeneficiary = function (entityPayorItem, type) {
            if (type == 1) {
                $scope.viewConfig.showPayorSearchResult = false;
                $scope.entityPayor = entityPayorItem;
                $scope.entityPayor.IsActive = 1;
                $scope.entityPayor.IsDeleted = 0;
                $scope.viewConfig.createNewPayor = false;
            } else {
                $scope.viewConfig.showBeneficiarySearchResult = false;
                $scope.entityBeneficiary = entityPayorItem;
                $scope.entityBeneficiary.IsActive = 1;
                $scope.entityBeneficiary.IsDeleted = 0;
                $scope.viewConfig.createNewBeneficiary = false;
            }
            $scope.loadApplications();
        };

        $scope.resetApplications = function () {
            $scope.beneficiaryApplications = null;
            $scope.entityBeneficiaryOtherInfo.selectedApplication = null;
        };

        $scope.loadApplications = function () {
            if ($scope.viewConfig.SearchMode == 'payment') {
                if (!$scope.viewConfig.createNewPayor && !$scope.viewConfig.BeneficiaryNotSameAsPayor &&
                    !!$scope.entityPayor && !!$scope.entityPayor.IndividualId && $scope.entityPayor.EntityType == "I") {
                    $scope.getApplicationByIndividualId($scope.entityPayor.IndividualId);
                } else if (!$scope.viewConfig.createNewPayor && !$scope.viewConfig.BeneficiaryNotSameAsPayor &&
                    !!$scope.entityPayor && !!$scope.entityPayor.ProviderId && $scope.entityPayor.EntityType == "P") {
                    $scope.getApplicationByProviderId($scope.entityPayor.ProviderId);
                } else if (!$scope.viewConfig.createNewBeneficiary && !!$scope.viewConfig.BeneficiaryNotSameAsPayor &&
                    !!$scope.entityBeneficiary && !!$scope.entityBeneficiary.IndividualId && $scope.entityBeneficiary.EntityType == "I") {
                    $scope.getApplicationByIndividualId($scope.entityBeneficiary.IndividualId);
                } else if (!$scope.viewConfig.createNewBeneficiary && !!$scope.viewConfig.BeneficiaryNotSameAsPayor &&
                    !!$scope.entityBeneficiary && !!$scope.entityBeneficiary.ProviderId && $scope.entityBeneficiary.EntityType == "P") {
                    $scope.getApplicationByProviderId($scope.entityBeneficiary.ProviderId);
                } else {
                    $scope.resetApplications();
                }
            } else if ($scope.viewConfig.SearchMode == 'refundAndTransfer') {
                if (!$scope.viewConfig.createNewBeneficiary &&
                    !!$scope.entityBeneficiary && !!$scope.entityBeneficiary.IndividualId && $scope.entityBeneficiary.EntityType == "I") {

                    $scope.getApplicationByIndividualId($scope.entityBeneficiary.IndividualId);
                } else if (!$scope.viewConfig.createNewBeneficiary &&
                    !!$scope.entityBeneficiary && !!$scope.entityBeneficiary.ProviderId && $scope.entityBeneficiary.EntityType == "P") {
                    $scope.getApplicationByProviderId($scope.entityBeneficiary.ProviderId);
                } else {
                    $scope.resetApplications();
                }
            }
        };

        $scope.beneficiaryNotSameAsPayorChanged = function () {
            if (!$scope.viewConfig.BeneficiaryNotSameAsPayor) {
                resetBeneficiary();
            }
            $scope.loadApplications();
        };

        $scope.beneficiaryCreateNewApplicationChanged = function () {
            if (!$scope.entityBeneficiaryOtherInfo.createNewApplication) {
                $scope.entityBeneficiaryOtherInfo.selectedApplication = null;
                $scope.entityBeneficiaryOtherInfo.LicenseTypeId = null;
                $scope.entityBeneficiaryOtherInfo.CredentialNumber = null;
                $scope.entityBeneficiaryOtherInfo.ApplicationTypeId = null;
                $scope.entityBeneficiaryOtherInfo.selectedPaymentType = null;
            }
        };

        $scope.resetReceipts = function () {
            $scope.entitySelectedReceipt = null;
            angular.forEach($scope.entityReceipts, function (entityReceiptItem) {
                entityReceiptItem.showDetails = false;
                entityReceiptItem.showRefundProcess = false;
                entityReceiptItem.showTransferProcess = false;
                entityReceiptItem.showBadCheckProcess = false;
                entityReceiptItem.showReceiptDetail = false;
                entityReceiptItem.isSelected = false;
            });
        };

        $scope.openRefundProcess = function (entityReceiptItem) {
            $scope.resetReceipts();
            entityReceiptItem.showDetails = true;
            entityReceiptItem.showRefundProcess = true;

            $scope.entitySelectedReceipt = entityReceiptItem;
            $scope.loadingReceipt(true, "Please Wait...");
            var reqInvoiceDetail = {
                InvoiceId: entityReceiptItem.InvoiceId,
                IndividualId: entityReceiptItem.IndividualId,
                ProviderId: entityReceiptItem.ProviderId
            };
            cashieringService.getInvoiceDetail(sessionStorage.Key, reqInvoiceDetail)
                .then(function (objInvoiceDetail) {
                    if (objInvoiceDetail.StatusCode != "00") {
                        $scope.loadingReceipt(false);
                        $scope.showStatusMsg('-', objInvoiceDetail.Message);
                        return;
                    }
                    $scope.refundFeeDetailRows = objInvoiceDetail.EntityRecords;
                    angular.forEach($scope.refundFeeDetailRows, function (refundFeeDetailRow) {
                        refundFeeDetailRow.TransferAmount = refundFeeDetailRow.Amount - (!refundFeeDetailRow.ReducedAmount ? 0 : refundFeeDetailRow.ReducedAmount);
                        refundFeeDetailRow.PendingAmount = refundFeeDetailRow.TransferAmount;
                    });
                    $scope.loadingReceipt(false);
                }, function (error) {
                    $scope.loadingReceipt(false);
                    $scope.showStatusMsg('-', error.message);
                });
        };

        $scope.closePayment = function () {
            $scope.resetReceipts();
        }

        $scope.openReceiptDetail = function (entityReceiptItem) {
            $scope.resetReceipts();
            entityReceiptItem.showDetails = true;
            entityReceiptItem.showReceiptDetail = true;
            $scope.entitySelectedReceipt = entityReceiptItem;
            $scope.loadingReceipt(true, "Please Wait...");
            var reqInvoiceMaster = {
                InvoiceNo: entityReceiptItem.InvoiceId,
                InvoiceId: entityReceiptItem.InvoiceId,
                IndividualId: entityReceiptItem.IndividualId,
                ProviderId: entityReceiptItem.ProviderId
            };
            var payorReqObj = {
                CurrentPage: 1,
                maxSize: 5,
                TotalRecords: 10,
                PageSize: 10,
                SortType: "Default",
                SortOrder: "asc",
                Data: {
                    EntityId: entityReceiptItem.PayorId,
                    EntityType: entityReceiptItem.PayorType,
                    AddressTypeId: ((entityReceiptItem.PayorType == "I") ? $scope.uiConfig.Individual.AddressTypeId : $scope.uiConfig.Provider.AddressTypeId)
                }
            };

            var reqRefundInvoiceMaster = {
                LinkInvoiceId: entityReceiptItem.InvoiceId
            };
            var reqRefundInvoiceDetail = {
                IndividualId: entityReceiptItem.IndividualId,
                ProviderId: entityReceiptItem.ProviderId
            };
            var reqRefundRevFeeCollect = {
                IndividualId: entityReceiptItem.IndividualId,
                ProviderId: entityReceiptItem.ProviderId
            };
            var beneficiaryReqObj = {
                CurrentPage: 1,
                maxSize: 5,
                TotalRecords: 10,
                PageSize: 10,
                SortType: "Default",
                SortOrder: "asc",
                Data: {
                    EntityId: entityReceiptItem.EntityId,
                    EntityType: entityReceiptItem.EntityType,
                    AddressTypeId: ((entityReceiptItem.EntityType == "I") ? $scope.uiConfig.Individual.AddressTypeId : $scope.uiConfig.Provider.AddressTypeId)
                }
            };

            var dataPromises = {
                invoiceMaster: cashieringService.getInvoiceMaster(sessionStorage.Key, reqInvoiceMaster),
                revFeeCollect: cashieringService.getRevFeeCollect(sessionStorage.Key, reqInvoiceMaster),
                invoiceDetail: cashieringService.getInvoiceDetail(sessionStorage.Key, reqInvoiceMaster),
                payorDetail: cashieringService.getEntityPayorBeneficiary(sessionStorage.Key, payorReqObj),
                refundInvoiceMaster: cashieringService.getInvoiceMaster(sessionStorage.Key, reqRefundInvoiceMaster),
                refundInvoiceDetail: cashieringService.getInvoiceDetail(sessionStorage.Key, reqRefundInvoiceDetail),
                refundRevFeeCollect: cashieringService.getRevFeeCollect(sessionStorage.Key, reqRefundRevFeeCollect),
                application: cashieringService.getApplicationInfoByApplicationId(entityReceiptItem.ApplicationId, $scope.userId, sessionStorage.Key),
                beneficiaryDetail: cashieringService.getEntityPayorBeneficiary(sessionStorage.Key, beneficiaryReqObj)
            };

            $q.all(dataPromises)
                .then(function (response) {
                    for (var prop in response) {
                        if (!response[prop].Status) {
                            $scope.loadingReceipt(false);
                            $scope.showStatusMsg('-', response[prop].Message);
                            return;
                        }
                    }

                    if (response.invoiceMaster) {
                        if (!!response.invoiceMaster.EntityRecords && response.invoiceMaster.EntityRecords.length > 0) {
                            $scope.entitySelectedReceipt.invoiceMaster = response.invoiceMaster.EntityRecords[0];
                            if (response.invoiceDetail) {
                                $scope.entitySelectedReceipt.invoiceMaster.invoiceDetails = response.invoiceDetail.EntityRecords;
                            }
                            if (response.revFeeCollect) {
                                $scope.entitySelectedReceipt.invoiceMaster.revFeeCollect = response.revFeeCollect.EntityRecords[0];
                            }
                        }
                    }

                    if (response.payorDetail) {
                        if (!!response.payorDetail.PagerVM.Records && response.payorDetail.PagerVM.Records.length > 0) {
                            $scope.entitySelectedReceipt.payorDetail = response.payorDetail.PagerVM.Records[0];
                        }
                    }


                    if (response.beneficiaryDetail) {
                        if (!!response.beneficiaryDetail.PagerVM.Records && response.beneficiaryDetail.PagerVM.Records.length > 0) {
                            $scope.entitySelectedReceipt.beneficiaryDetail = response.beneficiaryDetail.PagerVM.Records[0];
                        }
                    }

                    if (response.application) {
                        if (!!response.application.ApplicationResponseList && response.application.ApplicationResponseList.length > 0) {
                            $scope.entitySelectedReceipt.applicationInfo = response.application.ApplicationResponseList[0];
                        }
                    }

                    if (response.refundInvoiceMaster) {
                        if (!!response.refundInvoiceMaster.EntityRecords && response.refundInvoiceMaster.EntityRecords.length > 0) {
                            $scope.entitySelectedReceipt.refundInvoiceMasters = response.refundInvoiceMaster.EntityRecords
                                .whereEquals('RD', 'InvoiceTransactionType');
                            if (!!$scope.entitySelectedReceipt.refundInvoiceMasters && $scope.entitySelectedReceipt.refundInvoiceMasters.length > 0) {
                                angular.forEach($scope.entitySelectedReceipt.refundInvoiceMasters, function (refundInvoiceMaster) {
                                    if (response.refundInvoiceDetail) {
                                        refundInvoiceMaster.invoiceDetails = response.refundInvoiceDetail.EntityRecords
                                            .whereEquals(refundInvoiceMaster.InvoiceID, 'InvoiceID');
                                    }
                                    if (response.revFeeCollect) {
                                        refundInvoiceMaster.revFeeCollect = response.refundRevFeeCollect.EntityRecords
                                            .whereEquals(refundInvoiceMaster.InvoiceID, 'InvoiceNo').firstOrDefault();
                                    }
                                });
                            }
                            var transferInvoiceMasters = response.refundInvoiceMaster.EntityRecords.whereEquals('TR', 'InvoiceTransactionType')
                                .whereEquals($scope.entitySelectedReceipt.EntityId, (($scope.entitySelectedReceipt.EntityType == "I") ? 'IndividualId' : "ProviderId"));

                            if (!!transferInvoiceMasters && transferInvoiceMasters.length > 0) {
                                $scope.entitySelectedReceipt.transferInvoiceMaster = transferInvoiceMasters[0];

                                if (response.refundInvoiceDetail) {
                                    $scope.entitySelectedReceipt.transferInvoiceMaster.invoiceDetails = response.refundInvoiceDetail.EntityRecords
                                        .whereEquals($scope.entitySelectedReceipt.transferInvoiceMaster.InvoiceID, 'InvoiceID');
                                }
                                if (response.revFeeCollect) {
                                    $scope.entitySelectedReceipt.transferInvoiceMaster.revFeeCollect = response.refundRevFeeCollect.EntityRecords
                                        .whereEquals($scope.entitySelectedReceipt.transferInvoiceMaster.InvoiceID, 'InvoiceNo');
                                }

                                if (transferInvoiceMasters.length > 1) {
                                    $scope.entitySelectedReceipt.transferToInvoiceMaster = transferInvoiceMasters[1];

                                    if (response.refundInvoiceDetail) {
                                        $scope.entitySelectedReceipt.transferToInvoiceMaster.invoiceDetails = response.refundInvoiceDetail.EntityRecords
                                            .whereEquals($scope.entitySelectedReceipt.transferToInvoiceMaster.InvoiceID, 'InvoiceID');
                                    }
                                    if (response.revFeeCollect) {
                                        $scope.entitySelectedReceipt.transferToInvoiceMaster.revFeeCollect = response.refundRevFeeCollect.EntityRecords
                                            .whereEquals($scope.entitySelectedReceipt.transferToInvoiceMaster.InvoiceID, 'InvoiceNo');
                                    }
                                }
                            }
                        }
                    }

                    $scope.loadingReceipt(false);
                }, function (error) {
                    $scope.loadingReceipt(false);
                    $scope.showStatusMsg('-', error.message);
                });
        };

        $scope.resetBadCheckProcess = function () {
            $scope.resetReceipts();
            $scope.viewConfig.isAnySelected = false;
            $scope.viewConfig.isAllSelected = false;
            $scope.viewConfig.showBadCheckProcess = false;
        };

        $scope.openBadCheckProcess = function () {
            $scope.viewConfig.showBadCheckProcess = true;
        };

        $scope.openTransferProcess = function (entityReceiptItem, isReceiptEdit) {
            $scope.resetReceipts();
            entityReceiptItem.showDetails = true;
            entityReceiptItem.showTransferProcess = true;
            entityReceiptItem.isReceiptEdit = !!isReceiptEdit;
            $scope.entitySelectedReceipt = entityReceiptItem;
            $scope.loadingReceipt(true, "Please Wait...");
            var reqInvoiceDetail = {
                InvoiceId: entityReceiptItem.InvoiceId,
                IndividualId: entityReceiptItem.IndividualId,
                ProviderId: entityReceiptItem.ProviderId
            };
            cashieringService.getInvoiceDetail(sessionStorage.Key, reqInvoiceDetail)
                .then(function (objInvoiceDetail) {
                    if (objInvoiceDetail.StatusCode != "00") {
                        $scope.loadingReceipt(false);
                        $scope.showStatusMsg('-', objInvoiceDetail.Message);
                        return;
                    }
                    $scope.transferFeeDetailRows = objInvoiceDetail.EntityRecords;
                    angular.forEach($scope.transferFeeDetailRows, function (transferFeeDetailRow) {
                        transferFeeDetailRow.TransferAmount = transferFeeDetailRow.Amount - (!transferFeeDetailRow.ReducedAmount ? 0 : transferFeeDetailRow.ReducedAmount);
                        transferFeeDetailRow.PendingAmount = transferFeeDetailRow.TransferAmount;
                    });
                    $scope.loadingReceipt(false);
                }, function (error) {
                    $scope.loadingReceipt(false);
                    $scope.showStatusMsg('-', error.message);
                });
        };

        $scope.createNewPayorBeneficiary = function (type) {
            if (type == 1) {
                $scope.viewConfig.showPayorSearchResult = false;
                if (!!$scope.entityPayor) {
                    $scope.entityPayor.IndividualId = null;
                    $scope.entityPayor.IsActive = 1;
                    $scope.entityPayor.IsDeleted = 0;
                };
                $scope.viewConfig.createNewPayor = true;
            } else if (type == 2) {
                $scope.viewConfig.showBeneficiarySearchResult = false;
                if (!!$scope.entityBeneficiary) {
                    $scope.entityBeneficiary.IndividualId = null;
                    $scope.entityBeneficiary.IsActive = 1;
                    $scope.entityBeneficiary.IsDeleted = 0;
                };
                $scope.viewConfig.createNewBeneficiary = true;
            }
            $scope.loadApplications();
        };

        $scope.searchPayorInformation = function (currentPageNumber, showAllRecords, type, searchMode) {
            var pagination = null;
            if (type == 1) {
                $scope.paginationPayor.CurrentPage = (!!currentPageNumber ? currentPageNumber : (!!$scope.paginationPayor.CurrentPage ? $scope.paginationPayor.CurrentPage : 1));
                $scope.paginationPayor.ShowAllRecords = (!!showAllRecords);
                $scope.paginationPayor.Data = {
                    EntityType: $scope.entityPayor.EntityType,
                    LastName: $scope.entityPayor.LastName,
                    FirstName: $scope.entityPayor.FirstName,
                    MiddleName: $scope.entityPayor.MiddleName,
                    EntityName: $scope.entityPayor.EntityName,
                    SSN: $scope.entityPayor.SSN,
                    DateOfBirth: $scope.entityPayor.DateOfBirth,
                    ReceiptNumber: $scope.entityPayor.ReceiptNumber,
                    SearchMode: (!!searchMode ? searchMode : $scope.viewConfig.SearchMode),
                    AddressTypeId: $scope.uiConfig.Individual.AddressTypeId
                };
                pagination = $scope.paginationPayor;
                $scope.loadingPayor(true, "Please Wait...");
            } else if (type == 2) {
                $scope.paginationBeneficiary.CurrentPage = (!!currentPageNumber ? currentPageNumber : (!!$scope.paginationBeneficiary.CurrentPage ? $scope.paginationBeneficiary.CurrentPage : 1));
                $scope.paginationBeneficiary.ShowAllRecords = (!!showAllRecords);
                $scope.paginationBeneficiary.Data = {
                    EntityType: $scope.entityBeneficiary.EntityType,
                    LastName: $scope.entityBeneficiary.LastName,
                    FirstName: $scope.entityBeneficiary.FirstName,
                    MiddleName: $scope.entityBeneficiary.MiddleName,
                    EntityName: $scope.entityBeneficiary.EntityName,
                    SSN: $scope.entityBeneficiary.SSN,
                    DateOfBirth: $scope.entityBeneficiary.DateOfBirth,
                    ReceiptNumber: $scope.entityBeneficiary.ReceiptNumber,
                    SearchMode: (!!searchMode ? searchMode : $scope.viewConfig.SearchMode),
                    AddressTypeId: $scope.uiConfig.Individual.AddressTypeId
                };
                pagination = $scope.paginationBeneficiary;
                $scope.loadingBeneficiary(true, "Please Wait...");
            }
            cashieringService.getEntityPayorBeneficiary(sessionStorage.Key, pagination)
                .then(function (response) {
                    if (!response.Status) {
                        $scope.loadingPayor(false);
                        $scope.loadingBeneficiary(false);
                        return;
                    }
                    if (type == 1) {
                        $scope.paginationPayor.TotalRecords = response.PagerVM.TotalRecords;
                        $scope.viewConfig.showPayorSearchResult = true;
                        $scope.entityPayors = response.PagerVM.Records;
                        $scope.loadingPayor(false);
                    } else if (type == 2) {
                        $scope.paginationBeneficiary.TotalRecords = response.PagerVM.TotalRecords;
                        $scope.viewConfig.showBeneficiarySearchResult = true;
                        $scope.entityBeneficiaries = response.PagerVM.Records;
                        $scope.loadingBeneficiary(false);
                    }
                });
        };

        $scope.searchReceiptInformation = function (currentPageNumber, showAllRecords, type) {
            var pagination = null;
            if (type == 1) {
                $scope.paginationReceipt.CurrentPage = (!!currentPageNumber ? currentPageNumber : (!!$scope.paginationReceipt.CurrentPage ? $scope.paginationReceipt.CurrentPage : 1));
                $scope.paginationReceipt.ShowAllRecords = (!!showAllRecords);
                $scope.paginationReceipt.Data = {
                    LastName: $scope.entityReceiptSearch.LastName,
                    FirstName: $scope.entityReceiptSearch.FirstName,
                    MiddleName: $scope.entityReceiptSearch.MiddleName,
                    EntityName: $scope.entityReceiptSearch.EntityName,
                    SSN: $scope.entityReceiptSearch.SSN,
                    DateOfBirth: $scope.entityReceiptSearch.DateOfBirth,
                    ReceiptNumber: $scope.entityReceiptSearch.ReceiptNumber,
                    SearchMode: $scope.viewConfig.SearchMode,
                    AddressTypeId: $scope.uiConfig.Individual.AddressTypeId,
                    ShowAllReceipts: $scope.entityReceiptSearch.ShowAllReceipts
                };
                pagination = $scope.paginationReceipt;
                $scope.loadingReceipt(true, "Please Wait...");
            } else if (type == 2) {
                $scope.paginationBeneficiary.CurrentPage = (!!currentPageNumber ? currentPageNumber : (!!$scope.paginationBeneficiary.CurrentPage ? $scope.paginationBeneficiary.CurrentPage : 1));
                $scope.paginationBeneficiary.ShowAllRecords = (!!showAllRecords);
                $scope.paginationBeneficiary.Data = {
                    LastName: $scope.entityBeneficiary.LastName,
                    FirstName: $scope.entityBeneficiary.FirstName,
                    MiddleName: $scope.entityBeneficiary.MiddleName,
                    SSN: $scope.entityBeneficiary.SSN,
                    DateOfBirth: $scope.entityBeneficiary.DateOfBirth,
                    SearchMode: $scope.viewConfig.SearchMode
                };
                pagination = $scope.paginationBeneficiary;
                $scope.loadingBeneficiary(true, "Please Wait...");
            }
            cashieringService.getEntityPayorBeneficiary(sessionStorage.Key, pagination)
                .then(function (response) {
                    if (!response.Status) {
                        $scope.loadingReceipt(false);
                        $scope.loadingBeneficiary(false);
                        return;
                    }
                    $scope.entitySelectedReceipt = null;
                    if (type == 1) {
                        $scope.paginationReceipt.TotalRecords = response.PagerVM.TotalRecords;
                        $scope.viewConfig.showReceiptSearchResult = true;
                        $scope.entityReceipts = response.PagerVM.Records;
                        angular.forEach($scope.entityReceipts, function (entityReceiptItem) {
                            entityReceiptItem.canView = true;
                            entityReceiptItem.canEdit = (!entityReceiptItem.DepositNumber && !entityReceiptItem.BadCheck && !!$scope.revDrawer && (entityReceiptItem.BatchNum == $scope.revDrawer.DrawerNumber)
                                && (entityReceiptItem.AmountBilled > (!entityReceiptItem.ReducedAmount ? 0 : entityReceiptItem.ReducedAmount)));
                            entityReceiptItem.canDelete = (!entityReceiptItem.DepositNumber && !entityReceiptItem.BadCheck && !!$scope.revDrawer && (entityReceiptItem.BatchNum == $scope.revDrawer.DrawerNumber)
                                && !entityReceiptItem.ReducedAmount);
                            entityReceiptItem.canRefund = (!entityReceiptItem.BadCheck && (!!entityReceiptItem.DepositNumber || (!!entityReceiptItem.PaymentMode && entityReceiptItem.PaymentMode == 'CC'))
                                && (entityReceiptItem.AmountBilled > (!entityReceiptItem.ReducedAmount ? 0 : entityReceiptItem.ReducedAmount)));
                            entityReceiptItem.canTransfer = (!entityReceiptItem.BadCheck && (!!entityReceiptItem.DepositNumber || (!!entityReceiptItem.PaymentMode && entityReceiptItem.PaymentMode == 'CC'))
                                && (entityReceiptItem.AmountBilled > (!entityReceiptItem.ReducedAmount ? 0 : entityReceiptItem.ReducedAmount)));
                        });
                        $scope.loadingReceipt(false);
                    } else if (type == 2) {
                        $scope.paginationBeneficiary.TotalRecords = response.PagerVM.TotalRecords;
                        $scope.viewConfig.showBeneficiarySearchResult = true;
                        $scope.entityBeneficiaries = response.PagerVM.Records;
                        $scope.loadingBeneficiary(false);
                    }
                });
        };

        var getUIConfig = function () {
            var configToUse = $scope.config.UIConfigs[$scope.configName];
            if (!configToUse) {
                configToUse = $scope.config.UIConfigs['Default'];
            }
            return configToUse;
        };

        $scope.init = function () {
            $scope.viewConfig = {
                showAddBlock: false,
                proceedToPayment: false,
                proceedToRefund: false,
                proceedToTrasfer: false
            };
            if (!$scope.transactionType)
                $scope.transactionType = 'MISC';

            $scope.feeDetails = {
                discrepancyAmount: 0
            };

            $scope.refundFeeDetails = {
                discrepancyAmount: 0
            };

            $scope.transferFeeDetails = {
                discrepancyAmount: 0
            };

            $scope.validator = {
                feePayment: {}
            };

            $scope.datePicker = {
                fromDateOpened: false,
                toDateOpened: false,
                format: 'M!/d!/yyyy',
                startDateOptions: {
                    minDate: null,
                },
                endDateOptions: {}
            };
            $scope.badCheckInfo = {};
            $scope.entityPayor = {};
            $scope.entityBeneficiary = {};
            $scope.entityBeneficiaryOtherInfo = {
                createNewApplication: true
            };
            $scope.entityBeneficiariesData = [];
            $scope.entityPaymentInfo = {};
            $scope.entityReceiptSearch = {};
            $scope.individualId = $scope.entityId;
            $scope.PaymentMethodList = $scope.getPaymentMethod();
            $scope.viewConfig.createNewPayor = true;
            $scope.viewConfig.createNewBeneficiary = true;
            utilityService.getConfigData('/components/backoffice/entity/cashiering/cashiering.server-config.json').
                then(function (data) {
                    var config = data;
                    $scope.config = config;
                    $scope.uiConfig = getUIConfig();
                    $scope.loadFinanceData();
                    $scope.getOpenRevDrawer();
                });
        };

        var resetInvoiceMaster = function () {
            $scope.invoiceMaster = {};
            $scope.entityPaymentInfo = {

            };
        };

        var detectCardType = function (number) {
            var re = {
                VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
                MCRD: /^5[1-5][0-9]{14}$/,
                AMEX: /^3[47][0-9]{13}$/,
                DISC: /^6(?:011|5[0-9]{2})[0-9]{12}$/
            }

            for (var key in re) {
                if (re[key].test(number)) {
                    return key
                }
            }
        };

        $scope.loadFinanceData = function () {
            var dataPromises = [];

            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=feegroup")));
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=feegroupdetailslk")));
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=feedetails")));
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=licensetype")));
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=applicationtype")));
            dataPromises.push(utilityService.getServerDate());

            $q.all(dataPromises)
                .then(function (response) {
                    angular.forEach(response, function (responseItem, itemIndex) {
                        if (!!responseItem.Status) {
                            if (itemIndex == 0) {
                                $scope.feeDetailGroups = responseItem.ReferenceEntities;
                            } else if (itemIndex == 1) {
                                $scope.feeDetailLks = responseItem.ReferenceEntities;
                            } else if (itemIndex == 2) {
                                $scope.feeDetailTypes = responseItem.ReferenceEntities.orderBy('FeeDetailDesc');
                            } else if (itemIndex == 3) {
                                $scope.licenseTypes = responseItem.ReferenceEntities;
                            } else if (itemIndex == 4) {
                                var applicationTypes = null;
                                if (!!responseItem.ReferenceEntities && responseItem.ReferenceEntities.length > 0) {
                                    applicationTypes = responseItem.ReferenceEntities.whereIncludes($scope.uiConfig.ApplicationTypeIds, 'ApplicationTypeId');
                                }
                                $scope.applicationTypes = applicationTypes;
                            }
                        } else {
                            if (itemIndex == 5) {
                                $scope.currentDate = new Date(responseItem);
                            } else {
                                showStatusMessage(responseItem.Message, "error")
                            }
                        }
                    });
                    resetInvoiceMaster();
                    $scope.isFinanceLoadedOnce = true;
                    $scope.loadingCashiering(false);
                }, function (data) {
                    $scope.loadingCashiering(false);
                    if (data != null) {
                        showStatusMessage(data.message, "error");
                    }
                });
        };

        $scope.feeDetailTypeChanged = function (individualFinance) {
            if (!!individualFinance.feeDetailType) {
                individualFinance.Amount = individualFinance.feeDetailType.FeeAmount;
            } else {
                individualFinance.Amount = 0;
            }
        };

        $scope.loadFeeDetailRows = function (feeGroupCode, feeGroupId, referenceNumber, reloadFeeDetails, isPaymentProcess) {
            if (!!isPaymentProcess) {
                if (!$scope.entityBeneficiaryOtherInfo)
                    $scope.entityBeneficiaryOtherInfo = { feeDetailRows: [] };
                if (!!reloadFeeDetails || !$scope.entityBeneficiaryOtherInfo.feeDetailRows)
                    $scope.entityBeneficiaryOtherInfo.feeDetailRows = [];
            } else {
                if (!!reloadFeeDetails || !$scope.feeDetailRows)
                    $scope.feeDetailRows = [];
            }
            var feeDetailRows = [];

            angular.forEach($scope.feeDetailTypes, function (feeDetailType) {
                var feeDetailLinks = $scope.feeDetailLks.whereEquals(feeDetailType.FeeDetailId, 'FeeDetailId');
                if (!!feeGroupId) {
                    feeDetailLinks = feeDetailLinks.whereEquals(feeGroupId, 'FeeGroupId');
                }
                if (!!feeGroupCode) {
                    feeDetailLinks = feeDetailLinks.whereEquals(feeGroupCode, 'FeeGroupCode');
                }
                if (!!feeDetailLinks && feeDetailLinks.length > 0) {
                    feeDetailType.ReferenceNumber = referenceNumber;
                    feeDetailRows.push({
                        Amount: feeDetailType.FeeAmount,
                        feeDetailType: feeDetailType,
                        ReferenceNumber: referenceNumber // 
                    });
                }
            });
            if (!!isPaymentProcess) {
                $scope.entityBeneficiaryOtherInfo.feeDetailRows = $scope.entityBeneficiaryOtherInfo.feeDetailRows.concat(feeDetailRows);
            } else {
                $scope.feeDetailRows = $scope.feeDetailRows.concat(feeDetailRows);
            }
        };

        var validateForm = function (formsToValidate) {
            $scope.natValidation = {
                errorMessages: []
            };

            if (!!formsToValidate) {
                angular.forEach(formsToValidate, function (currentForm, index) {
                    currentForm.$submitted = true;
                    if (!!currentForm.errorMessages) {
                        var errorMessages = angular.copy(currentForm.errorMessages);
                        angular.forEach(errorMessages, function (errorMessage, index) {
                            $scope.natValidation.errorMessages.push(errorMessage);
                        });
                    }

                    if (!!currentForm && currentForm.$name == "cashieringForm") {
                        var cashieringErrors = [];
                        if (!$scope.entityBeneficiariesData || $scope.entityBeneficiariesData.length == 0) {
                            cashieringErrors.push({
                                propName: "AtleastOneBeneficiary",
                                errorMessage: Messages.General.Errors.AtleastOneBeneficiary
                            });
                        }
                        var totalFeePaymentAmount = 0;
                        if (!!$scope.entityBeneficiariesData && $scope.entityBeneficiariesData.length != 0) {
                            angular.forEach($scope.entityBeneficiariesData, function (entityBeneficiaryDataItem) {
                                if (!!entityBeneficiaryDataItem.entityBeneficiaryOtherInfo && !!entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.feeDetailRows) {
                                    totalFeePaymentAmount = totalFeePaymentAmount + entityBeneficiaryDataItem.TotalAmount;
                                }
                            });
                        }
                        if (totalFeePaymentAmount != $scope.entityPaymentInfo.paymentAmount) {
                            cashieringErrors.push({
                                propName: "PaymentAmountNotMatching",
                                errorMessage: Messages.General.Errors.PaymentAmountNotMatching
                            });
                        }
                        if (!!cashieringErrors && cashieringErrors.length > 0) {
                            $scope.natValidation.errorMessages = $scope.natValidation.errorMessages.concat(cashieringErrors);
                            return;
                        }
                    }

                    if (!!currentForm && currentForm.$name == "beneficiarySearchForm") {
                        var beneficiaryErrors = [];
                        if ($scope.viewConfig.SearchMode != 'refundAndTransfer') {
                            if (!$scope.entityBeneficiaryOtherInfo.feeDetailRows || $scope.entityBeneficiaryOtherInfo.feeDetailRows.length == 0) {
                                beneficiaryErrors.push({
                                    propName: "AtleastOneFeeType",
                                    errorMessage: Messages.General.Errors.FeeType
                                });
                            }
                            if ($scope.entityBeneficiary && !$scope.viewConfig.BeneficiaryNotSameAsPayor
                                && (!$scope.entityPayor || !($scope.entityPayor.LastName || $scope.entityPayor.EntityName))) {
                                beneficiaryErrors.push({
                                    propName: "PayorBeforeBeneficiary",
                                    errorMessage: Messages.General.Errors.PayorBeforeBeneficiary
                                });
                            }
                        }
                        if (!!$scope.entityBeneficiaryOtherInfo.createNewApplication) {
                            if (($scope.viewConfig.SearchMode == 'payment' && !$scope.viewConfig.BeneficiaryNotSameAsPayor)) {
                                if (!!$scope.entityPayor.EntityType && $scope.uiConfig.AllowedBeneficiaryEntityTypes.indexOf($scope.entityPayor.EntityType) == -1) {
                                    beneficiaryErrors.push({
                                        propName: "BeneficiaryTypeError",
                                        errorMessage: (($scope.entityPayor.EntityType == "I") ? Messages.General.Errors.IndividualCanNotBeBeneficiary : Messages.General.Errors.OrganizationCanNotBeBeneficiary)
                                    });
                                }
                            } else if (($scope.viewConfig.SearchMode == 'payment' && !!$scope.viewConfig.BeneficiaryNotSameAsPayor) || ($scope.viewConfig.SearchMode == 'refundAndTransfer')) {
                                if (!!$scope.entityBeneficiary.EntityType && $scope.uiConfig.AllowedBeneficiaryEntityTypes.indexOf($scope.entityBeneficiary.EntityType) == -1) {
                                    beneficiaryErrors.push({
                                        propName: "BeneficiaryTypeError",
                                        errorMessage: (($scope.entityBeneficiary.EntityType == "I") ? Messages.General.Errors.IndividualCanNotBeBeneficiary : Messages.General.Errors.OrganizationCanNotBeBeneficiary)
                                    });
                                }
                            }
                        }

                        if (!!beneficiaryErrors && beneficiaryErrors.length > 0) {
                            $scope.natValidation.errorMessages = $scope.natValidation.errorMessages.concat(beneficiaryErrors);
                            return;
                        }
                    }

                    if (!!currentForm && currentForm.$name == "cashieringRefundForm") {
                        var refundFeeDetailRows = null;
                        var revenueRefundErrors = [];
                        if (!!$scope.refundFeeDetailRows && $scope.refundFeeDetailRows.length > 0) {
                            refundFeeDetailRows = $scope.refundFeeDetailRows.whereEquals(true, 'isSelected');
                        }

                        if (!refundFeeDetailRows || refundFeeDetailRows.length == 0) {
                            revenueRefundErrors.push({
                                propName: "AtleastOneRefundRow",
                                errorMessage: Messages.General.Errors.FeeRefund
                            });
                        } else {
                            var transferAmountInvalidExists = refundFeeDetailRows
                                .any('item.TransferAmount>item.PendingAmount');
                            if (transferAmountInvalidExists) {
                                revenueRefundErrors.push({
                                    propName: "InvalidRefundAmount",
                                    errorMessage: "Refund amount can not be greated than amount pending for the detail code"
                                });
                            }
                        }

                        if (!!revenueRefundErrors && revenueRefundErrors.length > 0) {
                            $scope.natValidation.errorMessages = $scope.natValidation.errorMessages.concat(revenueRefundErrors);
                            return;
                        }
                    }
                    if (!!currentForm && currentForm.$name == "revenueTransferForm") {
                        var revenueTransferErrors = [];
                        var transferFeeDetailRows = null
                        if (!!$scope.transferFeeDetailRows && $scope.transferFeeDetailRows.length > 0) {
                            transferFeeDetailRows = $scope.transferFeeDetailRows.whereEquals(true, 'isSelected');
                        }
                        if (!transferFeeDetailRows || transferFeeDetailRows.length == 0) {
                            revenueTransferErrors.push({
                                propName: "AtleastOneRefundRow",
                                errorMessage: Messages.General.Errors.FeeTransfer
                            });
                        } else if (!!$scope.entitySelectedReceipt.TransferToNewAccountCode) {
                            var selectedAmount = transferFeeDetailRows.sum('TransferAmount');
                            var feeDetailsAmount = $scope.feeDetailRows.sum('Amount');
                            if (selectedAmount != feeDetailsAmount) {
                                revenueTransferErrors.push({
                                    propName: "AtleastOneRefundRow",
                                    errorMessage: "Payment Amount is not matching to total of all beneficiary fee types."
                                });
                            }
                        }
                        if (!(!transferFeeDetailRows || transferFeeDetailRows.length == 0)) {
                            var transferAmountInvalidExists = transferFeeDetailRows
                                .any('item.TransferAmount>item.PendingAmount');
                            if (transferAmountInvalidExists) {
                                revenueTransferErrors.push({
                                    propName: "InvalidTransferAmount",
                                    errorMessage: "Transfer amount can not be greated than amount pending for the detail code"
                                });
                            }
                        }

                        if (!!revenueTransferErrors && revenueTransferErrors.length > 0) {
                            $scope.natValidation.errorMessages = $scope.natValidation.errorMessages.concat(revenueTransferErrors);
                            return;
                        }
                    }
                });
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.showStatusMsg('-', $scope.natValidation.errorMessages);
                return false;
            } else {
                $scope.hideStatusMsg();
                return true;
            }
        };

        $scope.getTotalCashieringFee = function (feeDetailRows) {
            if (!$scope.viewConfig.proceedToPayment) {
                var totalFee = 0;
                angular.forEach(feeDetailRows, function (feeDetailRow) {
                    totalFee = totalFee + (1 * feeDetailRow.Amount);
                });
                $scope.feeDetails.totalFee = totalFee;
                $scope.feeDetails.applicationFee = totalFee;
            } else {
                return $scope.feeDetails.totalFee;
            }
            return totalFee;
        };

        $scope.getTotalRefundFee = function () {
            if (!$scope.viewConfig.proceedToRefund) {
                var totalFee = 0;
                angular.forEach($scope.refundFeeDetailRows, function (refundFeeDetailRow) {
                    totalFee = totalFee + (1 * refundFeeDetailRow.Amount);
                });
                $scope.refundFeeDetails.totalFee = totalFee;
                $scope.refundFeeDetails.applicationFee = totalFee;
            } else {
                return $scope.refundFeeDetails.totalFee;
            }
            return totalFee;
        };

        $scope.getTotalTransferFee = function () {
            if (!$scope.viewConfig.proceedToTrasfer) {
                var totalFee = 0;
                angular.forEach($scope.transferFeeDetailRows, function (transferFeeDetailRow) {
                    totalFee = totalFee + (1 * transferFeeDetailRow.Amount);
                });
                $scope.transferFeeDetails.totalFee = totalFee;
                $scope.transferFeeDetails.applicationFee = totalFee;
            } else {
                return $scope.transferFeeDetails.totalFee;
            }
            return totalFee;
        };

        var addressExists = function (address) {
            return (!!address.StreetLine1 || !!address.StreetLine2 || !!address.City || !!address.StateCode || !!address.Zip);
        };

        var saveEntityBeneficiaryRecord = function () {
            var deffer = $q.defer();
            if (!!$scope.entitySelectedReceipt.TransferToNewBeneficiary && !!$scope.viewConfig.createNewBeneficiary) {
                var beneficiarySavePromise = null;
                if ($scope.entityBeneficiary.EntityType == "I") {
                    beneficiarySavePromise = cashieringService.individualSave(sessionStorage.Key, [$scope.entityBeneficiary]);
                } else {
                    $scope.entityBeneficiary.ProviderName = $scope.entityBeneficiary.EntityName;
                    $scope.entityBeneficiary.ProviderTypeId = $scope.uiConfig.Provider.ProviderTypeId;
                    $scope.entityBeneficiary.ProviderStatusTypeId = $scope.uiConfig.Provider.ProviderStatusTypeId;
                    $scope.entityBeneficiary.IsActive = true;
                    $scope.entityBeneficiary.IsDeleted = false;
                    beneficiarySavePromise = cashieringService.saveProvider(sessionStorage.Key, $scope.entityBeneficiary);
                }

                beneficiarySavePromise.then(function (response) {
                    if (response.Status) {
                        if ($scope.entityBeneficiary.EntityType == "I") {
                            $scope.entityBeneficiary.IndividualId = response.IndividualResponse[0].IndividualId;
                        } else {
                            $scope.entityBeneficiary.ProviderId = response.ProviderId;
                        }
                        $scope.viewConfig.createNewBeneficiary = false;
                        deffer.resolve({
                            Status: true
                        });
                    } else {
                        deffer.resolve({
                            Status: false,
                            Message: response.Message
                        });
                        $scope.showStatusMsg('-', response.Message);
                        $scope.loadingCashiering(false);
                    }
                }, function (data) {
                    deffer.resolve({
                        Status: false,
                        Message: data.message
                    });
                });
            } else {
                deffer.resolve({
                    Status: true
                });
            }
            return deffer.promise;
        };

        $scope.proceedToRefund = function (cashieringRefundForm) {
            cashieringRefundForm.$submitted = true;
            if (validateForm([cashieringRefundForm])) {
                $scope.loadingReceipt(true, "Please wait");
                var urlparm = '/' + sessionStorage.Key + '?ApplicationId=' + 0 + '&UserId=' + $scope.userId;
                var reqData = {
                    ApplicationTypeId: 13,
                    ApplicationStatusId: 14,
                    ApplicationSubmitMode: 'B',
                    GoPaperless: true,
                    IsActive: true
                };

                cashieringService.generateApplicationId(urlparm, reqData).then(function (response) {
                    if (response.Status) {
                        var reqData = {
                            ApplicationId: response.ApplicationId,
                            IndividualId: $scope.entitySelectedReceipt.EntityId,
                            ProviderId: $scope.entitySelectedReceipt.EntityId,
                            CreatedBy: $scope.userId,
                            CreatedOn: new Date()
                        };
                        var saveEntityAppPromise = null;
                        if ($scope.entitySelectedReceipt.EntityType == "I") {
                            saveEntityAppPromise = cashieringService.saveIndividualApplication(sessionStorage.Key, reqData);
                        }
                        else {
                            saveEntityAppPromise = cashieringService.saveProviderApplication(sessionStorage.Key, reqData);
                        }

                        saveEntityAppPromise.then(function (res) {
                            if (!res.Status) {
                                $scope.loadingReceipt(false);
                                $scope.showStatusMsg('-', res.Message);
                                return;
                            }
                            getApplication(response.ApplicationId).then(function (response) {
                                if (!response.Status) {
                                    $scope.loadingCashiering(false);
                                    $scope.showStatusMsg('-', response.Message);
                                }
                                $scope.processRefundPayment();
                            });
                        }, function (res) {
                            $scope.loadingReceipt(false);
                            showStatusMessage(Messages.efbdcdf, 'error');
                        });
                    }
                }, function (error) {
                    $scope.loadingCashiering(false);
                    showStatusMessage(error, 'error');
                });
            }
        };

        $scope.proceedToTransfer = function (revenueTransferForm, beneficiarySearchForm) {
            revenueTransferForm.$submitted = true;
            if (validateForm([revenueTransferForm, beneficiarySearchForm])) {
                $scope.paymentTypeConfig = null;
                if ($scope.uiConfig.paymentType && !$scope.uiConfig.paymentType.isEnabled && !$scope.entityBeneficiaryOtherInfo.selectedPaymentType) {
                    $scope.paymentTypeConfig = $scope.uiConfig.paymentType.Default;
                } else if (!!$scope.entityBeneficiaryOtherInfo.selectedPaymentType) {
                    $scope.paymentTypeConfig = $scope.entityBeneficiaryOtherInfo.selectedPaymentType;
                }
                $scope.loadingReceipt(true, "Please wait");
                saveEntityBeneficiaryRecord().then(function (response) {
                    if (!response.Status) {
                        $scope.loadingCashiering(false);
                        return;
                    }
                    var beneficiaryEntityId = null;
                    var beneficiaryEntityType = null;
                    if (!!$scope.entitySelectedReceipt.TransferToNewBeneficiary) {
                        if ($scope.entityBeneficiary.EntityType == "I") {
                            beneficiaryEntityId = $scope.entityBeneficiary.IndividualId;
                        } else {
                            beneficiaryEntityId = $scope.entityBeneficiary.ProviderId;
                        }
                        beneficiaryEntityType = $scope.entityBeneficiary.EntityType;
                    } else {
                        if ($scope.entitySelectedReceipt.EntityType == "I") {
                            beneficiaryEntityId = $scope.entitySelectedReceipt.IndividualId;
                        } else {
                            beneficiaryEntityId = $scope.entitySelectedReceipt.ProviderId;
                        }
                        beneficiaryEntityType = $scope.entitySelectedReceipt.EntityType;
                    }

                    if (!!$scope.entitySelectedReceipt.TransferToNewBeneficiary && !$scope.entityBeneficiaryOtherInfo.selectedApplication) {
                        var urlparm = '/' + sessionStorage.Key + '?ApplicationId=' + 0 + '&UserId=' + $scope.userId;
                        var reqData = $scope.uiConfig.Defaults.Application;
                        reqData.ApplicationTypeId = $scope.entityBeneficiaryOtherInfo.ApplicationTypeId;
                        reqData.LicenseApplicationForId = $scope.entityBeneficiaryOtherInfo.LicenseTypeId;
                        reqData.LicenseTypeId = $scope.entityBeneficiaryOtherInfo.LicenseTypeId;
                        reqData.UserId = $scope.userId;

                        cashieringService.generateApplicationId(urlparm, reqData).then(function (response) {
                            if (response.Status) {
                                var dataPromises = [];
                                if (beneficiaryEntityType == "I") {
                                    var reqData = {
                                        ApplicationId: response.ApplicationId,
                                        IndividualId: beneficiaryEntityId,
                                        CreatedBy: $scope.userId,
                                        CreatedOn: new Date()
                                    };

                                    var reqIndividualLicense = $scope.uiConfig.Defaults.License;
                                    reqIndividualLicense.IndividualId = beneficiaryEntityId;
                                    reqIndividualLicense.ApplicationId = response.ApplicationId;
                                    reqIndividualLicense.ApplicationTypeId = $scope.entityBeneficiaryOtherInfo.ApplicationTypeId;
                                    reqIndividualLicense.LicenseTypeId = $scope.entityBeneficiaryOtherInfo.LicenseTypeId;
                                    dataPromises = [
                                        cashieringService.saveIndividualApplication(sessionStorage.Key, reqData),
                                        cashieringService.saveIndividualLicense(sessionStorage.Key, reqIndividualLicense)
                                    ];
                                } else {
                                    var reqData = {
                                        ApplicationId: response.ApplicationId,
                                        ProviderId: beneficiaryEntityId,
                                        CreatedBy: $scope.userId,
                                        CreatedOn: new Date()
                                    };

                                    var reqProviderLicense = $scope.uiConfig.Defaults.License;
                                    reqProviderLicense.ProviderId = beneficiaryEntityId;
                                    reqProviderLicense.ApplicationId = response.ApplicationId;
                                    reqProviderLicense.ApplicationTypeId = $scope.entityBeneficiaryOtherInfo.ApplicationTypeId;
                                    reqProviderLicense.ProviderTypeId = $scope.entityBeneficiaryOtherInfo.LicenseTypeId;
                                    reqProviderLicense.ProviderLicenseTypeId = $scope.entityBeneficiaryOtherInfo.LicenseTypeId;

                                    dataPromises = [
                                        cashieringService.saveProviderApplication(sessionStorage.Key, reqData),
                                        cashieringService.saveProviderLicense(sessionStorage.Key, [reqProviderLicense])
                                    ];
                                }
                                $q.all(dataPromises).then(function (res) {
                                    if (!res[0].Status || !res[1].Status) {
                                        $scope.loadingReceipt(false);
                                        $scope.showStatusMsg('-', res[0].Message);
                                        return;
                                    }
                                    getApplication(response.ApplicationId).then(function (response) {
                                        if (!response.Status) {
                                            $scope.loadingCashiering(false);
                                            $scope.showStatusMsg('-', response.Message);
                                        }
                                        $scope.processPaymentTransfer();
                                    });
                                }, function (res) {
                                    $scope.loadingReceipt(false);
                                    showStatusMessage(Messages.efbdcdf, 'error');
                                });
                            }
                        }, function (error) {
                            $scope.loadingCashiering(false);
                            showStatusMessage(error, 'error');
                        });
                    } else {
                        var effectiveApplicationId = null;
                        if (!!$scope.entitySelectedReceipt.TransferToNewBeneficiary) {
                            effectiveApplicationId = $scope.entityBeneficiaryOtherInfo.selectedApplication.ApplicationId;
                        } else {
                            effectiveApplicationId = $scope.entitySelectedReceipt.ApplicationId;
                        }
                        getApplication(effectiveApplicationId).then(function (response) {
                            if (!response.Status) {
                                $scope.loadingCashiering(false);
                                $scope.showStatusMsg('-', response.Message);
                            }
                            $scope.processPaymentTransfer();
                        });
                    }
                });
            }
        };

        var getCashieringInvoiceDetail = function (feeDetailRow) {
            var licenseEndorsementFeeInvoiceDetail = {
                TransType: "ORD",
                Description: feeDetailRow.feeDetailType.FeeDetailDesc,
                Quantity: 1,
                Amount: feeDetailRow.Amount,
                AmtBilled: feeDetailRow.Amount,
                AmtPaid: feeDetailRow.Amount,
                AmtAdjusted: 0,
                AmtBalance: 0,
                TransCode: feeDetailRow.feeDetailType.FeeTypeCode, //'MISCEFEE',
                Module: 'MiscPay',
                DetailCode: feeDetailRow.feeDetailType.FeeDetailCode,
                ReferenceNumber: feeDetailRow.ReferenceNumber,
                GPAdjust: 0,
                GPPayment: 0,
            };
            return licenseEndorsementFeeInvoiceDetail;
        };

        var getCashieringIndividualPaymentRequest = function (entityBeneficiaryDataItem) {
            var transaction = {
                AmountDue: entityBeneficiaryDataItem.TotalAmount,
                TotalPaidAmount: entityBeneficiaryDataItem.TotalAmount,
                UserDefinedPaymentNo: "",
                TransCode: "",
                EmailAddress: '',
                PaymentSource: "BO"
            };


            if (["1", "2", "4", "5", "7", "8", "9"].indexOf($scope.entityPaymentInfo.PaymentMethodSelected) != -1) {
                transaction.MailingPayment = $scope.payment.CheckMailing;
                transaction.RequiresApproval = $scope.payment.CheckMailing;
            } else if ($scope.entityPaymentInfo.PaymentMethodSelected == 3) {
                transaction.RequiresApproval = false;
                transaction.MailingPayment = false;
            }

            if (["1", "2", "4", "5", "7", "8", "9"].indexOf($scope.entityPaymentInfo.PaymentMethodSelected) != -1) {
                if ($scope.entityPaymentInfo.PaymentMethodSelected == 1) {
                    transaction.PaymentMode = "CK";
                    transaction.TransCode = "CK";
                } else if ($scope.entityPaymentInfo.PaymentMethodSelected == 2) {
                    transaction.PaymentMode = "MO";
                    transaction.TransCode = "MO";
                } else if ($scope.entityPaymentInfo.PaymentMethodSelected == 4) {
                    transaction.PaymentMode = "PC";
                    transaction.TransCode = "PC";
                } else if ($scope.entityPaymentInfo.PaymentMethodSelected == 5) {
                    transaction.PaymentMode = "CS";
                    transaction.TransCode = "CS";
                } else if ($scope.entityPaymentInfo.PaymentMethodSelected == 7) {
                    transaction.PaymentMode = "CCK";
                    transaction.TransCode = "CCK";
                } else if ($scope.entityPaymentInfo.PaymentMethodSelected == 8) {
                    transaction.PaymentMode = "IBT";
                    transaction.TransCode = "IBT";
                } else if ($scope.entityPaymentInfo.PaymentMethodSelected == 9) {
                    transaction.PaymentMode = "RD";
                    transaction.TransCode = "RD";
                }
                transaction.PaymentModeNumber = ((!!$scope.entityPaymentInfo.CheckNo) ? $scope.entityPaymentInfo.CheckNo : 0);
                transaction.Comment = $scope.entityPaymentInfo.CheckComment;
                transaction.ElectronicPayment = false;
                transaction.ManualPayment = true;
            } else if ($scope.entityPaymentInfo.PaymentMethodSelected == 3) {
                transaction.PaymentMode = "CC";
                transaction.PaymentModeNumber = "";
                transaction.CardType = detectCardType($scope.entityPaymentInfo.CardNumber);
                transaction.CardNumber = $scope.entityPaymentInfo.CardNumber;
                transaction.ExpirationMonths = $scope.entityPaymentInfo.ExpirationMonths;
                transaction.ExpirationYears = $scope.entityPaymentInfo.ExpirationYears;
                transaction.CVV = $scope.entityPaymentInfo.CVV;
                transaction.FirstName = getPayorName();
                transaction.AddressStreetLine1 = $scope.entityPayor.StreetLine1;
                transaction.AddressCity = $scope.entityPayor.City;
                transaction.AddressStateCode = $scope.entityPayor.State;
                transaction.AddressZip = $scope.entityPayor.Zip;
                transaction.PhoneNumber = '';
                transaction.PaymentInitiateSubmitMode = "BO";
                transaction.PaymentCompletionSubmitMode = "BO";
                transaction.ElectronicPayment = true;
                transaction.ManualPayment = false;
                transaction.TransCode = transaction.CardType;
            }
            if (entityBeneficiaryDataItem.BeneficiaryEntityType == "I") {
                transaction.IndividualTransactionObject = [];
                angular.forEach(entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.feeDetailRows, function (feeDetailRow) {
                    transaction.IndividualTransactionObject
                        .push(getIndividualTransactionObject(4, feeDetailRow.Amount, transaction.TransCode));
                });
            } else if (entityBeneficiaryDataItem.BeneficiaryEntityType == "P") {
                transaction.ProviderTransactionObject = [];
                angular.forEach(entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.feeDetailRows, function (feeDetailRow) {
                    transaction.ProviderTransactionObject
                        .push(getIndividualTransactionObject(4, feeDetailRow.Amount, transaction.TransCode));
                });
            }

            return transaction;
        };

        var getPayorRequestData = function () {
            var payorRequestData = {
                CreateNewPayor: $scope.viewConfig.createNewPayor,
                IndividualPayor: (($scope.entityPayor && $scope.entityPayor.EntityType == "I") ? $scope.entityPayor : null),
                ProviderPayor: (($scope.entityPayor && $scope.entityPayor.EntityType == "P") ? $scope.entityPayor : null),
                PayorEntityType: $scope.entityPayor.EntityType
            };
            if (payorRequestData.PayorEntityType == "P" && !!payorRequestData.ProviderPayor) {
                payorRequestData.ProviderPayor.ProviderName = payorRequestData.ProviderPayor.EntityName;
                payorRequestData.ProviderPayor.ProviderTypeId = $scope.uiConfig.Provider.ProviderTypeId;
                payorRequestData.ProviderPayor.ProviderStatusTypeId = $scope.uiConfig.Provider.ProviderStatusTypeId;
                payorRequestData.ProviderPayor.IsActive = true;
                payorRequestData.ProviderPayor.IsDeleted = false;
            } else if (payorRequestData.PayorEntityType == "I" && !!payorRequestData.IndividualPayor) {
                payorRequestData.IndividualPayor.IsActive = true;
                payorRequestData.IndividualPayor.IsDeleted = false;
            }

            if ($scope.viewConfig.createNewPayor && (!!$scope.entityPayor && !!addressExists($scope.entityPayor))) {
                payorRequestData.CreateAddress = true;
                if ($scope.entityPayor && $scope.entityPayor.EntityType == "I") {
                    payorRequestData.IndividualPayorAddresses = [{
                        AddressTypeId: $scope.uiConfig.Individual.AddressTypeId,
                        IsActive: true,
                        CountryId: $rootScope.DefaultCountryId,
                        AdressStatusId: 1,
                        BeginDate: new Date(),
                        StreetLine1: $scope.entityPayor.StreetLine1,
                        StreetLine2: $scope.entityPayor.StreetLine2,
                        City: $scope.entityPayor.City,
                        StateCode: $scope.entityPayor.State,
                        Zip: $scope.entityPayor.Zip,
                    }];
                } else if ($scope.entityPayor && $scope.entityPayor.EntityType == "P") {
                    payorRequestData.ProviderPayorAddresses = [{
                        AddressTypeId: $scope.uiConfig.Provider.AddressTypeId,
                        IsActive: true,
                        CountryId: $rootScope.DefaultCountryId,
                        AddressStatusId: 1,
                        BeginDate: new Date(),
                        StreetLine1: $scope.entityPayor.StreetLine1,
                        StreetLine2: $scope.entityPayor.StreetLine2,
                        City: $scope.entityPayor.City,
                        StateCode: $scope.entityPayor.State,
                        Zip: $scope.entityPayor.Zip,
                    }];
                }
            }

            var entityBeneficiariesRequestData = [];
            angular.forEach($scope.entityBeneficiariesData, function (entityBeneficiaryDataItem) {
                var entityBeneficiaryRequestDataItem = {
                    IsBeneficiarySameAsPayor: entityBeneficiaryDataItem.IsBeneficiarySameAsPayor,
                    CreateNewBeneficiary: (!entityBeneficiaryDataItem.IsBeneficiarySameAsPayor && entityBeneficiaryDataItem.CreateNewBeneficiary),
                    IndividualBeneficiary: (entityBeneficiaryDataItem.IsBeneficiarySameAsPayor ?
                        (($scope.entityPayor && $scope.entityPayor.EntityType == "I") ? $scope.entityPayor : null)
                        : ((entityBeneficiaryDataItem.entityBeneficiary && entityBeneficiaryDataItem.entityBeneficiary.EntityType == "I") ? entityBeneficiaryDataItem.entityBeneficiary : null)),
                    ProviderBeneficiary: (entityBeneficiaryDataItem.IsBeneficiarySameAsPayor ?
                        (($scope.entityPayor && $scope.entityPayor.EntityType == "P") ? $scope.entityPayor : null)
                        : ((entityBeneficiaryDataItem.entityBeneficiary && entityBeneficiaryDataItem.entityBeneficiary.EntityType == "P") ? entityBeneficiaryDataItem.entityBeneficiary : null)),
                    BeneficiaryEntityType: (entityBeneficiaryDataItem.IsBeneficiarySameAsPayor ? $scope.entityPayor.EntityType : entityBeneficiaryDataItem.entityBeneficiary.EntityType),
                    CreateApplication: (!entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.selectedApplication),
                    CreateLicense: (!entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.selectedApplication && !!entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.createNewApplication)
                };
                if (entityBeneficiaryRequestDataItem.BeneficiaryEntityType == "P" && !!entityBeneficiaryRequestDataItem.ProviderBeneficiary) {
                    entityBeneficiaryRequestDataItem.ProviderBeneficiary.ProviderName = entityBeneficiaryRequestDataItem.ProviderBeneficiary.EntityName;
                    entityBeneficiaryRequestDataItem.ProviderBeneficiary.ProviderTypeId = $scope.uiConfig.Provider.ProviderTypeId;
                    entityBeneficiaryRequestDataItem.ProviderBeneficiary.ProviderStatusTypeId = $scope.uiConfig.Provider.ProviderStatusTypeId;
                    entityBeneficiaryRequestDataItem.ProviderBeneficiary.IsActive = true;
                    entityBeneficiaryRequestDataItem.ProviderBeneficiary.IsDeleted = false;
                } else if (entityBeneficiaryRequestDataItem.BeneficiaryEntityType == "I" && !!entityBeneficiaryRequestDataItem.IndividualBeneficiary) {
                    entityBeneficiaryRequestDataItem.IndividualBeneficiary.IsActive = true;
                    entityBeneficiaryRequestDataItem.IndividualBeneficiary.IsDeleted = false;
                }

                if (!entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.selectedApplication) {
                    if (!!entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.createNewApplication) {
                        entityBeneficiaryRequestDataItem.Application = $scope.uiConfig.Defaults.Application;
                        entityBeneficiaryRequestDataItem.Application.ApplicationTypeId = entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.ApplicationTypeId;
                        entityBeneficiaryRequestDataItem.Application.LicenseApplicationForId = entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.LicenseTypeId;
                        entityBeneficiaryRequestDataItem.Application.LicenseTypeId = entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.LicenseTypeId;

                        if (entityBeneficiaryRequestDataItem.BeneficiaryEntityType == "I") {
                            entityBeneficiaryRequestDataItem.IndividualLicense = $scope.uiConfig.Defaults.License;
                            entityBeneficiaryRequestDataItem.IndividualLicense.ApplicationTypeId = entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.ApplicationTypeId;
                            entityBeneficiaryRequestDataItem.IndividualLicense.LicenseTypeId = entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.LicenseTypeId;
                        } else if (entityBeneficiaryRequestDataItem.BeneficiaryEntityType == "P") {
                            entityBeneficiaryRequestDataItem.ProviderLicense = $scope.uiConfig.Defaults.License;
                            entityBeneficiaryRequestDataItem.ProviderLicense.ApplicationTypeId = entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.ApplicationTypeId;
                            entityBeneficiaryRequestDataItem.ProviderLicense.ProviderTypeId = entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.LicenseTypeId;
                            entityBeneficiaryRequestDataItem.ProviderLicense.ProviderLicenseTypeId = entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.LicenseTypeId;
                        }
                    } else {
                        entityBeneficiaryRequestDataItem.Application = $scope.uiConfig.Defaults.MiscPaymentApplication;
                    }
                } else {
                    entityBeneficiaryRequestDataItem.Application = entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.selectedApplication;
                    if (entityBeneficiaryRequestDataItem.BeneficiaryEntityType == "I") {
                        entityBeneficiaryRequestDataItem.IndividualLicense = {
                            LicenseTypeId: entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.LicenseTypeId
                        };
                    } else if (entityBeneficiaryRequestDataItem.BeneficiaryEntityType == "P") {
                        entityBeneficiaryRequestDataItem.ProviderLicense = {
                            ProviderTypeId: entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.LicenseTypeId
                        };
                    }
                }

                entityBeneficiaryRequestDataItem.ApplicationTabStatus = $scope.uiConfig.Defaults.TabStaus;

                entityBeneficiaryRequestDataItem.InvoiceMaster = $scope.uiConfig.Defaults.InvoiceMaster;
                entityBeneficiaryRequestDataItem.InvoiceMaster.AmountBilled = entityBeneficiaryDataItem.TotalAmount;
                entityBeneficiaryRequestDataItem.InvoiceMaster.AmountPaid = $scope.entityPaymentInfo.paymentAmount;
                entityBeneficiaryRequestDataItem.InvoiceMaster.AmountAdjusted = $scope.entityPaymentInfo.paymentAmount;
                entityBeneficiaryRequestDataItem.InvoiceMaster.CheckAmount = $scope.entityPaymentInfo.paymentAmount;
                entityBeneficiaryRequestDataItem.InvoiceMaster.Balance = entityBeneficiaryDataItem.TotalAmount;
                entityBeneficiaryRequestDataItem.InvoiceMaster.PayorType = $scope.entityPayor.EntityType;
                entityBeneficiaryRequestDataItem.InvoiceMaster.CredentialNumber = entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.CredentialNumber;

                var paymentTypeConfig = null;
                if ($scope.uiConfig.paymentType && (!$scope.uiConfig.paymentType.isEnabled || !entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.selectedPaymentType)) {
                    paymentTypeConfig = $scope.uiConfig.paymentType.Default;
                } else if (!!entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.selectedPaymentType) {
                    paymentTypeConfig = entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.selectedPaymentType;
                }
                entityBeneficiaryRequestDataItem.InvoiceMaster.Module = paymentTypeConfig.Payment.Module;
                entityBeneficiaryRequestDataItem.InvoiceMaster.InvoiceStatus = "OPEN";
                entityBeneficiaryRequestDataItem.InvoiceMaster.ModuleGroup = $scope.entityPaymentInfo.stateReceiptNumber;
                entityBeneficiaryRequestDataItem.InvoiceMaster.ShipToContact = getPayorName();
                entityBeneficiaryRequestDataItem.InvoiceMaster.ShipToAddress1 = $scope.entityPayor.StreetLine1;
                entityBeneficiaryRequestDataItem.InvoiceMaster.ShipToAddress2 = $scope.entityPayor.StreetLine2;
                entityBeneficiaryRequestDataItem.InvoiceMaster.ShipToCity = $scope.entityPayor.City;
                entityBeneficiaryRequestDataItem.InvoiceMaster.ShipToState = $scope.entityPayor.State;
                entityBeneficiaryRequestDataItem.InvoiceMaster.ShipToZip = $scope.entityPayor.Zip;
                entityBeneficiaryRequestDataItem.InvoiceMaster.InvoiceDate = $scope.entityPaymentInfo.InvoiceDate;
                entityBeneficiaryRequestDataItem.InvoiceMaster.PostMarkDate = $scope.entityPaymentInfo.PostMarkDate;
                entityBeneficiaryRequestDataItem.InvoiceMaster.DateAdded = $scope.entityPaymentInfo.DateAdded;
                entityBeneficiaryRequestDataItem.InvoiceMaster.BatchNum = $scope.revDrawer.DrawerNumber
                entityBeneficiaryRequestDataItem.InvoiceDetails = [];

                if (!!entityBeneficiaryDataItem.entityBeneficiaryOtherInfo && !!entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.feeDetailRows) {
                    angular.forEach(entityBeneficiaryDataItem.entityBeneficiaryOtherInfo.feeDetailRows, function (feeDetailItem) {
                        var invoiceDetailItem = getCashieringInvoiceDetail(feeDetailItem);
                        entityBeneficiaryRequestDataItem.InvoiceDetails.push(invoiceDetailItem);
                    });
                }

                if (entityBeneficiaryRequestDataItem.BeneficiaryEntityType == "I") {
                    entityBeneficiaryRequestDataItem.IndividualPaymentRequests = [getCashieringIndividualPaymentRequest(entityBeneficiaryDataItem)];
                } else if (entityBeneficiaryRequestDataItem.BeneficiaryEntityType == "P") {
                    entityBeneficiaryRequestDataItem.ProviderPaymentRequests = [getCashieringIndividualPaymentRequest(entityBeneficiaryDataItem)];
                }

                entityBeneficiariesRequestData.push(entityBeneficiaryRequestDataItem);
            });

            payorRequestData.BeneficiariesInfo = entityBeneficiariesRequestData;

            var payorPaymentRequestData = [{
                ReceiptLevel: "payor",
                PayorsInfo: [
                    payorRequestData
                ]
            }];
            return payorPaymentRequestData;
        };

        $scope.proceedToPayment = function (cashieringForm, payorSearchForm) {
            cashieringForm.$submitted = true;
            if (validateForm([cashieringForm, payorSearchForm])) {

                $scope.loadingCashiering(true, "Please wait");

                var payorPaymentRequestData = getPayorRequestData();

                cashieringService.individualProcessPayment(sessionStorage.Key, payorPaymentRequestData)
                    .then(function (responseTransaction) {
                        if (!responseTransaction.Status) {
                            $scope.showStatusMsg('-', responseTransaction.Message);
                        }
                        $scope.paymentStatusUpdate({
                            action: 'success'
                        });
                        $scope.openConfirmationPopup(responseTransaction.EntityRecords[0].PayorsInfo[0]);
                        $scope.loadingCashiering(false);
                    }, function (error) {
                        $scope.loadingCashiering(false);
                        $scope.showStatusMsg('-', error.message);
                    });
            }
        };


        $scope.deleteFeeDetailsRow = function (feeDetailsRows, index) {
            feeDetailsRows.splice(index, 1);
        };

        $scope.deleteBeneficieries = function (index) {
            if ($scope.entityBeneficiariesData && $scope.entityBeneficiariesData.length > 0) {
                $scope.entityBeneficiariesData.splice(index, 1);
            }
        };

        $scope.getApplicationByIndividualId = function (individualId) {
            var deffer = $q.defer();
            cashieringService.getApplicationByIndividualId(sessionStorage.Key, individualId, true)
                .then(function (response) {
                    if (response.Status) {
                        var beneficiaryApplications = null;
                        if (!!response.ApplicationResponseList && response.ApplicationResponseList.length > 0) {
                            beneficiaryApplications = response.ApplicationResponseList.whereIncludes($scope.uiConfig.ApplicationTypeIds, 'ApplicationTypeId');
                        }
                        $scope.beneficiaryApplications = beneficiaryApplications;
                        deffer.resolve({
                            Status: true,
                            Message: response.Message
                        });
                    } else {
                        deffer.resolve({
                            Status: false,
                            Message: response.Message
                        });
                    }
                }, function (error) {
                    showStatusMessage(error, 'error');
                    deffer.resolve({
                        Status: false,
                        Message: response.Message
                    });
                });
            return deffer.promise;
        };

        $scope.getApplicationByProviderId = function (providerId) {
            var deffer = $q.defer();
            cashieringService.getApplicationByProviderId(sessionStorage.Key, providerId, true)
                .then(function (response) {
                    if (response.Status) {
                        var beneficiaryApplications = null;
                        if (!!response.ApplicationResponseList && response.ApplicationResponseList.length > 0) {
                            beneficiaryApplications = response.ApplicationResponseList.whereIncludes($scope.uiConfig.ApplicationTypeIds, 'ApplicationTypeId');
                        }
                        $scope.beneficiaryApplications = beneficiaryApplications;
                        deffer.resolve({
                            Status: true,
                            Message: response.Message
                        });
                    } else {
                        deffer.resolve({
                            Status: false,
                            Message: response.Message
                        });
                    }
                }, function (error) {
                    showStatusMessage(error, 'error');
                    deffer.resolve({
                        Status: false,
                        Message: response.Message
                    });
                });
            return deffer.promise;
        };

        var getApplication = function (ApplicationId) {
            var deffer = $q.defer();
            cashieringService.getApplicationInfoByApplicationId(ApplicationId, $scope.userId, sessionStorage.Key)
                .then(function (response) {
                    if (response.Status) {
                        $scope.applicationInfo = response.ApplicationResponseList[0];
                        deffer.resolve({
                            Status: true,
                            Message: response.Message
                        });
                    } else {
                        deffer.resolve({
                            Status: false,
                            Message: response.Message
                        });
                    }
                }, function (error) {
                    $scope.loadingCashiering(false);
                    showStatusMessage(error, 'error');
                    deffer.resolve({
                        Status: false,
                        Message: response.Message
                    });
                });
            return deffer.promise;
        };

        var resetPayor = function () {
            $scope.entityPayor = {
                IsActive: 1,
                IsDeleted: 0
            };
            $scope.viewConfig.createNewPayor = true;
        };

        var resetBeneficiary = function () {
            $scope.entityBeneficiary = {
                IsActive: 1,
                IsDeleted: 0
            };
            $scope.viewConfig.createNewBeneficiary = true;
        };

        $scope.paymentStatusUpdate = function (response) {
            if (response.action == 'success') {
                $scope.loadingCashiering(false);
            } else if (response.action == 'close') {
                $scope.feeDetailRows = [];
                $scope.individualFinance = {};
                $scope.viewConfig.proceedToPayment = false;
                $scope.viewConfig.BeneficiaryNotSameAsPayor = false;
                resetBeneficiary();
                resetPayor();
                $scope.beneficiaryApplications = null;
                $scope.entityPaymentInfo = {};
                $scope.currentForm.$setPristine();
                $scope.currentForm.$submitted = false;
                $scope.entityBeneficiaryOtherInfo = {
                    createNewApplication: true
                };
                $scope.entityBeneficiariesData = [];
            }
            $scope.entityPaymentInfo = {
                PaymentMethodSelected: "-1"
            };
            resetInvoiceMaster();
        };

        $scope.resetRufundProcess = function () {
            $scope.entitySelectedReceipt = null;
            $scope.refundFeeDetailRows = null;
            $scope.entityReceipts = null;
            $scope.entityReceiptSearch = {};
            $scope.viewConfig.proceedToPayment = false;
            $scope.currentForm.$setPristine();
            $scope.currentForm.$submitted = false;
            resetBeneficiary();
        };

        $scope.resetCashiering = function () {
            $scope.paymentStatusUpdate({
                action: 'close'
            });
            $scope.hideStatusMsg();
        };

        var getPayorName = function () {
            var payorName = "";
            if (!!$scope.entityPayor) {
                if (!!$scope.entityPayor.FirstName) {
                    payorName = payorName + $scope.entityPayor.FirstName;
                }
                if (!!$scope.entityPayor.MiddleName) {
                    payorName = payorName + " " + $scope.entityPayor.MiddleName;
                }
                if (!!$scope.entityPayor.LastName) {
                    payorName = payorName + " " + $scope.entityPayor.LastName;
                }
                if (!!$scope.entityPayor.EntityName) {
                    payorName = payorName + " " + $scope.entityPayor.EntityName;
                }
            }
            return payorName;
        };

        $scope.openRefundConfirmationPopup = function (displayData) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'cashieringRefundConfirmation.html',
                size: 'sm',
                resolve: {
                    displayData: displayData
                },
                controller: ['$scope', '$uibModalInstance', 'displayData', function ($scope, $uibModalInstance, displayData) {
                    $scope.displayData = displayData;
                    if (!!$scope.displayData && !!$scope.displayData.ReceiptNumber) {
                        var invoiceNumberLength = $scope.displayData.ReceiptNumber.length;
                        for (var i = 0; i < (8 - invoiceNumberLength); i++) {
                            $scope.displayData.ReceiptNumber = "0" + $scope.displayData.ReceiptNumber;
                        }
                    }
                    if (!!$scope.displayData && !!$scope.displayData.RefundReceiptNumber) {
                        var refundInvoiceNumberLength = $scope.displayData.RefundReceiptNumber.length;
                        for (var i = 0; i < (8 - refundInvoiceNumberLength); i++) {
                            $scope.displayData.RefundReceiptNumber = "0" + $scope.displayData.RefundReceiptNumber;
                        }
                    }
                    $scope.onCancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.onConfirm = function () {
                        $uibModalInstance.close('success');
                    };
                }]
            });

            modalInstance.result.then(function () {
                $scope.resetRufundProcess();
            });
        };

        $scope.openTransferConfirmationPopup = function (displayData) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'cashieringTransferConfirmation.html',
                size: 'sm',
                resolve: {
                    displayData: displayData
                },
                controller: ['$scope', '$uibModalInstance', 'displayData', function ($scope, $uibModalInstance, displayData) {
                    $scope.displayData = displayData;
                    if (!!$scope.displayData && !!$scope.displayData.ReceiptNumber) {
                        var invoiceNumberLength = $scope.displayData.ReceiptNumber.length;
                        for (var i = 0; i < (8 - invoiceNumberLength); i++) {
                            $scope.displayData.ReceiptNumber = "0" + $scope.displayData.ReceiptNumber;
                        }
                    }
                    if (!!$scope.displayData && !!$scope.displayData.RefundReceiptNumber) {
                        var refundInvoiceNumberLength = $scope.displayData.RefundReceiptNumber.length;
                        for (var i = 0; i < (8 - refundInvoiceNumberLength); i++) {
                            $scope.displayData.RefundReceiptNumber = "0" + $scope.displayData.RefundReceiptNumber;
                        }
                    }
                    $scope.onCancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.onConfirm = function () {
                        $uibModalInstance.close('success');
                    };
                }]
            });

            modalInstance.result.then(function () {
                $scope.resetRufundProcess();
            });
        };

        $scope.openBadCheckConfirmationPopup = function (displayData) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'cashieringBadCheckConfirmation.html',
                size: 'sm',
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    $scope.onCancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.onConfirm = function () {
                        $uibModalInstance.close('success');
                    };
                }]
            });

            modalInstance.result.then(function () {
                $scope.resetRufundProcess();
            });
        };

        $scope.processPaymentTransfer = function () {
            $scope.viewConfig.proceedToRefund = true;
            var transferInvoiceDetails = [];
            if (!$scope.entitySelectedReceipt.TransferToNewAccountCode) {
                transferInvoiceDetails = $scope.transferFeeDetailRows.whereEquals(true, 'isSelected');
            } else {
                angular.forEach($scope.feeDetailRows, function (feeDetailRow) {
                    var miscFeeInvoiceDetail = getMiscFeeInvoiceDetail(feeDetailRow);
                    transferInvoiceDetails.push(miscFeeInvoiceDetail);
                });
            }

            var reqObj = {
                TransactionFromTypeCode: ($scope.entitySelectedReceipt.isReceiptEdit ? 'ED' : 'TR'),
                TransactionToTypeCode: ($scope.entitySelectedReceipt.isReceiptEdit ? 'ET' : 'TT'),
                EntityId: $scope.entitySelectedReceipt.EntityId,
                EntityType: $scope.entitySelectedReceipt.EntityType,
                InvoiceId: $scope.entitySelectedReceipt.InvoiceId,
                ApplicationId: $scope.applicationInfo.ApplicationId,
                ReceiptNumber: $scope.entitySelectedReceipt.ReceiptNumber,
                TransferToNewBeneficiary: $scope.entitySelectedReceipt.TransferToNewBeneficiary,
                TransferToNewAccountCode: $scope.entitySelectedReceipt.TransferToNewAccountCode,
                InvoiceDetails: $scope.transferFeeDetailRows.whereEquals(true, 'isSelected'),
                TransferInvoiceDetails: transferInvoiceDetails,
                Module: ($scope.entitySelectedReceipt.TransferToNewBeneficiary ? $scope.paymentTypeConfig.Payment.Module : null),
                BeneficiaryCredentialNumber: $scope.entityBeneficiaryOtherInfo.CredentialNumber
            };


            if (!!$scope.entitySelectedReceipt.TransferToNewBeneficiary) {
                if ($scope.entityBeneficiary.EntityType == "I") {
                    reqObj.BeneficiaryEntityId = $scope.entityBeneficiary.IndividualId;
                } else {
                    reqObj.BeneficiaryEntityId = $scope.entityBeneficiary.ProviderId;
                }
                reqObj.BeneficiaryEntityType = $scope.entityBeneficiary.EntityType;
            } else {
                if ($scope.entitySelectedReceipt.EntityType == "I") {
                    reqObj.BeneficiaryEntityId = $scope.entitySelectedReceipt.IndividualId;
                } else {
                    reqObj.BeneficiaryEntityId = $scope.entitySelectedReceipt.ProviderId;
                }
                reqObj.BeneficiaryEntityType = $scope.entitySelectedReceipt.EntityType;
            }

            cashieringService.processPaymentTransfer(sessionStorage.Key, reqObj)
                .then(function (response) {
                    if (!response.Status) {
                        $scope.loadingReceipt(false);
                        $scope.showStatusMsg('-', response.Message);
                        return;
                    }
                    $scope.loadingReceipt(false);
                    if (!$scope.applicationInfo.ApplicationNumber && !!$scope.applicationInfo.ApplicationNumber) {
                        response.ApplicationNumber = $scope.applicationInfo.ApplicationNumber;
                    }
                    $scope.openTransferConfirmationPopup(response);
                }, function (error) {
                    $scope.loadingCashiering(false);
                });
        };

        $scope.processRefundPayment = function () {
            $scope.viewConfig.proceedToRefund = true;
            var reqObj = {
                EntityId: $scope.entitySelectedReceipt.EntityId,
                EntityType: $scope.entitySelectedReceipt.EntityType,
                InvoiceId: $scope.entitySelectedReceipt.InvoiceId,
                ApplicationId: $scope.applicationInfo.ApplicationId,
                ReceiptNumber: $scope.entitySelectedReceipt.ReceiptNumber,
                InvoiceDetails: $scope.refundFeeDetailRows.whereEquals(true, 'isSelected')
            };
            cashieringService.processRefund(sessionStorage.Key, reqObj)
                .then(function (response) {
                    if (!response.Status) {
                        $scope.loadingReceipt(false);
                        $scope.showStatusMsg('-', response.Message);
                        return;
                    }
                    $scope.loadingReceipt(false);
                    $scope.openRefundConfirmationPopup(response);
                }, function (error) {
                    $scope.loadingCashiering(false);
                });
        };


        $scope.processToBadCheckProcessing = function (cashieringBadCheckForm) {
            if (validateForm([cashieringBadCheckForm])) {
                $scope.loadingReceipt(true, "Please Wait...");
                $scope.viewConfig.proceedToRefund = true;
                var reqObj = [];
                var selectedReceiptData = $scope.entityReceipts.whereEquals(true, 'isSelected');
                angular.forEach(selectedReceiptData, function (selectedReceiptItem) {
                    reqObj.push({
                        EntityId: selectedReceiptItem.EntityId,
                        EntityType: 'I',
                        InvoiceId: selectedReceiptItem.InvoiceId,
                        BadCheck: true,
                        BadCheckReasonId: $scope.badCheckInfo.BadCheckReasonId,
                        BadCheckReason: $scope.badCheckInfo.BadCheckReason
                    });
                });

                cashieringService.processBadCheck(sessionStorage.Key, reqObj)
                    .then(function (response) {
                        if (!response.Status) {
                            $scope.loadingReceipt(false);
                            $scope.showStatusMsg('-', response.Message);
                            return;
                        }
                        $scope.badCheckInfo = {};
                        $scope.resetBadCheckProcess();
                        $scope.loadingReceipt(false);
                        $scope.openBadCheckConfirmationPopup(response);
                    }, function (error) {
                        $scope.loadingCashiering(false);
                    });
            }
        };

        $scope.deleteReceipt = function (entityReceiptItem) {
            if (confirm(Messages.General.Errors.deleteConfirmation)) {
                $scope.loadingReceipt(true, "Please Wait...");
                var reqObj = {
                    InvoiceId: entityReceiptItem.InvoiceId
                };

                cashieringService.getInvoiceMaster(sessionStorage.Key, reqObj)
                    .then(function (response) {
                        if (!response.Status) {
                            $scope.loadingReceipt(false);
                            $scope.showStatusMsg('-', response.Message);
                            return;
                        }
                        if (response.EntityRecords && response.EntityRecords.length > 0) {
                            var invoiceMaster = response.EntityRecords[0];
                            invoiceMaster.InvoiceStatus = "DELETED";
                            cashieringService.invoiceMasterSave(sessionStorage.Key, [invoiceMaster]).then(function (response) {
                                if (!response.Status) {
                                    $scope.loadingReceipt(false);
                                    $scope.showStatusMsg('-', response.Message);
                                    return;
                                }
                                alert("Payment Amount is not matching to total of all beneficiary fee types.");
                                $scope.showStatusMsg('+', response.Message);
                                $scope.loadingReceipt(false);
                                $scope.searchReceiptInformation(null, null, 1);
                            }, function (error) {
                                $scope.loadingCashiering(false);
                            });
                        } else {
                            $scope.showStatusMsg('-', 'Something went wrong');
                        }
                    }, function (error) {
                        $scope.loadingCashiering(false);
                    });
            }
        };

        var getMiscFeeInvoiceDetail = function (feeDetailRow) {
            var licenseEndorsementFeeInvoiceDetail = {
                InvoiceDetailID: 0,
                InvoiceID: $scope.InvoiceId,
                IndividualId: $scope.individualId,
                ApplicationId: $scope.applicationInfo.ApplicationId,
                TransType: "ORD",
                Description: feeDetailRow.feeDetailType.FeeDetailDesc,
                Quantity: 1,
                Amount: feeDetailRow.Amount,
                TransferAmount: feeDetailRow.Amount,
                AmtBilled: feeDetailRow.Amount,
                AmtPaid: feeDetailRow.Amount,
                AmtAdjusted: 0,
                AmtBalance: 0,
                TransCode: feeDetailRow.feeDetailType.FeeTypeCode, //'MISCEFEE',
                Module: 'MiscPay',
                DetailCode: feeDetailRow.feeDetailType.FeeDetailCode,
                ReferenceNumber: feeDetailRow.ReferenceNumber,
                GPAdjust: 0,
                GPPayment: 0,
                CreatedBy: $scope.userId
            };
            return licenseEndorsementFeeInvoiceDetail;
        };

        $scope.getOpenRevDrawer = function () {
            cashieringService.getOpenRevDrawer(sessionStorage.Key).then(function (response) {
                if (response.Status) {
                    if (!!response.EntityRecords && response.EntityRecords.length > 0) {
                        $scope.revDrawers = response.EntityRecords;
                        $scope.revDrawer = $scope.revDrawers[0];
                        $scope.selectedDrawerId = $scope.revDrawer.RevDrawerId;
                        $scope.viewConfig.batchOpened = false;
                        $scope.viewConfig.SearchMode = 'payment';
                    } else {
                        $scope.viewConfig.batchOpened = false;
                        $scope.revDrawer = null;
                        $scope.selectedDrawerId = null;
                        $scope.viewConfig.SearchMode = 'refundAndTransfer';
                    }
                    $scope.showStatusMsg('+', response.Message);
                } else {
                    $scope.showStatusMsg('-', response.Message);
                }
                $scope.loadingCashiering(false);
            }, function (data) {
                $scope.loadingCashiering(false);
                $scope.showStatusMsg('-', data.message);
            });
        };

        $scope.openRevDrawer = function () {
            $scope.hideStatusMsg();
            $scope.loadingCashiering(true, "Please wait");
            cashieringService.openRevDrawer(sessionStorage.Key).then(function (response) {
                if (response.Status) {
                    if (!!response.EntityRecords && response.EntityRecords.length > 0) {
                        $scope.revDrawers = response.EntityRecords;
                        $scope.revDrawer = $scope.revDrawers[0];
                        $scope.selectedDrawerId = $scope.revDrawer.RevDrawerId;
                        $scope.viewConfig.batchOpened = true;
                        $scope.viewConfig.SearchMode = 'payment';
                    } else {
                        $scope.viewConfig.batchOpened = false;
                        $scope.revDrawer = null;
                        $scope.selectedDrawerId = null;
                        $scope.viewConfig.SearchMode = 'refundAndTransfer';
                    }
                    $scope.showStatusMsg('+', response.Message);
                } else {
                    $scope.showStatusMsg('-', response.Message);
                }
                $scope.loadingCashiering(false);
            }, function (data) {
                $scope.loadingCashiering(false);
                $scope.showStatusMsg('-', data.message);
            });
        };

        $scope.closeRevDrawer = function () {
            $scope.hideStatusMsg();
            $scope.loadingCashiering(true, "Please wait");
            var revDrawerData = angular.copy($scope.revDrawer);
            cashieringService.closeRevDrawer(sessionStorage.Key, [revDrawerData]).then(function (response) {
                if (response.Status) {
                    $scope.viewConfig.batchOpened = false;
                    $scope.revDrawer = null;
                    $scope.selectedDrawerId = null;
                    $scope.showStatusMsg('+', response.Message);
                } else {
                    $scope.showStatusMsg('-', response.Message);
                }
                $scope.getOpenRevDrawer();
                $scope.loadingCashiering(false);
            }, function (data) {
                $scope.loadingCashiering(false);
                $scope.showStatusMsg('-', data.message);
            });
        };

        $scope.onSelectionChanged = function (isAllSelectedClicked, currentValue) {
            if (!!isAllSelectedClicked) {
                $scope.entityReceipts.map(function (entityReceiptItem) {
                    if (!entityReceiptItem.BadCheck) {
                        entityReceiptItem.isSelected = currentValue;
                    }
                });
            } else {
                $scope.viewConfig.isAllSelected = ($scope.entityReceipts.where('!item.BadCheck').all('!!item.isSelected'));
            }
            $scope.viewConfig.isAnySelected = ($scope.entityReceipts.any('!!item.isSelected'));
        };

        $scope.addNewBeneficiary = function (beneficiarySearchForm) {
            if (validateForm([beneficiarySearchForm])) {
                var beneficiaryInfoData = {
                    entityBeneficiaryOtherInfo: $scope.entityBeneficiaryOtherInfo,
                    entityBeneficiary: ($scope.viewConfig.BeneficiaryNotSameAsPayor ? $scope.entityBeneficiary : $scope.entityPayor)
                };
                beneficiaryInfoData.IsBeneficiarySameAsPayor = (!$scope.viewConfig.BeneficiaryNotSameAsPayor);
                beneficiaryInfoData.CreateNewBeneficiary = $scope.viewConfig.createNewBeneficiary;
                if (!!beneficiaryInfoData.entityBeneficiaryOtherInfo && !!beneficiaryInfoData.entityBeneficiaryOtherInfo.feeDetailRows) {
                    var totalFeePaymentAmountForBeneficiary = 0;
                    angular.forEach(beneficiaryInfoData.entityBeneficiaryOtherInfo.feeDetailRows, function (feeDetailItem) {
                        totalFeePaymentAmountForBeneficiary = totalFeePaymentAmountForBeneficiary + (1 * feeDetailItem.Amount);
                    });
                    beneficiaryInfoData.TotalAmount = totalFeePaymentAmountForBeneficiary;
                }
                $scope.entityBeneficiariesData.push(beneficiaryInfoData);

                $scope.entityBeneficiaryOtherInfo = {
                    createNewApplication: true
                };
                $scope.viewConfig.BeneficiaryNotSameAsPayor = false;
                resetBeneficiary();
                beneficiarySearchForm.$submitted = false;
                beneficiarySearchForm.$setPristine();
                $scope.loadApplications();
            }
        };

        $scope.onPayorOrBeneficiaryChanged = function (entityPayorOrBeneficiary) {
            if (!!entityPayorOrBeneficiary && (!!entityPayorOrBeneficiary.FirstName
                || !!entityPayorOrBeneficiary.MiddleName || !!entityPayorOrBeneficiary.LastName
                || !!entityPayorOrBeneficiary.SSN || !!entityPayorOrBeneficiary.DateOfBirth
            )) {
                entityPayorOrBeneficiary.EntityType = "I";
                return;
            } else if (!!entityPayorOrBeneficiary && (!!entityPayorOrBeneficiary.EntityName)) {
                entityPayorOrBeneficiary.EntityType = "P";
                return;
            } else {
                entityPayorOrBeneficiary.EntityType = "";
            }
        };

        $scope.init();
    }
})();