(function() {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('supervisingPhysicianLogic', [SupervisingPhysicianLogic]);

    function SupervisingPhysicianLogic() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                
            }
        };
    }
})();