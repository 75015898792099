(function () {
  'use strict'
  angular.module('app.InitialApplication')
    .directive('initialApplicationProfessionalReferences', initialApplicationProfessionalReferencesDirective);

  initialApplicationProfessionalReferencesDirective.$inject = [];

  function initialApplicationProfessionalReferencesDirective() {
    return {
      restrict: 'E',
      scope: {
        headingPosition: "=headingPosition",
        natValidation: "=natValidation",
        individualId: '=individualId',
        applicationId: '=applicationId',
        isBackoffice: "=isBackoffice",
        applicationInfo: "=?applicationInfo",
        tab: "=tab",
        openNextTab: "&",
        isRenewal: "=?isRenewal",
        dashboard: "=dashboard"
      },
      templateUrl: "app/components/individual/application/directives/professional-references/professional-references.html",
      controller: "InitialApplicationProfessionalReferencesController"
    }
  }
})();