﻿(function () {
    'use strict'
    angular.module('app.backofficeAdministor')
        .factory('ReferencetableFactory', ['$http', 'WebApiUrl', '$q', function ($http, WebApiUrl, $q) {
            var _getRefTableMasterList = function (key) {
                return returnDefferedResult($http.get(WebApiUrl + 'TypeValues/GetRefTableMasterList/' + key ));
            };

            var _getTableDataByRefTableId = function (key, tableId,searchData) {
                return returnDefferedResult($http.post(WebApiUrl + 'TypeValues/GetTableDataByRefTableId/' + key + '?TableId=' + tableId, searchData));
            }

            var _refTableFieldDetailsByRefTableId = function (key, tableId) {
                return returnDefferedResult($http.get(WebApiUrl + 'TypeValues/RefTableFieldDetailsByRefTableId/' + key + '?TableId=' + tableId));
            };

            var _refGetRefTableFieldSourceData = function (key, data) {
                return returnDefferedResult($http.post(WebApiUrl + 'TypeValues/GetRefTableFieldSourceData/' + key, data));
            };            

            var returnDefferedResult = function (request) {
                var deferred = $q.defer();
                request.then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.reject(response);
                });
                return deferred.promise;
            };
            return {
                GetRefTableMasterList: _getRefTableMasterList,
                RefTableFieldDetailsByRefTableId: _refTableFieldDetailsByRefTableId,
                GetTableDataByRefTableId: _getTableDataByRefTableId,
                GetRefTableFieldSourceData: _refGetRefTableFieldSourceData
            };
        }]);
})();