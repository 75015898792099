(function () {
    'use strict'

    angular.module('app.backofficeProvider')
        .factory('backofficeProviderCorrespondenceService', backofficeProviderCorrespondenceService)

    backofficeProviderCorrespondenceService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeProviderCorrespondenceService($http, $q, WebApiUrl, AppConfig) {

        var _individualCorrespondenceByIndividualId = function (Key, providerId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualCommunicationGetByIndividualId/' + Key + '?IndividualId=0&ProviderId=' + providerId));
        }

        var _individualCorrespondenceSave = function (correspondence, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualCommunicationSave/' + key + '?Source=' + AppConfig.Source, correspondence));
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        return {
            individualCorrespondenceByIndividualId: _individualCorrespondenceByIndividualId,
            individualCorrespondenceSave: _individualCorrespondenceSave
        }
    }
})();