(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintInvolvedParty', [ComplaintInvolvedParty]);

    function ComplaintInvolvedParty() {
        return {
            restrict: 'E',
            scope: {
                complaint: '=',
                complaintInvolvedPartyTypeId: '=typeId',
                complaintInvolvedPartyTypeIds: '=typeIds',
                maxOfType: '=?',
                linkDocumentControl: '=?',
                readOnly: '=?',
                partySaved: '&?',
                hideSearch: '=?',
                currentInvolvedParties: '=?',
                externalSave: '=?',
                publicFacing: '=?',
                configName: '@?',
                involvedParties: '=?'
            },
            link: function ($scope, element, attrs) {

            },
            templateUrl: 'app/components/backoffice/enforcement/complaint-involved-party/complaint-involved-party.html',
            controller: 'ComplaintInvolvedPartyController'
        };
    }
})();
