(function () {
    'use strict'
    angular.module('app.login')
        .controller("FirmForgotUserNameController", ["$scope", "$controller", "$state", 'AppConfig', 'RegisterFactory', 'MessageFactory', '$filter', '$q', '$timeout', 'FrimForgotUsernameService','Messages',
            function ($scope, $controller, $state, AppConfig, RegisterFactory, MessageFactory, $filter, $q, $timeout, FrimForgotUsernameService, Messages) {
                $scope.$watch('statusMessage', function () {
                    $timeout(function () {
                        angular.element(".registerStatusMessage").focus();
                    })
                })

                $scope.blurDOB = false;

                $scope.login = function () {
                    $state.go('app.FirmLogin');
                };
                $scope.loginNewApp = function () {
                    $state.go('app.NewApplicantLogin');
                };
                $scope.dateOptions = { format: 'MM/DD/YYYY', datepickerMode: "year" };

                $scope.$watch('dateOptions.datepickerMode', function (newval, oldval) {
                    if (newval == 'month')
                        $timeout(function () { $('div[uib-monthpicker]').focus() })
                    if (newval == 'day')
                        $timeout(function () { $('div[uib-daypicker]').focus() })
                })
                $scope.$watch('popup1.opened', function (newval) {
                    if (!newval)
                        $scope.dateOptions.datepickerMode = "year";
                })

                $scope.formats = ['MM/dd/yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
                $scope.format = $scope.formats[0];

                $scope.open1 = function () {
                    $scope.popup1.opened = true;
                };
                $scope.popup1 = {
                    opened: false
                };
                $scope.altInputFormats = ['M!/d!/yyyy'];

                $scope.maskConfig = {
                    clearOnBlur: false,
                    allowInvalidValue: false,
                    clearOnBlurPlaceholder: true
                };

                $scope.Individual = {};
                var isUserExists = false;
                $scope.Register = function () {

                    //$scope.hideStatusMessage();
                    //$scope.Individual.IsNewApplicant = false;
                    //if (validateData('regvalidation')) {
                    //    ShowLoader("Please wait...");
                    //    CheckLoginUserName();
                    //} else {
                    //    $scope.statusMessage = {};
                    //}
                    $scope.hideStatusMessage();
                    if (!validateData('regvalidation'))
                        return;

                    if (!angular.isDate($scope.Individual.DateOfBirth)) {
                        $scope.showStatusMessage(Messages.Registration.Errors.Age, 'error');
                        return;
                    }
                    if (!validateDateOFBirth(new Date(angular.copy($scope.Individual.DateOfBirth)))) {
                        $scope.showStatusMessage(Messages.Registration.Errors.Age, 'error');
                        return;
                    }
                    ShowLoader("Please wait...");
                    CheckLoginUserName();
                };

                $scope.preventPaste = function (e) {
                    e.preventDefault();
                    return false;
                };

                $scope.RegisterNewApplicant = function (form) {
                    $scope.hideStatusMessage();
                    if (!validateData('naregistrationval'))
                        return;
                    // if (!angular.isDate($scope.Individual.DateOfBirth)) {
                    //     $('#dpregisterdob').addClass("required-text");
                    //     $scope.showStatusMessage(Messages.Registration.Errors.BirthDayFormat, 'error');
                    //     return;
                    // }
                    // if (!validateDateOFBirth(new Date(angular.copy($scope.Individual.DateOfBirth)))) {
                    //     $('#dpregisterdob').addClass("required-text");
                    //     $scope.showStatusMessage(Messages.Registration.Errors.Age, 'error');
                    //     return;
                    // }
                    //email                    
                    // if ($scope.Individual.ConfirmEmail.trim().toLowerCase() != $scope.Individual.Email.trim().toLowerCase()) {
                    //     $('#confirmemail').addClass("required-text");
                    //     $scope.showStatusMessage(Messages.ccecefa, 'error');
                    //     return;
                    // }
                    ShowLoader("Please wait...");
                    CheckLoginUserName();
                };

                $scope.CheckExistingUserForRegistrationStatusCode = 0;
                $scope.CheckExistingUserForRegistrationIndvID = 0;
                var CheckLoginUserName = function () {
                    //$scope.Individual.DateOfBirth.setUTCHours(0);
                    //$scope.Individual.DateOfBirth.setUTCMilliseconds(0);
                    //$scope.Individual.DateOfBirth.setUTCMinutes(0);
                    $scope.Firmforgotusername = 'cba';
                    var reqData = angular.copy($scope.provider);
                    reqData.UserTypeId = 7;
                    FrimForgotUsernameService.CheckLoginUserName(reqData, $scope.Firmforgotusername).then(function (res) {
                        var errMessage = res.data.StatusMessage;

                        if (res.data.Status) {
                            $scope.showStatusMessage(errMessage, 'success');
                        } else {
                            $scope.showStatusMessage('An unknown error occured.', 'error');
                        }
                        
                        HideLoader();
                    }, function (res) {
                        $scope.showStatusMessage(Messages.eaacdec, 'error');
                        HideLoader();
                    });
                };

                var ApplicationTypeId = 0;
                var ApplicationStatusReasonId = 0;
                var ApplicationInternalStatusId = 0;

                var GenerateApplicationId = function (ApplicationID, UserID) {

                    var Urlparm = '/key?ApplicationId=' + ApplicationID + '&UserId=' + UserID;
                    var reqData = {
                        ApplicationTypeId: ApplicationTypeId,
                        ApplicationStatusId: 6,
                        ApplicationSubmitMode: 'O',
                        // ApplicationNumber: Messages.abdfade,
                        //StartedDate: new Date(),
                        ApplicationStatusReasonId: ApplicationStatusReasonId,
                        GoPaperless: true,
                        IsDeleted: 0,
                        IsActive: 1,
                        IsArchive: 0,
                        ApplicationInternalStatusId: ApplicationInternalStatusId
                    };

                    RegisterFactory.GenerateApplicationId(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) {
                            var appID = res.data.ApplicationId;
                            //if existing user registration
                            //Else new applicant

                            if ($scope.CheckExistingUserForRegistrationStatusCode == 4) {
                                GetUserByIndividualId($scope.CheckExistingUserForRegistrationIndvID, UserID, appID);
                            } else {
                                if (!$scope.CheckExistingUserForRegistrationIndvID || $scope.CheckExistingUserForRegistrationIndvID == 0) {
                                    SaveIndividual(appID);
                                } else {
                                    //new applicant registration
                                    //if individual already existes not required to create new individual and go next step
                                    var indvID = $scope.CheckExistingUserForRegistrationIndvID;
                                    SaveIndividualNameTypeLk(appID, indvID);
                                    //IndividualContactSave(indvID);
                                    var reqData = {
                                        IndividualId: indvID,
                                        ApplicationID: appID,
                                        UserName: $scope.Individual.Email,
                                        UserTypeId: 2,
                                        FirstName: $scope.Individual.FirstName,
                                        LastName: $scope.Individual.LastName,
                                        SSN: $scope.Individual.Last4SSN,
                                        Email: $scope.Individual.Email,
                                        UserStatusId: 4,
                                        TemporaryPassword: true,
                                        DateOfBirth: angular.copy($scope.Individual.DateOfBirth),
                                        SourceId: 1,
                                        IsDeleted: 0,
                                        IsArchive: 0,
                                        CreatedOn: new Date(),
                                        Phone: $scope.Individual.Phone
                                    };

                                    UsersSave(reqData, appID);
                                }
                            }
                        } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }

                    }, function (res) {
                        HideLoader();
                        var err = 'Error in the Registration Process: ApplicationSave, ApplicationTypeId=' + ApplicationTypeId + '  (Reference Code: 00039). When contacting Board Office, please refer the Reference Code in the communication'
                        $scope.showStatusMessage('', 'error');
                    });
                };
                var SaveIndividual = function (applicationID) {
                    var Urlparm = '/Key';
                    var reqData = angular.copy($scope.Individual);
                    reqData.PlaceofBirthState = 'O';
                    reqData.Gender = 'O';
                    reqData.IsActive = true;
                    RegisterFactory.SaveIndividual(Urlparm, [reqData]).then(function (res) {
                        if (res.data.Status && res.data.IndividualResponse && res.data.IndividualResponse.length > 0) {
                            var indvID = res.data.IndividualResponse[0].IndividualId;
                            SaveIndividualNameTypeLk(applicationID, indvID);
                            //IndividualContactSave(indvID);
                            var reqData = {
                                IndividualId: indvID,
                                ApplicationID: applicationID,
                                UserName: $scope.Individual.Email,
                                UserTypeId: 2,
                                FirstName: $scope.Individual.FirstName,
                                LastName: $scope.Individual.LastName,
                                SSN: $scope.Individual.Last4SSN,
                                Email: $scope.Individual.Email,
                                UserStatusId: 4,
                                TemporaryPassword: true,
                                DateOfBirth: angular.copy($scope.Individual.DateOfBirth),
                                SourceId: 1,
                                IsDeleted: 0,
                                IsArchive: 0,
                                CreatedOn: new Date(),
                                Phone: $scope.Individual.Phone
                            };

                            UsersSave(reqData, applicationID);

                        } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(res.data.Message, 'error');
                    });
                };
                var SaveIndividualNameTypeLk = function (appID, indvID) {
                    var Urlparm = '/Key';
                    var reqData = {
                        IndividualId: indvID,
                        IndividualNameTypeId: 1,
                        IndividualNameStatusId: 1,
                        ApplicationId: appID,
                        IsActive: 1,
                        IsDeleted: 0,
                    };
                    RegisterFactory.SaveIndividualNameTypeLk(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) { } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage('“Error in the Registration Process: IndividualNameTypeLkSave, ApplicationTypeId=' + ApplicationTypeId + '  (Reference Code: 00044). When contacting Board Office, please refer the Reference Code in the communication.', 'error');
                    });
                };
                var IndividualContactSave = function (indvID, userid, appID) {
                    var Urlparm = '/Key';
                    var reqData = {
                        IndividualId: indvID,
                        ContactId: 0,
                        ContactTypeId: 8,
                        //ContactTypeDesc
                        //ContactTypeId: 18,
                        CreatedBy: userid,
                        ContactStatusId: 1,
                        ApplicationId: appID,
                        ContactInfo: $scope.Individual.Email,
                        //BeginDate
                        //EndDate
                        //IsPreferredContact
                        IsActive: 1,
                        IsDeleted: 0
                    };
                    RegisterFactory.IndividualContactSave(Urlparm, [reqData]).then(function (res) {

                        HideLoader();
                        if (res.data.Status) {
                            $scope.showStatusMessage(res.data.Message, 'success');
                        } else {
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(res.data.Message, 'error');
                    });
                };

                var IndividualContactPhoneSave = function (indvID, userid, appID) {
                    var deffer = $q.defer();
                    var Urlparm = '/Key';
                    var reqData = {
                        IndividualId: indvID,
                        ContactId: 0,
                        ContactTypeId: 1,
                        CreatedBy: userid,
                        ContactStatusId: 1,
                        ApplicationId: appID,
                        ContactInfo: $scope.Individual.Phone,
                        IsActive: 1,
                        IsDeleted: 0
                    };
                    RegisterFactory.IndividualContactSave(Urlparm, [reqData]).then(function (res) {
                        deffer.resolve();
                    }, function (res) {
                        deffer.reject();
                    });
                    return deffer.promise;
                };
                var UsersSave = function (reqData, AppID) {
                    var Urlparm = '/Key?Source=' + AppConfig.Source;
                    var indvID = reqData.IndividualId;
                    RegisterFactory.UsersSave(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) {

                            var user = res.data.Users[0];
                            var uId = user.UserId;
                            if (!isUserExists) {

                                UserChangeLogSave(uId);
                                //if new reg
                                if ($scope.Individual.IsNewApplicant) {
                                    var reqData = [{
                                        UserID: uId,
                                        Selected: true,
                                        RoleId: 1
                                    }];
                                    UserRoleSave(reqData);

                                }
                                //if existing user reg
                                else {
                                    var reqData = [{
                                        UserID: uId,
                                        Selected: true,
                                        RoleId: 2
                                    }];
                                    UserRoleSave(reqData);
                                    ShowMessageExistingUserLogin(user.UserName, user.UserStatusId, res);
                                    HideLoader();
                                }

                                // SaveIndividualApplication
                                SaveIndividualApplication(user.IndividualId, AppID, uId);
                            } else {
                                //user already exist condition
                                // check role else insert
                                ShowMessageExistingUserLogin(user.UserName, user.UserStatusId, res);
                                UserRoleGetBYUserID(uId);
                                ResetPassword(uId);
                            }
                            reqData = {
                                ApplicationId: AppID,
                                ApplicationTypeId: 6,
                                ApplicationStatusId: 6,
                                ApplicationStatusReasonId: 1,
                                IsActive: 1,
                                IsDeleted: 0,
                                IsArchive: 0,
                                CreatedBy: uId,
                                ApplicationInternalStatusReasonText: '',
                                ApplicationInternalStatusId: 7,
                                HighPriority: false
                            };
                            IndividualContactSave(indvID, uId, AppID);
                            IndividualContactPhoneSave(indvID, uId, AppID);
                            ApplicationStatusLkSave(uId, reqData);
                            IndividualLogSave(uId, indvID, AppID);
                        } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage('Error in the Registration Process: Users, ApplicationTypeId=' + ApplicationTypeId + '  (Reference Code: 00047). When contacting Board Office, please refer the Reference Code in the communication.', 'error');
                    });
                };
                var UserRoleSave = function (reqData) {
                    var Urlparm = '/Key';
                    RegisterFactory.UserRoleSave(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) {
                            //$scope.showStatusMessage(MessageFactory.NewApplicantRegSuccess, 'success');
                            $scope.registrationSuccess = "Please check your email a temporary password has been sent to you. You need to login using that password."
                            //$scope.Individual = {};
                            //$('.datepicker').val('');
                        } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage('Error in the Registration Process: UserRoleSave, ApplicationTypeId=' + ApplicationTypeId + '  (Reference Code: 00050). When contacting Board Office, please refer the Reference Code in the communication.', 'error');
                    });
                };
                var _IndividualName = '';
                // var CheckLoginUserName = function () {
                //     var Urlparm = '/Key';
                //     ForgotUsernameApplicantService.CheckLoginUserName(Urlparm, $scope.Individual).then(function (res) {
                //         if (res.data.Status) {
                //             var individualID = res.data.IndividualID;
                //             _IndividualName = res.data.FirstName;
                //             GetUserByIndividualId(individualID);
                //         } else {
                //             HideLoader();
                //             console.log(res.data.Message);
                //             $scope.showStatusMessage(res.data.Message, 'error');
                //         }
                //     }, function (res) {
                //         HideLoader();
                //         $scope.showStatusMessage(res.data.Message, 'error');
                //     });
                // };

                var GetUserByIndividualId = function (individualID, userid, appid) {
                    var Urlparm = '/key?IndividualId=' + individualID + '&UserId=' + userid;
                    RegisterFactory.UserByIndividualId(Urlparm).then(function (res) {
                        if (res.data.Status) {
                            var user = res.data.Users;
                            var userStatusId = 0;
                            var userID = 0;
                            var userName = '';
                            if (user) {
                                isUserExists = true;
                                var userStatusId = user.UserStatusId;
                                var userName = user.UserName;
                                var userID = user.UserId;
                                //update user table if user name is null or empty
                                if (userName && userName != "") {
                                    if ((userName.toLowerCase() == $scope.Individual.Email.toLowerCase().trim()) && (userStatusId == 4 || userStatusId == 6)) {
                                        ResetPassword(userID);
                                    }
                                    ShowMessageExistingUserLogin(userName, userStatusId, res);
                                    HideLoader();
                                } else {
                                    //update user here
                                    user.UserName = $scope.Individual.Email;
                                    user.Email = $scope.Individual.Email;
                                    user.UserStatusId = 4;
                                    user.UserTypeId = 6;
                                    user.TemporaryPassword = true;
                                    //user.FirstName = (user.FirstName == null || !user.FirstName) ? _IndividualName : user.FirstName;
                                    //user.LastName = user.LastName == null || !user.LastName ? $scope.Individual.LastName : user.LastName;
                                    user.FirstName = $scope.Individual.FirstName;
                                    user.LastName = $scope.Individual.LastName;
                                    user.DateOfBirth = angular.copy($scope.Individual.DateOfBirth);
                                    user.Phone = $scope.Individual.Phone;
                                    user.ApplicationID = appid,
                                        UsersSave(user, appid);
                                    UserChangeLogSave(userID);
                                }
                            } else {
                                HideLoader();
                            }

                        } else {
                            var indvID = $scope.CheckExistingUserForRegistrationIndvID;
                            SaveIndividualNameTypeLk(appid, indvID);

                            //UserTypeId = 6, UserStatusId = 4, TemporaryPassword = True, IndividualId = IndividualId
                            var reqData = {
                                UserName: $scope.Individual.Email,
                                UserTypeId: 6,
                                UserStatusId: 4,
                                TemporaryPassword: true,
                                IndividualId: individualID,
                                FirstName: $scope.Individual.FirstName,
                                LastName: $scope.Individual.LastName,
                                //FirstName: (_IndividualName && _IndividualName != '' ? _IndividualName : $scope.Individual.FirstName),
                                //LastName: $scope.Individual.LastName ? $scope.Individual.LastName : "",
                                Email: $scope.Individual.Email,
                                DateOfBirth: angular.copy($scope.Individual.DateOfBirth),
                                Phone: $scope.Individual.Phone,
                                SourceId: 1,
                                IsDeleted: 0,
                                IsArchive: 0,
                                CreatedOn: new Date(),
                                ApplicationID: appid
                            };
                            UsersSave(reqData, appid);
                            //IndividualContactSave(individualID);
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(res.data.Message, 'error');
                    });
                };

                //
                var UserChangeLogSave = function (userID) {
                    var Urlparm = '/key?userId=' + userID;
                    var reqData = {
                        UserChangeLogId: 0,
                        UserId: userID,
                        UserName: '',
                        UserTypeId: 6,
                        UserStatusId: 4,
                        UserChangeDateTime: new Date(),
                        UserChangedBy: userID,
                        ReferenceNumber: '',
                        UserChangeReasonText: '',
                        UserChangeLogGuid: 'Guid'
                    };
                    RegisterFactory.UserChangeLogSave(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) {

                        } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage('Error in the Registration Process: UserChangeLogSave, ApplicationTypeId=' + ApplicationTypeId + '  (Reference Code: 00049). When contacting Board Office, please refer the Reference Code in the communication.', 'error');
                    });
                };

                var ApplicationStatusChangeLogSave = function (appID, userID) {
                    var Urlparm = '/key?UserId=' + userID;
                    reqData = {
                        ApplicationId: appID,
                        ApplicationStatusReasonId: 1,
                        ApplicationNumber: '',
                        ApplicationTypeId: 6,
                        ApplicationStatusId: 6,
                        ApplicationSubmitMode: '',
                        ApplicationStatusChangedBy: userID,
                        ReferenceNumber: '',
                        HighPriority: '',
                        ApplicationInternalStatusId: 7,
                        ApplicationInternalStatusReasonText: '',
                        ApplicationStatusChangeLogGuid: 'guid'
                    };
                    RegisterFactory.ApplicationStatusChangeLogSave(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) { } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'success');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(res.data.Message, 'error');
                    });
                };


                var ApplicationStatusLkSave = function (userID, reqData) {
                    var Urlparm = '/key?UserId=' + userID;
                    RegisterFactory.ApplicationStatusLkSave(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) { } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'success');
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage('Error in the Registration Process: ApplicationStatusLkSave, ApplicationTypeId=' + ApplicationTypeId + '  (Reference Code: 00041). When contacting Board Office, please refer the Reference Code in the communication.', 'error');
                    });
                }

                var ShowMessageExistingUserLogin = function (userName, userStatusId, res) {
                    //var msg = '';
                    if (!userName || userName == '' || (userName.toLowerCase() == $scope.Individual.Email.toLowerCase().trim())) {
                        if (userStatusId == 1) {
                            $scope.showStatusMessage(MessageFactory.Alreadyreg, "error");
                        } else if (userStatusId == 2 || userStatusId == 3) {
                            $scope.showStatusMessage(MessageFactory.PlsUseForgotPass, "error");
                        } else if (userStatusId == 4 || userStatusId == 6) {
                            $scope.registrationSuccess = "Please check your email a temporary password has been sent to you. You need to login using that password."
                            //
                            //$scope.showStatusMessage(res.data.Message, "success");
                        } else if (userStatusId = 5) {
                            $scope.showStatusMessage(MessageFactory.ContactCAMTC, "error");
                        }
                    } else {
                        $scope.showStatusMessage(MessageFactory.UserEmailShouldSame, "error");
                    }
                };

                var ResetPassword = function (userId) {
                    var Urlparm = '/key';
                    var reqData = [{ UserId: userId }];
                    RegisterFactory.ResetPassword(Urlparm, reqData).then(function (res) {
                        if (res.data.Status) { } else {
                            HideLoader();
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(res.data.Message, 'error');
                    });
                };

                var SaveIndividualApplication = function (IndividulaID, AppID, userID) {
                    // console.log('indvapplication save');
                    if (IndividulaID && IndividulaID != 0 && AppID && AppID != 0) {
                        var Urlparm = '/key';
                        var reqData = {
                            ApplicationId: AppID,
                            IndividualId: IndividulaID,
                            CreatedBy: userID,
                            CreatedOn: new Date()
                        };
                        // console.log('dd indv save', reqData);
                        RegisterFactory.SaveIndividualApplication(Urlparm, reqData).then(function (res) {

                            // console.log('dd indv save success');
                            if (res.data.Status) { } else {
                                HideLoader();
                            }
                        }, function (res) {
                            HideLoader();
                            $scope.showStatusMessage(Messages.bbffceb, 'error');

                        });
                    }
                };

                var UserRoleGetBYUserID = function (uID) {
                    var urlParm = "/Key?UserId=" + uID;
                    RegisterFactory.UserRoleGetBYUserID(urlParm).then(function (res) {
                        if (!res.data.UserRole || res.data.UserRole.length == 0) {
                            var reqData = [{
                                UserID: uID,
                                Selected: true,
                                RoleId: 2
                            }];
                            UserRoleSave(reqData);
                        }

                        HideLoader();
                    }, function () {

                        HideLoader();
                    });
                };
                //audit api call
                var AuditvisitInfoSave = function () {
                    var parm = 'key';
                    var data = {
                        HostIPAddress: location.host,
                        PageName: 'Ingividual Registration',
                        RequestUrl: window.location.href,
                        RequestUrlReferrer: document.referrer,
                        RequestBrowserTypeVersion: 'Type: ' + navigator.appCodeName + ', Version: ' + navigator.appVersion,
                        SessionID: '', //document.cookie,
                        TimeStamp: new Date(),
                        IndividualId: 0,
                        EntityId: '',
                        UserId: 0,
                        Platform: navigator.platform,
                        MachineDeviceName: '',
                        DeviceId: '',
                        AppDomainName: '',
                        UserHostAddress: '',
                        UserHostName: '',
                        IsJavascriptEnabled: true,
                        IsCookieEnabled: navigator.cookieEnabled,
                        IsCrawler: '',
                        IsActiveXControlEnabled: ''
                    };
                    RegisterFactory.AuditvisitInfoSave(parm, data).then(function (res) {
                        /// auditSaved
                    }, function (res) {

                    });
                };
                AuditvisitInfoSave();
                //indv log save
                var IndividualLogSave = function (uid, indvID, appID) {
                    var parem = 'key'
                    var reqData = {
                        IndividualId: indvID,
                        ApplicationId: appID,
                        LogSource: "ApplicantRegistration",
                        LogText: "Applicant Registration Submitted",
                        ReferenceNumber: '',
                        IsActive: 1,
                        IsDeleted: 0,
                        CreatedBy: uid,
                        LogMessageId: 1
                    };
                    RegisterFactory.IndividualLogTableSave(parem, reqData).then(function (res) {
                        // console.log(res);
                    }, function (res) {
                        $scope.showStatusMessage(Messages.ffbdddd, 'error');
                    });
                };

                var _MS_PER_DAY = 1000 * 60 * 60 * 24;
                // a and b are javascript Date objects
                var dateDiffInDays = function (a, b) {
                    // Discard the time and time-zone information.
                    var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
                    var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
                    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
                };

                var validateDateOFBirth = function (dateofBirth) {
                    var s = dateDiffInDays(dateofBirth, new Date());
                    var validationYear = 18;
                    if (s < (365 * validationYear)) {
                        return false;
                    }
                    return true;
                };

                var getMaskedEmail = function (email) {
                    var res = email;
                    //if email length >6 make first 3 char * and last 3 char *
                    var ind = email.indexOf('@');
                    if (ind > 6) {
                        //  console.log(ind - 3)
                        res = '***' + email.substr(3, (ind - 6)) + '***' + email.substr(ind)
                    }
                    //if email length <6 make first 3 char * and last 3 char *
                    else if (ind >= 3) {
                        var ind = email.indexOf('@');
                        res = '***' + email.substr(3, ind - 3) + email.substr(ind)
                    }
                    return res;
                };
            }
        ])
})();