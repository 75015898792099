﻿(function () {
    'use strict'
    angular.module('app.backofficeAdministor')
    .directive('dynamicInputDirective', function () {
        return {
            restrict: 'EA',
            scope: {
                FieldDetails: '=fieldDetails',
                formobj: '=formobj',
                FormData: '=inputModel',
                PreventReadOnly:'=preventReadonly',
                OnDropdownValueChange:'&?ondropdoenvaluechange'
            },
            controller: "DynamicInputController",
            templateUrl: "app/components/backoffice/administrator/referencetable/dynamic-input-directive/dynamic-input-template.html",
        };
    })
    .directive('stringToNumber', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (value) {
                    return '' + value;
                });
                ngModel.$formatters.push(function (value) {
                    return parseFloat(value);
                });
            }
        };
    });
})();