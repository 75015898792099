﻿(function () {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("FirmsSearchController", FirmsSearchController);
        
    FirmsSearchController.$inject = ["$rootScope", "$scope", "$state", "$controller", "BackofficeIndividualService", "WebApiUrl", "backofficeProviderResposibleChargeService", "backofficeIndividualAssociatedwithFirmService", "RegisterFactory", "BackofficeProviderService", "typeValuesService", "BackofficeFirmRenewalListService", 'Messages'];

    function FirmsSearchController($rootScope, $scope, $state, $controller, BackofficeIndividualService, WebApiUrl, backofficeProviderResposibleChargeService, backofficeIndividualAssociatedwithFirmService, RegisterFactory, BackofficeProviderService, typeValuesService, BackofficeFirmRenewalListService, Messages) {
        $controller('GlobalController', { $scope: $scope });
        //$scope.init();
        if (!$scope.isSessionActive()) {
            $state.go('app.BackofficeLoginstaff');
        }

        $scope.routeData = $state.current.routeData;
        $scope.documentsList = null;
        $scope.documentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: null,
            IndividualId: "",
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "FR",
        };

        $scope.dateOptions = { format: 'MM/DD/YYYY' };

        $scope.formats = ['MM/dd/yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];

        $scope.open1 = function () {
            $scope.popup1.opened = true;
        };
        $scope.popup1 = {
            opened: false
        };
        $scope.altInputFormats = ['M!/d!/yyyy'];
        
        $scope.maskConfig = {
            clearOnBlur: false,
            allowInvalidValue: false,
            clearOnBlurPlaceholder: true
        };


        $scope.searchFirm = {
            Name: '',
            LicenseNumber: '',
            Email: '',
            Address: '',
            LicenseTypeId: '',
            LicenseStatus: '',
            CourseName: '',
            CourseTypeId: '',
            ContactName: '',
            CourseCategory: '',
            CourseStatus: ''
        };

        $scope.searchFirms = function () {
            $scope.searchFirm.ProviderTypeId = 5;
            ShowLoader();
            hideStatusMessage();
            if (!$scope.addButton) {
                
                $scope.addButton = false;
                $scope.showaddButton = false;
                
                BackofficeIndividualService.setFirmSearchDataContent($scope.searchFirm);
                BackofficeFirmRenewalListService.providerSearchWithPage(sessionStorage.Key, 1, 10, $scope.searchFirm)
                    .then(function (response) {

                        if (response.data.ProviderList) {
                            $state.go('app.Firms');
                        }
                        else {
                            showStatusMessage(response.Message, "error");
                            $scope.showaddButton = true;
                        }
                        HideLoader();
                    }, function (res) {
                        HideLoader();
                    });
            }
            else {
                HideLoader();
            }
        }

        $scope.searchCEPPProvider = function () {
            if (!!$scope.routeData && !!$scope.routeData.ProviderTypeId) {
                $scope.searchFirm.ProviderTypeId = $scope.routeData.ProviderTypeId;
            }
            ShowLoader();
            hideStatusMessage();
            if (!$scope.addButton) {
                $scope.addButton = false;
                $scope.showaddButton = false;
                BackofficeIndividualService.setFirmSearchDataContent($scope.searchFirm);
                BackofficeFirmRenewalListService.providerSearchWithPage(sessionStorage.Key, 1, 10, $scope.searchFirm)
                    .then(function (response) {

                        if (response.data.StatusCode == '00') {
                            if (response.data.ProviderList) {
                                $state.go('app.CEPP');
                            }
                            else {
                                showStatusMessage(Messages.dcdcabb, "error");
                            }
                        }
                        else {
                            showStatusMessage(response.Message, "error");
                            $scope.showaddButton = true;
                        }
                        HideLoader();
                    }, function (res) {
                        HideLoader();
                    });
            }
            else {
                HideLoader();
            }
        }

        $scope.searchAMCProvider = function () {
            if (!!$scope.routeData && !!$scope.routeData.ProviderTypeId) {
                $scope.searchFirm.ProviderTypeId = $scope.routeData.ProviderTypeId;
            }
            ShowLoader();
            hideStatusMessage();
            if (!$scope.addButton) {
                $scope.addButton = false;
                $scope.showaddButton = false;
                BackofficeIndividualService.setFirmSearchDataContent($scope.searchFirm);
                BackofficeFirmRenewalListService.providerSearchWithPage(sessionStorage.Key, 1, 10, $scope.searchFirm)
                    .then(function (response) {

                        if (response.data.StatusCode == '00') {
                            if (response.data.ProviderList) {
                                $state.go('app.AMC');
                            }
                            else {
                                showStatusMessage(Messages.dcdcabb, "error");
                            }
                        }
                        else {
                            showStatusMessage(response.Message, "error");
                            $scope.showaddButton = true;
                        }
                        HideLoader();
                    }, function (res) {
                        HideLoader();
                    });
            }
            else {
                HideLoader();
            }
        }

        // Pagination
        $scope.Pager = {
            pageSize: 20,
            currentPage: 1,
            totalRecords: "",
            maxSize: 3,
        };

        $scope.addFirm = function () {
            $scope.addButton = true;
        }

        $scope.saveFirm = function (ProviderTypeId) {
            if ($scope.searchFirm && $scope.searchFirm.Name) {
                ShowLoader();
                var reqdata = {};
                reqdata.SubmittedDate = new Date();
                reqdata.ProviderTypeId = ProviderTypeId;
                reqdata.ProviderStatusTypeId = 1;
                reqdata.IsEnabled = true;
                reqdata.IsActive = true;
                reqdata.IsDeleted = false;
                reqdata.CreatedBy = sessionStorage.UserID;
                reqdata.CreatedOn = new Date();
                reqdata.ProviderName = $scope.searchFirm.Name;
                BackofficeProviderService.ProviderSave(sessionStorage.Key, 0, sessionStorage.UserID, reqdata).then(function (res) {
                    if (res.data.Status) {
                        HideLoader();
                        sessionStorage.isFirmUserCreated = true;
                        $scope.addButton = false;
                        if (ProviderTypeId == 5) {
                            $scope.searchFirms();
                        }
                        else if (ProviderTypeId == 6) {
                            $scope.searchAMCProvider();
                        }
                    }
                });
            }
        }

        $scope.cancelfirm = function () {
            $scope.addButton = false;
            $scope.showaddButton = false;
            $scope.searchFirm = {};
        }

        $scope.loadTypeahed = function (searchString, searchType) {
            return backofficeProviderResposibleChargeService.getEmployerList(
                sessionStorage.Key, searchString, 5, searchType
            ).then(function (response) {
                if (!!response.Employers) {
                    return response.Employers.map(function (item) {
                        item.formattedResult = (item.EmployerName + " " + item.StreetLine1 + " " + item.StreetLine2 + " " + item.City + " " + item.Zip);
                        return item;
                    });
                }
            }, function (res) {

            });
        };

        $scope.loadTypeahedForCEPP = function (searchString, searchType) {
            return backofficeProviderResposibleChargeService.getEmployerList(
                sessionStorage.Key, searchString, 3, searchType
            ).then(function (response) {
                if (!!response.Employers) {
                    return response.Employers.map(function (item) {
                        item.formattedResult = (item.EmployerName + " " + item.StreetLine1 + " " + item.StreetLine2 + " " + item.City + " " + item.Zip);
                        return item;
                    });
                }
            }, function (res) {

            });
        };

        $scope.loadTypeahedForAMC = function (searchString, searchType) {
            return backofficeProviderResposibleChargeService.getEmployerList(
                sessionStorage.Key, searchString, 6, searchType
            ).then(function (response) {
                if (!!response.Employers) {
                    return response.Employers.map(function (item) {
                        item.formattedResult = (item.EmployerName + " " + item.StreetLine1 + " " + item.StreetLine2 + " " + item.City + " " + item.Zip);
                        return item;
                    });
                }
            }, function (res) {

            });
        };

        $scope.SelectTypeAheadItem = function (selectedItem, $model, $label, $event) {
            $scope.searchFirm.Name = selectedItem.EmployerName;
        };

        var init = function () {
            // $scope.courseStautsList = [{ "CourseStatus":'Pending', "CourseStatusId": 1}, { "CourseStatus":'Approved', "CourseStatusId": 2}];
            typeValuesService.RefTablesGetAll('cecoursestatus', sessionStorage.Key).then(function (response) {
                $scope.courseStautsList = response.data.ReferenceEntities;
            }, function (error) {
            });
            typeValuesService.RefTablesGetAll('refproviderlicensestatus', sessionStorage.Key).then(function (response) {
                $scope.licenseStautsList = response.data.ReferenceEntities;
            }, function (error) {
            });
            typeValuesService.RefProviderLicenseTypeGetAll(sessionStorage.Key, true).then(function (response) {
                $scope.licenseTypeList = response.data.ReferenceEntities;
                $scope.licenseTypeList = $scope.licenseTypeList.filter(function (item) {
                    return (item.LicenseTypeId != 1 )                   
                });
            }, function (error) {
            });
            typeValuesService.RefTablesGetAll('cecoursetype', sessionStorage.Key).then(function (response) {
                $scope.courseTypeList = response.data.ReferenceEntities;
            }, function (error) {
            });
            typeValuesService.RefTablesGetAll('cecoursecategory', sessionStorage.Key).then(function (response) {
                $scope.courseCategoryList = response.data.ReferenceEntities;
            }, function (error) {
            });
        }

        init();
    }
})();
