/// <reference path="inlumon-table.ts" />
/// <reference path="../../../scripts/built-in-type-extensions.ts" />

(function() {
    
    angular.module('natApp').filter('iltHeaderName', [function () {
        return function(input) {
            if(typeof(input) === 'object') {
                return input.name;
            } else {
                return input;
            }
        };
    }]);

    angular.module('natApp').filter('iltIsObject', [function () {
        return function (input) {
            return typeof(input) === 'object';
        };
    }]);

    angular.module('natApp').filter('iltPage', [function () {
        return function (data, paginationParams) {
            if (!!data && data.length > paginationParams.pageSize) {
                var skip = (paginationParams.currentPage - 1) * paginationParams.pageSize;
                var take = paginationParams.pageSize;

                return data.skip(skip).take(take);
            } else {
                return data;
            }
        };
    }]);

    angular.module('natApp').controller('InlumonTableController', ['$scope', '$timeout', InlumonTableController]);
    
    /**
     * 
     * @param {InlumonTableControllerScope<T>} $scope 
     * @param {*} $timeout 
     */
    function InlumonTableController($scope, $timeout) {

        $scope.$on('$destroy', function() {
            for(prop in $scope.watches) {
                if ($scope.watches.hasOwnProperty(prop)) {
                    $scope.watches[prop]();
                }
            }
        });

        /**
         * 
         * @param {TableHeader} header 
         * @param {string} headerProperty 
         */
        var generateOptionsForHeader = function (header, headerProperty) {
            /**
             * @type {TableHeaderObj}
             */
            var options = {
                label: null,
                templateUrl: null,
                sizeRatio: 1,
                cellStyle: {}
            };

            if(typeof(header) === 'string') {
                options.label = header;
            } else {
                options.label = (!!header.label) ? header.label : headerProperty;
            }
            options.templateUrl = (!!header.templateUrl) ? header.templateUrl : $scope.dataCellTemplate;
            options.sizeRatio = (!!header.sizeRatio) ? header.sizeRatio : 1;

            return options;
        };

        var updateScope = function () {
            for(injectedProp in $scope.injectedScope) {
                if ($scope.injectedScope.hasOwnProperty(injectedProp)) {
                    (function() {
                        var prop = injectedProp;

                        $scope[prop] = $scope.injectedScope[prop];

                        if (!!$scope.watches[prop]) {
                            $scope.watches[prop]();
                        }
                        $scope.watches[prop] = $scope.$watch(function() { return $scope.injectedScope[prop]; }, function (newVal, oldVal, scope) {
                            $scope[prop] = newVal;
                        });
                    })();
                }
            }
        };

        var updateTableHeaders = function () {
            var index = 0;
            var sizeRatioSum = 0;

            for(headerProperty in $scope.tableHeaders) {
                if($scope.tableHeaders.hasOwnProperty(headerProperty)) {
                    var header = $scope.tableHeaders[headerProperty];
                    var headerOptions = generateOptionsForHeader(header, headerProperty);

                    if (typeof($scope.tableHeaders[headerProperty]) === 'string') { 
                        $scope.tableHeaders[headerProperty] = headerOptions;
                    } else {
                        $scope.tableHeaders[headerProperty].templateUrl = headerOptions.templateUrl;
                        $scope.tableHeaders[headerProperty].sizeRatio = headerOptions.sizeRatio;
                    }

                    sizeRatioSum += headerOptions.sizeRatio;
                    
                    $scope.headerMap[index] = headerProperty;
                    index++;
                }
            }

            for (var prop in $scope.tableHeaders) {
                if ($scope.tableHeaders.hasOwnProperty(prop)) {
                    var h = $scope.tableHeaders[prop];
                    h.cellStyle = angular.copy($scope.cellStyle);

                    h.cellStyle['width'] = ((100/sizeRatioSum) * h.sizeRatio) + '%';
                }
            }
        };

        $scope.init = function () {
            $scope.headerMap = [];

            $scope.watches = {};
            
            if(!!$scope.injectedScope) {
                $scope.watches.injectedScope = $scope.$watch('injectedScope', function () {
                    updateScope();
                }, true);
            }

            if(!!$scope.tableHeaders) {
                $scope.watches.headers = $scope.$watch('tableHeaders', function () {
                    updateTableHeaders();
                }, true);
            }

            if(!$scope.tableData) {
                $scope.tableData = [];
            }

            if (!!$scope.tableData) {
                $scope.watches.dataLength = $scope.$watch('tableData.length', function () {
                    if (!$scope.paginationParams.ignoreDataLength) {
                        $scope.paginationParams.totalRecords = $scope.tableData.length;
                    }
                    $scope.paginationParams.ignoreDataLength = false;
                });
            }

            if(!$scope.sortHeader) {
                $scope.sortHeader = '';
            }
            if(!$scope.sortDirection) {
                $scope.sortDirection = 'asc';
            }

            if(!$scope.paginationParams) {
                $scope.paginationParams = {};
            }

            if(typeof($scope.paginationParams.showPager) === 'undefined') {
                $scope.paginationParams.showPager = false;
            }
            if(!$scope.paginationParams.pageSize) {
                $scope.paginationParams.pageSize = 20;
            }            
            if(!$scope.paginationParams.pageChanged) {
                $scope.paginationParams.pageChanged = function() { };
            }
            if(!$scope.paginationParams.totalRecords) {
                $scope.paginationParams.totalRecords = $scope.tableData.length;
            }
            if(!$scope.paginationParams.setTotalRecords) {
                $scope.paginationParams.setTotalRecords = function (totalRecords) {
                    $timeout(function () { 
                        $scope.paginationParams.totalRecords = totalRecords; 
                        $scope.paginationParams.ignoreDataLength = true; 
                    });
                };
            }

            if(!$scope.paginationParams.currentPage) {
                $scope.paginationParams.currentPage = 1;
            }
            $scope.$watch(function() { return '' + $scope.paginationParams.currentPage + '' + $scope.paginationParams.totalRecords + '' + $scope.paginationParams.pageSize}, function () {
                var pp = $scope.paginationParams;
                if (!pp.shownRecords) {
                    pp.shownRecords = {
                        begin: 0,
                        end: 0
                    };
                }
                var sr = pp.shownRecords;
                sr.begin = (pp.currentPage - 1) * pp.pageSize + 1;
                sr.end = pp.currentPage * pp.pageSize;
                if (sr.end > pp.totalRecords) {
                    sr.end = pp.totalRecords;
                }

                if (pp.totalRecords > pp.pageSize) {
                    pp.showPager = true;
                } else {
                    pp.showPager = false;
                }
            });
            if(!$scope.headerCellTemplate) {
                $scope.headerCellTemplate = 'app/core/directive/inlumon-table/default-header-cell.html';
            }
            if(!$scope.dataCellTemplate) {
                $scope.dataCellTemplate = 'app/core/directive/inlumon-table/default-data-cell.html';
            }
            if(typeof($scope.allowSorting) === 'undefined') {
                $scope.allowSorting = true;
            }            
            if(!$scope.sortHeaderClicked) {
                $scope.sortHeaderClicked = $scope.sortHeaderClickedDefault;
            }            
            if (typeof($scope.showColumnBorders) == 'undefined') {
                $scope.showColumnBorders = true;
            }
            if (typeof($scope.showRowBorders) == 'undefined') {
                $scope.showRowBorders = true;
            }
            if (typeof($scope.centerData) == 'undefined') {
                $scope.centerData = false;
            }

            $scope.cellStyle = {};
            $scope.rowStyle = {};
            if (!$scope.showColumnBorders) {
                $scope.cellStyle['border-left'] = '0px';
            }
            if (!$scope.showRowBorders) {
                $scope.rowStyle['border-bottom'] = '0px';
            }
            if ($scope.centerData) {
                $scope.cellStyle['text-align'] = 'center';
            }
        };

        $scope.sortBy = function (index) {
            var headerProperty = $scope.headerMap[index];

            if($scope.sortHeader == headerProperty) {
                $scope.sortDirection = ($scope.sortDirection == 'asc') ? 'desc': 'asc';
            } else {
                $scope.sortDirection = 'asc';
            }
            
            $scope.sortHeader = headerProperty;
            $timeout(function() {
                $scope.sortHeaderClicked({$index: index, $header: $scope.sortHeader, $headerProperty: headerProperty, $tableData: $scope.tableData, $sortDirection: $scope.sortDirection});
            },1)
        };

        $scope.sortHeaderClickedDefault = function (params) {
            var dataLength = params.$tableData.length;

            /** @type {any[]} */
            var sortedArr = angular.copy(params.$tableData);
            if(params.$sortDirection == 'asc') {
                sortedArr = params.$tableData.orderBy(params.$header);
            } else if (params.$sortDirection == 'desc') {
                sortedArr = params.$tableData.orderByDescending(params.$header);
            }

            Array.prototype.splice.apply(params.$tableData, [0, dataLength].concat(sortedArr));
        };
    };
})();