/// <reference path="../../../scripts/built-in-type-extensions.ts" />
/// <reference path="query-reporting-doc.ts" />

(function() {
    'use strict'
    
    angular.module('app.report')
        .filter('functionCategory', function () {
            return function (array, expression) {
                return array.filter(function (i) {
                    return !expression || i.Category == expression;
                });
            };
        })
        .controller('QueryReportingExpressionEditorController', ['$scope', '$uibModalInstance', '$q', '$timeout', 'QueryReportingService', 'parameters', QueryReportingExpressionEditorController]);

    function QueryReportingExpressionEditorController($scope, $uibModalInstance, $q, $timeout, QueryReportingService, parameters) {
        var _key = '';
        var isClosed = false;

        $scope.init = function () {
            _key = sessionStorage.Key;

            $scope.expressionList = parameters.expressionList;
            $scope.processedTables = parameters.processedTables;
            $scope.currentQuery = parameters.currentQuery;
            $scope.columnList = [];
            $scope.mode = parameters.mode;
            $scope.dateOptions = {
                maxDate: new Date(),
                minDate: new Date(1800,1,1),
                startingDay: 1
            };

            //Setup the column expression scope variables
            var setFunction = setupColumnExpression();

            //Construct the column list
            setupColumnList();

            //Now get the available functions for the operands
            $q.all([getAvailableFunctions(setFunction)]).then(function() {
                $scope.operationInProgress(false);
            }, function () {
                $scope.operationInProgress(false);
            });
        };

        var getAvailableFunctions = function (setFunction) {
            var deferred = $q.defer();
            var expression = $scope.columnExpression.Expression;
            var operands = ['LeftOperand', 'RightOperand'];
            var categoryProps = ['leftSelectedCategory', 'rightSelectedCategory'];
            var functionsProps = ['leftAvailableFunctions', 'rightAvailableFunctions'];

            var defaultFunction = {
                Name: '[Loading functions...]',
                Parameters: []
            };
            var emptyFunction = {
                Name: '[No functions found]',
                Parameters: []
            };
            var failedFunction = {
                Name: '[Failed to retrieve functions]',
                Parameters: []
            };

            operands.forEach(function (operand, i) {
                var functionsProp = functionsProps[i];

                if(setFunction) {
                    expression[operand] = angular.copy(defaultFunction);
                }
    
                if (expression[operand] && !setFunction) {
                    expression[functionsProp] = [expression[operand], defaultFunction];
                } else {
                    expression[functionsProp] = [expression[operand]];
                }
            });

            try
            {
                QueryReportingService.getAvailableFunctions(_key)
                .then(function (data) {
                    if(isClosed) { return; }

                    operands.forEach(function (operand, i) {
                        var functionsProp = functionsProps[i];
                        var categoryProp = categoryProps[i];

                        if(data.Status) {
                            expression[functionsProp] = angular.copy(data.Functions.orderBy('Category,Name'));

                            if(data.Functions.length > 0 && setFunction) {
                                expression[operand] = expression[functionsProp][0];
                            } else if (data.Functions.length > 0 && !setFunction) {
                                var selectedFunc = expression[functionsProp].findIndex(function (f) { return f.Category == expression[operand].Category && f.Name == expression[operand].Name; });

                                if(selectedFunc >= 0) {
                                    var func = expression[functionsProp][selectedFunc];
                                
                                    var i;  //Refresh the tooltip info for this function
                                    for (i = 0; i < expression[operand].Parameters.length; i++) {
                                        expression[operand].Parameters[i].ToolTip = func.Parameters[i].ToolTip;
                                        expression[operand].Parameters[i].ToolTipTable = func.Parameters[i].ToolTipTable;
                                        expression[operand].Parameters[i].ToolTipTitle = func.Parameters[i].ToolTipTitle;
                                    }
                                
                                    expression[functionsProp][selectedFunc] = expression[operand]; //Keep the original reference for the operand
                                } else {
                                    expression[operand] = expression[functionsProp][0];
                                }
                            } else if (data.Functions.length == 0) {
                                expression[operand] = emptyFunction;
                                expression[functionsProp] = [expression[operand]];
                            }
                        } else {
                            $scope.showError(data.Message);
    
                            expression[functionsProp] = [expression[operand]];
                        }
                    });

                    deferred.resolve(data);
                }, function (error) {
                    if(isClosed) { return; }
                    $scope.showError(error.Message);

                    operands.forEach(function (operand, i) { expression[functionsProps[i]] = [expression[operand]]; });

                    deferred.reject(error);
                });
            } catch (ex) {
                if (ex != null) { $scope.showError(ex.message); }
            }

            return deferred.promise;
        };

        var setupColumnExpression = function () {
            var setFunction = false;

            if($scope.mode == 'add' || $scope.mode == 'edit') {
                $scope.originalExpression = parameters.selectedExpression;
                $scope.columnExpression = angular.copy(parameters.selectedExpression);
            } else if ($scope.mode == 'view') {
                $scope.originalExpression = angular.copy(parameters.selectedExpression);
                $scope.columnExpression = angular.copy(parameters.selectedExpression);
            } else {
                $scope.columnExpression = {
                    TableName: 'Expression',
                    TableDisplayName: 'Expression',
                    TableAlias: 'Expr',
                    FieldName: 'Expression',
                    FieldDisplayName: 'Expression',
                    FieldDataType: '',
                    IsEncrypted: false,
                    IsExpressionColumn: true,
                    Expression: {
                        LeftOperand: null,
                        Operator: '',                        //If '' then no right expression
                        RightOperand: null,

                        leftSelectedCategory: '',
                        leftAvailableCategories: [],
                        leftAvailableFunctions: {},

                        rightSelectedCategory: '',
                        rightAvailableCategories: [],
                        rightAvailableFunctions: {}
                    },
                    
                    //Present on column list and all query columns
                    isSelected: false,      
                };

                setFunction = true;
            }

            return setFunction;
        };

        var setupColumnList = function () {
            $scope.columnList = [];

            if($scope.currentQuery.From) {
                $scope.currentQuery.From.table.ColumnList.forEach(function (c) {
                    var rel = $scope.currentQuery.From;
                    var column = angular.copy(c);
    
                    column.TableAlias = rel.TableAlias;
                    column.TableName = rel.TableName;
    
                    $scope.columnList.push(column);
                });
                    
                $scope.currentQuery.Join.forEach(function (rel) {
                    if(rel.table) {
                        rel.table.ColumnList.forEach(function (c) {
                            var column = angular.copy(c);
    
                            column.TableAlias = rel.RelationTableAlias;
                            column.TableName = rel.RelationTableName;
    
                            $scope.columnList.push(column);
                        });
                    }
                });
            }
        };

        var expressionNameExists = function (name) {
            return $scope.expressionList.some(function (e) { return e.FieldName.toLowerCase() == name.toLowerCase() && !e.isSelected; });
        };

        var getExpressionDependencies = function (col) {
            var expr = col.Expression;
            var dependencies = [];

            if(expr.LeftOperand) {
                expr.LeftOperand.Parameters.forEach(function(p) {
                    if(p.Value.DataType == 'expression' && !dependencies.includes(p.Value.Column.FieldName)) {
                        dependencies.push(p.Value.Column.FieldName);
                        dependencies = dependencies.concat(getExpressionDependencies(p.Value.Column));
                    }
                });
            }

            if(expr.Operator && expr.RightOperand) {
                expr.RightOperand.Parameters.forEach(function(p) {
                    if(p.Value.DataType == 'expression' && !dependencies.includes(p.Value.Column.FieldName)) {
                        dependencies.push(p.Value.Column.FieldName);
                        dependencies = dependencies.concat(getExpressionDependencies(p.Value.Column));
                    }
                });
            }

            return dependencies.distinct();
        };

        var cleanExpressionColumn = function () {
            var ce = $scope.columnExpression;

            isClosed = true;

            delete ce.isSelected;
            delete ce.Expression.leftAvailableFunctions;
            delete ce.Expression.rightAvailableFunctions;

            ce.Expression.LeftOperand.Parameters.forEach(function(p) {
                delete p.ToolTip;
                delete p.ToolTipTable;
                delete p.ToolTipTitle;
            });
        };

        var parseBool = function (val) {
            if(typeof(val) === 'boolean') {
                return val;
            } else {
                return (val == 'True' || val == 'true');
            }
        };

        var isBooleanOperator = function (operator) {
            switch(operator) {
                case 'equal to':
                case 'less than':
                case 'less than or equal to':
                case 'greater than':
                case 'greater than or equal to':
                    return true;
                default:
                    return false;
                    break;
            }
        };

        $scope.validateExpression = function () {
            if(isClosed) { return true; }

            //All parameters for each operand must be filled in
            var expr = $scope.columnExpression.Expression;
            var operands = ['LeftOperand', 'RightOperand']
            var operator = $scope.columnExpression.Expression.Operator;
            var result = true;

            operands.forEach(function(o) {
                var operand = expr[o];
                
                //Make sure there's a need to look at the RightOperand
                if (o == 'RightOperand' && (!operator || operator.length == 0)) { return; }

                result = result && operand.Parameters.every(function (p) {
                    if(p.IsHidden) { p.Value = {}; return true; }

                    if(!p.Value) { return false; }

                    if(p.Value.IsColumn) {
                        return typeof(p.Value.Column) != 'undefined' && typeof(p.Value.Column) != 'string';
                    } else {
                        return (typeof(p.Value.Value) == 'boolean') || (!!p.Value.Value);
                    }
                });
            });

            return result;
        };

        $scope.boolValueChanged = function (value) {
            if(value.Value == 'True') {
                value.Value = true;
            } else if (value.Value == 'False') {
                value.Value = false;
            }
        }

        $scope.showError = function (errorMessage) {
            $scope.showStatusMsg('-', errorMessage);
        };

        $scope.hideError = function () {
            $scope.hideStatusMsg();
        };

        $scope.initParamValue = function (param) {
            if(!param.Value) {
                param.Value = {}; 
                param.Value.Value = ((param.DataType == 'boolean') ? false : '');
                param.Value.DataType = ((param.DataType == 'any') ? 'column' : param.DataType);
                param.Value.IsColumn = (param.Value.DataType == 'column' || param.Value.DataType == 'expression');
            } else {
                param.Value.Value = ((param.DataType == 'boolean') ? parseBool(param.Value.Value) : param.Value.Value);
            }

            if (!param.Value.IsColumn && !param.Value.Value) {
                param.Value.Value = param.DefaultValue;
            }
        };

        $scope.parameterTypeChanged = function (param) {
            param.Value.Value = undefined; 
            param.Value.Column = undefined; 
            param.Value.IsColumn = (param.Value.DataType == 'column' || param.Value.DataType == 'expression');

            if(!param.Value.IsColumn && !param.Value.Value) {
                param.Value.Value = param.DefaultValue;
            }
        };

        $scope.addExpressionColumn = function (type) {
            cleanExpressionColumn();

            $scope.hideError();

            $uibModalInstance.close({ columnType: type, column: angular.copy($scope.originalExpression) });
        };

        $scope.editExpressionColumn = function () {
            cleanExpressionColumn();

            var ce = $scope.columnExpression;

            if(isBooleanOperator(ce.Expression.Operator)) {
                ce.FieldDataType = 'boolean';
            } else {
                ce.FieldDataType = ce.Expression.LeftOperand.DataType;
            }

            $scope.hideError();
            
            var dependencies = getExpressionDependencies(ce);
            if(dependencies.includes(ce.FieldName)) {
                $scope.showError('Circular dependency detected: ' + dependencies.toString());
                return;
            }

            if(expressionNameExists(ce.FieldName)){
                $scope.showError('Cannot have two expressions with the same name.  Please change the name and try again.');
                return;
            }

            ce.FieldDisplayName = ce.FieldName;

            $uibModalInstance.close({ columnType: '', column: angular.copy(ce) });
        };

        $scope.createExpressionColumn = function () {
            cleanExpressionColumn();

            var ce = $scope.columnExpression;
            
            if(isBooleanOperator(ce.Expression.Operator)) {
                ce.FieldDataType = 'boolean';
            } else {
                ce.FieldDataType = ce.Expression.LeftOperand.DataType;
            }


            $scope.hideError();

            var dependencies = getExpressionDependencies(ce);
            if(dependencies.includes(ce.FieldName)) {
                $scope.showError('Circular dependency detected: ' + dependencies.toString());
                return;
            }

            if(expressionNameExists(ce.FieldName)){
                $scope.showError('Cannot have two expressions with the same name.  Please change the name and try again.');
                return;
            }

            ce.FieldDisplayName = ce.FieldName;

            $uibModalInstance.close({ columnType: '', column: angular.copy(ce) });
        };

        $scope.cancelDialog = function () {
            $scope.hideError();

            $uibModalInstance.dismiss();
        };
    };
})();