(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('licenseEndorsementValidation', [LicenseEndorsementValidationDirective]);

    function LicenseEndorsementValidationDirective() {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function () {
                    return true;
                };
            }
        };
    }
})();