(function () {
    'use strict'
    angular.module('app.core')
        .directive('natRequired', natRequiredDirective);

    natRequiredDirective.$inject = ['$timeout', '$parse'];

    function natRequiredDirective($timeout, $parse) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                // var isValidationRequired = (attrs.validateReqField == undefined || attrs.validateReqField == null || attrs.validateReqField == true);
                // var currentValue = '';
                // scope.$watch(attrs.ngModel, function (newValue, oldValue) {
                //     currentValue = newValue;
                //     validateRequiredField(newValue, oldValue, false);
                // });
                // if (attrs.validateReqField != undefined && attrs.validateReqField != null)
                //     scope.$watch(attrs.validateReqField, function (newValue, oldValue) {
                //         var newReqValue = (newValue == undefined || newValue == null || newValue == true);
                //         isValidationRequired = newReqValue;
                //         validateRequiredField(newReqValue, oldValue, true);
                //     });

                // function validateRequiredField(newValue, oldValue, isValidationChanged) {
                //     var el = element;
                //     if (!ctrl.$$parentForm.errorMessages) {
                //         ctrl.$$parentForm.errorMessages = [];
                //     }
                //     var errorObj = {
                //         propName: (!!attrs.validationName ? attrs.validationName : ("natrequired-" + ctrl.$name)),
                //         errorMessage: attrs.natRequired
                //     };

                //     angular.forEach(ctrl.$$parentForm.errorMessages, function (error, indexOfMsg) {
                //         if (errorObj.propName == error.propName) {
                //             ctrl.$$parentForm.errorMessages.splice(indexOfMsg, 1);
                //         }
                //     });
                //     if (isValidationChanged) {
                //         if (!!attrs.ischeckbox && (currentValue == -1 || currentValue == '-1' || currentValue == undefined || currentValue == null || currentValue === "" || currentValue === false) && (newValue)) {
                //             ctrl.$$parentForm.errorMessages.push(errorObj);
                //             ctrl.$setValidity('natrequired', false);
                //         } else if ((currentValue == -1 || currentValue == '-1' || currentValue == undefined || currentValue == null || currentValue === "") && (newValue)) {
                //             ctrl.$$parentForm.errorMessages.push(errorObj);
                //             ctrl.$setValidity('natrequired', false);
                //         } else {
                //             ctrl.$setValidity('natrequired', true);
                //         }
                //     } else {
                //         if (!!attrs.ischeckbox && (newValue == -1 || newValue == '-1' || newValue == undefined || newValue == null || newValue === "" || newValue === false) && (isValidationRequired)) {
                //             ctrl.$$parentForm.errorMessages.push(errorObj);
                //             ctrl.$setValidity('natrequired', false);
                //         } else if ((newValue == -1 || newValue == '-1' || newValue == undefined || newValue == null || newValue === "") && (isValidationRequired)) {
                //             ctrl.$$parentForm.errorMessages.push(errorObj);
                //             ctrl.$setValidity('natrequired', false);
                //         } else {
                //             ctrl.$setValidity('natrequired', true);
                //         }
                //     }
                // }
                if (!!attrs.validateReqField) {
                    scope.$watch(function () { return $parse(attrs.validateReqField)(scope); }, function () {
                        ctrl.$validate();
                    });
                }

                scope.$watch(attrs.ngModel, function () {
                    ctrl.$validate();
                });

                ctrl.$validators.natrequired = function (modelValue, viewValue) {
                    var isValidationRequired = true;
                    if (!!attrs.validateReqField) {
                        isValidationRequired = $parse(attrs.validateReqField)(scope);
                    }

                    if (!ctrl.$$parentForm.errorMessages) {
                        ctrl.$$parentForm.errorMessages = [];
                    }
                    var errorObj = {
                        propName: (!!attrs.validationName ? attrs.validationName : ("natrequired-" + ctrl.$name)),
                        errorMessage: attrs.natRequired
                    };

                    angular.forEach(ctrl.$$parentForm.errorMessages, function (error, indexOfMsg) {
                        if (errorObj.propName == error.propName) {
                            ctrl.$$parentForm.errorMessages.splice(indexOfMsg, 1);
                        }
                    });

                    if (!!attrs.ischeckbox && (parseInt(modelValue) == -1 || modelValue == undefined || modelValue == null || modelValue === "" || modelValue === false) && (isValidationRequired)) {
                        ctrl.$$parentForm.errorMessages.push(errorObj);
                        return false;
                    } else if ((parseInt(modelValue) == -1 || modelValue == undefined || modelValue == null || modelValue === "") && (isValidationRequired)) {
                        ctrl.$$parentForm.errorMessages.push(errorObj);
                        return false;
                    } else {
                        return true;
                    }
                };

                element.on('focus', function (event) {
                    $timeout(function () {
                        scope.$apply(function () {
                            ctrl.isFocused = true;
                        });
                    });
                });

                element.on('blur', function (event) {
                    $timeout(function () {
                        scope.$apply(function () {
                            ctrl.isFocused = false;
                        });
                    });
                });

                element.on('mouseenter', function (event) {
                    scope.$apply(function () {
                        ctrl.isHover = true;
                    });
                });

                element.on('mouseleave', function (event) {
                    scope.$apply(function () {
                        ctrl.isHover = false;
                    });
                });
            }
        }
    }

    angular.module('app.core')
    .directive('natRequiredHtml', natRequiredHtmlDirective);

    natRequiredHtmlDirective.$inject = ['$timeout', '$parse'];

    function natRequiredHtmlDirective($timeout, $parse) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, model) {
                if (!!attrs.validateReqField) {
                    scope.$watch(function () { return $parse(attrs.validateReqField)(scope); }, function () {
                        model.$validate();
                    });
                }

                scope.$watch(attrs.ngModel, function () {
                    model.$validate();
                });

                model.$validators.natrequiredhtml = function (modelValue, viewValue) {
                    var isValidationRequired = true;
                    if (!!attrs.validateReqField) {
                        isValidationRequired = $parse(attrs.validateReqField)(scope);
                    }

                    if (!model.$$parentForm.errorMessages) {
                        model.$$parentForm.errorMessages = [];
                    }
                    var errorObj = {
                        propName: (!!attrs.validationName ? attrs.validationName : ("natrequiredhtml-" + model.$name)),
                        errorMessage: attrs.natRequiredHtml
                    };

                    angular.forEach(model.$$parentForm.errorMessages, function (error, indexOfMsg) {
                        if (errorObj.propName == error.propName) {
                            model.$$parentForm.errorMessages.splice(indexOfMsg, 1);
                        }
                    });

                    if (!!isValidationRequired) {
                        if (!modelValue) {
                            model.$$parentForm.errorMessages.push(errorObj);
                            return false;
                        } else {
                            var content = angular.element(modelValue).text().trim();
                            if (!content) {
                                model.$$parentForm.errorMessages.push(errorObj);
                                return false;
                            } else {
                                return true;
                            }                            
                        }
                    } else {
                        return true;
                    }
                };

                element.on('focus', function (event) {
                    $timeout(function () {
                        scope.$apply(function () {
                            model.isFocused = true;
                        });
                    });
                });

                element.on('blur', function (event) {
                    $timeout(function () {
                        scope.$apply(function () {
                            model.isFocused = false;
                        });
                    });
                });

                element.on('mouseenter', function (event) {
                    scope.$apply(function () {
                        model.isHover = true;
                    });
                });

                element.on('mouseleave', function (event) {
                    scope.$apply(function () {
                        model.isHover = false;
                    });
                });
            }
        };
    }
})();