/// <reference path="query-reporting-extensions-doc.ts" />
/// <reference path="query-reporting-doc.ts" />

(function() {
    'use strict'

    angular.module('app.report')
    .controller('QueryReportingJoinSelectorController', ['$scope', '$uibModalInstance', QueryReportingJoinSelectorController]);

    function QueryReportingJoinSelectorController($scope, $uibModalInstance) {
        $scope.init = function () {
            
        };

        $scope.selectRelationship = function (type) {
            $uibModalInstance.close({relationshipType: type});
        };

        $scope.cancelDialog = function() {
            $uibModalInstance.dismiss();
        };
    }
})();