(function () {

    'use strict';
    angular.module('app.backofficeAdministor')
        .controller("ContentController", ContentController);
    ContentController.$inject = ['$scope', 'contentFactory', 'utilityService','$timeout', 'Messages'];
    function ContentController($scope, contentFactory, utilityService, $timeout, Messages) {

        $scope.currentPage = 1;
        $scope.data = {};
        $scope.userPerPages = 20;
        $scope.show = false;
        var key = sessionStorage.Key;

        // Editor options.
        $scope.options = {
            language: 'en',
            allowedContent: true,
            entities: false,
            disableNativeSpellChecker: false,
            scayt_autoStartup: true
        };

        //uiColor: '#F7B42C',
        //height: 300,
        //toolbarCanCollapse: true

        // Called when the editor is completely ready.
        $scope.onReady = function () {
            // ...
        };



        function GetAllPage() {
            contentFactory.getAllPages(key)
                .then(function (response) {
                    AuditVisitInfoSave();
                    if (response.StatusCode == '00') {
                        $scope.PageList = response.PageModule;
                    } else {
                        //alert(Messages.efafcdf);
                    }
                })
                .catch(function (response) {
                    utilityService.GetLogData(response);
                    HideLoader();
                    ShowResult(response.data);
                });
        }
        $scope.datePicker = (function () {
            var method = {};
            method.instances = [];

            method.open = function ($event, instance) {
                $event.preventDefault();
                $event.stopPropagation();

                method.instances[instance] = true;
            };

            method.options = {
                'show-weeks': false,
                startingDay: 0
            };

            var formats = ['MM/dd/yyyy', 'dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
            method.format = formats[0];

            return method;
        }());

        $scope.$on("FillContentGrid", function(event, data){
            FillContentGrid();
        });

        function FillContentGrid() {
            ShowLoader();
            var id = $scope.data.ddlContentType == undefined ? "" : $scope.data.ddlContentType;
            contentFactory.fillGrid(key, id)
                .then(function (response) {
                    AuditVisitInfoSave();
                    if (response.StatusCode == '00') {
                        $scope.ContentList = response.ContentItemLk;
                    } else {
                       // alert(Messages.efafcdf);
                    }
                    HideLoader();
                })
                .catch(function (response) {
                    utilityService.GetLogData(response);
                    HideLoader();
                    ShowResult(response.data);
                });

        }

        GetAllPage();

        FillContentGrid();

        $scope.showAll = function(){
            $scope.show=!$scope.show;
            ShowLoader('Please wait...');
            if ($scope.show){
                $timeout(function(){
                   HideLoader();
                });
                $scope.userPerPages =  $scope.ContentList.length;
            }
            if (!$scope.show){
                HideLoader();
                $scope.userPerPages =  10;
            }
        };
        $scope.Search = function SearchGrid() {

            ShowLoader();

            contentFactory.fillGrid(key, $scope.data.ddlContentType)
                .then(function (response) {
                    AuditVisitInfoSave();
                    if (response.StatusCode == '00') {
                        $scope.ContentList = response.ContentItemLk;
                    } else {
                       // alert(Messages.efafcdf);
                    }
                    HideLoader();
                })
                .catch(function (response) {

                    utilityService.GetLogData(response);
                    HideLoader();
                    ShowResult(response.data);
                });

        };

        $scope.GetAllTabsSubModule = function () {

            contentFactory.getAllTabsSubModule(key, $scope.data.pageList)
                .then(function (response) {
                    AuditVisitInfoSave();
                    if (response.StatusCode == '00') {
                        $scope.PageModuleTabsSubModuleList = response.PageModuleTabSubModule;
                    } else {
                        //alert(Messages.efafcdf);
                    }

                    HideLoader();

                })
                .catch(function (response) {
                    utilityService.GetLogData(response);
                    HideLoader();
                    ShowResult(response.data);
                });
        };

        $scope.GetAllTabSection = function () {

            contentFactory.getAllTabSection(key, $scope.data.pageModuleTabsSubModule)

                .then(function (response) {
                    AuditVisitInfoSave();
                    if (response.StatusCode == '00') {
                        $scope.PageTabSectionList = response.PageTabSection;
                    } else {
                       // alert(Messages.efafcdf);
                    }

                    HideLoader();
                })
                .catch(function (response) {
                    utilityService.GetLogData(response);
                    HideLoader();
                    ShowResult(response.data);
                });
        };

        $scope.GetAllContentType = function () {

            contentFactory.getAllContentType(key, $scope.data.pageList, $scope.data.pageTabSection, $scope.data.pageTabSection)

                .then(function (response) {
                    AuditVisitInfoSave();
                    if (response.StatusCode == '00') {
                        $scope.ContentLkToPageTabSectionList = response.ContentLkToPageTabSection;
                    } else {
                        //alert(Messages.efafcdf);
                    }

                    HideLoader();
                })
                .catch(function (response) {
                    utilityService.GetLogData(response);
                    HideLoader();
                    ShowResult(response.data);
                })

        };

        $scope.UpdateContentById = function (m) {
            ShowLoader();
            contentFactory.updateContentById(key, m)
                .then(function (response) {
                    FillContentGrid();
                    //AuditVisitInfoSave();
                    showStatusMessage(response.Message,'success');
                    HideLoader();
                    //location.reload();

                })
                .catch(function (response) {
                    utilityService.GetLogData(response);
                    HideLoader();
                    ShowResult(response.data);
                });

        };

        function AuditVisitInfoSave() {
            var data = {
                "PageName": "ContentManagement",
                "SessionId": sessionStorage.Key,
                "IndividualId": sessionStorage.IndividualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        }
    }
})();

