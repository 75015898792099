(function () {
    'use strict'

    angular.module('app.backofficeAdministor')
    .controller('DocumentTemplatesController', ['$scope', '$rootScope', '$q', 'DocumentTemplatesService', 'utilityService', 'Messages', DocumentTemplatesController]);

    function DocumentTemplatesController($scope, $rootScope, $q, DocumentTemplatesService, utilityService, Messages) {
        var _key = sessionStorage.Key;
        var _childName = '';

        //Setup a watch on the editorIsOpen to ensure that if the editor closes that the 
        //content reverts back to the original content
        var removeEditorOpenWatch = $scope.$watch('current.documentTemplate', function (newVal) {
            if (!newVal) {
                //Destroy the CKEDITOR instance in the popup before it gets destroyed
                //(This removes an annoying error when the editor is not destroyed properly)
                if (!!CKEDITOR.instances.templateEditor) {
                    CKEDITOR.instances.templateEditor.destroy();
                }
            }
        });

        var removeUserParamWatch = $scope.$watch('current.documentTemplate.userInputParameters', function (newVal) {
            if (!!newVal) {
                $scope.current.documentTemplate.JSONInputParameters = JSON.stringify($scope.current.documentTemplate.userInputParameters);
            }
        }, true);

        //Cleanup when the scope is destroyed
        $scope.$on('$destroy', function () {
            removeEditorOpenWatch();
            removeUserParamWatch();
        });
        
        var getNewDocumentTemplate = function () {
            return {
                DocumentTemplateId: 0,
                DocumentTemplateCode: '',
                DocumentTemplateName: '',
                DocumentTemplateHtml: '',
                JSONInputParameters: '[]',
                EntityType: 'I',
                ForLicenseTypeIds: '',
                NotLicenseTypeIds: '',
                SearchLicenseNumber: false,
                SearchApplicationId: false,
                SearchIndividualLicenseId: false,
                PageOrientation: 'Portrait',
                PageSize: 'Letter',
                AutoFitX: 'None',
                AutoFitY: 'None',
                DisplayInList: true,
                
                IsActive: 1,
                IsDeleted: 0,

                BoardInfoId: null,

                userInputParameters: []
            };
        };

        $scope.getDocumentTemplateInfo = function (entityType) {
              var deferred = $q.defer();
              
              try {
                  DocumentTemplatesService.getDoumentTemplateInfo(sessionStorage.Key, entityType)
                  .then(function(data) {
                      if (data.Status) {
                          $scope.docGenerationInfo = data;
                      }
                      deferred.resolve(data);
                  }, function(err) {
                      deferred.reject(err);
                  });
              } catch (ex) {
                  deferred.reject(ex);
              }
              
              return deferred.promise;
        };

        $scope.getDocumentTemplates = function (documentTemplateCode, boardInfoId) {
            var deferred = $q.defer();
            
            try {
                var search = {
                    DocumentTemplateCode: documentTemplateCode,
                    BoardInfoId: boardInfoId
                };

                DocumentTemplatesService.getDocumentTemplates(sessionStorage.Key, search)
                .then(function(data) {
                    if (data.Status) {
                        $scope.documentTemplates = data.DocumentTemplates;
                    } else {
                        $scope.documentTemplates = [];
                        $scope.showStatusMsg('-', data.Message);
                    }

                    $scope.documentTemplates.forEach(function (template) {
                        if (!!template.JSONInputParameters) {
                            template.userInputParameters = JSON.parse(template.JSONInputParameters);
                        } else {
                            template.userInputParameters = [];
                        }
                    });
                    deferred.resolve(data);
                }, function(err) {
                    $scope.showStatusMsg('-', err);
                    deferred.reject(err);
                });
            } catch (ex) {
                $scope.showStatusMsg('-', ex);
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.saveDocumentTemplate = function (template) {
            var deferred = $q.defer();
            
            try {
                DocumentTemplatesService.saveDocumentTemplate(sessionStorage.Key, template)
                .then(function(data) {
                    if (!data.Status) {
                        $scope.showStatusMsg('-', data.Message);
                    }
                    deferred.resolve(data);
                }, function(err) {
                    $scope.showStatusMsg('-', err);
                    deferred.reject(err);
                });
            } catch (ex) {
                $scope.showStatusMsg('-', ex);
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        $scope.addEditTemplateClicked = function (template) {
            if (!template) {
                template = getNewDocumentTemplate();
            }

            $scope.current.documentTemplate = angular.copy(template);
        };

        $scope.saveTemplateClicked = function () {
            $scope.operationPending(true, 'Saving...');
            $scope.saveDocumentTemplate($scope.current.documentTemplate)
            .then(function () {
                $scope.cancelTemplateClicked();
                
                $scope.operationPending(true, 'Loading...');

                $scope.getDocumentTemplates(null, null)
                .then(function() {
                    $scope.operationPending(false);
                }, function() {
                    $scope.operationPending(false);
                });
            }, function() {
                $scope.operationPending(false);
            });
        };

        $scope.cancelTemplateClicked = function () {
            $scope.current.documentTemplate = null;
        };

        $scope.deleteTemplateClicked = function (template) {
            if (confirm(Messages.ebaedfa)) {
                template.IsActive = false;
                template.IsDeleted = true;

                $scope.operationPending(true, 'Deleting...');
                $scope.saveDocumentTemplate(template)
                .then(function() {
                    $scope.operationPending(true, 'Loading...');

                    $scope.getDocumentTemplates(null, null)
                    .then(function() {
                        $scope.operationPending(false);
                    }, function() {
                        $scope.operationPending(false);
                    });
                }, function () {
                    $scope.operationPending(false);
                });
            }
        };

        $scope.addUserInputParameter = function () {
            $scope.current.documentTemplate.userInputParameters.push({});
        };

        $scope.removeUserInputParameter = function (param) {
            $scope.current.documentTemplate.userInputParameters.conditionalSplice('item==this', param);
        };

        $scope.init = function () {
            $scope.current = {
                documentTemplate: null
            };

            $scope.tableHeaders = {
                DocumentTemplateCode: {
                    label: 'Code'
                },
                DocumentTemplateName: {
                    label: 'Name'
                },
                ForLicenseTypeIds: {
                    label: 'Include Licenses'
                },
                NotLicenseTypeIds: {
                    label: 'Exclude Licenses'
                },
                SearchLicenseNumber: {
                    label: 'Get License #',
                    sizeRatio: 0.7
                },
                SearchApplicationId: {
                    label: 'Get Application',
                    sizeRatio: 0.7
                },
                SearchIndividualLicenseId: {
                    label: 'Get License ID',
                    sizeRatio: 0.7
                },
                EntityType: {
                    label: 'Entity Type',
                    sizeRatio: 0.5
                },
                Actions: {
                    label: 'Actions',
                    templateUrl: 'documentTemplatesActions.html',
                    sizeRatio: 0.5
                }
            };

            $scope.tableEvents = {
                addEditTemplateClicked: $scope.addEditTemplateClicked,
                deleteTemplateClicked: $scope.deleteTemplateClicked
            };

            $scope.getDocumentTemplates(null, null);

            $scope.ckOptions = {
                language: 'en',
                allowedContent: true,
                fullPage: true,
                entities: false,
                disableNativeSpellChecker: true,
                scayt_autoStartup: false
                // toolbarGroups: [
                //     { name: 'document',    groups: [ 'mode', 'document', 'doctools' ] },
                //     { name: 'clipboard',   groups: [ 'clipboard', 'undo' ] },
                //     { name: 'editing',     groups: [ 'find', 'selection', 'spellchecker' ] },
                //     { name: 'forms' },
                //     '/',
                //     { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                //     { name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
                //     { name: 'links' },
                //     { name: 'insert' },
                //     '/',
                //     { name: 'styles' },
                //     { name: 'colors' },
                //     { name: 'tools' },
                //     { name: 'others' },
                //     { name: 'about' }
                // ],
                // toolbar: [
                //     ['Source'],
                //     ['RemoveFormat', 'CopyFormatting', '-', 'TextColor', 'BGColor', '/'],
                //     ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript', '-', 'Link', 'Unlink', '/'],
                //     ['Styles', 'Format', 'Font', 'FontSize']
                // ]
            };
        };
    }
})();