(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
    .factory('providerJursidictionsService', providerJursidictionsService)
    providerJursidictionsService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']
    function providerJursidictionsService($http, $q, WebApiUrl, AppConfig) {

        var _getProviderPreviousLicense = function (Key, IndividualId, ApplicationId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Provider/GetProviderPreviousLicense/' + Key + '?ProviderId=' + IndividualId + '&ApplicationId='+ApplicationId)
        .then(function(data){ data = data.data;
            deferred.resolve(data);
        }, function(error) { error = error.data;
            deferred.reject(error);
        });
            return deferred.promise;
        };

        var _saveProviderPreviousLicense = function (Key, data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/SaveProviderPreviousLicense/' + Key + '?Source=' + AppConfig.Source, data)
        .then(function(data){ data = data.data;
            deferred.resolve(data);
        }, function(error) { error = error.data;
            deferred.reject(error);
        });
            return deferred.promise;
        };

        return {
            getProviderPreviousLicense: _getProviderPreviousLicense,
            saveProviderPreviousLicense: _saveProviderPreviousLicense
        }
    }
})();