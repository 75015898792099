(function () {
    'use strict'

    angular.module('app.backofficeEntity')
        .factory('cashieringService', cashieringService)

    cashieringService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function cashieringService($http, $q, WebApiUrl, AppConfig) {

        return {
            invoiceDetailSave: _invoiceDetailSave,
            processRefund: _processRefund,
            processPaymentTransfer: _processPaymentTransfer,
            processBadCheck: _processBadCheck,
            invoiceMasterSave: _invoiceMasterSave,
            getInvoiceMasterByIndividualIdAndInvoiceStatus: _getInvoiceMasterByIndividualIdAndInvoiceStatus,
            getInvoiceMaster: _getInvoiceMaster,
            getInvoiceDetail: _getInvoiceDetail,
            getRevFeeCollect: _getRevFeeCollect,
            individualByIndividualId: _individualByIndividualId,
            individualProcessPayment: _individualProcessPayment,
            individualSave: _individualSave,
            saveProvider: _saveProvider,
            getOpenRevDrawer: _getOpenRevDrawer,
            getRevDrawer: _getRevDrawer,
            openRevDrawer: _openRevDrawer,
            reOpenRevDrawer: _reOpenRevDrawer,
            closeRevDrawer: _closeRevDrawer,
            entityPayorGetByEntityId: _entityPayorGetByEntityId,
            entityPayorSave: _entityPayorSave,
            getEntityPayorBeneficiary: _getEntityPayorBeneficiary,
            individualPaidInvoiceByIndividualId: _individualPaidInvoiceByIndividualId,
            individualFinanceByIndividualId: _individualFinanceByIndividualId,
            getApplicationInfoByApplicationId: _getApplicationInfoByApplicationId,
            generateApplicationId: _generateApplicationId,
            saveIndividualApplication: _saveIndividualApplication,
            saveProviderApplication: _saveProviderApplication,
            getInvoiceDetailByIndividualIdAndInvoiceId: _getInvoiceDetailByIndividualIdAndInvoiceId,
            saveAddress: _saveAddress,
            getApplicationByIndividualId: _getApplicationByIndividualId,
            getApplicationByProviderId: _getApplicationByProviderId,
            saveIndividualLicense: _saveIndividualLicense,
            saveProviderLicense: _saveProviderLicense,
            saveTabStatus: _saveTabStatus
        };

        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function _individualPaidInvoiceByIndividualId(Key, individualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/PaidInvoiceByIndividualId/' + Key + '?IndividualId=' + individualId));
        };

        function _individualFinanceByIndividualId(Key, individualId, providerId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Payment/GetPaymentDetailsByIndividualId/' + Key + '?IndividualId=' + individualId + '&ProviderId=&Source=WYPEPLS'));
        };

        function _getApplicationInfoByApplicationId(applicationId, userId, key) {
            return returnDefferedResult($http.get(WebApiUrl + 'Common/ApplicationInfoByApplicationId/' + key + '?ApplicationId=' + applicationId + '&UserId=' + userId));
        };

        function _generateApplicationId(urlParam, data) {
            return returnDefferedResult($http.post(WebApiUrl + 'Application/GenerateApplicationId' + urlParam, data));
        };

        function _saveIndividualApplication(Key, data) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/SaveIndividualApplication/' + Key, data));
        };

        function _saveProviderApplication(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'Provider/ProviderApplicationSave/' + key, reqData));
        };

        function _getInvoiceDetailByIndividualIdAndInvoiceId(key, individualId, invoiceId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetInvoiceDetailByIndividualIdAndInvoiceId/' + key + "?IndividualId=" + individualId + "&InvoiceId=" + invoiceId + '&Source=' + AppConfig.Source));
        };

        function _getInvoiceDetail(key, urlParams) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetInvoiceDetail/' + key, {
                params: urlParams
            }));
        };

        function _getEntityPayorBeneficiary(Key, pager) {
            return returnDefferedResult($http.post(WebApiUrl + 'EntityCashiering/GetEntityPayorBeneficiary/' + Key, pager));
        };

        function _entityPayorGetByEntityId(Key, entityId, entityType) {
            return returnDefferedResult($http.get(WebApiUrl + 'EntityCashiering/GetEntityPayor/' + Key + '?EntityId=' + entityId + '&EntityType=' + entityType));
        };

        function _entityPayorSave(key, entityPayor) {
            return returnDefferedResult($http.post(WebApiUrl + 'EntityCashiering/SaveEntityPayor/' + key + "?Source=" + AppConfig.Source, entityPayor));
        };

        function _getOpenRevDrawer(Key) {
            return returnDefferedResult($http.get(WebApiUrl + 'EntityCashiering/GetOpenRevDrawer/' + Key));
        };

        function _getRevDrawer(Key, reqData) {
            return returnDefferedResult($http.get(WebApiUrl + 'EntityCashiering/GetRevDrawer/' + Key, { params: reqData }));
        };

        function _openRevDrawer(key) {
            return returnDefferedResult($http.post(WebApiUrl + 'EntityCashiering/OpenRevDrawer/' + key + "?Source=" + AppConfig.Source));
        };

        function _reOpenRevDrawer(key, revDrawers) {
            return returnDefferedResult($http.post(WebApiUrl + 'EntityCashiering/SaveRevDrawer/' + key + "?Source=" + AppConfig.Source, revDrawers));
        };

        function _closeRevDrawer(key, revDrawer) {
            return returnDefferedResult($http.post(WebApiUrl + 'EntityCashiering/CloseRevDrawer/' + key + "?Source=" + AppConfig.Source, revDrawer));
        };

        function _individualSave(key, individual) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualSave/' + key + '?Source=' + AppConfig.Source, individual));
        };

        function _saveProvider(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'Provider/ProviderSave/' + key + '?UserId=' + sessionStorage.UserID, reqData));
        };

        function _individualProcessPayment(key, data) {
            var urlParams = {
                Source: "BPELSGFlatTemplate"
            };
            return returnDefferedResult($http.post(WebApiUrl + 'EntityCashiering/ProcessCashieringPayment/' + key, data, {
                params: urlParams
            }));
        };

        function _getInvoiceMasterByIndividualIdAndInvoiceStatus(key, individualId, applicationId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetInvoiceMasterByIndividualIdAndApplicationIdAndInvoiceStatus/' + key + "?IndividualId=" + individualId + '&ApplicationId=' + applicationId + '&Source=' + AppConfig.Source));
        };

        function _getInvoiceMaster(key, urlParams) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetInvoiceMaster/' + key, {
                params: urlParams
            }));
        };

        function _getRevFeeCollect(key, urlParams) {
            return returnDefferedResult($http.get(WebApiUrl + 'EntityCashiering/GetRevFeeCollect/' + key, {
                params: urlParams
            }));
        };

        function _individualByIndividualId(Key, individualId) {
            return $http.get(WebApiUrl + 'Individual/IndividualBYIndividualId/' + Key + '?IndividualId=' + individualId);
        }

        function _invoiceMasterSave(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/InvoiceMasterSave/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _invoiceDetailSave(key, data) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/InvoiceDetailSave/' + key + '?Source=' + AppConfig.Source, data));
        };

        function _processRefund(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'EntityCashiering/ProcessRefund/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _processPaymentTransfer(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'EntityCashiering/ProcessPaymentTransfer/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _processBadCheck(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'EntityCashiering/ProcessBadCheck/' + key + '?Source=' + AppConfig.Source, reqData));
        };

        function _saveAddress(key, address) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualAddressSave/' + key + '?Source=' + AppConfig.Source, address));
        };

        function _getApplicationByIndividualId(key, individualId, isGetAll) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/ApplicationBYIndividualId/' + key + '?IndividualId=' + individualId + '&IsGetAll=' + isGetAll));
        };

        function _getApplicationByProviderId(key, providerId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Provider/ApplicationBYProviderId/' + key + '?ProviderId=' + providerId));
        };

        function _saveIndividualLicense(key, reqData) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualLicenseSave/' + key, reqData));
        };

        function _saveProviderLicense(key, providerLicenses) {
            return returnDefferedResult($http.post(WebApiUrl + 'Provider/SaveProviderLicense/' + key + '?Source=' + AppConfig.Source, providerLicenses));
        };

        function _saveTabStatus(key, tabData) {
            return returnDefferedResult($http.post(WebApiUrl + 'Provider/SaveTabStatus/' + key, tabData));
        };

    }
})();