﻿(function () {
    'use strict'
    angular.module('app.backofficeAdministor')
        .controller('RefTableFormDirectiveController',['$scope', 'RefTableFormDirectiveFactory',  'Messages', 
			function($scope, RefTableFormDirectiveFactory, Messages) {
            var _Key = sessionStorage.Key;
            var _UserId = sessionStorage.UserID;
            $scope.PrimaryKeyValue = 0;
            $scope.init = function () {
                $scope.netValidator = {};
                $scope.FormData = {};
                if ($scope.edititem) {
                    $scope.FormData = angular.copy($scope.edititem);
                    var primaryField=getPrimaryKeyField();
                    if (primaryField && primaryField != '') {
                        $scope.PrimaryKeyValue = $scope.FormData[primaryField];
                    }
                    MakeAllTheDropDownSelected();
                }
                $scope.PreventReadOnly = false;
                //if used for search remove required field
                if ($scope.type == 'search' && $scope.FieldListDetails) {
                    for (var i = 0; i < $scope.FieldListDetails.length; i++) {
                        $scope.FieldListDetails[i].IsRequired = false;
                    }
                    $scope.PreventReadOnly = true;
                }
            };

            //make all teh dropdownSelected
            var MakeAllTheDropDownSelected = function () {
                if ($scope.FieldListDetails) {
                    var ddlFList = $.grep($scope.FieldListDetails, function (res) {
                        return res.FieldType.toLowerCase() == 'dropdown';
                    });
                    if (ddlFList && ddlFList.length > 0) {
                        ddlFList.map(function (i) {
                            if (i.DataSourceItems) {
                                var ds = $.grep(i.DataSourceItems, function (j) {
                                    var v1 = j[getSourceField(i.DropDownSelectByField)];
                                    var v2 = $scope.FormData[getFormFieldToSelectBy(i.DropDownSelectByField)];
                                    if (v1 && v1 != null && v2 && v2 != null) {
                                        return v1.toString().trim() == v2.toString().trim();
                                    } else {
                                        return false;
                                    }
                                })
                                if (ds && ds.length > 0) {
                                    $scope.FormData[i.FieldName] = ds[0];
                                }
                            }
                            return i;
                        });
                    }
                }
            };

            var getSourceField = function (selectBy) {
                if (selectBy && selectBy != "") {
                    var arr = selectBy.split(":");
                    return arr[0];
                }
            };

            var getFormFieldToSelectBy = function (selectBy) {
                if (selectBy && selectBy != "") {
                    var arr = selectBy.split(":");
                    if (arr.length > 1) {
                        return arr[1]
                    } else {
                        return arr[0];
                    }
                }
            };

            $scope.OnDDLValueChange = function (i) {
                if (i && $scope.FormData) {
                    var obj = {};
                    try {
                        obj = JSON.parse(i.DataSourceIdField)
                    } catch (e) { };
                    var masterFields = Object.keys(obj);
                    var dataSourceFields = Object.values(obj);
                    var ddlSelectedValue = $scope.FormData[i.FieldName];
                    if (ddlSelectedValue) {
                        for (var j = 0; j < masterFields.length; j++) {
                            $scope.FormData[masterFields[j]] = ddlSelectedValue[dataSourceFields[j]];
                        }
                    }
                }
            };

            var getPrimaryKeyField=function(){
                if($scope.FieldListDetails){
                    var field=$.grep($scope.FieldListDetails,function(i){
                        return i.IsPrimaryKey;
                    });
                    if(field && field.length>0){
                        return field[0].FieldName;
                    }
                }
                return '';
            };

            $scope.SaveRefTableData = function (form) {
                if (!validateForm(form)) {
                    return;
                }
                //If Form Used for Search
                if ($scope.type == 'search' && $scope.FieldListDetails && $scope.FieldListDetails.length > 0) {
                    var formData = angular.copy($scope.FormData);
                    $scope.$emit('OnSearchRefData', formData);
                }
                //Else If Form Used For Edit & Save
                else if ($scope.FieldListDetails && $scope.FieldListDetails.length > 0) {
                    ShowLoader();
                    var ReqData = {};
                    delete $scope.FormData.DisplayEdit;
                    if (!$scope.PrimaryKeyValue || $scope.PrimaryKeyValue == 0) {
                        ReqData = {
                            FormData:angular.copy($scope.FormData),
                            TableId: $scope.FieldListDetails[0].RefTableId,
                            CreatedBy: _UserId,
                            PrimaryFieldId: 0
                        }
                    } else {
                        ReqData = {
                            FormData:angular.copy($scope.FormData),
                            TableId: $scope.FieldListDetails[0].RefTableId,
                            ModifiedBy: _UserId,
                            PrimaryFieldId: $scope.PrimaryKeyValue
                        }
                    }
                    if (ReqData.FormData) {
                        //date issue resolve
                        var datefields = getDateFields();
                        if (datefields && datefields.length > 0) {
                            for (var i = 0; i < datefields.length; i++) {
                                var val = ReqData.FormData[datefields[i]];
                                if (val && val != '') {
                                    var date = new Date(val);
                                    ReqData.FormData[datefields[i]] =date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                                }
                            }
                        }                    
                        //populate dropdownValue
                        var notRequiredToRemoveFields = [];
                        var ddlFList = [];
                        if ($scope.FieldListDetails) {
                            ddlFList = $.grep($scope.FieldListDetails, function (res) {
                                return res.FieldType.toLowerCase() == 'dropdown';
                            });
                            
                            if (ddlFList && ddlFList.length > 0) {
                                ddlFList.map(function (i) {
                                    var obj = {};
                                    try {
                                        obj = JSON.parse(i.DataSourceIdField)
                                    } catch (e) { };
                                    var masterFields = Object.keys(obj);
                                    notRequiredToRemoveFields =notRequiredToRemoveFields.concat(masterFields);
                                    var dataSourceFields = Object.values(obj);
                                    var ddlSelectedValue = ReqData.FormData[i.FieldName];
                                    for (var j = 0; j < masterFields.length; j++) {
                                        if (ddlSelectedValue) {
                                            ReqData.FormData[masterFields[j]] = ddlSelectedValue[dataSourceFields[j]];
                                        }
                                    }
                                    return i;
                                });
                            }
                        }


                        //remove items which all not required to save                    
                        var fieldsneedtoRemove = getFieldsToRemove();
                        if (fieldsneedtoRemove && fieldsneedtoRemove.length > 0) {
                            if (!notRequiredToRemoveFields) {
                                notRequiredToRemoveFields = [];
                            }
                            for (var i = 0; i < fieldsneedtoRemove.length; i++) {
                                if (notRequiredToRemoveFields.indexOf(fieldsneedtoRemove[i]) == -1) {
                                    delete ReqData.FormData[fieldsneedtoRemove[i]];
                                }
                            }
                        }
                    }

                    RefTableFormDirectiveFactory.SaveRefTableData(_Key, ReqData).then(function (res) {
                        if (res.data.Status) {
                            //alert(Messages.dcbaaaf);
                            $scope.onsavesuccess();
                        } else {
                            if(res.data.Message){
                                var message = 'Error occurred while saving. Error Details: "' + res.data.Message + '"';
                                validateForm(null, message );
                            }
                        }
                        HideLoader();
                    }, function () {
                        HideLoader();
                    });
                }
            };

            var getDateFields = function () {
                if ($scope.FieldListDetails) {
                    var dateFields = $.grep($scope.FieldListDetails, function (res) {
                        return res.FieldType.toLowerCase() == 'datepicker';
                    });
                    if (dateFields && dateFields.length > 0) {
                        return dateFields.map(function (i) {
                            return i.FieldName;
                        });
                    } else {
                        return [];
                    }
                }
            };

            var getFieldsToRemove = function () {
                if ($scope.FieldListDetails) {
                    var flist = $.grep($scope.FieldListDetails, function (res) {
                        return (!res.DisplayInForm || res.IsAdditionalField == 1 || (!res.IsRequired && (typeof $scope.FormData[res.FieldName] == 'undefined' || $scope.FormData[res.FieldName] == null)));
                    });
                    if (flist && flist.length > 0) {
                        return flist.map(function (i) {
                            return i.FieldName;
                        });
                    } else {
                        return [];
                    }
                }
            };

            

            $scope.CancelBtnClick = function () {
                if ($scope.edititem) {
                    $scope.edititem.DisplayEdit = false;
                }
                $scope.oncancelclick({ item: $scope.edititem });
            };

            $scope.FilterFormFields = function (i) {
                return (i.DisplayInForm);
            };

            //This function validates the for on click of submit button
            var validateForm = function (form, error) {
                $scope.netValidator.errorMessages = [];
                if (!!form) {
                    form.$submitted = true;
                    var errorMessages = angular.copy(form.errorMessages);
                    angular.forEach(errorMessages, function (errorMessage, index) {
                        $scope.netValidator.errorMessages.push(errorMessage);
                    });
                }
                if (!!error) {
                    $scope.netValidator.errorMessages.push({ errorMessage: error });
                }

                if (!!$scope.netValidator.errorMessages && $scope.netValidator.errorMessages.length > 0) {
                    $scope.netValidator.showError = true;
                    $scope.netValidator.isValid = false;
                    return false;
                } else {
                    $scope.netValidator.showError = false;
                    $scope.netValidator.isValid = true;
                    return true;
                }
            };

        }]);
})();