(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('entityConfirmation', [EntityConfirmation]);

    function EntityConfirmation() {
        return {
            restrict: 'E',
            scope: {
                entityId: '=',
                entityType: '@',
                applicationId: '=?',
                applicationTypeId: '=?',
                applicationInfo: '=?',
                showHeaderPanel: '=?',
                showSubmitPanel: '=?',
                isBackoffice: '=?',
                natValidation: '=',
                isTabStructure: '=',
                tab: '=?',
                openNextTab: '&',
                configName: '@',
                eventname: "=eventname",
                isRequired: '@',
                legalResponses: '=?',
                content: "=?"
            },
            link: function ($scope, element, attrs) {
                
            },
            templateUrl: 'app/core/directive/entity-confirmation/entity-confirmation.html',
            controller: 'EntityConfirmationController'
        };
    }
})();