(function () {
    'use strict'
    angular.module('app.core')
        .controller('MultipleFileUploadController', MultipleFileUploadController);

    MultipleFileUploadController.$inject = ['$controller', '$rootScope', '$scope', '$http', '$q',
        'multipleFileUploadService', 'backofficeIndividualPersonalService', 'configurationService', 'SettingsValue', '$exceptionHandler', 'UploadDataUrl', '$filter', 'Messages'
    ];

    function MultipleFileUploadController($controller, $rootScope, $scope, $http, $q,
        multipleFileUploadService, backofficeIndividualPersonalService, configurationService, SettingsValue, $exceptionHandler, UploadDataUrl, $filter, Messages) {
        $controller('GlobalController', {
            $scope: $scope
        });
        $scope.isBackOfficeUser = (!!sessionStorage.isBackOfficeUser && (sessionStorage.isBackOfficeUser == true || sessionStorage.isBackOfficeUser == 'true'));
        $scope.acceptPattern = "image/*,text/plain,application/pdf,.doc,.docx,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";


        $scope.invalidFiles = []

        $scope.closeFileError = function () {
            $scope.invalidFiles = []
            $scope.documentForm.documentfile.$modelValue = angular.copy($scope.documentForm.documentfile.$modelValue)
        }

        $scope.masterDocument = {
            DocumentName: "",
            DocumentType: "",
            DocumentFile: null
        };

        $scope.resetDocument = function () {
            $scope.document = angular.copy($scope.masterDocument);
            if (!!$scope.preSelectFirstDocumentType && (!!$scope.DocType && $scope.DocType.length == 1)) {
                $scope.document.DocumentType = $scope.DocType[0];
            }
        };

        $scope.resetDocumentList = function () {
            $scope.resetDocument();
            $scope.individualDocumentUpload.DocumentUploadList = [];
        };

        $scope.resetDocumentList();

        function init() {
            $scope.individualDocumentUpload.isBackOfficeUser = $scope.isBackOfficeUser;
            if (!$scope.individualDocumentUpload.LoadByDocIdAndDocCode) {
                multipleFileUploadService.documentMasterGetALL(sessionStorage.Key)
                    .then(function (response) {
                        if ($scope.checkResponse(response) && !!response.DocumentMasterGET) {
                            if (!$scope.individualDocumentUpload.MultipleDocId) {
                                $scope.DocType = response.DocumentMasterGET.orderBy('DocumentName,DocumentTypeIdName');
                            } else {
                                $scope.DocType = $filter('filter')(response.DocumentMasterGET, function (docMaster) {
                                    return ($scope.individualDocumentUpload.MultipleDocId.indexOf(docMaster.DocumentId) != -1);
                                });
                            }
                            if (!!$scope.preSelectFirstDocumentType && (!!$scope.DocType && $scope.DocType.length == 1))
                                $scope.document.DocumentType = $scope.DocType[0];
                        }
                    }, function (data) {
                        showStatusMessage(data, "error");
                    });
            } else {

                multipleFileUploadService.documentGetDocumentTypeName(sessionStorage.Key, $scope.individualDocumentUpload.DocId, $scope.individualDocumentUpload.DocCode)
                    .then(function (response) {
                        if ($scope.checkResponse(response) && !!response.DocumentMasterGET) {
                            $scope.DocType = response.DocumentMasterGET.orderBy('DocumentTypeIdName');
                            if (!!$scope.preSelectFirstDocumentType && (!!$scope.DocType && $scope.DocType.length == 1))
                                $scope.document.DocumentType = $scope.DocType[0];
                        }
                    }, function (data) {
                        showStatusMessage(data, "error");
                    });
            }
            if ($scope.hideDocumentName != false) {
                $q.all([configurationService.configurationGetbySettings(sessionStorage.Key, SettingsValue.IsDocumentNameHidden),
                configurationService.configurationGetbySettings(sessionStorage.Key, SettingsValue.AllowedDocTypesForOnline),
                configurationService.configurationGetbySettings(sessionStorage.Key, SettingsValue.AllowedDocTypesForBackOffice)
                ])
                    .then(function (response) {
                        if (!!response[0].Status && !!response[1].Status && !!response[2].Status) {
                            $scope.hideDocumentName = response[0].ConfigurationList[0] ? (response[0].ConfigurationList[0].Value.toLowerCase() == "true" ? true : false) : true;
                            if (!$scope.isBackOfficeUser) {
                                $scope.acceptPattern = response[1].ConfigurationList[0].Value;
                            } else {
                                $scope.acceptPattern = response[2].ConfigurationList[0].Value;
                            }
                        }
                    }, function (data) {
                        showStatusMessage(data, "error");
                    });
            }
        };

        $scope.$on("initdocumentCreation", function (evt) {
            init();
        });

        $scope.processValidation = function (internalCall) {
            $scope.individualDocumentUpload.errorMessages = [];
            if (!!$scope.document.DocumentName || !!$scope.document.DocumentType ||
                (!!$scope.individualDocumentUpload.DocumentUploadList &&
                    $scope.individualDocumentUpload.DocumentUploadList.length > 0)
            ) {
                $scope.individualDocumentUpload.isUploadRequired = true;
                if ((!!$scope.hideDocumentName || !!$scope.document.DocumentName) && !!$scope.document.DocumentType &&
                    (!!$scope.individualDocumentUpload.DocumentUploadList &&
                        $scope.individualDocumentUpload.DocumentUploadList.length > 0)) {
                    $scope.individualDocumentUpload.isUploadValid = true;
                } else {
                    $scope.individualDocumentUpload.isUploadValid = false;
                    if (!$scope.hideDocumentName && !$scope.document.DocumentName) {
                        $scope.individualDocumentUpload.errorMessages.push({
                            propName: "docNameReq",
                            errorMessage: Messages.General.Errors.DocName
                        });
                    }
                    if (!$scope.document.DocumentType) {
                        $scope.individualDocumentUpload.errorMessages.push({
                            propName: "docTypeReq",
                            errorMessage: Messages.General.Errors.DocType
                        });
                    }
                    if (!$scope.individualDocumentUpload.DocumentUploadList ||
                        $scope.individualDocumentUpload.DocumentUploadList.length == 0) {
                        $scope.individualDocumentUpload.errorMessages.push({
                            propName: "docUploadReq",
                            errorMessage: Messages.General.Errors.UploadDoc
                        });
                    }
                }
            } else {
                $scope.individualDocumentUpload.isUploadRequired = false;
                $scope.individualDocumentUpload.isUploadValid = true;
            }
            if (!internalCall) {
                angular.forEach($scope.individualDocumentUpload.DocumentUploadList, function (currentDocFile) {
                    $scope.processDocumentFields(currentDocFile);
                });
            }
        };

        $scope.processDocumentFields = function (documentToUpload) {
            if (!$scope.hideDocumentName) {
                if (!!$scope.document.DocumentName) {
                    documentToUpload.DocumentName = $scope.document.DocumentName;
                    documentToUpload.DocNameWithExtention = $scope.document.DocumentName;
                }
            } else {
                documentToUpload.DocumentName = "Document";
            }
            if (!$scope.hideDocumentType) {
                if (!!$scope.document.DocumentType) {
                    documentToUpload.DocumentTypeId = $scope.document.DocumentType.DocumentMasterId;
                    documentToUpload.DocumentTypeName = $scope.document.DocumentType.DocumentTypeIdName;
                    documentToUpload.OtherDocumentTypeName = $scope.document.DocumentType.DocumentTypeIdName;
                    if (!!$scope.individualDocumentUpload.MultipleDocId) {
                        documentToUpload.DocumentId = $scope.document.DocumentType.DocumentId;
                        documentToUpload.DocumentCd = $scope.document.DocumentType.DocumentCd;
                    }
                }
            } else {
                documentToUpload.DocumentTypeId = $scope.DocType[0].DocumentMasterId;
                documentToUpload.DocumentTypeName = $scope.DocType[0].DocumentTypeIdName;
                if (!!$scope.individualDocumentUpload.MultipleDocId) {
                    documentToUpload.DocumentId = $scope.DocType[0].DocumentId;
                    documentToUpload.DocumentCd = $scope.DocType[0].DocumentCd;
                }
            }
            documentToUpload.EffectiveDate = new Date();
            return documentToUpload;
        };

        $scope.AddNewDocument = function (files) {
            if (!$scope.individualDocumentUpload.DocumentUploadList)
                $scope.individualDocumentUpload.DocumentUploadList = [];
            var individualDocumentUpload = $scope.individualDocumentUpload;
            if (files && files.length) {
                UploadDataUrl.base64DataUrl(files).then(function (response) {
                    angular.forEach(response.file, function (currentDocFile) {
                        var documentToUpload = {
                            IndividualDocumentId: 0,
                            DocumentLkToPageTabSectionCode: individualDocumentUpload.DocumentLkToPageTabSectionCode,
                            DocumentLkToPageTabSectionId: individualDocumentUpload.DocumentLkToPageTabSectionId,
                            DocumentPath: currentDocFile.name,
                            DocName: currentDocFile.name,
                            DocStrBase64: currentDocFile.base64,
                            IsDocumentUploadedbyIndividual: (!$scope.isBackOfficeUser),
                            IsDocumentUploadedbyStaff: $scope.isBackOfficeUser,
                            ReferenceNumber: (!!$scope.referenceNumber ? $scope.referenceNumber : ""),
                            DocumentId: (!!individualDocumentUpload.DocId) ? individualDocumentUpload.DocId : 19,
                            DocumentCd: (!!individualDocumentUpload.DocCode) ? individualDocumentUpload.DocCode : "D1019",
                            DocumentUploadedForId: individualDocumentUpload.DocumentUploadedForId
                        };
                        $scope.processDocumentFields(documentToUpload);
                        $scope.individualDocumentUpload.DocumentUploadList.push(documentToUpload);
                    });
                    $scope.processValidation(true);
                })
            } else {
                $scope.processValidation(true);
            }
            //$scope.resetDocument();
        };

        $scope.deleteDoc = function (doc, index) {
            $scope.individualDocumentUpload.DocumentUploadList.splice(index, 1);
        };

        $scope.individualDocumentSave = function (documentLinkId) {
            try {
                var defer = $q.defer();
                hideStatusMessage();
                hideValidationSummary();
                if (!!$scope.individualDocumentUpload && !!$scope.individualDocumentUpload.DocumentUploadList && $scope.individualDocumentUpload.DocumentUploadList.length > 0) {
                    ShowLoader("Please wait...");
                    angular.forEach($scope.individualDocumentUpload.DocumentUploadList, function (currentDocFile) {
                        currentDocFile.LinkType = $scope.individualDocumentUpload.LinkType;
                        currentDocFile.LinkId = documentLinkId ? documentLinkId : $scope.individualDocumentUpload.LinkId;
                        $scope.processDocumentFields(currentDocFile);
                    });
                    multipleFileUploadService.individualDocumentSave($scope.individualDocumentUpload, sessionStorage.Key)
                        .then(function (response) {
                            if ($scope.checkResponse(response)) {
                                if (!$scope.individualDocumentUpload.IsLinkedDocControl) {
                                    if ($scope.showListing) {
                                        $scope.$emit("documentsSavedFromControl", {
                                            savedDocuments: response.IndividualDocumentUploadList
                                        })
                                        $scope.$broadcast("loaddocumentListing", $scope.individualDocumentUpload.IndividualId);
                                    }
                                }
                                //showStatusMessage(response.Message, "success");
                                $scope.resetDocument();
                                $scope.resetDocumentList();
                                defer.resolve(response);
                            } else {
                                defer.reject(response);
                            }
                            HideLoader();
                        }, function (data) {
                            HideLoader();
                            if (data != null) {
                                showStatusMessage(data.message, "error");
                            }
                            defer.reject(data);
                        });
                } else {
                    defer.resolve({
                        Status: true
                    });
                }
                return defer.promise;
            } catch (ex) {
                HideLoader();
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
                $exceptionHandler(ex);
            }
        };

        function sendUploadNotification(documentType) {
            backofficeIndividualPersonalService.individualBYIndividualId(sessionStorage.Key, $scope.individualDocumentUpload.IndividualId, true).then(function (response) {
                if (response.IndividualResponse != null) {
                    $scope.personDetails = response.IndividualResponse[0];
                    var individualTask = {
                        IndividualId: $scope.individualDocumentUpload.IndividualId,
                        ApplicationId: $scope.individualDocumentUpload.ApplicationId,
                        TaskAssignedtoUserId: $scope.individualDocumentUpload.NotificationToUserId,
                        TaskAssignedByUserId: (($scope.isBackOfficeUser) ? sessionStorage.UserID : sessionStorage.UserId),
                        TaskTypeId: 1,
                        TaskTypeName: (($scope.personDetails.LicenseNumber ? ($scope.personDetails.LicenseNumber + "-") : "") + $scope.personDetails.FirstName + ' ' + $scope.personDetails.LastName),
                        TaskTypeSubject: 'DNQ document uploaded',
                        TaskTypeDesc: ("DNQ document uploaded of type " + documentType.DocumentTypeIdName),
                        TaskStatusID: 1,
                    };
                    taskDirectiveFactory.SaveIndividualTask(sessionStorage.Key, individualTask);
                }
            }, function (data) {
                HideLoader();
                $scope.showStatusMessage(data, "error");
            });
        };

        $rootScope.$on("cleardocumentField", function () {
            $scope.resetDocumentList();
        });

        init();
    }
})();