﻿(function () {
    'use strict'
    angular.module('app.core')
        .directive('providerDashboardApplicationDetails', [function () {
            return {
                restrict: 'EA',
                scope: {
                    //providerId: '=',
                    iseditable: '=iseditable',
                    isapplicant: '=isapplicant',
                    applicationLoaded: '&?'
                },
                controller: "ProviderDashboardApplicationDetailsController",
                templateUrl: "app/core/directive/provider-dashboard-application-details/provider-dashboard-application-details.html",
                link: function ($scope, element, attrs, ngCtrl) {

                }
            };
        }]);
})();
