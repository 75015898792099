(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationStandardsPracticeSurveying', initialApplicationStandardsPracticeSurveyingDirective);

    initialApplicationStandardsPracticeSurveyingDirective.$inject = [];

    function initialApplicationStandardsPracticeSurveyingDirective() {
        return {
            restrict: 'E',
            scope: {
                cpcEducationInfo: "=cpcEducationInfo",
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                headingTest: "=headingTest",
                applicationId: "=applicationId",
                individualId: "=individualId",
                individualLicense: "=individualLicense",
                individualCEH: "=individualCeh",
                natValidation: "=natValidation",
                showStatusMessage: "&",
                tab: "=tab",
                openNextTab: "&",
                isRenewal: '=?isRenewal',
                loadData: '=?loadData',
                individualLicenseReciprocity: "=?individualLicenseReciprocity",
                individualLicenseStatusSubTypeReason: "=?individualLicenseStatusSubTypeReason",
                personalData: "=?personalData",
                courseTypeId: '=courseTypeId',
                totalValue: "=?totalValue"
            },
            templateUrl: "app/components/individual/application/directives/standards-practice-surveying/standards-practice-surveying.html",
            controller: "StandardsPracticeSurveyingController"
        }
    }
})();