﻿(function() {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeProviderPeerService', backofficeProviderPeerService)

    backofficeProviderPeerService.$inject = ['$http', '$q', 'WebApiUrl']

    function backofficeProviderPeerService($http, $q, WebApiUrl) {


        var _savePeer = function(key, PeerData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/SaveProviderOther/' + key + '?Source=', PeerData)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _GetPeer = function(key, ProviderId) {
            var deferred = $q.defer();
            // console.log(WebApiUrl + "Provider/GetProviderOther/" + key + '?ProviderId=' + ProviderId + '&ApplicationId=')
            $http.get(WebApiUrl + "Provider/GetProviderOther/" + key + '?ProviderId=' + ProviderId + '&ApplicationId=')
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return {
            savePeer: _savePeer,
            GetPeer: _GetPeer
        }
    }
})();