//Directives
// Tabs and Panes Directive
angular.module('natApp').
    directive('tabs', function () {
        return {
            restrict: 'E',
            transclude: true,
            scope: {},
            controller: ["$scope", function ($scope) {
                var panes = $scope.panes = [];

                $scope.select = function (pane) {
                    angular.forEach(panes, function (pane) {
                        pane.selected = false;
                    });
                    pane.selected = true;
                }

                this.addPane = function (pane) {
                    if (panes.length == 0) $scope.select(pane);
                    panes.push(pane);
                }
            }],
            template:
                '<div class="inner-round peel-shadows mb20">' +
                '<div class="tab-bordered p10">' +
                '<ul class="nav nav-tabs">' +
                '<li ng-repeat="pane in panes" ng-class="{active:pane.selected}">' +
                '<a href="" ng-click="select(pane)">{{pane.title}}</a>' +
                '</li>' +
                '</ul>' +
                '</div>' +
                '<div class="row">' +
                '<div class="col-sm-12 col-md-12">' +
                '<div class="mr10"></div>' +
                '<div class="tab-content" ng-transclude></div>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>',
            replace: true
        };
    }).
    directive('pane', function () {
        return {
            require: '^tabs',
            restrict: 'E',
            transclude: true,
            scope: { title: '@' },
            link: function (scope, element, attrs, tabsCtrl) {
                tabsCtrl.addPane(scope);
            },
            template:
                '<div class="tab-pane" ng-class="{active: selected}" ng-transclude>' +
                '</div>',
            replace: true
        };
    }).

    // Question Category Directive
    directive('categories', function () {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                list: '=list'
            },
            link: function (scope) {
                scope.getData();
            },
            controller: ["$scope", "examFactory", "typeValuesService", function ($scope, examFactory, typeValuesService) {

                // Init Function
                $scope.init = function () {

                    // Initialize Variables
                    $scope.data = {};
                    $scope.types = [];

                    $scope.dateOptions = { format: 'MM/DD/YYYY' };
                    $scope.keepLive = false;

                    // Set up State Variables               
                    $scope.deleteMode = false;
                    $scope.selected = null;

                    // Pagination
                    $scope.Pager = {
                        sortType: 'ExamQuestionCategoryName',
                        sortOrder: 'asc',
                        currentPage: 1,
                        totalRecords: 10,
                        pageSize: 20,
                        maxSize: 5
                    }

                    // Headers
                    $scope.Headers = [
                        { name: 'Exam Category Name', sortType: 'ExamQuestionCategoryName', sortOrder: 'asc' },
                        { name: 'License Types', sortType: 'LicenseTypesNames', sortOrder: 'asc' },
                    ]

                    typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=licensetype")).then(function (res) {
                        $scope.types = angular.copy(res.ReferenceEntities);
                    });
                }

                // If deleting from modal confirmation, then activate the delete method
                // $scope.$watch('deleteMode', function(newValue) {
                //     if(newValue==true) {
                //         $scope.deleteCategory();
                //     } 
                //  });

                // Refresh a new data entry everytime the add button is clicked
                $scope.newEntry = function () {
                    $scope.data = {
                        ExamQuestionCategoryID: -1,
                        ExamQuestionCategoryName: "",
                        EffectiveDate: null,
                        EndDate: null,
                        IsActive: true,
                        IsDeleted: false,
                        LicenseTypeId: null,
                        LicenseTypes: "",
                        LicenseTypeIds: []
                    }
                }

                // Populate Fields when editing
                $scope.editData = function (id) {
                    $scope.selected = $scope.findIndex(id);
                    $scope.data = angular.copy($scope.list[$scope.selected]);
                    $scope.data.LicenseTypeIds = [];
                    var split = $scope.data.LicenseTypes.split(",");
                    for (var i = 0; i < split.length; i++) $scope.data.LicenseTypeIds[Number(split[i]) - 1] = Number(split[i]);
                    if ($scope.data.EndDate == null) $scope.keepLive = true;
                    if ($scope.data.EndDate != null) $scope.data.EndDate = new Date($scope.data.EndDate);
                };

                // Set up loader and retrieve data
                $scope.getData = function () {
                    $scope.loadingCategories(true);
                    $scope.getCategoryList();
                }

                // Retrieve Paginated Results from Database
                $scope.getCategoryList = function () {
                    examFactory.getAllCategories(sessionStorage.Key, $scope.Pager).then(function (res) {
                        $scope.list = angular.copy(res.data.ExamList);
                        if ($scope.list != null && $scope.list.length > 0) $scope.Pager.totalRecords = $scope.list[0].TotalRecord;
                    }).finally(function () { $scope.loadingCategories(false) });
                };

                // Add new entries to the dictionary while incrementing the index
                $scope.saveCategory = function () {
                    $scope.loadingCategories(true);
                    if ($scope.keepLive == true) $scope.data["EndDate"] = null;
                    $scope.data.LicenseTypes = "";
                    for (var i = 0; i < $scope.data.LicenseTypeIds.length; i++)
                        if ($scope.data.LicenseTypeIds[i] > 0) $scope.data.LicenseTypes = $scope.data.LicenseTypes + $scope.data.LicenseTypeIds[i] + (i + 1 == $scope.data.LicenseTypeIds.length ? '' : ',');
                    examFactory.saveCategory(sessionStorage.Key, $scope.data).then(function (res) {
                        $scope.getCategoryList();
                        $scope.data = {};
                        $scope.deleteMode = false;
                        $scope.selected = null;
                    });
                };

                // "Delete" a category element from the database
                // $scope.deleteCategory = function () {
                //     $scope.data = angular.copy($scope.list[$scope.selected]);
                //     $scope.data["IsActive"] = false;
                //     $scope.saveCategory();
                // };

                // Find the index of the list when the ID matches
                $scope.findIndex = function (id) {
                    return $scope.list.map(function (e) { return e.ExamQuestionCategoryID; }).indexOf(id);
                }

                // Sort by Column
                $scope.SortRecords = function (header) {
                    $scope.Pager.currentPage = 1;
                    if ($scope.Pager.sortType != header.sortType) {
                        $scope.Pager.sortType = header.sortType;
                        header.sortOrder = "asc";
                    }
                    else {
                        header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
                    }
                    $scope.Pager.sortOrder = header.sortOrder;
                    $scope.getData();
                }
            }],
            templateUrl: 'app/components/individual/license/renewal/test-UI-tab-categories.html',
            replace: true
        };
    }).

    // Question Section Directive
    directive('sections', function () {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                list: '=',
                categories: '<'
            },
            link: function (scope) {
                scope.getData();
            },

            controller: ["$scope", "examFactory", function ($scope, examFactory) {

                $scope.init = function () {

                    // Initialize Variables
                    $scope.data = {};
                    $scope.selected = null;
                    $scope.dateOptions = { format: 'MM/DD/YYYY' };
                    $scope.keepLive = false;

                    // Set up state for deleting
                    $scope.deleteMode = false;

                    // Pagination
                    $scope.Pager = {
                        sortType: 'ExamSectionNumber',
                        sortOrder: 'asc',
                        currentPage: 1,
                        totalRecords: 10,
                        pageSize: 20,
                        maxSize: 5,
                        ExamQuestionCategoryId: null
                    }

                    // Headers
                    $scope.Headers = [
                        { name: 'Exam Section Name', sortType: 'ExamSectionName', sortOrder: 'asc' },
                        { name: 'Exam Section Number', sortType: 'ExamSectionNumber', sortOrder: 'asc' },
                        { name: 'Exam Category', sortType: 'ExamQuestionCategoryID', sortOrder: 'asc' }
                    ]
                }

                // // If deleting from modal confirmation, then activate the delete method
                // $scope.$watch('deleteMode', function(newValue) {
                //     if(newValue==true) {
                //         $scope.deleteSection();
                //     } 
                //  });            

                // Refresh a new data entry everytime the add button is clicked
                $scope.newEntry = function () {
                    $scope.data = {
                        ExamSectionID: -1,
                        ExamQuestionCategoryID: null,
                        ExamSectionName: null,
                        ExamSectionNumber: null,
                        EffectiveDate: null,
                        EndDate: null,
                        IsActive: true,
                        IsDeleted: false
                    }
                }

                // Save any edits only on button click
                $scope.editData = function (id) {
                    $scope.dvaddexamsec = true;
                    $scope.btnaddexamsec = true;
                    $scope.selected = $scope.findIndex(id);
                    $scope.data = angular.copy($scope.list[$scope.selected]);
                    if ($scope.data.EndDate == null) $scope.keepLive = true;
                    if ($scope.data.EndDate != null) $scope.data.EndDate = new Date($scope.data.EndDate);
                }

                // Set up Loader and call the Section Retrieval Function
                $scope.getData = function () {
                    $scope.loadingSections(true);
                    $scope.getSectionList();
                }

                // Retrieve Section Data from Database
                $scope.getSectionList = function () {
                    examFactory.getAllSections(sessionStorage.Key, $scope.Pager).then(function (res) {
                        $scope.list = [];
                        var filterData = _.filter(res.data.ExamList, function (it) {
                            return it.ExamQuestionCategoryID > 0;
                        });
                        var record = (res.data.ExamList.length - filterData.length);
                        $scope.list = angular.copy(filterData);
                        if ($scope.list != null && $scope.list.length > 0) $scope.Pager.totalRecords = ($scope.list[0].TotalRecord - record);
                    }).finally(function () { $scope.loadingSections(false) });
                };

                // Save a section and then update it
                $scope.saveSection = function () {
                    $scope.loadingSections(true);
                    if ($scope.keepLive == true) $scope.data["EndDate"] = null;
                    examFactory.saveSection(sessionStorage.Key, $scope.data).then(function (res) {
                        $scope.getSectionList();
                        $scope.data = {};
                        $scope.deleteMode = false;
                        $scope.selected = null;
                    });
                };

                // "Delete" a section
                // $scope.deleteSection = function () {
                //     $scope.data = angular.copy($scope.list[$scope.selected]);
                //     $scope.data["IsActive"] = false;
                //     $scope.data["IsDeleted"] = true;
                //     $scope.saveSection();
                // };

                // Find the index of the list when the ID matches
                $scope.findIndex = function (id) {
                    return $scope.list.map(function (e) { return e.ExamSectionID; }).indexOf(id);
                }

                // Find the index of the list when the ID matches
                $scope.findCategory = function (id) {
                    return $scope.categories.map(function (e) { return e.ExamQuestionCategoryID; }).indexOf(id);
                }

                // Sort by Column
                $scope.SortRecords = function (header) {
                    $scope.Pager.currentPage = 1;
                    if ($scope.Pager.sortType != header.sortType) {
                        $scope.Pager.sortType = header.sortType;
                        header.sortOrder = "asc";
                    }
                    else {
                        header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
                    }
                    $scope.Pager.sortOrder = header.sortOrder;
                    $scope.getData();
                }

                $scope.sorterFunc = function (section) {
                    return parseInt(section.ExamSectionNumber);
                };

            }],
            templateUrl: 'app/components/individual/license/renewal/test-UI-tab-sections.html',
            replace: true
        };
    }).

    // Question Category Directive
    directive('questionBank', ['$injector', function ($injector) {
        return {
            restrict: 'E',
            transclude: {
                'multi': '?multipleChoice',
                'tf': '?trueFalse'
            },
            scope: {
                data: '=',
                list: '=',
                types: '=',
                sections: '<'
            },
            link: function (scope) {
                scope.getTypes();
                scope.getData();
            },
            controller: ["$scope", "examFactory", "$q", '$timeout', function ($scope, examFactory, $q, $timeout) {

                // Init Function
                $scope.init = function () {

                    // Initialize Variables
                    $scope.selected = null;
                    $scope.dateOptions = { format: 'MM/DD/YYYY' };
                    $scope.keepLive = false;
                    $scope.filterSection = null;

                    // Set up state for deleting
                    $scope.deleteMode = false;
                    $scope.questionSwitch = false;

                    // Pagination
                    $scope.Pager = {
                        sortType: 'ExamQuestionNumber',
                        sortOrder: 'asc',
                        currentPage: 1,
                        totalRecords: 10,
                        pageSize: 20,
                        maxSize: 5,
                        ExamSectionId: null
                    }

                    $scope.documentpager = {
                        ExamQuestionDocumentId: 0,
                        ExamQuestionId: 0,
                        Document: null,
                        IsActive: true,
                        IsDeleted: false
                    }

                    // Headers
                    $scope.Headers = [
                        { name: 'Exam Question Number', sortType: 'ExamQuestionNumber', sortOrder: 'asc' },
                        { name: 'Exam Question Type', sortType: 'ExamQuestionTypeId', sortOrder: 'asc' },
                        { name: 'Exam Section', sortType: 'ExamSectionId', sortOrder: 'asc' },
                        { name: 'Point Value', sortType: 'PointValue', sortOrder: 'asc' },
                        { name: 'Weighted Value', sortType: 'WeightedValue', sortOrder: 'asc' }
                    ]
                }

                // If deleting from modal confirmation, then activate the delete method
                $scope.$watch('deleteMode', function (newValue, oldValue) {
                    if (newValue == true) {
                        $scope.deleteQuestion();
                    }
                });

                // Refresh a new data entry everytime the add button is clicked
                $scope.newEntry = function () {

                    // Set Flags
                    $scope.DVAddQuestionBank = true;
                    $scope.btnaddquestion = true;
                    $scope.keepLive = false;

                    // Refresh data entry
                    $scope.data = {
                        ExamQuestionId: -1,
                        ExamQuestionTypeId: null,
                        ExamQuestionText: "",
                        ExamQuestionNumber: null,
                        ExamQuestionName: null,
                        ExamSectionId: null,
                        PointValue: null,
                        WeightedValue: null,
                        MultipleOptions: false,
                        EndDate: null,
                        IsActive: true,
                        IsDeleted: false,
                        conflicts: {}
                    }
                }

                // Add new entries to the assigned list
                $scope.saveData = function () {

                    $scope.saveQuestion();
                    $scope.DVAddQuestionBank = false;
                    $scope.btnaddquestion = false
                }

                // Turn on the Edit Mode
                $scope.editData = function (id) {
                    var promises = [];
                    $scope.selected = $scope.findIndex(id);
                    $scope.data = angular.copy($scope.list[$scope.selected]);
                    $scope.data.answers = [];
                    $scope.data.image = {};
                    if ($scope.data.EndDate == null) $scope.keepLive = true;
                    if ($scope.data.EndDate != null) $scope.data.EndDate = new Date($scope.data.EndDate);

                    // Load Answers
                    $scope.loadingQuestions(true);
                    promises.push(examFactory.getQuestionAnswers(sessionStorage.Key, $scope.data.ExamQuestionId));
                    promises.push(examFactory.getSingleQuestionDocument(sessionStorage.Key, $scope.data.ExamQuestionId));

                    // Resolve all promises, format data structures, then shut off loading
                    $q.all(promises).then(function (res) {
                        console.log(res);
                        // $scope.data.answers = angular.copy(res[0].data.ExamList);
                        // $scope.data.image = angular.copy(res[1].data.ExamList[0]);  
                        $timeout(function () {
                            //any code in here will automatically have an apply run afterwards
                            $scope.data.answers = angular.copy(res[0].data.ExamList);
                            $scope.data.image = angular.copy(res[1].data.ExamList[0]);
                        });
                        $scope.loadingQuestions(false);
                    });

                    // Copy Conflicts
                    $scope.data.conflicts = angular.copy($scope.list[$scope.selected].conflicts);
                    $scope.data.conflictList = $scope.data.conflicts.map(a => {
                        if (a.IsActive) return a.ExamQuestionConflictNumber;
                    });

                    // Turn on the edit state
                    $scope.editMode = true;
                }

                // Get the current list of Questions
                $scope.getData = function () {
                    $scope.loadingQuestions(true);
                    $scope.getQuestionList();
                }

                // Get the current list of question types
                $scope.getTypes = function () {

                    // Set up promises
                    var promises = [];

                    // Query for Types
                    promises.push(examFactory.getAllQuestionTypes(sessionStorage.Key));

                    // Resolve promises
                    $q.all(promises).then(function (res) {
                        $scope.types = angular.copy(res[0].data.ExamList);
                    });
                }

                // Query database for list of active questions
                $scope.getQuestionList = function () {

                    examFactory.getAllQuestions(sessionStorage.Key, $scope.Pager).then(function (res) {
                        // Set up promises
                        var promises = [];

                        // Save local copy of question list
                        $scope.list = angular.copy(res.data.ExamList);
                        if ($scope.list != null && $scope.list.length > 0) $scope.Pager.totalRecords = $scope.list[0].TotalRecord;

                        // Load promises
                        angular.forEach(res.data.ExamList, function (response, index) {
                            promises.push(examFactory.getQuestionConflicts(sessionStorage.Key, response.ExamQuestionId));
                            $scope.list[index].conflicts = [];
                            $scope.list[index].conflictList = [];
                        });

                        // Resolve promises
                        $q.all(promises).then(function (res) {
                            // For each promise
                            angular.forEach(res, function (response, index) {

                                $scope.list[index].conflicts = angular.copy(response.data.ExamList);
                                $scope.list[index].conflictList = $scope.list[index].conflicts.map(a => {
                                    if (a.IsActive) return a.ExamQuestionConflictNumber;
                                });

                            });
                            $scope.loadingQuestions(false);
                        });
                    });
                };

                // Conflict function bound to control the conflict multiselect
                $scope.editConflict = function (conflictID, status) {

                    // Check if conflict exists with this question ID
                    var index = $scope.data.conflicts.map(function (e) { return e.ExamQuestionConflictWithId; }).indexOf(conflictID);

                    // If Yes
                    if (index > -1) {

                        // If unchecked false
                        if (!status) {
                            $scope.data.conflicts[index].IsActive = false;

                        }

                        // If checked true
                        else {
                            $scope.data.conflicts[index].IsActive = true;
                        }
                    }

                    // If No
                    else {

                        // Make a new Entry
                        $scope.data.conflicts.push(
                            {
                                ExamQuestionConflictId: -1,
                                ExamQuestionId: null,
                                ExamQuestionConflictWithId: conflictID,
                                EffectiveDate: null,
                                EndDate: null,
                                IsActive: true
                            }
                        );
                    }
                }

                // Save a section and then update it
                $scope.saveQuestion = function () {
                    var promises = [];

                    if ($scope.questionSwitch == true) {
                        angular.forEach($scope.data.answers, function (answer) {
                            if (answer.ExamQuestionAnswerId != -1) {
                                answer.IsActive = false;
                                answer.IsDeleted = true;
                            }
                        });
                        $scope.questionSwitch = false;
                    }

                    if ($scope.data.image != null) {
                        $scope.documentpager.ExamQuestionDocumentId = $scope.data.image.ExamQuestionDocumentId;
                        $scope.documentpager.ExamQuestionId = $scope.data.ExamQuestionId;
                        $scope.documentpager.Document = $scope.data.image.Photo;
                        $scope.documentpager.IsDeleted = $scope.data.image.IsDeleted;
                        $scope.documentpager.ExamDocumentName = $scope.data.image.ExamDocumentName;
                    }

                    $scope.loadingQuestions(true);
                    if ($scope.keepLive == true) $scope.data["EndDate"] = null;
                    examFactory.saveQuestion(sessionStorage.Key, $scope.data).then(function (res) {

                        // Set up Promises to save answers and conflicts
                        $scope.saveAnswers(res.data.ExamList[0].ExamQuestionId, promises);
                        $scope.saveConflicts(res.data.ExamList[0].ExamQuestionId, promises);
                        if ($scope.data.image != null) promises.push(examFactory.saveExamQuestionDocument(sessionStorage.Key, $scope.documentpager));

                        // Wait for completion across all promises before shutting off the loading
                        $q.all(promises).then(function (results) {
                            // console.log(results);
                            $('#upload').val(null);
                            $scope.documentpager = {
                                ExamQuestionDocumentId: -1,
                                ExamQuestionId: 0,
                                Document: null,
                                IsActive: true,
                                IsDeleted: false,
                                ExamDocumentName: null
                            }
                        });

                        $scope.getQuestionList();

                    }).finally(function () {
                        // Revert states back to normal
                        $scope.deleteMode = false;
                        $scope.selected = null;
                        $scope.data = {};
                        $scope.keepLive = false;
                    });

                };

                // Save a section and then update it
                $scope.saveAnswers = function (questionID, promises) {

                    $scope.loadingQuestions(true);
                    angular.forEach($scope.data.answers, function (answer) {
                        if (answer != null) {
                            var data = {
                                'ExamQuestionAnswerId': answer.ExamQuestionAnswerId,
                                'ExamQuestionId': questionID,
                                'ExamQuestionAnswer': answer.ExamQuestionAnswer,
                                'CorrectAnswer': answer.CorrectAnswer,
                                'EffectiveDate': $scope.data.EffectiveDate,
                                'EndDate': $scope.data.EndDate,
                                'IsActive': answer.IsActive,
                                'IsDeleted': answer.IsDeleted,
                                'Identifier': answer.Identifier
                            };
                            promises.push(examFactory.saveAnswer(sessionStorage.Key, data));
                        }
                    });
                };

                // Save a section and then update it
                $scope.saveConflicts = function (questionID, promises) {

                    $scope.loadingQuestions(true);
                    angular.forEach($scope.data.conflicts, function (conflict) {
                        var data = {
                            'ExamQuestionConflictId': conflict.ExamQuestionConflictId > -1 ? conflict.ExamQuestionConflictId : -1,
                            'ExamQuestionId': questionID,
                            'ExamQuestionConflictWithId': conflict.ExamQuestionConflictWithId,
                            'EffectiveDate': conflict.EffectiveDate == null ? $scope.data.EffectiveDate : conflict.EffectiveDate,
                            'EndDate': conflict.EndDate == null ? $scope.data.EndDate : conflict.EndDate,
                            'IsActive': conflict.IsActive,
                            'IsDeleted': false
                        };
                        promises.push(examFactory.saveConflict(sessionStorage.Key, data));
                    });
                };

                // "Delete" a question
                $scope.deleteQuestion = function () {
                    $scope.data = angular.copy($scope.list[$scope.selected]);
                    $scope.data["IsActive"] = false;
                    $scope.data["IsDeleted"] = true;
                    $scope.saveQuestion();
                };

                // Find the index of an array based on the table ID
                $scope.findIndex = function (id) {
                    return $scope.list.map(function (e) { return e.ExamQuestionId; }).indexOf(id);
                }

                // Find the category name based on it's table ID
                $scope.findCategory = function (id) {
                    var index = $scope.categories.map(function (e) { return e.ExamQuestionCategoryID; }).indexOf(id);
                    return index > -1 ? $scope.categories[index].ExamQuestionCategoryName : "Undefined";
                }

                $scope.findType = function (id) {
                    return $scope.types.map(function (e) { return e.ExamQuestionTypeId; }).indexOf(id);
                }

                $scope.findSection = function (id) {
                    return $scope.sections.map(function (e) { return e.ExamSectionID; }).indexOf(id);
                }

                // Add new entries to true false ------- (potential bounce bug fix later)
                $scope.newAnswer = function () {
                    if ($scope.data.answers == null) $scope.data.answers = [];
                    if ($scope.data.answers.length > 0) {
                        $scope.questionSwitch = true;
                    }
                    $scope.data.answers.push({
                        ExamQuestionAnswerId: -1,
                        ExamQuestionAnswer: null,
                        CorrectAnswer: null,
                        IsActive: true,
                        IsDeleted: false
                    });
                };

                // Sort by Column
                $scope.SortRecords = function (header) {
                    $scope.Pager.currentPage = 1;
                    if ($scope.Pager.sortType != header.sortType) {
                        $scope.Pager.sortType = header.sortType;
                        header.sortOrder = "asc";
                    }
                    else {
                        header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
                    }
                    $scope.Pager.sortOrder = header.sortOrder;
                    $scope.getData();
                }

                // Check if a directive exists
                $scope.directiveExists = function (name) {
                    return $injector.has(name);
                }

            }],
            templateUrl: 'app/components/individual/license/renewal/test-UI-tab-question-bank.html',
            replace: true
        };
    }]).

    // Multiple Choice Directive
    directive('multipleChoice', function () {
        return {
            require: '^questionBank',
            restrict: 'E',
            transclude: true,
            scope: { data: '=' },
            link: function (scope, element, attrs, questionBankCtrl) {

                scope.data.answers = [];
                scope.delete = {
                    deleteOption: false
                }
                scope.answerIndex = -1;
                scope.image = null;

                // delete answer confirmation
                scope.$watch('delete.deleteOption', function (newValue, oldValue) {
                    if (newValue == true) {
                        scope.data.answers[scope.answerIndex].IsActive = false;
                        scope.data.answers[scope.answerIndex].IsDeleted = true;
                    }
                    scope.delete.deleteOption = false;
                    scope.answerIndex = null;
                });

                // Add new entries to multiple choice
                scope.addChoices = function () {
                    var times = scope.data.answers.length == 0 ? 3 : 1;
                    for (var i = 0; i < times; i++) {
                        scope.data.answers.push({
                            ExamQuestionAnswerId: -1,
                            ExamQuestionAnswer: "",
                            CorrectAnswer: false,
                            IsActive: true,
                            IsDeleted: false
                        });
                    }
                };

                scope.updateSelection = function (position, entities) {
                    angular.forEach(entities, function (subscription, index) {
                        if (position != index)
                            subscription.CorrectAnswer = false;
                    });
                };

                scope.resetCheckbox = function (value) {
                    if (value == false) {
                        for (var i = 0; i < scope.data.answers.length; i++) {
                            scope.data.answers[i].CorrectAnswer = false;
                        }
                    }
                };


                scope.assignIndex = function (index) {
                    scope.answerIndex = index;
                }

            },
            controller: ["$scope", "examFactory", "$q", function ($scope, examFactory, $q) {

                $scope.resetImage = function () {
                    $scope.doc = null;
                    $scope.documentpager = {
                        ExamQuestionDocumentId: 0,
                        ExamQuestionId: 0,
                        Document: null,
                        IsActive: true,
                        IsDeleted: false,
                        ExamDocumentName: null
                    }
                };

                $scope.resetImage();

                $scope.UploadImage = function (input) {
                    $scope.documentpager.ExamDocumentName = input.files[0].name;
                    if (input.files && input.files[0]) {
                        var promise = $scope.CreateBlob(input.files[0]);
                        promise.then(function (res) {
                            if ($scope.data.image == null) {
                                $scope.data.image = {
                                    ExamDocumentName: input.files[0].name,
                                    ExamQuestionDocumentId: -1,
                                    Photo: res.split(',')[1]
                                }
                            }
                            else {
                                $scope.data.image.ExamDocumentName = input.files[0].name;
                                $scope.data.image.Photo = res.split(',')[1];
                            }
                        })
                    }
                }



                $scope.UploadSubmit = function () {
                    $scope.documentpager.ExamQuestionId = $scope.data.ExamQuestionId;
                    $scope.documentpager.Document = $scope.upload;
                    console.log($scope.documentpager);
                    examFactory.saveExamQuestionDocument(sessionStorage.Key, $scope.documentpager).then(function (res) {
                        console.log(res);
                        $scope.resetImage();
                    });
                }

                $scope.CreateBlob = function (file) {
                    var deferred = $q.defer();
                    var reader = new FileReader();
                    reader.onload = function () {
                        deferred.resolve(reader.result);
                    };
                    reader.readAsDataURL(file);
                    return deferred.promise;
                }


            }],
            templateUrl: 'app/components/individual/license/renewal/test-UI-tab-question-multi.html',
            replace: true
        };
    }).

    // True False Directive
    directive('trueFalse', function () {
        return {
            require: '^questionBank',
            restrict: 'E',
            transclude: true,
            scope: { data: '=' },
            link: function (scope, element, attrs, questionBankCtrl) {

                scope.data.answers = [];

                // Add new entries to multiple choice
                scope.newAnswer = function () {

                    scope.data.answers.push({
                        ExamQuestionAnswerId: -1,
                        ExamQuestionAnswer: null,
                        CorrectAnswer: false,
                        IsActive: true,
                        IsDeleted: false
                    });
                };

            },
            templateUrl: 'app/components/individual/license/renewal/test-UI-tab-question-true-false.html',
            replace: true
        };
    }).

    // Exam Management Directive
    directive('examManagement', function () {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                list: '=',
                types: '<',
                sections: '<',
                questions: '<',
                categories: '<'
            },
            link: function (scope) {
                scope.getData();
            },
            controller: ["$scope", "examFactory", "$q", function ($scope, examFactory, $q) {

                $scope.init = function () {

                    // Isolated Variables
                    $scope.data = {};
                    $scope.selected = null;
                    $scope.keepLive = false;
                    $scope.data.qlinks = [];
                    $scope.cutOffDate = new Date('12/31/1980');

                    // State Variables
                    $scope.deleteMode = false;
                    $scope.new = true;

                    // Pagination
                    $scope.Pager = {
                        sortType: 'ExamId',
                        sortOrder: 'asc',
                        currentPage: 1,
                        totalRecords: 10,
                        pageSize: 20,
                        maxSize: 5
                    }

                    // Headers
                    $scope.Headers = [
                        { name: 'Exam Number', sortType: 'ExamNumber', sortOrder: 'asc' },
                        { name: 'Exam Name', sortType: 'ExamName', sortOrder: 'asc' },
                        { name: 'Active Date', sortType: 'EffectiveDate', sortOrder: 'asc' },
                        { name: 'End Date', sortType: 'EndDate', sortOrder: 'asc' },
                        { name: 'Total Score', sortType: 'OverallScore', sortOrder: 'asc' },
                        { name: 'Passing Percentage', sortType: 'PassingScore', sortOrder: 'asc' },
                    ]

                    // Link Pagination
                    $scope.LinkPager = {
                        sortType: 'ExamSectionId',
                        sortOrder: 'asc',
                        currentPage: 1,
                        totalRecords: 10,
                        pageSize: 20,
                        maxSize: 5,
                        ExamId: null
                    }

                    // Link Headers
                    $scope.LinkHeaders = [
                        { name: 'Exam Section Name', sortType: 'ExamSectionId', sortOrder: 'asc' },
                        { name: 'Number of Questions', sortType: 'NoofQuestionfromSectioninExam', sortOrder: 'asc' },
                        { name: 'Section Number', sortType: 'ExamSectionNumber', sortOrder: 'asc' }
                    ]

                }

                // // If deleting from modal confirmation, then activate the delete method
                // $scope.$watch('deleteMode', function(newValue, oldValue) {
                //     if(newValue==true) {
                //         $scope.deleteData();
                //     } 
                //  });            

                // Set up Loader + Call Data Retrieval
                $scope.getData = function () {
                    $scope.loadingExams(true);
                    $scope.getExams();
                }

                // Retrieve Exams from Database and store locally
                $scope.getExams = function () {
                    examFactory.getAllExams(sessionStorage.Key, $scope.Pager).then(function (res) {
                        $scope.list = angular.copy(res.data.ExamList);
                        angular.forEach($scope.list, function (exam) {
                            //exam.Completed = false;
                            var EntityExamPager = {
                                sortType: 'ExamId',
                                sortOrder: 'asc',
                                currentPage: 1,
                                totalRecords: 10,
                                pageSize: 100,
                                maxSize: 5,
                                ExamId: exam.ExamId,
                                FromDate: null,
                                ToDate: null
                            }
                            examFactory.getEntityExams(sessionStorage.Key, EntityExamPager).then(function (res) {

                                if (res.data.ExamList.length > 0) exam.Completed = true;
                            });
                        })

                    }).finally(function () { $scope.loadingExams(false); });
                }

                $scope.newEntry = function () {
                    $scope.data = {
                        'ExamId': -1,
                        'ExamNumber': null,
                        'ExamName': null,
                        'ExamDesc': null,
                        'ExamQuestionText': null,
                        'OverallScore': null,
                        'PassingScore': null,
                        'ExamTimeLimit': null,
                        'EffectiveDate': null,
                        'EndDate': null,
                        'IsActive': true,
                        'IsDeleted': false,
                        'hours': 0,
                        'minutes': 0

                    }
                    $scope.new = true;
                    $scope.DVexamManage = true;
                    $scope.btnaddexam = true;
                }

                // Add new entries to the assigned list
                $scope.saveData = function () {
                    $scope.loadingExams(true);
                    $scope.saveExam($scope.data);
                }

                // Save Exam Entry to Database
                $scope.saveExam = function () {
                    var promises = [];
                    if ($scope.keepLive == true) $scope.data["EndDate"] = null;
                    $scope.data["ExamTimeLimit"] = "" + $scope.data.hours + ":" + $scope.data.minutes + ":00";

                    examFactory.saveExam(sessionStorage.Key, $scope.data).then(function (res) {
                        console.log(res);
                        if ($scope.new == true) {
                            angular.forEach($scope.sections, function (section) {
                                promises.push(examFactory.saveSectionLink(sessionStorage.Key,
                                    {
                                        ExamSectionLkId: -1,
                                        ExamId: res.data.ExamList[0].ExamId,
                                        ExamSectionId: section.ExamSectionID,
                                        NoofQuestionfromSectioninExam: null,
                                        SectionTimeLimit: null,
                                        IsActive: true,
                                        IsDeleted: false
                                    }
                                ));
                            });

                            $q.all(promises).then(function (res) {
                                console.log(res);
                                $scope.getExams();
                                $scope.deleteMode = false;
                                $scope.selected = null;
                                $scope.data = {};
                                $scope.keepLive = false;
                            });
                        }
                        else {
                            $scope.getExams();
                            $scope.deleteMode = false;
                            $scope.selected = null;
                            $scope.data = {};
                            $scope.keepLive = false;
                        }
                    });
                }

                $scope.editData = function (id, isClone) {
                    $scope.new = false;
                    $scope.editMode = true;
                    $scope.DVexamManage = true;
                    $scope.selected = $scope.findIndex(id);
                    $scope.data = angular.copy($scope.list[$scope.selected]);
                    if (!!isClone) {
                        $scope.data.ExamId = null;
                    }
                    if ($scope.data.EffectiveDate != null) {
                        $scope.data.EffectiveDate = new Date($scope.data.EffectiveDate);
                    }
                    if ($scope.data.EndDate != null) {
                        $scope.data.EndDate = new Date($scope.data.EndDate);
                    }
                    var str = $scope.data.ExamTimeLimit.split(':');
                    $scope.data.hours = Number(str[0]);
                    $scope.data.minutes = Number(str[1]);
                }

                // $scope.deleteData = function(){
                //     $scope.data = angular.copy($scope.list[$scope.selected]);
                //     $scope.data["IsActive"] = false;
                //     $scope.data["IsDeleted"] = true;        
                //     $scope.saveData();                
                // }

                // Save any edits only on button click
                $scope.editQuestions = function (id) {
                    var promises = [];
                    $scope.selected = $scope.findIndex(id);
                    $scope.data = angular.copy($scope.list[$scope.selected]);
                    $scope.LinkPager.ExamId = $scope.data.ExamId;
                    $scope.data.links = [];
                    $scope.data.qlinks = [];
                    $scope.data.linkRef = {};

                    $scope.loadingExams(true);
                    promises.push(examFactory.getSectionLink(sessionStorage.Key, $scope.LinkPager));
                    promises.push(examFactory.getQuestionLink(sessionStorage.Key, $scope.data.ExamId));
                    $q.all(promises).then(function (res) {

                        console.log(res);
                        if (!!res[0].data && !!res[0].data.ExamList) {
                            $scope.data.links = angular.copy(res[0].data.ExamList);
                            angular.forEach($scope.data.links, function (link, index) {
                                var str = link.SectionTimeLimit.split(':');
                                link.hours = Number(str[0]);
                                link.minutes = Number(str[1]);
                                $scope.data.linkRef[link.ExamSectionId] = index;
                            });
                        }

                        if (!!res[1].data && !!res[1].data.ExamList) {
                            $scope.data.qlinks = angular.copy(res[1].data.ExamList);
                        }

                        $scope.loadingExams(false);

                    }).finally(function () {
                        $scope.DVexamManage = false;
                        $scope.btnaddexam = false;
                        $scope.DVAssignSection = true;
                        $scope.AssignQuestionSection = true;
                    });
                }

                $scope.InitializeLink = function (ID, Status) {

                    if (!$scope.data.linkRef || !(ID in $scope.data.linkRef)) {
                        var length = $scope.data.links.push(
                            {
                                ExamSectionLkId: -1,
                                ExamId: $scope.data.ExamId,
                                ExamSectionId: ID,
                                NoofQuestionfromSectioninExam: null,
                                SectionTimeLimit: null,
                                IsActive: true,
                                IsDeleted: false
                            }
                        );
                        $scope.data.linkRef[ID] = length - 1;
                    }
                    else {
                        $scope.data.links[$scope.data.linkRef[ID]].IsActive = Status;
                    }
                }

                $scope.CheckActive = function (scope) {
                    if (scope.IsActive == false) {
                        scope.NoofQuestionfromSectioninExam = null;
                        scope.hours = null;
                        scope.minutes = null;
                    }
                }

                // Save any edits only on button click
                $scope.editComplete = function () {
                    var promises = [];
                    $scope.data.OverallScore = 0;

                    $scope.loadingExams(true);
                    angular.forEach($scope.data.links, function (link) {
                        link.SectionTimeLimit = "" + link.hours + ":" + link.minutes + ":00";
                        promises.push(examFactory.saveSectionLink(sessionStorage.Key, link));
                    });

                    $q.all(promises).then(function (res) {
                        console.log(res);
                        var result = [];
                        for (var i = 0; i < res.length; i++) result.push(res[i].data.ExamList[0]);
                        $scope.generateExam(result);
                    });
                }

                // Pre-generate the Exam
                $scope.generateExam = function (links) {
                    var promises = [];

                    angular.forEach(links, function (link, index) {
                        promises.push(examFactory.getQuestionsBySection(sessionStorage.Key, link.ExamSectionId, link.NoofQuestionfromSectioninExam));

                    });

                    $q.all(promises).then(function (res) {
                        console.log(res);
                        $scope.saveGeneratedQuestions(res);
                    });
                }

                $scope.saveGeneratedQuestions = function (questions) {
                    var promises = [];

                    // Update all Links to delete to generate new
                    angular.forEach($scope.data.qlinks, function (qlink, index) {
                        qlink.IsActive = false;
                        qlink.IsDeleted = true;
                        promises.push(examFactory.saveQuestionLink(sessionStorage.Key, qlink));
                    });

                    // Generate New Exam
                    for (var i = 0; i < questions.length; i++) {
                        angular.forEach(questions[i].data.ExamList, function (question, index) {
                            $scope.data.OverallScore = $scope.data.OverallScore + question.PointValue;
                            promises.push(examFactory.saveQuestionLink(sessionStorage.Key,
                                {
                                    'ExamSectionQuestionLkId': 0,
                                    'ExamSectionId': question.ExamSectionId,
                                    'ExamQuestionId': question.ExamQuestionId,
                                    'ExamId': $scope.data.ExamId,
                                    'EffectiveDate': new Date(),
                                    'IsActive': true,
                                    'IsDeleted': false
                                })
                            );

                        });
                    }


                    $q.all(promises).then(function (res) {
                        console.log(res);
                        examFactory.saveExam(sessionStorage.Key, $scope.data).then(function (res) {
                            $scope.selected = null;
                            $scope.DVAssignSection = false;
                            $scope.AssignQuestionSection = false;
                            $scope.getExams();
                        });
                    });
                }

                // Find the index of an array based on the table ID
                $scope.findIndex = function (id) {
                    return $scope.list.map(function (e) { return e.ExamId; }).indexOf(id);
                }

                $scope.findType = function (id) {
                    return $scope.types.map(function (e) { return e.ExamQuestionTypeId; }).indexOf(id);
                }

                $scope.findLink = function (id) {
                    return $scope.data.links.map(function (e) { return e.ExamSectionId; }).indexOf(id);
                }

                $scope.findSection = function (id) {
                    return $scope.sections.map(function (e) { return e.ExamSectionID; }).indexOf(id);
                }

                $scope.findQuestionLinks = function (id) {
                    return $scope.data.qlinks.map(function (e) { return e.ExamQuestionID; }).indexOf(id);
                }

                $scope.sum = function (items, prop) {
                    return items.reduce(function (a, b) {
                        return a + b[prop];
                    }, 0);
                };

                $scope.tickerCheck = function (scope) {
                    if (scope.minutes == 60) {
                        scope.hours == null ? 1 : scope.hour + 1;
                        scope.minutes = 0;
                    }
                }

                $scope.convertDate = function (datestr) {
                    var newdate = new Date(datestr);
                    return newdate;
                }

                $scope.setEndDate = function (status) {
                    if (status == false) $scope.data.EndDate = new Date();
                }

                // Sort by Column
                $scope.SortRecords = function (header) {
                    $scope.Pager.currentPage = 1;
                    if ($scope.Pager.sortType != header.sortType) {
                        $scope.Pager.sortType = header.sortType;
                        header.sortOrder = "asc";
                    }
                    else {
                        header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
                    }
                    $scope.Pager.sortOrder = header.sortOrder;
                    $scope.getData();
                }

            }],
            templateUrl: 'app/components/individual/license/renewal/test-UI-tab-management.html',
            replace: true
        };
    }).

    // Exam Configuration Directive
    directive('examConfiguration', function () {
        return {
            restrict: 'E',
            require: '^examManagement',
            transclude: true,
            scope: {
                exams: '='
            },
            controller: ["$scope", "examFactory", "$q", "$filter", "reportService", "ApiUrlsForReportsXls", function ($scope, examFactory, $q, $filter, reportService, ApiUrlsForReportsXls) {

                $scope.init = function () {

                    //Initialize Variables
                    $scope.SExamId = null;
                    $scope.SExamNum = null;
                    $scope.data = {};
                    $scope.list = [];
                    $scope.examinees = null;
                    $scope.examSelection = null;
                    $scope.scheduleSelection = null;
                    $scope.test = null;
                    $scope.links = null;
                    $scope.DOB = null;

                    // State Variables
                    $scope.deleteMode = false;

                    // Pagination
                    $scope.Pager = {
                        sortType: 'ExamId',
                        sortOrder: 'asc',
                        currentPage: 1,
                        totalRecords: 10,
                        pageSize: 20,
                        maxSize: 5,
                        ExamId: null,
                        ExamCompletion: false
                    }

                    // Link Pagination
                    $scope.LinkPager = {
                        sortType: 'ExamSectionId',
                        sortOrder: 'asc',
                        currentPage: 1,
                        totalRecords: 10,
                        pageSize: 20,
                        maxSize: 5,
                        ExamId: null
                    }

                    // Pagination
                    $scope.PartialPager = {
                        sortType: 'IndividualId',
                        sortOrder: 'asc',
                        currentPage: 1,
                        totalRecords: 10,
                        pageSize: 20,
                        maxSize: 5,
                        LastName: null,
                        FirstName: null,
                        LicenseTypeId: null,
                    }

                    $scope.DefaultQuestions = {
                        'EntityExamId': 0,
                        'QuestionId': 0,
                        'AnswerId': null,
                        'IsCorrect': null,
                        'IsActive': true,
                        'IsDeleted': false
                    }

                    // Headers
                    $scope.Headers = [
                        { name: 'Entity Name', sortType: 'EntityId', sortOrder: 'asc' },
                        { name: 'Application Number', sortType: 'ApplicationNumber', sortOrder: 'asc' },
                        { name: 'Exam Start Date', sortType: 'ExamStartDate', sortOrder: 'asc' },
                        { name: 'Exam End Date', sortType: 'ExamEndDate', sortOrder: 'asc' },
                        { name: 'Access Code', sortType: 'AccessCode', sortOrder: 'asc' },
                        { name: 'Pass/Fail', sortType: 'TotalScore', sortOrder: 'asc' }
                    ]
                };

                $scope.newEntry = function (status) {
                    $scope.data = {
                        'EntityExamId': -1,
                        'ExamId': $scope.SExamId,
                        'ExamNum': $scope.SExamNum,
                        'EntityId': null,
                        'EntityType': null,
                        'ExamStartDate': null,
                        'ExamEndDate': null,
                        'ApplicationId': null,
                        'LicenseId': null,
                        'ReferenceNumber': null,
                        'IsActive': true,
                        'IsDeleted': false,
                        'AccessCode': null,
                        'TimeLimit': null,
                        'TimeLeft': null
                    }

                    $scope.DVExamconfigure = status;
                    if (status == true) {
                        $scope.selected = null;
                        $scope.PartialPager.LastName = null;
                        $scope.PartialPager.ApplicationNumber = null;
                        $scope.PartialPager.FirstName = null;
                    }
                }

                // If deleting from modal confirmation, then activate the delete method
                $scope.$watch('deleteMode', function (newValue, oldValue) {
                    if (newValue == true) {
                        $scope.deleteData();
                    }
                });

                // Add new entries to the assigned list
                $scope.saveData = function () {
                    $scope.exams[$scope.configurationIndex].configuration = {};
                    $scope.exams[$scope.configurationIndex].configuration = angular.copy($scope.entry);
                    $scope.configurationDetails = {};
                    $scope.configurationIndex = -1;
                    this.DVExamconfigure = false;
                }

                // Save any edits only on button click
                $scope.editData = function (ID) {
                    $scope.examSelection = $scope.findExam(ID);
                    $scope.Pager.ExamId = ID;
                    $scope.getData();
                    $scope.ConfigurationUI = true;
                }

                $scope.manageSchedule = function (ID) {

                    // Capture the Exam Location
                    $scope.scheduleSelection = $scope.findIndex(ID);
                    $scope.data = angular.copy($scope.list[$scope.scheduleSelection]);

                    // Modify Data for display
                    if ($scope.data.ExamStartDate != null) $scope.data.ExamStartDate = new Date($scope.data.ExamStartDate);
                    if ($scope.data.ExamEndDate != null) $scope.data.ExamEndDate = new Date($scope.data.ExamEndDate);
                    $scope.PartialPager.LastName = null;
                    $scope.PartialPager.FirstName = null;
                    $scope.PartialPager.ApplicationNumber = $scope.data.ApplicationNumber

                    // Set up loader
                    $scope.loadingConfigurations(true);

                    // Call Services
                    console.log($scope.PartialPager);
                    examFactory.getEntityExaminees(sessionStorage.Key, $scope.PartialPager).then(function (res) {
                        $scope.selected = angular.copy(res.data.ExamList[0]);
                        $scope.DOB = angular.copy(res.data.ExamList[0].DateOfBirth);
                        $scope.DOB = $filter('date')($scope.DOB, "dd/MM/yyyy");
                        $scope.PartialPager.ApplicationNumber = null;
                        $scope.PartialPager.FirstName = angular.copy(res.data.ExamList[0].FirstName);
                        $scope.PartialPager.LastName = angular.copy(res.data.ExamList[0].LastName);
                        $scope.loadingConfigurations(false);
                    });
                    $scope.DVExamconfigure = true;
                }

                // Save any edits only on button click
                $scope.manageComplete = function () {

                    // Set up Multi-Calls
                    var promises = [];

                    var generateFlag = false;
                    $scope.loadingConfigurations(true);
                    $scope.data.TimeLimit = $scope.exams[$scope.examSelection].ExamTimeLimit;
                    $scope.data.TimeLeft = $scope.exams[$scope.examSelection].ExamTimeLimit;
                    if ($scope.data.AccessCode == "" || $scope.data.AccessCode == null) {
                        $scope.data.AccessCode = $scope.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
                        generateFlag = true;
                    }

                    // Check if the exam has been altered at all and generate if it has been
                    if ($scope.data.ExamCompletion != true && !!$scope.links && !!$scope.links.length > 0 && $scope.links[0].ModifiedOn >= $scope.data.ModifiedOn) {
                        examFactory.deleteQuestionsEntityExam(sessionStorage.Key, $scope.data.EntityExamId).then(function (res) {
                            console.log(res);
                        });
                        generateFlag = true;
                    }

                    promises.push(examFactory.saveEntityExam(sessionStorage.Key, $scope.data));
                    // promises.push(examFactory.getQuestionLink(sessionStorage.Key,$scope.exams[$scope.examSelection].ExamId));

                    $q.all(promises).then(function (res) {
                        console.log(res);
                        $scope.scheduleSelection = $scope.findIndex(res[0].data.ExamList[0].EntityExamId);
                        $scope.scheduleEntry = angular.copy(res[0].data.ExamList[0]);
                        // $scope.links = angular.copy(res[1].data.ExamList);

                        if (generateFlag == true) $scope.generateExam();
                        else {
                            $scope.getEntityExamData();
                            $scope.DVExamconfigure = false;
                            $scope.scheduleSelection = null;
                            $scope.selected = null;
                            $scope.PartialPager.LastName = null;
                            $scope.PartialPager.FirstName = null;
                            $scope.PartialPager.ApplicationNumber = null;
                        }
                    });
                }

                // Set Loader and Retrieve Entity Data from Database
                $scope.getData = function () {
                    $scope.loadingConfigurations(true);
                    $scope.getEntityExamData();
                }

                // Send Request and Parse Data from Database
                $scope.getEntityExamData = function () {
                    var promises = [];

                    promises.push(examFactory.getEntityExams(sessionStorage.Key, $scope.Pager));
                    promises.push(examFactory.getQuestionLink(sessionStorage.Key, $scope.exams[$scope.examSelection].ExamId));

                    $q.all(promises).then(function (res) {
                        $scope.list = angular.copy(res[0].data.ExamList);
                        $scope.links = angular.copy(res[1].data.ExamList);
                        $scope.loadingConfigurations(false);
                    });
                }

                // Pre-generate the Exam
                $scope.generateExam = function () {
                    var promises = [];
                    angular.forEach($scope.links, function (link, index) {
                        promises.push(examFactory.saveEntityExamQA(sessionStorage.Key,
                            {
                                'EntityExamId': $scope.scheduleEntry.EntityExamId,
                                'QuestionId': link.ExamQuestionId,
                                'AnswerId': null,
                                'IsCorrect': null,
                                'IsActive': true,
                                'IsDeleted': false
                            }));
                    });

                    $q.all(promises).then(function (res) {
                        $scope.getEntityExamData();
                        $scope.DVExamconfigure = false;
                        $scope.scheduleSelection = null;
                        $scope.selected = null;
                        $scope.PartialPager.LastName = null;
                        $scope.PartialPager.FirstName = null;
                        $scope.PartialPager.ApplicationNumber = null;
                    });
                }

                // Type Ahead Dynamic Call
                $scope.typeAheadCall = function () {
                    $scope.PartialPager.LicenseTypes = $scope.exams[$scope.examSelection].LicenseTypes;
                    $scope.loadingPartial(true);
                    var p = examFactory.getEntityExaminees(sessionStorage.Key, $scope.PartialPager).then(function (res) {
                        var Examinees = angular.copy(res.data.ExamList);
                        $scope.examinees = angular.copy(res.data.ExamList);
                        $scope.loadingPartial(false);
                        return Examinees;
                    });
                    return p;
                }

                $scope.typeAheadUpdate = function (value) {
                    $scope.data["ApplicationId"] = angular.copy(value.ApplicationId);
                    $scope.data["EntityId"] = angular.copy(value.IndividualId);
                    $scope.data["EntityType"] = "I";
                    $scope.DOB = angular.copy(value.DateOfBirth);
                    $scope.DOB = $filter('date')($scope.DOB, "dd/MM/yyyy");
                    $scope.PartialPager.FirstName = angular.copy(value.FirstName);
                    $scope.PartialPager.LastName = angular.copy(value.LastName);
                }

                $scope.deleteData = function () {
                    $scope.data = angular.copy($scope.list[$scope.scheduleSelection]);
                    $scope.data["IsActive"] = false;
                    $scope.data["IsDeleted"] = true;
                    $scope.manageComplete();
                }

                // Find the index of an array based on the table ID
                $scope.findIndex = function (id) {
                    return $scope.list.map(function (e) { return e.EntityExamId; }).indexOf(id);
                }

                $scope.findExam = function (id) {
                    return $scope.exams.map(function (e) { return e.ExamId; }).indexOf(id);
                }

                // Create Random Access String
                $scope.randomString = function (length, chars) {
                    var result = '';
                    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
                    return result;
                }

                $scope.passOrFail = function (top, bottom) {
                    var testratio = top / bottom;
                    var result = ""
                    if ($scope.exams[$scope.examSelection].PassingScore > testratio) result = "Fail"
                    else result = "Pass"
                    return result;
                }

                // Sort by Column
                $scope.SortRecords = function (header) {
                    $scope.Pager.currentPage = 1;
                    if ($scope.Pager.sortType != header.sortType) {
                        $scope.Pager.sortType = header.sortType;
                        header.sortOrder = "asc";
                    }
                    else {
                        header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
                    }
                    $scope.Pager.sortOrder = header.sortOrder;
                    $scope.getData();
                }

                $scope.DownloadPDF = function (Id) {
                    var ExamPager = {
                        sortType: 'IndividualId',
                        sortOrder: 'asc',
                        currentPage: 1,
                        totalRecords: 10,
                        pageSize: 20,
                        maxSize: 5,
                        ExamId: $scope.Pager.ExamId,
                        EntityExamId: null
                    }
                    $scope.loadingConfigurations(true);
                    console.log(ExamPager);
                    reportService.getXLSForVeteranDataReport(sessionStorage.Key, ExamPager, ApiUrlsForReportsXls.pdfExam).then(function (res) {
                        console.log(res);
                        $scope.loadingConfigurations(false);
                    });
                }

            }],
            templateUrl: 'app/components/individual/license/renewal/test-UI-tab-configuration.html',
            replace: true
        };
    }).

    // Exam Configuration Directive
    directive('deleteConfirm', ['$uibModal', function ($uibModal) {
        return {
            restrict: 'A',
            scope: {
                deleteEvent: '='
            },
            link: function ($scope, element, attrs) {
                element.click(function () {
                    $scope.resolution = false;
                    $scope.animationsEnabled = true;

                    var modalInstance = $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: 'app/components/individual/license/renewal/test-UI-confirmation-modal.html',
                        controller: function ($scope, $uibModalInstance) {
                            $scope.choice = false;
                            $scope.ok = function () {
                                $scope.choice = true;
                                $uibModalInstance.close($scope.choice);
                            };

                            $scope.cancel = function () {
                                $scope.choice = false;
                                $uibModalInstance.close($scope.choice);
                            };
                        }
                    });

                    modalInstance.result.then(function (response) {
                        $scope.resolution = response;
                        if ($scope.resolution == true) {
                            $scope.deleteEvent = true;
                        }
                    }, function () { });
                });
            }
        };
    }]).
    // Question Section Directive
    directive('examScheduler', function () {
        return {
            restrict: 'E',
            transclude: true,
            scope: {},
            link: function (scope) {
                scope.getData(true);
            },

            controller: ["$scope", "examFactory", "$q", function ($scope, examFactory, $q) {

                $scope.init = function () {

                    // Initialize Variables
                    $scope.data = {};
                    $scope.selected = null;
                    $scope.examineeSelection = null;
                    $scope.dateOptions = { format: 'MM/DD/YYYY' };
                    $scope.keepLive = false;

                    // Set up state for deleting
                    $scope.deleteMode = false;
                    $scope.deleteExaminee = false;

                    // Pagination
                    $scope.Pager = {
                        sortType: 'EntityName',
                        sortOrder: 'asc',
                        currentPage: 1,
                        totalRecords: 10,
                        pageSize: 20,
                        maxSize: 5,
                        ExamLocationLkId: null
                    }

                    // Pagination
                    $scope.ExamPager = {
                        sortType: 'ExamId',
                        sortOrder: 'asc',
                        currentPage: 1,
                        totalRecords: 10,
                        pageSize: 20,
                        maxSize: 5
                    }

                    // Pagination
                    $scope.PartialPager = {
                        sortType: 'IndividualId',
                        sortOrder: 'asc',
                        currentPage: 1,
                        totalRecords: 10,
                        pageSize: 20,
                        maxSize: 5,
                        LastName: null,
                        ApplicationNumber: null
                    }

                    // Headers
                    $scope.Headers = [
                        { name: 'Location', sortType: 'ExamLocation', sortOrder: 'asc' },
                        { name: 'Exam Name', sortType: 'ExamName', sortOrder: 'asc' },
                        { name: 'From Date', sortType: 'ExamStartDate', sortOrder: 'asc' },
                        { name: 'To Date', sortType: 'ExamEndDate', sortOrder: 'asc' },
                        { name: 'Total Slots', sortType: 'MaximumSlots', sortOrder: 'asc' }
                    ]

                    // Headers
                    $scope.ExamineeHeaders = [
                        { name: 'Name', sortType: 'EntityName', sortOrder: 'asc' },
                        { name: 'Application Number', sortType: 'ApplicationNumber', sortOrder: 'asc' },
                        { name: 'Application Type', sortType: 'ApplicationType', sortOrder: 'asc' },
                        { name: 'Result Status', sortType: 'ResultStatus', sortOrder: 'asc' },
                    ]

                    $scope.ResultStatus = [
                        { id: 0, status: "Not Processed" },
                        { id: 1, status: "Pass" },
                        { id: 2, status: "Fail" }
                    ]
                }

                // If deleting from modal confirmation, then activate the delete method
                $scope.$watch('deleteMode', function (newValue) {
                    if (newValue == true) {
                        $scope.deleteLocation();
                    }
                });

                // If deleting from modal confirmation, then activate the delete method
                $scope.$watch('deleteExaminee', function (newValue) {
                    if (newValue == true) {
                        $scope.deleteEntityExam();
                    }
                });

                // Refresh a new data entry everytime the add button is clicked
                $scope.newEntry = function () {
                    $scope.data = {
                        ExamLocationLkId: -1,
                        ExamLocationId: null,
                        ExamId: null,
                        ExamStartDate: null,
                        ExamEndDate: null,
                        MaximumSlots: null,
                        IsActive: true,
                        IsDeleted: false
                    }
                }

                // Refresh a new data entry everytime the add button is clicked
                $scope.newExaminee = function () {
                    $scope.data.NewExaminee = {
                        EntityExamId: -1,
                        ExamId: $scope.data.ExamId,
                        ExamNum: $scope.exams[$scope.findExam($scope.data.ExamId)].ExamNumber,
                        EntityId: null,
                        EntityType: null,
                        ApplicationId: null,
                        ExamStartDate: $scope.data.ExamStartDate,
                        ExamEndDate: $scope.data.ExamEndDate,
                        ResultStatus: 0,
                        ExamLocationLkId: $scope.data.ExamLocationLkId,
                        IsActive: true,
                        IsDeleted: false
                    }
                }

                // Save any edits only on button click
                $scope.editData = function (id) {
                    $scope.dvaddexamsec = true;
                    $scope.btnaddexamsec = true;
                    $scope.selected = $scope.findIndex(id);
                    $scope.data = angular.copy($scope.LocationTimes[$scope.selected]);
                    $scope.data.ExamEndDate = new Date($scope.data.ExamEndDate);
                    $scope.data.ExamStartDate = new Date($scope.data.ExamStartDate);
                }

                // Save any edits only on button click
                $scope.editExaminees = function (id) {
                    $scope.selected = $scope.findIndex(id);
                    $scope.data = angular.copy($scope.LocationTimes[$scope.selected]);
                    $scope.data.ExamEndDate = new Date($scope.data.ExamEndDate);
                    $scope.data.ExamStartDate = new Date($scope.data.ExamStartDate);
                    $scope.getExaminees();
                    $scope.ConfigurationUI = true;

                }

                // Get Examinees
                $scope.getExaminees = function () {
                    var promises = []
                    $scope.Pager.ExamLocationLkId = angular.copy($scope.data.ExamLocationLkId);
                    $scope.loadingScheduler(true);
                    promises.push(examFactory.getEntityExams(sessionStorage.Key, $scope.Pager));
                    $q.all(promises).then(function (res) {
                        $scope.data.Examinees = angular.copy(res[0].data.ExamList);
                        if (res[0].data.ExamList.length > 0) {
                            $scope.Pager.totalRecords = res[0].data.ExamList[0].TotalRecord;
                        }
                        $scope.loadingScheduler(false);
                    });
                }

                // Set up Loader and call the Section Retrieval Function
                $scope.getData = function (mode) {
                    $scope.loadingScheduler(true);
                    $scope.getInitialData(mode);
                }

                // Retrieve Section Data from Database
                $scope.getInitialData = function (mode) {
                    var promises = []
                    promises.push(examFactory.getExamLocationLk(sessionStorage.Key));
                    if (mode == true) promises.push(examFactory.getRefExamLocation(sessionStorage.Key));
                    if (mode == true) promises.push(examFactory.getAllExams(sessionStorage.Key, $scope.ExamPager));

                    $q.all(promises).then(function (res) {
                        $scope.LocationTimes = res[0].data.ExamList;
                        if (mode == true) $scope.Locations = res[1].data.ExamList;
                        if (mode == true) $scope.exams = res[2].data.ExamList;
                        $scope.loadingScheduler(false);
                    });
                };

                // Type Ahead Dynamic Call
                $scope.typeAheadCall = function (partial) {
                    $scope.PartialPager.LastName = angular.copy(partial);
                    $scope.PartialPager.ApplicationNumber = null;
                    var p = examFactory.getEntityExaminees(sessionStorage.Key, $scope.PartialPager).then(function (res) {
                        var Examinees = angular.copy(res.data.ExamList);
                        $scope.examinees = angular.copy(res.data.ExamList);
                        return Examinees;
                    });
                    return p;
                }

                // Update Entity Exam
                $scope.typeAheadUpdate = function (value) {
                    $scope.data.NewExaminee["ApplicationId"] = angular.copy(value.ApplicationId);
                    $scope.data.NewExaminee["EntityId"] = angular.copy(value.IndividualId);
                    $scope.data.NewExaminee["EntityType"] = "I";
                }

                // Save a section and then update it
                $scope.saveLocation = function () {
                    $scope.loadingScheduler(true);
                    examFactory.saveExamLocationLk(sessionStorage.Key, $scope.data).then(function (res) {
                        $scope.getInitialData(false);
                        $scope.data = {};
                        $scope.deleteMode = false;
                        $scope.deleteExaminee = false;
                        $scope.selected = null;
                        $scope.examineeSelection = null;
                    });
                };

                // Save Examinee
                $scope.saveExaminee = function () {
                    $scope.loadingScheduler(true);
                    examFactory.saveEntityExam(sessionStorage.Key, $scope.data.NewExaminee).then(function (res) {
                        $scope.getExaminees();
                        $scope.data.NewExaminee = {};
                        $scope.deleteMode = false;
                        $scope.deleteExaminee = false;
                        $scope.examineeSelection = null;
                    });
                };

                $scope.editExaminee = function (ID) {
                    $scope.examineeSelection = $scope.findExaminee(ID);
                    $scope.data.NewExaminee = angular.copy($scope.data.Examinees[$scope.examineeSelection]);
                    $scope.PartialPager.LastName = null;
                    $scope.PartialPager.ApplicationNumber = $scope.data.NewExaminee.ApplicationNumber
                    $scope.loadingScheduler(true);
                    examFactory.getEntityExaminees(sessionStorage.Key, $scope.PartialPager).then(function (res) {
                        $scope.view = angular.copy(res.data.ExamList[0]);
                        $scope.loadingScheduler(false);
                    });
                    $scope.DVExamconfigure = true;
                }

                // "Delete" a location link
                $scope.deleteLocation = function () {
                    $scope.data = angular.copy($scope.LocationTimes[$scope.selected]);
                    $scope.data["IsActive"] = false;
                    $scope.data["IsDeleted"] = true;
                    $scope.saveLocation();
                };

                // "Delete" an examinee at a location
                $scope.deleteEntityExam = function () {
                    $scope.data.NewExaminee = angular.copy($scope.data.Examinees[$scope.examineeSelection]);
                    $scope.data.NewExaminee["IsActive"] = false;
                    $scope.data.NewExaminee["IsDeleted"] = true;
                    $scope.saveExaminee();
                };

                // Find the index of the list when the ID matches
                $scope.findIndex = function (id) {
                    return $scope.LocationTimes.map(function (e) { return e.ExamLocationLkId; }).indexOf(id);
                }

                // Find the index of the list when the ID matches
                $scope.findExaminee = function (id) {
                    return $scope.data.Examinees.map(function (e) { return e.EntityExamId; }).indexOf(id);
                }

                // Find the index of the list when the ID matches
                $scope.findExam = function (id) {
                    return $scope.exams.map(function (e) { return e.ExamId; }).indexOf(id);
                }

                // Sort by Column
                $scope.SortRecords = function (header) {
                    $scope.Pager.currentPage = 1;
                    if ($scope.Pager.sortType != header.sortType) {
                        $scope.Pager.sortType = header.sortType;
                        header.sortOrder = "asc";
                    }
                    else {
                        header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
                    }
                    $scope.Pager.sortOrder = header.sortOrder;
                    $scope.getData();
                }

            }],
            templateUrl: 'app/components/individual/license/renewal/test-UI-tab-scheduler.html',
            replace: true
        };
    })



// Filters
// Filter to clean HTML Text
angular.module('natApp').filter('htmlToPlaintext', function () {
    return function (text) {
        return text ? String(text).replace(/<[^>]+>/gm, '') : '';
    };
}
);

// General Controller for the Test-UI
angular.module('natApp').controller("TestUITabController", ["$scope", "$controller",
    function ($scope, $controller) {

        $scope.init = function () {

            //
            console.log(sessionStorage);

            $scope.dataStructures = {
                categories: [],
                sections: [],
                question: {},
                questions: [],
                exams: [],
                types: [],
                configurations: []
            };

            // Editor Options
            $scope.options = {
                language: 'en',
                allowedContent: true,
                entities: false,
                disableNativeSpellChecker: false,
                scayt_autoStartup: false
            };

            // Editor Function when loaded
            $scope.onReady = function () {
                // ...
            };

        };

        $scope.destroyEditor = function () {
            for (name in CKEDITOR.instances) { CKEDITOR.instances[name].destroy(); }
        }
    }]);