(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintQuestionnaire', [ComplaintQuestionnaireDirective]);

    function ComplaintQuestionnaireDirective() {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                complaint: '=',
                currentResponses: '=?',
                linkId: '=?',
                linkType: '=?'
            },
            link: function ($scope, element, attrs) {
                $scope.tabIndex = attrs.tabIndex;
                $scope.configName = attrs.configName;
                $scope.mode = (!!attrs.mode) ? attrs.mode : 'input'; //Can be 'input' or 'output'
                $scope.complaintId = $scope.$eval(attrs.complaintId);
                $scope.questionNumberBase = $scope.$eval(attrs.questionNumberBase);
                if (!$scope.questionNumberBase) {
                    $scope.questionNumberBase = 1;
                }
                $scope.$watch(function () { return $scope.$parent.$eval(attrs.isRequired); }, function (newVal) {
                    $scope.isRequired = newVal;
                });
            },
            templateUrl: function (element, attrs) {
                if (!attrs.mode || attrs.mode == 'input' || attrs.mode == 'inputWithGet') {
                    return 'app/components/backoffice/enforcement/complaint-questionnaire/complaint-questionnaire.input.html'
                } else if (attrs.mode == 'outputlist') {
                    return 'app/components/backoffice/enforcement/complaint-questionnaire/complaint-questionnaire.outputlist.html'
                } else if (attrs.mode == 'outputform') {
                    return 'app/components/backoffice/enforcement/complaint-questionnaire/complaint-questionnaire.outputform.html'
                }
            },
            controller: 'ComplaintQuestionnaireController'
        };
    }
})();