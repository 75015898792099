(function () {
    'use strict'

    angular.module('app.backofficeAdministor')
    .controller('contentManagementAddController', ['$scope', '$rootScope', '$q', 'contentManagementAddService', 'utilityService', 'Messages', contentManagementAddController]);

    function contentManagementAddController($scope, $rootScope, $q, contentManagementAddService, utilityService, Messages) {
        var _key = sessionStorage.Key;

        // Initialize Variables
        $scope.itemCodes = [];
        $scope.Content = {};
        $scope.netValidator = {};
        $scope.ContentItemHashs = [];

        // Getting Data for ItemCode and ItemHash Inputs
        contentManagementAddService.getContent(_key).then(function(data){
            if(data && data.ContentItemLk){
                $scope.itemCodes = data.ContentItemLk.map(function(item){
                    return item.ContentItemLkCode;
                }).filter(function(element, index, array){
                    return array.indexOf(element) === index;
                })
                $scope.itemHashs = {};
                data.ContentItemLk.forEach(function(item) {
                    if(!$scope.itemHashs[item.ContentItemLkCode]){
                        $scope.itemHashs[item.ContentItemLkCode] = [];
                    }
                    if($scope.itemHashs[item.ContentItemLkCode].indexOf(item.ContentItemHash) < 0){
                        $scope.itemHashs[item.ContentItemLkCode].push(item.ContentItemHash);
                    }
                    
                })
            }
        })
        
        // Get ContentTypeNameId
        contentManagementAddService.getContentTypeName(_key).then(function(data){
            if(data){
                $scope.ContentLkToPageTabSection = data.ContentLkToPageTabSection;
            }
        })
        // Watch user to pick appropriate Hash Array Selection
        $scope.$watch('Content.ContentItemLkCode', function(newValue, oldValue){
            if(newValue)
            $scope.ContentItemHashs = $scope.itemHashs[newValue]
            if($scope.ContentItemHashs && $scope.ContentItemHashs.length == 1){
                $scope.Content.ItemHash = $scope.ContentItemHashs[0]
            }
        })
 

        // Form Validation
        var validateForm = function (form, error) {
            $scope.netValidator.errorMessages = [];
            if (!!form) {
                form.$submitted = true;
                var errorMessages = angular.copy(form.errorMessages);
                angular.forEach(errorMessages, function (errorMessage, index) {
                    $scope.netValidator.errorMessages.push(errorMessage);
                });
            }
            if (error.length > 0) {
                $scope.netValidator.errorMessages = $scope.netValidator.errorMessages.concat(error);
            }

            if (!!$scope.netValidator.errorMessages && $scope.netValidator.errorMessages.length > 0) {
                $scope.netValidator.showError = true;
                $scope.netValidator.isValid = false;
                return false;
            } else {
                $scope.netValidator.showError = false;
                $scope.netValidator.isValid = true;
                return true;
            }
        };


        // Save Form to Database
        $scope.SaveContentItem = function (form) {
            var error = [];
            if(!$scope.Content.ContentItemLkCode){
                error.push({
                    propName:"natrequired-ContentItemLkCode", 
                    errorMessage:Messages.efbceae,
                })
            }
            if(!$scope.Content.ContentItemHash){
                error.push({
                    propName:"natrequired-ContentItemHash",
                    errorMessage:Messages.ffafcaa
                });
            }
            if (!validateForm(form, error)) {
                return;
            }
            if ($scope.Content) {
                
                ShowLoader();
                contentManagementAddService
                    .createContent(_key, $scope.Content)
                    .then(function(data){
                        HideLoader();
                        $scope.Content = {};
                        $scope.addNewContentFlag = false;
                        $scope.$emit("FillContentGrid", {});
                });

            }

        };

        $scope.chkCustom = function(checked){
            if(checked){
                $scope.retainCodeSelect = $scope.Content.ContentItemLkCode;
                $scope.retainHashSelect = $scope.Content.ContentItemHash;
                $scope.Content.ContentItemLkCode = !!$scope.retainCodeCustom ? $scope.retainCodeCustom : null;
                $scope.Content.ContentItemHash = !!$scope.retainHashCustom ? $scope.retainHashCustom : null;
            }else{
                $scope.retainCodeCustom = $scope.Content.ContentItemLkCode;
                $scope.retainHashCustom = $scope.Content.ContentItemHash;
                $scope.Content.ContentItemLkCode = !!$scope.retainCodeSelect ? $scope.retainCodeSelect : null;
                $scope.Content.ContentItemHash = !!$scope.retainHashSelect ? $scope.retainHashSelect : null;
            }
        }


        //Cancel Content Entry
        $scope.CancelContent = function(){
            $scope.addNewContentFlag = false;
            $scope.Content = {}
        }
    }
})();