(function() {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('applicationIndividualSearch', [ApplicationIndividualSearchDirective]);

    function ApplicationIndividualSearchDirective() {
        return {
            restrict: 'E', 
            scope: {
                individualSelected: '&'
            },
            link: function ($scope, element, attrs) {

            },
            templateUrl: 'app/components/backoffice/application/application-individual-search/application-individual-search.html',
            controller: 'ApplicationIndividualSearchController'
        };
    }
})();