(function() {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('personalAppearanceValidation', ['Messages', PersonalAppearanceValidationDirective]);

    function PersonalAppearanceValidationDirective(Messages) {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function () {
                    var valid = true;

                    $scope.natValidation.showError = false;
                    $scope.natValidation.errorMessages = [];
                    
                    if (!$scope.current.appearance.PersonalAppearanceLocationId) {
                        $scope.natValidation.showError = true;
                        $scope.natValidation.errorMessages.push({
                            propName: 'PersonalAppearanceLocationId',
                            errorMessage: Messages.General.Errors.SelectLocation
                        });                      
                    }

                    return valid;  
                };
            }
        };
    }
})();