(function () {
    'use strict'

    angular.module('app.account')
        .directive('loginStaff', loginStaffDirective);

    loginStaffDirective.$inject = [];

    function loginStaffDirective() {
        return {
            restrict: 'E',
            scope: {
                isStaffLoginView: "=?"
            },
            templateUrl: 'app/components/account/login/staff/login-staff.html',
            controller: 'StaffLoginController'
        };
    }
})();