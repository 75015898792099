(function () {
    angular
        .module('app.core')
        .controller("DashboardNotificationController", DashboardNotificationController);

    DashboardNotificationController.$inject = ["$scope", "$window", "dashboardNotificationService", "userPortalViewService"];

    function DashboardNotificationController($scope, $window, dashboardNotificationService, userPortalViewService) {

        $scope.viewConfig = {
            showAddBlock: false
        };

        $scope.invalidFiles = []
        $scope.closeFileError = function () {
            $scope.invalidFiles = []
        }

        $scope.isCorespondenceEdit = false;
        $scope.sortType = 'CommunicationLogDate';
        $scope.sortOrder = '-';

        $scope.changeSort = function (sortType) {
            if ($scope.sortType == sortType) {
                if ($scope.sortOrder == '+')
                    $scope.sortOrder = '-';
                else
                    $scope.sortOrder = '+';
            } else {
                $scope.sortOrder = '+';
            }
            $scope.sortType = sortType;
        };

        $scope.init = function () {
           // $scope.ProviderId = sessionStorage.ProviderId;

            if (!!$scope.providerId && $scope.providerId != 'null')
            {   
                $scope.notificationProviderId = $scope.providerId ;  
                $scope.getUserDetails();  
                     
 
            }
            else if (!!$scope.individualId)
            {
                $scope.individualCorrespondenceByIndividualId(true);
            }
            
        };

        $scope.openAddEditCorrespondence = function (individualCorrespondence) {

        };

        $scope.individualCorrespondenceByIndividualId = function (hideMessages) {
            dashboardNotificationService.individualCorrespondenceByIndividualId(sessionStorage.Key, $scope.individualId)
                .then(function (response) {
                    if (hideMessages) {
                        $scope.hideStatusMsg();
                    }
                    if (response.StatusCode == "00") {
                        if (!!response.IndividualCommunicationLogRequest) {
                            $scope.individualCorrespondences = response.IndividualCommunicationLogRequest.whereNotEquals('Reference', 'CommunicationSource').orderByDescending('IndividualCommunicationLogId');
                        } else {
                            $scope.individualCorrespondences = [];
                        }
                    } else {
                        $scope.individualCorrespondences = null;
                        $scope.showStatusMsg("-", response.Message);
                    }
                    $scope.loadingCorrespondence(false);
                }, function (data) {
                    $scope.showStatusMsg("-", data);
                });
        };
        
        $scope.getUserDetails = function () {
            if(!!sessionStorage.isBackOfficeUser)
            {
                var Urlparm = '/' + sessionStorage.Key + '?ProviderId=' + $scope.providerId + '&UserId=' + sessionStorage.UserID;
                userPortalViewService.userByProviderIdExternal(Urlparm).then(function (response) {
                    if (response.Status) {
                        if (!!response.Users) {
                            $scope.userEmail = response.Users?.Email;
                            $scope.providerCorrespondenceByProviderId(true);  
                        }
                    }
                });
            }
            else{
                dashboardNotificationService.getUser(sessionStorage.Key, sessionStorage.UserId).then(function(response) {
                    $scope.currentUser = response.Users;
                    $scope.userEmail = response.Users.Email;
                    $scope.providerCorrespondenceByProviderId(true);  
                });
            }
        };

        $scope.providerCorrespondenceByProviderId = function (hideMessages) {
            dashboardNotificationService.providerCorrespondenceByProviderId(sessionStorage.Key, $scope.notificationProviderId)
                .then(function (response) {
                    if (hideMessages) {
                        $scope.hideStatusMsg();
                    }
                    if (response.StatusCode == "00") {
                        if (!!response.IndividualCommunicationLogRequest) {
                            $scope.individualCorrespondences = response.IndividualCommunicationLogRequest.whereNotEquals('Reference', 'CommunicationSource').orderByDescending('IndividualCommunicationLogId');
                            $scope.individualCorrespondences = $scope.individualCorrespondences.filter(function (item) {
                                if (item.EmailTo == $scope.userEmail)
                                return item;
                            });
                            // console.log('10', $scope.processedIndividualCorrespondences);
                            
                        } else {
                            $scope.individualCorrespondences = [];
                        }
                    } else {
                        $scope.individualCorrespondences = null;
                        $scope.showStatusMsg("-", response.Message);
                    }
                    $scope.loadingCorrespondence(false);
                }, function (data) {
                    $scope.showStatusMsg("-", data);
                });
        };
        $scope.showNotification = function (item) {
            if (item) {
                var win = $window.open("", item.Subject, "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600, top=" + (100) + ", left=" + 240);
                var printButtonStyle = '\n<link href="{0}/app/core/directive/dashboard-payment-directive/dashboard-payment.css" rel="stylesheet" />\n'.format(window.origin);
                var printButton = '\n<button class="print-button no-print" onclick="window.print()">Print</button>\n';
                win.document.head.innerHTML = printButtonStyle
                win.document.body.innerHTML = item.CommunicationText ? printButton + item.CommunicationText : '';
            }
        };

        $scope.init();
    }
})();