(function () {
  'use strict'
  angular.module('app.InitialApplication')
    .directive('initialApplicationLicenseType', initialApplicationLicenseTypeDirective);

  initialApplicationLicenseTypeDirective.$inject = [];

  function initialApplicationLicenseTypeDirective() {
    return {
      restrict: 'E',
      scope: {

      },
      templateUrl: "app/components/individual/application/directives/license-type/license-type.html",
      controller: "InitialApplicationLicenseTypeController"
    }
  }
})();