(function () {
  'use strict'
  angular.module('app.report')
    .controller('VeteranDataReportController', VeteranDataReportController);

  VeteranDataReportController.$inject = ['$scope', '$q', '$controller', '$filter',
    'reportService', '$state', 'utilityService', 'ApiUrlsForReports', 'ApiUrlsForReportsXls'];

  function VeteranDataReportController($scope, $q, $controller, $filter, reportService, $state, utilityService, ApiUrlsForReports, ApiUrlsForReportsXls) {

    // Pagination
    $scope.Pager = {
      sortType: 'LicenseNumber',
      sortOrder: 'asc',
      currentPage: 1,
      totalRecords: 10,
      pageSize: 20,
      maxSize: 5
    }
    $scope.Headers = [
      { name: 'First Name', sortType: 'FirstName', sortOrder: 'asc', class: 'w90' },
      { name: 'Last Name', sortType: 'LastName', sortOrder: 'asc', class: 'w90' },
      { name: 'DoB', sortType: 'DateOfBirth', sortOrder: 'asc', class: 'w120' },
      { name: 'Street', sortType: 'StreetLine1', sortOrder: 'asc', class: 'w90' },
      { name: 'City', sortType: 'City', sortOrder: 'asc', class: 'w80' },
      { name: 'State', sortType: 'StateCode', sortOrder: 'asc', class: 'w60' },
      { name: 'Zip', sortType: 'Zip', sortOrder: 'asc', class: 'w50' },
      { name: 'Service Branch', sortType: 'ServiceBranch', sortOrder: 'asc' },
      { name: 'Speciality', sortType: 'Speciality', sortOrder: 'asc', class: 'w60' },
      { name: 'Service From', sortType: 'ServiceFrom', sortOrder: 'asc', class: 'w100' },
      { name: 'Service To', sortType: 'ServiceTo', sortOrder: 'asc', class: 'w90' }
    ]
    $scope.getVeteranDataReportList = function () {
      try {
        if ($scope.isUserSessionActive()) {
          ShowLoader('Please Wait...')
          reportService.veteranDataReportWithPager(sessionStorage.Key, $scope.Pager, ApiUrlsForReports.veteranData)
            .then(function (response) {
              if ($scope.checkServerResponse(response)) {
                $scope.Pager.totalRecords = response.PagerVM.TotalRecords;
                $scope.VeteranData = response.PagerVM.Records;
              }
              HideLoader();
              utilityService.scrollTop();
            }, function (data) {
              HideLoader()
              showStatusMessage(data, 'error')
            })
        } else {
          $state.go('app.Loginwoemail')
        }
      } catch (ex) {
        HideLoader()
        if (ex != null) { showStatusMessage(ex.message, 'error'); }
      }
    }

    $scope.getVeteranDataReportList();

    $scope.SortRecords = function (header) {
      $scope.Pager.currentPage = 1;
      $scope.Pager.sortType = header.sortType;
      $scope.Pager.sortOrder = header.sortOrder;
      header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
      $scope.getVeteranDataReportList();
    }
    $scope.getExcelForVeteranDataReport = function () {
      try {
        ShowLoader('Please Wait...')
        reportService.getXLSForVeteranDataReport(sessionStorage.Key, $scope.Pager, ApiUrlsForReportsXls.veteranData)
          .then(function (response) {
            HideLoader();
          }, function (data) {
            HideLoader()
            showStatusMessage(data, 'error')
          })
      } catch (ex) {
        HideLoader()
        if (ex != null) { showStatusMessage(ex.message, 'error'); }
      }
    }
  };
})();

