(function() {
    'use strict'
    angular.module('app.core')
    .controller('ProviderContactController', ['$scope', '$state', '$q', 'providerContactService', '$uibModal',
    'Messages', 'dcaIService', 'UserAccountService', 'userLinkingService', 'LoginwemailService',
            function($scope, $state, $q, providerContactService, $uibModal, Messages, dcaIService, UserAccountService, userLinkingService, LoginwemailService) {
                var LoggedUserKey = sessionStorage.Key;
                var LoggedUserID = sessionStorage.UserId;
                $scope.providerId = sessionStorage.ProviderId; 
                $scope.natValidation = {};
                var init = function() {
                    getContactDetails();
                    ContactTypeGetAll();
                    // if (sessionStorage.LoginPage == 'app.BackofficeLoginstaff') {
                    //     userLinkingService.getUserByIndividualId(sessionStorage.Key, (sessionStorage.IndividualId ? sessionStorage.IndividualId : $scope.individualid)).then(function (response) {
                    //         if (response.Users) {
                    //             LoggedUserID = response.Users.UserId;
                    //         }
                    //     });
                    // }
                };

                $scope.$on("popupConfigSaved", function(event, args) {
                    getContactDetails();
                });
            
                var getContactDetails = function() {
                    providerContactService.GetContactDetails(LoggedUserKey, $scope.providerId).then(function(res) {
                        if (res.data) {
                            $scope.ContactDetailsList = res.data.ProviderContacts;
                            $scope.contactsLoaded = true;
                            setContactType();
                        }
                    });
                };

                var ContactTypeGetAll = function() {
                    providerContactService.ContactTypeGetAll(LoggedUserKey).then(function(res) {
                        if (res.data) {
                            $scope.ContactTypeAll = res.data.ContactTypeGetList;
                            $scope.contactTypeLoaded = true;
                            setContactType();
                        }
                    });
                };

                var setContactType = function() {
                    if ($scope.contactsLoaded && $scope.contactTypeLoaded) {
                        var requiredContactTypes = {
                            21: { exists: false },
                            23: { exists: false }
                        };
                        if ($scope.ContactTypeAll && $scope.ContactDetailsList) {
                            for (var i = 0; i < $scope.ContactDetailsList.length; i++) {
                                for (var j = 0; j < $scope.ContactTypeAll.length; j++) {
                                    if ($scope.ContactTypeAll[j].ContactTypeId == $scope.ContactDetailsList[i].ContactTypeId) {
                                        $scope.ContactDetailsList[i].ContactTypeDesc = $scope.ContactTypeAll[j].Desc;
                                    }
                                }
                                if ($scope.ContactDetailsList[i].ContactStatusId == 1) {
                                    requiredContactTypes[$scope.ContactDetailsList[i].ContactTypeId].exists = true;
                                }
                            }
                            for (var contactType in requiredContactTypes) {
                                if (!requiredContactTypes[contactType].exists) {
                                    var contact = {
                                        ContactTypeId: contactType,
                                        ContactStatusId: 1
                                    };
                                    for (var j = 0; j < $scope.ContactTypeAll.length; j++) {
                                        if ($scope.ContactTypeAll[j].ContactTypeId == contact.ContactTypeId) {
                                            contact.ContactTypeDesc = $scope.ContactTypeAll[j].Desc;
                                        }
                                    }
                                    $scope.ContactDetailsList.push(contact);
                                }
                            }
                        }
                        HideLoader();
                    }
                };

                $scope.updateIServiceData = function(requestData, serviceType) {
                    var dataPromise = null;
                    if (serviceType == "Phone") {
                        requestData.serviceType = "Phone";
                        dataPromise = dcaIService.updatePhoneForFirms(sessionStorage.Key, requestData);
                    } else if (serviceType == "Email") {
                        requestData.serviceType = "Email";
                        dataPromise = dcaIService.updateEmailForFirms(sessionStorage.Key, requestData);
                    } else if (serviceType == "Address") {
                        requestData.serviceType = "Email";
                        dataPromise = dcaIService.updateAddressForFirms(sessionStorage.Key, requestData);
                    }

                    dataPromise
                        .then(function(responseUpdate) {
                            if (!responseUpdate.Status) {

                            }
                        }, function(err) {});
                };

                var saveContactAndSendOtp = function() {
                    ShowLoader();
                    $scope.isValidEmail = true;
                    $scope.ContactDetailsToEdit.IsActive = true;
                    $scope.ContactDetailsToEdit.IsDeleted = false;
                    $scope.ContactDetailsToEdit.ContactStatusId = 1;
                    $scope.ContactDetailsToEdit.ProviderId = $scope.providerId;
                    $scope.ContactDetailsToEdit.ProviderContactId = null;

                    providerContactService.SaveContactDetails("/" + LoggedUserKey + '?Source=', [$scope.ContactDetailsToEdit])
                        .then(function(response) {
                            response = response.data;
                            if (!!response.Status) {
                                $scope.ContactDetailsToEdit.RecordId = response.RecordId;
                                var verificationRequest = {
                                    Email: $scope.ContactDetailsToEdit.ContactInfo,
                                    ProviderId: $scope.providerId,
                                    FromProviderContactId: $scope.OriginalContactDetailsToEdit.ProviderContactId,
                                    ToProviderContactId: response.RecordId,
                                    UserId: LoggedUserID,
                                };
                                UserAccountService.saveAndSendOTPForFirms(sessionStorage.Key, verificationRequest)
                                    .then(function(response) {
                                        if (!response.Status) {
                                            $scope.isValidEmail = false;
                                            $scope.natValidation.errorMessages = [{
                                                propName: 'otpEmailFailed',
                                                errorMessage: 'Some error occurred, Please try again later.'
                                            }];
                                            $scope.natValidation.showError = true;
                                            HideLoader();
                                        } else {
                                            HideLoader();
                                            $scope.ContactDetailsToEdit.enableVerification = true;
                                            $scope.openConfirmationPopup('emailChangeConfirmation.html');
                                        }
                                    });
                            }
                        }, function() {
                            HideLoader()
                        });
                };


                var validateAndSendOTP = function() {
                    UserAccountService.ValidateEmailExistForFirmUser(encodeURIComponent($scope.ContactDetailsToEdit.ContactInfo), sessionStorage.Key, $scope.providerId)
                        .then(function(response) {
                            if (!response.Status) {
                                $scope.isValidEmail = false;
                                $scope.natValidation.errorMessages = [{
                                    propName: 'invalidEmailAddress',
                                    errorMessage: 'The email address is not available.'
                                }];
                                $scope.natValidation.showError = true;
                                HideLoader();
                            } else {
                                saveContactAndSendOtp();
                            }
                        });
                };

                var confirmContactDetails = function(form) {
                    //validate form
                    if (!validateForm(null, form)) {
                        return;
                    } else {
                        ShowLoader();
                        var verificationRequest = {
                            Email: $scope.ContactDetailsToEdit.ContactInfo,
                            OTPValue: $scope.ContactDetailsToEdit.VerificationCode,
                            ProviderId: $scope.providerId,
                            FromProviderContactId: $scope.OriginalContactDetailsToEdit.ProviderContactId,
                            UserId: LoggedUserID,
                        };
                        UserAccountService.verifyOTPAndProcessContactForFirms(sessionStorage.Key, verificationRequest)
                            .then(function(response) {
                                if (!response.Status) {
                                    $scope.natValidation.errorMessages = [{
                                        propName: 'otpEmailFailed',
                                        errorMessage: 'Invalid code, Please try again.'
                                    }];
                                    $scope.natValidation.showError = true;
                                    HideLoader();
                                } else {
                                    var requestData = {
                                        EntityId: $scope.providerId,
                                        ProviderContactId: $scope.ContactDetailsToEdit.RecordId,
                                        
                                    };
                                    $scope.updateIServiceData(requestData, "Email");                                   
                                    $scope.ContactDetailsToEdit = undefined;
                                    getContactDetails();
                                    HideLoader();
                                    $scope.openConfirmationPopup('emailChangeAndLogoutMessage.html');
                                    LoginwemailService.logout();
                                    sessionStorage.clear();
                                    $state.go('app.FirmLogin');
                                }
                            });
                    }
                };

                var saveContactDetails = function(form) {
                    //validate form
                    if (!validateForm(null, form)) {
                        return;
                    } else if ($scope.ContactDetailsToEdit.ContactTypeId == 23) {
                        validateAndSendOTP();
                    } else {
                        ShowLoader();
                        //if new contact
                        $scope.ContactDetailsToEdit.IsActive = true;
                        $scope.ContactDetailsToEdit.IsDeleted = false;
                        if ($scope.ContactDetailsToEdit && !!$scope.ContactDetailsToEdit.ProviderContactId) {
                            $scope.ContactDetailsToEdit.ActionFlag = "UI";
                        }
                        $scope.ContactDetailsToEdit.ContactStatusId = 1;
                        $scope.ContactDetailsToEdit.ProviderId = $scope.providerId;

                        providerContactService.SaveContactDetails("/" + LoggedUserKey + '?Source=', [$scope.ContactDetailsToEdit]).then(function(response) {
                            if ($scope.ContactDetailsToEdit.ContactTypeId == "21") {
                                var requestData = {
                                    EntityId: $scope.providerId,
                                    ProviderContactId: response.data.RecordId
                                };
                                $scope.updateIServiceData(requestData, "Phone");
                            } 
                            $scope.ContactDetailsToEdit = undefined;
                            getContactDetails();
                            HideLoader();
                        }, function() {
                            HideLoader()
                        });
                    }
                };

                $scope.BtnClickEvent = function(cmd, item, form) {
                    switch (cmd) {
                        case "edit":
                            {
                                $scope.ContactDetailsToEdit = {};
                                setTimeout(function() {
                                    $scope.ContactDetailsToEdit = angular.copy(item);
                                    $scope.OriginalContactDetailsToEdit = angular.copy(item);
                                    $scope.contactTypeChanged(item.ContactTypeId);
                                    $scope.$apply();
                                    angular.element("#contactType").focus()
                                }, 1000);
                                break;
                            }
                        case "save":
                            {
                                saveContactDetails(form);
                                break;
                            }
                        case "confirm":
                            {
                                confirmContactDetails(form);
                                break;
                            }
                        case "cancel":
                            {
                                $scope.ContactDetailsToEdit = undefined;
                                break;
                            }
                        case "delete":
                            {
                                if (confirm(Messages.General.Confirm.Delete)) {
                                    ShowLoader();
                                    item.IsDeleted = true;
                                    providerContactService.SaveContactDetails("/" + LoggedUserKey + '?Source=', [item]).then(function() {
                                        getContactDetails();
                                        HideLoader();
                                    }, function() {
                                        HideLoader()
                                    });
                                }
                                break;
                            }
                        case "previous":
                            {
                                if (confirm(Messages.General.Confirm.Delete)) {
                                    ShowLoader();
                                    item.ContactStatusId = 2;
                                    providerContactService.SaveContactDetails("/" + LoggedUserKey + '?Source=', [item]).then(function() {
                                        getContactDetails();
                                    }, function() {
                                        HideLoader()
                                    });
                                }
                                break;
                            }

                    }
                };

                var validateForm = function(currentFormValidation, currentForm) {
                    $scope.natValidation.errorMessages = [];
                    if (!!currentForm) {
                        currentForm.$submitted = true;
                        var veteranErrorMessages = angular.copy(currentForm.errorMessages);
                        angular.forEach(veteranErrorMessages, function(errorMessage, index) {
                            if (($scope.isPhoneType && (errorMessage.propName == 'natrequired-workPhone' || errorMessage.propName == 'natphone-workPhone')) ||
                                ($scope.isEmailType && (errorMessage.propName == 'natemail-workEmail' || errorMessage.propName == 'natrequired-workEmail')) ||
                                ($scope.isWebsite && (errorMessage.propName == 'natrequired-website' || errorMessage.propName == 'naturl-website')) ||
                                ($scope.isDefaultType && errorMessage.propName == 'natrequired-contactval') ||
                                (errorMessage.propName == 'natrequired-contacttype')) {
                                $scope.natValidation.errorMessages.push(errorMessage);
                            }
                        });
                        if (!$scope.OriginalContactDetailsToEdit || $scope.ContactDetailsToEdit.ContactInfo == $scope.OriginalContactDetailsToEdit.ContactInfo) {
                            $scope.natValidation.errorMessages.push({
                                propName: 'sameContactInfo',
                                errorMessage: 'Contact information same as previous.'
                            });
                        }
                    }

                    if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                        $scope.natValidation.showError = true;
                        $scope.natValidation.isValid = false;
                        return false;
                    } else {
                        $scope.natValidation.showError = false;
                        $scope.natValidation.isValid = true;
                        return true;
                    }
                };

                $scope.contactTypeChanged = function(typeID) {
                    if (typeID) {
                        $scope.isPhoneType = false;
                        $scope.isEmailType = false;
                        $scope.isDefaultType = false;
                        $scope.isWebsite = false;
                        switch (parseInt(typeID)) {
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 9:
                            case 11:
                            case 12:
                            case 14:
                            case 15:
                            case 30:
                            case 21:
                            case 27:
                                {
                                    $scope.isPhoneType = true;
                                    break;
                                }
                            case 8:
                            case 23:
                            case 24:
                            case 26:
                            case 13:
                            case 16:
                            case 18:
                            case 19:
                            case 29:
                                {
                                    $scope.isEmailType = true;
                                    break;
                                }
                            case 10:
                            case 17:
                                {
                                    $scope.isWebsite = true;
                                    break;
                                }
                            default:
                                {
                                    $scope.isDefaultType = true;
                                    break;
                                }

                        }
                    } else {
                        $scope.isWebsite = false;
                        $scope.isPhoneType = false;
                        $scope.isEmailType = false;
                        $scope.isDefaultType = true;
                    }
                };

                $scope.contactTypeChanged();

                $scope.FilterContactStatus = function(contact) {
                    return (contact.ContactStatusId == 1)
                };

                $scope.openConfirmationPopup = function(templateUrlParam) {
                    $uibModal.open({
                        animation: true,
                        templateUrl: templateUrlParam,
                        size: 'md',
                        controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
                            $scope.onCancel = function() {
                                $uibModalInstance.dismiss('cancel');
                            };
                            $scope.onConfirm = function() {
                                $uibModalInstance.close('success');
                            };
                        }]
                    });
                };

                init();
            }
        ]);
})();