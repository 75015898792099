﻿(function () {
    'use strict'
    angular.module('app.core')
        .factory('DashboardPaymentFactory', ['$http', 'WebApiUrl', '$q', 'AppConfig', function ($http, WebApiUrl, $q, AppConfig) {
            var baseFactory = {};
            baseFactory.GetPaymentDetails = function (key, individualId, providerId, isProvider) {

                var urlParam = key + '?IndividualId=' + individualId + '&ProviderId=&Source=CADCABPELSGDASHBOARD';
                if (isProvider) {
                    urlParam = key + '?IndividualId=&ProviderId=' + providerId + '&Source=CADCABPELSGDASHBOARD';
                }
                return $http.get(WebApiUrl + 'Payment/GetPaymentDetailsByIndividualId/' + urlParam);
            };

            baseFactory.GetLicenseTypes = function (key) {
                var urlParams = {
                    tableKey: 'licensetype'
                };

                return $http.get(WebApiUrl + 'TypeValues/RefTablesGetAll/' + key + '/', { params: urlParams });
            };
            return baseFactory;
        }]);
})();
