(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationVerificationController", InitialApplicationVerificationController);

    InitialApplicationVerificationController.$inject = ["$rootScope", "$scope", "$q",
        "initialApplicationVerificationService",
        "initialApplicationstatusService", "utilityService", "countryStateService", "typeValuesService", "WebApiUrl", "AppConfig", "TypeValue", "contentService", "Messages"];

    function InitialApplicationVerificationController($rootScope, $scope, $q,
        initialApplicationVerificationService,
        initialApplicationstatusService, utilityService, countryStateService, typeValuesService, WebApiUrl, AppConfig, TypeValue, contentService, Messages) {

        if (AppConfig.EnableConsoleLogging)
            console.log('Verification : ', $scope.verification);

        $scope.previous = {};
        function AuditVisitInfoSave() {
            var data = {
                "PageName": $scope.verificationConfig.Content.TabHeading,
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };

        function AuditVisitInfoSave() {
            var data = {
                "PageName": $scope.tab.name,
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        }

        $scope.init = function () {
            $scope.natValidation;
            try {
                $scope.viewConfig = {};

                $scope.documentUploadOutOfState = {
                    ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                    ApplicationId: $scope.applicationId,
                    IndividualId: $scope.individualId,
                    Email: null,
                    SendEmail: null,
                    TransactionId: null,
                    LicenseeReprint: false,
                    DocumentLkToPageTabSectionId: 1,
                    DocumentLkToPageTabSectionCode: "R",
                    LoadByDocIdAndDocCode: true,
                    DocId: 67,
                    DocCode: "D1067",
                    LoadDocumentsWithoutBroadCast: true
                };
                getContent();
                loadInitialData();
                AuditVisitInfoSave();

            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
                //    $exceptionHandler(ex);
            }
        };

        //Out Of State Licensure Experience Starts here
        $scope.setOutOfStateLicForm = function (outOfStateLicForm) {
            $scope.outOfStateLicForm = outOfStateLicForm;
        };

        $scope.closeAddEditOutOfStateLic = function () {
            $scope.viewConfig.showAddOutOfStateLicBlock = false;
            $scope.outOfStateLicForm = null;
        };

        $scope.openAddEditOutOfStateLic = function (isEdit, individualOutOfStateLic) {
            $scope.isOutOfStateLicEdit = isEdit;
            $scope.viewConfig.showAddOutOfStateLicBlock = true;
            if (!$scope.isOutOfStateLicEdit) {
                $scope.individualOutOfStateLic = {
                    IndividualId: $scope.individualId,
                    IsActive: true,
                    UseId: sessionStorage.UserID,
                    ApplicationId: $scope.applicationId
                };
            } else {
                $scope.individualOutOfStateLic = angular.copy(individualOutOfStateLic);
            }
        };

        $scope.getOutOfStateLicInfo = function () {
            initialApplicationVerificationService.getIndividualPreviousLicense(sessionStorage.Key, $scope.individualId, $scope.applicationId, $scope.userId)
                .then(function (response) {
                    if (response.Status) {
                        $scope.individualOutOfStateLicList = response.List;
                        angular.forEach($scope.individualOutOfStateLicList, function (individualOutOfStateLicItem) {
                            angular.forEach($scope.licenseStatusList, function (licenseStatusItem) {
                                if (licenseStatusItem.LicenseStatusTypeId == individualOutOfStateLicItem.LicenseStatusTypeId) {
                                    individualOutOfStateLicItem.LicenseStatusTypeName = licenseStatusItem.LicenseStatusTypeName;
                                }
                            });
                        });
                    } else {
                        ShowErrMsg("experienceNotLoaded", response.Message);
                    }
                    HideLoader();
                }, function (error) {
                    HideLoader();
                    ShowErrMsg("experienceNotLoaded", error.Message);
                });
        };

        $scope.saveOutOfStateLic = function (outOfStateLicForm) {
            try {
                if (!validateForm(outOfStateLicForm)) {
                    return;
                };
                ShowLoader("Please Wait...");
                angular.forEach($scope.statList, function (stateItem) {
                    if (stateItem.StateCode == $scope.individualOutOfStateLic.StateCode) {
                        $scope.individualOutOfStateLic.StateName = stateItem.Name;
                    }
                });

                initialApplicationVerificationService.saveIndividualPreviousLicense($scope.individualOutOfStateLic, $scope.individualId, $scope.applicationId, $scope.userId, sessionStorage.Key)
                    .then(function (response) {
                        if (response.Status && response.StatusCode == "00") {
                            $scope.getOutOfStateLicInfo();
                            $scope.viewConfig.showAddOutOfStateLicBlock = false;
                        } else {
                            HideLoader();
                            ShowErrMsg("SaveIndividualOutOfStateLic", response.Message);
                        }
                    });
            } catch (ex) {
                HideLoader();
                ShowErrMsg("SaveIndividualOutOfStateLic", ex.message);
            }
        };

        $scope.deleteOutOfStateLic = function (individualOutOfStateLic) {
            try {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    ShowLoader();
                    individualOutOfStateLic.IsDeleted = true;
                    initialApplicationVerificationService.saveIndividualPreviousLicense(individualOutOfStateLic, $scope.individualId, $scope.applicationId, $scope.userId, sessionStorage.Key)
                        .then(function (response) {
                            if (response.Status && response.StatusCode == "00") {
                                $scope.getOutOfStateLicInfo();
                            }
                            HideLoader();
                        }, function (data) {
                            HideLoader();
                            ShowErrMsg();
                        });
                }
            } catch (ex) {
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        };
        //Out Of State Licensure Experience Ends here

        //Teaching Experience Starts here
        $scope.loadCountyByState = function (stateCode, processLoaderExternally) {
            if (!processLoaderExternally)
                ShowLoader();
            var dataPromise = initialApplicationVerificationService.countyGetAll(sessionStorage.Key, stateCode);
            dataPromise.then(function (response) {
                if (response.Status) {
                    $scope.countyList = response.County;
                } else {
                    ShowErrMsg("countyGetAll", response.Message);
                }
                if (!processLoaderExternally)
                    HideLoader();
            }, function (error) {
                HideLoader();
                ShowErrMsg("countyGetAll", error.Message);
            });
            return dataPromise;
        };

        $scope.loadDistrictByState = function (stateCode, countyCode, processLoaderExternally) {
            if (!processLoaderExternally)
                ShowLoader();
            var params = "?CountyCode=" + countyCode + "&AddressState=" + stateCode;
            var dataPromise = typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefDistrictGet, sessionStorage.Key, true, params);
            dataPromise.then(function (response) {
                if (response.Status) {
                    $scope.districtList = response.RefDistrictList;
                } else {
                    ShowErrMsg("refDistrictGet", response.Message);
                }
                if (!processLoaderExternally)
                    HideLoader();
            }, function (error) {
                HideLoader();
                ShowErrMsg("refDistrictGet", error.Message);
            });
            return dataPromise;
        };

        $scope.loadSchoolByDistrict = function (countyCode, districtCode, processLoaderExternally) {
            if (!processLoaderExternally)
                ShowLoader();
            var params = "?CountyCode=" + countyCode + "&DistrictCode=" + districtCode;
            var dataPromise = typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefSchoolGet, sessionStorage.Key, true, params);
            dataPromise.then(function (response) {
                if (response.Status) {
                    $scope.schoolList = response.RefSchoolList;
                } else {
                    ShowErrMsg("refSchoolGet", response.Message);
                }
                if (!processLoaderExternally)
                    HideLoader();
            }, function (error) {
                HideLoader();
                ShowErrMsg("refSchoolGet", error.Message);
            });
            return dataPromise;
        };

        var loadDropDownsData = function (individualExperience) {
            ShowLoader();
            var dataLoadPromises = [];

            if (!!individualExperience.StateCd) {
                dataLoadPromises.push($scope.loadCountyByState(individualExperience.StateCd, true));
                if (!!individualExperience.CountyCd) {
                    dataLoadPromises.push($scope.loadDistrictByState(individualExperience.StateCd, individualExperience.CountyCd, true));
                    if (!!individualExperience.DistrictCd)
                        dataLoadPromises.push($scope.loadSchoolByDistrict(individualExperience.CountyCd, individualExperience.DistrictCd, true));
                }
            }

            if (dataLoadPromises.length > 0) {
                $q.all(dataLoadPromises).then(function (response) {
                    $scope.viewConfig.showAddExperienceBlock = true;
                    HideLoader();
                }, function (error) {
                    HideLoader();
                });
            } else {
                $scope.viewConfig.showAddExperienceBlock = true;
                HideLoader();
            }
        };




        $scope.loadTypeahed = function (searchString) {
            return initialApplicationVerificationService.getEmployerList(
                sessionStorage.Key, searchString, 1, 'Name'
            ).then(function (response) {
                if (!!response.Employers) {
                    return response.Employers.map(function (item) {
                        item.formattedResult = (!!item.EmployerName ? item.EmployerName : '');             // item.formattedResult = ((!!item.EmployerName ? item.EmployerName : '')
                        return item;
                    });
                }
            }, function (res) {

            });
        };


        var loadInitialData = function () {
            var dataLoadPromises = [
                countryStateService.getStateByCountryID(sessionStorage.Key, $rootScope.DefaultCountryId),
                typeValuesService.getTypeValuesByTypeName(TypeValue.LicenseStatus, sessionStorage.Key),
                // initialApplicationVerificationService.getIndividualPreviousLicense(sessionStorage.Key, $scope.individualId, $scope.applicationId, $scope.userId),
            ];
            $q.all(dataLoadPromises).then(function (response) {
                //  console.log(response);
                $scope.isConfigLoaded = true;
                $scope.statList = response[0].State;
                $scope.licenseStatusList = response[1].LicenseStatusTypeGetList;
                // $scope.individualOutOfStateLicList = response[2].List;
                $scope.getOutOfStateLicInfo();
                HideLoader();
            },
                function (error) {
                    HideLoader();
                    ShowErrMsg("licenseEndorsementConfigNotLoaded", "Some error occurred,Unable to load the License/Endorsement config");
                });
        };

        var ShowErrMsg = function (propName, errorMessage) {
            propName = (!!propName) ? propName : "personalErr";
            errorMessage = (!!errorMessage) ? errorMessage : $scope.personalConfig.ValidationMsg.APIError;
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            var propExist = false;
            for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                if ($scope.natValidation.errorMessages[i].propName == propName)
                    propExist = true;
            }
            if (!propExist)
                $scope.natValidation.errorMessages.push({
                    propName: propName,
                    errorMessage: errorMessage
                });

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        // $scope.setForm = function (outOfStateLicForm) {
        //     $scope.outOfStateLicForm = outOfStateLicForm;
        // };

        var validateForm = function (outOfStateLicForm) {
            $scope.natValidation.errorMessages = [];
            if (!!outOfStateLicForm) {
                outOfStateLicForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(outOfStateLicForm.errorMessages);
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        }


        $scope.saveAndNext = function (isOutOfStateLic) {
            $scope.openNextTab({ currentTab: $scope.tab });
        };

        $scope.getVerificationConfig = function () {
            var relativeFilePath = "/individual/application/verification/verification.server-config.json";
            return utilityService.getConfigData(relativeFilePath);
        };

        $scope.$on("SaveRenewal", function (event, args) {
            $scope.saveAndNext($scope.outOfStateLicForm);
        });

        var getContent = function () {
            contentService.getContentByContentItemLkIdList([5], sessionStorage.Key)
                .then(function (response) {
                    if (response.Status) {
                        _.filter(response.ContentItemLk, function (item) {
                            if (item.ContentItemLkId == 5)
                                $scope.headingContent = item.ContentItemLkDesc;
                        });
                    }
                }, function (error) {

                });
        }

        $scope.init();
    }
})();