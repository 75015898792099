﻿angular.module('natApp').controller("ucPaymentController", ["$scope", "$filter", "$controller", "ProcessPaymentService", "IndividualDocumentSaveService", "LicenseRenewalHASnAService", "$state", "$timeout",
    function ($scope, $filter, $controller, ProcessPaymentService, IndividualDocumentSaveService, LicenseRenewalHASnAService, $state, $timeout) {
        $controller('GlobalController', { $scope: $scope });
        // Variables
        $scope.ApplicationNumber = "";
        $scope.InvoiceNumber = "";
        $scope.PaymentMethod = "";
        // $scope.ApplicationFee = "";
        $scope.ConvenienceFee = "";
        // $scope.LateFee = "";
        $scope.CardNumber = "";
        $scope.CVV = "";
        $scope.ExpirationMonth = "";
        $scope.ExpirationYear = "";
        $scope.FirstName = "";
        $scope.LastName = "";
        $scope.Street = "";
        $scope.City = "";
        $scope.State = "";
        $scope.Zip = "";
        $scope.Country = "";
        $scope.MonthList = [];
        $scope.YearList = [];
        $scope.CountryList = [];
        $scope.PaymentReceiptFlag = false;
        $scope.PrintLicenseFlag = false;
        $scope.ApplicationRenewalFlag = false;
        $scope.DocumentUploadList = [];
        $scope.IndividualName = "";
        $scope.LicenseTypeName = "";

        $scope.ReferenceNumber = "";
        $scope.TransactionDate = "";

        $scope.OriginalLicenseDate = "";
        $scope.LicenseExpirationDate = "";
        $scope.LicenseNumber = "";
        $scope.ApplicationName = "";
        $scope.FeesDetailObj = [];
        $scope.DisplayMessage = "";
        $scope.MoneyOrderNo = "";
        $scope.MoneyOrderComment = "";
        // Init
        $scope.payment_init = function () {
            if (sessionStorage.PaymentDone == "true") { }

            if ($scope.isSessionActive()) {
                angular.element(document.querySelector("#PaymentDiv")).hide();
                angular.element(document.querySelector("#moneyOrderID")).hide();

                if (sessionStorage.PaymentMethodType == "CC") {
                    angular.element(document.querySelector("#PaymentDiv")).show();
                    angular.element(document.querySelector("#moneyOrderID")).hide();
                } else if (sessionStorage.PaymentMethodType == "MO" || sessionStorage.PaymentMethodType == "CP" || sessionStorage.PaymentMethodType == "CK") {
                    angular.element(document.querySelector("#moneyOrderID")).show();
                    angular.element(document.querySelector("#PaymentDiv")).hide();
                    if (sessionStorage.PaymentMethodType == "CP") {
                        $("#lblmoneyOrderNo").html("Invoice#:");
                    } else if (sessionStorage.PaymentMethodType == "CK") {
                        $("#lblmoneyOrderNo").html("Check#:");
                    } else { $("#lblmoneyOrderNo").html("Money Order#:"); }
                }

                if (sessionStorage.IsPaid == "true") {
                    angular.element(document.querySelector("#moneyOrderID")).hide();
                    angular.element(document.querySelector("#PaymentDiv")).hide();
                }


                $('#txtCardNumber').focus();
                $scope.ApplicationNumber = sessionStorage.ApplicationNumber;
                //$scope.ApplicationFee = sessionStorage.RenewalFees;
                $scope.ConvenienceFee = 0;

                LicenseRenewalHASnAService.GetServerDate().then(function(response){ response = response.data;
                    var date = new Date(response.substring(0, 10));
                    $scope.ReciptDate = ($filter('date')(new Date(date), 'MMM dd, yyyy'));
                });

                //$scope.LateFee = sessionStorage.LateFees;

                $scope.TotalFees = parseInt(sessionStorage.RenewalFees) + $scope.ConvenienceFee + parseInt(sessionStorage.LateFees);
                $scope.monthListGet();
                $scope.yearListGet();
                $scope.countryGetAll();
                $scope.Country = $rootScope.DefaultCountryId;
                $scope.countrySelectChange($scope.Country);
                //$scope.initiatePayment();

                $scope.ReferenceNumber = sessionStorage.ReferenceNumber;

                $scope.OriginalLicenseDate = ($filter('date')(new Date(sessionStorage.OriginalLicenseDate), 'MM/dd/yyyy'));

                var date = new Date(sessionStorage.LicenseExpirationDate);
                date.setFullYear(date.getFullYear() + 1);
                $scope.LicenseExpirationDate = ($filter('date')(new Date(date), 'MM/dd/yyyy'));
                $scope.LicenseNumber = sessionStorage.LicenseNumber;
                var appendStatus = "";
                if (sessionStorage.RequestedLicenseStatusTypeId == 4) {
                    appendStatus = " Inactive";
                    $scope.LicenseNumber = sessionStorage.LicenseNumber + appendStatus;
                }
                $scope.ApplicationName = sessionStorage.ApplicationTypeName;
                $scope.LicenseTypeName = sessionStorage.LicenseTypeName;
                $scope.IndividualName = sessionStorage.IndividualName;

                LegalStatusAction = JSON.parse(sessionStorage.LegalStatus);
                if (LegalStatusAction == true || sessionStorage.ChildSupportInfo == "true") {
                    $scope.DisplayMessage = "Your license renewal application is pending review, you will be notified once your application is approved.";
                } else {
                    $scope.DisplayMessage = "Your license renewal application is approved.";
                }

            } else {
                $state.go('app.BackofficeLoginstaff');
            }
        }


        $scope.counter = 0;
        $scope.ValidateManualPayment = function () {
            if ($scope.MoneyOrderNo == undefined || $scope.MoneyOrderNo == null) {
                if (sessionStorage.PaymentMethodType == "CP") {
                    showValidationSummary("<ul><li>" + "Please enter invoice number." + "</li></ul>");
                    HideLoader();
                } else if (sessionStorage.PaymentMethodType == "MO") {
                    showValidationSummary("<ul><li>" + "Please enter money-order number." + "</li></ul>");
                    HideLoader();
                } else if (sessionStorage.PaymentMethodType == "CK") {
                    showValidationSummary("<ul><li>" + "Please enter check number." + "</li></ul>");
                    HideLoader();
                }
                return false;
            } else if ($scope.MoneyOrderNo == "") {
                if (sessionStorage.PaymentMethodType == "CP") {
                    showValidationSummary("<ul><li>" + "Please enter invoice number." + "</li></ul>");
                    HideLoader();
                } else if (sessionStorage.PaymentMethodType == "MO") {
                    showValidationSummary("<ul><li>" + "Please enter money-order number." + "</li></ul>");
                    HideLoader();
                } else if (sessionStorage.PaymentMethodType == "CK") {
                    showValidationSummary("<ul><li>" + "Please enter check number." + "</li></ul>");
                    HideLoader();
                }
                return false;
            }

            return true;
        }
        $scope.$on("Payment", function (event, args) {
            if (sessionStorage.PaymentMethodType == "CC") {
                $scope.Save(false);
            } else {
                if ($scope.ValidateManualPayment()) {
                    $scope.Save(false);
                }
            }

        });
        $scope.$on("ConversionPayment", function (event, args) {

            if ($scope.ValidateManualPayment()) {
                $scope.ConversionPayment(false);
            }


        });

        $scope.$on("PaymentApprove", function (event, args) {

            if (sessionStorage.PaymentMethodType == "CC") {
                $scope.Save(true);
            } else {
                if ($scope.ValidateManualPayment()) {
                    $scope.Save(true);
                }
            }

        });
        $scope.$on("SendPDFEmail", function (event, args) {

            ApplicationNumber = sessionStorage.ApplicationNumber;
            ApplicationId = sessionStorage.ApplicationId;
            IndividualId = sessionStorage.IndividualId;
            InvoiceNumber = sessionStorage.InvoiceNumber;

            $scope.ReferenceNumber = sessionStorage.ReferenceNumber;
            $scope.PaymentRecieptFlag = true;
            $scope.PrintLicenseFlag = true;
            $scope.ApplicationRenewalFlag = true;

            //sessionStorage.TransactionStartDatetime = sessionStorage.TransactionObject.TransactionStartDatetime;
            $scope.TransactionDate = new Date(sessionStorage.TransactionStartDatetime);
            var paymentReceiptHtmlContent = $("#PaymentReceipt").html();
            $scope.paymentReceiptHTML = paymentReceiptHtmlContent.replace('#@String@#', $scope.ReferenceNumber);
            $scope.sendPDFThroughEmail("false");

        });

        $scope.$on("SendEmail", function (event, args) {

            ApplicationId = sessionStorage.ApplicationId;
            IndividualId = sessionStorage.IndividualId;

            // $scope.SendDocumentEmail(IndividualId, ApplicationId);
            HideLoader();
            angular.element(document.querySelector("#LicenseReHASBo")).hide();
            angular.element(document.querySelector("#LicenseBoAdd")).show();
            angular.element(document.querySelector("#LicenseAppClose")).hide();
            angular.element(document.querySelector("#searchdata")).hide();
            $scope.clearArrayList();
            $scope.closeApplication();
            $scope.PageLoad(true);
        });

        $scope.SendDocumentEmail = function (IndividualId, ApplicationId) {
            ShowLoader("Please wait...");
            $scope.ReceiptFilename = "";
            $scope.LicenseFilename = "";

            Email = sessionStorage.Email;

            AffirmativeAction = JSON.parse(sessionStorage.LegalStatus);
            if (AffirmativeAction == true || sessionStorage.ChildSupportInfo == "true") {
                AffirmativeAction = "Y";
            } else {
                AffirmativeAction = "N";
            };

            IndividualDocumentSaveService.IndividualDocumentGet(sessionStorage.Key, IndividualId, ApplicationId).then(function(response){ response = response.data;
                var IndividualDocumentList = response.IndividualDocumentGetList;
                for (var i = 0; i < IndividualDocumentList.length; i++) {
                    if (IndividualDocumentList[i].DocumentTypeName == "Payment Receipt") {
                        sessionStorage.PaymentReceiptDocID = IndividualDocumentList[i].IndividualDocumentId;
                        $scope.ReceiptFilename = IndividualDocumentList[i].DocumentName;
                    } else if (IndividualDocumentList[i].DocumentTypeName == "License Output") {
                        sessionStorage.LicenseOutputDocID = IndividualDocumentList[i].IndividualDocumentId;
                        $scope.LicenseFilename = IndividualDocumentList[i].DocumentName;
                    }
                }


                IndividualDocumentSaveService.IndividualSendEmail(sessionStorage.Key, sessionStorage.LicenseOutputDocID, sessionStorage.PaymentReceiptDocID, $scope.LicenseFilename, $scope.ReceiptFilename, IndividualId, ApplicationId, Email, AffirmativeAction)
                    .then(function(response){ response = response.data;
                        if ($scope.checkResponse(response)) {
                            if (response.IndividualDocumentUploadList != null) {
                                if (response.IndividualDocumentUploadList[0].DocumentUploadList.length > 0) {
                                    for (var i = 0; i < response.IndividualDocumentUploadList[0].DocumentUploadList.length; i++) {
                                        if (response.IndividualDocumentUploadList[0].DocumentUploadList[i].DocumentTypeName == "Payment Receipt") {
                                            sessionStorage.PaymentReceiptDocID = "Individual/PdfDocumentByIndividualDocumentId/" + sessionStorage.Key + "?IndividualDocumentId=" + response.IndividualDocumentUploadList[0].DocumentUploadList[i].IndividualDocumentId;
                                        } else if (response.IndividualDocumentUploadList[0].DocumentUploadList[i].DocumentTypeName == "License Output") {
                                            sessionStorage.LicenseOutputDocID = "Individual/PdfDocumentByIndividualDocumentId/" + sessionStorage.Key + "?IndividualDocumentId=" + response.IndividualDocumentUploadList[0].DocumentUploadList[i].IndividualDocumentId;
                                        } else {
                                            sessionStorage.ApplicationRenewalID = "Individual/PdfDocumentByIndividualDocumentId/" + sessionStorage.Key + "?IndividualDocumentId=" + response.IndividualDocumentUploadList[0].DocumentUploadList[i].IndividualDocumentId;
                                        }
                                    }
                                }
                            }
                            $scope.clearPaymentData();
                            angular.element(document.querySelector("#PaymentDiv")).hide();
                        } else { }
                        HideLoader();
                        angular.element(document.querySelector("#LicenseReHASBo")).hide();
                        angular.element(document.querySelector("#LicenseBoAdd")).show();
                        angular.element(document.querySelector("#LicenseAppClose")).hide();
                        angular.element(document.querySelector("#searchdata")).hide();
                        $scope.clearArrayList();
                        $scope.closeApplication();
                        $scope.PageLoad(true);
                    }, function(data) { data = data.data;
                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });
                HideLoader();
            });

        }


        $scope.ConversionPayment = function (flagApprove) {
            try {
                $scope.initiateConversionPayment();
                $timeout(function () {

                    // Manual Payment coding
                    if (validateData("UI_MoneyOrdervalidation")) {
                        ShowLoader("Please wait...");
                        ApplicationNumber = sessionStorage.ApplicationNumber;
                        ApplicationId = sessionStorage.ApplicationId;
                        IndividualId = sessionStorage.IndividualId;
                        Amount = $scope.FeesTotal;
                        Description = $scope.MoneyOrderComment;
                        InvoiceNumber = sessionStorage.InvoiceNumber;
                        TransactionObject = $scope.TransactionObject;
                        RequestedLicenseStatusTypeId = sessionStorage.RequestedLicenseStatusTypeId;
                        $scope.ReferenceNumber = sessionStorage.ReferenceNumber;
                        var affirmativeStatus = JSON.parse(sessionStorage.LegalStatus);
                        if (affirmativeStatus == true || sessionStorage.ChildSupportInfo == "true") {
                            $scope.AffirmativeAction = "Y";
                        } else {
                            $scope.AffirmativeAction = "N";
                        }
                        IsBackofficePayment = true;
                        PaymentMode = sessionStorage.PaymentMethodType;
                        PaymentModeNumber = $scope.MoneyOrderNo;
                        PaymentBankName = null;

                        ProcessPaymentService.ProcessManualPayment(sessionStorage.Key,
                            $scope.AffirmativeAction,
                            IsBackofficePayment,
                            ApplicationNumber,
                            ApplicationId,
                            IndividualId,
                            PaymentMode,
                            PaymentModeNumber,
                            PaymentBankName,
                            Amount,
                            Description,
                            InvoiceNumber,
                            TransactionObject,
                            RequestedLicenseStatusTypeId).then(function(response){ response = response.data;

                                if ($scope.checkResponse(response)) {
                                    showStatusMessage(response.Message, "success");


                                    sessionStorage.LicenseFee = $scope.FeesTotal;
                                    sessionStorage.ReferenceNumber = '00000';
                                    $scope.ReferenceNumber = sessionStorage.ReferenceNumber;
                                    $scope.ReferenceNo = $scope.ReferenceNumber;



                                    ApplicationNumber = sessionStorage.ApplicationNumber;
                                    ApplicationId = sessionStorage.ApplicationId;
                                    IndividualId = sessionStorage.IndividualId;
                                    InvoiceNumber = sessionStorage.InvoiceNumber;

                                    $scope.ReferenceNumber = sessionStorage.ReferenceNumber;
                                    $scope.PaymentRecieptFlag = true;
                                    $scope.PrintLicenseFlag = true;
                                    $scope.ApplicationRenewalFlag = true;
                                    var paymentReceiptHtmlContent = $("#PaymentReceipt").html();
                                    $scope.paymentReceiptHTML = paymentReceiptHtmlContent.replace('#@String@#', $scope.ReferenceNumber);

                                    $scope.sendPDFThroughEmail("false");

                                } else {
                                    HideLoader();
                                }
                            });
                    } else {
                        HideLoader();
                    }

                }, 5000);

            } catch (ex) {
                $scope.CVV = "";
                HideLoader();
                if (ex != null) { showStatusMessage("103: " + $scope.ErrorMsg + " ('" + ex.message + "').", "error"); }
            }
        }
        // Save
        $scope.Save = function (flagApprove) {
            try {
                $scope.initiatePayment();
                //$scope.payment_init();
                $timeout(function () {
                    if (sessionStorage.PaymentMethodType == "CC") {
                        if (validateData("UI_Paymentvalidation")) {
                            ShowLoader("Please wait...");
                            IsBackofficePayment = true;
                            resetValidation();
                            ApplicationNumber = sessionStorage.ApplicationNumber;
                            ApplicationId = sessionStorage.ApplicationId;
                            IndividualId = sessionStorage.IndividualId;
                            CardNumber = $scope.CardNumber;
                            CVV = $scope.CVV;
                            ExpirationMonths = $scope.ExpirationMonth;
                            ExpirationYears = $scope.ExpirationYear;
                            Amount = $scope.FeesTotal;
                            Description = "Test";
                            InvoiceNumber = sessionStorage.InvoiceNumber;
                            FirstName = $scope.FirstName;
                            LastName = $scope.LastName;
                            Address = $scope.Street;
                            City = $scope.City;
                            StateCode = $scope.State;
                            Zip = $scope.Zip;
                            Country = $scope.Country;
                            EmailAddress = $scope.Email;
                            TransactionObject = $scope.TransactionObject;
                            RequestedLicenseStatusTypeId = sessionStorage.RequestedLicenseStatusTypeId;
                            $scope.ReferenceNumber = sessionStorage.ReferenceNumber;
                            var affirmativeStatus = JSON.parse(sessionStorage.LegalStatus);
                            if (affirmativeStatus == true || sessionStorage.ChildSupportInfo == "true") {
                                $scope.AffirmativeAction = "Y";
                            } else {
                                $scope.AffirmativeAction = "N";
                            }
                            ProcessPaymentService.ProcessPayment(sessionStorage.Key,
                                $scope.AffirmativeAction,
                                IsBackofficePayment,
                                ApplicationNumber,
                                ApplicationId,
                                IndividualId,
                                CardNumber,
                                CVV,
                                ExpirationMonths,
                                ExpirationYears,
                                Amount,
                                Description,
                                InvoiceNumber,
                                FirstName,
                                LastName,
                                Address,
                                City,
                                StateCode,
                                Zip,
                                Country,
                                EmailAddress,
                                TransactionObject,
                                RequestedLicenseStatusTypeId)
                                .then(function(response){ response = response.data;
                                    if ($scope.checkResponse(response)) {
                                        showStatusMessage(response.Message, "success");
                                        sessionStorage.LicenseFee = $scope.FeesTotal;
                                        sessionStorage.ReferenceNumber = response.PaymentAuthResponse.AuthorizationCode;
                                        $scope.ReferenceNumber = sessionStorage.ReferenceNumber;
                                        $scope.ReferenceNo = $scope.ReferenceNumber;


                                        if (flagApprove) {
                                            try {
                                                $scope.Action = "C";
                                                $scope.ApproveApplication(true);

                                            } catch (ex) {
                                                if (ex != null) { showStatusMessage("101: " + $scope.ErrorMsg + " ('" + ex.message + "').", "error"); }
                                            }
                                        } else {
                                            ApplicationNumber = sessionStorage.ApplicationNumber;
                                            ApplicationId = sessionStorage.ApplicationId;
                                            IndividualId = sessionStorage.IndividualId;
                                            InvoiceNumber = sessionStorage.InvoiceNumber;

                                            $scope.ReferenceNumber = sessionStorage.ReferenceNumber;
                                            $scope.PaymentRecieptFlag = true;
                                            $scope.PrintLicenseFlag = true;
                                            $scope.ApplicationRenewalFlag = true;

                                            //sessionStorage.TransactionStartDatetime = sessionStorage.TransactionObject.TransactionStartDatetime;
                                            //$scope.TransactionDate = new Date(sessionStorage.TransactionStartDatetime);
                                            var paymentReceiptHtmlContent = $("#PaymentReceipt").html();
                                            $scope.paymentReceiptHTML = paymentReceiptHtmlContent.replace('#@String@#', $scope.ReferenceNumber);

                                            $scope.sendPDFThroughEmail("false");

                                        }
                                    } else {
                                        HideLoader();
                                    }
                                }, function(data) { data = data.data;
                                    $scope.CVV = "";
                                    HideLoader();
                                    if (data != null) { showStatusMessage(data.message, "error"); }
                                });
                        } else {
                            $scope.CVV = "";
                            HideLoader();
                        }
                    } else {
                        // Manual Payment coding
                        if (validateData("UI_MoneyOrdervalidation")) {
                            ShowLoader("Please wait...");
                            ApplicationNumber = sessionStorage.ApplicationNumber;
                            ApplicationId = sessionStorage.ApplicationId;
                            IndividualId = sessionStorage.IndividualId;
                            Amount = $scope.FeesTotal;
                            Description = $scope.MoneyOrderComment;
                            InvoiceNumber = sessionStorage.InvoiceNumber;
                            TransactionObject = $scope.TransactionObject;
                            RequestedLicenseStatusTypeId = sessionStorage.RequestedLicenseStatusTypeId;
                            $scope.ReferenceNumber = sessionStorage.ReferenceNumber;
                            var affirmativeStatus = JSON.parse(sessionStorage.LegalStatus);
                            if (affirmativeStatus == true || sessionStorage.ChildSupportInfo == "true") {
                                $scope.AffirmativeAction = "Y";
                            } else {
                                $scope.AffirmativeAction = "N";
                            }
                            IsBackofficePayment = true;
                            PaymentMode = sessionStorage.PaymentMethodType;
                            PaymentModeNumber = $scope.MoneyOrderNo;
                            PaymentBankName = null;

                            ProcessPaymentService.ProcessManualPayment(sessionStorage.Key,
                                $scope.AffirmativeAction,
                                IsBackofficePayment,
                                ApplicationNumber,
                                ApplicationId,
                                IndividualId,
                                PaymentMode,
                                PaymentModeNumber,
                                PaymentBankName,
                                Amount,
                                Description,
                                InvoiceNumber,
                                TransactionObject,
                                RequestedLicenseStatusTypeId).then(function(response){ response = response.data;

                                    if ($scope.checkResponse(response)) {
                                        showStatusMessage(response.Message, "success");


                                        sessionStorage.LicenseFee = $scope.FeesTotal;
                                        sessionStorage.ReferenceNumber = '00000';
                                        $scope.ReferenceNumber = sessionStorage.ReferenceNumber;
                                        $scope.ReferenceNo = $scope.ReferenceNumber;


                                        if (flagApprove) {
                                            try {
                                                $scope.Action = "C";
                                                $scope.ApproveApplication(true);

                                            } catch (ex) {
                                                if (ex != null) { showStatusMessage("102: " + $scope.ErrorMsg + " ('" + ex.message + "').", "error"); }
                                            }
                                        } else {

                                            ApplicationNumber = sessionStorage.ApplicationNumber;
                                            ApplicationId = sessionStorage.ApplicationId;
                                            IndividualId = sessionStorage.IndividualId;
                                            InvoiceNumber = sessionStorage.InvoiceNumber;

                                            $scope.ReferenceNumber = sessionStorage.ReferenceNumber;
                                            $scope.PaymentRecieptFlag = true;
                                            $scope.PrintLicenseFlag = true;
                                            $scope.ApplicationRenewalFlag = true;

                                            //sessionStorage.TransactionStartDatetime = sessionStorage.TransactionObject.TransactionStartDatetime;
                                            //$scope.TransactionDate = new Date(sessionStorage.TransactionStartDatetime);
                                            var paymentReceiptHtmlContent = $("#PaymentReceipt").html();
                                            $scope.paymentReceiptHTML = paymentReceiptHtmlContent.replace('#@String@#', $scope.ReferenceNumber);

                                            $scope.sendPDFThroughEmail("false");

                                        }
                                    } else {
                                        HideLoader();
                                    }
                                });
                        } else {
                            HideLoader();
                        }
                    }
                }, 5000);

            } catch (ex) {
                $scope.CVV = "";
                HideLoader();
                if (ex != null) { showStatusMessage("103: " + $scope.ErrorMsg + " ('" + ex.message + "').", "error"); }
            }
        }

        $scope.sendPDFThroughEmail = function (SendEmail) {
            ///// Send Email
            AffirmativeAction = JSON.parse(sessionStorage.LegalStatus);
            if (AffirmativeAction == true || sessionStorage.ChildSupportInfo == "true") {
                AffirmativeAction = "Y";
            } else {
                AffirmativeAction = "N";
            };

            var licenseOutputHtmlContent = $("#PrintLicense").html();
            var appRenewalHtmlContent = $("#ApplicationRenewal").html();
            $scope.PaymentRecieptFlag = false;
            $scope.PrintLicenseFlag = false;
            $scope.ApplicationRenewalFlag = false;
            $scope.DocumentUploadList.push({
                DocumentLkToPageTabSectionId: 1,
                DocumentLkToPageTabSectionCode: "R",
                DocumentTypeName: "Payment Receipt",
                DocNameWithExtention: sessionStorage.LicenseNumber + "+" + sessionStorage.ApplicationTypeName + "+" + sessionStorage.ApplicationNumber + "+" + sessionStorage.LicenseTypeName + "+" + "Payment Receipt.pdf",
                HtmlString: $scope.paymentReceiptHTML,
                EffectiveDate: "base64 of file",
                EndDate: null,
                IsDocumentUploadedbyIndividual: true,
                IsDocumentUploadedbyStaff: true,
                DocumentId: 1,
                DocumentCd: null,
                DocumentTypeId: 1,
                OtherDocumentTypeName: null
            });

            // if (!(JSON.parse(sessionStorage.LegalStatus) || sessionStorage.ChildSupportInfo == "true")) {

            $scope.DocumentUploadList.push({
                IndividualDocumentId: 1,
                DocumentLkToPageTabSectionId: 1,
                DocumentLkToPageTabSectionCode: "R",
                DocumentTypeName: "License Output",
                DocNameWithExtention: sessionStorage.LicenseNumber + "+" + sessionStorage.ApplicationTypeName + "+" + sessionStorage.ApplicationNumber + "+" + sessionStorage.LicenseTypeName + "+" + "License Output.pdf",
                HtmlString: licenseOutputHtmlContent,
                EffectiveDate: "base64 of file",
                EndDate: null,
                IsDocumentUploadedbyIndividual: true,
                IsDocumentUploadedbyStaff: true,
                DocumentId: 1,
                DocumentCd: null,
                DocumentTypeId: 1,
                OtherDocumentTypeName: null
            });
            //  }

            //$scope.DocumentUploadList.push({
            //    IndividualDocumentId: 1,
            //    DocumentLkToPageTabSectionId: 1,
            //    DocumentLkToPageTabSectionCode: "R",
            //    DocumentTypeName: "Renewal Application",
            //    DocNameWithExtention: sessionStorage.LicenseNumber + "+" + sessionStorage.ApplicationTypeName + "+" + sessionStorage.ApplicationNumber + "+" + sessionStorage.LicenseTypeName + "+" + "Renewal Application.pdf",
            //    HtmlString: appRenewalHtmlContent,
            //    EffectiveDate: "base64 of file",
            //    EndDate: null,
            //    IsDocumentUploadedbyIndividual: true,
            //    IsDocumentUploadedbyStaff: true,
            //    DocumentId: 1,
            //    DocumentCd: null,
            //    DocumentTypeId: 1,
            //    OtherDocumentTypeName: null
            //});
            Email = sessionStorage.Email;
            //SendEmail = "true";
            DocumentUploadList = $scope.DocumentUploadList;
            IndividualDocumentSaveService.IndividualDocumentSave(sessionStorage.Key, IndividualId, ApplicationId, Email, SendEmail, sessionStorage.TransactionId, AffirmativeAction, DocumentUploadList)
                .then(function(response){ response = response.data;
                    if ($scope.checkResponse(response)) {
                        if (response.IndividualDocumentUploadList != null) {
                            if (response.IndividualDocumentUploadList[0].DocumentUploadList.length > 0) {
                                for (var i = 0; i < response.IndividualDocumentUploadList[0].DocumentUploadList.length; i++) {
                                    if (response.IndividualDocumentUploadList[0].DocumentUploadList[i].DocumentTypeName == "Payment Receipt") {
                                        sessionStorage.PaymentReceiptDocID = "Individual/PdfDocumentByIndividualDocumentId/" + sessionStorage.Key + "?IndividualDocumentId=" + response.IndividualDocumentUploadList[0].DocumentUploadList[i].IndividualDocumentId;
                                    } else if (response.IndividualDocumentUploadList[0].DocumentUploadList[i].DocumentTypeName == "License Output") {
                                        sessionStorage.LicenseOutputDocID = "Individual/PdfDocumentByIndividualDocumentId/" + sessionStorage.Key + "?IndividualDocumentId=" + response.IndividualDocumentUploadList[0].DocumentUploadList[i].IndividualDocumentId;
                                    } else {
                                        sessionStorage.ApplicationRenewalID = "Individual/PdfDocumentByIndividualDocumentId/" + sessionStorage.Key + "?IndividualDocumentId=" + response.IndividualDocumentUploadList[0].DocumentUploadList[i].IndividualDocumentId;
                                    }
                                }
                            }
                        }
                        $scope.clearPaymentData();
                        angular.element(document.querySelector("#PaymentDiv")).hide();
                    } else { }
                    HideLoader();
                    angular.element(document.querySelector("#LicenseReHASBo")).hide();
                    angular.element(document.querySelector("#LicenseBoAdd")).show();
                    angular.element(document.querySelector("#LicenseAppClose")).hide();
                    angular.element(document.querySelector("#searchdata")).hide();
                    $scope.clearArrayList();
                    $scope.closeApplication();
                    $scope.PageLoad(true);
                }, function(data) { data = data.data;
                    if (data != null) { showStatusMessage(data.message, "error"); }
                });
        }

        $scope.monthListGet = function () {
            try {

                ProcessPaymentService.MonthListGet(sessionStorage.Key)
                    .then(function(response){ response = response.data;

                        if ($scope.checkResponse(response)) {
                            $scope.MonthList = response.ListItems;
                        } else {
                            //else of CheckResponse
                        }
                    }, function(data) { data = data.data;

                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {

                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.yearListGet = function () {
            try {

                ProcessPaymentService.YearListGet(sessionStorage.Key)
                    .then(function(response){ response = response.data;

                        if ($scope.checkResponse(response)) {
                            $scope.YearList = response.ListItems;
                        } else {
                            //else of CheckResponse
                        }
                    }, function(data) { data = data.data;

                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {

                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        // Get State List by CountryID
        $scope.getStateByCountryID = function (CountryID) {
            try {
                ProcessPaymentService.GetStateByCountryID(sessionStorage.Key, CountryID)
                    .then(function(response){ response = response.data;

                        if ($scope.checkResponse(response)) {
                            $scope.StatList = response.State;
                        } else {
                            //else of CheckResponse
                        }
                    }, function(data) { data = data.data;

                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        // Get Country List 
        $scope.countryGetAll = function () {
            try {
                ProcessPaymentService.CountryGetAll(sessionStorage.Key)
                    .then(function(response){ response = response.data;

                        if ($scope.checkResponse(response)) {
                            $scope.CountryList = response.Country;
                        } else {
                            //else of CheckResponse
                        }
                    }, function(data) { data = data.data;

                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        // Get Country List 
        $scope.countrySelectChange = function (CountryID) {
            try {

                $scope.getStateByCountryID(CountryID);
                for (var i = 0; i < $scope.CountryList.length; i++) {
                    if ($scope.CountryList[i].CountryId == CountryID) {
                        $scope.ZipRegx = $scope.CountryList[i].ZipRegex;

                    }
                }

            } catch (ex) {
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.clearPaymentData = function () {
            $scope.CardNumber = "";
            $scope.CVV = "";
            $scope.ExpirationMonth = "";
            $scope.ExpirationYear = "";
            $scope.FirstName = "";
            $scope.LastName = "";
            $scope.Street = "";
            $scope.City = "";
            $scope.State = "";
            $scope.Zip = "";
            $scope.Country = "";
            $scope.Email = "";
            $scope.copyValue = "";
            resetValidation();
        }

        // Get Initiate Payment 
        $scope.initiatePayment = function () {
            try {
                ShowLoader("Please wait...");
                IndividualId = sessionStorage.IndividualId;
                //ApplicationId = sessionStorage.ApplicationId;
                //IndividualLicenseId = sessionStorage.IndividualLicenseId;
                //LicenseTypeId = sessionStorage.LicenseTypeId;
                //LicenseNumber = sessionStorage.LicenseNumber;
                LicenseRenewalHASnAService.IndividualRenewalGet(sessionStorage.Key, sessionStorage.IndividualId)
                    .then(function(response){ response = response.data;
                        if ($scope.checkResponse(response)) {
                            $scope.ShowRenewalApplicationHASnA = true;
                            $scope.IndividualRenewal = response.IndividualRenewal;
                            if ($scope.IndividualRenewal != null) {
                                if ($scope.IndividualRenewal.IndividualLicense.length > 0) {
                                    $scope.ApplicationId = $scope.IndividualRenewal.IndividualLicense[0].ApplicationId;
                                    $scope.IndividualLicenseId = $scope.IndividualRenewal.IndividualLicense[0].IndividualLicenseId;
                                    $scope.LicenseTypeId = $scope.IndividualRenewal.IndividualLicense[0].LicenseTypeId;
                                    $scope.LicenseNumber = $scope.IndividualRenewal.IndividualLicense[0].LicenseNumber;
                                }
                                if ($scope.IndividualRenewal.Application != null || $scope.IndividualRenewal.Application != undefined) {
                                    $scope.ApplicationId = $scope.IndividualRenewal.Application.ApplicationId;
                                }
                            }
                        } else {
                            HideLoader();
                        }
                    }, function(data) { data = data.data;
                        HideLoader();
                        showStatusMessage(data, "error");
                    });
                TransactionDeviceTy = "BC";

                if (sessionStorage.FeesDetails != undefined) {
                    $scope.FeesDetailObj = JSON.parse(sessionStorage.FeesDetails);
                    for (var i = 0; i < $scope.FeesDetailObj.length; i++) {
                        if ($scope.LicenseTypeId == 1) {
                            if ($scope.FeesDetailObj[i].RevMstFeeId == 1) {

                                $scope.FeesDetailObj[i].FeeAmount = sessionStorage.RenewalFees;
                            } else if ($scope.FeesDetailObj[i].RevMstFeeId == 2) {
                                $scope.FeesDetailObj[i].FeeAmount = sessionStorage.LateFees;
                            }
                        } else if ($scope.LicenseTypeId == 2) {
                            if ($scope.FeesDetailObj[i].RevMstFeeId == 4) {

                                $scope.FeesDetailObj[i].FeeAmount = sessionStorage.RenewalFees;
                            } else if ($scope.FeesDetailObj[i].RevMstFeeId == 3) {
                                $scope.FeesDetailObj[i].FeeAmount = sessionStorage.LateFees;
                            }
                        }
                    }
                }

                FeeDetailsList = $scope.FeesDetailObj;
                TransactionObject = null;

                ProcessPaymentService.InitiatePayment(sessionStorage.Key, $scope.ApplicationId, IndividualId, $scope.IndividualLicenseId, $scope.LicenseTypeId, $scope.LicenseNumber, TransactionDeviceTy, FeeDetailsList, TransactionObject)
                    .then(function(response){ response = response.data;
                        $scope.TransactionObject = [];
                        if ($scope.checkResponse(response)) {

                            $scope.TransactionObject = response.Transaction;
                            $scope.InvoiceNumber = $scope.TransactionObject.InvoiceNumber;
                            sessionStorage.TransactionId = $scope.TransactionObject.TransactionId;
                            sessionStorage.InvoiceNumber = $scope.InvoiceNumber;
                            sessionStorage.TransactionStartDatetime = $scope.TransactionObject.TransactionStartDatetime;
                            $scope.TransactionDate = new Date(sessionStorage.TransactionStartDatetime);

                        } else {
                            //else of CheckResponse
                        }
                    }, function(data) { data = data.data;
                        HideLoader();
                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }
        $scope.initiateConversionPayment = function () {
            try {
                ShowLoader("Please wait...");
                IndividualId = sessionStorage.IndividualId;
                ApplicationId = sessionStorage.ApplicationId;
                IndividualLicenseId = sessionStorage.IndividualLicenseId;
                LicenseTypeId = sessionStorage.LicenseTypeId;
                LicenseNumber = sessionStorage.LicenseNumber;

                TransactionDeviceTy = "BC";

                if (sessionStorage.FeesDetails != undefined) {
                    $scope.FeesDetailObj = JSON.parse(sessionStorage.FeesDetails);
                    for (var i = 0; i < $scope.FeesDetailObj.length; i++) {
                        if ($scope.LicenseTypeId == 1) {
                            if ($scope.FeesDetailObj[i].RevMstFeeId == 1) {

                                $scope.FeesDetailObj[i].FeeAmount = sessionStorage.RenewalFees;
                            } else if ($scope.FeesDetailObj[i].RevMstFeeId == 2) {
                                $scope.FeesDetailObj[i].FeeAmount = sessionStorage.LateFees;
                            }
                        } else if ($scope.LicenseTypeId == 2) {
                            if ($scope.FeesDetailObj[i].RevMstFeeId == 4) {

                                $scope.FeesDetailObj[i].FeeAmount = sessionStorage.RenewalFees;
                            } else if ($scope.FeesDetailObj[i].RevMstFeeId == 3) {
                                $scope.FeesDetailObj[i].FeeAmount = sessionStorage.LateFees;
                            }
                        }
                    }
                }

                FeeDetailsList = $scope.FeesDetailObj;
                TransactionObject = null;

                ProcessPaymentService.InitiatePayment(sessionStorage.Key, $scope.ApplicationId, IndividualId, $scope.IndividualLicenseId, $scope.LicenseTypeId, $scope.LicenseNumber, TransactionDeviceTy, FeeDetailsList, TransactionObject)
                    .then(function(response){ response = response.data;
                        $scope.TransactionObject = [];
                        if ($scope.checkResponse(response)) {
                            $scope.TransactionObject = response.Transaction;
                            $scope.InvoiceNumber = $scope.TransactionObject.InvoiceNumber;
                            sessionStorage.TransactionId = $scope.TransactionObject.TransactionId;
                            sessionStorage.InvoiceNumber = $scope.InvoiceNumber;
                            sessionStorage.TransactionStartDatetime = $scope.TransactionObject.TransactionStartDatetime;
                            $scope.TransactionDate = new Date(sessionStorage.TransactionStartDatetime);

                        } else {
                            //else of CheckResponse
                        }
                    }, function(data) { data = data.data;
                        HideLoader();
                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.fillDetail = function (copyValue) {
            ShowLoader("Please wait...");
            LicenseRenewalHASnAService.IndividualRenewalGet(sessionStorage.Key, sessionStorage.IndividualId)
                .then(function(response){ response = response.data;
                    HideLoader();
                    if ($scope.checkResponse(response)) {
                        $scope.ShowRenewalApplicationHASnA = true;
                        $scope.IndividualRenewal = response.IndividualRenewal;
                        if ($scope.IndividualRenewal != null) {
                            //Individual
                            if ($scope.IndividualRenewal.Individual != null) {
                                $scope.IndividualId = $scope.IndividualRenewal.Individual.IndividualId;
                                sessionStorage.IndividualId = $scope.IndividualId;
                                $scope.FirstName = $scope.IndividualRenewal.Individual.FirstName;
                                $scope.LastName = $scope.IndividualRenewal.Individual.LastName;
                                $scope.Email = $scope.IndividualRenewal.Individual.Email;
                                if ($scope.IndividualRenewal.Individual.objIndividualAddress != null && $scope.IndividualRenewal.Individual.objIndividualAddress != "") {
                                    $scope.Street = $scope.IndividualRenewal.Individual.objIndividualAddress.StreetLine1;
                                    $scope.City = $scope.IndividualRenewal.Individual.objIndividualAddress.City;
                                    $scope.Country = $scope.IndividualRenewal.Individual.objIndividualAddress.CountryId;
                                    $scope.State = $scope.IndividualRenewal.Individual.objIndividualAddress.StateCode;
                                    $scope.Zip = $scope.IndividualRenewal.Individual.objIndividualAddress.Zip;
                                }
                            }
                            HideLoader();
                        }
                    } else { }
                }, function(data) { data = data.data;
                    HideLoader();
                    showStatusMessage(data, "error");
                });
        }

        $scope.backToApplication = function () {
            try {
                if (window.location.hash == "#/Backoffice/Payment") {
                    $state.go('app.BackofficeLicenseRenewalHASnA_BO');
                } else if (window.location.hash == "#/User/Payment") {
                    $state.go('app.LicenseRenewalHASnA');
                }
            } catch (e) {

            }
        }

        $scope.cancelPayment = function () {
            angular.element(document.querySelector("#PaymentDiv")).hide();
            angular.element(document.querySelector("#moneyOrderID")).hide();
            $scope.FirstName = "";
            $scope.LastName = "";
            $scope.Email = "";
            $scope.Street = "";
            $scope.City = "";
            $scope.State = "";
            $scope.Zip = "";
            $scope.copyValue = "";
            $scope.ddlpaymentMethod = "";
            $scope.MoneyOrderNo = "";
            $scope.MoneyOrderComment = "";
        }
    }
]);