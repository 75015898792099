angular.module('app.account')
    .factory('RegisterFactory', ['$http', 'WebApiUrl', '$q', 'AppConfig', function ($http, WebApiUrl, $q, AppConfig) {
        var BaseAPI = WebApiUrl;
        var ApiPath = '';
        var baseFactory = {};
        baseFactory.GenerateApplicationId = function (urlParam, data) {
            ApiPath = 'Application/GenerateApplicationId' + urlParam;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };

        baseFactory.SaveIndividual = function (urlParam, data) {
            ApiPath = 'Individual/IndividualSave' + urlParam + '?Source=' + AppConfig.Source;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };

        baseFactory.individualOtherCommunicationPreferenceSave = function (individualOtherCommunicationPreferenceSave) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + '/Individual/IndividualOtherCommunicationPreferenceSave', individualOtherCommunicationPreferenceSave)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        baseFactory.SaveIndividualNameTypeLk = function (urlParam, data) {
            ApiPath = 'IndividualNameTypeLk/IndividualNameTypeLkSave' + urlParam;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };

        baseFactory.IndividualContactSave = function (urlParam, data) {
            ApiPath = 'Individual/IndividualContactSave' + urlParam + '?Source=' + AppConfig.Source;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };
        baseFactory.ProviderContactSave = function (urlParam, data) {
            ApiPath = 'Provider/ProviderContactsSave' + urlParam + '?Source=' + AppConfig.Source;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };


        baseFactory.UsersSave = function (urlParam, data) {
            ApiPath = 'Users/UsersSave' + urlParam;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };

        baseFactory.UsersSaveAgency = function (urlParam, data) {
            ApiPath = 'Users/UsersSave' + urlParam;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };
        baseFactory.UsersEntitySave = function (urlParam, data) {
            ApiPath = 'Users/UsersEntitySave' + urlParam;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };

        baseFactory.UserRoleSave = function (urlParam, data) {
            ApiPath = 'Users/UserRoleSave' + urlParam;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };
        baseFactory.CheckExistingMemberforRegistration = function (urlParam, data) {
            ApiPath = 'User/CheckExistingMemberforRegistration' + urlParam;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };
        baseFactory.UserByIndividualId = function (urlParam) {
            ApiPath = 'Users/UserByIndividualId' + urlParam;
            var deferred = $q.defer();
            $http.get(BaseAPI + ApiPath).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };

        baseFactory.UserChangeLogSave = function (urlParam, data) {
            ApiPath = 'Users/UserChangeLogSave' + urlParam;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };

        baseFactory.ApplicationStatusChangeLogSave = function (urlParam, data) {
            ApiPath = 'Common/ApplicationStatusChangeLogSave' + urlParam;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };
        baseFactory.ApplicationStatusLkSave = function (urlParam, data) {
            ApiPath = 'Common/ApplicationStatusLkSave' + urlParam;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };

        baseFactory.ResetPassword = function (urlParam, data) {
            ApiPath = 'User/ResetPassword' + urlParam;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };
        baseFactory.SaveIndividualApplication = function (urlParam, data) {
            ApiPath = 'Individual/SaveIndividualApplication' + urlParam;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };
        baseFactory.getIp = function (urlParam, data) {
            var deferred = $q.defer();
            $http.get('https://api.ipify.org/?format=json').then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };

        baseFactory.UserRoleGetBYUserID = function (urlParm) {
            ApiPath = 'Users/UserRoleGetBYUserID' + urlParm;
            var deferred = $q.defer();
            $http.get(BaseAPI + ApiPath).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };
        baseFactory.AuditvisitInfoSave = function (params, data) {
            var deferred = $q.defer();
            $http.post(BaseAPI + 'Logging/AuditvisitInfoSave/' + params, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };
        baseFactory.CheckExistingUserForRegistration = function (data) {
            var deferred = $q.defer();
            $http.post(BaseAPI + 'User/CheckExistingUserForRegistration', data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };
        baseFactory.IndividualLogTableSave = function (key, data) {
            var deferred = $q.defer();
            $http.post(BaseAPI + 'Individual/IndividualLogTableSave/' + key, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };
        baseFactory.CheckExistingEntityUserForRegistration = function (data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + '/User/CheckExistingEntityUserForRegistration', data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };

        baseFactory.SaveProvider = function (Key, ApplicationId, UserId, objData) {
            var deferred = $q.defer();
            var key = 'undefined';
            $http.post(BaseAPI + "Provider/ProviderSave?Key="  + key + "&ApplicationId=" + ApplicationId + "&UserId=" + UserId, objData).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };
        
        baseFactory.SaveProviderOther = function (Key, data) {
            console.log("key", sessionStorage);
            var deferred = $q.defer();
            var key = Key == "" ? 'undefined' : Key;
            // var key = "VXhBTWNrNjZoSXZzbnlPOU9RS090dDl6OXJsdmovU24zRVdpYjEzZ3c4ekxXbmF3MWd4SWxoSjdTUHZvbm5zZ0graXJPZ3NJSlVjQ1B0VHdEdSs0bFFlOGFjN2orV1l0VllPR2MyRXAyNTZiMjZETFJSMGx4dz090";
            $http.post(BaseAPI + "Provider/SaveProviderOther/" + key + '?Source=' + AppConfig.Source, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };

        baseFactory.SaveProviderApplication = function (key, data) {
            var deferred = $q.defer();
            $http.post(BaseAPI + "Provider/ProviderApplicationSave/" + key, data).then(function (response) {
                deferred.resolve(response);
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };
        baseFactory.saveProviderApplication = function (key, data) {
            var deferred = $q.defer();
            $http.post(BaseAPI + 'Provider/ProviderApplicationSave/' + key, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };
        baseFactory.sendEmailAndSms = function (key, reqData) {
            var deferred = $q.defer();
            $http.post(BaseAPI + 'User/EmailAndText?key=', reqData).then(function (data) {
                data = data.data;
                deferred.resolve(data);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };
        baseFactory.ExternalUserUsersSave = function (urlParam, data) {
            ApiPath = 'Users/ExternalUserUsersSave' + urlParam;
            var deferred = $q.defer();
            $http.post(BaseAPI + ApiPath, data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };
        baseFactory.UserByIndividualIdExternal = function (urlParam) {
            ApiPath = 'Users/UserByIndividualIdExternal' + urlParam;
            var deferred = $q.defer();
            $http.get(BaseAPI + ApiPath).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        };

        return baseFactory;
    }]);
