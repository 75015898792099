(function () {
    //'use strict';

    angular
        .module('app.backofficeProvider')
        .controller("FirmsCSLocationsController", FirmsCSLocationsController);
    FirmsCSLocationsController.$inject = ['provideCEEducationService'];

    function FirmsCSLocationsController(provideCEEducationService) {


        var init = function () {
            ShowLoader();
            provideCEEducationService.getCEEducation().then(function (response) {
                HideLoader();
                if (response.ProviderCourseAddInfoList) {
                    var responseData = [];
                    angular.forEach(response.ProviderCourseAddInfoList, function (item) {

                        responseData.push({
                            id: item.ProviderCourseAddInfoId,
                            title: item.CourseLocationState,
                            start: item.CourseBeginDate,
                            end: item.CourseEndDate,
                        });

                    });
                }
                $('#calendar').fullCalendar({
                    header: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'month,basicWeek,basicDay'
                    },
                    defaultDate: '2018-05-15',
                    navLinks: true, // can click day/week names to navigate views
                    editable: false,
                    eventLimit: true,
                    events: responseData
                });
            }, function (error) {
                HideLoader();
                console.log(error);
            });
        }

        init();

    }
})();
