(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .directive('surgeryLogic', SurgeryLogic);

    SurgeryLogic.$inject = [];

    function SurgeryLogic() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {

            }
        };
    }
})();