﻿(function () {
    'use strict'
    angular.module('app.backofficeBusinessEntity')
        .directive('businessentityformDirective', beInstructionsDirective);

    beInstructionsDirective.$inject = [];

    function beInstructionsDirective() {
        return {
            restrict: 'E',
            scope: {
                IsBackoffice: '=isbackoffice',
                providerId: '=providerId',
                applicationId: '=?applicationId',
                callbackitem: '=callbackitem',
                paymentProcessed: '=paymentProcessed',
                applicationStatus: '=applicationStatus',
                closeform: '&',
                ApplicationNumber: '=?applicationNumber',
                applicationForId: '=?applicationForId'
            },
            templateUrl: "app/components/individual/businessentity/business-entity.html",
            controller: "BusinessEntityController"
        }
    }
})();