(function () {
  'use strict'
  angular.module('app.InitialApplication')
    .directive('initialApplicationAssistant', initialApplicationAssistantDirective);

  initialApplicationAssistantDirective.$inject = [];

  function initialApplicationAssistantDirective() {
    return {
      restrict: 'E',
      scope: {
        assistantInfo: "=assistantInfo",
        showSubmitPanel: "=showSubmitPanel",
        showHeader: "=showHeader",
        headingPosition: "=headingPosition",
        isApprentice: "=isApprentice",
        applicationId: "=applicationId",
        individualId: "=individualId",
        natValidation: "=natValidation"
      },
      templateUrl: "app/components/individual/application/directives/assistant/assistant.html",
      controller: "InitialApplicationAssistantController"
    }
  }
})();