(function () {
    'use strict'

    angular.module('app.enforcement')
        .directive('complaintUpdateLogic', ['$q', 'ComplaintUpdateExtService', ComplaintUpdateLogic]);

    function ComplaintUpdateLogic($q, ComplaintUpdateExtService) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.complaintStatusChanged = function (propName) {
                    $scope.current.complaint[propName] = new Date().toJSON();
                };

                $scope.getInvestigators = function () {
                    var deferred = $q.defer();

                    try {
                        ComplaintUpdateExtService.getUsersByRole(sessionStorage.Key, null, 'Complaint Investigator')
                            .then(function (data) {
                                if (data.Status) {
                                    $scope.investigatorList = data.UsersInRole;
                                }
                                deferred.resolve(data);
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.filterComplaintDispType = function (list) {
                    if (!!list) {
                        list.conditionalSplice('item.Queue != this', $scope.queueName);
                    }
                };

                $scope.sendToChiefReview = function () {
                    //If the box is checked, move it to the next stage
                    if (!!$scope.current.complaint.IsAdministrativeAction) {
                        $scope.current.complaint.CaseStageId = 2;
                        $scope.current.complaint.CurrentStatusDate = new Date().toJSON();
                    }
                };

                $scope.initExt = function () {
                    $scope.getInvestigators();
                };
            }
        };
    }
})();