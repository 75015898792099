(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
    .directive('addressContactsCommon', ['$q', 'backofficeIndividualAdressService', AddressContactsCommon]);

    function AddressContactsCommon($q, backofficeIndividualAdressService) {
        return {
            restrict: 'EA',
            scope: {
                individualId: '=addressContactsIndividual',
                addressesLoaded: '&',
                addressesSaved: '&'
            },
            link: function ($scope, element, attrs, ctrl) {
                ctrl.getAllAddressContacts();
            },
            controller: ['$rootScope', '$scope', 'utilityService', 'ChildEventHandlerFactory', function ($rootScope, $scope, utilityService, ChildEventHandlerFactory) {
                $scope.addressContacts = [];
                var self = this;
                var _childName = 'addressContacts';

                self.getConfig = function () {
                    var deferred = $q.defer();
                    
                    try {
                        utilityService.getConfigData('/core/directive/address-contacts/address-contacts.server-config.json')
                        .then(function(data) {
                            if (!!data) {
                                $scope.config = data;
                            } else {
                                $scope.config = {};
                            }
                            deferred.resolve(data);
                        }, function(err) {
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                    
                    return deferred.promise;
                };
                
                self.registerAddressContact = function (scope) {
                    $scope.addressContacts.push(scope);

                    scope.common = {
                        saveChangedAddresses: self.saveChangedAddresses,
                        checkAddressIsEmpty: self.checkAddressIsEmpty,
                        checkContactIsEmpty: self.checkContactIsEmpty,
                        addressChanged: self.addressChanged,
                        getAllAddressContacts: self.getAllAddressContacts,
                        publicChanged: self.publicChanged,
                        mailingChanged: self.mailingChanged,
                        deleteAddress: self.deleteAddress,
                        markAddressPrevious: self.markAddressPrevious
                    };
                };

                self.showLoader = function (message) {
                    $scope.addressContacts.forEach(function (addrCont) {
                        addrCont.operationPending(true, message);
                    });
                };

                self.hideLoader = function () {
                    $scope.addressContacts.forEach(function (addrCont) {
                        addrCont.operationPending(false);
                    });
                };

                self.updateAddressContacts = function () {
                    $scope.addressContacts.forEach(function (addrCont) {
                        addrCont.updateAddressContacts($scope.allAddresses, $scope.allContacts, $scope.addressTypes);
                    });
                };

                self.linkAndPrepareContact = function (address, contact) {
                    if (!contact.justCreated) {
                        contact.ModifiedBy = sessionStorage.UserID;
                        contact.ModifiedOn = new Date();
                    }
                    
                    if (!contact.ContactInfo || contact.IsDeleted) {
                        contact.isContactChanged = true;
                        contact.IsActive = false;
                        contact.IsDeleted = true;
                        contact.ActionFlag = 'U';
                    } else {
                        contact.ReferenceNumber = address.IndividualAddressId;
                        if (contact.ContactStatusId == 1 || !!contact.isContactChanged) { 
                            contact.ActionFlag = 'UI';
                        } else {
                            contact.ActionFlag = 'UIS';
                        }
                    }

                    if (!!contact.contactStatusChanged && !contact.isContactChanged) {
                        contact.ActionFlag = 'U';
                    }
                };
        
                self.saveContact = function (address, contact) {
                    var deferred = $q.defer();
        
                    try {
                        contact.ContactTypeName = $scope.contactTypes.whereEquals(contact.ContactTypeId, 'ContactTypeId').select('Desc').firstOrDefault();

                        self.linkAndPrepareContact(address, contact);
                        if (contact.IsDeleted && !!contact.justCreated) {
                            deferred.resolve({});
                            return deferred.promise;
                        }
        
                        backofficeIndividualAdressService.addressContactSave([contact], sessionStorage.Key)
                        .then(function (data) {
                            if (data.Status) {
                                deferred.resolve(data);
                            } else {
                                //Error
                                deferred.reject(data.Message);
                            }
                        }, function (err) {
                            //Error
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        //Error
                        deferred.reject(ex);
                    }
        
                    return deferred.promise;
                };

                self.saveAllContacts = function (address, contacts, deferred) {
                    var promises = [];
        
                    contacts.forEach(function (contact) {
                        if (!!contact && (contact.isContactChanged || contact.contactStatusChanged || address.isAddressChanged || address.addressStatusChanged || !!address.extAddressChanged)) {
                            promises.push(self.saveContact(address, contact));
                        }
                    });
        
                    $q.all(promises)
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (err) {
                        //Error
                        deferred.reject(err);
                    });
                };

                self.prepareAddress = function (address) {
                    self.checkAddressIsEmpty(address);
                    if (!address.justCreated) {
                        address.ModifiedBy = sessionStorage.UserID;
                        address.ModifiedOn = new Date();
                    }
        
                    if (address.isEmpty || address.IsDeleted) {
                        address.isAddressChanged = true;
                        address.IsActive = false;
                        address.IsDeleted = true;
                        address.ActionFlag = 'U';
                    } else {
                        if (address.AdressStatusId == 1 || !!address.isAddressChanged || !!address.extAddressChanged) {
                            address.ActionFlag = 'UI';
                        } else {
                            address.ActionFlag = 'UIS';
                        }
                    }

                    if (!!address.addressStatusChanged && !address.isAddressChanged && !address.extAddressChanged) {
                        address.ActionFlag = 'U';
                    }

                    if (!!address.isUpdateOnly && address.IndividualAddressId != 0) {
                        address.ActionFlag = 'U';
                    }
                };

                self.saveAddress = function (address) {
                    //Address and Contacts
                    var deferred = $q.defer();
                    var linkedContacts = [];

                    try {
                        address.AddressTypeName = $scope.addressTypes.whereEquals(address.AddressTypeId, 'AddressTypeId').select('AddressTypeDesc').firstOrDefault();

                        self.prepareAddress(address);

                        address.contactsConfig.forEach(function (config) {
                            linkedContacts.push(address[config.Name]);
                        });

                        if (!!address.IsDeleted && !!address.justCreated) {
                            deferred.resolve({});
                            return deferred.promise;
                        }
                        if (!!address.IsDeleted) {
                            linkedContacts.forEach(function (contact) {
                                contact.IsActive = false;
                                contact.IsDeleted = true;
                                contact.isContactChanged = true;
                            });
                        }
                        if (!!address.isAddressChanged || !!address.extAddressChanged) {
                            linkedContacts.forEach(function (contact) {
                                contact.isContactChanged = true;
                            });
                        }
                        if (!!address.addressStatusChanged) {
                            linkedContacts.forEach(function (contact) {
                                contact.ContactStatusId = address.AdressStatusId;
                                contact.contactStatusChanged = true;
                            });
                        }

                        if (!!address.isAddressChanged || !!address.addressStatusChanged || !!address.extAddressChanged) {
                            backofficeIndividualAdressService.addressSave([address], sessionStorage.Key)
                            .then(function (data) {
                                if (data.Status) {
                                    var savedAddress = {};
                                    if (address.ActionFlag == 'U') {
                                        savedAddress = address;
                                    } else {
                                        savedAddress = data.IndividualAddressResponse.whereEquals(address.AddressTypeId, 'AddressTypeId').orderByDescending('CreatedOn').firstOrDefault();
                                    }

                                    self.saveAllContacts(savedAddress, linkedContacts, deferred);
                                } else {
                                    //Error
                                    deferred.reject(data);
                                }
                            }, function (err) {
                                //Error
                                deferred.reject(err);
                            });
                        } else {
                            self.saveAllContacts(address, linkedContacts, deferred);
                        }
                    } catch (ex) {
                        //Error
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                self.deleteAddress = function (address) {
                    address.IsActive = false;
                    address.IsDeleted = true;

                    self.showLoader('Deleting...');

                    $q.all([self.saveAddress(address)])
                    .then(function () {
                        if (!!$scope.addressesSaved) {
                            $scope.addressesSaved();
                        }
                        $scope.addressContacts.forEach(function (addrCont) {
                            addrCont.addressesSaved();
                        });

                        self.getAllAddressContacts();
                    }, function () {
                        self.hideLoader();
                    });
                };

                self.markAddressPrevious = function (address) {
                    address.AdressStatusId = 2;
                    address.addressStatusChanged = true;

                    self.showLoader('Saving...');

                    $q.all([self.saveAddress(address)])
                    .then(function () {
                        if (!!$scope.addressesSaved) {
                            $scope.addressesSaved();
                        }
                        $scope.addressContacts.forEach(function (addrCont) {
                            addrCont.addressesSaved();
                        });

                        self.getAllAddressContacts();
                    }, function () {
                        self.hideLoader();
                    });
                };

                //Exported
                self.saveChangedAddresses = function () {
                    $scope.sendWait(_childName);

                    var savePromises = [];
                    var addresses = [];
                    var allValid = true;

                    $scope.addressContacts.forEach(function (addrCont) {
                        var currentAddress = addrCont.getCurrentAddress();
                        var validAddress = addrCont.validateAddress();

                        if (!!currentAddress && validAddress) {
                            addresses.push(currentAddress);
                        }

                        if (!validAddress) {
                            allValid = false;
                        }
                    });

                    if (!allValid) {
                        return;
                    }

                    self.showLoader('Saving...'); 
                    
                    addresses.forEach(function (addr) {
                        self.addressChanged(addr);
                        if (!!addr.isAddressChanged || !!addr.addressStatusChanged || !!addr.extAddressChanged) {
                            savePromises.push(self.saveAddress(addr));
                        }
                    });

                    if (savePromises.length > 0) {
                        $q.all(savePromises)
                        .then(function () {
                            if (!!$scope.addressesSaved) {
                                $scope.addressesSaved();
                            }
                            $scope.addressContacts.forEach(function (addrCont) {
                                addrCont.addressesSaved();
                            });

                            self.getAllAddressContacts();
                        }, function (err) {
                            console.error(err);
                            self.hideLoader();
                            $scope.sendCancel();
                        });
                    } else {
                        var promises = [];
                        
                        addresses.forEach(function (addr) {
                            var deferred = $q.defer();
                            var linkedContacts = [];

                            addr.contactsConfig.forEach(function (config) {
                                linkedContacts.push(addr[config.Name]);
                            });

                            promises.push(deferred.promise);
                            self.saveAllContacts(addr, linkedContacts, deferred);            
                        });

                        $q.all(promises)
                        .then(function () {
                            if (!!$scope.addressesSaved) {
                                $scope.addressesSaved();
                            }
                            $scope.addressContacts.forEach(function (addrCont) {
                                addrCont.addressesSaved();
                            });

                            self.getAllAddressContacts();
                        }, function (err) {
                            console.error(err);
                            $scope.sendCancel();
                            self.hideLoader();
                        });
                    }
                };

                self.validateAllAddresses = function () {
                    $scope.sendWait(_childName);

                    var invalidAddresses = [];
                    $scope.addressContacts.forEach(function (addrCont) {
                        if (!addrCont.validateAddress()) {
                            invalidAddresses.push(addrCont);
                        }
                    });

                    if (invalidAddresses.length == 0) {
                        $scope.sendComplete(_childName);
                    } else {
                        $scope.sendCancel();
                    }
                };

                self.publicChanged = function (newPublic) {
                    $scope.addressContacts.forEach(function (addrCont) {
                        addrCont.setPublic(false);
                    });

                    newPublic.IsPublicAddress = true;
                };

                self.mailingChanged = function (newMailing) {
                    $scope.addressContacts.forEach(function (addrCont) {
                        addrCont.setMailing(false);
                    });
                    
                    newMailing.IsMailingSameasPhysical = true;
                };

                self.getBlankAddress = function (addressOption) {
                    return {
                        IndividualAddressId: addressOption.IndividualAddressId,
                        AddressId: addressOption.AddressId,
                        IndividualId: $scope.individualId,
                        AddressTypeId: addressOption.AddressTypeId,
                        AdressStatusId: 1,
                        StreetLine1: '',
                        StreetLine2: '',
                        City: '',
                        StateCode: '',
                        CountryId: $rootScope.DefaultCountryId,
                        Zip: '',
                        IsMailingSameasPhysical: addressOption.IsMailingSameasPhysical,
                        IsPublicAddress: addressOption.IsPublicAddress,
                        CreatedBy: sessionStorage.UserID,
                        CreatedOn: new Date(),
        
                        IsActive: true,
                        IsDeleted: false,
        
                        justCreated: true
                    };
                };

                //Exported
                self.checkAddressIsEmpty = function (addressOption) {
                    if (!addressOption.AttentionTo && 
                        !addressOption.StreetLine1 && 
                        !addressOption.StreetLine2 && 
                        !addressOption.City && !addressOption.StateCode && !addressOption.Zip) {
                        addressOption.isEmpty = true;
                    } else {
                        addressOption.isEmpty = false;
                    }
                };

                //Exported
                self.checkContactIsEmpty = function (contactOption) {
                    if (!contactOption.ContactInfo) {
                        contactOption.isEmpty = true;
                    } else {
                        contactOption.isEmpty = false;
                    }
                };

                //Exported
                self.addressChanged = function (address) {
                    var originalAddress = null;

                    if (!!address.IndividualAddressId) {
                        originalAddress = $scope.originalAddresses.whereEquals(address.IndividualAddressId, 'IndividualAddressId').firstOrDefault();
                    } else {
                        originalAddress = self.getBlankAddress(address);
                    }

                    if (originalAddress.AttentionTo != address.AttentionTo ||
                        originalAddress.StreetLine1 != address.StreetLine1 ||
                        originalAddress.StreetLine2 != address.StreetLine2 ||
                        originalAddress.City != address.City ||
                        originalAddress.StateCode != address.StateCode ||
                        originalAddress.Zip != address.Zip ||
                        originalAddress.CountyId != address.CountyId ||
                        originalAddress.CountryId != address.CountryId || 
                        originalAddress.IsMailingSameasPhysical != address.IsMailingSameasPhysical ||
                        originalAddress.IsPublicAddress != address.IsPublicAddress) {
                        address.isAddressChanged = true;
                    } else {
                        address.isAddressChanged = false;
                    }

                    if (originalAddress.AdressStatusId != address.AdressStatusId) {
                        address.addressStatusChanged = true;
                    } else {
                        address.addressStatusChanged = false;
                    }
                    self.checkAddressIsEmpty(address);
        
                    address.contactsConfig.forEach(function (config) {
                        if (address[config.Name].ContactInfo != address['Original'+config.Name].ContactInfo) {
                            address[config.Name].isContactChanged = true;
                        } else {
                            address[config.Name].isContactChanged = false;
                        }
                        self.checkContactIsEmpty(address[config.Name]);
                    });
                };

                //Exported
                self.getAllAddressContacts = function () {
                    try {
                        self.showLoader('Loading...');
                        
                        $q.all([
                            self.getConfig(),
                            backofficeIndividualAdressService.individualAddressBYIndividualId(sessionStorage.Key, $scope.individualId),
                            backofficeIndividualAdressService.individualContactBYIndividualId(sessionStorage.Key, $scope.individualId),
                            backofficeIndividualAdressService.getAddressTypes(sessionStorage.Key),
                            backofficeIndividualAdressService.getContactTypes(sessionStorage.Key)
                        ])
                        .then(function (data) {
                            //Pass config to children
                            if (!!data[0]) {
                                $scope.addressContacts.forEach(function (addrCont) {
                                    if (!addrCont.hasConfig()) {
                                        addrCont.setConfig(data[0]);
                                    }
                                });
                            }

                            if (data[1].Status && data[2].Status && data[3].Status) {
                                if (!!data[1].IndividualAddressResponse) {
                                    $scope.allAddresses = data[1].IndividualAddressResponse;
                                    $scope.originalAddresses = angular.copy(data[1].IndividualAddressResponse);
                                } else {
                                    $scope.allAddresses = [];
                                    $scope.originalAddresses = [];
                                }
                                
                                if (!!data[2].IndividualContactResponse) {
                                    $scope.allContacts = data[2].IndividualContactResponse;
                                    $scope.originalContacts = angular.copy(data[2].IndividualContactResponse);
                                } else {
                                    $scope.allContacts = [];
                                    $scope.originalContacts = [];
                                }

                                $scope.addressTypes = data[3].AddressTypeGetList;
                                $scope.contactTypes = data[4].ReferenceEntities;

                                $scope.allAddresses.forEach(function (addr) {
                                    addr.AddressTypeName = $scope.addressTypes.whereEquals(addr.AddressTypeId, 'AddressTypeId').select('AddressTypeDesc').firstOrDefault();
                                });
                                $scope.allContacts.forEach(function (cont) {
                                    cont.ContactTypeName = $scope.contactTypes.whereEquals(cont.ContactTypeId, 'ContactTypeId').select('Desc').firstOrDefault();
                                });

                                self.updateAddressContacts();

                                if (!!$scope.addressesLoaded) {
                                    $scope.addressesLoaded({$addresses: $scope.allAddresses});
                                }

                                $scope.sendComplete(_childName);
                                self.hideLoader();
                            }
                        }, function (err) {
                            //Error
                            console.error(err);
                            $scope.sendCancel();
                            self.hideLoader();
                        });
                    } catch (ex) {
                        //Error
                        console.error(ex);
                        $scope.sendCancel();
                        self.hideLoader();
                    }
                };

                $scope.saveChangedAddresses = self.saveChangedAddresses;
                $scope.validateAllAddresses = self.validateAllAddresses;
                var events = {
                    saveInformation: 'saveChangedAddresses()',
                    validateChildren: 'validateAllAddresses()'
                };
                ChildEventHandlerFactory.setupChildEvents($scope, events);
            }]
        };
    }
})();