
(function () {
    'use strict';
    angular
        .module('natApp')
        .controller("BusinessEntityListController", ['$scope', '$state', 'countryStateService', "$q", "WebApiUrl", "utilityService", "$rootScope", "AppConfig", "typeValuesService", "TypeValue", "BusinessEntityService", "backofficeIndividualEducationService", "Messages",
            function ($scope, $state, countryStateService, $q, WebApiUrl, utilityService, $rootScope, AppConfig, typeValuesService, TypeValue, BusinessEntityService, backofficeIndividualEducationService, Messages) {

                $scope.userId = sessionStorage.UserID;
                $scope.ProviderId = null;
                $scope.IsBackoffice = true;
                $scope.applicationTypeId = 3;
                $scope.routeData = $state.current.views.routeData;
                $scope.newCallbackitem = {};
                $scope.sortColumn = "ApprovedDate";
                $scope.sortOrder = "desc";
                $scope.pageSize = 50;
                $scope.currentPage = 1;
                $scope.totalRecords;
                $scope.maxSize = 5;
                $scope.searchData = {};
                $scope.searchdata = true;
                $scope.applicationType = {};
                $scope.applicationReceivedDate = new Date();
                $scope.showProviderDropDown = false;
                $scope.datePicker = {
                    receivedDateOpened: false,
                    format: 'M!/d!/yyyy',
                    receivedDateOptions: {
                        minDate: null,
                    }
                };
                var searchDataObj = {
                    FromRow: "",
                    NumberOfRecords: "",
                    ProviderName: "",
                    LicenseNumber: "",
                    ListFor: "BPL",
                    SortColumn: "",
                    ApplicationTypeIds: 3,
                    HideApprovedApps: true
                };
                if ($scope.routeData.LicenseApplicationForId == 10) {
                    $scope.applicationHeader = 'Establishment Application';
                }
                else if ($scope.routeData.LicenseApplicationForId == 21) {
                    $scope.applicationHeader = 'Branch Application';
                }
                $scope.init = function () {
                    if ($scope.isUserSessionActive()) {
                        getRefTablesGetAll();
                        $scope.searchData = angular.copy(searchDataObj);
                        $scope.getLicenseeRenewalList();
                        $scope.applicationStatusTypeGetAll();
                    } else {
                    }
                };

                $scope.applicationTypeChanged = function (applicationSubTypeId) {
                    $scope.applicationType.LicenseApplicationForId = $scope.initialLicenseApplicationSubTypes.where('item.ApplicationSubTypeId==this', applicationSubTypeId).firstOrDefault().LicenseApplicationForId;
                    $scope.applicationType.LicenseTypeId = $scope.initialLicenseApplicationSubTypes.where('item.ApplicationSubTypeId==this', applicationSubTypeId).firstOrDefault().LicenseTypeId;
                    $scope.routeData.LicenseApplicationForId = $scope.applicationType.LicenseApplicationForId;
                    if ($scope.applicationType.LicenseApplicationForId == 12)
                        $scope.showProviderDropDown = true;
                    else
                        $scope.showProviderDropDown = false;
                };

                $scope.canCreateApplication = function () {
                    if (!!$scope.applicationType.ApplicationSubTypeId)
                        return true;
                    else
                        return false;
                };

                var getRefTablesGetAll = function () {
                    var dataPromise = [];
                    dataPromise.push(BusinessEntityService.getRefTablesGetAll(sessionStorage.Key, 'applicationsubtype'));
                    dataPromise.push(BusinessEntityService.getRefTablesGetAll(sessionStorage.Key, 'licenseapplicationfor'));

                    $q.all(dataPromise)
                        .then(function (res) {
                            var status = true;
                            _.each(res, function (response) {
                                if (!response.Status) {
                                    status = false;
                                }
                            })
                            if (status) {
                                $scope.initialLicenseApplicationTypes = res[0].ReferenceEntities;
                                $scope.initialLicenseApplicationSubTypes = res[1].ReferenceEntities;
                            }
                            console.log($scope.initialLicenseApplicationTypes);
                            console.log($scope.initialLicenseApplicationSubTypes);
                        }, function (error) {
                            console.log('Error');

                        });
                };

                $scope.closeApplication = function () {
                    $scope.isTableDisplay = true;
                    $scope.displayAddApplication = false;
                    $scope.isAddPemit = false;
                    $scope.displayPermitAddApplication = false;
                    $scope.displaySpecialityCertificationAddApplication = false;
                    $scope.displayApplicationTypeSelection = false;
                    $scope.applicationTypeSelection = false;
                    $scope.currentApplication = {};
                };

                $scope.closeEditApplication = function (item) {
                    item.Display = true;
                };

                $scope.showNewApplicationTypeSelection = function () {
                    $scope.applicationType = {};
                    $scope.LicensePathwayLevel1Data = [];
                    $scope.displayApplicationTypeSelection = true;
                    $scope.applicationTypeSelection = false;
                    $scope.isTableDisplay = false;
                };

                $scope.hideApplicationTypeSelection = function () {
                    $scope.applicationType = {};
                    $scope.LicensePathwayLevel1Data = [];
                    $scope.displayApplicationTypeSelection = false;
                    $scope.applicationTypeSelection = false;
                    $scope.isTableDisplay = true;
                };

                $scope.showApplicationType = function () {
                    $scope.closeApplication();
                    $scope.RenewalList.forEach(function (item) {
                        $scope.closeEditApplication(item);
                    });

                    $scope.applicationType = {};
                    $scope.LicensePathwayLevel1Data = [];
                    $scope.displayApplicationTypeSelection = true;
                    $scope.applicationTypeSelection = false;
                    $scope.applicationType.ApplicationSubTypeId = null;
                    $scope.applicationType.LicenseApplicationForId = null;
                    $scope.applicationType.question = null;
                };

                $scope.hideApplicationType = function () {
                    $scope.closeApplication();
                    $scope.RenewalList.forEach(function (item) {
                        $scope.closeEditApplication(item);
                    });

                    $scope.applicationType = {};
                    $scope.LicensePathwayLevel1Data = [];
                    $scope.displayApplicationTypeSelection = false;
                    $scope.applicationTypeSelection = false;
                    $scope.applicationType.ApplicationSubTypeId = null;
                    $scope.applicationType.LicenseApplicationForId = null;
                    $scope.applicationType.question = null;
                };

                $scope.headers = {
                    ProviderName: { name: 'Business Name', sortColumn: 'ProviderName', sortOrder: 'asc' },
                    ProviderStatusTypeId: { name: 'Application Type', sortColumn: 'ProviderStatusTypeId', sortOrder: 'asc' },
                    submittedDate: { name: 'Submitted Date', sortColumn: 'SubmittedDate', sortOrder: 'asc' },
                    ReceivedDate: { name: 'Received Date', sortColumn: 'ReceivedDate', sortOrder: 'asc' },
                    approvedDate: { name: 'Approved Date', sortColumn: 'ApprovedDate', sortOrder: 'desc' },
                    applicationStatus: { name: 'Status', sortColumn: 'StatusName', sortOrder: 'asc' }
                };
                $scope.pageChanged = function () {
                    hideStatusMessage();
                    $scope.getLicenseeRenewalList();
                };
                $scope.SortRecords = function (header) {
                    $scope.sortColumn = header.sortColumn;
                    $scope.sortOrder = header.sortOrder;
                    header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
                    $scope.getLicenseeRenewalList();
                };
                $scope.CancelSearchClick = function () {
                    $scope.searchData.ProviderName = '';
                    $scope.searchData.ApplicationStatusId = '';
                    $scope.searchData.HideApprovedApps = false;
                    $scope.getLicenseeRenewalList();
                };
                $scope.getAllLicenseeRenewalList = function () {
                    $scope.searchData.HideApprovedApps = false;
                    $scope.getLicenseeRenewalList(true);
                };
                $scope.getLicenseeRenewalList = function (showAllRecords) {
                    $scope.hidePaging = !!showAllRecords;
                    $scope.ShowLicenseAdd = false;
                    $scope.showProviderDropDown = false;
                    ShowLoader("Please Wait...");
                    $scope.gridRenewalList = true;
                    $scope.totalRecords = 0;
                    $scope.RenewalList = [];
                    $scope.searchData.ApplicationTypeIds = 3;
                    BusinessEntityService.GetProviderApplicationList(sessionStorage.Key, $scope.searchData)
                        .then(function (response) {
                            if (response.data.Status) {
                                $scope.totalRecords = response.data.TotalRecord;
                                $scope.RenewalList = response.data.ProviderApplicationList;
                               // $scope.RenewalList = _.filter(response.data.ProviderApplicationList, function (o) { return o.LicenseApplicationForId == $scope.routeData.LicenseApplicationForId; });
                                HideLoader();
                            } else {
                                HideLoader();
                            }
                        }, function (data) {
                            HideLoader();
                            showStatusMessage(data, "error");
                        });
                };

                // Get LicenseStatus
                $scope.applicationStatusTypeGetAll = function () {
                    try {
                        typeValuesService.getTypeValuesByTypeName(TypeValue.ApplicationStatus, sessionStorage.Key, false, true)
                            .then(function (response) {
                                if ($scope.checkServerResponse(response)) {
                                    $scope.ApplicationStatusList = response.ApplicationStatusList;
                                }
                            }, function (data) {
                                showStatusMessage(data, "error");
                            });
                    } catch (ex) {
                        if (ex != null) { showStatusMessage(ex.message, "error"); }
                    }
                }
                var resetRenewalListing = function () {
                    angular.forEach($scope.RenewalList, function (renewalItem, key) {
                        renewalItem.ShowInternalForm = false;
                        renewalItem.ShowEmailForm = false;
                        renewalItem.ShowLicenseOutputForm = false;
                    });
                };
                $scope.openLicenseOutputForm = function (renewalItem) {
                    $scope.DisplayAddApplication = false;
                    $scope.currentItem = renewalItem;
                    renewalItem.licenseInfoValidation = {};
                    resetRenewalListing();
                    renewalItem.ShowLicenseOutputForm = true;
                    sessionStorage.IndividualId = renewalItem.IndividualId;
                    //  hideStatusMessage();
                };
                $scope.closeAddnewEntityform = function () {
                    $scope.ShowLicenseAdd = false;
                    $scope.showProviderDropDown = false;
                    if ($scope.currentItem) {
                        $scope.currentItem.ShowInternalForm = false;
                    }
                };
                $scope.generateAndSendLicenseOutput = function () {
                    $scope.$broadcast("SendLicenseOutputEmail", {});
                };
                $scope.closeLicenseOutputForm = function () {
                    $scope.DisplayAddApplication = false;
                    $scope.IsPendingApplication = false;
                    resetRenewalListing();
                    $scope.currentRenewalItem = null;
                    //$scope.IsSearch = true;
                    // $scope.clearApplication();
                };
                $scope.updatePaymentAndStatus = function (data) {

                    for (var i = 0; i < $scope.RenewalList.length; i++) {
                        if ($scope.RenewalList[i].ApplicationId == data.applicationId) {
                            // if (data.updateStatusOnly == false) {
                            $scope.RenewalList[i].IsPaymentProcessed = true;
                            //  }
                            $scope.RenewalList[i].ApplicationStatusId = data.applicationStatusId;
                            for (var j = 0; j < $scope.ApplicationStatusList.length; j++) {
                                if ($scope.ApplicationStatusList[j].ApplicationStatusId == data.applicationStatusId) {
                                    $scope.RenewalList[i].AppStatus = $scope.ApplicationStatusList[j].Name;
                                    break;
                                }
                            }
                            break;
                        }
                    }
                    $state.reload();
                }

                $scope.$on('updatePaymentAndStatus', function (event, data) {
                    $scope.updatePaymentAndStatus(data);
                });
                $scope.loadTypeahed = function (searchString, searchType) {
                    return BusinessEntityService.getEmployerList(
                        sessionStorage.Key, searchString, 5, searchType
                    ).then(function (response) {
                        if (!!response.Employers) {
                            return response.Employers.filter(function(Employer){return Employer.ProviderStatusTypeId == 1}).map(function (item) {
                                item.formattedResult = (item.EmployerName + " " + item.StreetLine1 + " " + item.StreetLine2 + " " + item.City + " " + item.Zip);
                                return item;
                            });
                        }
                    }, function (res) {

                    });
                };

                $scope.SelectTypeAheadItem = function (selectedItem, $model, $label, $event) {
                    $scope.ProviderParentId = selectedItem.ProviderId;
                    $scope.selectedItems = selectedItem;
                    $scope.addNewApplication();
                };

                $scope.selectProvider = function () {
                    $scope.showProviderDropDown = true;
                    $scope.searchdata = false;
                };
                $scope.addNewApplication = function () {
                    ShowLoader("Please Wait...");


                    $scope.openPaymentTab = false;
                    $scope.displayApplicationTypeSelection = false;
                    var effectiveDate = new Date();
                    var expirationDate = new Date();
                    $scope.currentApplication = {
                        effectiveDate: effectiveDate
                    };
                    expirationDate.setFullYear(expirationDate.getFullYear() + 2);
                    $scope.currentApplication.expirationDate = expirationDate;
                    generateApplicationId(0, $scope.userId);
                    //$scope.IsSearch = false;
                };

                var generateApplicationId = function (applicationID, userID) {
                    var reqData = {
                        ApplicationTypeId: 3,
                        ApplicationStatusId: 14,
                        ApplicationSubmitMode: 'B',
                        UserId: userID,
                        GoPaperless: true,
                        IsDeleted: 0,
                        IsActive: 1,
                        IsArchive: 0,
                        ReceivedDate: $scope.applicationReceivedDate,
                        LicenseApplicationForId: $scope.routeData.LicenseApplicationForId,
                        EntityName: 'BusinessEntity',// $scope.applicationType.EntityName,
                        LicenseTypeId: $scope.applicationType.LicenseTypeId,
                        LicensePathwayLevel1Id: null //Number($scope.applicationType.LicensePathwayTyepId)
                    };
                    // ProviderTypeId: 5,
                    BusinessEntityService.generateApplicationId(sessionStorage.Key, applicationID, userID, reqData).then(function (res) {
                        if ($scope.checkServerResponse(res)) {
                            $scope.ApplicationId = res.ApplicationId;
                            $scope.ApplicationNumber = res.ApplicationNumber;
                            $scope.currentApplication.ApplicationID = res.ApplicationId;

                            // $scope.Application = res;
                            var reqDataSaveProvider = {
                                // IndividualPlaceofBirthState: 'O',
                                Gender: '',
                                IsActive: true,
                                ApplicationId: res.ApplicationId,
                                ProviderTypeId: 5,
                                ProviderStatusTypeId: 9,
                                DepartmentId: 1,
                                ProviderParentId: $scope.ProviderParentId
                            }

                            backofficeIndividualEducationService.saveProvider(sessionStorage.Key, $scope.ApplicationId, $scope.userId, reqDataSaveProvider).then(function (res) {

                                if ($scope.checkServerResponse(res)) {

                                    $scope.ProviderId = res.ProviderId;
                                    //  $scope.currentApplication.IndividualID = $scope.IndividualId;
                                    createApplicationProcess(userID);
                                    //$scope.ShowLicenseAdd = true;
                                } else {
                                    HideLoader();
                                    $scope.showStatusMessage(Messages.General.Errors.SomeError, "error");
                                }
                            }, function (res) {
                                $scope.showStatusMessage(res.Message, "error");
                            });
                        }
                        else {
                            HideLoader();
                            $scope.showStatusMessage(Messages.General.Errors.SomeError, "error");
                        }
                    }, function (res) {
                        $scope.showStatusMessage(res.Message, "error");
                    });
                };

                var createApplicationProcess = function (userID) {
                    var promiseSaveIndividualApplication = saveIndividualApplication($scope.ProviderId, $scope.ApplicationId, userID);
                    // var promiseSaveIndividualNameTypeLk = saveIndividualNameTypeLk($scope.ApplicationId, $scope.ProviderId);
                    var promiseIndividualContactSave = individualContactSave($scope.ProviderId);
                    var promissApplicationStatusLkSave = applicationStatusLkSave(userID, 3);
                    $q.all([promiseSaveIndividualApplication, promissApplicationStatusLkSave])
                        .then(function (res) {
                            var isSavedSuccessFully = true;
                            var errMessage = '';
                            angular.forEach(res, function (resData, key) {
                                if (resData.Status == false) {
                                    isSavedSuccessFully = false;
                                    errMessage += resData.Message;
                                }
                            });
                            if (isSavedSuccessFully) {
                                $scope.ShowLicenseAdd = true;
                                $scope.showProviderDropDown = false;
                            } else {
                                $scope.showStatusMessage(errMessage, "error");
                            }
                            HideLoader();
                        }, function (res) {
                            HideLoader();
                            $scope.showStatusMessage(res.Message, "error");
                        });
                }
                var applicationStatusLkSave = function (userID, ApplicationTypeId) {
                    var reqData = {
                        ApplicationId: $scope.ApplicationId,
                        ApplicationTypeId: ApplicationTypeId,
                        ApplicationStatusId: 14,
                        ApplicationStatusReasonId: 0,
                        IsActive: 1,
                        IsDeleted: 0,
                        IsArchive: 0,
                        CreatedBy: userID,
                        ApplicationInternalStatusReasonText: '',
                        ApplicationInternalStatusId: 0,
                        HighPriority: false
                    };

                    return BusinessEntityService.applicationStatusLkSave(sessionStorage.Key, userID, reqData);
                }

                var saveIndividualApplication = function (IndividulaID, AppID, userID) {
                    var reqData = {
                        ApplicationId: AppID,
                        ProviderId: IndividulaID,
                        CreatedBy: userID,
                        CreatedOn: new Date()
                    };

                    return BusinessEntityService.saveProviderApplication(sessionStorage.Key, reqData);
                };

                var individualContactSave = function (indvID) {
                    var reqData = {
                        ProviderId: indvID,
                        ContactId: 0,
                        ContactTypeId: 8,
                        IsActive: 1,
                        IsDeleted: 0
                    };

                    return BusinessEntityService.providerContactSave(sessionStorage.Key, [reqData]);
                };

                $scope.openRenewalApplication = function (Item) {
                    if ($scope.currentItem) {
                        $scope.currentItem.ShowInternalForm = false;
                    }

                    $scope.routeData.LicenseApplicationForId = Item.LicenseApplicationForId;
                    $scope.applicationStatus = Item.ApplicationStatusId;
                    Item.ShowInternalForm = true;
                    $scope.currentItem = Item;


                    // $scope.ProceedtoPaymentSectionEnable = $scope.currentItem.IsPaymentProcessed;
                    // $scope.staffReviewEnable = $scope.currentItem.IsPaymentProcessed;
                }




            }]);
})();