(function() {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeIndividualPermitService', backofficeIndividualPermitService)

    backofficeIndividualPermitService.$inject = ['$http', '$q', 'WebApiUrl']

    function backofficeIndividualPermitService($http, $q, WebApiUrl) {

        var _individualPermitByIndividualId = function(Key, IndividualId, permitType) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/GetAllPermitByIndividualIdPermitTypeId/' + Key + '?IndividualId=' + IndividualId + '&PermitTypeId=' + permitType));
        };

        var _savePermitInfo = function(objPermits, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/SavePermit/' + key + "?Source=PermitRenewal", objPermits));
        };

        var _generateApplicationId = function(userId, reqData, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Application/GenerateApplicationId/' + key + '?ApplicationId=0&UserId=' + userId, reqData));
        };

        var _saveIndividualApplication = function(reqData, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/SaveIndividualApplication/' + key, reqData));
        };

        var _getpermitsiteAddress = function(individualId,individualLicenceId,key){
            return returnDefferedResult($http.post(WebApiUrl+'Individual/GetPermitSiteAddress/'+key+'?individualid='+individualId+'&individualLicenceId='+individualLicenceId));
        };

        

        var returnDefferedResult = function(request) {
            var deferred = $q.defer();
            request.then(function(response) {
                deferred.resolve(response.data);
            }, function(response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        return {
            individualPermitByIndividualId: _individualPermitByIndividualId,
            savePermitInfo: _savePermitInfo,
            generateApplicationId: _generateApplicationId,
            saveIndividualApplication: _saveIndividualApplication,
            getPermitSiteAddress :_getpermitsiteAddress
        }
    }
})();