(function () {
    'use strict'

    angular.module('app.core')
    .factory('BulkUploadService', ['$http', '$q', 'WebApiUrl', 'utilityService', BulkUploadService]);

    function BulkUploadService($http, $q, WebApiUrl, utilityService) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _downloadTemplateFile = function (key, urlParams) {
            var httpPromise = $http.get(WebApiUrl + 'BulkUpload/DownloadBulkUploadTemplateFile/' + key + '/', { params: urlParams, responseType: 'arraybuffer' });
            return responseHandler(httpPromise, function(res) {
                var headers = res.headers();
                if (res.status == 200) {
                    utilityService.downloadExcelFile(res.data, headers);
                }
            }, function (err) {
                
            });
        };

        var _runBulkUploadOperation = function (key, request) {
            return responseHandler($http.post(WebApiUrl + 'BulkUpload/RunBulkUploadOperation/' + key + '/', request));  
        };

        var _getOperationProgress = function (key, urlParams) {
            return responseHandler($http.get(WebApiUrl + 'AsyncOperation/GetAsyncOperationProgress/' + key + '/', { params: urlParams }));
        };

        var _downloadResultFile = function (key, urlParams) {
            var httpPromise = $http.get(WebApiUrl + 'AsyncOperation/DownloadAsyncOperationFile/' + key + '/', { params: urlParams, responseType: 'arraybuffer' });
            return responseHandler(httpPromise, function(res) {
                var headers = res.headers();
                if (res.status == 200) {
                    utilityService.downloadExcelFile(res.data, headers);
                }
            }, function (err) {
                
            });
        };
        
        return {
            downloadTemplateFile: _downloadTemplateFile,
            runBulkUploadOperation: _runBulkUploadOperation,
            getOperationProgress: _getOperationProgress,
            downloadResultFile: _downloadResultFile
        };
    }
})();