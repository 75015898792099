(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .filter('applicationPathwayFilter', [function () {
            return function (pathwayList, applicationSubType) {
                return pathwayList.whereEquals(applicationSubType, 'ApplicationSubTypeId');
            };
        }])
        .controller('OnLineInitialLicensureApplicationController', OnLineInitialLicensureApplicationController);

    OnLineInitialLicensureApplicationController.$inject = ['$rootScope', '$scope', '$q', 'TypeValue', 'typeValuesService', 'licenseApplicationListingService', 'WebApiUrl', '$state', 'personalService', 'ApplicationSettings', 'Messages'];

    function OnLineInitialLicensureApplicationController($rootScope, $scope, $q, TypeValue, typeValuesService, licenseApplicationListingService, WebApiUrl, $state, personalService, ApplicationSettings, Messages) {
        // BEGIN GENERAL
        var getNewSearch = function () {
            return {
                SearchByName: '',
                Application: '',
                SSN: '',
                Name: '',
                LastName: '',
                FirstName: '',
                Phone: '',
                Status: '',
                DateOfBirth: '',
                LicenseNumber: '',
                Email: '',
                Address: '',
                LicenseTypeId: 0,
                LicenseStatusTypeId: 0,

                IsActive: true,
                SortColumn: 'LastName asc, FirstName',
                SortOrder: 'asc'
            };
        };

        $scope.getIndividual = function () {
            var deferred = $q.defer();

            licenseApplicationListingService.getIndividualByIndividualId(sessionStorage.Key, $scope.IndividualId)
                .then(function (individuals) {
                    if (individuals.Status) {
                        $scope.individual = individuals.IndividualResponse.firstOrDefault();
                        deferred.resolve(individuals);
                    } else {
                        deferred.reject(individuals);
                    }
                    HideLoader();
                }, function (err) {
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        $scope.init = function () {
            $scope.listConfig = {};
            $scope.UserId = sessionStorage.UserID;
            $scope.search = getNewSearch();
            $scope.CertificateAppList = [];

            if ($scope.isUserSessionActive()) {
                ShowLoader("Please Wait...");

                $scope.getApplicationStatusList();

                $q.all([
                    $scope.getApplicationList(),
                    $scope.getIndividual()
                ])
                    .then(function () {
                        if (!!$scope.currentApplication) {
                            $scope.ApplicationId = $scope.currentApplication.ApplicationId;
                            $scope.ApplicationTypeId = $scope.currentApplication.ApplicationTypeId;
                            $scope.applicationNumber = $scope.currentApplication.ApplicationNumber;

                            $scope.displayApplicationTypeSelection = false;
                            $scope.applicationTypeSelection = false;
                            $scope.displayAddApplication = true;
                        } else {
                            $scope.currentApplication = {};
                            $scope.showApplicationType();
                        }
                    }, function (error) {

                    });
                //getRefTablesGetAll();
            } else {
                $state.go('app.BackofficeLoginstaff')
            }
        };
        // END GENERAL

        // BEGIN ADD APPLICATION 
        $scope.displayAddApplication = false;
        $scope.indPager = {
            totalItems: 0,
            itemsPerPage: 10,
            fromRow: 0,
            currentPage: 1,
            maxSize: 6
        };

        $scope.indPageChanged = function () {
            ShowLoader("Please Wait...");
            $scope.indPager.fromRow = ($scope.indPager.currentPage * $scope.indPager.itemsPerPage) - $scope.indPager.itemsPerPage;
            $scope.getIndividualSearchWithPage();
        };

        $scope.getIndividualSearchWithPage = function () {
            var reqdata = $scope.search;

            reqdata.FromRow = $scope.indPager.fromRow;
            reqdata.ItemsPerPage = $scope.indPager.itemsPerPage;
            reqdata.UserId = $scope.userId;

            ShowLoader();

            licenseApplicationListingService.getIndividualSearchWithPage(sessionStorage.Key, $scope.indPager.currentPage, $scope.indPager.itemsPerPage, reqdata)
                .then(function (response) {
                    if (response.Status) {
                        $scope.IndividualList = response.IndividualList;

                        $scope.indPager.totalItems = response.Total_Recard;

                        console.log($scope.IndividualList);
                    } else {
                        $scope.selectedLicense = null;
                        $scope.applicationTypeSelection = true;
                        $scope.IndividualList = null;
                    }
                    HideLoader();
                }, function (error) {
                    HideLoader();
                });
        };

        $scope.addLicense = function (item) {
            $scope.selectedLicense = item;
            ShowLoader();

            backofficeIndividualLicenseService.individualLicenseDetailBYIndividualId(sessionStorage.Key, item.IndividualId)
                .then(function (data) {
                    if (data.Status) {
                        $scope.selectedLicense.existingLicenseTypes = data.IndividualLicenseList.select('LicenseTypeId');

                        $scope.applicationTypeSelection = true;
                    } else {
                        //Show Error
                        $scope.showApplistMsg('-', data.Message);
                    }
                    HideLoader();
                }, function (err) {
                    console.log(err);
                    HideLoader();
                });
        };
        // END ADD APPLICATION

        // BEGIN APPLICATION TYPE SELECTION
        $scope.displayApplicationTypeSelection = false;
        // $scope.applicationTypeSelection = false;

        // $scope.licenseAlreadyExists = function () {
        //     return !!$scope.selectedLicense && $scope.selectedLicense.existingLicenseTypes.includes($scope.applicationType.LicenseTypeId);
        // };

        // $scope.clearApplicationTypeSelection = function () {
        //     $scope.applicationType.ApplicationSubTypeId = null;                
        //     $scope.applicationType.LicenseApplicationForId = null;
        //     $scope.applicationType.LicenseTypeId = null;                
        // };

        // $scope.applicationTypeChanged = function (levelData, levelType) {
        //     if (levelType == 'subType') {

        //     } else if (levelType == 'appFor') {
        //         $scope.applicationType.LicenseTypeId = levelData.LicenseTypeId;
        //     } 
        //     console.group('Application Selection');
        //     console.log($scope.applicationType.ApplicationSubTypeId);
        //     console.log($scope.applicationType.LicenseApplicationForId);
        //     console.log($scope.applicationType.LicenseTypeId);
        //     console.groupEnd();

        //     if ($scope.licenseAlreadyExists()) {
        //         $scope.showApptypeMsg('-', 'User already has a credential of that type');
        //     }
        // };

        // $scope.canCreateApplication = function () {
        //     return !!$scope.applicationType && 
        //         !!$scope.applicationType.ApplicationSubTypeId && 
        //         !!$scope.applicationType.LicenseApplicationForId && 
        //         !!$scope.applicationType.LicenseTypeId &&
        //         !$scope.licenseAlreadyExists();
        // };

        // $scope.showNewApplicationTypeSelection = function () {
        //     $scope.applicationType = {};
        //     $scope.LicensePathwayLevel1Data = [];
        //     $scope.displayApplicationTypeSelection = true;
        //     $scope.applicationTypeSelection = false;
        //     $scope.isTableDisplay = false;
        // };

        // $scope.hideApplicationTypeSelection = function () {
        //     $scope.applicationType = {};
        //     $scope.LicensePathwayLevel1Data = [];
        //     $scope.displayApplicationTypeSelection = false;
        //     $scope.applicationTypeSelection = false;
        //     $scope.isTableDisplay = true;
        // };

        var validateApplicationTypeForm = function () {
            $scope.hideStatusMessage();
            if (!$scope.applicationType.ApplicationSubTypeId) {
                $scope.showStatusMessage(Messages.General.Errors.SelectApp, "error");
                return false;
            } else if (!$scope.applicationType.LicenseApplicationForId) {
                $scope.showStatusMessage(Messages.General.Errors.SelectSubApp, "error");
                return false;
            }
            return true;
        };

        // var getRefTablesGetAll = function () {
        //     var dataPromise = [];
        //     dataPromise.push(licenseApplicationListingService.getRefTablesGetAll(sessionStorage.Key, 'applicationsubtype'));
        //     dataPromise.push(licenseApplicationListingService.getRefTablesGetAll(sessionStorage.Key, 'licenseapplicationfor'));

        //     $q.all(dataPromise)
        //     .then(function (res) {
        //         var status = true;
        //         _.each(res, function (response) {
        //             if (!response.Status) {
        //                 status = false;
        //             }
        //         })
        //         if (status) {
        //             $scope.initialLicenseApplicationTypes = res[0].ReferenceEntities;
        //             $scope.initialLicenseApplicationSubTypes = res[1].ReferenceEntities;
        //         }
        //         console.log($scope.initialLicenseApplicationTypes);
        //         console.log($scope.initialLicenseApplicationSubTypes);
        //     }, function (error) {
        //         console.log('Error');

        //     });
        // };

        $scope.showApplicationType = function () {
            $scope.closeApplication();
            $scope.CertificateAppList.forEach(function (item) {
                $scope.closeEditApplication(item);
            });

            $scope.applicationType = {};
            $scope.LicensePathwayLevel1Data = [];
            $scope.displayApplicationTypeSelection = true;
            $scope.applicationTypeSelection = false;
            $scope.applicationType.ApplicationSubTypeId = null;
            $scope.applicationType.LicenseApplicationForId = null;
            $scope.applicationType.question = null;
        };

        $scope.hideApplicationType = function () {
            $scope.closeApplication();
            $scope.CertificateAppList.forEach(function (item) {
                $scope.closeEditApplication(item);
            });

            $scope.applicationType = {};
            $scope.LicensePathwayLevel1Data = [];
            $scope.displayApplicationTypeSelection = false;
            $scope.applicationTypeSelection = false;
            $scope.applicationType.ApplicationSubTypeId = null;
            $scope.applicationType.LicenseApplicationForId = null;
            $scope.applicationType.question = null;
        };

        $scope.applicationTypeSelected = function (appType) {
            $scope.applicationType = appType;

            $scope.addNewApplication();
        };
        // END APPLICATION TYPE SELECTION

        // BEGIN CREATE APPLICATION
        var getNewLicenseEntry = function () {
            return {
                IndividualId: $scope.IndividualId,
                ApplicationId: $scope.ApplicationId,
                ApplicationTypeId: $scope.ApplicationTypeId,
                IsLicenseTemporary: $scope.applicationType.IsTemporary,
                IsLicenseActive: false,
                LicenseNumber: '',
                LicenseTypeId: $scope.applicationType.LicenseTypeId,
                LicenseStatusTypeId: 17, //APPLICATION IN PROCESS
                IsActive: true,
                IsDeleted: false,

                ActionFlag: 'N' //Unfortunately, this is the only way to create a new license, and it ignores the IsLicenseActive
            };
        };

        var deactivateLicense = function (data) {
            var newIndLicense = data.IndividualLicenseList.whereEquals($scope.ApplicationId, 'ApplicationId').firstOrDefault();
            newIndLicense.IsLicenseActive = false;
            newIndLicense.ActionFlag = 'M';
            return individualLicenseSave(newIndLicense);
        };

        var saveIndividualApplication = function (IndividulaID, AppID, userID) {
            var reqData = {
                ApplicationId: AppID,
                IndividualId: IndividulaID,
                CreatedBy: userID,
                CreatedOn: new Date()
            };

            return licenseApplicationListingService.saveIndividualApplication(sessionStorage.Key, reqData);
        };

        var saveIndividualNameTypeLk = function (appID, indvID) {
            var reqData = {
                IndividualId: indvID,
                IndividualNameTypeId: 1,
                IndividualNameStatusId: 14,
                ApplicationId: appID,
                IsActive: 1,
                IsDeleted: 0,
            };

            return licenseApplicationListingService.saveIndividualNameTypeLk(sessionStorage.Key, reqData);
        };

        var individualLicenseSave = function (newLicense) {
            return licenseApplicationListingService.saveIndividualLicense(sessionStorage.Key, newLicense);
        };

        var createApplicationProcess = function (userID) {
            var promiseSaveIndividualApplication = saveIndividualApplication($scope.IndividualId, $scope.ApplicationId, userID);
            var promiseSaveIndividualNameTypeLk = saveIndividualNameTypeLk($scope.ApplicationId, $scope.IndividualId);
            var promiseIndividualLicenseSave = individualLicenseSave(getNewLicenseEntry());

            $q.all([promiseSaveIndividualApplication, promiseSaveIndividualNameTypeLk, promiseIndividualLicenseSave])
                .then(function (res) {
                    var isSavedSuccessFully = true;
                    var errMessage = '';
                    angular.forEach(res, function (resData, key) {
                        if (resData.Status == false) {
                            isSavedSuccessFully = false;
                            errMessage += resData.Message;
                        }
                    });
                    if (isSavedSuccessFully) {
                        // deactivateLicense(res[2])
                        //     .then(function(data) {
                        $scope.displayApplicationTypeSelection = false;
                        $scope.applicationTypeSelection = false;
                        $scope.displayAddApplication = true;
                        //   }, function(error) {});
                    } else {
                        $scope.showStatusMessage(errMessage, "error");
                    }
                    HideLoader();
                }, function (res) {
                    HideLoader();
                    $scope.showStatusMessage(res.Message, "error");
                });
        };

        var generateApplicationId = function (applicationID, userID) {
            var reqData = {};
            reqData = {
                ApplicationTypeId: $scope.ApplicationTypeId,
                ApplicationSubTypeId: 1,
                ApplicationStatusId: 1,
                ApplicationSubmitMode: 'O',
                UserId: userID,
                GoPaperless: true,
                IsDeleted: 0,
                IsActive: 1,
                IsArchive: 0,
                LicenseApplicationForId: $scope.applicationType.LicenseApplicationForId,
                LicensePathwayLevel1Id: $scope.applicationType.LicensePathwayLevel1Id,
                LicenseExamTypeId: $scope.applicationType.LicenseExamTypeId,
                LicensePathwayLevel2Id: $scope.applicationType.LicensePathwayLevel2Id,
                LicensePathwayLevel3Id: $scope.applicationType.LicensePathwayLevel3Id,
                EntityName: $scope.applicationType.EntityName,
                LicenseTypeId: $scope.applicationType.LicenseTypeId,
                IsTemporary: $scope.applicationType.IsTemporary
            };

            licenseApplicationListingService.generateApplicationId(sessionStorage.Key, applicationID, userID, reqData).then(function (res) {
                if ($scope.checkServerResponse(res)) {
                    $scope.ApplicationId = res.ApplicationId;
                    $scope.ApplicationTypeId = res.ApplicationTypeId;
                    $scope.applicationNumber = res.ApplicationNumber;
                    $scope.currentApplication.ApplicationID = res.ApplicationId;
                    $scope.currentApplication.ApplicationStatusId = reqData.ApplicationStatusId;

                    $scope.IndividualId = parseInt(sessionStorage.IndividualId);
                    $scope.currentApplication.IndividualID = $scope.IndividualId;
                    $scope.selectedLicense = null;
                    createApplicationProcess(userID);
                } else {
                    HideLoader();
                    $scope.showStatusMessage(Messages.General.Errors.SomeError, "error");
                }
            }, function (res) {
                HideLoader();
                $scope.showStatusMessage(res.Message, "error");
            });
        };

        $scope.addNewApplication = function () {
            ShowLoader("Please Wait...");
            if (!validateApplicationTypeForm()) {
                HideLoader();
                return;
            }

            // $scope.getFeeDetails()
            //     .then(function() {
            $scope.openPaymentTab = false;
            $scope.displayApplicationTypeSelection = false;
            $scope.applicationTypeSelection = false;

            var effectiveDate = new Date();
            var expirationDate = new Date();
            $scope.currentApplication = {
                effectiveDate: effectiveDate
            };
            expirationDate.setFullYear(expirationDate.getFullYear() + 2);
            $scope.currentApplication.expirationDate = expirationDate;

            generateApplicationId(0, $scope.userId);
            // }, function(err) {
            //     $scope.showStatusMsg('-', 'Could not retrieve or could not find fees for selected credential definition.');
            //     HideLoader();
            // });
        };

        $scope.moveToDashboard = function (applicationInfo) {
            $state.go("app.Dashboard");
        };

        var getFeeDetailsByAppType = function () {
            var feesForLicenseType = $scope.feeDetailRows
                .whereEquals($scope.ApplicationTypeId, 'ApplicationTypeId')
                .whereEquals($scope.applicationType.LicenseTypeId, 'LicenseTypeId');

            if (!$scope.applicationType.IsTemporary) {
                feesForLicenseType = feesForLicenseType.whereNotEquals('TEMP', 'FeeTypeCode');
            }

            if (feesForLicenseType.length > 0) {
                return {
                    fees: feesForLicenseType,
                    applicationFee: feesForLicenseType.sum('FeeAmount'),
                    totalFee: feesForLicenseType.sum('FeeAmount'),
                    isNewLicEndApp: (!!$scope.isNewLicEndApp)
                };
            } else {
                throw 'No fees associated with this license type';
            }
        };

        $scope.getFeeDetails = function () {
            var deferred = $q.defer();
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=feedetails")).then(function (responseFee) {
                if (responseFee.Status) {
                    try {
                        $scope.feeDetailRows = responseFee.ReferenceEntities;
                        $scope.feeDetails = getFeeDetailsByAppType();
                        deferred.resolve();
                    } catch (ex) {
                        deferred.reject(ex);
                    }
                }
            }, function (error) {
                deferred.reject(error);
                showStatusMessage(error, "error");
            });

            return deferred.promise;
        };

        $scope.pageChanged = function () {
            ShowLoader("Please Wait...");
            $scope.pager.fromRow = ($scope.pager.currentPage * $scope.pager.itemsPerPage) - $scope.pager.itemsPerPage;
            $scope.getApplicationList();
        };
        // END CREATE APPLICATION

        // BEGIN APPLICATION
        $scope.ApplicationTypeId = 2;
        $scope.ApplicationId = 0;
        $scope.applicationNumber = 0;
        $scope.IndividualId = parseInt(sessionStorage.IndividualId);
        $scope.currentApplication = {};

        $scope.closeApplication = function () {
            $scope.isTableDisplay = true;
            $scope.displayAddApplication = false;
            $scope.isAddPemit = false;
            $scope.displayPermitAddApplication = false;
            $scope.displaySpecialityCertificationAddApplication = false;
            $scope.displayApplicationTypeSelection = false;
            $scope.applicationTypeSelection = false;
            $scope.currentApplication = {};
        };

        $scope.closeEditApplication = function (item) {
            item.Display = true;
        };

        //Currently not used in Initial Application - Staff Review Tab approves the application
        $scope.ApproveApplication = function (item) {
            if (!item.licenseNumber) {
                alert(Messages.aaaccef);
                return;
            }

            ShowLoader();

            licenseApplicationListingService.getIndividualLicenseBYIndividualId(sessionStorage.Key, item.IndividualId)
                .then(function (data) {
                    var indLicense = data.IndividualLicenseList.whereEquals(item.ApplicationID, 'ApplicationId').firstOrDefault();

                    var reqData = {
                        IndividualLicenseId: indLicense.IndividualLicenseId,
                        IndividualId: item.IndividualID,
                        ApplicationId: item.ApplicationID,
                        LicenseNumber: item.licenseNumber,
                        ActionFlag: 'M',
                        ApplicationTypeId: 2,
                        LicenseTypeId: 1,
                        IsLicenseTemporary: false,
                        IsLicenseActive: true,
                        OriginalLicenseDate: item.effectiveDate,
                        LicenseEffectiveDate: item.effectiveDate,
                        LicenseExpirationDate: item.expirationDate,
                        LicenseStatusTypeId: 1,
                        IsActive: true,
                        IsDeleted: false,
                        LicenseStatusTypeCode: 'ACT',
                        LicenseStatusTypeName: 'Active',
                        ApplicationTypeName: "New Certification Application"
                    };

                    licenseApplicationListingService.saveIndividualLicense(sessionStorage.Key, reqData)
                        .then(function (response) {
                            licenseApplicationListingService.applicationStatusUpdate(sessionStorage.Key, item.ApplicationID, 3, {})
                                .then(function (response) {
                                    HideLoader();
                                    $scope.updatePaymentAndStatus({
                                        applicationId: item.ApplicationID,
                                        applicationStatusId: 3,
                                        updateStatusOnly: true
                                    });
                                });
                        }, function (error) {

                        });
                }, function (error) {

                });
        };

        $scope.updatePaymentAndStatus = function (data) {
            for (var i = 0; i < $scope.CertificateAppList.length; i++) {
                if ($scope.CertificateAppList[i].ApplicationID == data.applicationId) {
                    if (data.updateStatusOnly == false) {
                        $scope.CertificateAppList[i].IsPaymentProcessed = true;
                    }
                    $scope.CertificateAppList[i].ApplicationStatusId = data.applicationStatusId;
                    for (var j = 0; j < $scope.ApplicationStatusList.length; j++) {
                        if ($scope.ApplicationStatusList[j].ApplicationStatusId == data.applicationStatusId) {
                            $scope.CertificateAppList[i].AppStatus = $scope.ApplicationStatusList[j].Name;
                            break;
                        }
                    }
                    break;
                }
            }
        };

        $scope.$on('applicationStatusChanged', function (scope, appInfo) {
            var app = $scope.CertificateAppList.whereEquals(appInfo.ApplicationId, 'ApplicationID').firstOrDefault();

            if (!!app) {
                app.ApplicationStatusId = appInfo.ApplicationStatusId;

                var appStatus = $scope.ApplicationStatusList.whereEquals(appInfo.ApplicationStatusId, 'ApplicationStatusId').firstOrDefault();

                if (!!appStatus) {
                    app.AppStatus = appStatus.Name;
                }
            }
        });

        $scope.$on('applicationComplete', function () {
            $scope.closeApplication();
            $scope.CertificateAppList.forEach(function (item) {
                $scope.closeEditApplication(item);
            });
        });

        $scope.$on('updatePaymentAndStatus', function (event, data) {
            $scope.updatePaymentAndStatus(data);
        });

        var saveApplicationStatus = function (applicationId, applicationStatus, queueApprovalData) {
            return initialApplicationstatusService.saveApplicationStatus(applicationId, sessionStorage.Key, applicationStatus, queueApprovalData);
        };

        var saveApplicationStatusLk = function (applicationId, applicationStatus) {
            var reqData = {
                ApplicationId: applicationId,
                ApplicationTypeId: 2,
                ApplicationStatusId: applicationStatus,
                ApplicationStatusReasonId: 1,
                IsActive: true,
                IsDeleted: false,
                IsArchive: false,
                CreatedBy: $scope.UserId,
                ApplicationInternalStatusReasonText: '',
                ApplicationInternalStatusId: 1,
                HighPriority: false
            };
            return initialApplicationstatusService.saveApplicationStatusLk(sessionStorage.Key, $scope.UserId, reqData);
        };

        var saveApplicationStatusChangeLog = function (applicationId, applicationStatus) {
            var reqData = {
                ApplicationId: applicationId,
                ApplicationStatusReasonId: 1,
                ApplicationNumber: '',
                ApplicationTypeId: 2,
                ApplicationStatusId: applicationStatus,
                ApplicationSubmitMode: 'O',
                ApplicationStatusChangedBy: $scope.UserId,
                ReferenceNumber: '',
                LicenseTypeId: 0,
                HighPriority: false,
                ApplicationInternalStatusId: 1,
                ApplicationInternalStatusReasonText: ''
            };
            return initialApplicationstatusService.saveApplicationStatusChangeLog(sessionStorage.Key, $scope.UserId, reqData)
        };
        // END APPLICATION

        // BEGIN APPLICATION LISTING
        $scope.isSearch = true;
        $scope.isTableDisplay = true;
        $scope.userId = sessionStorage.UserID;
        $scope.isAllCertification = true;
        $scope.isBackoffice = false;
        $rootScope.routeData = $state.current.routeData;

        $scope.pager = {
            totalItems: 0,
            itemsPerPage: 10,
            fromRow: 0,
            currentPage: 1,
            maxSize: 6
        };

        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                ngModelOptions: {
                    allowInvalid: true
                }
            }
        };

        var resetList = function (list) {
            if (list) {
                list.map(function (i) {
                    i.Display = true;
                    i.DisplayComplaint = false;
                    i.DisplaySendMail = false;
                    i.DisplayRcertification = false;
                    i.DisplayBC = false;
                    return i;
                })
            }
        };

        $scope.showSearch = function () {
            $scope.isSearch = true;
        };

        $scope.hideSearch = function () {
            $scope.isSearch = false;
        };

        $scope.applicationItemClick = function (certificateItem, openPaymentTab) {
            $scope.openPaymentTab = !!openPaymentTab;
            $scope.displayAddApplication = false;
            $scope.closeApplication();
            $scope.certificateItem = certificateItem;
            resetList($scope.CertificateAppList);
            certificateItem.Display = false;
            $scope.IndividualId = certificateItem.IndividualID;
            $scope.ApplicationId = certificateItem.ApplicationID;
            $scope.ApplicationTypeId = certificateItem.ApplicationTypeId;
            $scope.ApplicationStatusId = certificateItem.ApplicationStatusId;

            certificateItem.AssignToUserId = certificateItem.AssignToUserId == null ? '' : certificateItem.AssignToUserId.toString();

            var effectiveDate = new Date();
            var expirationDate = new Date();
            certificateItem.effectiveDate = effectiveDate;
            expirationDate.setFullYear(expirationDate.getFullYear() + 2);
            certificateItem.expirationDate = expirationDate;
        };

        $scope.CurrencySignClick = function (certificateItem) {
            $scope.applicationItemClick(certificateItem, true);
        };

        $scope.applicationSearch = function () {
            ShowLoader("Please Wait...");
            $scope.pager.fromRow = 0;
            $scope.pager.currentPage = 1;
            if ($scope.displayApplicationTypeSelection) {
                $scope.getIndividualSearchWithPage();
            } else {
                $scope.getApplicationList();
            }
        };

        $scope.cancelApplicationSearch = function () {
            ShowLoader("Please Wait...");

            $scope.search = getNewSearch();

            if ($scope.displayApplicationTypeSelection) {
                $scope.getIndividualSearchWithPage();
            } else {
                $scope.getApplicationList();
            }
        };

        $scope.getApplicationList = function () {
            var deferred = $q.defer();
            var reqdata = $scope.search;
            reqdata.FromRow = $scope.pager.fromRow;
            reqdata.ItemsPerPage = $scope.pager.itemsPerPage;
            reqdata.IsAllCertification = $scope.isAllCertification;
            reqdata.UserId = $scope.userId;

            licenseApplicationListingService.getApplicationBYIndividualId(sessionStorage.Key, $scope.IndividualId)
                .then(function (applications) {
                    if (applications.Status) {
                        var currentApplicationId = sessionStorage.currentApplicationId;
                        if (!!currentApplicationId) {
                            $scope.currentApplication = applications.ApplicationResponseList
                                .whereIncludes(ApplicationSettings.ActiveStatuses, 'ApplicationStatusId')
                                .whereIncludes(ApplicationSettings.AvailableTypes, 'ApplicationTypeId')
                                .whereEquals(currentApplicationId, 'ApplicationId')
                                .orderByDescending('StartedDate')
                                .firstOrDefault();
                        } else {
                            $scope.currentApplication = null;
                        }
                        deferred.resolve(applications);
                    } else {
                        deferred.reject(applications);
                    }
                    HideLoader();
                }, function (err) {
                    deferred.reject(err);
                });

            return deferred.promise;

            // licenseApplicationListingService.getCertificationApplicationList(sessionStorage.Key, $scope.userId, reqdata)
            //     .then(function (response) {
            //         if (response.Status) {
            //             $scope.CertificateAppList = response.CertificationAppList;
            //             $scope.pager.totalItems = response.TotalRowCount;
            //         }
            //         HideLoader();
            //     }, function (error) {
            //         HideLoader();
            //     });
        };

        $scope.getApplicationStatusList = function () {
            typeValuesService.getTypeValuesByTypeName('ApplicationStatusGetAll', sessionStorage.Key, false, true)
                .then(function (response) {
                    $scope.ApplicationStatusList = response.ApplicationStatusList;
                }, function (error) {

                });
        };

        $scope.getApplicationTypeName = function (type) {
            var applicationType = _.find($scope.ApplicationTypeList, function (item) {
                if (item.ApplicationTypeId == type) {
                    return true;
                }
            })
            if (applicationType)
                return applicationType.Name;
            else
                return '';
        };
        // END APPLICATION LISTING

        // BEGIN DEFICIENCY
        $scope.openEmailDeficiency = function (renewalItem, isDenyCall, isAssignTo, isDeficiencyCall) {
            console.log(renewalItem);
            ShowLoader();
            hideStatusMessage();
            $scope.isDenyCall = !!isDenyCall;
            $scope.isAssignTo = !!isAssignTo;
            $scope.isDeficiencyCall = !!isDeficiencyCall;
            if (!!$scope.isAssignTo && !renewalItem.moveToQueue && !renewalItem.AssignToUserId) {
                showStatusMessage(Messages.fcfdcaa, "error");
                HideLoader();
                return;
            }
            if (!!$scope.isAssignTo && !!renewalItem.moveToQueue) {
                var applicationStatusId = getAssignToApplicationStatus(renewalItem.ApplicationTypeId, renewalItem.ApplicationStatusId, renewalItem.moveToQueue);
                if (!applicationStatusId) {
                    showStatusMessage(Messages.dcdddfa, "error");
                    HideLoader();
                    return;
                }
            }
            if (!!$scope.isDeficiencyCall) {
                $scope.$broadcast("SaveDeficiency", {});
                if (!$scope.listConfig.individualViewConfig.isAlltabsSavedExceptPayment && (renewalItem.ApplicationTypeId != 10) && (renewalItem.ApplicationTypeId != 11) && (renewalItem.ApplicationTypeId != 12)) {
                    showStatusMessage(Messages.General.Errors.ReviewSaveDef, "error");
                    HideLoader();
                    return;
                }
            }
            hideStatusMessage();
            renewalItem.ShowInternalForm = true;
            renewalItem.ShowEmailDeficienyForm = true;
            HideLoader();
        };

        $scope.closeDeficiencyEmailForm = function () {
            if ($scope.certificateItem) {
                $scope.certificateItem.ShowEmailDeficienyForm = false;
            }
        };

        $scope.updateApplicationDefeciency = function (applicationId, applicationStatusId, renewalItem, validateAndResolveDeficiency) {
            var queueApprovalData = {
                ProcessDeficiency: true,
                DeficiencyStatusId: 1
            };

            if (!!validateAndResolveDeficiency) {
                queueApprovalData.DeficiencyStatusId = 3;
                if ($scope.listConfig.individualViewConfig.IsDefecient &&
                    !$scope.listConfig.individualViewConfig.isDeficiencyResolved) {
                    showStatusMessage(Messages.General.Errors.ResolveDef, "error");
                    return;
                } else {
                    hideStatusMessage();
                    $scope.$broadcast("SaveDeficiency", {});
                    $scope.verifyLicense(renewalItem, false, false);
                    return;
                }
            }
            var applicationStatusChangeLogId = applicationStatusId;
            if (applicationStatusId == 16) {
                applicationStatusChangeLogId = 31;
            } else if (applicationStatusId == 25) {
                applicationStatusChangeLogId = 32;
            } else if (applicationStatusId == 26) {
                applicationStatusChangeLogId = 33;
            } else if (applicationStatusId == 27) {
                applicationStatusChangeLogId = 34;
            }
            var statusUpdatePromises = [
                saveApplicationStatus(applicationId, applicationStatusId, queueApprovalData),
                saveApplicationStatusLk(applicationId, applicationStatusChangeLogId, $scope.ApplicationTypeId),
                saveApplicationStatusChangeLog(applicationId, applicationStatusChangeLogId, $scope.ApplicationTypeId)
            ];
            $q.all(statusUpdatePromises)
                .then(function (responses) {
                    var savedSuccessFully = true;
                    angular.forEach(responses, function (response) {
                        if (response.Status == false) {
                            showStatusMessage(response.Message, "error");
                            HideLoader();
                            savedSuccessFully = false;
                        }
                    })
                    if (!savedSuccessFully)
                        return;
                    HideLoader();
                    //$scope.getLicenseeRenewalList($scope.currentPage);
                }, function (data) {
                    HideLoader();
                    showStatusMessage(data.message, "error");
                });
        };
        // END DEFICIENCY
    }

})();