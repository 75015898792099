(function() {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationProviderFeeDetails', initialApplicationProviderFeeDetailsDirective);

    initialApplicationProviderFeeDetailsDirective.$inject = [];

    function initialApplicationProviderFeeDetailsDirective() {
        return {
            restrict: 'E',
            scope: {
                licenseDescription: "=licenseDescription",
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                feeDetails: "=feeDetails",
                isBackoffice: "=isBackoffice",
                isPendingApplication: "=isPendingApplication",
                natValidation: "=natValidation",
                totalCourses: "=",
                ceCourseList:"=?ceCourseList"
                // administratorPermits: "=administratorPermits",
                // sitePermits: "=sitePermits",
                // individualLicense: "=individualLicense"
            },
            templateUrl: "app/components/provider/directive/provider-fee-details/fee-details.html",
            controller: "InitialApplicationFeeDetailsController"
        }
    }
})();
