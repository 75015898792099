(function () {
    'use strict'

    angular.module('app.core')
        .factory('InlumonContentService', ['$http', '$q', 'WebApiUrl', InlumonContentService]);

    function InlumonContentService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
                .then(function (res) {
                    if (success) {
                        success(res);
                    }
                    deferred.resolve(res.data);
                }, function (error) {
                    if (fail) {
                        fail(error);
                    }
                    console.log("Request failed with status: " + error.status);
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getContentByCode = function (key, contentCode) {
            var urlParams = {
                Key: '',
                ContentItemLkCode: contentCode
            };
            return responseHandler($http.get(WebApiUrl + 'Content/ContentGetBYContentItemLkCode/', { params: urlParams }));
        };

        var _getContentById = function (key, contentId) {
            var urlParams = {
                Key: key ? key : '',
                ID: contentId
            };
            return responseHandler($http.get(WebApiUrl + 'Content/LookupContentGetBYContentItemLkID/', { params: urlParams }));
        };

        var _getContentByPageTabSectionId = function (key, pageTabSectionId) {
            var urlParams = {
                Key: '',
                ContentLkToPageTabSectionId: pageTabSectionId
            };
            return responseHandler($http.get(WebApiUrl + 'Content/ContentGetBYContentLkToPageTabSectionId/', { params: urlParams }));
        };

        return {
            getContentByCode: _getContentByCode,
            getContentById: _getContentById,
            getContentByPageTabSectionId: _getContentByPageTabSectionId
        }
    }
})();