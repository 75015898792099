(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeIndividualExamService', backofficeIndividualExamService)

    backofficeIndividualExamService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeIndividualExamService($http, $q, WebApiUrl, AppConfig) {

        var _individualExam_IndividualId = function (Key, IndividualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualExamBYIndividualId/' + Key + '?IndividualId=' + IndividualId + '&ApplicationId=0'));
        };


        var _saveExamVerification = function (experience, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualExamSave/' + key + '?Source=' + AppConfig.Source, experience)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var _getExamVerification = function (individualId, applicationId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualExamBYIndividualId/' + key + '?IndividualId=' + individualId + '&ApplicationId=' + applicationId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _refExamNameGetAll = function (key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'TypeValues/RefExamNameGetAll/' + key)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        return {
            individualExam_IndividualId: _individualExam_IndividualId,
            saveExamVerification: _saveExamVerification,
            getExamVerification: _getExamVerification,
            refExamNameGetAll: _refExamNameGetAll
        }
    }
})();