//Code Generated by Inlumon Code generation tool
(function () {
    'use strict'

    angular.module('app.backofficeAdministor')
        .directive('revenueDepositLogic', [RevenueDepositLogic]);

    function RevenueDepositLogic() {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                $scope.processRefEntityData = function (entityRefData) {
                    var filteredEntityRefData = [];
                    angular.forEach(entityRefData, function (entityRefDataItem) {
                        var isFiltered = true;
                        angular.forEach($scope.uiConfig.Filter, function (filterItem) {
                            if (!!entityRefDataItem.hasOwnProperty(filterItem.field) &&
                                !!filterItem.VisibleInForm && filterItem.VisibleInForm.length > 0 &&
                                (filterItem.VisibleInForm.indexOf(entityRefDataItem[filterItem.field]) == -1)
                            ) {
                                isFiltered = false;
                            }
                        });
                        if (!!isFiltered) {
                            filteredEntityRefData.push(entityRefDataItem);
                        }
                    })
                    return filteredEntityRefData;
                };
                $scope.filterRevenueDepositDataByConfig = function (entityData) {
                    var filteredEntityData = [];

                    angular.forEach(entityData, function (entityDataItem) {
                        var isFiltered = true;
                        angular.forEach($scope.uiConfig.Filter.RevenueDeposit, function (filterItem) {
                            if (entityDataItem[filterItem.field] != undefined &&
                                !!filterItem.VisibleInTable && filterItem.VisibleInTable.length > 0 &&
                                (filterItem.VisibleInTable.indexOf(entityDataItem[filterItem.field]) == -1)
                            ) {
                                isFiltered = false;
                            }
                        });
                        if (!!isFiltered) {
                            filteredEntityData.push(entityDataItem);
                        }
                    });
                    return filteredEntityData;
                };
            }
        };
    }
})();