﻿(function () {
    //'use strict';

    angular
        .module('app.backofficeProvider')
        .controller("BackofficeFirmsAddController", BackofficeFirmsAddController);

    BackofficeFirmsAddController.$inject = ["$scope", "BackofficeFirmRenewalListService", "$controller", "$state", "$rootScope"];

    function BackofficeFirmsAddController($scope, BackofficeFirmRenewalListService, $controller, $state, $rootScope) {
        $controller('GlobalController', { $scope: $scope });
        if (!$scope.isSessionActive()) {
            $state.go('app.BackofficeLoginstaff');
        }
        $scope.viewConfig = {};
        //$scope.IsBackoffice = true;
        $scope.currentRenewalItem = {};
        $scope.sortColumn = "ApprovedDate";
        $scope.sortOrder = "desc";

        $scope.SearchProvider = function () {
            $scope.closeApplication();
            if ($scope.viewConfig.SearchLicenseNumber) {
                ShowLoader("Please Wait...");
                BackofficeFirmRenewalListService.GetProviderLicenseByCertificateNumber(sessionStorage.Key, $scope.viewConfig.SearchLicenseNumber)
                    .then(function (response) {
                        if (response.data.Status) {
                            $scope.currentRenewalItem = response.data;
                            $scope.currentRenewalItem.LicenseRenewal = true;
                            $scope.ProviderID = response.data.ProviderId;
                            $scope.EIN = response.data.EIN;
                            $scope.providerName = response.data.ProviderName;

                            $scope.licenseNumber = angular.copy($scope.viewConfig.SearchLicenseNumber);
                            $scope.EnableCreateFirmDirective = true;
                            $scope.viewConfig.ShowLicenseAdd =  true;
                            // HideLoader();
                        } else {
                            HideLoader();
                        }
                    }, function (data) {
                        HideLoader();
                        showStatusMessage(data, "error");
                    });
            }
        };

        $scope.closeApplication = function () {
            $scope.EnableCreateFirmDirective = false;
            $scope.ProviderID = undefined;
            $scope.EIN = undefined;
            $scope.providerName = undefined;
            $scope.licenseNumber = undefined;
        };

        $scope.init = function () {
            if (!$scope.IsBackoffice) {
                $scope.viewConfig.SearchLicenseNumber = $rootScope.ProviderRenewalLicNumber;
                $scope.SearchProvider();
            }
        };

        $scope.init();
    }
})();
