﻿(function () {
    'use strict';
    angular.module('app.backofficeAdministor')
        .controller("PasswordManagementController", PasswordManagementController);
    PasswordManagementController.$inject = ['$scope', '$controller', '$timeout', 'ResetPasswordService', 'utilityService'];
    function PasswordManagementController($scope, $controller, $timeout, ResetPasswordService, utilityService) {
        $controller('MainController', { $scope: $scope });
        $scope.data = {
            Type: '',
            Role: '',
            Status: '',
            LastName: '',
            UserName: '',
            Email: ''
        };
        $scope.userPerPages = 10;
        var key = sessionStorage.Key;
        $scope.init = function () {
            //$scope.getUserList();
            $scope.getAllUserTypeName();
            $scope.getAllUserStatus();
        };
        $scope.orderData = function (param) {
            $scope.orderflag = !$scope.orderflag;
            if (!$scope.orderflag) {
                $scope.order = '-' + param;
            }
            if ($scope.orderflag) {
                $scope.order = param.split('-')[0];
            }
        };
        $scope.getAllUserTypeName = function () {
            ResetPasswordService.getAllUserTypeName(key)
                .then(function (response) {
                    AuditVisitInfoSave();
                    $scope.data.usertypename = response.UserType;
                })
                .catch(function () {

                });
        };
        $scope.getAllRoleList = function () {
            var UserTypeID = $scope.data.Type;
            ResetPasswordService.getAllRoleList(key, UserTypeID)
                .then(function (response) {
                    AuditVisitInfoSave();
                    $scope.data.rolelist = response.Role;
                })
                .catch(function () {

                });
        };
        $scope.getAllUserStatus = function () {
            ResetPasswordService.getAllUserStatus(key)
                .then(function (response) {
                    AuditVisitInfoSave();
                    $scope.data.userstatus = response.UserStatus;
                })
                .catch(function () {

                });
        };
        $scope.searchUser = function () {
            try {
                var data = {
                    "UserTypeId": $scope.data.Type,
                    "RoleId": $scope.data.Role,
                    "UserStatusId": $scope.data.Status,
                    "LastName": $scope.data.LastName,
                    "UserName": $scope.data.UserName,
                    "Email": $scope.data.Email,
                    "IsPending": $scope.data.pending
                };
                ShowLoader();
                ResetPasswordService.searchUser(key, data)
                    .then(function (result) {
                        AuditVisitInfoSave();
                        $timeout(function () {
                            $scope.$apply(function () {
                                $scope.users = result.Users;
                                angular.element(document.querySelector(".dataTables_paginate ")).hide();
                                angular.element(document.querySelector(".dataTables_length")).hide();
                            });
                        });

                        HideLoader();
                    })
                    .catch(function (response) {

                    });
            }
            catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };
        $scope.getUserList = function () {
            ShowLoader("Please wait...");
            try {
                ResetPasswordService.UsersGetAll(key)
                    .then(function (response) {
                        AuditVisitInfoSave();
                        $scope.users = response.Users;
                        HideLoader();
                        angular.element(".selectAll").click(function () {
                            angular.element("td input[type=checkbox]").prop('checked', $(this).prop('checked'));
                        });
                    })
                    .catch(function (response) {

                    });
            }
            catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };
        $scope.resetPassword = function () {
            ShowLoader();
            var ids = [];
            angular.element('td input[type=checkbox]:checked').each(function () {
                ids.push(this.value);
            });
            ResetPasswordService.ResetPassword(ids)
                .then(function (response) {
                    HideLoader();
                    AuditVisitInfoSave();
                    if (response.Status) {
                        showStatusMessage(response.Message, "success");
                    } else {
                        showStatusMessage(response.Message, "error");
                    }
                })
                .catch(function (response) {

                });
        };
        function AuditVisitInfoSave() {
            var data = {
                "PageName": "PasswordManagement",
                "SessionId": sessionStorage.Key,
                "IndividualId": sessionStorage.IndividualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        }
    }
})();