(function () {
  'use strict'
  angular.module('app.core')
    .directive('natUrl', natUrlDirective);

  natUrlDirective.$inject = [];

  function natUrlDirective() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ctrl) {
        element.on('blur', function (event) {
          var RegPattern = new RegExp(/((ftp|http|https):\/\/)?[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/);

          if (!ctrl.$$parentForm.errorMessages) {
            ctrl.$$parentForm.errorMessages = [];
          }

          var errorObj = {
            propName: (!!attrs.validationName ? attrs.validationName : ("naturl-" + ctrl.$name)),
            errorMessage: attrs.natUrl
          }

          angular.forEach(ctrl.$$parentForm.errorMessages, function (error, indexOfMsg) {
            if (errorObj.propName == error.propName) {
              ctrl.$$parentForm.errorMessages.splice(indexOfMsg, 1);
            }
          });

          if (!RegPattern.test(ctrl.$viewValue.trim())) {
            ctrl.$$parentForm.errorMessages.push(errorObj);
            ctrl.$setValidity("naturl", false);
          }
          else {
            ctrl.$setValidity('naturl', true);
          }
        })
      }
    }
  }
})();
