﻿(function() {
    'use strict'
    angular.module('app.core').directive('providerContactDirective', function() {
        return {
            restrict: 'EA',
            scope: {
                providerId: '=?providerId',
                iseditable: '=iseditable'
            },
            controller: "ProviderContactController",
            templateUrl: "app/core/directive/ProviderContactDirective/provider-contact-template.html",
        };
    });
})();