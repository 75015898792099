(function () {
  'use strict'

  angular.module('app.backofficeIndividual')
    .factory('backofficeProviderNameService', backofficeProviderNameService)

  backofficeProviderNameService.$inject = ['$http', '$q', 'WebApiUrl']

  function backofficeProviderNameService($http, $q, WebApiUrl) {


    var _nameByProviderId = function (Key, ProviderId, ApplicationId) {
      var deferred = $q.defer();
      $http.get(WebApiUrl + 'Provider/GetProviderName/' + Key + '?ProviderId=' + ProviderId + '&ApplicationId=' + ApplicationId)
        .then(function(data){ data = data.data;
          deferred.resolve(data);
        }, function(error) { error = error.data;
          deferred.reject(error);
        });
      return deferred.promise;
    };
    var _nameProviderSave = function (providerName, key) {
      var deferred = $q.defer();
      $http.post(WebApiUrl + 'Provider/SaveProviderName/' + key + '?Source=WYRealEstate', providerName)
        .then(function(data){ data = data.data;
          deferred.resolve(data);
        }, function(error) { error = error.data;
          deferred.reject(error);
        });
      return deferred.promise;
    };

    return {
      nameProviderSave: _nameProviderSave,
      nameByProviderId: _nameByProviderId
    }
  }
})();
