(function () {
    'use strict'
    angular.module('app.core')
        .directive('individualinfoContinuingEducationDirective', individualinfoContinuingEducationDirective);
    individualinfoContinuingEducationDirective.$inject = [];

    function individualinfoContinuingEducationDirective() {
        return {
            restrict: 'E',
            scope: {
                license: '=license'
            },
            templateUrl: "app/core/directive/continuing-education/continuing-education.directive.html",
            controller: "IndividualinfoContinuingEducationController"
        }
    }
})();
