(function () {
  'use strict'
  angular.module('app.report')
    .controller('PendingApprovalReportController', PendingApprovalReportController);

  PendingApprovalReportController.$inject = ['$scope', '$q', '$controller', '$filter',
    'reportService', '$state', 'utilityService', 'ApiUrlsForReports', 'ApiUrlsForReportsXls'];

  function PendingApprovalReportController($scope, $q, $controller, $filter, reportService, $state, utilityService, ApiUrlsForReports, ApiUrlsForReportsXls) {

    // Pagination
    $scope.Pager = {
      sortType: 'LicenseNumber',
      sortOrder: 'asc',
      currentPage: 1,
      totalRecords: 10,
      pageSize: 20,
      maxSize: 5
    }

    $scope.Headers = [
      { name: 'First Name', sortType: 'FirstName', sortOrder: 'asc', class: 'w90' },
      { name: 'Last Name', sortType: 'LastName', sortOrder: 'asc', class: 'w90' },
      { name: 'License#', sortType: 'LicenseNumber', sortOrder: 'desc', class: 'w90' },
      { name: 'Submitted On', sortType: 'SubmittedOn', sortOrder: 'asc', class: 'w90' }
    ];

    $scope.getPendingApprovalReportList = function () {
      try {
        if ($scope.isUserSessionActive()) {
          ShowLoader('Please Wait...')
          reportService.pendingApprovalReportWithPager(sessionStorage.Key, $scope.Pager, ApiUrlsForReports.pendingApproval)
            .then(function (response) {
              if ($scope.checkServerResponse(response)) {
                $scope.Pager.totalRecords = response.PagerVM.TotalRecords;
                $scope.PendingApprovalData = response.PagerVM.Records;
              }
              HideLoader();
              utilityService.scrollTop();
            }, function (data) {
              HideLoader()
              showStatusMessage(data, 'error')
            })
        } else {
          $state.go('app.Loginwoemail')
        }
      } catch (ex) {
        HideLoader()
        if (ex != null) { showStatusMessage(ex.message, 'error'); }
      }
    }

    $scope.getPendingApprovalReportList();

    $scope.SortRecords = function (header) {
      $scope.Pager.currentPage = 1;
      $scope.Pager.sortType = header.sortType;
      $scope.Pager.sortOrder = header.sortOrder;
      header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
      $scope.getPendingApprovalReportList();
    }
    $scope.getExcelForPendingApprovalReport = function () {
      try {
        ShowLoader('Please Wait...')
        reportService.getXLSForPendingApprovalReport(sessionStorage.Key, $scope.Pager, ApiUrlsForReportsXls.pendingApproval)
          .then(function (response) {
            HideLoader()
          }, function (data) {
            HideLoader()
            showStatusMessage(data, 'error')
          })
      } catch (ex) {
        HideLoader()
        if (ex != null) { showStatusMessage(ex.message, 'error'); }
      }
    }
  };
})();

