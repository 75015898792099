(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationVeteranInfo', initialApplicationVeteranInfoDirective);

    initialApplicationVeteranInfoDirective.$inject = [];

    function initialApplicationVeteranInfoDirective() {
        return {
            restrict: 'E',
            scope: {
                veteranInfo: "=veteranInfo",
                veteranInfoConfirmation: "=veteranInfoConfirmation",
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                individualId: "=individualId",
                applicationId: "=applicationId",
                natValidation: "=natValidation",
                individualLicense: "=individualLicense",
                tab: "=tab",
                openNextTab: "&",
                applicationInfo: "=?applicationInfo",
                isBackoffice: "=isBackoffice",
                isRenewal: '=?isRenewal',
            },
            templateUrl: "app/components/individual/application/directives/veteran-info/veteran-info.html",
            controller: "InitialApplicationVeteranInfoController"
        }
    }
})();