(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationEducation', initialApplicationEducationDirective);

    initialApplicationEducationDirective.$inject = [];

    function initialApplicationEducationDirective() {
        return {
            restrict: 'E',
            scope: {
                educationInfo: "=educationInfo",
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                applicationId: "=applicationId",
                individualId: "=individualId",
                natValidation: "=natValidation",
                applicationInfo: "=?applicationInfo",
                tab: "=tab",
                openNextTab: "&",
                isRenewal: "=?isRenewal",
                isBackoffice: "=isBackoffice",
            },
            templateUrl: "app/components/individual/application/directives/education/education.html",
            controller: "InitialApplicationEducationController"
        }
    }
})();
