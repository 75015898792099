(function () {
  'use strict'
  angular.module('app.core')
    .directive('natMinLength', natMinLengthDirective);

  natMinLengthDirective.$inject = [];

  function natMinLengthDirective() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ctrl) {
        element.on('blur', function (event) {
          if (!ctrl.$$parentForm.errorMessages) {
            ctrl.$$parentForm.errorMessages = [];
          }

          var errorObj = {
            propName: (!!attrs.validationName ? attrs.validationName : ("natminlength-" + ctrl.$name)),
            errorMessage: attrs.natMinlengthErrormessage
          }

          angular.forEach(ctrl.$$parentForm.errorMessages, function (error, indexOfMsg) {
            if (errorObj.propName == error.propName) {
              ctrl.$$parentForm.errorMessages.splice(indexOfMsg, 1);
            }
          })

          if (ctrl.$viewValue.length < attrs.natMinLength) {
            ctrl.$$parentForm.errorMessages.push(errorObj);
            ctrl.$setValidity("natminlength", false);
          }
          else {
            ctrl.$setValidity('natminlength', true);
          }
        })
      }
    }
  }
})();
