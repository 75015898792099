(function () {
    'use strict'
    angular.module('app.core')
        .directive('inlRoleUpdate', inlRoleUpdate);
    inlRoleUpdate.$inject = [];

    function inlRoleUpdate() {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attrs) {
                processPermissionforElement('Update', $element, $attrs.inlRoleUpdate);
            }
        }
    };


    angular.module('app.core')
        .directive('inlRoleCreate', inlRoleCreate);
    inlRoleCreate.$inject = [];

    function inlRoleCreate() {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attrs) {
                processPermissionforElement('Create', $element, $attrs.inlRoleCreate);
            }
        }
    };

    angular.module('app.core')
        .directive('inlRoleDelete', inlRoleDelete);
    inlRoleDelete.$inject = [];

    function inlRoleDelete() {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attrs) {
                processPermissionforElement('Delete', $element, $attrs.inlRoleDelete);
            }
        }
    };

    var processPermissionforElement = function (permissionToCheck, $element, inlAttributeValue) {
        var rolePermission = null;
        if (!!sessionStorage.routePermission) {
            rolePermission = JSON.parse(sessionStorage.routePermission);
        }
        if (!rolePermission || !rolePermission[permissionToCheck]) {
            if (!inlAttributeValue || inlAttributeValue.toLowerCase() == 'r' || inlAttributeValue.toLowerCase() == 'remove') { // Remove condition if nothing is passed or r is passed
                $element.remove();
            } else if (!!inlAttributeValue && (inlAttributeValue.toLowerCase() == 'd' || inlAttributeValue.toLowerCase() == 'disable')) { // Disable condition if d is passed
                $element.attr('disabled', 'disabled');
                $element.css({
                    pointerEvents: 'none'
                });
            }
        }
    };

})();