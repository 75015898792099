(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('providerApplicationAcknowledgementService', providerApplicationAcknowledgementService)

    providerApplicationAcknowledgementService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function providerApplicationAcknowledgementService($http, $q, WebApiUrl, AppConfig) {
        var _acknowledgementSave = function (acknowledgementData, userId, key, isrenewal) {
            var acknowledgementObj = isrenewal ? acknowledgementData : (Array.isArray(acknowledgementData) ? acknowledgementData[0] : acknowledgementData);
            var acknowledgementList = (Array.isArray(acknowledgementData) ? acknowledgementData : [acknowledgementData]);
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/SaveProviderAffidavit/'+key+'?ProviderId=' + acknowledgementObj.ProviderId + "&applicationId=" + acknowledgementObj.ApplicationId + "&userId=" + userId + "&Source=" + AppConfig.Source, [{
                ContentItems: acknowledgementList,
                Individualaffidavitsignature: {
                    SignatureName: acknowledgementObj.SignatureName,
                    Date: acknowledgementObj.Date,
                    Name: acknowledgementObj.Name
                }
            }])
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _acknowledgementGet = function (key, providerId, applicationId, contentLkToPageTabSectionIds, individualLicenseId) {
            // var urlParams = {
            //     ProviderId: providerId,
            //     ApplicationId: applicationId
            // };
            // if (!!contentLkToPageTabSectionIds) {
            //     urlParams.ContentLkToPageTabSectionIds = contentLkToPageTabSectionIds;
            // }
            // if (!!individualLicenseId) {
            //     urlParams.IndividualLicenseId = individualLicenseId;
            // }
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Provider/GetProviderAffidavit/' + key +'?ProviderId=' + providerId + "&applicationId=" + applicationId)  
                .then(function (res) {
                    deferred.resolve(res.data);
                },function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        };

        return {
            acknowledgementSave: _acknowledgementSave,
            acknowledgementGet: _acknowledgementGet
        };
    }
})();