(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("RenewalTypeController", RenewalTypeController);

    RenewalTypeController.$inject = ["$scope", "$q", "$uibModal", "renewalTypeService", "initialApplicationstatusService", "licenseRenewalService", "utilityService", "expirationService", "typeValuesService", "TypeValue"];

    function RenewalTypeController($scope, $q, $uibModal, renewalTypeService, initialApplicationstatusService, licenseRenewalService, utilityService, expirationService, typeValuesService, TypeValue) {

        $scope.init = function () {
            $scope.getRenewalTypeConfig();
            initialProcessing();
        };

        var getUIConfig = function () {
            return $scope.config.UIConfigs[$scope.configName];
        };


        function initialProcessing() {
            angular.forEach($scope.renewalCheckData.IndividualLicenseList, function (licenseType) {
                if (licenseType.LicenseStatusTypeId == 1 || licenseType.LicenseStatusTypeId == 7 || licenseType.LicenseStatusTypeId == 8) {
                    licenseType.applicationType = 'active';
                    licenseType.currentApplicationType = 'active';
                }
            });
            // processApplicationFeeAndRenewalData();
        };

        var mapRefLicenseTypeAndFeeDetails = function () {
            $scope.renewalCheckData.IndividualLicenseList.map(function (individualLicenseType) {
                if (!!$scope.refLicenseTypes) {
                    individualLicenseType.refLicenseType = $scope.refLicenseTypes.whereEquals(individualLicenseType.LicenseTypeId, 'LicenseTypeId').firstOrDefault();
                }
                var feeDetailRowsByLicenseType = getFeeDetailsByAppType(individualLicenseType);
                individualLicenseType.applicationFee = feeDetailRowsByLicenseType.whereEquals('RENEWAL', 'FeeTypeCode').sum('FeeAmount');
                individualLicenseType.lateFee = feeDetailRowsByLicenseType.whereEquals('LATE', 'FeeTypeCode').sum('FeeAmount');
            });
        };

        var getFeeDetailsByAppType = function (licenseType) {
            var feesForLicenseType = [];
            if (!!$scope.feeDetailRows) {
                feesForLicenseType = $scope.feeDetailRows
                    .whereEquals($scope.applicationTypeId, 'ApplicationTypeId')
                    .where(function (feeDetailItem) {
                        return (feeDetailItem.LicenseTypeIds.split(',').indexOf(licenseType.LicenseTypeId.toString()) != -1);
                    })
                    //.whereContains(licenseType.PreviousLicenseStatusTypeId.toString(), 'LicenseStatusTypeIds')
                    .whereEquals('RENEWAL', 'FeeTypeCode');

                if (licenseType.ApplyLateFee) {
                    var lateFeesForLicenseType = $scope.feeDetailRows
                        .whereEquals($scope.applicationTypeId, 'ApplicationTypeId')
                        .where(function (feeDetailItem) {
                            return (feeDetailItem.LicenseTypeIds.split(',').indexOf(licenseType.LicenseTypeId.toString()) != -1);
                        })
                        //.whereContains(licenseType.PreviousLicenseStatusTypeId.toString(), 'LicenseStatusTypeIds')
                        .whereEquals('LATE', 'FeeTypeCode');
                    feesForLicenseType = feesForLicenseType.concat(lateFeesForLicenseType);
                }
            }

            feesForLicenseType = feesForLicenseType.map(function (i) {
                i.LicenseNumber = licenseType.LicenseNumber;
                return i;
            });

            if (!!feesForLicenseType && feesForLicenseType.length > 0) {
                feesForLicenseType = feesForLicenseType.filter(function (feeItemForLicenseType) {
                    return ((!feeItemForLicenseType.EndDate || utilityService.checkIfDateGreaterOrEqual(feeItemForLicenseType.EndDate, licenseType.PreviousExpirationDate))
                        && (!feeItemForLicenseType.EffectiveDate || utilityService.checkIfDateGreaterOrEqual(licenseType.PreviousExpirationDate, feeItemForLicenseType.EffectiveDate)));
                });
            }

            if (!!feesForLicenseType && feesForLicenseType.length > 0) {
                return feesForLicenseType;
            } else {
                $scope.showStatusMsg('-', "No fees associated with this license type: " + licenseType.LicenseTypeName);
                throw ('No fees associated with this license type: ' + licenseType.LicenseTypeName);
            }
        };

        var processApplicationFeeAndRenewalData = function () {
            $scope.feeDetails.applicationFee = 0;
            var renewalGroups = [];
            $scope.renewalCheckData.selectedGroups = {};
            var historyQuestionContentId = '';
            var feeDetailRows = [];
            angular.forEach($scope.renewalCheckData.IndividualLicenseList, function (licenseType) {
                if (licenseType.IsSelectedToRenew) {
                    if (renewalGroups.indexOf(licenseType.RenewalApplicationGrouping) == -1)
                        renewalGroups.push(licenseType.RenewalApplicationGrouping);
                    $scope.renewalCheckData.selectedGroups["group" + licenseType.RenewalApplicationGrouping] = true;
                    if (!!licenseType.RenewalApplicationHistoryQuestionContentId) {
                        if (!historyQuestionContentId)
                            historyQuestionContentId = licenseType.RenewalApplicationHistoryQuestionContentId;
                        else
                            historyQuestionContentId = historyQuestionContentId.concat(',', licenseType.RenewalApplicationHistoryQuestionContentId);
                    }
                    feeDetailRows = feeDetailRows.concat(angular.copy(getFeeDetailsByAppType(licenseType)));
                }
            });
            mapRefLicenseTypeAndFeeDetails();
            $scope.renewalCheckData.RenewalApplicationHistoryQuestionContentId = historyQuestionContentId.split(',').distinct().join(',');
            $scope.renewalCheckData.renewalGroups = renewalGroups;
            $scope.feeDetails.fees = feeDetailRows;
            $scope.feeDetails.applicationFee = $scope.feeDetails.fees.whereEquals('RENEWAL', 'FeeTypeCode').sum('FeeAmount');
            $scope.feeDetails.lateFee = $scope.feeDetails.fees.whereEquals('LATE', 'FeeTypeCode').sum('FeeAmount');
            $scope.feeDetails.totalFee = $scope.feeDetails.applicationFee + $scope.feeDetails.lateFee;
        };

        $scope.getRenewalTypeConfig = function () {
            var relativeFilePath = "/components/individual/application/directives/renewal-type/renewal-type.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function (data) {
                    $scope.config = data;
                    $scope.getInitData();
                }, function (error) {
                    $scope.showStatusMsg('-', "Some error occurred,Unable to load the renewal type config");
                });
        };

        var _processInstructionContent = function (configToUse) {
            var instructionByGroup = [];
            for (var group in configToUse.InstructionContentByGroup) {
                if ($scope.applicableGroups.indexOf(Number(group)) != -1) {
                    angular.forEach(configToUse.InstructionContentByGroup[group], function (instructionContentCode) {
                        angular.forEach($scope.contentItems, function (contentItem) {
                            if (instructionContentCode == contentItem.ContentItemLkCode) {
                                instructionByGroup.push(contentItem);
                            }
                        })
                    })
                }
            }
            return instructionByGroup;
        };

        $scope.getInitData = function () {
            var configToUse = getUIConfig();
            $q.all([renewalTypeService.getContent(sessionStorage.Key, configToUse.ContentCode, true),
            renewalTypeService.individualBYIndividualId(sessionStorage.Key, $scope.individualId),
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=feedetails&pullInActive=true")),
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=licensetype"))
            ]).then(function (response) {
                if (!response[0].Status) {
                    $scope.showStatusMsg('-', 'some error occurred !');
                } else {
                    if (response[0].ContentItems != null) {
                        $scope.contentItems = response[0].ContentItems;
                        $scope.instructionContentData = _processInstructionContent(configToUse);
                    }
                }

                if (!response[1].Status || !response[1].IndividualResponse || response[1].IndividualResponse.length == 0) {
                    $scope.showStatusMsg('-', 'some error occurred !');
                } else {
                    $scope.personalInfo = response[1].IndividualResponse[0];
                }

                if (!response[2].Status) {
                    $scope.showStatusMsg('-', 'some error occurred !');
                } else {
                    $scope.feeDetailRows = response[2].ReferenceEntities;
                }
                if (!response[3].Status) {
                    $scope.showStatusMsg('-', 'some error occurred !');
                } else {
                    $scope.refLicenseTypes = response[3].ReferenceEntities;
                }
                processApplicationFeeAndRenewalData();
                $scope.isConfigLoaded = true;
                $scope.loadingRenewalType(false);
            }, function (err) {
                $scope.loadingRenewalType(false);
                $scope.showStatusMsg('-', 'some error occurred !');
            });
        };

        $scope.changeApplicationType = function (licenseType, licenseStatusTypeId, isWallCertificate) {
            licenseType.LicenseStatusTypeId = licenseStatusTypeId;
            licenseType.IsWallCertificate = isWallCertificate;
            processApplicationFeeAndRenewalData();
        };

        $scope.licenseTypeSelectionChanged = function (licenseType) {
            if (!licenseType.IsSelectedToRenew) {
                licenseType.applicationType = null;
            }
            processApplicationFeeAndRenewalData();
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        $scope.openExpiredRenewalValidationConfirmation = function () {
            $uibModal.open({
                animation: true,
                templateUrl: 'expiredRenewalValidationConfirmation.html',
                size: 'md',
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    $scope.onCancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.onConfirm = function () {
                        $uibModalInstance.close('success');
                    };
                }]
            });
        };

        var validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                if (!!currentForm.errorMessages)
                    $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            }

            if (!$scope.renewalCheckData.IndividualLicenseList.any('item.IsSelectedToRenew')) {
                $scope.natValidation.errorMessages.push({
                    propName: 'isAtleastOneLicSelected',
                    errorMessage: "Please select at least one license type to renew."
                });
            } else {
                var allIndividualLicenseList = $scope.renewalCheckData.IndividualLicenseList.whereEquals(true, 'ExtensionGranted');
                var individualLicenseList = $scope.renewalCheckData.IndividualLicenseList.whereEquals(true, 'IsSelectedToRenew');
                angular.forEach(individualLicenseList, function (individualLicenseItem) {
                    if (!!individualLicenseItem.refLicenseType.DependentsToBeRenewedFirst) {
                        var dependentsToBeRenewedFirst = individualLicenseItem.refLicenseType.DependentsToBeRenewedFirst.split(',');
                        angular.forEach(dependentsToBeRenewedFirst, function (dependentsToBeRenewedFirstItem) {
                            var dependentsToBeRenewedFirstBeingRenewed = individualLicenseList.whereEquals(dependentsToBeRenewedFirstItem, 'LicenseTypeId').firstOrDefault();
                            if (!dependentsToBeRenewedFirstBeingRenewed) {
                                var dependentsToBeRenewedFirstEligibleToRenew = allIndividualLicenseList.whereEquals(dependentsToBeRenewedFirstItem, 'LicenseTypeId').firstOrDefault();
                                if (!!dependentsToBeRenewedFirstEligibleToRenew) {
                                    $scope.natValidation.errorMessages.push({
                                        propName: ('dependentToBeRenewed-' + individualLicenseItem.LicenseTypeId),
                                        errorMessage: ("Please renew " + dependentsToBeRenewedFirstEligibleToRenew.LicenseTypeName + " before renewing " + individualLicenseItem.LicenseTypeName)
                                    });
                                }
                            }
                        });
                    }
                });
                var expiredMoreThanRenewalPeriod = individualLicenseList.filter(function (individualLicenseItem) {
                    var previousExpirationDate = null;
                    if (!!individualLicenseItem.PreviousExpirationDate) {
                        if (!angular.isDate(individualLicenseItem.PreviousExpirationDate)) {
                            previousExpirationDate = new Date(individualLicenseItem.PreviousExpirationDate);
                        } else {
                            previousExpirationDate = individualLicenseItem.PreviousExpirationDate;
                        }
                        previousExpirationDate.setFullYear(previousExpirationDate.getFullYear() + 2);
                        if (utilityService.checkIfDateGreater(new Date(), previousExpirationDate)
                        ) {
                            return true;
                        }
                    }
                });
                if (!!expiredMoreThanRenewalPeriod && expiredMoreThanRenewalPeriod.length > 0) {
                    $scope.natValidation.errorMessages.push({
                        propName: "ExpiredMoreThan2year",
                        errorMessage: ""
                    });
                    $scope.openExpiredRenewalValidationConfirmation();
                }
            }
            return (!$scope.natValidation.errorMessages || $scope.natValidation.errorMessages.length == 0);
        };

        $scope.createPendingRenewal = function (currentForm) {
            $scope.loadingRenewalType(true, 'Saving the renewal selection...');
            $scope.hideStatusMsg();
            if (!validateForm(currentForm)) {
                initialApplicationstatusService.setRenewalType("invalid");
                $scope.showStatusMsg('-', $scope.natValidation.errorMessages);
                $scope.loadingRenewalType(false);
                return;
            }
            $scope.renewalCheckData.ProcessSentData = true;
            angular.forEach($scope.renewalCheckData.IndividualLicenseList, function (individualLicense) {
                if (!!individualLicense.IsSelectedToRenew && !individualLicense.LicenseExpirationDate) {
                    var expirationOptions = {
                        baseDate: individualLicense.PreviousExpirationDate,
                        dateOfBirth: (!!$scope.personalInfo) ? $scope.personalInfo.DateOfBirth : (new Date()),
                        dayOfMonth: 'end'
                    };
                    individualLicense.LicenseExpirationDate = individualLicense.PreviousExpirationDate;
                }
            })
            licenseRenewalService.checkAndCreatePendingRenewal(sessionStorage.Key, $scope.renewalCheckData)
                .then(function (response) {
                    if (!response.Status) {
                        initialApplicationstatusService.setRenewalType('error');
                        $scope.showStatusMsg('-', response.Message);
                    } else {
                        initialApplicationstatusService.setRenewalType('success');
                        $scope.renewalCheckData = response.RenewalCheckData;
                        initialProcessing();
                        mapRefLicenseTypeAndFeeDetails();
                        $scope.setRenewalTypeEdit(false);
                        $scope.proceedToRenewalApp({
                            showRenewalTypeEnabled: false
                        });
                        $scope.openNextTab({
                            currentTab: $scope.tab
                        });
                    }
                    $scope.loadingRenewalType(false);
                }, function (data) {
                    initialApplicationstatusService.setRenewalType('error');
                    $scope.loadingRenewalType(false);
                    $scope.showStatusMsg('-', data);
                });
        };

        $scope.setRenewalTypeEdit = function (isRenewalTypeEdit) {
            $scope.isRenewalTypeEdit = isRenewalTypeEdit;
        };

        $scope.init();
    }
})();