﻿(function () {
    'use strict'
    angular.module('natApp')
        .controller('TaskDirectiveController', ['$scope', '$rootScope', 'taskDirectiveFactory', '$q', 'WebApiUrl', 'utilityService',
            function ($scope, $rootScope, taskDirectiveFactory, $q, WebApiUrl, utilityService) {

                var _key = '';
                var _UserId = '';
                var init = function () {
                    _key = sessionStorage.Key;
                    _UserId = sessionStorage.UserID;
                    $scope.natValidation = {};
                    $scope.currentPage = 1;
                    $scope.totalItems = 0;
                    $scope.itemsPerPage = 10;
                    $scope.maxSize = 6;
                    $scope.FromRow = 0;
                    $scope.Order = 0;
                    $scope.OrderByField = 'CreatedOn';

                    $scope.getEmploymentConfig().then(function () {
                        getTaskList();
                    })
                };

                var getTaskList = function () {
                    try {
                        ShowLoader();
                        var getTaskListRequest = {
                            IndividualTaskId: 0,
                            FromRow: $scope.FromRow,
                            RecordToFetch: $scope.itemsPerPage,
                            OrderByField: $scope.OrderByField,
                            AssignedToUserId: _UserId,
                            Order: $scope.Order,
                            IndividualId: null,
                            ApplicationId: null
                        };
                        taskDirectiveFactory.GetIndividualTaskList(_key, getTaskListRequest).then(function (res) {
                            HideLoader();
                            if (res.data.Status) {
                                $scope.IndividualTaskList = res.data.IndividualTaskList;
                                $scope.totalItems = res.data.TotalCount;
                            }
                        }, function (res) {
                            HideLoader();
                        });
                    } catch (ex) { console.log("Exception:", ex) }
                };

                $scope.pageChanged = function (currentPage) {
                    $scope.FromRow = ((currentPage - 1) * $scope.itemsPerPage);
                    getTaskList()
                };

                $scope.AddnewTaskBtnClick = function () {
                    $scope.ShowTaskForm = true;
                    var newTaskObj = GetNewTask();
                    $scope.TaskData = angular.copy(newTaskObj);
                };

                $scope.saveTask = function (form) {
                    try {
                        if (!validateForm(form)) {
                            return;
                        }
                        var reqData = angular.copy($scope.TaskData);
                        _SaveTaskDataToDB(reqData).then(function (res) {
                            if (res.data.Status) {
                                getTaskList();
                                $scope.cancelTaskAdd();
                            }
                        }, function (res) {

                        });
                    } catch (ex) { console.log("Exception:", ex) }
                };

                $scope.cancelTaskAdd = function () {
                    $scope.ShowTaskForm = false;
                    $scope.TaskData = {};
                    if ($scope.taskfrm) {
                        $scope.taskfrm.$submitted = false;
                        $scope.taskfrm.$setPristine();
                    }
                };

                $scope.deleteTaskFromList = function (item) {
                    try {
                        if (confirm($scope.taskConfig.ValidationMsg.deleteConfirm)) {
                            $rootScope.individualEventLog('deleteStatus.deleteConfirmed', $scope.IndividualId);
                            var taskDataToDelete = angular.copy(item);
                            taskDataToDelete.IsActive = false;
                            taskDataToDelete.IsDeleted = true;
                            _SaveTaskDataToDB(taskDataToDelete).then(function (res) {
                                if (res.data.Status) {
                                    getTaskList();
                                }
                            }, function (res) {

                            });
                        } else {
                            $rootScope.individualEventLog('deleteStatus.deleteCanceled', $scope.IndividualId);
                        }
                    } catch (ex) {
                        console.log('Exception:', ex);
                    }
                };

                $scope.editTask = function (item) {
                    $scope.isTaskEdit = true;
                    try {
                        ShowLoader();
                        $scope.TaskData = angular.copy(item);
                        $scope.ShowTaskForm = true;
                        HideLoader();
                    } catch (ex) {
                        console.log('Exception', ex);
                    }
                };

                $scope.ArchiveButtonClicked = function () {
                    try {
                        $scope.onarchiveclick();
                    } catch (e) { }
                };

                $scope.DeleteButtonClicked = function () {
                    try {
                        $scope.ondeleteclick();
                    } catch (e) { }
                };

                var _SaveTaskDataToDB = function (data) {
                    var defer = $q.defer();
                    ShowLoader();
                    taskDirectiveFactory.SaveIndividualTask(_key, data)
                        .then(function (res) {
                            defer.resolve(res);
                            HideLoader();
                        }, function (res) {
                            HideLoader();
                            defer.reject(res);
                        });
                    return defer.promise;
                };

                var validateForm = function (currentForm) {
                    if (!!currentForm) {
                        currentForm.$submitted = true;
                        $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
                    } else {
                        $scope.natValidation.errorMessages = [];
                    }
                    if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                        $scope.natValidation.showError = true;
                        $scope.natValidation.isValid = false;
                        return false;
                    } else {
                        $scope.natValidation.showError = false;
                        $scope.natValidation.isValid = true;
                        return true;
                    }
                }

                var GetNewTask = function () {
                    var tsk = {
                        IndividualId: null,
                        ApplicationId: null,
                        TaskAssignedtoUserId: _UserId,
                        TaskAssignedByUserId: _UserId,
                        TaskAssignedDate: null,
                        LinkID: null,
                        LinkIDType: null,
                        TaskTypeId: null,
                        TaskTypeName: "",
                        TaskTypeSubject: "",
                        TaskTypeDesc: "",
                        TaskStatusID: null,
                        TaskStatus: null,
                        TaskDueDate: null,
                        TaskDueTime: null,
                        TaskCompletedDateTime: null,
                        TaskRedirectURLPage: null,
                        Comments: null,
                        IsActive: true,
                        IsDeleted: false
                    }
                    return tsk;
                };

                $scope.getEmploymentConfig = function () {
                    var relativeFilePath = "/components/backoffice/ui/directives/task-directive/task-directive-config.json";
                    var defer = $q.defer();
                    utilityService.getConfigData(relativeFilePath)
                        .then(function (data) {
                            defer.resolve();
                            $scope.taskConfig = data;
                        }, function (error) {
                            HideLoader();
                            defer.reject();
                        });
                    return defer.promise;
                };
                init();
            }]);
})();

