(function () {
  'use strict'
    angular.module('app.core')
        .directive('natPhone', natPhoneDirective);

    natPhoneDirective.$inject = [];

    function natPhoneDirective() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                scope.$watch(attrs.ngModel, function (newValue, oldValue) {
                    var RegPattern = new RegExp(/^\(?[0-9]{3}(\-|\)) ?[0-9]{3}-[0-9]{4}$/);
                    if (!ctrl.$$parentForm.errorMessages) {
                        ctrl.$$parentForm.errorMessages = [];
                    }

                    var errorObj = {
                        propName: (!!attrs.validationName ? attrs.validationName : ("natphone-" + ctrl.$name)),
                        errorMessage: attrs.natPhone
          }

                    angular.forEach(ctrl.$$parentForm.errorMessages, function (error, indexOfMsg) {
                        if (errorObj.propName == error.propName) {
                            ctrl.$$parentForm.errorMessages.splice(indexOfMsg, 1);
                        }
          })
                    if (!!ctrl.$viewValue && !RegPattern.test(ctrl.$viewValue.trim())) {
                        ctrl.$$parentForm.errorMessages.push(errorObj);
                        ctrl.$setValidity("natphone", false);
                    }
                    else {
                        ctrl.$setValidity('natphone', true);
                    }
                })
            }
        }
    }
})();
