(function () {
  'use strict'
  angular.module('app.InitialApplication')
    .directive('initialApplicationEmployment', initialApplicationEmploymentDirective);

  initialApplicationEmploymentDirective.$inject = [];

  function initialApplicationEmploymentDirective() {
    return {
      restrict: 'E',
      scope: {
        employmentInfo: "=employmentInfo",
        assistantInfo: "=assistantInfo",
        showSubmitPanel: "=showSubmitPanel",
        showHeader: "=showHeader",
        headingPosition: "=headingPosition",
        isApprentice: "=isApprentice",
        applicationId: "=applicationId",
        individualId: "=individualId",
        natValidation: "=natValidation",
        isindividualinfo: "=isindividualinfo",
        applicationInfo: "=?applicationInfo",
        isBackoffice: "=isBackoffice",
      },
      templateUrl: "app/components/individual/application/directives/employment/employment.html",
      controller: "InitialApplicationEmploymentController"
    }
  }
})();