(function () {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('locationsLogic', [LocationsLogicDirective]);

    function LocationsLogicDirective() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {

            }
        };
    }
})();