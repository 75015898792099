(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("FeePaymentController", FeePaymentController);

    FeePaymentController.$inject = ["$rootScope", "$scope", "$state",
        "feePaymentService", "backofficeIndividualPersonalService", "initialApplicationstatusService", "htmlToPdfGenerationService", "backofficeIndividualAdressService",
        "utilityService", "$timeout", "$q", "dcaIService", "$exceptionHandler", "contentService", "countryStateService", "typeValuesService", "TypeValue"
        , 'Messages'];

    function FeePaymentController($rootScope, $scope, $state,
        feePaymentService, backofficeIndividualPersonalService, initialApplicationstatusService, htmlToPdfGenerationService, backofficeIndividualAdressService,
        utilityService, $timeout, $q, dcaIService, $exceptionHandler, contentService, countryStateService, typeValuesService, TypeValue, Messages) {
        var invoiceMastersDetail = [];
        var invoiceDetailsData = [];
        $scope.uiLogic = {
            sendExamEmail: false
        };
        $scope.feePayment = {};
        $scope.payment = {};
        $scope.content = {};
        $scope.personalData = {};
        $scope.additionalCss = ['app/content/css/processHtmltoPdf.css'];

        $scope.queueName = ((!!$state.current && !!$state.current.routeData && !!$state.current.routeData.queueType) ? $state.current.routeData.queueType : "");

        $scope.paymentTemplate = {
            "ApplicationNumber": "sample string 1",
            "ApplicationId": $scope.applicationId,
            "IndividualId": $scope.individualId,
            "PaymentMethod": '-1',
            "PaymentMethodSelected": "-1",
            "CertificationFees": 150,
            "TotalNoOfAdditional": '0',
            "AdditinolCertiCost": 15,
            "FeeForAdditional": '',
            "CheckNo": '',
            "CheckComment": '',
            "CheckMailing": false,
            "CardNumber": "",
            "CVV": '',
            "ExpirationMonths": '-1',
            "ExpirationYears": '-1',
            "TotalFee": 150,
            "Description": "",
            "InvoiceNumber": "",
            "FirstName": "",
            "LastName": "",
            "Address": "",
            "City": "",
            "StateCode": "",
            "Zip": "",
            "Country": "USA",
            "EmailAddress": "",
            "TransactionObject": {
                "TransactionId": (new Date()).getMilliseconds(),
                "MasterTransactionId": (new Date()).getMilliseconds(),
                "IndividualId": 3,
                "ApplicationId": 4,
                "ProviderId": 5,
                "ShoppingCartId": 6,
                "IndividualLicenseId": 7,
                "LicenseTypeId": 8,
                "LicenseNumber": "",
                "TransactionStartDatetime": "",
                "TransactionEndDatetime": "",
                "TransactionStatus": "SENT",
                "TransactionInterruptReasonId": 12,
                "TransactionDeviceTy": "TEST",
                "InvoiceNumber": "",
                "CreatedBy": 15,
                "CreatedOn": "",
                "TransactionGuid": ""
            },
            "RequestedLicenseStatusTypeId": 1
        };

        if ($scope.isBackoffice) {
            $scope.paymentTemplate.PaymentMethod = "-1";
            $scope.paymentTemplate.PaymentMethodSelected = "-1";
        } else if (!$scope.isBackoffice) {
            $scope.paymentTemplate.PaymentMethod = "3";
            $scope.paymentTemplate.PaymentMethodSelected = "3";
        }


        $scope.validationObjectTemplate = {
            Check: {
                CheckNo: false,
                CheckMailingPayment: false
            },
            Card: {
                CardNumber: false,
                Cvv: false,
                ExpDateMonth: false,
                ExpDateYear: false,
                AddressFirstName: false,
                AddressLastName: false,
                AddressStreet: false,
                AddressCity: false,
                AddressState: false,
                AddressZIP: false,
                AddressCountry: false,
            }
        };

        $scope.validationObject = angular.copy($scope.validationObjectTemplate);

        $scope.getFeePaymentConfig = function () {
            var relativeFilePath = "/components/individual/application/directives/fee-payment/fee-payment.server-config.json";
            return utilityService.getConfigData(relativeFilePath);
        };

        $scope.checkMailingChanged = function () {
            if ($scope.payment.CheckMailing) {
                $scope.payment.PaymentMethod = "1";
                $scope.payment.PaymentMethodSelected = "-1";
                $scope.changePaymentMethod(true);
            }
        };

        $scope.feeDetailAmountOverride = function () {
            $scope.payment.feeDetails.totalFee = 0;
            angular.forEach($scope.feeDetailRows, function (feeDetailRow) {
                $scope.payment.feeDetails.totalFee = $scope.payment.feeDetails.totalFee + (1 * feeDetailRow.FeeAmount);
            });
        };
        $scope.paymentAmountChanged = function () {
            // $scope.payment.feeDetails.totalFee = 0;
            $scope.payment.feeDetails.discrepancyAmount = (1 * $scope.payment.feeDetails.totalFee) - (1 * $scope.payment.feeDetails.applicationFee);
        };


        $scope.changePaymentMethod = function (isInternalChange) {
            $scope.validationObject = angular.copy($scope.validationObjectTemplate);
            if (!!$scope.currentForm)
                $scope.currentForm.$submitted = false;
            if (!isInternalChange)
                $scope.payment.PaymentMethod = $scope.payment.PaymentMethodSelected;
            if ((['1', '2', '4', '5', '6', '7', '8', '9'].indexOf($scope.payment.PaymentMethod) != -1) && !!$scope.isBackoffice) {
                $scope.validationObject.Check = {
                    CheckNo: true,
                    CheckMailingPayment: true
                };
            } else if ($scope.payment.PaymentMethod == '3') {
                $scope.validationObject.Card = {
                    CardNumber: true,
                    Cvv: true,
                    ExpDateMonth: true,
                    ExpDateYear: true,
                    AddressFirstName: true,
                    AddressLastName: true,
                    AddressStreet: true,
                    AddressCity: true,
                    AddressState: true,
                    AddressZIP: true,
                    AddressCountry: true,
                };
            }
            if (!!$scope.isRenewal) {
                if ($scope.payment.PaymentMethod == '3') {
                    if (!!$scope.payment.feeDetails.applicationFeeOverride) {
                        $scope.payment.feeDetails.applicationFee = $scope.payment.feeDetails.applicationFeeOverride;
                    }
                    if (!!$scope.payment.feeDetails.reInstatementFeeOverride)
                        $scope.payment.feeDetails.reInstatementFee = $scope.payment.feeDetails.reInstatementFeeOverride;
                }
            }
        };

        var makeFormReadOnly = function (includeControls, includeButton) {
            if (!!includeControls)
                $scope.IsControlsDisabled = true;
            else
                $scope.IsControlsDisabled = false;

            if (!!includeButton)
                $scope.IsButtonsDisabled = true;
            else
                $scope.IsButtonsDisabled = false;
        };

        var detectCardType = function (number) {
            var re = {
                VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
                MCRD: /^5[1-5][0-9]{14}$/,
                AMEX: /^3[47][0-9]{13}$/,
                DISC: /^6(?:011|5[0-9]{2})[0-9]{12}$/
            }

            for (var key in re) {
                if (re[key].test(number)) {
                    return key
                }
            }
        };

        var showErrorMessage = function (error) {
            $scope.showFeepaymentstatusMsg('-', error);
            HideLoader();
            $('#paymentFailureModal').modal('show');
            return false;
        };

        var showSuccessMessage = function (successMessage) {
            $scope.showFeepaymentstatusMsg('+', successMessage);
            HideLoader();
            return false;
        };

        var getNewApplicationFeeInvoiceDetail = function (feeDetail, moduleName, descPrefix) {
            var applicationFeeInvoiceDetail = {
                InvoiceDetailID: 0,
                InvoiceID: $scope.payment.InvoiceId,
                IndividualId: $scope.individualId,
                ApplicationId: $scope.applicationId,
                TransType: "ORD",
                Description: ((!!descPrefix ? (descPrefix + "-") : "") + feeDetail.FeeDetailDesc + ((!!feeDetail.LicenseNumber) ? ("-" + feeDetail.LicenseNumber) : "")),
                Quantity: 1,
                Amount: feeDetail.FeeAmount,
                AmtBilled: feeDetail.FeeAmount,
                AmtPaid: 0,
                AmtAdjusted: 0,
                AmtBalance: feeDetail.FeeAmount,
                TransCode: feeDetail.FeeTypeCode,
                Module: moduleName,
                DetailCode: feeDetail.FeeDetailCode,
                ReferenceNumber: ((!!feeDetail.FeeTypeCode ? feeDetail.FeeTypeCode : '') + '-' + (!!feeDetail.FeeDetailCode ? feeDetail.FeeDetailCode : '')),
                GPAdjust: 0,
                GPPayment: 0,
                CreatedBy: $scope.userId,
                CredentialNumber: ((!!feeDetail.LicenseNumber) ? feeDetail.LicenseNumber : ""),
                TableName: feeDetail.TableName,
                TableLinkIdColumnName: feeDetail.TableLinkIdColumnName,
                TableLinkId: feeDetail.TableLinkId
            };
            if ($scope.payment.PaymentMethod == 3) {
                applicationFeeInvoiceDetail.AmtPaid = applicationFeeInvoiceDetail.Amount;
                applicationFeeInvoiceDetail.AmtBalance = applicationFeeInvoiceDetail.Amount - applicationFeeInvoiceDetail.AmtPaid;
            }
            return applicationFeeInvoiceDetail;
        };

        var getIndividualTransactionObject = function (revFeeMasterId, amount, transCode) {
            return {
                RevFeeMasterId: revFeeMasterId,
                DebitAmt: 0,
                CreditAmt: amount,
                FeePaidAmount: amount,
                OrigFeeAmount: amount,
                TransCode: transCode,
                ModuleCode: "ADVDUES",
                TransType: "PAY"
            }
        };

        var getMiscFeeInvoiceDetail = function (feeDetailRow) {
            var licenseEndorsementFeeInvoiceDetail = {
                InvoiceDetailID: 0,
                InvoiceID: $scope.payment.InvoiceId,
                IndividualId: $scope.individualId,
                ApplicationId: $scope.applicationId,
                TransType: "ORD",
                Description: feeDetailRow.feeDetailType.FeeDetailDesc,
                Quantity: 1,
                Amount: feeDetailRow.Amount,
                AmtBilled: feeDetailRow.Amount,
                AmtPaid: feeDetailRow.Amount,
                AmtAdjusted: 0,
                AmtBalance: 0,
                TransCode: feeDetailRow.feeDetailType.FeeTypeCode,
                Module: 'MiscPay',
                DetailCode: feeDetailRow.feeDetailType.FeeDetailCode,
                ReferenceNumber: feeDetailRow.referenceNumber,
                GPAdjust: 0,
                GPPayment: 0,
                CreatedBy: $scope.userId
            };
            return licenseEndorsementFeeInvoiceDetail;
        };

        var getInvoiceDetailRowFormInvoiceDetails = function (feeDetailRow, invoiceDetailsData, removeItem) {
            var invoiceDetail = null;
            if (!invoiceDetailsData) {
                return invoiceDetail;
            }
            var i;
            for (i = 0; i < invoiceDetailsData.length; i++) {
                if (invoiceDetailsData[i].TransCode == feeDetailRow.FeeTypeCode &&
                    invoiceDetailsData[i].DetailCode == feeDetailRow.FeeDetailCode
                    && (!feeDetailRow.isExamRow ||
                        ((!invoiceDetailsData[i].TableName || invoiceDetailsData[i].TableName == feeDetailRow.TableName)
                            && (!invoiceDetailsData[i].TableLinkIdColumnName || invoiceDetailsData[i].TableLinkIdColumnName == feeDetailRow.TableLinkIdColumnName)
                            && (!invoiceDetailsData[i].TableLinkId || invoiceDetailsData[i].TableLinkId == feeDetailRow.TableLinkId)
                            && !!feeDetailRow.needsProcessing
                        ))) {
                    invoiceDetail = invoiceDetailsData[i];
                    break;
                }
            }
            if (!!removeItem && !!invoiceDetail) {
                invoiceDetailsData.splice(i, 1);
            }
            return invoiceDetail;
        };

        $scope.getPaymentMethod = function (paymentMode) {
            var allPaymentMethods = [{
                paymentMethodId: '-1',
                Name: "Select",
                isElectronic: false,
                showOnline: true,
                showBackoffice: true
            }, {
                paymentMethodId: '1',
                Name: "Check",
                isElectronic: false,
                showOnline: true,
                showBackoffice: (!$scope.isRefundPayment),
                PaymentMode: "CK"
            }, {
                paymentMethodId: '7',
                Name: "Cashiers Check",
                isElectronic: false,
                showOnline: false,
                showBackoffice: false,
                PaymentMode: "CCK"
            },
            {
                paymentMethodId: '2',
                Name: "Money Order",
                isElectronic: false,
                showOnline: true,
                showBackoffice: (!$scope.isRefundPayment),
                PaymentMode: "MO"
            },
            {
                paymentMethodId: '4',
                Name: "Click To Pay",
                isElectronic: false,
                showOnline: false,
                showBackoffice: false,
                PaymentMode: "PC"
            },
            {
                paymentMethodId: '5',
                Name: "Cash",
                isElectronic: false,
                showOnline: true,
                showBackoffice: (!$scope.isRefundPayment),
                PaymentMode: "CS"
            },
            {
                paymentMethodId: '3',
                Name: "Credit / Debit Card",
                isElectronic: true,
                showOnline: true,
                showBackoffice: (!$scope.isRefundPayment),
                PaymentMode: "CC"
            }, {
                paymentMethodId: '8',
                Name: "IBT",
                isElectronic: false,
                showOnline: false,
                showBackoffice: false,
                PaymentMode: "IBT"
            }, {
                paymentMethodId: '9',
                Name: "Refund",
                isElectronic: false,
                showOnline: false,
                showBackoffice: (!!$scope.isMiscPayment && !!$scope.isRefundPayment),
                PaymentMode: "RD"
            }
            ];

            if (!!paymentMode) {
                var paymentMethod;
                angular.forEach(allPaymentMethods, function (paymentMethodItem) {
                    if (paymentMode == paymentMethodItem.PaymentMode) {
                        paymentMethod = paymentMethodItem;
                    }
                });
                return paymentMethod;
            } else {
                var paymentMethodList = [];
                angular.forEach(allPaymentMethods, function (paymentMethodItem) {
                    if (($scope.isBackoffice && paymentMethodItem.showBackoffice) || (!$scope.isBackoffice && paymentMethodItem.showOnline)) {
                        paymentMethodList.push(paymentMethodItem);
                    }
                });
                return paymentMethodList;
            }
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        var validateForm = function (currentForm) {
            $scope.hideFeepaymentstatusMsg();
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            if (!$scope.isMiscPayment) {
                if (!$scope.personalData.EmailAddressContact || !$scope.personalData.EmailAddressContact.ContactInfo) {
                    $scope.natValidation.errorMessages.push({
                        propName: "personalInfoEmailReq",
                        errorMessage: $scope.isMiscPayment ? "Please fill Primary Email Address in Address tab" : "Please fill Primary Email Address in Personal tab"
                    });
                }
            }
            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }

        };

        var insertInvoiceDetails = function (invoiceDetails, transaction, IsConfirmPayment, isPaymentSubmitted) {
            feePaymentService.invoiceDetailSave(sessionStorage.Key, invoiceDetails)
                .then(function (objInvoiceDetails) {
                    if (objInvoiceDetails.StatusCode != "00") {
                        if ($scope.isRenewal)
                            initialApplicationstatusService.setPaymetStatus('error');
                        return showErrorMessage(objInvoiceDetails.Message);
                    }
                    processRevPayment(IsConfirmPayment, transaction, isPaymentSubmitted);
                }, function (response) {
                    if ($scope.isRenewal)
                        initialApplicationstatusService.setPaymetStatus('error');
                    return showErrorMessage(response);
                });
        };

        var getApprovalStatusData = function () {
            var deffer = $q.defer();
            var processingData = {
                ApprovalType: 'pendingApproval',
                ApplicationStatusId: 27,
                LogApplicationStatusIds: '2,27',
                ApplicationReviewReasonIds: "",
                UpdateApplicationStatus: true,
                IndividualId: $scope.individualId,
                ApplicationId: $scope.applicationId,
                IsSubmission: true,
                ProcessDeficiency: false
            };

            if (!!$scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 1) {
                var warningExists = false;
                if (!!$scope.renewalCheckData && $scope.renewalCheckData.IndividualLicenseList && $scope.renewalCheckData.IndividualLicenseList.length > 0) {
                    warningExists = $scope.renewalCheckData.IndividualLicenseList.any('item.WarningFlag');
                }
                //$rootScope.submissionLicenseStatusTypeId = 1;
                if ((!!$rootScope.submissionLicenseStatusTypeId && $rootScope.submissionLicenseStatusTypeId == 8) || (!!$rootScope.renewalInfoLicenseStatusTypeId && $rootScope.renewalInfoLicenseStatusTypeId == 8)) {
                    processingData.ApprovalType = 'pendingApproval';
                    if ($scope.isRetiredOrDelinquent){
                        $scope.isConviction = 1;
                    }
                    else {
                        $scope.isConviction = 0;
                    }

                    if (!!$rootScope.submissionLicenseStatusTypeId && $rootScope.submissionLicenseStatusTypeId == 8) {
                        processingData.ApprovalType = 'pendingApproval-CE';
                    }

                    processingData.ApplicationStatusId = 8;
                    processingData.LogApplicationStatusIds = '8';
                } else {
                    processingData.ApprovalType = $scope.isRenewal ? 'approvalRenewal' : 'approval';
                    processingData.ApplicationStatusId = 3;
                    processingData.LogApplicationStatusIds = '2,3';
                }
            } else {
                processingData.UpdateBackgroundCheckStatus = true;
                processingData.BackgroundCheckStatusId = 1;
            }

            deffer.resolve({
                Status: true,
                processingData: processingData
            });
            return deffer.promise;
        };

        var processRevPayment = function (isConfirmPayment, transactionsData, isPaymentSubmitted, doNotSendPaymentReceipt, donotRequirePayment) {
            $scope.payment.isInitialLoadPayment = false;
            var paymentRequest = {
                VerifyExistingPayment: !!isPaymentSubmitted,
                DonotRequirePayment: donotRequirePayment,
                VerificationModule: $scope.module,
                SendLicenseOutput: false,
                PaymentRequests: (!!transactionsData ? [transactionsData] : null),
                AccountSelection: "",
                EntityExamIds: [],
                LicenseApplicationDetails: {
                    UpdateApplicationStatus: true,
                    IndividualId: $scope.individualId,
                    ApplicationId: $scope.applicationId,
                    ApprovalType: 'pendingApproval',
                    IsSubmission: true
                },
                PaymentAndSubmissionEmailDetail: {
                    EmailAddress: $scope.personalInfo.EmailAddress,
                    IndividualId: $scope.individualId,
                    ApplicationId: $scope.applicationId,
                    ProcessEmailAndApplicationPdf: true,
                    PhoneNumber: $scope.personalInfo.PhoneNumber,
                    TotalApplicationFee: $scope.feeDetails.totalFee,
                    ApplicationFees: $scope.feeDetails.applicationFee,
                    ReInstatementFee: $scope.payment.feeDetails.reInstatementFee,
                    AdditionalApplicationFees: 0,
                    ApplicationFeeType: !!$scope.individualLicense ? $scope.individualLicense.LicenseTypeName : null,
                    LateFee: $scope.feeDetails.lateFee,
                    IsConfirm: !!isConfirmPayment,
                    IsSendAttachment: false,
                    ActionFlag: ($scope.feeDetails.isNewLicEndApp ? 'isNewLicEndApp' : ''),
                    DoNotSendPaymentReceipt: $scope.payment.DoNotSendPaymentReceipt,
                    FirstName: $scope.personalInfo.FirstName,
                    LastName: $scope.personalInfo.LastName,
                    IsMiscPayment: (!!$scope.isMiscPayment),
                    IsNewApplication: (!$scope.isRenewal),
                    PaymentHtmlProcessor: "",
                    LicenseNumber: $scope.individualLicense ? $scope.individualLicense.LicenseNumber : null,
                    LicenseTypeId: !!$scope.individualLicense ? $scope.individualLicense.LicenseTypeId : null,
                    QueueName: $scope.queueName
                },
                LicenseOutput: {
                    IndividualId: $scope.individualId,
                    ApplicationId: $scope.applicationId,
                    Email: $scope.personalInfo.EmailAddress,
                    SendApprovalEmail: true
                },
                ProcessApplicationApproval: false,
                ApplicationApprovalRequest: [{
                    EntityId: $scope.individualId,
                    EntityType: "I",
                    ApplicationId: $scope.applicationId,
                    BoardInfoId: 1,
                    EventTrigger: "RenewalApproval",
                    GenerateDCALicenseTransferFile: false,
                    GenerateLicensePaymentFile: $scope.isRetiredOrDelinquent ? false : true,
                    IndividualLicenseId: !!$scope.individualLicense ? $scope.individualLicense.IndividualLicenseId : 0,
                    LicensePaymentFileRequest: {
                        EntityId: $scope.individualId,
                        EntityType: "I",
                        ApplicationId: $scope.applicationId,
                        IndicatorDContentItemId: 396,
                        IndicatorGHContentItemIds: "430",
                        IndicatorCContentItemId: 459,
                        IndividualLicenseId: !!$scope.individualLicense ? $scope.individualLicense.IndividualLicenseId : 0,
                        LicenseStatusTypeId: (!!$scope.individualLicense && ($scope.individualLicense.LicenseStatusTypeId == 7 || $scope.individualLicense.LicenseStatusTypeId == 8 || $scope.individualLicense.LicenseStatusTypeId == 5 || $scope.individualLicense.LicenseStatusTypeId == 1)) ? 1 : 4
                    }
                }]
            };

            getApprovalStatusData().then(function (responseAppData) {
                if (responseAppData.Status) {
                    paymentRequest.LicenseApplicationDetails = responseAppData.processingData;
                    paymentRequest.ApplicationApprovalRequest[0].LicensePaymentFileRequest.ApplicationStatusId = paymentRequest.LicenseApplicationDetails.ApplicationStatusId;
                    paymentRequest.ProcessApplicationApproval = (!!paymentRequest.LicenseApplicationDetails && !!paymentRequest.LicenseApplicationDetails.ApprovalType && (paymentRequest.LicenseApplicationDetails.ApprovalType == "approval" || paymentRequest.LicenseApplicationDetails.ApprovalType == "approvalRenewal"));
                    var htmlToPdfDataPromise = htmlToPdfGenerationService.processHtmlNewApplication($scope.additionalCss);
                    if ($scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 16) {
                        htmlToPdfDataPromise = htmlToPdfGenerationService.processHtml($scope.additionalCss);
                    }
                    if (!!$scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 2 && [16, 18].includes($scope.applicationInfo.ApplicationStatusId)) {
                        paymentRequest.LicenseApplicationDetails.ApprovalType = 'examApplicationSubmission';
                        paymentRequest.LicenseApplicationDetails.UpdateApplicationStatus = true;
                        paymentRequest.LicenseApplicationDetails.ApplicationStatusId = $scope.applicationInfo.ApplicationStatusId;
                        paymentRequest.LicenseApplicationDetails.LogApplicationStatusIds = $scope.applicationInfo.ApplicationStatusId;
                        paymentRequest.LicenseApplicationDetails.UpdateBackgroundCheckStatus = false;
                        paymentRequest.LicenseApplicationDetails.IsSubmission = false;
                        paymentRequest.PaymentAndSubmissionEmailDetail.DoNotSendAppSubmissionEmail = true;
                        if (!!$scope.feeDetailRows && $scope.feeDetailRows.length > 0) {
                            paymentRequest.EntityExamIds = $scope.feeDetailRows
                                .whereEquals('EntityExam', 'TableName')
                                .whereEquals('EntityExamId', 'TableLinkIdColumnName')
                                .whereEquals(true, 'isExamRow')
                                .whereEquals(true, 'needsProcessing')
                                .select('TableLinkId');
                            paymentRequest.PaymentAndSubmissionEmailDetail.InvoiceDetailFilter = {
                                LoadInvoiceByTableLinkId: true,
                                TableName: "EntityExam",
                                TableLinkIdColumnName: "EntityExamId",
                                TableLinkIds: paymentRequest.EntityExamIds
                            };
                        }
                    }
                    htmlToPdfDataPromise.then(function (response) {
                        if ($scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 16) {
                            paymentRequest.PaymentAndSubmissionEmailDetail.TabHtml = [{
                                ApplicationID: $scope.applicationId,
                                IndividualID: $scope.individualId,
                                TabName: 'IntakeQ-RenewalHtml',
                                CreatedBy: $scope.userId,
                                DataDesc: "RenewalHtml",
                                HTMLContent: response,
                                SortingOrder: 1
                            }];
                        } else {
                            var ceSummaryElem =  angular.element(".ce-summary-tbl");
                            angular.element(ceSummaryElem).removeClass("ng-hide");
                            var ceDetailsElem =  angular.element(".ce-details-tbl");
                            angular.element(ceDetailsElem).addClass("ng-hide");

                            var personalHtml = angular.element('#tabSectionpersonalData').parent();
                            var renewalInfoHtml = angular.element('#tabSectionrenewalInfo').parent();
                            var CEHtml = angular.element('#tabSectioncontinuingeducation').parent();
                            var acknowledgmentAndDeclarationHtml = angular.element('#tabSectionacknowledgmentAndDeclaration').parent();
                            var paymentHtml = angular.element('#tabSectionfeeAndPayment').parent();

                            var personalHtmlContent = angular.element(personalHtml).html();
                            if (!!personalHtmlContent)
                                personalHtmlContent = personalHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');

                            var renewalInfoHtmlContent = angular.element(renewalInfoHtml).html();
                            if (!!renewalInfoHtmlContent)
                                renewalInfoHtmlContent = renewalInfoHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');

                            var CEHtmlContent = angular.element(CEHtml).html();
                            if (!!CEHtmlContent)
                                CEHtmlContent = CEHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');

                            var acknowledgmentAndDeclarationHtmlContent = angular.element(acknowledgmentAndDeclarationHtml).html();
                            if (!!acknowledgmentAndDeclarationHtmlContent)
                                acknowledgmentAndDeclarationHtmlContent = acknowledgmentAndDeclarationHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');

                            var paymentHtmlContent = angular.element(paymentHtml).html();
                            if (!!paymentHtmlContent)
                                paymentHtmlContent = paymentHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');

                            if (!isConfirmPayment) {
                                paymentRequest.PaymentAndSubmissionEmailDetail.TabHtml = [{
                                    ApplicationID: $scope.applicationId,
                                    IndividualID: $scope.individualId,
                                    TabName: 'IntakeQ-NewApplicationOuterPartHtml',
                                    CreatedBy: $scope.userId,
                                    DataDesc: "New Application Outer Part Html for All Tabs including the css and all sections",
                                    HTMLContent: response,
                                    SortingOrder: 0
                                }, {
                                    ApplicationID: $scope.applicationId,
                                    IndividualID: $scope.individualId,
                                    TabName: 'IntakeQ-personal',
                                    CreatedBy: $scope.userId,
                                    DataDesc: "New Application section personal Html",
                                    SortingOrder: 100,
                                    HTMLContent: personalHtmlContent
                                }, {
                                    ApplicationID: $scope.applicationId,
                                    IndividualID: $scope.individualId,
                                    TabName: 'IntakeQ-renewalInfo',
                                    CreatedBy: $scope.userId,
                                    DataDesc: "New Application section renewalInfo Html",
                                    HTMLContent: renewalInfoHtmlContent,
                                    SortingOrder: 200
                                }, {
                                    ApplicationID: $scope.applicationId,
                                    IndividualID: $scope.individualId,
                                    TabName: 'IntakeQ-CE',
                                    CreatedBy: $scope.userId,
                                    DataDesc: "New Application section CE Html",
                                    HTMLContent: CEHtmlContent,
                                    SortingOrder: 300
                                }, {
                                    ApplicationID: $scope.applicationId,
                                    IndividualID: $scope.individualId,
                                    TabName: 'IntakeQ-acknowledgmentAndDeclaration',
                                    CreatedBy: $scope.userId,
                                    DataDesc: "New Application section acknowledgmentAndDeclaration Html",
                                    HTMLContent: acknowledgmentAndDeclarationHtmlContent,
                                    SortingOrder: 400
                                }, {
                                    ApplicationID: $scope.applicationId,
                                    IndividualID: $scope.individualId,
                                    TabName: 'IntakeQ-feeAndPayment',
                                    CreatedBy: $scope.userId,
                                    DataDesc: "New Application section feeAndPayment Html",
                                    HTMLContent: paymentHtmlContent,
                                    SortingOrder: 500
                                }];
                            }

                        }

                        feePaymentService.individualProcessPayment(sessionStorage.Key, paymentRequest)
                            .then(function (objTransaction) {
                                if (objTransaction.StatusCode != "00") {
                                    if (!objTransaction.PaymentStatus) {
                                        if ($scope.isRenewal)
                                            initialApplicationstatusService.setPaymetStatus('error');
                                        return showErrorMessage(Messages.Payment.Error);
                                    }
                                }
                                let isOk = false;
                                if ($scope.isRetiredOrDelinquent) {
                                    let requestData = {
                                        isn: $scope.individualLicense.IDNumber,
                                        IndividualLicenseId:  $scope.individualLicense.IndividualLicenseId,
                                        isAbandoned: 0,
                                        IndividualId: $scope.individualId,
                                        isConviction: $scope.isConviction
                                    };
                    
                                    let renewalProcessStatusResponseRetiredOrDelinquent = dcaIService.updateRenewalProcessStatusForRetired(sessionStorage.Key, requestData);
                    
                                    isOk = (!!renewalProcessStatusResponseRetiredOrDelinquent && !!renewalProcessStatusResponseRetiredOrDelinquent.Status);
                                }
                                if ($scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 2
                                    && [16, 18].indexOf($scope.applicationInfo.ApplicationStatusId) == -1
                                ) {
                                    if (!!$scope.individualLicense && !!$scope.individualLicense.licenseTypeInfo && !!$scope.individualLicense.licenseTypeInfo.ExamRequired) {
                                        $scope.uiLogic.sendExamEmail = true;
                                    }
                                }
                                $scope.payment.PaymentEmailHtml = objTransaction.PaymentEmailHtml;
                                var individualPaymentAuthResponse = null;
                                if (!!objTransaction.IndividualPaymentAuthResponse && objTransaction.IndividualPaymentAuthResponse.length > 0) {
                                    individualPaymentAuthResponse = objTransaction.IndividualPaymentAuthResponse[0];
                                }

                                $scope.payment.InvoiceDatePayment = (!!individualPaymentAuthResponse ? individualPaymentAuthResponse.TransDateTime : null);
                                $scope.payment.IsPaymentInitiated = true;
                                makeFormReadOnly(true);
                                $scope.payment.IsPaymentSubmitted = true;

                                if (!!$scope.currentRenewalItem)
                                    $scope.currentRenewalItem.IsPaymentProcessed = true;

                                if (!$scope.isBackoffice) {
                                    showSuccessMessage('Thank you for using online process. You will receive a confirmation and receipt via email. If it does not arrive in your Inbox, please check your Spam/Junk folder. Please make sure to submit your application if not already submitted.');
                                }
                                initialApplicationstatusService.setPaymetStatus('success', paymentRequest.LicenseApplicationDetails.ApplicationStatusId);
                                if (paymentRequest.LicenseApplicationDetails.ApplicationStatusId == 3) {
                                    $rootScope.$broadcast('changeCourseStatus', { courseStatusId: 2, applicationId: $scope.applicationId });
                                }
                                $scope.$emit('paymentSuccess', {
                                    applicationId: $scope.applicationId,
                                    statusId: paymentRequest.LicenseApplicationDetails.ApplicationStatusId
                                });
                                if (!!$scope.isMiscPayment) {
                                    $scope.paymentStatusUpdate({
                                        action: 'success'
                                    });
                                }
                                $scope.openNextTab({
                                    currentTab: $scope.tab
                                });
                                makeFormReadOnly(true);
                            }, function (response) {
                                initialApplicationstatusService.setPaymetStatus('error');
                                return showErrorMessage(response);
                            });
                    });
                }
            })
        };

        $scope.pciRedirecting = function () {
            if (!!$scope.openNextTab) {
                $scope.openNextTab({ currentTab: $scope.tab });
            }
        };

        $scope.processPayment = function (currentForm, IsConfirmPayment) {
            $rootScope.SelectedPaymentMethod = $scope.payment.PaymentMethod;
            IsConfirmPayment = !!IsConfirmPayment;
            ShowLoader();
            if ($scope.isRenewal)
                initialApplicationstatusService.setPaymetStatus('waiting');
            if (!validateForm(currentForm)) {
                if ($scope.isRenewal)
                    initialApplicationstatusService.setPaymetStatus('invalid');
                HideLoader();
                return;
            }

            if (!!$scope.useAltPaymentMethod && $scope.payment.PaymentMethodSelected == 3) {
                $scope.$broadcast('beginPCICompliantPayment');
                return;
            }

            var transaction = {};
            transaction.GenerateNewReceiptNumber = true;
            transaction.ApplicationId = $scope.applicationId;
            transaction.IndividualId = $scope.individualId;
            transaction.AmountDue = $scope.payment.feeDetails.totalFee;
            transaction.TotalPaidAmount = $scope.payment.feeDetails.totalFee;
            transaction.InvoiceNo = $scope.payment.InvoiceId;
            transaction.UserDefinedPaymentNo = "";
            transaction.TransCode = "";
            transaction.EmailAddress = ((!!$scope.personalData.EmailAddressContact && !!$scope.personalData.EmailAddressContact.ContactInfo) ? $scope.personalData.EmailAddressContact.ContactInfo : '');

            if (!$scope.isBackoffice) {
                transaction.PaymentSource = "OL";
                if ($scope.payment.PaymentMethod == 1 ||
                    $scope.payment.PaymentMethod == 2 ||
                    $scope.payment.PaymentMethod == 4 ||
                    $scope.payment.PaymentMethod == 5 ||
                    $scope.payment.PaymentMethod == 7 ||
                    $scope.payment.PaymentMethod == 8 ||
                    $scope.payment.PaymentMethod == 9) {
                    transaction.RequiresApproval = true;
                    transaction.MailingPayment = $scope.payment.CheckMailing;
                } else if ($scope.payment.PaymentMethod == 3) {
                    transaction.RequiresApproval = false;
                    transaction.MailingPayment = false;
                }
            } else {
                if (IsConfirmPayment) {
                    transaction.PaymentSource = "BC";
                } else {
                    transaction.PaymentSource = "BO";
                }
                if ($scope.payment.PaymentMethod == 1 ||
                    $scope.payment.PaymentMethod == 2 ||
                    $scope.payment.PaymentMethod == 4 ||
                    $scope.payment.PaymentMethod == 5 ||
                    $scope.payment.PaymentMethod == 7 ||
                    $scope.payment.PaymentMethod == 8 ||
                    $scope.payment.PaymentMethod == 9) {
                    transaction.MailingPayment = $scope.payment.CheckMailing;
                    transaction.RequiresApproval = $scope.payment.CheckMailing;
                } else if ($scope.payment.PaymentMethod == 3) {
                    transaction.RequiresApproval = false;
                    transaction.MailingPayment = false;
                }
            }

            if ($scope.payment.PaymentMethod == 1 ||
                $scope.payment.PaymentMethod == 2 ||
                $scope.payment.PaymentMethod == 4 ||
                $scope.payment.PaymentMethod == 5 ||
                $scope.payment.PaymentMethod == 7 ||
                $scope.payment.PaymentMethod == 8 ||
                $scope.payment.PaymentMethod == 9) {
                if ($scope.payment.PaymentMethod == 1) {
                    transaction.PaymentMode = "CK";
                    transaction.TransCode = "CK";
                } else if ($scope.payment.PaymentMethod == 2) {
                    transaction.PaymentMode = "MO";
                    transaction.TransCode = "MO";
                } else if ($scope.payment.PaymentMethod == 4) {
                    transaction.PaymentMode = "PC";
                    transaction.TransCode = "PC";
                } else if ($scope.payment.PaymentMethod == 5) {
                    transaction.PaymentMode = "CS";
                    transaction.TransCode = "CS";
                } else if ($scope.payment.PaymentMethod == 7) {
                    transaction.PaymentMode = "CCK";
                    transaction.TransCode = "CCK";
                } else if ($scope.payment.PaymentMethod == 8) {
                    transaction.PaymentMode = "IBT";
                    transaction.TransCode = "IBT";
                } else if ($scope.payment.PaymentMethod == 9) {
                    transaction.PaymentMode = "RD";
                    transaction.TransCode = "RD";
                }
                transaction.PaymentModeNumber = $scope.payment.CheckNo;
                transaction.Comment = $scope.payment.CheckComment;
                if (!IsConfirmPayment)
                    transaction.ElectronicPayment = false;
                else
                    transaction.ElectronicPayment = true;
                transaction.ManualPayment = true;
            } else if ($scope.payment.PaymentMethod == 3) {
                transaction.PaymentMode = "CC";
                transaction.PaymentModeNumber = "";
                transaction.CardType = detectCardType($scope.payment.CardNumber);
                transaction.CardNumber = $scope.payment.CardNumber;
                transaction.ExpirationMonths = $scope.payment.ExpirationMonths;
                transaction.ExpirationYears = $scope.payment.ExpirationYears;
                transaction.CVV = $scope.payment.CVV;
                transaction.FirstName = $scope.payment.FirstName;
                transaction.LastName = $scope.payment.LastName;
                transaction.AddressStreetLine1 = $scope.payment.Address;
                transaction.AddressCity = $scope.payment.City;
                transaction.AddressStateCode = $scope.payment.StateCode;
                transaction.AddressState = angular.element("#ddlState option[value='" + $scope.payment.StateCode + "']").text();
                transaction.AddressZip = $scope.payment.Zip;
                transaction.AddressCountry = $scope.payment.Country;
                transaction.PhoneNumber = (($scope.personalData.CellPhoneContact) ? $scope.personalData.CellPhoneContact.ContactInfo : (($scope.personalData.HomePhoneContact) ? $scope.personalData.HomePhoneContact.ContactInfo : ''));
                transaction.PaymentInitiateSubmitMode = "OL";
                transaction.PaymentCompletionSubmitMode = "OL";
                transaction.ElectronicPayment = true;
                transaction.ManualPayment = false;
                transaction.TransCode = transaction.CardType;
            }

            transaction.IndividualTransactionObject = [];
            var invoiceDetails = [];
            //Invoice Detail Save
            var reqData = {
                IndividualId: $scope.individualId,
                InvoiceId: $scope.payment.InvoiceId
            };
            feePaymentService.getInvoiceDetail(sessionStorage.Key, reqData)
                .then(function (objInvoiceDetail) {
                    if (objInvoiceDetail.StatusCode != "00") {
                        initialApplicationstatusService.setPaymetStatus('error');
                        return showErrorMessage(objInvoiceDetail.Message);
                    }
                    invoiceDetailsData = objInvoiceDetail.EntityRecords;

                    if ($scope.isMiscPayment) {
                        angular.forEach($scope.feeDetailRows, function (feeDetailRow, index) {
                            var miscFeeInvoiceDetail = getInvoiceDetailRowFormInvoiceDetails(feeDetailRow.feeDetailType, invoiceDetailsData, true);
                            if (!miscFeeInvoiceDetail) {
                                miscFeeInvoiceDetail = getMiscFeeInvoiceDetail(feeDetailRow);
                                invoiceDetails.push(miscFeeInvoiceDetail);
                            } else if (!!miscFeeInvoiceDetail) {
                                miscFeeInvoiceDetail.ModifiedBy = $scope.userId;
                                miscFeeInvoiceDetail.Amount = feeDetailRow.Amount;
                                miscFeeInvoiceDetail.AmtBilled = feeDetailRow.Amount;
                                miscFeeInvoiceDetail.AmtPaid = feeDetailRow.Amount;
                                miscFeeInvoiceDetail.AmtBalance = 0;
                                invoiceDetails.push(miscFeeInvoiceDetail);
                            }
                            transaction.IndividualTransactionObject
                                .push(getIndividualTransactionObject(4, miscFeeInvoiceDetail.Amount, transaction.TransCode));
                        });
                    } else {
                        if (!!$scope.feeDetailRows && !!$scope.applicationInfo) {
                            $scope.feeDetailRows.forEach(function (fdr) {
                                var feeInvoiceDetail = getInvoiceDetailRowFormInvoiceDetails(fdr, invoiceDetailsData, true);
                                if (!feeInvoiceDetail) {
                                    feeInvoiceDetail = getNewApplicationFeeInvoiceDetail(fdr,
                                        ($scope.applicationInfo.ApplicationTypeId == 1 ? "RenewalApp" : ($scope.applicationInfo.ApplicationTypeId == 2 ? "NewApplication" : "")),
                                        ($scope.applicationInfo.ApplicationTypeId == 1 ? "RenApp" : ($scope.applicationInfo.ApplicationTypeId == 2 ? ($scope.module == "APPLICATION" ? "NewApp" : "") : ""))
                                    );
                                    invoiceDetails.push(feeInvoiceDetail);
                                } else if (!!feeInvoiceDetail) {
                                    feeInvoiceDetail.ModifiedBy = $scope.userId;
                                    feeInvoiceDetail.Amount = fdr.FeeAmount;
                                    feeInvoiceDetail.AmtBilled = fdr.FeeAmount;
                                    feeInvoiceDetail.CredentialNumber = fdr.LicenseNumber;
                                    feeInvoiceDetail.AmtPaid = 0;
                                    feeInvoiceDetail.AmtBalance = 0;
                                    feeInvoiceDetail.TableName = fdr.TableName;
                                    feeInvoiceDetail.TableLinkIdColumnName = fdr.TableLinkIdColumnName;
                                    feeInvoiceDetail.TableLinkId = fdr.TableLinkId;
                                    if ($scope.payment.PaymentMethod == 3) {
                                        feeInvoiceDetail.AmtPaid = feeInvoiceDetail.Amount;
                                        feeInvoiceDetail.AmtBalance = feeInvoiceDetail.Amount - feeInvoiceDetail.AmtPaid;
                                    }
                                    invoiceDetails.push(feeInvoiceDetail);
                                }
                                transaction.IndividualTransactionObject
                                    .push(getIndividualTransactionObject(6, feeInvoiceDetail == null ? 0 : feeInvoiceDetail.Amount, transaction.TransCode));
                            });
                        }
                        angular.forEach(invoiceDetailsData, function (invoiceDetailsItem) {
                            invoiceDetailsItem.IsDeleted = true;
                            invoiceDetails.push(invoiceDetailsItem);
                        });
                    }

                    if (!$scope.isMiscPayment) {
                        insertInvoiceDetails(invoiceDetails, transaction, IsConfirmPayment);
                    } else {
                        processRevPayment(IsConfirmPayment, transaction)
                    }
                }, function (response) {
                    initialApplicationstatusService.setPaymetStatus('error');
                    return showErrorMessage(response);
                });

            //Invoice Detail Save
        };

        function AuditVisitInfoSave() {
            var data = {
                "PageName": $scope.feePaymentConfig.Content.TabHeading,
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.userId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };

        $scope.getIndividual = function () {
            return backofficeIndividualPersonalService.individualBYIndividualId(sessionStorage.Key, $scope.individualId);
        };

        $scope.getIndividualAddress = function () {
            return backofficeIndividualAdressService.individualAddressBYIndividualId(sessionStorage.Key, $scope.individualId);
        };

        $scope.getIndividualContact = function () {
            return backofficeIndividualAdressService.individualContactBYIndividualId(sessionStorage.Key, $scope.individualId);
        };

        $scope.getContentByLkItemId = function () {
            contentService.getContentByContentItemLkIdList([142], sessionStorage.Key)
                .then(function (response) {
                    var contentArray = response.ContentItemLk;
                    for (var i = 0; i < contentArray.length; i++) {
                        switch (contentArray[i].ContentItemLkId) {
                            case 142:
                                $scope.content.content142 = contentArray[i].ContentItemLkDesc;
                                break;
                        }
                    }
                }, function (error) {

                });
        };

        $scope.changeOverridePaymentAmount = function () {
            if ($scope.payment.feeDetails.isOverridePaymentAmount == true) {
                angular.forEach($scope.feeDetailRows, function (feeDetailRow) {
                    feeDetailRow.OverrideFeeAmount = feeDetailRow.FeeAmount;
                });
            } else if ($scope.payment.feeDetails.isOverridePaymentAmount == false) {
                angular.forEach($scope.feeDetailRows, function (feeDetailRow) {
                    feeDetailRow.FeeAmount = feeDetailRow.OverrideFeeAmount;
                });
            }
        };

        $scope.loadReceiptDetail = function (invoiceModule) {
            var deffer = $q.defer();
            var reqData = {
                IndividualId: $scope.individualId,
                ApplicationId: $scope.applicationId
            };

            var dataPromises = {
                invoiceMaster: feePaymentService.getInvoiceMaster(sessionStorage.Key, reqData),
                revFeeCollect: feePaymentService.getRevFeeCollect(sessionStorage.Key, reqData),
                invoiceDetail: feePaymentService.getInvoiceDetail(sessionStorage.Key, reqData)
            };

            $q.all(dataPromises)
                .then(function (response) {
                    for (var prop in response) {
                        if (!response[prop].Status) {
                            HideLoader();
                            $scope.showFeepaymentstatusMsg('-', response[prop].Message);
                            deffer.resolve({
                                Status: false
                            });
                            return;
                        }
                    }
                    $scope.allInvoiceDetails = response.invoiceDetail.EntityRecords;
                    $scope.payment.receiptsData = {};
                    if (!!response.invoiceMaster && !!response.invoiceMaster.EntityRecords && response.invoiceMaster.EntityRecords.length > 0) {
                        $scope.payment.receiptsData.InvoiceMasters = response.invoiceMaster.EntityRecords.where(function (item) {
                            return (!item.InvoiceTransactionType || item.InvoiceTransactionType == 'TT' || item.InvoiceTransactionType == 'ET');
                        });
                        if (!!$scope.payment.receiptsData.InvoiceMasters && $scope.payment.receiptsData.InvoiceMasters.length > 0) {
                            angular.forEach($scope.payment.receiptsData.InvoiceMasters, function (invoiceMasterItem) {
                                if (response.invoiceDetail && response.invoiceDetail.EntityRecords && response.invoiceDetail.EntityRecords.length > 0) {
                                    invoiceMasterItem.InvoiceDetails = response.invoiceDetail.EntityRecords.whereEquals(invoiceMasterItem.InvoiceID, 'InvoiceID').whereNotEquals('CSHR', 'DetailCode');
                                }
                                if (response.revFeeCollect && response.revFeeCollect.EntityRecords && response.revFeeCollect.EntityRecords.length > 0) {
                                    invoiceMasterItem.RevFeeCollect = response.revFeeCollect.EntityRecords.whereEquals(invoiceMasterItem.InvoiceID, 'InvoiceNo').firstOrDefault();
                                }
                            });
                        }
                        var invoiceModulesToVerify = $scope.feeDetailRows.select('ModuleName').distinct();
                        $scope.payment.receiptsData.invoiceMastersByModule = $scope.payment.receiptsData.InvoiceMasters.whereIncludes(invoiceModulesToVerify, 'Module');
                        if (!!$scope.payment.receiptsData.invoiceMastersByModule && $scope.payment.receiptsData.invoiceMastersByModule.length > 0) {
                            $scope.payment.receiptsData.openInvoiceMastersByModule = $scope.payment.receiptsData.invoiceMastersByModule.whereEquals($scope.module, 'Module').whereEquals('OPEN', 'InvoiceStatus');
                            $scope.payment.receiptsData.closedInvoiceMastersByModule = $scope.payment.receiptsData.invoiceMastersByModule.whereEquals('CLOSED', 'InvoiceStatus');
                            $scope.payment.receiptsData.closedInvoiceExists = (!!$scope.payment.receiptsData.closedInvoiceMastersByModule && $scope.payment.receiptsData.closedInvoiceMastersByModule.length > 0);
                            $scope.payment.receiptsData.openInvoiceExists = (!!$scope.payment.receiptsData.openInvoiceMastersByModule && $scope.payment.receiptsData.openInvoiceMastersByModule.length > 0);
                            if ($scope.payment.receiptsData.closedInvoiceExists) {
                                var effectiveApplicationTotalAmount = 0;
                                var effectiveApplicationAmountByFeeCode = {};
                                angular.forEach($scope.payment.receiptsData.closedInvoiceMastersByModule, function (invoiceMasterItem) {
                                    angular.forEach(invoiceMasterItem.InvoiceDetails, function (invoiceDetailItem) {
                                        effectiveApplicationTotalAmount = effectiveApplicationTotalAmount + (invoiceDetailItem.Amount - (!invoiceDetailItem.ReducedAmount ? 0 : invoiceDetailItem.ReducedAmount))
                                        effectiveApplicationAmountByFeeCode[invoiceMasterItem.Module + '-' + invoiceDetailItem.DetailCode] = (!!effectiveApplicationAmountByFeeCode[invoiceMasterItem.Module + '-' + invoiceDetailItem.DetailCode] ? effectiveApplicationAmountByFeeCode[invoiceMasterItem.Module + '-' + invoiceDetailItem.DetailCode] : 0) + (invoiceDetailItem.Amount - (!invoiceDetailItem.ReducedAmount ? 0 : invoiceDetailItem.ReducedAmount))
                                    });
                                });
                                $scope.payment.receiptsData.effectiveApplicationTotalAmount = effectiveApplicationTotalAmount;
                                var sufficientTotalExistsByAccountCodes = true;
                                var requiredApplicationAmountByFeeCode = {};
                                angular.forEach($scope.feeDetailRows, function (feeDetailRow) {
                                    requiredApplicationAmountByFeeCode[feeDetailRow.ModuleName + '-' + feeDetailRow.FeeDetailCode] = (!!requiredApplicationAmountByFeeCode[feeDetailRow.ModuleName + '-' + feeDetailRow.FeeDetailCode] ? requiredApplicationAmountByFeeCode[feeDetailRow.ModuleName + '-' + feeDetailRow.FeeDetailCode] : 0) + feeDetailRow.FeeAmount
                                });
                                for (var prop in requiredApplicationAmountByFeeCode) {
                                    if (!effectiveApplicationAmountByFeeCode[prop] || effectiveApplicationAmountByFeeCode[prop] < requiredApplicationAmountByFeeCode[prop]) {
                                        sufficientTotalExistsByAccountCodes = false;
                                    }
                                }
                                $scope.payment.receiptsData.sufficientTotalExistsByAccountCodes = sufficientTotalExistsByAccountCodes;
                            }
                        }
                    }
                    $scope.payment.receiptsData.createNewInvoiceMaster = ((!$scope.payment.receiptsData.closedInvoiceExists || $scope.payment.receiptsData.effectiveApplicationTotalAmount == 0) && !$scope.payment.receiptsData.openInvoiceExists);
                    deffer.resolve({
                        Status: true
                    });
                }, function (error) {
                    deffer.resolve({
                        Status: false
                    });
                    HideLoader();
                    $scope.showFeepaymentstatusMsg('-', error.message);
                });
            return deffer.promise;
        };

        //----------Function call on Page load---------------------------
        $scope.getPaymentCommonData = function () {
            try {
                $scope.getFeePaymentConfig()
                    .then(function (data) {
                        $scope.feePaymentConfig = data;
                        if (!$scope.isRenewal) {
                            AuditVisitInfoSave();
                        }
                        $scope.payment = angular.copy($scope.paymentTemplate);
                        if (!!$scope.feeDetails) {
                            $scope.payment.feeDetails = $scope.feeDetails;
                        }
                        $scope.payment.PaymentCopyAddress = false;
                        $scope.loadReceiptDetail($scope.module).then(function (loadedResponse) {
                            if (!!loadedResponse.Status) {
                                if (!!$scope.payment.receiptsData.createNewInvoiceMaster || !!$scope.acceptNewPayment) {
                                    if (!$scope.isBackoffice) {
                                        var reqObj = {
                                            InvoiceID: 0,
                                            IndividualId: $scope.individualId,
                                            ApplicationId: $scope.applicationId,
                                            LicenseTypeId: 1,
                                            AmountBilled: $scope.payment.feeDetails.totalFee,
                                            AmountPaid: $scope.payment.feeDetails.totalFee,
                                            AmountAdjusted: 0,
                                            Balance: $scope.payment.feeDetails.totalFee,
                                            TransType: "ORD",
                                            Module: "ADVDUES",
                                            InvoiceStatus: "OPEN",
                                            ModuleGroup: 'APPLICATION',
                                            GPSelected: false,
                                            GPAdjust: 0,
                                            GPPayment: 0,
                                            GPVoid: false,
                                            Description: "ReCertification Application Payment Invoice",
                                            LFSelected: false,
                                            CreatedBy: $scope.userId,
                                            PayorId: $scope.individualId,
                                            PayorType: 'I'
                                        };
                                        if (!!$scope.module) {
                                            reqObj.LicenseTypeId = $scope.applicationInfo.LicenseTypeId;
                                            reqObj.Module = $scope.module;
                                            reqObj.Description = $scope.module + " Application Payment Invoice";
                                        } else if (!$scope.isRenewal) {
                                            reqObj.LicenseTypeId = $scope.applicationInfo.LicenseTypeId;
                                            reqObj.Module = "APPLICATION";
                                            reqObj.Description = "New Application Payment Invoice";
                                        } else {
                                            reqObj.LicenseTypeId = $scope.individualLicense.LicenseTypeId;
                                            reqObj.CredentialNumber = $scope.individualLicense.LicenseNumber;
                                            reqObj.Module = "APPLICATION";
                                            reqObj.Description = "Renewal Application Payment Invoice";
                                        }
                                        feePaymentService.invoiceMasterSave(sessionStorage.Key, [reqObj])
                                            .then(function (objMasterInvoice) {
                                                if (objMasterInvoice.StatusCode != "00") {
                                                    return showErrorMessage(objMasterInvoice.Message);
                                                }
                                                HideLoader();
                                                invoiceMastersDetail = objMasterInvoice.InvoiceMasters[0];
                                                $scope.payment.InvoiceId = invoiceMastersDetail.InvoiceID;
                                            }, function (response) {
                                                return showErrorMessage(response);
                                            });
                                    } else {
                                        HideLoader();
                                    }
                                } else {
                                    $scope.payment.InvoiceId = ($scope.payment.receiptsData.closedInvoiceExists ? $scope.payment.receiptsData.closedInvoiceMastersByModule[0].InvoiceID : $scope.payment.receiptsData.openInvoiceMastersByModule[0].InvoiceID);
                                    if (!!$scope.payment.receiptsData.closedInvoiceExists) {
                                        makeFormReadOnly(true, true);
                                    }
                                    $scope.payment.IsPaymentSubmitted = (!!$scope.payment.receiptsData.closedInvoiceExists);
                                    $scope.payment.isInitialLoadPayment = true;
                                    HideLoader();
                                }
                            }
                        });
                        $scope.isConfigLoaded = true;
                        $scope.isRetiredOrDelinquent = $rootScope.isRetiredOrDelinquent;
                        $scope.changePaymentMethod(true);
                    }, function (error) {
                        console.log(error);
                        showErrorMessage(error);
                        HideLoader();
                    });
            } catch (ex) {
                console.log(ex);
                showErrorMessage(ex);
                HideLoader();
            }
        };

        var assignAddressByAddressTypeId = function () {
            var residentialAddressExist = false;
            $scope.personalData.OfficeAddresses = [];
            angular.forEach($scope.personalAddress, function (addressOption, index) {
                if (addressOption.AddressTypeId == 1) {
                    if (!residentialAddressExist) {
                        $scope.personalData.ResidentialAddress = addressOption;
                    }
                    residentialAddressExist = true;
                } else if (addressOption.AddressTypeId == 10 || addressOption.AddressTypeId == 11) {
                    angular.forEach($scope.personalContact, function (contactOption, index) {
                        if (addressOption.IndividualAddressId == contactOption.ReferenceNumber) {
                            if (contactOption.ContactTypeId == "3") {
                                addressOption.OfficePhoneContact = contactOption;
                            }
                            if (contactOption.ContactTypeId == "30") {
                                addressOption.OfficeFaxContact = contactOption;
                            }
                        }
                    });
                    $scope.personalData.OfficeAddresses.push(addressOption);
                };
            });
        };

        var assignContactsByContactTypeId = function (returnedContact) {
            if (!!returnedContact) {
                var isAssigned = false;
                angular.forEach($scope.personalContact, function (contactOption, index) {
                    if (contactOption.ContactTypeId == "31" && returnedContact.ContactTypeId == "31" && !!returnedContact.ContactInfo) {
                        isAssigned = true;
                        contactOption = returnedContact;
                        $scope.personalData.CellPhoneContact = contactOption;
                    }
                    if (contactOption.ContactTypeId == "2" && returnedContact.ContactTypeId == "2" && !!returnedContact.ContactInfo) {
                        isAssigned = true;
                        contactOption = returnedContact;
                        $scope.personalData.HomePhoneContact = contactOption;
                    }
                    if (contactOption.ContactTypeId == "8" && returnedContact.ContactTypeId == "8" && !!returnedContact.ContactInfo) {
                        isAssigned = true;
                        contactOption = returnedContact;
                        $scope.personalData.EmailAddressContact = contactOption;
                    }
                    if (contactOption.ContactTypeId == "5" && returnedContact.ContactTypeId == "5" && !!returnedContact.ContactInfo) {
                        isAssigned = true;
                        contactOption = returnedContact;
                        $scope.personalData.FaxContact = contactOption;
                    }
                });
                if (!isAssigned) {
                    $scope.personalContact.push(returnedContact);
                    if (returnedContact.ContactTypeId == "31" && !!returnedContact.ContactInfo) {
                        $scope.personalData.CellPhoneContact = returnedContact;
                    }
                    if (returnedContact.ContactTypeId == "2" && !!returnedContact.ContactInfo) {
                        $scope.personalData.HomePhoneContact = returnedContact;
                    }
                    if (returnedContact.ContactTypeId == "8" && !!returnedContact.ContactInfo) {
                        $scope.personalData.EmailAddressContact = returnedContact;
                    }
                    if (returnedContact.ContactTypeId == "5" && !!returnedContact.ContactInfo) {
                        $scope.personalData.FaxContact = returnedContact;
                    }
                }
            } else {
                angular.forEach($scope.personalContact, function (contactOption, index) {
                    if (contactOption.ContactTypeId == "31" && !!contactOption.ContactInfo) {
                        $scope.personalData.CellPhoneContact = contactOption;
                    }
                    if (contactOption.ContactTypeId == "2" && !!contactOption.ContactInfo) {
                        $scope.personalData.HomePhoneContact = contactOption;
                    }
                    if (contactOption.ContactTypeId == "8" && !!contactOption.ContactInfo) {
                        $scope.personalData.EmailAddressContact = contactOption;
                    }
                    if (contactOption.ContactTypeId == "5" && !!contactOption.ContactInfo) {
                        $scope.personalData.FaxContact = contactOption;
                    }
                });
            }
            $scope.personalInfo.EmailAddress = ((!!$scope.personalData.EmailAddressContact && !!$scope.personalData.EmailAddressContact.ContactInfo) ? $scope.personalData.EmailAddressContact.ContactInfo : '');
            $scope.personalInfo.PhoneNumber = (($scope.personalData.CellPhoneContact) ? $scope.personalData.CellPhoneContact.ContactInfo : (($scope.personalData.HomePhoneContact) ? $scope.personalData.HomePhoneContact.ContactInfo : ''));
        };

        $scope.getAllInitData = function () {
            try {
                var dataPromises = [];
                dataPromises.push($scope.getIndividual());
                dataPromises.push($scope.getIndividualAddress());
                dataPromises.push($scope.getIndividualContact());

                $q.all(dataPromises)
                    .then(function (response) {
                        $scope.personalInfo = response[0].IndividualResponse[0];
                        $scope.personalAddress = response[1].IndividualAddressResponse.whereEquals(1, 'AdressStatusId');
                        $scope.personalContact = response[2].IndividualContactResponse.whereEquals(1, 'ContactStatusId');

                        assignAddressByAddressTypeId();
                        assignContactsByContactTypeId();
                        $scope.getPaymentCommonData();
                    }, function (error) {
                        HideLoader();
                        $scope.showStatusMessage({
                            messageContent: 'Some error occurred !',
                            messageType: 'error'
                        });
                    });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
                $exceptionHandler(ex);
            }
        };

        $scope.loadCityTypeahed = function (searchString, stateCode) {
            var cityList = [];

            if (!!$scope.CityList && stateCode == 'CA') {
                cityList = $scope.CityList.filter(function (item) {
                    if (!!item.CityName && item.CityName.toLowerCase() == searchString.toLowerCase()) {
                        return item;
                    }
                });

                if (cityList.length == 1) {
                    return cityList;
                }

                cityList = $scope.CityList.filter(function (item) {
                    if (!!item.CityName && item.CityName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) {
                        return item;
                    }
                });
            }

            return cityList;
        };

        $scope.loadZipRange = function (stateCode) {
            if (!!$scope.ZipList) {
                $scope.zipRange = [];
                var zipItem = $scope.ZipList.whereEquals(stateCode, 'StateCd').firstOrDefault();
                if (!!zipItem) {
                    for (var i = parseInt(zipItem.LowZip); i < parseInt(zipItem.HighZip); i++) {
                        $scope.zipRange.push({
                            Zip: i.toString().padStart(5, 0)
                        });
                    }
                }
                return $scope.zipRange;
            }
        };

        $scope.loadZipTypeahed = function (searchString, stateCode) {
            var zipRange = $scope.loadZipRange(stateCode);
            if (zipRange.length > 0) {
                return zipRange.filter(function (item) {
                    if (!!zipRange && item.Zip.startsWith(searchString)) {
                        return item;
                    }
                });
            }
        };

        $scope.getStateByCountryID = function (Country) {
            try {
                var CountryID = $scope.CountryList.whereEquals(Country, 'UserDefinedNumber').firstOrDefault().CountryId;
                countryStateService.getStateByCountryID(sessionStorage.Key, CountryID)
                    .then(function (response) {
                        if (!!response.Status) {
                            $scope.StatesData = response.State;
                        }
                    }, function (data) {
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        $scope.copyAddressFromApplication = function () {
            if ($scope.payment.PaymentCopyAddress) {
                var mailingAddress = $scope.personalData.ResidentialAddress;
                if (!!mailingAddress) {
                    $scope.payment.Address = mailingAddress.StreetLine1;
                    $scope.payment.City = mailingAddress.City;
                    $scope.payment.StateCode = mailingAddress.StateCode;
                    $scope.payment.Zip = mailingAddress.Zip;
                    $scope.payment.Country = $scope.CountryList.whereEquals(mailingAddress.CountryId, 'CountryId').firstOrDefault().UserDefinedNumber;
                    $scope.getStateByCountryID($scope.payment.Country);
                }
                $scope.payment.FirstName = $scope.personalInfo.FirstName;
                $scope.payment.LastName = $scope.personalInfo.LastName;
            } else {
                $scope.payment.Address = '';
                $scope.payment.City = '';
                $scope.payment.StateCode = '-1';
                $scope.payment.Zip = '';
                $scope.payment.FirstName = '';
                $scope.payment.LastName = '';
            }
        };

        $scope.$on("PayandSubmit", function (event, args) {
            if (!$scope.payment.IsPaymentSubmitted && !$scope.payment.IsPaymentInitiated)
                $scope.processPayment($scope.currentForm, false);
        });

        $scope.closePayment = function (feePaymentForm) {
            if (!!$scope.isMiscPayment) {
                $scope.natValidation.errorMessages = [];
                $scope.paymentStatusUpdate({
                    action: 'close'
                });
            }
        };

        $scope.saveAndNext = function () {
            if (!!$scope.payment.IsPaymentSubmitted) {
                $scope.openNextTab({
                    currentTab: $scope.tab
                });
                return;
            }
        };

        $scope.processPaymentRenewal = function (currentForm) {
            if (!!$scope.isRenewal) {
                $scope.$emit("PayandSubmitTabRenewal", {});
                if ($scope.isRetiredOrDelinquent) {
                    $scope.copyAddressFromApplication();
                    
                }
            }
            else {
                $scope.processPayment($scope.currentForm, false);
            }
        };

        $scope.processApplicationSubmission = function (currentForm, doNotSendPaymentReceipt, donotRequirePayment) {
            ShowLoader();
            processRevPayment(false, null, true, doNotSendPaymentReceipt, donotRequirePayment);
        };

        $scope.updateInvoiceDetailsForExam = function () {
            var invoiceDetails = [];
            invoiceDetailsData = $scope.allInvoiceDetails;
            if (!!$scope.feeDetailRows && !!$scope.applicationInfo) {
                $scope.feeDetailRows.forEach(function (fdr) {
                    if (fdr.needsProcessing) {
                        var feeInvoiceDetail = getInvoiceDetailRowFormInvoiceDetails(fdr, invoiceDetailsData, true);
                        if (!!feeInvoiceDetail) {
                            feeInvoiceDetail.TableName = fdr.TableName;
                            feeInvoiceDetail.TableLinkIdColumnName = fdr.TableLinkIdColumnName;
                            feeInvoiceDetail.TableLinkId = fdr.TableLinkId;
                            invoiceDetails.push(feeInvoiceDetail);
                        }
                    }
                });
            }
            insertInvoiceDetails(invoiceDetails, null, false, true);
        };

        $scope.processExamApplicationSubmission = function (currentForm) {
            ShowLoader();
            $scope.updateInvoiceDetailsForExam();
        };

        $scope.submitApplicationWithoutPayment = function (currentForm) {
            if (!!validateForm(currentForm)) {
                $scope.$emit("ApplicationSubmitWithoutPayment", {});
            }
        };

        $scope.init = function () {
            ShowLoader("Please Wait...");
            $scope.getAllInitData();
            $scope.PaymentMethodList = $scope.getPaymentMethod();
            if (!$scope.module) {
                $scope.module = "APPLICATION";
            }
            if ($scope.isRenewal) {
                $scope.PaymentMethodList = $scope.PaymentMethodList.filter(function (meth) {
                    return meth.PaymentMode == 'CC' || meth.Name == 'Select';
                })
            }
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=country")).then(function (response) {
                if (!response.Status) {
                    $scope.showStatusMessage(response.Message, "error");
                } else {
                    $scope.CountryList = response.ReferenceEntities;
                    $scope.DefaultCountryName = $scope.CountryList.whereEquals($rootScope.DefaultCountryId, 'CountryId').firstOrDefault().UserDefinedNumber;
                    $scope.getStateByCountryID($scope.DefaultCountryName);
                }
            }, function (error) {
                $scope.showStatusMessage(error, "error");
            });
            $scope.feeDetailRowsDisplay = angular.copy($scope.feeDetailRows);
            if (!$scope.isRenewal && !$scope.isMiscPayment) {
                var checkfingerprint = $scope.feeDetailRows.filter(function (obj) {
                    return obj.FeeTypeCode == 'FINGERPRINT';
                });
                if (checkfingerprint && checkfingerprint.length > 0) {
                    $scope.feeDetailRowsDisplay = $scope.feeDetailRowsDisplay.filter(function (obj) {
                        return obj.FeeTypeCode != 'FINGERPRINT';
                    });
                    $scope.feeDetailRowsDisplay.push({
                        AdditionalFeeAccountDesc: "Fingerprint Reports",
                        AdditionalFeeAccountuffixS: "001",
                        AdditionalFeeCode: "991937",
                        ApplicationStatusId: null,
                        ApplicationTypeId: null,
                        BoardInfoId: null,
                        CreatedBy: 100,
                        CreatedOn: "2019-05-01T00:00:00",
                        EffectiveDate: "2019-05-01T00:00:00",
                        EndDate: null,
                        FeeAccountSuffix: "01",
                        FeeAmount: 49,
                        FeeDetailCode: "DOJ",
                        FeeDetailDesc: "DOJ Fingerprint Fees",
                        FeeDetailId: 75,
                        FeeDetailName: "DOJ Fingerprint Fees",
                        FeeTypeCode: "FINGERPRINT",
                        FundAdditionalCode: "0770",
                        FundCode: "0770",
                        IndexAdditionalFeeAccountCode: "11112900",
                        IndexFeeAccountCode: "7500",
                        IsActive: 1,
                        IsDeleted: 0,
                        LicenseApplicationForId: null,
                        LicensePathwayLevel1Id: null,
                        LicensePathwayLevel2Id: null,
                        LicenseStatusTypeIds: null,
                        LicenseTypeId: null,
                        LicenseTypeIds: null,
                        ModifiedBy: null,
                        ModifiedOn: null,
                        ProgramAdditionalCode: null,
                        ProgramCode: "4840000",
                        SortOrder: null,
                        UserDefinedAccountCode: "77700",
                        UserDefinedAdditionalAccountCode: "1215014"
                    });
                }

            }

            var dataPromises = [];
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=city")));
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=zip")));
            $q.all(dataPromises)
                .then(function (response) {
                    angular.forEach(response, function (responseItem, itemIndex) {
                        if (!!responseItem.Status) {
                            if (itemIndex == 0) {
                                $scope.CityList = responseItem.ReferenceEntities;
                            } else if (itemIndex == 1) {
                                $scope.ZipList = responseItem.ReferenceEntities;
                            }
                        }
                    });
                }, function (data) {
                    HideLoader();
                    if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                });
        };

        $scope.$on('SwitchingTab', function (event, args) {
            if (args.fromTab == $scope.tab.name) {
                if (!$scope.isBackoffice) {
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                } else {
                    $scope.$broadcast("SaveDeficiency", {});
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
            }
        });

        $scope.clearTabConfirmation = function () {
            $scope.$emit("clearTabConfirmation", { currentTab: $scope.tab });
        };

        $scope.init();
    }
})();