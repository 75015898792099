(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('initialApplicationstatusService', initialApplicationstatusService)

    initialApplicationstatusService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function initialApplicationstatusService($http, $q, WebApiUrl, AppConfig) {

        var Instruction = true;
        var RenewalType = true;
        var Decleration = true;
        var Education = true;
        var StandardsPractice = true;
        var StandardsPractice3 = true;
        var CpcEducation = true;
        var CprCertification = true;
        var DentalAuxiliary = true;
        var Employment = true;
        var Legal = true;
        var Veteran = true;
        var License = true;
        var ChildSupport = true;
        var Personal = true;
        var FeeDetails = true;
        var PaymetStatus = '';
        var IsPayment = false;
        var AllTabsStatus = false;
        var AllTabsProcessed = false;
        var IndividualLicense = null;
        var AdminPermitExists = null;

        var _saveApplicationStatus = function (ApplicationId, key, applicationStatusId, queueApprovalData) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Common/ApplicationStatusUpdate/' + key + '?ApplicationId=' + ApplicationId + '&ApplicationStatusId=' + applicationStatusId + '&Source=' + AppConfig.Source, queueApprovalData)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };


        var _saveApplicationStatusLk = function (key, userId, reqObj) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Common/ApplicationStatusLkSave/' + key + '?UserId=' + userId, reqObj)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveApplicationStatusChangeLog = function (key, userId, reqObj) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Common/ApplicationStatusChangeLogSave/' + key + '?UserId=' + userId, reqObj)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _licenseStatusUpdate = function (key, reqObj) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/LicenseStatusUpdate/' + key, reqObj)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _processLicenseAndApplication = function (key, reqObj) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'IndividualLicense/ProcessLicenseAndApplication/' + key, reqObj)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _processFirmLicenseAndApplication = function (key, reqObj) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'ProviderLicense/ProcessLicenseAndApplication/' + key, reqObj)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _setInstruction = function (val) {
            Instruction = val;
            setAllTabStatus();
        };
        var _setRenewalType = function (val) {
            RenewalType = val;
            setAllTabStatus();
        };
        var _setPersonal = function (val) {
            Personal = val;
            setAllTabStatus();
        };
        var _setFeeDetails = function (val) {
            FeeDetails = val;
            setAllTabStatus();
        };
        var _setChildSupport = function (val) {
            ChildSupport = val;
            setAllTabStatus();
        };
        var _setLicense = function (val) {
            License = val;
            setAllTabStatus();
        };
        var _setVeteran = function (val) {
            Veteran = val;
            setAllTabStatus();
        };
        var _setLegal = function (val) {
            Legal = val;
            setAllTabStatus();
        };
        var _setEmployment = function (val) {
            Employment = val;
            setAllTabStatus();
        };
        var _setEducation = function (val) {
            Education = val;
            setAllTabStatus();
        };
        var _setCpcEducation = function (val) {
            CpcEducation = val;
            setAllTabStatus();
        };
        var _setStandardsPractice = function (val) {
            StandardsPractice = val;
            setAllTabStatus();
        };
        var _setStandardsPractice3 = function (val) {
            StandardsPractice3 = val;
            setAllTabStatus();
        };

        var _setCprCertification = function (val) {
            CprCertification = val;
            setAllTabStatus();
        };
        var _setDentalAuxiliary = function (val) {
            DentalAuxiliary = val;
            setAllTabStatus();
        };
        var _setdecleration = function (val) {
            Decleration = val;
            setAllTabStatus();
        };
        var _setPaymetStatus = function (val) {
            PaymetStatus = val;
        };
        var _getPaymetStatus = function () {
            return PaymetStatus;
        };

        var _resetAll = function (individualLicense, isPayment) {
            Instruction = "init";
            RenewalType = 'init';
            Decleration = "init";
            Education = "init";
            CpcEducation = "init";
            StandardsPractice = "init";
            StandardsPractice3 = "init";
            CprCertification = "init";
            DentalAuxiliary = "init";
            Employment = "init";
            Legal = "init";
            Veteran = "init";
            License = "init";
            ChildSupport = "init";
            Personal = "init";
            FeeDetails = "init";
            PaymetStatus = '';
            IndividualLicense = individualLicense;
            IsPayment = isPayment;
            AllTabsProcessed = false;
            AllTabsStatus = false;
        };
        var _getAllTabsProcessedStatus = function () {
            return AllTabsProcessed;
        };
        var _getAllTabStatus = function () {
            return AllTabsStatus;
        };
        var setAllTabStatus = function () {
            var allTabsProcessed = true;            
            if (!(Personal == "invalid" || Personal == "success" || Personal == "error")) {
                allTabsProcessed = false;
            };
            if (!(Veteran == "invalid" || Veteran == "success" || Veteran == "error")) {
                allTabsProcessed = false;
            };
            if (!(Decleration == "invalid" || Decleration == "success" || Decleration == "error")) {
                allTabsProcessed = false;
            };

            AllTabsProcessed = allTabsProcessed;
            if (allTabsProcessed == true) {
                var allTabsStatus = true;
                if ((Personal == "invalid" || Personal == "error")) {
                    allTabsStatus = false;
                };
                if ((Veteran == "invalid" || Veteran == "error")) {
                    allTabsStatus = false;
                };
                if ((Decleration == "invalid" || Decleration == "error")) {
                    allTabsStatus = false;
                };
                AllTabsStatus = allTabsStatus;
            }
        };

        return {
            saveApplicationStatus: _saveApplicationStatus,
            saveApplicationStatusLk: _saveApplicationStatusLk,
            saveApplicationStatusChangeLog: _saveApplicationStatusChangeLog,
            licenseStatusUpdate: _licenseStatusUpdate,
            processLicenseAndApplication: _processLicenseAndApplication,
            processFirmLicenseAndApplication: _processFirmLicenseAndApplication,
            setdecleration: _setdecleration,
            setInstruction: _setInstruction,
            setRenewalType: _setRenewalType,
            setEducation: _setEducation,
            setCpcEducation: _setCpcEducation,
            setStandardsPractice: _setStandardsPractice,
            setStandardsPractice3: _setStandardsPractice3,
            setCprCertification: _setCprCertification,
            setDentalAuxiliary: _setDentalAuxiliary,
            setEmployment: _setEmployment,
            setLegal: _setLegal,
            setVeteran: _setVeteran,
            setLicense: _setLicense,
            setChildSupport: _setChildSupport,
            setPersonal: _setPersonal,
            setFeeDetails: _setFeeDetails,
            setPaymetStatus: _setPaymetStatus,
            getPaymetStatus: _getPaymetStatus,
            resetAll: _resetAll,
            getAllTabsProcessedStatus: _getAllTabsProcessedStatus,
            getAllTabStatus: _getAllTabStatus
        };
    }
})();