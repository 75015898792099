(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeCertificationController", BackofficeCertificationController);

    BackofficeCertificationController.$inject = ["$rootScope", "$scope", "$state", "$q", "backofficeIndividualCertificationService", "backofficeIndividualLicenseService", "initialApplicationstatusService",
        "countryStateService", "typeValuesService", "lookupService", "utilityService", "$exceptionHandler", "TypeValue", "WebApiUrl", "multipleFileUploadService"
    , 'Messages'];

    function BackofficeCertificationController($rootScope, $scope, $state, $q, backofficeIndividualCertificationService, backofficeIndividualLicenseService, initialApplicationstatusService, countryStateService, typeValuesService, lookupService, utilityService, $exceptionHandler, TypeValue, WebApiUrl, multipleFileUploadService, Messages) {


        $scope.documentByIndividualDocumentId = multipleFileUploadService.documentByIndividualDocumentId;

        $scope.AddBoardCertification = false;
        $scope.boardCertification = {};
        $scope.natValidation = {};
        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            fromDateOpened2: false,
            toDateOpened2: false,
            format: 'M!/d!/yyyy',
            format2: 'M!/yyyy',
            dateOptions: {
                minDate: (new Date(2015, 0, 1)),
                maxDate: (new Date(2017, 0, 31)),
                ngModelOptions: {
                    allowInvalid: true
                },
            },
            startDateOptions: {
                maxDate: null
            },
            endDateOptions: {
                minDate: null
            },
            startDateOptions2: {
                maxDate: null
            },
            endDateOptions2: {
                minDate: null
            },
            startDateOptions3: {
                maxDate: null
            },
            endDateOptions3: {
                minDate: null
            }
        };
        $scope.certificationTabs = [
            { name: 'boardCertification', text: 'Board Certification' },
            { name: 'continuingEduc', text: 'Continuing Educ' },
            { name: 'ceAudit', text: 'CE Audit' },

        ];

        $scope.$on("individualTabChanged", function (evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "certification" && !$rootScope.individualCurrentTab.isLoadedOnce) {
                if (!$scope.isLoadedOnce) {
                    $scope.isLoadedOnce = true;
                }
                // $scope.refallegationstatus2(2);
                // $scope.refallegationstatus1(1);

                $scope.IndividualId = individualId;
                init();
                // $scope.UserId = sessionStorage.UserID;
                // $scope.isBackoffice = true;
                // $scope.validator = { feePayment: {} };
                // AuditVisitInfoSave();
                // $scope.loadAllegationTabData($scope.IndividualId);
                // $rootScope.individualCurrentTab.isLoadedOnce = true;
                getDoumentType(32, 'D1032'); //needs to be fixed
                getDoumentType(33, 'D1033');
                getDoumentType(34, 'D1034');

                $scope.documentBoardCertification = {
                    ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                    ApplicationId: $scope.applicationId,
                    IndividualId: $scope.IndividualId,
                    Email: null,
                    SendEmail: null,
                    TransactionId: null,
                    LicenseeReprint: false,
                    DocumentLkToPageTabSectionId: 1,
                    DocumentLkToPageTabSectionCode: "R",
                    LoadByDocIdAndDocCode: true,
                    DocId: 32,
                    DocCode: "D1032",
                    LoadDocumentsWithoutBroadCast: false,
                    LinkType: 'BC',
                    PropertyToProcess: 'IndividualBoardCertificationId',
                    IsLinkedDocControl: true
                };
                $scope.documentCECourse = {
                    ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                    ApplicationId: $scope.applicationId,
                    IndividualId: $scope.IndividualId,
                    Email: null,
                    SendEmail: null,
                    TransactionId: null,
                    LicenseeReprint: false,
                    DocumentLkToPageTabSectionId: 1,
                    DocumentLkToPageTabSectionCode: "R",
                    LoadByDocIdAndDocCode: true,
                    DocId: 33,
                    DocCode: "D1033",
                    LoadDocumentsWithoutBroadCast: false,
                    LinkType: 'CC',
                    PropertyToProcess: 'IndividualCECourseId',
                    IsLinkedDocControl: true
                };
                $scope.documentCEAudit = {
                    ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                    ApplicationId: $scope.applicationId,
                    IndividualId: $scope.IndividualId,
                    Email: null,
                    SendEmail: null,
                    TransactionId: null,
                    LicenseeReprint: false,
                    DocumentLkToPageTabSectionId: 1,
                    DocumentLkToPageTabSectionCode: "R",
                    LoadByDocIdAndDocCode: true,
                    DocId: 34,
                    DocCode: "D1034",
                    LoadDocumentsWithoutBroadCast: false,
                    LinkType: 'CA',
                    PropertyToProcess: 'IndividualCEAuditId',
                    IsLinkedDocControl: true
                };
            }
        });

        var getDoumentType = function (id, code) {
            multipleFileUploadService.documentGetDocumentTypeName(sessionStorage.Key, id, code)
                .then(function (response) {
                    if ($scope.checkResponse(response)) {
                        if (id == 8) {
                            $scope.DocType = response.DocumentMasterGET;
                        } else {
                            $scope.DocType2 = response.DocumentMasterGET;
                        }
                        // if (!!$scope.preSelectFirstDocumentType && (!!$scope.DocType && $scope.DocType.length > 0))
                        //     $scope.document.DocumentType = $scope.DocType[0];
                    }
                }, function (data) {
                    showStatusMessage(data, "error");
                });
        }

        var resetTabs = function () {
            angular.forEach($scope.certificationTabs, function (tab, key) {
                tab.isLoadedOnce = false;
            })
            $scope.setCertificationCurrentTab($scope.certificationTabs[0]);
        };

        $scope.setCertificationCurrentTab = function (tab) {
            $scope.individualCertificationCurrentTab = tab;
            // $scope.$broadcast('individualTabChanged', $scope.IndividualID);
        };
        resetTabs();


        $scope.addEditBoardCertification = function (val) {

            $scope.AddBoardCertification = true;
            if (val) {
                $scope.boardCertification = angular.copy(val);
                $scope.boardCertification.ModifiedBy = sessionStorage.UserID;
            }
            else {
                $scope.boardCertification = {
                    "IndividualId": $scope.IndividualId,
                    "BoardCertificationStatusId": 1,
                    "IsActive": true,
                    "IsDeleted": false,
                }
            }
        }
        $scope.addEditCECourseResponse = function (val) {
            $scope.AddContinuingEducForm = true;
            if (val) {
                $scope.ceCourse = angular.copy(val);
                $scope.ceCourse.ModifiedBy = sessionStorage.UserID;
            }
            else {
                $scope.ceCourse = {
                    IndividualId: $scope.IndividualId,
                    IsActive: true,
                    IsDeleted: false
                }
            }
        }


        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        }


        $scope.setEmploymentToMinDate2 = function () {
            if (angular.isDate($scope.boardCertification.BeginDate))
                $scope.datePicker.endDateOptions.minDate = null;
            $scope.boardCertification.ExpirationDate = null;
        };



        $scope.setEmploymentFromMaxDate = function () {
            if (angular.isDate($scope.boardCertification.ExpirationDate))
                $scope.datePicker.startDateOptions.maxDate = $scope.boardCertification.ExpirationDate;
        };

        $scope.setEmploymentFromMaxDate3 = function () {
            if (angular.isDate($scope.ceCourse.CECourseEndDate))
                $scope.datePicker.startDateOptions2.maxDate = $scope.ceCourse.CECourseEndDate;
        };
        $scope.setEmploymentToMinDate3 = function () {
            if (angular.isDate($scope.ceCourse.CECourseStartDate))
                $scope.datePicker.endDateOptions2.minDate = null;
            $scope.boardCertification.CECourseEndDate = null;
        };
        $scope.setEmploymentFromMaxDate4 = function () {
            if (angular.isDate($scope.ceAudit.PeriodTo))
                $scope.datePicker.startDateOptions3.maxDate = $scope.ceAudit.PeriodTo;
        };
        $scope.setEmploymentToMinDate4 = function () {
            if (angular.isDate($scope.ceAudit.PeriodFrom))
                $scope.datePicker.endDateOptions3.minDate = null;
            $scope.ceAudit.CECourseEndDate = null;
        };
        $scope.closeBoardCertificationForm = function () {
            $scope.AddBoardCertification = false;
            $scope.currentForm = null;
        }

        $scope.closeContinuingEducForm = function () {
            $scope.AddContinuingEducForm = false;
            $scope.currentForm = null;
        }

        var validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            }
            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            }
            else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.deleteBoardCertification = function (board) {
            if (confirm(Messages.cdeefee)) {
                ShowLoader();
                board.IsDeleted = true;
                backofficeIndividualCertificationService.saveIndividualBoardCertification([board], sessionStorage.Key)
                    .then(function (response) {
                        getIndividualBoardCertification();
                    }, function (error) {
                        HideLoader();
                    });
            }
        };

        $scope.deleteCECourseResponse = function (course) {
            if (confirm(Messages.cdeefee)) {
                ShowLoader();
                course.IsDeleted = true;
                backofficeIndividualCertificationService.saveIndividualCECourseSave(course, sessionStorage.Key)
                    .then(function (response) {
                        getIndividualCECourseByIndividualIdn();
                    }, function (error) {
                        HideLoader();
                    });
            }
        }
        $scope.saveBoardCertificationForm = function (CertificationForm) {
            //   $scope.closeBoardCertificationForm();
            ShowLoader("Please Wait...");
            var boardCertificationData = angular.copy($scope.boardCertification);

            if (validateForm(CertificationForm)) {
                //console.log($scope.documentUpload);
                $scope.boardCertification.CreatedBy = sessionStorage.UserID;
                //console.log($scope.boardCertification.IndividualDocumentList); 
                //console.log($scope.boardCertification);
                backofficeIndividualCertificationService.saveIndividualBoardCertification([$scope.boardCertification], sessionStorage.Key).then(function (response) {
                    //console.log(response);
                    boardCertificationData.IndividualBoardCertificationId = response.IndividualBoardCertificationList[0].IndividualBoardCertificationId;
                    $scope.documentBoardCertification.individualDocumentSave(boardCertificationData.IndividualBoardCertificationId).then(function (documentResponse) {
                        if (documentResponse.Status) {
                            $scope.boardCertification = {};
                            $scope.closeBoardCertificationForm();
                            getIndividualBoardCertification();
                            HideLoader();
                        } else {
                            HideLoader();
                            ShowErrMsg('educationDocUploadFailed', "Unable to upload Board Certification documents");
                        }


                    }, function (error) {
                        HideLoader();
                        ShowErrMsg('educationDocUploadFailed', "Unable to upload Board Certification documents");
                    })



                }, function (error) {
                    //console.log(response);
                    HideLoader();
                });
            } else {
                HideLoader();
            }

        }

        var getIndividualBoardCertification = function () {
            backofficeIndividualCertificationService.getIndividualBoardCertification(sessionStorage.Key, $scope.IndividualId).then(function (response) {
                //console.log(response);
                $scope.IndividualBoardCertificationList = processBoardCertificatioData(response.IndividualBoardCertificationList);
                //console.log($scope.IndividualBoardCertificationList);
                processIndividualBoardCertificateAndFields();
                HideLoader();
            }, function (error) {
                //console.log(response);
                HideLoader();
            });
        }
        var processIndividualBoardCertificateAndFields = function () {
            if ($scope.IndividualBoardCertificationList && $scope.IndividualBoardCertificationList.length > 0) {
                multipleFileUploadService.individualDocumentProcess(sessionStorage.Key, $scope.IndividualId,
                    $scope.IndividualBoardCertificationList, $scope.documentBoardCertification.PropertyToProcess, $scope.documentBoardCertification.LinkType).then(function (responseData) {
                        $scope.IndividualBoardCertificationList = responseData;
                        //console.log($scope.IndividualBoardCertificationList);
                        HideLoader();
                    });
            }
        };
        var processBoardCertificatioData = function (data) {
            angular.forEach(data, function (board) {
                angular.forEach($scope.boardcertificationstatus, function (status) {
                    if (board.BoardCertificationStatusId == status.BoardCertificationStatusId) {
                        board.stautsName = status.BoardCertificationStatusName;
                    }
                });
            });
            return data;
        }

        $scope.saveContinuingEducForm = function (cprForm) {
            ShowLoader("Please Wait...");
            var CECourseData = angular.copy($scope.ceCourse);

            if (validateForm(cprForm)) {
                $scope.ceCourse.CreatedBy = sessionStorage.UserID;
                //console.log($scope.ceCourse);
                backofficeIndividualCertificationService.saveIndividualCECourseSave($scope.ceCourse, sessionStorage.Key).then(function (response) {
                    //console.log(response);
                    CECourseData.IndividualCECourseId = response.IndividualCECourseResponseList[0].IndividualCECourseId;
                    $scope.documentCECourse.individualDocumentSave(CECourseData.IndividualCECourseId).then(function (documentResponse) {
                        if (documentResponse.Status) {
                            HideLoader();
                            $scope.ceCourse = {};
                            $scope.closeContinuingEducForm();
                            getIndividualCECourseByIndividualIdn();
                        } else {
                            HideLoader();
                            ShowErrMsg('educationDocUploadFailed', "Unable to upload Continuing Education documents");
                        }
                    }, function (error) {
                        HideLoader();
                        ShowErrMsg('educationDocUploadFailed', "Unable to upload Continuing Education documents");
                    })
                }, function (error) {
                    //console.log(response);
                    HideLoader();
                });
            } else {
                HideLoader();
            }
        };
        var getIndividualCECourseByIndividualIdn = function () {
            backofficeIndividualCertificationService.getIndividualCECourseByIndividualIdn(sessionStorage.Key, $scope.IndividualId).then(function (response) {
                //console.log(response.IndividualCECourseResponseList);
                $scope.IndividualCECourseResponseList = processData(response.IndividualCECourseResponseList);
                //console.log(response.IndividualCECourseResponseList);
                $scope.IndividualCECourseResponseList = processCourseStatus($scope.IndividualCECourseResponseList);
                processIndividualCECourseAndFields();
                HideLoader();
            }, function (error) {
                //console.log(response);
                HideLoader();
            });
        }
        var processCourseStatus = function (data) {
            angular.forEach(data, function (cecourse) {
                //console.log(cecourse.CECourseStatusId);
                cecourse.coursestatus = "";
                if (cecourse.CECourseStatusId == 1) {
                    cecourse.coursestatus = "Complete";
                    //console.log(cecourse);
                }
                else if (cecourse.CECourseStatusId == 2) {
                    cecourse.coursestatus = "Incomplete";
                }
                else {
                    cecourse.coursestatus = "Pending";
                }

            });
            return data;
        }
        var processIndividualCECourseAndFields = function () {
            if ($scope.IndividualCECourseResponseList && $scope.IndividualCECourseResponseList.length > 0) {
                multipleFileUploadService.individualDocumentProcess(sessionStorage.Key, $scope.IndividualId,
                    $scope.IndividualCECourseResponseList, $scope.documentCECourse.PropertyToProcess, $scope.documentCECourse.LinkType).then(function (responseData) {
                        $scope.IndividualCECourseResponseList = responseData;
                        //console.log(responseData);
                        HideLoader();
                    });
            }
        };
        var processData = function (data) {
            angular.forEach(data, function (cecours) {
                angular.forEach($scope.cecoursetype, function (status) {
                    if (cecours.CECourseActivityTypeId == status.CECourseTypeId) {
                        cecours.courseType = status.CECourseTypeName;
                    }
                });
            });
            return data;
        }

        $scope.closeContinuingEducForm = function () {
            $scope.AddContinuingEducForm = false;
            $scope.currentForm = null;
        }


        $scope.addEditCEAudit = function (val) {
            $scope.AddCEAuditForm = true;
            if (val) {
                $scope.ceAudit = angular.copy(val);
                $scope.ceAudit.ModifiedBy = sessionStorage.UserID;
                //console.log($scope.ceAudit.ModifiedBy);
            }
            else {
                $scope.ceAudit = {
                    IndividualId: $scope.IndividualId,
                    IsActive: true,
                    IsDeleted: false
                }
            }
        }

        $scope.closeAuditForm = function () {
            $scope.AddCEAuditForm = false;
            $scope.currentForm = null;
        }

        $scope.saveAuditForm = function (form) {
            ShowLoader("Please Wait...");
            var auditData = angular.copy($scope.ceAudit);

            if (validateForm(form)) {
                //console.log($scope.ceAudit);
                $scope.ceAudit.CreatedBy = sessionStorage.UserID;
                backofficeIndividualCertificationService.saveIndividualCEAudit([$scope.ceAudit], sessionStorage.Key).then(function (response) {
                    auditData.IndividualCEAuditId = response.IndividualCEAuditList[0].IndividualCEAuditId;
                    $scope.documentCEAudit.individualDocumentSave(auditData.IndividualCEAuditId).then(function (documentResponse) {
                        if (documentResponse.Status) {
                            //console.log(response);
                            HideLoader();
                            $scope.ceAudit = {};
                            $scope.closeAuditForm();
                            getIndividualCEAudit();
                        } else {
                            HideLoader();
                            ShowErrMsg('educationDocUploadFailed', "Unable to upload CE Audit documents");
                        }
                    }, function (error) {
                        HideLoader();
                        ShowErrMsg('educationDocUploadFailed', "Unable to upload CE Audit documents");
                    })
                }, function (error) {
                    //console.log(response);
                    HideLoader();
                });
            } else {
                HideLoader();
            }
        }

        var getIndividualCEAudit = function () {
            backofficeIndividualCertificationService.getIndividualCEAudit(sessionStorage.Key, $scope.IndividualId).then(function (response) {
                //console.log(response);
                $scope.IndividualCEAuditList = response.IndividualCEAuditList;
                processIndividualCEAuditAndFields();
                HideLoader();
            }, function (error) {
                HideLoader();
                //console.log(response);
            });

        }

        var processIndividualCEAuditAndFields = function () {
            if ($scope.IndividualCEAuditList && $scope.IndividualCEAuditList.length > 0) {
                multipleFileUploadService.individualDocumentProcess(sessionStorage.Key, $scope.IndividualId,
                    $scope.IndividualCEAuditList, $scope.documentCEAudit.PropertyToProcess, $scope.documentCEAudit.LinkType).then(function (responseData) {
                        $scope.IndividualCEAuditList = responseData;
                        //console.log(responseData);
                        HideLoader();
                    });
            }
        };

        $scope.deleteAudit = function (audit) {
            if (confirm(Messages.cdeefee)) {
                ShowLoader();
                audit.IsDeleted = true;
                backofficeIndividualCertificationService.saveIndividualCEAudit([audit], sessionStorage.Key)
                    .then(function (response) {
                        getIndividualCEAudit();
                    }, function (error) {
                        HideLoader();
                    });
            }
        }

        var init = function () {
            ShowLoader();

            backofficeIndividualCertificationService.typeValues(sessionStorage.Key, 'boardcertificationstatus').then(function (response) {
                //console.log(response.ReferenceEntities);
                HideLoader();
                $scope.boardcertificationstatus = response.ReferenceEntities;
                getIndividualBoardCertification();
            }, function (error) {
                HideLoader();
                getIndividualBoardCertification();
            });
            backofficeIndividualCertificationService.typeValues(sessionStorage.Key, 'cecoursetype').then(function (response) {
                //console.log(response.ReferenceEntities);
                HideLoader();
                $scope.cecoursetype = response.ReferenceEntities;
                getIndividualCECourseByIndividualIdn();
            }, function (error) {
                HideLoader();
                getIndividualCECourseByIndividualIdn();
            });
            backofficeIndividualCertificationService.typeValues(sessionStorage.Key, 'cecoursestatus').then(function (response) {
                //console.log(response.ReferenceEntities);
                HideLoader();
                $scope.cecoursestatus = response.ReferenceEntities;
                getIndividualCECourseByIndividualIdn();
            }, function (error) {
                HideLoader();
                getIndividualCECourseByIndividualIdn();
            });


            getIndividualCEAudit();


        }
    }
})();