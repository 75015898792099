(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('cprService', cprService)

    cprService.$inject = ['$http', '$q', 'WebApiUrl']

    function cprService($http, $q, WebApiUrl) {

        var _cprCertificationSave = function (objCprCertification, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/saveIndividualCPR/' + key, objCprCertification)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getAllIndividualCPR = function (Key, IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/GetIndividualCPRbyIndividualId/' + Key + '?IndividualId=' + IndividualId)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (response) {
                    deferred.reject(response);
                });
            return deferred.promise;
        };

        return {
            cprCertificationSave: _cprCertificationSave,
            getAllIndividualCPR: _getAllIndividualCPR
        };
    }
})();