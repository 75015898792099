(function () {
    'use strict'

    angular.module('app.core')
    .directive('bulkUpload', [BulkUploadDirective]);

    function BulkUploadDirective() {
        return {
            restrict: 'E',
            scope: {
                bulkUploadOperationId: '=?',
                bulkUploadOperationCode: '=?',
                inputValues: '=?',
                configName: '@',
                uploadComplete: '&?'
            },
            link: function ($scope, element, attrs) {
                $scope.isDirective = true;
            },
            templateUrl: 'app/core/directive/bulk-upload/bulk-upload.html',
            controller: 'BulkUploadController'
        };
    }
})();