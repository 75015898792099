(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationbeStaffReview', initialApplicationbeStaffReviewDirective);

    initialApplicationbeStaffReviewDirective.$inject = [];

    function initialApplicationbeStaffReviewDirective() {
        return {
            restrict: 'E',
            scope: {
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                natValidation: "=natValidation",
                providerId: "=providerId",
                applicationId: "=applicationId",
                showStatusMessage: "&",
                tab: "=tab",
                userId: '=userId',
                openNextTab: "&",
                isRenewal: "=?isRenewal",
                applicationInfo: "=?applicationInfo",
                individualViewConfig: "=?individualViewConfig"
            },
            templateUrl: "app/components/individual/businessentity/directives/be-staff-review/be-staff-review.html",
            controller: "InitialApplicationBEStaffReviewController"
        }
    }
})();