(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .directive('individualCorrespondence', individualCorrespondenceDirective);

    individualCorrespondenceDirective.$inject = [];

    function individualCorrespondenceDirective() {
        return {
            restrict: 'E',
            scope: {
                individualId: '='
            },
            templateUrl: 'app/components/backoffice/individual/correspondence/correspondence.html',
            controller: 'IndividualCorrespondenceController'
        };
    }
})();