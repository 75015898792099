(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeProviderDEAController", BackofficeProviderDEAController);

    BackofficeProviderDEAController.$inject = ["$rootScope", "$scope", "backofficeProviderDEAService", "multipleFileUploadService", "WebApiUrl", "utilityService", "backofficeIndividualLicenseService", "ProviderMultipleFileUploadService", "Messages"
    ];

    function BackofficeProviderDEAController($rootScope, $scope, backofficeProviderDEAService, multipleFileUploadService, WebApiUrl, utilityService, backofficeIndividualLicenseService, ProviderMultipleFileUploadService, Messages) {

        $scope.documentByIndividualDocumentId = multipleFileUploadService.documentByIndividualDocumentId;

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, providerId) {

            if ($rootScope.individualFirmCurrentTab.name == "dea") {
                $scope.providerId = providerId;
                // ShowLoader();
                //   $rootScope.individualFirmCurrentTab.isLoadedOnce = true;
                $scope.getDEA($scope.providerId);
                $scope.getProviderLicenseDetailBYProviderId($scope.providerId);
                getDoumentType(32, 'D1032');
                HideLoader();
                initDocument();
            }
        });

        $scope.adddeabutton = true;
        $scope.DEAFormShow = false;
        $scope.isEditDEA = false;

        $scope.documentDEATab = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.providerId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "R",
            LoadByDocIdAndDocCode: true,
            DocId: 32,
            DocCode: "D1032",
            LoadDocumentsWithoutBroadCast: false,
            LinkType: 'BC',
            PropertyToProcess: 'IndividualBoardCertificationId',
            IsLinkedDocControl: true
        };

        var getDoumentType = function (id, code) {
            multipleFileUploadService.documentGetDocumentTypeName(sessionStorage.Key, id, code)
                .then(function (response) {
                    if ($scope.checkResponse(response)) {
                        if (id == 8) {
                            $scope.DocType = response.DocumentMasterGET;
                        } else {
                            $scope.DocType2 = response.DocumentMasterGET;
                        }
                    }
                }, function (data) {
                    showStatusMessage(data, "error");
                });
        }
        $scope.natValidation = {};

        var validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            }

            if ($scope.dea.AddInfoTypeValue1 == undefined && $scope.dea.Schedule == undefined) {
                //$scope.natValidation.errorMessages.push("Cannot save empty row!");
                var errorObj = {
                    propName: "emptyDEARow",
                    errorMessage: Messages.General.Errors.EmptyRow
                };
                if (!$scope.natValidation.errorMessages) {
                    $scope.natValidation.errorMessages = [];
                }
                $scope.natValidation.errorMessages.push(errorObj);
                // $scope.natValidation.showError = true;
                // $scope.natValidation.isValid = false;
                // return false;
            }

            if ($scope.dea && $scope.dea.LicenseNumber) {
                var filterDeaList = _.find($scope.deaList, function (o) { return o.LicenseNumber == $scope.dea.LicenseNumber; });
                if (filterDeaList && filterDeaList.IndividualLicenseAddInfoId != $scope.dea.IndividualLicenseAddInfoId) {
                    var errorObj = {
                        propName: "emptyDEALicenseRow",
                        errorMessage: Messages.General.Errors.DAE
                    };
                    if (!$scope.natValidation.errorMessages) {
                        $scope.natValidation.errorMessages = [];
                    }
                    $scope.natValidation.errorMessages.push(errorObj);
                }
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }

        };

        $scope.editDEA = function (item) {
            $scope.adddeabutton = false;
            $scope.dea = angular.copy(item);
            $scope.DEAFormShow = true;
            $scope.isEditDEA = true;
        }
        $scope.cancelDEAForm = function () {
            $scope.adddeabutton = true;
            $scope.dea = {};
            $scope.DEAFormShow = false;
            $scope.natValidation = {};
            $scope.natValidation.errorMessages = [];
            $scope.isEditDEA = false;
        }

        $scope.addNewDEA = function () {
            $scope.adddeabutton = false;
            $scope.DEAFormShow = true;
            $scope.dea = {
                EntityId: $scope.providerId,
                IsActive: true,
                IsDeleted: false,
                AddInfoTypeId1: 21
            };
            $scope.isEditDEA = false;
            $scope.natValidation = {};
            $scope.natValidation.errorMessages = [];
        }

        $scope.getDEA = function (providerId) {
            try {
                ShowLoader("Please Wait...");
                backofficeProviderDEAService.getIndividualLicenseAddInfo(sessionStorage.Key, providerId, 0)
                    .then(function (response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();

                        if ($scope.checkServerResponse(response)) {
                            $scope.deaList = response.IndividualLicenseAddInfoList;
                            $scope.processDEAFields();
                        }
                        HideLoader();
                    },
                        function (response) {
                            HideLoader();
                            $scope.showStatusMessage(response.data.Message, "error");
                        });
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        }



        $scope.processDEAFields = function () {
            var indexOfSlash = -1;
            var totalChar = -1;
            if (!!$scope.deaList) {
                angular.forEach($scope.deaList, function (val, index) {
                    indexOfSlash = $scope.deaList[index].AddInfoTypeValue1.indexOf("/");

                    totalChar = $scope.deaList[index].AddInfoTypeValue1.length;
                    $scope.deaList[index].Schedule = $scope.deaList[index].AddInfoTypeValue1.substring(indexOfSlash + 1, totalChar);
                    if ($scope.deaList[index].Schedule == "undefined") {
                        $scope.deaList[index].Schedule = "";
                    }
                    $scope.deaList[index].AddInfoTypeValue1 = $scope.deaList[index].AddInfoTypeValue1.substring(0, indexOfSlash);
                    if ($scope.deaList[index].AddInfoTypeValue1 == "undefined") {
                        $scope.deaList[index].AddInfoTypeValue1 = "";
                    }
                });
                processDocument();
            }
        }

        var processDocument = function () {
            if ($scope.deaList && $scope.deaList.length > 0) {
                ProviderMultipleFileUploadService.providerDocumentProcess(sessionStorage.Key, $scope.ProviderId, $scope.documentConfig.DocId,
                    $scope.deaList, $scope.documentConfig.PropertyToProcess, $scope.documentConfig.LinkType).then(function (responseData) {
                        $scope.deaList = responseData;
                    });
            }
        };

        $scope.documentConfig = {
            DocId: 32,
            LinkType: 'OI',
            PropertyToProcess: 'IndividualLicenseAddInfoId'
        };

        var initDocument = function () {
            $scope.documentUpload = undefined;
            setTimeout(function () {
                $scope.$apply(function () {
                    $scope.documentUpload = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                        // ApplicationId: _applicationId,
                        ProviderId: $scope.providerId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FRM",
                        LoadByDocIdAndDocCode: true,
                        DocId: 32,
                        DocCode: "D1032",
                        LoadDocumentsWithoutBroadCast: true,
                        //PDocumentLkType: 'OI'
                        LinkType: 'OI',
                        PropertyToProcess: 'IndividualLicenseAddInfoId'
                    };
                });
            }, 1000);
        };

        $scope.getDocument = function (provDocID) {
            try {
                ShowLoader("Please wait...");
                ProviderMultipleFileUploadService.providerDocumentByProviderDocumentId(sessionStorage.Key, provDocID)
                    .then(function (response) {
                        var headers = response.headers();
                        response = response.data;
                        utilityService.downloadExcelFile(response, headers);
                        HideLoader();
                    });
            } catch (ex) {
                showStatusMessage(ex.message, "error");
                HideLoader();
                $exceptionHandler(ex);
            }
        };


        $scope.setSaveDocumentRef = function (fn) {
            $scope.saveAllDocument = fn;
        };

        $scope.savedea = function (currentForm) {
            try {
                if (!validateForm(currentForm)) {
                    return;
                };
                $scope.dea.AddInfoTypeValue1 = $scope.dea.AddInfoTypeValue1 + "/" + $scope.dea.Schedule;

                ShowLoader("Please Wait...");
                var filterLicense = _.find($scope.IndividualLicenseList, function (o) { return o.ProviderLicenseNumber == $scope.dea.ProviderLicenseNumber; });
                if (filterLicense) {
                    $scope.dea.EntityLicenseId = filterLicense.ProviderLicenseId;
                }
                backofficeProviderDEAService.saveIndividualLicenseAddInfo(sessionStorage.Key, [$scope.dea])
                    .then(function (response) {
                        $scope.documentUpload.LinkId = response.IndividualLicenseAddInfoList[0].IndividualLicenseAddInfoId;
                        if (typeof $scope.saveAllDocument == "function") {
                            $scope.saveAllDocument().then(function () {
                                $scope.getDEA($scope.providerId);
                                $scope.DEAFormShow = false;
                                $scope.adddeabutton = true;
                            }, function () { });
                        } else {
                            $scope.getDEA($scope.providerId);
                            $scope.DEAFormShow = false;
                            $scope.adddeabutton = true;
                        }

                        HideLoader();
                    });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        }

        $scope.deleteDEA = function (deaObject) {
            try {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    $rootScope.individualEventLog('deleteStatus.deleteConfirmed', $scope.IndividualId);
                    deaObject.IsDeleted = true;
                    ShowLoader("Please Wait...");

                    backofficeProviderDEAService.saveIndividualLicenseAddInfo(sessionStorage.Key, [deaObject])
                        .then(function (response) {
                            $scope.getDEA($scope.providerId);
                            HideLoader();
                        });
                }
                else {
                    $rootScope.individualEventLog('deleteStatus.deleteCanceled', $scope.IndividualId);
                }
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        }

        $scope.getProviderLicenseDetailBYProviderId = function (providerId) {
            ShowLoader("Please Wait...");
            try {
                $scope.ConvertableIndividualLicenseId = 0;
                backofficeProviderDEAService.getProviderLicenseDetailBYProviderId(sessionStorage.Key, providerId)
                    .then(function (response) {
                        HideLoader();
                        if ($scope.checkServerResponse(response)) {
                            var isLicenseActiveList = _.filter(response.ProviderLicenseResponseList, function (o) { return o.IsLicenseActive == true; });
                            $scope.IndividualLicenseList = _.uniq(isLicenseActiveList);

                        }
                    }, function (data) {
                        HideLoader();
                    });
            } catch (ex) {
                HideLoader();
            }
        };
    }
})();