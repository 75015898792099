﻿(function () {
    'use strict';

    angular
        .module('app.lga')
        .factory("LGARequestAccessFactory", ['$http', '$q', 'WebApiUrl', 'AppConfig', function ($http, $q, WebApiUrl, AppConfig) {
            var baseFactory = {};
            baseFactory.GovernmentAgencyUserGetAll = function (reqData) {
                return returnDefferedResult($http.post(WebApiUrl + 'Agency/GovernmentAgencyUserGetAll', reqData));
            };
            baseFactory.UserChangeLogSave = function (Key, userId, param) {
                return returnDefferedResult($http.post(WebApiUrl + 'Users/UserChangeLogSave?Key=' + Key + '&userId=' + userId, param));
            };
            baseFactory.ConfigurationGetbySettings = function (Key) {
                return returnDefferedResult($http.get(WebApiUrl + 'Configuration/ConfigurationGetbySettings?Key=' + Key + '&Setting=CAMTCLGAFromEmailAddress'));
            };

            var returnDefferedResult = function (request) {
                var deferred = $q.defer();
                request.then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.reject(response);
                });
                return deferred.promise;
            };
            return baseFactory;

        }]);

})();