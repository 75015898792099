(function () {
    'use strict'

    angular.module('app.backofficeEntity')
        .factory('ShareholderPartnerService', ShareholderPartnerService)

    ShareholderPartnerService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function ShareholderPartnerService($http, $q, WebApiUrl, AppConfig) {

        return {
            loadAllAdditionalInfo: _loadAllAdditionalInfo,
            saveAdditionalInfo: _saveAdditionalInfo,
            providerCECourseByProviderId: _providerCECourseByProviderId
        };

        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function _saveAdditionalInfo(entityId, applicationId, userId, legalInfoObj, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Entity/SaveEntityLegalAddInfo/' + key +
                '?entityId=' + entityId + '&applicationId=' + applicationId + '&userId=' + userId, legalInfoObj));
        };

        function _loadAllAdditionalInfo(entityId, applicationId, userId, legalInfoObj, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Entity/LoadAllEntityLegalAddInfo/' + key + '?Source=' + AppConfig.Source + '&entityId=' + entityId + '&applicationId=' + applicationId + '&userId=' + userId, legalInfoObj));
        };

        function _providerCECourseByProviderId (providerId, providerName, key, applicationId, onlyLatestRecord) {
            return returnDefferedResult($http.get(WebApiUrl + 'Provider/GetProviderCourseByProviderId/' + key + '?ProviderId=' + providerId + '&ProviderName=' + providerName + ((!!applicationId ? ('&ApplicationId=' + applicationId) : '')) + ((!!onlyLatestRecord ? ('&OnlyLatestRecord=' + onlyLatestRecord) : ''))));
        };
    }
})();