(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .directive('individualInfoOtherLogic', individualInfoOtherLogicDirective);

    individualInfoOtherLogicDirective.$inject = ["$q", "$timeout", 'individualInfoOtherLogicService', 'dcaIService', 'backofficeIndividualLicenseService', 'typeValuesService', 'TypeValue', "$rootScope"];

    function individualInfoOtherLogicDirective($q, $timeout, individualInfoOtherLogicService, dcaIService, backofficeIndividualLicenseService, typeValuesService, TypeValue, $rootScope) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.warningTypes = [{
                    name: "Yes",
                    value: true
                }, {
                    name: "No",
                    value: false
                }];

                $scope.uiLogic = {
                    isEditingAddresses: false
                };

                // BEGIN ADDRESS TAB
                $scope.dispensingAddressHeaders = {
                    AddressStatusName: {
                        label: 'Status'
                    },
                    StreetLine1: {
                        label: 'Street Line 1'
                    },
                    StreetLine2: {
                        label: 'Street Line 2'
                    },
                    City: {
                        label: 'City'
                    },
                    StateCode: {
                        label: 'State'
                    },
                    Zip: {
                        label: 'Zip'
                    },
                    BeginDate: {
                        label: 'Effective Date'
                    },
                    LicenseNumber: {
                        label: 'Credential Number'
                    },
                    Actions: {
                        label: 'Actions',
                        templateUrl: 'addressActionsTemplate.html',
                        sizeRatio: 1.2
                    }
                };

                $scope.dispensingAddressOptions = {
                    includeCounty: true,
                    includeCountry: true,
                    showPublicMailing: false
                };

                var getNewEmploymentConfirmation = function () {
                    return {
                        IndividualEmploymentConfirmationId: 0,

                        IndividualId: $scope.individualId,
                        ApplicationId: $scope.applicationId,
                        IndividualLicenseId: (!!$scope.individualLicense) ? $scope.individualLicense.IndividualLicenseId : null,

                        CurrentlyEmployed: true,
                        EmployedInPast: false,

                        IsActive: true,
                        IsDeleted: false
                    };
                };

                var loadEmploymentConfirmation = function () {
                    var deferred = $q.defer();

                    try {
                        $scope.employmentConfirmation = null;
                        individualInfoOtherLogicService.getEmploymentConfirmation(sessionStorage.Key, $scope.individualId)
                            .then(function (data) {
                                if (!!data.IndividualEmploymentConfirmationlst && data.IndividualEmploymentConfirmationlst.length > 0) {
                                    $scope.employmentConfirmation = data.IndividualEmploymentConfirmationlst.firstOrDefault();
                                } else {
                                    $scope.employmentConfirmation = getNewEmploymentConfirmation();
                                }
                                deferred.resolve(data);
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                var saveEmploymentConfirmation = function () {
                    var deferred = $q.defer();

                    try {
                        individualInfoOtherLogicService.saveEmploymentConfirmation(sessionStorage.Key, [$scope.employmentConfirmation])
                            .then(function (data) {
                                $scope.logDataChanges();

                                deferred.resolve(data);
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.initEmploymentConfirmation = function () {
                    loadEmploymentConfirmation();
                };

                $scope.currentlyEmployedChanged = function () {
                    if ($scope.employmentConfirmation.CurrentlyEmployed && !!$scope.latestBusinessAddress) {
                        $scope.$broadcast('addressStatusChanged', {
                            IndividualAddressId: $scope.latestBusinessAddress.IndividualAddressId,
                            AddressStatusId: 1
                        });
                    } else if (!!$scope.latestBusinessAddress) {
                        $scope.$broadcast('addressStatusChanged', {
                            IndividualAddressId: $scope.latestBusinessAddress.IndividualAddressId,
                            AddressStatusId: 2
                        });
                    }
                };

                var getNewContact = function (contactTypeId) {
                    return {
                        IndividualContactId: 0,
                        ContactId: 0,
                        IndividualId: $scope.individualId,

                        BeginDate: new Date().toJSON(),
                        Code: '',
                        ContactFirstName: '',
                        ContactInfo: '',
                        ContactLastName: '',
                        ContactMiddleName: '',
                        ContactTypeId: contactTypeId,
                        DateContactValidated: null,
                        EndDate: null,

                        IsActive: true,
                        IsMobile: false,
                        IsPreferredContact: false,
                        ContactStatusId: 1,
                        CreatedBy: sessionStorage.UserID,
                        CreatedOn: new Date(),

                        justCreated: true
                    };
                };

                $scope.contactChanged = function (originalContact, contactOption) {
                    if (originalContact.ContactInfo != contactOption.ContactInfo) {
                        contactOption.isContactChanged = true;
                    } else {
                        contactOption.isContactChanged = false;
                    }
                };

                $scope.mailingAddressType = '';
                $scope.publicAddressType = '';

                $scope.publicChanged = function (address, type) {
                    $scope.publicAddressType = type;
                };

                $scope.mailingChanged = function (address, type) {
                    $scope.mailingAddressType = type;
                };

                $scope.addressesLoaded = function (addresses) {
                    $scope.allAddresses = addresses;
                    var types = {
                        2: 'r',
                        3: 'o',
                        5: 'b'
                    };

                    var currentMailing = $scope.allAddresses.whereEquals(1, 'AdressStatusId').where('item.IsMailingSameasPhysical').firstOrDefault();
                    if (!!currentMailing) {
                        $scope.mailingAddressType = types[currentMailing.AddressTypeId];
                    }

                    var currentPublic = $scope.allAddresses.whereEquals(1, 'AdressStatusId').where('item.IsPublicAddress').firstOrDefault();
                    if (!!currentPublic) {
                        $scope.publicAddressType = types[currentPublic.AddressTypeId];
                    }

                    var latestBusiness = $scope.allAddresses.whereEquals(5, 'AddressTypeId').orderByDescending('IndividualAddressId').firstOrDefault();
                    if (!!latestBusiness) {
                        $scope.latestBusinessAddress = latestBusiness;
                    }
                };

                $scope.addressesSaved = function () {
                    //Save employment confirmation
                    saveEmploymentConfirmation().then(loadEmploymentConfirmation);
                };

                var filterContacts = function () {
                    // 8 and 31
                    $scope.emailContact = $scope.allContacts.where('item.ContactStatusId==1 && item.ContactTypeId==8').firstOrDefault();
                    if (!$scope.emailContact) {
                        $scope.emailContact = getNewContact(8);
                    }
                    $scope.emailContact.ActionFlag = 'UI';
                    $scope.originalEmailContact = angular.copy($scope.emailContact);

                    $scope.cellPhoneContact = $scope.allContacts.where('item.ContactStatusId==1 && item.ContactTypeId==1').firstOrDefault();
                    if (!$scope.cellPhoneContact) {
                        $scope.cellPhoneContact = getNewContact(1);
                    }
                    $scope.cellPhoneContact.ActionFlag = 'UI';
                    $scope.originalCellPhoneContact = angular.copy($scope.cellPhoneContact);
                };

                $scope.getAllContacts = function () {
                    var deferred = $q.defer();
                    try {
                        ShowLoader();

                        individualInfoOtherLogicService.getAllContacts(sessionStorage.Key, $scope.individualId)
                            .then(function (data) {
                                HideLoader();
                                if (data.Status) {
                                    $scope.allContacts = data.IndividualContactResponse;

                                    filterContacts();

                                    deferred.resolve(data);
                                } else {
                                    deferred.reject(data);
                                }
                            }, function (err) {
                                console.log(err);
                                HideLoader();
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        $scope.$evalAsync(function () {
                            console.log(ex);
                            HideLoader();
                            deferred.reject(ex);
                        });
                    }

                    return deferred.promise;
                };

                $scope.getUser = function () {
                    var deferred = $q.defer();

                    try {
                        individualInfoOtherLogicService.getUser(sessionStorage.Key, $scope.individualId)
                            .then(function (data) {
                                if (data.Status && data.Users && data.Users.IndividualId == $scope.individualId) {
                                    $scope.individualUser = data.Users;
                                }
                                deferred.resolve(data);
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.saveContact = function (contact) {
                    var deferred = $q.defer();

                    try {
                        ShowLoader();

                        individualInfoOtherLogicService.saveContacts(sessionStorage.Key, [contact])
                            .then(function (data) {
                                HideLoader();
                                if (data.Status) {
                                    $scope.logDataChanges();

                                    $scope.allContacts = data.IndividualContactResponse;

                                    filterContacts();

                                    deferred.resolve(data);
                                } else {
                                    deferred.reject(data);
                                }
                            }, function (err) {
                                console.log(err);
                                HideLoader();
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        console.log(ex);
                        HideLoader();
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.saveUser = function (user) {
                    var deferred = $q.defer();

                    try {
                        individualInfoOtherLogicService.saveUser(sessionStorage.Key, user)
                            .then(function (data) {
                                deferred.resolve(data);
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.updateEmail = function () {
                    if (!!$scope.emailContact.isContactChanged) {
                        $scope.saveContact($scope.emailContact);
                        if (!!$scope.individualUser && $scope.individualUser.IndividualId == $scope.individualId) {
                            $scope.individualUser.UserName = $scope.emailContact.ContactInfo;
                            $scope.individualUser.Email = $scope.emailContact.ContactInfo;

                            $scope.saveUser($scope.individualUser);
                        }
                        var requestData = {
                            EntityId: $scope.individualId,
                            IndividualContactId: $scope.emailContact.IndividualContactId
                        };
                        dcaIService.updateEmail(sessionStorage.Key, requestData).then(function (responseUpdate) {
                            if (!responseUpdate.Status) {

                            }
                        }, function (err) {
                        });
                    }
                };

                $scope.updateCell = function () {
                    if (!!$scope.cellPhoneContact.isContactChanged) {
                        $scope.saveContact($scope.cellPhoneContact);
                        var requestData = {
                            EntityId: $scope.individualId,
                            IndividualContactId: $scope.cellPhoneContact.IndividualContactId
                        };
                        dcaIService.updatePhone(sessionStorage.Key, requestData).then(function (responseUpdate) {
                            if (!responseUpdate.Status) {

                            }
                        }, function (err) {
                        });
                    }
                };

                $scope.initContacts = function () {
                    $scope.allContacts = [];
                    $scope.emailContact = null;
                    $scope.originalEmailContact = null;
                    $scope.cellPhoneContact = null;
                    $scope.originalCellPhoneContact = null;

                    $timeout(function () {
                        $scope.getAllContacts();
                        $scope.getUser();
                    }, 1);
                };

                $scope.initLicenseList = function () {
                    var deferred = $q.defer();

                    try {
                        individualInfoOtherLogicService.individualLicenseDetailBYIndividualId(sessionStorage.Key, $scope.individualId)
                            .then(function (data) {
                                $scope.individualLicenseList = data.IndividualLicenseList;

                                deferred.resolve(data);
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.extAddressChanged = function (originalAddress, address) {
                    if (originalAddress.BeginDate != address.BeginDate ||
                        originalAddress.LicenseNumber != address.LicenseNumber) {
                        address.extAddressChanged = true;
                    } else {
                        address.extAddressChanged = false;
                    }
                };
                // END ADDRESS TAB

                // BEGIN COMPLAINT

                var getWarning = function () {
                    individualInfoOtherLogicService.getWarning(sessionStorage.Key, $scope.individualId, "I", null)
                        .then(function (response) {
                            if (response.Status) {
                                var disciplineExists = false;
                                angular.forEach(response.DisciplineList, function (warning) {
                                    if (warning.EndDate == null || (new Date(warning.EndDate)) > (new Date())) {
                                        disciplineExists = true;
                                    }
                                });
                                $scope.discipline = {
                                    disciplineExists: disciplineExists
                                };
                            } else {
                                $scope.discipline = {
                                    disciplineExists: false
                                };
                            }
                        }, function (data) {
                            $scope.discipline = {
                                disciplineExists: false
                            };
                            $scope.showStatusMessage(data, "error");
                        });
                };

                var individualLicenseDetailBYIndividualId = function (individualId) {
                    try {
                        individualInfoOtherLogicService.individualLicenseDetailBYIndividualId(sessionStorage.Key, individualId)
                            .then(function (response) {
                                if (!!response.IndividualLicenseList && response.IndividualLicenseList.length > 0) {
                                    $scope.licenseData = {
                                        IDNumbers: response.IndividualLicenseList.whereNotEquals(null, 'IDNumber').select('IDNumber').distinct().join(',')
                                    };
                                }
                            }, function (data) {
                                HideLoader();
                                $scope.showStatusMessage(data, "error");
                            });
                    } catch (ex) {
                        HideLoader();
                        if (ex != null) {
                            $scope.showStatusMessage(ex.message, "error");
                        }
                    }
                };

                $scope.applicationByIndividualId = function (individualId) {
                    var urlParmReq = "?Key=" + sessionStorage.Key + "&IndividualId=" + individualId;
                    $q.all([backofficeIndividualLicenseService.applicationBYIndividualId(urlParmReq),
                    typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=licensetype"))])
                        .then(function (response) {
                            if ($scope.checkServerResponse(response[0])) {
                                var maxApplicationId = 0;
                                var currentApplication = null;
                                angular.forEach(response[0].ApplicationResponseList, function (applicationItem) {
                                    if ((applicationItem.ApplicationTypeId == 1 || applicationItem.ApplicationTypeId == 2) &&
                                        (!applicationItem.IsArchive) && (applicationItem.ApplicationStatusId != 3)) {
                                        if (maxApplicationId <= applicationItem.ApplicationId) {
                                            currentApplication = applicationItem;
                                        }
                                    }
                                });
                                $scope.currentApplication = currentApplication;
                                $rootScope.headerApplicationDetail = currentApplication;
                                if (!!$rootScope.headerApplicationDetail) {
                                    if (!!response[1] && !!response[1].ReferenceEntities && response[1].ReferenceEntities.length > 0) {
                                        var licenseTypeNameObject = response[1].ReferenceEntities.whereEquals($rootScope.headerApplicationDetail.LicenseTypeId, 'LicenseTypeId').firstOrDefault();
                                        if (!!licenseTypeNameObject) {
                                            $rootScope.headerApplicationDetail.LicenseTypeName = licenseTypeNameObject.LicenseTypeName;
                                        } else {
                                            $rootScope.headerApplicationDetail.LicenseTypeName = '';
                                        }
                                    }
                                }
                            }
                        }, function (data) {
                            HideLoader();
                            $scope.showStatusMessage(data, "error");
                        });
                };

                $scope.$on("individualInfoSelectionChanged", function (evt, individualId) {
                    getWarning();
                    individualLicenseDetailBYIndividualId(individualId);
                    $scope.applicationByIndividualId(individualId);
                    $scope.currentRenewalItem = {
                        IndividualId: individualId,
                        ApplicationId: 0,
                        ApplicationTypeId: 0,
                        isIndividualInfo: true
                    };
                });

                $scope.$on("reloadWarningFlag", function (evt, individualId) {
                    getWarning();
                });
                //END COMPLAINT

                // Begin Finance
                $scope.$on("loadInvoicePaymentsParent", function (event, args) {
                    $scope.$broadcast("loadInvoicePayments", {});
                });
                //End Finance
            }
        };
    }
})();