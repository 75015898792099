(function () {
  'use strict'

  angular.module('app.InitialApplication')
    .factory('initialApplicationStaffReviewService', initialApplicationStaffReviewService)

  initialApplicationStaffReviewService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

  function initialApplicationStaffReviewService($http, $q, WebApiUrl, AppConfig) {

    var staffReviewService = {
      getApplicationReview: _getApplicationReview,
      saveApplicationReview: _saveApplicationReview,
      individualPermitByIndividualId: _individualPermitByIndividualId,
      savePermitInfo: _savePermitInfo,
      saveIndividualLicense: _saveIndividualLicense
    };

    function _getApplicationReview(key, individualId, applicationId) {
      var deferred = $q.defer();
      $http.get(WebApiUrl + 'Application/ApplicationReviewGet/' + key + '?ApplicationId=' + applicationId + '&IndividualId=' + individualId + '&ProviderId=&Source=' + AppConfig.Source)
        .then(function (data) {
          data = data.data;
          deferred.resolve(data);
        }, function (error) {
          error = error.data;
          deferred.reject(error);
        });
      return deferred.promise;
    };

    function _saveApplicationReview(key, reqData) {
      var deferred = $q.defer();
      $http.post(WebApiUrl + 'Application/SaveApplicationReview/' + key,
        reqData)
        .then(function (data) {
          data = data.data;
          deferred.resolve(data);
        }, function (error) {
          error = error.data;
          deferred.reject(error);
        });
      return deferred.promise;
    };

    function _individualPermitByIndividualId(Key, IndividualId, permitTypeId, applicationId) {
      var deferred = $q.defer();
      $http.get(WebApiUrl + 'Individual/GetAllPermitByIndividualIdPermitTypeId/' + Key + '?IndividualId=' + IndividualId + '&PermitTypeId=' + permitTypeId + '&ApplicationId=' + applicationId)
        .then(function (data) {
          data = data.data;
          deferred.resolve(data);
        }, function (error) {
          error = error.data;
          deferred.reject(error);
        });
      return deferred.promise;
    };

    var _savePermitInfo = function (objPermits, key) {
      var deferred = $q.defer();
      $http.post(WebApiUrl + 'Individual/SavePermit/' + key + "?Source=Permit", objPermits)
        .then(function (data) {
          data = data.data;
          deferred.resolve(data);
        }, function (error) {
          error = error.data;
          deferred.reject(error);
        });
      return deferred.promise;
    };

    function _saveIndividualLicense(key, reqData) {
      var deferred = $q.defer();
      $http.post(WebApiUrl + 'Individual/IndividualLicenseSave/' + key + '?Source=cadcabpelsg',
        reqData)
        .then(function (data) {
          data = data.data;
          deferred.resolve(data);
        }, function (error) {
          error = error.data;
          deferred.reject(error);
        });
      return deferred.promise;
    };

    return staffReviewService;
  }
})();