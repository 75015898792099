﻿(function () {
    'use strict';
    
    angular.module('task.management')
    .controller('TaskExecutionLogDetailsController', ['$scope', '$q', 'TaskExecutionLogService', TaskExecutionLogDetailsController]);

    function TaskExecutionLogDetailsController($scope, $q, TaskExecutionLogService) {
        var _key = sessionStorage.Key;

        var resetSearchCriteria = function () {
            $scope.searchCriteria = {
                TaskScheduleExecutionId: $scope.taskScheduleExecutionId,
                TaskScheduleAutoId: 0,
                IndividualId: 0,
                ApplicationStatusId: 0,
                Order: 'asc',
                OrderByField: 'LastName',
                FromRow: 0,
                RecordToFetch: 20,
                SSN: '',
                FirstName: '',
                LastName: '',
                LicenseNumber: ''
            };

            $scope.paginationParams = {
                showPager: true,
                totalRecords: 0,
                currentPage: 1,
                pageSize: 20,
                pageChanged: $scope.pageChanged
            };

            $scope.sortHeader = null;
        };

        var searchTaskExecutionLogDetails = function () {
            try {
                $scope.loadingExecutionDetails(true, "Searching...");

                TaskExecutionLogService.getTaskExecutionLogList(_key, $scope.currentSearchCriteria)
                .then(function (data) {
                    if(data.Status) {
                        $scope.taskExecutionDetailsList = data.ExecutionLogList;
                        $scope.paginationParams.totalRecords = data.TotalCount;
                    } else {

                    }
                    $scope.loadingExecutionDetails(false);
                }, function (error) {
                    $scope.loadingExecutionDetails(false);    
                });
            } catch (ex) {
                $scope.loadingExecutionDetails(false);
            }
        };

        $scope.init = function () {
            resetSearchCriteria();

            $scope.currentSearchCriteria = angular.copy($scope.searchCriteria);
            $scope.showSearch = false;

            $scope.tableEvents = {
                showHideSearch: $scope.showHideSearch
            };

            $scope.tableHeaders = {
                LastName: {
                    label: 'Last Name'
                },
                FirstName: {
                    label: 'First Name'
                },
                LicenseNumber: {
                    label: 'Lic #',
                    sizeRatio: 0.75
                },
                RenewalPeriod: {
                    label: 'Renewal Period',
                    templateUrl: 'taskExecutionLogDetailRenewalPeriod.html',
                    sizeRatio: 2
                },
                Email: {
                    label: 'Email',
                    sizeRatio: 2
                },
                StreetLine1: {
                    label: 'Street Line 1',
                    sizeRatio: 2
                },
                StreetLine2: {
                    label: 'Street Line 2'
                },
                City: {
                    label: 'City',
                    sizeRation: 1.25
                },
                StateName: {
                    label: 'State',
                    sizeRatio: 0.75
                },
                Zip: {
                    label: 'Zip',
                    sizeRatio: 0.5
                },
                LinkIdType: {
                    label: 'Link Type'
                },
                ApplicationNumber: {
                    label: 'Application Number'
                },
                EntityExamId: {
                    label: 'Entity Exam ID'
                },
                ExamTypeCode: {
                    label: 'Exam ID'
                },
                ExamStatus: {
                    label: 'Exam Status'
                },
                ExamDate: {
                    label: 'Exam Date',
                    templateUrl: 'taskExecutionLogDetailDate.html',
                },
                Uploaded: {
                    label: 'Uploaded'
                }
            };

            $q.all([TaskExecutionLogService.getAllApplicationStatus(_key), TaskExecutionLogService.getTaskExecutionLogList(_key, $scope.currentSearchCriteria)])
            .then(function (data) {
                $scope.applicationStatusList = data[0].ApplicationStatusList;

                $scope.taskExecutionDetailsList = data[1].ExecutionLogList;
                $scope.paginationParams.totalRecords = data[1].TotalCount;
                
                $scope.loadingExecutionDetails(false);
            }, function (error) {
                $scope.loadingExecutionDetails(false);
            });
        };

        $scope.showHideSearch = function () {
            $scope.showSearch = !$scope.showSearch;
        }

        $scope.searchClicked = function () {
            $scope.searchCriteria.FromRow = 0;
            $scope.currentSearchCriteria = angular.copy($scope.searchCriteria);

            searchTaskExecutionLogDetails();
        };

        $scope.clearClicked = function () {
            resetSearchCriteria();

            $scope.searchClicked();
        };

        $scope.pageChanged = function (newPage) {
            $scope.currentSearchCriteria.FromRow = (newPage - 1) * $scope.searchCriteria.RecordToFetch;

            searchTaskExecutionLogDetails();
        };

        $scope.sortBy = function(sortParams) {
            $scope.currentSearchCriteria.Order = sortParams.$sortDirection == 'asc';
            $scope.currentSearchCriteria.OrderByField = sortParams.$headerProperty;

            searchTaskExecutionLogDetails();
        };
    }
})();