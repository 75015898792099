﻿(function () {
    'use strict';
    angular.module('app.backofficeAdministor')
        .controller("RolesController", RolesController);
    RolesController.$inject = ["$scope","$window", "$controller", "RolesService", "$state", "utilityService", "Messages"];
    function RolesController($scope,$window, $controller, RolesService, $state, utilityService, Messages) {
        $controller('MainController', {$scope: $scope});
        var key = sessionStorage.Key;

        $scope.init = function () {
            HideLoader();
            if ($scope.isUserSessionActive()) {
                $scope.getRoleList();
            }
            else {
                $state.go('app.BackofficeLoginstaff');
            }
        };

        $scope.getRoleList = function () {
            ShowLoader("Please wait...");
            try {
                RolesService.UserRoleGetAll(key)
                    .then(function (response) {
                        AuditVisitInfoSave();
                        if ($scope.checkServerResponse(response) || true) {
                            $scope.Model = response.Role;
                            window.getroles = response.Role;
                            var roles = {};

                            response.Role.forEach(function (element) {
                                var userType = element.UserTypeId;

                                if ('undefined' === typeof roles[userType]) {
                                    roles[userType] = [];
                                }

                                roles[userType].push(element);
                            });

                            $scope.Model = roles;
                            HideLoader();
                        } else {
                        }
                    })
                    .catch(function (data) {
                        HideLoader();
                        showStatusMessage(data, "error");
                    });
            }
            catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
                HideLoader();
            }
        };
        $scope.deleteRole = function (RoleId) {
            var role = getroles.filter(function (role) {
                return role['RoleId'] == RoleId
            });
            var data = {
                "RoleId": role[0].RoleId,
                "Name": role[0].Name,
                "UserTypeId": role[0].UserTypeId,
                "Description":"description",
                "IsDeleted":true,
                "IsActive":false
            };
            ShowLoader();
            RolesService.UserRoleDelete(key, data)
                .then(function (response) {
                    AuditVisitInfoSave();
                    HideLoader();
                    if (response.Status) {
                        showStatusMessage(Messages.General.Success.Delete, 'success');
                        $window.location.reload();
                    } else {
                        showStatusMessage(Messages.General.Error.SomeError, "error");
                    }
                })
                .catch(function () {
                    HideLoader();
                    showStatusMessage(Messages.General.Error.SomeError, "error");
                });
        };
        function AuditVisitInfoSave() {

            var data = {
                "PageName": "Roles Management",
                "SessionId": sessionStorage.Key,
                "IndividualId": sessionStorage.IndividualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        }
    }
})();