(function () {
    'use strict'

    angular.module('app.pciCompliantPayment')
    .directive('pciCompliantPaymentValidation', [PCICompliantPaymentValidation]);

    function PCICompliantPaymentValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.setForm = function (form) {
                    $scope.form = form;
                };

                $scope.validateTab = function () {
                    return true;
                };

                $scope.validateForm = function () {
                    var valid = true;

                    if (!!$scope.form.errorMessages && $scope.form.errorMessages.length > 0) {
                        $scope.showStatusMsg('-', $scope.form.errorMessages);
                        valid = false;
                    }

                    return valid;
                };
            }
        };
    }
})();