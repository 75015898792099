(function (){
    'use strict'

    angular.module('app.InitialApplication')
    .directive('collaboratingPracticeValidation', [CollaboratingPracticeValidation]);

    function CollaboratingPracticeValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function () {
                    var errMsgs = [];
                    var valid = true;

                    if (!!$scope.showConfirmation) {
                        if ($scope.current.confirmationResponse.ConfirmationResponse == null) {
                            valid = false;
                            errMsgs.push('You must confirm yes or no to the question.');
                        }

                        if (!!$scope.current.confirmationResponse.ConfirmationResponse && $scope.collaboratingPractices.length == 0) {
                            valid = false;
                            errMsgs.push('You must enter at least 1 collaborating practice.');
                        }
                    }

                    $scope.showStatusMsg('-', errMsgs);
                    return valid;
                };

                $scope.validateCollaboratingPractice = function () {
                    var errMsgs = [];
                    var valid = true;
                    var practice = $scope.current.practice;

                    if (!practice.FirstName) {
                        valid = false;
                        errMsgs.push('You must provide a first name.');
                    }
                    if (!practice.LastName) {
                        valid = false;
                        errMsgs.push('You must provide a last name.');
                    }

                    if (!practice.Speciality) {
                        valid = false;
                        errMsgs.push('You must provide a speciality.');
                    }

                    if (!practice.StreetLine1) {
                        valid = false;
                        errMsgs.push('You must provide a street.');
                    }
                    if (!practice.City) {
                        valid = false;
                        errMsgs.push('You must provide a city.');
                    }
                    if (!practice.StateCode) {
                        valid = false;
                        errMsgs.push('You must provide a state.');
                    }
                    if (!practice.Zip) {
                        valid = false;
                        errMsgs.push('You must provide a zip code.');
                    }

                    if (!practice.Phone) {
                        valid = false;
                        errMsgs.push('You must provide a phone number.');
                    }
                    if (!practice.Email) {
                        valid = false;
                        errMsgs.push('You must provide an email.');
                    }

                    $scope.showStatusMsg('-', errMsgs);

                    return valid;
                };
            }
        };
    }
})();