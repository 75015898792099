angular.module('app.backofficeAdministor').factory('ConfigurationService', ["$http", "WebApiUrl", function ($http, WebApiUrl) {
    return {
        //Application Configuration
        GetAllConfigurationList: function (Key) {

            return $http.get(WebApiUrl + 'Configuration/ConfigurationGetAll/' + Key);
        },
        UpdateConfiguration: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        GetConfiguration: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        //Codes
        InsertCode: function (key) {
            return $http.post(WebApiUrl + +key);
        },
        UpdateCode: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        GetCodeList: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        GetCode: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        DeleteCode: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        //DocumentList
        InsertDocument: function (key) {
            return $http.post(WebApiUrl + +key);
        },
        UpdateDocument: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        GetDocumentList: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        GetDocument: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        DeleteDocument: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        //ApplicationStatusColor
        UpdateStatusColor: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        GetStatusColorList: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        GetStatusColor: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        //DeficiencyReason
        InsertDeficiencyReason: function (key) {
            return $http.post(WebApiUrl + +key);
        },
        UpdateDeficiencyReason: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        GetDeficiencyReasonList: function (key,data) {
            return $http.post(WebApiUrl + 'Configuration/GetDeficiencyReason/' + key, data);
        },
        Get_All_LAPP_DeficiencyTemplate:function(key){
            return $http.get(WebApiUrl + 'Configuration/Get_All_LAPP_DeficiencyTemplate/' + key);
        },
        GetAllMasterTransaction: function (key) {
            return $http.get(WebApiUrl + 'Configuration/GetAllMasterTransaction/' + key);
        },
        SaveDeficiencyReason: function (key, data) {
            return $http.post(WebApiUrl + 'Configuration/SaveDeficiencyReason/' + key,data);
        },
        PageGetAllPageNamesByMasterTransactionId : function (key,mt) {
            return $http.get(WebApiUrl + 'Page/PageGetAllPageNamesByMasterTransactionId/' + key + '?MasterTransactionId='+mt);
        },
        PageGetAllTabsByPageModuleId:function (key, moduleId) {
            return $http.get(WebApiUrl + 'Page/PageGetAllTabsByPageModuleId/' + key + '?PageModuleId=' + moduleId);
        },
        GetAllTabsSubModule: function (key, PageModuleTabSubModuleId) {
            return $http.get(WebApiUrl + 'Page/PageGetAllSectionsByTabId/' + key + '?PageModuleTabSubModuleId=' + PageModuleTabSubModuleId);
        },
        GetDeficiencyReason: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        DeleteDeficiencyReason: function (key) {
            return $http.get(WebApiUrl + +key);
        },
        ConfigurationGetbySettings: function(key, setting)
        {
            return $http.get(WebApiUrl + 'Configuration/ConfigurationGetbySettings/' + key + '?setting=' + setting);
        }
    };
}]);