﻿(function () {
    'use strict'
    angular.module('app.core')
        .factory('AppstatusballFactory', ['$http', 'WebApiUrl', '$q', 'AppConfig', function ($http, WebApiUrl, $q, AppConfig) {
            var baseFactory = {};
            baseFactory.ExternalApplicationStatusGetAll = function (key, IndvId, applicationId) {
                return $http.get(WebApiUrl + 'TypeValues/ExternalApplicationStatusGetAll/' + key + '?IndividualId=' + IndvId +
                    (!!applicationId ? ("&ApplicationId=" + applicationId) : "")
                );
            };

            baseFactory.ApplicationStatusChangeLogByAppId = function (key, applicationId) {
                return $http.get(WebApiUrl + 'Common/ApplicationStatusChangeLogByAppId/' + key + '?ApplicationId=' + applicationId);
            };

            return baseFactory;
        }]);
})();
