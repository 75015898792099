﻿angular.module('natApp').factory('BackofficeProviderService', ['$http', '$q', 'WebApiUrl', 'AppConfig', function ($http, $q, WebApiUrl, AppConfig) {

    var FirmSearchContent = null;
    var FirmSearchContentType = null;

    var _setFirmSearchContent = function (value, type) {
        FirmSearchContent = value;
        FirmSearchContentType = type;
    };
    var _getFirmSearchContent = function () {
        return { FirmSearchContent: FirmSearchContent, FirmSearchContentType: FirmSearchContentType };
    };

    return {

        setFirmSearchContent: _setFirmSearchContent,
        getFirmSearchContent: _getFirmSearchContent,

        ProviderBusinessNameSave: function (key, ProviderOther) {

            return $http.post(WebApiUrl + 'Provider/SaveProviderOther/' + key + '?Source=WYPEPLS', ProviderOther);
        },


        ProviderDocumentByProviderId: function (Key, providerId) {
            return $http.get(WebApiUrl + 'Provider/ProviderGetProviderDocumentByProviderIdAndDocumentId/' + Key + '?ProviderId=' + providerId + '&DocumentId=0');
        },

        ProviderOtherProviderId: function (Key, providerId) {
            return $http.get(WebApiUrl + 'Provider/GetProviderOther/' + Key + '?ProviderId=' + providerId + '&ApplicationId=0');
        },
        ProviderEINProviderId: function (Key, providerId) {
            return $http.get(WebApiUrl + 'Provider/GetProviderLicense/' + Key + '?ProviderId=' + providerId + '&Source=WYPEPLS');
        },
        ApplicationStatusChange: function (Key, ApplicationId, ApplicationStatusId) {
            return $http.post(WebApiUrl + 'Common/FirmApplicationStatusChange/' + Key + '?ApplicationId=' + ApplicationId + "&ApplicationStatusId=" + ApplicationStatusId);
        },

        GetProviderById: function (Key, providerId) {
            return $http.get(WebApiUrl + 'Provider/GetProviderById/' + Key + '?providerid=' + providerId);
        },
        SaveProviderLicense: function (Key, data) {
            return $http.post(WebApiUrl + 'Provider/SaveProviderLicense/' + Key + '?&Source=WYPEPLS', data);
        },
        ProviderSave: function (Key, appid, uid, data) {
            return $http.post(WebApiUrl + 'Provider/ProviderSave/' + Key + '?ApplicationId=' + appid + '&UserId=' + uid, data);
        },
        GetProviderStatusType: function (key) {
            return $http.get(WebApiUrl + 'Provider/GetProviderStatusType/' + key);
        },
        GetApplicationBYProviderId: function (key, ProviderId) {
            return $http.get(WebApiUrl + 'Provider/ApplicationBYProviderId/' + key + '?ProviderId=' + ProviderId);
        },
        GetProviderAddress: function (key, ProviderId) {
            return $http.get(WebApiUrl + 'Provider/ProviderAddressByProviderId/' + key + '?ProviderId=' + ProviderId);
        },
        GetProviderContact: function (key, ProviderId) {
            return $http.get(WebApiUrl + 'Provider/ProviderContactByProviderId/' + key + '?ProviderId=' + ProviderId);
        },
        getFirmSearchList: function (key, searchString, providerTypeId, pageNumber, pageSize, searchType) {
            var deferred = $q.defer();
            searchString = encodeURIComponent(searchString);
            $http.get(WebApiUrl + "Individual/GetEmployerNameAndAddressSearchWithPage/" + key + "?SearchString=" + searchString + "&ProviderTypeId=" + providerTypeId + "&PageNumber=" + pageNumber + "&NoOfRecords=" + pageSize + "&SearchType=" + searchType)
                .then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        IndividualLogGetByProviderId: function (Key, ProviderId, LogType) {
            return $http.get(WebApiUrl + 'Individual/IndividualLogGetByIndividualId/' + Key + '?IndividualId=null&EntityId=' + ProviderId + '&EntityType=' + LogType + '&Source=' + AppConfig.Source + 'FIRM');
        }
    };
}]);
