// (function () {
//     'use strict'
//     angular.module('app.provider')
//         .directive('ProviderCEEducation', ProviderCEEducationDirective);
//     ProviderCEEducationDirective.$inject = [];

//     function ProviderCEEducationDirective() {
//         return {
//             restrict: 'E',
//             scope: {
//                 educationInfo: "=educationInfo",
//                 showSubmitPanel: "=showSubmitPanel",
//                 showHeader: "=showHeader",
//                 headingPosition: "=headingPosition",
//                 heading: "=?heading",
//                 natValidation: "=natValidation",
//                 ProviderId: "=providerId",
//                 applicationId: "=applicationId",
//                 ProviderCourseId: '=providerCourseId'
//             },
//             templateUrl: "app/components/provider/application/provider-ce-education/provider-ce-education.html",
//             // controller: "ProviderCEEducationController"
//         }
//     }
// })();

(function () {
    'use strict'
    angular.module('app.provider')
        .directive('providerCeEducation', providerCEEducationDirective);
    providerCEEducationDirective.$inject = [];

    function providerCEEducationDirective() {
        return {
            restrict: 'E',
            scope: {
                ProviderId: "=?providerId",
                applicationId: "=?applicationId",
                ProviderCourseId: '=?providerCourseId'
            },
            templateUrl: "app/components/provider/application/provider-ce-education/provider-ce-education.html",
            controller: "ProviderCEEducationController"
        }
    }
})();