(function() {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeIndividualAdressService', backofficeIndividualAdressService)

    backofficeIndividualAdressService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeIndividualAdressService($http, $q, WebApiUrl, AppConfig) {

        var _individualAddressBYIndividualId = function(Key, IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualAddressBYIndividualId/' + Key + '?IndividualId=' + IndividualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _individualContactBYIndividualId = function(Key, IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualContactBYIndividualId/' + Key + '?IndividualId=' + IndividualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _addressSave = function(address, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualAddressSave/' + key + '?Source=' + AppConfig.Source, address)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _addressContactSave = function(contact, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualContactSave/' + key + '?Source=' + AppConfig.Source, contact)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getAddressTypes = function (key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'TypeValues/AddressTypeGetAll/' + key + '/')
            .then(function(data){ data = data.data;
                deferred.resolve(data);
            }, function(error) { error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getContactTypes = function (key) {
            var deferred = $q.defer();
            
            try {
                var urlParams = {
                    tableKey: 'contacttype'
                };
                $http.get(WebApiUrl + 'TypeValues/RefTablesGetAll/' + key + '/', {params: urlParams})
                .then(function(response) {
                    deferred.resolve(response.data);
                }, function(response) {
                    deferred.reject(response);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        return {
            individualAddressBYIndividualId: _individualAddressBYIndividualId,
            individualContactBYIndividualId: _individualContactBYIndividualId,
            addressSave: _addressSave,
            addressContactSave: _addressContactSave,
            getAddressTypes: _getAddressTypes,
            getContactTypes: _getContactTypes
        }
    }
})();