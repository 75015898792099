(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationPLSSController", InitialApplicationPLSSController);

    InitialApplicationPLSSController.$inject = ["$scope", "$rootScope",
        "initialApplicationPlssService", "utilityService", "initialApplicationNcessService", "countryStateService", "$q", "Messages"
    ];

    function InitialApplicationPLSSController($scope, $rootScope, initialApplicationPlssService, utilityService, initialApplicationNcessService, countryStateService, $q, Messages) {


        $scope.datePicker = {
            dateOpened: false,
            dateTopened: false,
            format: 'M!/yyyy',
            formatTo: "M!/yyyy",
            endDateOptions: {
                datepickerMode: 'month',
                minMode: 'month',
                minDate: null
            },
            startDateOptions: {
                datepickerMode: 'month',
                minMode: 'month',
            }
        };
        $scope.experience = {};
        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        var validateForm = function (currentForm) {
            if (!currentForm.errorMessages)
                currentForm.errorMessages = [];
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        function AuditVisitInfoSave() {
            // $scope.ncess
            var data = {
                "PageName": $scope.tab.text,
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };

        var getCountry = function () {
            return countryStateService.countryGetAll(sessionStorage.Key);
        }

        var init = function () {
            ShowLoader();
            if (!$scope.dashboard)
                AuditVisitInfoSave();
            $scope.getExperience();

            var dataPromise = [];
            dataPromise.push(getCountry());
            dataPromise.push(countryStateService.getStateByCountryID(sessionStorage.Key, $rootScope.DefaultCountryId));
            $q.all(dataPromise).then(function (res) {
                if (res[1].Status) {
                    $scope.PlaceOfBirthStates = res[1].State;
                }
                if (res[0].Status) {
                    $scope.CountryList = res[0].Country;
                }
                HideLoader();
            }, function (error) {
                HideLoader();
            });
            if (!$scope.dashboard) {
                if ($scope.applicationInfo.LicenseApplicationForId != 6) {
                    initialApplicationNcessService.getIndividualLicenseRecordBook($scope.individualId, $scope.applicationId, sessionStorage.Key).then(function (response) {
                        if (response.Status) {
                            if (response.IndividualLicenseRecordBookList)
                                $scope.ncess = response.IndividualLicenseRecordBookList[0];
                        }
                        HideLoader();
                    }, function (error) {
                        HideLoader();
                    });

                }
            }
        }

        $scope.getStateByCountryID = function (CountryID) {
            ShowLoader();
            try {
                countryStateService.getStateByCountryID(sessionStorage.Key, CountryID)
                    .then(function (response) {
                        if (response.Status) {
                            $scope.PlaceOfBirthStates = response.State;
                        }
                        else {
                            $scope.PlaceOfBirthStates = null;
                        }
                        HideLoader();
                    }, function (data) {
                        HideLoader();
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };



        $scope.getTotalExperience = function () {
            if ($scope.experience.TerminationDate && $scope.experience.EmploymentDate) {
                var months = utilityService.getDatesDiff($scope.experience.TerminationDate, $scope.experience.EmploymentDate);
                if (months > 0) {
                    $scope.experience.ExpYears = (months / 12).toFixed(1);

                }
            }
        }

        $scope.saveExperience = function () {
            $scope.experience.IndividualId = $scope.individualId;
            $scope.experience.ApplicationId = $scope.applicationId;
            $scope.experience.IsActive = true;
            $scope.experience.IsDeleted = false;

            ShowLoader("Please Wait...");

            initialApplicationPlssService.saveIndividualEssay([$scope.experience], sessionStorage.Key).then(function (res) {
                $scope.hideAddUpdateForm();
                $scope.getExperience(true);
                HideLoader();
            }, function (error) {
                HideLoader();
            });
        }

        $scope.getExperience = function (next) {
            ShowLoader("Please Wait...");
            initialApplicationPlssService.getIndividualEssay($scope.individualId, $scope.applicationId, sessionStorage.Key).then(function (res) {
                HideLoader();

                if (res.Status && res.IndividualEssayList)
                    $scope.experience = res.IndividualEssayList[res.IndividualEssayList.length - 1];
                else
                    $scope.experience = {};
                if (next)
                    $scope.openNextTab({ currentTab: $scope.tab });
            }, function (error) {
                HideLoader();
            });
        }


        $scope.deleteExprieance = function (individualExperience, indexToDelete, formNumber) {

            try {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    individualExperience.IsDeleted = true;
                    ShowLoader("Please Wait...");
                    initialApplicationPlssService.saveExperience([individualExperience], sessionStorage.Key).then(function (response) {
                        if (response.Status && response.StatusCode == "00") {
                            $scope.experienceList.splice(indexToDelete, 1)
                        }
                        HideLoader();
                    }, function (error) {
                        HideLoader();
                    });
                }
            } catch (ex) {
                // $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        };

        $scope.editExprieance = function (individualExperience) {
            $scope.showAddUpdatexperience = true;
            $scope.experience = angular.copy(individualExperience);
            $scope.experience.jobDescription = individualExperience.JobDescription;
            $scope.experience.title = individualExperience.Title;
            if (individualExperience.CountryId)
                $scope.getStateByCountryID(individualExperience.CountryId);
        }

        // $scope.saveAndNext = function () {

        // }

        $scope.showAddUpdateForm = function () {
            $scope.showAddUpdatexperience = true;
        }

        $scope.hideAddUpdateForm = function () {
            $scope.showAddUpdatexperience = false;
        }
        init();
    }
})();
