(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('backofficeIndividualAssociatedwithFirmService', backofficeIndividualAssociatedwithFirmService)

    backofficeIndividualAssociatedwithFirmService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeIndividualAssociatedwithFirmService($http, $q, WebApiUrl, AppConfig) {

        var _getEmployerList = function (key, searchString, providerTypeId, searchType) {
            var deferred = $q.defer();
            searchString = encodeURIComponent(searchString);
            $http.get(WebApiUrl + "Individual/GetEmployerNameAndAddress/" + key + "?SearchString=" + searchString + "&ProviderTypeId=" + providerTypeId + "&SearchType=" + searchType)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getProviderId = function (key, IndividualId) {

            //if (ProviderIndividual == null) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + "Provider/GetProviderIndividual/" + key + "?IndividualId=" + IndividualId + "&ProviderId=0&Source=OL")
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
            //}
            //else            {
            //    return returnDefferedResult($http.post(WebApiUrl + "Provider/SaveProviderIndividual/" + key + "?Source=OL", ProviderIndividual));
            //}

        };
        var _getProvider = function (key, ProviderId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + "Provider/GetProviderById/" + key + "?providerid=" + ProviderId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveProvider = function (key, ProviderIndividual) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/SaveProviderIndividual/' + key + '?Source=OL', ProviderIndividual)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        var _getFirmSearchList = function (key, searchString, providerTypeId, pageNumber, pageSize, searchType) {
            var deferred = $q.defer();
            searchString = encodeURIComponent(searchString);
            $http.get(WebApiUrl + "Individual/GetEmployerNameAndAddressSearchWithPage/" + key + "?SearchString=" + searchString + "&ProviderTypeId=" + providerTypeId + "&PageNumber=" + pageNumber + "&NoOfRecords=" + pageSize + "&SearchType=" + searchType)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };


        return {
            getEmployerList: _getEmployerList,
            providerIdBy_IndividualId: _getProviderId,
            providerDetails: _getProvider,
            providerSave: _saveProvider,
            getFirmSearchList: _getFirmSearchList
        };
    }
})();