(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationAcknowledgementController", InitialApplicationAcknowledgementController);

    InitialApplicationAcknowledgementController.$inject = ["$rootScope", "$scope", '$q', '$uibModal',
        "initialApplicationAcknowledgementService", "initialApplicationstatusService", "backofficeIndividualPersonalService", "utilityService", "WebApiUrl", "AppConfig", "$exceptionHandler", "contentService"
        , 'Messages'];

    function InitialApplicationAcknowledgementController($rootScope, $scope, $q, $uibModal,
        initialApplicationAcknowledgementService, initialApplicationstatusService, backofficeIndividualPersonalService, utilityService, WebApiUrl, AppConfig, $exceptionHandler, contentService, Messages) {

        if (AppConfig.EnableConsoleLogging)
            console.log('Acknowledgement : ', $scope.acknowledgement);

        $scope.datePicker = {
            dateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };

        function AuditVisitInfoSave() {
            var data = {
                "PageName": $scope.acknowledgementConfig.Content.TabHeading,
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };
        $scope.processDateValues = function () {

            if (!!$scope.acknowledgement && !!$scope.acknowledgement.Date && !angular.isDate($scope.acknowledgement.Date))
                $scope.acknowledgement.Date = new Date($scope.acknowledgement.Date);
            else if (!$scope.acknowledgement) {
                $scope.acknowledgement = {
                    Date: new Date()
                }
            } else if (!$scope.acknowledgement.Date) {
                $scope.acknowledgement.Date = new Date();
            }
        };
        $scope.init = function () {
            try {
                if (!!$scope.loadData) {
                    ShowLoader("Please Wait...");
                }
                $scope.viewConfig = {};

                $scope.isConfigLoaded = false;
                $scope.getAcknowledgementConfig()
                    .then(function (data) {
                        $scope.acknowledgementConfig = data;
                        $scope.isConfigLoaded = true;
                        if (!$scope.isRenewal) {
                            AuditVisitInfoSave();
                        }
                        contentService.getContentByContentItemLkIdList([423], sessionStorage.Key)
                            .then(function (responseContent) {
                                $scope.contentData = responseContent.ContentItemLk;

                                if (!!$scope.loadData) {
                                    $q.all([
                                        initialApplicationAcknowledgementService.acknowledgementGet(sessionStorage.Key, $scope.individualId, $scope.applicationId, 102),
                                        backofficeIndividualPersonalService.individualBYIndividualId(sessionStorage.Key, $scope.individualId)
                                    ]).then(function (res) {
                                        //if (res[0].IndividualAffidavitResponseList != null && res[0].IndividualAffidavitResponseList.length > 0) {
                                        //    $scope.acknowledgement = res[0].IndividualAffidavitResponseList[0]//.whereEquals(423, 'ContentItemLkId').firstOrDefault();
                                        //}
                                        if (res[0].Status && res[0].IndividualAffidavitResponseList && res[0].IndividualAffidavitResponseList.length > 0) {
                                            if (!!$scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 2
                                                && !$scope.isBackoffice && [16].indexOf($scope.applicationInfo.ApplicationStatusId) != -1) {
                                                $scope.acknowledgement = res[0].IndividualAffidavitResponseList.whereEquals(528, 'ContentItemLkId').firstOrDefault();
                                            } else {
                                                $scope.acknowledgement = res[0].IndividualAffidavitResponseList[0];
                                            }
                                            $scope.acknowledgement.IndividualId = $scope.individualId;
                                            $scope.acknowledgement.ApplicationId = $scope.applicationId;
                                            if (!$scope.acknowledgement.Date) {
                                                $scope.acknowledgement.Date = new Date();
                                            }
                                            if ($scope.acknowledgement.SignatureName && $scope.acknowledgement.Date) {
                                                $scope.acknowledgement.ContentItemResponse = true;
                                            }
                                        } else {
                                            $scope.acknowledgement.Date = new Date();
                                        }
                                        if (res[1].IndividualResponse != null && res[1].IndividualResponse.length > 0) {
                                            $scope.individualDetail = res[1].IndividualResponse.firstOrDefault();
                                        }
                                        $scope.isConfigLoaded = true;
                                        $scope.processDateValues();
                                        HideLoader();
                                    }, function (err) {
                                        HideLoader();
                                        $scope.showStatusMessage({
                                            messageContent: 'some error occurred',
                                            messageType: 'error'
                                        });
                                    })
                                } else {
                                    $scope.processDateValues();
                                    HideLoader();
                                }
                            }, function (error) {
                                $scope.showStatusMessage({
                                    messageContent: error.Message,
                                    messageType: 'error'
                                });
                                HideLoader();
                            });
                    }, function (error) {
                        HideLoader();
                        $scope.showStatusMessage({
                            messageContent: 'some error occurred',
                            messageType: 'error'
                        });
                    });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
                $exceptionHandler(ex);
            }
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        var validateForm = function (currentForm) {
            if (!currentForm.errorMessages)
                currentForm.errorMessages = [];
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.openConfirmationPopup = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/individual/application/directives/acknowledgement/acknowledgement-confirmation.html',
                size: 'md',
                resolve: {
                    contentIem: $scope.contentData[0]
                },
                controller: ['$scope', '$uibModalInstance', 'contentIem', function ($scope, $uibModalInstance, contentIem) {
                    $scope.contentIem = contentIem;
                    $scope.onCancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.onConfirm = function () {
                        $uibModalInstance.close('success');
                    };
                }]
            });

            modalInstance.result.then(function () {
                $scope.acknowledgementSaveRecord();
            });
        };

        $scope.acknowledgementSaveRecord = function (skipNextTab) {
            ShowLoader("Please wait...");
            if ($scope.acknowledgement != null) {
                $scope.acknowledgement.IndividualId = $scope.individualId;
                $scope.acknowledgement.ApplicationId = $scope.applicationId;
                $scope.acknowledgement.IndividualLicenseId = (!!$scope.individualLicense) ? $scope.individualLicense.IndividualLicenseId : 0;

                $scope.acknowledgement.Desc = $scope.acknowledgement.SignatureName;
                if ($scope.isRenewal) {
                    $scope.acknowledgement.IndividualLicenseId = $scope.individualLicense.IndividualLicenseId;
                    $scope.acknowledgement.ContentItemResponse = true;
                    $scope.acknowledgement.ContentItemLkId = 423;
                }
                $q.all([
                    initialApplicationAcknowledgementService.acknowledgementSave($scope.acknowledgement, $scope.UserId, sessionStorage.Key, $scope.isRenewal)
                ])
                    .then(function (response) {
                        if (response[0].Status) {
                            if ($scope.isTabStructure) {
                                $scope.tab.skipNextTab = skipNextTab;
                                $scope.openNextTab({
                                    currentTab: $scope.tab
                                });
                                if ($scope.tab.skipNextTab == true) {
                                    $scope.$emit('setCurrentTabNow');
                                }
                                if (skipNextTab && !!skipNextTab.fromDeficiency) {
                                    $scope.$emit('deficiencyExecuted', { success: true });
                                }
                                initialApplicationstatusService.setdecleration('success');
                                $scope.tab.tabSaveStatus = "success";
                            } else {
                                initialApplicationstatusService.setdecleration('success');
                                $scope.tab.tabSaveStatus = "success";
                            }
                        } else {
                            initialApplicationstatusService.setdecleration('error');
                            $scope.tab.tabSaveStatus = "error";
                            $scope.showStatusMessage({
                                messageContent: 'Some error occurred !',
                                messageType: 'error'
                            });
                        }
                        if (!$rootScope.ProcessLoaderExternally)
                            HideLoader();
                    }, function (data) {
                        initialApplicationstatusService.setdecleration('error');
                        $scope.tab.tabSaveStatus = "error";
                        HideLoader();
                        $scope.showStatusMessage({
                            messageContent: 'Some error occurred !',
                            messageType: 'error'
                        });
                    });
            }
        };

        $scope.acknowledgementSave = function (currentForm, skipNextTab) {
            try {
                $scope.tab.skipNextTab = skipNextTab;
                ShowLoader();
                $scope.tab.isInvalid = false;
                if (!validateForm(currentForm)) {
                    if ($scope.isTabStructure) {
                        $scope.tab.isInvalid = true;
                        HideLoader();
                        if ($scope.tab.skipNextTab == true) {
                            $scope.$emit('setCurrentTabNow', $scope.tab);
                        }
                        if (skipNextTab && !!skipNextTab.fromDeficiency) {
                            $scope.$emit('deficiencyExecuted', { success: false });
                        }
                        initialApplicationstatusService.setdecleration('invalid');
                        $scope.tab.tabSaveStatus = "invalid";
                    }
                    utilityService.scrollToElement("newApplicationForm");
                    return;
                }
                $scope.$broadcast("SaveDeficiency", {});

                if (!$scope.isBackoffice && !!$scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId != 1
                    && $scope.tab.skipNextTab == true && !!$scope.switchingToTab

                ) {
                    if ($scope.switchingToTab.tabNumber < $scope.tab.tabNumber) {
                        HideLoader();
                        $scope.$emit('setCurrentTabNow', $scope.tab);
                        return;
                    } else if ($scope.tab.isSaved && !$scope.tab.isInvalid && !$scope.tab.isInvalidTab) {
                        $scope.acknowledgementSaveRecord(skipNextTab);
                        return;
                    }
                }

                if ($scope.isRenewal) {
                    $scope.showConfirmationPopup = true;
                }

                if (!$scope.isBackoffice && (!!$scope.applicationInfo && ($scope.applicationInfo.ApplicationTypeId != 1
                    || !!$scope.showConfirmationPopup))) {
                    $scope.openConfirmationPopup();
                } else {
                    $scope.acknowledgementSaveRecord(skipNextTab);
                }
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
                $exceptionHandler(ex);
            }
        };

        $scope.showOath = function () {
            return $scope.applicationInfo.ApplicationSubmitMode.toLowerCase() == 'o';
        };

        var checkPropertyExists = function (list, prop) {
            var res = false;
            if (list) {
                for (var i = 0; i < list.length; i++) {
                    if (list[i].propName == prop)
                        return true;
                }
            }
            return res;
        };

        $scope.getAcknowledgementConfig = function () {
            var relativeFilePath = "/components/individual/application/directives/acknowledgement/acknowledgement.server-config.json";
            return utilityService.getConfigData(relativeFilePath);
        };

        $scope.$on("SaveRenewal", function (event, args) {
            $scope.showConfirmationPopup = args.showConfirmationPopup;
            $scope.acknowledgementSave($scope.currentForm);
        });

        $scope.$on("SaveApplicationTab", function (event, args) {
            $scope.showConfirmationPopup = args.showConfirmationPopup;
            $scope.acknowledgementSave($scope.currentForm);
        });

        $scope.$on('SwitchingTab', function (event, args) {
            if (args.fromTab == $scope.tab.name) {
                $scope.switchingToTab = args.switchingToTab;
                if (!$scope.isBackoffice) {
                    $scope.acknowledgementSave($scope.currentForm, true);
                } else {
                    $scope.$broadcast("SaveDeficiency", {});
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
            }
        });

        $scope.$on('deficiencyResolve', function (event, args) {
            if (args.tabName == 'acknowledgmentAndDeclaration') {
                $scope.acknowledgementSave($scope.currentForm, { fromDeficiency: true });
            }
        });

        $scope.clearTabConfirmation = function () {
            $scope.$emit("clearTabConfirmation", { currentTab: $scope.tab });
        };

        $scope.init();
    }

})();