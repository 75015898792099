(function () {
    'use strict'
    angular.module('app.core')
    .directive('inlumonFormBuilder', inlumonFormBuilderDirective)
    .factory('InlumonFormBuilderService', InlumonFormBuilderService)
    .run(['InlumonFormBuilderService', function (InlumonFormBuilderService) {
        return InlumonFormBuilderService.refreshForms();
    }]);

    inlumonFormBuilderDirective.$inject = ['$parse', '$animate', '$compile'];

    //  Note: for best results, make sure to surround this directive with a <div></div> that uses the class "form-horizontal"
    //  formConfig Structure
    //  {
    //      "name": "<required - form name, used for form validation>",
    //      "columns": "<deprecated - number of columns",
    //      "modelObj": "<required - the object in which all of the fields will be stored, can be empty if form spans multiple objects>",
    //      "waitForAll": "<optional - angular expression, used to execute the expression when all of the dropdownRefs have loaded>",
    //      "rows" [
    //          {
    //              "init": "<optional - angular expression>",
    //              "show": "<optional - angular ng-show expression>",
    //              "condition": "<optional - angular ng-if expression>",
    //              "removeFormGroup": <optional - bool, removes the form-group class from the row",
    //              "columns": [    //Required, the list of "columns" (fields) on a row
    //                  {
    //                      "name": "<required - unique name of field in form, used for form validation>",
    //                      "label": "<required - label of the field OR use labelContent>",
    //                      "labelContent": {   //Uses "<inlumon-content ...></inlumon-content>" for the label
    //                          // Only need to use 1 of the following: contentId, contentCode, pageTabSectionId
    //                          "contentId": <number>,
    //                          "contentCode": "<string>",
    //                          "pageTabSectionId: <number>,
    //                          "onLoaded": "<optional - angular expression>"
    //                      },
    //                      "errorMessage": "<optional - text to display when validation fails>",
    //                      "hideLabel": <bool, optional - override to type config to not generate the label HTML>,
    //                      "isRequired": "<optional - angular boolean expression, default false>",
    //                      "isDisabled": "<optional - angular boolean expression, default false>",
    //                      "bindingField": "<required - name of the property in modelObj that will store the value>",
    //                      "controlWidth": "<deprecated - number of columns the control will spread across>"
    //                      "type": "<required - text | date | dropdown | dropdownRef | radio | checkbox | textarea | button | label | texthtml | empty | dummy | blank>"
    //                      "init": "<optional - angular expression>",
    //                      "show": "<optional - angular ng-show expression>",
    //                      "condition": "<optional - angular ng-if expression>",
    //                      "onChange": "<optional - angular ng-change expression>",
    //                      "sizes": {  // Specify the sizes of the label and the control
    //                          "label": "<required - label size using bootstrap grid>", // e.g. "col-md-offset-1 col-md-2 col-sm-4"
    //                          "control": "<required - control size using bootstrap grid>"
    //                      },
    //                      "addlAttrs": {  // Additional attributes to add to the label or control HTML elements
    //                          "label": "",
    //                          "control": ""
    //                      },
    //
    //                      "isMasked": <optional - bool, denotes if the field has a mask (like phone numbers or SSN), only used with "type" text>
    //                      "mask": "<optional - mask used by ui-mask when isMasked is true>"
    //                      "maskConfig": {     // Only used when "isMasked" is true and "type" is text
    //                          "clearOnBlur": <bool>,
    //                          "allowInvalidValue": <bool>,
    //                          "clearOnBlurPlaceholder": <bool>
    //                      },
    //
    //                      "numberConfig": {   // Only used when "type" is number
    //                          "min": "<optional - default none>",
    //                          "max": "<optional - default none>"
    //                      },
    //
    //                      "datePicker": {     // Only used when "type" is date
    //                          "opened": "",
    //                          "format": "",
    //                          "valueFormat": "",
    //                          "appendToBody": "",
    //                          "options": "{...}" // uib-datepicker options
    //                      },
    //
    //                      "dropdownConfig": {   // Only used when "type" is dropdown or dropdownRef
    //                          // Properties used in dropdown
    //                          "dataList": "<the scope variable to which the select will bind>"
    //                          "staticList":  [    // alternative to dataList - provide a static list with values 
    //                              { text: "<what appears in the dropdown", value: <the desired value>}
    //                          ],
    //                          "defaultOption": "<optional - name of the default (first) option in the dropdown>",
    //                          
    //                          // Properties used in dropdownRef
    //                          "tableKey": "<required - table key that corresponds to a reference table>",
    //                          "orderBy": "<optional - the column by which the elements will be sorted>"
    //                          "listName": "<optional - the array that the data will be put into>",
    //                          "array": "<optional - the array from which the data will be listed>",
    //                          "onLoaded": "<optional - angular expression>"
    //
    //                          // Not used in staticList dropdowns
    //                          "overrideNames": "<optional - if true, removes the automatic 'item.' preprended to the field names",
    //                          "valueFieldName": "<required - the column from the reference table that holds the desired value>",
    //                          "textFieldName": "<required - the column from the reference table that holds what appears in the dropdown>",
    //
    //                          //Modes of the select (mutually exclusive, not available with static lists)
    //                          "isMultiSelect": "<optional - determines whether it's a multiple select control or not>",
    //                          "isTypeAhead": "<optional - determined whether it's a typeahead control or not>",
    //                          "typeAheadConfig": {    // Only used when "isTypeAhead" is set to true
    //                              "appendToBody": <optional - bool>,
    //                              "onSelect": "<optional - angular expression>"
    //                          }
    //                      },
    //                      "dataBindings": {}// Deprecated, is assigned to dropdownConfig if detected
    //
    //                      "radioConfig": {    // Only used when "type" is radio
    //                          "breakOnEach": <optional - create a line break "<br />" after each radioOption>
    //                      },
    //                      "radioOptions": [   // Only used when "type" is radio
    //                          {
    //                              "label": "<the label for the radio button>",
    //                              "value": <any type, the value of the radio button>
    //                          },...
    //                      ],
    //
    //                      "checkboxConfig": { // Only used when "type" is checkbox
    //                          "trueValue": <any type, optional - default true>,
    //                          "falseValue": <any type, optional - default false>
    //                      },
    //
    //                      "buttonConfig": {
    //                          "label": "<button text>",
    //                          "type": "<submit | button>",
    //                          "class": "<button classes>",
    //                          "onClick": "<button click event>"
    //                      }
    //                  }
    //              ]
    //          },...
    //      ]
    //  }

    // Most column types will use this configuration
    var defaultColumnTypeConfig = {
        hasLabel: true,
        usesLabelTag: true,
        hasControl: true
    };

    var noLabelConfig = {
        hasLabel: false,
        usesLabelTag: false,
        hasControl: true
    }

    var emptyConfig = {
        hasLabel: true,
        usesLabelTag: false,
        hasControl: true
    };

    //Specify configurations for all types.
    var columnTypeConfigs = {
        text: defaultColumnTypeConfig,
        number: defaultColumnTypeConfig,
        date: defaultColumnTypeConfig,
        datetime: defaultColumnTypeConfig,
        dropdown: defaultColumnTypeConfig,
        dropdownRef: defaultColumnTypeConfig,
        radio: defaultColumnTypeConfig,
        checkbox: noLabelConfig,
        textarea: defaultColumnTypeConfig,
        label: {
            hasLabel: true,
            usesLabelTag: true,
            hasControl: false
        },
        empty: emptyConfig,
        dummy: emptyConfig,
        blank: emptyConfig,
        button: noLabelConfig,
        hidden: noLabelConfig,
        texthtml: defaultColumnTypeConfig//noLabelConfig
    };

    var isUndefinedOrNull = function (value) {
        return typeof(value) == 'undefined' || value == null;
    };

    function inlumonFormBuilderDirective($parse, $animate, $compile) {
        return {
            replace: true,
            restrict: 'EA',
            link: function ($scope, element, attrs, form) {
                var formConfig = $scope.$eval(attrs.formConfig);
                var isReadOnly = $scope.$eval(attrs.isReadOnly);
                var startingTabIndex = (!!attrs.startingTabIndex ? $scope.$eval(attrs.startingTabIndex) : null);

                if (!formConfig) {
                    throw 'Form Builder - form config is not defined.  Try adding the following to this directive: ng-if="!!{0}"'.format(attrs.formConfig);
                }
                if (!formConfig.rows || !Array.isArray(formConfig.rows)) {
                    throw 'Form Builder - no array of rows defined in form config.';
                }

                // Since the bindingField will be appended to modelObj, make sure to add the '.' at the end if modelObj is defined.
                var modelObj = formConfig.modelObj;
                if (!!modelObj) {
                    modelObj += '.';
                }
                
                //First, setup the defaults for each column in each row based on the column's type
                formConfig.rows.forEach(function (row) {
                    if(row.columns){
                        row.columns.forEach(function (column) {
                            if (!column.type) {
                                column.type = 'text';
                            }
                            if (!column.isRequired) {
                                column.isRequired = 'false';
                            }
                            if (!column.isDisabled) {
                                column.isDisabled = false;
                            }
                            if (!column.show) {
                                column.show = 'true';
                            }
                            if (!column.addlAttrs) {
                                column.addlAttrs = {};
                            }
                            if (!column.addlAttrs.label) {
                                column.addlAttrs.label = '';
                            }
                            if (!column.addlAttrs.control) {
                                column.addlAttrs.control = '';
                            }
    
                            if (['empty', 'dummy', 'blank'].includes(column.type)) {
                                column.controlWidth = 1;
                            }
                            if (column.type == 'date') {
                                if(!column.datePicker) {
                                    column.datePicker = {};
                                }
                                column.datePicker.opened = (isUndefinedOrNull(column.datePicker.opened)) ? false : column.datePicker.opened;
                                column.datePicker.format = (!column.datePicker.format) ? 'M!/d!/yyyy' : column.datePicker.format;
                                column.datePicker.valueFormat = (!column.datePicker.valueFormat) ? 'MM/dd/yyyy' : column.datePicker.valueFormat;
                                column.datePicker.appendToBody = (isUndefinedOrNull(column.datePicker.appendToBody)) ? true : column.datePicker.appendToBody;
                                column.datePicker.options = (!column.datePicker.options) ? '{}' : column.datePicker.options;
                            }
                            if (column.type == 'text' && !!column.isMasked) {
                                if (!column.maskConfig) {
                                    column.maskConfig = {};
                                }
                                column.maskConfig.clearOnBlur = (isUndefinedOrNull(column.maskConfig.clearOnBlur)) ? false : column.maskConfig.clearOnBlur;
                                column.maskConfig.allowInvalidValue = (isUndefinedOrNull(column.maskConfig.allowInvalidValue)) ? true : column.maskConfig.allowInvalidValue;
                                column.maskConfig.clearOnBlurPlaceholder = (isUndefinedOrNull(column.maskConfig.clearOnBlurPlaceholder)) ? false : column.maskConfig.clearOnBlurPlaceholder;
                            }
                            if (column.type == 'number') {
                                if (!column.numberConfig) {
                                    column.numberConfig = {};
                                }
                                column.numberConfig.min = (!column.numberConfig.min) ? null : column.numberConfig.min;                            
                                column.numberConfig.max = (!column.numberConfig.max) ? null : column.numberConfig.max;
                            }
                            if (column.type == 'button') {
                                if (!column.buttonConfig) {
                                    column.buttonConfig = {};
                                }
    
                                column.buttonConfig.label = (!column.buttonConfig.label) ? column.name : column.buttonConfig.label;
                                column.buttonConfig.onClick = (!column.buttonConfig.onClick) ? '' : column.buttonConfig.onClick;
                            }
                            if (column.type == 'radio') {
                                if (!column.radioConfig) {
                                    column.radioConfig = {};
                                }
    
                                column.radioConfig.breakOnEach = (!isUndefinedOrNull(column.radioConfig.breakOnEach)) ? column.radioConfig.breakOnEach : false;
                            }
                            if (column.type == 'checkbox') {
                                if (!column.checkboxConfig) {
                                    column.checkboxConfig = {};
                                }
    
                                column.checkboxConfig.trueValue = (!isUndefinedOrNull(column.checkboxConfig.trueValue)) ? column.checkboxConfig.trueValue : true;
                                column.checkboxConfig.falseValue = (!isUndefinedOrNull(column.checkboxConfig.falseValue)) ? column.checkboxConfig.falseValue : false;
                            }
                            if (column.type == 'hidden') {
                                column.show = "false"
                            }
                            if (column.type == 'texthtml') {
                                if (!column.ckEditorConfig) {
                                    column.ckEditorConfig = {};
                                }
                                column.ckEditorConfig.language = (!column.ckEditorConfig.language) ? 'en' : column.ckEditorConfig.language;
                                column.ckEditorConfig.allowedContent = (isUndefinedOrNull(column.ckEditorConfig.allowedContent)) ? true : column.ckEditorConfig.allowedContent;
                                column.ckEditorConfig.fullPage = (isUndefinedOrNull(column.ckEditorConfig.fullPage)) ? true : column.ckEditorConfig.fullPage;
                                column.ckEditorConfig.entities = (isUndefinedOrNull(column.ckEditorConfig.entities)) ? false : column.ckEditorConfig.entities;
                                column.ckEditorConfig.disableNativeSpellChecker = (isUndefinedOrNull(column.ckEditorConfig.disableNativeSpellChecker)) ? true : column.ckEditorConfig.disableNativeSpellChecker;
                                column.ckEditorConfig.scayt_autoStartup = (isUndefinedOrNull(column.ckEditorConfig.scayt_autoStartup)) ? false : column.ckEditorConfig.scayt_autoStartup;
                            };
                            if (column.type == 'dropdown' || column.type == 'dropdownRef') {
                                if (!!column.dataBindings) {
                                    column.dropdownConfig = column.dataBindings;
                                }
    
                                if (!column.dropdownConfig) {
                                    console.error('Form Builder - You cannot declare a dropdown without an accompanying configuration - {0}.{1}'.format(formConfig.name, column.name));
                                }
                                if (!column.dropdownConfig.staticList && (!column.dropdownConfig.valueFieldName || !column.dropdownConfig.textFieldName)) {
                                    console.error('Form Builder - You must specify a value field and a text field - {0}.{1}'.format(formConfig.name, column.name));
                                }
                                if (column.type == 'dropdown' && !column.dropdownConfig.dataList && !column.dropdownConfig.staticList) {
                                    console.error('Form Builder - You must specify a data list or a static list for the dropdown - {0}.{1}'.format(formConfig.name, column.name));
                                }
                                if (column.type == 'dropdownRef' && !column.dropdownConfig.tableKey) {
                                    console.error('Form Builder - You must specify the table key for a reference dropdown - {0}.{1}'.format(formConfig.name, column.name));
                                }
                                
                                if (column.type == 'dropdownRef' && !!column.dropdownConfig.listName && !column.dropdownConfig.array) {
                                    column.dropdownConfig.array = column.dropdownConfig.listName;
                                } else if (column.type == 'dropdownRef' && !column.dropdownConfig.listName && !!column.dropdownConfig.array) {
                                    column.dropdownConfig.listName = column.dropdownConfig.array;
                                }
    
                                column.dropdownConfig.isMultiSelect = (isUndefinedOrNull(column.dropdownConfig.isMultiSelect)) ? false : column.dropdownConfig.isMultiSelect;
                                column.dropdownConfig.isTypeAhead = (isUndefinedOrNull(column.dropdownConfig.isTypeAhead)) ? false : column.dropdownConfig.isTypeAhead;
                                column.dropdownConfig.orderBy = (!column.dropdownConfig.orderBy) ? column.dropdownConfig.textFieldName : column.dropdownConfig.orderBy;
                                column.dropdownConfig.defaultOption = (!column.dropdownConfig.defaultOption) ? '-- Select --' : column.dropdownConfig.defaultOption;
    
                                if (!column.dropdownConfig.typeAheadConfig) {
                                    column.dropdownConfig.typeAheadConfig = {};
                                }
                                column.dropdownConfig.typeAheadConfig.appendToBody = (isUndefinedOrNull(column.dropdownConfig.typeAheadConfig.appendToBody)) ? 'true' : column.dropdownConfig.typeAheadConfig.appendToBody;
                                column.dropdownConfig.typeAheadConfig.onSelect = (!column.dropdownConfig.typeAheadConfig.onSelect) ? '' : column.dropdownConfig.typeAheadConfig.onSelect;
                            }
    
                            // Make sure every type present has a default column type config.
                            // This does not override a column type config, but rather will ensure that 
                            // if this type isn't currently accounted for that it will use the default
                            // configuration.
                            if (!columnTypeConfigs[column.type]) {
                                columnTypeConfigs[column.type] = defaultColumnTypeConfig;
                            }
                        });
                    }
                   
                });

                // Surround the form with a div that can catch all reference tables being loaded in it
                // with a wait-for-all, if used.
                var html = '<div {0}>'.format((!!formConfig.waitForAll) ? 'wait-for-all on-loaded="' + formConfig.waitForAll.onLoaded + '"' : '');

                // Start generating the HTML 
                formConfig.rows.forEach(function (row, rowIndex) {
                    // Create a form-group (row)
                    html += '<div class="{0}" {1} {2} {3}>'
                        .format((!row.removeFormGroup) ? 'form-group' : '',
                                (!!row.show) ? 'ng-show="' + row.show + '"' : '',
                                (!!row.condition) ? 'ng-if="' + row.condition + '"' : '',
                                (!!row.init) ? 'ng-init="' + row.init + '"' : '');

                    // Iterate through each column in this row
                     if(row.columns){
                        row.columns.forEach(function (column, colIndex) {
                            var labelClasses = '';
                            var controlClasses = '';
                            var inputId = $scope.$id + column.name + 'r' + rowIndex + 'c' + colIndex;
    
                            // First, get the column sizes for the label and control
                            if (!!column.sizes) {
                                labelClasses = column.sizes.label;
                                controlClasses = column.sizes.control;
                            } else {
                                // Deprecated, should always define the sizes property
                                labelClasses = 'col-md-2 col-sm-4';
                                controlClasses = 'col-md-{0} col-sm-8'
                                    .format(((12-(row.columns.length*2))*(column.controlWidth/formConfig.columns)));
                            }
    
                            // Now, if the column "type" uses a label, generate the HTML for the label
                            if (!!columnTypeConfigs[column.type].hasLabel) {
                                if (!!columnTypeConfigs[column.type].usesLabelTag && !column.hideLabel) {
                                    // Make sure the column label is being shown, and uses the label tag
                                    if (!!column.label) {
                                        var r = /'/gi;
                                        html += '<label for={7} class="control-label {0}" ng-class="{\'required-label\':{1}}" ng-show="{4}" {5} {6}><span ng-bind-html="\'{2}\'"></span>{3}</label>'
                                        .format(labelClasses, column.isRequired, column.label.replace(r, '\\\''), (!!column.hideColon) ? '' : ':', column.show,
                                            (!!column.condition) ? 'ng-if="' + column.condition + '"' : '',
                                            column.addlAttrs.label, inputId);
                                        column.requiredLabel = column.label;
                                    } else if (!!column.labelContent) {
                                        html += '<label for={10} class="control-label {0}" ng-class="{\'required-label\':{1}}" ng-show="{3}" {4}> \
                                                <inlumon-content display="first" tag="div" {5} {6} {7} {8} {9}></inlumon-content>{2} \
                                            </label>'
                                        .format(labelClasses, column.isRequired, (!!column.hideColon) ? '' : ':', column.show,
                                            (!!column.condition) ? 'ng-if="' + column.condition + '"' : '',
                                            (!!column.labelContent.contentId) ? 'content-id="' + column.labelContent.contentId + '"' : '',
                                            (!!column.labelContent.contentCode) ? 'content-code="\'' + column.labelContent.contentCode + '\'"' : '',
                                            (!!column.labelContent.pageTabSectionId) ? 'page-tab-section-id="' + column.labelContent.pageTabSectionId + '"' : '',
                                            (!!column.labelContent.onLoaded) ? 'on-loaded="' + column.labelContent.onLoaded + '"' : '',
                                            column.addlAttrs.label, inputId);
                                        column.requiredLabel = (!!column.labelContent.requiredLabel) ? column.labelContent.requiredLabel : column.name;
                                    }
                                } else if (!column.hideLabel) {
                                    // Make sure the column label is being shown, but does not use the label tag
                                    html += '<div class="{0}" ng-show="{1}">&nbsp;</div>'.format(labelClasses, column.show);
                                } else {
                                    // The label is hidden, but may still need to be validated
                                    column.requiredLabel = column.label;
                                }
                            } else {
                                if (!!column.label) {
                                    // There is no label, but may still need to be validated
                                    column.requiredLabel = column.label;
                                } else if (!!column.labelContent) {
                                    // There is no label, but may still need to be validated
                                    column.requiredLabel = (!!column.labelContent.requiredLabel) ? column.labelContent.requiredLabel : column.name;
                                }
                            }
                            
                            // Some types might only have a label, not a control
                            if (!columnTypeConfigs[column.type].hasControl) {
                                return;
                            }
    
                            // Add style classes to the control div
                            if (column.type == 'checkbox') {
                                controlClasses += ' checkbox';
                            }
                            if (column.type == 'radio') {
                                controlClasses += ' radio';
                            }
    
                            // if (!!column.condition) {
                            //     html += '<div class="{0}" ng-if="{1}" ng-show="{2}">'.format(controlClasses, column.condition, column.show);
                            // } else {
                            //     html += '<div class="{0}" ng-show="{1}">'.format(controlClasses, column.show);
                            // }
    
                            // Generate the surrounding div
                            html += '<div class="{0}" {1} {2} {3}>'.format(
                                controlClasses,
                                (!!column.condition) ? 'ng-if="' + column.condition + '"' : '',
                                (!!column.show) ? 'ng-show="' + column.show + '"' : '',
                                (!!column.init) ? 'ng-init="' + column.init + '"' : ''
                            );
    
                            // Generate the error message that appears if this field fails validation
                            var errorMessage = '';
                            if (!!column.errorMessage) {
                                errorMessage = column.errorMessage;
                            } else {
                                errorMessage = column.requiredLabel + ' is required';
                            }
                            
                            // Generate the HTML of the form field itself
                            switch(column.type) {
                                case 'text':
                                default: 
                                    if (column.isMasked) {
                                        html += '\
                                            <input name="{0}" id="{13}" placeholder="{1}" {2}\
                                                type="text" class="form-control" ng-model="{3}{4}"\
                                                ng-value="{3}{4}"\
                                                {5}\
                                                ui-mask="{6}" model-view-value="true" ui-options="{7}"\
                                                \
                                                ng-disabled="{11}"\
                                                nat-required="{8}" validate-req-field="{9}"\
                                                ng-class="{\'required-text\':(({10}.{0}.$dirty || {10}.$submitted) && {10}.{0}.$error.natrequired)}" \
                                                {12} />\
                                        '.format(column.name, (!!column.placeholder) ? column.placeholder: '', (!!startingTabIndex) ? 'tabindex="{0}"'.format(startingTabIndex) : '', modelObj,
                                            column.bindingField, (!!column.onChange ? 'ng-change="' + column.onChange + '" ': ''), column.mask,
                                            JSON.stringify(column.maskConfig).replace(/"/g, '\''), errorMessage, column.isRequired, formConfig.name,
                                            column.isDisabled,
                                            column.addlAttrs.control, inputId);
                                    } else {
                                        html += '\
                                            <input name="{0}" id="{11}" placeholder="{1}" {2}\
                                                type="text" class="form-control" ng-model="{3}{4}"\
                                                ng-value="{3}{4}"\
                                                {5}\
                                                \
                                                ng-disabled="{9}"\
                                                nat-required="{6}" validate-req-field="{7}"\
                                                ng-class="{\'required-text\':(({8}.{0}.$dirty || {8}.$submitted) && {8}.{0}.$error.natrequired)}" \
                                                {10} {12} {13}/>\
                                        '.format(column.name, (!!column.placeholder) ? column.placeholder: '', (!!startingTabIndex) ? 'tabindex="{0}"'.format(startingTabIndex) : '', 
                                            modelObj, column.bindingField, 
                                            (!!column.onChange) ? 'ng-change="' + column.onChange + '" ': '', 
                                            errorMessage, column.isRequired, formConfig.name,
                                            column.isDisabled,
                                            column.addlAttrs.control, inputId,
                                            (!!column.regexErrorMessage ? 'nat-regex="' + column.regexErrorMessage + '" ' : ''),
                                            (!!column.regex ? 'regextype="' + column.regex + '" ' : '')
                                        );
                                    }
                                    break;
                                case 'number':
                                    html += '\
                                        <input name="{0}" id="{11}" placeholder="{1}" {2}\
                                            type="number" class="form-control" ng-model="{3}{4}"\
                                            ng-value="{3}{4}"\
                                            {5}\
                                            model-view-value="true" \
                                            \
                                            ng-disabled="{9}"\
                                            nat-required="{6}" validate-req-field="{7}"\
                                            ng-class="{\'required-text\':(({8}.{0}.$dirty || {8}.$submitted) && {8}.{0}.$error.natrequired)}" \
                                            {10} />\
                                        '.format(column.name, (!!column.placeholder) ? column.placeholder: '', (!!startingTabIndex) ? 'tabindex="{0}"'.format(startingTabIndex) : '', modelObj,
                                            column.bindingField, (!!column.onChange ? 'ng-change="' + column.onChange + '" ': ''), 
                                            errorMessage, column.isRequired, formConfig.name, column.isDisabled,
                                            column.addlAttrs.control, inputId);
                                    break;
                                case 'date':
                                    html += '\
                                        <input name="{0}" id="{16}" placeholder="{1}" {2}\
                                            type="text" class="form-control" ng-model="{3}{4}"\
                                            ng-value="{3}{4} | date: \'{12}\'"\
                                            {5} datepicker-options="{14}"\
                                            uib-datepicker-popup="{9}" ng-model-options="{allowInvalid: true}" on-open-focus="false"\
                                            ng-click="_formDates.{0}.opened=true" popup-placement="auto top" is-open="_formDates.{0}.opened"\
                                            \
                                            ng-disabled="{11}"\
                                            nat-required="{6}" validate-req-field="{7}"\
                                            nat-date-format="Please enter the {15} in format M/D/YYYY or MM/DD/YYYY." datepicker-append-to-body="{10}"\
                                            ng-class="{\'required-text\':(({8}.{0}.$dirty || {8}.$submitted) && ({8}.{0}.$error.natdateformat || {8}.{0}.$error.natrequired))}" \
                                            {13} />\
                                        '.format(column.name, (!!column.placeholder) ? column.placeholder: '', (!!startingTabIndex) ? 'tabindex="{0}"'.format(startingTabIndex) : '', 
                                            modelObj, column.bindingField, 
                                            (!!column.onChange) ? 'ng-change="' + column.onChange + '" ': '', 
                                            errorMessage, column.isRequired, formConfig.name,
                                            column.datePicker.format, column.datePicker.appendToBody,
                                            column.isDisabled, column.datePicker.valueFormat,
                                            column.addlAttrs.control, column.datePicker.options, column.requiredLabel, inputId);
                                    break;
                                case 'datetime':
                                        html += '\
                                            <date-time-picker name="{0}" id="{11}" placeholder="{1}" {2}\
                                                class="form-control" ng-model="{3}{4}"\
                                                {5}\
                                                ng-disabled="{9}"\
                                                nat-required="{6}" validate-req-field="{7}"\
                                                ng-class="{\'required-text\':(({8}.{0}.$dirty || {8}.$submitted) && ({8}.{0}.$error.natdateformat || {8}.{0}.$error.natrequired))}" \
                                                {10}> \
                                            </date-time-picker> \
                                            '.format(column.name, (!!column.placeholder) ? column.placeholder: '', (!!startingTabIndex) ? 'tabindex="{0}"'.format(startingTabIndex) : '', 
                                                modelObj, column.bindingField, 
                                                (!!column.onChange) ? 'ng-change="' + column.onChange + '" ': '', 
                                                errorMessage, column.isRequired, formConfig.name,
                                                column.isDisabled,
                                                column.addlAttrs.control, inputId);
                                        break;
                                case 'dropdown':
                                    var staticList = '';
                                    var ngOptions = '';
                                    if (!!column.dropdownConfig.staticList) {
                                        column.dropdownConfig.staticList.forEach(function (e) {
                                            if (typeof(e.value) == 'string') {
                                                staticList += '<option ng-value="\'{0}\'" ng-selected="{2}{3} == \'{0}\'">{1}</option>'.format(e.value, e.text, modelObj, column.bindingField);
                                            } else {
                                                staticList += '<option ng-value="{0}" ng-selected="{2}{3} == {0}">{1}</option>'.format(e.value, e.text, modelObj, column.bindingField);
                                            }
                                        });
                                    } else {
                                        ngOptions = 'ng-options="{0} as {1} for item in {2}"'
                                            .format((!!column.dropdownConfig.overrideNames) ? column.dropdownConfig.valueFieldName : 'item.' + column.dropdownConfig.valueFieldName, 
                                                    (!!column.dropdownConfig.overrideNames) ? column.dropdownConfig.textFieldName : 'item.' + column.dropdownConfig.textFieldName,
                                                    column.dropdownConfig.dataList);
                                    }
    
                                    if (!!column.dropdownConfig.isMultiSelect) {
                                        html += '\
                                        <multi-select name="{0}" id="{14}" {2}\
                                            class="form-control" ng-model="{3}{4}"\
                                            {5}\
                                            {9}\
                                            \
                                            ng-disabled="{12}"\
                                            nat-required="{6}" validate-req-field="{7}"\
                                            ng-class="{\'required-text\':(({8}.{0}.$dirty || {8}.$submitted) && {8}.{0}.$error.natrequired)}" \
                                            {13}>\
                                        </multi-select>\
                                        '.format(column.name, (!!column.placeholder) ? column.placeholder: '', (!!startingTabIndex) ? 'tabindex="{0}"'.format(startingTabIndex) : '', 
                                            modelObj, column.bindingField, 
                                            (!!column.onChange) ? 'ng-change="' + column.onChange + '" ': '', 
                                            errorMessage, column.isRequired, formConfig.name,
                                            ngOptions, staticList, column.dropdownConfig.defaultOption, column.isDisabled,
                                            column.addlAttrs.control, inputId);
                                    } else if (!!column.dropdownConfig.isTypeAhead) {
                                        var valueFieldName = (!!column.dropdownConfig.valueFieldName) ? 'item.' + column.dropdownConfig.valueFieldName : 'item';
                                        var textFieldName = (!!column.dropdownConfig.textFieldName) ? 'item.' + column.dropdownConfig.textFieldName : 'item';
                                        ngOptions = 'uib-typeahead="{0} as {1} for item in ({2} | filter:$viewValue | limitTo:8)"'
                                            .format((!!column.dropdownConfig.overrideNames) ? column.dropdownConfig.valueFieldName : valueFieldName, 
                                                    (!!column.dropdownConfig.overrideNames) ? column.dropdownConfig.textFieldName : textFieldName,
                                                    column.dropdownConfig.dataList);
                                        html += '\
                                        <input type="text" name="{0}" id="{14}" autocomplete="off" {2}\
                                            class="form-control" ng-model="{3}{4}"\
                                            {5}\
                                            {9}\
                                            typahead-append-to-body="{11}"\
                                            typeahead-editable="false" \
                                            \
                                            ng-disabled="{10}"\
                                            nat-required="{6}" validate-req-field="{7}" {11} \
                                            ng-class="{\'required-text\':(({8}.{0}.$dirty || {8}.$submitted) && {8}.{0}.$error.natrequired)}" \
                                            {12} {13} />\
                                        '.format(column.name, (!!column.placeholder) ? column.placeholder: '', (!!startingTabIndex) ? 'tabindex="{0}"'.format(startingTabIndex) : '', 
                                            modelObj, column.bindingField, 
                                            (!!column.onChange) ? 'ng-change="' + column.onChange + '" ': '', 
                                            errorMessage, column.isRequired, formConfig.name,
                                            ngOptions, column.isDisabled, column.dropdownConfig.typeAheadConfig.appendToBody,
                                            (!!column.dropdownConfig.typeAheadConfig.onSelect) ? 'typeahead-on-select="{0}"'.format(column.dropdownConfig.typeAheadConfig.onSelect) : '',
                                            column.addlAttrs.control, inputId);
                                    } else {
                                        html += '\
                                        <select name="{0}" id="{14}" {2}\
                                            class="form-control" ng-model="{3}{4}"\
                                            {5}\
                                            {9}\
                                            \
                                            ng-disabled="{12}"\
                                            nat-required="{6}" validate-req-field="{7}"\
                                            ng-class="{\'required-text\':(({8}.{0}.$dirty || {8}.$submitted) && {8}.{0}.$error.natrequired)}" \
                                            {13}>\
                                            <option value="">{11}</option>\
                                            {10}\
                                        </select>\
                                        '.format(column.name, (!!column.placeholder) ? column.placeholder: '', (!!startingTabIndex) ? 'tabindex="{0}"'.format(startingTabIndex) : '', 
                                            modelObj, column.bindingField, 
                                            (!!column.onChange) ? 'ng-change="' + column.onChange + '" ': '', 
                                            errorMessage, column.isRequired, formConfig.name,
                                            ngOptions, staticList, column.dropdownConfig.defaultOption, column.isDisabled,
                                            column.addlAttrs.control, inputId);
                                    }
                                    
                                    break;
                                case 'dropdownRef':
                                    html += '\
                                        <inlumon-ref-table-loader\
                                            name="{0}" id="{20}" {2}\
                                            class="form-control" ng-model="{3}{4}"\
                                            {5}\
                                            table-key="{9}" default="{13}" {10}\
                                            show-select="true" select="{11}" label="{12}"\
                                            is-multi-select="{18}"\
                                            \
                                            is-type-ahead="{22}" \
                                            typahead-append-to-body="{23}"\
                                            {24}\
                                            \
                                            {14}\
                                            {15}\
                                            {16}\
                                            {21}\
                                            ng-disabled="{17}"\
                                            nat-required="{6}" validate-req-field="{7}"\
                                            ng-class="{\'required-text\':(({8}.{0}.$dirty || {8}.$submitted) && {8}.{0}.$error.natrequired)}" \
                                            {19}>\
                                        </inlumon-ref-table-loader>\
                                    '.format(column.name, (!!column.placeholder) ? column.placeholder: '', (!!startingTabIndex) ? 'tabindex="{0}"'.format(startingTabIndex) : '', 
                                        modelObj, column.bindingField, 
                                        (!!column.onChange) ? 'ng-change="' + column.onChange + '" ': '', 
                                        errorMessage, column.isRequired, formConfig.name,
                                        column.dropdownConfig.tableKey, (!!column.dropdownConfig.orderBy) ? 'order-by="' + column.dropdownConfig.orderBy + '"' : '',
                                        column.dropdownConfig.valueFieldName, column.dropdownConfig.textFieldName, column.dropdownConfig.defaultOption,
                                        (!!column.dropdownConfig.listName ? 'list-name="{0}" array="{1}"'.format(column.dropdownConfig.listName, column.dropdownConfig.array) : ''),
                                        (!!column.dropdownConfig.lookFor ? 'look-for="{0}"'.format(column.dropdownConfig.lookFor) : ''),
                                        (!!column.dropdownConfig.onLoaded ? 'on-loaded="{0}"'.format(column.dropdownConfig.onLoaded) : ''),
                                        column.isDisabled, !!column.dropdownConfig.isMultiSelect,
                                        column.addlAttrs.control, inputId,
                                        (!!column.dropdownConfig.where ? 'where="{0}"'.format(column.dropdownConfig.where) : ''),
                                        !!column.dropdownConfig.isTypeAhead, column.dropdownConfig.typeAheadConfig.appendToBody,
                                        (!!column.dropdownConfig.typeAheadConfig.onSelect) ? 'typeahead-on-select="{0}"'.format(column.dropdownConfig.typeAheadConfig.onSelect) : '');
                                    break;
                                case 'radio':
                                    column.radioOptions.forEach(function (r) {
                                        if (typeof(r.value) == 'string') {
                                            html += '<label {11} {12} style="margin: 0px 10px 0px 0px; padding-top: 0px;">\
                                                <input name="{0}" type="radio" role="radio" ng-model="{3}{4}"\
                                                    ng-checked="{3}{4}==\'{10}\'"\
                                                    nat-required="{6}" validate-req-field="{7}"\
                                                    {5} ng-value="\'{10}\'" \
                                                    {13}/> \
                                                {9} \
                                            </label>'.format(column.name, (!!column.placeholder) ? column.placeholder: '', (!!startingTabIndex) ? 'tabindex="{0}"'.format(startingTabIndex) : '', 
                                                        modelObj, column.bindingField, 
                                                        (!!column.onChange) ? 'ng-change="' + column.onChange + '" ': '', 
                                                        errorMessage, column.isRequired, formConfig.name,
                                                        r.label, r.value, 
                                                        (!!r.condition) ? 'ng-if="' + r.condition + '"' : '',
                                                        (!!r.show) ? 'ng-show="' + r.show + '"' : '',
                                                        column.addlAttrs.control);
                                        } else {
                                            html += '<label {11} {12} style="margin: 0px 10px 0px 0px; padding-top: 0px;">\
                                                <input name="{0}" type="radio" role="radio" ng-model="{3}{4}"\
                                                    ng-checked="{3}{4}=={10}"\
                                                    nat-required="{6}" validate-req-field="{7}"\
                                                    {5} ng-value="{10}" \
                                                    {11}/>\
                                                {9} \
                                            </label>'.format(column.name, (!!column.placeholder) ? column.placeholder: '', (!!startingTabIndex) ? 'tabindex="{0}"'.format(startingTabIndex) : '', 
                                                        modelObj, column.bindingField, 
                                                        (!!column.onChange) ? 'ng-change="' + column.onChange + '" ': '', 
                                                        errorMessage, column.isRequired, formConfig.name,
                                                        r.label, r.value, 
                                                        (!!r.condition) ? 'ng-if="' + r.condition + '"' : '',
                                                        (!!r.show) ? 'ng-show="' + r.show + '"' : '',
                                                        column.addlAttrs.control);
                                        }
    
                                        if (!!column.radioConfig.breakOnEach) {
                                            html += '<br {0} {1} />'
                                            .format(
                                                (!!r.condition) ? 'ng-if="' + r.condition + '"' : '',
                                                (!!r.show) ? 'ng-show="' + r.show + '"' : ''
                                            );
                                        }
                                    });
                                    break;
                                case 'checkbox':
                                        var checkboxLabel = '';
                                        var trueValue = '';
                                        var falseValue = '';
    
                                        if (!!column.label) {
                                            checkboxLabel = column.label;
                                        } else if (!!column.labelContent) {
                                            checkboxLabel = '<inlumon-content display="first" tag="span" {0} {1} {2} {3} {4}></inlumon-content>'
                                                .format(
                                                    (!!column.labelContent.contentId) ? 'content-id="' + column.labelContent.contentId + '"' : '',
                                                    (!!column.labelContent.contentCode) ? 'content-code="\'' + column.labelContent.contentCode + '\'"' : '',
                                                    (!!column.labelContent.pageTabSectionId) ? 'page-tab-section-id="' + column.labelContent.pageTabSectionId + '"' : '',
                                                    (!!column.labelContent.onLoaded) ? 'on-loaded="' + column.labelContent.onLoaded + '"' : '',
                                                    column.addlAttrs.label);
                                        }
    
                                        if (typeof(column.checkboxConfig.trueValue) == 'string') {
                                            trueValue = '\'' + column.checkboxConfig.trueValue + '\'';
                                        } else {
                                            trueValue = (!!column.checkboxConfig.trueValue) ? column.checkboxConfig.trueValue : 'true';
                                        }
    
                                        if (typeof(column.checkboxConfig.falseValue) == 'string') {
                                            falseValue = '\'' + column.checkboxConfig.falseValue + '\'';
                                        } else {
                                            falseValue = (!!column.checkboxConfig.falseValue) ? column.checkboxConfig.falseValue : 'false';
                                        }
    
                                        html += '<label> \
                                                    <input name="{0}" type="checkbox" ng-model="{3}{4}" \
                                                        nat-required="{6}" validate-req-field="{7}" \
                                                        {5} ng-true-value="{10}" ng-false-value="{11}" \
                                                        ng-checked="{3}{4}=={10}" ng-disabled="{9}"\
                                                        {13} \
                                                        style="margin-top: 1px;"/> \
                                                    {12} \
                                                </label>'
                                                .format(column.name, (!!column.placeholder) ? column.placeholder: '', (!!startingTabIndex) ? 'tabindex="{0}"'.format(startingTabIndex) : '', 
                                                        modelObj, column.bindingField, 
                                                        (!!column.onChange) ? 'ng-change="' + column.onChange + '" ': '', 
                                                        errorMessage, column.isRequired, formConfig.name,
                                                        column.isDisabled, trueValue, falseValue, checkboxLabel,
                                                        column.addlAttrs.control);
                                        break;
                                case 'textarea':
                                    if (!isReadOnly) {
                                        html += '\
                                            <textarea name="{0}" id="{11}" placeholder="{1}" {2}\
                                                type="text" class="form-control" ng-model="{3}{4}"\
                                                ng-value="{3}{4}"\
                                                ng-init="{3}{4}=((!!{3}{4}) ? {3}{4} : \'\')" \
                                                ng-change="{3}{4}=((!!{3}{4}) ? {3}{4} : \'\');{5}" \
                                                \
                                                ng-disabled="{9}"\
                                                nat-required="{6}" validate-req-field="{7}"\
                                                ng-class="{\'required-text\':(({8}.{0}.$dirty || {8}.$submitted) && {8}.{0}.$error.natrequired)}" \
                                                {10}>\
                                            </textarea>\
                                        '.format(column.name, (!!column.placeholder) ? column.placeholder: '', (!!startingTabIndex) ? 'tabindex="{0}"'.format(startingTabIndex) : '', 
                                            modelObj, column.bindingField, 
                                            (!!column.onChange) ? column.onChange: '', 
                                            errorMessage, column.isRequired, formConfig.name, column.isDisabled,
                                            column.addlAttrs.control, inputId);
                                    } else {
                                        html += '\
                                            <pre style="white-space: pre-line !important;"> \
                                                {{{3}{4}}} \
                                            </pre> \
                                        '.format(column.name, (!!column.placeholder) ? column.placeholder: '', (!!startingTabIndex) ? 'tabindex="{0}"'.format(startingTabIndex) : '', 
                                            modelObj, column.bindingField, 
                                            (!!column.onChange) ? column.onChange: '', 
                                            errorMessage, column.isRequired, formConfig.name, column.isDisabled,
                                            column.addlAttrs.control, inputId);
                                    }
                                    
                                    break;
                                case 'button':
                                    html += '\
                                        <button name="{0}" type="{1}"\
                                            class="{2}"\
                                            ng-click="{3}"\
                                            ng-disabled="{5}" \
                                            {6}\>\
                                            {4}\
                                        </button>\
                                    '.format(column.name, 
                                        (!!column.buttonConfig.type) ? column.buttonConfig.type : 'button',
                                        (!!column.buttonConfig.class) ? column.buttonConfig.class : 'btn btn-success',
                                        column.buttonConfig.onClick, column.buttonConfig.label, column.isDisabled,
                                        column.addlAttrs.control);
                                    break;
                                case 'hidden':
                                    html += ' \
                                        <input type="text" ng-model="{1}{2}" nat-required="{3}" validate-req-field="{4}" {5} /> \
                                    '.format(column.name, modelObj, column.bindingField, 
                                        errorMessage, column.isRequired,
                                        column.addlAttrs.control
                                    );
                                    break;
                                case 'texthtml':
                                    html += '<div name="{0}" id="{7}" type="hidden" class="editor" ckeditor="{1}" ng-model="{2}{3}"\
                                        nat-required-html="{4}" validate-req-field="{5}" {6}></div>'
                                        .format(column.name, JSON.stringify(column.ckEditorConfig).replace(/"/g, '\''),
                                            modelObj, column.bindingField, errorMessage, column.isRequired,
                                            column.addlAttrs.control, inputId);
                                    break;
                                case 'empty':
                                case 'dummy':
                                case 'blank':
                                    html += '<span>&nbsp;</span>';
                                    break;
                            }
                            html += '</div>';
    
                            if (!!startingTabIndex) {
                                startingTabIndex++;
                            }
                        });
                     }
                    

                    html += '</div>';
                });
            
                // Figured out what was going on here with the lack of the <form> being referenced in the ngModels
                //
                // The html that is generated is only a snippet, so when it is compiled/linked on its own, 
                // without first being inserted into the larger DOM, it would only compile/link within the context of 
                // the snippet, leaving out all other potential dependencies in the rest of the DOM.  
                // In order to put it properly within the DOM, you first have to add it to the DOM, 
                // THEN compile it, and then FINALLY link it with the $scope.
                // This will ensure that the directives used in the form will be able to "require" other directives,
                // hence ngModel requiring "form", because until it is in the DOM, it won't be able to see other
                // directives.

                html += '</div>';

                var newForm = angular.element(html);

                element.replaceWith(newForm);

                $compile(newForm)($scope);
                
                // Old code, to ensure the original logic isn't lost until the new logic can be thoroughly tested.
                // var DOM = angular.element(
                //     '<div class="form-group" ng-repeat="row in formConfig.rows">
                //         <label ng-repeat-start="column in row.columns" class="control-label col-md-2 col-sm-4" ng-class="{'required-label':(!!column.isRequired)}">{{column.label}} {{(!!column.label ? ':' : '')}}</label>
                //         <div ng-repeat-end class="col-md-{{((12-(row.columns.length*2))*(column.controlWidth/formConfig.columns))}} col-sm-8">
                //             <input ng-if="((!column.type || column.type=='text') && !column.isMasked)" name="{{column.name}}" placeholder="{{column.placeholder}}" tabindex="{{startingTabIndex+1}}" type="text" class="form-control" ng-model="${$scope.formConfig.modelObj}[column.bindingField]" nat-required="{{column.label}} is required" validate-req-field="(!!column.isRequired)"/>
                //             <input ng-if="(column.type=='text' && !!column.isMasked)" name="{{column.name}}" placeholder="{{column.placeholder}}" tabindex="{{startingTabIndex+1}}" type="text" class="form-control" ng-model="${$scope.formConfig.modelObj}[column.bindingField]" nat-required="{{column.label}} is required" validate-req-field="(!!column.isRequired)" ui-mask="{{column.mask}}" model-view-value="true" ui-options="column.maskConfig"/>
                //             <input ng-if="(column.type=='date')" name="{{column.name}}" placeholder="{{column.placeholder}}" tabindex="{{startingTabIndex+1}}" type="text" class="form-control" ng-model="${$scope.formConfig.modelObj}[column.bindingField]" nat-required="{{column.label}} is required" validate-req-field="(!!column.isRequired)" uib-datepicker-popup="{{column.datePicker.format}}" ng-model-options="{allowInvalid: true}" on-open-focus="false" ng-click="column.datePicker.opened=true" popup-placement="top" is-open="column.datePicker.opened" nat-date-format="Please enter the {{column.label}} in format M/D/YYYY or MM/DD/YYYY." ng-class="{'required-text':((searchForm.dateOfBirth.$dirty || searchForm.$submitted) && searchForm.dateOfBirth.$error.natdateformat)}" />
                //             <select ng-if="(column.type=='staticdropdown')" name="{{column.name}}" placeholder="{{column.placeholder}}" 
                //                 tabindex="{{startingTabIndex+1}}" class="form-control" ng-model="${$scope.formConfig.modelObj}[column.bindingField]" 
                //                 nat-required="{{column.label}} is required" validate-req-field="(!!column.isRequired)">
                //                 <option ng-repeat="item in column.dataList" value="{{item[column.dropdownConfig.valuecolumnName]}}">{{item[column.dropdownConfig.textcolumnName]}}</option>
                //             </select>
                //             <inlumon-ref-table-loader ng-if="(column.type=='dropdownRef')"
                //                         name="{{column.name}}" placeholder="{{column.placeholder}}" tabindex="{{startingTabIndex+1}}"
                //                         class="form-control" ng-model="${$scope.formConfig.modelObj}[column.bindingField]" nat-required="{{column.label}} is required"
                //                         validate-req-field="(!!column.isRequired)"
                //                         table-key="{{column.dropdownConfig.tableKey}}"
                //                         show-select="true" select="{{column.dropdownConfig.valueFieldName}}" label="{{column.dropdownConfig.textFieldName}}">
                //             </inlumon-ref-table-loader>
                //             <span ng-if="column.type=='radio'" ng-repeat="r in column.radioOptions">
                //                 {{r.label}} <input type="radio" ng-model="${$scope.formConfig.modelObj}[column.bindingField]" ng-value="r.value" />
                //             </span>
                //             <span ng-if="column.type=='empty'"></span>
                //         </div>
                //     </div>'
                // );
            }
        }
    }

    var _refTables = {};
    
    InlumonFormBuilderService.$inject = ['$q', 'typeValuesService'];
    function InlumonFormBuilderService ($q, typeValuesService) {
        var getForms = function () {
            var deferred = $q.defer();
            
            try {
                $q.all([
                    typeValuesService.RefTablesGetAll('form', sessionStorage.Key),
                    typeValuesService.RefTablesGetAll('formfield', sessionStorage.Key)
                ])
                .then(function(data) {
                    if (!!data[0].data.Status) {
                        _refTables.forms = data[0].data.ReferenceEntities;
                    }
                    if (!!data[1].data.Status) {
                        _refTables.formFields = data[1].data.ReferenceEntities;
                    }
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        var _refreshForms = function () {
            var deferred = $q.defer();
            
            try {
                getForms()
                .then(function(data) {
                    deferred.resolve(data);
                }, function(err) {
                    deferred.reject(err);
                });
            } catch (ex) {
                deferred.reject(ex);
            }
            
            return deferred.promise;
        };

        var generateForm = function (formId, formName, modelObj, names, labels, bindings) {
            if (!formId) { return; }

            var objRefReg = /{OBJ_REF}/gi;

            var form = _refTables.forms.whereEquals(formId, 'FormId');
            var formFields = _refTables.formFields.whereEquals(formId, 'FormId').orderBy('SortOrder,FormFieldId');

            if (!form || !formFields || formFields.length == 0) { return; }

            var formConfig = {
                name: formName,
                modelObj: modelObj,
                rows: []
            };

            var numRows = Math.max.apply(null, formFields.select('RowNumber').distinct());

            for (var i = 0; i < numRows; i++) {
                formConfig.rows.push({
                    show: 'false',
                    columns: []
                });
            }

            //Get the form's scope
            var formScope = angular.element('form[name={0}]'.format(formName)).scope();

            formFields.forEach(function (formField) {
                //Initialize the binding field if it doesn't already have a value
                var fullBind = '{0}{1}'.format(
                    (!!modelObj) ? (modelObj) + '.' : '',
                    formField.BindingField
                );
                if (!!formScope && !formScope.$eval(fullBind)) {
                    formScope.$eval(fullBind + ' = null');
                }

                var row = formConfig.rows[formField.RowNumber - 1];
                row.show = 'true';

                var newCol = {};

                newCol.name = formField.FormFieldName;
                if (!!names && !!names[formField.FormFieldName]) {
                    newCol.name = names[formField.FormFieldName];
                }
                newCol.label = formField.FormFieldLabel;
                if (!!labels && !!labels[formField.FormFieldName]) {
                    newCol.label = labels[formField.FormFieldName];
                }                
                newCol.errorMessage = formField.ErrorMessage;
                newCol.hideLabel = formField.HideLabel;
                newCol.isRequired = (!!formField.IsRequired) ? formField.IsRequired.replace(objRefReg, modelObj) : null;
                newCol.isDisabled = (!!formField.IsDisabled) ? formField.IsDisabled.replace(objRefReg, modelObj) : null;
                newCol.bindingField = formField.BindingField;
                if (!!bindings && !!bindings[formField.FormFieldName]) {
                    newCol.bindingField = bindings[formField.FormFieldName];
                }
                newCol.type = formField.Type;
                newCol.placeholder = formField.Placeholder;
                newCol.init = (!!formField.InitField) ? formField.InitField.replace(objRefReg, modelObj) : null;
                newCol.show = (!!formField.ShowField) ? formField.ShowField.replace(objRefReg, modelObj) : null;
                newCol.condition = (!!formField.FieldCondition) ? formField.FieldCondition.replace(objRefReg, modelObj) : null;
                newCol.onChange = (!!formField.OnChange) ? formField.OnChange.replace(objRefReg, modelObj) : null;
                newCol.sizes = {
                    label: formField.LabelSize,
                    control: formField.ControlSize
                };
                newCol.addlAttrs = {
                    label: formField.LabelAddlAttrs,
                    control: formField.ControlAddlAttrs
                };

                switch(newCol.type) {
                    case 'text':
                        newCol.isMasked = formField.IsMasked;
                        if (!!newCol.isMasked) {
                            newCol.mask = formField.Mask;
                            newCol.maskConfig = {
                                clearOnBlur: formField.MaskClearOnBlur,
                                allowInvalidValue: formField.MaskAllowInvalidValue,
                                clearOnBlurPlaceholder: formField.MaskClearOnBlurPlaceholder
                            };
                        }
                        break;
                    case 'number':
                        newCol.numberConfig = {
                            min: formField.NumberMin,
                            max: formField.NumberMax
                        };
                        break;
                    case 'date':
                        newCol.datePicker = {
                            opened: formField.DPOpened,
                            format: formField.DPFormat,
                            valueFormat: formField.DPValueFormat,
                            appendToBody: formField.DPAppendToBody,
                            options: (!!formField.DPOptionsJSON) ? JSON.parse(formField.DPOptionsJSON) : null
                        };
                        break;
                    case 'dropdown':
                    case 'dropdownRef':
                        var ddConfig = newCol.dropdownConfig = {};

                        ddConfig.dataList = formField.DRDDataList;
                        ddConfig.defaultOption = formField.DRDDefaultOption;

                        if (!!formField.ValueList) {
                            ddConfig.staticList = formField.ValueList.split(';').select(function (kvp) {
                                var text = kvp.split(':')[0];
                                var value = kvp.split(':')[1];

                                if (!isNaN(value)) {
                                    value = Number(value);
                                } else if (value.toLowerCase() == 'true') {
                                    value = true;
                                } else if (value.toLowerCase() == 'false') {
                                    value = false;
                                }

                                return {
                                    text: text,
                                    value: value
                                };
                            });
                        }
                        
                        ddConfig.tableKey = formField.DRDTableKey;
                        if (!!ddConfig.tableKey) {
                            ddConfig.orderBy = formField.DRDOrderBy;
                            ddConfig.listName = formField.DRDListName;
                            ddConfig.array = formField.DRDArray;
                            ddConfig.onLoaded = (!!formField.DRDOnLoaded) ? formField.DRDOnLoaded.replace(objRefReg, modelObj) : null;
                        }
                        
                        ddConfig.overrideNames = formField.DRDOverrideNames;
                        ddConfig.valueFieldName = formField.DRDValueFieldName;
                        ddConfig.textFieldName = formField.DRDTextFieldName;

                        ddConfig.isMultiSelect = formField.DRDIsMultiselect;
                        ddConfig.isTypeAhead = formField.DRDIsTypeAhead;
                        if (!!ddConfig.isTypeAhead) {
                            ddConfig.typeAheadConfig = {
                                appendToBody: formField.DRDTypeAheadAppendToBody,
                                onSelect: (!!formField.DRDTypeAheadOnSelect) ? formField.DRDTypeAheadOnSelect.replace(objRefReg, modelObj) : null
                            };
                        }
                        break;
                    case 'radio':
                        newCol.radioConfig = {
                            breakOnEach: formField.RadioBreakOnEach
                        };
                        newCol.radioOptions = formField.ValueList.split(';').select(function (kvp) {
                            var label = kvp.split(':')[0];
                            var value = kvp.split(':')[1];

                            if (!isNaN(value)) {
                                value = Number(value);
                            } else if (value.toLowerCase() == 'true') {
                                value = true;
                            } else if (value.toLowerCase() == 'false') {
                                value = false;
                            }

                            return {
                                label: label,
                                value: value
                            };
                        });
                        break;
                    case 'checkbox':
                        newCol.checkboxConfig = {
                            trueValue: formField.CheckboxTrueValue,
                            falseValue: formField.CheckboxFalseValue
                        };
                        break;
                }

                row.columns.push(newCol);
            });

            return formConfig;
        };

        var _generateFormConfig = function (formId, formName, modelObj, names, labels, bindings) {
            return generateForm(formId, formName, modelObj, names, labels, bindings);
        };

        return {
            refTables: _refTables,
            refreshForms: _refreshForms,
            generateFormConfig: _generateFormConfig
        }
    }
})();
