(function () {
    'use strict'

    angular.module('app.core')
        .directive('inlumonProfileLoader', ['ProfileLoaderService', ProfileLoaderDirective]);

    function ProfileLoaderDirective(ProfileLoaderService) {
        return {
            restrict: 'E',
            scope: {
                profileList: '@',
                variableList: '@',
                lookFor: '@?',
                onLoaded: '&?'
            },
            link: function ($scope, element, attrs) {
                var varList = $scope.variableList.split(',');
                var profList = $scope.profileList.split(',');
                $scope.isBackOfficeUser = (sessionStorage.isBackOfficeUser == 'true' || sessionStorage.isBackOfficeUser == true);
                if (varList.length != profList.length) {
                    throw 'Profile Loader - Profile and Variable lists have to have equal length';
                }

                var scopeElement = null;
                if (!!$scope.lookFor) {
                    scopeElement = element.closest($scope.lookFor);
                } else {
                    scopeElement = element.parent();
                }
                var effectiveScope = scopeElement.scope();
                if ($scope.isBackOfficeUser) {
                    ProfileLoaderService.getUserProfiles(sessionStorage.Key, sessionStorage.UserID)
                        .then(function (data) {
                            if (!!data.Status) {
                                for (var i = 0; i < varList.length; i++) {
                                    var userHasProfile = data.UserProfileList.whereEquals(profList[i], 'ProfileCode').firstOrDefault();
                                    effectiveScope[varList[i]] = !!userHasProfile;
                                }
                                if (!!$scope.onLoaded) {
                                    $scope.onLoaded();
                                }
                            }
                        });
                }
            },
        };
    }
})();
