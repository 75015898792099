(function() {
    'use strict'

    angular.module('app.InitialApplication')
    .directive('licenseAddlInfoValidation', [LicenseAdditionalInfoValidation]);

    function LicenseAdditionalInfoValidation() {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function () {
                    var errMsgs = [];

                    if (!!$scope.current.additionalInfo.deaNumberQuestion) {
                        if (!$scope.current.additionalInfo.AddInfoTypeValue1) {
                            errMsgs.push('You must supply a DEA Number.');
                        }
                    }

                    if (!!$scope.current.additionalInfo.snNumberQuestion) {
                        if (!$scope.current.additionalInfo.AddInfoTypeValue2) {
                            errMsgs.push('You must supply a State Narcotics Number.');
                        }
                    }

                    if (errMsgs.length > 0) {
                        $scope.showStatusMsg('-', errMsgs);
                    }

                    return errMsgs.length == 0;
                };
            }
        };
    }
})();