(function () {
    'use strict'

    angular.module('natApp')
        .factory('userLinkingService', userLinkingService)

    userLinkingService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function userLinkingService($http, $q, WebApiUrl, AppConfig) {

        return {
            getUser: _getUser,
            saveUser: _saveUser,
            getIndividualLicense: _getIndividualLicense,
            getUserByIndividualId: _getUserByIndividualId,
            externalUserUsersSave:_externalUserUsersSave,
            getUserByIndividualIdExternal:_getUserByIndividualIdExternal
        };

        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function _getUser(Key, userId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Users/UsersGetBYID/' + Key + '?ID=' + userId));
        };

        function _saveUser(key, user) {
            return returnDefferedResult($http.post(WebApiUrl + 'Users/UsersSave/' + key + '?Source=' + AppConfig.Source, user));
        };

        function _getUserByIndividualId(Key, IndividualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Users/UserByIndividualId/' + Key + '?IndividualId=' + IndividualId + '&UserId=0'));
        };
        function _externalUserUsersSave(key, user) {
            return returnDefferedResult($http.post(WebApiUrl + 'Users/ExternalUserUsersSave/' + key + '?Source=' + AppConfig.Source, user));
        };
        function _getUserByIndividualIdExternal(Key, IndividualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Users/UserByIndividualIdExternal/' + Key + '?IndividualId=' + IndividualId+'&UserId=0'));
        };
        function _getIndividualLicense(key, individualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualLicenseDetailBYIndividualId/' + key + '?IndividualId=' + individualId));
        };

    }
})();