(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('renewalType', renewalTypeDirective);

    renewalTypeDirective.$inject = [];

    function renewalTypeDirective() {
        return {
            restrict: 'E',
            scope: {
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                applicationId: "=applicationId",
                individualId: "=individualId",
                natValidation: "=natValidation",
                individualLicense: "=individualLicense",
                feeDetails: "=feeDetails",
                renewalCheckData: "=renewalCheckData",
                proceedToRenewalApp: "&proceedToRenewalApp",
                setShowRenewalType: "&setShowRenewalType",
                showRenewalTypeEnabled: "=showRenewalTypeEnabled",
                tab: "=tab",
                openNextTab: "&",
                applicationTypeId: '=?',
                configName: '@',
                applicableGroups: '=?'
            },
            templateUrl: "app/components/individual/application/directives/renewal-type/renewal-type.html",
            controller: "RenewalTypeController"
        }
    }
})();