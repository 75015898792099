(function () {
    'use strict'

    angular.module('app.core')
    .directive('bulkUploadLogic', [BulkUploadLogic]);

    function BulkUploadLogic() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.initExt = function () {
                    
                };
            }
        };
    }
})();