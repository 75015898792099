﻿(function () {
    'use strict'
    angular.module('app.core')
        .controller('DashboardconfigController', ['$rootScope', '$scope', '$controller', 'DashboardconfigFactory', '$q', 'Messages', function ($rootScope, $scope, $controller, DashboardconfigFactory, $q, Messages) {
            $controller('GlobalController', { $scope: $scope });
            var _Key = '';
            var _UserId = '';
            $scope.IndividualId = 0;
            $scope.FirstName = '';
            $scope.LastName = '';
            $scope.DashboardPage = {};
            // page init method
            $scope.init = function () {
                try {
                    if ($scope.isSessionActive()) {
                        _Key = sessionStorage.Key;
                        _UserId = sessionStorage.UserID;
                        $scope.IndividualId = sessionStorage.IndividualId;
                        $scope.FirstName = sessionStorage.FirstName !== 'null' ? sessionStorage.FirstName : '';
                        $scope.LastName = sessionStorage.LastName != 'null' ? sessionStorage.LastName : '';
                        ConfigurationGetbySettings();
                        getContactDetails();
                        getAddressDetails();
                        ContactTypeGetAll();
                        getStateList($rootScope.DefaultCountryId);
                    }
                    else {
                        $state.go('app.Loginwemail');
                    }
                }
                catch (ex) {
                    showStatusMessage(ex.message, "error");
                }
            }

            var ConfigurationGetbySettings = function () {
                DashboardconfigFactory.ConfigurationGetbySettings(_Key, 'ConfirmDateonLogin').then(function (res) {
                    if (res && res.data.Status && res.data.ConfigurationList && res.data.ConfigurationList.length > 0) {
                        var setVal = (res.data.ConfigurationList[0].Value && res.data.ConfigurationList[0].Value.toLowerCase() == 'true');
                        // if (setVal) {
                        //     $scope.DashboardPage.ShowPopup = true;
                        //     $scope.DashboardPage.DisplayInfo = true;
                        // } else {
                        //     $scope.DashboardPage.ShowPopup = false;
                        // }
                    }
                });
            };

            var saveAddressDetails = function (data) {
                var d = $q.defer();
                if (data) {
                    DashboardconfigFactory.SaveAddressDetails(_Key, data).then(function (res) {
                        d.resolve(res);
                    }, function () { HideLoader() });
                } else {
                    d.reject(res);
                }
                return d.promise;
            };

            var saveContactDetails = function (data) {
                var d = $q.defer();
                if (data) {
                    DashboardconfigFactory.SaveContactDetails("/" + _Key + '?Source=', data).then(function (res) {
                        d.resolve(res);
                    }, function () { HideLoader() });
                } else {
                    d.reject(res);
                }
                return d.promise;
            };

            $scope.YesLooksCorrect = function () {
                $scope.PopupCancelClick();
            };

            $scope.PopupEditClick = function () {
                $scope.DashboardPage.DisplayInfo = false;
                $scope.DashboardPage.DisplayEdit = true;
                $scope.EditHomeAddress = angular.copy($scope.HomeAddress);
                $scope.EditMailingAddress = angular.copy($scope.MailingAddress);
                setContactForEdit();
            };

            $scope.PopupSaveClick = function () {
                if (!validateData('dashboardpopup'))
                    return;
                //save Address details & contactDetails
                if (!$scope.EditHomeAddress || !$scope.EditHomeAddress.IndividualAddressId || $scope.EditHomeAddress.IndividualAddressId == 0) {
                    $scope.EditHomeAddress.AddressId = 0;
                    $scope.EditHomeAddress.IndividualId = $scope.IndividualId;
                    $scope.EditHomeAddress.AddressTypeId = 2;
                    $scope.EditHomeAddress.IsMailingSameasPhysical = 0;
                    $scope.EditHomeAddress.IsActive = 1;
                    $scope.EditHomeAddress.IsDeleted = 0;
                }
                if (!$scope.EditMailingAddress || !$scope.EditMailingAddress.IndividualAddressId || $scope.EditMailingAddress.IndividualAddressId == 0) {
                    $scope.EditMailingAddress.AddressId = 0;
                    $scope.EditMailingAddress.IndividualId = $scope.IndividualId;
                    $scope.EditMailingAddress.AddressTypeId = 1;
                    $scope.EditMailingAddress.IsMailingSameasPhysical = 0;
                    $scope.EditMailingAddress.IsActive = 1;
                    $scope.EditMailingAddress.IsDeleted = 0;
                }
                var addressData = [$scope.EditHomeAddress, $scope.EditMailingAddress];
                var ContactList = [];
                //ContactData
                //CellPhone
                if ($scope.CellPhone && $scope.CellPhone.ContactInfo) {
                    var ContactDetails = {};
                    if ($scope.CellPhone.IndividualContactId && $scope.CellPhone.IndividualContactId != 0) {
                        ContactDetails = angular.copy($scope.CellPhone);
                    } else {
                        var ContactDetails = GetNewContact($scope.CellPhone.ContactInfo, 1);
                    }
                    ContactList.splice(0, 0, ContactDetails);
                }
                //PrimaryEmail
                if ($scope.PrimaryEmail && $scope.PrimaryEmail.ContactInfo) {
                    var ContactDetails = {};
                    if ($scope.PrimaryEmail.IndividualContactId && $scope.PrimaryEmail.IndividualContactId != 0) {
                        ContactDetails = angular.copy($scope.PrimaryEmail);
                    } else {
                        var ContactDetails = GetNewContact($scope.PrimaryEmail.ContactInfo, 18);
                    }
                    ContactList.splice(0, 0, ContactDetails);
                }
                //SecondaryPhone
                if ($scope.SecondaryPhone && $scope.SecondaryPhone.ContactInfo) {
                    var ContactDetails = {};
                    if ($scope.SecondaryPhone.IndividualContactId && $scope.SecondaryPhone.IndividualContactId != 0) {
                        ContactDetails = angular.copy($scope.SecondaryPhone);
                    } else {
                        var ContactDetails = GetNewContact($scope.SecondaryPhone.ContactInfo, 7);
                    }
                    ContactList.splice(0, 0, ContactDetails);
                }
                //SecondaryEmail
                if ($scope.SecondaryEmail && $scope.SecondaryEmail.ContactInfo) {
                    var ContactDetails = {};
                    if ($scope.SecondaryEmail.IndividualContactId && $scope.SecondaryEmail.IndividualContactId != 0) {
                        ContactDetails = angular.copy($scope.SecondaryEmail);
                    } else {
                        var ContactDetails = GetNewContact($scope.SecondaryEmail.ContactInfo, 19);
                    }
                    ContactList.splice(0, 0, ContactDetails);
                }
                ShowLoader("Please wait...");
                $q.all([saveAddressDetails(addressData), saveContactDetails(ContactList)]).then(function (res) {
                    alert(Messages.General.Success.Saved);
                    $scope.PopupCancelClick();
                    HideLoader();
                }, function () { HideLoader() });
            };

            $scope.PopupCancelClick = function () {
                $scope.DashboardPage.ShowPopup = false;
                $scope.DashboardPage.DisplayInfo = false;
                $scope.DashboardPage.DisplayEdit = false;
            };

            var GetNewContact = function (info, type) {
                return {
                    IndividualContactId: 0,
                    IndividualId: $scope.IndividualId,
                    IsMobile: 0,
                    ContactId: 0,
                    ContactTypeId: type,
                    ContactInfo: info,
                    CreatedBy: _UserId,
                    ContactStatusId: 18,
                    IsActive: 1,
                    IsDeleted: 0
                };
            };

            var getContactDetails = function () {
                DashboardconfigFactory.GetContactDetails(_Key, $scope.IndividualId).then(function (res) {
                    if (res.data) {
                        $scope.ContactDetailsList = res.data.IndividualContactResponse;
                        setContactType();
                    }
                });
            };

            var ContactTypeGetAll = function () {
                DashboardconfigFactory.ContactTypeGetAll(_Key).then(function (res) {
                    if (res.data) {
                        $scope.ContactTypeAll = res.data.ContactTypeGetList;
                        setContactType();
                    }
                });
            };

            var setContactType = function () {
                if ($scope.ContactTypeAll && $scope.ContactDetailsList) {
                    for (var i = 0; i < $scope.ContactDetailsList.length; i++) {
                        for (var j = 0; j < $scope.ContactTypeAll.length; j++) {
                            if ($scope.ContactTypeAll[j].ContactTypeId == $scope.ContactDetailsList[i].ContactTypeId) {
                                $scope.ContactDetailsList[i].ContactTypeDesc = $scope.ContactTypeAll[j].Desc;
                            }
                        }
                    }
                }
            };

            var setContactForEdit = function () {
                if ($scope.ContactDetailsList) {
                    for (var i = 0; i < $scope.ContactDetailsList.length; i++) {
                        if ($scope.ContactDetailsList[i].ContactTypeId == 1) {
                            $scope.CellPhone = angular.copy($scope.ContactDetailsList[i]);
                        }
                        if ($scope.ContactDetailsList[i].ContactTypeId == 18) {
                            $scope.PrimaryEmail = angular.copy($scope.ContactDetailsList[i]);
                        }
                        if ($scope.ContactDetailsList[i].ContactTypeId == 7) {
                            $scope.SecondaryPhone = angular.copy($scope.ContactDetailsList[i]);
                        }
                        if ($scope.ContactDetailsList[i].ContactTypeId == 19) {
                            $scope.SecondaryEmail = angular.copy($scope.ContactDetailsList[i]);
                        }
                    }
                }
            };

            var getAddressDetails = function () {
                var urlParam = "/" + _Key + "?IndividualId=" + $scope.IndividualId;
                DashboardconfigFactory.GetAddressDetails(urlParam).then(function (res) {
                    var indvRes = res.data.IndividualAddressResponse;
                    $scope.MailingAddress = getAddressbyType(indvRes, 1);
                    $scope.HomeAddress = getAddressbyType(indvRes, 2);
                    //if mailing address is empty and mailing address is same as home address. Show home address both the place
                    if (!$scope.MailingAddress) {
                        if ($scope.HomeAddress && $scope.HomeAddress.IsMailingSameasPhysical) {
                            $scope.MailingAddress = angular.copy($scope.HomeAddress);
                        }
                    }
                    setStateName();
                });
            };

            var getAddressbyType = function (addList, type) {
                var res;
                if (addList && addList.length > 0) {
                    for (var i = 0; i < addList.length; i++) {
                        if (addList[i].AddressTypeId == type) {
                            return addList[i];
                        }
                    }
                }
                return res;
            };

            var getStateList = function (countryID) {
                DashboardconfigFactory.GetStateList(_Key, countryID).then(function (res) {
                    $scope.StateList = res.data.State;
                    setStateName();
                });
            };

            var setStateName = function () {
                if ($scope.StateList && $scope.StateList.length > 0) {
                    //set home state
                    if ($scope.HomeAddress) {
                        var len = $scope.StateList.length;
                        for (var i = 0; i < len; i++) {
                            if ($scope.StateList[i].StateCode == $scope.HomeAddress.StateCode) {
                                $scope.HomeAddress.StateName = $scope.StateList[i].Name;
                                break;
                            }
                        }
                    }
                    //set mailing State
                    if ($scope.MailingAddress) {
                        var len = $scope.StateList.length;
                        for (var i = 0; i < len; i++) {
                            if ($scope.StateList[i].StateCode == $scope.MailingAddress.StateCode) {
                                $scope.MailingAddress.StateName = $scope.StateList[i].Name;
                                break;
                            }
                        }
                    }
                };
            };
        }]);
})();

