(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .directive('telemedicineValidation', ['Messages', TelemedicineValidationDirective]);

    function TelemedicineValidationDirective(Messages) {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function () {
                    $scope.natValidation.errorMessages = [];
                    $scope.invaliddate = false;
                    if (!!$scope.currentForm) {
                        $scope.currentForm.$submitted = true;
                        $scope.natValidation.errorMessages = angular.copy($scope.currentForm.errorMessages);
                        if (!$scope.natValidation.errorMessages) {
                            $scope.natValidation.errorMessages = [];
                        }
                    }
                    if (!!$scope.applicationTypeId && $scope.applicationTypeId == 2) {
                        if (!!$scope.current.telemedicine.IsTeleMedicineUsed) {
                            if ($scope.current.telemedicine.CompletedOnlineTeleMedicineCourse == null) {
                                $scope.natValidation.errorMessages.push({
                                    propName: 'CompletedOnlineTeleMedicineCourse',
                                    errorMessage: Messages.abdfade
                                });
                            }
                            if ($scope.current.telemedicine.PracticingTeleMedicineinState == null) {
                                $scope.natValidation.errorMessages.push({
                                    propName: 'PracticingTeleMedicineinState',
                                    errorMessage: Messages.abdfade
                                });
                            }
                            if ($scope.current.telemedicine.PracticingTeleMedicineAcrossState == null) {
                                $scope.natValidation.errorMessages.push({
                                    propName: 'PracticingTeleMedicineAcrossState',
                                    errorMessage: Messages.abdfade
                                });
                            }
                            if (!$scope.current.telemedicine.TeleMedicineUseDesc) {
                                $scope.natValidation.errorMessages.push({
                                    propName: 'TeleMedicineUseDesc',
                                    errorMessage: Messages.abdfade
                                });
                            }
                            if (!$scope.current.telemedicine.CustodianStreetLine1 ||
                                !$scope.current.telemedicine.CustodianCity ||
                                !$scope.current.telemedicine.CustodianState ||
                                !$scope.current.telemedicine.CustodianZip) {
                                $scope.natValidation.errorMessages.push({
                                    propName: 'CustodianAddress',
                                    errorMessage: Messages.abdfade
                                });
                            }
                            if (!$scope.current.telemedicine.DescProcedureArrangement) {
                                $scope.natValidation.errorMessages.push({
                                    propName: 'DescProcedureArrangement',
                                    errorMessage: Messages.abdfade
                                });
                            }
                        }
                    }

                    if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                        $scope.natValidation.showError = true;
                        $scope.natValidation.isValid = false;
                        return false;
                    } else {
                        $scope.natValidation.showError = false;
                        $scope.natValidation.isValid = true;
                        return true;
                    }
                };
            }
        };
    }
})();