/// <reference path="individual-merging.ts" />
/// <reference path="../../../../scripts/built-in-type-extensions.ts" />

(function() {
    'use strict'

    angular.module('app.merging')
    .controller('IndividualMergingController', ['$scope', 'IndividualMergingService', IndividualMergingController]);

    /**
     * Controller function
     * 
     * @param {IndividualMergingControllerScope} $scope
     * @param {IndividualMergingService} IndividualMergingService
     */
    function IndividualMergingController($scope, IndividualMergingService) {
        var _key = sessionStorage.Key;

        /**
         * Resets the search result criteria back to defaults.
         */
        var resetSearchCriteria = function () {
            $scope.searchCriteria = {
                IndividualId: '',
                FirstName: '',
                LastName: '',
                DateOfBirth: '',
                SSN: '',
                LicenseNumber: ''
            };

            $scope.paginationParams = {
                showPager: true,
                totalRecords: 0,
                currentPage: 1,
                pageSize: 20,
                pageChanged: $scope.pageChanged
            };

            $scope.sortParams = {
                sortBy: '',
                sortOrder: 'asc'
            };
        };

        /**
         * This function will replace the same individual object in the list with the one that is passed in.
         * 
         * The reason for this method is so the UI can display the Remove/Select button appropriately across
         * search results.
         * @param {Individual[]} individualList 
         * @param {Individual} individual 
         */
        var replaceReference = function (individualList, individual) {
            var index = individualList.findIndex(function (i) { return i.IndividualId == individual.IndividualId });
            if(index >=0 ) {
                individualList[index] = individual;
            }
        };

        /**
         * Searches the database for individuals to merge.
         */
        var searchIndividuals = function () {
            try {
                $scope.operationPending(true, "Searching...");

                /**
                 * @type {SearchForIndividualsRequest}
                 */
                var request = {
                    SearchCriteria: $scope.currentSearchCriteria,
                    Fuzzy: $scope.fuzzySearch,
                    Page: $scope.paginationParams.currentPage,
                    Take: $scope.paginationParams.pageSize,
                    SortBy: $scope.sortParams.sortBy,
                    SortOrder: $scope.sortParams.sortOrder
                };

                IndividualMergingService.searchForIndividuals(_key, request)
                .then(function (data) {
                    if(data.Status) {
                        $scope.individualList = data.Individuals;
                        if($scope.mergeInto) {
                            replaceReference($scope.individualList, $scope.mergeInto);
                        }
                        $scope.mergeList.forEach(function (m) {
                            replaceReference($scope.individualList, m);
                        });
                        $scope.paginationParams.totalRecords = data.TotalNumberOfRecords;
                    } else {
                        $scope.showStatusMsg('error', data.Message);
                    }

                    $scope.operationPending(false);
                    $scope.showStatusMsg('', 'Retrieved the list of individuals.');
                }, function (error) {
                    $scope.showStatusMsg('error', 'There was an error retrieving the list of individuals.');
                    console.log(error);
                    $scope.operationPending(false);
                });
            } catch (ex) {
                $scope.showStatusMsg('error', ex.message);
                $scope.operationPending(false);
            }
        };

        /**
         * Merges the selected individuals in the database.
         */
        var mergeIndividuals = function () {
            try {
                $scope.displayMainLoader(true, 'Merging...');

                /**
                 * @type {MergeIndividualsRequest}
                 */
                var request = {
                    MergeInto: $scope.mergeInto.IndividualId,
                    IDs: $scope.mergeList.select('IndividualId')
                };

                IndividualMergingService.mergeIndividuals(_key, request)
                .then(function (data) {
                    if(data.Status) {
                        $scope.clearSearchClicked();
                        $scope.clearMergeClicked();
                        
                        $scope.showStatusMsg('success', 'Merge completed successfully!');
                    } else {
                        $scope.showStatusMsg('error', data.Message);
                    }
                    
                    $scope.displayMainLoader(false);
                }, function (error) {
                    $scope.showStatusMsg('error', 'There was an error during the merging process.');
                    console.log(error);
                    $scope.displayMainLoader(false);
                });
            } catch (ex) {
                $scope.showStatusMsg('error', ex.message);
                $scope.displayMainLoader(false);
            }
        };

        $scope.init = function () {
            resetSearchCriteria();

            $scope.currentSearchCriteria = angular.copy($scope.searchCriteria);

            $scope.tableHeaders = {
                IndividualId: {
                    label: 'ID',
                    sizeRatio: 0.5
                },
                FirstName: {
                    label: 'First Name',
                    sizeRatio: 1.5
                },
                LastName: {
                    label: 'Last Name',
                    sizeRatio: 1.5
                },
                DateOfBirth: {
                    label: 'Date Of Birth',
                    templateUrl: 'mergingDate.html'
                },
                SSN: {
                    label: 'SSN'
                },
                LicenseNumber: {
                    label: 'License #'
                },
                Action: {
                    label: 'Action',
                    templateUrl: 'selectButton.html',
                    sizeRatio: 0.5
                },
            };

            $scope.individualList = [];
            $scope.mergeInto = null;
            $scope.mergeList = [];
            $scope.tableEvents = {
                selectIndividual: $scope.selectIndividual,
                removeMergeIndividual: $scope.removeMergeIndividual
            };
        };

        $scope.selectIndividual = function (individual) {
            individual.selected = true;
            if (!$scope.mergeInto) {
                $scope.mergeInto = individual;
            } else {
                $scope.mergeList.push(individual);
            }
        };

        $scope.sort = function (params) {
            searchIndividuals();
        };

        $scope.pageChanged = function (newPage) {
            searchIndividuals();
        };

        $scope.searchClicked = function () {
            $scope.currentSearchCriteria = angular.copy($scope.searchCriteria);
            
            searchIndividuals();
        };

        $scope.clearSearchClicked = function () {
            resetSearchCriteria();

            $scope.individualList = [];
        };

        $scope.removeMergeInto = function () {
            if($scope.mergeInto) {
                $scope.mergeInto.selected = false;
                $scope.mergeInto = null;
            }
        };

        $scope.removeMergeIndividual = function (individual) {
            individual.selected = false;
            if(individual == $scope.mergeInto) {
                $scope.removeMergeInto();
            } else {
                $scope.mergeList.conditionalSplice('item.IndividualId == this', individual.IndividualId);
            }
        };

        $scope.mergeClicked = function () {
            mergeIndividuals();
        };

        $scope.clearMergeClicked = function () {
            $scope.removeMergeInto();
            $scope.mergeList.forEach(function (m) {
                m.selected = false;
            });
            $scope.mergeList = [];
        };
    }
})();