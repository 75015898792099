(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('providerWarningService', providerWarningService)
        providerWarningService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']
    function providerWarningService($http, $q, WebApiUrl, AppConfig) {

        var _getProviderOwnership = function (Key, IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Provider/GetProviderOwnership/' + Key + '?ProviderId=' + IndividualId + "&IndividualId=null&ProvIndvNameOwnershipId=null")
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveProviderOwnership = function (Key, data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/SaveProviderOwnership/' + Key + '?Source=' + AppConfig.Source, data)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getEntityOwnership = function (Key, providerId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Entity/GetEntityOwnership/' + Key + '?EntityId=' + providerId + "&ApplicationId=null")
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveEntityOwnership = function (Key, data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Entity/SaveEntityOwnership/' + Key + '?Source=' + AppConfig.Source, data)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getProviderOwnershipNew = function (Key, ProviderOwnershipId, ProviderId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'ProvidersOwnership/GetProvidersOwnership/' + Key + '?ProviderOwnershipId=' + ProviderOwnershipId + '&ProviderId=' + ProviderId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _saveProviderOwnershipNew = function (Key, data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'ProvidersOwnership/SaveProvidersOwnership/' + Key , data)
                .then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.reject(response);
                });
            return deferred.promise;
        };

        function _getwarning(Key, entityId, entityType) {
            return returnDefferedResult($http.get(WebApiUrl + 'Complaint/GetDiscipline/' + Key + '?EntityId=' + entityId + '&EntityTy=' + entityType));
        };

        return {
            getProviderOwnership: _getProviderOwnership,
            saveProviderOwnership: _saveProviderOwnership,
            saveEntityOwnership: _saveEntityOwnership,
            getEntityOwnership: _getEntityOwnership,
            getProviderOwnershipNew: _getProviderOwnershipNew,
            saveProviderOwnershipNew: _saveProviderOwnershipNew,
            getwarning: _getwarning
        }
    }
})();