(function() {
    'use strict'

    angular.module('app.report')
        .service('ReportDecorators', [function () {
            this.Obsolete = function (fn) {
                if(typeof(fn) !== 'function') {
                    throw { message: 'Invalid parameter: not a function. ' };
                }
                var obsolete = function() {
                    console.log("Function is obsolete: " + fn.name);
                    return fn.apply(null, arguments);
                };

                return obsolete;
            };

            this.Deprecated = function (fn) {
                if(typeof(fn) !== 'function') {
                    throw { message: 'Invalid parameter: not a function. ' };
                }
                var deprecated = function() {
                    console.log("Function is deprecated: " + fn.name);
                    return fn.apply(null, arguments);
                };

                return deprecated;
            };
        }])
        .factory('QueryReportingService', ['$http', '$q', 'utilityService', 'WebApiUrl', QueryReportingService]);

    function QueryReportingService($http, $q, utilityService, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log("Request failed with status: " + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };
    
        var _getQueryReportingEntityList = function (key) {        
            return responseHandler($http.get(WebApiUrl + 'Report/GetQueryReportingEntityList/' + key + '/'));
        };
    
        var _getTableRelationByTable = function (key,tableName) {
            var parameters = { TableName: tableName };
            return responseHandler($http.get(WebApiUrl + 'Report/GetTableRelationByTable/' + key + '/', { params: parameters }));
        };
    
        var _getAvailableExportDocumentTypes = function (key) {
            return responseHandler($http.get(WebApiUrl + 'Report/GetAvailableExportDocumentTypes/' + key + '/'));
        };
        
        var _getTableRelationPaths = function (key) {
            return responseHandler($http.get(WebApiUrl + 'Report/GetTableRelationPaths/' + key +'/'));
        };
    
        var _getSavedReportingQueries = function (key, query) {
            if(query == undefined) {
                query = null;
            }
            return responseHandler($http.get(WebApiUrl + 'Report/GetSavedReportingQueries/' + key + '/', { params: query }));
        };

        var _deleteReportingQuery = function (key, query) {
            return responseHandler($http.get(WebApiUrl + 'Report/DeleteSavedReportingQuery/' + key + '/', { params: query }));
        };
    
        var _saveReportingQuery = function (key, request) {
            return responseHandler($http.post(WebApiUrl + 'Report/SaveReportingQuery/' + key + '/', JSON.stringify(request)));
        };
    
        var _updateReportingQuery = function (key, request) {
            return responseHandler($http.post(WebApiUrl + 'Report/UpdateReportingQuery/' + key + '/', JSON.stringify(request)));
        };
    
        var _executeQueryAndGetReportTest = function (key, data) {
            return responseHandler($http.post(WebApiUrl + 'Report/ExecuteQueryAndGetReportTest/' + key + '/', JSON.stringify(data)));
        };
    
        var _executeQueryAndGetDocumentTest = function (key, data) {
            var httpPromise = $http.post(WebApiUrl + 'Report/ExecuteQueryAndGetDocumentTest/' + key + '/', JSON.stringify(data), {
                responseType: 'arraybuffer'
            });
            return responseHandler(httpPromise, function(res){
                var headers = res.headers();
                utilityService.downloadExcelFile(res.data, headers);
            });
        };
    
        var _executeBulkEmailOnQuery = function (key, data) {
            return responseHandler($http.post(WebApiUrl + 'Report/ExecuteBulkEmailOnQuery/' + key + '/', JSON.stringify(data)));
        };
    
        var _getPossibleValuesForColumn = function (key, tableName, columnName) {
            var parameters = { tableName: tableName, columnName: columnName };
            return responseHandler($http.get(WebApiUrl + 'Report/GetPossibleValuesForColumn/' + key + '/', { params: parameters }));
        };
    
        var _getAvailableConditionOperators = function (key, columnDataType) {
            var parameters = { columnDataType: columnDataType };
            return responseHandler($http.get(WebApiUrl + 'Report/GetAvailableConditionOperators/' + key + '/', { params: parameters }));
        };
    
        var _getAvailableFunctions = function (key) {
            return responseHandler($http.get(WebApiUrl + 'Report/GetAvailableFunctions/' + key + '/'));
        };

        var _getGeneratedSQL = function (key, data) {
            return responseHandler($http.post(WebApiUrl + 'Report/GetGeneratedSQL/' + key + '/', data));
        };

        return {
            getQueryReportingEntityList: _getQueryReportingEntityList,
            getTableRelationByTable: _getTableRelationByTable,
            getAvailableExportDocumentTypes: _getAvailableExportDocumentTypes,
            getTableRelationPaths: _getTableRelationPaths,

            getSavedReportingQueries: _getSavedReportingQueries,
            deleteReportingQuery: _deleteReportingQuery,
            saveReportingQuery: _saveReportingQuery,
            updateReportingQuery: _updateReportingQuery,
            
            executeQueryAndGetReportTest: _executeQueryAndGetReportTest,
            executeQueryAndGetDocumentTest: _executeQueryAndGetDocumentTest,
            executeBulkEmailOnQuery: _executeBulkEmailOnQuery,

            getPossibleValuesForColumn: _getPossibleValuesForColumn,
            getAvailableConditionOperators: _getAvailableConditionOperators,
            getAvailableFunctions: _getAvailableFunctions,
            getGeneratedSQL: _getGeneratedSQL
        };
    }
})();