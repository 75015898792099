(function () {
    'use strict'

    angular.module('reporting.FieldSearchReporting')
    .factory('FieldSearchReportingUtility', ['$rootScope', '$filter', 'QueryReportingUtility', FieldSearchReportingUtility]);

    function FieldSearchReportingUtility($rootScope, $filter, QueryReportingUtility) {
        var _updateMultiValueSelect = function (condition) {
            return condition.V1.Value = condition.Column.possibleValues.where('item.isSelected').select('value').join(',');
        };  

        var selectValues = function (condition) {
            var cdList = [];
            
            cdList = condition.V1.Value.split(',').concat(condition.V2.Value.split(','));
            
            condition.Column.possibleValues.forEach(function (v) { if(v.getJSType() != 'String') { v.isSelected = false; } });

            cdList.forEach(function (v) {
                var val = condition.Column.possibleValues.whereEquals(v, 'value').firstOrDefault();
                if(val != null) {
                    val.isSelected = true;
                }
            });
        };

        var _setupOperatorValue = function (condition, tableList) {
            if(condition.Column.reference) {
                $rootScope.$evalAsync(function() {condition.isOpen = true;});
                if(condition.Column.possibleValues.length == 0 && !condition.Column.retrievingValues) {
                    QueryReportingUtility.getPossibleValuesForColumn(condition.Column, null, tableList)
                    .then(function () { selectValues(condition); });
                } else {
                    selectValues(condition);
                }
                QueryReportingUtility.addTypeaheadClasses();
            }

            if(condition.Column.availableOperators.length == 0 && !condition.Column.retrievingOperators) {
                QueryReportingUtility.getAvailableConditionOperators(condition.Column, true);
            }
        };

        var _createConditionWatch = function (condition) {
            //Monitor any changes to the condition
            var removeWatch = $rootScope.$watch(function() { return condition.Operator.concat(condition.V1.Value).concat(condition.V2.Value); }, function (newVal, oldVal) {
                if(typeof(newVal) === 'undefined' || newVal == null) {
                    condition.hasValue = false;
                    return;
                }

                condition.Value = condition.V1.Value;
                condition.Value2 = condition.V2.Value;

                if (condition.Operator == 'between') {
                    if (condition.Value.length > 0 && condition.Value2.length > 0) {
                        condition.hasValue = true;
                    } else {
                        condition.hasValue = false;
                    }        
                } else if (['is null', 'is not null'].includes(condition.Operator)) {
                    condition.hasValue = true;
                } else {
                    if (condition.Value.length > 0 && condition.Operator.length > 0) {
                        condition.hasValue = true;
                    } else {
                        condition.hasValue = false;
                    }
                }

                if (!condition.V1.Value && !!condition.possibleValues && condition.possibleValues.length > 0) {
                    _updateMultiValueSelect(condition);
                }
            });

            return removeWatch;
        };

        var _clearCondition = function (condition) {
            condition.Operator = '';
            condition.V1.Value = '';
            condition.V2.Value = '';

            if (condition.Column.reference) {
                condition.Operator = 'in';
                condition.Column.possibleValues.forEach(function (pv) {
                    pv.isSelected = false;
                });
                $rootScope.$evalAsync(function() {condition.isOpen = true;});
            } else {
                var dataType = $filter('qrDataTypeMap')(condition.Column.FieldDataType);

                switch (dataType) {
                    case 'text': 
                        condition.Operator = 'contains'; 
                        break;
                    case 'datetime': 
                        condition.Operator = 'between'; 
                        break;
                    default: 
                        condition.Operator = 'equal to'; 
                        break;
                }
            }
        };

        return {
            updateMultiValueSelect: _updateMultiValueSelect,
            setupOperatorValue: _setupOperatorValue,
            createConditionWatch: _createConditionWatch,
            clearCondition: _clearCondition
        };
    }
})();