﻿(function () {
    'use strict';
    angular
        .module('app.account')
        .controller("StaffLoginController", StaffLoginController);

    StaffLoginController.$inject = ["$scope", "$state", "utilityService", "loginStaffService", "LoginwemailService"];

    function StaffLoginController($scope, $state, utilityService, loginStaffService, LoginwemailService) {
        // Variables
        $scope.loginData = {};
        $scope.natValidation = {};
        $scope.OTPType = "Email";
        // page init method
        $scope.init = function () {
            if (!!$scope.isStaffLoginView) {
                HideLoader();
                sessionStorage.clear();
                sessionStorage.LoginPage = 'app.BackofficeLoginstaff';
                sessionStorage.logCurrentIndividualId = null;
                sessionStorage.Key = "";
                sessionStorage.isBackOfficeUser = false;
                sessionStorage.LoginURL = window.location.href;
            }
            var relativeFilePath = "/components/account/login/staff/login-staff.config.json";
            utilityService.getConfigData(relativeFilePath).then(function (data) {
                $scope.loginStaffConfig = data;
            }, function (error) {
                showStatusMessage(error.message, "error");
            })
        };

        $scope.validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                if (!!currentForm.errorMessages)
                    $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.sendOTP = function (loginForm) {
            try {
                $scope.ShowInvalidUserError = false;
                if (!$scope.validateForm(loginForm)) {
                    var errorMessages = [];
                    angular.forEach($scope.natValidation.errorMessages, function (errorMessageItem) {
                        errorMessages.push(errorMessageItem.errorMessage);
                    });
                    $scope.showStatusMsg("-", errorMessages);
                    return;
                } else {
                    if (!$scope.loginData.otpGenerated) {
                        if ($scope.isOTPEnabled) {
                            $scope.loginStaffConfig.templateUrl = "app/components/account/login/staff/partial/login-staff-authentication.html";
                            ValidateUserEmailandSendOTP();
                        } else {
                            $scope.login();
                        }
                    } else if ($scope.loginData.otpGenerated) {
                        VerifyOTP();
                    }
                }

            }
            catch (ex) {
                $scope.showStatusMessage(ex.message, "error");
            }
        };

        var getOTPConfigurationSetting = function () {
            LoginwemailService.ConfigurationGetbySettings('key', 'EnableLoginOTP').then(function (res) {
                if (res && res.data.Status && res.data.ConfigurationList && res.data.ConfigurationList.length > 0) {
                    $scope.isOTPEnabled = (res.data.ConfigurationList[0].Value.toLowerCase() == 'true');
                }
            });
        };
        getOTPConfigurationSetting();

        var ValidateUserEmailandSendOTP = function () {
            ShowLoader("Please wait...");
            LoginwemailService.ValidateStaffEmailandSendOTP($scope.loginData.Email, $scope.OTPType)
                .then(function (res) {
                    var response = res.data;
                    if (response) {
                        $scope.UserId = response.RecordId;
                        $scope.loginData.otpGenerated = true;
                        $scope.ShowotpGeneratedMessage = true;
                        $scope.ShowInvalidUserError = false;
                        HideLoader();
                    }
                    else if (response && response.StatusCode == '08') {
                        $scope.loginData.otpGenerated = false;
                        $scope.ShowotpGeneratedMessage = false;
                        // CallForgotPassword();
                    }
                    else if (response && response.StatusCode == '09') {
                        $scope.loginData.otpGenerated = false;
                        $scope.showStatusMessage(Messages.General.Error.OTP, "error");
                        HideLoader();
                    }
                    else {
                        $scope.loginData.otpGenerated = false;
                        $scope.ShowotpGeneratedMessage = false;
                        HideLoader();
                        $scope.ShowInvalidUserError = true;
                        $scope.showStatusMsg("-", "Messages.General.Errors.InvalidUser");
                        // $scope.showStatusMessage(Messages.General.Errors.InvalidUser, "error");
                        $scope.Password = "";
                    }
                }, function (res) {
                    HideLoader();
                    var data = res.data;
                    if (data != null) { $scope.showStatusMsg("-", data.message); }
                });
        };

        var VerifyOTP = function () {
            var reqData = {
                OTPValue: $scope.loginData.OTP,
                UserId: $scope.UserId,
                Email: $scope.loginData.Email
            }
            LoginwemailService.VerifyOTP(reqData)
                .then(function (res) {
                    if (res.data.Status) {
                        $scope.login(loginForm);
                    }
                    else {
                        HideLoader();
                        $scope.showStatusMsg("-", Messages.General.Errors.InvalidOTP);
                        $scope.Password = "";
                    }
                }, function (res) {
                    HideLoader();
                    var data = res.data;
                    if (data != null) { $scope.showStatusMsg("-", data.message); }
                });
        };

        // Login Method
        $scope.login = function () {
            $scope.hideStatusMsg();
            // if (!$scope.validateForm(loginForm)) {
            //     var errorMessages = [];
            //     angular.forEach($scope.natValidation.errorMessages, function (errorMessageItem) {
            //         errorMessages.push(errorMessageItem.errorMessage);
            //     });
            //     $scope.showStatusMsg("-", errorMessages);
            //     return;
            // } else {
            $scope.loadingStaffLogin(true, "Please wait...");
            loginStaffService.login($scope.loginData.Email, $scope.loginData.Password, false)
                .then(function (response) {
                    if (response.Status) {
                        var loginVerification = {};
                        if (!!$scope.isStaffLoginView) {
                            $scope.Key = response.Key;
                            sessionStorage.UserID = response.UserID;
                            sessionStorage.Key = $scope.Key;
                            sessionStorage.menuName = '';
                            sessionStorage.isBackOfficeUser = true;
                        }
                        if (response.UserInfo.TemporaryPassword == true) {
                            if (!!$scope.isStaffLoginView) {
                                sessionStorage.FirstName = response.UserInfo.FirstName;
                                sessionStorage.LastName = response.UserInfo.LastName;
                                sessionStorage.Email = $scope.loginData.Email;
                                $state.go('app.StaffChangePassword');
                            } else {
                                $scope.$dismiss({
                                    Status: false,
                                    Message: 'Staff needs to change temp password'
                                });
                                $state.go('app.BackofficeLoginstaff');
                            }
                        } else {
                            if (response.UserInfo.UserTypeName == "Staff") {
                                if (!!$scope.isStaffLoginView) {
                                    sessionStorage.MenuList = JSON.stringify(response.MenuList);
                                    sessionStorage.Key = $scope.Key;
                                    sessionStorage.FirstName = response.UserInfo.FirstName;
                                    sessionStorage.LastName = response.UserInfo.LastName;
                                    $state.go('app.BackofficeDashboardStaff');
                                } else {
                                    loginVerification.tokenKey = response.Key;
                                    loginVerification.userId = response.UserID;
                                    $scope.$parent.$close({
                                        Status: true,
                                        Message: 'User Verification Successfull',
                                        loginVerification: loginVerification
                                    });
                                }
                            } else {
                                $scope.showStatusMsg("-", "Invalid UserName Or Password");
                                $scope.loginData.Password = "";
                            }
                        }
                    } else {
                        $scope.showStatusMsg("-", response.Message);
                        $scope.loginData.Password = "";
                    }
                    $scope.loadingStaffLogin(false);
                }, function (data) {
                    $scope.showStatusMsg("-", data.message);
                });
            // }
        };

        $scope.cancel = function () {
            $scope.$parent.$dismiss({
                result: 'cancel',
                data: 10
            });
        };
    }
})();