﻿(function () {
    'use strict'
    angular.module('app.core')
        .factory('providerDashboardApplicationDetailsService', ['$http', 'WebApiUrl', '$q', 'AppConfig', function ($http, WebApiUrl, $q, AppConfig) {
            var baseFactory = {};
            baseFactory.applicationDocumentByProviderId = function (key, ProviderId) {
                return $http.get(WebApiUrl + 'Provider/ApplicationDocumentByProviderId/' + key + '?ProviderId=' + ProviderId);
            }

            baseFactory.deleteApplication = function (key, applicationId) {
                var urlParams = {
                    IndividualId: 0,
                    IsBackOffice: (!!sessionStorage.isBackOfficeUser) ? (sessionStorage.isBackOfficeUser == 'true' || sessionStorage.isBackOfficeUser == true) : false,
                    ApplicationId: applicationId,
                    IndividualLicenseId: 0
                };
                return $http.post(WebApiUrl + 'Individual/IndividualTablesDelete/' + key + '/', {}, { params: urlParams });
            };
            return baseFactory;
    }]);
})();
