﻿(function () {
    'use strict'
    angular.module('app.core')
        .directive('dashboardDeficiencyDetailsDirective', [function () {
        return {
            restrict: 'EA',
            scope: {
                individualid: '=individualid',
                iseditable: '=iseditable'
            },
            controller: "DashboardDeficiencyDetailsController",
            templateUrl: "app/core/directive/dashboard-deficiency-details-directive/dashboard-deficiency-details-template.html",
            link: function ($scope, element, attrs, ngCtrl) {

            }
        };
    }]);
})();
