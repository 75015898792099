﻿(function () {
    'use strict';
    angular
    .module('app.InitialApplication')
        .controller("SecureCommunicationController", ['$scope', '$q', 'SecureCommunicationFactory', function ($scope, $q, SecureCommunicationFactory) {
            var _key = sessionStorage.Key;
            $scope.init = function () {
                $scope.currentPage = 1;
                $scope.totalItems = 0;
                $scope.itemsPerPage = 20;
                $scope.maxSize = 6;
                $scope.FromRow = 0;
                $scope.Order = false;
                $scope.OrderByField = 'Date';
                $scope.CommunicationType = 'M';
                $scope.CommunicationSrc = 'Secure Communication';
                $scope.CommunicationList = [];
                GetCommunicationList();
            };

            var OrderByFields = {
                EmailFrom: 'EmailFrom',
                EmailTo: 'EmailTo',
                Subject: 'Subject',
                Date: 'Date'
            };

            $scope.OrderBy = function (fieldname) {
                $scope.OrderByField = OrderByFields[fieldname];
                $scope.Order = $scope.Order ? false : true;
                GetCommunicationList();
            };

            var GetCommunicationList = function () {
                var reqData = {
                    IndividualId: 0,
                    FromRow: $scope.FromRow,
                    RecordToFetch: $scope.itemsPerPage,
                    OrderByField: $scope.OrderByField,
                    Order: $scope.Order,
                    CommunicationType: $scope.CommunicationType,
                    CommunicationSource: $scope.CommunicationSrc,
                    DisplayArea: 'backoffice',

                };
                ShowLoader();
                SecureCommunicationFactory.GetSecureCommunicationAll(_key, reqData).then(function (res) {
                    if (res.data.Status) {
                        $scope.CommunicationList = res.data.SecureCommunicationList;
                        $scope.totalItems = res.data.TotalRecord;
                    }
                    HideLoader();
                }, function () {
                    HideLoader();
                });
            };

            $scope.pageChanged = function () {
                $scope.FromRow = (($scope.currentPage - 1) * $scope.itemsPerPage)
                GetCommunicationList()
            };

            $scope.CloseReplySection = function (item) {
                if (item) {
                    item.showsendemail = false;
                }
                GetCommunicationList();
            };
    }]);
})();