﻿(function () {
    'use strict'
    angular.module('app.core')
        .controller('SecureCommunicationDirectiveController', ['$scope', 'SecureCommunicationDirectiveFactory', 'emailerFactory', '$sce',
            function ($scope, SecureCommunicationDirectiveFactory, emailerFactory, $sce) {
                var _key = sessionStorage.Key;
                var _userId = sessionStorage.UserID;
                $scope.LoggedUserId = _userId;
                $scope.selectedTab = 1;
                $scope.EmailTemplateId = 54;
                $scope.SMSTemplateId = 55;
                $scope.SaveCommunicationOnly = true;
                $scope.tabClick = function (tab) {
                    $scope.selectedTab = tab;
                    GetCommunicationList();
                };

                $scope.init = function () {
                    $scope.currentPage = 1;
                    $scope.totalItems = 0;
                    $scope.itemsPerPage = 10;
                    $scope.maxSize = 6;
                    $scope.FromRow = 0;
                    $scope.Order = false;
                    $scope.OrderByField = 'Date';
                    if (!$scope.CommunicationType) {
                        $scope.CommunicationType = 'M';
                    }
                    $scope.CommunicationSrc = 'Secure Communication';
                    $scope.CommunicationList = [];
                    GetCommunicationList();
                    ConfigurationGetbySettings('EmailFrom');
                };

                var OrderByFields = {
                    EmailFrom: 'EmailFrom',
                    EmailTo: 'EmailTo',
                    Subject: 'Subject',
                    Date: 'Date'
                };

                $scope.OrderBy = function (fieldname) {
                    $scope.OrderByField = OrderByFields[fieldname];
                    $scope.Order = $scope.Order ? false : true;
                    GetCommunicationList();
                };

                var GetCommunicationList = function () {
                    if ($scope.individualid || $scope.isstaff) {
                        var reqData = {
                            IndividualId: $scope.individualid,
                            FromRow: $scope.FromRow,
                            RecordToFetch: $scope.itemsPerPage,
                            OrderByField: $scope.OrderByField,
                            CommunicationType: $scope.CommunicationType,
                            CommunicationSource: $scope.CommunicationSrc,
                            DisplayArea: $scope.displayarea,
                            DataFor: 'LIST',
                            Order: $scope.Order,
                            UserId: _userId
                        };
                        if ($scope.isstaff) {
                            reqData = Object.assign(reqData, {
                                IsStaff: $scope.isstaff,
                                UserId: _userId
                            })
                        }
                        ShowLoader();
                        SecureCommunicationDirectiveFactory.GetSecureCommunicationAll(_key, reqData).then(function (res) {
                            if (res.data.Status) {
                                $scope.CommunicationList = res.data.SecureCommunicationList;
                                $scope.totalItems = res.data.TotalRecord;
                            }
                            HideLoader();
                        }, function () {
                            HideLoader();
                        });
                    }
                };

                $scope.GetCommunicationDetails = function (item) {
                    if (item.ApplicationId && (!item.CommunicationDetails || item.LoadRequiredCount == 0)) {
                        item.LoadRequiredCount = undefined;
                        var reqData = {
                            ApplicationId: item.ApplicationId,
                            IndividualId: $scope.individualid,
                            FromRow: $scope.FromRow,
                            RecordToFetch: $scope.itemsPerPage,
                            OrderByField: $scope.OrderByField,
                            CommunicationType: $scope.CommunicationType,
                            CommunicationSource: $scope.CommunicationSrc,
                            DisplayArea: $scope.displayarea,
                            DataFor: 'DETAILS',
                            Order: $scope.Order,
                            UserId: _userId
                        };
                        if ($scope.isstaff) {
                            reqData = Object.assign(reqData, {
                                IsStaff: $scope.isstaff,
                                UserId: _userId
                            })
                        }
                        ShowLoader();
                        SecureCommunicationDirectiveFactory.GetSecureCommunicationAll(_key, reqData).then(function (res) {
                            if (res.data.Status) {
                                item.CommunicationDetails = res.data.SecureCommunicationList;
                                //get IndividualId and UserIdto Send
                                if (item.CommunicationDetails && item.CommunicationDetails.length > 0) {
                                    var indvList = $.grep(item.CommunicationDetails, function (i) {
                                        return (i.IndividualId && i.IndividualId != 0);
                                    });
                                    if (indvList && indvList.length > 0) {
                                        item.IndividualId = indvList[0].IndividualId;
                                    }
                                    var UserIdList = $.grep(item.CommunicationDetails, function (i) {
                                        return (i.UserIdFrom != _userId);
                                    });
                                    if (UserIdList && UserIdList.length > 0) {
                                        item.ToUserId = UserIdList[0].UserIdFrom;
                                    }
                                }
                                item.UnreadMessageCount = 0;
                            }
                            HideLoader();
                        }, function () {
                            HideLoader();
                        });
                    }
                    //Load Data on expand 
                    else if (item.LoadRequiredCount > 0) {
                        item.LoadRequiredCount = (item.LoadRequiredCount - 1)
                    }
                };

                var ConfigurationGetbySettings = function (sett) {
                    emailerFactory.ConfigurationGetbySettings(_key, sett).then(function (res) {
                        if (res && res.data.Status && res.data.ConfigurationList && res.data.ConfigurationList.length > 0) {
                            var setVal = res.data.ConfigurationList[0].Value;
                            if (setVal && setVal != null) {
                                $scope.FromEmail = setVal;
                            }
                        }
                    }, function () {});
                };

                $scope.MakeAsUnRead = function (item) {
                    if (item) {
                        ShowLoader();
                        var userType = 'ONLINE';
                        if ($scope.IsStaff) {
                            userType = 'STAFF';
                        }
                        SecureCommunicationDirectiveFactory.MakeSecureCommunicationUnread(_key, _userId, item.ApplicationId, userType).then(function (res) {
                            if (res.data.Status) {
                                item.UnreadMessageCount = 1;
                                item.LoadRequiredCount = 1;
                            }
                            HideLoader();
                        }, function () {
                            HideLoader();
                        })
                    }
                };

                $scope.ViewCommunicationClick = function (item) {
                    item.showsendemail = false;
                    item.ViewDetails = item.ViewDetails ? false : true;
                };

                $scope.ReplyCommunicationClick = function (item) {
                    if ($scope.isstaff) {
                        $scope.individualid = item.IndividualId;
                    }
                    if ($scope.selectedTab == 1) { //inbox reply
                        item.newUserIdTo = angular.copy(item.UserIdFrom)
                    } else if ($scope.selectedTab == 2) { //outbox reply
                        item.newUserIdTo = angular.copy(item.UserIdTo)
                    };
                    item.showsendemail = (item.showsendemail ? false : true);
                    item.ViewDetails = false;
                };


                $scope.pageChanged = function () {
                    $scope.FromRow = (($scope.currentPage - 1) * $scope.itemsPerPage)
                    GetCommunicationList()
                };

                $scope.CloseReplySection = function (item) {
                    if (item) {
                        item.showsendemail = false;
                    }
                    GetCommunicationList();
                };

                $scope.OnEmailSend = function (p, c) {
                    if (p && p.CommunicationDetails && c) {
                        c.CreatedOn = new Date();
                        p.CommunicationDetails.splice(0, 0, c);
                    }
                };

                $scope.CloseSendEmailSection = function () {
                    $scope.ShowSendEmail = false;
                    GetCommunicationList();
                };

                $scope.trustAsHtml = function (string) {
                    return $sce.trustAsHtml(string);
                };
            }
        ]);
})();