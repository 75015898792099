﻿(function () {
    'use strict';

    angular
        .module('app.backofficeProvider')
        .controller("BackofficeFirmRenewalListingController", BackofficeFirmRenewalController);

    BackofficeFirmRenewalController.$inject = ['$scope', 'utilityService', '$controller', '$filter', '$state', '$stateParams', 'typeValuesService', 'TypeValue', 'BackofficeFirmRenewalListService'];

    function BackofficeFirmRenewalController($scope, utilityService, $controller, $filter, $state, $stateParams, typeValuesService, TypeValue, BackofficeFirmRenewalListService) {

        $scope.searchData = {};
        $scope.DisplayAddApplication = false;
        $scope.IsPendingApplication = false;
        // Pagination
        $scope.sortColumn = "ApprovedDate";
        $scope.sortOrder = "desc";
        $scope.pageSize = 50;
        $scope.currentPage = 1;
        $scope.totalRecords;
        $scope.maxSize = 5;
        $scope.IsBackoffice = true;
        $scope.userId = sessionStorage.UserID;
        $scope.searchDataObj = {
            Name: "",
            FirstName: "",
            LastName: "",
            Phone: "",
            License: "",
            SSN: ""
        };
        $scope.headers = {
            licenseNumber: { name: 'License#', sortColumn: 'LicenseNumber', sortOrder: 'asc' },
            ProviderName: { name: 'Business Name', sortColumn: 'ProviderName', sortOrder: 'asc' },
            ProviderStatusTypeId: { name: 'Application Type', sortColumn: 'ProviderStatusTypeId', sortOrder: 'asc' },
            submittedDate: { name: 'Submitted Date', sortColumn: 'SubmittedDate', sortOrder: 'asc' },
            approvedDate: { name: 'Approved Date', sortColumn: 'ApprovedDate', sortOrder: 'desc' },
            applicationStatus: { name: 'Status', sortColumn: 'StatusName', sortOrder: 'asc' }
        };
        $scope.init = function () {
            $scope.searchData = angular.copy($scope.searchDataObj);
            $scope.addNewLicense = true;
            if ($scope.isUserSessionActive()) {
                $scope.licenseBoSearch = false;
                $scope.getLicenseeRenewalList(1, false, true);
                $scope.applicationStatusTypeGetAll();
                $scope.searchdata = true;
            } else {
                $state.go('app.Loginwoemail');
            }
        };

        $scope.cancelLicenseNumberSearch = function (licenseSearchForm) {
            $scope.addNewLicense = true;
            $scope.licenseBoSearch = false;
            $scope.SearchLicenseNumber = '';
            licenseSearchForm.$submitted = false;
            licenseSearchForm.$setPristine();
        };

        $scope.licenseBoAdd = function () {
            $scope.licenseBoSearch = true;
            $scope.SearchLicenseNumber = "";
            $scope.searchdata = false;
        };

        $scope.pageChanged = function () {
            hideStatusMessage();
            $scope.getLicenseeRenewalList($scope.currentPage);
        };
        $scope.SortRecords = function (header) {
            $scope.sortColumn = header.sortColumn;
            $scope.sortOrder = header.sortOrder;
            header.sortOrder = (header.sortOrder == "asc" ? "desc" : "asc");
            $scope.getLicenseeRenewalList($scope.currentPage);
        }
        $scope.getAllLicenseeRenewalList = function () {
            $scope.getLicenseeRenewalList(1, true);
        };
        $scope.getLicenseeRenewalList = function (PageNumber, showAllRecords, hideApproved) {
            $scope.hidePaging = !!showAllRecords;
            resetValidation();
            $scope.DisplayAddApplication = false;
            ShowLoader("Please Wait...");
            $scope.gridRenewalList = true;
            $scope.searchData.ProviderTypeId = 5;
            $scope.searchData.HideApprovedApps = hideApproved;
            BackofficeFirmRenewalListService.RenewalSearchWithPager(sessionStorage.Key, PageNumber, $scope.pageSize, $scope.sortColumn, $scope.sortOrder, !!showAllRecords, $scope.searchData)
                .then(function (response) {
                    if ($scope.checkServerResponse(response.data)) {
                        $scope.totalRecords = response.data.Total_Record;
                        $scope.RenewalList = _.filter(response.data.ProviderRenewalApplicationList, function (o) {
                            return o.ApplicationTypeId == 1;
                        });
                        HideLoader();
                    } else {
                        HideLoader();
                    }
                }, function (data) {
                    HideLoader();
                    showStatusMessage(data, "error");
                });
        };

        $scope.renewalSearch = function () {
            hideStatusMessage();
            $scope.currentPage = 1;
            $scope.getLicenseeRenewalList(1);
        };

        $scope.renewalSearchByLicenseNumber = function (licenseSearchForm) {
            if (!!licenseSearchForm.errorMessages && licenseSearchForm.errorMessages.length > 0) {
                return;
            }
            hideStatusMessage();
            ShowLoader("Please Wait...");
            $scope.DisplayAddApplication = false;
            $scope.IsPendingApplication = false;
            LicenseRenewalService.IndividualLicenseByLicenseNumber(sessionStorage.Key, $scope.SearchLicenseNumber).then(function (response) {
                if (!!response.data.IndividualId && !!response.data.IndividualId != 0) {
                    LicenseRenewalService.LicenseRenewalCheck(sessionStorage.Key, response.data.IndividualId, "BackOffice").then(function (responseLicenseCheck) {
                        if (!!responseLicenseCheck.data.Status) {
                            resetRenewalListing();
                            sessionStorage.IndividualId = response.data.IndividualId;
                            $scope.DisplayAddApplication = true;
                            $scope.IsPendingApplication = true;
                            $scope.SearchLicenseNumber = "";
                            $scope.licenseBoSearch = false;
                            licenseSearchForm.$submitted = false;
                            licenseSearchForm.$setPristine();
                        } else {
                            $scope.DisplayAddApplication = false;
                            $scope.IsPendingApplication = false;
                            showStatusMessage(responseLicenseCheck.data.Message, "error");
                            HideLoader();
                        }
                    }, function (data) {
                        HideLoader();
                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });

                } else {
                    $scope.DisplayAddApplication = false;
                    $scope.IsPendingApplication = false;
                    showStatusMessage(response.data.Message, "error");
                    HideLoader();
                }
            }, function (data) {
                HideLoader();
                if (data != null) { showStatusMessage(data.message, "error"); }
            });
        }

        // Get LicenseStatus
        $scope.applicationStatusTypeGetAll = function () {
            try {
                typeValuesService.getTypeValuesByTypeName(TypeValue.ApplicationStatus, sessionStorage.Key, false, true)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.ApplicationStatusList = response.ApplicationStatusList;
                        }
                    }, function (data) {
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }
        var resetRenewalListing = function () {
            angular.forEach($scope.RenewalList, function (renewalItem, key) {
                renewalItem.ShowInternalForm = false;
                renewalItem.ShowEmailForm = false;
                renewalItem.ShowLicenseOutputForm = false;
            });
        };

        $scope.openLicenseOutputForm = function (renewalItem) {
            $scope.DisplayAddApplication = false;
            $scope.currentRenewalItem = renewalItem;
            renewalItem.licenseInfoValidation = {};
            resetRenewalListing();
            renewalItem.ShowLicenseOutputForm = true;
            sessionStorage.IndividualId = renewalItem.IndividualId;
            hideStatusMessage();
        };

        $scope.closeLicenseOutputForm = function () {
            $scope.DisplayAddApplication = false;
            $scope.IsPendingApplication = false;
            resetRenewalListing();
            $scope.currentRenewalItem = null;
            //$scope.IsSearch = true;
            $scope.clearApplication();
        };

        $scope.generateAndSendLicenseOutput = function () {
            $scope.$broadcast("SendLicenseOutputEmail", {});
        };

        $scope.openRenewalApplication = function (renewalItem) {
            $scope.DisplayAddApplication = false;
            $scope.addNewLicense = true;
            $scope.currentRenewalItem = renewalItem;
            if (renewalItem.ApplicationStatusId == 14) {
                //Edit
                $scope.IsPendingApplication = true;
                renewalItem.disableSubmitControls = false;
                renewalItem.disableRenewalForm = false;
                renewalItem.DisableSaveButton = false;
                renewalItem.DisableSubmitButton = false;
                renewalItem.ShowPayAndApproveButton = true;
                renewalItem.ShowConfirmAndApproveButton = true;
                $scope.ProceedtoPaymentSectionEnable = false;
                //"Save" ,"Pay Only","Pay and Approve", "Close"
                //Form Not disabled

            } else if (renewalItem.ApplicationStatusId == 2 || renewalItem.ApplicationStatusId == 13 || renewalItem.ApplicationStatusId == 15) {
                //Verify
                renewalItem.disableSubmitControls = false;
                renewalItem.disableRenewalForm = true;
                renewalItem.DisableSaveButton = true;
                renewalItem.DisableSubmitButton = true;
                renewalItem.ShowPayAndApproveButton = true;
                renewalItem.ShowConfirmAndApproveButton = true;
                $scope.ProceedtoPaymentSectionEnable = true;
                //"Save" ,"Confirm And Approve", "Close"
                //Form Not disabled
            } else if (renewalItem.ApplicationStatusId == 3) {
                //View
                renewalItem.disableSubmitControls = true;
                renewalItem.disableRenewalForm = true;
                renewalItem.DisableSaveButton = true;
                renewalItem.DisableSubmitButton = true;
                renewalItem.ShowPayAndApproveButton = false;
                renewalItem.ShowConfirmAndApproveButton = true;
                $scope.ProceedtoPaymentSectionEnable = true;

                //"Save"----Disabled ,"Confirm And Approve"----Disabled, "Close"
                //Form disabled
            }
            resetRenewalListing();
            //load required data for directive            
            $scope.ProviderID = renewalItem.ProviderId;
            $scope.EIN = renewalItem.TaxId;
            $scope.licenseNumber = renewalItem.LicenseNumber;
            $scope.providerName = renewalItem.ProviderName;
            renewalItem.ShowInternalForm = true;
            hideStatusMessage();
        }

        $scope.emailSend = function (renewalItem) {
            return;
            resetRenewalListing();
            renewalItem.ShowEmailForm = true;
        }

        $scope.closeApplicationItem = function () {
            $scope.addNewLicense = true;
            $scope.DisplayAddApplication = false;
            $scope.IsPendingApplication = false;
            resetRenewalListing();
            $scope.currentRenewalItem = null;
            //$scope.IsSearch = true;
            $scope.clearApplication();
        }
        $scope.CancelSearchClick = function () {
            $scope.clearApplication();
            $scope.getLicenseeRenewalList(1);
        };
        $scope.ReloadApplicationList = function () {
            //$scope.CancelSearchClick();
        };
        // Get State List by CountryID
        $scope.getStateByCountryID = function (CountryID) {
            try {
                LicenseRenewalService.GetStateByCountryID(sessionStorage.Key, CountryID)
                    .then(function(response){ response = response.data;
                        if ($scope.checkServerResponse(response)) {
                            $scope.StatList = response.State;
                        }
                    }, function(data) { data = data.data;
                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.clearApplication = function () {
            $scope.searchData = angular.copy($scope.searchDataObj);
            $scope.DisplayAddApplication = false;
            $scope.IsPendingApplication = false;
            $scope.SearchLicenseNumber = "";
        }

        $scope.init();
    };
})();