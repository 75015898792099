(function () {
    'use strict'

    angular.module('app.core')
        .directive('multiSelect', ['$compile', '$http', MultiSelect]);

    function MultiSelect($compile, $http) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                var template = '<div uib-dropdown class="multi-select"> \
                                    <button uib-dropdown-toggle class="multi-select-dropdown-toggle btn btn-secondary {{current.classes}}" style="{{current.styles}}" \
                                        type="button" title="{{current.viewValue}}" \
                                        is-open="current.dropdownOpen"> \
                                        <span ng-show="!current.viewValue">{{current.default}}</span> \
                                        <span ng-show="!!current.viewValue">{{current.viewValue}}</span> \
                                        <div class="select-caret">&#9662</div> \
                                    </button> \
                                    <ul uib-dropdown-menu class="multi-select-dropdown-menu"> \
                                        <li class="dropdown-item"> \
                                            <button type="button" class="btn btn-sm" ng-click="clearAll(); $event.stopPropagation();">Clear All</button> \
                                        </li> \
                                        <li class="dropdown-item" ng-repeat="value in current.array"> \
                                            <label ng-click="$event.stopPropagation();"> \
                                                <input type="checkbox" ng-model="current.selectedValues[value[current.select]]" ng-change="valueChanged();"> {{value[current.label]}} \
                                            </label> \
                                        </li>  \
                                    </ul> \
                                </div>';
                var DOM = angular.element(template);
                var toggle = DOM.find('.multi-select-dropdown-toggle');
                var directiveAttrs = ['select', 'label', 'list', 'array', 'default', 'class', 'style'];
                var btnAttrs = ['ng-disabled', 'name', 'ng-model', 'nat-required', 'validate-req-field', 'ng-class']
                for (var i = 0; i < element[0].attributes.length; i++) {
                    var attr = element[0].attributes[i];

                    if (!directiveAttrs.includes(attr.name)) {
                        if (btnAttrs.includes(attr.name)) {
                            var val = attr.nodeValue
                            if (attr.name == 'ng-class' && attr.nodeValue.length > 1) {
                                //splice the dropdown toggle in
                                var start = attr.nodeValue.length - 1;

                                //val = attr.nodeValue.splice(start, 0, `, 'dropdown-open':dropdownOpen`)
                            }
                            if (attr.name == 'ng-model') {
                                val = '$parent.' + attr.nodeValue;
                            }
                            toggle.attr(attr.name, val);
                        } else {
                            DOM.attr(attr.name, attr.nodeValue);
                        }
                    }
                }
                DOM.attr('dropdown-append-to-body', '');
                //DOM.attr('ng-controller', 'MultiSelectController');

                var newScope = $scope.$new(false);

                newScope.current = {
                    select: attrs.select,
                    label: attrs.label,
                    array: [],
                    default: (!!attrs.default) ? attrs.default : '-- Select --',

                    styles: (!!attrs.style) ? attrs.style : '',
                    classes: (!!attrs.class) ? attrs.class.split(' ').where(function (s) { return !s.startsWith('ng'); }).join(' ') : '',
                    model: null,

                    modelValue: '',
                    viewValue: '',
                    display: attrs.display
                };

                element.replaceWith(DOM);

                $compile(DOM)(newScope);

                var model = angular.element(toggle).controller('ngModel');

                newScope.current.model = model;
                //console.log(model);

                newScope.valueChanged = function () {
                    if (!!newScope.current.array && !!newScope.current.model) {
                        var viewValueArr = [];
                        var modelValueArr = [];

                        newScope.current.array.forEach(function (value) {
                            if (!!newScope.current.selectedValues[value[newScope.current.select]]) {
                                viewValueArr.push(value[newScope.current.label]);
                                modelValueArr.push(value[newScope.current.select]);
                            }
                        });

                        if (typeof (newScope.current.modelValue) != 'undefined' || modelValueArr.length > 0) {
                            // If the model is not null OR if it is transitioning from null to a value
                            if (newScope.current.modelValue != null || (newScope.current.modelValue == null && modelValueArr.length > 0)) {
                                newScope.current.model.$setViewValue(modelValueArr.join(','));
                            } else {
                                newScope.current.model.$setViewValue(null);
                                newScope.current.model.$setPristine();
                            }
                        }
                        newScope.current.viewValue = (viewValueArr.length > 0) ? viewValueArr.join(',') : newScope.current.default;
                        if (!!newScope.current.display) {
                            $scope[newScope.current.display] = (viewValueArr.length > 0) ? viewValueArr.join(',') : "";
                        }
                    }
                };
                newScope.clearAll = function () {
                    newScope.current.array.forEach(function (value) {
                        newScope.current.selectedValues[value[newScope.current.select]] = false;
                    });
                    newScope.valueChanged();
                };
                newScope.refreshSelectedList = function (modelValue) {
                    if (!!newScope.current.array) {
                        var modelValueArr = !!modelValue ? modelValue.split(',') : [];

                        newScope.current.selectedValues = {};

                        newScope.current.array.forEach(function (value) {
                            var i = value[newScope.current.select];

                            newScope.current.selectedValues[i] = modelValueArr.any(function (m) { return m == i; });;
                        });
                    }
                };
                $scope.$watch(attrs.array, function (newVal) {
                    newScope.current.modelValue = $scope.$eval(attrs.ngModel);
                    newScope.current.array = newVal;
                    newScope.refreshSelectedList(newScope.current.modelValue);
                    newScope.valueChanged();
                });
                $scope.$watch(attrs.ngModel, function (newVal) {
                    newScope.current.modelValue = newVal;
                    newScope.refreshSelectedList(newVal);
                    newScope.valueChanged();
                });
            }
            // templateUrl: 'app/core/directive/multi-select/multi-select.html',
            // controller: 'MultiSelectController'
        };
    }
})();
