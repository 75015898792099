﻿(function () {
    'use strict';
    angular
        .module('app.InitialApplication').factory("SecureCommunicationFactory", ['$http', '$q', 'WebApiUrl', function ($http, $q, WebApiUrl) {

            var _getSecureCommunicationAll = function (key, reqdata) {
                return returnDefferedResult($http.post(WebApiUrl + 'Individual/GetSecureCommunicationAll/' + key, reqdata));
            };

            var returnDefferedResult = function (request) {
                var deferred = $q.defer();
                request.then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.reject(response);
                });
                return deferred.promise;
            };
            return {
                GetSecureCommunicationAll: _getSecureCommunicationAll
            };
    }]);
})();