(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialApplicationEducationController", InitialApplicationEducationController);

    InitialApplicationEducationController.$inject = ["$rootScope", "$scope", "$state", "$q", "typeValuesService",
        "initialApplicationEducationService", "initialApplicationstatusService", "contentService", "utilityService", "WebApiUrl", "EntityConfirmationService",
        "countryStateService", "multipleFileUploadService", "initialApplicationNcessService", "Messages"
    ];

    function InitialApplicationEducationController($rootScope, $scope, $state, $q, typeValuesService,
        initialApplicationEducationService, initialApplicationstatusService, contentService, utilityService, WebApiUrl, EntityConfirmationService,
        countryStateService, multipleFileUploadService, initialApplicationNcessService, Messages) {
        $scope.IndividualEducation = [];
        $scope.providerRequired = true;
        $scope.otherRequired = false;

        $scope.documentByIndividualDocumentId = multipleFileUploadService.documentByIndividualDocumentId;
        $rootScope.routeData = $state.current.routeData;

        if (!$scope.isRenewal) {
            $scope.educationInfo = {
                IndividualId: $scope.individualId,
                IsActive: true,
                UseId: sessionStorage.UserID,
            };

            $scope.educationPostDoc = {
                IndividualId: $scope.individualId,
                IsActive: true,
                UseId: sessionStorage.UserID,
            };
            $scope.IndividualCEHResponseList = {};
        }

        $scope.viewConfig = {
            showAddEducationDoctoralForm: false,
            showAddEducationPostDoctoralForm: false
        }

        $scope.educationtData = {
            Date: new Date()
        };
        $scope.uiLogic = {};
        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/yyyy',
            formatGraduation: "MM/yyyy",
            dateOptions: {
                datepickerMode: 'month',
                minMode: 'month',
            },
            endDateOptions: {

            },
            startDateOptions: {

            }
        };

        $scope.documentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.individualId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "FR",
            LoadByDocIdAndDocCode: true,
            DocId: 2,
            DocCode: "D1002",
            LoadDocumentsWithoutBroadCast: true
        };
        $scope.supportingDocumentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.individualId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "FR",
            LoadByDocIdAndDocCode: true,
            DocId: 115,
            DocCode: "D1115",
            LoadDocumentsWithoutBroadCast: true
        };
        $scope.datePickerPostDoctrol = angular.copy($scope.datePicker);

        $scope.getEducationConfig = function () {
            var relativeFilePath = "/individual/application/education/education.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function (data) {
                    $scope.educationConfig = data;
                    $scope.isConfigLoaded = true;
                    if (!$scope.isRenewal) {
                        $scope.getEntityConfirmation().then(function (response) {
                            if (!response.Status) {
                                $scope.showStatusMessage(Messages.aaabcfc, 'error')
                                return;
                            }
                            $scope.GetEducationInfo();
                        })
                    }
                }, function (error) {
                    ShowErrMsg("educationConfig", "Some error occurred,Unable to load the Continuing Education config")
                });
        };

        $scope.getQualifyingEducationMonths = function () {
            var qualifyingEducationMonths = 0;
            if ($scope.individualEducationDoctoral && $scope.individualEducationDoctoral.length > 0) {
                angular.forEach($scope.individualEducationDoctoral, function (individualEducationItem) {
                    qualifyingEducationMonths = qualifyingEducationMonths + (!!individualEducationItem.ProgramHours ? individualEducationItem.ProgramHours : 0);
                });
            };
            $scope.educationLoaded = true;
            if (angular.isNumber($scope.uiLogic.qualifyingEducationMonths)) {
                if (angular.isNumber($scope.tempVerMonths)) {
                    $scope.uiLogic.qualifyingEducationMonths -= parseInt($scope.tempVerMonths);
                    $scope.uiLogic.qualifyingEducationMonths += qualifyingEducationMonths;
                    $scope.tempVerMonths = qualifyingEducationMonths;
                }
                else {
                    $scope.uiLogic.qualifyingEducationMonths = $scope.tempMonths + qualifyingEducationMonths;
                    $scope.tempVerMonths = qualifyingEducationMonths;
                }
            }
            else {
                $scope.uiLogic.qualifyingEducationMonths = qualifyingEducationMonths;
            }
        };

        $scope.$watch('uiLogic.NCEESRecordTechnicalReview.NCEESRecordTechnicalReview[0].legalResponse.Desc', function (newval) {
            if (!!newval && newval != "") {
                if (angular.isNumber($scope.tempMonths)) {
                    $scope.uiLogic.qualifyingEducationMonths -= parseInt($scope.tempMonths);
                    $scope.uiLogic.qualifyingEducationMonths += parseInt(newval);
                    $scope.tempMonths = parseInt(newval);
                }
                else {
                    $scope.uiLogic.qualifyingEducationMonths += parseInt(newval);
                    $scope.tempMonths = parseInt(newval);
                }
            }
            else {
                if (angular.isNumber($scope.tempMonths)) {
                    $scope.uiLogic.qualifyingEducationMonths -= parseInt($scope.tempMonths);
                    $scope.uiLogic.qualifyingEducationMonths += 0;
                    $scope.tempMonths = 0;
                }
            }
        })

        var processIndividualEducationByProgramType = function () {
            var individualEducationDoctoral = [];
            var individualEducationPostDoctoral = [];
            var isValueExist = false;
            var isValueExist2 = false;

            angular.forEach($scope.IndividualEducation, function (individualEducation) {
                if (individualEducation.ProgramTypeId == 1) {
                    individualEducationDoctoral.push(individualEducation);
                    if ((individualEducation.DegreefromForeignStr == 'RS' || individualEducation.DegreefromForeignStr == 'ENGTECH') && (individualEducation.Degree && individualEducation.Degree.trim() == 'MS')) {
                        isValueExist = true;
                    }
                    if ((individualEducation.CurriculumnId == 44 || individualEducation.CurriculumnId == 46)) {
                        isValueExist2 = true;
                    }
                } else if (individualEducation.ProgramTypeId == 2) {
                    individualEducationPostDoctoral.push(individualEducation);
                }
            });

            $scope.individualEducationDoctoral = individualEducationDoctoral;
            $scope.getQualifyingEducationMonths();
            if (isValueExist) {
                sessionStorage.IEEducation = isValueExist;
            } else {
                sessionStorage.IEEducation = isValueExist;
            }


            if (isValueExist2) {
                sessionStorage.LSEducation = isValueExist2;
            } else {
                sessionStorage.LSEducation = isValueExist2;
            }

            //  console.log($scope.individualEducationDoctoral);
            $scope.individualEducationPostDoctoral = individualEducationPostDoctoral;
            $scope.showCredits = true;
            if (!!$scope.applicationInfo && $scope.applicationInfo.LicenseApplicationForId == 6) {
                angular.forEach($scope.individualEducationDoctoral, function (item) {
                    if (item.IsBSDegreeSurveying) {
                        $scope.showCredits = false;
                    }
                });
            }
        };

        $scope.GetEducationInfo = function () {
            try {
                $scope.IndividualEducation = [];
                initialApplicationEducationService.getEducation($scope.individualId, $scope.applicationId, sessionStorage.UserID, sessionStorage.Key)
                    .then(function (response) {
                        $scope.IndividualEducation = response.IndividualEducation;
                        if (!!$scope.IndividualEducation) {
                            $scope.IndividualEducation.map(function (individualEducationItem) {
                                if (!!individualEducationItem.Major) {
                                    individualEducationItem.MajorDescription = $scope.fieldOfStudyTypesOriginal.whereIncludes(individualEducationItem.Major.split(',').select(function (majorItem) {
                                        return 1 * majorItem;
                                    }), 'fieldOfStudyTypeId').select('fieldOfStudyTypeName').join(',');
                                }
                            });
                        }
                        processIndividualEducationByProgramType();
                        HideLoader();
                    }, function (error) {
                        HideLoader();
                    });
            } catch (ex) {
                HideLoader();
            }
        };

        $scope.setForm = function (currentForm, formNumber) {
            if (formNumber == 1)
                $scope.currentDoctoralForm = currentForm;
            else if (formNumber == 2)
                $scope.currentInstitutionForm = currentForm;
            else if (formNumber == 3)
                $scope.currentForm = currentForm;
        };

        $scope.setEducationToMinDate = function (formNumber) {
            if (formNumber == 1) {
                if (angular.isDate($scope.educationInfo.DateFrom)) {
                    $scope.datePicker.endDateOptions.minDate = $scope.educationInfo.DateFrom;
                }
            } else if (formNumber == 2) {
                if (angular.isDate($scope.educationPostDoc.DateFrom)) {
                    $scope.datePickerPostDoctrol.endDateOptions.minDate = $scope.educationPostDoc.DateFrom;
                }
            }
        };

        $scope.setEducationFromMaxDate = function (formNumber) {
            if (formNumber == 1) {
                if (angular.isDate($scope.educationInfo.DateTo)) {
                    $scope.datePicker.startDateOptions.maxDate = $scope.educationInfo.DateTo;
                }
            } else if (formNumber == 2) {
                if (angular.isDate($scope.educationPostDoc.DateTo)) {
                    $scope.datePickerPostDoctrol.startDateOptions.maxDate = $scope.educationPostDoc.DateTo;
                }
            }
        };

        var processEducationDates = function (formNumber) {
            if (formNumber == 1) {
                if (!!$scope.educationInfo) {
                    if (!!$scope.educationInfo.DateFrom && !angular.isDate($scope.educationInfo.DateFrom))
                        $scope.educationInfo.DateFrom = new Date($scope.educationInfo.DateFrom);
                    if (!!$scope.educationInfo.DateTo && !angular.isDate($scope.educationInfo.DateTo))
                        $scope.educationInfo.DateTo = new Date($scope.educationInfo.DateTo);
                }
            } else {
                if (!!$scope.educationPostDoc) {
                    if (!!$scope.educationPostDoc.DateFrom && !angular.isDate($scope.educationPostDoc.DateFrom))
                        $scope.educationPostDoc.DateFrom = new Date($scope.educationPostDoc.DateFrom);
                    if (!!$scope.educationPostDoc.DateTo && !angular.isDate($scope.educationPostDoc.DateTo))
                        $scope.educationPostDoc.DateTo = new Date($scope.educationPostDoc.DateTo);
                }
            }
            $scope.setEducationToMinDate(formNumber);
            $scope.setEducationFromMaxDate(formNumber);
        };

        var createNewEducationInfo = function (isEducationEdit, educationInfoData, formNumber) {
            if (!isEducationEdit) {
                if (formNumber == 1) {
                    $scope.educationInfo = {
                        IndividualId: $scope.individualId,
                        ApplicationId: $scope.applicationId,
                        IsActive: true,
                        ProgramTypeId: 1,
                        ProviderId: -1,
                        CountryId: $rootScope.DefaultCountryId
                    };
                } else if (formNumber == 2) {
                    $scope.educationPostDoc = {
                        IndividualId: $scope.individualId,
                        ApplicationId: $scope.applicationId,
                        IsActive: true,
                        ProgramTypeId: 2,
                        CountryId: $rootScope.DefaultCountryId
                    };
                }
            } else {
                if (formNumber == 1) {
                    angular.forEach($scope.Employers, function (item) {
                        if (item.ProviderId == educationInfoData.ProviderId && !educationInfoData.NoDegree) {
                            if (item.EmployerName == 'Other') {
                                $scope.otherRequired = true;
                            }
                        }
                    });
                    $scope.educationInfo = angular.copy(educationInfoData);
                    if (!!$scope.educationInfo.Major && !!$scope.fieldOfStudyTypes) {
                        $scope.fieldOfStudyTypes.map(function (fieldOfStudyTypeItem) {
                            fieldOfStudyTypeItem.isSelected = $scope.educationInfo.Major.split(',').includes('' + fieldOfStudyTypeItem.fieldOfStudyTypeId);
                        });
                    }
                } else if (formNumber == 2) {
                    $scope.educationPostDoc = angular.copy(educationInfoData);
                }
                processEducationDates(formNumber);
            }

            if (!$scope.educationInfo.ReferenceNumber) {
                $scope.educationInfo.ReferenceNumber = (new Date().valueOf()).toString();
            }
            //if (!!$scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 8) {
            initDocument();
            // }
        };

        var validateForm = function (currentForm, externalForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                if (!currentForm.errorMessages)
                    currentForm.errorMessages = [];
                currentForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
                if (!$scope.educationInfo.Major) {
                    $scope.natValidation.errorMessages.push({
                        propName: "majorFieldOfStudyRequired",
                        errorMessage: "Major Field of study is required"
                    });
                }
            }

            if (!!externalForm) {
                if (!!$scope.entityConfirmationEducation && $scope.entityConfirmationEducation.ConfirmationResponse == null) {
                    $scope.natValidation.errorMessages.push({
                        errorMessage: Messages.General.Errors.ConfirmQuestion
                    });
                }
                if ([1, 2].indexOf($scope.applicationInfo.LicenseApplicationForId) == -1 && !!$scope.entityConfirmationEducation.ConfirmationResponse && !!$scope.entityConfirmationNCEESRecord.ConfirmationResponse
                    && (!!$scope.entityConfirmationEducationTranscriptInNCEES && $scope.entityConfirmationEducationTranscriptInNCEES.ConfirmationResponse == null)) {
                    $scope.natValidation.errorMessages.push({
                        errorMessage: Messages.General.Errors.TranscriptInNCEESConfirmQuestion
                    });
                }
                if (([1, 2].indexOf($scope.applicationInfo.LicenseApplicationForId) != -1 && !!$scope.entityConfirmationEducation.ConfirmationResponse)
                    || ([1, 2].indexOf($scope.applicationInfo.LicenseApplicationForId) == -1 && !!$scope.entityConfirmationEducation.ConfirmationResponse
                        && (!$scope.entityConfirmationNCEESRecord.ConfirmationResponse || ($scope.entityConfirmationNCEESRecord.ConfirmationResponse == true && $scope.entityConfirmationEducationTranscriptInNCEES.ConfirmationResponse == false)))) {
                    if (!!$scope.entityConfirmationEducation && !!$scope.entityConfirmationEducation.ConfirmationResponse && (!$scope.individualEducationDoctoral || $scope.individualEducationDoctoral.length == 0)) {
                        $scope.natValidation.errorMessages.push({
                            errorMessage: Messages.General.Errors.OneRecord
                        });
                    }
                }
                if ($scope.applicationInfo && [1, 2].indexOf($scope.applicationInfo.LicenseApplicationForId) != -1 && !!$scope.entityConfirmationEducationTranscriptInNCEES) {
                    if ((!!$scope.entityConfirmationEducation && $scope.entityConfirmationEducation.ConfirmationResponse == false) &&
                        (!!$scope.entityConfirmationExperience && $scope.entityConfirmationExperience.ConfirmationResponse == false)) {
                        $scope.natValidation.errorMessages.push({
                            errorMessage: Messages.General.Errors.EitherExpOrEdu
                        });
                    }
                }
            } else {
                if (!!$scope.documentUploadNewApp && ($scope.documentUploadNewApp.DocumentUploadList && $scope.documentUploadNewApp.DocumentUploadList.length > 0) && ($scope.documentUploadNewApp.errorMessages && $scope.documentUploadNewApp.errorMessages.length > 0)) {
                    $scope.natValidation.errorMessages = $scope.natValidation.errorMessages.concat($scope.documentUploadNewApp.errorMessages);
                }
            }

            if (!!$scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 8) {
                if (!$scope.natValidation.errorMessages) {
                    $scope.natValidation.errorMessages = [];
                }
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        var ShowErrMsg = function (propName, errorMessage) {
            propName = (!!propName) ? propName : "educationErr";
            errorMessage = (!!errorMessage) ? errorMessage : $scope.educationConfig.ValidationMsg.APIError;
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            var propExist = false;
            for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                if ($scope.natValidation.errorMessages[i].propName == propName)
                    propExist = true;
            }
            if (!propExist)
                $scope.natValidation.errorMessages.push({
                    propName: propName,
                    errorMessage: errorMessage
                });

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.openAddEditEducationInfo = function (isEducationEdit, educationInfoData, formNumber) {
            ShowLoader();
            $scope.fieldOfStudyTypes = angular.copy($scope.fieldOfStudyTypesOriginal);
            if (formNumber == 1) {
                $scope.showAddEducationDoctoralForm = true;
            }
            if (formNumber == 2)
                $scope.showAddInstitutionForm = true;

            $scope.isEducationDoctoralEdit = isEducationEdit;
            createNewEducationInfo(isEducationEdit, educationInfoData, formNumber);
            HideLoader();
        };

        $scope.closeAddEditEducationInfo = function (formNumber) {

            if (formNumber == 1) {
                $scope.showAddEducationDoctoralForm = false;
                $scope.currentDoctoralForm = null;
            }
            if (formNumber == 2) {
                $scope.showAddInstitutionForm = false;
                $scope.currentInstitutionForm = null;
            }
            $scope.disableApplication = false;
        };

        $scope.onotherChange = function () {
            if (!$scope.educationInfo.ProviderName) {
                $scope.providerRequired = true;
                $scope.otherRequired = false;
            } else if (!$scope.educationInfo.ProviderId || $scope.educationInfo.ProviderId == -1) {
                $scope.providerRequired = false;
                $scope.otherRequired = true;
            } else {
                $scope.providerRequired = true;
                $scope.otherRequired = false;
            }
            if ($scope.educationInfo.NoDegree) {
                $scope.providerRequired = false;
                $scope.otherRequired = false;
            }
            if ($scope.educationInfo.ProviderName == 'Other' && !$scope.educationInfo.NoDegree) {
                $scope.otherRequired = true;
                $scope.educationInfo.ProviderName = '';
            }
            if ($scope.educationInfo.ProviderId) {
                angular.forEach($scope.Employers, function (item) {
                    if (item.ProviderId == $scope.educationInfo.ProviderId && !$scope.educationInfo.NoDegree) {
                        if (item.EmployerName == 'Other') {
                            $scope.otherRequired = true;
                        }
                    }
                });
            }
        }

        $scope.saveEducation = function (currentForm, formNumber) {
            try {
                if ($scope.educationInfo) {
                    if ($scope.educationInfo.ProviderId == -1) {
                        $scope.educationInfo.ProviderId = null;
                    }
                    $scope.educationInfo.Major = $scope.fieldOfStudyTypes.whereEquals(true, 'isSelected').select('fieldOfStudyTypeId').join(',');
                }
                if (!validateForm(currentForm)) {
                    return;
                };
                ShowLoader("Please Wait...");
                var individualEducationResponse = {

                };
                if (formNumber == 1)
                    individualEducationResponse.IndividualEducationResponse = [$scope.educationInfo]
                if (formNumber == 2)
                    individualEducationResponse.IndividualEducationResponse = [$scope.educationPostDoc]

                initialApplicationEducationService.individualEducationSave($scope.individualId,
                    $scope.applicationId, sessionStorage.UserID, [individualEducationResponse], sessionStorage.Key)
                    .then(function (response) {
                        if (response.Status && response.StatusCode == "00") {
                            $scope.documentUploadNewApp.LinkId = response.IndividualEducation[0].IndividualEducationId;
                            if (typeof $scope.saveAllDocument1 == "function") {
                                $scope.saveAllDocument1().then(function () {
                                    $scope.closeAddEditEducationInfo(formNumber);
                                    $scope.GetEducationInfo($scope.IndividualId);
                                    HideLoader();
                                }, function () { });

                            } else {
                                $scope.closeAddEditEducationInfo(formNumber);
                                $scope.GetEducationInfo($scope.IndividualId);
                                HideLoader();
                            }
                        }
                        HideLoader();
                    });
            } catch (ex) {
                HideLoader();
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
            }
        }

        var initDocument = function () {
            $scope.documentUploadNewApp = undefined;
            $scope.documentUploadNewApp2 = undefined;
            setTimeout(function () {
                $scope.$apply(function () {
                    $scope.documentUploadNewApp = {
                        ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                        ApplicationId: $scope.applicationId,
                        IndividualId: $scope.individualId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FR",
                        LoadByDocIdAndDocCode: true,
                        DocId: 101,
                        DocCode: 'D1101',
                        LoadDocumentsWithoutBroadCast: true,
                        LinkType: 'AL',
                        PropertyToProcess: 'IndividualCECourseId',
                        IsLinkedDocControl: true
                    };
                    $scope.documentUploadNewApp2 = {
                        ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                        ApplicationId: $scope.applicationId,
                        IndividualId: $scope.individualId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FR",
                        LoadByDocIdAndDocCode: true,
                        DocId: 23,
                        DocCode: 'D1023',
                        LoadDocumentsWithoutBroadCast: true,
                        LinkType: 'AL',
                        PropertyToProcess: 'IndividualCECourseId',
                        IsLinkedDocControl: true
                    };
                });
            }, 1000);
        };

        $scope.getStateByCountryID = function (CountryID) {
            return countryStateService.getStateByCountryID(sessionStorage.Key, CountryID);
        };

        $scope.deleteEducation = function (individualEducation, indexToDelete, formNumber) {
            try {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    individualEducation.IsDeleted = true;
                    var individualEducationResponse = {

                    };
                    individualEducationResponse.IndividualEducationResponse = [individualEducation]

                    initialApplicationEducationService.individualEducationSave($scope.individualId,
                        $scope.applicationId, sessionStorage.UserID, [individualEducationResponse], sessionStorage.Key)
                        .then(function (response) {
                            if (response.Status && response.StatusCode == "00") {
                                if (formNumber == 1)
                                    $scope.individualEducationDoctoral.splice(indexToDelete, 1)
                                else if (formNumber == 2)
                                    $scope.individualEducationPostDoctoral.splice(indexToDelete, 1)
                            }
                            HideLoader();
                        }, function (data) {
                            HideLoader();
                            ShowErrMsg();
                        });
                }
            } catch (ex) {
                // $scope.showStatusMessage({ messageContent: ex.message, messageType: 'error' });
            }
        };

        $scope.clearDataEducation = function () {
            try {
                $scope.course.EducationInformationDate = "";
                $scope.course.EducationInformationCourse = "";
                $scope.course.EducationInformationCEUnits = "";;
                $scope.course.EducationInformationInstitution = "";
                $scope.course.EducationInclasscredit = false;
                $scope.course.EducationCLCredit = false;
                $scope.courseEditIndex = 0;
            } catch (ex) {
                $scope.showStatusMessage({
                    messageContent: ex.message,
                    messageType: 'error'
                });
            }
        }

        $scope.saveAndNext = function (skipNextTab) {
            $scope.natValidation.errorMessages = [];
            $scope.tab.skipNextTab = skipNextTab;
            ShowLoader();
            $scope.tab.isInvalid = false;
            if (!validateForm(null, true)) {
                $scope.tab.isInvalid = true;
                HideLoader();
                if (skipNextTab && !!skipNextTab.fromDeficiency) {
                    $scope.$emit('deficiencyExecuted', { success: false });
                }
                if ($scope.tab.skipNextTab == true) {
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
                return;
            }
            $scope.$broadcast("SaveDeficiency", {});
            $scope.$broadcast("NCEESRecordTechnicalReviewSave", {});

            $scope.saveEntityConfirmation().then(function (response) {
                if (!response.Status) {
                    $scope.showStatusMessage(response.Message, "error");
                    HideLoader();
                    return;
                }
                HideLoader();
                if (!!$scope.applicationInfo && ($scope.applicationInfo.LicenseApplicationForId == 3 || $scope.applicationInfo.LicenseApplicationForId == 4 || $scope.applicationInfo.LicenseApplicationForId == 6)) {
                    if ($scope.IndividualCEHResponseList.CEHoursReportingYear < 30 || !$scope.IndividualCEHResponseList.CEHoursReportingYear) {
                        var errorObj = {
                            propName: "CEHoursReportingYear",
                            errorMessage: Messages.General.Errors.CreditMinimum
                        };
                        $scope.natValidation.errorMessages.push(errorObj);
                        $scope.natValidation.showError = true;
                        $scope.natValidation.isValid = false;
                    } else {
                        ShowLoader();
                        $scope.natValidation.showError = false;
                        $scope.natValidation.isValid = true;
                        initialApplicationEducationService.individualCEHSSave([$scope.IndividualCEHResponseList], sessionStorage.Key).then(function (response) {
                            if (!$scope.isRenewal)
                                $scope.$broadcast("SaveDeficiency", {});
                            $scope.openNextTab({
                                currentTab: $scope.tab
                            });
                            if ($scope.tab.skipNextTab == true) {
                                $scope.$emit('setCurrentTabNow');
                            }
                            if (skipNextTab && !!skipNextTab.fromDeficiency) {
                                $scope.$emit('deficiencyExecuted', { success: true });
                            }
                        }, function (error) {
                            HideLoader();
                        });
                    }
                } else {
                    if (!$scope.isRenewal) {
                        ShowLoader();
                        // $scope.$broadcast("SaveDeficiency", {});
                    }

                    $scope.openNextTab({
                        currentTab: $scope.tab
                    });
                    if ($scope.tab.skipNextTab == true) {
                        $scope.$emit('setCurrentTabNow');
                    }
                    if (skipNextTab && !!skipNextTab.fromDeficiency) {
                        $scope.$emit('deficiencyExecuted', { success: true });
                    }
                }
            }, function (error) {
                $scope.showStatusMessage(error.message, "error");
            })
            // }
        }

        function AuditVisitInfoSave() {
            var data = {
                "PageName": $scope.tab.name,
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserID,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        }

        var getIndividualCEHBYIndividualId = function () {
            ShowLoader();
            initialApplicationEducationService.getIndividualCEHBYIndividualId($scope.individualId, sessionStorage.Key).then(function (response) {
                //console.log(response);
                HideLoader();
                if (response.Status && response.IndividualCEHResponseList) {
                    $scope.IndividualCEHResponseList = response.IndividualCEHResponseList[0];
                } else {
                    $scope.IndividualCEHResponseList = {
                        ApplicationId: $scope.applicationId,
                        IndividualId: $scope.individualId,
                        IsActive: true,
                        IsDeleted: false,
                        CEHoursReportingYear: 30
                    }
                }
            }, function (error) {
                HideLoader();

            });
        }

        // $scope.SelectTypeAheadItem = function (item) {        //     
        //     var data = _.find($scope.Employers, function (item) {
        //         if (item.ProviderId == item.ProviderId) {
        //             return true;
        //         }
        //         else
        //             return false;
        //     });
        //     if (data) {
        //         $scope.educationInfo.ProviderName = data.EmployerName;
        //     }
        //     $scope.onotherChange();
        // }

        $scope.loadTypeahed = function (value) {
            var data = [];
            angular.forEach($scope.Employers, function (item) {
                if (_.startsWith(item.EmployerName.toLowerCase(), value.toLowerCase())) {
                    data.push(item);
                }
            });
            return data;
        }

        var loadDegreeType = function () {
            typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=degree")).then(function (response) {
                if ($scope.applicationInfo && ($scope.applicationInfo.LicenseApplicationForId == 1 || $scope.applicationInfo.LicenseApplicationForId == 3 || $scope.applicationInfo.LicenseApplicationForId == 5)) {
                    $scope.degrees = _.filter(response.ReferenceEntities, function (o) {
                        return (o.DegreeCd != 'NG' && o.DegreeCd != 'MA');
                    });
                } else {
                    if ($scope.applicationInfo && ($scope.applicationInfo.LicenseApplicationForId == 6)) {
                        $scope.degrees = _.filter(response.ReferenceEntities, function (o) {
                            return (o.DegreeCd != 'NG');
                        });
                    } else {
                        $scope.degrees = response.ReferenceEntities;
                    }
                }

            }, function (error) { });
        };

        $scope.changeSelection = function (val) {

            if ($scope.educationInfo && $scope.educationInfo.LicensedPriorTo == false && ($scope.educationInfo.DegreefromForeignStr == 'RS' || $scope.educationInfo.DegreefromForeignStr == 'ENGTECH' || $scope.educationInfo.Degree == 'NG'))
                $scope.disableApplication = true;
            else
                $scope.disableApplication = false;

            if (val) {
                $scope.educationInfo.LicensedPriorTo = null;
                $scope.disableApplication = false;
            }
        }

        $scope.curriculumValue = function (CurriculumnId) {
            var data = _.find($scope.curriculumList, function (o) {
                return (o.CurriculumId == CurriculumnId)
            });
            if (data) {
                $scope.educationInfo.CurriculumName = data.CurriculumName;
            } else
                $scope.educationInfo.CurriculumName = ''; //data.CurriculumName;
            //&& data.CurriculumName == "Other"
        }

        $scope.init = function () {
            try {
                $scope.fieldOfStudyTypesOriginal = [
                    { fieldOfStudyTypeId: 1, fieldOfStudyTypeName: 'Engineering', isSelected: false },
                    { fieldOfStudyTypeId: 2, fieldOfStudyTypeName: 'Land Surveying', isSelected: false },
                    { fieldOfStudyTypeId: 3, fieldOfStudyTypeName: 'Other', isSelected: false }
                ];
                $scope.getEducationConfig();
                //  if ($scope.applicationInfo.LicenseApplicationForId == 3 || $scope.applicationInfo.LicenseApplicationForId == 4 || $scope.applicationInfo.LicenseApplicationForId == 6) {
                getIndividualCEHBYIndividualId();
                loadDegreeType();
                initialApplicationEducationService.getAllProviderByProviderTypeIdAndProviderStatusTypeId(sessionStorage.Key).then(function (response) {
                    $scope.Employers = response.Employers;
                }, function (error) {
                    console.log(error);
                });
                $scope.getStateByCountryID($rootScope.DefaultCountryId)
                    .then(function (data) {
                        $scope.StatList = data.State;
                    }, function (error) {
                        console.log(error);
                    });
                // } 
                countryStateService.countryGetAll(sessionStorage.Key).then(function (response) {
                    if (!response.Status) {
                        showStatusMessage(response.Message, "error");
                    } else {
                        $scope.CountryList = response.Country;
                    }
                }, function (error) {
                    showStatusMessage(error, "error");
                });

                initialApplicationNcessService.getIndividualLicenseRecordBook($scope.individualId, $scope.applicationId, sessionStorage.Key).then(function (response) {
                    if (response.Status) {
                        if (response.IndividualLicenseRecordBookList) {
                            $scope.ncess = response.IndividualLicenseRecordBookList[0];
                            if (response.IndividualLicenseRecordBookList.length > 1) {
                                $scope.ncess = response.IndividualLicenseRecordBookList[1];
                            }
                        }
                    }
                    HideLoader();
                }, function (error) {
                    HideLoader();
                });
            } catch (ex) {

            }
        }

        $scope.saveContinuingEducation = function (currentForm) {
            ShowLoader("Please wait...");
            if (!validateForm(currentForm)) {
                initialApplicationstatusService.setEducation("invalid");
                return;
            }

            initialApplicationEducationService.educationSave($scope.educationInfo, sessionStorage.Key)
                .then(function (response) {
                    if (response.Status == false) {
                        ShowErrMsg("unableToSaveEducation", response.Message);
                        initialApplicationstatusService.setEducation("error");
                    } else {
                        initialApplicationstatusService.setEducation("success");
                        $scope.educationInfo = response.IndividualCECourseResponseList[0];
                    }
                    if (!$rootScope.ProcessLoaderExternally)
                        HideLoader();
                }, function (data) {
                    initialApplicationstatusService.setEducation("error");
                    ShowErrMsg("unableToSaveEducation", data);
                    if (!$rootScope.ProcessLoaderExternally)
                        HideLoader();
                });
        };

        $scope.setSaveDocumentRef1 = function (fn) {
            $scope.saveAllDocument1 = fn;
        };

        // $scope.setSaveDocumentRef = function (fn) {
        //     $scope.saveAllDocument = fn;
        // };

        // var processIndividualNameAndFields = function () {
        //     if ($scope.IndividualNameInfoList && $scope.IndividualNameInfoList.length > 0) {
        //         multipleFileUploadService.individualDocumentProcess(sessionStorage.Key, $scope.IndividualId,
        //             $scope.IndividualNameInfoList, $scope.documentNameTab.PropertyToProcess, $scope.documentNameTab.LinkType).then(function (responseData) {
        //                 $scope.IndividualNameInfoList = responseData;
        //                 //console.log($scope.IndividualNameInfoList);
        //                 HideLoader();
        //             });
        //     }
        // };

        var getNewEntityConfirmation = function (content) {
            return {
                EntityConfirmationId: 0,
                EntityId: $scope.individualId,
                EntityTy: 'I',
                ApplicationId: $scope.applicationId,
                ContentItemLkId: content.ContentItemLkId,
                ConfirmationResponse: null,
                Desc: '',
                IsActive: true,
                IsDeleted: false
            };
        };

        $scope.getEntityConfirmation = function () {
            var deferred = $q.defer();
            try {
                $q.all([
                    contentService.getContentByContentItemLkIdList([471, 502, 503, 514, 524], sessionStorage.Key),
                    EntityConfirmationService.getEntityConfirmation(sessionStorage.Key, $scope.individualId, 'I', $scope.applicationId)
                ])
                    .then(function (response) {
                        if (response.any('!item.Status')) {
                            HideLoader();
                            $scope.showStatusMessage(Messages.aeabcfa, "error");
                            return;
                        }
                        var contentItemLkId = null;
                        if ($scope.applicationInfo && [1, 2].indexOf($scope.applicationInfo.LicenseApplicationForId) != -1) {
                            contentItemLkId = 471;
                        } else if ($scope.applicationInfo && [3, 4, 7, 9, 11, 15, 18, 19, 20, 21].indexOf($scope.applicationInfo.LicenseApplicationForId) != -1) {
                            contentItemLkId = 502;
                        } else if ($scope.applicationInfo && [5].indexOf($scope.applicationInfo.LicenseApplicationForId) != -1) {
                            contentItemLkId = 514;
                        } else if ($scope.applicationInfo && [16].indexOf($scope.applicationInfo.LicenseApplicationForId) != -1) {
                            contentItemLkId = 524;
                        }
                        $scope.contentDataEducation = response[0].ContentItemLk.whereEquals(contentItemLkId, 'ContentItemLkId').firstOrDefault();
                        $scope.contentDataEducationTranscriptInNCEES = response[0].ContentItemLk.whereEquals(503, 'ContentItemLkId').firstOrDefault();
                        $scope.entityConfirmationExperience = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals(472, 'ContentItemLkId')
                            .firstOrDefault();

                        $scope.entityConfirmationEducation = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals(contentItemLkId, 'ContentItemLkId')
                            .firstOrDefault();

                        $scope.entityConfirmationNCEESRecord = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals(499, 'ContentItemLkId')
                            .firstOrDefault();

                        $scope.entityConfirmationEducationTranscriptInNCEES = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals(503, 'ContentItemLkId')
                            .firstOrDefault();

                        $scope.entityConfirmationQualNCEESEngExam = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals('QualNCEESEngExam', 'ContentItemLkCode')
                            .firstOrDefault();

                        $scope.entityConfirmationQualNCEESSurveyorWaiver = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals('QualNCEESSurveyorWaiver', 'ContentItemLkCode')
                            .firstOrDefault();
                        $scope.entityConfirmationQualNCEESSurveyorExam = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals('QualNCEESSurveyorExam', 'ContentItemLkCode')
                            .firstOrDefault();

                        $scope.entityConfirmationQualNCEESWaiver = response[1].EntityConfirmations
                            .whereEquals($scope.applicationId, 'ApplicationId')
                            .whereEquals('QualNCEESWaiver', 'ContentItemLkCode')
                            .firstOrDefault();

                        if (!$scope.entityConfirmationEducation) {
                            $scope.entityConfirmationEducation = getNewEntityConfirmation($scope.contentDataEducation);
                        }
                        if (!$scope.entityConfirmationEducationTranscriptInNCEES) {
                            $scope.entityConfirmationEducationTranscriptInNCEES = getNewEntityConfirmation($scope.contentDataEducationTranscriptInNCEES);
                        }
                        deferred.resolve({
                            Status: true
                        });
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }
            return deferred.promise;
        };

        $scope.$on("SaveRenewal", function (event, args) {
            $scope.saveContinuingEducation($scope.currentForm);
        });

        $scope.saveEntityConfirmation = function () {
            var deferred = $q.defer();

            try {
                var entityConfirmations = [$scope.entityConfirmationEducation];
                if ($scope.applicationInfo && [1, 2].indexOf($scope.applicationInfo.LicenseApplicationForId) == -1
                    && !!$scope.entityConfirmationEducationTranscriptInNCEES) {
                    entityConfirmations.push($scope.entityConfirmationEducationTranscriptInNCEES);
                }
                EntityConfirmationService.saveEntityConfirmation(sessionStorage.Key, entityConfirmations)
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.$on('SwitchingTab', function (event, args) {
            if (args.fromTab == $scope.tab.name) {
                if (!$scope.isBackoffice) {
                    $scope.saveAndNext(true);
                } else {
                    $scope.$broadcast("SaveDeficiency", {});
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
            }
        });

        $scope.$on('deficiencyResolve', function (event, args) {
            if (args.tabName == 'educationTraining') {
                $scope.saveAndNext({ fromDeficiency: true });
            }
        });

        $scope.clearTabConfirmation = function () {
            $scope.$emit("clearTabConfirmation", { currentTab: $scope.tab });
        };

        $scope.init();

    }
})();