(function () {
    'use strict'

    angular.module('app.core')
    .factory('ProfileLoaderService', ['$http', '$q', 'WebApiUrl', ProfileLoaderService]);

    function ProfileLoaderService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getUserProfiles = function (key, userId) {
            var postObj = {
                UserId: userId,
                ProfileId: 0,
                FromRow: 0,
                RecordPerPage: 0,
                Order: false,
                OrderByField: ''
            };
            return responseHandler($http.post(WebApiUrl + 'Profile/GetUserProfileList/' + key + '/', postObj));
        };

        return {
            getUserProfiles: _getUserProfiles
        };
    }
})();