﻿(function () {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeProviderDisiplineController", BackofficeProviderDisiplineController);

    BackofficeProviderDisiplineController.$inject = ["$rootScope", "$scope", "$state", "backofficeProviderDisiplineService", "utilityService", "$exceptionHandler", "WebApiUrl", "ProviderMultipleFileUploadService", 'Messages'];

    function BackofficeProviderDisiplineController($rootScope, $scope, $state, backofficeProviderDisiplineService, utilityService, $exceptionHandler, WebApiUrl, ProviderMultipleFileUploadService, Messages) {

        // //Tab Initilizer
        // $scope.$on("individualTabChanged", function(evt, ProviderId) {
        //     if ($rootScope.individualFirmCurrentTab.name == "compliance") {
        //         $scope.ProviderId = ProviderId;
        //         $scope.GetProviderDisiplineByIndividualId($scope.ProviderId);
        //         $rootScope.individualFirmCurrentTab.isLoadedOnce = true;
        //     }
        // });

        // $scope.saveDisipline = function(disipline, DisiplineForm) {
        //     ShowLoader("Please Wait...");
        //     if ($scope.validateData(DisiplineForm)) {
        //         disipline.ProviderId = $scope.ProviderId;

        //         disipline.isActive = true;
        //         disipline.IsDelete = false;
        //         backofficeProviderDisiplineService.saveDisipline(sessionStorage.Key, [disipline])
        //             .then(function(response) {
        //                 if ($scope.checkServerResponse(response)) {
        //                     $scope.GetProviderDisiplineByIndividualId($scope.ProviderId);
        //                 }
        //                 HideLoader();
        //             }, function(data) {
        //                 HideLoader();
        //                 if (data != null) { $scope.showStatusMessage(data.message, "error"); }
        //             });
        //     } else {
        //         HideLoader();
        //     }

        // }

        // $scope.GetProviderDisiplineByIndividualId = function(ProviderId) {
        //     ShowLoader("Please Wait...");
        //     try {
        //         backofficeProviderDisiplineService.GetDisipline(sessionStorage.Key, ProviderId)
        //             .then(function(response) {
        //                 if ($scope.checkServerResponse(response)) {
        //                     if (response.ProviderOtherList != null) {
        //                         $scope.disipline = response.ProviderOtherList[0];
        //                         HideLoader();
        //                     } else {
        //                         HideLoader();
        //                     }
        //                 } else {
        //                     HideLoader();
        //                 }
        //             }, function(data) {
        //                 HideLoader();
        //                 $scope.showStatusMessage(data, "error");
        //             });
        //     } catch (ex) {
        //         HideLoader();
        //         if (ex != null) { showStatusMessage(ex.message, "error"); }
        //     }
        // }

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, ProviderId) {
            if (ProviderId && $rootScope.individualFirmCurrentTab.name == "compliance") {
                $scope.ProviderId = ProviderId;
                $scope.saveData = {};
                GetDisipline();
                initDocument();
            }
        });
        $scope.documentConfig = {
            DocId: 8,
            LinkType: 'OI',
            PropertyToProcess: 'ProviderDisciplineId'
        };

        var initDocument = function () {
            $scope.documentUpload = undefined;
            setTimeout(function () {
                $scope.$apply(function () {
                    $scope.documentUpload = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                        // ApplicationId: _applicationId,
                        ProviderId: $scope.ProviderId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FRM",
                        LoadByDocIdAndDocCode: true,
                        DocId: 8,
                        DocCode: "D1008",
                        LoadDocumentsWithoutBroadCast: true,
                        //PDocumentLkType: 'OI'
                        LinkType: 'OI',
                        PropertyToProcess: 'ProviderDisciplineId'
                    };
                });
            }, 1000);
        };

        $scope.DownloadDocumentAPIURL = WebApiUrl + 'Individual/DownloadPDF/' + sessionStorage.Key + '?path=';
        $scope.DownloadDocument = function (path) {
            window.open($scope.DownloadDocumentAPIURL + path, '_blank');
        };

        $scope.setSaveDocumentRef = function (fn) {
            $scope.saveAllDocument = fn;
        };

        $scope.netValidator = {};
        $scope.saveDisipline = function (form) {

            if (validateForm(form) && $scope.saveData) {
                ShowLoader();
                if (!$scope.saveData.ProviderDisciplineId) {
                    $scope.saveData.ProviderId = $scope.ProviderId;
                    $scope.saveData.IsActive = true;
                }
                if (!$scope.saveData.ReferenceNumber) {
                    $scope.saveData.ReferenceNumber = new Date().valueOf();
                }

                $scope.saveData.LicensedIssuedStateId = getStateIdByCd($scope.saveData.getStateIdByCd);
                backofficeProviderDisiplineService.saveDisipline(sessionStorage.Key, [$scope.saveData]).then(function (res) {
                    if (res.Status && res.ProviderDisciplineList && res.ProviderDisciplineList.length > 0) {
                        $scope.documentUpload.LinkId = res.ProviderDisciplineList[0].ProviderDisciplineId;
                        if (typeof $scope.saveAllDocument == "function") {
                            $scope.saveAllDocument().then(function () {
                                GetDisipline();
                                $scope.cancelBtnClick();
                            }, function () { });
                        } else {
                            GetDisipline();
                            $scope.cancelBtnClick();
                        }
                    } else {
                        HideLoader();
                    }
                }, function (err) {
                    //  console.log(err);
                    HideLoader();
                });
            }
        };

        var getStateIdByCd = function (cd) {
            try {
                if ($scope.statesData) {
                    var state = $.grep($scope.statesData, function (i) {
                        return (i.StateCode == cd);
                    })
                    if (state && state.length > 0) {
                        return state[0].StateId;
                    }
                }
            } catch (ee) { console.log(ee); }
            return '';
        };


        var GetDisipline = function () {
            try {
                ShowLoader();
                backofficeProviderDisiplineService.GetDisipline(sessionStorage.Key, $scope.ProviderId).then(function (res) {
                    if (res.Status) {
                        $scope.ProviderDisciplineList = res.ProviderDisciplineList;
                        processDocument();
                    }
                    HideLoader();
                }, function (err) {
                    //    console.log(err);
                    HideLoader();
                });
            } catch (ex) {
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                $exceptionHandler(ex);
            }
        };

        var processDocument = function () {

            if ($scope.ProviderDisciplineList && $scope.ProviderDisciplineList.length > 0) {
                ProviderMultipleFileUploadService.providerDocumentProcess(sessionStorage.Key, $scope.ProviderId, $scope.documentConfig.DocId,
                    $scope.ProviderDisciplineList, $scope.documentConfig.PropertyToProcess, $scope.documentConfig.LinkType).then(function (responseData) {
                        $scope.ProviderDisciplineList = responseData;
                    });
            }
        };


        $scope.addNewBtnClick = function () {
            $scope.showScopeOfPracticeForm = true;
            $scope.saveData = {};
            $scope.saveData.ReferenceNumber = new Date().valueOf();
            initDocument();
        };

        $scope.cancelBtnClick = function () {
            $scope.saveData = {};
            $scope.showScopeOfPracticeForm = false;
        };

        $scope.editButtonClick = function (i) {
            $scope.showScopeOfPracticeForm = true;
            $scope.saveData = angular.copy(i);
            if (!$scope.saveData.ReferenceNumber) {
                $scope.saveData.ReferenceNumber = new Date().valueOf();
            }
            initDocument();
        };

        $scope.deleteButtonClick = function (i) {
            try {

                if (i && confirm(Messages.bbabace)) {
                    ShowLoader();
                    i.IsActive = false;
                    i.IsDeleted = true;
                    backofficeProviderDisiplineService.saveDisipline(sessionStorage.Key, [i]).then(function (res) {
                        if (res.Status) {
                            GetDisipline();
                        } else {
                            HideLoader();
                        }
                    }, function (err) {
                        //   console.log(err);
                        HideLoader();
                    });
                }
            } catch (ex) { }
        };


        //This function validates the for on click of submit button
        var validateForm = function (form, error) {
            $scope.netValidator.errorMessages = [];
            if (!!form) {
                form.$submitted = true;
                var errorMessages = angular.copy(form.errorMessages);
                angular.forEach(errorMessages, function (errorMessage, index) {
                    $scope.netValidator.errorMessages.push(errorMessage);
                });
            }
            if (!!error) {
                $scope.netValidator.errorMessages.push(error);
            }
            if (!!$scope.netValidator.errorMessages && $scope.netValidator.errorMessages.length > 0) {
                $scope.netValidator.showError = true;
                $scope.netValidator.isValid = false;
                return false;
            } else {
                $scope.netValidator.showError = false;
                $scope.netValidator.isValid = true;
                return true;
            }
        };
    }
})();