(function () {
    'use strict'

    angular.module('app.compliance')
    .factory('ComplianceSearchService', ['$http', '$q', 'WebApiUrl', ComplianceSearchService]);

    function ComplianceSearchService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getComplaints = function (key, request, pageNum, pageSize) {
            var urlParams = {
                PageNumber: pageNum,
                NoOfRecords: pageSize
            };
            return responseHandler($http.post(WebApiUrl + 'Complaint/ComplaintSearchWithPage/' + key + '/', request, { params: urlParams }));
        };

        var _saveComplaint = function (key, request) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/SaveComplaint/' + key + '/', request))
        };

        return {
            getComplaints: _getComplaints,
            saveComplaint: _saveComplaint
        };
    }
})();