(function () {
    'use strict';
    angular.module('app.core').filter('search', searchFilter);

    searchFilter.$inject = ['$filter'];

    function searchFilter($filter) {
        return function (items, searchText, implementedAs) {
            if (!searchText || '' == searchText || !items) {
                return items;
            }

            return items.filter(function (element, index, array) {
                var isMatched = false;
                if (implementedAs == 'document') {
                    if ((angular.isDate(element.EffectiveDate) && ($filter('date')(element.EffectiveDate, 'MM/dd/yyyy')).indexOf(searchText) > -1) ||
                        (!angular.isDate(element.EffectiveDate) && ($filter('date')(new Date(element.EffectiveDate), 'MM/dd/yyyy')).indexOf(searchText) > -1)
                    )
                        isMatched = true;
                    if (!!element.DocumentName && element.DocumentName.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                        isMatched = true;
                    if (!!element.DocumentTypeName && element.DocumentTypeName.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                        isMatched = true;
                } else if (implementedAs == 'correspondence') {
                    if ((angular.isDate(element.CommunicationLogDate) && ($filter('date')(element.CommunicationLogDate, 'MM/dd/yyyy')).indexOf(searchText) > -1) ||
                        (!angular.isDate(element.CommunicationLogDate) && ($filter('date')(new Date(element.CommunicationLogDate), 'MM/dd/yyyy')).indexOf(searchText) > -1)
                    )
                        isMatched = true;
                    if (!!element.Subject && element.Subject.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                        isMatched = true;
                    if (!!element.Type && element.Type.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                        isMatched = true;
                    if (!!element.CreatedByName && element.CreatedByName.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                        isMatched = true;
                }
                return isMatched;
            });

        };
    };

    angular.module('app.core').filter('booleanFilter', [function () {
        return function (input, column, trueOrFalse) {
            var ret = [];
            angular.forEach(input, function (v) {
                if ((!!v[column]) === trueOrFalse) {
                    ret.push(v);
                }
            });
            return ret;
        };
    }]);
})();
