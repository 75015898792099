(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("ExamVerificationController", ExamVerificationController);

    ExamVerificationController.$inject = ["$rootScope", "$scope",
        "examFactory", "utilityService", "typeValuesService", "$q", "WebApiUrl", "TypeValue"
    ];

    function ExamVerificationController($rootScope, $scope, examFactory, utilityService, typeValuesService, $q, WebApiUrl, TypeValue) {
        $scope.datePicker = {
            dateOpened: false,
            format: 'M!/yyyy',
            DateOptions: {
                datepickerMode: 'month',
                minMode: 'month',
            }
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        $scope.documentUpload = {
            ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
            ApplicationId: $scope.applicationId,
            IndividualId: $scope.individualId,
            Email: null,
            SendEmail: null,
            TransactionId: null,
            LicenseeReprint: false,
            DocumentLkToPageTabSectionId: 1,
            DocumentLkToPageTabSectionCode: "R",
            LoadByDocIdAndDocCode: true,
            DocId: 112,
            DocCode: "D1112",
            LoadDocumentsWithoutBroadCast: true,
            hideUploadedFor: true
        };

        function AuditVisitInfoSave() {
            var data = {
                "PageName": $scope.tab.text,
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };

        function getApplicableIntervalsForSchedule(examIntervals, examType) {
            var applicableIntervals = [];
            applicableIntervals = examIntervals.where(function (examInterval) {
                return ((!!examInterval.ExamTypeIds && examInterval.ExamTypeIds.split(',').indexOf(examType.ExamTypeId.toString()) != -1)
                    && !!utilityService.checkIfDateGreaterOrEqual(examInterval.PaymentWindowEndDate, new Date()));
            }).orderBy('ExamIntervalId').take(examType.NoOfScheduleInterval);
            return applicableIntervals;
        };

        function getApplicableIntervalsForReschedule(examIntervals, examType, currentExamIntervalId) {
            var applicableIntervals = [];
            applicableIntervals = examIntervals.where(function (examInterval) {
                return ((!!examInterval.ExamTypeIds && examInterval.ExamTypeIds.split(',').indexOf(examType.ExamTypeId.toString()) != -1)
                    && ((!!examType.ShowCurrentWindowForReschedule && examInterval.ExamIntervalId >= currentExamIntervalId) || (!examType.ShowCurrentWindowForReschedule && examInterval.ExamIntervalId > currentExamIntervalId)));
            }).orderBy('ExamIntervalId').take(examType.NoOfRescheduleInterval);
            return applicableIntervals;
        };

        var getNewEntityExam = function (examType) {
            return {
                ExamId: examType.ExamId,
                EntityId: $scope.individualId,
                EntityType: "I",
                EntityId: $scope.individualId,
                ExamNameId: examType.ExamNameId,
                ExamTypeId: examType.ExamTypeId,
                ExamStatusId: 1,
                ApplicationId: $scope.applicationId,
                IsActive: 1,
                IsDeleted: 0
            };
        }

        var init = function (isInitialLoad) {
            AuditVisitInfoSave();
            ShowLoader();
            $scope.applicableExams = [];
            var dataPromises = [];
            var entityExamPager = {
                sortType: 'ExamId',
                sortOrder: 'asc',
                currentPage: 1,
                totalRecords: 10,
                pageSize: 100,
                maxSize: 5,
                ExamId: null,
                FromDate: null,
                ToDate: null,
                EntityType: 'I',
                EntityId: $scope.individualId,
                ApplicationId: $scope.applicationId
            };

            var entityExamResultRequest = {
                EntityId: $scope.individualId
            };

            dataPromises.push(examFactory.getEntityExams(sessionStorage.Key, entityExamPager));
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=examtype")));
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=examinterval")));
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=feedetails&pullInActive=true")));
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=reexam")));
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=examstatus")));
            dataPromises.push(examFactory.getEntityExamResult(sessionStorage.Key, entityExamResultRequest));

            $q.all(dataPromises).then(function (response) {
                if (!!response[0].data.ExamList) {
                    $scope.allEntityExams = response[0].data.ExamList;
                    $scope.entityExams = response[0].data.ExamList
                        .whereEquals(1, 'ExamTypeId').whereEquals(1, 'ExamNameId');
                    $scope.entityStateExams = response[0].data.ExamList
                        .whereEquals(2, 'ExamNameId');
                }
                if (response[6].data.Status && response[6].data.EntityRecords) {
                    $scope.allExamResults = response[6].data.EntityRecords;
                }
                if (!!$scope.allEntityExams && !!$scope.allExamResults) {
                    $scope.allEntityExams.map(function (examItem) {
                        examItem.ExamResults = $scope.allExamResults.whereEquals(examItem.EntityExamId, "EntityExamId");
                    });
                }
                var listOfReExamRecord = [];
                if (!!response[4].Status && response[4].ReferenceEntities) {
                    $scope.reExamList = response[4].ReferenceEntities;
                    if (!!$scope.applicationInfo) {
                        _.each($scope.reExamList, function (i) {
                            if (i.LicenseTypeId == $scope.applicationInfo.LicenseTypeId && $scope.applicationInfo.ConfirmationNumber == i.AppATSID) {
                                listOfReExamRecord.push(i.ExamTypeId);
                            }
                        });
                    }
                }
                if (!!response[1].Status && response[1].ReferenceEntities) {
                    $scope.allExamTypes = response[1].ReferenceEntities;
                    if ($scope.applicationSubTypeId == 2) {
                        $scope.examTypes = response[1].ReferenceEntities
                            .whereEquals(2, 'ExamNameId').where(function (examType) {
                                return (!!examType.ExamTypeId && _.find(listOfReExamRecord, function (item) { return (examType.ExamTypeId == item) }));
                            });
                    }
                    else {
                        $scope.examTypes = response[1].ReferenceEntities
                            .whereEquals(2, 'ExamNameId').where(function (examType) {
                                return (!!$scope.applicationInfo && !!$scope.applicationInfo.LicenseApplicationForId && !!examType.LicenseTypeIds && examType.LicenseTypeIds.split(',').indexOf($scope.applicationInfo.LicenseApplicationForId.toString()) != -1);
                            });
                    }
                }
                if (!!response[2].Status && response[2].ReferenceEntities) {
                    $scope.examIntervals = response[2].ReferenceEntities;
                }
                if (!response[3].Status) {
                    $scope.showStatusMsg('-', 'some error occurred !');
                } else {
                    $scope.feeDetailRows = response[3].ReferenceEntities;
                }
                if (!!response[5].Status && response[5].ReferenceEntities) {
                    $scope.allExamStatus = response[5].ReferenceEntities;
                }

                if ($scope.allEntityExams) {
                    if (!!$scope.examIntervals) {
                        $scope.allEntityExams = $scope.allEntityExams.leftJoinObjects($scope.examIntervals, "item1.ExamIntervalId==item2.ExamIntervalId", 'this1, ExamIntervalName: item2.ExamIntervalName');
                    }
                    if (!!$scope.allExamTypes) {
                        $scope.allEntityExams = $scope.allEntityExams.leftJoinObjects($scope.allExamTypes, "item1.ExamTypeId==item2.ExamTypeId", 'this1, examType: this2');
                    }
                    if (!!$scope.allExamStatus) {
                        $scope.allEntityExams = $scope.allEntityExams.leftJoinObjects($scope.allExamStatus, "item1.ExamStatusId==item2.ExamStatusId", 'this1, ExamStatusName: item2.ExamStatusName');
                    }
                    if (!!$scope.allExamTypes && $scope.allExamTypes.length > 0) {
                        angular.forEach($scope.allEntityExams, function (entityExamItem) {
                            var applicableIntervals = getApplicableIntervalsForReschedule($scope.examIntervals, entityExamItem.examType, entityExamItem.ExamIntervalId);
                            entityExamItem.applicableIntervals = applicableIntervals;
                        })
                    }
                }

                if (!!$scope.examTypes && $scope.examTypes.length > 0) {
                    angular.forEach($scope.examTypes, function (examType) {
                        var entityStateExamByType = null;
                        var applicableIntervals = getApplicableIntervalsForSchedule($scope.examIntervals, examType);
                        if (!!$scope.entityStateExams && $scope.entityStateExams.length > 0) {
                            entityStateExamByType = $scope.entityStateExams.whereEquals(examType.ExamTypeId, 'ExamTypeId')
                                .whereNotIncludes([5, 6, 8, 9], 'ExamStatusId').firstOrDefault();
                        }
                        if (!entityStateExamByType) {
                            entityStateExamByType = getNewEntityExam(examType);
                            entityStateExamByType.examType = examType;
                            entityStateExamByType.applicableIntervals = applicableIntervals;
                            $scope.applicableExams.push(entityStateExamByType);
                        } else if (!!entityStateExamByType
                            && [1].includes(entityStateExamByType.ExamStatusId)) {
                            entityStateExamByType.IsSelected = true;
                            entityStateExamByType.examType = examType;
                            entityStateExamByType.applicableIntervals = applicableIntervals;
                            $scope.applicableExams.push(entityStateExamByType);
                        }
                    })
                }

                processApplicationFeeAndRenewalData();

                $scope.isConfigLoaded = true;
                if (!isInitialLoad) {
                    $scope.tab.tabSaveStatus = "success";
                }
                HideLoader();
            }, function (error) {
                HideLoader();
                console.log(error);
            });
        }

        $scope.saveAndNext = function (skipNextTab) {
            $scope.tab.skipNextTab = skipNextTab;
            $scope.$broadcast("SaveDeficiency", {});
            $scope.openNextTab({ currentTab: $scope.tab });
            if ($scope.tab.skipNextTab == true) {
                $scope.$emit('setCurrentTabNow');
            }
            if (skipNextTab && !!skipNextTab.fromDeficiency) {
                $scope.$emit('deficiencyExecuted', { success: true });
            }
        };


        var validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                if (!!currentForm.errorMessages)
                    $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            }

            if (!$scope.applicableExams.any('item.IsSelected')) {
                $scope.natValidation.errorMessages.push({
                    propName: 'isAtleastOneLicSelected',
                    errorMessage: "Please select at least one exam type to proceed with."
                });
            }
            return (!$scope.natValidation.errorMessages || $scope.natValidation.errorMessages.length == 0);
        };

        $scope.saveExam = function (currentForm) {
            if (!validateForm(currentForm)) {
                $scope.tab.tabSaveStatus = "invalid";
                $scope.showStatusMsg('-', $scope.natValidation.errorMessages);
                return;
            }
            else {
                $scope.hideStatusMsg();
            }
            var examsToSave = $scope.applicableExams.whereEquals(true, 'IsSelected');
            var examsToDelete = $scope.applicableExams.where('!item.IsSelected && !!item.EntityExamId').map(function (examItem) {
                examItem.IsDeleted = true;
                examItem.IsActive = false;
                return examItem;
            });

            if (!!examsToDelete) {
                examsToSave = examsToSave.concat(examsToDelete);
            }

            var examSavePromises = [];
            angular.forEach(examsToSave, function (examsToSaveItem) {
                var examInterval = examsToSaveItem.applicableIntervals.whereEquals(examsToSaveItem.ExamIntervalId, 'ExamIntervalId').firstOrDefault();
                examsToSaveItem.ExamStartDate = examInterval.ExamIntervalStartDate;
                examsToSaveItem.ExamEndDate = examInterval.ExamIntervalEndDate;
                examSavePromises.push(examFactory.saveEntityExam(sessionStorage.Key, examsToSaveItem));
            });

            $q.all(examSavePromises)
                .then(function (response) {
                    if (response.any('!item.data.Status')) {
                        $scope.tab.tabSaveStatus = "error";
                        $scope.showStatusMsg('-', "Some error occurred.");
                        return;
                    }
                    init(false);
                }, function (data) {
                    $scope.tab.tabSaveStatus = "error";
                    $scope.showStatusMsg('-', data);
                });
        };

        $scope.$on("SaveApplicationTab", function (event, args) {
            $scope.saveExam($scope.currentForm);
        });

        $scope.$on('SwitchingTab', function (event, args) {
            if (args.fromTab == $scope.tab.name) {
                if (!$scope.isBackoffice) {
                    $scope.saveAndNext(true);
                } else {
                    $scope.$broadcast("SaveDeficiency", {});
                    $scope.$emit('setCurrentTabNow', $scope.tab);
                }
            }
        });

        $scope.$on('deficiencyResolve', function (event, args) {
            if (args.tabName == 'exam') {
                $scope.saveAndNext({ fromDeficiency: true });
            }
        });

        $scope.clearTabConfirmation = function () {
            $scope.$emit("clearTabConfirmation", { currentTab: $scope.tab });
        };

        var getFeeDetailsByAppType = function (examTypeItem) {
            var feesForLicenseType = [];
            if (!!$scope.feeDetailRows) {
                feesForLicenseType = $scope.feeDetailRows
                    .whereEquals($scope.applicationInfo.ApplicationTypeId, 'ApplicationTypeId')
                    .where(function (feeDetailItem) {
                        return (!!feeDetailItem.IsActive && !!feeDetailItem.ExamTypeIds && feeDetailItem.ExamTypeIds.split(',').indexOf(examTypeItem.examType.ExamTypeId.toString()) != -1);
                    })
                    .whereEquals('INITIALEXAM', 'FeeTypeCode');
            }

            feesForLicenseType = feesForLicenseType.map(function (i) {
                i.TableName = 'EntityExam';
                i.TableLinkIdColumnName = 'EntityExamId';
                i.TableLinkId = examTypeItem.EntityExamId;
                i.isExamRow = true;
                i.needsProcessing = (!!examTypeItem.ExamStatusId && [1].indexOf(examTypeItem.ExamStatusId) != -1)
                return i;
            });

            if (!!feesForLicenseType && feesForLicenseType.length > 0) {
                return feesForLicenseType;
            } else {
                $scope.showStatusMsg('-', "No fees associated with this exam type: " + examTypeItem.ExamTypeId);
                throw ('No fees associated with this exam type: ' + examTypeItem.ExamTypeId);
            }
        };

        var mapRefLicenseTypeAndFeeDetails = function () {
            $scope.applicableExams.map(function (examTypeItem) {
                var feeDetailRowsByLicenseType = getFeeDetailsByAppType(examTypeItem);
                examTypeItem.applicationFee = feeDetailRowsByLicenseType.whereEquals('INITIALEXAM', 'FeeTypeCode').sum('FeeAmount');
            });
        };

        var processApplicationFeeAndRenewalData = function () {
            $scope.feeDetails.applicationFee = 0;
            var feeDetailRows = [];
            angular.forEach($scope.applicableExams, function (examTypeItem) {
                if (examTypeItem.IsSelected) {
                    feeDetailRows = feeDetailRows.concat(angular.copy(getFeeDetailsByAppType(examTypeItem)));
                }
            });
            mapRefLicenseTypeAndFeeDetails();
            $scope.feeDetails.fees = feeDetailRows;
            $scope.feeDetails.applicationFee = $scope.feeDetails.fees.whereEquals('INITIALEXAM', 'FeeTypeCode').sum('FeeAmount');
            $scope.feeDetails.totalFee = $scope.feeDetails.applicationFee;
        };

        $scope.licenseTypeSelectionChanged = function () {
            processApplicationFeeAndRenewalData();
        };

        $scope.openReschedule = function (examItem) {
            $scope.historyExamItem = angular.copy(examItem);
            examItem.ExamIntervalId = null;
            examItem.isEdit = true;
        };

        $scope.savePostponement = function (examsToSaveItem) {
            var examSavePromises = [];
            var examInterval = examsToSaveItem.applicableIntervals.whereEquals(examsToSaveItem.ExamIntervalId, 'ExamIntervalId').firstOrDefault();
            if ([7, 8].indexOf(examsToSaveItem.ExamStatusId) != -1) {
                examsToSaveItem.ExamStatusId = 2;
            }
            examsToSaveItem.ExamStartDate = examInterval.ExamIntervalStartDate;
            examsToSaveItem.ExamEndDate = examInterval.ExamIntervalEndDate;
            examsToSaveItem.ExamDate = null;
            examSavePromises.push(examFactory.saveEntityExam(sessionStorage.Key, examsToSaveItem));
            $scope.historyExamItem.EntityExamId = null;
            $scope.historyExamItem.ExamStatusId = 9;
            examSavePromises.push(examFactory.saveEntityExam(sessionStorage.Key, $scope.historyExamItem));
            ShowLoader();
            $q.all(examSavePromises)
                .then(function (response) {
                    if (response.any('!item.data.Status')) {
                        $scope.tab.tabSaveStatus = "error";
                        $scope.showStatusMsg('-', "Some error occurred.");
                        return;
                    }
                    examFactory.sendEligibilityAndEmail(sessionStorage.Key,
                        {
                            EntityId: examsToSaveItem.EntityId,
                            EntityType: 'I',
                            APplicationId: examsToSaveItem.ApplicationId,
                            Action: 'update',
                            EntityExamIds: [examsToSaveItem.EntityExamId],
                            TemplateCode: "StateExamAuthorizationToTest"
                        }
                    ).then(function (responseEligibility) {
                        if (!responseEligibility.data.Status) {
                            $scope.tab.tabSaveStatus = "error";
                            $scope.showStatusMsg('-', "Some error occurred.");
                            return;
                        }
                        init(false);
                    }, function (data) {
                        $scope.tab.tabSaveStatus = "error";
                        $scope.showStatusMsg('-', data);
                    })
                }, function (data) {
                    $scope.tab.tabSaveStatus = "error";
                    $scope.showStatusMsg('-', data);
                });
        };

        init(true);
    }
})();
