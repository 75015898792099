﻿(function () {
    'use strict'
    angular.module('natApp')
        .controller('BEInformationController', BEInformationController);

    BEInformationController.$inject = ['$rootScope', '$scope', '$q', 'EInformationDirectiveService', 'BackofficeProviderService', 'countryStateService', 'BusinessEntitytatusService', 'licensureApplicationListingService'];

    function BEInformationController($rootScope, $scope, $q, EInformationDirectiveService, BackofficeProviderService, countryStateService, BusinessEntitytatusService, licensureApplicationListingService) {
        var _key = sessionStorage.Key;
        $scope.ProviderAddress = {};
        $scope.ProviderPhysicalAddress = {};
        $scope.ProviderAddress.CountryId = $rootScope.DefaultCountryId;
        $scope.ProviderAddress.StateCode = 'AL';
        $scope.ProviderPhysicalAddress.CountryId = $rootScope.DefaultCountryId;
        $scope.ProviderPhysicalAddress.StateCode = 'AL';
        $scope.ContactPrimaryEmail = {};
        $scope.ContactPhone = {};
        $scope.init = function () {
            //initialize data

            GetCountryList();
            getApplicaiton();
            if ($scope.providerid && $scope.providerid != 0) {
                //edit b-entity
                $q.all([GetStateList($scope.ProviderAddress.CountryId, false, true), GetStateList($scope.ProviderPhysicalAddress.CountryId, true, false), ProviderAddressByProviderId(true, true), GetProviderContactByProviderId(), GetProviderById()]).then(function (res) {

                }, function () {
                });
            } else {
                //New b-entity
                $q.all([GetStateList($scope.ProviderAddress.CountryId, false, true), GetStateList($scope.ProviderPhysicalAddress.CountryId, true, false)]).then(function (res) {

                }, function () {
                });
            }
        };

        var getApplicaiton = function () {
            BackofficeProviderService.GetApplicationBYProviderId(sessionStorage.Key, $scope.providerid).then(function (response) {
                if (response.data.Status) {
                    console.log(response.data.ApplicationResponseList[0]);
                    $scope.application = response.data.ApplicationResponseList[0];
                }
            }, function (error) {

            });

            EInformationDirectiveService.GetProviderOther(sessionStorage.Key, $scope.providerid, $scope.applicationId).then(function (response) {
                if (response.data.Status) {
                    if (response.data.ProviderOtherList.length > 0) {
                        $scope.providerOther = response.data.ProviderOtherList[0];
                    }
                    else {
                        $scope.providerOther = {
                            "ProviderId": $scope.providerid,
                            "ApplicationId": $scope.applicationId,
                            "IsActive": true,
                            "IsDeleted": false,
                        }
                    }
                }
                else {
                    $scope.providerOther = {
                        "ProviderId": $scope.providerid,
                        "ApplicationId": $scope.applicationId,
                        "IsActive": true,
                        "IsDeleted": false,
                    }
                }
            }, function (error) {

            });
        }


        $scope.CountryChanged = function (id, isPhysicalAddress) {
            $scope.ProviderAddress.StateCode = '';
            if (isPhysicalAddress)
                $scope.StateList = [];
            else
                $scope.StateList2 = [];

            if ($scope.ProviderAddress.CountryId && !isPhysicalAddress) {
                GetStateList($scope.ProviderAddress.CountryId, false, true);
            }
            if ($scope.ProviderPhysicalAddress.CountryId && isPhysicalAddress) {
                GetStateList($scope.ProviderPhysicalAddress.CountryId, true, false);
            }
        };
        var GetCountryList = function () {


            try {
                countryStateService.countryGetAll(_key).then(function (res) {

                    if (res.Status) {
                        $scope.CountryList = res.Country;
                    }
                    //  deferred.resolve(res);
                }, function (err) {
                    console.log('response', res);
                    //deferred.reject(err);
                });
            }
            catch (ex) {
                console.log('Exception GetCountryList()', ex);
                //   deferred.reject(ex);
            }
            // return deferred.promise;
        };

        var ProviderSave = function () {
            var deferred = $q.defer();
            var reqdata = angular.copy($scope.ProviderDetails);
            reqdata.ApplicationId = $scope.applicationId;
            reqdata.SubmittedDate = new Date();
            reqdata.ProviderTypeId = 5;
            reqdata.ProviderStatusTypeId = 1;
            reqdata.IsEnabled = true;
            reqdata.IsActive = true;
            reqdata.IsDeleted = false;
            reqdata.CreatedBy = $scope.userId;
            reqdata.CreatedOn = new Date();
            BackofficeProviderService.ProviderSave(_key, $scope.applicationId, $scope.userId, reqdata).then(function (res) {
                if (res.data.Status) {
                    deferred.resolve(res.data);
                } else {
                    deferred.reject(res.data);
                }
            }, function (res) {
                deferred.reject(res.data);
            });
            return deferred.promise;
        };

        var ProviderApplicationSave = function () {
            var deferred = $q.defer();
            var data = {
                ProviderId: $scope.providerid,
                ApplicationId: $scope.applicationId,
                CreatedBy: $scope.userId
            };
            //debugger
            if ($scope.application) {
                //  $scope.application.ApplicationStatusId==
                // EInformationDirectiveService.ProviderApplicationSave
                // licensureApplicationListingService.generateApplicationId
                EInformationDirectiveService.UpdateLicenseTypebyApplicationId(_key, $scope.applicationId, $scope.application.LicenseTypeId, $scope.application).then(function (res) {
                    if (res.data.Status) {
                        deferred.resolve(res.data);
                    } else {
                        deferred.reject(res.data);
                    }
                }, function (res) {
                    deferred.reject(res.data);
                });

            }
            if ($scope.providerOther) {
                EInformationDirectiveService.SaveProviderOtherSave(_key, [$scope.providerOther]).then(function (res) {

                    if (res.data.Status) {
                        if (res.data.ProviderOtherList.length > 0) {
                            var PracticeDesc = angular.copy($scope.providerOther.PracticeDesc);
                            $scope.providerOther = res.data.ProviderOtherList;
                            $scope.providerOther.PracticeDesc = PracticeDesc;
                        }
                    } else {

                    }
                }, function (res) {

                });
            }
            return deferred.promise;
        };

        var GetProviderContactByProviderId = function () {
            var deferred = $q.defer();
            try {
                EInformationDirectiveService.GetProviderContactByProviderId(_key, $scope.providerid).then(function (res) {
                    if (res.data.Status) {
                        $scope.ProviderContact = res.data.ProviderContacts;
                        setProviderContact();
                    }
                    deferred.resolve(res);
                }, function (err) {
                    deferred.reject(err);
                    console.log('response', err);

                });
            }
            catch (ex) {
                deferred.reject(ex);
                console.log('Exception GetProviderContactByProviderId()', ex);
            }
            return deferred.promise;
        };

        var setProviderContact = function () {
            if ($scope.ProviderContact && $scope.ProviderContact.length > 0) {
                for (var i = 0; i < $scope.ProviderContact.length; i++) {
                    var contactTypeId = $scope.ProviderContact[i].ContactTypeId;
                    switch (contactTypeId) {
                        case 23:
                            {
                                $scope.ContactPrimaryEmail = $scope.ProviderContact[i];
                                $rootScope.emailofProvider = $scope.ProviderContact[i];

                                break;
                            }
                        case 21:
                            {
                                $scope.ContactPhone = $scope.ProviderContact[i];
                                break;
                            }
                    }
                }
            }
        };

        var SaveProviderAddress = function (address) {
            var deferred = $q.defer();
            EInformationDirectiveService.ProviderAddressSave(_key, address).then(function (response) {
                deferred.resolve(response);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        var SaveProviderContact = function (contact) {
            var deferred = $q.defer();
            if (contact && contact.length > 0) {
                EInformationDirectiveService.ProviderContactSave(_key, contact).then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.reject(response);
                });
            } else {
                deferred.resolve({});
            }
            return deferred.promise;
        };

        var GetProviderById = function () {
            try {

                EInformationDirectiveService.GetProviderById(_key, $scope.providerid).then(function (res) {
                    if (res.data.Status) {

                        $scope.ProviderDetails = res.data.ProviderResponse;
                    }
                }, function (err) {
                    console.log('response', err);
                });
            }
            catch (ex) {
                console.log('Exception GetProviderById()', ex);
            }
        };

        var GetStateList = function (cid, l1, l2) {
            var deferred = $q.defer();
            try {
                countryStateService.getStateByCountryID(_key, cid).then(function (res) {
                    if (res.Status) {
                        if (l1) {
                            $scope.StateList = res.State;
                        }
                        if (l2) {
                            $scope.StateList2 = res.State;
                        }
                    } else {
                        if (l1) {
                            $scope.StateList = [];
                        }
                        if (l2) {
                            $scope.StateList2 = [];
                        }
                    }
                    deferred.resolve(res);
                }, function (err) {
                    console.log('response', err);
                    deferred.reject(err);
                });
            }
            catch (ex) {
                console.log('Exception GetStateList()', ex);
                deferred.reject(ex);
            }
            return deferred.promise;
        };

        var ProviderAddressByProviderId = function (LoadProviderAdd) {
            var deferred = $q.defer();
            try {
                EInformationDirectiveService.ProviderAddressByProviderId(_key, $scope.providerid).then(function (res) {
                    if (res.data.Status) {
                        if (LoadProviderAdd) {
                            $scope.ProviderAddressList = res.data.ProviderAddress;
                            setProviderAddress();
                        }
                    }
                    deferred.resolve(res);
                }, function (err) {
                    console.log('response', err);
                    deferred.reject(err);

                });
            }
            catch (ex) {
                deferred.reject(ex);
                console.log('Exception ProviderAddressByProviderId()', ex);
            }
            return deferred.promise;
        };

        var setProviderAddress = function () {
            if ($scope.ProviderAddressList) {
                for (var i = 0; i < $scope.ProviderAddressList.length; i++) {
                    //set provider Address
                    if ($scope.ProviderAddressList[i].AddressTypeId == 4 && $scope.ProviderAddressList[i].AddressStatusId == 1) {
                        $scope.ProviderAddress = $scope.ProviderAddressList[i];
                        setTimeout(function () {
                            $scope.StateList = [];
                            $scope.$apply();
                        }, 0);
                        if ($scope.ProviderAddress.CountryId) {
                            GetStateList($scope.ProviderAddress.CountryId, false, true);
                        }
                        break;
                    }
                    if ($scope.ProviderAddressList[i].AddressTypeId == 6 && $scope.ProviderAddressList[i].AddressStatusId == 1) {
                        $scope.ProviderPhysicalAddress = $scope.ProviderAddressList[i];
                        setTimeout(function () {
                            $scope.StateList = [];
                            $scope.$apply();
                        }, 0);
                        if ($scope.ProviderPhysicalAddress.CountryId) {
                            GetStateList($scope.ProviderPhysicalAddress.CountryId, true, false);
                        }
                        break;
                    }
                }

            }
        };

        //Save All the Data
        var SaveBusinessEntityInformation = function (ProviderSaveRequired) {

            if (!validateForm($scope.bedetailsdetailsform)) {
                HideLoader();
                BusinessEntitytatusService.setBEInformation("invalid");
                $(document).scrollTop();
                return;
            } else if ($scope.ProviderAddress) {
                //make provider data ready
                ProviderApplicationSave();
                if (!$scope.ProviderAddress.ProviderAddressId || $scope.ProviderAddress.ProviderAddressId == 0) {
                    $scope.ProviderAddress.AddressTypeId = 4;
                    $scope.ProviderAddress.AddressStatusId = 1;
                    $scope.ProviderAddress.BadAddress = false;
                    $scope.ProviderAddress.IsActive = 1;
                    $scope.ProviderAddress.IsDeleted = 0;
                    $scope.ProviderAddress.CreatedBy = $scope.userId;
                    $scope.ProviderAddress.ProviderId = $scope.providerid;
                }
                if (!$scope.ProviderPhysicalAddress.ProviderAddressId || $scope.ProviderPhysicalAddress.ProviderAddressId == 0) {
                    $scope.ProviderPhysicalAddress.AddressTypeId = 6;
                    $scope.ProviderPhysicalAddress.AddressStatusId = 1;
                    $scope.ProviderPhysicalAddress.BadAddress = false;
                    $scope.ProviderPhysicalAddress.IsActive = 1;
                    $scope.ProviderPhysicalAddress.IsDeleted = 0;
                    $scope.ProviderPhysicalAddress.CreatedBy = $scope.userId;
                    $scope.ProviderPhysicalAddress.ProviderId = $scope.providerid;
                }


                $scope.paymentPersonalData.ResidentialAddress = $scope.ProviderAddress;

                //make contactlist ready for save
                //Primary email
                var contactList = [];
                if ($scope.ContactPrimaryEmail) {
                    if ($scope.ContactPrimaryEmail.ProviderContactId) {
                        $scope.ContactPrimaryEmail.ContactStatusId = 1;
                        $scope.ContactPrimaryEmail.ModifiedBy = $scope.userId;
                        contactList.splice(0, 0, $scope.ContactPrimaryEmail);
                    } else {
                        $scope.ContactPrimaryEmail.ContactStatusId = 1;
                        $scope.ContactPrimaryEmail.IsActive = 1;
                        $scope.ContactPrimaryEmail.IsDeleted = 0;
                        $scope.ContactPrimaryEmail.CreatedBy = $scope.userId;
                        $scope.ContactPrimaryEmail.ProviderId = $scope.providerid;
                        $scope.ContactPrimaryEmail.ContactTypeId = 23;
                        contactList.splice(0, 0, $scope.ContactPrimaryEmail);
                    }
                    $scope.paymentPersonalData.EmailAddressContact = $scope.ContactPrimaryEmail;
                    $rootScope.emailofProvider = $scope.ContactPrimaryEmail;

                }

                //Contact Phone
                if ($scope.ContactPhone) {
                    if ($scope.ContactPhone.ProviderContactId) {
                        $scope.ContactPhone.ModifiedBy = $scope.userId;
                        contactList.splice(0, 0, $scope.ContactPhone);
                    } else {
                        $scope.ContactPhone.ContactStatusId = 1;
                        $scope.ContactPhone.IsActive = 1;
                        $scope.ContactPhone.IsDeleted = 0;
                        $scope.ContactPhone.CreatedBy = $scope.userId;
                        $scope.ContactPhone.ProviderId = $scope.providerid;
                        $scope.ContactPhone.ContactTypeId = 21;
                        contactList.splice(0, 0, $scope.ContactPhone);
                    }
                    //$scope.paymentPersonalData.CellPhoneContact = $scope.ContactPhone;
                }
                ShowLoader();
                //if edit save all at a time
                if ($scope.providerid && $scope.providerid != 0 && ProviderSaveRequired) {
                    $q.all([ProviderSave(), SaveProviderAddress($scope.ProviderAddress), SaveProviderAddress($scope.ProviderPhysicalAddress), SaveProviderContact(contactList)]).then(function (res) {
                        ProviderAddressByProviderId(true);
                        GetProviderContactByProviderId();
                        $scope.onformsubmitsuccess({ provider: res[0] });
                        BusinessEntitytatusService.setBEInformation("success");
                    }, function (res) {
                        BusinessEntitytatusService.setBEInformation("error");
                        HideLoader();
                    });

                }
                //Provider details already save so saved remaining data
                else if ($scope.providerid && $scope.providerid && !ProviderSaveRequired) {
                    $q.all([SaveProviderAddress($scope.ProviderAddress), SaveProviderAddress($scope.ProviderPhysicalAddress), SaveProviderContact(contactList)]).then(function (res2) {
                        ProviderAddressByProviderId(true);
                        GetProviderContactByProviderId();
                        BusinessEntitytatusService.setBEInformation("success");
                    }, function (res) {
                        BusinessEntitytatusService.setBEInformation("error");
                        HideLoader();
                    });
                }
                //If new provider then first save provider there save other records
                else {
                    ProviderSave().then(function (res) {
                        $scope.providerid = res.ProviderId;

                        if (res.Status) {
                            $scope.onformsubmitsuccess({ provider: res });
                            //save other data
                            SaveBusinessEntityInformation(false);
                        } else {
                            HideLoader();
                        }
                    }, function () {
                        HideLoader();
                    });
                }
            }

        };

        var ShowErrMsg = function (propName, errorMessage) {
            propName = (!!propName) ? propName : "BusinessInformation";
            errorMessage = (!!errorMessage) ? errorMessage : $scope.EmployerInformationConfig.ValidationMsg.APIError;
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            var propExist = false;
            for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                if ($scope.natValidation.errorMessages[i].propName == propName)
                    propExist = true;
            }
            if (!propExist)
                $scope.natValidation.errorMessages.push({
                    propName: propName,
                    errorMessage: errorMessage
                });

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };
        var validateForm = function (form) {
            //validate 
            //Form custom
            $scope.natValidation.errorMessages = [];
            if (!!form && $scope.natValidation) {
                if ($scope.natValidation.errorMessages == undefined) {
                    $scope.natValidation.errorMessages = [];
                }
                form.$submitted = true;
                var errorMessages = angular.copy(form.errorMessages);
                angular.forEach(errorMessages, function (errorMessage, index) {
                    $scope.natValidation.errorMessages.push(errorMessage);
                });
            }
            ////validate radio
            //if (!$scope.SelfreportableItem1 || ($scope.SelfreportableItem1.ContentItemResponse != true && $scope.SelfreportableItem1.ContentItemResponse != false)) {
            //    ShowErrMsg('SelfreportableItem1', 'Please answer Self Reportable Event question 1');
            //}

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }

        };
        $scope.init();
        $scope.$on($scope.eventname, function (event, args) {
            SaveBusinessEntityInformation(true);
        });
    }
})();