//Code Generated by Inlumon Code generation tool
(function () {
    'use strict';

    angular
        .module('app.backofficeAdministor')
        .controller("RevenueDepositController", RevenueDepositController);

    RevenueDepositController.$inject = ["$rootScope", "$scope", "$q", "revenueDepositService", "typeValuesService", "utilityService",
        "TypeValue", "multipleFileUploadService", "CouncilInfoService", "profileManagemetFactory", "Messages"
    ];

    function RevenueDepositController($rootScope, $scope, $q, revenueDepositService, typeValuesService, utilityService, TypeValue, multipleFileUploadService, CouncilInfoService, profileManagemetFactory, Messages) {

        // #region Common Code
        $scope.$on("entityTabChanged", function (evt, entityId) {
            if ($rootScope.entityCurrentTab.name == "revenueDeposit") {
                $scope.entityId = entityId;
                $scope.init();
            }
        });

        $scope.viewConfig = {
            showAddRevenueDepositBlock: false,
            isRevenueDepositEdit: false
        };

        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/d!/yyyy',
            startDateOptions: {
                minDate: null,
            },
            endDateOptions: {}
        };

        $scope.searchData = {

        };

        $scope.secondHeaders = [{
            name: 'Account',
            sortType: 'Type',
            sortOrder: 'asc'
        }, {
            name: 'Class of Revenue',
            sortType: 'ClassOfRevenue',
            sortOrder: 'asc'
        },
        {
            name: 'Current Fy Amount',
            sortType: 'CurFyAmtDep',
            sortOrder: 'asc'
        },
        {
            name: 'Revenue In Advance',
            sortType: 'RenenueInAdvance',
            sortOrder: 'asc'
        },
        {
            name: 'Total Amount',
            sortType: 'TotalAmtDep',
            sortOrder: 'asc'
        }
        ];

        $scope.onNgInit = function () {
            $scope.isConfigLoaded = false;
            $scope.current = {};
            $scope.uiLogic = {};
            $scope.headers = {};
            getConfig();
            $scope.documentUploadRevenueDeposit = {
                ApplicationId: $scope.applicationId,
                IndividualId: $scope.entityId,
                DocumentLkToPageTabSectionId: 1,
                DocumentLkToPageTabSectionCode: "R",
                LoadByDocIdAndDocCode: true,
                DocId: 81,
                DocCode: "D1081",
                LoadDocumentsWithoutBroadCast: false,
                IsLinkedDocControl: true,
                LinkType: 'NC',
                PropertyToProcess: 'IndividualRevenueDepositId',
                DocumentUploadedForId: (($rootScope.routeData && !!$rootScope.routeData.documentUploadedForId) ? $rootScope.routeData.documentUploadedForId : 1),
                SendUploadNotification: ($rootScope.routeData && $rootScope.routeData.queueType == 'DNQ'),
                NotificationToUserId: (!!$scope.applicationInfo ? $scope.applicationInfo.DnqMarkedBy : '')
            };

            profileManagemetFactory.usersSearch(sessionStorage.Key, 1, 10000, {
                UserTypeId: 1
            }).then(function (res) {
                if (res.Status) {
                    $scope.userSearchList = res.Users;
                }
                HideLoader();
            }, function (err) {
                HideLoader();
                showStatusMessage(err.Message, "error");
            });
        };

        $scope.clearValidation = function () {
            $scope.hideStatusMsg();
        };

        var getUIConfig = function () {
            var configToUse = $scope.config.UIConfigs[$scope.configName];
            if (!configToUse) {
                configToUse = $scope.config.UIConfigs['Default'];
            }
            return configToUse;
        };

        var getConfig = function () {
            utilityService.getConfigData('/components/backoffice/administrator/revenue-deposit/revenue-deposit.config.json').
                then(function (data) {
                    var config = data;
                    $scope.config = config;
                    $scope.uiConfig = getUIConfig();
                    var headersRevenueDeposit = {};
                    var fieldListRevenueDeposit = {};

                    for (var key in $scope.uiConfig.Fields.RevenueDeposit) {
                        if ($scope.uiConfig.Fields.RevenueDeposit[key].showInTable) {
                            headersRevenueDeposit[key] = $scope.uiConfig.Fields.RevenueDeposit[key];
                        }
                        if ($scope.uiConfig.Fields.RevenueDeposit[key].showInForm) {
                            fieldListRevenueDeposit[key] = $scope.uiConfig.Fields.RevenueDeposit[key];
                        }
                    }

                    $scope.headersRevenueDeposit = headersRevenueDeposit;
                    $scope.fieldListRevenueDeposit = fieldListRevenueDeposit;

                    loadData(true);
                }, function (error) {
                    $scope.loadingRevenueDeposit(false);
                });
        };

        var resetPagination = function () {
            $scope.paginationParamsRevenueDeposit = {
                showPager: false
            };
        };

        var loadData = function (loadAllData) {
            var dataPromises = {
                revenueDeposit: revenueDepositService.revenueDepositGetByEntityId(sessionStorage.Key, $scope.searchData.BatchNumber)
            };
            // if (!!loadAllData) {
            //     dataPromises.certificationtype = typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=certificationtype"));
            // }

            $q.all(dataPromises)
                .then(function (response) {
                    var revenueDeposits = [];
                    for (var prop in response) {
                        if (!response[prop].Status) {
                            $scope.loadingRevenueDeposit(false);
                            $scope.showStatusMsg('-', response[prop].Message);
                            return;
                        }
                    }
                    if (response.revenueDeposit) {
                        revenueDeposits = response.revenueDeposit.EntityRecords;
                    }
                    // if (response.revenueDeposittype) {
                    //     $scope.allLicenseKindTypes = response.revenueDeposittype.ReferenceEntities;
                    //     $scope.revenueDepositTypes = $scope.processRefEntityData(response.revenueDeposittype.ReferenceEntities);
                    // }
                    $scope.revenueDeposits = processRevenueDepositData(revenueDeposits);
                    resetPagination();
                    $scope.isConfigLoaded = true;
                    $scope.loadingRevenueDeposit(false);
                }, function (data) {
                    $scope.loadingRevenueDeposit(false);
                    $scope.showStatusMsg('-', data.message);
                });
        };

        $scope.searchRevenueDeposit = function () {
            $scope.loadingRevenueDeposit(true);
            loadData(false);
        };

        $scope.processRefEntityData = function (entityRefData) {
            return entityRefData;
        };

        $scope.entityFilterDataByConfig = function (entityData) {
            return entityData;
        };

        $scope.saveAndNext = function () {
            $scope.loadingRevenueDeposit(true, "Please wait");
            $scope.openNextTab({
                currentTab: $scope.tab
            });
            $scope.loadingRevenueDeposit(false);
        };

        //#endregion

        // #region RevenueDeposit Code

        $scope.validateRevenueDepositForm = function (currentForm) {
            var formData = {
                errorMessages: ((!!currentForm && !!currentForm.errorMessages) ? angular.copy(currentForm.errorMessages) : [])
            };

            if (!(!!formData.errorMessages && formData.errorMessages.length > 0)) {
                return true;
            } else {
                var errorMessages = [];
                angular.forEach(formData.errorMessages, function (obj) {
                    errorMessages.push(obj.errorMessage);
                })
                $scope.showStatusMsg('-', errorMessages);
                return false;
            }
        };

        var loadRevenueDepositDefaultsFromConfig = function (entityDataItem) {
            if ($scope.uiConfig.Defaults && $scope.uiConfig.Defaults.RevenueDeposit) {
                for (var key in $scope.uiConfig.Defaults.RevenueDeposit) {
                    if ($scope.uiConfig.Defaults.RevenueDeposit[key] != undefined) {
                        entityDataItem[key] = $scope.uiConfig.Defaults.RevenueDeposit[key];
                    }
                }
            }
            return entityDataItem;
        };

        var getNewRevenueDeposit = function () {
            var revenueDeposit = {
                EntityId: $scope.entityId,
                IndividualId: $scope.entityId,
                EntityType: $scope.entityType,
                IsActive: true,
                IsDeleted: false,
                BeginDate: new Date()
            };
            revenueDeposit = loadRevenueDepositDefaultsFromConfig(revenueDeposit);
            return revenueDeposit;
        };

        $scope.addEditRevenueDeposit = function (isRevenueDepositEdit, revenueDeposit) {
            $scope.loadingRevenueDeposit(true, "Please wait");
            $scope.viewConfig.isRevenueDepositEdit = isRevenueDepositEdit;
            $scope.viewConfig.showAddRevenueDepositBlock = true;
            if (!$scope.viewConfig.isRevenueDepositEdit) {
                $scope.revenueDeposit = getNewRevenueDeposit();
            } else {
                $scope.revenueDeposit = angular.copy(revenueDeposit);
            }
            $scope.loadingRevenueDeposit(false);
        };

        $scope.closeAddEditRevenueDeposit = function () {
            $scope.viewConfig.showAddRevenueDepositBlock = false;
            $scope.clearValidation();
        };

        var processRevenueDepositData = function (revenueDepositData) {
            if (!!revenueDepositData) {
                // revenueDepositData = revenueDepositData.leftJoinObjects($scope.allRevenueDepositTypes, 'item1.RevenueDepositTypeId==item2.RevenueDepositTypeId', 'this1, RevenueDepositTypeName: item2.RevenueDepositTypeName');
                revenueDepositData = $scope.entityFilterDataByConfig(revenueDepositData);
            }
            return revenueDepositData;
        };

        var updateRevenueDepositReferenceData = function () {
            // var revenueDeposittype = $scope.allRevenueDeposits.whereEquals($scope.RevenueDeposit.RevenueDepositId, 'RevenueDepositId').firstOrDefault();
            // var revenueDepositStatus = $scope.allLicenseStatuses.whereEquals($scope.RevenueDeposit.LicenseStatusTypeId, 'LicenseStatusTypeId').firstOrDefault();

            // if (!!revenueDeposittype && !!revenueDeposittype.RevenueDepositCode) {
            //     $scope.RevenueDeposit.RevenueDepositCode = revenueDeposittype.RevenueDepositCode;
            // }
            // if (!!revenueDepositStatus && !!revenueDepositStatus.LicenseKindStatusCode) {
            //     $scope.RevenueDeposit.LicenseStatusCode = revenueDepositStatus.LicenseStatusCode;
            // }
        };

        $scope.saveRevenueDeposit = function (revenueDepositForm) {
            $scope.hideStatusMsg();
            revenueDepositForm.$submitted = true;
            if ($scope.validateRevenueDepositForm(revenueDepositForm)) {
                $scope.loadingRevenueDeposit(true, "Please wait");
                updateRevenueDepositReferenceData();
                $scope.saveRevenueDepositRecord(false);
            }
        };

        $scope.saveRevenueDepositRecord = function (saveDocument) {
            var revenueDepositData = angular.copy($scope.revenueDeposit);
            revenueDepositService.revenueDepositSave([revenueDepositData],
                sessionStorage.Key).then(function (response) {
                    if (response.Status) {
                        revenueDepositData.IndividualRevenueDepositId = response.EntityRecords[0].IndividualRevenueDepositId;
                        if (!!saveDocument) {
                            $scope.documentUploadRevenueDeposit.individualDocumentSave(revenueDepositData.IndividualRevenueDepositId).then(function (documentResponse) {
                                if (documentResponse.Status) {
                                    loadData(false);
                                    $scope.closeAddEditRevenueDeposit();
                                    $scope.showStatusMsg('+', response.Message);
                                } else {
                                    $scope.showStatusMsg('-', documentResponse.Message);
                                }
                            }, function (error) { });
                        } else {
                            loadData(false);
                            $scope.closeAddEditRevenueDeposit();
                            $scope.showStatusMsg('+', response.Message);
                        }
                    } else {
                        $scope.showStatusMsg('-', response.Message);
                    }
                    $scope.loadingRevenueDeposit(false);
                }, function (data) {
                    $scope.loadingRevenueDeposit(false);
                    $scope.showStatusMsg('-', data.message);
                });
        };


        $scope.deleteRevenueDeposit = function (revenueDeposit) {
            if (confirm(Messages.General.Confirm.Delete)) {
                $scope.loadingRevenueDeposit(true, "Please wait");
                $scope.revenueDeposit = angular.copy(revenueDeposit);
                $scope.revenueDeposit.IsDeleted = true;
                $scope.saveRevenueDepositRecord(false);
            }
        };

        $scope.isConfiguredRevenueDepositField = function (fieldName) {
            return ($scope.fieldListRevenueDeposit[fieldName]);
        };

        $scope.trialBalanceReportWithPager = function () {
            ShowLoader();
            CouncilInfoService.TrialBalanceSummeryGet(sessionStorage.Key, 'F', $scope.searchData.BatchNumber).then(function (response) {
                HideLoader();
                //Records
                if (response.data.PagerVM) {
                    $scope.secondDailyDepositList = response.data.PagerVM.Records;
                    $scope.searchData.totalRecords = response.data.PagerVM.TotalRecords;
                }
                $('#dailyDepositListModal').modal('show');
            }, function (error) {
                HideLoader();
            });
        };

        $scope.tableEvents = {
            addEditRevenueDeposit: $scope.addEditRevenueDeposit,
            deleteRevenueDeposit: $scope.deleteRevenueDeposit
        };

        //#endregion
        $scope.onNgInit();
    };
})();