(function () {
  'use strict';
  angular.module('natApp').filter('unsafe', unsafeFilter);

  unsafeFilter.$inject = ['$sce'];

  function unsafeFilter($sce) {
    return $sce.trustAsHtml;
  };

})();



