﻿


(function () {
    'use strict'
    angular.module('app.backofficeProvider')
        .factory('backofficeFirmService', ['$http', '$q', 'WebApiUrl', 'AppConfig', function ($http, $q, WebApiUrl, AppConfig) {

            var firmSearchContent = null;
            var firmSearchContentType = null;
            var _setFirmSearchContent = function (value, type) {
                firmSearchContent = value;
                firmSearchContentType = type;
            };
            var _getFirmSearchContent = function () {
                return { firmSearchContent: firmSearchContent, firmSearchContentType: firmSearchContentType };
            };

            var _getEmployerList = function (key, searchString, providerTypeId, searchType) {
                var deferred = $q.defer();
                $http.get(WebApiUrl + "Individual/GetEmployerNameAndAddress/" + key + "?SearchString=" + escape(searchString) + "&ProviderTypeId=" + providerTypeId + "&SearchType=" + searchType)
                    .then(function (data) {
                        data = data.data;
                        deferred.resolve(data);
                    }, function (error) {
                        error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            };

            var _individualFinanceByIndividualId = function (Key, IndividualId, providerId) {
                if (IndividualId == 0) {
                    return $http.get(WebApiUrl + 'Payment/GetPaymentDetailsByIndividualId/' + Key + '?IndividualId=&ProviderId=' + providerId + '&Source=WYRealEstate');
                }
                else {
                    return $http.get(WebApiUrl + 'Payment/GetPaymentDetailsByIndividualId/' + Key + '?IndividualId=' + IndividualId + '&ProviderId=&Source=WYRealEstate');
                }
            };

            var _getInvoiceMasterandDetail = function (Key, IndividualId, providerId) {
                if (IndividualId == 0) {
                    return $http.get(WebApiUrl + 'Payment/GetInvoiceMasterandDetail/' + Key + '?IndividualId=&ProviderId=' + providerId + '&Source=WYRealEstate');
                }
                else {
                    return $http.get(WebApiUrl + 'Payment/GetInvoiceMasterandDetail/' + Key + '?IndividualId=' + IndividualId + '&ProviderId=&Source=WYRealEstate');
                }
            };

            return {
                setFirmSearchContent: _setFirmSearchContent,
                getFirmSearchContent: _getFirmSearchContent,
                getEmployerList: _getEmployerList,
                individualFinanceByIndividualId: _individualFinanceByIndividualId,
                getInvoiceMasterandDetail: _getInvoiceMasterandDetail
            };
        }]);
})();

