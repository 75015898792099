(function() {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeIndividualLicenseController", BackofficeIndividualLicenseController);

    BackofficeIndividualLicenseController.$inject = ["$rootScope", "$scope", '$q', 'feePaymentService', "backofficeIndividualLicenseService", "backofficeIndividualEducationService", "multipleFileUploadService", "dcaIService",
        "utilityService", "typeValuesService", "Messages"
    ];

    function BackofficeIndividualLicenseController($rootScope, $scope, $q, feePaymentService, backofficeIndividualLicenseService, backofficeIndividualEducationService, multipleFileUploadService, dcaIService,
        utilityService, typeValuesService, Messages) {
        //Tab Initilizer
        $scope.$on("individualTabChanged", function(evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "credential") { // && !$rootScope.individualCurrentTab.isLoadedOnce) {
                $scope.individualId = individualId;
                if (!$scope.isLoadedOnce) {
                    $scope.isLoadedOnce = true;
                    $scope.getPaymentMethod();
                }
                $scope.showRenewedLicenses = false;

                $scope.individualLicenseDetailBYIndividualId($scope.individualId);
                $rootScope.individualCurrentTab.isLoadedOnce = true;
                $scope.loadApplicationConfiguration();
                $scope.getLicenseTypes();
                $scope.getLicenseStatuses();
                $scope.getLicenseDisciplineStatuses();
            }
        });

        $scope.$watch('showRenewedLicenses', function(newVal) {
            $scope.filterLicenses();
        });


        $scope.isBackoffice = (sessionStorage.isBackOfficeUser == 'true' || sessionStorage.isBackOfficeUser == true);
        $scope.supervisorTab = {
            text: "Supervisor Information"
        }
        $scope.userId = sessionStorage.UserID;
        $scope.individualLicense = {};
        $scope.ViewLicenseDetailObject = {};
        $scope.natValidation = {
            licenseEndorsement: {},
            telemedicine: {},
            obsSurgery: {},
            cdsCmeVal: {},
            miscData: {}
        };
        $scope.validator = {};
        $scope.showLicenseForm = false;
        $scope.showLicenseOldDetails = false;
        $scope.isLicenseEdit = false;

        $scope.viewConfig = {
            showLicenseViewBlock: false,
            showCEHTable: false,
            showAddCEHButton: true,
            viewLicense: {},
        };

        $scope.datePicker = {
            beginDateOpened: false,
            endDateOpened: false,
            beginDateOpened1: false,
            endDateOpened1: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };

        $scope.cehDatePicker = {
            dateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };

        $scope.newLicenseDatePicker = {
            originalLicenseDateOpened: false,
            expirationDateOpened: false,
            statusEffectiveDateOpened: false,
            dateOpened: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };

        if (typeof($scope.showYesNoQuestions) == 'undefined') {
            $scope.showYesNoQuestions = true;
        }

        $scope.checkPaymentOnLicense = function(license) {
            var deferred = $q.defer();

            try {
                if (!license.ApplicationId) {
                    deferred.resolve();
                    return deferred.promise;
                }

                feePaymentService.getInvoiceMasterByIndividualIdAndInvoiceStatus(sessionStorage.Key, license.IndividualId, license.ApplicationId)
                    .then(function(data) {
                        if (data.Status) {
                            var invoice = data.InvoiceMasters.firstOrDefault();

                            if (!!invoice && invoice.InvoiceStatus == 'CLOSED') {
                                license.ApplicationIsPaid = true;
                            } else if (!invoice) {
                                license.ApplicationIsPaid = true; //FOr now, if it can't find an invoice for the application, allow it to show
                            } else {
                                license.ApplicationIsPaid = false;
                            }
                        }
                        deferred.resolve(data);
                    }, function(err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.validateForm = function(currentForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.viewOldLicenseDetail = function(licenseObject, showTable) {
            $scope.ViewLicenseDetailObject = licenseObject;
            $scope.showLicenseOldDetails = !showTable;
        };

        $scope.addLicense = function() {
            $scope.individualLicense = {
                IndividualId: $scope.individualId,
                ActionFlag: "MA",
                IsActive: true,
                IsDeleted: false
            };
            $scope.showLicenseForm = true;
            $scope.isLicenseEdit = false;
        };

        $scope.filterLicenses = function() {
            var licIdsDependentOnPayment = [18];

            if (!$scope.allLicenses) { return; }

            $scope.displayLicenses = $scope.allLicenses.where(function(license) {
                if (!$scope.showRenewedLicenses && [5].includes(license.LicenseStatusTypeId)) {
                    return false;
                }
                if (licIdsDependentOnPayment.includes(license.LicenseStatusTypeId) && !license.ApplicationIsPaid) {
                    return false;
                }
                return true;
            });

            // if ($scope.displayLicenses != null) {
            //     $scope.ConvertableIndividualLicenseId = getMaxIndLicenseId($scope.displayLicenses);
            // }
        };

        $scope.getCASPrimaryAndSecondryApplicationLicenseStatus = function(ApplicationInformation){
            $scope.CASApplicationStatus = ApplicationInformation;
            $scope.CASApplicationPrimaryStatus = [];
            $scope.CASApplicationSecondryStatus = [];

            if(!!ApplicationInformation.IDNumber){
                let requestData = {
                    isn: ApplicationInformation.IDNumber,
                };
                try {
                    ShowLoader("Please Wait...");
                    dcaIService.getCaLicFilePrimStatCde(sessionStorage.Key, requestData)
                        .then(function (response){
                            if ($scope.checkServerResponse(response)) {
                                if (!!response.EntityRecords && response.EntityRecords.length > 0) {
                                    response.EntityRecords.forEach(function(eachRecord) {
                                        $scope.CASApplicationPrimaryStatus.push($scope.CASlicenseStatusTypeName(eachRecord.primStatCde));
                                    });
                                    $scope.CASApplicationStatus.CASApplicationPrimaryStatus = $scope.CASApplicationPrimaryStatus;
                                }
                            }
                        }, function(data) {
                            $scope.showStatusMessage(data, "error");
                        });
                } catch (ex) {
                    if (ex != null) {
                        $scope.showStatusMessage(ex.message, "error");
                    }
                }

                try {
                    ShowLoader("Please Wait...");
                    dcaIService.getCaLicFileSecStatCde(sessionStorage.Key, requestData)
                        .then(function (response){
                            if ($scope.checkServerResponse(response)) {
                                if (!!response.EntityRecords && response.EntityRecords.length > 0) {
                                    response.EntityRecords.forEach(function(eachRecord) {
                                        $scope.CASApplicationSecondryStatus.push($scope.CASlicenseStatusTypeName(eachRecord.secStatCde));
                                    });
                                    $scope.CASApplicationStatus.CASApplicationSecondryStatus = $scope.CASApplicationSecondryStatus;
                                }
                            }
                        }, function(data) {
                            $scope.showStatusMessage(data, "error");
                        });
                } catch (ex) {
                    if (ex != null) {
                        $scope.showStatusMessage(ex.message, "error");
                    }
                }
                HideLoader();
            }
        }

        $scope.CASlicenseStatusTypeName = function(licenseStatusTypeId) {
            if (licenseStatusTypeId) {
                return licenseStatusTypeId + ' - '+ $scope.licenseStatusList.whereEquals(licenseStatusTypeId, 'LicenseStatusTypeCode').firstOrDefault().LicenseStatusTypeName;
            }
        };

        $scope.individualLicenseDetailBYIndividualId = function(individualId) {
            ShowLoader("Please Wait...");
            try {
                $scope.ConvertableIndividualLicenseId = 0;
                backofficeIndividualLicenseService.individualLicenseDetailBYIndividualId(sessionStorage.Key, individualId)
                    .then(function(response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        HideLoader();
                        if ($scope.checkServerResponse(response) && !!response.IndividualLicenseList) {
                            var promises = [];
                            var licIdsDependentOnPayment = [18];
                            $scope.allLicenses = response.IndividualLicenseList;

                            var isLicenseActiveList = _.filter(response.IndividualLicenseList, function (o) { return !(o.IDNumber === null || o.IDNumber.trim().length === 0) });
                            var ApplicationInformation = _.uniq(isLicenseActiveList).firstOrDefault();
                            // Fetching the CAS Application Status
                            $scope.getCASPrimaryAndSecondryApplicationLicenseStatus(ApplicationInformation);
                            //Get License Payment status
                            $scope.allLicenses.whereIncludes(licIdsDependentOnPayment, 'LicenseStatusTypeId').forEach(function(license) {
                                promises.push($scope.checkPaymentOnLicense(license));
                            });

                            $q.all(promises)
                                .then(function() {
                                    $scope.filterLicenses();
                                });
                        } else {
                            $scope.allLicenses = [];
                            $scope.filterLicenses();
                        }
                    }, function(data) {
                        HideLoader();
                        $scope.showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) {
                    $scope.showStatusMessage(ex.message, "error");
                }
            }
        };

        function getMaxIndLicenseId(LicArray) {
            var LicNumArr = [];
            angular.forEach(LicArray, function(value, index) {
                LicNumArr.push(LicArray[index].IndividualLicenseId);
            });
            return Math.max.apply(null, LicNumArr);
        };

        $scope.licenseView = function(individualLicense, isConversion) {
            $scope.licenseConversion = isConversion;
            $scope.viewConfig.viewLicense.disableLicenseNumber = !isConversion;
            $scope.viewConfig.viewLicense.disableLicenseType = !isConversion;
            $scope.viewConfig.viewLicense.disableOriginalLicenseDate = true;
            individualLicense.LicenseEffectiveDate = (!isConversion) ? ((!!individualLicense.LicenseEffectiveDate) ? (new Date(individualLicense.LicenseEffectiveDate)) : "") : (new Date());
            individualLicense.LicenseExpirationDate = (!!individualLicense.LicenseExpirationDate) ? (new Date(individualLicense.LicenseExpirationDate)) : "";
            individualLicense.OriginalLicenseDate = (!!individualLicense.OriginalLicenseDate) ? (new Date(individualLicense.OriginalLicenseDate)) : "";
            $scope.viewConfig.showLicenseViewBlock = true;
            $scope.viewConfig.viewLicense.showPaymentBlock = isConversion;
            individualLicense.ExistingLicenseTypeId = individualLicense.LicenseTypeId;
            individualLicense.ActionFlag = "M";
            $scope.individualLicense = individualLicense;
        };

        $scope.openAddEditLicense = function() {
            $scope.individualLicense = {
                IndividualId: $scope.individualId,
                ActionFlag: "M",
                IsActive: true,
                IsDeleted: false
            };
            $scope.licenseConversion = false;
            $scope.viewConfig.viewLicense.disableLicenseNumber = false;
            $scope.viewConfig.viewLicense.disableLicenseType = false;
            $scope.viewConfig.viewLicense.disableOriginalLicenseDate = false;
            $scope.viewConfig.showLicenseViewBlock = true;
            $scope.viewConfig.viewLicense.showPaymentBlock = false;
        };

        $scope.getPaymentMethod = function() {
            $scope.PaymentMethodList = [{
                    Paymentcode: 'CK',
                    Name: 'Check',
                    Id: 1
                },
                {
                    Paymentcode: 'MO',
                    Name: 'Money Order',
                    Id: 2
                },
                {
                    Paymentcode: 'CP',
                    Name: 'Click to pay',
                    Id: 4
                }
            ];
        };

        $scope.generateLicenseNo = function(individualLicense) {
            try {
                backofficeIndividualLicenseService.licenseSave($scope.individualLicense, sessionStorage.Key)
                    .then(function(response) {
                        $scope.showStatusMessage(response.Message, "success");
                        HideLoader();
                    }, function(data) {
                        HideLoader();
                        if (data != null) {
                            $scope.showStatusMessage(data.message, "error");
                        }
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) {
                    $scope.showStatusMessage(ex.message, "error");
                }
            }
        };

        $scope.saveLicense = function(isPayment, licenseForm, applicationObj) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                if ($scope.validateForm(licenseForm)) {
                    ShowLoader("Please wait...");
                    if ($scope.licenseConversion) {
                        $scope.individualLicense.ApplicationId = applicationObj.ApplicationId;
                        $scope.individualLicense.ApplicationTypeId = 4;
                    }
                    $scope.individualLicense.IsActive = true;
                    $scope.individualLicense.IsDeleted = false;
                    if (isPayment) {
                        backofficeIndividualLicenseService.licenseConversion($scope.individualLicense, sessionStorage.Key)
                            .then(function(response) {
                                if ($scope.checkServerResponse(response)) {
                                    $scope.SavePayment();
                                    $scope.showLicenseForm = false;
                                }
                            }, function(data) {
                                HideLoader();
                                if (data != null) {
                                    $scope.showStatusMessage(data.message, "error");
                                }
                            });
                    } else {
                        backofficeIndividualLicenseService.licenseSave($scope.individualLicense, sessionStorage.Key)
                            .then(function(response) {
                                $scope.logDataChanges();
                                $scope.showStatusMessage(response.Message, "success");
                                $scope.individualLicenseDetailBYIndividualId($scope.individualId);
                                $scope.viewConfig.showLicenseViewBlock = false;
                                $scope.natValidation = {
                                    addlInfo: {},
                                    specialties: {},
                                    inLicenses: {},
                                    outLicenses: {}
                                };
                                $scope.showLicenseForm = false;
                                $scope.individualLicense = null;
                                HideLoader();
                                $scope.$emit('reloadWarningFlag', $scope.individualId);
                            }, function(data) {
                                HideLoader();
                                if (data != null) {
                                    $scope.showStatusMessage(data.message, "error");
                                }
                            });
                    }
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) {
                    $scope.showStatusMessage(ex.message, "error");
                }
            }
        };

        $scope.SavePayment = function() {
            sessionStorage.IndividualId = $scope.individualId;
            sessionStorage.RequestedLicenseStatusTypeId = 1;
            sessionStorage.RenewalFees = 50;
            sessionStorage.LateFees = 0;

            $scope.LicenseTypeId = sessionStorage.LicenseTypeId;
            $scope.ApplicationId = sessionStorage.ApplicationId;
            $scope.IndividualLicenseId = sessionStorage.IndividualLicenseId;
            $scope.LicenseTypeId = sessionStorage.LicenseTypeId;
            $scope.LicenseNumber = sessionStorage.LicenseNumber;


            feeDetails = [{
                'RevMstFeeId': 1,
                'FeeName': sessionStorage.LicenseTypeName + ' License Conversion Fee',
                'FeeAmount': 50,
                'FeeTypeID': 4,
                'LicenseTypeId': sessionStorage.LicenseTypeId,
                'IndividualLicenseId': sessionStorage.IndividualLicenseId,
                'Description': ''
            }];
            var date = new Date();
            sessionStorage.TransactionStartDatetime = date;
            sessionStorage.FeesDetails = JSON.stringify(feeDetails);
            $scope.FeesTotal = 50;
            $scope.$broadcast("ConversionPayment", {});
        }

        $scope.validateConversionForm = function(licenseForm) {
            $scope.hideStatusMessage();
            $scope.hideValidationSummary();

            if (licenseForm.errorMessages == undefined)
                licenseForm.errorMessages = [];

            var errorObj = {
                propName: "changeLicenseTypeforConversion",
                errorMessage: Messages.General.Errors.ChangeLicenseType
            };
            cleanErrorMessage(licenseForm.errorMessages, errorObj.propName);
            if ($scope.individualLicense != undefined) {
                if ($scope.individualLicense.LicenseTypeId == $scope.individualLicense.ExistingLicenseTypeId) {
                    licenseForm.errorMessages.push(errorObj);
                }
            }

            return $scope.validateData(licenseForm);
        };

        var cleanErrorMessage = function(errorMessages, prop) {
            var indexOfError = null;
            for (var i = 0; i < errorMessages.length; i++) {
                if (errorMessages[i].propName == prop)
                    indexOfError = i;
            }
            if (indexOfError != null && indexOfError >= 0) {
                errorMessages.splice(indexOfError, 1);
            }
        };


        $scope.LicensePayment_init = function() {};

        $scope.changePaymentMethod = function(ddlpaymentMethod) {
            $scope.LicensePayment_init();
        };

        $scope.showlicenseModel = function(value) {
            $('#LicenseModal').modal('show');
            $scope.currentLicenseData = value;
            $scope.isDownload = null;
        }

        $scope.sendGenericEmailandLogCommunication = function(value) {
            // $('#LicenseModal').model('show');
            $('#LicenseModal').modal('hide');
            ShowLoader();
            var data = {
                IndividualId: $scope.currentLicenseData.IndividualId,
                IndividualLicenseId: $scope.currentLicenseData.IndividualLicenseId,
                Email: sessionStorage.currentUserEmail
            }
            backofficeIndividualLicenseService.sendGenericEmailandLogCommunication(sessionStorage.Key, data).then(function(response) {
                HideLoader();
                if (response.Status && $scope.isDownload) {
                    multipleFileUploadService.documentByIndividualDocumentId(response.IndividualDocumentId);
                } else {
                    if (response.Status)
                        showStatusMessage(response.Message, "success");
                    else
                        showStatusMessage(response.Message, "error");

                }
            }, function(error) {
                HideLoader();
                showStatusMessage(data.message, "error");
            });
        }

        //License Conversion
        $scope.applicationId = null;
        $scope.LicenseSaveConversion = function(licenseForm) {
            if (licenseForm == undefined) {
                licenseForm = [];
                licenseForm.licenseNumber = 0;
                licenseForm.licenseStatusTypeId = null;
                licenseForm.licenseTypeId = null;
                licenseForm.BeginDate = null;
                licenseForm.EndDate = null;
            }
            try {
                if (!$scope.validateConversionForm(licenseForm)) {
                    return false;
                }
                var CurrentDate = new Date();
                var applicationObj = {
                    ApplicationTypeId: 4,
                    ApplicationStatusId: 1,
                    ApplicationStatusReasonId: 1,
                    ApplicationInternalStatusId: 1,
                    StartedDate: CurrentDate,
                    SubmittedDate: CurrentDate,
                    ApplicationStatusDate: CurrentDate,
                    PaymentDeadlineDate: CurrentDate,
                    PaymentDate: CurrentDate,
                    LicenseTypeId: $scope.individualLicense.LicenseTypeId,
                    IsActive: true,
                    HighPriority: true
                };
                if (!$scope.applicationId) {
                    backofficeIndividualLicenseService.generateApplicationId(sessionStorage.Key, 0, sessionStorage.UserID, applicationObj)
                        .then(function(response) {
                            if ($scope.checkServerResponse(response)) {
                                $scope.applicationId = response.ApplicationId;
                                var applicationObj = {
                                    ApplicationId: response.ApplicationId,
                                    ApplicationNumber: response.ApplicationNumber
                                };
                                sessionStorage.applicationObj = applicationObj;
                                $scope.saveLicense(true, licenseForm, 'M', applicationObj);
                            }
                        }, function(data) {
                            HideLoader();
                            if (data != null) {
                                $scope.showStatusMessage(data.message, "error");
                            }
                        });
                } else {
                    $scope.saveLicense(true, licenseForm, 'M', sessionStorage.applicationObj);
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) {
                    $scope.showStatusMessage(ex.message, "error");
                }
            }
        };

        $scope.deleteLicenseDetail = function(individualLicense) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                $rootScope.individualEventLog('deleteStatus.deleteConfirmed', $scope.IndividualId);
                ShowLoader("Please Wait...");
                individualLicense.IsActive = true;
                individualLicense.IsDeleted = true;
                individualLicense.ActionFlag = "D";
                backofficeIndividualLicenseService.licenseSave(individualLicense, sessionStorage.Key)
                    .then(function(response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.logDataChanges();
                            $scope.individualLicenseDetailBYIndividualId($scope.individualId);
                            $scope.showStatusMessage(response.Message, "success");
                        }
                        HideLoader();
                    }, function(data) {
                        HideLoader();
                        if (data != null) {
                            showStatusMessage(data.message, "error");
                        }
                    });
            } else {
                $rootScope.individualEventLog('deleteStatus.deleteCanceled', $scope.IndividualId);
            }
        };

        $scope.individualEducationBYIndividualLicenseId = function(IndividualLicenseId) {
            try {
                $scope.selectedIndividualLicenseId = IndividualLicenseId;
                $scope.IndividualCECourseList = [];
                $scope.viewConfig.showCEHTable = true;
                ShowLoader("Please Wait...");
                backofficeIndividualLicenseService.individualEducationBYIndividualLicenseId(sessionStorage.Key, IndividualLicenseId)
                    .then(function(response) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                        if ($scope.checkServerResponse(response)) {
                            $scope.IndividualCECourseList = response.IndividualCECourseResponseList;
                        }
                        HideLoader();
                    }, function(data) {
                        HideLoader();
                        $scope.showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) {
                    $scope.showStatusMessage(ex.message, "error");
                }
            }
        };

        $scope.editLicense = function(licenseObject) {
            $scope.showLicenseForm = true;
            licenseObject.ActionFlag = "M";
            $scope.individualLicense = angular.copy(licenseObject);

            var licenseType = $scope.licenseTypeList.whereEquals($scope.individualLicense.LicenseTypeId, 'LicenseTypeId').firstOrDefault();
            $scope.individualLicense.LicenseTypeName = licenseType.LicenseTypeName;

            var licenseStatus = $scope.licenseStatusList.whereEquals($scope.individualLicense.LicenseStatusTypeId, 'LicenseStatusTypeId').firstOrDefault();
            $scope.individualLicense.LicenseStatusTypeName = licenseStatus.LicenseStatusTypeName;

            $scope.isLicenseEdit = true;
        };

        $scope.getCEH = function(individualCECourse) {
            $scope.isCEHEdit = true;
            $scope.viewConfig.showAddCEHBlock = true;
            $scope.viewConfig.showAddCEHButton = false;
            if (!!individualCECourse.CECourseDate && !angular.isDate(individualCECourse.CECourseDate))
                individualCECourse.CECourseDate = new Date(individualCECourse.CECourseDate);
            $scope.individualCECourse = angular.copy(individualCECourse);
        };

        $scope.saveCEH = function(cehForm) {
            try {
                $scope.viewConfig.showCEHTable = true;
                cehForm.$submitted = true;
                if ($scope.validateData(cehForm)) {
                    ShowLoader("Please wait...");
                    if (!$scope.isCEHEdit) {
                        $scope.individualCECourse.IndividualId = $scope.individualId;
                        $scope.individualCECourse.IndividualLicenseId = $scope.selectedIndividualLicenseId;
                    }
                    $scope.individualCECourse.IsActive = true;
                    $scope.individualCECourse.IsDeleted = false;

                    backofficeIndividualEducationService.individualCECourseSave($scope.individualCECourse, sessionStorage.Key)
                        .then(function(response) {
                            if ($scope.checkServerResponse(response)) {
                                $scope.individualEducationBYIndividualLicenseId($scope.individualCECourse.IndividualLicenseId);
                                $scope.showStatusMessage(response.Message, "success");
                                $scope.viewConfig.showAddCEHBlock = false;
                                $scope.viewConfig.showAddCEHButton = true;
                            }
                            HideLoader();
                        }, function(data) {
                            HideLoader();
                            if (data != null) {
                                $scope.showStatusMessage(data.message, "error");
                            }
                        });
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) {
                    $scope.showStatusMessage(ex.message, "error");
                }
            }
        };

        $scope.deleteCEH = function(individualCECourse) {
            if (confirm(Messages.General.Confirm.DeleteRecord)) {
                ShowLoader("Please wait...");
                individualCECourse.IsDeleted = true;

                backofficeIndividualEducationService.individualCECourseSave(individualCECourse, sessionStorage.Key)
                    .then(function(response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.individualEducationBYIndividualLicenseId(individualCECourse.IndividualLicenseId);
                            $scope.showStatusMessage(response.Message, "success");
                        }
                        HideLoader();
                    }, function(data) {
                        HideLoader();
                        if (data != null) {
                            $scope.showStatusMessage(data.message, "error");
                        }
                    });
            }
        };

        //Clear CEH Row
        $scope.cancelCEH = function() {
            $scope.viewConfig.showAddCEHBlock = false;
            $scope.viewConfig.showAddCEHButton = true;
        };

        $scope.showAddEditCEH = function() {
            $scope.individualCECourse = {};
            $scope.isCEHEdit = false;
            $scope.viewConfig.showAddCEHBlock = true;
            $scope.viewConfig.showAddCEHButton = false;
        };

        $scope.closeCEH = function() {
            $scope.viewConfig.showCEHTable = false;
        };

        $scope.cancelLicenseView = function() {
            $scope.individualLicenseDetailBYIndividualId($scope.individualId);
            $scope.viewConfig.showLicenseViewBlock = false;
            $scope.showLicenseForm = false;
            $scope.natValidation = {
                addlInfo: {},
                specialties: {},
                inLicenses: {},
                outLicenses: {}
            };
            $scope.individualLicense = {};
        };

        $scope.licenseTypeChange = function(licenseTypeId) {
            if (licenseTypeId) {
                //ShowLoader();

                var licenseType = $scope.licenseTypeList.whereEquals(licenseTypeId, 'LicenseTypeId').firstOrDefault();
                $scope.individualLicense.LicenseTypeName = licenseType.LicenseTypeName;

                //Search license list for this license type
                // var licenseOfType = $scope.IndividualLicenseList.orderByDescending('IndividualLicenseId').whereEquals(licenseTypeId, 'LicenseTypeId').firstOrDefault();
                // if (!!licenseOfType) {
                //     $scope.individualLicense.LicenseNumber = licenseOfType.LicenseNumber;
                // } else {
                //     $scope.individualLicense.LicenseNumber = '';
                // }
            }
        };

        $scope.licenseStatusTypeChange = function(licenseStatusTypeId) {
            if (licenseStatusTypeId) {
                var licenseStatus = $scope.licenseStatusList.whereEquals(licenseStatusTypeId, 'LicenseStatusTypeId').firstOrDefault();
                $scope.individualLicense.LicenseStatusTypeName = licenseStatus.LicenseStatusTypeName;
            }
        };

        $scope.getLicenseStatuses = function() {
            ShowLoader();
            backofficeIndividualLicenseService.getLicenseStatuses(sessionStorage.Key)
                .then(function(response) {
                    if (response.Status) {
                        $scope.licenseStatusList = response.LicenseStatusTypeGetList;
                    } else {

                    }
                    HideLoader();
                }, function(err) {
                    HideLoader();
                });
        };

        $scope.getLicenseDisciplineStatuses = function() {
            ShowLoader();
            typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=licensedisciplinestatus"))
                .then(function(response) {
                    if (response.Status) {
                        $scope.licenseDisciplineStatusList = response.ReferenceEntities;
                    }
                    HideLoader();
                }, function(err) {
                    HideLoader();
                });
        };

        $scope.getLicenseTypes = function() {
            ShowLoader();
            backofficeIndividualLicenseService.getLicenseTypes(sessionStorage.Key)
                .then(function(response) {
                    if (response.Status) {
                        $scope.licenseTypeList = response.ReferenceEntities.orderBy('LicenseTypeName');
                    } else {

                    }
                    HideLoader();
                }, function(err) {
                    HideLoader();
                });
        };

        $scope.loadApplicationConfiguration = function() {
            var relativeFilePath = "/components/individual/application/application.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function(data) {
                    $scope.appConfig = data;
                }, function(error) {

                });
        };

        $scope.showLDSection = function(sectionName, license) {
            if (!!$scope.appConfig && !!$scope.licenseTypeList) {
                var tabConfig = $scope.appConfig.newApplicationTabs.whereEquals(sectionName, 'name').firstOrDefault();
                var licenseType = $scope.licenseTypeList.whereEquals(license.LicenseTypeId, 'LicenseTypeId').firstOrDefault();
                var renewalTabConfig = $scope.appConfig.renewalConfig.tabs.whereEquals(sectionName, 'name').firstOrDefault();

                var existsInInitialAppConfig;
                var existsInRenewalAppConfig;

                if (!!tabConfig && !!licenseType) {
                    existsInInitialAppConfig = tabConfig.initialGroups.includes(licenseType.InitialApplicationGrouping);
                }
                if (!!renewalTabConfig && !!licenseType) {
                    existsInRenewalAppConfig = renewalTabConfig.renewalGroups.includes(licenseType.RenewalApplicationGrouping);
                }

                return (existsInInitialAppConfig || existsInRenewalAppConfig);
            }
            return false;
        };

        $scope.showSUPDVSection = function(sectionName, license) {
            switch (sectionName) {
                case 'supervisor':
                    return [1, 2, 3, 6, 7, 8, 10, 11, 13, 14].includes(license.LicenseTypeId);
                case 'supervisee':
                    return [4, 9].includes(license.LicenseTypeId);
                default:
                    return false;
            }
        };

        $scope.warnignDVClick = function(item) {
            $scope.warnignDV = !$scope.warnignDV;
            $scope.currentItem = item;
            $scope.SUPDV = false;
            $scope.LDDV = false;
            $scope.CEDV = false;
            $scope.OSLDV = false;
            $scope.DEF = false;
        };

        $scope.LDDVClick = function(item) {
            if ($scope.showLDSection('licenseEndorsement', item) || $scope.showLDSection('telemedicine', item)) {
                $scope.LDDV = !$scope.LDDV;
            } else {
                $scope.LDDV = false;
            }
            $scope.warnignDV = false;
            $scope.SUPDV = false;
            $scope.CEDV = false;
            $scope.OSLDV = false;
            $scope.DEF = false;
            $scope.currentItem = item;
            if (!$scope.licenseTypeList) {
                $scope.getLicenseTypes();
            }
        };

        $scope.OSLDVClick = function(item) {
            $scope.OSLDV = !$scope.OSLDV;
            $scope.warnignDV = false;
            $scope.SUPDV = false;
            $scope.LDDV = false;
            $scope.CEDV = false;
            $scope.DEF = false;
            getIndividualPreviousLicenseBYIndividualId(item);
        };

        $scope.DEFClick = function(item) {
            $scope.DEF = !$scope.DEF;
            $scope.currentItem = item;
            $scope.warnignDV = false;
            $scope.SUPDV = false;
            $scope.LDDV = false;
            $scope.OSLDV = false;
        };

        $scope.CEDVClick = function(item) {
            $scope.CEDV = !$scope.CEDV;
            $scope.currentItem = item;
            $scope.warnignDV = false;
            $scope.SUPDV = false;
            $scope.LDDV = false;
            $scope.OSLDV = false;
            $scope.DEF = false;
        };

        $scope.SUPDVClick = function(item) {
            if ($scope.showSUPDVSection('supervisor', item) || $scope.showSUPDVSection('supervisee', item)) {
                $scope.SUPDV = !$scope.SUPDV;
            } else {
                $scope.SUPDV = false;
            }
            $scope.currentItem = item;
            $scope.warnignDV = false;
            $scope.LDDV = false;
            $scope.CEDV = false;
            $scope.OSLDV = false;
            $scope.DEF = false;
            //getIndividualSupervisoryInfo(item);
        };

        var getIndividualPreviousLicenseBYIndividualId = function(item) {
            backofficeIndividualLicenseService.getIndividualPreviousLicenseBYIndividualId(sessionStorage.Key, item.IndividualId, 0).then(function(response) {
                $scope.previousLicenseList = response.List;
            }, function(error) {

            });
        };

        var getIndividualSupervisoryInfo = function(item) {
            backofficeIndividualLicenseService.getIndividualSupervisoryInfo(sessionStorage.Key, item.IndividualId).then(function(response) {
                $scope.IndividualSupervisoryInfos = response.IndividualSupervisoryInfos;
            }, function(error) {

            });
        };
    }
})();