﻿(function () {
    'use strict'

    angular.module('app.InitialApplication').factory('licenseRenewalService', licenseRenewalService)

    licenseRenewalService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function licenseRenewalService($http, $q, WebApiUrl, AppConfig) {
        return {

            GetLicenseeRenewalList: function (key) {

                return $http.get(WebApiUrl + 'Renewal/RenewalGetAll/' + key);
            },
            RenewalSearchWithPager: function (key, PageNumber, NoOfRecords, SortColumn, SortOrder, ShowAllRecords, renewalSearchObj, QueuePage, isBackoffice, RenewalFormType) {
                return $http.post(WebApiUrl + 'Renewal/RenewalSearchWithPager/' + key + '?PageNumber=' + PageNumber + '&NoOfRecords=' + NoOfRecords + '&IsBackoffice=' + isBackoffice + '&SortColumn=' + SortColumn + '&SortOrder=' + SortOrder + '&ShowAllRecords=' + ShowAllRecords + '&QueuePage=' + QueuePage + '&RenewalFormType=' + RenewalFormType, renewalSearchObj);
            },
            IndividualLicenseByLicenseNumber: function (key, licenseNumber, RenewalFormType) {
                return $http.get(WebApiUrl + 'Individual/GetIndividualLicenseByCertificateNumber/' + key + '?CertificateNumbetr=' + licenseNumber + "&RenewalFormType=" + RenewalFormType);
            },
            IndividualLicenseRenewalList: function (key, licenseNumber, firstName, lastName) {
                return $http.get(WebApiUrl + 'Individual/GetIndividualLicenseRenewalList/' + key + '?CertificateNumber=' + licenseNumber + "&FirstName=" + firstName + "&LastName=" + lastName);
            },
            RenewalSearch: function (key, Name, FirstName, LastName, Email, Phone, LicenseNumber, SSN, StatusId, StatusName, IndividualId, ApplicationNumber, IsActive, SubmittedDate, IsPaid) {

                return $http.post(WebApiUrl + 'Renewal/RenewalSearchWithPager/' + key, {
                    Name: Name,
                    FirstName: FirstName,
                    LastName: LastName,
                    Email: Email,
                    Phone: Phone,
                    LicenseNumber: LicenseNumber,
                    SSN: SSN,
                    StatusId: StatusId,
                    StatusName: StatusName,
                    IndividualId: IndividualId,
                    ApplicationNumber: ApplicationNumber,
                    IsActive: IsActive,
                    SubmittedDate: SubmittedDate,
                    IsPaid: IsPaid
                });
            },
            IndividualRenewalGetByApplicationId: function (key, IndividualId, ApplicationId) {
                return $http.get(WebApiUrl + 'Renewal/IndividualRenewalGetByApplicationId/' + key + '?IndividualId=' + IndividualId + '&ApplicationId=' + ApplicationId);
            },
            ConfirmAndApprove: function (key, ApplicationId, IndividualLicenseId, RequestedLicenseStatusTypeId, AffirmativeAction, Action, SentFrom) {
                return $http.post(WebApiUrl + 'Renewal/ConfirmAndApprove/' + key, {
                    ApplicationId: ApplicationId,
                    IndividualLicenseId: IndividualLicenseId,
                    RequestedLicenseStatusTypeId: RequestedLicenseStatusTypeId,
                    AffirmativeAction: AffirmativeAction,
                    Action: Action,
                    SentFrom: SentFrom
                });
            },
            IndividualRenewalGet: function (key, IndividualId) {
                return $http.get(WebApiUrl + 'Renewal/NVPharmacyIndividualRenewalGet/' + key + '?IndividualId=' + IndividualId);
            },
            ContactTypeGetAll: function (key) {

                return $http.get(WebApiUrl + 'TypeValues/ContactTypeGetAll/' + key);
            },
            GetServerDate: function () {
                return $http.get(WebApiUrl + 'Common/ServerDateTimeGet');
            },
            ApplicationTypeGetAll: function (key) {
                return $http.get(WebApiUrl + 'TypeValues/ApplicationTypeGetAll/' + key);
            },
            LicenseTypeGetAll: function (key) {
                return $http.get(WebApiUrl + 'TypeValues/LicenseTypeGetAll/' + key);
            },
            IndividualRenewalSave: function (key, IndividualRenewal) {
                return $http.post(WebApiUrl + 'Renewal/IndividualRenewalSave/' + key, {
                    IndividualRenewal: IndividualRenewal
                });
            },
            GetStateByCountryID: function (Key, ID) {
                return $http.get(WebApiUrl + 'State/StateGetByCountryID/' + Key + '?CountryID=' + ID);
            },
            LicenseRenewalCheck: function (key, individualId, source, RenewalFormType) {
                if (source == "NVPHARMACYOnline")
                    return $http.get(WebApiUrl + 'Renewal/LicenseRenewalCheck/' + key + '?Source=' + source + '&IndividualId=' + individualId);
                else
                    return $http.get(WebApiUrl + 'Renewal/LicenseRenewalCheck/' + key + '?Source=' + source + '&IndividualId=' + individualId + '&RenewalFormType=' + RenewalFormType);
            },
            AutomatedLicenseRenewal: function (key, IndividualRenewal) {
                return $http.post(WebApiUrl + 'Renewal/AutomatedLicenseRenewal/' + key, IndividualRenewal);
            },
            checkAndCreatePendingRenewal: function (key, reqObj) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'Renewal/CheckAndCreatePendingRenewal?Key=' + (!!key ? key : '') + '&Source=' + AppConfig.Source, reqObj)
                    .then(function (data) {
                        data = data.data;
                        deferred.resolve(data);
                    }, function (error) {
                        error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            saveTabStatus: function (tabData, key) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'Provider/SaveTabStatus/' + key, tabData)
                    .then(function (data) {
                        data = data.data;
                        deferred.resolve(data);
                    }, function (error) {
                        error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            getAllTabStatus: function (applicationId, providerId, key) {
                var deferred = $q.defer();
                $http.get(WebApiUrl + 'Provider/GetAllTabStatus/' + key + '?ApplicationId=' + applicationId + '&ProviderId=' + providerId)
                    .then(function (data) {
                        data = data.data;
                        deferred.resolve(data);
                    }, function (error) {
                        error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            saveIndividualbackgroundcheck: function (bcCheckData, key) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'Individual/SaveIndividualbackgroundcheck/' + key + "?Source=" + AppConfig.Source, bcCheckData)
                    .then(function (data) {
                        data = data.data;
                        deferred.resolve(data);
                    }, function (error) {
                        error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            saveFPMethod: function (reqData, key) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'Individual/SaveIndividualFPMethod/' + key + "?Source=" + AppConfig.Source, reqData)
                    .then(function (data) {
                        data = data.data;
                        deferred.resolve(data);
                    }, function (error) {
                        error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            saveIndividualsubsequentarrest: function (subRequestData, key) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'Individual/SaveIndividualsubsequentarrest/' + key, subRequestData)
                    .then(function (data) {
                        data = data.data;
                        deferred.resolve(data);
                    }, function (error) {
                        error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            getIndividualbackgroundcheck: function (individualId, applicationId, applicationTypeId, key) {
                var deferred = $q.defer();
                $http.get(WebApiUrl + 'Individual/GetIndividualbackgroundcheck/' + key + '?individualId=' + individualId + '&applicationId=' + applicationId + '&applicationTypeId=' + applicationTypeId)
                    .then(function (data) {
                        data = data.data;
                        deferred.resolve(data);
                    }, function (error) {
                        error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            getIndividualsubsequentarrest: function (individualId, applicationId, applicationTypeId, key) {
                var deferred = $q.defer();
                $http.get(WebApiUrl + 'Individual/GetIndividualsubsequentarrest/' + key + '?individualId=' + individualId + '&applicationId=' + applicationId + '&applicationTypeId=' + applicationTypeId)
                    .then(function (data) {
                        data = data.data;
                        deferred.resolve(data);
                    }, function (error) {
                        error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            individualTablesDelete: function (key, individualId, applicationId, isBackoffice) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'Individual/IndividualTablesDelete/' + key + '?IndividualId=' + individualId +
                    '&ApplicationId=' + applicationId + '&IsBackOffice=' + isBackoffice + "&Source=" + AppConfig.Source)
                    .then(function (data) {
                        data = data.data;
                        deferred.resolve(data);
                    }, function (error) {
                        error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            getUsersByUserTypeId: function (key, userTypeId) {
                var deferred = $q.defer();
                $http.get(WebApiUrl + 'Users/GetUsersByUserTypeId/' + key + '?UserTypeId=' + userTypeId).then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            usersGetAllByRole: function (key, request) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'Users/UsersGetAllByRole/' + key, request).then(function (data) {
                    data = data.data;
                    deferred.resolve(data);
                }, function (error) {
                    error = error.data;
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            updateDOJFBI: function (subRequestData, key) {
                var deferred = $q.defer();
                $http.post(WebApiUrl + 'DCAIService/DOJMatchByRecId/' + key, subRequestData)
                    .then(function (data) {
                        data = data.data;
                        deferred.resolve(data);
                    }, function (error) {
                        error = error.data;
                        deferred.reject(error);
                    });
                return deferred.promise;
            }
        };
    }
})();