﻿(function () {
    'use strict'
    angular.module('app.backofficeAdministor')
        .controller('DynamicInputController', ['$scope', function ($scope) {
            var _Key = sessionStorage.Key;
            $scope.datePicker = {
                crpRejectionDate: false,
                crpReceivedDate: false,
                format: 'M!/d!/yyyy',
                dateOptions: {
                    formatYear: 'yy',
                    startingDay: 1
                }
            };

            $scope.OnDropdownChange = function (field) {
                try{
                    $scope.OnDropdownValueChange({ 'field': field });
                }catch(ee){}
            };
    }]);
})();