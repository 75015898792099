(function() {
    'use strict'

    angular.module('reporting.FieldSearchReporting')
    .controller('FieldSearchReportingSelectController', ['$scope', '$uibModalInstance', 'QueryReportingService', 'parameters', FieldSearchReportingSelectController]);
    
    function FieldSearchReportingSelectController ($scope, $uibModalInstance, QueryReportingService, parameters) {
        var _key = sessionStorage.Key;
        
        var findColumnInList = function (column) {
            return column.TableName == this.TableName && column.FieldName == this.FieldName;
        };

        var validateSelect = function () {
            var valid = $scope.currentQuery.Select.any();

            if (!valid) {
                $scope.showStatusMsg('-', 'No data has been selected.');
            }

            return valid;
        };

        var getCountSelect = function () {
            var countFn = {
                Category: 'Aggregate',
                Name: 'Count',
                Signature: 'COUNT({0})',
                DataType: 'number',
                Parameters: [{
                    Name: 'Column',
                    DataType: 'any',
                    Value: {
                        IsColumn: false,
                        DataType: 'any',
                        Value: '*'
                    }
                }]
            };

            var selectCount = {
                TableName: '',
                TableAlias: '',
                FieldName: '',
                FieldDisplayName: 'Count',
                FieldDataType: '',
                IsEncrypted: false,

                IsExpressionColumn: true,
                Expression: {
                    LeftOperand: countFn
                }
            };

            return [selectCount];
        };

        $scope.init = function () {
            $scope.currentQuery = angular.copy(parameters.currentQuery);
            $scope.relationList = angular.copy(parameters.relationList).orderBy('RelationTableDisplayName');

            $scope.currentTable = null;

            $scope.columns = {
                addColumn: null,
                removeColumn: null
            };

            $scope.currentQuery.DocumentType = 'xlsx';
            QueryReportingService.getAvailableExportDocumentTypes(_key)
            .then(function (data) {
                $scope.availableDocumentTypes = data.DocumentTypes;
            }, function (err) {

            });
        };
        
        $scope.addSelectColumn = function (columns) {
            if(columns.length == 0) {
                return;
            }

            $scope.currentQuery.Select = angular.copy($scope.currentQuery.Select.concat(columns));

            $scope.currentQuery.Select.forEach(function (col) {
                delete col.Condition;   //Remove unnecessary field
            });
        };

        $scope.removeSelectColumn = function (columns) {
            if(columns.length == 0) {
                return;
            }

            columns.forEach(function (c) {
                var removeIndex = $scope.currentQuery.Select.findIndex(findColumnInList, c);

                $scope.currentQuery.Select.splice(removeIndex, 1);
            });
        };

        $scope.moveSelectionUp = function (columns) {
            if(columns.length == 0) {
                return;
            }
            var column = columns[0];
            var array = $scope.currentQuery.Select;
            var i = array.findIndex(findColumnInList, column);

            array.splice(i, 1);
            array.splice(i-1, 0, column);
        };

        $scope.moveSelectionDown = function (columns) {
            if(columns.length == 0) {
                return;
            }
            var column = columns[0];
            var array = $scope.currentQuery.Select;
            var i = array.findIndex(findColumnInList, column);

            array.splice(i, 1);
            array.splice(i+1, 0, column);
        };

        $scope.saveQueryClicked = function () {
            if (!validateSelect()) { return; }

            parameters.saveQueryRef(angular.copy($scope.currentQuery))
            .then(function (data) {
                $scope.showStatusMsg('+', 'Saved successfully!');
                $scope.currentQuery.Name = '';
                $scope.currentQuery.queryTabOpened = false;
            }, function (err) {
                $scope.showStatusMsg('-', err);
            });
        };

        $scope.generateSQLClicked = function () {
            if (!validateSelect()) { return; }

            $uibModalInstance.close({type: 'sql', query: $scope.currentQuery});
        };

        $scope.getCountClicked = function () {
            $scope.currentQuery.Select = getCountSelect();

            $uibModalInstance.close({type: 'results', query: $scope.currentQuery});
        };

        $scope.queryDataClicked = function () {
            if (!validateSelect()) { return; }

            $uibModalInstance.close({type: 'results', query: $scope.currentQuery});
        };

        $scope.exportDataClicked = function () {
            if (!validateSelect()) { return; }

            $uibModalInstance.close({type: 'document', query: $scope.currentQuery});
        };

        $scope.cancelClicked = function () {
            $uibModalInstance.dismiss();
        };
    }
})();