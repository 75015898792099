(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .directive('reciprocityValidation', [ReciprocityValidation]);

    function ReciprocityValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.validateForm = function () {
                    var valid = true;
                    var errMsgs = [];

                    if (!$scope.reciprocities || $scope.reciprocities.length <= 0) {
                        valid = false;
                        errMsgs.push('You must add atleast one license/permit type to continue.');
                    }


                    $scope.showStatusMsg('-', errMsgs);

                    return valid;
                };
            }
        };
    }
})();