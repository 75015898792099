(function() {
    'use strict'
    angular.module('app.core')
        .directive('natMapEnterKey', natMapEnterKeyDirective);

    natMapEnterKeyDirective.$inject = ['$timeout'];

    function natMapEnterKeyDirective($timeout) {
        return {
            link: function(scope, element, attrs) {
                $timeout(function() {
                    $(element).on('keydown', function(e) {
                        if (e.which == 13) {
                            element.submit();
                        }
                    });
                });

            }
        };
    }
})();