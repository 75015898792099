(function () {
    'use strict'

    angular.module('app.enforcement')
    .factory('ComplaintInvolvedPartyService', ['$http', '$q', 'WebApiUrl', ComplaintInvolvedPartyService]);

    function ComplaintInvolvedPartyService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getIndividuals = function (key, searchParams, pageNum, pageSize) {
            var urlParams = {
                PageNumber: pageNum,
                NoOfRecords: pageSize
            };
            return responseHandler($http.post(WebApiUrl + 'Individual/IndividualSearchWithPage/' + key + '/', searchParams, { params: urlParams }));
        };

        var _getProviders = function (key, searchParams, pageNum, pageSize) {
            var urlParams = {
                PageNumber: pageNum,
                NoOfRecords: pageSize
            };
            return responseHandler($http.post(WebApiUrl + 'Provider/ProviderSearchWithPage/' + key + '/', searchParams, { params: urlParams }));
        };

        var _getPublicAddress = function (key, individualId) {
            var urlParams = {
                IndividualId: individualId
            };
            return responseHandler($http.get(WebApiUrl + 'Address/GetPublicAddressesByIndividualId/' + key + '/', { params: urlParams }));
        };

        var _getContacts = function (key, individualId) {
            var urlParams = {
                IndividualId: individualId
            };
            return responseHandler($http.get(WebApiUrl + 'Individual/IndividualContactBYIndividualId/' + key + '/', { params: urlParams }));
        };

        var _getInvolvedParties = function (key, complaintId, complaintInvolvedPartyTypeId) {
            var request = {
                ComplaintId: complaintId,
                ComplaintInvolvedPartyTypeId: complaintInvolvedPartyTypeId
            };
            return responseHandler($http.post(WebApiUrl + 'Complaint/GetComplaintInvolvedParty/' + key + '/', request));
        };

        var _saveInvolvedParties = function (key, request) {
            return responseHandler($http.post(WebApiUrl + 'Complaint/SaveComplaintInvolvedParty/' + key + '/', request));
        };

        return {
            getIndividuals: _getIndividuals,
            getProviders: _getProviders,
            getPublicAddress: _getPublicAddress,
            getContacts: _getContacts,
            
            getInvolvedParties: _getInvolvedParties,
            saveInvolvedParties: _saveInvolvedParties
        };
    }
})();