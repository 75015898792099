(function () {
    'use strict'

    angular.module('app.report')
    .directive('cannedReports', [CannedReportsDirective]);

    function CannedReportsDirective() {
        return {
            restrict: 'E',
            scope: {
                title: '=?',
                cannedReportId: '=?',
                pageName: '=?',
                searchParams: '=?',
                pagerParams: '=?',
                autoExecReport: '=?',
                currentReportData: '=?'    // Outgoing parameter
            },
            link: function ($scope, element, attrs) {

            },
            templateUrl: 'app/components/report/canned-reports/canned-reports.html',
            controller: 'CannedReportsController'
        };
    }
})();