(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("InitialLicenseApplicationController", InitialLicenseApplicationController);

    InitialLicenseApplicationController.$inject = ['$rootScope', '$scope', '$timeout', '$interval', 'utilityService', 'initialLicenseApplicationService', 'feePaymentService', 'initialApplicationExperiencService',
        'htmlToPdfGenerationService', '$state', '$q', 'licenseApplicationListingService', 'deficiencyService', 'typeValuesService', 'backofficeIndividualLicenseService',
        'TypeValue', 'initialApplicationstatusService', 'backofficeIndividualPersonalService', 'backofficeIndividualAdressService', 'ApplicationSettings',
        'examFactory', 'externalFormService', 'FingerprintConfirmationService', 'dcaIService', 'Messages', 'warningService', 'initialApplicationLegalInfoService'
    ];

    function InitialLicenseApplicationController($rootScope, $scope, $timeout, $interval, utilityService, initialLicenseApplicationService, feePaymentService, initialApplicationExperiencService,
        htmlToPdfGenerationService, $state, $q, licenseApplicationListingService, deficiencyService, typeValuesService, backofficeIndividualLicenseService,
        TypeValue, initialApplicationstatusService, backofficeIndividualPersonalService, backofficeIndividualAdressService, ApplicationSettings,
        examFactory, externalFormService, FingerprintConfirmationService, dcaIService, Messages, warningService, initialApplicationLegalInfoService) {
        $scope.individualViewConfig = [];
        $scope.individualLicense = {};
        $scope.legalInfo = {};
        $scope.ncess = {};
        $scope.personalData = {};
        $scope.growingAddress = {
            growingAddressList: []
        };
        $rootScope.diabledNewappForm = false;
        $scope.isTabStructure = true;
        $scope.canChangeTabs = false;

        $scope.isBackoffice = sessionStorage.isBackOfficeUser == 'true' || sessionStorage.isBackOfficeUser == true;

        // $scope.PersonalInfo = {};
        $scope.UserId = sessionStorage.Key;
        $scope.getIndividualViewConfig = function () {
            $scope.applicationInfo = {};
            var relativeFilePath = "/components/individual/application/application.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function (data) {
                    $scope.individualViewConfig = data;
                    $scope.listConfig.individualViewConfig = $scope.individualViewConfig;
                    $scope.getApplicationInfoByApplicationId();
                }, function (error) {

                });
        };

        var processTabsToBeShown = function () {
            var processedTabs = [];
            $scope.validator = {};
            $scope.individualViewConfig.paymentTabNumber = 0;
            $scope.applicationConfig = $scope.individualViewConfig.initialConfig;
            if (!$scope.isBackoffice && [16].indexOf($scope.applicationInfo.ApplicationStatusId) != -1) {
                $scope.applicationConfig = $scope.individualViewConfig.initialExamConfig;
            }
            angular.forEach($scope.applicationConfig.tabs, function (tabItem) {
                if (tabItem.isEnabled && (($scope.isBackoffice && !tabItem.hideBackOffice) || (!$scope.isBackoffice && !tabItem.hideOnline))) {
                    if (tabItem.queues.indexOf($rootScope.routeData.queueType) != -1
                        && tabItem.initialGroups.includes($scope.individualLicense.licenseTypeInfo.InitialApplicationGrouping)
                        && (!tabItem.applicationStatusId || tabItem.applicationStatusId.includes($scope.applicationInfo.ApplicationStatusId))
                        && (!tabItem.applicationStatusIdNotIn || !tabItem.applicationStatusIdNotIn.includes($scope.applicationInfo.ApplicationStatusId))
                        && (!tabItem.applicationSubTypeIds || !!tabItem.applicationSubTypeIds.includes($scope.applicationInfo.ApplicationSubTypeId))) {
                        processedTabs.push(tabItem);
                        if (!!tabItem.isPaymentTab) {
                            $scope.individualViewConfig.paymentTabNumber = (processedTabs.length + 1);
                        }
                    }
                }
                $scope.validator[tabItem.name] = {};
            });
            $scope.individualViewConfig.processedTabs = processedTabs;

            $scope.getAllTabStatus();
        };

        $scope.openNextTab = function (currentTab) {
            ShowLoader();
            var dataPromises = [];
            var currentTabIndex = 0;
            angular.forEach($scope.individualViewConfig.processedTabs, function (tabItem, index) {
                if (tabItem.name == currentTab.name) {
                    dataPromises.push($scope.saveTabStatus(currentTab));
                    dataPromises.push($scope.saveTabHtml(currentTab, (index + 1)));
                    currentTabIndex = index;
                } else if (!$scope.isBackoffice && !!currentTab.markTabsIncompleteOnChange && currentTab.markTabsIncompleteOnChange.indexOf(tabItem.name) != -1) {
                    tabItem.inComplete = true;
                    tabItem.isInvalidTab = true;
                    dataPromises.push($scope.saveTabStatus(tabItem));
                    tabItem.inComplete = false;
                }
            });
            ShowLoader();
            $q.all(dataPromises)
                .then(function (response) {
                    currentTab.isSaved = (!currentTab.inComplete);
                    currentTab.isInvalidTab = currentTab.isInvalid;
                    HideLoader();
                    if (!!currentTab.skipNextTab) {
                        return;
                    }
                    for (var j = currentTabIndex + 1; j < $scope.individualViewConfig.processedTabs.length; j++) {
                        if ($scope.individualViewConfig.processedTabs[j].isEnabled === true) {
                            if (!!$scope.individualViewConfig.feeAlteringTabs && $scope.individualViewConfig.feeAlteringTabs.includes(currentTab.name)) {
                                ShowLoader();
                                $scope.getFeeDetails()
                                    .then(function () {
                                        HideLoader();
                                        $scope.setCurrentTab($scope.individualViewConfig.processedTabs[j]);
                                    });
                            } else {
                                $scope.setCurrentTab($scope.individualViewConfig.processedTabs[j]);
                            }
                            break;
                        }
                    }
                });
        };

        $scope.$on("ApplicationSubmit", function (event, args) {
            ShowLoader();
            if (args.isAutoApproval) {
                applicationStatusUpdate(2);
            } else {
                applicationStatusUpdate(2);
            }
        });

        $scope.$on("ApplicationSubmitWithoutPayment", function (event, args) {
            ShowLoader();
            if (!!$scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 2 && [16].includes($scope.applicationInfo.ApplicationStatusId)) {
                applicationStatusUpdate(18);
            } else {
                applicationStatusUpdate(13);
            }
        });

        $scope.$on("ApplicationSubmitForm", function (event, args) {
            $scope.$broadcast("ReportingSubmit", {});
        });

        $scope.saveTabStatus = function (tab) {
            var applicableQueueType = $rootScope.routeData.queueType;
            if (applicableQueueType == "DeficiencyQ") {
                applicableQueueType = "ReviewQ";
            }
            if (applicableQueueType == "TechDeficiencyQ") {
                applicableQueueType = "TechReviewQ";
            }
            if (applicableQueueType == "ReviewQ" && $scope.applicationInfo.ApplicationStatusId == 30) {
                applicableQueueType = "ReviewQFinal";
            }
            var reqData = {
                ApplicationTabStatusId: tab.ApplicationTabStatusId,
                ApplicationId: $scope.ApplicationId,
                PageModuleId: 0,
                PageModuleTabSubModuleId: 0,
                PageTabSectionId: 0,
                IndividualId: $scope.IndividualId,
                ProviderId: $scope.IndividualId,
                TabName: (applicableQueueType + "-" + tab.name),
                ApplicationTabStatus: (!tab.inComplete),
                SortOrder: 1,
                IsActive: 1,
                IsDeleted: 0,
                CreatedBy: sessionStorage.UserID,
                CreatedOn: new Date()
            };
            return initialLicenseApplicationService.saveTabStatus(reqData, sessionStorage.Key);
        };

        $scope.saveTabHtml = function (tab, tabNumber) {
            var htmlContent = angular.element('#tabSection' + tab.name).parent().html();
            htmlContent = htmlContent.replace(/\<textarea /g, '<div class="textAreaReplaced"><div ').replace(/\<\/textarea>/g, '</div></div>');
            var htmlContentElement = angular.element('<div>' + htmlContent + '</div>');
            var textAreaElements = angular.element(htmlContentElement).find('.textAreaReplaced');
            angular.forEach(textAreaElements, function (textAreaElement, index) {
                if (!!textAreaElement.firstChild)
                    textAreaElement.innerHTML = "<pre>" + textAreaElement.firstChild.innerHTML + "</pre>";
            });
            var reqData = {
                ApplicationID: $scope.ApplicationId,
                IndividualID: $scope.IndividualId,
                TabName: ($rootScope.routeData.queueType + "-" + tab.name),
                CreatedBy: sessionStorage.UserID,
                DataDesc: "New Application section " + tab.name + " Html",
                HTMLContent: angular.element(htmlContentElement).html(),
                SortingOrder: tabNumber
            };
            return htmlToPdfGenerationService.saveTabHtml(reqData, sessionStorage.Key);
        };

        $scope.getAllTabStatus = function () {
            var applicableQueueType = $rootScope.routeData.queueType;
            if (applicableQueueType == "DeficiencyQ") {
                applicableQueueType = "ReviewQ";
            }
            if (applicableQueueType == "TechDeficiencyQ") {
                applicableQueueType = "TechReviewQ";
            }
            if (applicableQueueType == "ReviewQ" && $scope.applicationInfo.ApplicationStatusId == 30) {
                applicableQueueType = "ReviewQFinal";
            }
            initialLicenseApplicationService.getAllTabStatus($scope.ApplicationId, $scope.IndividualId, sessionStorage.Key)
                .then(function (response) {
                    if (response.Status == true) {
                        $scope.tabStatusList = response.ProviderTabStatusList;
                        var deficiencyDataPromise = [
                            deficiencyService.getIndividualDeficiency(sessionStorage.Key, $scope.IndividualId, $scope.ApplicationId, ''),
                            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ('?tableKey=tabdeficiencyreason')),
                            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ('?tableKey=entityexternalformtype'))
                        ];
                        $q.all(deficiencyDataPromise).then(function (responseDefData) {
                            $scope.individualDefeciencyAllList = responseDefData[0].IndividualDefeciencyAllList;
                            $scope.deficiencyReasonData = responseDefData[1].ReferenceEntities.whereEquals($scope.applicationInfo.ApplicationTypeId, 'ApplicationTypeId');
                            $scope.entityExternalFormTypes = responseDefData[2].ReferenceEntities;
                            $scope.individualViewConfig.processedTabs.forEach(function (tab, j) {
                                tab.isSaved = $scope.tabStatusList.whereContains((applicableQueueType + "-" + tab.name), 'TabName').select('ApplicationTabStatus').firstOrDefault();
                                if ($scope.applicationInfo.ApplicationStatusId == 3) {
                                    tab.isSaved = true;
                                }
                                if (!tab.isSaved) {
                                    if ((!!tab.showCompletedInQueues && tab.showCompletedInQueues.indexOf($rootScope.routeData.queueType) != -1)
                                        || (!!tab.showCompletedForApplicationStatus && tab.showCompletedForApplicationStatus.indexOf($scope.applicationInfo.ApplicationStatusId) != -1)) {
                                        tab.isSaved = true;
                                    }
                                }
                                tab.deficiencyData = getDeficiencyReasonDataforTab(tab);
                                tab.recordBasedDeficiencyData = getDeficiencyReasonDataforTab(tab, true);
                                if (!!tab.deficiencyData && tab.deficiencyData.length > 0) {
                                    var tabSavedDeficiencyData = null;
                                    if (!!$scope.individualDefeciencyAllList) {
                                        tabSavedDeficiencyData = $scope.individualDefeciencyAllList.whereEquals(tab.name, 'TabName').firstOrDefault();
                                    }
                                    tab.individualDefeciency = getDeficiencyData(tab, tabSavedDeficiencyData);
                                }
                            });
                            processIsDeficient(true);
                            processTabsToBeShownForLevel();
                            if ($scope.isBackoffice) {
                                checkPayment();
                            }
                            $scope.individualViewConfig.tabsProcessed = true;
                            if (!!$scope.openPaymentTab) {
                                setTabByNumber($scope.individualViewConfig.paymentTabNumber);
                            } else {
                                setTabByNumber(0);
                            }
                        });
                    }
                });
        };

        $scope.getIndividual = function () {
            return backofficeIndividualPersonalService.individualBYIndividualId(sessionStorage.Key, $scope.IndividualId);
        };

        $scope.getApplicationInfoByApplicationId = function () {
            ShowLoader();
            var dataPromises = [
                initialLicenseApplicationService.getApplicationInfoByApplicationId($scope.ApplicationId, $scope.userId, sessionStorage.Key),
                backofficeIndividualPersonalService.individualBYIndividualId(sessionStorage.Key, $scope.IndividualId),
                backofficeIndividualLicenseService.individualLicenseDetailBYIndividualId(sessionStorage.Key, $scope.IndividualId),
                initialLicenseApplicationService.getRefTablesGetAll(sessionStorage.Key, 'licensetype'),
                $scope.getIndividual()
            ];

            $q.all(dataPromises).then(function (response) {
                var isResponseValid = true;
                angular.forEach(response, function (responseItem) {
                    if (!responseItem.Status) {
                        isResponseValid = false;
                    };
                });
                if (!isResponseValid) {
                    HideLoader();
                    return;
                }

                if (!!response[0].ApplicationResponseList && response[0].ApplicationResponseList.length > 0) {
                    $scope.applicationInfo = response[0].ApplicationResponseList[0];
                    $scope.applicationInfo.ApplicationStatusIdAtLoad = $scope.applicationInfo.ApplicationStatusId;
                    $scope.ApplicationTypeId = response[0].ApplicationResponseList[0].ApplicationTypeId;
                }
                licenseApplicationListingService.getRefTablesGetAll(sessionStorage.Key, 'licenseapplicationfor').then(function (resp) {
                    $scope.initialLicenseApplicationSubTypes = resp.ReferenceEntities;
                    var licenseApp = $scope.initialLicenseApplicationSubTypes.filter(function (obj) {
                        return obj.LicenseApplicationForId == $scope.applicationInfo.LicenseApplicationForId;
                    });
                    $scope.LicenseApplicationForName = licenseApp[0].LicenseApplicationForName;
                });

                if (!!response[1] && response[1].IndividualResponse) {
                    $scope.individualDetail = response[1].IndividualResponse[0];
                }
                if (!!response[2] && response[2].IndividualLicenseList) {
                    $scope.individualLicense = response[2].IndividualLicenseList.whereEquals($scope.ApplicationId, 'ApplicationId').firstOrDefault();
                    $scope.individualLicense.ActionFlag = 'M';

                    //No license associated with this application, so make a new one to ensure consistency
                    if (!$scope.individualLicense) {
                        individualLicenseSave(getNewLicenseEntry())
                            .then(function (data) {
                                $scope.individualLicense = data.IndividualLicenseList.whereEquals($scope.ApplicationId, 'ApplicationId').firstOrDefault();
                                $scope.individualLicense.ActionFlag = 'M';
                            });
                    } else {
                        $scope.existingLicense = response[2].IndividualLicenseList
                            .whereNotEquals($scope.ApplicationId, 'ApplicationId')
                            .whereEquals($scope.individualLicense.LicenseTypeId, 'LicenseTypeId')
                            .firstOrDefault();
                    }
                }
                if (!!response[3] && response[3].ReferenceEntities) {
                    var licenseTypeInfo = response[3].ReferenceEntities.whereEquals($scope.individualLicense.LicenseTypeId, 'LicenseTypeId').firstOrDefault();
                    $scope.individualLicense.licenseTypeInfo = licenseTypeInfo;

                    if (!licenseTypeInfo) {
                        throw 'LicenseTypeId {0} not found.'.format($scope.individualLicense.LicenseTypeId);
                    }
                }

                if (!!response[4] && response[4].IndividualResponse) {
                    $scope.personalInfo = response[4].IndividualResponse.firstOrDefault();
                }

                processTabsToBeShown();
                $scope.licenseEndorsement = {};

                $scope.getFeeDetails();
            });
        };

        $scope.saveCurrentTabAndNavigateToClickedTab = function (tab) {
            $scope.tabClickedOn = tab;
            if ($scope.individualCurrentTab) {
                $scope.$broadcast("SwitchingTab", {
                    fromTab: $scope.individualCurrentTab.name,
                    switchingToTab: tab
                });
            }
        }

        $scope.$on('setCurrentTabNow', function (evt, tab) {
            if (tab && tab.isInvalid) {
                angular.forEach($scope.individualViewConfig.processedTabs, function (tabItem) {
                    if (tabItem.name == tab.name) {
                        tabItem.isInvalidTab = true;
                    }
                });
            }
            $scope.setCurrentTab($scope.tabClickedOn);
            $scope.tabClickedOn = null;
        });

        $scope.setCurrentTab = function (tab) {
            var isAllPreviousTabsComplete = true;
            for (var i = 0; i < $scope.individualViewConfig.processedTabs.length; i++) {
                if ($scope.individualViewConfig.processedTabs[i].name == tab.name) {
                    break;
                }
                if ((!$scope.individualViewConfig.processedTabs[i].isSaved || !!$scope.individualViewConfig.processedTabs[i].isInvalidTab) && $scope.individualViewConfig.processedTabs[i].isEnabled) {
                    isAllPreviousTabsComplete = false;
                }
            }
            if (isAllPreviousTabsComplete) {
                tab.isAllPreviousTabsComplete = isAllPreviousTabsComplete;
            }
            if ((!$scope.isBackoffice && !!tab.openIfAllPreviousComplete) || (!!$scope.isBackoffice && !!tab.openIfAllPreviousCompleteInBackoffice)) {
                if (!isAllPreviousTabsComplete) {
                    showStatusMessage(Messages.General.Errors.CompleteTab(tab.text), 'warning');
                    utilityService.scrollTop();
                    return;
                } else {
                    hideStatusMessage();
                }
            } else {
                hideStatusMessage();
            }

            var isAlltabsSaved = true;
            var isAlltabsSavedExceptPayment = true;
            angular.forEach($scope.individualViewConfig.processedTabs, function (tabItem) {
                tabItem.isActiveTab = false;
                tabItem.isActiveTab = false;
                if (!tabItem.isSaved && tabItem.isEnabled) {
                    isAlltabsSaved = false;
                    if (!['feeAndPayment', 'staffReview'].includes(tabItem.name)) {
                        isAlltabsSavedExceptPayment = false;
                    }
                }
                if (!$scope.isBackoffice && !!tabItem.clearIfPreviousTabOpened) {
                    if (tab.tabNumber < tabItem.tabNumber
                        && !!tabItem.isSaved) {
                        tabItem.isInvalidTab = true;
                    }
                }
            });

            $scope.individualViewConfig.isAlltabsSaved = isAlltabsSaved;
            $scope.individualViewConfig.isAlltabsSavedExceptPayment = isAlltabsSavedExceptPayment;

            if (tab.name == 'feeAndPayment') {
                ShowLoader();
                $scope.getFeeDetails().then(function (responseFeeDetails) {
                    $scope.individualCurrentTab = tab;
                    if (tab) {
                        tab.isActiveTab = true;
                        tab.isLoadedOnce = true;
                    }
                }, function (error) {
                    showStatusMessage(error, 'error');
                })
            } else {
                $scope.individualCurrentTab = tab;
                if (tab) {
                    tab.isActiveTab = true;
                    tab.isLoadedOnce = true;
                }
            }
            auditVisitInfoSave($scope.individualCurrentTab);
            $scope.canChangeTabs = true;
            $scope.validator[tab.name].errorMessages = [];
            $timeout(function () {
                utilityService.scrollToElementOffset("newApplicationForm");
            }, 1000);
        };

        var setTabByNumber = function (tabNumber) {
            if ($scope.individualViewConfig.processedTabs[tabNumber])
                $scope.setCurrentTab($scope.individualViewConfig.processedTabs[tabNumber]);
        };

        var applicationStatusUpdate = function (status) {
            ShowLoader();
            var applicationSubmitPromise = licenseApplicationListingService.applicationStatusUpdate(sessionStorage.Key, $scope.applicationInfo.ApplicationId, status, {});
            var applicationSubmitLkPromise = applicationStatusLkSave(sessionStorage.UserID, status);
            var applicationSubmitChangeLogPromise = applicationStatusChangeLogSaveData(sessionStorage.UserID, status);
            $q.all([applicationSubmitPromise, applicationSubmitLkPromise, applicationSubmitChangeLogPromise]).
                then(function (responseAppSubmitted) {
                    if (!$scope.isBackoffice) {
                        HideLoader();
                        $state.go('app.PaymentConfirmation', {
                            entityId: $scope.IndividualId,
                            entityType: 'I',
                            applicationId: $scope.applicationInfo.ApplicationId,
                            applicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                            paymentMethodTypeId: '',
                            isReSubmit: false
                        });
                    }
                    $scope.applicationInfo.ApplicationStatusId = status;
                }, function (error) {
                    HideLoader();
                    $scope.isdisable = false;
                    showStatusMessage(
                        error,
                        'error'
                    );
                });
        };

        var applicationStatusLkSave = function (userId, applicationStatusId) {
            var reqData = {
                ApplicationId: $scope.applicationInfo.ApplicationId,
                ApplicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                ApplicationStatusId: applicationStatusId,
                IsActive: 1,
                CreatedBy: userId,
                HighPriority: false
            };

            return licenseApplicationListingService.applicationStatusLkSave(sessionStorage.Key, userId, reqData);
        };

        var applicationStatusChangeLogSaveData = function (userId, applicationStatusId) {
            var reqData = {
                ApplicationId: $scope.applicationInfo.ApplicationId,
                ApplicationNumber: $scope.applicationInfo.ApplicationNumber,
                ApplicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                ApplicationStatusId: applicationStatusId,
                ApplicationSubmitMode: 'B',
                LicenseTypeId: $scope.applicationInfo.LicenseTypeId
            };

            return licenseApplicationListingService.applicationStatusChangeLogSave(sessionStorage.Key, userId, reqData);
        };

        var processTabsToBeShownForLevel = function () {
            angular.forEach($scope.individualViewConfig.processedTabs, function (tabItem) {
                if ($scope.applicationConfig.showTabsForLevel.includes(tabItem.level)) {
                    tabItem.isEnabled = true;
                } else {
                    tabItem.isEnabled = false;
                }
            });
        };

        $scope.resetAllTabStatus = function (tabLevel) {
            angular.forEach($scope.individualViewConfig.processedTabs, function (tabItem) {
                if (tabLevel == 'all' || tabItem.level == tabLevel) {
                    tabItem.tabSaveStatus = null;
                }
            });
        };

        $scope.proceedToNextLevel = function () {
            var highestTabLevel = $scope.getHighestLevel();
            $scope.resetAllTabStatus(highestTabLevel);
            $scope.$broadcast("SaveApplicationTab", {
                tabLevel: highestTabLevel,
                showConfirmationPopup: (highestTabLevel != 2)
            });
            var intervalDataSave = $interval(function () {
                var allTabsProcessedStatus = true;
                var allTabsSavedSuccessfully = true;
                angular.forEach($scope.individualViewConfig.processedTabs, function (tabItem) {
                    if (tabItem.level == highestTabLevel) {
                        if (!tabItem.tabSaveStatus) {
                            allTabsProcessedStatus = false;
                        } else if (tabItem.tabSaveStatus != 'success') {
                            allTabsSavedSuccessfully = false;
                        }
                    }
                });
                if (!!allTabsProcessedStatus) {
                    $interval.cancel(intervalDataSave);
                    if (!!allTabsSavedSuccessfully) {
                        $scope.applicationConfig.showTabsForLevel.push(highestTabLevel + 1);
                        processTabsToBeShownForLevel();
                    }
                }
            }, 500);

        };

        $scope.getHighestLevel = function () {
            return Math.max(...$scope.applicationConfig.showTabsForLevel);
        };

        var processIsDeficient = function (isInitialProcessing) {
            var isDeficient = false;
            var isDeficiencyResolved = true;
            var isDefeciencyReviewedByIndividual = true;
            var isStaffDeficiencyExists = false;
            var onlystaffDeficiencyExists = true;

            $scope.individualViewConfig.processedTabs.forEach(function (tabData, j) {
                var isTabDeficient = false;
                var isTabDeficiencyResolved = true;
                var isTabDefeciencyReviewedByIndividual = true;

                if (!!tabData.individualDefeciency && !!tabData.individualDefeciency.IndividualDefeciencyDtlList && tabData.individualDefeciency.IndividualDefeciencyDtlList.length > 0) {
                    tabData.individualDefeciency.IndividualDefeciencyDtlList.forEach(function (deficiencyDtl, i) {
                        if (!$scope.isBackoffice || $scope.routeData.deficiencyReasonTypeIds.indexOf(deficiencyDtl.DeficiencyReasonTypeId) != -1) {
                            if (deficiencyDtl.IsDefecient && (!deficiencyDtl.ResolvedByStaff || deficiencyDtl.ResolvedByStaff.toLowerCase() != 'y')) {
                                isDeficient = true;
                                isTabDeficient = true;

                                if (!tabData.individualDefeciency.IndividualDefeciencyDtlList[i].IsDefeciencyResolved) {
                                    onlystaffDeficiencyExists = false;
                                    isDeficiencyResolved = false;
                                    isTabDeficiencyResolved = false;
                                }
                                if (!tabData.individualDefeciency.IndividualDefeciencyDtlList[i].IsDefeciencyReviewedByIndividual && (!tabData.individualDefeciency.IndividualDefeciencyDtlList[i].ResolvedByStaff || tabData.individualDefeciency.IndividualDefeciencyDtlList[i].ResolvedByStaff.toLowerCase() != 'y')) {
                                    isDefeciencyReviewedByIndividual = false;
                                    isTabDefeciencyReviewedByIndividual = false;
                                }
                            } else if (deficiencyDtl.IsDefecient && (!!deficiencyDtl.ResolvedByStaff && deficiencyDtl.ResolvedByStaff.toLowerCase() == 'y')) {
                                if (!tabData.individualDefeciency.IndividualDefeciencyDtlList[i].IsDefeciencyResolved) {
                                    isStaffDeficiencyExists = true;
                                }
                                isDeficient = true;
                                if (!!$scope.isBackoffice)
                                    isTabDeficient = true;
                                if (!!$scope.isBackoffice && !tabData.individualDefeciency.IndividualDefeciencyDtlList[i].IsDefeciencyResolved) {
                                    isDeficiencyResolved = false;
                                    isTabDeficiencyResolved = false;
                                }
                            }
                        }
                    });
                }

                tabData.isTabDeficient = isTabDeficient;
                tabData.isTabDeficiencyResolved = isTabDeficiencyResolved;
                tabData.isTabDefeciencyReviewedByIndividual = isTabDefeciencyReviewedByIndividual;
                if (!$scope.isBackoffice && !!isInitialProcessing && ($scope.applicationInfo.DeficiencyStatusId == 1 || $scope.applicationInfo.BackgroundDeficiencyStatusId == 1)) {
                    if (!tabData.isTabDeficient || !!tabData.isTabDeficiencyResolved) {
                        tabData.hideInDeficiencyProcessing = true;
                    }
                }
            });

            if (!$scope.isBackoffice && !!isInitialProcessing && ($scope.applicationInfo.DeficiencyStatusId == 1 || $scope.applicationInfo.BackgroundDeficiencyStatusId == 1)) {
                processTabsToBeShownForDeficiency();
            }

            $scope.individualViewConfig.isDeficient = isDeficient;
            $scope.individualViewConfig.isDeficiencyResolved = isDeficiencyResolved;
            $scope.individualViewConfig.isDefeciencyReviewedByIndividual = isDefeciencyReviewedByIndividual;
            $scope.individualViewConfig.onlystaffDeficiencyExists = onlystaffDeficiencyExists;
            if (!!$scope.isBackoffice || (!$scope.isBackoffice && !!isInitialProcessing)) {
                $scope.individualViewConfig.isStaffDeficiencyExists = isStaffDeficiencyExists;
            }
        };

        var redirectApplication = function (applicationProcessingData) {
            if (!$scope.isBackoffice) {
                $state.go('app.PaymentConfirmation', {
                    entityId: $scope.IndividualId,
                    entityType: 'I',
                    applicationId: $scope.applicationInfo.ApplicationId,
                    applicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                    paymentMethodTypeId: $rootScope.SelectedPaymentMethod,
                    isReSubmit: false
                });
            } else {
                $scope.$emit('applicationStatusChanged', {
                    ApplicationId: $scope.applicationInfo.ApplicationId,
                    ApplicationStatusId: applicationProcessingData.ApplicationStatusId
                });
                $scope.$emit('closeApplication', {});
            }
        };


        var saveWarningRecord = function (warningData) {
            warningService.warningSave([warningData], sessionStorage.Key).then(function (response) {
                if (response.Status) {

                } else {
                    showStatusMessage("Error occured while saving Admin Hold", "error");
                }
            }, function (data) {
                showStatusMessage(data.message, "error");
            });
        };

        var checkAndInsertAdminHold = function () {

            var disciplineObj = {
                AppliesToInitialApp: true,
                AppliesToRenewal: true,
                DisciplineNotes: "self-disclosed out of state action",
                DisciplineTypeId: 4,
                EntityId: $scope.IndividualId,
                EntityTy: "I",
                IsActive: true,
                StartDate: new Date()
            }
            saveWarningRecord(disciplineObj);
        };

        var checkIfDisciplined = function () {

            var source = "cadcabpelsgNewApp";

            initialApplicationLegalInfoService.legalInfoGet($scope.IndividualId, $scope.ApplicationId, sessionStorage.Key, source)
                .then(function (response) {
                    $scope.legalInfo = response.IndividualLegalResponse;
                    angular.forEach($scope.legalInfo, function (legalInfoItem) {
                        if (legalInfoItem.ContentItemLkId == 400 && !!legalInfoItem.ContentItemResponse) {
                            checkAndInsertAdminHold();
                        }
                    });
                }, function (error) {
                    showStatusMessage(error.message, "error");
                });

        };

        $scope.paymentSuccess = function (event, args) {
            if (!(!!$scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 2 && [16, 18].includes($scope.applicationInfo.ApplicationStatusId))) {
                processExternalForm().then(function (externalformResponse) {
                    if (externalformResponse.Status) {

                        // Add a new Admin Hold if Action by another state question is answered true
                        checkIfDisciplined();

                        var reqObj = {
                            IndividualId: $scope.IndividualId,
                            ApplicationId: args.applicationId,
                            GenerateBackgroundRecord: true
                        };
                        dcaIService.dojMatch(sessionStorage.Key, reqObj).then(function (response) {
                            if (!response.Status) {
                                showStatusMessage(response.Message, "error");
                                return;
                            }
                            $scope.applicationInfo.ApplicationStatusId = args.statusId;
                            $scope.$emit('applicationStatusChanged', {
                                ApplicationId: args.applicationId,
                                ApplicationStatusId: args.statusId
                            });

                            if (!$scope.isBackoffice) {
                                $state.go('app.PaymentConfirmation', {
                                    entityId: $scope.IndividualId,
                                    entityType: 'I',
                                    applicationId: $scope.applicationInfo.ApplicationId,
                                    applicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                                    paymentMethodTypeId: $rootScope.SelectedPaymentMethod,
                                    isReSubmit: false
                                });
                            }
                        }, function (error) {
                            showStatusMessage(error.message, "error");
                        });
                    }
                }, function (response) {
                    return showErrorMessage(response);
                });
            } else {
                redirectApplication({ ApplicationStatusId: $scope.applicationInfo.ApplicationStatusId });
            }
        };

        $scope.$on('paymentSuccess', $scope.paymentSuccess);

        $scope.preApproveApplication = function (event, args) {
            ShowLoader('Pre-approving Application...');
            var statuses = ApplicationSettings.AppLicenseStatus[args.approvalType];
            var applicationProcessingData = {
                ApprovalType: "",
                ApplicationStatusId: (!!statuses.ApplicationStatusId ? statuses.ApplicationStatusId : $scope.applicationInfo.ApplicationStatusId),
                LogApplicationStatusIds: (!!statuses.ApplicationStatusId ? statuses.ApplicationStatusId : $scope.applicationInfo.ApplicationStatusId),
                ApplicationReviewReasonIds: null,
                ApplicationInternalStatusId: statuses.ApplicationInternalStatusId,
                UpdateBackgroundCheckStatus: statuses.UpdateBackgroundCheckStatus,
                BackgroundCheckStatusId: statuses.BackgroundCheckStatusId
            };
            if (['FingerprintQ', 'FingerprintDeficiencyQ'].indexOf($scope.routeData.queueType) != -1) {
                if (!!$scope.applicationInfo.BackgroundDeficiencyStatusId) {
                    applicationProcessingData.ProcessBackgroundDeficiency = true;
                    applicationProcessingData.BackgroundDeficiencyStatusId = 4;
                }
            } else {
                if (!!$scope.applicationInfo.DeficiencyStatusId) {
                    applicationProcessingData.ProcessDeficiency = true;
                    applicationProcessingData.DeficiencyStatusId = 4;
                }
            }
            processLicenseAndApplication($scope.IndividualId, $scope.ApplicationId, applicationProcessingData);
        };
        $scope.$on('preApproveApplication', $scope.preApproveApplication);

        $scope.approveApplication = function (event, args) {
            ShowLoader('Approving Application...');
            var statuses = ApplicationSettings.AppLicenseStatus['approved'];
            var applicationProcessingData = {
                ApprovalType: "applicationApproval",
                ApplicationStatusId: statuses.ApplicationStatusId,
                LogApplicationStatusIds: statuses.ApplicationStatusId,
                ApplicationReviewReasonIds: null
            };
            processLicenseAndApplication($scope.IndividualId, $scope.ApplicationId, applicationProcessingData);
        };
        $scope.$on('approveApplication', $scope.approveApplication);

        var processLicenseAndApplication = function (individualId, applicationId, applicationProcessingData) {
            var reqData = {
                UpdateApplicationStatus: true,
                IndividualId: individualId,
                ApplicationId: applicationId,
                ApprovalType: applicationProcessingData.ApprovalType,
                ApplicationStatusId: applicationProcessingData.ApplicationStatusId,
                IsSubmission: true,
                LogApplicationStatusIds: applicationProcessingData.LogApplicationStatusIds,
                ProcessDeficiency: applicationProcessingData.ProcessDeficiency,
                DeficiencyStatusId: applicationProcessingData.DeficiencyStatusId,
                ProcessBackgroundDeficiency: applicationProcessingData.ProcessBackgroundDeficiency,
                BackgroundDeficiencyStatusId: applicationProcessingData.BackgroundDeficiencyStatusId,
                UpdateBackgroundCheckStatus: applicationProcessingData.UpdateBackgroundCheckStatus,
                BackgroundCheckStatusId: applicationProcessingData.BackgroundCheckStatusId,
                ApplicationReviewReasonIds: applicationProcessingData.ApplicationReviewReasonIds,
                ApplicationInternalStatusId: applicationProcessingData.ApplicationInternalStatusId
            }
            initialApplicationstatusService.processLicenseAndApplication(sessionStorage.Key, reqData)
                .then(function (response) {
                    redirectApplication(applicationProcessingData);
                }, function (data) {
                    showStatusMessage(data, "error");
                });
        };

        $scope.resubmitApplication = function () {
            var tabName;
            $scope.individualViewConfig.processedTabs.forEach(function (obj) {
                if (!!obj.isActiveTab) {
                    tabName = obj.name;
                }
            });
            $scope.$broadcast("deficiencyResolve", { tabName: tabName });
        }

        $scope.$on('deficiencyExecuted', function (evt, args) {
            if (!!args.success) {
                $scope.deficiencyStatusUpdate();
            } else {
                return;
            }
        });

        $scope.deficiencyStatusUpdate = function () {
            if ($scope.individualViewConfig.isDeficient && !$scope.individualViewConfig.isDefeciencyReviewedByIndividual) {
                showStatusMessage(Messages.General.Errors.ResolveDef, "error");
                return;
            } else {
                $scope.$broadcast("SaveDeficiency", {});
                hideStatusMessage();
            }
            ShowLoader();
            var queueApprovalData = {
                UpdateApplicationStatus: true,
                IndividualId: $scope.IndividualId,
                ApplicationId: $scope.applicationInfo.ApplicationId,
                ApplicationStatusId: $scope.applicationInfo.ApplicationStatusId,
            };
            if (!!$scope.individualViewConfig.isStaffDeficiencyExists) {
                if (!!$scope.applicationInfo.DeficiencyStatusId && $scope.applicationInfo.DeficiencyStatusId == 1) {
                    queueApprovalData.ProcessDeficiency = true;
                    queueApprovalData.DeficiencyStatusId = 2;
                }
                if (!!$scope.applicationInfo.BackgroundDeficiencyStatusId && $scope.applicationInfo.BackgroundDeficiencyStatusId == 1) {
                    queueApprovalData.ProcessBackgroundDeficiency = true;
                    queueApprovalData.BackgroundDeficiencyStatusId = 2;
                }
            } else {
                if (!!$scope.applicationInfo.DeficiencyStatusId && $scope.applicationInfo.DeficiencyStatusId == 1) {
                    queueApprovalData.ProcessDeficiency = true;
                    queueApprovalData.DeficiencyStatusId = 3;
                }
                if (!!$scope.applicationInfo.BackgroundDeficiencyStatusId && $scope.applicationInfo.BackgroundDeficiencyStatusId == 1) {
                    queueApprovalData.ProcessBackgroundDeficiency = true;
                    queueApprovalData.BackgroundDeficiencyStatusId = 3;
                }
            }
            initialApplicationstatusService.processLicenseAndApplication(sessionStorage.Key, queueApprovalData)
                .then(function (response) {
                    if (response.Status == false) {
                        showStatusMessage(response.Message, "error");
                        HideLoader();
                        return;
                    }
                    processExternalForm().then(function (externalformResponse) {
                        if (externalformResponse.Status) {
                            HideLoader();
                            $state.go('app.PaymentConfirmation', {
                                entityId: $scope.IndividualId,
                                entityType: 'I',
                                applicationId: $scope.applicationInfo.ApplicationId,
                                applicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                                paymentMethodTypeId: '',
                                isReSubmit: true
                            });
                        }
                    });
                }, function (data) {
                    showStatusMessage(error, "error");
                    HideLoader();
                });

        };

        var processTabsToBeShownForDeficiency = function () {
            var allTabs = $scope.individualViewConfig.processedTabs;
            var processedTabs = [];
            angular.forEach(allTabs, function (thisTab) {
                if (!thisTab.hideInDeficiencyProcessing) {
                    processedTabs.push(thisTab);
                }
            });
            $scope.canChangeTabs = true;
            $scope.individualViewConfig.processedTabs = processedTabs;
        };

        $scope.$on('deficiencyChanged', function (event, tab) {
            var beforeDeficient = tab.isTabDeficient;
            var beforeDeficiencyReviewed = tab.isTabDefeciencyReviewedByIndividual;
            var beforeDeficiencyResolved = tab.isTabDeficiencyResolved;

            processIsDeficient();

            var deficientChanged = beforeDeficient != tab.isTabDeficient;
            var reviewedChanged = beforeDeficiencyReviewed != tab.isTabDefeciencyReviewedByIndividual;
            var resolvedChanged = beforeDeficiencyResolved != tab.isTabDeficiencyResolved;

            if (deficientChanged || reviewedChanged || resolvedChanged) {
                tab.isTabDeficiencyDirty = true;
            }
        });

        $scope.$on('deficiencySaved', function (event, tab) {
            tab.isTabDeficiencyDirty = false;
        });

        var getFeeDetailsByAppType = function () {
            var feesForLicenseType = [];
            if (!(!$scope.isBackoffice && $scope.applicationInfo.ApplicationStatusId == 16)) {
                feesForLicenseType = $scope.feeDetailRows
                    .whereEquals($scope.ApplicationTypeId, 'ApplicationTypeId')
                    .whereEquals("INITIAL", 'FeeTypeCode')
                    .where(function (feeDetailItem) {
                        return ((feeDetailItem.LicenseTypeIds.split(',').indexOf($scope.individualLicense.LicenseTypeId.toString()) != -1)
                            && feeDetailItem.ApplicationSubTypeIds.split(',').indexOf($scope.applicationInfo.ApplicationSubTypeId.toString()) != -1);
                    });

                if (!$scope.applicationInfo.IsTemporary) {
                    feesForLicenseType = feesForLicenseType.whereNotEquals('TEMP', 'FeeTypeCode');
                }

                if (!!$scope.confirmation && $scope.confirmation.ConfirmationResponse == true) {
                    var fingerPrintFee = $scope.feeDetailRows
                        .whereEquals('FINGERPRINT', 'FeeTypeCode');
                    if (!!fingerPrintFee) {
                        feesForLicenseType = feesForLicenseType.concat(fingerPrintFee);
                    }
                }

                if (!!$scope.entityStateExams && $scope.entityStateExams.length > 0) {
                    $scope.entityStateExams.map(function (examTypeItem) {
                        var feeDetailRowsByExamType = $scope.feeDetailRows
                            .whereEquals($scope.applicationInfo.ApplicationTypeId, 'ApplicationTypeId')
                            .where(function (feeDetailItem) {
                                return (!!feeDetailItem.IsActive && !!feeDetailItem.ExamTypeIds && feeDetailItem.ExamTypeIds.split(',').indexOf(examTypeItem.ExamTypeId.toString()) != -1);
                            })
                            .whereEquals('INITIALEXAM', 'FeeTypeCode');
                        feeDetailRowsByExamType = feeDetailRowsByExamType.map(function (i) {
                            i.TableName = 'EntityExam';
                            i.TableLinkIdColumnName = 'EntityExamId';
                            i.TableLinkId = examTypeItem.EntityExamId;
                            i.isExamRow = true;
                            i.needsProcessing = (!!examTypeItem.ExamStatusId && [1].indexOf(examTypeItem.ExamStatusId) != -1)
                            return i;
                        });
                        if (!!feeDetailRowsByExamType) {
                            feesForLicenseType = feesForLicenseType.concat(feeDetailRowsByExamType);
                        }
                    });
                }

                if (!!feesForLicenseType && feesForLicenseType.length > 0) {
                    feesForLicenseType = feesForLicenseType.filter(function (feeItemForLicenseType) {
                        return (
                            (!$scope.applicationInfo.SubmittedDate && !!feeItemForLicenseType.IsActive)
                            || (!!$scope.applicationInfo.SubmittedDate &&
                                (
                                    (!feeItemForLicenseType.EndDate || utilityService.checkIfDateGreaterOrEqual(feeItemForLicenseType.EndDate, $scope.applicationInfo.SubmittedDate))
                                    && (!feeItemForLicenseType.EffectiveDate || utilityService.checkIfDateGreaterOrEqual($scope.applicationInfo.SubmittedDate, feeItemForLicenseType.EffectiveDate))
                                )
                            )
                        )
                    });
                }

                if (feesForLicenseType.length == 0) {
                    throw 'No fees associated with this license type';
                }
            }
            return {
                fees: feesForLicenseType,
                applicationFee: feesForLicenseType.sum('FeeAmount'),
                totalFee: feesForLicenseType.sum('FeeAmount'),
                applicationRequiredFee: feesForLicenseType.sum('FeeAmount'),
                isNewLicEndApp: (!!$scope.isNewLicEndApp)
            };
        };

        $scope.getFeeDetails = function () {
            var deferred = $q.defer();
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=feedetails&pullInActive=true")).then(function (responseFee) {
                if (responseFee.Status) {
                    $scope.feeDetailRows = responseFee.ReferenceEntities;
                    var entityExamPager = {
                        sortType: 'ExamId',
                        sortOrder: 'asc',
                        currentPage: 1,
                        totalRecords: 10,
                        pageSize: 100,
                        maxSize: 5,
                        ExamId: null,
                        FromDate: null,
                        ToDate: null,
                        EntityType: 'I',
                        EntityId: $scope.IndividualId,
                        ApplicationId: $scope.ApplicationId
                    };
                    $q.all([
                        FingerprintConfirmationService.getConfirmations(sessionStorage.Key, $scope.IndividualId, "I", $scope.ApplicationId, null, null),
                        examFactory.getEntityExams(sessionStorage.Key, entityExamPager)
                    ])
                        .then(function (data) {
                            if (data[0].Status && data[0].EntityConfirmations.length > 0) {
                                $scope.confirmationFingerprintOnFile = data[0].EntityConfirmations.whereEquals(506, 'ContentItemLkId').firstOrDefault();
                                $scope.confirmation = data[0].EntityConfirmations.whereEquals(470, 'ContentItemLkId').firstOrDefault();
                            }
                            $scope.entityStateExams = data[1].data.ExamList
                                .whereEquals(2, 'ExamNameId');

                            $scope.feeDetails = getFeeDetailsByAppType();
                            feePaymentService.getPaymentDetailsByApplicationIdAndIndividualId(sessionStorage.Key, $scope.ApplicationId, $scope.IndividualId).then(function (objPaymentDetail) {
                                if (objPaymentDetail.StatusCode != "00") {
                                    $scope.showStatusMessage(objPaymentDetail.Message, 'error');
                                    deferred.reject(objPaymentDetail);
                                } else {
                                    if (!!objPaymentDetail.PaymentDetails && objPaymentDetail.PaymentDetails.length > 0) {
                                        $scope.feeDetails.applicationFee = objPaymentDetail.PaymentDetails[0].AmountBilled;
                                        $scope.feeDetails.totalFee = objPaymentDetail.PaymentDetails[0].AmountDue;
                                    }
                                    deferred.resolve(objPaymentDetail);
                                }
                                HideLoader();
                            }, function (error) {
                                deferred.reject(error);
                                HideLoader();
                                $scope.showStatusMessage(error.Message, 'error');
                                return;
                            });
                        }, function (err) {
                            deferred.reject(err);
                        });
                }
            }, function (error) {
                deferred.reject(error);
                showStatusMessage(error, "error");
                HideLoader();
            });
            return deferred.promise;
        };

        var getIndividualDeficiencyDetailItem = function (tabDeficiencyDataItem) {
            return {
                IndividualId: $scope.IndividualId,
                IsDefecient: false,
                DefeciencyReasonId: tabDeficiencyDataItem.DeficiencyReasonId,
                DefeciencyReasonText: tabDeficiencyDataItem.DeficiencyReasonMessage,
                ResolvedByStaff: tabDeficiencyDataItem.ResolvedByStaff,
                DeficiencyReasonTypeId: tabDeficiencyDataItem.DeficiencyReasonTypeId,
                IsRecordBasedDeficiency: tabDeficiencyDataItem.IsRecordBasedDeficiency,
                VisibleInQueueMenuIds: tabDeficiencyDataItem.VisibleInQueueMenuIds,
                EditableInQueueMenuIds: tabDeficiencyDataItem.EditableInQueueMenuIds,
                AdditionalText: tabDeficiencyDataItem.AdditionalText,
                ExplanationRequired: tabDeficiencyDataItem.ExplanationRequired,
                IsActive: true,
            };
        };

        var getDeficiencyData = function (tab, deficiencyObj) {
            if (!deficiencyObj) {
                deficiencyObj = {
                    ApplicationId: $scope.ApplicationId,
                    IndividualId: $scope.IndividualId,
                    WorkflowQueueName: 'IntakeQ',
                    TabName: tab.name,
                    SortOrder: tab.tabNumber,
                    IsActive: true,
                    IndividualDefeciencyDtlList: []
                };
                angular.forEach(tab.deficiencyData, function (tabDeficiencyDataItem) {
                    if (!tabDeficiencyDataItem.IsRecordBasedDeficiency) {
                        deficiencyObj.IndividualDefeciencyDtlList.push(getIndividualDeficiencyDetailItem(tabDeficiencyDataItem));
                    }
                })
            } else {
                var deficiencyDetails = [];
                angular.forEach(tab.deficiencyData, function (tabDeficiencyDataItem) {
                    var deficiencyExists = false;
                    angular.forEach(deficiencyObj.IndividualDefeciencyDtlList, function (individualDefeciencyDtlItem) {
                        if (individualDefeciencyDtlItem.DefeciencyReasonId == tabDeficiencyDataItem.DeficiencyReasonId) {
                            deficiencyExists = true;
                            individualDefeciencyDtlItem.ResolvedByStaff = tabDeficiencyDataItem.ResolvedByStaff;
                            individualDefeciencyDtlItem.DeficiencyReasonTypeId = tabDeficiencyDataItem.DeficiencyReasonTypeId;
                            individualDefeciencyDtlItem.IsRecordBasedDeficiency = tabDeficiencyDataItem.IsRecordBasedDeficiency;
                            individualDefeciencyDtlItem.VisibleInQueueMenuIds = tabDeficiencyDataItem.VisibleInQueueMenuIds;
                            individualDefeciencyDtlItem.EditableInQueueMenuIds = tabDeficiencyDataItem.EditableInQueueMenuIds;
                            individualDefeciencyDtlItem.AdditionalText = tabDeficiencyDataItem.AdditionalText;
                            individualDefeciencyDtlItem.ExplanationRequired = tabDeficiencyDataItem.ExplanationRequired;
                            // individualDefeciencyDtlItem.name = tabDeficiencyDataItem.name;
                            deficiencyDetails.push(individualDefeciencyDtlItem);
                        }
                    });
                    if (!deficiencyExists) {
                        if (!tabDeficiencyDataItem.IsRecordBasedDeficiency) {
                            deficiencyDetails.push(getIndividualDeficiencyDetailItem(tabDeficiencyDataItem));
                        }
                    }
                });
                deficiencyObj.IndividualDefeciencyDtlList = deficiencyDetails;
            }
            return deficiencyObj;
        };

        var getDeficiencyReasonDataforTab = function (tabItem, loadOnlyRecordBasedDeficiency) {
            var applicableDeficiencyReasons = [];
            angular.forEach($scope.deficiencyReasonData, function (deficiencyReason) {
                if (tabItem.tabNumber == deficiencyReason.PageModuleTabSubModuleId
                    && (!loadOnlyRecordBasedDeficiency || (!!loadOnlyRecordBasedDeficiency && deficiencyReason.IsRecordBasedDeficiency))) {
                    applicableDeficiencyReasons.push(deficiencyReason);
                }
            });
            return applicableDeficiencyReasons;
        };

        var processExternalForm = function () {
            var deferred = $q.defer();
            if (!!$scope.individualLicense && !!$scope.individualLicense.licenseTypeInfo && $scope.individualLicense.licenseTypeInfo.EnableReferenceForm) {
                var externalEmailDataPromises = [];
                externalEmailDataPromises.push(initialApplicationExperiencService.getIndividualExperience($scope.IndividualId, $scope.ApplicationId, sessionStorage.Key));

                $q.all(externalEmailDataPromises).then(function (resData) {
                    var isSuccessFullyLoaded = !resData.any('!item.Status');
                    if (isSuccessFullyLoaded) {
                        var externalFormData = [];
                        var experienceEffectiveData = [];
                        var experienceList = resData[0].IndividualExperienceList;
                        if (!!experienceList) {
                            experienceEffectiveData = experienceList.whereEquals($scope.ApplicationId, 'ApplicationId').whereEquals(3, 'ExperienceStatusId').where('!!item.Email');
                            // experienceEffectiveData = experienceEffectiveData.groupBy('Email');
                        }
                        var entityExternalFormType = $scope.entityExternalFormTypes.whereEquals($scope.individualLicense.licenseTypeInfo.ReferenceFormType, 'ExternalFormTypeCode').firstOrDefault();
                        angular.forEach(experienceEffectiveData, function (experienceItem) {
                            var externalForm = {
                                EntityId: $scope.IndividualId,
                                EntityType: 'I',
                                EntityName: experienceItem.SupervisorName,
                                ApplicationId: $scope.ApplicationId,
                                ExternalFormTypeId: entityExternalFormType.ExternalFormTypeId,
                                ExternalFormStatusId: 1,
                                AssignedToEmailIds: experienceItem.Email,
                                IsActive: true,
                                IsDeleted: false,
                                TemplateId: 136,
                                SendEmail: true,
                                GenerateFormPdf: true,
                                EntityExternalFormAssignedItems: [],
                                LicenseTypeName: $scope.individualLicense.licenseTypeInfo.LicenseTypeName
                            };
                            externalForm.EntityExternalFormAssignedItems.push({
                                EntityId: $scope.IndividualId,
                                EntityType: 'I',
                                EntityExternalFormAssignedItemTypeId: 1,
                                EntityExternalFormAssignedItemStatusId: 1,
                                EntityExternalFormAssignedItemLinkId: experienceItem.IndividualExperienceId,
                                IsActive: true,
                                IsDeleted: false,
                                EntityExternalFormPdfDetail: {
                                    GenerateFormPdf: true,
                                    LoadHtmlFromDb: true,
                                    EntityId: $scope.IndividualId,
                                    EntityType: 'I',
                                    ApplicationId: $scope.ApplicationId,
                                    DataDesc: "Experience Form Part A",
                                    TabName: "ExperienceFormA",
                                    TableName: "individualexperience",
                                    TableLinkId: experienceItem.IndividualExperienceId,
                                    TableLinkIdColumnName: "IndividualExperienceId",
                                    TableLinkTypeId: 1,
                                    SortingOrder: 1,
                                    DocumentId: 107,
                                    DocumentCd: "D1107"
                                }
                            });
                            externalFormData.push(externalForm);
                            experienceItem.ExperienceStatusId = 1;
                        });
                        if (!!externalFormData && externalFormData.length > 0) {
                            $q.all([
                                externalFormService.saveEntityExternalForm(externalFormData, sessionStorage.Key),
                                externalFormService.saveIndividualExperience(experienceEffectiveData, sessionStorage.Key),
                            ]).then(function (response) {
                                var isSuccessFullySaved = !resData.any('!item.Status');
                                deferred.resolve({
                                    Status: isSuccessFullySaved
                                });
                                console.log(response);
                            }, function (data) {
                                showStatusMessage(data, "error");
                            });
                        } else {
                            deferred.resolve({
                                Status: true
                            });
                        }
                    }
                }, function (error) {
                    HideLoader();
                    deferred.reject(error);
                });
            } else {
                deferred.resolve({
                    Status: true
                });
            }
            return deferred.promise;
        };

        function auditVisitInfoSave(tab) {
            var data = {
                PageName: (($scope.isBackoffice ? "Backoffice" : "Online") + " - " + ("Application - " + tab.text)),
                SessionId: sessionStorage.Key,
                IndividualId: $scope.IndividualId,
                EntityId: tab.tabNumber,
                UserId: $scope.userId,
                Username: "",
                RequestUrlReferrer: document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };

        var checkPayment = function () {
            var dataPromises = {};
            var paymentClear = false;
            var reqParams = {
                IndividualId: $scope.IndividualId,
                ApplicationId: $scope.ApplicationId
            };
            dataPromises.invoiceMaster = warningService.getApplicationPaymentInvoiceStatus(sessionStorage.Key, reqParams);
            $q.all(dataPromises).then(function (response) {
                if (response.invoiceMaster.ApplicationPaymentStatus && response.invoiceMaster.ApplicationPaymentStatus.IsPaymentProcessed) {
                    paymentClear = true;
                }
                $scope.individualViewConfig.processedTabs.forEach(function (obj) {
                    if (obj.name == 'feeAndPayment') {
                        obj.isSaved = paymentClear;
                        obj.isInvalidTab = !paymentClear;
                    }
                });
            });

        }

        $scope.$on("invokeDeficiencyParent", function (event, args) {
            $scope.$broadcast("invokeDeficiency", args);
        });

        $scope.clearTabConfirmation = function (currentTab) {
            ShowLoader();
            var dataPromises = [];
            angular.forEach($scope.individualViewConfig.processedTabs, function (tabItem, index) {
                if (tabItem.name == currentTab.name) {
                    currentTab.ApplicationTabStatus = false;
                    dataPromises.push($scope.saveTabStatus(currentTab));
                }
            });
            ShowLoader();
            $q.all(dataPromises)
                .then(function (response) {
                    currentTab.isSaved = false;
                    HideLoader();
                });
        };

        $scope.$on("clearTabConfirmation", function (event, args) {
            $scope.clearTabConfirmation(args.currentTab);
        });
    };
})();