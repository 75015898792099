(function () {
    'use strict'
    angular.module('app.core')
        .directive('warning', warningDirective);
    warningDirective.$inject = [];

    function warningDirective() {
        return {
            restrict: 'E',
            scope: {
                entityId: '=',
                entityType: "=",
                boardInfoId: "=?",
                individualLicense: "="
            },
            templateUrl: "app/core/directive/warning/warning.html",
            controller: "WarningController"
        }
    }
})();