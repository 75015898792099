(function() {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeIndividualVerificationController", BackofficeIndividualVerificationController);

        BackofficeIndividualVerificationController.$inject = ["$rootScope", "$scope", "BackofficeIndividualService",
        "utilityService", "loggingService"
    ];

    function BackofficeIndividualVerificationController($rootScope, $scope, BackofficeIndividualService,
        utilityService, loggingService) {

        //Tab Initilizer
        $scope.$on("individualTabChanged", function(evt, individualId) {
            if ($rootScope.individualCurrentTab.name == "verification" && !$rootScope.individualCurrentTab.isLoadedOnce) {
                $scope.IndividualId = individualId;
                if (!$scope.isLoadedOnce) {
                    $scope.isLoadedOnce = true;
                } 
                $rootScope.individualCurrentTab.isLoadedOnce = true;          
            }
        });

        // Save Verification
        $scope.saveVerification = function () {
            try {
                hideStatusMessage();
                hideValidationSummary();
                ShowLoader("Please wait...");
                BackofficeIndividualService.LicenseSave(sessionStorage.Key)
                    .then(function(response){ response = response.data;
                        if ($scope.checkResponse(response)) {
                            showStatusMessage(response.Message, "success");
                            $scope.clearData();
                        }
                        HideLoader();
                    }, function(data) { data = data.data;
                        HideLoader();
                        if (data != null) { showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }
     }
})();