(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeIndividualExamController", BackofficeIndividualExamController);

    BackofficeIndividualExamController.$inject = ["$rootScope", "$scope", "backofficeIndividualExamService", "multipleFileUploadService", "WebApiUrl", "Messages"
    ];

    function BackofficeIndividualExamController($rootScope, $scope, backofficeIndividualExamService, multipleFileUploadService, WebApiUrl, Messages) {

        $scope.documentByIndividualDocumentId = multipleFileUploadService.documentByIndividualDocumentId;

        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, individualId) {
            devicePixelRatio
            if ($rootScope.individualCurrentTab.name == "exam") {
                $scope.IndividualId = individualId;
                $scope.ExamDV = false;
                ShowLoader();
                refExamNameGetAll();
                $rootScope.individualCurrentTab.isLoadedOnce = true;
                getDoumentType(32, 'D1032');
                initDocument();

            }
        });
        $scope.isEditExam = false;



        var initDocument = function () {
            $scope.documentExamTab = undefined;
            setTimeout(function () {
                $scope.$apply(function () {
                    $scope.documentExamTab = {
                        ApiSaveEndpoint: WebApiUrl + 'Individual/IndividualDocumentSave/',
                        ApplicationId: $scope.applicationId,
                        IndividualId: $scope.IndividualId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "R",
                        LoadByDocIdAndDocCode: true,
                        DocId: 32,
                        DocCode: "D1032",
                        LoadDocumentsWithoutBroadCast: false,
                        LinkType: 'BC',
                        PropertyToProcess: 'IndividualExamId',
                        IsLinkedDocControl: true
                    };
                });
            }, 1000);
        };

        var getDoumentType = function (id, code) {
            multipleFileUploadService.documentGetDocumentTypeName(sessionStorage.Key, id, code)
                .then(function (response) {
                    if ($scope.checkResponse(response)) {
                        if (id == 8) {
                            $scope.DocType = response.DocumentMasterGET;
                        } else {
                            $scope.DocType2 = response.DocumentMasterGET;
                        }
                    }
                }, function (data) {
                    showStatusMessage(data, "error");
                });
        }

        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/d!/yyyy',
            toDateOptions: {
                minDate: null
            },
            fromDateOptions: {}
        };
        $scope.natValidation = {};
        var refExamNameGetAll = function () {
            backofficeIndividualExamService.refExamNameGetAll(sessionStorage.Key).then(function (respoonse) {
                if (respoonse.Status)
                    $scope.RefExamNameGetList = respoonse.RefExamNameGetList;
                $scope.individualEmploymentByIndividualId($scope.IndividualId);
            }, function (erorr) {
                $scope.individualEmploymentByIndividualId($scope.IndividualId);
            });
        }


        $scope.individualEmploymentByIndividualId = function (IndividualId) {
            ShowLoader("Please Wait...");
            try {
                backofficeIndividualExamService.individualExam_IndividualId(sessionStorage.Key, IndividualId)
                    .then(function (response) {

                        if ($scope.checkServerResponse(response)) {
                            if (response.lstIndividualExam != null) {
                                $scope.ExamArray = [];
                                $scope.ExamArray = response.lstIndividualExam;
                                if ($scope.RefExamNameGetList) {
                                    angular.forEach($scope.ExamArray, function (item) {
                                        angular.forEach($scope.RefExamNameGetList, function (refItem) {
                                            if (refItem.ExamNameId == item.ExamNameId) {
                                                item.ExamName = refItem.ExamName;
                                            }
                                        });
                                    });
                                }
                                processIndividualExamAndFields();
                                HideLoader();
                            }
                            else {
                                $scope.ExamArray = [];
                                HideLoader();
                            }
                        } else {
                            HideLoader();
                        }
                    }, function (data) {
                        HideLoader();
                        $scope.showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.setSaveDocumentRef = function (fn) {
            $scope.saveAllDocument = fn;
        };

        var processIndividualExamAndFields = function () {
            if ($scope.ExamArray && $scope.ExamArray.length > 0) {
                multipleFileUploadService.individualDocumentProcess(sessionStorage.Key, $scope.IndividualId,
                    $scope.ExamArray, $scope.documentExamTab.PropertyToProcess, $scope.documentExamTab.LinkType).then(function (responseData) {
                        $scope.ExamArray = responseData;
                        HideLoader();
                    });
            }
        };

        var validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.editExam = function (item) {
            $scope.Addexambutton = true;
            $scope.ExamDV = true;
            if (!item.ReferenceNumber) {
                item.ReferenceNumber = new Date().valueOf();
            }
            $scope.exam = angular.copy(item);
            $scope.isEditExam = true;
        }
        $scope.cancelExamForm = function () {
            $scope.Addexambutton = false;
            $scope.ExamDV = false;
            $scope.exam = {};
            $scope.isEditExam = false;
        }

        $scope.addNewExam = function () {
            $scope.Addexambutton = true;
            $scope.ExamDV = true;
            $scope.exam = {
                IndividualId: $scope.IndividualId,
                IsActive: true,
                IsDeleted: false,
                ReferenceNumber: new Date().valueOf()
            };
            $scope.isEditExam = false;
        }

        $scope.saveExam = function (form) {
            ShowLoader();
            if (!validateForm(form)) {
                HideLoader();
                return;
            }

            backofficeIndividualExamService.saveExamVerification([$scope.exam], sessionStorage.Key).then(function (response) {

                if (response.Status && response.StatusCode == "00") {
                    $scope.documentExamTab.LinkId = response.lstIndividualExam[0].IndividualExamId;
                    //$scope.viewConfig.showAddEducationForm = false;
                    if (typeof $scope.saveAllDocument == "function") {
                        $scope.saveAllDocument().then(function () {
                            $scope.cancelExamForm();
                            $scope.individualEmploymentByIndividualId($scope.IndividualId);
                            HideLoader();
                        }, function () { });
                    } else {
                        $scope.cancelExamForm();
                        $scope.individualEmploymentByIndividualId($scope.IndividualId);
                        HideLoader();
                    }

                }
                else {
                    HideLoader();
                }

            }, function (error) {
                HideLoader();
            });
        }

        $scope.deleteExam = function (record) {
            try {
                if (confirm(Messages.General.Confirm.DeleteRecord)) {
                    $rootScope.individualEventLog('deleteStatus.deleteConfirmed', $scope.IndividualId);
                    record.IsDeleted = true;

                    backofficeIndividualExamService.saveExamVerification([record], sessionStorage.Key).then(function (response) {

                        $scope.individualEmploymentByIndividualId($scope.IndividualId);
                    }, function (error) {
                        HideLoader();
                    });

                }
                else {
                    $rootScope.individualEventLog('deleteStatus.deleteCanceled', $scope.IndividualId);
                }
            } catch (ex) {
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.QuestionReport = function () {
            $scope.QuestionReportShow = true;
        }
        $scope.SectionReport = function () {
            $scope.SectionReportShow = true;
        }
        $scope.AverageReport = function () {
            $scope.AverageReportShow = true;
        }

        $scope.getResult = function (value) {
            if (value) {
                return (Number(value) >= 75 ? 'Pass' : 'Fail');
            }
            else {
                return '';
            }
        }

    }
})();