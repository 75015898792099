(function () {
    'use strict';

    angular
        .module('app.core')
        .controller("EmailController", EmailController);

    EmailController.$inject = ["$scope", "$q", "$window", "emailService", "utilityService"];

    function EmailController($scope, $q, $window, emailService, utilityService) {

        $scope.ckEditorOptions = {
            language: 'en',
            allowedContent: true,
            entities: false,
            disableNativeSpellChecker: false,
            scayt_autoStartup: true
        };

        $scope.init = function () {
            $scope.getIndividualContact();
        };

        var sendEmailAndText = function () {
            var dataService = emailService.emailAndText($scope.emailObj, sessionStorage.Key);

            dataService.then(function (responseDef) {
                $scope.onComplete();
            });
        };

        $scope.sendEmail = function () {
            $scope.errorMessage = '';
            if (!$scope.emailObj.Email) {
                $scope.errorMessage = "Please enter email address <br/>";
            }
            if (!$scope.emailObj.BodyContent) {
                $scope.errorMessage = $scope.errorMessage + "Please enter email body";
            }
            if ($scope.errorMessage != '') {
                return;
            }
            sendEmailAndText();
        };

        var assignContactsByContactTypeId = function (restrictCreateNewContact) {
            $scope.personalData = {};
            var emailAddressExists = false;
            angular.forEach($scope.personalContact, function (contactOption, index) {
                if (contactOption.ContactStatusId == 1) {
                    if (contactOption.ContactTypeId == "8" && !emailAddressExists) {
                        $scope.personalData.EmailAddressContact = contactOption;
                        emailAddressExists = true;
                    }
                }
            });
            $scope.emailObj = {
                EmailACopyToSender: false,
                sendEmail: true,
                IndividualId: $scope.individualId,
                CommunicationType: 0,
            };

            if (!!$scope.personalData.EmailAddressContact) {
                $scope.emailObj.Email = $scope.personalData.EmailAddressContact.ContactInfo;
            }
            $scope.getEmailBody();
        };

        $scope.getEmailBody = function () {
            var bodyContent = "";
            var dataPromises = [utilityService.getTemplateById($scope.emailTemplateId),
            emailService.individualBYIndividualId(sessionStorage.Key, $scope.individualId)
            ];

            $q.all(dataPromises).then(function (response) {
                if (!!response[0].TemplateMessage) {
                    bodyContent = response[0].TemplateMessage;
                }
                if (!!response[1] && !!response[1].IndividualResponse && response[1].IndividualResponse.length > 0) {
                    var personalInfo = response[1].IndividualResponse[0];
                    bodyContent = bodyContent.replace('#Firstname#', personalInfo.FirstName);
                    bodyContent = bodyContent.replace('#Lastname#', personalInfo.LastName);
                    bodyContent = bodyContent.replace('#FirstName#', personalInfo.FirstName);
                    bodyContent = bodyContent.replace('#LastName#', personalInfo.LastName);
                    bodyContent = bodyContent.replace('#URI#', $window.location.origin + "/" + $window.location.pathname);
                    if ($scope.tagsToReplace && $scope.tagsToReplace.length > 0) {
                        angular.forEach($scope.tagsToReplace, function (tagToReplace) {
                            bodyContent = bodyContent.replace(tagToReplace.tagName, tagToReplace.tagValue);
                        });
                    }
                }
                $scope.emailObj.BodyContent = bodyContent;
                $scope.emailObj.Subject = response[0].TemplateSubject;
                $scope.loadCkEditor = true;
                if (!!$scope.sendAutomattedEmail) {
                    sendEmailAndText();
                }
            }, function (error) {
                $scope.emailObj.BodyContent = bodyContent;
            });
        };

        $scope.closeEmailForm = function () {
            $scope.onCancel();
        };

        $scope.getIndividualContact = function () {
            emailService.individualContactBYIndividualId(sessionStorage.Key, $scope.individualId).then(function (response) {
                $scope.personalContact = response.IndividualContactResponse;
                assignContactsByContactTypeId();
            })
        };

        $scope.init();
    }
})();