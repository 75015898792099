(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeFinanceService', backofficeFinanceService)

    backofficeFinanceService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeFinanceService($http, $q, WebApiUrl, AppConfig) {

        var _individualPaidInvoiceByIndividualId = function (Key, individualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/PaidInvoiceByIndividualId/' + Key + '?IndividualId=' + individualId));
        };
        var _individualFinanceByIndividualId = function (Key, individualId, providerId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Payment/GetPaymentDetailsByIndividualId/' + Key + '?IndividualId=' + individualId + '&ProviderId=&Source=WYPEPLS'));
        };
        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };
        return {
            individualPaidInvoiceByIndividualId: _individualPaidInvoiceByIndividualId,
            individualFinanceByIndividualId: _individualFinanceByIndividualId
        }
    }
})();
