﻿angular.module('natApp').factory('BackofficeLicenseRenewalHASnA_BOService', function ($http, WebApiUrl) {
    return {
        Insert: function (key) {

            return $http.post(WebApiUrl + +key);
        },
        Delete: function (key) {

            return $http.post(WebApiUrl + +key);
        },
        Update: function (key) {

            return $http.get(WebApiUrl + +key);
        },
        GetLicenseeRenewalList: function (key) {

            return $http.get(WebApiUrl + 'Renewal/RenewalGetAll/' + key);
        },
        RenewalSearchWithPager: function (key, PageNumber, NoOfRecords, IsSearch, Name, FirstName, LastName, Email, Phone, LicenseNumber, SSN, StatusId, StatusName, IndividualId, ApplicationNumber, IsActive, SubmittedDate, IsPaid) {

            return $http.post(WebApiUrl + 'Renewal/RenewalSearchWithPager/' + key + '?PageNumber=' + PageNumber + '&NoOfRecords=' + NoOfRecords + '&IsSearch=' + IsSearch, { Name: Name, FirstName: FirstName, LastName: LastName, Email: Email, Phone: Phone, LicenseNumber: LicenseNumber, SSN: SSN, StatusId: StatusId, StatusName: StatusName, IndividualId: IndividualId, ApplicationNumber: ApplicationNumber, IsActive: IsActive, SubmittedDate: SubmittedDate, IsPaid: IsPaid });
        },
        RenewalSearch: function (key, Name, FirstName, LastName, Email, Phone, LicenseNumber, SSN, StatusId, StatusName, IndividualId, ApplicationNumber, IsActive, SubmittedDate, IsPaid) {

            return $http.post(WebApiUrl + 'Renewal/RenewalSearchWithPager/' + key, { Name: Name, FirstName: FirstName, LastName: LastName, Email: Email, Phone: Phone, LicenseNumber: LicenseNumber, SSN: SSN, StatusId: StatusId, StatusName: StatusName, IndividualId: IndividualId, ApplicationNumber: ApplicationNumber, IsActive: IsActive, SubmittedDate: SubmittedDate, IsPaid: IsPaid });
        },
        Get: function (key) {

            return $http.get(WebApiUrl + +key);
        },
        IndividualRenewalGetByApplicationId: function (key, IndividualId, ApplicationId) {
            return $http.get(WebApiUrl + 'Renewal/IndividualRenewalGetByApplicationId/' + key + '?IndividualId=' + IndividualId + '&ApplicationId=' + ApplicationId);
        },
        ConfirmAndApprove: function (key, ApplicationId, IndividualLicenseId, RequestedLicenseStatusTypeId, AffirmativeAction, Action, SentFrom) {
            return $http.post(WebApiUrl + 'Renewal/ConfirmAndApprove/' + key, {
                ApplicationId: ApplicationId,
                IndividualLicenseId: IndividualLicenseId,
                RequestedLicenseStatusTypeId: RequestedLicenseStatusTypeId,
                AffirmativeAction: AffirmativeAction,
                Action: Action,
                SentFrom: SentFrom
            });
        },
    };
});