(function () {
    'use strict';

    angular
        .module('app.lga')
        .controller("LGARequestFormController", ['$scope', 'LGARequestFormFactory', 'countryStateService', '$q', 'RegisterFactory', 'ConfigurationService', 'backofficeProviderAdressService', '$exceptionHandler', 'Messages', function ($scope, LGARequestFormFactory, countryStateService, $q, RegisterFactory, ConfigurationService, backofficeProviderAdressService, $exceptionHandler, Messages) {

            var _key = 'key';
            //Initially calls ths method
            $scope.init = function () {
                $scope.clearAddress();
                $scope.netValidator = {};
                $scope.RequestAccess = {};
                $scope.RequestAccess.StateCode = 'NV';
                if (sessionStorage.Key) {
                    _key = sessionStorage.Key;
                }
                GetStateList();
                if ($scope.isbackoffice && $scope.requestitem) {
                    GetAgencyUserInfo($scope.requestitem.EmailAddress);
                    //make the form disable
                    $scope.DisableForm = true;
                }
                //ConfigurationGetbySettings('BPPUserRegistrationCopy');
                //ConfigurationGetbySettings('EmailFrom');
            }

            //Get list of State
            var GetStateList = function () {
                countryStateService.getStateByCountryID(_key, $rootScope.DefaultCountryId).then(function (res) {
                    if (res.Status) {
                        $scope.StateList = res.State;
                    }
                });
            }

            //Validate Email by getting user from user tabe on Blur of Email Textbox
            $scope.isValideEmail = true;
            var _gaUserExists = false;
            var InvalidEmaiErrorMessage = '';
            $scope.UsersSearch = function () {
                if ($scope.RequestAccess && $scope.RequestAccess.Email) {
                    _gaUserExists = false;
                    var data = { Email: $scope.RequestAccess.Email };
                    ShowLoader();
                    LGARequestFormFactory.UsersSearch(_key, 1, 10, data).then(function (res) {
                        var userResult = res.data.Users;
                        $scope.isValideEmail = CheckUserExistence(userResult);
                        if ($scope.isValideEmail) {
                            GetAgencyUserInfo($scope.RequestAccess.Email);
                        } else {
                            HideLoader();
                        }
                        validateForm();
                    }, function () {
                        HideLoader();
                    });
                }
            };
            //Check User result whether User is valid to register or not and generate error message
            var CheckUserExistence = function (users) {
                $scope.DeniedUser = undefined;
                var isvalid = false;
                if (users != null) {
                    var msg = "";
                    if (users.length > 1) {
                        msg = "It looks like you may already have an account with Alabama board of funeral service. Please click on Forgot password if you don't remember your password. Please contact Alabama board of funeral service if you have any other question or need help with access to your account.";
                    }
                    else if (users.length == 1) {
                        if (users[0].UserTypeId == 7) {
                            var UserStatusId = users[0].UserStatusId;

                            if (UserStatusId == 1 || UserStatusId == 6) {
                                msg = "The Email Address entered is already Approved. If you have forgotten your password, please use the Reset Password link to request for temporary password.";
                            }
                            else if (UserStatusId == 4) {
                                msg = "Your request for access is under review.";
                            }
                            else if (UserStatusId == 2) {
                                msg = "Please use the Reset Password link to request for temporary password.";
                            }
                            else if (UserStatusId == 3) {
                                msg = "Please contact Alabama board of funeral service.";
                            } else if (UserStatusId == 5) {
                                //denied user can re-submit again
                                $scope.DeniedUser = users[0];
                                isvalid = true;
                                return isvalid;
                            }
                        }
                        else {
                            msg = "The User Name entered does not have access to this portal. Please contact Alabama board of funeral service if you need help with access to your account.";
                        }
                    }

                    InvalidEmaiErrorMessage = msg;
                }
                else {
                    InvalidEmaiErrorMessage = '';
                    isvalid = true;
                }
                return isvalid;
            };
            //If Email not exists in users table then check AgencyUser table data by email
            var GetAgencyUserInfo = function (email) {
                ShowLoader();
                LGARequestFormFactory.GetAgencyUserInfo(_key, email).then(function (res) {
                    if (res.data.Status && res.data.User) {
                        //Make the form pre Populated
                        _gaUserExists = true;
                        $scope.RequestAccess = res.data.User;
                        $scope.RequestAccess.Email = email;
                        $scope.RequestAccess.Phone = res.data.User.ContactNumber;
                        $scope.RequestAccess.Organization = res.data.User.GovernmentAgencyName;
                        $scope.RequestAccess.Department = res.data.User.GovernmentAgencyDeptName;
                        $scope.RequestAccess.Extension = res.data.User.GAAC_ReferenceNumber;
                        $scope.RequestAccess.PositionTitle = res.data.User.Title;
                    }
                    HideLoader();
                }, function () {
                    HideLoader();
                });
            };

            //Generate New Application
            var GenerateApplicationId = function (ApplicationID, UserID, reqData) {
                var deferred = $q.defer();
                var Urlparm = '/key?ApplicationId=' + ApplicationID + '&UserId=' + UserID;
                RegisterFactory.GenerateApplicationId(Urlparm, reqData).then(function (res) {
                    if (res.data.Status) {
                        var appID = res.data;
                        deferred.resolve(appID);
                    } else {
                        deferred.reject({});
                    }

                }, function (res) {
                    deferred.reject({});
                });
                return deferred.promise;
            };

            //On Submit Save UserDetails
            var UsersSave = function (reqData) {
                var deferred = $q.defer();
                var Urlparm = '/Key';
                RegisterFactory.ExternalUserUsersSave(Urlparm, reqData).then(function (res) {
                    if (res.data.Status) {
                        var user = res.data.Users[0];
                        deferred.resolve(user);
                    } else {
                        deferred.reject({});
                    }
                }, function (res) {
                    deferred.reject({});
                });
                return deferred.promise;
            };

            //On Submit Save UserRole
            var UserRoleSave = function (reqData) {
                var deferred = $q.defer();
                var Urlparm = '/Key';
                RegisterFactory.UserRoleSave(Urlparm, reqData).then(function (res) {
                    if (res.data.Status) {
                        deferred.resolve(res.data);
                    } else {
                        deferred.reject({});
                    }
                }, function (res) {
                    deferred.reject({});
                });
                return deferred.promise;
            };

            //Save UserChangelog
            var UserChangeLogSave = function (uid, reqData) {
                var deferred = $q.defer();
                var Urlparm = '/key?userId=' + uid;
                RegisterFactory.UserChangeLogSave(Urlparm, reqData).then(function (res) {
                    if (res.data.Status) {
                        deferred.resolve(res.data);
                    } else {
                        deferred.reject({});
                    }
                }, function (res) {
                    deferred.reject({});
                });
                return deferred.promise;
            };

            //Save ApplicationStatuschangeLog daa
            var ApplicationStatusChangeLogSave = function (userID, reqData) {
                var deferred = $q.defer();
                var Urlparm = '/key?UserId=' + userID;
                RegisterFactory.ApplicationStatusChangeLogSave(Urlparm, reqData).then(function (res) {
                    if (res.data.Status) {
                        deferred.resolve(res.data);
                    } else {
                        deferred.reject({});
                    }
                }, function (res) {
                    deferred.reject({});
                });
                return deferred.promise;
            };

            //Save ApplicationStatusLK data
            var ApplicationStatusLkSave = function (userID, reqData) {
                var deferred = $q.defer();
                var Urlparm = '/key?UserId=' + userID;
                RegisterFactory.ApplicationStatusLkSave(Urlparm, reqData).then(function (res) {
                    if (res.data.Status) {
                        deferred.resolve(res.data);
                    } else {
                        deferred.reject({});
                    }
                }, function (res) {
                    deferred.reject({});
                });
                return deferred.promise;
            }

            //Save Provider on SubmitClick
            var SaveProvider = function (ApplicationId, UserId, objData) {
                var deferred = $q.defer();
                RegisterFactory.SaveProvider(_key, ApplicationId, UserId, objData).then(function (res) {
                    if (res.data.Status) {
                        deferred.resolve(res.data);
                    } else {
                        deferred.reject({});
                    }
                }, function (res) {
                    deferred.reject({});
                });
                return deferred.promise;
            };

            //Save Provider Application
            var SaveProviderApplication = function (reqData) {
                var deferred = $q.defer();
                RegisterFactory.SaveProviderApplication(_key, reqData).then(function (res) {
                    if (res.data.Status) {
                        deferred.resolve(res.data);
                    } else {
                        deferred.reject({});
                    }
                }, function (res) {
                    deferred.reject({});
                });
                return deferred.promise;
            };

            //Save GovernmentAgency table data
            var GovernmentAgencySave = function (Action, UserId, reqData) {
                var deferred = $q.defer();
                LGARequestFormFactory.GovernmentAgencySave(_key, Action, UserId, reqData).then(function (res) {
                    if (res.data.Status) {
                        deferred.resolve(res.data);
                    } else {
                        deferred.reject({});
                    }
                }, function (res) {
                    deferred.reject({});
                });
                return deferred.promise;
            };

            //Save GovernmentAgencyDept table data
            var GovernmentAgencyDeptSave = function (Action, UserId, reqData) {
                var deferred = $q.defer();
                LGARequestFormFactory.GovernmentAgencyDeptSave(_key, Action, UserId, reqData).then(function (res) {
                    if (res.data.Status) {
                        deferred.resolve(res.data);
                    } else {
                        deferred.reject({});
                    }
                }, function (res) {
                    deferred.reject({});
                });
                return deferred.promise;
            };

            //Save GovernmentAgencyAddressContact table data
            var GovernmentAgencyAddressContactSave = function (Action, UserId, reqData) {
                var deferred = $q.defer();
                LGARequestFormFactory.GovernmentAgencyAddressContactSave(_key, Action, UserId, reqData).then(function (res) {
                    if (res.data.Status) {
                        deferred.resolve(res.data);
                    } else {
                        deferred.reject({});
                    }
                }, function (res) {
                    deferred.reject({});
                });
                return deferred.promise;
            };

            //Save GovernmentAgencyUser table data
            var GovernmentAgencyUserSave = function (reqData) {
                var deferred = $q.defer();
                LGARequestFormFactory.GovernmentAgencyUserSave(_key, reqData).then(function (res) {
                    if (res.data.Status) {
                        deferred.resolve(res.data);
                    } else {
                        deferred.reject({});
                    }
                }, function (res) {
                    deferred.reject({});
                });
                return deferred.promise;
            };

            $scope.clearContact = function () {
                $scope.contact = {};
            }

            $scope.clearAddress = function () {
                $scope.address = {};
            };

            var createContactObj = function (type, providerid) {
                $scope.contact = {};
                $scope.contact.ContactStatusId = 1;
                $scope.contact.providerId = providerid;
                $scope.contact.ContactFirstName = $scope.RequestAccess.FirstName;
                $scope.contact.ContactLastName = $scope.RequestAccess.LastName;
                $scope.contact.IsActive = true;
                $scope.contact.IsDeleted = false;
                if (type == "Email") {
                    $scope.contact.ContactInfo = $scope.RequestAccess.Email;
                    $scope.contact.ContactTypeId = 23;
                }
                if (type == "Phone") {
                    $scope.contact.ContactInfo = $scope.RequestAccess.Phone;
                    $scope.contact.ContactTypeId = 21;
                }
            }

            var createAddressObj = function () {
                $scope.address = {};
                $scope.address.StreetLine1 = $scope.RequestAccess.StreetLine1;
                $scope.address.City = $scope.RequestAccess.City;
                $scope.address.StateCode = $scope.RequestAccess.StateCode;
                $scope.address.Zip = $scope.RequestAccess.Zip;
                $scope.address.AddressStatusId = 1;
                $scope.address.AddressTypeId = 4;
                $scope.address.BeginDate = new Date();
            }

            $scope.saveAddress = function (providerid) {
                try {
                    createAddressObj();
                    $scope.address.providerId = providerid;
                    $scope.address.IsActive = true;
                    $scope.address.IsDeleted = false;
                    $scope.address.ActionFlag = 'UI';

                    backofficeProviderAdressService.addressSave($scope.address, null)
                        .then(function (response) {
                            $scope.clearAddress();
                        }, function (data) {
                            HideLoader();
                        });
                    HideLoader();
                } catch (ex) {
                    console.log('ex', ex);
                    HideLoader();
                    $exceptionHandler(ex);
                }
            };

            $scope.addressContactSave = function (providerid) {
                try {
                    var contactList = [];
                    createContactObj("Email", providerid);
                    contactList.push($scope.contact);
                    createContactObj("Phone", providerid);
                    contactList.push($scope.contact);

                    backofficeProviderAdressService.addressContactSave(contactList, null)
                        .then(function (response) {
                            $scope.clearContact();
                            HideLoader();
                        }, function (data) {
                            HideLoader();
                        });
                } catch (ex) {
                    HideLoader();
                    $exceptionHandler(ex);
                }
            };


            //On Save button click this function does all the operation Eg: Form validation and saving to all the tables
            $scope.SubmitRegistration = function (form) {
                if (!validateForm(form)) {
                    return;
                }
                var email = $scope.RequestAccess.Email;
                ShowLoader();
                //save user table data
                var userData = getRequestData('user');
                UsersSave(userData).then(function (res) {
                    //User saved Success and do next operation
                    var userId = res.UserId;
                    if (res) {
                        var ApplicationData = getRequestData('application', userId);
                        var userRoledata = [getRequestData('role', userId)];
                        var userchData = getRequestData('usercl', userId);
                        //save Application, Userrole,Userchangelog table data
                        $q.all([GenerateApplicationId(0, userId, ApplicationData), UserRoleSave(userRoledata), UserChangeLogSave(userId, userchData)]).then(function (res) {
                            var appid = res[0].ApplicationId;
                            var appNumber = res[0].ApplicationNumber;
                            var providerData = getRequestData('provider', userId, appid);
                            var appsttsclData = getRequestData('appstatuschangelog', userId, appid);
                            appsttsclData.ApplicationNumber = appNumber;
                            appsttsclData.ReferenceNumber = appNumber;
                            var statusLKData = getRequestData('appstatuslk', userId, appid);
                            //save provider, ApplicationStatusChangeLog, ApplicationStatusLk table data
                            $q.all([SaveProvider(appid, userId, providerData), ApplicationStatusChangeLogSave(userId, appsttsclData), ApplicationStatusLkSave(userId, statusLKData)]).then(function (res) {
                                var providerid = res[0].ProviderId;
                                $scope.saveAddress(providerid);
                                $scope.addressContactSave(providerid);
                                //send confirmation email to backoffice
                                //SendEmailConfirmation(providerid, appid);

                                var provAppData = getRequestData('provappdata', userId, appid, providerid);
                                //Govement Agency
                                var govAgencyData = getRequestData('gadata', userId, appid, providerid);
                                //IsEdit
                                govAgencyData.Action = 'I';
                                if ($scope.RequestAccess.GovernmentAgencyId) {
                                    govAgencyData.Action = 'U';
                                    govAgencyData.ModifiedBy = userId;
                                    govAgencyData.GovernmentAgencyId = $scope.RequestAccess.GovernmentAgencyId;
                                }

                                //Govement Agency Dept
                                var govAgencyDeptData = getRequestData('gadeptdata', userId, appid, providerid);
                                //IsEdit
                                govAgencyDeptData.Action = 'I';
                                if ($scope.RequestAccess.GovernmentAgencyDeptId) {
                                    govAgencyDeptData.ModifiedBy = userId;
                                    govAgencyDeptData.Action = 'U';
                                    govAgencyDeptData.GovernmentAgencyDeptId = $scope.RequestAccess.GovernmentAgencyDeptId;
                                }
                                //save ProviderApplication, GovementAgency
                                $q.all([SaveProviderApplication(provAppData), GovernmentAgencySave(govAgencyData.Action, userId, govAgencyData), GovernmentAgencyDeptSave(govAgencyDeptData.Action, userId, govAgencyDeptData)]).then(function (res) {
                                    var govAgencyAddContData = getRequestData('gaaddresscontactdata', userId, appid, providerid);
                                    govAgencyAddContData.GovernmentAgencyId = res[1].GovernmentAgencyId;
                                    govAgencyAddContData.GovernmentAgencyDeptId = res[2].GovernmentAgencyDeptId;
                                    govAgencyAddContData.Action = 'I';
                                    if ($scope.RequestAccess.GovernmentAgencyAddressContactId) {
                                        govAgencyAddContData.ModifiedBy = userId;
                                        govAgencyAddContData.Action = 'U';
                                        govAgencyAddContData.GovernmentAgencyAddressContactId = $scope.RequestAccess.GovernmentAgencyAddressContactId;
                                    }
                                    //"GovernmentAgencyAddressContactId": 0,
                                    //"GovernmentAgencyAddressId": governmentAgencyAddressId,
                                    //save ProviderApplication, GovementAgency
                                    $q.all([GovernmentAgencyAddressContactSave(govAgencyAddContData.Action, userId, govAgencyAddContData)]).then(function (res) {
                                        var goUserData = getRequestData('gauserdata', userId, appid, providerid);
                                        goUserData.GovernmentAgencyAddressContactId = res[0].GovernmentAgencyAddressContactId;
                                        goUserData.GovernmentAgencyId = govAgencyAddContData.GovernmentAgencyId;
                                        if (!_gaUserExists) {
                                            $q.all([GovernmentAgencyUserSave(goUserData)]).then(function (res) {
                                                resetForm();
                                                HideLoader();
                                                var sto = setTimeout(function () {
                                                    $scope.ApproveApplication(userId, email);
                                                    $scope.onsavesuccess({ item: $scope.requestitem });
                                                    clearTimeout(sto);
                                                }, 1000);
                                            }, function () {
                                                HideLoader();
                                            });
                                        } else {
                                            resetForm();
                                            HideLoader();
                                            var sto = setTimeout(function () {
                                                $scope.onsavesuccess({ item: $scope.requestitem });
                                                clearTimeout(sto);
                                            }, 1000);
                                        }
                                    }, function () {
                                        HideLoader();
                                    });

                                }, function () {
                                    HideLoader();
                                });
                            }, function () {
                                HideLoader();
                            });
                        }, function () {
                            HideLoader();
                        });
                    } else {
                        HideLoader();
                    }
                }, function () {
                    HideLoader();
                });
            };

            //Reset the for after successfully registration
            var resetForm = function () {
                $scope.registrationSuccess = "Thank you for submitting your request for access. We will notify you by email when the processing for Request Access is complete.";
                $scope.RequestAccess = {};
                $scope.reqaccessform.$submitted = false;
                $scope.reqaccessform.$setPristine();
            };

            //Prepare Data before saving it
            var getRequestData = function (type, userid, appnumber, provid) {
                switch (type) {
                    case 'user': {
                        return Object.assign({
                            UserId: ($scope.DeniedUser ? $scope.DeniedUser.UserId : 0),
                            UserTypeId: 7,
                            UserStatusId: 4,
                            Gender: "M",
                            IsPending: false,
                            IsDeleted: false,
                            IsActive: true,
                            TemporaryPassword: true,
                            CountyId: 257,
                            CountryId: $rootScope.DefaultCountryId,
                            UserFor: 'AGENCY',
                            SourceId: 1,
                            UserName: $scope.RequestAccess.Email
                        }, $scope.RequestAccess);
                        break;
                    }
                    case 'application': {
                        return {
                            CreatedBy: userid,
                            ApplicationTypeId: 5,
                            ApplicationStatusId: 5,
                            ApplicationStatusReasonId: 1,
                            ApplicationSubmitMode: "O",
                            IsFingerprintingNotRequired: true,
                            IsPaymentRequired: true,
                            CanProvisionallyHire: true,
                            GoPaperless: false,
                            LicenseTypeId: 1,
                            IsActive: true,
                            IsPaid: true
                        };
                        break;
                    }
                    case 'role': {
                        return {
                            UserID: userid,
                            IsActive: true,
                            RoleId: 4
                        };
                        break;
                    }
                    case 'usercl': {
                        return {
                            UserId: userid,
                            UserName: $scope.RequestAccess.Email,
                            UserTypeId: 7,
                            UserStatusId: 4,
                            UserChangeDateTime: new Date(),
                            UserChangedBy: userid,
                            ApplicationInternalStatusId: 0,
                            UserChangeReasonText: "",
                        };
                        break;
                    }
                    case 'provider': {
                        return {
                            ApplicationId: appnumber,
                            DepartmentId: 2,
                            ProviderTypeId: 3,
                            ProviderName: $scope.RequestAccess.Organization,
                            ProviderStatusTypeId: 1,
                            IsEnabled: true,
                            IsActive: true,
                            IsDeleted: false,
                            CreatedBy: userid
                        };
                        break;
                    }
                    case 'appstatuschangelog': {
                        return {
                            ApplicationId: appnumber,
                            //ApplicationNumber": response.ApplicationNumber,
                            ApplicationTypeId: 5,
                            ApplicationStatusId: 5,
                            ApplicationStatusReasonId: 1,
                            ApplicationSubmitMode: "O",
                            ApplicationInternalStatusId: 0,
                            ApplicationInternalStatusReasonText: '',
                            ApplicationStatusChangeDateTime: new Date(),
                            ApplicationStatusChangedBy: userid,
                            //ReferenceNumber: response.ApplicationNumber,
                            LicenseTypeId: 1,
                            HighPriority: true
                        };
                        break;
                    }
                    case 'appstatuslk': {
                        return {
                            ApplicationId: appnumber,
                            CreatedBy: userid,
                            ApplicationTypeId: 5,
                            ApplicationStatusId: 5,
                            ApplicationStatusReasonId: 1,
                            ApplicationInternalStatusId: 0,
                            ApplicationInternalStatusReasonText: '',
                            ApplicationStatusDate: new Date(),
                            HighPriority: true,
                            IsActive: true,
                            IsDeleted: false,
                            IsArchive: false
                        };
                        break;
                    }
                    case 'provappdata': {
                        return {
                            ProviderId: provid,
                            ApplicationId: appnumber,
                            CreatedBy: userid
                        };
                        break;
                    }
                    case 'gadata': {
                        return {
                            GovernmentAgencyInternalId: $scope.RequestAccess.GovernmentAgencyInternalId ? $scope.RequestAccess.GovernmentAgencyInternalId : 0,
                            GovernmentAgencyNumber: $scope.RequestAccess.GovernmentAgencyNumber ? $scope.RequestAccess.GovernmentAgencyNumber : null,
                            GovernmentAgencyName: $scope.RequestAccess.Organization,
                            ApplicationId: appnumber,
                            ProviderId: provid,
                            //GovernmentAgencyTypeId: $scope.AgencyUserInfo.GovernmentAgencyTypeId,
                            GovernmentAgencyStatusTypeId: 1,
                            BillingNumber: $scope.RequestAccess.BillingNumber ? $scope.RequestAccess.BillingNumber : null,
                            //EffectiveDate": null,
                            //EndDate": " ",
                            //ReferenceNumber": "  ",
                            IsActive: true,
                            IsDeleted: false,
                        };
                        break;
                    }
                    case 'gadeptdata': {
                        return {
                            GovernmentAgencyDeptInternalId: 0,
                            GovernmentAgencyDeptTypeId: 0,
                            GovernmentAgencyDeptName: $scope.RequestAccess.Department,
                            IsActive: true,
                            IsDeleted: false,
                        };
                        break;
                    }
                    case 'gaaddresscontactdata': {
                        return Object.assign($scope.RequestAccess, {
                            //"GovernmentAgencyAddressContactId": 0,
                            //"GovernmentAgencyId": governmentAgencyId,
                            //"GovernmentAgencyAddressId": governmentAgencyAddressId,
                            //"GovernmentAgencyDeptId": governmentAgencyDeptId,
                            Title: $scope.RequestAccess.PositionTitle,
                            EmailAddress: $scope.RequestAccess.Email,
                            ContactNumber: $scope.RequestAccess.Phone,
                            CountyId: 257,
                            CountryId: $rootScope.DefaultCountryId,
                            ReferenceNumber: $scope.RequestAccess.Extension,
                            BadAddress: false,
                            UseUserAddress: true,
                            UseVerifiedAddress: true,
                            IsActive: true,
                            IsDeleted: false,
                        });
                        break;
                    }
                    case 'gauserdata': {
                        return {
                            //"GovernmentAgencyUserId": 0,
                            //"GovernmentAgencyAddressContactId": governmentAgencyAddressContactId,
                            //"GovernmentAgencyId": governmentAgencyId,
                            "ApplicationId": appnumber,
                            "UserId": userid,
                            "IsActive": true,
                            "IsDeleted": false,
                            "CreatedBy": userid,
                            "CreatedOn": new Date(),
                        };
                        break;
                    }
                }
                return null;
            };

            //This function validates the for on click of submit button
            var validateForm = function (form, error) {
                $scope.netValidator.errorMessages = [];
                $scope.FormError = {};
                $scope.FormError.errorMessages = [];
                if (!!form) {
                    form.$submitted = true;
                    var errorMessages = angular.copy(form.errorMessages);
                    angular.forEach(errorMessages, function (errorMessage, index) {
                        $scope.FormError.errorMessages.push(errorMessage);
                    });
                }
                if (!!error) {
                    $scope.netValidator.errorMessages.push(error);
                }
                if (!$scope.isValideEmail) {
                    $scope.netValidator.errorMessages.push({ propName: 'EmailExists', errorMessage: InvalidEmaiErrorMessage });
                }
                if (!!$scope.netValidator.errorMessages && $scope.netValidator.errorMessages.length > 0 || $scope.FormError.errorMessages.length > 0) {
                    $scope.netValidator.showError = true;
                    $scope.netValidator.isValid = false;
                    return false;
                } else {
                    $scope.netValidator.showError = false;
                    $scope.netValidator.isValid = true;
                    return true;
                }
            };

            //ApproveApplication
            $scope.ApproveApplication = function (uid, email) {
                // if ($scope.requestitem) {
                // if (confirm(Messages.faebeeb)) {
                ShowLoader();
                var param = { Key: _key, UserId: uid, _StatusID: 6, EmailID: email };
                LGARequestFormFactory.SetAgencyUserStatus(param).then(function (res) {
                    HideLoader();
                    var selectedUserID = uid;
                    if (res.data.Status) {
                        var reqdata = {
                            UserId: uid,
                            UserName: email,
                            UserTypeId: 7,
                            UserStatusId: 6,
                            UserChangeDateTime: new Date(),
                            UserChangedBy: sessionStorage.UserID ? sessionStorage.UserID : uid,
                            ApplicationInternalStatusId: 0,
                            UserChangeReasonText: "",
                        };
                        UserChangeLogSave(selectedUserID, reqdata)
                        $scope.requestitem.StatusName = "Approved";
                        $scope.requestitem.UserStatusId = 6;
                        $scope.registrationSuccess = "The secure access to the Alabama board of funeral service CEPP portal has been Approved.";
                    }
                }, function (res) {

                });
                // }
                // }

            };
            //DenyApplication
            $scope.DenyApplication = function () {
                if ($scope.requestitem) {
                    if (confirm(Messages.General.Confirm.DenySecureAccess)) {
                        ShowLoader();
                        var param = { Key: _key, UserId: $scope.requestitem.UserId, _StatusID: 5, EmailID: $scope.requestitem.EmailAddress };
                        LGARequestFormFactory.SetAgencyUserStatus(param).then(function (res) {
                            HideLoader();
                            var selectedUserID = $scope.requestitem.UserId;
                            if (res.data.Status) {
                                var reqdata = {
                                    UserId: $scope.requestitem.UserId,
                                    UserName: $scope.requestitem.EmailAddress,
                                    UserTypeId: 7,
                                    UserStatusId: 5,
                                    UserChangeDateTime: new Date(),
                                    UserChangedBy: sessionStorage.UserID ? sessionStorage.UserID : $scope.requestitem.UserId,
                                    ApplicationInternalStatusId: 0,
                                    UserChangeReasonText: "",
                                };
                                UserChangeLogSave(selectedUserID, reqdata)
                                $scope.requestitem.StatusName = "Denied";
                                $scope.requestitem.UserStatusId = 5;
                                $scope.registrationError = "The secure access to the Alabama board of funeral service CEPP portal has been Denied.";
                            }
                        }, function (res) {

                        });
                    }
                }
            };

            var SendEmailConfirmation = function (providerId, applicationid) {
                var EmailCommunication = {
                    ProviderId: providerId,
                    ApplicationId: applicationid,
                    Subject: 'Business Portal Registration Request Received',
                    EmailContent: getConfEmailText(),
                    FirstName: $scope.RequestAccess.FirstName,
                    LastName: $scope.RequestAccess.LastName,
                    Organization: $scope.RequestAccess.Organization,
                    Department: $scope.RequestAccess.Department,
                    Titele: $scope.RequestAccess.PositionTitle,
                    EmailId: $scope.RequestAccess.Email
                };
                LGARequestFormFactory.SendBBPRegistrationEmail(_key, EmailCommunication).then(function (res) {
                }, function () {
                });
            };

            var getConfEmailText = function () {
                var res = '';
                res += 'Hi,<br/><br/>';
                res += 'A new Registration Request has been received to access Alabama board of Funeral Service Continuing Education Portal, please login to the portal to Approve or Deny the request.<br/><br/>';
                res += '<label style="width:200px">First Name:</label><span>' + $scope.RequestAccess.FirstName + '</span><br/>';
                res += '<label style="width:200px">Last Name:</label><span>' + $scope.RequestAccess.LastName + '</span><br/>';
                res += '<label style="width:200px">Organization:</label><span>' + $scope.RequestAccess.Organization + '</span><br/>';
                res += '<label style="width:200px">DepartMant:</label><span>' + $scope.RequestAccess.Department + '</span><br/>';
                res += '<label style="width:200px">Title:</label><span>' + $scope.RequestAccess.PositionTitle + '</span><br/><br/>';
                res += 'Alabama board of funeral service<br/>';
                res += 'http://www.fsb.alabama.gov/ <br/>';
                return res;
            };

            //Save Auditvisit information on page load
            var AuditvisitInfoSave = function () {
                var parm = 'key';
                var data = {
                    HostIPAddress: location.host,
                    PageName: 'Ingividual Registration',
                    RequestUrl: window.location.href,
                    RequestUrlReferrer: document.referrer,
                    RequestBrowserTypeVersion: 'Type: ' + navigator.appCodeName + ', Version: ' + navigator.appVersion,
                    SessionID: '',//document.cookie,
                    TimeStamp: new Date(),
                    IndividualId: 0,
                    EntityId: '',
                    UserId: 0,
                    Platform: navigator.platform,
                    MachineDeviceName: '',
                    DeviceId: '',
                    AppDomainName: '',
                    UserHostAddress: '',
                    UserHostName: '',
                    IsJavascriptEnabled: true,
                    IsCookieEnabled: navigator.cookieEnabled,
                    IsCrawler: '',
                    IsActiveXControlEnabled: ''
                };
                RegisterFactory.AuditvisitInfoSave(parm, data).then(function (res) {
                    /// auditSaved
                }, function (res) {

                });
            };
            AuditvisitInfoSave();
        }]);
})();

