(function () {
    'use strict';
    angular.module('app.backofficeAdministor')
        .controller("TemplateMessage", TemplateMessageController)
    TemplateMessageController.$inject = ['$scope', 'templateMessageFactory', 'utilityService', 'Messages'];
    function TemplateMessageController($scope, templateMessageFactory, utilityService, Messages) {
        var _key = sessionStorage.Key;
        var _userId = sessionStorage.UserID;
        $scope.init = function () {
            $scope.netValidator = {};
            $scope.TMSearch = {};
            $scope.Template = {};
            $scope.GetTemplateMessage();
            $scope.ApplicationTypeGetAll();
            $scope.AuditVisitInfoSave();
            $scope.GetAllTemplateAppliesToType();
        };

        $scope.options = {
            language: 'en',
            allowedContent: true,
            entities: false,
            disableNativeSpellChecker: false,
            scayt_autoStartup: true
        };

        // Called when the editor is completely ready.
        $scope.onReady = function () {
            // ...
        };

        $scope.TemplateTypeList = [{ key: 1, value: "Email"},{key: 2, value: "SMS"},{ key: 3, value: "Letter" }];

        var getTemplateTypeById = function (id) {
            var res='';
            if ($scope.TemplateTypeList) {
                var itemindex = $scope.TemplateTypeList.map(function (i) { return i.key; }).indexOf(id);
                if (itemindex != -1) {
                    res = $scope.TemplateTypeList[itemindex].value;
                }
            }
            return res;
        }

        $scope.ClearSaveSection = function () {
            $scope.ShowSaveSection = false;
            $scope.Template = {};
            if ($scope.frmtemplate) {
                $scope.frmtemplate.$setPristine();
            }
        };

        $scope.AddNewTemplateBtnClick = function () {
            $scope.ShowSaveSection = true;
            $scope.Template = {};
        };

        $scope.AddtemplateTag = function (tag) {
            if (!$scope.Template.TemplateMessage || $scope.Template.TemplateMessage == null) {
                $scope.Template.TemplateMessage = tag;
            } else {
                $scope.Template.TemplateMessage = $scope.Template.TemplateMessage + tag;
            }
        };

        //This function validates the for on click of submit button
        var validateForm = function (form, error) {
            $scope.netValidator.errorMessages = [];
            if (!!form) {
                form.$submitted = true;
                var errorMessages = angular.copy(form.errorMessages);
                angular.forEach(errorMessages, function (errorMessage, index) {
                    $scope.netValidator.errorMessages.push(errorMessage);
                });
            }
            if (!!error) {
                $scope.netValidator.errorMessages.push(error);
            }

            if (!!$scope.netValidator.errorMessages && $scope.netValidator.errorMessages.length > 0) {
                $scope.netValidator.showError = true;
                $scope.netValidator.isValid = false;
                return false;
            } else {
                $scope.netValidator.showError = false;
                $scope.netValidator.isValid = true;
                return true;
            }
        };

        $scope.AuditVisitInfoSave = function () {

            var data = {
                "PageName": "TemplateMessage",
                "SessionId": sessionStorage.Key,
                "IndividualId": sessionStorage.IndividualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };

        $scope.GetTemplateMessage = function (isSearch) {
            ShowLoader();
            if (isSearch && ($scope.TMSearch.ApplicationTypeId || $scope.TMSearch.TemplateName)) {
                if (!$scope.TMSearch.ApplicationTypeId) {
                    var objContent = {};
                    objContent.tempName = $scope.TMSearch.TemplateName;
                    templateMessageFactory.GetAllTemplatesGetByTemplateName(_key, objContent)
                        .then(function (data) {
                            $scope.TemplateMessageList = data;
                            PrepareListData();
                            HideLoader();
                        })
                        .catch(function (error) {
                            $scope.Error = error;
                            HideLoader();
                        });
                }
                else if (!$scope.TMSearch.TemplateName) {
                    var objContent = {};
                    objContent.applicationTy = parseInt($scope.TMSearch.ApplicationTypeId);
                    templateMessageFactory.GetAllTemplatesGetByAppTyId(_key, objContent)
                        .then(function (data) {
                            $scope.TemplateMessageList = data;
                            PrepareListData();
                            HideLoader();
                        })
                        .catch(function (error) {
                            $scope.Error = error;
                            HideLoader();
                        });
                }
                else {
                    var objContent = {};
                    objContent.tempName = $scope.TMSearch.TemplateName == undefined ? '' : $scope.TMSearch.TemplateName;
                    objContent.applicationTy = parseInt($scope.TMSearch.ApplicationTypeId);
                    templateMessageFactory.GetAllTemplatesGetByAppTyIdTemplateName(_key, objContent)
                        .then(function (data) {
                            $scope.TemplateMessageList = data;
                            PrepareListData();
                            HideLoader();
                        }).catch(function (error) {
                            $scope.Error = error;
                            HideLoader();
                        });
                }
            }
            else {
                templateMessageFactory.GetAllTemplates(_key)
                    .then(function (data) {
                        $scope.TemplateMessageList = data;
                        PrepareListData();
                        HideLoader();
                    })
                    .catch(function (error) {
                        $scope.Error = error;
                        HideLoader();
                    });
            }
        };

        var PrepareListData = function () {
            if ($scope.TemplateMessageList) {
                $scope.TemplateMessageList = $scope.TemplateMessageList.map(function (i) {
                    i.TemplateTypeName = getTemplateTypeById(i.TemplateTypeId);
                    i.ApplicationTypeName = getApplicationTypeNameById(i.ApplicationTypeId);
                    return i;
                });
            }
        };

        var getApplicationTypeNameById = function (id) {
            var res = '';
            if ($scope.ddlApplicationType) {
                var index = $scope.ddlApplicationType.map(function (i) { return i.ApplicationTypeId; }).indexOf(id);
                if (index != -1) {
                    res=$scope.ddlApplicationType[index].Name;
                }
            }
            return res;
        };

        $scope.ApplicationTypeGetAll = function () {
            ShowLoader();
            templateMessageFactory.ApplicationTypeGetAll(_key)
                .then(function (data) {
                    $scope.ddlApplicationType = data.ApplicationTypeGetList;
                    HideLoader();
                })
                .catch(function (error) {
                    $scope.Error = error;
                    HideLoader();
                });
        };

        $scope.GetAllTemplateAppliesToType = function () {
            ShowLoader();
            templateMessageFactory.GetAllTemplateAppliesToType()
                .then(function (data) {
                    $scope.TemplateAppliesToTypeList = data;
                    HideLoader();
                })
                .catch(function (error) {
                    $scope.Error = error;
                    HideLoader();
                });
        };

        $scope.SaveTemplateMessage = function (form, deleteTemplateData) {

            if (!deleteTemplateData && !validateForm(form)) {
                return;
            }
            if ($scope.Template) {
                ShowLoader();
                angular.element('#divAddAppReqPanel').hide();
                var templateData = {};
                if (deleteTemplateData) {
                    templateData = angular.copy(deleteTemplateData);
                    templateData.ModifiedBy = _userId;
                    templateData.IsDeleted = true;
                    templateData.IsActive = false;
                    templateData.IsEnabled = false;
                    templateData.ModifiedOn = new Date();
                } else {
                    templateData = angular.copy($scope.Template);
                    //add
                    if (!$scope.Template.TemplateId) {
                        templateData.IsActive = true;
                        templateData.IsEnabled = true;
                        templateData.IsEditable = true;
                        templateData.IsDeleted = false;
                        templateData.CreatedBy = _userId;
                        templateData.CreatedOn = new Date();
                    }
                        //Edit
                    else {
                        templateData.ModifiedBy = _userId;
                        templateData.ModifiedOn = new Date();
                    }
                }

                templateMessageFactory.CreateTemplate(sessionStorage.Key, templateData)
                    .then(function (response) {
                        HideLoader();
                        $scope.GetTemplateMessage();
                        $scope.ClearSaveSection();
                    })
                    .catch(function (data) {
                        alert(Messages.bcfefad);
                        HideLoader();
                    });
            }

        };

        $scope.EditTemplate = function (item) {
            ShowLoader();
            $scope.ShowSearch = false;
            $scope.ShowSaveSection = true;
            $scope.Template = angular.copy(item);
            HideLoader();
        };

        $scope.DeleteTemplate = function (item) {
            if (confirm(Messages.bbabace)) {
                ShowLoader();
                $scope.SaveTemplateMessage(undefined, item);
                HideLoader();
            }
        };

        $scope.ClearSearchTM = function () {
            if ($scope.TMSearch) {
                $scope.TMSearch = undefined;
                $scope.GetTemplateMessage();
            }
        };

        $scope.SearchTemplate = function () {
            if ($scope.TMSearch) {
                $scope.GetTemplateMessage($scope.TMSearch);
            }
        };
    }

})();
