﻿(function () {
    'use strict'
    angular.module('app.backofficeProvider')
        .directive('firmAddLicenseDirective', firmAddDirective);

    firmAddDirective.$inject = [];

    function firmAddDirective() {
        return {
            restrict: 'A',
            scope: {
                IsBackoffice: "=isBackoffice",
                providerId: "=providerid",
                EIN: "=ein",
                providerName: "=providerName",
                providerLicenseNumber: "=licenseNumber",
                currentRenewalItem: "=currentRenewalItem",
                applicationStatusList: "=applicationStatusList",
                isUserSessionActive: "&",
                closeApplication: "&",
                isamc: "=isamc"
            },
            templateUrl: "app/components/individual/license/renewal/firm-renewal-application.html",
            controller: "firmRenewalApplicationController",
            link: function (scope, element, attrs, ngModel) {
            }
        }
    }
})();
