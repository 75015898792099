(function () {
    'use strict'

    angular.module('app.enforcement')
        .factory('ComplaintActionsDBFormService', [ComplaintActionsDBFormService])
        .directive('complaintActionsLogic', ['ComplaintActionsExtService', '$q', '$timeout', 'ComplaintActionsDBFormService', ComplaintActionsLogic]);

    function ComplaintActionsDBFormService() {
        var _generateFormFieldsConfig = function ($scope) {
            $scope.current.actions = {};

            $scope.actions.forEach(function (action) {
                $scope.current.actions['actionType{0}'.format(action.ComplaintActionTypeId)] = angular.copy(action);
            });

            //Now generate the form
            $scope.uiConfig.InputForm = {};
            var inputForm = $scope.uiConfig.InputForm;
            inputForm.name = 'complaintActionsForm';
            inputForm.modelObj = '';
            inputForm.rows = [];

            $scope.refTables.actionTypes.forEach(function (actionType) {
                var actionTypeModelName = 'actionType{0}'.format(actionType.ComplaintActionTypeId);
                var form = $scope.refTables.forms.whereEquals(actionType.ComplaintFormId, 'ComplaintFormId').firstOrDefault();
                var formFields = $scope.refTables.formFields.whereEquals(actionType.ComplaintFormId, 'ComplaintFormId').orderBy('SortOrder');
                var actionObjRef = 'current.actions.actionType{0}'.format(actionType.ComplaintActionTypeId);
                var objRefReg = /{OBJ_REF}/gi;

                if (!$scope.current.actions[actionTypeModelName]) {
                    //Generate a new entry for it
                    var newAction = $scope.getNewAction();

                    newAction.ComplaintActionTypeId = actionType.ComplaintActionTypeId;

                    $scope.current.actions[actionTypeModelName] = newAction;
                }

                //For now only one row per form, we'll figure it out later.
                var row = {
                    columns: []
                };
                inputForm.rows.push(row);

                var cols = row.columns;
                formFields.forEach(function (formField) {
                    var newCol = {};

                    newCol.name = '{0}{1}'.format(formField.ComplaintFormFieldName, actionType.ComplaintActionTypeId);
                    if (!!formField.UseLinkedItemLabel) {
                        newCol.label = actionType.ComplaintActionTypeName;
                    } else {
                        newCol.label = formField.ComplaintFormFieldLabel;
                    }
                    newCol.bindingField = '{0}.{1}'.format(actionObjRef, formField.BindingField);
                    newCol.type = formField.Type;
                    newCol.isRequired = (!!formField.IsRequired) ? formField.IsRequired.replace(objRefReg, actionObjRef) : null;
                    newCol.isDisabled = (!!formField.IsDisabled) ? formField.IsDisabled.replace(objRefReg, actionObjRef) : null;
                    newCol.onChange = (!!formField.OnChange) ? formField.OnChange.replace(objRefReg, actionObjRef) : null;
                    newCol.placeholder = formField.Placeholder;
                    newCol.init = (!!formField.InitField) ? formField.InitField.replace(objRefReg, actionObjRef) : null;
                    newCol.show = (!!formField.ShowField) ? formField.ShowField.replace(objRefReg, actionObjRef) : null;
                    newCol.condition = (!!formField.FieldCondition) ? formField.FieldCondition.replace(objRefReg, actionObjRef) : null;
                    newCol.sizes = {
                        label: formField.LabelSize,
                        control: formField.ControlSize
                    };
                    newCol.addlAttrs = {
                        label: formField.LabelAddlAttrs,
                        control: formField.ControlAddlAttrs
                    }

                    switch (newCol.type) {
                        case 'dropdownRef':
                        case 'dropdown':
                            var listName = formField.DRDListName;
                            if (!listName && !!formField.DRDTableKey) {
                                listName = '{0}{1}List'.format(formField.DRDTableKey, actionType.ComplaintActionTypeId);
                            } else if (!formField.ValueList) {
                                throw 'Must supply list name, table key, or static list for dropdown';
                            }
                            var array = formField.DRDArray;
                            if (!array) {
                                array = listName;
                            }
                            newCol.dropdownConfig = {
                                tableKey: formField.DRDTableKey,
                                dataList: formField.DRDDataList,
                                defaultOption: formField.DRDDefaultOption,
                                overrideNames: formField.DRDOverrideNames,
                                valueFieldName: formField.DRDValueFieldName,
                                textFieldName: formField.DRDTextFieldName,
                                listName: listName,
                                array: array,
                                orderBy: formField.DRDOrderBy,
                                onLoaded: (!!formField.DRDOnLoaded) ? formField.DRDOnLoaded.replace(objRefReg, actionObjRef) : null
                            };

                            if (!!formField.ValueList) {
                                var entries = formField.ValueList.split(';');
                                if (!!entries) {
                                    newCol.dropdownConfig.staticList = [];
                                    entries.forEach(function (entry) {
                                        var text = entry.split(':')[0];
                                        var value = entry.split(':')[1];
                                        var newEntry = {
                                            text: text
                                        };

                                        if (!!Number(value)) {
                                            newEntry.value = Number(value);
                                        } else if (value.toLowerCase() == 'true') {
                                            newEntry.value = true;
                                        } else if (value.toLowerCase() == 'false') {
                                            newEntry.value = false;
                                        } else {
                                            newEntry.value = value;
                                        }

                                        newCol.dropdownConfig.staticList.push(newEntry);
                                    });
                                }
                            }
                            break;
                        case 'date':
                            newCol.datePicker = {
                                appendToBody: formField.DPAppendToBody,
                                format: formField.DPFormat
                            };
                            break;
                        default:
                            break;
                    }

                    cols.push(newCol);
                });
            });
        };

        return {
            generateFormFieldsConfig: _generateFormFieldsConfig
        };
    }

    function ComplaintActionsLogic(ComplaintActionsExtService, $q, $timeout, ComplaintActionsDBFormService) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.$watch('current.action', function (action) {
                    if (!!action && !!$scope.actionList) {
                        action.typeObj = $scope.actionList.whereEquals(action.ComplaintActionTypeId, 'ComplaintActionTypeId').firstOrDefault();
                    }
                    $scope.subActionList = [];
                });

                $scope.openItemInViewMode = function (action) {
                    $scope.uiLogic.formReadOnly = true;
                };
                $scope.openItemInEditMode = function (action) {
                    $scope.uiLogic.formReadOnly = false;
                };

                $scope.actionTypesLoaded = function (action, actionList) {
                    if (!!action) {
                        action.typeObj = actionList.whereEquals(action.ComplaintActionTypeId, 'ComplaintActionTypeId').firstOrDefault();
                    }
                };
                $scope.actionTypeChanged = function (action) {
                    if (!!action && !!action.typeObj) {
                        action.ComplaintActionTypeId = action.typeObj.ComplaintActionTypeId;

                        if (action.typeObj.ComplaintActionTypeCode != 'O') {
                            action.Activity = action.typeObj.ComplaintActionTypeName;
                        } else {
                            action.Activity = '';
                        }
                    }
                };

                $scope.heardByChanged = function (action) {
                    var heardBy = [];

                    action.Activity = '';

                    if (!!action.heardByBoard) {
                        heardBy.push('Board');
                    }
                    if (!!action.heardByALJ) {
                        heardBy.push('ALJ');
                    }

                    action.Activity = heardBy.join(',');

                    console.log(action.Activity);
                };

                $scope.getComplaintInvestigators = function () {
                    var deferred = $q.defer();

                    try {
                        ComplaintActionsExtService.getUsersByRole(sessionStorage.Key, null, 'Complaint Investigator')
                            .then(function (data) {
                                if (data.Status) {
                                    $scope.investigatorList = data.UsersInRole;
                                }
                                deferred.resolve(data);
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.getDisciplineAnalysts = function () {
                    var deferred = $q.defer();

                    try {
                        ComplaintActionsExtService.getUsersByRole(sessionStorage.Key, null, 'Discipline Analyst')
                            .then(function (data) {
                                if (data.Status) {
                                    $scope.investigatorList = data.UsersInRole;
                                }
                                deferred.resolve(data);
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.filterFormActions = function () {
                    if (!!$scope.uiConfig) {
                        $scope.refTables.originalActionTypes = angular.copy($scope.refTables.actionTypes);
                        $scope.refTables.actionTypes = $scope.refTables.originalActionTypes.where(function (action) {
                            var groups = (!!action.GroupNames) ? action.GroupNames.split(',') : [];
                            var actionGroupName = (!!$scope.uiConfig.ActionGroupName) ? $scope.uiConfig.ActionGroupName : '';
                            var itemTypeFilter = (!!$scope.uiConfig.ItemTypeFilter) ? $scope.uiConfig.ItemTypeFilter : [];

                            return (itemTypeFilter.length == 0 || itemTypeFilter.includes(action.ComplaintActionTypeId))
                                && (groups.length == 0 || groups.includes(actionGroupName));
                        });
                    }
                };

                $scope.spliceRefTable = function (refTable, condition, thisValue) {
                    refTable.conditionalSplice(condition, thisValue);
                };

                $scope.copyValueFromRefTable = function (refTable, primaryKeyName, sourceProperty, sourceKeyValue, destObj, destinationProperty) {
                    var refTableEntry = refTable.whereEquals(sourceKeyValue, primaryKeyName).firstOrDefault();
                    var valueToCopy = refTableEntry[sourceProperty];

                    destObj[destinationProperty] = valueToCopy;
                };

                $scope.refreshForm = function () {
                    if (!!$scope.refTables.actionTypes && !$scope.uiLogic.refreshingForm) {
                        $scope.uiLogic.refreshingForm = true;

                        $timeout(function () {
                            ComplaintActionsDBFormService.generateFormFieldsConfig($scope);

                            $scope.uiLogic.refreshingForm = false;
                        });
                    }
                };

                $scope.saveDBFormActionsClicked = function (form) {
                    var actionList = [];

                    //Convert the actions object to an array
                    for (var prop in $scope.current.actions) {
                        if (!$scope.current.actions.hasOwnProperty(prop)) { continue; }

                        actionList.push($scope.current.actions[prop]);
                    }

                    //Save the form
                    $scope.hideStatusMsg();
                    if (!$scope.validateForm(form)) { return; }

                    $scope.operationPending(true, 'Saving...');

                    $scope.saveActions(actionList)
                        .then(function () {
                            $scope.getActions()
                                .then(function () {
                                    $scope.operationPending(false);
                                }, function () {
                                    $scope.operationPending(false);
                                });
                        }, function () {
                            $scope.operationPending(false);
                        });
                };

                var stopWatchingFormsAndActions = $scope.$watch(function () { return !!$scope.uiLogic.formsLoaded && !!$scope.uiLogic.actionsLoaded; }, function (newVal) {
                    if (!!newVal) {
                        $scope.refreshForm();

                        stopWatchingFormsAndActions();
                    }
                });

                $scope.getConfigExt = function () {
                    if (!!$scope.uiConfig.UseDBForm) {
                        //Overwrite the input form
                        $scope.uiConfig.InputForm = null;
                    }
                };

                $scope.saveActionExt = function (action) {
                    if ($scope.configName == 'DecisionInfo') {
                        $scope.$broadcast('SaveComplaintQuestionnaire', {
                            complaint: $scope.complaint,
                            linkId: action.ComplaintActionId,
                            linkType: 'CA'
                        });
                    }
                };

                $scope.initExt = function () {
                    $scope.getComplaintInvestigators();
                };

                $scope.getActionsExt = function () {
                    $scope.uiLogic.actionsLoaded = true;

                    $scope.refreshForm();

                    if (!!$scope.actions && $scope.actions.length > 0) {
                        $scope.actions.forEach(function (action) {
                            if (!!action.Activity && action.Activity.includes('Board')) {
                                action.heardByBoard = true;
                            }
                            if (!!action.Activity && action.Activity.includes('ALJ')) {
                                action.heardByALJ = true;
                            }

                            if (!!action.TimeSpent) {
                                action.TimeSpentHours = Math.floor(action.TimeSpent / 60);
                                action.TimeSpentMinutes = action.TimeSpent % 60;
                            }
                        });
                    }
                };

                $scope.timeSpentChanged = function (action) {
                    if (!!action && (!!action.TimeSpentHours || !!action.TimeSpentMinutes)) {
                        action.TimeSpent = ((!!action.TimeSpentHours) ? action.TimeSpentHours * 60 : 0) + ((!!action.TimeSpentMinutes) ? action.TimeSpentMinutes : 0);
                    } else if (!!action) {
                        action.TimeSpent = 0;
                    }
                };

                $scope.filterComplaintCodeViolationByActionTypeId = function (list, actionTypeId) {
                    if (!!list) {
                        $scope.current.action.ComplaintCodeViolationId = null;
                        if (!$scope.complaintCodeViolationCompleteList) {
                            $scope.complaintCodeViolationCompleteList = [];
                            angular.copy(list, $scope.complaintCodeViolationCompleteList);
                        }
                        angular.copy($scope.complaintCodeViolationCompleteList, list)

                        list.conditionalSplice('item.ComplaintActionTypeId != this', actionTypeId);
                    }
                };

                $scope.filterComplaintActionSubType = function (list, actionTypeId) {
                    $scope.current.action.ComplaintActionSubTypeId = null;
                    if (!!list && !!actionTypeId) {
                        $scope.subActionList = list.where('item.ComplaintActionTypeId == this', actionTypeId);
                    } else {
                        $scope.subActionList = [];
                    }
                };
            },
        };
    }
})();