(function () {
    'use strict'

    angular.module('app.enforcement')
    .directive('complaintInvolvedPartyValidation', [ComplaintInvolvedPartyValidation]);

    function ComplaintInvolvedPartyValidation() {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.setForm = function (form) {
                    $scope.form = form;
                };

                $scope.validateForm = function () {
                    var valid = true;
                    
                    $scope.hideStatusMsg();
                    if (!!$scope.form && $scope.form.errorMessages.length > 0) {
                        $scope.showStatusMsg('-', $scope.form.errorMessages);
                        valid = false;
                    }
                    
                    return valid;
                };
            },
        };
    }
})();