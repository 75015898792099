(function () {
    'use strict'

    angular.module('app.backofficeEntity')
        .factory('backofficeCeCourseService', backofficeCeCourseService)

    backofficeCeCourseService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeCeCourseService($http, $q, WebApiUrl, AppConfig) {

        return {
            ceCourseGetByEntityId: _ceCourseGetByEntityId,
            ceCourseSave: _ceCourseSave,
            providerCECourseByProviderId: _providerCECourseByProviderId
        };

        function returnDefferedResult(request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        function _ceCourseGetByEntityId(Key, entityId, entityType) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualCECourseByIndividualId/' + Key + '?IndividualId=' + entityId + '&EntityType=' + entityType));
        };

        function _ceCourseSave(ceCourse, key) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualCECourseSave/' + key + "?Source=" + AppConfig.Source, ceCourse));
        };

        function _providerCECourseByProviderId (providerId, providerName, key, applicationId, onlyLatestRecord) {
            return returnDefferedResult($http.get(WebApiUrl + 'Provider/GetProviderCourseByProviderId/' + key + '?ProviderId=' + providerId + '&ProviderName=' + providerName + ((!!applicationId ? ('&ApplicationId=' + applicationId) : '')) + ((!!onlyLatestRecord ? ('&OnlyLatestRecord=' + onlyLatestRecord) : ''))));
        };
    }
})();