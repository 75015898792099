(function () {
    'use strict'

    angular.module('app.InitialApplication')
        .factory('initialApplicationCPCEducationService', initialApplicationCPCEducationService)

    initialApplicationCPCEducationService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function initialApplicationCPCEducationService($http, $q, WebApiUrl, AppConfig) {
        var cpcEducationService = {
            individualCEHSave: individualCEHSave,
            saveIndividualLicenseStatusSubTypeReason: saveIndividualLicenseStatusSubTypeReason,
            saveIndividualLicenseReciprocity: saveIndividualLicenseReciprocity,
            educationSave: educationSave,
            getCEEducation: getCEEducation,
            individualCEHSSave: _individualCEHSSave,
            getIndividualCEHBYIndividualId: _getIndividualCEHBYIndividualId
        }

        function individualCEHSave(cehItemsToSave, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualCEHSave/' + key + '?Source=' + AppConfig.Source, cehItemsToSave)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function saveIndividualLicenseStatusSubTypeReason(licenseStatusSubTypeReasons, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/SaveIndividualLicenseStatusSubTypeReason/' + key + '?Source=' + AppConfig.Source, licenseStatusSubTypeReasons)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function saveIndividualLicenseReciprocity(licenseReciprocities, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/SaveIndividualLicenseReciprocity/' + key + '?Source=' + AppConfig.Source, licenseReciprocities)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function educationSave(objCECourse, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualCECourseSave/' + key, objCECourse)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function getCEEducation(individualId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualCECourseByIndividualId/' + key +
                '?IndividualId=' + individualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function _getIndividualCEHBYIndividualId(individualId, ApplicationId, key) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualCEHBYIndividualId/' + key +
                '?IndividualId=' + individualId + '&ApplicationId=' + ApplicationId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        function _individualCEHSSave(objCECourse, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualCEHSave/' + key, objCECourse)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        return cpcEducationService;
    }
})();