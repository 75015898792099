(function() {
    'use strict'

    angular.module('app.core')
    .directive('documentUpload', [DocumentUploadDirective]);

    function DocumentUploadDirective() {
        return {
            restrict: 'E',
            scope: {
                title: '@',
                options: '=',
                getUploadRequest: '=',
                getExampleDocumentResponse: '=?',
                getUploadDocumentResponse: '=?',
                provideMethods: '&?'
            },
            controller: 'DocumentUploadController',
            templateUrl: 'app/core/directive/document-upload/document-upload.html',
            link: function(scope, element, attrs) {
                
            }
        };
    }
})();