(function () {
    'use strict';
    angular.module('app.core')
        .controller('HeaderBackOfficeController', HeaderBackOfficeController)
    HeaderBackOfficeController.$inject = ['$scope', '$controller', '$location'];

    function HeaderBackOfficeController($scope, $controller, $location) {
        $controller('GlobalController', {
            $scope: $scope
        });
        if (sessionStorage.menuName != undefined && sessionStorage.menuName != "" && sessionStorage.menuName != null) {
            $scope.menuName = sessionStorage.menuName;
        } else {
            $scope.menuName = "Home";
        }
        if (sessionStorage.MenuList != undefined) {
            $scope.MenuList = JSON.parse(sessionStorage.MenuList);
        }

        $scope.init = function () {
            $scope.getAllConfiguration();
        }

        $scope.changeMenuClick = function (menuName, redirectPath) {
            sessionStorage.menuName = menuName;
            $scope.menuName = sessionStorage.menuName;
            if (redirectPath) {
                $location.path(redirectPath);
            }
        }
    }
})();