(function () {
    'use strict'
    angular.module('app.core')
        .directive('multipleFileUploadList', multipleFileUploadDirective);

    multipleFileUploadDirective.$inject = [];

    function multipleFileUploadDirective() {
        return {
            restrict: 'E',
            scope: {
                documentsList: '=?documentsList',
                individualDocumentUpload: '=?individualDocumentUpload',
                documentNameCaption: '=?documentNameCaption',
                hideDocumentName: "=?hideDocumentName",
                hideDocumentType: "=?hideDocumentType",
                processLoader: "=?processLoader",
                showSorting: "=?showSorting",
                showFilter: "=?showFilter",
                referenceNumber: "=?referenceNumber",
                disableForm: "=disableForm",
                isEmailSend: "=isEmailSend",
                listingTemplateUrl: "=?"
            },
            templateUrl: "app/core/directive/multiple-file-upload-list/multiple-file-upload-list.html",
            controller: "MultipleFileUploadListController"
        }
    }
})();