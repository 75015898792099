﻿(function () {
    'use strict';
    angular.module('natApp').config(natAppConfig);

    natAppConfig.$inject = ['$stateProvider', '$urlRouterProvider', '$urlMatcherFactoryProvider'];

    function natAppConfig($stateProvider, $urlRouterProvider, $urlMatcherFactoryProvider) {
        $urlMatcherFactoryProvider.caseInsensitive(true);
        $urlRouterProvider.otherwise('/');

        $stateProvider.state('app', {
            abstract: true,
            views: {
                'main@': {
                    templateUrl: 'app/Core/layout/base-layout/backoffice-layout.html',
                    controller: 'MainController'
                },
                'header@app': {
                    templateUrl: "app/core/layout/header/header-backoffice/header-backoffice.html",
                    controller: 'HeaderBackOfficeController'
                }
            }
        });
    };
})();