(function () {
    'use strict'

    angular.module('reporting.QueryReporting')
    .controller('QueryReportingJoinConditionsController', ['$scope', '$uibModalInstance', 'parameters', QueryReportingJoinConditionsController]);

    function QueryReportingJoinConditionsController($scope, $uibModalInstance, parameters) {
        $scope.init = function() {
            $scope.currentQuery = parameters.currentQuery;
            $scope.relationship = angular.copy(parameters.relation);

            $scope.removeWatch = $scope.$watch(function() { return $scope.relationship.JoinConditionBlock.Conditions.length; }, function(newVal, oldVal) {
                if($scope.relationship.IsCustomCondition) {
                } else if (newVal != oldVal && newVal != 1 && $scope.relationship.JoinConditionBlock.isDefault) {
                    $scope.relationship.IsCustomCondition = true;
                    delete $scope.relationship.JoinConditionBlock.isDefault;
                }
            });
        };

        $scope.customConditionChanged = function () {
            if ($scope.relationship.IsCustomCondition) {
                $scope.relationship.JoinConditionBlock = { Conditions: [] };
            } else {
                parameters.setDefaultConditionBlock($scope.relationship);
            }
        };

        $scope.saveClicked = function() {
            $scope.removeWatch();
            $uibModalInstance.close({ joinConditionBlock : $scope.relationship.JoinConditionBlock, isCustom: $scope.relationship.IsCustomCondition })
        };

        $scope.cancelClicked = function() {
            $scope.removeWatch();
            $uibModalInstance.dismiss();
        };
    }
})();