(function () {
    'use strict'
    angular.module('app.core')
        .directive('checkWarning', checkWarningDirective);
    checkWarningDirective.$inject = ['$q', 'warningService', 'licenseRenewalService', 'feePaymentService', '$rootScope'];

    function checkWarningDirective($q, warningService, licenseRenewalService, feePaymentService, $rootScope) {
        return {
            restrict: 'A',
            scope: {
                entityId: '=',
                entityType: "=",
                applicationId: "=",
                applicationTypeId: "=",
                backgroundCheckStatusId: "=",
                disableOnWarning: "=",
                disableOnBackground: "=",
                disableOnBackgroundApproval: "=",
                disableOnPayment: "=",
                disableOnExam: "=",
                paymentModule: "=",
            },
            link: function ($scope, $element, $attrs) {
                var dataPromises = {};

                if ($scope.disableOnWarning) {
                    dataPromises.warning = warningService.warningExists(sessionStorage.Key, $scope.entityId, $scope.entityType, $scope.applicationTypeId);
                }

                if ($scope.disableOnBackground) {
                    dataPromises.background = licenseRenewalService.getIndividualbackgroundcheck($scope.entityId, $scope.applicationId, $scope.applicationTypeId, sessionStorage.Key);
                }

                if ($scope.disableOnPayment) {
                    var reqParams = {
                        IndividualId: $scope.entityId,
                        ApplicationId: $scope.applicationId
                    };
                    if(!$scope.entityId)
                    {
                        dataPromises.invoiceMaster = warningService.getApplicationPaymentInvoiceStatusProvider(sessionStorage.Key, reqParams);
                    }
                    else
                    {
                        dataPromises.invoiceMaster = warningService.getApplicationPaymentInvoiceStatus(sessionStorage.Key, reqParams);
                    }
                }

                if ($scope.disableOnExam) {
                    var reqParams = {
                        ApplicationId: $scope.applicationId
                    };
                    dataPromises.entityExam = warningService.getApplicationExamStatus(sessionStorage.Key, reqParams);
                }

                $q.all(dataPromises).then(function (response) {
                    var warningExists = false;
                    var backgroundClear = false;
                    var backgroundClear = false;
                    var paymentClear = true;
                    var examClear = false;
                    if ($scope.disableOnWarning) {
                        warningExists = !!response.warning.warningExists
                    }
                    if ($rootScope.isRetiredOrDelinquent) {
                        var paymentClear = true;
                    }
                    if ($scope.disableOnBackground) {
                        var individualbackgroundcheck = null;
                        if (response.background.IndividualbackgroundcheckList && response.background.IndividualbackgroundcheckList.length > 0) {
                            individualbackgroundcheck = response.background.IndividualbackgroundcheckList[0];
                        }
                        if (!!individualbackgroundcheck) {
                            var backgroundStatus = warningService.getBackgroundStatus(individualbackgroundcheck.DOJFingerPrintStatus, individualbackgroundcheck.DOJApprovalStatus,
                                individualbackgroundcheck.FBIFingerPrintStatus, individualbackgroundcheck.FBIApprovalStatus);
                            backgroundClear = (backgroundStatus == "MatchedPass");
                        }
                    }
                    if ($scope.disableOnPayment) {
                        if (response.invoiceMaster.ApplicationPaymentStatus && response.invoiceMaster.ApplicationPaymentStatus.IsPaymentProcessed) {
                            paymentClear = true;
                        }
                    }
                    if ($scope.disableOnExam) {
                        if (response.entityExam.ResponseReason && response.entityExam.ResponseReason == "Passed") {
                            examClear = true;
                        }
                    }
                    if(!$scope.entityId)
                    {
                        var paymentClear = true;
                    }
                    if (($scope.disableOnWarning && !!warningExists) || ($scope.disableOnBackground && !backgroundClear)
                        || ($scope.disableOnBackgroundApproval && $scope.backgroundCheckStatusId == 1)
                        || ($scope.disableOnPayment && !paymentClear) || ($scope.disableOnExam && !examClear)) {
                        $element.find('input').each(function (index, innerElement) {
                            angular.element(innerElement).addClass('section-disabled');
                            angular.element(innerElement).attr('disabled', 'disabled');
                        });
                        var adminHoldElement = document.createElement("span");
                        var innerHTML = "";
                        if ($scope.disableOnWarning && !!warningExists) {
                            innerHTML = "Admin Hold";
                        }
                        if ($scope.disableOnBackground && !backgroundClear) {
                            innerHTML = innerHTML + ((!!innerHTML) ? ", " : "") + "Background Check";
                        }
                        if ($scope.disableOnBackgroundApproval && $scope.backgroundCheckStatusId == 1) {
                            innerHTML = innerHTML + ((!!innerHTML) ? ", " : "") + "FingerprintQ Not Approved";
                        }
                        if ($scope.disableOnPayment && !paymentClear) {
                            if (!$rootScope.isRetiredOrDelinquent) {
                                innerHTML = innerHTML + ((!!innerHTML) ? ", " : "") + "Payment Not Clear";
                            }
                        }
                        if ($scope.disableOnExam && !examClear) {
                            innerHTML = innerHTML + ((!!innerHTML) ? ", " : "") + "Exam Not Clear";
                        }

                        adminHoldElement.innerHTML = innerHTML;
                        adminHoldElement.style.color = 'Red';
                        adminHoldElement.style.fontWeight = 'bold';
                        adminHoldElement.style.fontSize = '16px';
                        adminHoldElement.style.marginRight = '10px';
                        adminHoldElement.style.lineHeight = '30px';
                        adminHoldElement.style.float = 'left';
                        $element.prepend(adminHoldElement);
                    }
                })
            }
        }
    }
})();