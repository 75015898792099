(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeIndividualPersonalService', backofficeIndividualPersonalService)

    backofficeIndividualPersonalService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeIndividualPersonalService($http, $q, WebApiUrl, AppConfig) {
        var licenseStatusTypesData = null;
        var suffixTypesData = null;
        var countriesData = null;

        var _getComplaintAssociatedLicense = function (Key, EntityId, EntityType) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Complaint/GetComplaintAssociatedLicense/' + Key + '?EntityId=' + EntityId + '&EntityType=' + EntityType + '&Source=' + AppConfig.Source)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        var _individualBYIndividualId = function (Key, IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Individual/IndividualBYIndividualId/' + Key + '?IndividualId=' + IndividualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        }
        var _individualDocumentReprintToPDFandMail = function (data, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualDocumentReprintToPDFandMail/' + key + '?Source=' + AppConfig.Source, data)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        }
        // var _licenseStatusTypeGetAll = function (key) {
        //   var deferred = $q.defer();
        //   if (licenseStatusTypesData) {
        //     deferred.resolve(licenseStatusTypesData);
        //   }
        //   else {
        //     $http.get(WebApiUrl + 'TypeValues/LicenseStatusTypeGetAll/' + key)
        //       .then(function(data){ data = data.data;
        //         licenseStatusTypesData = data;
        //         deferred.resolve(data);
        //       }, function(error) { error = error.data;
        //         deferred.reject(error);
        //       });
        //   }
        //   return deferred.promise;
        // };

        var _individualSave = function (person, key) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Individual/IndividualSave/' + key + '?Source=' + AppConfig.Source, person)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        var _individualNameSave = function (individualName, key, source) {
            return returnDefferedResult($http.post(WebApiUrl + 'Individual/IndividualNameSave/' + key + "?Source=" + (!!source ? source : AppConfig.Source), individualName));
        };


        return {
            individualBYIndividualId: _individualBYIndividualId,
            individualSave: _individualSave,
            individualDocumentReprintToPDFandMail: _individualDocumentReprintToPDFandMail,
            IndividualUnPaidInvoiceByIndividualId: function (Key, IndividualId) {
                return $http.get(WebApiUrl + 'Individual/UnpaidInvoiceByIndividualId/' + Key + '?IndividualId=' + IndividualId);
            },
            getComplaintAssociatedLicense: _getComplaintAssociatedLicense,
            individualNameSave: _individualNameSave
        }

    }
}());