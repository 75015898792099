angular.module('natApp').factory('IndividualDocumentSaveService', ['$http', 'WebApiUrl', function ($http, WebApiUrl) {
    return {
        IndividualDocumentSave: function (key, IndividualId, ApplicationId, Email, SendEmail, TransactionId, AffirmativeAction, DocumentUploadList) {
            return $http.post(WebApiUrl + 'Individual/IndividualDocumentSaveByHTML/' + key,
                {
                    IndividualId: IndividualId,
                    ApplicationId: ApplicationId,
                    Email: Email,
                    SendEmail: SendEmail,
                    TransactionId: TransactionId,
                    AffirmativeAction: AffirmativeAction,
                    DocumentUploadList: DocumentUploadList
                })
        },
        IndividualSendEmail: function (key, IndividualDocumentIdLicense, IndividualDocumentIdReceipt, LicenseDocName, ReceiptDocName, IndividualId, ApplicationId, Email, AffirmativeAction) {
            return $http.post(WebApiUrl + 'Individual/SendIndividualDocumentByEmail/' + key,
                {
                    IndividualDocumentIdLicense: IndividualDocumentIdLicense,
                    IndividualDocumentIdReceipt: IndividualDocumentIdReceipt,
                    LicenseDocName: LicenseDocName,
                    ReceiptDocName: ReceiptDocName,
                    IndividualId: IndividualId,
                    ApplicationId: ApplicationId,
                    Email: Email,
                    AffirmativeAction: AffirmativeAction
                })
        },
        PdfDocumentByIndividualDocumentId: function (key, IndividualDocumentId) {
            return $http.get(WebApiUrl + 'Individual/DocumentByIndividualDocumentId/' + key + '?IndividualDocumentId=' + IndividualDocumentId, {
                responseType: 'arraybuffer'
            })
        },

        IndividualDocumentGet: function (key, IndividualId, ApplicationId) {
            return $http.get(WebApiUrl + 'Individual/IndividualDocumentGetByIndividualIdAndApplicationId/' + key + '?IndividualId=' + IndividualId + '&ApplicationId=' + ApplicationId)
        },
    };
}]);