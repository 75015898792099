(function () {
    'use strict'

    var appStatusProfileCode = 'APPSTATUS';
    var hasAppStatusProfile = false;

    angular.module('app.core')
        .run(['$q', '$rootScope', 'ProfileLoaderService', ApplicationStatusPickerInit])
        .controller('ApplicationStatusPickerController', ['$scope', '$rootScope', '$state', '$q', 'ApplicationStatusPickerService', 'providerJursidictionsService', 'utilityService', 'typeValuesService', "dcaIService", 'applicationListingService' ,  ApplicationStatusPickerController]);

    function ApplicationStatusPickerInit($q, $rootScope, ProfileLoaderService) {

        var getUserProfileList = function () {
            var deferred = $q.defer();

            try {
                ProfileLoaderService.getUserProfiles(sessionStorage.Key, sessionStorage.UserID)
                    .then(function (data) {
                        if (!!data.Status) {
                            var userHasProfile = data.UserProfileList.whereEquals(appStatusProfileCode, 'ProfileCode').firstOrDefault();

                            hasAppStatusProfile = !!userHasProfile;
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        console.error(err);
                        deferred.reject(err);
                    });
            } catch (ex) {
                console.error(ex);
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $rootScope.$watch(function () { return (sessionStorage.isBackOfficeUser == 'true' || sessionStorage.isBackOfficeUser == true) && sessionStorage.Key; }, function (newVal, oldVal) {
            if (!!newVal && (sessionStorage.isBackOfficeUser == 'true' || sessionStorage.isBackOfficeUser == true)) {
                getUserProfileList();
            }
        });
    }

    function ApplicationStatusPickerController($scope, $rootScope, $state, $q, ApplicationStatusPickerService, providerJursidictionsService,utilityService, typeValuesService, dcaIService, applicationListingService) {
        var _key = sessionStorage.Key;
        var _childName = '';

        var checkApplicationStatusId = function () {
            // if (!!$scope.uiConfig.ApplicationStatusIds && !$scope.uiConfig.ApplicationStatusIds.includes($scope.current.applicationStatusId)) {
            //     $scope.current.applicationStatusId = $scope.uiConfig.ApplicationStatusIds.firstOrDefault();
            // }
        };

        $scope.getUIConfig = function () {
            var config = {};

            for (var prop in $scope.config.UIConfigs['Default']) {
                if (!$scope.config.UIConfigs['Default'].hasOwnProperty(prop)) { continue; }

                config[prop] = $scope.config.UIConfigs['Default'][prop];
            }

            if (!!$scope.config.UIConfigs[$scope.configName]) {
                for (var prop in $scope.config.UIConfigs[$scope.configName]) {
                    if (!$scope.config.UIConfigs[$scope.configName].hasOwnProperty(prop)) { continue; }

                    config[prop] = $scope.config.UIConfigs[$scope.configName][prop];
                }
            }

            return config;
        };

        $scope.getConfig = function () {
            var deferred = $q.defer();

            try {
                utilityService.getConfigData('/core/directive/application-status-picker/application-status-picker.server-config.json')
                    .then(function (data) {
                        if (!!data) {
                            $scope.config = data;

                            $scope.uiConfig = $scope.getUIConfig();

                            $scope.isConfigLoaded = true;
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.filterApplicationStatus = function () {
            if (!!$scope.uiConfig.ApplicationStatusIds && $scope.uiConfig.ApplicationStatusIds.length > 0) {
                $scope.refTables.applicationStatus = $scope.refTables.applicationStatus.whereIncludes($scope.uiConfig.ApplicationStatusIds, 'ApplicationStatusId');
            }
        };

        $scope.filterApplicationInternalStatus = function () {
            if (!!$scope.refTables.applicationInternalStatus && $scope.uiConfig.ApplicationInternalStatusIds.length > 0) {
                $scope.refTables.applicationInternalStatus = $scope.refTables.applicationInternalStatus.whereIncludes($scope.uiConfig.ApplicationInternalStatusIds, 'ApplicationInternalStatusId').orderBy('SortOrder');
            }
            // if (!!$scope.uiConfig.AdditionalApplicationInternalStatus && !!$scope.uiConfig.AdditionalApplicationInternalStatus.length > 0) {
            //     if (!$scope.refTables.applicationInternalStatus) {
            //         $scope.refTables.applicationInternalStatus = [];
            //     }
            //     $scope.refTables.applicationInternalStatus = $scope.refTables.applicationInternalStatus.concat($scope.uiConfig.AdditionalApplicationInternalStatus).orderBy('SortOrder');
            // }
        };

        $scope.applicationStatusChanged = function () {
            if (!!$scope.current && !!$scope.current.applicationStatusId) {
                $scope.current.applicationInternalStatusId = null;
            }
        };

        $scope.applicationInternalStatusChanged = function () {
            if (!!$scope.current && !!$scope.current.applicationInternalStatusId) {
                $scope.current.applicationStatusId = null;
            }
        };

        var applicationStatusUpdateAll = function () {
            var deferred = $q.defer();

            try {
                var approvalType = "ApplicationStatusChange";
                if (!!$scope.uiConfig.ApplicationStatusApprovalTypeMapping && !!$scope.current.applicationStatusId) {
                    var approvalTypeMappings = $scope.uiConfig.ApplicationStatusApprovalTypeMapping.whereEquals($scope.current.applicationStatusId, "ApplicationStatusId");
                    if (!!approvalTypeMappings && approvalTypeMappings.length > 0) {
                        approvalType = approvalTypeMappings[0].ApprovalType;
                    }
                }
                var effectiveApplicationStatusId = (!!$scope.current.applicationStatusId ? $scope.current.applicationStatusId : $scope.applicationStatusId);
                var effectiveApplicationInternalStatusId = (!!$scope.current.applicationInternalStatusId ? $scope.current.applicationInternalStatusId : $scope.applicationInternalStatusId);
                var reqData = {
                    UpdateApplicationStatus: true,
                    IndividualId: $scope.individualId,
                    ApplicationId: $scope.applicationId,
                    ApprovalType: approvalType,
                    ApplicationStatusId: effectiveApplicationStatusId,
                    IsSubmission: false,
                    LogApplicationStatusIds: effectiveApplicationStatusId,
                    ProcessDeficiency: false,
                    ApplicationInternalStatusId: (effectiveApplicationInternalStatusId == 100 ? null : effectiveApplicationInternalStatusId)
                }
                ApplicationStatusPickerService.processLicenseAndApplication(sessionStorage.Key, reqData)
                    .then(function (response) {
                        deferred.resolve(response);
                    }, function (data) {
                        deferred.reject(data);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        var saveApplicationStatusReview = function () {
            var deferred = $q.defer();
            if (!!$scope.applicationReview) {
                $scope.applicationReview.ApplicationReviewComment = $scope.current.applicationReviewComment;
                $scope.applicationReview.ApplicationStatusId = (!!$scope.current.applicationStatusId ? $scope.current.applicationStatusId : $scope.applicationStatusId);
                $scope.applicationReview.ApplicationInternalStatusId = (!!$scope.current.applicationInternalStatusId ? $scope.current.applicationInternalStatusId : $scope.applicationInternalStatusId);

                ApplicationStatusPickerService.saveApplicationReview(sessionStorage.Key, [$scope.applicationReview]).then(function (response) {
                    deferred.resolve(response);
                }, function (error) {
                    deferred.reject(error);
                });
            }
            return deferred.promise;
        };

        $scope.saveApplicationStatusClicked = function (form) {
            if (!$scope.validateForm(form)) { return; }
            if(!!$scope.current && !!$scope.current.ProviderData) { return;}
            if(!$scope.current && form.$name == 'applicationStatusPickerForm')
            {
                $scope.current = {
                    applicationId: $scope.applicationId,
                    applicationTypeId: $scope.applicationTypeId,
                    applicationSubTypeId: $scope.applicationSubTypeId,
                    applicationStatusId: 31,
                    applicationInternalStatusId: $scope.applicationInternalStatusId,
                    applicationSubmitMode: $scope.applicationSubmitMode,
                    applicationNumber: $scope.applicationNumber,
                    licenseTypeId: $scope.licenseTypeId
                };
            }
            
            if ((!!$scope.current.applicationStatusId && !confirm("This change is not reversible, Are you sure you want to continue?"))
                || (!!$scope.current.applicationInternalStatusId && !confirm("Are you sure you want to continue?"))) {
                return;
            }
            $scope.uiLogic.operationPending = true;

            $q.all([
                applicationStatusUpdateAll(),
                saveApplicationStatusReview()
            ]).then(function () {
                let isOk = false;
                $scope.uiLogic.operationPending = false;
                $scope.applicationStatusUpdated({ $applicationStatusId: $scope.current.applicationStatusId });
                let requestData = {};
                if(!!$scope.current.individualData){
                    requestData = {
                        isn: $scope.current.individualData.IDNumber,
                        IndividualLicenseId:  $scope.current.individualData.IndividualLicenseId,
                        isAbandoned: 1,
                        ProviderId: 0
                    };
                }
    
                let renewalProcessStatusResponse = dcaIService.updateRenewalProcessStatus(sessionStorage.Key, requestData);
                isOk = (!!renewalProcessStatusResponse && !!renewalProcessStatusResponse.Status); 

                let applicationIdInfo = {   
                    ApplicationId: $scope.current.applicationId                
                };

                applicationListingService.processApplicationAbandoned(sessionStorage.Key, applicationIdInfo)
                    .then(function (response) {
                    }, function (response) {
                    });                
                
            }, function (err) {
                console.error(err);
                $scope.uiLogic.operationPending = false;
            });
        };

        $scope.cancelApplicationStatusClicked = function () {
            $scope.current = {
                applicationId: $scope.applicationId,
                applicationTypeId: $scope.applicationTypeId,
                applicationSubTypeId: $scope.applicationSubTypeId,
                //applicationStatusId: $scope.applicationStatusId,
                //applicationInternalStatusId: $scope.applicationInternalStatusId,
                applicationSubmitMode: $scope.applicationSubmitMode,
                applicationNumber: $scope.applicationNumber,
                licenseTypeId: $scope.licenseTypeId
            };

            $scope.uiLogic.popoverIsOpen = false;

            checkApplicationStatusId();
        };

        var getApplicationReviewType = function () {
            var queueStatusReviewTypeMapping = [
                { queueType: "ReviewQ", applicationStatusId: 27, ApplicationReviewTypeId: 1 },
                { queueType: "TechReviewQ", applicationStatusId: 29, ApplicationReviewTypeId: 2 },
                { queueType: "ReviewQ", applicationStatusId: 30, ApplicationReviewTypeId: 3 },
                { queueType: "ManagerQ", applicationStatusId: 28, ApplicationReviewTypeId: 4 },
                { queueType: "ExecutiveQ", applicationStatusId: 12, ApplicationReviewTypeId: 5 }
            ];
            var queueStatusReviewType = queueStatusReviewTypeMapping
                .whereEquals($scope.routeData.queueType, 'queueType')
                .whereEquals($scope.applicationStatusId, 'applicationStatusId').firstOrDefault();
            var applicationReviewType = null;
            if (!!queueStatusReviewType) {
                applicationReviewType = $scope.applicationReviewTypes.whereEquals(queueStatusReviewType.ApplicationReviewTypeId, 'ApplicationReviewTypeId').firstOrDefault();
            }
            return applicationReviewType;
        };

        var createApplicationReview = function () {
            var applicationReviewType = getApplicationReviewType();
            $scope.applicationReview = {
                ApplicationId: $scope.applicationId,
                IndividualId: $scope.individualId,
                ApplicationStatusId: $scope.applicationStatusId,
                ApplicationReviewTypeId: (applicationReviewType ? applicationReviewType.ApplicationReviewTypeId : null),
                ReviewTypeCode: (applicationReviewType ? applicationReviewType.ReviewTypeCode : null),
                ReviewTypeName: (applicationReviewType ? applicationReviewType.ReviewTypeName : null)
            }
            if (!!$scope.applicationReview && !$scope.applicationReview.ApplicationReviewDate) {
                $scope.applicationReview.ApplicationReviewDate = new Date();
            }
            if(!!$scope.individualId)
            {
                ApplicationStatusPickerService.getIndividualLicenseBYIndividualId(sessionStorage.Key, $scope.individualId)  
                .then(function (data) {
                    if(!!$scope.current)
                        $scope.current.individualData = data.IndividualLicenseList.firstOrDefault();
                }, function (error) {
    
                });
            }
        };

        var createProviderApplicationReview = function () {
            var applicationReviewType = getApplicationReviewType();
            $scope.applicationReview = {
                ApplicationId: $scope.applicationId,
                ProviderId: $scope.current.ProviderData.ProviderId,
                ApplicationStatusId: $scope.applicationStatusId,
                ApplicationReviewTypeId: (applicationReviewType ? applicationReviewType.ApplicationReviewTypeId : null),
                ReviewTypeCode: (applicationReviewType ? applicationReviewType.ReviewTypeCode : null),
                ReviewTypeName: (applicationReviewType ? applicationReviewType.ReviewTypeName : null)
            }
            if (!!$scope.applicationReview && !$scope.applicationReview.ApplicationReviewDate) {
                $scope.applicationReview.ApplicationReviewDate = new Date();
            }
            /*ApplicationStatusPickerService.getIndividualLicenseBYIndividualId(sessionStorage.Key, $scope.individualId)  
            .then(function (data) {
                $scope.current.individualData = data.IndividualLicenseList.firstOrDefault();
            }, function (error) {

            });*/
        
           
        };


        $scope.init = function () {
            if(!!$scope.individualId)
            {
                $scope.current = {
                    applicationId: $scope.applicationId,
                    applicationTypeId: $scope.applicationTypeId,
                    applicationSubTypeId: $scope.applicationSubTypeId,
                    //applicationStatusId: $scope.applicationStatusId,
                    //applicationInternalStatusId: $scope.applicationInternalStatusId,
                    applicationSubmitMode: $scope.applicationSubmitMode,
                    applicationNumber: $scope.applicationNumber,
                    licenseTypeId: $scope.licenseTypeId
                };
                $scope.uiLogic = {
                    operationPending: true
                };
                $scope.refTables = {};
                $scope.routeData = $state.current.routeData;

                $scope.getConfig()
                .then(function () {
                    typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=applicationreviewtype")).then(function (response) {
                        $scope.applicationReviewTypes = response.ReferenceEntities;
                        $scope.uiLogic.operationPending = false;
                        createApplicationReview();
                        checkApplicationStatusId();
                    })
                });
            }
            else
            {
                providerJursidictionsService.getProviderPreviousLicense(sessionStorage.Key, null ,$scope.applicationId).then(function (data){
                    $scope.ProviderData = data.ProviderPreviousLicenseList?.firstOrDefault();
                    $scope.current = {
                        applicationId: $scope.applicationId,
                        applicationTypeId: $scope.applicationTypeId,
                        applicationSubTypeId: $scope.applicationSubTypeId,
                        providerId: $scope.ProviderData.ProviderId,
                        //applicationStatusId: $scope.applicationStatusId,
                        //applicationInternalStatusId: $scope.applicationInternalStatusId,
                        applicationSubmitMode: $scope.applicationSubmitMode,
                        applicationNumber: $scope.applicationNumber,
                        licenseTypeId: $scope.ProviderData.ProviderLicenseTypeId
                    };
                    $scope.current.ProviderData = $scope.ProviderData;
                    
                } , function (error){
                });
            }
            
            if($scope.ProviderData != null && $scope.ProviderData.ProviderLicenseTypeId != null && $scope.ProviderData.ProviderLicenseTypeId > 1)
            {
            $scope.current = {
                applicationId: $scope.applicationId,
                applicationTypeId: $scope.applicationTypeId,
                applicationSubTypeId: $scope.applicationSubTypeId,
                providerId: (!!$scope.ProvideId)?$scope.ProvideId:null,
                //applicationStatusId: $scope.applicationStatusId,
                //applicationInternalStatusId: $scope.applicationInternalStatusId,
                applicationSubmitMode: $scope.applicationSubmitMode,
                applicationNumber: $scope.applicationNumber,
                licenseTypeId: $scope.licenseTypeId
            };
        }
            $scope.uiLogic = {
                operationPending: true
            };
            $scope.refTables = {};
            $scope.routeData = $state.current.routeData;

            $scope.getConfig()
                .then(function () {
                    typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=applicationreviewtype")).then(function (response) {
                        $scope.applicationReviewTypes = response.ReferenceEntities;
                        $scope.uiLogic.operationPending = false;
                        
                        if($scope?.current?.ProviderData != null && $scope?.current?.ProviderData?.ProviderLicenseTypeId > 1 )
                            createProviderApplicationReview();
                        else
                            createApplicationReview();

                        checkApplicationStatusId();
                    })
                    //$scope.operationPending(false);
                    HideLoader();
                });
        };

        $scope.$watch(function () { return hasAppStatusProfile; }, function (newVal, oldVal) {
            $scope.hasAppStatusProfile = newVal;
        });
    }
})();