(function () {
    'use strict'

    angular.module('app.core')
    .factory('EntityDocumentGenerationService', ['$http', '$q', 'WebApiUrl', 'utilityService', EntityDocumentGenerationService]);

    function EntityDocumentGenerationService($http, $q, WebApiUrl, utilityService) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                var headers = res.headers();
                if (!!headers['generatedresponse'] && headers['apistatus']=='false') {
                    res.data = {
                        Status: false,
                        StatusCode: headers.statuscode,
                        Message: headers.message
                    };
                }
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log('Request failed with status: ' + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _getLicenses = function (key, individualId) {
            var urlParams = {
                IndividualId: individualId
            };

            return responseHandler($http.get(WebApiUrl + 'Individual/IndividualLicenseDetailBYIndividualId/' + key + '/', { params: urlParams }));
        };

        var _getUserInfo = function (key, userId) {
            var urlParams = {
                ID: userId
            };

            return responseHandler($http.get(WebApiUrl + 'Users/UsersGetBYID/' + key + '/', { params: urlParams }));
        };

        var _generateAndDownloadDocument = function (key, request) {
            var httpPromise = $http.post(WebApiUrl + 'Entity/GenerateAndDownloadDocument/' + key + '/', request, {
                responseType: 'arraybuffer'
            });
            return responseHandler(httpPromise, function(res) {
                var headers = res.headers();
                if (headers['apistatus'] == 'true') {
                    utilityService.downloadExcelFile(res.data, headers);
                }
            });
        };

        var _getDocumentTemplates = function (key, search) {
            return responseHandler($http.post(WebApiUrl + 'Entity/GetDocumentTemplates/' + key + '/', search));
        };

        return {
            getLicenses: _getLicenses,
            getUserInfo: _getUserInfo,
            generateAndDownloadDocument: _generateAndDownloadDocument,
            getDocumentTemplates: _getDocumentTemplates
        };
    }
})();