﻿(function () {
    'use strict'
    angular.module('app.login')
        .controller("ForgotPasswordController", ["LoginwemailService", "$state", "$scope", "AppConfig", "Messages",
            function (LoginwemailService, $state, $scope, AppConfig, Messages) {
                // Forgot Password
                $scope.OTPType = "SMS";
                var Source = AppConfig.Source;
                var currentState = $state.current.name;

                if (currentState == 'app.FirmForgotPassword') {
                    $scope.isFirmChangePassword = true;
                }

                $scope.forgetPassword = function () {
                    try {
                        if (!$scope.otpGenerated && validateData('forgotpasswordemailvalidation')) {
                            if ($scope.isOTPEnabled) {
                                ValidateUserEmailandSendOTP();
                            } else {
                                CallForgotPassword();
                            }
                        } else if ($scope.otpGenerated && validateData('forgotpasswordotpvalidation')) {
                            VerifyOTP();
                        }
                    }
                    catch (ex) {
                        $scope.showStatusMessage(ex.message, "error");
                    }
                };

                var getOTPConfigurationSetting = function () {
                    LoginwemailService.ConfigurationGetbySettings('key', 'EnableForgotPasswordOTP').then(function (res) {
                        if (res && res.data.Status && res.data.ConfigurationList && res.data.ConfigurationList.length > 0) {
                            $scope.isOTPEnabled = (res.data.ConfigurationList[0].Value.toLowerCase() == 'true');
                        }
                    });
                };
                getOTPConfigurationSetting();

                var currentState = $state.current.name;
                $scope.backtLogin = function () {
                    switch (currentState) {
                        case 'app.ForgotPassword': {
                            $state.go('app.BackofficeLoginstaff');
                            break;
                        }
                        case 'app.ApplicantForgotPassword': {
                            $state.go('app.Loginwemail');
                            break;
                        }
                        case 'app.FirmForgotPassword': {
                            $state.go('app.FirmLogin');
                            break;
                        }
                        case 'app.AgencyForgotPassword': {
                            $state.go('app.LoginBusinessPortal');
                            break;
                        }
                        default: {
                            if (sessionStorage.LoginPage && sessionStorage.LoginPage != '') {
                                try {
                                    $state.go(sessionStorage.LoginPage);
                                } catch (ex) {
                                    $state.go('app.Loginwemail');
                                }
                            } else {
                                $state.go('app.Loginwemail');
                            }
                        }
                    }
                };

                //OTP Code
                $scope.goBacktoEmail = function () {
                    $scope.otpGenerated = false;
                    $scope.ShowotpGeneratedMessage = false;
                };
                $scope.reSendOTP = function () {
                    if ($scope.Email && $scope.Email != '') {
                        $scope.ShowotpGeneratedMessage = false;
                        ValidateUserEmailandSendOTP();
                    }
                };

                var ValidateUserEmailandSendOTP = function () {
                    var Email = $scope.Email;
                    ShowLoader("Please wait...");
                    LoginwemailService.ValidateUserEmailandSendOTP(Email, $scope.OTPType)
                        .then(function (res) {
                            var response = res.data;
                            if (response && $scope.checkServerResponse(response)) {
                                $scope.UserId = response.RecordId;
                                $scope.otpGenerated = true;
                                $scope.ShowotpGeneratedMessage = true;
                                HideLoader();
                            }
                            else if (response && response.StatusCode == '08') {
                                $scope.otpGenerated = false;
                                $scope.ShowotpGeneratedMessage = false;
                                CallForgotPassword();
                            }
                            else if (response && response.StatusCode == '09') {
                                $scope.otpGenerated = false;
                                $scope.otpGenerated = true;
                                $scope.showStatusMessage(Messages.General.Errors.OTP, "error");
                                HideLoader();
                            }
                            else {
                                $scope.otpGenerated = false;
                                $scope.ShowotpGeneratedMessage = false;
                                HideLoader();
                                $scope.showStatusMessage(Messages.General.Errors.InvalidUser, "error");
                                $scope.Password = "";
                            }
                        }, function (res) {
                            HideLoader();
                            var data = res.data;
                            if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                        });
                };

                var CallForgotPassword = function () {
                    ShowLoader("Please wait...");
                    var Email = $scope.Email;
                    var source = ($scope.isFirmChangePassword ? 'cbafirm' : '');

                    LoginwemailService.ForgetPassword(Email, source)
                        .then(function (res) {
                            var response = res.data;
                            if ($scope.checkServerResponse(response)) {
                                $scope.showStatusMessage(response.Message, "success");
                                $scope.Password = "";
                            }
                            else {
                                $scope.showStatusMessage(Messages.General.Errors.InvalidUser, "error");
                                $scope.Password = "";
                            }
                            HideLoader();
                        }, function (res) {
                            HideLoader();
                            var data = res.data;
                            if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                        });
                };

                var VerifyOTP = function () {
                    ShowLoader("Please wait...");
                    var reqData = {
                        OTPValue: $scope.OTPVal,
                        UserId: $scope.UserId,
                        Email: $scope.Email
                    }
                    LoginwemailService.VerifyOTP(reqData)
                        .then(function (res) {
                            var response = res.data;
                            if ($scope.checkServerResponse(response)) {
                                CallForgotPassword();
                            }
                            else {
                                HideLoader();
                                $scope.showStatusMessage(Messages.General.Errors.InvalidOTP, "error");
                                $scope.Password = "";
                            }
                        }, function (res) {
                            HideLoader();
                            var data = res.data;
                            if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                        });
                };

            }]);
})();
