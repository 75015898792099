(function () {
    'use strict';

    angular
        .module('app.InitialApplication')
        .controller("LicenseOutputController", LicenseOutputController);

    LicenseOutputController.$inject = ["$scope",
        "licenseOutputService", "backofficeIndividualAdressService", "utilityService", "AppConfig","Messages"
    ];

    function LicenseOutputController($scope,
        licenseOutputService, backofficeIndividualAdressService, utilityService, AppConfig,Messages) {

        if (AppConfig.EnableConsoleLogging)
            console.log('License Info : ', $scope.licenseOutput);

        $scope.type = ($scope.type ? $scope.type : "emailform");
        $scope.individualLicense = {};
        $scope.init = function () {
            ShowLoader();
            $scope.licenseOutput = {};
            $scope.getLicenseOutputConfig();
        };

        $scope.getLicenseOutputConfig = function () {
            $scope.individualLicenseOutput = -1;
            var relativeFilePath = "/components/individual/application/directives/license-output/license-output.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function (data) {
                    $scope.licenseOutputConfig = data;
                    getIndividualContactsByIndividualId($scope.individualId);
                    individualLicenseDetailBYIndividualId($scope.individualId);
                    $scope.isConfigLoaded = true;
                }, function (error) {
                    HideLoader();
                    ShowErrMsg("licenseOutputConfig", "Some error occurred,Unable to load the business license config")
                });
        };

        $scope.CreateDocument = function () {
            if ($scope.individualLicense.LicenseNumber) {
                ShowLoader("Please wait...");
                var data = {
                    IndividualId: $scope.individualId,
                    LicenseNumber: $scope.individualLicense.LicenseNumber,
                    SendApprovalEmail: false
                };
                licenseOutputService.generateAndSendLicenseEmail(sessionStorage.Key, data).then(function (res) {
                    if (res.Status) {
                        $scope.showStatusMessage(Messages.General.Success.Wallet, "success");
                        HideLoader();
                        $scope.$emit("closeLicenseOutputForm", {});
                    } else {
                        HideLoader();
                        $scope.$emit("closeLicenseOutputForm", {});
                    }
                }, function (error) {
                    HideLoader();
                });
            } else {
                showStatusMessage(Messages.General.Errors.SelectCredential, "error");
            }
        }

        var individualLicenseDetailBYIndividualId = function (individualId) {
            try {
                $scope.IndividualLicenseList = [];
                licenseOutputService.individualLicenseDetailBYIndividualId(sessionStorage.Key, individualId)
                    .then(function (response) {
                        HideLoader();
                        if (response.Status) {
                            $scope.IndividualLicenseList = _.uniq(response.IndividualLicenseList, function (x) {
                                return x.LicenseNumber;
                            });
                            if ($scope.IndividualLicenseList && $scope.IndividualLicenseList.length == 1) {
                                $scope.LicenseNumber = $scope.IndividualLicenseList[0].LicenseNumber;
                            }
                        }
                    }, function (data) {
                        HideLoader();
                    });
            } catch (ex) {
                HideLoader();
            }
        };

        var getIndividualContactsByIndividualId = function (individualId) {
            licenseOutputService.individualContactBYIndividualId(sessionStorage.Key, individualId)
                .then(function (responseContact) {
                    var emailAddress = '';
                    angular.forEach(responseContact.IndividualContactResponse, function (contactOption, index) {
                        if (!!contactOption.ContactStatusId && contactOption.ContactStatusId == 1) {
                            if (contactOption.ContactTypeId == "8") {
                                emailAddress = contactOption;
                            }
                        }
                    });
                    if (!!emailAddress) {
                        $scope.licenseOutput.emailAddress = emailAddress.ContactInfo;
                    } else {
                        $scope.licenseOutput.emailAddress = null;
                    }
                    $scope.isConfigLoaded = true;
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    showStatusMessage(data, "error");
                });
        };

        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        var validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            }
            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        var ShowErrMsg = function (propName, errorMessage) {
            propName = (!!propName) ? propName : "licenseOutputErr";
            errorMessage = (!!errorMessage) ? errorMessage : $scope.licenseOutputConfig.ValidationMsg.APIError;
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            var propExist = false;
            for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                if ($scope.natValidation.errorMessages[i].propName == propName)
                    propExist = true;
            }
            if (!propExist)
                $scope.natValidation.errorMessages.push({
                    propName: propName,
                    errorMessage: errorMessage
                });

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        $scope.generateAndSendLicenseOutput = function (sendEmail) {
            ShowLoader();
            if (!!sendEmail) {
                if (!validateForm($scope.currentForm)) {
                    HideLoader();
                    return;
                }
            }
            var reqEmailObj = {
                LicenseNumber: $scope.licenseNumber,
                IndividualId: $scope.individualId,
                ApplicationId: $scope.applicationId,
                Email: $scope.licenseOutput.emailAddress,
                SendApprovalEmail: (!!sendEmail)
            };

            licenseOutputService.generateAndSendLicenseEmail(sessionStorage.Key, reqEmailObj).then(function (response) {
                if (!response.Status) {
                    ShowErrMsg("unableToGenerateOutput", response.Message);
                    HideLoader();
                    return;
                }
                $scope.showStatusMessage({
                    messageContent: ((!!sendEmail) ? 'Output email sent successfully' : "Output generated successfully"),
                    messageType: 'success'
                });
                $scope.$emit("closeLicenseOutputForm", {});
                HideLoader();
            }, function (data) {
                HideLoader();
                ShowErrMsg("unableToGenerateOutput", data.Message);
            });
        };

        $scope.$on("SendLicenseOutputEmail", function (event, args) {
            $scope.generateAndSendLicenseOutput($scope.currentForm);
        });

        $scope.closeLicenseOutputForm = function () {
            $scope.$emit("closeLicenseOutputForm", {});
        };

        $scope.init();
    }
})();
