(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('licenseOutput', licenseOutputDirective);

    licenseOutputDirective.$inject = [];

    function licenseOutputDirective() {
        return {
            restrict: 'E',
            scope: {
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                natValidation: "=natValidation",
                licenseNumber: "=licenseNumber",
                individualId: "=individualId",
                applicationId: "=applicationId",
                individualLicenseId: "=?individualLicenseId",
                showStatusMessage: "&",
                type: "=?"
            },
            templateUrl: "app/components/individual/application/directives/license-output/license-output.html",
            controller: "LicenseOutputController"
        }
    }
})();
