(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeProviderFinanceService', backofficeProviderFinanceService)

    backofficeProviderFinanceService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeProviderFinanceService($http, $q, WebApiUrl, AppConfig) {

        var _individualPaidInvoiceByIndividualId = function (Key, individualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/PaidInvoiceByIndividualId/' + Key + '?IndividualId=' + individualId));
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };
        return {
            individualPaidInvoiceByIndividualId: _individualPaidInvoiceByIndividualId
        }
    }
})();