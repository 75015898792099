(function () {
    'use strict';

    var controllerModule = angular.module('app.InitialApplication')
    controllerModule.controller("InitialProviderApplicationFeePaymentController", InitialProviderApplicationFeePaymentController);

    InitialProviderApplicationFeePaymentController.$inject = ["$rootScope", "$scope", "$state",
        "initialProviderApplicationFeePaymentService", "utilityService", "typeValuesService", "TypeValue", "WebApiUrl", "$timeout", "$exceptionHandler", "contentService", "peerReviewService", "ShareholderPartnerService", "ProviderMultipleFileUploadService", "bppService", "countryStateService", "initialApplicationstatusService", "htmlToPdfGenerationService", "EInformationDirectiveService", "$q", "BusinessEntitytatusService"
    , 'Messages'];

    function InitialProviderApplicationFeePaymentController($rootScope, $scope, $state,
        initialProviderApplicationFeePaymentService, utilityService, typeValuesService, TypeValue, WebApiUrl, $timeout, $exceptionHandler, contentService, peerReviewService, ShareholderPartnerService, ProviderMultipleFileUploadService, bppService, countryStateService, initialApplicationstatusService, htmlToPdfGenerationService, EInformationDirectiveService, $q, BusinessEntitytatusService, Messages) {
        var invoiceMastersDetail = [];
        var invoiceDetailsData = [];
        $scope.payment = {};
        $scope.content = {};
        $scope.isAutoConfirm = true;
        if (!$scope.personalData)
            $scope.personalData = {};
        if (!$scope.personalInfo)
            $scope.personalInfo = {};
        
            $scope.queueName = ((!!$state.current && !!$state.current.routeData && !!$state.current.routeData.queueType) ? $state.current.routeData.queueType : "");
        
        $scope.paymentTemplate = {
            "ApplicationNumber": "sample string 1",
            "ApplicationId": $scope.ApplicationId,
            "ProviderId": $scope.providerId,
            "PaymentMethod": '-1',
            "PaymentMethodSelected": "-1",
            "CertificationFees": 150,
            "TotalNoOfAdditional": '0',
            "AdditinolCertiCost": 15,
            "FeeForAdditional": '',
            "CheckNo": '',
            "CheckComment": '',
            "CheckMailing": false,
            "CardNumber": "",
            "CVV": '',
            "ExpirationMonths": '-1',
            "ExpirationYears": '-1',
            "TotalFee": 150,
            "Description": "",
            "InvoiceNumber": "",
            "FirstName": "",
            "LastName": "",
            "Address": "",
            "City": "",
            "StateCode": "",
            "Zip": "",
            "Country": "USA",
            "EmailAddress": "",
            "TransactionObject": {
                "TransactionId": (new Date()).getMilliseconds(),
                "MasterTransactionId": (new Date()).getMilliseconds(),
                "ApplicationId": 4,
                "ProviderId": 5,
                "ShoppingCartId": 6,
                "IndividualLicenseId": 7,
                "LicenseTypeId": 8,
                "LicenseNumber": "",
                "TransactionStartDatetime": "",
                "TransactionEndDatetime": "",
                "TransactionStatus": "SENT",
                "TransactionInterruptReasonId": 12,
                "TransactionDeviceTy": "TEST",
                "InvoiceNumber": "",
                "CreatedBy": 15,
                "CreatedOn": "",
                "TransactionGuid": ""
            },
            "RequestedLicenseStatusTypeId": 1
        };
        if ($scope.isBackoffice) {
            $scope.paymentTemplate.PaymentMethod = "-1";
            $scope.paymentTemplate.PaymentMethodSelected = "-1";
        } else if (!$scope.isBackoffice) {
            $scope.paymentTemplate.PaymentMethod = "3";
            $scope.paymentTemplate.PaymentMethodSelected = "3";
        }
        // $scope.PaymentMethodList = [{ Id: '-1', Name: "Select" }, { Id: '3', Name: "Credit / Debit Card" }];


        $scope.validationObjectTemplate = {
            Check: {
                CheckNo: false,
                CheckMailingPayment: false
            },
            Card: {
                CardNumber: false,
                Cvv: false,
                ExpDateMonth: false,
                ExpDateYear: false,
                AddressFirstName: false,
                AddressLastName: false,
                AddressStreet: false,
                AddressCity: false,
                AddressState: false,
                AddressZIP: false,
                AddressCountry: false,
            }
        };
        $scope.validationObject = angular.copy($scope.validationObjectTemplate);
        //------------Page Config functions----------------
        $scope.getFeePaymentConfig = function () {
            var relativeFilePath = "/components/individual/application/directives/provider-fee-payment/provider-fee-payment.server-config.json";
            utilityService.getConfigData(relativeFilePath)
                .then(function (data) {
                    $scope.feePaymentConfig = data;
                    if (!$scope.isRenewal) {
                        AuditVisitInfoSave();
                    }
                    $scope.payment = angular.copy($scope.paymentTemplate);
                    if (!!$scope.feeDetails) {
                        $scope.payment.feeDetails = $scope.feeDetails;
                    }
                    $scope.payment.PaymentCopyAddress = false;
                    $scope.loadReceiptDetail($scope.module).then(function (loadedResponse) {
                        if (!!loadedResponse.Status) {
                            if (!!$scope.payment.receiptsData.createNewInvoiceMaster || !!$scope.acceptNewPayment) {
                                if (!$scope.isBackoffice) {
                                    var reqObj = {
                                        InvoiceID: 0,
                                        ProviderId: $scope.providerId,
                                        ApplicationId: $scope.ApplicationId,
                                        LicenseTypeId: $scope.providerLicense.ProviderLicenseTypeId,
                                        AmountBilled: $scope.payment.feeDetails.totalFee,
                                        AmountPaid: $scope.payment.feeDetails.totalFee,
                                        AmountAdjusted: 0,
                                        Balance: $scope.payment.feeDetails.totalFee,
                                        TransType: "ORD",
                                        Module: "ADVDUES",
                                        InvoiceStatus: "OPEN",
                                        ModuleGroup: 'APPLICATION',
                                        GPSelected: false,
                                        GPAdjust: 0,
                                        GPPayment: 0,
                                        GPVoid: false,
                                        Description: "ReCertification Application Payment Invoice",
                                        LFSelected: false,
                                        CreatedBy: $scope.userId,
                                        PayorId: $scope.providerId,
                                        PayorType: 'I'
                                    };
                                    if (!!$scope.module) {
                                        reqObj.LicenseTypeId = $scope.applicationInfo.LicenseTypeId;
                                        reqObj.Module = $scope.module;
                                        reqObj.Description = $scope.module + " Application Payment Invoice";
                                    } else if (!$scope.isRenewal) {
                                        reqObj.LicenseTypeId = $scope.applicationInfo.LicenseTypeId;
                                        reqObj.Module = "APPLICATION";
                                        reqObj.Description = "New Application Payment Invoice";
                                    } else {
                                        reqObj.LicenseTypeId = $scope.providerLicense.LicenseTypeId;
                                        reqObj.CredentialNumber = $scope.providerLicense.ProviderLicenseNumber;
                                        reqObj.Module = "APPLICATION";
                                        reqObj.Description = "Renewal Application Payment Invoice";
                                    }
                                    initialProviderApplicationFeePaymentService
                                    .invoiceMasterSave(sessionStorage.Key, [reqObj])
                                        .then(function (objMasterInvoice) {
                                            if (objMasterInvoice.StatusCode != "00") {
                                                return showErrorMessage(objMasterInvoice.Message);
                                            }
                                            HideLoader();
                                            invoiceMastersDetail = objMasterInvoice.InvoiceMasters[0];
                                            $scope.payment.InvoiceId = invoiceMastersDetail.InvoiceID;
                                        }, function (response) {
                                            return showErrorMessage(response);
                                        });
                                } else {
                                    HideLoader();
                                }
                            } else {
                                $scope.payment.InvoiceId = ($scope.payment.receiptsData.closedInvoiceExists ? $scope.payment.receiptsData.closedInvoiceMastersByModule[0].InvoiceID : $scope.payment.receiptsData.openInvoiceMastersByModule[0].InvoiceID);
                                if (!!$scope.payment.receiptsData.closedInvoiceExists) {
                                    makeFormReadOnly(true, true);
                                }
                                $scope.payment.IsPaymentSubmitted = (!!$scope.payment.receiptsData.closedInvoiceExists);
                                $scope.payment.isInitialLoadPayment = true;
                                HideLoader();
                            }
                        }
                    });
                    $scope.isConfigLoaded = true;
                    $scope.changePaymentMethod(true);
                }, function (error) {

                });
        };
        $scope.checkMailingChanged = function () {
            if ($scope.payment.CheckMailing) {
                $scope.payment.PaymentMethod = "1";
                $scope.payment.PaymentMethodSelected = "-1";
                $scope.changePaymentMethod(true);
            }
        };
        $scope.changePaymentMethod = function (isInternalChange) {
            $scope.validationObject = angular.copy($scope.validationObjectTemplate);
            if (!!$scope.currentForm)
                $scope.currentForm.$submitted = false;
            if (!isInternalChange)
                $scope.payment.PaymentMethod = $scope.payment.PaymentMethodSelected;
            if ($scope.payment.PaymentMethod == '1' ||
                $scope.payment.PaymentMethod == '2' ||
                $scope.payment.PaymentMethod == '4' ||
                $scope.payment.PaymentMethod == '5') {
                $scope.validationObject.Check = {
                    CheckNo: true,
                    CheckMailingPayment: true
                };
            } else if ($scope.payment.PaymentMethod == '3') {
                $scope.validationObject.Card = {
                    CardNumber: true,
                    Cvv: true,
                    ExpDateMonth: true,
                    ExpDateYear: true,
                    AddressFirstName: true,
                    AddressLastName: true,
                    AddressStreet: true,
                    AddressCity: true,
                    AddressState: true,
                    AddressZIP: true,
                    AddressCountry: true,
                };
            }
            if ($scope.payment.PaymentMethod == '3') {
                if (!!$scope.payment.feeDetails.applicationFeeOverride)
                    $scope.payment.feeDetails.applicationFee = $scope.payment.feeDetails.applicationFeeOverride;
            }
        };
        var makeFormReadOnly = function (includeControls, includeButton) {
            if (!!includeControls)
                $scope.IsControlsDisabled = true;
            else
                $scope.IsControlsDisabled = false;

            if (!!includeButton)
                $scope.IsButtonsDisabled = true;
            else
                $scope.IsButtonsDisabled = false;

            $scope.IsPaymentSubmit = true;
            HideLoader();
        };

        var detectCardType = function (number) {
            var re = {
                VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
                MCRD: /^5[1-5][0-9]{14}$/,
                AMEX: /^3[47][0-9]{13}$/,
                DISC: /^6(?:011|5[0-9]{2})[0-9]{12}$/
            }

            for (var key in re) {
                if (re[key].test(number)) {
                    return key
                }
            }
        };

        var showErrorMessage = function (error) {
            $('#approvedCivilAppSuccess').hide();
            $('#approvedCivilApp').show();
            $('#approvedCivilApp').html(error);
            if (!$rootScope.ProcessLoaderExternally)
                HideLoader();
            $('#paymentFailureModal').modal('show');
            return false;
        };
        var showSuccessMessage = function (successMessage) {
            $('#approvedCivilAppSuccess').show();
            $('#approvedCivilApp').hide();
            $('#approvedCivilAppSuccess').html(successMessage);
            if (!$rootScope.ProcessLoaderExternally)
                HideLoader();
            return false;
        };

        $scope.$on("PayandSubmit", function (event, args) {
            if (!$scope.payment.IsPaymentSubmitted && !$scope.payment.IsPaymentInitiated)
                $scope.processPayment($scope.currentForm, false);
        });

        $scope.processPaymentRenewal = function (currentForm) {
            if (!!$scope.isRenewal) {
                $scope.$emit("PayandSubmitTabRenewal", {});
                if ($scope.isRetiredOrDelinquent) {
                    $scope.copyAddressFromApplication();
                    
                }
            }
            else {
                $scope.processPayment($scope.currentForm, false);
            }
        };

        $scope.loadCityTypeahed = function (searchString, stateCode) {
            var cityList = [];

            if (!!$scope.CityList && stateCode == 'CA') {
                cityList = $scope.CityList.filter(function (item) {
                    if (!!item.CityName && item.CityName.toLowerCase() == searchString.toLowerCase()) {
                        return item;
                    }
                });

                if (cityList.length == 1) {
                    return cityList;
                }

                cityList = $scope.CityList.filter(function (item) {
                    if (!!item.CityName && item.CityName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) {
                        return item;
                    }
                });
            }

            return cityList;
        };

        $scope.loadZipRange = function (stateCode) {
            if (!!$scope.ZipList) {
                $scope.zipRange = [];
                var zipItem = $scope.ZipList.whereEquals(stateCode, 'StateCd').firstOrDefault();
                if (!!zipItem) {
                    for (var i = parseInt(zipItem.LowZip); i < parseInt(zipItem.HighZip); i++) {
                        $scope.zipRange.push({
                            Zip: i.toString().padStart(5, 0)
                        });
                    }
                }
                return $scope.zipRange;
            }
        };

        $scope.loadZipTypeahed = function (searchString, stateCode) {
            var zipRange = $scope.loadZipRange(stateCode);
            if (zipRange.length > 0) {
                return zipRange.filter(function (item) {
                    if (!!zipRange && item.Zip.startsWith(searchString)) {
                        return item;
                    }
                });
            }
        };
        
        $scope.getStateByCountryID = function (Country) {
            try {
                var CountryID = $scope.CountryList.whereEquals(Country, 'UserDefinedNumber').firstOrDefault().CountryId;
                countryStateService.getStateByCountryID(sessionStorage.Key, CountryID)
                    .then(function (response) {
                        if (!!response.Status) {
                            $scope.StatesData = response.State;
                        }
                    }, function (data) {
                        if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                    });
            } catch (ex) {
                if (ex != null) { }
                $exceptionHandler(ex);
            }
        };

        var getCeCourseApplicationFeeInvoiceDetail = function (associationId, associationType, descAppendStr) {
            var applicationFeeInvoiceDetail = {
                InvoiceDetailID: 0,
                InvoiceID: $scope.payment.InvoiceId,
                AssociationId: associationId,
                AssociationType: associationType,
                ApplicationId: $scope.ApplicationId,
                TransType: "CE",
                Description: (!!$scope.payment.feeDetails.applicationFeeDetailDesc) ? ($scope.payment.feeDetails.applicationFeeDetailDesc + descAppendStr) : "CE Course App",
                Quantity: 1,
                Amount: $scope.payment.feeDetails.applicationFee,
                AmtBilled: $scope.payment.feeDetails.applicationFee,
                AmtPaid: 0,
                AmtAdjusted: 0,
                AmtBalance: $scope.payment.feeDetails.applicationFee,
                TransCode: (!!$scope.payment.feeDetails.applicationFeeDetailCode) ? $scope.payment.feeDetails.applicationFeeDetailCode : "CREN-A",
                Module: (!!$scope.payment.feeDetails.Module) ? $scope.payment.feeDetails.Module : "ADVDUES",
                DetailCode: (!!$scope.payment.feeDetails.applicationFeeFundingString) ? $scope.payment.feeDetails.applicationFeeFundingString : "APPFEE",
                GPAdjust: 0,
                GPPayment: 0,
                CreatedBy: $scope.UserId,
                ProviderId: $scope.providerId,
                ReferenceNumber: $scope.providerLicense ? $scope.providerLicense.ProviderLicenseNumber : undefined
            };
            if ($scope.payment.PaymentMethod == 3 || $scope.isAutoConfirm) {
                applicationFeeInvoiceDetail.AmtPaid = applicationFeeInvoiceDetail.Amount;
                applicationFeeInvoiceDetail.AmtBalance = applicationFeeInvoiceDetail.Amount - applicationFeeInvoiceDetail.AmtPaid;
            }
            return applicationFeeInvoiceDetail;
        };

        $scope.submitPayment = function () {
            $scope.openNextTab({ currentTab: $scope.tab });
        };

        var getApplicationFeeInvoiceDetail = function () {
            var applicationFeeInvoiceDetail = {
                InvoiceDetailID: 0,
                InvoiceID: $scope.payment.InvoiceId,
                ApplicationId: $scope.ApplicationId,
                TransType: "CE",
                Description: (!!$scope.payment.feeDetails.applicationFeeDetailDesc) ? $scope.payment.feeDetails.applicationFeeDetailDesc : "Corporate Renewal Fee-Active",
                Quantity: 1,
                Amount: $scope.payment.feeDetails.applicationFee,
                AmtBilled: $scope.payment.feeDetails.applicationFee,
                AmtPaid: 0,
                AmtAdjusted: 0,
                AmtBalance: $scope.payment.feeDetails.applicationFee,
                TransCode: (!!$scope.payment.feeDetails.applicationFeeDetailCode) ? $scope.payment.feeDetails.applicationFeeDetailCode : "CREN-A",
                Module: (!!$scope.payment.feeDetails.Module) ? $scope.payment.feeDetails.Module : "ADVDUES",
                DetailCode: (!!$scope.payment.feeDetails.applicationFeeFundingString) ? $scope.payment.feeDetails.applicationFeeFundingString : "APPFEE",
                GPAdjust: 0,
                GPPayment: 0,
                CreatedBy: $scope.UserId,
                ProviderId: $scope.providerId,
                ReferenceNumber: $scope.providerLicense ? $scope.providerLicense.ProviderLicenseNumber : undefined
            };
            if ($scope.payment.PaymentMethod == 3 || $scope.isAutoConfirm) {
                applicationFeeInvoiceDetail.AmtPaid = applicationFeeInvoiceDetail.Amount;
                applicationFeeInvoiceDetail.AmtBalance = applicationFeeInvoiceDetail.Amount - applicationFeeInvoiceDetail.AmtPaid;
            }
            return applicationFeeInvoiceDetail;
        }

        var getChangeApplicationFeeInvoiceDetail = function (associationId, associationType, descAppendStr) {
            var changeApplicationFeeInvoiceDetail = {
                InvoiceDetailID: 0,
                InvoiceID: $scope.payment.InvoiceId,
                ProviderId: $scope.providerId,
                AssociationId: associationId,
                AssociationType: associationType,
                ApplicationId: $scope.ApplicationId,
                TransType: "CE",
                Description: (!!$scope.payment.feeDetails.applicationFeeDetailDesc) ? ($scope.payment.feeDetails.applicationFeeDetailDesc + descAppendStr) : "Change Application Fee",
                Quantity: 1,
                Amount: $scope.payment.feeDetails.applicationFee,
                AmtBilled: $scope.payment.feeDetails.applicationFee,
                AmtPaid: 0,
                AmtAdjusted: 0,
                AmtBalance: $scope.payment.feeDetails.applicationFee,
                TransCode: (!!$scope.payment.feeDetails.applicationFeeDetailCode) ? $scope.payment.feeDetails.applicationFeeDetailCode : "CHANGEAPPLICATION",
                Module: (!!$scope.payment.feeDetails.Module) ? $scope.payment.feeDetails.Module : "ADVDUES",
                DetailCode: (!!$scope.payment.feeDetails.applicationFeeFundingString) ? $scope.payment.feeDetails.applicationFeeFundingString : "APPFEE",
                GPAdjust: 0,
                GPPayment: 0,
                CreatedBy: $scope.UserId
            };

            if ($scope.payment.PaymentMethod == 3) {
                changeApplicationFeeInvoiceDetail.AmtPaid = changeApplicationFeeInvoiceDetail.Amount;
                changeApplicationFeeInvoiceDetail.AmtBalance = changeApplicationFeeInvoiceDetail.Amount - changeApplicationFeeInvoiceDetail.AmtPaid;
            }

            return changeApplicationFeeInvoiceDetail;
        }

        var getAdditionalApplicationFeeInvoiceDetail = function () {
            var additionalApplicationFeeInvoiceDetail = {
                InvoiceDetailID: 0,
                InvoiceID: $$scope.payment.InvoiceId,
                ApplicationId: $scope.ApplicationId,
                TransType: "CE",
                Description: "Corporate Renewal Fee-Active",
                Quantity: $scope.payment.TotalNoOfAdditional,
                Amount: $scope.payment.FeeForAdditional,
                AmtBilled: $scope.payment.FeeForAdditional,
                AmtPaid: 0,
                AmtAdjusted: 0,
                AmtBalance: $scope.payment.FeeForAdditional,
                TransCode: "CREN-A",
                //Module: "ADVDUES",
                //DetailCode: "APPFEE",
                GPAdjust: 0,
                GPPayment: 0,
                CreatedBy: $scope.UserId,
                ProviderId: $scope.providerId,
                ReferenceNumber: $scope.providerLicense ? $scope.providerLicense.ProviderLicenseNumber : undefined
            };
            if ($scope.payment.PaymentMethod == 3) {
                additionalApplicationFeeInvoiceDetail.AmtPaid = additionalApplicationFeeInvoiceDetail.Amount;
                additionalApplicationFeeInvoiceDetail.AmtBalance = additionalApplicationFeeInvoiceDetail.Amount - additionalApplicationFeeInvoiceDetail.AmtPaid;
            }
            return additionalApplicationFeeInvoiceDetail;
        }
        var getLateApplicationFeeInvoiceDetail = function () {
            var lateApplicationFeeInvoiceDetail = {
                InvoiceDetailID: 0,
                InvoiceID: $scope.payment.InvoiceId,
                ProviderId: $scope.providerId,
                ApplicationId: $scope.ApplicationId,
                TransType: "CE",
                Description: (!!$scope.payment.feeDetails.lateFeeDetailDesc) ? $scope.payment.feeDetails.lateFeeDetailDesc : "Corporate Renewal Fee-Active",
                Quantity: 1,
                Amount: $scope.payment.feeDetails.lateFee,
                AmtBilled: $scope.payment.feeDetails.lateFee,
                AmtPaid: 0,
                AmtAdjusted: 0,
                AmtBalance: $scope.payment.feeDetails.lateFee,
                TransCode: (!!$scope.payment.feeDetails.lateFeeDetailCode) ? $scope.payment.feeDetails.lateFeeDetailCode : "LPFF",
                Module: (!!$scope.payment.feeDetails.Module) ? $scope.payment.feeDetails.Module : "ADVDUES",
                DetailCode: (!!$scope.payment.feeDetails.lateFeeFundingString) ? $scope.payment.feeDetails.lateFeeFundingString : "APPFEE",
                GPAdjust: 0,
                GPPayment: 0,
                CreatedBy: $scope.UserId,
                ReferenceNumber: $scope.providerLicense ? $scope.providerLicense.ProviderLicenseNumber : undefined
            };
            if ($scope.PaymentMethod == 3) {
                lateApplicationFeeInvoiceDetail.AmtPaid = lateApplicationFeeInvoiceDetail.Amount;
                lateApplicationFeeInvoiceDetail.AmtBalance = lateApplicationFeeInvoiceDetail.Amount - lateApplicationFeeInvoiceDetail.AmtPaid;
            }
            return lateApplicationFeeInvoiceDetail;
        };

        var getIndividualTransactionObject = function (revFeeMasterId, amount, transCode) {
            return {
                RevFeeMasterId: revFeeMasterId,
                DebitAmt: 0,
                CreditAmt: amount,
                FeePaidAmount: amount,
                OrigFeeAmount: amount,
                TransCode: transCode,
                ModuleCode: "ADVDUES",
                TransType: "PAY"
            }
        };

        var getMiscFeeInvoiceDetail = function (feeDetailRow) {
            var licenseEndorsementFeeInvoiceDetail = {
                InvoiceDetailID: 0,
                InvoiceID: $scope.payment.InvoiceId,
                ProviderId: $scope.providerId,
                ApplicationId: $scope.ApplicationId,
                TransType: 'ORD',
                Description: feeDetailRow.feeDetailType.FeeDetailDesc,
                Quantity: 1,
                Amount: feeDetailRow.Amount,
                AmtBilled: feeDetailRow.Amount,
                AmtPaid: 0,
                AmtAdjusted: 0,
                AmtBalance: feeDetailRow.Amount,
                TransCode: feeDetailRow.feeDetailType.FeeDetailCode,//'MISCEFEE',
                Module: 'MiscPay',
                DetailCode: feeDetailRow.feeDetailType.FundingString,
                ReferenceNumber: feeDetailRow.referenceNumber,
                GPAdjust: 0,
                GPPayment: 0,
                CreatedBy: $scope.userId
            };
            return licenseEndorsementFeeInvoiceDetail;
        };

        var getInvoiceDetailRowFormInvoiceDetails = function (transcode, invoiceDetailsData, associationType, associationId) {
            var invoiceDetail = null;
            if (!invoiceDetailsData) {
                return invoiceDetail;
            }
            var i;
            for (i = 0; i < invoiceDetailsData.length; i++) {
                if (invoiceDetailsData[i].TransCode == transcode) {
                    if (!!associationType && !!associationId) {
                        if (invoiceDetailsData[i].AssociationId == associationId && invoiceDetailsData[i].AssociationType == associationType) {
                            invoiceDetail = invoiceDetailsData[i];
                            break;
                        }
                    }
                    else {
                        invoiceDetail = invoiceDetailsData[i];
                        break;
                    }
                }
            }
            return invoiceDetail;
        };

        var processRevPayment = function (isConfirmPayment, transactionsData, isPaymentSubmitted, doNotSendPaymentReceipt, donotRequirePayment) {
            $scope.payment.isInitialLoadPayment = false;
            var paymentRequest = {
                VerifyExistingPayment: !!isPaymentSubmitted,
                DonotRequirePayment: donotRequirePayment,
                VerificationModule: $scope.module,
                SendLicenseOutput: false,
                ProviderPaymentRequests: (!!transactionsData ? [transactionsData] : null),
                AccountSelection: "",
                EntityExamIds: [],
                LicenseApplicationDetails: {
                    UpdateApplicationStatus: true,
                    ProviderId: $scope.providerId,
                    ApplicationId: $scope.applicationId,
                    ApprovalType: 'pendingApproval',
                    IsSubmission: true
                },
                PaymentAndSubmissionEmailDetail: {
                    EmailAddress: $scope.emailAddress,
                    ProviderId: $scope.providerId,
                    EntityType: "P",
                    ApplicationId: $scope.applicationId,
                    ProcessEmailAndApplicationPdf: true,
                    PhoneNumber: $scope.personalInfo.PhoneNumber,
                    TotalApplicationFee: $scope.feeDetails.totalFee,
                    ApplicationFees: $scope.feeDetails.applicationFee,
                    ReInstatementFee: $scope.payment.feeDetails.reInstatementFee,
                    AdditionalApplicationFees: 0,
                    ApplicationFeeType: !!$scope.providerLicense ? $scope.providerLicense.LicenseTypeName : null,
                    LateFee: $scope.feeDetails.lateFee,
                    IsConfirm: !!isConfirmPayment,
                    IsSendAttachment: false,
                    ActionFlag: ($scope.feeDetails.isNewLicEndApp ? 'isNewLicEndApp' : ''),
                    DoNotSendPaymentReceipt: $scope.payment.DoNotSendPaymentReceipt,
                    ProviderName: $scope.providerName,
                    //LastName: $scope.personalInfo.LastName,
                    IsMiscPayment: (!!$scope.isMiscPayment),
                    IsNewApplication: (!$scope.isRenewal),
                    PaymentHtmlProcessor: "",
                    LicenseNumber: $scope.providerLicense ? $scope.providerLicense.ProviderLicenseNumber : null,
                    LicenseTypeId: !!$scope.providerLicense ? $scope.providerLicense.ProviderLicenseTypeId : null,
                    QueueName: $scope.queueName
                },
                LicenseOutput: {
                    ProviderId: $scope.providerId,
                    EntityType: "P",
                    ApplicationId: $scope.applicationId,
                    Email: $scope.personalInfo.EmailAddress,
                    SendApprovalEmail: true
                },
                ProcessApplicationApproval: false,
                ApplicationApprovalRequest: [{
                    EntityId: $scope.providerId,
                    EntityType: "P",
                    ApplicationId: $scope.applicationId,
                    BoardInfoId: 1,
                    EventTrigger: "RenewalApproval",
                    GenerateDCALicenseTransferFile: false,
                    GenerateLicensePaymentFile: $scope.isRetiredOrDelinquent ? false : true,
                    ProviderLicenseId: !!$scope.providerLicense ? $scope.providerLicense.ProviderLicenseId : 0,
                    LicensePaymentFileRequest: {
                        EntityId: $scope.providerId,
                        EntityType: "P",
                        ApplicationId: $scope.applicationId,
                        IndicatorDContentItemId: 396,
                        IndicatorGHContentItemIds: "430",
                        IndicatorCContentItemId: 459,
                        ProviderLicenseId: !!$scope.providerLicense ? $scope.providerLicense.ProviderLicenseId : 0,
                        LicenseStatusTypeId: (!!$scope.providerLicense && ($scope.providerLicense.ProviderStatusTypeId == 7 || $scope.providerLicense.ProviderStatusTypeId == 8 || $scope.providerLicense.ProviderStatusTypeId == 5 || $scope.providerLicense.ProviderStatusTypeId == 1)) ? 1 : 4
                    }
                }]
            };

            getApprovalStatusData().then(function (responseAppData) {
                if (responseAppData.Status) {
                    paymentRequest.LicenseApplicationDetails = responseAppData.processingData;
                    paymentRequest.ApplicationApprovalRequest[0].LicensePaymentFileRequest.ApplicationStatusId = paymentRequest.LicenseApplicationDetails.ApplicationStatusId;
                    paymentRequest.ProcessApplicationApproval = (!!paymentRequest.LicenseApplicationDetails && !!paymentRequest.LicenseApplicationDetails.ApprovalType && (paymentRequest.LicenseApplicationDetails.ApprovalType == "approval" || paymentRequest.LicenseApplicationDetails.ApprovalType == "approvalRenewal"));
                    var htmlToPdfDataPromise = htmlToPdfGenerationService.processHtmlNewApplication($scope.additionalCss);
                    if ($scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 16) {
                        htmlToPdfDataPromise = htmlToPdfGenerationService.processHtml($scope.additionalCss);
                    }
                    if (!!$scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 2 && [16, 18].includes($scope.applicationInfo.ApplicationStatusId)) {
                        paymentRequest.LicenseApplicationDetails.ApprovalType = 'examApplicationSubmission';
                        paymentRequest.LicenseApplicationDetails.UpdateApplicationStatus = true;
                        paymentRequest.LicenseApplicationDetails.ApplicationStatusId = $scope.applicationInfo.ApplicationStatusId;
                        paymentRequest.LicenseApplicationDetails.LogApplicationStatusIds = $scope.applicationInfo.ApplicationStatusId;
                        paymentRequest.LicenseApplicationDetails.UpdateBackgroundCheckStatus = false;
                        paymentRequest.LicenseApplicationDetails.IsSubmission = false;
                        paymentRequest.PaymentAndSubmissionEmailDetail.DoNotSendAppSubmissionEmail = true;
                        if (!!$scope.feeDetailRows && $scope.feeDetailRows.length > 0) {
                            paymentRequest.EntityExamIds = $scope.feeDetailRows
                                .whereEquals('EntityExam', 'TableName')
                                .whereEquals('EntityExamId', 'TableLinkIdColumnName')
                                .whereEquals(true, 'isExamRow')
                                .whereEquals(true, 'needsProcessing')
                                .select('TableLinkId');
                            paymentRequest.PaymentAndSubmissionEmailDetail.InvoiceDetailFilter = {
                                LoadInvoiceByTableLinkId: true,
                                TableName: "EntityExam",
                                TableLinkIdColumnName: "EntityExamId",
                                TableLinkIds: paymentRequest.EntityExamIds
                            };
                        }
                    }
                    htmlToPdfDataPromise.then(function (response) {
                        if ($scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 16) {
                            paymentRequest.PaymentAndSubmissionEmailDetail.TabHtml = [{
                                ApplicationID: $scope.applicationId,
                                ProviderID: $scope.providerId,
                                TabName: 'IntakeQ-RenewalHtml',
                                CreatedBy: $scope.userId,
                                DataDesc: "RenewalHtml",
                                HTMLContent: response,
                                SortingOrder: 1
                            }];
                        } else {
                            var ceSummaryElem =  angular.element(".ce-summary-tbl");
                            angular.element(ceSummaryElem).removeClass("ng-hide");
                            var ceDetailsElem =  angular.element(".ce-details-tbl");
                            angular.element(ceDetailsElem).addClass("ng-hide");

                            var personalHtml = angular.element('#tabSectionfirmData').parent();
                            var renewalInfoHtml = angular.element('#tabSectionpeerReviewInfo').parent();
                            var CEHtml = angular.element('#tabSectionshareholderInfo').parent();
                            var acknowledgmentAndDeclarationHtml = angular.element('#tabSectionacknowledgmentAndDeclaration').parent();
                            var paymentHtml = angular.element('#tabSectionfeeAndPayment').parent();

                            var personalHtmlContent = angular.element(personalHtml).html();
                            if (!!personalHtmlContent)
                                personalHtmlContent = personalHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');

                            var renewalInfoHtmlContent = angular.element(renewalInfoHtml).html();
                            if (!!renewalInfoHtmlContent)
                                renewalInfoHtmlContent = renewalInfoHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');

                            var CEHtmlContent = angular.element(CEHtml).html();
                            if (!!CEHtmlContent)
                                CEHtmlContent = CEHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');

                            var acknowledgmentAndDeclarationHtmlContent = angular.element(acknowledgmentAndDeclarationHtml).html();
                            if (!!acknowledgmentAndDeclarationHtmlContent)
                                acknowledgmentAndDeclarationHtmlContent = acknowledgmentAndDeclarationHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');

                            var paymentHtmlContent = angular.element(paymentHtml).html();
                            if (!!paymentHtmlContent)
                                paymentHtmlContent = paymentHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');

                            if (!isConfirmPayment) {
                                paymentRequest.PaymentAndSubmissionEmailDetail.TabHtml = [{
                                    ApplicationID: $scope.applicationId,
                                    ProviderID: $scope.providerId,
                                    TabName: 'IntakeQ-NewApplicationOuterPartHtml',
                                    CreatedBy: $scope.userId,
                                    DataDesc: "New Application Outer Part Html for All Tabs including the css and all sections",
                                    HTMLContent: response,
                                    SortingOrder: 0
                                }, {
                                    ApplicationID: $scope.applicationId,
                                    ProviderID: $scope.providerId,
                                    TabName: 'IntakeQ-personal',
                                    CreatedBy: $scope.userId,
                                    DataDesc: "New Application section personal Html",
                                    SortingOrder: 100,
                                    HTMLContent: personalHtmlContent
                                }, {
                                    ApplicationID: $scope.applicationId,
                                    ProviderID: $scope.providerId,
                                    TabName: 'IntakeQ-renewalInfo',
                                    CreatedBy: $scope.userId,
                                    DataDesc: "New Application section renewalInfo Html",
                                    HTMLContent: renewalInfoHtmlContent,
                                    SortingOrder: 200
                                }, {
                                    ApplicationID: $scope.applicationId,
                                    ProviderID: $scope.providerId,
                                    TabName: 'IntakeQ-CE',
                                    CreatedBy: $scope.userId,
                                    DataDesc: "New Application section CE Html",
                                    HTMLContent: CEHtmlContent,
                                    SortingOrder: 300
                                }, {
                                    ApplicationID: $scope.applicationId,
                                    ProviderID: $scope.providerId,
                                    TabName: 'IntakeQ-acknowledgmentAndDeclaration',
                                    CreatedBy: $scope.userId,
                                    DataDesc: "New Application section acknowledgmentAndDeclaration Html",
                                    HTMLContent: acknowledgmentAndDeclarationHtmlContent,
                                    SortingOrder: 400
                                }, {
                                    ApplicationID: $scope.applicationId,
                                    ProviderID: $scope.providerId,
                                    TabName: 'IntakeQ-feeAndPayment',
                                    CreatedBy: $scope.userId,
                                    DataDesc: "New Application section feeAndPayment Html",
                                    HTMLContent: paymentHtmlContent,
                                    SortingOrder: 500
                                }];
                            }

                        }

                        initialProviderApplicationFeePaymentService.providerProcessPayment(sessionStorage.Key, paymentRequest)
                            .then(function (objTransaction) {
                                if (objTransaction.StatusCode != "00") {
                                    if (!objTransaction.PaymentStatus) {
                                        if ($scope.isRenewal)
                                            initialApplicationstatusService.setPaymetStatus('error');
                                        return showErrorMessage(Messages.Payment.Error);
                                    }
                                }
                                let isOk = false;
                                if ($scope.isRetiredOrDelinquent) {
                                    let requestData = {
                                        isn: $scope.individualLicense.IDNumber,
                                        IndividualLicenseId:  $scope.individualLicense.IndividualLicenseId,
                                        isAbandoned: 0,
                                        ProviderId: $scope.providerId,
                                        isConviction: $scope.isConviction
                                    };
                    
                                    let renewalProcessStatusResponseRetiredOrDelinquent = dcaIService.updateRenewalProcessStatusForRetired(sessionStorage.Key, requestData);
                    
                                    isOk = (!!renewalProcessStatusResponseRetiredOrDelinquent && !!renewalProcessStatusResponseRetiredOrDelinquent.Status);
                                }
                                if ($scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 2
                                    && [16, 18].indexOf($scope.applicationInfo.ApplicationStatusId) == -1
                                ) {
                                    if (!!$scope.individualLicense && !!$scope.individualLicense.licenseTypeInfo && !!$scope.individualLicense.licenseTypeInfo.ExamRequired) {
                                        $scope.uiLogic.sendExamEmail = true;
                                    }
                                }
                                $scope.payment.PaymentEmailHtml = objTransaction.PaymentEmailHtml;
                                var ProviderPaymentAuthResponse = null;
                                if (!!objTransaction.ProviderPaymentAuthResponse && objTransaction.ProviderPaymentAuthResponse.length > 0) {
                                    ProviderPaymentAuthResponse = objTransaction.ProviderPaymentAuthResponse[0];
                                }

                                $scope.payment.InvoiceDatePayment = (!!ProviderPaymentAuthResponse ? ProviderPaymentAuthResponse.TransDateTime : null);
                                $scope.payment.IsPaymentInitiated = true;
                                makeFormReadOnly(true);
                                $scope.payment.IsPaymentSubmitted = true;

                                if (!!$scope.currentRenewalItem)
                                    $scope.currentRenewalItem.IsPaymentProcessed = true;

                                if (!$scope.isBackoffice) {
                                    showSuccessMessage('Thank you for using online process. You will receive a confirmation and receipt via email. If it does not arrive in your Inbox, please check your Spam/Junk folder. Please make sure to submit your application if not already submitted.');
                                }
                                initialApplicationstatusService.setPaymetStatus('success', paymentRequest.LicenseApplicationDetails.ApplicationStatusId);
                                if (paymentRequest.LicenseApplicationDetails.ApplicationStatusId == 3) {
                                    // $rootScope.$broadcast('changeCourseStatus', { courseStatusId: 2, applicationId: $scope.applicationId });
                                }
                                $scope.$emit('paymentProviderSuccess', {
                                    applicationId: $scope.applicationId,
                                    statusId: paymentRequest.LicenseApplicationDetails.ApplicationStatusId
                                });
                                if (!!$scope.isMiscPayment) {
                                    $scope.paymentStatusUpdate({
                                        action: 'success'
                                    });
                                }
                                // $scope.openNextTab({
                                //     currentTab: $scope.tab
                                // });
                                makeFormReadOnly(true);
                            }, function (response) {
                                initialApplicationstatusService.setPaymetStatus('error');
                                return showErrorMessage(response);
                            });
                    });
                }
            })

        };

        $scope.pciRedirecting = function () {
            if (!!$scope.openNextTab) {
                $scope.openNextTab({ currentTab: $scope.tab });
            }
        };


        function AuditVisitInfoSave() {
            var data = {
                "PageName": $scope.feePaymentConfig.Content.TabHeading,
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.individualId,
                "EntityId": 0,
                "UserId": sessionStorage.userId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };

        var saveApplicationStatus = function (ApplicationId, applicationStatus, hideLoader) {
            bppService.applicationStatusUpdate(sessionStorage.Key, $scope.applicationInfo.ApplicationId, 2, 'O')
                .then(function (response) {
                    if (!!$scope.currentRenewalItem) {
                        $scope.currentRenewalItem.ApplicationStatusId = applicationStatus;
                    }
                    if (!!$scope.isBackoffice && (applicationStatus == 2 || applicationStatus == 13 || applicationStatus == 15)) {
                        if (!!$scope.currentRenewalItem) {
                            $scope.currentRenewalItem.disableRenewalForm = true;
                            angular.forEach($scope.applicationStatusList, function (appStatusItem, key) {
                                if (appStatusItem.ApplicationStatusId == applicationStatus) {
                                    $scope.currentRenewalItem.StatusName = appStatusItem.Name;
                                }
                            });
                        }
                    }
                    if (hideLoader == undefined || hideLoader == true)
                        HideLoader();
                }, function (data) {
                    HideLoader();
                    $scope.isSavedSuccessFully = false;
                });
        };

        var saveApplicationStatusLk = function (applicationId, applicationStatus) {
            var reqData = {
                ApplicationId: applicationId,
                ApplicationTypeId: 1,
                ApplicationStatusId: applicationStatus,
                ApplicationStatusReasonId: 0,
                IsActive: true,
                IsDeleted: false,
                IsArchive: false,
                CreatedBy: $scope.UserId,
                ApplicationInternalStatusReasonText: '',
                ApplicationInternalStatusId: 0,
                HighPriority: false
            };
            initialApplicationstatusService.saveApplicationStatusLk(sessionStorage.Key, $scope.UserId, reqData)
                .then(function (response) {

                }, function (data) {
                    return showErrorMessage(data);
                });
        };

        var saveApplicationStatusChangeLog = function (applicationId, applicationStatus) {
            var reqData = {
                ApplicationId: applicationId,
                ApplicationStatusReasonId: 0,
                ApplicationNumber: '',
                ApplicationTypeId: 1,
                ApplicationStatusId: applicationStatus,
                ApplicationSubmitMode: ($scope.isBackoffice ? 'B' : 'O'),
                ApplicationStatusChangedBy: $scope.UserId,
                ReferenceNumber: '',
                LicenseTypeId: 0,
                HighPriority: false,
                ApplicationInternalStatusId: 0,
                ApplicationInternalStatusReasonText: ''
            };
            initialApplicationstatusService.saveApplicationStatusChangeLog(sessionStorage.Key, $scope.UserId, reqData)
                .then(function (response) {

                }, function (data) {
                    return showErrorMessage(data);
                });
        };

        var insertInvoiceDetails = function (invoiceDetails, transaction, IsConfirmPayment) {
            initialProviderApplicationFeePaymentService.invoiceDetailSave(sessionStorage.Key, invoiceDetails)
                .then(function (objInvoiceDetails) {
                    if (objInvoiceDetails.StatusCode != "00") {
                        initialApplicationstatusService.setPaymetStatus('error');
                        return showErrorMessage(objInvoiceDetails.Message);
                    }
                    processRevPayment(IsConfirmPayment, transaction);
                }, function (response) {
                    initialApplicationstatusService.setPaymetStatus('error');
                    return showErrorMessage(response);
                });
        };
        $scope.setForm = function (currentForm) {
            $scope.currentForm = currentForm;
        };

        var getApprovalStatusData = function () {
            var deffer = $q.defer();
            var processingData = {
                ApprovalType: 'pendingApproval',
                ApplicationStatusId: 27,
                LogApplicationStatusIds: '2,27',
                ApplicationReviewReasonIds: "",
                UpdateApplicationStatus: true,
                ProviderId: $scope.providerId,
                ApplicationId: $scope.applicationId,
                IsSubmission: true,
                ProcessDeficiency: false
            };

            if (!!$scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 1) {
                var warningExists = false;
                if (!!$scope.renewalCheckData && $scope.renewalCheckData.IndividualLicenseList && $scope.renewalCheckData.IndividualLicenseList.length > 0) {
                    warningExists = $scope.renewalCheckData.IndividualLicenseList.any('item.WarningFlag');
                }
                //$rootScope.submissionLicenseStatusTypeId = 1;
                if ((!!$rootScope.submissionLicenseStatusTypeId && $rootScope.submissionLicenseStatusTypeId == 8) || (!!$rootScope.renewalInfoLicenseStatusTypeId && $rootScope.renewalInfoLicenseStatusTypeId == 8)) {
                    processingData.ApprovalType = 'pendingApproval';
                    if ($scope.isRetiredOrDelinquent){
                        $scope.isConviction = 1;
                    }
                    else {
                        $scope.isConviction = 0;
                    }

                    processingData.ApplicationStatusId = 8;
                    processingData.LogApplicationStatusIds = '8';
                } else {
                    processingData.ApprovalType = $scope.isRenewal ? 'approvalRenewal' : 'approval';
                    processingData.ApplicationStatusId = 3;
                    processingData.LogApplicationStatusIds = '2,3';
                }
            } else {
                processingData.UpdateBackgroundCheckStatus = true;
                processingData.BackgroundCheckStatusId = 1;
            }

            deffer.resolve({
                Status: true,
                processingData: processingData
            });
            return deffer.promise;
        };

        var validateForm = function (currentForm) {
            $('#approvedCivilApp').text('');
            $('#approvedCivilAppSuccess').text('');
            currentForm.$submitted = true;
            $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
            if (!$scope.payment.CheckMailing && ($scope.payment.PaymentMethod == 1 ||
                $scope.payment.PaymentMethod == 2 ||
                $scope.payment.PaymentMethod == 4 ||
                $scope.payment.PaymentMethod == 5
            ) && !$scope.isBackoffice) {
                // $scope.natValidation.errorMessages.push({
                //     propName: "CheckMailingPaymentOption",
                //     errorMessage: Messages.bbfeaff
                // });
            }
            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }

        };

        var getNewApplicationFeeInvoiceDetail = function (feeDetail, moduleName, descPrefix) {
            var applicationFeeInvoiceDetail = {
                InvoiceDetailID: 0,
                InvoiceID: $scope.payment.InvoiceId,
                ProviderId: $scope.providerId,
                ApplicationId: $scope.applicationId,
                TransType: "ORD",
                Description: ((!!descPrefix ? (descPrefix + "-") : "") + feeDetail.FeeDetailDesc + ((!!feeDetail.LicenseNumber) ? ("-" + feeDetail.LicenseNumber) : "")),
                Quantity: 1,
                Amount: feeDetail.FeeAmount,
                AmtBilled: feeDetail.FeeAmount,
                AmtPaid: 0,
                AmtAdjusted: 0,
                AmtBalance: feeDetail.FeeAmount,
                TransCode: feeDetail.FeeTypeCode,
                Module: moduleName,
                DetailCode: feeDetail.FeeDetailCode,
                ReferenceNumber: ((!!feeDetail.FeeTypeCode ? feeDetail.FeeTypeCode : '') + '-' + (!!feeDetail.FeeDetailCode ? feeDetail.FeeDetailCode : '')),
                GPAdjust: 0,
                GPPayment: 0,
                CreatedBy: $scope.userId,
                CredentialNumber: ((!!feeDetail.LicenseNumber) ? feeDetail.LicenseNumber : ""),
                TableName: feeDetail.TableName,
                TableLinkIdColumnName: feeDetail.TableLinkIdColumnName,
                TableLinkId: feeDetail.TableLinkId
            };
            if ($scope.payment.PaymentMethod == 3) {
                applicationFeeInvoiceDetail.AmtPaid = applicationFeeInvoiceDetail.Amount;
                applicationFeeInvoiceDetail.AmtBalance = applicationFeeInvoiceDetail.Amount - applicationFeeInvoiceDetail.AmtPaid;
            }
            return applicationFeeInvoiceDetail;
        };

        $scope.processApplicationSubmission = function (currentForm, doNotSendPaymentReceipt, donotRequirePayment) {
            ShowLoader();
            processRevPayment(false, null, true, doNotSendPaymentReceipt, donotRequirePayment);
        };

        $scope.processPayment = function (currentForm, IsConfirmPayment) {

            $rootScope.SelectedPaymentMethod = $scope.payment.PaymentMethod;
            IsConfirmPayment = !!IsConfirmPayment;
            ShowLoader();
            initialApplicationstatusService.setPaymetStatus('waiting');
            if (!validateForm(currentForm)) {
                initialApplicationstatusService.setPaymetStatus('invalid');
                HideLoader();
                return;
            }

            if (!!$scope.useAltPaymentMethod && $scope.payment.PaymentMethodSelected == 3) {
                $scope.$broadcast('beginPCICompliantPayment');
                return;
            }

            var transaction = {};
            transaction.GenerateNewReceiptNumber = true;
            transaction.ApplicationId = $scope.ApplicationId;
            transaction.ProviderId = $scope.providerId;
            transaction.EntityType = "P";
            transaction.AmountDue = $scope.payment.feeDetails.totalFee;
            transaction.TotalPaidAmount = $scope.payment.feeDetails.totalFee;
            transaction.InvoiceNo = $scope.payment.InvoiceId;
            transaction.UserDefinedPaymentNo = "";
            transaction.TransCode = "";
            transaction.EmailAddress = $scope.personalData.EmailAddressContact ? $scope.personalData.EmailAddressContact.ContactInfo : $rootScope.EmailAddressContact; //$scope.personalDat.EmailAddressContact.ContactInfo;

            if (!$scope.isBackoffice) {
                transaction.PaymentSource = "OL";
                if ($scope.payment.PaymentMethod == 1 ||
                    $scope.payment.PaymentMethod == 2 ||
                    $scope.payment.PaymentMethod == 4 ||
                    $scope.payment.PaymentMethod == 5) {
                    transaction.RequiresApproval = true;
                    transaction.MailingPayment = $scope.payment.CheckMailing;
                } else if ($scope.payment.PaymentMethod == 3) {
                    transaction.RequiresApproval = false;
                    transaction.MailingPayment = false;
                }
            } else {
                if (IsConfirmPayment) {
                    transaction.PaymentSource = "BC";
                } else {
                    transaction.PaymentSource = "BO";
                }
                if ($scope.payment.PaymentMethod == 1 ||
                    $scope.payment.PaymentMethod == 2 ||
                    $scope.payment.PaymentMethod == 4 ||
                    $scope.payment.PaymentMethod == 5) {
                    transaction.MailingPayment = $scope.payment.CheckMailing;
                    transaction.RequiresApproval = $scope.payment.CheckMailing;
                } else if ($scope.payment.PaymentMethod == 3) {
                    transaction.RequiresApproval = false;
                    transaction.MailingPayment = false;
                }
            }

            if ($scope.payment.PaymentMethod == 1 ||
                $scope.payment.PaymentMethod == 2 ||
                $scope.payment.PaymentMethod == 4 ||
                $scope.payment.PaymentMethod == 5) {
                if ($scope.payment.PaymentMethod == 1) {
                    transaction.PaymentMode = "CK";
                    transaction.TransCode = "CK";
                } else if ($scope.payment.PaymentMethod == 2) {
                    transaction.PaymentMode = "MO";
                    transaction.TransCode = "MO";
                } else if ($scope.payment.PaymentMethod == 4) {
                    transaction.PaymentMode = "PC";
                    transaction.TransCode = "PC";
                } else if ($scope.payment.PaymentMethod == 5) {
                    transaction.PaymentMode = "CA";
                    transaction.TransCode = "CA";
                }
                transaction.PaymentModeNumber = $scope.payment.CheckNo;
                transaction.Comment = $scope.payment.CheckComment;
                if (!IsConfirmPayment)
                    transaction.ElectronicPayment = false;
                else
                    transaction.ElectronicPayment = true;
                transaction.ManualPayment = true;
            } else if ($scope.payment.PaymentMethod == 3) {
                transaction.PaymentMode = "CC";
                transaction.PaymentModeNumber = "";
                transaction.CardType = detectCardType($scope.payment.CardNumber);
                transaction.CardNumber = $scope.payment.CardNumber;
                transaction.ExpirationMonths = $scope.payment.ExpirationMonths;
                transaction.ExpirationYears = $scope.payment.ExpirationYears;
                transaction.CVV = $scope.payment.CVV;
                transaction.FirstName = $scope.payment.FirstName;
                transaction.LastName = $scope.payment.LastName;
                transaction.AddressStreetLine1 = $scope.payment.Address;
                transaction.AddressStreetLine2 = $scope.payment.StreetLine2;
                transaction.AddressCity = $scope.payment.City;
                transaction.AddressStateCode = $scope.payment.StateCode;
                transaction.AddressState = angular.element("#ddlState option[value='" + $scope.payment.StateCode + "']").text();
                transaction.AddressZip = $scope.payment.Zip;
                transaction.AddressCountry = $scope.payment.Country;
                transaction.PhoneNumber = $scope.personalData.CellPhoneContact ? $scope.personalData.CellPhoneContact.ContactInfo : ''; //vm.appData.Applicant.PrimaryPhone.ContactInfo; //LeftPArt
                transaction.PaymentInitiateSubmitMode = "OL";
                transaction.PaymentCompletionSubmitMode = "OL";
                transaction.ElectronicPayment = true;
                transaction.ManualPayment = false;
                transaction.TransCode = transaction.CardType;
            }

            transaction.ProviderTransactionObject = [];
            var invoiceDetails = [];
            /////Invoice Detail Save
            initialProviderApplicationFeePaymentService.getInvoiceDetailByIndividualIdAndInvoiceId(sessionStorage.Key, $scope.providerId, $scope.payment.InvoiceId)
                .then(function (objInvoiceDetail) {
                    console.log(objInvoiceDetail);
                    if (objInvoiceDetail.StatusCode != "00") {
                        initialApplicationstatusService.setPaymetStatus('error');
                        return showErrorMessage(objInvoiceDetail.Message);
                    }
                    invoiceDetailsData = objInvoiceDetail.EntityRecords;
                    if (!!$scope.isMiscPayment) {

                        angular.forEach($scope.feeDetailRows, function (feeDetailRow, index) {
                            var miscFeeInvoiceDetail = getInvoiceDetailRowFormInvoiceDetails(
                                feeDetailRow.feeDetailType, invoiceDetailsData);
                            if (!miscFeeInvoiceDetail) {
                                miscFeeInvoiceDetail = getMiscFeeInvoiceDetail(feeDetailRow);
                                invoiceDetails.push(miscFeeInvoiceDetail);
                            } else if (!!miscFeeInvoiceDetail) {
                                miscFeeInvoiceDetail.ModifiedBy = $scope.userId;
                                miscFeeInvoiceDetail.Amount = feeDetailRow.Amount;
                                miscFeeInvoiceDetail.AmtBilled = feeDetailRow.Amount;
                                miscFeeInvoiceDetail.AmtPaid = 0;
                                miscFeeInvoiceDetail.AmtBalance = feeDetailRow.Amount;
                                invoiceDetails.push(miscFeeInvoiceDetail);
                            }
                            transaction.ProviderTransactionObject
                                .push(getIndividualTransactionObject(4, miscFeeInvoiceDetail.Amount, transaction.TransCode));
                        });
                    }
                    else {
                        if (!!$scope.feeDetailRows && !!$scope.applicationInfo) {
                            $scope.feeDetailRows.forEach(function (fdr) {
                                var feeInvoiceDetail = getInvoiceDetailRowFormInvoiceDetails(fdr, invoiceDetailsData, true);
                                if (!feeInvoiceDetail) {
                                    feeInvoiceDetail = getNewApplicationFeeInvoiceDetail(fdr,
                                        ($scope.applicationInfo.ApplicationTypeId == 1 ? "RenewalApp" : ($scope.applicationInfo.ApplicationTypeId == 2 ? "NewApplication" : "")),
                                        ($scope.applicationInfo.ApplicationTypeId == 1 ? "RenApp" : ($scope.applicationInfo.ApplicationTypeId == 2 ? ($scope.module == "APPLICATION" ? "NewApp" : "") : ""))
                                    );
                                    invoiceDetails.push(feeInvoiceDetail);
                                } else if (!!feeInvoiceDetail) {
                                    feeInvoiceDetail.ModifiedBy = $scope.userId;
                                    feeInvoiceDetail.Amount = fdr.FeeAmount;
                                    feeInvoiceDetail.AmtBilled = fdr.FeeAmount;
                                    feeInvoiceDetail.CredentialNumber = fdr.LicenseNumber;
                                    feeInvoiceDetail.AmtPaid = 0;
                                    feeInvoiceDetail.AmtBalance = 0;
                                    feeInvoiceDetail.TableName = fdr.TableName;
                                    feeInvoiceDetail.TableLinkIdColumnName = fdr.TableLinkIdColumnName;
                                    feeInvoiceDetail.TableLinkId = fdr.TableLinkId;
                                    if ($scope.payment.PaymentMethod == 3) {
                                        feeInvoiceDetail.AmtPaid = feeInvoiceDetail.Amount;
                                        feeInvoiceDetail.AmtBalance = feeInvoiceDetail.Amount - feeInvoiceDetail.AmtPaid;
                                    }
                                    invoiceDetails.push(feeInvoiceDetail);
                                }
                                transaction.ProviderTransactionObject
                                    .push(getIndividualTransactionObject(6, feeInvoiceDetail == null ? 0 : feeInvoiceDetail.Amount, transaction.TransCode));
                            });
                        }
                        angular.forEach(invoiceDetailsData, function (invoiceDetailsItem) {
                            invoiceDetailsItem.IsDeleted = true;
                            invoiceDetails.push(invoiceDetailsItem);
                        });

                    }
                    if (!$scope.isMiscPayment) {
                        insertInvoiceDetails(invoiceDetails, transaction, IsConfirmPayment);
                    } else {
                        processRevPayment(transaction, IsConfirmPayment)
                    }
                }, function (response) {
                    initialApplicationstatusService.setPaymetStatus('error');
                    return showErrorMessage(response);
                });

            //////////////Invoice Detail Save
        };

        // $scope.CopyAddressFromApplication = function () {
        //     if ($scope.payment.PaymentCopyAddress) {
        //         var mailingAddress;
        //         if ($scope.ProviderAdditionalAddressList) {
        //             mailingAddress = $scope.ProviderAdditionalAddressList[0];
        //         }
        //         if (!!mailingAddress) {
        //             $scope.payment.Address = mailingAddress.StreetLine1;
        //             $scope.payment.StreetLine2 = mailingAddress.StreetLine2;
        //             $scope.payment.City = mailingAddress.City;
        //             $scope.payment.StateCode = mailingAddress.StateCode;
        //             $scope.payment.Zip = mailingAddress.Zip;
        //             if (mailingAddress.CountryId != $rootScope.DefaultCountryId) {
        //                 $scope.payment.StateCode = '00';
        //             }
        //         }
        //         $scope.payment.FirstName = $scope.personalInfo.FirstName;
        //         $scope.payment.LastName = $scope.personalInfo.LastName;
        //     } else {
        //         $scope.payment.Address = '';
        //         $scope.payment.StreetLine2 = '';
        //         $scope.payment.City = '';
        //         $scope.payment.StateCode = '-1';
        //         $scope.payment.Zip = '';
        //         $scope.payment.FirstName = '';
        //         $scope.payment.LastName = '';
        //     }
        // };

        $scope.getContentByLkItemId = function () {
            contentService.getContentByContentItemLkIdList([142, 1569], sessionStorage.Key)
                .then(function (response) {
                    var contentArray = response.ContentItemLk;
                    if (contentArray) {
                        for (var i = 0; i < contentArray.length; i++) {
                            switch (contentArray[i].ContentItemLkId) {
                                case 142:
                                    $scope.content.content142 = contentArray[i].ContentItemLkDesc;
                                    break;
                                case 1569:
                                    $scope.content.content1569 = contentArray[i].ContentItemLkDesc;
                                    break;
                            }
                        }
                    }
                }, function (error) {

                });
        };

        $scope.changeOverridePaymentAmount = function () {
            if ($scope.payment.feeDetails.isOverridePaymentAmount == true) {
                $scope.payment.feeDetails.applicationFeeOverride = $scope.payment.feeDetails.applicationFee;
            } else if ($scope.payment.feeDetails.isOverridePaymentAmount == false) {
                if (!!$scope.payment.feeDetails.applicationFeeOverride) {
                    $scope.payment.feeDetails.applicationFee = $scope.payment.feeDetails.applicationFeeOverride;
                }
            }
        };

        $scope.loadReceiptDetail = function (invoiceModule) {
            var deffer = $q.defer();
            var reqData = {
                ProviderId: $scope.providerId,
                ApplicationId: $scope.applicationId
            };
            var dataPromises = {
                invoiceMaster: initialProviderApplicationFeePaymentService.getInvoiceMaster(sessionStorage.Key, reqData),
                revFeeCollect: initialProviderApplicationFeePaymentService.getRevFeeCollect(sessionStorage.Key, reqData),
                invoiceDetail: initialProviderApplicationFeePaymentService.getInvoiceDetail(sessionStorage.Key, reqData)
            };

            $q.all(dataPromises)
                .then(function (response) {
                    for (var prop in response) {
                        if (!response[prop].Status) {
                            HideLoader();
                            $scope.showFeepaymentstatusMsg('-', response[prop].Message);
                            deffer.resolve({
                                Status: false
                            });
                            return;
                        }
                    }
                    $scope.allInvoiceDetails = response.invoiceDetail.EntityRecords;
                    $scope.payment.receiptsData = {};
                    if (!!response.invoiceMaster && !!response.invoiceMaster.EntityRecords && response.invoiceMaster.EntityRecords.length > 0) {
                        $scope.payment.receiptsData.InvoiceMasters = response.invoiceMaster.EntityRecords.where(function (item) {
                            return (!item.InvoiceTransactionType || item.InvoiceTransactionType == 'TT' || item.InvoiceTransactionType == 'ET');
                        });
                        if (!!$scope.payment.receiptsData.InvoiceMasters && $scope.payment.receiptsData.InvoiceMasters.length > 0) {
                            angular.forEach($scope.payment.receiptsData.InvoiceMasters, function (invoiceMasterItem) {
                                if (response.invoiceDetail && response.invoiceDetail.EntityRecords && response.invoiceDetail.EntityRecords.length > 0) {
                                    invoiceMasterItem.InvoiceDetails = response.invoiceDetail.EntityRecords.whereEquals(invoiceMasterItem.InvoiceID, 'InvoiceID').whereNotEquals('CSHR', 'DetailCode');
                                }
                                if (response.revFeeCollect && response.revFeeCollect.EntityRecords && response.revFeeCollect.EntityRecords.length > 0) {
                                    invoiceMasterItem.RevFeeCollect = response.revFeeCollect.EntityRecords.whereEquals(invoiceMasterItem.InvoiceID, 'InvoiceNo').firstOrDefault();
                                }
                            });
                        }
                        var invoiceModulesToVerify = $scope.feeDetailRows.select('ModuleName').distinct();
                        $scope.payment.receiptsData.invoiceMastersByModule = $scope.payment.receiptsData.InvoiceMasters.whereIncludes(invoiceModulesToVerify, 'Module');
                        if (!!$scope.payment.receiptsData.invoiceMastersByModule && $scope.payment.receiptsData.invoiceMastersByModule.length > 0) {
                            $scope.payment.receiptsData.openInvoiceMastersByModule = $scope.payment.receiptsData.invoiceMastersByModule.whereEquals($scope.module, 'Module').whereEquals('OPEN', 'InvoiceStatus');
                            $scope.payment.receiptsData.closedInvoiceMastersByModule = $scope.payment.receiptsData.invoiceMastersByModule.whereEquals('CLOSED', 'InvoiceStatus');
                            $scope.payment.receiptsData.closedInvoiceExists = (!!$scope.payment.receiptsData.closedInvoiceMastersByModule && $scope.payment.receiptsData.closedInvoiceMastersByModule.length > 0);
                            $scope.payment.receiptsData.openInvoiceExists = (!!$scope.payment.receiptsData.openInvoiceMastersByModule && $scope.payment.receiptsData.openInvoiceMastersByModule.length > 0);
                            if ($scope.payment.receiptsData.closedInvoiceExists) {
                                var effectiveApplicationTotalAmount = 0;
                                var effectiveApplicationAmountByFeeCode = {};
                                angular.forEach($scope.payment.receiptsData.closedInvoiceMastersByModule, function (invoiceMasterItem) {
                                    angular.forEach(invoiceMasterItem.InvoiceDetails, function (invoiceDetailItem) {
                                        effectiveApplicationTotalAmount = effectiveApplicationTotalAmount + (invoiceDetailItem.Amount - (!invoiceDetailItem.ReducedAmount ? 0 : invoiceDetailItem.ReducedAmount))
                                        effectiveApplicationAmountByFeeCode[invoiceMasterItem.Module + '-' + invoiceDetailItem.DetailCode] = (!!effectiveApplicationAmountByFeeCode[invoiceMasterItem.Module + '-' + invoiceDetailItem.DetailCode] ? effectiveApplicationAmountByFeeCode[invoiceMasterItem.Module + '-' + invoiceDetailItem.DetailCode] : 0) + (invoiceDetailItem.Amount - (!invoiceDetailItem.ReducedAmount ? 0 : invoiceDetailItem.ReducedAmount))
                                    });
                                });
                                $scope.payment.receiptsData.effectiveApplicationTotalAmount = effectiveApplicationTotalAmount;
                                var sufficientTotalExistsByAccountCodes = true;
                                var requiredApplicationAmountByFeeCode = {};
                                angular.forEach($scope.feeDetailRows, function (feeDetailRow) {
                                    requiredApplicationAmountByFeeCode[feeDetailRow.ModuleName + '-' + feeDetailRow.FeeDetailCode] = (!!requiredApplicationAmountByFeeCode[feeDetailRow.ModuleName + '-' + feeDetailRow.FeeDetailCode] ? requiredApplicationAmountByFeeCode[feeDetailRow.ModuleName + '-' + feeDetailRow.FeeDetailCode] : 0) + feeDetailRow.FeeAmount
                                });
                                for (var prop in requiredApplicationAmountByFeeCode) {
                                    if (!effectiveApplicationAmountByFeeCode[prop] || effectiveApplicationAmountByFeeCode[prop] < requiredApplicationAmountByFeeCode[prop]) {
                                        sufficientTotalExistsByAccountCodes = false;
                                    }
                                }
                                $scope.payment.receiptsData.sufficientTotalExistsByAccountCodes = sufficientTotalExistsByAccountCodes;
                            }
                        }
                    }
                    $scope.payment.receiptsData.createNewInvoiceMaster = ((!$scope.payment.receiptsData.closedInvoiceExists || $scope.payment.receiptsData.effectiveApplicationTotalAmount == 0) && !$scope.payment.receiptsData.openInvoiceExists);
                    deffer.resolve({
                        Status: true
                    });
                }, function (error) {
                    deffer.resolve({
                        Status: false
                    });
                    HideLoader();
                    $scope.showFeepaymentstatusMsg('-', error.message);
                });
            return deffer.promise;
        };

        $scope.getPaymentMethod = function (paymentMode) {
            var allPaymentMethods = [{
                paymentMethodId: '-1',
                Name: "Select",
                isElectronic: false,
                showOnline: true,
                showBackoffice: true
            }, {
                paymentMethodId: '1',
                Name: "Check",
                isElectronic: false,
                showOnline: true,
                showBackoffice: (!$scope.isRefundPayment),
                PaymentMode: "CK"
            }, {
                paymentMethodId: '7',
                Name: "Cashiers Check",
                isElectronic: false,
                showOnline: false,
                showBackoffice: false,
                PaymentMode: "CCK"
            },
            {
                paymentMethodId: '2',
                Name: "Money Order",
                isElectronic: false,
                showOnline: true,
                showBackoffice: (!$scope.isRefundPayment),
                PaymentMode: "MO"
            },
            {
                paymentMethodId: '4',
                Name: "Click To Pay",
                isElectronic: false,
                showOnline: false,
                showBackoffice: false,
                PaymentMode: "PC"
            },
            {
                paymentMethodId: '5',
                Name: "Cash",
                isElectronic: false,
                showOnline: true,
                showBackoffice: (!$scope.isRefundPayment),
                PaymentMode: "CS"
            },
            {
                paymentMethodId: '3',
                Name: "Credit / Debit Card",
                isElectronic: true,
                showOnline: true,
                showBackoffice: (!$scope.isRefundPayment),
                PaymentMode: "CC"
            }, {
                paymentMethodId: '8',
                Name: "IBT",
                isElectronic: false,
                showOnline: false,
                showBackoffice: false,
                PaymentMode: "IBT"
            }, {
                paymentMethodId: '9',
                Name: "Refund",
                isElectronic: false,
                showOnline: false,
                showBackoffice: (!!$scope.isMiscPayment && !!$scope.isRefundPayment),
                PaymentMode: "RD"
            }
            ];

            if (!!paymentMode) {
                var paymentMethod;
                angular.forEach(allPaymentMethods, function (paymentMethodItem) {
                    if (paymentMode == paymentMethodItem.PaymentMode) {
                        paymentMethod = paymentMethodItem;
                    }
                });
                return paymentMethod;
            } else {
                var paymentMethodList = [];
                angular.forEach(allPaymentMethods, function (paymentMethodItem) {
                    if (($scope.isBackoffice && paymentMethodItem.showBackoffice) || (!$scope.isBackoffice && paymentMethodItem.showOnline)) {
                        paymentMethodList.push(paymentMethodItem);
                    }
                });
                return paymentMethodList;
            }
        };
        //----------Function call on Page load---------------------------
        $scope.init = function () {
            ShowLoader("Please Wait...");
            $scope.providerId = sessionStorage.ProviderId;
            $scope.applicationId = $scope.ApplicationId;
            $scope.emailAddress = sessionStorage.UserName;
            $scope.providerName = sessionStorage.ProviderName;
            $scope.getFeePaymentConfig();
            getProviderData();
            $scope.PaymentMethodList = $scope.getPaymentMethod();
            if (!$scope.module) {
                $scope.module = "APPLICATION";
            }

            
            console.log("print val1" ,  $rootScope.submissionLicenseStatusTypeId);
            console.log("print val2" ,  $rootScope.renewalInfoLicenseStatusTypeId);

            if ($rootScope.submissionLicenseStatusTypeId == undefined || $rootScope.renewalInfoLicenseStatusTypeId == undefined) {
                peerReviewService.legalInfoGetProvider($scope.providerId, $scope.applicationId, 3, sessionStorage.Key, "cbaRenewal")
                .then(function (response) {
                    if ($scope.isRenewal) {
                        $scope.firmType = $scope.providerLicense.ProviderLicenseTypeId;
                        if ($scope.firmType == 2) {
                            $scope.legalInfoListforFee = response.EntityLegalList.filter(function (entityItem){
                                return (entityItem.ContentItemLkId == 820 || entityItem.ContentItemLkId == 819 || entityItem.ContentItemLkId == 813)
                            });
                        }
                        else {
                            $scope.legalInfoListforFee = response.EntityLegalList.filter(function (entityItem){
                                return (entityItem.ContentItemLkId == 820 || entityItem.ContentItemLkId == 819 || entityItem.ContentItemLkId == 814)
                            });
                        }
        
                        angular.forEach($scope.legalInfoListforFee, function (legalInfoItemList) {
                            if (legalInfoItemList.ContentItemLkId == 813 || legalInfoItemList.ContentItemLkId == 814) {
                                if(legalInfoItemList.ContentItemResponse == true) {
                                    $rootScope.submissionLicenseStatusTypeId = 8;
                                }
                                else if (legalInfoItemList.ContentItemResponse == false)
                                {
                                    $rootScope.submissionLicenseStatusTypeId = 3;
                                }
                            }
                        
                            if (legalInfoItemList.ContentItemLkId == 820) {
                                peerReviewService.loadAllProviderLegalAddInfo($scope.providerId, $scope.applicationId, $scope.UserId, legalInfoItemList, sessionStorage.Key)
                                    .then(function (response) {
                                        if (!!response && response.length > 0) {
                                            $scope.additionalInfo = response[0];
                                            console.log('addInfo', $scope.additionalInfo);
                                            if ($scope.additionalInfo.AdditionalInformation == 'Substandard/Fail') {
                                                $rootScope.renewalInfoLicenseStatusTypeId = 8;
                                            }
                                            else {
                                                $rootScope.renewalInfoLicenseStatusTypeId = 3;
                                            }
                                        }
                                        else if (response.length == 0) {
                                            $rootScope.renewalInfoLicenseStatusTypeId = 3;
                                        }
                                        // if (hideLoader)
                                        //     HideLoader();
                                    }, function (data) {
                                        ShowErrMsg();
                                    });
                            }
                        });
                        
                    } 
                    // HideLoader();
                }, function (error) {
                    // HideLoader();
                    $scope.showStatusMessage({
                        messageContent: 'Some error occurred !',
                        messageType: 'error'
                    });
                });
            }

            if ($scope.isRenewal) {
                $scope.PaymentMethodList = $scope.PaymentMethodList.filter(function (meth) {
                    return meth.PaymentMode == 'CC' || meth.Name == 'Select';
                })
            }
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=country")).then(function (response) {
                if (!response.Status) {
                    $scope.showStatusMessage(response.Message, "error");
                } else {
                    $scope.CountryList = response.ReferenceEntities;
                    $scope.DefaultCountryName = $scope.CountryList.whereEquals($rootScope.DefaultCountryId, 'CountryId').firstOrDefault().UserDefinedNumber;
                    $scope.getStateByCountryID($scope.DefaultCountryName);
                }
            }, function (error) {
                $scope.showStatusMessage(error, "error");
            });
            $scope.feeDetailRowsDisplay = angular.copy($scope.feeDetailRows);
            if (!$scope.isRenewal && !$scope.isMiscPayment) {
                var checkfingerprint = $scope.feeDetailRows.filter(function (obj) {
                    return obj.FeeTypeCode == 'FINGERPRINT';
                });
                if (checkfingerprint && checkfingerprint.length > 0) {
                    $scope.feeDetailRowsDisplay = $scope.feeDetailRowsDisplay.filter(function (obj) {
                        return obj.FeeTypeCode != 'FINGERPRINT';
                    });
                    $scope.feeDetailRowsDisplay.push({
                        AdditionalFeeAccountDesc: "Fingerprint Reports",
                        AdditionalFeeAccountuffixS: "001",
                        AdditionalFeeCode: "991937",
                        ApplicationStatusId: null,
                        ApplicationTypeId: null,
                        BoardInfoId: null,
                        CreatedBy: 100,
                        CreatedOn: "2019-05-01T00:00:00",
                        EffectiveDate: "2019-05-01T00:00:00",
                        EndDate: null,
                        FeeAccountSuffix: "01",
                        FeeAmount: 49,
                        FeeDetailCode: "DOJ",
                        FeeDetailDesc: "DOJ Fingerprint Fees",
                        FeeDetailId: 75,
                        FeeDetailName: "DOJ Fingerprint Fees",
                        FeeTypeCode: "FINGERPRINT",
                        FundAdditionalCode: "0770",
                        FundCode: "0770",
                        IndexAdditionalFeeAccountCode: "11112900",
                        IndexFeeAccountCode: "7500",
                        IsActive: 1,
                        IsDeleted: 0,
                        LicenseApplicationForId: null,
                        LicensePathwayLevel1Id: null,
                        LicensePathwayLevel2Id: null,
                        LicenseStatusTypeIds: null,
                        LicenseTypeId: null,
                        LicenseTypeIds: null,
                        ModifiedBy: null,
                        ModifiedOn: null,
                        ProgramAdditionalCode: null,
                        ProgramCode: "4840000",
                        SortOrder: null,
                        UserDefinedAccountCode: "77700",
                        UserDefinedAdditionalAccountCode: "1215014"
                    });
                }

            }

            var dataPromises = [];
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=city")));
            dataPromises.push(typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=zip")));
            $q.all(dataPromises)
                .then(function (response) {
                    angular.forEach(response, function (responseItem, itemIndex) {
                        if (!!responseItem.Status) {
                            if (itemIndex == 0) {
                                $scope.CityList = responseItem.ReferenceEntities;
                            } else if (itemIndex == 1) {
                                $scope.ZipList = responseItem.ReferenceEntities;
                            }
                        }
                    });
                }, function (data) {
                    HideLoader();
                    if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                });
            //$scope.getContentByLkItemId();
            
            $scope.$on('SwitchingTab', function (event, args) {
                if (args.fromTab == $scope.tab.name) {
                    if (!$scope.isBackoffice) {
                        $scope.$emit('setCurrentTabNow', $scope.tab);
                    } else {
                        $scope.$broadcast("SaveDeficiency", {});
                        $scope.$emit('setCurrentTabNow', $scope.tab);
                    }
                }
            });

            $scope.clearTabConfirmation = function () {
                $scope.$emit("clearTabConfirmation", { currentTab: $scope.tab });
            };
            
            // if (!$scope.isMiscPayment) {
            //     ShowLoader();
            //     initialProviderApplicationFeePaymentService.getInvoiceMasterByIndividualIdAndInvoiceStatus(sessionStorage.Key, $scope.providerId, $scope.ApplicationId)
            //         .then(function (objMasterDetails) {

            //             if (objMasterDetails.StatusCode != "00") {
            //                 return showErrorMessage(objMasterDetails.Message);
            //             }
            //             if (!objMasterDetails.InvoiceMasters) {
            //                 var reqObj = {
            //                     InvoiceID: 0,
            //                     ProviderId: $scope.providerId,
            //                     ApplicationId: $scope.ApplicationId,
            //                     LicenseTypeId: 1,
            //                     AmountBilled: $scope.payment.feeDetails.totalFee,
            //                     AmountPaid: 0,
            //                     AmountAdjusted: 0,
            //                     Balance: $scope.payment.feeDetails.totalFee,
            //                     TransType: (!!$scope.transactionType ? $scope.transactionType : "CE"),
            //                     Module: (!!$scope.payment.feeDetails.Module) ? $scope.payment.feeDetails.Module : "ADVDUES",
            //                     InvoiceStatus: "OPEN",
            //                     GPSelected: false,
            //                     GPAdjust: 0,
            //                     GPPayment: 0,
            //                     GPVoid: false,
            //                     Description: (!!$scope.payment.feeDetails.feeType) ? $scope.payment.feeDetails.feeType : "CORPORATE RENEWAL FEE",
            //                     LFSelected: false,
            //                     CreatedBy: $scope.UserId,
            //                     ReferenceNumber: $scope.providerLicense ? $scope.providerLicense.ProviderLicenseNumber : undefined
            //                 };
            //                 initialProviderApplicationFeePaymentService.invoiceMasterSave(sessionStorage.Key, [reqObj])
            //                     .then(function (objMasterInvoice) {
            //                         console.log(objMasterInvoice);
            //                         if (objMasterInvoice.StatusCode != "00") {
            //                             return showErrorMessage(objMasterInvoice.Message);
            //                         }
            //                         HideLoader();
            //                         invoiceMastersDetail = objMasterInvoice.InvoiceMasters[0];
            //                         console.log('res', invoiceMastersDetail);
            //                         $scope.payment.InvoiceId = invoiceMastersDetail.InvoiceID;
            //                     }, function (response) {
            //                         return showErrorMessage(response);
            //                     });
            //             } else if (!!objMasterDetails.InvoiceMasters) {
            //                 invoiceMastersDetail = objMasterDetails.InvoiceMasters[0];
            //                 $scope.payment.InvoiceId = invoiceMastersDetail.InvoiceID;

            //                 initialProviderApplicationFeePaymentService.getPaymentDetailsByApplicationIdAndIndividualId(sessionStorage.Key, $scope.ApplicationId, $scope.providerId)
                                
            //                     .then(function (objPaymentDetail) {

            //                         if (objPaymentDetail.StatusCode != "00") {
            //                             return showErrorMessage(objPaymentDetail.Message);
            //                         }
            //                         $scope.payment.PaymentDetails = objPaymentDetail.PaymentDetails;
            //                         if (!!objPaymentDetail.PaymentDetails && objPaymentDetail.PaymentDetails.length > 0) {
            //                             $scope.payment.IsPaymentInitiated = true;
            //                             $rootScope.showOnlySaveButton = true;
            //                             var paymentDetails = objPaymentDetail.PaymentDetails[0];
            //                             if (paymentDetails.PaymentMode == "CK") {
            //                                 $scope.payment.PaymentMethod = "1";
            //                                 $scope.payment.PaymentMethodSelected = "1";
            //                                 $scope.payment.CheckMailing = true;
            //                             } else if (paymentDetails.PaymentMode == "MO") {
            //                                 $scope.payment.PaymentMethod = "2";
            //                                 $scope.payment.PaymentMethodSelected = "2";
            //                                 $scope.payment.CheckMailing = true;
            //                             } else if (paymentDetails.PaymentMode == "PC") {
            //                                 $scope.payment.PaymentMethod = "4";
            //                                 $scope.payment.PaymentMethodSelected = "4";
            //                                 $scope.payment.CheckMailing = true;
            //                             } else if (paymentDetails.PaymentMode == "CA") {
            //                                 $scope.payment.PaymentMethod = "5";
            //                                 $scope.payment.PaymentMethodSelected = "5";
            //                                 $scope.payment.CheckMailing = true;
            //                             } else if (paymentDetails.PaymentMode == "CC") {
            //                                 $scope.payment.PaymentMethod = "3";
            //                                 $scope.payment.PaymentMethodSelected = "3";
            //                             }
            //                             $scope.payment.feeDetails.applicationFee = paymentDetails.AmountBilled;
            //                             $scope.payment.TotalNoOfAdditional = paymentDetails.Quantity.toString();
            //                             $scope.payment.FeeForAdditional = paymentDetails.Amount;
            //                             $scope.payment.feeDetails.lateFee = paymentDetails.LateFee;
            //                             $scope.payment.feeDetails.totalFee = paymentDetails.AmountDue;
            //                             $scope.payment.CheckNo = paymentDetails.CheckNo;
            //                             $scope.payment.InvoiceDatePayment = paymentDetails.InvoiceDate;
            //                             $scope.payment.ReferenceNumberPayment = paymentDetails.ReferenceNumber;
            //                             makeFormReadOnly(true);
            //                             HideLoader();
            //                         } else {
            //                             HideLoader();
            //                         }
            //                         ////
            //                         if (objMasterDetails.InvoiceMasters[0].InvoiceStatus == "CLOSED") {
            //                             makeFormReadOnly(true, true);
            //                             $scope.payment.IsPaymentSubmitted = true;
            //                             $rootScope.showOnlySaveButton = true;
            //                         }
            //                     }, function (response) {
            //                         return showErrorMessage(response);
            //                     });
            //             }
            //         }, function (response) {
            //             return showErrorMessage(response);
            //         });
            // }
        };

        var getProviderData = function () {
            $scope.personalData = {};
            $scope.personalInfo = {};

            // alert(Messages.bafbefd);
            $q.all([ProviderAddressByProviderId(true, true), GetProviderContactByProviderId(), GetProviderById()]).then(function (res) {

                setProviderAddressListData();
            }, function () {
            });
        };

        var setProviderAddressListData = function () {
            try {
                if ($scope.ProviderAdditionalAddressList) {
                    var tempState = {};
                    for (var i = 0; i < $scope.ProviderAdditionalAddressList.length; i++) {
                        //set provider State

                        //Set provider Contact
                        if ($scope.ProviderContact && $scope.ProviderContact.length > 0) {
                            //get AdditionalEmail
                            var paddID = '' + $scope.ProviderAdditionalAddressList[i].ProviderAddressId;
                            var addEm = $.grep($scope.ProviderContact, function (n, i) { return (n.ContactTypeId == 23 && n.ReferenceNumber == paddID) });
                            $scope.ProviderAdditionalAddressList[i].AdditionalEmail = (addEm && addEm.length > 0) ? addEm[0] : {};

                            //get AdditionalPhone
                            var addPh = $.grep($scope.ProviderContact, function (n, i) { return (n.ContactTypeId == 21 && n.ReferenceNumber == paddID) });
                            $scope.ProviderAdditionalAddressList[i].AdditionalPhone = (addPh && addPh.length > 0) ? addPh[0] : {};
                        }
                    }
                }
            } catch (ex) {
                console.log('Exception setProviderAddressListData()', ex);
            }
        };

        var ProviderAddressByProviderId = function (LoadProviderAdd, loadAdditional) {
            var deferred = $q.defer();
            try {
                EInformationDirectiveService.ProviderAddressByProviderId(sessionStorage.Key, $scope.providerId).then(function (res) {
                    if (res.data.Status) {
                        if (LoadProviderAdd) {
                            $scope.ProviderAddressList = res.data.ProviderAddress;
                            setProviderAddress();
                        }
                        if (loadAdditional) {
                            $scope.ProviderAdditionalAddressList = res.data.ProviderAddress;
                        }
                    }
                    deferred.resolve(res);
                }, function (err) {
                    console.log('response', err);
                    deferred.reject(err);

                });
            }
            catch (ex) {
                deferred.reject(ex);
                console.log('Exception ProviderAddressByProviderId()', ex);
            }
            return deferred.promise;
        };

        var setProviderAddress = function () {
            if ($scope.ProviderAddressList) {
                // for (var i = 0; i < $scope.ProviderAddressList.length; i++) {
                //     //set provider Address
                //     if ($scope.ProviderAddressList[i].AddressTypeId == 4 && $scope.ProviderAddressList[i].AddressStatusId == 1) {
                //         $scope.personalData.ProviderPhAddress = $scope.ProviderAddressList[i];
                //         break;
                //     }
                // }
                for (var i = 0; i < $scope.ProviderAddressList.length; i++) {
                    //set provider Physical Address
                    if ($scope.ProviderAddressList[i].AddressTypeId == 4 && $scope.ProviderAddressList[i].AddressStatusId == 1) {
                        $scope.personalData.ResidentialAddress = $scope.ProviderAddressList[i];
                        break;
                    }
                }

            }
        };


        var GetProviderContactByProviderId = function () {
            var deferred = $q.defer();
            try {
                EInformationDirectiveService.GetProviderContactByProviderId(sessionStorage.Key, $scope.providerId).then(function (res) {
                    if (res.data.Status) {
                        $scope.ProviderContact = res.data.ProviderContacts;
                        setProviderContact();
                    }
                    deferred.resolve(res);
                }, function (err) {
                    deferred.reject(err);
                });
            }
            catch (ex) {
                deferred.reject(ex);
            }
            return deferred.promise;
        };

        var setProviderContact = function () {
            if ($scope.ProviderContact && $scope.ProviderContact.length > 0) {

                for (var i = 0; i < $scope.ProviderContact.length; i++) {
                    var contactTypeId = $scope.ProviderContact[i].ContactTypeId;
                    switch (contactTypeId) {
                        case 23:
                            {
                                $scope.personalData.EmailAddressContact = $scope.ProviderContact[i];
                                break;
                            }
                        case 21:
                            {
                                $scope.personalData.CellPhoneContact = $scope.ProviderContact[i];
                                break;
                            }
                    }
                }
            }
        };

        var GetProviderById = function () {
            try {
                EInformationDirectiveService.GetProviderById(sessionStorage.Key, $scope.providerId).then(function (res) {
                    if (res.data.Status) {
                        $scope.personalData.provider = res.data.ProviderResponse;
                    }
                }, function (err) {
                    console.log('response', err);
                });
            }
            catch (ex) {
                console.log('Exception GetProviderById()', ex);
            }
        };

        $scope.closePayment = function (feePaymentForm) {
            if (!!$scope.isMiscPayment) {
                $scope.natValidation.errorMessages = [];
                $scope.paymentStatusUpdate({
                    action: 'close'
                });
            }
        };

        
        $scope.saveAndNext = function () {
            if (!!$scope.payment.IsPaymentSubmitted) {
                $scope.openNextTab({
                    currentTab: $scope.tab
                });
                return;
            }
        };

        $scope.submitApplicationWithoutPayment = function (currentForm) {
            if (!!validateForm(currentForm)) {
                $scope.$emit("ApplicationSubmitWithoutPayment", {});
            }
        };

        $scope.init();
        // $scope.$on("PayandSubmit", function (event, args) {
        //     if (!$scope.payment.IsPaymentSubmitted && !$scope.payment.IsPaymentInitiated)
        //         $scope.processPayment($scope.currentForm, false);
        // });

        $scope.SavePayment = function (value) {
            $scope.processPayment($scope.currentForm, false, value);
        }
    }

})();