angular.module('natApp').controller("PaymentConfirmationController", ["$rootScope", "$scope", "$state", "$q", "$stateParams", "paymentConfirmationService",
    function ($rootScope, $scope, $state, $q, $stateParams, paymentConfirmationService) {

        //State Data
        $scope.paymentData = {
            entityId: $stateParams.entityId,
            entityType: $stateParams.entityType,
            applicationId: $stateParams.applicationId,
            applicationTypeId: $stateParams.applicationTypeId,
            paymentMethodTypeId: $stateParams.paymentMethodTypeId,
            isReSubmit: (($stateParams.isReSubmit == 'true' || $stateParams.isReSubmit == true) ? true : false)
        };
        $scope.userId = (!!sessionStorage.UserId ? sessionStorage.UserId : sessionStorage.UserID);
        $scope.uiConfig = {
            isConfigLoaded: false
        };
        $scope.init = function () {
            if ($rootScope.isUserSessionActive()) {
                ShowLoader();
                var dataPromises = {
                    individual: paymentConfirmationService.individualByIndividualId(sessionStorage.Key, $scope.paymentData.entityId),
                    application: paymentConfirmationService.getApplicationInfoByApplicationId($scope.paymentData.applicationId, $scope.userId, sessionStorage.Key),
                    individualLicense: paymentConfirmationService.getIndividualLicense(sessionStorage.Key, $scope.paymentData.entityId)
                };

                $q.all(dataPromises)
                    .then(function (response) {
                        for (var prop in response) {
                            if (!response[prop].Status) {
                                $scope.showStatusMsg('-', response[prop].Message);
                                return;
                            }
                        }
                        if (response.individual) {
                            if (!!response.individual.IndividualResponse && response.individual.IndividualResponse.length > 0) {
                                $scope.paymentData.individual = response.individual.IndividualResponse[0];
                            }
                        }

                        if (response.application) {
                            if (!!response.application.ApplicationResponseList && response.application.ApplicationResponseList.length > 0) {
                                $scope.paymentData.applicationInfo = response.application.ApplicationResponseList[0];
                                $scope.paymentData.applicationInfo.AppType = ($scope.paymentData.applicationInfo.ApplicationTypeId == 1 ? 'renewal' : 'application');
                            }
                        }

                        if (response.individualLicense) {
                            if (!!response.individualLicense.IndividualLicenseList && response.individualLicense.IndividualLicenseList.length > 0) {
                                var individualLicenses = response.individualLicense.IndividualLicenseList;
                                var licenseTypesName = '';
                                individualLicenses = individualLicenses
                                    .whereEquals($scope.paymentData.applicationId, 'ApplicationId')
                                    .select('LicenseTypeName').distinct();
                                individualLicenses.map(function (individualLicense, index) {
                                    if (index == 0) {
                                        licenseTypesName = individualLicense;
                                    } else if (index == (individualLicenses.length - 1)) {
                                        licenseTypesName = licenseTypesName + ' and ' + individualLicense;
                                    } else {
                                        licenseTypesName = licenseTypesName + ', ' + individualLicense;
                                    }
                                });
                                $scope.paymentData.licenseTypesName = licenseTypesName;
                            }
                        }
                        $scope.uiConfig.isConfigLoaded = true;
                        HideLoader();
                    }, function (error) {
                        $scope.showStatusMsg('-', error.message);
                    });

            } else {
                HideLoader();
                $state.go('app.Loginwoemail');
            }
        };

        $scope.printConfirmation = function () {
            window.print();
        };

        $scope.init();
    }
]);