﻿(function () {
    'use strict'
    angular.module('app.provider')
        .factory('sendApplicationReceiptEmailFactory', ['$http', 'WebApiUrl', '$q', 'AppConfig', function ($http, WebApiUrl, $q, AppConfig) {
            var baseFactory = {};
            baseFactory.sendApplicationPaymentReceiptEmail = function (key, reqdata ,src) {
                var defer = $q.defer();
                $http.post(WebApiUrl + 'Payment/SendApplicationPaymentReceiptEmail/' + key + '?Source=' + src, reqdata).then(function (res) {
                    defer.resolve(res.data);
                }, function (err) {
                    defer.reject(err.data);
                });
                return defer.promise;
            }
            return baseFactory;
        }]);
})();