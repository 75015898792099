(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('providerApplicationService', providerApplicationService)
    providerApplicationService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']
    function providerApplicationService($http, $q, WebApiUrl, AppConfig) {

        var _getProviderApplication = function (Key, IndividualId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Provider/ApplicationDocumentByProviderId/' + Key + '?ProviderId=' + IndividualId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getApplicationDocumentByProviderIdForOtherTab = function (Key, IndividualId, DocumentId, userId) {
            var deferred = $q.defer();
            $http.get(WebApiUrl + 'Provider/ApplicationDocumentByProviderId/' + Key + '?ProviderId=' + IndividualId + '&userId=' + userId + '&Source=' + AppConfig.Source + '&DocumentId=' + DocumentId)
                .then(function(data){ data = data.data;
                    deferred.resolve(data);
                }, function(error) { error = error.data;
                    deferred.reject(error);
                });
            return deferred.promise;
        };
        return {
            getProviderApplication: _getProviderApplication,
            getApplicationDocumentByProviderIdForOtherTab: _getApplicationDocumentByProviderIdForOtherTab
        }
    }
})();