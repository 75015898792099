﻿(function () {
    'use strict'
    angular.module('app.backofficeAdministor')
        .factory('RefTableFormDirectiveFactory', ['$http', 'WebApiUrl', '$q', function ($http, WebApiUrl, $q) {
            var _refTableFieldDetailsByRefTableId = function (key, tableId) {
                return returnDefferedResult($http.get(WebApiUrl + 'TypeValues/RefTableFieldDetailsByRefTableId/' + key + '?TableId=' + tableId));
            };

            var _saveRefTableData = function (key,data) {
                return returnDefferedResult($http.post(WebApiUrl + 'TypeValues/SaveRefTableData/' + key, data));
            };

            var returnDefferedResult = function (request) {
                var deferred = $q.defer();
                request.then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.reject(response);
                });
                return deferred.promise;
            };
            return {
                RefTableFieldDetailsByRefTableId: _refTableFieldDetailsByRefTableId,
                SaveRefTableData: _saveRefTableData
            };
        }]);
})();