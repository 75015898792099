(function () {
  'use strict'

    angular.module('app.backofficeIndividual')
    .factory('backofficeProvideDCHInformationService', backofficeProvideDCHInformationService)

    backofficeProvideDCHInformationService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function backofficeProvideDCHInformationService($http, $q, WebApiUrl, AppConfig) {
    
        var baseFactory = {};

        baseFactory.GetProviderOwnership = function (Key, providerid) {
            return returnDefferedResult($http.get(WebApiUrl + "Provider/GetProviderOwnership/" + Key + '?ProviderId=' + providerid + '&IndividualId=&ProvIndvNameOwnershipId='));
        };
        baseFactory.SaveProviderOwnership = function (key, data) {
            return returnDefferedResult($http.post(WebApiUrl + 'Provider/SaveProviderOwnership/' + key + '?Source=' + AppConfig.Source, data));
        };
        baseFactory.GetDCHInformation = function (Key, providerid, ln) {
            return returnDefferedResult($http.get(WebApiUrl + "Individual/GetDCHInformation/" + Key + '?ProviderId=' + providerid + '&LicenseNumber=' + ln + '&Source=' + AppConfig.Source));
        };
        baseFactory.SaveProviderIndividual = function (key, data) {
            return returnDefferedResult($http.post(WebApiUrl + 'Provider/SaveProviderIndividual/' + key + '?Source=' + AppConfig.Source, data));
        };

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        return baseFactory;
  }
})();