﻿(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('individualInfoOtherLogicService', ['$http', '$q', 'WebApiUrl', 'AppConfig', individualInfoOtherLogicService])

    function individualInfoOtherLogicService($http, $q, WebApiUrl, AppConfig) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
                .then(function (res) {
                    if (success) {
                        success(res);
                    }
                    deferred.resolve(res.data);
                }, function (error) {
                    if (fail) {
                        fail(error);
                    }
                    console.log("Request failed with status: " + error.status);
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _getAllContacts = function (Key, individualId) {
            var urlParams = {
                IndividualId: individualId
            };
            return responseHandler($http.get(WebApiUrl + 'Individual/IndividualContactBYIndividualId/' + Key + '/', {
                params: urlParams
            }));
        };

        var _saveContacts = function (key, contacts) {
            var urlParams = {
                Source: AppConfig.Source
            };
            return responseHandler($http.post(WebApiUrl + 'Individual/IndividualContactSave/' + key + '', contacts, {
                params: urlParams
            }));
        };

        var _individualLicenseDetailBYIndividualId = function (Key, IndividualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/IndividualLicenseDetailBYIndividualId/' + Key + '?IndividualId=' + IndividualId));
        };

        var _getWarning = function(Key, entityId, entityType, licenseNumber) {
            return returnDefferedResult($http.get(WebApiUrl + 'Complaint/GetDiscipline/' + Key + '?EntityId=' + entityId + '&EntityTy=' + entityType));
        }

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        var _getEmploymentConfirmation = function (key, individualId) {
            var urlParams = {
                IndividualId: individualId
            };

            return responseHandler($http.get(WebApiUrl + 'Individual/GetIndividualEmploymentConfirmation/' + key + '/', { params: urlParams }));
        };

        var _saveEmploymentConfirmation = function (key, confirmations) {
            return responseHandler($http.post(WebApiUrl + 'Individual/SaveIndividualEmploymentConfirmation/'  + key + '/', confirmations));
        };

        var _getUser = function (key, individualId) {
            var urlParams = {
                IndividualId: individualId,
                UserId: 0
            };

            return responseHandler($http.get(WebApiUrl + 'Users/UserByIndividualId/' + key + '/', { params: urlParams }));
        };

        var _saveUser = function (key, user) {
            return responseHandler($http.post(WebApiUrl + 'Users/UsersSave/' + key + '/', user));
        };
        var _externalUserUsersSave = function (key, user) {
            return returnDefferedResult($http.post(WebApiUrl + 'Users/ExternalUserUsersSave/' + key + '?Source=' + AppConfig.Source, user));
        };
        return {
            getAllContacts: _getAllContacts,
            saveContacts: _saveContacts,
            individualLicenseDetailBYIndividualId: _individualLicenseDetailBYIndividualId,
            getEmploymentConfirmation: _getEmploymentConfirmation,
            saveEmploymentConfirmation: _saveEmploymentConfirmation,

            getUser: _getUser,
            saveUser: _saveUser,
            getWarning: _getWarning,
            externalUserUsersSave:_externalUserUsersSave
        };
    }
}());