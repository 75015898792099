(function () {
    'use strict'

    angular.module('app.enforcement')
        .directive('complaintCasesLogic', ['ComplaintCasesExtService', '$q', ComplaintCasesLogic]);

    function ComplaintCasesLogic(ComplaintCasesExtService, $q) {
        return {
            restrict: 'E',
            link: function ($scope, element, attrs) {
                $scope.complaintStatusChanged = function (complaintCase, propName) {
                    complaintCase[propName] = new Date().toJSON();
                };

                $scope.caseStageChanged = function (complaintCase) {
                    if (complaintCase.CaseStageId == 5) {
                        complaintCase.CaseStageId = 5;
                        complaintCase.CaseStatusId = 80;
                        complaintCase.CurrentStatusDate = new Date().toJSON();
                        complaintCase.DispositionDate = new Date().toJSON();
                    } else if (complaintCase.CaseStageId == 7) {
                        $scope.current.complaintCase.ClosureReasonId = 23;
                    } else if (complaintCase.CaseStageId == 8) {
                        $scope.current.complaintCase.ClosureReasonId = 16;
                    } else {
                        $scope.current.complaintCase.ClosureReasonId = $scope.current.originalComplaintCase.ClosureReasonId;
                    }
                };

                $scope.filterComplaintDispType = function (list) {
                    if (!!list) {
                        list.conditionalSplice('item.Queue != this', $scope.queueName);
                    }
                };

                $scope.filterComplaintDispTypeByStageId = function (stageId) {
                    $scope.refTables.filteredDispTypeList = [];

                    //$scope.current.complaintCase.ComplaintDispTypeIds = null;
                    if (!$scope.complaintDispTypeCompleteList) {
                        $scope.complaintDispTypeCompleteList = [];
                    }
                    
                    //angular.copy($scope.complaintDispTypeCompleteList, list);
                    var list = angular.copy($scope.refTables.dispTypeList);
                    if (!!list) {
                        list.conditionalSplice('item.Queue != this', $scope.queueName);
                        list.conditionalSplice('item.StageId != this', stageId);
                    }
                    $scope.refTables.filteredDispTypeList = list;
                };

                $scope.getInvestigators = function () {
                    var deferred = $q.defer();

                    try {
                        ComplaintCasesExtService.getUsersByRole(sessionStorage.Key, null, 'Complaint Investigator')
                            .then(function (data) {
                                if (data.Status) {
                                    $scope.investigatorList = data.UsersInRole;
                                }
                                deferred.resolve(data);
                            }, function (err) {
                                deferred.reject(err);
                            });
                    } catch (ex) {
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                $scope.initExt = function () {
                    $scope.refTables = {};
                    $scope.getInvestigators();
                };

                $scope.getComplaintCasesExt = function () {
                    $scope.current.originalComplaintCase = angular.copy($scope.current.complaintCase);
                };
            }
        };
    }
})();
