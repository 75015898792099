(function () {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationWrittenExam', initialApplicationWrittenExamDirective);

    initialApplicationWrittenExamDirective.$inject = [];

    function initialApplicationWrittenExamDirective() {
        return {
            restrict: 'E',
            scope: {
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                individualId: '=individualId',
                applicationId: '=applicationId',
                isRenewal: '=?isRenewal',
                loadData: '=?loadData',
                isNameChange: "=?isNameChange",
                natValidation: "=natValidation",
                applicationInfo: "=?applicationInfo",
                ncess: "=?ncess",
                showStatusMessage: "&",
                tab: "=tab",
                openNextTab: "&",
                individualLicense: "=?individualLicense",
                hidePersonalInfo: "=hidePersonalInfo",
                individualEmployment: "=?individualEmployment"
            },
            templateUrl: function (elements, attrs) {
                return "app/components/individual/application/directives/written-exam/written-exam.html";
            },
            controller: "InitialApplicationWrittenExamController"
        }
    }
})();