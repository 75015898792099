(function () {
    'use strict'

    angular.module('app.account')
        .factory('loginStaffService', loginStaffService)

    loginStaffService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function loginStaffService($http, $q, WebApiUrl, AppConfig) {

        var _login = function (emailAddress, password, loginWithoutEmail) {
            var reqObj = {
                Email: emailAddress,
                Password: password,
                LastName: '',
                AccessCode: '',
                LicenseNumber: '',
                LoginWithoutEmail: loginWithoutEmail
            }
            return returnDefferedResult($http.post(WebApiUrl + 'User/Login?Source=' + AppConfig.Source, reqObj));
        }

        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };

        return {
            login: _login
        }
    }
})();