//Code Generated by Inlumon Code generation tool
(function () {
    'use strict'

    angular.module('app.backofficeAdministor')
        .directive('revenueDepositValidation', [RevenueDepositValidation]);

    function RevenueDepositValidation() {
        return {
            restrict: 'EA',
            link: function ($scope, element, attrs) {
                $scope.$on('$destroy', function () {
                    if (!!$scope.natValidation) {
                        $scope.natValidation.showError = false;
                        $scope.natValidation.errorMessages = [];
                    }
                });
            }
        };
    }
})();