(function() {
    'use strict'
    angular.module('app.InitialApplication')
        .directive('initialApplicationLicenseInfo', initialApplicationLicenseInfoDirective);

    initialApplicationLicenseInfoDirective.$inject = [];

    function initialApplicationLicenseInfoDirective() {
        return {
            restrict: 'E',
            scope: {
                licenseInfo: "=licenseInfo",
                showSubmitPanel: "=showSubmitPanel",
                showHeader: "=showHeader",
                headingPosition: "=headingPosition",
                natValidation: "=natValidation",
                individualId: '=individualId',
                applicationId: '=applicationId',
                UserId: '=userId',
                isRenewal: '=?isRenewal',
                loadData: '=?loadData',
                individualLicense: "=?individualLicense",
                acknowledgementApplicantName: "=acknowledgementApplicantName",
                acknowledgement: "=acknowledgement",
                showStatusMessage: "&",
                tab: "=tab",
                openNextTab: "&"
            },
            templateUrl: "app/components/individual/application/directives/license-Info/license-Info.html",
            controller: "InitialApplicationLicenseInfoController"
        }
    }
})();