(function() {
    'use strict'

    angular.module('natApp').factory('DataChangeLoggingService', ['$http', '$q', 'WebApiUrl', DataChangeLoggingService]);
    function DataChangeLoggingService($http, $q, WebApiUrl) {
        var responseHandler = function (httpPromise, success, fail) {
            var deferred = $q.defer();
            httpPromise
            .then(function(res) {
                if(success) {
                    success(res);
                }
                deferred.resolve(res.data);
            }, function(error) {
                if(fail) {
                    fail(error);
                }
                console.log("Request failed with status: " + error.status);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _saveDataChangeLog = function (key, listOfChanges) {
            var request = {
                Changes: listOfChanges
            };
            return responseHandler($http.post(WebApiUrl + 'Individual/SaveDataChangeLog/' + key + '/', request));
        };

        var _getDataChangeLog = function (key, individualId) {
            var urlParams = {
                IndividualId: individualId
            };
            return responseHandler($http.get(WebApiUrl + 'Individual/GetDataChangeLog/' + key + '/', { params: urlParams }));
        };

        var _getHumanReadableSentence = function (log) {
            //FieldNameChanged
            //FieldNewValue
            //FieldOldValue
            var humanReadableFormat = '';

            //Reference Number holds the ID 
            if (!parseInt(log.ReferenceNumber)) {
                humanReadableFormat = 'Added "{0}"';
            } else {
                humanReadableFormat = 'Changed "{0}"';
            }
            
            return humanReadableFormat.format(log.FieldNameChanged);
        };

        return {
            saveDataChangeLog: _saveDataChangeLog,
            getDataChangeLog: _getDataChangeLog,
            getHumanReadableSentence: _getHumanReadableSentence
        };
    }
})();