(function () {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('firmNotesService', firmNotesService)

    firmNotesService.$inject = ['$http', '$q', 'WebApiUrl', 'AppConfig']

    function firmNotesService($http, $q, WebApiUrl, AppConfig) {

        //var _providerLicenseDetailBYProviderId = function(Key, ProviderId) {
        //    return returnDefferedResult($http.get(WebApiUrl + 'Provider/GetProviderLicense/' + Key + '?ProviderId=' + ProviderId + '&Source=WYRealEstate'));
        //};
        var returnDefferedResult = function (request) {
            var deferred = $q.defer();
            request.then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };
        return {

        }
    }
})();
