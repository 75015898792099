(function () {
    'use strict'

    angular.module('app.core')
        .factory('expirationService', [ExpirationService]);

    function ExpirationService() {
        var _getDate = function (dob) {
            if (!dob) {
                return null;
            }

            if (typeof (dob) == 'string') {
                dob = dob.getDateObj();
            }

            return dob;
        };

        var _getDayOfMonth = function (month, year, options, allowedDays) {
            if (!allowedDays) {
                allowedDays = ['beginning', 'end', 'current', 'base'];
            }
            if (!!options.dayOfMonth && !allowedDays.includes(options.dayOfMonth)) {
                throw 'Invalid expiration script: {0} day of month is not applicable with current specifiers'.format(options.dayOfMonth);
            }

            switch (options.dayOfMonth) {
                case 'beginning':
                    return 1;
                case 'current':
                    return options._currentDate.getDate();
                case 'base':
                    return options._baseDate.getDate();
                case 'birthday':
                    if (!options._dateOfBirth) {
                        throw 'Invalid expiration script: dateOfBirth option required for birthday day specifier';
                    }
                    return options._dateOfBirth.getDate();
                case 'end':
                default:
                    return new Date(year, month + 1, 0).getDate();  //Adds a month and then goes 1 day back
            }
        };

        var _processDateSpecifier = function (matches, options) {
            var expirationDate = null;

            var currentDate = new Date();
            var currentYear = currentDate.getFullYear();
            var currentMonth = currentDate.getMonth();      // 0 indexed, so May returns as 4
            var currentDay = currentDate.getDate();

            if (!options) {
                options = {
                    dateOfBirth: null,
                    dayOfMonth: 'end'
                };
            }
            options._currentDate = currentDate;
            options._baseDate = _getDate(options.baseDate);
            if (!options._baseDate) {
                options._baseDate = currentDate;
            }
            options._dateOfBirth = _getDate(options.dateOfBirth);

            try {
                //First, let's break the string up into its constituent parts
                //var matches = expRegEx.exec(expirationRule);
                var monthRule = matches[1];
                var expMonth = matches[1];
                var expDay = null;
                var expYear = [matches[2], matches[3]].coalesce();
                var expAddYears = [matches[4], '+0'].coalesce();

                if (!expMonth || !expYear) {
                    throw 'Invalid expiration script: missing month or year specifier';
                }

                //In the case of a leap year, the last day of the month for february will be different than other years.
                //So, we need to calculate the year first.

                //Get the add years first
                expAddYears = [parseInt(expAddYears.slice(1)), 0].coalesce();

                //Now get the year (later, once we've determined the expiration month/day, if the date has already passed, we will add a year)
                var expYear = parseInt(expYear.replace('year', currentYear)) + expAddYears;
                var allowedDays = [];

                if (expMonth == 'birthmonth') {
                    allowedDays = ['beginning', 'end', 'current', 'base', 'birthday'];
                    if (!options._dateOfBirth) {
                        throw 'Invalid expiration script: birthmonth requires the dateOfBirth option';
                    }
                    expMonth = options._dateOfBirth.getMonth();
                    expDay = _getDayOfMonth(expMonth, expYear, options, allowedDays);
                } else if (expMonth == 'month') {
                    allowedDays = ['beginning', 'end', 'current', 'base'];
                    expMonth = currentMonth;
                    expDay = _getDayOfMonth(expMonth, expYear, options, allowedDays);
                } else {
                    //Parse the month/day out of the string
                    var split = expMonth.split('/');
                    if (split.length != 2) {
                        throw 'Invalid expiration script: month or day is missing from "month/day"';
                    }

                    expMonth = parseInt(split[0]) - 1;
                    expDay = parseInt(split[1]);
                }

                expirationDate = new Date(expYear, expMonth, expDay);
                //Ensure that the new expiration date is some time in the future from the base date
                while (expirationDate <= options._baseDate) {
                    expYear += 1;
                    //Re-calculate the day of the month, just in case the current/next year is a leap year
                    if (['birthmonth', 'month'].includes(monthRule)) {
                        expDay = _getDayOfMonth(expMonth, expYear, options, allowedDays)
                    }
                    expirationDate = new Date(expYear, expMonth, expDay);
                }

                delete options._currentDate;
                delete options._dateOfBirth;
            } catch (ex) {
                delete options._currentDate;
                delete options._dateOfBirth;
                throw ex;
            }

            return expirationDate;
        };

        var _processTimeSpecifier = function (matches) {
            var amount = parseInt(matches[1]);
            var unit = matches[2];

            var today = new Date();
            var expirationDate = null;


            switch (unit) {
                case 'day':
                case 'days':
                    expirationDate = today.addDays(amount);
                    break;
                case 'month':
                case 'months':
                    expirationDate = today.addMonths(amount);
                    break;
                case 'year':
                case 'years':
                    expirationDate = today.addYears(amount);
                    break;
            }

            return expirationDate;
        };

        // expirationRule: 
        //      e.g. 'month/year+1', 'birthmonth/year', '06/30/year+2'
        //      - 12/31, 06/30, etc: Static month/day during a year
        //      - month: The current month
        //      - birthmonth: The month of the individual's birthday (required: dateOfBirth option)
        //      - year, year+X: The current year, or the current year plus a number of years
        //      - Also works with time span specifiers: '6 months', '1 day', etc.
        // options (ignored when expiration rule is a time span specifier): 
        //      baseDate: Date() or String - the date from which the expiration year should be calculated
        //          - e.g. current License Expiration Date
        //          - Default: current date
        //      dateOfBirth: Date() or String
        //      dayOfMonth: String: beginning, end, sameDay, birthday
        //          - beginning: Always the 1st of the month
        //          - end (default): Always the last day of the month
        //          - current: Whatever day of the month it is now, that's the day it will expire
        //          - base: Whatever day of the month it is in the baseDate option.
        //          - birthday: The individual's birthday day of the month (required: dateOfBirth option, birthmonth specifier)
        var _getExpirationDate = function (expirationRule, options) {
            if (!expirationRule) {
                return null;
            }
            var expirationDate = null;

            var dateSpecifierRegex = /((?:\d{1,2}\/\d{1,2})|(?:month)|(?:birthmonth))\/(?:(\d{2,4})|(?:(year)(\+\d+)?))/g;
            var dateMatches = dateSpecifierRegex.exec(expirationRule);

            if (!!dateMatches && dateMatches.length > 0) {
                expirationDate = _processDateSpecifier(dateMatches, options)
            }

            var timeSpecifierRegex = /(\d+) (days|day|months|month|years|year)/g;
            var timeMatches = timeSpecifierRegex.exec(expirationRule);

            if (!!timeMatches && timeMatches.length > 0) {
                expirationDate = _processTimeSpecifier(timeMatches, options);
            }

            return expirationDate;
        };

        var _getRenewalCycleBasedExpirationDate = function () {
            var today = new Date();
            var currentYear = today.getFullYear();
            var currentMonth = today.getMonth();
            var renewalPeriodsByMonth = {
                0: { date: 30, month: 5, currentPlusYear: 0 },
                1: { date: 30, month: 5, currentPlusYear: 0 },
                2: { date: 30, month: 5, currentPlusYear: 0 },
                3: { date: 30, month: 8, currentPlusYear: 0 },
                4: { date: 30, month: 8, currentPlusYear: 0 },
                5: { date: 30, month: 8, currentPlusYear: 0 },
                6: { date: 31, month: 11, currentPlusYear: 1 },
                7: { date: 31, month: 11, currentPlusYear: 1 },
                8: { date: 31, month: 11, currentPlusYear: 1 },
                9: { date: 31, month: 2, currentPlusYear: 1 },
                10: { date: 31, month: 2, currentPlusYear: 1 },
                11: { date: 31, month: 2, currentPlusYear: 1 }
            };
            var renewalRule = renewalPeriodsByMonth[currentMonth];
            var expirationDate = new Date((currentYear + renewalRule.currentPlusYear), renewalRule.month, renewalRule.date);
            return expirationDate;
        };

        return {
            getExpirationDate: _getExpirationDate,
            getRenewalCycleBasedExpirationDate: _getRenewalCycleBasedExpirationDate
        };
    }
})();