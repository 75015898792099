﻿(function () {
    'use strict'
    angular.module('natApp')
        .controller('BEResponsibleInchargeController', BEResponsibleInchargeController);

    BEResponsibleInchargeController.$inject = ['$scope', 'ResponsibleInChargeService', '$q', 'BusinessEntitytatusService', 'contentService', 'Messages'];

    function BEResponsibleInchargeController($scope, ResponsibleInChargeService, $q, BusinessEntitytatusService, contentService, Messages) {
        //Responsible in charge
        var _key = sessionStorage.Key;
        $scope.currentPage = 1;
        $scope.itemperpage = 10;
        $scope.totalItems = 0;
        $scope.maxPaginationSize = 5;
        $scope.ResInChargeSearch = {
            FirstName: '',
            LastName: ''

        };

        $scope.init = function () {
            if ($scope.providerid && $scope.providerid != 0) {
                GetProviderIndividual();
            }
            contentService.getContentByContentItemLkIdList([253], sessionStorage.Key)
                .then(function (response) {
                    $scope.contentData = response.ContentItemLk[0];

                }, function (error) {

                });
        };

        $scope.individualSearchWithPage = function () {
            ShowLoader();

            $scope.ResInChargeSearch.SortColumn = "LicenseNumber";
            $scope.ResInChargeSearch.SortOrder = "asc";
            $scope.ResInChargeSearch.IsFuzzySearch = true;
            $scope.ResInChargeSearch.enableSearch = true;
            $scope.ResInChargeSearch.LicenseExpirationGreaterThan = new Date(2019, 11, 31);
            ResponsibleInChargeService.individualSearchWithPage($scope.ResInChargeSearch, _key, $scope.currentPage, $scope.itemperpage).then(function (res) {
                if (res.data.Status) {
                    $scope.IndividualListData = res.data.IndividualList;
                    $scope.totalItems = res.data.Total_Recard;
                    SetProviderIndividualValue();
                    //GetProviderIndividual();
                } else {
                    $scope.IndividualListData = [];
                }
                HideLoader();
            }, function () {
                HideLoader();
            });
        };

        $scope.SelectUnSelectIncharge = function (item) {
            if (item) {
                if (!item.IsResponsible) {
                    item.IsResponsible = true;
                }
                else {
                    item.IsResponsible = false;
                }
                var listData = [];
                var reqData1 = {
                    ProviderId: $scope.providerid,
                    IndividualId: item.IndividualId,
                    Responsible: item.IsResponsible,
                    IsResponsible: item.IsResponsible,
                    Individual: item,
                    CreatedBy: $scope.userId,
                    CreatedOn: new Date(),
                    ModifiedBy: $scope.userId,
                    ModifiedOn: new Date(),
                }
                listData.push(reqData1);
                if ($scope.saveonsubmit) {
                    TempProviderIndvSave(reqData1);
                } else {
                    SaveProviderIndividual(listData);
                }

            }
        };

        var TempProviderIndvSave = function (item) {
            if (!$scope.ProviderIndividualList) {
                $scope.ProviderIndividualList = [];
            }
            //if item not exists
            var itemIndex = $scope.ProviderIndividualList.map(function (i) { return i.IndividualId; }).indexOf(item.IndividualId);
            if (itemIndex == -1) {
                $scope.ProviderIndividualList.splice(0, 0, item);
            } else {
                $scope.ProviderIndividualList.splice(itemIndex, 1);
                $scope.ProviderIndividualList.splice(0, 0, item);
            }
        };

        $scope.deleteResponsibleincharge = function (item) {
            if (item && confirm(Messages.cceccbd)) {
                var reqData = {
                    ProviderId: $scope.providerid,
                    IndividualId: item.IndividualId,
                    Responsible: false,
                    IsResponsible: false,
                    CreatedBy: $scope.userId,
                    CreatedOn: new Date(),
                    ModifiedBy: $scope.userId,
                    ModifiedOn: new Date(),
                };
                $scope.RIncharge = undefined;
                if ($scope.saveonsubmit) {
                    TempProviderIndvSave(reqData);
                } else {
                    SaveProviderIndividual(listData);
                }
            }
        };

        var SaveProviderIndividual = function (arrayItems) {
            var defer = $q.defer();
            ResponsibleInChargeService.SaveProviderIndividual(_key, arrayItems).then(function (res) {
                if (res.data.Status) {
                    if (!$scope.saveonsubmit) {
                        GetProviderIndividual();
                    }
                }
                defer.resolve({});
            }, function () {
                defer.reject({});
            });
            return defer.promise;
        };

        var GetProviderIndividual = function () {
            ShowLoader();
            ResponsibleInChargeService.GetProviderIndividual(_key, undefined, $scope.providerid).then(function (res) {
                if (res.data.Status) {
                    $scope.ProviderIndividualList = res.data.ProviderIndividualList;
                    if ($scope.ProviderIndividualList && $scope.ProviderIndividualList.length > 0) {
                        for (var i = 0; i < $scope.ProviderIndividualList.length; i++) {
                            if ($scope.ProviderIndividualList[i].Responsible) {
                                if (!$scope.ProviderIndividualList[i].IsDeleted)
                                    IndividualByIndividualID(i);
                            }
                        }
                    }
                    SetProviderIndividualValue();
                }
                HideLoader();

            }, function () {
                HideLoader();
            });
        };

        var IndividualByIndividualID = function (index) {
            ShowLoader();
            ResponsibleInChargeService.IndividualByIndividualID(_key, $scope.ProviderIndividualList[index].IndividualId).then(function (res) {
                if (res.data.Status && res.data.IndividualResponse && res.data.IndividualResponse.length > 0) {
                    $scope.ProviderIndividualList[index].Individual = res.data.IndividualResponse[0];
                    $scope.ProviderIndividualList[index].IsResponsible = true;
                }
                HideLoader();
            }, function () {
                HideLoader();
            });
        };

        var SetProviderIndividualValue = function () {
            if ($scope.ProviderIndividualList && $scope.IndividualListData) {
                var tempIndv = 0
                var tempIndex = -1;
                for (var i = 0; i < $scope.ProviderIndividualList.length; i++) {
                    tempIndv = $scope.ProviderIndividualList[i].IndividualId;
                    tempIndex = $scope.IndividualListData.map(function (i) { return i.IndividualId }).indexOf(tempIndv);
                    if (tempIndex != -1) {
                        $scope.IndividualListData[tempIndex].IsResponsible = $scope.ProviderIndividualList[i].Responsible ? true : false;
                    }

                }
            }
        };

        $scope.pageChanged = function (curpage) {
            $scope.currentPage = curpage;
            $scope.individualSearchWithPage();
        };

        $scope.$on($scope.eventname, function (event, args) {

            if ($scope.saveonsubmit && $scope.providerid && $scope.providerid != 0 && $scope.ProviderIndividualList && $scope.ProviderIndividualList.length > 0) {
                var allitem = $scope.ProviderIndividualList.map(function (i) {
                    i.ProviderId = $scope.providerid;
                    return i;
                });
                if (allitem && allitem.length > 0) {
                    SaveProviderIndividual(allitem).then(function () {
                        BusinessEntitytatusService.setBEResponsibleIncharge("success");
                    }, function () {
                        BusinessEntitytatusService.setBEResponsibleIncharge("error");
                    });
                } else {
                    BusinessEntitytatusService.setBEResponsibleIncharge("success");
                }
            } else {
                BusinessEntitytatusService.setBEResponsibleIncharge("success");

            }
        });
        //Responsible in charge ends here
    }
})();