(function () {
    //'use strict';

    angular
        .module('app.providerDashboard')
        .controller("ProviderUserLinkingController", ProviderUserLinkingController);

        ProviderUserLinkingController.$inject = ["$scope", "$state", "$q", "userPortalViewService", "providerUserLinkingService", "dcaIService", "typeValuesService"];

    function ProviderUserLinkingController($scope, $state, $q, userPortalViewService, providerUserLinkingService, dcaIService, typeValuesService) {

        var validateForm = function (formsToValidate) {
            $scope.natValidation = {
                errorMessages: []
            };

            if (!!formsToValidate) {
                angular.forEach(formsToValidate, function (currentForm, index) {
                    currentForm.$submitted = true;
                    if (!!currentForm.errorMessages) {
                        var errorMessages = angular.copy(currentForm.errorMessages);
                        angular.forEach(errorMessages, function (errorMessage, index) {
                            $scope.natValidation.errorMessages.push(errorMessage);
                        });
                    }
                });
                // if (!!$scope.userLinking.haveLicense && !!$scope.userLinking.licType) {
                //     var licenseType = $scope.licenseTypes.whereEquals($scope.userLinking.licType, 'LicenseTypeCode').firstOrDefault();
                //     var dependentLicenseTypeIds = [];
                //     if (!!licenseType.DependentsToBeRenewedFirst) {
                //         dependentLicenseTypeIds = licenseType.DependentsToBeRenewedFirst.split(',');
                //         if (!!dependentLicenseTypeIds && dependentLicenseTypeIds.length > 0) {
                //             angular.forEach(dependentLicenseTypeIds, function (dependentLicenseTypeId) {
                //                 var dependentLicenseExists = false;
                //                 var dependentLicenseType = $scope.licenseTypes.whereEquals(dependentLicenseTypeId, 'LicenseTypeId').firstOrDefault();
                //                 if (!!$scope.existingLicenseTypes && $scope.existingLicenseTypes.indexOf(1 * dependentLicenseTypeId) != -1) {
                //                     dependentLicenseExists = true;
                //                 }
                //                 if (!dependentLicenseExists) {
                //                     $scope.natValidation.errorMessages.push({
                //                         propName: ("DependentLicense-" + dependentLicenseType.LicenseTypeId),
                //                         errorMessage: ("Please link " + dependentLicenseType.LicenseTypeName + " before linking " + licenseType.LicenseTypeName)
                //                     });
                //                 }
                //             });
                //         }
                //     }
                // }
            }

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.showStatusMsg('-', $scope.natValidation.errorMessages);
                return false;
            } else {
                $scope.hideStatusMsg();
                return true;
            }
        };

        $scope.navigateToDashboard = function () {
            var userPortalState = userPortalViewService.getFirmUserPortalState();
            $state.go(userPortalState);
        };

        $scope.updateUser = function (changeToLicensee, navigateToDashboard) {
            ShowLoader();
            var userId = sessionStorage.UserId;
            if (!!sessionStorage.applicantUserId && sessionStorage.applicantUserId != "undefined") {
                userId = sessionStorage.applicantUserId;
            }
            if(!!sessionStorage.UserId)
            {
                providerUserLinkingService.getUser(sessionStorage.Key, sessionStorage.UserId).then(function (response) {
                    var currentUser = response.Users;
                    if (!!changeToLicensee) {
                        currentUser.UserTypeId = 7;
                        sessionStorage.UserTypeID = 7;
                        sessionStorage.FirstName = response.Users.FirstName;
                        sessionStorage.LastName = response.Users.LastName;
                    }
                    currentUser.IsFirstLogin = false
                    providerUserLinkingService.externalUserUsersSave(sessionStorage.Key, currentUser).then(function (responseUser) {
                        HideLoader();
                        if (navigateToDashboard) {
                            $scope.navigateToDashboard();
                        }
                    });
                });
            }
        };

        $scope.continueToLinkOrDashboard = function (userLinkingForm) {
            if (!$scope.userLinking.haveAnotherLicense) {
                $scope.navigateToDashboard();
            } else {
                $scope.userLinking.userLinked = false;
                $scope.userLinking.licType = null;
                $scope.userLinking.licNum = null;
                $scope.hideStatusMsg();
                userLinkingForm.$submitted = false;
                userLinkingForm.$setPristine();
                $scope.getIndividualLicense();
            }
        };

        $scope.linkUser = function (currentForm) {
            if (!validateForm([currentForm])) {
                return;
            }
            ShowLoader();
            if ($scope.userLinking.haveLicense) {
                //console.log('sessionStorage',sessionStorage);
                $scope.userLinking.LinkIndividualAddress = true;
                $scope.userLinking.CreateAddressIfNotExists = true;
                $scope.userLinking.UpdateName = false;
                $scope.userLinking.AddressTypeId = 1;
                $scope.userLinking.EntityId = sessionStorage.ProviderId;
                $scope.userLinking.EntityType = 'P';
                dcaIService.linkExistingUser(sessionStorage.Key, $scope.userLinking).then(function (response) {
                    if (!response.Status) {
                        if (response.ResponseId == 1) {
                            if (!$scope.userLinking.isSubsiquentAttempt) {
                                $scope.userLinking.isSubsiquentAttempt = true;
                                $scope.showStatusMsg('-', "We are unable to connect your information to a record in our database.  Please check the information you entered and try again.  If you continue to receive this message, please contact the CBA's Renewal and Continuing Competency Unit by <a href='mailto:renewalinfo@cba.ca.gov'>e-mail</a> or by telephone at (916)561-1702.");
                            } else {
                                $scope.showStatusMsg('-', "We are unable to connect your information to a record in our database.  Please check the information you entered and try again.  If you continue to receive this message, please contact the CBA's Renewal and Continuing Competency Unit by <a href='mailto:renewalinfo@cba.ca.gov'>e-mail</a> or by telephone at (916)561-1702.");
                            }
                        } else if (response.ResponseId == 2) {
                            $scope.showStatusMsg('-', "Our records indicate this license is already linked to an online profile. Please contact the CBA's Renewal and Continuing Competency unit by <a href='mailto:renewalinfo@cba.ca.gov'>e-mail</a> or by telephone at (916) 561-1702.");
                        }
                        HideLoader();
                        return;
                    }
                    $scope.updateUser(true);
                    HideLoader();
                    $scope.userLinking.userLinked = true;
                    $scope.userLinking.haveAnotherLicense = null;
                    $scope.showStatusMsg('+', 'Congratulations. We found your license record in the database and have connected your login information to that existing record.');
                    $scope.userLinking.haveAnotherLicense = false;
                    $scope.continueToLinkOrDashboard();
                }, function (error) {
                    HideLoader();
                    //$scope.showStatusMsg('-', error.messages);
                    $scope.showStatusMsg('-', "We are unable to connect your information to a record in our database.  Please check the information you entered and try again.  If you continue to receive this message, please contact the CBA's Renewal and Continuing Competency Unit by <a href='mailto:renewalinfo@cba.ca.gov'>e-mail</a> or by telephone at (916)561-1702.")
                });
            } else if (false) {
                let msg = "We are unable to connect your information to a record in our database.  Please check the information you entered and try again.  If you continue to receive this message please contact the CBA's Renewal and Continuing Competency Unit by e-mail or by telephone at (916) 561-1702.";
                if (confirm(msg)) {
                    $scope.updateUser(false, true);
                }
                else {
                    HideLoader();
                }
            }
            else {
                $scope.userLinking.haveAnotherLicense = false;
                $scope.continueToLinkOrDashboard();
            }
        };

        $scope.init = function () {
            $q.all([
                typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=licensetype")),
                $scope.getProviderLicense()
            ]).then(function (response) {
                $scope.licenseTypes = response[0].ReferenceEntities;
                // console.log("licesnetypes", response[0].ReferenceEntities);
                
            });
        };

        $scope.getProviderLicense = function () {
            ShowLoader();
            var deferred = $q.defer();
            $scope.existingLicenseTypes = null;
            providerUserLinkingService.getProviderLicense(sessionStorage.Key, sessionStorage.ProviderId).then(function (response) {
                if (response.StatusCode != '01') {
                    HideLoader();
                    deferred.reject(response);
                    return;
                }
                if (!!response.ProviderResponse) {
                    var providerLicenseList = response.ProviderResponse;
                    // $scope.existingProviderLicenseTypes = providerLicenseList.select('LicenseTypeId').distinct();
                }
                HideLoader();
                deferred.resolve(response);
            }, function (error) {
                HideLoader();
                $scope.showStatusMsg('-', error.messages);
            });
            return deferred.promise;
        };

        $scope.preventPaste = function (e) {
            e.preventDefault();
            return false;
        };

        $scope.init();
    }
})();