(function() {
    'use strict'

    angular.module('app.backofficeIndividual')
        .factory('backofficeIndividualApplicationDataService', backofficeIndividualApplicationDataService)

    backofficeIndividualApplicationDataService.$inject = ['$http', '$q', 'WebApiUrl']

    function backofficeIndividualApplicationDataService($http, $q, WebApiUrl) {

        var _individualApplicationDataByIndividualId = function(Key, IndividualId) {
            return returnDefferedResult($http.get(WebApiUrl + 'Individual/ApplicationDocumentByIndividualId/' + Key + '?IndividualId=' + IndividualId));
        };

        var returnDefferedResult = function(request) {
            var deferred = $q.defer();
            request.then(function(response) {
                deferred.resolve(response.data);
            }, function(response) {
                deferred.reject(response);
            });
            return deferred.promise;
        };
        return {
            individualApplicationDataByIndividualId: _individualApplicationDataByIndividualId
        }
    }
})();