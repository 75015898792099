(function () {
    'use strict';
    angular.module('app.backofficeAdministor')
        .controller("UserAccountController", UserAccountController);

    UserAccountController.$inject = ["$scope", "$window", "$controller", "UserAccountService", "RolesService", "$state",
        "$timeout", "utilityService", "$q", "RegisterFactory", "typeValuesService", "TypeValue", "Messages"
    ];

    function UserAccountController($scope, $window, $controller, UserAccountService, RolesService, $state,
        $timeout, utilityService, $q, RegisterFactory, typeValuesService, TypeValue, Messages) {
        $controller('MainController', {
            $scope: $scope
        });
        // Variables
        $scope.Type = "";
        $scope.Role = [];
        $scope.Status = "";
        $scope.UserId = "";
        $scope.UserRoleID = "";
        $scope.Pending = "";
        $scope.Role = "";
        $scope.ChangePassword = false;
        $scope.Password = "";
        $scope.RepeatPassword = "";
        $scope.SourceID = "";
        $scope.IsPending = [{
            status: true,
            name: 'Yes'
        }, {
            status: false,
            name: 'No'
        }];
        $scope.UserType = [];
        $scope.UserStatus = [];
        $scope.Gender = [];
        $scope.RoleList = [];
        $scope.isValidEmail = false;
        $scope.isValidUsername = false;
        $scope.flag = "";
        $scope.data = {
            SearchUserTypeID: 1
        };
        $scope.order = "UserName";
        $scope.userPerPages = 10;
        $scope.orderflag = 0;
        $scope.show_elem = {
            searchdata: true,
            newuser: false,
            passrule: false,
            Userac_footer: false,
            changePass: false,
            ua: false,
            ca: false,
            table_wrapper: false,
            gridUpperDiv: false
        };
        $scope.init = function () {
            HideLoader();
            if ($scope.isUserSessionActive()) {
                $timeout(function () {
                    $scope.show_elem.searchdata = true;
                    $scope.show_elem.newuser = false;
                    $scope.show_elem.passrule = false;
                    $scope.show_elem.Userac_footer = false;
                    $scope.show_elem.changePass = false;
                    $scope.show_elem.ua = false;
                    $scope.show_elem.ca = false;
                    $scope.show_elem.table_wrapper = false;
                    $scope.show_elem.gridUpperDiv = true;
                });
                //$scope.getUserList();
                $scope.userTypeGetAll();
                $scope.userStatusGetAll();
                $scope.lookupGetBYLookupTypeID();
                $scope.sourceGetAll();
                typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=licensetype")).then(
                    function (response) {
                        if (!!response.ReferenceEntities)
                            $scope.licenseTypes = response.ReferenceEntities.where('item.IsActive && !item.IsDeleted');
                    });

            } else {
                $state.go('app.BackofficeLoginstaff');
            }
        };

        $scope.orderData = function (param) {
            $scope.orderflag = !$scope.orderflag;
            if (!$scope.orderflag) {
                $scope.order = '-' + param;
            }
            if ($scope.orderflag) {
                $scope.order = param.split('-')[0];
            }
        };
        // Search user
        $scope.searchUser = function () {
            ShowLoader("Please wait...");
            try {

                var UserName = $scope.data.SearchUserName;
                var UserTypeId = $scope.data.SearchUserTypeID;
                var FirstName = $scope.data.SearchFirstName;
                var LastName = $scope.data.SearchLastName;
                var BusinessName = $scope.data.SearchBusinessName;
                var EntityName = $scope.data.SearchFirmName;
                var LicenseNumber = $scope.data.SearchLicenseNumber;
                var Phone = null;
                var PositionTitle = null;
                var Email = $scope.data.SearchEmail;
                var UserStatusId = $scope.data.SearchStatusID;
                var SourceId = null;
                var Gender = "";
                var IsPending = $scope.data.SearchIsPending;
                var DateOfBirth = null;
                var DOB = null;
                var SourceName = null;
                var UserTypeName = $scope.data.SearchUserTypeID;
                var UserStatusName = $scope.data.SearchStatusID;

                UserAccountService.UsersSearch(
                        sessionStorage.Key,
                        UserName,
                        UserTypeId,
                        FirstName,
                        LastName,
                        BusinessName,
                        EntityName,
                        LicenseNumber,
                        Phone,
                        PositionTitle,
                        Email,
                        UserStatusId,
                        SourceId,
                        Gender,
                        IsPending,
                        DateOfBirth,
                        DOB,
                        SourceName,
                        UserTypeName,
                        UserStatusName)
                    .then(function (response) {
                        $scope.CallAudit();
                        if ($scope.checkServerResponse(response)) {
                            $scope.UserList = response.Users;
                            HideLoader();
                        } else {
                            HideLoader();
                        }
                    })
                    .catch(function (data) {

                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }

        };

        // Get User List
        $scope.getUserList = function () {
            ShowLoader("Please wait...");
            try {
                var UserName = '';
                var UserTypeId = '';
                var FirstName = '';
                var LastName = '';
                var Phone = '';
                var PositionTitle = '';
                var Email = '';
                var UserStatusId = '';
                var SourceId = '';
                var Gender = '';
                var IsPending = '';
                var DateOfBirth = '';
                var DOB = '';
                var SourceName = '';
                var UserTypeName = '';
                var UserStatusName = '';
                UserAccountService.UsersSearch(
                        sessionStorage.Key,
                        UserName,
                        UserTypeId,
                        FirstName,
                        LastName,
                        Phone,
                        PositionTitle,
                        Email,
                        UserStatusId,
                        SourceId,
                        Gender,
                        IsPending,
                        DateOfBirth,
                        DOB,
                        SourceName,
                        UserTypeName,
                        UserStatusName)
                    .then(function (response) {
                        $scope.CallAudit();
                        if ($scope.checkServerResponse(response)) {
                            $scope.UserList = response.Users;
                            HideLoader();
                        } else {
                            HideLoader();
                        }
                    })
                    .catch(function (data) {
                        HideLoader();
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };

        var IndividualContactSave = function (indvID, userid, appID) {
            var Urlparm = '/Key';
            var reqData = {
                IndividualId: indvID,
                ContactId: 0,
                ContactTypeId: 8,
                CreatedBy: userid,
                ContactStatusId: 1,
                ApplicationId: appID,
                ContactInfo: $scope.data.Email,
                IsActive: 1,
                IsDeleted: 0
            };
            RegisterFactory.IndividualContactSave(Urlparm, [reqData]).then(function (res) {

                HideLoader();
                if (res.data.Status) {
                    $scope.showStatusMessage(res.data.Message, 'success');
                } else {
                    $scope.showStatusMessage(res.data.Message, 'error');
                }
            }, function (res) {
                HideLoader();
                $scope.showStatusMessage(res.data.Message, 'error');
            });
        };

        var IndividualContactPhoneSave = function (indvID, userid, appID) {
            var deffer = $q.defer();
            var Urlparm = '/Key';
            var reqData = {
                IndividualId: indvID,
                ContactId: 0,
                ContactTypeId: 1,
                CreatedBy: userid,
                ContactStatusId: 1,
                ApplicationId: appID,
                ContactInfo: $scope.data.Phone,
                IsActive: 1,
                IsDeleted: 0
            };
            RegisterFactory.IndividualContactSave(Urlparm, [reqData]).then(function (res) {
                deffer.resolve();
            }, function (res) {
                deffer.reject();
            });
            return deffer.promise;
        };

        var IndividualContactSave = function (indvID, userid, appID) {
            var Urlparm = '/Key';
            var reqData = {
                IndividualId: indvID,
                ContactId: 0,
                ContactTypeId: 8,
                CreatedBy: userid,
                ContactStatusId: 1,
                ApplicationId: appID,
                ContactInfo: $scope.data.Email,
                IsActive: 1,
                IsDeleted: 0
            };
            RegisterFactory.IndividualContactSave(Urlparm, [reqData]).then(function (res) {

                HideLoader();
                if (res.data.Status) {
                    $scope.showStatusMessage(res.data.Message, 'success');
                } else {
                    $scope.showStatusMessage(res.data.Message, 'error');
                }
            }, function (res) {
                HideLoader();
                $scope.showStatusMessage(res.data.Message, 'error');
            });
        };

        var ProviderContactSaveEmail = function (indvID, userid, appID) {
            var Urlparm = '/Key';
            var reqData = {
                ProviderId: indvID,
                ContactId: 0,
                ContactTypeId: 23,
                ContactStatusId: 1,
                ApplicationId: appID,
                ContactInfo: $scope.data.Email,
                IsActive: 1,
                IsDeleted: 0
            };
            RegisterFactory.ProviderContactSave(Urlparm, [reqData]).then(function (res) {

                HideLoader();
                if (res.data.Status) {
                    $scope.showStatusMessage(res.data.Message, 'success');
                } else {
                    $scope.showStatusMessage(res.data.Message, 'error');
                }
            }, function (res) {
                HideLoader();
                $scope.showStatusMessage(res.data.Message, 'error');
            });
        };

        var ProviderContactSavePhoneSave = function (indvID, userid, appID) {
            var deffer = $q.defer();
            var Urlparm = '/Key';
            var reqData = {
                ProviderId: indvID,
                ContactId: 0,
                ContactTypeId: 21,
                CreatedBy: userid,
                ContactStatusId: 1,
                ApplicationId: appID,
                ContactInfo: $scope.data.Phone,
                IsActive: 1,
                IsDeleted: 0
            };
            RegisterFactory.ProviderContactSave(Urlparm, [reqData]).then(function (res) {
                deffer.resolve();
            }, function (res) {
                deffer.reject();
            });
            return deffer.promise;
        };

        // Save & Update user
        $scope.saveUser = function (status) {
            hideStatusMessage();
            try {
                if ($scope.isUserSessionActive()) {
                    if (validateData('UI_BackofficeAdministrationUserAccountvalidation') && $scope.validateRole()) {
                        ShowLoader("Please wait...");
                        var UserName = $scope.data.UserName;
                        var UserTypeId = $scope.data.UserTypeID;
                        var FirstName = $scope.data.FirstName
                        var LastName = $scope.data.LastName;
                        var EntityName = $scope.data.SearchFirmName;
                        var Phone = $scope.data.Phone;
                        var PositionTitle = $scope.data.PositionTitle;
                        var Email = $scope.data.Email;
                        var UserStatusId = $scope.data.UserStatusID;
                        var SourceId = $scope.SourceID;
                        var IsPending = $scope.data.IsPendingID;
                        var Gender = $scope.data.GenderID;
                        var DateOfBirth = angular.element('#txtDateOfBirth').val();
                        if (status == 0) {
                            var UserId = 0;
                            var IsDeleted = false;
                        } else if (status == 1) {
                            UserId = $scope.UserId;
                            IsDeleted = false;
                        } else if (status == 2) {
                            UserId = $scope.UserId;
                            IsDeleted = true;
                        }
                        var UserRoles = $scope.RoleList;
                        //Validate existing user
                        CheckExistingUserForRegistration(UserId).then(function (res) {

                            var EntityId = 0;
                            var EntityType = '';
                            var response = res.data;
                            if (UserTypeId != 7) {
                                EntityId = response.IndividualId;
                                if ($scope.data.Phone && status == 0)
                                    IndividualContactPhoneSave(response.IndividualId, $scope.UserId, 0);
                                if ($scope.data.Email && status == 0)
                                    IndividualContactSave(response.IndividualId, $scope.UserId, 0);
                            } else if (UserTypeId == 7) {
                                EntityId = $scope.UserList[0].IndividualId;
                                EntityType = 'P';
                                if ($scope.data.Email && status == 0)
                                    ProviderContactSaveEmail(response.EntityId, $scope.UserId, 0);
                                if ($scope.data.Phone && status == 0)
                                    ProviderContactSavePhoneSave(response.EntityId, $scope.UserId, 0);
                            }
                            $scope.data.LicenseTypeAssignedTo = $scope.licenseTypes.where('item.isSelected').select('LicenseTypeId').join(',');
                            UserAccountService.UsersSave(
                                    UserId,
                                    UserName,
                                    UserTypeId,
                                    FirstName,
                                    LastName,
                                    EntityName,
                                    Phone,
                                    PositionTitle,
                                    Email,
                                    UserStatusId,
                                    SourceId,
                                    Gender,
                                    IsPending,
                                    DateOfBirth,
                                    IsDeleted,
                                    UserRoles,
                                    sessionStorage.Key,
                                    EntityId,
                                    EntityType,
                                    $scope.data.ApplicationAssignedTo,
                                    $scope.data.LicenseTypeAssignedTo,
                                    $scope.data.TemporaryPassword
                                ).then(function (response) {
                                    HideLoader();
                                    $scope.CallAudit();
                                    if ($scope.checkServerResponse(response)) {
                                        HideLoader();
                                        showStatusMessage(response.Message, "success");
                                        $scope.searchUser();
                                        $scope.clearData();
                                        $scope.show_elem.searchdata = true;
                                        $scope.show_elem.newuser = false;
                                    } else {
                                        //else of checkServerResponse
                                    }
                                })
                                .catch(function (data) {
                                    if (data != null) {
                                        showStatusMessage(data.message, "error");
                                    }
                                });
                        });
                    }
                }
            } catch (ex) {
                if (ex != null) {
                    HideLoader();
                    showStatusMessage(ex.message, "error");
                }
            }
        };

        // Delete user
        $scope.deleteUser = function (Id, Name) {
            hideStatusMessage();
            try {
                $scope.UserId = Id;

                var r = confirm(Messages.General.Confirm.DeleteUser(Name));
                if (r == true) {
                    try {
                        if (Id != undefined && Id != null && Id != "") {
                            UserAccountService.UsersDeletebyID(Id, sessionStorage.Key)
                                .then(function (response) {
                                    $scope.CallAudit();
                                    if ($scope.checkServerResponse(response)) {
                                        showStatusMessage(response.Message, "success");
                                        //$scope.searchUser();

                                        $scope.UserList = [];
                                    } else {}
                                })
                                .catch(function (data) {
                                    showStatusMessage(data, "error");
                                });
                        } else {
                            showStatusMessage(Messages.General.Errors.UserDelete, "error");
                        }
                    } catch (ex) {
                        if (ex != null) {
                            showStatusMessage(ex.message, "error");
                        }
                    }
                }
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };

        var processLicenseTypeAssignToData = function () {
            if (!!$scope.data.LicenseTypeAssignedTo) {
                var licenseTypeAssignedToData = $scope.data.LicenseTypeAssignedTo.split(',');
                angular.forEach($scope.licenseTypes, function (licenseType) {
                    licenseType.isSelected = (licenseTypeAssignedToData.indexOf(licenseType.LicenseTypeId.toString()) != -1);
                })
            } else {
                angular.forEach($scope.licenseTypes, function (licenseType) {
                    licenseType.isSelected = false;
                })
            }
        };
        // Get User Detail by UserId
        $scope.getUser = function (ID, LicenseNumber) {
            try {
                ShowLoader("Please wait...");
                resetValidation();
                hideStatusMessage();
                hideValidationSummary();
                UserAccountService.UsersGetBYID(sessionStorage.Key, ID)
                    .then(function (response) {
                        $scope.CallAudit();
                        if ($scope.checkServerResponse(response) && response.Users && response.Users != null) {

                            $scope.show_elem.searchdata = false;
                            $scope.show_elem.newuser = true;
                            $scope.show_elem.passrule = false;
                            $scope.show_elem.Userac_footer = true;
                            $scope.show_elem.changePass = true;
                            $scope.show_elem.ua = true;
                            $scope.show_elem.ca = false;
                            var Users = response.Users;

                            $scope.data.LastName = Users.LastName;
                            $scope.data.FirstName = Users.FirstName;
                            $scope.data.EntityName = LicenseNumber;
                            $scope.data.Email = Users.Email;
                            $scope.data.UserName = Users.UserName;
                            $scope.data.UserStatusID = Users.UserStatusId;
                            $scope.data.UserTypeID = Users.UserTypeId;
                            $scope.data.PositionTitle = Users.PositionTitle;
                            $scope.data.Phone = Users.Phone;
                            $scope.data.IsPendingID = Users.IsPending;
                            $scope.data.GenderID = Users.Gender;
                            $scope.data.DOB = Users.DateOfBirth;
                            $scope.data.ApplicationAssignedTo = Users.ApplicationAssignedTo;
                            $scope.data.LicenseTypeAssignedTo = Users.LicenseTypeAssignedTo;
                            processLicenseTypeAssignToData();
                            $scope.data.TemporaryPassword = Users.TemporaryPassword;
                            $scope.userRoleGetBYUserID(ID);
                            $scope.UserId = ID;
                            HideLoader();
                            angular.element("#txtLastName").focus();
                        } else {
                            // Else of checkServerResponse Method
                            HideLoader();
                        }
                    })
                    .catch(function (data) {
                        HideLoader();
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };

        // Get User Role List
        $scope.getUserRoleList = function () {
            try {
                RolesService.UserRoleGetAll(sessionStorage.Key)
                    .then(function(response){ response = response.data;
                        $scope.CallAudit();
                        if ($scope.checkServerResponse(response)) {
                            $scope.RoleList = response.UserRole;
                        } else {}
                    }, function(data) { data = data.data;
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };

        // Get UserType List
        $scope.userTypeGetAll = function () {
            try {
                UserAccountService.UserTypeGetAll(sessionStorage.Key)
                    .then(function (response) {
                        $scope.CallAudit();
                        if ($scope.checkServerResponse(response)) {
                            $scope.UserType = response.UserType;
                        } else {}
                    })
                    .catch(function (data) {
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };

        // Get UserStatus List
        $scope.userStatusGetAll = function () {
            try {
                UserAccountService.UserStatusGetAll(sessionStorage.Key)
                    .then(function (response) {
                        $scope.CallAudit();
                        if ($scope.checkServerResponse(response)) {
                            $scope.UserStatus = response.UserStatus;

                        } else {}
                    })
                    .catch(function (data) {
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };

        // Get Lookup List for Gender
        $scope.lookupGetBYLookupTypeID = function () {
            try {
                UserAccountService.LookupGetBYLookupTypeID(sessionStorage.Key)
                    .then(function (response) {
                        $scope.CallAudit();
                        if ($scope.checkServerResponse(response)) {
                            $scope.Gender = response.Lookup;

                        } else {}
                    })
                    .catch(function (data) {
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };

        // Get RoleList by UserTypeId
        $scope.roleGetbyUserTypeId = function (param) {
            var UserTypeId = '';
            if (param == 'search') {
                UserTypeId = angular.element('#ddlUserType').val().split(":")[1];
            } else {
                UserTypeId = $scope.data.UserTypeID;
            }
            hideStatusMessage();
            if (UserTypeId != null && UserTypeId != "" && UserTypeId != "undefined") {
                try {
                    UserAccountService.RoleGetbyUserTypeId(UserTypeId, sessionStorage.Key)
                        .then(function (response) {
                            $scope.CallAudit();
                            if ($scope.checkServerResponse(response)) {
                                $scope.Role = response.Role;
                                $scope.RoleList = [];
                                angular.forEach($scope.Role, function (value, index) {
                                    var record = angular.fromJson($scope.Role[index]);
                                    $scope.RoleList.push({
                                        UserID: 0,
                                        UserRoleID: 0,
                                        RoleId: record.RoleId,
                                        Selected: false,
                                        Grantable: false
                                    });
                                });
                            } else {
                                //else of checkServerResponse method
                            }
                        })
                        .catch(function (data) {
                            if (data != null) {
                                showStatusMessage(data.message, "error");
                            }
                        });
                } catch (ex) {
                    if (ex != null) {
                        showStatusMessage(ex.message, "error");
                    }
                }
            }
        };

        // Get User Role List by UserID
        $scope.userRoleGetBYUserID = function (UserId) {
            hideStatusMessage();

            if (UserId != null && UserId != "" && UserId != "undefined") {
                try {
                    UserAccountService.UserRoleGetBYUserID(UserId, sessionStorage.Key)
                        .then(function (response) {
                            $scope.CallAudit();
                            if ($scope.checkServerResponse(response)) {
                                $scope.R = response.UserRole;
                                $scope.RoleList = [];
                                $scope.Role = [];
                                angular.forEach($scope.R, function (value, index) {
                                    var record = angular.fromJson($scope.R[index]);
                                    $scope.Role.push({
                                        RoleId: record.RoleID,
                                        Name: record.Role,
                                        Selected: record.Selected,
                                        Grantable: record.Grantable
                                    });
                                    $scope.RoleList.push({
                                        UserID: UserId,
                                        UserRoleID: record.UserRoleID,
                                        RoleId: record.RoleID,
                                        Selected: record.Selected,
                                        Grantable: record.Grantable
                                    });
                                });
                            } else {
                                //else of checkServerResponse method
                            }
                        })
                        .catch(function (data) {
                            showStatusMessage(data, "error");
                        });
                } catch (ex) {
                    if (ex != null) {
                        showStatusMessage(ex.message, "error");
                    }
                }
            }
        };

        // Get Source List
        $scope.sourceGetAll = function () {
            try {
                UserAccountService.SourceGetAll(sessionStorage.Key)
                    .then(function (response) {
                        $scope.CallAudit();
                        if ($scope.checkServerResponse(response)) {
                            $scope.SourceID = response.Source[0].SourceId;
                        } else {
                            //else of checkServerResponse method
                        }
                    })
                    .catch(function (data) {
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };

        // Select UserRole Checkbox
        $scope.isSelected = function (Id, status) {
            if ($scope.UserId == "" || $scope.UserId == null) {
                $scope.UserId = 0;
            }
            for (var i = 0; i < $scope.RoleList.length; i++) {
                if ($scope.RoleList[i].RoleId == Id) {
                    $scope.RoleList[i].UserID = $scope.UserId;
                    if (status == 1) {
                        if ($scope.RoleList[i].Selected == true) {
                            $scope.RoleList[i].Selected = false;
                        } else {
                            $scope.RoleList[i].Selected = true;
                        }
                    } else if (status == 2) {
                        if ($scope.RoleList[i].Grantable == true) {
                            $scope.RoleList[i].Grantable = false;
                        } else {
                            $scope.RoleList[i].Grantable = true;
                        }
                    }
                    break;
                }
            }
        };

        // Clear the All element
        $scope.clearData = function () {
            try {

                // New User Control
                $scope.data.LastName = "";
                $scope.data.FirstName = "";
                $scope.data.EntityName = "";
                $scope.data.PositionTitle = "";
                $scope.data.Phone = "";
                $scope.data.DOB = "";
                $scope.data.GenderID = "";
                $scope.data.IsPendingID = "";
                $scope.Role = [];
                $scope.data.Email = "";
                $scope.data.UserName = "";
                $scope.data.UserStatusID = "";
                $scope.data.UserTypeID = "";
                $scope.UserId = "";

                //Search Control
                $scope.data.SearchUserTypeID = 1;
                $scope.data.SearchRoleID = "";
                $scope.data.SearchStatusID = "";
                $scope.data.SearchLastName = "";
                $scope.data.SearchUserName = "";
                $scope.data.SearchEmail = "";
                $scope.data.SearchIsPending = "";
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };

        // Cancle Button
        $scope.canclePage = function () {
            resetValidation();
            hideStatusMessage();
            hideValidationSummary();
            $scope.clearData();
        };

        // Check Role selected or not
        $scope.validateRole = function () {

            var flag = false;
            for (var i = 0; i < $scope.RoleList.length; i++) {
                if ($scope.RoleList[i].Selected == true || $scope.RoleList[i].Grantable == true) {
                    return true;
                } else {
                    flag = true;
                }
            }
            if (flag == true || $scope.RoleList.length <= 0) {
                showStatusMessage(Messages.General.Errors.RoleRequired, "error");
                return false;
            }
        };
        angular.element(document.querySelector("#btnNewUser")).click(function () {
            ShowLoader("Please wait...");
            HideLoader();
            resetValidation();
            hideStatusMessage();
            hideValidationSummary();
            // angular.element(document.querySelector("#searchdata")).hide();
            $scope.show_elem.searchdata = false;
            $scope.show_elem.newuser = true;
            $scope.show_elem.passrule = true;
            $scope.show_elem.Userac_footer = true;
            $scope.show_elem.changePass = false;
            $scope.show_elem.ua = false;
            $scope.show_elem.ca = true;
        });
        angular.element(document.querySelector("#Search")).click(function () {
            ShowLoader("Please wait...");
            HideLoader();
            resetValidation();
            hideStatusMessage();
            hideValidationSummary();
            $scope.show_elem.searchdata = true;
            $scope.show_elem.newuser = false;
            $scope.show_elem.passrule = false;
            $scope.show_elem.Userac_footer = false;
            $scope.show_elem.changePass = false;
            $scope.show_elem.ua = false;
            $scope.show_elem.ca = false;
        });
        angular.element(document.querySelector("#cancel")).click(function () {
            ShowLoader("Please wait...");
            HideLoader();
            resetValidation();
            hideStatusMessage();
            hideValidationSummary();
            $scope.show_elem.searchdata = true;
            $scope.show_elem.newuser = false;
            $scope.show_elem.passrule = false;
            $scope.show_elem.Userac_footer = false;
            $scope.show_elem.changePass = false;
            $scope.show_elem.ua = false;
            $scope.show_elem.ca = false;
        });
        angular.element(document.querySelector("#Close")).click(function () {
            ShowLoader("Please wait...");
            HideLoader();
            resetValidation();
            hideStatusMessage();
            hideValidationSummary();
            $scope.show_elem.searchdata = false;
            $scope.show_elem.newuser = false;
            $scope.show_elem.passrule = false;
            $scope.show_elem.Userac_footer = false;
            $scope.show_elem.changePass = false;
            $scope.show_elem.ua = false;
            $scope.show_elem.ca = false;
        });

        // Validate Email
        $scope.validateEmailExist = function (email) {

            try {

                if (email != null && email != undefined && email != "" && $scope.UserId < 1) {
                    hideStatusMessage();
                    resetValidation();
                    ShowLoader("Please wait...");
                    UserAccountService.ValidateEmailExist(email)
                        .then(function (response) {
                            $scope.CallAudit();
                            if (response != null && response != "") {
                                if (response.StatusCode == "01") {

                                    showStatusMessage(response.Message, "error");
                                    angular.element("#txtEmail").focus();
                                    $scope.isValidEmail = false;
                                } else {
                                    $scope.isValidEmail = true;
                                    //$("#txtUserName").focus();
                                }
                            }
                        })
                        .catch(function (data) {
                            showStatusMessage(data, "error");
                        });

                } else {
                    //$("#txtEmail").focus();
                }
                HideLoader();
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
                HideLoader();
            }
        };

        // Validate UserName
        $scope.validateUserNameExist = function (UserName) {
            try {
                if (UserName != null && UserName != undefined && UserName != "" && $scope.UserId < 1 && $scope.isValidEmail == true) {
                    hideStatusMessage();
                    resetValidation();
                    ShowLoader("Please wait...");
                    UserAccountService.ValidateUserNameExist(UserName)
                        .then(function (response) {
                            $scope.CallAudit();
                            if (response != null && response != "") {
                                if (response.StatusCode == "01") {
                                    showStatusMessage(response.Message, "error");
                                    $scope.data.UserName;
                                    angular.element("#txtUserName").focus();
                                    $scope.isValidUsername = false;
                                } else {
                                    angular.element("#txtPhone").focus();
                                    $scope.isValidUsername = true;
                                }
                            }
                        })
                        .catch(function (data) {
                            showStatusMessage(data, "error");
                        });
                } else {

                }
                HideLoader();
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
                HideLoader();
            }
        };
        $scope.copyEmailtoUsername = function () {
            $scope.data.UserName = $scope.data.Email;
        };
        $scope.CallAudit = function () {
            var data = {
                "PageName": "UserAccount",
                "SessionId": sessionStorage.Key,
                "IndividualId": sessionStorage.IndividualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };
        $scope.CallActions = function (userid, userstatusid) {
            ShowLoader();
            UserAccountService.CallActions(userid, userstatusid, sessionStorage.Key)
                .then(function (response) {
                    angular.element("#btnSearch").trigger("click");
                    if (response.Status) {
                        showStatusMessage(response.Message, 'success');
                        $timeout(function () {
                            $window.location.reload();
                        }, 500);
                    } else {
                        showStatusMessage(response.Message, 'error');
                    }
                    HideLoader();
                })
                .catch(function (response) {

                })
        };
        var CheckExistingUserForRegistration = function (userid, username) {
            var deferred = $q.defer();

            var reqData = {
                Email: (!username || username == '') ? $scope.data.Email : username,
                LastName: $scope.data.LastName,
                FirstName: $scope.data.FirstName,
                DateOfBirth: $scope.data.DOB,
                LicenseNumber: $scope.data.EntityName,
                UserTypeId: $scope.data.UserTypeID
            };

            UserAccountService.CheckExistingUserForRegistration(reqData, $scope.data.UserTypeID).then(function (res) {
                var resData = res.data;
                //   console.log('Inside chk res', res);
                if (resData.Status) {
                    if (resData.StatusID == 2 || resData.StatusID == 4 || (userid != 0 && userid == resData.UserId)) {
                        // validate username
                        if (!username || username == "") {
                            CheckExistingUserForRegistration(userid, $scope.data.UserName).then(function () {
                                deferred.resolve(res);
                            });
                        } else {
                            deferred.resolve(res);
                        }
                    } else {
                        if (username && username != '') {
                            showStatusMessage(Messages.bbcddaa, 'error');
                        } else {
                            if ($scope.data.UserTypeID == 7) {
                                showStatusMessage(resData.StatusMessage, 'error');
                            } else
                                showStatusMessage(Messages.cbbebad, 'error');
                        }
                        HideLoader();
                    }
                } else {
                    showStatusMessage(resData.Message, 'error');
                    HideLoader();
                    deferred.reject(res);
                }
            }, function (res) {
                showStatusMessage(Messages.eddffaf, 'error');
                HideLoader();
            });
            return deferred.promise;
        };

        $scope.getLicensseTypeStyle = function () {
            if ($scope.licenseTypes.any('item.isSelected')) {
                return {
                    "background-color": "#d9edf7",
                    "color": "#31708f"
                };
            }
            return {};
        }

    }
})();