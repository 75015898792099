(function () {
    'use strict'

    angular.module('task.management')
        .controller('TaskManagementController', ['$scope', '$q', '$state', '$rootScope', 'TaskManagementService', 'utilityService', 'Messages', TaskManagementController]);

    function TaskManagementController($scope, $q, $state, $rootScope, TaskManagementService, utilityService, Messages) {
        var _key = sessionStorage.Key;

        var resetSearchCriteria = function () {
            $scope.idParams = {
                UserId: 0,
                TaskScheduleAutoId: 0
            };
            $scope.searchCriteria = {
                FromDate: null,
                ToDate: null,
                CmdStatus: 1,
                TaskExecutionStatusId: 0,
                FromRowNum: 0,
                RecordToFetch: 1000,
                OrderBy: 'TaskTypeName',
                Order: 0,
                GMTMin: new Date().getTimezoneOffset(),
                LocalDate: new Date(),
                TaskScheduleModeId: 2,
                ShowDisabled: false
            };
        };

        var processTaskData = function (scheduledTaskData) {
            if (scheduledTaskData) {
                var oneWeekFromNow = new Date();
                oneWeekFromNow.setMonth(oneWeekFromNow.getMonth() + 1);
                scheduledTaskData.forEach(function (taskDataItem) {
                    taskDataItem.isExpired = utilityService.checkIfDateGreater(new Date(), taskDataItem.TaskEndDate);
                    if (!taskDataItem.isExpired) {
                        taskDataItem.expiringSoon = utilityService.checkIfDateGreaterOrEqual(oneWeekFromNow, taskDataItem.TaskEndDate);
                    }
                });
            }
            return scheduledTaskData;
        };

        $scope.init = function () {
            resetSearchCriteria();

            $scope.taskDetailsId = 0;
            $scope.datePicker = {
                fromDateOpened: false,
                toDateOpened: false,
                format: 'M!/d!/yyyy'
            };
            $scope.taskList = [];
            $scope.taskModes = [
                { TaskScheduleModeId: 1, TaskScheduleModeName: "One Time" },
                { TaskScheduleModeId: 2, TaskScheduleModeName: "Daily" },
                { TaskScheduleModeId: 3, TaskScheduleModeName: "Weekly" },
                { TaskScheduleModeId: 4, TaskScheduleModeName: "Monthly" },
            ];
            $scope.scheduledTaskData = [];
            $scope.scheduledTaskHeaders = {
                TaskScheduleName: {
                    label: 'Task Name',
                    sizeRatio: 2
                },
                TaskScheduleType: {
                    label: 'Task',
                    sizeRatio: 2
                },
                TaskScheduleModeName: 'Execution Mode',
                TaskEffectiveDate: {
                    label: 'From Date',
                    templateUrl: 'taskManagementDates.html'
                },
                TaskEndDate: {
                    label: 'To Date',
                    templateUrl: 'taskManagementDatesExpiringSoon.html'
                },
                IsEnabled: {
                    label: 'Is Enabled',
                    sizeRatio: 0.5,
                    templateUrl: 'taskManagementYesNo.html'
                },
                Action: {
                    label: 'Action',
                    templateUrl: 'taskManagementActions.html',
                    sizeRatio: 0.5
                }
            };

            $scope.tableEvents = {
                showHideSearch: function () {

                },
                editTaskClicked: $scope.editTaskClicked,
                cloneTaskClicked: $scope.cloneTaskClicked,
                deleteTaskClicked: $scope.deleteTaskClicked
            };

            $q.all([TaskManagementService.getTaskList(_key), TaskManagementService.getScheduledTaskList(_key, $scope.idParams, $scope.searchCriteria)]).then(function (data) {
                if (data[0].Status) {
                    $scope.taskList = data[0].ReferenceEntities;
                }
                if (data[1].Status && data[1].AutomatedManualTaskDetailList) {
                    $scope.scheduledTaskData = data[1].AutomatedManualTaskDetailList.orderBy('TaskEffectiveDate');
                } else {
                    $scope.scheduledTaskData = null;
                }
                $scope.scheduledTaskData = processTaskData($scope.scheduledTaskData);
                $scope.loadingTasks(false);
            });
        };

        $scope.searchClicked = function () {
            try {

                $scope.loadingTasks(true, 'Searching...');
                TaskManagementService.getScheduledTaskList(_key, $scope.idParams, $scope.searchCriteria)
                    .then(function (data) {
                        if (data.AutomatedManualTaskDetailList) {
                            $scope.scheduledTaskData = data.AutomatedManualTaskDetailList.orderBy('TaskEffectiveDate');
                        } else {
                            $scope.scheduledTaskData = null;
                        }

                        $scope.scheduledTaskData = processTaskData($scope.scheduledTaskData);
                        $scope.loadingTasks(false);
                    }, function (error) {
                        $scope.loadingTasks(false);
                    });
            } catch (ex) {
                $scope.loadingTasks(false);
            }
        };

        $scope.clearClicked = function () {
            resetSearchCriteria();
        };

        $scope.editTaskClicked = function (task) {
            if (task) {
                $state.go('app.CreateTask', { TaskScheduleAutoId: task.TaskScheduleAutoId, actionType: "edit" });
            }
        };

        $scope.cloneTaskClicked = function (task) {
            if (task) {
                $state.go('app.CreateTask', { TaskScheduleAutoId: task.TaskScheduleAutoId, actionType: "clone" });
            }
        };

        $scope.deleteTaskClicked = function (item) {
            try {
                if (item && confirm(Messages.General.Confirm.Delete)) {
                    $scope.loadingTasks(true, 'Deleting...');

                    $rootScope.individualEventLog('deleteStatus.deleteConfirmed', $scope.IndividualId);
                    TaskManagementService.deleteTaskById(_key, item.TaskScheduleAutoId).then(function (data) {
                        if (data.Status) {
                            showStatusMessage(Messages.abdffee, "success");

                            //Remove Item from list
                            $scope.scheduledTaskData.conditionalSplice('item.TaskScheduleAutoId==this', item.TaskScheduleAutoId);

                            $scope.loadingTasks(false);
                        } else {
                            showStatusMessage(Messages.cecfcfd, "error");
                            $scope.loadingTasks(false);
                        }
                    }, function (err) {
                        showStatusMessage(err.data.Message, "error");
                        $scope.loadingTasks(false);
                    });
                } else {
                    $rootScope.individualEventLog('deleteStatus.deleteCanceled', $scope.IndividualId);
                }
            } catch (ex) {
                if (ex != null) { showStatusMessage(ex.message, "error"); }
                $scope.loadingTasks(false);
            }
        };
    }
})();