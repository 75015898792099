(function () {
  'use strict'

  angular.module('app.backofficeIndividual')
    .factory('backofficeIndividualNotesService', backofficeIndividualNotesService)

  backofficeIndividualNotesService.$inject = ['$http', '$q', 'WebApiUrl']

  function backofficeIndividualNotesService($http, $q, WebApiUrl) {
    var _saveIndividualComment = function (indivdualComment, individualDocumentUpload, Key, isdelete) {
      var deferred = $q.defer();
      var noteData = {
        ModelData: indivdualComment,
        IndividualDocumentUpload: {}
      };
      if (!isdelete) {
        noteData.IndividualDocumentUpload = individualDocumentUpload;
      }
      $http.post(individualDocumentUpload.ApiSaveEndpoint + Key, noteData).then(function(data){ data = data.data;
        deferred.resolve(data);
      }, function(error) { error = error.data;
        deferred.reject(error);
      });
      return deferred.promise;
    };

    var _individualCommentLogGetByIndividualId = function (Key, IndividualId) {
      var deferred = $q.defer();
      $http.get(WebApiUrl + 'Individual/IndividualCommentWithDocsGetByIndividualId/' + Key + '?IndividualId=' + IndividualId)
        .then(function(data){ data = data.data;
          deferred.resolve(data);
        }, function(error) { error = error.data;
          deferred.reject(error);
        });
      return deferred.promise;
    };


    return {
      saveIndividualComment: _saveIndividualComment,
      individualCommentLogGetByIndividualId: _individualCommentLogGetByIndividualId
    }
  }
})();