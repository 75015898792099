﻿(function () {
    'use strict'
    angular.module('app.core').factory('providerAddressService', ['$http', 'WebApiUrl', function ($http, WebApiUrl) {
        var BaseUrl = WebApiUrl;
        var BaseFactory = {};
        BaseFactory.GetAddressDetails = function (urlParam) {
            return $http.get(BaseUrl + "Provider/ProviderAddressByProviderId" + urlParam);
        };
        BaseFactory.SaveAddressDetails = function (ProviderId, key, address) {
            return $http.post(BaseUrl + 'Provider/ProviderAddressSave/' + key + '?ProviderId=' + ProviderId + '?Source=', address);
        };
        BaseFactory.GetStateList = function (key, cID) {
            return $http.get(BaseUrl + "State/StateGetByCountryID/" + key + "?CountryID=" + cID);
        };
        return BaseFactory;
    }]);
})();