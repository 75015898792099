(function () {
    'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("BackofficeAuxiliaryTabController", BackofficeAuxiliaryTabController);

    BackofficeAuxiliaryTabController.$inject = ["$rootScope", "$scope", "$state", "backofficeIndividualLicenseService", "backofficeIndividualEducationService",
        "utilityService", "$exceptionHandler", "WebApiUrl", "typeValuesService", "initialApplicationDentalAuxiliariesService", "backofficeDAuxilaryTabService",
        "loggingService"
    , 'Messages'];

    function BackofficeAuxiliaryTabController($rootScope, $scope, $state, backofficeIndividualLicenseService, backofficeIndividualEducationService,
        utilityService, $exceptionHandler, WebApiUrl, typeValuesService, initialApplicationDentalAuxiliariesService, backofficeDAuxilaryTabService,
        loggingService, Messages) {
        $scope.natValidation = {};


        $scope.datePicker = {
            fromDateOpened: false,
            toDateOpened: false,
            format: 'M!/d!/yyyy',
            toDateOptions: {
                minDate: null
            },
            fromDateOptions: {}
        };

        $scope.viewConfig = {
            showAddDentalAuxiliaryForm: false
        };


        $scope.setForm = function (currentForm) {
            $scope.currentAuxiliaryForm = currentForm;
        };

        // $scope.setAuxiliaryForm = function (currentAuxiliaryForm) {
        //     $scope.currentAuxiliaryForm = currentAuxiliaryForm;
        // };

        var createNewDentalAuxiliary = function (isAuxiliaryEdit, dentalAuxiliary) {
            if (!isAuxiliaryEdit) {
                $scope.dentalAuxiliary = {
                    IndividualId: $scope.IndividualID,
                    //  ApplicationId: $scope.applicationId,
                    //    IndividualLicenseId: $scope.individualLicense.IndividualLicenseId,
                    Doyousupervise: true,
                    IsActive: true,
                    EmployConfirmation: false,
                    SupervisedTypeId: 1,
                    SupervisedStatusId: 1,
                    CreatedBy: $scope.UserId
                };
            } else {
                $scope.dentalAuxiliary = angular.copy(dentalAuxiliary);
                if (!!$scope.dentalAuxiliary) {
                    if (($scope.dentalAuxiliary.SupervisedFirstName == null || $scope.dentalAuxiliary.SupervisedFirstName == '') && ($scope.dentalAuxiliary.SupervisedLastName == null || $scope.dentalAuxiliary.SupervisedLastName == '')) {
                        $scope.dentalAuxiliary.SupervisedFirstName = $scope.dentalAuxiliary.SupervisedName;
                    }
                    if (!!$scope.dentalAuxiliary.DateBegin && !angular.isDate($scope.dentalAuxiliary.DateBegin))
                        $scope.dentalAuxiliary.DateBegin = new Date($scope.dentalAuxiliary.DateBegin);
                    if (!!$scope.dentalAuxiliary.DateEnd && !angular.isDate($scope.dentalAuxiliary.DateEnd))
                        $scope.dentalAuxiliary.DateEnd = new Date($scope.dentalAuxiliary.DateEnd);
                }
            }
        };


        var validateForm = function (currentForm) {
            $scope.natValidation.errorMessages = [];
            if (!!currentForm) {
                currentForm.$submitted = true;
                $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);

            }
            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        var ShowErrMsg = function (propName, errorMessage) {
            propName = (!!propName) ? propName : "DentalAuxiliariesErr";
            errorMessage = (!!errorMessage) ? errorMessage : $scope.dentalAuxiliaryConfig.ValidationMsg.APIError;
            if ($scope.natValidation.errorMessages == undefined)
                $scope.natValidation.errorMessages = [];
            var propExist = false;
            for (var i = 0; i < $scope.natValidation.errorMessages.length; i++) {
                if ($scope.natValidation.errorMessages[i].propName == propName)
                    propExist = true;
            }
            if (!propExist)
                $scope.natValidation.errorMessages.push({
                    propName: propName,
                    errorMessage: errorMessage
                });

            if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                $scope.natValidation.showError = true;
                $scope.natValidation.isValid = false;
                return false;
            } else {
                $scope.natValidation.showError = false;
                $scope.natValidation.isValid = true;
                return true;
            }
        };

        var prepareAuxiliaryData = function () {

            var dentalAuxiliaryConfirmationIndex = null;
            if (!$scope.lstIndividualSupervisory)
                $scope.lstIndividualSupervisory = [];
            angular.forEach($scope.lstIndividualSupervisorys, function (dentalAuxiliary, index) {
                angular.forEach($scope.auxiliaryTypes, function (auxiliaryType, index) {
                    if (auxiliaryType.SupervisedSubTypeId == dentalAuxiliary.SupervisedSubTypeId) {
                        dentalAuxiliary.SupervisedSubTypeName = auxiliaryType.SupervisedSubTypeName;
                    }
                });
                $scope.lstIndividualSupervisory.push(dentalAuxiliary);
            });

        };



        $scope.getAllAuxiliaryType = function () {
            typeValuesService.getTypeValuesByTypeName(TypeValue.AuxiliaryType, sessionStorage.Key, false, true)
                .then(function (response) {
                    if (response.Status) {
                        $scope.auxiliaryTypes = response.RefSupervisedSubTypeResponseList;
                        prepareAuxiliaryData();
                    } else {
                        ShowErrMsg("unableToLoadAuxiliaryTypes", response.Message);
                    }
                }, function (data) {
                    if (data != null) {
                        ShowErrMsg("unableToLoadAuxiliaryTypes", data);
                    }
                });
        };

        $scope.openAddDentalAuxiliaryForm = function (isAuxiliaryEdit, dentalAuxiliary) {
            ShowLoader();
            $scope.viewConfig.showAddDentalAuxiliaryForm = true;
            $scope.isAuxiliaryEdit = isAuxiliaryEdit;
            createNewDentalAuxiliary(isAuxiliaryEdit, dentalAuxiliary);
            HideLoader();
        };

        $scope.closeAddDentalAuxiliaryForm = function (hideLoader) {
            if (!hideLoader)
                ShowLoader();
            validateForm(null, null);
            $scope.viewConfig.showAddDentalAuxiliaryForm = false;
            $scope.currentAuxiliaryForm = null;
            if (!hideLoader)
                HideLoader();
        };

        $scope.deleteDentalAuxiliary = function (dentalAuxiliary, indexToDelete) {
            if (confirm(Messages.ccafaae)) {
                ShowLoader();
                dentalAuxiliary.IsDeleted = true;
                initialApplicationDentalAuxiliariesService.saveDentalAuxiliary([dentalAuxiliary], sessionStorage.Key)
                    .then(function (response) {
                        if (response.Status == false) {
                            ShowErrMsg("unableToDeleteAuxiliary", response.Message);
                            $scope.showStatusMessage(response.Message, "error");
                        } else {
                            $scope.lstIndividualSupervisory.splice(indexToDelete, 1);
                            $scope.showStatusMessage(Messages.aedbddf, "success");
                            HideLoader();
                        }

                    }, function (data) {
                        if (data != null) {
                            ShowErrMsg("unableToDeleteAuxiliary", data);
                        }
                    });
            }
        };

        $scope.saveDentalAuxiliary = function (currentAuxiliaryForm) {
            try {
                ShowLoader();
                if (!validateForm(currentAuxiliaryForm)) {
                    HideLoader();
                    return;
                }
                angular.forEach($scope.auxiliaryTypes, function (auxiliaryType, index) {
                    if (auxiliaryType.SupervisedSubTypeId == $scope.dentalAuxiliary.SupervisedSubTypeId) {
                        $scope.dentalAuxiliary.SupervisedTypeId = $scope.dentalAuxiliary.SupervisedTypeId;
                    }
                });

                // $scope.dentalAuxiliary.IndividualSupervisoryConfId = $scope.dentalAuxiliaryConfirmation.IndividualSupervisoryConfId;
                $scope.dentalAuxiliary.SupervisedStatusId = 1;
                $scope.dentalAuxiliary.SupervisedTypeId = 1;
                //console.log($scope.dentalAuxiliary);
                initialApplicationDentalAuxiliariesService.saveDentalAuxiliary([$scope.dentalAuxiliary], sessionStorage.Key)
                    .then(function (response) {
                        if (response.Status == false) {
                            ShowErrMsg("unableToSaveAuxiliary", response.Message);
                        } else {
                            var dentalAuxiliary = response.IndividualSupervisoryInfos[0];
                            if (!!dentalAuxiliary.DateBegin)
                                dentalAuxiliary.DateBegin = ((dentalAuxiliary.DateBegin.slice(-1) == 'Z') ? dentalAuxiliary.DateBegin.slice(0, -1) : dentalAuxiliary.DateBegin);
                            if (!!dentalAuxiliary.DateEnd)
                                dentalAuxiliary.DateEnd = ((dentalAuxiliary.DateEnd.slice(-1) == 'Z') ? dentalAuxiliary.DateEnd.slice(0, -1) : dentalAuxiliary.DateEnd);

                            if (!$scope.lstIndividualSupervisory) {
                                $scope.lstIndividualSupervisory = [];
                            }

                            if (!$scope.isAuxiliaryEdit) {
                                angular.forEach($scope.auxiliaryTypes, function (auxiliaryType, index) {
                                    if (auxiliaryType.SupervisedSubTypeId == dentalAuxiliary.SupervisedSubTypeId) {
                                        dentalAuxiliary.SupervisedSubTypeName = auxiliaryType.SupervisedSubTypeName;
                                    }
                                });

                                $scope.lstIndividualSupervisory.push(dentalAuxiliary);
                            } else {
                                for (var i = 0; i < $scope.lstIndividualSupervisory.length; i++) {
                                    if ($scope.lstIndividualSupervisory[i].IndividualSupervisoryInfoId == dentalAuxiliary.IndividualSupervisoryInfoId) {
                                        $scope.lstIndividualSupervisory[i] = dentalAuxiliary;
                                        angular.forEach($scope.auxiliaryTypes, function (auxiliaryType, index) {
                                            if (auxiliaryType.SupervisedSubTypeId == $scope.lstIndividualSupervisory[i].SupervisedSubTypeId) {
                                                $scope.lstIndividualSupervisory[i].SupervisedSubTypeName = auxiliaryType.SupervisedSubTypeName;
                                            }
                                        });
                                    }
                                }
                            }
                            $scope.viewConfig.showAddDentalAuxiliaryForm = false;
                        }
                        if ($scope.isAuxiliaryEdit) {
                            $scope.showStatusMessage(Messages.cfedefc, "success");

                        }
                        else {
                            $scope.showStatusMessage(Messages.affbeca, "success");
                        }
                        HideLoader();
                    }, function (data) {
                        if (data != null) {
                            $scope.showStatusMessage(data.message, "error");
                            ShowErrMsg("unableToSaveAuxiliary", data);
                        }
                    });
            } catch (ex) {
                ShowErrMsg("unableToSaveAuxiliary", ex);
                HideLoader();
            }
        };

        // $scope.saveDentalAuxiliaryConfirmation = function (currentForm, currentAuxiliaryForm) {
        //     if (!validateForm(currentForm, currentAuxiliaryForm)) {
        //         // initialApplicationstatusService.setDentalAuxiliary("invalid");
        //         return;
        //     }
        //     initialApplicationDentalAuxiliariesService.saveDentalAuxiliaryConf($scope.dentalAuxiliaryConfirmation, sessionStorage.Key)
        //         .then(function (response) {
        //             if (response.Status) {
        //                 //     initialApplicationstatusService.setDentalAuxiliary("success");
        //             } else {
        //                 //    initialApplicationstatusService.setDentalAuxiliary("error");
        //                 ShowErrMsg("unableToSaveAuxiliary", response.Message);
        //             }
        //             if (!$rootScope.ProcessLoaderExternally)
        //                 HideLoader();
        //         }, function (data) {
        //             //  initialApplicationstatusService.setDentalAuxiliary("error");
        //             ShowErrMsg("unableToSaveAuxiliary", data);
        //             $scope.isSavedSuccessFully = false;
        //             if (!$rootScope.ProcessLoaderExternally)
        //                 HideLoader();
        //         });
        // };



        $scope.init = function () {
            ShowLoader();
            typeValuesService.getTypeValuesByTypeName('GetAllAuxiliaryType', sessionStorage.Key, false, true).then(function (response) {
                //console.log(response);
                $scope.auxiliaryTypes = response.RefSupervisedSubTypeResponseList;
                HideLoader();
            }, function (error) {
                HideLoader();
            });

            backofficeDAuxilaryTabService.getIndividualSupervisoryInfo(sessionStorage.Key, $scope.IndividualID).then(function (response) {
                if (response.Status && response.IndividualSupervisoryInfos) {
                    $scope.lstIndividualSupervisorys = response.IndividualSupervisoryInfos;
                    prepareAuxiliaryData();
                }
            }, function (error) {

            });
            // getData();
        }


        $scope.init();
    }
})();